import React, { Component } from "react";
import DatePickerAdapter from "./datePickerAdapter";
import {Translation} from "react-i18next";

export default class InlineAdvanceFilter extends Component {
  render() {
    return (
      <div className="card inline-advance-search float-right">
        <div className="form-inline">
          <div className="form-group ">
            <label htmlFor="start_date" className="startDate mr-3">
            <Translation>{t => t('filterFunction:sort_by_date')}</Translation>
            </label>
            <DatePickerAdapter
              id="start_date"
              className="form-control"
              name="temp_start_date"
              selected={this.props.start_date}
              onChange={this.props.handleChange}
            />
          </div>
          <div className="form-group ">
            <label htmlFor="end_date" className="ml-3 mr-3">
              <Translation>{t => t('filterFunction:to')}</Translation>
            </label>
            <DatePickerAdapter
              id="end_date"
              name="temp_end_date"
              className="form-control"
              selected={this.props.end_date}
              onChange={this.props.handleChange}
            />
          </div>
          {this.props.app_status && (
            <div className="form-group " style={{ paddingBottom: "5px" }}>
              <label htmlFor="end_date" className="ml-3 mr-3">
                <Translation>{t => t('filterFunction:status_app')}</Translation>
              </label>
              <select
                name="temp_app_status"
                onChange={this.props.handleChange}
                className="form-control p-0 m-0"
                style={{ paddingBottom: "5px" }}
              > 
                <Translation>
                    {
                        t => <option value="all">{t('filterFunction:all')}</option>
                    }
                </Translation>
                <Translation>
                    {
                        t => <option value="active">{t('filterFunction:download')}</option>
                    }
                </Translation>
                <Translation>
                    {
                        t => <option value="is_active">{t('filterFunction:not_download')}</option>
                    }
                </Translation>
              </select>
            </div>
          )}

          {this.props.status && (
            <div className="form-group " style={{ paddingBottom: "5px" }}>
              <label htmlFor="status" className="ml-3 mr-3">
                <Translation>{t => t('filterFunction:status')}</Translation>
              </label>
              <select
                name="temp_status"
                onChange={this.props.handleChange}
                className="form-control"
                value={this.props.status_value}
              >
                {this.props.status_list.map((list, index) => {
                  return (
                    <Translation>
                      {t=>
                      <option key={index} value={list.value}>
                        {t(`guarantee_money_received:${list.text}`)}
                      </option>}
                    </Translation>
                  );
                })}
              </select>
            </div>
          )}
        </div>
      </div>
    );
  }
}
