import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import "./styles/report.scss";
import AssetRegisterReportTable from "./assetRegisterReportTable";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import _ from "lodash";
import ExportAssetRegisterReport from "./server_export";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { format, setDate, setMonth, lastDayOfMonth } from "date-fns";
import Loading from "../../../../libs/loading"
import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from "../../../../components/BackBtn/indexBack";
import i18next from "i18next";

const asset_type = [
  {
    code: "land",
    header_name: "LAN - ที่ดิน",
  },
  {
    code: "building",
    header_name: "B - อาคาร",
  },
  {
    code: "building_renovation",
    header_name: "BR - ส่วนต่อเติมและปรับปรุงอาคาร",
  },
  {
    code: "office_decoration",
    header_name: "OD - เครื่องตกแต่งสำนักงาน",
  },
  {
    code: "office_equipment",
    header_name: "EQ - อุปกรณ์และเครื่องใช้สำนักงาน",
  },
  {
    code: "tools",
    header_name: "T - เครื่องมือ/อุปกรณ์งานช่าง",
  },
  {
    code: "training_equipment",
    header_name: "TE - เครื่องออกกำลังกาย/สันทนาการ",
  },
  {
    code: "vehicle",
    header_name: "V - ยานพาหนะ",
  },
  {
    code: "common_property",
    header_name: "CP - ทรัพย์สินส่วนกลาง",
  },
  {
    code: "other_asset",
    header_name: "OA - สินทรัพย์อื่นๆ",
  },
  {
    code: "intangible_asset",
    header_name: "ITG - สินทรัพย์ไม่มีตัวตน",
  },
];

const query = graphql`
  query assetRegisterReportQuery(
    $type: String
    $search: String
    $first: Int
    $last: Int
    $order: String
    $startDate: DateTime
    $endDate: DateTime
    $date: Date
  ) {
    allAsset(
      type: $type
      search: $search
      first: $first
      last: $last
      order: $order
      startDate: $startDate
      endDate: $endDate
    ) {
      totalCount
      edges {
        node {
          id
          docNumber
          name
          type
          storage
          purchaseDate
          costPrice
          calDepreciation
          lifeTimeYear
          depreciationRatePercent
          description
          remark
          nounClassifier
          depreciationAmount
          sellerOther
          calDepreciationDate
          saleStatus
          seller {
            id
            refNumber
            taxIdNumber
            name
            firstName
            lastName
          }
          historicalAmount
          assetDepreciate(date_Lte: $date) {
            edges {
              node {
                id
                depreciatePrice
              }
            }
          }
          internalAssetCode
          carcassPrice
          saleDate
        }
      }
    }
  }
`;

class AssetRegisterReport extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.advance_search = false;
    this.state.search = "";
    this.state.search_input = "";
    this.state.type = "";
    this.state.temp_type = "";
    this.state.start_date = null;
    this.state.end_date = new Date();
    this.state.temp_start_date = null;
    this.state.temp_end_date = new Date();
    this.state.asset = [];
    this.state.date = lastDayOfMonth(
      setDate(setMonth(new Date(), new Date().getMonth() - 1), 1)
    );

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.advanceSearchToggle = this.advanceSearchToggle.bind(this);
    this.handleAsset = this.handleAsset.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "assetRegisterReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  componentDidMount() {
    let last_day_date = lastDayOfMonth(
      setMonth(this.state.end_date, this.state.end_date.getMonth())
    ).getDate();
    if (this.state.end_date.getDate() === last_day_date) {
      let last_date = lastDayOfMonth(
        setMonth(this.state.end_date, this.state.end_date.getMonth())
      );
      this.setState({ date: last_date });
    }
  }

  handleChange(e) {
    let last_date = this.state.date;
    if (e.target.name === "temp_end_date") {
      let last_day_date = lastDayOfMonth(
        setMonth(e.target.value, e.target.value.getMonth())
      ).getDate();
      if (e.target.value.getDate() === last_day_date) {
        last_date = lastDayOfMonth(
          setMonth(e.target.value, e.target.value.getMonth())
        );
      } else {
        last_date = lastDayOfMonth(
          setMonth(e.target.value, e.target.value.getMonth() - 1)
        );
      }
      this.setState({ date: last_date });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      type: this.state.temp_type,
    });
  }

  advanceSearchToggle() {
    this.setState({ advance_search: !this.state.advance_search });
  }

  async handleAsset(asset_list) {
    await this.setState({ asset: asset_list });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>

              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/asset"}
                  LinkText={i18next.t("ReportAsset:Asset Register Report")}
                  boxHtmlText={
                    <div className="col">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_asset_print",
                      }) && (
                          <ExportAssetRegisterReport
                            state={this.state}
                            asset={this.state.asset}
                            asset_type={asset_type}
                            startDate={this.state.start_date}
                            endDate={this.state.end_date}
                            type={this.state.type}
                            date={this.state.date}
                            reportType='register'
                          />
                        )}
                    </div>
                  }
                />

                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col-2" />
                    <div className="col">
                      <div className="row">
                        {/* <label className="col-form-label ml-2 mr-2">เรียงดูตามวัน</label>
                                        <DatePickerAdapter
                                            name="start_date" className="form-control"
                                            selected={this.state.start_date}
                                            onChange={this.handleChange}
                                            required={true}
                                        /> */}
                        <label className="col-form-label ml-2 mr-2">{i18next.t("ReportAssetDetail:As of Date")}</label>
                        <div className="form-inline float-right mr-2">
                          <DatePickerAdapter
                            name="temp_end_date"
                            className="form-control"
                            selected={this.state.temp_end_date}
                            // maxDate= {new Date()}
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>
                        <label className="col-form-label mr-2 ml-2 text-right">
                          {i18next.t("ReportAssetDetail:Asset Category")}
                        </label>
                        <select
                          name="temp_type"
                          onChange={this.handleChange}
                          value={this.state.temp_type}
                          className="form-control col"
                        >
                          <option value="">{i18next.t("ReportAssetDetail:All")}</option>
                          <option value="land">{i18next.t("assetSetting:land")}</option>
                          <option value="building">{i18next.t("assetSetting:building")}</option>
                          <option value="building_renovation">
                            {i18next.t("assetSetting:building_renovation")}
                          </option>
                          <option value="office_decoration">
                            {i18next.t("assetSetting:office_decoration")}
                          </option>
                          <option value="office_equipment">
                            {i18next.t("assetSetting:office_equipment")}
                          </option>
                          <option value="tools">{i18next.t("assetSetting:tools")}</option>
                          <option value="training_equipment">
                            {i18next.t("assetSetting:training_equipment")}
                          </option>
                          <option value="vehicle">{i18next.t("assetSetting:vehicle")}</option>
                          <option value="common_property">{i18next.t("assetSetting:common_property")}</option>
                          <option value="other_asset">{i18next.t("assetSetting:other_asset")}</option>
                          <option value="intangible_asset">
                            {i18next.t("assetSetting:intangible_asset")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="input-group w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>
                    </div>
                  </div>

                  <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{ use_cache: false }}
                    variables={{
                      type: this.state.type,
                      // first: this.state.first,
                      // last: this.state.last,
                      search: this.state.search,
                      // order: "doc_number",
                      startDate: this.state.start_date,
                      endDate: this.state.end_date,
                      date: format(this.state.date, "YYYY-MM-DD"),
                    }}
                    render={({ error, props }) => {
                      if (props) {
                        return (
                          <React.Fragment>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table className="table table-hover">
                                      <thead className="thead-light">
                                        <tr>
                                          <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Sequence")}</th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Asset Category")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Asset Code")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Internal Asset Code")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Asset Name")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Description")}</th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Storage Location")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Seller Code")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Seller")}</th>
                                          <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Amount")}</th>
                                          <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Unit")}</th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Depreciation Method")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Depreciation Rate (%)")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Asset Lifespan (Years)")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Purchase Date")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Asset Value (THB)")}
                                          </th>
                                          <th className="text-center" rowSpan={2} >
                                            {i18next.t("ReportAssetDetail:Scrap Value")}
                                          </th>
                                          <th className="text-center" colSpan={2}>
                                            {i18next.t("ReportAssetDetail:Remaining Lifespan")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Start Depreciation Date")}
                                          </th>
                                          <th className="text-center" width={180} rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Accumulated Depreciation (THB)")} <br /> {i18next.t("ReportAssetDetail:as of date")}
                                            {" "}
                                            {this.state.date
                                              ? format(
                                                this.state.date,
                                                "DD-MM-YYYY"
                                              )
                                              : ""}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Net Book Value (THB)")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Disposal Date")}
                                          </th>
                                          <th className="text-center" rowSpan={2}>
                                            {i18next.t("ReportAssetDetail:Disposal Method")}
                                          </th>
                                        </tr>
                                        <tr>
                                          <th className="text-center">{i18next.t("ReportAssetDetail:Year")}</th>
                                          <th className="text-center">{i18next.t("ReportAssetDetail:Month")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <AssetRegisterReportTable
                                          query={query}
                                          props={props}
                                          state={this.state}
                                          asset_type={asset_type}
                                          handlerAsset={this.handleAsset}
                                        />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row">
                                                    <Pagination
                                                        changePage={this.changePage}
                                                        first={this.state.first}
                                                        last={this.state.last}
                                                        totalCount={props.allAsset.totalCount}
                                                    />
                                                </div> */}
                          </React.Fragment>
                        );
                      } else {
                        return <Loading />
                      }
                    }}
                  />
                </div>
              </div>

            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default AssetRegisterReport;
