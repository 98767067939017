import i18next from "i18next";
import React from "react";
import { Component } from "react";
import numberWithCommas from "../../libs/numberWithComma";

class UnitReportTable extends Component {

    render() {
        return (
            <React.Fragment>
                <tbody>
                    {this.props.unitReportList.map((transaction,index) => {
                        return (
                            <tr key={index}>
                                <td className ="text-center">{index+1}</td>                                                                       
                                <td className ="text-center">{transaction.plan}</td>
                                <td className ="text-center">{transaction.roomNo}</td>
                                <td className ="text-center">{transaction.size}</td>
                                {transaction.productAndService.map((product,index2) => {
                                    return <td className ="text-right" key={index2}>{numberWithCommas(product)}</td>
                                })

                                }
                                <td className ="text-right">{numberWithCommas(transaction.total)}</td>
                            </tr>
                        );
                    })}
                    
                    
                </tbody> 
                <tfoot>
                    <tr>
                        <td colSpan={2}className="text-right text-dark"><strong>{i18next.t("unitReport:Total amount")}</strong></td>
                        <td colSpan={2}/>
                        <td className="text-right text-dark"><strong>{numberWithCommas(parseFloat(this.props.state.totalProduct1))}</strong></td>
                        <td className="text-right text-dark"><strong>{numberWithCommas(parseFloat(this.props.state.totalProduct2))}</strong></td>
                        <td className="text-right text-dark"><strong>{numberWithCommas(parseFloat(this.props.state.totalProduct3))}</strong></td>
                        <td className="text-right text-dark"><strong>{numberWithCommas(parseFloat(this.props.state.totalOther))}</strong></td>
                        <td className="text-right text-dark"><strong>{numberWithCommas(parseFloat(this.props.state.totalUnit))}</strong></td>
                        
                    </tr>
                </tfoot>
            </React.Fragment>
        )

    }


} 

export default UnitReportTable;