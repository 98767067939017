import React, { Component } from 'react'
import _ from "lodash"
import ReactPaginate from "react-paginate";
import "./style/productCreateAndUpdate.scss"
import i18next from 'i18next';

export default class setFormatNumber extends Component {
  render() {
    const {page, getPageLimit, importKeycard, index} = this.props;
    let items = importKeycard.slice(page * getPageLimit, (page + 1) * getPageLimit);

    return (
      <React.Fragment>
        <div className="modal fade" id="modalSetFormatNumber" role="dialog" aria-labelledby="modalSetFormatNumberTitle" aria-hidden="true" >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title" id="modalSetFormatNumberTitle">{i18next.t("chequeControlView:Specify the numbering format for No.")}</h5>
              </div>
              <div className="lineModal"/>
              
              <div className="modal-body">
                <div className="cardTable">
                  <div className="bglight">
                    <div className="row items">
                      <div className="col-2 text-center">{i18next.t("chequeControlView:Sequence")}</div>
                      <div className="col-10">Run No.</div>
                    </div>
                  </div>
                  <div className="slide">
                    {_.map(items, (item, indexItem) => {
                      return (
                        <React.Fragment key={indexItem}>
                          <div className="lineTop"/>
                          <div className="row items">
                            <div className="col-2 text-center">{(indexItem + 1) + (page * getPageLimit)}</div>
                            <div className="col-10">
                              <input className="form-control"  value={item.node.noNumber}
                                type="text" disabled={this.props.disableEdit}
                                name={`bring_forward_list[${index}].importKeycard[${(indexItem) + (page * getPageLimit)}].node.noNumber`} 
                                onChange={this.props.handleInputChange}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>

                <div className="paginationList my-4">
                    <div className="bgListPage">
                      <span>{i18next.t("chequeControlView:Show 50 items per page")}</span> 
                    </div>
                    <div className="paginationSetFormatNum">
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={
                          <span className="btn page-link">...</span>
                        }
                        breakClassName={"break-me"}
                        pageClassName="page-item"
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={4}
                        previousClassName="sr-only"
                        containerClassName={"pagination float-right"}
                        // subContainerClassName={"pagination-page"}
                        nextLinkClassName="sr-only"
                        pageLinkClassName="page-link"
                        activeClassName={"active"}
                        pageCount={Math.ceil(this.props.importKeycard.length / getPageLimit)}
                        onPageChange={this.props.handlePageClick}
                      />
                    </div>
                </div>

                <div className="row">
                  <div className="col-12 text-right listAll">
                  {i18next.t("chequeControlView:All items")} {this.props.importKeycard.length}
                  </div>
                </div>
              </div>
              
              <div className="lineModal"/>
              <div className="modal-footer border-0">
                {this.props.disableEdit 
                  ? <button type="button" className="btnClose" data-dismiss="modal">{i18next.t("Allaction:Close")}</button>
                  : <React.Fragment>
                      <button type="button" className="btnClose" 
                        data-dismiss="modal" 
                        onClick={() => this.props.handleClose(index, this.props.importKeycard.length, `bring_forward_list[${index}].importKeycard`)}
                      >
                          {i18next.t("chequeControlView:cancel")}
                      </button>
                      <button type="button" className="btnSave" data-dismiss="modal">{i18next.t("Allaction:save")}</button>
                    </React.Fragment>
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
