import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import WrapperContent from '../../components/wrapper/wrapperContent'
import Header from "../../components/header/index"
import Sidebar from '../../components/sidebar'
import { Link } from 'react-router-dom'
import Pagination from '../../libs/newPagination'
import {Redirect} from 'react-router';
import Navigation from './navigationAccountting'

const listServiceFree = [
    {no: "OR-202110100001", dateopen: "16/08/2021", nobooking: "BK-202110100001", reservationrights: "1089/95", name: "Marley George", commonarea: "ห้องประชุม 1", total: "1,200.00", paymentmethod: "เงินสด", by: "Marilyn Aminoff", status: "รับชำระแล้ว"},
    {no: "OR-202110100001", dateopen: "16/08/2021", nobooking: "BK-202110100001", reservationrights: "1089/95", name: "Marley George", commonarea: "ห้องประชุม 1", total: "1,200.00", paymentmethod: "เงินสด", by: "Marilyn Aminoff", status: "ยังไม่รับชำระ"},
    {no: "OR-202110100001", dateopen: "16/08/2021", nobooking: "BK-202110100001", reservationrights: "1089/95", name: "Marley George", commonarea: "ห้องประชุม 1", total: "1,200.00", paymentmethod: "เงินสด", by: "Marilyn Aminoff", status: "รับชำระแล้ว"},
    // {no: "OR-202110100001", dateopen: "16/08/2021", nobooking: "BK-202110100001", reservationrights: "1089/95", name: "Marley George", commonarea: "ห้องประชุม 1", total: "1,200.00", paymentmethod: "เงินสด", by: "Marilyn Aminoff", status: "รับชำระแล้ว"},
    // {no: "OR-202110100001", dateopen: "16/08/2021", nobooking: "BK-202110100001", reservationrights: "1089/95", name: "Marley George", commonarea: "ห้องประชุม 1", total: "1,200.00", paymentmethod: "เงินสด", by: "Marilyn Aminoff", status: "รับชำระแล้ว"},
    // {no: "OR-202110100001", dateopen: "16/08/2021", nobooking: "BK-202110100001", reservationrights: "1089/95", name: "Marley George", commonarea: "ห้องประชุม 1", total: "1,200.00", paymentmethod: "เงินสด", by: "Marilyn Aminoff", status: "รับชำระแล้ว"},

]

export default class AccountingList extends Component {

    constructor(props){
        super(props)

        this.state = {
            noSelect: "",
            redirect: false,
        }
    }

    status(item){
        let text = ""
        let color = ""
        switch (item) {
            case "รับชำระแล้ว":
                text = "รับชำระแล้ว"
                color = "#68DD6E"
                break;
        
            default:
                text = item
                color = "#DC143C"
                break;
        }
        return <td style={{color: `${color}`}}>{text}</td>
    }

    handleCheckItem = () => {
        if(this.state.noSelect === ""){
            alert("กรุณาเลือก")
        }else{
            this.setState({redirect: true})
        }
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={`/contact/global-area/accounting/addIncome/single/${this.state.noSelect}`}/>
        }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <div className="container-fluid gray" style={{ width: "100%", height: "48px" }}/>
                    <div className="container-fluid box">
                        <div className="row">
                            <div className="col">
                                <h3>
                                    <Link to="/contact">
                                        <img
                                            src={process.env.PUBLIC_URL +
                                                "/images/icons/chevron-back.png"}
                                            alt="back"
                                            className="back"
                                            />
                                    </Link>
                                    งานจัดเก็บค่าใช้บริการส่วนกลาง
                                </h3>
                            </div>
                        </div>

                        <Navigation/>

                        <div className="content-inner">
                            <div className="row justify-content-end m-0">
                                <button className="btn btn-primary" onClick={this.handleCheckItem}>
                                    <img src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                                        alt="plus"/>&nbsp;
                                    เพิ่มพื้นที่ส่วนกลาง
                                </button>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="float-right">
                                        <form className="form-inline">
                                            <label className="mr-2"><span className="font-weight-bold">แสดงช่วงเวลา</span>:</label>
                                            <select className="form-control my-3">
                                                <option value="">ทุกช่วงเวลา</option>
                                                <option value="today">วันนี้</option>
                                                <option value="threeDay">3 วัน</option>
                                                <option value="week">สัปดาห์นี้</option>
                                                <option value="mounth">เดือนนี้</option>
                                                <option value="threeMounth">3 เดือนนี้</option>
                                            </select>

                                            <div className="input-group ml-4 my-3">
                                                <input type="text" 
                                                    placeholder="ค้นหา" 
                                                    style={{borderTopLeftRadius: "3px", borderBottomLeftRadius: "3px", border: "1px solid #ced4da", borderRight:"none", padding: "6px 12px"}}
                                                    />
                                                <div className="input-group-prepend">
                                                    <button 
                                                        type="button" 
                                                        className="btn rounded-right input-group-text btn-light" 
                                                        style={{borderLeft:"none",color:"none", background: 'transparent'}}
                                                        >
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/images/icons/search-outline.png"
                                                            }
                                                            alt="calendars"
                                                            style={{ width: "17.81px", height: "17.81px" }}
                                                            />
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive fade-up">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th width="50">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="checkAll"
                                                        className="custom-control-input"
                                                        />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="checkAll"
                                                        />
                                                </div>
                                            </th>
                                            <th width="140">เลขที่</th>
                                            <th width="105">วัน/เวลาทำรายการ</th>
                                            <th>เลขที่การจอง</th>
                                            <th>สิทธิ์การจอง</th>
                                            <th>ชื่อ-นามสกุลผู้จอง</th>
                                            <th>พื้นที่ส่วนกลาง</th>
                                            <th>จำนวนเงินรวม</th>
                                            <th width="100">ช่องทางการชำระ</th>
                                            <th width="100">ออกโดย</th>
                                            <th>สถานะ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listServiceFree.map((item,index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id={index}
                                                                    className="custom-control-input"
                                                                    onChange={() => this.setState({noSelect: item.no})}
                                                                    />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={index}
                                                                    />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <Link 
                                                                to={`/contact/global-area/accounting/details/${item.no}`}  
                                                                className="text-decoration-none"
                                                                >
                                                                {item.no}
                                                            </Link>
                                                        </td>
                                                        <td>{item.dateopen}</td>
                                                        <td>{item.nobooking}</td>
                                                        <td>{item.reservationrights}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.commonarea}</td>
                                                        <td>{item.total}</td>
                                                        <td>{item.paymentmethod}</td>
                                                        <td>{item.by}</td>
                                                        {this.status(item.status)}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <Pagination/>
                            </div>
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}
