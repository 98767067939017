/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetPettyCashStatus = "ACTIVE" | "VOID" | "%future added value";
export type pettyCashListQueryVariables = {||};
export type pettyCashListQueryResponse = {|
  +allSetPettyCash: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +description: ?string,
        +withdrawer: ?string,
        +withdrawAmount: number,
        +totalPettyCashRecord: ?number,
        +status: SetPettyCashStatus,
        +voidRemark: ?string,
      |}
    |}>,
  |}
|};
export type pettyCashListQuery = {|
  variables: pettyCashListQueryVariables,
  response: pettyCashListQueryResponse,
|};
*/


/*
query pettyCashListQuery {
  allSetPettyCash(status: "active") {
    totalCount
    edges {
      node {
        id
        docNumber
        description
        withdrawer
        withdrawAmount
        totalPettyCashRecord
        status
        voidRemark
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "status",
        "value": "active"
      }
    ],
    "concreteType": "SetPettyCashNodeConnection",
    "kind": "LinkedField",
    "name": "allSetPettyCash",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SetPettyCashNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SetPettyCashNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "withdrawer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "withdrawAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalPettyCashRecord",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voidRemark",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "allSetPettyCash(status:\"active\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "pettyCashListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "pettyCashListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "35e0503ffc628e889fa8f5caf1c318db",
    "id": null,
    "metadata": {},
    "name": "pettyCashListQuery",
    "operationKind": "query",
    "text": "query pettyCashListQuery {\n  allSetPettyCash(status: \"active\") {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        description\n        withdrawer\n        withdrawAmount\n        totalPettyCashRecord\n        status\n        voidRemark\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '21e6a6cc65750af61819f026cd45f7f2';

module.exports = node;
