import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import "../styles/pettyCashAll.scss"
import { Helmet } from 'react-helmet';
import i18n  from 'i18next';
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';
const ThaiBaht = require('thai-baht-text');

class PettyCashAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            total: 0.0,
            start_date: this.props.start_date,
            end_date: this.props.end_date,
            now_date: new Date(),
        }
    }

    componentDidMount() {
        setTimeout(() => {
            let page = $('.' + this.props.petty_cash_page);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + (signature || 0));
            $('.' + this.props.petty_cash_page + ' table').css({ borderBottom: "1px solid" });
            if (this.props.current_page !== this.props.end_page) {
                $('.' + this.props.petty_cash_page + ' tbody tr:last-child td').css({ paddingBottom: diff });
            } else {
                $('.' + this.props.petty_cash_page + ' tbody tr:last-child td').css({ paddingBottom: diff });
            }
        }, 200);

    }

    render() {
        return (
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                            @media print {
                                html, body {
                                    width: 210mm;
                                    height: 297mm;
                                   
                                }    
                            }
                        `
                    }]}>
                </Helmet>
                <div className={"print-daily-journal-a4 " + this.props.petty_cash_page} style={{ position: 'relative' }}>
                    <div className="subpage" style={{ maxHeight: this.props.current_page === this.props.end_page ? '260mm' : '276mm', overflow: 'hidden' }}>
                        <div className="head">
                            <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
                                <div style={{ maxWidth: 100, maxHeight: 100 }}>
                                    <img src={this.props.query.selfProject?.logo} alt="silverman" />
                                </div>
                                <div>
                                    <span className="text-center" style={{ position: "absolute" , fontSize: 13 }}>
                                        <div><strong>{i18n.languages[0] === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name) : this.props.query.selfProject.name}</strong></div>
                                        <div>{i18n.languages[0] === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}</div>
                                        <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="detail mt-2">
                            <div className="row">
                                <div className="col">
                                    {(this.state.start_date && this.state.end_date) ?
                                        <strong>ใบสรุปบันทึกจ่ายเงินสดย่อย
                                            ประจำวันที่ {format(this.state.start_date, 'DD/MM/YYYY', { locale: thLocale }) + ' - ' + format(this.state.end_date, 'DD/MM/YYYY', { locale: thLocale })}</strong>
                                        :
                                        <strong>ใบสรุปบันทึกจ่ายเงินสดย่อย
                                            ประจำวันที่ {format(this.state.start_date, 'DD/MM/YYYY', { locale: thLocale })}</strong>
                                    }
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>รายการตั้งเบิก {this.props.query.setPettyCash.docNumber} {this.props.query.setPettyCash.withdrawer} {this.props.query.setPettyCash.description}</strong>
                                </div>
                            </div>
                        </div>

                        <div className="invoice-body">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th className="text-center">
                                            <strong>เลขที่</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>วันที่</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>รหัสบัญชี</strong>
                                        </th>
                                        <th className="text-center" width="162">
                                            <strong>ชื่อบัญชี</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>คำอธิบาย</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>จำนวนเงิน</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.petty_cash_list.map((petty_cash, index) => {
                                        //ถ้าที่วนมาเป็น petty cash
                                        if (!petty_cash.node.otherExpense && !petty_cash.node.payRecordGroup && !petty_cash.node.advance && !petty_cash.node.clearAdvance && !petty_cash.node.clearGuaranteeMoneyReceived) {
                                            return (
                                                <tr key={petty_cash.node.id + index}>
                                                    <td width="95" className="text-center">
                                                        {petty_cash.node.docNumber}
                                                    </td>
                                                    <td className="text-center"> {format(petty_cash.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                    <td width="60"
                                                        className="text-center">{petty_cash.node.chartOfAccount.chartOfAccountCode}</td>
                                                    <td>{petty_cash.node.chartOfAccount.name}</td>
                                                    <td style={{ maxWidth: 200, overflowWrap: 'break-word' }} >{petty_cash.node.description}</td>
                                                    <td className="text-right">{numberWithComma(petty_cash.node.price)}</td>
                                                </tr>
                                            )
                                            //ถ้าวนมาเป็น OE
                                        } else if (petty_cash.node.otherExpense) {
                                            return (
                                                petty_cash.node.otherExpense.otherExpenseTransaction.edges.map((otherExpenseTransaction, index_into) => {
                                                    let code = "";
                                                    let name_code = "";
                                                    let last_row = petty_cash.node.otherExpense.otherExpenseTransaction.edges.length;
                                                    if (otherExpenseTransaction.node.productAndService) {
                                                        code = otherExpenseTransaction.node.productAndService.productCode;
                                                        name_code = otherExpenseTransaction.node.productAndService.name;
                                                    } else {
                                                        code = otherExpenseTransaction.node.chartOfAccount.chartOfAccountCode;
                                                        name_code = otherExpenseTransaction.node.chartOfAccount.name;
                                                    }
                                                    return (
                                                        <tr key={petty_cash.node.id + index_into}>
                                                            <td className="text-center">{index_into === 0 && petty_cash.node.otherExpense.docNumber}</td>
                                                            <td className="text-center"> {format(petty_cash.node.otherExpense.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                            <td className="text-center">{code}</td>
                                                            <td>{name_code}</td>
                                                            <td>{otherExpenseTransaction.node.description}</td>
                                                            <td className="text-right">{index_into + 1 === last_row ? numberWithComma(petty_cash.node.price) : "-"}</td>
                                                        </tr>
                                                    )
                                                })

                                            )
                                        }//ถ้าวนมาเป็น PS
                                        else if (petty_cash.node.payRecordGroup) {
                                            return (
                                                petty_cash.node.payRecordGroup.payRecord.edges.map((payRecord, index_into) => {
                                                    let code = "";
                                                    let name_code = "";
                                                    let last_row = petty_cash.node.payRecordGroup.payRecord.edges.length;
                                                    if (payRecord.node.purchaseRecord.productAndService) {
                                                        code = payRecord.node.purchaseRecord.productAndService.productCode;
                                                        name_code = payRecord.node.purchaseRecord.productAndService.name;
                                                    } else {
                                                        code = payRecord.node.purchaseRecord.chartOfAccount.chartOfAccountCode;
                                                        name_code = payRecord.node.purchaseRecord.chartOfAccount.name;
                                                    }
                                                    return (
                                                        <tr key={petty_cash.node.id + index_into}>
                                                            <td className="text-center">{index_into === 0 && petty_cash.node.payRecordGroup.docNumber}</td>
                                                            <td className="text-center"> {format(petty_cash.node.payRecordGroup.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                            <td className="text-center">{code}</td>
                                                            <td>{name_code}</td>
                                                            <td>{payRecord.node.purchaseRecord.description}</td>
                                                            <td className="text-right">{index_into + 1 === last_row ? numberWithComma(petty_cash.node.price) : "-"}</td>
                                                        </tr>
                                                    )
                                                })

                                            )
                                        } //ถ้าเป็นเงินทดรองจ่าย
                                        else if (petty_cash.node.advance) {
                                            let code = "";
                                            let name_code = "";

                                            code = petty_cash.node.advance.chartOfAccount.chartOfAccountCode;
                                            name_code = petty_cash.node.advance.chartOfAccount.name;
                                            return (
                                                <tr key={petty_cash.node.id}>
                                                    <td className="text-center">{ petty_cash.node.advance.docNumber}</td>
                                                    <td className="text-center"> {format(petty_cash.node.advance.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                    <td className="text-center">{code}</td>
                                                    <td>{name_code}</td>
                                                    <td>{petty_cash.node.advance.description}</td>
                                                    <td className="text-right">{numberWithComma(petty_cash.node.price)}</td>
                                                </tr>
                                            )
                                        }
                                        else if (petty_cash.node.clearAdvance) {
                                            return (
                                                petty_cash.node.clearAdvance.clearAdvanceTransaction.edges.map((clearAdvanceTransaction, index_into) => {
                                                    let code = "";
                                                    let name_code = "";
                                                    let last_row = petty_cash.node.clearAdvance.clearAdvanceTransaction.edges.length;
                                                    code = clearAdvanceTransaction.node.chartOfAccount.chartOfAccountCode;
                                                    name_code = clearAdvanceTransaction.node.chartOfAccount.name;
                                                    
                                                    return (
                                                        <tr key={petty_cash.node.id + index_into}>
                                                            <td className="text-center">{index_into === 0 && petty_cash.node.clearAdvance.docNumber}</td>
                                                            <td className="text-center"> {format(petty_cash.node.clearAdvance.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                            <td className="text-center">{code}</td>
                                                            <td>{name_code}</td>
                                                            <td>{clearAdvanceTransaction.node.description}</td>
                                                            <td className="text-right">{index_into + 1 === last_row ? numberWithComma(petty_cash.node.price) : "-"}</td>
                                                        </tr>
                                                    )
                                                })

                                            )
                                        }//ถ้าวนมาเป็น CAD
                                        else if (petty_cash.node.clearGuaranteeMoneyReceived) {
                                            let code = petty_cash.node.clearGuaranteeMoneyReceived.guarantee.chartOfAccount.chartOfAccountCode;
                                            let name_code = petty_cash.node.clearGuaranteeMoneyReceived.guarantee.chartOfAccount.name;
                                            return (
                                                <tr key={petty_cash.node.id}>
                                                    <td className="text-center">{ petty_cash.node.clearGuaranteeMoneyReceived.docNumber}</td>
                                                    <td className="text-center"> {format(petty_cash.node.clearGuaranteeMoneyReceived.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                    <td className="text-center">{code}</td>
                                                    <td>{name_code}</td>
                                                    <td>{petty_cash.node.description}</td>
                                                    <td className="text-right">{numberWithComma(petty_cash.node.price)}</td>
                                                </tr>
                                            )
                                        }//ถ้าวนมาเป็น CGR

                                    })}
                                </tbody>
                                <tfoot>
                                    {(this.props.end_page === 0 ?
                                        <tr>
                                            <td className="text-center" style={{ borderRight: "none" }}>
                                                <strong>รวมทั้งหมด</strong>
                                            </td>
                                            <td colSpan={4} className="text-left" style={{ borderLeft: "none" }}>
                                                <strong style={{ fontSize: 12 }}>
                                                    {i18n.languages[0] === 'th' ?
                                                    (ThaiBaht(this.props.total_sum_page.toFixed(2))):
                                                    ConvertNumberToTextEng(this.props.total_sum_page.toFixed(2))}
                                                </strong>
                                            </td>
                                            <td className="text-right">
                                                <strong style={{ fontSize: 12 }}>{numberWithComma(this.props.total_sum_page)}</strong>
                                            </td>
                                        </tr>
                                        :
                                        <tr>
                                            <td className="text-center" style={{ borderRight: "none" }}>
                                                <strong>รวม</strong>
                                            </td>
                                            <td colSpan={4} className="text-left" style={{ borderLeft: "none" }}>
                                                <strong style={{ fontSize: 12 }}>                                                    
                                                    {i18n.languages[0] === 'th' ?
                                                    (ThaiBaht(this.props.total.toFixed(2))):
                                                    ConvertNumberToTextEng(this.props.total.toFixed(2))}
                                                </strong>
                                            </td>
                                            <td className="text-right">
                                                <strong style={{ fontSize: 12 }}>{numberWithComma(this.props.total)}</strong>
                                            </td>
                                        </tr>
                                    )}
                                    {(this.props.current_page === this.props.end_page && this.props.current_page !== 0) &&
                                        <tr>
                                            <td className="text-center" style={{ borderRight: "none" }}>
                                                <strong>รวมทั้งหมด</strong>
                                            </td>
                                            <td colSpan={4} className="text-left" style={{ borderLeft: "none" }}>
                                                <strong style={{ fontSize: 12 }}>                                                    
                                                    {i18n.languages[0] === 'th' ?
                                                    (ThaiBaht(this.props.total_sum_page.toFixed(2))):
                                                    ConvertNumberToTextEng(this.props.total_sum_page.toFixed(2))}
                                                </strong>
                                            </td>
                                            <td className="text-right">
                                                <strong style={{ fontSize: 12 }}>{numberWithComma(this.props.total_sum_page)}</strong>
                                            </td>
                                        </tr>
                                    }
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    {this.props.current_page === this.props.end_page &&
                        <div style={{ position: 'relative' }}>
                            <div className="footer" style={{ fontSize: "12px" }}>
                                <div className="pt-5">
                                    <span className="box-four">
                                        ..................................................<br />
                                        <span className="font-weight-bold">ผู้จัดทำ / Organizer</span>
                                    </span>
                                    <span className="box-four">
                                        ..................................................<br />
                                        <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span>
                                    </span>
                                    <span className="box-four">
                                        ..................................................<br />
                                        <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span>
                                    </span>
                                    <span className="box-four">
                                        ..................................................<br />
                                        <span className="font-weight-bold">ผู้รับเงิน / Receiver</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                    <div>
                        <div className="number-page" style={{ marginTop: this.props.current_page === this.props.end_page ? 56 : 'auto' }}>
                            {this.props.current_page + 1}/{this.props.end_page + 1}
                        </div>
                    </div>
                </div>
            </React.Fragment>


        );
    }
}

export default PettyCashAll;
