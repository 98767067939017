/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfCompany = "BRANCH" | "HEADQUARTERS" | "NOBRANCH" | "%future added value";
export type ContactTypeOfSupplier = "ASSOCIATION" | "COMPANY" | "FOUNDATION" | "GROUP_OF_PERSON" | "JOINT_VENTURE" | "MERCHANT" | "ORDINARY_PARTNERSHIP" | "OTHER" | "PARTNERSHIP" | "PERSON" | "PUBLIC_COMPANY" | "%future added value";
export type ProcurementFormPaymentTerms = "A_15" | "A_30" | "A_45" | "A_7" | "OTHER" | "%future added value";
export type ProcurementFormType = "ASSET" | "PRODUCT" | "SERVICE" | "SERVICE_CONTACT" | "%future added value";
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type wrapperManageRequestQueryVariables = {|
  prfId?: ?string
|};
export type wrapperManageRequestQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +logo: ?string,
    +juristicContactNumber: ?string,
    +keyProjectQr: ?string,
    +bankCompCode: ?string,
    +bankServiceCode: ?string,
    +taxIdNumber: ?string,
    +typeOfProject: ?ProjectTypeOfProject,
  |},
  +oneProcurementForm: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +warrantyDay: ?number,
        +presenterRole: ?string,
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +creditor: {|
          +id: string,
          +name: string,
          +refNumber: string,
          +taxIdNumber: ?string,
          +registeredAddress: string,
          +registeredDistrict: string,
          +registeredPostalCode: string,
          +registeredCity: string,
          +registeredProvince: string,
          +typeOfSupplier: ContactTypeOfSupplier,
          +typeOfCompany: ?ContactTypeOfCompany,
          +nameBranch: string,
        |},
        +buyDate: any,
        +contractNumber: ?string,
        +contractDate: ?any,
        +paymentTerms: ProcurementFormPaymentTerms,
        +otherPaymentTerms: ?string,
        +contractor: ?string,
        +contractorTel: string,
        +contractorEmail: ?string,
        +deliveryAddress: ?string,
        +deliveryDate: any,
        +comparativeCompany: ?number,
        +lowestOffer: number,
        +highestOffer: number,
        +reason: ?string,
        +feedback: ?string,
        +remark: string,
        +type: ProcurementFormType,
        +total: ?number,
        +procurementList: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +productAndService: ?{|
                +id: string,
                +name: string,
                +productCode: string,
              |},
              +chartOfAccount: ?{|
                +id: string,
                +chartOfAccountCode: string,
                +name: string,
              |},
              +description: string,
              +unitItems: number,
              +price: number,
              +unit: string,
              +discount: ?number,
              +budget: ?number,
              +vat: ?number,
              +whtRate: ?number,
              +whtRatePercent: ?number,
              +preTaxAmount: ?number,
              +total: ?number,
            |}
          |}>
        |},
      |}
    |}>
  |},
|};
export type wrapperManageRequestQuery = {|
  variables: wrapperManageRequestQueryVariables,
  response: wrapperManageRequestQueryResponse,
|};
*/


/*
query wrapperManageRequestQuery(
  $prfId: String
) {
  selfProject {
    id
    name
    address
    logo
    juristicContactNumber
    keyProjectQr
    bankCompCode
    bankServiceCode
    taxIdNumber
    typeOfProject
  }
  oneProcurementForm(prfId: $prfId) {
    edges {
      node {
        warrantyDay
        presenterRole
        id
        docNumber
        issuedDate
        creditor {
          id
          name
          refNumber
          taxIdNumber
          registeredAddress
          registeredDistrict
          registeredPostalCode
          registeredCity
          registeredProvince
          typeOfSupplier
          typeOfCompany
          nameBranch
        }
        buyDate
        contractNumber
        contractDate
        paymentTerms
        otherPaymentTerms
        contractor
        contractorTel
        contractorEmail
        deliveryAddress
        deliveryDate
        comparativeCompany
        lowestOffer
        highestOffer
        reason
        feedback
        remark
        type
        total
        procurementList {
          edges {
            node {
              id
              productAndService {
                id
                name
                productCode
              }
              chartOfAccount {
                id
                chartOfAccountCode
                name
              }
              description
              unitItems
              price
              unit
              discount
              budget
              vat
              whtRate
              whtRatePercent
              preTaxAmount
              total
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "prfId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxIdNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "juristicContactNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "keyProjectQr",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bankCompCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bankServiceCode",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfProject",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "prfId",
        "variableName": "prfId"
      }
    ],
    "concreteType": "ProcurementFormNodeConnection",
    "kind": "LinkedField",
    "name": "oneProcurementForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProcurementFormNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProcurementFormNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "warrantyDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "presenterRole",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "issuedDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "creditor",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refNumber",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "typeOfSupplier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "typeOfCompany",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameBranch",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "buyDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentTerms",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "otherPaymentTerms",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractorTel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractorEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deliveryAddress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deliveryDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "comparativeCompany",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lowestOffer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "highestOffer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reason",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feedback",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remark",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProcurementListNodeConnection",
                "kind": "LinkedField",
                "name": "procurementList",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProcurementListNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProcurementListNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductAndServiceNode",
                            "kind": "LinkedField",
                            "name": "productAndService",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "productCode",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ChartOfAccountNode",
                            "kind": "LinkedField",
                            "name": "chartOfAccount",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "chartOfAccountCode",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unitItems",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "price",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unit",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "discount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "budget",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "vat",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "whtRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "whtRatePercent",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "preTaxAmount",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperManageRequestQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wrapperManageRequestQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "8f374a72826432001efc76ce62d16421",
    "id": null,
    "metadata": {},
    "name": "wrapperManageRequestQuery",
    "operationKind": "query",
    "text": "query wrapperManageRequestQuery(\n  $prfId: String\n) {\n  selfProject {\n    id\n    name\n    address\n    logo\n    juristicContactNumber\n    keyProjectQr\n    bankCompCode\n    bankServiceCode\n    taxIdNumber\n    typeOfProject\n  }\n  oneProcurementForm(prfId: $prfId) {\n    edges {\n      node {\n        warrantyDay\n        presenterRole\n        id\n        docNumber\n        issuedDate\n        creditor {\n          id\n          name\n          refNumber\n          taxIdNumber\n          registeredAddress\n          registeredDistrict\n          registeredPostalCode\n          registeredCity\n          registeredProvince\n          typeOfSupplier\n          typeOfCompany\n          nameBranch\n        }\n        buyDate\n        contractNumber\n        contractDate\n        paymentTerms\n        otherPaymentTerms\n        contractor\n        contractorTel\n        contractorEmail\n        deliveryAddress\n        deliveryDate\n        comparativeCompany\n        lowestOffer\n        highestOffer\n        reason\n        feedback\n        remark\n        type\n        total\n        procurementList {\n          edges {\n            node {\n              id\n              productAndService {\n                id\n                name\n                productCode\n              }\n              chartOfAccount {\n                id\n                chartOfAccountCode\n                name\n              }\n              description\n              unitItems\n              price\n              unit\n              discount\n              budget\n              vat\n              whtRate\n              whtRatePercent\n              preTaxAmount\n              total\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9bf4acdd7a1e3471029fadd45e833dca';

module.exports = node;
