import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import Wrapper from '../components/wrapper';
import WrapperContent from '../components/wrapper/wrapperContent';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../env/environment";
import Swal from "sweetalert2";
import './setting.scss'
import i18next from "i18next";
import UpdatePDPA from './graphQL/updatePDPA';


const query = graphql`
    query pdpaAndManagementAcceptMarketQuery {
        myUser { 
            juristic {
                acceptPolicy acceptTermOfUse acceptMarketing acceptPartner
            } 
        }
    }
`;

class pdpaAndManagementAcceptMarket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            acceptPolicy: "",
            acceptTermOfUse: "",
            acceptMarketing: "",
            acceptPartner: "",
            loading: true
        }
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentWillMount() {
        this.getQuery()
    }

    getQuery() {
        this.setState({ loading: true });
        fetchQuery(environment, query).then(response_data => {
            if (response_data.myUser.juristic) {
                this.setState({
                    acceptPolicy: response_data.myUser.juristic.acceptPolicy,
                    acceptTermOfUse: response_data.myUser.juristic.acceptTermOfUse,
                    acceptMarketing: response_data.myUser.juristic.acceptMarketing,
                    acceptPartner: response_data.myUser.juristic.acceptPartner,
                    loading: false
                })
            }
        });
    }

    onSubmitSuccess = (response) => {
        this.setState({ loading: false });
        if (response) {
            Swal.fire(i18next.t('project:Save successfully!'), '', 'success').then(() => { 
                this.getQuery()
            });
        } else {
            Swal.fire(i18next.t('project:Fail to saved!'), '', 'warning');
        }
    }

    onSubmitError = (err) => {
        this.setState({ loading: false });
        Swal.fire('Error!', i18next.t('project:Please try again'), 'warning')
    }

    onSubmit(e) {
        // console.log(e.target.value);
        
        
        let data = e.target.value === "true" ? true : false

        let titel = data ? i18next.t("AlertMore:Confirm consent.") : i18next.t("AlertMore:Confirm withdrawal of consent.")
        let text = data ? `${i18next.t("AlertMore:Do you want us to disclose information for marketing purposes")}?` :`${i18next.t("AlertMore:Do you want to confirm your withdrawal of consent")}?`

        let variables = {
            input: {
                acceptPolicy: this.state.acceptPolicy,
                acceptTermOfUse: this.state.acceptTermOfUse,
                acceptMarketing: data,
                acceptPartner: this.state.acceptPartner,
            }
        }
        Swal.fire({
            title: titel,
            text: text,
            cancelButtonText: i18next.t("Allaction:cancel"),
            confirmButtonText: i18next.t("Allaction:Confirm"),
            showCancelButton: true,
            customClass: {
                cancelButton: 'swal-btn-primary-outline', //insert class here
                confirmButton: 'swal-btn-primary'
            }
        }).then((result) => { 
            if (result.value) {
                UpdatePDPA(variables, this.onSubmitSuccess, this.onSubmitError) 
            }else{
                this.getQuery()
            }
          })



    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            this.state.loading === false ?
                                <div id='pdpa-menu'>
                                    <div className="container-fluid box " style={{ marginBottom: '-25px' }}>
                                        <div className="row justify-content-between">
                                            <div className="col">
                                                <h3 className="mb-4">
                                                    <Link to="/policyAndManagement">
                                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                            alt="back" className="back" />
                                                    </Link>
                                                    <span>{t("pdpaandmanagement:Consent for marketing purposes")}</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row selectManagementAndPolicy bg-gray" style={{ paddingTop: '56px', minHeight: "83vh" }}>
                                        <div className="col-8 ml-8">
                                            <p>{t('pdpaAcceptMarking:AcceptMarking01')}</p>
                                            <p>{t('pdpaAcceptMarking:AcceptMarking02')} {t('pdpaAcceptMarking:AcceptMarking03')}</p>
                                            <p>{t('pdpaAcceptMarking:AcceptMarking04')} <b>"{t('pdpaAcceptMarking:AcceptMarking05')}"</b> 
                                            {t('pdpaAcceptMarking:AcceptMarking06')}</p>
                                            <p>{t('pdpaAcceptMarking:AcceptMarking07')} {t('pdpaAcceptMarking:AcceptMarking08')}</p>
                                            <p>{t('pdpaAcceptMarking:AcceptMarking09')} {t('pdpaAcceptMarking:AcceptMarking10')}</p>

                                            <p className='mt-10'>{t('pdpaAcceptMarking:AcceptMarking11')}</p>

                                            <div style={{ display: 'inline-flex' }}>
                                                <label className="containerRadio">{t('pdpaandmanagement:Accept')}
                                                    <input type={"radio"}
                                                        defaultChecked={this.state.acceptMarketing === true}
                                                        value={true}
                                                        name='acceptPolicy' onChange={this.onSubmit} />
                                                    <span className="checkmark"></span></label>

                                                <label className="containerRadio">{t('pdpaandmanagement:Reject')}
                                                    <input type={"radio"}
                                                        defaultChecked={this.state.acceptMarketing === false}
                                                        value={false}
                                                        name='acceptPolicy' onChange={this.onSubmit} />
                                                    <span className="checkmark"></span></label>
                                            </div>




                                            <hr className='mt-10' />
                                            <div className='apcentPolicy'>
                                                <p>{t('pdpaAcceptMarking:AcceptMarking12')}
                                                    <br />{t('pdpaAcceptMarking:AcceptMarking13')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container-fluid box fixRequestFast text-center" >
                                    <img src={process.env.PUBLIC_URL + '/images/icons/alert/loading.gif'} />
                                </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>

        );
    }
}

export default pdpaAndManagementAcceptMarket;
