import i18next from 'i18next';
import React, { Component } from 'react';
import '../style/claim.scss'


class StatusCliam extends Component {
    
    render() {
        return (

            <div className='row divwidth colorUse' style={{ position: 'relative' }}>
                {
                    this.props.status !== "not_approve" && this.props.status !== "consider_fail" ?
                        this.props.line === 1 ?
                            <div className='line top_0' style={{ top: -2 }}></div> :
                            this.props.line === 2 ?
                            <div className='line top_0' style={{ top: 39 }}></div> :
                            <div className='line top_0' style={{ bottom: this.props.bottom }}></div>
                        : 

                        ""

                }



                {
                    this.props.status === "not_approve" ?
                        <React.Fragment>
                            {
                                    this.props.line === 1 ? 
                                    <div className='line' style={{ top: -2, width: "64%", left: "18%" }}></div>
                                    :
                                    <div className='line' style={{ bottom: 36, width: "64%" , left: "18%" }}></div>
                                }
                           
                            <div className='col-4 text-center'>
                                {
                                    this.props.status === 'new' ?
                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive1.png"} />
                                        :
                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon1.png"} />
                                }
                                <p className={this.props.status === 'new' ? "text_FFBD2D" : ""}>{i18next.t("claimRequstList:Under consideration")}</p>
                            </div>
                            <div className='col-4 text-center'>
                                {
                                    this.props.status === 'pending_approve' ?
                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive2.png"} />
                                        :
                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon2.png"} />
                                }
                                <p className={this.props.status === 'pending_approve' ? "text_1567B4" : ""}>{i18next.t("claimRequstList:Under insurance approval")}</p>
                            </div>
                            <div className='col-4 text-center'>
                                {
                                    this.props.status === 'not_approve' ?
                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive5.png"} />
                                        :
                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon5.png"} />
                                }
                                <p className={this.props.status === 'not_approve' ? "text_CA0824" : ""}>{i18next.t("claimRequstList:Warranty disapproved")}</p>
                            </div>
                        </React.Fragment>
                        :
                        this.props.status === "consider_fail" ?
                            <React.Fragment>
                                {
                                    this.props.line === 1 ?
                                    
                                        <React.Fragment>
                                        <div className='line' style={{ top: -2, width: "25%", left: "38%" }}></div>
                                        <div className='col-3'></div>
                                        </React.Fragment> 
                                    :
                                    <div className='line' style={{ bottom: 36, width: "25%" }}></div>
                                }
                                
                                <div className='col-3 text-center'>
                                    {
                                        this.props.status === 'new' ?
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive1.png"} />
                                            :
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon1.png"} />
                                    }
                                    <p className={this.props.status === 'new' ? "text_FFBD2D" : ""}>{i18next.t("claimRequstList:Under consideration")}</p>
                                </div>
                                <div className='col-3 text-center'>
                                    {
                                        this.props.status === 'consider_fail' ?
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive5.png"} />
                                            :
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon5.png"} />
                                    }
                                    <p className={this.props.status === 'consider_fail' ? "text_CA0824" : ""}>ยกเลิก</p>
                                </div>
                                <div className='col-3'></div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className='col-3 text-center '>
                                    {
                                        this.props.status === 'new' ?
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive1.png"} />
                                            :
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon1.png"} />
                                    }
                                    <p className={this.props.status === 'new' ? "text_FFBD2D" : ""}>{i18next.t("claimRequstList:Under consideration")}</p>
                                </div>
                                <div className='col-3 text-center'>
                                    {
                                        this.props.status === 'pending_approve' ?
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive2.png"} />
                                            :
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon2.png"} />
                                    }
                                    <p className={this.props.status === 'pending_approve' ? "text_1567B4" : ""}>{i18next.t("claimRequstList:Under insurance approval")}</p>
                                </div>
                                <div className='col-3 text-center'>
                                    {
                                        this.props.status === 'approve' ?
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive3.png"} />
                                            :
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon3.png"} />
                                    }
                                    <p className={this.props.status === 'approve' ? "text_4EB4A9" : ""}>{i18next.t("claimRequstList:Receive compensation")}</p>
                                </div>
                                <div className='col-3 text-center'>
                                    {
                                        this.props.status === 'finish' ?
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive4.png"} />
                                            :
                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon4.png"} />
                                    }
                                    <p className={this.props.status === 'finish' ? "text_68DD6E" : ""}>{i18next.t("claimRequstList:Finished")}</p>
                                </div>
                            </React.Fragment>

                }



            </div>

            // <div style={{ display: 'inline-flex'  }}>
            //     <div className={'linestatus ml-1 mr-1 mt-2'}></div>
            //     <div className='text-center divwidth' style={{width: 71}}>
            //         {
            //             this.props.status === 'new' ?
            //             <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive1.png"} />
            //             :
            //             <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon1.png"} />
            //         }

            //         <p className={this.props.status === 'new' ?  "text_FFBD2D" : ""}>งานเคลมใหม่</p>
            //     </div>

            //     <div className='mr-3 ml-3'></div>
            //     <div className='text-center divwidth' style={{width: 81}}>
            //     {
            //             this.props.status === 'pending_approve' ?
            //             <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive2.png"} />
            //             :
            //             <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon2.png"} />
            //         }
            //         <p className={this.props.status === 'pending_approve' ?  "text_1567B4" : ""}>{i18next.t("claimRequstList:Under insurance approval")}</p>
            //     </div>

            //     <div className='mr-3 ml-3'></div>
            //     <div className='text-center divwidth' style={{width: 72}}>
            //     {
            //             this.props.status === 'approve' ?
            //             <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive3.png"} />
            //             :
            //             <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon3.png"} />
            //         }
            //         <p className={this.props.status === 'approve' ?  "text_4EB4A9" : ""}>{i18next.t("claimRequstList:Receive compensation")}</p>
            //     </div> 

            //     <div className='mr-3 ml-3'></div>
            //     <div className='text-center divwidth' style={{width: 72}}>
            //     {
            //             this.props.status === 'finish' ?
            //             <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive4.png"} />
            //             :
            //             <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon4.png"} />
            //         }
            //         <p className={this.props.status === 'finish' ?  "text_68DD6E" : ""}>{i18next.t("claimRequstList:Finished")}</p>
            //     </div> 

            //     {/* {

            //         this.props.status === "void" ?
            //             <React.Fragment>
            //                 <div className='mr-3 ml-3'></div>
            //                 <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon5.png"} />
            //                 <p>ยกเลิก</p>
            //             </React.Fragment>

            //             :

            //             <React.Fragment>
            //                 <div className='mr-3 ml-3'></div>
            //                 <div className={this.props.status === "finish" ? "groudStatus active" : "groudStatus "}>
            //                     <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/5.png"} />
            //                     <p>ปิดงาน</p>
            //                 </div>
            //             </React.Fragment>
            //     } */}




            // </div >
        )
    }
}


export default StatusCliam
