import React, { Component } from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Translation } from "react-i18next";
import i18next from 'i18next'
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import SearchSelect from "../../../libs/searchSelect";
import SelectContactDropDown from "../../income/selectContactDropDown";
import Swal from "sweetalert2";
import { commitMutation } from "react-relay";
import { Redirect } from 'react-router'
import { H4, H5, Line, HS6 } from '../../../components/Text/Text'
import './style/createChequeTransaction.scss'
import upload from '../../../libs/upload';
import { format } from "date-fns";
import Select from 'react-select'
import BackButtonIcon from '../../../components/BackBtn/indexBack';

const _ = require('lodash');

const query = graphql`
query createChequeTransactionQuery($bankAccountId: String) {
    chequeControl(bankAccountId: $bankAccountId,chequeFilter:true) {
      totalCount
      edges{
          node{
              id
              docNumber
              bankName
              branch
              bankLogo
              accountType
              accountName
              accountNameEn
              accountNumber
              updated
              chequeDetails(status:true,chequeNo_Status:true){
                  edges{
                      node{
                          id
                          status
                          noNumber
                          startCheque
                          endCheque
                          lastCheque
                          chequeNo(status:true){
                              totalCount
                              edges{
                                  node{
                                      id
                                      status
                                      noNumber
                                  }
                              }
                          }
                      }
                  }
              }
              
          }
      }          
  }
  }
  `
const mutation = graphql`
 mutation createChequeTransactionMutation($input: CreateAndUpdateChequeTransactionInput!){
    createAndUpdateChequeTransaction(input: $input){
        ok
        message
        }
    }
`;

const cheque_detail = {
    id: '',
    contact: { id: '' },
    chequeDate: new Date(),
    bankAccountId: '',
    date: new Date(),
    checkboxCustomCheque: false,
    ChequeDetailsId: '',
    ChequeDetailsCustom: '',
    ChequeNoId: '',
    ChequeNoCustom: '',
    price: '',
    contactId: "",
    refNumber: "",
    unknownContact: '',
    depositDate: '',
    note: "",
    status: "clearing",
    chequeDetails: [],
    chequeNo: [],
    bankName: '',
    branchNumber: '',
    accountNumber: '',
};

class CreateChequeTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cheque_transaction: cheque_detail,
            bank_list: [],
            loading: false,
            redirectToList: false,
            image_upload: '',
            options: [
                { value: 'wait', label: 'Pending Clearance/Printing of Cheque' },
            ],
            selected: 'wait',
            signreq: Math.random().toString(36),
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.docInputChange = this.docInputChange.bind(this);
        this.checkboxCustomCheque = this.checkboxCustomCheque.bind(this);
    }

    componentWillMount() {
        fetchQuery(environment, query, { bankAccountId: '' }).then(response_data => this.setState({ bank_list: response_data.chequeControl.edges }));
    }

    checkboxCustomCheque(e) {
        this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.checkboxCustomCheque", _.get(prevState, 'cheque_transaction.checkboxCustomCheque') ? false : true));
    }

    docInputChange(e) {
        if (e.target.name === 'image_upload') {
            this.setState({ image_upload: e.currentTarget.files[0], });
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e), { cheque_transaction } = this.state
        if (event.target.name === 'cheque_transaction.bankAccountId') {
            this.state.bank_list.forEach((bank) => {
                if (bank.node.id === event.target.value) {
                    this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.bankName", bank.node.bankName));
                    this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.branchNumber", bank.node.branch));
                    this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.accountNumber", bank.node.accountNumber));
                    this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.chequeDetails", bank.node.chequeDetails), () => {
                        if (bank.node.chequeDetails.edges.length >= 0) {
                            this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.ChequeDetailsId", ''));
                        }
                    });
                }

            })
        }

        if (this.state.cheque_transaction.checkboxCustomCheque === false) {

            if (event.target.name === 'cheque_transaction.chequeDetails.ChequeDetailsId') {
                if (cheque_transaction.chequeDetails.edges.length > 0) {
                    cheque_transaction.chequeDetails.edges.forEach((cheque) => {
                        if (cheque.node.id === event.target.value) {
                            this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.ChequeDetailsId", cheque.node.id));
                            this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.chequeNo", cheque.node.chequeNo));
                        }

                    })
                }
            }

            if (event.target.name === 'cheque_transaction.chequeNo.ChequeNoId') {
                if (cheque_transaction.chequeNo.edges.length > 0) {
                    cheque_transaction.chequeNo.edges.forEach((cheque) => {
                        if (cheque.node.id === event.target.value) {
                            this.setState(prevState => { return _.set(_.cloneDeep(prevState), "cheque_transaction.ChequeNoId", cheque.node.id); });
                        }

                    })
                }
            }
        }

        this.setState(prevState => { return _.set(_.cloneDeep(prevState), event.target.name, event.target.value); })
    }


    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload(path, file);
            resolve(res);
        });
    };

    async onSubmit(e) {
        let fileUpload = '', { image_upload } = this.state, chequeDocuments = []
        e.preventDefault();
        this.setState({ loading: true });
        if (image_upload) {
            await this.callUpload("/cheque_transaction",
                new File([this.state.image_upload], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${image_upload['name']}`)
            ).then((data) => fileUpload = data.key.replace("silverman-storage/private/", ""));
        }
        let variables = {
            input: {
                chequeTransaction: JSON.stringify({
                    chequeTransactionId: '',
                    "chequeDate": this.state.cheque_transaction.chequeDate,
                    "bankAccountId": this.state.cheque_transaction.bankAccountId,
                    "date": this.state.cheque_transaction.date,
                    "ChequeDetailsId": this.state.cheque_transaction.ChequeDetailsId,
                    "ChequeNoId": this.state.cheque_transaction.ChequeNoId,
                    "checkboxCustomCheque": this.state.cheque_transaction.checkboxCustomCheque,
                    "ChequeDetailsCustom": this.state.cheque_transaction.ChequeDetailsCustom,
                    "ChequeNoCustom": this.state.cheque_transaction.ChequeNoCustom,
                    "price": this.state.cheque_transaction.price,
                    "contactId": this.state.cheque_transaction.contact.id === 'other_select' ? '' : this.state.cheque_transaction.contact.id,
                    "refNumber": `เช็คจ่ายยกมา - ` + this.state.cheque_transaction.refNumber,
                    "unknownContact": this.state.cheque_transaction.unknownContact,
                    // "depositDate": this.state.cheque_transaction.depositDate || null,
                    "note": this.state.cheque_transaction.note,
                    "status": this.state.selected,
                    "clientMutationId": "CT" + Math.random().toString(36),
                    "chequeDocuments": image_upload ? [
                        {
                            "chequeDocumentsId": "",
                            "fileName": image_upload['name'],
                            "fileUpload": fileUpload,
                            "status": ""
                        }
                    ] : []
                }),
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({ loading: false });
                    if (response.createAndUpdateChequeTransaction.ok) {
                        Swal.fire(i18next.t("cheque_transaction:Success"), '', 'success').then((result) => {
                            this.setState({ redirectToList: true, loading: false });
                        })
                    } else {
                        Swal.fire(i18next.t("cheque_transaction:Unsuccess"), response.createAndUpdateChequeTransaction.message, 'warning').then((result) => {
                            this.setState({ loading: false });
                        })
                    }
                },
                onError: (err) => {
                    Swal.fire('server error', '', 'error').then((result) => {
                        this.setState({ loading: false });
                    })
                },
            },
        )
    }
    getValueSelect = (status) => {
        switch (status) {
            case 'wait':
                return { value: 'wait', label: 'Pending Clearance/Printing of Cheque' }
            case 'clearing':
                return { value: 'clearing', label: 'Passed Through Cheque Payment' }
            case 'void':
                return { value: 'void', label: 'Cheque Returned' }
            default:
                return []
        }
    }

    handleChange = (changed_value) => {
        this.setState({ selected: changed_value['value'] });
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/finance/cheque/list/all" />
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <form id="createChequeTransaction" onSubmit={this.onSubmit}>
                        <Translation>
                            {t =>
                                <div className="container-fluid box">
                                    <BackButtonIcon
                                        LinkBack={"/accounting/finance/cheque/list/all"}
                                        LinkText={t('cheque:cheque_transaction')}
                                        SpanWordRed={t("accountWarning:finance_cheque_clearing")}
                                    />
                                    <div className="content-inner">
                                        <div className="d-flex justify-content-center">
                                            <div className="col-8" >
                                                <div className="d-flex justify-content-end align-items-center mt-6">
                                                    <H5 className='mb-2'>{i18next.t("ChequeTransaction:Status")}:</H5>
                                                    <div className='col-3' >
                                                        <Select
                                                            options={this.state.options?.map(ex => ({ ...ex, label: i18next.t("ChequeTransaction:" + ex.label) }))}
                                                            value={this.getValueSelect(this.state.selected)[0]?.map(ex => ({ ...ex, label: i18next.t("ChequeTransaction:" + ex.label) }))}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-3 mt-6">
                                                    <H5 className='mb-2'>{i18next.t("ChequeTransaction:Recording Date")}</H5>
                                                    {/* <DatePickerAdapter id="cheque_transaction.date"
                                                className="form-control mt-2" name="cheque_transaction.date"
                                                selected={this.state.cheque_transaction.date}
                                                onChange={this.handleInputChange}
                                                require /> */}
                                                    {/* สลับ input date to chequeDate */}
                                                    <DatePickerAdapter
                                                        id="cheque_transaction.chequeDate"
                                                        className="form-control mt-2" name="cheque_transaction.chequeDate"
                                                        selected={this.state.cheque_transaction.chequeDate}
                                                        onChange={this.handleInputChange}
                                                        require />
                                                </div>
                                                <div className="col-6 mt-6">
                                                    <H5 className='mb-2'>{i18next.t("createChequeTransaction:Select Cheque Control Register")}</H5>
                                                    <SearchSelect onChange={this.handleInputChange}
                                                        value={this.state.cheque_transaction.bankAccountId}
                                                        name="cheque_transaction.bankAccountId"
                                                        placeholder={i18next.t("createChequeTransaction:Select Cheque Control Register")}
                                                        queryObject={this.state.bank_list}
                                                        keyOfValue="id"
                                                        require
                                                        keyOfLabel="docNumber" />
                                                </div>
                                                <div className="col-6 mt-6">
                                                    <H5 className='mb-2'>{i18next.t("createChequeTransaction:Bank")}</H5>
                                                    <SearchSelect onChange={this.handleInputChange}
                                                        value={this.state.cheque_transaction.bankAccountId}
                                                        name="cheque_transaction.bankName"
                                                        placeholder={i18next.t("createChequeTransaction:Bank")}
                                                        queryObject={this.state.bank_list}
                                                        keyOfValue="id"
                                                        require
                                                        disabled
                                                        keyOfLabel="bankName" />
                                                </div>
                                                <div className="col-6 mt-6">
                                                    <H5 className='mb-2'>{i18next.t("createChequeTransaction:Account Number")}</H5>
                                                    <SearchSelect onChange={this.handleInputChange}
                                                        value={this.state.cheque_transaction.bankAccountId}
                                                        name="cheque_transaction.bankAccountId"
                                                        placeholder={i18next.t("createChequeTransaction:Account Number")}
                                                        queryObject={this.state.bank_list}
                                                        keyOfValue="id"
                                                        require
                                                        disabled
                                                        keyOfLabel="accountNumber" />
                                                </div>
                                                <div className="col-6 mt-6">
                                                    <H5 className='mb-2'>{i18next.t("createChequeTransaction:Branch")}</H5>
                                                    <SearchSelect onChange={this.handleInputChange}
                                                        value={this.state.cheque_transaction.bankAccountId}
                                                        name="cheque_transaction.bankAccountId"
                                                        placeholder={i18next.t("createChequeTransaction:Branch")}
                                                        queryObject={this.state.bank_list}
                                                        keyOfValue="id"
                                                        require
                                                        disabled
                                                        keyOfLabel="branch" />
                                                </div>

                                                <div className="col-12 mt-6">
                                                    <H4 className='mb-6' >{i18next.t("createChequeTransaction:Cheque Information")}</H4>
                                                    <Line />
                                                </div>

                                                <div className="col-3 mt-6">
                                                    <H5>{i18next.t("createChequeTransaction:Cheque Due Date")}</H5>
                                                    {/* <DatePickerAdapter
                                                id="cheque_transaction.chequeDate"
                                                className="form-control mt-2" name="cheque_transaction.chequeDate"
                                                selected={this.state.cheque_transaction.chequeDate}
                                                onChange={this.handleInputChange}
                                                require /> */}
                                                    {/* สลับ input chequeDate to date */}
                                                    <DatePickerAdapter id="cheque_transaction.date"
                                                        className="form-control mt-2" name="cheque_transaction.date"
                                                        selected={this.state.cheque_transaction.date}
                                                        onChange={this.handleInputChange}
                                                        require />
                                                </div>

                                                <div className="d-flex justify-content-between mt-6 col-6 ">
                                                    <div className="col-6" style={{ paddingLeft: 0, marginLeft: 0 }} >
                                                        <H5>
                                                            <input type="checkbox" onChange={this.checkboxCustomCheque}
                                                                className="check-box mr-3"
                                                                name={"cheque_transaction.checkboxCustomCheque"}
                                                                id="cheque_transaction.checkboxCustomCheque"
                                                                checked={this.state.cheque_transaction.checkboxCustomCheque} /> {i18next.t("createChequeTransaction:Custom")}</H5>

                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-between col-6 ">

                                                    {this.state.cheque_transaction.checkboxCustomCheque === false ?
                                                        <React.Fragment>
                                                            <div className="col-6 mt-6" style={{ paddingLeft: 0, marginLeft: 0 }} >
                                                                <H5 className='mb-2'>{i18next.t("createChequeTransaction:Chequebook")}</H5>
                                                                <SearchSelect onChange={this.handleInputChange}
                                                                    value={this.state.cheque_transaction.ChequeDetailsId}
                                                                    name="cheque_transaction.chequeDetails.ChequeDetailsId"
                                                                    placeholder={i18next.t("createChequeTransaction:Chequebook")}
                                                                    queryObject={this.state.cheque_transaction.chequeDetails.edges || []}
                                                                    keyOfValue="id"
                                                                    require
                                                                    keyOfLabel="noNumber" />
                                                            </div>

                                                            <div className="col-6 mt-6" style={{ paddingRight: 0, marginRight: 0 }}>
                                                                <H5 className='mb-2'>{i18next.t("createChequeTransaction:Cheque Number")}</H5>
                                                                <SearchSelect onChange={this.handleInputChange}
                                                                    value={this.state.cheque_transaction.ChequeNoId}
                                                                    name="cheque_transaction.chequeNo.ChequeNoId"
                                                                    placeholder={i18next.t("createChequeTransaction:Cheque Number")}
                                                                    queryObject={this.state.cheque_transaction.chequeNo.edges || []}
                                                                    keyOfValue="id"
                                                                    require
                                                                    keyOfLabel="noNumber" />
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <div className="col-6 mt-6" style={{ paddingLeft: 0, marginLeft: 0 }} >
                                                                <H5 className='mb-2'>{i18next.t("createChequeTransaction:Chequebook")}</H5>
                                                                <input type="text" className="form-control mt-2" name={"cheque_transaction.ChequeDetailsCustom"}
                                                                    id="cheque_transaction.ChequeDetailsCustom" value={this.state.cheque_transaction.ChequeDetailsCustom}
                                                                    onChange={this.handleInputChange} />
                                                            </div>

                                                            <div className="col-6 mt-6" style={{ paddingRight: 0, marginRight: 0 }}>
                                                                <H5 className='mb-2'>{i18next.t("createChequeTransaction:Cheque Number")} </H5>
                                                                <input type="text" className="form-control mt-2" name={"cheque_transaction.ChequeNoCustom"}
                                                                    id="cheque_transaction.ChequeNoCustom" value={this.state.cheque_transaction.ChequeNoCustom}
                                                                    onChange={this.handleInputChange}
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) { event.preventDefault(); }
                                                                    }
                                                                    }
                                                                    maxLength={8}
                                                                    required />
                                                                <span className='text-red'>*{i18next.t("createChequeTransaction:Cheque Number Must Not Be the Same As One Already in the System")}</span>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>

                                                <div className="col-6 mt-6">
                                                    <H5>{i18next.t("createChequeTransaction:Amount (Baht)")}</H5>
                                                    <input type="text" className="form-control mt-2" name={"cheque_transaction.price"}
                                                        id="cheque_transaction.price" value={this.state.cheque_transaction.price}
                                                        onChange={this.handleInputChange} required />
                                                </div>

                                                <div className="col-6 mt-6">
                                                    <h5 className='mb-2'>{i18next.t("createChequeTransaction:Creditor Name")}</h5>
                                                    <SelectContactDropDown
                                                        contact_type=''
                                                        handleInputChange={this.handleInputChange}
                                                        name="cheque_transaction.contact.id"
                                                        payment_type="payable"
                                                        contact={this.state.cheque_transaction.contact.id}
                                                        asset={true}
                                                        require
                                                        contact_key={true}
                                                        get_only_name={false}
                                                        notOnContact={true}
                                                    />
                                                </div>
                                                {this.state.cheque_transaction.contact.id === "other_select" &&
                                                    <div className="col-6 mt-6">
                                                        <input type="text" className="form-control"
                                                            name="cheque_transaction.unknownContact"
                                                            value={this.state.cheque_transaction.unknownContact}
                                                            onChange={this.handleInputChange}
                                                            required={true}
                                                        />
                                                    </div>}

                                                <div className="col-6 mt-6">
                                                    <H5>{i18next.t("createChequeTransaction:Reference Document Number")}<HS6 fontSize='0.8rem' color='#dc3545' > *{i18next.t("createChequeTransaction:Limited to No More Than 25 Characters")}</HS6></H5>
                                                    <div className="d-flex inputRefContainer">
                                                        <div className='containerPretext'>
                                                            <input
                                                                className='inputPretext'
                                                                value={i18next.t("ChequeTransaction:Carried Forward Cheque Payment")}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="input-group mb-3 col-9 mt-3">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">{i18next.t("createChequeTransaction:Carried Forward Cheque Payment")} -</span>
                                                            </div>
                                                            <input type="text"
                                                                name={"cheque_transaction.refNumber"}
                                                                className="form-control input-docNumber"
                                                                id="cheque_transaction.refNumber"
                                                                value={this.state.cheque_transaction.refNumber}
                                                                onChange={this.handleInputChange}
                                                                maxLength={25}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* <input
                                                type="text"
                                                className="form-control mt-2"
                                                disabled={true}
                                                value="เช็คจ่ายยกมา"
                                            />
                                            <div className="input-group mb-3 mt-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                    เช็คจ่ายยกมา -
                                                    </span>
                                                </div>
                                                <input type="text"
                                                    name={"cheque_transaction.refNumber"}
                                                    className="form-control input-docNumber"
                                                    id="cheque_transaction.refNumber"
                                                    value={this.state.cheque_transaction.refNumber}
                                                    onChange={this.handleInputChange}
                                                    maxLength={25}
                                                    required 
                                                />
                                            </div> */}

                                                </div>

                                                <div className="col-6 mt-6">
                                                    <H5>{i18next.t("createChequeTransaction:Date of Cheque Clearance")}</H5>
                                                    <DatePickerAdapter
                                                        id="cheque_transaction.depositDate"
                                                        className="form-control mt-2"
                                                        name="cheque_transaction.depositDate"
                                                        selected={this.state.cheque_transaction.depositDate}
                                                        onChange={this.handleInputChange} />
                                                </div>

                                                <div className="col-6 mt-6">
                                                    <Translation>
                                                        {t =>
                                                            <label
                                                                className={this.state.image_upload.name ? "btn btn-success mb-0 cursor font-medium mr-3" : "btn btn-secondary mb-0 cursor font-medium mr-3"}>
                                                                {this.state.image_upload.name ? <>{t("cheque_transaction:Done")}</> : <>{t("cheque_transaction:Attach file")}</>}
                                                                <input type="file" name="image_upload" onChange={this.docInputChange} hidden />
                                                            </label>}
                                                    </Translation>
                                                    <div className='mt-2' style={{ fontFamily: 'medium' }}>
                                                        <small className='text-red' >{i18next.t("Allaction:Each file should not exceed 10 MB")} {i18next.t("Allaction:and just")} .pdf .jpg</small>
                                                    </div>
                                                    {this.state.image_upload.name}
                                                </div>
                                                <div className="d-flex justify-content-end mt-12 col-12">
                                                    <button type="submit" className="btn add" style={{ backgroundColor: '#1567B4', color: '#FFFFFF' }} >{i18next.t("Allaction:save")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                        </Translation>
                    </form>
                </WrapperContent>

            </Wrapper>

        )
    }
}

export default CreateChequeTransaction;