export function listDataFilter() {
      return [
            { "node": { number: 1, label: "หมายเหตุที่/Remark No. 1", value: "111-112" } },
            { "node": { number: 2, label: "หมายเหตุที่/Remark No. 2", value: "1131" } },
            { "node": { number: 3, label: "หมายเหตุที่/Remark No. 3", value: "1132-1140-115" } },
            { "node": { number: 4, label: "หมายเหตุที่/Remark No. 4", value: "1210-1220" } },
            { "node": { number: 5, label: "หมายเหตุที่/Remark No. 5", value: "1230-1240-1310" } },
            { "node": { number: 6, label: "หมายเหตุที่/Remark No. 6", value: "2110-2111" } },
            { "node": { number: 7, label: "หมายเหตุที่/Remark No. 7", value: "2121-2122-2123" } },
            { "node": { number: 8, label: "หมายเหตุที่/Remark No. 8", value: "2230" } },
            { "node": { number: 9, label: "หมายเหตุที่/Remark No. 9", value: "2220" } },
            { "node": { number: 10, label: "หมายเหตุที่/Remark No. 10", value: "2125" } },
            { "node": { number: 11, label: "หมายเหตุที่/Remark No. 11", value: "2210" } },
            { "node": { number: 12, label: "หมายเหตุที่/Remark No. 12", value: "2124-2126" } },
            { "node": { number: 13, label: "หมายเหตุที่/Remark No. 13", value: "2300-2400-2410" } },

            { "node": { number: 14, label: "รายรับค่าส่วนกลาง / Revenue - Common area fees", value: "4100-01" } },
            { "node": { number: 15, label: "รายรับค่าน้ำประปา / Revenue - Waer supply", value: "4100-04" } },
            { "node": { number: 16, label: "รายรับค่าใช้ประโยชน์พื้นที่ส่วนกลาง / Revenue Common area utilization", value: "4200" } },
            { "node": { number: 17, label: "รายรับอื่นๆ / Other Revenue", value: "other_income" } },

            { "node": { number: 18, label: "ต้นทุนขายสุทธิ / Cost of Goods Sold", value: "5100" } },
            { "node": { number: 19, label: "เงินเดือนเเละค่าจ้าง / Salaries and wages", value: "5200" } },
            { "node": { number: 20, label: "รายจ่าย - ตามสัญญา / Expense - by agreement", value: "5300" } },
            { "node": { number: 20, label: "รายจ่ายดำเนินงานและสำนักงาน / Operation expense - Office", value: "5400" } },
            { "node": { number: 22, label: "รายจ่ายสาธารณูปโภค / Operation expense - Utility", value: "5500" } },
            { "node": { number: 23, label: "รายจ่าย - ซ่อมเเซมและบำรุงรักษา / Operation expense - Maintenance & Repair", value: "5600" } },
            { "node": { number: 24, label: "รายจ่ายค่าเสื่อมราคาและค่าตัดจำหน่าย / Depreciation and Amortization expense", value: "5700" } },
            { "node": { number: 25, label: "ค่าใช้จ่ายอื่น ๆ / Other expenses", value: "other_expense" } },
      ]
}
