import React, {Component} from 'react';
import {Switch} from "react-router-dom";
import PrivateRoute from '../libs/privateRoute';
import FeedbackList from "./feedbackList"

class Feedback extends Component {

    render() {
    return (
        <Switch>
            <PrivateRoute exact path={`${this.props.match.url}/feedback-dashboard/:status?`} component={FeedbackList}/>
        </Switch>
    )
    }
}

export default Feedback;