import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import {fetchQuery} from "relay-runtime";
import SummaryListTable from "./summaryListTable";
import Loading from "../../libs/loading";

const query = graphql`
    query expenditureListTableQuery ($typeOfReport: String, $start_date: DateTime, $end_date: DateTime, $month_count: Int) {
        chartOfAccountAccrualBasis(typeOfReport: $typeOfReport, startDate: $start_date, endDate: $end_date, monthCount: $month_count) {
            edges {
                node {
                    id
                    name nameEn
                    chartOfAccountCode
                    col1Debit col2Debit col3Debit col4Debit col5Debit col6Debit col7Debit col8Debit col9Debit col10Debit col11Debit col12Debit
                    col1Credit col2Credit col3Credit col4Credit col5Credit col6Credit col7Credit col8Credit col9Credit col10Credit col11Credit col12Credit
                }
            }
            totalCount
        }
    }
`;

class ExpenditureListTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            month_list: [],
            chartOfAccountAccrualBasis: [],
            summary: [],
            total_row: [],
            list_data_loop: Array.from(new Array(this.props.state.month_count+1)),
            loading: true,
        };
        this.setPeriod = this.setPeriod.bind(this);
        this.queryChartOfAccount = this.queryChartOfAccount.bind(this);
    }

    componentWillMount() {
        this.setPeriod();
    }

    queryChartOfAccount() {
        this.setState({
            loading: true,
        })
        this.setState({summary: [], total_row: []}, () => {

            fetchQuery(environment, query, {
                end_date: this.props.state.end_period,
                start_date: this.props.state.start_period,
                month_count: this.props.state.month_count,
                typeOfReport: this.props.typeOfReport.code
            }).then(data => {
                if (_.isObject(data.chartOfAccountAccrualBasis)) {
                    let summary = [];
                    let total_row = [];
                    let data_set = _.cloneDeep(data);
                    data_set.chartOfAccountAccrualBasis.edges.forEach((chart, index) => {
                        let node = chart.node;
                        
                        let total = 0
                        for (let i = 0; i < this.props.state.month_count+1; i++) {
                            // let total = node.col1Debit + node.col2Debit + node.col3Debit + node.col4Debit
                            //     + node.col5Debit + node.col6Debit + node.col7Debit + node.col8Debit
                            //     + node.col9Debit + node.col10Debit + node.col11Debit + node.col12Debit
                            //     - node.col1Credit - node.col2Credit - node.col3Credit - node.col4Credit
                            //     - node.col5Credit - node.col6Credit - node.col7Credit - node.col8Credit
                            //     - node.col9Credit - node.col10Credit - node.col11Credit - node.col12Credit;
                            total += (node['col' + (i + 1) + 'Debit'] || 0) - (node['col' + (i + 1) + 'Credit'] || 0)
                        }

                        _.set(total_row, index, total);

                        let empty_value = false;
                        _.set(chart, 'node.empty_value', empty_value);

                        for (let i = 0; i < this.props.state.month_count+2; i++) {
                            if (i === this.props.state.month_count+1) {
                                _.set(summary, i, _.get(summary, i, 0) + total)

                            } else {
                                _.set(summary, i, _.get(summary, i, 0) + (node['col' + (i + 1) + 'Debit'] || 0) - (node['col' + (i + 1) + 'Credit'] || 0))
                            }
                        }
                    });

                    this.setState({
                        chartOfAccountAccrualBasis: data_set.chartOfAccountAccrualBasis.edges,
                        summary: summary,
                        total_row: total_row,
                    }, () => this.props.updateSummary('total_expenditure', this.state))
                }
                this.setState({
                    loading: false,
                })
            })

        });


    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.start_period !== this.props.state.start_period || prevProps.state.end_period !== this.props.state.end_period) {
            this.setPeriod();
        }
    }

    setPeriod() {
        this.setState({
            loading: true,
        })
       let start_month = this.props.state.start_period.getMonth();
        let start_year = this.props.state.start_period.getFullYear();
        let month_list = [this.props.state.start_period];
        let list = Array.from(new Array(this.props.state.month_count));
        let list_data_loop = Array.from(new Array(this.props.state.month_count+2));

        list.map((month,index) => {
            let new_date = new Date(start_year, start_month + (index+1) );
            if (index === this.props.state.month_count-1){
                new_date = this.props.state.end_period
            }
            return month_list.push(new_date);
        });
        this.setState({month_list: month_list, list_data_loop:list_data_loop, loading: false});
        this.queryChartOfAccount();
    }

    render() {
        return (
            this.state.loading ?
            <Loading/>
            :
            <React.Fragment>
                <tbody>
                {this.props.typeOfReport.header &&
                <tr>
                     <td width={360}><strong>2. รายจ่าย / Expense</strong></td>
                    {this.state.month_list.map((month, index) => {
                        return (
                            <td width={100} key={'td-expense-'+index}/>
                        )
                    })}
                    <td/>
                </tr>
                }
                <tr>
                    <td width={360}><strong>{this.props.typeOfReport.name}</strong></td>
                    {this.state.month_list.map((month, index) => {
                        return (
                            <td width={100} key={'td-expense-header-' + index}/>
                        )
                    })}
                    <td/>
                </tr>
                {this.state.chartOfAccountAccrualBasis.map((chart, index) => {
                    if (chart.node.empty_value) {
                        return <React.Fragment key={'data-expense-td-'+index}/>;
                    }
                    return (
                        <React.Fragment key={chart.node.id}>
                            <tr>
                                <td>&nbsp;&nbsp;&nbsp;&nbsp;{chart.node.name}{chart.node.nameEn ? " / "+chart.node.nameEn: ""}</td>
                                {this.state.month_list.map((month, index) => {
                                    let data_index = index+1;
                                    return (
                                        <td className="text-right" key={'data-expense-td-'+index}>
                                            {numberWithComma(chart.node['col'+data_index+'Debit'] - chart.node['col'+data_index+'Credit'])}
                                        </td>
                                    )
                                })}
                                <td className="text-right">{numberWithComma(this.state.total_row[index])}</td>
                            </tr>
                        </React.Fragment>

                    )
                })}
                <SummaryListTable text_summary={this.props.typeOfReport.summary_name} summary={this.state.summary}
                                  list_data_loop={this.state.list_data_loop}/>
                </tbody>
            </React.Fragment>
        )
    }
}

export default ExpenditureListTable;
