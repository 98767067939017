
import '../../../document/styles/main.scss';
import React, {Component} from 'react';

class CollectionLawFrimSettingViewForm extends Component {

    constructor(props){
        super(props);
        this.state = {

        }
    }

    componentDidUpdate(){

    }

    render() {


        return (
            <React.Fragment>
                <div className="print-page-a4" id="setting-collection-view-form1" >
                    <div className='d-flex justify-content-center'>
                        <div className='d-flex flex-column logoContainer'>
                            <img className="logo mb-2" src={process.env.PUBLIC_URL + '/images/collectionLaw/houseCondoLawyer.png'}/>
                            <p>วันที่  .........................</p>
                        </div>
                    </div>
                    <p>
                        &nbsp;&nbsp;โดยหนังสือฉบับนี้ ข้าพเจ้า{"....................................................."}โดย นาย/นางสาว..........................................อยู่บ้านเลขที่....................................โทรศัพท์...................................
                        ซึ่งต่อไปจะขอเรียกว่า <b>“ผู้มอบอำนาจ”</b> ทำหนังสือฉบับนี้เพื่อให้ปรากฏและเป็นที่ยอมรับต่อ
                        สาธารณชนทั่วไปว่า ผู้มอบอำนาจขอมอบอำนาจให้............................อยู่บ้านเลขที่............................ซึ่ง
                        ต่อไปนี้จะขอเรียกว่า <b>“ผู้รับมอบอำนาจ”</b> มีอำนาจกระทำการแทนข้าพเจ้าได้ดังนี้
                        ข้าพเจ้าได้ดังนี้
                    </p>
                    <div className='ml-10'>
                        <ol>
                            <li>ให้มีอำนาจดำเนินคดีกับ.............................................ต่อศาลที่มีเขตอำนาจ ทั้งคดี
                            แพ่งและคดีอาญาจนกว่าคดีจะถึงที่สุด</li>
                            <li>ดำเนินกระบวนพิจารณาไปในทางจำหน่ายสิทธิของข้าพเจ้าได้ด้วย เช่น การ
                            ยอมรับตามที่คู่ความอีกฝ่ายหนึ่งเรียกร้อง การถอนฟ้อง การถอนคำร้องทุกข์ การ
                            ประนีประนอมยอมความการสละสิทธิในการอุทธรณ์ หรือฎีกา หรือในการขอ
                            พิจารณาคดีใหม่ตลอดจนการแต่งตั้งอนุญาโตตุลาการชี้ขาดคดี</li>
                            <li>ดำเนินกระบวนพิจารณาในชั้นบังคับคดี ตลอดจนการนำเจ้าพนักงานบังคับคดีไป
                            ทำการยึด และ/หรืออายัดทรัพย์ของลูกหนี้ตามคำพิพากษา รับรู้การขายทอดตลาด
                            ทรัพย์สินที่ยึด ยื่นคำร้องขอรับชำระหนี้จำนอง หรือคำร้องขอเฉลี่ยทรัพย์ของลูกหนี้ที่
                            เจ้าพนักงานบังคับคดียึดไว้ในคดีอื่น ตลอดจนยื่นคำร้องขอรับชำระหนี้ในคดีล้ม
                            ละลายด้วย</li>
                            <li>ยื่นคำร้อง คำขอ คำแถลง ขอคัดสำเนาสรรพเอกสารต่าง ๆ ในสำนวนความ พร้อม
                            ทั้งให้มีอำนาจรับเงิน รับเอกสาร หรือสิ่งของอื่นใดจากศาล เจ้าพนักงานศาล หรือคู่
                            ความอีกฝ่ายหนึ่ง หรือบุคคลอื่นใดแทนข้าพเจ้าได้</li>
                            <li>แต่งตั้งตัวแทนช่วง หรือทนายความตามวัตถุประสงค์ เพื่อให้กระทำการดังกล่าวข้าง
                            ต้นด้วย</li>
                        </ol>
                        <p className='ml-6'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;การกระทำของผู้รับมอบอำนาจซึ่งได้กระทำไปภายในขอบเขตแห่งหนังสือมอบ
                        อำนาจนี้ให้ถือว่าเป็นการกระทำของผู้มอบอำนาจทุกประการ ผู้มอบอำนาจและผู้รับมอบ
                        อำนาจเข้าใจข้อความหนังสือมอบอำนาจนี้โดยตลอดแล้ว จึงลงลายมือชื่อไว้เป็นหลัก
                        ฐานต่อหน้าพยาน</p>
                    </div>
                    <div className='d-flex flex-row justify-content-between mt-12'>
                        <div>
                            <div className='mb-8'>
                                <p>ลงชื่อ..................................................ผู้มอบอำนาจ</p>
                                <pre>(                                   )</pre>
                            </div>
                            <div>
                               <p>ลงชื่อ..................................................ผู้รับมอบอำนาจ</p>                            
                                <pre>(                                   )</pre>
                                                               
                            </div>
                        </div>
                        <div>
                            <div className='mb-8'>
                                <p>ลงชื่อ..................................................พยาน</p>
                                 <pre>(                              )</pre>
                            </div>
                            <div>
                                <p>ลงชื่อ..................................................พยาน</p>
                                 <pre>(                              )</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }                   
}

export default CollectionLawFrimSettingViewForm;