import _ from "lodash";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import DepositInterest from "./depositInterest";
import { graphql } from "babel-plugin-relay/macro";
import CreateBankUnitMutation from "./createBankUnit";
import UpdateBankUnitMutation from "./updateBankUnit";
import Swal from "sweetalert2";
import './deposit-interest.scss'
import UpdateNotificationMutation from "./updateNotification";
import i18next from "i18next";


const mutation = graphql`
  mutation depositInterestBankMutation ($input: CreateBankUnitInput!)  {
    createBankUnit (input: $input) {
        ok
        message
    }
  }
`;

const newTransaction = {
    node : {
        automaticCal : false,
        numberOfMonth : '',
        issuedDate : new Date(),
        dueDate : new Date(new Date().setMonth(new Date().getMonth() + 1)),
        interestPerYear : '',
        price : '',
        calculationDate : ''
    }
}

class DepositInterestBank extends Component {

    constructor(props){
        super(props);
        this.state = {
            getShowAll : false,
            depositInterestList : this.props.bankDetail.bankUnit.edges ? this.props.bankDetail.bankUnit.edges : [],
            edit : false,
            bankDetail : this.props.bankDetail,
            createBankUnitSuccess:false,
            updateBankUnitSuccess:false,
            status : this.props.status,
            showNotification : true,
        }
    }

    componentDidMount (){
        if(this.state.depositInterestList.length > 0){
            let depositInterest = []
            _.forEach(this.state.depositInterestList, (bank,index) => {
                depositInterest.push({node : {...bank.node ,  defaultAutomatic : bank.node.id ? bank.node.automaticCal : false}})
            })
            this.setState({
                depositInterestList : depositInterest
            })
        }
        
    }

    componentDidUpdate(prevProps,prevState){
        if(this.props.bankDetail !== prevProps.bankDetail){
            this.setState({
                bankDetail : this.props.bankDetail,
                depositInterestList : this.props.bankDetail.bankUnit.edges
            })
        }
        if (this.state.createBankUnitSuccess !== prevState.createBankUnitSuccess || this.state.updateBankUnitSuccess !== prevState.updateBankUnitSuccess) {
            if (this.state.createBankUnitSuccess && this.state.updateBankUnitSuccess) {
                Swal.fire(i18next.t("depositInterestDashBoard:Interest Recording Successful"), '', 'success').then(()=>{
                    this.setState({loading: false , edit : false});
                    this.props.reQuery()
                });
            }
        }
        if(prevProps.status !== this.props.status){
            this.setState({
                edit : false,
                getShowAll : false
            })
        }
    }

    addTransaction = () => {
        this.setState({ depositInterestList: [...this.state.depositInterestList,  newTransaction] });
    }

    onEdit = () => {
        this.setState({ edit : !this.state.edit})
    }

    handleChange = (event) => {
        let e = _.cloneDeep(event)
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                e.target.name,
                e.target.value
            )
        });
    }

    toggleChange = (index) => {
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                "depositInterestList["+index+"].node.automaticCal",
                !this.state.depositInterestList[index].node.automaticCal
            )
        });
    }
    
    addFirstTransaction = () => {
        this.setState({ 
            depositInterestList: [...this.state.depositInterestList,  newTransaction] ,
            getShowAll : true,
            edit : true
        });
    }

    onSubmit = async (e) => {
        e.preventDefault();
        let bankUnitCreate = []
        let bankUnitUpdate = []
        this.setState({loading: true});
        Swal.fire({
            title: i18next.t("depositInterestDashBoard:Once the transaction is automatically recorded in the account, it cannot be edited."),
            type: 'warning',
            iconColor: '#FFBD2D' ,
            showCancelButton: true,
            confirmButtonColor: '#1567B4',
            cancelButtonColor: '#BFBFBF',
            confirmButtonText: i18next.t("Allaction:Confirm"),
            cancelButtonText: i18next.t("Allaction:cancel"),
            reverseButtons : true,
        }).then((result) => {
            if(result.value){
                for(const interest of this.state.depositInterestList){
                    if(interest.node.id){
                        bankUnitUpdate.push({
                            bankUnitId : interest.node.id,
                            automaticCal : interest.node.automaticCal,
                            issuedDate : interest.node.issuedDate,
                            dueDate : interest.node.dueDate,
                            interestPerYear : parseFloat(interest.node.interestPerYear).toFixed(2),
                            price : parseFloat(interest.node.price).toFixed(2),
                            calculationDate : (interest.node.calculationDate === '' && this.props.startSite < new Date(interest.node.issuedDate) ) ? null : interest.node.calculationDate
                        })
                    }else{
                        bankUnitCreate.push({
                            bankAccountId : this.state.bankDetail.id,
                            automaticCal : interest.node.automaticCal,
                            issuedDate : interest.node.issuedDate,
                            dueDate : interest.node.dueDate,
                            interestPerYear : parseFloat(interest.node.interestPerYear).toFixed(2),
                            price : parseFloat(interest.node.price).toFixed(2),
                            calculationDate : (interest.node.calculationDate === '' && this.props.startSite < new Date(interest.node.issuedDate)) ? null : interest.node.calculationDate
                        })
                    }
                }
        
                this.setState({
                    createBankUnitSuccess : bankUnitCreate.length > 0 ? false : true,
                    updateBankUnitSuccess : bankUnitUpdate.length > 0 ? false : true
                }, () => {
                    if(bankUnitCreate.length > 0){
        
                        const variables = {
                            input: {
                                bankUnit : bankUnitCreate
                            }
                        };
                        CreateBankUnitMutation(variables,this.onSuccess,this.onError)
                    }
                    if(bankUnitUpdate.length > 0){
                        const variables = {
                            input: {
                                bankUnit : bankUnitUpdate
                            }
                        };
                        UpdateBankUnitMutation(variables,this.onSuccess,this.onError)
                    }
                })
            }else{
                this.setState({loading : false})
            }
        })
    }

    onSuccess = (response) => {
        if ((response.createBankUnit && response.createBankUnit.ok) || (response.updateBankUnit && response.updateBankUnit.ok)) {
            if(response.createBankUnit && response.createBankUnit.ok){
                this.setState({createBankUnitSuccess:true})
            }else if(response.updateBankUnit && response.updateBankUnit.ok){
                this.setState({updateBankUnitSuccess:true})
            }
        }
         else {
            if(response.createBankUnit?.message || response.updateBankUnit?.message){
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createBankUnit?.message || response.updateBankUnit?.message, 'warning').then( () => {
                    this.setState({loading: false});
                })
            }else{
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'warning').then( () => {
                    this.setState({loading: false});
                })          
            }

        }
    }

    onError = () => {
         Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error').then(() => {
            this.setState({loading: false});
         })
    }

    onCancel = () => {
        this.setState({
            edit : false
        }, () => this.props.reQuery())
    }

    getNotificationNum = () => {
        let count = 0
        if(this.state.depositInterestList && this.state.depositInterestList.length > 0){
            _.forEach(this.state.depositInterestList, bank => {
                if(bank.node.bankNotification?.edges[0]?.node.status === true){
                    count ++
                }
            })
            return count
        }else{
            return count
        }
    }

    setTransactionList = (newTransaction) => {
        this.setState({ depositInterestList: newTransaction });
    }

    onErrorNotification = (response) => {
        if (!(response.readBankUnit && response.readBankUnit.ok)) {
            Swal.fire(i18next.t("depositInterestDashBoard:Unable to update the notification system"), '', 'warning')
        }
        else if(!response){
            Swal.fire(i18next.t("depositInterestDashBoard:Unable to update the notification system"), '', 'warning')
        }
    }

    handleShowAll = () => {
        this.setState({
            getShowAll : true
        }, () => {
            for (const bankUnit of this.state.depositInterestList){
                if(bankUnit.node.bankNotification.edges[0]?.node.status === true){
                    let variables = {
                        input : {
                            bankNotificationId : bankUnit.node.bankNotification.edges[0].node.id
                        }
                    }
                    UpdateNotificationMutation(variables,this.onErrorNotification, this.onErrorNotification)
                    this.setState({
                        showNotification : false
                    })
                }
            } 
        })
    }

    render() {
        const notificationCount = this.getNotificationNum()
        return(
            <form onSubmit={this.onSubmit}>
            <Translation>
                {t =>
                    <div className="bank-box">
                        <div className="row p-2 m-4">
                            <div className="col-2 col-md-3 mt-3 mb-3">
                                <span>{this.state.bankDetail.docNumber}</span> <br/>
                                <span>{this.state.bankDetail.accountNumber}</span>
                                <div className="row mt-3">
                                    <div className="col-2">
                                        <img src={this.state.bankDetail.bankLogo}  style={{width: 40, height: 40}}/>
                                    </div>
                                    <div className="col">
                                        <span>{this.state.bankDetail.bankName}</span> <br/>
                                        <span>{this.state.bankDetail.branch}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row col mt-3 mb-3">
                                <div className="col-12">
                                    <h6>{this.state.bankDetail.accountName} 
                                        {(notificationCount !== 0 && this.state.showNotification) && 
                                            <p className="ml-2 font-weight-bold badge text-light bg-danger">{notificationCount}</p>}
                                    </h6> 
                                    <h6>{this.state.bankDetail.accountNameEn}</h6>
                                </div>
                                {this.state.getShowAll &&
                                    <React.Fragment>
                                        <div className="col-11">
                                            <DepositInterest 
                                                depositInterestList={this.state.depositInterestList} 
                                                edit={this.state.edit} 
                                                onEdit={this.onEdit} 
                                                handleChange = {this.handleChange}
                                                startSite={this.props.startSite}
                                                toggleChange ={this.toggleChange}
                                                status={this.props.status}
                                                setTransactionList = {this.setTransactionList}
                                            />
                                            {this.state.edit && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_bankunit_edit' }) &&
                                            <span className="span-blue cursor" onClick={() => this.addTransaction()}>+ {i18next.t("settingAll:Add list")}</span>}
                                        </div>
                                    </React.Fragment>
                                }
                                <div className="mt-auto col-12">
                                    {!this.state.getShowAll ?
                                        this.state.depositInterestList.length > 0 ?
                                        <span className="mt-auto span-blue cursor" onClick={() => this.handleShowAll()}>{i18next.t("depositInterestDashBoard:Show more")}</span> 
                                        :
                                        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_bankunit_create' }) && 
                                        <button className="text-center btn-add" onClick={() => this.addFirstTransaction()}>
                                            <span className="span-blue"> + {i18next.t("settingAll:Add list")}</span>
                                        </button>
                                        :
                                        !this.state.edit && <span className="mt-auto span-grey cursor" onClick={() => this.setState({getShowAll : false})}>{i18next.t("depositInterestDashBoard:Show less")}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        {this.state.edit &&  (_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_bankunit_edit' }) || _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_bankunit_create' })) &&
                        <div className="row p-2 m-4 text-right mb-3">
                            <div className="col-12 ">
                                <button className="btn btn-link mr-4"  type="button" onClick= {() => this.onCancel()}>ยกเลิก</button>
                                <button className="btn btn-primary" disabled={this.state.loading}  type="submit"  // onClick={() => this.onSubmit()}
                                >
                                    {this.state.loading &&
                                    <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                    {i18next.t("Allaction:save")}
                                </button>
                            </div>
                        </div>
                        }
                    </div>
                }
            </Translation>
            </form>
        );
    }



}
export default DepositInterestBank;