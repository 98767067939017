import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import GuaranteeMoneyReceived from './guaranteeMoneyReceived';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperGuaranteeMoneyReceivedQuery($id: ID!){
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        guaranteeMoneyReceived(id: $id) {
            id
            docNumber
            issuedDate
            status
            amount
            
            firstName
            lastName
            address district city province postalCode
            name
            taxIdNumber
            email
            phone
            note
            
            chartOfAccount{
                id
                chartOfAccountCode
                name
            }
            description
            
            contact {
                refNumber
                name
                taxIdNumber
                firstName
                lastName
                phone
                email
                typeOfCompany
                typeOfSupplier
                typeOfContact
                nameBranch
                registeredAddress
                registeredCountry
                registeredProvince
                registeredDistrict
                registeredCity
                registeredPostalCode
                residential {
                    id
                    size
                    ownershipRatio
                }
            }
            bankAccountTransaction{
                edges{
                    node{
                        id
                        price
                        date
                        imageSlip
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            cashDepositReceiveTransaction{
                edges{
                    node{
                        id
                        price
                    }
                }
            }
            chequeDeposit{
                edges{
                    node{
                        id
                        date
                        bankName
                        price
                        chequeNumber
                        branchNumber
                    }
                }
            }
        }
        userConfig {
            id languages
        }
    }
`;

class WrapperGuaranteeMoneyReceived extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
    }

    print() {
        window.print();
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Guarantee Money Received</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{
                        id: this.props.match.params.id,
                    }}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return <GuaranteeMoneyReceived guarantee_type={"guarantee"}
                                                           selfProject={props.selfProject}
                                                           contact={props.guaranteeMoneyReceived.contact}
                                                           sub_detail={props.guaranteeMoneyReceived}                                                           detail={props.guaranteeMoneyReceived}
                                                           bank_transaction={props.guaranteeMoneyReceived.bankAccountTransaction.edges}
                                                           cash_transaction={props.guaranteeMoneyReceived.cashDepositReceiveTransaction.edges}
                                                           remark={props.guaranteeMoneyReceived.note}
                                                           languages={props.userConfig.languages}
                                                           cheque_transaction={props.guaranteeMoneyReceived.chequeDeposit.edges}
                                                           editClearGuarantee={this.props.location.state}
                                                           address={props.guaranteeMoneyReceived}
                                                           clear_guarantee_money_received_petty_cash_channel={[]}
                                                           />
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperGuaranteeMoneyReceived;
