import React, {Component} from 'react';
import "../styles/genParcelA4.scss"
import {Helmet} from "react-helmet";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import _ from 'lodash'
import Loading from "../../libs/loading";
import jwtDecode from "jwt-decode";

const genInviteCode = graphql`
query genParcelA4Query($is_residential_active:Boolean, $status: String, $order:String, $start_date : DateTime, $end_date : DateTime) { 
    parcelViewer{
        allParcel(isResidentialActive:$is_residential_active, status: $status, order: $order, startDate: $start_date, endDate: $end_date){
            totalCount
            edges {
                node {
                    id
                    name
                    trackingNumber
                    serviceName
                    description
                    added
                    status
                    docNumber
                    residential{
                        name
                    }
                    
                }
            }
        }
    }
}
`;

class genParcelA4 extends Component {

    constructor(props) {
        super(props);
        this.state={
            status:props.history.location.state.status ==='all'?false:true,
            start_date:props.history.location.state.start_date,
            end_date:props.history.location.state.end_date,
            check_list:props.history.location.state.check_list,
            token: jwtDecode(window.localStorage.getItem('token')),
        }
        this.print = this.print.bind(this);
    }

    print() {
        window.print();
    }

    render() {
        return (
            <div id="gen-parcel-a4">
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>GenParcel</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>

                <QueryRenderer
                    environment={environment}
                    query={genInviteCode}
                    variables={{type_download: this.props.match.params.type || "",
                    is_residential_active:this.state.status,status:"not_receive",
                    start_date: new Date(this.state.start_date),
                    end_date: new Date(this.state.end_date),
                }}
                    cacheConfig={{use_cache: false}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            let parcelViewer = props.parcelViewer.allParcel.edges
                            if ( this.state.check_list.length > 0 ) {
                                parcelViewer = _.filter(props.parcelViewer.allParcel.edges, (parcel) => _.indexOf(this.state.check_list, parcel.node.id) !== -1)
                            }
                            if (parcelViewer.length === 0) {
                                alert('ไม่พบผู้ใช้ที่ยังไม่ได้ทำการดาวน์โหลดแอปจากรายการที่เลือก')
                            }
                            return (
                                <div className="print-page-a4">
                                    {parcelViewer.map((parcel) => {
                                        if (parcel.node.status === 'NOT_RECEIVE') {
                                            return (
                                                <div className="box-body" key={parcel.node.id}>

                                                    {
                                                        this.state.token?.site?.includes('sklasmanagement') ? 

                                                            <img
                                                            src="https://silverman-storage.sgp1.digitaloceanspaces.com/3partysource/images/singha_logo.png"
                                                            alt="silverman-logo" style={{width: 75, height: 75}}/> 
                                                        
                                                        :

                                                            <img
                                                            src="https://silverman-storage.sgp1.digitaloceanspaces.com/3partysource/images/silvetman-logo.png"
                                                            alt="silverman-logo" style={{width: 75, height: 75}}/>
                                            
                                                    }
                                                       

                                                    <p style={{fontSize: 14, fontWeight: 400}}>
                                                        <b>ใบแจ้งรับพัสดุ / Parcel Card</b>
                                                    </p>
                                                    <div style={{display:'flex',flexDirection:'row',paddingTop:8,marginLeft:50}} >
                                                        <div style={{flex:1}} >
                                                            <div style={{textAlign:'left'}} >
                                                                <div className='text-label'  >ห้อง <b>{`: ${parcel.node.residential?.name}`}</b></div>
                                                                <div className='text-label'  >Room No. </div>
                                                                
                                                            </div>
                                                            <div className="div-text-label"  >
                                                                <div className='text-label'  >วันที่มาส่ง <b>{format(parcel.node.added, 'DD/MM/YYYY ', {locale: thLocale})}</b></div>
                                                                <div className='text-label'  >Parcel Date </div>
                                                                
                                                            </div>
                                                            <div className="div-text-label"  >
                                                                <div className='text-label'  >รหัสพัสดุ <b>{parcel.node.trackingNumber}</b></div>
                                                                <div className='text-label'  >Tracking No. </div>
                                                                
                                                            </div>
                                                            <div className="div-text-label"  >
                                                                <div className='text-label'  >จัดส่งโดย <b>{parcel.node.serviceName}</b></div>
                                                                <div className='text-label'  >Delivery </div>
                                                            </div>
                                                        </div>
                                                        <div style={{flex:1}} >
                                                            <div style={{textAlign:'left'}} >
                                                            <div className='text-label'  >ลำดับพัสดุ <b>{parcel.node.docNumber}</b></div>
                                                                <div className='text-label'  >Parcel No.</div>
                                                            </div>
                                                            <div className="div-text-label"  >
                                                                <div className='text-label'  >ชื่อผู้รับ <b>{parcel.node.name}</b></div>
                                                                <div className='text-label'  >Receiver </div>
                                                                
                                                            </div>
                                                            <div className="div-text-label"  >
                                                                <div className='text-label'  >ลักษณะพัสดุ <b>{parcel.node.description}</b></div>
                                                                <div className='text-label'  >Description Letter</div>
                                                            </div>
                                                            <div style={{textAlign:'center',marginTop:24}} >
                                                                <div className='signature-box' ></div>
                                                                <div className='text-label' style={{fontSize:12}} ><b>ลายเซ็นผู้รับ / Signature</b></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{alignItems:'center',paddingLeft:16,paddingRight:16,marginTop:8}} >
                                                        <div style={{border: '1px dashed'}} ></div>
                                                    </div>

                                                    <p style={{marginTop:8}} >ดาวน์โหลด Application ได้ที่</p>
                                                    <div className="download">
                                                        <img
                                                            src="https://silverman-storage.sgp1.digitaloceanspaces.com/3partysource/images/magnify.png"
                                                            alt="magnify" style={{
                                                                position: "relative",
                                                                width: 10,
                                                                height: 10,
                                                                right: 70,
                                                                top: -3
                                                            }}/>

                                                        <p style={{
                                                            position: "relative",
                                                            right: 0,
                                                            bottom: 20,
                                                            fontSize:10
                                                        }}>
                                                        {
                                                            this.state.token?.site?.includes('sklasmanagement') ? 'S KLAS' : 'Silverman'
                                                        }
                                                        </p>
                                                    </div> 

                                                    {
                                                    this.state.token?.site?.includes('sklasmanagement') ? 
                                                        <img src={process.env.PUBLIC_URL + "/images/qrcode/qr-SKLAS.png"}
                                                        alt="sklas-qr-code"
                                                        className="m-3" style={{width: 70, height: 70}}/> 
                                                    :
                                                        <img src="https://silverman-storage.sgp1.digitaloceanspaces.com/3partysource/images/qr.png"
                                                            alt="silverman-qr-code"
                                                            className="m-3" style={{width: 70, height: 70}}/> 
                                                    }
                                                    <div style={{fontSize:10,marginBottom:8}} >กรุณานำใบนี้มาติดต่อเพื่อรับพัสดุ / Please bring this card to claim your parcel.</div>
                                                </div>
                                            )
                                        }
                                        }
                                        )}
                                        
                                        </div>
                                        )
                        }
                        return <Loading/>
                    }}/>
            </div>
        )
    }
}

export default genParcelA4;
