/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateClearAdvanceInput = {|
  clearAdvance: any,
  paymentChannel: any,
  overOrReturn: number,
  asset?: ?any,
  clientMutationId?: ?string,
|};
export type clearAdvanceCreateAndViewFormMutationVariables = {|
  input: CreateClearAdvanceInput
|};
export type clearAdvanceCreateAndViewFormMutationResponse = {|
  +createClearAdvance: ?{|
    +ok: ?boolean,
    +warningText: ?string,
    +newClearAdvance: ?{|
      +id: string
    |},
  |}
|};
export type clearAdvanceCreateAndViewFormMutation = {|
  variables: clearAdvanceCreateAndViewFormMutationVariables,
  response: clearAdvanceCreateAndViewFormMutationResponse,
|};
*/


/*
mutation clearAdvanceCreateAndViewFormMutation(
  $input: CreateClearAdvanceInput!
) {
  createClearAdvance(input: $input) {
    ok
    warningText
    newClearAdvance {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateClearAdvancePayload",
    "kind": "LinkedField",
    "name": "createClearAdvance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClearAdvanceNode",
        "kind": "LinkedField",
        "name": "newClearAdvance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clearAdvanceCreateAndViewFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clearAdvanceCreateAndViewFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2655661a29da31fac036800c4a9ab995",
    "id": null,
    "metadata": {},
    "name": "clearAdvanceCreateAndViewFormMutation",
    "operationKind": "mutation",
    "text": "mutation clearAdvanceCreateAndViewFormMutation(\n  $input: CreateClearAdvanceInput!\n) {\n  createClearAdvance(input: $input) {\n    ok\n    warningText\n    newClearAdvance {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '576f0beb83efa59de5c8b612958dbdd6';

module.exports = node;
