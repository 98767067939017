import React from 'react';
import { Translation } from 'react-i18next';
import InputAddress from "../../../libs/autoCompleteAddress";

const OwnerAddressDetail = (props) => {
    return (
        <Translation>
            {t =>
                <React.Fragment>
                    <div className='row m-0 p-0'>
                        <div className='col-8 pl-0'>
                            <p className='fs-16 mt-5'>{t("add_receivable:Address")} <span className="star-button">*</span></p>
                            <textarea className="form-control h48" id="mailingAddress" maxLength="256"
                                rows={1} style={{ resize: 'none' }}
                                name="mailingAddress" value={props.state.mailingAddress}
                                onChange={props.handleInputChange}
                                disabled={props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                required
                            />
                        </div>

                        <div className='col' />

                        <div className='col-4 pl-0'>
                            <p className='fs-16 mt-5' htmlFor="mailingDistrict">{t("add_receivable:Sub-district")}</p>
                            <InputAddress
                                address="mailingDistrict"
                                value={props.state.mailingDistrict}
                                onChange={props.handleInputChange}
                                onSelect={props.onSelect}
                                mailing={false}
                                className="form-control h48"
                                disabled={props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                            />

                        </div>

                        <div className='col-4 pl-0'>
                            <p className='fs-16 mt-5' htmlFor="mailingCity">{t("add_receivable:District")}</p>
                            <InputAddress
                                address="mailingCity"
                                value={props.state.mailingCity}
                                onChange={props.handleInputChange}
                                onSelect={props.onSelect}
                                mailing={false}
                                className="form-control h48"
                                disabled={props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                            />
                        </div>
                        <div className='col' />

                        <div className='col-4 pl-0'>
                            <p className='fs-16 mt-5' htmlFor="mailingProvince">{t("add_receivable:Province")}</p>
                            <InputAddress
                                address="mailingProvince"
                                value={props.state.mailingProvince}
                                onChange={props.handleInputChange}
                                onSelect={props.onSelect}
                                mailing={false}
                                className="form-control h48"
                                disabled={props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                            />

                        </div>

                        <div className='col-4 pl-0'>
                            <p className='fs-16 mt-5' htmlFor="mailingPostalCode">{t("add_receivable:Zip code")}</p>
                            <InputAddress
                                address="mailingPostalCode"
                                value={props.state.mailingPostalCode}
                                onChange={props.handleInputChange}
                                onSelect={props.onSelect}
                                mailing={false}
                                className="form-control h48"
                                disabled={props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                            />
                        </div>

                    </div>
                </React.Fragment>
            }
        </Translation>
    )

}

export default OwnerAddressDetail; 