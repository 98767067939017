import '../../document/styles/main.scss';
import React, { Component } from 'react';
const ThaiBaht = require('thai-baht-text');

class SettingCollectionLetterLawViewForm2 extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidUpdate() {

    }

    render() {


        return (
            <React.Fragment>
                <div className="print-page-a4" id="setting-collection-view-form2" >
                    <div className='d-flex justify-content-center'>
                        <div className='d-flex flex-column logoContainer'>
                            <img className="logo mb-2" src={process.env.PUBLIC_URL + '/images/collectionLaw/houseCondoLawyer.png'} />
                            <p>วันที่ {this.props.settingInfo.dateSelected || "........................."}</p>
                        </div>
                    </div>
                    <p>เรื่อง ขอให้ชำระค่าบำรุงรักษา และค่าจัดการสาธารณูปโภค (ค่าส่วนกลางนิติบุคคลอาคารชุด โครงการ{this.props.settingInfo.projectName || "......."})</p>
                    <p>เรียน {this.props.settingInfo.nameOfDebter || "............................"}</p>
                    <p>เอกสารที่แนบมาด้วย : <b>รายการสรุปรายการหนี้ค้างชำระ ห้องชุดเลขที่ {this.props.settingInfo.roomNumber || ".............."}</b></p>
                    <p>&nbsp;&nbsp;ตามที่ท่านเป็นผู้ถือกรรมสิทธิ์ห้องชุดเลขที่ {this.props.settingInfo.roomNumber || "......."} อาคารชุดโครงการ {this.props.settingInfo.projectName || "..................."} ขนาดเนื้อที่ห้องชุด
                        {this.props.settingInfo.areaRoom || "......"} ตารางเมตร และพื้นที่ระเบียง {this.props.settingInfo.areaBalcony || "....."} ตารางเมตร รวมเนื้อที่ {(Number(this.props.settingInfo.areaRoom || 0) + Number(this.props.settingInfo.areaBalcony || 0)) || "........"} ตารางเมตร เนื่องด้วยนิติบุคคล
                        อาคารชุดโครงการ{this.props.settingInfo.projectName || "......."} ซึ่งจดทะเบียนจัดตั้งเป็นนิติบุคคลอาคารชุด ตามพระราชบัญญัติ อาคารชุด พ.ศ.
                        2522 ตามกฎหมาย และมีวัตถุประสงค์เพื่อจัดการ ดูแลบำรุงรักษาทรัพย์ส่วนกลาง อันเพื่อประโยชน์ร่วม
                        กันของเจ้าร่วมภายในอาคารชุด และให้อำนาจกระทำการใด ๆ เพื่อประโยชน์ตามวัตถุประสงค์ดังกล่าว
                        และตามมติของที่ประชุมใหญ่ได้กำหนดให้ท่านเจ้าของร่วมจะต้องชำระค่าบำรุงรักษา และการจัดการ
                        สาธารณูปโภค เบี้ยประกันอาคาร เงินกองทุน ตลอดจนค่าใช้จ่ายอื่นๆ ตามรายละเอียดที่ท่านได้ทราบดี
                        อยู่แล้วนั้น
                    </p>
                    <p>&nbsp;&nbsp;ปรากฏว่า ปัจจุบัน ท่านผู้เป็นเจ้าของร่วมได้ค้างชำระค่าบำรุงรักษา และการจัดการ
                        สาธารณูปโภค (ค่าส่วนกลาง) เบี้ยประกันอาคาร เงินกองทุน ตลอดจนค่าใช้จ่ายอื่นๆ และเงินเพิ่มจาก
                        การชำระล่าช้า คิดเป็นจำนวนเงินทั้งสิ้น {this.props.settingInfo.outstadingNumber || "........................."} บาท ({this.props.settingInfo.outstadingText || "................................."}) <b>ดังรายการตาม</b>
                        <b>เอกสารที่แนบมาด้วย</b>
                    </p>
                    <p>&nbsp;&nbsp;ดังนั้น ข้าพเจ้าในฐานะทนายความผู้รับมอบอำนาจจากนิติบุคคลอาคารชุดโครงการ{this.props.settingInfo.projectName || "......."} จึงขอบอก
                        กล่าวมายังท่าน โปรดดำเนินการชำระเงินดังกล่าวที่ท่านได้ค้างชำระ ณ สำนักงานนิติบุคคลอาคารชุด
                        โครงการ{this.props.settingInfo.projectName || "......."} ในวันและเวลาทำการของนิติบุคคล ฯ ภายในระยะเวลา {this.props.settingInfo.dueDateDays || "......."} วัน นับแต่วันที่ท่านได้รับหนังสือ
                        บอกกล่าวทวงถามฉบับนี้
                    </p>
                    <p>&nbsp;&nbsp;หากท่านยังคงเพิกเฉยไม่ดำเนินการภายในระยะเวลาดังกล่าว ข้าพเจ้าในฐานะทนายความผู้รับ
                        มอบอำนาจมีความจำเป็นที่จะต้องดำเนินการตามกฎหมายต่อท่านต่อไป
                    </p>
                    {this.props?.lawWordingShow ?
                        <p>&nbsp;&nbsp;อนึ่ง เมื่อมีการยื่นคำร้องต่อศาล เพื่อดำเนินคดีตามกฎหมาย ตามระเบียบข้อบังคับนิติบุคคลอา
                            คารชุดฯ {this.props.lawWording} {this.props.lawWording && this.props?.lawWordingNo ? this.props?.lawWordingNo : this.props.lawWording ? "......" : ""} ท่านต้องชำระค่าปรับเพิ่ม เป็นจำนวน {this.props.feeRateTh ? this.props.feeRateTh?.toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0.00"} บาท ({this.props.feeRateTh ? ThaiBaht(this.props.feeRateTh) : "ศูนย์บาทถ้วน"}) เพื่อเป็น
                            ค่าใช้จ่ายในการดำเนินการ ตลอดจนหากมีค่าใช้จ่ายอื่นใด เพื่อดำเนินการติดตามหนี้คงค้างที่เกิดขึ้น
                            ภายหลัง จะเป็นหน้าที่ความรับผิดชอบของท่านในฐานะเจ้าของร่วม ทั้งหมด
                        </p> : ""
                    }

                    <div className='d-flex flex-row-reverse'>
                        <div className='d-flex flex-column align-items-center signatureContainer colorUse'>
                            <p>ขอแสดงความนับถือ</p>
                            {this.props.lawyerSignature ?
                                <p>
                                    <img id={"signatureImageSettingCollectionLawForm"} className='h40' src={this.props.lawyerSignature} />
                                </p> :
                                <p>………………………………</p>
                            }
                            <p>({this.props.lawyerName || "………………………………"})</p>
                            <p>ทนายความผู้รับมอบอำนาจ</p>
                        </div>
                    </div>
                    <p>โทร. {this.props.settingInfo.telOffice || "......................"}, {this.props.settingInfo.telJuristic || "......................"}</p>
                </div>
            </React.Fragment>
        )
    }
}

export default SettingCollectionLetterLawViewForm2;