/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type CreditNotePayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type CreditNoteStatus = "PAID" | "VOID" | "WAIT" | "%future added value";
export type CreditNoteTransactionStatus = "PAID" | "VOID" | "WAIT" | "%future added value";
export type CreditNoteVersion = "V1" | "V2" | "%future added value";
export type creditNoteViewQueryVariables = {|
  id: string
|};
export type creditNoteViewQueryResponse = {|
  +creditNote: ?{|
    +id: string,
    +docNumber: string,
    +issuedDate: any,
    +status: CreditNoteStatus,
    +payGroup: CreditNotePayGroup,
    +firstName: string,
    +lastName: string,
    +address: string,
    +district: string,
    +city: string,
    +province: string,
    +postalCode: string,
    +creditNoteFile: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +fileName: ?string,
          +fileUpload: string,
          +fileShow: ?string,
        |}
      |}>
    |},
    +contact: {|
      +refNumber: string,
      +firstName: string,
      +lastName: string,
      +registeredName: string,
      +registeredAddress: string,
      +registeredCountry: string,
      +registeredProvince: string,
      +registeredCity: string,
      +registeredDistrict: string,
      +registeredPostalCode: string,
      +typeOfContact: ContactTypeOfContact,
      +name: string,
    |},
    +returnChartOfAccount: ?{|
      +id: string,
      +chartOfAccountCode: string,
      +name: string,
    |},
    +transaction: ?{|
      +id: string,
      +productAndService: ?{|
        +productCode: string,
        +name: string,
        +chartOfAccount: ?{|
          +chartOfAccountCode: string,
          +name: string,
        |},
      |},
      +invoice: {|
        +docNumber: string
      |},
    |},
    +reason: string,
    +description: ?string,
    +price: number,
    +remark: string,
    +version: CreditNoteVersion,
    +receive: ?{|
      +id: string,
      +docNumber: string,
      +total: ?number,
    |},
    +creditNoteTransactionRecord: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +price: number,
          +status: CreditNoteTransactionStatus,
          +description: ?string,
          +returnChartOfAccount: ?{|
            +id: string,
            +chartOfAccountCode: string,
            +name: string,
          |},
          +transaction: ?{|
            +id: string,
            +productAndService: ?{|
              +productCode: string,
              +name: string,
              +chartOfAccount: ?{|
                +chartOfAccountCode: string,
                +name: string,
              |},
            |},
            +invoice: {|
              +docNumber: string
            |},
          |},
        |}
      |}>
    |},
  |}
|};
export type creditNoteViewQuery = {|
  variables: creditNoteViewQueryVariables,
  response: creditNoteViewQueryResponse,
|};
*/


/*
query creditNoteViewQuery(
  $id: ID!
) {
  creditNote(id: $id) {
    id
    docNumber
    issuedDate
    status
    payGroup
    firstName
    lastName
    address
    district
    city
    province
    postalCode
    creditNoteFile {
      edges {
        node {
          fileName
          fileUpload
          fileShow
          id
        }
      }
    }
    contact {
      refNumber
      firstName
      lastName
      registeredName
      registeredAddress
      registeredCountry
      registeredProvince
      registeredCity
      registeredDistrict
      registeredPostalCode
      typeOfContact
      name
      id
    }
    returnChartOfAccount {
      id
      chartOfAccountCode
      name
    }
    transaction {
      id
      productAndService {
        productCode
        name
        chartOfAccount {
          chartOfAccountCode
          name
          id
        }
        id
      }
      invoice {
        docNumber
        id
      }
    }
    reason
    description
    price
    remark
    version
    receive {
      id
      docNumber
      total
    }
    creditNoteTransactionRecord {
      edges {
        node {
          id
          price
          status
          description
          returnChartOfAccount {
            id
            chartOfAccountCode
            name
          }
          transaction {
            id
            productAndService {
              productCode
              name
              chartOfAccount {
                chartOfAccountCode
                name
                id
              }
              id
            }
            invoice {
              docNumber
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payGroup",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "district",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "province",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileUpload",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileShow",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredAddress",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCountry",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredProvince",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCity",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredDistrict",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredPostalCode",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "returnChartOfAccount",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v27/*: any*/),
    (v26/*: any*/)
  ],
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "TransactionNode",
  "kind": "LinkedField",
  "name": "transaction",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNode",
      "kind": "LinkedField",
      "name": "productAndService",
      "plural": false,
      "selections": [
        (v29/*: any*/),
        (v26/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "chartOfAccount",
          "plural": false,
          "selections": [
            (v27/*: any*/),
            (v26/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceNode",
      "kind": "LinkedField",
      "name": "invoice",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reason",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "concreteType": "ReceiveNode",
  "kind": "LinkedField",
  "name": "receive",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "concreteType": "TransactionNode",
  "kind": "LinkedField",
  "name": "transaction",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNode",
      "kind": "LinkedField",
      "name": "productAndService",
      "plural": false,
      "selections": [
        (v29/*: any*/),
        (v26/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "chartOfAccount",
          "plural": false,
          "selections": [
            (v27/*: any*/),
            (v26/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceNode",
      "kind": "LinkedField",
      "name": "invoice",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "creditNoteViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreditNoteNode",
        "kind": "LinkedField",
        "name": "creditNote",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CreditNoteFileNodeConnection",
            "kind": "LinkedField",
            "name": "creditNoteFile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreditNoteFileNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreditNoteFileNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNode",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/)
            ],
            "storageKey": null
          },
          (v28/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CreditNoteTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "creditNoteTransactionRecord",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreditNoteTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreditNoteTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v33/*: any*/),
                      (v5/*: any*/),
                      (v32/*: any*/),
                      (v28/*: any*/),
                      (v30/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "creditNoteViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreditNoteNode",
        "kind": "LinkedField",
        "name": "creditNote",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CreditNoteFileNodeConnection",
            "kind": "LinkedField",
            "name": "creditNoteFile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreditNoteFileNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreditNoteFileNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNode",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v28/*: any*/),
          (v37/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CreditNoteTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "creditNoteTransactionRecord",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreditNoteTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreditNoteTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v33/*: any*/),
                      (v5/*: any*/),
                      (v32/*: any*/),
                      (v28/*: any*/),
                      (v37/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "848f3c0357bc56d71280e9f0518b524a",
    "id": null,
    "metadata": {},
    "name": "creditNoteViewQuery",
    "operationKind": "query",
    "text": "query creditNoteViewQuery(\n  $id: ID!\n) {\n  creditNote(id: $id) {\n    id\n    docNumber\n    issuedDate\n    status\n    payGroup\n    firstName\n    lastName\n    address\n    district\n    city\n    province\n    postalCode\n    creditNoteFile {\n      edges {\n        node {\n          fileName\n          fileUpload\n          fileShow\n          id\n        }\n      }\n    }\n    contact {\n      refNumber\n      firstName\n      lastName\n      registeredName\n      registeredAddress\n      registeredCountry\n      registeredProvince\n      registeredCity\n      registeredDistrict\n      registeredPostalCode\n      typeOfContact\n      name\n      id\n    }\n    returnChartOfAccount {\n      id\n      chartOfAccountCode\n      name\n    }\n    transaction {\n      id\n      productAndService {\n        productCode\n        name\n        chartOfAccount {\n          chartOfAccountCode\n          name\n          id\n        }\n        id\n      }\n      invoice {\n        docNumber\n        id\n      }\n    }\n    reason\n    description\n    price\n    remark\n    version\n    receive {\n      id\n      docNumber\n      total\n    }\n    creditNoteTransactionRecord {\n      edges {\n        node {\n          id\n          price\n          status\n          description\n          returnChartOfAccount {\n            id\n            chartOfAccountCode\n            name\n          }\n          transaction {\n            id\n            productAndService {\n              productCode\n              name\n              chartOfAccount {\n                chartOfAccountCode\n                name\n                id\n              }\n              id\n            }\n            invoice {\n              docNumber\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '892cd93bd947f132a57c1568c43aadc5';

module.exports = node;
