/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type cashDepositCreateQueryVariables = {||};
export type cashDepositCreateQueryResponse = {|
  +cashTransactionViewer: ?{|
    +allCashTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +receive: ?{|
            +docNumber: string,
            +issuedDate: any,
          |},
          +invoice: ?{|
            +docNumber: string,
            +issuedDate: any,
          |},
          +receiptDeposit: ?{|
            +docNumber: string,
            +issuedDate: any,
          |},
          +guaranteeMoneyReceive: ?{|
            +docNumber: string,
            +issuedDate: any,
          |},
          +clearAdvance: ?{|
            +docNumber: string,
            +issuedDate: any,
          |},
          +otherReceive: ?{|
            +docNumber: string,
            +issuedDate: any,
          |},
          +description: ?string,
          +price: number,
        |}
      |}>
    |}
  |}
|};
export type cashDepositCreateQuery = {|
  variables: cashDepositCreateQueryVariables,
  response: cashDepositCreateQueryResponse,
|};
*/


/*
query cashDepositCreateQuery {
  cashTransactionViewer {
    allCashTransaction(status: "not_pass", cashType: "cash_in", createCashDeposit: true) {
      edges {
        node {
          id
          receive {
            docNumber
            issuedDate
            id
          }
          invoice {
            docNumber
            issuedDate
            id
          }
          receiptDeposit {
            docNumber
            issuedDate
            id
          }
          guaranteeMoneyReceive {
            docNumber
            issuedDate
            id
          }
          clearAdvance {
            docNumber
            issuedDate
            id
          }
          otherReceive {
            docNumber
            issuedDate
            id
          }
          description
          price
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "cashType",
    "value": "cash_in"
  },
  {
    "kind": "Literal",
    "name": "createCashDeposit",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "status",
    "value": "not_pass"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "cashDepositCreateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CashTransactionViewer",
        "kind": "LinkedField",
        "name": "cashTransactionViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "CashTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "allCashTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CashTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CashTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveNode",
                        "kind": "LinkedField",
                        "name": "receive",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiceNode",
                        "kind": "LinkedField",
                        "name": "invoice",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiptDepositNode",
                        "kind": "LinkedField",
                        "name": "receiptDeposit",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GuaranteeMoneyReceivedNode",
                        "kind": "LinkedField",
                        "name": "guaranteeMoneyReceive",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClearAdvanceNode",
                        "kind": "LinkedField",
                        "name": "clearAdvance",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OtherReceiveNode",
                        "kind": "LinkedField",
                        "name": "otherReceive",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "allCashTransaction(cashType:\"cash_in\",createCashDeposit:true,status:\"not_pass\")"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "cashDepositCreateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CashTransactionViewer",
        "kind": "LinkedField",
        "name": "cashTransactionViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "CashTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "allCashTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CashTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CashTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveNode",
                        "kind": "LinkedField",
                        "name": "receive",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiceNode",
                        "kind": "LinkedField",
                        "name": "invoice",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiptDepositNode",
                        "kind": "LinkedField",
                        "name": "receiptDeposit",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GuaranteeMoneyReceivedNode",
                        "kind": "LinkedField",
                        "name": "guaranteeMoneyReceive",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClearAdvanceNode",
                        "kind": "LinkedField",
                        "name": "clearAdvance",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OtherReceiveNode",
                        "kind": "LinkedField",
                        "name": "otherReceive",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "allCashTransaction(cashType:\"cash_in\",createCashDeposit:true,status:\"not_pass\")"
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "003ba0be59e1debe99360027c738e761",
    "id": null,
    "metadata": {},
    "name": "cashDepositCreateQuery",
    "operationKind": "query",
    "text": "query cashDepositCreateQuery {\n  cashTransactionViewer {\n    allCashTransaction(status: \"not_pass\", cashType: \"cash_in\", createCashDeposit: true) {\n      edges {\n        node {\n          id\n          receive {\n            docNumber\n            issuedDate\n            id\n          }\n          invoice {\n            docNumber\n            issuedDate\n            id\n          }\n          receiptDeposit {\n            docNumber\n            issuedDate\n            id\n          }\n          guaranteeMoneyReceive {\n            docNumber\n            issuedDate\n            id\n          }\n          clearAdvance {\n            docNumber\n            issuedDate\n            id\n          }\n          otherReceive {\n            docNumber\n            issuedDate\n            id\n          }\n          description\n          price\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '635efa920b30111b9863e564f09c7c14';

module.exports = node;
