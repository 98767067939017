import React, { Component } from 'react'
import thLocale from "date-fns/locale/th";
import { format } from 'date-fns';
import _ from "lodash"
import numberWithCommas from "../../../libs/numberWithComma"
import "./reportRegisterPaper.scss"
import jwtDecode from 'jwt-decode'
export default class reportRegisterPaper extends Component {

  constructor(props) {
    super(props)

    this.state = {
      id: "",
      queryDataReport: [],
      queryDate: {},
      date: new Date(),
      rangeDate: "",
      printBy: "",
      jwtDecodes: "",
};
}

  componentWillMount() {
    this.setState({
      jwtDecodes: jwtDecode(window.localStorage.getItem('token')),
          }, () => {
            this.setState({
                printBy: `${this.state.jwtDecodes.first_name} ${this.state.jwtDecodes.last_name}`,
            });
          });
    if (this.props.state) {
      this.setState({
        id: this.props.state.id,
        queryDataReport: this.props.state.queryDataReport,
        queryDate: this.props.state.queryDate,
        date: this.props.state.date,
        rangeDate: this.props.state.rangeDate
      })
    }
  }

  sortData = (inputData) => {
    let group_date = {};
    let new_currentRecord = [];

    _.map(inputData, (record) => {
      if (Object.keys(group_date).includes(record.node.group.issuedDate.substring(0, 7))) {
        group_date[record.node.group.issuedDate.substring(0, 7)].push(record)
      } else {
        group_date[record.node.group.issuedDate.substring(0, 7)] = [record]
      }
      group_date[record.node.group.issuedDate.substring(0, 7)] = _.sortBy(group_date[record.node.group.issuedDate.substring(0, 7)], sort => sort.node.group.refNumber)
    })
    Object.entries(group_date).map((value) => {
      new_currentRecord.push({ month: value[0], accountRecord: value[1] })
      return value
    });

    let sortNewRecord = _.sortBy(new_currentRecord, sort => sort.month)
    return sortNewRecord
  }

  
  render() {
    let sumHappendOfMonth = 0;
    return (
      _.map(this.state.queryDataReport, (item, index) => {
        let dataAll = []
        let rowSum = []
        let sumTotalHappend = 0
        let accountRecordSordIsuedate = this.sortData(item.node.chartOfAccount.accountRecord.edges)
        //Exact data
        accountRecordSordIsuedate.map((dat)=>( 
          dat.accountRecord.map((data, index)=>{
            if(dat.accountRecord.length === 1) {
                  dataAll.push(data)
                  if (item.node.chartOfAccount.chartOfAccountCode.substring(0, 1) === "4") {
                    sumHappendOfMonth += data.node.credit - data.node.debit
                    sumTotalHappend += data.node.credit - data.node.debit
                  } else if (item.node.chartOfAccount.chartOfAccountCode.substring(0, 1) === "5") {
                    sumHappendOfMonth += data.node.debit - data.node.credit
                    sumTotalHappend += data.node.debit - data.node.credit
                  } 
                  rowSum.push('rowofsum')
                  rowSum.push(sumHappendOfMonth)
                  rowSum.push(dat.month)
                  dataAll.push(rowSum)
                  sumHappendOfMonth = 0
                  rowSum = []
            }
            else if(index === (dat.accountRecord.length-1)) {
                  dataAll.push(data)
                  if (item.node.chartOfAccount.chartOfAccountCode.substring(0, 1) === "4") {
                    sumHappendOfMonth += data.node.credit - data.node.debit
                    sumTotalHappend += data.node.credit - data.node.debit
                  } else if (item.node.chartOfAccount.chartOfAccountCode.substring(0, 1) === "5") {
                    sumHappendOfMonth += data.node.debit - data.node.credit
                    sumTotalHappend += data.node.debit - data.node.credit
                  } 
                  rowSum.push('rowofsum')
                  rowSum.push(sumHappendOfMonth)
                  rowSum.push(dat.month)
                  dataAll.push(rowSum)
                  sumHappendOfMonth = 0
                  rowSum = []
            } else {
                  dataAll.push(data)
                  if (item.node.chartOfAccount.chartOfAccountCode.substring(0, 1) === "4") {
                    sumHappendOfMonth += data.node.credit - data.node.debit
                    sumTotalHappend += data.node.credit - data.node.debit
                  } else if (item.node.chartOfAccount.chartOfAccountCode.substring(0, 1) === "5") {
                    sumHappendOfMonth += data.node.debit - data.node.credit
                    sumTotalHappend += data.node.debit - data.node.credit
                  } 
            }
            return data
          })
        ))
        const pageSize = 7;
        const dataAllSlice = _.chunk(dataAll, pageSize);
        const allPage = dataAllSlice.length
        return (
          <React.Fragment key={index}>
            <div id="reportRegisterPaper" className='marginLeftRigth' >
                  <div className="reportRegisterPaper-body">
                      {dataAllSlice.map((data, index_page)=>(
                        <React.Fragment key={index_page}>
                          <div className='page'>
                            {index_page === 0 && 
                              <div className="head">
                                <div className="logo">
                                  {this.props.selfProject.logo !== '' &&
                                    <img src={this.props.selfProject.logo} alt="silverman" style={{ width: '80px', marginLeft: '5px' }} />
                                  }
                                </div>
                                <div className="headTextCenter text-center font-weight-bold" style={{ paddingTop: "40px" }}>
                                  <div>{this.props.selfProject.name}</div>
                                  <div>ทะเบียนควบคุมการใช้งบประมาณ {item.node.chartOfAccount.name}</div>
                                  สำหรับรอบระยะเวลา {this.state.rangeDate} เดือน วันที่ {format(this.state.queryDate.startDate, " D MMMM YYYY ", { locale: thLocale })} ถึง {format(this.state.date, " D MMMM YYYY ", { locale: thLocale })}
                                </div>
                              </div>
                            }
                           <table className="table table-bordered">
                                <thead>
                                  <tr className="tr-header">
                                    <th rowSpan={2} className="text-center" style={{ width: 100 }}>วันที่</th>
                                    <th rowSpan={2} className="text-center">คำอธิบาย</th>
                                    <th rowSpan={2} style={{ width: 70 }} />
                                    <th rowSpan={2} className="text-center" style={{ width: 140 }}>เลขที่เอกสาร</th>
                                    <th rowSpan={2} className="text-center" style={{ width: 170 }}>เกิดจริง</th>
                                    <th className="text-center" style={{ width: 170 }}>งบประมาณ/ปี</th>
                                    <th className="text-right" style={{ width: 170 }}>{numberWithCommas(item.node.totalBudget)}</th>
                                  </tr>
                                  <tr className="tr-header">
                                    <th className="text-center" style={{ width: 170 }}>งบประมาณ/เดือน</th>
                                    <th className="text-right" style={{ width: 170 }}>เกิดจริง มาก(น้อย)กว่างบฯ</th>
                                  </tr>
                                </thead>
                                <tbody>  
                            {_.map(data, (item_c_child, index_c_child) => {
                              if(item_c_child[0] !== 'rowofsum') {
                                return (
                                  <tr key={index_c_child}>
                                    <td className="text-center">{format(item_c_child.node.group.issuedDate, "DD/MM/YYYY")}</td>
                                    <td width={400} className="text-center">{`${item_c_child.node.name} ${item_c_child.node.group.name}`}</td>
                                    <td />
                                    <td width={120} className="text-center text-primary">{item_c_child.node.group.refNumber || item_c_child.node.group.refTransaction}</td>
                                    <td className="text-center td-red-scale">
                                      {item.node.chartOfAccount.chartOfAccountCode.substring(0, 1) === "4"
                                        ? numberWithCommas((item_c_child.node.credit - item_c_child.node.debit).toFixed(2))
                                        : item.node.chartOfAccount.chartOfAccountCode.substring(0, 1) === "5"
                                        && numberWithCommas((item_c_child.node.debit - item_c_child.node.credit).toFixed(2))
                                      }
                                    </td>
                                    <td className="text-center"></td>
                                    <td className="text-right td-red-scale"></td>
                                  </tr>
                                );
                              } else {
                                let totalOfMonth = _.filter(item.node.monthBudget.edges, (o) => o.node.months.substring(0, 7) === item_c_child[2])
                                return (                              
                                  <tr className="td-sum font-weight-bold " key={index_c_child}>
                                    <td />
                                    <td />
                                    <td className="text-center">รวม</td>
                                    <td className="text-center">{format(item_c_child[2], "MMMM YYYY", { locale: thLocale })}</td>
                                    <td className="text-center">{numberWithCommas(item_c_child[1].toFixed(2))}</td>
                                    <td className="text-center">
                                    {(totalOfMonth[0]?.node?.budget || 0) !== 0
                                    ? numberWithCommas(totalOfMonth[0]?.node?.budget)
                                    : "-"
                                    }
                                    </td>
                                    <td className="text-right">{numberWithCommas(item_c_child[1] - (totalOfMonth[0]?.node?.budget || 0))}</td>
                                  </tr>
                                );
                              }
                            })}
                                </tbody>
                                {index_page + 1 === allPage && <React.Fragment>
                                  <tr className="td-sum2 font-weight-bold">
                                    <td colSpan={5} className="text-right">รวมการใช้งบประมาณ</td>
                                    <td colSpan={2} className="text-right">{sumTotalHappend !== 0 ? numberWithCommas(sumTotalHappend.toFixed(2)) : "-"}</td>
                                  </tr>
                                  <tr className="td-sum3 font-weight-bold">
                                    <td colSpan={5} className="text-right">งบประมาณคงเหลือ / ปี+</td>
                                    <td colSpan={2} className="text-right">
                                      {(item.node.totalBudget - sumTotalHappend) !== 0
                                        ? numberWithCommas(((item.node.totalBudget || 0) - sumTotalHappend).toFixed(2))
                                        : "-"
                                      }
                                    </td>
                                  </tr>
                                </React.Fragment>}
                            </table>
                            <div className={`footer ${index_page === 0 ? 'spaceTopOfFooterFirstPage':'spaceTopOfFooter'}`}>
                                      <div className='row'>
                                          <div className='col-5' />
                                      <div className='col-2 text-center'>
                                          <p className='f14 pb-0 mb-0'>{index_page + 1}/{allPage}</p>
                                      </div>
                                      <div className='col-5 text-right'>
                                        <p className='f14 pb-0 mb-0'>ผู้พิมพ์ {this.state.printBy}  วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}</p>
                                      </div>
                                    </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
            </div>
          </React.Fragment>
        )

      })
    )
  }
}
