import i18next from 'i18next';
import React, { Component } from 'react'
import { NavLink } from "react-router-dom";


export default class ClaimReportTopMenuNavigation extends Component {
  render() {
    return (
      <div className="row" id="navigation-tab">
        <div className="col">
          <ul>
            <li>
              <NavLink exact={true} to="/purchasing/claim/report">
                {i18next.t("claimRequstList:All")}
              </NavLink>
            </li>
            <li>
              <NavLink exact={true} to="/purchasing/claim/report/finish" >
                {i18next.t("claimRequstList:Finished")}
              </NavLink>
            </li>
            <li>
              <NavLink exact={true} to="/purchasing/claim/report/void" >
              {i18next.t("claimRequstList:Canceled")}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
