import React, { Component } from 'react';
import CollectionLawFrimNumberDocsTable from './collectionLawFrimNumberDocsTable';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import BackButtonIcon from "../../components/BackBtn/indexBack"; 
import i18next from "i18next";
import { Translation } from 'react-i18next';
class CollectionLawFrimNumberofDocs extends Component {

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <div id="wrapper-content">
                    <Translation>
                        {t =>
                            <div className="container-fluid box slip-verification-list" >
                                <BackButtonIcon
                                    LinkBack={"/accounting"}
                                    LinkText={"รายงานติดตามจำนวนการออกเอกสาร e-notice"}
                                />
                                <div className="content-inner">
                                    <CollectionLawFrimNumberDocsTable />
                                </div>
                            </div>
                        }
                    </Translation>
                </div>

            </Wrapper>

        )
    }
}

export default CollectionLawFrimNumberofDocs;