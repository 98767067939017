/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HouseHolderType = "AGENT" | "OWNER" | "RENTER" | "RESIDENT" | "%future added value";
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type ResidentialStatus = "AVAILABILITY" | "FOR_RENT" | "LIVE" | "OTHER" | "SEIZURE" | "%future added value";
export type foreignOwnershipQueryVariables = {|
  search?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type foreignOwnershipQueryResponse = {|
  +selfProject: ?{|
    +typeOfProject: ?ProjectTypeOfProject
  |},
  +ownershipSummaryPercent: ?any,
  +foreignOwnershipPercent: ?$ReadOnlyArray<?any>,
  +allForeignOwnership: ?{|
    +totalCount: ?number,
    +pageInfo: {|
      +hasNextPage: boolean,
      +hasPreviousPage: boolean,
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +floor: ?string,
        +size: number,
        +status: ResidentialStatus,
        +ownershipRatio: ?number,
        +ownershipRatioShow: ?number,
        +unitNumber: ?string,
        +residentialHouseholder: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +type: HouseHolderType,
              +id: string,
              +tenant: {|
                +firstName: string,
                +lastName: ?string,
                +countries: string,
                +nameTitle: ?string,
              |},
            |}
          |}>
        |},
        +type: {|
          +id: string,
          +name: string,
        |},
        +ownershipArea: ?number,
      |}
    |}>,
  |},
  +allNoticeForeignOwnership: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +percentNoti: number,
      |}
    |}>,
  |},
  +areaSummary: ?number,
|};
export type foreignOwnershipQuery = {|
  variables: foreignOwnershipQueryVariables,
  response: foreignOwnershipQueryResponse,
|};
*/


/*
query foreignOwnershipQuery(
  $search: String
  $first: Int
  $last: Int
) {
  selfProject {
    typeOfProject
    id
  }
  ownershipSummaryPercent
  foreignOwnershipPercent
  allForeignOwnership(search: $search, residentialList: true, first: $first, last: $last) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        name
        floor
        size
        status
        ownershipRatio
        ownershipRatioShow
        unitNumber
        residentialHouseholder(type: "OWNER") {
          edges {
            node {
              type
              id
              tenant {
                firstName
                lastName
                countries
                nameTitle
                id
              }
            }
          }
        }
        type {
          id
          name
        }
        ownershipArea
      }
    }
  }
  allNoticeForeignOwnership {
    totalCount
    edges {
      node {
        id
        percentNoti
      }
    }
  }
  areaSummary(search: $search)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfProject",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ownershipSummaryPercent",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "foreignOwnershipPercent",
  "storageKey": null
},
v6 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v7 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Literal",
    "name": "residentialList",
    "value": true
  },
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floor",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ownershipRatio",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ownershipRatioShow",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitNumber",
  "storageKey": null
},
v18 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "OWNER"
  }
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countries",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameTitle",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "ResidentialTypeNode",
  "kind": "LinkedField",
  "name": "type",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ownershipArea",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "NoticeForeignOwnershipNodeConnection",
  "kind": "LinkedField",
  "name": "allNoticeForeignOwnership",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "NoticeForeignOwnershipNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NoticeForeignOwnershipNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentNoti",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": [
    (v6/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "areaSummary",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "foreignOwnershipQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "ResidentialNodeConnection",
        "kind": "LinkedField",
        "name": "allForeignOwnership",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": (v18/*: any*/),
                    "concreteType": "HouseHolderNodeConnection",
                    "kind": "LinkedField",
                    "name": "residentialHouseholder",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TenantNode",
                                "kind": "LinkedField",
                                "name": "tenant",
                                "plural": false,
                                "selections": [
                                  (v20/*: any*/),
                                  (v21/*: any*/),
                                  (v22/*: any*/),
                                  (v23/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "residentialHouseholder(type:\"OWNER\")"
                  },
                  (v24/*: any*/),
                  (v25/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v26/*: any*/),
      (v27/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "foreignOwnershipQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "ResidentialNodeConnection",
        "kind": "LinkedField",
        "name": "allForeignOwnership",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": (v18/*: any*/),
                    "concreteType": "HouseHolderNodeConnection",
                    "kind": "LinkedField",
                    "name": "residentialHouseholder",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TenantNode",
                                "kind": "LinkedField",
                                "name": "tenant",
                                "plural": false,
                                "selections": [
                                  (v20/*: any*/),
                                  (v21/*: any*/),
                                  (v22/*: any*/),
                                  (v23/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "residentialHouseholder(type:\"OWNER\")"
                  },
                  (v24/*: any*/),
                  (v25/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v26/*: any*/),
      (v27/*: any*/)
    ]
  },
  "params": {
    "cacheID": "3b686c28d5a928d18c43dc5e92c531a8",
    "id": null,
    "metadata": {},
    "name": "foreignOwnershipQuery",
    "operationKind": "query",
    "text": "query foreignOwnershipQuery(\n  $search: String\n  $first: Int\n  $last: Int\n) {\n  selfProject {\n    typeOfProject\n    id\n  }\n  ownershipSummaryPercent\n  foreignOwnershipPercent\n  allForeignOwnership(search: $search, residentialList: true, first: $first, last: $last) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n    }\n    edges {\n      node {\n        id\n        name\n        floor\n        size\n        status\n        ownershipRatio\n        ownershipRatioShow\n        unitNumber\n        residentialHouseholder(type: \"OWNER\") {\n          edges {\n            node {\n              type\n              id\n              tenant {\n                firstName\n                lastName\n                countries\n                nameTitle\n                id\n              }\n            }\n          }\n        }\n        type {\n          id\n          name\n        }\n        ownershipArea\n      }\n    }\n  }\n  allNoticeForeignOwnership {\n    totalCount\n    edges {\n      node {\n        id\n        percentNoti\n      }\n    }\n  }\n  areaSummary(search: $search)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f3655373e2e87d8e1c4af1bd022ee29';

module.exports = node;
