// import S3 from "react-aws-s3";
import jwtDecode from "jwt-decode";
import { uploadFile } from "react-s3";
const upload = async (keyPrefix, file) => {
  let token = window.localStorage.getItem('token')
  let token_decode = await jwtDecode(token)

  let config = {
    bucketName: "silverman-storage-backup",
    dirName: "silverman-storage/private" + keyPrefix,
    region: "ap-southeast-1",
    accessKeyId: "AKIA3OESFULNFF5B7P5Y",
    secretAccessKey: "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",
  };

  if (token_decode?.site?.includes('victorymanagement')) {
    config = {
      bucketName: "sena-storage",
      dirName: "silverman-storage/private" + keyPrefix,
      region: "ap-southeast-1",
      accessKeyId: "AKIAURTOYLNAWSLEIIFA",
      secretAccessKey: "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",
    };
  }

  if (token_decode?.site?.includes('sklasmanagement')) {
    config = {
      bucketName: "sklas-storage",
      dirName: "silverman-storage/private" + keyPrefix,
      region: "ap-southeast-1",
      accessKeyId: "AKIA3OESFULNFF5B7P5Y",
      secretAccessKey: "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",
    };
  }



  return await uploadFile(file, config);
};

export default upload;