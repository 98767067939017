import React, { Component } from 'react';
import Swal from 'sweetalert2';
import Apising from '../../../api/sing'
import i18n from 'i18next';
import i18next from 'i18next';


class ModalTag extends Component {
    constructor(props) {
        super(props); 
        this.state ={
            redirect: false,
            tagName: "",
            chkBox : false,
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    onChange(e){
        this.setState({[e.target.name] : e.target.value})
    }

    onSubmit(e){
        e.preventDefault(); 
         
        if(this.state.tagName){
            this.setState({ chkBox : false})
            let data = { 
                tagName: this.state.tagName
            }  
            Apising.postNewTag(data).then((res)=> {
                if(res.data.statusMessage === "success"){
                    Swal.fire(i18n.t("Allaction:Saved Successfully"),"","success").then(()=>{
                        window.location.reload()
                    })
                }else{
                    Swal.fire(i18next.t("Allaction:Unsuccessful "),"","warning")
                }
            },()=> Swal.fire(i18next.t("Allaction:Unsuccessful "),i18next.t("settingToolandConnect:Unable to connect to the API at this time."),"error"))
        }else{
            this.setState({ chkBox : true})
        }  
    }



    render() { 

        return (
           <React.Fragment>
               { 
                   <div className={"modal fade bd-example-modal-lg tagModals tagModal"+this.props.indexID} tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog  " role="document" style={{ marginTop: "15%" , width: "390px" }}>
                        <div className="modal-content">
                            <form onSubmit={this.onSubmit}>
                                <div className="modal-header" style={{ borderBottom: '0', padding: "15px 30px" }}>
                                    <h2 className="modal-title text-center" style={{ fontSize: "24px" }}>สร้าง Tag ใหม่</h2> 
                                </div>
                                <div className="modal-body" style={{ padding: "10px 30px", marginTop: "-10px" }}>
                                    <input type={"text"} defaultValue={this.state.tagName} name='tagName' className='form-control'
                                    onKeyPress={this.onChange} onChange={this.onChange} placeholder='ระบุชื่อ Tag'  />
                                    {
                                       this.state.chkBox == true?
                                       <span className='text-red'>กรุณาระบุชื่อ Tag</span>
                                       :''
                                    }
                                     
                                </div>
                                <div className="modal-footer" style={{ borderTop: '0', justifyContent: 'center' }}>
                                    <button type="button" className="btn btn-primary-outlie" data-dismiss="modal" aria-label="Close"  style={{width: '160px'}}>ยกเลิก</button>
                                    <button type="submit" className="btn btn-primary" style={{width: '160px'}}>สร้าง Tag</button>
                                </div>

                            </form>
                        </div>
                    </div>
                 </div>
               }
                

           </React.Fragment>
        )
    }
}
 

export default ModalTag