import React from 'react';
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Search from "../SearchInput";
import ComponentPagination from "../../libs/componentPagination";
import RegisterTopMenuNavigation from "../registerTopMenuNavigation";
import Pagination from "../../libs/newPagination";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import { fetchQuery } from "relay-runtime";

import "../styles/register.scss"
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import Loading from "../../libs/loading";
import ExportKeyCardExcel from './keyCardExcel';
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import i18next from 'i18next';
import ExportKeycardPDF from './keycardPDF';
import jwtDecode from 'jwt-decode'

const allResidential = graphql`
    query keyCardListQuery($search: String, $first: Int, $last: Int){
        selfProject{
            name
            address
            logo
        }
        allResidential(search: $search, first: $first, last: $last, queryType:"key_card"){
            totalCount
            edges{
                node{
                    id
                    name
                    keyCard{
                        edges{
                            node{
                                id
                                keyCardCode
                                keyCardActive
                            }
                        }
                    }
                }
            }
        }
    }
`;

class KeyCardList extends ComponentPagination {

    constructor(props) {
        super(props);
        this.state.search = '';
        this.state.loading = false;
        this.state.allResidentialKeyCard = []
        this.state.dataExport = []
        this.state.project_name = "";
        this.state.namePrint = "";
        this.onChangeSearch = this.onChangeSearch.bind(this);
    }
    componentDidMount() {
        this.getData()
    }

    getData = () => {
        fetchQuery(environment, allResidential, { search: this.state.search }).then(response => {
            if (response?.allResidential) {
                let nameToken = jwtDecode(localStorage.getItem("token"))
                this.setState({
                    allResidentialKeyCard: response?.allResidential?.edges,
                    project_name: response?.selfProject?.name,
                    namePrint: nameToken.first_name + " " + nameToken.last_name,
                    dataExport: response?.allResidential?.edges,
                })
            }
        })
    }

    onChangeSearch(text) {
        this.setState({ search: text }, () => {
            this.getData()
        });
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <RegisterTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack="/register"
                                    LinkText={t('register:key_card')}
                                    boxHtmlText={
                                        <React.Fragment>
                                            <div className="row mt-2 d-flex justify-content-end" id='interest-report' style={{ position: 'absolute', marginRight: 24, right: 0, top: '1%' }} >

                                                <Dropdown >
                                                    <DropdownToggle id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>{i18next.t("Allaction:Print")}</DropdownToggle>
                                                    <DropdownMenu>
                                                        <ExportKeycardPDF
                                                            project_name={this.state.project_name}
                                                            dataExport={this.state.dataExport}
                                                            namePrint={this.state.namePrint}
                                                        />
                                                        <ExportKeyCardExcel
                                                            state={this.state}
                                                        />
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div >
                                        </React.Fragment>

                                    }
                                />

                                <div className="content-inner">
                                    <div className="row justify-content-end mb-2">
                                        <div className="col-3">
                                            <Search
                                                callback_function={this.onChangeSearch}
                                                search={this.state.search}
                                            />
                                        </div>
                                    </div>

                                    <QueryRenderer
                                        environment={environment}
                                        query={allResidential}
                                        cacheConfig={{ use_cache: false }}
                                        variables={{
                                            search: this.state.search,
                                            first: this.state.first,
                                            last: this.state.last,
                                        }}
                                        render={({ error, props }) => {
                                            if (error) {
                                                return <div className="alert alert-danger"
                                                    role="alert">{error.message}</div>;
                                            } else if (props) {
                                                return <React.Fragment>
                                                    <div className="card fade-up">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover">
                                                                <thead className="thead-light">
                                                                    <Translation>
                                                                        {t =>
                                                                            <tr>
                                                                                <th>{t("keycard:Room")}</th>
                                                                                <th>{t("keycard:Keycard No.")}</th>
                                                                            </tr>}
                                                                    </Translation>
                                                                </thead>

                                                                <tbody>
                                                                    {
                                                                        props.allResidential.edges.map((residential_key_card) =>
                                                                            <tr key={residential_key_card.node.id}>
                                                                                <td width="150">
                                                                                    <Link to={{
                                                                                        pathname: "/register/residential/detail/key-card-detail/" + residential_key_card.node.id
                                                                                    }}>
                                                                                        {residential_key_card.node.name}
                                                                                    </Link>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        residential_key_card.node.keyCard.edges.map((key_card) =>
                                                                                            <React.Fragment key={key_card.node.id}>
                                                                                                {
                                                                                                    key_card.node.keyCardActive &&
                                                                                                    <span className="pr-5">
                                                                                                        {key_card.node.keyCardCode}
                                                                                                    </span>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <Pagination changePage={this.changePage}
                                                            first={this.state.first}
                                                            last={this.state.last}
                                                            totalCount={props.allResidential.totalCount} />
                                                    </div>
                                                </React.Fragment>
                                            }
                                            return <Loading />
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default KeyCardList;
