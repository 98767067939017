import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import SearchSelect from "../../libs/searchSelect";
import {QueryRenderer} from "react-relay";

const allContact = graphql`
    query selectContactDropDownQuery($search: String, $contact_type: String, $payment_type: String) {
        contactViewer {
            id
            allContact(search: $search, typeOfContact: $contact_type , typeOfPayment: $payment_type, isActive: true){
                edges{
                    node{
                        id
                        refNumber
                        name
                        firstName
                        lastName
                        ownerName
                        renterName
                        agentName
                    }
                }
            }
        }
    }
`;

class SelectContact extends Component {

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={allContact}
                cacheConfig={{use_cache: false}}
                variables={{
                    contact_type: this.props.contact_type,
                    payment_type: this.props.payment_type,
                }}
                render={({error, props}) => {

                    if (error) {
                        return <div className="alert alert-danger"
                                    role="alert">{error.message}</div>;
                    } else if (props) {
                        let all_contact = [];

                        if (this.props.asset && this.props.notOnContact) {
                            let other_object = [
                                {
                                    node: {
                                        firstName: '',
                                        id: 'other_select',
                                        lastName: '',
                                        name: '',
                                        refNumber: 'อื่นๆ โปรดระบุ',

                                    }
                                }
                            ];
                            all_contact = [...props.contactViewer.allContact.edges, ...other_object];
                        } else if(this.props.asset && !this.props.notOnContact){
                            let other_object = [
                                {
                                    node: {
                                        firstName: '',
                                        id: 'other_select',
                                        lastName: '',
                                        name: '',
                                        refNumber: 'อื่นๆ โปรดระบุ',

                                    }
                                }, {
                                    node: {
                                        firstName: '',
                                        id: 'on_contact',
                                        lastName: '',
                                        name: '',
                                        refNumber: 'ไม่ระบุ',

                                    }
                                },
                            ];
                            all_contact = [...props.contactViewer.allContact.edges, ...other_object];
                        }else {
                            all_contact = props.contactViewer.allContact.edges
                        }

                        return <React.Fragment>
                            <SearchSelect onChange={this.props.handleInputChange}
                                          value={this.props.contact}
                                          name={this.props.name} placeholder="รายการ"
                                          queryObject={all_contact}
                                          keyOfValue="id" require={this.props.require}
                                          keyOfLabel={`refNumber`}
                                          contact_key={this.props.contact_key}
                                          disabled={this.props.disabled}
                                          get_only_name = {this.props.get_only_name ? this.props.get_only_name : false}
                                          role={this.props.role}
                                          asset={true}

                            />
                        </React.Fragment>
                    }
                }}
            />
        )
    }
}

export default SelectContact;
