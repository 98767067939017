import { graphql } from "babel-plugin-relay/macro";

const queryReportRegister = graphql`
  query queryReportRegisterQuery($yearId: String, $chartOfAccountId_In: [String], $group_IssuedDate_Gte: Date, $group_IssuedDate_Lte: Date) {
    allChartOfAccountBudget(yearId: $yearId, chartOfAccountId_In: $chartOfAccountId_In){
      edges{
        node{
          totalBudget
          chartOfAccount{
            id 
            name 
            nameEn 
            chartOfAccountCode 
            accountRecord(group_IssuedDate_Gte: $group_IssuedDate_Gte, group_IssuedDate_Lte: $group_IssuedDate_Lte){
              edges{
                node {
                    id name debit credit added
                    group {
                        id name issuedDate refNumber totalDebit totalCredit refTransaction category
                    }
                }
              }
            }
          }
          monthBudget{
            edges{
              node{
                months
                budget
              }
            }
          }
        }
      }
    } 
  }
`;

export default queryReportRegister;
