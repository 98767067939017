import React from "react";

class Loading extends React.PureComponent {

    render() {
        return (
            <div id="loading">
                {/* <div className="preloader-1">
                    <div className="text">Loading</div>
                    <div className="loading-line line-1"/>
                    <div className="loading-line line-2"/>
                    <div className="loading-line line-3"/>
                    <div className="loading-line line-4"/>
                    <div className="loading-line line-5"/>
                    <div className="loading-line line-6"/>
                    <div className="loading-line line-7"/>
                    <div className="loading-line line-8"/>
                    <div className="loading-line line-9"/>
                </div> */}
                 <div className="container-fluid box fixRequestFast text-center" >
                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/loading.gif'} alt="loading" />
                  </div>
            </div>
        );
    }

}

export default Loading;
