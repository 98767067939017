import React, {Component} from 'react';
import Header from '../../components/header/';
import Sidebar from '../../components/sidebar/';
import Wrapper from '../../components/wrapper/';
import WrapperContent from '../../components/wrapper/wrapperContent';
import SurveyCreate from './surveyCreate';
import SurveyEdit from "./surveyEdit";

class SurveyCreateOrUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: false,
        };
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            this.setState({
                id: this.props.match.params.id
            });
        }
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    {this.props.match.params.status === 'create' &&
                        <SurveyCreate/>
                    }
                    {this.props.match.params.status === 'edit' &&
                        <SurveyEdit survey_id={this.state.id}/>
                    }
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default SurveyCreateOrUpdate;