import { commitMutation } from 'react-relay'
import { Component } from 'react';
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import i18n from "i18next";
import React from 'react';
import Swal from 'sweetalert2';
import environment from "../../env/environment";
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper';
import WrapperContent from '../../components/wrapper/wrapperContent';
import SettingInvoiceTempateView from './settingInvoiceTempateView'
import "../../project/projectDashboard.scss"

const mutation = graphql`
   mutation settingInvoiceTemplateMutation ($input: CreateAndUpdateInvoiceTemplateSettingInput!) {
    createAndUpdateInvoiceTemplateSetting (input: $input) {
        ok
        message
    }
}`


const query = graphql`
 query settingInvoiceTemplateQuery{
    checkInvoiceTemplate{
        edges{
            node{
                id 
                templateFirst
                descriptionFirst
                topicThFirst
                topicEnFirst
                templateSecond
                descriptionSecond
                topicThSecond,
                topicEnSecond,
                templateThird,
                descriptionThird,
                topicThThird,
                topicEnThird,
                templateFourth
                descriptionFourth
                topicThFourth,
                topicEnFourth,
                templateFifth
                descriptionFifth
                topicThFifth
                topicEnFifth
            }
        }
    }
}
`;

const setting_detail = {
    id: '',
    numberOfDays: 0,
    description: "อ้างถึงข้อบังคับนิติบุคคลฯ กรณีเจ้าของร่วมผิดนัดไม่ชำระในเวลาที่กำหนดต้องเสียเงินเพิ่มและค่าปรับในอัตราร้อยละ 12 ต่อปี กรณีค้างชำระตั้งแต่" +
        "6 เดือนขึ้นไป ต้องเสียเงินเพิ่มและค่าปรับในอัตราร้อยละ 20 ต่อปีของจำนวนเงินที่ค้างชำระโดยไม่คิดทบต้น และอาจถูกระงับการให้" +
        "บริการส่วนรวม",
    description2: "",
    payment_channel: {
        bank: {
            bankAccountStatus: false,
            bank_payment: {
                bankName: '',
                accountNumber: '',
                accountName: '',
                accountNumberId: '',
                branch: ''
            },
        },
        cheque: {
            paymentCheck: false,
        },
        cash: {
            paymentTimeStatus: false,
            paymentTime1: new Date(),
            paymentTime2: new Date(),
        },
        channelDescription: "-",
    },
    contactName: "",
    contactPhone: "",
    signerName: "",
    signerPosition: ""

}

class settingInvoiceTemplate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            check_templete: false,
            descriptionFirst:'',
            descriptionSecond:'',
            descriptionThird:'',
            template:'template1',
            redirectToList: false,
            langTH:'',
            langEN:'',
            langTH1:'',
            langEN1:'',
            langTH2:'',
            langEN2:'',
            langTH3:'',
            langEN3:'',
            langTH4:'',
            langEN4:'',
            langTH5:'',
            langEN5:''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputTextChange = this.handleInputTextChange.bind(this)

    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        fetchQuery(environment, query).then(data => {
            let result = ''
            if(data.checkInvoiceTemplate?.edges.length > 0){
                if(data.checkInvoiceTemplate.edges[0].node.templateFirst === true){
                    result = 'template1'
                }else if(data.checkInvoiceTemplate.edges[0].node.templateSecond === true){
                    result = 'template2'
                }else if(data.checkInvoiceTemplate.edges[0].node.templateThird === true){
                    result = 'template3'
                }else if(data.checkInvoiceTemplate.edges[0].node.templateFourth === true){
                    result = 'template4'
                }else if(data.checkInvoiceTemplate.edges[0].node.templateFifth === true){
                    result = 'template5'
                }else{
                    result = 'template1' //default
                }
            }else{
                result = 'template1' //default
            }

            this.setState({
                template: result,
                langTH1: data?.checkInvoiceTemplate?.edges[0]?.node?.topicThFirst ,
                langEN1: data?.checkInvoiceTemplate?.edges[0]?.node?.topicEnFirst ,
                langTH2: data?.checkInvoiceTemplate?.edges[0]?.node?.topicThSecond,
                langEN2: data?.checkInvoiceTemplate?.edges[0]?.node?.topicEnSecond,
                langTH3: data?.checkInvoiceTemplate?.edges[0]?.node?.topicThThird,
                langEN3: data?.checkInvoiceTemplate?.edges[0]?.node?.topicEnThird,
                langTH4: data?.checkInvoiceTemplate?.edges[0]?.node?.topicThFourth,
                langEN4: data?.checkInvoiceTemplate?.edges[0]?.node?.topicEnFourth,
                langTH5: data?.checkInvoiceTemplate?.edges[0]?.node?.topicThFifth,
                langEN5: data?.checkInvoiceTemplate?.edges[0]?.node?.topicEnFifth
            })
        
        }).catch(err => {
            alert(err)
        })
    }

    handleInputChange(e) {
        this.setState({
            template: e.target.value,
            [e.target.name]: e.target.value
        });
    }

 
    handleInputTextChange(e) {
        this.setState({
           [e.target.name]: e.target.value ,
        });
    }

    submit = () => {
        let variables = {
            input: {
                invoiceTemplate: {
                    templateFirst: this.state.template === 'template1' ? true : false,
                    topicThFirst: this.state.langTH1,
                    topicEnFirst: this.state.langEN1,
                    // descriptionFirst:this.state.descriptionFirst,
                    templateSecond:this.state.template === 'template2' ? true : false,
                    topicThSecond: this.state.langTH2,
                    topicEnSecond: this.state.langEN2,
                    // descriptionSecond:this.state.descriptionSecond,
                    templateThird:this.state.template === 'template3' ? true : false,
                    topicThThird: this.state.langTH3,
                    topicEnThird: this.state.langEN3,
                    // descriptionThird:this.state.descriptionThird
                    templateFourth: this.state.template === 'template4' ? true : false,
                    topicThFourth: this.state.langTH4,
                    topicEnFourth: this.state.langEN4,
                    templateFifth: this.state.template === 'template5' ? true : false,
                    topicThFifth: this.state.langTH5,
                    topicEnFifth: this.state.langEN5,
                }
            }  
        };
  
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    if (response.createAndUpdateInvoiceTemplateSetting.ok) {
                        Swal.fire(i18n.t('settingInvoiceTemplate:Successful'), '', 'success').then(() => {
                            window.location.reload()
                            this.props.history.push("/setting/setting-invoice-template")
                        })
                    } else {
                        Swal.fire(i18n.t('settingInvoiceTemplate:Saved failed'), i18n.t('settingInvoiceTemplate:Please check again'), 'warning');
                    }
                },
                onError: (err) => {
                    Swal.fire('Error!', i18n.t('settingInvoiceTemplate:Please try again'), 'warning');
                },
            },
        )
    }

    render() {
  
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                <Translation>
                        {
                            t => 
                            <div className="container-fluid box" id="setting-invoice-template">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/setting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <Translation>
                                                {
                                                    t => <span>{t('MenuName:invoice template setting')}</span>
                                                }
                                            </Translation>
                                        </h3>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-5'>
                                        <div>
                                            <div className="form-check m-2 pb-2">
                                                <input className="form-check-input" type="radio"
                                                name="check_templete" checked={this.state.template === 'template1' ? 'checked' :''}
                                                onChange={this.handleInputChange} 
                                                value="template1" 
                                                />
                                                <label className="form-check-label" htmlFor="checkTypeAll"> 
                                                <Translation>
                                                {
                                                    t => <h6>{t('settingInvoiceTemplate:template1')}</h6>
                                                }
                                                 </Translation>
                                               </label>
                                               <Translation>
                                                {
                                                 t => 
                                                    <textarea cols="" rows="2" className="form-control" id="descriptionFirst"
                                                     name="descriptionFirst" maxLength={256} placeholder={t("settingInvoiceTemplate:description")}
                                                     value="ใบแจ้งหนี้จะแสดงที่อยู่ของเจ้าของร่วม/สมาชิก,ลูกหนี้ ตามข้อมูลในหน้าตั้งค่าผู้ติดต่อ"
                                                     disabled
                                                    />
                                                }
                                                </Translation>
                                                {
                                                this.state.template ===  'template1' && 
                                                    <div className='mt-3'>
                                                    <h6>หัวข้อเอกสาร  <small className='text-danger'>* ใส่ได้ไม่เกิน 20 ตัวอักษร</small></h6>
                                                    <div className='row'>
                                                       <div className='col-6'>
                                                        <input name="langTH1" className="form-control" placeholder='ใบแจ้งหนี้' onBlur={this.handleInputTextChange} maxLength={20}
                                                        defaultValue={this.state.langTH1}
                                                        /></div>
                                                       <div className='col-2'><h6 className='pt-2'>TH</h6></div>
                                                    </div>
                                                    <div className='row'>
                                                       <div className='col-6 pt-2'>
                                                        <input name="langEN1" className="form-control" onBlur={this.handleInputTextChange} maxLength={20} placeholder='Invoice'
                                                         defaultValue={this.state.langEN1}/></div>
                                                       <div className='col-2'><h6 className='pt-4'>EN</h6></div>
                                                    </div>
                                                  </div>
                                                }
                                            </div>
                                            <div className="form-check m-2 pb-2">
                                                <input className="form-check-input" type="radio"
                                                 name="check_templete" checked={this.state.template  ===  'template2'  ? 'checked' :''}
                                                 value="template2" 
                                                  onChange={this.handleInputChange} 
                                                  />
                                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                                <Translation>
                                                    {
                                                        t => <h6>{t('settingInvoiceTemplate:template2')}</h6>
                                                    }
                                                 </Translation>    
                                                </label>
                                                <Translation>
                                                    {
                                                     t => 
                                                        <textarea cols="" rows="4" className="form-control" id="descriptionSecond"
                                                            name="descriptionSecond" maxLength={256} placeholder={t("settingInvoiceTemplate:description")}
                                                            value="ใบแจ้งหนี้จะแสดงรหัสของเจ้าของร่วม/สมาชิก,ลูกหนี้ ตามข้อมูลในหน้าตั้งค่าผู้ติดต่อ และจะแสดงที่อยู่ตามที่อยู่โครงการจากเมนูข้อมูลโครงการ"
                                                            disabled
                                                         />
                                                    }
                                                 </Translation>   
                                                 {
                                                    this.state.template ===  'template2' && 
                                                    <div className='mt-3'>
                                                        <h6>หัวข้อเอกสาร  <small className='text-danger'>* ใส่ได้ไม่เกิน 20 ตัวอักษร</small></h6>
                                                        <div className='row'>
                                                            <div className='col-6'><input name="langTH2" className="form-control" onBlur={this.handleInputTextChange} 
                                                            defaultValue={this.state.langTH2} placeholder='ใบแจ้งหนี้' maxLength={20} /></div>
                                                            <div className='col-2'><h6 className='pt-2'>TH</h6></div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-6 pt-2'><input name="langEN2" className="form-control" onBlur={this.handleInputTextChange} 
                                                            defaultValue={this.state.langEN2} placeholder='Invoice' maxLength={20}/></div>
                                                            <div className='col-2'><h6 className='pt-4'>EN</h6></div>
                                                        </div>
                                                   </div> 
                                                 }
                                                 
                                            </div> 
                                            <div className="form-check m-2 pb-2">
                                                <input className="form-check-input" type="radio" name="check_templete" value="template3"  
                                                     onChange={this.handleInputChange}  
                                                    checked={this.state.template ===  'template3' ? 'checked' :''} 
                                                />
                                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                                <Translation>
                                                    {
                                                        t => <h6>{t('settingInvoiceTemplate:template3')}</h6>
                                                    }
                                                 </Translation>        
                                                </label>
                                                <Translation>
                                                    {
                                                     t => 
                                                        <textarea rows="2" className="form-control" id="descriptionThird"
                                                                name="descriptionThird" maxLength={256} placeholder={t("settingInvoiceTemplate:description")}
                                                                value="ปรับบรรทัดการแสดงผลในส่วนของที่อยู่โครงการ" disabled
                                                        />
                                                    }
                                                </Translation>
                                                {
                                                this.state.template ===  'template3' && 
                                                    <div className='mt-3'>
                                                    <h6>หัวข้อเอกสาร  <small className='text-danger'>* ใส่ได้ไม่เกิน 20 ตัวอักษร</small></h6>
                                                    <div className='row'>
                                                        <div className='col-6'><input name="langTH3" className="form-control" onBlur={this.handleInputTextChange}
                                                         placeholder='ใบแจ้งหนี้' maxLength={20}
                                                         defaultValue={this.state.langTH3}/></div>
                                                        <div className='col-2'><h6 className='pt-2'>TH</h6></div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-6 pt-2'><input name="langEN3" className="form-control" onBlur={this.handleInputTextChange} 
                                                        placeholder='Invoice' maxLength={20}
                                                        defaultValue={this.state.langEN3}/></div>
                                                        <div className='col-2'><h6 className='pt-4'>EN</h6></div>
                                                    </div>
                                                </div>     
                                                }
                                                
                                            </div> 
                                            <div className="form-check m-2 pb-2">
                                                <input className="form-check-input" type="radio" name="check_templete" value="template4"  
                                                     onChange={this.handleInputChange}  
                                                    checked={this.state.template ===  'template4' ? 'checked' :''} 
                                                />
                                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                                <Translation>
                                                    {
                                                        t => <h6>{t('settingInvoiceTemplate:template4')}</h6>
                                                    }
                                                 </Translation>        
                                                </label>
                                                <Translation>
                                                    {
                                                    t => 
                                                    <textarea rows="2" className="form-control" id="descriptionThird"
                                                        name="descriptionThird" maxLength={256} placeholder={t("settingInvoiceTemplate:description")}
                                                        value="รูปแบบใบแจ้งหนี้รองรับสำหรับการแจ้งหนี้ 12 รายการ (กรณีออกใบแจ้งหนี้เป็นรายปี)" disabled 
                                                    />
                                                    }
                                                </Translation>  
                                                {
                                                this.state.template ===  'template4' && 
                                                    <div className='mt-3'>
                                                    <h6>หัวข้อเอกสาร  <small className='text-danger'>* ใส่ได้ไม่เกิน 20 ตัวอักษร</small></h6>
                                                    <div className='row'>
                                                        <div className='col-6'><input name="langTH4" className="form-control" onBlur={this.handleInputTextChange}
                                                         defaultValue={this.state.langTH4} placeholder='ใบแจ้งหนี้' maxLength={20}/></div>
                                                        <div className='col-2'><h6 className='pt-2'>TH</h6></div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-6 pt-2'><input name="langEN4" className="form-control" onBlur={this.handleInputTextChange} 
                                                        defaultValue={this.state.langEN4} placeholder='Invoice' maxLength={20}/></div>
                                                        <div className='col-2'><h6 className='pt-4'>EN</h6></div>
                                                    </div>
                                                </div>  
                                                }
                                                
                                            </div> 
                                            <div className="form-check m-2 pb-2">
                                                <input className="form-check-input" type="radio" name="check_templete" value="template5"  
                                                     onChange={this.handleInputChange}  
                                                     checked={this.state.template ===  'template5' ? 'checked' :''} 
                                                />
                                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                                <Translation>
                                                    {
                                                        t => <h6>{t('settingInvoiceTemplate:template5')}</h6>
                                                    }
                                                 </Translation>        
                                                </label>
                                                <Translation>
                                                    {t => 
                                                        <textarea cols="" rows="2" className="form-control" id="descriptionThird"
                                                            name="descriptionThird" maxLength={256} placeholder={t("settingInvoiceTemplate:description")}
                                                            value="รูปแบบใบแจ้งหนี้รองรับสำหรับการแสดงผลจำนวนเงินค้างชำระรวม" disabled
                                                        />
                                                    }
                                                </Translation> 
                                                {
                                                     this.state.template ===  'template5' && 
                                                     <div className='mt-3'>
                                                     <h6>หัวข้อเอกสาร  <small className='text-danger'>* ใส่ได้ไม่เกิน 20 ตัวอักษร</small></h6>
                                                     <div className='row'>
                                                        <div className='col-6'><input name="langTH5" className="form-control" onBlur={this.handleInputTextChange} 
                                                        defaultValue={this.state.langTH5} placeholder='ใบแจ้งหนี้' maxLength={20}/></div>
                                                        <div className='col-2'><h6 className='pt-2'>TH</h6></div>
                                                     </div>
                                                     <div className='row'>
                                                        <div className='col-6 pt-2'><input name="langEN5" className="form-control" onBlur={this.handleInputTextChange} 
                                                        defaultValue={this.state.langEN5} placeholder='Invoice' maxLength={20}/></div>
                                                        <div className='col-2'><h6 className='pt-4'>EN</h6></div>
                                                     </div>
                                                   </div> 
                                                }
                                               
                                            </div> 
                                        </div>
                                        <div>
                                        <button
                                            type="button"
                                            className="btn add"
                                            style={{ 
                                            height: 48, 
                                            marginTop: "30px", 
                                            background: "#0382FA", 
                                            color: "#ffffff",
                                            float:"right"
                                            }}
                                            onClick={this.submit}
                                        >
                                           <Translation>
                                                {
                                                    t => <span>{t('Allaction:save')}</span>
                                                }
                                            </Translation>
                                        </button>
                                        </div>
                                    </div>
                                
                                    <div className='col-md-6'>
                                        <SettingInvoiceTempateView setting_detail={setting_detail} type={this.state.template}
                                            langTH1={this.state.langTH1} langEN1={this.state.langEN1}
                                            langTH2={this.state.langTH2} langEN2={this.state.langEN2}
                                            langTH3={this.state.langTH3} langEN3={this.state.langEN3}
                                            langTH4={this.state.langTH4} langEN4={this.state.langEN4}
                                            langTH5={this.state.langTH5} langEN5={this.state.langEN5}  
                                        />
                                        
                                    </div>
                                   
                                </div>
                               
                                </div>
                        }
                    </Translation>

                   

                </WrapperContent>
            </Wrapper>
        );
    }


}
export default settingInvoiceTemplate;