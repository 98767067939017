/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateRoomInput = {|
  name?: ?string,
  detail?: ?string,
  status?: ?string,
  residentialId?: ?string,
  houseHolderId?: ?string,
  roomType?: ?string,
  clientMutationId?: ?string,
|};
export type complaintCreateModalMutationVariables = {|
  input: CreateRoomInput
|};
export type complaintCreateModalMutationResponse = {|
  +createRoom: ?{|
    +newRoom: ?{|
      +id: string
    |}
  |}
|};
export type complaintCreateModalMutation = {|
  variables: complaintCreateModalMutationVariables,
  response: complaintCreateModalMutationResponse,
|};
*/


/*
mutation complaintCreateModalMutation(
  $input: CreateRoomInput!
) {
  createRoom(input: $input) {
    newRoom {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRoomPayload",
    "kind": "LinkedField",
    "name": "createRoom",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RoomNode",
        "kind": "LinkedField",
        "name": "newRoom",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "complaintCreateModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "complaintCreateModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "071380b00aa038b308e3c93afa087daa",
    "id": null,
    "metadata": {},
    "name": "complaintCreateModalMutation",
    "operationKind": "mutation",
    "text": "mutation complaintCreateModalMutation(\n  $input: CreateRoomInput!\n) {\n  createRoom(input: $input) {\n    newRoom {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4a7b206bec657339c65b7af0f4d7a275';

module.exports = node;
