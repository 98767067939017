import React, {Component} from 'react';

class noMatch extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h4>404</h4>
                    </div>
                </div>
            </div>
        );
    }
}

export default noMatch;