/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getAdvertiseQueryVariables = {|
  first?: ?number,
  last?: ?number,
|};
export type getAdvertiseQueryResponse = {|
  +allAdvertise: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +detail: ?string,
        +link: ?string,
        +image: ?string,
        +customerName: ?string,
        +types: ?string,
        +updated: any,
      |}
    |}>
  |}
|};
export type getAdvertiseQuery = {|
  variables: getAdvertiseQueryVariables,
  response: getAdvertiseQueryResponse,
|};
*/


/*
query getAdvertiseQuery(
  $first: Int
  $last: Int
) {
  allAdvertise(first: $first, last: $last) {
    edges {
      node {
        id
        title
        detail
        link
        image
        customerName
        types
        updated
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      }
    ],
    "concreteType": "AdvertiseNodeConnection",
    "kind": "LinkedField",
    "name": "allAdvertise",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdvertiseNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvertiseNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "types",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getAdvertiseQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getAdvertiseQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a7a93aa761b93b9ce5dc6eeb5a1510d2",
    "id": null,
    "metadata": {},
    "name": "getAdvertiseQuery",
    "operationKind": "query",
    "text": "query getAdvertiseQuery(\n  $first: Int\n  $last: Int\n) {\n  allAdvertise(first: $first, last: $last) {\n    edges {\n      node {\n        id\n        title\n        detail\n        link\n        image\n        customerName\n        types\n        updated\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c40289c6a84dd194b8ee89624a690b4';

module.exports = node;
