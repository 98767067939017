import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import CreditNoteList from './creditNoteList';
import CreditNoteCreate from './creditNoteCreate';
import CreditNoteView from './creditNoteView';


class CreditNote extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={CreditNoteList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status/:id?`} component={CreditNoteCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id`} component={CreditNoteView}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default CreditNote;