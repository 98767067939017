import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Translation } from "react-i18next";
import "./styles/commonArea.scss";

class Navigation extends Component {
  render() {
    return (
      <div className="row" id="navigation">
        <div className="col">
          <Translation>
            {(t) => (
              <ul>
                <li>
                  <NavLink key={"newBookingSingha_Booking::All"} exact={true} activeClassName="current" to="/contact/global-area/all">
                    <div>{t("newBookingSingha_Booking:All")}</div>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink key="ยังไม่ยืนยัน" activeClassName="current" to="/contact/global-area/unconfirmed">
                        ยังไม่ยืนยัน
                  </NavLink>
                </li> */}
                <li>
                  <NavLink key={"newBookingSingha_Booking::Confirm"} activeClassName="current" to="/contact/global-area/confirmed">
                  {t("newBookingSingha_Booking:Confirm")}
                  </NavLink>
                </li>
                <li>
                  <NavLink key={"newBookingSingha_Booking::Used"} activeClassName="current" to="/contact/global-area/used">
                  {t("newBookingSingha_Booking:Used")}
                  </NavLink>
                </li>
                <li>
                  <NavLink key={"newBookingSingha_Booking::cancel"} activeClassName="current" to="/contact/global-area/cancel">
                  {t("newBookingSingha_Booking:Void")}
                  </NavLink>
                </li>
              </ul>
            )}
          </Translation>
        </div>
      </div>
    );
  }
}

export default Navigation;
