import React from "react";
import "../../styles/commonArea.scss";
import './commonArea.scss'
import { Translation } from "react-i18next";

const TimeToPreLocation = ({
  HandleOnChange,
  state: {
    timeToPreLocation,
    timeToBooking,
    typeTobooking
  },
  onClickSelectBtn,
}) => {
  return (
    typeTobooking &&
    <Translation>
      {t =>
        <div className="booking-form mt-10 claim">
          <div className="switch-languages-div d-flex justify-content-between create usedAll">
            <p className="headerLabel24 fw-400">{t("newBookingSingha_Create_step2:Time period to prepare the facility")}</p>
          </div>

          <label className="headerLabel18 fw-400">{t("newBookingSingha_Create_step2:In the event that a facility requires preparation, such as cleaning")}  </label>

          <div className="usedAll">
            <button onClick={() => onClickSelectBtn(`timeToPreLocation`, null)}
              className={`btnSelectItem m-3 ml-0 floatLeft 
            ${timeToPreLocation === null ? `selected` : ``}`} type="button">
              <label className="label16 fw-400 mt-2">{t("newBookingSingha_Create_step2:None")}</label>
            </button>

            <button onClick={() => onClickSelectBtn(`timeToPreLocation`, 15)}
              className={`btnSelectItem m-3 ml-0 floatLeft 
            ${timeToBooking > 15 ? `disabled` : ``}
            ${timeToPreLocation === 15 ? `selected` : ``}`} type="button"
              disabled={timeToBooking > 15}>
              <label className="label16 fw-400 mt-2">{t("newBookingSingha_Create_step2:15 Minutes")}</label>
            </button>

            <button onClick={() => onClickSelectBtn(`timeToPreLocation`, 30)}
              className={`btnSelectItem m-3 ml-0 floatLeft 
            ${timeToBooking > 30 ? `disabled` : ``}
            ${timeToPreLocation === 30 ? `selected` : ``}`} type="button"
              disabled={timeToBooking > 30}>
              <label className="label16 fw-400 mt-2">{t("newBookingSingha_Create_step2:30 Minutes")}</label>
            </button>

            <button onClick={() => onClickSelectBtn(`timeToPreLocation`, 60)}
              className={`btnSelectItem m-3 ml-0 floatLeft 
            ${timeToPreLocation === 60 ? `selected` : ``}`} type="button">
              <label className="label16 fw-400 mt-2">{t("newBookingSingha_Create_step2:60 Minutes")}</label>
            </button>
          </div>

          <div style={{ clear: 'both' }}></div>
        </div>
      }
    </Translation>
  );
};

export default TimeToPreLocation;
