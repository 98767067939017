/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type balanceSheetAccountQueryVariables = {|
  startDateFirst?: ?any,
  endDateFirst?: ?any,
  startDateSecond?: ?any,
  endDateSecond?: ?any,
  period?: ?string,
|};
export type balanceSheetAccountQueryResponse = {|
  +allAccountProjectManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +tableAccount: ?any,
      |}
    |}>
  |},
  +chartOfAccountBalanceSheetAccount: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +chartOfAccountCode: string,
        +col1Debit: ?number,
        +col1Credit: ?number,
        +col2Debit: ?number,
        +col2Credit: ?number,
        +totalHistoricalDebit: ?number,
        +totalHistoricalCredit: ?number,
      |}
    |}>
  |},
  +capitalCash: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +nameEn: ?string,
        +chartOfAccountCode: string,
        +col1Debit: ?number,
        +col1Credit: ?number,
        +col2Debit: ?number,
        +col2Credit: ?number,
        +totalHistoricalDebit: ?number,
        +totalHistoricalCredit: ?number,
      |}
    |}>
  |},
  +chartOfAccountSummaryIncomeExpenseDiff: ?{|
    +yearFirst: ?number,
    +yearSecond: ?number,
    +accruedSurplusFirst: ?number,
    +accruedSurplusSecond: ?number,
  |},
|};
export type balanceSheetAccountQuery = {|
  variables: balanceSheetAccountQueryVariables,
  response: balanceSheetAccountQueryResponse,
|};
*/


/*
query balanceSheetAccountQuery(
  $startDateFirst: DateTime
  $endDateFirst: DateTime
  $startDateSecond: DateTime
  $endDateSecond: DateTime
  $period: String
) {
  allAccountProjectManager {
    edges {
      node {
        id
        tableAccount
      }
    }
  }
  chartOfAccountBalanceSheetAccount(startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
    edges {
      node {
        id
        name
        chartOfAccountCode
        col1Debit
        col1Credit
        col2Debit
        col2Credit
        totalHistoricalDebit
        totalHistoricalCredit
      }
    }
  }
  capitalCash: chartOfAccountBalanceSheetAccount(startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond, typeAccount: "3000") {
    edges {
      node {
        id
        name
        nameEn
        chartOfAccountCode
        col1Debit
        col1Credit
        col2Debit
        col2Credit
        totalHistoricalDebit
        totalHistoricalCredit
      }
    }
  }
  chartOfAccountSummaryIncomeExpenseDiff(startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond, period: $period) {
    yearFirst
    yearSecond
    accruedSurplusFirst
    accruedSurplusSecond
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDateFirst"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDateSecond"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "period"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDateFirst"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDateSecond"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "kind": "Variable",
  "name": "endDateFirst",
  "variableName": "endDateFirst"
},
v7 = {
  "kind": "Variable",
  "name": "endDateSecond",
  "variableName": "endDateSecond"
},
v8 = {
  "kind": "Variable",
  "name": "startDateFirst",
  "variableName": "startDateFirst"
},
v9 = {
  "kind": "Variable",
  "name": "startDateSecond",
  "variableName": "startDateSecond"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "col1Debit",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "col1Credit",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "col2Debit",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "col2Credit",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalHistoricalDebit",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalHistoricalCredit",
  "storageKey": null
},
v18 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountProjectManagerNodeConnection",
    "kind": "LinkedField",
    "name": "allAccountProjectManager",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountProjectManagerNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountProjectManagerNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tableAccount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "concreteType": "ChartOfAccountNodeConnection",
    "kind": "LinkedField",
    "name": "chartOfAccountBalanceSheetAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "capitalCash",
    "args": [
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      {
        "kind": "Literal",
        "name": "typeAccount",
        "value": "3000"
      }
    ],
    "concreteType": "ChartOfAccountNodeConnection",
    "kind": "LinkedField",
    "name": "chartOfAccountBalanceSheetAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameEn",
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v6/*: any*/),
      (v7/*: any*/),
      {
        "kind": "Variable",
        "name": "period",
        "variableName": "period"
      },
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "concreteType": "ChartOfAccountSummaryIncomeExpenseDiff",
    "kind": "LinkedField",
    "name": "chartOfAccountSummaryIncomeExpenseDiff",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "yearFirst",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "yearSecond",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accruedSurplusFirst",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accruedSurplusSecond",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "balanceSheetAccountQuery",
    "selections": (v18/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "balanceSheetAccountQuery",
    "selections": (v18/*: any*/)
  },
  "params": {
    "cacheID": "64b03bee8a07ef66624ec8cdd12271da",
    "id": null,
    "metadata": {},
    "name": "balanceSheetAccountQuery",
    "operationKind": "query",
    "text": "query balanceSheetAccountQuery(\n  $startDateFirst: DateTime\n  $endDateFirst: DateTime\n  $startDateSecond: DateTime\n  $endDateSecond: DateTime\n  $period: String\n) {\n  allAccountProjectManager {\n    edges {\n      node {\n        id\n        tableAccount\n      }\n    }\n  }\n  chartOfAccountBalanceSheetAccount(startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {\n    edges {\n      node {\n        id\n        name\n        chartOfAccountCode\n        col1Debit\n        col1Credit\n        col2Debit\n        col2Credit\n        totalHistoricalDebit\n        totalHistoricalCredit\n      }\n    }\n  }\n  capitalCash: chartOfAccountBalanceSheetAccount(startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond, typeAccount: \"3000\") {\n    edges {\n      node {\n        id\n        name\n        nameEn\n        chartOfAccountCode\n        col1Debit\n        col1Credit\n        col2Debit\n        col2Credit\n        totalHistoricalDebit\n        totalHistoricalCredit\n      }\n    }\n  }\n  chartOfAccountSummaryIncomeExpenseDiff(startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond, period: $period) {\n    yearFirst\n    yearSecond\n    accruedSurplusFirst\n    accruedSurplusSecond\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ff0cf3a782d363cf16047de0df060ec6';

module.exports = node;
