import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import NavigationMenuSetup from "../libs/navigationMenuSetup";
import getSettingMenuData from "../libs/settingMenuData";
import Swal from "sweetalert2";
import './setting.scss'

// const query = graphql`
//     query settingTopMenuNavigationsQuery{
//         selfProject {
//             useAccount
//         }
//     }
// `;

class meetingAndScoreTopMenuNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = getSettingMenuData()
        this.state.useAccount = false
    }

    // componentWillMount() {

    //     fetchQuery(environment, query).then(response_data => {
    //         let useAccount = false;
    //         if(response_data.selfProject.useAccount) {
    //             useAccount = response_data.selfProject.useAccount
    //             this.setState({useAccount: useAccount})
    //         }
    //     });
    // }

    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
    }

    checkUseAccount(useAccount) {
        if (useAccount === false) {
            Swal.fire({
                // title: 'เพราะเรื่องเงิน \n คือเรื่องใหญ่',
                html:
                    '<p style="color: #2D2D30; font-weight: 500; font-size: 48px; line-height: 30px; margin-top: 40px">เพราะเรื่องเงิน</p>' +
                    '<br><p style="color: #2D2D30; font-weight: 600; font-size: 48px; line-height: 20px;">คือเรื่องใหญ่</p>' +
                    '<br><br><p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">หมดปัญหาเก็บเงินค่าส่วนกลางไม่ได้</p>' +
                    '<p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">ตรวจสอบยาก - เงินสูญหาย - หนี้ค้างชำระเยอะ</p>' +
                    '<br><p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">ขับเคลื่อนงานนิติบุคคลด้วยระบบ AI</p>' +
                    '<p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">จัดการทุกขั้นตอนเกี่ยวกับการเงินได้อย่างมีประสิทธิภาพ</p>' +
                    '<br><br><p style="color: #1578D7; font-weight: 500; font-size: 16px; line-height: 5px;">"ขณะนี้นิติบุคคลโครงการท่าน ยังไม่ได้เปิดใช้บริการฟังก์ชันสำหรับบัญชีและการเงิน</p>' +
                    '<p style="color: #1578D7; font-weight: 500; font-size: 16px; line-height: 10px;">กรุณาติดต่อทีมงาน Silverman Support เพื่อเปิดใช้บริการฟังก์ชันนี้"</p>' +
                    '<br><a href="https://silverman.app/" style="background-color:#1578D7; color: #FFFFFF; text-decoration: none; border: none; padding: 12px 24px; border-radius: 4px;">สมัครใช้บริการ</a>' +
                    '<br><br><br><p style="color: #B3B3B3; font-weight: 300; font-size: 12px; line-height: 5px;">ติดต่อสอบถาม www.silverman.app / E-mail: hello@silverman.app</p>' +
                    '<p style="color: #B3B3B3; font-weight: 300; font-size: 12px; line-height: 5px;">Tel: 081-4426888 / Line: @Silvermanapp / Facebook: Silverman App</p>',
                showConfirmButton: false,
                showCloseButton: true,
                width: 860,
            }).then(() => {
                // Reload the Page
                window.location.reload(false);
            });
        }
    }

    render() {
        return (
            <Translation>
                {t =>
                    <div className="bg-gray" id="setting-menu" style={{ height: "95vh", overflowX: "hidden" }}>
                        <div className={"row "
                            + (this.props.mini ? "mini " : "")
                            + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

                            {this.props.center &&
                                <div className="col-12 mt-5 mb-5"><h3 className="text-center">{t('navigation:meetingAndScore')}</h3></div>
                            }

                            <div className={"col-12 text-center" + (this.props.center ? " fade-up" : "")}>
                                <Link to={{ pathname: "https://silverman.app/foQus" }} target={'_blank'}>
                                    <img src={process.env.PUBLIC_URL + "/images/banner/foqus.png"} style={{ width: "auto", maxWidth: "100%", height: "68vh" }} 
                                    alt="meetingpic"/>
                                </Link>


                                <div className='col-sm-12' style={{ marginTop: "20px" ,marginBottom: "20px"}}>
                                    <Link to={{ pathname: "https://silverman.app/foQus" }} target={'_blank'}>
                                        <button className='btn btn-primary add' style={{ borderRadius: "20px", padding: "8px 16px ",
                                    width: "15%", maxWidth: "100%" }}>{t("MenuName:RegistorMeeting")}</button>
                                    </Link>

                                    
                                </div>

                            </div>
                        </div>
                    </div>
                }
            </Translation>

        );
    }
}

export default meetingAndScoreTopMenuNavigation;
