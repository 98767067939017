import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import SettingDashboard from './settingDashboard'

import NoMatch from "../components/noMatch";
import SettingAccount from "./settingAccount/settingAccountForm";
import SettingPeriodLockForm from "./settingPeriodLock/settingPeriodLockForm";
import SettingJuristic from "./settingJuristic/index"; 
import SettingNoteInvoice from "./settingNote/settingNoteInvoice";
import SettingNoteReceive from "./settingNote/settingNoteReceive";
// import SettingBringForward from './settingBringForward/settingBringForward';
import SettingBringForward from './settingBringForward/settingBringForward';
import SettingProjectManager from './settingProjectManager/settingProjectManager';
import SettingInvoiceTerms from './settingInvoice/index';
import SettingDeveloper from './settingDeveloper/settingDeveloper';
import SettingEnterpriseInformation from './settingEnterpriseInformation/settingEnterpriseInformation';
import SettingInvoiceCreate from './settingInvoice/settingInvoiceCreate'
import SettingInvoiceTermsList from './settingInvoice/settingInvoiceList';
import SettingInvoiceCreateForm from './settingInvoice/settingInvoiceCreateForm';
import SettingInvoiceDetail from './settingInvoice/settingInvoiceDatail';
import SettingInvoiceEdit from './settingInvoice/settingInvoiceEdit';
import SettingInvoiceRevenueContract from './settingInvoice/settingInvoiceRevenueContract';
import SettingFee from './settingFee/index'
import SettingAsset from './settingAsset/index';
import SettingSecutiy from './settingSecurityAndCon/settingSecurity';
import SettingSecutiynotFoundLRC from './settingSecurityAndCon/settingSecurityNotFound'; 
import settingPackingEstamp from './settingSecurityAndCon/settingSecurityPacking/settingPackingEstamp';
import settingPackingCalculetePacking from './settingSecurityAndCon/settingSecurityPacking/settingPackingCalculetePacking';
import settingPackingCompnany from './settingSecurityAndCon/settingSecurityPacking/settingPackingCompany';
import settingWebviewQR from './settingSecurityAndCon/settingSecurityPacking/settingWebviewQR';
import SettingFavorite from './settingFavorite/settingFavorite';
import SettingAdvertise from './settingAdvertise';
import SettingPaymentgateway from './settingPaymentgateway/index';
import SetupChartofaccount from './setupchartofaccount/index';
import SettingCollectionLetter from './settingCollectionLetter';
import SettingClaim from './settingClaim';
import settingParcel from './settingParcel/settingParcel';
import settingChat from './settingChat/settingChat';

import settingInvoiceTemplate from './settingInvoiceTemplate/settingInvoiceTemplate'
import Role from './settingMenuSidebar';
import SettingAutoMessageForm from './settingAutoMessage/settingAutoMessageForm';
import WrapperSettingWalkinQRcode from './settingWalkin/index'
import SettingForeignOwnwershipNotifications from './settingForeignOwnership/foreignOwnershipNotifications';
import SettingDocument from './settingForeignOwnership/foreignOwnershipDocument';
import SettingInvoiceRole from './settingInvoiceRole/settingInvoiceRole';
import SettingCollectionLaw from './settingCollectionLetterLaw/settingCollectionLetterLaw';
import CollectionLawDocsStatus from './settingCollectionLetterLaw/collectionLawFrimDocsStatus';
import CollectionLawFrimNumberofDocs from './settingCollectionLetterLaw/collectionLawFrimNumberofDocs'
// import SettingDocumentPaidLawyer from './settingDocumentPaidLawyer/settingDocumentPaidLawyer';
import SettingDocumentPaidLawyerContainer from './settingDocumentPaidLawyer/settingDocumentPaidLawyer';
import SettingDocumentPaidLawyerDetail from './settingDocumentPaidLawyer/settingDocumentPaidLawyerDetail';
import SettingDepositUse from './settingReceiptDepositUse/settingReceiptDepositUse';
// import settingEmail from './settingEmail/settingEmail';
import SettingReceiptIssueAndHoliday from './settingReceiptIssueAndHoliday/settingReceiptIssueAndHoliday';


class Project extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={SettingDashboard}/>
                <PrivateRoute exact path={`${this.props.match.url}/account-setting`} component={SettingAccount}/>
                <PrivateRoute exact path={`${this.props.match.url}/period-lock`} component={SettingPeriodLockForm}/>
                <PrivateRoute path={`${this.props.match.url}/juristic-setting`} component={SettingJuristic}/>
                {/* <PrivateRoute exact path={`${this.props.match.url}/auto-message-setting`} component={settingAutoMessage}/> */}
                <PrivateRoute exact path={`${this.props.match.url}/note-invoice-setting`} component={SettingNoteInvoice}/>
                <PrivateRoute exact path={`${this.props.match.url}/note-receive-setting`} component={SettingNoteReceive}/>
                {/* <PrivateRoute exact path={`${this.props.match.url}/bring-forward-setting`} component={SettingBringForward}/> */}
                <PrivateRoute exact path={`${this.props.match.url}/bring-forward-setting`} component={SettingBringForward}/>
                <PrivateRoute exact path={`${this.props.match.url}/project-manager`} component={SettingProjectManager}/>
                <PrivateRoute exact path={`${this.props.match.url}/invoice-setting-terms`} component={SettingInvoiceTerms} />
                <PrivateRoute exact path={`${this.props.match.url}/developer-setting`} component={SettingDeveloper} />
                <PrivateRoute exact path={`${this.props.match.url}/enterprise-information-setting`} component={SettingEnterpriseInformation} />
                {/* ช่ั่วคราว */}
                <PrivateRoute exact path={`${this.props.match.url}/invoice-setting-terms/form/:status`} component={SettingInvoiceCreate} />
                <PrivateRoute exact path={`${this.props.match.url}/invoice-setting-terms/list/:status`} component={SettingInvoiceTermsList} />
                <PrivateRoute exact path={`${this.props.match.url}/invoice-setting-terms/form/:status/:id?`} component={SettingInvoiceCreateForm} />
                <PrivateRoute exact path={`${this.props.match.url}/invoice-setting-terms/detail/:status/:id?`} component={SettingInvoiceDetail} />
                <PrivateRoute exact path={`${this.props.match.url}/invoice-setting-terms/edit/:status/:id?`} component={SettingInvoiceEdit} />
                <PrivateRoute exact path={`${this.props.match.url}/invoice-setting-terms/revenue/:id/:type/:preID`} component={SettingInvoiceRevenueContract} />


                <PrivateRoute path={`${this.props.match.url}/collection`} component={SettingCollectionLetter} />
                <PrivateRoute path={`${this.props.match.url}/fee`} component={SettingFee} />

                <PrivateRoute path={`${this.props.match.url}/asset`} component={SettingAsset} />

                {/* <PrivateRoute path={`${this.props.match.url}/fee`} component={SettingFee} /> */}
                
                <PrivateRoute path={`${this.props.match.url}/tool-and-connection`} component={SettingSecutiy} />
                <PrivateRoute path={`${this.props.match.url}/notFoundLRC`} component={SettingSecutiynotFoundLRC} />
                {/* <PrivateRoute exact path={`${this.props.match.url}/setting-keep-packing/all`} component={settingPackingAccount} /> */}
                <PrivateRoute exact path={`${this.props.match.url}/setting-keep-packing/estamp`} component={settingPackingEstamp} />
                <PrivateRoute path={`${this.props.match.url}/setting-keep-packing/calculetepacking`} component={settingPackingCalculetePacking} />
                <PrivateRoute path={`${this.props.match.url}/setting-keep-packing/companyDetail`} component={settingPackingCompnany} />
                <PrivateRoute path={`${this.props.match.url}/setting-keep-packing/all`} component={settingWebviewQR} />
                <PrivateRoute exact path={`${this.props.match.url}/setting-favorite`} component={SettingFavorite} />
                <PrivateRoute path={`${this.props.match.url}/setting-advertise`} component={SettingAdvertise} />
                <PrivateRoute exact path={`${this.props.match.url}/setuppaymentgateway`} component={SettingPaymentgateway}/>
                <PrivateRoute exact path={`${this.props.match.url}/setupchartofaccount`} component={SetupChartofaccount}/>

                {/* SettingClaim */}
                <PrivateRoute exact path={`${this.props.match.url}/SettingClaim`} component={SettingClaim}/>
                <PrivateRoute exact path={`${this.props.match.url}/SettingRole`} component={Role}/>

                {/* AUTO Reply Chat */}
                <PrivateRoute exact path={`${this.props.match.url}/SettingReplyAutoChat`} component={SettingAutoMessageForm}/>

                {/* settingParcel */}
                <PrivateRoute path={`${this.props.match.url}/settingParcel`} component={settingParcel} />

                {/* settingChat */}
                <PrivateRoute path={`${this.props.match.url}/settingChat`} component={settingChat} />

                {/* settingInvoiceTemplate */}
                <PrivateRoute path={`${this.props.match.url}/setting-invoice-template`} component={settingInvoiceTemplate} />
                
                {/* settingSettingWalkin */}
                <PrivateRoute path={`${this.props.match.url}/setting-walkin`} component={WrapperSettingWalkinQRcode} />

                {/* setting foreignOwnership */}
                <PrivateRoute exact path={`${this.props.match.url}/foreign-ownship-document`} component={SettingDocument}/>
                <PrivateRoute exact path={`${this.props.match.url}/foreign-ownship-notifications`} component={SettingForeignOwnwershipNotifications}/>

                {/* InvoiceRoleSetting */}
                <PrivateRoute exact path={`${this.props.match.url}/setting-invoice-role`} component={SettingInvoiceRole}/>
                
                {/* Law collection document Setting */}
                <PrivateRoute exact path={`${this.props.match.url}/setting-collection-law/:status`} component={SettingCollectionLaw}/>
    
                {/* Law collection document status */}
                <PrivateRoute exact path={`${this.props.match.url}/law-collection-docs-status`} component={CollectionLawDocsStatus}/>

                <PrivateRoute exact path={`${this.props.match.url}/law-collection-docs-report-number`} component={CollectionLawFrimNumberofDocs}/>

                {/* Law collection document Setting */}
                <PrivateRoute exact path={`${this.props.match.url}/lawyer-summary-report/:status?`} component={SettingDocumentPaidLawyerContainer}/>
                <PrivateRoute exact path={`${this.props.match.url}/lawyer-summary-report/detail/:id?`} component={SettingDocumentPaidLawyerDetail}/>
               
                {/* Receipt Deposit use Setting */}
                <PrivateRoute exact path={`${this.props.match.url}/setting-receiptdeposit-use`} component={SettingDepositUse}/>

                <PrivateRoute exact path={`${this.props.match.url}/setting-receipt-issue-and-holiday`} component={SettingReceiptIssueAndHoliday}/>
                
                {/* setting email มีการทำใหม่ */}
                {/* <PrivateRoute exact path={`${this.props.match.url}/setting-email`} component={settingEmail}/> */}

                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Project;