import React, { Component } from 'react'
import Wrapper from '../../components/wrapper';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Translation } from 'react-i18next';
import Navigation from './navigation';
import { Link } from 'react-router-dom';
import "./settingFee.scss"
import DebtFreeCertification from './debtFreeCertification';
import AccountingTopMenuNavigation from '../../accounting/accountingTopMenuNavigation';

class SettingsFee extends Component {

    constructor(props){
        super(props)
        this.state ={
            status : this.props.match.params.status,
        }
    }

    componentDidUpdate(){
        if(this.state.status !== this.props.match.params.status){
            this.setState({
                status : this.props.match.params.status
            })
        }
    }


    render(){
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <Translation>
                        {
                            t => 
                            <div className="container-fluid box" id="setting-fee">
                                <div className="row justify-content-between">
                                    <div className="col">
                                       <Translation>
                                            {
                                                t =>  
                                                    <h4 className="mb-4">
                                                        <Link to="/accounting">
                                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                alt="back" className="back"/>
                                                        </Link>
                                                        {t('setting:Fee Document Setting')}         
                                                    </h4> 
                                            }
                                        </Translation>
                                    </div>
                                </div>
                                <div className="content-inner">
                                    <Navigation />
                                    <DebtFreeCertification />
                                    
                                </div>
                            </div>
                        }
                    </Translation>


                </WrapperContent>
            </Wrapper>
        );
    }




}
export default SettingsFee;