import React from "react";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import Wrapper from "../../components/wrapper";
import { Translation } from "react-i18next";
import IncomeTable from "./incomeTable";
import ExpenseTable from "./expenseTable";
import DifferentTable from "./differentTable";
import ExportSummaryOfIncomeExpense from "./serverExport";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import { getDaysInMonth, setDate, setMonth, format, lastDayOfMonth } from "date-fns";
import _ from "lodash";

import Swal from "sweetalert2";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import { Redirect } from 'react-router';
import IncomePieCharts from "./incomePieCharts";
import IncomeBarCharts from "./incomeBarCharts";
import ExpensePieCharts from "./expensePieCharts";
import ExpenseBarCharts from "./expenseBarCharts";
import './summaryOfIncomeExpenseCharts.scss'
import Loading from "../../libs/loading";
import BackButtonIcon from "../../components/BackBtn/indexBack";
import i18next from "i18next";

const query = graphql`
    query summaryOfIncomeExpenseQuery ($startDateFirst: DateTime, $endDateFirst: DateTime, $startDateSecond: DateTime, $endDateSecond: DateTime) {
        allAccountProjectManager{
            edges{
              node{
                id
                tableAccount
              }
            }
        }
        chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: "4", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
            edges {
                node {
                    chartOfAccountCode
                    yearFirst
                    yearSecond
                    totalHistoricalDiffFirst
                    totalHistoricalDiffSecond
                }
            }
        }
    }
`;
class SummaryOfIncomeExpense extends React.Component {
    constructor(props) {
        super(props);
        let before_date = setMonth(new Date(), new Date().getMonth() - 1);
        this.state = {
            redirect: false,
            start_date_first: before_date,
            end_date_first: new Date(),

            start_date_second: setMonth(new Date(), new Date().getMonth() - 2),
            end_date_second: setDate(before_date, getDaysInMonth(before_date)),

            selected_date: new Date(),
            period: 'month',
            showMonth: false,
            loading: true,
        };

        this.handleChange = this.handleChange.bind(this);
        // this.calDate = this.calDate.bind(this)
    }

    componentDidMount() {
        this.setState({ loading: true })
        fetchQuery(environment, query, {}).then((data) => {
            if (data.allAccountProjectManager.edges[0]?.node.tableAccount === null) {
                Swal.fire({
                    title: 'คุณยังไม่ได้ตั้งค่า บันทึกยอดยกมา โปรดตั้งค่าบันทึกยอดยกมา',
                    type: "warning",
                    confirmButtonColor: "#3085d6",
                }).then(async () => {
                    this.setState({
                        redirect: true,
                    })
                });
            } else {
                this.setState({ loading: false })
            }
        });
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if ((this.state.period !== prevState.period) || (this.state.selected_date !== prevState.selected_date)) {
    //         this.calDate()
    //     }
    // }

    handleChange(e) {

        if (e.target.value === 'month3') {
            this.setState({
                showMonth: true
            })
        }
        this.setState({ [e.target.name]: e.target.value })
    }


    calDate() {
        this.setState({
            loading: true,
        }, () => {
            if (this.state.period === 'year') {
                // let before_date = setYear(this.state.selected_date, this.state.selected_date.getFullYear() - 1);

                //Set localStorage
                // localStorage.setItem('balance_start_date_first', before_date)
                // localStorage.setItem('balance_end_date_first', this.state.selected_date)
                // localStorage.setItem('balance_start_date_second', setYear(this.state.selected_date, this.state.selected_date.getFullYear() - 2))
                // localStorage.setItem('balance_end_date_second', setDate(before_date, getDaysInMonth(before_date)))

                // this.setState({
                //     start_date_first: before_date,
                //     end_date_first: this.state.selected_date,

                //     start_date_second: setYear(this.state.selected_date, this.state.selected_date.getFullYear() - 2),
                //     end_date_second: setDate(before_date, getDaysInMonth(before_date)),
                // })

                let start_date_first = new Date();
                let end_date_first = new Date();
                let start_date_second = new Date();
                let end_date_second = new Date();

                let month_minus = 11;

                let firstDay = new Date(this.state.selected_date.getFullYear(), this.state.selected_date.getMonth(), 1);
                start_date_first = setDate(setMonth(this.state.selected_date, this.state.selected_date.getMonth() - month_minus), firstDay.getDate());
                end_date_first = this.state.selected_date;

                end_date_second = setDate(start_date_first, start_date_first.getDate() - 1);
                // start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - (month_minus)), end_date_second.getDate() + 1);
                start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - month_minus), firstDay.getDate());

                localStorage.setItem('balance_start_date_first', start_date_first)
                localStorage.setItem('balance_end_date_first', end_date_first)
                localStorage.setItem('balance_start_date_second', start_date_second)
                localStorage.setItem('balance_end_date_second', end_date_second)

                this.setState({
                    start_date_first: start_date_first,
                    end_date_first: end_date_first,
                    start_date_second: start_date_second,
                    end_date_second: end_date_second,
                    showMonth: false,
                    loading: false,
                })



            } else {
                let start_date_first = new Date();
                let end_date_first = new Date();
                let start_date_second = new Date();
                let end_date_second = new Date();


                const month_select = this.state.period

                let month_minus = month_select === 'month' && this.state.selected_date.getDate() !== lastDayOfMonth(this.state.selected_date).getDate() ? 1 : 0;

                if (month_select === 'month3') {
                    month_minus = 2
                }
                else if (month_select === 'month6') {
                    month_minus = 5
                }
                else if (month_select === 'month9') {
                    month_minus = 8
                }


                if (this.state.selected_date.getDate() === lastDayOfMonth(this.state.selected_date).getDate()) {
                    start_date_first = setDate(setMonth(this.state.selected_date, this.state.selected_date.getMonth() - month_minus), 1);
                    end_date_first = this.state.selected_date;

                    end_date_second = setDate(start_date_first, start_date_first.getDate() - 1);
                    start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - (month_minus)), 1);
                } else {

                    //start_date_first = setDate(setMonth(this.state.selected_date, this.state.selected_date.getMonth() - month_minus), this.state.selected_date.getDate() + 1);
                    let firstDay = new Date(this.state.selected_date.getFullYear(), this.state.selected_date.getMonth(), 1);
                    let checkFirstDay = month_minus === 1 ? 0 : month_minus
                    start_date_first = setDate(setMonth(this.state.selected_date, this.state.selected_date.getMonth() - checkFirstDay), firstDay.getDate());
                    end_date_first = this.state.selected_date;

                    end_date_second = setDate(start_date_first, start_date_first.getDate() - 1);
                    // start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - (month_minus)), end_date_second.getDate() + 1);
                    start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - checkFirstDay), firstDay.getDate());

                }


                //Set localStorage
                localStorage.setItem('balance_start_date_first', start_date_first)
                localStorage.setItem('balance_end_date_first', end_date_first)
                localStorage.setItem('balance_start_date_second', start_date_second)
                localStorage.setItem('balance_end_date_second', end_date_second)
                // month

                this.setState({
                    start_date_first: start_date_first,
                    end_date_first: end_date_first,
                    start_date_second: start_date_second,
                    end_date_second: end_date_second,
                    showMonth: false,
                    loading: false
                })

            }
        })

    }

    render() {
        if (this.state.redirect) {
            return <Redirect to='/setting/bring-forward-setting' />
        }

        const month_select = this.state.period

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack={"/accounting"}
                                    LinkText={i18next.t('accounting:summary of income expense')}
                                    boxHtmlText={
                                        <div className="col text-right">
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_summary_income_expense_print' }) &&
                                                <ExportSummaryOfIncomeExpense state={this.state} />
                                            }
                                        </div>
                                    }
                                />
                                <div className="content-inner">
                                    <div className="row mb-4">
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group">
                                                <select className="form-control dropdown-custom-arrow" name="period" id="period"
                                                    onChange={this.handleChange} value={this.state.period}>
                                                    <option value="month">{i18next.t("BalanceSheet:Monthly")}</option>
                                                    <option value="month3">{i18next.t("BalanceSheet:With")} 3 {i18next.t("BalanceSheet:Months")}</option>
                                                    <option value="month6">{i18next.t("BalanceSheet:With")} 6 {i18next.t("BalanceSheet:Months")}</option>
                                                    <option value="month9">{i18next.t("BalanceSheet:With")} 9 {i18next.t("BalanceSheet:Months")}</option>
                                                    <option value="year">{i18next.t("BalanceSheet:Yearly")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="float-left mr-1">
                                                <DatePickerAdapter onChange={this.handleChange} className="form-control"
                                                    selected={this.state.selected_date} name="selected_date" />
                                            </div>
                                        </div>
                                        <div className="ml-n2">
                                            <button type="button" className="btn btn-primary form-control"
                                                name="search" onClick={() => this.calDate()}>{i18next.t("AllFilter:Search")}</button>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            {

                                                <React.Fragment>
                                                    <div className="table-responsive fade-up card">
                                                        <table className="table table-bordered small">
                                                            <thead className="text-center">
                                                                <tr>
                                                                    <th rowSpan={2} />
                                                                    <th colSpan={2}>{i18next.t("BalanceSheet:THB")}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>

                                                                        {/* {format(this.state.end_date_first, 'MMM YY', {locale: thLocale})}  */}

                                                                        {!this.state.showMonth && month_select !== 'month' ? `${i18next.t("monthNameShort:"+format(this.state.start_date_first, 'MMM'))} ${format(this.state.start_date_first, 'YY')}` + " -" : ''} {`${i18next.t("monthNameShort:"+format(this.state.end_date_first, 'MMM'))} ${format(this.state.end_date_first, 'YY')}`}
                                                                    </th>
                                                                    <th>
                                                                        {/* {format(this.state.end_date_second, 'MMM YY', {locale: thLocale})}  */}
                                                                        {!this.state.showMonth && month_select !== 'month' ? `${i18next.t("monthNameShort:"+format(this.state.start_date_second, 'MMM'))} ${format(this.state.start_date_second, 'YY')}` + " -" : ''} {`${i18next.t("monthNameShort:"+format(this.state.end_date_second, 'MMM'))} ${format(this.state.end_date_second, 'YY')}`}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <IncomeTable state={this.state} />
                                                                <ExpenseTable state={this.state} />
                                                                <DifferentTable state={this.state} />
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {this.state.loading ?
                                                        <Loading />
                                                        :
                                                        <React.Fragment>
                                                            <div className="incomeExpenseSummaryPieCharts">
                                                                {<IncomePieCharts state={this.state} />}
                                                                {<ExpensePieCharts state={this.state} />}
                                                            </div>
                                                            <div>
                                                                {<IncomeBarCharts state={this.state} />}
                                                                {<ExpenseBarCharts state={this.state} />}
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>

                        }
                    </Translation>

                </WrapperContent>
            </Wrapper>
        )
    }

}

export default SummaryOfIncomeExpense;