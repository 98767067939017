import React from "react";
import numberWithCommas from "../../libs/numberWithComma";
import _ from "lodash";
import { Link } from "react-router-dom";

class BalanceSheetAccountCurrent extends React.Component {

    render() {
        return (
            <React.Fragment>
                <tbody>
                    <tr>
                        <td className="pl-3" width={700}>
                            เจ้าหนี้และเช็คจ่ายรอตัดบัญชี / Cheque in Transit
                        </td>
                        <td className="text-center">
                            <Link key={6} to={"balance-sheet-statements/2110-2111/"+this.props.state.period} target='_blank'>6</Link>
                        </td>
                        <td/>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_6"))}</td>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_6"))}</td>
                    </tr>
                    <tr>
                        <td className="pl-3">ค่าใช้จ่ายค้างจ่าย / Account Payable</td>

                        <td className="text-center">
                            <Link key={7} to={"balance-sheet-statements/2121-2122-2123/"+this.props.state.period} target='_blank'>7</Link>
                        </td>

                        <td/>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_7"))}</td>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_7"))}</td>
                    </tr>
                    <tr>
                        <td className="pl-3">เงินรับรอการตรวจสอบ / Others collection</td>
                        <td className="text-center">
                            <Link key={8} to={"balance-sheet-statements/2230/"+this.props.state.period} target='_blank'>8</Link>
                        </td>
                        <td/>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_8"))}</td>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_8"))}</td>
                    </tr>
                    <tr>
                        <td className="pl-3">รายรับรอตัดบัญชี / Deferred revenue</td>
                        <td className="text-center">
                            <Link key={9} to={"balance-sheet-statements/2220/"+this.props.state.period} target='_blank'>9</Link>
                        </td>
                        <td/>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_9"))}</td>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_9"))}</td>
                    </tr>
                    <tr>
                        <td className="pl-3">เงินค้ำประกัน / Deposit</td>
                        <td className="text-center">
                            <Link key={10} to={"balance-sheet-statements/2125/"+this.props.state.period} target='_blank'>10</Link>
                        </td>
                        <td/>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_10"))}</td>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_10"))}</td>
                    </tr>
                    <tr>
                        <td className="pl-3">รายได้รับล่วงหน้า / Advance Revenue</td>
                        <td className="text-center">
                            <Link key={11} to={"balance-sheet-statements/2210/"+this.props.state.period} target='_blank'>11</Link>
                        </td>
                        <td/>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_11"))}</td>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_11"))}</td>
                    </tr>
                    <tr>
                        <td className="pl-3">หนี้สินหมุนเวียนอื่น / Other Current Liabilities</td>
                        <td className="text-center">
                            <Link key={12} to={"balance-sheet-statements/2124-2126/"+this.props.state.period} target='_blank'>12</Link>
                        </td>
                        <td/>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_12"))}</td>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_12"))}</td>
                    </tr>
                    <tr className="">
                        <td className="pl-3"><strong>รวมหนี้สินหมุนเวียน / Total Current Liabilities</strong></td>
                        <td className="text-center"/>
                        <td/>
                        <td className="text-right">
                            <hr/>
                            {numberWithCommas(
                                _.get(this.props.state.beforeSet, "total_ref_6") +
                                _.get(this.props.state.beforeSet, "total_ref_7") +
                                _.get(this.props.state.beforeSet, "total_ref_8") +
                                _.get(this.props.state.beforeSet, "total_ref_9") +
                                _.get(this.props.state.beforeSet, "total_ref_10") +
                                _.get(this.props.state.beforeSet, "total_ref_11") +
                                _.get(this.props.state.beforeSet, "total_ref_12")
                            )}
                            <hr/>
                        </td>
                        <td className="text-right">
                            <hr/>
                            {numberWithCommas(
                                _.get(this.props.state.afterSet, "total_ref_6") +
                                _.get(this.props.state.afterSet, "total_ref_7") +
                                _.get(this.props.state.afterSet, "total_ref_8") +
                                _.get(this.props.state.afterSet, "total_ref_9") +
                                _.get(this.props.state.afterSet, "total_ref_10") +
                                _.get(this.props.state.afterSet, "total_ref_11") +
                                _.get(this.props.state.afterSet, "total_ref_12")
                            )}
                            <hr/>
                        </td>
                    </tr>
                    </tbody>
                <thead>
                <tr>
                    <td colSpan={5}>
                        <h6 className="mt-2">หนี้สินไม่หมุนเวียน / Non Current Liabilities</h6>
                    </td>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="pl-3">
                            หนี้สินไม่หมุนเวียนอื่น / Other Non Current Liabilities
                        </td>
                        <td className="text-center">
                            <Link key={13} to={"balance-sheet-statements/2300-2400-2410/"+this.props.state.period}>13</Link>
                        </td>
                        <td/>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_13"))}</td>
                        <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_13"))}</td>
                    </tr>
                    <tr className="">
                        <td className="pl-3"><strong>รวมหนี้สินไม่หมุนเวียน / Total Non Current Liabilities</strong></td>
                        <td className="text-center"/>
                        <td/>
                        <td className="text-right">
                            <hr/>
                            {numberWithCommas(
                                _.get(this.props.state.beforeSet, "total_ref_13")
                            )}
                            <hr/>
                        </td>
                        <td className="text-right">
                            <hr/>
                            {numberWithCommas(
                                _.get(this.props.state.afterSet, "total_ref_13")
                            )}
                            <hr/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h6 className="mt-2">รวมหนี้สิน / Total Liabilities</h6>
                        </td>
                        <td className="text-center"/>
                        <td/>
                        <td className="text-right">
                            <hr/>
                            {numberWithCommas(
                                _.get(this.props.state.beforeSet, "total_ref_6") +
                                _.get(this.props.state.beforeSet, "total_ref_7") +
                                _.get(this.props.state.beforeSet, "total_ref_8") +
                                _.get(this.props.state.beforeSet, "total_ref_9") +
                                _.get(this.props.state.beforeSet, "total_ref_10") +
                                _.get(this.props.state.beforeSet, "total_ref_11") +
                                _.get(this.props.state.beforeSet, "total_ref_12") +
                                _.get(this.props.state.beforeSet, "total_ref_13")
                            )}
                            <hr/>
                        </td>
                        <td className="text-right">
                            <hr/>
                            {numberWithCommas(
                                _.get(this.props.state.afterSet, "total_ref_6") +
                                _.get(this.props.state.afterSet, "total_ref_7") +
                                _.get(this.props.state.afterSet, "total_ref_8") +
                                _.get(this.props.state.afterSet, "total_ref_9") +
                                _.get(this.props.state.afterSet, "total_ref_10") +
                                _.get(this.props.state.afterSet, "total_ref_11") +
                                _.get(this.props.state.afterSet, "total_ref_12") +
                                _.get(this.props.state.afterSet, "total_ref_13")
                            )}
                            <hr/>
                        </td>
                    </tr>
                    </tbody>
            </React.Fragment>
        )
    }

}

export default BalanceSheetAccountCurrent;
