import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from "react-i18next";

class Navigation extends Component {

    render() {

        return (
            <div className="row" id="navigation-tab">
                <div className="col-9">
                    <ul>
                        <li>
                            <NavLink exact={true} to={`/accounting/finance/bank-transfer/all`}>
                                <Translation>
                                    {(t) => <span> 
                                        {t("bankTransfer:All")}
                                    </span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink  exact={true} to={`/accounting/finance/bank-transfer/active`}>
                                <Translation>
                                    {(t) => <span> 
                                        {t("bankTransfer:Active")}
                                        </span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact={true} to="/accounting/finance/bank-transfer/void" >
                                <Translation>
                                    {(t) => <span> 
                                        {t("bankTransfer:Void")}
                                          </span>}
                                </Translation>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;