import { format, differenceInCalendarMonths, getDate } from 'date-fns';
import _ from 'lodash'
import React, { Component } from 'react'
import numberWithCommas from '../../../libs/numberWithComma';
import { Dropdown  } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { Link } from 'react-router-dom';
import ReportRegisterListExport from './reportRegisterListExport';
import i18next from 'i18next';
import GetdateOnEngAndTh from '../../../components/Action/getdateOnEngAndTh';

export default class reportRegitterDetailTable extends Component {

  sortData = (inputData) => {
    let group_date = {};
    let new_currentRecord = [];

    _.map(inputData, (record) => {
      if (Object.keys(group_date).includes(record.node.group.issuedDate.substring(0, 7))) {
        group_date[record.node.group.issuedDate.substring(0, 7)].push(record)
      } else {
        group_date[record.node.group.issuedDate.substring(0, 7)] = [record]
      }
      group_date[record.node.group.issuedDate.substring(0, 7)] = _.sortBy(group_date[record.node.group.issuedDate.substring(0, 7)], sort => sort.node.group.refNumber)
    })
    Object.entries(group_date).map((value) => {
      new_currentRecord.push({month: value[0], accountRecord: value[1]})
    });

    let sortNewRecord =  _.sortBy(new_currentRecord, sort => sort.month)
    return sortNewRecord
  }

  render() {

    let rangeDate =  differenceInCalendarMonths(this.props.date, this.props.queryDate.startDate)
    if(getDate(this.props.queryDate.startDate) < getDate(this.props.date)  && rangeDate >= 0){
      rangeDate += 1
    }

    return (
      <div>
        <div className="row col-12 mt-2 d-flex justify-content-end">
          {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_print'}) && 
          <Dropdown>
            <DropdownToggle id="dropdown-item-button" bsPrefix = 'dropdown-export dropdown-toggle'>
            {i18next.t("Allaction:Print")}
            </DropdownToggle>
            <DropdownMenu>
              <Dropdown.Item>
                  <Link 
                  to={{pathname : `/accounting/budget/reportRegister/document/reportRegister/${this.props.yearId}` ,
                      state : {id : this.props.yearId , queryDate: this.props.queryDate, queryDataReport: this.props.queryDataReport.edges, date: this.props.date, rangeDate: rangeDate}}} 
                  className="text-black"
                  >
                      <p className="text-black">PDF</p>
                  </Link>
              </Dropdown.Item>
              <ReportRegisterListExport 
                queryDataReport={this.props.queryDataReport} 
                queryDate={this.props.queryDate}
                sortData={this.sortData}
                date={this.props.date}
                rangeDate={rangeDate}
                projectName={this.props.queryDate.project.name}
                />
            </DropdownMenu>
          </Dropdown>
          }
        </div>
        <div className="header-title text-center">
          {this.props.queryDate.project.name}<br />
          {i18next.t("reportRegisterDetails:Budget Usage Control Register")}<br />
          {`${i18next.t("reportRegisterDetails:For Time Period")}:`} {rangeDate} {`${i18next.t("reportRegisterDetails:Month(s)")} `}
          {`${i18next.t("AllFilter:Date")} `}
          <GetdateOnEngAndTh 
            date={this.props.queryDate.startDate}
            withFullMonth={true}
            withFullYear={true}
          />
           {` ${i18next.t("AllFilter:to")} `}
           <GetdateOnEngAndTh 
            date={this.props.date}
            withFullMonth={true}
            withFullYear={true}
          />
        </div>
        {_.map(this.props.queryDataReport.edges, (item, index) => {
          let sumTotalHappend = 0
          let accountRecordSordIsuedate = this.sortData(item.node.chartOfAccount.accountRecord.edges)

          return (
            <div className="mb-5" key={index}>
              <div className="name-detail my-3">{`${item.node.chartOfAccount.chartOfAccountCode} ${item.node.chartOfAccount.name}`}</div>
              <div className="fade-up table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th rowSpan={2} className="text-center">{i18next.t("reportRegisterDetails:Date")}</th>
                      <th rowSpan={2} className="text-center">{i18next.t("reportRegisterDetails:Description")}</th>
                      <th rowSpan={2}/>
                      <th rowSpan={2} className="text-center">{i18next.t("reportRegisterDetails:Document Number")}</th>
                      <th rowSpan={2} className="text-center">{i18next.t("reportRegisterDetails:Actual")}</th>
                      <th className="text-center">{i18next.t("reportRegisterDetails:Budget Year")}</th>
                      <th className="text-right">{numberWithCommas(parseFloat(item.node.totalBudget).toFixed(2))}</th>
                    </tr>
                    <tr>
                      <th className="text-center">{i18next.t("reportRegisterDetails:Budget Month")}</th>
                      <th className="text-right">{i18next.t("reportRegisterDetails:Actual Vs. Budget")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(accountRecordSordIsuedate, (item_child, index_child) => {
                      let sumHappendOfMonth = 0
                      let totalOfMonth = _.filter(item.node.monthBudget.edges, (o) => o.node.months.substring(0, 7) === item_child.month)
                      return (
                        <React.Fragment key={index_child}>
                          {_.map(item_child.accountRecord, (item_c_child, index_c_child) => {
                            if(item.node.chartOfAccount.chartOfAccountCode.substring(0,1) === "4"){
                              sumHappendOfMonth += item_c_child.node.credit - item_c_child.node.debit
                              sumTotalHappend += item_c_child.node.credit - item_c_child.node.debit
                            }else if(item.node.chartOfAccount.chartOfAccountCode.substring(0,1) === "5" ){
                              sumHappendOfMonth += item_c_child.node.debit - item_c_child.node.credit
                              sumTotalHappend += item_c_child.node.debit - item_c_child.node.credit
                            }
                            return (
                              <tr key={index_c_child}>
                                <td className="text-center">{format(item_c_child.node.group.issuedDate, "DD/MM/YYYY")}</td>
                                <td width={400} className="text-center">{`${item_c_child.node.name} ${item_c_child.node.group.name}`}</td>
                                <td />
                                <td width={120} className="text-center text-primary">{item_c_child.node.group.refNumber || item_c_child.node.group.refTransaction}</td>
                                <td className="text-center td-red-scale">
                                  {item.node.chartOfAccount.chartOfAccountCode.substring(0,1) === "4" 
                                    ? numberWithCommas((item_c_child.node.credit - item_c_child.node.debit).toFixed(2))
                                      : item.node.chartOfAccount.chartOfAccountCode.substring(0,1) === "5" 
                                        && numberWithCommas((item_c_child.node.debit - item_c_child.node.credit).toFixed(2))
                                  }
                                  </td>
                                <td className="text-center"></td>
                                <td className="text-right td-red-scale"></td>
                              </tr>
                            )
                          })}
                          <tr className="td-sum font_sum">
                            <td />
                            <td />
                            <td className="text-center">{i18next.t("reportRegisterDetails:Total")}</td>
                            <td className="text-center">
                              <GetdateOnEngAndTh 
                                date={item_child.month}
                                withFullMonth={true}
                                withFullYear={true}
                              />
                            </td>
                            <td className="text-center">{numberWithCommas(sumHappendOfMonth.toFixed(2))}</td>
                            <td className="text-center">
                              {(totalOfMonth[0]?.node?.budget || 0) !== 0 
                                ?  numberWithCommas(totalOfMonth[0]?.node?.budget)
                                  : "-"
                              }
                            </td>
                            <td className="text-right">{numberWithCommas(sumHappendOfMonth - (totalOfMonth[0]?.node?.budget || 0))}</td>
                          </tr>
                        </React.Fragment>
                      )
                    })}
                    <React.Fragment>
                      <tr className="td-sum2 font_sum">
                        <td colSpan={5} className="text-right">{i18next.t("reportRegisterDetails:Total Budget Used")}</td>
                        <td colSpan={2} className="text-right">{sumTotalHappend !== 0 ? numberWithCommas(sumTotalHappend.toFixed(2)) : "-"}</td>
                      </tr>
                      <tr className="td-sum3 font_sum">
                        <td colSpan={5} className="text-right">{i18next.t("reportRegisterDetails:Remaining Budget Per Year")}+</td>
                        <td colSpan={2} className="text-right">
                          {(item.node.totalBudget - sumTotalHappend) !== 0   
                            ? numberWithCommas(((item.node.totalBudget || 0) - sumTotalHappend).toFixed(2)) 
                              : "-"
                          }
                        </td>
                      </tr>
                    </React.Fragment>
                  </tbody>
                </table>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
