/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingAutoMessageFormQueryVariables = {||};
export type settingAutoMessageFormQueryResponse = {|
  +allAutoReplayNitiSetting: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +autoReplay: boolean,
        +text: ?string,
        +timeStart: ?any,
        +timeEnd: ?any,
        +monday: boolean,
        +tuesday: boolean,
        +wednesday: boolean,
        +thursday: boolean,
        +friday: boolean,
        +saturday: boolean,
        +sunday: boolean,
      |}
    |}>
  |}
|};
export type settingAutoMessageFormQuery = {|
  variables: settingAutoMessageFormQueryVariables,
  response: settingAutoMessageFormQueryResponse,
|};
*/


/*
query settingAutoMessageFormQuery {
  allAutoReplayNitiSetting {
    edges {
      node {
        id
        autoReplay
        text
        timeStart
        timeEnd
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AutoReplayNitiSettingNodeConnection",
    "kind": "LinkedField",
    "name": "allAutoReplayNitiSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AutoReplayNitiSettingNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoReplayNitiSettingNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "autoReplay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeEnd",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "monday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tuesday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wednesday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thursday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "friday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "saturday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sunday",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingAutoMessageFormQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingAutoMessageFormQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "2cc29a0434d7f23a9b5c7df3a464b225",
    "id": null,
    "metadata": {},
    "name": "settingAutoMessageFormQuery",
    "operationKind": "query",
    "text": "query settingAutoMessageFormQuery {\n  allAutoReplayNitiSetting {\n    edges {\n      node {\n        id\n        autoReplay\n        text\n        timeStart\n        timeEnd\n        monday\n        tuesday\n        wednesday\n        thursday\n        friday\n        saturday\n        sunday\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0a0fd40f1044389372925e6bcc365ae4';

module.exports = node;
