import React from 'react';
import "../styles/survey.scss"
import ModalSurvey from "./modalSurvey";
import ComponentPagination from "../../../libs/componentPagination";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import {fetchQuery} from "relay-runtime";
import DeleteResidentialVote from "./deleteResidentialVote";
import {commitMutation, QueryRenderer} from "react-relay";
import Swal from "sweetalert2";
import ResidentialVoteUpdate from "./residentialVoteMutation";
import {Link} from "react-router-dom";
import _ from "lodash";
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import Loading from "../../../libs/loading";

const mutation = graphql`
    mutation surveyDetailListMutation($input: DeleteResidentialVoteInput!){
        deleteResidentialVote(input:$input){
            ok
        }
    }`;

const query = graphql`
    query surveyDetailQuery($search: String, $first: Int, $last: Int, $status: String, $surveyId: ID!) {
        surveyResidentialVote(search: $search, first: $first, last: $last, status: $status, surveyId: $surveyId){
            totalCount
            edges{
                node{
                    id
                    residential{
                        id
                        name
                        size
                        residentialHouseholder{
                            edges{
                                node{
                                    type
                                    id
                                    tenant{
                                        firstName
                                        lastName
                                    }
                                }
                            }
                        }
                    }
                    status
                }
            }
        }
    }
`;

class SurveyDetail extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state.status = this.props.status;

        this.translateStatus = this.translateStatus.bind(this);
        this.queryResidentialVoteList = this.queryResidentialVoteList.bind(this);
        this.onDeleteSurvey = this.onDeleteSurvey.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.onClickPrintAll = this.onClickPrintAll.bind(this);
    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        if (this.state.status !== this.props.status) {
            this.setState({
                status: this.props.status,
                checkList: [],
                check_all: false,
            }, () => {
                this.goFirst();
            })
        }
    }

    queryResidentialVoteList() {
        if (this.props.survey_id) {
            fetchQuery(environment, query, {
                search: this.props.search,
                first: this.state.first,
                last: this.state.last,

                status: this.state.status,
                surveyId: this.props.survey_id
            });
        }
    }

    translateStatus(status) {
        let word = '';
        if (status === 'APPROVE') {
            word = 'confirmation_document';
        }
        if (status === 'NOT_APPROVE') {
            word = 'documents_received';
        }
        if (status === 'NOT_VOTE') {
            word = 'not_voted';
        }
        return <Translation>{t=><>{t('survey:'+word)}</>}</Translation>;
    }

    onDeleteSurvey(id_list) {
        if (id_list.length !== 0 && !this.state.loading) {
            Swal.fire({
                title: i18next.t('survey:want_to_remove'),
                text: i18next.t('survey:all_survey') + id_list.length + i18next.t('survey:item'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: i18next.t('survey:yes'),
                cancelButtonText: i18next.t('survey:cancel')
            }).then((result) => {
                if (result.value) {
                    this.setState({loading: true});

                    let variables = {
                        input: {
                            idList: id_list,
                        }
                    };

                    commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            this.setState({loading: false, check_all: false, checkList: []});
                            if (response.deleteResidentialVote.ok) {
                                Swal.fire(i18next.t('survey:remove_success'), '', 'success').then(() => this.queryResidentialVoteList());
                            } else {
                                Swal.fire(i18next.t('survey:remove_fail'), '', 'warning');
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18next.t('survey:try_again'), 'warning')
                        },
                    },
                )

                }
            })
        } else if (this.state.loading) {
            Swal.fire({
                title: i18next.t('survey:waiting'),
                type: 'warning',
            })
        } else {
            Swal.fire({
                title: i18next.t('survey:please_select_item'),
                type: 'warning',
            })
        }
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let variables = {
            input: {
                residentialVoteId: e.target.dataset.index,
            }
        };

        Swal.fire({
            title: i18next.t('survey:are_you_sure_to_confirm'),
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t('survey:yes'),
            cancelButtonText: i18next.t('survey:cancel')
        }).then((result) => {
            if (result.value) {
                ResidentialVoteUpdate(variables, this.onSuccess, this.onError)
            }
        });
    }

    onError() {
        this.setState({loading:false});
        Swal.fire('Error!', i18next.t('survey:try_again'), 'warning');
    }

    onSuccess(response) {
        this.setState({loading:false});
        Swal.fire(i18next.t('survey:save_success'), '', 'success')
    }

    onClickPrintAll() {
        if (this.state.checkList.length <= 0) {
            Swal.fire(i18next.t('survey:please_select_item'), '', 'warning');
        }

    }

    render() {
        return (
            <Translation>{t=>
            <React.Fragment>
                <div className="row mb-2">
                    <div className="col">
                        <div className="btn-group">
                            <button type="button"
                                    className="btn btn-light dropdown-toggle"
                                    data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                {t('survey:manage_multiple')}
                            </button>
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_survey_delete'}) &&
                            <div className="dropdown-menu">
                                <span className="dropdown-item approve-dropdown"
                                      onClick={() => this.onDeleteSurvey(this.state.checkList)}>
                                 {this.state.loading &&
                                 <span
                                    className="spinner-border spinner-border-sm align-middle mr-2" />}{t('survey:remove_list')}</span>
                            </div>
                            }
                        </div>
                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_survey_print'})?
                            this.state.checkList.length > 0 ?
                                <Link
                                    to={{
                                        pathname: "/document/survey-all/" + this.props.survey_id + "/",
                                        state: {checkList: this.state.checkList}
                                    }}>
                                    <button type="button" className="btn btn-outline-secondary ml-3">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'}
                                            alt="print-icon"/>
                                    </button>
                                </Link> :
                                <button type="button" className="btn btn-outline-secondary ml-3"
                                        onClick={this.onClickPrintAll}>
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'}
                                        alt="print-icon"/>
                                </button>
                            :null
                        }
                    </div>

                    <div className="col-3">
                        <input type="text" className="form-control search-input pl-5"
                               placeholder= {t('survey:search')}
                               value={this.state.search}
                               name="search"
                               onChange={this.handleInputChange}
                        />
                    </div>
                </div>
            <QueryRenderer
                environment={environment}
                query={query}
                variables={{
                    search: this.props.search,
                    first: this.state.first,
                    last: this.state.last,

                    status: this.props.status,
                    surveyId: this.props.survey_id,
                }}
                cacheConfig={{use_cache: false}}
                render={({error, props}) => {
                    if (error) {
                        return <div className="alert alert-danger"
                                    role="alert">{error.message}</div>;
                    } else if (props) {
                        return (
                            <React.Fragment>
                                <div className="card mt-2">
                                    <div className="table-responsive fade-up">
                                        <table className="table table-hover">
                                            <thead className="thead-light">
                                            <tr>
                                                <th width="50">
                                                    <div
                                                        className="custom-control custom-checkbox">
                                                        <input type="checkbox"
                                                               className="custom-control-input"
                                                               id="customCheck1"
                                                               onChange={() => this.getAllList(props.surveyResidentialVote.edges)}
                                                               checked={this.state.check_all}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="customCheck1"/>
                                                    </div>
                                                </th>
                                                <th>{t('survey:room')}</th>
                                                <th width="800">{t('survey:status')}</th>
                                                <th/>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {props.surveyResidentialVote.edges.map((residential_vote) =>
                                                <tr className="show" key={residential_vote.node.id}>
                                                    <td>
                                                        <div
                                                            className="custom-control custom-checkbox">
                                                            <input type="checkbox"
                                                                   className="custom-control-input"
                                                                   id={residential_vote.node.id}
                                                                   onChange={() => this.appendToCheckList(residential_vote.node.id)}
                                                                   checked={this.checkList(residential_vote.node.id)}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={residential_vote.node.id}/>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ModalSurvey
                                                            survey_id={this.props.survey_id}
                                                            residential={residential_vote.node}
                                                            status={residential_vote.node.status}
                                                            type="result"
                                                            queryResidentialVoteList={this.queryResidentialVoteList}
                                                        />
                                                    </td>
                                                    <td>{this.translateStatus(residential_vote.node.status)}</td>
                                                    <td width="300">
                                                        {residential_vote.node.status !== "NOT_VOTE" &&
                                                        <button onClick={this.onSubmit} data-index={residential_vote.node.id}
                                                            className={residential_vote.node.status === "APPROVE" ? "btn btn-light" : "btn btn-document-received"}
                                                            disabled={(residential_vote.node.status === "APPROVE" || !_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_survey_edit'})) ? "disabled" : false}>
                                                            {residential_vote.node.status === "NOT_APPROVE" &&
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/images/icons/correct-white.png'}
                                                                alt="correct-icon"
                                                                className="pr-2"/>
                                                            }
                                                            {residential_vote.node.status === "APPROVE" &&
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/images/icons/correct-grey.png'}
                                                                alt="correct-icon"
                                                                className="pr-2"/>
                                                            }
                                                            {this.translateStatus(residential_vote.node.status)}
                                                        </button>
                                                        }
                                                    </td>
                                                    <td width="100">
                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_survey_print'})&&
                                                            <Link
                                                                to={"/contact/survey/document/survey/result/" + this.props.survey_id + "/" + residential_vote.node.id + "/" + residential_vote.node.residential.size}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'}
                                                                    alt="print-icon"
                                                                    className="show-icon pr-3"/>
                                                            </Link>
                                                        }
                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_survey_delete'}) &&
                                                        <DeleteResidentialVote
                                                            residential_vote={residential_vote.node}
                                                            queryResidentialVoteList={this.queryResidentialVoteList}/>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <Pagination changePage={this.changePage}
                                                first={this.state.first}
                                                last={this.state.last}
                                                totalCount={props.surveyResidentialVote.totalCount}/>
                                </div>
                            </React.Fragment>
                        )
                    }
                    return <Loading/>
                }}/>
            </React.Fragment>
            }</Translation>

        );
    }
}

export default SurveyDetail;
