import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';

class Navigation extends Component {
    render() {
        return (
            <Translation>
                {t=>
                <div className="row" id="navigation-tab">
                    <div className="col">
                        <ul>
                            <li>
                                <NavLink to="/silvermanguard/report/all">
                                    {t("graudreport:all")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/silvermanguard/report/notpay">
                                    {t("graudreport:notpay")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/silvermanguard/report/packing">
                                    {t("graudreport:complete")}
                                </NavLink>
                            </li> 
                        </ul>
                    </div>
                </div>}
            </Translation>
        )
    }
}

export default Navigation;