import React, {Component} from "react";
import {format} from "date-fns";
import getNameResidential from "../../../../libs/getNameResidential";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../../libs/numberWithComma";
import { graphql } from "babel-plugin-relay/macro";
import jwtDecode from 'jwt-decode';
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import './receiptDepositReportPDF.scss';
import i18next from "i18next";

const query = graphql`
  query receiptDepositReportPDFRenderTableQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

class ReceiptDepositReportPDFRenderTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectName: "",
            printBy: "",
            start_date: "",
            end_date: "",
            contactName: "",
            paymentChannel: "",
            status: "",
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            if (res.selfProject) {
                let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
                this.setState({
                    projectName: res.selfProject.name,
                    printBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    contactName: this.props.contactName,
                    paymentChannel: this.props.paymentChannel,
                    status: this.props.status,
                })
            }
        }
        )
    }

    getText(status) {
        let text = '';
        switch (status) {
            case 'DRAFT':
                text = 'แบบร่าง';
                break;
            case 'ACTIVE':
                text = 'ยังไม่ตัดชำระ';
                break;
            case 'USE_PARTIAL':
                text = 'ตัดชำระแล้วบางส่วน';
                break;
            case 'USE_ALL':
                text = 'ตัดชำระแล้วทั้งจำนวน';
                break;
            default:
                text = 'รายการที่ยกเลิก';
        }
        return text
    }

    getPaymentChannel(receipt_deposit){
        if(receipt_deposit.node.bankAccountTransaction.totalCount > 0){
            return "โอนเงิน";
        }else if(receipt_deposit.node.cashTransaction.totalCount > 0){
            return "เงินสด";
        }else if(receipt_deposit.node.chequeDeposit.totalCount > 0){
            return "เช็ค"
        }else{
            return "อื่นๆ";
        }
    }

    render() {
        // //  ประมวลผลข้อมูล
        // let summaryPrice = 0;
        let total = 0;
        let cut_pay = 0;
        let total_balance_all = 0;
        let order = 0;
        let dataChunked = [];
        let rowPerpage = 18;
        for (let i=0;i<this.props.props.receiptDepositViewer.allReceiptDeposit.edges.length ;i += rowPerpage) {
            let chunk = this.props.props.receiptDepositViewer.allReceiptDeposit.edges.slice(i, i + rowPerpage);
            dataChunked.push(chunk);            
        }
        return (
            <React.Fragment>
                {dataChunked.map((data,page_index)=>(               
                    <div className='print-page-a4 hoziA4' key={page_index}>
                                    {/* แสดงหัวกระดาษ */}
                                    <div>
                                            <p className='f12' style={{ lineHeight: 1.5 }}> {this.state.projectName}</p>
                                            <p className='f10'>รายงานรับล่วงหน้า</p>
                                            <p className='f10'>เรียงตามผู้ติดต่อ - {this.state.contactName}</p>
                                            <p className='f10'>เรียงตามวิธีชำระเงิน - {this.state.paymentChannel}</p> 
                                            <p className='f10'>เรียงตามสถานะ - {this.state.status}</p>
                                            <p className='f10'>เรียงตามวันที่ - {format(this.state.start_date, "DD-MM-YYYY")} ถึง {format(this.state.end_date, "DD-MM-YYYY")}</p>
                                    </div>
                                    {/* แสดงตาราง */}
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <table className="table tebleBorder">
                                                <thead className='tableHead'>
                                                    <tr>
                                                        <th className="text-center">ลำดับ</th>
                                                        <th className="text-center width10">
                                                            เลขที่ใบรับล่วงหน้า
                                                        </th>
                                                        <th className="text-center">
                                                            วันที่ออกใบรับล่วงหน้า
                                                        </th>
                                                        <th className="text-center">เลขที่ห้อง/บ้านเลขที่</th>
                                                        <th className="text-center width20">ชื่อ</th>
                                                        <th className="text-center width20">รายละเอียด</th>
                                                        <th className="text-center width7">จำนวนเงิน</th>
                                                        <th className="text-center width7">ตัดชำระแล้ว</th>
                                                        <th className="text-center width7">คงเหลือ</th>
                                                        <th className="text-center">
                                                            วิธีการรับชำระ
                                                        </th>
                                                        <th className="text-center">สถานะ</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tableBody'>
                                                {data.map((receipt_deposit, index) => {                                                    
                                                    let total_use = 0
                                                    let total_balance = 0
                                                    if (receipt_deposit.node.status !== "VOID") {
                                                        total += receipt_deposit.node.total;
                                                        receipt_deposit.node.receiptDepositTransactionUsed.edges.map((val)=>{ 
                                                            cut_pay += val.node.amount
                                                            total_use = total_use + val.node.amount
                                                            return val
                                                        })
                                                        total_balance += receipt_deposit.node.total - total_use
                                                        total_balance_all += total_balance

                                                        return (
                                                            <React.Fragment key={receipt_deposit.node.id}>
                                                            <tr>
                                                                <td className="text-center">{order+=1}</td>
                                                                <td className="text-center">
                                                                    {receipt_deposit.node.docNumber}
                                                                </td>
                                                                <td className="text-center">{format(receipt_deposit.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td className="text-center">
                                                                    {receipt_deposit.node.contact.residential ? receipt_deposit.node.contact.residential.name : "-"}
                                                                </td>
                                                                <td className="text-left">
                                                                    {receipt_deposit.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                                        getNameResidential(receipt_deposit.node.firstName, receipt_deposit.node.lastName)
                                                                    }

                                                                    {receipt_deposit.node.contact.typeOfContact === "SUPPLIER" &&
                                                                        receipt_deposit.node.name
                                                                    }
                                                                    {" "}                                            
                                                                    {
                                                                    (receipt_deposit.node.payGroup === "RENT" || receipt_deposit.node.payGroup === "AGENT" || receipt_deposit.node.payGroup === "OWNER" || receipt_deposit.node.payGroup === "DEVELOPER") &&
                                                                    <label>
                                                                    ({receipt_deposit.node.payGroup === "RENT" || receipt_deposit.node.payGroup === "AGENT" || receipt_deposit.node.payGroup === "OWNER" || receipt_deposit.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${receipt_deposit.node.payGroup}`) : ""})
                                                                    </label>
                                                                    }                                                                       
                                                                </td>
                                                                <td className="text-left">{receipt_deposit.node.receiptDepositTransaction.edges[0].node.description}</td>
                                                                <td className="text-right space-right">{numberWithComma(receipt_deposit.node.total)}</td>
                                                                <td className="text-right space-right">{numberWithComma(total_use)}</td>
                                                                <td className="text-right space-right">{numberWithComma(total_balance)}</td>
                                                                <td className="text-center">
                                                                    {this.getPaymentChannel(receipt_deposit)}
                                                                </td>
                                                                <td className="text-center">{this.getText(receipt_deposit.node.status)}</td>
                                                            </tr>
                                                            {
                                                            (index === data.length - 1) &&   
                                                            (<tr>
                                                                <td colSpan={6} className="text-center"><strong>รวม</strong></td>
                                                                <td className="text-right space-right"><strong>{numberWithComma(total)}</strong></td>
                                                                <td className="text-right space-right"><strong>{numberWithComma(cut_pay)}</strong></td>
                                                                <td className="text-right space-right"><strong>{numberWithComma(total_balance_all)}</strong></td>
                                                                <td />
                                                                <td />
                                                            </tr>)
                                                            }
                                                            </React.Fragment>
                                                        )
                                                    } else {
                                                        return (
                                                            <React.Fragment key={receipt_deposit.node.id}>
                                                            <tr>
                                                                <td className="text-center">{order+=1}</td>
                                                                <td className="text-center">
                                                                    {receipt_deposit.node.docNumber}
                                                                </td>
                                                                <td className="text-center">{format(receipt_deposit.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td className="text-center">
                                                                    {receipt_deposit.node.contact.residential ? receipt_deposit.node.contact.residential.name : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {receipt_deposit.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                                        getNameResidential(receipt_deposit.node.firstName, receipt_deposit.node.lastName)
                                                                    }

                                                                    {receipt_deposit.node.contact.typeOfContact === "SUPPLIER" &&
                                                                        receipt_deposit.node.name
                                                                    }
                                                                {" "}                                            
                                                                {
                                                                (receipt_deposit.node.payGroup === "RENT" || receipt_deposit.node.payGroup === "AGENT" || receipt_deposit.node.payGroup === "OWNER" || receipt_deposit.node.payGroup === "DEVELOPER") &&
                                                                <label>
                                                                ({receipt_deposit.node.payGroup === "RENT" || receipt_deposit.node.payGroup === "AGENT" || receipt_deposit.node.payGroup === "OWNER" || receipt_deposit.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${receipt_deposit.node.payGroup}`) : ""})
                                                                </label>
                                                                }                                                                  
                                                                </td>
                                                                <td className="text-center">{receipt_deposit.node.receiptDepositTransaction.edges[0].node.description}</td>
                                                                <td className="text-right space-right">{numberWithComma(0)}</td>
                                                                <td className="text-right space-right">{numberWithComma(0)}</td>
                                                                <td className="text-right space-right">{numberWithComma(0)}</td>
                                                                <td className="text-center">
                                                                    {receipt_deposit.node.bankAccountTransaction.totalCount > 0 && "โอนเงิน"}
                                                                    {receipt_deposit.node.cashTransaction.totalCount > 0 && "เงินสด"}
                                                                    {receipt_deposit.node.chequeDeposit.totalCount > 0 && "เช็ค"}
                                                                </td>
                                                                <td className="text-center">{this.getText(receipt_deposit.node.status)}</td>
                                                            </tr>
                                                            {
                                                                (index === data.length - 1) &&   
                                                                (<tr>
                                                                    <td colSpan={6} className="text-center"><strong>รวม</strong></td>
                                                                    <td className="text-right space-right"><strong>{numberWithComma(total)}</strong></td>
                                                                    <td className="text-right space-right"><strong>{numberWithComma(cut_pay)}</strong></td>
                                                                    <td className="text-right space-right"><strong>{numberWithComma(total_balance_all)}</strong></td>
                                                                    <td />
                                                                    <td />
                                                                </tr>)
                                                            }    
                                                            </React.Fragment>                                                        
                                                        )
                                                    }
                                                })
                                                }       
                                                </tbody>
                                                <tfoot className="tableFooter">
                                                    {page_index === dataChunked.length - 1 &&
                                                        (<tr key={page_index}>
                                                            <td colSpan={6} className="text-center"><strong>รวมทั้งหมด</strong></td>
                                                            <td className="text-right space-right"><strong>{numberWithComma(this.props.props.receiptDepositViewer.summaryReceiptDeposit)}</strong></td>
                                                            <td className="text-right space-right"><strong>{numberWithComma(this.props.summaryUsePartialReceiptDeposit.cut_pay)}</strong></td>
                                                            <td className="text-right space-right"><strong>{numberWithComma(this.props.summaryUsePartialReceiptDeposit.total_balance)}</strong></td>
                                                            <td />
                                                            <td />
                                                        </tr>)   
                                                    }   
                                                </tfoot>
                                            </table>
                                        </div>    
                                    </div>    
                                    {/* แสดงเลขหน้า และผู้พิมพ์ */}
                                    <div className="footer">
                                        <div className="row">
                                            <div className="col-5" />
                                            <div className="col-2 text-center">
                                                <p className="f10 pb-0 mb-0">{page_index + 1}/{dataChunked.length}</p>
                                            </div>
                                            <div className="col-5 text-right">
                                                <p className="f10 pb-0 mb-0">
                                                    ผู้พิมพ์ {this.state.printBy} วันที่พิมพ์{format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>   
                    </div>
                ))}  
            </React.Fragment>
        )
    }
}

export default ReceiptDepositReportPDFRenderTable;