import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import Swal from "sweetalert2";
import UserNameInput from "./userNameInput";
import PasswordValidation from './passwordValidity'
import UploadImageInput from "../../libs/uploadImageInput";
import "./style/createUpdateSettingJuristic.scss"
import Redirect from "react-router-dom/es/Redirect";
import i18n from "i18next";
import { Translation } from "react-i18next";
import jwtDecode from 'jwt-decode'
const _ = require('lodash');
const $ = window.jQuery;


const query = graphql`
    query createUpdateSettingJuristicQuery($id: ID!) {
        juristic(id: $id) {
            id
            user {
              id
              username
            }
            firstName
            lastName
            role
            image
            signature
            phone 
            email
        } 

    }
`;

const mutation = graphql`
    mutation createUpdateSettingJuristicMutation($input: CreateUpdateJuristicInput!) {
        createUpdateJuristic(input: $input) {
            newJuristic {
                id
            }
        }


    }
`;



class CreateUpdateSettingJuristic extends Component {
    constructor(props) {
        super(props);
        this.state = {

            existUserName: false,
            passwordResult: false,
            loading: false,
            redirectToList: false,
            checkPassword: false,
            checkConfirmPassword: false,
            confirmPassword: '',

            token: jwtDecode(window.localStorage.getItem('token')),

            upload_image: {
                image: '',
                signature: '',
            },

            juristic: {
                id: '',
                user: {
                    id: '',
                    username: '',
                    password: '',
                },
                firstName: '',
                lastName: '',
                role: '',
                image: '',
                signature: '',
                phone:'',
                email:''
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
        this.showHidePassword = this.showHidePassword.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            // update
            fetchQuery(environment, query, {id: this.props.match.params.id}).then(data => {
                this.setState({juristic: data.juristic})
            });
        }
    }



    handleInputChange(e) {
        let event = _.cloneDeep(e);
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                event.target.value
            );
        });

// || this.state.token?.site?.includes('port80')
        if(this.state.token?.site?.includes('sklasmanagement') || this.state.token?.site?.includes('victorymanagement') ) {
            var pass  = document.getElementById("password").value;
            var rpass  = document.getElementById("confirmPassword").value;

            if(pass !== rpass){
                this.setState({checkConfirmPassword : true})
            }else{
                this.setState({checkConfirmPassword : false})
            }
            if (event.target.name === 'juristic.user.password'){
                this.setState({passwordResult: event.target.dataset.passwordResult})
            }
        }

        if (event.target.name === 'juristic.user.username') { //check if username is exist
            this.setState({existUserName: event.target.dataset.existUserName})
        }
        

    }

    showHidePassword() {
        var password = document.getElementById("password");
        var confirmPassword = document.getElementById("confirmPassword");

        if(password.type === "password" && confirmPassword.type === "password") {
            password.type = "text";
            confirmPassword.type = "text";
        } else {
            password.type = "password";
            confirmPassword.type = "password";
        }
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.state.existUserName){
            $('#username').focus();
            return
        }

        //only sena and sklasmanagement
       if(this.state.token?.site?.includes('sklasmanagement') || this.state.token?.site?.includes('victorymanagement')) {
                if (!this.state.passwordResult){
                    $('#password').focus();
                    return
                }
        
                if (this.state.checkConfirmPassword){
                    $('#confirmPassword').focus();
                    return
                }
        }
        
        this.setState({loading: true});

        let variables = {
            input: {
                juristic: JSON.stringify(this.state.juristic)
            }
        };
        
        let uploadables = this.state.upload_image;
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    uploadables,
                    onCompleted: (response) => {
                        this.setState({loading: false});
                        if (response) {
                            Swal.fire(i18n.t("premission:Save successfully!"), '', 'success').then(() => {
                                this.setState({redirectToList: true});
                            });
                        } else {
                            Swal.fire(i18n.t("premission:Save unsuccessfully!"), i18n.t("premission:Please check again"), 'warning');
                        }
                    },
                    onError: (err) => {
                        this.setState({loading: false});
                        Swal.fire('Error!', i18n.t("premission:Please try again"), 'warning')
                    },
                },
            )
    }

    render() {

        if (this.state.redirectToList) {
            return <Redirect to="/setting/juristic-setting"/>
        }

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <Translation>
                        {t=>
                            <div className="container-fluid box" id="project-manager-list">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/setting/juristic-setting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            {
                                                this.props.match.params.id ? t("premission:Edit juristic person") : t("premission:Add juristic person")
                                            }
                                        </h3>
                                    </div>
                                </div>
                                <form onSubmit={this.onSubmit}>
                                <div className="content-inner">
                                    <div className="row mb-4">
                                        <div className="col-md-10 col-xl-10">
                                            <div className="card p-3 fade-up">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-5 col-12">
                                                            <div className="row">
                                                                <div className="col-md-5 col-5">
                                                                    {this.state.juristic.image ?
                                                                        <img
                                                                            src={this.state.juristic.image}
                                                                            className="rounded-circle avatar-img-juristic profile-img"
                                                                            alt="juristic-profile"/>
                                                                        :
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                            className="rounded-circle avatar-img-juristic profile-img"
                                                                            alt="juristic-profile"/>
                                                                    }
                                                                </div>
                                                                <div className="col-md-7 col-12">
                                                                    <h6 className="mt-4">{t("premission:Picture")}</h6> <small className="text-red">
                                                                       {t("premission:Attach files (JPG 10 MB)")}
                                                                    </small>
                                                                    <div className="upload-file">
                                                                        <div>
                                                                            <label className="btn-link"
                                                                                htmlFor="uploadImage">{t("premission:Change picture")}</label>
                                                                        </div>
                                                                        <div className="upload-input">
                                                                            <UploadImageInput id="uploadImage"
                                                                                            setField="upload_image.image:juristic.image"
                                                                                            onChange={this.handleInputChange}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                            <div className="row mt-4">
                                                                <div className="col-md-5">
                                                                    {this.state.juristic.signature ?
                                                                        <img
                                                                            src={this.state.juristic.signature}
                                                                            className="rounded-circle avatar-img-juristic profile-img"
                                                                            alt="juristic-signature"/>
                                                                        :
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/signature-upload-icon.png'}
                                                                            className="rounded-circle avatar-img-juristic profile-img"
                                                                            alt="juristic-signature"/>
                                                                    }
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <h6 className="mt-4">{t("premission:Signature")}</h6><small className="text-red">
                                                                        {t("premission:Attach files (JPG 10 MB)")}<br/>
                                                                        {t("premission:The recommended image size is 180 pixels in height and 400 pixels in width")}
                                                                    </small>
                                                                    <div className="upload-file">
                                                                        <div>
                                                                            <label className="btn-link"
                                                                                htmlFor="uploadSignature">{t("premission:Change picture")}</label>
                                                                        </div>
                                                                        <div className="upload-input">
                                                                            <UploadImageInput id="uploadSignature"
                                                                                            setField="upload_image.signature:juristic.signature"
                                                                                            onChange={this.handleInputChange}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
        
                                                        <div className="col-md-7 col-7">
                                                            <div className="row mt-2">
                                                                <div className="col">
                                                                    <UserNameInput name="juristic.user.username"
                                                                                value={this.state.juristic.user.username}
                                                                                handleInputChange={this.handleInputChange}
                                                                                disabled={this.props.match.params.id ? true : false
                                                                                }
                                                                    />
                                                                </div>
                                                            </div>
        
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <h6>
                                                                        {this.props.match.params.id ? t("premission:New password") : t("premission:Password")}
                                                                    </h6>
                                                                        {
                                                                        this.state.token?.site?.includes('sklasmanagement') || this.state.token?.site?.includes('victorymanagement') ? 
                                                                        <PasswordValidation  
                                                                            name="juristic.user.password"  
                                                                            username={this.state.juristic.user.username}
                                                                            value={this.state.juristic.user.password || ''}
                                                                            params = {this.props.match.params.id}
                                                                            handleInputChange={this.handleInputChange}
                                                                            id="password"
                                                                         /> 
                                                                         :
                                                                            <input type="text" className="form-control"
                                                                            name="juristic.user.password"
                                                                            value={this.state.juristic.user.password || ''}
                                                                            params = {this.props.match.params.id}
                                                                            handleInputChange={this.handleInputChange}
                                                                            onChange={this.handleInputChange} 
                                                                            required={this.props.match.params.id ? false : true}
                                                                            />
                                                                        }
                                                                </div>
                                                            </div>
                                                            {
                                                            this.state.token?.site?.includes('sklasmanagement') || this.state.token?.site?.includes('victorymanagement') ? 
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <h6>
                                                                        {t("premission:Confirm Password")}
                                                                    </h6>
                                                                    <input type="password" className="form-control"
                                                                        name="confirmPassword"
                                                                        id="confirmPassword"
                                                                        value={this.state.confirmPassword}
                                                                        required
                                                                        onChange={this.handleInputChange} 
                                                                        />
                                                                      {
                                                                        this.state.checkConfirmPassword ?  <h6 style={{color:'#DC3545'}}><small> {t("premission:new password is same with the old password")}</small></h6> : null
                                                                      }
                                                                      <div style={{display:'inline-flex'}}>
                                                                        <input type="checkbox" onClick={this.showHidePassword}/> <h6> <small 
                                                                        style={{padding:'2px 5px 5px 5px',position:'relative',bottom:'-4px'}}>{t("premission:Show Password")}</small></h6> 
                                                                      </div>
                                                                </div>
                                                            </div> : null
                                                            }

                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <h6>
                                                                        {t("premission:Name")}
                                                                    </h6>
                                                                    <input type="text" className="form-control"
                                                                        name="juristic.firstName"
                                                                        value={this.state.juristic.firstName}
                                                                        onChange={this.handleInputChange} required={this.props.match.params.id ? false : true}/>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <h6>
                                                                        {t("premission:Surname")}
                                                                    </h6>
                                                                    <input type="text" className="form-control"
                                                                        name="juristic.lastName"
                                                                        value={this.state.juristic.lastName}
                                                                        onChange={this.handleInputChange} required={this.props.match.params.id ? false : true}/>
                                                                </div>
                                                            </div>
        
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <h6>
                                                                        {t("premission:Position")}
                                                                    </h6>
                                                                    <input type="text" className="form-control"
                                                                        name="juristic.role"
                                                                        value={this.state.juristic.role}
                                                                        onChange={this.handleInputChange} required={this.props.match.params.id ? false : true}/>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <h6>
                                                                        {t("projectManager:Phone")}
                                                                    </h6>
                                                                    <input type="text" className="form-control"
                                                                        name="juristic.phone"
                                                                        value={this.state.juristic.phone}
                                                                        onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <h6>
                                                                        {t("projectManager:Email")}
                                                                    </h6>
                                                                    <input type="text" className="form-control"
                                                                        name="juristic.email"
                                                                        value={this.state.juristic.email}
                                                                        onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <button type="submit" className="btn btn-primary bt-style"
                                                                            disabled={this.state.loading}>
                                                                        {this.state.loading &&
                                                                        <span
                                                                            className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                                        {t("premission:Confirm")}
                                                                    </button>
                                                                </div>
                                                                <div className="col">
        
                                                                    <Link to="/setting/juristic-setting">
                                                                        <button type="button"
                                                                                className="btn btn-secondary bt-style">
                                                                            {t("premission:Cancel")}
                                                                        </button>
                                                                    </Link>
                                                                </div>
                                                            </div>
        
                                                        </div>
                                                    </div>
        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </form>
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default CreateUpdateSettingJuristic;
