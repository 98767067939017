import React from "react";
import ComponentPagination from "../../libs/componentPagination";
import Pagination from "../../libs/newPagination";

import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import "../styles/register.scss";

import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import Loading from "../../libs/loading";


const allResidential = graphql`
 query coownerListQuery(
    $first: Int
    $last: Int
    ){
    allRegisterInformation(
      first: $first
      last: $last
    ) {
      totalCount
          edges{
              node{
                  id
                  residentialId
                  residentialName
                  typeRegister
                  type
                  nameTitle
                  firstName
                  lastName
                  dateOfBirth
                  gender
                  phone
                  sklas
                  idLine
                  whatApp
                  wechat
                  email
                  receiveDocuments
                  province
                  district
                  subDistrict
                  zipCode
                  address
                  registerInformationResident{
                      edges{
                          node{
                              id
                              firstName
                              lastName
                              dateOfBirth
                              gender
                              phone
                          }
                      }
                  }
                  vehicleInformation{
                      edges{
                          node{
                              id
                              licensePlate
                              vehicleType
                              brand
                              color
                              carPowerType
                              using
                          }
                      }
                  }
                  registerInformationFile{
                      edges{
                          node{
                              id
                              fileName
                              fileUpload
                          }
                      }
                  }
                  health{
                      edges{
                          node{
                            id
                            congenitalDisease
                            signatureFileName
                            signatureFileUpload
                            congenitalDiseaseList{
                                edges{
                                    node{
                                        id
                                        congenitalDiseaseName
                                        congenitalDiseaseNameOther
                                    }
                                }
                            }
                            treatmentInformation{
                                edges{
                                    node{
                                        id
                                        hospitalName
                                        doctorName
                                        diseaseTreatment
                                        drugsto
                                    }
                                }
                            }
                            emergencyContact{
                                edges{
                                    node{
                                        id
                                        name
                                        phone
                                        email
                                    }
                                }
                            }
                          }
                      }
                  }
                  

              }
        } 
      }
}
`;

class CoownerList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.search = "";
    this.state.filter_download = "all";
    this.state.temp_filter_download = "all";

  }

  onChangeSearch(text) {
    this.setState({ search: text, filter_download: this.state.temp_filter_download });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  
  handleSearch(event) {
    this.setState({
      search: event.target.value,
      filter_download: this.state.temp_filter_download,
    });
  }

  handlePropsChange(channel_list) {
    let search = ""
    let start_date = null
    let end_date = null
    channel_list.forEach((e, index) => {
      if (e.name === "search") {
        search = e.value
      }
      else if (e.name === "start_date" && e.value !== '') {
        start_date = e.value
      }
      else if (e.name === "end_date" && e.value !== '') {
        end_date = e.value
      }

    });


    this.setState({
      search: search,
      start_date: start_date,
      end_date: end_date,
      filter_download: this.state.temp_filter_download,
    })
  }



  calAge(params) {
    const birthday = new Date(params);
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    const monthDiff = today.getMonth() - birthday.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }
    return age
  }

  render() {
    return (
            
      <>
        <QueryRenderer
                environment={environment}
                query={allResidential}
                cacheConfig={{ use_cache: false }}
                variables={{
                  first: this.state.first,
                  last: this.state.last
                }}
                render={({ error, props }) => {
                  if (error) {
                    return (
                      <div className="alert alert-danger" role="alert">
                        {error.message}
                      </div>
                    );
                  } else if (props) {

                    let num = 1;

                    return (
                      <React.Fragment>
                            <div className="card fade-up">
                            <div className="table-responsive">
                            <table className="table table-hover">
                                <thead className="thead-light">
                                <Translation>
                                 {t =>
                                    <tr>
                                      <th>{t("residential:No.")}</th>
                                      <th>{t("residential:Room")}</th>
                                      <th className="text-center">{t("residential:Status")} </th>
                                      <th className="text-center">{t("residential:Name")} - {t("residential:Surname")}</th>
                                      <th> </th>
                                    </tr> 
                                  }
                                  </Translation>
                                </thead>
                                <tbody>
                         
                                  {props?.allRegisterInformation?.edges.map(
                                        (coowner, index) => {
                                          let no = num++
                                          return (
                                            <>
                                              <Translation>
                                              {t =>
                                              <tr className="clickable" data-toggle="collapse" id="row1" data-target={`.row`+no}>
                                                <td width="200" >{no}</td>
                                                <td width="200">{coowner.node?.residentialName ? coowner.node?.residentialName : '-'}</td>
                                                <td className="text-center"> {coowner.node?.type === 'OWNER_RESIDENT' ? t("residential:Owner Resident") : coowner.node?.type === 'RENTER' ? t("residential:Renter") : coowner.node?.type === 'AGENT' ? t("residential:Agent") :coowner.node?.type} </td>
                                                <td className="text-center">{coowner?.node?.nameTitle} {coowner?.node?.firstName} {coowner?.node?.lastName}</td>
                                                <td><button className="btn btn-default btn-sm">{t("residential:View more") }</button></td>
                                               </tr>
                                               }
                                               </Translation>
                                               <Translation>
                                              {t =>
                                             <tr className={`p-2 collapse row`+no}>
                                                <td colspan="999">
                                                  <div>
                                                    <h6>{t("residential:Details")}</h6>
                                                    <table className="table table-striped">
                                                      <thead>
                                                        <tr>
                                                        <th>{t("residential:Sex")}</th>
                                                        <th className="text-center">{t("residential:Age")}</th>
                                                        <th className="text-center"> {t("residential:Birthday")}</th>
                                                        <th className="text-center"> {t("residential:Address")}</th>
                                                        <th>{t("residential:Contact")}</th>
                                                        <th>{t("residential:Information Resident")}</th>
                                                        <th>{t("residential:Details")}</th>
                                                        <th>{t("residential:Docs")}</th>
                                                        <th>{t("residential:Signature")}</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                        <td> {coowner?.node?.gender === 'MALE' ? t(`residential:Men`) : t(`residential:Women`)}</td>
                                                        <td className="text-center"> {coowner?.node?.dateOfBirth ? this.calAge(coowner?.node?.dateOfBirth) : '-'}</td>  
                                                        <td className="text-center"> {coowner?.node?.dateOfBirth ? coowner?.node?.dateOfBirth : '-'}</td>
                                                        <td  width="100"> {coowner?.node?.address} {coowner?.node?.district} {coowner?.node?.province} {coowner?.node?.zipCode}</td>
                                                        <td  width="200">
                                                         <strong>{t("residential:Tell")}:</strong>  {coowner?.node?.phone ? coowner?.node?.phone :'-'} <br/> 
                                                         <strong>{t("residential:Email")}:</strong>  {coowner?.node?.email ? coowner?.node?.email : '-'} <br/>
                                                         <strong>Line:</strong> {coowner?.node?.idLine ? coowner?.node?.idLine : '-'} <br/>
                                                         <strong>Wechat:</strong> {coowner?.node?.wechat ? coowner?.node?.wechat : '-'} <br/>
                                                         <strong>whatApp: </strong> {coowner?.node?.whatApp ? coowner?.node?.whatApp : '-'} <br/>
                                                        </td>
                                                        <td>
                                                          {
                                                            coowner?.node?.registerInformationResident?.edges.map(
                                                            (regis, index) => {   
                                                              return (
                                                                   <>
                                                                     <span>
                                                                    <strong>{t("residential:Birthday")}:</strong> {regis?.node?.dateOfBirth ? regis?.node?.dateOfBirth :'-'} <br/> 
                                                                     <strong> {t("residential:Name")} - {t("residential:Surname")}:</strong> {regis.node.firstName}  {regis.node.lastName}  <br/> 
                                                                     <strong>{t("residential:Tell")}:</strong>{regis.node.phone ? regis.node.phone : '-'}  <br/> 
                                                                      </span>
                                                                   </>
                                                                )
                                                            })
                                                          }
                                                        </td>
                                                        <td>
                                                        {
                                                            coowner?.node?.vehicleInformation?.edges.map(
                                                              (vehic, index) => {       
                                                                return (
                                                                  <>
                                                                    <span>
                                                                    <strong>{t("residential:Brand")}:</strong> {vehic.node.brand} <br/> 
                                                                    <strong>{t("residential:Vehicle Power Type")}:</strong> {vehic.node.carPowerType === 'OIL' ? t("residential:OIL") : vehic.node.carPowerType }  <br/> 
                                                                    <strong>{t("residential:Color")}:</strong> {vehic.node.color}  <br/> 
                                                                    <strong>{t("residential:Using")}: </strong>{vehic.node.using ==='ALWAYS' ? t("residential:ALWAYS") : '-'}  <br/> 
                                                                    <strong>{t("residential:Vehicle Type")}:</strong> {vehic.node.vehicleType === 'MOTORCYCLE' ? t("residential:MOTORCYCLE") : t("residential:CAR")}  <br/> 
                                                                    <strong>{t("residential:License Plate")}:</strong> {vehic?.node?.licensePlate ? vehic?.node?.licensePlate : '-'}  <br/> 
                                                                    </span>
                                                                  </>
                                                                )
                                                                })
                                                          }
                                                        </td>
                                                        <td>
                                                          {
                                                            coowner?.node?.registerInformationFile?.edges.map(
                                                              (docs, index) => {               
                                                                return (
                                                                  <>
                                                                  <Link to="route"
                                                                      onClick={(event) => {
                                                                      event.preventDefault();
                                                                      window.open(
                                                                         typeof docs.node.fileUpload === "string"
                                                                          ? docs.node.fileUpload
                                                                          : URL.createObjectURL(docs.node.fileUpload))
                                                                      }}
                                                                          target="blank">
                                                                       <span>{docs.node.fileName} <br/></span>
                                                                    </Link>
                                                                  </>
                                                                )
                                                                })
                                                          }
                                                        </td>
                                                        <td>
                                                        {
                                                          coowner?.node?.health?.edges.map(
                                                              (health, index) => {                   
                                                                  return (
                                                                    <>
                                                                    <td>
                                                                      <img src={health.node.signatureFileUpload} alt="signatureFileUpload"
                                                                      style={{width:'100px'}}/>
                                                                    </td>
                                                                    </>
                                                                  )
                                                              })
                                                          }
                                                        </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </td>
                                              </tr>
                                              }
                                              </Translation>
                                              <tr className={`p-2 collapse row`+no}>
                                                <td colspan="999">
                                                <Translation>
                                                  {t =>
                                                  <div>
                                                    <h6>{t("residential:Health")}</h6>
                                                    <table className="table table-striped">
                                                      <thead>
                                                        <tr>
                                                          <th>{t("residential:Congenital Disease")}</th>
                                                          <th>{t("residential:Hospital Name")}</th>
                                                          <th>{t("residential:Doctor Name")}</th>
                                                          <th>{t("residential:Disease Treatment")}</th>
                                                          <th>{t("residential:Drug")}</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                        {
                                                         coowner?.node?.health?.edges.map((health, index) => {                   
                                                          return (
                                                                <>
                                                                 <td>{health.node.congenitalDisease === 'YES' ? t(`residential:Yes`): '-'}</td>
                                                                  {health?.node?.treatmentInformation?.edges.map(
                                                                     (val, index) => {   
                                                                  return (
                                                                      <>
                                                                        <td>{val.node.hospitalName}</td>  
                                                                        <td>{val.node.doctorName}</td>
                                                                        <td>{val.node.diseaseTreatment}</td>
                                                                        <td>{val.node.drugsto}</td>
                                                                      </>
                                                                        )
                                                                      })
                                                                    }
                                                                </>
                                                                  )
                                                            })
                                                          }
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                   </div>
                                                  }
                                                </Translation>
                                                </td>
                                                
                                              </tr>
                                             
                                            </>
                                          )
                                   })}
                                
                                </tbody>
                              </table>
                            </div> 
                           </div>
                        <div className="row">
                          <Pagination
                            changePage={this.changePage}
                            first={this.state.first}
                            last={this.state.last}
                            totalCount={props.allRegisterInformation.totalCount}
                          />
                        </div>

                      </React.Fragment>
                    );
                  }
                  return <Loading />
                }}
              />
      </>
           
    );
  }
}

export default CoownerList;
