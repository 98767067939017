import React, {Component} from 'react';

import $ from "jquery";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import "../styles/cashDeposit.scss";
import { convertToTimeZone } from '../../libs/date';
import i18n from 'i18next'
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';

const ThaiBaht = require('thai-baht-text');

class CashDeposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languages: "th",
        };
    }

    componentDidMount() {
        this.setState({
            languages: this.props.languages
        })
        setTimeout(() => {
            let page = $('.'+this.props.cash_page);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + (detail+detail) + invoice + signature);
            $('.' + this.props.cash_page + ' table').css({borderBottom: "1px solid"});
            if (this.props.current_page !== this.props.end_page) {
                $('.' + this.props.cash_page + ' tbody tr:last-child td').css({paddingBottom: "35px"});
            } else {
                $('.' + this.props.cash_page + ' tbody tr:last-child td').css({paddingBottom: diff});
            }
        }, 200);
        

    }

    render() {
        return (

            <div className={"print-daily-journal-a4 " + this.props.cash_page} id="cash_deposit">
                <div className="subpage">
                    <div className="head">
                        <div className="juristic-description" style={{position:"relative"}}>
                            <img src={this.props.query.selfProject.logo} alt="silverman"/>
                            <span className="text-center" style={{position:"absolute"}}>
                                <div><strong style={{fontSize:12}}>{this.state.languages === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name): this.props.query.selfProject.name}</strong></div>
                                <div>{this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}</div>
                                <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                            </span>
                        </div>
                    </div>
                    <div className="detail mt-3" >
                        <div className="customer-detail">
                            <br />
                            <strong>รายการนำฝากเงินสด ประจำวันที่ {this.props.date ? format(convertToTimeZone(this.props.date, this.props.query.selfProject.timeZone), 'DD/MM/YYYY', {locale: thLocale}) : format(new Date(), 'DD/MM/YYYY', {locale: thLocale})} </strong>
                        </div>
                        <div className="document-detail" >
                            <strong className="sub-document-detail">เลขที่ / No :</strong> {this.props.docNumber ? this.props.docNumber : '-'}
                            <br/>
                            <strong className="sub-document-detail">วันที่ / Date :</strong> {this.props.date ? format(convertToTimeZone(this.props.date, this.props.query.selfProject.timeZone), 'DD/MM/YYYY', {locale: thLocale}) : format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}
                        </div>
                        
                    </div>
                    

                    <div className="invoice-body">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th className="text-center">
                                    <strong>เลขที่</strong>
                                </th>
                                <th className="text-center">
                                    <strong>วันที่</strong>
                                </th>
                                <th className="text-center">
                                    <strong>คำอธิบาย</strong>
                                </th>
                                <th className="text-center">
                                    <strong>จำนวนเงิน</strong>
                                </th>
                            </tr>
                            </thead>
                            <tbody>

                            {this.props.all_cash_transactions.map((cash_transaction)=>{
                                let doc_number = '';
                                let issuedDate = '';

                                if (cash_transaction.node.receive) {
                                    doc_number = cash_transaction.node.receive.docNumber;
                                    issuedDate = cash_transaction.node.receive.issuedDate;
                                } else if (cash_transaction.node.invoice) {
                                    doc_number = cash_transaction.node.invoice.docNumber;
                                    issuedDate = cash_transaction.node.invoice.issuedDate;
                                } else if (cash_transaction.node.receiptDeposit) {
                                    doc_number = cash_transaction.node.receiptDeposit.docNumber;
                                    issuedDate = cash_transaction.node.receiptDeposit.issuedDate;
                                } else if (cash_transaction.node.guaranteeMoneyReceive) {
                                    doc_number = cash_transaction.node.guaranteeMoneyReceive.docNumber;
                                    issuedDate = cash_transaction.node.guaranteeMoneyReceive.issuedDate;
                                } else if (cash_transaction.node.clearAdvance) {
                                    doc_number = cash_transaction.node.clearAdvance.docNumber;
                                    issuedDate = cash_transaction.node.clearAdvance.issuedDate;
                                } else if (cash_transaction.node.otherReceive) {
                                    doc_number = cash_transaction.node.otherReceive.docNumber;
                                    issuedDate = cash_transaction.node.otherReceive.issuedDate;  
                                }
                                return(
                                    <tr key={cash_transaction.node.id}>
                                        <td className="text-center">
                                            {doc_number}
                                        </td>
                                        <td className="text-center"> {format(issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                        <td >{cash_transaction.node.description}</td>
                                        <td className="text-right">{numberWithComma(cash_transaction.node.price)}</td>
                                    </tr>
                                )
                            })}

                            </tbody>
                            <tfoot>
                            <tr>
                                <td className="text-center" style={{borderRight: "none"}}>
                                    <strong>รวม</strong>
                                </td>
                                <td colSpan={2} className="text-left" style={{borderLeft:"none"}}>
                                    <strong style={{fontSize:12}}>                                        
                                        {this.state.languages === 'th' ? 
                                        (ThaiBaht(this.props.total)):
                                        (ConvertNumberToTextEng(this.props.total))}
                                    </strong>
                                </td>
                                <td className="text-right">
                                    <strong style={{fontSize:12}}>{numberWithComma(this.props.total)}</strong>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div className="footer">
                        <div className="row pt-5">
                            <div className="col">
                                <span className="box-right">
                                ..................................................<br/>
                                <strong>ผู้จัดทำ</strong>
                            </span>
                            </div>
                        </div>
                        <div className="pt-2">
                            <span className="box-right">
                            วันที่ ................................
                            </span>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

export default CashDeposit;
