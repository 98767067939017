/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type detailTeamQueryVariables = {|
  teamType?: ?string,
  search?: ?string,
  first?: ?number,
  last?: ?number,
  employmentStatus?: ?string,
|};
export type detailTeamQueryResponse = {|
  +allTeam: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +teamType: ?string,
        +firstName: string,
        +lastName: string,
        +department: ?string,
        +position: ?string,
        +authorizationRight: boolean,
        +maximumApprovalLimit: ?string,
        +dateOfPosition: ?any,
        +onlineStatus: ?any,
        +image: ?string,
        +employmentStatus: ?string,
      |}
    |}>,
  |}
|};
export type detailTeamQuery = {|
  variables: detailTeamQueryVariables,
  response: detailTeamQueryResponse,
|};
*/


/*
query detailTeamQuery(
  $teamType: String
  $search: String
  $first: Int
  $last: Int
  $employmentStatus: String
) {
  allTeam(teamType: $teamType, search: $search, first: $first, last: $last, employmentStatus: $employmentStatus) {
    totalCount
    edges {
      node {
        id
        teamType
        firstName
        lastName
        department
        position
        authorizationRight
        maximumApprovalLimit
        dateOfPosition
        onlineStatus
        image
        employmentStatus
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "employmentStatus"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamType"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "employmentStatus",
        "variableName": "employmentStatus"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "teamType",
        "variableName": "teamType"
      }
    ],
    "concreteType": "TeamNodeConnection",
    "kind": "LinkedField",
    "name": "allTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "teamType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "department",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "authorizationRight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maximumApprovalLimit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dateOfPosition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onlineStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "detailTeamQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "detailTeamQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "4e4f4856a743d6e7bf831b79d248aa8d",
    "id": null,
    "metadata": {},
    "name": "detailTeamQuery",
    "operationKind": "query",
    "text": "query detailTeamQuery(\n  $teamType: String\n  $search: String\n  $first: Int\n  $last: Int\n  $employmentStatus: String\n) {\n  allTeam(teamType: $teamType, search: $search, first: $first, last: $last, employmentStatus: $employmentStatus) {\n    totalCount\n    edges {\n      node {\n        id\n        teamType\n        firstName\n        lastName\n        department\n        position\n        authorizationRight\n        maximumApprovalLimit\n        dateOfPosition\n        onlineStatus\n        image\n        employmentStatus\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '161e9f3999a1be223b69fc1292729f99';

module.exports = node;
