import React, { useEffect } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery} from "react-relay";
import environment from "../../env/environment";
import '../styles/termOfuser.scss'
import { Translation } from 'react-i18next';
import api from '../../contact/services';

const query = graphql`
    query checkSiteAdminSvmQuery ($username: String, $password: String, $role: String) {
    auth (username: $username, password: $password, role: $role) {
        token
        permission
        result
        haveVat
        url
        projectName
        tenant
        notification
        acceptPolicy
        acceptTermOfUse
        acceptMarketing
        acceptPartner
        message
        messageTh
    }
    allExtraMenu
}
`;


const CheckSiteAdminSvm = (props) => {
    // const [langauge, setLangauge] = useState(localStorage.getItem("language") ?? 'th')
    // const [loading, setLoading] = useState(true)


    useEffect( () => {
        getProjectList()
    }, [])

    const getProjectList = () => {

        let user = props.match.params.user

        // window.localStorage.setItem("token", props.match.params.token)
        fetchQuery(environment, query, {
            "username": user,
            "password": "w,j[vdot8iy[",
            "role": 'admin',
        }).then(async (data) => {
            if (data.auth.result) {
                let res = await api.getSiteId();
                localStorage.setItem("token", data.auth.token)
                localStorage.setItem('site_id', res.site_id);
                localStorage.setItem("permission_list", data.auth.permission);
                localStorage.setItem("allExtraMenu", data.allExtraMenu)
                localStorage.setItem('checkStatusPolicyAndTerms', data.auth.acceptPolicy)
                localStorage.setItem('project_have_vat', data.auth.haveVat);
                window.location.href = '/setting/lawyer-summary-report/all'
            }
        })
    } 

    // const changeLangauge = (lang) => {
    //     setLangauge(lang == false ? 'en' : 'th')
    // }

    return (
        <Translation>
            {t =>
                <div className="container-fluid box colorUse" key="SelectProject">

                </div>
            }
        </Translation >
    )
}
export default CheckSiteAdminSvm