import React, {Component} from 'react';
import { Translation } from "react-i18next";
import format from 'date-fns/format';
import numberWithComma from "../../libs/numberWithComma";
import { Link } from 'react-router-dom';
class SettingDocumentPaidLawyerDetailTable extends Component {

    render() {
        return (
            <Translation>
                { t =>
                    <React.Fragment>
                        <div className="table-responsive fade-up">
                            <table className="table table-hover mt-2">
                                <thead className="thead-light">
                                    <tr>
                                        <th>{t('collectionLawFirm:No.')}</th>
                                        <th>{t('collectionLawFirm:Document Date')}</th>
                                        <th>{t('collectionLawFirm:Unit No.')}</th>
                                        <th className="text-left" style={{width:'150px'}}>{t('collectionLawFirm:Name')}</th>
                                        <th className="text-right">{t('collectionLawFirm:Total Due (Baht)')}</th>
                                        <th className="text-right">{t('collectionLawFirm:Service charge (Baht)')}</th>
                                        <th className="text-right">{t('collectionLawFirm:Creator')}</th>
                                        <th className="text-right">{t('collectionLawFirm:View Document')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.dataCollection.receiveCollectionLawFirmRecord.edges.map((receiveCollection, index) =>{
                                            return(
                                            <tr key={index}>
                                                <td>
                                                    <Link to={`/accounting/collection-law-firm/detail/${receiveCollection.node.collectionLawFirm.id}`}>
                                                        {receiveCollection.node.collectionLawFirm.docNumber || '-'}
                                                    </Link>
                                                </td>
                                                <td className=''>{format(this.props.dataCollection.issuedDate, 'YYYY-MM-DD') || '-'}</td>
                                                <td className=''>{receiveCollection.node.collectionLawFirm.contact.name || '-'}</td>
                                                <td className="text-left" style={{width:'150px'}}>{receiveCollection.node.collectionLawFirm.contact.firstName + " " + receiveCollection.node.collectionLawFirm.contact.lastName  || '-'}</td>
                                                <td className="text-right">{numberWithComma(receiveCollection.node.collectionLawFirm.totalDebt) || '-'}</td>
                                                <td className="text-right">{numberWithComma(receiveCollection.node.collectionLawFirm.totalFine) || '-'}</td>
                                                <td className="text-right">{receiveCollection.node.creator}</td>
                                                <td className="text-right">
                                                <a href={`/accounting/collection-law-firm/printDocument/${receiveCollection.node.collectionLawFirm.id}`} target='_blank' >
                                                    <span className="cursor mr-2 file-text"> {receiveCollection.node.collectionLawFirm.docNumber && receiveCollection.node.collectionLawFirm.docNumber.replace("CLF", "FM-CM")}</span>
                                                </a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default SettingDocumentPaidLawyerDetailTable;