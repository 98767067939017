import React from 'react'
import { Component } from 'react';
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import SelectResident from '../../unitStatus/transferOfOwnershipCreate/selectResident';
import "../../developer.scss";
import revenueAPI from '../revenueContractAPI';

class RevenueContractSelect extends Component {

    constructor(props){
        super(props);
        this.state ={
            residentialList : [],
            loading : false,
            search : '',
            token : localStorage.getItem("token")
        }
        this.updateContactList = this.updateContactList.bind(this)
        this.fetchQuery = this.fetchQuery.bind(this);
    }

    updateContactList(contactList) {
        this.props.updateContactList(contactList)
    }
    componentDidMount(){
        this.fetchQuery('')
    }

    fetchQuery(search){
        this.setState({search:search})
         this.setState({loading:true})
        revenueAPI.getObligationRoom(this.state.token,this.state.search,'not_obligation')
        .then((response) => {
            if(response.data.residential_list !== this.state.residentialList){
                this.setState({
                    residentialList : response.data.residential_list
                },() => this.setState({loading:false}))
            }
        })
    }

    render(){

        return(
            <Translation>
                {t =>
                    <div className="container-fluid box" id="developer-form">
                        <div className="row">
                            <div className="col">
                                <div className="mb-4">
                                    
                                    <Translation>
                                        {
                                            t => 
                                                <h4>
                                                    <Link to="/developer/revenue-contract/list/all">
                                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                alt="back" className="back"/>
                                                    </Link>
                                                    {t('developer:Bind revenue contract')}
                                                </h4>
                                        }
                                    </Translation>
                                </div>
                            </div>
                        </div>    
                        <div className="content-inner">
                            <div className="mt-1">
                                <SelectResident 
                                    updateContactList={this.updateContactList}
                                    fetchQuery = {this.fetchQuery}
                                    nextLink="/developer/revenue-contract/create/form"
                                    residentialList = {this.state.residentialList}
                                    search = {this.state.search}
                                    loading = {this.state.loading}
                                    />
                               
                            </div>
                        </div>
                    </div>
                }
            </Translation>
            
        )
    }


}
export default RevenueContractSelect;