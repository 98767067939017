import React from "react";
import SearchSelect from "../../libs/searchSelect";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import ReceiveCheque from "./receiveCheque";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import Swal from "sweetalert2";
import "./receiveForm.scss"
import { Translation } from "react-i18next";
import SelectPaymentType from "../../libs/selectPaymentType";
import SelectPaymentMethod from "../../libs/selectPaymentMethod";
import i18n from "i18next";
import i18next from "i18next";

const _ = require('lodash');
const $ = window.jQuery;

const query = graphql`
    query receiveFormQuery($makePayment: Boolean, $receivePayment: Boolean) {
        bankAccountViewer {
            allBankAccount(status: true, accountType_In:"current_account, saving_account, saving_deposit, fixed_deposit", makePayment:$makePayment, receivePayment:$receivePayment) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        status
                    }
                }
                totalCount
            }
        }
        viewer {
            allChartOfAccount(chartOfAccountCode_Istartswith: "2210") {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
        otherExpense : viewer {
            allChartOfAccount(chartOfAccountCode_Istartswith: "5830") {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
        unknownReceiveViewer{
            allUnknownReceive(status:"wait"){
                edges{
                    node{
                        id
                        docNumber
                        description
                        amount
                    }
                }
            }
        }
        paymentGatewaySettingList{
            edges{
              node{
                id
                paymentType
                fee
                typeCal
                bankAccount{
                    id
                    docNumber
                    accountNumber
                    accountName
                    accountType
                    receivePayment
                    makePayment
                    bankName
                    status
                }
              }
            }
        }
    }
`;


const receive_channel = {
    slug: "",
    // pay: "",
    pay: 0,
    image: false,
    image_slip:"", 
    cheque: {},
    payment_gateway: {},
    ref: "",
    unknown_ref: "",
    date: new Date(),
    type_other_pay: false,
};

const payment_gateway_channel = {
    payment_type: "",
    payment_gateway: "",
    description: "",
    amount: 0,
    fee_amount: 0,
};

const cash_channel_choice = [
    {
        node: {
            id: '1',
            slug: "cash",
            accountName: 'เงินสด',
        }
    },
];

const cheque_channel_choice = [
    {
        node: {
            id: '2',
            slug: "cheque",
            accountName: 'เช็ค',
        }
    },

];

const credit_card_channel_choice = [
    {
        node: {
            id: '7',
            slug: "credit-card",
            accountName: 'บัตรเครดิต',
        }
    },

];

const payment_gateway_channel_choice = [
    {
        node: {
            id: '8',
            slug: "payment-gateway",
            accountName: 'เพย์เมนท์เกตเวย์',
        }
    },

];

const unknown_receive_channel_choice = [

    {
        node: {
            id: '7',
            slug: "unknown-receive",
            accountName: 'เงินรับรอการตรวจสอบ',
        }
    }

];

const payment_channel_fee = [
    {
        node: {
            id: '4',
            slug: "fee",
            accountName: 'ค่าธรรมเนียมธนาคาร',
        }
    },
    {
        node: {
            id: '3',
            slug: "small-change",
            accountName: 'รายได้จากส่วนต่างเศษสตางค์',
        }
    },
    {
        node: {
            id: '6',
            slug: "receipt-deposit",
            accountName: 'รับเงินล่วงหน้า',
        }
    },
    {
        node: {
            id: '7',
            slug: "other-expense",
            accountName: 'ค่าใช้จ่ายอื่นๆ',
        }
    },

];

const other_chanel = [
    {
        node: {
            id: '4',
            slug: "fee",
            accountName: 'ค่าธรรมเนียมธนาคาร',
        }
    },
    {
        node: {
            id: '3',
            slug: "small-change",
            accountName: 'รายได้จากส่วนต่างเศษสตางค์',
        }
    },
    {
        node: {
            id: '7',
            slug: "unknown-receive",
            accountName: 'เงินรับรอการตรวจสอบ',
        }
    },
    {
        node: {
            id: '6',
            slug: "receipt-deposit",
            accountName: 'รับเงินล่วงหน้า',
        }
    },

];

const fee_and_small_change_chanel = [
    {
        node: {
            id: '4',
            slug: "fee",
            accountName: 'ค่าธรรมเนียมธนาคาร',
        }
    },
    {
        node: {
            id: '3',
            slug: "small-change",
            accountName: 'รายได้จากส่วนต่างเศษสตางค์',
        }
    },
];

class ReceiveForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            receive_channel: [],
            channel_choice: [],
            receipt_deposit: [],
            other_expense: [],
            unknown_receive: [],
            channel_other_choice: [],
            payment_gateway_channel: [],
            bank_list:[],
        };
        this.state.single_payment = props.single_payment ? props.single_payment : false;
        this.state.receivePayment = props.payment_type === 'receive' ? true : null;
        this.state.makePayment = props.payment_type === 'receive' ? null : true;
        this.state.headerText = props.payment_type === 'receive' ? 'Payment details' : 'รายละเอียดการจ่ายเงิน';
        this.addReceiveChannel = this.addReceiveChannel.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.deleteChannel = this.deleteChannel.bind(this);
        this.queryBank = this.queryBank.bind(this);
        this.changeDateFromIssuedDate = this.changeDateFromIssuedDate.bind(this);
        this.onAddOtherChannel = this.onAddOtherChannel.bind(this);

        this.handleModalShowClick = this.handleModalShowClick.bind(this);
        this.handleModalCloseClick = this.handleModalCloseClick.bind(this);
        this.calDiffPay = this.calDiffPay.bind(this);
    }

    componentWillMount() {
        this.queryBank()
    }

    queryBank() {
        if (this.props.page === "clear_advance") {
            this.setState({ headerText: "รายละเอียดการรับเงินคืน" })
        }
        fetchQuery(environment, query, { makePayment: this.state.makePayment, receivePayment: this.state.receivePayment })
            .then(response_data => {
                let bank_list = [];
                if (_.isObject(response_data.bankAccountViewer)) {
                    let query_bank_data = _.cloneDeep(response_data);
                    query_bank_data.bankAccountViewer.allBankAccount.edges.forEach((bank) => {
                        _.set(bank, 'node.slug', 'bank:' + bank.node.id);
                        _.set(bank, 'node.type', 'bank');
                        let account_type = 'ออมทรัพย์';
                        if (bank.node.accountType === 'CURRENT_ACCOUNT') {
                            account_type = 'กระแสรายวัน'
                        } else if (bank.node.accountType === 'FIXED_DEPOSIT') {
                            account_type = 'ฝากประจำ'
                        }
                        _.set(bank, 'node.account_type', account_type);
                    });
                    bank_list = query_bank_data.bankAccountViewer.allBankAccount.edges;
                }

                let update_channel_choice = [];
                let channel_other_choice = [];


                if (this.props.not_other_channel) {
                    update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice];
                    if (this.props.credit_card_channel) {
                        // update_channel_choice = [...update_channel_choice, ...credit_card_channel_choice]
                        update_channel_choice = [...update_channel_choice]
                    }
                } else if (this.props.not_other_and_cheque_channel) {
                    update_channel_choice = [...cash_channel_choice, ...bank_list];
                } else if (this.props.typeSupplier) {
                    // กรณีเป็นลูกหนี้ให้เอา รับล่วงหน้า กับ รับเงินรอการตรวจสอบ ออก เรียกจาก componentDidUpdate
                    // update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice, ...credit_card_channel_choice, ...fee_and_small_change_chanel]
                    update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice, ...fee_and_small_change_chanel]
                } else {
                    // update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice, ...credit_card_channel_choice, ...other_chanel]
                    update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice, ...other_chanel]
                }

                // -------------------------------------------------------- //
                if (this.props.receive_form) {
                    // update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice, ...credit_card_channel_choice, ...payment_gateway_channel_choice, ...unknown_receive_channel_choice]
                    if(this.props.page === "receive"){
                        // update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice, ...payment_gateway_channel_choice, ...unknown_receive_channel_choice]
                        update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice, ...unknown_receive_channel_choice]
                        if(_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on){
                            update_channel_choice = [...update_channel_choice, ...payment_gateway_channel_choice]
                        }
                    }
                    else{
                        update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice, ...unknown_receive_channel_choice]
                    }
                    channel_other_choice = [...payment_channel_fee]
                }

                if(_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on){
                    update_channel_choice = [...update_channel_choice, ...credit_card_channel_choice]
                }

                let receive_channel_object = { ...receive_channel };
                receive_channel_object.pay = this.props.total || "";
                // if(this.props.min_date > new Date()){
                //     receive_channel_object.date = this.props.min_date
                // }
                this.setState(
                    {
                        receive_channel: [receive_channel_object],
                        payment_gateway_channel: [{ ...payment_gateway_channel }],
                        channel_choice: update_channel_choice,
                        channel_other_choice: channel_other_choice,
                        receipt_deposit: response_data.viewer.allChartOfAccount.edges,
                        other_expense: response_data.otherExpense.allChartOfAccount.edges,
                        unknown_receive: response_data.unknownReceiveViewer.allUnknownReceive.edges,
                        payment_gateway_channel_list: response_data.paymentGatewaySettingList.edges,
                        bank_list:bank_list
                    })
            });


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.payment_type !== this.props.payment_type) {
            if (this.props.payment_type === 'receive') {
                this.setState({ receivePayment: true, makePayment: null, headerText: i18next.t("TransactionChannel:Details of Receipt") })
            } else {
                this.setState({ receivePayment: null, makePayment: true, headerText: i18next.t("TransactionChannel:Details of Payment") })
            }

            this.queryBank()
        }

        // กรณีเป็นลูกหนี้ให้เอา รับล่วงหน้า กับ รับเงินรอการตรวจสอบ ออก
        if (prevProps.typeSupplier !== this.props.typeSupplier) {
            this.queryBank()
        }
        if (prevProps.issued_date !== this.props.issued_date) {
            this.changeDateFromIssuedDate('date')
        }
        if (prevProps.total !== this.props.total) {
            this.changeDateFromIssuedDate('pay')
        }
    }

    changeDateFromIssuedDate(name) {
        let channel_list = _.cloneDeep(this.state.receive_channel);
        let value = '';
        if (name === 'date') {
            if (this.props.page === "clear_advance" && channel_list.length > 0) {
                value = channel_list[0].date || this.props.issued_date
            } else {
                value = this.props.issued_date
            }
        } else {
            value = parseFloat(this.props.total)
        }
        channel_list.forEach((channel, index) => {
            if ((name === 'pay' && index === 0) || name === 'date') {
                channel[name] = value;
            } else {
                if (name === 'pay') {
                    channel[name] = 0;
                }
            }
            if (channel['slug'] !== "" && this.props.page === "other_receiver") {
                channel['slug'] = ""
            }

        });
        this.setState({ receive_channel: channel_list });
        if (name === 'pay') {
            this.props.updateReceiveChannel(channel_list);
        }
    }

    addReceiveChannel() {
        let channel = _.cloneDeep(this.state.receive_channel);
        let channel_object = { ...receive_channel };
        channel_object.date = this.props.issued_date;
        let value = this.props.total - this.props.pay;
        if (value !== 0) {
            channel_object.pay = value;
        }
        channel.push(channel_object);
        this.setState({ receive_channel: channel });
    }

    deleteChannel(index) {
        let channel = _.cloneDeep(this.state.receive_channel);
        channel.splice(index, 1);
        if (channel.length > 0) {
            this.setState({ receive_channel: channel }, () => {
                this.props.updateReceiveChannel(this.state.receive_channel);
            });
        }
    }

    handleInputChange(e) {
        let event = null;
        try {
            event = _.cloneDeep(e);
        } catch (error) {
            event = _.clone(e);
        }
        let value = event.target.value;
        let state_group = event.target.name.split('.')[0];
        let state_key = event.target.name.split('.')[1];
        if (event.target.type === 'file') {
            if (event.currentTarget.files.length === 0) {
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        event.target.name,
                        false
                    );
                }, () => {
                    this.props.updateReceiveChannel(this.state.receive_channel);
                });

            } else if (event.currentTarget.files.length > 0 && event.currentTarget.files[0].type.split("/")[0] === 'image') {
                value = e.currentTarget.files[0];
               
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        event.target.name,
                        value
                    );
                }, () => {
                    this.props.updateReceiveChannel(this.state.receive_channel);
                });
            } 
            else {
                Swal.fire(i18n.t('createReceive:Invalid file type!'), i18n.t('createReceive:Please check the validity of this file again.'), 'warning');
            }

        } else {
            if (state_key === 'unknown_ref' || (state_key === 'slug' && value === 'unknown-receive')) {
                let requiredKeys = [event.target.name, state_group + '.pay'];
                let value_set = [value];

                if (state_key === 'slug') {
                    requiredKeys = [...requiredKeys, state_group + '.unknown_ref'];
                    value_set = [...value_set, '', ''];

                } else {
                    this.state.unknown_receive.forEach((unknown_receive) => {
                        if (value === unknown_receive.node.id) {
                            value_set = [...value_set, unknown_receive.node.amount]
                        }
                    });
                }

                this.setState(prevState => {
                    let cloneDeep = _.cloneDeep(prevState);
                    return _.set(cloneDeep, '', requiredKeys.forEach(function (requiredKeys, index) {
                        return _.set(cloneDeep, requiredKeys, value_set[index]);
                    }));
                }, () => {
                    this.props.updateReceiveChannel(this.state.receive_channel)
                });
                return;

            }

            if(this.props.page === "receive"){
                //สร้าง Transaction Payment_gateway
                if (state_key === 'payment_gateway') {
                    //2c2p
                    if ((value === 'credit-card' || value === 'debit-card' || value === 'true-wallet' || value === 'alipay')) {
                        let index_sub = state_group.substring(state_group.length - 2)
                        index_sub = index_sub.substring(0)
                        index_sub = parseInt(index_sub)
                        
                        if (this.state.receive_channel[index_sub].payment_gateway.paymentType === "2C2P") {

                            let var_check = this.state.receive_channel[index_sub].payment_gateway.paymentType === "2C2P" ? "TWOCTWOP" : this.state.payment_gateway_channel_list
                            const payment_gateway_data_cal = _.find(this.state.payment_gateway_channel_list, { node: { paymentType: var_check } })
                            //check ความถูกต้อง
                            if (!payment_gateway_data_cal.node.bankAccount) {
                                Swal.fire(i18n.t('paymentGateway:Error'), i18n.t('paymentGateway:Invalid Bank Please set payment gateway.'), 'warning');
                                return;
                            }

                            let fee_amount = 0
                            if (payment_gateway_data_cal.node.typeCal === 'PERCENT') {
                                fee_amount = Math.round(((this.props.total * payment_gateway_data_cal.node.fee / 100) + Number.EPSILON) * 100) / 100;
                            } else {
                                fee_amount = payment_gateway_data_cal.node.fee
                            }
                            const spayment_gateway_channel = {
                                payment_type: this.state.receive_channel[index_sub].payment_gateway.paymentType,
                                payment_gateway: value,
                                description: i18n.t('paymentGateway:Payment Gateway Pay'),
                                amount: this.props.total,
                                fee: payment_gateway_data_cal.node.fee,
                                fee_amount: fee_amount,
                                type_cal: payment_gateway_data_cal.node.typeCal
                            };
                            this.setState({ payment_gateway_channel: [{ ...spayment_gateway_channel }] }, () => {
                                this.props.updatePaymentGateway(this.state.payment_gateway_channel);
                            })

                        }
                    }//other
                    else if (value === 'DEEPLINK' || value === 'CROSSBILL' || value === 'SLIP_VERIFICATION') {

                        const payment_gateway_data_cal = _.find(this.state.payment_gateway_channel_list, { node: { paymentType: value } })
                        //check ความถูกต้อง
                        if (value !== 'SLIP_VERIFICATION' && !payment_gateway_data_cal.node.bankAccount) {
                            Swal.fire(i18n.t('paymentGateway:Error'), i18n.t('paymentGateway:Invalid Bank Please set payment gateway.'), 'warning');
                            return;
                        }

                        let fee_amount = 0
                        if (payment_gateway_data_cal.node.typeCal === 'PERCENT') {
                            fee_amount = Math.round(((this.props.total * payment_gateway_data_cal.node.fee / 100) + Number.EPSILON) * 100) / 100;
                        } else {
                            fee_amount = payment_gateway_data_cal.node.fee
                        }
                        const spayment_gateway_channel = {
                            payment_type: value,
                            payment_gateway: '-',
                            description: i18n.t('paymentGateway:Payment Gateway Pay'),
                            amount: this.props.total,
                            fee: payment_gateway_data_cal.node.fee,
                            fee_amount: fee_amount,
                            type_cal: payment_gateway_data_cal.node.typeCal
                        };
                        this.setState({ payment_gateway_channel: [{ ...spayment_gateway_channel }] }, () => {
                            this.props.updatePaymentGateway(this.state.payment_gateway_channel);
                        })

                    }
                } else {
                    //remove payment_gateway
                    this.props.deletePaymentGateway();
                    
                }
            }

            this.setState(prevState => {
                if (state_key === 'pay') {
                    value = parseFloat(value)
                }
                return _.set(
                    _.cloneDeep(prevState),
                    event.target.name,
                    value
                );
            }, () => {
                this.props.updateReceiveChannel(this.state.receive_channel);
            });
        }

    }

    onAddOtherChannel(type) {
        let amount_pay = this.calDiffPay('value');
        let channel = _.cloneDeep(this.state.receive_channel);
        let channel_object = { ...receive_channel };
        channel_object.date = this.props.issued_date;
        channel_object.slug = type;
        channel_object.pay = amount_pay;
        channel_object.type_other_pay = true;
        channel.push(channel_object);
        this.setState({ receive_channel: channel }, () => {
            this.props.updateReceiveChannel(this.state.receive_channel);
            this.handleModalCloseClick()
        });
    }

    handleModalShowClick() {
        $(this.modal).modal('show');
    }

    handleModalCloseClick() {
        $(this.modal).modal('hide');
    }

    calDiffPay(type) {
        let value = this.props.pay - this.props.total;
        if (type === 'value') {
            return Math.abs(value).toFixed(2)
        } else if (value > 0) {
            return true
        } else {
            return false
        }
        // return this.props.pay - this.props.total;
    }

    returnAbsoluteValue(value) {
        let return_value = value ? Math.abs(_.cloneDeep(value)) : ' ';
        if (!(return_value || return_value === 0)) {
            return_value = "";
        }
        return return_value;
    }

    render() {
        let total = Math.round(((this.props.total) + Number.EPSILON) * 100) / 100;
        let pay = Math.round(((this.props.pay) + Number.EPSILON) * 100) / 100;
        
        let payment_gateway_pay = 0;
        if(this.props.payment_gateway_pa){
            payment_gateway_pay = parseFloat(this.props.payment_gateway_pay)
        };

        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="row mt-4 mb-1 fade-up">
                            <div className="col">
                                <h5>{t('createReceive:' + this.state.headerText)}</h5>
                            </div>
                        </div>

                        {this.state.receive_channel.map((payment_channel, index) =>
                            <div className="card fade-up mb-3" key={index}>
                                <div className="card-body">


                                    <div className="row mt-2">

                                        <div className="col-12">
                                            <h6>
                                                {
                                                    !this.props.single_payment && <span>{t('createReceive:Method')} {index + 1}</span>
                                                }

                                                {index !== 0 &&
                                                    <button className="btn btn-danger btn-sm ml-1" type="button"
                                                        onClick={() => this.deleteChannel(index)}>
                                                        {t('createReceive:Delete')}
                                                    </button>
                                                }
                                            </h6>
                                        </div>

                                        <div className="col-4">
                                            <label>{t('createReceive:Received by')}</label>
                                            <SearchSelect onChange={this.handleInputChange}
                                                value={this.state.receive_channel[index].slug}
                                                name={"receive_channel[" + index + "].slug"} placeholder="รายการ"
                                                queryObject={payment_channel.type_other_pay ? this.state.channel_other_choice : this.state.channel_choice}
                                                disabled={payment_channel.type_other_pay ? "disabled" : false}
                                                keyOfValue="slug" require={true}
                                                keyOfLabel="bankName:account_type:accountName:accountNumber"

                                            />
                                        </div>
                                        <div className="col-2">
                                            <label>{t('createReceive:Amount')}</label>
                                            <input type="number" className="form-control text-right" style={{ opacity: 1 }}
                                                onChange={this.handleInputChange}
                                                step=".01"
                                                value={this.returnAbsoluteValue(this.state.receive_channel[index].pay ? parseFloat(parseFloat(this.state.receive_channel[index].pay + payment_gateway_pay).toFixed(2)) : '')}
                                                name={"receive_channel[" + index + "].pay"}
                                                required={true}
                                                disabled={this.state.receive_channel[index].slug === 'unknown-receive' || this.state.receive_channel[index].slug === 'payment-gateway' || this.props.page === 'clear_advance' ? "disabled" : false}
                                            />
                                        </div>
                                        {_.startsWith(this.state.receive_channel[index].slug, "bank") &&
                                            <div className="col-1 mr-4">
                                                <label>{t('createReceive:date')}</label><br />
                                                <DatePickerAdapter
                                                    className="form-control" name={"receive_channel[" + index + "].date"}
                                                    selected={this.state.receive_channel[index].date}
                                                    onChange={this.handleInputChange}
                                                    required={true}
                                                    data-key="payment_list"
                                                    minDate={this.props.min_date && this.props.min_date}
                                                />

                                            </div>
                                        }

                                        { (_.startsWith(this.state.receive_channel[index].slug, "bank") || _.startsWith(this.state.receive_channel[index].slug, "payment-gateway") ) &&
                                            <div className="col-3">
                                                <label>{t('createReceive:Receipt')}</label> <br />
                                                <label className={
                                                    this.state.receive_channel[index].image ?
                                                        "btn btn-success cursor mr-1" : "btn btn-secondary cursor"
                                                }>
                                                    {this.state.receive_channel[index].image ? t('createReceive:Done') : t('createReceive:Upload receipt')}
                                                    <input type="file" name={"receive_channel[" + index + "].image"}
                                                        data-key="payment_list"
                                                        onChange={this.handleInputChange}
                                                        accept="image/png, image/jpeg"
                                                        hidden />
                                                </label>
                                                {(payment_channel.image.name && payment_channel.image.name.length >= 30) ?
                                                    payment_channel.image.name.substring(0, 30) + '...' : payment_channel.image.name
                                                }
                                            </div>
                                        }
                                        { (_.startsWith(this.state.receive_channel[index].slug, "unknown-receive")) &&
                                            <div className="col-3">
                                                <label>{t('createReceive:Receipt')}</label> <br />
                                                <label className={
                                                    this.state.receive_channel[index].image_slip ?
                                                        "btn btn-success cursor mr-1" : "btn btn-secondary cursor"
                                                }>
                                                    {this.state.receive_channel[index].image_slip ? t('createReceive:Done') : t('createReceive:Upload receipt')}
                                                    <input type="file" name={"receive_channel[" + index + "].image_slip"}
                                                        data-key="payment_list"
                                                        onChange={this.handleInputChange}
                                                        accept="image/png, image/jpeg"
                                                        hidden />
                                                </label>
                                                {(this.state.receive_channel[index].image_slip.name && this.state.receive_channel[index].image_slip.name.length >= 30) ?
                                                    this.state.receive_channel[index].image_slip.name.substring(0, 30) + '...' : this.state.receive_channel[index].image_slip.name
                                                }
                                            </div>
                                        }

                                        {
                                            this.state.receive_channel[index].slug === 'receipt-deposit' &&
                                            <div className="col-4 clearfix">
                                                <label>{t('createReceive:Select the Receipt deposit')}</label> <br />
                                                <SearchSelect onChange={this.handleInputChange}
                                                    value={this.state.receive_channel[index].ref}
                                                    name={"receive_channel[" + index + "].ref"} placeholder="รายการ"
                                                    queryObject={this.state.receipt_deposit}
                                                    keyOfValue="id" require={true}
                                                    keyOfLabel="chartOfAccountCode:name"
                                                />
                                            </div>
                                        }
                                        {
                                            this.state.receive_channel[index].slug === 'other-expense' &&
                                            <div className="col-4 clearfix">
                                                <label>{t('createReceive:Select other expenses')}</label> <br />
                                                <SearchSelect onChange={this.handleInputChange}
                                                    value={this.state.receive_channel[index].ref}
                                                    name={"receive_channel[" + index + "].ref"} placeholder="รายการ"
                                                    queryObject={this.state.other_expense}
                                                    keyOfValue="id" require={true}
                                                    keyOfLabel="chartOfAccountCode:name"
                                                />
                                            </div>
                                        }
                                        {
                                            this.state.receive_channel[index].slug === 'unknown-receive' &&
                                            <div className="col-4 clearfix">
                                                <label>{t('createReceive:Cash/receipt pending review')}</label> <br />
                                                <SearchSelect onChange={this.handleInputChange}
                                                    value={this.state.receive_channel[index].unknown_ref}
                                                    name={"receive_channel[" + index + "].unknown_ref"}
                                                    placeholder="รายการ"
                                                    queryObject={this.state.unknown_receive}
                                                    keyOfValue="id" require={true}
                                                    keyOfLabel="docNumber:description"
                                                />
                                            </div>
                                        }

                                    </div>
                                    {
                                        this.state.receive_channel[index].slug === 'cheque' &&
                                        <div className="row">
                                            <div className="col-8 clearfix">
                                                <ReceiveCheque
                                                    index={index}
                                                    receive_channel={this.state.receive_channel}
                                                    handleInputChange={this.handleInputChange}
                                                    page={this.props.page}
                                                />
                                            </div>
                                        </div>

                                    }

                                    {
                                        this.state.receive_channel[index].slug === 'payment-gateway' &&
                                        <div className="row">
                                            <div className="col-8 clearfix">
                                                <Translation>
                                                    {t =>
                                                        <div className="mt-4 border p-4">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h6>{t('paymentGateway:payment_gateway_datail')}</h6>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-4">
                                                                <SelectPaymentType
                                                                    name={"receive_channel[" + index + "].payment_gateway.paymentType"}
                                                                    value={this.state.receive_channel[index].payment_gateway.paymentType}
                                                                    handleInputChange={this.handleInputChange}
                                                                />

                                                                {this.state.receive_channel[index].payment_gateway.paymentType === "2C2P" &&
                                                                    <SelectPaymentMethod
                                                                        name={"receive_channel[" + index + "].payment_gateway.paymentMethod"}
                                                                        value={this.state.receive_channel[index].payment_gateway.paymentMethod}
                                                                        handleInputChange={this.handleInputChange}
                                                                        paymentType={this.state.receive_channel[index].payment_gateway.paymentType}
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-4">
                                                                    <p>{t('paymentGateway:date')}</p>
                                                                    <DatePickerAdapter id="IssuedDate"
                                                                        className="form-control"
                                                                        name={"receive_channel[" + index + "].payment_gateway.IssuedDate"}
                                                                        data-key="cheque"
                                                                        selected={this.state.receive_channel[index].payment_gateway.IssuedDate}
                                                                        onChange={this.handleInputChange}
                                                                        required={true} />
                                                                </div>
                                                                {this.state.receive_channel[index].payment_gateway.paymentType === "SLIP_VERIFICATION" &&
                                                                    <div className="col-4">
                                                                        <p>{t('paymentGateway:bank')}</p>
                                                                        <SearchSelect onChange={this.handleInputChange}
                                                                                value={this.state.receive_channel[index].payment_gateway.bankid}
                                                                                name={"receive_channel[" + index + "].payment_gateway.bankid"} placeholder="รายการ"
                                                                                queryObject={this.state.bank_list}
                                                                                keyOfValue="id"
                                                                                data-id={index}
                                                                                data-key="paymentGatewaySettingList"
                                                                                keyOfLabel="bankName:account_type:accountName:accountNumber" />
                                                                    
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </Translation>

                                            </div>
                                        </div>

                                    }

                                    {this.props.receive_form && (this.state.receive_channel.length === (index + 1)) && this.props.total !== 0 && this.props.pay !== 0 && (total !== pay) &&
                                        <div className="row mt-3">
                                            <div className="col">
                                                <button type="button" className="btn btn-danger"
                                                    onClick={this.handleModalShowClick}>
                                                    {t('createReceive:Excess/Lack')}
                                                </button>
                                                <span
                                                    className="text-red ml-3">{this.calDiffPay() ? t('createReceive:There is a excess') : t('createReceive:There is a lack')} {this.calDiffPay('value')} {t('createReceive:Baht')}</span>
                                                <span className="text-red ml-2">{t('createReceive:(The difference is adjusted to income from the penny difference, bank fees, or received in advance)')}</span>
                                            </div>
                                        </div>
                                    }


                                </div>
                            </div>
                        )}

                        {/* เพย์เมนท์เกตเวย์ ไม่สามารถชำระหลายช่องทางได้เพราะต้องนำไปคิดค่าธรรมเนียมต่อใบ */}
                        {!this.props.single_payment && this.state.receive_channel[0]?.slug !== 'payment-gateway' &&
                            <div className="row mt-3 rowAdd">
                                <div className="col">
                                    <button type="button" className="btn btn-success add"
                                        onClick={this.addReceiveChannel}>{t('createReceive:Add payment method')}
                                    </button>
                                </div>
                            </div>
                        }
                        {
                         this.props?.carReportDocList && this.props?.carReportDocList.length >= 1 ? 
                          <div className="pt-5">
                               <div className="row">
                                    <div className="col-1">
                                       <div style={{width:'300px'}}>
                                        <h5>เอกสารอ้างอิง: </h5> 
                                       </div>
                                    </div>
                                <div className="col-5" style={{padding:'0',marginLeft:'24px'}}>
                                    {
                                      this.props?.carReportDocList && this.props?.carReportDocList.map((val, index) => {
                                            return <span className="pr-2">{val}</span>
                                        })
                                    }
                                    </div>
                             </div>
                              <p className="">จำนวนเอกสารที่อ้างอิงทั้งหมด: {this.props?.carReportDocList?.length} รายการ</p>
                              <hr/>
                          </div>
                            : ''
                        }
                        <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog"
                            ref={modal => this.modal = modal}
                            aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">{t('createReceive:Excess/Lack note options')}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row mt-2">
                                            <div className="col pl-5 pr-5">
                                                {t('createReceive:Select the difference reconciliation list.')}
                                            </div>
                                        </div>
                                        <div className="row mt-2 mb-5">
                                            <div className="col pl-5 pr-5">
                                                {
                                                    <button type="button" className="btn btn-light buttonMenu buttonBorder"
                                                        onClick={() => this.onAddOtherChannel('small-change')}>
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/money.png'}
                                                                    alt="hand" className="center" />
                                                            </div>
                                                            <div className="col text-left textButton">
                                                                <div className="row">
                                                                    <div className="col">{t('createReceive:Income from the penny difference')}</div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col textDescription">
                                                                        {t('createReceive:Caused by receiving - paying from rounding')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                }
                                                {
                                                    this.props.pay - this.props.total < 0 &&
                                                    <button type="button" className="btn btn-light buttonMenu buttonBorder"
                                                        onClick={() => this.onAddOtherChannel('fee')}>
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/hand.png'}
                                                                    alt="hand" className="center" />
                                                            </div>
                                                            <div className="col text-left textButton">
                                                                <div className="row">
                                                                    <div className="col">{t('createReceive:Bank fees')}</div>
                                                                </div>
                                                                <div className="row">
                                                                    <div
                                                                        className="col textDescription">{t('createReceive:Choose the difference as bank fees')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                }
                                                {this.props?.pay - this.props?.total > 0 && !this.props.notReceiptDeposit &&
                                                    <button type="button" className="btn btn-light buttonMenu buttonBorder"
                                                        onClick={() => this.onAddOtherChannel('receipt-deposit')}>
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/unknown.png'}
                                                                    alt="hand" className="center" />
                                                            </div>
                                                            <div className="col text-left textButton">
                                                                <div className="row">
                                                                    <div className="col">{t('createReceive:Receipt deposit')}</div>
                                                                </div>
                                                                <div className="row">
                                                                    <div
                                                                        className="col textDescription">{t('createReceive:Select this difference to receive upfront expense or other processing fees.')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                }

                                                {this.props.pay - this.props.total > 0 &&
                                                    <button type="button" className="btn btn-light buttonMenu buttonBorder"
                                                        onClick={() => this.onAddOtherChannel('other-expense')}>
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/hand.png'}
                                                                    alt="hand" className="center" />
                                                            </div>
                                                            <div className="col text-left textButton">
                                                                <div className="row">
                                                                    <div className="col">{t('createReceive:Other expenses')}</div>
                                                                </div>
                                                                <div className="row">
                                                                    <div
                                                                        className="col textDescription">{t('createReceive:Select this difference as other expenses')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default ReceiveForm;