/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollectionLawFirmStatus = "ACTIVE" | "DRAFT" | "FINISH" | "FOLLOW" | "OVERDUE" | "PAID" | "PARTIAL_PAYMENT" | "VOID" | "WAIT" | "%future added value";
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type collectionLawFrimNumberDocsTableQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
  first?: ?number,
  last?: ?number,
|};
export type collectionLawFrimNumberDocsTableQueryResponse = {|
  +allCollectionLawFirm: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: ?any,
        +added: any,
        +status: CollectionLawFirmStatus,
        +dueDate: any,
        +contact: {|
          +id: string,
          +refNumber: string,
          +name: string,
        |},
        +log: ?number,
        +site: {|
          +id: string,
          +domain: string,
          +project: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +nameTh: ?string,
                +nameEn: ?string,
                +typeOfProject: ?ProjectTypeOfProject,
                +logo: ?string,
                +added: any,
              |}
            |}>
          |},
        |},
      |}
    |}>,
  |}
|};
export type collectionLawFrimNumberDocsTableQuery = {|
  variables: collectionLawFrimNumberDocsTableQueryVariables,
  response: collectionLawFrimNumberDocsTableQueryResponse,
|};
*/


/*
query collectionLawFrimNumberDocsTableQuery(
  $startDate: DateTime
  $endDate: DateTime
  $first: Int
  $last: Int
) {
  allCollectionLawFirm(startDate: $startDate, endDate: $endDate, first: $first, last: $last, log: true) {
    totalCount
    edges {
      node {
        id
        docNumber
        issuedDate
        added
        status
        dueDate
        contact {
          id
          refNumber
          name
        }
        log
        site {
          id
          domain
          project {
            edges {
              node {
                id
                nameTh
                nameEn
                typeOfProject
                logo
                added
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Literal",
        "name": "log",
        "value": true
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      }
    ],
    "concreteType": "CollectionLawFirmNodeConnection",
    "kind": "LinkedField",
    "name": "allCollectionLawFirm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionLawFirmNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionLawFirmNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "issuedDate",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "log",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteType",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectNodeConnection",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "nameTh",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "nameEn",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "typeOfProject",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "logo",
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionLawFrimNumberDocsTableQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "collectionLawFrimNumberDocsTableQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "b469c7e85d35d1e9c22d0f5e2bff840f",
    "id": null,
    "metadata": {},
    "name": "collectionLawFrimNumberDocsTableQuery",
    "operationKind": "query",
    "text": "query collectionLawFrimNumberDocsTableQuery(\n  $startDate: DateTime\n  $endDate: DateTime\n  $first: Int\n  $last: Int\n) {\n  allCollectionLawFirm(startDate: $startDate, endDate: $endDate, first: $first, last: $last, log: true) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        added\n        status\n        dueDate\n        contact {\n          id\n          refNumber\n          name\n        }\n        log\n        site {\n          id\n          domain\n          project {\n            edges {\n              node {\n                id\n                nameTh\n                nameEn\n                typeOfProject\n                logo\n                added\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '462c92b154fa45c7ff30d78b45ae6516';

module.exports = node;
