import React from 'react';
import { Translation } from "react-i18next";
import _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../env/environment";
import Swal from "sweetalert2";
import Loading from '../libs/loading'
import Pagination from '../libs/newPagination';
import { QueryRenderer } from "react-relay";
import ComponentPagination from "../libs/componentPagination";
import { format } from "date-fns";
import i18n from 'i18next';
import EmptyTable from '../libs/emptyTable';

const query = graphql`
    query uploadFileTableQuery(
        $search: String
        $startDate: Date
        $endDate: Date
        $status:String
        $first: Int
        $last: Int
        $typeFile: String
        $menuName: String
    ) {
     allUploadJob(
        search: $search,
        startDate:$startDate,
        endDate:$endDate
        status:$status
        first: $first
        last: $last
        typeFile: $typeFile
        menuName: $menuName
     ){
        edges{
            node{
                issuedDate
                typeFile
                pathFile
                fileName
                fileExtension
                menuName
                sizeFile
                refTransaction
                status
                creator
                timeCreate
            }  
        } 
    }
    }

`;
class UploadFileTable extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            startDate: null,
            endDate: null,
            currentDate: new Date(),
            search: "",
            loading: false
        }
    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        if (this.state.status !== this.props.status) {
            this.setState(
                {
                    status: this.props.status,
                    loading: false,
                }, () => {
                    this.goFirst();
                }
            );
        }
    }

    formatSize(sizeBytes) {
        const kilo = 1024;
        const mega = kilo * 1024;
        const giga = mega * 1024;

        if (sizeBytes < kilo) {
            return sizeBytes + ' B';
        } else if (sizeBytes < mega) {
            return (sizeBytes / kilo).toFixed(2) + ' KB';
        } else if (sizeBytes < giga) {
            return (sizeBytes / mega).toFixed(2) + ' MB';
        } else {
            return (sizeBytes / giga).toFixed(2) + ' GB';
        }
    }
    formatStatus(status) {
        let text = '-'
        if (status === 'ENABLE') {
            text = i18n.t('uploadFileList:Enable')
        } else if (status === 'DISABLE') {
            text = i18n.t('uploadFileList:Disable')
        }
        return text;
    }
    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                cacheConfig={{ use_cache: false }}
                variables={{
                    search: this.props.state.search,
                    startDate: this.props.state.startDate ? format(this.props.state.startDate, "YYYY-MM-DD") : null,
                    endDate: this.props.state.endDate ? format(this.props.state.endDate, "YYYY-MM-DD") : null,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status === 'all' ? null : this.props.status,
                    typeFile: this.props.state.typeFile ? this.props.state.typeFile : null,
                    menuName: this.props.state.menuName ? this.props.state.menuName : null
                }}

                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return (
                                props?.allUploadJob?.edges ?
                                    <React.Fragment>
                                        <Translation>
                                            {t =>
                                            <>
                                            <div className="table-responsive fade-up card">
                                                <table className="table table-hover">
                                                    <thead className='thead-light text-center'>
                                                        <tr>
                                                            <th style={{width:'5%'}}>{t('downloadFile:File name')}</th>
                                                            <th style={{width:'5%'}}>{t('downloadFile:Number Document name')}</th>
                                                            <th style={{width:'5%'}}>{t('downloadFile:File size')}</th>
                                                            <th style={{width:'5%'}}>{t('downloadFile:Report type')}</th>
                                                            <th style={{width:'5%'}}>{t('downloadFile:File type')}</th>
                                                            <th style={{width:'5%'}}>{t('uploadFileList:upload date')}</th>
                                                            <th style={{width:'5%'}}>{t('settingAds:Created Date')}</th>
                                                            <th style={{width:'5%'}}>{t('PageListHistoryResident:Status')}</th>
                                                            <th style={{width:'5%'}}>{t('uploadFileList:upload by')}</th>
                                                            <th />
                                                        </tr>
                                                    </thead>

                                                    <tbody className='text-center useAll colorUse'>
                                                        {props.allUploadJob.edges.map((all_download_job, index) => {   
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{width:'5%'}}>
                                                                                {all_download_job.node.fileName}
                                                                            </td>
                                                                            <td style={{width:'5%'}}>{all_download_job.node.refTransaction}</td>
                                                                            <td style={{width:'5%'}}>{this.formatSize(all_download_job.node.sizeFile)}</td>
                                                                            <td style={{width:'5%'}}>{ all_download_job.node.menuName ?  t(`uploadFileList:${all_download_job.node.menuName}`) : '-'}</td>
                                                                            <td style={{width:'5%'}}>
                                                                                {
                                                                                    all_download_job.node.fileExtension === 'pdf' &&
                                                                                        <>
                                                                                        <img className='imgW24 mt--4' style={{width:'24px'}}
                                                                                            src={process.env.PUBLIC_URL + `/images/typeFiles/${all_download_job.node.fileExtension}Icon.png`}
                                                                                            />  PDF
                                                                                        </>
                                                                                }
                                                                                {
                                                                                    all_download_job.node.fileExtension === 'excel' &&
                                                                                    <>
                                                                                      <img className='imgW24 mt--4' style={{width:'24px'}}
                                                                                        src={process.env.PUBLIC_URL + `/images/typeFiles/xlsIcon.png`} /> 
                                                                                        Excel
                                                                                    </>
                                                                                      
                                                                                 }
                                                                                 {
                                                                                    all_download_job.node.fileExtension === 'jpeg' &&
                                                                                        <>
                                                                                        <img className='imgW24 mt--4' style={{width:'24px'}}
                                                                                            src={process.env.PUBLIC_URL + `/images/typeFiles/jpegIcon.png`} /> 
                                                                                            JPEG
                                                                                        </>
                                                                                       
                                                                                 }
                                                                                  {
                                                                                    all_download_job.node.fileExtension === 'jpg' &&
                                                                                        <>
                                                                                        <img className='imgW24 mt--4' style={{width:'24px'}}
                                                                                            src={process.env.PUBLIC_URL + `/images/typeFiles/jpgIcon.png`} /> 
                                                                                            JPG
                                                                                        </>
                                                                                       
                                                                                 }
                                                                                 {
                                                                                    all_download_job.node.fileExtension === 'png' ||  all_download_job.node.fileExtension === 'apng' &&
                                                                                    <>
                                                                                        <img className='imgW24 mt--4' style={{width:'24px'}}
                                                                                            src={process.env.PUBLIC_URL + `/images/typeFiles/pngIcon.png`} /> 
                                                                                            {all_download_job.node.fileExtension === 'apng' ? "PNG" : "PNG"}
                                                                                    </>
                                                                                       
                                                                                 }
                                                                            
                                                                            </td>
                                                                            <td style={{width:'5%'}}>{format(all_download_job.node.issuedDate, "DD/MM/YYYY")}</td>
                                                                            <td style={{width:'5%'}}>{format(all_download_job.node.timeCreate, "DD/MM/YYYY")}</td>
                                                                            <td style={{width:'5%'}}>{this.formatStatus(all_download_job.node.status)}</td>
                                                                            <td style={{width:'5%'}}>{all_download_job.node.creator ? all_download_job.node.creator : '-'}</td>
                                                                            <td style={{width:'5%'}}>
                                                                                <a href={all_download_job.node.pathFile} target='_blank'>
                                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/download-file-icon.png'}
                                                                                    alt="back" className="download-file-icon" />
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row">
                                                <Pagination
                                                    changePage={this.changePage}
                                                    first={this.state.first}
                                                    last={this.state.last}
                                                    totalCount={props.allUploadJob?.edges.length}
                                                />
                                            </div>
                                             </>
                                            }
                                        </Translation>
                                    </React.Fragment>
                                    :
                                    props?.allUploadJob?.edges.length === 0 ?
                                        <EmptyTable />
                                        :
                                        <Loading />
                        )
                    }
                }
                } />

        );
    }
}

export default UploadFileTable;
