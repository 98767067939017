/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getContactQueryVariables = {|
  id?: ?string
|};
export type getContactQueryResponse = {|
  +contactViewer: ?{|
    +id: string,
    +allContact: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +firstName: string,
          +lastName: string,
          +name: string,
          +ownerName: ?string,
          +mailingAddress: string,
          +mailingDistrict: string,
          +mailingCity: string,
          +mailingProvince: string,
          +mailingPostalCode: string,
          +renterName: ?string,
          +renterAddress: ?string,
          +renterDistrict: string,
          +renterCity: string,
          +renterProvince: string,
          +renterPostalCode: string,
          +agentName: ?string,
          +agentAddress: ?string,
          +agentDistrict: string,
          +agentCity: string,
          +agentProvince: string,
          +agentPostalCode: string,
        |}
      |}>
    |},
  |}
|};
export type getContactQuery = {|
  variables: getContactQueryVariables,
  response: getContactQueryResponse,
|};
*/


/*
query getContactQuery(
  $id: ID
) {
  contactViewer {
    id
    allContact(id: $id) {
      edges {
        node {
          id
          firstName
          lastName
          name
          ownerName
          mailingAddress
          mailingDistrict
          mailingCity
          mailingProvince
          mailingPostalCode
          renterName
          renterAddress
          renterDistrict
          renterCity
          renterProvince
          renterPostalCode
          agentName
          agentAddress
          agentDistrict
          agentCity
          agentProvince
          agentPostalCode
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContactViewer",
    "kind": "LinkedField",
    "name": "contactViewer",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "concreteType": "ContactNodeConnection",
        "kind": "LinkedField",
        "name": "allContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ownerName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentPostalCode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getContactQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getContactQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2473189265bb48ae73d93ce334c5e515",
    "id": null,
    "metadata": {},
    "name": "getContactQuery",
    "operationKind": "query",
    "text": "query getContactQuery(\n  $id: ID\n) {\n  contactViewer {\n    id\n    allContact(id: $id) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          name\n          ownerName\n          mailingAddress\n          mailingDistrict\n          mailingCity\n          mailingProvince\n          mailingPostalCode\n          renterName\n          renterAddress\n          renterDistrict\n          renterCity\n          renterProvince\n          renterPostalCode\n          agentName\n          agentAddress\n          agentDistrict\n          agentCity\n          agentProvince\n          agentPostalCode\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cb0b88ef69878f2efac63b621a840ad4';

module.exports = node;
