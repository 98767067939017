import React, {Component} from 'react';
import ProductListTable from "./productListTable";
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import {graphql} from "babel-plugin-relay/macro";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";

const allProduct = graphql`
    query productListAllQuery($type: String!, $search: String, $first: Int, $last: Int){
        productViewer{
            allProduct(type:$type, search:$search, first: $first, last: $last,excludeFine:true){
                edges{
                    node{
                        id
                        name
                        type
                        productCode
                        price
                        isActive
                        chartOfAccount{
                            chartOfAccountCode
                        }
                        reorderPoint
                        totalLeftInStock
                        price
                        cost
                        stock {
                            edges {
                                node {
                                    id
                                    cost
                                    leftInStock
                                    added
                                    transaction{
                                        date
                                    }
                                }
                            }
                        }
                    }
                }
                totalCount
            }
        }
    }
`;

class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
        };
        this.onChangeSearch = this.onChangeSearch.bind(this)
    }

    onChangeSearch(e) {
        this.setState({search: e.target.value});
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="inventory">
                        <ProductListTable query={allProduct} type={this.props.match.params.type}/>
                    </div>
                </WrapperContent>

            </Wrapper>

        )
    }
}

export default ProductList;