import {
    commitMutation,
} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";


const mutation = graphql`
    mutation createPreviousBudgetMutation($input:SubmitPreviousBudgetInput!){
        submitPreviousBudget(input:$input) 
        {
            ok
            message
            budgetId
        }       
    }
`;

export default function createPreviousBudget(variables, callback, error_callback) {
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
