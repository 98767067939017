import React , {useState} from 'react'
import {Translation} from "react-i18next"
import { Modal} from 'react-bootstrap';
import "./styles/project_manager.scss";
import {commitMutation} from "react-relay";
import environment from '../../env/environment';
import {graphql} from "babel-plugin-relay/macro";

const mutation = graphql `
    mutation modalChangeCommitteeMutation ($input: DeleteBoardTenantInput!) {
        deleteBoardTenant (input: $input) {
            ok
            message
        }
    }
`

function ModalChangeCommittee(props) {
    const [state] = useState(props);
    const [modalListNote, setModalListNote] = useState(false);
    const [modalNote, setModalNote] = useState(false);
    const [modalConfirm , setModalConfirm] = useState(false);
    const [modalSuccess , setModalSuccess] = useState(false);
    const [isError , setError] = useState(false);
    const [note , setNote] = useState('');

    const handleNextModal = (choice) => {
        if(choice === 'other'){
            setModalListNote(false)
            setModalNote(true)
        }else{
            setModalListNote(false)
            setNote(choice)
            setModalConfirm(true)
        }
    }

    const handleShow = () => {
      setModalListNote(true)
      setNote('')
    }

    const handleNextConfirm = () => {
        setModalNote(false)
        setModalConfirm(true)
    }

    const handleChange = e => {
      const {value} = e.target
      setNote(value)
    }

    const handleConfirm = () => {
        let variables ={
          input : {
            boardId : state.id,
            note : note
          }
        }
        commitMutation(
          environment,
          {
              mutation,
              variables,
              onCompleted: (response) => {
                  if (response.deleteBoardTenant.ok) {
                    setModalConfirm(false)
                    setModalSuccess(true)
                  } 
                  else {
                    setModalConfirm(false)
                    setError(true)
                  }
              },
              onError: (err) => {
                setModalConfirm(false)
                setError(true)
              },
          },
      )
        
    }
    
    const clickSuccess = () => {
      setModalSuccess(false)
      state.onDelete()
    }
    
  
    return (
      <Translation>
        {t =>
          <div>
            {state.status === 'delete' ?
                <span className="dropdown-item cursor" onClick={() => handleShow()}>
                    <span className="ml-3 text-black">
                    {t("projectManager:Delete")}
                </span>
                    
                </span>
                
              :
                <button type="button" className="add btn btn-primary float-right" onClick={() => handleShow()}>
                    <span>{t('projectManager:Change committee')}</span>
                </button>
            }


            {/* รายการหมายเหตุที่ยกเลิกหรือเปลี่ยน */}
            <Modal show={modalListNote}  >
              <Modal.Header style={{borderBottom:'none'}} >
                <Modal.Title >
                      <div className="row">
                            <div className="col-12 input-group">
                              <h4 className="modal-title">{state.status === 'delete' ? t("projectManager:Delete committee") : t('projectManager:Change committee')}</h4>
                            </div>
                        </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                  <div className="row mt-1 mb-1 cursor" onClick ={() => handleNextModal('ครบวาระ')}>
                    <div className="col-12">
                        <span>{t("projectManager:Expire from term")}</span>
                    </div>
                  </div>
                  <hr/>
                  <div className="row mt-1 mb-1 cursor" onClick ={() => handleNextModal('เกษียณอายุ')}>
                    <div className="col-12">
                        <span>{t("projectManager:Retire")}</span>
                    </div>
                  </div>
                  <hr/>
                  <div className="row mt-1 mb-1 cursor" onClick ={() => handleNextModal('ปัญหาเรื่องสุขภาพ')}>
                    <div className="col-12">
                        <span>{t("projectManager:Health problems")}</span>
                    </div>
                  </div>
                  <hr/>
                  <div className="row mt-1 mb-1 cursor" onClick ={() => handleNextModal('อื่นๆ')}>
                    <div className="col-12">
                        <span>{t("projectManager:etc.")}</span>
                    </div>
                  </div>
                  <hr/>
              </Modal.Body>
              <Modal.Footer style={{fontFamily:'Kanit' ,borderTop:'none'}}>
                <span className="text-blue cursor" onClick={() => setModalListNote(false)}>{t('projectManager:Cancel')}</span>
              </Modal.Footer>
            </Modal>


            {/* input note */}
            <Modal show={modalNote} >
              <Modal.Header style={{borderBottom:'none'}} >
                <Modal.Title>
                  <h4 className="modal-title">{t('projectManager:Reason to')}{state.status === 'delete' ? t("projectManager:Delete committee") : t('projectManager:Change committee')}</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                  <div className="row mt-1 mb-1">
                      <div className="col">
                          <textarea className="form-control" onChange={handleChange} value={note}/>
                      </div>
                  </div>
              </Modal.Body>
              <Modal.Footer style={{fontFamily:'Kanit' ,borderTop:'none'}}>
                <button type="button" className="btn add-button add" data-dismiss="modal" onClick={() => setModalNote(false)}>
                  <span className="text-blue">{t('projectManager:Cancel')}</span>
                </button>
                <button type="submit" form="form-transfer" className="btn add-button add" disabled={note=== ''} 
                onClick={() => handleNextConfirm()}>
                  <span style={{color:'#0382FA'}}>{t('projectManager:Confirm')}</span>
                </button>
                
              </Modal.Footer>
            </Modal>

            <Modal show={modalConfirm} >
              <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                  <div className="row mt-1 mb-1">
                      <div className="col-12 d-flex justify-content-center">
                        <img
                          src={process.env.PUBLIC_URL + '/images/icons/popup-yellow.png'}
                          alt="project-manager-profile"/>
                      </div>
                      <div className="col-12  mt-3 d-flex justify-content-center">
                          <h4>{t('projectManager:Are you sure to')} {state.status === 'delete' ? t("projectManager:Delete committee") : t('projectManager:Change committee')}</h4>
                      </div>
                  </div>
              </Modal.Body>
              <Modal.Footer style={{fontFamily:'Kanit' ,borderTop:'none'}}>
                <button type="button" className="btn add-button add" data-dismiss="modal" onClick={() => setModalConfirm(false)}>
                  <span className="text-blue">{t('projectManager:Cancel')}</span>
                </button>
                <button type="submit" form="form-transfer" className="btn add-button add" disabled={note=== ''} 
                onClick={() => handleConfirm()}>
                  <span style={{color:'#0382FA'}}>{t('projectManager:Confirm')}</span>
                </button>
                
              </Modal.Footer>
            </Modal>


            <Modal show={modalSuccess}  >
              <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                  <div className="row mt-1 mb-1 d-flex justify-content-center">
                      <div className="col-12 d-flex justify-content-center">
                        <img
                          src={process.env.PUBLIC_URL + '/images/icons/popup-green.png'}
                          alt="project-manager-profile"/>
                      </div>
                      <div className="col-12 d-flex mt-3 justify-content-center">
                          <h4>{state.status === 'delete' ? t('projectManager:Success to delete committee') : t('projectManager:Success to change committee')}</h4>
                      </div>
                  </div>
              </Modal.Body>
              <Modal.Footer style={{fontFamily:'Kanit' , borderTop:'none'}}>
                <button type="button" form="form-transfer" className="btn add-button add" onClick={() => clickSuccess()}>
                  <span style={{color:'#0382FA'}}>{t('projectManager:Confirm')}</span>
                </button>
                
              </Modal.Footer>
            </Modal>

            <Modal show={isError} >
              <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                  <div className="row mt-1 mb-1">
                      <div className="col-12 d-flex justify-content-center">
                        <img
                          src={process.env.PUBLIC_URL + '/images/icons/popup-yellow.png'}
                          alt="project-manager-profile"/>
                      </div>
                      <div className="col-12  mt-3 d-flex justify-content-center">
                          <h4> {t("projectManager:Error")} </h4>
                      </div>
                  </div>
              </Modal.Body>
              <Modal.Footer style={{fontFamily:'Kanit' ,borderTop:'none'}}>
                <button type="button" form="form-transfer" className="btn add-button add" onClick={() => setError(false)}>
                  <span style={{color:'#0382FA'}}>{t('projectManager:Confirm')}</span>
                </button>
                
              </Modal.Footer>
            </Modal>

          </div>
          }
      </Translation>
    );
  }

export default ModalChangeCommittee;