/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type RequisitionNoteStatus = "ACTIVE" | "VOID" | "%future added value";
export type RequisitionNoteTypeRequisition = "PROJECT" | "UNIT" | "%future added value";
export type wrapperStockMenagementQueryVariables = {|
  requisitionNoteId?: ?$ReadOnlyArray<?string>
|};
export type wrapperStockMenagementQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +logo: ?string,
    +juristicContactNumber: ?string,
    +keyProjectQr: ?string,
    +bankCompCode: ?string,
    +bankServiceCode: ?string,
    +taxIdNumber: ?string,
    +typeOfProject: ?ProjectTypeOfProject,
  |},
  +allRequisitionNote: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +status: RequisitionNoteStatus,
        +docNumber: ?string,
        +typeRequisition: RequisitionNoteTypeRequisition,
        +typeNote: ?string,
        +issuedDate: ?any,
        +recipient: ?string,
        +firstNameRecipient: ?string,
        +lastNameRecipient: ?string,
        +username: ?string,
        +objective: ?string,
        +remark: ?string,
        +contact: ?{|
          +id: string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |},
        +juristict: {|
          +id: string,
          +firstName: string,
          +lastName: string,
        |},
        +requisitionList: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +numberOfPieces: number,
              +numberOfBalance: number,
              +noNumber: ?string,
              +productAndService: {|
                +id: string,
                +noNumber: boolean,
                +name: string,
                +productCode: string,
                +stock: ?{|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +cost: number,
                      +leftInStock: number,
                      +added: any,
                      +transaction: ?{|
                        +date: ?any
                      |},
                    |}
                  |}>
                |},
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
|};
export type wrapperStockMenagementQuery = {|
  variables: wrapperStockMenagementQueryVariables,
  response: wrapperStockMenagementQueryResponse,
|};
*/


/*
query wrapperStockMenagementQuery(
  $requisitionNoteId: [String]
) {
  selfProject {
    id
    name
    address
    logo
    juristicContactNumber
    keyProjectQr
    bankCompCode
    bankServiceCode
    taxIdNumber
    typeOfProject
  }
  allRequisitionNote(requisitionNoteId: $requisitionNoteId) {
    edges {
      node {
        id
        status
        docNumber
        typeRequisition
        typeNote
        issuedDate
        recipient
        firstNameRecipient
        lastNameRecipient
        username
        objective
        remark
        contact {
          id
          name
          firstName
          lastName
        }
        juristict {
          id
          firstName
          lastName
        }
        requisitionList {
          edges {
            node {
              id
              numberOfPieces
              numberOfBalance
              noNumber
              productAndService {
                id
                noNumber
                name
                productCode
                stock {
                  edges {
                    node {
                      id
                      cost
                      leftInStock
                      added
                      transaction {
                        date
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requisitionNoteId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectNode",
  "kind": "LinkedField",
  "name": "selfProject",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "juristicContactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keyProjectQr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankCompCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankServiceCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxIdNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfProject",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "requisitionNoteId",
    "variableName": "requisitionNoteId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeRequisition",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeNote",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recipient",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstNameRecipient",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastNameRecipient",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objective",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "JuristicNode",
  "kind": "LinkedField",
  "name": "juristict",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfPieces",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfBalance",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "noNumber",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "leftInStock",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperStockMenagementQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "RequisitionNoteNodeConnection",
        "kind": "LinkedField",
        "name": "allRequisitionNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RequisitionNoteNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RequisitionNoteNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RequisitionListNodeConnection",
                    "kind": "LinkedField",
                    "name": "requisitionList",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RequisitionListNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RequisitionListNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v20/*: any*/),
                              (v21/*: any*/),
                              (v22/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductAndServiceNode",
                                "kind": "LinkedField",
                                "name": "productAndService",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v22/*: any*/),
                                  (v2/*: any*/),
                                  (v23/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductStockNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "stock",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProductStockNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ProductStockNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v1/*: any*/),
                                              (v24/*: any*/),
                                              (v25/*: any*/),
                                              (v26/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ProductTransactionNode",
                                                "kind": "LinkedField",
                                                "name": "transaction",
                                                "plural": false,
                                                "selections": [
                                                  (v27/*: any*/)
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wrapperStockMenagementQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "RequisitionNoteNodeConnection",
        "kind": "LinkedField",
        "name": "allRequisitionNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RequisitionNoteNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RequisitionNoteNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RequisitionListNodeConnection",
                    "kind": "LinkedField",
                    "name": "requisitionList",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RequisitionListNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RequisitionListNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v20/*: any*/),
                              (v21/*: any*/),
                              (v22/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductAndServiceNode",
                                "kind": "LinkedField",
                                "name": "productAndService",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v22/*: any*/),
                                  (v2/*: any*/),
                                  (v23/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductStockNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "stock",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProductStockNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ProductStockNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v1/*: any*/),
                                              (v24/*: any*/),
                                              (v25/*: any*/),
                                              (v26/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ProductTransactionNode",
                                                "kind": "LinkedField",
                                                "name": "transaction",
                                                "plural": false,
                                                "selections": [
                                                  (v27/*: any*/),
                                                  (v1/*: any*/)
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3e7bbad124f8349826e200a35d2b195",
    "id": null,
    "metadata": {},
    "name": "wrapperStockMenagementQuery",
    "operationKind": "query",
    "text": "query wrapperStockMenagementQuery(\n  $requisitionNoteId: [String]\n) {\n  selfProject {\n    id\n    name\n    address\n    logo\n    juristicContactNumber\n    keyProjectQr\n    bankCompCode\n    bankServiceCode\n    taxIdNumber\n    typeOfProject\n  }\n  allRequisitionNote(requisitionNoteId: $requisitionNoteId) {\n    edges {\n      node {\n        id\n        status\n        docNumber\n        typeRequisition\n        typeNote\n        issuedDate\n        recipient\n        firstNameRecipient\n        lastNameRecipient\n        username\n        objective\n        remark\n        contact {\n          id\n          name\n          firstName\n          lastName\n        }\n        juristict {\n          id\n          firstName\n          lastName\n        }\n        requisitionList {\n          edges {\n            node {\n              id\n              numberOfPieces\n              numberOfBalance\n              noNumber\n              productAndService {\n                id\n                noNumber\n                name\n                productCode\n                stock {\n                  edges {\n                    node {\n                      id\n                      cost\n                      leftInStock\n                      added\n                      transaction {\n                        date\n                        id\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f2c0b048195cc5b9a034b057b7d5d584';

module.exports = node;
