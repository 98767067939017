import {
    commitMutation,
} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import Swal from "sweetalert2";
import i18next from 'i18next';


const mutation = graphql`
    mutation CreateServicePricingEachUnitMutation($input: CreateServicePricingEachUnitInput!) {
        createServicePricingEachUnit(input: $input) {
            totalServicePricingEachUnit
        }
    }
`;

export default function CreateServicePricingEachUnit(variables, callback, error_callback) {

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success')
                callback(response)
            },
            onError: (err) => {
                error_callback(err)
            },
        },
    )
}
