import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const query = graphql`
    query allJuristicObjectQuery($userId: String) {
        allJuristicObject(userId: $userId){
            edges {
                node { 
                    id
                    image
                    signature
                    firstName 
                    firstNameEn
                    lastName 
                    lastNameEn  
                    phone
                    email 
                    # role 
                    siteId
                    roles 
                    project
                    user {
                        id 
                        username
                    }
                }
            }
        } 
    }
`;

export const allJuristicObject = (variables, response) => {
  fetchQuery(environment, query, variables)
    .then((data) => {
      if (data) {
        response(data.allJuristicObject);
      }
    })
    .catch((error) => {
      response(null);
    });
};
