import React from "react";
import ComponentPagination from "../../libs/componentPagination";
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import RegisterTopMenuNavigation from "../registerTopMenuNavigation";
import { Translation } from "react-i18next";
import "../styles/register.scss";
import SearchProject from "../../project/searchProject";
import { Link } from "react-router-dom";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import _ from "lodash";
import ownershipAPI from "./ownershipAPI";
import ReactPaginate from "../../libs/ReactPaginate";


class OwnershipList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      ownershipList : [],
      start_date : '',
      end_date : '',
      search : '',
      order : false,
      token : localStorage.getItem("token"),
      lastDateConveyance : '',
      loading : false,
      totalCount : 0,
      first: 0,
      last: 10,
      page_count: 0,
      num_record: 0,
      all_item: 10,
      page_select: 0,
      index_pages: {},
      indexFirst : 0
  }

}

  handleSearch = (text) => {
    this.setState({search: text} ,() => this.fetchData())
  }
  handleDate = (e) => {
      this.setState({
          [e.target.name] : e.target.value
      }, () => this.fetchData())
  }

  handleOrder = () => {
      this.setState({
          order : !this.state.order
      },() => this.fetchData())
  }

  changePageLimit = (value) => {
      this.setState({
        first: 0,
        last: value,
        all_item: value,
      },() => this.fetchData());
  }

  handlePageClick = (event) => {
      let page = event.selected;
      this.setState({ page_select: page });
  }

  componentWillMount(){
    this.fetchData()
}

  fetchData = (update) => {
    ownershipAPI.getOwnershipList(this.state.token,'', this.state.order ? 'order' : '' , 
        this.state.start_date,this.state.end_date,this.state.search,
        update ? this.state.index_pages[this.state.page_select].first : this.state.first,
        update ? this.state.index_pages[this.state.page_select].last : this.state.last)
    .then((response) => {
        if(response.data.totalCount !== 0){
            let first_index = _.cloneDeep(this.state.first);
            let last_index = _.cloneDeep(this.state.last);
            let index_pages = {};
            let page_count = Math.ceil(response.data.totalCount / this.state.all_item);
            for (let index = 0; index < page_count; index++) {
                index_pages[index] = {
                first: first_index,
                last: last_index,
                };
                if (index === page_count - 2) {
                first_index += this.state.all_item;
                last_index +=
                    response.data.totalCount % this.state.all_item !== 0
                    ? response.data.totalCount % this.state.all_item
                    : this.state.all_item;
                } else {
                first_index += this.state.all_item;
                last_index += this.state.all_item;
                }
            }
            this.setState({
                page_count: page_count,
                num_record: response.data.totalCount,
                index_pages: index_pages,
            
            },() => this.setState({
                indexFirst : this.state.index_pages[this.state.page_select].first
            }))
        }else{
            this.setState({
                page_count: 0,
                num_record: response.data.totalCount,
                index_pages: {},
            },() => this.setState({
                indexFirst : 0
            }))
        }
        this.setState({
            ownershipList : response.data.ownershipTenant,
            num_record: response.data.totalCount,
        })
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot){
    if (prevState.page_select !== this.state.page_select) {
        this.fetchData(true);
    }
  }

  render() {
    let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <RegisterTopMenuNavigation mini={true} />
          <Translation>
            {
              t=>
              <div className="container-fluid box" id="ownership">
                <div className="row justify-content-between">
                  <div className="col">
                    <h3>
                      <Link to="/register">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/wrapperContent/back.png"
                          }
                          alt="back"
                          className="back"
                        />
                      </Link>
                      <Translation>
                        {(t) => 
                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                        <span>{textData?.data?.ownershipListDetail.ownershipTransferHistory}</span>
                        :
                        <span>{t("register:ownership")}</span>
                        }
                      </Translation>
                    </h3>
                  </div>
                  {/* <div className="col">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                      codename: "register_ownership_create",
                    }) && (
                      <Link to="/register/ownership/create">
                        <button
                          type="button"
                          className="btn btn-primary add float-right"
                        >
                          <img
                            src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                            alt="plus"
                          />
                          <Translation>
                            {(t) => (
                              <span>{t("register:add_historical_data")}</span>
                            )}
                          </Translation>
                        </button>
                      </Link>
                    )} */}

                    {/*<Link to="">*/}
                    {/*    <button type="button" className="btn btn-outline-secondary float-right add mr-3">*/}
                    {/*        <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print"/>*/}
                    {/*        <Translation>*/}
                    {/*            {*/}
                    {/*                t => <span>{t('register:print')}</span>*/}
                    {/*            }*/}
                    {/*        </Translation>*/}
                    {/*    </button>*/}
                    {/*</Link>*/}
                  {/* </div> */}
                </div>

                <div className="content-inner">
                  <div className="row justify-content-end mt-4">
                    <div className="col-3 float-right w-auto mb-2">
                      <div className="form-inline">
                          <div className="form-group mb-2">
                              <label className="mr-2">{t("PageListOwnerShip:Date")}</label>
                              <DatePickerAdapter
                                  id="start_date"
                                  className="form-control"
                                  name="start_date"
                                  selected={this.state.start_date}
                                  onChange={this.handleDate}
                              />
                          </div>
                          <div className="form-group mb-2">
                              <Translation>
                                  {t=>
                                  <label htmlFor="end_date" className="ml-3 mr-3">
                                  {t("keycard:To")}
                                  </label>}
                              </Translation>
                              <DatePickerAdapter
                                  id="end_date"
                                  name="end_date"
                                  className="form-control"
                                  selected={this.state.end_date}
                                  onChange={this.handleDate}
                              />
                          </div>
                      </div>
                  </div>
                  <div className="col-3 float-right w-auto mb-2">
                      <SearchProject callback_function={this.handleSearch} search={this.state.search}/>
                  </div>
                  </div>
                    <div className="card fade-up">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead className="thead-light">
                            <Translation>
                              {t=>
                              <tr>
                                <th>
                                  <span onClick={() => this.handleOrder()} className="cursor">{t("PageListOwnerShip:Ownership Date")}
                                    {!this.state.order ? (
                                            <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/icons/sort-down.png"
                                            }
                                            alt="sort-down-icon"
                                            className="ml-1"
                                            />
                                        ) : (
                                            <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/images/icons/sort-up.png"
                                            }
                                            alt="sort-up-icon"
                                            className="ml-1"
                                            />
                                    )}
                                  </span>
                                </th>
                                <th>{t("keycard:Room")}</th>
                                <th>
                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                  textData?.data?.ownershipListDetail.assignor
                                  :
                                  t("PageListOwnerShip:Ownership By")
                                }
                                </th>
                                <th>{
                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                  textData?.data?.ownershipListDetail.assignee
                                  :                                  
                                  t("PageListOwnerShip:Ownership Recipient")
                                }</th>
                                <th>{t("PageListOwnerShip:Save By")}</th>
                              </tr>}
                            </Translation>
                          </thead>
                          <tbody>
                            {this.state.ownershipList.length > 0 && this.state.ownershipList.map((owner,index) => {
                              let lastOwnership = this.state.order ?   0 : (this.state.ownershipList.length -1)
                              return(
                                <tr key={index} style={{background: index === lastOwnership ? '#F2F9FF' : ''}}>
                                  <td >
                                      {lastOwnership === index ?
                                          <strong>{owner.dateConveyance}</strong>
                                          :
                                          <span>{owner.dateConveyance}</span>
                                      }
                                  </td>
                                  <td>
                                    {owner.residential}
                                  </td>
                                  <td className ="text-left">
                                      {owner.transferOfOwnership.map((ownershipName,indexOwner) =>{
                                        return <div key={indexOwner}><span>{ownershipName}</span><br /></div>
                                      })}
                                  </td>
                                  <td className ="text-left">
                                      {owner.owner.map((ownershipName,indexOwner) =>{
                                        return <div key={indexOwner}><span>{ownershipName}</span><br /></div>
                                      })}
                                  </td>
                                  <td>
                                    {owner.creator}
                                  </td>
                                </tr>
                                
                              );

                            })

                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <ReactPaginate
                        state={this.state}
                        changePageLimit={this.changePageLimit}
                        handlePageClick={this.handlePageClick}
                    />
                </div>
              </div>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default OwnershipList;
