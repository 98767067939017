import React from 'react'
import { Component } from 'react';
import Swal from 'sweetalert2';
import Wrapper from '../../components/wrapper';
import WrapperContent from '../../components/wrapper/wrapperContent';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import './juristicManager.scss'
import UploadImageInput from '../../libs/uploadImageInput';
import _ from 'lodash'
import UploadFileInput from '../../libs/uploadFileInput';
import SelectNamePrefix from '../../libs/selectNamePrefix';
import DatePickerAdapter from "../../libs/datePickerAdapter"
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from '../../env/environment';
import i18n from 'i18next';
import CreateAndUpdateManager from './createAndUpdateManager';
import { format } from 'date-fns';
import { Redirect } from 'react-router';
import upload from '../../libs/upload';
import i18next from 'i18next';
import BackButtonIcon from '../../components/BackBtn/indexBack';



const query = graphql`
query juristicManagerChangeQuery($id:String){
    personeManager (id: $id) {
        edges{
            node{
                id
                image
                signature
                prefix
                firstName
                lastName
                address
                department
                position
                affiliation
                phone
                email
                state
                note
                online
                workExperience{
                   edges{
                      node{
                          id
                          company
                          position
                          startDate
                          expDate
                          state
                          
                      } 
                   } 
                }
                education{
                   edges{
                      node{
                          id
                          name
                          degree
                          state
                      } 
                   } 
                }
                term{
                   edges{
                      node{
                          startDate
                          expDate
                      } 
                   } 
                }
                filepdf{
                   edges{
                      node{
                          id
                          name
                          pdf
                          state
                      } 
                   } 
                }
            }
        }
    }
}

`

const newJuristicManager = {
    image: '',
    prefix: '',
    otherPrefix: '',
    firstName: '',
    lastName: '',
    position: i18n.t('juristicManager:Juristic Manager'),
    department: '',
    address: '',
    mailAddress: '',
    signature: '',
    filepdf: { edges: [] },
    phone: '',
    email: '',
    affiliation: '',
    workExperience: { edges: [] },
    education: { edges: [] },
    startDate: new Date()
}

const newDocumentFile = {
    node:
    {
        name: '',
        pdf: '',
        state: '',
        id: ''
    }
}

const newWorkExperience = {
    node:
    {
        company: '',
        position: '',
        startDate: '',
        expDate: '',
        state: '',
        id: ''
    }
}

const newEducation = {
    node:
    {
        name: '',
        degree: '',
        state: '',
        id: ''
    }
}

const Prename = ["คุณ", "นาย", "นาง", "นางสาว", "เด็กชาย", "เด็กหญิง", "Miss", "Mrs.", "Mr.", "Master"]

class JuristicManagerChange extends Component {
    constructor(props) {
        super(props)
        this.state = {
            juristicManager: newJuristicManager,
            loading: false,
            uploadImage: '',
            uploadSignature: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputDocument = this.handleInputDocument.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.addDocument = this.addDocument.bind(this);
        this.handleChangeNamFile = this.handleChangeNamFile.bind(this)
        this.handleNamePrefix = this.handleNamePrefix.bind(this)
        this.appendEducation = this.appendEducation.bind(this)
        this.appendWork = this.appendWork.bind(this)
        this.onSubmitSuccess = this.onSubmitSuccess.bind(this)
        this.onSubmitError = this.onSubmitError.bind(this)
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            fetchQuery(environment, query, {
                id: this.props.match.params.id,
            }).then(data => {
                if (data.personeManager.edges.length > 0) {
                    let setData = data.personeManager.edges
                    let findPreName = Prename.includes(setData[0].node.prefix)

                    let setDatas = setData?.map((n) => ({
                        ...n.node,
                        otherPrefix: !findPreName ? n.node.prefix : "",
                        prefix: !findPreName ? "other_input" : n.node.prefix,
                        startDate: n.node.term.edges[0].node.startDate
                    }))
                    this.setState({
                        juristicManager: setDatas[0],
                        defaultImage: data.personeManager.edges[0].node.image,
                        defaultSignature: data.personeManager.edges[0].node.signature
                    })
                }
            })
        } else {
            this.setState({ juristicManager: newJuristicManager })
        }
    }

    handleNamePrefix(value) {
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                "juristicManager.prefix",
                value
            );
        });
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        if (event.target.name === 'juristicManager.image.upload_image') {
            this.setState({
                uploadImage: value
            })
        }
        if (event.target.name === 'juristicManager.signature.upload_image') {
            this.setState({
                uploadSignature: value
            })
        }
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });

    }

    handleChangeNamFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });
    }

    handleInputDocument(e) {
        let id = parseInt(e.currentTarget.dataset.id)
        let filepdf = _.cloneDeep(this.state.juristicManager.filepdf.edges)
        _.set(filepdf, [id, 'node', 'pdf'], e.currentTarget.files[0])
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                "juristicManager.filepdf.edges",
                filepdf
            );
        });
    }
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/juristicManager/manager" + path, file);
            resolve(res);
        });
    };

    async onSubmit(e) {
        e.preventDefault();
        let juristicManager = this.state.juristicManager
        this.setState({ loading: true })

        let educationData = []
        _.forEach(juristicManager.education.edges, (education) => {
            educationData.push({
                id: education.node.id, name: education.node.name,
                degree: education.node.degree, status: education.node.state
            })
        })

        let newFilePdf = []
        for (const file of juristicManager.filepdf.edges) {
            if (file.node.pdf !== '' && file.node.id === '') {
                await this.callUpload('', file.node.pdf).then((data) => {
                    newFilePdf.push({
                        id: file.node.id, nameFile: file.node.name,
                        fileUpload: data.key.replace("silverman-storage/private/", ""), status: file.node.state
                    })
                });
            } else {
                newFilePdf.push({
                    id: file.node.id, nameFile: file.node.name,
                    fileUpload: file.node.pdf, status: file.node.state
                })
            }
        }
        let newWorkExperience = []
        _.forEach(juristicManager.workExperience.edges, (work) => {
            newWorkExperience.push({
                id: work.node.id, nameOfCompany: work.node.company, position: work.node.position, dateOfPosition: format(work.node.startDate, 'YY-M-D'),
                dateOfEndPosition: format(work.node.expDate, 'YY-M-D'), status: work.node.state
            })
        })
        let variables = {
            input: {
                id: juristicManager.id ? juristicManager.id : '',
                namePrefix: juristicManager.prefix === "other_input" ? juristicManager.otherPrefix : juristicManager.prefix,
                firstName: juristicManager.firstName,
                lastName: juristicManager.lastName,
                address: juristicManager.address,
                department: '',
                position: juristicManager.position,
                affiliation: '',
                email: juristicManager.email,
                phone: juristicManager.phone,
                education: JSON.stringify({ education: educationData }),
                workExperience: JSON.stringify({ workExperience: newWorkExperience }),
                filepdf: JSON.stringify({ filepdf: newFilePdf }),
                startDate: format(new Date(juristicManager.startDate), "YYYY-MM-DD")
            }
        }

        if (this.state.uploadImage !== '') {
            await this.callUpload('', this.state.uploadImage).then((data) => {
                variables.input["image"] = data.key.replace("silverman-storage/private/", "");
            });
        }
        if (this.state.uploadSignature !== '') {
            await this.callUpload('', this.state.uploadSignature).then((data) => {
                variables.input["signature"] = data.key.replace("silverman-storage/private/", "");
            });
        }
       CreateAndUpdateManager(variables, this.onSubmitSuccess, this.onSubmitError)

    }

    onSubmitSuccess(response) {
        this.setState({ loading: false });
        if (response) {
            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                this.setState({ redirectToList: true })
            });
        } else {
            Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
        }
    }

    onSubmitError(err) {
        this.setState({ loading: false });
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }

    onDelete = (data, id, name) => {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data") + "?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let getData = _.cloneDeep(data)
                let index = _.findIndex(_.cloneDeep(getData), { node: { id: id } })
                if (index === -1) {
                    getData.splice(id, 1)
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            name,
                            getData
                        );
                    });
                } else {
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            name + '[' + index + '].node.state',
                            'delete'
                        );
                    });
                }
            }
        })
    }


    addDocument() {
        let value = _.cloneDeep(this.state.juristicManager.filepdf.edges)
        value.push(newDocumentFile)
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                "juristicManager.filepdf.edges",
                value
            );
        });
    }

    appendEducation() {
        let education = _.cloneDeep(this.state.juristicManager.education.edges)
        education.push(newEducation)
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                'juristicManager.education.edges',
                education
            );
        });
    }

    appendWork() {
        let work = _.cloneDeep(this.state.juristicManager.workExperience.edges)
        work.push(newWorkExperience)
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                'juristicManager.workExperience.edges',
                work
            );
        });
    }

    onDeleteItem(index) {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data") + "?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let filepdf = _.cloneDeep(this.state.juristicManager.filepdf.edges);
                filepdf.splice(index, 1)
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        "juristicManager.filepdf.edges",
                        filepdf
                    );
                });
            }
        })
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/project/juristic-manager/list/current" />
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <ProjectTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="projectDashboard">
                                <BackButtonIcon
                                    LinkBack={"/project/juristic-manager/list/current"}
                                    LinkText={t('juristicManager:Change juristic manager')}
                                />
                                <div className="content-inner">
                                    <form id="juristic-manager-form" onSubmit={this.onSubmit}>
                                        <div className="row p-3">
                                            <div className="col-md-7 col-sm-12">
                                                <div className="card card-manager pt-3 pr-3 pt-1">
                                                    <div className="row mt-3">
                                                        <div className="col-md-3">
                                                            <div className="col-12 d-flex justify-content-center mt-2">
                                                                <img
                                                                    src={this.state.juristicManager.image ? this.state.juristicManager.image : process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                    alt="project-manager-profile"
                                                                    className="rounded-circle avatar-img profile-img" />
                                                            </div>
                                                            <div className="col-12 d-flex justify-content-center mt-2">
                                                                <div className="upload-file">
                                                                    <div >
                                                                        <label className="btn-link"
                                                                            htmlFor="uploadProfileImages">{t('project_manager:Change picture')}</label>
                                                                    </div>
                                                                    <div className="upload-input">
                                                                        <UploadImageInput
                                                                            id="uploadProfileImages"
                                                                            setField={"juristicManager.image.upload_image:juristicManager.image"}
                                                                            onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">

                                                            <div className="row mb-1">
                                                                <div className="col-12">
                                                                    <h6>{t('juristicManager:Name Prefix')}</h6>
                                                                </div>
                                                                <div className={this.state.juristicManager.prefix !== "other_input" ? "col-12" : "col-6"}>
                                                                    <SelectNamePrefix callBack={this.handleNamePrefix}
                                                                        needOtherInput={true} value={this.state.juristicManager.prefix} required={true} />
                                                                </div>
                                                                {
                                                                    this.state.juristicManager.prefix === "other_input" &&
                                                                    <div className="col-6">
                                                                        <input type="text" className="form-control"
                                                                            value={this.state.juristicManager.otherPrefix}
                                                                            onChange={this.handleInputChange}
                                                                            name="juristicManager.otherPrefix" required />
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="row mb-1">
                                                                <div className="col-6 mt-2">
                                                                    <h6>{t('juristicManager:First Name')}</h6>
                                                                </div>
                                                                <div className="col-6 mt-2">
                                                                    <h6>{t('juristicManager:Last Name')}</h6>
                                                                </div>
                                                                <div className="col-6">
                                                                    <input type="text" className="form-control"
                                                                        value={this.state.juristicManager.firstName}
                                                                        onChange={this.handleInputChange}
                                                                        name="juristicManager.firstName" required />
                                                                </div>
                                                                <div className="col-6">
                                                                    <input type="text" className="form-control"
                                                                        value={this.state.juristicManager.lastName}
                                                                        onChange={this.handleInputChange}
                                                                        name="juristicManager.lastName" required />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-12 mt-2">
                                                                    <h6>{t('juristicManager:Address')}</h6>
                                                                </div>
                                                                <div className="col-12 ">
                                                                    <textarea className="form-control" value={this.state.juristicManager.address}
                                                                        name="juristicManager.address" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-12 mt-2" >
                                                                    <h6>{t('juristicManager:Position')}</h6>
                                                                    <input type="text" className="form-control"
                                                                        value={this.state.juristicManager.position}
                                                                        onChange={this.handleInputChange}
                                                                        name="juristicManager.position" required
                                                                        disabled={true} />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-12">
                                                                    <h6>{t('projectManager:Work experience')}</h6>
                                                                </div>
                                                                {this.state.juristicManager.workExperience?.edges.length > 0 &&
                                                                    this.state.juristicManager.workExperience?.edges.map((work, index) => {
                                                                        if (work.node.state !== 'delete') {
                                                                            return (
                                                                                <div className="col-12 mb-3 mt-3" key={index + 'work'}>
                                                                                    <div className="col-12">
                                                                                        <span>{t('projectManager:Name of company')}</span>
                                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                            alt="delete" className="btn-delete cursor ml-3 align-self-center"
                                                                                            onClick={() => this.onDelete(this.state.juristicManager.workExperience.edges, work.node.id ?
                                                                                                work.node.id : index, 'juristicManager.workExperience.edges')} />
                                                                                    </div>
                                                                                    <div className="col-12 mt-1">
                                                                                        <input type="text" className="form-control"
                                                                                            name={"juristicManager.workExperience.edges[" + index + "].node.company"}
                                                                                            value={work.node.company} onChange={this.handleInputChange}
                                                                                            required={true} />
                                                                                    </div>
                                                                                    <div className="col-12 mt-1">
                                                                                        <span>{t('projectManager:Position')}</span>
                                                                                    </div>
                                                                                    <div className="col-12 mt-1">
                                                                                        <input type="text" className="form-control"
                                                                                            name={"juristicManager.workExperience.edges[" + index + "].node.position"}
                                                                                            value={work.node.position} required={true} onChange={this.handleInputChange} />
                                                                                    </div>
                                                                                    <div className="col-12 mt-1">
                                                                                        <span>{t('projectManager:Working period')}</span>
                                                                                    </div>
                                                                                    <div className="col-12 mt-1 custom-control-inline">
                                                                                        <DatePickerAdapter
                                                                                            name={"juristicManager.workExperience.edges[" + index + "].node.startDate"}
                                                                                            className="form-control mr-2"
                                                                                            selected={work.node.startDate !== '' ? new Date(work.node.startDate) : work.node.startDate}
                                                                                            onChange={this.handleInputChange}
                                                                                            required={true}
                                                                                        />

                                                                                        <label className="ml-2 align-self-center mr-2">{t('projectManager:to')}</label>
                                                                                        <DatePickerAdapter
                                                                                            name={"juristicManager.workExperience.edges[" + index + "].node.expDate"}
                                                                                            className="form-control"
                                                                                            selected={work.node.expDate !== '' ? new Date(work.node.expDate) : work.node.expDate}
                                                                                            onChange={this.handleInputChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return <></>
                                                                        }

                                                                    })
                                                                }
                                                                <div className="col-12 mt-2 mb-2">
                                                                    <span className="text-blue cursor" onClick={() => this.appendWork()}> + {t('projectManager:Add work experience')}</span>
                                                                </div>

                                                            </div>
                                                            <div className="row mt-4 ">
                                                                <div className="col-12">
                                                                    <h6>{t('projectManager:Education')}</h6>
                                                                </div>
                                                                {this.state.juristicManager.education?.edges.length > 0 &&
                                                                    this.state.juristicManager.education?.edges.map((education, index) => {
                                                                        if (education.node.state !== 'delete') {
                                                                            return (
                                                                                <div className="col-12 mt-3 mb-3" key={index + 'education'}>
                                                                                    <div className="col-12">
                                                                                        <span>{t('projectManager:Name')}</span>
                                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                            alt="delete" className="btn-delete cursor ml-3 align-self-center"
                                                                                            onClick={() => this.onDelete(this.state.juristicManager.education.edges, education.node.id ?
                                                                                                education.node.id : index, 'juristicManager.education.edges')} />
                                                                                    </div>
                                                                                    <div className="col-12 mt-1">
                                                                                        <input type="text" className="form-control col-12"
                                                                                            name={"juristicManager.education.edges[" + index + "].node.name"}
                                                                                            value={education.node.name}
                                                                                            onChange={this.handleInputChange}
                                                                                            required={true} />
                                                                                    </div>
                                                                                    <div className="col-12 mt-1">
                                                                                        <span>{t('projectManager:Degree')}</span>
                                                                                    </div>
                                                                                    <div className="col-12 mt-1">
                                                                                        <input type="text" className="form-control col-12"
                                                                                            name={"juristicManager.education.edges[" + index + "].node.degree"}
                                                                                            value={education.node.degree}
                                                                                            onChange={this.handleInputChange}
                                                                                            required={true} />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return <></>
                                                                        }

                                                                    })
                                                                }
                                                                <div className="col-12 mt-2 mb-2 bottom">
                                                                    <span className="text-blue cursor" onClick={() => this.appendEducation()}> + {t('projectManager:Add education')}</span>
                                                                </div>
                                                            </div>

                                                            <div className='row mt-4'>
                                                                <div className='col-12'>{t("juristicManager:Date of position")}:
                                                                    <input type={'date'} className='form-control'
                                                                        name={"juristicManager.startDate"}
                                                                        onChange={this.handleInputChange}
                                                                        value={format(new Date(this.state.juristicManager?.startDate ?? new Date()), "YYYY-MM-DD")} /></div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="col-md col-sm-12">
                                                <div className="card card-manager p-3">
                                                    <div className="row mt-3">
                                                        <div className="col-12">
                                                            <h6>{t('juristicManager:Signature')}</h6>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="col-12 d-flex justify-content-center mt-2">
                                                                <img
                                                                    src={this.state.juristicManager.signature ? this.state.juristicManager.signature : process.env.PUBLIC_URL + '/images/icons/signature-upload-icon.png'}
                                                                    alt="project-manager-profile"
                                                                    className="profile-img signature" />
                                                            </div>
                                                            <div className="col-12 d-flex justify-content-center mt-2">
                                                                <div className="upload-file">
                                                                    <div >
                                                                        <label className="btn-link"
                                                                            htmlFor="uploadSignatureImages">{t('project_manager:Change picture')}</label>
                                                                    </div>
                                                                    <div className="upload-input">
                                                                        <UploadImageInput
                                                                            id="uploadSignatureImages"
                                                                            setField={"juristicManager.signature.upload_image:juristicManager.signature"}
                                                                            onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="row input-group d-flex align-items-center mt-3">
                                                        <div className="col-5">{t('projectManager:Contact')}</div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col-1 align-self-center">
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/phone-manager-icon.png'} alt="phone" className="icon" />
                                                                </div>
                                                                <div className="col">
                                                                    <input type="text" className="form-control custom-control-inline" onChange={this.handleInputChange}
                                                                        name={'juristicManager.phone'} value={this.state.juristicManager.phone} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row input-group d-flex align-items-center mt-2">
                                                        <div className="col-5" />
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col-1 align-self-center">
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/mail-icon.png'} alt="mail" className="icon" />
                                                                </div>
                                                                <div className="col">
                                                                    <input type="text" className="form-control custom-control-inline" onChange={this.handleInputChange}
                                                                        name="juristicManager.email" value={this.state.juristicManager.email} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2">
                                                        <div className="col-12">
                                                            <h6>{t('juristicManager:Document')} </h6>
                                                        </div>
                                                        {this.state.juristicManager.filepdf?.edges?.length > 0 &&
                                                            this.state.juristicManager.filepdf?.edges.map((document, index) => {
                                                                if (document.node.state !== 'delete') {
                                                                    return (
                                                                        <div className="row col-12 mb-2" key={index + 'edit'}>
                                                                            <div className="input-group col-5 align-self-center">
                                                                                <div className="input-group-prepend">
                                                                                    <strong><span className="input-group-text">{t('juristicManager:Name')}</span></strong>
                                                                                </div>
                                                                                <input type="text" className="form-control" value={document.node.name}
                                                                                    name={"juristicManager.filepdf.edges[" + index + "].node.name"} onChange={this.handleChangeNamFile}
                                                                                    data-key="documentFile" data-id={index} required={true} />
                                                                            </div>
                                                                            <div className="col ">
                                                                                {(document.node.pdf && document.node.id) ?
                                                                                    <a download={document.node.name}
                                                                                        href={document.node.pdf}>
                                                                                        <label className="cursor ml-2">ดาวน์โหลด</label>
                                                                                    </a>
                                                                                    :
                                                                                    <UploadFileInput
                                                                                        handleInputDocument={this.handleInputDocument}
                                                                                        documentUpload={document.node.pdf}
                                                                                        dataId={index}
                                                                                        name={"juristicManager.filepdf.edges[" + index + "].node.pdf"}
                                                                                        disableHeaderText={true}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            <div className="col-1 align-self-center">
                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                    alt="delete" className="btn-delete cursor ml-3 align-self-center"
                                                                                    onClick={() => this.onDelete(this.state.juristicManager.filepdf.edges, document.node.id ?
                                                                                        document.node.id : index, 'juristicManager.filepdf.edges')} />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return <></>
                                                                }


                                                            })
                                                        }
                                                        <div className="row col-12">
                                                            <button
                                                                type="button"
                                                                className="btn add-button add"
                                                                onClick={this.addDocument}
                                                            >
                                                                <span className="text-blue add-list" >+ {t('createUpdateForm:add_list')}</span>
                                                            </button>
                                                            <span className="text-red pt-3"> {t('projectManager:The system supports .pdf .docx files up to 10MB in size.')}  </span>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="row mt-2 bottom mb-3 center" >
                                                    <div className="col-12 bottom">
                                                        <button type="submit"
                                                            className="btn btn-primary mr-1"
                                                            form="juristic-manager-form" disabled={this.state.loading}>
                                                            {this.state.loading && <span
                                                                className="spinner-border spinner-border-sm align-middle mr-1" />}
                                                            {t("juristicManager:Confirm")}
                                                        </button>
                                                        <Link to="/project/juristic-manager/list/current">
                                                            <button type="button"
                                                                className="btn btn-dark"
                                                                form="juristic-manager-form" disabled={this.state.loading}>
                                                                {t("juristicManager:Cancel")}
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    </Translation>

                </WrapperContent>
            </Wrapper>

        );
    }


}
export default JuristicManagerChange;