import React from "react";
import getApiRoot from "../../libs/getAPIRoot";
import Helpers from '../../libs/file_download_helper';
import { format } from "date-fns";
import { Translation } from "react-i18next";
import i18next from "i18next";

class ExportBalanceSheetAccount extends React.Component {

    constructor(props) {
        super(props);
        this.state = { loading: false };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (event) => {
        this.setState({
            errors: null,
            loading: true,
        }, () => {
            Helpers.httpRequest(
                getApiRoot() + 'export/balance_sheet_account?start_date_first='
                + format(this.props.state.start_date_first, 'YYYY-MM-DD')
                + '&end_date_first=' + format(this.props.state.end_date_first, 'YYYY-MM-DD')
                + '&start_date_second=' + format(this.props.state.start_date_second, 'YYYY-MM-DD')
                + '&end_date_second=' + format(this.props.state.end_date_second, 'YYYY-MM-DD')
                + '&selected_date=' + format(this.props.state.selected_date, 'YYYY-MM-DD')
                + '&period=' + this.props.state.period,
                'GET',
            )
                .then((response) => response.blob())
                .then((blob) => {
                    // create blob link
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "balance_sheet_account.xls");

                    // append to html
                    document.body.appendChild(link);

                    // download
                    link.click();

                    // remove
                    link.parentNode.removeChild(link);

                    this.setState({
                        loading: false
                    });
                })
                .catch((error) => {
                    error.json().then((json) => {
                        this.setState({
                            errors: json,
                            loading: false
                        });
                    })
                });
        });

        event.preventDefault();
    };

    render() {

        return (
            <Translation>
                {t =>
                    <form onSubmit={this.handleSubmit}>
                        <button type="submit" className="btn print float-right"
                            disabled={this.state.loading}>
                            {this.state.loading ?
                                <span>
                                    <span
                                        className="spinner-border spinner-border-sm align-middle mr-2" /> {i18next.t("BalanceSheet:Preparing information")}</span> :
                                <span>
                                    <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon" />
                                    {i18next.t("BalanceSheet:Print")}
                                </span>
                            }
                        </button>
                    </form>

                }
            </Translation>
        )
    }
}

export default ExportBalanceSheetAccount;
