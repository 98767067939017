/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SettingAssetTypeAsset = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type clearAdvanceCreateQueryVariables = {|
  id: string,
  status?: ?string,
|};
export type clearAdvanceCreateQueryResponse = {|
  +allAdvance: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +issuedDate: any,
        +docNumber: string,
        +description: string,
        +withdrawer: string,
        +amount: number,
      |}
    |}>,
  |},
  +advance: ?{|
    +id: string,
    +amount: number,
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |}
      |}>
    |}
  |},
  +allSettingAsset: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +typeAsset: SettingAssetTypeAsset,
        +depreciationRatePercent: ?number,
        +depreciationRate: ?number,
        +lifeTimeYear: ?number,
      |}
    |}>
  |},
  +allSetPettyCash: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +description: ?string,
        +withdrawer: ?string,
        +withdrawAmount: number,
        +totalPettyCashRecord: ?number,
      |}
    |}>,
  |},
|};
export type clearAdvanceCreateQuery = {|
  variables: clearAdvanceCreateQueryVariables,
  response: clearAdvanceCreateQueryResponse,
|};
*/


/*
query clearAdvanceCreateQuery(
  $id: ID!
  $status: String
) {
  allAdvance(status_Icontains: $status) {
    totalCount
    edges {
      node {
        id
        issuedDate
        docNumber
        description
        withdrawer
        amount
      }
    }
  }
  advance(id: $id) {
    id
    amount
  }
  viewer {
    allChartOfAccount(codeList: ["5", "1151", "1152", "1154", "1210", "1230", "2121", "2122", "2123", "2124", "2125", "2126"]) {
      edges {
        node {
          id
          name
          chartOfAccountCode
        }
      }
    }
    id
  }
  allSettingAsset(settingAssetId: "", typeAsset: "") {
    edges {
      node {
        id
        typeAsset
        depreciationRatePercent
        depreciationRate
        lifeTimeYear
      }
    }
  }
  allSetPettyCash(status: "active") {
    totalCount
    edges {
      node {
        id
        docNumber
        description
        withdrawer
        withdrawAmount
        totalPettyCashRecord
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "withdrawer",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "status_Icontains",
      "variableName": "status"
    }
  ],
  "concreteType": "AdvanceNodeConnection",
  "kind": "LinkedField",
  "name": "allAdvance",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AdvanceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdvanceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "issuedDate",
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "AdvanceNode",
  "kind": "LinkedField",
  "name": "advance",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "codeList",
      "value": [
        "5",
        "1151",
        "1152",
        "1154",
        "1210",
        "1230",
        "2121",
        "2122",
        "2123",
        "2124",
        "2125",
        "2126"
      ]
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chartOfAccountCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allChartOfAccount(codeList:[\"5\",\"1151\",\"1152\",\"1154\",\"1210\",\"1230\",\"2121\",\"2122\",\"2123\",\"2124\",\"2125\",\"2126\"])"
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "settingAssetId",
      "value": ""
    },
    {
      "kind": "Literal",
      "name": "typeAsset",
      "value": ""
    }
  ],
  "concreteType": "SettingAssetNodeConnection",
  "kind": "LinkedField",
  "name": "allSettingAsset",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SettingAssetNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SettingAssetNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "typeAsset",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "depreciationRatePercent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "depreciationRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lifeTimeYear",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allSettingAsset(settingAssetId:\"\",typeAsset:\"\")"
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "status",
      "value": "active"
    }
  ],
  "concreteType": "SetPettyCashNodeConnection",
  "kind": "LinkedField",
  "name": "allSetPettyCash",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SetPettyCashNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SetPettyCashNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "withdrawAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPettyCashRecord",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allSetPettyCash(status:\"active\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clearAdvanceCreateQuery",
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clearAdvanceCreateQuery",
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v10/*: any*/),
      (v11/*: any*/)
    ]
  },
  "params": {
    "cacheID": "8a5e9ac4ec566a44ed34b1b2840e2089",
    "id": null,
    "metadata": {},
    "name": "clearAdvanceCreateQuery",
    "operationKind": "query",
    "text": "query clearAdvanceCreateQuery(\n  $id: ID!\n  $status: String\n) {\n  allAdvance(status_Icontains: $status) {\n    totalCount\n    edges {\n      node {\n        id\n        issuedDate\n        docNumber\n        description\n        withdrawer\n        amount\n      }\n    }\n  }\n  advance(id: $id) {\n    id\n    amount\n  }\n  viewer {\n    allChartOfAccount(codeList: [\"5\", \"1151\", \"1152\", \"1154\", \"1210\", \"1230\", \"2121\", \"2122\", \"2123\", \"2124\", \"2125\", \"2126\"]) {\n      edges {\n        node {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n    id\n  }\n  allSettingAsset(settingAssetId: \"\", typeAsset: \"\") {\n    edges {\n      node {\n        id\n        typeAsset\n        depreciationRatePercent\n        depreciationRate\n        lifeTimeYear\n      }\n    }\n  }\n  allSetPettyCash(status: \"active\") {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        description\n        withdrawer\n        withdrawAmount\n        totalPettyCashRecord\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5e370512cc4739561018f987e3b8bae7';

module.exports = node;
