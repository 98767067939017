import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import ProjectManagerList from "./projectManagerList";
import CreateUpdateProjectManager from "./createUpdateProjectManager";
import UpdateProjectManager from "./updateProjectManager";
// import CreateProjectManagerGroup from "./createProjectManagerGroup";
import NoMatch from "../../components/noMatch";

class ProjectManager extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={ProjectManagerList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form-manager/:status/:id?`} component={CreateUpdateProjectManager}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail-manager/:id`} component={UpdateProjectManager}/>
                {/* <PrivateRoute exact path={`${this.props.match.url}/form-manager-group`} component={CreateProjectManagerGroup}/> */}
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default ProjectManager;