import React from 'react';
import { Translation } from "react-i18next";
import ComponentPagination from "../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import Pagination from "../../libs/newPagination";
import { format } from "date-fns";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import _ from "lodash";
import thLocale from "date-fns/locale/th";
import Swal from "sweetalert2";
import { commitMutation } from "react-relay";
import { Redirect } from 'react-router';
import i18n from "i18next";
import Loading from "../../libs/loading"
import BackButtonIcon from '../../components/BackBtn/indexBack';
import Navigation from './navigation';
import SortMeterWater from './sortMeterWater';

var time;
const query = graphql`
    query waterMeterListHistoryQuery($first: Int, $last: Int,$year: Float,$search: String) {
        allWaterMeterUnit(first: $first, last: $last,residential_Name_Icontains: $search,year: $year,changeWaterMeter: true){
            totalCount
            edges{
                node{
                    id
                    issuedDate
                    meterValue
                    residential{
                        name
                    }
                    remark
                    added
                    editDate
                    initialChangeMeterValue
                }
            }
        }

    }
`;

const mutation = graphql`
mutation waterMeterListHistoryMutation($input : DeleteChangeWaterMeterInput!){
    deleteChangeWaterMeter(input: $input){
        ok
    }
}
`;


class WaterMeterListHistory extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state.data_list = false;
        this.state.water_meter_id = false;
        this.state.search = "";
        this.state.selectYear = new Date().getFullYear();
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.handlePropsFormSort = this.handlePropsFormSort.bind(this);
    }

    componentDidMount() {
        this.getMeterList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
          prevState.first !== this.state.first ||
          prevState.last !== this.state.last
        ) {
          this.getMeterList();
        }
    
        if (prevState.search !== this.state.search || prevState.selectYear !== this.state.selectYear) {
          clearTimeout(time);
          time = setTimeout(() => {
            this.getMeterList();
          }, 500);
        }
      }

    getMeterList() {
        this.setState({ data_list: false }, () => {
            let variables = {
                first: this.state.first,
                last: this.state.last,
                search: this.state.search,
                year: this.state.selectYear,
            };

            fetchQuery(environment, query, variables).then(results => {
                if (_.isObject(results.allWaterMeterUnit)) {
                    this.setState({ data_list: results.allWaterMeterUnit })
                }

            });

        });

    }

    handlePropsFormSort(date) {
        this.setState(
          {
            search: date[0].value,
            selectYear: date[5].value,
          }
        );
      }

    onEditMeter(item) {
        this.setState({ water_meter_id: item.node.id })
    }

    onDeleteItem(item) {

        Swal.fire({
            title: i18n.t('meter:Are you sure to delete record?'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t('meter:Yes'),
            cancelButtonText: i18n.t('meter:Cancel')
        }).then((result) => {
            if (result.value) {
                let variables = {
                    input: {
                        id: item.node.id
                    }
                };
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            this.setState({ loading: false });
                            if (response.deleteChangeWaterMeter.ok) {
                                Swal.fire(i18n.t('meter:Save successfully'), '', 'success').then(() => {
                                    this.getMeterList();
                                });
                            } else {
                                Swal.fire(i18n.t('meter:Failed to save'), '', 'warning');
                            }
                        },
                        onError: (err) => {
                            this.setState({ loading: false });
                            Swal.fire('Error!', i18n.t('meter:Please try again'), 'warning')
                        },
                    },
                );
            }
        });

    }

    render() {
        if (this.state.water_meter_id) {
            return <Redirect to={"/accounting/water-meter/create_history/" + this.state.water_meter_id} />
        }
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <BackButtonIcon
                            printdocLinkChk={true}
                            addLinkButton={"/accounting/water-meter/createQRocde"}
                            addLinkText={t("meter:createqrcode")}
                            LinkBack={"/accounting"}
                            LinkText={t("materWater:Water Meter History back")}
                            btnOutlineChk={true}
                            btnOutlineLink={"/accounting/water-meter/viewHistory"}
                            btnOutlineText={t("meter:viewHistory")}
                            btnprintChk={false}
                            btnExportChk={true}
                            state={this.state}
                        />
                        <Navigation />
                        <div className="content-inner">
                            <div className="row">
                                <div className="col">
                                    <div>
                                        <SortMeterWater
                                            selectIs={true}
                                            selectMonthIs={false}
                                            searchBoxId={true}
                                            selectText={t("meter:MeterWater")}
                                            changMeter={true}
                                            handlePropsChange={this.handlePropsFormSort}
                                        />
                                    </div>
                                </div>
                                {this.state.data_list &&
                                    <React.Fragment>
                                        <div className="col-12">
                                            <form className="card">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-light text-center">
                                                            <th width="60">{t('meter:Room No.')}</th>
                                                            <th width="60">{t('meter:Recording date')}</th>
                                                            <th width="60">{t('meter:Month/Year')}</th>
                                                            <th width="60">{t('meter:New meter start')}</th>
                                                            <th width="60">{t('meter:New current meter')}</th>
                                                            <th width="100">{t('meter:Note')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.data_list.edges.map((data, index) => {
                                                            return (
                                                                <tr key={data.node.id}>
                                                                    <td>{data.node.residential.name}</td>
                                                                    <td className="text-center">{format(data.node.editDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                    <td className="text-center">{format(data.node.issuedDate, 'MM/YYYY', { locale: thLocale })}</td>
                                                                    <td className="text-right">{data.node.initialChangeMeterValue}</td>
                                                                    <td className="text-right">{data.node.meterValue}</td>
                                                                    <td style={{ position: 'relative' }}>{data.node.remark}
                                                                        <span style={{ position: 'absolute', right: 10 }}>
                                                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_water_meter_edit' }) &&
                                                                                <div className="dropdown printDoc">
                                                                                    <img 
                                                                                        className='dropdown-toggle'
                                                                                        data-toggle="dropdown"
                                                                                        src={process.env.PUBLIC_URL + "/images/icons/choiceNoBG.png"} />
                                                                                    {/* <button type="button" className="btn-primary-scale-outline floatRight dropdown-toggle" data-toggle="dropdown">
                                                                                        พิมพ์เอกสาร
                                                                                    </button> */}
                                                                                    <div className="dropdown-menu">
                                                                                        <a className="dropdown-item" target={"_blank"}
                                                                                            onClick={() => this.onEditMeter(data)}>{t('meter:Edit')}</a>
                                                                                        <a className="dropdown-item" target={"_blank"}
                                                                                            onClick={() => this.onDeleteItem(data)}>{t('meter:Delete')}</a>
                                                                                    </div>
                                                                                </div>

                                                                                // <img src={process.env.PUBLIC_URL + "/images/icons/choiceNoBG.png"} />
                                                                            }
                                                                        </span>

                                                                        {/* onClick={() => this.onDeleteItem(data)} */}
                                                                        {/* onClick={() => this.onEditMeter(data)} */}


                                                                    </td>

                                                                    {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_water_meter_edit' }) &&
                                                                        <React.Fragment>
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + '/images/icons/edit.png'}
                                                                                alt="delete-icon" className="show-icon"
                                                                            />
                                                                            <span
                                                                                className="cursor grey-color show-icon ml-1 underline hover"
                                                                                onClick={() => this.onEditMeter(data)}>{t('meter:Edit')}</span>
                                                                        </React.Fragment>
                                                                    } */}
                                                                    {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_water_meter_delete' }) &&

                                                                        <React.Fragment>
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                alt="delete-icon" className="show-icon"
                                                                            />
                                                                            <span
                                                                                className="cursor grey-color show-icon ml-1 underline hover"
                                                                                onClick={() => this.onDeleteItem(data)}>{t('meter:Delete')}</span>
                                                                        </React.Fragment>


                                                                    } */}
                                                                </tr>
                                                            )
                                                        })}

                                                    </tbody>
                                                </table>
                                            </form>
                                        </div>

                                        <Pagination changePage={this.changePage} first={this.state.first}
                                            last={this.state.last}
                                            totalCount={this.state.data_list.totalCount} />

                                        <div style={{ height: 200 }} />
                                    </React.Fragment>

                                }
                                {!this.state.data_list && <Loading />}
                            </div>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        );
    }
}

export default WaterMeterListHistory;
