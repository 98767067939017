import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import AcceptCreditNoteList from './acceptCreditNoteList';
import AcceptCreditNoteCreate from './acceptCreditNoteCreate';
import AcceptCreditNoteView from './acceptCreditNoteView';


class AcceptCreditNote extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={AcceptCreditNoteList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status/:id?`} component={AcceptCreditNoteCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id`} component={AcceptCreditNoteView}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default AcceptCreditNote;