import React, { Component } from 'react';
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Wrapper from "../../components/wrapper";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import WaterMeterRecord from "./waterMeterListRecord";
import WaterMeterCompare from "./waterMeterListCompare";
import WaterMeterListHistory from "./waterMeterListHistory";
import { Translation } from 'react-i18next';
import WaterMeterListHistoryByRoom from './waterMeterListHistoryByRoom';
import WaterMeterListCreateQrCode from './waterMeterListCreateQrCode';

class WaterMeterList extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <React.Fragment> 
                                <div className="container-fluid box">
                                   
                                    {/* <Navigation /> */}
                                    {/*component*/}
                                    {this.props.match.params.status === "record" &&
                                        <WaterMeterRecord />
                                    }
                                    {this.props.match.params.status === "compare" &&
                                        <WaterMeterCompare />
                                    }
                                    {this.props.match.params.status === "history" &&
                                        <WaterMeterListHistory />
                                    }
                                    {this.props.match.params.status === "viewHistory" &&
                                        <WaterMeterListHistoryByRoom />
                                    }
                                    {this.props.match.params.status === "createQRocde" &&
                                        <WaterMeterListCreateQrCode />
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </Translation>


                </WrapperContent>
            </Wrapper>
        );
    }
}

export default WaterMeterList;
