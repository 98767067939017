import React, {useState} from 'react';
import './customerDetailModal.scss';
import Modal from 'react-modal'
import numberWithComma from '../../../../../libs/numberWithComma';
import { Translation } from "react-i18next";


const CustomerDetailModal = ({closeBatchDataHandler, showModal, receiveCash, allData, receive, allAmountSummary})=> {
    const customStyles = {
        content: {
            width: '60vw',
            height: '60vh',
            margin: 'auto',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)' 
        }
    }

    const [loading, setLoading] = useState(false)
    

    const handleReceiveCash = () => {
        setLoading(true)
        receiveCash()
    }

    return (
        <Modal 
            isOpen={loading ? loading : showModal}
            onRequestClose={closeBatchDataHandler}
            style={customStyles}
        >
        <Translation>{ 
            t=>
            <div id="customerDetail">
                <div>
                    <div className='d-flex flex-row justify-content-between'>
                        <h3>{t('batchRt:Customer Information')}</h3>
                        <img 
                            src={process.env.PUBLIC_URL + '/images/icons/crossIcon.svg'} 
                            alt="crossIcon" 
                            className="closeModalBtn"
                            onClick={closeBatchDataHandler}    
                        />
                    </div>
                    <div className='table-responsive fade-up card mt-2'>
                        <table className="table table-hover customerTable">
                            <thead className='thead-light'>
                                <tr>
                                    <th className='text-left'>{t('batchRt:Room No.')}</th>
                                    <th className='text-left'>{t('batchRt:Fistname - Lastname')}</th>
                                    <th className='text-center'>{t('batchRt:Invoice No.')}</th>
                                    <th className='text-center'>{t('batchRt:Amount')}</th>
                                </tr>
                            </thead>
                            <tbody className='customerTableBody'>
                                {
                                    allData.map((data, index)=> {
                                        let nameNumber 
                                        let fullName
                                        if(index > 0 && allData[index - 1].node.contact.name === data.node.contact.name) {
                                            nameNumber = ""
                                            fullName  = ""
                                        } else {
                                            nameNumber = data.node.contact.name
                                            fullName  = `${data.node.contact.firstName} ${data.node.contact.lastName}`
                                        }                                
                                        return <tr key={index}>
                                            <td className='text-left'>{nameNumber}</td>
                                            <td className='text-left'>{fullName}</td>
                                            <td className='text-center'>{data.node.docNumber}</td>
                                            <td className='text-center'>{numberWithComma(data.node.allAmountNotPaid)}</td>
                                        </tr>
                                    })
                                }
                                <tr>                                    
                                    <td></td>
                                    <td></td>
                                    <td className='text-center'>{t('batchRt:Total Balance')}</td>
                                    <td className='text-center'>{numberWithComma(allAmountSummary)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
                {receive &&                
                <div className='d-flex flex-row justify-content-end pt-5 pb-5'>
                    <button 
                        className='cancelBtn mr-2'
                        disabled={loading}    
                    >{loading && <span className="spinner-border spinner-border-sm align-middle mr-2" />} {t('batchRt:Cancel')}</button>
                    <button 
                        className='receiveCashBtn' 
                        onClick={handleReceiveCash}
                        disabled={loading}
                    >{loading && <span className="spinner-border spinner-border-sm align-middle mr-2" />} {t('batchRt:Receive Payment')}</button>
                </div>}
            </div>
        }</Translation>
        </Modal>
    )
}

export default CustomerDetailModal