/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DateRange = {|
  start: any,
  end: any,
|};
export type payListTableQueryVariables = {|
  dateRanges: $ReadOnlyArray<?DateRange>,
  typeOfReport?: ?string,
|};
export type payListTableQueryResponse = {|
  +reportCashBasisExpense: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +nameEn: ?string,
        +chartOfAccountCode: string,
        +data: ?$ReadOnlyArray<?number>,
      |}
    |}>
  |}
|};
export type payListTableQuery = {|
  variables: payListTableQueryVariables,
  response: payListTableQueryResponse,
|};
*/


/*
query payListTableQuery(
  $dateRanges: [DateRange]!
  $typeOfReport: String
) {
  reportCashBasisExpense(dateRanges: $dateRanges, typeOfReport: $typeOfReport, typeOfPay: "pay") {
    edges {
      node {
        id
        name
        nameEn
        chartOfAccountCode
        data
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateRanges"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "typeOfReport"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dateRanges",
        "variableName": "dateRanges"
      },
      {
        "kind": "Literal",
        "name": "typeOfPay",
        "value": "pay"
      },
      {
        "kind": "Variable",
        "name": "typeOfReport",
        "variableName": "typeOfReport"
      }
    ],
    "concreteType": "CashBasisConnection",
    "kind": "LinkedField",
    "name": "reportCashBasisExpense",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CashBasisEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CashBasis",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "chartOfAccountCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "data",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "payListTableQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "payListTableQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "acf8f1accb159697b2d9734bc4b7ee77",
    "id": null,
    "metadata": {},
    "name": "payListTableQuery",
    "operationKind": "query",
    "text": "query payListTableQuery(\n  $dateRanges: [DateRange]!\n  $typeOfReport: String\n) {\n  reportCashBasisExpense(dateRanges: $dateRanges, typeOfReport: $typeOfReport, typeOfPay: \"pay\") {\n    edges {\n      node {\n        id\n        name\n        nameEn\n        chartOfAccountCode\n        data\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '14fa2028f511b8a7c60abd856edbfbd1';

module.exports = node;
