import * as React from "react"

const iconDelte = () => (
    <svg width={44} height={44} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width={44} height={44} rx={4} fill="#F5F5F5" />
        <path
            d="M23.782 10.334c.092.028.183.06.276.085a2.186 2.186 0 0 1 1.59 2.07c.005.243 0 .487 0 .76h3.92c1.138 0 1.912.779 1.915 1.92v2.346c0 .556-.284.836-.844.837h-.282a6873.45 6873.45 0 0 0-.452 9.415c-.061 1.304-.112 2.61-.198 3.913-.072 1.1-.995 1.954-2.098 1.98-.51.012-1.018.004-1.527.004H16.56c-1.15 0-2.064-.72-2.238-1.803-.076-.47-.073-.954-.096-1.43-.115-2.389-.228-4.777-.338-7.166-.075-1.6-.152-3.2-.23-4.8a1.031 1.031 0 0 0-.018-.111h-.385c-.412-.014-.725-.313-.73-.723-.006-.88-.014-1.762.005-2.643.022-.954.825-1.727 1.795-1.732 1.253-.01 2.506-.003 3.758-.003h.274v-.646c.006-1.146.676-1.98 1.79-2.234a.34.34 0 0 0 .079-.04h3.556Zm-8.645 8.032c0 .211-.008.4 0 .59.114 2.435.23 4.87.348 7.302.081 1.705.158 3.413.244 5.119.029.586.298.83.88.83h10.776c.598 0 .861-.243.891-.844.1-2.047.193-4.095.29-6.142.084-1.768.17-3.535.256-5.301.024-.514.045-1.027.069-1.554H15.137Zm14.886-1.479v-1.616c0-.468-.093-.563-.554-.563H14.392c-.27.009-.408.14-.41.409-.004.54 0 1.078 0 1.617.003.051.009.102.018.153h16.023Zm-5.834-3.645c0-.274.01-.524 0-.773a.712.712 0 0 0-.706-.674c-.986-.008-1.973-.008-2.96 0a.7.7 0 0 0-.693.608c-.028.275-.006.555-.006.84l4.364-.001Z"
            fill="gray"
        />
        <path
            d="M19.087 25.29v4.693c0 .544-.493.9-.974.72a.724.724 0 0 1-.48-.681c-.006-.334 0-.67 0-1.003v-8.406c0-.577.487-.943.987-.752.304.116.47.382.47.758l-.003 4.671ZM22.732 25.272v4.692c0 .56-.49.924-.98.738a.73.73 0 0 1-.476-.708c-.003-.592 0-1.185 0-1.777v-7.61c0-.575.49-.94.992-.745.294.114.465.38.465.738V25.272ZM26.378 25.304v4.67c0 .33-.14.575-.438.71-.284.13-.548.074-.783-.131-.182-.162-.24-.374-.24-.614.004-1.268 0-2.537 0-3.805v-5.536c0-.57.503-.933 1-.73.292.116.457.382.457.745.004 1.563.005 3.127.004 4.69Z"
            fill="gray"
        />
    </svg>
)

const iconClose = () => (
    <svg width={376} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", left: 30 , zIndex: -1 }}>
        <rect width={376} height={48} rx={4} fill="#F5F5F5" />
        {/* <path
            d="M182.904 30.16c-1.216 0-2.154-.288-2.816-.864-.65-.576-.976-1.43-.976-2.56V21.36h1.312v5.472c0 .779.214 1.344.64 1.696.427.341 1.04.512 1.84.512s1.414-.17 1.84-.512c.427-.352.64-.917.64-1.696V18.8h1.312v7.936c0 1.13-.33 1.984-.992 2.56-.65.576-1.584.864-2.8.864Zm-4.156-10.256.08-.944h5.36v.944h-5.44Zm13.328 10.256c-.661 0-1.253-.155-1.776-.464-.522-.31-.933-.784-1.232-1.424-.298-.65-.448-1.488-.448-2.512 0-1.408.352-2.517 1.056-3.328.704-.821 1.792-1.232 3.264-1.232.939 0 1.696.165 2.272.496.587.32 1.014.741 1.28 1.264.267.523.4 1.083.4 1.68V30h-1.312v-5.344c0-.448-.085-.848-.256-1.2-.17-.352-.453-.63-.848-.832-.384-.203-.906-.304-1.568-.304-1.066 0-1.829.299-2.288.896-.458.597-.688 1.445-.688 2.544 0 1.216.198 2.07.592 2.56.406.48.998.72 1.776.72.203 0 .4-.01.592-.032.203-.021.374-.053.512-.096V30c-.352.107-.794.16-1.328.16Z"
            fill="gray"
        /> */}
    </svg>
)

const iconAdd = () => (
    <svg width={44} height={44} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width={44} height={44} rx={4} fill="#F5F5F5" />
        <path
            d="M33.664 22.183c-.185.544-.573.736-1.139.734-3.104-.013-6.207-.007-9.314-.007h-.299v.278c0 3.107-.007 6.213.007 9.32 0 .562-.191.949-.736 1.134h-.364c-.545-.185-.738-.572-.735-1.135.013-3.098.006-6.198.006-9.296v-.3h-.277c-3.113 0-6.226-.007-9.34.006-.564 0-.952-.19-1.14-.734v-.364c.186-.543.574-.736 1.14-.733 3.105.012 6.21.006 9.316.006h.298v-9.691c0-.301.058-.575.285-.785.275-.252.6-.326.95-.192.351.134.545.402.584.773.007.09.01.182.006.273v9.624h.276c3.113 0 6.226.006 9.34-.006.564 0 .952.19 1.139.733l-.003.362Z"
            fill="gray"
        />
    </svg>
)

const iconStep1 = () => (
    <svg width={346} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m17.383 10.02-4.663 5.595-2.208-2.21-.007-.006a1.667 1.667 0 0 0-2.356 2.357l.006.006 3.5 3.5a1.666 1.666 0 0 0 1.919.314l-.222-.448.222.448c.207-.103.391-.248.54-.426l5.83-6.996a1.669 1.669 0 0 0-.193-2.372 1.666 1.666 0 0 0-2.368.239ZM1.667 14C1.666 7.19 7.187 1.667 14 1.667c6.811 0 12.333 5.522 12.333 12.333S20.811 26.333 14 26.333C7.188 26.333 1.666 20.811 1.666 14Z"
            fill="#1983E8"
            stroke="#1983E8"
        />
        <path
            stroke="#1983E8"
            strokeWidth={4}
            strokeLinecap="square"
            d="M344 14H38"
        />
    </svg>
)

const iconStep1_2 = () => (
    <svg width={346} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity={0.4} stroke="#1983E8">
            <path
                d="m17.383 10.02-4.663 5.595-2.208-2.21-.007-.006a1.667 1.667 0 0 0-2.356 2.357l.006.006 3.5 3.5a1.666 1.666 0 0 0 1.919.314l-.222-.448.222.448c.207-.103.391-.248.54-.426l5.83-6.996a1.669 1.669 0 0 0-.193-2.372 1.666 1.666 0 0 0-2.368.239ZM1.667 14C1.666 7.19 7.187 1.667 14 1.667c6.811 0 12.333 5.522 12.333 12.333S20.811 26.333 14 26.333C7.188 26.333 1.666 20.811 1.666 14Z"
                fill="#1983E8"
            />
            <circle cx={14} cy={14} r={12} fill="#fff" strokeWidth={2} />
            <path strokeWidth={4} strokeLinecap="square" d="M344 14H38" />
        </g>
    </svg>
)

const iconStep1_3 = () => (
    <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m17.383 10.02-4.663 5.595-2.208-2.21-.007-.006a1.667 1.667 0 0 0-2.356 2.357l.006.006 3.5 3.5a1.666 1.666 0 0 0 2.458-.112l5.83-6.996a1.669 1.669 0 0 0-.192-2.372 1.667 1.667 0 0 0-2.368.239ZM1.667 14C1.666 7.19 7.187 1.667 14 1.667c6.811 0 12.333 5.522 12.333 12.333S20.811 26.333 14 26.333C7.188 26.333 1.666 20.811 1.666 14Z"
            fill="#1983E8"
            stroke="#1983E8"
        />
        <circle
            cx={14}
            cy={14}
            r={12}
            fill="#fff"
            strokeWidth={2}
        />
    </svg>
)

const iconStep2 = () => (
    <svg width={346} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m17.383 10.02-4.662 5.595-2.21-2.21-.005-.006a1.667 1.667 0 0 0-2.357 2.357l.006.006 3.5 3.5a1.666 1.666 0 0 0 2.458-.112l5.83-6.996a1.665 1.665 0 1 0-2.56-2.133ZM1.667 14C1.667 7.19 7.189 1.667 14 1.667S26.333 7.189 26.333 14 20.811 26.333 14 26.333 1.667 20.811 1.667 14Z"
            fill="#1983E8"
            stroke="#1983E8"
        />
        <path
            stroke="#1983E8"
            strokeWidth={4}
            strokeLinecap="square"
            strokeDasharray="10 10"
            d="M344 14H38"
        />
    </svg>
)

const iconStep2_1 = () => (
    <svg width={346} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m17.383 10.02-4.662 5.595-2.21-2.21-.005-.006a1.667 1.667 0 0 0-2.357 2.356l.006.007 3.5 3.5a1.669 1.669 0 0 0 1.92.314l-.223-.448.222.448c.207-.103.391-.248.54-.426l5.83-6.996a1.665 1.665 0 1 0-2.56-2.133ZM1.667 14C1.667 7.188 7.189 1.666 14 1.666S26.333 7.188 26.333 14c0 6.811-5.522 12.333-12.333 12.333S1.667 20.811 1.667 14Z"
            fill="#1983E8"
            stroke="#1983E8"
        />
        <path
            stroke="#1983E8"
            strokeWidth={4}
            strokeLinecap="square"
            d="M344 14H38"
        />
    </svg>
)


const iconStep3 = () => (
    <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m17.383 10.02-4.662 5.595-2.21-2.21-.005-.006a1.667 1.667 0 0 0-2.357 2.356l.006.007 3.5 3.5a1.667 1.667 0 0 0 1.92.314l-.223-.448.222.448c.207-.103.391-.248.54-.426l5.83-6.996a1.665 1.665 0 1 0-2.56-2.133ZM1.667 14C1.667 7.188 7.189 1.666 14 1.666S26.333 7.188 26.333 14c0 6.811-5.522 12.333-12.333 12.333S1.667 20.811 1.667 14Z"
            fill="#1983E8"
            stroke="#1983E8"
        />
    </svg>
)




export default {
    iconDelte,
    iconClose,
    iconAdd,
    iconStep1,
    iconStep1_2,
    iconStep1_3,
    iconStep2,
    iconStep2_1,
    iconStep3
}