import React from "react";
import { QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import { format } from "date-fns";
import ComponentPagination from "../../../libs/componentPagination";
import thLocale from "date-fns/locale/th";
import Pagination from "../../../libs/newPagination";
import numberWithComma from "../../../libs/numberWithComma";
import "./style/chequeListTable.scss";
import { Link } from "react-router-dom";
import getNameResidential from "../../../libs/getNameResidential";
import _ from "lodash";
import { Translation } from "react-i18next";
import Loading from "../../../libs/loading";

class ChequeDepositListTable extends ComponentPagination {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     // re_query: false,
  //     data: {},
  //   };
  //   // this.refreshQuery = this.refreshQuery.bind(this);
  // }

  // refreshQuery() {
  //   this.setState({ re_query: !this.state.re_query });
  // }


  sortList(chequeList, status) {
    if (status === "deposit") {
      return _.sortBy(chequeList, "node.docNumber").reverse();
    } else return chequeList;
  }

  render() {
    return (
      <React.Fragment>
        <div className="row" id="cheque-list">
          <div className="col">
            <QueryRenderer
              environment={environment}
              query={this.props.query}
              variables={{
                status: this.props.status,
                first: this.state.first,
                last: this.state.last,
                re_query: this.state.re_query,
                search: this.props.search,
                start_date: this.props.start_date,
                end_date: this.props.end_date,
              }}
              render={({ error, props }) => {
                if (error) {
                  return <div>{error.message}</div>;
                } else if (props) {
                  return (
                    <React.Fragment>
                      <Translation>
                        {t =>
                          <div className="table-responsive fade-up card">
                            <table className="table table-hover">
                              <thead className="thead-light">
                                <tr>
                                  {this.props.status === "deposit" && (
                                    <th className="text-center">{t("cheque:Document No.")}</th>
                                  )}
                                  {this.props.status === "deposit" && (
                                    <th className="text-center">{t("cheque:Cheque dated")}</th>
                                  )}
                                  <th className="text-center">{t("cheque:Cheque No.")}</th>
                                  <th className="text-center">{t("cheque:Cheque dated")}</th>
                                  <th className="text-center">{t("cheque:Customer name")}</th>
                                  <th className="text-center">{t("cheque:Reference document")}</th>
                                  <th className="text-center">{t("cheque:Detail")}</th>
                                  <th className="text-right">{t("cheque:Amount")}</th>
                                  {this.props.status === "all" && (
                                    <th className="text-center">{t("cheque:Status")}</th>
                                  )}
                                  {this.props.status === "not_deposit" && (
                                    <th className="text-center">{t("cheque:Sequence")}</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {props.chequeDepositViewer.allChequeDeposit.edges &&
                                  this.sortList(
                                    props.chequeDepositViewer.allChequeDeposit
                                      .edges,
                                    this.props.status
                                  ).map((cheque_deposit) => (
                                    <tr key={cheque_deposit.node.id}>
                                      {this.props.status === "deposit" && (
                                        <td className="text-center">
                                          {cheque_deposit.node.docNumber}
                                        </td>
                                      )}
                                      {this.props.status === "deposit" && (
                                        <td className="text-center">
                                          {format(
                                            cheque_deposit.node.depositDate,
                                            "DD/MM/YYYY",
                                            { locale: thLocale }
                                          )}
                                        </td>
                                      )}
                                      <td className="text-center">
                                        <Link
                                          to={
                                            "/accounting/finance/cheque-deposit/deposit/detail/" +
                                            cheque_deposit.node.id
                                          }
                                          >
                                          {cheque_deposit.node.chequeNumber}
                                        </Link>
                                      </td>
                                      <td className="text-center">
                                        {" "}
                                        {format(
                                          cheque_deposit.node.date,
                                          "DD/MM/YYYY",
                                          { locale: thLocale }
                                        )}
                                      </td>
                                      <td>
                                        {cheque_deposit.node.contact
                                          ? cheque_deposit.node.contact
                                            .typeOfContact === "RESIDENTIAL"
                                            ? getNameResidential(
                                              cheque_deposit.node.contact
                                                .firstName,
                                              cheque_deposit.node.contact.lastName
                                            )
                                            : cheque_deposit.node.contact.name
                                          : cheque_deposit.node.unknownContact ||
                                          "-"}
                                      </td>
                                      <td>{cheque_deposit.node.refNumber}</td>
                                      <td>{cheque_deposit.node.description}</td>
                                      {/*<td>{cheque_deposit.node.receive.docNumber}</td>*/}
                                      <td className="text-right">
                                        {numberWithComma(cheque_deposit.node.price)}
                                      </td>
                                      {this.props.status === "all" && (
                                        <td className="text-center">
                                          {cheque_deposit.node.status ===
                                            "NOT_DEPOSIT"
                                            ? t("cheque:Waiting for a deposit cheque")
                                            : t("cheque:Money already")}
                                        </td>
                                      )}
                                      {this.props.status === "not_deposit" && (
                                        <td width="150" className="text-center">
                                          <Link
                                            to={
                                              "/accounting/finance/cheque-deposit/deposit/detail/" +
                                              cheque_deposit.node.id
                                            }
                                            >
                                            <button
                                              type="button"
                                              disabled={
                                                !_.some(
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "permission_list"
                                                    )
                                                  ),
                                                  {
                                                    codename:
                                                      "accounting_cheque_deposit_edit",
                                                  }
                                                )
                                                  ? "disabled"
                                                  : false
                                              }
                                              className="btn btn-primary add button-deposit"
                                            >
                                              {t("cheque:Deposit this cheque")}
                                            </button>
                                          </Link>
                                          {/*<ChequeDepositModal detail={cheque_deposit} refreshQuery={this.refreshQuery}/>*/}
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        }
                      </Translation>
                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={
                            props.chequeDepositViewer.allChequeDeposit
                              .totalCount
                          }
                        />
                      </div>
                    </React.Fragment>
                  );
                }
                return <Loading/>
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChequeDepositListTable;
