/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type periodAdjustSettingQueryVariables = {|
  service: string,
  contact: string,
|};
export type periodAdjustSettingQueryResponse = {|
  +periodServicePricing: ?{|
    +unit: ?number,
    +unitPrice: ?number,
    +totalPrice: ?number,
    +description: ?string,
  |}
|};
export type periodAdjustSettingQuery = {|
  variables: periodAdjustSettingQueryVariables,
  response: periodAdjustSettingQueryResponse,
|};
*/


/*
query periodAdjustSettingQuery(
  $service: String!
  $contact: String!
) {
  periodServicePricing(service: $service, contact: $contact) {
    unit
    unitPrice
    totalPrice
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "service"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contact",
        "variableName": "contact"
      },
      {
        "kind": "Variable",
        "name": "service",
        "variableName": "service"
      }
    ],
    "concreteType": "PeriodServicePricing",
    "kind": "LinkedField",
    "name": "periodServicePricing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unitPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "periodAdjustSettingQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "periodAdjustSettingQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7f396c7f037b1f707afd2e50f77f741e",
    "id": null,
    "metadata": {},
    "name": "periodAdjustSettingQuery",
    "operationKind": "query",
    "text": "query periodAdjustSettingQuery(\n  $service: String!\n  $contact: String!\n) {\n  periodServicePricing(service: $service, contact: $contact) {\n    unit\n    unitPrice\n    totalPrice\n    description\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7fc4b317c9032c9423709a7381abcbc9';

module.exports = node;
