import React from "react";
import { QueryRenderer } from "react-relay";
import numberWithComma from "../../../../libs/numberWithComma";
import environment from "../../../../env/environment";
import { fetchQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { differenceInCalendarDays, format } from "date-fns";
import { Link } from "react-router-dom";
import Pagination from "../../../../libs/newPagination";
import ComponentPagination from "../../../../libs/componentPagination";
import Swal from "sweetalert2";
import ChangeStatusInvoiceMutation from "../invoice_detail/mutations/ChangeStatusInvoiceMutation";
import localStoragePage from "../../../../libs/localstorage";
import Navigation from "./navigation";
import getDocumentNumber from "../../../../libs/getDocumentNumber";
import getNameResidential from "../../../../libs/getNameResidential";
import getNameResidentialRent from "../../../../libs/getNameResidentialRent";
// import AdvanceFilter from "../../../../libs/advanceFilter";
import Loading from "../../../../libs/loading";
import _ from "lodash";
import InlineAdvanceFilter from "../../../../libs/inlineAdvanceFilter";
import { Translation } from "react-i18next";
import i18n from "i18next";
import thLocale from "date-fns/locale/th";
import InvoiceExportsDraftExcel from "./invoiceExportsDraftExcel";
import ExportInvoicePdf from "./server_export_invoice_pdf";
import i18next from "i18next";

const getCountInvoice = graphql`
  query invoiceListTableQuery($status: String, $search: String, $start_date : DateTime, $end_date : DateTime, $first: Int, $last: Int, $is_contact_online: Boolean) {
    invoiceViewer {
      allInvoice(status:$status, search:$search, status_In:"active, overdue, paid, partial_payment",startDate:$start_date, endDate:$end_date, first: $first, last: $last, isContactOnline: $is_contact_online) {
        totalCount
      }
    }
    selfProject{
      name
      address
      logo
    }
  }
`;

class InvoiceListTable extends ComponentPagination {
  constructor(props) {
    super(props);

    this.state.search = "";
    this.state.search_input = "";
    this.state.re_query = false;
    this.state.start_date = null;
    this.state.end_date = null;
    this.state.temp_start_date = null;
    this.state.temp_end_date = null;
    this.state.total_count_invoice = 0;
    this.state.status = props.status;
    this.state.temp_status = props.status;
    this.state.updateInvoice = props.updateInvoice;
    this.state.loading_approve = false;
    this.state.loading_delete = false;
    this.state.now_date = new Date();
    this.state.advance_search = false;
    this.state.app_status = "all";
    this.state.temp_app_status = "all";
    this.state.invoice = [];
    this.state.projectName = ""
    this.state.check_all_draft = false

    this.onClickDropdownInvoice = this.onClickDropdownInvoice.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.calTotalwithReceiptDepositUsed = this.calTotalwithReceiptDepositUsed.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onClickPrintAll = this.onClickPrintAll.bind(this);
    this.onClickVoiceAll = this.onClickVoiceAll.bind(this);
    // this.verifyInvoice = this.verifyInvoice(this);
  }

  removeTrailingZeros(description) {
    if (description.includes("ค่าน้ำประปา")) {
      description = description.replace(/ *\[[^)]*\] */g, " ");
      // description = description.replace(/ *\[[^)]*\] */g, " ").replace(/.50 /gi, ".5 ")
      // description = description.replace(/.00 /gi, " ")
    } else {
      description = description.replace(/ *\[[^)]*\] */g, " ");
    }
    return description;
  }

  componentDidUpdate(nextProps, nextState, nextContext) {
    if (this.state.status !== this.props.status) {
      this.queryCountInvoice();
      this.setState(
        {
          status: this.props.status,
          checkList: [],
          check_all: false,
        },
        () => {
          this.goFirst();
        }
      );
    }

    if (this.props.updateInvoice === true) {
      // ถ้า ไม่ได้เลือก ไม่ต้อง requery
        if(this.state.checkList.length>0){
          this.setState(
            {re_query: true})
        }
        this.setState(
          { updateInvoice: this.props.updateInvoice },
          () => {
            this.props.updateInvoiceFunction()
        }
        );
    }

    if (this.state.re_query === true) {
      this.setState(
        {
          re_query: false
        })
    }

    if (nextState.search !== this.state.search || 
      nextState.start_date !== this.state.start_date || 
      nextState.end_date !== this.state.end_date || 
      nextState.app_status !== this.state.app_status) {
      this.queryCountInvoice();
    }

  }

  componentDidMount() {
    fetchQuery(environment, getCountInvoice , { search: this.state.search,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      first: this.state.first,
      last: this.state.last,
      status: this.props.status || this.props.updateInvoice,
      re_query: this.state.re_query,
      is_contact_online:
        this.state.app_status === "all"
          ? null
          : this.state.app_status === "active", }).then((response_data) => {
      this.setState({
        projectName: response_data.selfProject.name,
        total_count_invoice: response_data.invoiceViewer.allInvoice.totalCount,
      });
    });

  }



  queryCountInvoice() {
    fetchQuery(environment, getCountInvoice , { 
      search: this.state.search,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      first: this.state.first,
      last: this.state.last,
      status: this.props.status || this.props.updateInvoice,
      re_query: this.state.re_query,
      is_contact_online:
        this.state.app_status === "all"
          ? null
          : this.state.app_status === "active", }).then((response_data) => {
      this.setState({
        total_count_invoice: response_data.invoiceViewer.allInvoice.totalCount,
      });
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }

  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      status: this.state.temp_status,
      app_status: this.state.temp_app_status,
      // updateInvoice : this.props.updateInvoice
    });
  }

  onClickDropdownInvoice(type) {

    let dateNow = new Date()

    let isOverScheduleTimeArr = []
    let checkList = []
    
    if(this.state.checkList.length > 0){
      checkList = _.cloneDeep(this.state.checkList)
    }else{
      checkList = this.props.listExportToExcel.map((e) => {return e.node.id})
    }


    _.forEach(checkList, (id) => {
      let isOverScheduleTimeFilter = _.filter(this.state.invoice, (o) =>
        o.node.scheduleTime !== null && o.node.id === id && format(new Date(o.node.scheduleTime)) < format(new Date(dateNow)))[0]
      if (isOverScheduleTimeFilter?.node) {
        isOverScheduleTimeArr.push(isOverScheduleTimeFilter)
      }
    })

    if (
      checkList.length !== 0 &&
      !this.state.loading_approve &&
      !this.state.loading_delete
    ) {
      if (isOverScheduleTimeArr.length > 0 && type === "approve") {
        Swal.fire({
          title: "ไม่สามารถอนุมัติแบบร่างได้!",
          text: "เนื่องจากมีใบแจ้งหนี้เกินวัน/เวลา ที่ตั้งเวลาไว้ กรุณาลบรายการดังกล่าวจำนวน " + isOverScheduleTimeArr.length + " ใบ และออกใบแจ้งหนี้ใหม่อีกครั้ง ",
          type: "warning",
          showCancelButton: false
        })
        this.setState({ loading_approve: false });
      } else {
        let title = "";
        let status = "";
        // let success_text = "";

        if (type === "approve") {
          this.setState({ loading_approve: true });
          title = i18n.t('invoice:Are you sure to approve the invoice?');
          status = "active";
          // success_text = i18n.t('invoice:invoice_approved');
        } else {
          this.setState({ loading_delete: true });
          title = i18n.t('invoice:Are you sure to delete the invoice?');
          status = "delete";
          // success_text = i18n.t('invoice:invoice_deleted');
        }
        Swal.fire({
          title: title,
          text: i18n.t('invoice:total invoice') + checkList.length + i18n.t('invoice:items'),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: i18n.t('invoice:yes'),
          cancelButtonText: i18n.t('invoice:no'),
        }).then((result) => {
          if (result.value) {
            let input = {
              invoiceList: checkList,
              status: status,
            };
            ChangeStatusInvoiceMutation(
              input,
              this.onSuccess,
              this.onError
            );
          } else {
            this.setState({ loading_approve: false, loading_delete: false });
          }
        });
      }

    } else if (this.state.loading_approve || this.state.loading_delete) {
      Swal.fire({
        title: i18n.t('invoice:Please wait a moment.'),
        type: "warning",
      });
    } else {
      Swal.fire({
        title: i18n.t('invoice:Please select an item'),
        type: "warning",
      });
    }
  }

  onSuccess(response) {

    this.setState({ loading_approve: false, loading_delete: false });
    if (response.updateInvoice.ok) {
      Swal.fire(i18n.t('invoice:success'), response, "success");
    } else {
      if (response.updateInvoice.warningText) {
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.updateInvoice.warningText, "warning");
      }
      else {
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
      }
    }
    this.queryCountInvoice();
    this.setState({
      checkList: [],
      check_all: false,
      re_query: !this.state.re_query,
    }, () => this.props.clearAllListSelect());
    
    this.changePage({
      first: localStoragePage.getPageLimit(),
      last: localStoragePage.getPageLimit(),
    });
  }

  onError() {
    this.setState({ loading_approve: false, loading_delete: false });
    Swal.fire("Error", "", "error");
  }

  calTotalwithReceiptDepositUsed(total, invoice) {
    let amountUse = 0;
    invoice.contact.receiptDeposit.edges.forEach((rd_group) => {
      rd_group.node.receiptDepositTransaction.edges.forEach((rd_trans) => {
        rd_trans.node.receiptDepositTransactionUsed.edges.forEach((rd_use) => {
          if (rd_use.node.transaction.invoice.id === invoice.id) {
            amountUse += rd_use.node.amount;
          }
        });
      });
    });

    return total - amountUse;
  }

  onClickPrintAll() {
    if (this.state.checkList.length <= 0) {
      Swal.fire(i18n.t('invoice:Please select an item'), "", "warning");
    }
  }

  verifyInvoice(checkList, invoice) {
    let checkList_delete_docnumber = [];
    invoice.forEach((reslut) => {
      if (checkList.includes(reslut.node.id)) {
        reslut.node.transaction.edges.forEach((transaction) => {
          if (transaction.node.receiveTransaction.edges?.length > 0) {
            let index = checkList.indexOf(reslut.node.id);
            if (index > -1) {
              checkList.splice(index, 1);
            }
            checkList_delete_docnumber.push(reslut.node.docNumber);
          }
          if (transaction.node.creditNoteTransaction.edges?.length > 0) {
            let index = checkList.indexOf(reslut.node.id);
            if (index > -1) {
              checkList.splice(index, 1);
            }
            checkList_delete_docnumber.push(reslut.node.docNumber);
          }
        });

        if (reslut.node.status === "VOID") {
          let index = checkList.indexOf(reslut.node.id);
          if (index > -1) {
            checkList.splice(index, 1);
          }
        }
      }
    });
    return [checkList, checkList_delete_docnumber];
  }

  onClickVoiceAll() {
    if (this.state.checkList.length <= 0) {
      Swal.fire(i18n.t('invoice:Please select an item'), "", "warning");
    } else {
      if (
        this.state.checkList.length !== 0 &&
        !this.state.loading_approve &&
        !this.state.loading_delete
      ) {
        let title = "";
        let status = "";
        // let success_text = "";
        this.setState({ loading_delete: true });

        title = i18n.t('invoice:Are you sure to cancel the invoice?');
        status = "void";
        // success_text = i18n.t('invoice:Already canceled this invoice');;

        let checkList = this.verifyInvoice(
          this.state.checkList,
          this.state.invoice
        );

        if (checkList[1].length > 0) {
          let warning_text = "";
          checkList[1].forEach((result, index) => {
            if (index + 1 === checkList[1].length) {
              warning_text = warning_text + result;
            } else {
              warning_text = warning_text + " " + result + ",";
            }
          });
          // warning_text = warning_text + " รายการบันทึกรับหรือลดหนี้ไปแล้ว"
          Swal.fire({
            title:
              i18n.t('invoice:Failed to cancel some invoice!') + i18n.t('invoice:Already record of debt received or reduced'),
            text: warning_text,
            type: "warning",
            // footer: '<h2><b>รายการบันทึกรับหรือลดหนี้ไปแล้ว</b></h2>',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: i18n.t('invoice:continue'),
            cancelButtonText: i18n.t('invoice:cancel'),
          }).then(async (result) => {
            if (checkList[0].length > 0) {
              if (result.value) {
                let { value: void_remark } = await Swal.fire({
                  title: i18n.t('invoice:note'),
                  input: "text",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: i18n.t('invoice:cancel'),
                  confirmButtonText: i18n.t('invoice:confirm'),
                  inputValidator: (value) => {
                    if (!value) {
                      return i18n.t('invoice:Please enter a cancellation note.')
                    }
                  },
                });
                if (void_remark) {
                  let input = {
                    invoiceList: checkList[0],
                    status: status,
                    voidRemark: void_remark,
                  };
                  ChangeStatusInvoiceMutation(
                    input,
                    this.onSuccess,
                    this.onError
                  );
                } else {
                  this.setState({
                    loading_approve: false,
                    loading_delete: false,
                  });
                }
              } else {
                this.setState({
                  loading_approve: false,
                  loading_delete: false,
                });
              }
            } else if (result.dismiss) {
              this.setState({
                loading_approve: false,
                loading_delete: false,
                check_all: false,
              });
            } else {
              Swal.fire({
                title: i18n.t('invoice:No data'),
                type: "warning",
              });
              this.setState({
                loading_approve: false,
                loading_delete: false,
                check_all: false,
              });
            }
          });
        } else {
          Swal.fire({
            title: title,
            text: i18n.t('invoice:total invoice') + checkList[0].length + i18n.t('invoice:items'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: i18n.t('invoice:yes'),
            cancelButtonText: i18n.t('invoice:cancel'),
          }).then(async (result) => {
            if (result.value) {
              let { value: void_remark } = await Swal.fire({
                title: i18n.t('invoice:note'),
                input: "text",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: i18n.t('invoice:cancel'),
                confirmButtonText: i18n.t('invoice:confirm'),
                inputValidator: (value) => {
                  if (!value) {
                    return i18n.t('invoice:Please enter a cancellation note.');
                  }
                },
              });
              if (void_remark) {
                let input = {
                  invoiceList: checkList[0],
                  status: status,
                  voidRemark: void_remark,
                };
                ChangeStatusInvoiceMutation(
                  input,
                  this.onSuccess,
                  this.onError
                );
              } else {
                this.setState({
                  loading_approve: false,
                  loading_delete: false,
                });
              }
            } else {
              this.setState({ loading_approve: false, loading_delete: false });
            }
          });
        }
      } else if (this.state.loading_approve || this.state.loading_delete) {
        Swal.fire({
          title: i18n.t('invoice:Please wait a moment.'),
          type: "warning",
        });
      } else {
        Swal.fire({
          title: i18n.t('invoice:Please select an item'),
          type: "warning",
        });
      }
    }
  }

  getColorAndText(invoice) {
    let text = "";
    let color = "text-left";
    switch (invoice.node.status) {
      case "DRAFT":
        text = 'draft';
        break;
      case "ACTIVE":
        text = 'waiting_for_payment';
        break;
      case "OVERDUE":
        text = 'overdue';
        color = "text-left text-danger";
        break;
      case "PAID":
        text = 'paid';
        break;
      case "PARTIAL_PAYMENT":
        text = 'partial_payment';
        break;
      case "WAITING_FIX":
        text = 'pending_edit_list';
        break;
      default:
        text = 'canceled_list';
        color = "text-left text-danger";
    }
    return <Translation>{t => <td className={color}>{t('invoice:' + text)}</td>}</Translation>;
  }

  render() {
    return (
      <React.Fragment>
        <Navigation total_count_invoice={this.state.total_count_invoice} />
        <div className="row mb-2 mt-1">
          <div className="col fixRequestFast">
            {!(this.props.status === "void") && 
            (
                <React.Fragment>
                  {this.props.status !== "draft" && this.props.status !== "waiting_fix" ? (
                    <React.Fragment>
                      {_.some(
                        JSON.parse(localStorage.getItem("permission_list")),
                        { codename: "accounting_invoice_print" }
                      ) && !(this.props.status === "waiting_fix") ? (
                        this.state.checkList.length > 0 ? (
                          <Link
                            to={{
                              pathname: "/document/invoice/all/",
                              state: { checkList: this.state.checkList },
                            }}
                          >
                            <button
                              type="button"
                              style={{float: 'left'}}
                              className="btn btn-outline-secondary"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/icons/print-icon.png"
                                }
                                alt="print-icon"
                              />
                            </button>
                          </Link>
                        ) : (
                          <button
                            style={{float: 'left'}}
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={this.onClickPrintAll}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icons/print-icon.png"
                              }
                              alt="print-icon"
                            />
                          </button>
                        )
                      ) : null}

                      {/* Voice แบบ list */}
                      {_.some(
                        JSON.parse(localStorage.getItem("permission_list")),
                        { codename: "accounting_invoice_delete" }
                      ) ? (
                        <button
                          type="button"
                          style={{float: 'left'}}
                          className="btn btn-outline-secondary ml-2"
                          onClick={this.onClickVoiceAll}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/icons/delete.png"
                            }
                            alt="delete"
                          />
                        </button>
                      ) : null}

                    {/* {_.some(JSON.parse(localStorage.getItem("permission_list")),{ codename: "accounting_invoice_print" } ) && 
                      <Link
                        to={{
                          pathname: "/document/invoice/getall/",
                          state: { 
                            start_date: this.state.start_date,
                            end_date: this.state.end_date, 
                            search: this.state.search, 
                            status: this.state.status, 
                            app_status: this.state.app_status, 
                          },
                        }}
                      >
                      <button type="button" className="btn-primary-scale-outline ml-2" ><Translation>{t => t('invoice:Printall')}</Translation></button>
                      </Link>
                      } */}
                    
                    {/* DownloadFile RQ212 */}
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: "accounting_invoice_print", }) &&  this.props.status !== "draft" && this.props.status !== "waiting_fix" && <ExportInvoicePdf 
                    state={this.state} 
                    status={this.props.status} 
                    // totalCount={props.invoiceViewer.allInvoice.totalCount}
                    />}

                    </React.Fragment>
                  ) : (
                    <React.Fragment>

                      {this.props.status !== "waiting_fix" && <InvoiceExportsDraftExcel
                        state={this.state}
                        listAllExportToExcel={this.props.listAllExportToExcel}
                        listExportToExcel={this.props.listExportToExcel}
                        check_all_draft={this.props.check_all_draft}
                        query={this.props.query}
                      />}

                      {_.some(
                        JSON.parse(localStorage.getItem("permission_list")),
                        { codename: "accounting_invoice_approve" }
                      ) && this.props.status !== "waiting_fix" && (
                          <button
                            type="button"
                            className="btn btn-light border mr-3"
                            onClick={() => this.onClickDropdownInvoice("approve")}
                            disabled={
                              this.state.loading_approve ||
                              this.state.loading_delete
                            }
                          >
                            {this.state.loading_approve && (
                              <span className="spinner-border spinner-border-sm align-middle mr-2" />
                            )}{" "}
                            <Translation>{t => t('invoice:approve_items')}</Translation>
                          </button>
                        )}

                      {_.some(
                        JSON.parse(localStorage.getItem("permission_list")),
                        { codename: "accounting_invoice_delete" }
                      ) && (
                          <button
                            type="button"
                            className="btn btn-light border"
                            onClick={() => this.onClickDropdownInvoice("void")}
                            disabled={
                              this.state.loading_approve ||
                              this.state.loading_delete
                            }
                          >
                            {this.state.loading_delete && (
                              <span className="spinner-border spinner-border-sm align-middle mr-2" />
                            )}
                            <Translation>{t => t('invoice:delete_items')}</Translation>
                          </button>
                        )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {this.props.status === "waiting_fix" && 
                <div className="align-middle mt-4" style={{width:"110%"}}>
                  <span className="text-red text-left ml-2"><Translation>{t => t('invoice:The items pending correction are those with insufficient stock. Please recheck the inventory of these items.')}</Translation></span>
                </div>
              }
          </div>

          <div className="col-7 text-right">
            <div className="input-group float-right w-auto ml-2 mb-2">
              <Translation>
                {t =>
                  <input
                    type="text"
                    className="form-control input-size col-8"
                    placeholder={t('filterFunction:search')}
                    value={this.state.search_input}
                    name="search"
                    onChange={(e) =>
                      this.setState({ search_input: e.target.value })
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                }
              </Translation>

              <button
                type="submit"
                className="btn btn-primary form-control search-button text-center"
                name="search"
                value={this.state.search_input}
                onClick={(e) => this.handleSearch(e)}
              >
                <Translation>{t => t('filterFunction:search')}</Translation>
              </button>

            </div>

            {/* <button
              type="button"
              className="btn btn-outline-secondary float-right"
              onClick={() =>
                this.setState({ advance_search: !this.state.advance_search })
              }
            >
              <img src="/images/icons/filter-icon.png" alt="filter-icon" />
            </button> */}
            <InlineAdvanceFilter
              // show_filter={this.state.advance_search}
              start_date={this.state.temp_start_date}
              end_date={this.state.temp_end_date}
              handleChange={this.handleChange}
              app_status
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            
            <QueryRenderer
              environment={environment}
              query={this.props.query}
              cacheConfig={{ use_cache: false }}
              variables={{
                search: this.state.search,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                first: this.state.first,
                last: this.state.last,
                status: this.props.status || this.props.updateInvoice,
                re_query: this.state.re_query,
                is_contact_online:
                  this.state.app_status === "all"
                    ? null
                    : this.state.app_status === "active",
              }}
              render={({ error, props }) => {
                if (error) {
                  return <div>{error.message}</div>;
                } else if (props) {
                  this.state.invoice = props.invoiceViewer.allInvoice.edges;
                  this.state.scheduleTimeInvoice = props.invoiceViewer.allInvoice.edges;
                  return (
                    <React.Fragment>
                      <div className="table-responsive fade-up card">
                        <table className="table table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th>
                                {!(this.props.status === "void"
                                ) && (
                                    this.props.status === "draft" ?
                                      <input type="checkbox"
                                        onChange={(e) => this.props.getAllListSelect(e, props.invoiceViewer.allInvoice.edges)}
                                        checked={this.props.check_all_draft}
                                      />
                                      :
                                      <input type="checkbox"
                                        onChange={() => this.getAllList(props.invoiceViewer.allInvoice.edges)}
                                        checked={this.state.check_all}
                                      />
                                  )}
                              </th>
                              <th className="text-center" width={130}>
                                <Translation>{t => t('invoice:no.')}</Translation>
                              </th>
                              <th className="text-center"><Translation>{t => t('invoice:date')}</Translation></th>
                              <th className="text-center"><Translation>{t => t('invoice:due_date')}</Translation></th>
                              <th className="text-center" width={130}><Translation>{t => t('invoice:schedule_time')}</Translation></th>
                              {/*<th className="text-center">รหัส</th>*/}
                              <th className="text-center"><Translation>{t => t('invoice:room_no')}</Translation></th>
                              <th><Translation>{t => t('invoice:name')}</Translation></th>
                              <th><Translation>{t => t('invoice:detail')}</Translation></th>
                              <th className="text-right"><Translation>{t => t('invoice:amount')}</Translation></th>
                              {/*<th className="text-right">จำนวนเงินรวม</th>*/}
                              {this.props.status === "paid" && (
                                <th className="text-center"><Translation>{t => t('invoice:payment_method')}</Translation></th>
                              )}

                              {this.props.status === "partial_payment" && (
                                <React.Fragment>
                                  <th className="text-right">
                                    <Translation>{t => t('invoice:amount_received')}</Translation>
                                  </th>
                                  <th className="text-right">
                                    <Translation>{t => t('invoice:amount_owed')}</Translation>
                                  </th>
                                </React.Fragment>
                              )}

                              {this.props.status === "overdue" && (
                                <React.Fragment>
                                  <th className="text-center">
                                    <Translation>{t => t('invoice:overdue_day')}</Translation>
                                  </th>
                                </React.Fragment>
                              )}

                              {this.props.status === "all" && (
                                <th className="text-left"><Translation>{t => t('invoice:status')}</Translation></th>
                              )}
                              {this.props.status === "void" && (
                                <th className="text-center"><Translation>{t => t('invoice:cancellation_date')}</Translation></th>
                              )}
                              <th className="text-center"><Translation>{t => t('invoice:by')}</Translation></th>
                              {/*<th className="text-center">วันที่สร้าง</th>*/}
                              {this.props.status === "void" && (
                                <React.Fragment>
                                  <th className="text-center"><Translation>{t => t('invoice:canceled_by')}</Translation></th>
                                  <th className="text-center">
                                    <Translation>{t => t('invoice:reason_for_cancellation')}</Translation>
                                  </th>
                                </React.Fragment>
                              )}
                              {(this.props.status === "partial_payment" ||
                                this.props.status === "paid" ||
                                this.props.status === "all") && (
                                  <th className="text-center">
                                    <Translation>{t => t('invoice:receipt_no')}</Translation>
                                  </th>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {props.invoiceViewer.allInvoice.edges.map(
                              (invoice, index) => {
                                let order_id = this.state.first - localStoragePage.getPageLimit();
                                let accumulate = 0;

                                return invoice.node.transaction.edges.map(

                                  (transaction, t_index) => {
                                    // eslint-disable-next-line
                                    let number_of_transaction = invoice.node.transaction.edges.length;
                                    accumulate = accumulate + transaction.node.total;
                                    let row_number = t_index === 0 && order_id + index + 1;
                                    let payment_channel = [];
                                    let ref_doc_number_list = [];

                                    if (
                                      this.state.product_and_service &&
                                      this.state.product_and_service !==
                                      transaction.node.productAndService.id
                                    ) {
                                      return true;
                                    } else if (
                                      this.state.product_and_service &&
                                      this.state.product_and_service ===
                                      transaction.node.productAndService.id
                                    ) {
                                      accumulate = transaction.node.total;
                                      row_number = index + 1;
                                      number_of_transaction = t_index + 1;
                                    }
                                    if (this.props.status === "paid" &&
                                      transaction.node.receiveTransaction.edges.length > 0
                                    ) {
                                      transaction.node.receiveTransaction.edges.forEach(
                                        (channel) => {
                                          if (channel.node.receive.cashDepositReceiveTransaction.totalCount > 0 &&
                                            !payment_channel.includes("เงินสด")
                                          ) {
                                            payment_channel.push("เงินสด");
                                          }
                                          if (channel.node.receive.bankAccountTransactionReceive.totalCount > 0 &&
                                            !payment_channel.includes("เงินโอน")
                                          ) {
                                            payment_channel.push("เงินโอน");
                                          }
                                          if (channel.node.receive.chequeDeposit.totalCount > 0 &&
                                            !payment_channel.includes("เช็ค")
                                          ) {
                                            payment_channel.push("เช็ค");
                                          }
                                          if (channel.node.receive.creditCardTransaction.totalCount > 0 &&
                                            !payment_channel.includes("บัตรเครดิต")
                                          ) {
                                            payment_channel.push("บัตรเครดิต");
                                          }
                                        }
                                      );
                                    }

                                    if (transaction.node.receiptDepositTransactionUsed.edges.length !== 0
                                    ) {
                                      transaction.node.receiptDepositTransactionUsed.edges.forEach(
                                        (receive_deposit) => {
                                          if (receive_deposit.node.receiptDepositTransaction.couple !== "NO" &&
                                            receive_deposit.node.coupleUse !== "NO"
                                          )
                                            ref_doc_number_list.push({
                                              type: "receive_deposit",
                                              id: receive_deposit.node.receiptDeposit.id,
                                              doc_number: receive_deposit.node.receiptDeposit.docNumber,
                                            });
                                        }
                                      );
                                    }

                                    
                                    if (transaction.node.creditNoteTransaction.edges.length !== 0) {
                                      transaction.node.creditNoteTransaction.edges.forEach(
                                        (credit_note) => {
                                          ref_doc_number_list.push({
                                            type: "credit_note",
                                            id: credit_note.node.id,
                                            doc_number: credit_note.node.docNumber,
                                          });
                                        }
                                      );
                                    }

                                    if (transaction.node.creditNoteTransactionRecord.edges.length !== 0) {
                                      transaction.node.creditNoteTransactionRecord.edges.forEach(
                                        (credit_note_transaction) => {
                                          ref_doc_number_list.push({
                                            type: "credit_note",
                                            id: credit_note_transaction.node.creditNote.id,
                                            doc_number: credit_note_transaction.node.creditNote.docNumber,
                                          });
                                        }
                                      );
                                    }

                                    if (transaction.node.receiveTransaction.edges.length !== 0) {
                                      transaction.node.receiveTransaction.edges.forEach(
                                        (receive) => {
                                          if (receive.node.receive.status !== "VOID") {

                                            let docNumber = receive.node.receive.docNumber
                                            if (receive.node.receive.status === 'WAIT') {
                                              docNumber = getDocumentNumber.get(
                                                "receive",
                                                "XXXX",
                                                new Date(receive.node.receive.issuedDate))
                                            }
                                            ref_doc_number_list.push({
                                              type: "receive",
                                              id: receive.node.receive.id,
                                              doc_number: docNumber,
                                            });
                                          }
                                        }
                                      );
                                    }

                                    return (
                                      <tr key={transaction.node.id}>
                                        <td>
                                          {row_number && !(invoice.node.status === "VOID" ||
                                            (invoice.node.status === "DRAFT" && this.props.status === "all")
                                          ) && (
                                              this.props.status === "draft" ?
                                                <React.Fragment>
                                                  {
                                                    this.props.check_all_draft ?
                                                      <input type="checkbox"
                                                        onChange={(e) => this.props.appendToCheckListDraf(e, invoice)}
                                                        checked={true}
                                                      />
                                                      :
                                                      <input type="checkbox"
                                                        onChange={(e) => this.props.appendToCheckListDraf(e, invoice)}
                                                        checked={this.props.listAllExportToExcel?.find(ex => ex.node.id === invoice.node.id)}
                                                      />
                                                  }
                                                </React.Fragment>

                                                :
                                                <input type="checkbox"
                                                  onChange={() => this.appendToCheckList(invoice.node.id)}
                                                  checked={this.checkList(invoice.node.id)}
                                                />
                                            )}
                                        </td>
                                        <td className="text-left">
                                          {invoice.node.status === "DRAFT" || invoice.node.status === "WAITING_FIX" ? (
                                            <React.Fragment>
                                              {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_invoice_edit", }) ||
                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_invoice_approve", }) ? (
                                                <Link to={"/accounting/income/invoice/form/edit/" + invoice.node.id}>
                                                  {getDocumentNumber.get("invoice", "XXXX", new Date(invoice.node.issuedDate))}
                                                </Link>
                                              ) : (getDocumentNumber.get("invoice", "XXXX", new Date(invoice.node.issuedDate)
                                              )
                                              )}
                                            </React.Fragment>
                                          ) : invoice.node.status !== "DRAFT" && invoice.node.status !== "VOID" ? (
                                            <Link to={"/accounting/income/invoice/invoice-detail/" + invoice.node.id}>
                                              {invoice.node.docNumber}
                                            </Link>
                                          ) : (invoice.node.docNumber)}
                                        </td>
                                        <td className="text-center">
                                          {format(invoice.node.issuedDate, "DD/MM/YYYY")}
                                        </td>
                                        <td className="text-center">
                                          {format(invoice.node.dueDate, "DD/MM/YYYY")}
                                        </td>
                                        <td className="text-center">
                                          {/* schedule time */}
                                          {invoice.node?.scheduleTime ? format(invoice.node.scheduleTime, 'DD/MM/YYYY - HH:mm น.', { locale: thLocale }) : '-'}
                                        </td>
                                        {/*<td className="text-center">{invoice.node.contact.refNumber}</td>*/}
                                        <td className="text-center">
                                          {invoice.node.contact.typeOfContact === "RESIDENTIAL" ? invoice.node.name : "-"}{" "}
                                        </td>
                                        <td>
                                          {invoice.node.contact.typeOfContact === "RESIDENTIAL"
                                            ? invoice.node.payGroup === "RENT"
                                              ? getNameResidentialRent(invoice.node.contact.residential.residentialHouseholder?.edges)
                                              : getNameResidential(invoice.node.firstName, invoice.node.lastName)
                                            : invoice.node.name}{" "}
                                          <span style={invoice.node.payGroup === "RENT" ? { color: '#F43853' } : null}>
                                            {invoice.node.payGroup === "RENT" ? `(${i18next.t("AgentRole:Renter")})` : ""}
                                          </span>
                                          <span style={invoice.node.payGroup === "AGENT"  ? { color: '#F43853' } : null}>
                                            {invoice.node.payGroup === "AGENT" ? `(${i18next.t("AgentRole:Agent")})` : ""}
                                          </span>
                                        </td>

                                        <td>
                                          {this.removeTrailingZeros(transaction.node.description)}
                                        </td>
                                        <td className="text-right">
                                          {numberWithComma(transaction.node.total)}
                                        </td>
                                        {/*<td className="text-right">{*/}
                                        {/*    t_index === number_of_transaction - 1 && numberWithComma(accumulate)*/}
                                        {/*}</td>*/}
                                        {this.props.status ===
                                          "partial_payment" && (
                                            <React.Fragment>
                                              <td className="text-right">
                                                {numberWithComma(
                                                  transaction.node.paid
                                                )}
                                              </td>
                                              <td className="text-right">
                                                {numberWithComma(
                                                  transaction.node.total -
                                                  transaction.node.paid || 0
                                                )}
                                              </td>
                                            </React.Fragment>
                                          )}

                                        {this.props.status === "void" && (
                                          <td className="text-left">
                                            {format(
                                              invoice.node.updated,
                                              "DD/MM/YYYY"
                                            )}
                                          </td>
                                        )}
                                        {this.props.status === "paid" && (
                                          <td className="text-center">
                                            {payment_channel.join() || "อื่นๆ"}
                                          </td>
                                        )}
                                        {this.props.status === "overdue" && (
                                          <React.Fragment>
                                            <td className="text-center text-danger">
                                              {differenceInCalendarDays(
                                                this.state.now_date,
                                                invoice.node.dueDate
                                              )}
                                            </td>
                                          </React.Fragment>
                                        )}
                                        {this.props.status === "all" &&
                                          this.getColorAndText(invoice)}
                                        <td className="text-left">
                                          {invoice.node.creator || "-"}
                                        </td>
                                        {/*<td className="text-center">*/}
                                        {/*    {format(invoice.node.added, 'DD/MM/YYYY')}*/}
                                        {/*</td>*/}

                                        {this.props.status === "void" && (
                                          <React.Fragment>
                                            <td className="text-left">
                                              {invoice.node.updater || "-"}
                                            </td>
                                            <td className="text-left">
                                              {invoice.node.voidRemark || "-"}
                                            </td>
                                          </React.Fragment>
                                        )}
                                        {(this.props.status ===
                                          "partial_payment" ||
                                          this.props.status === "paid" ||
                                          this.props.status === "all") && (
                                            <td
                                              className="text-center"
                                              width={130}
                                            >
                                              {ref_doc_number_list.length > 0
                                                ? ref_doc_number_list.map(
                                                  (ref_doc, index) => (
                                                    <span key={ref_doc.id}>
                                                      {ref_doc.type ===
                                                        "receive_deposit" ||
                                                        ref_doc.type ===
                                                        "credit_note" ? (
                                                        ref_doc.type ===
                                                          "receive_deposit" ? (
                                                          <Link
                                                            to={
                                                              "/accounting/income/receipt_deposit/view/" +
                                                              ref_doc.id
                                                            }
                                                          >
                                                            {ref_doc.doc_number}
                                                          </Link>
                                                        ) : (
                                                          <Link
                                                            to={
                                                              "/accounting/income/credit-note/detail/" +
                                                              ref_doc.id
                                                            }
                                                          >
                                                            {ref_doc.doc_number}
                                                          </Link>
                                                        )
                                                      ) : (
                                                        <Link
                                                          to={
                                                            "/accounting/income/receive/detail/" +
                                                            ref_doc.id +
                                                            "/" +
                                                            ref_doc.doc_number
                                                          }
                                                        >
                                                          {ref_doc.doc_number}
                                                        </Link>
                                                      )}

                                                      {index + 1 <
                                                        ref_doc_number_list.length &&
                                                        ", "}
                                                    </span>
                                                  )
                                                )
                                                : "-"}
                                            </td>
                                          )}
                                      </tr>
                                    );
                                  }
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={props.invoiceViewer.allInvoice.totalCount}
                        />
                      </div>
                    </React.Fragment>
                  );
                }
                return <Loading />;
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default InvoiceListTable;