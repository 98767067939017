import i18next from 'i18next';
import React, { Component} from 'react';
class ClaimReportStatus extends Component {
    
    render() {
        return (
            <>
            {
            this.props.status === 'finish' && (
            <div className="container">
            <section className="step-indicator">
                    <div className={this.props.status === 'new' ? 'step step1 active' : 'step step1'}>
                        {
                            this.props.status === 'new' ?
                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive1.png"} />
                            :
                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon1.png"} />
                        }
                        <p className={this.props.status === 'new' ?  "text_FFBD2D" : ""}>{i18next.t("claimRequstList:Under consideration")}</p>
                    </div>
                    
                    <div className='indicator-line'></div>

                    <div className={this.props.status === 'pending_approve' ? 'step step2 active' : 'step step2'}>
                        {
                            this.props.status === 'pending_approve' ?
                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive2.png"} />
                            :
                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon2.png"} />
                        }
                        <p className={this.props.status === 'pending_approve' ?  "text_1567B4" : ""}>{i18next.t("claimRequstList:Under insurance approval")}</p>
                     </div>

                     <div className='indicator-line'></div>

                     <div className={this.props.status === 'approve' ? 'step step3 active' : 'step step3'}>
                        {
                            this.props.status === 'approve' ?
                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive3.png"} />
                            :
                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon3.png"} />
                        }
                        <p className={this.props.status === 'approve' ?  "text_4EB4A9" : ""}>{i18next.t("claimRequstList:Receive compensation")}</p>
                    </div>

                    <div className='indicator-line'></div>
                    <div className={this.props.status === 'finish' ? 'step step4 active' : 'step step4'}>
                    {
                            this.props.status === 'finish' ?
                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive4.png"} />
                            :
                            <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon4.png"} />
                        }
                        <p className={this.props.status === 'finish' ?  "text_68DD6E" : ""}>ปิดเคลม</p>
                    </div>
             </section>
            </div>
            )
            }
            {
                this.props.status === 'consider_fail' && (
                    <div className="container" style={{maxWidth:'600px',position:'relative',right:'20%'}}>
                    <section className="step-indicator">
                            <div className='step step1'>
                                {
                                    this.props.status === 'new' ?
                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive1.png"} />
                                    :
                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon1.png"} />
                                }
                                <p className={this.props.status === 'new' ?  "text_FFBD2D" : ""}>{i18next.t("claimRequstList:Under consideration")}</p>
                            </div>
                            
                            <div className='indicator-line'></div>
        
                            <div className='step step2'>
                                {
                                    this.props.status === 'consider_fail' ?
                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive5.png"} />
                                    :
                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon5.png"} />
                                }
                                <p className={this.props.status === 'pending_approve' ?  "text_1567B4" : ""}>ยกเลิก</p>
                             </div>
                     </section>
                    </div>
                )
            }
             {
                this.props.status === 'not_approve' && (
                    <div className="container" style={{maxWidth:'800px',position:'relative',right:'18%'}}>
                    <section className="step-indicator">
                            <div className='step step1'>
                                {
                                    this.props.status === 'new' ?
                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive1.png"} />
                                    :
                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon1.png"} />
                                }
                                <p className={this.props.status === 'new' ?  "text_FFBD2D" : ""}>{i18next.t("claimRequstList:Under consideration")}</p>
                            </div>
                            
                            <div className='indicator-line'></div>
                            <div className='step step2'>
                                {
                                    this.props.status === 'pending_approve' ?
                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive2.png"} />
                                    :
                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon2.png"} />
                                }
                                <p className={this.props.status === 'pending_approve' ?  "text_1567B4" : ""}>{i18next.t("claimRequstList:Under insurance approval")}</p>
                             </div>
        
                            <div className='indicator-line'></div>
                            <div className='step step3'>
                            {
                                    this.props.status === 'not_approve' ?
                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/iconActive5.png"} />
                                    :
                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/status/icon5.png"} />
                                }
                                <p className={this.props.status === 'finish' ?  "text_68DD6E" : ""}>ยกเลิก</p>
                            </div>
                     </section>
                    </div>
                )
            }
            </>
        )
    }
}


export default ClaimReportStatus
