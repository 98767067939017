import React, { Component } from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import { Route, Switch } from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import Dashboard from "./dashboard"
import InterestDashboard from "./interest/interestDashboard"
import WrapperInterestReport from './interest/wrapperInterestReport';
import BankDepositReport from "./bankDepositReport/bankDepositReport";
import WrapperBankDepositReport from "./bankDepositReport/wrapperBankDepositReport";
import BankDepositImpactReport from './bankDepositImpactReport/bankDepositImpactReport';
import BankDepositImpactReportDetail from './bankDepositImpactReport/bankDepositImpactReportDetail';
import WrapperBankDepositImpactReportDetail from './bankDepositImpactReport/Doc/wrapperBankDepositImpactReportDetail';
import WrapperBankDepositImpactReport from "./bankDepositImpactReport/wrapperBankDepositImpactReport";


class Report extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={Dashboard} />
                <PrivateRoute path={`${this.props.match.url}/interest-dashboard`} component={InterestDashboard} />
                <PrivateRoute path={`${this.props.match.url}/report-interest`} component={WrapperInterestReport} />
                <PrivateRoute path={`${this.props.match.url}/bank-deposit-report`} component={BankDepositReport} />
                <PrivateRoute path={`${this.props.match.url}/wrapper-bank-deposit-report/:date`} component={WrapperBankDepositReport} />

                <PrivateRoute path={`${this.props.match.url}/wrapper-bank-deposit-impact-report/:bankAccountId/:startDate/:endDate/:namebank/:type`} component={WrapperBankDepositImpactReportDetail} />
                <PrivateRoute path={`${this.props.match.url}/wrapper-bank-deposit-impact-report/:date`} component={WrapperBankDepositImpactReport} />
                <PrivateRoute path={`${this.props.match.url}/bank-deposit-impact-report/:id/:monthyear/:namebank/:type/:accountnumber`} component={BankDepositImpactReportDetail} />
                <PrivateRoute path={`${this.props.match.url}/bank-deposit-impact-report`} component={BankDepositImpactReport} />
                <Route component={NoMatch} />
            </Switch>
        );
    }
}

export default Report;
