import './styles/index.scss';
import React, {Component} from 'react';
import environment from '../../env/environment';
import {graphql} from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import {QueryRenderer} from 'react-relay';

const query = graphql`
    query toggleMenuSwitchQuery {
        userConfig {
            id
            languages
            sidebarSize
        }
    }
`;

const mutation = graphql`
    mutation toggleMenuSwitchMutation($input: UpdateUserConfigInput!) {
      UpdateUserConfig(input: $input) {
        newUserConfig {
          id languages sidebarSize
        }
      }
    }
`;

class ToggleMenuSwitch extends Component {

    setSidebarSize(props) {
        let size = props.sidebarSize;
        let selected_size = "";
        if (size === 'FULL') {
            selected_size = 'half'
        } else {
            selected_size = 'full'
        }
        let variables = {
            input: {sidebarSize: selected_size}
        };

        commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response, errors) => {

            },
            onError: error => {
                alert('server error')
            },
            optimisticUpdater: (store) => {
                store.get(props.id).setValue(selected_size, 'sidebarSize');
            }
        });
    }

    render() {
        return (
            <QueryRenderer
                cacheConfig={{use_cache: true}}
                environment={environment}
                query={query}
                render={({error, props}) => {
                    if (props) {
                        return (
                            <span onClick={() => {
                                this.setSidebarSize(props.userConfig)
                            }}>
                            <img src={process.env.PUBLIC_URL + '/images/header/switch.png'}
                                 alt="switch" className="switch"/>
                            <img src={process.env.PUBLIC_URL + '/images/header/logo.png'}
                                 alt="silverman" className="logo"
                                 srcSet={process.env.PUBLIC_URL + '/images/header/logo@2x.png 2x'}/>
                            </span>
                        )
                    }
            }}/>
        );
    }
}

export default ToggleMenuSwitch;
