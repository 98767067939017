import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import PaymentVoucher from './pay';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import WithholdingTaxPrint from "../witholding_tax/withholdingTaxPrint";
import PayAccountRecord from "./payAccountRecord";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperPayQuery($id: ID!,$ref_transaction: String) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        allAccountRecordGroup(refTransaction: $ref_transaction) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator issuedDate
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }

        payRecordGroup(id: $id) {
            id
            contact {
                refNumber
                name
                taxIdNumber
                firstName
                lastName
                phone
                email
                typeOfCompany
                typeOfSupplier
                typeOfContact
                nameBranch
                registeredAddress
                registeredCountry
                registeredProvince
                registeredDistrict
                registeredCity
                registeredPostalCode
                residential {
                    id
                    size
                }
            }

            firstName 
            lastName
            name
            phone
            email
            taxIdNumber
            address
            district
            city
            province
            postalCode
            country
            

            docNumber
            issuedDate
            refPayExpenseDate
            status
            creator
            remark
            total
            refNumber
            refPayDate

            payRecord {
                totalCount
                edges {
                    node {
                        id
                        amount
                        whtRatePercent
                        whtAmount
                        purchaseRecord {
                            purchaseRecordGroup {
                                docNumber
                                issuedDate
                                taxType
                            }
                            id
                            productAndService {
                                productCode
                            }
                            description
                            unitItems
                            price
                            whtRatePercent
                            preTaxAmount
                            discount
                            vat
                            whtRate
                            total
                            paid
                        }
                    }
                }
            }
            paymentChannel(status:"paid",slug:"petty_cash"){
                edges{
                  node{
                    id
                    price
                    date
                    setPettyCash{
                      docNumber
                      description
                      withdrawer
                  }
                  }
                }
              }
            acceptCreditNotePayRecordGroup{
                edges{
                    node{
                        id
                        docNumber
                        description
                        issuedDate
                        price
                    }
                }
            }
            
            bankAccountTransaction {
                edges {
                    node {
                        id
                        price
                        date
                        imageSlip
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            cashTransaction {
                edges {
                    node {
                        id
                        price
                    }
                }
            }
            chequeTransaction {
                edges {
                    node {
                        id
                        date
                        bankAccount {
                            id
                            bankName
                            branch
                        }
                        chequeNumber
                        price
                    }
                }
            }
        }
        withholdingTaxPayRecordGroup(payRecordGroupId: $id) {
            id
            docNumber
            total
            unknownContact

            payRecordGroup {
                id
                total
                docNumber
                issuedDate

                firstName 
                lastName
                name
                phone
                email
                taxIdNumber
                address
                district
                city
                province
                postalCode
                country

                payRecord {
                    edges {
                        node {
                            id
                            amount
                            whtAmount
                            whtRatePercent
                            purchaseRecord{
                                id
                                total
                                preTaxAmount
                                whtRate
                                whtRatePercent
                                description

                                typeOfIncome
                                typeOfIncomeOther
                                typeOfTax
                                typeOfTaxOther
                                taxRate

                                productAndService{
                                    id
                                    name
                                    typeOfIncome
                                    typeOfIncomeOther
                                    typeOfTax
                                    typeOfTaxOther
                                    taxRate
                                }
                                prepaidDepositTransactionUsed {
                                    edges {
                                        node {
                                            amount
                                            prepaidDepositTransaction{
                                                whtRatePercent
                                                vat
                                            }
                                        }
                                    }
                                }
                                acceptCreditNoteTransaction(status: "paid"){
                                    edges {
                                        node {
                                            price
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            contact {
                id
                taxIdNumber
                typeOfSupplier
                name
                refNumber
                firstName
                lastName
                registeredAddress
                registeredCity
                registeredCountry
                registeredDistrict
                registeredName
                registeredPostalCode
                registeredProvince
                typeOfPeople
            }

            issuedDate
            added
            total
            remark

            


        }
        paymentChannelDatail(refTransaction: $ref_transaction) {
            refDoc
            date
            payType
            price
            imageslip
        }
        userConfig {
            id languages
        }
    }
`;

class WrapperPay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type: 'all'
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Payment Voucher</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>
                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{
                        id: this.props.match.params.id,
                        ref_transaction: this.props.match.params.ref_transaction

                    }}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            let count_page = Math.ceil((props.payRecordGroup.payRecord.totalCount) / 20);
                            let arrX = Array.from(new Array(count_page));
                            let transaction_per_page = 20;
                            if (this.state.check_type === "all") {

                                return (
                                    <React.Fragment>
                                        {arrX.map((value, index) => {
                                                // original
                                                return (
                                                    <PaymentVoucher selfProject={props.selfProject}
                                                             payRecordGroup={props.payRecordGroup}
                                                             allAccountRecordGroup={props.allAccountRecordGroup}
                                                             payRecord={props.payRecordGroup.payRecord.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1))}
                                                             check_type={"manuscript"}
                                                             pageNumber={index + "manuscript" + atob(props.payRecordGroup.id).split(':')[1]}
                                                             end_page={count_page - 1}
                                                             current_page={index}
                                                             key={index}
                                                             paymentChannelDetail={props.paymentChannelDatail}
                                                             languages={props.userConfig.languages}
                                                    />
                                                )
                                            }
                                        )}
                                        {props.payRecordGroup.status !== "DRAFT" && arrX.map((value,index) => {
                                            // GL
                                            return(
                                                <PayAccountRecord selfProject={props.selfProject}
                                                        payRecordGroup={props.payRecordGroup}
                                                        allAccountRecordGroup={props.allAccountRecordGroup}
                                                        payRecord={props.payRecordGroup.payRecord.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1))}
                                                        pageNumber={index + atob(props.payRecordGroup.id).split(':')[1]}
                                                        end_page={count_page - 1}
                                                        current_page={index}
                                                        key={index}
                                                        languages={props.userConfig.languages}
                                                />
                                            )

                                        })
                                        }
                                        
                                        {props.payRecordGroup.status !== "DRAFT" && props.withholdingTaxPayRecordGroup && [1, 2, 3, 4].map((count_paper, index) => {
                                            return (<WithholdingTaxPrint query={props} withholdingTax={props.withholdingTaxPayRecordGroup} count_paper={count_paper} key={index}
                                                payRecordGroup={props.withholdingTaxPayRecordGroup.payRecordGroup}
                                            />)
                                        })}
                                    </React.Fragment>
                                )

                            } else {
                                return (
                                    <React.Fragment>
                                        {arrX.map((value, index) => {
                                            return (
                                                <PaymentVoucher selfProject={props.selfProject}
                                                         payRecordGroup={props.payRecordGroup}
                                                         allAccountRecordGroup={props.allAccountRecordGroup}
                                                         payRecord={props.payRecordGroup.payRecord.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1))}
                                                         check_type={this.state.check_type}
                                                         pageNumber={index + "receive" + atob(props.payRecordGroup.id).split(':')[1]}
                                                         end_page={count_page - 1}
                                                         current_page={index}
                                                         key={index}
                                                         paymentChannelDetail={props.paymentChannelDatail}
                                                         languages={props.userConfig.languages}
                                                />
                                            )
                                        })}

                                        {/* {props.payRecordGroup.status !== "DRAFT" && arrX.map((value,index) => {
                                            // GL
                                            return(
                                                <PayAccountRecord selfProject={props.selfProject}
                                                        payRecordGroup={props.payRecordGroup}
                                                        allAccountRecordGroup={props.allAccountRecordGroup}
                                                        payRecord={props.payRecordGroup.payRecord.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1))}
                                                        pageNumber={index + atob(props.payRecordGroup.id).split(':')[1]}
                                                        end_page={count_page - 1}
                                                        current_page={index}
                                                        key={index}
                                                />
                                            )

                                        })
                                        } */}

                                        {props.payRecordGroup.status !== "DRAFT" && props.withholdingTaxPayRecordGroup && [1, 2, 3, 4].map((count_paper, index) => {
                                            return (<WithholdingTaxPrint query={props} withholdingTax={props.withholdingTaxPayRecordGroup} count_paper={count_paper} key={index}
                                                payRecordGroup={props.withholdingTaxPayRecordGroup.payRecordGroup}
                                            />)
                                        })}
                                    </React.Fragment>
                                )
                            }

                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperPay;
