import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";


class PettyCashReportPaymentTable extends React.Component {

    render() {
        let text_payment= "";
        if (this.props.payment.bankAccountTransaction.totalCount > 0){
            let bank = this.props.payment.bankAccountTransaction.edges[0].node;
            text_payment = bank.bankAccount.bankName +" "+ bank.bankAccount.accountNumber
        }else if(this.props.payment.chequeTransaction.totalCount > 0){
            text_payment = 'เช็ค'
        }else {
           text_payment = 'เงินสด'
        }
        return (
            <tr key={this.props.payment.id}>
                <td/>
                <td/>
                <td/>
                <td/>
                <td/>
                <td/>
                <td/>
                <td/>
                <td/>
                <td className="text-right">{numberWithComma(this.props.total)}</td>
                <td className="text-center">{format(this.props.payment.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                <td className="text-center">{this.props.payment.docNumber}</td>
                <td>{this.props.payment.description}</td>
                <td className="text-right">{numberWithComma(this.props.payment.total)}</td>
                <td className="text-center">{text_payment}</td>
            </tr>
        )
    }
}

export default PettyCashReportPaymentTable;
