import React, {Component} from 'react';
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import Swal from "sweetalert2";
import {commitMutation} from "react-relay";
import environment from "../../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import SearchSelect from "../../../libs/searchSelect";
import {fetchQuery} from "relay-runtime";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import {Redirect} from 'react-router'
import getDocumentNumber from "../../../libs/getDocumentNumber";
import {format} from "date-fns";
import i18n from '../../../i18n';
const _ = require("lodash");

const getdocNumberNextAndPettyCashSetCreateQuery = graphql`
    query unknownReceiveCreateQuery{
        bankAccountViewer {
            allBankAccount(status: true,accountType_In:"current_account,saving_account,saving_deposit,fixed_deposit", receivePayment: true) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        status
                    }
                }
                totalCount
            }
        }
        viewer {
            allChartOfAccount(chartOfAccountCode_Istartswith:"2230"){
                totalCount
                edges{
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
        }
        allProjects {
        edges {
            node {
                periodCreate
            }
        }
       }
    }
`;

const mutation = graphql`
    mutation unknownReceiveCreateMutation($input: CreateUnknownReceiveInput!){
        createUnknownReceive(input: $input){
            ok
            warningText
        }
    }
`;


class UnknownReceiveCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            issued_date: new Date(),
            description: '',
            amount: '',
            payment_channel: '',
            payment_list: [],
            category: 'unknown_receive',
            chart_of_account: [],
            chartOfAccount: '',
            redirectToList: false,
            loading: false,
            date: new Date(),
            chart_of_account_id: '',
            chart_of_account_view:'',
            signreq:Math.random().toString(36),
            temp_month_before : '',
            temp_month_after : '',
            period_create:'',
            fileUpload: [],
            fileName: '',
            upload: {},
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.refreshPettyCashDocNumberNext = this.refreshPettyCashDocNumberNext.bind(this);
    }

    componentWillMount() {
        this.refreshPettyCashDocNumberNext();
    }


    refreshPettyCashDocNumberNext() {
        fetchQuery(environment, getdocNumberNextAndPettyCashSetCreateQuery, {}).then(data => {
            if (data.bankAccountViewer) {
                this.setState({payment_list: data.bankAccountViewer.allBankAccount.edges});
            }
            if (data.viewer) {
                this.setState({chart_of_account: data.viewer.allChartOfAccount.edges, 
                    chart_of_account_id: data.viewer.allChartOfAccount.edges[0].node.id,
                    chart_of_account_view: data.viewer.allChartOfAccount.edges[0].node.chartOfAccountCode + " " + data.viewer.allChartOfAccount.edges[0].node.name })
            }
            if(data){
                this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
            }
        });
    }


    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }


    calPeriodMouth(val){
        let month_before = new Date();
        let month_after= new Date();
    
        month_before.setMonth(month_before.getMonth() -  parseInt(val));
        month_after.setMonth(month_after.getMonth() + parseInt(val));
    
        this.setState({
            temp_month_before : month_before,
            temp_month_after : month_after,
        })
    }


    onSubmit(e) {
        e.preventDefault();
        let upload = {};
        upload['imageSlip'] = this.state.fileUpload
        let uploadables = upload

        this.setState({loading: true});
        let variables = {
            input: {
                issuedDate: format(this.state.issued_date, 'YYYY-MM-DD'),
                description: this.state.description,
                chartOfAccount: this.state.chart_of_account_id,
                bankAccount: this.state.payment_channel,
                amount: this.state.amount,
                date: format(this.state.issued_date, 'YYYY-MM-DD'),
                clientMutationId:"UR"+this.state.signreq,
            }
        };
        commitMutation(
            environment,
            {
                mutation,
                variables,
                uploadables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createUnknownReceive.ok) {
                        Swal.fire(i18n.t("unknown_receive:Add unknown receive successfully"), '', 'success').then(() => {
                            this.setState({redirectToList: true});
                        });
                    } else {
                        Swal.fire(i18n.t("unknown_receive:Add unknown receive unsuccessfully"), response.createUnknownReceive.warningText, 'warning');
                        // Swal.fire(i18n.t("unknown_receive:Add unknown receive unsuccessfully"), i18next.t("Allaction:Please check again"), 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({loading: true});
                    Swal.fire('Error!', i18n.t("unknown_receive:Please try again"), 'warning')
                },
            },
        )
    }

    uploadFile = (e) => {
        let data = _.cloneDeep(e);
        const file = data.target.files[0];
    
        if (file?.size <= 10 * 1024 * 1024) {
            this.setState({ fileUpload: file, fileName: file.name, editImage: true });
        } else if(file?.size > 10 * 1024 * 1024){
            Swal.fire('Error!', i18n.t("unknown_receive:File size exceeds the limit of 10 MB. Please choose a smaller file"), 'warning')
            data.target.value = null;
        } else {
            Swal.fire("Error!", i18n.t('createReceive:Please try again'), "warning");
        }
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/income/unknown-receive/list/wait"/>
        }

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/income/unknown-receive/list/all">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('accounting:add unknown receive')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>
                        <div className="content-inner">
                            <div className="card">
                                <div className="card-body">
                                    <form id="form-add" onSubmit={this.onSubmit}>
                                        <div className="row">
                                            <label htmlFor="docNumber"
                                                   className="col-2 col-form-label">
                                                <Translation>{t=><strong>{t("unknown_receive:No.")}</strong>}</Translation>
                                            </label>
                                            <div className="col-5">
                                                <input type="text" className="form-control" id="docNumber"
                                                       value={getDocumentNumber.get(this.state.category, 'XXX', this.state.issued_date)}
                                                       name="docNumber" disabled/>
                                            </div>
                                            <div className="col-1"/>
                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="issued_date"
                                                   className="col-2 col-form-label">
                                                <Translation>{t=><strong>{t("unknown_receive:Payment date")}</strong>}</Translation>
                                            </label>
                                            <div className="col-5">
                                                <div className="input-group">
                                                    {/* <DatePickerAdapter id="issued_date"
                                                                       className="form-control" name="issued_date"
                                                                       selected={this.state.issued_date}
                                                                       onChange={this.handleInputChange}
                                                    /> */}
                                                    {
                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ? 
                                                        <DatePickerAdapter
                                                            name="issued_date"
                                                            id="issued_date"
                                                            className="form-control"
                                                            selected={this.state.issued_date}
                                                            minDate={this.state.temp_month_before}
                                                            // maxDate={this.state.temp_month_after}
                                                            onChange={this.handleInputChange}
                                                            required={true}
                                                        /> : 
                                                        <DatePickerAdapter id="issued_date"
                                                            className="form-control" name="issued_date"
                                                            selected={this.state.issued_date}
                                                            onChange={this.handleInputChange}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-1"/>
                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="InputBankAccountNum"
                                                   className="col-2 col-form-label">
                                                <Translation>{t=><strong>{t("unknown_receive:Description")}</strong>}</Translation>
                                            </label>
                                            <div className="col-5">
                                           <textarea className="form-control" maxLength={190}
                                                     rows="3" name="description" value={this.state.description}
                                                     onChange={this.handleInputChange} required/>
                                            </div>
                                            <div className="col-1"/>
                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="payment_channel"
                                                   className="col-2 col-form-label">
                                                <Translation>{t=><strong>{t("unknown_receive:List")}</strong>}</Translation>
                                            </label>
                                            <div className="col-5 text-left">
                                                {/* <SearchSelect onChange={this.handleInputChange}
                                                              value={this.state.chartOfAccount}
                                                              name="chartOfAccount" placeholder="รายการ"
                                                              queryObject={this.state.chart_of_account}
                                                              keyOfValue="id" require={true}
                                                              keyOfLabel="chartOfAccountCode:name"/> */}
                                                <span type="text" className="form-control"
                                                       name="chartOfAccount">{this.state.chart_of_account_view}</span>
                                            </div>
                                            <div className="col-1"/>

                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="payment_channel"
                                                   className="col-2 col-form-label">
                                                <Translation>{t=><strong>{t("unknown_receive:Payment method")}</strong>}</Translation>
                                            </label>
                                            <div className="col-5 text-left">
                                                <SearchSelect onChange={this.handleInputChange}
                                                              value={this.state.payment_channel}
                                                              name="payment_channel" id="payment_channel"
                                                              placeholder="ช่องทางการจ่ายเงิน"
                                                              queryObject={this.state.payment_list}
                                                              keyOfValue="id" require={true}
                                                              keyOfLabel="bankName:accountType:accountNumber"
                                                />
                                            </div>
                                            <div className="col-1"/>
                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="inputPrice"
                                                   className="col-2 col-form-label">
                                                <Translation>{t=><strong>{t("unknown_receive:Amount")}</strong>}</Translation>
                                            </label>
                                            <div className="col-5">
                                                <input type="number" className="form-control" id="inputPrice"
                                                       step="0.01" name="amount" min={0.01}
                                                       onChange={this.handleInputChange}
                                                       value={this.state.amount} required/>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-2'></div>
                                            <div className="col-5 d-flex flex-column">
                                                <div>
                                                <Translation>{t=><label htmlFor="uploadFile" className="btn btn-primary add mr-4">{t("unknown_receive:Upload receipt")}</label>}</Translation>
                                                    <input type="file" name='file' id="uploadFile"
                                                        style={{display:'none'}}
                                                        className='form-control uploadFile'
                                                        placeholder='uploadFile'
                                                        accept="image/png, image/jpeg"
                                                        onChange={this.uploadFile} />        
                                                    <Translation>{t=><span className='text-red'>{t("unknown_receive:Attach files (JPG PNG, size not exceeding 10 MB)")}</span>}</Translation>
                                                </div>
                                                {this.state.fileUpload?.name && <p>{this.state.fileUpload.name}</p>}    
                                            </div>
                                        </div>                                        
                                        <div className="row mt-4">
                                            <div className="col-12 text-right">
                                                <div className="btn-group mr-2">
                                                    <Link to="/accounting/income/unknown-receive/list/all">
                                                        <Translation>
                                                            {t=>
                                                            <button type="button" className="btn btn-secondary add">
                                                                {t("unknown_receive:Cancel")}
                                                            </button>}
                                                        </Translation>
                                                    </Link>
                                                </div>
                                                <div className="btn-group mr-2">
                                                    <Translation>
                                                        {t=>
                                                        <button type="submit" className="btn btn-primary add"
                                                                form="form-add"
                                                                disabled={this.state.loading}>
                                                            {this.state.loading &&
                                                            <span
                                                                className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                            {t("unknown_receive:Add unknown receive")}
                                                        </button>}
                                                    </Translation>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default UnknownReceiveCreate;