/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollectionLawFirmFromSettingDocumentType = "FM_CF_01" | "FM_CF_02" | "FM_CF_03" | "FM_CF_04" | "FM_CF_05" | "FM_CF_12" | "%future added value";
export type collectionLawFirmENoticeQueryVariables = {|
  language?: ?string,
  siteId?: ?string,
|};
export type collectionLawFirmENoticeQueryResponse = {|
  +allCollectionLawFirmFromSetting: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +documentType: CollectionLawFirmFromSettingDocumentType,
        +dueDateDays: number,
        +dueDateDaysOnsite: number,
        +feeRateTh: number,
        +feeRateOther: number,
        +lawyerSignature: ?string,
        +lawyerName: ?string,
        +rulesRegulationsjuristic: ?string,
        +telOffice: ?string,
        +telJuristic: ?string,
        +language: string,
        +creator: ?string,
        +creatorEdit: ?string,
      |}
    |}>
  |}
|};
export type collectionLawFirmENoticeQuery = {|
  variables: collectionLawFirmENoticeQueryVariables,
  response: collectionLawFirmENoticeQueryResponse,
|};
*/


/*
query collectionLawFirmENoticeQuery(
  $language: String
  $siteId: ID
) {
  allCollectionLawFirmFromSetting(documentType: "FM_CF_02", language: $language, siteId: $siteId) {
    edges {
      node {
        id
        documentType
        dueDateDays
        dueDateDaysOnsite
        feeRateTh
        feeRateOther
        lawyerSignature
        lawyerName
        rulesRegulationsjuristic
        telOffice
        telJuristic
        language
        creator
        creatorEdit
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "language"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "documentType",
        "value": "FM_CF_02"
      },
      {
        "kind": "Variable",
        "name": "language",
        "variableName": "language"
      },
      {
        "kind": "Variable",
        "name": "siteId",
        "variableName": "siteId"
      }
    ],
    "concreteType": "CollectionLawFirmFromSettingNodeConnection",
    "kind": "LinkedField",
    "name": "allCollectionLawFirmFromSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionLawFirmFromSettingNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionLawFirmFromSettingNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "documentType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDateDays",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDateDaysOnsite",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feeRateTh",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feeRateOther",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lawyerSignature",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lawyerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rulesRegulationsjuristic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "telOffice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "telJuristic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "language",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creatorEdit",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionLawFirmENoticeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "collectionLawFirmENoticeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "63608737832da6609a609b75d532eec9",
    "id": null,
    "metadata": {},
    "name": "collectionLawFirmENoticeQuery",
    "operationKind": "query",
    "text": "query collectionLawFirmENoticeQuery(\n  $language: String\n  $siteId: ID\n) {\n  allCollectionLawFirmFromSetting(documentType: \"FM_CF_02\", language: $language, siteId: $siteId) {\n    edges {\n      node {\n        id\n        documentType\n        dueDateDays\n        dueDateDaysOnsite\n        feeRateTh\n        feeRateOther\n        lawyerSignature\n        lawyerName\n        rulesRegulationsjuristic\n        telOffice\n        telJuristic\n        language\n        creator\n        creatorEdit\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bf9c6c85fb406106b6fc3a882e95f976';

module.exports = node;
