import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";

var token_id = localStorage.getItem("token");
var header = {
    authorization: `JWT ${token_id}`,
    "Content-Type": "application/json",
};


function SendChatMessage(data) {
    return axios.post(`${getApiRoot()}insure/send_and_read_insurance_message`, data, { headers: header, },);
}

function readChat(claimId) {
    return axios.get(`${getApiRoot()}insure/send_and_read_insurance_message`, { headers: header, params: { claimId: claimId } },);
}


function GetCliamList(data) {
    return axios.get(`${getApiRoot()}insure/claim`, {
        headers: header,
        params: {
            end_date: data.end_date || "",
            start_date: data.start_date || "",
            search: data.search || "",
            status: data.status || "",
            residential_id: data.residential_id || "",
            index_first: data.index_first || 0,
            index_last: data.index_last || 10,
            claim_type: data.claim_type || "",
        }
    });
}

function SaveDataClaim(data) {
    return axios.post(`${getApiRoot()}insure/claim`, data, { headers: header },);
}

function GetCliamID(claim_id) {
    return axios.get(`${getApiRoot()}insure/claim`, {
        headers: header,
        params: {
            claim_id: claim_id
        }
    });
}

function UpdateCliam(data , claim_id){
    return axios.patch(`${getApiRoot()}insure/claim`, data, { headers: header , params:{claim_id: claim_id} },);
}

function getListTag(){
    return axios.get(`${getApiRoot()}insure/tag_global`, { headers: header },);
}

function create_tag_global(tagName){
    return axios.post(`${getApiRoot()}insure/tag_global`, tagName , { headers: header },);
}

function create_tag_claim(data){
    return axios.post(`${getApiRoot()}insure/tag_claim`, data , { headers: header },);
} 

function removeTagFromGlobal(id){
    return axios.patch(`${getApiRoot()}insure/tag_global`, id , { headers: header },);
}

function removeTagFromClaim(id){
    return axios.patch(`${getApiRoot()}insure/tag_claim`, id , { headers: header },);
}

function inserranceCurrent(){
    return axios.get(`${getApiRoot()}insure/insurance`, {headers: header , params: { 
        current: true
    }})
}


function settingClaim(data){
    return axios.get(`${getApiRoot()}insure/claim_setting`, {
        headers: header,params:{
            juristic_consider: data?.juristic_consider || "",
            insure_approve: data?.insure_approve || "",
            receive_compensation: data?.receive_compensation || "",
            button: data?.button
        }
    })
}






export default {
    //Chat
    SendChatMessage,
    readChat,

    //Cliam
    GetCliamList,
    SaveDataClaim,
    GetCliamID,
    UpdateCliam,

    //Tag
    getListTag,
    create_tag_global,
    create_tag_claim,
    removeTagFromGlobal,
    removeTagFromClaim,

    //Insurrance
    inserranceCurrent,

    //SettingClaim
    settingClaim
}