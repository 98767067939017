import React, { Component } from 'react';
import PrivateRoute from '../../libs/privateRoute';
import { Route, Switch } from "react-router-dom";

import NoMatch from "../../components/noMatch";
import Schedule from "./schedule/scheduleList";
import fixRequestListCreate from './tenant/fixRequestListCreate';
import fixRequestListForTenent from './tenant/fixRequestListForTenent';
import fixRequestChat from './chat/fixRequestChat';
import FixrequestReport from './report/report';
import fixRequestListDetail from './tenant/fixRequestListDetail';
import fixRequestListForCommon from './common/fixRequestListForCommon';
import fixRequestListCommonCreate from './common/fixRequestListCommonCreate';
import fixRequestPDF from '../../document/fix_request/fixRequsetPDF';
import FixrequestReportCommon from './report/reporCommon';
import fixRequestListCommonDetail from './common/fixRequestListCommonDetail';
import fixRequestWorksheet from '../../document/fix_request/fixRequestWorksheet';

class FixRequestSingha extends Component { 
    render() {
        return (
            <Switch>
                <PrivateRoute path={`${this.props.match.url}/fortenant/:detail/:id`} component={fixRequestListDetail} />
                <PrivateRoute path={`${this.props.match.url}/forcommon/:detail/:id`} component={fixRequestListCommonDetail} /> 
                <PrivateRoute path={`${this.props.match.url}/fortenant/create`} component={fixRequestListCreate} />
                <PrivateRoute path={`${this.props.match.url}/forcommon/create`} component={fixRequestListCommonCreate} />
                <PrivateRoute path={`${this.props.match.url}/document/worksheet/:repairroomid`} component={fixRequestWorksheet} />
                <PrivateRoute path={`${this.props.match.url}/document/:for/:tabName`} component={fixRequestPDF} />

                <PrivateRoute path={`${this.props.match.url}/report/fortenant/:tabName`} component={FixrequestReport} />
                <PrivateRoute path={`${this.props.match.url}/report/forcommon/:tabName`} component={FixrequestReportCommon} />
                
                <PrivateRoute path={`${this.props.match.url}/chat/:for/:id/:status`} component={fixRequestChat} />
                <PrivateRoute path={`${this.props.match.url}/chat/:for/:id/:status`} component={fixRequestChat} />
                <PrivateRoute exact path={`${this.props.match.url}/fortenant/:tabName`} component={fixRequestListForTenent} />
                <PrivateRoute exact path={`${this.props.match.url}/forcommon/:tabName`} component={fixRequestListForCommon} />
                {/* <PrivateRoute path={`${this.props.match.url}/fix-request`} component={FixRequest}/> */}
                {/* <PrivateRoute path={`${this.props.match.url}/fortenant/:tabName/:worksheetId`} component={fixRequestListForCommon} />
                <PrivateRoute path={`${this.props.match.url}/forcommon/:tabName/:worksheetId`} component={fixRequestListForCommon} /> */}

                <PrivateRoute path={`${this.props.match.url}/schedule`} component={Schedule} />
                <Route component={NoMatch} />
            </Switch>
        );
    }
}

export default FixRequestSingha;