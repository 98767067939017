/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type paySummaryProductAndServiceListQueryVariables = {||};
export type paySummaryProductAndServiceListQueryResponse = {|
  +productViewer: ?{|
    +allProduct: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +productCode: string,
        |}
      |}>
    |}
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
        |}
      |}>
    |}
  |},
|};
export type paySummaryProductAndServiceListQuery = {|
  variables: paySummaryProductAndServiceListQueryVariables,
  response: paySummaryProductAndServiceListQueryResponse,
|};
*/


/*
query paySummaryProductAndServiceListQuery {
  productViewer {
    allProduct(type_In: "product, expense", isActive: true) {
      edges {
        node {
          id
          name
          productCode
        }
      }
    }
    id
  }
  viewer {
    allChartOfAccount(codeList: ["1210", "1151", "1154", "2121", "2122", "2123", "2124", "2125", "2126"]) {
      edges {
        node {
          id
          chartOfAccountCode
          name
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "isActive",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "type_In",
      "value": "product, expense"
    }
  ],
  "concreteType": "ProductAndServiceNodeConnection",
  "kind": "LinkedField",
  "name": "allProduct",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAndServiceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allProduct(isActive:true,type_In:\"product, expense\")"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "codeList",
      "value": [
        "1210",
        "1151",
        "1154",
        "2121",
        "2122",
        "2123",
        "2124",
        "2125",
        "2126"
      ]
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chartOfAccountCode",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allChartOfAccount(codeList:[\"1210\",\"1151\",\"1154\",\"2121\",\"2122\",\"2123\",\"2124\",\"2125\",\"2126\"])"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "paySummaryProductAndServiceListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "paySummaryProductAndServiceListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c1644fc0927595818935be116d4169d",
    "id": null,
    "metadata": {},
    "name": "paySummaryProductAndServiceListQuery",
    "operationKind": "query",
    "text": "query paySummaryProductAndServiceListQuery {\n  productViewer {\n    allProduct(type_In: \"product, expense\", isActive: true) {\n      edges {\n        node {\n          id\n          name\n          productCode\n        }\n      }\n    }\n    id\n  }\n  viewer {\n    allChartOfAccount(codeList: [\"1210\", \"1151\", \"1154\", \"2121\", \"2122\", \"2123\", \"2124\", \"2125\", \"2126\"]) {\n      edges {\n        node {\n          id\n          chartOfAccountCode\n          name\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '84a1fcf210cdd949a192dfc87049a4d6';

module.exports = node;
