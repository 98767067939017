import React, { Component } from "react";
import { Translation } from "react-i18next";
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import {commitMutation} from "react-relay";
import './settingFee.scss'
import _ from 'lodash'
import Loading from '../../libs/loading';
import i18next from "i18next";

const query = graphql`
query debtFreeCertificationQuery{
    allSettingDebsFreeCertification {
        edges{
            node{
                id
                fee
                charge
                waiting
                lifetime
            }
        }
    }
}`;

const mutation = graphql`
  mutation debtFreeCertificationMutation($input: CreateAndUpdateSettingDebsFreeCertificationInput!)  {
    createAndUpdateSettingDebsFreeCertification (input: $input) {
        ok
        message   
    }
  }
`;


class DebtFreeCertification extends Component {
    constructor(props){
        super(props)
        this.state = {
            fee : false,
            charge : null,
            waiting : null,
            lifetime : null,
            loading : false,
            loadingData : false,
            id : '',
            permissionEdit : _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_settingdebsfreecertification_edit'}) ?
                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_settingdebsfreecertification_edit' }) : false
        }
    }

    componentDidMount(){
        this.fetchQuery()
    }

    fetchQuery = () => {
        this.setState({loadingData : true})
        fetchQuery(environment, query, {}).then((data) => {
            if(data.allSettingDebsFreeCertification && data.allSettingDebsFreeCertification.edges.length > 0){
                this.setState({
                    id : data.allSettingDebsFreeCertification.edges[0].node.id,
                    charge : data.allSettingDebsFreeCertification.edges[0].node.charge,
                    waiting : data.allSettingDebsFreeCertification.edges[0].node.waiting,
                    lifetime : data.allSettingDebsFreeCertification.edges[0].node.lifetime,
                    fee : data.allSettingDebsFreeCertification.edges[0].node.fee, 
                },() => this.setState({
                    loadingData : false
                }))
            }else{
                this.setState({loadingData : false})
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }


    onSubmit = (e) => {
        e.preventDefault();
        this.setState({loading:true})
        let variables = {
            input : {
                settingDebsFree: {
                    settingDebsFreeCertificationId : this.state.id,
                    fee : this.state.fee,
                    charge : this.state.charge === '' ? null : this.state.charge,
                    waiting : this.state.waiting === '' ? null : this.state.waiting,
                    lifetime : this.state.lifetime === '' ? null : this.state.lifetime,
                }
            }
        }
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createAndUpdateSettingDebsFreeCertification && response.createAndUpdateSettingDebsFreeCertification?.ok) {
                        Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                            this.setState({loading: false});
                        });
                    } else {
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning').then(() =>{
                            this.setState({loading: false});
                        })
                    }
                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                },
            },
        )

    }

    render(){
        return(
            <Translation>
                {
                    t => 
                    <React.Fragment>
                        {this.state.loadingData ? 
                         <Loading/>
                            :
                            <div className="set-info" id="setting-fee">
                                <form onSubmit={this.onSubmit} id="set-fee">
                                    <div className="row col-12 pr-4 pl-4" >
                                        <div className="row mt-4">
                                            <div className="col-12 form-inline">
                                                <h5 className="mr-4">{i18next.t("debtFreeCertification:Issuance Fee")}</h5>
                                                
                                            </div>
                                            <div className="row col-12 mt-2 d-flex align-items-center">
                                                <div className="col-1 d-flex justify-content-center">
                                                    <input type="radio" name="fee" 
                                                        id="false"
                                                        className="radio-size"
                                                        onChange={() => this.setState({fee: false})} 
                                                        checked={!this.state.fee}
                                                        disabled={!this.state.permissionEdit}
                                                    />
                                                </div>
                                                <div className="col-11">
                                                    <span htmlFor="false">
                                                    {i18next.t("debtFreeCertification:No Request Fee")}
                                                    </span>
                                                </div>
                                                <div className="col-1 d-flex justify-content-center mt-2">
                                                    <input type="radio" name="fee" 
                                                        id="true"
                                                        className="radio-size"
                                                        onChange={() => this.setState({fee: true})} 
                                                        checked={this.state.fee}
                                                        disabled={!this.state.permissionEdit}
                                                    />
                                                </div>
                                                <div className="col-11 mt-2" >
                                                    <span htmlFor="true">
                                                    {i18next.t("debtFreeCertification:Fee Applied")}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row col-12 mt-3  ml-1 form-inline">
                                                <input type="number" className="form-control mr-3" 
                                                    value = {this.state.charge || ''} name="charge"
                                                    onChange={this.handleChange}
                                                    disabled={!this.state.fee || !this.state.permissionEdit}
                                                    required={true}
                                                />
                                                <span className={!this.state.fee ? 'disable' : ''}>{i18next.t("debtFreeCertification:THB / Set")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row col-12 pr-4 pl-4 mt-3" >
                                        <div className="row mt-4">
                                            <div className="col-12 form-inline">
                                                
                                                <h5 className="mr-4">{i18next.t("debtFreeCertification:Processing Time After Application Submission")}
                                                    <div className="tooltipsSpan"><img src={process.env.PUBLIC_URL + "/images/iconAction/light.png"} width={18} style={{marginBottom:'10px'}}  />
                                                        <span className='tooltiptext' style={{fontSize:'13px',width:'400px'}} >
                                                            {i18next.t("debtFree:If the document creation date is set to 01/01/2024 the system will display 08/01/2024 as the document issuance date for selection")}
                                                        </span>
                                                    </div>
                                                </h5> 
                                            </div>
                                            <div className="row col-12 mt-2 d-flex align-items-center">
                                                <div className="col-12 form-inline">
                                                    <span htmlFor="false" className="mr-2">
                                                    {i18next.t("debtFreeCertification:Waiting Time")}
                                                    </span>
                                                    <input type="number" className="form-control col-2 mr-2" 
                                                        value = {this.state.waiting || ''} name="waiting"
                                                        onChange={this.handleChange}
                                                        required={true}
                                                        min={0}
                                                        step={1}
                                                        disabled={!this.state.permissionEdit}
                                                    />
                                                    <span>{i18next.t("debtFreeCertification:Day(s)")}</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row col-12 pr-4 pl-4 mt-3" >
                                        <div className="row mt-4">
                                            <div className="col-12 form-inline">
                                                <h5 className="mr-4">{i18next.t("debtFreeCertification:Document Validity")}
                                                    <div className="tooltipsSpan"><img src={process.env.PUBLIC_URL + "/images/iconAction/light.png"} width={18} style={{marginBottom:'10px'}}  />
                                                        <span className='tooltiptext' style={{width:'200px'}} >
                                                            {i18next.t("debtFree:Counting from the document issuance date.")}
                                                        </span>
                                                    </div>
                                                </h5>
                                                
                                            </div>
                                            <div className="row col-12 mt-2 d-flex align-items-center">
                                                <div className="col-12 form-inline">
                                                    <span htmlFor="false" className="mr-2">
                                                    {i18next.t("debtFreeCertification:Document Validity Period")}
                                                    </span>
                                                    <input type="number"
                                                        className="form-control col-2 mr-2" 
                                                        step={1}
                                                        min={1}
                                                        required={true}
                                                        value = {this.state.lifetime || ''} name="lifetime"
                                                        onChange={this.handleChange}
                                                        disabled={!this.state.permissionEdit}
                                                    />
                                                    <span>{i18next.t("debtFreeCertification:Day(s)")}</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {this.state.permissionEdit && 
                                    <div className="row" id="setting-fee">
                                        <div className="col-11 d-flex justify-content-end p-4">
                                            <button type="submit" className="btn-confirm" form="set-fee">
                                                {this.state.loading && <span className="spinner-border spinner-border-sm spinner-border-white align-middle mr-2"/>}
                                                {i18next.t("Allaction:save")}
                                            </button>
                                        </div>
                                        <div className="col-1"/>
                                    </div>
                                }
                            </div>
                        }
                    </React.Fragment>
                }
                
            </Translation>
        );
    }



}
export default DebtFreeCertification;