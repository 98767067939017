import React, {Component} from 'react';
import {NavLink, Link} from "react-router-dom";
import { Translation } from "react-i18next";
import "../projectDashboard.scss"


class Navigation extends Component {
    render() {
        return (
            <div className="row projectNav" id="navigation-tab">
                <div className="col">
                    <Translation>
                        {t => 
                            <ul className='mt-0'>
                                <li>
                                    <NavLink exact={true} to="/project/project-manager/list/current-board">
                                         <span>{t("projectManager:Current Board")}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/project/project-manager/list/old-board">
                                         <span>{t("projectManager:Old Board")}</span>
                                    </NavLink>
                                </li>
                                <li className="float-right text-right mr-0">
                                    <Link to={'/setting/project-manager'} className='p-0'><h4 className='linkSetting'>{t("setting:Setting Juristic Committee")}</h4></Link>
                                </li>
                            </ul>
                        }
                    </Translation>
                </div>
            </div>
        )
    }
}

export default Navigation; 