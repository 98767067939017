import React, { Component } from 'react'
import NavigationMenuSetup from '../libs/navigationMenuSetup'
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../env/environment';
import _ from "lodash";

const query = graphql`
    query stockTopMenuNavigationQuery{
        checkSpecialSite
    }
`;

export default class stockTopMenuNavigation extends Component {

  componentDidMount() {
    if (this.props.center) {
      NavigationMenuSetup();
    }
    this.getData()
  }

  constructor(props){
    super(props)
    this.state = {
      checkSpecialSite:false
    }
  }

  getData = () => {
    fetchQuery(environment, query).then((response) => {
      this.setState({checkSpecialSite:response.checkSpecialSite})
    })
  }
  render() {
    return (
      <div className="container-fluid bg-gray">

        <div className={"row "
          + (this.props.mini ? "mini " : "")
          + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

          {this.props.center &&
            <Translation>{t => <div className="col-12 mt-5 mb-5"><h3 className="text-center">{t("set_permission:Stock")}</h3></div>}</Translation>
          }

          <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
            <ul>
            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_productstock' }) &&
            //  localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                <li>
                  <Link to="/stockandsupplies/stockManagement/list/all">
                    <img
                      src={process.env.PUBLIC_URL + '/images/topMenuNavigation/stock/stockManagement.png'}
                      alt="news-top-menu"
                      className={this.props.mini && 'd-none'} />
                    <Translation>
                      {
                        t => <div >
                          {t('set_permission:Stock and Product')+" "+"(STF)"}
                          {/* {this.state.countPurchaseRequisition !== "0" &&
                            <span className="badge badge-pill badge-danger">
                              {this.state.countPurchaseRequisition}
                            </span>
                          } */}
                          </div>
                      }
                    </Translation>
                  </Link>
                </li>
              }

              {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_numkey' }) &&
              //  localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                <li>
                  <Link to="/stockandsupplies/reportStock/list" className="pr-4 d-flex align-items-center">
                    <img
                      style={{width: "7%"}}
                      src={process.env.PUBLIC_URL + '/images/topMenuNavigation/stock/reportStock.png'}
                      alt="news-top-menu"
                      className={this.props.mini && 'd-none'} />
                    <Translation>
                      {
                        t => <div >
                          {t('set_permission:Key of Product')}
                          {/* {this.state.countPurchaseRequisition !== "0" &&
                            <span className="badge badge-pill badge-danger">
                              {this.state.countPurchaseRequisition}
                            </span>
                          } */}
                          </div>
                      }
                    </Translation>
                  </Link>
                </li>
              }

              {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_producttransaction' }) &&
              //  localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                <li>
                  <Link to="/stockandsupplies/stockMovementReport/list">
                    <img
                      src={process.env.PUBLIC_URL + '/images/topMenuNavigation/stock/stockMovementReport.png'}
                      alt="news-top-menu"
                      className={this.props.mini && 'd-none'} />
                    <Translation>
                      {
                        t => <div >
                          {t('set_permission:Product Transaction')}
                          {/* {this.state.countPurchaseRequisition !== "0" &&
                            <span className="badge badge-pill badge-danger">
                              {this.state.countPurchaseRequisition}
                            </span>
                          } */}
                          </div>
                      }
                    </Translation>
                  </Link>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    )
  }
}