import React, {Component} from 'react';
import Header from "../components/header/";
import Sidebar from "../components/sidebar/";
import Wrapper from "../components/wrapper/";
import WrapperContent from "../components/wrapper/wrapperContent";
import DataAnalyticMenuNavigaton from './dataAnalyticMenuNavigaton';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import './trackingDisplay.scss';
import DataAnalyticTrackingMenuList from './dataAnalyticTrackingMenuList';
import Navigation from './navigation';
class DataAnalyticTrackingMenu extends Component {

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <DataAnalyticMenuNavigaton mini={true} />
                    <div className="container-fluid box" id="invoice-list">
                    <div className="row justify-content-between">
                            <div className="col">
                            <h3>
                                <Link to="/dataAnalytic">
                                    <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/images/wrapperContent/back.png"
                                    }
                                    alt="back"
                                    className="back"
                                    />
                                </Link>
                                <Translation>
                                    {(t) => <span>{t("navigation:dataAnalytic")}</span>}
                                </Translation>
                                </h3>
                            </div>
                            <div className="col text-right">

                            </div>
                    </div>
                    <div className="content-inner">
                        <Navigation />
                        <div className="row"></div>
                        <DataAnalyticTrackingMenuList 
                            category={this.props.match.params.category}
                        />
                    </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default DataAnalyticTrackingMenu;
