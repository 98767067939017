import React from 'react';
import getDocumentNumber from "../../../../libs/getDocumentNumber";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import numberWithComma from "../../../../libs/numberWithComma";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import getNameResidential from "../../../../libs/getNameResidential";
import SelectRoleAccounting from '../../../../components/FucntionLib/selectRoleAccounting';
import { Translation } from "react-i18next";
const _ = require('lodash');

const query = graphql`
    query receiveTransactionListQuery($id_in: [String]!, $contact_id: ID!) {
        allInvoiceTransaction(id_In: $id_in, order: "invoice__doc_number"){
            totalCount
            edges{
                node{
                    id
                    description
                    unitItems
                    price
                    preTaxAmount
                    vat
                    paid
                    whtRate
                    whtRatePercent
                    total
                    productAndService{
                        productCode
                    }
                    chartOfAccount {
                        chartOfAccountCode
                    }
                    invoice {
                        id 
                        docNumber 
                        taxType 
                        added 
                        updated 
                        issuedDate
                        firstName
                        lastName
                        name
                        payGroup
                        address district city province postalCode
                        contact{
                            id
                            firstName
                            lastName
                        }
                    }
                    receiptDepositTransactionUsed {
                        edges{
                            node{
                                id
                                amount
                            }
                        }
                    }
                }
            }
        }
        contact(id: $contact_id) {
            id
            refNumber
            name
            firstName
            lastName

            registeredAddress
            registeredCountry
            registeredProvince
            registeredDistrict
            registeredCity
            registeredPostalCode
            typeOfContact

            ownerName
            mailingAddress
            mailingDistrict
            mailingCity
            mailingProvince
            mailingPostalCode
            
            renterName
            renterAddress
            renterDistrict
            renterCity
            renterProvince
            renterPostalCode

            agentName
            agentAddress
            agentDistrict
            agentCity
            agentProvince
            agentPostalCode
        }
        creditNoteViewer{
            allCreditNote(id_In: $id_in){
              totalCount
              edges{
                node{
                  id
                  docNumber
                  status
                  issuedDate
                  reason
                  price
                  description
                  
                }
              }
            }
        }
        receiptDepositTransactionViewer{
            allReceiptDepositTransaction(id_In: $id_in){
                totalCount
                edges {
                    node {
                        id
                        description
                        price
                        balance
                        receiptDeposit {
                            docNumber
                            status
                            issuedDate
                        }
                    }
                }
            }
        }
        allExtraMenu
        allProjects {
            edges {
            node {
            periodCreate
            }
          }
        }
    }
`;


class ReceiveTransactionList extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            category: 'receive',
            issuedDate: new Date(),
            transactions: false,
            temp_month_before: '',
            temp_month_after: '',
            createInvoiceDate: new Date(), //เช็ควันที่ของออก invoice เลือกเอาวันที่มากที่สุด
            periodCreate: '',
            pay_group: "owner",
            name: "",
            address: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.calPretaxAmount = this.calPretaxAmount.bind(this);
        this.calPeriodMouth = this.calPeriodMouth.bind(this)
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                event.target.value
            );
        });
        this.props.updateIssuedDate(event.target.value);
    }

    async componentWillMount() {
        let transactions = [];
        this.props.transactions.forEach((transaction) => {
            transactions.push(transaction.transaction_id)
        });



        await fetchQuery(environment, query, { id_in: transactions, contact_id: this.props.customer })
            .then(response_data => {
                if (_.isObject(response_data.allInvoiceTransaction)) {
                    let data_set = _.cloneDeep(response_data);
                    let total = 0;
                    let wht = 0;
                    let discount = 0;
                    data_set.allInvoiceTransaction.edges.forEach((transaction) => {
                        this.props.transactions.forEach((selected_transaction) => {
                            if (transaction.node.id === selected_transaction.transaction_id) {
                                _.set(transaction, 'node.amount_pay', selected_transaction.amount_pay);
                                _.set(transaction, 'node.discount', selected_transaction.discount);
                                total += parseFloat(selected_transaction.amount_pay);
                                // wht += (parseFloat(selected_transaction.amount_pay) / (1 + parseFloat(transaction.node.vat || 0) / 100)) * (parseFloat(transaction.node.whtRatePercent) / 100);
                                wht += ((((parseFloat(selected_transaction.amount_pay) / (1 + parseFloat(transaction.node.vat || 0) / 100)) * 100) / (100 - transaction.node.whtRatePercent)) * (transaction.node.whtRatePercent / 100));
                                discount += parseFloat(selected_transaction.discount);
                            }
                        });
                    });

                    data_set.creditNoteViewer.allCreditNote.edges.forEach((credit_note) => {
                        this.props.transactions.forEach((selected_transaction) => {
                            if (credit_note.node.id === selected_transaction.transaction_id) {
                                total -= parseFloat(selected_transaction.amount_pay);
                            }
                        });
                    });

                    data_set.receiptDepositTransactionViewer.allReceiptDepositTransaction.edges.forEach((receipt_deposit) => {
                        this.props.transactions.forEach((selected_transaction) => {
                            if (receipt_deposit.node.id === selected_transaction.transaction_id) {
                                _.set(receipt_deposit, 'node.amount_pay', selected_transaction.amount_pay);
                                _.set(selected_transaction, 'is_receipt_deposit_transaction', true);
                                total -= parseFloat(selected_transaction.amount_pay);
                            }
                        });
                        total = total < 0 ? 0 : total;
                    });

                    let payGroup = data_set.allInvoiceTransaction?.edges[0].node.invoice.payGroup?.toLowerCase()
                    let addessWithInvoice = {
                        registeredAddress: data_set.allInvoiceTransaction?.edges[0].node.invoice.address,
                        registeredDistrict: data_set.allInvoiceTransaction?.edges[0].node.invoice.district,
                        registeredCity: data_set.allInvoiceTransaction?.edges[0].node.invoice.city,
                        registeredProvince: data_set.allInvoiceTransaction?.edges[0].node.invoice.province,
                        registeredPostalCode: data_set.allInvoiceTransaction?.edges[0].node.invoice.postalCode
                    }

                
                    // ชื่อที่อยู่ ตามใบแจ้งหนี้ 
                    let addrrss = this.addressData(payGroup !== "owner" ? addessWithInvoice : response_data.contact)
                    let name = response_data.contact.typeOfContact === "RESIDENTIAL" ? data_set.contact.refNumber + " " + getNameResidential(data_set.allInvoiceTransaction?.edges[0].node.invoice.firstName, data_set.allInvoiceTransaction?.edges[0].node.invoice.lastName) : data_set.contact.refNumber + " " + response_data.contact.name
                    this.setState({
                        transactions: data_set, total: total, periodCreate: data_set.allProjects.edges[0].node.periodCreate,
                        address: addrrss, name: name, pay_group: payGroup
                    });

                    this.setState({ transactions: data_set, total: total, periodCreate: data_set.allProjects.edges[0].node.periodCreate, contact: response_data.contact });
                    this.props.updateTotal(total + wht);
                    this.props.updateWht(wht || 0);
                    this.props.updateDiscount(discount);
                    this.props.updateData(payGroup);
                }
            });
        let allDate = [];
        if (this.state.transactions.allInvoiceTransaction.edges.length > 1) {
            this.state.transactions.allInvoiceTransaction.edges.forEach((value) => {
                allDate.push(new Date(value.node.invoice.issuedDate))
            })
        } else {
            allDate.push(new Date(this.state.transactions.allInvoiceTransaction.edges[0].node.invoice.issuedDate))
        }
        let sortDate = allDate.sort((date1, date2) => {
            return date1 - date2
        })
        let max_issuedDate = 0
        if (sortDate.length > 0) {
            max_issuedDate = sortDate.length - 1
        }
        this.setState({ createInvoiceDate: new Date(sortDate[max_issuedDate]) })
        this.props.updateMinDateInvoice(new Date(sortDate[max_issuedDate]))
        this.calPeriodMouth();

        // if(new Date() < new Date(sortDate[max_issuedDate])){
        //     this.setState({issuedDate : new Date(sortDate[max_issuedDate])})
        // }
    }

    // componentDidUpdate(){
    //     if(this.state.issuedDate < this.state.createInvoiceDate){
    //         this.setState({issuedDate : this.state.createInvoiceDate})
    //     }
    // }


    calPretaxAmount(type, transaction) {
        if (transaction.node.invoice.taxType === 'INCLUDE_TAX') {
            let pretax = ((parseFloat(transaction.node.amount_pay) + parseFloat(transaction.node.discount)) * (100 + (transaction.node.vat || 0))) / ((100 + (transaction.node.vat || 0)) - transaction.node.whtRatePercent);
            if (type === 'pretax') {
                return pretax
            } else if (type === 'wht') {
                return (pretax / (1 + (transaction.node.vat / 100))) * (transaction.node.whtRatePercent / 100)
            } else {
                return pretax - (pretax / (1 + ((transaction.node.vat || 0) / 100)))
            }
        } else {
            // let pretax = (parseFloat(transaction.node.amount_pay) + (parseFloat(transaction.node.preTaxAmount) * parseFloat(transaction.node.whtRatePercent) / 100)) - (parseFloat(transaction.node.preTaxAmount) * parseFloat(transaction.node.vat || 0) / 100);
            let pretax = parseFloat(transaction.node.preTaxAmount);
            if (type === 'pretax') {
                return pretax
            } else if (type === 'wht') {
                return (pretax * transaction.node.whtRatePercent / 100)
            } else {
                return (pretax * (1 + (transaction.node.vat / 100))) - pretax
            }
        }
    }

    calAmount(transaction, taxType) {
        // let sum_rd_use = transaction.receiptDepositTransactionUsed.edges.reduce(function (total, obj) {
        //     return total + parseFloat(obj.node.amount)
        // }, 0);
        // let amount = (parseFloat(transaction.price) * parseFloat(transaction.unitItems)) - parseFloat(transaction.discount_transaction || 0) - parseFloat(sum_rd_use);

        // let amount = (parseFloat(transaction.price) * parseFloat(transaction.unitItems)) - parseFloat(transaction.discount_transaction || 0);

        let amount = parseFloat(transaction.total)
        if (taxType === "EXCLUDE_TAX") {
            amount = amount * 1.07
        }

        return numberWithComma(amount)
    }

    calPeriodMouth() {
        let month_before = new Date();
        let month_after = new Date();

        let stringPeriodCreate = this.state.periodCreate;

        month_before.setMonth(month_before.getMonth() - parseInt(stringPeriodCreate));
        month_after.setMonth(month_after.getMonth() + parseInt(stringPeriodCreate));

        this.setState({
            temp_month_before: month_before,
            temp_month_after: month_after,
        })
    }
    getData = (pay_group) => {
        let name = "";
        let transactions = this.state.transactions.contact
        let contact = this.state.contact
        let addresss = "";
        if (pay_group === "agent") {
            name = transactions.refNumber + " " + contact.agentName
            addresss = contact.agentAddress +
                ` ${contact.agentDistrict}` +
                ` ${contact.agentCity}` +
                ` ${contact.agentProvince}` +
                ` ${contact.agentPostalCode}`
        } else if (pay_group === "rent") {
            name = transactions.refNumber + " " + contact.renterName
            addresss = contact.renterAddress +
                ` ${contact.renterDistrict}` +
                ` ${contact.renterCity}` +
                ` ${contact.renterProvince}` +
                ` ${contact.renterPostalCode}`
        } else {
            name = transactions.refNumber + " " + contact.ownerName
            addresss = contact.mailingAddress +
                ` ${contact.mailingDistrict}` +
                ` ${contact.mailingCity}` +
                ` ${contact.mailingProvince}` +
                ` ${contact.mailingPostalCode}`
        }

        this.setState({ pay_group: pay_group, name: name, address: addresss })
        this.props.updateData(pay_group)
    }

    addressData = (address) => {
        return (
            address.registeredProvince.includes('กรุงเทพ') || address.registeredProvince.includes('กทม') ? (
                <>
                    {address.registeredAddress}
                    {address.registeredDistrict && ` แขวง${address.registeredDistrict}`}
                    {address.registeredCity && ` เขต${address.registeredCity}`}
                    {address.registeredProvince && ` ${address.registeredProvince} `}
                    {address.registeredPostalCode}
                </>
            ) : (
                <>
                    {address.registeredAddress}
                    {address.registeredDistrict && ` ตำบล${address.registeredDistrict}`}
                    {address.registeredCity && ` อำเภอ${address.registeredCity}`}
                    {address.registeredProvince && ` จังหวัด${address.registeredProvince} `}
                    {address.registeredPostalCode}
                </>
            )
        )
    }
    render() {
        return (
            <React.Fragment>
                {this.state.transactions &&
                    <Translation>
                        {t => <React.Fragment>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <SelectRoleAccounting
                                            handleChangeSelect={(value) => this.getData(value)}
                                            value={this.state.pay_group}
                                            nameRole={this.state.pay_group}
                                        />
                                        <div className="col">
                                            <p><strong>{t('createReceive:Customer')}</strong></p>
                                            <div className="input-group">
                                                {this.state.transactions &&
                                                    <input type="text" className="form-control"
                                                        value={this.state.name}
                                                        disabled />
                                                }
                                            </div>
                                        </div>

                                        <div className="col">
                                            <p><strong>{t('createReceive:No.')}</strong></p>
                                            <div className="input-group">
                                                <input
                                                    value={getDocumentNumber.get(this.state.category, 'XXX', this.state.issued_date)}
                                                    type="text" className="form-control" disabled />
                                            </div>
                                        </div>

                                        <div className="col">
                                            <p><strong>{t('createReceive:Date')}</strong></p>
                                            <div className="input-group">
                                                {
                                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ?
                                                        <DatePickerAdapter
                                                            name="issuedDate" className="form-control"
                                                            selected={this.state.issuedDate}
                                                            onChange={this.handleInputChange}
                                                            required={true}
                                                            minDate={this.state.temp_month_before}
                                                        // maxDate={this.state.temp_month_after}
                                                        />
                                                        :
                                                        <DatePickerAdapter
                                                            name="issuedDate" className="form-control"
                                                            selected={this.state.issuedDate} /*maxDate={this.state.dueDate}*/
                                                            onChange={this.handleInputChange}
                                                            required={true}
                                                            minDate={this.state.createInvoiceDate}
                                                        />
                                                }

                                            </div>
                                        </div>

                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col">
                                            <p><strong>{t('createReceive:Address')}</strong></p>
                                            <p>{this.state.address}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive fade-up mt-4">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th width="80">{t('createReceive:List')}</th>
                                            <th width="200">{t('createReceive:Description')}</th>
                                            <th className="text-right" width="80">{t('createReceive:Total amount')}</th>
                                            <th className="text-right" width="80">{t('createReceive:Discount')}</th>
                                            <th className="text-right" width="80">{t('createReceive:Outstanding balance')}</th>
                                            <th className="text-right" width="100">{t('createReceive:Tax')}</th>
                                            <th className="text-right" width="100">{t('createReceive:Withholding tax')} ({t('createReceive:Baht')})</th>
                                            <th className="text-right" width="80">{t('createReceive:The selected amount')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.transactions &&
                                            this.state.transactions.allInvoiceTransaction.edges.map((transaction) =>
                                                <tr key={transaction.node.id}>
                                                    <td>
                                                        ({transaction.node.invoice.docNumber}) {transaction.node.productAndService ? transaction.node.productAndService.productCode : transaction.node.chartOfAccount.chartOfAccountCode}
                                                    </td>
                                                    <td>
                                                        {transaction.node.description.replace(/ *\[[^)]*\] */g, " ")}
                                                    </td>
                                                    {/*<td className="text-right">*/}
                                                    {/*    {numberWithComma(transaction.node.total)}*/}
                                                    {/*</td>*/}
                                                    <td className="text-right">
                                                        {this.calAmount(transaction.node, transaction.node.invoice.taxType)}
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(transaction.node.discount)}
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma((transaction.node.total - transaction.node.discount) - transaction.node.paid)}
                                                    </td>
                                                    <td className="text-right">
                                                        {/*{numberWithComma(transaction.node.amount_pay - (transaction.node.amount_pay / (1 + (transaction.node.vat / 100))))}*/}
                                                        {numberWithComma(this.calPretaxAmount('vat', transaction))}
                                                    </td>
                                                    <td className="text-right">
                                                        {/*--{numberWithComma((transaction.node.amount_pay / (1 + (transaction.node.vat / 100))) * (transaction.node.whtRatePercent / 100))}*/}
                                                        {this.calPretaxAmount('wht', transaction).toFixed(2)}
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(transaction.node.amount_pay)}</td>
                                                </tr>
                                            )}

                                        {this.props.paymentGateway &&
                                            this.props.paymentGateway.map((payment_gateway) => {
                                                let typecal = payment_gateway.type_cal === "PERCENT" ? 'ร้อยละ' : 'ต่อใบ'
                                                let detail = payment_gateway.payment_type + ' ' + typecal + ' ' + payment_gateway.fee

                                                return (
                                                    <tr key={payment_gateway.payment_type}>
                                                        <td>
                                                            {payment_gateway.description}
                                                        </td>
                                                        <td>
                                                            {
                                                                detail
                                                            }
                                                        </td>
                                                        <td className="text-right">
                                                            {numberWithComma(payment_gateway.fee_amount, '-')}
                                                        </td>
                                                        <td className="text-right">
                                                            -
                                                        </td>
                                                        <td className="text-right">
                                                            -
                                                        </td>
                                                        <td className="text-right">
                                                            -
                                                        </td>
                                                        <td className="text-right">
                                                            -
                                                        </td>
                                                        <td className="text-right">
                                                            {numberWithComma(payment_gateway.fee_amount, '-')}</td>
                                                    </tr>
                                                )
                                            }

                                            )}

                                        {this.state.transactions &&
                                            this.state.transactions.creditNoteViewer.allCreditNote.edges.map((credit_note) =>
                                                <tr key={credit_note.node.id}>
                                                    <td>
                                                        ({credit_note.node.docNumber})
                                                    </td>
                                                    <td>
                                                        {credit_note.node.description}
                                                    </td>
                                                    <td className="text-right">
                                                        - {numberWithComma(credit_note.node.price)}
                                                    </td>
                                                    <td className="text-right">
                                                        -
                                                    </td>
                                                    <td className="text-right">
                                                        - {numberWithComma(credit_note.node.price)}
                                                    </td>
                                                    <td className="text-right">
                                                        -
                                                    </td>
                                                    <td className="text-right">
                                                        -
                                                    </td>
                                                    <td className="text-right">
                                                        - {numberWithComma(credit_note.node.price)}</td>
                                                </tr>
                                            )}
                                        {this.state.transactions &&
                                            this.state.transactions.receiptDepositTransactionViewer.allReceiptDepositTransaction.edges.map((receiptDeposit) =>
                                                <tr key={receiptDeposit.node.id}>
                                                    <td>
                                                        ({receiptDeposit.node.receiptDeposit.docNumber})
                                                    </td>
                                                    <td>
                                                        {receiptDeposit.node.description}
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(receiptDeposit.node.price)}
                                                    </td>
                                                    <td className="text-right">
                                                        -
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(receiptDeposit.node.balance)}
                                                    </td>
                                                    <td className="text-right">
                                                        -
                                                    </td>
                                                    <td className="text-right">
                                                        -
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(receiptDeposit.node.amount_pay)}</td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>}
                    </Translation>
                }
            </React.Fragment>
        )
    }
}

export default ReceiveTransactionList;
