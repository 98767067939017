/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingReceiptIssueAndHolidayQueryVariables = {|
  year: number,
  receiveDate?: ?number,
|};
export type settingReceiptIssueAndHolidayQueryResponse = {|
  +calendarPage: ?any
|};
export type settingReceiptIssueAndHolidayQuery = {|
  variables: settingReceiptIssueAndHolidayQueryVariables,
  response: settingReceiptIssueAndHolidayQueryResponse,
|};
*/


/*
query settingReceiptIssueAndHolidayQuery(
  $year: Int!
  $receiveDate: Int
) {
  calendarPage(year: $year, receiveDate: $receiveDate)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "receiveDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "receiveDate",
        "variableName": "receiveDate"
      },
      {
        "kind": "Variable",
        "name": "year",
        "variableName": "year"
      }
    ],
    "kind": "ScalarField",
    "name": "calendarPage",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingReceiptIssueAndHolidayQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "settingReceiptIssueAndHolidayQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "34d7aa2eff542ccea3088e335b6d5be1",
    "id": null,
    "metadata": {},
    "name": "settingReceiptIssueAndHolidayQuery",
    "operationKind": "query",
    "text": "query settingReceiptIssueAndHolidayQuery(\n  $year: Int!\n  $receiveDate: Int\n) {\n  calendarPage(year: $year, receiveDate: $receiveDate)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8c959f4dc0cbb39120035584518037de';

module.exports = node;
