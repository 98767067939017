import React, { Component } from 'react'
import PrivateRoute from '../libs/privateRoute';
import DashboardStock from './dashboardStock'
import NoMatch from '../components/noMatch';
import { Route , Switch} from "react-router-dom";
import StockManagement from './stockMenagement';
import ReportStock from './reportStock';
import StockMovementReport from './stockMovementReport'

class stockandsupplies extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path={`${this.props.match.url}`} component={DashboardStock}/>
        <PrivateRoute path={`${this.props.match.url}/stockManagement`} component={StockManagement}/>
        <PrivateRoute path={`${this.props.match.url}/reportStock`} component={ReportStock}/>
        <PrivateRoute path={`${this.props.match.url}/stockMovementReport`} component={StockMovementReport}/>
        <Route component={NoMatch} />
      </Switch>
    )
  }
}
export default stockandsupplies;