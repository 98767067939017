import React, {Component} from 'react';
import {Translation} from "react-i18next";
import {Link} from "react-router-dom";
import NavigationMenuSetup from "../libs/navigationMenuSetup";


class FeedbackTopMenuNavigation extends Component {

    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
    }


    render() {
        return (
            <div className="container-fluid bg-gray">

            <div className={"row "
            +(this.props.mini ? "mini " : "")
            + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

                {this.props.center &&
                    <Translation>{t=><div className="col-12 mt-5 mb-5"><h3 className="text-center">{t("topMenuNavigation:register")}</h3></div>}</Translation>
                }

                <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
                    <ul>
                        {/* {
                            _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'tenant_tenant'}) 
                            && */}
                            <li>
                                <Link to="/feedback/feedback-dashboard/all">
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/topMenuNavigation/register/tenant-icon.png'}
                                        alt="tenant-icon"
                                        className={this.props.mini && 'd-none'}
                                    />
                                    <Translation>
                                        {
                                            t => <div>
                                                {t('feedback:feedback title')}
                                                </div>
                                        }
                                    </Translation>
                                </Link>
                            </li>
                        {/* } */}
                    </ul>
                </div>
            </div>
        </div>
        );
    }
}

export default FeedbackTopMenuNavigation;
