import React from 'react'
import _ from "lodash"
import ManageOrderTopNavigation from "./manegeTopMenuNavigation"
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import ComponentPagination from '../../libs/componentPagination';
import { format } from 'date-fns';
import Loading from '../../libs/loading';
import ManageOrderTableAll from "./manageOrderTableStatus/tableAll"
import ManageOrderTableWailPO from "./manageOrderTableStatus/tableWaitPO"
import ManageOrderTablePO from "./manageOrderTableStatus/tablePO"
import ReactPaginate from '../../libs/ReactPaginate';
import Swal from 'sweetalert2'
import CreatePurchaseOrderes from "./mutation/createPurchaseOrderes"
import DatePickerNoti from '../../accounting/finance/depositInterest/datePickerNoti';
import "./style/manageOrder.scss"
import { Translation } from 'react-i18next'
import i18next from 'i18next';

const query = graphql`
  query manageOrderTableQuery($status: String, $first: Int, $last: Int, $search: String, $startDate: Date, $endDate: Date, $firstStr: String, $lastStr: String){
    allApproveProcurementForm(search: $search, startDate: $startDate, endDate: $endDate, first: $first, last: $last){
      edges {
        node {
          id
          docNumber
          issuedDate
          status
          voidRemark
          creditor{
            id
            name
          }
          chartOfAccount{
            id name nameEn chartOfAccountCode 
          }
          buyDate
          type
          status
          total
          comparativeCompany
          lowestOffer
          highestOffer  
          contractor
          approveDate
        }
      }
      totalCount
    }
    allPurchaseOrderes(status: $status, search: $search, startDate: $startDate, endDate: $endDate, first: $first, last: $last){
      edges {
        node {
          id
          docNumber
          issuedDate
          status
          voidRemark
          voidDate
          voider
          approver
          approveDate
          procurementForm{
            creditor{
                id
                name
            }
            issuedDate
            buyDate
            type
            status
            total
            chartOfAccount{
              id name nameEn chartOfAccountCode 
            }
            comparativeCompany
            lowestOffer
            highestOffer  
            contractor
          }
        }
      }
      totalCount
    }
    listAllPurchaseOrderes(search: $search, startDate: $startDate, endDate: $endDate, first: $firstStr, last: $lastStr){
      dataTable
      numRow
    }
  }
`

export default class manageOrderTable extends ComponentPagination {

  constructor(props) {
    super(props)

    this.state = {
      startDate: null,
      endDate: null,
      textSearch: "",
      dataManageOrderListAll: [],
      dataManageOrderListWaitPO: [],
      dataManageOrderListPO: [],
      loading: false,
      totalCount: 0,
      firstStr: 0,
      lastStr: 10,
      page_count: 0,
      num_record: 0,
      all_item: 10,
      page_select: 0,
      selectedId: [],
      checkAll: false,
      signreq: Math.random().toString(36),
    }
  }

  componentWillMount() {
    this.goFirst()
  }

  componentDidMount() {
    this.getData()

  }

  componentDidUpdate(prevProps, prevState) {

    const { page_select, all_item, num_record } = this.state;

    if (prevProps.status !== this.props.status || prevState.first !== this.state.first || prevState.last !== this.state.last) {
      this.setState({ loading: true })
      this.getData()
    }

    if (prevState.page_select !== page_select) {
      this.getData(true);
    }

    if (prevState.all_item !== all_item) {
      let first_index = 0;
      let last_index = _.cloneDeep(all_item);
      let index_pages = [];
      let page_count = Math.ceil(num_record / all_item);
      if (page_count > 1) {
        for (let index = 0; index < page_count; index++) {
          index_pages[index] = {
            first: first_index,
            last: last_index,
          };
          if (index == page_count - 2) {
            first_index += all_item;
            last_index +=
              num_record % all_item != 0 ? num_record % all_item : all_item;
          } else {
            first_index += all_item;
            last_index += all_item;
          }
        }
      } else {
        index_pages[0] = {
          first: 0,
          last: num_record,
        };
      }
      this.setState(
        {
          page_select: 0,
          index_pages: index_pages,
        },
        () => this.getData(true)
      );
    }

    if (this.state.selectedId !== prevState.selectedId && this.state.selectedId.length === 0) {
      this.setState({
        checkAll: false
      })
    }
  }

  getData = (update, search) => {
    const {
      firstStr,
      lastStr,
      all_item,
      index_pages,
      page_select,
    } = this.state;

    let valable = {
      status: this.props.status !== "all" ? this.props.status : "",
      first: this.state.first,
      last: this.state.last,
      search: this.state.textSearch,
      firstStr: update || search === "search" ? index_pages[page_select].first.toString() : firstStr.toString(),
      lastStr: update || search === "search" ? index_pages[page_select].last.toString() : lastStr.toString(),
    }

    if (this.state.startDate) {
      valable.startDate = format(this.state.startDate, "YYYY-MM-DD")
    }
    if (this.state.endDate) {
      valable.endDate = format(this.state.endDate, "YYYY-MM-DD")
    }

    fetchQuery(
      environment,
      query,
      valable
    ).then((response) => {
      let newDataManageOrderListAll = []
      let newDataManageOrderListWaitPO = []
      let newDataManageOrderListPO = []
      let newTotalCount = 0

      if (response.allApproveProcurementForm && this.props.status === "waitPO") {
        _.forEach(response.allApproveProcurementForm.edges, (item, index) => {
          newDataManageOrderListWaitPO.push({
            docNumber: item.node.docNumber,
            id: item.node.id,
            issuedDate: item.node.issuedDate,
            approveDate: item.node.approveDate,
            creditor: item.node.creditor.name,
            catagory: item.node.chartOfAccount?.name || "-",
            type: item.node.type,
            total: item.node.total,
            status: item.node.status,
          })
        })
        newTotalCount = response.allApproveProcurementForm.totalCount
      }
      if (response.allPurchaseOrderes && (this.props.status === "wait" || this.props.status === "void" || this.props.status === "approve")) {
        _.forEach(response.allPurchaseOrderes.edges, (item, index) => {
          newDataManageOrderListPO.push(item)
        })
        newTotalCount = response.allPurchaseOrderes.totalCount
      }
      if (response.listAllPurchaseOrderes && this.props.status === "all") {
        let first_index = _.cloneDeep(firstStr);
        let last_index = _.cloneDeep(lastStr);
        let index_pages = [];
        let page_count = Math.ceil(response.listAllPurchaseOrderes.numRow / this.state.all_item);

        _.forEach(response.listAllPurchaseOrderes.dataTable, (item) => {
          newDataManageOrderListAll.push(JSON.parse(item))
        })

        if (page_count > 1) {
          for (let index = 0; index < page_count; index++) {
            index_pages[index] = {
              first: first_index,
              last: last_index,
            };
            if (index == page_count - 2) {
              first_index += all_item;
              last_index +=
                response.listAllPurchaseOrderes.numRow % all_item != 0
                  ? response.listAllPurchaseOrderes.numRow % all_item
                  : all_item;
            } else {
              first_index += all_item;
              last_index += all_item;
            }
          }
        } else {
          index_pages[0] = {
            first: 0,
            last: response.listAllPurchaseOrderes.numRow,
          };
        }

        this.setState({
          page_count: page_count,
          num_record: response.listAllPurchaseOrderes.numRow,
          index_pages: !update ? index_pages : this.state.index_pages,
        })

      }

      this.setState({
        dataManageOrderListAll: newDataManageOrderListAll,
        dataManageOrderListWaitPO: newDataManageOrderListWaitPO,
        dataManageOrderListPO: newDataManageOrderListPO,
        totalCount: newTotalCount,
        loading: false
      })
    })
  }

  handleSearch = () => {
    if (this.props.status === "all") {
      let index_pages = []
      index_pages[0] = {
        first: 0,
        last: this.state.all_item,
      }
      this.setState(
        {
          page_select: 0,
          index_pages: this.state.index_pages.length > 1 ? this.state.index_pages : index_pages
        },
        () => this.getData(false, "search")
      );
    } else {
      this.getData()
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handlePageClick = (event) => {
    let page = event.selected;
    this.setState({ page_select: page });
  };

  changePageLimit = (value) => {
    this.setState({
      all_item: value,
    });
  };

  onCheckAll = (props) => {
    let selected = []
    this.setState({ checkAll: !this.state.checkAll }, () => {
      if (this.state.checkAll) {
        _.forEach(props, (item) => {
          selected.push(item.id)
        })
        this.setState({
          selectedId: selected
        })
      } else {
        this.setState({
          selectedId: []
        })
      }
    })
  }

  onCheck = (id) => {
    let index = this.state.selectedId.indexOf(id);
    let selected = _.cloneDeep(this.state.selectedId)
    if (index === -1) {
      selected.push(id)
    } else {
      selected.splice(index, 1)
    }
    this.setState({
      selectedId: selected
    })
  }

  handleFiling = () => {

    const swalSaveFormManageRequest = Swal.mixin({
      customClass: {
        confirmButton: 'swal-BtnConfirm',
        cancelButton: 'swal-BtnCancel ml-3',
      },
      width: '640px',
      buttonsStyling: false
    })

    if (this.state.selectedId.length > 0) {
      swalSaveFormManageRequest.fire({
        html:
          `<img class="my-4" src="/images/iconAlerts/iconAlert-Blue.png" alt="icon-blue"/>
        <p><b>${i18next.t("manageOrderTable:Do you open purchase order")}?</b></p>`,
        showCancelButton: true,
        confirmButtonText: i18next.t("Allaction:Yes"),
        cancelButtonText: i18next.t("Allaction:cancel"),
      }).then(async (response) => {
        if (response.value) {

          let variables = {
            input: {
              procurementFormId: this.state.selectedId,
              clientMutationId: "PO" + this.state.signreq,
            }
          }

          CreatePurchaseOrderes(
            variables,
            (res) => {

              if (res.createPurchaseOrderes.ok) {
                Swal.fire(i18next.t("manageOrderTable:Successfully submit a purchase order"), '', 'success').then(() => {
                  window.location.reload();
                })

              } else {
                Swal.fire(i18next.t("manageOrderTable:Failed to submit a purchase order"), i18next.t("Allaction:Please try again."), 'warning')
              }
            },
            () => { Swal.fire(i18next.t("manageOrderTable:Failed to submit a purchase order"), '', 'warning') }
          )
        }
      })
    } else {
      Swal.fire(i18next.t("manageOrderTable:Please select an item"), '', 'warning')
    }

  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.getData()
    }
  }

  render() {
    return (
      <Translation>
        {
          t =>
            <div id="manageOrderTable">
              <ManageOrderTopNavigation />

              <div className="row my-5">
                {this.props.status === "waitPO" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_purchase_orderes_create' }) &&
                  <div className="col-3 d-flex justify-content-start">
                    <button className="btnWhite" onClick={this.handleFiling}>
                      <span>{t("PageListPRPO:Wait PO")} </span>
                    </button>
                  </div>
                }
                <div className="col d-flex justify-content-end">
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">{t("PageListPRPO:Date")}:</h5>
                    <div
                      className="mx-4"
                      style={{ width: 160 }}
                    >
                      <DatePickerNoti
                        selected={this.state.startDate}
                        name='startDate'
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">{t("PageListPRPO:To")}:</h5>
                    <div
                      className="mx-4"
                      style={{ width: 160 }}
                    >
                      <DatePickerNoti
                        selected={this.state.endDate}
                        name='endDate'
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="calendars-wrapper">
                    <form>
                      <input
                        type="text"
                        placeholder={t("PageListPRPO:Search")}
                        value={this.state.textSearch}
                        onChange={(event) => this.setState({ textSearch: event.target.value })}
                        style={{ fontFamily: "light", height: "48px", width: "300px" }}
                        className="calendars-wrapper colorborder form-control float-left"
                        onKeyDown={this.handleKeyDown}
                      />
                    </form>
                    <div
                      className="calendars float-right"
                      style={{ paddingRight: 25 }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/search-outline.png"
                        }
                        alt="calendars"
                        style={{ width: "17.81px", height: "17.81px" }}
                        onClick={this.handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {
                this.state.loading
                  ? <Loading />
                  : <React.Fragment>
                    {
                      this.props.status === "all" &&
                      <div style={{ marginBottom: "80px" }}>
                        <ManageOrderTableAll
                          dataManageOrderListAll={this.state.dataManageOrderListAll}
                        />
                        <ReactPaginate
                          state={this.state}
                          changePageLimit={this.changePageLimit}
                          handlePageClick={this.handlePageClick}
                        />
                      </div>
                    }

                    {
                      this.props.status === "waitPO" &&
                      <ManageOrderTableWailPO
                        dataManageOrderListWaitPO={this.state.dataManageOrderListWaitPO}
                        changePage={this.changePage}
                        first={this.state.first}
                        last={this.state.last}
                        totalCount={this.state.totalCount}
                        selectedId={this.state.selectedId}
                        checkAll={this.state.checkAll}
                        onCheckAll={this.onCheckAll}
                        onCheck={this.onCheck}
                      />
                    }

                    {
                      (this.props.status === "wait" || this.props.status === "void" || this.props.status === "approve") &&
                      <ManageOrderTablePO
                        status={this.props.status}
                        dataManageOrderListPO={this.state.dataManageOrderListPO}
                        changePage={this.changePage}
                        first={this.state.first}
                        last={this.state.last}
                        totalCount={this.state.totalCount}
                      />
                    }
                  </React.Fragment>
              }
            </div>
        }
      </Translation>

    )
  }
}

