import React, { Component } from 'react'
import PrivateRoute from '../../libs/privateRoute'
import NoMatch from '../../components/noMatch'
import { Route, Switch } from "react-router-dom";
import StockMovementReportList from './stockMovementReportList'
import StockMovementReportDetail from './stockMovementReportDetail';
import WrapperStockMovementReport from './documentStockMovementReport/wrapperStockMovementReport';

export default class index extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path={`${this.props.match.url}/list`} component={StockMovementReportList} />
        <PrivateRoute exact path={`${this.props.match.url}/stockMovementReportDetail/:id/:status/:statusTable`} component={StockMovementReportDetail} />
        <PrivateRoute exact path={`${this.props.match.url}/document`} component={WrapperStockMovementReport} />
        <Route component={NoMatch} />
      </Switch>
    )
  }
}
