import React, {Component} from 'react';
import $ from "jquery";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import "../styles/receive.scss"
import _ from "lodash";
import getNameResidential from "../../libs/getNameResidential";
import {getTypeProject} from "../../libs/getTypeOfProject";
import "../styles/new-styles.scss"
import i18n from 'i18next'
import { Translation } from 'react-i18next';
import paymentGatewayText from "../../libs/paymentGatewayText";
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';

const ThaiBaht = require('thai-baht-text');
require('number-to-text/converters/en-us');


class Receive extends Component {

    constructor(props) {
        super(props);
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
                10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
                10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
                10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
                10160, 10210, 10310 ,10280];

        //รับล่วงหน้า 2210 ค่าธรรมเนียมธนาคาร 5400 เงินคืนอื่นๆ 5830 เงินรอการตรวจสอบ 2230    
        let otherCode = ["2210-01" , "2210-02" , "2210-03"  , "5400-07" ,"5830-01"];

        this.state = {
            bangkokCode: bangkokCode,
            total: 0,
            total_cash_pay:0,
            total_credit_card_pay:0,
            total_all_pay:0,
            total_other_pay:0,

            sum_amount:0,
            sum_vat:0,
            sum_wht_rate:0,
            receiveDeposit: 0,
            sum_grand_total: 0,
            sum_discount: 0,
            discount: 0,
            discount_receive: 0,
            sum_receive_deposit: 0,

            have_vat:"",
            discount_status:"",
            diff: 0,
            remark : "",

            otherCode : otherCode,
            total_unknown_receive_pay : [],
            languages:"th"
        };
        this.calSummary = this.calSummary.bind(this);
        this.calPayAmount = this.calPayAmount.bind(this);
        this.allStatus = this.allStatus.bind(this);
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
        // this.setData = this.setData.bind(this);


    }

    componentDidMount() {
        this.calSummary();
        this.allStatus();
        // this.setData();
        this.setState({ languages : this.props.languages })
        i18n.changeLanguage(this.props.languages);

        setTimeout(() => {
            let page = $('.'+this.props.receiveNumber);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = 0

            if(signature === undefined){
                diff = page_height - (head + detail + invoice);
            }else{
                diff = page_height - (head + detail + invoice + signature);
            }
            $('.' + this.props.receiveNumber + ' table').css({borderBottom: "1px solid"});

            if (this.props.current_page !== this.props.end_page) {
                $('.' + this.props.receiveNumber + ' tbody tr:last-child td').css({paddingBottom: diff});
            } else {
                $('.' + this.props.receiveNumber + ' tbody tr:last-child td').css({paddingBottom: diff});
            }
            this.setState({diff: diff});
        }, 200);
    }
    // async setData(){
    //     let max_remark = {length:800,line:6}; //จำนวนตัวอักษรมากสุดของหมายเหตุ
    //     let max_transaction = 10; //จำนวนรายการหนี้มากสุดต่อ 1 หน้ากระดาษ (รวมทั้งหนี้ใหม่และหนี้เก่าค้างชำระ)
    //     let new_remark = ""; //หมายเหตุใหม่กรณีเกินที่กำหนด สำหรับใบแจ้งหนี้เก่าก่อนที่จะมีการกำหนด
    //     let count_line_remark = 0; //จำนวนบรรทัดของหมายเหตุ (แบบเฉลี่ยสำหรับกรณีไม่ได้เว้นบรรทัดจะหารจากตัวอักษรแทน)
    //     let history_transaction = [];
    //     let page = [];
    //         //ตัดหมายเหตุไม่ให้เกินที่กำหนดทั้งตัวอักษรและบรรทัด
    //         if(this.props.receive.remark.length > 0){
    //         //บรรทัด remark เกินที่กำหนด (กรณีพิมพ์แบบกดเว้นบรรทัด)
    //             if(this.props.receive.remark.split(/\r\n|\r|\n/).length > max_remark.line){
    //                 this.props.receive.remark.split(/\r\n|\r|\n/).map((remark,index)=> {
    //                     if(index+1 <= max_remark.line){
    //                         new_remark += remark + "\n";
    //                     }
    //                 })
    //                 count_line_remark = max_remark.line
    //             }else if(this.props.receive.remark.length > max_remark.length){
    //                 //จำนวนตัวอักษร remark เกินที่กำหนด
    //                 new_remark = this.props.receive.remark.slice(0,max_remark.length);
    //                 count_line_remark = max_remark.line;
    //             if(this.props.receive.remark.split(/\r\n|\r|\n/).length > 0){
    //                     //count_line_remark ใช้สำหรับวัดบรรทัดที่เหลือสำหรับใบแจ้งหนี้ปกติ เช่นถ้าไม่มีหมายเหตุ รายการที่มากสุดจะเพิ่มมาได้
    //                     count_line_remark = this.props.invoice.remark.split(/\r\n|\r|\n/).length;
    //                     this.props.receive.remark.split(/\r\n|\r|\n/).map((remark,index)=> {
    //                         if(remark.length > 133){
    //                         count_line_remark += Math.round(remark.length/133);
    //                         }
    //                     })
    //                 }else{
    //                     count_line_remark = Math.round(this.props.receive.remark.length/133);
    //                 }
    //             }else{
    //                 new_remark = this.props.receive.remark;
    //                 if(!this.props.selfProject.keyProjectQr){
    //                     if(this.props.receive.remark.split(/\r\n|\r|\n/).length > 0){
    //                         //count_line_remark ใช้สำหรับวัดบรรทัดที่เหลือสำหรับใบแจ้งหนี้ปกติ เช่นถ้าไม่มีหมายเหตุ รายการที่มากสุดจะเพิ่มมาได้
    //                         count_line_remark = this.props.receive.remark.split(/\r\n|\r|\n/).length;
    //                         this.props.receive.remark.split(/\r\n|\r|\n/).map((remark,index)=> {
    //                             if(remark.length > 133){
    //                             count_line_remark += Math.round(remark.length/133);
    //                             }
    //                         })
    //                     }else{
    //                         count_line_remark = Math.round(this.props.receive.remark.length/133);
    //                         }
    //                 }else{
    //                        count_line_remark = max_remark.line
    //                 }
    //             }
    //         }else{
    //             new_remark = this.props.receive.remark;
    //             count_line_remark = 0;
    //         }
    //         if((max_remark.line - (count_line_remark*2)) > 0){
    //             max_transaction += (max_remark.line - (count_line_remark*2))
    //         }
    //         this.setState({
    //             remark : new_remark
    //         })
    // }

    calSummary(){
        let amount = 0; //จน.ของ * ราคา
        let sumAmount = 0; // amountทั้งหมด
        let discount = 0; //ส่วนลด
        let sumDiscount = 0; //ส่วนลดทั้งหมด
        let sumWhtRate = 0; //หัก ณ ที่จ่ายทั้งหมด
        let receiveDeposit = 0; //รับล่วงหน้า
        let sumReceiveDeposit = 0; //รวมหลังหักรับล่วงหน้า
        let sumVat = 0;//VAT ทั้งหมด
        let sumTotal = 0;// รวมหลังหักรับล่วงหน้า + VAT ทั้งหมด
        let discountReceive = 0;// ส่วนลด receive
        let sumGrandTotal = 0;//ยอดรวมสุทธิ
        let sumcreditNote = 0; //ยอดรวมใบลดหนี้
        this.props.receive.receiveTransaction.edges.forEach((transactions) => {
            
            if(this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' && transactions.node.transaction.vat !== null){
                amount = parseFloat(transactions.node.transaction.unitItems) * parseFloat(transactions.node.transaction.price);
                sumDiscount += amount - parseFloat(transactions.node.transaction.discount);
            }else{
                amount = parseFloat(transactions.node.amount);
                sumDiscount += amount
            }
            discountReceive += transactions.node.discount;//ส่วนลดของreceive

            sumAmount += parseFloat(amount);
            discount += parseFloat(transactions.node.transaction.discount);
            // transactions.node.transaction.receiptDepositTransactionUsed.edges.forEach((rd_dept) => {
            //     receiveDeposit += rd_dept.node.amount;
            // });

            //คำนวนรับล่วงหน้า
            if (transactions.node.transaction.invoice.taxType === "INCLUDE_TAX") {
                sumReceiveDeposit = (((parseFloat(sumDiscount) - receiveDeposit) * 100) / (100 + transactions.node.transaction.vat));
            } else if(this.props.receive.contact.typeOfContact === 'RESIDENTIAL') {
                sumReceiveDeposit = parseFloat(sumDiscount);
            } else {
                sumReceiveDeposit = parseFloat(sumDiscount) - receiveDeposit;
            }

            //คำนวนvat
            sumVat = parseFloat(sumReceiveDeposit) * (transactions.node.transaction.vat / 100);

            //คำนวนหัก ณ ที่จ่าย
            if(transactions.node.transaction.whtRatePercent !== 0){
                sumWhtRate = parseFloat(sumReceiveDeposit) * (transactions.node.transaction.whtRatePercent / 100);
            }

            //คำนวนจำนวนเงินรวม
            sumTotal = sumReceiveDeposit + sumVat;

            //คำนวนยอดรวมทั้งสิ้น
            if (transactions.node.transaction.invoice.taxType === "INCLUDE_TAX") {
                sumGrandTotal = parseFloat(sumTotal) || 0.00
            } else {
                sumGrandTotal = (sumReceiveDeposit + sumVat) || 0.00
            }
        });

        // sumGrandTotal = this.props.receive.total || 0;

        //CN
        this.props.receive.creditNoteReceive.edges.forEach((credit_note, index_credit) => {
            sumcreditNote = credit_note.node.price;
        })

        sumGrandTotal = sumGrandTotal - sumcreditNote;
        
        this.setState({
            sum_wht_rate: sumWhtRate.toFixed(2),
            sum_vat: sumVat.toFixed(2),
            sum_grand_total: sumGrandTotal.toFixed(2),
            discount_receive: discountReceive.toFixed(2),
            sum_amount: sumAmount.toFixed(2),
            sum_total: sumTotal.toFixed(2),
            sum_receive_deposit: sumReceiveDeposit.toFixed(2),
            receiveDeposit: receiveDeposit.toFixed(2),
            sum_discount: sumDiscount.toFixed(2),
            discount: discount.toFixed(2),
        },()=> this.calPayAmount());
    }

    allStatus() {
        if (this.props.receive.receiveTransaction.edges.length > 0) {
            //vat
            if (this.props.receive.receiveTransaction.edges[0].node.transaction.vat !== null) {
                this.setState({
                    have_vat: true,
                })
            } else if (this.props.receive.receiveTransaction.edges[0].node.transaction.vat === null) {
                this.setState({
                    have_vat: false
                })
            }
            //vat

            // discount
            if (this.props.receive.receiveTransaction.edges[0].node.transaction.discount !== 0) {
                this.setState({discount_status: true})
            } else {
                this.setState({discount_status: false})
            }
            //discount
        }
    }

    calPayAmount(){
        let total_all_pay = 0;
        let total_cash_pay = 0;
        let total_credit_card_pay = 0;
        let total_small_change = 0;
        let total_fee = 0;
        //total_other_pay ไม่ครบทุกกรณี
        let total_other_pay = 0;
        let total_unknown_receive_pay = [];

        if (this.props.cash_transaction.length > 0) {
            this.props.cash_transaction.forEach((cash)=>{
               total_cash_pay = total_cash_pay + cash.node.price;
               total_all_pay = total_all_pay + cash.node.price;
            })
        }

        if (this.props.bank_transaction.length > 0) {
            this.props.bank_transaction.forEach((bank) => {
                total_all_pay = total_all_pay + bank.node.price;

            })
        }
        if (this.props.cheque_transaction.length > 0) {
            this.props.cheque_transaction.forEach((cheque) => {
                total_all_pay = total_all_pay + cheque.node.price;

            })
        }
        if (this.props.credit_card_transaction.length > 0) {
            this.props.credit_card_transaction.forEach((credit_card) => {
                total_credit_card_pay = total_credit_card_pay + credit_card.node.amount;
                total_all_pay = total_all_pay + credit_card.node.amount;

            })
        }//other pay -----------
        if (this.props.small_change.length > 0) {
            this.props.small_change.forEach((small_change) => {
                total_small_change = total_small_change + small_change.node.amount;
                total_other_pay = total_other_pay + Math.abs(small_change.node.amount);
            })
        }
        if (this.props.fee.length > 0) {
            this.props.fee.forEach((fee) => {
                total_fee = total_fee + fee.node.amount;
                total_other_pay = total_other_pay + fee.node.amount;
            })
        }

        if(this.props.receiveTransaction.length >0){
            this.props.receiveTransaction.map((receive) => (
            total_other_pay = total_other_pay + receive.node.discount
            ))
        }
        //เพิ่ม other pay กรณี other code
        if(this.props.otherTransaction?.length > 0){
            this.props.otherTransaction.forEach((other) => {
                if(other.node.accountRecord.edges.length > 0){
                    other.node.accountRecord.edges.forEach((account) => {
                        if(this.isOther(account.node.chartOfAccountCode.chartOfAccountCode)){
                            if(parseFloat(account.node.credit) !== 0.0){
                                total_other_pay = total_other_pay + account.node.credit
                            }
                            if(parseFloat(account.node.debit) !== 0.0){
                                total_other_pay = total_other_pay + account.node.debit
                            }
                        }else if(account.node.chartOfAccountCode.chartOfAccountCode.includes('2230-01')){
                            total_unknown_receive_pay.push(account.node.debit)
                        }
                    })
                }
            })
        }else{
            if(this.props.otherTransaction_all === "all" && this.props.otherTransaction?.node){
                if(this.props.otherTransaction.node.accountRecord.edges.length > 0){
                    this.props.otherTransaction.node.accountRecord.edges.forEach((account) => {
                        if(this.isOther(account.node.chartOfAccountCode.chartOfAccountCode)){
                            if(parseFloat(account.node.credit) !== 0.0){
                                total_other_pay = total_other_pay + account.node.credit
                            }
                            if(parseFloat(account.node.debit) !== 0.0){
                                total_other_pay = total_other_pay + account.node.debit
                            }
                        }else if(account.node.chartOfAccountCode.chartOfAccountCode.includes('2230-01')){
                            total_unknown_receive_pay.push(account.node.debit)
                        }
                    })
                }
            }
        }

        this.setState({
            total_cash_pay: total_cash_pay.toFixed(2),
            total_all_pay: total_all_pay.toFixed(2),
            total_other_pay: total_other_pay.toFixed(2),
            total_credit_card_pay: total_credit_card_pay.toFixed(2),
            total_unknown_receive_pay: total_unknown_receive_pay,
        });
    }

    isBangkok(code) {
        if(_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {
        // let languages = this.state.languages
        let languages = i18n.languages[0];
   
        if(type === "district" && this.isBangkok(code)){
            return languages == "th" ? "แขวง" : "District "
        }
        else if(type === "city" && this.isBangkok(code)){
            return languages == "th" ? "เขต" : "County "
        }
        else if(type === "province" && this.isBangkok(code)){
            return languages == "th" ? "" : ""
        }
        else if(type === "province") {
            return languages == "th" ? "จ." : "Province "
        }
        else if(type === "district") {
            return languages == "th" ? "ต." : "District "
        }
        else if(type === "city"){
            return languages == "th" ? "อ." : "County "
        }
    }

    isOther(code) {
        if(_.startsWith(code,'2210')){
            return true;
        }
        if(_.indexOf(this.state.otherCode, code) >= 0) {
            return true;
        }
        return false;
    }
    isOtherCheck(others){
        let coss_check = false;
        if(this.props.otherTransaction_all){
            others.node.accountRecord.edges.forEach((other) => {
                if(this.isOther(other.node.chartOfAccountCode.chartOfAccountCode))coss_check = true;
            })
        }
        return coss_check
    }

    //check length transaction
    isCorrectOther(transaction){
        if(transaction > 0){
            return true;
        }
        return false;

    }

    nextLine(address) {
        return address = address.replace('แขวง', '\nแขวง')
    }

    render() {
        
        let checkNumPerpage = this.props.num_perpage ? this.props.num_perpage : 16;

        let order = this.props.current_page * checkNumPerpage;

        let nameOfCustomer = this.props.receive.receiveTransaction?.edges.length > 0 ?  this.props.receive.receiveTransaction?.edges[0].node.transaction.invoice.firstName : ''
        let lastNameOfCustomer = this.props.receive.receiveTransaction?.edges.length > 0 ?  this.props.receive.receiveTransaction?.edges[0].node.transaction.invoice.lastName :''
    
        //edit to ref_transaction = RT-2021080039 B134 B170
        if(this.props.refTransaction === "RT-2021080039" || this.props.refTransaction === "RT-2021100100"){
            order = this.props.current_page * 16;
        }
        else if(this.props.refTransaction === "RT-2022030155"){
            order = this.props.current_page * 15;
        }
        
        let split_address = []
        split_address = this.props.receive.contact.registeredAddress.split('หมู่ที่')
        let village = split_address.length > 0 ? 'หมู่ที่ ' + split_address[split_address.length-1] : ''

        let getType = getTypeProject(this.props.selfProject.typeOfProject)
        
        return (
            <div className={"print-page-a4 " + this.props.receiveNumber} id="receive" style={{position: 'relative'}}>
                <div className="subpage" id="style-document" style={{overflow: 'hidden'}}>
                    <div className="head">
                        <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                            <div style={{minWidth:100,minHeight:100}}>
                                <img src={this.props.selfProject.logo} alt="silverman"/>
                            </div>
                            <div>                                
                                <div style={{minWidth:'160%'}}>
                                    <strong>{i18n.languages[0] === "en" ? (this.props.selfProject.nameEn || this.props.selfProject.name) : this.props.selfProject.name}</strong>
                                </div>
                                                                                         
                                {
                                    (this.props.selfProject.id === "UHJvamVjdE5vZGU6NDE2" || this.props.selfProject.id === 'UHJvamVjdE5vZGU6MzQz' || this.props.selfProject.id === 'UHJvamVjdE5vZGU6NjIy') ? 
                                    <div>
                                        <Translation>{t=><div className="address-header">{this.nextLine(i18n.languages[0] === "en" ?  (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address)} </div>}</Translation>
                                        <Translation>{t=><div className="address-header">{t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                    </div>
                                    :
                                    <Translation>{t=><div className="address-header">{i18n.languages[0] === "en" ?  (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address} {t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                }
                                <Translation>{t=><div>{t("document:Taxpayer identification number")} {this.props.selfProject.taxIdNumber || '-'}</div>}</Translation>
                            </div>
                        </div>

                        <div className={this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' &&
                        this.state.have_vat ? "document-name customer-vat" : "document-name"}>
                            {
                                this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' &&
                                this.state.have_vat ?
                                    <Translation>
                                        {t=>
                                        <React.Fragment>
                                            {t("document:Tax invoice/Receipt") === "Tax invoice/Receipt" ?
                                                    <div className="title-head">Tax invoice/Receipt</div>
                                                :
                                                <>
                                                    <div className="title-head">ใบกำกับภาษี/ใบเสร็จรับเงิน</div>
                                                    <div className="title-head">Tax invoice/Receipt</div>
                                                </>
                                                
                                            }
                                        </React.Fragment>}
                                    </Translation>
                                    :
                                    <Translation>
                                        {t=>
                                        <React.Fragment>
                                            {t("document:Receipt") === "Receipt" ?
                                                    <div className="title-head">Receipt</div>
                                                :
                                                <>
                                                    <div className="title-head">ใบเสร็จรับเงิน</div>
                                                    <div className="title-head">Receipt</div>
                                                </>
                                            }
                                        </React.Fragment>}
                                    </Translation>
                            }

                            {
                                this.props.check_type === "manuscript" ?
                                    <Translation>{t=><div className="title small">({t("document:Original")})</div>}</Translation>
                                    :
                                    <Translation>{t=><div className="title small">({t("document:Copy")})</div>}</Translation>
                            }
                        </div>

                        
                    </div>

                    <div className="detail">
                        <div className="customer-detail">
                            
                            <Translation>{t=><strong>{t("document:ID")}:</strong>}</Translation> {this.props.receive.contact.refNumber}<br/>
                            {this.props.receive.contact.typeOfContact === 'RESIDENTIAL' ?
                                <React.Fragment>
                                    <Translation>{t=><strong>{t("document:Customer")}: </strong>}</Translation>
                                    {
                                        this.props.query?.firstName ? 
                                        this.props.query?.firstName +" "+ this.props.query?.lastName :
                                        getNameResidential(nameOfCustomer, lastNameOfCustomer)
                                    }
                                     {/* {getNameResidential(nameOfCustomer, lastNameOfCustomer)}
                                     for edit mockup to pdf
                                     */} 
                                    <br/>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Translation>{t=><strong>{t("document:Customer")}:</strong>}</Translation> 
                                    {
                                         this.props.query?.firstName ? this.props.query?.firstName +" "+ this.props.query?.lastName : this.props.receive.name
                                    } <br/>
                                </React.Fragment>
                            }
                            <Translation>{t=><strong>{t("document:Address")}:</strong>}</Translation>

                            <div className="customer-detail address-space">
                                {/* UHJvamVjdE5vZGU6Mjk3 censirihome ตัดหมู่ที่อีกบรรทัด B274 */}
                                {this.props.selfProject.id === "UHJvamVjdE5vZGU6Mjk3" ?
                                    split_address.map((address,index) => {
                                        if(index+1 !== split_address.length){
                                            return <React.Fragment key={index}>{address + " "}</React.Fragment>
                                        }else{
                                            return <></>
                                        }
                                    })
                                    :
                                    this.props.query?.registeredAddress ?
                                    this.state.languages === "en" ?
                                    this.props.query?.registeredAddress.replace('เลขที่', 'No.').replace('ซอย', 'Soi').replace('หมู่ที่', 'Moo').replace('ถนน', 'Road') + " " :
                                    this.props.query?.registeredAddress + " " :
                                    this.state.languages === "en" ?
                                    this.props.receive.contact.registeredAddress.replace('เลขที่', 'No.').replace('ซอย', 'Soi').replace('หมู่ที่', 'Moo').replace('ถนน', 'Road') + " " :
                                    this.props.receive.contact.registeredAddress + " "
                                }

                                {/* {this.props.receive.contact.registeredDistrict && this.getPrefix("district", this.props.receive.contact.registeredPostalCode) + this.props.receive.contact.registeredDistrict + " "}  */}

                                {/* {this.props.query?.registeredDistrict ?  this.props.query?.registeredDistrict && this.getPrefix("district", this.props.query?.registeredPostalCode) + this.props.query?.registeredDistrict + " " : 
                                 this.props.receive.contact.registeredDistrict && this.getPrefix("district", this.props.receive.contact.registeredPostalCode) + this.props.receive.contact.registeredDistrict
                                } */}

                                {this.props.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ?
                                    <React.Fragment>
                                        {this.props.receive.contact.registeredDistrict && this.getPrefix("district", this.props.receive.contact.registeredPostalCode) + this.props.receive.contact.registeredDistrict + " "}<br/>
                                    </React.Fragment>:
                                    this.props.selfProject.id === 'UHJvamVjdE5vZGU6MzQz' ?
                                    <React.Fragment>
                                        {this.props.receive.contact.registeredDistrict && <React.Fragment><br/> {this.getPrefix("district", this.props.receive.contact.registeredPostalCode) + this.props.receive.contact.registeredDistrict + " "}</React.Fragment>}
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {this.props.selfProject.id === "UHJvamVjdE5vZGU6Mjk3" && <React.Fragment><br/>{village+" "}</React.Fragment>}
                                        
                                        {/* {this.props.receive.contact.registeredDistrict && this.getPrefix("district", this.props.receive.contact.registeredPostalCode) + this.props.receive.contact.registeredDistrict + " "} */}
                                        {this.props.query?.registeredDistrict ?  this.props.query?.registeredDistrict && this.getPrefix("district", this.props.query?.registeredPostalCode) + this.props.query?.registeredDistrict + " " : 
                                        this.props.receive.contact.registeredDistrict && this.getPrefix("district", this.props.receive.contact.registeredPostalCode) + this.props.receive.contact.registeredDistrict  + " "}
                                    </React.Fragment>
                                }
                                {
                                    this.props.query?.registeredCity ? this.props.query?.registeredCity && this.getPrefix("city", this.props.query?.registeredPostalCode) + this.props.query?.registeredCity + " "
                                    :this.props.receive.contact.registeredCity && this.getPrefix("city", this.props.receive.contact.registeredPostalCode) + this.props.receive.contact.registeredCity + " "
                                }
                                {
                                     this.props.query?.registeredProvince ? this.props.query?.registeredProvince && this.getPrefix("province", this.props.query?.registeredPostalCode) + this.props.query?.registeredProvince + " "
                                    : this.props.receive.contact.registeredProvince && this.getPrefix("province", this.props.receive.contact.registeredPostalCode) + this.props.receive.contact.registeredProvince + " "
                                }
                                {
                                    this.props.query?.registeredPostalCode ? this.props.query?.registeredPostalCode + " "
                                    : this.props.receive.contact.registeredPostalCode + " "}

                                {
                                    (this.props.receive.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.receive.contact.typeOfSupplier === "COMPANY") &&
                                    <Translation>
                                        {t=>
                                        <React.Fragment>
                                            {
                                                this.props.receive.contact.typeOfCompany === "HEADQUARTERS" ?
                                                    t("document:Head office")
                                                    : this.props.receive.contact.typeOfCompany === "BRANCH" &&
                                                    `${t("document:Branch")} ` + this.props.receive.contact.nameBranch
                                            }
                                        </React.Fragment>}
                                    </Translation>
                                }
                            </div>
                            <br/>
                            {this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' &&
                            <React.Fragment>
                                <Translation>{t=><strong>{t("document:Tax ID")}:</strong>}</Translation> {this.props.query?.taxIdNumber ? this.props.query?.taxIdNumber :this.props.receive.taxIdNumber} <br/>
                                <Translation>{t=><strong>{t("document:Attention")}:</strong>}</Translation> {this.props.receive.firstName} {this.props.receive.lastName}
                                &emsp;{this.props.receive.phone && "T: " + this.props.receive.phone}&emsp;{this.props.receive.email && "E: " + this.props.receive.email}
                            </React.Fragment>
                            }

                            {
                            
                            this.props.query?.firstName ? this.props.query?.firstName && ((this.props.query?.firstName.substring(0, 6) === "บริษัท") || (this.props.query?.firstName.substring(0, 4) === "บมจ."))  &&  this.props.receive.contact.typeOfContact === 'RESIDENTIAL' &&
                            <React.Fragment>
                                <Translation>{t=><strong>{t("document:Tax ID")}:</strong>}</Translation> {this.props.query?.taxIdNumber} <br/>
                            </React.Fragment>
                            :
                            this.props.receive.firstName && ((this.props.receive.firstName.substring(0, 6) === "บริษัท" )|| (this.props.receive.firstName.substring(0, 4) === "บมจ.")) && this.props.receive.contact.typeOfContact === 'RESIDENTIAL' &&
                            <React.Fragment>
                                <Translation>{t=><strong>{t("document:Tax ID")}:</strong>}</Translation> {this.props.receive.taxIdNumber} <br/>
                            </React.Fragment>

                            }
                            <br/>
                        </div>

                        <div
                            className={this.props.receive.contact.typeOfContact === 'RESIDENTIAL' ? "document-detail sub-document-detail" : "document-detail detail-outsider sub-document-detail"}>
                            <Translation>{t=>
                                <strong className={this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' ? "outsider sub-document-detail" : 'sub-document-detail'}>{t("document:No.")}:</strong>}</Translation> {this.props.receive.docNumber}<br/>
                            <Translation>
                                {t=>
                                <strong className={this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' ? "outsider sub-document-detail" : 'sub-document-detail'}>{t("document:Date")}:</strong>}</Translation> {format(this.props.receive.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}<br/>
                            {this.props.receive.contact.typeOfContact === 'RESIDENTIAL' &&
                            <React.Fragment>
                                <Translation>{t=><strong className="sub-document-detail">{getType === "highType" ? `${t("document:Room No.")}:` : `${t("document:House No.")}:`}</strong>}</Translation> {this.props.receive.contact.name} &nbsp;
                                <br/>
                                <Translation>{t=><><strong className="sub-document-detail">{t("document:Ratio/Space")}:</strong> {numberWithComma(this.props.receive.contact.residential.ownershipRatio)}/{numberWithComma(this.props.receive.contact.residential.size)} </>}</Translation>
                                <br/>
                            </React.Fragment>
                            }
                        </div>
                    </div>

                    <div className="invoice-body">
                        <table className="table table-bordered">
                            <thead>
                            <Translation>
                                {t=>
                                <tr>
                                    <th 
                                        width={50} 
                                        className="text-center" 
                                        dangerouslySetInnerHTML={{__html: t("document:No._br")}}/>
    
                                    {
                                        this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' &&
                                        this.state.have_vat ?
                                            <th 
                                                width={110} 
                                                className="text-center" 
                                                dangerouslySetInnerHTML={{__html: t("document:Code_br")}}/>
                                            :
                                            <th 
                                                width={110} 
                                                className="text-center" 
                                                dangerouslySetInnerHTML={{__html: t("document:Ref._No._br")}}/>
                                    }

                                    <th 
                                        className="text-center" 
                                        dangerouslySetInnerHTML={{__html: t("document:Description_br")}}/>
                                    
                                    {
                                        this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' &&
                                        this.state.have_vat &&
                                        <React.Fragment>
                                            <th 
                                                width={110} 
                                                className="text-center" 
                                                dangerouslySetInnerHTML={{__html: t("document:Unit_br")}}/>
                                            <th 
                                                width={110} 
                                                className="text-center" 
                                                dangerouslySetInnerHTML={{__html: t("document:Unit Price_br")}}/>
                                        </React.Fragment>
                                    }

                                    <th 
                                        width={100} 
                                        className="text-center" 
                                        dangerouslySetInnerHTML={{__html: t("document:Amount_br")}}/>
                                </tr>}
                            </Translation>
                            </thead>
                            <tbody>
                            {this.props.receiveTransaction.map((transaction, index) => {
                                order += 1;
                                return(
                                    <tr key={transaction.node.id}>
                                        <td className="text-center">
                                            {order}
                                        </td>

                                        {
                                            this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' &&
                                            this.state.have_vat ?
                                                <td className="text-center">{transaction.node.transaction.productAndService.productCode}</td>
                                                :
                                                <td className="text-center">{transaction.node.transaction.invoice.docNumber}</td>
                                        }

                                        <td>{transaction.node.transaction.description.replace(/ *\[[^)]*\] */g, " ")}</td>

                                        {
                                            this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' &&
                                            this.state.have_vat ?
                                                <React.Fragment>
                                                    <td className="text-right">{numberWithComma(transaction.node.transaction.unitItems)}</td>
                                                    <td className="text-right">{numberWithComma(transaction.node.transaction.price)}</td>
                                                    <td className="text-right">{numberWithComma(transaction.node.transaction.price * transaction.node.transaction.unitItems)}</td>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <td className="text-right">{numberWithComma(transaction.node.amount)}</td>
                                                </React.Fragment>
                                        }
                                    </tr>
                                )}
                            )}

                            {this.props.current_page === this.props.end_page && this.props.receive.creditNoteReceive.edges.map((credit_note, index_credit) => {
                                    let row_index = this.props.receive.receiveTransaction.edges.length + index_credit + 1;
                                    return (
                                        <tr key={credit_note.node.id}>
                                            <td className="text-center">
                                                {row_index}
                                            </td>
                                            <td className="text-center">
                                                {credit_note.node.docNumber}
                                            </td>
                                            <td>
                                                {credit_note.node.description}
                                            </td>
                                            <td className="text-right">
                                                - {numberWithComma(credit_note.node.price)}
                                                </td>
                                        </tr>
                                    )
                                }
                            )}

                            </tbody>
                            <tfoot>
                            {
                                this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' &&
                                this.state.have_vat &&
                                <React.Fragment>
                                    <tr>
                                        <td colSpan={3} rowSpan={
                                            !this.state.discount_status ? 4 :
                                                this.state.discount_status && 6
                                        }/>
                                        <Translation>
                                            {t=>
                                            <td colSpan={2}>
                                                {t("document:Amount")}
                                            </td>}
                                        </Translation>
                                        <td className="text-right">
                                            {numberWithComma(this.state.sum_amount, 0)}
                                        </td>
                                    </tr>

                                    {
                                        this.state.discount_status &&
                                        <React.Fragment>
                                            <tr>
                                                <Translation>
                                                    {t=>
                                                    <td colSpan={2}>
                                                        <strong>{t("document:Deduct")}&nbsp;</strong>{t("document:Discount")}
                                                    </td>}
                                                </Translation>
                                                <td className="text-right">
                                                    {numberWithComma(this.state.discount)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <Translation>
                                                    {t=><td colSpan={2}>
                                                        {t("document:Total Discount")}
                                                    </td>}
                                                </Translation>
                                                <td className="text-right">
                                                    {numberWithComma(this.state.sum_discount, 0)}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    }

                                    <tr>
                                        <Translation>
                                            {t=>
                                            <td colSpan={2}>
                                                <strong>{t("document:Deduct")}&nbsp;</strong>{t("document:Receipt_Deposit")}
                                            </td>}
                                        </Translation>
                                        <td className="text-right">
                                            {numberWithComma(this.state.receiveDeposit, 0)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <Translation>
                                            {t=>
                                            <td colSpan={2}>
                                                {t("document:Total Receipt Deposit")}
                                            </td>}
                                        </Translation>
                                        <td className="text-right">
                                            {numberWithComma(this.state.sum_receive_deposit, 0)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <Translation>
                                            {t=>
                                            <td colSpan={2}>
                                                {t("document:Vat")}
                                            </td>}
                                        </Translation>
                                        <td className="text-right">
                                            {numberWithComma(this.state.sum_vat, 0)}
                                        </td>
                                    </tr>

                                </React.Fragment>
                            }

                            {this.props.current_page === this.props.end_page &&
                            <tr>
                                {
                                    this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' &&
                                    this.state.have_vat ?
                                        <td colSpan={3} className="text-center">
                                            {/* ({ThaiBaht(this.state.sum_grand_total)}) */}
                                            {
                                                i18n.languages[0] === 'th' ? (ThaiBaht(this.state.sum_grand_total))
                                                : (ConvertNumberToTextEng(this.state.sum_grand_total) + " Baht")
                                            }
                                        </td>
                                        :
                                        <td colSpan={3}>
                                            <div className="row">
                                                <div className="col-1">
                                                    <Translation>{t=><strong>{t("document:Total")}</strong>}</Translation>
                                                </div>
                                                <div className="col-10 text-center">
                                                    <strong>
                                                        {/* ({ThaiBaht(this.state.sum_grand_total)}) */}
                                                        {
                                                             i18n.languages[0] === 'th' ? (ThaiBaht(this.state.sum_grand_total))
                                                                : (ConvertNumberToTextEng(this.state.sum_grand_total) + " Baht")
                                                        }
                                                    </strong>
                                                </div>
                                                <div className="col-1"/>
                                            </div>
                                        </td>
                                }

                                {
                                    this.props.receive.contact.typeOfContact !== 'RESIDENTIAL' &&
                                    this.state.have_vat ?
                                        <React.Fragment>
                                            <td colSpan={2}>
                                                <Translation>{t=><strong>{t("document:Grand Total")}</strong>}</Translation>
                                            </td>
                                            <td className="text-right">
                                                <strong>{numberWithComma(this.state.sum_grand_total)}</strong>
                                            </td>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <td className="text-right">
                                                <strong>{numberWithComma(this.state.sum_grand_total)}</strong>
                                            </td>
                                        </React.Fragment>
                                }
                            </tr>
                            }
                            </tfoot>
                        </table>

                        {this.props.current_page === this.props.end_page &&
                        <React.Fragment>
                            {this.props.receive.remark.length > 0 ?
                                <React.Fragment>
                                    <div className="remark">
                                        <Translation>{t=><strong>{t("document:Remarks")}:</strong>}</Translation> <br/>
                                    </div>
                                    <div className="remark" style={{whiteSpace: 'pre-line'}}>
                                        {this.props.receive.remark} <br/>
                                    </div>
                                </React.Fragment>
                                :
                                null                        
                            }                            
                            <div className="remark">
                                <Translation>{t=><strong>{t("document:Conditions of Payments")}</strong>}</Translation><br />
                                {this.props.cash_transaction.length > 0 &&
                                    <div className="row col ml-1" key ="cash">
                                        <Translation>{t=><span className="align-middle">{t("document:Cash")} </span>}</Translation>
                                        <div className="col">
                                            <Translation>{t=><span>{t("document:Amount_")} {numberWithComma(this.state.total_cash_pay)} {t("document:Baht")} </span>}</Translation>
                                        </div>
                                    </div>

                                }
                                {this.props.bank_transaction.length > 0 && 
                                    <div className="col ml-1" key="bank">
                                        <Translation>{t=><span className="align-middle">{t("document:Bank Transfer")} </span>}</Translation><br/>
                                        <div className = "col ml-2">
                                            {this.props.bank_transaction.map((bank,index_bank)=>{
                                                return <div className="row" key = {bank.node.bankAccount.accountNumber + index_bank}><Translation>{t=><span key = {bank.node.bankAccount.accountNumber}> - {bank.node.bankAccount.bankName}&emsp;{`${t("document:Account No.")} `+bank.node.bankAccount.accountNumber}&emsp;{`${t("document:Date_")} `+format(bank.node.date, 'DD/MM/YYYY', {locale: thLocale})}&emsp;{`${t("document:Amount_")} `+numberWithComma(bank.node.price)} {t("document:Baht")}<br /></span>}</Translation></div>
                                            })}
                                        </div> 
                                    </div>
                                }

                                {this.props.cheque_transaction.length > 0 &&
                                    <div className="col ml-1" key="cheque">
                                        <Translation>{t=><span className="align-middle">{t("document:Cheque Bank")}</span>}</Translation><br/>
                                        <div className = "col ml-2">
                                            {this.props.cheque_transaction.map((cheque,index_cheque)=>{
                                                return <div className="row" key = {cheque.node.chequeNumber+index_cheque}><Translation>{t=><span key = {cheque.node.chequeNumber}>- {t("document:Cheque No.")} {cheque.node.chequeNumber}&emsp;{cheque.node.bankName}&emsp;{t("document:Branch")} {cheque.node.branchNumber}&emsp;{t("document:Date_")} {format(cheque.node.date, 'DD/MM/YYYY', {locale: thLocale})}&emsp;จำนวนเงิน&nbsp;&nbsp;{numberWithComma(cheque.node.price)} {t("document:Baht")} <br /></span>}</Translation></div>
                                            })}
                                        </div> 
                                    </div>   
                                }
                                
                                {this.props.paymentGatewayTransaction && this.props.paymentGatewayTransaction.length > 0 &&
                                    <div className="col ml-1" key="payment_gateway">
                                        <Translation>{t=><span className="align-middle">{t("document:Payment Gateway")}</span>}</Translation><br/>
                                        <div className = "col ml-2">
                                            {this.props.paymentGatewayTransaction.map((payment_gateway,index_cheque)=>{
                                                
                                                if(payment_gateway.node.feeAmount > 0) {
                                                    return <div className="row" key = {payment_gateway.node.id+index_cheque}>
                                                        <Translation>
                                                            {t=> <span key = {payment_gateway.node.id}>{"วันที่ "+format(payment_gateway.node.depositDate, "DD/MM/YYYY")}<br />-&nbsp;{paymentGatewayText(payment_gateway.node.paymentType)}&nbsp;&nbsp;{numberWithComma(payment_gateway.node.amount)} {t("document:Baht")} <br />
                                                            -&nbsp;{t("paymentGateway:Payment Fee")} {numberWithComma(payment_gateway.node.feeAmount)}</span>}
                                                        </Translation>
                                                        </div>
                                                }else{
                                                    return <div className="row" key = {payment_gateway.node.id+index_cheque}>
                                                    <Translation>
                                                        {t=> 
                                                        <span key = {payment_gateway.node.id}>{"วันที่ "+format(payment_gateway.node.depositDate, "DD/MM/YYYY")}<br />-&nbsp;{paymentGatewayText(payment_gateway.node.paymentType)}&nbsp;&nbsp;{numberWithComma(payment_gateway.node.amount)} {t("document:Baht")} <br />
                                                        </span>}
                                                    </Translation>
                                                    </div>
                                                }
                                            })}
                                        </div> 
                                    </div>
                                }
                                {this.state.total_unknown_receive_pay.length > 0 &&
                                    <div className="row col ml-1 d-flex flex-column" key ="unknown-receive">
                                        {this.state.total_unknown_receive_pay.map((unknownReceive)=>(
                                        <React.Fragment>
                                                <Translation>{t=><span className="align-middle">{t("document:Unknown Receive")}   {t("document:Amount_")}{" "}{numberWithComma(unknownReceive.toFixed(2))}{" "}{t("document:Baht")}</span>}</Translation>
                                        </React.Fragment>
                                        ))}    
                                    </div>
                                }
                                {(parseFloat(this.state.total_other_pay) !== 0.0 || this.isCorrectOther(this.props.small_change.length) ||
                                    this.isCorrectOther(this.props.fee.length) || (this.props.otherTransaction ? this.isOtherCheck(this.props.otherTransaction) : false && this.props.otherTransaction_all) || this.state.discount_receive > 0 ) &&
                                        <div className="col ml-1" key ="other">
                                            <Translation>{t=><span className="align-middle">{t("document:Other")} </span>}</Translation><br/>
                                            <div className="col ml-2">
                                                {this.props.receiveTransaction.length > 0 && this.state.discount_receive > 0 &&
                                                    <div className = "row" >
                                                        <Translation>{t=><span>- {t("document:Discount_")} {t("document:Amount_")} {numberWithComma(parseFloat(this.state.discount_receive))} {t("document:Baht")}<br/></span>}</Translation>
                                                    </div>
                                                }
                                                {this.props.small_change.length > 0 &&
                                                    <div className="row">
                                                        {this.props.small_change.map((small_change,index) => {
                                                            return [<Translation>{t=><span key = {index} >- {t("document:The difference income from the penny")} {t("document:Amount_")} {(numberWithComma(Math.abs(small_change.node.amount)))} {t("document:Baht")}<br/></span>}</Translation>]
                                                        })}
                                                    </div>
                                                }
                                                {(this.props.otherTransaction && this.props.otherTransaction.length > 0) &&
                                                        this.props.otherTransaction[0].node.accountRecord.edges.map((other) => (
                                                            this.isOther(other.node.chartOfAccountCode.chartOfAccountCode) && 
                                                                <div key = {other.node.id} className="row">
                                                                    <Translation>{t=><span key = {other.node.id}>- {other.node.chartOfAccountCode.name } {t("document:Amount_")} {other.node.credit > 0 ? numberWithComma(other.node.credit) : numberWithComma(other.node.debit)} {t("document:Baht")} </span>}</Translation><br/>
                                                                </div>      
                                                        ))
                                                        
                                                }
                                                { (this.props.otherTransaction && this.props.otherTransaction_all)  &&
                                                        this.props.otherTransaction.node?.accountRecord?.edges?.map((other) => (
                                                            this.isOther(other.node.chartOfAccountCode.chartOfAccountCode) && 
                                                                <div key = {other.node.id} className="row">
                                                                    <Translation>{t=><span key = {other.node.id}>- {other.node.chartOfAccountCode.name } {t("document:Amount_")} {other.node.credit > 0 ? numberWithComma(other.node.credit) : numberWithComma(other.node.debit)} {t("document:Baht")}</span>}</Translation><br/>
                                                                </div>      
                                                        ))
                                                        
                                                }
                                            </div>
                                        </div>
                                }

                                {/* <div className="row">
                                    <div className="col-4 ml-3">
                                        <div className="row">
                                            <div className="col-1">
                                                {this.props.withheld_tax.length > 0 &&
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/icons/correct-signal.png'}
                                                    alt="correct-signal" className="collect"/>
                                                }
                                            </div>
                                            <div className="col ml-2">
                                                ภาษีหัก ณ ที่จ่าย/With holding tax
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.sum_wht_rate && this.props.withheld_tax.length > 0 ?
                                        <div className="col">
                                            จำนวน {numberWithComma(this.state.sum_wht_rate)} บาท
                                        </div>
                                        : <div className="col"/>
                                    }
                                </div> */}
                                
                            </div>
                        </React.Fragment>
                        }
                    </div>
                    {this.props.current_page === this.props.end_page &&
                    <div className="footer">

                        <div className="signature">
                            <div className="left">
                                .........................................................<br/>
                               <Translation>{t=><strong>{t("document:Authorized")}</strong>}</Translation>
                            </div>
                            <div className="right">
                                .........................................................<br/>
                                <Translation>{t=><strong>{t("document:Receiver")}</strong>}</Translation>
                            </div>
                        </div>
                    </div>
                    }
                    {this.props.end_page +1 !== 1 &&
                    <div className="number-page">
                        <br/>
                        {this.props.current_page + 1}/{this.props.end_page + 1}
                    </div>}

                </div>
            </div>
        );
    }
}

export default Receive;
