import React, { Component } from 'react'
import "./styles/alertWithholdPage.scss"

export default class alertWithholdPage extends Component {
  render() {
    return (
      <div className="container-fluid" id="alertWithholdPage">
        <div className="d-flex align-items-center justify-content-center container-alert">
          <div className="text-center">
            <img src={"/images/icons/popup-danger.svg"} className="img-show"/>
            <p className="headTop">ระบบของคุณถูกระงับการใช้งานชั่วคราว</p>
            <p className="headTop">กรุณาชำระค่าบริการ เพื่อเปิดใช้งานต่อ</p>
            <p className="headBottom" style={{marginTop: "24px"}}>สอบถามข้อมูลเพิ่มเติมได้ที่</p>
            <p className="headBottom">08-1442-6888</p>
            <p className="headBottom">06-5579-6844</p>
            <p className="headBottom">หรือ @silvermansupport</p>
          </div>
        </div>
      </div>
    )
  }
}
