import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import PurchaseCreateUpdateForm from "./core/PurchaseCreateUpdateForm"
import {Redirect} from 'react-router'
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import Loading from "../../../libs/loading"

const _ = require('lodash');

const purchaseForm = graphql`
    query purchaseFormQuery($id: ID!,$creditorId: String) {
        allSettingAsset{
            edges{
                node{
                    id
                    typeAsset
                    depreciationRatePercent
                    depreciationRate
                    lifeTimeYear
                    chartOfAccountType {
                        id
                        chartOfAccountCode
                        name
                        nameEn
                    }
                }
            }
        }
        contact(id: $id) {
            id
            refNumber
            name
            firstName
            lastName
            typeOfSupplier
            vat

            registeredAddress
            registeredCountry
            registeredProvince
            registeredDistrict
            registeredCity
            registeredPostalCode
            typeOfContact
        }
        productViewer{
            allProduct (type_In: "product, expense",isActive: true){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                        cost
                        noNumber
                        autoOrder
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        creditorChartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        description
                        totalLeftInStock
                        taxRate
                    }
                }
            }
        }
        viewer{
            allChartOfAccount(codeList: ["1210","1230","1151","1154","2121","2122","2123","2124","2125","2126"]){
                edges{
                    node{
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
        }
         selfProject{
            haveVat
        }

        allPurchaseOrderes(status: "approve",creditorId:$creditorId){
            totalCount
            edges {
                node {
                    id
                    docNumber
                    issuedDate
                    status
                    voidRemark
                    voidDate
                    voider
                    approver
                    approveDate
                    procurementForm {
                        id
                        docNumber
                        facilityUse
                        issuedDate
                        creditor{
                            id
                            name
                        }
                        type
                        buyDate
                        contractNumber
                        contractDate
                        paymentTerms
                        contractor
                        contractorTel
                        contractorEmail
                        deliveryAddress
                        deliveryDate
                        remark
                        total
                        procurementList{
                            edges{
                                node{
                                    id
                                    productAndService{
                                        id
                                        name
                                        productCode
                                        type
                                        price
                                        cost
                                        chartOfAccount{
                                            id
                                            chartOfAccountCode
                                            name
                                        }
                                        creditorChartOfAccount{
                                            id
                                            chartOfAccountCode
                                            name
                                        }
                                        noNumber
                                        autoOrder
                                        description
                                        totalLeftInStock
                                        taxRate
                                    }
                                    chartOfAccount{
                                        id
                                        chartOfAccountCode
                                        name
                                    }
                                    description
                                    unitItems
                                    price
                                    unit
                                    discount
                                    budget
                                    vat
                                    whtRate
                                    whtRatePercent
                                    preTaxAmount
                                    total
                                }
                            }
                        }
                        procurementFormImage{
                            edges{
                                node{
                                    fileUpload
                                    pathName
                                }
                            }
                        }
                        procurementFormContract{
                            edges{
                                node{
                                    fileUpload
                                    pathName
                                    fileName
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
            // allChartOfAccount(codeList: ["5","1210"]){

class PurchaseForm extends Component {

    render() {
        if (this.props.customerList.length > 0) {
            return (
                <div className="container-fluid box">
                    <div className="row">
                        <div className="col">
                            <h3 className="mb-4">
                                <Link to="/accounting/expense/purchase/form/select_customer">
                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                         alt="back" className="back"/>
                                </Link>
                                <Translation>
                                    {
                                        t => <span>{t('accounting:add_purchase')}</span>
                                    }
                                </Translation>
                            </h3>
                        </div>
                    </div>
                    <div className="content-inner">
                    <QueryRenderer
                        environment={environment}
                        query={purchaseForm}
                        variables={{
                            id: this.props.customerList[0],
                            creditorId: this.props.customerList[0],
                        }}
                        cacheConfig={{use_cache: false}}
                        render={({error, props}) => {
                            if (props) {
                                // set cost to price
                                const whtopen = props.contact.typeOfSupplier === "FOUNDATION" || props.contact.typeOfSupplier === "ASSOCIATION" ? false : true;
                                const vatopen = props.contact.typeOfSupplier === "FOUNDATION" || props.contact.typeOfSupplier === "ASSOCIATION" ? false : true;

                                let product_and_service_list = [];
                                props.productViewer.allProduct.edges.forEach((product) => {
                                    let product_clone = _.cloneDeep(product);
                                    product_clone.node.price = product_clone.node.cost;
                                    product_and_service_list.push(product_clone)
                                });
                                return <PurchaseCreateUpdateForm contactList={this.props.customerList}
                                                                contact={props.contact} invoiceQuery={props}
                                                                query={purchaseForm}
                                                                category={'purchase'}
                                                                product_and_service={product_and_service_list}
                                                                chart_of_account={props.viewer.allChartOfAccount.edges}
                                                                next_page={'/accounting/expense/purchase/list/all'}
                                                                back_page={'/accounting/expense/purchase/list/all'}
                                                                purchase_form={true}
                                                                project_have_vat={props.selfProject.haveVat}
                                                                openVat={vatopen}
                                                                openWht={whtopen}
                                                                allSettingAsset={props.allSettingAsset?.edges}
                                                                allPurchaseOrderes={props.allPurchaseOrderes?.edges}
                                                                purchaseNew={
                                                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'purchaseNew'])?.menu_on ?
                                                                    true
                                                                    :
                                                                    false
                                                                }

                                />;

                            } else {
                                return <Loading/>;
                            }
                        }}
                    />
                    </div>
                </div>
            )
        }
        return <Redirect to="/accounting/expense/purchase/form/select_customer"/>;
    }
}

export default PurchaseForm;
