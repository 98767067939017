import {
  commitMutation,

} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";


const mutation = graphql`
    mutation createClearGuaranteeMoneyReceivedAndTransactionMutation($input: CreateClearGuaranteeMoneyReceivedAndTransactionInput!){
        createClearGuaranteeMoneyReceivedAndTransaction(input: $input){
            ok
            warningText
        }
    }
`;

export default function CreateClearGuaranteeMoneyReceivedAndTransactionMutation(variables, callback, error_callback) {

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
          callback(response)
      },
      onError: (err) => {
          error_callback(err);
      },
    },
  )
}
