import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import ModalSumVote from "./modalSumVoteAndResult";

const query = graphql`
    query modalPrototypeQuery($id: ID!, $status: String) {
        allChoiceSummaryPercentage(surveyId: $id, status: $status){
            total
            choiceId
            countPerson
        }
    }
`;

class ModalPrototype extends Component {

    render() {
        return (
            <ModalSumVote survey_id={this.props.survey_id} type={this.props.type} all_vote={[]}
                          status={this.props.status}
                          query={query}/>
        );
    }
}

export default ModalPrototype;