import React, {Component} from 'react';
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import CustomerFilterReceivable from "./invoice/customerFilterReceivable";
import "./listContactResidential.scss";
import getNameResidential from '../../libs/getNameResidential';
import {Translation} from "react-i18next";
import Loading from '../../libs/loading';
import numberWithComma from "../../libs/numberWithComma";

class ContactResidentialListReceivable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            select_residential: true,
            all_residential: false,
            search: '',
            conveyance: 'all',
            select_renter: false,
            select_agent: false,
        };
        this.toggle_radio = this.toggle_radio.bind(this);
        this.checkSelect = this.checkSelect.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
    }

    async toggle_radio(e, contact) {
        let status_radioBox = e.currentTarget.name;
        if (status_radioBox === 'select_residential') {
            await this.setState({
                select_residential: true,
                all_residential: false,
                select_renter: false,
                select_agent: false,
            });
            this.props.onContactTypeChange();
        } else if(status_radioBox === 'select_agent'){
            await this.setState({
                select_residential: false,
                all_residential: false,
                select_renter: false,
                select_agent: true,
            });
            this.props.onSelectAllContact(contact, 'agent');
        } else if(status_radioBox === 'select_renter'){
            await this.setState({
                select_residential: false,
                all_residential: false,
                select_renter: true,
                select_agent: false,
            });
            this.props.onSelectAllContact(contact,'rent');
        } else {                
            await this.setState({
                select_residential: false,
                all_residential: true,
                select_renter: false,
                select_agent: false,
            });
            this.props.onSelectAllContact(contact);
        }
    }

    checkSelect() {
        if (!this.state.select_residential) {
            this.setState({
                select_residential: true,
                all_residential: false,
                select_renter: false,
                select_agent: false,
            });
        }
    }

    handleChangeInput(e) {
        this.setState({
            [e.target.name]: e.target.value,
            select_residential: true,
            all_residential: false,
            select_renter: false,
            select_agent: false,
        })
        this.props.onContactTypeChange();
    }

    render() {
        return (
            <div>
                <div className="row mt-5">
                    <div className="col-md-3 col-12">
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input"
                                   id="conveyance_all" name="conveyance"
                                   value="all" checked={this.state.conveyance === 'all'}
                                   onChange={this.handleChangeInput}/>
                            <label className="custom-control-label"
                                   htmlFor="conveyance_all">
                                <Translation>{t => t('selectContact:all')}</Translation>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input"
                                   id="conveyance_already_transferred" name="conveyance"
                                   value="already_transferred" checked={this.state.conveyance === 'already_transferred'}
                                   onChange={this.handleChangeInput}/>
                            <label className="custom-control-label"
                                   htmlFor="conveyance_already_transferred">
                                <Translation>{t => t('selectContact:transfer_ownership')}</Translation>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input"
                                   id="conveyance_not_transferred" name="conveyance"
                                   value="not_transferred" checked={this.state.conveyance === 'not_transferred'}
                                   onChange={this.handleChangeInput}/>
                            <label className="custom-control-label"
                                   htmlFor="conveyance_not_transferred">
                                <Translation>{t => t('selectContact:not_transfer_ownership')}</Translation>
                            </label>
                        </div>
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={this.props.query}
                    cacheConfig={{use_cache: false}}
                    variables={{
                        search: this.state.search,
                        contact_type: this.props.contact_type,
                        conveyance: this.state.conveyance,
                        receivable_filter: true
                    }}
                    render={({error, props}) => {
                        if (error) {
                            return <div>{error.message}</div>;
                        } else if (props) {
                            return <React.Fragment>
                                <div className="row">
                                    <div className="col-md-3 col-12">
                                        <div className="custom-control custom-radio">
                                            <input type="radio" className="custom-control-input"
                                                   id="select_residential_radio" name="select_residential"
                                                   checked={this.state.select_residential}
                                                   onChange={(e) => this.toggle_radio(e)}/>
                                            <label className="custom-control-label"
                                                   htmlFor="select_residential_radio">
                                                <Translation>{t => t('selectContact:select_room')}</Translation>
                                            </label>
                                        </div>
                                    </div>
                                    {this.props.accountType === "invoice" &&    
                                    <>                                
                                        <div className="col-md-3 col-12">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input"
                                                       id="all_residential_radio" name="all_residential"
                                                       checked={this.state.all_residential}
                                                       onChange={(e) => this.toggle_radio(e, props.contactViewer.allContact.edges)}/>
                                                <label className="custom-control-label"
                                                       htmlFor="all_residential_radio">
                                                    <Translation>{t => t('selectContact:co-owner_all-member')}</Translation>
                                                </label>
                                            </div>
                                        </div>                                                                                            
                                        <div className="col-md-3 col-12">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input"
                                                        id="renter_radio" name="select_renter"
                                                        value="not_transferred" checked={this.state.select_renter}
                                                        onChange={(e) => this.toggle_radio(e, props.contactViewer.allContact.edges)}/>
                                                    <label className="custom-control-label"
                                                        htmlFor="renter_radio">
                                                            <Translation>{t => t('selectContact:rent')}</Translation>
                                                    </label>
                                                </div>
                                        </div>
                                        <div className="col-md-3 col-12">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input"
                                                        id="agent_radio" name="select_agent"
                                                        value="not_transferred" checked={this.state.select_agent}
                                                        onChange={(e) => this.toggle_radio(e, props.contactViewer.allContact.edges)}/>
                                                    <label className="custom-control-label"
                                                        htmlFor="agent_radio">
                                                            <Translation>{t => t('selectContact:agent')}</Translation>
                                                    </label>
                                                </div>
                                        </div>
                                    </>
                                    }
                                </div>
                                <div className="row p-3 mt-1 ">
                                    <div className="col-12 col-md-5 card p-3 colSearch">
                                        <CustomerFilterReceivable queryObject={props.contactViewer.allContact.edges}
                                                        contactSelected={this.props.contactSelected}
                                                        checkSelect={this.checkSelect}
                                                        selectContact={this.props.selectContact}
                                                        activeRowSelectedContact={this.activeRowSelectedContact}
                                                        queryField="name:refNumber:firstName:lastName"
                                                        list_type={"residential"}
                                                        select_renter={this.state.select_renter}
                                                        select_agent={this.state.select_agent}
                                        />
                                    </div>
                                    <div className="col-12 col-md-7 card colSearch ">
                                        {/* <div className="row p-3"> */}
                                            <Translation>{t => 
                                                <div className="row p-3">{t('selectContact:selected_item')+' '}{this.props.accountType !== "report_history" && this.props.contact_count !== 0 && '(' + this.props.contact_count + t('selectContact:items')+ ')'}<span className='text-red'>&nbsp; &nbsp;{this.props.role ? `*ชื่อที่แสดงเป็นชื่อ${t(`selectContact:${this.props.role}`)}` : ""}</span></div>
                                            }
                                            </Translation>
                                        {/* </div> */}
                                        <div className="row scrollList">
                                            <div className="col-12">                                            
                                                {this.props.contactResidentialSelected.map((select_contact) => {
                                                    let nameRole = "";                                                    
                                                    if(this.props.role === 'agent'){
                                                        nameRole = select_contact.agentName
                                                    } else if(this.props.role === 'rent'){
                                                        nameRole = select_contact.renterName
                                                    }
                                                    return (
                                                        <div className="row p-2"
                                                                 key={'select_residential-' + select_contact.id}>
                                                            <div className="col-2 colResidentialRef">{select_contact.refNumber}</div>
                                                            <div className="col-2 colResidential">{select_contact.name}</div>
                                                            <div className="col colResidential">{this.props.role? nameRole : getNameResidential(select_contact.firstName,select_contact.lastName)}</div>
                                                            <div className="col colResidential">{numberWithComma(select_contact.allAmountNotPaid)}</div>
                                                        {/*{select_contact.refNumber}&emsp;&emsp;{select_contact.name}&emsp;{getNameResidential(select_contact.firstName,select_contact.lastName)}*/}
                                                    </div>
                                                )})}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        return <Loading/>
                    }}
                />
            </div>
        )
    }
}

export default ContactResidentialListReceivable;
