import { graphql } from "babel-plugin-relay/macro";

const queryAllRegisterCollectionLawFirmSite = graphql`
  query queryAllRegisterCollectionLawFirmSiteQuery{
        allRegisterCollectionLawFirmSite{
            edges{
              node{
                  site{
                      id
                      name
                      project{
                          edges{
                              node{
                                  id
                                  nameTh
                              }
                          }
                      }
  
                  }
                  
              }
          }
    }
  }          
`;


export default function getqueryAllRegisterCollectionLawFirmSite() {
    return queryAllRegisterCollectionLawFirmSite
}