import React from 'react';
import { NavLink } from "react-router-dom";

const Navigation =(props)=>{
    return(
        <div className="row" id="navigation-tab">
            <div className="col">
                <ul>
                    <li>
                        <NavLink exact={true} to={{pathname: "/setting/invoice-setting-terms/detail/not/" + props.id}}>
                            <h5>ยังไม่โอนกรรมสิทธิ์</h5>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={{pathname: "/setting/invoice-setting-terms/detail/developer/" + props.id}}>
                            <h5>โอนกรรมสิทธิ์แล้ว Developer สนับสนุน</h5>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={{pathname: "/setting/invoice-setting-terms/detail/owner/" + props.id}}>
                            <h5>เจ้าของร่วม / สมาชิก รับผิดชอบ</h5>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navigation