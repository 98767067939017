import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../../../env/environment";

const mutation = graphql`
  mutation ReUploadContractRevenueMutation($input: UploadFileContractRevenueInput!) {
    uploadFileContractRevenue(input: $input){
      ok
    }
  }
`;

function ReUploadContractRevenueInput(input, callback, error_callback) {
  const variables = {
    input: input,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response);
    },
    onError: (err) => {
      error_callback(err);
    },
  });
}

export default ReUploadContractRevenueInput;
