import React, {Component} from 'react';
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import Wrapper from "../../../../components/wrapper";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import SelectInterest from './selectInterest';
import {fetchQuery} from "relay-runtime";
import environment from "../../../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import _ from 'lodash';
import { format } from 'date-fns';
import numberWithCommas from '../../../../libs/numberWithComma';
import InterestTable from './interestTable';
import postApiUseing from '../../../../libs/postApiUseing';
import i18next from 'i18next';

const query = graphql`
    query interestDashboardQuery{
        bankUnitAutomaticCal{
            edges{
                node{
                    id
                    automaticCal
                    issuedDate
                    dueDate
                    interestPerYear
                    price
                    calculationDate
                    depositStatus
                }
                    
            }
        }
        selfProject {
            id
            name
            nameEn
        }
    }`;
class InterestDashboard extends Component {

    constructor(props){
        super(props);
        this.state = {
            selectOption : [],
            selectList : [],
            projectName : '',
            firstDate : '',
            lastDate : ''
        }
    }

    componentWillMount() {

        let bodyFormData = new FormData();
        bodyFormData.append('title', "interestDashboardQuery");
        bodyFormData.append('body', '');
    
        postApiUseing.PostApiUseing(bodyFormData).then(res => {
          return;
        }).catch(error => {
            console.log(error);
        })
    
      }

    componentDidMount(){
        this.fetchQuery()
    }

    getLabel = (bankUnit) => {
        let months = 0 ;
        months = (new Date(bankUnit.node.dueDate).getFullYear() - new Date(bankUnit.node.issuedDate).getFullYear()) * 12;
        months -= new Date(bankUnit.node.issuedDate).getMonth();
        months += new Date(bankUnit.node.dueDate).getMonth();
        months = months <= 0 ? 0 : months;

        let calInterest = bankUnit.node.calculationDate ? " เริ่มคำนวณดอกเบี้ย " +  format(bankUnit.node.calculationDate, 'DD/MM/YYYY') : ''

        return months + " เดือน เริ่ม " +  format(bankUnit.node.issuedDate, 'DD/MM/YYYY') + " ครบ " + format(bankUnit.node.dueDate, 'DD/MM/YYYY')  +
            " ดอกเบี้ย " + bankUnit.node.interestPerYear + "% " + numberWithCommas(bankUnit.node.price) + " บาท" + calInterest
    }

    fetchQuery = () => {
        fetchQuery(environment, query, {}).then((data) => {
            if(data?.bankUnitAutomaticCal?.edges){
                let selectOption = []
                _.forEach(data.bankUnitAutomaticCal.edges, bankUnit => {
                    selectOption.push({
                        value : bankUnit.node.id,
                        label : this.getLabel(bankUnit),
                        price : bankUnit.node.price,
                        issuedDate : bankUnit.node.issuedDate,
                        dueDate : bankUnit.node.dueDate,
                        calculationDate : bankUnit.node.calculationDate
                    })
                })
                this.setState({
                    selectOption : selectOption
                })
            }
            if(data.selfProject){
                this.setState({
                    projectName : data.selfProject.name
                })
            }
        });
    }

    onSearch = (selectList) => {
        let getListIssuedDate = selectList.length > 0  ? _.filter(selectList, list => {return list.calculationDate === null}) : []
        let firstDateIssuedDate =  getListIssuedDate.length > 0 ?  _.sortBy(getListIssuedDate ,'issuedDate')[0].issuedDate : new Date()
        let sortCalDate = selectList.length > 0 ? _.sortBy(selectList , 'calculationDate' )[0].calculationDate : ''
        let firstDateCalculationDate =  sortCalDate? sortCalDate : ''
        this.setState({
            selectList : selectList,
            firstDate : selectList.length > 0 ? (firstDateCalculationDate !== '' ? firstDateIssuedDate <= firstDateCalculationDate ? firstDateIssuedDate : firstDateCalculationDate : firstDateIssuedDate) : '',
            lastDate : selectList.length > 0 ? _.sortBy(selectList,'dueDate').reverse()[0].dueDate : ''
        })
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="interest-report">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/report/finance">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{i18next.t("interestDashboard:Report on Detailed Estimation of Fixed Deposit Interest Income")}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>

                        <div className="content-inner">
                            <SelectInterest selectOption={this.state.selectOption} onSearch={this.onSearch}/>
                            {this.state.selectList.length > 0 &&
                                <InterestTable 
                                    selectList={this.state.selectList} 
                                    projectName={this.state.projectName} 
                                    firstDate ={this.state.firstDate}
                                    lastDate = {this.state.lastDate}
                                />
                            }
                            
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default InterestDashboard;
