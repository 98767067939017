import React from 'react';
import {QueryRenderer} from 'react-relay';
import environment from "../../../env/environment";
import {format} from "date-fns";
import ComponentPagination from "../../../libs/componentPagination";
import thLocale from "date-fns/locale/th";
import Pagination from "../../../libs/newPagination";
import {Link} from "react-router-dom";
import numberWithCommas from "../../../libs/numberWithComma";
import { convertToTimeZone } from '../../../libs/date';
import { Translation } from 'react-i18next';
import Loading from "../../../libs/loading"

class CashListTable extends ComponentPagination {

    render() {
        return (

            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <QueryRenderer
                            environment={environment}
                            query={this.props.query}
                            variables={{first : this.state.first, last: this.state.last}}
                            render={({error, props}) => {
                                if (error) {
                                    return <div>{error.message}</div>;
                                } else if (props) {
                                    return <React.Fragment>
                                        <div className="table-responsive fade-up card">
                                            <table className="table table-hover">
                                                <thead className="thead-light">
                                                <Translation>
                                                    {t=>
                                                    <tr>
                                                        <th>{t("cash_deposit:No.")}</th>
                                                        <th>{t("cash_deposit:Date")}</th>
                                                        <th>{t("cash_deposit:Description")}</th>
                                                        <th>{t("cash_deposit:Deposit bank")}</th>
                                                        <th className="text-right">{t("cash_deposit:Amount")}</th>
                                                    </tr>}
                                                </Translation>
                                                </thead>
                                                <tbody>

                                                {props.cashDepositGroups.edges.map((cash_deposit_group) => (
                                                    <tr key={cash_deposit_group.node.id}>
                                                        <td>
                                                            <Link to={"/accounting/finance/cash/detail/" + cash_deposit_group.node.id}
                                                                >{cash_deposit_group.node.docNumber}</Link>
                                                        </td>
                                                        <td> {format(convertToTimeZone(cash_deposit_group.node.depositDate, props.selfProject.timeZone), 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                        <td>{cash_deposit_group.node.description}</td>
                                                        <td>{cash_deposit_group.node.bank.bankName} {cash_deposit_group.node.bank.accountNumber}</td>
                                                        <td className="text-right">{numberWithCommas(cash_deposit_group.node.total)}</td>
                                                    </tr>
                                                ))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <Pagination changePage={this.changePage} first={this.state.first}
                                                        last={this.state.last}
                                                        totalCount={props.cashDepositGroups.totalCount}/>
                                        </div>
                                    </React.Fragment>
                                }
                                return <Loading/>
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CashListTable;
