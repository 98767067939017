/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FixRequestDetailFixType = "DEMOLISH" | "RENOVATE" | "REPAIR" | "%future added value";
export type FixRequestDetailTechnician = "IN_HOUSE" | "OUT_SOURCE" | "%future added value";
export type chatDetailHistoryQueryVariables = {|
  id: string
|};
export type chatDetailHistoryQueryResponse = {|
  +allRooms: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +detail: ?string,
        +added: any,
        +fixRequestRoom: ?{|
          +id: string,
          +fixType: FixRequestDetailFixType,
          +technician: FixRequestDetailTechnician,
          +startDate: ?string,
          +startTime: ?string,
          +endTime: ?string,
          +contactPerson: ?string,
          +contactNumber: ?string,
          +technicianName: ?string,
        |},
      |}
    |}>
  |}
|};
export type chatDetailHistoryQuery = {|
  variables: chatDetailHistoryQueryVariables,
  response: chatDetailHistoryQueryResponse,
|};
*/


/*
query chatDetailHistoryQuery(
  $id: ID!
) {
  allRooms(roomId: $id) {
    edges {
      node {
        id
        name
        detail
        added
        fixRequestRoom {
          id
          fixType
          technician
          startDate
          startTime
          endTime
          contactPerson
          contactNumber
          technicianName
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "roomId",
        "variableName": "id"
      }
    ],
    "concreteType": "RoomNodeConnection",
    "kind": "LinkedField",
    "name": "allRooms",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RoomNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoomNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FixRequestDetailNode",
                "kind": "LinkedField",
                "name": "fixRequestRoom",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fixType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "technician",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contactPerson",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contactNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "technicianName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "chatDetailHistoryQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "chatDetailHistoryQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f6ebdb79fc046162854a1fc5ebe33404",
    "id": null,
    "metadata": {},
    "name": "chatDetailHistoryQuery",
    "operationKind": "query",
    "text": "query chatDetailHistoryQuery(\n  $id: ID!\n) {\n  allRooms(roomId: $id) {\n    edges {\n      node {\n        id\n        name\n        detail\n        added\n        fixRequestRoom {\n          id\n          fixType\n          technician\n          startDate\n          startTime\n          endTime\n          contactPerson\n          contactNumber\n          technicianName\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '00bfec035c8951a615647ce59733b83e';

module.exports = node;
