import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import AssetEdit from "./assetEdit";
import AssetCreateUpdateForm from "./assetCreateAndUpdateForm";
import { Translation } from 'react-i18next';

class AssetCreateOrUpdate extends Component {

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {(t) =>
                            <React.Fragment>
                                {!this.props.match.params.id &&
                                    <AssetCreateUpdateForm type={this.props.match.params.asset_type} />
                                }
                                {this.props.match.params.id &&
                                    <AssetEdit asset_id={this.props.match.params.id} />
                                }
                            </React.Fragment>
                        }
                    </Translation>

                </WrapperContent>

            </Wrapper>

        )
    }
}

export default AssetCreateOrUpdate;