import React from "react";
import "../listContactResidential.scss";
import getNameResidential from "../../../libs/getNameResidential";
import numberWithComma from "../../../libs/numberWithComma";
import {Translation} from "react-i18next";
import i18next from "i18next";

class CustomerFilterReceivable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {customerList: this.props.queryObject, query: ""};
        this.search = this.search.bind(this);
        this.queryFilter = this.queryFilter.bind(this);
    }

    queryFilter(customer) {
        let query = this.state.query.toLowerCase();
        let query_field = this.props.queryField.split(":");
        let result = false;
        query_field.forEach((field)=>{
            result = result || customer.node[field].toLowerCase().includes(query)
        });

        return result
    }

    search(e) {
        let customers = this.props.queryObject;
        this.setState({query: e.target.value}, () => {
            this.setState({customerList: customers.filter(this.queryFilter)});
        });
    }


    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <div className="input-group">
                            <Translation>
                               {t => <input type="text" className="form-control"
                                       placeholder={t('filterFunction:search')}
                                       value={this.state.search}
                                       onChange={this.search}
                                />}
                            </Translation>
                        </div>
                    </div>
                </div>
                <div className="row scrollList">
                    <div className="col">
                        <div className="table-responsive tableResidential" id="list-contact">
                            <table className="table table-hover mt-2">
                                <thead className="thead-light">
                                    <tr>
                                        <th colSpan={2}>{i18next.t("settingInvoiceTerms:customerCode")}</th>
                                        <th className="text-left">{i18next.t("contact_list:Name")}</th>
                                        <th style={{width: 120}}>{i18next.t("receiveSelectInvoice:Overdue list")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.customerList.map((contact) => {
                                    let name = '';
                                    if (this.props.list_type === "residential") {
                                        name = getNameResidential(contact.node.firstName, contact.node.lastName);
                                    }
                                    return (
                                        <tr
                                            className={this.props.contactSelected.includes(contact.node.id) ? 'table-primary' : ''}
                                            key={contact.node.id}
                                            id={atob(contact.node.id).split(":")[1]}
                                            onClick={() => {
                                                this.props.checkSelect && this.props.checkSelect();
                                                this.props.selectContact(contact.node.id, contact);
                                            }}>
                                            <td className="text-left">{contact.node.refNumber}</td>
                                            <td className="text-center">{contact.node.name}</td>
                                            <td className="text-left">{name}</td>
                                            <td className="text-right">{numberWithComma(contact.node.allAmountNotPaid)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CustomerFilterReceivable;
