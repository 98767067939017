/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GuaranteeMoneyReceivedPayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type guaranteeMoneyReceivedCreateUpdateQueryVariables = {|
  contact_id: string,
  gr_id: string,
  startswith?: ?string,
|};
export type guaranteeMoneyReceivedCreateUpdateQueryResponse = {|
  +contact: ?{|
    +id: string,
    +refNumber: string,
    +name: string,
    +lastName: string,
    +registeredAddress: string,
    +registeredDistrict: string,
    +registeredCity: string,
    +registeredProvince: string,
    +registeredPostalCode: string,
    +ownerName: ?string,
    +mailingAddress: string,
    +mailingDistrict: string,
    +mailingCity: string,
    +mailingProvince: string,
    +mailingPostalCode: string,
    +renterName: ?string,
    +renterAddress: ?string,
    +renterDistrict: string,
    +renterCity: string,
    +renterProvince: string,
    +renterPostalCode: string,
    +agentName: ?string,
    +agentAddress: ?string,
    +agentDistrict: string,
    +agentCity: string,
    +agentProvince: string,
    +agentPostalCode: string,
  |},
  +guaranteeMoneyReceived: ?{|
    +id: string,
    +docNumber: string,
    +firstName: string,
    +lastName: string,
    +address: string,
    +district: string,
    +city: string,
    +province: string,
    +postalCode: string,
    +payGroup: GuaranteeMoneyReceivedPayGroup,
    +amount: ?number,
    +issuedDate: any,
    +creator: string,
    +note: ?string,
    +description: string,
    +chartOfAccount: ?{|
      +id: string,
      +chartOfAccountCode: string,
      +name: string,
    |},
    +contact: {|
      +id: string,
      +refNumber: string,
      +name: string,
      +lastName: string,
      +ownerName: ?string,
      +registeredAddress: string,
      +registeredDistrict: string,
      +registeredCity: string,
      +registeredProvince: string,
      +registeredPostalCode: string,
      +renterName: ?string,
      +renterAddress: ?string,
      +renterDistrict: string,
      +renterCity: string,
      +renterProvince: string,
      +renterPostalCode: string,
      +agentName: ?string,
      +agentAddress: ?string,
      +agentDistrict: string,
      +agentCity: string,
      +agentProvince: string,
      +agentPostalCode: string,
    |},
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
        |}
      |}>
    |}
  |},
  +allProjects: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +periodCreate: string
      |}
    |}>
  |},
|};
export type guaranteeMoneyReceivedCreateUpdateQuery = {|
  variables: guaranteeMoneyReceivedCreateUpdateQueryVariables,
  response: guaranteeMoneyReceivedCreateUpdateQueryResponse,
|};
*/


/*
query guaranteeMoneyReceivedCreateUpdateQuery(
  $contact_id: ID!
  $gr_id: ID!
  $startswith: String
) {
  contact(id: $contact_id) {
    id
    refNumber
    name
    lastName
    registeredAddress
    registeredDistrict
    registeredCity
    registeredProvince
    registeredPostalCode
    ownerName
    mailingAddress
    mailingDistrict
    mailingCity
    mailingProvince
    mailingPostalCode
    renterName
    renterAddress
    renterDistrict
    renterCity
    renterProvince
    renterPostalCode
    agentName
    agentAddress
    agentDistrict
    agentCity
    agentProvince
    agentPostalCode
  }
  guaranteeMoneyReceived(id: $gr_id) {
    id
    docNumber
    firstName
    lastName
    address
    district
    city
    province
    postalCode
    payGroup
    amount
    issuedDate
    creator
    note
    description
    chartOfAccount {
      id
      chartOfAccountCode
      name
    }
    contact {
      id
      refNumber
      name
      lastName
      ownerName
      registeredAddress
      registeredDistrict
      registeredCity
      registeredProvince
      registeredPostalCode
      renterName
      renterAddress
      renterDistrict
      renterCity
      renterProvince
      renterPostalCode
      agentName
      agentAddress
      agentDistrict
      agentCity
      agentProvince
      agentPostalCode
    }
  }
  viewer {
    allChartOfAccount(chartOfAccountCode_Istartswith: $startswith) {
      edges {
        node {
          id
          chartOfAccountCode
          name
        }
      }
    }
    id
  }
  allProjects {
    edges {
      node {
        periodCreate
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contact_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gr_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startswith"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredAddress",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredDistrict",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCity",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredProvince",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredPostalCode",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ownerName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterAddress",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterDistrict",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterCity",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterProvince",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterPostalCode",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentAddress",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentDistrict",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentCity",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentProvince",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentPostalCode",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "contact_id"
    }
  ],
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailingAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailingDistrict",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailingCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailingProvince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailingPostalCode",
      "storageKey": null
    },
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/),
    (v19/*: any*/),
    (v20/*: any*/),
    (v21/*: any*/),
    (v22/*: any*/)
  ],
  "storageKey": null
},
v24 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "chartOfAccountCode",
    "storageKey": null
  },
  (v3/*: any*/)
],
v25 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "gr_id"
    }
  ],
  "concreteType": "GuaranteeMoneyReceivedNode",
  "kind": "LinkedField",
  "name": "guaranteeMoneyReceived",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "docNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "district",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "province",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payGroup",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNode",
      "kind": "LinkedField",
      "name": "chartOfAccount",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactNode",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v10/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/),
        (v17/*: any*/),
        (v18/*: any*/),
        (v19/*: any*/),
        (v20/*: any*/),
        (v21/*: any*/),
        (v22/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "chartOfAccountCode_Istartswith",
      "variableName": "startswith"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v24/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodCreate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "guaranteeMoneyReceivedCreateUpdateQuery",
    "selections": [
      (v23/*: any*/),
      (v25/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v26/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v27/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "guaranteeMoneyReceivedCreateUpdateQuery",
    "selections": [
      (v23/*: any*/),
      (v25/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v26/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v27/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "385c27b973f0a1ae96aff159b2292f6d",
    "id": null,
    "metadata": {},
    "name": "guaranteeMoneyReceivedCreateUpdateQuery",
    "operationKind": "query",
    "text": "query guaranteeMoneyReceivedCreateUpdateQuery(\n  $contact_id: ID!\n  $gr_id: ID!\n  $startswith: String\n) {\n  contact(id: $contact_id) {\n    id\n    refNumber\n    name\n    lastName\n    registeredAddress\n    registeredDistrict\n    registeredCity\n    registeredProvince\n    registeredPostalCode\n    ownerName\n    mailingAddress\n    mailingDistrict\n    mailingCity\n    mailingProvince\n    mailingPostalCode\n    renterName\n    renterAddress\n    renterDistrict\n    renterCity\n    renterProvince\n    renterPostalCode\n    agentName\n    agentAddress\n    agentDistrict\n    agentCity\n    agentProvince\n    agentPostalCode\n  }\n  guaranteeMoneyReceived(id: $gr_id) {\n    id\n    docNumber\n    firstName\n    lastName\n    address\n    district\n    city\n    province\n    postalCode\n    payGroup\n    amount\n    issuedDate\n    creator\n    note\n    description\n    chartOfAccount {\n      id\n      chartOfAccountCode\n      name\n    }\n    contact {\n      id\n      refNumber\n      name\n      lastName\n      ownerName\n      registeredAddress\n      registeredDistrict\n      registeredCity\n      registeredProvince\n      registeredPostalCode\n      renterName\n      renterAddress\n      renterDistrict\n      renterCity\n      renterProvince\n      renterPostalCode\n      agentName\n      agentAddress\n      agentDistrict\n      agentCity\n      agentProvince\n      agentPostalCode\n    }\n  }\n  viewer {\n    allChartOfAccount(chartOfAccountCode_Istartswith: $startswith) {\n      edges {\n        node {\n          id\n          chartOfAccountCode\n          name\n        }\n      }\n    }\n    id\n  }\n  allProjects {\n    edges {\n      node {\n        periodCreate\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0775251c39c272145e22d28d3b320b8a';

module.exports = node;
