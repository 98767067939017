/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AcceptCreditNoteStatus = "PAID" | "VOID" | "WAIT" | "WAITING_PAY" | "%future added value";
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type acceptCreditNoteReportQueryVariables = {|
  first?: ?number,
  last?: ?number,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  contact?: ?string,
  status?: ?string,
|};
export type acceptCreditNoteReportQueryResponse = {|
  +acceptCreditNoteViewer: ?{|
    +allAcceptCreditNote: ?{|
      +totalCount: ?number,
      +pageInfo: {|
        +hasNextPage: boolean,
        +hasPreviousPage: boolean,
      |},
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +issuedDate: any,
          +price: number,
          +total: number,
          +status: AcceptCreditNoteStatus,
          +contact: {|
            +refNumber: string,
            +name: string,
            +typeOfContact: ContactTypeOfContact,
          |},
          +purchaseRecord: {|
            +description: string,
            +total: number,
            +price: number,
            +purchaseRecordGroup: {|
              +docNumber: string,
              +issuedDate: any,
            |},
          |},
          +payRecordGroup: ?{|
            +docNumber: string
          |},
        |}
      |}>,
    |},
    +summaryAcceptCreditNote: ?number,
  |}
|};
export type acceptCreditNoteReportQuery = {|
  variables: acceptCreditNoteReportQueryVariables,
  response: acceptCreditNoteReportQueryResponse,
|};
*/


/*
query acceptCreditNoteReportQuery(
  $first: Int
  $last: Int
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $contact: String
  $status: String
) {
  acceptCreditNoteViewer {
    allAcceptCreditNote(first: $first, last: $last, search: $search, startDate: $start_date, endDate: $end_date, contact: $contact, status: $status, order: "doc_number") {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          docNumber
          issuedDate
          price
          total
          status
          contact {
            refNumber
            name
            typeOfContact
            id
          }
          purchaseRecord {
            description
            total
            price
            purchaseRecordGroup {
              docNumber
              issuedDate
              id
            }
            id
          }
          payRecordGroup {
            docNumber
            id
          }
        }
      }
    }
    summaryAcceptCreditNote(search: $search, startDate: $start_date, endDate: $end_date, contact: $contact, status: $status, order: "doc_number")
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = {
  "kind": "Variable",
  "name": "contact",
  "variableName": "contact"
},
v8 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "end_date"
},
v9 = {
  "kind": "Literal",
  "name": "order",
  "value": "doc_number"
},
v10 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v11 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "start_date"
},
v12 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v13 = [
  (v7/*: any*/),
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "summaryAcceptCreditNote",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "acceptCreditNoteReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AcceptCreditNoteViewer",
        "kind": "LinkedField",
        "name": "acceptCreditNoteViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "AcceptCreditNoteNodeConnection",
            "kind": "LinkedField",
            "name": "allAcceptCreditNote",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AcceptCreditNoteNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AcceptCreditNoteNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PurchaseRecordNode",
                        "kind": "LinkedField",
                        "name": "purchaseRecord",
                        "plural": false,
                        "selections": [
                          (v25/*: any*/),
                          (v20/*: any*/),
                          (v19/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PurchaseRecordGroupNode",
                            "kind": "LinkedField",
                            "name": "purchaseRecordGroup",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PayRecordGroupNode",
                        "kind": "LinkedField",
                        "name": "payRecordGroup",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v26/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "acceptCreditNoteReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AcceptCreditNoteViewer",
        "kind": "LinkedField",
        "name": "acceptCreditNoteViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "AcceptCreditNoteNodeConnection",
            "kind": "LinkedField",
            "name": "allAcceptCreditNote",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AcceptCreditNoteNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AcceptCreditNoteNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PurchaseRecordNode",
                        "kind": "LinkedField",
                        "name": "purchaseRecord",
                        "plural": false,
                        "selections": [
                          (v25/*: any*/),
                          (v20/*: any*/),
                          (v19/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PurchaseRecordGroupNode",
                            "kind": "LinkedField",
                            "name": "purchaseRecordGroup",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PayRecordGroupNode",
                        "kind": "LinkedField",
                        "name": "payRecordGroup",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v26/*: any*/),
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22514c565e75d6ae72e73ae20cb7161f",
    "id": null,
    "metadata": {},
    "name": "acceptCreditNoteReportQuery",
    "operationKind": "query",
    "text": "query acceptCreditNoteReportQuery(\n  $first: Int\n  $last: Int\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $contact: String\n  $status: String\n) {\n  acceptCreditNoteViewer {\n    allAcceptCreditNote(first: $first, last: $last, search: $search, startDate: $start_date, endDate: $end_date, contact: $contact, status: $status, order: \"doc_number\") {\n      totalCount\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n      }\n      edges {\n        node {\n          id\n          docNumber\n          issuedDate\n          price\n          total\n          status\n          contact {\n            refNumber\n            name\n            typeOfContact\n            id\n          }\n          purchaseRecord {\n            description\n            total\n            price\n            purchaseRecordGroup {\n              docNumber\n              issuedDate\n              id\n            }\n            id\n          }\n          payRecordGroup {\n            docNumber\n            id\n          }\n        }\n      }\n    }\n    summaryAcceptCreditNote(search: $search, startDate: $start_date, endDate: $end_date, contact: $contact, status: $status, order: \"doc_number\")\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b3a2d9fd0c763fda4edfe6d7993d37f9';

module.exports = node;
