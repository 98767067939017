import { graphql } from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../../../env/environment";

const mutation = graphql`
    mutation createAndUpdateReceiptMutation($input: CreateAndUpdateReceiptInput!) {
      createAndUpdateReceipt(input:$input){
        ok
        massage
      }
}
`

export default function createAndUpdateReceiptMutation(variables, callback, error_callback) {
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response) => {
          callback(response.createAndUpdateReceipt)
        },
        onError: (err) => {
            error_callback(err);
        },
      },
    )
  }