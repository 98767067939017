/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type juristicManagerListQueryVariables = {|
  search?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type juristicManagerListQueryResponse = {|
  +currentManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +image: ?string,
        +signature: ?string,
        +prefix: ?string,
        +firstName: ?string,
        +lastName: ?string,
        +address: ?string,
        +department: ?string,
        +position: ?string,
        +affiliation: ?string,
        +phone: ?string,
        +email: ?string,
        +state: string,
        +note: ?string,
        +online: ?any,
        +term: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +startDate: ?any,
              +expDate: ?any,
            |}
          |}>
        |},
      |}
    |}>,
    +totalCount: ?number,
  |},
  +oldManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +image: ?string,
        +signature: ?string,
        +prefix: ?string,
        +firstName: ?string,
        +lastName: ?string,
        +address: ?string,
        +department: ?string,
        +position: ?string,
        +affiliation: ?string,
        +phone: ?string,
        +email: ?string,
        +state: string,
        +note: ?string,
        +online: ?any,
        +term: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +startDate: ?any,
              +expDate: ?any,
            |}
          |}>
        |},
      |}
    |}>,
    +totalCount: ?number,
  |},
|};
export type juristicManagerListQuery = {|
  variables: juristicManagerListQueryVariables,
  response: juristicManagerListQueryResponse,
|};
*/


/*
query juristicManagerListQuery(
  $search: String
  $first: Int
  $last: Int
) {
  currentManager {
    edges {
      node {
        id
        image
        signature
        prefix
        firstName
        lastName
        address
        department
        position
        affiliation
        phone
        email
        state
        note
        online
        term {
          edges {
            node {
              startDate
              expDate
              id
            }
          }
        }
      }
    }
    totalCount
  }
  oldManager(search: $search, first: $first, last: $last) {
    edges {
      node {
        id
        image
        signature
        prefix
        firstName
        lastName
        address
        department
        position
        affiliation
        phone
        email
        state
        note
        online
        term {
          edges {
            node {
              startDate
              expDate
              id
            }
          }
        }
      }
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signature",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefix",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "department",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affiliation",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "online",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expDate",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v21 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JuristicManagerNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicManagerNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TermNodeConnection",
            "kind": "LinkedField",
            "name": "term",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TermNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TermNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v20/*: any*/)
],
v22 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v23 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JuristicManagerNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicManagerNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TermNodeConnection",
            "kind": "LinkedField",
            "name": "term",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TermNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TermNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v20/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "juristicManagerListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicManagerNodeConnection",
        "kind": "LinkedField",
        "name": "currentManager",
        "plural": false,
        "selections": (v21/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v22/*: any*/),
        "concreteType": "JuristicManagerNodeConnection",
        "kind": "LinkedField",
        "name": "oldManager",
        "plural": false,
        "selections": (v21/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "juristicManagerListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicManagerNodeConnection",
        "kind": "LinkedField",
        "name": "currentManager",
        "plural": false,
        "selections": (v23/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v22/*: any*/),
        "concreteType": "JuristicManagerNodeConnection",
        "kind": "LinkedField",
        "name": "oldManager",
        "plural": false,
        "selections": (v23/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "12292734db06d2dfb2e7a9202c36e43e",
    "id": null,
    "metadata": {},
    "name": "juristicManagerListQuery",
    "operationKind": "query",
    "text": "query juristicManagerListQuery(\n  $search: String\n  $first: Int\n  $last: Int\n) {\n  currentManager {\n    edges {\n      node {\n        id\n        image\n        signature\n        prefix\n        firstName\n        lastName\n        address\n        department\n        position\n        affiliation\n        phone\n        email\n        state\n        note\n        online\n        term {\n          edges {\n            node {\n              startDate\n              expDate\n              id\n            }\n          }\n        }\n      }\n    }\n    totalCount\n  }\n  oldManager(search: $search, first: $first, last: $last) {\n    edges {\n      node {\n        id\n        image\n        signature\n        prefix\n        firstName\n        lastName\n        address\n        department\n        position\n        affiliation\n        phone\n        email\n        state\n        note\n        online\n        term {\n          edges {\n            node {\n              startDate\n              expDate\n              id\n            }\n          }\n        }\n      }\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1b712c66930afebe8faab3313360efdf';

module.exports = node;
