import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import Navigation from "./navigation";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Translation } from "react-i18next";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import PettyCashSetListTable from "./pettyCashSetListTable";
import PettyCashRecordListTablePettyCash from "./pettyCashRecordListTablePettyCash";
import PettyCashRecordListTableOtherExpense from "./pettyCashRecordListTableOtherExpense";
import PettyCashRecordListTablePay from "./pettyCashRecordListTablePay";
import PettyCashRecordListTableAdvance from "./pettyCashRecordListTableAdvance";
import PettyCashRecordListTableClearAdvance from "./pettyCashRecordListTableClearAdvance";
import PettyCashRecordListTableGuaranteeMoneyReceived from "./pettyCashRecordListTableGuaranteeMoneyReceived";
import PettyCashPaymentListTable from "./pettyCashPaymentListTable";
import environment from "../../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import SearchSelect from "../../../libs/searchSelect";
import { fetchQuery } from "relay-runtime";
import _ from "lodash";
import "./styles/pettyCashList.scss";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
import i18next from "i18next";
const getAllSetPettyCash = graphql`
  query pettyCashListQuery {
    allSetPettyCash (status:"active"){
      totalCount
      edges {
        node {
          id
          docNumber
          description
          withdrawer
          withdrawAmount
          totalPettyCashRecord
          status
          voidRemark
        }
      }
    }
  }
`;

class PettyCashList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date_input: null,
      end_date_input: null,
      start_date: null,
      end_date: null,
      search: "",
      search_input: "",
      all_set_petty_cash_list: [],
      selected_set_petty_cash: "",
      selected_set_petty_cash_status: "",
      temp_selected_set_petty_cash: "",
      temp_selected_set_petty_cash_status: "",
      temp_selected_type_petty_cash: "petty_cash",
      reQuery: false,
      advance_search: false,
      selected_type_petty_cash: "petty_cash"
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.getAllSetPettyCash = this.getAllSetPettyCash.bind(this)
    this.handleReQuery = this.handleReQuery.bind(this)
  }

  componentWillMount() {
    if (this.props.match.params.page === "record") {
      this.getAllSetPettyCash();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.page !== prevProps.match.params.page && this.props.match.params.page === "record") {
      this.getAllSetPettyCash();
    }
    else if (prevState.reQuery !== this.state.reQuery && this.props.match.params.page === "set" && this.state.reQuery !== false) {
      this.setState({ reQuery: false });
    }
  }

  handleReQuery() {
    this.setState({ reQuery: true });
  }

  getAllSetPettyCash() {
    fetchQuery(environment, getAllSetPettyCash, {}).then((data) => {
      this.setState({
        all_set_petty_cash_list: data.allSetPettyCash.edges,
        selected_set_petty_cash:
          data.allSetPettyCash.totalCount !== 0
            ? data.allSetPettyCash.edges[0]?.node.id
            : "",
        selected_set_petty_cash_status:
          data.allSetPettyCash.totalCount !== 0
            ? data.allSetPettyCash.edges[0]?.node.status
            : "",
      }, () => this.setState({
        temp_selected_set_petty_cash: this.state.selected_set_petty_cash,
        temp_selected_set_petty_cash_status: this.state.selected_set_petty_cash_status
      }));
    });
  }

  handleChange(event) {
    if (event.target.name === 'temp_start_date') {
      this.setState({ start_date: event.target.value });
    } else if (event.target.name === 'temp_end_date') {
      this.setState({ end_date: event.target.value });
    }
    else if (event.target.name === 'temp_selected_set_petty_cash') {
      let select_set_petty_cash = _.find(this.state.all_set_petty_cash_list, { 'node': { 'id': event.target.value } })
      this.setState({
        temp_selected_set_petty_cash: event.target.value,
        temp_selected_set_petty_cash_status: select_set_petty_cash.node.status
      });
    }
    else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    if (event.target.name === 'search_button' || event.target.name === 'search') {
      this.setState({ search_input: event.target.value, start_date_input: this.state.start_date, end_date_input: this.state.end_date })
    }
    if (this.props.match.params.page !== "set") {
      this.setState({
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        search: event.target.value,
        selected_set_petty_cash: this.state.temp_selected_set_petty_cash,
        selected_type_petty_cash: this.state.temp_selected_type_petty_cash,
        selected_set_petty_cash_status: this.state.temp_selected_set_petty_cash_status,
      });
    } else {
      this.setState({
        search: event.target.value,
        selected_set_petty_cash: this.state.temp_selected_set_petty_cash,
      });
    }
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="receive-list">
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("pettyCash:petty_cash")+" "+"(SPC / PC /PCP)"}</span>}
                  </Translation>
                </h3>
              </div>
              <div className="col text-right">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_petty_cash_create", }) && (
                  <React.Fragment>
                    {this.props.match.params.page === "set" && (
                      <Link to="/accounting/expense/petty_cash/set/from">
                        <button
                          type="button"
                          className="btn btn-primary add float-right"
                          onClick={this.onClickOpenModal}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/icons/plus.png"
                            }
                            alt="plus"
                          />
                          <Translation>
                            {(t) => (
                              <span>{t("accounting:add_petty_cash")}</span>
                            )}
                          </Translation>
                        </button>
                      </Link>
                    )}

                    {this.state.selected_set_petty_cash_status !== 'VOID' && this.props.match.params.page === "record" && (
                      <React.Fragment>
                        <Link
                          to={
                            "/accounting/expense/petty_cash/create-record/" +
                            this.state.selected_set_petty_cash
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-primary add float-right"
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icons/plus.png"
                              }
                              alt="plus"
                            />
                            <Translation>
                              {(t) => <span>{t("pettyCash:petty_cash")}</span>}
                            </Translation>
                          </button>
                        </Link>
                      </React.Fragment>
                    )}
                    {this.props.match.params.page === "payment" && (
                      <Link to="/accounting/expense/petty_cash/payment/create">
                        <button
                          type="button"
                          className="btn btn-primary add float-right"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/icons/plus.png"
                            }
                            alt="plus"
                          />
                          <Translation>
                            {(t) => (
                              <span>
                                {t("accounting:add_petty_cash_payment")}
                              </span>
                            )}
                          </Translation>
                        </button>
                      </Link>
                    )}
                  </React.Fragment>
                )}
              </div>

              {this.props.match.params.page === "set" && (
                <Link to="/accounting/expense/petty_cash/setBringForward/from">
                  <button type="button"
                    className="btn btn-primary add float-right"
                    onClick={this.onClickOpenModal}
                  >
                    <img src={process.env.PUBLIC_URL + "/images/icons/plus.png"} alt="plus" />
                    <Translation>
                      {(t) => (<span>{t("accounting:add_petty_cash_bring_forward")}</span>)}
                    </Translation>
                  </button>
                </Link>
              )}
            </div>
          <Translation>
            {(t) =>
            <div className="content-inner">
              <Navigation />

              <div className="row mb-4 mt-1">
                <div className="col-9 form-inline">
                  {this.props.match.params.page === "record" && (
                    <React.Fragment>
                      <label htmlFor="date" className="col-form-label">
                        {i18next.t("pettyCashList:Choose a list of petty cash")}
                      </label>
                      <div className="select-list-record" style={{ minWidth: 300 }}>
                        <SearchSelect
                          onChange={this.handleChange}
                          value={this.state.temp_selected_set_petty_cash}
                          name="temp_selected_set_petty_cash"
                          placeholder={i18next.t("pettyCashList:Choose a list of petty cash")}
                          data-key="set_petty_cash"
                          queryObject={this.state.all_set_petty_cash_list}
                          keyOfValue="id"
                          require={true}
                          keyOfLabel="docNumber:withdrawer:description"
                        />
                      </div>

                      <label htmlFor="date" className="col-form-label">
                        {i18next.t("pettyCashList:Petty cash type")}
                      </label>
                      <div className="select-list-record" style={{ minWidth: 100 }}>
                        <select className="form-control"
                          value={this.state.temp_selected_type_petty_cash}
                          data-key="type"
                          name="temp_selected_type_petty_cash"
                          onChange={this.handleChange}
                        >
                          <option value="petty_cash">{i18next.t("set_permission:Petty cash")}</option>
                          <option value="pay">{i18next.t("pettyCashList:Expenses")}</option>
                          <option value="other_expense">{i18next.t("pettyCashList:Other expenses")}</option>
                          <option value="advance">{i18next.t("pettyCashList:Advance")}</option>
                          <option value="clear_advance">{i18next.t("pettyCashList:Clear Advance")}</option>
                          <option value="clear_guarantee_money_received">{i18next.t("pettyCashList:Clear Guarantee Money Received Petty Cash")}</option>
                        </select>
                      </div>

                      <div>
                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_petty_cash_print" }) ? (
                          this.state.selected_set_petty_cash ? (
                            <Link
                              to={{
                                pathname: "/document/petty-cash/all/",
                                state: {
                                  selected_set_petty_cash: this.state.selected_set_petty_cash,
                                  start_date: this.state.start_date,
                                  end_date: this.state.end_date,
                                },
                              }}
                            >
                              <button type="button" className="btn btn-outline-secondary">
                                <img src={process.env.PUBLIC_URL + "/images/icons/print-icon.png"} alt="print-icon" />
                              </button>
                            </Link>
                          ) : (
                            <button type="button" className="btn btn-outline-secondary">
                              <img src={process.env.PUBLIC_URL + "/images/icons/print-icon.png"} alt="print-icon" />
                            </button>
                          )
                        ) : null}
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className="col-12 text-right float-right">
                  <div className="input-group float-right w-auto ml-2">
                    <input type="text"
                      className="form-control input-size"
                      placeholder={i18next.t("pettyCashList:Search")}
                      value={this.state.search}
                      name="search"
                      onChange={this.handleChange}
                      onKeyDown={this.handleKeyDown}
                    />
                    <button type="submit"
                      className="btn btn-primary form-control search-button add"
                      name="search_button"
                      value={this.state.search}
                      onClick={(e) => this.handleSearch(e)}
                    >
                      {i18next.t("pettyCashList:Search")}
                    </button>
                  </div>
                  {this.props.match.params.page !== "set" && (
                    <InlineAdvanceFilter
                      start_date={this.state.start_date}
                      end_date={this.state.end_date}
                      handleChange={this.handleChange}
                    />
                  )}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  {this.props.match.params.page === "set" && (
                    <PettyCashSetListTable
                      search={this.state.search_input}
                      reQuery={this.state.reQuery}
                      handleReQuery={this.handleReQuery}
                    />
                  )}

                  {/* ประเภทเงินสดย่อย */}
                  {this.props.match.params.page === "record" && this.state.selected_type_petty_cash === "petty_cash" && (
                    <PettyCashRecordListTablePettyCash
                      search={this.state.search_input}
                      start_date={this.state.start_date_input}
                      end_date={this.state.end_date_input}
                      set_petty_cash_id={this.state.selected_set_petty_cash}
                      all_set_petty_cash_list={this.state.all_set_petty_cash_list}
                      selected_type_petty_cash={this.state.selected_type_petty_cash}
                    />
                  )}
                  {this.props.match.params.page === "record" && this.state.selected_type_petty_cash === "other_expense" && (
                    <PettyCashRecordListTableOtherExpense
                      search={this.state.search_input}
                      start_date={this.state.start_date_input}
                      end_date={this.state.end_date_input}
                      set_petty_cash_id={this.state.selected_set_petty_cash}
                      all_set_petty_cash_list={this.state.all_set_petty_cash_list}
                      selected_type_petty_cash={this.state.selected_type_petty_cash}
                    />
                  )}
                  {this.props.match.params.page === "record" && this.state.selected_type_petty_cash === "pay" && (
                    <PettyCashRecordListTablePay
                      search={this.state.search_input}
                      start_date={this.state.start_date_input}
                      end_date={this.state.end_date_input}
                      set_petty_cash_id={this.state.selected_set_petty_cash}
                      all_set_petty_cash_list={this.state.all_set_petty_cash_list}
                      selected_type_petty_cash={this.state.selected_type_petty_cash}
                    />
                  )}
                  {this.props.match.params.page === "record" && this.state.selected_type_petty_cash === "advance" && (
                    <PettyCashRecordListTableAdvance
                      search={this.state.search_input}
                      start_date={this.state.start_date_input}
                      end_date={this.state.end_date_input}
                      set_petty_cash_id={this.state.selected_set_petty_cash}
                      all_set_petty_cash_list={this.state.all_set_petty_cash_list}
                      selected_type_petty_cash={this.state.selected_type_petty_cash}
                    />
                  )}
                  {this.props.match.params.page === "record" && this.state.selected_type_petty_cash === "clear_advance" && (
                    <PettyCashRecordListTableClearAdvance
                      search={this.state.search_input}
                      start_date={this.state.start_date_input}
                      end_date={this.state.end_date_input}
                      set_petty_cash_id={this.state.selected_set_petty_cash}
                      all_set_petty_cash_list={this.state.all_set_petty_cash_list}
                      selected_type_petty_cash={this.state.selected_type_petty_cash}
                    />
                  )}
                  {this.props.match.params.page === "record" && this.state.selected_type_petty_cash === "clear_guarantee_money_received" && (
                    <PettyCashRecordListTableGuaranteeMoneyReceived
                      search={this.state.search_input}
                      start_date={this.state.start_date_input}
                      end_date={this.state.end_date_input}
                      set_petty_cash_id={this.state.selected_set_petty_cash}
                      all_set_petty_cash_list={this.state.all_set_petty_cash_list}
                      selected_type_petty_cash={this.state.selected_type_petty_cash}
                    />
                  )}
                  {/* ประเภทเงินสดย่อย */}

                  {this.props.match.params.page === "payment" && (
                    <PettyCashPaymentListTable
                      search={this.state.search_input}
                      start_date={this.state.start_date_input}
                      end_date={this.state.end_date_input}
                    />
                  )}

                </div>
              </div>
            </div>
            }
          </Translation>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default PettyCashList;
