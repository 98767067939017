/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type modalSettingQueryVariables = {||};
export type modalSettingQueryResponse = {|
  +selfCreditCardSetting: ?{|
    +id: string,
    +bankAccount: {|
      +id: string,
      +accountName: string,
      +accountNumber: string,
      +accountType: BankAccountAccountType,
      +bankName: string,
    |},
    +fee: number,
  |},
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
        |}
      |}>,
      +totalCount: ?number,
    |}
  |},
|};
export type modalSettingQuery = {|
  variables: modalSettingQueryVariables,
  response: modalSettingQueryResponse,
|};
*/


/*
query modalSettingQuery {
  selfCreditCardSetting {
    id
    bankAccount {
      id
      accountName
      accountNumber
      accountType
      bankName
    }
    fee
  }
  bankAccountViewer {
    allBankAccount(status: true, accountType_In: "current_account,saving_account,saving_deposit") {
      edges {
        node {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
        }
      }
      totalCount
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CreditCardSettingNode",
  "kind": "LinkedField",
  "name": "selfCreditCardSetting",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNode",
      "kind": "LinkedField",
      "name": "bankAccount",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fee",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "accountType_In",
      "value": "current_account,saving_account,saving_deposit"
    },
    {
      "kind": "Literal",
      "name": "status",
      "value": true
    }
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            (v2/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "receivePayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "makePayment",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "allBankAccount(accountType_In:\"current_account,saving_account,saving_deposit\",status:true)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "modalSettingQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "modalSettingQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fad9028246945c91cb6dc57439fc7b59",
    "id": null,
    "metadata": {},
    "name": "modalSettingQuery",
    "operationKind": "query",
    "text": "query modalSettingQuery {\n  selfCreditCardSetting {\n    id\n    bankAccount {\n      id\n      accountName\n      accountNumber\n      accountType\n      bankName\n    }\n    fee\n  }\n  bankAccountViewer {\n    allBankAccount(status: true, accountType_In: \"current_account,saving_account,saving_deposit\") {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n        }\n      }\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ce772652386a8a17ae77aa5abcaf22c';

module.exports = node;
