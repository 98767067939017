import React, { Component } from 'react'
import DatePickerNoti from '../../../finance/depositInterest/datePickerNoti'
import SelectCreditor from '../../../../purchasing/selectCreditor/selectCreditor'
import "../style/contractExpense.scss"
import i18next from 'i18next'

export default class headContractExpense extends Component {

  getStatus = (statusInput) => {
    let valueResule = ""
    let colorStatus = ""

    switch (statusInput) {
      case "WAIT":
        valueResule = `${i18next.t("contract_expese_list:Pending")}`
        colorStatus = "statusYellow"
        break;
      case "IN_PROGRESS":
        valueResule = `${i18next.t("contract_expese_list:In Process")}`
        colorStatus = "statusGreen"
        break;
      case "NEAR_END":
        valueResule = `${i18next.t("contract_expese_list:Near Expiry")}`
        colorStatus = "statusYellow"
        break;
      case "RENEW":
        valueResule = `${i18next.t("contract_expese_list:Renew")}`
        colorStatus = "statusGreen"
        break;
      case "NOT_RENEW":
        valueResule = `${i18next.t("contract_expese_list:Don't Renew")}`
        colorStatus = "statusRed"
        break;
      case "VOID":
        valueResule = `${i18next.t("contract_expese_list:Void")}`
        colorStatus = "statusRed"
        break;
    }

    return (
      <div className={colorStatus + " mb-3"}>
        <span>{valueResule}</span>
      </div>
    )
  }

  render() {
    return (
      <div id="headerContractExpense">
        <div className="row">
          <div className="col-3">
            <h6>{i18next.t("updateFormContractExpense:Contract Number")}</h6>
            <input type="text"
              className="form-control"
              value={this.props.docNumber}
              disabled
            />
          </div>

          <div className="col-2">
            <h6>{i18next.t("updateFormContractExpense:Date")}</h6>
            <DatePickerNoti
              disabled
              selected={this.props.issuedDate}
            />
          </div>

          <div className="col-2 d-flex align-items-end p-0">
            {this.getStatus(this.props.status)}
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-3">
            <h6>{i18next.t("updateFormContractExpense:Creditor / Contracting Company")}</h6>
            <SelectCreditor
              creditorId={this.props.creditor}
              disibleInput={true}
            />
          </div>

          <div className="col-3">
            <h6>{i18next.t("updateFormContractExpense:Category")}</h6>
            <input type="text"
              className="form-control"
              value={this.props.category}
              disabled
            />
          </div>

          <div className="col-3">
            <h6>{i18next.t("updateFormContractExpense:Reference PO Number")}</h6>
            <input type="text"
              className="form-control"
              value={this.props.refNumberPO}
              disabled
            />
          </div>
        </div>

      </div>
    )
  }
}
