import React, {Component} from 'react';
import { Link } from "react-router-dom";
import './lottery.scss'

class LotteryAwardStep extends Component {

    render() {
        let myLang = this.props.match?.params?.lang;
        return (
            <>
            <div className='p-4' id='svm-lottery'>
                <Link to="/lottery-award/">
                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'} alt="back" className="back" />
                </Link>
                <h4 className='text-center'>{myLang === 'en' ? 'How to get the reward' : 'ขั้นตอนการรับรางวัล'}</h4>
                <div className='row p-2 mt-6' style={{backgroundColor:'#FFFFFF'}}>
                    <div className='col-3'>
                        <div className='text-center pt-8'>
                            <img src={process.env.PUBLIC_URL + '/images/lottery/step1.png'}/>
                        </div>
                    </div>
                    <div className='col-9 mt-2' style={{padding:'0'}}>
                        <div className='ml-3'>
                            <h5>{myLang === 'en' ? 'Step 1' : 'ขั้นตอนที่ 1'}</h5>
                            <h5>{myLang === 'en' ? 'Collect Evidence Of Ownership' : 'เก็บหลักฐาน แสดงความเป็นเจ้าของ'}</h5>
                            <ul style={{fontSize:'12px'}}>
                                <li>{myLang === 'en' ? 'Write your full name Phone number on the back of the lottery' : 'เขียนชื่อนามสกุล เบอร์โทรที่ด้านหลังสลาก'}</li>
                                <li>{myLang === 'en' ? 'Take a photo or document make a copy as evidence' : 'ถ่ายรูปหรือเอกสาร ทำสำเนาไว้เป็นหลักฐาน'}</li>
                                <li>{myLang === 'en' ? 'Write down the number, installment, series clearly.' : 'จดบันทึกเลข งวด ชุด ไว้ให้ชัดเจน'}</li>
                            </ul>
                       </div>
                    </div>
                </div>
               
                <div className='row p-2 mt-6' style={{backgroundColor:'#FFFFFF'}}>
                    <div className='col-3'>
                        <div className='text-center pt-8'>
                            <img src={process.env.PUBLIC_URL + '/images/lottery/step2.png'}
                            />
                        </div>
                    </div>
                    <div className='col-9 mt-2' style={{padding:'0'}}>
                        <div className='ml-3'>
                            <h5>{myLang === 'en' ? 'Step 2' : 'ขั้นตอนที่ 2'}</h5>
                            <h5>{myLang === 'en' ? 'How to get the money' : 'นำไปขึ้นเงิน'}</h5>
                            <p className='' style={{fontSize:'12px'}}>{myLang === 'en' ? 'Bring money to Government Lottery Office (Sanambinnam) G floor' : 'นำไปขึ้นเงินที่ สำนักงานสลากกินแบ่งรัฐบาล (สนามบินน้ำ) ชั้น G'}</p>
                        </div>
                    </div>
                </div>
                <div className='row p-2 mt-6' style={{backgroundColor:'#FFFFFF'}}>
                    <div className='col-3'>
                        <div className='text-center pt-12'>
                            <img src={process.env.PUBLIC_URL + '/images/lottery/step3.png'} />
                        </div>
                    </div>
                    <div className='col-9 mt-2' style={{padding:'0'}}>
                        <div className='ml-3'>
                            <h5>{myLang === 'en' ? 'Step 3' : 'ขั้นตอนที่ 3'}</h5>
                            <h5>{myLang === 'en' ? 'Get the money or cheque' : 'รอรับเงินหรือเช็ค'}</h5>
                            <ul style={{fontSize:'12px'}}>
                                <li>{myLang === 'en' ? 'Won prizes in the amount of not more than 20,000 baht, received in cash' : 'ถูกรางวัลเป็นจำนวนไม่เกิน 20,000 บาท รับเป็นเงินสด'}</li>
                                <li>{myLang === 'en' ? 'Winning more than 20,000 baht, receive a "cheque" that can be cashed at Krung Thai Bank' : 'ถูกรางวัลมากกว่า 20,000 บาท รับเป็น "เช็ค" สามารถนำไปขึ้นเงินสดได้ที่ธนาคารกรุงไทย'}</li>
                                <li>{myLang === 'en' ? 'The cashier must be the same person as the name on the cheque before being able to withdraw.' :'ผู้ขึ้นเงินต้องเป็นคนเดียวกับชื่อบนเช็คจึงจะเบิกได้'}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default LotteryAwardStep;