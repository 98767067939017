import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import FirstLotteryAward from "./firstLotteryAward"
import {getYear} from "date-fns";
import Loading from '../libs/loading';
import LotteryLog from "./lotteryReportLog"
import './lottery.scss'

let transaction_object = {
    lottery_num:""
};


class LotteryAwardDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transaction_list: [],
            lottery_year_list:[],
            lottery_year_list_now:[],
            lotteryAward:[],
            period_date:"",
            year:"",
            dateLotteryAward:"",
            isCheckLengthAlert:false
        };

      this.appendNewRow = this.appendNewRow.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.deleteRow = this.deleteRow.bind(this);
      this.getThaiYear = this.getThaiYear.bind(this);
      this.getDateList = this.getDateList.bind(this)
      this.getLotteryDate = this.getLotteryDate.bind(this)
      this.getLotteryAwardNow = this.getLotteryAwardNow.bind(this)
      this.getLotteryAward = this.getLotteryAward.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
      this.logLotteryAward = this.logLotteryAward.bind(this)
   

    } 

    componentWillMount() {
        this.setState({
            transaction_list: [...this.state.transaction_list, { ...transaction_object }],
        });
    }

    componentDidMount() {
       let year_now =  getYear(new Date)
       this.getLotteryDate("2024")

       //main lottery content
       this.getLotteryAwardNow(year_now)
    }

    appendNewRow() {
        this.setState({ transaction_list: [...this.state.transaction_list, { ...transaction_object }] });
    }

    handleInputChange(e) {
        if (e.target.dataset.key === 'transaction_list') {
            // let transaction_list = [...this.state.transaction_list];

             let lottery_num_temp = []
             let elements = document.getElementsByName("lottery_num");

            for (let index = 0; index < elements.length; index++) {
                lottery_num_temp.push({
                   "lottery_num":elements[index].value
                })
            }

            this.setState({
                transaction_list: lottery_num_temp
            });

        } else {
            if(e.target.name === 'year'){
                this.getLotteryDate(e.target.value)
            }
            this.setState({[e.target.name]: e.target.value});
        }
    }

     logLotteryAward(myapiName){

        var formdata = new FormData();
        formdata.append("apiName",myapiName);

        LotteryLog.LotteryLog(formdata).then(res => {
            return;
          }).catch(error => {
              console.log(error);
          })
    }
    
    getLotteryDate(year){
        var raw = JSON.stringify({
            year: year,
            type: "CHECKED"
          });

        fetch("https://svmsupport.silverman.cloud/open/lottery/getPeriodsByYear", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
            },
            body: raw
        })
            .then((res) => res.json())
            .then((data) => {
                if(data){
                    this.setState({
                        lottery_year_list:data?.response?.result,
                        period_date: data?.response?.result[0].date
                    })
                    this.logLotteryAward("getPeriodsByYear")
                }
        });
    }

    deleteRow(indx) {
        let transaction_list_new = [...this.state.transaction_list];
        if (indx !== -1 && indx !== 0) {
            transaction_list_new.splice(indx, 1)
            this.setState({
                transaction_list: transaction_list_new
            });
        }
    }

    getLotteryAwardNow(year){
        var raw = JSON.stringify({
            year: year,
            type: "CHECKED"
          });

        fetch("https://svmsupport.silverman.cloud/open/lottery/getPeriodsByYear", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
            },
            body: raw
        })
            .then((res) => res.json())
            .then((data) => {
                if(data){
                    let lottery_year_list_main = this.getDateList(data?.response?.result)
                    this.getLotteryAward(lottery_year_list_main)
                }
        });
    }

    getLotteryAward(data){

       let defaultDateLotteryAward = data &&  Object.values(data)[0].date
       let dayFormat = format(new Date(defaultDateLotteryAward), "DD")
       let monthFormat = format(new Date(defaultDateLotteryAward), "MM")
       let yearFormat = format(new Date(defaultDateLotteryAward), "YYYY")

        var raw = JSON.stringify({
            date: dayFormat,
            month: monthFormat,
            year: yearFormat
          });

        fetch("https://svmsupport.silverman.cloud/open/lottery/getLotteryAward", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
            },
            body: raw
        })
            .then((res) => res.json())
            .then((data) => {
                if(data?.response){
                    this.setState({
                        lotteryAward : data?.response?.data,
                        dateLotteryAward: data?.response?.date
                    })
                    this.logLotteryAward("getLotteryAward")
                }
        });
    }

    getThaiYear(year) {
        let year_thai = parseInt(year) + 543
        return (year_thai).toString()
    }

    getDateList(data){ 
        // let result = data.reduce((unique, o) => {
        //     if(!unique.some(obj => obj.date === o.date)) {
        //       unique.push(o);
        //     }
        //     return unique;
        // },[]);
        // return result
        const result = data?.filter((obj, index) => {
            return index === data?.findIndex(o => obj.date === o.date);
          });
          
        return result
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let checkLength = false;

        this.state.transaction_list.forEach((value) => {
            if(value.lottery_num.length >= 7 || value.lottery_num.length < 6){
                checkLength = true;
            }
          })

        if(checkLength){
            this.setState({
                isCheckLengthAlert: true
            })
        }else{
            this.setState({
                isCheckLengthAlert: false
            })
            this.props.history.push({
                pathname:"/lottery-award/result",
                state: this.state
            })
            this.logLotteryAward("getcheckLotteryResult")
        }
    }

    render() {

        let num = 1;
        let lottery_date_list = this.getDateList(this.state.lottery_year_list);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams?.get('token')
        const lang = urlParams?.get('lang')

        let myLang = '';

        if(token && lang){
            window.localStorage.setItem("token", token);
            myLang = lang?.split("?")[0];
        }

        return (
            <div id="wrapper-content">
                <div id='svm-lottery' className='p-3' style={{height: '100vh',overflowX:'hidden'}}>
                        <div className='row pt-2'>
                            <div className='col text-center'><h4>{myLang === 'en' ? 'Check the results of the lottery numbers' : 'ตรวจผลรางวัลจากหมายเลขสลาก'}</h4></div>
                        </div>
                        <div>
                        <form onSubmit={this.handleSubmit}>
                            <div className='row justify-content-center'>
                            <div className='col-6'>
                            <div className="input-group">
                                   <select className="form-control preiod_date" name="year"
                                        onChange={this.handleInputChange}>
                                       {[2024,2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013].map((value) => {
                                            return (
                                                <option key={'year' + value} value={value}>
                                                    {this.getThaiYear(value)}
                                                </option>
                                            )
                                        })}
                                    </select>
                            </div>
                            </div>
                            <div className='col-6'>
                                <div className="input-group">
                                    <select className="form-control preiod_date" name="period_date" value={this.state.period_date}
                                            onChange={this.handleInputChange}>
                                            {
                                                lottery_date_list.map((value) => {
                                                    let dateInThai = format(new Date(value.date).setFullYear(parseInt(new Date(value.date).getFullYear()) + 543), "DD MMM YYYY", { locale: thLocale})
                                                return (
                                                    <option value={value.date}>
                                                        {dateInThai}
                                                    </option>
                                                )
                                            })
                                            }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='pt-2 pb-2'>
                            {
                                this.state.transaction_list.map((transaction, index) =>  {
                                    num = index
                                    return(
                                        <React.Fragment key={index}>
                                            <div className="row mt-1" style={{ display: "flex", alignItems: 'center' }}>
                                                <div className="col">
                                                    <input type="number" className="form-control"
                                                        name="lottery_num"
                                                        data-key={"transaction_list"}
                                                        data-id={index}
                                                        onChange={this.handleInputChange}
                                                        data-limit-rows="true"
                                                        placeholder='ค้นหาหมายเลขสลาก...'
                                                        style={{width:'93%',height:'48px'}}
                                                        size="6"
                                                    />
                                                </div>
                                                    <img src={process.env.PUBLIC_URL + '/images/lottery/btn-del.png'}  
                                                    style={{cursor:'pointer'}} className='btn-del' onClick={() => this.deleteRow(index)} />
                                                </div>
                                               
                                        </React.Fragment>
                                    )
                                })
                            }  
                        </div>
                        {
                            num++ >= 9 ?  '' : 
                            <>
                                <div className='btn-add-more'>
                                    <img src={process.env.PUBLIC_URL + '/images/lottery/add-btn.png'}  
                                    style={{cursor:'pointer'}} onClick={() => this.appendNewRow()}/> 
                                </div> 
                            </>
             
                        }
                        <div className='row justify-content-center' style={{marginTop:'-30px'}}>
                           <div className='col text-center'>
                            {/* <Link to={{
                                    pathname:"/lottery-award/result",
                                    state:this.state
                            }} > */}
                                    <div className='p-2'>
                                        {this.state.isCheckLengthAlert ? <span className='text-red'>{myLang === 'en' ? 'please fill with 6 number' : 'กรุณากรอกจำนวนตัวเลขให้ครบ 6 ตัวเท่านั้น'}
                                            </span> : ''}
                                    </div>
                                    <button type="submit" className="btn btn-primary" style={{width:'100%'}}>  
                                            {myLang === 'en' ? 'Check Results' : 'ตรวจผลรางวัล'}
                                    </button>
                            {/* </Link> */}
                           </div>
                        </div>
                     </form>
                        </div>
                        
                        {
                             this.state.lotteryAward ? <div className='lottery-award-content '>
                             <div className='mb-3 mt-6'>
                                 <h4 style={{margin:'0'}}>{myLang === 'en' ? 'Check the results of the lottery numbers' : 'ผลรางวัลจากหมายเลขสลาก'}</h4>
                                 <span style={{color:'#808080'}}> {myLang === 'en' ? 'Date' : 'งวดประจำวันที่ '}{this.state.dateLotteryAward && format(new Date(this.state.dateLotteryAward && this.state.dateLotteryAward).setFullYear(parseInt(new Date(this.state.dateLotteryAward && this.state.dateLotteryAward).getFullYear()) + 543), "DD MMM YYYY", { locale: thLocale})}</span> 
                             </div>
                             <div className='p-2' style={{backgroundColor:'#FFFFFF'}}>
                                 {
                                     Object.keys(this.state.lotteryAward).length > 1 ?
                                         <FirstLotteryAward 
                                         lang={myLang}
                                         lotteryAward={this.state.lotteryAward}
                                         />  :<Loading/>
                                 }
                                 <div className='row pt-8 justify-content-center'>
                                     <Link to={{
                                             pathname:"/lottery-award/view-more/lang"+myLang,
                                             state:this.state
                                     }} >
                                     <div className='col-12 text-center view-award-back' style={{color:'#1567B4'}}>
                                         <span> {myLang === 'en' ? 'View' : 'ดูผลรางวัลทั้งหมด'}</span>
                                     </div>
                                     </Link>
                                 </div>
                             </div>
                             
                         </div> : <Loading/>
                        }
                       

                        <div className='row pt-6 justify-content-center pb-6'>
                                    <div className='col-12 text-center btn-view-award-back-all p-3'>
                                        <Link to={{ pathname:"/lottery-award/view-all",
                                                state:this.state
                                        }} >
                                            <span> {myLang === 'en' ? 'View All' : 'ดูผลรางวัลย้อนหลังทั้งหมด'}</span>
                                        </Link>
                                    </div>
                        </div>
                </div>
            </div>
        );
    }
}

export default LotteryAwardDashboard;