import React, {Component} from "react";
import {format} from "date-fns";
import { graphql } from "babel-plugin-relay/macro";
import jwtDecode from 'jwt-decode';
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import getNameResidential from "../../../../libs/getNameResidential";
import localStorage from "../../../../libs/localstorage";
import './historyReportPDF.scss';

const query = graphql`
  query historyReportPDFRenderTableQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

class HistoryReportPDFRenderTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            projectName: "",
            printBy: "",
            start_date: "",
            end_date: "",
            contactName: "",
            statusType: "",
            paymentChannel: "",
            typePrint: "",
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            if (res.selfProject) {
                let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
                this.setState({
                    data: this.props.props,
                    projectName: res.selfProject.name,
                    printBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    contactName: this.props.contactName,
                    statusType: this.props.statusType,
                    paymentChannel: this.props.paymentChannel,
                    typePrint: this.props.typePrint,
                })
            }
        }
        )
    }

    removeTrailingZeros(description){
        if(description.includes("ค่าน้ำประปา")){
            description = description.replace(/ *\[[^)]*\] */g, " ")
            // description = description.replace(/ *\[[^)]*\] */g, " ").replace(/.50 /gi, ".5 ")
            // description = description.replace(/.00 /gi, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }
    numberWithComma(num) {
        let isNegative = false;
        // Check if the number is negative
        if (num < 0) {
          isNegative = true;
          num = Math.abs(num); // Convert negative number to positive
        }
        // Add .00 to the number
        num = num.toFixed(2);
        // Format the number with commas
        let formattedNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // Add a minus sign for negative numbers
        if (isNegative) {
          formattedNum = '(' + formattedNum + ')';
        }
        return formattedNum;
      }    
    render() {
        // ประมวลผลข้อมูลเก็บ data รายบรรทัดเข้าไปใน array เดียวเพื่อนำไป render เป็น pdf 
        let dataAll = [];
        let dataRowSum = [];
        let transactionRow = "";
        let sumRow = "";
        let order_id = 0;  

        let sumAmount = 0;
        let sumAmountRt = 0;
        let sumAmountCN = 0;
        let sumAmountRd = 0;    

        let sumTotalAmount = 0;
        let sumTotalAmountRt = 0;
        let sumTotalAmountCN = 0;
        let sumTotalAmountRd = 0;     


        let orderCustomerFromPrintAll = 0;
        let orderPerCustomerFromPrintAll = 0;

        this.state.data && this.state.data.invoiceViewer.allInvoice.edges.map((invoice, index) => {
            let order_id = this.props.dataFirst - localStorage.getPageLimit();
            invoice.node.transaction.edges.map((transaction, t_index) => {
            let row_number = (t_index === 0 && order_id + index + 1);
                        let balance = transaction.node.total;
                        let rt_cn_keep = [];
                        let rt_info = [];
                        let cn_info = [];
                        let cn_infoV2 = [];
                        let rd_info = [];
                        //order by date cn
                        rt_cn_keep = [];
                        transaction.node.creditNoteTransaction.edges.map((cn_transaction_id) => {
                            return rt_cn_keep.push(cn_transaction_id.node.issuedDate);
                        });
                        rt_cn_keep.sort()
                        rt_cn_keep.forEach((element) => {
                            transaction.node.creditNoteTransaction.edges.map((e,index) => {
                                if(element === e.node.issuedDate ){
                                    let check_cn = cn_info.find(en => en.node.receive?.id === e.node.receive?.id)
                                    if(!check_cn && e.node.status !== "VOID")cn_info.push(e)
                                }
                                return null
                            });
                        });

                        //order by date cn
                        rt_cn_keep = [];
                        transaction.node.creditNoteTransactionRecord.edges.map((cn_transaction_id) => {
                            return rt_cn_keep.push(cn_transaction_id.node.creditNote.issuedDate);
                        });
                        rt_cn_keep.sort()
                        rt_cn_keep.forEach((element) => {
                            transaction.node.creditNoteTransactionRecord.edges.map((e,index) => {
                                if(element === e.node.creditNote.issuedDate ){
                                    if(e.node.status !== "VOID")cn_infoV2.push(e)
                                }
                                return null
                            });
                        });
                        // order by date RT
                        transaction.node.receiveTransaction.edges.map((rt_transaction_id,index) => {
                            return rt_cn_keep.push(rt_transaction_id.node.receive.issuedDate);
                        });
                        rt_cn_keep.sort();
                        rt_cn_keep.forEach((element) => {
                            transaction.node.receiveTransaction.edges.map((e, index) => {
                                if (element === e.node.receive.issuedDate) {
                                    let check_rt = rt_info.find(en => en.node.receive?.id === e.node.receive?.id);
                                    if (!check_rt) rt_info.push(e);
                                }
                                return null;
                            });
                        });
                        // check RT duplicate with CN 
                        rt_info.forEach(en => {
                            let check_cn = cn_info.find(cn => cn.node.receive && cn.node.receive.id === en.node.receive.id);
                            if (check_cn) {
                                rt_info.pop();
                            }
                        });

                        //order by date rd
                        transaction.node.receiptDepositTransactionUsed.edges.map((rd_transaction_id) => {
                            return rd_info.push(rd_transaction_id);
                        });

                        // กรณีที่เลือกปริ๊นจากหน้าปริ๊นทั้งหมด จะมีคอลัมแสดงชื่อ เพิ่มขึ้นมา
                        if((this.state.typePrint === 'all') && (invoice.node.contact.id !== this.state.data.invoiceViewer.allInvoice.edges[index-1]?.node.contact.id)){
                            orderPerCustomerFromPrintAll = 0;
                            sumAmount = 0;
                            sumAmountRt = 0;
                            sumAmountCN = 0;
                            sumAmountRd = 0;
                            dataAll.push(
                            <tr key={"nameContact"+transaction.node.id}>
                                <td colSpan={15}>{orderCustomerFromPrintAll+=1}.{" "}บ้านเลขที่ {invoice.node.contact.name}{" "}{invoice.node.contact.firstName}{" "}{invoice.node.contact.lastName}</td>
                            </tr>)     
                        }

                        transactionRow = 
                                (<tr key={transaction.node.id}>
                                    <td className="text-center">{(this.state.typePrint === 'all')? orderPerCustomerFromPrintAll+=1 : 
                                   row_number}</td>
                                    <td className="text-center">{format(invoice.node.issuedDate, 'DD/MM/YYYY')}</td>
                                    <td className="text-center">
                                            {invoice.node.docNumber}
                                    </td>
                                    <td>{
                                        this.removeTrailingZeros(transaction.node.description)
                                        }</td>
                                    <td className="text-right">{this.numberWithComma(transaction.node.total)}</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td className="text-right">{this.numberWithComma(balance)}</td>
                                </tr>)
                        dataAll.push(transactionRow)
                        sumAmount += transaction.node.total;   
                        sumTotalAmount +=  transaction.node.total;

                        if((this.state.typePrint !== 'all')) {
                            sumRow =  (<tr key={'sumRow'+ transaction.node.id}>
                            <td className="text-center font-weight-bold" colSpan={4}>
                                รวม
                            </td>
                            <td className="text-right font-weight-bold">
                                {this.numberWithComma(sumAmount, "-")}
                            </td>
                            <td className="text-right font-weight-bold" colSpan={3}>
                                {this.numberWithComma(sumAmountRt, "-")}
                            </td>
                            <td className="text-right font-weight-bold" colSpan={3}>
                                {this.numberWithComma(sumAmountCN, "-")}
                            </td>
                            <td className="text-right font-weight-bold" colSpan={3}>
                                {this.numberWithComma(sumAmountRd, "-")}
                            </td>
                            <td className="text-right font-weight-bold">
                                {this.numberWithComma(sumAmount - sumAmountRt - sumAmountCN - sumAmountRd, "-")}
                            </td>
                        </tr>                            
                        )   
                        dataRowSum.push(sumRow)  
                        }

                                rt_info.map((rt_transaction,index) => {
                                        let amount_total = rt_transaction.node.amount
                                        let rd_total = 0
                                        //rd
                                        // rt_transaction.node.receiptDepositTransactionUsed.edges.map((rd) => {
                                        //     rd_total += rd.node.amount
                                        //     return amount_total +=  Math.abs(rd.node.amount)
                                        // })
                                        //cn
                                        rt_transaction.node.receive.creditNoteReceive.edges.map((cd) => {
                                            if(amount_total === cd.node.price) return;
                                            return amount_total
                                        })
                                        balance -= amount_total;
                                        if(balance < 0.00)balance=0.00;
                                        transactionRow =
                                            (<tr key={rt_transaction.node.id}>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-center">
                                                    {format(rt_transaction.node.receive.issuedDate, 'DD/MM/YYYY')}
                                                </td>
                                                <td className="text-center">
                                                        {rt_transaction.node.receive.docNumber}
                                                </td>
                                                <td className="text-right">
                                                    {this.numberWithComma((rt_transaction.node.amount),"0.00")}
                                                    {/* {this.numberWithComma((amount_total),"0.00")} */}
                                                </td>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-right">{this.numberWithComma(balance, "0.00")}</td>
                                            </tr>)
                                        dataAll.push(transactionRow)  
                                        sumAmountRt += rt_transaction.node.amount;
                                        sumTotalAmountRt += rt_transaction.node.amount;


                                        if((this.state.typePrint !== 'all')) {
                                        sumRow = 
                                        (<tr key={'sumRow'+ rt_transaction.node.id}>
                                            <td className="text-center font-weight-bold" colSpan={4}>
                                                รวม
                                            </td>
                                            <td className="text-right font-weight-bold">
                                                {this.numberWithComma(sumAmount, "-")}
                                            </td>
                                            <td className="text-right font-weight-bold" colSpan={3}>
                                                {this.numberWithComma(sumAmountRt, "-")}
                                            </td>
                                            <td className="text-right font-weight-bold" colSpan={3}>
                                                {this.numberWithComma(sumAmountCN, "-")}
                                            </td>
                                            <td className="text-right font-weight-bold" colSpan={3}>
                                                {this.numberWithComma(sumAmountRd, "-")}
                                            </td>
                                            <td className="text-right font-weight-bold">
                                                {this.numberWithComma(sumAmount - sumAmountRt - sumAmountCN - sumAmountRd, "-")}
                                            </td>
                                        </tr>                            
                                        )    
                                        dataRowSum.push(sumRow)        
                                        }    
                                })
                        
                                cn_info.map((cn_transaction) => {
                                        balance -= cn_transaction.node.price;
                                        if(Math.round(balance) === -0){
                                            balance = 0
                                        }
                                        transactionRow = (
                                            <tr key={cn_transaction.node.id}>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-center">{format(cn_transaction.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                <td className="text-center">
                                                        {cn_transaction.node.docNumber}
                                                </td>
                                                <td className="text-right red">
                                                    ({this.numberWithComma(cn_transaction.node.price)})
                                                </td>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-right">{this.numberWithComma(balance, "0.00")}</td>
                                            </tr>
                                            )
                                        dataAll.push(transactionRow)
                                        sumAmountCN += cn_transaction.node.price;
                                        sumTotalAmountCN += cn_transaction.node.price;
                                        
                                        if((this.state.typePrint !== 'all')) {
                                        sumRow = 
                                        (<tr key={'sumRow'+ cn_transaction.node.id}>
                                            <td className="text-center font-weight-bold" colSpan={4}>
                                                รวม
                                            </td>
                                            <td className="text-right font-weight-bold">
                                                {this.numberWithComma(sumAmount, "-")}
                                            </td>
                                            <td className="text-right font-weight-bold" colSpan={3}>
                                                {this.numberWithComma(sumAmountRt, "-")}
                                            </td>
                                            <td className="text-right font-weight-bold" colSpan={3}>
                                                {this.numberWithComma(sumAmountCN, "-")}
                                            </td>
                                            <td className="text-right font-weight-bold" colSpan={3}>
                                                {this.numberWithComma(sumAmountRd, "-")}
                                            </td>
                                            <td className="text-right font-weight-bold">
                                                {this.numberWithComma(sumAmount - sumAmountRt - sumAmountCN - sumAmountRd, "-")}
                                            </td>
                                        </tr>                            
                                        )    
                                        dataRowSum.push(sumRow)  
                                        }
                                })

                                cn_infoV2.map((cn_transaction) => {
                                    balance -= cn_transaction.node.price;
                                    if(balance.toFixed(2) < 0 || balance.toFixed(2) === "-0.00"){
                                        balance = 0
                                    }   
                                    transactionRow = (
                                        <tr key={cn_transaction.node.id}>
                                            <td/>
                                            <td/>
                                            <td/>
                                            <td/>
                                            <td/>
                                            <td/>
                                            <td/>
                                            <td/>
                                            <td className="text-center">{format(cn_transaction.node.creditNote.issuedDate, 'DD/MM/YYYY')}</td>
                                            <td className="text-center">
                                                    {cn_transaction.node.creditNote.docNumber}
                                            </td>
                                            <td className="text-right red">
                                                ({this.numberWithComma(cn_transaction.node.price)})
                                            </td>
                                            <td/>
                                            <td/>
                                            <td/>
                                            <td className="text-right">{this.numberWithComma(balance, "0.00")}</td>
                                        </tr>
                                        )
                                    dataAll.push(transactionRow)
                                    sumAmountCN += cn_transaction.node.price;
                                    sumTotalAmountCN += cn_transaction.node.price;
                                    
                                    if((this.state.typePrint !== 'all')) {
                                    sumRow = 
                                    (<tr key={'sumRow'+ cn_transaction.node.id}>
                                        <td className="text-center font-weight-bold" colSpan={4}>
                                            รวม
                                        </td>
                                        <td className="text-right font-weight-bold">
                                            {this.numberWithComma(sumAmount, "-")}
                                        </td>
                                        <td className="text-right font-weight-bold" colSpan={3}>
                                            {this.numberWithComma(sumAmountRt, "-")}
                                        </td>
                                        <td className="text-right font-weight-bold" colSpan={3}>
                                            {this.numberWithComma(sumAmountCN, "-")}
                                        </td>
                                        <td className="text-right font-weight-bold" colSpan={3}>
                                            {this.numberWithComma(sumAmountRd, "-")}
                                        </td>
                                        <td className="text-right font-weight-bold">
                                            {this.numberWithComma(sumAmount - sumAmountRt - sumAmountCN - sumAmountRd, "-")}
                                        </td>
                                    </tr>                            
                                    )    
                                    dataRowSum.push(sumRow)  
                                    }
                                })

                                rd_info.map((rt_transaction) => {
                                        if(rt_transaction.node.receiptDepositTransaction.couple === "HAVE" && rt_transaction.node.coupleUse === "HAVE"){
                                            balance -= rt_transaction.node.amount;

                                            if(Math.round(balance) === -0){
                                                balance = 0
                                            }
                                            transactionRow = (
                                                <tr key={rt_transaction.node.receiptDeposit.id}>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td className="text-center">{format(rt_transaction.node.receiptDeposit.issuedDate, 'DD/MM/YYYY')}</td>
                                                    <td className="text-center">
                                                            {rt_transaction.node.receiptDeposit.docNumber}
                                                    </td>
                                                    <td className="text-right">
                                                        {this.numberWithComma(rt_transaction.node.amount)}
                                                    </td>
                                                    <td className="text-right">{this.numberWithComma(balance, "0.00")}</td>
                                                </tr>
                                                )
                                            dataAll.push(transactionRow) 
                                            sumAmountRd += rt_transaction.node.amount;
                                            sumTotalAmountRd += rt_transaction.node.amount;   

                                            if((this.state.typePrint !== 'all')) {
                                            sumRow = 
                                            (<tr key={'sumRow'+ rt_transaction.node.receiptDeposit.id}>
                                                <td className="text-center font-weight-bold" colSpan={4}>
                                                    รวม
                                                </td>
                                                <td className="text-right font-weight-bold">
                                                    {this.numberWithComma(sumAmount, "-")}
                                                </td>
                                                <td className="text-right font-weight-bold" colSpan={3}>
                                                    {this.numberWithComma(sumAmountRt, "-")}
                                                </td>
                                                <td className="text-right font-weight-bold" colSpan={3}>
                                                    {this.numberWithComma(sumAmountCN, "-")}
                                                </td>
                                                <td className="text-right font-weight-bold" colSpan={3}>
                                                    {this.numberWithComma(sumAmountRd, "-")}
                                                </td>
                                                <td className="text-right font-weight-bold">
                                                    {this.numberWithComma(sumAmount - sumAmountRt - sumAmountCN - sumAmountRd, "-")}
                                                </td>
                                            </tr>                            
                                            )    
                                            dataRowSum.push(sumRow) 
                                            }

                                            }else{
                                                return (null)
                                            }
                                }) 

                                // กรณีที่เลือกปริ๊นจากหน้าปริ๊นทั้งหมด จะมีคอลัมรวมรายลูกหนี้ชื่อ เพิ่มขึ้นมา
                        if (this.state.typePrint === 'all' && (invoice.node.contact.id !== (this.state.data.invoiceViewer.allInvoice.edges[index + 1]?.node.contact.id))) {
                            dataAll.push(
                                <tr key={'sumByCustomer' + transaction.node.id}>
                                    <td className="text-center font-weight-bold" colSpan={4}>
                                        รวม บ้านเลขที่ {invoice.node.contact.name} {invoice.node.contact.firstName}{' '}
                                        {invoice.node.contact.lastName}
                                    </td>
                                    <td className="text-right font-weight-bold">{this.numberWithComma(sumAmount, '-')}</td>
                                    <td className="text-right font-weight-bold" colSpan={3}>
                                        {this.numberWithComma(sumAmountRt, '-')}
                                    </td>
                                    <td className="text-right font-weight-bold" colSpan={3}>
                                        {this.numberWithComma(sumAmountCN, '-')}
                                    </td>
                                    <td className="text-right font-weight-bold" colSpan={3}>
                                        {this.numberWithComma(sumAmountRd, '-')}
                                    </td>
                                    <td className="text-right font-weight-bold">
                                        {this.numberWithComma(sumAmount - sumAmountRt - sumAmountCN - sumAmountRd, '-')}
                                    </td>
                                </tr>
                            );
                        }
                                 
            })
        })
        // คำนวน data row ต่อหนึ่งหน้า A4
        let dataChunked = [];
        let rowPerpage = 16;        
        for (let i=0;i<dataAll.length ;i += rowPerpage) {
            let chunk = dataAll.slice(i, i + rowPerpage);
            if(i + rowPerpage < dataRowSum.length-1) {
                chunk.push(dataRowSum[i + rowPerpage - 1])  
            }
            dataChunked.push(chunk);            
        }


        return (
            <React.Fragment>
            {dataChunked.map((renderA4, page_index)=> (
                    <div className='print-page-a4 hoziA4' key={page_index}>
                                    {/* แสดงหัวกระดาษ */}
                                    <div>
                                            <p className='f12' style={{ lineHeight: 1.5 }}> {this.state.projectName}</p>
                                            <p className='f10'>รายงานการเคลื่อนไหวลูกหนี้</p>
                                            <p className='f10'>เรียงตามชื่อ - {" "}
                                                {this.state.typePrint === "all" ? 'ทั้งหมด': 
                                                this.state.data.contact
                                                    ? this.state.data.contact.typeOfContact ===
                                                        "RESIDENTIAL"
                                                        ? this.state.data.contact.refNumber +
                                                        " " +
                                                        this.state.data.contact.name +
                                                        " " +
                                                        getNameResidential(
                                                            this.state.data.contact.firstName,
                                                            this.state.data.contact.lastName
                                                        )
                                                        : this.state.data.contact.refNumber +
                                                        " " +
                                                        this.state.data.contact.name
                                                    : "-"                                                    
                                                }                                        
                                            </p>
                                            <p className='f10'>ณ วันที่  {format(this.state.start_date, "DD-MM-YYYY")} ถึง {format(this.state.end_date, "DD-MM-YYYY")}</p>   
                                    </div>
                                    {/* แสดงตาราง */}
                                    <table className="table tebleBorder">
                                        <thead className='tableHead'>
                                            <tr>
                                                <th className="text-center width2">ลำดับ</th>
                                                <th className="text-center width7">วันที่ใบแจ้งหนี้</th>
                                                <th className="text-center width7">เลขที่ใบแจ้งหนี้</th>
                                                <th className="text-center width20">รายละเอียด</th>
                                                <th className="text-center">จำนวนเงิน</th>
                                                <th className="text-center">วันที่ใบเสร็จ</th>
                                                <th className="text-center width7">เลขที่ใบเสร็จ</th>
                                                <th className="text-center">ยอดรับชำระ</th>
                                                <th className="text-center">วันที่ใบลดหนี้</th>
                                                <th className="text-center width7">ใบลดหนี้</th>
                                                <th className="text-center">ยอดลดหนี้</th>
                                                <th className="text-center">วันที่รับเงินล่วงหน้า</th>
                                                <th className="text-center width7">รับเงินล่วงหน้า</th>
                                                <th className="text-center">ยอดรับเงินล่วงหน้า</th>
                                                <th className="text-center">ยอดคงเหลือ</th>
                                            </tr>
                                        </thead>
       
                                        <tbody className='tableBody'>                
                                            {renderA4.map((renderRow)=>(
                                                renderRow
                                            ))}
                                        </tbody>
                                        <tfoot className="tableFooter">
                                            {page_index +1 === dataChunked.length && 
                                            <tr >
                                                <td className="text-center font-weight-bold" colSpan={4}>
                                                    รวมทั้งหมด
                                                </td>
                                                <td className="text-right font-weight-bold">
                                                    {this.numberWithComma(sumTotalAmount, "-")}
                                                </td>
                                                <td className="text-right font-weight-bold" colSpan={3}>
                                                    {this.numberWithComma(sumTotalAmountRt, "-")}
                                                </td>
                                                <td className="text-right font-weight-bold" colSpan={3}>
                                                    {this.numberWithComma(sumTotalAmountCN, "-")}
                                                </td>
                                                <td className="text-right font-weight-bold" colSpan={3}>
                                                    {this.numberWithComma(sumTotalAmountRd, "-")}
                                                </td>
                                                <td className="text-right font-weight-bold">
                                                    {this.numberWithComma(sumTotalAmount - sumTotalAmountRt - sumTotalAmountCN - sumTotalAmountRd, "-")}
                                                </td>
                                            </tr>
                                            }
                                        </tfoot>
                                    </table>

                                    {/* แสดงเลขหน้า และผู้พิมพ์ */}
                                    <div className="footer">
                                        <div className="row">
                                            <div className="col-5" />
                                            <div className="col-2 text-center">
                                                <p className="f10 pb-0 mb-0">{page_index + 1}/{dataChunked.length}</p>
                                            </div>
                                            <div className="col-5 text-right">
                                                <p className="f10 pb-0 mb-0">
                                                    ผู้พิมพ์ {this.state.printBy} วันที่พิมพ์{format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>  
                    </div>
            ))}
            </React.Fragment>
        )
    }
}

export default HistoryReportPDFRenderTable;