import React, {Component} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import i18next from "i18next";

const mutation = graphql`
    mutation dailyJournalDeleteMutation($input: DeleteAccountRecordGroupInput!) {
        deleteAccountRecordGroup(input: $input) {
            ok
            warningText
        }
    }
`;

class DailyJournalDelete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
        this.onDelete = this.onDelete.bind(this)
    }

    onDelete(e) {
        e.preventDefault();
        this.setState({loading: true});

        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                const variables = {
                    input: {
                        clientMutationId: this.props.id,
                    }
                };

                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if (response.deleteAccountRecordGroup.ok) {
                                Swal.fire('ลบสำเร็จ!', '', 'success').then(() => this.props.this.setState({redirect: true}));
                                this.setState({loading: false});
                            } else {
                                Swal.fire('ลบไม่สำเร็จ!', response.deleteAccountRecordGroup.warningText, 'warning');
                                this.setState({loading: false});
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
                            this.setState({loading: false});
                        },
                    },
                )

            }
        })
    }

    render() {
        return (
            <button className="btn btn-danger float-right mt-3"
                    disabled={this.state.loading} type="submit" onClick={this.onDelete}>
                {this.state.loading &&
                <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                ลบบัญชีรายวัน
            </button>
        )
    }
}

export default DailyJournalDelete;