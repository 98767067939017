import './styles/createBudget.scss'
import React, { Component } from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import NavigationMenuSetup from "../../libs/navigationMenuSetup";
import BackButtonIcon from "../../components/BackBtn/indexBack";
import i18next from 'i18next';
class ProjectTopMenuNavigation extends Component {
  componentDidMount() {
    NavigationMenuSetup();
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div className="container-fluid bg-gray dropdown-budget">
            <div className={"row " + (this.props.mini ? "mini " : "") + (this.props.center ? "center-navigation" : "top-menu-navigation")}>
              <div className="col-12  mb-5 p-3">
                <BackButtonIcon
                  LinkBack={"/accounting"}
                  LinkText={i18next.t("menuListBudget:Budget")}
                />
              </div>

              <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
                <ul>
                  <li>
                    <Link to="/accounting/budget/budgetDraft/list/all">
                      <div>1. {i18next.t("menuListBudget:Draft Budget")}</div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/accounting/budget/list/all">
                      <div>2. {i18next.t("menuListBudget:Record Budget Setting")}</div>
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-toggle"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      <div>3. {i18next.t("menuListBudget:Reports")}</div>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <Link to="/accounting/budget/report/report-seting">
                        <div>{i18next.t("menuListBudget:Annual Budget Setting Report")}</div>
                      </Link>
                      <Link to="/accounting/budget/report/report-usage-summary">
                        <div>{i18next.t("menuListBudget:Summary Budget Usage Report")}</div>
                      </Link>
                      <div className="dropdown">
                        <Link to="#">
                          <div data-toggle="dropdown" id="dropdownMenuButton" aria-haspopup="false" aria-expanded="true">
                          {i18next.t("menuListBudget:Monthly Budget Usage Report")}
                          </div>
                        </Link>
                        <div style={{ margin: 0, padding: 0 }} className="dropdown-menu" aria-labelledby="dropdownMenuButton" >
                          <Link to="/accounting/budget/report/report-month-budget-usage">
                            <div style={{ paddingLeft: 8 }}  >{i18next.t("menuListBudget:Monthly Budget Usage Report vs. Budget Setting")}</div>
                          </Link>
                          <Link to="/accounting/budget/report/report-month-budget-usage-summary">
                            <div style={{ paddingLeft: 8 }} >{i18next.t("menuListBudget:Monthly Budget Usage Report Summary Remaining Balance")}</div>
                          </Link>
                        </div>
                      </div>
                      <Link to="/accounting/budget/reportRegister/list">
                        <div>{i18next.t("menuListBudget:Budget Usage Control Register")}</div>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default ProjectTopMenuNavigation;
