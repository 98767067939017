/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SettingAssetTypeAsset = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type settingAssetDetailQueryVariables = {|
  settingAssetId?: ?string
|};
export type settingAssetDetailQueryResponse = {|
  +allSettingAsset: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +typeAsset: SettingAssetTypeAsset,
        +depreciationRatePercent: ?number,
        +depreciationRate: ?number,
        +lifeTimeYear: ?number,
        +chartOfAccountType: {|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
          +nameEn: ?string,
        |},
        +chartOfAccountDepreciation: ?{|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
          +nameEn: ?string,
        |},
        +chartOfAccountDepreciationCumulative: ?{|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
          +nameEn: ?string,
        |},
      |}
    |}>
  |}
|};
export type settingAssetDetailQuery = {|
  variables: settingAssetDetailQueryVariables,
  response: settingAssetDetailQueryResponse,
|};
*/


/*
query settingAssetDetailQuery(
  $settingAssetId: String
) {
  allSettingAsset(settingAssetId: $settingAssetId) {
    edges {
      node {
        id
        typeAsset
        depreciationRatePercent
        depreciationRate
        lifeTimeYear
        chartOfAccountType {
          id
          chartOfAccountCode
          name
          nameEn
        }
        chartOfAccountDepreciation {
          id
          chartOfAccountCode
          name
          nameEn
        }
        chartOfAccountDepreciationCumulative {
          id
          chartOfAccountCode
          name
          nameEn
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "settingAssetId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "chartOfAccountCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nameEn",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "settingAssetId",
        "variableName": "settingAssetId"
      }
    ],
    "concreteType": "SettingAssetNodeConnection",
    "kind": "LinkedField",
    "name": "allSettingAsset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingAssetNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingAssetNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeAsset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "depreciationRatePercent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "depreciationRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lifeTimeYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "chartOfAccountType",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "chartOfAccountDepreciation",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "chartOfAccountDepreciationCumulative",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingAssetDetailQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingAssetDetailQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "7b1cb7bf7fdb0592039048a24ebcc1b3",
    "id": null,
    "metadata": {},
    "name": "settingAssetDetailQuery",
    "operationKind": "query",
    "text": "query settingAssetDetailQuery(\n  $settingAssetId: String\n) {\n  allSettingAsset(settingAssetId: $settingAssetId) {\n    edges {\n      node {\n        id\n        typeAsset\n        depreciationRatePercent\n        depreciationRate\n        lifeTimeYear\n        chartOfAccountType {\n          id\n          chartOfAccountCode\n          name\n          nameEn\n        }\n        chartOfAccountDepreciation {\n          id\n          chartOfAccountCode\n          name\n          nameEn\n        }\n        chartOfAccountDepreciationCumulative {\n          id\n          chartOfAccountCode\n          name\n          nameEn\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '68c2040de91bf4214b9ba448aaa4c582';

module.exports = node;
