import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import ProductServiceList from "../productServiceList";
import InvoiceReportTable from "./invoiceReportTable";
import ExportInvoice from "./server_export";
import ContactReportList from "../contactReportList";
import _ from "lodash";
import Loading from '../../../../libs/loading';
import postApiUseing from '../../../../libs/postApiUseing';
import { fetchQuery } from "relay-runtime";
import Swal from "sweetalert2";
import './invoiceReportPDF.scss'
import i18next from "i18next";

const query = graphql`
  query invoiceReportQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $newEndDate: Date
    $search: String
    $status: String
    $customerType: String
    $productAndService: String
    $productAndServiceID: ID
    $order: String
    $suborder: String
    $contact: String
  ) {
    invoiceViewer {
      allInvoice(
        approveOnly: true
        first: $first
        last: $last
        startDate: $startDate
        endDate: $endDate
        search: $search
        statusNew: $status
        customerType: $customerType
        productAndService: $productAndService
        order: $order
        suborder: $suborder
        contact: $contact
        report: true
        useEndDate:true
      ) {
        edges {
          node {
            id
            docNumber
            issuedDate
            dueDate
            status
            name
            firstName
            lastName
            payGroup
            contact {
              name
              refNumber
              firstName
              lastName
              typeOfContact
              residential {
                name
              }
            }
            transaction(productAndService_Id: $productAndServiceID) {
              edges {
                node {
                  id
                  description
                  paid
                  total
                  productAndService {
                    id
                    productCode
                  }

                  creditNoteTransaction(status: "paid",issuedDate_Lte:$newEndDate) {
                    edges {
                      node {
                        id
                        docNumber
                        issuedDate
                        price
                        status
                      }
                    }
                  }
                  creditNoteTransactionRecord(status: "paid"){
                      edges{
                          node{
                              id
                              price
                              creditNote{
                                  id
                                  docNumber
                                  issuedDate
                                  status
                              }
                              status
                          }
                      }
                  }
                  receiptDepositTransactionUsed {
                    edges {
                      node {
                        id
                        coupleUse
                        receiptDeposit {
                          id
                          docNumber
                          issuedDate
                        }
                        receiptDepositTransaction {
                          couple
                        }
                      }
                    }
                  }

                  receiveTransaction (receive_IssuedDate_Lte:$newEndDate){
                    edges {
                      node {
                        id
                        amount
                        receive {
                          id
                          docNumber
                          status
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
      summaryOnlyReportInvoice(
        approveOnly: true
        startDate: $startDate
        endDate: $endDate
        search: $search
        statusNew: $status
        customerType: $customerType
        productAndService: $productAndService
        contact: $contact
      )
    }
  }
`;

class InvoiceReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);

    this.state.current_date = new Date();
    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.min_date = min_date;
    this.state.product_and_service = "";
    this.state.temp_product_and_service = "";
    this.state.search = "";
    this.state.search_input = "";
    this.state.status = "";
    this.state.temp_status = "";

    this.state.sortStateDocNumber = false;
    this.state.sortStateIssuedDate = false;
    this.state.sortStateDueDate = false;
    this.state.sortStateRoom = false;
    this.state.sortStateTotal = false;

    this.state.suborder = "contact__residential__ref_number";
    this.state.typeSort = "asc";
    this.state.temp_typeSort = "asc";

    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.customer_type = "";
    this.state.checkFetchState = false;
    this.state.dataResult = "";
    this.state.loading = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSortAmountChange = this.handleSortAmountChange.bind(this);

    this.advanceSortToggle = this.advanceSortToggle.bind(this);
    this.fetchDataQuery = this.fetchDataQuery.bind(this);
    this.checkLoadingExcel = this.checkLoadingExcel.bind(this);

  }

  componentWillMount() {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "invoiceReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

    this.fetchDataQuery();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.first !== this.state.first || prevState.last !== this.state.last) {
      this.fetchDataQuery();
    }

  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.setState({
      suborder: "contact__residential__ref_number",
      sortStateIssuedDate: false,
      sortStateDueDate: false,
      sortStateRoom: false,
      sortStateTotal: false,
      sortStateDocNumber: false,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }

  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      typeSort: this.state.temp_typeSort,
      product_and_service: this.state.temp_product_and_service,
      product_and_service_id: this.state.temp_product_and_service_id,
      status: this.state.temp_status,
      contact: this.state.temp_contact,
    },
      () => {
        this.fetchDataQuery()
        this.goFirst()
      });
    // super.goFirst()
  }

  handleSortAmountChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  advanceSortToggle(sortCol) {
    let sortStateIssuedDate = false;
    let sortStateDueDate = false;
    let sortStateRoom = false;
    let sortStateTotal = false;
    let sortStateDocNumber = false;
    if (sortCol === "issued_date") {
      sortStateIssuedDate = !this.state.sortStateIssuedDate;
      if (sortStateIssuedDate) {
        sortCol = `-${sortCol}`;
      }
    }
    if (sortCol === "doc_number") {
      sortStateDocNumber = !this.state.sortStateDocNumber;
      if (sortStateDocNumber) {
        sortCol = `-${sortCol}`;
      }
    }
    if (sortCol === "due_date") {
      sortStateDueDate = !this.state.sortStateDueDate;
      if (sortStateDueDate) {
        sortCol = `-${sortCol}`;
      }
    }
    if (sortCol === "contact__residential__ref_number") {
      sortStateRoom = !this.state.sortStateRoom;
      if (!sortStateRoom) {
        sortCol = `-${sortCol}`;
      }
    }
    if (sortCol === "total") {
      sortStateTotal = !this.state.sortStateTotal;
      if (sortStateTotal) {
        sortCol = `-${sortCol}`;
      }
    }

    this.setState({
      suborder: sortCol,
      sortStateIssuedDate: sortStateIssuedDate,
      sortStateDueDate: sortStateDueDate,
      sortStateRoom: sortStateRoom,
      sortStateTotal: sortStateTotal,
      sortStateDocNumber: sortStateDocNumber,
    });
  }
  onClickGetData(data) {
    this.props.history.push(`/accounting/report/account-receivable/invoice/PDF`, { data: data })
  }

  fetchDataQuery() {
    this.setState({
      checkFetchState: false,
      loading: true,
    })
    fetchQuery(environment, query, {
      first: this.state.first,
      last: this.state.last,
      startDate: this.state.start_date,
      endDate: this.state.end_date,
      newEndDate: this.state.end_date.toISOString().slice(0, 10),
      search: this.state.search,
      status: this.state.status,
      customerType: this.state.customer_type,
      productAndService: this.state.product_and_service,
      productAndServiceID: this.state.product_and_service,
      order: "doc_number",
      contact: this.state.contact,
      suborder: this.state.suborder,
    }).then((item) => {
      this.setState({ dataResult: item, checkFetchState: true, loading: false });
    }).catch(error => {
      Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
    })
  }

  checkLoadingExcel(status) {
    this.setState({ checkFetchState: status });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box">
            <div className="row justify-content-between" id="invoiceReport">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting/report/account-receivable">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{i18next.t("receivable_dashboard:Invoice Issuance Report")}</span>}
                  </Translation>
                </h3>
              </div>
              <div className={`col`}>
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "report_receivable_print",
                }) &&
                  <div className={`${this.state.checkFetchState ? "dropdown" : ''} printReport`}>
                    {!this.state.checkFetchState ?
                      <React.Fragment>
                        <button type="button" className="btnPrint">
                          <span className="spinner-border spinner-border-sm align-middle mr-2" />
                          <Translation>{t => t("meter:Preparing information")}</Translation>
                        </button>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                          <Translation>{t => t("PageList:Print")}</Translation>
                        </button>
                      </React.Fragment>
                    }
                    <div className={`dropdown-menu`}>
                      <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state)}>PDF</a>
                      <ExportInvoice state={this.state} checkLoadingExcel={this.checkLoadingExcel} />
                    </div>
                  </div>
                }
              </div>
              {/* <div className={`col`}>
                      <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state)}>PDF</a>
                      <ExportInvoice state={this.state}/>
                </div> */}
              {/* <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "report_receivable_print",
                }) && <ExportInvoice state={this.state} />}
              </div> */}
            </div>

            <Translation>
              {t =>
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          value={this.state.search_input}
                          name="search"
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="input-group float-right w-auto ml-4">
                        <select
                          name="temp_typeSort"
                          onChange={this.handleSortAmountChange}
                          className="form-control dropdown-custom-arrow"
                        >
                          <option value="asc">{i18next.t("invoiceReport:Amount Ascending")}</option>
                          <option value="desc">{i18next.t("invoiceReport:Amount Descending")}</option>
                        </select>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          minDate={this.state.temp_start_date}
                          // maxDate={this.state.temp_start_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          // minDate={this.state.min_date}
                          // maxDate={this.state.temp_end_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          {/* <div className="col-md-4 border-right">
                                                 <div className="form-group">
                                                     <label>ประเภทลูกค้า</label>
                                                     <select name="customer_type" onChange={this.handleChange}
                                                             className="form-control dropdown-custom-arrow">
                                                         <option value="">ทั้งหมด</option>
                                                         <option value="residential">เฉพาะเจ้าของร่วม/สมาชิก</option>
                                                         <option value="supplier">เฉพาะลูกหนี้</option>
                                                     </select>
                                                 </div>
                                             </div> */}
                          <ContactReportList
                            handleChange={this.handleChange}
                            customer_type={this.state.customer_type}
                            contact={this.state.temp_contact}
                          />

                          <ProductServiceList
                            handleChange={this.handleChange}
                            product_and_service={
                              this.state.temp_product_and_service
                            }
                          />

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("invoiceReport:Status")}</label>
                              <select
                                name="temp_status"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("invoiceReport:All")}</option>
                                <option value="active">{i18next.t("invoiceReport:Awaiting payment")}</option>
                                <option value="overdue">{i18next.t("invoiceReport:Overdue")}</option>
                                <option value="paid">{i18next.t("invoiceReport:Paid")}</option>
                                <option value="partial_payment">
                                  {i18next.t("invoiceReport:Partially paid")}
                                </option>
                                <option value="void">{i18next.t("invoiceReport:Canceled")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.loading ?
                    <Loading />
                    :
                    this.state.dataResult &&
                    <React.Fragment>
                      <div className="row mt-3">
                        <div className="col-12">
                          <div className="card fade-up">
                            <div className="table-responsive">
                              <table className="table table-hover">
                                <thead className="thead-light">
                                  <tr>
                                    <th className="text-center">{i18next.t("invoiceReport:Sequence")}</th>
                                    <th
                                      className="text-center"
                                      onClick={() =>
                                        this.advanceSortToggle("doc_number")
                                      }
                                    >
                                      {i18next.t("invoiceReport:Invoice Number")}
                                      {this.state.sortStateDocNumber ? (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/sort-up.png"
                                          }
                                          alt="sort-up-icon"
                                          className="ml-1"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/sort-down.png"
                                          }
                                          alt="sort-down-icon"
                                          className="ml-1"
                                        />
                                      )}
                                    </th>

                                    <th
                                      className="text-center"
                                      onClick={() =>
                                        this.advanceSortToggle("issued_date")
                                      }
                                    >
                                      {i18next.t("invoiceReport:Issued Date")}
                                      {this.state.sortStateIssuedDate ? (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/sort-up.png"
                                          }
                                          alt="sort-up-icon"
                                          className="ml-1"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/sort-down.png"
                                          }
                                          alt="sort-down-icon"
                                          className="ml-1"
                                        />
                                      )}
                                    </th>

                                    <th
                                      className="text-center"
                                      onClick={() =>

                                        this.advanceSortToggle("due_date")
                                      }
                                    >
                                      {i18next.t("invoiceReport:Due Date")}
                                      {this.state.sortStateDueDate ? (

                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/sort-up.png"
                                          }
                                          alt="sort-up-icon"
                                          className="ml-1"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/sort-down.png"
                                          }
                                          alt="sort-down-icon"
                                          className="ml-1"
                                        />
                                      )}
                                    </th>

                                    <th

                                      onClick={() =>
                                        this.advanceSortToggle(
                                          "contact__residential__ref_number"
                                        )
                                      }
                                    >
                                      {i18next.t("invoiceReport:Room/House Number")}
                                      {this.state.sortStateRoom ? (

                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/sort-up.png"
                                          }
                                          alt="sort-up-icon"
                                          className="ml-1"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/sort-down.png"
                                          }
                                          alt="sort-down-icon"
                                          className="ml-1"
                                        />
                                      )}
                                    </th>

                                    <th>{i18next.t("invoiceReport:Name")}</th>

                                    <th>{i18next.t("invoiceReport:Code")}</th>
                                    <th>{i18next.t("invoiceReport:Details")}</th>

                                    <th className="text-center">{i18next.t("invoiceReport:Status")}</th>

                                    <th className="text-right">{i18next.t("invoiceReport:Amount")}</th>

                                    {this.state.status === 'partial_payment' &&
                                      <th className="text-right">{i18next.t("invoiceReport:Paid")}</th>
                                    }
                                    {this.state.status === 'partial_payment' &&
                                      <th className="text-right">{i18next.t("invoiceReport:Balance")}</th>
                                    }

                                    {this.state.status !== 'partial_payment' &&

                                      <th
                                        className="text-right"
                                        onClick={() =>
                                          this.advanceSortToggle("total")
                                        }
                                      >

                                        {i18next.t("invoiceReport:Total Amount")}
                                        {this.state.sortStateTotal ? (
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/images/icons/sort-up.png"
                                            }
                                            alt="sort-up-icon"
                                            className="ml-1"
                                          />
                                        ) : (
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/images/icons/sort-down.png"
                                            }
                                            alt="sort-down-icon"
                                            className="ml-1"
                                          />
                                        )}
                                      </th>}

                                    <th className="text-right">
                                    {i18next.t("invoiceReport:Receipt Number")}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <InvoiceReportTable
                                    props={this.state.dataResult}
                                    state={this.state}
                                  />
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={
                            this.state.dataResult?.invoiceViewer?.allInvoice.totalCount
                          }
                        />
                      </div>
                    </React.Fragment>
                  }
                </div>
              }
            </Translation>


          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default InvoiceReport;
