/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateBankAccountInput = {|
  id: string,
  bankName?: ?string,
  branch?: ?string,
  accountType?: ?string,
  receivePayment?: ?boolean,
  makePayment?: ?boolean,
  crossBilling?: ?boolean,
  prompay?: ?boolean,
  importReceive?: ?boolean,
  status?: ?boolean,
  hasCheque?: ?boolean,
  carryingBalance?: ?number,
  description?: ?string,
  closeRemark?: ?string,
  mobilePayment?: ?boolean,
  issuedDate?: ?any,
  dueDate?: ?any,
  taxRate?: ?any,
  notiStatus?: ?boolean,
  numberOfUnit?: ?number,
  unit?: ?string,
  prompayQr?: ?string,
  billerId?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateBankAccountMutationVariables = {|
  input: UpdateBankAccountInput
|};
export type UpdateBankAccountMutationResponse = {|
  +updateBankAccount: ?{|
    +newBankAccount: ?{|
      +id: string
    |}
  |}
|};
export type UpdateBankAccountMutation = {|
  variables: UpdateBankAccountMutationVariables,
  response: UpdateBankAccountMutationResponse,
|};
*/


/*
mutation UpdateBankAccountMutation(
  $input: UpdateBankAccountInput!
) {
  updateBankAccount(input: $input) {
    newBankAccount {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateBankAccountPayload",
    "kind": "LinkedField",
    "name": "updateBankAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountNode",
        "kind": "LinkedField",
        "name": "newBankAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBankAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBankAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3591483c97f528646b340dab2525e17f",
    "id": null,
    "metadata": {},
    "name": "UpdateBankAccountMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBankAccountMutation(\n  $input: UpdateBankAccountInput!\n) {\n  updateBankAccount(input: $input) {\n    newBankAccount {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a6ad1fc533442f6a707021576d40baa8';

module.exports = node;
