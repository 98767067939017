import i18n from "i18next";
import React from "react";
import "../../styles/commonArea.scss";
const Accessories = ({
  state: { equipment_status, equipment = [] },
  handleChange,
  addEquipment,
}) => {
  return (
    <div className="booking-form mb-3  mt-5">
      <div className="switch-languages-div pb-2 ">
        <h2>{i18n.t("newBookingSingha_Create_step1:Room accessories")}</h2>
      </div>
      <div className="mt-4">
      {i18n.t("newBookingSingha_Create_step1:Display the list of accessories")}
        <div className="form-check mt-2">
          <input
            className="form-check-input"
            type="radio"
            name="equipment_status"
            id="equipment_status1"
            value={"true"}
            onChange={handleChange}
            defaultChecked={equipment_status === "true"}
          />
          <label className="form-check-label" htmlFor="equipment_status1">
          {i18n.t("newBookingSingha_Create_step1:Not Show")}
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="equipment_status"
            id="equipment_status2"
            value={"false"}
            onChange={handleChange}
            defaultChecked={equipment_status === "false"}
          />
          <label className="form-check-label" htmlFor="equipment_status2">
          {i18n.t("newBookingSingha_Create_step1:Show")} 
          </label>
        </div>
        <div className="mt-3 d-flex">
          <h3>{i18n.t("newBookingSingha_Create_step1:Accessories list")}</h3>
        </div>
        {equipment.map((item, index) => {
          return (
            <div className="form-row mt-3 " key={index}>
              <div className="col-6">
                <label htmlFor={`equipment${index}`}>{i18n.t("newBookingSingha_Create_step1:Accessory name")}</label>
                <input
                  disabled={equipment_status === "true" && true}
                  type="text"
                  className="form-control"
                  placeholder={i18n.t("newBookingSingha_Create_step1:Accessory name")}
                  id={`equipment`}
                  name={`equipment`}
                  value={item.equipment}
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
              <div className="col-2">
                <label htmlFor={`unit_items`}>{i18n.t("newBookingSingha_Create_step1:Quantity")}</label>
                <input
                  disabled={equipment_status === "true" && true}
                  type="number"
                  className="form-control"
                  placeholder={i18n.t("newBookingSingha_Create_step1:Quantity")}
                  id={`unit_items`}
                  name={`unit_items`}
                  value={item.unit_items}
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
              <div
                onClick={() => addEquipment("remove", index)}
                className="col align-self-end pb-3 ml-4"
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/icon_delete.png"}
                  alt="delete"
                  className="delete"
                />
              </div>
            </div>
          );
        })}
        <div className="mt-3">
          <button
            type="button"
            className="btn btn-link button-add"
            style={
              equipment_status === "true"
                ? { color: "#B3B3B3" }
                : { color: "#0382FA" }
            }
            disabled={equipment_status === "true"}
            onClick={() => addEquipment("add")}
          >
            + {i18n.t("newBookingSingha_Create_step1:Add list")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Accessories;
