/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssetCalSchedule = "MONTHLY" | "YEARLY" | "%future added value";
export type AssetSaleStatus = "ACTIVE" | "DETERIORATE" | "DISAPPEAR" | "SOLD" | "%future added value";
export type AssetType = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type assetDeteriorateQueryVariables = {|
  id: string,
  date?: ?any,
|};
export type assetDeteriorateQueryResponse = {|
  +asset: ?{|
    +id: string,
    +type: AssetType,
    +docNumber: string,
    +nounClassifier: string,
    +chartOfAccountType: {|
      +chartOfAccountCode: string,
      +name: string,
      +id: string,
    |},
    +chartOfAccountDepreciation: ?{|
      +id: string,
      +chartOfAccountCode: string,
      +name: string,
    |},
    +chartOfAccountDepreciationCumulative: ?{|
      +id: string,
      +chartOfAccountCode: string,
      +name: string,
    |},
    +name: string,
    +description: ?string,
    +storage: ?string,
    +remark: string,
    +internalAssetCode: ?string,
    +seller: ?{|
      +id: string
    |},
    +sellerOther: ?string,
    +calDepreciation: boolean,
    +depreciationRatePercent: ?number,
    +depreciationRate: ?number,
    +lifeTimeYear: ?number,
    +purchaseDate: ?any,
    +saleDate: ?any,
    +calDepreciationDate: ?any,
    +costPrice: ?number,
    +carcassPrice: ?number,
    +calSchedule: AssetCalSchedule,
    +historicalValue: number,
    +historicalAmount: number,
    +saleStatus: AssetSaleStatus,
    +assetDepreciate: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +depreciatePrice: number,
        |}
      |}>
    |},
    +deteriorateAsset: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +saleDate: ?any,
          +approver: ?string,
          +deteriorateDocument: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +fileName: string,
                +fileUpload: string,
                +id: string,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
        |}
      |}>
    |}
  |},
|};
export type assetDeteriorateQuery = {|
  variables: assetDeteriorateQueryVariables,
  response: assetDeteriorateQueryResponse,
|};
*/


/*
query assetDeteriorateQuery(
  $id: ID!
  $date: Date
) {
  asset(id: $id) {
    id
    type
    docNumber
    nounClassifier
    chartOfAccountType {
      chartOfAccountCode
      name
      id
    }
    chartOfAccountDepreciation {
      id
      chartOfAccountCode
      name
    }
    chartOfAccountDepreciationCumulative {
      id
      chartOfAccountCode
      name
    }
    name
    description
    storage
    remark
    internalAssetCode
    seller {
      id
    }
    sellerOther
    calDepreciation
    depreciationRatePercent
    depreciationRate
    lifeTimeYear
    purchaseDate
    saleDate
    calDepreciationDate
    costPrice
    carcassPrice
    calSchedule
    historicalValue
    historicalAmount
    saleStatus
    assetDepreciate(date_Lte: $date) {
      edges {
        node {
          id
          depreciatePrice
        }
      }
    }
    deteriorateAsset {
      edges {
        node {
          saleDate
          approver
          deteriorateDocument {
            edges {
              node {
                fileName
                fileUpload
                id
              }
            }
          }
          id
        }
      }
    }
  }
  viewer {
    allChartOfAccount(codeList: ["4300"]) {
      edges {
        node {
          id
          chartOfAccountCode
          name
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nounClassifier",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccountType",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v10 = [
  (v3/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccountDepreciation",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccountDepreciationCumulative",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storage",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalAssetCode",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "seller",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sellerOther",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calDepreciation",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depreciationRatePercent",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depreciationRate",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lifeTimeYear",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purchaseDate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saleDate",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calDepreciationDate",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costPrice",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carcassPrice",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calSchedule",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "historicalValue",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "historicalAmount",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saleStatus",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "date_Lte",
      "variableName": "date"
    }
  ],
  "concreteType": "AssetDepreciateNodeConnection",
  "kind": "LinkedField",
  "name": "assetDepreciate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetDepreciateNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetDepreciateNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "depreciatePrice",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approver",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "concreteType": "DeteriorateDocumentNodeConnection",
  "kind": "LinkedField",
  "name": "deteriorateDocument",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeteriorateDocumentNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeteriorateDocumentNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileUpload",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "codeList",
      "value": [
        "4300"
      ]
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allChartOfAccount(codeList:[\"4300\"])"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "assetDeteriorateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AssetNode",
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v9/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v8/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeteriorateAssetNodeConnection",
            "kind": "LinkedField",
            "name": "deteriorateAsset",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeteriorateAssetNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeteriorateAssetNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v24/*: any*/),
                      (v33/*: any*/),
                      (v34/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v35/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "assetDeteriorateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AssetNode",
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v9/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v8/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeteriorateAssetNodeConnection",
            "kind": "LinkedField",
            "name": "deteriorateAsset",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeteriorateAssetNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeteriorateAssetNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v24/*: any*/),
                      (v33/*: any*/),
                      (v34/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v35/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7770bc37029990861ee25b469c0c9c2d",
    "id": null,
    "metadata": {},
    "name": "assetDeteriorateQuery",
    "operationKind": "query",
    "text": "query assetDeteriorateQuery(\n  $id: ID!\n  $date: Date\n) {\n  asset(id: $id) {\n    id\n    type\n    docNumber\n    nounClassifier\n    chartOfAccountType {\n      chartOfAccountCode\n      name\n      id\n    }\n    chartOfAccountDepreciation {\n      id\n      chartOfAccountCode\n      name\n    }\n    chartOfAccountDepreciationCumulative {\n      id\n      chartOfAccountCode\n      name\n    }\n    name\n    description\n    storage\n    remark\n    internalAssetCode\n    seller {\n      id\n    }\n    sellerOther\n    calDepreciation\n    depreciationRatePercent\n    depreciationRate\n    lifeTimeYear\n    purchaseDate\n    saleDate\n    calDepreciationDate\n    costPrice\n    carcassPrice\n    calSchedule\n    historicalValue\n    historicalAmount\n    saleStatus\n    assetDepreciate(date_Lte: $date) {\n      edges {\n        node {\n          id\n          depreciatePrice\n        }\n      }\n    }\n    deteriorateAsset {\n      edges {\n        node {\n          saleDate\n          approver\n          deteriorateDocument {\n            edges {\n              node {\n                fileName\n                fileUpload\n                id\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n  viewer {\n    allChartOfAccount(codeList: [\"4300\"]) {\n      edges {\n        node {\n          id\n          chartOfAccountCode\n          name\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '181531e4faadeb720e895377d84a0f65';

module.exports = node;
