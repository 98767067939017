import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import StaffList from "./staffList";
import CreateUpdateStaff from "./createUpdateStaff";
import NoMatch from "../../components/noMatch";

class Staff extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/form-staff/:id?`} component={CreateUpdateStaff}/>
                <PrivateRoute exact path={`${this.props.match.url}/:status`} component={StaffList}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Staff;