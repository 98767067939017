import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import Navigation from "./navigation";

import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { graphql } from "babel-plugin-relay/macro";

import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import OtherReceiverListTable from "./otherReceiverListTable";
import ComponentPagination from "../../../libs/componentPagination";
import _ from "lodash";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
import { Translation } from "react-i18next";

const query = graphql`
  query otherReceiverListQuery(
    $status: String
    $search: String
    $first: Int
    $last: Int
    $issuedDate_Gte: Date
    $issuedDate_Lte: Date
  ) {
    allOtherReceive(
      status: $status
      search: $search
      issuedDate_Gte: $issuedDate_Gte
      issuedDate_Lte: $issuedDate_Lte
      first: $first
      last: $last
    ) {
      totalCount
      edges {
        node {
          id
          docNumber
          total
          issuedDate
          creator
          status
          remark
          voidRemark
          refNumber
          updated
          payGroup
          firstName lastName
          contact {
            id
            refNumber
            name
            firstName
            lastName
            typeOfContact
            registeredName
            registeredAddress
            registeredCountry
            registeredProvince
            registeredCity
            registeredDistrict
            registeredPostalCode
          }
          unknownContact
          otherReceiveTransaction {
            edges {
              node {
                id
                productAndService {
                  id
                }
                chartOfAccount {
                  id
                }
                description
                total
              }
            }
          }
        }
      }
    }
  }
`;

class OtherReceiverList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      end_date: null,
      temp_start_date: null,
      temp_end_date: null,
      search: "",
      search_input: "",
      advance_search: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
  }

  componentDidMount() {
    this.setState({
      status: this.props.match.params.status,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSearchInputChange(value) {
   this.setState({search_input: value});
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="receive-list">
            <div className="row justify-content-between">
              <div className="col">
                <Translation>
                  {t=>
                  <h3 className="mb-4">
                    <Link to="/accounting">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/wrapperContent/back.png"
                        }
                        alt="back"
                        className="back"
                      />
                    </Link>
                    {t("other_received:Other received")+ " "+"(OR)"}
                  </h3>}
                </Translation>
              </div>
              <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "accounting_other_receiver_create",
                }) && (
                  <Link to="/accounting/income/other_receiver/form/select_customer">
                    <Translation>
                      {t=>
                      <button
                        type="button"
                        className="btn btn-primary add float-right"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                          alt="plus"
                        />
                        {t("other_received:Other received")}
                      </button>}
                    </Translation>
                  </Link>
                )}
              </div>
            </div>
            <div className="content-inner">
              <Navigation />
              <OtherReceiverListTable
                    query={query}
                    search={this.state.search}
                    start_date={this.state.start_date}
                    end_date={this.state.end_date}
                    temp_start_date={this.state.temp_start_date}
                    temp_end_date={this.state.temp_end_date}
                    status={this.props.match.params.status}
                    handleKeyDown={this.handleKeyDown}
                    search_input={this.state.search_input}
                    handleSearch={this.handleSearch}
                    handleSearchInputChange={this.handleSearchInputChange}
                    handleChange={this.handleChange}
                  />

              {/* <div className="row mb-2 mt-1">
                <div className="col-12 text-right">
                  <Translation>
                    {t=>
                    <div className="input-group float-right w-auto ml-2">
                      <input
                        type="text"
                        className="form-control input-size"
                        placeholder={t("other_received:Search")}
                        value={this.state.search_input}
                        name="search"
                        onChange={(e) =>
                          this.setState({ search_input: e.target.value })
                        }
                        onKeyDown={this.handleKeyDown}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary form-control search-button"
                        name="search"
                        value={this.state.search_input}
                        onClick={(e) => this.handleSearch(e)}
                      >
                        {t("other_received:Search")}
                      </button>
                    </div>}
                  </Translation>
       
                  <InlineAdvanceFilter
                    start_date={this.state.temp_start_date}
                    end_date={this.state.temp_end_date}
                    handleChange={this.handleChange}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default OtherReceiverList;
