import React, { Component } from 'react'
import { Dropdown  } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import BudgetDraftYearCompareAverageYearExport from './budgetYearCompareAverageYear/budgetDraftYearCompareAverageYearExport';
import BudgetLastYearCompareWithYearExport from './budgetYearCompareWithYear/budgetLastYearCompareWithYearExport';
import BudgetLastYearCompareWithMonthExport from './budgetLastYearCompareWithMonth/budgetLastYearCompareWithMonthExport';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

export default class downloadDocument extends Component {
    render() {
        return (
            <div style={{marginRight: "60px"}}>
                <Dropdown>
                    <DropdownToggle id="dropdown-item-button" bsPrefix = 'dropdown-export dropdown-toggle'>
                    {i18next.t("Allaction:Print")}
                    </DropdownToggle>
                    {this.props.checkTextSelect === "Budget, Actual Previous Year, Comparison with Budget for the Current Year" &&
                        <DropdownMenu>
                            <Dropdown.Item>
                                <Link 
                                    to={{pathname : `/document/budgetDraftLastYearCompareWithYear/${this.props.yearId}` ,
                                        query : {id : this.props.yearId , query : this.props.state }}} 
                                    className="text-black"
                                >
                                    <p className="text-black">PDF</p>
                                </Link>
                            </Dropdown.Item>
                            <BudgetLastYearCompareWithYearExport
                                checkTextSelect={this.props.checkTextSelect}
                                state={this.props.state}
                                />
                        </DropdownMenu>
                    }
                    {this.props.checkTextSelect === "Budget, Actual Previous Year, Monthly Comparison" &&
                        <DropdownMenu>
                            <Dropdown.Item>
                                <Link 
                                to={{pathname : `/document/budgetDraftLastYearCompareWithMonth/${this.props.yearId}` ,
                                    query : {id : this.props.yearId , query : this.props.state }}} 
                                className="text-black"
                                >
                                    <p className="text-black">PDF</p>
                                </Link>
                            </Dropdown.Item>
                            <BudgetLastYearCompareWithMonthExport
                                checkTextSelect={this.props.checkTextSelect}
                                state={this.props.state}
                                />
                        </DropdownMenu>
                    }
                    {this.props.checkTextSelect === "Actual Previous Year Average Monthly Budget, Average Monthly Comparison with Current Year" &&
                        <DropdownMenu>
                            <Dropdown.Item>
                                <Link 
                                    to={{pathname : `/document/budgetDraftLastYearCompareAverageYear/${this.props.yearId}` ,
                                        query : {id : this.props.yearId , query : this.props.state }}} 
                                    className="text-black"
                                >
                                    <p className="text-black">PDF</p>
                                </Link>
                            </Dropdown.Item>
                            <BudgetDraftYearCompareAverageYearExport
                                checkTextSelect={this.props.checkTextSelect}
                                state={this.props.state}
                                />
                        </DropdownMenu>
                    }
                </Dropdown>
            </div>
        )
    }
}
