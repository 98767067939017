import React from "react";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { commitMutation, fetchQuery } from "react-relay";
import Swal from "sweetalert2";
import "./style/settingJuristicList.scss";
import ComponentPagination from "../../libs/componentPagination";
import i18n from "i18next";

// query
const query = graphql`
query SettingJuristicListJobQuery ($positionId: String) {
    allPositionPermission(positionId: $positionId){
        edges{
            node{
                id
                positionName
                permissionPerPosition{
                    edges{
                        node{
                            id
                            permission{
                                id
                                codename
                                name
                            }
                        }
                    }
                }
            }
        }
    }              
}
`
const mutation =  graphql`
mutation SettingJuristicListJobMutation ($input: DeletePermissionPerPositionInput!) {
    deletePermissionPerPosition (input: $input) {
        ok
        message
    }
}
`

class SettingJuristicListJob extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
          data : [],
          redirectToList:false
        }

        this.deletePosition = this.deletePosition.bind()
      }

      componentDidMount() {

        fetchQuery(environment, query,{positionId:''}).then(data => {
                if(data){
                  this.setState({
                    data : data.allPositionPermission.edges
                  })
                }
          
        });
    }

    deletePosition(id){
      Swal.fire({
        title: i18n.t('set_permission:Are you sure that delete data?'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: i18n.t('set_permission:Yes'),
        cancelButtonText: i18n.t('set_permission:Cancel'),
    }).then((result) => {
        if (result.value) {
            let variables = {
                input: {
                  position: id,
                }
              };
        
              commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        if (response.deletePermissionPerPosition.ok) {
                            Swal.fire(i18n.t("set_permission:Save successfully!"), '', 'success').then(() => {
                                //  this.setState({redirectToList: true});
                                window.location.reload()
                            });
                        } else {
                            Swal.fire(i18n.t("set_permission:Save unsuccessfully!"), i18n.t("set_permission:Please check again"), 'warning');
                        }
                    },
                    onError: (err) => {
                        Swal.fire('Error!', i18n.t("set_permission:Please try again"), 'warning')
                    },
                },
            );
        }
    })
    }

    render() {

        return (
          <Wrapper>
            <Header />
            <Sidebar />
            <WrapperContent>
            <Translation>
                {t=>
                <div className="container-fluid box">
                    <div className="row justify-content-start test2">
                        <div className="col-3">
                            <h3 className="mb-4">
                                <Link to="/setting/juristic-setting">
                                <img
                                    src={
                                    process.env.PUBLIC_URL +
                                    "/images/wrapperContent/back.png"
                                    }
                                    alt="back"
                                    className="back"
                                    />
                                </Link>
                                <Translation>
                                {(t) => <span>{t('premission:Setting permissions job')}</span>}
                                </Translation>
                            </h3>

                        </div>
                        <div className="col">
                      <div className="float-right">
                          <Link
                             to={
                                "/setting/juristic-setting/permissions-juristic/management/"
                            }
                          >
                          <button className="btn btn-primary add">
                              +&nbsp;{t("premission:Add job title permissions")}
                            </button>
                          </Link>
                      </div>
                    </div>
                    </div>
                    <div>
                    </div>
                    <div className="content-inner">
                        <div className="row mt-3">
                            <div className="col-2">
                            </div>
                            <div className="col-10">
                                
                                <React.Fragment>
                                    <div className="card fade-up mr-3">
                                        <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead className="thead-light">
                                            <tr>
                                                <th colSpan={4}>{t("premission:Position")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                
                                            {this.state.data.map((position) => (
                                            <tr className="show" height='70' key={position.node.id}>
                                                    <td>{position.node.positionName}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td width="180">
                                                        <React.Fragment>
                                                            <button
                                                                className="btn show-icon"
                                                                id="dropdownMenuButton"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <img
                                                                    src={
                                                                    process.env.PUBLIC_URL +
                                                                    "/images/icons/choice.png"
                                                                    }
                                                                    alt="choice"
                                                                />
                                                            </button>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <Link
                                                                        to={
                                                                            "/setting/juristic-setting/permissions-juristic/management/"+position.node.id
                                                                        }
                                                                        >
                                                                        <span className="dropdown-item cursor">
                                                                            {t("premission:Access rights setting")}
                                                                        </span>
                                                                </Link>
                                                                <span
                                                                        className="dropdown-item cursor"
                                                                        onClick={() => this.deletePosition(position.node.id)}
                                                                        >
                                                                            {t("premission:Remove")}
                                                                </span>
                                                            </div>
                                                        </React.Fragment>
                                                    </td>
                                            </tr>
                                            ))}
                                            </tbody>
                                        </table>

                                        </div>
                                    </div>
                                </React.Fragment>
                            </div>  
                        </div>            
                    </div>
                </div>
                }
            </Translation>
            
            </WrapperContent>
          </Wrapper>
        );
      }
}

export default SettingJuristicListJob;   


