import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import Wrapper from "../../../components/wrapper";
import ProductCreateAndUpdate from "./productCreateAndUpdate";
import ServiceCreateAndUpdate from "./serviceCreateAndUpdate";
import ExpenseCreateAndUpdate from "./expenseCreateAndUpdate";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Loading from '../../../libs/loading';

const query = graphql`
    query createAndUpdateQuery($id: ID!) {
        product(id: $id){
            id
            name
            productCode
            cost
            inputTax
            type
            price
            outputTax
            taxRate
            taxRateOther
            typeOfIncome
            typeOfIncomeOther
            typeOfTax
            typeOfTaxOther
            typeOfWithholdingTax
            noNumber
            collect
            reorderPoint
            autoOrder
            numberKey{
                edges {
                    node {
                        id
                        noNumber
                    }
                }
            }
            productTransaction {
                edges {
                    node {
                        id
                        list
                        amount
                        price
                        date
                        numberKey{
                            edges {
                                node {
                                    id
                                    noNumber
                                }
                            }
                        }
                    }
                }
            }
            chartOfAccount {
                id
            }
            customerChartOfAccount{
                id
            }
            creditorChartOfAccount{
                id
            }
            creditorChartOfAccountBuy{
                id
            }
            description
            serviceFine {
                edges {
                    node {
                        id
                    }
                }
            }
            servicePricing {
                edges {
                    node {
                        id
                        pricingType
                        rounding
                        periodAdjustment
                        receiveDeposit {
                            id
                        }
                        deferredIncome {
                            id
                        }
                    }
                }
            }
            serviceFine {
                edges {
                    node {
                        id
                        fineType
                        limitDay
                        fineLimit
                        serviceFineSeries {
                            edges {
                                node {
                                    id
                                    month
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }

        viewer {
            group2: allChartOfAccount (codeList: ["2"]){
                totalCount
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
            group4: allChartOfAccount (codeList: ["1155","4","3000-01"]){
            # group4: allChartOfAccount (codeList: ["4"]){
                totalCount
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
            # group5: allChartOfAccount (codeList: ["5","1151","2121","2122","2123","2124"]){
            group5: allChartOfAccount (chartOfAccountCode_Istartswith: "5") {
                totalCount
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
            groupReceiveDeposit: allChartOfAccount (chartOfAccountCode_Istartswith: "2210"){
                totalCount
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
            # groupDeferredIncome: allChartOfAccount (chartOfAccountCode_Istartswith: "2220"){
            groupDeferredIncome: allChartOfAccount (codeList: ["2210","2220"]){
                totalCount
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
            groupCustomerChartOfAccount: allChartOfAccount (chartOfAccountCode_Istartswith: "113"){
                totalCount
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
            groupCreditorChartOfAccount: allChartOfAccount (codeList: ["2110","2111-01","2122","2123"]){
                totalCount
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
            # RQT300
            groupExpenseCreateUpdate: allChartOfAccount (codeList: ["1151","5"]){
                totalCount
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
            group4service: allChartOfAccount (codeList: ["1155","4","3000-01","5830"]){
            # group4: allChartOfAccount (codeList: ["4"]){
                totalCount
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
        }
        selfProject{
            haveVat
        }

        productViewer{
            productAccountVerify(id:$id)
        }
    }`;

class CreateAndUpdate extends Component {
    render() {

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box">
                        <QueryRenderer
                            environment={environment}
                            query={query}
                            cacheConfig={{use_cache: false}}
                            variables={{id: this.props.match.params.id || false}}
                            render={({error, props}) => {
                                if (props) {            
                                    if (this.props.match.params.page === 'product') {
                                        return <ProductCreateAndUpdate product={props.product} chart_of_account={props.viewer} project_not_edit_account={props.productViewer.productAccountVerify}/>
                                    } else if (this.props.match.params.page === 'service') {
                                        return <ServiceCreateAndUpdate product={props.product} chart_of_account={props.viewer} project_have_vat={props.selfProject.haveVat} project_not_edit_account={props.productViewer.productAccountVerify}/>
                                    } else {
                                        return <ExpenseCreateAndUpdate product={props.product} chart_of_account={props.viewer} project_not_edit_account={props.productViewer.productAccountVerify}/>
                                    }
                                } else {
                                    return <Loading/>
                                }
                            }}
                        />

                    </div>
                </WrapperContent>
            </Wrapper>


        )
    }
}

export default CreateAndUpdate;
