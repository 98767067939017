import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {fetchQuery } from "react-relay";
import axios from "axios";
import getApiRoot from "../../libs/getAPIRoot";

const query = graphql`
    query libcurrentRecordQuery($startDate: DateTime,$endDate: DateTime, $betweenStartId: String, $betweenEndId: String) {
        currentRecord: allAccountRecord(startDate: $startDate, endDate: $endDate, typeOfRecord: "current", betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {
            edges {
                node {
                    id name debit credit added
                    group {
                        id name issuedDate refNumber totalDebit totalCredit refTransaction added category
                    }
                    chartOfAccountCode {
                        id name chartOfAccountCode
                    }
                }
            }
        }
    }
`;

async function libcurrentRecordApi(body){
    let token_id = localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.post(`${getApiRoot()}account/all-account-record`,body ,{headers: header});
}

async function libcurrentRecordGraph(start_date,end_date,chart_of_account_code) {
    // แบบ API
    // let bodyFormData = new FormData();
    // bodyFormData.append('startDate', format(this.props.state.start_date, "YYYY-MM-DD"))
    // bodyFormData.append('endDate', format(this.props.state.end_date, "YYYY-MM-DD"))
    // bodyFormData.append('betweenStartId', element.node.id);
    // bodyFormData.append('betweenEndId', element.node.id);
    // bodyFormData.append('typeOfRecord', 'current');
    // bodyFormData.append('orderLedger', true);
    // const res = await API.libcurrentRecordApi(bodyFormData);
    // return res.data.data;
    return fetchQuery(environment, query, {
        startDate: start_date,
        endDate: end_date,
        betweenStartId: chart_of_account_code,
        betweenEndId: chart_of_account_code,
     });
 }

export default {
    libcurrentRecordApi,
    libcurrentRecordGraph,
}