/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type PaymentGatewayPaymentType = "CROSSBILL" | "DEEPLINK" | "ETC" | "KSHER" | "SLIP_VERIFICATION" | "TWOCTWOP" | "%future added value";
export type selectPaymentTypeQueryVariables = {||};
export type selectPaymentTypeQueryResponse = {|
  +paymentGatewaySettingList: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +paymentType: PaymentGatewayPaymentType,
        +fee: number,
        +bankAccount: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
        |},
      |}
    |}>
  |}
|};
export type selectPaymentTypeQuery = {|
  variables: selectPaymentTypeQueryVariables,
  response: selectPaymentTypeQueryResponse,
|};
*/


/*
query selectPaymentTypeQuery {
  paymentGatewaySettingList(paymentType_In: "twoctwop,deeplink") {
    edges {
      node {
        id
        paymentType
        fee
        bankAccount {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "paymentType_In",
        "value": "twoctwop,deeplink"
      }
    ],
    "concreteType": "PaymentGatewayNodeConnection",
    "kind": "LinkedField",
    "name": "paymentGatewaySettingList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PaymentGatewayNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentGatewayNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fee",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountNode",
                "kind": "LinkedField",
                "name": "bankAccount",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "docNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "receivePayment",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "makePayment",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bankName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "paymentGatewaySettingList(paymentType_In:\"twoctwop,deeplink\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectPaymentTypeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "selectPaymentTypeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "096db2b01b542cd3a1cca1b6015bf818",
    "id": null,
    "metadata": {},
    "name": "selectPaymentTypeQuery",
    "operationKind": "query",
    "text": "query selectPaymentTypeQuery {\n  paymentGatewaySettingList(paymentType_In: \"twoctwop,deeplink\") {\n    edges {\n      node {\n        id\n        paymentType\n        fee\n        bankAccount {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7d84007cc11d2bb67f0a8dae79252b28';

module.exports = node;
