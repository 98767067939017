import {
    commitMutation,

} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";


const mutation = graphql`
 mutation UpdateBankAccountMutation($input: UpdateBankAccountInput!){
      updateBankAccount(input: $input){
        newBankAccount{
        id
       }
      }
    }
`;


export default function UpdateBankAccountMutation(input, uploadables, callback, error_callback) {
    const variables = {
        input: input
    };


    commitMutation(
        environment,
        {
            mutation,
            variables,
            uploadables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                // console.log(err);
                error_callback(err);
            },
        },
    )
}
