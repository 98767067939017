import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";

import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";

import { format, getDaysInMonth, setDate } from "date-fns";
import environment from "../../../env/environment";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../libs/numberWithComma";
import Pagination from "../../../libs/newPagination";
import { QueryRenderer } from "react-relay";
import ComponentPagination from "../../../libs/componentPagination";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import getNameResidential from "../../../libs/getNameResidential";
import DatePicker from "react-datepicker/es";
import Loading from "../../../libs/loading";

const allWithheldTax = graphql`
  query withheldTaxListQuery(
    $search: String
    $start_date: DateTime
    $end_date: DateTime
    $first: Int
    $last: Int
  ) {
    withheldTaxViewer {
      allWithheldTax(
        search: $search
        startDate: $start_date
        endDate: $end_date
        first: $first
        last: $last
      ) {
        totalCount
        edges {
          node {
            id
            docNumber
            contact {
              id
              name
              refNumber
              firstName
              lastName
              invoice {
                edges {
                  node {
                    docNumber
                  }
                }
              }
            }
            receive {
              total
            }
            otherReceive {
              total
              unknownContact
            }
            issuedDate
            added
            total
            remark
            status
          }
        }
      }
    }
  }
`;

class WithheldTaxList extends ComponentPagination {
  constructor(props) {
    super(props);
    let current_month = new Date();

    this.state = {
      start_date: setDate(current_month, 1),
      end_date: setDate(current_month, getDaysInMonth(current_month)),
      temp_start_date: setDate(current_month, 1),
      temp_end_date: setDate(current_month, getDaysInMonth(current_month)),
      search: "",
      search_input: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this)
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.calTotal = this.calTotal.bind(this);
    this.setEmpty = this.setEmpty.bind(this);
  }

  componentDidMount() {
    this.setState({
      status: this.props.match.params.status,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  handleChangeStart(date) {
    this.setState({
      temp_start_date: date,
      temp_end_date: setDate(date, getDaysInMonth(date)),
    });
  }

  setEmpty() {
    this.record = {
      total_receive: 0,
      total_withheld: 0,
    };
  }

  calTotal(props) {
    this.setEmpty();
    props.withheldTaxViewer.allWithheldTax.edges.forEach((summary) => {
      if (summary) {
        if (summary.node.receive) {
          this.record["total_receive"] += summary.node.total;
          this.record["total_withheld"] += summary.node.receive.total;
        } else {
          this.record["total_receive"] += summary.node.total;
          this.record["total_withheld"] += summary.node.otherReceive.total;
        }
      }
    });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="receive-list">
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("finance:withheld_tax")}</span>}
                  </Translation>
                </h3>
              </div>
            </div>
            <div className="row mb-2 mt-1 justify-content-end">
              <div className="col-9">
                <div className="receive-search-date">
                  <div className="form-inline float-right">
                    <div className="form-group mb-2">
                      <label htmlFor="start_date" className="startDate mr-3">
                        เรียกดู
                      </label>
                      <DatePicker
                        id="start_date"
                        className="form-control"
                        selected={this.state.temp_start_date}
                        onChange={this.handleChangeStart}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="input-group ">
                  <input
                    type="text"
                    className="form-control input-size"
                    placeholder="ค้นหา"
                    value={this.state.search_input}
                    name="search"
                    onChange={(e) =>
                      this.setState({ search_input: e.target.value })
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary form-control search-button"
                    name="search"
                    value={this.state.search_input}
                    onClick={(e) => this.handleSearch(e)}
                  >
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
            <div className="content-inner">
              <div className="row">
                <div className="col">
                  <QueryRenderer
                    environment={environment}
                    query={allWithheldTax}
                    variables={{
                      search: this.state.search,
                      start_date: this.state.start_date,
                      end_date: this.state.end_date,
                      first: this.state.first,
                      last: this.state.last,
                    }}
                    render={({ error, props }) => {
                      if (error) {
                        return <div>{error.message}</div>;
                      } else if (props) {
                        this.calTotal(props);
                        return (
                          <div>
                            <div className="table-responsive fade-up card">
                              <table className="table table-hover">
                                <thead className="thead-light">
                                  <tr>
                                    <th className="text-center">เลขที่</th>
                                    <th className="text-center">
                                      เลขที่เอกสารอ้างอิง
                                    </th>
                                    <th className="text-center">วันที่</th>
                                    <th className="text-center">รหัส</th>
                                    <th>ชื่อ</th>
                                    <th className="text-right">
                                      จำนวนเงินที่ได้รับ
                                    </th>
                                    <th className="text-right">
                                      ภาษีถูกหัก ณ ที่จ่าย
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {props.withheldTaxViewer.allWithheldTax.edges.map(
                                    (withheldTax) => {
                                      let total = 0.0;
                                      if (withheldTax.node.receive) {
                                        total = withheldTax.node.receive.total;
                                      } else {
                                        total =
                                          withheldTax.node.otherReceive.total;
                                      }
                                      return (
                                        <tr key={withheldTax.node.id}>
                                          <td className="text-center">
                                            {withheldTax.node.docNumber}
                                          </td>
                                          <td className="text-center">
                                            {withheldTax.node.remark}
                                          </td>
                                          <td className="text-center">
                                            {format(
                                              withheldTax.node.issuedDate,
                                              "DD/MM/YYYY",
                                              { locale: thLocale }
                                            )}
                                          </td>
                                          <td className="text-center">
                                            {withheldTax.node.contact
                                              ? withheldTax.node.contact
                                                  .refNumber
                                              : "-"}
                                          </td>
                                          {withheldTax.node.contact ? (
                                            <td>
                                              {withheldTax.node.contact.name}{" "}
                                              {getNameResidential(
                                                withheldTax.node.contact
                                                  .firstName,
                                                withheldTax.node.contact
                                                  .lastName
                                              )}
                                            </td>
                                          ) : (
                                            <td>
                                              {
                                                withheldTax.node.otherReceive
                                                  .unknownContact
                                              }
                                            </td>
                                          )}
                                          <td className="text-right">
                                            {numberWithComma(total)}
                                          </td>
                                          <td className="text-right">
                                            {numberWithComma(
                                              withheldTax.node.total
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan={5} className="text-right">
                                      รวม
                                    </td>
                                    <td className="text-right">
                                      {numberWithComma(
                                        this.record["total_withheld"]
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {numberWithComma(
                                        this.record["total_receive"]
                                      )}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={
                                  props.withheldTaxViewer.allWithheldTax
                                    .totalCount
                                }
                              />
                            </div>
                          </div>
                        );
                      }
                      return <Loading/>
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default WithheldTaxList;
