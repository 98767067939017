import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
const query = graphql`
  query checkRangeYearBudgetQuery($startDate: Date, $endDate: Date) {
    checkRangeYearBudget(startDate: $startDate, endDate: $endDate)
  }
`;

export const checkRangeYearBudget = (variables, response) => {
  fetchQuery(environment, query, variables)
    .then((data) => {
      if (data) {
        response(data);
      }
    })
    .catch((error) => {
      response(null);
    });
};
