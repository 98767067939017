import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import CashListTable from "./cashListTable";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import _ from "lodash";

const allCashDepositGroup = graphql`
    query cashListAllQuery($first: Int, $last: Int){
        cashDepositGroups(first: $first, last: $last){
            edges{
                node{
                    id
                    docNumber
                    depositDate
                    description
                    total
                    added
                    bank{
                        id
                        bankName
                        accountNumber
                    }
                }
            }
            totalCount
        }
        selfProject {
            timeZone
        }
    }
`;

class CashListAll extends Component {
    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="invoice-list">

                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('cash:cash_deposit')+ " "+"(CD)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                            <div className="col">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_cash_create'}) &&
                                <Link to="/accounting/finance/cash/create/select-receive">
                                    <button type="button" className="btn btn-primary add" style={{float: "right"}}>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                        <Translation>
                                            {
                                                t => <span>{t('cash:add_cash')}</span>
                                            }
                                        </Translation>
                                    </button>
                                </Link>
                                }
                            </div>
                        </div>
                        <div className="content-inner">

                            <CashListTable query={allCashDepositGroup} />
                        </div>

                    </div>
                </WrapperContent>

            </Wrapper>

        )
    }
}

export default CashListAll;