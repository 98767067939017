import {commitMutation} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {format} from "date-fns";


const mutation = graphql`
    mutation prepaidDepositMutationMutation($input: CreatePrepaidDepositAndTransactionInput!){
        createPrepaidDepositAndTransaction(input: $input){
            ok
            warningText
        }
    }
`;

export function setFormatPrepaidDepositMutation(state, preTaxAmount) {
    let _this = {
        state
    };

    let prepaid_deposit = {
        "id": "",
        "issuedDate": format(_this.state.issuedDate, 'YYYY-MM-DD'),
        "remark": _this.state.remark,
        "total": _this.state.total,
        "taxType": _this.state.taxType,
        "preTaxAmount": preTaxAmount,
        "tax_invoice_number": _this.state.tax_invoice_number,
        "tax_invoice_date": format(_this.state.tax_invoice_date, 'YYYY-MM-DD'),
        "tax_invoice_period": format(_this.state.tax_invoice_period, 'YYYY-MM-DD'),
        "ref_number": _this.state.ref_number,
    };

    let variables = {
        input: {
            contactId: _this.state.customerList[0],
            prepaidDeposit: JSON.stringify(prepaid_deposit),
            transactionList: JSON.stringify(_this.state.transaction_list),
            paymentChannel: JSON.stringify(_this.state.payChannel),
            clientMutationId:"PD"+_this.state.signreq,
        }
    };
    return variables;

}

export function prepaidDepositMutation(variables, uploadables, callback, error_callback) {

    commitMutation(
        environment,
        {
            mutation,
            variables,
            uploadables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}

export default {prepaidDepositMutation, setFormatPrepaidDepositMutation};
