/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type feedbackListQueryVariables = {|
  search?: ?string,
  award?: ?number,
  startDate?: ?any,
  endDate?: ?any,
  first?: ?number,
  last?: ?number,
  userType?: ?string,
  site?: ?string,
|};
export type feedbackListQueryResponse = {|
  +allFeedback: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +award: number,
        +note: ?string,
        +added: any,
        +feedbackImage: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: string,
              +fileUpload: ?string,
            |}
          |}>
        |},
        +user: {|
          +id: string,
          +tenant: ?{|
            +id: string,
            +firstName: string,
            +lastName: ?string,
            +phone: ?string,
            +email: ?string,
          |},
          +juristic: ?{|
            +id: string,
            +firstName: string,
            +lastName: string,
            +phone: string,
          |},
        |},
        +site: {|
          +id: string,
          +name: string,
          +project: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +name: string
              |}
            |}>
          |},
        |},
      |}
    |}>,
  |},
  +rattingFeedback: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +award: number,
        +note: ?string,
        +added: any,
        +site: {|
          +id: string,
          +name: string,
          +project: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +name: string
              |}
            |}>
          |},
        |},
      |}
    |}>,
  |},
|};
export type feedbackListQuery = {|
  variables: feedbackListQueryVariables,
  response: feedbackListQueryResponse,
|};
*/


/*
query feedbackListQuery(
  $search: String
  $award: Float
  $startDate: DateTime
  $endDate: DateTime
  $first: Int
  $last: Int
  $userType: String
  $site: ID
) {
  allFeedback(search: $search, award: $award, startDate: $startDate, endDate: $endDate, first: $first, last: $last, userType: $userType, site: $site) {
    totalCount
    edges {
      node {
        id
        award
        note
        added
        feedbackImage {
          edges {
            node {
              id
              fileName
              fileUpload
            }
          }
        }
        user {
          id
          tenant {
            id
            firstName
            lastName
            phone
            email
          }
          juristic {
            id
            firstName
            lastName
            phone
          }
        }
        site {
          id
          name
          project {
            edges {
              node {
                name
                id
              }
            }
          }
        }
      }
    }
  }
  rattingFeedback: allFeedback {
    totalCount
    edges {
      node {
        id
        award
        note
        added
        site {
          id
          name
          project {
            edges {
              node {
                name
                id
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "award"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "site"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userType"
},
v8 = [
  {
    "kind": "Variable",
    "name": "award",
    "variableName": "award"
  },
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "site",
    "variableName": "site"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  },
  {
    "kind": "Variable",
    "name": "userType",
    "variableName": "userType"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "award",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "FeedbackImageNodeConnection",
  "kind": "LinkedField",
  "name": "feedbackImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FeedbackImageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeedbackImageNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileUpload",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantNode",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        (v10/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/),
        (v17/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicNode",
      "kind": "LinkedField",
      "name": "juristic",
      "plural": false,
      "selections": [
        (v10/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/),
        (v17/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "SiteType",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v19/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectNodeConnection",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v19/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "SiteType",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v19/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectNodeConnection",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v19/*: any*/),
                (v10/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "feedbackListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "FeedbackNodeConnection",
        "kind": "LinkedField",
        "name": "allFeedback",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedbackNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeedbackNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v18/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "rattingFeedback",
        "args": null,
        "concreteType": "FeedbackNodeConnection",
        "kind": "LinkedField",
        "name": "allFeedback",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedbackNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeedbackNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "feedbackListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "FeedbackNodeConnection",
        "kind": "LinkedField",
        "name": "allFeedback",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedbackNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeedbackNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v18/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "rattingFeedback",
        "args": null,
        "concreteType": "FeedbackNodeConnection",
        "kind": "LinkedField",
        "name": "allFeedback",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedbackNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeedbackNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c44902a03d61542e1f0f3346c50a3e11",
    "id": null,
    "metadata": {},
    "name": "feedbackListQuery",
    "operationKind": "query",
    "text": "query feedbackListQuery(\n  $search: String\n  $award: Float\n  $startDate: DateTime\n  $endDate: DateTime\n  $first: Int\n  $last: Int\n  $userType: String\n  $site: ID\n) {\n  allFeedback(search: $search, award: $award, startDate: $startDate, endDate: $endDate, first: $first, last: $last, userType: $userType, site: $site) {\n    totalCount\n    edges {\n      node {\n        id\n        award\n        note\n        added\n        feedbackImage {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n            }\n          }\n        }\n        user {\n          id\n          tenant {\n            id\n            firstName\n            lastName\n            phone\n            email\n          }\n          juristic {\n            id\n            firstName\n            lastName\n            phone\n          }\n        }\n        site {\n          id\n          name\n          project {\n            edges {\n              node {\n                name\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  rattingFeedback: allFeedback {\n    totalCount\n    edges {\n      node {\n        id\n        award\n        note\n        added\n        site {\n          id\n          name\n          project {\n            edges {\n              node {\n                name\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5de52a1cba108a8c9b9eb1b1d9ebb2e0';

module.exports = node;
