import React, { Component } from 'react';
import Header from '../../../../components/header/index';
import Sidebar from '../../../../components/sidebar/index';
import Wrapper from '../../../../components/wrapper/index';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import InvoiceListTable from "./invoiceListTable"
import './style/invoiceList.scss'
import "react-datepicker/dist/react-datepicker.css";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { commitMutation } from "react-relay";
import environment from "../../../../env/environment";
import Swal from "sweetalert2";
import i18n from 'i18next';
import _ from "lodash"


const allInvoice = graphql`
    query invoiceListAllQuery($status: String, $search: String, $start_date : DateTime, $end_date : DateTime, $first: Int, $last: Int, $is_contact_online: Boolean){
        
        invoiceViewer{
            allInvoice(status:$status, search:$search, startDate:$start_date, endDate:$end_date, first: $first, last: $last, isContactOnline: $is_contact_online){
                totalCount
                edges{
                    node{
                        id
                        docNumber
                        name
                        firstName
                        lastName
                        payGroup
                        historyShow
                        remark
                        contact{
                            id
                            refNumber
                            name
                            firstName
                            lastName
                            typeOfContact
                            residential{
                                residentialHouseholder(type:"renter"){
                                    edges{
                                        node{
                                            id
                                            tenant{
                                                nameTitle
                                                firstName
                                                lastName
                                                phone
                                                email
                                            }
                                        }
                                    }
                                }
                            }
                            
                        }
                        total
                        issuedDate
                        dueDate
                        scheduleTime
                        status
                        approver
                        creator
                        updater
                        added
                        updated
                        voidRemark
                        transaction {
                            edges {
                                node {
                                    id
                                    productAndService {
                                        id
                                        productCode
                                    }
                                    description
                                    total
                                    paid
                                    #Cn V1
                                    creditNoteTransaction(status: "paid"){
                                        edges{
                                            node{
                                                id
                                                docNumber
                                                issuedDate
                                                price
                                                status
                                            }
                                        }
                                    }
                                    ##Cn V2
                                    creditNoteTransactionRecord(status: "paid"){
                                        edges{
                                            node{
                                                id
                                                price
                                                creditNote{
                                                    id
                                                    docNumber
                                                    issuedDate
                                                    status
                                                }
                                                status
                                            }
                                        }
                                    }

                                    receiptDepositTransactionUsed{
                                        edges{
                                            node{
                                                id
                                                coupleUse
                                                amount
                                                receiptDeposit{
                                                    id
                                                    docNumber
                                                    issuedDate
                                                }
                                                receiptDepositTransaction{
                                                    couple
                                                }
                                            }
                                        }
                                    }
                                    receiveTransaction(
                                        receive_Status_In: "paid, wait,partial_payment"
                                    ){
                                        edges {
                                            node {
                                                id
                                                receive {
                                                    id
                                                    docNumber
                                                    issuedDate
                                                    status
                                                    creditCardTransaction {
                                                        totalCount
                                                    }
                                                    chequeDeposit {
                                                        totalCount
                                                    }
                                                    cashDepositReceiveTransaction {
                                                        totalCount
                                                    }
                                                    bankAccountTransactionReceive {
                                                        totalCount
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const mutation = graphql`
  mutation invoiceListAllMutation(
    $input: InvoiceUpdateSelectInput!
  ) {
    invoiceUpdateSelect(input: $input) {
      ok
      message
    }
  }
`;

class InvoiceListAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            updateInvoice: false,
            listAllExportToExcel: null,
            listExportToExcel: [],
            checkList: "",
        }

        this.updateInvoice = this.updateInvoice.bind(this)
        this.updateInvoiceState = this.updateInvoiceState.bind(this)
        this.clearAllListSelect = this.clearAllListSelect.bind(this)
        this.getAllListSelect = this.getAllListSelect.bind(this)
    }

    updateInvoice = () => {
        this.setState({
            updateInvoice: true
        })
    }

    updateInvoiceState() {
        if (this.state.checkList.length > 0) {
            let variables = {
                input: {
                    idList: this.state.checkList,
                },
            };
    
            commitMutation(environment, {
                mutation,
                variables,
                onCompleted: (response) => {
                    // console.log(response);
                },
                onError: (err) => {
                    console.log(err);
                },
            });
    
            this.setState({
                updateInvoice: false,
            });
        } else {
            Swal.fire(i18n.t('invoice:Please select an item'), "", "warning");
            this.setState({
                updateInvoice: false,
            })
        }
    }
    

    clearAllListSelect() {
        this.setState({
            check_all_draft: false,
            listAllExportToExcel: null,
            listExportToExcel: []
        }, () => console.log())
    }

    getAllListSelect(e, data) {
        let { checked } = e.target
        this.setState({
            check_all_draft: checked ? true : false,
            listAllExportToExcel: checked ? data : null,
            listExportToExcel: checked ? data : []
        }, () => console.log())
    }

    appendToCheckListDraf(e, dataId , nodeData) {
        let listAllExportToExcel = _.cloneDeep(this.state.listAllExportToExcel) || []
        let { checked } = e.target
        let findIndex = listAllExportToExcel.findIndex(ex => ex.node.id === dataId.node.id)

        if (checked && findIndex < 0) {
            listAllExportToExcel.push(dataId)
        } else {
            listAllExportToExcel.splice(findIndex, 1) //listExportToExcelJustId
        }
        this.setState({
            check_all_draft: false,
            listAllExportToExcel: listAllExportToExcel,
            listExportToExcel: listAllExportToExcel,
        }, () => console.log())
    }

    handleCheckListChange = (current_checklist) => {
        this.setState({ checkList: current_checklist }
        );
      }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <div id="wrapper-content">
                    <AccountingTopMenuNavigation mini={true} />
                    <div className="container-fluid box" id="invoice-list">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('accounting:invoice') + " " + "(IV)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                            <div className="col">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_invoice_create' }) &&
                                    <Link to="/accounting/income/invoice/form/select_customer">
                                        <button type="button" className="btn btn-primary add float-right">
                                            <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus" />
                                            <Translation>
                                                {
                                                    t => <span>{t('accounting:add_invoice')}</span>
                                                }
                                            </Translation>
                                        </button>
                                    </Link>
                                }

                                <button type="submit" className="btn btn-primary add float-right mr-4" onClick={this.updateInvoice}>
                                    <img src={process.env.PUBLIC_URL + '/images/icons/updating.png'} alt="updating" />
                                    <Translation>
                                        {
                                            t => <span>{t('accounting:update_invoice')}</span>
                                        }
                                    </Translation>
                                </button>
                            </div>
                        </div>
                        <div className="content-inner">
                            <InvoiceListTable query={allInvoice} status={this.props.match.params.status}
                                appendToCheckListDraf={(e, dataId) => this.appendToCheckListDraf(e, dataId)}
                                getAllListSelect={(e, data) => this.getAllListSelect(e, data)}
                                check_all_draft={this.state.check_all_draft}
                                listAllExportToExcel={this.state.listAllExportToExcel}
                                listExportToExcel={this.state.listExportToExcel}
                                updateInvoice={this.state.updateInvoice}
                                updateInvoiceFunction={this.updateInvoiceState}
                                clearAllListSelect={this.clearAllListSelect}
                                onCheckListChange={this.handleCheckListChange}
                            />
                        </div>
                    </div>
                </div>

            </Wrapper>

        )
    }
}

export default InvoiceListAll;
