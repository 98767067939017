import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { styled } from '@mui/material/styles';

import './slip.scss'
import { format } from 'date-fns';
import i18next from 'i18next';

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));



function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ fontFamily: 'medium' }}>
        <TableCell component="th" scope="row">{`${row.projectId} - ${row.ProjectName ? row.ProjectName?.fullName : 'ระบุรหัสโครงการไม่ถูกต้อง'}`}</TableCell>
        <TableCell align="left">{row.houseNo}</TableCell>
        <TableCell align="center">{format(new Date(row.added), "DD/MM/YYYY hh:mm A")}</TableCell>
        <TableCell align="center">{row.statusCode}</TableCell>
        <TableCell align="center">{row.statusMessage.toUpperCase() == 'SUCCESS' ?
          <Chip label="True" color="success" sx={{ bgcolor: '#4EB4A9', color: 'white', width: '65px' }} /> :
          <Chip color="error" label="False" sx={{ bgcolor: '#E14558', color: 'white', width: '65px' }} />}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>

              <Table size="small" className='slip-dark-gray'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <AccountBalanceIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="ข้อมูลลูกบ้าน" />
                      </ListItem>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0' }}>เลขบัตรปชช/Passport ID</TableCell>
                    <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0' }}>ชื่อลูกบ้าน</TableCell>
                    <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0' }}>เบอร์โทร</TableCell>
                    <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0' }}>ประเภทผู้อาศัย</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    row.tenant?.map((item_tenant, index_item_tenant) => {
                      return (
                        <React.Fragment key={`index_item_tenant ${index_item_tenant}`}>
                          <TableCell style={{ paddingBottom: "16px" }}></TableCell>
                          <TableCell style={{ paddingBottom: "16px" }}>{`${item_tenant.cardNo}`}</TableCell>
                          <TableCell style={{ paddingBottom: "16px" }}>{`${item_tenant.preName} ${item_tenant.firstName} ${item_tenant.lastName} `}</TableCell>
                          <TableCell style={{ paddingBottom: "16px" }}>{`${item_tenant.mobilePhoneNumber} `}</TableCell>
                          <TableCell style={{ paddingBottom: "16px" }} >{item_tenant.userType === "owner" ? 'เจ้าของห้อง' : item_tenant.userType === "tenant" ? 'ผู้อาศัย' : 'ผู้เช่า'
                          }</TableCell>
                        </React.Fragment>
                      )
                    })
                  }
                </TableBody>
              </Table>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen2(!open2)}
                >
                  {open2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} Log Transfer Response
                </IconButton>
                <Collapse in={open2} timeout="auto" unmountOnExit>
                  <Div>{JSON.stringify(row.resVerify)}</Div>
                </Collapse>
              </TableCell>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     invoiceId: PropTypes.string.isRequired,
//     qrcodeData: PropTypes.string.isRequired,
//     resultCompare: PropTypes.string.isRequired,
//     resultCode: PropTypes.string.isRequired,
//     resultText: PropTypes.string.isRequired,
//     slipVerifyLogsStatusCode: PropTypes.string.isRequired,
//     slipVerifyLogsStatusMessage: PropTypes.string.isRequired,
//     amount: PropTypes.shape({
//       currentPaid: PropTypes.number.isRequired,
//       transactionTotal: PropTypes.number.isRequired,
//       amount_status: PropTypes.bool.isRequired,
//     }),
//     date: PropTypes.shape({
//       slipDate: PropTypes.string.isRequired,
//       invoiceDate: PropTypes.string.isRequired,
//       appDate: PropTypes.string.isRequired,
//       date_status: PropTypes.bool.isRequired,
//     }),
//     bank: PropTypes.shape({
//       bankName: PropTypes.string.isRequired,
//       bankName_svm: PropTypes.string.isRequired,
//       bankName_status: PropTypes.bool.isRequired,
//       accountName: PropTypes.string.isRequired,
//       accountName_svm: PropTypes.string.isRequired,
//       accountName_status: PropTypes.bool.isRequired,
//       accountNumber: PropTypes.string.isRequired,
//       accountNumber_svm: PropTypes.string.isRequired,
//       accountNumber_status: PropTypes.bool.isRequired
//     }),
//     slipVerifyLogResVerify: PropTypes.shape({
//       amount: PropTypes.number,
//       countryCode: PropTypes.string,
//       language: PropTypes.string,
//       paidLocalAmount: PropTypes.number,
//       paidLocalCurrency: PropTypes.string,
//       receiver: PropTypes.shape({
//         account: PropTypes.shape({
//           type: PropTypes.string,
//           value: PropTypes.string,
//         }),
//         displayName: PropTypes.string,
//         name: PropTypes.string,
//         proxy: PropTypes.shape({
//           type: PropTypes.string,
//           value: PropTypes.string,
//         }),
//       }),
//       receivingBank: PropTypes.string,
//       ref1: PropTypes.string,
//       ref2: PropTypes.string,
//       ref3: PropTypes.string,
//       sender: PropTypes.shape({
//         account: PropTypes.shape({
//           type: PropTypes.string,
//           value: PropTypes.string,
//         }),
//         displayName: PropTypes.string,
//         name: PropTypes.string,
//         proxy: PropTypes.shape({
//           type: PropTypes.string,
//           value: PropTypes.string,
//         }),
//       }),
//       sendingBank: PropTypes.string,
//       toMerchantId: PropTypes.string,
//       transDate: PropTypes.string,
//       transFeeAmount: PropTypes.number,
//       transRef: PropTypes.string,
//       transTime: PropTypes.string,
//     }),
//   }).isRequired,
// };

export default function CollapsibleTable({filter , AllProjectData , TransferLogData , countData}) {
  const [logs, setLogs] = useState([])
  const [iconProject, setProject] = useState()
  const [countLog, setCountLog] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let log = TransferLogData

    // let countLog = data.data.data.countLog
    let project = AllProjectData

    let logData = log.map((item) => ({
      ...item,
      chkProject: project.includes(ex => ex.projectId === item.projectId),
      ProjectName: project.find(ex => ex.projectId === item.projectId)
    }))

    setCountLog(countData);
    setLogs(logData);
    setProject(project)
    setLoading(false);

  }, [])

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          {countLog < 1 && <caption>{i18next.t("logsTransfer:Number of Data Found")} {countLog}</caption> }
          <TableHead>
            <TableRow>
              <TableCell style={{ fontFamily: 'medium', backgroundColor: '#e9ecef' }}> Project Id </TableCell>
              <TableCell style={{ width: '20%', fontFamily: 'medium', backgroundColor: '#e9ecef' }} align="left">{i18next.t("logsTransfer:House/Room Number")}</TableCell>
              <TableCell style={{ fontFamily: 'medium', backgroundColor: '#e9ecef' }} align="center">{i18next.t("logsTransfer:Date of Data Transfer")}</TableCell>
              <TableCell style={{ fontFamily: 'medium', backgroundColor: '#e9ecef' }} align="center">{i18next.t("logsTransfer:Result")}</TableCell>
              <TableCell style={{ fontFamily: 'medium', backgroundColor: '#e9ecef' }} align="center">{i18next.t("logsTransfer:Status")}</TableCell>
              <TableCell style={{ fontFamily: 'medium', backgroundColor: '#e9ecef' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>

      </TableContainer>
    </React.Fragment>
  );
}
