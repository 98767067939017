import React, {Component} from "react";
import { Helmet } from "react-helmet";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import Loading from '../../../../libs/loading';
import { QueryRenderer } from "react-relay";
import GuaranteeMoneyReceivedReportPDFRenderTable from "./guaranteeMoneyReceivedReportPDFRenderTable";
import './guaranteeMoneyReceivedReportPDF.scss'

const query = graphql`
  query guaranteeMoneyReceivedReportPDFQuery(
    $first: Int
    $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $search: String
    $customerType: String
    $statusType: String
    $contact: ID
    $paymentChannel: String
    $order: String
  ) {
    allGuaranteeMoneyReceived(
      first: $first
      last: $last
      startDate: $start_date
      endDate: $end_date
      search: $search
      customerType: $customerType
      statusType: $statusType
      contact: $contact
      paymentChannel: $paymentChannel
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          docNumber
          issuedDate
          amount
          description
          status
          payGroup
          contact {
            name
            refNumber
            firstName
            lastName
            typeOfContact
            residential {
              name
            }
          }
          cashDepositReceiveTransaction {
            totalCount
          }
          bankAccountTransaction {
            totalCount
          }
          chequeDeposit {
            totalCount
          }
        }
      }
    }
    summaryGuaranteeMoneyReceived(
      startDate: $start_date
      endDate: $end_date
      search: $search
      customerType: $customerType
      contact: $contact
      paymentChannel: $paymentChannel
      statusType: $statusType
    )
  }
`;


class GuaranteeMoneyReceivedReportPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        }
    }

    componentWillMount() {
        this.setState({
            data: this.props.location?.state?.data,
        })
    }

    render() {
        return (
            <React.Fragment>
                        <Helmet
                        style={[{
                            "cssText": `
                            body {
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                padding: 0;
                                background-color: #FAFAFA;
                                }
                            
                                * {
                                box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                }
                            
                                .page {
                                width: 297mm;
                                max-hight:210mm;
                                min-height: 210mm;
                                padding: 10mm 5mm 5mm 5mm ;
                                border: 1px #000 solid;
                                border-radius: 2px;
                                background: white;
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                                position: relative;
                                }
                                .pageNumber{
                                position: fixed;
                                bottom: 0;
                                }
                            
                                @page {
                                size: a4 landscape;
                                margin: 0;
                                }
                                @media print {
                                html, body {
                                    width: 297mm;
                                    height: 210mm;
                                }

                                @page: last{
                                    .pageNumber {
                                        display: block;
                                    }
                                }
                                .bg-gray{
                                    background-color: #D3D3D3;
                                }
                                .page {
                                    margin: 0;
                                    border: initial;
                                    border-radius: initial;
                                    width: initial;
                                    min-height: initial;
                                    box-shadow: initial;
                                    background: initial;
                                    page-break-after: always;
                                    position: relative;
                                }
                                .pageNumber{
                                    position: fixed;
                                    bottom: 0;
                                }
                                }
                        `
                        }]}>
                        <meta charSet="utf-8" />
                        <title>Guarantee Money Received</title>
                        <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        </Helmet>
                        <div id="guaranteeMoneyReceivedReportPDF">
                            <div className="print-top-menu">
                                    <div className="logo">
                                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                                    </div>
                                    <div className="print" onClick={() => window.print()}>
                                        PRINT
                                    </div>
                            </div>                            
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                cacheConfig={{ use_cache: false }}
                                variables={{
                                    first: null,
                                    last: null,
                                    start_date: this.state.data.start_date,
                                    end_date: this.state.data.end_date,
                                    search: this.state.data.search,
                                    contact: this.state.data.contact,
                                    customerType: this.state.data.customer_type,
                                    paymentChannel: this.state.data.payment_channel,
                                    statusType: this.state.data.status_type,
                                    order: "doc_number",
                                }}
                                render={({ error, props }) => {

                                if (props) {
                                    let contactName = 'ทั้งหมด';
                                    let statusType = 'ทั้งหมด';
                                    let paymentChannel = 'ทั้งหมด';
                                    if(this.state.data.contact !== "") {
                                        if(props.allGuaranteeMoneyReceived?.edges[0]?.node.contact.typeOfContact === "RESIDENTIAL"){
                                            contactName = props.allGuaranteeMoneyReceived?.edges[0].node.contact.refNumber
                                            +" "+props.allGuaranteeMoneyReceived?.edges[0].node.contact.firstName
                                            +" "+props.allGuaranteeMoneyReceived?.edges[0].node.contact.lastName
                                        } 
                                        if(props.allGuaranteeMoneyReceived?.edges[0]?.node.contact.typeOfContact === "SUPPLIER"){
                                            contactName = props.allGuaranteeMoneyReceived?.edges[0].node.contact.refNumber
                                            +" "+props.allGuaranteeMoneyReceived?.edges[0].node.contact.name                                        
                                        }
                                    }
                                    switch (this.state.data.status_type) {
                                        case "in_process":
                                          statusType = "อยู่ระหว่างดำเนินการ";
                                          break;
                                        case "completed":
                                          statusType = "ทำการคืน";
                                          break;      
                                        case "forfeit_all":
                                          statusType = "ทำการยึด";
                                          break;      
                                        case "return_forfeit":
                                          statusType = "ยึดบางส่วน/คืนบางส่วน";
                                          break;      
                                        case "void":
                                          statusType = "รายการที่ยกเลิก";
                                          break;      
                                    }    
                                    switch (this.state.data.payment_channel) {
                                        case "cash":
                                          paymentChannel = "เงินสด";
                                          break;
                                        case "bank":
                                          paymentChannel = "เงินโอน";
                                          break;      
                                        case "cheque":
                                          paymentChannel = "เช็ค";
                                          break;      
                                    }    
                                    return (
                                        <GuaranteeMoneyReceivedReportPDFRenderTable
                                          props={props}
                                          contactName={contactName}
                                          statusType={statusType}
                                          paymentChannel={paymentChannel}
                                          start_date={this.state.data.start_date}
                                          end_date={this.state.data.end_date}
                                        />
                                    );
                                } else {
                                    return <Loading/>
                                }
                                }}
                            />
                        </div>    
            </React.Fragment>
        )
    }
}

export default GuaranteeMoneyReceivedReportPDF;