import {
    commitMutation,
} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment"

const mutation = graphql`
    mutation approvePurchaseOrderesMutation($input: ApprovePurchaseOrderesInput!){
        approvePurchaseOrderes(input:$input) 
        {
            ok
            message
        }
    }
`;

export default function approvePurchaseOrderes(variables, callback, error_callback){
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
