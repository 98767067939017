/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssetCalSchedule = "MONTHLY" | "YEARLY" | "%future added value";
export type AssetSaleStatus = "ACTIVE" | "DETERIORATE" | "DISAPPEAR" | "SOLD" | "%future added value";
export type AssetType = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type assetDetailQueryVariables = {|
  id: string,
  date?: ?any,
|};
export type assetDetailQueryResponse = {|
  +asset: ?{|
    +id: string,
    +type: AssetType,
    +docNumber: string,
    +nounClassifier: string,
    +importStatus: boolean,
    +chartOfAccountType: {|
      +chartOfAccountCode: string,
      +name: string,
      +id: string,
    |},
    +chartOfAccountDepreciation: ?{|
      +id: string,
      +chartOfAccountCode: string,
      +name: string,
    |},
    +chartOfAccountDepreciationCumulative: ?{|
      +id: string,
      +chartOfAccountCode: string,
      +name: string,
    |},
    +name: string,
    +description: ?string,
    +storage: ?string,
    +remark: string,
    +internalAssetCode: ?string,
    +seller: ?{|
      +id: string,
      +name: string,
    |},
    +sellerOther: ?string,
    +calDepreciation: boolean,
    +depreciationRatePercent: ?number,
    +depreciationRate: ?number,
    +lifeTimeYear: ?number,
    +purchaseDate: ?any,
    +saleDate: ?any,
    +calDepreciationDate: ?any,
    +costPrice: ?number,
    +carcassPrice: ?number,
    +calSchedule: AssetCalSchedule,
    +historicalValue: number,
    +historicalAmount: number,
    +saleStatus: AssetSaleStatus,
    +refNumber: ?string,
    +assetDepreciate: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +depreciatePrice: number,
        |}
      |}>
    |},
    +deteriorateAsset: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +approver: ?string,
          +total: ?number,
          +chartOfAccountBook: ?{|
            +name: string
          |},
        |}
      |}>
    |},
  |},
  +allAssetImage: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +source: string,
      |}
    |}>
  |},
  +checkApproveAsset: ?any,
|};
export type assetDetailQuery = {|
  variables: assetDetailQueryVariables,
  response: assetDetailQueryResponse,
|};
*/


/*
query assetDetailQuery(
  $id: ID!
  $date: Date
) {
  asset(id: $id) {
    id
    type
    docNumber
    nounClassifier
    importStatus
    chartOfAccountType {
      chartOfAccountCode
      name
      id
    }
    chartOfAccountDepreciation {
      id
      chartOfAccountCode
      name
    }
    chartOfAccountDepreciationCumulative {
      id
      chartOfAccountCode
      name
    }
    name
    description
    storage
    remark
    internalAssetCode
    seller {
      id
      name
    }
    sellerOther
    calDepreciation
    depreciationRatePercent
    depreciationRate
    lifeTimeYear
    purchaseDate
    saleDate
    calDepreciationDate
    costPrice
    carcassPrice
    calSchedule
    historicalValue
    historicalAmount
    saleStatus
    refNumber
    assetDepreciate(date_Lte: $date) {
      edges {
        node {
          id
          depreciatePrice
        }
      }
    }
    deteriorateAsset {
      edges {
        node {
          id
          approver
          total
          chartOfAccountBook {
            name
            id
          }
        }
      }
    }
  }
  allAssetImage(asset_Id: $id) {
    edges {
      node {
        id
        source
      }
    }
  }
  checkApproveAsset(assetId: $id)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nounClassifier",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "importStatus",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccountType",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v11 = [
  (v3/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccountDepreciation",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccountDepreciationCumulative",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storage",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalAssetCode",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "seller",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sellerOther",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calDepreciation",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depreciationRatePercent",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depreciationRate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lifeTimeYear",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purchaseDate",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saleDate",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calDepreciationDate",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costPrice",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carcassPrice",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calSchedule",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "historicalValue",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "historicalAmount",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saleStatus",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "date_Lte",
      "variableName": "date"
    }
  ],
  "concreteType": "AssetDepreciateNodeConnection",
  "kind": "LinkedField",
  "name": "assetDepreciate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetDepreciateNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetDepreciateNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "depreciatePrice",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approver",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "asset_Id",
      "variableName": "id"
    }
  ],
  "concreteType": "AssetImageNodeConnection",
  "kind": "LinkedField",
  "name": "allAssetImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetImageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetImageNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "source",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "assetId",
      "variableName": "id"
    }
  ],
  "kind": "ScalarField",
  "name": "checkApproveAsset",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "assetDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AssetNode",
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v10/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v9/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeteriorateAssetNodeConnection",
            "kind": "LinkedField",
            "name": "deteriorateAsset",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeteriorateAssetNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeteriorateAssetNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v35/*: any*/),
                      (v36/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChartOfAccountNode",
                        "kind": "LinkedField",
                        "name": "chartOfAccountBook",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v37/*: any*/),
      (v38/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "assetDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AssetNode",
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v10/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v9/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeteriorateAssetNodeConnection",
            "kind": "LinkedField",
            "name": "deteriorateAsset",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeteriorateAssetNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeteriorateAssetNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v35/*: any*/),
                      (v36/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChartOfAccountNode",
                        "kind": "LinkedField",
                        "name": "chartOfAccountBook",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v37/*: any*/),
      (v38/*: any*/)
    ]
  },
  "params": {
    "cacheID": "fce7e5401064c19ff493b8a7ba9c32e4",
    "id": null,
    "metadata": {},
    "name": "assetDetailQuery",
    "operationKind": "query",
    "text": "query assetDetailQuery(\n  $id: ID!\n  $date: Date\n) {\n  asset(id: $id) {\n    id\n    type\n    docNumber\n    nounClassifier\n    importStatus\n    chartOfAccountType {\n      chartOfAccountCode\n      name\n      id\n    }\n    chartOfAccountDepreciation {\n      id\n      chartOfAccountCode\n      name\n    }\n    chartOfAccountDepreciationCumulative {\n      id\n      chartOfAccountCode\n      name\n    }\n    name\n    description\n    storage\n    remark\n    internalAssetCode\n    seller {\n      id\n      name\n    }\n    sellerOther\n    calDepreciation\n    depreciationRatePercent\n    depreciationRate\n    lifeTimeYear\n    purchaseDate\n    saleDate\n    calDepreciationDate\n    costPrice\n    carcassPrice\n    calSchedule\n    historicalValue\n    historicalAmount\n    saleStatus\n    refNumber\n    assetDepreciate(date_Lte: $date) {\n      edges {\n        node {\n          id\n          depreciatePrice\n        }\n      }\n    }\n    deteriorateAsset {\n      edges {\n        node {\n          id\n          approver\n          total\n          chartOfAccountBook {\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n  allAssetImage(asset_Id: $id) {\n    edges {\n      node {\n        id\n        source\n      }\n    }\n  }\n  checkApproveAsset(assetId: $id)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6c8e0aab4fd7175381ed25ddf29e57ad';

module.exports = node;
