/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type permissionQueryVariables = {|
  juristicId?: ?string,
  positionId?: ?string,
|};
export type permissionQueryResponse = {|
  +getJuristicPermissions: ?$ReadOnlyArray<?{|
    +id: string,
    +codename: string,
    +name: string,
  |}>,
  +checkUserJuristic: ?boolean,
  +getJuristicLimit: ?any,
  +allPositionPermission: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +positionName: ?string,
        +permissionPerPosition: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +permission: {|
                +id: string,
                +codename: string,
                +name: string,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
|};
export type permissionQuery = {|
  variables: permissionQueryVariables,
  response: permissionQueryResponse,
|};
*/


/*
query permissionQuery(
  $juristicId: String
  $positionId: String
) {
  getJuristicPermissions(juristicId: $juristicId) {
    id
    codename
    name
  }
  checkUserJuristic(juristicId: $juristicId)
  getJuristicLimit(juristicId: $juristicId)
  allPositionPermission(positionId: $positionId) {
    edges {
      node {
        id
        positionName
        permissionPerPosition {
          edges {
            node {
              id
              permission {
                id
                codename
                name
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "juristicId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "positionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "juristicId",
    "variableName": "juristicId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "codename",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "PermissionNode",
    "kind": "LinkedField",
    "name": "getJuristicPermissions",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "kind": "ScalarField",
    "name": "checkUserJuristic",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "kind": "ScalarField",
    "name": "getJuristicLimit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "positionId",
        "variableName": "positionId"
      }
    ],
    "concreteType": "PositionNodeConnection",
    "kind": "LinkedField",
    "name": "allPositionPermission",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PositionNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PositionNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "positionName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PermissionPerPositionNodeConnection",
                "kind": "LinkedField",
                "name": "permissionPerPosition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PermissionPerPositionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PermissionPerPositionNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PermissionNode",
                            "kind": "LinkedField",
                            "name": "permission",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "permissionQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "permissionQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "35a6807f0df4a2968a6e954589c8c0c1",
    "id": null,
    "metadata": {},
    "name": "permissionQuery",
    "operationKind": "query",
    "text": "query permissionQuery(\n  $juristicId: String\n  $positionId: String\n) {\n  getJuristicPermissions(juristicId: $juristicId) {\n    id\n    codename\n    name\n  }\n  checkUserJuristic(juristicId: $juristicId)\n  getJuristicLimit(juristicId: $juristicId)\n  allPositionPermission(positionId: $positionId) {\n    edges {\n      node {\n        id\n        positionName\n        permissionPerPosition {\n          edges {\n            node {\n              id\n              permission {\n                id\n                codename\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '121e70e6d3b869c04681ca09cce09836';

module.exports = node;
