/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type stockMovementReportTableQueryVariables = {|
  first?: ?number,
  last?: ?number,
  search?: ?string,
|};
export type stockMovementReportTableQueryResponse = {|
  +productViewer: ?{|
    +allProduct: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +productCode: string,
          +name: string,
          +added: any,
          +updated: any,
          +reorderPoint: ?number,
          +stock: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +cost: number,
                +leftInStock: number,
                +added: any,
                +transaction: ?{|
                  +date: ?any
                |},
              |}
            |}>
          |},
        |}
      |}>,
      +totalCount: ?number,
    |}
  |}
|};
export type stockMovementReportTableQuery = {|
  variables: stockMovementReportTableQueryVariables,
  response: stockMovementReportTableQueryResponse,
|};
*/


/*
query stockMovementReportTableQuery(
  $first: Int
  $last: Int
  $search: String
) {
  productViewer {
    allProduct(type_In: "product", first: $first, last: $last, search: $search, isActive: true) {
      edges {
        node {
          id
          productCode
          name
          added
          updated
          reorderPoint
          stock {
            edges {
              node {
                id
                cost
                leftInStock
                added
                transaction {
                  date
                  id
                }
              }
            }
          }
        }
      }
      totalCount
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Literal",
    "name": "isActive",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Literal",
    "name": "type_In",
    "value": "product"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reorderPoint",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "leftInStock",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "stockMovementReportTableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ProductAndServiceNodeConnection",
            "kind": "LinkedField",
            "name": "allProduct",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductAndServiceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductAndServiceNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductStockNodeConnection",
                        "kind": "LinkedField",
                        "name": "stock",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductStockNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductStockNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductTransactionNode",
                                    "kind": "LinkedField",
                                    "name": "transaction",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "stockMovementReportTableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ProductAndServiceNodeConnection",
            "kind": "LinkedField",
            "name": "allProduct",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductAndServiceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductAndServiceNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductStockNodeConnection",
                        "kind": "LinkedField",
                        "name": "stock",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductStockNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductStockNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductTransactionNode",
                                    "kind": "LinkedField",
                                    "name": "transaction",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "43c1f507693ba58a2fae869fd5fc419c",
    "id": null,
    "metadata": {},
    "name": "stockMovementReportTableQuery",
    "operationKind": "query",
    "text": "query stockMovementReportTableQuery(\n  $first: Int\n  $last: Int\n  $search: String\n) {\n  productViewer {\n    allProduct(type_In: \"product\", first: $first, last: $last, search: $search, isActive: true) {\n      edges {\n        node {\n          id\n          productCode\n          name\n          added\n          updated\n          reorderPoint\n          stock {\n            edges {\n              node {\n                id\n                cost\n                leftInStock\n                added\n                transaction {\n                  date\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '71a743059de7b5c6aa888e583968b2b6';

module.exports = node;
