import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import { differenceInCalendarDays } from "date-fns";
import i18next from "i18next";

class PayableOutstandingSummaryReportTable extends React.Component {

    getAmountOwed(purchase) {
        var _this = this
        const sumPayRecord = purchase.node.payRecord.edges.reduce((total, obj) => {
            if (differenceInCalendarDays(_this.props.state.end_date, obj.node.payRecordGroup.issuedDate) >= 0) {
                return total + (obj.node.amount + obj.node.whtAmount);
            } else {
                return total;
            }
        }, 0);

        const sumPrepaidDeposit = purchase.node.prepaidDepositTransactionUsed.edges.reduce((total, obj) => {
            return total + obj.node.amount;
        }, 0);

        const sumAcceptCreditNote = purchase.node.acceptCreditNoteTransaction.edges.reduce((total, obj) => {
            if (obj) {
                if (differenceInCalendarDays(_this.props.state.end_date, obj.node.issuedDate) >= 0) {
                    return total + obj.node.total;
                } else {
                    return total;
                }
            }
        }, 0)
        return parseFloat(purchase.node.total - sumPayRecord - sumPrepaidDeposit - sumAcceptCreditNote)
    }

    getNumberDay(purchase) {
        if (differenceInCalendarDays(this.props.state.end_date, purchase.node.dueDate) < 0) {
            return 0;
        } else {
            return differenceInCalendarDays(this.props.state.end_date, purchase.node.dueDate);
        }
    }

    between(value, min, max) {
        return value >= min && value <= max;
    }

    render() {
        let sumOutstandingBalance = 0;
        let sumNotOverdueBalance = 0;
        let sumOverdueOneMonthBalance = 0;
        let sumOverdueTwoMonthsBalance = 0;
        let sumOverdueThreeMonthsBalance = 0;
        let sumOverdueFourMonthsBalance = 0;
        let sumOverdueFiveMonthsBalance = 0;
        let sumOverdueSixMonthsBalance = 0;
        let sumOverdueOverSixMonthsBalance = 0;

        return (
            <React.Fragment>
                {
                    Object.entries(this.props.data).map(([key,record],index_obj) => {
                        let sum_totals = [0, 0, 0, 0, 0, 0, 0, 0];
                        let refNumber = '';
                        let nameContract = '';
                        let overdue_total = 0;
                        record.forEach(PurchaseRecordGroup => {
                            refNumber = PurchaseRecordGroup.node.contact.refNumber
                            nameContract = PurchaseRecordGroup.node.contact.name
                            PurchaseRecordGroup.node.purchaseRecord.edges.forEach(transaction =>{
                                const amountOwed = this.getAmountOwed(transaction)
                                overdue_total += amountOwed;
                                sumOutstandingBalance += amountOwed;
                                if (this.getNumberDay(PurchaseRecordGroup) < 1) {
                                    sum_totals[0] += amountOwed;
                                    sumNotOverdueBalance += amountOwed;
                                } else if (this.between(this.getNumberDay(PurchaseRecordGroup), 1, 30)) {
                                    sum_totals[1] += amountOwed;
                                    sumOverdueOneMonthBalance += amountOwed;
                                } else if (this.between(this.getNumberDay(PurchaseRecordGroup), 31, 60)) {
                                    sum_totals[2] += amountOwed;
                                    sumOverdueTwoMonthsBalance += amountOwed;
                                } else if (this.between(this.getNumberDay(PurchaseRecordGroup), 61, 90)) {
                                    sum_totals[3] += amountOwed;
                                    sumOverdueThreeMonthsBalance += amountOwed;
                                } else if (this.between(this.getNumberDay(PurchaseRecordGroup), 91, 120)) {
                                    sum_totals[4] += amountOwed;
                                    sumOverdueFourMonthsBalance += amountOwed;
                                } else if (this.between(this.getNumberDay(PurchaseRecordGroup), 121, 150)) {
                                    sum_totals[5] += amountOwed;
                                    sumOverdueFiveMonthsBalance += amountOwed;
                                } else if (this.between(this.getNumberDay(PurchaseRecordGroup), 151, 180)) {
                                    sum_totals[6] += amountOwed;
                                    sumOverdueSixMonthsBalance += amountOwed;
                                } else {
                                    sum_totals[7] += amountOwed;
                                    sumOverdueOverSixMonthsBalance += amountOwed;
                                }
                            })
                        })
                        return (
                            <tr key={key}>
                                <td className="text-center">{index_obj + 1}</td>
                                <td className="text-left">{refNumber}</td>
                                <td className="text-left">{nameContract}</td>
                                <td className="text-center">{numberWithComma(sum_totals[0], "")}</td>
                                <td className="text-center">{numberWithComma(sum_totals[1], "")}</td>
                                <td className="text-center">{numberWithComma(sum_totals[2], "")}</td>
                                <td className="text-center">{numberWithComma(sum_totals[3], "")}</td>
                                <td className="text-center">{numberWithComma(sum_totals[4], "")}</td>
                                <td className="text-center">{numberWithComma(sum_totals[5], "")}</td>
                                <td className="text-center">{numberWithComma(sum_totals[6], "")}</td>
                                <td className="text-center">{numberWithComma(sum_totals[7], "")}</td>
                                <td className="text-right">{numberWithComma(overdue_total, "")}</td>
                            </tr>
                        )
                    })
                }
                <tr>
                    <td colSpan={3} className="text-right"><strong>{i18next.t("PayableOutstandingSummaryReport:Total")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumNotOverdueBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueOneMonthBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueTwoMonthsBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueThreeMonthsBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueFourMonthsBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueFiveMonthsBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueSixMonthsBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueOverSixMonthsBalance, "")}</strong></td>
                    <td className="text-right"><strong>{numberWithComma(sumOutstandingBalance, "")}</strong></td>
                </tr>
            </React.Fragment>
        )

    }
}

export default PayableOutstandingSummaryReportTable;
