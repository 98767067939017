import { graphql } from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../../env/environment";

const mutation = graphql`
    mutation deleteInvoiceMutation ($input: DeleteDebitManualInput!) {
    deleteDebitManual (input: $input) {
        ok
        message
    }
}
`

export default function deleteInvoice(variables, callback, error_callback) {
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response) => {
          callback(response.deleteDebitManual.ok)
        },
        onError: (err) => {
            error_callback(err);
        },
      },
    )
  }