

import React, { Component } from 'react';
import environment from "../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import SilvermanAgentView from "./silvermanAgentView"
import './silvermanAgent.scss'


const query = graphql`
query decodeUrlQuery ($param: String) {
    decodeUrl (param: $param)
}
`
class DecodeUrl extends Component {

    constructor(props) {
        super(props)
        this.state = {
            passwordDecode : ''
        }
    }

    componentWillMount() {
        this.getQuery()
    }

    getQuery() {

        let url = this.props.location.search;
        const urlId = url.split("?data=");

        fetchQuery(environment, query,{param:urlId[1]}).then(response_data => {
            if (response_data.decodeUrl) {
                let silvermanAgentId = response_data.decodeUrl;
                const silvermanAgentIdDecode = silvermanAgentId.split("silvermanAgentId=");
                this.setState({
                    passwordDecode: silvermanAgentIdDecode[1]
                })
            }
        });
    }

    render() {
        return (
            <SilvermanAgentView passwordDecode={this.state.passwordDecode}/>
        )}
    
}

export default DecodeUrl;