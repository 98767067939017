import "../styles/createBudget.scss";
import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import _ from "lodash";
import { Translation } from "react-i18next";
import { addMonths, format } from "date-fns";
import { Redirect } from "react-router";
import Loading from "../../../libs/loading";
import numberWithCommas from "../../../libs/numberWithComma";
import code from "../json/chart_of_account.json";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import $ from "jquery"
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import BudgetSetingTable from "./budgetSetingTable";
import BudgetSetingExcelExports from "./budgetSetingExcelExports";
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import i18next from "i18next";
import GetdateOnEngAndTh from "../../../components/Action/getdateOnEngAndTh";

const query = graphql`
  query budgetSetingReportQuery($yearId: String) {
    oneYearBudget(yearId: $yearId) {
      edges {
        node {
          id
          years
          startDate
          endDate
          project {
            name
            nameEn
          }
          chartOfAccountBudget {
            edges {
              node {
                totalBudget
                chartOfAccount {
                  id
                  name
                  nameEn
                  chartOfAccountCode
                }
                monthBudget {
                  edges {
                    node {
                      months
                      budget
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

class BudgetSetingReport extends React.Component {
    constructor(pros) {
        super(pros);
        this.state = {
            lists: _.clone(code),
            loading: true,
            year: new Date(),
            start_date: new Date(2021, 0, 1),
            end_date: new Date(2021, 11, 1),
            list_month: [],
            value: [],
            save: false,
            redirectToList: false,
            disabled_table: true,
            id: "",
            year_search: this.props.match.params.id,
            project_name: "",
            sum_income: {},
            sum_expenses: {},
            total_sum_expenses: {},
            widthTable1: 0
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.widthTable1 !== this.state.widthTable1 || prevState.loading !== this.state.loading) {
            let width = $('table');
            this.setState({ widthTable1: width.width() })
        }
    }

    async componentDidMount() {
        let budget;
        let width = $('table');
        this.setState({ widthTable1: width.width() })
        await fetchQuery(environment, query, { yearId: this.state.year_search }).then((data) => { budget = data; }).catch((error) => { });
        if (budget.oneYearBudget.edges.length > 0) {
            let start = new Date(budget.oneYearBudget.edges[0]?.node.startDate);
            let end = addMonths(new Date(budget.oneYearBudget.edges[0].node.endDate), 1);
            let list = _.cloneDeep(this.state.lists);
            let list_month = [];
            do {
                list_month.push(start);
                start = addMonths(start, 1);
            } while (format(start, "MM-YYYY") !== format(end, "MM-YYYY"));

            for (const item of budget.oneYearBudget.edges[0].node.chartOfAccountBudget.edges) {
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 1) === "4") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[4].push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "51") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[5][0].child.push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "52") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[5][1].child.push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "53" && item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) !== "531") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[5][2].child.push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "531") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[5][3].child.push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "54") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[5][4].child.push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "55") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[5][5].child.push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "56") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[5][6].child.push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "57") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[5][7].child.push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "581") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[5][8].child.push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "582") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[5][8].child.push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
                if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "583") {
                    let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
                    check_null &&
                        list[5][8].child.push({
                            ...item.node.chartOfAccount,
                            month_budget: item.node.monthBudget.edges,
                            total_budget: item.node.totalBudget || "",
                        });
                }
            }
            let key = {
                sum_month_budget: [],
                sum_total_budget: 0,
            }
            let sum_income = _.cloneDeep(key)
            let sum_expenses = {}
            list[4].map((item) => {
                item.month_budget.map((month, index) => {
                    sum_income.sum_month_budget[index] = parseFloat(sum_income.sum_month_budget[index] || 0) + parseFloat(month.node.budget || 0)
                })
                sum_income.sum_total_budget = parseFloat(sum_income.sum_total_budget || 0) + parseFloat(item.total_budget || 0)
            })
            list[5].map((item) => {
                let code = item.key
                if (!sum_expenses[code]) {
                    sum_expenses[code] = _.cloneDeep(key)
                }
                item.child.map((item_child) => {
                    item_child.month_budget.map((month, index) => {
                        sum_expenses[code].sum_month_budget[index] = parseFloat(sum_expenses[code].sum_month_budget[index] || 0) + parseFloat(month.node.budget || 0)
                    })
                    sum_expenses[code].sum_total_budget = parseFloat(sum_expenses[code].sum_total_budget || 0) + parseFloat(item_child.total_budget || 0)
                })
            })

            let total_sum_expenses = _.cloneDeep(key)
            _.map(sum_expenses, (total_item, index) => {
                total_item.sum_month_budget.map((month, index) => {
                    total_sum_expenses.sum_month_budget[index] = parseFloat(total_sum_expenses.sum_month_budget[index] || 0) + parseFloat(month || 0)
                })
                total_sum_expenses.sum_total_budget = parseFloat(total_sum_expenses.sum_total_budget || 0) + parseFloat(total_item.sum_total_budget || 0)
            });

            this.setState({
                lists: list,
                loading: false,
                list_month,
                project_name: budget.oneYearBudget.edges[0].node.project.name,
                year: new Date(budget.oneYearBudget.edges[0].node.years),
                start_date: new Date(budget.oneYearBudget.edges[0].node.startDate),
                end_date: new Date(budget.oneYearBudget.edges[0].node.endDate),
                id: budget.oneYearBudget.edges[0].node.id,
                sum_income,
                sum_expenses,
                total_sum_expenses,
            });
        } else {
            this.setState({ redirectToList: true })
        }

    }

    toFixedNumber = (value) => {
        let sub = parseFloat(value);
        sub = sub.toString();
        if (sub.indexOf(".") > 0) {
            return numberWithCommas(sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3));
        } else if (value == 0) {
            return "-";
        } else if (value == "") {
            return "-";
        } else {
            return numberWithCommas(sub);
        }
    };

    toggleHandleScroll = (scroll) => {
        let scroller = document.querySelector('.table-responsive2');
        let table = document.querySelector('.table-responsive');

        if (scroll === "table-responsive2") {
            table.scrollTo(scroller.scrollLeft, 0);
        } if (scroll === "table-responsive") {
            scroller.scrollTo(table.scrollLeft, 0);
        }
    }

    render() {
        let last_index = 0;
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/budget/report/report-seting" />;
        }
        if (this.state.loading) {
            return (
                <div><Loading /></div>
            );
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box budget p-4" id="budget">
                                <BackButtonIcon
                                    LinkBack={"/accounting/budget/report/report-seting"}
                                    LinkText={i18next.t("menuListBudget:Annual Budget Setting Report")}
                                    boxHtmlText={
                                        <React.Fragment>
                                            <div className="row mt-2 d-flex justify-content-end" id='interest-report' style={{ position: 'absolute', marginRight: 24, right: 0, top: '1%' }} >
                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_print' }) &&
                                                    <Dropdown >
                                                        <DropdownToggle id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>{i18next.t("Allaction:Print")}</DropdownToggle>
                                                        <DropdownMenu>
                                                            {/* <Dropdown.Item>
                                            <Link to={{
                                                pathname: `/accounting/budget/report/wrapper-report-seting/${this.props.match.params.id}/${this.props.match.params.year}`,
                                                query: { lastDate: new Date() }
                                            }}
                                                className="text-black">
                                                <p className="text-black">PDF</p>
                                            </Link>
                                        </Dropdown.Item> */}
                                                            <BudgetSetingExcelExports
                                                                year={this.props.match.params.id}
                                                                state={this.state}
                                                            />
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                }
                                            </div >
                                        </React.Fragment>
                                    }
                                />
                                <div className="">
                                    <h3 className="text-center">{this.state.project_name}</h3>
                                    <h3 className="text-center">{i18next.t("budgetSetingReport:Summary of Annual Budgeting Report")}</h3>
                                    <h3 className="text-center">
                                        {` ${i18next.t("AllFilter:Date")} `}
                                        <GetdateOnEngAndTh
                                            date={this.state.start_date}
                                            withDate={true}
                                            withFullMonth={true}
                                            withFullYear={true}
                                        />
                                        {` ${i18next.t("AllFilter:to")} `}
                                        <GetdateOnEngAndTh
                                            date={this.state.end_date}
                                            withDate={true}
                                            withFullMonth={true}
                                            withFullYear={true}
                                        />
                                    </h3>
                                </div>
                                <BudgetSetingTable state={this.state} toFixedNumber={this.toFixedNumber} last_index={last_index} toggleHandleScroll={this.toggleHandleScroll} />
                            </div >
                        }
                    </Translation>
                </WrapperContent >
            </Wrapper >
        );
    }
}

export default BudgetSetingReport;
