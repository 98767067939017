import React from 'react';
import {Link} from "react-router-dom";
import { Translation } from 'react-i18next';
import _ from "lodash";
import Navigation from './navigation';
import ComponentPagination from '../../../../../libs/componentPagination';
import Pagination from '../../../../../libs/newPagination';
import './manageBatch.scss';
import InlineAdvanceFilter from '../../../../../libs/inlineAdvanceFilter';
import BatchPrintPdf from './batchPrintPDFAll';
import BatchPrintExcelAll from './batchPrintExcelAll';

import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from '../../../../../env/environment';
import { format } from 'date-fns';
import Loading from '../../../../../libs/loading';
import i18next from 'i18next';
import numberWithComma from '../../../../../libs/numberWithComma';

const allBatchReceive = graphql`
    query manageBatchQuery($startDate: Date, $endDate: Date, $search: String, $first: Int, $last: Int, $status: String){
        allBatchReceive(startDate: $startDate, endDate: $endDate, search: $search, first: $first, last: $last, status: $status){
            edges{
                node{
                    id
                    docNumber
                    batchDate
                    startRt
                    endRt
                    creator
                    voider
                    remark
                    status
                    added
                    receiveCount
                    importReceive
                    {
                        id
                        added
                        allRow
                    }
                }
            }
            totalCount
        },
        selfProject {
            name
            logo
        }
}`;

class MangeBatch extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state.allBatchData = [];
        this.state.loading = false;
        this.state.temp_start_date = null;
        this.state.temp_end_date = null;
        this.state.startDate = null;
        this.state.endDate = null;
        this.state.search = "";
        this.state.loading = false;
        this.state.project = "";
        this.state.totalCount = 0;
        this.state.pdfLoading = true;
        this.state.excelLoading = true;

        this.fetchBatchData = this.fetchBatchData.bind(this);
        this.calBatchRange = this.calBatchRange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
      }

    componentDidMount() {
        this.fetchBatchData()
    }
    

    componentDidUpdate(prevProps, prevState) {
     if (
          prevState.first !== this.state.first ||
          prevState.last !== this.state.last ||
          prevProps.status !== this.props.status
        ) {
          this.fetchBatchData();
        }
    }

    fetchBatchData() {
        this.setState({loading: true})
        const { temp_start_date, temp_end_date, search } = this.state;
        const variables = {
          startDate: temp_start_date ? format(temp_start_date, "YYYY-MM-DD") : null,
          endDate: temp_end_date ? format(temp_end_date, "YYYY-MM-DD") : null,
          search: search ? search : "",
          first: this.state.first,
          last: this.state.last,
          status: this.props.status
        };
        fetchQuery(environment, allBatchReceive, variables).then(data => {       
            this.setState({
                allBatchData: data.allBatchReceive?.edges,
                loading: false,
                totalCount: data.allBatchReceive?.totalCount,
                project: data.selfProject?.name
            })
        })
    }

    handleKeyDown(event) {
        if (event.key === "Enter") {
          this.handleSearch(event);
        }
    }

    handleSearch(e) {
        this.setState({
          startDate: this.state.temp_start_date,
          endDate: this.state.temp_end_date,
          search: e.target.value,
        }, ()=>this.fetchBatchData());
    }

    calBatchRange(startRt, endRt) {            
        const containsComma = startRt? startRt.includes(',') : "";

        let results = ""

        if(containsComma) {
            const startData = startRt.split(",");
            const endData = endRt.split(",");            
            let resultConcat = startData.map((start, index) => `${start} - ${endData[index]}`);
            results = resultConcat.join(', ')
        } else if(startRt === endRt){
            results = startRt
        } else {
            results = `${startRt} - ${endRt}`
        }

        return results
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render () {

        return (
            <Translation>
            {t => 
            <div className="container-fluid box" id="manageBatch">
                <div className='col'>
                    <div className="row justify-content-between">        
                        <h3 className="mb-4">
                            <Link to="/accounting/income/receive/list/all">
                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                    alt="back" className="back"/>
                            </Link>
                            <span>{t('batchRt:Batch management')}</span>
                        </h3>
                        {/* {
                            this.state.loading ?     
                            <>
                                <button className='dropdown printBatchDoc' data-toggle="dropdown">
                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                    <Translation>{t => t("meter:Preparing information")}</Translation>
                                </button>
                            </> */}
                                :
                            <>
                                <button className='dropdown printBatchDoc' data-toggle="dropdown">                                    
                                    {t('batchRt:Print Batch Report')}
                                    <img src={process.env.PUBLIC_URL + '/images/icons/vectorDown.svg'}
                                        alt="vectorDown"
                                        className='ml-2'
                                    />
                                </button>                            
                                <div className="dropdown-menu printItemContainer">
                                        <BatchPrintPdf 
                                            projectName={this.state.project}
                                            allBatchData={this.state.allBatchData}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            search={this.state.search}
                                            status={this.props.status}
                                        />
                                        <BatchPrintExcelAll 
                                            projectName={this.state.project}
                                            allBatchData={this.state.allBatchData}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            search={this.state.search}
                                            status={this.props.status}
                                        />                           
                                </div>   
                            </>
                        {/* }                      */}
                    </div>
                </div>
                <div className="content-inner">
                    <div className="row">   
                        <div className='col'>                      
                            <Navigation />              
                        </div>
                    </div>
                    <div className="row">
                        <div className='col'>           
                            <div className="input-group float-right w-auto ml-2">
                                <input  
                                    type="text"
                                    className="form-control input-size col-8"
                                    placeholder={`${i18next.t('batchRt:Search')}`}
                                    value={this.state.search_input}
                                    name="search"
                                    onChange={(e) =>
                                        this.setState({ search_input: e.target.value })
                                    }
                                    onKeyDown={this.handleKeyDown}
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary form-control search-button"
                                    name="search"
                                    value={this.state.search_input}
                                    onClick={(e) => this.handleSearch(e)}
                                >
                                {t('batchRt:Search')}
                            </button>
                            </div>
                            <InlineAdvanceFilter
                                show_filter={this.state.advance_search}
                                start_date={this.state.temp_start_date}
                                end_date={this.state.temp_end_date}
                                handleChange={this.handleChange}
                            />
                        </div>
                    </div>                        
                    <div className="row mt-2">      
                        <div className='col'>          
                            {
                            this.state.loading ?                                        
                            <Loading />
                                :
                            <div className="table-responsive fade-up card">
                                    <table className="table table-hover">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>{t('batchRt:Batch number')}</th>
                                                <th>{t('batchRt:Date')}</th>
                                                <th>{t('batchRt:Time')}</th>
                                                <th>{t('batchRt:Quantity')}</th>
                                                <th>{t('batchRt:Document number')}</th>
                                                <th>{t('batchRt:Reason for Cancellation')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.allBatchData?.map((batchData, index)=>(
                                                <tr key={index}>
                                                    {
                                                        batchData.node.status === "VOID" ?
                                                        <td>{batchData.node.docNumber}</td>
                                                            :
                                                        <td><Link to={`/accounting/income/receive/form/manage_batch_detail/${batchData.node.id}`}>{batchData.node.docNumber}</Link></td>
                                                    }
                                                    <td>{format(batchData.node.batchDate, "DD/MM/YYYY")}</td>
                                                    <td>{format(batchData.node?.added, 'HH.mm')}</td>
                                                    <td>{batchData.node?.receiveCount ? numberWithComma(batchData.node?.receiveCount,_,_,0,_) : 0} {t('batchRt:Transaction')}</td>
                                                    <td>
                                                        {this.calBatchRange(batchData.node.startRt, batchData.node.endRt)?.length > 0 ? 
                                                            this.calBatchRange(batchData.node.startRt, batchData.node.endRt).split(',').map((rtRange, index) => (
                                                                <React.Fragment key={index}>
                                                                    {rtRange}
                                                                    <br />
                                                                </React.Fragment>
                                                            )) 
                                                            : 
                                                            this.calBatchRange(batchData.node.startRt, batchData.node.endRt)
                                                        }
                                                    </td>
                                                    <td>{batchData.node?.remark}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                            </div>      
                            }
                        </div>                         
                    </div>
                    <div className="row">  
                        <Pagination
                            changePage={this.changePage}
                            first={this.state.first}
                            last={this.state.last}
                            totalCount={this.state.totalCount}
                        />
                    </div>
                </div>
            </div>
            }</Translation>
        )
    }
}

export default MangeBatch