/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateJuristicComapnyInput = {|
  juristicCompany: any,
  clientMutationId?: ?string,
|};
export type updateCompanyMutationVariables = {|
  input: UpdateJuristicComapnyInput
|};
export type updateCompanyMutationResponse = {|
  +updateJuristicComapny: ?{|
    +ok: ?boolean
  |}
|};
export type updateCompanyMutation = {|
  variables: updateCompanyMutationVariables,
  response: updateCompanyMutationResponse,
|};
*/


/*
mutation updateCompanyMutation(
  $input: UpdateJuristicComapnyInput!
) {
  updateJuristicComapny(input: $input) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateJuristicComapnyPayload",
    "kind": "LinkedField",
    "name": "updateJuristicComapny",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateCompanyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateCompanyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ae3c31b28faed11295ca467639897059",
    "id": null,
    "metadata": {},
    "name": "updateCompanyMutation",
    "operationKind": "mutation",
    "text": "mutation updateCompanyMutation(\n  $input: UpdateJuristicComapnyInput!\n) {\n  updateJuristicComapny(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6b7bbf6dc37b4c2348728e87de0e492d';

module.exports = node;
