import React, { Component } from 'react';
import $ from "jquery";
import './unitpdf.scss';
import numberWithCommas from '../../libs/numberWithComma'

class UnitReportpdf extends Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height();
            // let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            // let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head  + invoice );
            $('tbody tr:last-child td').css({paddingBottom: diff});
            
        }, 600);
    }

    render(){
        return(
            <div className="print-page-a4" id="unit-report">
                <div className="subpage">
                    <div className="head row">
                        <div className="col-2"></div>
                        <div className="col row">
                            <div className="col-12 d-flex justify-content-center"><span>{this.props.projectName}<br/></span></div>
                            <div className="col-12 d-flex justify-content-center"><span>รายงานยูนิตสมทบเรียกเก็บ {this.props.developerName} <br/></span></div>
                            {this.props.startDate !== '' && this.props.endDate !== '' &&
                                <div className="col-12 d-flex justify-content-center">
                                    <span>
                                        ตั้งต่วันที่ {this.props.startDate.toLocaleDateString('th-TH' , { year: 'numeric', month: 'long', day: 'numeric',})} - 
                                    {this.props.endDate.toLocaleDateString('th-TH' , { year: 'numeric', month: 'long', day: 'numeric'})}
                                    </span>
                                </div>
                            }
                        </div>
                        <div className="col-2 float-right">
                            <div className="logo">
                                {this.props.projectLogo !== '' &&<img src={this.props.projectLogo} alt="silverman"/>}
                            </div>
                        </div>
                    </div>
                    <div className="invoice-body">
                        <table className="table table-bordered">
                            <thead className="thead-black" style={{backgroundColor:"#0f0f0f"}}>
                                <tr>
                                    {this.props.head_table.map((head,index) => {
                                        return (<th className= "text-center" width={index <= 3 ? 100 : index > 3 ? 200 : ''} key={index}><strong>{head.label}</strong></th>)
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.unitReportList.map((transaction,index)=>{
                                    return(
                                        <tr key={index} >
                                            <td className="text-center">{this.props.order + index + 1}</td>
                                            <td className="text-center">{transaction.plan}</td>
                                            <td className="text-center">{transaction.roomNo}</td>
                                            <td className="text-center">{transaction.size}</td>
                                            {transaction.productAndService.map((product,index) => {
                                                return <td className ="text-right" key={index}>{numberWithCommas(product)}</td>
                                            })
                                            }
                                            <td className="text-right">{numberWithCommas(transaction.total)}</td>
                                        </tr>
                                    );
                                })
                                }
                                <tr style={{borderBottom : !this.props.lastPage ? '1px solid #212529' : ''}}>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                </tr>
                            </tbody>
                            {this.props.lastPage &&
                            <tfoot>
                                <tr>
                                    <td colSpan={4}className="text-right text-dark"><strong>ยอดรวม (บาท)</strong></td>
                                    <td className="text-right text-dark"><strong>{numberWithCommas(parseFloat(this.props.state.totalProduct1))}</strong></td>
                                    <td className="text-right text-dark"><strong>{numberWithCommas(parseFloat(this.props.state.totalProduct2))}</strong></td>
                                    <td className="text-right text-dark"><strong>{numberWithCommas(parseFloat(this.props.state.totalProduct3))}</strong></td>
                                    <td className="text-right text-dark"><strong>{numberWithCommas(parseFloat(this.props.state.totalOther))}</strong></td>
                                    <td className="text-right text-dark"><strong>{numberWithCommas(parseFloat(this.props.state.totalUnit))}</strong></td>
                                    
                                </tr>
                            </tfoot> 
                            }
                        </table>
                    </div>
                </div>
            </div>

        );
    }


}
export default UnitReportpdf;