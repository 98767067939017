import React from "react";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Wrapper from "../../components/wrapper";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { commitMutation, QueryRenderer } from "react-relay";
import { format, subMonths } from "date-fns";
import Pagination from "../../libs/newPagination";
import ComponentPagination from "../../libs/componentPagination";
import Swal from "sweetalert2";
import ContactTopMenuNavigation from "../contactTopMenuNavigation";
import _ from "lodash";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import Loading from "../../libs/loading";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import i18next from "i18next";

const query = graphql`
  query EDMListQuery ($emailMarketingId: String, $startDate: DateTime,$endDate: DateTime, $first: Int, $last: Int ) {
    allEmailMarketing(emailMarketingId:$emailMarketingId, startDate:$startDate, endDate:$endDate, first: $first, last: $last){
        totalCount
        edges{
            node{
                id
                title
                detail
                previewImage
                status
                scheduleTime
                emailMarketingImage{
                  edges{
                    node{
                      id
                      fileUpload
                    }
                  }
                }
            }
        }
    }
}
`;

const mutation = graphql`
  mutation EDMListMutation ($input: EmailMarketingJuristicInput!) {
    emailMarketingJuristic (input: $input) {
        ok
        message
    }
  }
`;


class EDMList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.order = "-updated";
    this.state.search = "";
    this.state.search_input = "";
    this.state.loading = false;
    this.state.reQuery = false;
    this.state.start_date = subMonths(new Date(), 1);
    this.state.end_date = new Date();
    this.state.temp_start_date = subMonths(new Date(), 1);
    this.state.temp_end_date = new Date();
    this.state.news_type = props.match.params.news_type;
    this.state.checkList = []
    this.state.check_all = false
    this.state.inputFiles = {}
    this.state.inputFilesName = ""

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.confirmApproveToSend = this.confirmApproveToSend.bind(this)
    this.onSelected = this.onSelected.bind(this)
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  onSelected(dataSelect) {
    let selected = _.cloneDeep(this.state.checkList)
    let findIndex = selected.findIndex(item => item === dataSelect)
    let check_all = false
    if (findIndex < 0) {
      selected.push(dataSelect)
    } else {
      selected.splice(findIndex, 1)
      check_all = false
    }
    this.setState({ checkList: selected, check_all: check_all })
  }

  onSelectedAll(e, data) {
    let { name, value, checked } = e.target
    let selected = _.cloneDeep(this.state.checkList)
    let checkedSelected = []
    if (checked) {
      data.map((item) => {
        checkedSelected.push(item.node.id)
        document.getElementById("SelectedS_" + item.node.id).checked = true
      })
    }
    else {
      checkedSelected = []
      data.map((item) => {
        document.getElementById("SelectedS_" + item.node.id).checked = false
      })
    }
    this.setState({ checkList: checkedSelected, check_all: checked })
  }

  confirmApproveToSend() {
    if (this.state.checkList.length > 0) {
      Swal.fire({
        title: i18next.t("edmList:Please verify your identity to use the EDM system."),
        type: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        html:
          '<div class="row  text-left">' +
          '<div class="col-12 mt-3"><label class="headerLabel18 text-center">' + i18next.t("edmList:Please enter the password to confirm your identity.") + '</label></div>' +
          '<div class="col-12 mt-3"><input type="text" id="password" class="form-control" /></div>' +
          '</div>',
        preConfirm: () => ({
          passwordConfirm: document.getElementById("password").value,
        }),
      }).then((c) => {
        if (c.value.passwordConfirm === "f3HYpp5,y%66TLu?") {
          let uploadables = {
            "excel_import": this.state.inputFiles
          };

          let variables = {
            "input": {
              "emailMarketing": {
                "idList": this.state.checkList
              }
            }
          };
          commitMutation(
            environment,
            {
              mutation,
              variables,
              uploadables,
              onCompleted: (response) => {
                this.setState({ loading: false });
                if (response?.emailMarketingJuristic?.ok) {
                  Swal.fire(i18next.t("edmList:Submission successful."), '', 'success').then(() => {
                    this.setState({ new: false });
                    this.getData()
                  });
                } else {
                  Swal.fire(i18next.t("edmList:Submission unsuccessful."), i18next.t("Allaction:Please check again"), 'warning');
                }
              },
              onError: (err) => {
                this.setState({ loading: false });
                Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'error');
              },
            },
          )
        } else {
          this.setState({ savingData: false })
          Swal.fire(i18next.t("edmList:Incorrect password"), i18next.t("edmList:Confirmation of approval for submission unsuccessful."), 'error')
        }
      })
    } else {
      Swal.fire(i18next.t("edmList:No items selected yet."), i18next.t("edmList:Apologies, you have not selected the item(s) to submit.Please try again."), "error")
    }
  }

  handleChangeFiles = async (e) => {
    let files = e.currentTarget.files[0];
    let name = e.currentTarget.files[0].name;

    this.setState({ inputFiles: files, inputFilesName: name })
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <ContactTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box" id="news" style={{ minHeight: '90vh' }}>
                <BackButtonIcon
                  addLinkChk={true}
                  addLinkButton={"/contact/EDM/Create"}
                  addLinkText={t("edmList:Create")}
                  LinkBack={"/contact"}
                  LinkText={t('edmList:EMD System')}
                />
                <Translation>
                  {t =>
                    <div className="content-inner">
                      <div className="row mb-2 mt-4">
                        <div className="col-4">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={this.confirmApproveToSend}
                          >
                            {t("Allaction:Confirm")}
                          </button>
                          <input style={{ marginLeft: '10px' }} type="file" id="customFile"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            name="inputFilesName" onChange={this.handleChangeFiles} />
                        </div>

                        <div className="col-5">
                          <div className="form-inline float-right">
                            <div className="form-group mb-2">
                              <label htmlFor="end_date" className="ml-3 mr-3">
                                {t("press_release:sort_by_date")}
                              </label>
                              <DatePickerAdapter
                                id="temp_start_date"
                                className="form-control"
                                name="temp_start_date"
                                selected={this.state.temp_start_date}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group mb-2">
                              <label htmlFor="end_date" className="ml-3 mr-3">
                                {t("press_release:to")}
                              </label>
                              <DatePickerAdapter
                                id="temp_end_date"
                                name="temp_end_date"
                                className="form-control"
                                selected={this.state.temp_end_date}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-3 input-group">
                          <input
                            type="text"
                            className="form-control search-input input-size col-8"
                            placeholder={t("press_release:search")}
                            name="search"
                            value={this.state.search_input}
                            onChange={(e) =>
                              this.setState({ search_input: e.target.value })
                            }
                            onKeyDown={this.handleKeyDown}
                          />
                          <button
                            type="submit"
                            className="btn btn-primary form-control search-button"
                            name="search"
                            value={this.state.search_input}
                            onClick={(e) => this.handleSearch(e)}
                          >
                            {t("press_release:search")}
                          </button>
                        </div>
                      </div>

                      <QueryRenderer
                        environment={environment}
                        query={query}
                        variables={{
                          search: this.state.search,
                          first: this.state.first,
                          last: this.state.last,
                          startDate: this.state.start_date,
                          endDate: this.state.end_date,
                        }}
                        cacheConfig={{ use_cache: false }}
                        render={({ error, props }) => {
                          if (error) {
                            return (
                              <div className="alert alert-danger" role="alert">
                                {error.message}
                              </div>
                            );
                          } else if (props) {
                            return (
                              <React.Fragment>
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table className="table table-hover">
                                      <thead className="thead-light">
                                        <tr>
                                          {this.state.news_type !== "deleted" && (
                                            <th width="50">
                                              <div className="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  id="checkAll"
                                                  className="custom-control-input"
                                                  onChange={(e) =>
                                                    this.onSelectedAll(e, props?.allEmailMarketing?.edges)
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="checkAll"
                                                />
                                              </div>
                                            </th>
                                          )}
                                          <th width="150" className="text-center">{t("press_release:date_posted")}</th>
                                          <th>{t("press_release:subject")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          props?.allEmailMarketing?.edges?.map((n, index_allEmailMarketing) => {
                                            return (
                                              <tr key={`SendEmail_EDM_${index_allEmailMarketing}`}>
                                                <td>
                                                  <input
                                                    id={`SelectedS_${n.node.id}`}
                                                    type={"checkbox"}
                                                    name="allEmailMarketing"
                                                    onChange={(e) => this.onSelected(n.node.id)} />
                                                </td>
                                                <td className="text-center">{format(n.node.scheduleTime, "DD/MM/YYYY")}</td>
                                                <td>{n.node.title}</td>
                                              </tr>
                                            )
                                          })
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="row">
                                  <Pagination
                                    changePage={this.changePage}
                                    first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props?.allEmailMarketing?.totalCount}
                                  />
                                </div>
                              </React.Fragment>
                            );
                          }
                          return <Loading />
                        }}
                      />
                    </div>
                  }
                </Translation>
              </div>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default EDMList;
