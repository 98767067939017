/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SettingAutomaticDebitDebtStatus = "CUSTOM" | "DUEDATE" | "%future added value";
export type SettingAutomaticDebitSetDateMethod = "END_OF_MONTH" | "THE_EIGHTEENTH" | "THE_EIGHTH" | "THE_ELEVENTH" | "THE_FIFTEENTH" | "THE_FIFTH" | "THE_FIRST" | "THE_FOURTEENTH" | "THE_FOURTH" | "THE_NINETEENTH" | "THE_NINTH" | "THE_SECOND" | "THE_SEVENTEENTH" | "THE_SEVENTH" | "THE_SIXTEENTH" | "THE_SIXTH" | "THE_TENTH" | "THE_THIRD" | "THE_THIRTEENTH" | "THE_TWELFTH" | "THE_TWENTIETH" | "THE_TWENTY_EIGHTH" | "THE_TWENTY_FIFTH" | "THE_TWENTY_FIRST" | "THE_TWENTY_FOURTH" | "THE_TWENTY_SECOND" | "THE_TWENTY_SEVENTH" | "THE_TWENTY_SIXTH" | "THE_TWENTY_THIRD" | "%future added value";
export type SettingAutomaticDebitSetMethod = "CUSTOM" | "DATE" | "DUEDATE" | "%future added value";
export type settingAutomaticQueryVariables = {||};
export type settingAutomaticQueryResponse = {|
  +allSettingAutomaticDebit: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +automaticStatus: boolean,
        +setMethod: SettingAutomaticDebitSetMethod,
        +setDateMethod: ?SettingAutomaticDebitSetDateMethod,
        +setDuedateMethod: ?number,
        +setCustomMethod1: ?number,
        +setCustomMethod2: ?number,
        +setCustomMethod3: ?number,
        +setCustomMethod4: ?number,
        +debtStatus: SettingAutomaticDebitDebtStatus,
        +receivableOutstanding: boolean,
        +statusUsing: ?boolean,
      |}
    |}>
  |}
|};
export type settingAutomaticQuery = {|
  variables: settingAutomaticQueryVariables,
  response: settingAutomaticQueryResponse,
|};
*/


/*
query settingAutomaticQuery {
  allSettingAutomaticDebit {
    edges {
      node {
        id
        automaticStatus
        setMethod
        setDateMethod
        setDuedateMethod
        setCustomMethod1
        setCustomMethod2
        setCustomMethod3
        setCustomMethod4
        debtStatus
        receivableOutstanding
        statusUsing
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SettingAutomaticDebitNodeConnection",
    "kind": "LinkedField",
    "name": "allSettingAutomaticDebit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingAutomaticDebitNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingAutomaticDebitNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "automaticStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "setMethod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "setDateMethod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "setDuedateMethod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "setCustomMethod1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "setCustomMethod2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "setCustomMethod3",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "setCustomMethod4",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "debtStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "receivableOutstanding",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "statusUsing",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingAutomaticQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingAutomaticQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8c01156f29abb38b237edff23f0e3510",
    "id": null,
    "metadata": {},
    "name": "settingAutomaticQuery",
    "operationKind": "query",
    "text": "query settingAutomaticQuery {\n  allSettingAutomaticDebit {\n    edges {\n      node {\n        id\n        automaticStatus\n        setMethod\n        setDateMethod\n        setDuedateMethod\n        setCustomMethod1\n        setCustomMethod2\n        setCustomMethod3\n        setCustomMethod4\n        debtStatus\n        receivableOutstanding\n        statusUsing\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b47d394abbd5646f6652f512d016c8d';

module.exports = node;
