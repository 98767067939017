

import React, { Component } from 'react';
import numberWithComma from "../libs/numberWithComma";
import Resizer from "react-image-file-resizer";
import Swal from 'sweetalert2';
import API from '../api/silverGraund2'
import { uploadFile } from "react-s3";
import _ from "lodash";
import './vms.scss'
import i18next from 'i18next';

class VMSPaymentCheck extends Component {

    constructor(props) {
        super(props)
        this.state = {
            slipImgUpload:"",
            email:'',
            loading:false,
            lang:this.props.location?.state?.lang ? true : false,
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.resizeImg = this.resizeImg.bind(this)
        this.resolveUpload = this.resolveUpload.bind(this);
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        this.setState(prevState => { return _.set(_.cloneDeep(prevState), event.target.name, value)});   
    }

    upload(keyPrefix, file){
            let config = {
                bucketName: "silverman-storage-backup",
                dirName: "silverman-storage/private" + keyPrefix,
                region: "ap-southeast-1",
                accessKeyId: "AKIA3OESFULNFF5B7P5Y",
                secretAccessKey: "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",
              };
              return  uploadFile(file, config);
    }
    resolveUpload(path, file) {
        return new Promise(async (resolve) => {
            let res =  await this.upload("/vms/" + path, file);
            resolve(res);
        });
    }
      

    resizeImg(file) {
        let resizeImg;
         const resizeFile = (file) =>
            new Promise((resolve) => {
                Resizer.imageFileResizer(
                file,
                450,
                450,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
                );
            });

           resizeImg =  resizeFile(file);

         return resizeImg
      }

    asyncCall2(idcardImg) {

        const allPromise = Promise.all([this.resolveUpload("image",idcardImg)]);

        allPromise.then(values => {
           // console.log(values); // [resolvedValue1, resolvedValue2]
          }).catch(error => {
            console.log(error); // rejectReason of any first rejected promise
          });
        return allPromise
      }
      
      
    async onSubmit(e) {
        e.preventDefault();
        this.setState({
            loading : true
        })
            try {
                    let new_file = new File([this.props.location.state.slipImg], `${'payment_' + new Date().getTime()}.jpeg`);
                    await this.asyncCall2(new_file).then((data) => {

                        if(data[0].result.ok){
                            let dese = "vms/image/" + new_file.name
                             this.setState({ 
                                 slipImgUpload: dese,
                              })
                        }
                    });
            
                    let data = {
                    "code" : this.props.location.state.codeData,
                    "refPayment":this.state.slipImgUpload,
                    "email":this.state.email ? this.state.email :""
                    }
                    
                    API.postPayment(data).then((res) => {
                        if(res.data){
                            if(res.data.statusMessage === "SUCCESS"){
                                Swal.fire(this.state.lang ? 'Save successfully' :'Paid', '', 'success')
                            }else{
                                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:You have already created the data."), 'error') 
                                this.setState({
                                    loading:false
                                }) 
                            }
                        }
                    }).catch(err => Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Saved Unsuccessful"), 'error'))

            }catch (err) {
                alert("err aaa",err)
            }

        // }       
    }

    render() {
        return (
            <>
                <div id="wrapper-content">
                    <div id='vms-wrapper'>
                        <div className='p-4 vms-payment'>
                           <div className='row'>
                                <div className='col'>
                                    <span className='span-blue'>{this.props.location.state.paymentData.typePerson && this.props.location.state.paymentData.typePerson}</span> 
                                    {this.props.location.state.paymentData.overtime > 0 ? <span className='span-red'> {this.state.lang ? 'Over Time' :'เกินเวลา'} </span> :'' }
                                    <p className='vms-payment-text pt-2'>{this.props.location.state.paymentData.carRegistration && this.props.location.state.paymentData.carRegistration}, 
                                    <span className="pl-2" style={{color:'#808080'}}>{this.props.location.state.paymentData.province && this.props.location.state.paymentData.province}</span></p>
                                    <div className='pt-2 text-gray'>
                                        <label style={{color:'#212830'}}><strong>{this.state.lang ? 'Conditions' :'ข้อกำหนดและเงื่อนไข'}</strong></label>
                                           <div>
                                                {
                                                    this.props.location.state.paymentData.condition ? this.props.location.state.paymentData.condition : ''
                                                }
                                         </div>
                                    </div>
                                </div>
                           </div>
                           <div>

                           </div>
                            <div className="row pt-6">
                                <div className='col text-center'>
                                    <div className='pt-4 payment-box'>
                                        <div style={{paddingTop:'5rem'}}>
                                            {/* <img src={process.env.PUBLIC_URL + '/images/setting-walkin/success.png'} />  */}
                                            <p>{this.state.lang ? 'Total Fee' :'รวมค่าที่จอดรถ'}</p>
                                        </div>
                                        <div className='pt-2'> 
                                            <h1> {numberWithComma(this.props.location?.price || 0, "0.00")} {this.state.lang ? 'Baht' :'บาท'}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-5'> 
                                <hr/>
                                <p className='pt-2'>{this.state.lang ? 'Please fill email to request an electronic receipt' :'กรุณากรอกอีเมล ในกรณีที่ต้องการขอใบเสร็จรับเงินแบบอิเล็คทรอนิกส์'}</p>
                                <p className='fw-bolder pt-2'><strong>{this.state.lang ? 'Email (optional)' :'อีเมล (ไม่ได้บังคับ)'}</strong></p>
                                <input type='text' className='form-control' name='email' placeholder={this.state.lang ? 'Email' :'อีเมล'}
                                      onChange={this.handleInputChange}
                                />
                            </div>

                            <div className='pt-4'>
                                <button onClick={this.onSubmit} disabled={this.state.loading}
                                className="btn btn-primary add mr-2" style={{width:'100%'}}>
                                    {this.state.lang ? 'Send' :'ส่ง'}
                                </button>
                            </div>
                        </div>
                    </div>
                  </div>
            </>
                
        );
    }
}

export default VMSPaymentCheck;
