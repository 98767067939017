/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HouseHolderType = "AGENT" | "OWNER" | "RENTER" | "RESIDENT" | "%future added value";
export type ResidentialConveyance = "ALREADY_TRANSFERRED" | "NOT_TRANSFERRED" | "%future added value";
export type contactViewerQueryQueryVariables = {||};
export type contactViewerQueryQueryResponse = {|
  +contactViewer: ?{|
    +allContact: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +refNumber: string,
          +name: string,
          +firstName: string,
          +lastName: string,
          +residential: ?{|
            +id: string,
            +conveyance: ResidentialConveyance,
            +size: number,
            +address: ?string,
            +ownershipRatio: ?number,
            +ownershipArea: ?number,
            +homeNumber: ?string,
            +villageBuilding: ?string,
            +villageNumber: ?string,
            +lane: ?string,
            +soi: ?string,
            +plan: ?string,
            +postalCode: ?string,
            +province: ?string,
            +district: ?string,
            +subDistrict: ?string,
            +mailingVillageBuilding: ?string,
            +mailingVillageNumber: ?string,
            +mailingLane: ?string,
            +mailingPostalCode: ?string,
            +mailingProvince: ?string,
            +mailingDistrict: ?string,
            +mailingSubDistrict: ?string,
            +residentialHouseholder: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +type: HouseHolderType,
                  +tenant: {|
                    +id: string,
                    +firstName: string,
                    +lastName: ?string,
                    +tenantStatus: ?string,
                  |},
                |}
              |}>
            |},
          |},
        |}
      |}>
    |}
  |},
  +allSettingDebsFreeCertification: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fee: boolean,
        +charge: ?number,
        +waiting: ?number,
        +lifetime: ?number,
      |}
    |}>
  |},
|};
export type contactViewerQueryQuery = {|
  variables: contactViewerQueryQueryVariables,
  response: contactViewerQueryQueryResponse,
|};
*/


/*
query contactViewerQueryQuery {
  contactViewer {
    allContact(typeOfContact: "residential", typeOfPayment: "receivable", isActive: true, order: "name") {
      edges {
        node {
          id
          refNumber
          name
          firstName
          lastName
          residential {
            id
            conveyance
            size
            address
            ownershipRatio
            ownershipArea
            homeNumber
            villageBuilding
            villageNumber
            lane
            soi
            plan
            postalCode
            province
            district
            subDistrict
            mailingVillageBuilding
            mailingVillageNumber
            mailingLane
            mailingPostalCode
            mailingProvince
            mailingDistrict
            mailingSubDistrict
            residentialHouseholder {
              edges {
                node {
                  id
                  type
                  tenant {
                    id
                    firstName
                    lastName
                    tenantStatus
                  }
                }
              }
            }
          }
        }
      }
    }
    id
  }
  allSettingDebsFreeCertification {
    edges {
      node {
        id
        fee
        charge
        waiting
        lifetime
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "isActive",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "order",
      "value": "name"
    },
    {
      "kind": "Literal",
      "name": "typeOfContact",
      "value": "residential"
    },
    {
      "kind": "Literal",
      "name": "typeOfPayment",
      "value": "receivable"
    }
  ],
  "concreteType": "ContactNodeConnection",
  "kind": "LinkedField",
  "name": "allContact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ResidentialNode",
              "kind": "LinkedField",
              "name": "residential",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "conveyance",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "size",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ownershipRatio",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ownershipArea",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "homeNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "villageBuilding",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "villageNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lane",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "soi",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "plan",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "postalCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "province",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "district",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subDistrict",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mailingVillageBuilding",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mailingVillageNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mailingLane",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mailingPostalCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mailingProvince",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mailingDistrict",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mailingSubDistrict",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "HouseHolderNodeConnection",
                  "kind": "LinkedField",
                  "name": "residentialHouseholder",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "HouseHolderNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "HouseHolderNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "type",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TenantNode",
                              "kind": "LinkedField",
                              "name": "tenant",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v1/*: any*/),
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "tenantStatus",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allContact(isActive:true,order:\"name\",typeOfContact:\"residential\",typeOfPayment:\"receivable\")"
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SettingDebsFreeCertificationNodeConnection",
  "kind": "LinkedField",
  "name": "allSettingDebsFreeCertification",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SettingDebsFreeCertificationNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SettingDebsFreeCertificationNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "charge",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "waiting",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lifetime",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "contactViewerQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactViewer",
        "kind": "LinkedField",
        "name": "contactViewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "contactViewerQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactViewer",
        "kind": "LinkedField",
        "name": "contactViewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "4627daf5c7b3f311af906c2466281e63",
    "id": null,
    "metadata": {},
    "name": "contactViewerQueryQuery",
    "operationKind": "query",
    "text": "query contactViewerQueryQuery {\n  contactViewer {\n    allContact(typeOfContact: \"residential\", typeOfPayment: \"receivable\", isActive: true, order: \"name\") {\n      edges {\n        node {\n          id\n          refNumber\n          name\n          firstName\n          lastName\n          residential {\n            id\n            conveyance\n            size\n            address\n            ownershipRatio\n            ownershipArea\n            homeNumber\n            villageBuilding\n            villageNumber\n            lane\n            soi\n            plan\n            postalCode\n            province\n            district\n            subDistrict\n            mailingVillageBuilding\n            mailingVillageNumber\n            mailingLane\n            mailingPostalCode\n            mailingProvince\n            mailingDistrict\n            mailingSubDistrict\n            residentialHouseholder {\n              edges {\n                node {\n                  id\n                  type\n                  tenant {\n                    id\n                    firstName\n                    lastName\n                    tenantStatus\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n  allSettingDebsFreeCertification {\n    edges {\n      node {\n        id\n        fee\n        charge\n        waiting\n        lifetime\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '399d137a47a04725273786866d171253';

module.exports = node;
