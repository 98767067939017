import React, { Component } from 'react'
import PrivateRoute from '../../libs/privateRoute';
// import DashboardPurchasing from './dashboardPurchasing' 
import NoMatch from '../../components/noMatch';
import { Route , Switch} from "react-router-dom"; 
import InsurList from './insurInfo/insurList';
import DetailInsur from './insurInfo/detailInsur';
import CreateInsur from './insurInfo/createInsur';
import EditInsuranceInfo from './insurInfo/Update/EditInfo';
import EditInsurancePolicy from './insurInfo/Update/EditPolicy';
import EditInsuranceBroker from './insurInfo/Update/EditBroker';
import EditInsuranceCoInsurrance from './insurInfo/Update/EditInsurrance';


class Insurance extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path={`${this.props.match.url}/`} component={InsurList}/> 
        <PrivateRoute exact path={`${this.props.match.url}/create/`} component={CreateInsur}/> 
        <PrivateRoute exact path={`${this.props.match.url}/insur-edit-info/:id`}  component={EditInsuranceInfo}/>
        <PrivateRoute exact path={`${this.props.match.url}/insur-edit-policy/:id`}  component={EditInsurancePolicy}/>
        <PrivateRoute exact path={`${this.props.match.url}/insur-edit-insurance/:id`}  component={EditInsuranceCoInsurrance}/>
        <PrivateRoute exact path={`${this.props.match.url}/insur-edit-broker/:id`}  component={EditInsuranceBroker}/>
        <PrivateRoute exact path={`${this.props.match.url}/insur-detail/:id?/:status?`} component={DetailInsur}/>

        {/* <PrivateRoute exact path={`${this.props.match.url}/claim`} component={ClaimList}/>  */}

        <Route component={NoMatch} />
      </Switch>
    )
  }
}
export default Insurance;
