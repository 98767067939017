import React, { Component } from 'react';
import Swal from "sweetalert2";
import i18n from "i18next";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { decode, encode } from 'base-64';
import Select from 'react-select';
import upload from '../../../libs/upload'
import format from 'date-fns/format';
import jwtDecode from "jwt-decode";
import DatePicker from "react-datepicker";
import APICliam from '../../../api/claim'
import _ from 'lodash';
import StatusCliam from './statusClaim';
import StatusView from '../../../components/statusView/indexStatusView';
import ClaimTopMenuNavigation from "../claimTopMenuNavigation";
import i18next from 'i18next';
class UpdateClaimRequset extends Component {

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
    }
    componentWillMount() {
        // this.getHomeAll()
        let id = this.props.match.params.id
        APICliam.GetCliamID(decode(id)).then((res) => {
            if (res.data.statusCode !== "9999") {
                this.setState({ data: res.data.claim, loadingDetails: false, asset_claim: res.data.claim.asset_claim })
            }
        })
    }

    //UPLOADFILE
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/claim/file" + path, file);
            resolve(res);
        });
    };
    async uploadFile(e, file_type) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];

        if (files) {
            if (files?.size > 2097152) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }
            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .png, .jpg, .jpeg ${i18next.t("Allaction:Only")} ${i18next.t("Allaction:and each file should not exceed")} 2 MB (${i18next.t("Allaction:maximum of")} 3 ${i18next.t("Allaction:images")} )`, 'error')
            }
            else {
                let jwt = jwtDecode(localStorage.getItem("token"))
                let new_file = new File([files], `claim_web_${Math.floor(Math.random() * 1000000001)}${format(new Date(), jwt.user_id + "_YYYYMMDDHHmmss")}${files.name}`);
                let data_state = _.cloneDeep(this.state.data)
                if (file_type === "image") {
                    let data_state = _.cloneDeep(this.state.data)
                    let image_claim = data_state.image_claim
                    await this.callUpload('', new_file).then((data) => {
                        let dese = "claim/file/" + new_file.name
                        image_claim.push({ "id": "nosei", "file_name": new_file.name, "file_upload": dese, "file_type": file_type.toString(), "viewPtath": data.location, claim: this.props.match.params.id })
                        this.setState({ data: data_state })
                    });
                } else {
                    let file_claim = data_state.file_claim
                    await this.callUpload('', new_file).then((data) => {
                        let dese = "claim/file/" + new_file.name
                        file_claim.push({ "id": "nosei", "file_name": new_file.name, "file_upload": dese, "file_type": file_type.toString(), "viewPtath": data.location, claim: this.props.match.params.id })
                        this.setState({ data: data_state })
                    });
                }


            }
        }

    }
    // END UPLOAD


    async onChangeRequestBy(e) {
        await this.setState({ other_contact: '' })
        if (e.value === "other" || e.value === "0") {
            this.setState({ RequestBy: e.value, isRequestKey: true, phone_contact: "", other_contact: '', loadingContact: false })

        } else {
            let value = decode(e.value).split(":")
            let phone = e.phone ? e.phone : ""
            this.setState({ phone_contact: phone, RequestBy: value[1], isRequestKey: false, other_contact: '', loadingContact: false })
        }
    }

    deleteImage(data, index) {
        // let imageData = _.cloneDeep(this.state.image)
        // imageData.splice(index, 1)
        // this.setState({ image: imageData })
        let data_state = _.cloneDeep(this.state.data)
        const index_f = _.findIndex(data_state.image_claim, function (e) {
            return e.file_name == data.file_name;
        });

        let image_claim = data_state.image_claim[index_f]
        if (image_claim.id === 'nosei') {
            image_claim = data_state.image_claim
            image_claim.splice(index_f, 1);
            this.setState({ data: data_state })

        } else {
            image_claim.file_name = null
            _.set(data_state, 'image_claim[' + index_f + ']', image_claim)
            this.setState({ data: data_state })
        }


    }

    dateFile = (index, data) => {
        //Delete name Images
        let data_state = _.cloneDeep(this.state.data)
        //find index
        const index_f = _.findIndex(data_state.file_claim, function (e) {
            return e.file_name == data.file_name;
        });

        let file_claim = data_state.file_claim[index_f]
        if (file_claim.id === 'nosei') {
            file_claim = data_state.file_claim
            file_claim.splice(index_f, 1);

        } else {
            file_claim.file_name = null
            _.set(data_state, 'file_claim[' + index_f + ']', file_claim)
        }

        this.setState({ data: data_state })
    }

    constructor(props) {
        super(props);

        this.state = {
            loadingDetails: true,
            file: [],
            claim_type: 'private',
            residential: [],
            residential_id: null,

            tenant_contact: [],
            juristic_contact: [],
            tenant_contact_selct: true,

            tenant_contact_id: null,
            other_contact: "",
            phone_contact: "",
            description: "",
            issued_date: new Date(),
            issued_date: new Date(),
            deTailProperty: [],
            listDocumentMore: [
                { id: 1, idName: "uploadFile1", label: "Choose1", status: true },
                { id: 2, idName: "uploadFile2", label: "Choose2", status: true },
                { id: 3, idName: "uploadFile3", label: "Choose3", status: true },
                { id: 4, idName: "uploadFile4", label: "Choose4", status: true },
                { id: 5, idName: "uploadFile5", label: "Choose5", status: true },
                { id: 6, idName: "uploadFile6", label: "Choose6", status: true },
                { id: 7, idName: "uploadFile7", label: "Choose7", status: true },
                { id: 8, idName: "uploadFile8", label: "Choose8", status: true },
                { id: 9, idName: "uploadFile9", label: "Choose9", status: true },
            ],
            amountToClaim: null,
            data: {
                claim_detail: {},
                asset_claim: [],
                file_claim: [],
                image_claim: [],
            },
            btnSaveType: ""
        }
        this.uploadFile = this.uploadFile.bind(this)
        this.on_consider_fail = this.on_consider_fail.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.AddListAssets = this.AddListAssets.bind(this)
        this.removeListAssets = this.removeListAssets.bind(this)
    }

    AddListAssets() {
        let asset = _.cloneDeep(this.state.asset_claim)
        let assetFilter = asset.filter((n) => n.assets === "")
        if (asset.length === 0 || assetFilter.length < 1) {

            asset.push({ "assets": "", "description": "" })
            this.setState({ asset_claim: asset })
        }
    }
    removeListAssets(index, data) {

        let asset = _.cloneDeep(this.state.asset_claim)
        asset.splice(index, 1)
        this.setState({ asset_claim: asset })
    }
    //อัพเดท ข้อมูลในรายการตาราง
    onChangeTable(e, index) {
        let asset = _.cloneDeep(this.state.asset_claim)
        let name = e.target.name
        let value = e.target.value
        let data = _.set(asset, `[${index}].${name}`, value)
        this.setState({ asset_claim: data })
    }


    render() {
        let paseName = jwtDecode(localStorage.getItem("token"))

        let { claim_detail, asset_claim, file_claim, image_claim } = this.state.data
        
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                <ClaimTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            this.state.loadingDetails == false ?
                                <div className="container-fluid box claim" >

                                    <div className='create'>
                                        <div className="row justify-content-between">
                                            <div className="col">
                                                <h3 className="mb-4 mt-4">
                                                    <Link to="/purchasing/claim/all">
                                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                            alt="back" className="back" />
                                                    </Link>
                                                    <span style={{ fontWeight: '400' }}>{claim_detail?.doc_number || "แก้ไขงานเคลม"}</span>

                                                </h3>
                                            </div>
                                        </div>
                                        {
                                            <React.Fragment>
                                                <div className='row'>
                                                    <div className="col-10 mx-auto">
                                                        <form onSubmit={this.onSubmit}>
                                                            <div>
                                                                <br /><br />

                                                                <section className='HeaderForCommon'>
                                                                    <React.Fragment>
                                                                        <div className='statusClass' style={{ flexDirection: 'row', }} >
                                                                            <StatusCliam width={"66%"} left={"16%"} bottom={115} status={decode(this.props.match.params.status)} line={2} />
                                                                        </div>
                                                                        {/* <p style={{ fontSize: '18px' }}>เลือกประเภทผู้แจ้งเคลม   เพื่อที่นิติบุคคลจะได้จัดเก็บเป็นประวัติการแจ้งเคลมของโครงการ</p> */}
                                                                        <div style={{ display: 'inline' }}>

                                                                            {
                                                                                claim_detail?.claim_type === "private" ?
                                                                                    <React.Fragment>
                                                                                        <label className='labelInput'>
                                                                                            <input type={"radio"} name='claim_type' onChange={() => this.setState({ claim_type: "private" })}
                                                                                                checked={claim_detail?.claim_type === "private"} className='mr-2'
                                                                                                disabled={claim_detail?.status !== "draft"} />
                                                                                            {t("claimRequst:PrivateClaim")}</label>
                                                                                        <label style={{ marginLeft: "190px" }}></label>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    <label className='labelInput'>
                                                                                        <input type={"radio"} name='claim_type' onChange={() => this.setState({ claim_type: "public" })}
                                                                                            checked={claim_detail?.claim_type === "public"} className='mr-2'
                                                                                            disabled={claim_detail?.status !== "draft"} />
                                                                                        {t("claimRequst:CommonClaim")}</label>

                                                                            }




                                                                        </div>
                                                                    </React.Fragment>
                                                                </section>

                                                                <section className='GeneralInformation mt-5'>
                                                                    {claim_detail?.claim_type === "private" ?
                                                                        // แจ้งเคลมส่วนตัว
                                                                        <div className='GeneralInformation'>
                                                                            <h3>{t("claimRequst:GeneralInfo")}



                                                                                {/* Tab Status */}
                                                                                <StatusView
                                                                                    status={decode(this.props.match.params.status)}
                                                                                    typeForUse={"claim"}
                                                                                />
                                                                            </h3>
                                                                            <hr />
                                                                            <section className='findUnit'>
                                                                                <label>{t("claimRequst:FindUnit")} <span className='text-red'>*</span></label>
                                                                                <div style={{ position: 'relative' }}>
                                                                                    {
                                                                                        claim_detail?.status === "draft" ?
                                                                                            this.state.loadingDetails == false ?
                                                                                                <div>
                                                                                                    <Select
                                                                                                        classNamePrefix='inputBox'
                                                                                                        options={this.state.residential}
                                                                                                        onChange={this.onChageResidential}
                                                                                                        required={true}
                                                                                                        isSearchable={false}
                                                                                                        placeholder={t("claimRequst:FindUnit")}
                                                                                                    />
                                                                                                    <input name="residential_id" type="text" value={this.state.residential_id || ""}
                                                                                                        style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                                        onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                                        required={true} />
                                                                                                </div>
                                                                                                :
                                                                                                <Select
                                                                                                    isSearchable={false}
                                                                                                    placeholder={"Loading..."}
                                                                                                /> :
                                                                                            <input value={claim_detail?.residential.name}
                                                                                                className='form-control inputBox'
                                                                                                readOnly />
                                                                                    }

                                                                                </div>
                                                                            </section>

                                                                            <section className='findNameRequest'>
                                                                                <label>{t("claimRequst:Choose Who's Request")} <span className='text-red'>*</span></label>
                                                                                <div style={{ position: 'relative' }}>
                                                                                    {
                                                                                        claim_detail?.status === "draft" ?
                                                                                            this.state.loadingContact == false ?
                                                                                                <div>
                                                                                                    <Select
                                                                                                        classNamePrefix='inputBox'
                                                                                                        options={this.state.tenant_contact}
                                                                                                        onChange={this.onChageTenant}
                                                                                                        required={true}
                                                                                                        isSearchable={false}
                                                                                                        placeholder={t("claimRequst:Choose Who's Request")}
                                                                                                    />
                                                                                                    <input name="tenant_contact_id" type="text" value={this.state.tenant_contact_id || ""}
                                                                                                        style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                                        onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                                        required={true} />
                                                                                                </div>
                                                                                                :
                                                                                                <Select
                                                                                                    isSearchable={false}
                                                                                                    placeholder={"Loading..."}
                                                                                                />
                                                                                            :
                                                                                            <input value={claim_detail?.tenant_contact.first_name + " " + claim_detail?.tenant_contact.last_name}
                                                                                                className='form-control inputBox'
                                                                                                readOnly />
                                                                                    }
                                                                                </div>

                                                                                {
                                                                                    this.state.tenant_contact_selct === false &&
                                                                                    <input className='form-control'
                                                                                        type={"text"} name='' defaultValue={this.state.other_contact}
                                                                                        onChange={(e) => this.setState({ other_contact: e.target.value })}
                                                                                        onKeyUp={(e) => this.setState({ other_contact: e.target.value })} />
                                                                                }
                                                                            </section>

                                                                            <section className='phone_contract'>
                                                                                <label>{t("claimRequst:Phone")} </label>
                                                                                <input className='form-control inputBox'
                                                                                    type={"text"} name='' defaultValue={claim_detail?.phone_contact}
                                                                                    onChange={(e) => this.setState({ phone_contact: e.target.value })}
                                                                                    onKeyUp={(e) => this.setState({ phone_contact: e.target.value })}
                                                                                    onInvalid={e => e.target.setCustomValidity('โปรดกรอกตัวเลขเท่านั้น')}
                                                                                    onInput={e => e.target.setCustomValidity('')}
                                                                                    pattern='[0-9]+$'
                                                                                    readOnly />
                                                                            </section>
                                                                        </div>
                                                                        :
                                                                        //แจ้งเคลมส่วนกลาง
                                                                        <div className='GeneralInformation'>
                                                                            <h3>{t("claimRequst:GeneralInfo")}</h3>
                                                                            <hr />
                                                                            <section className='findUnit'>
                                                                                <label>{t("claimRequst:Choose Who's Request")} <span className='text-red'>*</span></label>
                                                                                <div style={{ position: 'relative' }}>
                                                                                    {
                                                                                        claim_detail?.status === 'draft' ?
                                                                                            this.state.loadingDetails == false ?
                                                                                                <div>
                                                                                                    <Select
                                                                                                        classNamePrefix='inputBox'
                                                                                                        // options={this.state.residential_id}
                                                                                                        // onChange={this.onChageSelectWarranty}
                                                                                                        required={true}
                                                                                                        isSearchable={false}
                                                                                                        placeholder={t("claimRequst:Choose Who's Request")}
                                                                                                    />
                                                                                                    <input name="residential_id" type="text" value={this.state.residential_id || ""}
                                                                                                        style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                                        onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                                        required={true} />
                                                                                                </div>
                                                                                                :
                                                                                                <Select
                                                                                                    isSearchable={false}
                                                                                                    placeholder={"Loading..."}
                                                                                                />

                                                                                            :
                                                                                            <input value={claim_detail?.juristic_contact.first_name + " " + claim_detail?.juristic_contact.last_name}
                                                                                                className='form-control inputBox'
                                                                                                readOnly />
                                                                                    }
                                                                                </div>
                                                                            </section>

                                                                            <section className='phone_contract'>
                                                                                <label>{t("claimRequst:Phone")} </label>
                                                                                <input className='form-control inputBox'
                                                                                    type={"text"} name='' defaultValue={claim_detail?.phone_contact}
                                                                                    onChange={(e) => this.setState({ phone_contact: e.target.value })}
                                                                                    onKeyUp={(e) => this.setState({ phone_contact: e.target.value })}
                                                                                    onInvalid={e => e.target.setCustomValidity('โปรดกรอกตัวเลขเท่านั้น')}
                                                                                    onInput={e => e.target.setCustomValidity('')}
                                                                                    pattern='[0-9]+$'
                                                                                    disabled={claim_detail?.status !== "draft"} />
                                                                            </section>
                                                                        </div>
                                                                    }
                                                                </section>


                                                                <div className='detailCliam'>
                                                                    <h3 className='mt-5'>{t("claimRequst:Claim Info")}</h3>
                                                                    <hr />

                                                                    <section className='header'>
                                                                        <label>{t("claimRequst:Claim Title")} <span className='text-red'>*</span></label>
                                                                        <input className='form-control inputBox'
                                                                            type={"text"} name='' defaultValue={claim_detail?.header}
                                                                            onChange={(e) => this.setState({ phone_contact: e.target.value })}
                                                                            onKeyUp={(e) => this.setState({ phone_contact: e.target.value })}
                                                                            disabled={claim_detail?.status !== "draft"} />
                                                                    </section>

                                                                    <section className='description'>
                                                                        <label>{t("claimRequst:Claim Detail")} <span className='text-red'></span></label>
                                                                        <textarea value={claim_detail?.description} className='form-control textareaBox'
                                                                            onChange={(e) => this.setState({ description: e.target.value })}
                                                                            onKeyDown={(e) => this.setState({ description: e.target.value })}
                                                                            disabled={claim_detail?.status !== "draft"} />

                                                                    </section>

                                                                    <section className='incident_date'>
                                                                        <label>{t("claimRequst:Incident Date")}<span className='text-red'>*</span></label>
                                                                        <br />
                                                                        <DatePicker
                                                                            name='incident_date'
                                                                            className="form-control inputBox"
                                                                            selected={new Date(claim_detail?.incident_date) || new Date()}
                                                                            maxDate={new Date()}
                                                                            onChange={(e) => this.setState({ incident_date: e })}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            placeholderText='dd/mm/yyyy'
                                                                            disabled={claim_detail?.status !== "draft"}
                                                                        />
                                                                    </section>

                                                                    <section className='issued_date'>
                                                                        <label>{t("claimRequst:Claim Request Date")}<span className='text-red'></span></label>
                                                                        <br />
                                                                        <DatePicker
                                                                            name='issued_dateDate'
                                                                            className="form-control inputBox"
                                                                            selected={new Date(claim_detail?.issued_date)}
                                                                            maxDate={new Date()}
                                                                            onChange={(e) => this.setState({ issued_date: e })}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            placeholderText='dd/mm/yyyy'
                                                                            readOnly={true}
                                                                            disabled={claim_detail?.status !== "draft"}
                                                                        />
                                                                    </section>

                                                                </div>

                                                                <div className='detailAsset'>
                                                                    <h3 className='mt-10'>{t("claimRequst:Assets Related")}</h3>
                                                                    <hr />

                                                                    <label className='label16 mt-2 mb-5' >{t("claimRequst:Lists Asset")} <span className='text-red'>*</span></label>

                                                                    <table className='table table-border'>
                                                                        <thead className='bg-gray'>
                                                                            <tr>
                                                                                <th style={{ width: '30%' }}>{t("claimRequst:Lists")}</th>
                                                                                <th style={{ width: '70%' }}>{t("claimRequst:Detail Asset")}</th>
                                                                                <th width={50}></th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>

                                                                            <React.Fragment>
                                                                                {
                                                                                    this.state.asset_claim?.length > 0 ?
                                                                                        this.state.asset_claim?.map((n, assetsIndex) => {
                                                                                            return (
                                                                                                <tr key={assetsIndex} className=''>
                                                                                                    <td><input type={"text"} required={true} name='assets' className='form-control' defaultValue={n.assets} onChange={(e) => this.onChangeTable(e, assetsIndex)} /></td>
                                                                                                    <td><input type={"text"} required={true} name='description' className='form-control' defaultValue={n.description} onChange={(e) => this.onChangeTable(e, assetsIndex)} /></td>
                                                                                                    <td className='text-center' onClick={() => this.removeListAssets(assetsIndex, n)}>
                                                                                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/delete.png"} />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        })

                                                                                        :
                                                                                        <tr className='text-center'>
                                                                                            <td colSpan={3}>{t("claimRequst:Detail Asset:The damaged property has not yet been identified.")}</td>
                                                                                        </tr>
                                                                                }
                                                                            </React.Fragment>
                                                                        </tbody>
                                                                    </table>
                                                                    <label className='cursor label16 mt-5' onClick={this.AddListAssets}>
                                                                        <span className='colorBlue'>
                                                                            + {t("claimRequst:Add Lists")}
                                                                        </span>
                                                                    </label>

                                                                </div>

                                                                <div className='UploadImage mt-10'>
                                                                    <label className='headerLabel18'>{t("claimRequst:Photo Assets Broken")} <span className='text-red'>*</span></label>
                                                                    <br />
                                                                    <div style={{ display: 'inline-flex', pointerEvents: this.state.file.length >= 10 ? 'none' : 'all' }}>
                                                                        <label htmlFor="UploadImage" className="upload-btn-wrapper"
                                                                        >
                                                                            <button style={{ fontWeight: 400 }}
                                                                                className={this.state.file.length >= 10 ? "btn width110 disabled" : "btn width110 line-lightgray"}>
                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/defualt.png'} />{t("claimRequst:Choose Photo")} </button>
                                                                            <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px', fontWeight: 300 }}>
                                                                            {t("claimRequst:Support")} </span>

                                                                            <input
                                                                                type="file" name='file' id="UploadImage"
                                                                                className='form-control uploadFile'
                                                                                placeholder='ระบุเบอร์โทร'
                                                                                accept='application/.pdf,.png,.jpg'
                                                                                onChange={(e) => this.uploadFile(e, "image")}
                                                                            />

                                                                        </label>
                                                                    </div>

                                                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                                        {
                                                                            image_claim?.map((n, inxx) => {
                                                                                return (
                                                                                    n.file_name &&
                                                                                    <div key={"image" + inxx}>
                                                                                        <img src={n.file_upload} style={{ width: 100, height: 100, marginRight: 10 }}
                                                                                            onError={({ currentTarget }) => {
                                                                                                currentTarget.onerror = null; // prevents looping
                                                                                                currentTarget.src = "/images/icons/defualt.png";
                                                                                            }}
                                                                                        />
                                                                                        <button className='btnDeleteImage' type='button' onClick={() => this.deleteImage(n, inxx)}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} style={{ width: 20, height: 20 }} />
                                                                                        </button>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </div>



                                                                </div>


                                                                <section className='UploadFile mt-10'>
                                                                    <label className='headerLabel28'>{t("claimRequst:Choose File")} <span className='text-red'></span></label>
                                                                    <hr /> <br />
                                                                    {
                                                                        this.state.listDocumentMore?.map((n, inx) => {
                                                                            let type = inx + 1
                                                                            return (
                                                                                <React.Fragment key={inx}>
                                                                                    <div key={inx} style={{ display: 'inline-flex', pointerEvents: this.state.file.length >= 10 ? 'none' : 'all' }}>
                                                                                        <label className='headerLabel18 w370'>{`${inx + 1}. ${t("claimRequst:"+n.label)}`} </label>
                                                                                        <label htmlFor={n.idName} className="upload-btn-wrapper">
                                                                                            <button className={this.state.file.length >= 10 ? "btn disabled" : "btn"}>
                                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />{t("silvermanguard:Choose File")} </button>
                                                                                            <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>
                                                                                            {t("claimRequst:Support")} </span>
                                                                                            <input
                                                                                                type="file" name='file' id={n.idName}
                                                                                                className='form-control uploadFile'
                                                                                                placeholder='ระบุเบอร์โทร'
                                                                                                accept='application/.pdf,.png,.jpg'
                                                                                                onChange={(e) => this.uploadFile(e, n.id)}
                                                                                            />
                                                                                        </label>
                                                                                    </div>
                                                                                    <br className='mb-2' />
                                                                                    {
                                                                                        file_claim?.filter((chk) => chk.file_type === type.toString()).map((file, inxw) => {
                                                                                            if (file.file_name) {
                                                                                                let typeFile = file.file_name.split(".")
                                                                                                return (
                                                                                                    <p key={inxw} style={{ marginTop: -20 }}>
                                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;
                                                                                                        <Link
                                                                                                            to="route"
                                                                                                            onClick={(event) => {
                                                                                                                event.preventDefault();
                                                                                                                window.open(
                                                                                                                    typeof file.file_upload === "string" || file.viewPtath
                                                                                                                        ? file.viewPtath ? file.viewPtath : file.file_upload
                                                                                                                        : URL.createObjectURL(file.viewPtath ? file.viewPtath : file.file_upload)
                                                                                                                )
                                                                                                            }}
                                                                                                            target="blank">{file.file_name}</Link>
                                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inxw, file)}>
                                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} style={{ width: 20, height: 20 }} />

                                                                                                        </label>
                                                                                                    </p>
                                                                                                )
                                                                                            }
                                                                                            return;
                                                                                        })
                                                                                    }
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                    }
                                                                </section>


                                                                <div className='ResultClaim mt-10'>
                                                                    <label className='headerLabel28'>{t("claimRequst:Claims Summary")}</label>
                                                                    <hr /> <br />
                                                                    <label className='headerLabel18'>{t("claimRequst:Amount To Claim")} <span className='text-red'> * </span> {t("claimRequst:(Bath)")}</label>
                                                                    <br />
                                                                    <input type={"number"} name='amountCliam'
                                                                        defaultValue={parseInt(claim_detail?.claim_amount).toFixed(2)}
                                                                        placeholder='0.00'
                                                                        className='form-control inputBox text-right' style={{ width: 222 }}
                                                                        onKeyUp={(e) => this.setState({ amountToClaim: e.target.value })}
                                                                        onChange={(e) => this.setState({ amountToClaim: e.target.value })}
                                                                        required={true} min={1}
                                                                    />
                                                                </div>


                                                            </div>
                                                            {
                                                            
                                                          _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'claim_create' }) && 
                                                            <div className='row mt-2'>
                                                                <div className='col-12 text-right'>
                                                                    <button type='button'
                                                                        onClick={this.on_consider_fail}
                                                                        className='btn btn-gray-outline m-2'>{t("claimRequst:reject")}</button>

                                                                    <button type='submit'
                                                                        onClick={() => this.setState({ btnSaveType: claim_detail.status })}
                                                                        className='btn btn-primary-outline m-2'>{t("claimRequst:Save")}</button>

                                                                    <button type='submit' className='btn btn-primary m-2'
                                                                        // onClick={() => this.props.history.push("")}
                                                                        onClick={() => this.setState({ btnSaveType: "pending_approve" })}
                                                                    >
                                                                        {t("claimRequst:Submit A claim and Save")}</button>
                                                                    {/* <button type='submit' className='btn btn-primary m-2'>{"บันทึก และส่งเคลมประกัน"}</button> */}
                                                                </div>
                                                            </div>}

                                                        </form>

                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }


                                    </div>
                                </div>

                                :
                                <></>

                        }
                    </Translation>
                </WrapperContent >
            </Wrapper >
        )
    }

    on_consider_fail() {
        Swal.fire({
            customClass: {
                title: 'swal2-title-custom',
                header: 'swal2-header-custom',
                container: 'swal2-container-custom',
                content: 'swal2-content-custom',
            },
            title: i18n.t("claimAction:PleaseEnterReasonToReject"),
            html:
                '<div class="row  text-left">' +
                '<div class="col-1 text-left mt-3"><input type="radio" id="notreach" name="statusDelete" checked /> </div><div class="col-11 mt-3">ยอดเคลมไม่ถึงขั้นต่ำมูลค่ารับผิดชอบส่วนแรก</div>' +
                '<div class="col-1 text-left mt-3"><input type="radio" id="expair" name="statusDelete"  /> </div><div class="col-11 mt-3">เกิดจากความเสื่อมสภาพการใช้งาน</div>' +
                '<div class="col-1 text-left mt-3"><input type="radio" id="otherStatusIs" name="statusDelete"  /> </div>' +
                '<div class="col-11 mt-3"><input type="text" id="otherStatusName" class="form-control" /></div>' +
                '</div>',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonText: i18n.t("claimAction:Reject"),
            preConfirm: () => ({
                expair: document.getElementById("expair").checked,
                notreach: document.getElementById("notreach").checked,
                otherStatusIs: document.getElementById("otherStatusIs").checked,
                otherStatusName: document.getElementById("otherStatusName").value,
            }),
        }).then((c) => {
            if (c.value) {
                // let data = {
                //     id: staff_id,
                //     endWork: format(new Date(), 'YYYY-MM-DD'),
                //     note: c.value.death ? "เสียชีวิต" : c.value.expair ? "หมดสัญญาจ้าง" : c.value.retire ? "เกษียณอายุ" : c.value.otherStatusName
                // }
                let data = {
                    "status": "consider_fail",
                    "void_remark": c.value.notreach ? i18n.t("claimAction:The claim amount does not reach the minimum of the first portion of liability.") 
                    : c.value.expair ? i18n.t("claimAction:Caused by deterioration in use") : c.value.otherStatusName
                }
                
                Swal.fire({
                    title: i18n.t("claimAction:Please wait while this is being done."),
                    type: 'info',
                    showLoaderOnConfirm: true,
                    showCloseButton: false,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,
                    allowOutsideClick: () => !Swal.isLoading(),
                }).then(() => {
                    APICliam.UpdateCliam(data, this.state.data.claim_detail.id).then((res) => {
                        if (res.data.statusCode === "0000") {
                            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                                this.props.history.push(`/purchasing/claim/summarize/${encode(this.state.data.claim_detail.id)}/${encode("consider_fail")}`)
                            })
                        } else {
                            Swal.fire(i18n.t('settingAccount:Failed to save', "", "warning"))
                        }
                    })
                })

                // CreateUpdateStaff(data, onSubmitSuccess, onSubmitError)
                // this.props.history.push("/project/staff/loading")
            }
        })

    }

    onSubmit(e) {
        e.preventDefault();
        if (this.state.btnSaveType === "pending_approve") {
            let data = {
                "status": "pending_approve",
                "claim_amount": this.state.amountToClaim !== null ? parseInt(this.state.amountToClaim) : this.state.data.claim_detail.claim_amount
            }
            Swal.fire({
                title: i18n.t("claimAction:Please wait while this is being done."),
                type: 'info',
                showLoaderOnConfirm: true,
                showCloseButton: false,
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
                allowOutsideClick: () => !Swal.isLoading(),
            }).then(() => {
                APICliam.UpdateCliam(data, this.state.data.claim_detail.id).then((res) => {
                    if (res.data.statusCode === "0000") {
                        Swal.fire(i18n.t('claimAction:save'), '', 'success').then(() => {
                            this.props.history.push(`/purchasing/claim/summarize/${encode(this.state.data.claim_detail.id)}/${encode("pending_approve")}`)
                        })
                    } else {
                        Swal.fire(i18n.t('settingAccount:Failed to save', "", "warning"))
                    }
                })
            })


        } else {
            // let setAmount = _.set(this.state.data.claim_detail , 'claim_amount' , this.state.amountToClaim )
            // this.setState({data : setAmount})
            let data = {}
            if (this.state.data.claim_detail.status !== "draft") {
                data = {
                    "file_claim": this.state.data.file_claim,
                    "claim_amount": this.state.amountToClaim,
                    "image_claim": this.state.data.image_claim,
                    "asset_claim": this.state.asset_claim,
                }
            }
            Swal.fire({
                title: i18n.t("claimAction:Please wait while this is being done."),
                type: 'info',
                showLoaderOnConfirm: true,
                showCloseButton: false,
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
                allowOutsideClick: () => !Swal.isLoading(),
            }).then(() => {
                APICliam.UpdateCliam(data, this.state.data.claim_detail.id).then((res) => {
                    if (res.data.statusCode === "0000") {
                        Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                            this.props.history.push(`/purchasing/claim/${this.state.data.claim_detail.status}`)
                        })
                    } else {
                        Swal.fire(i18n.t('settingAccount:Failed to save', "", "warning"))
                    }
                })
            })

        }




    }
}

export default UpdateClaimRequset;