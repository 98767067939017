import React, { Component } from 'react'
import _ from "lodash"
import {format, differenceInCalendarDays} from "date-fns";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { fetchQuery } from "react-relay";
import getNameResidential from "../../../../libs/getNameResidential";
import Swal from "sweetalert2";
import axios from 'axios';
import getApiRoot from "../../../../libs/getAPIRoot";
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const query = graphql`
  query individualReceiptReportExportsDataQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $endDateOnDate: Date
    $search: String
    $customerType: String
    $productAndService: String
    $productAndServiceID: ID
    $contact: String
    $overdueDate: String
    $rangeOverdueDate: [Int]
  ) {
    invoiceViewer {
      allInvoice(
        approveOnly: true
        receivableOutstanding: true
        first: $first
        last: $last
        startDate: $startDate
        endDate: $endDate
        search: $search
        customerType: $customerType
        productAndService: $productAndService
        # transaction_ProductAndService_Id: $productAndServiceID
        contact: $contact
        order: "contact__residential__name"
        suborder: "individual_report"
        status_In: "active, overdue, partial_payment, paid"
        overdueDate: $overdueDate
        rangeOverdueDate: $rangeOverdueDate
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            docNumber
            issuedDate
            dueDate
            contact {
              id
              name
              firstName
              lastName
              refNumber
              typeOfContact
              residential {
                id
                name
              }
            }
            transaction(productAndService_Id: $productAndServiceID) {
              edges {
                node {
                  id
                  description
                  whtRate
                  total
                  productAndService {
                    id
                    productCode
                    name
                  }
                  chartOfAccount {
                    chartOfAccountCode
                  }
                  receiveTransaction(receive_Status_In: "paid" , receive_IssuedDate_Lte:$endDateOnDate) {
                    edges {
                      node {
                        amount
                        added
                        receive {
                          issuedDate

                        }
                      }
                    }
                  }
                  creditNoteTransaction(status: "paid",issuedDate_Lte:$endDateOnDate, receive_Isnull:true ) {
                    edges {
                      node {
                        price
                        issuedDate
                        receive {
                          id
                        }
                      }
                    }
                  }
                  creditNoteTransactionRecord(status: "paid",creditNote_IssuedDate_Lte:$endDateOnDate){
                      edges{
                          node{
                              id
                              price
                              creditNote{
                                  id
                                  docNumber
                                  issuedDate
                                  status
                              }
                              price
                              status
                          }
                      }
                  }
                  receiptDepositTransactionUsed(receiveTransaction_Isnull:true) {
                    edges {
                      node {
                        amount
                        receiveTransaction {
                          id

                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        totalCount
      }
    }
    selfProject{
      name
    }
    contactViewer{
            allContact(typeOfPayment: "receivable",order: "ref_number"){
                edges{
                    node{
                        id
                        refNumber
                        name
                        firstName
                        lastName
                    }
                }
            }
    }
    productViewer{
            allProduct (type_In: "product, service, fine"){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        description
                        totalLeftInStock
                    }
                }
            }
      }
  }
`;
export default class IndividualReceiptReportExports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataAll: [],
      summaryReceivableOutstanding: "",
      summaryIndividualReceivableReport: "", 
      reportOutstandingReceivableSummary: "",
      summaryReceivableOutstandingLoading: false,
      contactList: "",
      productAndServiceList: "",
      loading: false,
      selfProjectName: "",
    }

    this.queryData = this.queryData.bind(this);
  }

  queryData = () => {
    this.setState({ 
      loading: true,
    },()=>this.props.handleLoadingBtn(this.state.loading))
    let variable = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      endDateOnDate: this.props.endDateOnDate,
      search: this.props.search,
      customerType: this.props.customerType,
      productAndService: this.props.productAndService,
      productAndServiceID: this.props.productAndServiceID,
      contact: this.props.contact,
      overdueDate: "",
      rangeOverdueDate: this.props.rangeOverdueDate,
      dueDate: this.props.dueDate,
    }
    fetchQuery(environment, query, variable).then(data => {
      this.setState({ 
        loading: false,           
        contactList: data.contactViewer.allContact.edges,
        productAndServiceList: data.productViewer.allProduct.edges,
        selfProjectName : data.selfProject.name
       },()=>this.getDataExcel(data))
    })
    .catch(error => {
      Swal.fire("Error!", "", "error"); 
    })
  }

  getContactList(contact) {
    let value = ""
    let listContact = []

    listContact = _.filter(this.state.contactList, (o) => o.node.id === contact)

    if (listContact.length > 0) {
      value = `${listContact[0].node?.refNumber} ${listContact[0].node?.name} ${listContact[0].node?.firstName} ${listContact[0].node?.lastName}`
    } else {
      value = "ทั้งหมด"
    }

    return value
  }

  getProductList(productAndService){
    let value = ""
    let listProductAndService = []

    listProductAndService = _.filter(this.state.productAndServiceList, (o) => o.node.id === productAndService)

    if (listProductAndService.length > 0) {
      value = `${listProductAndService[0].node?.productCode} ${listProductAndService[0].node?.name}`
    } else {
      value = "ทั้งหมด"
    }

    return value
  }
  

  downloadExcel = (dataAllForExcel) => {
    let token_id = localStorage.getItem("token");
    let dataExcel = dataAllForExcel;
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('total receipt');

    let columns = [
      { width: 20 },
      { width: 50 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 50 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    
    // Set column widths
    worksheet.columns = columns.map((col, index) => {
      return { width: col.width, key: String.fromCharCode(65 + index) };
    });

    // setBorder
    let borders = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }

    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let textLeft = { vertical: 'middle', horizontal: 'left' };
    let textRight = { vertical: 'middle', horizontal: 'right' };
    let fontBold = { size: 12, bold: true };
    
    let projectName = this.state.selfProjectName;
    let reportName = "รายงานลูกหนี้ค้างชำระรายบุคคล";
    let contactHeader = `เรียงตามชื่อ - ${this.getContactList(this.props.contact)}`;
    let productAndServiceHeader = `สินค้าและบริการ - ${this.getProductList(this.props.productAndService)}`;
    let asAtDate = "ณ วันที่ " + format(this.props.endDate, "DD-MM-YYYY");


    worksheet.getCell('A1').value = projectName;
    worksheet.getCell('A1').font = { size: 16, bold: true };
    
    worksheet.getCell('A2').value = reportName;
    worksheet.getCell('A2').font = { size: 16, bold: true };

    worksheet.getCell('A3').value = contactHeader;
    worksheet.getCell('A3').font = fontBold;

    worksheet.getCell('A4').value = productAndServiceHeader;
    worksheet.getCell('A4').font = fontBold;


    worksheet.getCell('A5').value = asAtDate
    worksheet.getCell('A5').font = fontBold;

    worksheet.addRow();
    let headerRow = worksheet.addRow();
    let headers = 
      this.props.contact ? 
      ['บ้านเลขที่/เลขที่ห้อง', 'ชื่อ', 'วันที่ออก','วันที่ครบกำหนด	', 'เลขที่ใบแจ้งหนี้', 'รหัส', 'รายละเอียด','วันที่เกินกำหนด (วัน)', 'จำนวนเงิน','ชำระแล้ว','คงเหลือ',]
        :
      ['บ้านเลขที่/เลขที่ห้อง', 'ชื่อ', 'วันที่ออก','วันที่ครบกำหนด	', 'เลขที่ใบแจ้งหนี้', 'รหัส', 'รายละเอียด','วันที่เกินกำหนด (วัน)', 'จำนวนเงิน','ชำระแล้ว','คงเหลือ','รวมค้างชำระ']

    let count = 1
    headers.forEach((item) => {
      let cell = headerRow.getCell(count);
      cell.value = item;
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter
      count += 1
    })
    
    for (let i = 0; i < dataExcel.length; i++) {
      let dataRow = worksheet.addRow();  
      dataExcel[i].forEach((item, index) => {
        let cell = dataRow.getCell(index + 1); 
        cell.value = item;
        cell.border = borders
        cell.alignment = (index === 1 || index === 5) ? 
          textLeft : 
          (index >= 6) ?
          textRight
            :
          textCenter      
      });
    }
    
    workbook.xlsx.writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let nameFile = reportName + asAtDate;
        let formData = new FormData();
        formData.append('file', blob , "report.xlsx");
        formData.append('type', "excel");
        formData.append('type_report_ref', "individual_receipt_report");
        axios.post(`${getApiRoot()}taskdownload/add_download`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `JWT ${token_id}`,
          }
        })
        .then(response => {
          console.log('File uploaded successfully:', response.data);
        })
        .catch(error => {
          console.error('There was an error sending the file to the server:', error);
        })
        .finally(() => {
          // Download the file after sending it to the API
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = nameFile;
          a.click();
        });

      });
  }

  getDataExcel = (data) => {
    this.props.handleLoadingBtn(this.state.loading)
    let dataAllForExcel = [];
    let dataRow = [];
    let allInvoiceData = data.invoiceViewer.allInvoice.edges
    let sum_totals = [0, 0, 0, 0, 0, 0, 0, 0];
    let sum_total_contact = 0;
    let outstandingBalanceOfCustomer = 0;
    let summaryAmoutBeforePaid = 0;
    let summaryAmountPaid = 0;

    let outstandingBalanceForThelastLine = 0;

    allInvoiceData.map((invoice, index) => {                    
      let first = true
      let invoice_select = null
      // let invoiceSelectNextItem = [];         
      let firstLineOfCustomer = false;
      let invoiceName = "";
      let firstName = "";
      let lastName = "";
      let sameNextItem = true;                                


      if(index > 0 && invoice.node.contact?.id !== allInvoiceData[index-1]?.node?.contact?.id){
        outstandingBalanceOfCustomer = 0;
          if(invoice.node.contact.typeOfContact === "RESIDENTIAL") {
              invoiceName = invoice.node.contact.residential.name
              firstName = invoice.node.contact.firstName
              lastName = invoice.node.contact.lastName
              firstLineOfCustomer = true
          } else if(invoice.node.contact.typeOfContact === "SUPPLIER") {
              invoiceName = invoice.node.contact.refNumber
              firstName = invoice.node.contact.name
              lastName = ""
              firstLineOfCustomer = true
          }

      } else if(index === 0){
          if(invoice.node.contact.typeOfContact === "RESIDENTIAL") {
              invoiceName = invoice.node.contact.residential.name
              firstName = invoice.node.contact.firstName
              lastName = invoice.node.contact.lastName
              firstLineOfCustomer = true
          } else if(invoice.node.contact.typeOfContact === "SUPPLIER") {
              invoiceName = invoice.node.contact.refNumber
              firstName = invoice.node.contact.name
              lastName = ""
              firstLineOfCustomer = true
          }
      }

      if(allInvoiceData[index+1] && invoice.node.contact?.id !== allInvoiceData[index+1]?.node?.contact?.id){                            
          sameNextItem = false                            
      } else if(!allInvoiceData[index+1]) {
          sameNextItem = false
      } else {
          sameNextItem = true
      }

      if (this.props.productAndService === "") {
          invoice_select = invoice.node.transaction.edges
      }
      else {
          invoice_select = invoice.node.transaction.edges.filter(element => {
              if(element.node.productAndService){
                  if(element.node.productAndService.id === this.props.productAndService){
                      return element
                  }
              }
          });

          // invoice_select ตัวถัดไป
          // invoiceSelectNextItem = allInvoiceData[index +1]?.node.transaction.edges.filter(element => {
          //     if(element.node.productAndService){
          //         if(element.node.productAndService.id === this.props.productAndService){
          //             return element
          //         }
          //     }
          // });

      }
      return (                          
          invoice_select.map((transaction, t_index) => {
              const amountOwed = -0.01 <this.getAmountOwed(transaction) && this.getAmountOwed(transaction) < 0.01 ? 0 : this.getAmountOwed(transaction) 
              const amountOwedWithComma = this.numberWithComma(amountOwed, "")
              const amountPaid = this.getAmountPaid(transaction)
              const amountPaidWithComma  = this.numberWithComma(amountPaid, "")                                                            
                  if (amountOwed !== 0) {                      
                      first = false

                      outstandingBalanceOfCustomer += amountOwed
                      

                      let total_contact = 0
                      sum_total_contact += amountOwed
                      // หา outstading balance customer โดยการเช็คว่า customer รายการล่าสุดกับรายการถัดไปเป็นตัวเดียวกันมั้ย
                      if(invoice.node.contact.id !== allInvoiceData[index +1]?.node.contact.id 
                          && allInvoiceData[index+1]
                          ){
                              outstandingBalanceForThelastLine = 0;
                      } else {
                          outstandingBalanceForThelastLine += amountOwed
                      }

                      if (!invoice.node.transaction.edges[t_index + 1]) {
                          total_contact = sum_total_contact
                          sum_total_contact = 0
                      }     
                      if (this.getNumberDay(invoice) < 1) {
                          sum_totals[0] += amountOwed
                      } else if (this.between(this.getNumberDay(invoice), 1, 30)) {
                          sum_totals[1] += amountOwed
                      } else if (this.between(this.getNumberDay(invoice), 31, 60)) {
                          sum_totals[2] += amountOwed
                      } else if (this.between(this.getNumberDay(invoice), 61, 90)) {
                          sum_totals[3] += amountOwed
                      } else if (this.between(this.getNumberDay(invoice), 91, 120)) {
                          sum_totals[4] += amountOwed
                      } else if (this.between(this.getNumberDay(invoice), 121, 150)) {
                          sum_totals[5] += amountOwed
                      } else if (this.between(this.getNumberDay(invoice), 151, 180)) {
                          sum_totals[6] += amountOwed
                      } else {
                          sum_totals[7] += amountOwed
                      }                        
                      summaryAmoutBeforePaid += transaction.node.total
                      summaryAmountPaid += amountPaid

                      if(firstLineOfCustomer && t_index === 0){
                        dataRow.push(invoiceName)
                        dataRow.push(getNameResidential(firstName,lastName))
                        dataRow.push("")
                        dataRow.push("")
                        dataRow.push("")
                        dataRow.push("")
                        dataRow.push("")
                        dataRow.push("")
                        dataRow.push("")
                        dataRow.push("")
                        dataRow.push("")
                        dataRow.push("")
                        dataAllForExcel.push(dataRow)
                        dataRow = [];
                      }
                             
                      dataRow.push("")
                      dataRow.push("")
                      dataRow.push(format(invoice.node.issuedDate, 'DD/MM/YYYY'))
                      dataRow.push(format(invoice.node.dueDate, 'DD/MM/YYYY'))
                      dataRow.push(invoice.node.docNumber)
                      dataRow.push(transaction.node.productAndService ? transaction.node.productAndService?.productCode : (transaction.node.chartOfAccount && transaction.node.chartOfAccount?.chartOfAccountCode === '1131-12' || transaction.node.chartOfAccount?.chartOfAccountCode === '1131-10' ? 'S000' : ''))
                      dataRow.push(this.removeTrailingZeros(transaction.node.description))
                      dataRow.push(this.calOverdueDate(invoice.node.dueDate))
                      dataRow.push(this.numberWithComma(transaction.node.total, ""))
                      dataRow.push(amountPaidWithComma)
                      dataRow.push(amountOwedWithComma)
                      {!this.props.contact && dataRow.push(this.numberWithComma(outstandingBalanceOfCustomer, ""))}
                      dataAllForExcel.push(dataRow)
                      dataRow = [];

                  } else if(t_index === 0 && firstLineOfCustomer){
                    // กรณีที่ data ที่ query มามี transaction transsaction แรกมี AmountOwed เป็น 0 แต่ transaction อื่นมีปกติจะให้แถวแรกแสดงชื่อ
                    for (let index = 0; index < invoice_select.length; index++) {
                        const currentTransaction = invoice_select[index];                                            
                        let thisCustomerHaveAmountOwned = currentTransaction && this.getAmountOwed(currentTransaction)
                        if (thisCustomerHaveAmountOwned){
                          dataRow.push(invoiceName)
                          dataRow.push(getNameResidential(firstName,lastName))
                          dataRow.push("")
                          dataRow.push("")
                          dataRow.push("")
                          dataRow.push("")
                          dataRow.push("")
                          dataRow.push("")
                          dataRow.push("")
                          dataRow.push("")
                          dataRow.push("")
                          dataRow.push("")
                          dataAllForExcel.push(dataRow)
                          dataRow = [];
                          return
                        }
                    }
                }
          })
      )
    })
    dataRow.push("")
    dataRow.push("")
    dataRow.push("")
    dataRow.push("")
    dataRow.push("")
    dataRow.push("")
    dataRow.push("")
    dataRow.push("รวมทั้งหมด")
    dataRow.push(this.numberWithComma(summaryAmoutBeforePaid, ""))
    dataRow.push(this.numberWithComma(summaryAmountPaid, ""))
    dataRow.push(this.numberWithComma(sum_totals.reduce((a, b) => a + b, 0), ""))
    {!this.props.contact && dataRow.push(this.numberWithComma(sum_totals.reduce((a, b) => a + b, 0), ""))}

    dataAllForExcel.push(dataRow)      
    this.downloadExcel(dataAllForExcel)
  }

  removeTrailingZeros(description){
    if(description.includes("ค่าน้ำประปา")){
        description = description.replace(/ *\[[^)]*\] */g, " ")
    } else {
        description = description.replace(/ *\[[^)]*\] */g, " ")
    }
    return description
  }

  getAmountOwed(transaction) {

      var _this = this

      const sumRecive = transaction.node.receiveTransaction.edges.reduce((total, obj) => {
          if(differenceInCalendarDays(this.props.endDate, obj.node.receive.issuedDate) >= 0) {
              return total + obj.node.amount
          } else {
              return total
          }
      }, 0);

      const sumReceiptDeposit = transaction.node.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
          if (obj.node.receiveTransaction) {
              return total
          } else {
              return total + obj.node.amount
          }
      }, 0);

      const sumCreditNote = transaction.node.creditNoteTransaction.edges.reduce((total, obj) => {
          if(obj){
              if (obj.node.receive) {
                  return total
              }else if(differenceInCalendarDays(this.props.endDate, obj.node.issuedDate) >= 0){
                  return total + obj.node.price
              }else{
                  return total
              }
          }
      }, 0)

      const sumCreditNotev2 = transaction.node.creditNoteTransactionRecord.edges.reduce((total, obj) => {
          if(obj){
              if(differenceInCalendarDays(this.props.endDate, obj.node.creditNote.issuedDate) >= 0){
                  return total + obj.node.price
              }else{
                  return total
              }
          }
      }, 0)
      return parseFloat(transaction.node.total - transaction.node.whtRate - sumRecive - sumReceiptDeposit - sumCreditNote - sumCreditNotev2)
  }

  getAmountPaid(transaction) {

      var _this = this

      const sumRecive = transaction.node.receiveTransaction.edges.reduce((total, obj) => {
          if(differenceInCalendarDays(this.props.endDate, obj.node.receive.issuedDate) >= 0) {
              return total + obj.node.amount
          } else {
              return total
          }
      }, 0);

      const sumReceiptDeposit = transaction.node.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
          if (obj.node.receiveTransaction) {
              return total
          } else {
              return total + obj.node.amount
          }
      }, 0);

      const sumCreditNote = transaction.node.creditNoteTransaction.edges.reduce((total, obj) => {
          if(obj){
              if (obj.node.receive) {
                  return total
              }else if(differenceInCalendarDays(this.props.endDate, obj.node.issuedDate) >= 0){
                  return total + obj.node.price
              }else{
                  return total
              }
          }
      }, 0)

      const sumCreditNotev2 = transaction.node.creditNoteTransactionRecord.edges.reduce((total, obj) => {
          if(obj){
              if(differenceInCalendarDays(this.props.endDate, obj.node.creditNote.issuedDate) >= 0){
                  return total + obj.node.price
              }else{
                  return total
              }
          }
      }, 0)
      return parseFloat(-transaction.node.whtRate + sumRecive + sumReceiptDeposit + sumCreditNote + sumCreditNotev2)
  }

  getNumberDay(invoice) {
      if (differenceInCalendarDays(this.props.endDate, invoice.node.dueDate) < 0) {
          return 0
      } else {
          return differenceInCalendarDays(this.props.endDate, invoice.node.dueDate)
      }
  }

  between(value, min, max) {
      return value >= min && value <= max;
  }

  numberWithComma(amount, blank_sign = '-', digit = false, number_only = false) {

    let formatter = new Intl.NumberFormat('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    if (digit || digit === 0) {
        formatter = new Intl.NumberFormat('en', {
            minimumFractionDigits: digit,
            maximumFractionDigits: 2
        });
    }
    
    if (amount !== undefined && !isNaN(amount) && amount !== 0 && amount !== null) {
        if(amount < 0) {
            if(number_only) {
                return formatter.format(amount)
            }
        } else {
            return formatter.format(amount);
        }
    } else {
        return blank_sign;
    }
  };
  

  calOverdueDate(issueDate) {
    let overdueDate = Math.ceil((new Date() - new Date(issueDate)) / (1000 * 60 * 60 * 24))
    if(overdueDate < 0) {
        return ""
    }
    return overdueDate
}

  render() {   
    return (
      <a className="dropdown-item" target={"_blank"} onClick={this.queryData}>Excel</a>
    )
  }
}
