import _ from "lodash";

export function getTotalpayRecord(payRecored) {
  let preTaxAmount_pr_acn_pd = 0;
  let acnsums = 0;
  let sumpd = 0;
  if (payRecored.node.whtRatePercent) {
      payRecored.node.purchaseRecord.acceptCreditNoteTransaction.edges.forEach((acnsum) => {
          acnsums = + acnsum.node.price
      })
      if (_.find(payRecored.node.purchaseRecord.prepaidDepositTransactionUsed.edges, { node: { prepaidDepositTransaction: { whtRatePercent: null } } }) === undefined) {
          payRecored.node.purchaseRecord.prepaidDepositTransactionUsed.edges.forEach((pdsum) => {
              if (pdsum.node.prepaidDepositTransaction.whtRatePercent) {
                  sumpd = sumpd + Math.round((parseFloat(pdsum.node.amount) / (1 + parseFloat(pdsum.node.prepaidDepositTransaction.vat || 0) / 100) + Number.EPSILON) * 100) / 100;
              }
          })
      }
      preTaxAmount_pr_acn_pd = Math.round((parseFloat(payRecored.node.purchaseRecord.preTaxAmount - sumpd - acnsums)  + Number.EPSILON) * 100) / 100;
  }
  return preTaxAmount_pr_acn_pd;
}
