import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import RegisterDashboard from "./registerDashboard";
import Residential from "./residential";
import Vehicle from "./vehicle";
import KeyCard from "./keyCard";
import Pet from "./pet";
import Ownership from "./ownership";
import ResidentialHistory from "./residentialhistory"
import ForeignOwnership from './foreignOwnership/foreignOwnership';
import NoMatch from "../components/noMatch";

class Register extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={RegisterDashboard}/>
                <PrivateRoute path={`${this.props.match.url}/residential`} component={Residential}/>
                <PrivateRoute path={`${this.props.match.url}/vehicle`} component={Vehicle}/>
                <PrivateRoute path={`${this.props.match.url}/key-card`} component={KeyCard}/>
                <PrivateRoute path={`${this.props.match.url}/pet`} component={Pet}/>
                <PrivateRoute path={`${this.props.match.url}/ownership`} component={Ownership}/>
                <PrivateRoute path={`${this.props.match.url}/residentialhistory`} component={ResidentialHistory}/>
                <PrivateRoute path={`${this.props.match.url}/foreign-ownership`} component={ForeignOwnership}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Register;