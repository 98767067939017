import { graphql } from "babel-plugin-relay/macro";
import React, { Component } from "react";
import Swal from "sweetalert2";
import { commitMutation } from "react-relay";
import environment from "../../../env/environment";
import i18next from "i18next";

const mutation = graphql`
    mutation clearAdvanceChangeStatusMutation($input: UpdateClearAdvanceInput!){
        updateClearAdvance(input:$input){
            ok
            warningText
            newClearAdvance{
                id
            }
        }
    }
`;

class ClearAdvanceChangeStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };

        this.onChangeStatus = this.onChangeStatus.bind(this)
    }

    onChangeStatus() {

        Swal.fire({
            title: `${i18next.t("clearAdvanceCreate:Requesting to Cancel Petty Cash Clearance.")}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: i18next.t("Allaction:cancel"),
            confirmButtonText: i18next.t("Allaction:yes"),
        }).then(async (result) => {
            if (result.value) {

                let { value: void_remark } = await Swal.fire({
                    title: i18next.t("unknown_receive:Note"),
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: i18next.t("unknown_receive:Cancel"),
                    confirmButtonText: i18next.t("unknown_receive:Confirm"),
                    inputValidator: (value) => {
                        if (!value) {
                            return i18next.t("unknown_receive:Please enter the cancellation note!")
                        }
                    }
                });

                if (void_remark) {
                    this.setState({ loading: true });
                    let variables = {
                        input: {
                            clientMutationId: this.props.id,
                            status: this.props.status,
                            voidRemark: void_remark
                        }
                    };
                    commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            onCompleted: (response) => {
                                if (response.updateClearAdvance.ok) {
                                    Swal.fire(i18next.t("unknown_receive:Canceled successfully"), '', 'success').then(() => {
                                        this.setState({ loading: false });
                                        this.props.callBackChangeStatus()
                                    })
                                } else {
                                    Swal.fire(i18next.t("unknown_receive:Canceled unsuccessfully"), response.updateClearAdvance.warningText, 'warning');
                                    this.setState({ loading: false });
                                }
                            },
                            onError: (err) => {
                                Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                                this.setState({ loading: false });
                            },
                        },
                    );
                }

            } else {
                this.setState({ loading: false });
            }
        })
    }

    render() {
        return (
            <div className="col text-right">
                <button className="btn btn-danger add mr-3" onClick={this.onChangeStatus}>
                    {this.state.loading &&
                        <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                    {i18next.t("clearAdvanceCreate:Cancel Petty Cash Clearance.")}
                </button>
            </div>
        )
    }

}

export default ClearAdvanceChangeStatus;
