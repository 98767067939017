import '../styles/withholdingTax3Print.scss';
import React, {Component} from 'react';

class WithholdingAttachmentTax3Print extends Component {

    constructor(props) {
        super(props);
        this.state = {
            taxIdNumber: ["\u00a0", "\u00a0", "\u00a0", "\u00a0", "\u00a0", "\u00a0", "\u00a0", "\u00a0", "\u00a0", "\u00a0", "\u00a0", "\u00a0", "\u00a0"],
        };
    }

    render() {

        return (
            <div id="withholding-tax-3-print">
                <div className="print-page-withholding-a4">
                    <div className="subpage">
                        <div className="withholding-tax-body">
                            <div>
                                <span className="text-header">ใบแนบ <span className="text-header-3">ภ.ง.ด.3</span> เลขประจำตัวผู้เสียภาษีอากร (ของผู้มีหน้าที่หักภาษี ณ ที่จ่าย )</span>
                                <span className="ml-3 mr-3">
                                       {this.state.taxIdNumber.map((value, index) => {
                                           if (index === 1 || index === 5 || index === 10 || index === 12) {
                                               return (
                                                   <React.Fragment key={'taxNum' + index}>
                                                       <span>-</span>
                                                       <span className="border-id-number">{value}</span>
                                                   </React.Fragment>
                                               )
                                           }
                                           return (
                                               <span className="border-id-number"
                                                     key={'taxNum' + index}>{value}</span>
                                           )
                                       })}
                                   </span>
                                <span className="text-header mr-2">สาขาที่</span>
                                {["\u00a0", "\u00a0", "\u00a0", "\u00a0", "\u00a0"].map((value, index) => {
                                    return (
                                        <span key={'branchNum' + index}
                                              className="border-branch-number">{value}</span>
                                    )
                                })}
                            </div>
                            <div className="header-right">แผ่นที่..........ในจำนวน..........แผ่น</div>

                            <table className="table table-bordered mt-2">
                                <tbody>
                                <tr>
                                    <th rowSpan="3" className="text-header-center">ลำดับที่</th>
                                    <th colSpan="4" className="text-header-center" width={"20%"}>
                                        <span className="text-th-bold">เลขประจำตัวผู้เสียภาษีอากร</span>
                                        (ของผู้มีเงินได้)
                                    </th>
                                    <th colSpan="2" className="text-header-center">สาขาที่</th>
                                    <th colSpan="7" className="text-header-center">รายละเอียดเกี่ยวกับการจ่ายเงิน</th>
                                    <th colSpan="7" className="text-header-center" width="200">
                                        รวมเงินภาษีที่หักและ <br/>นำส่งในครั้งนี้
                                    </th>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <span className="text-th-bold">ชื่อผู้มีเงินได้</span> (ให้ระบุให้ชัดเจนว่าเป็น
                                        นาย นาง นางสาว หรือยศ)
                                    </td>
                                    <td rowSpan="2" className="text-header-center" width="100">วัน เดือน ปี ที่จ่าย</td>
                                    <td rowSpan="2" className="text-header-center text-th-number" width="200">
                                        <img src={process.env.PUBLIC_URL + '/images/print/number-one.png'}
                                             alt="correct-signal" className="number"/>
                                        <span className="text-th-bold"> ประเภทเงินได้</span><br/>
                                        (ถ้ามากกว่าหนึ่งประเภทให้กรอกเรียงลงไป)
                                    </td>
                                    <td rowSpan="2" className="text-header-center" width={50}>อัตราภาษี <br/>ร้อยละ</td>
                                    <td colSpan="4" rowSpan="2" className="text-header-center" width={350}>
                                        จำนวนเงินที่จ่ายแต่ละประเภท <br/>เฉพาะคนหนึ่งๆ ในครั้งนี้
                                    </td>
                                    <td colSpan="4" rowSpan="2" className="text-header-center" width="300">จำนวนเงิน</td>
                                    <td rowSpan="2" className="text-header-center text-land p-0">
                                        เงื่อนไข <img src={process.env.PUBLIC_URL + '/images/print/number-two.png'}
                                                      alt="correct-signal" className="number"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <span className="text-th-bold">ที่อยู่ของผู้มีเงินได้</span> (ให้ระบุเลขที่
                                        ตรอก/ซอย ถนน ตำ บล/แขวง อำ เภอ/เขต จังหวัด)
                                    </td>
                                </tr>

                                {[0, 1, 2, 3, 4, 5].map((index) => {
                                    let tax_id = ["\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0"];
                                    let branch_num = ["\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0"];
                                    let issued_date = "\u00a0";
                                    let description = "\u00a0";


                                    return (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td rowSpan="3" className="text-center align-top" width={"10px"}>{index + 1}</td>
                                                <td colSpan="4" className="no-border-bottom no-border-right">
                                                    {
                                                        tax_id.map((value, index) => {
                                                            if (index === 1 || index === 5 || index === 10 || index === 12) {
                                                                return (
                                                                    <React.Fragment key={'rowTaxNum' + index}>
                                                                        <span>-</span>
                                                                        <span
                                                                            className="border-id-number">{value}</span>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                            return (
                                                                <span className="border-id-number"
                                                                      key={'rowTaxNum' + index}>{value}</span>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td colSpan="2" className="text-header-center no-border-bottom">
                                                    {
                                                        branch_num.map((value, v_index) => {
                                                            return (
                                                                <span key={'rowBranchNum' + v_index}
                                                                      className="border-branch-number">{value}</span>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot text-center">{issued_date}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{description}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot text-center">{"\u00a0"}</p>
                                                </td>
                                                <td colSpan="3" className="no-border-bottom text-right">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom text-left" width={"2%"}>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td colSpan="3" className="no-border-bottom text-right">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom text-left" width={"2%"}>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom text-center"></td>
                                            </tr>
                                            <tr>
                                                <td width="180" className="no-border-bottom no-border-right">
                                                    <p className="border-dot">ชื่อ</p>
                                                </td>
                                                <td colSpan="5" className="no-border-bottom">
                                                    <p className="border-dot">ชื่อสกุล</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td colSpan="3" className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td colSpan="3" className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom"></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="6">
                                                    <p className="border-dot">ที่อยู่</p>
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td colSpan="3">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td colSpan="3">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}

                                <tr>
                                    <td colSpan="10" className="text-right"><span className="text-th-bold">รวม</span>ยอดเงินได้และภาษีที่นำส่ง (นำไปรวมกับ<span className="text-th-bold">ใบแนบ ภ.ง.ด.3</span> แผ่นอื่น (ถ้ามี))
                                    </td>
                                    <td className="text-right" colSpan="3"></td>
                                    <td className="text-left"></td>
                                    <td className="text-right" colSpan="3"></td>
                                    <td className="text-left"></td>
                                    <td/>
                                </tr>
                                <tr>
                                    <td colSpan="8">
                                        <div className="row mt-1">
                                            <div className="col">
                                                <i>(ให้กรอกลำดับที่ต่อเนื่องกันไปทุกแผ่นตามเงินได้แต่ละประเภท)</i></div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-1">
                                                <strong><u>หมายเหตุ</u></strong>
                                            </div>
                                            <img src={process.env.PUBLIC_URL + '/images/print/number-one.png'}
                                                 alt="correct-signal" className="number-foot ml-4"/>
                                            <div className="col">
                                                ให้ระบุว่าจ่ายเป็นค่าอะไร เช่น ค่าเช่าอาคาร ค่าสอบบัญชี ค่าทนายความ
                                                ค่าวิชาชีพของแพทย์
                                                ค่าก่อสร้าง รางวัล ส่วนลดหรือประโยชน์ใดๆ เนื่องจากการส่งเสริมการขาย
                                                รางวัลในการประกวด
                                                การแข่งขัน การชิงโชค ค่าจ้างแสดงภาพยนตร์ ร้องเพลงดนตรี ค่าจ้างทำของ
                                                ค่าจ้างโฆษณา ค่าขนส่งสินค้า ฯลฯ
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-1"/>
                                            <img src={process.env.PUBLIC_URL + '/images/print/number-two.png'}
                                                 alt="correct-signal" className="number-foot ml-4"/>
                                            <div className="col">
                                                เงื่อนไขการหักภาษีให้กรอกดังนี้
                                                <p className="mb-0 mt-1"><span className="ballot-box">&#9744;</span> หัก ณ ที่จ่าย กรอก 1</p>
                                                <p className="mb-0 mt-1"><span className="ballot-box">&#9744;</span> ออกให้ตลอดไป กรอก 2</p>
                                                <p className="mb-0 mt-1"><span className="ballot-box">&#9744;</span> ออกให้ครั้งเดียว กรอก 3</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td colSpan="11">
                                        <div className="row mt-5">
                                            <div className="col-3 div-seal pr-0">
                                                <div className="border-seal">
                                                    <span className="text-center">ประทับตรานิติบุคคล (ถ้ามี)</span>
                                                </div>
                                            </div>
                                            <div className="col-9 pl-0">
                                                <div className="row">
                                                    <div className="col text-center">
                                                        <p className="mb-2">ลงชื่อ..................................................................................ผู้จ่ายเงิน</p>
                                                        <p className="mb-2">(..........................................................................)</p>
                                                        <p className="mb-2">ตำแหน่ง........................................................................................</p>
                                                        <p className="mb-2">ยื่นวันที่.......................เดือน...................................พ.ศ....................</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div className="row mt-1">
                                <div className="col">
                                    <strong><i>สอบถามข้อมูลเพิ่มเติมได้ที่ศูนย์สารนิเทศสรรพากร RD Intelligence Center
                                        โทร. 1161</i></strong>
                                </div>
                                <div className="col text-right">
                                    <strong>พิมพ์ มี.ค. 2560</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithholdingAttachmentTax3Print;
