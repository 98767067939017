/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DebsFreeCertificationRequestStatus = "ACTIVE" | "APPROVE" | "DRAFT" | "EDIT" | "PAID" | "VOID" | "%future added value";
export type debtFreeRequestListQueryVariables = {|
  search?: ?string,
  status?: ?$ReadOnlyArray<?string>,
  first?: ?number,
  last?: ?number,
  startDate?: ?any,
  endDate?: ?any,
|};
export type debtFreeRequestListQueryResponse = {|
  +allDebsFreeCertificationRequest: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +added: any,
        +updated: any,
        +contact: {|
          +id: string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |},
        +requestingDate: ?any,
        +waitingDate: ?any,
        +approvalDate: ?any,
        +cancellationDate: ?any,
        +price: ?number,
        +tenant: {|
          +id: string,
          +firstName: string,
          +lastName: ?string,
        |},
        +phone: ?string,
        +addressContact: ?string,
        +address: ?string,
        +copyIdCard: boolean,
        +copyHouse: boolean,
        +copyApartment: boolean,
        +copySurnameChange: boolean,
        +otherDocument: boolean,
        +otherDocumentDescription: ?string,
        +attorney: boolean,
        +attorneyBook: ?string,
        +waterAndElectric: boolean,
        +startSuspend: ?any,
        +endSuspend: ?any,
        +transactionDebtType: ?string,
        +receive: ?{|
          +id: string,
          +docNumber: string,
        |},
        +otherReceive: ?{|
          +id: string,
          +docNumber: string,
        |},
        +status: DebsFreeCertificationRequestStatus,
        +debsFreeCertificationRequestDocument: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileUpload: string,
            |}
          |}>
        |},
        +noteEdit: ?string,
        +noteVoid: ?string,
        +creatorApprove: ?string,
        +creatorVoid: ?string,
        +creatorEdit: ?string,
        +editDate: ?any,
      |}
    |}>,
  |},
  +allSettingDebsFreeCertification: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fee: boolean,
        +charge: ?number,
        +waiting: ?number,
        +lifetime: ?number,
      |}
    |}>
  |},
|};
export type debtFreeRequestListQuery = {|
  variables: debtFreeRequestListQueryVariables,
  response: debtFreeRequestListQueryResponse,
|};
*/


/*
query debtFreeRequestListQuery(
  $search: String
  $status: [String]
  $first: Int
  $last: Int
  $startDate: DateTime
  $endDate: DateTime
) {
  allDebsFreeCertificationRequest(search: $search, status: $status, first: $first, last: $last, startDate: $startDate, endDate: $endDate, orderBy: "-doc_number") {
    totalCount
    edges {
      node {
        id
        docNumber
        added
        updated
        contact {
          id
          name
          firstName
          lastName
        }
        requestingDate
        waitingDate
        approvalDate
        cancellationDate
        price
        tenant {
          id
          firstName
          lastName
        }
        phone
        addressContact
        address
        copyIdCard
        copyHouse
        copyApartment
        copySurnameChange
        otherDocument
        otherDocumentDescription
        attorney
        attorneyBook
        waterAndElectric
        startSuspend
        endSuspend
        transactionDebtType
        receive {
          id
          docNumber
        }
        otherReceive {
          id
          docNumber
        }
        status
        debsFreeCertificationRequestDocument {
          edges {
            node {
              id
              fileUpload
            }
          }
        }
        noteEdit
        noteVoid
        creatorApprove
        creatorVoid
        creatorEdit
        editDate
      }
    }
  }
  allSettingDebsFreeCertification {
    edges {
      node {
        id
        fee
        charge
        waiting
        lifetime
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = [
  (v6/*: any*/),
  (v7/*: any*/)
],
v11 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": "-doc_number"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "DebsFreeCertificationRequestNodeConnection",
    "kind": "LinkedField",
    "name": "allDebsFreeCertificationRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DebsFreeCertificationRequestNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DebsFreeCertificationRequestNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requestingDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "waitingDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approvalDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cancellationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressContact",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copyIdCard",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copyHouse",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copyApartment",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copySurnameChange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "otherDocument",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "otherDocumentDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attorney",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attorneyBook",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "waterAndElectric",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startSuspend",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endSuspend",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transactionDebtType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReceiveNode",
                "kind": "LinkedField",
                "name": "receive",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OtherReceiveNode",
                "kind": "LinkedField",
                "name": "otherReceive",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DebsFreeCertificationRequestDocumentsNodeConnection",
                "kind": "LinkedField",
                "name": "debsFreeCertificationRequestDocument",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DebsFreeCertificationRequestDocumentsNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DebsFreeCertificationRequestDocumentsNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileUpload",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "noteEdit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "noteVoid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creatorApprove",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creatorVoid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creatorEdit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SettingDebsFreeCertificationNodeConnection",
    "kind": "LinkedField",
    "name": "allSettingDebsFreeCertification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingDebsFreeCertificationNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingDebsFreeCertificationNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fee",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "charge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "waiting",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lifetime",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "debtFreeRequestListQuery",
    "selections": (v11/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "debtFreeRequestListQuery",
    "selections": (v11/*: any*/)
  },
  "params": {
    "cacheID": "28bc77023d2bc96d2c279b2cb96d9fc9",
    "id": null,
    "metadata": {},
    "name": "debtFreeRequestListQuery",
    "operationKind": "query",
    "text": "query debtFreeRequestListQuery(\n  $search: String\n  $status: [String]\n  $first: Int\n  $last: Int\n  $startDate: DateTime\n  $endDate: DateTime\n) {\n  allDebsFreeCertificationRequest(search: $search, status: $status, first: $first, last: $last, startDate: $startDate, endDate: $endDate, orderBy: \"-doc_number\") {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        added\n        updated\n        contact {\n          id\n          name\n          firstName\n          lastName\n        }\n        requestingDate\n        waitingDate\n        approvalDate\n        cancellationDate\n        price\n        tenant {\n          id\n          firstName\n          lastName\n        }\n        phone\n        addressContact\n        address\n        copyIdCard\n        copyHouse\n        copyApartment\n        copySurnameChange\n        otherDocument\n        otherDocumentDescription\n        attorney\n        attorneyBook\n        waterAndElectric\n        startSuspend\n        endSuspend\n        transactionDebtType\n        receive {\n          id\n          docNumber\n        }\n        otherReceive {\n          id\n          docNumber\n        }\n        status\n        debsFreeCertificationRequestDocument {\n          edges {\n            node {\n              id\n              fileUpload\n            }\n          }\n        }\n        noteEdit\n        noteVoid\n        creatorApprove\n        creatorVoid\n        creatorEdit\n        editDate\n      }\n    }\n  }\n  allSettingDebsFreeCertification {\n    edges {\n      node {\n        id\n        fee\n        charge\n        waiting\n        lifetime\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9597338155559dd3eb9d82b7b5b63aab';

module.exports = node;
