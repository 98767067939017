/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type bankTransferCreateModalQueryVariables = {||};
export type bankTransferCreateModalQueryResponse = {|
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +branch: string,
          +status: boolean,
        |}
      |}>
    |}
  |}
|};
export type bankTransferCreateModalQuery = {|
  variables: bankTransferCreateModalQueryVariables,
  response: bankTransferCreateModalQueryResponse,
|};
*/


/*
query bankTransferCreateModalQuery {
  bankAccountViewer {
    allBankAccount(status: true) {
      edges {
        node {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          branch
          status
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "status",
      "value": true
    }
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "receivePayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "makePayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bankName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "branch",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allBankAccount(status:true)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "bankTransferCreateModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "bankTransferCreateModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22e708d26a291c38cb64a714f7f158b5",
    "id": null,
    "metadata": {},
    "name": "bankTransferCreateModalQuery",
    "operationKind": "query",
    "text": "query bankTransferCreateModalQuery {\n  bankAccountViewer {\n    allBankAccount(status: true) {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          branch\n          status\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5ac98acc511994320df23f6d9c7adb94';

module.exports = node;
