import React, { forwardRef } from "react";
import "../../styles/commonArea.scss";
import DatePicker from "react-datepicker";
import i18n from "i18next";
import { setHours, setMinutes } from "date-fns";

const TimeLimit = ({
  state: {
    limit_time_status,
    limit_time_reservation: {
      start_reservation_day,
      end_reservation_day,
      start_reservation_time,
      end_reservation_time,
    },
  },
  handleLimitTime,
  handleChange,
}) => {
  const ButtonDate = forwardRef(({ value, onClick, disabled, date }, ref) => (
    <button
      type="button"
      className="date form-control mt-2 booking-form"
      style={{ width: "100%" }}
      onClick={onClick}
      ref={ref}
      disabled={disabled}
    >
      {value}
      {!date && (
        <div
          className="calendars"
          style={{
            position: "absolute",
            right: 0,
            marginRight: 8,
          }}
        >
          <span
            style={{
              color: "#0382FA",
              fontFamily: "medium",
            }}
          >
            {i18n.t("newBookingSingha_Create_step1:Choose time")}
          </span>
        </div>
      )}
      {date && (
        <div
          className="calendars"
          style={{
            position: "absolute",
            right: 0,
            marginRight: 8,
          }}
        >
          <img
            className="icon"
            src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"}
            alt="calendars"
          />
        </div>
      )}
    </button>
  ));
  return (
    <div className="booking-form mb-3  mt-5">
      <div className="switch-languages-div">
        <h2>{i18n.t("newBookingSingha_Create_step1:Restrict the time for reservations")}</h2>
      </div>

      <div className="form-check mt-2">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="limit_time_status"
            id="limit_time_status1"
            value="true"
            defaultChecked={limit_time_status == "true"}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="limit_time_status1">
            <span className="regular">{i18n.t("newBookingSingha_Create_step1:Closed")}</span>
          </label>
        </div>
        <div className="mt-2">
          <input
            className="form-check-input"
            type="radio"
            name="limit_time_status"
            id="limit_time_status2"
            value="false"
            defaultChecked={limit_time_status == "false"}
            onChange={handleChange}
          />
          <label className="form-check-label " htmlFor="limit_time_status2">
            <span className="regular">{i18n.t("newBookingSingha_Create_step1:Restrict the time")}</span>
          </label>
        </div>
      </div>

      <div className="form-row mt-2 pl-3">
        <div className="col-4 customDatePickerWidth ">
          <h3>{i18n.t("newBookingSingha_Create_step1:Reservation Start Date")}</h3>

          <DatePicker
            disabled={limit_time_status == "true" ? true : false}
            className="form-control float-left colorborder"
            selected={start_reservation_day}
            onChange={(date) => handleLimitTime("start_reservation_day", date)}
            dateFormat="dd/MM/yyyy"
            customInput={
              <ButtonDate
                disabled={limit_time_status == "true" ? true : false}
                date
              />
            }
            maxDate={end_reservation_day}
          />
        </div>
        <div className="col-4 customDatePickerWidth">
        <h3>{i18n.t("newBookingSingha_Create_step1:Reservation end date")}</h3>
        <DatePicker
          disabled={limit_time_status == "true" ? true : false}
          className="form-control float-left colorborder"
          selected={end_reservation_day}
          onChange={(date) => handleLimitTime("end_reservation_day", date)}
          dateFormat="dd/MM/yyyy"
          customInput={
            <ButtonDate
              disabled={limit_time_status == "true" ? true : false}
              date
            />
          }
          minDate={start_reservation_day}
        />
        </div>
      </div>
      <div className="form-row mt-2 pl-3">
        <div className="col-4 customDatePickerWidth ">
          <h3>{i18n.t("newBookingSingha_Create_step1:Daily reservation hours")}</h3>
          <DatePicker
            selected={start_reservation_time}
            onChange={(date) => handleLimitTime("start_reservation_time", date)}
            disabled={limit_time_status == "true" ? true : false}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className="form-control float-left colorborder"
            customInput={
              <ButtonDate
                disabled={limit_time_status == "true" ? true : false}
              />
            }
            minTime={setHours(setMinutes(end_reservation_time, 0), 0)}
            maxTime={setHours(
              setMinutes(
                end_reservation_time,
                new Date(end_reservation_time).getMinutes()
              ),
              new Date(end_reservation_time).getHours()
            )}
          />
        </div>
        <div className="col-4 customDatePickerWidth">
          <h3 style={{color:'#FFFFFF'}} >-</h3>
          <DatePicker
            selected={end_reservation_time}
            onChange={(date) => handleLimitTime("end_reservation_time", date)}
            disabled={limit_time_status == "true" ? true : false}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className="form-control float-left colorborder"
            customInput={
              <ButtonDate
                disabled={limit_time_status == "true" ? true : false}
              />
            }
            minTime={setHours(
              setMinutes(
                start_reservation_time,
                new Date(start_reservation_time).getMinutes()
              ),
              new Date(start_reservation_time).getHours()
            )}
            maxTime={setHours(setMinutes(start_reservation_time, 59), 23)}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeLimit;
