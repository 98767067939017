import React, { Component } from 'react';
import Swal from "sweetalert2";
import i18n from "i18next";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import Navigation from './navigation'
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import _ from 'lodash';
import API from '../../../api/sing'
import API_silverGraund from '../../../api/silverGraund'
import { decode, encode } from 'base-64';
import Select from 'react-select';
import ReactPaginate from "react-paginate";
import ContactTopMenuNavigation from '../../contactTopMenuNavigation';
import Modalcancle from '../modal/cancle';
import Modalworksheet from '../modal/worksheet';
import Modalpayment from '../modal/payment';
import upload from '../../../libs/upload'
import format from 'date-fns/format';
import FixRequestStatusFast from "../subPage/statusFast"
import ModalTag from '../modal/tag';
import FixRequestWorksheetDetail from '../subPage/worksheetDetail';
import ModalworksheetUpdate from '../modal/worksheeUpdate';
import ExportfixRequestExcel from '../../../document/fix_request/fixRequestExcel';
import jwtDecode from "jwt-decode";
import i18next from 'i18next';
import ExportFixReportPDF from '../export/fixRepairPDF'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from 'relay-runtime';
import environment from '../../../env/environment';
import imageToBase64 from 'image-to-base64/browser';
import ModalReportRepairEditBeforePrint from '../modal/reportRepairEditBeforePrint';
import ExportFixReportPDFEn from '../export/fixRepairPDFen';


const query = graphql`
query fixRequestListForTenentQuery{
    selfProject{
        typeOfProject
        nameTh
        nameEn
        logo
        logoBase64
        address
        addressTh
        addressEn
        juristicContactNumber
    }
}
`;
class fixRequestListForTenent extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            optionAction: false,
            data: [],
            wroksheetView: [],
            allResidential: [],
            page: 1,
            pageAll: 0,
            pageSize: 10,
            totalCount: 0,
            index_first: 0,
            index_last: 10,
            optionSearch: [{ value: '', label: localStorage.getItem("language") === "th" ? 'ทั้งหมด' : "All" }, { value: 'S-Homecare', label: 'S-Homecare' }, { value: 'S KLAS(Juristic Person)', label: 'S KLAS(Juristic Person)' }],
            optionSearchDefault: [{ value: '', label: localStorage.getItem("language") === "th" ? 'ทั้งหมด' : "All" }, { value: 'Juristic Person', label: 'Juristic Person' }],
            searchTypePerson: "",
            searchBox: "",
            searchStartDate: "",
            searchEndDate: "",

            token: jwtDecode(window.localStorage.getItem('token')),
            listTag: [],
            loadingTagRepair: false,

            fileFinish: [],
            selfProject: {},
            checkBox: ''
        }
        this.viewMore = this.viewMore.bind(this)
        this.activeOther = this.activeOther.bind(this)
        this.onUpdateClick = this.onUpdateClick.bind(this)
        this.SearchChange = this.SearchChange.bind(this)
        this.SearchClick = this.SearchClick.bind(this)
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
        this.SearchChangeFilter = this.SearchChangeFilter.bind(this)
        this.removeTagFromRepairRoom = this.removeTagFromRepairRoom.bind(this)
        this.removeTagFromGlobal = this.removeTagFromGlobal.bind(this)
        this.functionReadData = this.functionReadData.bind(this)
        this.getStatusNoti = this.getStatusNoti.bind(this)
        this.readHistoryChat = this.readHistoryChat.bind(this)

    }

    async loadImageAsBase64(imgURL = '', type) {
        let imageB64 = "";

        const convertImage = async () => {
            await imageToBase64(imgURL)
                .then(
                    (response) => {
                        imageB64 = response
                        if (type === "logo") {
                            localStorage.setItem("logo", imageB64)
                        }
                    }
                )
                .catch(
                    (error) => {
                        console.log(`error fetching: ${imgURL}`)
                        console.log(error)
                    }
                )
        }

        await convertImage();

        return imageB64;
    };

    //เมื่อเข้า componant นี้ จะอัพเดทก่อนแค่ครั้งแรก
    async componentDidMount() {

        fetchQuery(environment, query).then(async (res) => {
            if (res?.selfProject) {
                let namePrint = jwtDecode(localStorage.getItem('token'))
                let checkboxNull = await this.loadImageAsBase64('/images/iconAction/checkboxNull.png')
                let checkboxTrue = await this.loadImageAsBase64('/images/iconAction/checkbox.png')
                let logo = res.selfProject.logoBase64 || localStorage.getItem("logo")
             
                let selfProject = _.cloneDeep(res.selfProject)
                _.set(selfProject, `logo`, logo)
                this.setState({ selfProject: selfProject, checkBox: checkboxNull, checkboxTrue, namePrint })
            }
        })
        await this.getListTag()
        await this.SearchClick()
    }



    // เปลี่ยนแท็บและอัพเดทข้อมูลใหม่
    async componentDidUpdate(prevProps) {
        let sta = this.props.match.params.tabName
        if (prevProps.match.params.tabName !== sta) {
            await this.setState({ loading: true });
            await this.getData(this.state.index_first, this.state.index_last);
        }
    }


    async SearchClick() {
        let getSearchFilter = JSON.parse(localStorage.getItem("filterReportFixRequest"))
        let setFilter = _.cloneDeep(getSearchFilter)

        let searchForTenent = {
            searchTypePerson: this.state.searchTypePerson,
            searchBox: this.state.searchBox,
            searchStartDate: this.state.searchStartDate,
            searchEndDate: this.state.searchEndDate,
        }
        let setData =
        {
            "searchForTenent": searchForTenent,
            "searchForCommon": setFilter?.searchForCommon || null,
            "searchForReportTenent": setFilter?.searchForReportTenent || null,
            "searchForReportCommon": setFilter?.searchForReportCommon || null,
        }

        await localStorage.setItem('filterReportFixRequest', JSON.stringify(setData))
        await this.getData(this.state.index_first, this.state.index_last)
    }

    async SearchChange(e) {

        if (e.target.name === "search") { this.setState({ searchBox: e.target.value }) }
        if (e.target.name === "startDate") { this.setState({ searchStartDate: e.target.value }) }
        if (e.target.name === "endDate") { this.setState({ searchEndDate: e.target.value }) }

        if (e.key === "Enter") {
            await this.SearchClick()
        }

    }
    async SearchChangeFilter(e) {
        await this.setState({ searchTypePerson: e.value })
    }
    getAllHome() {
        API_silverGraund.getHomeAll().then((res) => {
            if (res.data.allResidential) { }
        })
    }

    viewMore(index, worksheetId) {
        let status = _.cloneDeep(this.state.data)
        status.forEach(ele => ele.openCkh = false)
        status[index].openCkh = this.state.data[index].openCkh ? false : true;
        this.setState({ data: status })
    }
    activeOther(index) {
        let status = _.cloneDeep(this.state.data)
        status.forEach(ele => ele.activeOther = false)
        status[index].activeOther = this.state.data[index].activeOther ? false : true;
        this.setState({ data: status })
    }

    getData(index_f, index_l) {
        let seacrch = window.location.search
        const params = new URLSearchParams(seacrch)

        let filter = localStorage.getItem("filterReportFixRequest") ? JSON.parse(localStorage.getItem("filterReportFixRequest")) : []
        let date = new Date(this.state.searchEndDate)
        if (params.get("worksheetId")) {
            let datas = {
                id: parseInt(decode(params.get("worksheetId")))
            }
            API.getrepairs_room_viewBYId(datas).then((res) => {
                let data = []
                if (res.data.data) {
                    data = res.data.data
                    res.data.data.forEach((e, index) => {
                        _.set(data, '[' + index + ']openCkh', true);
                        _.set(data, '[' + index + ']activeOther', false);
                    })
                    this.setState({
                        loading: false, data: data,
                        totalCount: res.data.totalCount,
                        pageAll: res.data.pagesCount
                    })
                }
            }, () => Swal.fire({
                title: i18next.t("Allaction:Unsuccessful "),
                type: 'error',
                text: i18next.t("settingToolandConnect:Unable to connect to the API at this time."),
                showConfirmButton: true,
            }).then((e) => {
                if (e?.value) {
                    window.location.reload()
                    this.setState({ loading: false })
                }
            }))

        } else {
            let datas = {
                index_first: index_f,
                index_last: index_l,
                pageSize: this.state.pageSize,
                page: this.state.page,
                status: this.props.match.params.tabName,
                id: "",
                person_working: filter?.searchTypePerson || this.state.searchTypePerson,
                search: filter?.searchBox || this.state.searchBox,
                start_date: filter?.searchStartDate || this.state.searchStartDate,
                end_date: filter?.searchEndDate || this.state.searchEndDate,
                // end_date: filter?.searchEndDate || format(date.setDate(date.getDate() + 1), "YYYY-MM-DD"),
                repair_type: "private"
            }

            API.getrepairs_room_view(datas).then((res) => {
                let data = []
                if (res.data.data) {
                    data = res.data.data
                    res.data.data.forEach((e, index) => {
                        _.set(data, '[' + index + ']openCkh', false);
                        _.set(data, '[' + index + ']activeOther', false);
                    })

                    this.setState({
                        loading: false, data: data,
                        totalCount: res.data.totalCount,
                        pageAll: res.data.pagesCount
                    })
                    document.body.style.cursor = "auto";
                }
            }, () => Swal.fire({
                title: i18next.t("Allaction:Unsuccessful "),
                type: 'error',
                text: i18next.t("settingToolandConnect:Unable to connect to the API at this time."),
                showConfirmButton: true,
            }).then((e) => {
                if (e?.value) {
                    window.location.reload()
                    this.setState({ loading: false })
                }
            }))
        }
    }

    functionReadData(slip) {
        let file = JSON.parse(slip.replace(/\'/g, "\""))
        const fileSlip = file.map((n, i) => {
            let typeFiles = n.fileName.split(".");
            return (
                <p key={i}>
                    <img src={process.env.PUBLIC_URL + '/images/typeFiles/' + typeFiles[1] + 'Icon.png'} style={{ height: '30px' }} />
                    <Link
                        to="route"
                        onClick={(event) => {
                            event.preventDefault();
                            window.open(
                                typeof n.viewPtath === "string"
                                    ? n.viewPtath
                                    : URL.createObjectURL(n.viewPtath)
                            )
                        }}
                        target="blank">{n.fileName}</Link>
                </p>

            )

        })

        return fileSlip
        // let slip = []
        // if(file){
        // let slip =  JSON.parse(file)

        // }

    }

    onSubmit(e) {
        e.preventDefault();
        let idCode = this.state.account.length !== 0 ?
            this.state.account.settingParkingFeeAccountId : ""

    }

    handlePageClick = (event) => {
        let page = event.selected + 1;
        // page * last, (page + 1) * last

        let index_f = event.selected * this.state.pageSize
        let index_l = page * this.state.pageSize
        this.setState({ page: page })
        document.body.style.cursor = "wait";
        this.getData(index_f, index_l);
    };

    handlePageSizeChange(e) {
        let pageSizes = e.target.value
        this.setState({ pageSize: parseInt(pageSizes), index_last: pageSizes }, () => {
            if (parseInt(pageSizes) === this.state.pageSize) {
                document.body.style.cursor = "wait";
                this.getData(0, pageSizes);
            }
        })

    }
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/sing/file" + path, file);
            resolve(res);
        });
    };

    onUpdateClick(id) {
        let pageFor = this.props.match.params.for

        Swal.fire({
            title: i18n.t("FixRequestModal:Specify description before finishing work"),
            text: i18n.t("FixRequestModal:Please specify a description or attach evidence before finishing the work"),
            html:
                `<input type="text" id="swal-finishWork1" class="swal2-input" placeholder="${i18n.t('FixRequestModal:Specify your description here')}" />` +
                '<input type="file" id="swal-finishWork2" accept="application/.pdf,.png,.jpg"  style="width: 100%;">',
            focusConfirm: true,
            loaderHtml: (value) => {
                // console.log(value);
            },
            preConfirm: () => {
                if (document.getElementById('swal-finishWork1').value) {
                    return [
                        document.getElementById('swal-finishWork1').value,
                        document.getElementById('swal-finishWork2').files
                    ]
                } else {
                    Swal.showValidationMessage(i18n.t("FixRequestModal:Please specify a description or attach evidence before finishing the work"))
                }
            },
            showCancelButton: true,
            cancelButtonText: i18n.t("Allaction:cancel"),
            confirmButtonText: i18n.t("FixRequestModal:Finish this work"),
            showLoaderOnConfirm: true,
        }).then((value) => {
            if (value.value) {
                let refDescription = value.value[0];
                if (value.value[1].length !== 0) {
                    let files = value.value[1][0];
                    let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
                    this.callUpload('', new_file).then((data) => {
                        let refImageUpload = "sing/file/" + new_file.name
                        let refData = {
                            "id": id,
                            "refDescription": refDescription,
                            "refImageName": new_file.name,
                            "refImageUpload": refImageUpload,
                            "viewPtath": data.location
                        }
                        API.patchrepairs_room_update(refData).then((res) => {
                            if (res.data.statusMessage === "success") {
                                Swal.fire(i18n.t("Allaction:Updated successfully"), '', 'success').then(() => {
                                    this.getData(this.state.index_first, this.state.index_last)
                                })
                            }
                        })
                    });
                } else {
                    let refData = {
                        "id": id,
                        "refDescription": refDescription,
                        "refImageName": "",
                        "refImageUpload": ""
                    }
                    API.patchrepairs_room_update(refData).then((res) => {
                        if (res.data.statusMessage === "success") {
                            Swal.fire(i18n.t("Allaction:Updated successfully"), '', 'success').then(() => {
                                this.getData(this.state.index_first, this.state.index_last)
                            })
                        }
                    })
                }

            } else {
                Swal.fire(i18n.t("FixRequestModal:No closing of this work"), '', 'info').then(() => {
                    this.getData(this.state.index_first, this.state.index_last)
                })
            }
        })
    }

    getListTag() {
        API.getListTag().then((res) => {
            if (res.data.data) {
                this.setState({ listTag: res.data.data })
            }
        })
    }

    selectTag(TagId, RepairRoomID) {
        let data = {
            "tagGlobalId": TagId,
            "repairsRoomId": RepairRoomID
        }
        API.postTagToRepairRoom(data).then((res) => {
            this.getData(this.state.index_first, this.state.index_last);

        })
    }

    removeTagFromRepairRoom(TagRepairsRoomID, RepairRoomID) {
        let data = {
            "id": TagRepairsRoomID,
            "repairs_room_id": RepairRoomID
        }
        API.removeTagFromRepairRoom(data).then((res) => {
            this.getData(this.state.index_first, this.state.index_last);
        })
    }

    removeTagFromGlobal(TagId) {
        let data = {
            "id": TagId
        }
        API.removeTagFromGlobal(data).then((res) => {
            this.getListTag()
            this.getData(this.state.index_first, this.state.index_last);
        })
    }


    readHistoryChat(repairsRoomId64) {
        let data = {
            repairsRoomId64: repairsRoomId64
        }
        API.readChat(data);
    }

    getStatusNoti(data) {
        let result;
        if (data === 'null' || data === null) {
            result = 'new'
        } else if (data === 0) {
            result = ''
        } else {
            result = data
        }
        return result;
    }

    render() {
        let sta = this.props.match.params.tabName
        let forPage = this.props.match.params.for === "forcommon" ? "ส่วนกลาง" : 'ลูกบ้าน'
        const { data, num_record, loading, page, pages, last, startDate, endDate } = this.state;
        let items = []
        let lists = []
        let pageA = 0

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <ContactTopMenuNavigation mini={true} />
                    <Translation>
                        {
                            (t,lng) =>

                                this.state.loading == false ?
                                    <div className="container-fluid box fixRequestFast" >
                                        {/* BACK AND HEADER */}
                                        <div className="row justify-content-between">
                                            <div className="col">
                                                <h3 className="mb-4">
                                                    <Link to="/contact">
                                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                            alt="back" className="back" />
                                                    </Link>
                                                    <span style={{ fontWeight: '400' }}>{t("PageList:Fix Request Box")} {t("PageList:Tanant")+" "+"(PNT)"}</span>
                                                </h3>
                                            </div>
                                        </div>

                                        {/* BUTTON CREATE */}
                                        {
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'repairs_room_create' }) &&
                                            <section className='btnCreate'>
                                                {
                                                    this.props.match.params.tabName === "review" &&
                                                    <Link to={"/contact/fix-requests/fortenant/create"}>
                                                        <button className='btn btn-primary floatRight'>
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} /> {t("PageList:Create Fix Request / Problem")}</button>
                                                    </Link>
                                                }
                                            </section>
                                        }


                                        <section className='btnPrint'>
                                            {
                                                this.props.match.params.tabName !== "review" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'repairs_room_print' }) &&
                                                <div className="dropdown">
                                                    <button type="button" className="btn-primary-scale-outline floatRight dropdown-toggle" data-toggle="dropdown">
                                                        {t("PageList:Print")}
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item" target={"_blank"} href={"/contact/fix-requests/document/tenant/" + this.props.match.params.tabName}>PDF</a>

                                                        <ExportfixRequestExcel for={"tenant"}
                                                            status={this.props.match.params.tabName} data={data}
                                                            startDate={this.state.searchStartDate} endDate={this.state.searchEndDate} />
                                                    </div>
                                                </div>
                                            }
                                        </section>

                                        <Navigation pageFor={forPage} />

                                        {/* Filter And Search */}
                                        <div className='row filterSearch' >
                                            <div className='col-12 text-right' style={{ display: 'inline', zoom: "90%" }}>

                                                <div className='floatLeft'>
                                                    <label className='floatLeft labelInline'>{t("PageList:Sort")} : &nbsp;&nbsp;</label>
                                                    <div style={{ position: 'relative', textAlign: 'left' }}>
                                                        <Select
                                                            defaultValue={this.state.token?.site?.includes('sklasmanagement') ? this.state.optionSearch[0] : this.state.optionSearchDefault[0]}
                                                            classNamePrefix='inputBoxList'
                                                            options={this.state.token?.site?.includes('sklasmanagement') ? this.state.optionSearch : this.state.optionSearchDefault}
                                                            onChange={this.SearchChangeFilter}
                                                            required={true}
                                                            isSearchable={false}
                                                            placeholder={t("PageList:Sort")}
                                                        />
                                                        <input name="searchTypePerson" type="text" value={this.state.details}
                                                            style={{ position: "absolute", top: 0, left: 110, width: 5, zIndex: -1 }}
                                                            onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                            required={true} />
                                                    </div>

                                                </div>
                                                <div className='floatRight'>
                                                    <input type="text" name='search' className='form-control searchBox' placeholder={t("silvermanguard:search")}
                                                        onKeyUp={this.SearchChange} onChange={this.SearchChange} defaultValue={this.state.searchBox} />
                                                    <div style={{ position: 'absolute', top: '6px', right: '25px' }}>
                                                        <button className='btn' onClick={this.SearchClick} type="submit">
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} />
                                                        </button>
                                                    </div>

                                                </div>

                                                <div className='floatRight'>
                                                    <label className='floatLeft labelInline'>{t("silvermanguard:date")}</label>
                                                    <input type="date" name='startDate' className='floatLeft form-control searchDate'
                                                        placeholder='01/07/2021' onChange={this.SearchChange} defaultValue={this.state.searchStartDate} />

                                                    <label className='floatLeft labelInline'>{t("silvermanguard:dateTo")}</label>
                                                    <input type="date" name='endDate' className='floatLeft form-control searchDate'
                                                        placeholder='01/07/2021' onChange={this.SearchChange} defaultValue={this.state.searchEndDate} />

                                                </div>
                                            </div>
                                        </div>

                                        {/* LIST DATA */}
                                        <div className='row'>
                                            <div className="col-12">

                                                {
                                                    this.state.loading == false &&
                                                    data.map((n, index) => {
                                                        let wworksheet = n.worksheet[0]
                                                        return (
                                                            <div className=' card show mt-2' key={index}>
                                                                {/* {index + 1} */}
                                                                <div className="card-body p-4">
                                                                    <div className="row">
                                                                        <div className="col-md-2">
                                                                            <h6>{n.residential?.name} {n.residential?.is_user_activate && <div className="green-dot" />}
                                                                                {/* <span className="badge ml-2">
                                                                                        {this.getStatusNoti(n.numberReadFastinspect)}
                                                                                </span> */}
                                                                            </h6>
                                                                            {
                                                                                n.other_contact ?
                                                                                    n.other_contact :
                                                                                    n.tenant_contact?.name_title +
                                                                                    n.tenant_contact?.first_name + " " +
                                                                                    n.tenant_contact?.last_name
                                                                            } <br />
                                                                            <button className='btnprimary-blue' style={{ width: 'auto' }}>{t("PageList:Tenant")}</button>
                                                                        </div>

                                                                        <div className="col-md-7">
                                                                            <Link to={"/contact/fix-requests/chat/fortenant/" + encode(n.id) + "/" + encode(n.id + n.status)}
                                                                                onClick={() => this.readHistoryChat(encode(n.id + n.status))}>
                                                                                <h6 className="mt-1" >
                                                                                    {n.working_type}
                                                                                    <span className='badge ml-2'>
                                                                                        {this.getStatusNoti(n.numberReadFastinspect)}
                                                                                    </span>

                                                                                    {
                                                                                        n.insure_status === "have" &&
                                                                                        <button className='haveInsur ml-2 mr-2'>{t("PageList:Waranty")}</button>
                                                                                    }
                                                                                </h6>
                                                                            </Link>


                                                                            <div style={{ position: 'relative', display: 'inline-flex' }}>
                                                                                {
                                                                                    this.state.loadingTagRepair == false &&
                                                                                    n.tag_repairs_room?.map((tag, index) => {
                                                                                        return (
                                                                                            <React.Fragment key={index} >
                                                                                                <button className={n.status === "void" ? "tagBtnGray" : 'tagBtnBlue'}>
                                                                                                    {tag.tag_global.tag_name}
                                                                                                    {
                                                                                                        n.status !== "void" &&
                                                                                                        <span onClick={() => this.removeTagFromRepairRoom(tag.id, n.id)}>X</span>
                                                                                                    }


                                                                                                </button>
                                                                                            </React.Fragment>

                                                                                        )
                                                                                    })
                                                                                }

                                                                                {
                                                                                    n.status !== "finish" && n.status !== "void" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "repairs_room_create" }) ?
                                                                                        <div className="dropdown">
                                                                                            <button className="tagBtnGray dropdown-toggle" data-toggle="dropdown">+{t("PageList:Add Tag")}</button>
                                                                                            <ul className="dropdown-menu">
                                                                                                {
                                                                                                    this.state.listTag?.sort((a, b) => a.id - b.id).map((tagLi, index) => {
                                                                                                        return (
                                                                                                            <React.Fragment key={index} >
                                                                                                                {
                                                                                                                    tagLi.id > 3 ?
                                                                                                                        <li className='dropdown-item tagLi' onClick={() => this.selectTag(tagLi.id, n.id)}
                                                                                                                            style={{ cursor: 'pointer', borderBottom: '#BFBFBF 1px silid' }}>{tagLi.tag_name}
                                                                                                                            <span style={{ float: 'right' }} onClick={() => this.removeTagFromGlobal(tagLi.id)}>X</span></li>
                                                                                                                        :
                                                                                                                        <li key={index} style={{ cursor: 'pointer' }} className='dropdown-item tagLi'
                                                                                                                            onClick={() => this.selectTag(tagLi.id, n.id)}>{tagLi.tag_name}</li>
                                                                                                                }
                                                                                                            </React.Fragment>
                                                                                                        )

                                                                                                    })
                                                                                                }
                                                                                                <li className='dropdown-item' data-toggle="modal" data-target={".tagModal" + n.id} style={{ color: "#BFBFBF" }}>+ {t("PageList:Add Tag New")}</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                            </div>
                                                                            <br />
                                                                            <ModalTag
                                                                                //  indexID={index} 
                                                                                indexID={n.id}
                                                                            />
                                                                            <span className="grey-color">
                                                                                {
                                                                                    n.insure_status !== "have" &&
                                                                                        this.state.token?.site?.includes('sklasmanagement') ? "S KLAS(Juristic Person)" : 'Juristic Person'
                                                                                }
                                                                                {
                                                                                    n.insure_status === "have" &&
                                                                                    <React.Fragment>
                                                                                        S-Homecare : <b>{n.fast_job_code}</b> 
                                                                                        <img onClick={() => this.viewMore(index, "")}
                                                                                            style={{ float: 'right' }}
                                                                                            src={process.env.PUBLIC_URL + '/images/icons/arrowup.png'} />
                                                                                    </React.Fragment>

                                                                                }
                                                                            </span>

                                                                            <p></p>
                                                                            {
                                                                                (n.status === "review" && n.insure_status !== "have" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "repairs_room_edit" })) &&
                                                                                <React.Fragment>
                                                                                    <p><Link to={"/contact/fix-requests/fortenant/detail/" + n.id}>{t("PageList:Click Read More")}</Link></p>
                                                                                    <button data-toggle="modal" data-target={".worksheetFixRequset" + n.id} className='worksheetSS'>
                                                                                        +{t("PageList:Wroksheet")}
                                                                                    </button>
                                                                                </React.Fragment>


                                                                            }
                                                                            {/* MODAL WORKSHEET */}
                                                                            <Modalworksheet onClick={n.id} indexID={index} data={n} tag_repairs_room={n.tag_repairs_room ?? []} removeTagFromRepairRoom={(tag, nn) => this.removeTagFromRepairRoom(tag, nn)} />
                                                                            {
                                                                                n.insure_status === "have" &&

                                                                                <div className='statusClass' style={{ flexDirection: 'row', marginBottom: "30px" }} >
                                                                                    {
                                                                                        n.openCkh == true &&
                                                                                        <FixRequestStatusFast status={n.status} />
                                                                                    }
                                                                                    {
                                                                                        n.status === 'new' &&
                                                                                        <p><Link to={"/contact/fix-requests/fortenant/detail/" + n.id}>{t("PageList:Click Read More")}</Link></p>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            {

                                                                                (n.status !== "review" && n.status !== "void" && n.status !== "new") &&
                                                                                <div className='worksheetSS active' style={{ height: 'auto', padding: "20px 20px" }}>
                                                                                    {t("PageList:Detail Worksheet")}  : &nbsp;&nbsp;&nbsp;
                                                                                    {
                                                                                        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'repairs_room_print' }) &&
                                                                                        <span style={{ color: "#0092FF" }}>
                                                                                            <Link to={"/contact/fix-requests/fortenant/detail/" + n.id}>
                                                                                                {n.fast_job_code ? n.fast_job_code :
                                                                                                    wworksheet?.doc_number ? wworksheet?.doc_number : 'ทดสอบ'}
                                                                                            </Link>
                                                                                            &nbsp;&nbsp;&nbsp;

                                                                                            {/* PDF แบบ LIB PDFMake */}
                                                                                            {
                                                                                                lng.lng === "en" ?
                                                                                                    <ExportFixReportPDFEn
                                                                                                        dataExport={n}
                                                                                                        selfProject={this.state.selfProject}
                                                                                                        checkBox={this.state.checkBox}
                                                                                                        checkboxTrue={this.state.checkboxTrue}
                                                                                                        printIcon={true}
                                                                                                        namePrint={this.state.namePrint}
                                                                                                    />
                                                                                                    :
                                                                                                    <ExportFixReportPDF
                                                                                                        dataExport={n}
                                                                                                        selfProject={this.state.selfProject}
                                                                                                        checkBox={this.state.checkBox}
                                                                                                        checkboxTrue={this.state.checkboxTrue}
                                                                                                        printIcon={true}
                                                                                                        namePrint={this.state.namePrint}
                                                                                                    />
                                                                                            }


                                                                                            {/* PDF แบบเก่า */}
                                                                                            {/* <Link target={"_blank"} to={"/contact/fix-requests/document/worksheet/" + encode(wworksheet?.id)}>
                                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/printIcon.png'}
                                                                                                    style={{ height: " 20px" }} />
                                                                                            </Link> */}

                                                                                            {/* แก้ไขใบงานก่อนพิมพ์ */}
                                                                                            <ModalReportRepairEditBeforePrint
                                                                                                doc_nameNumber={wworksheet.doc_number}
                                                                                                dataExport={n}
                                                                                                selfProject={this.state.selfProject}
                                                                                                checkBox={this.state.checkBox}
                                                                                                checkboxTrue={this.state.checkboxTrue}
                                                                                                namePrint={this.state.namePrint}
                                                                                            />


                                                                                        </span>
                                                                                    }



                                                                                    {
                                                                                        n.insure_status !== "have" &&
                                                                                        <span style={{ position: 'absolute', right: '30px' }} onClick={() => this.viewMore(index, wworksheet?.id)}>
                                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/iconPlus.svg'}
                                                                                                style={{ height: " 20px" }} />
                                                                                        </span>

                                                                                    }


                                                                                    {
                                                                                        n.openCkh == true &&
                                                                                        <React.Fragment>
                                                                                            <div className='row wworkingSheetDetail'>
                                                                                                <FixRequestWorksheetDetail wworksheet={wworksheet} repoair={n} />
                                                                                            </div>
                                                                                            <section className='BTNpayment-BTNFinsih'>
                                                                                                {
                                                                                                    ((n.status === "pending" || n.status === "approve") && n.insure_status !== "have") ?
                                                                                                        <div>
                                                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/dashed.svg'} style={{ width: '100%' }} />

                                                                                                            {/* <div style={{ border: "2px dashed #E2E2E2", borderStyle: 'dashed' , marginTop: '12px' }} ></div> */}
                                                                                                            <div className='row' style={{ height: "60px" }}>
                                                                                                                <div style={{ position: "absolute", right: "15px" }}>
                                                                                                                    {
                                                                                                                        n.status === "pending" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'repairs_room_create' }) &&
                                                                                                                        <button
                                                                                                                            data-toggle="modal" data-target={".fixrequestPayment" + wworksheet?.id}
                                                                                                                            className='btn btn-primary'
                                                                                                                            style={{ marginTop: "15px", marginRight: '15px' }}>
                                                                                                                            {t("PageList:Bill Payment")}
                                                                                                                        </button>
                                                                                                                    }

                                                                                                                    {
                                                                                                                        n.status === "approve" &&
                                                                                                                        <button
                                                                                                                            onClick={() => this.onUpdateClick(n.id)}
                                                                                                                            // data-toggle="modal" data-target=".closefixRequset"
                                                                                                                            className='btn btn-primary'
                                                                                                                            style={{ marginTop: "15px", marginRight: '15px' }}>
                                                                                                                            {t("PageList:Finish Case")}
                                                                                                                        </button>
                                                                                                                    }

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div> :
                                                                                                        ""

                                                                                                }
                                                                                            </section>
                                                                                        </React.Fragment>
                                                                                    }

                                                                                    <Modalpayment onClick={wworksheet?.id} action={false} />



                                                                                </div>

                                                                            }


                                                                            {
                                                                                n.status === "void" &&
                                                                                <div className='worksheetSS active' style={{ height: 'auto', padding: "20px 20px" }}>
                                                                                    {t("PageList:Reason for Reject")} : &nbsp;&nbsp;&nbsp;
                                                                                    {n.remark ? n.remark : '-'}
                                                                                </div>
                                                                            }



                                                                        </div>

                                                                        <div className="col-md-3 text-right">
                                                                            <div className="grey-color listData">
                                                                                {format(n.updated, "DD/MM/YYYY HH:mm น.")}
                                                                                {
                                                                                    (n.status !== "finish" && n.status !== "void" && n.status !== "approve" && n.person_working?.includes('Juristic')) &&
                                                                                    _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "repairs_room_delete" }) &&
                                                                                    <div className="dropdown floatRight">
                                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/choice_noborder.png'} className=' dropdown-toggle' data-toggle="dropdown"
                                                                                            alt="choice-icon" onClick={() => this.activeOther(index)} style={{ cursor: "pointer" }} />
                                                                                        <ul className="dropdown-menu">
                                                                                            {
                                                                                                n.status === "review" &&
                                                                                                <li className=' dropdown-item' data-toggle="modal" data-target={".cancleFixRequest" + index}>{t("PageList:Reject this Case")}</li>
                                                                                            }
                                                                                            {
                                                                                                n.status === "pending" &&
                                                                                                <React.Fragment>
                                                                                                    <li className=' dropdown-item' data-toggle="modal" data-target={".cancleFixRequest" + index}>{t("PageList:Cancled")}</li>
                                                                                                    <li className=' dropdown-item' data-toggle="modal" data-target={".worksheetFixRequset1" + n.id}>{t("PageList:Edit")}</li>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                            {/* {
                                                                                            n.status === "approve" &&
                                                                                            <React.Fragment>
                                                                                                <li className=' dropdown-item' data-toggle="modal" data-target={".worksheetFixRequset" + n.id}>แก้ไข</li>
                                                                                            </React.Fragment>
                                                                                        } */}
                                                                                        </ul>

                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                            {/* MODAL CANCLE */}
                                                                            <Modalcancle onChange={this.SearchChange} indexID={index} dataID={n.id} />
                                                                            <ModalworksheetUpdate onClick={n.id} indexID={index} data={n} worksheet={n.worksheet[0]} />

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                        )
                                                    })
                                                }

                                                {
                                                    data.length === 0 &&
                                                    <div className=' card show mt-2'>
                                                        <div className="card-body p-4">
                                                            <div className="row">
                                                                <div className='col-sm-12 text-center'>
                                                                    {
                                                                        this.state.loading == true ?
                                                                            "LOADING...!" : t("PageList:This Status Not Found")
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                            </div>
                                        </div>

                                        {/* FOOTER AND PAGING */}
                                        <div className='row'>
                                            <div className='col-6'>
                                                <select className='page' onChange={this.handlePageSizeChange}>
                                                    <option value="10">{t("silvermanguard:show")} 10 {t("silvermanguard:items")}</option>
                                                    <option value="20">{t("silvermanguard:show")} 20 {t("silvermanguard:items")}</option>
                                                    <option value="50">{t("silvermanguard:show")} 50 {t("silvermanguard:items")}</option>
                                                    <option value="100">{t("silvermanguard:show")} 100 {t("silvermanguard:items")}</option>
                                                </select>
                                            </div>
                                            <div className='col-6'>
                                                <div className='text-right' style={{ marginTop: '22px' }}>{t("silvermanguard:listAll")} {this.state.totalCount} &nbsp;

                                                    {this.state.pageAll > 0 && (
                                                        <ReactPaginate
                                                            previousLabel={"previous"}
                                                            nextLabel={"next"}
                                                            breakLabel={
                                                                <span className="btn page-link">...</span>
                                                            }
                                                            breakClassName={"break-me"}
                                                            pageClassName="page-item"
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={4}
                                                            previousClassName="sr-only"
                                                            containerClassName={"pagination float-right"}
                                                            subContainerClassName={"pagination-page"}
                                                            nextLinkClassName="sr-only"
                                                            pageLinkClassName="page-link"
                                                            activeClassName={"active"}
                                                            pageCount={this.state.pageAll}
                                                            onPageChange={this.handlePageClick}
                                                        />
                                                    )}

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <div className="container-fluid box fixRequestFast text-center" >
                                        <img src={process.env.PUBLIC_URL + '/images/typeIcon/sing/loading.gif'} />
                                    </div>



                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }


}

export default fixRequestListForTenent; 
