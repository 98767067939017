/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatReceiptDepositUsedSettingInput = {|
  menuOn: boolean,
  clientMutationId?: ?string,
|};
export type settingReceiptDepositUseMutationVariables = {|
  input: UpdatReceiptDepositUsedSettingInput
|};
export type settingReceiptDepositUseMutationResponse = {|
  +updatReceiptDepositUsedSetting: ?{|
    +ok: ?boolean
  |}
|};
export type settingReceiptDepositUseMutation = {|
  variables: settingReceiptDepositUseMutationVariables,
  response: settingReceiptDepositUseMutationResponse,
|};
*/


/*
mutation settingReceiptDepositUseMutation(
  $input: UpdatReceiptDepositUsedSettingInput!
) {
  updatReceiptDepositUsedSetting(input: $input) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatReceiptDepositUsedSettingPayload",
    "kind": "LinkedField",
    "name": "updatReceiptDepositUsedSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingReceiptDepositUseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingReceiptDepositUseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f19a340e86d57da18f8076caf09ffe28",
    "id": null,
    "metadata": {},
    "name": "settingReceiptDepositUseMutation",
    "operationKind": "mutation",
    "text": "mutation settingReceiptDepositUseMutation(\n  $input: UpdatReceiptDepositUsedSettingInput!\n) {\n  updatReceiptDepositUsedSetting(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '714c9e259cb3aa83a5065e56a3ceef3c';

module.exports = node;
