import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';


class Navigation extends Component {
    render() {
        return (
            <Translation>
                {t=>
                <div className="row" id="navigation-tab">
                    <div className="col">
                        <ul>
                            <li>
                                <NavLink exact={true} to="/silvermanguard/visitormanage/all">
                                    {t("graudvisitmange:all")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/silvermanguard/visitormanage/coming">
                                    {t("graudvisitmange:incoming")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/silvermanguard/visitormanage/park">
                                    {t("graudvisitmange:packing")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/silvermanguard/visitormanage/over">
                                    {t("graudvisitmange:over")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/silvermanguard/visitormanage/success">
                                    {t("graudvisitmange:success")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/silvermanguard/visitormanage/cancel">
                                    {t("graudvisitmange:cancel")}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>}
            </Translation>
        )
    }
}

export default Navigation;