import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import environment from "../../../env/environment";
import i18n from "i18next";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { decode } from 'base-64';
import { Redirect } from 'react-router'
import SearchSelect from "../../../libs/searchSelect";
import Select, { components } from 'react-select';
import upload from '../../../libs/upload'
import format from 'date-fns/format';
import apiSing from '../../../api/sing';
import { fetchQuery } from "relay-runtime";
import jwtDecode from "jwt-decode";
import _ from 'lodash';
import i18next from 'i18next';
const allResidential = graphql`
    query fixRequestListCreateQuery {
        allResidential {
            totalCount
            edges {
                node {
                    id
                    name
                    floor
                    size
                    status
                    isUserActivate
                    residentialHouseholder{
                        edges{
                            node{
                                id
                                tenant{
                                    id
                                    firstName
                                    lastName
                                    countries
                                    phone
                                    user{
                                        id
                                    } 
                                    householder(first: 1, last: 1) {
                                        edges {
                                            node {
                                                active
                                            }
                                        }
                                    }   
                                }
                            }
                        }
                    }
                    type{
                        id
                        name
                    }
                }
            }
        }
    }
`;

let key = {
    "บัวเชิงผนัง":"Skirt",
    "งานพื้น":"Floor",
    "ประตู":"Door",
    "งานผนัง":"Wall",
    "งานอุปกรณ์ไฟฟ้า":"Electrical",
    "งานสุขภัณฑ์":"Sanitary Ware",
    "ชุดครัว":"Kitchen",
    "งานฝ้าเพดาน":"Ceiling",
    "งานหน้าต่าง":"Window",
    "งานระบบไฟฟ้า":"Electrical System",
    "งานระบบประปา":"Sanitary System", 
    "งานเฟอร์นิเจอร์":"Furniture",
    "งานเบ็ดเตล็ด":"Miscellaneous",
}

class fixRequestListCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedId: "",
            account: [],
            mapAccount: [],
            redirect: false,
            loading: true,
            workingTypeAll: [],
            homeAll: [],
            homeAll_test: [],
            residentialHouseholder: [],
            all_task_detail: [],
            all_task_detail_note: [],
            all_floor: [],


            typeFix: "1",
            Requsetfrom: "Tenant",
            RequestBy: "",
            RequestKey: "",
            isRequestKey: false,
            // imgProject: "/images/typeIcon/sing/imgProject.png",
            pageX: "0px",
            pageY: "0px",
            display: "none",
            file: [],

            homeId: "",
            residential_id: "",
            tenant_id: "",

            other_contact: "",
            email_contact: "",
            phone_contact: "",
            phone_other: "",

            repair_type: "private",
            task_id: "",
            working_type: "",
            material_Id: "",
            material: "",
            details_Id: "",
            details: "",
            floor: "",
            zone: "",
            description: "",


            isHaveImage: "",
            image: "",
            insure_status: "",
            due_date: "",

            mark: "",
            mark_x: "",
            mark_y: "",

            loadingRequest: false,
            loadingMaterial: false,
            loadingDetails: false,
            loadingFloor: false,
            token: jwtDecode(window.localStorage.getItem('token'))

        }
        this.onChange = this.onChange.bind(this);
        this.printMousePos = this.printMousePos.bind(this);
        this.dropDownChanged = this.dropDownChanged.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.get_task_detail = this.get_task_detail.bind(this);
        this.get_task_detail_note = this.get_task_detail_note.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChageSelectWarranty = this.onChageSelectWarranty.bind(this);
        this.onChangeRequestBy = this.onChangeRequestBy.bind(this);
        this.dateFile = this.dateFile.bind(this);
        this.selectFloor = this.selectFloor.bind(this)
        // this.ConvertToBase64ToFileObject = this.ConvertToBase64ToFileObject.bind(this)
        // this.toDataURL = this.toDataURL.bind(this)
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    componentWillMount() {
        this.getHomeAll()
        this.getWorkType()

        if (this.props.match.params.id) {
            this.getDataId()
        }
    }
    getWorkType() {
        let forPage = this.props.match.params.for === "forcommon" ? 'public' : 'private'
        apiSing.get_task_type_service(forPage).then((res) => {
            let data = []
            if (res.data.all_task_type) {
                data = res.data.all_task_type
                res.data.all_task_type.map((n, index) => {
                    let image = forPage === "private" ? "/images/typeIcon/sing/" + n.task_type_name + '.png' :
                        "/images/typeIcon/sing/singCommon/" + n.task_type_name + '.png'

                    _.set(data, '[' + index + ']images', image)
                })
                this.setState({ workingTypeAll: data, working_type: data[0].task_type_name, task_id: data[0].task_type_id })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }
    getHomeAll() {
        fetchQuery(environment, allResidential)
            .then(response_data => {
                let dataRoom = [...response_data.allResidential.edges]
                this.setState({
                    homeAll: dataRoom?.sort(function (a, b) {
                        return a.node.name.localeCompare(b.node.name, undefined, {
                            numeric: true,
                            sensitivity: 'base'
                        });
                    })
                });
            });
    }
    get_task_detail(index) {
        this.setState({ working_type: index.task_type_name_th, task_id: index.task_type_id, loadingMaterial: true, loadingDetails: true })
        apiSing.get_task_detail(index.task_type_id).then((res) => {
            let dataAllTaskDetail = []
            let langs = localStorage.getItem("language")
            if (res.data.all_task_detail) {
                res.data.all_task_detail.forEach((n, index) => {
                    _.set(dataAllTaskDetail, '[' + index + '].value', n.task_detail_id);
                    _.set(dataAllTaskDetail, '[' + index + '].label', langs === "en" ? n.task_detail_name : n.task_detail_name_th);
                })
                this.setState({ all_task_detail: dataAllTaskDetail, loadingMaterial: false })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }
    get_task_detail_note(e) {
        // let data = JSON.parse(e.target.value)
        // let id = data.id.toString()
        // let material = data.material.toString() 
        let residential_id = decode(this.state.residential_id)
        residential_id = residential_id.split(":")
        let id = e.value
        let material = e.label
        apiSing.get_task_detail_note(this.state.task_id, id, residential_id[1]).then((res) => {
            let dataAllTaskDetailNote = []
            let langs = localStorage.getItem("language")
            if (res.data.all_task_detail_note) {
                res.data.all_task_detail_note.forEach((n, index) => {
                    _.set(dataAllTaskDetailNote, '[' + index + '].value', n.task_detail_note_id);
                    _.set(dataAllTaskDetailNote, '[' + index + '].label', langs === "en" ? n.task_detail_note : n.task_detail_note_th);
                    _.set(dataAllTaskDetailNote, '[' + index + '].insure_status', n.warranty_status);
                    _.set(dataAllTaskDetailNote, '[' + index + '].due_date', n.warranty_expire_date);
                })
                this.setState({
                    all_task_detail_note: dataAllTaskDetailNote,
                    material: material,
                    material_Id: id,
                    loadingDetails: false
                })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }
    onChangeRequestBy(e) {

        if (e.value === "other" || e.value === "0") {

            this.setState({ RequestBy: e.value, isRequestKey: true, phone_contact: "", other_contact: '' })

        } else {
            let value = decode(e.value).split(":")
            this.setState({ RequestBy: value[1], isRequestKey: false, phone_contact: e.phone, other_contact: '' })
        }
    }
    onChange(e) {
        if (e.target.name === "floor") {
            let data = JSON.parse(e.target.value)
            this.setState({ floor: data.floor, image: data.image })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    onChageSelectWarranty(e) {
        let id = e.value
        let due_date = e.due_date
        let insure_status = e.insure_status
        let details = e.label
        if (e) {
            this.setState({ due_date: due_date, insure_status: insure_status, details: details, details_Id: parseInt(id) })
        }
    }
    async dropDownChanged(e) {
        this.setState({ loadingRequest: true, all_floor: [], loadingFloor: true })

        let listHouseHolder = []
        listHouseHolder = this.state.homeAll.filter((n) => n.node.id === e.target.value)
        let moreNameRequest = {
            value: "other",
            label: "ระบุเอง"
        }

        if (listHouseHolder) {
            let dataClone = []
            let dataFloor = []
            await listHouseHolder[0].node.residentialHouseholder.edges.forEach((n, index) => {
                _.set(dataClone, '[' + index + '].value', n.node.tenant.id);
                _.set(dataClone, '[' + index + '].label', n.node.tenant.firstName + " " + n.node.tenant.lastName);
                _.set(dataClone, '[' + index + '].phone', n.node.tenant.phone)
                _.set(dataClone, '[' + index + '].houseHolderActive', n.node.tenant.householder.edges[0].node.active)
            })
            if (this.state.token?.site?.includes('sklasmanagement')) {
                await apiSing.get_unit_detail(e.target.value).then((res) => {
                    dataFloor = res?.data?.unit_detail?.map((n) => ({
                        ...n, value: n.unit_layout_name, label: n.unit_layout_name, image: n.layout_image_url
                    }))
                })
            } else {
                _.set(dataFloor, '[' + 0 + '].value', listHouseHolder[0].node.floor)
                _.set(dataFloor, '[' + 0 + '].label', listHouseHolder[0].node.floor)
                _.set(dataFloor, '[' + 0 + '].image', "")
                _.set(dataFloor, '[' + 0 + '].isHaveImage', "N")
            }


            this.setState({
                [e.target.name]: e.target.value,
                residentialHouseholder: [...dataClone, moreNameRequest],
                all_floor: dataFloor,
                RequestBy: "", loadingRequest: false, phone_contact: '', loadingFloor: false
            })
        }

    }

    printMousePos(e) {
        var my_div = document.getElementById('my_div_id');
        var box = my_div.getBoundingClientRect();
        var cursorX = Math.ceil(e.clientX - box.left - 10);
        var cursorY = Math.ceil(e.clientY - box.top - 10);
        var mark_x = (cursorX * 2 + 6).toString()
        var mark_y = (cursorY * 2 + 57.97).toString()

        this.setState({
            pageX: cursorX + "px",
            pageY: cursorY + "px",
            mark_x: mark_x,
            mark_y: mark_y - 37,
            mark: mark_x + "," + mark_y
        })
    }
    //UPLOADFILE
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/sing/file" + path, file);
            resolve(res);
        });
    };
    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files) {
            if (files?.size > 10485760) {  // RQ147 2097152
                Swal.fire(i18next.t("Allaction:Unsuccessful "), `${i18next.t("Allaction:Please upload a new file as it exceeds the specified size of")} 10 MB`, 'error')
            }

            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), 'กรุณาอัพโหลดไฟล์ในประเภท .png, .jpg, และ .jpegเท่านั้น และไฟล์ขนาดไม่เกิน 10 MB (สูงสุด 3 รูป)', 'error')
            }

            else {
                // fileIsSel = false
                let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
                let fileOldList = _.cloneDeep(this.state.file);
                this.callUpload('', new_file).then((data) => {
                    let dese = "sing/file/" + new_file.name
                    fileOldList.push({ fileId: "", fileName: new_file.name, filePath: dese, status: "", viewPtath: data.location })
                    this.setState({ file: fileOldList })
                });
            }
        }
    }
    // END UPLOAD
    onSubmit(e) {
        e.preventDefault();

        let residential_id = decode(this.state.residential_id)
        residential_id = residential_id.split(":")

        let personWorkingValue = this.state.token?.site?.includes('sklasmanagement') ? " S KLAS(Juristic Person)":"Juristic Person"
        let dataSubmit = {
            "residential_id": parseInt(residential_id[1]),
            "tenant_id": parseInt(this.state.RequestBy),

            "other_contact": this.state.other_contact ? this.state.other_contact : '',
            "email_contact": this.state.email_contact,
            "phone_contact": this.state.phone_contact,

            "repair_type": this.state.repair_type,
            "working_type": this.state.working_type,
            "material": this.state.material,
            "details": this.state.details,
            "floor": this.state.floor || 0,
            "zone": this.state.zone,
            "image": this.state.image,
            "description": this.state.description,

            "insure_status": this.state.token?.site?.includes('sklasmanagement') ? this.state.insure_status : "not",
            "due_date": this.state.due_date + "T00:00",
            "person_working": this.state.insure_status === "have" ? "S-Homecare" : personWorkingValue,

            "mark": this.state.mark,
            "mark_x": this.state.mark_x,
            "mark_y": this.state.mark_y,

            "image_repairs_room": this.state.file,
            "fast_data_id": {
                "job_task_type_id": this.state.task_id,
                "job_task_detail_id": this.state.material_Id,
                "job_task_detail_note_id": this.state.details_Id
            },
            "create_by": "juristic_wab"
        }
        
        apiSing.POSTrepairs_room_view(dataSubmit).then((res) => {
            Swal.fire(i18n.t("Allaction:Saved Successfully"), "", "success").then(() => {
                this.setState({ redirect: true })

            })
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), "error"))

    }
    dateFile = (index, data) => {
        let DataFile = _.cloneDeep(this.state.file)
        DataFile.splice(index, 1)
        this.setState({ file: DataFile })
    }

    selectFloor(e) {
        this.setState({ isHaveImage: e.isHaveImage, image: e.image, floor: e.value })
    }

    CustomOption = (props) => {
        return (
          <components.Option {...props}>
            <div>
              {props.data.label}
              {props.data.houseHolderActive ? <div className="green-dot" /> : <div className="grey-dot" />}
            </div>
          </components.Option>
        );
    };

    CustomSingleValue = (props) => {
        return (
          <components.SingleValue {...props}>
            <div>
              {props.data.label}
              {props.data.houseHolderActive ? <div className="green-dot" /> : <div className="grey-dot" />}
            </div>
          </components.SingleValue>
        );
    };

    render() {


        if (this.state.redirect) {
            return <Redirect to="/contact/fix-requests/fortenant/review" />;
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            <div className="container-fluid box fixRequestFast" >
                                <div className='createFixrequest'>
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/contact/fix-requests/fortenant/review">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span style={{ fontWeight: '400' }}>
                                                    {t("PageList:Create Fix Request / Problem")}
                                                    {t("PageList:Tanant")}
                                                </span>

                                            </h3>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-10 mx-auto">
                                            <form onSubmit={this.onSubmit}>
                                                <div>
                                                    <br /><br />

                                                    <section className='GeneralInformation'>
                                                        <h3 style={{ fontWeight: '400' }}>{t("PageCreate:General Information")}</h3>
                                                        <hr />

                                                        <React.Fragment>
                                                            <label style={{ marginTop: '24px' }}>{t("PageCreate:Find Room")} <span className='text-red'>*</span></label>
                                                            <SearchSelect className="selectBox inputBox"
                                                                onChange={this.dropDownChanged}
                                                                value={this.state.residential_id}
                                                                name="residential_id" placeholder="รายการ"
                                                                queryObject={this.state.homeAll}
                                                                keyOfValue="id"
                                                                require={true}
                                                                keyOfLabel="name"
                                                                showUserActive={true}
                                                            />

                                                            {/* <SearchSelect className="selectBox inputBox"
                                                                    onChange={this.dropDownChanged}
                                                                    value={this.state.residential_id}
                                                                    name="residential_id" placeholder="รายการ"
                                                                    queryObject={this.state.homeAll_test}
                                                                    keyOfValue="residentialId"
                                                                    require={true}
                                                                    keyOfLabel="name"
                                                                /> */}
                                                            <label>{t("PageCreate:Choose Name Request")} <span className='text-red'>*</span></label>
                                                            <div style={{ position: 'relative' }}>                                                                
                                                                {
                                                                    this.state.loadingRequest == false ?
                                                                        <div>
                                                                            <Select
                                                                                placeholder={t("PageCreate:Choose Name Request")}
                                                                                options={this.state.residentialHouseholder}
                                                                                isSearchable={false}
                                                                                onChange={this.onChangeRequestBy}
                                                                                components={{
                                                                                    Option: this.CustomOption,
                                                                                    SingleValue: this.CustomSingleValue
                                                                                }}
                                                                            />
                                                                            <input name="RequestBy" type="text" value={this.state.RequestBy || ""}
                                                                                style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                required={true} />
                                                                        </div>
                                                                        :
                                                                        <Select
                                                                            placeholder={t("PageCreate:Choose Name Request")}
                                                                            isSearchable={false}
                                                                        />
                                                                }
                                                            </div>

                                                        </React.Fragment>

                                                        {
                                                            this.state.isRequestKey == true ?
                                                                <React.Fragment>
                                                                    <label>{t("PageCreate:First Name - Last Name")} <span className='text-red'>*</span></label>
                                                                    <input type="text" name='other_contact' className='form-control inputBox' placeholder='ระบุชื่อผู้แจ้ง'
                                                                        // defaultValue={this.state.other_contact}
                                                                        value={this.state.other_contact}
                                                                        required={true} onKeyPress={(e) => this.setState({ other_contact: e.target.value })}
                                                                        onChange={(e) => this.setState({ other_contact: e.target.value })} />

                                                                    <label>{t("PageCreate:Phone")} <span className='text-red'>*</span></label>
                                                                    <input type="text" name='phone_contact' className='form-control inputBox' placeholder='เบอร์ติดต่อผู้แจ้ง'
                                                                        // defaultValue={this.state.phone_contact}
                                                                        value={this.state.phone_contact}
                                                                        maxLength={10} minLength={10} pattern='[0-9]{10}'
                                                                        required={true} onKeyPress={(e) => this.setState({ phone_contact: e.target.value })}
                                                                        onChange={(e) => this.setState({ phone_contact: e.target.value })} />

                                                                    <label>{"E-mail"}</label>
                                                                    <input type="email" name='email_contact' className='form-control inputBox' placeholder='E-mail'
                                                                        // defaultValue={this.state.email_contact}
                                                                        value={this.state.email_contact}
                                                                        required={false} onKeyPress={(e) => this.setState({ email_contact: e.target.value })}
                                                                        onChange={(e) => this.setState({ email_contact: e.target.value })} />
                                                                </React.Fragment>

                                                                :

                                                                <React.Fragment>
                                                                    <label>{t("PageCreate:Phone")} <span className='text-red'>*</span></label>
                                                                    {
                                                                        <input type="text" name='phone_contact' className='form-control inputBox'
                                                                            placeholder='XXX-XXX-XXXX'
                                                                            defaultValue={this.state.phone_contact}
                                                                            required={true}
                                                                            onKeyPress={(e) => this.setState({ phone_contact: e.currentTarget.value })}
                                                                            onChange={(e) => this.setState({ phone_contact: e.currentTarget.value })} />
                                                                        // this.state.phone_other ?

                                                                        //     :
                                                                        //     <input type="text" name='phone_contact' className='form-control inputBox'
                                                                        //         placeholder='XXX-XXX-XXXX'
                                                                        //         value={this.state.phone_contact}
                                                                        //     />
                                                                    }

                                                                </React.Fragment>
                                                        }
                                                    </section>

                                                    <section className='WorkingType'>
                                                        <label>{t("PageCreate:Work Type")}  <span className='text-red'>*</span></label><br />
                                                        <div>
                                                            {
                                                                this.state.workingTypeAll.map((n, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <div
                                                                                className={this.state.working_type === n.task_type_name_th ? 'divTypeCar active' : 'divTypeCar '}
                                                                                // onClick={() => this.setState({ working_type: n.task_type_name_th , task_id : n.task_type_id})
                                                                                onClick={() => this.get_task_detail(n)}
                                                                            >
                                                                                <div className='imgTypeSelect'>
                                                                                    <img src={process.env.PUBLIC_URL + n.images} className='imgType' /><br />
                                                                                    {
                                                                                        this.state.working_type === n.task_type_name_th &&
                                                                                        <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Blues.png'} className='selected' />
                                                                                    }
                                                                                </div>
                                                                                <p>{t("pdpaPrivacyPolicy:PrivacyPolicy") === "th" ? n.task_type_name_th : n.task_type_name}</p>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }

                                                        </div>

                                                        <div style={{ clear: 'both' }}></div>
                                                        <br />
                                                    </section>

                                                    <section className='TypeOject'>
                                                        <label style={{ marginTop: '10px' }}>{t("PageCreate:Work Type / Material")} <span className='text-red'>*</span></label>
                                                        <div style={{ position: 'relative' }}>
                                                            {
                                                                this.state.loadingMaterial == false ?
                                                                    <div>
                                                                        <Select
                                                                            classNamePrefix='inputBox'
                                                                            options={this.state.all_task_detail}
                                                                            onChange={this.get_task_detail_note}
                                                                            require={true}
                                                                            isSearchable={false}
                                                                            placeholder={t("PageCreate:Work Type / Material")}
                                                                        />
                                                                        <input name="material" type="text" value={this.state.material || ""}
                                                                            style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                            onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                            required={true} />
                                                                    </div>
                                                                    :
                                                                    <Select
                                                                        isSearchable={false}
                                                                        placeholder={t("PageCreate:Work Type / Material")}
                                                                    />

                                                            }

                                                        </div>

                                                    </section>

                                                    <section className='DetailWorkingType'>
                                                        <label>{t("PageCreate:Detail Fix Request")} <span className='text-red'>*</span></label>
                                                        <div style={{ position: 'relative' }}>
                                                            {
                                                                this.state.loadingDetails == false ?
                                                                    <div>
                                                                        <Select
                                                                            classNamePrefix='inputBox'
                                                                            options={this.state.all_task_detail_note}
                                                                            onChange={this.onChageSelectWarranty}
                                                                            required={true}
                                                                            isSearchable={false}
                                                                            placeholder={t("PageCreate:Detail Fix Request")}
                                                                        />
                                                                        <input name="details" type="text" value={this.state.details || ""}
                                                                            style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                            onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                            required={true} />
                                                                    </div>

                                                                    : <Select
                                                                        isSearchable={false}
                                                                        placeholder={t("PageCreate:Detail Fix Request")}
                                                                    />

                                                            }
                                                        </div>



                                                    </section>

                                                    {
                                                        this.state.token?.site?.includes('sklasmanagement') ?
                                                        <section className='floor'>
                                                            <label>{t("PageCreate:Floor")} <span className='text-red'>*</span></label>
                                                            <div style={{ position: 'relative' }}>
                                                                {
                                                                    this.state.loadingFloor == false ?
                                                                        <div>
                                                                            <Select
                                                                                classNamePrefix='inputBox'
                                                                                options={this.state.all_floor}
                                                                                onChange={this.selectFloor}
                                                                                required={true}
                                                                                isSearchable={false}
                                                                                placeholder={t("PageCreate:Floor")}
                                                                            />
                                                                            <input name="floor" type="text" value={this.state.floor || ""}
                                                                                style={{ position: "absolute", top: 5, width: 5, zIndex: -1 }}
                                                                                onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                required={true} />
                                                                        </div>
                                                                        :
                                                                        <Select
                                                                            isSearchable={false}
                                                                            placeholder={t("PageCreate:Floor")}
                                                                        />
                                                                }
                                                            </div>
                                                            {
                                                                this.state.isHaveImage === "N" ?
                                                                    <span className='text-red'>{t("PageCreate:No Image Plan of this room")}</span>
                                                                    :
                                                                    this.state.image !== "" ?

                                                                        <div className='imgProductBox' id='my_div_id' onClick={this.printMousePos}
                                                                            style={{
                                                                                backgroundPositionX: this.state.pageX, backgroundPositionY: this.state.pageY
                                                                            }}>
                                                                            <img src={this.state.image} style={{ height: "300px", width: '400px' }} id='srcBoxImages' />
                                                                        </div>
                                                                        :
                                                                        ""
                                                            }
                                                        </section>
                                                        : 
                                                        <>
                                                        <section className='floor'>
                                                            <label>{t("PageCreate:Floor")}</label>
                                                            <div style={{ position: 'relative' }}>
                                                            <input name="floor" type="text" className='form-control inputBox' value={this.state.floor || ""}
                                                             onChange={(e) => this.setState({ floor: e.target.value })} />         
                                                            </div>
                                                        </section>
                                                              
                                                        </>
                                                    }



                                                    <section className='description'>
                                                        <label>{t("PageCreate:Detail of the problem")} <span className='text-red ml-2' style={{fontSize: '12px'}}>*{t("settingNote:Enter message not more than")} 200 {t("settingNote:characters")} {this.state.description?.length}/200 </span> </label>
                                                        <textarea
                                                            name='description' defaultValue={this.state.description}
                                                            placeholder={t("PageCreate:Detail of the problem")} rows={3}
                                                            onChange={(e) => this.setState({ description: e.target.value })}
                                                            onKeyDown={(e) => this.setState({ description: e.target.value })}
                                                            className='form-control'
                                                            maxLength={200}
                                                            style={{ resize: "none", width: "475px" }} />
                                                    </section>

                                                    <section className='UploadFile'>
                                                        <div style={{ display: 'inline-flex', pointerEvents: this.state.file.length >= 3 ? 'none' : 'all' }}>
                                                            <h3 style={{ marginTop: '40px' }}>{t("PageCreate:Choose File Fix Request")} </h3>
                                                            <label htmlFor="uploadFile" className="upload-btn-wrapper"
                                                            >
                                                                <button className={this.state.file.length >= 3 ? "btn disabled" : "btn"}><img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />{t("silvermanguard:Choose File")} </button>
                                                                <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>{t("PageCreate:Support File Just .png, .jpg, jpeg Only and File Not Over 10 MB (Max 3 Image Only)")} </span>

                                                                <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder='ระบุเบอร์โทร' accept='application/.pdf,.png,.jpg' onChange={this.uploadFile}
                                                                />

                                                            </label>
                                                        </div>

                                                        {
                                                            this.state.file.length > 0 &&
                                                            this.state.file.map((file, inx) => {

                                                                let typeFile = file.fileName.split(".")
                                                                return (
                                                                    <p key={inx}>
                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                        <Link
                                                                            to="route"
                                                                            onClick={(event) => {
                                                                                event.preventDefault();
                                                                                window.open(
                                                                                    typeof file.viewPtath === "string"
                                                                                        ? file.viewPtath
                                                                                        : URL.createObjectURL(file.viewPtath)
                                                                                )
                                                                            }}
                                                                            target="blank">{file.fileName}</Link>
                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file)}>X</label>
                                                                    </p>
                                                                )
                                                            })
                                                        }
                                                    </section>

                                                    <section className='ChkWarranty'>
                                                        <p style={{
                                                            fontWeight: "600", fontSize: "13px", color: this.state.insure_status === "have" ? "#38AE3E" : "#CA0824"
                                                        }}>
                                                            <React.Fragment>
                                                                {
                                                                    this.state.insure_status === "have" ?
                                                                        <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/InsurGreen.png"} style={{ width: '18px' }} /> :
                                                                        this.state.insure_status === "out" ?
                                                                            <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/InsurRed.png"} style={{ width: '18px' }} /> :
                                                                            ""
                                                                }
                                                                {
                                                                    this.state.insure_status === "have" || this.state.insure_status === "out" ?
                                                                        <React.Fragment>&nbsp; {t("PageCreate:Warranty Expiry")} &nbsp; {this.state.due_date ? format(this.state.due_date, "DD/MM/YYYY") : "-"}</React.Fragment>
                                                                        : ""
                                                                }
                                                            </React.Fragment>

                                                        </p>

                                                    </section>

                                                </div>

                                                <div className='row mt-2'>
                                                    <div className='col-12 text-right'>
                                                        <button type='submit' className='btn btn-primary'>{t("Allaction:save")}</button>
                                                    </div>
                                                </div>

                                            </form>

                                        </div>
                                    </div>


                                </div>



                            </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default fixRequestListCreate;
