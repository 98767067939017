import React, {Component} from 'react';
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import Wrapper from "../../../../components/wrapper";
import SelectContact from "../../../income/selectContact";
import GuaranteeMoneyReceiveMovementReport from "./guaranteeMoneyReceiveMovementReport";

class SelectRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_list: [],
            accountType: "guarantee_money",
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({customer_list: contact_list})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    {
                        this.props.match.params.status === "select-customer" &&
                             <SelectContact updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                            initialContactType="residential"
                                            nextLink="/accounting/report/account-receivable/guarantee-money-received-movement/list"
                                            backLink="/accounting/report/account-receivable"/>
                    }

                    {this.props.match.params.status === 'list' &&
                        <GuaranteeMoneyReceiveMovementReport customerList={this.state.customer_list}/>
                    }

                </WrapperContent>
            </Wrapper>
        );
    }
}

export default SelectRoom;