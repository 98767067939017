/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HouseHolderType = "AGENT" | "OWNER" | "RENTER" | "RESIDENT" | "%future added value";
export type TenantCardType = "DRIVING" | "GOVERNMENT_OFFICER" | "ID_CARD" | "OTHER" | "PASSPORT" | "UNSPECIFIED" | "%future added value";
export type TenantGender = "FEMALE" | "MALE" | "OTHER" | "UNSPECIFIED" | "%future added value";
export type createUpdateOwnerDetailQueryVariables = {|
  id: string,
  residential_id: string,
|};
export type createUpdateOwnerDetailQueryResponse = {|
  +residential: ?{|
    +name: string
  |},
  +houseHolder: ?{|
    +type: HouseHolderType,
    +detailTenantPerResidential: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +dateIn: ?any,
          +remark: ?string,
        |}
      |}>
    |},
    +active: boolean,
    +tenant: {|
      +id: string,
      +dateIn: ?any,
      +dateOut: ?any,
      +note: ?string,
      +image: string,
      +nameTitle: ?string,
      +firstName: string,
      +lastName: ?string,
      +firstNameEng: ?string,
      +lastNameEng: ?string,
      +occupation: ?string,
      +tenantStatus: ?string,
      +countries: string,
      +ethnicity: string,
      +dateOfBirth: ?any,
      +gender: TenantGender,
      +idCardNumber: ?string,
      +cardType: TenantCardType,
      +phone: ?string,
      +phone2: ?string,
      +email: ?string,
      +householder: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string
          |}
        |}>
      |},
      +verifyIdentityDocument: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +fileName: string,
            +fileUpload: ?string,
          |}
        |}>
      |},
      +passport: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +fileName: string,
            +fileUpload: ?string,
          |}
        |}>
      |},
      +lease: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +fileName: string,
            +fileUpload: ?string,
          |}
        |}>
      |},
      +rentOwnerLease: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +fileName: string,
            +fileUpload: ?string,
          |}
        |}>
      |},
      +dailyForm: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +fileName: string,
            +fileUpload: ?string,
          |}
        |}>
      |},
      +acceptPolicy: boolean,
      +acceptTermOfUse: boolean,
    |},
  |},
|};
export type createUpdateOwnerDetailQuery = {|
  variables: createUpdateOwnerDetailQueryVariables,
  response: createUpdateOwnerDetailQueryResponse,
|};
*/


/*
query createUpdateOwnerDetailQuery(
  $id: ID!
  $residential_id: ID!
) {
  residential(id: $residential_id) {
    name
    id
  }
  houseHolder(id: $id) {
    type
    detailTenantPerResidential {
      edges {
        node {
          id
          dateIn
          remark
        }
      }
    }
    active
    tenant {
      id
      dateIn
      dateOut
      note
      image
      nameTitle
      firstName
      lastName
      firstNameEng
      lastNameEng
      occupation
      tenantStatus
      countries
      ethnicity
      dateOfBirth
      gender
      idCardNumber
      cardType
      phone
      phone2
      email
      householder {
        edges {
          node {
            id
          }
        }
      }
      verifyIdentityDocument {
        edges {
          node {
            id
            fileName
            fileUpload
          }
        }
      }
      passport {
        edges {
          node {
            id
            fileName
            fileUpload
          }
        }
      }
      lease {
        edges {
          node {
            id
            fileName
            fileUpload
          }
        }
      }
      rentOwnerLease {
        edges {
          node {
            id
            fileName
            fileUpload
          }
        }
      }
      dailyForm {
        edges {
          node {
            id
            fileName
            fileUpload
          }
        }
      }
      acceptPolicy
      acceptTermOfUse
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "residential_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "residential_id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateIn",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "DetailTenantPerResidentialNodeConnection",
  "kind": "LinkedField",
  "name": "detailTenantPerResidential",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DetailTenantPerResidentialNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DetailTenantPerResidentialNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remark",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v9 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fileName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fileUpload",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantNode",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOut",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstNameEng",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastNameEng",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "occupation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tenantStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countries",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ethnicity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfBirth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "idCardNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseHolderNodeConnection",
      "kind": "LinkedField",
      "name": "householder",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HouseHolderNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "HouseHolderNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VerifyIdentityDocumentNodeConnection",
      "kind": "LinkedField",
      "name": "verifyIdentityDocument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VerifyIdentityDocumentNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VerifyIdentityDocumentNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v9/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PassportNodeConnection",
      "kind": "LinkedField",
      "name": "passport",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PassportNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PassportNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v9/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeaseNodeConnection",
      "kind": "LinkedField",
      "name": "lease",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LeaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LeaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v9/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RentOwnerLeaseNodeConnection",
      "kind": "LinkedField",
      "name": "rentOwnerLease",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RentOwnerLeaseNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RentOwnerLeaseNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v9/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DailyFormNodeConnection",
      "kind": "LinkedField",
      "name": "dailyForm",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DailyFormNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DailyFormNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v9/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptPolicy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptTermOfUse",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createUpdateOwnerDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialNode",
        "kind": "LinkedField",
        "name": "residential",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "HouseHolderNode",
        "kind": "LinkedField",
        "name": "houseHolder",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createUpdateOwnerDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialNode",
        "kind": "LinkedField",
        "name": "residential",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "HouseHolderNode",
        "kind": "LinkedField",
        "name": "houseHolder",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v10/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c89ee733577bf560114fef3ba9ea9fe4",
    "id": null,
    "metadata": {},
    "name": "createUpdateOwnerDetailQuery",
    "operationKind": "query",
    "text": "query createUpdateOwnerDetailQuery(\n  $id: ID!\n  $residential_id: ID!\n) {\n  residential(id: $residential_id) {\n    name\n    id\n  }\n  houseHolder(id: $id) {\n    type\n    detailTenantPerResidential {\n      edges {\n        node {\n          id\n          dateIn\n          remark\n        }\n      }\n    }\n    active\n    tenant {\n      id\n      dateIn\n      dateOut\n      note\n      image\n      nameTitle\n      firstName\n      lastName\n      firstNameEng\n      lastNameEng\n      occupation\n      tenantStatus\n      countries\n      ethnicity\n      dateOfBirth\n      gender\n      idCardNumber\n      cardType\n      phone\n      phone2\n      email\n      householder {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      verifyIdentityDocument {\n        edges {\n          node {\n            id\n            fileName\n            fileUpload\n          }\n        }\n      }\n      passport {\n        edges {\n          node {\n            id\n            fileName\n            fileUpload\n          }\n        }\n      }\n      lease {\n        edges {\n          node {\n            id\n            fileName\n            fileUpload\n          }\n        }\n      }\n      rentOwnerLease {\n        edges {\n          node {\n            id\n            fileName\n            fileUpload\n          }\n        }\n      }\n      dailyForm {\n        edges {\n          node {\n            id\n            fileName\n            fileUpload\n          }\n        }\n      }\n      acceptPolicy\n      acceptTermOfUse\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2732ef447f1d9014822463105eebaf0b';

module.exports = node;
