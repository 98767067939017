import React, { Component } from 'react';
import Swal from "sweetalert2";
import i18n from "i18next";
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import API from '../../api/silverGraund'

import { format } from 'date-fns';
import BackButtonIcon from '../../components/BackBtn/indexBack';
import i18next from 'i18next';
class VisitReportDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            settingCalu: {
                etampTimerVistor: 2,
                etampTimer: 2,
                etampTimerHH: null,
                etampTimerMM: null,
                etampTimerSS: null,
                toPayCal: 20,
                toCalOverTime: 50,
            },
            allTimePacking: 0,
            imgLoad: ""


        }
        // const times = this.state.data?.allTimePacking?.substring(11)
        // const timesSpl = times.split(":")
        // this.setStates = this.setStates.bind(this)
    }

    componentWillMount() {
        this.getHomeAll()
    }

    getHomeAll() {
        let idCode = this.props.match.params.id
        API.getDataReportDetail(idCode).then((ress) => {
            if (ress.data.data) {
                let timeNow = new Date();
                let timePack = new Date(ress.data.data[0].startDate);
                let timePackingCla = timeNow.getTime() - timePack.getTime()
                let timeCal =
                    new Date(timePackingCla).getHours() + ":" +
                    new Date(timePackingCla).getMinutes()
                this.setState({ data: ress.data.data[0], allTimePacking: timeCal })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    onError(imgData) {
        if (imgData !== "" && imgData !== null) {
            this.setState({
                imgLoad: imgData
            });
        }

    }


    render() {
        let idCode = this.props.match.params.id
        const idCardd = this.state.data.idCard ?
            this.state.data && this.state.data.idCard.substring(0, 1) + "-XXXX-XXXXX-" + this.state.data.idCard.substring(10, 12) + "-" + this.state.data.idCard.substring(12) : "";

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="graundreportDetail">
                                <BackButtonIcon
                                    LinkBack={"/silvermanguard/report/all"}
                                    LinkText={i18next.t('graud:reportPacking')}
                                />

                                {/* <Navigation /> */}
                                <div style={{ display: 'inline-flex' }}>
                                    <label className='label-danger'>{i18next.t("reportDetail:Overdue Parking")}</label> &nbsp;&nbsp;
                                    <label className='label-blue'>{
                                        this.state.data.typePerson === 'person' ? i18next.t("reportDetail:General Public") :
                                            this.state.data.typePerson === 'tenant' ? i18next.t("reportDetail:Renter/Homeowner") :
                                                this.state.data.typePerson === 'appointment' ? i18next.t("reportDetail:Contact Sales Office") : i18next.t("reportDetail:Contact Sales Office")
                                    }</label>

                                    <div style={{ position: 'absolute', right: '200px', top: '90px' }}>
                                        <Link to={"/silvermanguard/report/print/" + idCode}>
                                            <button className='btnBuleOutline'>{i18next.t("reportDetail:Print Parking Receipt")}</button>
                                        </Link>
                                    </div>

                                </div>

                                <div className='row'>
                                    <div className='col-10 mx-auto'>
                                        <br />
                                        <br />

                                        {
                                            this.state.imgLoad !== "" &&
                                            <img
                                                onError={() => this.onError(this.state.data.img)}
                                                src={process.env.PUBLIC_URL + this.state.imgLoad} className='img' />
                                        }



                                        <br /><br />
                                        <h3 >{i18next.t("reportDetail:General Information")}</h3>
                                        <hr />

                                        <label style={{ marginTop: '24px' }}>{i18next.t("reportDetail:Contact Person's Name")}</label>
                                        <input type="text" className='form-control inputBox' placeholder='กรอก' defaultValue={this.state.data.fullnameVisit} disabled={true} />

                                        <label>{i18next.t("reportDetail:ID Card Number")}</label>
                                        <input type="text" className='form-control inputBox' placeholder='x-xx-xxx-xxxx-xx-x'
                                            defaultValue={idCardd} disabled={true} />

                                        <label>{i18next.t("reportDetail:Contact Person's Name")}</label>
                                        <input type="text" className='form-control inputBox' placeholder={i18next.t("visitorManage:Contact number")} defaultValue={this.state.data.phone} disabled={true} />

                                        <div style={{ display: 'inline-flex' }}>
                                            <h3 style={{ marginTop: '40px' }}>{i18next.t("silvermanguard:file")}</h3>
                                            {
                                                true != true &&
                                                <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                                    <button className="btn"><img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} /> {i18n.t("FixRequestModal:Attach slip")}</button>
                                                    <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>{i18next.t("manageVisitor:.jpg or .pdf file and file size not over 2 MB")}</span>
                                                    <input type="file" id="uploadFile" className='form-control inputBox' inputProps={{ accept: '.pdf' }} />
                                                </label>
                                            }

                                        </div>

                                        {
                                            this.state.data.file &&
                                            this.state.data.file.map((n) => {
                                                return (
                                                    <p key={n.fileId}>
                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} style={{ width: '30px' }} /> &nbsp;
                                                        {/* <Link to={"/silvermanguard/visitormanage/file/" + n.fileName} style={{ fontSize: '16px', }}>{n.nameFile}</Link> */}
                                                        <Link
                                                            to="route"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                window.open(
                                                                    typeof n.filePath === "string"
                                                                        ? n.filePath
                                                                        : URL.createObjectURL(n.filePath)
                                                                )
                                                            }}
                                                            target="blank">
                                                            {n.fileName}
                                                            {/* {fileName.length >= 20 ? fileName.substr(0, 20) : filePath} */}
                                                        </Link>
                                                    </p>
                                                )
                                            })
                                        }



                                        <h3 style={{ marginTop: '58px' }}>{i18next.t("silvermanguard:CarInformation")}</h3>
                                        <hr />

                                        <label style={{ marginTop: '24px' }}>{i18next.t("silvermanguard:CarRegis")} <span className='text-red'>*</span></label>
                                        <input type="text" className='form-control inputBox' placeholder={i18next.t("silvermanguard:CarRegis")} defaultValue={this.state.data.carRegistration} disabled={true} />

                                        <label>{i18next.t("silvermanguard:Province")}</label>
                                        <select className='form-control inputBox' disabled={true}>
                                            <option>{this.state.data.province}</option>
                                        </select>


                                        <label>{i18next.t("silvermanguard:TypeCar")}</label><br />
                                        <div style={{ display: 'inline-flex' }}>
                                            <div className={this.state.data.typeTrackandCar === "sedan" ? 'divTypeCar actived' : 'divTypeCar '}>
                                                <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -02.png'} /> {
                                                    this.state.data.typeTrackandCar === "sedan" &&
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Gray.png'} className='selected' />
                                                }

                                                <p>{i18next.t("silvermanguard:car")}</p>
                                            </div>

                                            <div className={this.state.data.typeTrackandCar === "pickup" ? 'divTypeCar actived' : 'divTypeCar '}>
                                                <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -03.png'} />
                                                {
                                                    this.state.data.typeTrackandCar === "pickup" &&
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Gray.png'} className='selected' />
                                                }
                                                <p>{i18next.t("silvermanguard:pickup")}</p>
                                            </div>

                                            <div className={this.state.data.typeTrackandCar === "van" ? 'divTypeCar actived' : 'divTypeCar '}>
                                                <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -04.png'} />
                                                {
                                                    this.state.data.typeTrackandCar === "van" &&
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Gray.png'} className='selected' />
                                                }
                                                <p>{i18next.t("silvermanguard:van")}</p>
                                            </div>

                                            <div className={this.state.data.typeTrackandCar === "taxi" ? 'divTypeCar actived' : 'divTypeCar '}>
                                                <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -05.png'} />
                                                {
                                                    this.state.data.typeTrackandCar === "taxi" &&
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Gray.png'} className='selected' />
                                                }
                                                <p>{i18next.t("silvermanguard:taxi")}</p>
                                            </div>

                                            <div className={this.state.data.typeTrackandCar === "motocy" ? 'divTypeCar actived' : 'divTypeCar '}>
                                                <img src={process.env.PUBLIC_URL + '/images/typeIconCar/motor.png'} />
                                                {
                                                    this.state.data.typeTrackandCar === "motocy" &&
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Gray.png'} className='selected' />
                                                }
                                                <p>{i18next.t("silvermanguard:motocy")}</p>
                                            </div>

                                            <div className={this.state.data.typeTrackandCar === "motocycle" ? 'divTypeCar actived' : 'divTypeCar '}>
                                                <img src={process.env.PUBLIC_URL + '/images/typeIconCar/motor - BB.png'} />
                                                {
                                                    this.state.data.typeTrackandCar === "motocycle" &&
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Gray.png'} className='selected' />
                                                }
                                                <p>{i18next.t("silvermanguard:motocyBB")}</p>
                                            </div>
                                        </div>

                                        <br />
                                        <label style={{ marginTop: '60px' }}>{i18next.t("silvermanguard:brandCar")} <span className='text-red'>*</span></label>
                                        <select className='form-control inputBox' disabled={true}>
                                            <option>{this.state.data.brandCar}</option>
                                        </select>
                                        {/* 
                                <label>รุ่น</label>
                                <select className='form-control inputBox' disabled={true}>
                                    <option>{this.state.data.versonCar}</option>
                                </select> */}

                                        <label>{i18next.t("silvermanguard:ColorCar")}</label>
                                        <input type="text" className='form-control inputBox' placeholder='กรอก' defaultValue={this.state.data.colorCar} disabled={true} />

                                        {/* <label>ปี</label>
                                <input type="text" className='form-control inputBox' placeholder='กรอก' defaultValue={this.state.data.yearCar} disabled={true} />

                                <label>ขนาด</label>
                                <input type="text" className='form-control inputBox' placeholder='กรอก' defaultValue={this.state.data.sizeCar} disabled={true} /> */}



                                        <h3 style={{ marginTop: '36px' }}>{i18next.t("silvermanguard:role")}</h3>
                                        <hr />

                                        <label style={{ marginTop: '24px' }}>{i18next.t("vmsDashboard:Visitors Type")} </label><br />
                                        <label className="containerRadio">{i18next.t("visitorManage:Resident / Tenant")}
                                            <input type="radio" checked={this.state.data.typePerson === "tenant"} disabled={true} />
                                            <span className="checkmark"></span>
                                        </label>

                                        <label style={{ marginTop: '13px', marginLeft: '35px' }}>{i18next.t("reportDetail:First-time registration, is the car parked in the project or not?")}</label>
                                        <label className="containerRadio" style={{ marginLeft: '35px' }}>{i18next.t("reportDetail:In Parking Space")}
                                            <input type="checkbox" name="isPacking" disabled={true} />
                                            <span className="checkmarkCheckBox"></span>
                                        </label>

                                        <label className="containerRadio">{i18next.t("reportDetail:Contact Sales Office")}
                                            <input type="radio" checked={this.state.data.typePerson === "contact"} disabled={true} />
                                            <span className="checkmark"></span>
                                        </label>

                                        <label className="containerRadio">{i18next.t("reportDetail:Contact Person with Appointment (Visitor)")}
                                            <input type="radio" checked={this.state.data.typePerson === "appointment"} disabled={true} />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="containerRadio">{i18next.t("reportDetail:General Public")}
                                            <input type="radio" checked={this.state.data.typePerson === "person"} disabled={true} />
                                            <span className="checkmark"></span>
                                        </label>


                                        <label style={{ marginTop: '24px' }}>{i18next.t("reportDetail:Rights Type")} </label><br />
                                        <label className="containerRadio">{i18next.t("reportDetail:One-time")}
                                            <input type="radio" name="typeRole" disabled={true}
                                                checked={this.state.data.typeRole === "temp"} />
                                            <span className="checkmark"></span>
                                        </label>

                                        <label className="containerRadio">{i18next.t("reportDetail:Multi-use (with expiration date)")}
                                            <input type="radio" name="typeRole"
                                                checked={this.state.data.typePerson === "tenant" && this.state.data.typeRole === "perm"}
                                                disabled={this.state.data.typePerson !== "tenant"} />
                                            <span className="checkmark"></span>
                                        </label>


                                        {
                                            this.state.data.typePerson === "tenant" &&
                                            <React.Fragment>
                                                <div className='boxAlert' style={{ width: '440px' }}>
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconAlert-BlueSmall.png'} /> &nbsp;
                                                    <span>{i18next.t("reportDetail:Contact Sales Office")}</span>
                                                    &nbsp;
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/close-Blue.png'} style={{ marginLeft: '10px' }} />
                                                </div>
                                            </React.Fragment>

                                            ||

                                            this.state.data.typePerson === "contact" &&
                                            <React.Fragment>
                                                <div className='boxAlert' style={{ width: '561px' }}>
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconAlert-BlueSmall.png'} /> &nbsp;
                                                    <span>{i18next.t("reportDetail:Contact Person with Appointment (Visitor)")}</span>
                                                    &nbsp;
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/close-Blue.png'} style={{ marginLeft: '10px' }} />
                                                </div>
                                            </React.Fragment>

                                            ||

                                            this.state.data.typePerson === "person" &&
                                            <React.Fragment>
                                                <div className='boxAlert' style={{ width: '561px' }}>
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconAlert-BlueSmall.png'} /> &nbsp;
                                                    <span>{i18next.t("reportDetail:Contact Person with Appointment (Visitor)")}</span>
                                                    &nbsp;
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/close-Blue.png'} style={{ marginLeft: '10px' }} />
                                                </div>
                                            </React.Fragment>

                                        }



                                        {
                                            this.state.data.typePerson === "tenant" &&
                                            <React.Fragment>
                                                <label style={{ marginTop: '42px' }}>{i18next.t("silvermanguard:HomeId")} </label><br />
                                                <input type="text" className='form-control inputBox' placeholder='กรอก' defaultValue={this.state.data.homeName} disabled={true} />
                                            </React.Fragment>
                                        }
                                        {
                                            (this.state.data.typePerson === "contact" || this.state.typePerson === "appointment") &&
                                            <React.Fragment>
                                                <label style={{ marginTop: '42px' }}>{i18next.t("silvermanguard:HomeIdContact")} <span className='text-red'>*</span></label><br />
                                                <input type="text" className='form-control inputBox' placeholder='กรอก' defaultValue={this.state.data.homeName} disabled={true} />
                                            </React.Fragment>
                                        }

                                        {
                                            (this.state.data.typePerson === "person" || this.state.data.typePerson === "contact" || this.state.data.typePerson === "appointment") &&
                                            <React.Fragment>
                                                <label>{i18next.t("reportDetail:Purpose")} </label><br />
                                                <input type="text" className='form-control inputBox' placeholder='กรอก' defaultValue={this.state.reason} disabled={true} />
                                            </React.Fragment>
                                        }

                                        <div style={{ display: 'inline-flex' }}>
                                            <div style={{ width: '254px' }}>
                                                <label>{i18next.t("silvermanguard:DateUse")} </label><br />
                                                <input
                                                    key={this.state.data.startDate}
                                                    type="date" className='form-control inputBoxDate'
                                                    placeholder='กรอก' defaultValue={format(this.state.data.startDate, "YYYY-MM-DD")} disabled={true} />
                                            </div>
                                            <div style={{ width: '254px' }}>
                                                <label>{i18next.t("silvermanguard:TimeUse")} </label><br />
                                                <input type="time" className='form-control inputBoxDate'
                                                    placeholder='กรอก' defaultValue={this.state.data.timeUsingSt} disabled={true} />
                                            </div>
                                        </div>

                                        <br />
                                        <div style={{ display: 'inline-flex' }}>
                                            <div style={{ width: '254px' }}>
                                                <label>{i18next.t("reportDetail:Date Parking Rights End Permit")} </label><br />
                                                <input
                                                    key={this.state.data.endDate}
                                                    type="date" className='form-control inputBoxDate'
                                                    placeholder='กรอก' defaultValue={format(this.state.data.endDate, "YYYY-MM-DD")} disabled={true} />
                                            </div>
                                            <div style={{ width: '254px' }}>
                                                <label>{i18next.t("reportDetail:Parking Rights End Date")} </label><br />
                                                <input type="time" className='form-control inputBoxDate'
                                                    placeholder='กรอก' defaultValue={this.state.data.timeUsingEn} disabled={true} />
                                            </div>
                                        </div>

                                        <h3 style={{ marginTop: '36px' }}>{i18next.t("reportDetail:Exit Time")}</h3>
                                        <hr />

                                        <br />
                                        <div style={{ display: 'inline-flex' }}>
                                            <div style={{ width: '254px' }}>
                                                <label>{i18next.t("reportDetail:Date of Exit")} </label><br />
                                                <input
                                                    key={this.state.data.outDate}
                                                    className='form-control inputBoxDate' placeholder='กรอก'
                                                    defaultValue={format(this.state.data.outDate, "YYYY-MM-DD")} disabled={true}
                                                    type="date" />
                                            </div>
                                            <div style={{ width: '254px' }}>
                                                <label>{i18next.t("reportDetail:Exit Time")} </label><br />
                                                <input
                                                    type="time" className='form-control inputBoxDate'
                                                    placeholder='กรอก' defaultValue={this.state.data.timeCheckOut} disabled={true} />
                                            </div>
                                        </div>

                                        <br />
                                        <div style={{ marginTop: '42px', display: 'inline-flex' }}>
                                            <div style={{ width: '254px' }}>
                                                <label className='greys-04'>{i18next.t("reportDetail:Duration of Parking")} </label><br />
                                                <label className='greys-04'>{i18next.t("reportDetail:E-Stam Free Parking")}
                                                    <span className='label-default' style={{ fontWeight: '600' }}>E-Stamp</span></label><br />
                                                <label className='greys-04'>{i18next.t("reportDetail:Exceeded Time")} </label><br />
                                                <label className='greys-04'>{i18next.t("reportDetail:Parking Fee Rate")} </label><br />
                                            </div>


                                            <div style={{ width: '254px' }}>
                                                <label className='greys-044'>
                                                    {
                                                        this.state.data.parkingTime > 60 ?
                                                            Math.floor(this.state.data.parkingTime / 60) + i18next.t("reportDetail:hour") + this.state.data.parkingTime % 60 + i18next.t("settingSecurityCompany:minute") :
                                                            this.state.data.parkingTime + i18next.t("settingSecurityCompany:minute")
                                                    }
                                                </label><br />
                                                <label className='greys-044'>
                                                    {
                                                        this.state.data.etampTimer > 60 ? (parseInt(this.state.data.etampTimer) / 60) + i18next.t("reportDetail:hour") :
                                                            parseInt(this.state.data.etampTimer) + i18next.t("settingSecurityCompany:minute")
                                                    }
                                                </label><br />
                                                <label className='greys-044'>
                                                    {
                                                        this.state.data.overtime > 60 ?
                                                            Math.floor(this.state.data.overtime / 60) + i18next.t("reportDetail:hour")+ this.state.data.overtime % 60 + i18next.t("settingSecurityCompany:minute") :
                                                            this.state.data.overtime + i18next.t("settingSecurityCompany:minute")
                                                    }
                                                </label><br />
                                                <label className='greys-044'>{this.state.data.toPayCal} {i18next.t("reportDetail:Baht/Hour")}</label><br />
                                            </div>

                                        </div>
                                        <br />

                                        <label className='greys-05'>
                                        {i18next.t("reportDetail:Note")} :  {i18next.t("reportDetail:Exceeded time over")} {parseInt(this.state.data.toCalOverTime || 0)} {i18next.t("reportDetail:minute is considered as 1 hour.")}
                                        </label>
                                        <hr />



                                        <div style={{ marginTop: '42px', display: 'inline-flex' }}>
                                            <div style={{ width: '254px' }}>
                                                <h2>{i18next.t("reportDetail:Total Parking Fee")}</h2>
                                                {
                                                    this.state.data.methodPayment !== "" ?
                                                        <React.Fragment>
                                                            <label>{i18next.t("reportDetail:Payment Method")}  :  {
                                                                this.state.data.methodPayment === 'cash' ? i18next.t("reportDetail:Cash") : 'Scan QR Code'
                                                            }</label>
                                                        </React.Fragment>

                                                        : ""
                                                }

                                            </div>
                                            <div style={{ width: '254px' }}>
                                                <h2>{parseFloat(this.state.data.totalPacking || 0)} {i18next.t("reportDetail:Baht")}</h2>
                                            </div>
                                        </div>

                                        <br />





                                        {
                                            this.state.data.methodPayment !== 'cash' && this.state.data.methodPayment !== "" &&
                                            <div style={{ marginTop: '50px', display: 'inline-flex' }}>
                                                <h2>{i18next.t("createReceive:Receipt")}</h2>
                                                <p>
                                                    <img src={process.env.PUBLIC_URL + "/image/typeFiles/jpgIcon.png"} />
                                                    &nbsp;&nbsp;
                                                    {this.state.data.refPayment || ""}
                                                </p>
                                            </div>
                                        }

                                    </div>
                                </div>




                            </div>
                        }
                    </Translation>


                </WrapperContent>
            </Wrapper >
        )
    }
}

export default VisitReportDetail;
