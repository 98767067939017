import React, { Component } from 'react';
import format from 'date-fns/format';
import './receiveReportPDF.scss'

class ReceiveReportPDFrenderTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            col_span: "",
            dataAll: "",
            header: "",
            topTable:"",
            headerTable: "",
            totalSummary: "",
        }
    }    
    componentWillMount(){
        this.setState({
            dataAll: this.props.dataAll,
            header: this.props.header,
            topTable: this.props.topTable,
            headerTable: this.props.headerTable,
            totalSummary: this.props.totalSummary,
        })
    }
    render() {
        return (            
            this.state.dataAll.map((data, page_index)=>(
                <React.Fragment key={page_index}>
                    <div className='print-page-a4 hoziA4'>
                                <div className="row mt-3">                   
                                        <div className="col-7">
                                            <div className='text-left'>
                                                    <p className='f12' style={{ lineHeight: 1.5 }}>{this.props.projectName}</p>
                                                    <p className='f10'>รายงานสรุปการรับชำระเงิน</p>
                                                    <p className='f10'>เรียงตามผู้ติดต่อ - {this.props.contactName}</p>
                                                    <p className='f10'>เรียงตามประเภทสินค้าและบริการ - {this.props.productAndService}</p>
                                                    <p className='f10'>เรียงตามวิธีชำระเงิน - {this.props.paymentChannel}</p>  
                                                    <p className='f10'>เรียงตามวันที่ {format(this.props.startDate, 'DD/MM/YYYY')} ถึง {format(this.props.endDate, 'DD/MM/YYYY')}</p>
                                            </div>
                                        </div>
                                        {page_index === 0 && this.state.topTable}
                                    <div className="col-12">
                                        {/* table */}
                                        <table className="table tebleBorder mt-3" >
                                            {this.state.headerTable}
                                            <tbody className='tableBody'>
                                                {data}                 
                                            </tbody>
                                            <tfoot>
                                                {page_index === this.state.dataAll.length - 1 && this.state.totalSummary}
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>                       
                                {/* แสดงเลขหน้า และผู้พิมพ์ */}
                                <div className="footer">
                                                <div className="row">
                                                    <div className="col-5" />
                                                        <div className="col-2 text-center">
                                                            <p className="f10 pb-0 mb-0">{page_index+1}/{this.state.dataAll.length}</p>
                                                        </div>
                                                        <div className="col-5 text-right">
                                                            <p className="f10 pb-0 mb-0">
                                                                ผู้พิมพ์ {this.props.printBy} วันที่พิมพ์{format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                                            </p>
                                                        </div>
                                                </div>
                                </div>          
                    </div>
                </React.Fragment>
            ))
        )
    }

}
export default ReceiveReportPDFrenderTable
