
import React, { Component } from 'react';

import "./styles/generalLedger.scss";

class GeneralLedgerPDFTable extends Component {

render () {
    return (
        <React.Fragment>
                <thead className='text-center'>
                        <tr className='table-row-header'>
                            <th className='text-center date' colSpan={1}>วันที่</th>
                            <th className='text-left refNo' colSpan={2}>เลขที่เอกสาร</th>
                            <th className='text-left description' colSpan={1}>คำอธิบาย</th>
                            <th className='text-center record' colSpan={1}>เดบิต</th>
                            <th className='text-center record' colSpan={1}>เครดิต</th>
                            <th className='text-center record' colSpan={1}>คงเหลือ</th>
                        </tr>
                </thead>
                <tbody>
                        {this.props.data.map((data)=> {
                            return data
                        })}
                </tbody>
    </React.Fragment>
    )
}

}
export default GeneralLedgerPDFTable