/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ParcelStatus = "DELETED" | "NOT_RECEIVE" | "RECEIVE" | "RETURN" | "%future added value";
export type parcelListQueryVariables = {|
  status?: ?string,
  first?: ?number,
  last?: ?number,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  order?: ?string,
|};
export type parcelListQueryResponse = {|
  +parcelViewer: ?{|
    +allParcel: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +trackingNumber: string,
          +serviceName: string,
          +description: string,
          +imageParcel: string,
          +added: any,
          +status: ParcelStatus,
          +docNumber: ?string,
          +updated: any,
          +userFirstName: string,
          +userLastName: string,
          +deleterName: ?string,
          +deleteRemark: ?string,
          +parcelNoti: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +creator: ?string,
                +added: any,
              |}
            |}>
          |},
          +residential: {|
            +id: string,
            +name: string,
          |},
          +taker: ?{|
            +id: string,
            +lastLogin: ?any,
            +username: string,
            +juristic: ?{|
              +firstName: string,
              +lastName: string,
            |},
            +tenant: ?{|
              +nameTitle: ?string,
              +firstName: string,
              +lastName: ?string,
            |},
          |},
          +takerName: ?string,
          +receiverName: ?string,
          +creatorName: ?string,
          +pickupDate: ?any,
          +returnRemark: ?string,
        |}
      |}>,
    |}
  |}
|};
export type parcelListQuery = {|
  variables: parcelListQueryVariables,
  response: parcelListQueryResponse,
|};
*/


/*
query parcelListQuery(
  $status: String
  $first: Int
  $last: Int
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $order: String
) {
  parcelViewer {
    allParcel(status: $status, first: $first, last: $last, search: $search, startDate: $start_date, endDate: $end_date, order: $order) {
      totalCount
      edges {
        node {
          id
          name
          trackingNumber
          serviceName
          description
          imageParcel
          added
          status
          docNumber
          updated
          userFirstName
          userLastName
          deleterName
          deleteRemark
          parcelNoti {
            edges {
              node {
                id
                creator
                added
              }
            }
          }
          residential {
            id
            name
          }
          taker {
            id
            lastLogin
            username
            juristic {
              firstName
              lastName
              id
            }
            tenant {
              nameTitle
              firstName
              lastName
              id
            }
          }
          takerName
          receiverName
          creatorName
          pickupDate
          returnRemark
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trackingNumber",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageParcel",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userFirstName",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userLastName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleterName",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleteRemark",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "ParcelNotiNodeConnection",
  "kind": "LinkedField",
  "name": "parcelNoti",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ParcelNotiNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ParcelNotiNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creator",
              "storageKey": null
            },
            (v15/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "ResidentialNode",
  "kind": "LinkedField",
  "name": "residential",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastLogin",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameTitle",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "takerName",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receiverName",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorName",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupDate",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "returnRemark",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "parcelListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ParcelViewer",
        "kind": "LinkedField",
        "name": "parcelViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "ParcelNodeConnection",
            "kind": "LinkedField",
            "name": "allParcel",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ParcelNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParcelNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "kind": "LinkedField",
                        "name": "taker",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JuristicNode",
                            "kind": "LinkedField",
                            "name": "juristic",
                            "plural": false,
                            "selections": [
                              (v27/*: any*/),
                              (v28/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantNode",
                            "kind": "LinkedField",
                            "name": "tenant",
                            "plural": false,
                            "selections": [
                              (v29/*: any*/),
                              (v27/*: any*/),
                              (v28/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v30/*: any*/),
                      (v31/*: any*/),
                      (v32/*: any*/),
                      (v33/*: any*/),
                      (v34/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "parcelListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ParcelViewer",
        "kind": "LinkedField",
        "name": "parcelViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "ParcelNodeConnection",
            "kind": "LinkedField",
            "name": "allParcel",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ParcelNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParcelNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "kind": "LinkedField",
                        "name": "taker",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JuristicNode",
                            "kind": "LinkedField",
                            "name": "juristic",
                            "plural": false,
                            "selections": [
                              (v27/*: any*/),
                              (v28/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantNode",
                            "kind": "LinkedField",
                            "name": "tenant",
                            "plural": false,
                            "selections": [
                              (v29/*: any*/),
                              (v27/*: any*/),
                              (v28/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v30/*: any*/),
                      (v31/*: any*/),
                      (v32/*: any*/),
                      (v33/*: any*/),
                      (v34/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "55c5d94da94acc12f567144185868906",
    "id": null,
    "metadata": {},
    "name": "parcelListQuery",
    "operationKind": "query",
    "text": "query parcelListQuery(\n  $status: String\n  $first: Int\n  $last: Int\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $order: String\n) {\n  parcelViewer {\n    allParcel(status: $status, first: $first, last: $last, search: $search, startDate: $start_date, endDate: $end_date, order: $order) {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          trackingNumber\n          serviceName\n          description\n          imageParcel\n          added\n          status\n          docNumber\n          updated\n          userFirstName\n          userLastName\n          deleterName\n          deleteRemark\n          parcelNoti {\n            edges {\n              node {\n                id\n                creator\n                added\n              }\n            }\n          }\n          residential {\n            id\n            name\n          }\n          taker {\n            id\n            lastLogin\n            username\n            juristic {\n              firstName\n              lastName\n              id\n            }\n            tenant {\n              nameTitle\n              firstName\n              lastName\n              id\n            }\n          }\n          takerName\n          receiverName\n          creatorName\n          pickupDate\n          returnRemark\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0751d5b0a3825c1f3f19cf97e62e4dc7';

module.exports = node;
