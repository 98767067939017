import React, { Component } from 'react'
import { format } from "date-fns";
import _ from "lodash"
import getNameResidential from "../../../libs/getNameResidential"
import { Dropdown } from "react-bootstrap";

require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

export default class reportStockExcel extends Component {

  getStatusList = (inputStatusList) => {
    let result = ""

    switch (inputStatusList) {
      case "SELL":
        result = "ขาย"
        break;
      case "BUY":
        result = "ซื้อ"
        break;
      case "CONVERSION_BALANCE":
        result = "ยอดยกมา"
        break;
      case "UNIT":
        result = "สิทธิ์"
        break;
      case "PROJECT":
        result = "เบิก"
        break;
    }

    return result
  }

  downloadExcel = () => {
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('StockMovementReportsAndSupplies');

    // setWidthcolumns
    let columns = [
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ]

    // setBorder
    let borders = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }

    let fontSizes = { size: 11.5 }
    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let fontBold = { size: 11.5, bold: true };

    let status = "";

    if(this.props.statusTable === 'all') {
      status = "ทั้งหมด"
    }
    else if(this.props.statusTable === 'buy'){
      status = "ซื้อ"
    }
    else if(this.props.statusTable === 'unit'){
      status = "สิทธิ์"
    }
    else if(this.props.statusTable === 'sell'){
      status = "ขาย"
    }
    else if(this.props.statusTable === 'project'){
      status = "เบิก"
    }

    worksheet.getCell('A1').value = this.props.selfProject?.name
    worksheet.getCell('A1').font = {
      size: 11.5,
      bold: true
    };

    worksheet.getCell('A2').value = "รายงานสต็อคและวัสดุสิ้นเปลืองเรียงตามเลขที่"
    worksheet.getCell('A2').font = fontBold;

    worksheet.getCell('A3').value = "สถานะ " + status;
    worksheet.getCell('A3').font = fontBold;

    worksheet.getCell('A4').value = `ช่วงเวลา: ${this.props.startDate ? format(this.props.startDate, "DD-MM-YYYY") : "-"} ถึง ${this.props.endDate ? format(this.props.endDate, "DD-MM-YYYY") : "-"}`
    worksheet.getCell('A4').font = fontBold;

    let valueHead = []

    if(this.props.statusTable === "all"){
      valueHead = ["เลขNo.", "ประเภทรายการ", "วันที่", "เอกสาร", "วัตถุประสงค์ในการเบิก", "เลขห้อง", "ชื่อผู้รับสินค้า", "เบิกโดย"]
    }else{
      valueHead = ["เลขNo.", "วันที่", "เอกสาร", "วัตถุประสงค์ในการเบิก", "เลขห้อง", "ชื่อผู้รับสินค้า", "เบิกโดย"]
    }

    worksheet.getRow(5).values = valueHead

    _.forEach(valueHead, (itemHead, indexHead) => {
      let cell = worksheet.getRow(5).getCell(1 + indexHead)
      cell.font = fontBold;
      cell.border = borders;
      cell.alignment = textCenter

    })

    let details = []

    {_.map(this.props.keycardTransactionExport, (item, index) => {
      if(this.props.statusTable === "all"){
        details.push([
          `${item.node.noNumber || "-"}`,
          `${this.getStatusList(item.node.list)}`,
          `${format(item.node.date, "DD/MM/YYYY")}`,
          `${item.node.ref}`,
          `${item.node.requisitionList.edges[0]?.node?.requisitionNote?.typeRequisition === "UNIT"
              ? "เบิกตามสิทธิ์"
              : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "tenant"
                ? "เบิกใช้งานลูกบ้าน"
                : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "juristic"
                  ? "เบิกใช้งานส่วนกลาง"
                  : "-"
            }`,
          `${(item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR")
              ? item.node.contact.name
              :  item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.name || "-"
            }`,
          `${item.node.requisitionList.edges[0]?.node?.requisitionNote?.typeRequisition === "UNIT" && (item.node.requisitionList?.edges[0]?.node?.requisitionNote?.firstNameRecipient  || item.node.requisitionList?.edges[0]?.node?.requisitionNote?.lastNameRecipient)
              ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.firstNameRecipient|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.lastNameRecipient || "")
              : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "tenant"
                ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.firstName|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.lastName || "")
                : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "juristic"
                  ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName || "")
                  : (item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR")
                    ? getNameResidential(item.node.contact.firstName || "", item.node.contact.lastName || "")
                    : "-"
            }`,
          `${(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName || item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName) 
                ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName || "")
                : "-"
            }`,
        ])
      }else{
        details.push([
          `${item.node.noNumber || "-"}`,
          `${format(item.node.date, "DD/MM/YYYY")}`,
          `${item.node.ref}`,
          `${item.node.requisitionList.edges[0]?.node?.requisitionNote?.typeRequisition === "UNIT"
              ? "เบิกตามสิทธิ์"
              : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "tenant"
                ? "เบิกใช้งานลูกบ้าน"
                : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "juristic"
                  ? "เบิกใช้งานส่วนกลาง"
                  : "-"
            }`,
          `${(item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR")
              ? item.node.contact.name
              :  item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.name || "-"
            }`,
          `${item.node.requisitionList.edges[0]?.node?.requisitionNote?.typeRequisition === "UNIT" && (item.node.requisitionList?.edges[0]?.node?.requisitionNote?.firstNameRecipient  || item.node.requisitionList?.edges[0]?.node?.requisitionNote?.lastNameRecipient)
              ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.firstNameRecipient|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.lastNameRecipient || "")
              : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "tenant"
                ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.firstName|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.lastName || "")
                : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "juristic"
                  ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName || "")
                  : (item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR")
                    ? getNameResidential(item.node.contact.firstName || "", item.node.contact.lastName || "")
                    : "-"
            }`,
          `${(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName || item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName) 
                ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName || "")
                : "-"
            }`,
        ])
      }
    })}

    _.map(details, (value, indexHead) => {

      worksheet.getRow(6 + indexHead).values = value

      _.map(value, (value2, indexValue2) => {

        let cell = worksheet.getRow(6 + indexHead).getCell(1 + indexValue2)
        
        cell.font = fontSizes;
        cell.border = borders;

        if(indexValue2 === 3 && this.props.statusTable !== "all"){
          cell.alignment = textCenter
        }
        if(indexValue2 === 4){
          cell.alignment = textCenter
        }
        if(indexValue2 === 5 && this.props.statusTable === "all"){
          cell.alignment = textCenter
        }
      })
    })

    worksheet.columns = columns;

    workbook.xlsx.writeBuffer()
    .then((data) => {
      const blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      let nameFile = `ExcelStockMovementReportsAndSupplies`
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = nameFile;
      a.click();
    });
  }

  render() {
    return (
      <Dropdown.Item onClick={this.downloadExcel}>
        Excel
      </Dropdown.Item>
    )
  }
}
