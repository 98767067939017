import React from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5xy from "@amcharts/amcharts5/xy";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { fetchQuery } from "relay-runtime";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import enLocale from "date-fns/locale/en";
import i18next from "i18next";

const query = graphql`
    query expenseBarChartsQuery ($startDateFirst: DateTime, $endDateFirst: DateTime, $startDateSecond: DateTime, $endDateSecond: DateTime) {
        chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: "5", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
            edges {
                node {
                    chartOfAccountCode
                    yearFirst
                    yearSecond
                    totalHistoricalDiffFirst
                    totalHistoricalDiffSecond
                }
            }
        }
    }
`;

class ExpenseBarCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],  
    };
    this.setDataRender = this.setDataRender.bind(this)
  }
  
  componentDidMount() {
    this.queryExpenseData(); 
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.state !== prevProps.state) {
        this.queryExpenseData(); 
    }
  }

  setDataRender(data_render, chart_of_account, key, code) {
    let year_first = chart_of_account.node.yearFirst;
    let year_second = chart_of_account.node.yearSecond;
    let total_historical_diff_first = chart_of_account.node.totalHistoricalDiffFirst;
    let total_historical_diff_second = chart_of_account.node.totalHistoricalDiffSecond;
    Object.assign(data_render, {
        [key]: {
            year_first: year_first + data_render[key].year_first + total_historical_diff_first,
            year_second: year_second + data_render[key].year_second + total_historical_diff_second,
            code: code,
        }
    })
}

queryExpenseData() {
    fetchQuery(environment,query,
        {
            startDateFirst: this.props.state.start_date_first,
            endDateFirst: this.props.state.end_date_first,
            startDateSecond: this.props.state.start_date_second,
            endDateSecond: this.props.state.end_date_second,
        }
    ).then(props => {
        if (props) {
            let data_render = {
              [`${i18next.t("expenseChart:Net cost of sales")}`]: {year_first: 0, year_second: 0, code: "5100"},
              [`${i18next.t("expenseChart:Salary and wages")}`]: {year_first: 0, year_second: 0, code: "5200"},
              [`${i18next.t("expenseChart:Expense - by agreement")}`]: {year_first: 0, year_second: 0, code: "5300"},
              [`${i18next.t("expenseChart:Operation expense(Office)")}`]: {year_first: 0, year_second: 0, code: "5400"},
              [`${i18next.t("expenseChart:Utilities")}`]: {year_first: 0, year_second: 0, code: "5500"},
              [`${i18next.t("expenseChart:Repairs and maintenance")}`]: {year_first: 0, year_second: 0, code: "5600"},
              [`${i18next.t("expenseChart:Depreciation and Amortization")}`]: {year_first: 0, year_second: 0, code: "5700"},
              [`${i18next.t("expenseChart:Other expenses")}`]: {year_first: 0, year_second: 0, code: "other_expense"},
            };
            // let year_first_total = 0;
            // let year_second_total = 0;

            props.chartOfAccountSummaryIncomeExpense.edges.forEach((chart_of_account => {

                let code = chart_of_account.node.chartOfAccountCode;
                // year_first_total += (chart_of_account.node.yearFirst + chart_of_account.node.totalHistoricalDiffFirst);
                // year_second_total += (chart_of_account.node.yearSecond + chart_of_account.node.totalHistoricalDiffSecond);

                if (code.substring(0, 4) === '5100') {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("expenseChart:Net cost of sales")}`, code.substring(0, 4))

                }else if (code.substring(0, 4) === '5200') {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("expenseChart:Salary and wages")}`, code.substring(0, 4))

                }else if (code.substring(0, 4) === '5300') {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("expenseChart:Expense - by agreement")}`, code.substring(0, 4))

                }else if (code.substring(0, 4) === '5400') {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("expenseChart:Operation expense(Office)")}`, code.substring(0, 4))

                }else if (code.substring(0, 4) === '5500') {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("expenseChart:Utilities")}`, code.substring(0, 4))

                }else if (code.substring(0, 4) === '5600') {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("expenseChart:Repairs and maintenance")}`, code.substring(0, 4))

                } else if (code.substring(0, 4) === '5700') {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("expenseChart:Depreciation and Amortization")}`, code.substring(0, 4))

                }else {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("expenseChart:Other expenses")}`, "other_expense")

                }

            }))
            const result = [];
            for (const key in data_render) {
                result.push({ expenseGroup: key, totalAmountCurrent: data_render[key].year_first, totalAmountPrevious: data_render[key].year_second });
            }
            let resultFilter = result.filter(item => item.totalAmountCurrent > 0 || item.totalAmountPrevious > 0);
            this.genChart(resultFilter)
    }})
  }
  
  genChart(chartData) {
    if (this.root) {
        // Dispose of the old root and chart
        this.root.dispose(); 
        this.chart.dispose(); 
    }   
    let locale = `${i18next.t("expenseChart:Locale")}`;
    let localeUse = locale === 'thLocale'? thLocale : enLocale;
    let monthCurrent =  `${!this.props.state.showMonth && this.props.state.period !== 'month' ? format(this.props.state.start_date_first, 'MMM YY', {locale: localeUse}) + " -" :'' } ${format(this.props.state.end_date_first, 'MMM YY', {locale: localeUse})}`
    let monthPrevious =  `${!this.props.state.showMonth && this.props.state.period !== 'month' ? format(this.props.state.start_date_second, 'MMM YY', {locale: localeUse}) + " -" :'' } ${format(this.props.state.end_date_second, 'MMM YY', {locale: localeUse})}`
    let data = chartData;
    let root = am5.Root.new("expenseBarCharts");

           // Set themes
           root.setThemes([
            am5themes_Animated.new(root)
          ]);
          
          
          // Create chart
          let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            layout: root.verticalLayout,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 30,
          }));
  
          chart.children.unshift(am5.Label.new(root, {
            text: `${i18next.t("expenseChart:Graph comparing expense between")} ${monthCurrent} ${i18next.t("expenseChart:and")} ${monthPrevious}`,
            fontSize: 16,
            fontWeight: 600,
            textAlign: "left",
            paddingTop: 0,
            paddingBottom: 80,
          }));
          
          chart.get("colors").set("colors", [
            am5.color(0xBD295C),
            am5.color(0xE77490),
          ]);

          // Add legend
          let legend = chart.children.push(
            am5.Legend.new(root, {
              centerX: am5.p50,
              x: am5.p50
            })
          );
          
          // Create axes
          let xRenderer = am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9
          })
          
          let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "expenseGroup",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
          }));
          
          xRenderer.grid.template.setAll({
            location: 1
          })

          xRenderer.labels.template.setAll({
            rotation: 45,
            centerY: am5.p50,
            centerX: 70,
            paddingLeft: 15,
          });
          
  
          xAxis.data.setAll(data);
                    
          let label = am5.Label.new(root, {
            rotation: 0,
            text: `(${i18next.t("expenseChart:baht")})`,
            y: -50,
            x: 78,
          })
          
          let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
              strokeOpacity: 0.1
            })
          }));

          yAxis.children.unshift(
            label
          );

          // Add series
          function makeSeries(name, fieldValue) {
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
              name: name,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: fieldValue,
              categoryXField: "expenseGroup",
              tooltip: am5.Tooltip.new(root, {
                // ทำให้ตอน hover สามารถเลื่อนไปคลิ๊กได้
                keepTargetHover: true,
              })
            }));
             // ทำให้สามารถ click link ได้
            series.get("tooltip").label.set("interactive", true);
  
            series.columns.template.setAll({
              tooltipHTML: `<b style="color: black;">{valueY.formatNumber('#,###.00')}</b>`,
              width: am5.percent(40),
              tooltipY: 0,
              strokeOpacity: 0,
            });
  
            series.data.setAll(data);
  
            series.appear();
          
            series.bullets.push(function() {
              return am5.Bullet.new(root, {
                locationY: 0,
                sprite: am5.Label.new(root, {
                  fill: root.interfaceColors.get("alternativeText"),
                  centerY: 0,
                  centerX: am5.p50,
                  populateText: true
                })
              });
            });
          
            legend.data.push(series);
          }
          
          makeSeries(monthCurrent, "totalAmountCurrent");
          makeSeries(monthPrevious, "totalAmountPrevious");
          
          
          let exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
          });
          let annotator = am5plugins_exporting.Annotator.new(root, {});
  
          exporting.get("menu").set("items", [{
            type: "format",
            format: "jpg",
            label: "Export Image"
          },{
            type: "format",
            format: "print",
            label: "Print"
          },{
            type: "custom",
            label: "Remark",
            callback: function() {
              this.close();
              annotator.toggle();
            }
          }]);
  
          chart.appear(1000, 100);     
          root._logo.dispose();

        this.chart = chart;
        this.root = root;
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    
    return (
      <React.Fragment>
          <div id="expenseBarCharts"></div>      
      </React.Fragment>
    );
  }
}

export default ExpenseBarCharts;
