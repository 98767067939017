import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import SelectContact from '../../income/selectContact';
// import ReceiptDepositContactCreateForm from "../../income/receipt_deposit/receiptDepositContactCreateForm";
import PrepaidDepositForm from "./prepaidDepositCreateForm";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";


class PrepaidDepositCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer_list: [],
            accountType: "prepaid_deposit",
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({customer_list: contact_list})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    {this.props.match.params.status === 'select_customer' &&
                    <SelectContact updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                   nextLink="/accounting/expense/prepaid-deposit/form/create"
                                   backLink="/accounting/expense/prepaid-deposit/list/all"
                                   disable_select_type={true}
                    />
                    }

                    {/*{this.props.match.params.status === 'create' &&*/}
                    {/*    <ReceiptDepositContactCreateForm customerList={this.state.customer_list} category={this.state.accountType}*/}
                    {/*    headerWord='accounting:add_prepaid_deposit' backLink="/accounting/expense/prepaid-deposit/list/all"*/}
                    {/*    chartOfAccountCode='1151-01'/>*/}
                    {/*}*/}
                    {this.props.match.params.status === 'create' &&
                    <PrepaidDepositForm customerList={this.state.customer_list}
                                        category={this.state.accountType}
                                        headerWord='accounting:add_prepaid_deposit'
                                        backLink="/accounting/expense/prepaid-deposit/list/all"
                    />
                    }

                </WrapperContent>
            </Wrapper>


        )
    }
}

export default PrepaidDepositCreate;