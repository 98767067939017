import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import {fetchQuery} from "react-relay";

const query = graphql`
  query individualReceiptReportSummaryQuery(
    $startDate: DateTime
    $endDate: DateTime
    $search: String
    $customerType: String
    $productAndService: String
    $contact: String
    $overdueDate: String
    $rangeOverdueDate: [Int]
    $dueDate: Date!
  ) {
    invoiceViewer {
      summaryReceivableOutstanding(
        approveOnly: true
        receivableOutstanding: true
        startDate: $startDate
        endDate: $endDate
        search: $search
        customerType: $customerType
        productAndService: $productAndService
        statusIn: ["active", "overdue", "partial_payment", "paid"]
        contact: $contact
        overdueDate: $overdueDate
        rangeOverdueDate: $rangeOverdueDate
      )      
      summaryIndividualReceivableReport(
        approveOnly: true
        receivableOutstanding: true
        startDate: $startDate
        endDate: $endDate
        search: $search
        customerType: $customerType
        productAndService: $productAndService
        statusIn: ["active", "overdue", "partial_payment", "paid"]
        contact: $contact
        overdueDate: $overdueDate
        rangeOverdueDate: $rangeOverdueDate
      ) {
        total
        balance
      }
  }

  reportOutstandingReceivableSummary(
      dueDate: $dueDate
      search: $search
      rangeOverdueDate: $rangeOverdueDate
      overdueDate: $overdueDate
    ) {
      edges {
        node {
          id
          name
          refNumber
          firstName
          lastName
          outstandingBalance
        }
      }
    }

}`;


async function receivableOutstandingReportSummary(props) {
    return fetchQuery(environment, query, {
      startDate: props.end_date,
      endDate: props.end_date,
      search: props.search,
      customerType: props.customer_type,
      productAndService: props.product_and_service,
      contact: props.contact,
      overdueDate: props.overdue_date,
      rangeOverdueDate: props.range_overdue_date,
      dueDate: props.end_date.toISOString().slice(0, 10),
     });
 }

export default {
  receivableOutstandingReportSummary,
}