import React, { Component } from "react";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import { Translation } from "react-i18next";
import "../css/invoiceDetail.scss";
import { Link, Redirect ,withRouter } from "react-router-dom";
import { format, differenceInCalendarDays } from "date-fns";
import thLocale from "date-fns/locale/th";
import Swal from "sweetalert2";
import InvoiceDetailTable from "./invoiceDetailTable";
import ChangeStatusInvoiceMutation from "./mutations/ChangeStatusInvoiceMutation";
import CreateEditorDocumentLogMutation from "./mutations/CreateEditorDocumentLogMutation"
import InvoiceSummary from "../invoiceSummary";
import getNameResidential from "../../../../libs/getNameResidential";
import getNameResidentialRent from "../../../../libs/getNameResidentialRent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import numberWithComma from "../../../../libs/numberWithComma";
import InputAddress from "../../../../libs/autoCompleteAddress";
import _ from "lodash";
import i18n from "i18next";
import { Modal} from 'react-bootstrap';
import jwtDecode from 'jwt-decode'
import i18next from "i18next";

const invoiceNameAddressKey = [ "firstName", "lastName", "taxIdNumber","address","city","province","district","postalCode","email","phone" ];

class InvoiceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issuedDate: "",
      dueDate: "",
      dateCount: "",
      status: "",
      redirect: false,
      transaction_list: [],
      receiveDeposit: 0,
      loading: false,
      receipt_deposit_list: [],
      showModal:false,
      token: jwtDecode(window.localStorage.getItem('token')),
      invoiceNameAddress:[]
    };

    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onError = this.onError.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.submit = this.submit.bind(this)
  }

  componentWillMount() {
    let list = [...this.state.transaction_list];
    let receipt_deposit_list = [];
    let transactions = [...this.props.transactions];
    let amountUse = 0
    transactions.forEach((transaction) => {
      list = [...list, { ...transaction.node }];

      transaction.node.receiptDepositTransactionUsed.edges.forEach(
        (rd_group) => {
          if (rd_group.node.receiptDepositTransaction.couple !== "NO" && rd_group.node.coupleUse !== "NO" ) {
            receipt_deposit_list.push(rd_group)
            amountUse += rd_group.node.amount;
          }
        }
      );
    });
    
    this.setState({
      transaction_list: list,
      receipt_deposit_list: receipt_deposit_list,
      receiveDeposit: amountUse
    });

    ///edit invoice address
    let state = { ...this.state};

    if (this.props.invoiceQuery.invoice) {
        let key = Object.keys(this.props.invoiceQuery.invoice);
        key.forEach((key) => {
          state[key] = this.props.invoiceQuery.invoice[key];
          state['registeredCity'] = this.props.invoiceQuery.invoice['city'];
          state['registeredDistrict'] = this.props.invoiceQuery.invoice['district'];
          state['registeredProvince'] = this.props.invoiceQuery.invoice['province'];
          state['registeredPostalCode'] = this.props.invoiceQuery.invoice['postalCode'];
        });
    } else {
      invoiceNameAddressKey.forEach((key) => {
        state[key] = '';
        });
    }

     this.setState({invoiceNameAddress : state});
  }

  onError() {
    Swal.fire("Error", "", "error");
  }

  onChangeStatus() {
    Swal.fire({
      title: i18n.t('invoiceDetail:Are you sure to cancel this invoice?'),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: i18n.t('invoiceDetail:No'),
      confirmButtonText: i18n.t('invoiceDetail:Yes'),
    }).then(async (result) => {
      if (result.value) {

        let { value: void_remark } = await Swal.fire({
          title: i18n.t('invoiceDetail:Note'),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: i18n.t('invoiceDetail:Cancel'),
          confirmButtonText: i18n.t('invoiceDetail:Confirm'),
          inputValidator: (value) => {
            if (!value) {
              return i18n.t('invoiceDetail:Please enter a cancellation note');
            }
          },
        });

        if (void_remark) {
          this.setState({ loading: true });

          let input = {
            id: this.props.invoiceQuery.invoice.id,
            status: "void",
            voidRemark: void_remark,
          };

          ChangeStatusInvoiceMutation(
            input,
            (response) => {
              if (response.updateInvoice.newInvoice) {
                Swal.fire(i18n.t('invoiceDetail:Canceled successfully!'), i18n.t('invoiceDetail:Already canceled this invoice'), "success")
                this.setState({ redirect: true, loading: false });
              } else {
                Swal.fire(
                  i18n.t('invoiceDetail:Failed to cancel the invoice!'),
                  response.updateInvoice.warningText,
                  "warning"
                );
                this.setState({ loading: false });
              }
            },
            () => {
              this.setState({ loading: false });
              this.onError();
            }
          );
        }
      } else {
        this.setState({ loading: false });
      }
    });
  }


  handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    
    let state = _.set(_.cloneDeep(this.state), name, value);
    this.setState(state);
 }
 
 onSelect(fullAddress) {

  const { registeredDistrict, registeredCity, registeredProvince, registeredPostalCode } = fullAddress

  let value = _.cloneDeep(this.state.invoiceNameAddress);
  _.set(value,'registeredDistrict',registeredDistrict)
  _.set(value,'registeredCity',registeredCity)
  _.set(value,'registeredProvince',registeredProvince)
  _.set(value,'registeredPostalCode',registeredPostalCode)

  this.setState({
    invoiceNameAddress:value
  })

}

  onChangeEdit() {
    Swal.fire({
      title: i18n.t('invoiceDetail:Are you sure to edit this invoice?'),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: i18n.t('invoiceDetail:No'),
      confirmButtonText: i18n.t('invoiceDetail:Yes'),
    }).then(async (result) => {
      if (result.value) {
        this.setState({ showModal: true });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  submit(e) {

    e.preventDefault();

    this.props.history.push({
      pathname: `/document/invoice/${this.props.invoiceQuery.invoice.id}`,
      state: {
        firstName : this.state.invoiceNameAddress.firstName,
        lastName : this.state.invoiceNameAddress.lastName,
        taxIdNumber : this.state.invoiceNameAddress.taxIdNumber,
        address : this.state.invoiceNameAddress.address,
        city : this.state.invoiceNameAddress.registeredCity,
        province : this.state.invoiceNameAddress.registeredProvince,
        district : this.state.invoiceNameAddress.registeredDistrict,
        postalCode : this.state.invoiceNameAddress.registeredPostalCode,
        email : this.state.invoiceNameAddress.email,
        phone : this.state.invoiceNameAddress.phone,
      },
    })

    let input = {
      docNumber: this.props.invoiceQuery.invoice.docNumber,
      firstName : this.state.invoiceNameAddress.firstName,
      lastName : this.state.invoiceNameAddress.lastName,
      taxIdNumber : this.state.invoiceNameAddress.taxIdNumber,
      phone : this.state.invoiceNameAddress.phone,
      email : this.state.invoiceNameAddress.email,
      address : this.state.invoiceNameAddress.address,
      city : this.state.invoiceNameAddress.registeredCity,
      province : this.state.invoiceNameAddress.registeredProvince,
      country : this.state.invoiceNameAddress.registeredCountry,
      district : this.state.invoiceNameAddress.registeredDistrict,
      postalCode : this.state.invoiceNameAddress.registeredPostalCode,
    };


    CreateEditorDocumentLogMutation(
      input,
      (response) => {
        // console.log(response)
      }
    )
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/accounting/income/invoice/list/all"} />;
    }

    return (
      <WrapperContent>
        <AccountingTopMenuNavigation mini={true} />
        <div className="container-fluid box" id="invoice-detail">
          <div className="row justify-content-between pb-2">
            <div className="col">
              <h3 className="mb-4 ">
                <Link to="/accounting/income/invoice/list/all">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/wrapperContent/back.png"
                    }
                    alt="back"
                    className="back"
                  />
                </Link>
                <Translation>
                  {(t) => <span>{t("accounting:invoice")+ " " + "(IV)"}</span>}
                </Translation>
              </h3>
            </div>
            <Translation>
              {t =>
                <div className="col text-right">
                  {this.props.invoiceQuery.invoice.status !== "VOID" &&
                    _.some(JSON.parse(localStorage.getItem("permission_list")), {
                      codename: "accounting_invoice_delete",
                    }) && (
                      <button
                        className="btn btn-danger add mr-3"
                        disabled={this.state.loading}
                        onClick={this.onChangeStatus}
                      >
                        {this.state.loading && (
                          <span className="spinner-border spinner-border-sm align-middle mr-2" />
                        )}
                        {t('invoiceDetail:Cancel_invoice')}
                      </button>
                    )}
                     {
                    // _.some(JSON.parse(localStorage.getItem("permission_list")), {
                    //   codename: "accounting_invoice_delete",
                    // }) && (
                      // this.state.token?.site?.includes('sklasmanagement') &&
                        <button
                        className="btn btn-create add mr-3"
                        disabled={this.state.loading}
                        onClick={this.onChangeEdit}
                      >
                        {this.state.loading && (
                          <span className="spinner-border spinner-border-sm align-middle mr-2" />
                        )}
                        {t('invoiceDetail:Edit_invoice')}
                      </button>
                      }
                     
                    {/* // ) */}
                    {/* } */}
                  {/*<Link to="/accounting/income/invoice/invoice_print/full" target="_blank">*/}
                  {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                    codename: "accounting_invoice_print",
                  }) && (
                      <Link
                        to={"/document/invoice/" + this.props.invoiceQuery.invoice.id}
                        target="_blank"
                      >
                        <button
                          className="btn btn-primary add"
                          disabled={this.state.loading}
                        >
                          {t('invoiceDetail:Print')}
                        </button>
                      </Link>
                    )}
                </div>}
            </Translation>
          </div>

          <Modal show={this.state.showModal} dialogClassName="modal-approve" id="modal" >
              <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                  <div onSubmit={this.submit}> 
                  <h3 className="pt-2 pb-2">{i18next.t("editAddress:Edit customer name-surname / address")}</h3>
                  <form >
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label htmlFor="name">{i18next.t("editAddress:Name")}</label>
                        <input type="text" class="form-control" id="firstName" name="invoiceNameAddress.firstName" value={this.state.invoiceNameAddress.firstName} 
                         onChange={this.handleInputChange}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label htmlFor="surname">{i18next.t("editAddress:Surname")}</label>
                        <input type="text" class="form-control" id="lastName" name="invoiceNameAddress.lastName" value={this.state.invoiceNameAddress.lastName} 
                        onChange={this.handleInputChange}/>
                      </div>
                    </div>
                    <div class="form-group">
                      <label htmlFor="taxIdNumber">{i18next.t("editAddress:Tax payer identification/Identification number")}</label>
                      <input type="text" class="form-control" id="taxIdNumber" name="invoiceNameAddress.taxIdNumber" value={this.state?.invoiceNameAddress?.taxIdNumber?.replace('--', '')}
                      onChange={this.handleInputChange}/>
                    </div>
                    <div class="form-group">
                      <label htmlFor="inputAddress2">{i18next.t("editAddress:Address")}</label>
                      <textarea cols="" rows="3" className="form-control" id="address"
                       name="invoiceNameAddress.address" maxLength={256} value={this.state.invoiceNameAddress.address} onChange={this.handleInputChange}/>
                    </div>
                    <div class="form-row">
                     <div class="form-group col-md-6">
                        <label htmlFor="city">{i18next.t("editAddress:Sub-district")}</label>
                        <InputAddress
                             address="invoiceNameAddress.registeredDistrict"
                             value={this.state.invoiceNameAddress.registeredDistrict}
                             onChange={this.handleInputChange}
                             onSelect={this.onSelect}
                             mailing={false}
                             className="form-control"    
                         />
                      </div>
                      <div class="form-group col-md-6">
                        <label htmlFor="city">{i18next.t("editAddress:District")}</label>
                        <InputAddress
                             address="invoiceNameAddress.registeredCity"
                             value={this.state.invoiceNameAddress.registeredCity}
                             onChange={this.handleInputChange}
                             onSelect={this.onSelect}
                             mailing={false}
                             className="form-control"    
                         />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label htmlFor="inputState">{i18next.t("editAddress:Province")}</label>
                        <InputAddress
                             address="invoiceNameAddress.registeredProvince"
                             value={this.state.invoiceNameAddress.registeredProvince}
                             onChange={this.handleInputChange}
                             onSelect={this.onSelect}
                             mailing={false}
                             className="form-control"    
                         />
                      </div>
                      <div class="form-group col-md-6">
                        <label htmlFor="inputZip">{i18next.t("editAddress:Zip code")}</label>
                        <InputAddress
                             address="invoiceNameAddress.registeredPostalCode"
                             value={this.state.invoiceNameAddress.registeredPostalCode}
                             onChange={this.handleInputChange}
                             onSelect={this.onSelect}
                             mailing={false}
                             className="form-control"    
                         />
                      </div>
                    </div>
                    {/* <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="city">อีเมล</label>
                        <input type="text" className="form-control" id="email" name="invoiceNameAddress.email" value={this.state.invoiceNameAddress.email}  onChange={this.handleInputChange}/>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="phone">โทร</label>
                        <input type="number" className="form-control" id="phone" name="invoiceNameAddress.phone" value={this.state.invoiceNameAddress.phone}  onChange={this.handleInputChange} />
                      </div>
                    </div> */}
                    <div className="col-12  mt-3 text-right">
                  
                    <button type="button" className="btn btn-danger mr-2" onClick={() => this.setState({showModal : false})}>
                        <span>{i18next.t("editAddress:Cancel")}</span>
                    </button>
                    <button
                          className="btn btn-primary"
                          disabled={this.state.loading} type="submit"
                        >
                          {i18next.t("editAddress:Print")}{localStorage.getItem("language") === "th" && "ใบแจ้งหนี้"}
                    </button>
                </div>
                  </form>
                  </div>
              </Modal.Body>

            </Modal>


          <div className="content-inner">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <p><Translation>{t => <strong>{t('invoiceDetail:Customer')}</strong>}</Translation></p>
                    <p>
                      {this.props.contact.refNumber + " "}
                      {this.props.contact.typeOfContact === "RESIDENTIAL"
                        ? this.props.invoiceQuery.invoice.payGroup === "RENT"
                          ? getNameResidentialRent(this.props.invoiceQuery.invoice.contact.residential.residentialHouseholder?.edges)
                          : getNameResidential(
                            this.props.invoiceQuery.invoice.firstName,
                            this.props.invoiceQuery.invoice.lastName
                            // this.props.contact.firstName,
                            // this.props.contact.lastName
                          )
                        : this.props.contact.name}
                      <span style={{color: '#F43853'}}>
                        {this.props.invoiceQuery.invoice.payGroup === "RENT" ? `(${i18next.t("AgentRole:Renter")})` : 
                            this.props.invoiceQuery.invoice.payGroup === "AGENT" ? `(${i18next.t("AgentRole:Agent")})`:
                              ""
                        }
                      </span>
                    </p>
                  </div>
                  <div className="col">
                    <p><Translation>{t => <strong>{t('invoiceDetail:No.')}</strong>}</Translation></p>
                    <p>{this.props.invoiceQuery.invoice.docNumber} </p>
                  </div>
                  <div className="col">
                    <p><Translation>{t => <strong>{t('invoiceDetail:Date')}</strong>}</Translation></p>
                    <p>{format(this.props.invoiceQuery.invoice.issuedDate,"DD/MM/YYYY",{ locale: thLocale })}
                    </p>
                  </div>
                  <div className="col">
                    <p><Translation>{t => <strong>{t('invoiceDetail:Due date')}</strong>}</Translation></p>
                    <p>
                      {format(this.props.invoiceQuery.invoice.dueDate, "DD/MM/YYYY",{ locale: thLocale })}{" "}
                      ({differenceInCalendarDays(this.props.invoiceQuery.invoice.dueDate, this.props.invoiceQuery.invoice.issuedDate)}{" "}
                      <Translation>{t => <strong>{t('invoiceDetail:Day')}</strong>}</Translation>)
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row">

                  <div className="col">
                    <p><Translation>{t => <strong>{t('invoiceDetail:Address')}</strong>}</Translation></p>
                    <p>
                      {this.props.invoiceQuery.invoice?.province.includes("กรุงเทพ") || this.props.invoiceQuery.invoice?.province.includes("กทม") ? (
                        <>
                          {this.props.invoiceQuery.invoice.address}
                          {this.props.invoiceQuery.invoice.district && ` แขวง${this.props.invoiceQuery.invoice.district}`}
                          {this.props.invoiceQuery.invoice.city && ` เขต${this.props.invoiceQuery.invoice.city}`}
                          {this.props.invoiceQuery.invoice.province && ` ${this.props.invoiceQuery.invoice.province} `}
                          {this.props.invoiceQuery.invoice.postalCode}&nbsp;
                          {this.props.invoiceQuery.invoice.country}
                        </>
                      ) : (
                        <>
                          {this.props.invoiceQuery.invoice.address}
                          {this.props.invoiceQuery.invoice.district && ` ตำบล${this.props.invoiceQuery.invoice.district}`}
                          {this.props.invoiceQuery.invoice.city && ` อำเภอ${this.props.invoiceQuery.invoice.city}`}
                          {this.props.invoiceQuery.invoice.province && ` จังหวัด${this.props.invoiceQuery.invoice.province} `}
                          {this.props.invoiceQuery.invoice.postalCode}&nbsp;
                          {this.props.invoiceQuery.invoice.country}
                        </>
                      )}
                    </p>
                  </div>

                  {this.props.invoiceQuery.invoice.contractRevenue &&
                    <div className="col">
                      <p><Translation>{t => <strong>{t('contract_service:Contract Service Document')}</strong>}</Translation></p>

                      <Link
                          to={"/accounting/income/contract_service/view/" + this.props.invoiceQuery.invoice.contractRevenue.id}
                          target="_blank"
                        ><p> {this.props.invoiceQuery.invoice.contractRevenue.docNumber}{" "}</p>
                        </Link>
                    </div>
                  }

                </div>

                <InvoiceDetailTable invoice={this.props.invoiceQuery.invoice} />
              </div>
            </div>
            {this.state.receipt_deposit_list.length > 0 && (
              <div className="card mt-5">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <Translation>{t => <h6>{t('invoiceDetail:Receipt Deposit List')}</h6>}</Translation>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="table-responsive fade-up">
                        <table className="table table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th><Translation>{t => t('invoiceDetail:Products/Services')}</Translation></th>
                              <th><Translation>{t => t('invoiceDetail:List')}</Translation></th>
                              <th><Translation>{t => t('invoiceDetail:Description')}</Translation></th>
                              <th className="text-right"><Translation>{t => t('invoiceDetail:Amount')}</Translation></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.receipt_deposit_list.map(
                              (receipt_deposit) => (
                                <tr key={receipt_deposit.node.id}>
                                  <td>
                                    {
                                      receipt_deposit.node
                                        .receiptDepositTransaction
                                        .receiptDeposit.docNumber
                                    }
                                  </td>
                                  <td>
                                    {
                                      receipt_deposit.node
                                        .receiptDepositTransaction
                                        .chartOfAccount.chartOfAccountCode
                                    }{" "}
                                    {
                                      receipt_deposit.node
                                        .receiptDepositTransaction
                                        .chartOfAccount.name
                                    }
                                  </td>
                                  <td>
                                    {
                                      receipt_deposit.node
                                        .receiptDepositTransaction.description
                                    }
                                  </td>
                                  <td className="text-right">
                                    {numberWithComma(
                                      receipt_deposit.node.amount
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <InvoiceSummary
              invoice={this.props.invoiceQuery.invoice}
              remark={this.props.invoiceQuery.invoice.remark}
              transaction_list={this.state.transaction_list}
              receiveDeposit={this.state.receiveDeposit}
              remark_edit={true}
              taxType={this.props.invoiceQuery.invoice.taxType}
            />
          </div>
        </div>
      </WrapperContent>
    );
  }
}


export default withRouter(InvoiceDetail);