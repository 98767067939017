import React from "react";
import ReactExport from "react-data-export";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithCommas from "../../libs/numberWithComma"
import {Translation} from "react-i18next";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const _ = require('lodash');

class ExportBringForward extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            multiDataSet: []
        };
        this.buttonElement = this.buttonElement.bind(this);
        this.setData = this.setData.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.chartTree !== this.props.chartTree) {
            this.setData(this.props.chartTree)
        }
    }

    setData(chart_tree) {
        let data = [];
        data.push([
            {value : ""},
            {value : ""},
            {value : ""},
            {value : ""},
            {value : ""},
        ])
        data.push([
            {
                value :"เลขที่บัญชี" , 
                style: {alignment: {horizontal: "center"} ,font: {bold: true}}
            },
            {
                value :"ชื่อบัญชี" , 
                style: {alignment: {horizontal: "center"},font: {bold: true}}
            },
            {
                value :"หมวดหมู่" , 
                style: {alignment: {horizontal: "center"},font: {bold: true}}
            },
            {
                value :"เดบิต" , 
                style: {alignment: {horizontal: "center"},font: {bold: true}}
            },
            {
                value :"เครดิต" , 
                style: {alignment: {horizontal: "center"},font: {bold: true}}
            },
        ])
        chart_tree.forEach((chart_of_account) => {
            data.push([ 
                {value : chart_of_account.data.code}, 
                {value : chart_of_account.data.name}, 
                {value : chart_of_account.data.category}
            ]);
            chart_of_account.children.forEach((chart_of_account) => {
                data.push([
                    {value : chart_of_account.data.code}, 
                    {value : "-"+chart_of_account.data.name}, 
                    {value : chart_of_account.data.category},
                    {
                        value : (chart_of_account.data.totalHistoricalDebit && parseFloat(chart_of_account.data.totalHistoricalDebit) > 0.00) ? numberWithCommas(parseFloat(chart_of_account.data.totalHistoricalDebit).toFixed(2)) : "",
                        style: {alignment: {horizontal: "right"}}
                    }, 
                    {
                        value : (chart_of_account.data.totalHistoricalCredit && parseFloat(chart_of_account.data.totalHistoricalCredit) > 0.00) ? numberWithCommas(parseFloat(chart_of_account.data.totalHistoricalCredit).toFixed(2)) : "" ,
                        style: {alignment: {horizontal: "right"}}
                    }
                ]);

                if (_.get(chart_of_account, "children")) {
                    chart_of_account.children.forEach((chart_of_account) => {
                        data.push([
                            {value : chart_of_account.data.code} , 
                            {value : "--"+chart_of_account.data.name}, 
                            {value : chart_of_account.data.category},
                            {
                                value : (chart_of_account.data.totalHistoricalDebit && parseFloat(chart_of_account.data.totalHistoricalDebit) > 0.00) ? numberWithCommas(parseFloat(chart_of_account.data.totalHistoricalDebit).toFixed(2))  : "",
                                style: {alignment: {horizontal: "right"}}
                            } , 
                            {
                                value : (chart_of_account.data.totalHistoricalCredit && parseFloat(chart_of_account.data.totalHistoricalCredit) > 0.00) ? numberWithCommas(parseFloat(chart_of_account.data.totalHistoricalCredit).toFixed(2)) : "",
                                style: {alignment: {horizontal: "right"}}
                            }
                        ]);

                        if (_.get(chart_of_account, "children")) {
                            chart_of_account.children.forEach((chart_of_account) => {
                                data.push([
                                    {value : chart_of_account.data.code}, 
                                    {value : "---" + chart_of_account.data.name}, 
                                    {value : chart_of_account.data.category} ,
                                    {
                                        value :(chart_of_account.data.totalHistoricalDebit && parseFloat(chart_of_account.data.totalHistoricalDebit) > 0.00) ? numberWithCommas(parseFloat(chart_of_account.data.totalHistoricalDebit).toFixed(2)) : "",
                                        style: {alignment: {horizontal: "right"}}
                                    } , 
                                    {   
                                        value :(chart_of_account.data.totalHistoricalCredit && parseFloat(chart_of_account.data.totalHistoricalCredit) > 0.00) ? numberWithCommas(parseFloat(chart_of_account.data.totalHistoricalCredit).toFixed(2)) : "",
                                        style: {alignment: {horizontal: "right"}}
                                    }
                                ]);

                                if (_.get(chart_of_account, "children")) {
                                    chart_of_account.children.forEach((chart_of_account) => {
                                        data.push([
                                            {value : chart_of_account.data.code}, 
                                            {value : "----" + chart_of_account.data.name}, 
                                            {value : chart_of_account.data.category}, 
                                            {
                                                value : (chart_of_account.data.totalHistoricalDebit && parseFloat(chart_of_account.data.totalHistoricalDebit) > 0.00) ? numberWithCommas(parseFloat(chart_of_account.data.totalHistoricalDebit).toFixed(2)) : "" ,
                                                style: {alignment: {horizontal: "right"}}
                                            }, 
                                            {
                                                value :(chart_of_account.data.totalHistoricalCredit && parseFloat(chart_of_account.data.totalHistoricalCredit) > 0.00) ? numberWithCommas(parseFloat(chart_of_account.data.totalHistoricalCredit).toFixed(2)) : "",
                                                style: {alignment: {horizontal: "right"}}
                                            }
                                        ]);
                                        data.push()
                                    })
                                }
                            })
                        }
                    })
                }

            })
            data.push([
                {value : ""},
                {
                    value : "รวมรายการ" + chart_of_account.data.name.split("/")[0],
                    style : {font: {bold: true}}
                }, 
                {value : ""} , 
                {
                    value : numberWithCommas(_.get(this.props.totalHistoricalDebit,chart_of_account.data.code.charAt(0), 0),0),
                    style: {alignment: {horizontal: "right" } ,font: {bold: true} }
                }, 
                {
                    value :numberWithCommas(_.get(this.props.totalHistoricalCredit, chart_of_account.data.code.charAt(0), 0),0),
                    style: {alignment: {horizontal: "right"} , font: {bold: true}}
                }
            ])
        });

        let multiDataSet = [
            {   
                columns: 
                [   
                    {
                        title: "ยอดยกมา", 
                        width: {wpx: 100},
                    },
                    {
                        title: "ณ วันที่ " + format(this.props.start_date, 'DD-MM-YYYY'), 
                        width: {wpx: 500}
                    },
                    {
                        title: "", 
                        width: {wpx: 100}
                    },
                    {
                        title: "", 
                        width: {wpx: 120} 
                    },
                    {
                        title: "", 
                        width: {wpx: 120} ,
                    }

                ],
                data
            },
        ];

        this.setState({multiDataSet})
    }

    buttonElement() {
        return (
            <Translation>
                {t =>
                    <button type="button" className="btn print mr-2">
                        <span>
                        <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon"/>
                        {t('settingAccount:Print')}
                        </span>
                    </button>
                }
            </Translation>
        );
    }

    render() {
        return (
            <ExcelFile
                element={this.buttonElement()}
                filename={"bring_forward_" + format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}>
                <ExcelSheet
                    dataSet={this.state.multiDataSet}
                    name="BringForward"
                >
                </ExcelSheet>
            </ExcelFile>
        )
    }
}

export default ExportBringForward