import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";

import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";

import { format, getDaysInMonth, setDate } from "date-fns";
import environment from "../../../env/environment";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../libs/numberWithComma";
import Pagination from "../../../libs/newPagination";
import { QueryRenderer } from "react-relay";
import ComponentPagination from "../../../libs/componentPagination";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Navigation from "../../finance/withholdingTax/navigation";
import DatePicker from "react-datepicker/es";
import { getTotalpayRecord } from "../../../libs/getTotalpayRecord";
import _ from "lodash";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import withingTaxListApi from "../api/witholdingTaxListApi";
import Loading from "../../../libs/loading";

const query = graphql`
  query withholdingTaxListQuery(
    $pnd: String
    $search: String
    $start_date: DateTime
    $end_date: DateTime
    $first: Int
    $last: Int
  ) {
    selfProject{
      id
    }
    withholdingTaxViewer {
      allWithholdingTax(
        pnd: $pnd
        search: $search
        startDate: $start_date
        endDate: $end_date
        first: $first
        last: $last
      ) {
        totalCount
        edges {
          node {
            id
            docNumber
            unknownContact
            refNumber
            contact {
              id
              name
              refNumber
              firstName
              lastName
              invoice {
                edges {
                  node {
                    docNumber
                  }
                }
              }
            }
            payRecordGroup {
              id
              docNumber
              refPayExpenseDate
              payRecord {
                  edges {
                      node {
                        id
                        whtAmount
                        whtRatePercent
                        purchaseRecord {
                          id
                          preTaxAmount
                          prepaidDepositTransactionUsed {
                            edges {
                                node {
                                    amount
                                    prepaidDepositTransaction{
                                        whtRatePercent
                                        vat
                                    }
                                }
                            }
                        }
                        acceptCreditNoteTransaction(status: "paid"){
                            edges {
                                node {
                                    price
                                }
                            }
                        }
                      }
                    }
                  }
                }
            }

            otherExpense{
              id
              docNumber
              otherExpenseTransaction{
                  edges {
                    node {
                      id
                      preTaxAmount
                      whtRate
                      whtRatePercent
                  }
                }
              }
            }
            totalPrepaidDeposit

            issuedDate
            added
            total
            remark
            status
          }
        }
      }
    }
  }
`;

class WithholdingTaxList extends ComponentPagination {
  constructor() {
    super();
    let current_month = new Date();
    this.state.start_date = setDate(current_month, 1);
    this.state.end_date = setDate(current_month, getDaysInMonth(current_month));
    this.state.temp_start_date = setDate(current_month, 1);;
    this.state.temp_end_date = setDate(current_month, getDaysInMonth(current_month));
    this.state.search = "";
    this.state.search_input = "";
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    this.setState({
      status: this.props.match.params.status,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  handleChangeStart(date) {
    this.setState({
      temp_start_date: date,
      temp_end_date: setDate(date, getDaysInMonth(date)),
    });
  }

  getTotal(payRecored) {
    let preTaxAmount_pr_acn_pd = 0;
    let acnsums = 0;
    let sumpd = 0;
    if (payRecored.node.whtRatePercent) {
      payRecored.node.purchaseRecord.acceptCreditNoteTransaction.edges.forEach((acnsum) => {
        acnsums = + acnsum.node.price
      })
      if (_.find(payRecored.node.purchaseRecord.prepaidDepositTransactionUsed.edges, { node: { prepaidDepositTransaction: { whtRatePercent: null } } }) === undefined) {
        payRecored.node.purchaseRecord.prepaidDepositTransactionUsed.edges.forEach((pdsum) => {
          if (pdsum.node.prepaidDepositTransaction.whtRatePercent) {
            sumpd = sumpd + Math.round((parseFloat(pdsum.node.amount) / (1 + parseFloat(pdsum.node.prepaidDepositTransaction.vat || 0) / 100) + Number.EPSILON) * 100) / 100;
          }
        })
      }
      preTaxAmount_pr_acn_pd = Math.round((parseFloat(payRecored.node.purchaseRecord.preTaxAmount - sumpd - acnsums) + Number.EPSILON) * 100) / 100;
    }
    return preTaxAmount_pr_acn_pd;
  }

  printTextWirholdingTaxList = () => {
    let start_date = format(this.state.start_date, "DD/MM/YYYY HH:mm:ss")
    let end_date = format(this.state.end_date, "DD/MM/YYYY HH:mm:ss")
    let nameFile = ""

    withingTaxListApi.getTaxWithingTaxList(this.props.match.params.status, start_date, end_date, this.state.search).then((res) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;

      switch (this.props.match.params.status) {
        case "pnd-3":
          nameFile = `PND3 ${format(this.state.temp_start_date, "MM-YY")}`
          break;
        case "pnd-53":
          nameFile = `PND53 ${format(this.state.temp_start_date, "MM-YY")}`
          break;
      }
      link.setAttribute('download', `${nameFile}.txt`); //any other extension
      document.body.appendChild(link);
      link.click();
    })
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="receive-list">
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("finance:withholding_tax") + " " + "(WTI)"}</span>}
                  </Translation>
                </h3>
              </div>
            </div>

            <div className="content-inner">
              <Navigation />
              <div className="row mb-2 mt-1 justify-content-end">
                <div className="col d-flex">
                  {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                    codename: "accounting_withholdingTax_print",
                  }) && (
                      // <Link
                      //   to={{
                      //     pathname:
                      //       "/document/attachment-withholding-tax-" +
                      //       this.props.match.params.status +
                      //       "/with-data",
                      //     state: {
                      //       start_date: this.state.start_date,
                      //       end_date: this.state.end_date,
                      //     },
                      //   }}
                      // >
                      //   <Translation>
                      //     {t=>
                      //     <button
                      //       type="button"
                      //       className="btn btn-outline-secondary"
                      //     >
                      //       {t("withholding_tax:Tax filing form")}
                      //     </button>}
                      //   </Translation>
                      // </Link>
                      <Dropdown>
                        <Translation>
                          {t =>
                            <DropdownToggle
                              id="dropdown-item-button"
                              bsPrefix=" dropdown-toggle"
                              variant="outline-secondary"
                            >
                              {t("withholding_tax:Tax filing form")}
                            </DropdownToggle>
                          }
                        </Translation>
                        <DropdownMenu>
                          <Dropdown.Item>
                            <Link
                              style={{ color: "#000000", textDecoration: 'none' }}
                              to={{
                                pathname:
                                  "/document/attachment-withholding-tax-" +
                                  this.props.match.params.status +
                                  "/with-data",
                                state: {
                                  start_date: this.state.start_date,
                                  end_date: this.state.end_date,
                                },
                              }}>
                              <p className="text-center">PDF</p>
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={this.printTextWirholdingTaxList}>
                            <p className="text-black text-center">TEXT</p>
                          </Dropdown.Item>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  {/*<Link to={"/document/attachment-withholding-tax-" + this.props.match.params.status + "/not-data"}>
                                        <button type="button" className="btn btn-outline-secondary ml-2">
                                            แบบฟอร์มยื่นภาษี(เปล่า)
                                        </button>
                                    </Link>*/}
                  {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                    codename: "accounting_withholdingTax_print",
                  }) && (
                      <Link
                        to={{
                          pathname:
                            "/document/filing-form-withholding-tax-" +
                            this.props.match.params.status +
                            "/with-data",
                          state: {
                            start_date: this.state.start_date,
                            end_date: this.state.end_date,
                          },
                        }}
                      >
                        <Translation>
                          {t =>
                            <button
                              type="button"
                              className="btn btn-outline-secondary ml-2"
                            >
                              {t("withholding_tax:P.N.D.")}
                            </button>}
                        </Translation>
                      </Link>
                    )}
                  {/*<Link to={"/document/filing-form-withholding-tax-" + this.props.match.params.status + "/not-data"}>
                                        <button type="button" className="btn btn-outline-secondary ml-2">
                                            แบบภ.ง.ด(เปล่า)
                                        </button>
                                    </Link>*/}
                </div>
                <div className="col-3">
                  <div className="receive-search-date">
                    <div className="form-inline float-right">
                      <div className="form-group mb-2">
                        <Translation>
                          {t =>
                            <label htmlFor="start_date" className="startDate mr-3">
                              {t("withholding_tax:Browse")}
                            </label>}
                        </Translation>
                        <DatePicker
                          id="start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          onChange={this.handleChangeStart}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <Translation>
                    {t =>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control input-size"
                          placeholder={t("withholding_tax:Search")}
                          value={this.state.search_input}
                          name="search"
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {t("withholding_tax:Search")}
                        </button>
                      </div>}
                  </Translation>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <QueryRenderer
                    environment={environment}
                    query={query}
                    variables={{
                      search: this.state.search,
                      start_date: this.state.start_date,
                      end_date: this.state.end_date,
                      first: this.state.first,
                      last: this.state.last,
                      pnd: this.props.match.params.status,
                    }}
                    render={({ error, props }) => {
                      if (error) {
                        return <div>{error.message}</div>;
                      } else if (props) {
                        let totalAmount = 0
                        return (
                          <div>
                            <div className="table-responsive fade-up card">
                              <table className="table table-hover">
                                <thead className="thead-light">
                                  <Translation>
                                    {t =>
                                      <tr>
                                        <th className="text-center">{t("withholding_tax:No.")}</th>
                                        <th className="text-center">
                                          {t("withholding_tax:Reference document No.")}
                                        </th>
                                        <th className="text-center">{t("withholding_tax:Date")}</th>
                                        <th className="text-center">{t("withholding_tax:Code")}</th>
                                        <th>{t("withholding_tax:Name")}</th>
                                        <th className="text-right">
                                          {t("withholding_tax:Paid amount")}
                                        </th>
                                        <th className="text-right">
                                          {t("withholding_tax:Witholding tax")}
                                        </th>
                                        <th className="text-right">{t("withholding_tax:Status")}</th>
                                        <th />
                                      </tr>}
                                  </Translation>
                                </thead>
                                <tbody>
                                  {props.withholdingTaxViewer.allWithholdingTax.edges.map((withholdingTax) => {
                                    //PR//
                                    if (withholdingTax.node.payRecordGroup) {
                                      return withholdingTax.node.payRecordGroup?.payRecord?.edges.map((payRecore) => {
                                        if (payRecore.node.whtRatePercent) {
                                          const psAmount = getTotalpayRecord(payRecore);
                                          totalAmount = totalAmount + psAmount;
                                          // parseFloat(payRecore.node.whtAmount / payRecore.node.whtRatePercent * 100)
                                          return (
                                            <React.Fragment key={payRecore.node.id}>
                                              <tr key={payRecore.node.id} className="show">
                                                <td className="text-center">
                                                  {withholdingTax.node.docNumber}
                                                </td>
                                                <td className="text-center">
                                                  {withholdingTax.node.remark}
                                                </td>
                                                <td className="text-center">
                                                  {format(withholdingTax.node.payRecordGroup.refPayExpenseDate || withholdingTax.node.issuedDate, "DD/MM/YYYY", { locale: thLocale })}
                                                </td>
                                                <td className="text-center">
                                                  {withholdingTax.node.contact ? withholdingTax.node.contact.refNumber : "-"}
                                                </td>
                                                <td>
                                                  {withholdingTax.node.contact ? withholdingTax.node.contact.name : withholdingTax.node.unknownContact}
                                                </td>
                                                <td className="text-right">
                                                  {withholdingTax.node.status == "ACTIVE" ? numberWithComma(psAmount) : "-"}
                                                </td>
                                                <td className="text-right">
                                                  {numberWithComma(payRecore.node.whtAmount)}
                                                </td>
                                                <Translation>
                                                  {t =>
                                                    <td className="text-right">
                                                      {withholdingTax.node.status == "ACTIVE" ? t("withholding_tax:Normal") : t("withholding_tax:Cancel")}
                                                    </td>}
                                                </Translation>

                                                <td width="80">{withholdingTax.node.status !== "VOID" ?
                                                  <Link
                                                    to={"/accounting/finance/withholdingTax/account/withholding-tax/" +
                                                      withholdingTax.node.id
                                                    }
                                                  >
                                                    <img src={process.env.PUBLIC_URL + "/images/icons/print-icon.png"}
                                                      alt="print-icon"
                                                      className="cursor show-icon"
                                                    />
                                                  </Link>
                                                  :
                                                  null
                                                }

                                                </td>

                                              </tr>
                                            </React.Fragment>
                                          )
                                        }

                                      })
                                    }
                                    //PR//
                                    //OE//
                                    else if (withholdingTax.node.otherExpense) {
                                      return withholdingTax.node.otherExpense?.otherExpenseTransaction?.edges.map((otherExpenses) => {
                                        if (otherExpenses.node.whtRatePercent) {
                                          totalAmount = totalAmount + (parseFloat(otherExpenses.node.preTaxAmount) || 0);
                                          return (
                                            <React.Fragment key={otherExpenses.node.id}>
                                              <tr key={otherExpenses.node.id} className="show">
                                                <td className="text-center">
                                                  {withholdingTax.node.docNumber}
                                                </td>
                                                <td className="text-center">
                                                  {withholdingTax.node.remark}
                                                </td>
                                                <td className="text-center">
                                                  {format(withholdingTax.node.issuedDate, "DD/MM/YYYY", { locale: thLocale })}
                                                </td>
                                                <td className="text-center">
                                                  {withholdingTax.node.contact ? withholdingTax.node.contact.refNumber : "-"}
                                                </td>
                                                <td>
                                                  {withholdingTax.node.contact ? withholdingTax.node.contact.name : withholdingTax.node.unknownContact}
                                                </td>
                                                <td className="text-right">
                                                  {withholdingTax.node.status == "ACTIVE" ? numberWithComma(otherExpenses.node.preTaxAmount) : "-"}
                                                </td>
                                                <td className="text-right">
                                                  {numberWithComma(otherExpenses.node.whtRate)}
                                                </td>
                                                <Translation>
                                                  {t => <td className="text-right">
                                                    {withholdingTax.node.status == "ACTIVE" ? t("withholding_tax:Normal") : t("withholding_tax:Cancel")}
                                                  </td>}
                                                </Translation>

                                                <td width="80">{withholdingTax.node.status !== "VOID" ?
                                                  <Link
                                                    to={"/accounting/finance/withholdingTax/account/withholding-tax/" +
                                                      withholdingTax.node.id
                                                    }
                                                  >
                                                    <img src={process.env.PUBLIC_URL + "/images/icons/print-icon.png"}
                                                      alt="print-icon"
                                                      className="cursor show-icon"
                                                    />
                                                  </Link>
                                                  :
                                                  null
                                                }

                                                </td>

                                              </tr>
                                            </React.Fragment>
                                          )
                                        }
                                      })
                                    }
                                    //OE//
                                    //PD//
                                    else {
                                      totalAmount = totalAmount + (parseFloat(withholdingTax.node.totalPrepaidDeposit) || 0);
                                      return (
                                        <React.Fragment key={withholdingTax.node.id}>
                                          <tr key={withholdingTax.node.id} className="show">
                                            <td className="text-center">
                                              {withholdingTax.node.docNumber}
                                            </td>
                                            <td className="text-center">
                                              {withholdingTax.node.remark}
                                            </td>
                                            <td className="text-center">
                                              {format(withholdingTax.node.issuedDate, "DD/MM/YYYY", { locale: thLocale })}
                                            </td>
                                            <td className="text-center">
                                              {withholdingTax.node.contact ? withholdingTax.node.contact.refNumber : "-"}
                                            </td>
                                            <td>
                                              {withholdingTax.node.contact ? withholdingTax.node.contact.name : withholdingTax.node.unknownContact}
                                            </td>
                                            <td className="text-right">
                                              {withholdingTax.node.status == "ACTIVE" ? numberWithComma(withholdingTax.node.totalPrepaidDeposit) : "-"}
                                            </td>
                                            <td className="text-right">
                                              {numberWithComma(withholdingTax.node.total)}
                                            </td>
                                            <Translation>
                                              {t =>
                                                <td className="text-right">
                                                  {withholdingTax.node.status == "ACTIVE" ? t("withholding_tax:Normal") : t("withholding_tax:Cancel")}
                                                </td>}
                                            </Translation>

                                            <td width="80">{withholdingTax.node.status !== "VOID" ?
                                              <Link
                                                to={"/accounting/finance/withholdingTax/account/withholding-tax/" +
                                                  withholdingTax.node.id
                                                }
                                              >
                                                <img src={process.env.PUBLIC_URL + "/images/icons/print-icon.png"}
                                                  alt="print-icon"
                                                  className="cursor show-icon"
                                                />
                                              </Link>
                                              :
                                              null
                                            }

                                            </td>

                                          </tr>
                                        </React.Fragment>
                                      )
                                    }
                                    //PD//
                                  }
                                  )
                                  }
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <Translation>
                                      {t =>
                                        <td colSpan={5} className="text-right">
                                          {t("withholding_tax:Total")}
                                        </td>}
                                    </Translation>
                                    <td className="text-right">
                                      {numberWithComma(totalAmount)}
                                    </td>
                                    <td className="text-right">
                                      {numberWithComma(
                                        props.withholdingTaxViewer.allWithholdingTax.edges.reduce(function (total, withholdingTax) {
                                          if (withholdingTax.node.status === "ACTIVE") {
                                            return (total + parseFloat(withholdingTax.node.total));
                                          } else {
                                            return total + 0;
                                          }
                                        },
                                          0
                                        )
                                      )}
                                    </td>
                                    <td />
                                    <td />
                                  </tr>
                                </tfoot>
                              </table>
                            </div>

                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={props.withholdingTaxViewer.allWithholdingTax.totalCount}
                              />
                            </div>
                          </div>
                        );
                      }
                      return <Loading />
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default WithholdingTaxList;
