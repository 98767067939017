import React from "react";
import numberWithCommas from "../../libs/numberWithComma";
import _ from "lodash";


class BalanceSheetAccountCoOwner extends React.Component {
    TotalLiabilitiesOwnersEquityCol1(){
        const OwnerEquity = _.get(this.props.state.co_owner, "[0].before_amount_1",0) + _.get(this.props.state.co_owner, "[1].before_amount_2",0) + _.get(this.props.state.co_owner, "[2].yearFirst",0)
        const AccruedSurplusFirst = _.get(this.props.state.co_owner, "[2].accruedSurplusFirst",0);
        const Liabilities = _.get(this.props.state.beforeSet, "total_ref_6",0) +
        _.get(this.props.state.beforeSet, "total_ref_7",0) +
        _.get(this.props.state.beforeSet, "total_ref_8",0) +
        _.get(this.props.state.beforeSet, "total_ref_9",0) +
        _.get(this.props.state.beforeSet, "total_ref_10",0) +
        _.get(this.props.state.beforeSet, "total_ref_11",0) +
        _.get(this.props.state.beforeSet, "total_ref_12",0) +
        _.get(this.props.state.beforeSet, "total_ref_13",0)
        return Math.round(((OwnerEquity+ Liabilities + AccruedSurplusFirst) + Number.EPSILON) * 100) / 100;
    }
    TotalLiabilitiesOwnersEquityCol2(){
        const OwnerEquity = _.get(this.props.state.co_owner, "[0].after_amount_1",0) + _.get(this.props.state.co_owner, "[1].after_amount_2",0)+_.get(this.props.state.co_owner, "[2].yearSecond",0);
        const AccruedSurplusSecond = _.get(this.props.state.co_owner, "[2].accruedSurplusSecond",0);
        const Liabilities =  _.get(this.props.state.afterSet, "total_ref_6",0) +
        _.get(this.props.state.afterSet, "total_ref_7",0) +
        _.get(this.props.state.afterSet, "total_ref_8",0) +
        _.get(this.props.state.afterSet, "total_ref_9",0) +
        _.get(this.props.state.afterSet, "total_ref_10",0) +
        _.get(this.props.state.afterSet, "total_ref_11",0) +
        _.get(this.props.state.afterSet, "total_ref_12",0) +
        _.get(this.props.state.afterSet, "total_ref_13",0)
        return Math.round(((OwnerEquity + Liabilities + AccruedSurplusSecond) + Number.EPSILON) * 100) / 100;
    }
    render() {
        const totalcol1 = this.TotalLiabilitiesOwnersEquityCol1();
        const totalcol2 = this.TotalLiabilitiesOwnersEquityCol2();
        let total_co_owner_ex_first = 0
        let total_co_owner_ex_second = 0
        // let co_owner_ex_number = 14
        return (
            <React.Fragment>
                <tbody>
                <tr>
                    <td className="pl-3" width={700}>
                        เงินกองทุน / Sinking Fund
                    </td>
                    <td className="text-center"/>
                    {/* <td className="text-center">
                            <Link key={14} to={"balance-sheet-statements/3000-01/"+this.props.state.period}>14</Link>
                    </td> */}
                    <td/>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.co_owner, "[0].before_amount_1",0))}</td>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.co_owner, "[0].after_amount_1",0))}</td>
                </tr>
                {Object.entries(this.props.state.co_owner_ex).map((co_owner_ex) => {
                    // co_owner_ex_number += 1
                    total_co_owner_ex_first += co_owner_ex[1][1];
                    total_co_owner_ex_second += co_owner_ex[1][2];
                    return(
                        <tr key={co_owner_ex[0]}>
                            <td className="pl-3" width={700}>
                                {co_owner_ex[1][0]}
                            </td>
                            <td className="text-center"/>
                            {/* <td className="text-center">14</td> */}
                            {/* <td className="text-center">
                                <Link key={co_owner_ex_number} to={"balance-sheet-statements/"+co_owner_ex[0]+"/"+this.props.state.period}>{co_owner_ex_number}</Link>
                            </td> */}
                            <td/>
                            <td className="text-right">{numberWithCommas(co_owner_ex[1][1])}</td>
                            <td className="text-right">{numberWithCommas(co_owner_ex[1][2])}</td>
                        </tr>
                        )
                    })
                }

                <tr>
                    <td className="pl-3">รายรับสูง(ต่ำ) กว่ารายจ่ายสะสม / Accrued Surplus (Deficit) Accumulate</td>
                    <td className="text-center"/>
                    <td className="text-center"/>
                    {/* <td className="text-center">
                            <Link key={15} to={"balance-sheet-statements/3000-02/"+this.props.state.period}>16</Link>
                        </td>
                    <td/> */}
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.co_owner, "[2].yearFirst", 0) +
                        _.get(this.props.state.co_owner, "[1].before_amount_2", 0) +
                        _.get(this.props.state.co_owner, "[2].accruedSurplusFirst", 0), "-")}</td>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.co_owner, "[2].yearSecond", 0) +
                        _.get(this.props.state.co_owner, "[1].after_amount_2", 0) +
                        _.get(this.props.state.co_owner, "[2].accruedSurplusSecond", 0), "-")}</td>
                    
                </tr>
                
                
                <tr className="">
                    <td className="pl-3"><strong>รวมส่วนของเจ้าของร่วม / Total Owner's Equity</strong></td>
                    <td className="text-center"/>
                    <td/>
                    <td className="text-right">
                        <hr/>
                            {numberWithCommas(_.get(this.props.state.co_owner, "[0].before_amount_1",0) +
                                _.get(this.props.state.co_owner, "[2].accruedSurplusFirst", 0) +
                                _.get(this.props.state.co_owner, "[1].before_amount_2", 0) + 
                                _.get(this.props.state.co_owner, "[2].yearFirst",0) +
                                total_co_owner_ex_first )}
                        <hr/>
                    </td>
                    <td className="text-right">
                        <hr/>
                        {numberWithCommas(_.get(this.props.state.co_owner, "[0].after_amount_1",0) + 
                            _.get(this.props.state.co_owner, "[2].accruedSurplusSecond", 0) +
                            _.get(this.props.state.co_owner, "[1].after_amount_2",0) +
                            _.get(this.props.state.co_owner, "[2].yearSecond",0) +
                            total_co_owner_ex_second )}
                        <hr/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h6 className="mt-2">รวมหนี้สินและส่วนของเจ้าของร่วม / Total Liabilities & Owner's Equity</h6>
                    </td>
                    <td className="text-center"/>
                    <td/>
                    <td className="text-right">
                        {numberWithCommas(totalcol1 + total_co_owner_ex_first)}
                        <hr className="double"/>
                    </td>
                    <td className="text-right">
                        {numberWithCommas(totalcol2 + total_co_owner_ex_second)}
                        <hr className="double"/>
                    </td>
                </tr>

                </tbody>
            </React.Fragment>
        )
    }

}

export default BalanceSheetAccountCoOwner;
