import React, {Component} from 'react';
import {Link} from "react-router-dom";
import CreateAndUpdateProductMutation from "./mutations/CreateAndUpdateProductMutation";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import SearchSelect from "../../../libs/searchSelect";
import { Translation } from 'react-i18next';
import i18n from 'i18next';

const _ = require('lodash');

class ExpenseCreateAndUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            have_vat: false,
            product: {
                id: '',
                name: '',
                productCode: 'EXXX',
                cost: '',
                inputTax: '',
                type: 'expense',
                price: '',
                outputTax: '',
                chartOfAccount: {
                    id: ''
                },
                creditorChartOfAccount: {
                    id: ''
                },

                description: '',

                taxRate: '',
                taxRateOther: '',
                typeOfWithholdingTax: '',
                typeOfIncome: '',
                typeOfIncomeOther: '',
                typeOfTax: '',
                typeOfTaxOther: '',
            },
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentWillMount() {
        if (this.props.product) {
            // edit
            this.setState({product: this.props.product});
            if (_.get(this.props.product, "typeOfIncome") || _.get(this.props.product, "typeOfIncomeOther") || _.get(this.props.product, "taxRate") || _.get(this.props.product, "taxRateOther") || _.get(this.props.product, "typeOfWithholdingTax") || _.get(this.props.product, "typeOfTax") || _.get(this.props.product, "typeOfTaxOther")) {
                this.setState({have_vat: true})
            }
        }
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;

        this.setState(prevState => {

            if (event.target.type === 'checkbox') {
                let new_value = !_.get(prevState, event.target.name);
                value = new_value;

                if (!new_value && !this.props.product) {
                    _.set(prevState, "product.typeOfIncome", "");
                    _.set(prevState, "product.typeOfIncomeOther", "");
                    _.set(prevState, "product.taxRate", "");
                    _.set(prevState, "product.taxRateOther", "");
                    _.set(prevState, "product.typeOfWithholdingTax", "");
                    _.set(prevState, "product.typeOfTax", "");
                    _.set(prevState, "product.typeOfTaxOther", "");
                }
            }
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let product = {};
        if(!this.state.have_vat){
            product = {...this.state.product};
            product.typeOfIncome = "";
            product.typeOfIncomeOther = "";
            product.taxRate = "";
            product.taxRateOther = "";
            product.typeOfWithholdingTax = "";
            product.typeOfTax = "";
            product.typeOfTaxOther = "";
            product = JSON.stringify(product)

        } else {
          product = JSON.stringify(this.state.product)
        }

        let input = {
            product: product,
        };


        CreateAndUpdateProductMutation(input, (response) => this.onCompleted(response), (response) => this.onError(response))
    }

    onCompleted(response) {
        this.setState({loading: false});
        if (response.createAndUpdateProduct.newProduct.id) {
            Swal.fire(i18n.t("add_expense:Save successfully"), '', 'success').then(() => {
                this.setState({redirectToList: true})
            });
        }
    }

    onError(response) {
        this.setState({loading: false});
        Swal.fire('Error!', i18n.t("add_expense:Please try again"), 'warning')
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/inventory/product/list/expense"/>
        }

        return (
            <React.Fragment>
                <Translation>
                    {t=>
                    <>
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="mb-4">
                                <Link to="/accounting/inventory/product/list/expense">
                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                         alt="back" className="back"/>
                                </Link>
                                {t("add_expense:Increase expense")}
                            </h3>
                        </div>
                    </div>
                    <div className="content-inner min-vh-100">
                        <div className="row">
                            <div className="col-xl-8">
                                <form id="form-add" onSubmit={this.onSubmit}>
                                    <div className="form-group row">
                                        <div className="col-md-3 col-sm-6">
                                            <label>{t("add_expense:No.")}</label>
                                            <input className="form-control text-left" value={this.state.product.productCode}
                                                   disabled/>
                                        </div>
                                    </div>
    
                                    <div className="form-group">
                                        <label>{t("add_expense:Expenditure name")}</label>
                                        <input type="text" className="form-control" id="product_name"
                                               name="product.name" onChange={this.handleInputChange}
                                               value={this.state.product.name}
                                               disabled={this.state.product.name === 'ค่าบริการออกเอกสารติดตามโดยทนายความ'}
                                               required/>
                                    </div>
    
                                    <div className="form-group">
                                        <label>{t("add_expense:Description")}</label>
                                        <textarea className="form-control"
                                                  rows="3" name="product.description"
                                                  onChange={this.handleInputChange}
                                                  value={this.state.product.description}
                                                  disabled={this.state.product.name === 'ค่าบริการออกเอกสารติดตามโดยทนายความ'}
                                                  />
                                    </div>
    
                                    <h2 className="header-with-line mt-5">{t("add_expense:Bind the chart of account")}</h2>
                                    <div className="form-group row mt-3">
                                        <div className="col-md-6">
                                            <label>{t("add_expense:Expenditure account")}</label>
                                            <SearchSelect onChange={this.handleInputChange}
                                                          value={this.state.product.chartOfAccount.id}
                                                          name="product.chartOfAccount.id" placeholder="รายการ"
                                                          queryObject={this.props.chart_of_account.groupExpenseCreateUpdate.edges}
                                                          keyOfValue="id" require={true}
                                                          keyOfLabel="chartOfAccountCode:name"
                                                          disabled={this.props.project_not_edit_account}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>{t("add_expense:Creditor account")}</label>
                                            <SearchSelect onChange={this.handleInputChange}
                                                          value={this.state.product.creditorChartOfAccount.id}
                                                          name="product.creditorChartOfAccount.id" placeholder="รายการ"
                                                          queryObject={this.props.chart_of_account.groupCreditorChartOfAccount.edges}
                                                          keyOfValue="id" require={true}
                                                          keyOfLabel="chartOfAccountCode:name"
                                                          disabled={this.props.project_not_edit_account}
                                            />
                                        </div>
                                    </div>
    
                                    <div className="row">
                                        <div className="col-md-6 mt-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="cal_vat"
                                                       checked={this.state.have_vat}
                                                       value={this.state.have_vat}
                                                       onChange={this.handleInputChange}
                                                       name="have_vat"/>
                                                <label className="form-check-label"
                                                       htmlFor="cal_vat">{t("add_expense:Witholding detail")}</label>
                                            </div>
                                        </div>
                                    </div>
    
                                    {this.state.have_vat &&
                                    <React.Fragment>
                                        <div className="col">
                                            <div className="row mt-3 form-group">
                                                <label className="col-2 col-form-label">
                                                    {t("add_expense:Type of income paid")}
                                                </label>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <select className="form-control"
                                                                value={this.state.product.typeOfIncome}
                                                                name="product.typeOfIncome"
                                                                onChange={this.handleInputChange}
                                                                disabled={this.state.product.name === 'ค่าบริการออกเอกสารติดตามโดยทนายความ'}
                                                                >
                                                            <option value="">{t("add_expense:Please select")}</option>
                                                            <option value="1">{t("add_expense:1. Salary, wages, allowances, bonuses, etc. under Section 40 (1)")}
                                                            </option>
                                                            <option value="2">{t("add_expense:2. Fees, brokerage fees, etc. under Section 40 (2)")}
                                                            </option>
                                                            <option value="3">{t("add_expense:3. Royalty fees, etc. under Section 40 (3)")}
                                                            </option>
                                                            <option value="4">{t("add_expense:4(a). Interest charges, etc. under Section 40 (4) (a).")}
                                                            </option>
                                                            <option value="4.1.1">{t("add_expense:4(b) (1.1) 30% of net profit")}
                                                            </option>
                                                            <option value="4.1.2">{t("add_expense:4(b) (1.2) 25% of net profit")}
                                                            </option>
                                                            <option value="4.1.3">{t("add_expense:4(b) (1.3) 20% of net profit")}
                                                            </option>
                                                            <option value="4.1.4">{t("add_expense:4(b) (1.4) Other rates (please specify)")}</option>
                                                            <option value="4.2.1">{t("add_expense:4(b) (2.1) Net profit of the exempted business")}
                                                            </option>
                                                            <option value="4.2.2">{t("add_expense:4(b) (2.2) Dividends or share of profits that are exempt from being included in the calculation of corporate income tax.")}
                                                            </option>
                                                            <option value="4.2.3">{t("add_expense:4(b) (2.3) the portion of net profit that has been deducted from net loss for not more than 5 years prior to the current accounting period")}
                                                            </option>
                                                            <option value="4.2.4">{t("add_expense:4(b) (2.4) Profit recognized by equity method")}
                                                            </option>
                                                            <option value="4.2.5">{t("add_expense:4(b) (2.5) other (please specify)")}</option>
                                                            <option value="5">{t("add_expense:5. Payment of income subject to withholding tax according to the Revenue Department's order issued under Section 3 Tres.")}
                                                            </option>
                                                            <option value="6">{t("add_expense:6. Other (please specify)")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.product.typeOfIncome === "6" || this.state.product.typeOfIncome === "4.1.4"
                                                || this.state.product.typeOfIncome === "4.2.5" || this.state.product.typeOfIncome === "5" ?
                                                    <div className="row mt-3 form-group">
                                                        <div className="col-2"/>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <input type="text" className="form-control"
                                                                       value={this.state.product.typeOfIncomeOther}
                                                                       name="product.typeOfIncomeOther"
                                                                       placeholder={t("add_expense:please specify")}
                                                                       disabled={this.state.product.name === 'ค่าบริการออกเอกสารติดตามโดยทนายความ'}
                                                                       onChange={this.handleInputChange} required/>
                                                                <span style={{ color: 'red' }} > * {t('expense:text_required')}</span>
                                                            </div>
                                                        </div>
                                                    </div> : <div/>
                                            }
    
                                            <div className="row mt-3 form-group">
                                                <label className="col-2 col-form-label">
                                                    {t("add_expense:Tax rate")}
                                                </label>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <select className="form-control"
                                                                value={this.state.product.taxRate}
                                                                name="product.taxRate"
                                                                onChange={this.handleInputChange}
                                                                disabled={this.state.product.name === 'ค่าบริการออกเอกสารติดตามโดยทนายความ'}
                                                                >
                                                            <option value="">{t("add_expense:Please select")}</option>
                                                            <option value="1">1%</option>
                                                            <option value="1.5">1.5%</option>
                                                            <option value="2">2%</option>
                                                            <option value="3">3%</option>
                                                            <option value="5">5%</option>
                                                            <option value="other">{t("add_expense:Other (please specify)")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.product.taxRate === "other" ?
                                                    <div className="row mt-3 form-group">
                                                        <div className="col-2"/>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <input type="text" className="form-control"
                                                                       name="product.taxRateOther"
                                                                       value={this.state.product.taxRateOther}
                                                                       placeholder={t("add_expense:please specify")}
                                                                       onChange={this.handleInputChange}/>
                                                            </div>
                                                        </div>
                                                    </div> : <div/>
                                            }
    
                                            <div className="row mt-3 form-group">
                                                <label className="col-2 col-form-label">
                                                    {t("add_expense:Witholding tax category")}
                                                </label>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <select className="form-control"
                                                                value={this.state.product.typeOfWithholdingTax}
                                                                name="product.typeOfWithholdingTax"
                                                                onChange={this.handleInputChange}
                                                                disabled={this.state.product.name === 'ค่าบริการออกเอกสารติดตามโดยทนายความ'}
                                                                >
                                                            <option value="">{t("add_expense:Please select")}</option>
                                                            <option value="1">{t("add_expense:P.N.D. 1")}</option>
                                                            <option value="3">{t("add_expense:P.N.D. 3")}</option>
                                                            <option value="53">{t("add_expense:P.N.D. 53")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div className="row mt-3 form-group">
                                                <label className="col-2 col-form-label">
                                                    {t("add_expense:Deduction Conditions")}
                                                </label>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <select className="form-control"
                                                                value={this.state.product.typeOfTax}
                                                                name="product.typeOfTax"
                                                                onChange={this.handleInputChange}
                                                                disabled={this.state.product.name === 'ค่าบริการออกเอกสารติดตามโดยทนายความ'}
                                                                >
                                                            <option value="">{t("add_expense:Please select")}</option>
                                                            <option value="1">{t("add_expense:1. Withholding tax")}</option>
                                                            <option value="2">{t("add_expense:2. to pay taxes forever")}</option>
                                                            <option value="3">{t("add_expense:3. One time tax")}</option>
                                                            <option value="other">{t("add_expense:4. Other (please specify)")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
    
                                            {
                                                this.state.product.typeOfTax === "other" ?
                                                    <div className="row mt-3 form-group">
                                                        <div className="col-2"/>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <input type="text" className="form-control"
                                                                       onChange={this.handleInputChange}
                                                                       name="product.typeOfTaxOther"
                                                                       placeholder={t("add_expense:please specify")}
                                                                       value={this.state.product.typeOfTaxOther}/>
                                                            </div>
                                                        </div>
                                                    </div> : <div/>
                                            }
    
                                        </div>
                                    </React.Fragment>
                                    }
    
                                    {
                                    this.state.product.name !== 'ค่าบริการออกเอกสารติดตามโดยทนายความ' &&
                                    _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: "accounting_expense_edit"})
                                     &&

                                    <div className="row mt-3 fade-up"> 
                                        <div className="col text-right">
                                            <div className="btn-group mr-2">
                                                <Link to="/accounting/inventory/product/list/expense">
                                                    <button type="button"
                                                            className="btn btn-secondary add">
                                                        {t("add_expense:Cancel")}
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="btn-group mr-2">
                                                <button type="submit" className="btn btn-primary add"
                                                        disabled={this.state.loading}>
                                                    {this.state.loading && <span
                                                        className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                    {t("add_expense:Save")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                    </>
                    }
                </Translation>
            </React.Fragment>
        )
    }
}

export default ExpenseCreateAndUpdate;