import React, { Component } from 'react';
import { QueryRenderer } from 'react-relay';
import environment from "../../../env/environment";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import numberWithComma from "../../../libs/numberWithComma";
import { Translation } from 'react-i18next';
import Loading from "../../../libs/loading";

class CreditCardDepositCreateSelectTransactionList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkList: [],
            check_all: false,
            on_submit: false,
            total: 0.0
        };
        this.rowSelect = this.rowSelect.bind(this);
    }

    handleCheckInput(id) {
        return this.state.checkList.some(e => e.id === id)
    }

    handleChangeInput(e, transaction) {
        let current_checklist = [...this.state.checkList];
        let check_all = false;
        let total = this.state.total;

        if (e.target.name === 'checkbox_all') {

            if (!this.state.check_all) check_all = true;

            if ((check_all && (current_checklist.length === transaction.length)) || !check_all) {
                current_checklist = [];
                total = 0.0;
            } else {
                if (current_checklist.length !== 0) {
                    current_checklist = [];
                    total = 0.0;
                }
                transaction.forEach((item) => {
                    current_checklist.push(item.node);
                    total += item.node.amount
                });
            }

        } else if (e.target.name === 'checkbox') {

            if (current_checklist.some(e => e.id === transaction.node.id)) {
                current_checklist.splice(current_checklist.findIndex(x => x.id === transaction.node.id), 1);
                total = total - transaction.node.amount;
            } else {
                current_checklist.push(transaction.node);
                total = total + transaction.node.amount;
            }
        }

        this.setState({
            checkList: current_checklist,
            check_all: check_all,
            total: total
        }, () => this.props.updateSelectTransaction(this.state.checkList))
    }

    rowSelect(name, transaction) {
        let event = {
            target: {
                name: name,
                type: 'checkbox',
                dataset: {}
            }
        };
        this.handleChangeInput(event, transaction)
    }



    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <QueryRenderer
                            environment={environment}
                            query={this.props.query}
                            variables={{}}
                            render={({ error, props }) => {
                                if (error) {
                                    return <div>{error.message}</div>;
                                } else if (props) {
                                    return <React.Fragment>
                                        {props.allCreditCardTransaction.edges.length !== 0 ?
                                            <React.Fragment>
                                                <form action="" >
                                                    <div className="table-responsive fade-up">
                                                        <table className="table table-hover mt-2">
                                                            <thead className="thead-light">
                                                                <tr onDoubleClick={() => this.rowSelect('checkbox_all', props.allCreditCardTransaction.edges)}>
                                                                    <th>
                                                                        <input type="checkbox"
                                                                            name="checkbox_all"
                                                                            onChange={(e) => {
                                                                                this.handleChangeInput(e, props.allCreditCardTransaction.edges);
                                                                            }}
                                                                            checked={this.state.check_all} />
                                                                    </th>
                                                                    <Translation>
                                                                        {t =>
                                                                            <>
                                                                                <th>{t("cash_deposit:No.")}</th>
                                                                                <th>{t("cash_deposit:Date")}</th>
                                                                                <th>{t("cash_deposit:Description")}</th>
                                                                                <th className="text-right">{t("cash_deposit:Amount")}</th>
                                                                            </>
                                                                        }
                                                                    </Translation>

                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {props.allCreditCardTransaction.edges.map((credit_card) => {
                                                                    let doc_number = '';
                                                                    let issuedDate = '';
                                                                    if (credit_card.node.receive) {
                                                                        doc_number = credit_card.node.receive.docNumber;
                                                                        issuedDate = credit_card.node.receive.issuedDate
                                                                    } else if (credit_card.node.invoice) {
                                                                        doc_number = credit_card.node.invoice.docNumber;
                                                                        issuedDate = credit_card.node.invoice.issuedDate
                                                                    } else if (credit_card.node.receiptDeposit) {
                                                                        doc_number = credit_card.node.receiptDeposit.docNumber;
                                                                        issuedDate = credit_card.node.receiptDeposit.issuedDate
                                                                    } else {
                                                                        doc_number = credit_card.node.otherReceive.docNumber;
                                                                        issuedDate = credit_card.node.otherReceive.issuedDate
                                                                    }
                                                                    return (
                                                                        <tr key={credit_card.node.id} onDoubleClick={() => this.rowSelect('checkbox', credit_card)}>
                                                                            <td>
                                                                                <input type="checkbox"
                                                                                    name="checkbox"
                                                                                    onChange={(e) => {
                                                                                        this.handleChangeInput(e, credit_card);
                                                                                    }}
                                                                                    checked={this.handleCheckInput(credit_card.node.id)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                {doc_number}
                                                                            </td>
                                                                            <td> {format(issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                            <td>{credit_card.node.description}</td>
                                                                            <Translation>{t=><td className="text-right">{numberWithComma(credit_card.node.amount)} {t("cash_deposit:Baht")}</td>}</Translation>

                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {props.allCreditCardTransaction.edges.length !== 0 &&
                                                        <div className="row">
                                                            <div className="col" />
                                                            <div className="col">
                                                                <div
                                                                    className="form-inline float-right p-3 mb-2 mt-2 bg-light text-dark">
                                                                    <Translation>{t => <div className="text-left"><strong>{t("cash_deposit:Total amount deposit")}</strong></div>}</Translation>
                                                                    <div
                                                                        className="text-right ml-5"><Translation>{t => <strong>{numberWithComma(this.state.total)} {t("cash_deposit:Baht")}</strong>}</Translation>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="row mt-3">
                                                        <div className="col text-right">
                                                            <div className="btn-group mr-2">
                                                                <Link to="/accounting/finance/credit-card/list">
                                                                    <Translation>
                                                                        {t=>
                                                                        <button type="button" className="btn btn-secondary add">
                                                                            {t("cash_deposit:Cancel")}
                                                                        </button>}
                                                                    </Translation>
                                                                </Link>
                                                            </div>
                                                            <div className="btn-group mr-2">
                                                                {this.state.checkList.length > 0 ?
                                                                    <Link to="/accounting/finance/credit-card/create/form">
                                                                        <Translation>
                                                                            {t =>
                                                                                <button type="button"
                                                                                    className="btn btn-primary add">
                                                                                    {t("cash_deposit:Create a depost")}
                                                                                </button>}
                                                                        </Translation>
                                                                    </Link>
                                                                    :
                                                                    <Translation>
                                                                        {t =>
                                                                            <button type="button"
                                                                                className="btn btn-light disabled add">
                                                                                {t("cash_deposit:Create a depost")}
                                                                            </button>}
                                                                    </Translation>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </React.Fragment>
                                            :
                                            <Translation>{t => <div>{t("cash_deposit:Unable to save settings data")}</div>}</Translation>
                                        }

                                    </React.Fragment>
                                }
                                return <Loading/>
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreditCardDepositCreateSelectTransactionList;
