import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import NoMatch from "../components/noMatch"; 
import PdpaAndManagement from './pdpaAndManagementDashboard';
import PdpaAndManagementAcceptMarket from './pdpaAndManagementAcceptMarket';
import PdpaAndManagementAcceptPartner from './pdpaAndManagementAcceptPartner';
import PdpaAndManagementAcceptPolicy from './pdpaAndManagementAcceptPolicy';
import PdpaAndManagementPrivacyPolicy from './pdpaAndManagementPrivacyPolicy';

class pdpaAndManagementRoute extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={PdpaAndManagement}/>
                <PrivateRoute exact path={`${this.props.match.url}/Consentformarketingpurposes`} component={PdpaAndManagementAcceptMarket}/>
                <PrivateRoute exact path={`${this.props.match.url}/ConsenttoDisclosureforMarketingPurposes`} component={PdpaAndManagementAcceptPolicy}/>
                <PrivateRoute exact path={`${this.props.match.url}/SilvermanAffiliateConsent`} component={PdpaAndManagementAcceptPartner}/>
                <PrivateRoute exact path={`${this.props.match.url}/PrivacyPolicy`} component={PdpaAndManagementPrivacyPolicy}/>

                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default pdpaAndManagementRoute;