import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import Dashboard from "./dashboard";
import InvoiceReport from "./invoice/invoiceReport";
import ReceivableOutstandingReport from "./receivable_outstanding/receivableOutstandingReport";
import SelectRoom from "./history/selectRoom";
import ReceiveReport from "./receive/receiveReport";
import ReceivableClassifiedReport from "./receivable_classified/receivableClassifiedReport";
import GuaranteeMoneyReceivedReport from "./guarantee_money_received/guaranteeMoneyReceivedReport";
import CreditNoteReport from "./credit_note/creditNoteReport";
import receiptDepositReport from "./receipt_deposit/receiptDepositReport";
import otherReceiveReport from "./other_receive/otherReceiveReport";
import ReceivableOutstandingSummaryReport from "./receivable_outstanding_summary/receivableOutstandingSummaryReport";
import ReceiptDepositMovementReport from "./receipt_deposit_movement/selectRoom";
import GuaranteeMoneyReceiveMovementReport from "./guarantee_money_received_movement/selectRoom";
import DailyPaymentReceiptReport from './dailyPaymentReceipts/dailyPaymentReceiptReport';
import ReceivablePDF from '../../../document/account/reportDebtor/receivable/receivablePDF';
import ReceivablePDFAll from '../../../document/account/reportDebtor/receivable/receivablePDFAll';
import ReceivableOutstandingSummaryReportPDF from './receivable_outstanding_summary/receivableOutstandingSummaryReportPDF';
import invoiceReportPDF from './invoice/invoiceReportPDF';
import ReceiveReportPDF from './receive/receiveReportPDF';
import ReceivableClassifiedReportPDF from './receivable_classified/receivableClassifiedReportPDF';
import HistoryReportPDF from './history/historyReportPDF';
import CreditNoteReportPDF from './credit_note/creditNoteReportPDF';
import GuaranteeMoneyReceivedReportPDF from './guarantee_money_received/guaranteeMoneyReceivedReportPDF';
import ReceiptDepositReportPDF from './receipt_deposit/receiptDepositReportPDF';
import OtherReceiveReportPDF from './other_receive/otherReceiveReportPDF';
import IndividualReceiptReport from './individualReceiptReport/individualReceiptReport';
import WaterElectricUtilityReceiveReport from './waterElectricUtilityReceiveReport/waterAndElectricUtilityReceiveReport';
import IndividualReceiptReportPDF from './individualReceiptReport/individualReceiptReportPDF';
import WaterElectricUtilityReceiveReportPDF from './waterElectricUtilityReceiveReport/waterElectricUtilityReceiveReportPDF'

class Report extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={Dashboard}/>
                
                <PrivateRoute path={`${this.props.match.url}/receivable_outstanding`} component={ReceivableOutstandingReport}/>

                <PrivateRoute path={`${this.props.match.url}/receipt_deposit_movement/:status`} component={ReceiptDepositMovementReport}/>
                
                <PrivateRoute path={`${this.props.match.url}/guarantee-money-received-movement/:status`} component={GuaranteeMoneyReceiveMovementReport}/>



                {/* รายงานลูกหนี้ ข้อ 1 */}
                <PrivateRoute path={`${this.props.match.url}/invoice/PDF`} component={invoiceReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/invoice`} component={InvoiceReport}/>

                {/* รายงานลูกหนี้ ข้อ 2 */}
                <PrivateRoute path={`${this.props.match.url}/receive/PDF`} component={ReceiveReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/receive`} component={ReceiveReport}/>

                {/* รายงานลูกหนี้ ข้อ 5 */}
                <PrivateRoute path={`${this.props.match.url}/receivable_classified/PDF`} component={ReceivableClassifiedReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/receivable_classified`} component={ReceivableClassifiedReport}/>

                {/* รายงานลูกหนี้ ข้อ 6 */}
                <PrivateRoute path={`${this.props.match.url}/history/select-customer/PDF`} component={HistoryReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/history/list/PDF`} component={HistoryReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/history/:status`} component={SelectRoom}/>

                {/* รายงานลูกหนี้ ข้อ 7 */}
                <PrivateRoute path={`${this.props.match.url}/credit_note/PDF`} component={CreditNoteReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/credit_note`} component={CreditNoteReport}/>

                {/* รายงานลูกหนี้ ข้อ 8 */}
                <PrivateRoute path={`${this.props.match.url}/guarantee-money-received/PDF`} component={GuaranteeMoneyReceivedReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/guarantee-money-received`} component={GuaranteeMoneyReceivedReport}/>

                {/* รายงานลูกหนี้ ข้อ 10 */}
                <PrivateRoute path={`${this.props.match.url}/receipt_deposit/PDF`} component={ReceiptDepositReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/receipt_deposit`} component={receiptDepositReport}/>

                {/* รายงานลูกหนี้ ข้อ 4 */}
                <PrivateRoute path={`${this.props.match.url}/receivable_outstanding_summary/PDF`} component={ReceivableOutstandingSummaryReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/receivable_outstanding_summary`} component={ReceivableOutstandingSummaryReport}/>

                {/* รายงานลูกหนี้ ข้อ 12 */}
                <PrivateRoute path={`${this.props.match.url}/other_receipt/PDF`} component={OtherReceiveReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/other_receipt`} component={otherReceiveReport}/>


                {/* รายงานลูกหนี้ ข้อ 13 */}
                <PrivateRoute path={`${this.props.match.url}/dailyPaymentReceiptReport/PDF`} component={ReceivablePDF}/>
                <PrivateRoute path={`${this.props.match.url}/dailyPaymentReceiptReport/PDF-All`} component={ReceivablePDFAll}/>
                <PrivateRoute path={`${this.props.match.url}/dailyPaymentReceiptReport`} component={DailyPaymentReceiptReport}/>

                {/* รายงานลูกหนี้ ค้างชำระรายบุคคล (new) */}
                <PrivateRoute path={`${this.props.match.url}/individualReceiptReport/PDF`} component={IndividualReceiptReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/individualReceiptReport`} component={IndividualReceiptReport}/>
                
                {/* รายงานลูกหนี้ รายงานลูกหนี้ค่าน้ำประปา (new) */}
                <PrivateRoute path={`${this.props.match.url}/waterAndElectricUtilityReceiveReport/PDF`} component={WaterElectricUtilityReceiveReportPDF}/>
                <PrivateRoute path={`${this.props.match.url}/waterAndElectricUtilityReceiveReport`} component={WaterElectricUtilityReceiveReport}/>

                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Report;
