import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "./fixRequestDashBroad.scss";
import i18next from 'i18next';
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import enLocale from "date-fns/locale/en";
class FixRequestTechnicianJobTimechart extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.genChart();
  }

  genChart() {
    if (this.root) {
      this.root.dispose();
      this.chart.dispose();
    }

    let root = am5.Root.new("fixRequestTechnicianJobTime");
    let data = this.props.dataFixProjectStaffDay;
    let locale = `${i18next.t("fix_request_dashbroad:Locale")}`;
    let localeUse = locale === 'thLocale'? thLocale : enLocale;

    const formattedDates = data.map(item => ({
      ...item,
      date: format(new Date(item.date), 'DD-MMM',{ locale: localeUse })
    }));

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 40,
        paddingLeft: 30,
      })
    );

    chart.children.unshift(
        am5.Label.new(root, {
          text: `${i18next.t("fix_request:Number of repairs completed by person")} (${i18next.t("fix_request_dashbroad:Job")})`,
          fontSize: 16,
          fontWeight: 600,
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 40,
        })
      );

    // Add legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );
    

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 10,
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
    });

    
    xRenderer.grid.template.setAll({
      location: 1,
    });

    xRenderer.labels.template.setAll({
        rotation: 30,
        centerX: am5.p50,
        fontSize: 12
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "date",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.data.setAll(formattedDates);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxPrecision: 0,
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    // Add series
    function makeSeries(name, fieldName) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "date",
        })
      );

      series.columns.template.setAll({
        tooltipText: `{name}: {valueY} ${i18next.t("fix_request_dashbroad:Job")}`,
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.data.setAll(formattedDates);

      // Make stuff animate on load
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }
    let dataHeadExcelConfig = ["จำนวนงาน"]
    let dataHeadExcel = ["date"]
    let newDataObject = { date: "วันที่" };

    for (const key in this.props.dataFixProjectStaff) {
      if (key !== 'date') {
        if(key === 'other'){
          this.props.dataFixProjectStaff[key] > 0 && makeSeries('อื่นๆ', `${key}`);
          dataHeadExcelConfig.push('อื่นๆ')
          dataHeadExcel.push('อื่นๆ')
          newDataObject['อื่นๆ'] = 'อื่นๆ';
        } else {
          this.props.dataFixProjectStaff[key] > 0 && makeSeries(`${key}`, `${key}`);
          dataHeadExcelConfig.push(key)
          dataHeadExcel.push(key)
          newDataObject[key] = key;
        }
      }
    }

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: formattedDates,
      numericFields: dataHeadExcelConfig,
      numberFormat: "#,###",
      textFields: {
          text: {
            field: "วันที่",
          }
        },
      dataFields: newDataObject,
      dataFieldsOrder: dataHeadExcel,
    });
    let annotator = am5plugins_exporting.Annotator.new(root, {});

    exporting.get("menu").set("items", [
      {
        type: "format",
        format: "jpg",
        label: "Export Image",
      },
      {
        type: "format",
        format: "print",
        label: "Print",
      },
      {
        type: "format",
        format: "xlsx",
        label: "Export Excel",
      },
      {
        type: "separator"
      },
      {
        type: "custom",
        label: "Remark",
        callback: function () {
          this.close();
          annotator.toggle();
        },
      },
    ]);

    chart.appear(1000, 100);
    root._logo.dispose();

    this.chart = chart;
    this.root = root;
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="fixRequestBarChartContainer">
            <div id="fixRequestTechnicianJobTime"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default FixRequestTechnicianJobTimechart;
