import { _ } from 'core-js'
import { differenceInCalendarMonths, addDays, getDate } from 'date-fns';
import React, { Component } from 'react'
import SearchSelect from '../../../libs/searchSelect';
import DatePickerNoti from '../../../accounting/finance/depositInterest/datePickerNoti';
import "../style/manageRequest.scss"
import i18next from 'i18next';

const customStyles = {
  control: (style, { isDisabled }) => ({
    ...style,
    height: 32,
    minHeight: 32,
    backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF',
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none'
  })
};

export default class formRequisition extends Component {

  checkBox = (even) => {
    let { name } = even.target;
    let check_all = false;
    if (!this.props.urgent) {check_all = true;}

    this.props.handleChangeInput({
      target: {
        name: name,
        value: check_all
      }
    })
  }

  subtractUnitItems = (name, value) => {
    let valueResult = 0
    valueResult = parseInt( value || 0) - 1

    this.props.handleChangeInput({
      target : {
        name: name,
        value: valueResult
      }
    })
  }

  addUnitItems = (name, value) => {
    let valueResult = 0
    valueResult = parseInt( value || 0) + 1

    this.props.handleChangeInput({
      target : {
        name: name,
        value: valueResult
      }
    })
  }

  render() {

    let contractPeriod = differenceInCalendarMonths(this.props.purchaseRequisition.endContractDate, this.props.purchaseRequisition.startContractDate)
    if(getDate(this.props.purchaseRequisition.startContractDate) < getDate(this.props.purchaseRequisition.endContractDate)){
      contractPeriod += 1
    } 

    return (
      <div id="formRequisition">
        <div className="row">
          <div className="col d-flex align-items-center">
            <h4>{i18next.t("purchaseRequisition:Purchase requisition lists")}</h4>
            <div className="ml-5">
              <input 
                disabled={this.props.disibleInput}
                type="checkbox"
                name="purchaseRequisition.urgent"
                defaultChecked={this.props.urgent}
                onClick={this.checkBox}/>
              <span className={(this.props.disibleInput && `${"bgOpacity"}`) + " ml-3"}>{i18next.t("purchaseRequisition:Urgent")}</span>
            </div>
          </div>
        </div>

        {this.props.purchaseType === "service_contact" &&
          <div className="row" id="formControl-Requisition-big">
            <div className="col-2 customDatePickerWidth">
              <h6>{i18next.t("contract_service:Contract Start Date")}</h6>
              <DatePickerNoti
                disabled={this.props.disibleInput}
                className="form-control"
                selected={this.props.purchaseRequisition.startContractDate}
                name='purchaseRequisition.startContractDate'
                onChange={this.props.handleChangeInput}
                maxDate={addDays(this.props.purchaseRequisition.endContractDate, -1)}
                />
            </div>
            <div className="col-2 customDatePickerWidth">
              <h6>{i18next.t("contract_service:Contract Expiration Date")}</h6>
              <DatePickerNoti
                disabled={this.props.disibleInput}
                className="form-control"
                selected={this.props.purchaseRequisition.endContractDate}
                name='purchaseRequisition.endContractDate'
                onChange={this.props.handleChangeInput}
                minDate={addDays(this.props.purchaseRequisition.startContractDate, 1)}
                />
            </div>
            <div className="col-2">
              <h6>{i18next.t("purchaseRequisition:Contract period (months)")}</h6>
              <input 
                type="number"
                className="form-control"
                // name="purchaseRequisition.contractPeriod"
                value={contractPeriod} 
                // onChange={this.props.handleChangeInput}
                disabled
                />
            </div>
          </div>
        }

        <div className="row mt-5" id="formControl-requisition-small">
          <div className="col">
            <div>
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>{i18next.t("purchaseRequisition:Lists")} <span className="text-danger">*</span></th>
                    <th>{i18next.t("purchaseRequisition:Description")} <span className='text-danger'>{this.props.purchaseType === "asset" ? "*ชื่อสินทรัพย์" : ""}</span></th>
                    <th className="text-center">{i18next.t("purchaseRequisition:Unit")} <span className="text-danger">*</span></th>
                    <th className="text-center">{i18next.t("purchaseRequisition:Price/Unit (Baht)")}<span className="text-danger">*</span></th>
                    {this.props.purchaseType === "asset" 
                      && <th className="text-center">{i18next.t("purchaseRequisition:Unit")}</th>}
                    <th className="text-center">{i18next.t("purchaseRequisition:Budget (baht)")}</th>
                    <th className="text-center">{i18next.t("purchaseRequisition:VAT")}</th>
                    <th className="text-center">{i18next.t("purchaseRequisition:Withholding")}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {_.map(this.props.procurementFormList, (item, index) => {
                    return (
                      <tr key={index}>
                        <td style={{minWidth: 240}}>
                          <SearchSelect 
                            onChange={this.props.handleChangeProcurementFormList} 
                            disabled={this.props.disibleInput}
                            value={item.list_id}
                            name={`purchaseRequisition.procurementFormList[${index}].list_id:${index}`} placeholder="รายการ"
                            queryObject={this.props.productAndServiceList}
                            keyOfValue="id" require={true}
                            keyOfLabel="productCode:name"
                            style={customStyles}
                            />
                        </td>
                        <td style={{minWidth: 200}}>
                          <input 
                            disabled={this.props.disibleInput}
                            type="text" 
                            className="form-control" 
                            value={item.description}
                            name={`purchaseRequisition.procurementFormList[${index}].description`}
                            onChange={this.props.handleChangeInput}
                            />
                        </td>
                        <td className="text-center">
                          <div className="d-flex justify-content-center align-items-center">
                            <button 
                              type="button"
                              className={(this.props.disibleInput && `${"bgOpacity"}`) + " btn-subtract"} 
                              disabled={this.props.disibleInput}
                              onClick={() => this.subtractUnitItems(`purchaseRequisition.procurementFormList[${index}].unit_items`, item.unit_items)}
                              >-</button>
                            <input 
                              disabled={this.props.disibleInput}
                              type="number" 
                              className="form-control mx-2 text-right" 
                              style={{width: 70}}
                              value={item.unit_items}
                              name={`purchaseRequisition.procurementFormList[${index}].unit_items`}
                              onChange={this.props.handleChangeInput}
                              />
                            <button 
                              type="button"
                              className={(this.props.disibleInput && `${"bgOpacity"}`) + " btn-plus"} 
                              disabled={this.props.disibleInput}
                              onClick={() => this.addUnitItems(`purchaseRequisition.procurementFormList[${index}].unit_items`, item.unit_items)}
                              >+</button>
                          </div>
                        </td>
                        <td style={{minWidth: 120}}>
                          <input 
                            disabled={this.props.disibleInput}
                            type="number" 
                            className="form-control text-right" 
                            name={`purchaseRequisition.procurementFormList[${index}].price`}
                            value={item.price}
                            onChange={this.props.handleChangeInput}
                            required
                            />
                        </td>
                        {this.props.purchaseType === "asset" &&
                          <td style={{minWidth: 80}}>
                            <input 
                              disabled={this.props.disibleInput}
                              type="text" 
                              className="form-control" 
                              name={`purchaseRequisition.procurementFormList[${index}].unit`}
                              value={item.unit}
                              onChange={this.props.handleChangeInput}
                              />
                          </td>
                        }
                        <td style={{minWidth: 120}}>
                          <input
                            disabled type="text"
                            className="form-control text-right"
                            value={item.budget}
                            name={`purchaseRequisition.procurementFormList[${index}].budget`}
                            />
                        </td>
                        <td className="text-center" style={{minWidth: 115}}>
                          <select
                            className="form-control"
                            value={typeof(this.props.vat) === "number" ? this.props.vat : ""}
                            disabled={true}
                            >
                            <option value="">{i18next.t("purchaseRequisition:None")}</option>
                            <option value="0">VAT 0%</option>
                            <option value="7">VAT 7%</option>
                          </select>
                        </td>
                        <td className="text-center" style={{minWidth: 100}}>
                          <select
                            className="form-control"
                            name={`purchaseRequisition.procurementFormList[${index}].wht_rate`}
                            disabled={this.props.disibleInput}
                            value={item.wht_rate}
                            onChange={this.props.handleChangeInput}
                            >
                            <option value="">{i18next.t("purchaseRequisition:None")}</option>
                            <option value="1">1%</option>
                            <option value="1.5">1.5%</option>
                            <option value="2">2%</option>
                            <option value="3">3%</option>
                            <option value="5">5%</option>
                            <option value="10">10%</option>
                            <option value="15">15%</option>
                          </select>
                        </td>
                        <td >
                          {(!this.props.disibleInput && this.props.procurementFormList.length > 1) && 
                            <img
                              src={process.env.PUBLIC_URL + "/images/icons/icon_delete.png"}
                              alt="delete"
                              className="iconDelete"
                              onClick={() => this.props.handleRemoveRequisitionListRow(index)}
                            />
                          }
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {!this.props.disibleInput &&
            <div className="col-12 mt-4">
              <button type="button" className="btn addList" onClick={this.props.appendRequisitionListRow}>+ {i18next.t("settingAll:Add list")}</button>
            </div>
          }
        </div>
      </div>
    )
  }
}
