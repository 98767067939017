import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import numberWithCommas from "../../libs/numberWithComma";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import enLocale from "date-fns/locale/en";
import './styles/styles.scss'
import i18next from "i18next";

class BalanceSheetAccountCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataAsset: [],  
      dataLiaAndEqui: [],
    };
  }
  
  componentDidMount() {
    this.setState(
      {
        dataAsset: [
          { accountGroup: `${i18next.t("balanceSheetChart:Cash and deposits")}`, totalAmount: this.props.state.beforeSet?.total_ref_1 },
          { accountGroup: `${i18next.t("balanceSheetChart:Receivables and other current assets")}`, totalAmount: this.props.state.beforeSet?.total_ref_2 + this.props.state.beforeSet?.total_ref_3 },
          { accountGroup: `${i18next.t("balanceSheetChart:Non-current assets")}`, totalAmount: this.props.state.beforeSet?.total_ref_4 + this.props.state.beforeSet?.total_ref_5 },
        ],

        dataLiaAndEqui: [
          { accountGroup: `${i18next.t("balanceSheetChart:Liabilities")}`, totalAmount: this.props.state.beforeSet?.total_ref_6 + this.props.state.beforeSet?.total_ref_7 + this.props.state.beforeSet?.total_ref_8 + this.props.state.beforeSet?.total_ref_9 + this.props.state.beforeSet?.total_ref_10 + this.props.state.beforeSet?.total_ref_11 + this.props.state.beforeSet?.total_ref_12 + this.props.state.beforeSet?.total_ref_13},
          { accountGroup: `${i18next.t("balanceSheetChart:Surplus (Deficit)")}`, totalAmount: this.props.state.co_owner[2]?.yearFirst + this.props.state.co_owner[1]?.before_amount_2 + this.props.state.co_owner[2]?.accruedSurplusFirst},
          { accountGroup: `${i18next.t("balanceSheetChart:Fund")}`,    totalAmount: (
              this.props.state.co_owner[0]?.before_amount_1 +
              Object.values(this.props.state.co_owner_ex)
                .map((values) => values?.[1] || 0)
                .reduce((accumulator, value) => accumulator + value, 0)
            ),},
        ],
      },
      () => {
            this.genChartAsset(this.state.dataAsset); 
            this.genChartLiaAndEqui(this.state.dataLiaAndEqui);
      }
    );
    this.genChartAsset(this.state.dataAsset);
    this.genChartLiaAndEqui(this.state.dataLiaAndEqui);
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.state !== prevProps.state) {
      this.setState(
        {
          dataAsset: [
            { accountGroup: `${i18next.t("balanceSheetChart:Cash and deposits")}`, totalAmount: this.props.state.beforeSet?.total_ref_1 },
            { accountGroup: `${i18next.t("balanceSheetChart:Receivables and other current assets")}`, totalAmount: this.props.state.beforeSet?.total_ref_2 + this.props.state.beforeSet?.total_ref_3 },
            { accountGroup: `${i18next.t("balanceSheetChart:Non-current assets")}`, totalAmount: this.props.state.beforeSet?.total_ref_4 + this.props.state.beforeSet?.total_ref_5 },
          ],

          dataLiaAndEqui: [
            { accountGroup: `${i18next.t("balanceSheetChart:Liabilities")}`, totalAmount: this.props.state.beforeSet?.total_ref_6 + this.props.state.beforeSet?.total_ref_7 + this.props.state.beforeSet?.total_ref_8 + this.props.state.beforeSet?.total_ref_9 + this.props.state.beforeSet?.total_ref_10 + this.props.state.beforeSet?.total_ref_11 + this.props.state.beforeSet?.total_ref_12 + this.props.state.beforeSet?.total_ref_13},
            { accountGroup: `${i18next.t("balanceSheetChart:Surplus (Deficit)")}`, totalAmount: this.props.state.co_owner[2]?.yearFirst + this.props.state.co_owner[1]?.before_amount_2 + this.props.state.co_owner[2]?.accruedSurplusFirst},
            { accountGroup: `${i18next.t("balanceSheetChart:Fund")}`,    totalAmount: (
                this.props.state.co_owner[0]?.before_amount_1 +
                Object.values(this.props.state.co_owner_ex)
                  .map((values) => values?.[1] || 0)
                  .reduce((accumulator, value) => accumulator + value, 0)
              ),},
          ],
        },
        () => {
              this.genChartAsset(this.state.dataAsset); 
              this.genChartLiaAndEqui(this.state.dataLiaAndEqui);
        }
      );
    }
  }
  
  genChartAsset(chartData) {
    if (this.root) {
        // Dispose of the old root and chart
        this.root.dispose(); 
        this.chart.dispose(); 
      }
    // PIE
    let data = chartData;

    let root = am5.Root.new("balanceSheetAccountCharts");

    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,    
        radius: 120,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
      })
    );

    let locale = `${i18next.t("balanceSheetChart:Locale")}`
    let dateAsAt = `${format(this.props.state.end_date_first, 'DD MMM YYYY', { locale: locale === 'thLocale'? thLocale : enLocale})}`

    chart.children.unshift(am5.Label.new(root, {
      text: `${i18next.t("balanceSheetChart:asset as at")} ${dateAsAt} = ${numberWithCommas(data[0]?.totalAmount + data[1]?.totalAmount + data[2]?.totalAmount)} ${i18next.t("balanceSheetChart:baht")}`,
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      x: am5.percent(50),
      centerX: am5.percent(50),
      paddingTop: 0,
      paddingBottom: 0
    }));

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "totalAmount",
        categoryField: "accountGroup",
        tooltip: am5.Tooltip.new(root, {
          // ทำให้ตอน hover สามารถเลื่อนไปคลิ๊กได้
          keepTargetHover: true,
        })
      })
    );

    // hide ticks
    series.ticks.template.set("forceHidden", true);
    series.labels.template.set("forceHidden", true);

    series.get("tooltip").label.set("interactive", true);
          
    series.slices.template.setAll({
      tooltipHTML: `<b style="color: black;">{category}: {value.formatNumber('#,###.00')}</b>`,
      strokeWidth: 2,
    });

    series.get("colors").set("colors", [
      am5.color(0x1567B4),
      am5.color(0x008FCE),
      am5.color(0x00B2C8),
    ]);

    series.labels.template.set("tooltipText", "{accountGroup}: {totalAmount.formatNumber('#,###.00')}");

    // Set data
    series.data.setAll(data);

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
    });
    let annotator = am5plugins_exporting.Annotator.new(root, {});

    exporting.get("menu").set("items", [
      {
        type: "format",
        format: "jpg",
        label: "Export Image",
      },
      {
        type: "format",
        format: "print",
        label: "Print",
      },
      {
        type: "custom",
        label: "Remark",
        callback: function () {
          this.close();
          annotator.toggle();
        },
      },
    ]);

    // Create legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: am5.GridLayout.new(root, {
          maxColumns: 2,
          fixedWidthGrid: true,
        }),
      })
    );

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    series.appear(1000, 100);
    root._logo.dispose();

    this.chart = chart;
    this.root = root;
  }
  genChartLiaAndEqui(chartData) {
    if (this.root2) {
        // Dispose of the old root and chart
        this.root2.dispose(); 
        this.chart2.dispose(); 
      }
    // PIE
    let data = chartData;
    let root2 = am5.Root.new("balanceSheetAccountCharts2");

    root2.setThemes([am5themes_Animated.new(root2)]);

    // Create chart
    let chart2 = root2.container.children.push(
      am5percent.PieChart.new(root2, {
        layout: root2.verticalLayout,
        radius: 120,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
      })
    );

    let locale = `${i18next.t("balanceSheetChart:Locale")}`
    let dateAsAt = `${format(this.props.state.end_date_first, 'DD MMM YYYY', { locale: locale === 'thLocale'? thLocale : enLocale})}`
    
    chart2.children.unshift(am5.Label.new(root2, {
      text: `${i18next.t("balanceSheetChart:total")} = ${numberWithCommas(data[0]?.totalAmount + data[1]?.totalAmount + data[2]?.totalAmount)} ${i18next.t("balanceSheetChart:baht")}`,
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      x: am5.percent(50),
      centerX: am5.percent(50)
    }));

    chart2.children.unshift(am5.Label.new(root2, {
      text: `${i18next.t("balanceSheetChart:Liabilities + Owner's Equity as at")} ${dateAsAt} = ${numberWithCommas(data[0]?.totalAmount)} + ${numberWithCommas(data[1]?.totalAmount+data[2]?.totalAmount)} ${i18next.t("balanceSheetChart:baht")}`,
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      x: am5.percent(50),
      centerX: am5.percent(50),
      paddingTop: 0,
      paddingBottom: 0
    }));
    
    // Create series
    let series = chart2.series.push(
      am5percent.PieSeries.new(root2, {
        valueField: "totalAmount",
        categoryField: "accountGroup",
        tooltip: am5.Tooltip.new(root2, {
          // ทำให้ตอน hover สามารถเลื่อนไปคลิ๊กได้
          keepTargetHover: true,
        })
      })
    );

    series.ticks.template.set("forceHidden", true);
    series.labels.template.set("forceHidden", true);

    series.get("colors").set("colors", [
        am5.color(0xAC3F5E), 
        am5.color(0xE77490),  
        am5.color(0xA4ABBD),
    ]);

    series.get("tooltip").label.set("interactive", true);
          
    series.slices.template.setAll({
      tooltipHTML: `<b style="color: black;">{category}: {value.formatNumber('#,###.00')}</b>`,
      strokeWidth: 2,
    });

    // Set data
    series.data.setAll(data);

    let exporting = am5plugins_exporting.Exporting.new(root2, {
      menu: am5plugins_exporting.ExportingMenu.new(root2, {}),
    });
    let annotator = am5plugins_exporting.Annotator.new(root2, {});

    exporting.get("menu").set("items", [
      {
        type: "format",
        format: "jpg",
        label: "Export Image",
      },
      {
        type: "format",
        format: "print",
        label: "Print",
      },
      {
        type: "custom",
        label: "Remark",
        callback: function () {
          this.close();
          annotator.toggle();
        },
      },
    ]);

    // Create legend
    let legend = chart2.children.push(
      am5.Legend.new(root2, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: am5.GridLayout.new(root2, {
          maxColumns: 2,
          fixedWidthGrid: true,
        }),
      })
    );

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    series.appear(1000, 100);
    root2._logo.dispose();

    this.chart2 = chart2;
    this.root2 = root2;
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
    if (this.root2) {
      this.root2.dispose();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="balanceSheetChartsContainer" id="balanceSheetChartsContainer">
          <div id="balanceSheetAccountCharts"></div>
          <div id="balanceSheetAccountCharts2"></div>
        </div>
        
      </React.Fragment>
    );
  }
}

export default BalanceSheetAccountCharts;
