import "../../accounting/budget/styles/createBudget.scss";
import "react-datepicker/dist/react-datepicker.css";
import React from 'react';
import { format, getYear, setYear } from "date-fns";
import _ from "lodash";
import { B1, Td, Th } from '../../components/Text/Text';
import DatePicker from "react-datepicker";
import { ButtonDate, ButtonTime } from "./DateCustom";
import { Translation } from "react-i18next";

const SettingPeriodLockAutoTable = ({ state, handleTime, handleDate,handleDateManual, handleTimeManual, handleModal }) => {
    let { select_date, status } = state
    return (
        <div className="table-responsive fade-up card mt-6">
            <table className="table table-hover ">
            <Translation>
                {t =>
                <thead className="thead-light">
                    <tr>
                        <Th className="text-center" >{t("settingPeriodLock:No")} </Th>
                        <Th className="text-center" style={{ minWidth: 50 }} >{t("settingPeriodLock:Status")} </Th>
                        <Th className="text-center" style={{ minWidth: 100 }} >{t("settingPeriodLock:Period start date")} </Th>
                        <Th className="text-center" style={{ minWidth: 100 }} >{t("settingPeriodLock:Period end date")} </Th>
                        <Th className="text-center" style={{ minWidth: 100 }} >{t("settingPeriodLock:Period lock date")} </Th>
                        <Th className="text-center" style={{ minWidth: 100 }} >{t("settingPeriodLock:Time")} </Th>
                        <Th className="text-center" style={{ minWidth: 120 }} >{t("settingPeriodLock:Remark")}</Th>
                    </tr>
                </thead>
                 } 
            </Translation>
                <tbody>
                    {/* {state.lists ? state.lists.map((item, index) => {
                        return <tr key={index} >
                            <Td className="text-center"  > {index + 1} </Td>
                            {item.node.modes.toLowerCase() == 'on' ?
                                <Td className="text-center" > <img src={process.env.PUBLIC_URL + '/images/icons/periodLock/icon_lock.png'} style={{ width: 19.6, height: 22.4 }} /></Td> :
                                <Td className="text-center" > <img src={process.env.PUBLIC_URL + '/images/icons/periodLock/icon_unlock.png'} style={{ width: 19.6, height: 22.4 }} /></Td>}
                            <Td className="text-center" >
                                {item.node.startDate && index !== 0 && <B1>{item.node.startDate ? format(item.node.startDate, 'DD/MM/YYYY') : '/ &nbsp; /'}</B1>}
                                {!item.node.startDate && index !== 0 && <B1>/ /</B1>}
                                {index === 0 && item.node.startDate && <B1>{item.node.startDate ? format(item.node.startDate, 'DD/MM/YYYY') : '/ &nbsp; /'}</B1>}
                                {state.new && index === 0 && !item.node.startDate &&
                                    < DatePicker
                                        selected={setYear(_.cloneDeep(select_date), getYear(state.year))}
                                        onChange={handleDate}
                                        customInput={<ButtonDate date={setYear(_.cloneDeep(select_date), getYear(state.year))} />}
                                        value={select_date}
                                        minDate={new Date().setFullYear(getYear(state.year), 0, 1)}
                                        maxDate={new Date().setFullYear(getYear(state.year), 11, 1)}
                                    />}
                            </Td>
                            <Td className="text-center" >
                                <B1>{item.node.endDate ? format(item.node.endDate, 'DD/MM/YYYY') : '/ /'}</B1>
                            </Td>
                            <Td className="text-center" >
                                <B1>{item.node.periodDate ? format(item.node.periodDate, 'DD/MM/YYYY') : '/ /'}</B1>
                            </Td>
                            <Td className="text-center" >
                                {state.new && index === 0 ?
                                    <DatePicker
                                        onChange={handleTime}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="HH:mm"
                                        customInput={<ButtonTime timer={item.node.timer} />}
                                        value={item.node.timer}
                                    />
                                    : <B1>{item.node.timer ? format(item.node.timer, 'HH:mm') : '/ /'}</B1>}

                            </Td>
                            <Td className="text-center" ></Td>
                        </tr>
                    }) : <tr></tr>} */}

                    {state.lists ? state.lists.map((item, index) => {
                        return <tr key={index} >
                            <Td className="text-center" > {index + 1} </Td>
                            {item.node.modes.toLowerCase() == 'on' ?
                                <Td className="text-center" > <img src={process.env.PUBLIC_URL + '/images/icons/periodLock/icon_lock.png'} style={{ width: 19.6, height: 22.4 }} onClick={() => { handleModal(index, item) }} /></Td> :
                                <Td className="text-center" > <img src={process.env.PUBLIC_URL + '/images/icons/periodLock/icon_unlock.png'} style={{ width: 19.6, height: 22.4 }} onClick={() => { handleModal(index, item) }} /></Td>}
                            <Td className="text-center" >
                                {item.node.startDate && <B1>{item.node.startDate ? format(item.node.startDate, 'DD/MM/YYYY') : '/ /'}</B1>}
                                {/* // < DatePicker
                                //     selected={select_date}
                                //     onChange={handleDate}
                                //     customInput={<ButtonDate date={select_date} />}
                                //     value={select_date}
                                // /> */}
                                {index === 0 && !item.node.startDate &&
                                    < DatePicker
                                        selected={setYear(_.cloneDeep(select_date), getYear(state.year))}
                                        onChange={handleDate}
                                        customInput={<ButtonDate date={setYear(_.cloneDeep(select_date), getYear(state.year))} />}
                                        value={select_date}
                                        minDate={new Date().setFullYear(getYear(state.year), 0, 1)}
                                        maxDate={new Date().setFullYear(getYear(state.year), 11, 1)}
                                    />
                                }
                            </Td>
                            <Td className="text-center" >
                                <B1>{item.node.endDate ? format(item.node.endDate, 'DD/MM/YYYY') : '/ /'}</B1>
                            </Td>
                            <Td className="text-center" >
                                {!state.save_status ?
                                    < DatePicker
                                        selected={item.node.periodDate}
                                        onChange={(date) => handleDateManual(`[${index}].node.periodDate`, date)}
                                        customInput={<ButtonDate date={item.node.periodDate} />}
                                        value={item.node.periodDate}
                                    /> :
                                    <B1>{item.node.periodDate ? format(item.node.periodDate, 'DD/MM/YYYY') : '/ /'}</B1>
                                }
                            </Td>
                            <Td className="text-center" >
                                {!state.save_status ?
                                    <DatePicker
                                        onChange={(date) => handleTimeManual(`[${index}].node.timer`, date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        customInput={<ButtonTime timer={item.node.timer} />}
                                        value={item.node.timer}
                                    /> : <B1>{item.node.timer ? format(item.node.timer, 'HH:mm') : ''}</B1>}
                            </Td>
                            <Td className="text-center"></Td>
                        </tr>
                    }) : <tr></tr>}
                </tbody>
            </table>
        </div>
    );
};


export default SettingPeriodLockAutoTable;