/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type ContactTypeOfCompany = "BRANCH" | "HEADQUARTERS" | "NOBRANCH" | "%future added value";
export type PaymentGatewayPaymentType = "CROSSBILL" | "DEEPLINK" | "ETC" | "KSHER" | "SLIP_VERIFICATION" | "TWOCTWOP" | "%future added value";
export type PaymentGatewayTypeCal = "MONNEY_FAIT" | "PERCENT" | "%future added value";
export type paymentGatewayDepositCreateQueryVariables = {|
  paymentGatewaySettingListType?: ?string
|};
export type paymentGatewayDepositCreateQueryResponse = {|
  +allPaymentGatewayMethod: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +paymentType: ?string,
        +paymentGateway: ?{|
          +id: string,
          +paymentType: PaymentGatewayPaymentType,
          +fee: number,
          +typeCal: PaymentGatewayTypeCal,
        |},
        +receive: ?{|
          +id: string,
          +docNumber: string,
          +issuedDate: any,
          +contact: {|
            +id: string,
            +refNumber: string,
            +name: string,
            +firstName: string,
            +taxIdNumber: ?string,
            +lastName: string,
            +typeOfCompany: ?ContactTypeOfCompany,
            +nameBranch: string,
          |},
        |},
        +bankAccount: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
        |},
        +description: ?string,
        +amount: number,
        +feeAmount: number,
        +total: number,
        +depositDate: ?any,
        +image: ?string,
      |}
    |}>
  |},
  +paymentGatewaySettingList: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +paymentType: PaymentGatewayPaymentType,
        +fee: number,
        +typeCal: PaymentGatewayTypeCal,
        +bankAccount: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
        |},
      |}
    |}>
  |},
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +accountNumber: string,
          +accountName: string,
          +bankName: string,
        |}
      |}>
    |}
  |},
|};
export type paymentGatewayDepositCreateQuery = {|
  variables: paymentGatewayDepositCreateQueryVariables,
  response: paymentGatewayDepositCreateQueryResponse,
|};
*/


/*
query paymentGatewayDepositCreateQuery(
  $paymentGatewaySettingListType: String
) {
  allPaymentGatewayMethod(paymentGatewayId: $paymentGatewaySettingListType, status: "not_pass", paymentGateway_PaymentType_In: "twoctwop,deeplink,slip_verification,ksher") {
    edges {
      node {
        id
        paymentType
        paymentGateway {
          id
          paymentType
          fee
          typeCal
        }
        receive {
          id
          docNumber
          issuedDate
          contact {
            id
            refNumber
            name
            firstName
            taxIdNumber
            lastName
            typeOfCompany
            nameBranch
          }
        }
        bankAccount {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
        }
        description
        amount
        feeAmount
        total
        depositDate
        image
      }
    }
  }
  paymentGatewaySettingList(paymentType_In: "twoctwop,deeplink,ksher") {
    edges {
      node {
        id
        paymentType
        fee
        typeCal
        bankAccount {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
        }
      }
    }
  }
  bankAccountViewer {
    allBankAccount {
      edges {
        node {
          id
          accountNumber
          accountName
          bankName
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "paymentGatewaySettingListType"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fee",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeCal",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccountNode",
  "kind": "LinkedField",
  "name": "bankAccount",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivePayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "makePayment",
      "storageKey": null
    },
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "paymentGatewayId",
      "variableName": "paymentGatewaySettingListType"
    },
    {
      "kind": "Literal",
      "name": "paymentGateway_PaymentType_In",
      "value": "twoctwop,deeplink,slip_verification,ksher"
    },
    {
      "kind": "Literal",
      "name": "status",
      "value": "not_pass"
    }
  ],
  "concreteType": "PaymentGatewayMethodNodeConnection",
  "kind": "LinkedField",
  "name": "allPaymentGatewayMethod",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentGatewayMethodNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentGatewayMethodNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentGatewayNode",
              "kind": "LinkedField",
              "name": "paymentGateway",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ReceiveNode",
              "kind": "LinkedField",
              "name": "receive",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "issuedDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContactNode",
                  "kind": "LinkedField",
                  "name": "contact",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "refNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "taxIdNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "typeOfCompany",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "nameBranch",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feeAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "depositDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "paymentType_In",
      "value": "twoctwop,deeplink,ksher"
    }
  ],
  "concreteType": "PaymentGatewayNodeConnection",
  "kind": "LinkedField",
  "name": "paymentGatewaySettingList",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentGatewayNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentGatewayNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v9/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "paymentGatewaySettingList(paymentType_In:\"twoctwop,deeplink,ksher\")"
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentGatewayDepositCreateQuery",
    "selections": [
      (v10/*: any*/),
      (v11/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "paymentGatewayDepositCreateQuery",
    "selections": [
      (v10/*: any*/),
      (v11/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bba22faa00972912a68efb267a0f571c",
    "id": null,
    "metadata": {},
    "name": "paymentGatewayDepositCreateQuery",
    "operationKind": "query",
    "text": "query paymentGatewayDepositCreateQuery(\n  $paymentGatewaySettingListType: String\n) {\n  allPaymentGatewayMethod(paymentGatewayId: $paymentGatewaySettingListType, status: \"not_pass\", paymentGateway_PaymentType_In: \"twoctwop,deeplink,slip_verification,ksher\") {\n    edges {\n      node {\n        id\n        paymentType\n        paymentGateway {\n          id\n          paymentType\n          fee\n          typeCal\n        }\n        receive {\n          id\n          docNumber\n          issuedDate\n          contact {\n            id\n            refNumber\n            name\n            firstName\n            taxIdNumber\n            lastName\n            typeOfCompany\n            nameBranch\n          }\n        }\n        bankAccount {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n        }\n        description\n        amount\n        feeAmount\n        total\n        depositDate\n        image\n      }\n    }\n  }\n  paymentGatewaySettingList(paymentType_In: \"twoctwop,deeplink,ksher\") {\n    edges {\n      node {\n        id\n        paymentType\n        fee\n        typeCal\n        bankAccount {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n        }\n      }\n    }\n  }\n  bankAccountViewer {\n    allBankAccount {\n      edges {\n        node {\n          id\n          accountNumber\n          accountName\n          bankName\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b06be9bdca898fc777433160c9053814';

module.exports = node;
