import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import numberWithComma from "../../libs/numberWithComma";
import { formatDateISO } from "../../utils";
import _ from "lodash";
import Loading from "../../libs/loading";

const query = graphql`
    query differentTableQuery ($startDateFirst: DateTime, $endDateFirst: DateTime, $startDateSecond: DateTime, $endDateSecond: DateTime, $period: String) {
        allAccountProjectManager{
            edges{
              node{
                id
                tableAccount
              }
            }
        }

        capitalCash: chartOfAccountBalanceSheetAccount(startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond, typeAccount: "3000"){
            edges{
                node{
                    id
                    name
                    chartOfAccountCode
                    col1Debit
                    col1Credit
                    col2Debit
                    col2Credit
                    totalHistoricalDebit
                    totalHistoricalCredit
                }
            }
        }
        chartOfAccountSummaryIncomeExpenseDiff(startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond period: $period) {
            yearFirst
            yearSecond
            accruedSurplusFirst
            accruedSurplusSecond
        }
    }
`;

class DifferentTable extends React.PureComponent {

    setDataOwner(data_query) {
        let dataSet = data_query.capitalCash;
        let co_owner = [];
        let before_amount_1 = 0;
        let after_amount_1 = 0;
        let before_amount_2 = 0;
        let after_amount_2 = 0;

        const tableAccount = data_query.allAccountProjectManager.edges[0]?.node.tableAccount;
        // const start_date_first = formatDateISO(this.props.state.start_date_first);
        const end_date_first = formatDateISO(this.props.state.end_date_first);
        // const start_date_second = formatDateISO(this.props.state.start_date_second);
        const end_date_second = formatDateISO(this.props.state.end_date_second);
        let tableAccount_1 = false;
        let tableAccount_2 = false;
        if(this.props.state.period === 'year'){
            tableAccount_1 =  tableAccount <= end_date_first ? true : false
            tableAccount_2 =  tableAccount <= end_date_second ? true : false
        }else{
            tableAccount_1 = tableAccount <= end_date_first ? true : false
            tableAccount_2 = tableAccount <= end_date_second ? true : false
        }

        for (let i = 0; i < dataSet.edges.length; i++) {
            let data = dataSet.edges[i].node;
            if (data.chartOfAccountCode.substring(0, 7) === "3000-02") {
                if(tableAccount_1){
                    before_amount_2 += (_.get(data, 'col1Credit') + data.totalHistoricalCredit) - (_.get(data, 'col1Debit') + data.totalHistoricalDebit);
                }
                if(tableAccount_2){
                    after_amount_2 += (_.get(data, 'col2Credit') + data.totalHistoricalCredit) - (_.get(data, 'col2Debit') + data.totalHistoricalDebit);
                }
                continue;
            }
        }
        co_owner.push({ before_amount_1, after_amount_1 });
        co_owner.push({ before_amount_2, after_amount_2 });
        if (_.isObject(data_query.chartOfAccountSummaryIncomeExpenseDiff)) {
            let yearFirst = data_query.chartOfAccountSummaryIncomeExpenseDiff.yearFirst;
            let yearSecond = data_query.chartOfAccountSummaryIncomeExpenseDiff.yearSecond;
            let accruedSurplusFirst = data_query.chartOfAccountSummaryIncomeExpenseDiff.accruedSurplusFirst;
            let accruedSurplusSecond = data_query.chartOfAccountSummaryIncomeExpenseDiff.accruedSurplusSecond;
            co_owner.push({ yearFirst, yearSecond,accruedSurplusFirst,accruedSurplusSecond })
        }
        return co_owner;

    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                cacheConfig={{ use_cache: false }}
                variables={{
                    startDateFirst: this.props.state.start_date_first,
                    endDateFirst: this.props.state.end_date_first,
                    startDateSecond: this.props.state.start_date_second,
                    endDateSecond: this.props.state.end_date_second,
                    period: this.props.state.period,
                }}
                render={({ error, props }) => {
                    if (props) {
                        let co_owner = this.setDataOwner(props);
                        return (
                            <React.Fragment>
                                <tr>
                                    <td>
                                        <strong>รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด / Accrue Surplus (Deficit) Current Period</strong>
                                    </td>
                                    <td className="text-right">
                                        <strong>
                                            {numberWithComma(_.get(co_owner, "[2].yearFirst", 0), "-")}
                                        </strong>
                                    </td>
                                    <td className="text-right">
                                        <strong>
                                            {numberWithComma(_.get(co_owner, "[2].yearSecond", 0), "-")}
                                        </strong>
                                    </td>

                                </tr>

                                <tr>
                                    <td>
                                        <strong>รายรับสูง(ต่ำ)กว่ารายจ่ายยกมา / B/F Accrued Surplus (Deficit)</strong>
                                    </td>
                                    <td className="text-right">
                                        <strong>
                                            {/* {numberWithComma(_.get(co_owner, "[1].before_amount_2", 0), "-")} */}
                                            {numberWithComma( _.get(co_owner, "[1].before_amount_2", 0) + _.get(co_owner, "[2].accruedSurplusFirst", 0), "-")}
                                        </strong>
                                    </td>
                                    <td className="text-right">
                                        <strong>
                                            {/* {numberWithComma(_.get(co_owner, "[1].after_amount_2", 0), "-")} */}
                                            {numberWithComma( _.get(co_owner, "[1].after_amount_2", 0) + _.get(co_owner, "[2].accruedSurplusSecond", 0), "-")}
                                        </strong>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <strong>รายรับสูง(ต่ำ) กว่ารายจ่ายสะสม / Accrued Surplus (Deficit) Accumulate</strong>
                                    </td>
                                    <td className="text-right">
                                        <strong>
                                            {numberWithComma( 
                                            _.get(co_owner, "[2].yearFirst", 0) +
                                            _.get(co_owner, "[1].before_amount_2", 0) + 
                                            _.get(co_owner, "[2].accruedSurplusFirst", 0),  "-" )}
                                        </strong>
                                    </td>
                                    <td className="text-right">
                                        <strong>
                                            {numberWithComma( 
                                                _.get(co_owner, "[2].yearSecond", 0) + 
                                                _.get(co_owner, "[1].after_amount_2", 0) +
                                                _.get(co_owner, "[2].accruedSurplusSecond", 0), "-" )}
                                        </strong>
                                    </td>
                                </tr>
                            </React.Fragment>
                        )
                    } else {
                        return <Loading />
                    }
                }}
            />

        )
    }
}

export default DifferentTable;