import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import NewsList from "./newsList";
import CreateUpdateNews from "./createUpdateNews";
import NoMatch from "../../components/noMatch";

class Survey extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/:news_type`} component={NewsList}/>
                <PrivateRoute exact path={`${this.props.match.url}/:action?/form/:id?/:status?`} component={CreateUpdateNews}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Survey;