/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type payableClassifiedReportProductTableQueryVariables = {|
  first?: ?number,
  last?: ?number,
  startMonth?: ?number,
  startYear?: ?number,
  endMonth?: ?number,
  endYear?: ?number,
  search?: ?string,
|};
export type payableClassifiedReportProductTableQueryResponse = {|
  +productViewer: ?{|
    +productSummaryByStatusPayable: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +productCode: string,
          +col1Total: ?number,
          +col2Total: ?number,
          +col3Total: ?number,
          +col4Total: ?number,
          +col5Total: ?number,
          +col6Total: ?number,
          +col7Total: ?number,
          +col8Total: ?number,
          +col9Total: ?number,
          +col10Total: ?number,
          +col11Total: ?number,
          +col12Total: ?number,
          +colAllTotal: ?number,
          +col1Paid: ?number,
          +col2Paid: ?number,
          +col3Paid: ?number,
          +col4Paid: ?number,
          +col5Paid: ?number,
          +col6Paid: ?number,
          +col7Paid: ?number,
          +col8Paid: ?number,
          +col9Paid: ?number,
          +col10Paid: ?number,
          +col11Paid: ?number,
          +col12Paid: ?number,
          +colAllPaid: ?number,
          +col1NotPay: ?number,
          +col2NotPay: ?number,
          +col3NotPay: ?number,
          +col4NotPay: ?number,
          +col5NotPay: ?number,
          +col6NotPay: ?number,
          +col7NotPay: ?number,
          +col8NotPay: ?number,
          +col9NotPay: ?number,
          +col10NotPay: ?number,
          +col11NotPay: ?number,
          +col12NotPay: ?number,
          +colAllNotPay: ?number,
        |}
      |}>,
    |}
  |}
|};
export type payableClassifiedReportProductTableQuery = {|
  variables: payableClassifiedReportProductTableQueryVariables,
  response: payableClassifiedReportProductTableQueryResponse,
|};
*/


/*
query payableClassifiedReportProductTableQuery(
  $first: Int
  $last: Int
  $startMonth: Int
  $startYear: Int
  $endMonth: Int
  $endYear: Int
  $search: String
) {
  productViewer {
    productSummaryByStatusPayable(startMonth: $startMonth, startYear: $startYear, first: $first, last: $last, search: $search, endMonth: $endMonth, endYear: $endYear) {
      totalCount
      edges {
        node {
          id
          name
          productCode
          col1Total
          col2Total
          col3Total
          col4Total
          col5Total
          col6Total
          col7Total
          col8Total
          col9Total
          col10Total
          col11Total
          col12Total
          colAllTotal
          col1Paid
          col2Paid
          col3Paid
          col4Paid
          col5Paid
          col6Paid
          col7Paid
          col8Paid
          col9Paid
          col10Paid
          col11Paid
          col12Paid
          colAllPaid
          col1NotPay
          col2NotPay
          col3NotPay
          col4NotPay
          col5NotPay
          col6NotPay
          col7NotPay
          col8NotPay
          col9NotPay
          col10NotPay
          col11NotPay
          col12NotPay
          colAllNotPay
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endMonth"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endYear"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startMonth"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startYear"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endMonth",
      "variableName": "endMonth"
    },
    {
      "kind": "Variable",
      "name": "endYear",
      "variableName": "endYear"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    },
    {
      "kind": "Variable",
      "name": "startMonth",
      "variableName": "startMonth"
    },
    {
      "kind": "Variable",
      "name": "startYear",
      "variableName": "startYear"
    }
  ],
  "concreteType": "ProductAndServiceNodeConnection",
  "kind": "LinkedField",
  "name": "productSummaryByStatusPayable",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAndServiceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col1Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col2Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col3Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col4Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col5Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col6Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col7Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col8Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col9Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col10Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col11Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col12Total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "colAllTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col1Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col2Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col3Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col4Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col5Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col6Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col7Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col8Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col9Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col10Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col11Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col12Paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "colAllPaid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col1NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col2NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col3NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col4NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col5NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col6NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col7NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col8NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col9NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col10NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col11NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col12NotPay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "colAllNotPay",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "payableClassifiedReportProductTableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "payableClassifiedReportProductTableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d311ddc79a8a6aace3a1dda2175f4caf",
    "id": null,
    "metadata": {},
    "name": "payableClassifiedReportProductTableQuery",
    "operationKind": "query",
    "text": "query payableClassifiedReportProductTableQuery(\n  $first: Int\n  $last: Int\n  $startMonth: Int\n  $startYear: Int\n  $endMonth: Int\n  $endYear: Int\n  $search: String\n) {\n  productViewer {\n    productSummaryByStatusPayable(startMonth: $startMonth, startYear: $startYear, first: $first, last: $last, search: $search, endMonth: $endMonth, endYear: $endYear) {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          productCode\n          col1Total\n          col2Total\n          col3Total\n          col4Total\n          col5Total\n          col6Total\n          col7Total\n          col8Total\n          col9Total\n          col10Total\n          col11Total\n          col12Total\n          colAllTotal\n          col1Paid\n          col2Paid\n          col3Paid\n          col4Paid\n          col5Paid\n          col6Paid\n          col7Paid\n          col8Paid\n          col9Paid\n          col10Paid\n          col11Paid\n          col12Paid\n          colAllPaid\n          col1NotPay\n          col2NotPay\n          col3NotPay\n          col4NotPay\n          col5NotPay\n          col6NotPay\n          col7NotPay\n          col8NotPay\n          col9NotPay\n          col10NotPay\n          col11NotPay\n          col12NotPay\n          colAllNotPay\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0cd9f38d007a5832b186f1a7b11cadc2';

module.exports = node;
