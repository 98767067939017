import {commitMutation} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const mutation = graphql`
    mutation updateRegulationMutation ($input: UpdateRegulationInput!) {
    updateRegulation (input: $input) {
        ok
        message
    }
}

`

export default function updateMutation(variables, callback, error_callback) {
    commitMutation(
        environment,
        {
          mutation,
          variables,
          onCompleted: (response) => {
            callback(response.updateRegulation.ok)
          },
          onError: (err) => {
              error_callback(err);
          },
        },
      )
}