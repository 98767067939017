import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import NoMatch from "../../../components/noMatch";
import {Route, Switch} from "react-router-dom";
import ContractServiceList from "./contractServiceList";
import ContractServiceCreate from "./contractServiceCreate";
import ContractServiceView from "./contractServiceView";


class ContractService extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={ContractServiceList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status`} component={ContractServiceCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/view/:id`} component={ContractServiceView}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default ContractService;