/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserConfigSidebarSize = "FULL" | "HALF" | "%future added value";
export type UpdateUserConfigInput = {|
  sidebarSize?: ?string,
  languages?: ?string,
  clientMutationId?: ?string,
|};
export type toggleMenuSwitchMutationVariables = {|
  input: UpdateUserConfigInput
|};
export type toggleMenuSwitchMutationResponse = {|
  +UpdateUserConfig: ?{|
    +newUserConfig: ?{|
      +id: string,
      +languages: string,
      +sidebarSize: UserConfigSidebarSize,
    |}
  |}
|};
export type toggleMenuSwitchMutation = {|
  variables: toggleMenuSwitchMutationVariables,
  response: toggleMenuSwitchMutationResponse,
|};
*/


/*
mutation toggleMenuSwitchMutation(
  $input: UpdateUserConfigInput!
) {
  UpdateUserConfig(input: $input) {
    newUserConfig {
      id
      languages
      sidebarSize
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserConfigPayload",
    "kind": "LinkedField",
    "name": "UpdateUserConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserConfigNode",
        "kind": "LinkedField",
        "name": "newUserConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "languages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sidebarSize",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleMenuSwitchMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleMenuSwitchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b76f4be6d86314f25f64da3394f137cc",
    "id": null,
    "metadata": {},
    "name": "toggleMenuSwitchMutation",
    "operationKind": "mutation",
    "text": "mutation toggleMenuSwitchMutation(\n  $input: UpdateUserConfigInput!\n) {\n  UpdateUserConfig(input: $input) {\n    newUserConfig {\n      id\n      languages\n      sidebarSize\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '84f7ad65210df9e2b7a9dbb84c5fdbfa';

module.exports = node;
