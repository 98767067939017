import React, { Component } from 'react'
import NavigationMenuSetup from '../libs/navigationMenuSetup'
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../env/environment';
import _ from "lodash"

const query = graphql`
    query purchasingTopMenuNavigationQuery{
        getNotificationProcurementForm {
          type
          count
        }
        getNotificationWaitPurchaseOrderes {
          type
          count
        }
        
    }
`;
export default class purchasingTopMenuNavigation extends Component {

  constructor(props) {
    super(props)

    this.state = {
      countPurchaseRequisition: window.localStorage.getItem("countPurchaseRequisition") || "0",
      countPurchaseOrder: window.localStorage.getItem("countPurchaseOrder") || "0",
    }
  }

  componentDidMount() {
    if (this.props.center) {
      NavigationMenuSetup();
    }
    this.getData()
  }

  getData = () => {
    let newCountPurchaseRequisition = 0
    let newCountPurchaseOrder = 0
    fetchQuery(environment, query).then((response) => {
      if (response.getNotificationProcurementForm) {
        _.forEach(response.getNotificationProcurementForm, (item) => {
          newCountPurchaseRequisition += item.count
        })
        window.localStorage.setItem("countPurchaseRequisition", newCountPurchaseRequisition)
      }
      if (response.getNotificationWaitPurchaseOrderes) {
        _.forEach(response.getNotificationWaitPurchaseOrderes, (item) => {
          newCountPurchaseOrder += item.count
        })
        window.localStorage.setItem("countPurchaseOrder", newCountPurchaseOrder)
      }


      this.setState({
        countPurchaseRequisition: `${newCountPurchaseRequisition}`,
        countPurchaseOrder: `${newCountPurchaseOrder}`,
      })
    })
  }



  render() {
    return (
      <div className="container-fluid bg-gray">

        <div className={"row "
          + (this.props.mini ? "mini " : "")
          + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

          {this.props.center &&
            <Translation>{t => <div className="col-12 mt-5 mb-5"><h3 className="text-center">{t("set_permission:Common Property Management")}</h3></div>}</Translation>
          }

          <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
            <ul>
              <li>
                <a className="dropdown-toggle align-items-center d-flex" href="/" role="button"
                  id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <img style={{ width: 24, height: 24 }}
                    src={process.env.PUBLIC_URL + '/images/propertyManagement/logoOrder.svg'}
                    alt="parcel-top-menu"
                    className={this.props.mini && 'd-none'} />
                  <Translation>
                    {
                      t => <div className="p-0">
                        {t("set_permission:Procurement and Purchase")}
                      </div>
                    }
                  </Translation>
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">

                  {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_calendar' }) && */}
                  <Link to="/purchasing/manageRequest/list/all">
                    <Translation>
                      {
                        t => <div>
                          {t('set_permission:Procurement')+" "+"(PRF)"}
                        </div>
                      }
                    </Translation>
                  </Link>
                  {/* } */}

                  {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_list' }) && */}
                  <Link to="/purchasing/manageOrder/list/all">
                    <Translation>
                      {
                        t => <div>
                          {t('set_permission:Purchase Order')+" "+"(PO)"}
                        </div>
                      }
                    </Translation>
                  </Link>
                  {/* } */}
                </div>
              </li>
            </ul>
          </div>

          {
            // localStorage.getItem('site_id') && localStorage.getItem('site_id') === '331' &&
            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'claim' }) &&
            <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
              <ul>
                <li>
                  <a className="dropdown-toggle align-items-center d-flex" href="/" role="button"
                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <img style={{ width: 24, height: 24 }}
                      src={process.env.PUBLIC_URL + '/images/propertyManagement/2.svg'}
                      alt="parcel-top-menu"
                      className={this.props.mini && 'd-none'} />
                    <Translation>
                      {
                        t => <div className="p-0">
                          {t("set_permission:Claim Request")}
                        </div>
                      }
                    </Translation>
                  </a>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">

                    {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_calendar' }) && */}

                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'claim' }) &&
                    <Link to="/purchasing/claim/all">
                      <Translation>
                        {
                          t => <div>
                            {t('MenuName:Claim Request Box')+" "+"(CPR)"}
                          </div>
                        }
                      </Translation>
                    </Link>
                    }

                    {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_list' }) && */}
                    <Link to="/purchasing/claim/report">
                      <Translation>
                        {
                          t => <div>
                            {t('MenuName:Claim Reports')}
                          </div>
                        }
                      </Translation>
                    </Link>
                    {/* } */}
                    <Link to="/purchasing/insurrance">
                      <Translation>
                        {
                          t => <div>
                            {t('MenuName:Insurrance Information')}
                          </div>
                        }
                      </Translation>
                    </Link>
                    <Link to="/setting/settingClaim">
                      <Translation>
                        {
                          t => <div>
                            {t('MenuName:Claims Setting')}
                          </div>
                        }
                      </Translation>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          }


        </div>
      </div>
    )
  }
}
