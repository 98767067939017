/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreditNoteStatus = "PAID" | "VOID" | "WAIT" | "%future added value";
export type CreditNoteTransactionStatus = "PAID" | "VOID" | "WAIT" | "%future added value";
export type getCollectionsLettersQueryVariables = {|
  id: string
|};
export type getCollectionsLettersQueryResponse = {|
  +collectionLetters: ?{|
    +id: string,
    +recordCollectionLetters: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +transaction: {|
            +id: string,
            +creditNoteTransaction: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +docNumber: string,
                  +issuedDate: any,
                |}
              |}>
            |},
            +creditNoteTransactionRecord: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +price: number,
                  +creditNote: {|
                    +id: string,
                    +docNumber: string,
                    +issuedDate: any,
                    +status: CreditNoteStatus,
                  |},
                  +status: CreditNoteTransactionStatus,
                |}
              |}>
            |},
            +receiptDepositTransactionUsed: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +receiptDeposit: {|
                    +id: string,
                    +docNumber: string,
                    +issuedDate: any,
                  |},
                |}
              |}>
            |},
            +receiveTransaction: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +receive: {|
                    +id: string,
                    +docNumber: string,
                    +issuedDate: any,
                  |},
                |}
              |}>
            |},
          |}
        |}
      |}>
    |},
  |}
|};
export type getCollectionsLettersQuery = {|
  variables: getCollectionsLettersQueryVariables,
  response: getCollectionsLettersQueryResponse,
|};
*/


/*
query getCollectionsLettersQuery(
  $id: ID!
) {
  collectionLetters(id: $id) {
    id
    recordCollectionLetters {
      edges {
        node {
          transaction {
            id
            creditNoteTransaction(status: "paid") {
              edges {
                node {
                  id
                  docNumber
                  issuedDate
                }
              }
            }
            creditNoteTransactionRecord(status: "paid") {
              edges {
                node {
                  id
                  price
                  creditNote {
                    id
                    docNumber
                    issuedDate
                    status
                  }
                  status
                }
              }
            }
            receiptDepositTransactionUsed {
              edges {
                node {
                  id
                  receiptDeposit {
                    id
                    docNumber
                    issuedDate
                  }
                }
              }
            }
            receiveTransaction {
              edges {
                node {
                  id
                  receive {
                    id
                    docNumber
                    issuedDate
                  }
                }
              }
            }
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "status",
    "value": "paid"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "TransactionNode",
  "kind": "LinkedField",
  "name": "transaction",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": (v3/*: any*/),
      "concreteType": "CreditNoteNodeConnection",
      "kind": "LinkedField",
      "name": "creditNoteTransaction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreditNoteNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CreditNoteNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v6/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "creditNoteTransaction(status:\"paid\")"
    },
    {
      "alias": null,
      "args": (v3/*: any*/),
      "concreteType": "CreditNoteTransactionNodeConnection",
      "kind": "LinkedField",
      "name": "creditNoteTransactionRecord",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreditNoteTransactionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CreditNoteTransactionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CreditNoteNode",
                  "kind": "LinkedField",
                  "name": "creditNote",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "creditNoteTransactionRecord(status:\"paid\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReceiptDepositTransactionUsedNodeConnection",
      "kind": "LinkedField",
      "name": "receiptDepositTransactionUsed",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReceiptDepositTransactionUsedNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReceiptDepositTransactionUsedNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReceiptDepositNode",
                  "kind": "LinkedField",
                  "name": "receiptDeposit",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReceiveTransactionNodeConnection",
      "kind": "LinkedField",
      "name": "receiveTransaction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReceiveTransactionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReceiveTransactionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReceiveNode",
                  "kind": "LinkedField",
                  "name": "receive",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getCollectionsLettersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionLettersNode",
        "kind": "LinkedField",
        "name": "collectionLetters",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RecordCollectionLettersNodeConnection",
            "kind": "LinkedField",
            "name": "recordCollectionLetters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RecordCollectionLettersNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecordCollectionLettersNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getCollectionsLettersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionLettersNode",
        "kind": "LinkedField",
        "name": "collectionLetters",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RecordCollectionLettersNodeConnection",
            "kind": "LinkedField",
            "name": "recordCollectionLetters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RecordCollectionLettersNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecordCollectionLettersNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fcbb81203072f00243ccd0c3cfd0969",
    "id": null,
    "metadata": {},
    "name": "getCollectionsLettersQuery",
    "operationKind": "query",
    "text": "query getCollectionsLettersQuery(\n  $id: ID!\n) {\n  collectionLetters(id: $id) {\n    id\n    recordCollectionLetters {\n      edges {\n        node {\n          transaction {\n            id\n            creditNoteTransaction(status: \"paid\") {\n              edges {\n                node {\n                  id\n                  docNumber\n                  issuedDate\n                }\n              }\n            }\n            creditNoteTransactionRecord(status: \"paid\") {\n              edges {\n                node {\n                  id\n                  price\n                  creditNote {\n                    id\n                    docNumber\n                    issuedDate\n                    status\n                  }\n                  status\n                }\n              }\n            }\n            receiptDepositTransactionUsed {\n              edges {\n                node {\n                  id\n                  receiptDeposit {\n                    id\n                    docNumber\n                    issuedDate\n                  }\n                }\n              }\n            }\n            receiveTransaction {\n              edges {\n                node {\n                  id\n                  receive {\n                    id\n                    docNumber\n                    issuedDate\n                  }\n                }\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e3ec05cacf41dccf3f3bc1db1598eb78';

module.exports = node;
