import React from "react";
import getApiRoot from "../../../../libs/getAPIRoot";
import Helpers from '../../../../libs/file_download_helper';
import {format} from "date-fns";
import i18next from "i18next";
class ExportAssetDepreciateReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            type_report : 'basic' //แบบสรุป basic / แบบละเอียด(แสดงแต่ละเดือนของค่าเสื่อมด้วย) complete
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onClickOpenModal = this.onClickOpenModal.bind(this);
        this.onClickCloseModal = this.onClickCloseModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit = (event) => {
        this.setState({
            errors: null,
            loading: true,
        }, () => {
            Helpers.httpRequest(
                getApiRoot() + 'export/asset_depreciate?start_date=' + format(this.props.state.start_date, 'DD/MM/YYYY')
                + '&end_date=' + format(this.props.state.end_date, 'DD/MM/YYYY')
                + '&last_date=' + format(this.props.state.last_date, 'DD/MM/YYYY')
                + '&search=' + this.props.state.search
                + '&type=' + this.props.state.type
                + '&type_report=' + this.state.type_report,
                'GET',
            )
                .then((response) => response.blob())
                .then((blob) => {
                    // create blob link
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "asset_depreciate.xls");

                    // append to html
                    document.body.appendChild(link);

                    // download
                    link.click();

                    // remove
                    link.parentNode.removeChild(link);

                    this.setState({
                        loading: false,
                        type_report : 'basic'
                    });
                })
                .catch((error) => {
                    error.json().then((json) => {
                        this.setState({
                            errors: json,
                            loading: false,
                            type_report : 'basic'
                        });
                    })
                });
        });

        event.preventDefault();
    };

    onClickOpenModal() {
        this.setState({
            loading : true
        })
    }

    onClickCloseModal(){
        this.setState({
            loading : false,
            type_report : 'basic'
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {

        return (
            <React.Fragment>
                <button type="submit" className="btn print float-right"
                        data-toggle="modal" data-target="#exampleModal"
                        disabled={this.state.loading} onClick={this.onClickOpenModal}>
                    {this.state.loading ?
                        <span>
                        <span
                            className="spinner-border spinner-border-sm align-middle mr-2"/> {i18next.t("Allaction:Preparing information")}</span> :
                        <span>
                        <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon"/>
                            {i18next.t("Allaction:Print")}
                        </span>
                    }
                </button>
                <div className="modal fade bd-example-modal-lg" id="exampleModal"  tabIndex="-1" role="dialog" aria-label="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="exampleModalLabel">{i18next.t("ReportAssetDetail:Choose Print Format")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = {this.onClickCloseModal}>
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        <div className="modal-body">
                            <div className ="row mt-2 ml-2 mr-2">
                                <div className="col-2">
                                    <label className="col-form-label">{i18next.t("ReportAssetDetail:Document Format")} </label>
                                </div>
                                <div className = "col" >
                                    <select name="type_report" className="form-control dropdown-custom-arrow"
                                        onChange={this.handleChange} value={this.state.type_report}>
                                        <option value="basic">{i18next.t("ReportAssetDetail:Summary Depreciation Report")}</option>
                                        <option value="complete">{i18next.t("ReportAssetDetail:Detailed Depreciation Report")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary" data-dismiss="modal" aria-label="Close">ยืนยัน</button>
                        </div>
                        </div>
                    </div>
                </div>
                
            </React.Fragment>
        )
    }
}

export default ExportAssetDepreciateReport;
