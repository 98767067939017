import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import { commitMutation } from "react-relay";
import environment from '../../env/environment';
import { Translation } from 'react-i18next';
import Loading from '../../libs/loading';
import format from 'date-fns/format';
import _ from 'lodash';
import Swal from 'sweetalert2';
import upload from '../../libs/upload';
import './settingCollectionLetterLaw.scss';
import { encode } from 'base-64';
import SettingCollectionLetterLawViewForm1 from './settingCollectionLetterLawViewForm1';
import SettingCollectionLetterLawViewForm2 from './settingCollectionLetterLawViewForm2';
import i18next from 'i18next';
import Select from 'react-select';
import getqueryAllRegisterCollectionLawFirmSite from "./query/queryAllRegisterCollectionLawFirmSite"

const customStyles = {
    control: provided => ({
        ...provided,
        fontFamily: 'kanit'
    })
}

const query = graphql`
    query settingFormCollectionLawQuery($siteId:ID){
        allCollectionLawFirmFromSetting(siteId:$siteId){
            edges{
                node{
                    id
                    documentType
                    dueDateDays
                    feeRateTh
                    feeRateOther
                    lawyerSignature
                    lawyerName
                    telOffice
                    telJuristic
                    language
                    creator
                    creatorEdit
                    # ชื่อคำที่แสดง และ สถานะการใช้งานข้อระเรียบบังคับ
                    regulations
                    regulationsStatus
                    rulesRegulationsjuristic
                    site{
                      id
                      project{
                          edges{
                              node{
                                  id
                                  nameTh
                              }
                          }
                      }
                  }
                }
            } 
        }
        selfProject{
            name
        }
    }
`;


const mutation = graphql`
  mutation settingFormCollectionLawMutation  ($input: CreateAndUpdateCollectionLawFirmFromSettingInput!) {
    createAndUpdateCollectionLawFirmFromSetting(input:$input){
        ok
        massage
    }
}`;

class SettingForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            settingInfo: "",
            settingInfoForm1: {
                projectName: "",
                documentType: "",
                dueDateDays: "",
                feeRateTh: "",
                feeRateOther: "",
                lawyerSignature: "",
                lawyerName: "",
                rulesRegulationsjuristic: "",
                telOffice: "",
                telJuristic: "",
                language: "th",
            },
            settingInfoForm2: {
                projectName: "",
                documentType: "",
                dueDateDays: "",
                feeRateTh: "",
                feeRateOther: "",
                lawyerSignature: "",
                lawyerName: "",
                rulesRegulationsjuristic: "",
                telOffice: "",
                telJuristic: "",
                language: "th",
            },
            dateSelected: new Date(),
            documentType: "",
            uploadSignature: "",
            imageSrc: "",
            getAllSite: [],
            siteID: null,
            lawWording: "ข้อที่",
            lawWordingNo: ".......",
            lawWordingShow: "T",
        }

        this.onChangeSite = this.onChangeSite.bind(this);
        this.fetchData = this.fetchData.bind(this)
        this.getAllSite = this.getAllSite.bind(this)
        this.callSettingBySite = this.callSettingBySite.bind(this)
    }

    componentDidMount() {

        this.getAllSite()

        if (this.props.status === 'register') {
            this.setState({
                documentType: 'FM_CF_01',
            })
        } else if (this.props.status === 'issue') {
            this.setState({
                documentType: 'FM_CF_02',
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.status !== prevProps.status) {
            if (this.props.status === 'register') {
                this.setState({
                    documentType: 'FM_CF_01',
                })
            } else if (this.props.status === 'issue') {
                this.setState({
                    documentType: 'FM_CF_02',
                })
            }
        }
    }


    callSettingBySite() {
        this.fetchData()
    }

    getAllSite() {
        fetchQuery(environment, getqueryAllRegisterCollectionLawFirmSite()).then(data => {
            let data_clone = _.cloneDeep(data);

            let getAllSiteData = data_clone?.allRegisterCollectionLawFirmSite?.edges?.map((n) => (
                { ...n.node, value: n.node.site.id, label: n.node.site.project?.edges[0]?.node?.nameTh }))

            let optionSearch = [...getAllSiteData]

            this.setState({
                getAllSite: optionSearch,
            })
        }
        );
    }

    async fetchData() {

        let mySiteId = encode("SiteNode:" + this.state.siteID)

        await fetchQuery(environment, query, { siteId: mySiteId }).then((response) => {
            if (response.allCollectionLawFirmFromSetting.edges.length >= 1) {
                response.allCollectionLawFirmFromSetting.edges.forEach((form, index) => {
                    let data = form.node || "";
                    if (data.documentType === "FM_CF_01") {
                        let settingInfo = {
                            id: data.id,
                            documentType: data.documentType || "",
                            language: data.language || "th",
                            dueDateDays: data.dueDateDays || "",
                            rulesRegulationsjuristic: data.rulesRegulationsjuristic || "",
                            lawyerName: data.lawyerName || "",
                            lawyerSignature: data.lawyerSignature || "",
                            telOffice: data.telOffice || "",
                            telJuristic: data.telJuristic || "",
                            dateSelected: format(new Date(), "DD/MM/YYYY") || "",
                        };
                        this.setState({
                            settingInfoForm1: settingInfo,
                        });
                    } else if (data.documentType === "FM_CF_02") {
                        let settingInfo = {
                            id: data.id || "",
                            documentType: data.documentType || "",
                            language: data.language || "th",
                            dueDateDays: data.dueDateDays || "",
                            rulesRegulationsjuristic: data.rulesRegulationsjuristic || "",
                            lawyerName: data.lawyerName || "",
                            lawyerSignature: data.lawyerSignature || "",
                            telOffice: data.telOffice || "",
                            telJuristic: data.telJuristic || "",
                            dateSelected: format(new Date(), "DD/MM/YYYY") || "",
                            feeRateTh: data.feeRateTh,
                            feeRateOther: data.feeRateOther,
                            regulations: data.regulations || "ข้อที่",
                            regulationsStatus: data.regulationsStatus || true,
                            rulesRegulationsjuristic: data.rulesRegulationsjuristic || ""
                        };
                        this.setState({
                            settingInfoForm2: settingInfo,
                            lawWording: settingInfo.regulations,
                            lawWordingNo: settingInfo.rulesRegulationsjuristic,
                            lawWordingShow: settingInfo.regulationsStatus
                        });
                    }
                });
            }
        });
    }

    onChangeSite = (e) => {
        this.setState({
            siteID: e.value,
        }, () => this.fetchData())
    }

    changeFormLang(lang) {
        this.setState(prevState => ({
            settingInfo: {
                ...prevState.settingInfo,
                language: lang
            }
        }));
    }

    handleChange = (event) => {
        let e = _.cloneDeep(event)
        if (event.target.name === "settingInfoForm1.dateSelected") {
            let date = format(e.target.value, "DD/MM/YYYY")
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    e.target.name,
                    date
                )
            });
            this.setState({
                dateSelected: e.target.value
            })
        } else {
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    e.target.name,
                    e.target.value
                )
            });
        }
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/lawyer-signature" + path, file);
            resolve(res);
        });
    };

    handleInputFile = (e) => {
        let file = e.currentTarget.files[0];
        if (file) {
            if (file.size > 10485760) {
                Swal.fire(i18next.t("collectionLawFirm:Size is over"), i18next.t("collectionLawFirm:Please choose new"), 'warning');
                return;
            } else {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.setState({
                        imageSrc: e.target.result,
                        uploadSignature: [
                            {
                                node: {
                                    documentId: '',
                                    fileUpload: file,
                                    status: '',
                                    fileName: file.name,
                                },
                            },
                        ],
                    });
                };
                reader.readAsDataURL(file);
            }
        }
    }

    onSubmit = async (e) => {
        e.preventDefault()
        let uploadSignature = []
        if (this.state.uploadSignature.length > 0) {
            for (const file of this.state.uploadSignature) {
                if (file.node.documentId === "") {
                    await this.callUpload('', file.node.fileUpload).then((data) => {

                        uploadSignature.push({
                            documentId: "",
                            fileUpload: data.key.replace("silverman-storage/private/", ""),
                            status: "",
                            fileName: file.node.fileName
                        })
                    });
                }
            }
        }
        let input = "";

        if (!this.state.siteID) {
            Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("collectionLawFirm:Please choose site"), 'error')
            return
        }


        let split1 = this.state.settingInfoForm1 && this.state.settingInfoForm1?.lawyerSignature?.replace("https://silverman-storage.s3.amazonaws.com/silverman-storage/private/", "")
        let split2 = split1 && split1?.split("?AWSAccessKeyId")[0]


        // ชื่อ Variables มันเหมือนกัน ให้กำหนดไว้ 1 ทีแทน
        let documentType = this.state.documentType ? this.state.documentType : ""
        let id = ''
        let lawyerSignature = uploadSignature[0]?.fileUpload ? uploadSignature[0]?.fileUpload : split2 ? split2 : ""
        let lawyerName = this.state.settingInfoForm1.lawyerName ? this.state.settingInfoForm1.lawyerName : ""
        let language = 'th'
        let dueDateDays = 0
        let rulesRegulationsjuristic = ""
        let telOffice = ""
        let telJuristic = ""
        let feeRateTh = this.state.settingInfoForm2.feeRateTh
        let feeRateOther =  this.state.settingInfoForm2.feeRateOther
        let regulations = this.state.lawWording
        let regulationsStatus = this.state.lawWordingShow === "T" ? true : false


        // ถ้าเข้าเอกสารฉบับไหน ให้แก้แค่เอกสารนั้น และ set ค่า ไปในชื่อตัวแปรนั้นเลย
        if (this.state.documentType === "FM_CF_01") {
            id = this.state.settingInfoForm1.id
            language = this.state.settingInfoForm1.language
        }
        else if (this.state.documentType === "FM_CF_02") {
            id = this.state.settingInfoForm2.id
            language = this.state.settingInfoForm2.language
            dueDateDays = parseInt(this.state.settingInfoForm2.dueDateDays) || 0
            telOffice = this.state.settingInfoForm2.telOffice
            telJuristic = this.state.settingInfoForm2.telJuristic
            regulations = this.state.lawWording
            rulesRegulationsjuristic = parseInt(this.state.lawWordingNo)
            regulationsStatus = this.state.lawWordingShow === "T" ? true : false
        }

        // เรียกใช้และกำหนดไปใน input ค่าที่ส่งไป
        input = {
            "input": {
                "jsonObject": {
                    "id": id,
                    "documentType": documentType,
                    "dueDateDays": dueDateDays,
                    "lawyerSignature": decodeURIComponent(lawyerSignature),
                    "lawyerName": lawyerName,
                    "regulations": regulations,
                    "rulesRegulationsjuristic": rulesRegulationsjuristic,
                    "regulationsStatus": regulationsStatus,
                    "telOffice": telOffice,
                    "telJuristic": telJuristic,
                    "language": language,
                    "siteId": this.state.siteID,
                    "feeRateTh": feeRateTh,
                    "feeRateOther": feeRateOther,
                }
            }
        }

        //ส่งค่า input เข้า commitMutation เพื่ออัพเดท
        commitMutation(
            environment,
            {
                mutation,
                variables: input,
                onCompleted: (response) => {
                    this.setState({ loading: false });
                    if (response.createAndUpdateCollectionLawFirmFromSetting?.ok) {
                        Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                            this.setState({ loading: false }, () => {
                                window.location.reload()
                            });
                        });
                    } else {
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning').then(() => {
                            this.setState({ loading: false });
                        })
                    }
                },
                onError: (err) => {
                    this.setState({ loading: false });
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                },
            },
        )
    }


    onReset(e) {
        e.preventDefault();
        Swal.fire({
            title: i18next.t("collectionLawFirm:Do you want to reset all?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                if (this.state.documentType === "FM_CF_01") {
                    this.setState({
                        settingInfoForm1: {
                            projectName: "",
                            documentType: "",
                            dueDateDays: "",
                            feeRateTh: "",
                            feeRateOther: "",
                            lawyerSignature: "",
                            lawyerName: "",
                            rulesRegulationsjuristic: "",
                            telOffice: "",
                            telJuristic: "",
                            language: "th",
                        }
                    });
                } else if (this.state.documentType === "FM_CF_02") {
                    this.setState({
                        settingInfoForm2: {
                            projectName: "",
                            documentType: "",
                            dueDateDays: "",
                            feeRateTh: "0",
                            feeRateOther: "0",
                            lawyerSignature: "",
                            lawyerName: "",
                            rulesRegulationsjuristic: "",
                            telOffice: "",
                            telJuristic: "",
                            language: "th",
                        },
                        imageSrc: "",
                    });
                }
            }
        });
    }

    changeForm(documentType) {
        this.setState({
            documentType: documentType
        })
    }

    render() {
        return (
            <Translation>
                {
                    t =>
                        <React.Fragment>
                            {this.state.loadingData ?
                                <Loading />
                                :
                                <React.Fragment>
                                    <div className='row'>
                                        <div className='col-2'>
                                            {this.props.status === 'register' ?
                                                <button type="button"
                                                    className={this.state.documentType === 'FM_CF_01' ? 'blueBtn' : "greyBtn"}
                                                    onClick={() => this.changeForm('FM_CF_01')}>FM-CF-01</button>
                                                :
                                                <button type="button"
                                                    className={this.state.documentType === 'FM_CF_02' ? 'blueBtn mr-2' : "greyBtn mr-2"}
                                                    onClick={() => this.changeForm('FM_CF_02')}>FM-CF-02</button>
                                            }
                                        </div>
                                        <div className='col-4'>
                                            <Select
                                                id="siteID"
                                                name="siteID"
                                                className="select"
                                                options={this.state.getAllSite}
                                                // value={this.state.siteID}
                                                isSearchable={true}
                                                onChange={(e) => this.onChangeSite(e)}
                                                placeholder={t('project_info:Project name')}
                                                styles={customStyles}
                                            />
                                        </div>
                                        <div className='col-2'>
                                            <button type='button' onClick={this.callSettingBySite} className='btn btn-primary'>
                                                {t('FixRequestReport:Search')}
                                            </button>
                                        </div>
                                    </div>

                                    {
                                        this.state.siteID ?
                                            <div className="row" id="setting-collection-law">
                                                <div className="ml-2 wraperSettingInfo">
                                                    <h6> {t('collectionLawFirm:Appointing an attorney')} ({this.state.documentType})</h6>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <p> {t('collectionLawFirm:Document Language')}: </p>
                                                            <>
                                                                <button type="button"
                                                                    className={`${this.state.settingInfoForm1.language === "th" ? "blueBtn" : "lightGreyBtn"}`}
                                                                    onClick={() => this.changeFormLang('th')}>{t('collectionLawFirm:Thai')}</button>
                                                            </>
                                                        </div>
                                                    </div>
                                                    {this.state.documentType === "FM_CF_02" &&
                                                        <div className="row mt-4">
                                                            <div className="col-8">
                                                                <label>{t('collectionLawFirm:The number of days after the demand letter before the payment is due')}</label>
                                                                <div className="col-10 pl-0 d-flex flex-row align-items-center">
                                                                    <input className="form-controls" type="number" width={'50%'}
                                                                        onChange={this.handleChange}
                                                                        name="settingInfoForm2.dueDateDays"
                                                                        value={this.state.settingInfoForm2.dueDateDays}
                                                                        placeholder={t('newBookingSingha_Booking:Day')}
                                                                    />
                                                                    <span className='ml-2'>{t('newBookingSingha_Booking:Day')}</span>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                    {this.state.documentType === "FM_CF_01" &&
                                                        <>
                                                            <div className="row mt-4">
                                                                <div className="col-12">
                                                                    <h5>{t("collectionLawFirm:Signed by the authorized attorney")}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-4 pl-4">
                                                                    {this.state.uploadSignature ?
                                                                        <img
                                                                            src={this.state.imageSrc}
                                                                            className="rounded-circle avatar-img-juristic profile-img"
                                                                            alt="lawyerSignature" />
                                                                        :
                                                                        this.state.settingInfoForm1.lawyerSignature ?
                                                                            <img
                                                                                src={this.state.uploadSignature ? this.state.uploadSignature : this.state.settingInfoForm1.lawyerSignature}
                                                                                className="rounded-circle avatar-img-juristic profile-img"
                                                                                alt="lawyerSignature" />
                                                                            :
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + '/images/icons/signature-upload-icon.png'}
                                                                                className="rounded-circle avatar-img-juristic profile-img"
                                                                                alt="lawyerSignature" />
                                                                    }
                                                                </div>
                                                                <div className="col-8">
                                                                    <h6 className="mt-4">{t("premission:Signature")}</h6><small className="text-red">
                                                                        {t("premission:Attach files (JPG 10 MB)")}<br />
                                                                        {t("premission:The recommended image size is 180 pixels in height and 400 pixels in width")}
                                                                    </small>
                                                                    <div className="upload-file">
                                                                        <div>
                                                                            <label className="btn-link"
                                                                                htmlFor="lawyerSignature">{t("premission:Change picture")}</label>
                                                                        </div>
                                                                        <div className="upload-input">
                                                                            <input type="file"
                                                                                id="lawyerSignature"
                                                                                accept="application/pdf, image/*" value=""
                                                                                hidden={true}
                                                                                onChange={(e) => this.handleInputFile(e)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {this.state.documentType === "FM_CF_01" &&
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <label>{t("collectionLawFirm:Authorized Attorney Name")}</label>
                                                                <input className="form-controls" type="text" width={'100%'}
                                                                    placeholder={t("collectionLawFirm:Authorized Attorney Name")}
                                                                    onChange={this.handleChange}
                                                                    name="settingInfoForm1.lawyerName"
                                                                    value={this.state.settingInfoForm1.lawyerName}
                                                                />
                                                            </div>
                                                        </div>}
                                                    {this.state.documentType === "FM_CF_02" &&
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <label>{t("collectionLawFirm:Authorized Attorney Name")}</label>
                                                                <input className="form-controls" type="text" width={'100%'}
                                                                    placeholder={t("collectionLawFirm:Authorized Attorney Name")}
                                                                    onChange={this.handleChange}
                                                                    name="settingInfoForm2.lawyerName"
                                                                    disabled
                                                                    value={this.state.settingInfoForm1.lawyerName}
                                                                />
                                                            </div>
                                                        </div>}
                                                    {this.state.documentType === "FM_CF_02" &&
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <h5>{t("collectionLawFirm:Contact")}</h5>
                                                            </div>
                                                            <div className="col-12">
                                                                <label>{t("collectionLawFirm:Office Number")}</label>
                                                                <input className="form-controls" type="text" width={'100%'}
                                                                    placeholder={t("collectionLawFirm:Office Number")}
                                                                    onChange={this.handleChange}
                                                                    name="settingInfoForm2.telOffice"
                                                                    value={this.state.settingInfoForm2.telOffice}
                                                                />
                                                            </div>
                                                        </div>}
                                                    {this.state.documentType === "FM_CF_02" &&
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <label>{t("collectionLawFirm:Juristic Number")}</label>
                                                                <input className="form-controls" type="text" width={'100%'}
                                                                    placeholder={t("collectionLawFirm:Juristic Number")}
                                                                    onChange={this.handleChange}
                                                                    name="settingInfoForm2.telJuristic"
                                                                    value={this.state.settingInfoForm2.telJuristic}
                                                                />
                                                            </div>
                                                        </div>}
                                                    {this.state.documentType === "FM_CF_02" &&
                                                        <div className="row mt-6">
                                                            <div className="col-12">
                                                                <label>{t('collectionLawFirm:ระเบียบข้อบังคับนิติบุคคลอาคารชุดฯ')}</label>
                                                                <input className="form-controls" type="text"
                                                                    placeholder={t('collectionLawFirm:คำที่แสดง เช่น ข้อที่ หรือ ข้อบังคับที่ ฯลฯ')}
                                                                    onChange={(e) => this.setState({ lawWording: e.target.value })}
                                                                    name="settingInfoForm2.regulations"
                                                                    value={this.state.lawWording}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.documentType === "FM_CF_02" &&
                                                        <div className="row mt-6">
                                                            <div className="col-12">
                                                                <label>{t('collectionLawFirm:ข้อที่')}</label>
                                                                <input className="form-controls" type="text"
                                                                    placeholder={t('collectionLawFirm:According to Regulation Section/Article number')}
                                                                    onChange={(e) => this.setState({ lawWordingNo: e.target.value })}
                                                                    name="settingInfoForm2.rulesRegulationsjuristic"
                                                                    value={this.state.lawWordingNo}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.documentType === "FM_CF_02" &&
                                                        <div className="row mt-6">
                                                            <div className="col-12">
                                                                <label className='mr-3'>{t('collectionLawFirm:แสดงระเบียบข้อบังคับ')}</label>
                                                                <label htmlFor='disabledShowLawTrue' className='mr-3'>
                                                                    <input type="radio" id='disabledShowLaw' value={true} name="settingInfoForm2.rulesRegulationsShow" defaultChecked={this.state.lawWordingShow} onChange={(e) => this.setState({ lawWordingShow: true })} />
                                                                    <span> เปิด</span>
                                                                </label>

                                                                <label htmlFor='disabledShowLawFlase' className='mr-3'>
                                                                    <input type="radio" id='disabledShowLaw' value={false} name="settingInfoForm2.rulesRegulationsShow" defaultChecked={!this.state.lawWordingShow} onChange={(e) => this.setState({ lawWordingShow: false })} />
                                                                    <span> ปิด</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.documentType === "FM_CF_02" &&
                                                        <div className="row mt-6">
                                                            <div className="col-12">
                                                                <h5>{t("collectionLawFirm:ชำระค่าปรับ")}</h5>
                                                            </div>
                                                            <div className="col-12">
                                                                <label>{t('collectionLawFirm:ชำระค่าปรับคนไทย')}</label>
                                                                <input className="form-controls" type="text"
                                                                    placeholder={t('collectionLawFirm:จำนวนเงินที่ปรับคนไทย')}
                                                                    onChange={(e) => this.setState({ settingInfoForm2: _.set(_.cloneDeep(this.state.settingInfoForm2), 'feeRateTh', e.target.value) })}
                                                                    name="settingInfoForm2.feeRateTh"
                                                                    value={this.state.settingInfoForm2.feeRateTh}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.documentType === "FM_CF_02" &&
                                                        <div className="row mt-6">
                                                            <div className="col-12">
                                                                <label>{t('collectionLawFirm:ชำระค่าปรับคนต่างชาติ')}</label>
                                                                <input className="form-controls" type="text"
                                                                    placeholder={t('collectionLawFirm:จำนวนเงินที่ปรับต่างชาติ')}
                                                                    onChange={(e) => this.setState({ settingInfoForm2: _.set(_.cloneDeep(this.state.settingInfoForm2), 'feeRateOther', e.target.value) })}
                                                                    name="settingInfoForm2.feeRateOther"
                                                                    value={this.state.settingInfoForm2.feeRateOther}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="ml-2 wraperViewInfo">
                                                    <div className="col-md-5 input-form">
                                                        {this.state.documentType === 'FM_CF_01' &&
                                                            <SettingCollectionLetterLawViewForm1
                                                                settingInfo={this.state.settingInfoForm1}
                                                                newUploadSignature={this.state.imageSrc}
                                                            />}
                                                        {this.state.documentType === 'FM_CF_02' &&
                                                            <SettingCollectionLetterLawViewForm2
                                                                settingInfo={this.state.settingInfoForm2}
                                                                feeRateTh={parseFloat(this.state.settingInfoForm2.feeRateTh)}
                                                                feeRateOther={parseFloat(this.state.settingInfoForm2.feeRateOther)}
                                                                lawWording={this.state.lawWording}
                                                                lawWordingNo={this.state.lawWordingNo}
                                                                lawWordingShow={this.state.lawWordingShow}
                                                                lawyerSignature={this.state.settingInfoForm1.lawyerSignature}
                                                                lawyerName={this.state.settingInfoForm1.lawyerName}
                                                            />}
                                                    </div>
                                                    <div className='d-flex justify-content-end mt-4 submitBtnContainer'>
                                                        {/* <button type="button" className='lightGreyBtn mr-2 width250' onClick={(e)=>this.onCopy(e)}>คัดลอกข้อมูลจากระบบ Silverman</button> */}
                                                        <button type="button" className='greyBtn mr-2' onClick={(e) => this.onReset(e)}>
                                                            {t("collectionLawFirm:Reset All")}</button>
                                                        <button type="button" className='blueBtn' onClick={(e) => this.onSubmit(e)}>
                                                            {t("FixRequestModal:Save")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            ""
                                    }


                                </React.Fragment>
                            }
                        </React.Fragment>
                }
            </Translation>

        );
    }



} export default SettingForm;