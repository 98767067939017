/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateOtherReceiveAndOtherReceiveTransactionInput = {|
  contactList?: ?$ReadOnlyArray<?string>,
  invoice: any,
  payChannel: any,
  transactionList: any,
  assetId?: ?string,
  disappearAssetId?: ?string,
  payGroup?: ?string,
  clientMutationId?: ?string,
|};
export type otherReceiverMutationMutationVariables = {|
  input: CreateOtherReceiveAndOtherReceiveTransactionInput
|};
export type otherReceiverMutationMutationResponse = {|
  +createOtherReceiveAndOtherReceiveTransaction: ?{|
    +ok: ?boolean,
    +warningText: ?string,
    +clientMutationId: ?string,
    +newOr: ?{|
      +id: string
    |},
  |}
|};
export type otherReceiverMutationMutation = {|
  variables: otherReceiverMutationMutationVariables,
  response: otherReceiverMutationMutationResponse,
|};
*/


/*
mutation otherReceiverMutationMutation(
  $input: CreateOtherReceiveAndOtherReceiveTransactionInput!
) {
  createOtherReceiveAndOtherReceiveTransaction(input: $input) {
    ok
    warningText
    clientMutationId
    newOr {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateOtherReceiveAndOtherReceiveTransactionPayload",
    "kind": "LinkedField",
    "name": "createOtherReceiveAndOtherReceiveTransaction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OtherReceiveNode",
        "kind": "LinkedField",
        "name": "newOr",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "otherReceiverMutationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "otherReceiverMutationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a827495d6f84149cabbabd99b98410a0",
    "id": null,
    "metadata": {},
    "name": "otherReceiverMutationMutation",
    "operationKind": "mutation",
    "text": "mutation otherReceiverMutationMutation(\n  $input: CreateOtherReceiveAndOtherReceiveTransactionInput!\n) {\n  createOtherReceiveAndOtherReceiveTransaction(input: $input) {\n    ok\n    warningText\n    clientMutationId\n    newOr {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '092184fafd860548f9f01f7e1741cb8e';

module.exports = node;
