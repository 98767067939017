import React, { Component } from 'react'
import { format } from 'date-fns'
import _ from "lodash"
import numberWithComma from '../../libs/numberWithComma'
import thLocale from "date-fns/locale/th";
import "./budgetLastYearCompareWithYearPaper.scss"
import i18next from 'i18next';

export default class budgetLastYearCompareWithYear extends Component {

  constructor(props) {
    super(props)

    this.state = window.localStorage.getItem("stateLastYearCompareWithYear") ? JSON.parse(window.localStorage.getItem("stateLastYearCompareWithYear")) : {};
    this.state.bodyTable = []
  }

  componentWillMount() {
    if (this.props.state) {
      window.localStorage.setItem("stateLastYearCompareWithYear", JSON.stringify(this.props.state))
      this.setState(this.props.state)
    }
  }

  summaryRevenueYear = (codeValue) => {
    let result = 0;
    let sumRevenue_summaryYear_total_budget = 0;
    let sumRevenue_total_happend = 0;
    let sumRevenue_total_budget = 0;

    sumRevenue_summaryYear_total_budget = _.sumBy(this.state.query.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
    sumRevenue_total_happend = _.sumBy(this.state.query.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
    sumRevenue_total_budget = _.sumBy(this.state.query.lists[0]?.children, (o) => parseFloat(o.data.total_budget || 0))

    switch (codeValue) {
      case "4000-00 summaryYear_total_budget":
        result = sumRevenue_summaryYear_total_budget
        break;
      case "4000-00 total_happend":
        result = sumRevenue_total_happend
        break;
      case "4000-00 total_budget":
        result = sumRevenue_total_budget
        break;

      default:
        break;
    }
    return result;
  }

  summaryExpenseYear = (codeValue) => {
    let result = 0;
    let sumExpense_otherExpenses_summaryYear_total_budget = 0;
    let sumExpense_otherExpenses_total_happend = 0;
    let sumExpense_otherExpenses_total_budget = 0;

    let sumExpense_items_summaryYear_total_budget = 0;
    let sumExpense_items_total_happend = 0;
    let sumExpense_items_total_budget = 0;

    if (codeValue.substring(0, 7) === "5800-00") {
      _.forIn(this.state.query.lists[1].children[8].children, function (item) {
        sumExpense_otherExpenses_summaryYear_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_budget || 0))
        sumExpense_otherExpenses_total_happend += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_happend || 0))
        sumExpense_otherExpenses_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.total_budget || 0))
      })
    } else {
      if (codeValue.substring(0, 7) === "5100-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[0].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5200-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[1].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5300-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[2].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5310-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[3].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5400-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[4].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5500-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[5].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5600-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[6].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5700-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[7].children, (o) => parseFloat(o.data.total_budget || 0))
      }

    }

    switch (codeValue) {
      case "5800-00 summaryYear_total_budget":
        result = sumExpense_otherExpenses_summaryYear_total_budget
        break;
      case "5800-00 total_happend":
        result = sumExpense_otherExpenses_total_happend
        break;
      case "5800-00 total_budget":
        result = sumExpense_otherExpenses_total_budget
        break;
      case `${codeValue.substring(0, 7)} summaryYear_total_budget`:
        result = sumExpense_items_summaryYear_total_budget
        break;
      case `${codeValue.substring(0, 7)} total_happend`:
        result = sumExpense_items_total_happend
        break;
      case `${codeValue.substring(0, 7)} total_budget`:
        result = sumExpense_items_total_budget
        break;
    }
    return result;
  }

  checkMonth(monthInput) {
    let resultMonth = ""
    let monthFormat = format(new Date(monthInput), "MMM")
    let yearFormat = format(new Date(monthInput), "YY")

    switch (monthFormat) {
      case "Jan":
        resultMonth = "ม.ค."
        break;
      case "Feb":
        resultMonth = "ก.พ."
        break;
      case "Mar":
        resultMonth = "มี.ค."
        break;
      case "Apr":
        resultMonth = "เม.ย."
        break;
      case "May":
        resultMonth = "พ.ค."
        break;
      case "Jun":
        resultMonth = "มิ.ย."
        break;
      case "Jul":
        resultMonth = "ก.ค."
        break;
      case "Aug":
        resultMonth = "ส.ค."
        break;
      case "Sep":
        resultMonth = "ก.ย."
        break;
      case "Oct":
        resultMonth = "ต.ค."
        break;
      case "Nov":
        resultMonth = "พ.ย."
        break;
      case "Dec":
        resultMonth = "ธ.ค."
        break;
    }
    return resultMonth + yearFormat
  }

  componentDidMount() {
    this.setData()
  }

  setData() {

    let last_index = 0;
    let last_index_child = 0;
    let sumExpense_summaryYear_total_budget = 0;
    let sumExpense_total_happend = 0;
    let sumExpense_total_budget = 0;

    let data1 = []
    _.map(this.state.query.lists[0]?.children, (item, index) => {
      ++last_index;

      if (index == 0) {

        data1.push({
          no: "",
          name: "รายรับ",
          summaryYearTotalBudget: "",
          summaryYearTotalHappend: "",
          totalBudget: "",
        })
        data1.push({
          no: `${last_index}`,
          name: `${item.data.name}`,
          summaryYearTotalBudget: `${item.data?.summaryYear?.total_budget ? item.data.summaryYear.total_budget : ""}`,
          summaryYearTotalHappend: `${item.data?.summaryYear?.total_happend ? item.data?.summaryYear?.total_happend : ""}`,
          totalBudget: `${item.data?.total_budget ? item.data?.total_budget : ""}`,
        })

      } else if (index === (this.state.query.lists[0].children.length - 1)) {

        data1.push({
          no: `${last_index}`,
          name: `${item.data.name}`,
          summaryYearTotalBudget: `${item.data?.summaryYear?.total_budget ? item.data?.summaryYear?.total_budget : ""}`,
          summaryYearTotalHappend: `${item.data?.summaryYear?.total_happend ? item.data?.summaryYear?.total_happend : ""}`,
          totalBudget: `${item.data?.total_budget ? item.data?.total_budget : ""}`,
        })

        data1.push({
          no: "",
          name: "รวมรายรับ",
          summaryYearTotalBudget: `${this.summaryRevenueYear("4000-00 summaryYear_total_budget") !== 0 ? (this.summaryRevenueYear("4000-00 summaryYear_total_budget").toFixed(2)) : "-"}`,
          summaryYearTotalHappend: `${this.summaryRevenueYear("4000-00 total_happend") !== 0 ? (this.summaryRevenueYear("4000-00 total_happend").toFixed(2)) : "-"}`,
          totalBudget: `${this.summaryRevenueYear("4000-00 total_budget") !== 0 ? (this.summaryRevenueYear("4000-00 total_budget").toFixed(2)) : "-"}`,
        })
      } else {

        data1.push({
          no: `${last_index}`,
          name: `${item.data.name}`,
          summaryYearTotalBudget: `${item.data?.summaryYear?.total_budget ? (item.data?.summaryYear?.total_budget) : ""}`,
          summaryYearTotalHappend: `${item.data?.summaryYear?.total_happend ? (item.data?.summaryYear?.total_happend) : ""}`,
          totalBudget: `${item.data?.total_budget ? (item.data?.total_budget) : ""}`,
        })
      }
    })

    {_.map(this.state.query.lists[1]?.children, (item, index_code) => {

      sumExpense_summaryYear_total_budget += this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`)
      sumExpense_total_happend += this.summaryExpenseYear(`${item.data.code} total_happend`)
      sumExpense_total_budget += this.summaryExpenseYear(`${item.data.code} total_budget`)

      if(index_code === 0){
        data1.push({
          no: '', 
          name: 'รายจ่าย', 
          summaryYearTotalBudget: '', 
          summaryYearTotalHappend: '', 
          totalBudget: ''
        })
      }

      data1.push({
        no: '', 
        name: `${item.data.name}`, 
        summaryYearTotalBudget: '', 
        summaryYearTotalHappend: '', 
        totalBudget: ''
      })

      _.map(item.children, (item_child, index) => {
        if (index_code !== 8) { ++last_index }

        if(item_child.children?.length > 0){
          data1.push({
            no: '', 
            name: `${item_child.data.name}`, 
            summaryYearTotalBudget: '', 
            summaryYearTotalHappend: '', 
            totalBudget: ''
          })

          _.map(item_child?.children, (item_child_child, index_child_child) => {
            ++last_index_child

            data1.push({
              no: `${last_index + last_index_child}`, 
              name: `${item_child_child.data.name}`, 
              summaryYearTotalBudget: `${item_child_child.data?.summaryYear?.total_budget ? (item_child_child.data?.summaryYear?.total_budget) : ""}`, 
              summaryYearTotalHappend: `${item_child_child.data?.summaryYear?.total_happend ? (item_child_child.data?.summaryYear?.total_happend) : ""}`, 
              totalBudget: `${item_child_child.data?.total_budget ? (item_child_child.data?.total_budget) : ""}`, 
            })

          })
        }else{

          data1.push({
            no: `${last_index + last_index_child}`, 
            name: `${item_child.data.name}`, 
            summaryYearTotalBudget: `${item_child.data?.summaryYear?.total_budget ? (item_child.data?.summaryYear?.total_budget) : ""}`, 
            summaryYearTotalHappend: `${item_child.data?.summaryYear?.total_happend ? (item_child.data?.summaryYear?.total_happend) : ""}`, 
            totalBudget: `${item_child.data?.total_budget ? (item_child.data?.total_budget) : ""}`, 
          })
        }
      })

      data1.push({
        no: "", 
        name: `รวม${item.data.name}`, 
        summaryYearTotalBudget: `${this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`) !== 0 ? (this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`).toFixed(2)) : "-"}`, 
        summaryYearTotalHappend: `${this.summaryExpenseYear(`${item.data.code} total_happend`) !== 0  ? (this.summaryExpenseYear(`${item.data.code} total_happend`).toFixed(2)) : "-"}`, 
        totalBudget: `${this.summaryExpenseYear(`${item.data.code} total_budget`) !== 0 ? (this.summaryExpenseYear(`${item.data.code} total_budget`).toFixed(2)) : "-"}`, 
      })
    })}

    data1.push({
      no: "", 
      name: `รวมรายจ่าย`, 
      summaryYearTotalBudget: `${sumExpense_summaryYear_total_budget !== 0 ? (sumExpense_summaryYear_total_budget.toFixed(2)) : "-"}`, 
      summaryYearTotalHappend: `${sumExpense_total_happend !== 0 ? (sumExpense_total_happend.toFixed(2)) : "-"}`, 
      totalBudget: `${sumExpense_total_budget !== 0 ? (sumExpense_total_budget.toFixed(2)) : "-"}`, 
    })

    data1.push({
      no: "", 
      name: `รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด`, 
      summaryYearTotalBudget: `${(this.summaryRevenueYear("4000-00 summaryYear_total_budget") - sumExpense_summaryYear_total_budget) !== 0
          ? ((this.summaryRevenueYear("4000-00 summaryYear_total_budget") - sumExpense_summaryYear_total_budget).toFixed(2))
          : "-"}`, 
      summaryYearTotalHappend: `${(this.summaryRevenueYear("4000-00 total_happend") - sumExpense_total_happend) !== 0 
          ? ((this.summaryRevenueYear("4000-00 total_happend") - sumExpense_total_happend).toFixed(2)) 
          : "-"}`, 
      totalBudget: `${(this.summaryRevenueYear("4000-00 total_budget") - sumExpense_total_budget) !== 0 
          ? ((this.summaryRevenueYear("4000-00 total_budget") - sumExpense_total_budget).toFixed(2)) 
          : "-"}`
    })

    this.setState({ bodyTable: _.chunk(data1, 18) })
  }

  render() {

    let lastYear = new Date(this.state.query?.temp_year)
    lastYear = lastYear.getFullYear() - 1

    return (

      _.map(this.state.bodyTable, (valueList, index) => {
        return (
          <React.Fragment key={index}>
            <div className="print-page-a4" id="budgetLastYearCompareWithYear">
              <div className="subpage" >
                <div className="head">
                  <div className="headTextCenter text-center font-weight-bold">
                    <div>{this.props.selfProject.name}</div>
                    <div>{i18next.t("budgetDraftForm:Drafting a Budget")}</div>
                    <div>{i18next.t("budgetLastYearCompareWithMonthPaper:From Date")} {`${format(this.state.query.start_date, " D MMMM YYYY ", { locale: thLocale })}`} {i18next.t("budgetLastYearCompareWithMonthPaper:To")} {`${format(this.state.query.end_date, " D MMMM YYYY ", { locale: thLocale })}`}</div>
                  </div>
                </div>

                <div className="lastYearCompareWithYear-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th
                          rowSpan={2}
                          className="text-center"
                        >
                          {i18next.t("budgetLastYearCompareWithMonthPaper:Sequence")}
                        </th>
                        <th
                          rowSpan={2}
                          className="text-center"
                          style={{ width: 300 }}
                        >
                          {i18next.t("budgetLastYearCompareWithMonthPaper:Item")}
                        </th>
                        <th
                          colSpan={2}
                          className="text-center"
                        >
                          {i18next.t("budgetLastYearCompareWithMonthPaper:Annual Budget")} {lastYear}
                        </th>
                        <th rowSpan={2} className="text-center">
                        {i18next.t("budgetLastYearCompareWithMonthPaper:Annual Budget")} {format(this.state.query.temp_year, "YYYY")}<br />
                          {this.checkMonth(format(this.state.query.temp_start_date, "YYYY-MM-DD"))}-{this.checkMonth(format(this.state.query.temp_end_date, "YYYY-MM-DD"))}
                        </th>
                      </tr>
                      <tr className="border-top">
                        <th className="text-center" style={{ minWidth: 90 }}>{i18next.t("budgetLastYearCompareWithMonthPaper:Budget")}</th>
                        <th className="text-center" style={{ minWidth: 90 }}>{i18next.t("budgetLastYearCompareWithMonthPaper:Actual")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(valueList, (item, indexItem) => {
                        return (
                          <tr key={indexItem}>
                            <td className="text-center">{item.no}</td>
                            <td>{item.name}</td>
                            <td className="text-center">{ item.summaryYearTotalBudget ? numberWithComma(item.summaryYearTotalBudget) : ""}</td>
                            <td className="text-center">{ item.summaryYearTotalHappend ? numberWithComma(item.summaryYearTotalHappend) : ""}</td>
                            <td className="text-center">{ item.totalBudget ?  numberWithComma(item.totalBudget) : ""}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      })
    )
  }
}
