import { _ } from 'core-js'
import i18next from 'i18next'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import DatePickerNoti from '../../../finance/depositInterest/datePickerNoti'
import "../style/contractExpense.scss"

export default class contractDetails extends Component {
  render() {
    return (
      <div id="contractDetails">
        <div className="row">
          <div className="col-12">
            <h4>{i18next.t("updateFormContractExpense:Purchase Order")}</h4>
          </div>
        </div>

        <div className="row mt-5" id="formControlContract">
          <div className="col-2 customDatePickerWidth">
            <h6>{i18next.t("updateFormContractExpense:Start Date of Contract")}</h6>
            <DatePickerNoti
              disabled
              selected={this.props.startContractDate}
              />
          </div>
          <div className="col-2 customDatePickerWidth">
            <h6>{i18next.t("updateFormContractExpense:End Date of Contract")}</h6>
            <DatePickerNoti
              disabled
              selected={this.props.endContractDate}
              />
          </div>
          <div className="col-2">
            <h6>{i18next.t("updateFormContractExpense:Contract Duration (Months)")}</h6>
            <input 
              type="number"
              className="form-control"
              value={this.props.contractPeriod} 
              disabled
              />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-5">
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-light">
                  <tr>  
                    <th>{i18next.t("updateFormContractExpense:Item")}</th>
                    <th>{i18next.t("updateFormContractExpense:Description")}</th>
                    <th>{i18next.t("updateFormContractExpense:Quantity")}</th>
                    <th className="text-right">{i18next.t("updateFormContractExpense:Price/Unit (THB)")}</th>
                    <th className="text-center">{i18next.t("updateFormContractExpense:Budget (THB)")}</th>
                    <th className="text-center">{i18next.t("updateFormContractExpense:Value Added Tax (VAT)")}</th>
                    <th className="text-right">{i18next.t("updateFormContractExpense:Withholding Tax")}</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(this.props.procurementList, (item, index) => {
                    return (
                      <tr>
                        <td style={{minWidth: 240}}>
                          <input 
                            type="text" 
                            className="form-control" 
                            value={
                              item.node.productAndService 
                                ? `${item.node.productAndService.productCode} ${item.node.productAndService.name}`
                                : `${item.node.chartOfAccount.chartOfAccountCode} ${item.node.chartOfAccount.name}`
                            }
                            disabled
                            />
                        </td>
                        <td style={{minWidth: 240}}>
                          <input 
                            type="text" 
                            className="form-control" 
                            value={item.node.description}
                            disabled
                            />
                        </td>
                        <td>
                          <input 
                            type="number" 
                            className="form-control text-right" 
                            style={{width: 70}}
                            value={item.node.unitItems}
                            disabled
                            />
                        </td>
                        <td className="text-right" style={{minWidth: 120}}>
                          <input 
                            type="number" 
                            className="form-control text-right" 
                            value={item.node.price}
                            disabled
                            />
                        </td>
                        <td className="text-right" style={{minWidth: 80}}>
                          <input 
                            type="number" 
                            className="form-control text-right" 
                            value={item.node.budget}
                            disabled
                            />
                        </td>
                        <td className="text-center" style={{minWidth: 120}}>
                          <select
                            className="form-control"
                            value={
                              typeof(item.node.vat) === "number" 
                                ? item.node.vat
                                : ""
                            }
                            disabled
                            >
                            <option value="">{i18next.t("preFixName:None")}</option>
                            <option value="0">VAT 0%</option>
                            <option value="7">VAT 7%</option>
                          </select>
                        </td>
                        <td className="text-right" style={{minWidth: 120}}>
                          <select
                            className="form-control"
                            name={`purchaseRequisition.procurementFormList[${index}].wht_rate`}
                            disabled
                            value={
                              typeof(item.node.whtRate) === "number" 
                                ? item.node.whtRate
                                : ""
                            }
                            >
                            <option value="">{i18next.t("preFixName:None")}</option>
                            <option value="1">1%</option>
                            <option value="1.5">1.5%</option>
                            <option value="2">2%</option>
                            <option value="3">3%</option>
                            <option value="5">5%</option>
                            <option value="10">10%</option>
                            <option value="15">15%</option>
                          </select>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div className="lineBottom"/>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-4">
            <h5>{i18next.t("updateFormContractExpense:Attached Documents")}</h5>
            <div className="slide">
              {_.map(this.props.procurementFormContract, (item, index) => {
                let fileName = item.node.fileName
                let typeFileName = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length)

                return (
                  <div className="lineBottomAttach" key={index}>
                    <div className="item">
                      <div className="detail">
                        {typeFileName === "pdf"
                          ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} alt="pdf" />
                          : typeFileName === "png"
                            ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pngIcon.png"} alt="png" />
                            : typeFileName === "jpeg" && <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/jpgIcon.png"} alt="jpeg" />
                        }
                        <Link 
                          to="route"
                          onClick={(event) => { event.preventDefault(); 
                          window.open(item.node.fileUpload); }} 
                          target="blank">
                          {fileName.length >= 20 ? fileName.substr(0, 20) : fileName}
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      </div>
    )
  }
}
