/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SettingAssetTypeAsset = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type settingAssetFormQueryVariables = {||};
export type settingAssetFormQueryResponse = {|
  +allSettingAsset: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +typeAsset: SettingAssetTypeAsset,
        +depreciationRatePercent: ?number,
        +depreciationRate: ?number,
        +lifeTimeYear: ?number,
        +chartOfAccountType: {|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
          +nameEn: ?string,
        |},
        +chartOfAccountDepreciation: ?{|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
          +nameEn: ?string,
        |},
        +chartOfAccountDepreciationCumulative: ?{|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
          +nameEn: ?string,
        |},
      |}
    |}>
  |}
|};
export type settingAssetFormQuery = {|
  variables: settingAssetFormQueryVariables,
  response: settingAssetFormQueryResponse,
|};
*/


/*
query settingAssetFormQuery {
  allSettingAsset(settingAssetId: "", typeAsset: "") {
    edges {
      node {
        id
        typeAsset
        depreciationRatePercent
        depreciationRate
        lifeTimeYear
        chartOfAccountType {
          id
          chartOfAccountCode
          name
          nameEn
        }
        chartOfAccountDepreciation {
          id
          chartOfAccountCode
          name
          nameEn
        }
        chartOfAccountDepreciationCumulative {
          id
          chartOfAccountCode
          name
          nameEn
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "chartOfAccountCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nameEn",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "settingAssetId",
        "value": ""
      },
      {
        "kind": "Literal",
        "name": "typeAsset",
        "value": ""
      }
    ],
    "concreteType": "SettingAssetNodeConnection",
    "kind": "LinkedField",
    "name": "allSettingAsset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingAssetNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingAssetNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeAsset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "depreciationRatePercent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "depreciationRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lifeTimeYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "chartOfAccountType",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "chartOfAccountDepreciation",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "chartOfAccountDepreciationCumulative",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "allSettingAsset(settingAssetId:\"\",typeAsset:\"\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingAssetFormQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingAssetFormQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ae47b8f6ef5e28c1675368ad1b95e61b",
    "id": null,
    "metadata": {},
    "name": "settingAssetFormQuery",
    "operationKind": "query",
    "text": "query settingAssetFormQuery {\n  allSettingAsset(settingAssetId: \"\", typeAsset: \"\") {\n    edges {\n      node {\n        id\n        typeAsset\n        depreciationRatePercent\n        depreciationRate\n        lifeTimeYear\n        chartOfAccountType {\n          id\n          chartOfAccountCode\n          name\n          nameEn\n        }\n        chartOfAccountDepreciation {\n          id\n          chartOfAccountCode\n          name\n          nameEn\n        }\n        chartOfAccountDepreciationCumulative {\n          id\n          chartOfAccountCode\n          name\n          nameEn\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b6872a0a5d67c7b6fb978caa443fbf08';

module.exports = node;
