import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import _ from 'lodash';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts";
import getNameResidential from "../../../../libs/getNameResidential";
import numberWithCommas from '../../../../libs/numberWithComma';
import { fetchQuery } from 'relay-runtime';
import environment from '../../../../env/environment';
import Swal from 'sweetalert2';
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import GetAddressRole from '../../../../components/FucntionLib/getAddressRole';
import { graphql } from "babel-plugin-relay/macro";
const ThaiBaht = require('thai-baht-text');



pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNewBold.ttf',
    italics: 'THSarabunNewItalic.ttf',
    bolditalics: 'THSarabunNewBoldItalic.ttf'
  },
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Bold.ttf',
    italics: 'Sarabun-Italic.ttf',
    bolditalics: 'Sarabun-BoldItalic.ttf'
  }
}

const query = graphql`
    query receiveDetailPDFQuery($id_in: [String]!,$ref_in: [String]) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logoBase64
            juristicContactNumber
            keyProjectQr
            taxIdNumber
        }
        userConfig {
            id languages
        }
        receiveViewer {
            allReceive(id_In:$id_in, status_In: "paid") {
                edges {
                    node {
                        id
                        contact {
                            refNumber
                            name
                            taxIdNumber
                            firstName
                            lastName
                            phone
                            email
                            typeOfCompany
                            typeOfSupplier
                            typeOfContact
                            nameBranch
                            registeredAddress
                            registeredCountry
                            registeredProvince
                            registeredDistrict
                            registeredCity
                            registeredPostalCode
                            residential {
                                id
                                size
                                ownershipRatio
                            }
                        }
                        docNumber
                        issuedDate
                        creator
                        remark
                        total
                        
                        firstName
                        lastName
                        name
                        taxIdNumber
                        email
                        phone
                        
                        receiveTransaction {
                            totalCount
                            edges {
                                node {
                                    id
                                    amount
                                    discount
                                    transaction {
                                        invoice {
                                            docNumber
                                            taxType
                                            firstName
                                            lastName
                                        }
                                        id
                                        productAndService {
                                            productCode
                                        }
                                        description
                                        unitItems
                                        price
                                        whtRatePercent
                                        preTaxAmount
                                        discount
                                        vat
                                        whtRate
                                        total
                                        receiptDepositTransactionUsed {
                                            edges {
                                                node {
                                                    id
                                                    amount
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        creditNoteReceive{
                            totalCount
                            edges{
                                node{
                                    id
                                    docNumber
                                    description
                                    price
                                }
                            }
                        }
                        cashDepositReceiveTransaction {
                            edges {
                                node {
                                    price
                                }
                            }
                        }
                        chequeDeposit {
                            edges {
                                node {
                                    id
                                    date
                                    bankName
                                    chequeNumber
                                    branchNumber
                                    price
                                }
                            }
                        }
                        withheldTaxReceive {
                            edges {
                                node {
                                    total
                                }
                            }
                        }
                        bankAccountTransactionReceive {
                            edges {
                                node {
                                    id
                                    price
                                    date
                                    bankAccount {
                                        bankName
                                        accountNumber
                                    }
                                }
                            }
                        }
                        creditCardTransaction {
                            edges {
                                node {
                                    amount
                                }
                            }
                        }
                        smallChange{
                            edges{
                                node{
                                    id
                                    amount
                                }
                            }
                        }
                        feeAccounting{
                            edges{
                                node{
                                    id
                                    amount
                                }
                            }
                        }
                        paymentGatewayMethod{
                            edges{
                                node{
                                    id
                                    paymentType
                                    paymentGateway{
                                        id
                                        paymentType
                                    }
                                    amount
                                    feeAmount
                                    total
                                    depositDate
                                    image
                                }
                            }
                        }
                        
                    }
                }
            }
        }
        allAccountRecordGroup(ref_In: $ref_in) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ExportReceiveDetailPDF = ({ dataExport, selfProject, checkList, btnInDetail, batch, fromEmail, loading }) => {
  const getDataPerPage = (recordItem, IsOrigin, startPosition, breakPage, pageNow, pageAll) => {
    let {
      address, district, city, province, postalCode,
      prefixDistrict, prefixCity, prefixProvince
    } = GetAddressRole(recordItem.contact, recordItem.payGroup)

    let ddataDataailRecord = []
    let total = 0;
    _.map(recordItem.receiveTransaction.edges, (record, inx) => {
      total += record.node.amount
      ddataDataailRecord.push([
        { text: inx + 1, style: ['tabletd', 'center'], border: [true, false, true, false] },
        { text: `${record.node.transaction.invoice.docNumber}`, style: ['tabletd', 'center'], border: [true, false, true, false] },
        { text: `${record.node.transaction.description}`, style: ['tabletd', 'left'], border: [true, false, true, false] },
        { text: numberWithCommas(record.node.amount), style: ['tabletd', 'right'], border: [true, false, true, false] },
      ])

      if (inx + 1 === recordItem.receiveTransaction.edges.length) {
        for (let index = 0; index < (30 - recordItem.receiveTransaction.edges.length); index++) {
          ddataDataailRecord.push([
            { text: "", style: ['tabletd', 'center'], border: [true, false, true, false] },
            { text: "", style: ['tabletd', 'center'], border: [true, false, true, false] },
            { text: "", style: ['tabletd', 'left'], border: [true, false, true, false] },
            { text: "", style: ['tabletd', 'right'], border: [true, false, true, false] },
          ])

        }
      }
    })




    ddataDataailRecord.push([
      { text: 'รวม/Total', style: ['tabletd', 'center', 'bold'] },
      { text: `${ThaiBaht(total)}`, style: ['tabletd', 'center', 'bold'], colSpan: 2 },
      {},
      { text: numberWithCommas(total), style: ['tabletd', 'right', 'bold'] },
    ])

    let paymentMethod = recordItem.bankAccountTransactionReceive.edges.length > 0 ? "เงินโอน/Bank Transfer" :
      recordItem.cashDepositReceiveTransaction.edges.length ?
        "เงินสด" : "เช็ค";

    let paymentDetail = recordItem.bankAccountTransactionReceive?.edges?.length > 0 ? `${recordItem.bankAccountTransactionReceive.edges[0].node.bankAccount.bankName} เลขที่บัญชี ${recordItem.bankAccountTransactionReceive.edges[0].node.bankAccount.accountNumber} วันที่ ${format(new Date(recordItem.bankAccountTransactionReceive.edges[0].node.date), "DD/MM/YYYY")} จำนวน ${numberWithCommas(recordItem.bankAccountTransactionReceive.edges[0].node.price)}` : ""


    return [
      {
        image: `data:image/jpeg;base64,` + selfProject.logoBase64,
        width: 60,
        height: 60,
        absolutePosition: { x: 20, y: 30 }
      },
      { text: selfProject.name, style: ['bold', { fontSize: 9, }], absolutePosition: { x: 80, y: 20 } },
      { text: selfProject.address, style: { fontSize: 9, }, absolutePosition: { x: 80, y: 35 } },
      { text: 'โทร. ' + selfProject.juristicContactNumber, style: { fontSize: 9, }, absolutePosition: { x: 80, y: 50 } },
      { text: 'เลขประจำตัวผู้เสียภาษี ' + selfProject.taxIdNumber, style: { fontSize: 9, }, absolutePosition: { x: 80, y: 65 } },
      { text: `ใบเสร็จรับเงิน`, style: { fontSize: 12, bold: true, alignment: 'right' }, absolutePosition: { x: 0, y: 20 } },
      { text: `Receipt`, style: { fontSize: 12, bold: true, alignment: 'right' }, absolutePosition: { x: 0, y: 35 } },
      { text: `${IsOrigin}`, style: { fontSize: 9, bold: true, alignment: 'right' }, absolutePosition: { x: 20, y: 55 } },

      {
        table: {
          widths: [100, 200, 100, 100],
          heights: 12,
          body: [
            [
              { text: 'รหัสลูกค้า/ID:', style: { fontSize: 9, bold: true, alignment: 'left' }, height: 12, maxHeight: 12 },
              { text: `${recordItem.contact.refNumber}`, style: { fontSize: 9, bold: false, alignment: 'left' }, height: 12, maxHeight: 12 },
              { text: 'เลขที่/No.:', style: { fontSize: 9, bold: true, alignment: 'left' }, height: 12, maxHeight: 12 },
              { text: recordItem.docNumber, style: { fontSize: 9, bold: false, alignment: 'left' }, height: 12, maxHeight: 12 },
            ],
            [
              { text: 'ชื่อลูกค้า/Customer:', style: { fontSize: 9, bold: true, alignment: 'left' }, height: 12, maxHeight: 12 },
              { text: `${getNameResidential(recordItem.firstName, recordItem.lastName)}`, style: { fontSize: 9, bold: false, alignment: 'left' }, height: 12, maxHeight: 12 },
              { text: 'วันที่/Date:', style: { fontSize: 9, bold: true, alignment: 'left' }, height: 12, maxHeight: 12 },
              { text: `${format(new Date(recordItem.issuedDate), "DD/MM/YYYY")}`, style: { fontSize: 9, bold: false, alignment: 'left' }, height: 12, maxHeight: 12 },
            ],
            [
              { text: 'ที่อยู่/Address:', style: { fontSize: 9, bold: true, alignment: 'left' }, rowSpan: 2 },
              { text: `${address} ${prefixCity}${city} ${prefixDistrict}${district} ${prefixProvince}${province} ${postalCode}`, style: { fontSize: 9, bold: false, alignment: 'left' }, rowSpan: 2 },
              { text: 'เลขที่ห้องชุด/Room No.:', style: { fontSize: 9, bold: true, alignment: 'left' }, height: 12, maxHeight: 12 },
              { text: `${recordItem.contact.name}`, style: { fontSize: 9, bold: false, alignment: 'left' }, height: 12, maxHeight: 12 },
            ],
            [
              {},
              {},
              { text: 'อัตราRatio/พื้นที่Space:', style: { fontSize: 9, bold: true, alignment: 'left' }, height: 12, maxHeight: 12 },
              { text: `${recordItem.contact.residential?.ownershipRatio || "-"}/${numberWithCommas(recordItem.contact.residential?.size) || "-"}`, style: { fontSize: 9, bold: false, alignment: 'left' }, height: 12, maxHeight: 12 },
            ]
          ],
        },
        layout: 'noBorders',
        lineHeight: 1,
        absolutePosition: { x: 20, y: (startPosition + 60) },
      },

      // ตารางรายการ
      {
        // margin: [0, 150, 0, 0],
        table: {
          headerRows: 1,
          widths: [50, 70, 310, 90],
          dontBreakRows: true, // ใช้สำหรับ ให้แถวสุดท้ายที่ตกบรรทัดให้ ขึ้นหน้าใหม่แทน
          heights: 12,
          body: [
            [
              { text: 'ลำดับ\nNo.', style: ['th', 'center'] },
              { text: 'เลขใบแจ้งหนี้\nRef. No.', style: ['th', 'center'] },
              { text: 'รายการ\nDescription', style: ['th', 'center'] },
              { text: 'จำนวน\nAmount', style: ['th', 'center'] },
            ],
            ...ddataDataailRecord
          ],
        },
        absolutePosition: { x: 20, y: (startPosition + 125) },

      },

      {
        // margin: [0, 150, 0, 0],
        table: {
          headerRows: 1,
          widths: [550],
          dontBreakRows: true, // ใช้สำหรับ ให้แถวสุดท้ายที่ตกบรรทัดให้ ขึ้นหน้าใหม่แทน
          heights: 12,
          body: [
            [{ text: 'การชำระเงิน(Conditions of Payments)', style: { fontSize: 8, bold: true, alignment: 'left' } }],
            [{ text: `${paymentMethod}\n - ${paymentDetail}`, style: { fontSize: 8, bold: false, alignment: 'left' }, maxHeight: 58 },]
          ],
        },

        layout: 'noBorders',
        absolutePosition: { x: 20, y: (startPosition + 680) },
      },

      { text: `${pageNow}/${pageAll}`, style: { fontSize: 8, bold: false, alignment: 'center' }, absolutePosition: { x: 0, y: (startPosition + 770) } },
      { text: `.........................................................\nผู้รับเงิน/Receiver`, style: { fontSize: 8, bold: false, alignment: 'center' }, absolutePosition: { x: 200, y: (startPosition + 750) } },
      { text: `.........................................................\nผู้มีอำนาจลงนาม/Authorized`, style: { fontSize: 8, bold: false, alignment: 'center' }, absolutePosition: { x: 450, y: (startPosition + 750) }, pageBreak: breakPage },
    ]
  }

  const generatePdf = (data) => {
    try {
      let startItem = 0
      let dataMap = []
      _.map(data, (item, indexPageMain) => {
        let recordItem = item?.node ? item.node : item;
        startItem++

        let PageAll = 0
        PageAll = Math.ceil(parseFloat(recordItem.receiveTransaction.edges.length) / 30)

        if (recordItem.receiveTransaction.edges.length <= 30) {
          dataMap.push(getDataPerPage(recordItem, "(ต้นฉบับ/Original)", 35, "after", 1, PageAll))
          dataMap.push(getDataPerPage(recordItem, "(สำเนา/Copy)", 35, data.length === indexPageMain + 1 ? "" : "after", 1, PageAll))
        } else {

        }

        return dataMap;
      })

      const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [20, 20, 20, 20],
        content: [...dataMap],
        defaultStyle: {
          font: 'Sarabun'
        },
        styles: {
          address: {
            fontSize: 9,
            bold: true
          },
          th: {
            bold: true, fontSize: 8, verticalAlignment: 'middle',
          },
          mt10_mb10: {
            margin: [0, 10, 0, 10]
          },
          tabletd: {
            heihgt: 40, maxHeight: 40, fontSize: 8, verticalAlignment: 'middle',
          },
          bold: { bold: true },
          center: { alignment: 'center' },
          left: { alignment: 'left' },
          right: { alignment: 'right' },

        }
      };

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      if (fromEmail) {
        pdfDocGenerator.getBlob((blob) => {
          let preview = URL.createObjectURL(blob)
          window.open(preview, '_self')
        });
      } else {
        pdfDocGenerator.open()
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onPrint = () => {
    if (dataExport?.length > 0) {
      generatePdf(dataExport);
    } else {
      Swal.fire({
        imageUrl: '/images/iconAlerts/loading.gif',
        imageWidth: 150,
        showConfirmButton: false,
        titleText: i18next.t("Allaction:Preparing information"),
        timer: 9999999999,
        onOpen: () => { Swal.isLoading() },
        allowOutsideClick: () => !Swal.isLoading(),
        onRender: () => {
          fetchQuery(environment, query, {
            id_in: checkList.checkList,
            ref_in: checkList.checkListdoc,
          }).then((res) => {
            selfProject = res.selfProject
            generatePdf(res.receiveViewer.allReceive.edges)
            Swal.stopTimer()
            Swal.close()
          })
        }
      })
    }


  }

  useEffect(() => {
    if (fromEmail) {
      onPrint()
    }
  }, [])

  return (
    <Translation>
      {t =>
        <div className={batch ? "" : 'floatRight'}>
          <Link
            to="route"
            target="blank"
            onClick={(event) => {
              event.preventDefault();
              onPrint()
            }}>
            {
              btnInDetail ?
                <button className='btn h46 bg1567B4-textfff printBtn mr-2'>
                  {loading ?
                    <React.Fragment> <span className="spinner-border spinner-border-sm align-middle mr-2" /><span >{t("cheque:Preparing")}</span></React.Fragment> :
                    t("batchRt:Print Receipt")
                  }
                </button>
                :
                <button className='btn btn-outline-secondary m-1 w42 h42'>
                  <img src={process.env.PUBLIC_URL + '/images/icons/printIcon.png'} width={20} />
                </button>
            }

          </Link>

        </div>
      }
    </Translation>
  );


}

export default ExportReceiveDetailPDF;
