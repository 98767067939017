import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import localStorage from "../../../../libs/localstorage";
import {format} from "date-fns";
import Link from "react-router-dom/es/Link";
import i18next from "i18next";


class PurchaseReportTable extends React.Component {
    getStatus(object) {
        let text = '';
        switch (object.node.status) {
            case 'ACTIVE':
                text = 'ยังไม่จ่ายชำระ';
                break;
            case 'WAITING_PAY':
                text = 'รอจ่ายชำระ';
                break;
            case 'PAID':
                text = 'จ่ายชำระแล้ว';
                break;
            case 'VOID':
                text = 'รายการที่ยกเลิก';
                break;
            default:
                text = 'จ่ายชำระแล้วบางส่วน';
        }
        return text
    }

    render() {
        let order_id = this.props.state.first - localStorage.getPageLimit();
        let total = 0.0;
        return (
            <React.Fragment>
                {this.props.props.allPurchaseRecordGroup.edges.map((purchase_record_group, index) => {
                    let accumulate = 0;
                    let color_red = (purchase_record_group?.node?.status === 'VOID' ?{color:'red'}:null)
                    let status = (purchase_record_group?.node?.status === 'VOID' ?true:false)
                    return (
                        purchase_record_group.node.purchaseRecord.edges.map((transaction, t_index) => {
                            let ref_doc_number_list = []
                            let number_of_transaction = purchase_record_group.node.purchaseRecord.edges.length;
                            accumulate = accumulate + transaction.node.total;
                            if (purchase_record_group?.node?.status !== 'VOID') {
                                total += transaction.node.total;
                            }
                            let row_number = (t_index === 0 && order_id + index + 1);

                            if (transaction.node.acceptCreditNoteTransaction.edges.length !== 0) {
                                transaction.node.acceptCreditNoteTransaction.edges.forEach((accept_credit_note) => {
                                        ref_doc_number_list.push({
                                            type: 'accept_credit_note',
                                            id: accept_credit_note.node.id,
                                            doc_number: accept_credit_note.node.docNumber
                                        });
                                })
                            }
                            if (transaction.node.payRecord.edges.length !== 0) {
                                transaction.node.payRecord.edges.forEach((payRecord) => {
                                    ref_doc_number_list.push({
                                        type: 'pay_record',
                                        id: payRecord.node.payRecordGroup.id,
                                        doc_number: payRecord.node.payRecordGroup.docNumber
                                    });
                                })
                            }
                            if (transaction.node.prepaidDepositTransactionUsed.edges.length !== 0) {
                                transaction.node.prepaidDepositTransactionUsed.edges.forEach((pre_paid_deposit) => {
                                        ref_doc_number_list.push({
                                            type: 'prepaid_deposit',
                                            id: pre_paid_deposit.node.prepaidDeposit.id,
                                            doc_number: pre_paid_deposit.node.prepaidDeposit.docNumber
                                        });
                                    // }
                                })
                            }
                            return (
                                <tr key={transaction.node.id}>
                                    <td style={color_red} className="text-center">{row_number && index + order_id + 1}</td>
                                    <td style={color_red} className="text-center">
                                    {row_number&&!status?
                                        <Link
                                            style={color_red}
                                            to={"/accounting/expense/purchase/purchase-detail/" + purchase_record_group.node.id}
                                            target={'_blank'}>
                                            {purchase_record_group.node.docNumber}
                                        </Link>:
                                        purchase_record_group.node.docNumber
                                    }
                                    </td>
                                    <td style={color_red} className="text-center">{format(purchase_record_group.node.issuedDate, 'DD/MM/YYYY')}</td>
                                    <td style={color_red} className="text-center">{format(purchase_record_group.node.dueDate, 'DD/MM/YYYY')}</td>
                                    <td style={color_red} >{purchase_record_group.node.contact.refNumber}</td>
                                    <td style={color_red} >{purchase_record_group.node.contact.name}</td>
                                    <td style={color_red} >{transaction.node.description}</td>
                                    <td style={color_red} className="text-center">{this.getStatus(purchase_record_group)}</td>
                                    <td style={color_red} className="text-right">{purchase_record_group?.node?.status === 'VOID'?'-':numberWithComma(transaction.node.total, "-")}</td>
                                    {this.props.state.status === 'partial_payment'&&<>
                                    <td style={color_red} className="text-right">{purchase_record_group?.node?.status === 'VOID'?'-':numberWithComma(transaction.node.paid, "-")}</td>
                                    <td style={color_red} className="text-right">{purchase_record_group?.node?.status === 'VOID'?'-':numberWithComma(transaction.node.total - transaction.node.paid, "-")}</td>
                                    </>}
                                    <td style={color_red} className="text-right">{
                                        purchase_record_group?.node?.status === 'VOID'?'-':(t_index === number_of_transaction - 1 && numberWithComma(accumulate))
                                    }</td>
                                    {<td style={color_red} className="text-center" width={130}>
                                            {ref_doc_number_list.length > 0 ?
                                                ref_doc_number_list.map((ref_doc, index) =>
                                                    <span key={ref_doc.id}>
                                                        {ref_doc.type === 'prepaid_deposit' || ref_doc.type === 'accept_credit_note' ?
                                                            ref_doc.type === 'prepaid_deposit' ?
                                                                <Link
                                                                    to={"/accounting/expense/prepaid-deposit/view/" + ref_doc.id}
                                                                    target={'_blank'}>
                                                                    {ref_doc.doc_number}
                                                                </Link>
                                                                :
                                                                <Link
                                                                    to={"/accounting/expense/accept-credit-note/detail/" + ref_doc.id}
                                                                    target={'_blank'}>
                                                                    {ref_doc.doc_number}
                                                                </Link>

                                                            :
                                                            <Link
                                                                to={"/accounting/expense/pay/detail/" + ref_doc.id + "/" + ref_doc.doc_number}
                                                                target={'_blank'}>
                                                                {ref_doc.doc_number}
                                                            </Link>
                                                        }
                                                        {index + 1 < ref_doc_number_list.length && ', '}
                                                    </span>
                                                )
                                                : '-'
                                            }
                                        </td>
                                        }
                                </tr>
                            )
                        })
                    )
                })}
                {
                    (this.props.props.allPurchaseRecordGroup.pageInfo.hasNextPage || this.props.props.allPurchaseRecordGroup.pageInfo.hasPreviousPage) &&
                    <tr>
                        <td colSpan={10} className="text-right"><strong>{i18next.t("PurchaseReport:Sum")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(total)}</strong></td>
                    </tr>
                }

                {/* {!this.props.props.allPurchaseRecordGroup.pageInfo.hasNextPage && */}
                    <tr>
                    <td colSpan={this.props.state.status === 'partial_payment' ? 11 : 9} className="text-center"><strong>{i18next.t("FilterPayable:Total Number of Items")} {this.props.props.allPurchaseRecordGroup.totalCount} {i18next.t("PurchaseReport:Item")}</strong></td>
                        <td className="text-right"><strong>{i18next.t("PurchaseReport:Total")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(this.props.props.summaryPurchaseRecordGroup, "-")}</strong></td>
                    </tr>
                {/* } */}
            </React.Fragment>
        )
    }
}

export default PurchaseReportTable;
