import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import ReceiveList from './receiveList';
import ReceiveCreate from './receive_create/receiveCreate';
import ReceiveDetailQuery from './receive_detail/receiveDetailQuery';


class Receive extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={ReceiveList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status/:id?`} component={ReceiveCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id?/:ref_transaction?`} component={ReceiveDetailQuery}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default Receive;