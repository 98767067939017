/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApproveAssetHistoryApproveStatus = "APPROVE" | "NOT_APPROVE" | "%future added value";
export type AssetSaleStatus = "ACTIVE" | "DETERIORATE" | "DISAPPEAR" | "SOLD" | "%future added value";
export type AssetType = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type assetCheckingReportQueryVariables = {|
  type?: ?string,
  search?: ?string,
  first?: ?number,
  last?: ?number,
  order?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  approveDate_Gte?: ?any,
  approveDate_Lte?: ?any,
  approveHistoryReport?: ?boolean,
|};
export type assetCheckingReportQueryResponse = {|
  +allAsset: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +purchaseDate: ?any,
        +docNumber: string,
        +storage: ?string,
        +saleStatus: AssetSaleStatus,
        +nounClassifier: string,
        +seller: ?{|
          +firstName: string,
          +lastName: string,
          +name: string,
        |},
        +sellerOther: ?string,
        +chartOfAccountType: {|
          +chartOfAccountCode: string,
          +name: string,
        |},
        +name: string,
        +costPrice: ?number,
        +carcassPrice: ?number,
        +calDepreciation: boolean,
        +description: ?string,
        +historicalAmount: number,
        +depreciationRatePercent: ?number,
        +type: AssetType,
        +remark: string,
        +internalAssetCode: ?string,
        +assetImages: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +source: string
            |}
          |}>
        |},
        +approveAsset: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +approveStatus: ApproveAssetHistoryApproveStatus,
              +approver: ?string,
              +approveDate: ?any,
              +approveTime: ?any,
              +approveTerm: number,
              +costPrice: ?number,
              +remark: ?string,
            |}
          |}>
        |},
      |}
    |}>,
  |},
  +fixAssetDepreciate: ?boolean,
|};
export type assetCheckingReportQuery = {|
  variables: assetCheckingReportQueryVariables,
  response: assetCheckingReportQueryResponse,
|};
*/


/*
query assetCheckingReportQuery(
  $type: String
  $search: String
  $first: Int
  $last: Int
  $order: String
  $startDate: DateTime
  $endDate: DateTime
  $approveDate_Gte: Date
  $approveDate_Lte: Date
  $approveHistoryReport: Boolean
) {
  allAsset(type: $type, search: $search, first: $first, last: $last, order: $order, startDate: $startDate, endDate: $endDate, approveHistoryReport: $approveHistoryReport) {
    totalCount
    edges {
      node {
        id
        purchaseDate
        docNumber
        storage
        saleStatus
        nounClassifier
        seller {
          firstName
          lastName
          name
          id
        }
        sellerOther
        chartOfAccountType {
          chartOfAccountCode
          name
          id
        }
        name
        costPrice
        carcassPrice
        calDepreciation
        description
        historicalAmount
        depreciationRatePercent
        type
        remark
        internalAssetCode
        assetImages {
          edges {
            node {
              source
              id
            }
          }
        }
        approveAsset(approveDate_Gte: $approveDate_Gte, approveDate_Lte: $approveDate_Lte) {
          edges {
            node {
              id
              approveStatus
              approver
              approveDate
              approveTime
              approveTerm
              costPrice
              remark
            }
          }
        }
      }
    }
  }
  fixAssetDepreciate
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "approveDate_Gte"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "approveDate_Lte"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "approveHistoryReport"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v10 = [
  {
    "kind": "Variable",
    "name": "approveHistoryReport",
    "variableName": "approveHistoryReport"
  },
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purchaseDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storage",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saleStatus",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nounClassifier",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sellerOther",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costPrice",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carcassPrice",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calDepreciation",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "historicalAmount",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depreciationRatePercent",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalAssetCode",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "approveDate_Gte",
      "variableName": "approveDate_Gte"
    },
    {
      "kind": "Variable",
      "name": "approveDate_Lte",
      "variableName": "approveDate_Lte"
    }
  ],
  "concreteType": "ApproveAssetHistoryNodeConnection",
  "kind": "LinkedField",
  "name": "approveAsset",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ApproveAssetHistoryNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ApproveAssetHistoryNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v12/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approveStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approver",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approveDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approveTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approveTerm",
              "storageKey": null
            },
            (v23/*: any*/),
            (v30/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fixAssetDepreciate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "assetCheckingReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "AssetNodeConnection",
        "kind": "LinkedField",
        "name": "allAsset",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccountType",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetImageNodeConnection",
                    "kind": "LinkedField",
                    "name": "assetImages",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssetImageNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssetImageNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v32/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v33/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v34/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v9/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v8/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "assetCheckingReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "AssetNodeConnection",
        "kind": "LinkedField",
        "name": "allAsset",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccountType",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v20/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetImageNodeConnection",
                    "kind": "LinkedField",
                    "name": "assetImages",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssetImageNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssetImageNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v32/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v33/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v34/*: any*/)
    ]
  },
  "params": {
    "cacheID": "9cf8eaefb1ceaa91382a5c1571b11797",
    "id": null,
    "metadata": {},
    "name": "assetCheckingReportQuery",
    "operationKind": "query",
    "text": "query assetCheckingReportQuery(\n  $type: String\n  $search: String\n  $first: Int\n  $last: Int\n  $order: String\n  $startDate: DateTime\n  $endDate: DateTime\n  $approveDate_Gte: Date\n  $approveDate_Lte: Date\n  $approveHistoryReport: Boolean\n) {\n  allAsset(type: $type, search: $search, first: $first, last: $last, order: $order, startDate: $startDate, endDate: $endDate, approveHistoryReport: $approveHistoryReport) {\n    totalCount\n    edges {\n      node {\n        id\n        purchaseDate\n        docNumber\n        storage\n        saleStatus\n        nounClassifier\n        seller {\n          firstName\n          lastName\n          name\n          id\n        }\n        sellerOther\n        chartOfAccountType {\n          chartOfAccountCode\n          name\n          id\n        }\n        name\n        costPrice\n        carcassPrice\n        calDepreciation\n        description\n        historicalAmount\n        depreciationRatePercent\n        type\n        remark\n        internalAssetCode\n        assetImages {\n          edges {\n            node {\n              source\n              id\n            }\n          }\n        }\n        approveAsset(approveDate_Gte: $approveDate_Gte, approveDate_Lte: $approveDate_Lte) {\n          edges {\n            node {\n              id\n              approveStatus\n              approver\n              approveDate\n              approveTime\n              approveTerm\n              costPrice\n              remark\n            }\n          }\n        }\n      }\n    }\n  }\n  fixAssetDepreciate\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd4b0a8ae4ca741d2043cacc4b2d10e2';

module.exports = node;
