import React from 'react';
import { Translation } from 'react-i18next';
import Wrapper from '../../components/wrapper';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import {Link} from "react-router-dom";
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import Navigation from "./navigation"
import DatePickerAdapter from '../../libs/datePickerAdapter';
import SearchProject from '../searchProject';
import Pagination from '../../libs/newPagination';
import ComponentPagination from '../../libs/componentPagination';
import {format} from 'date-fns';
import {QueryRenderer ,commitMutation} from 'react-relay';
import environment from '../../env/environment';
import Loading from "../../libs/loading"
import getQueryPost from './graphQL/postRegulation';
import getQueryCancel from './graphQL/cancleRegulation';
import { graphql } from "babel-plugin-relay/macro";
import Swal from 'sweetalert2';
import _ from 'lodash'
import i18n from '../../i18n';
import i18next from 'i18next';
import {fetchQuery} from "relay-runtime";

const mutation = graphql`
mutation regulationListMutation ($input: DeleteRegulationInput!) {
    deleteRegulation (input: $input) {
        ok
        message
    }
}
`


class RegulationList extends ComponentPagination{
    constructor(props){
        super(props);
        this.state = {
            status : this.props.match.params.status,
            order : '-date_posted',
            startDate : null,
            endDate : null,
            first: 10,
            last: 10,
            search : '',
            deleteList : [],
            checkAll : false,
            redirect : false,
            allData: [],
            loading: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.deleteList = this.deleteList.bind(this);
        this.appendToDeleteList = this.appendToDeleteList.bind(this);
        this.checkAll = this.checkAll.bind(this);        
    }
    
    getData(){
        this.setState({
            loading: true
        })
        let variables = {
            search: this.state.search,
            startdate: this.state.startDate,
            enddate: this.state.endDate,
            first: this.state.first,
            last: this.state.last,
            order: this.state.order,
        };

        let typeQuery = this.state.status === 'effective' ? getQueryPost() : getQueryCancel();
            
        fetchQuery(
            environment, 
            typeQuery,
            variables,
        ).then((res) => {
            this.setState({
                allData: this.state.status === 'effective' ? res.postRegulation : res.cancelRegulation,
                loading: false
            });
        });
    }

    componentWillMount(){
        this.getData()
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.status !== this.props.match.params.status || prevState.first !== this.state.first || prevState.last !== this.state.last
            || prevState.order !== this.state.order 
            ){
            this.setState({
                status : this.props.match.params.status,
                first: this.state.first,
                last: this.state.last,
                order: this.state.order,
            },() => this.getData())
        }
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSearch(){
        this.getData()
    }

    deleteList(id){
        return this.state.deleteList.includes(id);
    }

    appendToDeleteList(id){
        let newDeleteList = [...this.state.deleteList];
        if(this.deleteList(id)){
            let index = _.indexOf(newDeleteList,id)
            newDeleteList.splice(index,1)
        }else{
            newDeleteList.push(id)
        }
        this.setState({
            deleteList : newDeleteList
        })
    }

    checkAll(props){
        if(this.state.checkAll){
            this.setState({
                deleteList : [],
                checkAll : false
            })
        }else{
            let newDeleteList = []
            _.forEach(props,(transaction) =>{
                newDeleteList.push(transaction.node.id)
            })
            this.setState({
                checkAll : true,
                deleteList : newDeleteList
            })
        }
    }

    onDelete(){
        let variables = {
            input: {
                id: this.state.deleteList,
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.deleteRegulation.ok) {
                        Swal.fire(i18n.t('press_release:Removed successfully'), '', 'success').then(() => {
                            this.setState({redirect: true});
                        });
                    } else {
                        Swal.fire('ลบไม่สำเร็จ!', i18next.t("Allaction:Please check again"), 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                },
            },
        )
    }

    render(){
        return (
            <Wrapper>
                <Header />
                <Sidebar/>
                <div id="wrapper-content">
                    <ProjectTopMenuNavigation mini={true}/>
                    <Translation>
                        {t=>
                            <div className="container-fluid box" id="projectDashboard">
                                <div className="row">
                                    <div className="col-md-5 col-xl-5">
                                        <h3 className="mb-4">
                                            <Link to="/project">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <span>{t('regulation:Regulation / Rule of resident')}</span>
                                        </h3>
                                    </div>
                                    <div className="col float-right ">
                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_regulation_create' }) &&
                                            <Link to="/project/regulation/form/create">
                                                <button type="button" className="add btn btn-primary float-right">
                                                    <span>+ {t('regulation:Add Regulation / New Rule of resident')}</span>
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                <div className="content-inner">
                                    <Navigation status={this.state.status}/>
                                    <div className="row mt-2">
                                        <div className="col-2">
                                            {this.state.status !== "cancel" && (
                                                <div className="btn-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-light dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    {t("regulation:Manage multiple")}
                                                </button>
                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_regulation_delete' }) &&
                                                    <div className="dropdown-menu">
                                                        <span
                                                            className="dropdown-item approve-dropdown cursor" onClick ={() => this.onDelete()}
                                                        >
                                                            {this.state.loading && (
                                                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                                            )}
                                                            {t("press_release:remove_list")}
                                                        </span>
                                                    </div>
                                                }
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-7">
                                            <div className="form-inline float-right">
                                                <div className="form-group mb-2">
                                                <label htmlFor="startDate" className="ml-3 mr-3">
                                                        {t("regulation:Sort by date")}
                                                </label>
                                                <DatePickerAdapter
                                                    id="startDate"
                                                    className="form-control"
                                                    name="startDate"
                                                    selected={this.state.startDate}
                                                    onChange={this.handleChange}
                                                />
                                                </div>
                                                <div className="form-group mb-2">
                                                <label htmlFor="endDate" className="ml-3 mr-3">
                                                        {t("regulation:to")}
                                                </label>
                                                <DatePickerAdapter
                                                    id="endDate"
                                                    name="endDate"
                                                    className="form-control"
                                                    selected={this.state.endDate}
                                                    onChange={this.handleChange}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group float-right w-auto ml-2 mb-2">
                                            <Translation>
                                                {t =>
                                                <input
                                                    type="text"
                                                    className="form-control input-size col-8"
                                                    placeholder={t('filterFunction:search')}
                                                    value={this.state.search}
                                                    name="search"
                                                    onChange={(e) =>
                                                    this.setState({ search: e.target.value })
                                                    }
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                                }
                                            </Translation>

                                            <button
                                                type="submit"
                                                className="btn btn-primary form-control search-button text-center"
                                                name="search"
                                                value={this.state.search}
                                                onClick={(e) => this.handleSearch(e)}
                                            >
                                                <Translation>{t => t('filterFunction:search')}</Translation>
                                            </button>

                                            </div>
                                    </div>
                                    <div className="mt-3">
                                    <React.Fragment>
                                        <div className="table-responsive">
                                            {
                                                this.state.loading ?
                                                <Loading />:                                            
                                                <table className="table table-hover">
                                                    <thead className="thead-light ">
                                                        <tr>
                                                            {this.state.status !== "cancel" && (
                                                                <th width="50">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="checkAll"
                                                                            className="custom-control-input"
                                                                            onChange={() =>this.checkAll(this.state.allData.edges)}
                                                                            checked={this.state.checkAll}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="checkAll"/>
                                                                    </div>
                                                                </th>
                                                            )}
                                                                                <th className="text-left" width="600" >{t("regulation:Subject Description")}</th>
                                                                                <th className="text-center " >{t("regulation:Post By")}</th>
                                                                                <th className="text-center">
                                                                                    <span onClick={() => this.setState({ order: this.state.order === "date_posted"  ? "-date_posted" : "date_posted" })}
                                                                                        className="cursor" >
        
                                                                                        {t("regulation:Date Posted")}
                                                                                        {"-date_posted" === this.state.order ? (
                                                                                            <img
                                                                                            src={
                                                                                                process.env.PUBLIC_URL +
                                                                                                "/images/icons/sort-down.png"
                                                                                            }
                                                                                            alt="sort-down-icon"
                                                                                            className="ml-1"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                            src={
                                                                                                process.env.PUBLIC_URL +
                                                                                                "/images/icons/sort-up.png"
                                                                                            }
                                                                                            alt="sort-up-icon"
                                                                                            className="ml-1"
                                                                                            />
                                                                                        )}
                                                                                    </span>
                                                                                </th>
                                                                                <th className="text-center">{t('regulation:Date from set time')}</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        
                                                                        <tbody>
                                                                            {
                                                                                this.state.allData && this.state.allData?.edges?.length > 0 &&
                                                                                this.state.allData.edges.map(((transaction,index) => {
                                                                                    let description = null;

                                                                                    if (transaction.node.description ){
                                                                                        if (transaction.node.description.length >= 80) {
                                                                                            description = transaction.node.description.replaceAll(/<[^>]+>/g, "") .substring(0, 80) + "...";
                                                                                            description = description.replaceAll( "&nbsp;", " " );
                                                                                        } else {
                                                                                            description = transaction.node.description.replaceAll(/<[^>]+>/g,"");
                                                                                            description = description.replaceAll("&nbsp;"," ");
                                                                                        }
                                                                                    }
                                                                                    return(
                                                                                        <tr key={transaction.node.id}>
                                                                                            {this.state.status !== "cancel" && (
                                                                                                <td>
                                                                                                    <div className="custom-control custom-checkbox">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id={transaction.node.id}
                                                                                                        className="custom-control-input"
                                                                                                        onChange={() =>
                                                                                                            this.appendToDeleteList(
                                                                                                                transaction.node.id
                                                                                                            )
                                                                                                        }
                                                                                                        checked={this.deleteList(transaction.node.id)}
                                                                                                    />
                                                                                                    <label
                                                                                                        className="custom-control-label"
                                                                                                        htmlFor={transaction.node.id}
                                                                                                    />
                                                                                                    </div>
                                                                                                </td>
                                                                                            )}
                                                                                            <td>
                                                                                                {transaction.node.status === 'draft' ? 
                                                                                                    <Link to={  "/project/regulation/form/edit/"+this.state.status + "/" + transaction.node.id }>
                                                                                                        <h6 className="blue-color">
                                                                                                            {transaction.node.subject}
                                                                                                            <span className="small ml-2 color red-color">{t("press_release:draft")}</span>
                                                                                                        </h6>
                                                                                                    </Link>
                                                                                                :
                                                                                                    <Link to={  "/project/regulation/form/edit/" +this.state.status + "/" + transaction.node.id }>
                                                                                                        <h6 className="blue-color">
                                                                                                            {transaction.node.subject}   
                                                                                                            {(new Date(transaction.node.dateSchedule) > new Date()) && <img src={process.env.PUBLIC_URL + '/images/icons/clock.png'} alt="clock-icon" width="25" height="25" style={{marginLeft:'10px'}}/>}  
                                                                                                        </h6>
                                                                                                    </Link>
                                                                                                }
                                                                                                <p className="small color gray-color">{description}</p>
                                                                                            </td>
                                                                                            <td className="text-center">{transaction.node.creator}</td>
                                                                                            <td className="text-center">{format(transaction.node.datePosted , 'DD/MM/YYYY')}</td>
                                                                                            <td className="text-center">{transaction.node.dateSchedule && format(transaction.node.dateSchedule, 'DD/MM/YYYY HH:mm')}</td>
                                                                                            <td>
                                                                                            <Link to={  "/project/regulation/form/edit/" +this.state.status + "/" + transaction.node.id }>
                                                                                                <img src={process.env.PUBLIC_URL +"/images/icons/edit.png"}
                                                                                                alt="edit-icon" className="cursor"/>
                                                                                            </Link>
                                                                                            </td>
            
                                                                                        </tr>
            
                                                                                    );

                                                                                }))
                                                                            }                                       
                                                                        </tbody>
                                                </table>
                                            }
                                        </div>
                                        <div className="row">
                                            <Pagination
                                                changePage={this.changePage}
                                                first={this.state.first}
                                                last={this.state.last}
                                                totalCount={this.state.allData.totalCount}
                                            />
                                        </div>
                                    </React.Fragment>                                        
                                    </div>


                                   
                                </div>
                            </div>
                            
                        }
                    </Translation>
                </div>
            </Wrapper>
            
        );
    }

}
export default RegulationList;