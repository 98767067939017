import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import InvoiceDetail from "./invoiceDetail";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import Wrapper from "../../../../components/wrapper";

const invoiceDetail = graphql`
    query invoiceDetailQuery($id: ID!){
        invoice(id: $id) {
            id
            docNumber
            issuedDate
            dueDate
            taxType
            status
            
            address
            country
            province
            city
            district
            postalCode
            payGroup
            firstName
            lastName
            email
            phone

            taxIdNumber
            
            contractRevenue{
                id
                docNumber
            }
            
            contact {
                name
                refNumber
                firstName
                lastName
                registeredName
                registeredAddress
                registeredCountry
                registeredProvince
                registeredCity
                registeredDistrict
                registeredPostalCode
                typeOfContact

                residential{
                    residentialHouseholder(type:"renter"){
                        edges{
                            node{
                                id
                                tenant{
                                    nameTitle
                                    firstName
                                    lastName
                                    phone
                                    email
                            
                                }
                            }
                        }
                    }
                }

                receiptDeposit {
                    edges {
                        node {
                            id
                            receiptDepositTransaction {
                                edges {
                                    node {
                                        id
                                        price
                                        balance
                                        couple
                                        chartOfAccount {
                                            id
                                            name
                                            chartOfAccountCode
                                        }
                                        receiptDepositTransactionUsed {
                                            edges {
                                                node {
                                                    id
                                                    amount
                                                    coupleUse
                                                    transaction{
                                                        invoice{
                                                            id
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
            remark
            transaction {
                edges {
                    node {
                        id
                        productAndService{
                            id
                            productCode
                            chartOfAccount{
                                id
                                chartOfAccountCode
                            }
                        }
                        chartOfAccount {
                            id
                            chartOfAccountCode
                            name
                        }
                        description
                        unitItems
                        price
                        discount
                        vat
                        preTaxAmount
                        whtRate
                        whtRatePercent
                        keycardPerInvoice{
                            edges{
                                node {
                                    id
                                    numberKey{
                                        id
                                        noNumber
                                        status
                                    }
                                }
                            }
                        }
                        receiptDepositTransactionUsed{
                            edges{
                                node{
                                    id
                                    amount
                                    coupleUse
                                    receiptDepositTransaction{
                                        description
                                        couple
                                        chartOfAccount{
                                            name
                                            chartOfAccountCode
                                        }
                                        receiptDeposit{
                                            docNumber
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

class InvoiceDetailQuery extends Component {
    render() {
        return (
            <Wrapper>
            <Header/>
            <Sidebar/>
            <QueryRenderer
                environment={environment}
                query={invoiceDetail}
                cacheConfig={{use_cache: false}}
                variables={{"id": this.props.match.params.id}}
                render={({error, props}) => {
                    if (error) {
                        return <div>Error :(</div>;
                    } else if (props) {
                        return <InvoiceDetail query={invoiceDetail}
                                              invoiceQuery={props}
                                              contact={props.invoice.contact}
                                              transactions={props.invoice.transaction.edges}
                        />
                    }
                }}
            />
            </Wrapper>
        )
    }
}

export default InvoiceDetailQuery;
