import React, {Component} from "react";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../env/environment";
import {QueryRenderer} from "react-relay";
import SearchSelect from "../libs/searchSelect";
import _ from "lodash";
import {Translation} from "react-i18next";
import Loading from "../libs/loading"

const query = graphql`
    query selectGlobalBankQuery{
        allGlobalBankAccount(status: "active"){
            edges{
                node{
                    name
                }
            }
        }
    }
`;

class SelectGlobalBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankName: ""
        };
        this.selectBank = this.selectBank.bind(this)

    }

    selectBank(e) {
        let value = e.target.value;
        this.setState({[e.target.name]: value});
        if (value === "อื่นๆ") {
            value = ""
        }

        let event = {
            target: {
                name: this.props.name,
                value: value
            }
        };
        this.props.handleInputChange(event);
    }

    render() {
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="col">
                            <p>{t('selectGlobalBank:Bank')}</p>
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                render={({error, props}) => {
                                    if (error) {
                                        return (
                                            <div className="alert alert-danger"
                                                role="alert">{error.message}</div>
                                        );
                                    } else if (props) {
        
                                        let list_bank = _.cloneDeep(props.allGlobalBankAccount.edges);
                                        let other = {
                                            node: {
                                                name: "อื่นๆ"
                                            }
                                        };
                                        list_bank.push(other);
        
                                        return <SearchSelect onChange={this.selectBank}
                                                            value={this.state.bankName}
                                                            name="bankName"
                                                            placeholder="ธนาคาร"
                                                            queryObject={list_bank}
                                                            keyOfValue="name" require={true}
                                                            keyOfLabel="name"/>
                                    }
                                    return <Loading/>;
                                }}
                            />
                        </div>
        
                        {
                            this.state.bankName === 'อื่นๆ' &&
                            <div className="col align-self-end">
                                <input type="text" name={this.props.name} className="form-control"
                                    id="bankName" value={this.props.value}
                                    onChange={this.props.handleInputChange}
                                    placeholder="ระบุธนาคาร"
                                    required
                                />
                            </div>
                        }
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default SelectGlobalBank