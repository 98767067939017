/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChequeTransactionStatus = "BIDE" | "CANCEL" | "CLEARING" | "DELETE" | "VOID" | "VOID_HISTORICAL" | "WAIT" | "%future added value";
export type wrapperChequeTransactionPrintQueryVariables = {|
  id_In?: ?$ReadOnlyArray<?string>
|};
export type wrapperChequeTransactionPrintQueryResponse = {|
  +chequeTransactionViewer: ?{|
    +allChequeTransaction: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +date: any,
          +chequeDate: ?any,
          +payName: ?string,
          +description: ?string,
          +price: number,
          +chequeNumber: string,
          +status: ChequeTransactionStatus,
          +refNumber: ?string,
          +docNumber: ?string,
          +bankAccount: ?{|
            +docNumber: string,
            +branch: string,
            +accountNumber: string,
            +accountName: string,
            +bankName: string,
          |},
          +depositDate: ?any,
        |}
      |}>,
    |}
  |}
|};
export type wrapperChequeTransactionPrintQuery = {|
  variables: wrapperChequeTransactionPrintQueryVariables,
  response: wrapperChequeTransactionPrintQueryResponse,
|};
*/


/*
query wrapperChequeTransactionPrintQuery(
  $id_In: [String]
) {
  chequeTransactionViewer {
    allChequeTransaction(id_In: $id_In) {
      totalCount
      edges {
        node {
          id
          date
          chequeDate
          payName
          description
          price
          chequeNumber
          status
          refNumber
          docNumber
          bankAccount {
            docNumber
            branch
            accountNumber
            accountName
            bankName
            id
          }
          depositDate
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id_In"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id_In",
    "variableName": "id_In"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chequeDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chequeNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "branch",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depositDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperChequeTransactionPrintQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChequeTransactionViewer",
        "kind": "LinkedField",
        "name": "chequeTransactionViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ChequeTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "allChequeTransaction",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ChequeTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChequeTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wrapperChequeTransactionPrintQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChequeTransactionViewer",
        "kind": "LinkedField",
        "name": "chequeTransactionViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ChequeTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "allChequeTransaction",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ChequeTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChequeTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "211509ad3702118deb2b407ed1bfa8fd",
    "id": null,
    "metadata": {},
    "name": "wrapperChequeTransactionPrintQuery",
    "operationKind": "query",
    "text": "query wrapperChequeTransactionPrintQuery(\n  $id_In: [String]\n) {\n  chequeTransactionViewer {\n    allChequeTransaction(id_In: $id_In) {\n      totalCount\n      edges {\n        node {\n          id\n          date\n          chequeDate\n          payName\n          description\n          price\n          chequeNumber\n          status\n          refNumber\n          docNumber\n          bankAccount {\n            docNumber\n            branch\n            accountNumber\n            accountName\n            bankName\n            id\n          }\n          depositDate\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '57a72da03a9dc4fdb1decea9e64ae661';

module.exports = node;
