import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import ComponentPagination from "../../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Loading from "../../../libs/loading";
import { format } from "date-fns";
import { Button } from "../../../components/Button/Button";
import Pagination from "../../../libs/newPagination";
import localstorage from "../../../libs/localstorage";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import i18next from "i18next";
import Swal from "sweetalert2";
import { commitMutation } from "react-relay";
import Navigation from "./navigation";

const query = graphql`
  query budgetAllListQuery($status: String, $first: Int, $last: Int) {
    allYearBudget (status: $status, first: $first, last: $last ) {
      edges {
        node {
          id
          years
          startDate
          endDate
          creator
          status
          project {
            name
          }
        }
      }
      totalCount
    }
  }
`;
const mutation = graphql`
    mutation budgetAllListMutation($input:CancelBudgetInput!){
        cancelBudget(input:$input) 
        {
            ok
            message
        }       
    }
`;

class BudGetList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      all_budget: [],
      first: this.state.first,
      last: this.state.last,
      totalCount: 10,
      status: "all",
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.first !== this.state.first || prevState.last !== this.state.last || prevProps.match.params.status !== this.props.match.params.status) {
      this.setState({
        status: this.props.match.params.status
      },()=>{
        this.getData()
      })
    }
  }

  async componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.setState({ loading: true })
    fetchQuery(environment, query, {status: this.state.status, first: this.state.first, last: this.state.last }).then((data) => {
      let data_clone = _.cloneDeep(data);
      this.setState({
        all_budget: data_clone.allYearBudget.edges,
        totalCount: data_clone.allYearBudget.totalCount,
        loading: false,
      });
    });

  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleCancelBudget = (budgetId, budgetYear) => {    
    let variables = {
      input :{
        id: budgetId
      }
    }
    Swal.fire({
      type: 'warning',
      title: `ต้องการยกเลิกงบประมาณปี ${budgetYear} ไหม`,            
      showCancelButton: true,
      confirmButtonText: i18next.t("Allaction:yes"),
      cancelButtonText: i18next.t("Allaction:cancel")
    }).then((result) => {
      if (result.value) {
        commitMutation(
          environment,
          {
              mutation,
              variables,
              onCompleted: (response) => {
                  if(response.cancelBudget.ok) {
                    Swal.fire(i18next.t("Allaction:Saved Successfully"), "", "success").then(() => {
                      window.location.reload()
                    })
                  } else {
                    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
                      this.setState({ redirectTo: '/accounting/budget/list/all' })
                    })
                  }
  
              },
              onError: (err) => {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
                  this.setState({ redirectTo: '/accounting/budget/list/all' })
                })
              },
          },
        )
      }
    });

  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
        <AccountingTopMenuNavigation mini={true}/>
          <div className="container-fluid box booking-form p-4 ">
            <div className="row justify-content-start ">
              <div className="col">
                <h3>
                  <Link to="/accounting/budget/">
                    <img src={process.env.PUBLIC_URL + "/images/wrapperContent/back.png"} alt="back" className="back" />
                  </Link>
                  <Translation>{(t) => <span className="header">{i18next.t("menuListBudget:Record Budget Setting")}</span>}</Translation>
                </h3>
              </div>
            </div>
            <div className="row justify-content-end mt-3 mb-5  ">
              <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_create'}) && 
                <Link to="/accounting/budget/create/">
                  <Button primary className="float-right">
                    + {i18next.t("menuListBudget:Record Budget Setting")}
                  </Button>
                </Link>
                }
              </div>
            </div>
            {/* <React.Fragment> */}
            {!this.state.loading ? (
              <><Navigation />
              <React.Fragment>
                <div className="card fade-up mt-4 " id="parcel">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <React.Fragment>
                        <thead>
                          <Translation>
                            {t=>
                              <tr>
                                <th className="text-center">{i18next.t("budgetAll:Sequence")}</th>
                                <th className="text-center">{i18next.t("budgetAll:Corporate name")}</th>
                                <th className="text-center">{i18next.t("budgetAll:Budgeting year")}</th>
                                <th className="text-center">{i18next.t("budgetAll:Budgeting year start date")}</th>
                                <th className="text-center">{i18next.t("budgetAll:Budgeting year end date")}</th>
                                <th className="text-center"></th>
                                <th className="text-left">{i18next.t("budgetDraftForm:Status")}</th>
                                <th className="text-center"></th>
                              </tr>
                            }
                          </Translation>
                          
                        </thead>

                        <tbody>
                          {_.map(this.state.all_budget, (budget, index) => {
                            let order_id = this.state.first - localstorage.getPageLimit();
                            return (<React.Fragment key={index}>
                              <tr className="show">
                                <td className="text-center">{order_id + index + 1}</td>
                                <td className="text-center">{budget.node.project?.name}</td>
                                <td className="text-center">{budget.node.years}</td>
                                <td className="text-center">{format(budget.node.startDate, "DD/MM/YYYY")}</td>
                                <td className="text-center">{format(budget.node.endDate, "DD/MM/YYYY")}</td>
                                <td className="text-center">
                                  {budget.node.status !== "CANCEL" && 
                                  <Link to={`/accounting/budget/edit/${budget.node.id}/${budget.node.years}`}>
                                    <img src={process.env.PUBLIC_URL + "/images/icons/icon_edit.png"} alt="edit" className="edit" />
                                  </Link>}
                                </td>
                                <td>{i18next.t(`budgetAll:${budget.node.status.toLowerCase()}`)}</td>
                                <td>
                                {budget.node.status !== "CANCEL" && 
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={()=>this.handleCancelBudget(budget.node.id, budget.node.years)}
                                  >
                                    <img
                                    src={process.env.PUBLIC_URL + '/images/icons/deleteIconRed.svg'}
                                    alt="deleteRed" className="btn-delete cursor"
                                    width="20px" height="20px"
                                    />                 
                                    <span className='ml-2'>{i18next.t("Allaction:Delete")}</span>
                                  </button>}
                                </td>
                              </tr>
                            </React.Fragment>)
                          })}
                        </tbody>
                      </React.Fragment>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <Pagination
                    changePage={this.changePage}
                    first={this.state.first}
                    last={this.state.last}
                    totalCount={this.state.totalCount}
                  />
                </div>
              </React.Fragment>
              </>
            ) : (
              <Loading />
            )}
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default BudGetList;
