
const getWSRoot = () => {
    let protocol = '';
    let host = window.location.hostname;
    let host_array = host.split('.');
    let project_name = host_array[0].split('-')[0];
    host_array.shift();

     if(window.location.protocol === 'http:') {
        protocol = 'ws://'
    } else {
        protocol = 'wss://'
    }
    return protocol + project_name + '.' + host_array.join('.') + '/ws';
};


export default getWSRoot;