import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../../env/environment";

const mutation = graphql`
  mutation ChartOfAccountUpdateMutation($input: ChartOfAccountUpdateInput!) {
    chartOfAccountUpdate(input: $input) {
      ok
      warningText
      chartOfAccount {
        id
        name
        nameEn
        nameTh
        chartOfAccountCode
        accountRecord(typeOfRecord: "historical") {
          edges {
            node {
              id
              debit
              credit
            }
          }
        }
      }
    }
  }
`;

function commit(input, uploadables, callback, error_callback) {
  const variables = {
    input: input,
  };

  commitMutation(environment, {
    mutation,
    variables,
    uploadables,
    onCompleted: (response) => {
      callback(response);
    },
    onError: (err) => {
      error_callback(err);
    },
  });
}

export default { commit };
