import {commitMutation} from "react-relay";
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import ReadClickAdvertise from '../../components/header/readClickAdvertise';

const mutation = graphql`
  mutation clickAdvertiseMutation($input: ClickAdvertiseInput!)  {
    clickAdvertise (input: $input) {
        ok   
    }
  }
`;

function clickAd(id){
    try {
        let variables = {
            input: {
                advertiseId: id,
                types: 1
            },
        };
        ReadClickAdvertise(
            variables,
            () => console.log('ads click success!'),
            () => console.log('ads click fail!')
        );
    } catch (error) {
        console.log('click ads error', error)
    }
}

const clickAdvertise = (id) => {
    let variables = {
        input : {
            id : id
        }
    }
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
            },
            onError: (err) => {
                console.log(err);
            },
        },
    )
    clickAd(id)
}

export default clickAdvertise;