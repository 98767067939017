import React from "react";
import _ from "lodash";
import numberWithComma from "../../libs/numberWithComma"


class RowRenderBringForward extends React.Component {

    constructor(props) {
        super(props);
        let chart_code = ["1000-00" , "2000-00" , "3000-00" , "4000-00"  , "5000-00"]; //,"9999-00"
        this.state = {
            shutter: {},
            edit_list: [],
            chart_code : chart_code,
    };
        this.clickOpen = this.clickOpen.bind(this);
    }

    isCode(code){
        if(_.indexOf(this.state.chart_code, code) >= 0) {
            return true;
        }
        return false;
    }

    componentWillMount() {
        let chartShutter = {};
        this.props.chartTree.map((chart) => {
            return _.set(chartShutter, chart.data.code, this.props.open);
        });
        this.setState({shutter: chartShutter})
    }

    clickOpen(code) {
        let chartShutter = _.cloneDeep(this.state.shutter);
        _.set(chartShutter, code, !_.get(chartShutter, code));
        this.setState({shutter: chartShutter})
    }

    getNumberPattern(number){
        // let number_pattern = parseFloat(number).toFixed(2)
        // number_pattern = numberWithComma(number_pattern)
        return 0
    }

    render() {
        return (
            <React.Fragment>
                {this.props.chartTree.map((chart) => {
                    return (
                        <React.Fragment key={chart.data.code}>
                        <tr className={this.props.level === 0 ? "head" : "sub fade-up"}
                            onClick={() => {if (chart.data.code.slice(-2) === "00") {this.clickOpen(chart.data.code)}}}>
                            <td>{chart.data.code}</td>
                            <td>
                                {Array(this.props.level).fill().map((value, index) => {
                                    return <span className="partition" key={chart.data.code+'-'+index}>
                                        |&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                })}
                                {chart.data.code.slice(-2) === "00" &&
                                <strong>
                                    {chart.data.name}
                                </strong>
                                }
                                {chart.data.code.slice(-2) !== "00" &&
                                <span> 
                                    {/* <Link to={"/accounting/chart-of-account/form/"+chart.data.id}> */}
                                    {chart.data.name} {chart.data.nameEn ? " / "+chart.data.nameEn : ""}
                                    {/* </Link> */}
                                </span>
                                }
                            </td>
                            <td className="text-center">
                                {chart.data.code.slice(-2) === "00" &&
                                    <span>✓</span>
                                }
                            </td>
                            <td>
                                {chart.data.category}
                                {this.props.level === 0 &&
                                <span className="ml-1 small">
                                    ( {_.get(this.props.chartCount, chart.data.code.charAt(0), 0)} )
                                </span>
                                }
                            </td>
                                {/* //debit */}
                            <td className="text-right">
                                {this.isCode(chart.data.code) && 
                                    <span className="ml-1"> { numberWithComma(_.get(this.props.totalHistoricalDebit, chart.data.code.charAt(0), 0),0) } </span>
                                }
                                {chart.data.code.slice(-2) !== "00" && 
                                    <div style ={{display:'flex' , justifyContent : 'flex-end'}}>
                                        <input  type = "number" 
                                                className="form-control text-right"
                                                style = {{width:120}}
                                                placeholder = {chart.data.totalHistoricalDebit? numberWithComma(chart.data.totalHistoricalDebit):""}
                                                onChange = {(e)=>this.props.handleChange(chart.data?.id,e.target.value,'totalHistoricalDebit')}
                                                pattern="[0-9]*"
                                                id = {'debit' + chart.data?.id} 
                                                // value = {chart.data.totalHistoricalDebit? chart.data.totalHistoricalDebit :  parseFloat(0).toFixed(2)}
                                                name = 'debit'
                                                disabled = {chart.data.totalHistoricalCredit > 0 ?true:false}
                                  
                                        />
                                    </div>
                                }
                            </td>
                                {/* //credit */}
                            <td className="text-right" >
                                {chart.data.code.slice(-2) !== "00" && 
                                    <div style ={{display:'flex' , justifyContent : 'flex-end'}}>
                                        <input  type="number" 
                                                className="form-control text-right"
                                                style = {{width:120}}
                                                // value = {chart.data.totalHistoricalCredit?chart.data.totalHistoricalCredit:parseFloat(0).toFixed(2)}
                                                placeholder = {chart.data.totalHistoricalCredit? numberWithComma(chart.data.totalHistoricalCredit):""}
                                                onChange = {(e)=>this.props.handleChange(chart.data?.id,e.target.value,'totalHistoricalCredit')}
                                                pattern="[0-9]*" 
                                                id = {'credit' + chart.data?.id}
                                                name = 'credit'
                                                disabled = {chart.data.totalHistoricalDebit > 0 ?true:false}
                                    
                                        />
                                    </div>
                                }
                                {this.isCode(chart.data.code) && 
                                    <span className="ml-1"> { numberWithComma(_.get(this.props.totalHistoricalCredit, chart.data.code.charAt(0), 0),0) } </span>
                                }
                            </td>
                        </tr>
                        {this.state.shutter[chart.data.code] && _.get(chart, 'children') &&
                            <RowRenderBringForward chartTree={chart['children']} child={true} level={this.props.level+1} open={this.state.shutter[chart.data.code]} handleChange={this.props.handleChange} />
                        }
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        )
    }
}

export default RowRenderBringForward;