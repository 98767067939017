import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import "./fixRequestDashBroad.scss";
import i18next from 'i18next';

class FixRequestTechnician extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      loading: false,
      checkOnline: true,
    };
  }

  componentDidMount() {
    this.genTechnicianJobChart();
    this.genTechnicianTimeChart();
  }

  genTechnicianJobChart() {
    if (this.root) {
      this.root.dispose();
      this.chart.dispose();
    }

    let root = am5.Root.new("fixRequestTechJobChart");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 40,
        paddingLeft: 30,
      })
    );

    chart.children.unshift(
        am5.Label.new(root, {
          text: `${i18next.t("fix_request:Number of repairs completed by person")} (${i18next.t("fix_request_dashbroad:Job")})`,
          fontSize: 16,
          fontWeight: 600,
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 40,
        })
      );

    // Add legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );
  
    let data = [this.props.dataFixProjectStaff];
    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "date",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.labels.template.set('visible', false)
    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    // Add series
    function makeSeries(name, fieldName) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "date",
        })
      );

      series.columns.template.setAll({
        tooltipText: `{name}: {valueY} ${i18next.t("fix_request_dashbroad:Job")}`,
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.data.setAll(data);

      // Make stuff animate on load
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }

    for (const key in data[0]) {
      if (key !== 'date') {
        if(key === 'other'){
            data[0][key] >0 && makeSeries('อื่นๆ', key);
        } else {
            data[0][key] >0 && makeSeries(key, key);
        }
      }
    }
    
    const excelData = []; 
    // ใช้สำหรับแปลง data ออกexcel
    for (const key in data[0]) {
      if (key !== 'date') {
        excelData.push({
          nameOfTechnician: key,
          totalWorkDone: data[0][key],
        });
      }
    }
    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: excelData,
      numericFields: ["จำนวนงานที่ทำเสร็จ"],
      numberFormat: "#,###",
      textFields: {
          text: {
            field: "รายชื่อช่าง",
          }
        },
      dataFields: {
        nameOfTechnician: "รายชื่อช่าง",
        totalWorkDone: "จำนวนงานที่ทำเสร็จ",
      },
      dataFieldsOrder: ["nameOfTechnician","totalWorkDone"],
    });
    let annotator = am5plugins_exporting.Annotator.new(root, {});

    exporting.get("menu").set("items", [
      {
        type: "format",
        format: "jpg",
        label: "Export Image",
      },
      {
        type: "format",
        format: "print",
        label: "Print",
      },
      {
        type: "format",
        format: "xlsx",
        label: "Export Excel",
      },
      {
        type: "separator"
      },
      {
        type: "custom",
        label: "Remark",
        callback: function () {
          this.close();
          annotator.toggle();
        },
      },
    ]);

    chart.appear(1000, 100);
    root._logo.dispose();

    this.chart = chart;
    this.root = root;
  }
  genTechnicianTimeChart() {
    if (this.root2) {
      this.root2.dispose();
      this.chart.dispose();
    }

    let root2 = am5.Root.new("fixRequestTechTimeChart");

    root2.setThemes([am5themes_Animated.new(root2)]);

    let chart = root2.container.children.push(
      am5xy.XYChart.new(root2, {
        panX: false,
        panY: false,
        layout: root2.verticalLayout,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 40,
        paddingLeft: 30,
      })
    );

    chart.children.unshift(
        am5.Label.new(root2, {
          text: `${i18next.t("fix_request:Average technician job completion")} (${i18next.t("fix_request:Hours")})`,
          fontSize: 16,
          fontWeight: 600,
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 40,
        })
      );

    // Add legend
    let legend = chart.children.push(
      am5.Legend.new(root2, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );
  
    let data = [this.props.dataFixProjectStaffTime];
    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root2, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root2, {
        categoryField: "date",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root2, {}),
      })
    );

    xRenderer.labels.template.set('visible', false)
    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root2, {
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root2, {
          strokeOpacity: 0.1,
        }),
      })
    );

    // Add series
    function makeSeries(name, fieldName) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root2, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "date",
        })
      );

      series.columns.template.setAll({
        tooltipText: `{name}: {valueY.formatNumber('#,###.00')} ชั่วโมง`,
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.data.setAll(data);

      // Make stuff animate on load
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root2, {
          locationY: 0,
          sprite: am5.Label.new(root2, {
            text: "{valueY}",
            fill: root2.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }

    for (const key in data[0]) {
      if (key !== 'date') {
        if(key === 'other'){
            data[0][key] >0 && makeSeries('อื่นๆ', key);
        } else {
            data[0][key] >0 && makeSeries(key, key);
        }
      }
    }
    
    const excelData = []; 
    // ใช้สำหรับแปลง data ออกexcel
    for (const key in data[0]) {
      if (key !== 'date') {
        excelData.push({
          nameOfTechnician: key,
          totalWorkDone: data[0][key],
        });
      }
    }

    let exporting = am5plugins_exporting.Exporting.new(root2, {
      menu: am5plugins_exporting.ExportingMenu.new(root2, {}),
      dataSource: excelData,
      numericFields: ["จำนวนชั่วโมงเฉลี่ยนที่ทำงานเสร็จ (ชั่วโมง)"],
      numberFormat: "#,###",
      textFields: {
          text: {
            field: "รายชื่อช่าง",
          }
        },
      dataFields: {
        nameOfTechnician: "รายชื่อช่าง",
        totalWorkDone: "จำนวนชั่วโมงเฉลี่ยนที่ทำงานเสร็จ (ชั่วโมง)",
      },
      dataFieldsOrder: ["nameOfTechnician","totalWorkDone"],
    });
    let annotator = am5plugins_exporting.Annotator.new(root2, {});

    exporting.get("menu").set("items", [
      {
        type: "format",
        format: "jpg",
        label: "Export Image",
      },
      {
        type: "format",
        format: "print",
        label: "Print",
      },
      {
        type: "format",
        format: "xlsx",
        label: "Export Excel",
      },
      {
        type: "separator"
      },
      {
        type: "custom",
        label: "Remark",
        callback: function () {
          this.close();
          annotator.toggle();
        },
      },
    ]);

    chart.appear(1000, 100);
    root2._logo.dispose();

    this.chart = chart;
    this.root2 = root2;
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
    if (this.root2) {
      this.root2.dispose();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="fixRequestTechChartContainer">
            <div className="divContainer">
                <div id="fixRequestTechJobChart"></div>
            </div>
            <div className="divContainer">
                <div id="fixRequestTechTimeChart"></div>
            </div>
        </div>
       
      </React.Fragment>
    );
  }
}

export default FixRequestTechnician;
