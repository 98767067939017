import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import CollectionLetter from './collectionLetter'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import _ from 'lodash';
import OutStandingCollectionLetter from './OutstandingCollectionLetter';
import Loading from "../../libs/loading";
import WrapperCollectionLetterOldTime from './wrapperCollectionLetterOldTime';

const query = graphql`
    query wrapperCollectionAllQuery($collectionLettersId: [String] , $formDebit: Int) {
        selfProject{
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            bankCompCode
            bankServiceCode
            taxIdNumber
            typeOfProject
            typeOfCompany
            establishmentStatus
        }
        userConfig {
            id languages
        }
        allSettingAutomaticDebit{
            edges{
                node{
                    receivableOutstanding
                }
            }
        }
        allSettingFormDebit(formDebit: $formDebit) {
            edges{
                node{
                    id
                    description
                    descriptionEng
                    descriptionSecond
                    descriptionSecondEng
                    paymentCheck
                    paymentTimeStatus
                    paymentTime1
                    paymentTime2
                    bankAccountStatus
                    numberOfDays
                    bankAccount{
                        id
                        accountNumber
                        bankName
                        accountName
                        branch
                    }
                    channelDescription
                    contactName
                    contactPhone
                    creatorName
                    creatorNameEng
                    signerName
                    signerPosition
                    formDebit
                    refDocument
                    refReport
                    refCollection1
                    refCollection2
                    refCollection3
                }
            }
        }
        allCollectionLetters(collectionLettersId:$collectionLettersId){
            edges{
                node{
                    id
                    numberOfTimes
                    refDocNumber
                    docNumber
                    added
                    issuedDate
                    creator
                    receivableOutstanding
                    debtStatus
                    status
                    updated
                    sumTotal
                    contact {
                        id
                        name
                        refNumber
                        firstName
                        lastName
                        registeredName
                        registeredAddress
                        registeredCountry
                        registeredProvince
                        registeredCity
                        registeredDistrict
                        registeredPostalCode
                        typeOfContact
                        residential {
                            type {
                                name
                                slug
                            }
                        }
                    }
                    documentCollectionLetters{
                                edges{
                                    node{
                                        id
                                        fileName
                                        numPage
                                    }
                                }
                    }
                    recordCollectionLetters{
                        edges {
                            node {
                                id
                                sumTotalRecord
                                transaction {
                                            id
                                            description
                                            total
                                            status
                                            invoice{
                                                id
                                                docNumber
                                                dueDate
                                                total
                                                issuedDate
                                            }
                                            productAndService {
                                                id
                                                productCode
                                                name
                                            }
                                            chartOfAccount {
                                                chartOfAccountCode
                                            }

                                }
                            }
                        }
                    }
                }
            }
        }  
    }`;

class WrapperCollectionAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type : 'all',
            indexOutStanding : 1,
            selectedId: props.location.state.selectedId,
        }
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    updateIndexOutStanding = (value) => {
        this.setState({
            indexOutStanding : value
        })
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Collection Letter Print</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                        

                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{collectionLettersId: this.state.selectedId, formDebit: parseInt(this.props.match.params.numberOfTimes) > 4 ? 4 : parseInt(this.props.match.params.numberOfTimes)}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            // let sumAll = props.allCollectionLetters.edges[0].node.sumTotal
                            return (
                                _.map(props.allCollectionLetters.edges ,(allCollectionLetters,indexCll) => {
                                    let group_transaction = _(allCollectionLetters.node.recordCollectionLetters.edges).groupBy('node.transaction.productAndService.name').map((value,name)=> {
                                        let sumTotal = _.sumBy(value,'node.sumTotalRecord')
                                        // _.forEach(value, transaction => {
                                            
                                        //     if(transaction.node.transaction.creditNoteTransaction.edges.length > 0){
                                        //         let sumCredit = _.sumBy(transaction.node.transaction.creditNoteTransaction.edges, 'node.price') 
                                        //         sumTotal -= sumCredit
                                        //     }
                                        //     if(transaction.node.transaction.creditNoteTransactionRecord.edges.length > 0){
                                        //         let sumCredit = _.sumBy(transaction.node.transaction.creditNoteTransactionRecord.edges, 'node.price') 
                                        //         sumTotal -= sumCredit
                                        //     }

                                        //     if(transaction.node.transaction.receiptDepositTransactionUsed.edges.length > 0){
                                        //         // let sumReceiveUse = _.sumBy(transaction.node.transaction.receiptDepositTransactionUsed.edges, 'node.amount') 
                                        //         // sumTotal -= sumReceiveUse
                                        //         const sumReceiveUse = transaction.node.transaction.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
                        
                                        //             if (obj.node.receiveTransaction) {
                                        //                 return total
                                        //             } else {
                                        //                 return total + obj.node.amount
                                        //             }
                                        //         }, 0);

                                        //         sumTotal -= sumReceiveUse
                                        //     }

                                        //     if(transaction.node.transaction.receiveTransaction.edges.length > 0){
                                        //         let sumReceive = _.sumBy(transaction.node.transaction.receiveTransaction.edges, 'node.amount') 
                                        //         sumTotal -= sumReceive
                                        //     }
                                        // })
                                        return({
                                            name: name,
                                            sum_transaction: sumTotal,
                                        })
                                    }).value()
                                    
                                    let sumAll = allCollectionLetters.node.sumTotal
                                    group_transaction = _.filter(group_transaction,transaction => {
                                        return transaction.sum_transaction > 0
                                    })
        
                                    let recordCollectionLetters = []
                                    let countList = 20
        
                                    if(localStorage.getItem('site_id') === '456'){
                                        countList = 10
                                    }

                                    let sumLine = 0;
                                    let dataMapLine = []
                                    let filter_zero = _.filter(allCollectionLetters.node.recordCollectionLetters.edges, e => e.node.sumTotalRecord > 0)
                                    // บรรทัด 278 ที่คอมเม้นไว้ อันล่างเป็นคำสั่งที่ใช้กับพิมพ์แค่หนึ่งใบเท่านั้น
                                    // let filter_zero = _.filter(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges, e => e.node.sumTotalRecord > 0)
                                    if(localStorage.getItem('site_id') !== '340' && localStorage.getItem('site_id') !== '468' && 
                                        localStorage.getItem('site_id') !== '402' && localStorage.getItem('site_id') !== '490' && 
                                        localStorage.getItem('site_id') !== '456' && localStorage.getItem('site_id') !== '340'){
                                           // let mapData = allCollectionLetters.node.recordCollectionLetters.edges.map((n,ins)=> ({...n, no : ins+1}))
                                            let docNumberChk = ""
                                            let orderNumber = 0
                                            _.forEach(filter_zero , (record , index) => {
                                                
                                                let duplicate = true
                                                if(docNumberChk !== record.node.transaction.invoice.docNumber){
                                                    orderNumber += 1
                                                    docNumberChk = record.node.transaction.invoice.docNumber 
                                                    duplicate = false
                                                } 
                                                let chkOrder = !duplicate ? orderNumber : ""
                                                dataMapLine.push({...record, no: chkOrder})
                                                
                                                if(record.node.transaction.description.length >= 145){
                                                    sumLine = sumLine + 5;  //cal row description per page
                                                }
                                                else if(record.node.transaction.description.length >= 115){
                                                    sumLine = sumLine + 4;  
                                                }
                                                else if(record.node.transaction.description.length >= 95){
                                                    sumLine = sumLine + 3;
                                                }
                                                else if(record.node.transaction.description.length >= 75){
                                                    sumLine = sumLine + 2.7;
                                                }
                                                else if(record.node.transaction.description.length >= 40){
                                                    sumLine = sumLine + 2.1; 
                                                }
                                                else if(record.node.transaction.description.length > 0) {
                                                    sumLine = sumLine + 1.1; 
                                                }
    
                                                
                                                if(sumLine >= 37 || filter_zero.length === (index+1)){
                                                    recordCollectionLetters.push(dataMapLine)
                                                    dataMapLine = []
                                                    sumLine = 0
                                                }
                                            })
                                    }else{
                                        // if(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges.length > countList){
                                        //     recordCollectionLetters = _.chunk(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges,countList)
                                        // }else{
                                        //     recordCollectionLetters = [props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges]
                                        // }
                                        // if(allCollectionLetters.node.recordCollectionLetters.edges.length > countList){
                                        //     recordCollectionLetters = _.chunk(allCollectionLetters.node.recordCollectionLetters.edges,countList)
                                        // }else{
                                        //     recordCollectionLetters = [allCollectionLetters.node.recordCollectionLetters.edges]
                                        // }

                                        if(filter_zero.length > countList){
                                            recordCollectionLetters = _.chunk(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges,countList)
                                        }else{
                                            recordCollectionLetters = [props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges]
                                        }
        
                                    }
                                    let totalRecordOutStanding = _.sumBy(allCollectionLetters.node.recordCollectionLetters.edges,'node.sumTotalRecord')

                                    // _.forEach(allCollectionLetters.node.recordCollectionLetters.edges , record => {
                                    //     if(record.node.transaction.creditNoteTransaction.edges.length > 0){
                                    //         let sumCredit = _.sumBy(record.node.transaction.creditNoteTransaction.edges, 'node.price') 
                                    //         totalRecordOutStanding -= sumCredit
                                    //     }
                                    //     if(record.node.transaction.creditNoteTransactionRecord.edges.length > 0){
                                    //         let sumCredit = _.sumBy(record.node.transaction.creditNoteTransactionRecord.edges, 'node.price') 
                                    //         totalRecordOutStanding -= sumCredit
                                    //     }

                                    //     if(record.node.transaction.receiptDepositTransactionUsed.edges.length > 0){
                                    //         const sumReceiveUse = record.node.transaction.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
                                
                                    //             if (obj.node.receiveTransaction) {
                                    //                 return total
                                    //             } else {
                                    //                 return total + obj.node.amount
                                    //             }
                                    //         }, 0);
        
                                    //         totalRecordOutStanding -= sumReceiveUse
                                    //     }
                                    //     if(record.node.transaction.receiveTransaction.edges.length > 0){
                                    //         let sumReceive = _.sumBy(record.node.transaction.receiveTransaction.edges, 'node.amount') 
                                    //         totalRecordOutStanding -= sumReceive
                                    //     }
                                    // } )
                                    
                                    return(
                                        <React.Fragment key={indexCll}>
                                            <CollectionLetter 
                                                selfProject={props.selfProject}
                                                allSettingFormDebit = {props.allSettingFormDebit.edges[0]}
                                                allCollectionLetters = {allCollectionLetters}
                                                group_transaction = {group_transaction}
                                                receivablePage = {recordCollectionLetters?.length}
                                                sumAll= {sumAll}
                                            />
                                            
                                            {/* แนบทวงภามครั้งที่ 1 */}
                                            {(this.props.match.params.numberOfTimes >= 2 && props.allSettingFormDebit?.edges[0]?.node.refCollection1) &&
                                            <WrapperCollectionLetterOldTime 
                                                collectionLettersId = {this.props.match.params.id}  
                                                receivablePage = {recordCollectionLetters?.length}       
                                                formDebit = {1}          
                                                searchOld = {props.allCollectionLetters?.edges[0].node.refDocNumber}    
                                                numberOftime = {this.props.match.params.numberOfTimes}
                                                ref2 = {props.allSettingFormDebit?.edges[0]?.node.refCollection2}                   
                                                ref3 = {props.allSettingFormDebit?.edges[0]?.node.refCollection3}                                    
                                            />}

                                            {/* {allCollectionLetters.node.receivableOutstanding && */}
                                            {props.allSettingFormDebit?.edges[0]?.node?.refReport &&
                                                recordCollectionLetters.map((record,index) => {
                                                    return (
                                                        <OutStandingCollectionLetter selfProject={props.selfProject} 
                                                            allCollectionLetters = {allCollectionLetters} 
                                                            recordCollectionLetters = {record} 
                                                            lastPage = {index+1 === recordCollectionLetters.length ? true : false}
                                                            key = {index}
                                                            indexRecord = {index}
                                                            countList ={countList}
                                                            sumRecord = {totalRecordOutStanding}
                                                            updateIndexOutStanding = {this.updateIndexOutStanding}
                                                            indexOutStanding = {this.state.indexOutStanding}
                                                            languages={props.userConfig.languages}
                                                        />  
                                                    )
        
                                                })
                                            }
                                        </React.Fragment>   
                                    )
                                } )
                            )
                        }
                         return <div className="text-center"><Loading/></div>;
                    }}
                /> 
            </React.Fragment>
        );
    }
}

export default WrapperCollectionAll;
