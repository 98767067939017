import React from "react";
import SearchSelect from "../../../../libs/searchSelect";

class WaterElectricProductServiceList extends React.Component {
    render() {
        let dataFilter = this.props.dataFilter.edges
        let dataFilterInit = dataFilter[0].node.id
        return (
            <div className="col-md-4 border-right">
                <div className="form-group">
                    <label>ประเภทสินค้าและบริการ</label>
                    <SearchSelect onChange={this.props.handleChange}
                                value={this.props.product_and_service ? this.props.product_and_service : dataFilterInit}
                                name="temp_product_and_service"
                                placeholder="กรุณาเลือกผู้ติดต่อ"
                                queryObject={dataFilter}
                                isClearable={true}
                                optionAll={false}
                                keyOfValue="id"
                                keyOfLabel="productCode:name"
                                />
                </div>
            </div>
        )
    }
}

export default WaterElectricProductServiceList;
