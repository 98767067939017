import React, {Component} from 'react';
import { Translation } from 'react-i18next';
import {NavLink} from "react-router-dom";
import jwtDecode from 'jwt-decode'
import _ from "lodash";

class Navigation extends Component {
    render() {
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        let token = jwtDecode(window.localStorage.getItem('token'))
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <Translation>
                                {t=>
                                <NavLink exact={true} to="/register/residential/list/all">
                                    {t("residential:All")}
                                </NavLink>}
                            </Translation>
                        </li>
                        <li>
                            <Translation>
                                {t=>
                                <NavLink to="/register/residential/list/live">
                                    {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                    textData?.data?.registerList.live
                                    :
                                    t("residential:Live")}
                                </NavLink>}
                            </Translation>
                        </li>
                        {!_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on &&
                        <li>
                            <Translation>
                                {t=>
                                <NavLink to="/register/residential/list/for_rent">
                                    {t("residential:For rent")}
                                </NavLink>}
                            </Translation>
                        </li>}
                        <li>
                            <Translation>
                                {t=>
                                <NavLink to="/register/residential/list/availability">
                                    {t("residential:Availability")}
                                </NavLink>}
                            </Translation>
                        </li>
                        <li>
                            <Translation>
                                {t=>
                                <NavLink to="/register/residential/list/seizure">
                                    {t("residential:Seizure")}
                                </NavLink>}
                            </Translation>
                        </li>
                        {
                         _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'coowner'])?.menu_on && 
                         _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'coowner' }) &&
                        <li>
                            <Translation>
                                {t=>
                            <NavLink to="/register/residential/list/coowner">
                                    {t("residential:Co-owner/Resident's Registration Record")}
                                </NavLink>}
                            </Translation>
                        </li>
                        }
                        
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;