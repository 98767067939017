/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type editPettyCashRecordInput = {|
  id: string,
  description: string,
  clientMutationId?: ?string,
|};
export type pettyCashRecordEditDescriptionMutationVariables = {|
  input: editPettyCashRecordInput
|};
export type pettyCashRecordEditDescriptionMutationResponse = {|
  +editPettyCashRecord: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type pettyCashRecordEditDescriptionMutation = {|
  variables: pettyCashRecordEditDescriptionMutationVariables,
  response: pettyCashRecordEditDescriptionMutationResponse,
|};
*/


/*
mutation pettyCashRecordEditDescriptionMutation(
  $input: editPettyCashRecordInput!
) {
  editPettyCashRecord(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "editPettyCashRecordPayload",
    "kind": "LinkedField",
    "name": "editPettyCashRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pettyCashRecordEditDescriptionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pettyCashRecordEditDescriptionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b8c28531b3e47178df17216ad9f3c3ca",
    "id": null,
    "metadata": {},
    "name": "pettyCashRecordEditDescriptionMutation",
    "operationKind": "mutation",
    "text": "mutation pettyCashRecordEditDescriptionMutation(\n  $input: editPettyCashRecordInput!\n) {\n  editPettyCashRecord(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cad0ad64cbc754df9151faea2081d6d6';

module.exports = node;
