import React, { Component } from 'react'
import numberWithCommas from '../../../libs/numberWithComma';
import { Translation } from 'react-i18next';

export default class detailsPhase extends Component {

    handleChangInput = (e) => {
        this.props.onChange({
            target: {
                name: e.target.name,
                value: e.target.value,
            }
        });
    }
    render() {
        return (
            <Translation>
                {
                    t=>
                    <React.Fragment>
                        <div className="row mb-3">
                            <div className="col-4">
                                <p>{t("PageDetailProjectInfo:AmountBuilding")}</p>
                            </div>
                            <div className="col-8">
                                {this.props.editProjectInfo
                                    ?
                                        <div className="d-flex">
                                            <input type="text" 
                                                className="form-control form-control-sm col-4 text-right" 
                                                name="projectInfo.numberOfBuilding" 
                                                value={this.props.projectInfo.numberOfBuilding || ""}
                                                onChange={this.handleChangInput}
                                                />
                                            <span className=" ml-2 mt-1">{t("PageDetailProjectInfo:Building")}</span>
                                        </div>
                                    :
                                        <span>{this.props.projectInfo.numberOfBuilding} {t("PageDetailProjectInfo:Building")}</span>
                                }
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4">
                                <p>{t("PageDetailProjectInfo:AmountUnitHouse")}</p>
                            </div>
                            <div className="col-8">
                                {this.props.editProjectInfo
                                    ?
                                        <div className="d-flex">
                                            <input type="text" 
                                                className="form-control form-control-sm col-4 text-right" 
                                                name="projectInfo.numberOfRoom" 
                                                value={this.props.projectInfo.numberOfRoom || ""}
                                                onChange={this.handleChangInput}
                                            />
                                            <span className=" ml-2 mt-1">{t("PageDetailProjectInfo:UnitHouse")}</span>
                                        </div>
                                    :
                                        <span>{this.props.projectInfo.numberOfRoom} {t("PageDetailProjectInfo:UnitHouse")}</span>
                                }
                            </div>
                        </div>
                        {this.props.projectInfo?.projectBuilding?.edges.length > 0 && 
                        this.props.projectInfo?.projectBuilding?.edges?.map((phase, indexPhase) =>{
                            if ((phase.node.status && phase.node.status !== 'delete') || !phase.node.status) {
                                return (
                                    <div className="row mb-3" key={indexPhase}>
                                        <div className="col-12">
                                            <div className="row phase">
                                                {this.props.editProjectInfo 
                                                    ?
                                                        <React.Fragment>
                                                            <div className="col-4">
                                                                <p>{t("PageDetailProjectInfo:Phase")}</p>
                                                                
                                                            </div>
                                                            <div className="col-8 d-flex">
                                                                <input type="text" 
                                                                    className="form-control form-control-sm col-4 text-right" 
                                                                    name={`projectInfo.projectBuilding.edges[${indexPhase}].node.name`}
                                                                    value={phase.node.name}
                                                                    onChange={this.handleChangInput}
                                                                    />
                                                                <span type="button" 
                                                                    className="text-danger ml-2"
                                                                    onClick={() =>this.props.deleteRow(this.props.projectInfo.projectBuilding.edges,phase.node.id !== '' ? phase.node.id : indexPhase, "projectInfo.projectBuilding.edges")}
                                                                    >{t("PageDetailProjectInfo:DeletePhase")}
                                                                </span>
                                                            </div>
                                                        </React.Fragment>
                                                    :
                                                        <div className="col-4">
                                                            <p>{t("PageDetailProjectInfo:Phase")} {phase.node.name}</p>
                                                        </div>
                                                }
                                            </div>
                                            {phase.node.projectBuildingUnit?.edges.length > 0 &&
                                            phase.node.projectBuildingUnit?.edges.map((buildingUnit, indexBuild) => {
                                                if((buildingUnit.node.status && buildingUnit.node.status !== 'delete') || !buildingUnit.node.status){
                                                return (
                                                    <React.Fragment key={indexBuild}>
                                                        <div className="row mt-2">
                                                            <div className="col-4">
                                                                <p>{t("PageDetailProjectInfo:BuildNo")} {indexBuild+1}</p> 
                                                            </div>
                                                            {this.props.editProjectInfo &&
                                                                <div className="col float-right">
                                                                    <span type="button" 
                                                                        className="text-danger"
                                                                        onClick={() => this.props.deleteRow(this.props.projectInfo.projectBuilding.edges[indexPhase].node.projectBuildingUnit.edges,buildingUnit.node.id !== '' ? buildingUnit.node.id : indexBuild, `projectInfo.projectBuilding.edges[${indexPhase}].node.projectBuildingUnit.edges`)}
                                                                        >{t("PageDetailProjectInfo:DeleteBuildNo")} {indexBuild +1}
                                                                    </span>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <p>{t("PageDetailProjectInfo:AmountFloor")}</p>
                                                            </div>
                                                            <div className="col-8">
                                                                {this.props.editProjectInfo
                                                                    ?
                                                                        <div className="d-flex">
                                                                            <input type="text" 
                                                                                className="form-control form-control-sm col-4 text-right" 
                                                                                name={`projectInfo.projectBuilding.edges[${indexPhase}].node.projectBuildingUnit.edges[${indexBuild}].node.numberOfFloor`}
                                                                                value={buildingUnit.node.numberOfFloor}
                                                                                onChange={this.handleChangInput}
                                                                                />
                                                                            <span className="ml-2 mt-1">{t("PageDetailProjectInfo:Floor")}</span>
                                                                        </div>
                                                                    :
                                                                        <span>{buildingUnit.node.numberOfFloor} {t("PageDetailProjectInfo:Floor")}</span>
                                                                }
                                                            </div>
                                                        </div>
            
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <p>{t("PageDetailProjectInfo:AmountUnit")}</p>
                                                            </div>
                                                            <div className="col-8">
                                                                {this.props.editProjectInfo
                                                                    ?
                                                                        <div className="d-flex">
                                                                            <input type="text" 
                                                                                className="form-control form-control-sm col-4 text-right" 
                                                                                name={`projectInfo.projectBuilding.edges[${indexPhase}].node.projectBuildingUnit.edges[${indexBuild}].node.numberOfRoom`}
                                                                                value={buildingUnit.node.numberOfRoom}
                                                                                onChange={this.handleChangInput}
                                                                                />
                                                                            <span className="ml-2 mt-1">{t("PageDetailProjectInfo:Unit")}</span>
                                                                        </div>
                                                                    :
                                                                        <span >{buildingUnit.node.numberOfRoom} {t("PageDetailProjectInfo:Unit")}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}else{
                                                    return <></>
                                                }
                                            })}
                                            {this.props.editProjectInfo && 
                                                <div className="row">
                                                    <React.Fragment>
                                                        <div className="col-2"/>
                                                        <div className="col-10">
                                                            <span type="button" 
                                                                className="text-primary"
                                                                onClick={(e) => this.props.appendRow(`projectInfo.projectBuilding.edges[${indexPhase}].node.projectBuildingUnit.edges`,indexPhase)}
                                                                >+ {t("PageDetailProjectInfo:AddBuildingInPhase")}</span>
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    )
                                }else{
                                    return <></>
                                }
                                
                            })
                        }
                        {this.props.editProjectInfo &&
                            <div className="row mb-3">
                                <div className="col-12">
                                    <div className="row">
                                        <React.Fragment>
                                            <div className="col-2"/>
                                            <div className="col-10">
                                                <span type="button" 
                                                    className="text-primary"
                                                    onClick={(e) => this.props.appendRow("projectInfo.projectBuilding.edges")}
                                                    >+ {t("PageDetailProjectInfo:AddPhase")}</span>
                                            </div>
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="row mb-5">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4">
                                        <p>{t("PageDetailProjectInfo:Project size")}</p>
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    {this.props.editProjectInfo 
                                                        ?
                                                            <React.Fragment>
                                                                <div className="col-5 d-flex">
                                                                    <input type="text" 
                                                                        className="form-control form-control-sm text-right col-12" 
                                                                        name="projectInfo.space" 
                                                                        value={this.props.projectInfo?.space || ''}
                                                                        onChange={this.handleChangInput}
                                                                        />
                                                                    <span className=" ml-2 mt-1">{t("PageDetailProjectInfo:square wah")}</span>
                                                                </div>
                                                            </React.Fragment>
                                                        :
                                                            <React.Fragment>
                                                                {this.props.projectInfo?.space !== "" &&
                                                                    <div className="col-4">
                                                                        <span>{numberWithCommas(this.props.projectInfo?.space)} {t("PageDetailProjectInfo:square wah")}</span>
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <p>{t("PageDetailProjectInfo:Usable area")}</p>
                                    </div>
                                    <div className="col-8">
                                        {this.props.editProjectInfo ?
                                                <div className="d-flex">
                                                    <input type="text" 
                                                        className="form-control form-control-sm text-right col-4" 
                                                        name="projectInfo.usableSpace" 
                                                        value={this.props.projectInfo?.usableSpace}
                                                        onChange={this.handleChangInput}
                                                        />
                                                    <span className="ml-2 mt-1">{t("PageDetailProjectInfo:square meter")}</span>
                                                </div>
                                            :
                                                <span>{numberWithCommas(this.props.projectInfo?.usableSpace)} {t("PageDetailProjectInfo:square meter")}</span>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <p>{t("PageDetailProjectInfo:Number of parking spaces")}</p>
                                    </div>
                                    <div className="col-8">
                                        {this.props.editProjectInfo ?
                                                <div className="d-flex">
                                                    <input type="text" 
                                                        className="form-control form-control-sm text-right col-4" 
                                                        name="projectInfo.parkingSpace" 
                                                        value={this.props.projectInfo?.parkingSpace}
                                                        onChange={this.handleChangInput}
                                                        />
                                                    <span className="ml-2 mt-1"> %</span>
                                                </div>
                                            :
                                                <span>{this.props.projectInfo?.parkingSpace} %</span>
                                        }
                                    </div>
                                    <div className="col-4">
                                    </div>
                                    <div className="col-8">
                                        {this.props.editProjectInfo ?
                                                <div className="d-flex">
                                                    <input type="text" 
                                                        className="form-control form-control-sm text-right col-4" 
                                                        name="projectInfo.parkingSlot" 
                                                        value={this.props.projectInfo?.parkingSlot}
                                                        onChange={this.handleChangInput}
                                                        />
                                                    <span className="ml-2 mt-1"> {t("PageDetailProjectInfo:vehicles")}</span>
                                                </div>
                                            :
                                                <span>{this.props.projectInfo?.parkingSlot} {t("PageDetailProjectInfo:vehicles")}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}
