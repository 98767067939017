import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import environment from "../../../env/environment";
import i18n from "i18next";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { decode } from 'base-64';
import { Redirect } from 'react-router'
import SearchSelect from "../../../libs/searchSelect";
import Select, { components } from 'react-select';
import upload from '../../../libs/upload'
import format from 'date-fns/format';
import apiSing from '../../../api/sing';
import { fetchQuery } from "relay-runtime";
import jwtDecode from "jwt-decode";
import _ from 'lodash';
import i18next from 'i18next';

const allResidential = graphql`
    query fixRequestListCommonCreateQuery {
        allResidential {
            totalCount
            edges {
                node {
                    id
                    name
                    floor
                    size
                    status
                    isUserActivate
                    residentialHouseholder{
                        edges{
                            node{
                                id
                                tenant{
                                    id
                                    firstName
                                    lastName
                                    countries
                                    phone
                                    user{
                                        id
                                    }                                    
                                    householder(first: 1, last: 1) {
                                        edges {
                                            node {
                                                active
                                            }
                                        }
                                    }   
                                }
                            }
                        }
                    }
                    type{
                        id
                        name
                    }
                }
            }
        }
    }
`;

class fixRequestListCommonCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedId: "",
            account: [],
            mapAccount: [],
            redirect: false,
            loading: true,
            workingTypeAll: [],
            homeAll: [],
            residentialHouseholder: [],
            all_task_detail: [],
            all_task_detail_note: [],
            all_floor: [],
            all_unit: [],
            unit_detail: [],


            typeFix: "1",
            Requsetfrom: "Tenant",
            RequestBy: "เลือกชื่อผู้แจ้ง",
            RequestKey: "",
            isRequestKey: false,
            // imgProject: "/images/typeIcon/sing/imgProject.png",
            pageX: "0px",
            pageY: "0px",
            display: "none",
            file: [],

            homeId: "",
            residential_id: "",
            tenant_id: "",

            other_contact: "",
            email_contact: "",
            phone_contact: "",

            repair_type: "public",
            task_id: "",
            working_type: "บัวเชิงผนัง",
            material_Id: "",
            material: "บัวเชิงผนัง",
            details_Id: "",
            details: "",
            floor: "",
            zone: "",
            description: "",

            image: "",
            insure_status: "",
            due_date: "",
            no_of_warranty: "",

            mark: "1,2",
            mark_x: "1",
            mark_y: "2",

            loadingRequestBy: false,
            loadingContact: false,
            loadingRequest: false,
            loadingMaterial: false,
            loadingDetails: false,
            loadingFloor: false,
            loadingZone: false,
            loadingSubmit: false,
            token: jwtDecode(window.localStorage.getItem('token'))
        }
        this.onChange = this.onChange.bind(this)
        this.printMousePos = this.printMousePos.bind(this)
        this.dropDownChanged = this.dropDownChanged.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.get_task_detail = this.get_task_detail.bind(this)
        this.get_task_detail_note = this.get_task_detail_note.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.getZone = this.getZone.bind(this)
        this.onChageSelectWarranty = this.onChageSelectWarranty.bind(this)
        this.CommonSelect = this.CommonSelect.bind(this)
        this.getUnitDetail = this.getUnitDetail.bind(this)
        this.onChangeRequestBy = this.onChangeRequestBy.bind(this)
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    componentWillMount() {
        this.getHomeAll()
        this.getWorkType()


        if (this.props.match.params.id) {
            this.getDataId()
        }
    }
    getWorkType() {
        apiSing.get_task_type_service("public").then((res) => {
            let data = []
            if (res.data.all_task_type) {
                data = res.data.all_task_type
                res.data.all_task_type.map((n, index) => {
                    let image = "/images/typeIcon/sing/singCommon/" + n.task_type_name + '.png'
                    _.set(data, '[' + index + ']images', image)
                })
                this.setState({ workingTypeAll: data })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }
    getHomeAll() {
        fetchQuery(environment, allResidential)
            .then(response_data => {
                let dataRoom = [...response_data.allResidential.edges]
                this.setState({
                    // homeAll: response_data.allResidential.edges,
                    homeAll: dataRoom?.sort(function (a, b) {
                        return a.node.name.localeCompare(b.node.name, undefined, {
                            numeric: true,
                            sensitivity: 'base'
                        });
                    })
                });
            });
    }
    getDataId() {
        let idd = this.props.match.params.id
        apiSing.getrepairs_room_view("", "", "", "", idd).then((res) => {
            if (res.data.data) {
                let data = res.data.data[0]
                let DeCodeJWT = localStorage.getItem("token")
                DeCodeJWT = jwtDecode(DeCodeJWT)
                this.setState({
                    residential_id: data.residential.name,
                    tenant_id: "",
                    isRequestKey: data.other_contact ? true : false,
                    RequestBy: "other",
                    other_contact: data.other_contact,
                    email_contact: data.email_contact,
                    phone_contact: data.phone_contact,
                    tenant_contact: data.tenant_contact.name_title + data.tenant_contact.first_name + "  " + data.tenant_contact.last_name,
                    working_type: "บัวเชิงผนัง",
                    material: "บัวเชิงผนัง",
                    details: "รอยต่อไม่เรียบ, เห็นหัวตะปู",

                    // repair_type: this.props.match.params.for === "forcommon" ? "public" : 'private',
                    // task_id: "",

                    // material_Id: "",
                    // details_Id: "",
                    // floor: "",
                    // zone: "",
                    // description: "",

                    // image: "",
                    // insure_status: "",
                    // due_date: "",

                    // mark: "1,2",
                    // mark_x: "1",
                    // mark_y: "2",
                })
            }
        })
    }

    getZone(e) {
        // let data = JSON.parse(e.target.value)
        // let floorNo = data.floor
        // floorNo = floorNo.split("ชั้น ")

        // let value = e.target.value
        let value = this.state.token?.site?.includes('sklasmanagement') ? e.value : e.target.value
        let noWarranty = this.state.no_of_warranty
        this.setState({ loadingZone: true })

        if (this.state.token?.site?.includes('sklasmanagement')) {
            apiSing.getZoneCommon(value, noWarranty).then((res) => {
                if (res.data.all_unit) {
                    let zoneCommon = []
                    res.data.all_unit.forEach((ele, index) => {
                        _.set(zoneCommon, '[' + index + '].value', ele.unit_code);
                        _.set(zoneCommon, '[' + index + '].label', ele.unit_code);
                        _.set(zoneCommon, '[' + index + '].warranty_expire_date', ele.warranty_expire_date);
                        _.set(zoneCommon, '[' + index + '].warranty_status', ele.warranty_status);
                    })

                    this.setState({ all_unit: zoneCommon, floor: value, loadingZone: false })
                }
            }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
        } else {
            this.setState({ floor: value, loadingZone: false })
        }

    }
    getUnitDetail(e) {
        // let value = e.target.value
        let value = e.value
        let label = e.label
        let warranty_status = e.warranty_status
        let warranty_expire_date = e.warranty_expire_date
        this.setState({ image: "" })

        apiSing.get_facilty_detail(value).then((res) => {
            if (res.data.unit_detail) {
                this.setState({ image: res.data.unit_detail[0]?.layout_image_url, zone: label, due_date: warranty_expire_date, insure_status: warranty_status })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }


    get_task_detail(index) {
        this.setState({
            working_type: index.task_type_name_th, task_id: index.task_type_id,
            loadingMaterial: true, loadingDetails: true, loadingFloor: true, loadingZone: true
        })
        apiSing.get_task_detail(index.task_type_id).then((res) => {
            let dataAllTaskDetail = []
            let langs = localStorage.getItem("language")
            if (res.data.all_task_detail) {
                res.data.all_task_detail.forEach((n, index) => {
                    _.set(dataAllTaskDetail, '[' + index + '].value', n.task_detail_id);
                    _.set(dataAllTaskDetail, '[' + index + '].label', langs === "th" ? n.task_detail_name_th : n.task_detail_name);
                })
                this.setState({ all_task_detail: dataAllTaskDetail, loadingMaterial: false })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }
    get_task_detail_note(e) {        
        // let data = JSON.parse(e.target.value)
        // let id = data.id.toString()
        // let material = data.material.toString()
        this.setState({ loadingDetails: true, loadingFloor: true, loadingZone: true })

        let residential_id = ""
        // if(this.state.Requsetfrom === 'Tenant'){
        //     residential_id = decode(this.state.residential_id)
        //     residential_id = residential_id.split(":")
        //     residential_id = residential_id[1]
        // }
        let id = e.value
        let material = e.label
        apiSing.get_task_detail_note(this.state.task_id, id, residential_id).then((res) => {
            let dataAllTaskDetailNote = []
            let langs = localStorage.getItem("language")

            if (res.data.all_task_detail_note) {
                res.data.all_task_detail_note.forEach((n, index) => {
                    _.set(dataAllTaskDetailNote, '[' + index + '].value', n.task_detail_note_id);
                    _.set(dataAllTaskDetailNote, '[' + index + '].label', langs === "th" ? n.task_detail_note_th : n.task_detail_note);
                    _.set(dataAllTaskDetailNote, '[' + index + '].insure_status', n.warranty_status);
                    _.set(dataAllTaskDetailNote, '[' + index + '].no_of_warranty', n.no_of_warranty);
                    _.set(dataAllTaskDetailNote, '[' + index + '].due_date', n.warranty_expire_date);
                })
                this.setState({
                    all_task_detail_note: dataAllTaskDetailNote,
                    material: material,
                    material_Id: id,
                    loadingDetails: false, loadingFloor: true, loadingZone: true
                })
            }
            // if (res.data.all_task_detail_note) {
            //     this.setState({ all_task_detail_note: res.data.all_task_detail_note, material: material, material_Id: parseInt(id) })
            // }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    
    onChageSelectWarranty(e) {
        
        let id = e.value
        let due_date = e.due_date
        let insure_status = e.insure_status
        let details = e.label
        this.setState({ loadingFloor: true, loadingZone: true, no_of_warranty: e.no_of_warranty, insure_status: insure_status, details: details, })

        if (e) {
            apiSing.get_number_of_floor().then((res) => {   
                             
                let number_of_floor = []
                let numb = 0
                for (let index = 0; index < res.data.number_of_floor; index++) {
                    numb = index + 1
                    _.set(number_of_floor, '[' + index + '].value', numb);
                    _.set(number_of_floor, '[' + index + '].label', numb);
                }                
                if (res.data.number_of_floor === numb) {                    
                    this.setState({
                        // all_floor: number_of_floor,
                        due_date: due_date,
                        details_Id: parseInt(id),
                        loadingFloor: false,
                        loadingZone: true,
                        floor: ""
                    })
                }else{
                    this.setState({
                        loadingFloor: false,
                    })
                }
            })
        }
    }



    async dropDownChanged(e) {
        this.setState({ loadingRequestBy: true, RequestBy: "" })
        let listHouseHolder = []
        listHouseHolder = this.state.homeAll.filter((n) => n.node.id === e.target.value)
        let moreNameRequest = {
            value: "other",
            label: "ระบุเอง"
        }

        if (listHouseHolder) {
            let dataClone = []
            let dataFloor = []
            await listHouseHolder[0].node.residentialHouseholder.edges.forEach((n, index) => {
                _.set(dataClone, '[' + index + '].value', n.node.tenant.id);
                _.set(dataClone, '[' + index + '].label', n.node.tenant.firstName + " " + n.node.tenant.lastName);
                _.set(dataClone, '[' + index + '].phone', n.node.tenant.phone)
                _.set(dataClone, '[' + index + '].houseHolderActive', n.node.tenant.householder.edges[0].node.active)
            })
            if (this.state.token?.site?.includes('sklasmanagement')) {
                await apiSing.get_unit_detail(e.target.value).then((res) => {
                    dataFloor = res?.data?.unit_detail?.map((n) => ({
                        ...n, value: n.unit_layout_name, label: n.unit_layout_name, image: n.layout_image_url
                    }))
                })
            } else {
                _.set(dataFloor, '[' + 0 + '].value', listHouseHolder[0].node.floor)
                _.set(dataFloor, '[' + 0 + '].label', listHouseHolder[0].node.floor)
                _.set(dataFloor, '[' + 0 + '].image', "")
                _.set(dataFloor, '[' + 0 + '].isHaveImage', "N")
            }
            this.setState({
                [e.target.name]: e.target.value,
                residentialHouseholder: [...dataClone, moreNameRequest],
                all_floor: dataFloor,
                RequestBy: "", loadingRequestBy: false, phone_contact: ''
            })
        }
    }
    printMousePos(e) {
        var my_div = document.getElementById('my_div_id');
        var box = my_div.getBoundingClientRect();
        var cursorX = Math.ceil(e.clientX - box.left - 10);
        var cursorY = Math.ceil(e.clientY - box.top - 10);
        var mark_x = (cursorX * 2 + 6).toString()
        var mark_y = (cursorY * 2 + 57.97).toString()


        this.setState({
            pageX: cursorX + "px",
            pageY: cursorY + "px",
            mark_x: mark_x,
            mark_y: mark_y - 37,
            mark: mark_x + "," + mark_y
        })
    }

    //UPLOADFILE
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/sing/file" + path, file);
            resolve(res);
        });
    };
    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files) {
            if (files?.size > 10485760) {  // RQ147 2097152
                Swal.fire(i18next.t("Allaction:Unsuccessful "), `${i18next.t("Allaction:Please upload a new file as it exceeds the specified size of")} 10 MB`, 'error')
            }
            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), 'กรุณาอัพโหลดไฟล์ในประเภท .png, .jpg, และ .jpegเท่านั้น และไฟล์ขนาดไม่เกิน 10 MB (สูงสุด 3 รูป)', 'error')
            }
            else {
                // fileIsSel = false
                let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
                let fileOldList = _.cloneDeep(this.state.file);
                this.callUpload('', new_file).then((data) => {
                    let dese = "sing/file/" + new_file.name
                    fileOldList.push({ fileId: "", fileName: new_file.name, filePath: dese, status: "", viewPtath: data.location })
                    this.setState({ file: fileOldList })
                });
            }
        }
    }
    // END UPLOAD
    onSubmit(e) {
        e.preventDefault();
        let residential_id = this.state.Requsetfrom === "Common" ? "" : decode(this.state.residential_id)
        residential_id = this.state.Requsetfrom === "Common" ? "" : residential_id.split(":")

        let personWorkingValue = this.state.token?.site?.includes('sklasmanagement') ? " S KLAS(Juristic Person)" : "Juristic Person"

        let dataSubmit = {
            "residential_id": this.state.Requsetfrom === "Common" ? "" : parseInt(residential_id[1]),
            "tenant_id": parseInt(this.state.RequestBy),

            "other_contact": this.state.other_contact ? this.state.other_contact : '',
            "email_contact": this.state.email_contact,
            "phone_contact": this.state.phone_contact,

            "repair_type": this.state.repair_type,
            "working_type": this.state.working_type,
            "material": this.state.material,
            "details": this.state.details,
            "floor": this.state.floor,
            "zone": this.state.zone,
            "image": this.state.image,
            "description": this.state.description,

            "insure_status": this.state.token?.site?.includes('sklasmanagement') ? this.state.insure_status : "not",
            "due_date": this.state.due_date + "T00:00",
            "person_working": this.state.insure_status === "have" ? "S-Homecare" : personWorkingValue,

            "mark": this.state.mark,
            "mark_x": this.state.mark_x,
            "mark_y": this.state.mark_y,

            "image_repairs_room": this.state.file,
            "fast_data_id": {
                "job_task_type_id": this.state.task_id,
                "job_task_detail_id": this.state.material_Id,
                "job_task_detail_note_id": this.state.details_Id,
                "unit_code": this.state.zone

            },
            "create_by": "juristic_wab"
        }

        this.setState({ loadingSubmit: true }, () => {
            apiSing.POSTrepairs_room_view(dataSubmit).then((res) => {
                if (res.data.statusMessage === "success") {
                    Swal.fire(i18n.t("Allaction:Saved Successfully"), "", "success").then(() => {
                        this.setState({ redirect: true })
                    })
                }
            }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), "error"))
        })
    }

    CommonSelect(data) {
        let user = jwtDecode(localStorage.getItem("token"))
        if (data === "Common") {
            let nameNiti = user.username
            this.setState({ other_contact: nameNiti, Requsetfrom: data, residential_id: ":0" })
        } else {
            this.setState({ other_contact: "", Requsetfrom: data })
        }
    }

    async onChangeRequestBy(e) {
        await this.setState({ other_contact: '' })
        if (e.value === "other" || e.value === "0") {
            this.setState({ RequestBy: e.value, isRequestKey: true, phone_contact: "", other_contact: '', loadingContact: false })

        } else {
            let value = decode(e.value).split(":")
            let phone = e.phone ? e.phone : ""
            this.setState({ phone_contact: phone, RequestBy: value[1], isRequestKey: false, other_contact: '', loadingContact: false })
        }
    }

    dateFile = (index, data) => {
        let DataFile = _.cloneDeep(this.state.file)
        DataFile.splice(index, 1)
        this.setState({ file: DataFile })
    }

    CustomOption = (props) => {
        return (
          <components.Option {...props}>
            <div>
              {props.data.label}
              {props.data.houseHolderActive ? <div className="green-dot" /> : <div className="grey-dot" />}
            </div>
          </components.Option>
        );
    };

    CustomSingleValue = (props) => {
        return (
          <components.SingleValue {...props}>
            <div>
              {props.data.label}
              {props.data.houseHolderActive ? <div className="green-dot" /> : <div className="grey-dot" />}
            </div>
          </components.SingleValue>
        );
    };

    render() {

        let paseName = jwtDecode(localStorage.getItem("token"))

        if (this.state.redirect) {
            return <Redirect to="/contact/fix-requests/forcommon/review" />;
        } 

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            <div className="container-fluid box fixRequestFast" >
                                <div className='createFixrequest'>
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/contact/fix-requests/forcommon/review">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span style={{ fontWeight: '400' }}>
                                                    {t("PageList:Create Fix Request / Problem")}
                                                    {t("PageList:Common")}
                                                </span>

                                            </h3>
                                        </div>
                                    </div>
                                    {
                                        <React.Fragment>
                                            <div className='row'>
                                                <div className="col-10 mx-auto">
                                                    <form onSubmit={this.onSubmit}>
                                                        <div>
                                                            <br /><br />

                                                            <section className='HeaderForCommon'>
                                                                <React.Fragment>
                                                                    <p style={{ fontSize: '18px' }}>{t("PageList:Create Subject")}</p>
                                                                    <div style={{ display: 'inline' }}>
                                                                        <label className='labelInput'>
                                                                            <input type={"radio"} name='Requsetfrom' onChange={() => this.CommonSelect("Tenant")}
                                                                                checked={this.state.Requsetfrom === "Tenant"} />
                                                                            {t("PageList:Fix Request From Tenant")}
                                                                        </label>

                                                                        <label style={{ marginLeft: "190px" }}></label>

                                                                        <label className='labelInput'>
                                                                            <input type={"radio"} name='Requsetfrom' onChange={() => this.CommonSelect("Common")}
                                                                                checked={this.state.Requsetfrom === "Common"} />
                                                                            {t("PageList:Fix Request From Juristic")}</label>
                                                                    </div>
                                                                    <hr />
                                                                </React.Fragment>
                                                            </section>

                                                            <section className='GeneralInformation'>
                                                                <h3 style={{ fontWeight: '400' }}>{t("PageCreate:General Information")}</h3>
                                                                <hr />

                                                                {
                                                                    this.state.Requsetfrom !== "Common" &&
                                                                    <React.Fragment>
                                                                        <label style={{ marginTop: '24px' }}>{t("PageCreate:Find Room")} <span className='text-red'>*</span></label>

                                                                        <SearchSelect className="selectBox inputBox"
                                                                            onChange={this.dropDownChanged}
                                                                            value={this.state.residential_id}
                                                                            name="residential_id" placeholder="รายการ"
                                                                            queryObject={this.state.homeAll}
                                                                            keyOfValue="id"
                                                                            require={true}
                                                                            keyOfLabel="name"
                                                                            showUserActive={true}
                                                                        />


                                                                        <label>{t("PageCreate:Choose Name Request")} <span className='text-red'>*</span></label>
                                                                        <div style={{ position: 'relative' }}>                                                                      
                                                                            {
                                                                                this.state.loadingRequestBy == false ?
                                                                                    <div>
                                                                                        <Select
                                                                                            placeholder={t("PageCreate:Choose Name Request")}
                                                                                            options={this.state.residentialHouseholder}
                                                                                            isSearchable={false}
                                                                                            onChange={this.onChangeRequestBy}
                                                                                            components={{
                                                                                                Option: this.CustomOption,
                                                                                                SingleValue: this.CustomSingleValue
                                                                                            }}
                                                                                        />
                                                                                        <input name="RequestBy" type="text" value={this.state.RequestBy || ""}
                                                                                            style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                            onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                            required={true} />
                                                                                    </div>
                                                                                    :
                                                                                    <Select
                                                                                        placeholder={t("PageCreate:Choose Name Request")}
                                                                                        isSearchable={false}
                                                                                    />



                                                                            }

                                                                        </div>


                                                                    </React.Fragment>

                                                                }

                                                                {
                                                                    this.state.Requsetfrom !== "Common" ?
                                                                        <React.Fragment>

                                                                            {
                                                                                this.state.loadingContact == false ?
                                                                                    (this.state.isRequestKey == true ?
                                                                                        <div>
                                                                                            <label>{t("PageCreate:First Name - Last Name")} <span className='text-red'>*</span></label>
                                                                                            <input type="text" name='other_contact' className='form-control inputBox'
                                                                                                placeholder='ระบุชื่อผู้แจ้ง' required={true}
                                                                                                value={this.state.other_contact}
                                                                                                // defaultValue={this.state.other_contact}
                                                                                                onKeyPress={this.onChange} onChange={this.onChange} />

                                                                                            <label>{t("PageCreate:Phone")} <span className='text-red'>*</span></label>
                                                                                            <input type="text" name='phone_contact' className='form-control inputBox' placeholder='เบอร์ติดต่อผู้แจ้ง'
                                                                                                // defaultValue={this.state.phone_contact}
                                                                                                value={this.state.phone_contact}
                                                                                                maxLength={10} minLength={10} pattern='[0-9]{10}'
                                                                                                required={true} onChange={this.onChange}
                                                                                                onKeyPress={this.onChange} />

                                                                                            <label>{"E-mail "}</label>
                                                                                            <input type="email" name='email_contact' className='form-control inputBox' placeholder='E-mail'
                                                                                                defaultValue={this.state.email_contact}
                                                                                                onChange={this.onChange}
                                                                                                required={false} onKeyPress={this.onChange} />
                                                                                        </div>

                                                                                        :


                                                                                        <div>
                                                                                            <label>{t("PageCreate:Phone")} <span className='text-red'>*</span></label>
                                                                                            <input type="text" name='phone_contact' className='form-control inputBox' placeholder='XXX-XXX-XXXX'
                                                                                                value={this.state.phone_contact}
                                                                                                // defaultValue={this.state.phone_contact}
                                                                                                onChange={this.onChange}
                                                                                                required={true} onKeyPress={this.onChange} />


                                                                                        </div>
                                                                                    )
                                                                                    : ""
                                                                            }
                                                                        </React.Fragment>
                                                                        :
                                                                        <React.Fragment>
                                                                            <label>{t("PageCreate:First Name - Last Name")}  <span className='text-red'>*</span></label>
                                                                            <input type="text" name='other_contact' className='form-control inputBox'
                                                                                defaultValue={paseName.first_name + " " + paseName.last_name} readOnly={true}
                                                                            />


                                                                            <label>{t("PageCreate:Phone")} <span className='text-red'>*</span></label>
                                                                            <input type="text" name='phone_contact' className='form-control inputBox' placeholder='XXX-XXX-XXXX'
                                                                                // defaultValue={this.state.idCard} 
                                                                                pattern='[0-9]{10}' maxLength={10} minLength={10}
                                                                                onChange={(e) => this.setState({ phone_contact: e.target.value })}
                                                                                required={true} onKeyPress={(e) => this.setState({ phone_contact: e.target.value })} />
                                                                        </React.Fragment>

                                                                }
                                                            </section>

                                                            <section className='WorkingType'>
                                                                <label>{t("PageCreate:Category")}  <span className='text-red'>*</span></label><br />
                                                                <div>
                                                                    {
                                                                        this.state.workingTypeAll.map((n, index) => {
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    <div
                                                                                        className={this.state.working_type === n.task_type_name_th ? 'divTypeCar active' : 'divTypeCar '}
                                                                                        onClick={() => this.get_task_detail(n)}
                                                                                    >
                                                                                        <div className='imgTypeSelect'>
                                                                                            <img src={process.env.PUBLIC_URL + n.images} className='imgType' /><br />
                                                                                            {
                                                                                                this.state.working_type === n.task_type_name_th &&
                                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Blues.png'} className='selected' />
                                                                                            }
                                                                                        </div>
                                                                                        <p>{t("pdpaPrivacyPolicy:PrivacyPolicy") === "th" ? n.task_type_name_th : n.task_type_name}</p>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>

                                                                <div style={{ clear: 'both' }}></div>
                                                                <br />
                                                            </section>

                                                            <section className='TypeOject'>
                                                                <label style={{ marginTop: '10px' }}>{t("PageCreate:Work Type / Material")} <span className='text-red'>*</span></label>
                                                                <div style={{ position: 'relative' }}>
                                                                    {
                                                                        this.state.loadingMaterial == false ?
                                                                            <div>
                                                                                <Select
                                                                                    classNamePrefix='inputBox'
                                                                                    options={this.state.all_task_detail}
                                                                                    onChange={this.get_task_detail_note}
                                                                                    require={true}
                                                                                    isSearchable={false}
                                                                                    placeholder={t("PageCreate:Work Type / Material")}
                                                                                />
                                                                                <input name="material" type="text" value={this.state.material || ""}
                                                                                    style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                    onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                    required={true} />
                                                                            </div>
                                                                            :
                                                                            <Select
                                                                                isSearchable={false}
                                                                                placeholder={t("PageCreate:Work Type / Material")}
                                                                            />

                                                                    }

                                                                </div>
                                                                {/* <select className='form-control inputBox' name='material' onChange={this.get_task_detail_note} defaultValue={""}>
                                                                    <option value={""}>-เลือกชนิดงาน/วัสดุ-</option>
                                                                    {
                                                                        this.state.all_task_detail.map((n) => {
                                                                            return (
                                                                                <React.Fragment key={n.task_detail_id}>
                                                                                    <option value={
                                                                                        JSON.stringify({
                                                                                            id: n.task_detail_id,
                                                                                            material: n.task_detail_name_th
                                                                                        })
                                                                                    }>{n.task_detail_name_th}</option>
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                    }

                                                                </select> */}
                                                            </section>

                                                            <section className='DetailWorkingType'>
                                                                <label>{t("PageCreate:Detail Fix Request")} <span className='text-red'>*</span></label>
                                                                <div style={{ position: 'relative' }}>
                                                                    {
                                                                        this.state.loadingDetails == false ?
                                                                            <div>
                                                                                <Select
                                                                                    classNamePrefix='inputBox'
                                                                                    options={this.state.all_task_detail_note}
                                                                                    onChange={this.onChageSelectWarranty}
                                                                                    required={true}
                                                                                    isSearchable={false}
                                                                                    placeholder={t("PageCreate:Detail Fix Request")}
                                                                                />
                                                                                <input name="details" type="text" value={this.state.details || ""}
                                                                                    style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                    onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                    required={true} />
                                                                            </div>
                                                                            :
                                                                            <Select
                                                                                isSearchable={false}
                                                                                placeholder={t("PageCreate:Detail Fix Request")}
                                                                            />
                                                                           
                                                                    }

                                                                </div>
                                                            </section>

                                                            <section className='floor'>
                                                                <label>{t("PageCreate:Floor")} <span className='text-red'>*</span></label>
                                                                <div style={{ position: 'relative' }}>
                                                                    {
                                                                        this.state.loadingFloor == false ?

                                                                            <div>
                                                                                {
                                                                                    this.state.token?.site?.includes('sklasmanagement') ?
                                                                                        <>
                                                                                            <Select
                                                                                                classNamePrefix='inputBox'
                                                                                                options={this.state.all_floor}
                                                                                                onChange={this.getZone}
                                                                                                required={true}
                                                                                                isSearchable={false}
                                                                                                placeholder={t("PageCreate:Floor")}
                                                                                            />
                                                                                            <input name="floor" type="text" value={this.state.floor || ""}
                                                                                                style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                                onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                                required={true} />

                                                                                        </> : <input name="floor" id="floor" className='form-control inputBox' type="text" defaultValue={this.state.floor}
                                                                                            onChange={this.getZone} required={true} />


                                                                                }

                                                                            </div>
                                                                            :
                                                                            <>
                                                                             <Select
                                                                                isSearchable={false}
                                                                                placeholder={t("PageCreate:Floor")}
                                                                            />
                                                                            </>
                                                                    }

                                                                </div>

                                                                <section className='zone'>
                                                                    <label>{t("PageCreate:Zone")} <span className='text-red ml-2' style={{fontSize: '12px'}}>*{t("settingNote:Enter message not more than")} 200 {t("settingNote:characters")} {this.state.zone?.length}/200 </span>
                                                                        {this.state.token?.site?.includes('sklasmanagement') ? <span className='text-red'>*</span> : ''} 
                                                                    </label>
                                                                    <div style={{ position: 'relative' }}>
                                                                        {
                                                                            this.state.loadingZone == false ?
                                                                                <React.Fragment>
                                                                                    {
                                                                                        this.state.token?.site?.includes('sklasmanagement') ?
                                                                                            <div>
                                                                                                <Select
                                                                                                    classNamePrefix='inputBox'
                                                                                                    options={this.state.all_unit}
                                                                                                    onChange={this.getUnitDetail}
                                                                                                    required={true}
                                                                                                    isSearchable={false}
                                                                                                    placeholder={t("PageCreate:Zone")}
                                                                                                />
                                                                                                <input name="zone" type="text" value={this.state.zone || ""}
                                                                                                    style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                                    onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                                    required={true} />
                                                                                            </div>
                                                                                            :
                                                                                            <input type={"text"} name='zone' defaultValue={this.state.zone} className='form-control inputBox'
                                                                                                style={{ width: 475 }} onChange={this.onChange} onKeyDown={this.onChange} maxLength={200} />
                                                                                    }

                                                                                </React.Fragment>

                                                                                :
                                                                                this.state.token?.site?.includes('sklasmanagement') ?
                                                                                    <Select
                                                                                        isSearchable={false}
                                                                                        placeholder={t("PageCreate:Zone")}
                                                                                    />
                                                                                    :
                                                                                    <input type={"text"} name='zone' defaultValue={this.state.zone} className='form-control inputBox'
                                                                                        style={{ width: 475 }} onChange={this.onChange} onKeyDown={this.onChange} />

                                                                        }

                                                                    </div>

                                                                </section>

                                                                {
                                                                    (this.state.image !== "" && this.state.floor !== "") &&

                                                                    <div className='imgProductBox' id='my_div_id' onClick={this.printMousePos}
                                                                        style={{
                                                                            backgroundPositionX: this.state.pageX, backgroundPositionY: this.state.pageY
                                                                        }}>
                                                                        <img src={this.state.image} style={{ height: "248px" }} onError={() => this.setState({ image: "/images/typeIcon/sing/common.png" })} />
                                                                    </div>
                                                                }
                                                            </section>



                                                            <section className='description'>
                                                                <label>{t("PageCreate:Detail of the problem")} <span className='text-red ml-2' style={{fontSize: '12px'}}>*{t("settingNote:Enter message not more than")} 200 {t("settingNote:characters")} {this.state.description?.length}/200 </span> </label>
                                                                <textarea
                                                                    name='description' defaultValue={this.state.description}
                                                                    placeholder={t("PageCreate:Detail of the problem")} rows={3}
                                                                    onChange={(e) => this.setState({ description: e.target.value })}
                                                                    onKeyDown={(e) => this.setState({ description: e.target.value })}
                                                                    className='form-control'
                                                                    style={{ resize: "none", width: "475px" }} 
                                                                    maxLength={200}
                                                                    />
                                                            </section>

                                                            <section className='UploadFile'>
                                                                <div style={{ display: 'inline-flex', pointerEvents: this.state.file.length >= 3 ? 'none' : 'all' }}>
                                                                    <h3 style={{ marginTop: '40px' }}>{t("PageCreate:Choose File Fix Request")} </h3>
                                                                    <label htmlFor="uploadFile" className="upload-btn-wrapper"
                                                                    >
                                                                        <button className={this.state.file.length >= 3 ? "btn disabled" : "btn"}><img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />{t("silvermanguard:Choose File")} </button>
                                                                        <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>{t("PageCreate:Support File Just .png, .jpg, jpeg Only and File Not Over 10 MB (Max 3 Image Only)")} </span>

                                                                        <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder='ระบุเบอร์โทร' accept='application/.pdf,.png,.jpg' onChange={this.uploadFile}
                                                                        />

                                                                    </label>
                                                                </div>

                                                                {
                                                                    this.state.file.length > 0 &&
                                                                    this.state.file.map((file, inx) => {
                                                                        let typeFile = file.fileName.split(".")

                                                                        return (

                                                                            <p key={inx}>
                                                                                <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;
                                                                                <Link
                                                                                    to="route"
                                                                                    onClick={(event) => {
                                                                                        event.preventDefault();
                                                                                        window.open(
                                                                                            typeof file.viewPtath === "string"
                                                                                                ? file.viewPtath
                                                                                                : URL.createObjectURL(file.viewPtath)
                                                                                        )
                                                                                    }}
                                                                                    target="blank">{file.fileName}</Link>
                                                                                &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file)}>X</label>
                                                                            </p>

                                                                        )

                                                                    })
                                                                }
                                                            </section>

                                                            <section className='ChkWarranty'>
                                                                <p style={{
                                                                    fontWeight: "600", fontSize: "13px", color: this.state.insure_status === "have" ? "#38AE3E" : "#CA0824"
                                                                }}>
                                                                    <React.Fragment>
                                                                        {
                                                                            this.state.insure_status === "have" ?
                                                                                <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/InsurGreen.png"} style={{ width: '18px' }} /> :
                                                                                this.state.insure_status === "out" || this.state.insure_status === "not" ?
                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/InsurRed.png"} style={{ width: '18px' }} /> :
                                                                                    ""

                                                                        }
                                                                        {
                                                                            this.state.insure_status === "have" ?
                                                                                <React.Fragment>&nbsp;&nbsp; {this.state.due_date ? format(this.state.due_date, "มีประกัน DD/MM/YYYY") : "-"}</React.Fragment>
                                                                                : this.state.insure_status === "out" ?
                                                                                    <React.Fragment>&nbsp;&nbsp; {this.state.due_date ? format(this.state.due_date, "หมดประกัน DD/MM/YYYY") : "-"}</React.Fragment>
                                                                                    : this.state.insure_status === "not" ? " ไม่มีประกัน" : ""
                                                                        }
                                                                    </React.Fragment>

                                                                </p>

                                                            </section>

                                                        </div>

                                                        <div className='row mt-2'>
                                                            <div className='col-12 text-right'>
                                                                {
                                                                    this.state.loadingSubmit ?
                                                                        <span className='text-red'>กำลังดำเนินการ โปรดรอสักครู่</span>
                                                                        :
                                                                        <button type='submit' className='btn btn-primary'>{t("Allaction:save")}</button>
                                                                }
                                                            </div>
                                                        </div>

                                                    </form>

                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }


                                </div>



                            </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default fixRequestListCommonCreate;
