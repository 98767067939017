/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductAndServiceType = "EXPENSE" | "FINE" | "PRODUCT" | "SERVICE" | "%future added value";
export type productDetailQueryVariables = {|
  id: string
|};
export type productDetailQueryResponse = {|
  +product: ?{|
    +id: string,
    +name: string,
    +productCode: string,
    +cost: ?number,
    +inputTax: ?string,
    +type: ProductAndServiceType,
    +price: ?number,
    +outputTax: ?string,
    +typeOfIncome: ?string,
    +typeOfIncomeOther: ?string,
    +typeOfTax: ?string,
    +typeOfTaxOther: ?string,
    +typeOfWithholdingTax: ?string,
    +taxRate: ?string,
    +taxRateOther: ?string,
    +chartOfAccount: ?{|
      +id: string,
      +name: string,
      +chartOfAccountCode: string,
    |},
    +description: ?string,
  |}
|};
export type productDetailQuery = {|
  variables: productDetailQueryVariables,
  response: productDetailQueryResponse,
|};
*/


/*
query productDetailQuery(
  $id: ID!
) {
  product(id: $id) {
    id
    name
    productCode
    cost
    inputTax
    type
    price
    outputTax
    typeOfIncome
    typeOfIncomeOther
    typeOfTax
    typeOfTaxOther
    typeOfWithholdingTax
    taxRate
    taxRateOther
    chartOfAccount {
      id
      name
      chartOfAccountCode
    }
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ProductAndServiceNode",
    "kind": "LinkedField",
    "name": "product",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cost",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "inputTax",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "outputTax",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfIncome",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfIncomeOther",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfTax",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfTaxOther",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfWithholdingTax",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taxRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taxRateOther",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNode",
        "kind": "LinkedField",
        "name": "chartOfAccount",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "chartOfAccountCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "productDetailQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "productDetailQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4a5c1f8edc1dc126a27900a96c1f9e6f",
    "id": null,
    "metadata": {},
    "name": "productDetailQuery",
    "operationKind": "query",
    "text": "query productDetailQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    id\n    name\n    productCode\n    cost\n    inputTax\n    type\n    price\n    outputTax\n    typeOfIncome\n    typeOfIncomeOther\n    typeOfTax\n    typeOfTaxOther\n    typeOfWithholdingTax\n    taxRate\n    taxRateOther\n    chartOfAccount {\n      id\n      name\n      chartOfAccountCode\n    }\n    description\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6fb677252ea5880fe15e82c7d85502be';

module.exports = node;
