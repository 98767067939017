import i18next from 'i18next';
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/expense/purchase/list/all">
                                {i18next.t("PurchaseList:All")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/expense/purchase/list/active">
                                {i18next.t("PurchaseList:Unpaid")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/expense/purchase/list/waiting_pay">
                                {i18next.t("PurchaseList:Pre - payment")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/expense/purchase/list/paid">
                                {i18next.t("PurchaseList:Paid")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/expense/purchase/list/partial_payment">
                                {i18next.t("PurchaseList:Partial pay")}
                            </NavLink>

                        </li>
                        <li>
                            <NavLink to="/accounting/expense/purchase/list/void">
                                {i18next.t("PurchaseList:Cancelled")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;