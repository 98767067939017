import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import api from './services'
import environment from "../env/environment";
import NavigationMenuSetup from "../libs/navigationMenuSetup";
import jwtDecode from "jwt-decode";

const _ = require('lodash');
const query = graphql`
    query contactTopMenuNavigationQuery($repairType:String){
        getNotification {
            type
            count
        }
        sumRepairsJuristic(repairType:$repairType)
    }
`;

class ContactTopMenuNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fix_request: window.localStorage.getItem('fix_request') || "0",
            contact: window.localStorage.getItem('contact') || "0",
            check_right_setting: false,
            check_score_setting: false,
            token: jwtDecode(window.localStorage.getItem('token')),
            publicType: '',
            privateType: '',
            codename_booking: [
                "booking_calendar",
                "booking_list",
                "booking_facility",
                "booking_setting"
            ],
        }
        this.getNotiRepairPrivate = this.getNotiRepairPrivate.bind(this)
    }

    async componentWillMount() {
        fetchQuery(environment, query, { repairType: 'public' }).then(response_data => {

            if (response_data.getNotification) {
                response_data.getNotification.forEach((notification) => {
                    this.setState({
                        [notification.type]: (notification.count).toString()
                    })
                    window.localStorage.setItem([notification.type], notification.count)
                })
            }
            if (response_data.sumRepairsJuristic) {
                this.setState({
                    publicType: response_data.sumRepairsJuristic
                })
            }
        });

        this.getNotiRepairPrivate('private')

        await  api.getCheckRightSetting().then((res) => {
            if (res) {
                this.setState({ check_right_setting: res.check_right_setting })
            }
        })
        await api.getCheckScoreSetting().then((res) => {
            if (res) {
                this.setState({ check_score_setting: res.check_score_setting })
            }
        })
    }

    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
    }

    getNotiRepairPrivate(type) {
        fetchQuery(environment, query, { repairType: type }).then(response_data => {
            if (response_data.sumRepairsJuristic) {
                this.setState({
                    privateType: response_data.sumRepairsJuristic
                })
            }
        });
    }

    render() {
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"))
        return (
            <div className="container-fluid bg-gray">

                <div className={"row "
                    + (this.props.mini ? "mini " : "")
                    + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

                    {this.props.center && (
                        <Translation>{t => 
                            <div className="col-12 mt-5 mb-5">
                                <h3 className="text-center">
                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on? 
                                textData?.data?.navigation.contact : t("navigation:contact")}
                                </h3>
                            </div>}
                        </Translation>
                    )}

                    <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
                        <ul>
                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_news' }) &&
                                <li>
                                    <Link to="/contact/news/all">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/news-top-menu.png'}
                                            alt="news-top-menu"
                                            className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <div >{t('topMenuNavigation:news')}</div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_survey' }) &&
                                <li>
                                    <Link to="/contact/survey">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/survey-top-menu.png'}
                                            alt="survey-top-menu"
                                            className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <div>{t('topMenuNavigation:survey')}</div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_parcel' }) &&
                                <li>
                                    <Link to="/contact/parcel/all">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/parcel-top-menu.png'}
                                            alt="parcel-top-menu"
                                            className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <div>
                                                    {t('topMenuNavigation:parcel')}
                                                </div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                            {
                                  _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_chat' }) &&
                                <li>
                                    <Link to="/contact/complaint/open">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/complain-top-menu.png'}
                                            alt="complaint-top-menu"
                                            className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t =>
                                                    <div>{t('topMenuNavigation:complaint')}
                                                        {this.state.contact !== "0" &&
                                                            <span className="badge">
                                                                {this.state.contact}
                                                            </span>
                                                        }
                                                    </div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }

                            {/* EDM */}
                            {
                                localStorage.getItem('site_id') && localStorage.getItem('site_id') === '331' &&
                                <li>
                                    <Link to="/contact/EDM">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/group.png'}
                                            alt="chat-group-menu"
                                            className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <div>
                                                    EDM
                                                </div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                            {/* Fix request old" */}
                            {
                                !_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on &&
                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_fix_request' }) && !this.state.token?.site?.includes('sklasmanagement') &&

                                <React.Fragment>
                                    <li>
                                        <Link to="/contact/fix-request/open">
                                            <img
                                                src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/fix-request-top-menu.png'}
                                                alt="fix-request-top-menu"
                                                className={this.props.mini && 'd-none'} />
                                            <Translation>
                                                {
                                                    t =>
                                                        <div>
                                                            {
                                                                // localStorage.getItem("site_id") === "331" ?
                                                                t('topMenuNavigation:fix request old')
                                                                // : t('topMenuNavigation:fix request')
                                                            }

                                                            {this.state.fix_request !== "0" &&
                                                                <span className="badge">
                                                                    {this.state.fix_request}
                                                                </span>
                                                            }
                                                        </div>
                                                }
                                            </Translation>
                                        </Link>
                                    </li>
                                </React.Fragment>
                            }

                            {/* FixRequest แจ้งซ่อมใหม่ codename repairs_room*/}
                            {
                                // (localStorage.getItem("site_id") === "331" || this.state.token?.site?.includes('sklasmanagement')) &&
                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'repairs_room' }) &&
                                <React.Fragment>
                                    <li>
                                        <a className="dropdown-toggle align-items-center d-flex" href="/" role="button"
                                            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <img
                                                src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/fix-request-top-menu.png'}
                                                alt="fix-request-top-menu"
                                                className={this.props.mini && 'd-none'} />
                                            <Translation>
                                                {
                                                    t =>
                                                        <div>{t('topMenuNavigation:fix request')}
                                                            {this.state.fix_request !== "0" &&
                                                                <span className="badge ml-2">
                                                                    {this.state.fix_request}
                                                                </span>
                                                            }
                                                        </div>
                                                }
                                            </Translation>
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <Link to="/contact/dashbroad">
                                                <Translation>
                                                    {
                                                        t => 
                                                        <div>
                                                            {t("MenuName:Fix Request Dashboard")} 
                                                            <span className="col-sm-2 d-flex flex-row mr-2">
                                                                <img className="mr-0" src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/piechart.png'} />
                                                                <img className="mr-0" src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/barchart.png'}/>                                               
                                                            </span>
                                                        </div>
                                                    }
                                                </Translation>
                                            </Link>
                                            <Link to="/contact/fix-requests/fortenant/review">
                                                <Translation>
                                                    {
                                                        t => <div>
                                                            {t("MenuName:Fix Request Tenant")+" "+"(PNT)"} 
                                                            {this.state?.privateType !== "0" && this.state.privateType > 0 ? <span className="badge-noti ml-2">
                                                                {this.state.privateType}
                                                            </span> : ''}
                                                        </div>
                                                    }
                                                </Translation>
                                            </Link>
                                            <Link to="/contact/fix-requests/forcommon/review">
                                                <Translation>
                                                    {
                                                        t => <div>
                                                            {t("MenuName:Fix Request Common")+" "+"(CNT)"}
                                                            {this.state?.publicType !== "0" &&
                                                                this.state.publicType > 0 ?
                                                                <span className="badge-noti ml-2">
                                                                    {this.state.publicType}
                                                            </span> : ''}
                                                        </div>
                                                    }
                                                </Translation>
                                            </Link>
                                            <Link to="/contact/fix-requests/report/fortenant/all" >
                                                <Translation>
                                                    {
                                                        t => <div>
                                                            {t("MenuName:Fix Request Tenant Report")}
                                                        </div>
                                                    }
                                                </Translation>
                                            </Link>
                                            <Link to="/contact/fix-requests/report/forcommon/all">
                                                <Translation>
                                                    {
                                                        t => <div>
                                                            {t("MenuName:Fix Request Common Report")}
                                                        </div>
                                                    }
                                                </Translation>
                                            </Link>
                                            <Link to="/contact/settinginsurance">
                                                <Translation>
                                                    {
                                                        t => <div>
                                                            {t("MenuName:Fix Request Insurrance Setting")}
                                                        </div>
                                                    }
                                                </Translation>
                                            </Link>
                                        </div>
                                    </li>
                                </React.Fragment>
                            }

                            {/* ปิดไว้ของ Sena */}
                            <React.Fragment>
                                {
                                    !this.state.token?.site?.includes('sklasmanagement') && _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_booking, element.codename)) &&
                                    <li>
                                        <a className="dropdown-toggle align-items-center d-flex" href="/" role="button"
                                            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <img
                                                src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/global-area-menu.png'}
                                                alt="parcel-top-menu"
                                                className={this.props.mini && 'd-none'} />
                                            <Translation>
                                                {
                                                    t => <div className="p-0">
                                                        {t('set_permission:Common Area')} 
                                                    </div>
                                                }
                                            </Translation>
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_calendar' }) &&
                                                <Link to="/contact/global-area/calendar/month">
                                                    <Translation>
                                                        {
                                                            t => <div>
                                                                {t('topMenuNavigation:global area')}
                                                            </div>
                                                        }
                                                    </Translation>
                                                </Link>
                                            }
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_list' }) &&
                                                <Link to="/contact/global-area/all">
                                                    <Translation>
                                                        {
                                                            t => <div>
                                                                {t('booking:booking_common_area')+" "+"(BK)"}
                                                            </div>
                                                        }
                                                    </Translation>
                                                </Link>
                                            }

                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_facility' }) &&
                                                <Link to={this.state.check_right_setting ? "/contact/global-area/booking/common-area/all" : '#'} style={this.state.check_right_setting ? {} : { color: '#E2E2E2' }} >
                                                    <Translation>
                                                        {
                                                            t => <div>
                                                                {t("set_permission:Facility List")}
                                                            </div>
                                                        }
                                                    </Translation>
                                                </Link>
                                            }
                                            {
                                                // <React.Fragment>
                                                //     <Link
                                                //         to="/contact/global-area/accounting/all"
                                                //         to="#"
                                                //     >
                                                //         <Translation>
                                                //             {
                                                //                 t => <div style={{ color: '#E2E2E2' }} >
                                                //                     งานจัดเก็บค่าใช้บริการส่วนกลาง Beta
                                                //                     <p style={{ color: "#e56070", fontWeight: "bold", display: "inline" }}>(Beta)</p>
                                                //                 </div>
                                                //             }
                                                //         </Translation>
                                                //     </Link>
                                                //     <Link
                                                //         to="/contact/global-area/services/all"
                                                //         to="#"
                                                //     >
                                                //         <Translation>
                                                //             {
                                                //                 t => <div style={{ color: '#E2E2E2' }} >
                                                //                     {t('booking:booking_common_area')}
                                                //                     บริการเสริมพื้นที่ส่วนกลาง Beta
                                                //                     <p style={{ color: "#e56070", fontWeight: "bold", display: "inline" }}>(Beta)</p>
                                                //                 </div>
                                                //             }
                                                //         </Translation>
                                                //     </Link>
                                                // </React.Fragment>
                                            }
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_setting' }) &&
                                                <Link to="/contact/global-area/setting/right/">
                                                    <Translation>
                                                        {
                                                            t => <div>
                                                                {t("set_permission:Setting Common Area")}
                                                            </div>
                                                        }
                                                    </Translation>
                                                </Link>
                                            }
                                        </div>
                                    </li>
                                }

                            </React.Fragment>

                            {/* ของ Singha */}
                            <React.Fragment>
                                {
                                    this.state.token?.site?.includes('sklasmanagement') &&
                                    <li>
                                        <a className="dropdown-toggle align-items-center d-flex" href="/" role="button"
                                            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <img
                                                src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/global-area-menu.png'}
                                                alt="parcel-top-menu"
                                                className={this.props.mini && 'd-none'} />
                                            <Translation>
                                                {
                                                    t => <div className="p-0">
                                                        {t('set_permission:Common Area')}
                                                    </div>
                                                }
                                            </Translation>
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_calendar' }) &&
                                                <Link to="/contact/global-area-singha/calendar/month">
                                                    <Translation>
                                                        {
                                                            t => <div>
                                                                {t('topMenuNavigation:global area')}
                                                            </div>
                                                        }
                                                    </Translation>
                                                </Link>
                                            }
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_list' }) &&
                                                <Link to="/contact/global-area-singha/all">
                                                    <Translation>
                                                        {
                                                            t => <div>
                                                                {t('booking:booking_common_area')}
                                                            </div>
                                                        }
                                                    </Translation>
                                                </Link>
                                            }
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_facility' }) &&
                                                <Link to={this.state.check_score_setting ? "/contact/global-area-singha/booking/common-area/all" : '#'} style={this.state.check_score_setting ? {} : { color: '#E2E2E2' }} >
                                                    <Translation>
                                                        {
                                                            t => <div>
                                                                {t("set_permission:Facility List")}
                                                            </div>
                                                        }
                                                    </Translation>
                                                </Link>
                                            }
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_setting' }) &&
                                                <Link to="/contact/global-area-singha/setting/right/">
                                                    <Translation>
                                                        {
                                                            t => <div>
                                                                {t("set_permission:Setting Common Area")}
                                                            </div>
                                                        }
                                                    </Translation>
                                                </Link>
                                            }

                                            <Link to="/contact/global-area-singha/managescore">
                                                <Translation>
                                                    {
                                                        t => <div>
                                                            {t("newBookingSingha_ManageScore:Adding points")}
                                                        </div>
                                                    }
                                                </Translation>
                                            </Link>
                                        </div>
                                    </li>
                                }
                            </React.Fragment>

                            {/* กรรมการ */}
                            {
                                //   localStorage.getItem('site_id') && localStorage.getItem('site_id') === '331' &&
                               _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'committee_chat' }) &&
                                <li>
                                    <Link to="/contact/committee/pending">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/group.png'}
                                            alt="chat-group-menu"
                                            className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <div>
                                                    {t('topMenuNavigation:committee work')}
                                                </div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactTopMenuNavigation;