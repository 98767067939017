import React, { Component } from 'react';
import { Link } from "react-router-dom";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import SearchSelect from "../../../libs/searchSelect";
import { graphql } from "babel-plugin-relay/macro";
import './css/receiptDepositContactCreateForm.scss';
import environment from "../../../env/environment";
import { commitMutation } from 'react-relay'
import { fetchQuery } from "relay-runtime";
import Swal from "sweetalert2";
import { Redirect } from 'react-router';
import getDocumentNumber from "../../../libs/getDocumentNumber";
import { Translation } from "react-i18next";
import i18n from "i18next"
import InvoiceDelete from "../invoice/invoiceDelete";
import { format, } from "date-fns";
import numberWithComma from "../../../libs/numberWithComma";
import { decimal } from "../../../utils";
import ReceiveForm from "../../receive/receiveForm";
import PayPaymentChannelForm from "../../pay/payPaymentChannelForm";
import getNameResidential from "../../../libs/getNameResidential";
import {
    prepaidDepositMutation,
    setFormatPrepaidDepositMutation
} from "../../expense/prepaid_deposit/prepaidDepositMutation";
import UploadFileInput from "../../../libs/uploadFileInput";
import _ from "lodash"
import SelectRoleAccounting from '../../../components/FucntionLib/selectRoleAccounting';
import queryCheckHouseHolderPaygroup from "../query/queryCheckHouseHolderPaygroup"



const mutation = graphql`
    mutation receiptDepositCreateFormMutation($input: CreateReceiptDepositAndTransactionInput!){
        createReceiptDepositAndTransaction(input: $input){
            ok
            warningText
        }
    }`;

const query = graphql`
    query receiptDepositCreateFormQuery{
    allProjects {
            edges {
            node {
            periodCreate
        }
    }
    }
}
`;

let transaction_object = {
    id: "",
    chartOfAccount: {},
    description: "",
    price: 0,
    preTaxAmount: 0,
    vat: "",
    vatAmount: 0,
    whtAmount: 0,
};

class ReceiptDepositCreateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            issuedDate: new Date(),
            remark: '',
            total: 0,
            transaction_list: [],
            redirectToList: false,
            category: this.props.category,
            receive_channel: [],
            payChannel: [],
            totalReceive: 0,
            totalPay: 0,
            taxType: this.getTaxType(),
            customerList: this.props.customerList,
            loading: false,
            tax_invoice_number: "",
            ref_number: '',
            tax_invoice_date: new Date(),
            // tax_invoice_date: null,
            tax_invoice_period: new Date(),
            document_upload: '',
            upload: {},
            signreq: Math.random().toString(36),
            temp_month_before: '',
            temp_month_after: '',
            period_create: '',
            pay_group: "owner",
            name: "",
            address: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.appendNewRow = this.appendNewRow.bind(this);
        this.setTransactionList = this.setTransactionList.bind(this);
        this.submitFrom = this.submitFrom.bind(this);
        this.updateReceiveChannel = this.updateReceiveChannel.bind(this);
        this.onError = this.onError.bind(this);
        this.getTaxAmount = this.getTaxAmount.bind(this);
        this.onSuccessPrepaid = this.onSuccessPrepaid.bind(this);
        this.updatePayChannel = this.updatePayChannel.bind(this);
        this.handleInputDocument = this.handleInputDocument.bind(this);
    }

    componentWillMount() {
        let object = transaction_object;
        object.vat = this.getVatFromContact();
        this.setState({
            transaction_list: [...this.state.transaction_list, { ...object }],
        });
    }


    componentDidMount() {
        let addrrss = this.addressData()
        let name = this.props.contact.typeOfContact === "RESIDENTIAL" ? this.props.contact.refNumber + " " + getNameResidential(this.props.contact.firstName, this.props.contact.lastName) : this.props.contact.refNumber + " " + this.props.contact.name
        this.setState({ address: addrrss, name: name });
        fetchQuery(environment, query).then((response_data) => {
            if (response_data) {
                let data = _.cloneDeep(response_data);
                this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
            }

        })
    }

    calPeriodMouth(val) {
        let month_before = new Date();
        let month_after = new Date();

        month_before.setMonth(month_before.getMonth() - parseInt(val));
        month_after.setMonth(month_after.getMonth() + parseInt(val));

        this.setState({
            temp_month_before: month_before,
            temp_month_after: month_after,
        })
    }
    getVatFromContact() {
        if ((!this.props.project_have_vat) && this.props.contact && this.props.contact.vat === 7) {
            return 7
        } else {
            return ""
        }
    }

    getTaxType() {
        if ((!this.props.project_have_vat) && this.props.openVat && (this.props.contact && this.props.contact.vat === 7)) {
            return 'INCLUDE_TAX';
        } else {
            return 'NO_TAX';
        }
    }

    handleInputDocument(e) {
        this.setState({
            document_upload: e.currentTarget.files[0],
        });
    }

    handleInputChange(e) {
        let transaction_list = [...this.state.transaction_list];

        if (e.target.dataset.key === 'transaction_list') {

            if (e.target.name === 'chartOfAccount') {
                let chart_of_account_list = [...this.props.receiptDepositQuery.viewer.allChartOfAccount.edges];

                chart_of_account_list.forEach((coa) => {
                    if (coa.node.id === e.target.value) {
                        transaction_list[e.target.dataset.id]['chartOfAccount'] = coa.node;
                        transaction_list[e.target.dataset.id]['vat'] = transaction_list[0].vat;
                    }
                });
            } else {
                transaction_list[e.target.dataset.id][e.target.name] = e.target.value;
            }

            let name = e.target.name;
            if (name === 'price') {
                const taxamount = parseFloat((e.target.value) * ((transaction_list[0].vat) / 100)).toFixed(2)
                transaction_list[e.target.dataset.id]['vatAmount'] = taxamount;
                if (transaction_list[e.target.dataset.id]['whtRatePercent']) {
                    const whtAmount_price = parseFloat(e.target.value) * ((transaction_list[e.target.dataset.id]['whtRatePercent'] || 0) / 100)
                    transaction_list[e.target.dataset.id]['whtAmount'] = whtAmount_price;
                }

            } else if (name == 'whtRatePercent') {
                let whtAmount = 0;
                const whtamount_write = parseFloat(e.target.value);
                this.state.transaction_list.forEach((transaction) => {
                    whtAmount += parseFloat(transaction.price) * ((whtamount_write || 0) / 100)
                });
                transaction_list[e.target.dataset.id]['whtAmount'] = whtAmount;
            }

            this.setState({ transaction_list: [...transaction_list] }, () => {
                if (name === 'price') {
                    this.calPrice();
                }
            });
            return;
        }


        if (e.target.name === 'vat') {
            let transaction_list = [...this.state.transaction_list];
            transaction_list.forEach((transaction) => {
                transaction.vat = e.target.value;
                transaction['preTaxAmount'] = this.getTaxAmount(transaction);
            });
            this.setState({ transaction_list, vat: e.target.value });
            return;
        } else if (e.target.name === 'vatAmount') {
            const taxamount_write = decimal(e.target.value);
            this.setState({ [e.target.name]: taxamount_write });
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    calPrice() {
        let sum_price = 0;
        let transaction_list = [...this.state.transaction_list];
        transaction_list.forEach((transaction) => {
            sum_price = (sum_price + (parseFloat(transaction.price) || 0));
        });
        this.setState({ total: sum_price });
    }

    appendNewRow() {
        this.setState({ transaction_list: [...this.state.transaction_list, { ...transaction_object }] });
    }

    setTransactionList(new_transaction) {
        this.setState({ transaction_list: new_transaction }, () => {
            this.calPrice();
        })
    }

    submitFrom(e) {
        e.preventDefault();
        if (this.state.category === 'receipt_deposit') {
            if (parseFloat(this.getTotal()) !== parseFloat(this.state.totalReceive)) {
                Swal.fire(i18n.t("receipt_deposit:Please enter the correct amount!"), '', 'warning');
                return
            }
            this.setState({ loading: true });

            let receipt_deposit = {
                "id": "",
                "issuedDate": format(this.state.issuedDate, 'YYYY-MM-DD'),
                "remark": this.state.remark,
                "total": this.getTotal(),
                "taxType": this.state.taxType,
            };

            let variables = {
                input: {
                    contactId: this.props.customerList[0],
                    receiptDeposit: JSON.stringify(receipt_deposit),
                    transactionList: JSON.stringify(this.state.transaction_list),
                    paymentChannel: JSON.stringify(this.state.receive_channel),
                    clientMutationId: "RD" + this.state.signreq,
                    payGroup: this.state.pay_group
                }
            };

           

            const uploadables = this.state.upload;

            fetchQuery(environment, queryCheckHouseHolderPaygroup, {
                contactId: this.props.customerList[0],
                payGroup:this.state.pay_group
             }).then((data) => {
               let checkHouseHolderPaygroup = JSON.parse(data.checkHouseHolderPaygroup)
               if(checkHouseHolderPaygroup.ok){
                 // create receipt deposit 
                    commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        uploadables,
                        onCompleted: (response) => {
                            this.setState({ loading: false });
                            if (response.createReceiptDepositAndTransaction.ok) {
                                Swal.fire(i18n.t("receipt_deposit:Save successfully!"), '', 'success').then(() => {
                                    this.setState({ redirectToList: true })
                                });
                            } else {
                                this.setState({ loading: false });
                                Swal.fire(i18n.t("receipt_deposit:Save unsuccessfully!"), response.createReceiptDepositAndTransaction.warningText, 'warning');
                            }
                        },
                        onError: (err) => {
                            this.onError();
                        },
                    },
                );   
        
               }else if(checkHouseHolderPaygroup.ok === false && this.state.pay_group === 'owner' ){
                 Swal.fire({
                //    title: "ออกได้แต่ชื่อจะเป็นค่าช่องว่าง",
                   type: 'warning',
                   showCancelButton: true,
                   text:checkHouseHolderPaygroup.message,
                   confirmButtonColor: '#3085d6',
                   cancelButtonColor: '#d33',
                   confirmButtonText: i18n.t("Allaction:Yes"),
                   cancelButtonText: i18n.t("Allaction:cancel")
                 }).then((result) => {
                     if (result.value) {
                         // create receipt deposit
                        commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            uploadables,
                            onCompleted: (response) => {
                                this.setState({ loading: false });
                                if (response.createReceiptDepositAndTransaction.ok) {
                                    Swal.fire(i18n.t("receipt_deposit:Save successfully!"), '', 'success').then(() => {
                                        this.setState({ redirectToList: true })
                                    });
                                } else {
                                    this.setState({ loading: false });
                                    Swal.fire(i18n.t("receipt_deposit:Save unsuccessfully!"), response.createReceiptDepositAndTransaction.warningText, 'warning');
                                }
                            },
                            onError: (err) => {
                                this.onError();
                            },
                        },
                    );

                       
            
                     }else{
                       this.setState({ loading: false });
                     }
                 })
               }else{
                 Swal.fire({
                   title: "บันทึกไม่สำเร็จ",
                   type: 'warning',
                   text:checkHouseHolderPaygroup.message,
                   showCancelButton: true,
                   showConfirmButton:false,
                   cancelButtonColor: '#d33',
                   cancelButtonText: 'ปิด',
               })
               
                 this.setState({ loading: false });
               }
             })
        } else {
            // console.log(parseFloat(this.getTotal()) ,parseFloat(this.state.totalPay));
            if (parseFloat(this.getTotal()) !== parseFloat(this.state.totalPay)) {
                Swal.fire(i18n.t("receipt_deposit:Please enter the correct amount!"), '', 'warning');
                return
            }
            else if (parseFloat(this.getSumAmount()) === 0) {
                Swal.fire(i18n.t("receipt_deposit:Please enter more amount 0 !"), '', 'warning');
                return
            }

            this.setState({ loading: true });

            let variables = setFormatPrepaidDepositMutation(this.state, this.getSumAmount());

            if (variables) {
                let uploadables = { document_upload: this.state.document_upload };
                prepaidDepositMutation(variables, uploadables, this.onSuccessPrepaid, this.onError);
            }
            this.setState({ loading: false });
        }
    }

    onSuccessPrepaid(response) {
        this.setState({ loading: false });
        if (response.createPrepaidDepositAndTransaction.ok) {
            Swal.fire(i18n.t("receipt_deposit:Save successfully!"), '', 'success').then(() => {
                this.setState({ redirectToList: true })
            });
        } else {
            Swal.fire(i18n.t("receipt_deposit:Save unsuccessfully!"), response.createPrepaidDepositAndTransaction.warningText, 'warning');
        }

    }

    updateReceiveChannel(channel_list) {
        let totalReceive = 0;
        let upload = {};
        channel_list.forEach((channel, index) => {
            if (channel.slug === "small-change" || channel.slug === "receipt-deposit") {
                totalReceive -= parseFloat(channel.pay);
            } else {
                totalReceive += parseFloat(channel.pay);
            }
            if (channel.image) {
                upload[index] = channel.image;
            }
        });
        this.setState({
            receive_channel: channel_list,
            totalReceive: totalReceive,
            upload: upload,
        });
    }

    updatePayChannel(channel_list) {
        let totalPay = 0;
        let upload = {};
        channel_list.forEach((channel, index) => {
            if (channel.slug === "small-change") {
                totalPay -= parseFloat(channel.pay);
            } else {
                totalPay += parseFloat(channel.pay);
            }
            if (channel.image) {
                upload[index] = channel.image;
            }
        });
        this.setState({
            payChannel: channel_list,
            totalPay: totalPay,
            upload: upload,
        });
    }

    onError() {
        this.setState({ loading: false });
        Swal.fire('Error!', i18n.t("receipt_deposit:Please try again"), 'warning')
    }

    getTaxAmount(transaction) {
        let tax_amount = 0;
        if (this.state.taxType === 'INCLUDE_TAX') {
            tax_amount = (transaction.price * ((transaction.vat) / 100))
        } else if (this.state.taxType === 'EXCLUDE_TAX' || this.state.taxType === 'NO_TAX') {
            tax_amount = transaction.price
        }
        return tax_amount
    }

    getSumAmount() {
        let sum_amount = 0;
        this.state.transaction_list.forEach((transaction) => {
            // sum_amount += Math.round(transaction.price)
            sum_amount += parseFloat(transaction.price)
        });
        return sum_amount
    }

    getSumVat() {
        if (this.state.taxType !== 'NO_TAX') {
            if (this.state.taxType === 'INCLUDE_TAX') {
                return this.state.transaction_list[0].vatAmount;
            } else {
                return this.state.transaction_list[0].vatAmount;
            }
        } else {
            return 0
        }

    }

    getTotal() {
        let total = this.state.total;
        if (this.state.taxType !== 'NO_TAX') {
            if (this.state.taxType === 'EXCLUDE_TAX' || this.state.taxType === 'INCLUDE_TAX') {
                total = this.state.total + parseFloat(this.getSumVat())
            }

        }
        return (total - this.getSumWht()).toFixed(2)
    }

    getSumWht() {
        let sum_wht = 0;
        this.state.transaction_list.forEach((transaction) => {
            sum_wht += parseFloat(transaction.price) * ((transaction.whtRatePercent || 0) / 100)
        });
        return sum_wht
    }

    getData = (pay_group) => {
        let name = "";
        let address = "";
        if (pay_group === "agent") {
            name = this.props.contact.refNumber + " " + this.props.contact.agentName
            address = this.props.contact.agentAddress +
                ` ${this.props.contact.agentDistrict}` +
                ` ${this.props.contact.agentCity}` +
                ` ${this.props.contact.agentProvince}` +
                ` ${this.props.contact.agentPostalCode}`
        } else if (pay_group === "rent") {
            name = this.props.contact.refNumber + " " + this.props.contact.renterName
            address = this.props.contact.renterAddress +
                ` ${this.props.contact.renterDistrict}` +
                ` ${this.props.contact.renterCity}` +
                ` ${this.props.contact.renterProvince}` +
                ` ${this.props.contact.renterPostalCode}`
        } else {
            name = this.props.contact.refNumber + " " + this.props.contact.ownerName
            address = this.props.contact.mailingAddress +
                ` ${this.props.contact.mailingDistrict}` +
                ` ${this.props.contact.mailingCity}` +
                ` ${this.props.contact.mailingProvince}` +
                ` ${this.props.contact.mailingPostalCode}`
        }

        this.setState({ pay_group: pay_group, name: name, address: address })
    }

    addressData = (address) => {
        return (
            this.props.contact.registeredProvince.includes('กรุงเทพ') || this.props.contact.registeredProvince.includes('กทม') ? (
                <>
                    {this.props.contact.registeredAddress}
                    {this.props.contact.registeredDistrict && ` แขวง${this.props.contact.registeredDistrict}`}
                    {this.props.contact.registeredCity && ` เขต${this.props.contact.registeredCity}`}
                    {this.props.contact.registeredProvince && ` ${this.props.contact.registeredProvince} `}
                    {this.props.contact.registeredPostalCode}
                </>
            ) : (
                <>
                    {this.props.contact.registeredAddress}
                    {this.props.contact.registeredDistrict && ` ตำบล${this.props.contact.registeredDistrict}`}
                    {this.props.contact.registeredCity && ` อำเภอ${this.props.contact.registeredCity}`}
                    {this.props.contact.registeredProvince && ` จังหวัด${this.props.contact.registeredProvince} `}
                    {this.props.contact.registeredPostalCode}
                </>
            )
        )
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to={this.props.backLink} />
        }

        return (
            <form onSubmit={this.submitFrom} id="receipt_deposit-create-form">
                <div className="container-fluid box">
                    <div className="row">
                        <div className="col">
                            <h3 className="mb-4">
                                <Link to={this.props.backLink}>
                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                        alt="back" className="back" />
                                </Link>
                                <Translation>
                                    {
                                        t => <span>{t(this.props.headerWord)}</span>
                                    }
                                </Translation>
                            </h3>
                        </div>
                    </div>
                    <div className="content-inner">
                        <div className="row">
                            {this.props.isHasAgentRole && 
                            <SelectRoleAccounting
                                handleChangeSelect={(value) => this.getData(value)}
                                value={this.state.pay_group}
                            />
                            }
                            <div className="col">
                                <Translation>{t => <p><strong>{this.props.category === 'prepaid_deposit' ? t("receipt_deposit:Creditor") : t("receipt_deposit:Customer")}</strong></p>}</Translation>
                                <div className="input-group">
                                    <input type="text" className="form-control"
                                        value={this.state.name}
                                        disabled />
                                </div>
                            </div>
                            <div className="col">
                                <Translation>{t => <p><strong>{t("receipt_deposit:No.")}</strong></p>}</Translation>
                                <div className="input-group">
                                    <input type="text" className="form-control"
                                        value={getDocumentNumber.get(this.state.category, 'XXXX', this.state.issued_date)}
                                        disabled />
                                </div>
                            </div>
                            <div className="col">
                                <Translation>{t => <p><strong>{t("receipt_deposit:Date_out")}</strong></p>}</Translation>
                                <div className="input-group">
                                    {
                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ?
                                            <DatePickerAdapter
                                                name="issuedDate" className="form-control"
                                                selected={this.state.issuedDate}
                                                minDate={this.state.temp_month_before}
                                                // maxDate={this.state.temp_month_after}
                                                onChange={this.handleInputChange}
                                                required={true}
                                            />
                                            :
                                            <DatePickerAdapter
                                                name="issuedDate" className="form-control"
                                                selected={this.state.issuedDate}
                                                maxDate={this.state.dueDate}
                                                onChange={this.handleInputChange}
                                                required={true}
                                            />
                                    }


                                </div>
                            </div>
                            {this.props.openVat && localStorage.getItem('project_have_vat') === 'true' &&
                                <div className="col">
                                    <Translation>{t => <p><strong>{t("receipt_deposit:Type of tax")}</strong></p>}</Translation>
                                    <div className="input-group">
                                        <Translation>
                                            {t =>
                                                <select className="form-control" name="taxType"
                                                    value={this.state.taxType}
                                                    onChange={this.handleInputChange}>
                                                    <option value="NO_TAX">{t("receipt_deposit:No Tax")}</option>
                                                    <option value="EXCLUDE_TAX">{t("receipt_deposit:Exclude Tax")}</option>
                                                    <option value="INCLUDE_TAX">{t("receipt_deposit:Include Tax")}</option>
                                                </select>}
                                        </Translation>
                                    </div>
                                </div>
                            }
                        </div>

                        <hr />

                        {this.props.category === 'prepaid_deposit' &&
                            <React.Fragment>

                                <div className="row form-group mt-3">
                                    <div className="col">
                                        <label htmlFor="tax_invoice_number"
                                            className="mr-5">
                                            <Translation>{t => <strong>{t("receipt_deposit:Tax number")}</strong>}</Translation>
                                        </label>
                                        <input type="text" className="form-control" id="tax_invoice_number"
                                            value={this.state.tax_invoice_number}
                                            onChange={this.handleInputChange} name="tax_invoice_number" maxLength="30"
                                        //    required={((this.props.contact && this.props.contact.typeOfSupplier === 'PERSON') || !this.props.contact) ? false : 'required'}
                                        />
                                         <strong className="text-danger font-small"> <Translation>{t => t("PurchaseCreate:*Enter text no more than 30 characters")}</Translation> {this.state.tax_invoice_number.length} / 30</strong>
                                    </div>

                                    {this.state.tax_invoice_number &&
                                        <div className="col">
                                            <label htmlFor="taxDate"
                                                className="mr-5">
                                                <Translation>{t => <strong>{t("receipt_deposit:Dated")}</strong>}</Translation>
                                            </label>
                                            <div className="input-group">
                                                <DatePickerAdapter
                                                    name="tax_invoice_date" className="form-control"
                                                    selected={this.state.tax_invoice_date}
                                                    onChange={this.handleInputChange}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    }

                                    <div className="col">
                                        <label htmlFor="ref_number" className="mr-5">
                                            <Translation>{t => <strong>{t("receipt_deposit:Reference number")}</strong>}</Translation>
                                        </label>

                                        <input type="text" className="form-control" id="ref_number"
                                            value={this.state.ref_number}
                                            onChange={this.handleInputChange} name="ref_number" />
                                    </div>
                                    <div className="col">
                                        <UploadFileInput
                                            handleInputDocument={this.handleInputDocument}
                                            documentUpload={this.state.document_upload}
                                        />
                                    </div>
                                </div>

                                <hr />
                            </React.Fragment>
                        }

                        <div className="row">
                            <div className="col">
                                <Translation>{t => <p><strong>{t("receipt_deposit:Address")}</strong></p>}</Translation>
                                {
                                    this.props.customerList.length === 1 ?
                                        <p>{this.state.address}</p> : <p>-</p>
                                }
                            </div>
                        </div>

                        <div className="fade-up">
                            <table className="table table-hover mt-2">
                                <thead className="thead-light">
                                    <tr>
                                        <Translation>{t => <th>{t("receipt_deposit:List")}</th>}</Translation>
                                        {this.props.category === "prepaid_deposit" ?
                                            <Translation>{t=><th>{t("receipt_deposit:Description")} <span className="text-danger">*{t("PrepaidDepositCreate:The information in this section will be displayed in the withholding tax certificate.")}</span></th>}</Translation>
                                            :
                                            <Translation>{t => <th>{t("receipt_deposit:Description")}</th>}</Translation>
                                        }
                                        <Translation>{t=><th className="text-right">{ !(this.state.taxType === 'NO_TAX') ? t("PrepaidDepositCreate:Sum before tax"):  t("PrepaidDepositCreate:Sum")}</th>}</Translation>
                                        {!(this.state.taxType === 'NO_TAX') && this.props.openVat &&
                                            <Translation>{t => <th className="text-right">{t("receipt_deposit:Tax")}</th>}</Translation>
                                        }


                                        {this.state.taxType === 'INCLUDE_TAX' ?
                                            <Translation>
                                                {t =>
                                                    <th width="100" className="text-right">
                                                        {t("receipt_deposit:Tax amount")}
                                                    </th>}
                                            </Translation> :
                                            <th />
                                        }
                                        {this.props.openWht && <Translation>{t => <th width="100" className="text-right">{t("receipt_deposit:Withholding")}</th>}</Translation>}

                                        <th />

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.transaction_list.map((transaction, index) => (
                                            <tr key={"transaction-" + index}>
                                          
                                                    <td width="100">
                                                        <SearchSelect onChange={this.handleInputChange}
                                                            value={transaction.chartOfAccount.id}
                                                            name="chartOfAccount" placeholder="รายการ"
                                                            data-id={index} data-key="transaction_list"
                                                            queryObject={this.props.receiptDepositQuery.viewer.allChartOfAccount.edges}
                                                            keyOfValue="id" require={true}
                                                            keyOfLabel="chartOfAccountCode:name" />
                                                    </td>
                                            
                                                <td width="100">
                                                    <input type="text" className="form-control" data-id={index}
                                                        data-key="transaction_list" name="description"
                                                        value={transaction.description || ""}
                                                        onChange={this.handleInputChange} required />
                                                </td>

                                                <td width="50">
                                                    <input type="number" className="form-control text-right"
                                                        data-id={index}
                                                        data-key="transaction_list" name="price"
                                                        value={transaction.price} onChange={this.handleInputChange}
                                                        required />
                                                </td>

                                                {!(this.state.taxType === 'NO_TAX') && this.props.openVat &&
                                                    <td width="20">
                                                        <select className="form-control" value={transaction.vat || ""}
                                                            data-id={index} data-key="transaction_list" name="vat"
                                                            disabled={!this.props.project_have_vat}
                                                            onChange={this.handleInputChange}>
                                                            <Translation>{t => <option value="">{t("receipt_deposit:None")}</option>}</Translation>
                                                            <option value="0">VAT 0%</option>
                                                            <option value="7">VAT 7%</option>
                                                        </select>
                                                    </td>
                                                }

                                                {/* {this.state.taxType !== 'INCLUDE_TAX' && 
                                                <td width="20" className="text-right">
                                                    {
                                                        <span>{numberWithComma(this.getTaxAmount(transaction))}</span>
                                                    }
                                                </td>
                                                } */}

                                                {this.state.taxType !== 'INCLUDE_TAX' &&
                                                    <td width="20" className="text-right"></td>
                                                }

                                                {this.state.taxType === 'INCLUDE_TAX' &&
                                                    <td width="20" className="text-right">
                                                        {numberWithComma(transaction.vatAmount || 0, "0.00")}
                                                        {/* <input type="number" className="form-control text-right"
                                                            data-id={index}
                                                            data-key="transaction_list" name="vatAmount"
                                                            value={transaction.vatAmount} onChange={this.handleInputChange}
                                                            required /> */}
                                                    </td>
                                                }

                                                {
                                                    this.props.openWht &&
                                                    <td width="20">
                                                        <select className="form-control"
                                                            value={transaction.whtRatePercent || 0}
                                                            data-id={index} data-key="transaction_list"
                                                            name="whtRatePercent"
                                                            onChange={this.handleInputChange}>
                                                            <Translation>{t => <option value={""}>{t("receipt_deposit:None")}</option>}</Translation>
                                                            <option value={1}>1%</option>
                                                            <option value={1.5}>1.5%</option>
                                                            <option value={2}>2%</option>
                                                            <option value={3}>3%</option>
                                                            <option value={5}>5%</option>
                                                            <option value={10}>10%</option>
                                                            <option value={15}>15%</option>
                                                        </select>
                                                    </td>
                                                }
                                                <td width="20" className="align-middle text-center">
                                                    <InvoiceDelete transaction={transaction}
                                                        transaction_list={this.state.transaction_list}
                                                        setTransactionList={this.setTransactionList} />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                            {/*<button type="button" className="btn add-button add" onClick={this.appendNewRow}>
                                +{i18next.t("settingAll:Add list")}
                            </button>*/}
                        </div>

                        <div className="row mt-4">
                            <div className="col">
                                <Translation>{t => <label htmlFor="note"><strong>{t("receipt_deposit:Note")}</strong></label>}</Translation>
                                <textarea className="form-control" rows="5" name="remark" value={this.state.remark}
                                    onChange={this.handleInputChange} />
                            </div>


                            <div className="col offset-6 bg-light">
                                {this.props.openVat &&
                                    <React.Fragment>
                                        <div className="row p-2 text-dark">
                                            <Translation>{t => <div className="col text-left"> {t("receipt_deposit:Amount_")}</div>}</Translation>
                                            <Translation>
                                                {t => <div
                                                    className="col text-right">{numberWithComma(this.getSumAmount())} {t("receipt_deposit:Baht")}
                                                </div>}
                                            </Translation>
                                        </div>
                                        {
                                            this.getSumVat() > 0 &&
                                            <div className="row p-2 text-dark">
                                                <Translation>{t => <div className="col text-left"> {t("receipt_deposit:VAT")}</div>}</Translation>
                                                <Translation>
                                                    {t => <div
                                                        className="col text-right">{numberWithComma(this.getSumVat())} {t("receipt_deposit:Baht")}
                                                    </div>}
                                                </Translation>
                                            </div>
                                        }

                                    </React.Fragment>
                                }


                                {this.props.openWht && this.getSumWht() > 0 &&
                                    <div className="row p-2 text-dark">
                                        <Translation>{t => <div className="col text-left"> {t("receipt_deposit:Withholding tax")}</div>}</Translation>
                                        <Translation>
                                            {t => <div
                                                className="col text-right">{numberWithComma(this.getSumWht())} {t("receipt_deposit:Baht")}
                                            </div>}
                                        </Translation>
                                    </div>
                                }

                                <div className="row p-2 text-dark">
                                    <Translation>{t => <div className="col text-left"> {t("receipt_deposit:Net amount")}</div>}</Translation>
                                    <Translation>
                                        {t => <div
                                            className="col text-right"> {numberWithComma(this.getTotal())} {t("receipt_deposit:Baht")}
                                        </div>}
                                    </Translation>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col">
                                {this.state.category === 'receipt_deposit' ?
                                    <ReceiveForm updateReceiveChannel={this.updateReceiveChannel} single_payment={false}
                                        payment_type={'receive'} not_other_channel={true}
                                        credit_card_channel={true} issued_date={this.state.issuedDate}
                                        total={this.state.transaction_list[0].price} 
                                        receive_form={true}
                                        />
                                    :
                                    <PayPaymentChannelForm updatePayChannel={this.updatePayChannel}
                                        single_payment={true} payment_type={'pay'}
                                        not_other_channel={true}
                                        issued_date={this.state.issuedDate}
                                        auto_suggest={this.getTotal()}

                                    />
                                }
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col text-right mt-2 button-grp">
                                <Link to={this.props.backLink}>
                                    <Translation>
                                        {t =>
                                            <button type="button" className="btn btn-secondary add">
                                                {t("receipt_deposit:Cancel")}
                                            </button>}
                                    </Translation>
                                </Link>
                                <Translation>
                                    {t => <button type="submit" className="btn btn-primary add" disabled={this.state.loading}>
                                        {
                                            this.state.category === 'receipt_deposit' ? t("receipt_deposit:Add receipt deposit") : t("receipt_deposit:Add advance payment")
                                        }
                                        {
                                            this.state.loading &&
                                            <span className="spinner-border spinner-border-sm align-middle ml-2" />
                                        }
                                    </button>}
                                </Translation>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default ReceiptDepositCreateForm;
