/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type querySumaryOwnershipRatioQueryVariables = {||};
export type querySumaryOwnershipRatioQueryResponse = {|
  +allResidential: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +size: number,
        +ownershipRatio: ?number,
        +ownershipRatioShow: ?number,
      |}
    |}>
  |}
|};
export type querySumaryOwnershipRatioQuery = {|
  variables: querySumaryOwnershipRatioQueryVariables,
  response: querySumaryOwnershipRatioQueryResponse,
|};
*/


/*
query querySumaryOwnershipRatioQuery {
  allResidential {
    edges {
      node {
        id
        name
        size
        ownershipRatio
        ownershipRatioShow
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ResidentialNodeConnection",
    "kind": "LinkedField",
    "name": "allResidential",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "size",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownershipRatio",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownershipRatioShow",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "querySumaryOwnershipRatioQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "querySumaryOwnershipRatioQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "764c7201a1d4c2917a2cb0bff8245dd7",
    "id": null,
    "metadata": {},
    "name": "querySumaryOwnershipRatioQuery",
    "operationKind": "query",
    "text": "query querySumaryOwnershipRatioQuery {\n  allResidential {\n    edges {\n      node {\n        id\n        name\n        size\n        ownershipRatio\n        ownershipRatioShow\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '99c01247bbebe922d4bfb0ea2c2045a6';

module.exports = node;
