import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import OtherReceiver from './otherReceiver';
import OtherReceiverAll from './otherReceiverAll'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import { Translation } from 'react-i18next';
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperOtherReceiverAllQuery($id_in: [String]!, $ref_In: [String]) {
        selfProject {
            id
            name
            address
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        allOtherReceive(id_In: $id_in) {
            totalCount
            edges {
                node {
                id
                docNumber
                total
                taxType
                issuedDate
                creator
                status
                remark
                voidRemark
                refNumber
                updated
                payGroup
                firstName lastName
                contact {
                    name
                    refNumber
                    firstName
                    lastName
                    registeredName
                    registeredAddress
                    registeredCountry
                    registeredProvince
                    registeredCity
                    registeredDistrict
                    registeredPostalCode
                    typeOfContact
                    typeOfSupplier
                    typeOfCompany
                    nameBranch
                    taxIdNumber
                    email
                    phone
                    residential{
                    size
                    ownershipRatio
                    }
                }
                unknownContact
                otherReceiveTransaction {
                edges {
                    node {
                        id
                        chartOfAccount {
                            chartOfAccountCode
                            name
                        }
                        productAndService {
                            productCode
                            name
                        }
                        keycardPerOtherReceiveTransaction{
                            edges {
                                node {
                                id
                                numberKey{
                                    id
                                    noNumber
                                    }
                                }
                            }
                        }
                        description
                        unitItems
                        price
                        total
                        discount
                        vat
                        preTaxAmount
                        whtRate
                        whtRatePercent
                        accountRecord{
                            id
                        }
                    }
                }
            }
                creditCardTransaction{
                edges{
                    node{
                        id
                        amount
                    }
                }
                }
                bankAccountTransaction{
                edges{
                    node{
                        id
                        price
                        date
                        imageSlip
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
                }
                cashTransaction{
                    edges{
                        node{
                            id
                            price
                        }
                    }
                }
                chequeDeposit{
                    edges{
                        node{
                            id
                            date
                            bankName
                            price
                            chequeNumber
                            branchNumber
                        }
                    }
                }

                }
            }
        }
        # cashTransactionViewer{
        #     allCashTransaction(ref_In: $ref_In){
        #         edges{
        #             node{
        #                 id
        #                 description
        #                 price
        #             }
        #         }
        #     }
        # }
        # chequeDepositViewer{
        #     allChequeDeposit(ref_In: $ref_In){
        #         edges{
        #             node {
        #                 id
        #                 price
        #                 date
        #                 chequeNumber
        #                 bankName
        #                 branchNumber
        #             }
        #         }
        #     }
        # }

        # allBankAccountTransaction(ref_In: $ref_In) {
        #     edges{
        #         node {
        #             id
        #             price
        #             date
        #             imageSlip
        #             bankAccount {
        #                 bankName
        #                 accountNumber
        #             }
        #         }
        #     }
        # }

        allAccountRecordGroup(ref_In: $ref_In) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }

        userConfig {
            id 
            languages
        }
    }
`;

class WrapperOtherReceiverAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type: 'all'
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Other receive</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>

                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeAll"
                                       onChange={this.handleInputChange}
                                       value="all"
                                       checked={this.state.check_type === 'all'}
                                       />
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeAll">
                                        {t("document:All")}
                                    </label>}
                                </Translation>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeManuscript"
                                       value="manuscript"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'manuscript'}
                                       />
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeManuscript">
                                        {t("document:Original_")}
                                    </label>}
                                </Translation>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeCopy"
                                       value="copy"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'copy'}
                                       />
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeCopy">
                                        {t("document:Copy_")}
                                    </label>}
                                </Translation>
                            </div>
                        </div>
                    </div>

                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{
                        id_in:this.props.location.state.checkList,
                        ref_In:this.props.location.state.docNumber
                    }}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            let allAccountRecordGroup = props.allAccountRecordGroup.edges;
                            let allOtherReceive = props.allOtherReceive.edges;

                            // Merge the arrays
                            let mergedRecords = allOtherReceive.map((item, index) => {
                                return {
                                    ...item,
                                    node: {
                                        ...item.node,
                                        accountRecordGroup: allAccountRecordGroup[index] ? allAccountRecordGroup[index].node : null
                                    }
                                };
                            });
                             return (
                                <div>
                                    {mergedRecords.map((or, index) => {
                                        return (
                                            <div key={index}>
                                              <OtherReceiverAll
                                                query={or.node}
                                                bank_transaction={or.node.bankAccountTransaction.edges}  
                                                cash_transaction={or.node.cashTransaction.edges} 
                                                cheque_transaction={or.node.chequeDeposit.edges}
                                                credit_card_transaction={or.node.creditCardTransaction.edges}
                                                selfProject={props.selfProject}
                                                userConfig={props.userConfig}
                                                allAccountRecordGroup={or.node.accountRecordGroup.accountRecord.edges}
                                                otherTransaction = {or.node.accountRecordGroup}
                                                check_type={this.state.check_type}
                                            />
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperOtherReceiverAll;
