/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type PrepaidDepositStatus = "ACTIVE" | "USE_ALL" | "USE_PARTIAL" | "VOID" | "%future added value";
export type flowOfPrepaidDepositReportQueryVariables = {|
  first?: ?number,
  last?: ?number,
  start_date?: ?any,
  end_date?: ?any,
  search?: ?string,
  contact_id?: ?string,
  status?: ?string,
|};
export type flowOfPrepaidDepositReportQueryResponse = {|
  +prepaidDepositViewer: ?{|
    +allPrepaidDeposit: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +status: PrepaidDepositStatus,
          +docNumber: string,
          +issuedDate: any,
          +contact: {|
            +name: string,
            +firstName: string,
            +lastName: string,
            +refNumber: string,
            +typeOfContact: ContactTypeOfContact,
          |},
          +prepaidDepositTransaction: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +chartOfAccount: {|
                  +chartOfAccountCode: string,
                  +name: string,
                |},
                +description: string,
                +total: number,
                +prepaidDepositTransactionUsed: ?{|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +amount: ?number,
                      +purchaseRecord: ?{|
                        +purchaseRecordGroup: {|
                          +docNumber: string,
                          +issuedDate: any,
                        |}
                      |},
                    |}
                  |}>
                |},
              |}
            |}>
          |},
        |}
      |}>,
    |}
  |}
|};
export type flowOfPrepaidDepositReportQuery = {|
  variables: flowOfPrepaidDepositReportQueryVariables,
  response: flowOfPrepaidDepositReportQueryResponse,
|};
*/


/*
query flowOfPrepaidDepositReportQuery(
  $first: Int
  $last: Int
  $start_date: DateTime
  $end_date: DateTime
  $search: String
  $contact_id: String
  $status: String
) {
  prepaidDepositViewer {
    allPrepaidDeposit(first: $first, last: $last, startDate: $start_date, endDate: $end_date, search: $search, status_In: "active, use_all, use_partial, void", order: "doc_number", contact: $contact_id, status: $status) {
      totalCount
      edges {
        node {
          id
          status
          docNumber
          issuedDate
          contact {
            name
            firstName
            lastName
            refNumber
            typeOfContact
            id
          }
          prepaidDepositTransaction {
            edges {
              node {
                id
                chartOfAccount {
                  chartOfAccountCode
                  name
                  id
                }
                description
                total
                prepaidDepositTransactionUsed {
                  edges {
                    node {
                      id
                      amount
                      purchaseRecord {
                        purchaseRecordGroup {
                          docNumber
                          issuedDate
                          id
                        }
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = [
  {
    "kind": "Variable",
    "name": "contact",
    "variableName": "contact_id"
  },
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": "doc_number"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  },
  {
    "kind": "Literal",
    "name": "status_In",
    "value": "active, use_all, use_partial, void"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "flowOfPrepaidDepositReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PrepaidDepositViewer",
        "kind": "LinkedField",
        "name": "prepaidDepositViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "PrepaidDepositNodeConnection",
            "kind": "LinkedField",
            "name": "allPrepaidDeposit",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PrepaidDepositNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrepaidDepositNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PrepaidDepositTransactionNodeConnection",
                        "kind": "LinkedField",
                        "name": "prepaidDepositTransaction",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PrepaidDepositTransactionNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PrepaidDepositTransactionNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ChartOfAccountNode",
                                    "kind": "LinkedField",
                                    "name": "chartOfAccount",
                                    "plural": false,
                                    "selections": [
                                      (v18/*: any*/),
                                      (v13/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v19/*: any*/),
                                  (v20/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "prepaidDepositTransactionUsed",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PrepaidDepositTransactionUsedNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v9/*: any*/),
                                              (v21/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "PurchaseRecordNode",
                                                "kind": "LinkedField",
                                                "name": "purchaseRecord",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "PurchaseRecordGroupNode",
                                                    "kind": "LinkedField",
                                                    "name": "purchaseRecordGroup",
                                                    "plural": false,
                                                    "selections": [
                                                      (v11/*: any*/),
                                                      (v12/*: any*/)
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "flowOfPrepaidDepositReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PrepaidDepositViewer",
        "kind": "LinkedField",
        "name": "prepaidDepositViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "PrepaidDepositNodeConnection",
            "kind": "LinkedField",
            "name": "allPrepaidDeposit",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PrepaidDepositNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrepaidDepositNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PrepaidDepositTransactionNodeConnection",
                        "kind": "LinkedField",
                        "name": "prepaidDepositTransaction",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PrepaidDepositTransactionNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PrepaidDepositTransactionNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ChartOfAccountNode",
                                    "kind": "LinkedField",
                                    "name": "chartOfAccount",
                                    "plural": false,
                                    "selections": [
                                      (v18/*: any*/),
                                      (v13/*: any*/),
                                      (v9/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v19/*: any*/),
                                  (v20/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "prepaidDepositTransactionUsed",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PrepaidDepositTransactionUsedNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v9/*: any*/),
                                              (v21/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "PurchaseRecordNode",
                                                "kind": "LinkedField",
                                                "name": "purchaseRecord",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "PurchaseRecordGroupNode",
                                                    "kind": "LinkedField",
                                                    "name": "purchaseRecordGroup",
                                                    "plural": false,
                                                    "selections": [
                                                      (v11/*: any*/),
                                                      (v12/*: any*/),
                                                      (v9/*: any*/)
                                                    ],
                                                    "storageKey": null
                                                  },
                                                  (v9/*: any*/)
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a61043d242abeddbcc42ad08a43ecbd7",
    "id": null,
    "metadata": {},
    "name": "flowOfPrepaidDepositReportQuery",
    "operationKind": "query",
    "text": "query flowOfPrepaidDepositReportQuery(\n  $first: Int\n  $last: Int\n  $start_date: DateTime\n  $end_date: DateTime\n  $search: String\n  $contact_id: String\n  $status: String\n) {\n  prepaidDepositViewer {\n    allPrepaidDeposit(first: $first, last: $last, startDate: $start_date, endDate: $end_date, search: $search, status_In: \"active, use_all, use_partial, void\", order: \"doc_number\", contact: $contact_id, status: $status) {\n      totalCount\n      edges {\n        node {\n          id\n          status\n          docNumber\n          issuedDate\n          contact {\n            name\n            firstName\n            lastName\n            refNumber\n            typeOfContact\n            id\n          }\n          prepaidDepositTransaction {\n            edges {\n              node {\n                id\n                chartOfAccount {\n                  chartOfAccountCode\n                  name\n                  id\n                }\n                description\n                total\n                prepaidDepositTransactionUsed {\n                  edges {\n                    node {\n                      id\n                      amount\n                      purchaseRecord {\n                        purchaseRecordGroup {\n                          docNumber\n                          issuedDate\n                          id\n                        }\n                        id\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a8f4fc5a06b648d823034b1bd32780fb';

module.exports = node;
