import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';

class SelectNamePrefix extends Component{
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        if(this.props.name){
            this.props.callBack(e)
        }else{
            this.props.callBack(e.target.value)
        }
    }

    render(){
        return(
            <Translation>
                {t =>
                    <select className="form-control dropdown-custom-arrow"
                        value={this.props.value}
                        onChange={this.handleChange}
                        required={this.props.required ? this.props.required : false}
                        name={this.props.name ? this.props.name :"namePrefix"}
                        index={this.props.index}
                        >
                        <option value="">{t("staff:Please select an item")}</option>
                        {/* <option value="คุณ">คุณ</option> */}
                        <option value="นาย">{t("preFixName:Mr.")}</option>
                        <option value="นาง">{t("preFixName:Mrs.")}</option>
                        <option value="นางสาว">{t("preFixName:Miss")}</option>
                        {/* <option value="เด็กชาย">เด็กชาย</option>
                        <option value="เด็กหญิง">เด็กหญิง</option> */}
                        {/* <option value="Miss">Miss</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Master">Master</option> */}
                       
                        {this.props.residential &&
                            <React.Fragment>
                                <option value="บริษัท">{t("preFixName:Limited company, Public company limited,Limited partnership,Ordinary partership")}</option>
                                <option value="other_input">{t("preFixName:Specify")}</option>
                            </React.Fragment>
                        }
                        {
                            this.props.needOtherInput &&
                            <option value="other_input">{t("preFixName:Specify")}</option>
                        }
                </select>}
            </Translation>
        );
    }


}
export default SelectNamePrefix;