import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import environment from "../../../env/environment";
import i18n from "i18next";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { decode } from 'base-64';
import Select from 'react-select';
import upload from '../../../libs/upload'
import { format } from 'date-fns';
import jwtDecode from "jwt-decode";
import DatePicker from "react-datepicker";
import { fetchQuery } from 'relay-runtime';

import _ from 'lodash';
import APICliam from '../../../api/claim'
import i18next from 'i18next';
const allResidential = graphql`
    query createClaimQuery {
        allJuristic {
            totalCount
            edges {
                node {
                    id
                    firstName
                    lastName
                    role
                    user {
                        id
                        username
                        lastLogin
                    }
                }
            }
        }
        allResidential {
            totalCount
            edges {
                node {
                    id
                    name
                    floor
                    size
                    status
                    residentialHouseholder{
                        edges{
                            node{
                                id
                                tenant{
                                    id
                                    firstName
                                    lastName
                                    countries
                                    phone
                                    user{
                                        id
                                    }
                                }
                            }
                        }
                    }
                    type{
                        id
                        name
                    }
                }
            }
        }

    }
`;

class CreateClaimRequset extends Component {

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
    }

    componentWillMount() {
        this.getHomeAll()
    }


    async dropDownChanged(e) {
        this.setState({ loadingRequestBy: true, RequestBy: "" })
        let listHouseHolder = []
        listHouseHolder = this.state.homeAll.filter((n) => n.node.id === e.target.value)
        let moreNameRequest = {
            value: "other",
            label: "ระบุเอง"
        }

        if (listHouseHolder) {
            let dataClone = []
            await listHouseHolder[0].node.residentialHouseholder.edges.forEach((n, index) => {
                _.set(dataClone, '[' + index + '].value', n.node.tenant.id);
                _.set(dataClone, '[' + index + '].label', n.node.tenant.firstName + " " + n.node.tenant.lastName);
                _.set(dataClone, '[' + index + '].phone', n.node.tenant.phone)
            })
            this.setState({
                [e.target.name]: e.target.value,
                residentialHouseholder: [...dataClone, moreNameRequest],
                RequestBy: "", loadingRequestBy: false, phone_contact: ''
            })
        }
    }

    getHomeAll() {
        fetchQuery(environment, allResidential)
            .then(response_data => {
                let jwt = jwtDecode(localStorage.getItem("token"))
                let jurusticID = response_data.allJuristic.edges.filter((n) => n.node.user.username === jwt.username)
                let juristic_contact_id = decode(jurusticID[0]?.node.id).split("JuristicNode:")
                this.setState({
                    residential: response_data.allResidential?.edges?.map((n) => ({ ...n, label: n.node.name, value: n.node.id })),
                    loadingDetails: false,
                    juristic_contact_id: parseInt(juristic_contact_id[1]) || null,
                    juristic_contact: response_data.allJuristic?.edges?.map((n) => ({ ...n, label: n.node.firstName + " " + n.node.lastName, value: n.node.id }))
                });
            });
    }


    //UPLOADFILE
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/claim/" + path, file);
            resolve(res);
        });
    };
    uploadFile(e, file_type) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];

        if (files) {
            if (files?.size > 2097152) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }
            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .png, .jpg, .jpeg ${i18next.t("Allaction:Only")} ${i18next.t("Allaction:and each file should not exceed")} 2 MB (${i18next.t("Allaction:maximum of")} 3 ${i18next.t("Allaction:images")} )`, 'error')
            }
            else {
                let jwt = jwtDecode(localStorage.getItem("token"))
                let new_file = new File([files], `${format(new Date(), "claim_web_" + jwt.user_id + "_YYYYMMDDHHmmss")}${Math.floor(Math.random() * 1000000001)}${files.name}`);

                this.callUpload(file_type === "image" ? "image" : 'file', new_file).then((data) => {
                    if (file_type === "image") {
                        let fileOldList = _.cloneDeep(this.state.image);
                        let dese = "claim/image/" + new_file.name
                        fileOldList.push({ "file_name": new_file.name, "file_upload": dese, "file_type": file_type, "viewPtath": data.location })
                        this.setState({ image: fileOldList })
                    } else {
                        let fileOldList = _.cloneDeep(this.state.file);
                        let dese = "claim/file/" + new_file.name
                        fileOldList.push({ "file_name": new_file.name, "file_upload": dese, "file_type": file_type, "viewPtath": data.location })
                        this.setState({ file: fileOldList })
                    }
                    // fileOldList.push({ fileId: "", fileName: new_file.name, filePath: dese, status: "", viewPtath: data.location })
                });
            }
        }

    }


    async onChangeRequestBy(e) {
        await this.setState({ other_contact: '' })
        if (e.value === "other" || e.value === "0") {
            this.setState({ RequestBy: e.value, isRequestKey: true, phone_contact: "", other_contact: '', loadingContact: false })

        } else {
            let value = decode(e.value).split(":")
            let phone = e.phone ? e.phone : ""
            this.setState({ phone_contact: phone, RequestBy: value[1], isRequestKey: false, other_contact: '', loadingContact: false })
        }
    }

    //ลบไฟล์ หรือ ลบรูปภาพ
    dateFile = (index, data) => {
        let DataFile = _.cloneDeep(this.state.file)
        DataFile.splice(index, 1)
        this.setState({ file: DataFile })
    }

    //อัพเดท ข้อมูลในรายการตาราง
    onChangeTable(e, index) {
        let asset = _.cloneDeep(this.state.deTailProperty)
        let name = e.target.name
        let value = e.target.value
        let data = _.set(asset, `[${index}].${name}`, value)
        this.setState({ deTailProperty: data })
    }

    //เลือกยูนิต
    onChageResidential(dataResidential) {
        let residential_id = decode(dataResidential.value).split("ResidentialNode:")
        let tenant = dataResidential.node.residentialHouseholder?.edges?.map((n) => ({ ...n, value: n.node.id, label: n.node.tenant.firstName + " " + n.node.tenant.lastName }))

        this.setState({
            residential_id: parseInt(residential_id[1]),
            tenant_contact_id: "", loadingContact: false,
            tenant_contact: [...tenant, { value: "other_contact", label: 'ระบุเอง' }],
            phone_contact: ""
        })
    }

    //เลือกผู้แจ้ง
    onChageTenant(dataTenant) {
        let tenant_contact_id = dataTenant.value !== "other_contact" ? decode(dataTenant.node.tenant.id).split("TenantNode:") : ""
        let phone = dataTenant?.node?.tenant?.phone || ""
        let jwt = jwtDecode(localStorage.getItem("token"))

        this.setState({
            tenant_contact_id: dataTenant.value === "other_contact" ? null : parseInt(tenant_contact_id[1]), loadingContact: false,
            tenant_contact_selct: dataTenant.value === "other_contact" ? false : true,
            phone_contact: phone,
            juristic_contact_id: ""
        })
    }

    onChageJuristic(dataJuristic) {
        let juristic_contact_id = dataJuristic.value !== "other_contact" ? decode(dataJuristic.node.id).split("JuristicNode:") : ""
        // let phone = dataJuristic?.node?.juristic?.phone || ""
        let jwt = jwtDecode(localStorage.getItem("token"))

        this.setState({
            tenant_contact_id: "",
            juristic_contact_id: dataJuristic.value === "other_contact" ? null : parseInt(juristic_contact_id[1]), loadingContact: false,
            juristic_contact_selct: dataJuristic.value === "other_contact" ? false : true,
            residential_id: ""
            // phone_contact: phone
        })
    }



    AddListAssets() {
        let asset = _.cloneDeep(this.state.deTailProperty)
        let assetFilter = asset.filter((n) => n.assets === "")
        if (asset.length === 0 || assetFilter.length < 1) {
            asset.push({ "assets": "", "description": "" })
            this.setState({ deTailProperty: asset })
        }
    }
    removeListAssets(index, data) {

        let asset = _.cloneDeep(this.state.deTailProperty)
        asset.splice(index, 1)
        this.setState({ deTailProperty: asset })
    }

    onChanges(e) {
        let name = e.target.name
        let value = e.target.value || ""

        this.setState({ [name]: value })
    }

    deleteImage(data, index) {
        let imageData = _.cloneDeep(this.state.image)
        imageData.splice(index, 1)
        this.setState({ image: imageData })
    }

    constructor(props) {
        super(props);

        this.state = {
            loadingDetails: true,
            listDocumentMore: [
                { id: 1, idName: "uploadFile1", label: "Choose1", status: true },
                { id: 2, idName: "uploadFile2", label: "Choose2", status: true },
                { id: 3, idName: "uploadFile3", label: "Choose3", status: true },
                { id: 4, idName: "uploadFile4", label: "Choose4", status: true },
                { id: 5, idName: "uploadFile5", label: "Choose5", status: true },
                { id: 6, idName: "uploadFile6", label: "Choose6", status: true },
                { id: 7, idName: "uploadFile7", label: "Choose7", status: true },
                { id: 8, idName: "uploadFile8", label: "Choose8", status: true },
                { id: 9, idName: "uploadFile9", label: "Choose9", status: true },
            ],
            amountToClaim: null,

            file: [],
            claim_type: 'private',
            residential: [],
            residential_id: null,

            tenant_contact: [],
            juristic_contact: [],
            tenant_contact_selct: true,
            juristic_contact_selct: true,

            tenant_contact_id: null,
            juristic_contact_id: null,
            other_contact: "",
            phone_contact: "",
            header: "",
            description: "",
            issued_date: new Date(),
            incident_date: new Date(),
            deTailProperty: [],
            image: [],
            btnClick: ""

        }
        this.uploadFile = this.uploadFile.bind(this)
        this.AddListAssets = this.AddListAssets.bind(this)
        this.removeListAssets = this.removeListAssets.bind(this)
        this.onChageResidential = this.onChageResidential.bind(this)
        this.onChageTenant = this.onChageTenant.bind(this)
        this.onChageJuristic = this.onChageJuristic.bind(this)
        this.onChanges = this.onChanges.bind(this)
        this.onChangeTable = this.onChangeTable.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.deleteImage = this.deleteImage.bind(this)
    }

    render() {

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            <div className="container-fluid  claim" >
                                <div className='create'>
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4 mt-8">
                                                <Link to="/purchasing/claim/all">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span style={{ fontWeight: '400' }}>{t("claimRequst:ClaimCreate")}</span>

                                            </h3>
                                        </div>
                                    </div>
                                    {
                                        <React.Fragment>
                                            <div className='row'>
                                                <div className="col-10 mx-auto">
                                                    <form onSubmit={this.onSubmit}>
                                                        <div>
                                                            <br /><br />

                                                            <section className='HeaderForCommon'>
                                                                <React.Fragment>
                                                                    <p style={{ fontSize: '18px' }}>{t("claimRequst:Choose Type To Claim For Juristic Personal To Keep Data History Claim's Project")}</p>
                                                                    <div style={{ display: 'inline' }}>
                                                                        <label className='labelInput'>
                                                                            <input type={"radio"} name='claim_type' value={"private"} onChange={this.onChanges}
                                                                                checked={this.state.claim_type === "private"} className='mr-2' />
                                                                            {t("claimRequst:PrivateClaim")}</label>
                                                                        <label style={{ marginLeft: "190px" }}></label>

                                                                        <label className='labelInput'>
                                                                            <input type={"radio"} name='claim_type' value={"public"} onChange={this.onChanges}
                                                                                checked={this.state.claim_type === "public"} className='mr-2' />
                                                                            {t("claimRequst:CommonClaim")}</label>
                                                                    </div>
                                                                    <hr />
                                                                </React.Fragment>
                                                            </section>

                                                            <section className='GeneralInformation mt-5'>
                                                                {this.state.claim_type === "private" ?
                                                                    // แจ้งเคลมส่วนตัว
                                                                    <div className='GeneralInformation'>
                                                                        <h3>{t("claimRequst:GeneralInfo")}</h3>
                                                                        <hr />
                                                                        <section className='findUnit'>
                                                                            <label>{t("claimRequst:FindUnit")} <span className='text-red'>*</span></label>
                                                                            <div style={{ position: 'relative' }}>
                                                                                {
                                                                                    this.state.loadingDetails == false ?
                                                                                        <div>
                                                                                            <Select
                                                                                                classNamePrefix='inputBox'
                                                                                                options={this.state.residential}
                                                                                                onChange={this.onChageResidential}
                                                                                                required={true}
                                                                                                isSearchable={false}
                                                                                                placeholder={t("claimRequst:FindUnit")}
                                                                                            />
                                                                                            <input name="residential_id" type="text" value={this.state.residential_id || ""}
                                                                                                style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                                onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                                required={this.state.residential_id} />
                                                                                        </div>
                                                                                        :
                                                                                        <Select
                                                                                            isSearchable={false}
                                                                                            placeholder={"Loading..."}
                                                                                        />
                                                                                }
                                                                            </div>
                                                                        </section>

                                                                        <section className='findNameRequest'>
                                                                            <label>{t("claimRequst:Choose Who's Request")} <span className='text-red'>*</span></label>
                                                                            <div style={{ position: 'relative' }}>
                                                                                {
                                                                                    this.state.loadingContact == false ?
                                                                                        <div>
                                                                                            <Select
                                                                                                classNamePrefix='inputBox'
                                                                                                options={this.state.tenant_contact}
                                                                                                onChange={this.onChageTenant}
                                                                                                required={true}
                                                                                                isSearchable={false}
                                                                                                placeholder={t("claimRequst:Choose Who's Request")}
                                                                                            />
                                                                                            <input name="tenant_contact_id" type="text" value={this.state.tenant_contact_id || ""}
                                                                                                style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                                onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                                required={this.state.tenant_contact_id} />
                                                                                        </div>
                                                                                        :
                                                                                        <Select
                                                                                            isSearchable={false}
                                                                                            placeholder={"Loading..."}
                                                                                        />
                                                                                }
                                                                            </div>

                                                                            {
                                                                                this.state.tenant_contact_selct == false &&
                                                                                <input className='form-control inputBox mt-3'
                                                                                    type={"text"} name='' defaultValue={this.state.other_contact}
                                                                                    onChange={(e) => this.setState({ other_contact: e.target.value })}
                                                                                    onKeyUp={(e) => this.setState({ other_contact: e.target.value })}
                                                                                    required={!this.state.tenant_contact_selct} />
                                                                            }
                                                                        </section>

                                                                        <section className='phone_contract'>
                                                                            <label>{"เบอร์ติดต่อผู้แจ้ง "} </label>
                                                                            <input className='form-control inputBox'
                                                                                type={"text"} name='' defaultValue={this.state.phone_contact}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกตัวเลขเท่านั้น')}
                                                                                onInput={e => e.target.setCustomValidity('')}
                                                                                pattern='[0-9]+$'
                                                                                onChange={(e) => this.setState({ phone_contact: e.target.value })}
                                                                                onKeyUp={(e) => this.setState({ phone_contact: e.target.value })}
                                                                                maxLength={10} />
                                                                        </section>
                                                                    </div>
                                                                    :
                                                                    //แจ้งเคลมส่วนกลาง
                                                                    <div className='GeneralInformation'>
                                                                        <h3>{t("claimRequst:GeneralInfo")}</h3>
                                                                        <hr />
                                                                        <section className='findUnit'>
                                                                            <label>{t("claimRequst:Choose Who's Request")} <span className='text-red'>*</span></label>
                                                                            <div style={{ position: 'relative' }}>
                                                                                {
                                                                                    this.state.loadingDetails == false ?
                                                                                        <div>
                                                                                            <Select
                                                                                                classNamePrefix='inputBox'
                                                                                                options={this.state.juristic_contact}
                                                                                                onChange={this.onChageJuristic}
                                                                                                required={true}
                                                                                                isSearchable={false}
                                                                                                placeholder={t("claimRequst:Choose Who's Request")}
                                                                                            />
                                                                                            <input name="residential_id" type="text" value={this.state.juristic_contact_id || ""}
                                                                                                style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                                onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                                required={this.state.juristic_contact_id} />
                                                                                        </div>
                                                                                        :
                                                                                        <Select
                                                                                            isSearchable={false}
                                                                                            placeholder={"Loading..."}
                                                                                        />
                                                                                }
                                                                            </div>
                                                                        </section>

                                                                        <section className='phone_contract'>
                                                                            <label>{t("claimRequst:Phone")} </label>
                                                                            <input className='form-control inputBox'
                                                                                type={"text"} name='' defaultValue={this.state.phone_contact}
                                                                                maxLength={10}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกตัวเลขเท่านั้น')}
                                                                                onInput={e => e.target.setCustomValidity('')}
                                                                                pattern='[0-9]+$'
                                                                                onChange={(e) => this.setState({ phone_contact: e.target.value })}
                                                                                onKeyUp={(e) => this.setState({ phone_contact: e.target.value })} />
                                                                        </section>
                                                                    </div>
                                                                }
                                                            </section>

                                                            <div className='detailCliam'>
                                                                <h3 className='mt-10'>{t("claimRequst:Claim Info")}</h3>
                                                                <hr />

                                                                <section className='header'>
                                                                    <label>{t("claimRequst:Claim Title")} <span className='text-red'>*</span></label>
                                                                    <input className='form-control inputBox'
                                                                        type={"text"} name='header' defaultValue={this.state.header}
                                                                        onChange={(e) => this.setState({ header: e.target.value })}
                                                                        onKeyUp={(e) => this.setState({ header: e.target.value })}
                                                                        required={true} />
                                                                </section>

                                                                <section className='description'>
                                                                    <label>{t("claimRequst:Claim Detail")} <span className='text-red'></span></label>
                                                                    <textarea value={this.state.description} className='form-control textareaBox'
                                                                        onChange={(e) => this.setState({ description: e.target.value })}
                                                                        onKeyDown={(e) => this.setState({ description: e.target.value })} />

                                                                </section>

                                                                <section className='incident_date'>
                                                                    <label>{t("claimRequst:Incident Date")} <span className='text-red'>*</span></label>
                                                                    <br />
                                                                    <DatePicker
                                                                        name='incident_date'
                                                                        className="form-control inputBox"
                                                                        selected={this.state.incident_date || new Date()}
                                                                        maxDate={new Date()}
                                                                        onChange={(e) => this.setState({ incident_date: e })}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        placeholderText='dd/mm/yyyy'
                                                                    />
                                                                </section>

                                                                <section className='issued_date'>
                                                                    <label>{t("claimRequst:Claim Request Date")} <span className='text-red'></span></label>
                                                                    <br />
                                                                    <DatePicker
                                                                        name='issued_dateDate'
                                                                        className="form-control inputBox"
                                                                        selected={this.state.issued_date}
                                                                        maxDate={new Date()}
                                                                        onChange={(e) => this.setState({ issued_date: e })}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        placeholderText='dd/mm/yyyy'
                                                                        readOnly={true}
                                                                    />
                                                                </section>

                                                            </div>

                                                            <div className='detailAsset'>
                                                                <h3 className='mt-10'>{t("claimRequst:Assets Related")}</h3>
                                                                <hr />

                                                                <label className='label16 mt-2 mb-5' >{t("claimRequst:Lists Asset")} <span className='text-red'>*</span></label>

                                                                <table className='table table-border'>
                                                                    <thead className='bg-gray'>
                                                                        <tr>
                                                                            <th style={{ width: '30%' }}>{t("claimRequst:Lists")}</th>
                                                                            <th style={{ width: '70%' }}>{t("claimRequst:Detail")}</th>
                                                                            <th width={50}></th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <React.Fragment>
                                                                            {
                                                                                this.state.deTailProperty.length > 0 ?
                                                                                    this.state.deTailProperty?.map((n, assetsIndex) => {
                                                                                        return (
                                                                                            <tr key={assetsIndex}>
                                                                                                <td><input type={"text"} required={true} name='assets' className='form-control' defaultValue={n.assets} onChange={(e) => this.onChangeTable(e, assetsIndex)} /></td>
                                                                                                <td><input type={"text"} required={true} name='description' className='form-control' defaultValue={n.description} onChange={(e) => this.onChangeTable(e, assetsIndex)} /></td>
                                                                                                <td className='text-center' onClick={() => this.removeListAssets(assetsIndex, n)}>
                                                                                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/delete.png"} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                    :
                                                                                    <tr className='text-center'>
                                                                                        <td colSpan={3}>{t("claimRequst:The damaged property has not yet been identified.")}</td>
                                                                                    </tr>
                                                                            }
                                                                        </React.Fragment>
                                                                    </tbody>
                                                                </table>
                                                                <label className='cursor label16 mt-5' onClick={this.AddListAssets}>
                                                                    <span className='colorBlue'>
                                                                        + {t("claimRequst:Add Lists")}
                                                                    </span>
                                                                </label>

                                                            </div>

                                                            <div className='UploadImage mt-10'>
                                                                <label className='headerLabel18'>{t("claimRequst:Photo Assets Broken")} <span className='text-red'>*</span></label>
                                                                <br />
                                                                <div className='mt-5' style={{ display: 'inline-flex', pointerEvents: this.state.image.length >= 10 ? 'none' : 'all' }}>
                                                                    <label htmlFor="UploadImage" className="upload-btn-wrapper  ml-0 mt-2"
                                                                    >

                                                                        <button type='button'
                                                                            style={{ fontWeight: 400, fontSize: 14 }}
                                                                            className={this.state.image.length >= 10 ? "btn width110 disabled" : "btn width110 line-lightgray"}>
                                                                            <img style={{ marginRight: 5 }} src={process.env.PUBLIC_URL + '/images/icons/defualt.png'} />{t("claimRequst:Choose Photo")} </button>
                                                                        <input
                                                                            type="file" name='file' id="UploadImage"
                                                                            className='form-control uploadFile'
                                                                            placeholder='ระบุเบอร์โทร'
                                                                            accept='application/.pdf,.png,.jpg'
                                                                            required={true}
                                                                            onChange={(e) => this.uploadFile(e, "image")}
                                                                            style={{ height: "30px" }}
                                                                            defaultValue={this.state.image[0]?.file_name || ""}
                                                                        />
                                                                        <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px', fontWeight: 300 }}>
                                                                        {t("claimRequst:Support")} </span>
                                                                    </label>
                                                                </div>
                                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                                    {
                                                                        this.state.image?.length <= 3 &&
                                                                        this.state.image?.map((n, inx) => {
                                                                            return (
                                                                                // <Lightbox src={n.file_upload} />
                                                                                <div>
                                                                                    <img src={n.viewPtath} style={{ width: 100, height: 100, marginRight: 10 }} />
                                                                                    <button className='btnDeleteImage' type={"button"} onClick={() => this.deleteImage(n, inx)}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} style={{ width: 20, height: 20 }} />
                                                                                    </button>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>


                                                            <section className='UploadFile mt-10'>
                                                                <label className='headerLabel28'>{t("claimRequst:Choose File")} <span className='text-red'></span></label>
                                                                <hr /> <br />
                                                                {
                                                                    this.state.listDocumentMore?.map((n, inx) => {
                                                                        let type = inx + 1
                                                                        return (
                                                                            <React.Fragment key={inx}>
                                                                                <div key={inx} style={{ display: 'inline-flex' }}>
                                                                                    <label className='headerLabel18 w370'>{`${inx + 1}. ${t("claimRequst:"+n.label)}`} </label>
                                                                                    <label htmlFor={n.idName} className="upload-btn-wrapper">
                                                                                        <button className={"btn"}>
                                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />{t("silvermanguard:Choose File")} </button>
                                                                                        <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>
                                                                                            {t("claimRequst:Support")} </span>
                                                                                        <input
                                                                                            type="file" name='file' id={n.idName}
                                                                                            className='form-control uploadFile'
                                                                                            placeholder='ระบุเบอร์โทร'
                                                                                            accept='application/.pdf,.png,.jpg'
                                                                                            onChange={(e) => this.uploadFile(e, n.id)}
                                                                                        />
                                                                                    </label>
                                                                                </div>
                                                                                <br className='mb-2' />
                                                                                {
                                                                                    this.state.file?.filter((chk) => chk.file_type === type || chk.file_type === type.toString()).map((file, inxw) => {
                                                                                        if (file.file_name) {
                                                                                            let typeFile = file.file_name.split(".")
                                                                                            return (
                                                                                                <p key={inxw} style={{ marginTop: -20 }}>
                                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;
                                                                                                    <Link
                                                                                                        to="route"
                                                                                                        onClick={(event) => {
                                                                                                            event.preventDefault();
                                                                                                            window.open(
                                                                                                                typeof file.file_upload === "string" || file.viewPtath
                                                                                                                    ? file.viewPtath ? file.viewPtath : file.file_upload
                                                                                                                    : URL.createObjectURL(file.viewPtath ? file.viewPtath : file.file_upload)
                                                                                                            )
                                                                                                        }}
                                                                                                        target="blank">{file.file_name}</Link>
                                                                                                    &nbsp;&nbsp; <label onClick={() => this.dateFile(inxw, file)}>
                                                                                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} style={{ width: 20, height: 20 }} />
                                                                                                    </label>
                                                                                                </p>
                                                                                            )
                                                                                        }
                                                                                        return;
                                                                                    })
                                                                                }
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                }


                                                            </section>


                                                            <div className='ResultClaim mt-10'>
                                                                <label className='headerLabel28'>{t("claimRequst:Claims Summary")}</label>
                                                                <hr /> <br />
                                                                <label className='headerLabel18'>{t("claimRequst:Amount To Claim")} <span className='text-red'> * </span> {t("claimRequst:(Bath)")}</label>
                                                                <br />
                                                                <input type={"number"} name='amountCliam'
                                                                    defaultValue={this.state.amountToClaim}
                                                                    placeholder='0.00'
                                                                    className='form-control inputBox text-right' style={{ width: 222 }}
                                                                    onKeyUp={(e) => this.setState({ amountToClaim: e.target.value })}
                                                                    onChange={(e) => this.setState({ amountToClaim: e.target.value })}
                                                                    required={true}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='row mt-2'>
                                                            <div className='col-12 text-right'>
                                                                <button type='submit' onClick={() => this.setState({ btnClick: 'draft' })} className='btn btn-gray-outline m-2'>{t("claimRequst:Draft")}</button>

                                                                <button type='submit' onClick={() => this.setState({ btnClick: 'new' })} className='btn btn-primary m-2'>{t("claimRequst:Submit A Claim")}</button>
                                                            </div>
                                                        </div>

                                                    </form>

                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }


                                </div>



                            </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }

    // Save Data
    onSubmit(e) {
        e.preventDefault();
        let {
            header,
            description,
            residential_id,
            tenant_contact_id,
            juristic_contact_id,
            phone_contact,
            other_contact,
            incident_date,
            issued_date,
            deTailProperty,
            image,
            file,
            claim_type,
            amountToClaim,
            btnClick
        } = this.state;

        var bodyFormData = new FormData();
        bodyFormData.append('status', btnClick);
        bodyFormData.append('header', header);
        bodyFormData.append('description', description);
        bodyFormData.append('claim_type', claim_type);
        bodyFormData.append('other_contact', other_contact);
        bodyFormData.append('phone_contact', phone_contact);
        if (residential_id) {
            bodyFormData.append('residential_id', residential_id)
            bodyFormData.append('tenant_contact_id', tenant_contact_id)
        } else if (juristic_contact_id) {
            bodyFormData.append('juristic_contact_id', juristic_contact_id);
        }
        bodyFormData.append('issued_date', format(issued_date, "DD/MM/YYYY"));
        bodyFormData.append('incident_date', format(incident_date, "DD/MM/YYYY"));
        bodyFormData.append('claim_amount', parseInt(amountToClaim));

        file.forEach((ele, inx) => {
            bodyFormData.append('file_claim', JSON.stringify(ele));
        })
        image.forEach((ele, inx) => {
            bodyFormData.append('image_claim', JSON.stringify(ele));
        })
        deTailProperty.forEach((ele, inx) => {
            bodyFormData.append('asset_claim', JSON.stringify(ele));

        })






        APICliam.SaveDataClaim(bodyFormData).then((res) => {
            if (res.data.statusCode === "0000") {
                Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                    this.props.history.push("/purchasing/claim/all")
                })
            } else {
                Swal.fire(i18n.t('settingAccount:Failed to save'), '', 'warning').then(() => {
                    return
                })
            }
        }, () => {
            Swal.fire(i18n.t('settingAccount:Failed to save'), '', 'warning').then(() => {
                return
            })
        })
    }
}

export default CreateClaimRequset;
