import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import NoMatch from "../../../components/noMatch";
import {Route, Switch} from "react-router-dom";
import PrepaidDepositList from './prepaidDepositList';
import PrepaidDepositCreate from './prepaidDepositCreate';
import prepaidDepositView from "./prepaidDepositView";


class PettyCash extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={PrepaidDepositList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status/:id?`} component={PrepaidDepositCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/view/:id`} component={prepaidDepositView}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default PettyCash;