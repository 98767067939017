/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type expensePieChartsQueryVariables = {|
  startDateFirst?: ?any,
  endDateFirst?: ?any,
  startDateSecond?: ?any,
  endDateSecond?: ?any,
|};
export type expensePieChartsQueryResponse = {|
  +chartOfAccountSummaryIncomeExpense: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +chartOfAccountCode: string,
        +yearFirst: ?number,
        +yearSecond: ?number,
        +totalHistoricalDiffFirst: ?number,
        +totalHistoricalDiffSecond: ?number,
      |}
    |}>
  |}
|};
export type expensePieChartsQuery = {|
  variables: expensePieChartsQueryVariables,
  response: expensePieChartsQueryResponse,
|};
*/


/*
query expensePieChartsQuery(
  $startDateFirst: DateTime
  $endDateFirst: DateTime
  $startDateSecond: DateTime
  $endDateSecond: DateTime
) {
  chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: "5", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
    edges {
      node {
        chartOfAccountCode
        yearFirst
        yearSecond
        totalHistoricalDiffFirst
        totalHistoricalDiffSecond
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDateFirst"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDateSecond"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDateFirst"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDateSecond"
},
v4 = [
  {
    "kind": "Literal",
    "name": "chartOfAccountCode_Istartswith",
    "value": "5"
  },
  {
    "kind": "Variable",
    "name": "endDateFirst",
    "variableName": "endDateFirst"
  },
  {
    "kind": "Variable",
    "name": "endDateSecond",
    "variableName": "endDateSecond"
  },
  {
    "kind": "Variable",
    "name": "startDateFirst",
    "variableName": "startDateFirst"
  },
  {
    "kind": "Variable",
    "name": "startDateSecond",
    "variableName": "startDateSecond"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yearFirst",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yearSecond",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalHistoricalDiffFirst",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalHistoricalDiffSecond",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "expensePieChartsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ChartOfAccountNodeConnection",
        "kind": "LinkedField",
        "name": "chartOfAccountSummaryIncomeExpense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "expensePieChartsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ChartOfAccountNodeConnection",
        "kind": "LinkedField",
        "name": "chartOfAccountSummaryIncomeExpense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cd049c40862048d3bd0637bde2037042",
    "id": null,
    "metadata": {},
    "name": "expensePieChartsQuery",
    "operationKind": "query",
    "text": "query expensePieChartsQuery(\n  $startDateFirst: DateTime\n  $endDateFirst: DateTime\n  $startDateSecond: DateTime\n  $endDateSecond: DateTime\n) {\n  chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: \"5\", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {\n    edges {\n      node {\n        chartOfAccountCode\n        yearFirst\n        yearSecond\n        totalHistoricalDiffFirst\n        totalHistoricalDiffSecond\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c7729d816548a5042d125e39ac820fcb';

module.exports = node;
