/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type modalSelectNumberQueryVariables = {|
  productAndServiceId?: ?string,
  noNumber?: ?string,
|};
export type modalSelectNumberQueryResponse = {|
  +allKeycard: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +noNumber: ?string,
        +keycardPerInvoice: ?{|
          +totalCount: ?number
        |},
        +keycardPerOtherReceiveTransaction: ?{|
          +totalCount: ?number
        |},
      |}
    |}>
  |}
|};
export type modalSelectNumberQuery = {|
  variables: modalSelectNumberQueryVariables,
  response: modalSelectNumberQueryResponse,
|};
*/


/*
query modalSelectNumberQuery(
  $productAndServiceId: String
  $noNumber: String
) {
  allKeycard(productAndServiceId: $productAndServiceId, noNumber: $noNumber) {
    edges {
      node {
        id
        noNumber
        keycardPerInvoice {
          totalCount
        }
        keycardPerOtherReceiveTransaction {
          totalCount
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "noNumber"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productAndServiceId"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "noNumber",
        "variableName": "noNumber"
      },
      {
        "kind": "Variable",
        "name": "productAndServiceId",
        "variableName": "productAndServiceId"
      }
    ],
    "concreteType": "NumberKeyNodeConnection",
    "kind": "LinkedField",
    "name": "allKeycard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NumberKeyNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NumberKeyNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "noNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "KeycardPerInvoiceNodeConnection",
                "kind": "LinkedField",
                "name": "keycardPerInvoice",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "KeycardPerOtherReceiveTransactionNodeConnection",
                "kind": "LinkedField",
                "name": "keycardPerOtherReceiveTransaction",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "modalSelectNumberQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "modalSelectNumberQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "884d77b8977190ddc1665cdcfa76e68b",
    "id": null,
    "metadata": {},
    "name": "modalSelectNumberQuery",
    "operationKind": "query",
    "text": "query modalSelectNumberQuery(\n  $productAndServiceId: String\n  $noNumber: String\n) {\n  allKeycard(productAndServiceId: $productAndServiceId, noNumber: $noNumber) {\n    edges {\n      node {\n        id\n        noNumber\n        keycardPerInvoice {\n          totalCount\n        }\n        keycardPerOtherReceiveTransaction {\n          totalCount\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c430f791b30ab8fa4f305a5e1642f345';

module.exports = node;
