import React from 'react';
import { QueryRenderer } from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import localStorage from "../../../libs/localstorage";
import getNameResidential from "../../../libs/getNameResidential";
import Loading from "../../../libs/loading"
import i18next from 'i18next';


class PurchaseListTable extends ComponentPagination {

    // calPaid(total, purchase) {
    //     let amountUse = 0;
    //     purchase.contact.prepaidDeposit.edges.forEach((rd_group) => {
    //         rd_group.node.prepaidDepositTransaction.edges.forEach((rd_trans) => {
    //             rd_trans.node.prepaidDepositTransactionUsed.edges.forEach((rd_use) => {
    //                 if (rd_use.node.purchaseRecord.purchaseRecordGroup.id === purchase.id) {
    //                     amountUse += rd_use.node.amount;
    //                 }
    //             });
    //         });
    //     });
    //     return total - amountUse
    // }

    sumPrepaidUse(purchase) {
        let amountUse = 0;
        if (purchase.prepaidDepositTransactionUsed.edges.length > 0) {
            purchase.prepaidDepositTransactionUsed.edges.forEach((pr_use) => {
                amountUse += pr_use.node.amount
            });
        }
        return amountUse
    }

    getColorAndText(object) {
        let text = '';
        let color = 'text-center';
        switch (object.node.status) {
            case 'ACTIVE':
                text = 'ยังไม่จ่ายชำระ';
                break;
            case 'WAITING_PAY':
                text = 'เตรียมจ่าย';
                break;
            case 'PAID':
                text = 'จ่ายชำระแล้ว';
                break;
            case 'VOID':
                text = 'รายการที่ยกเลิก';
                color = 'text-center text-danger';
                break;
            default:
                text = 'จ่ายชำระแล้วบางส่วน';
        }
        return <td className={color}>{text}</td>
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={this.props.query}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status
                }}
                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return <React.Fragment>
                            <div className="table-responsive fade-up card" id="purchaseListTable">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <input type="checkbox"
                                                    onChange={() => this.getAllList(props.allPurchaseRecordGroup.edges)}
                                                    checked={this.state.check_all} />
                                            </th>
                                            <th className="text-center" width={130}>{i18next.t("PurchaseList:No.")}</th>
                                            <th className="text-center">{i18next.t("PurchaseList:Date")}</th>
                                            <th className="text-center">{i18next.t("PurchaseList:Payable code")}</th>
                                            {/*<th>เลขห้อง</th>*/}
                                            <th>{i18next.t("PurchaseList:Name")}</th>
                                            <th>{i18next.t("PurchaseList:Description")}</th>
                                            <th className="text-right">{i18next.t("PurchaseList:Amount")}</th>
                                            {/*<th className="text-right">จำนวนเงินรวม</th>*/}

                                            {this.props.status === 'partial_payment' &&
                                                <React.Fragment>
                                                    <th className="text-right">{i18next.t("PurchaseList:Amount received")}</th>
                                                    <th className="text-right">{i18next.t("PurchaseList:Amount owed")}</th>
                                                </React.Fragment>
                                            }

                                            <th className="text-center">{i18next.t("PurchaseList:Due date")}</th>
                                            {this.props.status === 'void' &&
                                                <th className="text-center">{i18next.t("PurchaseList:Canceled date")}</th>
                                            }
                                            {this.props.status === 'all' &&
                                                <th className="text-center">{i18next.t("PurchaseList:Status")}</th>
                                            }
                                            <th className="text-center">{i18next.t("PurchaseList:By")}</th>
                                            {this.props.status !== 'void' &&
                                                <th className="text-center">{i18next.t("PurchaseList:Payment document")}</th>
                                            }
                                            {this.props.status === 'void' &&
                                                <th className="text-center">{i18next.t("PurchaseList:Canceled By")}</th>
                                            }
                                            {this.props.status === 'void' &&
                                                <th>{i18next.t("PurchaseList:Reason")}</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {props.allPurchaseRecordGroup.edges.map((purchase, index) => {
                                            let order_id = this.state.first - localStorage.getPageLimit();
                                            let accumulate = 0;
                                            return (
                                                purchase.node.purchaseRecord.edges.map((purchase_record, t_index) => {
                                                    let ref_doc_number_list = [];
                                                    accumulate = accumulate + purchase_record.node.total;
                                                    let row_number = (t_index === 0 && order_id + index + 1);

                                                    if (this.state.product_and_service &&
                                                        this.state.product_and_service !== purchase_record.node.productAndService.id) {
                                                        return true;
                                                    } else if (this.state.product_and_service &&
                                                        this.state.product_and_service === purchase_record.node.productAndService.id) {
                                                        accumulate = purchase_record.node.total;
                                                        row_number = index + 1;
                                                        // number_of_transaction = t_index + 1;
                                                    }

                                                    // คำนวนจำนวนเงินหน้า list
                                                    let vat = purchase_record.node.vatAmount || 0;
                                                    let total_prepaid_deposit = parseFloat(purchase_record.node.preTaxAmount);
                                                    let total_amount = total_prepaid_deposit + vat
                                                    // คำนวนจำนวนเงินหน้า list

                                                    if (purchase_record.node.prepaidDepositTransactionUsed.edges.length !== 0) {
                                                        purchase_record.node.prepaidDepositTransactionUsed.edges.forEach((prepaid_deposit_used) => {
                                                            ref_doc_number_list.push({
                                                                type: 'prepaid_deposit',
                                                                id: prepaid_deposit_used.node.prepaidDeposit.id,
                                                                doc_number: prepaid_deposit_used.node.prepaidDeposit.docNumber
                                                            });
                                                        })
                                                    }

                                                    if (purchase_record.node.acceptCreditNoteTransaction.edges.length !== 0) {
                                                        purchase_record.node.acceptCreditNoteTransaction.edges.forEach((accept_credit_note) => {
                                                            ref_doc_number_list.push({
                                                                type: 'accept_credit_note',
                                                                id: accept_credit_note.node.id,
                                                                doc_number: accept_credit_note.node.docNumber
                                                            });

                                                        })
                                                    }

                                                    if (purchase_record.node.payRecord.edges.length !== 0) {
                                                        purchase_record.node.payRecord.edges.forEach((pay) => {
                                                            if (pay.node.payRecordGroup.status !== 'VOID') {
                                                                ref_doc_number_list.push({
                                                                    type: 'pay',
                                                                    id: pay.node.payRecordGroup.id,
                                                                    doc_number: pay.node.payRecordGroup.docNumber
                                                                });
                                                            }
                                                        })
                                                    }
                                                    return (
                                                        <tr key={purchase_record.node.id}>
                                                            <td>
                                                                {row_number &&
                                                                    <input type="checkbox"
                                                                        onChange={() => this.appendToCheckList(purchase.node.id)}
                                                                        checked={this.checkList(purchase.node.id)} />
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {purchase.node.status === "VOID" ?
                                                                    purchase.node.docNumber :
                                                                    <Link
                                                                        to={"/accounting/expense/purchase/purchase-detail/" + purchase.node.id}
                                                                        >{purchase.node.docNumber}
                                                                    </Link>
                                                                }
                                                            </td>
                                                            <td className="text-center">{format(purchase.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                            <td className="text-center">{purchase.node.contact.refNumber}</td>
                                                            {/*<td>{purchase.node.contact.typeOfContact === 'RESIDENTIAL' ? purchase.node.contact.name : '-'} </td>*/}
                                                            <td>{purchase.node.contact.typeOfContact === 'RESIDENTIAL' ? getNameResidential(purchase.node.contact.firstName, purchase.node.contact.lastName) : purchase.node.contact.name} </td>
                                                            <td>{purchase_record.node.description.length >= 80 ? purchase_record.node.description.substring(0, 80) + '...' : purchase_record.node.description}</td>
                                                            <td className="text-right">{numberWithComma(total_amount)}</td>
                                                            {/*<td className="text-right">*/}
                                                            {/*    {t_index === number_of_transaction - 1 && numberWithComma(accumulate)}*/}
                                                            {/*</td>*/}

                                                            {this.props.status === 'partial_payment' &&
                                                                <React.Fragment>
                                                                    <td className="text-right">{numberWithComma(purchase_record.node.paid)}</td>
                                                                    <td className="text-right">{numberWithComma((purchase_record.node.total - purchase_record.node.paid) || 0)}</td>
                                                                </React.Fragment>
                                                            }
                                                            <td className="text-center">{format(purchase.node.dueDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                            {this.props.status === 'all' &&
                                                                this.getColorAndText(purchase)
                                                            }
                                                            {this.props.status === 'void' &&
                                                                <td className="text-center">{format(purchase.node.updated, 'DD/MM/YYYY', { locale: thLocale })}</td>

                                                            }
                                                            <td className="text-center">{purchase.node.creator || '-'}</td>
                                                            {purchase.node.status !== "VOID"?
                                                            <td className="text-center" width={130}>
                                                                {purchase.node.status === "PAID" || purchase.node.status === "PARTIAL_PAYMENT" ?
                                                                    <React.Fragment>
                                                                        {ref_doc_number_list.length > 0 ?
                                                                            ref_doc_number_list.map((ref_doc, index) =>
                                                                            <span key={ref_doc.id}>
                                                                                    {ref_doc.type === 'prepaid_deposit' || ref_doc.type === 'accept_credit_note' ?
                                                                                        ref_doc.type === 'prepaid_deposit' ?
                                                                                        <Link
                                                                                        to={"/accounting/expense/prepaid-deposit/view/" + ref_doc.id}
                                                                                            >
                                                                                                {ref_doc.doc_number}
                                                                                            </Link>
                                                                                            :
                                                                                            <Link
                                                                                            to={"/accounting/expense/accept-credit-note/detail/" + ref_doc.id}
                                                                                                >
                                                                                                {ref_doc.doc_number}
                                                                                            </Link>

                                                                                        :
                                                                                        <Link
                                                                                        to={"/accounting/expense/pay/detail/" + ref_doc.id + "/" + ref_doc.doc_number}
                                                                                            >
                                                                                            {ref_doc.doc_number}
                                                                                        </Link>
                                                                                    }

                                                                                    {index + 1 < ref_doc_number_list.length && ', '}
                                                                                </span>
                                                                            )
                                                                            : '-'
                                                                        }
                                                                    </React.Fragment>
                                                                    :
                                                                    '-'
                                                                }
                                                            </td>
                                                            :null
                                                            }
                                                            {this.props.status === 'void' &&
                                                                <td>{purchase.node.updater || '-'}</td>
                                                            }
                                                            {this.props.status === 'void' &&
                                                                <td>{purchase.node.voidRemark}</td>
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props.allPurchaseRecordGroup.totalCount} />
                            </div>
                        </React.Fragment>
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default PurchaseListTable;
