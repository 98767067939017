import React, {useState, useEffect} from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import "../styles/commonArea.scss";
import ComponentPagination from "../../../libs/componentPagination";
import _ from "lodash";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { Translation } from "react-i18next";
// import api from "../../../api/index";
import api from "./services";
import Swal from "sweetalert2";
import Loading from "../../../libs/loading";

const data = require("./data");

class SettingBooking extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      advanceDay: 30,
      advanceRight: 5,
      maxReservationPerRoom: 30,
      loading: true,
      permissionEdit: _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_setting_create' }) ?
        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_setting_create' }) : false
    };
    this.handleCustomValueChange= this.handleCustomValueChange.bind(this)
  }

  async componentDidMount() {
    const data = await api.getRightSetting();
    this.setState({
      advanceDay: data.advance_day || 30,
      advanceRight: data.advance_right,
      maxReservationPerRoom: data.max_reservation_per_room || 30,
      loading: false,
    });
  }

  save = async () => {
    let data = {
      advance_day: this.state.advanceDay,
      advance_right: this.state.advanceRight,
      max_reservation_per_room: this.state.maxReservationPerRoom,
      button: true,
    };
    const res = await api.setRightSetting(data);
    if (res.message === "successful") {
      Swal.fire(i18next.t("createUpdateForm:Successful record"), "", "success").then(() => {
        this.setState({
          advanceDay: data.advance_day,
          advanceRight: data.advance_right,
          maxReservationPerRoom: data.max_reservation_per_room,
        });
      });
    } else {
      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
    }
  };

  selectChange = (val) => {
    this.setState({ [val.target.name]: val.target.value });
  };

  handleCustomValueChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <Translation>
            {t =>
              !this.state.loading ? (
                <div className="container-fluid box booking-form" id="parcel">
                  <div className="row justify-content-between">
                    <div className="col">
                      <h3>
                        <Link to="/contact">
                          <img src={process.env.PUBLIC_URL + "/images/wrapperContent/back.png"} alt="back" className="back" />
                        </Link>
                        <span className="header">{t("newBookingSingha_Setting:Booking Facility setup")}</span>
                      </h3>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center  mb-3  mt-5">
                    <div className="col-8">
                      <div className="line">
                        <div className="header">{t("newBookingSingha_Setting:Advance booking setup")}</div>
                      </div>                      
                      <Select
                        data={data.setingCommonArea.advanceDay}
                        text={t("newBookingSingha_Setting:Days available for advance booking (Days)")}
                        unit={t("newBookingSingha_Setting:Days")}
                        value={this.state.advanceDay}
                        onChange={this.selectChange}
                        name={"advanceDay"}
                        disabled={!this.state.permissionEdit}
                        onCustomValueChange={this.handleCustomValueChange}
                      />                    
                      <Select
                        data={data.setingCommonArea.advanceRight}
                        text={t("newBookingSingha_Setting:Rights available for advance booking (Time)")}
                        unit={t("newBookingSingha_Setting:Time")}
                        value={this.state.advanceRight}
                        onChange={this.selectChange}
                        name={"advanceRight"}
                        disabled={!this.state.permissionEdit}
                        onCustomValueChange={this.handleCustomValueChange}
                      />                      
                      <Select
                        data={data.setingCommonArea.maxReservationPerRoom}
                        text={t("newBookingSingha_Setting:Maximum booking rights per room (Rights)")}
                        unit={t("newBookingSingha_Setting:Rights")}
                        value={this.state.maxReservationPerRoom}
                        onChange={this.selectChange}
                        name={"maxReservationPerRoom"}
                        disabled={!this.state.permissionEdit}
                        onCustomValueChange={this.handleCustomValueChange}
                      />

                      {this.state.permissionEdit &&
                        <div className="row justify-content-end" style={{ marginTop: 80 }}>
                          <React.Fragment>
                            <button
                              // style={{ width: 108, height: 46 }}
                              type="button"
                              className="btn btn-primary submit-seting"
                              onClick={this.save}>
                              {t("Allaction:save")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-primary submit-seting ml-3"
                            // style={{ width: 108, height: 46, marginLeft: 24 }}
                            >
                              {t("Allaction:cancel")}
                            </button>
                          </React.Fragment>
                        </div>
                      }

                    </div>
                  </div>
                </div>
              ) : (
                <Loading />
              )


            }
          </Translation>

        </WrapperContent>
      </Wrapper>
    );
  }
}

const Select = ({ data, text, unit, value, onChange, name, onCustomValueChange }) => {
  const [customValue, setCustomValue] = useState("");

  useEffect(() => {
    if (!data.includes(parseInt(value))) {
      setCustomValue(value);
    }
  }, [value, data]);

  const handleCustomValueChange = (e) => {
    const val = e.target.value;

    setCustomValue(val);
  };

  const handleCustomValueBlur = () => {
    onCustomValueChange(name, customValue);
  };

  const isValueInList = data.includes(parseInt(value));
  const checkValue = (item) => {
    return data.includes(parseInt(item));
  };

  return (
    <Translation>
      {t => (
        <div className="mt-2 booking-form">
          <span className="bold">{text}</span>
          <select
            className="form-control mt-1 col-8"
            id="select_right"
            value={isValueInList ? value : 'Specify'}
            onChange={onChange}
            name={name}
          >
            {data.map((item, index) => (
              item === 0 ?
                <option className="bold" value={item} key={index}>
                  {i18next.t("ReportAssetDetail:Not Limit")}
                </option>
                :
                <option className="bold" value={item} key={index}>
                  {checkValue(item) ? `${item} ${unit}` : t("generalInfo:Specify")}
                </option>
            ))}
          </select>
          {!isValueInList &&
            <input
              type="number"
              className="form-control mt-1 col-8"
              value={customValue}
              onChange={handleCustomValueChange}
              onBlur={handleCustomValueBlur}
              placeholder={t("generalInfo:Specify")}
            />
          }
        </div>
      )}
    </Translation>
  );
};

export default SettingBooking;
