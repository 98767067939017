/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type staffListQueryVariables = {|
  search?: ?string,
  first?: ?number,
  last?: ?number,
  status?: ?boolean,
  startDate?: ?any,
  endDate?: ?any,
|};
export type staffListQueryResponse = {|
  +projectStaffViewer: ?{|
    +allProjectStaff: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +image: ?string,
          +nameTitle: ?string,
          +nameStaff: string,
          +lastNameStaff: ?string,
          +role: string,
          +department: ?string,
          +dateWork: ?any,
          +endWork: ?any,
          +phone: ?string,
          +email: ?string,
          +note: ?string,
          +status: boolean,
          +projectStaffDocument: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +fileName: string,
                +fileUpload: ?string,
              |}
            |}>
          |},
        |}
      |}>,
    |}
  |}
|};
export type staffListQuery = {|
  variables: staffListQueryVariables,
  response: staffListQueryResponse,
|};
*/


/*
query staffListQuery(
  $search: String
  $first: Int
  $last: Int
  $status: Boolean
  $startDate: DateTime
  $endDate: DateTime
) {
  projectStaffViewer {
    allProjectStaff(search: $search, first: $first, last: $last, status: $status, startDate: $startDate, endDate: $endDate) {
      totalCount
      edges {
        node {
          id
          image
          nameTitle
          nameStaff
          lastNameStaff
          role
          department
          dateWork
          endWork
          phone
          email
          note
          status
          projectStaffDocument {
            edges {
              node {
                id
                fileName
                fileUpload
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "endDate"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    },
    {
      "kind": "Variable",
      "name": "startDate",
      "variableName": "startDate"
    },
    {
      "kind": "Variable",
      "name": "status",
      "variableName": "status"
    }
  ],
  "concreteType": "ProjectStaffNodeConnection",
  "kind": "LinkedField",
  "name": "allProjectStaff",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectStaffNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectStaffNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameStaff",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastNameStaff",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "department",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dateWork",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endWork",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "note",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectStaffDocumentNodeConnection",
              "kind": "LinkedField",
              "name": "projectStaffDocument",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectStaffDocumentNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectStaffDocumentNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fileName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fileUpload",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "staffListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectStaffViewer",
        "kind": "LinkedField",
        "name": "projectStaffViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "staffListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectStaffViewer",
        "kind": "LinkedField",
        "name": "projectStaffViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea6bbfef56812e9ababdf39d68020edd",
    "id": null,
    "metadata": {},
    "name": "staffListQuery",
    "operationKind": "query",
    "text": "query staffListQuery(\n  $search: String\n  $first: Int\n  $last: Int\n  $status: Boolean\n  $startDate: DateTime\n  $endDate: DateTime\n) {\n  projectStaffViewer {\n    allProjectStaff(search: $search, first: $first, last: $last, status: $status, startDate: $startDate, endDate: $endDate) {\n      totalCount\n      edges {\n        node {\n          id\n          image\n          nameTitle\n          nameStaff\n          lastNameStaff\n          role\n          department\n          dateWork\n          endWork\n          phone\n          email\n          note\n          status\n          projectStaffDocument {\n            edges {\n              node {\n                id\n                fileName\n                fileUpload\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fd80bb4b749956adfca7b9591c8d9c26';

module.exports = node;
