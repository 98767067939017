import React, {Component} from 'react';
import {commitMutation, QueryRenderer} from 'react-relay';
import environment from "../../../env/environment";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import Wrapper from "../../../components/wrapper";
import {graphql} from "babel-plugin-relay/macro";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../libs/numberWithComma";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import i18n from "i18next";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import _ from "lodash";
import { convertToTimeZone } from '../../../libs/date';
import Loading from "../../../libs/loading"
import LightBox from "../../../libs/lightBox";

const cashDetail = graphql`
    query cashDetailQuery($id: ID!) {
        cashDepositGroup(id: $id){
            id
            docNumber
            depositDate
            bank{
                accountNumber
                accountName
                bankName
            }
            image
            total
            cashDepositCashDepositGroup{
                edges{
                    node{
                        id
                        receive{
                            docNumber
                            issuedDate
                            total
                        }
                        invoice {
                            docNumber
                            total
                            issuedDate
                        }

                        receiptDeposit{
                            docNumber
                            total
                            issuedDate
                        }

                        guaranteeMoneyReceive {
                            docNumber
                            issuedDate
                            amount
                        }
                        clearAdvance{
                            docNumber
                            issuedDate
                            returnPrice
                        }
                        otherReceive{
                            docNumber
                            issuedDate
                            total
                        }
                        description
                        price
                    }
                }
            }
        }
        selfProject {
            timeZone
        }
    }
`;

const mutation = graphql`
    mutation cashDetailMutation($input: CashDepositGroupDeleteInput!){
        cashDepositGroupDelete(input:$input){
            clientMutationId
            ok
            warningText
        }
    }
`;

class CashDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            redirect: false
        };
        this.deleteGroup = this.deleteGroup.bind(this)
    }

    deleteGroup() {
        this.setState({loading: true});

        Swal.fire({
            title: i18n.t("cash:Are you sure to remove cash deposit?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: i18n.t("cash:Cancel"),
            confirmButtonText: i18n.t("cash:Yes")
        }).then((result) => {
            if (result.value) {

                let variables = {
                    input: {
                        id: this.props.match.params.id,
                    }
                };

                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if (response.cashDepositGroupDelete.ok) {
                                Swal.fire(
                                    i18n.t("cash:Success"),
                                    i18n.t("cash:Cancel cash deposit"),
                                    'success'
                                );
                                this.setState({redirect: true, loading: false});
                            } else {
                                // Swal.fire(i18n.t("cash:Remove unsuccessfully"), i18n.t("cash:Cancel cash deposit"), 'warning');
                                Swal.fire(i18n.t("cash:Remove unsuccessfully"), response.cashDepositGroupDelete.warningText, 'warning');
                                this.setState({loading: false});
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18n.t("cash:Please try again"), 'warning')
                            this.setState({loading: false});
                        },
                    },
                )

            } else {
                this.setState({loading: false});
            }
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/accounting/finance/cash/list"/>
        }

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <div className="container-fluid box">
                        <div className="row">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/finance/cash/list">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('cash:cash_deposit')+" "+"(CD)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>
                        <div className="content-inner">
                            <QueryRenderer
                                environment={environment}
                                query={cashDetail}
                                variables={{id: this.props.match.params.id}}
                                render={({error, props}) => {
                                    if (error) {
                                        return <div>{error.message}</div>;
                                    } else if (props) {
                                        return <React.Fragment>
                                            <div className="row" style ={{display:'flex',justifyContent:'flex-end'}} >
                                            { props.cashDepositGroup.cashDepositCashDepositGroup.edges.length > 0 ?
                                                    <div className = "text-right " style={{marginRight:8}} >
                                                        <Link to={{
                                                            pathname: "/document/cash-deposit-print/",
                                                            state: {select_cash_transaction: props.cashDepositGroup,view:true},
                                                        }}>
                                                            <button type="button" className="ml-3 btn btn-outline-secondary">
                                                                <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'}
                                                                            alt="print-icon"/>
                                                            </button>
                                                        </Link>
                                                    </div>
                                               :
                                                   <div className="col text-right mr-2">
                                                        <button type="button" className="ml-3 mb-2 btn btn-outline-secondary"
                                                                onClick={this.onClickPrintAll}>
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'}
                                                                            alt="print-icon"/>
                                                        </button>
                                                   </div>
                                            }
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_cash_delete'}) &&
                                                <div className = "text-right " style={{}} >
                                                    <Translation>
                                                        {t=>
                                                        <button className="btn btn-danger add mr-3"
                                                                disabled={this.state.loading}
                                                                onClick={this.deleteGroup}>
                                                            {this.state.loading && <span
                                                                className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                            {t("cash:Cancel cash deposit")}
                                                        </button>}
                                                    </Translation>
                                                </div>
                                                }
                                            </div>
                                            <div className="table-responsive fade-up">
                                                <table className="table table-hover mt-2">
                                                    <thead className="thead-light">
                                                    <Translation>
                                                        {t=>
                                                        <tr>
                                                            <th>{t("cash:No_deposit")}</th>
                                                            <th>{t("cash:Date")}</th>
                                                            <th>{t("cash:Description")}</th>
                                                            <th className="text-right">{t("cash:Amount")}</th>
                                                        </tr>}
                                                    </Translation>
                                                    </thead>
                                                    <tbody>
                                                    {props.cashDepositGroup.cashDepositCashDepositGroup.edges.map((cash_transaction) => {
                                                        let doc_number = '';
                                                        let issued_date = '';
                                                        let total = '';

                                                        if (cash_transaction.node.receive) {
                                                            doc_number = cash_transaction.node.receive.docNumber;
                                                            issued_date = cash_transaction.node.receive.issuedDate;
                                                            total = cash_transaction.node.receive.total;
                                                        } else if (cash_transaction.node.invoice) {
                                                            doc_number = cash_transaction.node.invoice.docNumber;
                                                            issued_date = cash_transaction.node.invoice.issuedDate;
                                                            total = cash_transaction.node.invoice.total;
                                                        } else if (cash_transaction.node.receiptDeposit) {
                                                            doc_number = cash_transaction.node.receiptDeposit.docNumber;
                                                            issued_date = cash_transaction.node.receiptDeposit.issuedDate;
                                                            total = cash_transaction.node.receiptDeposit.total;
                                                        } else if (cash_transaction.node.guaranteeMoneyReceive) {
                                                            doc_number = cash_transaction.node.guaranteeMoneyReceive.docNumber;
                                                            issued_date = cash_transaction.node.guaranteeMoneyReceive.issuedDate;
                                                            total = cash_transaction.node.guaranteeMoneyReceive.amount;
                                                        } else if (cash_transaction.node.clearAdvance) {
                                                            doc_number = cash_transaction.node.clearAdvance.docNumber;
                                                            issued_date = cash_transaction.node.clearAdvance.issuedDate;
                                                            total = cash_transaction.node.clearAdvance.returnPrice;
                                                        }
                                                        else if (cash_transaction.node.otherReceive) {
                                                            doc_number = cash_transaction.node.otherReceive.docNumber;
                                                            issued_date = cash_transaction.node.otherReceive.issuedDate;
                                                            total = cash_transaction.node.otherReceive.total;
                                                        }
                                                        return (
                                                            <tr key={cash_transaction.node.id}>
                                                                <td>
                                                                    {doc_number}
                                                                </td>
                                                                <td>{format(issued_date, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                                <td>{cash_transaction.node.description}</td>
                                                                <Translation>{t=><td className="text-right">{numberWithComma(cash_transaction.node.price)} {t("cash:Baht")}</td>}</Translation>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="row mt-2 fade-up">
                                                <div className="col-9"/>
                                                <div className="col-3">
                                                    <div className="col">
                                                        <div className="row p-3 mb-2 bg-light text-dark">
                                                            <Translation>{t=><div className="col text-left"><strong>{t("cash:Total deposit")}</strong></div>}</Translation>
                                                            <div
                                                                className="col text-right"><Translation>{t=><strong>{numberWithComma(props.cashDepositGroup.total)} {t("cash:Baht")}</strong>}</Translation>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-2 fade-up">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <Translation>{t=><p>{t("cash:Deposit method")}</p>}</Translation>
                                                            <input type="text" className="form-control"
                                                                   value={props.cashDepositGroup.bank.bankName + ' ' + props.cashDepositGroup.bank.accountNumber
                                                                   + ' ' +
                                                                   props.cashDepositGroup.bank.accountName}
                                                                   disabled/>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-2">
                                                            <Translation>{t=><p>{t("cash:Deposit date")}</p>}</Translation>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control"
                                                                       value={format(convertToTimeZone(props.cashDepositGroup.depositDate, props.selfProject.timeZone), 'DD/MM/YYYY', {locale: thLocale})}
                                                                       disabled/>
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <Translation>{t=><p>{t("cash:Deposit time")}</p>}</Translation>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control"
                                                                       value={format(convertToTimeZone(props.cashDepositGroup.depositDate, props.selfProject.timeZone), 'HH:mm A', {locale: thLocale})}
                                                                       disabled/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-5">
                                                        <div className="col bottom">
                                                            <div className="p-2">
                                                                {props.cashDepositGroup.image && <LightBox image={props.cashDepositGroup.image} height={'auto'} /> }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    return <Loading/>
                                }}
                            />
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default CashDetail;
