/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAndUpdateSettingFormDebitInput = {|
  settingForm: any,
  clientMutationId?: ?string,
|};
export type settingFormMutationVariables = {|
  input: CreateAndUpdateSettingFormDebitInput
|};
export type settingFormMutationResponse = {|
  +createAndUpdateSettingFormDebit: ?{|
    +ok: ?boolean,
    +message: ?string,
    +newSettingFormDebit: ?{|
      +id: string,
      +description: ?string,
      +descriptionEng: ?string,
      +descriptionSecond: ?string,
      +descriptionSecondEng: ?string,
      +paymentTimeStatus: boolean,
      +paymentTime1: ?any,
      +paymentTime2: ?any,
      +paymentCheck: boolean,
      +bankAccountStatus: boolean,
      +bankAccount: ?{|
        +id: string
      |},
      +channelDescription: ?string,
      +contactName: ?string,
      +contactPhone: ?string,
      +creatorName: ?string,
      +creatorNameEng: ?string,
      +signerName: ?string,
      +signerPosition: ?string,
      +formDebit: number,
      +numberOfDays: number,
      +refDocument: boolean,
      +refReport: boolean,
      +refCollection1: boolean,
      +refCollection2: boolean,
      +refCollection3: boolean,
    |},
  |}
|};
export type settingFormMutation = {|
  variables: settingFormMutationVariables,
  response: settingFormMutationResponse,
|};
*/


/*
mutation settingFormMutation(
  $input: CreateAndUpdateSettingFormDebitInput!
) {
  createAndUpdateSettingFormDebit(input: $input) {
    ok
    message
    newSettingFormDebit {
      id
      description
      descriptionEng
      descriptionSecond
      descriptionSecondEng
      paymentTimeStatus
      paymentTime1
      paymentTime2
      paymentCheck
      bankAccountStatus
      bankAccount {
        id
      }
      channelDescription
      contactName
      contactPhone
      creatorName
      creatorNameEng
      signerName
      signerPosition
      formDebit
      numberOfDays
      refDocument
      refReport
      refCollection1
      refCollection2
      refCollection3
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAndUpdateSettingFormDebitPayload",
    "kind": "LinkedField",
    "name": "createAndUpdateSettingFormDebit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingFormDebitNode",
        "kind": "LinkedField",
        "name": "newSettingFormDebit",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "descriptionEng",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "descriptionSecond",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "descriptionSecondEng",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentTimeStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentTime1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentTime2",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentCheck",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bankAccountStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNode",
            "kind": "LinkedField",
            "name": "bankAccount",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "channelDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactPhone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creatorName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creatorNameEng",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "signerName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "signerPosition",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "formDebit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfDays",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refDocument",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refReport",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refCollection1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refCollection2",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refCollection3",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingFormMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingFormMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "847a6dcb9e509382bd6711a522d2502a",
    "id": null,
    "metadata": {},
    "name": "settingFormMutation",
    "operationKind": "mutation",
    "text": "mutation settingFormMutation(\n  $input: CreateAndUpdateSettingFormDebitInput!\n) {\n  createAndUpdateSettingFormDebit(input: $input) {\n    ok\n    message\n    newSettingFormDebit {\n      id\n      description\n      descriptionEng\n      descriptionSecond\n      descriptionSecondEng\n      paymentTimeStatus\n      paymentTime1\n      paymentTime2\n      paymentCheck\n      bankAccountStatus\n      bankAccount {\n        id\n      }\n      channelDescription\n      contactName\n      contactPhone\n      creatorName\n      creatorNameEng\n      signerName\n      signerPosition\n      formDebit\n      numberOfDays\n      refDocument\n      refReport\n      refCollection1\n      refCollection2\n      refCollection3\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '419b7dee98024f6ce3ed5803136561e2';

module.exports = node;
