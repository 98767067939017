

import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import { Link } from "react-router-dom";

import '../styles/register.scss'

import { Translation } from 'react-i18next';
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import "../detail/styles/petDetail.scss"

import Loading from "../../libs/loading";
import SeacrhDetail from '../../components/search';
import Pagination from "../../libs/newPagination";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import i18n from "i18next";

class ResidentialhistoryListTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reQuery: false,
            search: "",
            start_date: null,
            end_date: null,
            order: "Descending",
            status: "",
        }
        // Descending 

        this.handlePropsChange = this.handlePropsChange.bind(this);
    }

    getColorAndText(object) {
        let text = "";
        let color = "";
        switch (object.node.type) {
            case "OWNER":
                text =  i18n.t("PageListHistoryResident:Owner");
                color = "tag_dark";
                break;
            case "RESIDENT":
                text = i18n.t("PageListHistoryResident:Residents");
                color = "tag_dark";
                break;
            case "RENTER":
                text = i18n.t("PageListHistoryResident:Rental");
                color = "tag_grey_04";
                break;
            case "AGENT":
                text = i18n.t("PageListHistoryResident:Agent");
                color = "tag_agent";
                break;
            default:
                text = i18n.t("PageListHistoryResident:Other");
                color = "tag_dark";
        }
        return <td className={"text-center tag"}><span className={color}>{text}</span></td>;
    }


    getStatusColorAndText(object) {
        let text = "";
        let color = "text-center";
        switch (object.node.status) {
            case "LIVEING":
                text =  i18n.t("PageListHistoryResident:In");
                color = "tag_turquoise";
                break;
            case "QUIT":
                text =  i18n.t("PageListHistoryResident:Out");
                color = "tag_red_secondary";
                break;
            default:
                text =  i18n.t("PageListHistoryResident:Other");
                color = "text-danger";
        }
        return <td className={"text-center tag"}><span className={color}>{text}</span></td>;
    }

    handlePropsChange(channel_list) {
        let search = ""
        let start_date = null
        let end_date = null
        let status = ""
        channel_list.forEach((e, index) => {
            if (e.name === "search") {
                search = e.value
            }
            else if (e.name === "start_date" && e.value !== '') {
                start_date = e.value
            }
            else if (e.name === "end_date" && e.value !== '') {
                end_date = e.value
            }
            else if (e.name === "status") {
                status = e.value
            }
        });
        this.setState({
            search: search,
            start_date: start_date,
            end_date: end_date,
            status: status,
        })
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <Translation>
                    {
                        t=> 
                        <div id="wrapper-content">
                            <QueryRenderer
                                environment={environment}
                                query={this.props.historyDetail}
                                variables={{
                                    // id: this.props.id,
                                    search: this.state.search,
                                    start_date: this.state.start_date,
                                    end_date: this.state.end_date,
                                    first: this.props.first,
                                    last: this.props.last,
                                    order: this.state.order,
                                    status: this.state.status,
                                    reQuery: this.state.reQuery
                                }}
                                render={({ error, props }) => {
                                    if (error) {
                                        return <div>{error.message}</div>;
                                    } else if (props) {

                                        // หาคนล่าสุดที่เข้ามา
                                        let index_most = null
                                        let added_most = null
                                        props.allResidentialHistory.edges.forEach((all_residential_history, index) => {
                                            if (index === 0) {
                                                index_most = index
                                                added_most = all_residential_history.node.added
                                            }
                                            else if (added_most < all_residential_history.node.added) {
                                                index_most = index
                                                added_most = all_residential_history.node.added
                                            }
                                        })

                                        return (
                                            <div className="container-fluid mt-5 staff-list" id="detail">
                                                <div className="row justify-content-between">
                                                    <div className="col header-color">
                                                        <h3 className="mb-4">
                                                            <Link to="/register">
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                    alt="back" className="back" />
                                                            </Link>
                                                            {t("PageListHistoryResident:residentialhistory")}
                                                        </h3>
                                                        <div>
                                                            {/* <Navigation id={this.props.id} /> */}
                                                            <SeacrhDetail
                                                                selectIs={true}
                                                                startDateIs={true}
                                                                endDateIs={true}
                                                                searchBoxId={true}
                                                                search={this.state.search}
                                                                start_date={this.state.start_date}
                                                                end_date={this.state.end_date}
                                                                status={this.state.status}
                                                                handlePropsChange={this.handlePropsChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='mt-5'>
                                                    <div className="table-responsive fade-up card">
                                                        <table className="table">
                                                            <thead className="thead-light">
                                                                <tr className='text-center thFontKanit'>
                                                                    {
                                                                        this.state.order === 'Descending' ?
                                                                            <th className='cursor' onClick={() => this.setState({ order: "Ascending" })}>▼</th> 
                                                                            :
                                                                            <th className='cursor' onClick={() => this.setState({ order: "Descending" })} >▲</th>
                                                                    }
                                                                    <th>{t("PageListHistoryResident:Type")}</th>
                                                                    <th>{t("PageListHistoryResident:Status")}</th>
                                                                    <th>{t("PageListHistoryResident:DateIn")}</th>
                                                                    <th>{t("PageListHistoryResident:DateOut")}</th>
                                                                    <th>{t("PageListHistoryResident:Room")}</th>
                                                                    <th>{t("PageListHistoryResident:FullName")}</th>
                                                                    <th>{t("PageListHistoryResident:Reason")}</th>
                                                                    <th>{t("PageListHistoryResident:Phone")}</th>
                                                                    <th>{t("PageListHistoryResident:E-mail")}</th>
                                                                    <th>{t("PageListHistoryResident:Nationality")}</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {props.allResidentialHistory.totalCount > 0 ?
                                                                    props.allResidentialHistory.edges.map((all_residential_history, t_index) => {
                                                                        return (
                                                                            <tr key={all_residential_history.node.id} className='tdFontKanit'>
                                                                                <td className='tag text-center'><span className={t_index === index_most ?"tags_econdary_red_red_base": ''}>{t_index === index_most && 'ล่าสุด'}</span></td>
                                                                                {this.getColorAndText(all_residential_history)}
                                                                                {this.getStatusColorAndText(all_residential_history)}                                                                               

                                                                                <td className='text-center'>{all_residential_history.node.liveing ? format(all_residential_history.node.liveing, "DD/MM/YYYY", { locale: thLocale }) : '-'}</td>
                                                                                <td className='text-center'>{all_residential_history.node.quit ? format(all_residential_history.node.quit, "DD/MM/YYYY", { locale: thLocale }) : '-'}</td>

                                                                                <td className='text-center'>{all_residential_history.node.nameResidential ? all_residential_history.node.nameResidential : '-'}</td>

                                                                                <td className='text-center'>
                                                                                <Link to={{
                                                                                        pathname:
                                                                                        "/register/residential/detail/residentialhistory-view/" +
                                                                                        all_residential_history.node.id,
                                                                                    }}
                                                                                    >    {all_residential_history.node.firstName + ' ' + all_residential_history.node.lastName} 
                                                                                </Link>
                                                                                </td>
                                                                                <td className='text-center'>{all_residential_history.node.remark ? all_residential_history.node.remark : '-'}</td>
                                                                                <td className='text-center'>{all_residential_history.node.phone ? all_residential_history.node.phone : '-'}</td>
                                                                                <td className='text-center'>{all_residential_history.node.email ? all_residential_history.node.email : '-'}</td>
                                                                                <td className='text-center'>{all_residential_history.node.countries ? all_residential_history.node.countries : '-'}</td>
                                                                                {/* <td className='text-center'>{all_residential_history.node.type}</td> */}
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr>
                                                                        <th colSpan={10} className='text-center'>{t("residentialHistory:Not found residential history")}</th>
                                                                    </tr>
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="row">
                                                        <Pagination changePage={this.props.changePage}
                                                            first={this.props.first}
                                                            last={this.props.last}
                                                            totalCount={props.allResidentialHistory.totalCount} />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return <Loading />
                                }}
                            />
                        </div>

                    }
                </Translation>
            </Wrapper>
        );
    }
}

export default ResidentialhistoryListTable;


