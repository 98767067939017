import { setDate, getDate } from "date-fns";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import DatePickerNoti from "./datePickerNoti";
import numberWithCommas from '../../../libs/numberWithComma'
import _ from 'lodash'
import OnDelete from "./onDelete";
import i18next from "i18next";


class DepositInterest extends Component {

    constructor(props){
        super(props);
        this.state = {
            depositInterestList : this.props.depositInterestList,
            edit : this.props.edit,
            startSite : this.props.startSite,
            haveDateInterest : false
        }
    }

    componentDidMount(){
        this.updateHaveDateInterest()
    }

    updateHaveDateInterest = () => {
        let count = 0
        _.forEach(this.props.depositInterestList , (interest) => {
            if(this.state.startSite >= new Date(interest.node.issuedDate)){
                count += 1
            }
        })
        if(count > 0){
            this.setState({
                haveDateInterest : true
            })
        }else{
            this.setState({
                haveDateInterest : false
            })
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.depositInterestList !== this.props.depositInterestList){
            this.updateHaveDateInterest()
            this.setState({
                depositInterestList : this.props.depositInterestList
            })
        }
        if(prevProps !== this.props){
            this.setState({
                edit : this.props.edit,
                startSite : this.props.startSite
            })
        }
    }

    

    getMonth = (issuedDate,dueDate) => {
        let months = 0 ;
        months = (dueDate.getFullYear() - issuedDate.getFullYear()) * 12;
        months -= issuedDate.getMonth();
        months += dueDate.getMonth();
        return months <= 0 ? 0 : months;
    }


    render() {
        return(
            <Translation>
                {t =>  
                    <div className="row col-10 mt-3">
                        <div className="table border-none" >
                            <table style={{border:'none'}}>
                                <thead>
                                    <tr >
                                        <th className="text-center"><span className="span-grey">{i18next.t("depositInterestDashBoard:Automatically record in the account")}</span></th>
                                        <th className="text-center"  style={{minWidth:'100px'}}>{i18next.t("depositInterestDashBoard:Number of Months")}</th>
                                        <th className="text-center" style={{minWidth:'150px'}}>{i18next.t("depositInterestDashBoard:Start Date")}</th>
                                        <th className="text-center"  style={{minWidth:'150px'}}>{i18next.t("depositInterestDashBoard:Maturity Date")}</th>
                                        <th className="text-center"  style={{minWidth:'100px'}}>{i18next.t("depositInterestDashBoard:Interest Rate")} (%)</th>
                                        <th className="text-center"  style={{minWidth:'150px'}}>{i18next.t("depositInterestDashBoard:Amount")}</th>
                                        {/* {this.state.haveDateInterest &&  */}
                                        <th className="text-center " style={{minWidth:'150px'}}>{i18next.t("depositInterestDashBoard:Interest Calculation Date")}</th>
                                        {/* } */}
                                        {(!this.props.edit && this.props.status !== 'finished') && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_bankunit_edit' }) &&
                                            <th className="text-center">
                                                <span className="span-blue cursor" onClick={() => this.props.onEdit()}>{i18next.t("depositInterestDashBoard:Edit")}</span>  
                                            </th>   
                                        }
                                        <th className="text-center" style={{minWidth:'50px'}}/>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.depositInterestList.map((transaction,index) => {
                                        return(
                                            <tr key={index}>
                                                <td className="text-center">
                                                    {!this.state.edit ? 
                                                        <input type="checkbox" checked={transaction.node.automaticCal} 
                                                            name={"depositInterestList["+index+"].node.automaticCal"}
                                                            disabled={true}
                                                            onChange={() => this.props.toggleChange(index)}
                                                        />
                                                        : 
                                                            <input type="checkbox" checked={transaction.node.automaticCal} 
                                                            name={"depositInterestList["+index+"].node.automaticCal"}
                                                            onChange={() => this.props.toggleChange(index)}
                                                            disabled={(transaction.node.defaultAutomatic && transaction.node.id)}
                                                        />
                                                    }
                                                </td>
                                                <td className="text-center">
                                                   <input value={this.getMonth(new Date(transaction.node.issuedDate) , new Date(transaction.node.dueDate))} 
                                                    disabled={true} className="form-control text-center"  />
                                                </td>
                                                <td className="text-center">
                                                    {!this.state.edit ? 
                                                        // <span>{format(transaction.node.issuedDate , 'DD/MM/YYYY')}</span> 
                                                        <DatePickerNoti 
                                                            selected={new Date(transaction.node.issuedDate)}
                                                            name={"depositInterestList["+index+"].node.issuedDate"}
                                                            disabled={(transaction.node.defaultAutomatic && transaction.node.id) || this.props.status === 'finished' || !this.state.edit}
                                                            onChange={(e) => this.props.handleChange(e)}
                                                            height='20px'
                                                            width='20px'
                                                        />
                                                        :
                                                        <DatePickerNoti 
                                                            selected={new Date(transaction.node.issuedDate)}
                                                            name={"depositInterestList["+index+"].node.issuedDate"}
                                                            disabled={(transaction.node.defaultAutomatic && transaction.node.id)}
                                                            onChange={(e) => this.props.handleChange(e)}
                                                            height='20px'
                                                            width='20px'
                                                        />
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {!this.state.edit ?
                                                        <DatePickerNoti 
                                                            selected={new Date(transaction.node.dueDate)}
                                                            disabled={(transaction.node.automaticCal && transaction.node.id) || this.props.status === 'finished' || !this.state.edit}
                                                            name={"depositInterestList["+index+"].node.dueDate"}
                                                            onChange={(e) => this.props.handleChange(e)}
                                                            height='20px'
                                                            width='20px'
                                                            noti ={transaction.node.bankNotification && transaction.node.bankNotification.edges[0]?.node.status}
                                                        />
                                                        :
                                                        <DatePickerNoti 
                                                            selected={new Date(transaction.node.dueDate)}
                                                            disabled={(transaction.node.defaultAutomatic && transaction.node.id)}
                                                            name={"depositInterestList["+index+"].node.dueDate"}
                                                            onChange={(e) => this.props.handleChange(e)}
                                                            height='20px'
                                                            width='20px'
                                                            minDate={setDate(new Date(transaction.node.issuedDate),getDate(transaction.node.issuedDate)+1 )}
                                                            noti ={transaction.node.bankNotification && transaction.node.bankNotification.edges[0]?.node.status}
                                                        />
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {!this.state.edit ?
                                                        <input value={numberWithCommas(transaction.node.interestPerYear)} disabled={true} className=" text-right form-control"/>
                                                        :
                                                        <input type="number" min="0.01"  max="100"
                                                            className="form-control text-right" 
                                                            step='0.01'
                                                            placeholder='0.00'
                                                            value={transaction.node.interestPerYear} 
                                                            name={"depositInterestList["+index+"].node.interestPerYear"}
                                                            disabled={(transaction.node.defaultAutomatic && transaction.node.id)}
                                                            onChange={(e) => this.props.handleChange(e)}
                                                            required
                                                        />
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {!this.state.edit ?
                                                        <input value={numberWithCommas(transaction.node.price)} disabled={true} className="text-right form-control"/>
                                                        :
                                                        <input type="number" 
                                                            min="0.01"
                                                            step='0.01'
                                                            className="form-control text-right" 
                                                            value={transaction.node.price} 
                                                            name={"depositInterestList["+index+"].node.price"}
                                                            disabled={(transaction.node.defaultAutomatic && transaction.node.id)}
                                                            onChange={(e) => this.props.handleChange(e)}
                                                            required
                                                        />
                                                     }
                                                </td>
                                                {/* {this.state.haveDateInterest && */}
                                                    <td className="text-center">
                                                        {
                                                            !this.state.edit ?
                                                                transaction.node.calculationDate ?
                                                                <DatePickerNoti 
                                                                    selected={transaction.node.calculationDate ? new Date(transaction.node.calculationDate) : ''}
                                                                    name={"depositInterestList["+index+"].node.calculationDate"}
                                                                    disabled={transaction.node.automaticCal && transaction.node.id || this.props.status === 'finished' || !this.state.edit }
                                                                    onChange={(e) => this.props.handleChange(e)}
                                                                    height='20px'
                                                                    width='20px'
                                                                    required
                                                                />
                                                                :
                                                                <span>-</span>
                                                            :
                                                                // this.state.startSite >= new Date(transaction.node.issuedDate) &&
                                                                <DatePickerNoti 
                                                                    selected={transaction.node.calculationDate ? new Date(transaction.node.calculationDate) : ''}
                                                                    name={"depositInterestList["+index+"].node.calculationDate"}
                                                                    disabled={(transaction.node.defaultAutomatic && transaction.node.id)}
                                                                    onChange={(e) => this.props.handleChange(e)}
                                                                    height='20px'
                                                                    width='20px'
                                                                    required
                                                                />
                                                        }
                                                    </td>
                                                {/* } */}
                                                {this.state.edit && !transaction.node.id &&
                                                    <td className="text-center" style={{textAlign:'center' , verticalAlign : 'center'}}>
                                                        <OnDelete   transaction={transaction}
                                                                    transactionList={this.state.depositInterestList}
                                                                    setTransactionList={this.props.setTransactionList}/>
                                                    </td>
                                                }
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>        
                }
            </Translation>
        );
    }



}
export default DepositInterest;