import React, { Component } from "react";
import Swal from "sweetalert2";
import '../../libs/styles/uploadFileInput.scss'
import { Translation } from "react-i18next";
import i18next from "i18next";
import { Link } from "react-router-dom";
class UploadFileXlsxInputCustomText extends Component {
    constructor(props) {
        super(props);
        this.handleInputFile = this.handleInputFile.bind(this)

    }

    handleInputFile(e) {
        let file = e.currentTarget.files[0];
        if (file) {
            // check gt 20 mb
            if (file?.size > 20971520) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), `${i18next.t("Allaction:Please upload a new file as it exceeds the specified size of")} 20 MB`, 'error')
                return
            } else if (file?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .xlsx ${i18next.t("Allaction:Only")}`, 'error')
            }
            else {
                this.props.handleInputDocument(e)
            }
        }
    }

    render() {
        let name = "document_upload";
        let id = "uploadFile";
        if (this.props.name) {
            name = this.props.name;
            id = this.props.name;
        }
        if (this.props.dataId || this.props.dataId === 0) {
            name = name + this.props.dataId;
            id = id + this.props.dataId;
        }

        return (
            <React.Fragment>
                {!this.props.disableHeaderText &&
                    <div className=" text-right">
                        <a href={this.props.LinkDocHowto} target={'_blank'}>
                            <div className="tooltipsSpan"><img src={process.env.PUBLIC_URL + "/images/iconAlerts/iconInfo-Gray.png"} alt='iconInfoGray' />
                                <span className='tooltiptext' >
                                    {this.props.tooltipText}
                                </span>
                            </div>
                        </a>

                        <label htmlFor={id} className="mr-3">
                            <strong className="text-danger font-small">{this.props.dangerText}</strong>
                        </label>

                        <a href={this.props.LinkDocEx} download={this.props.LinkDocEx} target="_blank" rel="noreferrer noopener">
                            <Translation>{t => <strong className=" font-small">{t('createUpdateForm:Form example')}</strong>}</Translation>
                        </a>

                    </div>
                }

                <div className="custom-file">
                    <input type="file" className="custom-file-input"
                        id={id} name={name} data-key={this.props.dataKey} data-id={this.props.dataId}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={this.handleInputFile} />

                    <label className="custom-file-label text-document text-secondary " htmlFor={id}>
                        {
                            this.props.documentUpload &&
                                this.props.documentUpload.name.length >= 100 ?
                                this.props.documentUpload.name.substr(0, 100)
                                :
                                this.props.documentUpload.name
                        }
                    </label>
                </div>
            </React.Fragment>
        )
    }
}

export default UploadFileXlsxInputCustomText