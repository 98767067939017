import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18next from 'i18next'
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../libs/numberWithComma";
import "./css/receiptDepositView.scss";
import Swal from "sweetalert2";
import { fetchQuery } from "relay-runtime";
import getNameResidential from "../../../libs/getNameResidential";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import PayChannel from "../../expense/pay/payChannel";
import _ from "lodash";
import { Redirect } from 'react-router'
import GetDocumentNumber from "../../../libs/getDocumentNumber";
import Loading from '../../../libs/loading';
import AddressDataShow from "../../../components/FucntionLib/addressDataShow";


const receiptDepositView = graphql`
  query receiptDepositViewQuery($id: ID!) {
    receiptDeposit(id: $id) {
      id
      docNumber
      firstName lastName
      payGroup
      address district city province postalCode
      refReceive {
        docNumber
      }
      issuedDate
      status
      total
      taxType
      added
      updated 
      contact {
        refNumber
        firstName
        lastName
        name
        registeredName
        registeredAddress
        registeredCountry
        registeredProvince
        registeredCity
        registeredDistrict
        registeredPostalCode
        typeOfContact
      }
      remark
      receiptDepositTransaction {
        edges {
          node {
            id
            chartOfAccount {
              id
              name
              chartOfAccountCode
            }
            vat
            price
            description
            receiptDepositTransactionUsed {
              edges {
                node {
                  id
                  amount
                  added
                  transaction{
                      invoice {
                          docNumber
                          taxType
                          firstName
                          lastName
                          status
                          issuedDate

                    }
                  }

                  receiveTransaction {
                    transaction {
                      invoice {
                          docNumber
                          taxType
                          firstName
                          lastName
                      }
                    }
                    receive {
                      id
                      docNumber
                      issuedDate
                      status
                      total
                    } 
                  }

                  otherExpense {
                      id
                      docNumber
                      name
                      issuedDate
                  }

                }
              }
            }
          }
        }
      }
      receiptDepositTransactionUsed {
        edges {
          node {
            id
            amount
          }
        }
      }
      bankAccountTransaction {
        edges {
          node {
            id
            price
            date
            imageSlip
            bankAccount {
              bankName
              accountNumber
            }
          }
        }
      }
      cashTransaction {
        edges {
          node {
            id
            price
          }
        }
      }
      chequeDeposit {
        edges {
          node {
            id
            date
            bankName
            price
            chequeNumber
            branchNumber
          }
        }
      }
      creditCardTransaction {
        edges {
          node {
            id
            amount
          }
        }
      }
      unknownReceiveUsed{
        edges{
            node{
              id description docNumber amount refNumber
                chartOfAccount{
                  chartOfAccountCode name
                }
                  imageSlip
            }
        }
      }
    }
  }
`;


const mutation = graphql`
  mutation receiptDepositViewMutation(
    $input: UpdateReceiptDepositAndTransactionStatusInput!
  ) {
    updateReceiptDepositAndTransactionStatus(input: $input) {
      ok
      warningText
    }
  }
`;

class ReceiptDepositView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sum_total: 0,
      loading: false,
      redirectToList: false,
    };

    this.onChangeStatusToVoid = this.onChangeStatusToVoid.bind(this);
  }

  onChangeStatusToVoid(receiptDepositID) {
    if (receiptDepositID) {
      Swal.fire({
        title: i18next.t("receipt_deposit:Can't cancel, have to cancel the receipt."),
        text: "",
        type: "warning",
      })
    } else {
      Swal.fire({
        title: i18next.t("receipt_deposit:Are you sure to cancel this receipt deposit?"),
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: i18next.t("receipt_deposit:Yes"),
        cancelButtonText: i18next.t("receipt_deposit:Cancel"),
      }).then(async (result) => {
        if (result.value) {
          let { value: void_remark } = await Swal.fire({
            title: i18next.t("receipt_deposit:Note"),
            input: "text",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: i18next.t("receipt_deposit:Cancel"),
            confirmButtonText: i18next.t("receipt_deposit:Confirm"),
            inputValidator: (value) => {
              if (!value) {
                return i18next.t("receipt_deposit:Please enter the cancellation note!");
              }
            },
          });

          if (void_remark) {
            this.setState({ loading: true });
            let variables = {
              input: {
                status: "void",
                receiptDepositIdList: [this.props.match.params.id],
                voidRemark: void_remark,
              },
            };
            commitMutation(environment, {
              mutation,
              variables,
              onCompleted: (response) => {
                if (response) {
                  if (response.updateReceiptDepositAndTransactionStatus.ok) {
                    Swal.fire(i18next.t("receipt_deposit:Save successfully!"), "", "success").then(() =>
                      this.setState({ redirectToList: true })
                      // this.refreshData()
                    );
                  } else {
                    Swal.fire(i18next.t("receipt_deposit:Save unsuccessfully!"), response.updateReceiptDepositAndTransactionStatus.warningText, "warning").then(() =>
                      this.setState({ loading: false })
                    );
                  }
                }
              },
              onError: (err) => {
                this.setState({ loading: false });
                Swal.fire(i18next.t("receipt_deposit:Save unsuccessfully!"), "", "warning");
              },
            });
          }
        } else {
          this.setState({ loading: false });
        }
      });
    }

  }

  refreshData() {
    fetchQuery(environment, receiptDepositView, {
      id: this.props.match.params.id,
    });
    this.setState({ loading: false });
  }

  getHeaderName(key) {
    let header = {
      "2210-01": "Receipt deposit - Common fees",
      "2210-02": "Receipt deposit - Water meter",
      "2210-03": "Receipt deposit - Common area electricity",
      "2210-04": "Receipt deposit - Monthly parking fee",
      "2210-05": "Receipt deposit - Insurance premium",
      "2210-06": "Receipt deposit - Water measures maintenance",
      "2210-07": "Receipt deposit - Common area utilization",
      "2210-08": "Receipt deposit - Service charge",
      "2210-09": "Receipt deposit - Other",
      "2210-10": "Receipt deposit - Common area service fees",
    };
    return header[key];
  }

  getTaxType(key) {
    let tax = {
      NO_TAX: "ไม่มีภาษี",
      EXCLUDE_TAX: "แยกภาษี",
      INCLUDE_TAX: "รวมภาษี",
    };
    return tax[key];
  }

  calPriceVat(price, vat, taxType) {
    if (taxType === "EXCLUDE_TAX") {
      price = parseFloat(price) * (1 + parseFloat(vat || 0) / 100);
    } else {
      price = (parseFloat(price) * 100) / (100 + parseFloat(vat || 0));
    }
    return numberWithComma(price.toFixed(2));
  }

  calVatAmount(price, vat, taxType) {
    if (taxType === "EXCLUDE_TAX") {
      price = parseFloat(price) * (parseFloat(vat || 0) / 100);
    } else {
      price =
        (parseFloat(price) * parseFloat(vat || 0)) /
        (100 + parseFloat(vat || 0));
    }
    return numberWithComma(price.toFixed(2));
  }

  render() {
    if (this.state.redirectToList) {
      return <Redirect to="/accounting/income/receipt_deposit/list/all" />
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <QueryRenderer
            environment={environment}
            query={receiptDepositView}
            variables={{ id: this.props.match.params.id }}
            render={({ error, props }) => {
              if (error) {
                return <div>Error :(</div>;
              } else if (props) {

                return (

                  <div
                    className="container-fluid box"
                    id="receipt_deposit-view"
                    key={props.receiptDeposit.id}
                  >
                    <div className="row justify-content-between">
                      <div className="col">
                        <h3 className="mb-4">
                          <Link to="/accounting/income/receipt_deposit/list/all">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/wrapperContent/back.png"
                              }
                              alt="back"
                              className="back"
                            />
                          </Link>
                          <Translation>
                            {(t) => (
                              <span>{t("receiptDeposit:receipt_deposit")+" "+"(RD)"}</span>
                            )}
                          </Translation>
                        </h3>
                      </div>
                      <div className="col text-right">
                        {props.receiptDeposit.status === "ACTIVE" &&
                          props.receiptDeposit.receiptDepositTransactionUsed
                            .edges.length === 0 &&
                          _.some(
                            JSON.parse(localStorage.getItem("permission_list")),
                            { codename: "accounting_receipt_deposit_delete" }
                          ) && (
                            <button
                              className="btn btn-danger add mr-3"
                              disabled={this.state.loading}
                              onClick={() => this.onChangeStatusToVoid(props.receiptDeposit.refReceive)}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm align-middle mr-2" />
                              )}
                              <Translation>{t => <>{t("receipt_deposit:Cancel receipt deposit")}</>}</Translation>
                            </button>
                          )}
                        {_.some(
                          JSON.parse(localStorage.getItem("permission_list")),
                          { codename: "accounting_receipt_deposit_print" }
                        ) && (
                            <Link
                              to={
                                "/document/receipt-deposit/" +
                                props.receiptDeposit.id
                              }
                              target={"_blank"}
                            >
                              <Translation>
                                {t =>
                                  <button className="btn btn-primary add">
                                    {t("receipt_deposit:Print")}
                                  </button>}
                              </Translation>
                            </Link>
                          )}
                      </div>
                    </div>

                    <div className="content-inner">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <p>
                                <Translation>{t => <strong>{t("receipt_deposit:Customer")}</strong>}</Translation>
                              </p>
                              <p>
                                {props.receiptDeposit.contact.refNumber}{" "}
                                {
                                  props.receiptDeposit.contact.typeOfContact === 'RESIDENTIAL' && (props.receiptDeposit.firstName || props.receiptDeposit.lastName) ?
                                    getNameResidential(props.receiptDeposit.firstName, props.receiptDeposit.lastName)
                                    :
                                    props.receiptDeposit.contact.typeOfContact === 'RESIDENTIAL' ?
                                      props.receiptDeposit.contact.firstName && props.receiptDeposit.contact.firstName !== '' ? getNameResidential(props.receiptDeposit.contact.firstName, props.receiptDeposit.contact.lastName) : props.receiptDeposit.contact.name
                                      :
                                      props.receiptDeposit.contact.name || props.receiptDeposit.contact.firstName + props.receiptDeposit.contact.lastName}
                                  &nbsp;<span style={{color: '#F43853'}}>
                                  {props.receiptDeposit.payGroup === "RENT" ? `(${i18next.t("AgentRole:Renter")})` : 
                                      props.receiptDeposit.payGroup === "AGENT" ? `(${i18next.t("AgentRole:Agent")})`:
                                        ""
                                  }
                                </span>  
                              </p>
                            </div>
                            <div className="col">
                              <p>
                                <Translation>{t => <strong>{t("receipt_deposit:No.")}</strong>}</Translation>
                              </p>
                              <p>{props.receiptDeposit.docNumber} </p>
                            </div>
                            <div className="col">
                              <p>
                                <Translation>{t => <strong>{t("receipt_deposit:Date_out")}</strong>}</Translation>
                              </p>
                              <p>
                                {format(
                                  props.receiptDeposit.issuedDate,
                                  "DD/MM/YYYY",
                                  { locale: thLocale }
                                )}
                              </p>
                            </div>
                            {/*<div className="col">*/}
                            {/*    <p>ประเภทภาษี</p>*/}
                            {/*    <p>*/}
                            {/*        {this.getTaxType(props.receiptDeposit.taxType)}*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col">
                              <p>
                                <Translation>{t => <strong>{t("receipt_deposit:Address")}</strong>}</Translation>
                              </p>
                              <p>
                                {
                                 ( props.receiptDeposit?.address || props.receiptDeposit.contact.registeredAddress) &&
                                  <AddressDataShow
                                    from={true}
                                    address={
                                      props.receiptDeposit?.address ?
                                      {
                                        address: props.receiptDeposit.address,
                                        district: props.receiptDeposit.district,
                                        city: props.receiptDeposit.city,
                                        province: props.receiptDeposit.province,
                                        postalCode: props.receiptDeposit.postalCode,
                                      }:
                                      {
                                        registeredAddress: props.receiptDeposit.contact.registeredAddress,
                                        registeredDistrict: props.receiptDeposit.contact.registeredDistrict,
                                        registeredCity: props.receiptDeposit.contact.registeredCity,
                                        registeredProvince: props.receiptDeposit.contact.registeredProvince,
                                        registeredPostalCode: props.receiptDeposit.contact.registeredPostalCode,
                                        registeredCountry: props.receiptDeposit.contact.registeredCountry,
                                      }
                                    }
                                  />

                                }

                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          {props.receiptDeposit.receiptDepositTransaction && props.receiptDeposit.receiptDepositTransaction?.edges.map(
                            (transaction) => {

                              let price = transaction.node.price;

                              return (
                                <div className="mt-3" key={transaction.node.id}>
                                  {/* <Translation>
                                    {t=>
                                    <h5>
                                      {t(`receipt_deposit:${this.getHeaderName(transaction.node.chartOfAccount.chartOfAccountCode)}`)}
                                    </h5>}
                                  </Translation> */}
                                  <div className="table-responsive fade-up">
                                    <table className="table table-hover mt-2">
                                      <thead className="thead-light">
                                        <Translation>
                                          {t =>
                                            <tr>
                                              <th>{t("receipt_deposit:List")}</th>
                                              <th>{t("receipt_deposit:Description")}</th>
                                              <th className="text-right">
                                                {t("receipt_deposit:Amount")}
                                              </th>
                                              {/*<th className="text-right">ภาษี</th>*/}
                                              {/*<th className="text-right">{!(this.state.taxType === 'NO_TAX') ? "ยอดก่อนภาษี" : "ยอดรวม"}</th>*/}
                                              <th className="text-right">
                                                {t("receipt_deposit:Amount_of_payment")}
                                              </th>
                                              <th className="text-right">
                                                {t("receipt_deposit:Balance amount")}
                                              </th>
                                            </tr>}
                                        </Translation>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            {transaction?.node?.chartOfAccount
                                              .chartOfAccountCode +
                                              " " +
                                              transaction?.node?.chartOfAccount
                                                .name}
                                          </td>
                                          <td>
                                            {transaction?.node?.description}
                                          </td>
                                          <td className="text-right">
                                            {numberWithComma(
                                              transaction.node.price
                                            )}
                                          </td>
                                          {/*<td className="text-right">{this.calVatAmount(transaction.node.price, transaction.node.vat, props.receiptDeposit.taxType)}</td>*/}
                                          {/*<td className="text-right">{this.calPriceVat(transaction.node.price, transaction.node.vat, props.receiptDeposit.taxType)}</td>*/}
                                          <td className="text-right">
                                            {numberWithComma(
                                              transaction?.node.amountUsed
                                            )}
                                          </td>
                                          <td className="text-right">
                                            {numberWithComma(
                                              transaction?.node.price
                                            )}
                                          </td>
                                        </tr>

                                        {
                                          transaction?.node?.receiptDepositTransactionUsed.edges
                                            .slice()
                                            .sort(
                                              (a, b) =>
                                                Date.parse(
                                                  new Date(
                                                    a.node.receiveTransaction?.transaction?.invoice?.issuedDate
                                                  )
                                                ) -
                                                Date.parse(
                                                  new Date(
                                                    b.node.receiveTransaction?.transaction?.invoice?.issuedDate
                                                  )
                                                )
                                            )
                                            .map((used) => {
                                              price = price - used.node.amount;

                                              let docNumberName = used?.node?.receiveTransaction?.receive?.docNumber
                                              let invoice = used?.node?.transaction?.invoice
                                              let doc_number = "";
                                              if(used.node.transaction == null){
                                                doc_number = used.node.otherExpense?.docNumber;
                                              }
                                              else{
                                                doc_number = invoice?.status === 'DRAFT' ? GetDocumentNumber.get("invoice", "XXXX", new Date(invoice?.issuedDate)) : invoice?.docNumber;
                                              }
                                              if (price < 0) price = 0;
                                              return (
                                                <tr key={used.node.id}>
                                                  <td>
                                                    {transaction.node
                                                      .chartOfAccount
                                                      .chartOfAccountCode +
                                                      " " +
                                                      transaction.node
                                                        .chartOfAccount.name}{" "}

                                                    {doc_number ? "(" + doc_number + ")" : ''}{" "}

                                                    {docNumberName ? "(" + docNumberName + ")"
                                                      : null}
                                                  </td>
                                                  <td>
                                                    {transaction.node.description}
                                                  </td>
                                                  <td className="text-right">
                                                    -
                                                  </td>
                                                  <td className="text-right">
                                                    {numberWithComma(
                                                      used.node.amount
                                                    )}
                                                  </td>
                                                  <td className="text-right">
                                                    {numberWithComma(price)}
                                                  </td>
                                                </tr>
                                              );

                                            })
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <PayChannel
                        total={props.receiptDeposit.total}
                        bankTransaction={
                          props.receiptDeposit.bankAccountTransaction.edges
                        }
                        cashTransaction={
                          props.receiptDeposit.cashTransaction.edges
                        }
                        chequeTransaction={
                          props.receiptDeposit.chequeDeposit.edges
                        }
                        creditCardTransaction={
                          props.receiptDeposit.creditCardTransaction.edges
                        }
                        unknownReceive={
                          props.receiptDeposit.unknownReceiveUsed
                        }
                        account_type="receive"
                      />
                      <div className="row mt-4">
                        <div className="col">
                          <label htmlFor="note">
                            <Translation>{t => <strong>{t("receipt_deposit:Note")}</strong>}</Translation>
                          </label>
                          <textarea
                            className="form-control col-4"
                            rows="4"
                            name="remark"
                            value={props.receiptDeposit.remark}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    {/*<div>*/}
                    {/*<div className="row mt-5">*/}
                    {/*<div className="col">*/}
                    {/*<p><strong>หมายเหตุ</strong></p>*/}
                    {/*</div>*/}
                    {/*</div>*/}

                    {/*<div className="row">*/}
                    {/*<div className="col">*/}
                    {/*<p>{props.receiptDeposit.remark}</p>*/}
                    {/*</div>*/}
                    {/*<div className="col offset-6">*/}
                    {/*<div className="boxSummary">*/}
                    {/*<div className="row mt-3">*/}
                    {/*<div className="col">*/}
                    {/*<h5>ยอดเงินทั้งหมด</h5>*/}
                    {/*</div>*/}
                    {/*<div className="col text-right">*/}
                    {/*<h5>{numberWithComma(this.calSumTotal(props))} บาท</h5>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="row mt-3">*/}
                    {/*<div className="col">*/}
                    {/*<h5>ยอดเงินที่ใช้ไปแล้วทั้งหมด</h5>*/}
                    {/*</div>*/}
                    {/*<div className="col text-right">*/}
                    {/*<h5>{numberWithComma(this.calSumUsed(props))} บาท</h5>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="row mt-3">*/}
                    {/*<div className="col">*/}
                    {/*<h5>คงเหลือทั้งหมด</h5>*/}
                    {/*</div>*/}
                    {/*<div className="col text-right">*/}
                    {/*<h5>{numberWithComma(this.calSumBalance(props))} บาท</h5>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                  </div>
                );
              }
              return <Loading />
            }}
          />
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ReceiptDepositView;
