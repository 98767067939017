import React, { useState } from 'react'
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './settingAdvertise.scss'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {commitMutation} from 'react-relay'
import { Redirect } from 'react-router';
import Swal from 'sweetalert2';
import i18next from 'i18next';
import upload from '../../libs/upload'
import _ from "lodash";
import { format } from 'date-fns';

const mutation = graphql`
    mutation createAdvertiseMutation($input: CreateAdvertiseInput!) {
        createAdvertise(input: $input) {
            ok
        }
    }
`;

const CreateAdvertise = () => {
    const [title,setTitle] = useState('')
    const [detail,setDetail] = useState('')
    const [link,setLink] = useState('')
    const [customerName,setCustomerName] = useState('')
    const [image,setImage] = useState([])
    const [redirect,setRedirect] = useState(false)

    const callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/advertise/" + path, file);
            resolve(res);
        });
    };

    function deleteImage(data, index) {
        let imageData = _.cloneDeep(image)
        imageData.splice(index, 1)
        setImage(imageData)
    }

    function uploadFile(e) {
        let event = _.cloneDeep(e);
        let files = event.currentTarget.files[0];

        if (files) {
            if (files?.size > 10485760) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }
            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), 'กรุณาอัพโหลดไฟล์ในประเภท .png, .jpg, และ .jpegเท่านั้น และไฟล์ขนาดไม่เกิน 2 MB (สูงสุด 10 รูป)', 'error')
            }
            else {
                let new_file = new File([files], `${format(new Date(), "picture" + "_YYYYMMDDHHmmss")}${Math.floor(Math.random() * 1000000001)}${files.name}`);

                callUpload("image", new_file).then((data) => {
                        let fileOldList = _.cloneDeep(image);
                        let dese = "advertise/image/" + new_file.name
                        fileOldList.push({ 
                            "fileName": new_file.name, 
                            "fileUpload": dese,
                            "viewPtath": data.location
                        })
                        setImage(fileOldList)
                }).catch((error) => {
                    console.log('error',  error)
                })
            }
        }
    }

    const onSubmit = () => {
        let adsImage = image.map(({fileName, fileUpload}) => ({fileName, fileUpload}));
        let variables = {
            input : {
                title:title,
                detail:detail,
                link:link,
                image:adsImage[0].fileUpload,
                customerName:customerName
            }
        }
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                        setRedirect(true)
                    })
                },
                onError: (err) => {
                    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
                    console.log(err);
                },
            },
        )
    }

    if(redirect){
        return <Redirect to="/setting/setting-advertise" />;
    }else{
        return(
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className='container-fluid box'>
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting/setting-advertise">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('settingAds:Create Ads')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>
                        <Translation>
                            {
                                t => 
                                    <div className='content-inner d-flex justify-content-center' id="advertise">
                                        <div className='row create'>
                                            <div className='col-12'>
                                                <Translation>
                                                    {
                                                        t => <h6>{t('settingAds:Title')}</h6>
                                                    }
                                                </Translation>
                                            </div>
                                            <hr />
                                            <div className='col-12 mb-2 mt-4'>
                                                <Translation>
                                                    {
                                                        t => <span>{t('settingAds:Title Name')}</span>
                                                    }
                                                </Translation>
                                            </div>
                                            <div className='col-7 mb-4'>
                                                <input className='form-control form-input' 
                                                            onChange={event => {
                                                            event.preventDefault();
                                                            setTitle(event.target.value)
                                                        }}  
                                                        value={title}
                                                />
                                            </div>
                                            <div className='col-5 mb-4' />
                                            <div className='col-12 mb-2'>
                                                <Translation>
                                                    {
                                                        t => <span>{t('settingAds:Details')}</span>
                                                    }
                                                </Translation>
                                            </div>
                                            <div className='col-7 mb-4'>
                                                <textarea  className='form-control form-input' style={{minHeight:'212px'}}
                                                    onChange={event => {
                                                        event.preventDefault();
                                                        setDetail(event.target.value)
                                                    }}  
                                                    value={detail}
                                                />
                                            </div>
                                            <div className='col-5 mb-4' />

                                            {/* upload image */}
                                            <div className="col">
                                                <h6>
                                                    <Translation>
                                                    {(t) => <span>{t("press_release:title_image")}</span>}
                                                    </Translation> {" "}
                                                    <span className="ml-2 color thin red-color">
                                                    <Translation>
                                                        {(t) => <span>{t("press_release:title_image_description")}</span>}
                                                    </Translation>
                                                    </span>
                                                </h6>

                                                <div className='row p-2'>
                                                        <div className='svm-agent-img'>
                                                            {
                                                            image?.length <= 1 &&
                                                                image?.map((n, inx) => {
                                                                        return (
                                                                        <div style={{position:'relative'}}>
                                                                            <img src={n.viewPtath} style={{ width: 'auto', height: 300,borderRadius:'8px',margin:'2px'}} />
                                                                            <button className='btnDeleteImage' type={"button"} onClick={() => deleteImage(n, inx)}>
                                                                                <img src={process.env.PUBLIC_URL + "/images/feedback/closeImg.png"}  className="img-pre"/>
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {image?.length <= 0 ?
                                                        <div className='btn-svmUploadImg'>
                                                            <div> 
                                                                <img src={process.env.PUBLIC_URL + '/images/icons/camera.png'} style={{position:'relative',top:'16px',left:'32px',width:'25px'}} /></div>
                                                                <div className='text-center'>
                                                                    <input type="file" name='adsImage' id="adsImage" className='svmUploadImg'                          
                                                                            accept='application/.pdf,.png,.jpg'
                                                                            onChange={(e) => uploadFile(e)}
                                                                        />
                                                                        <span style={{position:'relative',top:'-10px',left:'2px',fontSize:'12px',color:'#0F0F0F'}}>
                                                                        {'เพิ่มรูปภาพ'}</span>
                                                                </div>
                                                            </div>
                                                            :<span className='text-danger'> {'แนบรูปสูงสุดได้ 1 รูปเท่านั้น'} </span>
                                                        }    
                                                </div>
                                            </div><br></br>
                                            {/* upload image */}

                                            <div className='col-12 mb-2'>
                                                <span>Link</span>
                                            </div >
                                            <div className='col-7 mb-4'>
                                                <input className='form-control form-input' 
                                                    onChange={event => {
                                                        event.preventDefault();
                                                        setLink(event.target.value)
                                                    }}  
                                                    value={link}
                                                />
                                            </div>
                                            <div className='col-5 mb-4' />
                                            <div className='col-12 mb-2'>
                                            <Translation>
                                                    {
                                                        t => <span>{t('settingAds:Company Name')}</span>
                                                    }
                                            </Translation>
                                            </div >
                                            <div className='col-7 mb-4'>
                                                <input className='form-control form-input' 
                                                    onChange={event => {
                                                        event.preventDefault();
                                                        setCustomerName(event.target.value)
                                                    }}  
                                                    value={customerName}
                                                />
                                            </div>
                                            <div className='col-5 mb-4' />

                                            <div className='col-12 mt-4'>
                                                <button className='btn-create float-right' onClick={() => onSubmit()}>
                                                    <Translation>
                                                    {
                                                        t => <span style={{color:"#FFFFFF"}}>{t('Allaction:save')}</span>
                                                    }
                                                    </Translation>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </Translation>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}
export default CreateAdvertise