import React, { Component } from 'react'
import PrivateRoute from '../../libs/privateRoute';
import NoMatch from '../../components/noMatch';
import { Route , Switch} from "react-router-dom"; 
import ClaimList from './claimRequest/claimList';
import CreateClaimRequset from './claimRequest/createClaim';
import ClaimReportList from './report/claimReportList';
import ClaimSummaryReport from './report/claimSummaryReport';
import ClaimReportDetail from './report/claimReportDetail';
import UpdateClaimRequset from './claimRequest/updateClaim';
import SummarizeClaim from './claimRequest/summarizeClaim';
import ClaimChat from './claimRequest/claimChat';
import WrapperClaimReport from './report/wrapperClaimReport';
import UpdateClaimDraft from './claimRequest/updateClaimDraft';


class Claim extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path={`${this.props.match.url}/create`} component={CreateClaimRequset}/> 
        <PrivateRoute exact path={`${this.props.match.url}/report-claim-list-pdf/:startDate?/:endDate?/:search?`} component={WrapperClaimReport}/> 
        <PrivateRoute exact path={`${this.props.match.url}/report-claim-list-pdf`} component={WrapperClaimReport}/> 

        <PrivateRoute exact path={`${this.props.match.url}/report`} component={ClaimReportList}/> 
        <PrivateRoute exact path={`${this.props.match.url}/report/:status?`} component={ClaimReportList}/> 
        <PrivateRoute exact path={`${this.props.match.url}/report-detail/:id?`} component={ClaimReportDetail}/> 
        <PrivateRoute exact path={`${this.props.match.url}/view-report-all`} component={ClaimSummaryReport}/> 
        <PrivateRoute exact path={`${this.props.match.url}/cliam-detail/:id?/:status?`} component={UpdateClaimRequset}/>
        <PrivateRoute exact path={`${this.props.match.url}/cliam-draft/:id?/:status?`} component={UpdateClaimDraft}/>
        <PrivateRoute exact path={`${this.props.match.url}/summarize/:id?/:status?`} component={SummarizeClaim}/>  
        <PrivateRoute exact path={`${this.props.match.url}/chat/:id?/:status?`} component={ClaimChat}/>  
        <PrivateRoute exact path={`${this.props.match.url}/:status`} component={ClaimList}/> 
        <Route component={NoMatch} />
      </Switch>
    )
  }
}
export default Claim;
