import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Modal } from 'react-bootstrap';
import { Translation } from "react-i18next";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../libs/numberWithComma";
import getNameResidential from "../../../libs/getNameResidential";
import Swal from "sweetalert2";
import InvoiceSummary from "../../income/invoice/invoiceSummary";
import ChangeStatusOtherReceiveMutation from "../invoice/invoice_detail/mutations/ChangeStatusOtherReceiveMutation";
import Redirect from "react-router-dom/es/Redirect";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import PayChannel from "../../expense/pay/payChannel";
import _ from "lodash";
import i18n from 'i18next'
import Loading from "../../../libs/loading";
import InputAddress from "../../../libs/autoCompleteAddress";
import i18next from "i18next";
import { fetchQuery } from 'relay-runtime'

const query = graphql`
  query otherReceiverViewQuery($id: ID!, $ref_transaction: String) {
    otherReceive(id: $id) {
      id
      issuedDate
      docNumber
      remark
      status
      total
      taxType
      unknownContact
      firstName
      lastName
      payGroup
      address district city province postalCode
      carTransaction{
            edges{
                node{
                    id
                    codeNumber
                }
            }
        }
      unknownReceive {
        edges {
          node {
            id
            docNumber
            refNumber
            description
            amount
            chartOfAccount {
              chartOfAccountCode
              name
            }
          }
        }
      }
      contact {
        name
        refNumber
        firstName
        lastName
        registeredName
        registeredAddress
        registeredCountry
        registeredProvince
        registeredCity
        registeredDistrict
        registeredPostalCode
        typeOfContact
      }
      otherReceiveTransaction {
        edges {
          node {
            id
            chartOfAccount {
              chartOfAccountCode
              name
            }
            productAndService {
              productCode
              name
            }
            keycardPerOtherReceiveTransaction{
              edges {
                node {
                  id
                  numberKey{
                    id
                    noNumber
                  }
                }
              }
            }
            description
            unitItems
            price
            discount
            vat
            preTaxAmount
            whtRate
            whtRatePercent
            accountRecord{
              id
            }
            total
          }
        }
      }
      creditCardTransaction {
        edges {
          node {
            id
            amount
          }
        }
      }
      
    }
    cashTransactionViewer {
      allCashTransaction(refNumber: $ref_transaction) {
        edges {
          node {
            id
            description
            price
          }
        }
      }
    }
    chequeDepositViewer {
      allChequeDeposit(refNumber: $ref_transaction) {
        edges {
          node {
            id
            price
            date
            chequeNumber
            bankName
            branchNumber
            chequeImage
          }
        }
      }
    }

    allBankAccountTransaction(description_Icontains: $ref_transaction) {
      edges {
        node {
          id
          price
          date
          imageSlip
          bankAccount {
            bankName
            accountNumber
          }
        }
      }
    }

    allAccountRecordGroup(refTransaction: $ref_transaction) {
      edges {
        node {
          id
          refNumber
          name
          added
          issuedDate
          category
          totalDebit
          totalCredit
          refTransaction
          creator
          contact {
            id
            name
            firstName
            lastName
          }
          accountRecord {
            edges {
              node {
                id
                name
                debit
                credit
                chartOfAccountCode {
                  id
                  name
                  chartOfAccountCode
                }
              }
            }
          }
        }
      }
    }
  }
`;

const clearGuaranteeKey = ["firstName", "lastName", "taxIdNumber", "address", "city", "province", "district", "postalCode", "email", "phone"];
class OtherReceiverView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
      listOtherUnknown: [],
      showModalView: false,
      clearGuaranteeNameAddress: []
    };

    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.getDataForEdit = this.getDataForEdit.bind(this);
    this.submit = this.submit.bind(this)
  }

  onChangeStatus() {
    Swal.fire({
      title: i18n.t("other_received:Are you sure to cancel this other received?"),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: i18n.t("other_received:Cancel"),
      confirmButtonText: i18n.t("other_received:Yes"),
    }).then(async (result) => {
      if (result.value) {
        let { value: void_remark } = await Swal.fire({
          title: i18n.t("other_received:Note"),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: i18n.t("other_received:Cancel"),
          confirmButtonText: i18n.t("other_received:Confirm"),
          inputValidator: (value) => {
            if (!value) {
              return i18n.t("other_received:Please enter the cancellation note!");
            }
          },
        });

        if (void_remark) {
          this.setState({ loading: true });
          let input = {
            clientMutationId: this.props.match.params.id,
            status: "void",
            voidRemark: void_remark,
          };
          ChangeStatusOtherReceiveMutation(
            input,
            (response) => {
              if (
                response.updateOtherReceiveAndOtherReceiveTransactionStatus.ok
              ) {
                Swal.fire(
                  i18n.t("other_received:Success"),
                  i18n.t("other_received:Other receipts have been canceled"),
                  "success"
                ).then(() => this.setState({ redirect: true, loading: false }));
              } else {
                Swal.fire(
                  i18n.t("other_received:Unsuccess"),
                  response.updateOtherReceiveAndOtherReceiveTransactionStatus
                    .warningText,
                  "warning"
                ).then(() => this.setState({ loading: false }));
              }
            },
            () => {
              this.setState({ loading: false });
              Swal.fire(
                i18n.t("other_received:Unsuccess"),
                i18n.t("other_received:Failed to cancel other receipts"),
                "error"
              );
            }
          );
        }
      } else {
        this.setState({ loading: false });
      }
    });
  }

  onChangeEdit() {
    Swal.fire({
      title: i18n.t('invoiceDetail:Are you sure to edit this invoice?'),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: i18n.t('invoiceDetail:No'),
      confirmButtonText: i18n.t('invoiceDetail:Yes'),
    }).then(async (result) => {
      if (result.value) {
        this.setState({ showModalView: true });
      }
    });
  }

  componentWillMount() {
    this.getDataForEdit()
  }


  getDataForEdit() {
    ///edit OtherReceiver address
    let state = { ...this.state };

    if (this.props.location.state) {
      let key = Object.keys(this.props.location.state);
      key.forEach((key) => {
        state[key] = this.props.location.state[key];
        state['address'] = this.props.location.state['registeredAddress'];
        state['registeredCity'] = this.props.location.state['registeredCity'];
        state['registeredDistrict'] = this.props.location.state['registeredDistrict'];
        state['registeredProvince'] = this.props.location.state['registeredProvince'];
        state['registeredPostalCode'] = this.props.location.state['registeredPostalCode'];
      });
    } else {
      clearGuaranteeKey.forEach((key) => {
        state[key] = '';
      });
    }

    let dataState = state
    fetchQuery(environment, query, { id: this.props.match.params.id, ref_transaction: this.props.match.params.ref_transaction, }).then((res) => {
      _.set(dataState, `firstName` , res.otherReceive?.firstName)
      _.set(dataState, `lastName`, res.otherReceive?.lastName)
      this.setState({
        clearGuaranteeNameAddress: dataState
      })
    })

    

  }

  handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    let state = _.set(_.cloneDeep(this.state), name, value);
    this.setState(state);
  }

  onSelect(fullAddress) {

    const { registeredDistrict, registeredCity, registeredProvince, registeredPostalCode } = fullAddress

    let value = _.cloneDeep(this.state.clearGuaranteeNameAddress);
    _.set(value, 'registeredDistrict', registeredDistrict)
    _.set(value, 'registeredCity', registeredCity)
    _.set(value, 'registeredProvince', registeredProvince)
    _.set(value, 'registeredPostalCode', registeredPostalCode)

    this.setState({
      clearGuaranteeNameAddress: value
    })

  }

  submit(e) {
    e.preventDefault();
    this.props.history.push({
      pathname: `/document/other-receiver/` +
        this.props.match.params.id + "/" +
        this.props.match.params.ref_transaction,
      state: {
        firstName: this.state.clearGuaranteeNameAddress.firstName,
        lastName: this.state.clearGuaranteeNameAddress.lastName,
        taxIdNumber: this.state.clearGuaranteeNameAddress.taxIdNumber,
        address: this.state.clearGuaranteeNameAddress.address,
        registeredCity: this.state.clearGuaranteeNameAddress.registeredCity,
        registeredProvince: this.state.clearGuaranteeNameAddress.registeredProvince,
        registeredDistrict: this.state.clearGuaranteeNameAddress.registeredDistrict,
        registeredPostalCode: this.state.clearGuaranteeNameAddress.registeredPostalCode,
      },
    })
  }

  getAddress = (address) => {
    let addres = address.address ? address.address : address.registeredAddress
    let district = address?.district ? address?.district : address.registeredDistrict
    let City = address?.city ? address?.city : address.registeredCity
    let provoince = address?.province ? address?.province : address?.registeredProvince
    let PostalCode = address?.postalCode ? address?.postalCode : address?.registeredPostalCode

    return (
      provoince?.includes("กรุงเทพ") || provoince?.includes("กทม") ? (
        <>
          {addres}
          {district && ` แขวง${district}`}
          {City && ` เขต${City}`}
          {provoince && ` ${provoince} `}
          {PostalCode}{" "}
          {/* {address.registeredCountry} */}
        </>
      ) : (
        <>
          {addres}
          {district && ` ตำบล${district}`}
          {City && ` อำเภอ${City}`}
          {provoince && ` จังหวัด${provoince} `}
          {PostalCode}{" "}
          {/* {address.registeredCountry} */}
        </>
      )
    )
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/accounting/income/other_receiver/list/void" />;
    }

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              id: this.props.match.params.id,
              ref_transaction: this.props.match.params.ref_transaction,
            }}
            render={({ error, props }) => {
              if (error) {
                return (
                  <div className="alert alert-danger" role="alert">
                    {error.message}
                  </div>
                );
              } else if (props) {
                let transaction_list = [];
                props.otherReceive.otherReceiveTransaction.edges.forEach(
                  (transaction) => {
                    transaction_list.push(transaction.node);
                  }
                );

                return (
                  <div className="container-fluid box" id="invoice-detail">
                    <div className="row justify-content-between">
                      <div className="col">
                        <Translation>
                          {t =>
                            <h3 className="mb-4">
                              <Link to="/accounting/income/other_receiver/list/all">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/wrapperContent/back.png"
                                  }
                                  alt="back"
                                  className="back"
                                />
                              </Link>
                              {t("other_received:Other received")+ " "+"(OR)"}
                            </h3>}
                        </Translation>
                      </div>
                      <div className="col text-right mb-2">
                        {props.otherReceive.status !== "VOID" &&
                          _.some(
                            JSON.parse(localStorage.getItem("permission_list")),
                            { codename: "accounting_other_receiver_delete" }
                          ) && (
                            <Translation>
                              {t =>
                                <button
                                  className="btn btn-danger add mr-3"
                                  disabled={this.state.loading}
                                  onClick={this.onChangeStatus}
                                >
                                  {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                  )}
                                  {t("other_received:Cancel the other receipts")}
                                </button>}
                            </Translation>
                          )}
                        <Translation>
                          {t =>
                            <button
                              className="btn btn-primary add mr-3"
                              onClick={this.onChangeEdit}
                            >
                              {t("other_received:Edit Name/Address")}
                            </button>
                          }
                        </Translation>
                        {_.some(
                          JSON.parse(localStorage.getItem("permission_list")),
                          { codename: "accounting_other_receiver_print" }
                        ) && (
                            <Link
                              to={
                                "/document/other-receiver/" +
                                props.otherReceive.id +
                                "/" +
                                props.otherReceive.docNumber
                              }
                              target={"_blank"}
                            >
                              <Translation>
                                {t =>
                                  <button
                                    className="btn btn-primary add"
                                    disabled={this.state.loading}
                                  >
                                    {t("other_received:Print Other received")}
                                  </button>}
                              </Translation>
                            </Link>
                          )}
                      </div>
                    </div>

                    <Modal show={this.state.showModalView} dialogClassName="modal-approve" id="modal" >
                      <Modal.Body style={{ fontFamily: 'Kanit', borderBottom: 'none' }}>
                        <div >
                          <h3 className="pt-2 pb-2">{i18next.t("editAddress:Edit customer name-surname / address")}</h3>

                          <form onSubmit={this.submit}>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label htmlFor="name">{i18next.t("editAddress:Name")}</label>
                                <input type="text" className="form-control" id="firstName" name="clearGuaranteeNameAddress.firstName"
                                  value={this.state.clearGuaranteeNameAddress.firstName}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="surname">{i18next.t("editAddress:Surname")}</label>
                                <input type="text" className="form-control" id="lastName" name="clearGuaranteeNameAddress.lastName"
                                  value={this.state.clearGuaranteeNameAddress.lastName}
                                  onChange={this.handleInputChange} />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="taxIdNumber">{i18next.t("editAddress:Tax payer identification/Identification number")}</label>
                              <input type="text" className="form-control" id="taxIdNumber" name="clearGuaranteeNameAddress.taxIdNumber" value={this.state?.clearGuaranteeNameAddress?.taxIdNumber?.replace('--', '')}
                                onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                              <label htmlFor="inputAddress2">{i18next.t("editAddress:Address")}</label>
                              <textarea cols="" rows="3" className="form-control" id="address"
                                name="clearGuaranteeNameAddress.address" maxLength={256} value={this.state.clearGuaranteeNameAddress.address} onChange={this.handleInputChange} />
                            </div>

                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label htmlFor="city">{i18next.t("editAddress:Sub-district")}</label>
                                <InputAddress
                                  address="clearGuaranteeNameAddress.registeredDistrict"
                                  value={this.state.clearGuaranteeNameAddress.registeredDistrict}
                                  onChange={this.handleInputChange}
                                  onSelect={this.onSelect}
                                  mailing={false}
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="city">{i18next.t("editAddress:District")}</label>
                                <InputAddress
                                  address="clearGuaranteeNameAddress.registeredCity"
                                  value={this.state.clearGuaranteeNameAddress.registeredCity}
                                  onChange={this.handleInputChange}
                                  onSelect={this.onSelect}
                                  mailing={false}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label htmlFor="inputState">{i18next.t("editAddress:Province")}</label>
                                <InputAddress
                                  address="clearGuaranteeNameAddress.registeredProvince"
                                  value={this.state.clearGuaranteeNameAddress.registeredProvince}
                                  onChange={this.handleInputChange}
                                  onSelect={this.onSelect}
                                  mailing={false}
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="inputZip">{i18next.t("editAddress:Zip code")}</label>
                                <InputAddress
                                  address="clearGuaranteeNameAddress.registeredPostalCode"
                                  value={this.state.clearGuaranteeNameAddress.registeredPostalCode}
                                  onChange={this.handleInputChange}
                                  onSelect={this.onSelect}
                                  mailing={false}
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="col-12  mt-3 text-right">

                              <button type="button" className="btn btn-danger mr-2" onClick={() => this.setState({ showModalView: false })}>
                                <span>{i18next.t("editAddress:Cancel")}</span>
                              </button>
                              <button
                                className="btn btn-primary"
                                disabled={this.state.loading} type="submit"
                              >
                                {i18next.t("editAddress:Print")}{localStorage.getItem("language") === "th" && "บันทึกรายรับอื่นๆ"}
                              </button>
                            </div>
                          </form>
                        </div>
                      </Modal.Body>
                    </Modal>


                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <p>
                              <Translation>{t => <strong>{t("other_received:Customer")}</strong>}</Translation>
                            </p>
                            <p>
                              {props.otherReceive.contact?.typeOfContact === "RESIDENTIAL" ?
                                props.otherReceive.contact.refNumber + "  " +
                                getNameResidential(
                                  props.otherReceive.firstName,
                                  props.otherReceive.lastName
                                ) :
                                props.otherReceive.contact ? props.otherReceive.contact.refNumber + "  " + props.otherReceive.contact.name : props.otherReceive.unknownContact || "-"
                              }
                              {<span style={{color: '#F43853'}}>
                                    {" "}{props.otherReceive.payGroup === "RENT" ? `(${i18next.t("AgentRole:Renter")})` : 
                                        props.otherReceive.payGroup === "AGENT" ? `(${i18next.t("AgentRole:Agent")})`:
                                          ""
                                    }
                              </span>}  
                              {/* {props.otherReceive.contact
                                ? props.otherReceive.contact.typeOfContact ===
                                  "RESIDENTIAL"
                                  ? props.otherReceive.contact.refNumber +
                                    " " +
                                    getNameResidential(
                                      props.otherReceive.contact.firstName,
                                      props.otherReceive.contact.lastName
                                    )
                                  : props.otherReceive.contact.refNumber +
                                    " " +
                                    props.otherReceive.contact.name
                                : props.otherReceive.unknownContact || "-"} */}
                            </p>
                          </div>
                          <div className="col">
                            <p>
                              <Translation>{t => <strong>{t("other_received:No.")}</strong>}</Translation>
                            </p>
                            <p>{props.otherReceive.docNumber} </p>
                          </div>
                          <div className="col">
                            <p>
                              <Translation>{t => <strong>{t("other_received:Date of issue")}</strong>}</Translation>
                            </p>
                            <p>
                              {format(
                                props.otherReceive.issuedDate,
                                "DD/MM/YYYY",
                                { locale: thLocale }
                              )}
                            </p>
                          </div>
                          <div className="col">
                            <p>
                              <Translation>{t => <strong>{t("other_received:Note")}</strong>}</Translation>
                            </p>
                            <p>{props.otherReceive.remark}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col">
                            <p>
                              <Translation>{t => <strong>{t("other_received:Address")}</strong>}</Translation>
                            </p>
                            <p>
                              {props.otherReceive.contact ? (
                                <>
                                  {this.getAddress(props.otherReceive.address ? {
                                    address: props.otherReceive.address,
                                    city: props.otherReceive.city,
                                    district: props.otherReceive.district,
                                    postalCode: props.otherReceive.postalCode,
                                    province: props.otherReceive.province
                                  } : props.otherReceive.contact)}
                                  {/* {" "}
                                  {props.otherReceive.contact.registeredAddress}
                                  {props.otherReceive.contact
                                    .registeredProvince === "กรุงเทพมหานคร"
                                    ? " แขวง"
                                    : " ตำบล"}
                                  {
                                    props.otherReceive.contact
                                      .registeredDistrict
                                  }
                                  {props.otherReceive.contact
                                    .registeredProvince === "กรุงเทพมหานคร"
                                    ? " เขต"
                                    : " อำเภอ"}
                                  {props.otherReceive.contact.registeredCity}{" "}
                                  {props.otherReceive.contact
                                    .registeredProvince === "กรุงเทพมหานคร"
                                    ? props.otherReceive.contact
                                        .registeredProvince
                                    : `จังหวัด${props.otherReceive.contact.registeredProvince} `}{" "}
                                  {
                                    props.otherReceive.contact
                                      .registeredPostalCode
                                  }
                                  {props.otherReceive.contact.registeredCountry} */}
                                </>
                              ) : (
                                "-"
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="table-responsive fade-up">
                          <table className="table table-hover mt-2">
                            <thead className="thead-light">
                              <Translation>
                                {t =>
                                  <tr>
                                    <th>{t("other_received:List")}</th>
                                    <th>{t("other_received:Description")}</th>
                                    <th className="text-center">{t("other_received:Amount_")}</th>
                                    <th className="text-right">{t("other_received:Price")}</th>
                                    <th className="text-right">{t("other_received:Discount")}</th>
                                    <th className="text-right">{t("other_received:Tax")}</th>
                                    <th className="text-right">{t("other_received:Balance before tax")}</th>
                                    <th className="text-right">{t("other_received:Withholding")}</th>
                                    <th className="text-right">{t("other_received:No.Number")}</th>
                                  </tr>}
                              </Translation>
                            </thead>
                            {props.otherReceive.otherReceiveTransaction.edges.map(
                              (transaction) => (
                                <tbody key={transaction.node.id}>
                                  <tr>
                                    {transaction.node.chartOfAccount ? (
                                      <td>
                                        {
                                          transaction.node.chartOfAccount
                                            .chartOfAccountCode
                                        }{" "}
                                        {transaction.node.chartOfAccount.name}
                                      </td>
                                    ) : (
                                      <td>
                                        {
                                          transaction.node.productAndService
                                            .productCode
                                        }{" "}
                                        {
                                          transaction.node.productAndService
                                            .name
                                        }
                                      </td>
                                    )}

                                    <td>{transaction.node.description}</td>
                                    <td className="text-center">
                                      {transaction.node.unitItems}
                                    </td>
                                    <td className="text-right">
                                      {numberWithComma(transaction.node.price)}
                                    </td>
                                    <td className="text-right">
                                      {numberWithComma(
                                        transaction.node.discount
                                      )}
                                    </td>

                                    {transaction.node.vat === 0 ? (
                                      <td className="text-right">0%</td>
                                    ) : transaction.node.vat === 7 ? (
                                      <td className="text-right">7%</td>
                                    ) : (
                                      <Translation>{t => <td className="text-right">{t("other_received:None")}</td>}</Translation>
                                    )}

                                    <td className="text-right">
                                      {transaction.node.preTaxAmount}
                                    </td>
                                    <td className="text-right">
                                      {transaction.node.whtRate || "-"}
                                    </td>
                                    {transaction.node.keycardPerOtherReceiveTransaction.edges.length > 0 ?
                                      <td className="text-right">{transaction.node.keycardPerOtherReceiveTransaction.edges[0].node.numberKey.noNumber}</td>
                                      :
                                      <td />
                                    }
                                  </tr>
                                </tbody>
                              )
                            )}
                          </table>
                        </div>


                        {props.otherReceive.unknownReceive &&
                          props.otherReceive.unknownReceive?.edges.length >
                          0 && (
                            <div className="card mt-3">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col">
                                    <h6>รายการเงินรอตรวจสอบ</h6>
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col">
                                    <div className="table-responsive fade-up">
                                      <table className="table table-hover">
                                        <thead className="thead-light">
                                          <Translation>
                                            {t =>
                                              <tr>
                                                <th style={{ width: "20%" }}>
                                                  {t("other_received:No.")}
                                                </th>
                                                <th style={{ width: "30%" }}>
                                                  {t("other_received:List")}
                                                </th>
                                                <th style={{ width: "40%" }}>
                                                  {t("other_received:Description")}
                                                </th>
                                                <th
                                                  className="text-right"
                                                  style={{ width: "10%" }}
                                                >
                                                  {t("other_received:Amount")}
                                                </th>
                                              </tr>}
                                          </Translation>
                                        </thead>
                                        <tbody>
                                          {props.otherReceive.unknownReceive.edges.map(
                                            (unknown_receive) => (
                                              <tr key={unknown_receive.node.id}>
                                                <td>
                                                  {
                                                    unknown_receive.node
                                                      .docNumber
                                                  }
                                                </td>
                                                <td>
                                                  {
                                                    unknown_receive.node
                                                      .chartOfAccount
                                                      .chartOfAccountCode
                                                  }{" "}
                                                  {
                                                    unknown_receive.node
                                                      .chartOfAccount.name
                                                  }
                                                </td>
                                                <td>
                                                  {
                                                    unknown_receive.node
                                                      .description
                                                  }
                                                </td>
                                                <td className="text-right">
                                                  {numberWithComma(
                                                    unknown_receive.node.amount
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        <PayChannel
                          total={props.otherReceive.total}
                          bankTransaction={
                            props.allBankAccountTransaction.edges
                          }
                          cashTransaction={
                            props.cashTransactionViewer.allCashTransaction.edges
                          }
                          chequeTransaction={
                            props.chequeDepositViewer.allChequeDeposit.edges
                          }
                          creditCardTransaction={
                            props.otherReceive.creditCardTransaction.edges
                          }
                          allAccountRecordGroup={
                            props.allAccountRecordGroup.edges
                          }
                          otherReceive={props.otherReceive.unknownReceive}
                          otherReceiveTransaction={props.otherReceive.otherReceiveTransaction.edges}
                          isOther={true}
                          account_type="receive"
                        />

                        <InvoiceSummary
                          remark={props.otherReceive.remark}
                          transaction_list={transaction_list}
                          remark_edit={true}
                          other_expense={true}
                          taxType={props.otherReceive.taxType}
                          carTransaction={props.otherReceive.carTransaction}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
              return <Loading />
            }}
          />
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default OtherReceiverView;
