import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import Header from "../../components/header/";
import Sidebar from "../../components/sidebar/";
import Wrapper from "../../components/wrapper/";
import { Link, NavLink } from "react-router-dom";
import Navigation from "./dailyJournalNavigation";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import numberWithComma from "../../libs/numberWithComma";
import Pagination from "../../libs/newPagination";
import ComponentPagination from "../../libs/componentPagination";
import { Translation } from "react-i18next";
import { format } from "date-fns";
import th from "date-fns/locale/th";
import en from "date-fns/locale/en";
import i18n from "i18next";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import Swal from "sweetalert2";
import ExportDailyJournal from "./exportDailyJournal";
import Loading from "../../libs/loading"
import BackButtonIcon from "../../components/BackBtn/indexBack";
import i18next from "i18next";

let languages = {
  th: th,
  en: en,
};

const query = graphql`
  query dailyJournalAllQuery(
    $first: Int
    $last: Int
    $category: String
    $status: String
    $start_date: DateTime
    $end_date: DateTime
    $search: String
    $order: String
  ) {
    allAccountRecordGroup(
      first: $first
      last: $last
      category: $category
      status: $status
      startDate: $start_date
      endDate: $end_date
      search: $search
      order: $order
    ) {
      edges {
        node {
          id
          refNumber
          refTransaction
          refNumberSecond
          name
          added
          issuedDate
          refPayExpenseDate
          category
          status
          totalDebit
          totalCredit
          contact {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`;

const _ = require("lodash");

class DailyJournal extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.start_date = null;
    this.state.end_date = null;
    this.state.temp_start_date = null;
    this.state.temp_end_date = null;
    this.state.search = "";
    this.state.search_input = "";
    this.state.order = "-ref_number";
    this.state.status = "";
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    this.setState({
      category: this.props.match.params.category,
      status: this.props.match.params.status,
      order: "-ref_number",
    });
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (
      nextProps.match.params.category !== this.state.category ||
      nextProps.match.params.status !== this.state.status
    ) {
      this.setState(
        {
          category: nextProps.match.params.category,
          status: nextProps.match.params.status,
          check_all: false,
          checkList: [],
          checkRefNumber: [],
          checkRefTrans: [],
        },
        () => {
          this.goFirst();
        }
      );
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.setState({
        checkList: [],
        check_all: false,
        checkRefNumber: [],
        checkRefTrans: [],
      });
    });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  sortNumber() {
    let order_opposite = "";
    if (this.state.order.split("-")[1]) {
      order_opposite = this.state.order.split("-")[1];
    } else {
      order_opposite = "-" + this.state.order;
    }

    return (
      <span
        onClick={() => this.setState({ order: order_opposite })}
        className="cursor"
      >
        {i18next.t("dailyjournalList:Number")}
        {this.state.order.split("-")[1] ? (
          <img
            src={process.env.PUBLIC_URL + "/images/icons/sort-down.png"}
            alt="sort-down-icon"
            className="ml-1"
          />
        ) : (
          <img
            src={process.env.PUBLIC_URL + "/images/icons/sort-up.png"}
            alt="sort-up-icon"
            className="ml-1"
          />
        )}
      </span>
    );
  }

  onClickPrintAll() {
    Swal.fire("กรุณาเลือกรายการ!", "", "warning");
  }

  getStatusText(accountRecordGroup) {
    let text = "";
    let color = "text-center";
    switch (accountRecordGroup.node.status) {
      case "VALID":
        text = "ปกติ";
        break;
      case "INVALID":
        text = "รอการแก้ไข";
        break;
      default:
        text = i18next.t("Allaction:cancel");
        color = "text-center text-danger";
    }
    return <td className={color}>{text}</td>;
  }

  getShortName(value){
    let text = "";
    switch (value) {
      case "purchase":
        text = "RR";
        break;
      case "sales":
        text = "SV";
        break;
      case "payment":
        text = "PV";
        break;
      case "receive":
        text = "RV";
        break;
      case "general":
        text = "JV";
        break;
    }

    return "("+ text +")"
  }

  render() {
    console.log("this.props.match.params.category",this.props.match.params.category);
    
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting"}
                  LinkText={i18next.t(`dailyjournal:${this.state.category}`)+" "+this.getShortName(this.state.category)}
                  boxHtmlText={
                    <div
                      className="row col text-right"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <ExportDailyJournal
                        category={this.state.category}
                        start_date={this.state.start_date}
                        end_date={this.state.end_date}
                        search={this.state.search}
                        order={this.state.order}
                        status={this.state.status}
                      />
                      {(_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "daily_journal_payment_create",
                      }) ||
                        _.some(JSON.parse(localStorage.getItem("permission_list")), {
                          codename: "daily_journal_receive_create",
                        }) ||
                        _.some(JSON.parse(localStorage.getItem("permission_list")), {
                          codename: "daily_journal_general_create",
                        })) && (
                          <NavLink
                            to={
                              "/accounting/daily-journal/create/" + this.state.category
                            }
                          >
                            <button type="button" className="btn btn-primary add">
                              <img
                                src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                                alt="plus"
                              />
                              {i18next.t("dailyjournalList:Accounting Record")}
                            </button>
                          </NavLink>
                        )}
                    </div>

                  }
                />
                <div className="content-inner">
                  <Navigation category={this.state.category} />
                  <div className="row mt-1">
                    <div className="col-12">
                      <div className="form-inline float-left">
                        {this.state.checkList.length > 0 ? (
                          <Link
                            to={{
                              pathname:
                                "/accounting/daily-journal/account/daily-journal/all",
                              state: {
                                checkList: this.state.checkList,
                                checkRefTrans: this.state.checkRefTrans,
                                checkRefNumber: this.state.checkRefNumber,
                              },
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/icons/print-icon.png"
                                }
                                alt="print-icon"
                              />
                            </button>
                          </Link>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={this.onClickPrintAll}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icons/print-icon.png"
                              }
                              alt="print-icon"
                            />
                          </button>
                        )}
                        <div className="form-group mb-2">
                          <label htmlFor="end_date" className="ml-2 mr-3">
                          {i18next.t("dailyjournalList:Sort by date")}
                          </label>
                          <DatePickerAdapter
                            id="temp_end_date"
                            className="form-control"
                            name="temp_start_date"
                            selected={this.state.temp_start_date}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label htmlFor="end_date" className="ml-3 mr-3">
                          {i18next.t("dailyjournalList:To")}
                          </label>
                          <DatePickerAdapter
                            id="temp_end_date"
                            name="temp_end_date"
                            className="form-control"
                            selected={this.state.temp_end_date}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-inline float-right">
                        <div className="input-group ">
                          <input
                            type="text"
                            className="form-control input-size"
                            placeholder={i18next.t("AllFilter:Search")}
                            value={this.state.search_input}
                            name="search"
                            onChange={(e) =>
                              this.setState({ search_input: e.target.value })
                            }
                            onKeyDown={this.handleKeyDown}
                          />
                          <button
                            type="submit"
                            className="btn btn-primary form-control search-button"
                            name="search"
                            value={this.state.search_input}
                            onClick={(e) => this.handleSearch(e)}
                          >
                            {i18next.t("dailyjournalList:Search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <QueryRenderer
                      environment={environment}
                      query={query}
                      variables={{
                        first: this.state.first,
                        last: this.state.last,
                        category: this.state.category,
                        status: this.state.status,
                        start_date: this.state.start_date,
                        end_date: this.state.end_date,
                        search: this.state.search,
                        order: this.state.order,
                      }}
                      render={({ error, props }) => {
                        if (error) {
                          return (
                            <div className="alert alert-danger" role="alert">
                              {error.message}
                            </div>
                          );
                        } else if (props) {
                          return (
                            <React.Fragment>
                              <div className="col-12">
                                <div className="table-responsive fade-up card">
                                  <table className="table table-hover">
                                    <thead className="thead-light">
                                      <tr>
                                        <th>
                                          <input
                                            type="checkbox"
                                            onChange={() =>
                                              this.getAllListDaily(
                                                props.allAccountRecordGroup.edges
                                              )
                                            }
                                            checked={this.state.check_all}
                                          ></input>
                                        </th>
                                        <th width={130}>{this.sortNumber()}</th>
                                        <th>{i18next.t("dailyjournalList:Date")}</th>
                                        <th width={140}>{i18next.t("dailyjournalList:Reference")}</th>
                                        <th>{i18next.t("dailyjournalList:Description")}</th>
                                        {/*<th>สมุดบัญชี</th>*/}
                                        <th className="text-center">{i18next.t("dailyjournalList:Debit")}</th>
                                        <th className="text-center">{i18next.t("dailyjournalList:Credit")}</th>
                                        <th className="text-center">{i18next.t("dailyjournalList:Status")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {props.allAccountRecordGroup.edges.map(
                                        (chart) => {
                                          return (
                                            <tr
                                              key={chart.node.id}
                                              data-toggle="modal"
                                              data-target="#daily-journal-detail"
                                            >
                                              <td>
                                                {!(
                                                  chart.node.status === "INVALID" ||
                                                  chart.node.status === "VOID"
                                                ) && (
                                                    <input
                                                      type="checkbox"
                                                      onChange={() =>
                                                        this.appendCheckListDaily(
                                                          chart.node.id,
                                                          chart.node.refTransaction,
                                                          chart.node.refNumber
                                                        )
                                                      }
                                                      checked={this.checkList(
                                                        chart.node.id
                                                      )}
                                                    ></input>
                                                  )}
                                              </td>
                                              <td>
                                                {chart.node.refTransaction?.search(
                                                  /BT/
                                                ) !== 0 ? (
                                                  <Link
                                                    to={`/accounting/daily-journal/${this.state.category
                                                      }/detail/${chart.node.refNumber
                                                      }/${chart.node.refTransaction ||
                                                      chart.node.refNumber
                                                      }`}
                                                  >
                                                    {chart.node.refNumber}
                                                  </Link>
                                                ) : (
                                                  <Link
                                                    to={
                                                      "/accounting/finance/bank-transfer/transfer-detail/" +
                                                      chart.node.refTransaction
                                                    }
                                                  >
                                                    {chart.node.refNumber}
                                                  </Link>
                                                )}
                                              </td>
                                              <td>
                                                {format(
                                                  (chart.node.refPayExpenseDate || chart.node.issuedDate),
                                                  "DD/MM/YYYY",
                                                  {
                                                    locale:
                                                      languages[
                                                      i18n.t("custom:code")
                                                      ],
                                                  }
                                                )}
                                              </td>
                                              <td>
                                                {!chart.node.refTransaction?.startsWith(
                                                  chart.node.refNumber?.substring(
                                                    0,
                                                    2
                                                  )
                                                )
                                                  ? chart.node.refTransaction || "-"
                                                  : "-"}
                                              </td>
                                              <td>
                                                {/* {chart.node.name.length >= 80
                                              ? chart.node.name.substring(
                                                  0,
                                                  80
                                                ) + "..."
                                              : chart.node.name} */}
                                                {chart.node.name}
                                              </td>
                                              <td className="text-right">
                                                {numberWithComma(
                                                  chart.node.totalDebit
                                                )}
                                              </td>
                                              <td className="text-right">
                                                {numberWithComma(
                                                  chart.node.totalCredit
                                                )}
                                              </td>
                                              {this.getStatusText(chart)}
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={props.allAccountRecordGroup.totalCount}
                              />
                            </React.Fragment>
                          );
                        }
                        return <Loading />;
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          </Translation>
        </div>
      </Wrapper>
    );
  }
}

export default DailyJournal;
