import React from "react";
import { Translation } from "react-i18next";
import Wrapper from "../../components/wrapper";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Navigation from "./navigation"
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import {Link} from "react-router-dom";
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import ComponentPagination from "../../libs/componentPagination";
import Pagination from "../../libs/newPagination";
import SearchProject from "../searchProject";
import { format } from "date-fns";
import Swal from "sweetalert2";
import i18n from "i18next";
import {commitMutation, QueryRenderer} from 'react-relay'
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import _ from 'lodash'
import Loading from '../../libs/loading';

const mutation = graphql `
mutation juristicManagerListMutation($input: ChangeManagerInput!) {
    changeManager (input: $input) {
        ok
        message
    }
}`

const query = graphql `
query juristicManagerListQuery($search: String,$first: Int, $last: Int){
    currentManager{
        edges{
            node{
                id
                image
                signature
                prefix
                firstName
                lastName
                address
                department
                position
                affiliation
                phone
                email
                state
                note
                online
                term{
                   edges{
                      node{
                          startDate
                          expDate
                      } 
                   } 
                }
            }
        }
        totalCount
    }
    oldManager (search: $search,first:$first, last: $last) {
        edges{
            node{
                id
                image
                signature
                prefix
                firstName
                lastName
                address
                department
                position
                affiliation
                phone
                email
                state
                note
                online
                term{
                   edges{
                      node{
                          startDate
                          expDate
                      } 
                   } 
                }
            }
        }
        totalCount
    }
}

`
class JuristicManagerList extends ComponentPagination {

    constructor(props){
        super(props);
        this.state = {
            status : this.props.match.params.status,
            search : '',
            juristicManagerList : [],
            loading : false,
            redirect : false,
            isChange : false,
        }
        this.handleSearch = this.handleSearch.bind(this)
        this.onChangeJuristic = this.onChangeJuristic.bind(this)
    }

    componentDidUpdate(){
        if(this.state.status !== this.props.match.params.status){
            this.setState({
                status : this.props.match.params.status,
                first: this.state.first,
                last: this.state.last,
            },() => this.goFirst())
        }
    }

    handleSearch(text){
        this.setState({search: text})
    }

    onChangeJuristic(){
        Swal.fire({
            title: i18n.t('juristicManager:Are you sure to change Juristic Manager?'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t('juristicManager:Confirm'),
            cancelButtonText: i18n.t('juristicManager:Cancel')
        }).then(async (result) => {
            if (result.value) {
                let { value: void_remark } = await Swal.fire({
                    title: i18n.t("juristicManager:Note for change Juristic manager"),
                    input: "text",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: i18n.t('juristicManager:Cancel'),
                    confirmButtonText: i18n.t('juristicManager:Confirm'),
                    inputValidator: (value) => {
                      if (!value) {
                        return i18n.t("juristicManager:Please enter a note on the change of juristic manager.");
                      }
                    },
                  });

                  if (void_remark) {
                    this.setState({ loading: true });
                    let variables = {
                        input: {
                            note: void_remark,
                        }
                    };
                
                    commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            onCompleted:(response) => {
                                if (response.changeManager.ok) {
                                    Swal.fire(
                                        i18n.t("juristicManager:Success!"),
                                        i18n.t("juristicManager:Changed the Juristic manager complete"),
                                        "success"
                                        ).then(() => this.setState({ redirect: true, loading: false }) ,);
                                    } else {
                                        Swal.fire(
                                            i18n.t("juristicManager:Unsuccess to change committee"),
                                            response.changeManager.message,
                                            "warning"
                                        ).then(() => this.setState({ loading: false }));
                                    }
                                },
                            onError: (err) => {
                                this.setState({ loading: false });
                                Swal.fire(
                                    i18n.t("juristicManager:Unsuccess to change committee"),
                                    i18n.t("juristicManager:Please try again"),
                                    "error"
                                );
                            },
                        },
                    )
                  }
            }else{
                this.setState({loading: false});
            }
        });
    }

    render(){        
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        return(
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <ProjectTopMenuNavigation mini={true}/>
                    <Translation>
                        {t => 
                            <div className="container-fluid box" id="projectDashboard">
                                <QueryRenderer 
                                    environment={environment}
                                    query={query}
                                    variables={{
                                        search: this.state.search,
                                        first: this.state.first,
                                        last: this.state.last,
                                        status: this.state.status,
                                        redirect:this.state.redirect
                                    }}
                                    render={({error,props}) => {
                                        if(error){
                                            return <div>{error.message}</div>;
                                        }else if(props){
                                            let juristicManagerList = this.state.status === 'current' ? props.currentManager : props.oldManager
                                            return (
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-md-3 col-xl-3">
                                                            <h3 className="mb-4">
                                                                <Link to="/project">
                                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                        alt="back" className="back"/>
                                                                </Link>
                                                                <span>{
                                                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                textData?.data?.juristicManager.juristicManager
                                                                :                                                                
                                                                t('juristicManager:Juristic Manager')
                                                                }</span>
                                                            </h3>
                                                        </div>
                                                        {this.state.status === 'current' &&
                                                            <div className="col-9 float-right ">
                                                                {props.currentManager.edges.length > 0 ?
                                                                    _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_juristicmanager_create' }) &&
                                                                    <button type="button" className="add btn btn-primary float-right" onClick={this.onChangeJuristic}>
                                                                        {this.state.loading &&
                                                                            <span className="spinner-border spinner-border-sm align-middle mr-2"/>
                                                                        }
                                                                        <span>+ {
                                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                        textData?.data?.juristicManager.changeJuristicManager
                                                                        :                                                                              
                                                                        t('juristicManager:Change juristic manager')}
                                                                        </span>
                                                                    </button>
                                                                    :
                                                                    _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_juristicmanager_create' }) &&
                                                                    <Link to="/project/juristic-manager/change-manager">
                                                                        <button type="button" className="add btn btn-primary float-right" >
                                                                            <span>+ {t('juristicManager:Add the new Juristic Manager')}</span>
                                                                        </button>
                                                                    </Link> 
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="content-inner">
                                                        <Navigation status={this.state.status}/>
                                                        
                                                        {this.state.status === 'history' &&
                                                            <div className="row mt-2">
                                                                <div className="col-9"/>
                                                                <div className="col-3 float-right input-group">
                                                                    <SearchProject callback_function={this.handleSearch} search={this.state.search}/>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="mt-3">
                                                            <div className="table-responsive " >
                                                                <table className="table table-hover">
                                                                    <thead className="thead-light ">
                                                                        <tr>
                                                                            <th/>
                                                                            <th className="text-left " >{t("juristicManager:Name - surname")}</th>
                                                                            <th className="text-center " >{t("juristicManager:Position")}</th>
                                                                            <th className="text-center " >{t("juristicManager:Date of position")}</th>
                                                                            {this.state.status === 'history' && <th className="text-center " >{t("juristicManager:Date of end position")}</th>}
                                                                            {this.state.status === 'history' && <th className="text-center " >{t("juristicManager:Note")}</th>}
                                                                            {/* {this.state.status === 'current' && <th className="text-center " >{t("juristicManager:Status Online")}</th>} */}
                                                                            {this.state.status === 'current' && <th />}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {juristicManagerList.edges.length > 0  && juristicManagerList.edges.map((person,index) => {
                                                                            return(
                                                                                <tr key={index}>
                                                                                    <td className="text-center">
                                                                                        {person.node.image ?
                                                                                            <div
                                                                                                className="rounded-circle avatar-img juristic profile-img img-border"
                                                                                                style={{backgroundImage: `url(${person.node.image})`}}>
                                                                                            </div>
                                                                                            :
                                                                                            <img
                                                                                                src={process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                                                className="rounded-circle avatar-img  juristic profile-img"
                                                                                                alt="project-manager-profile"/>
                                                                                        }
                                                                                    </td>
                                                                                    <td className="text-left">
                                                                                        <Link
                                                                                            to={"/project/juristic-manager/edit/" +
                                                                                                person.node.id
                                                                                            }
                                                                                        >
                                                                                            {person.node.firstName} {person.node.lastName}
                                                                                        </Link></td>
                                                                                    <td className="text-center">{person.node.position}</td>
                                                                                    <td className="text-center">{person?.node.term.edges[0].node.startDate && format(person.node.term.edges[0].node.startDate,'DD/MM/YYYY')}</td>
                                                                                    {this.state.status==='history'&&<td className="text-center">{person.node.term.edges[0].node.expDate && format(person.node.term.edges[0].node.expDate,'DD/MM/YYYY')}</td>}
                                                                                    {this.state.status === 'history' && <td className="text-center">{person.node.note}</td>}
                                                                                    {/* {this.state.status === 'current' && <td className="text-center">{person.node.statusOnline && format(person.node.statusOnline , 'DD/MM/YYYY HH:mm')}</td>} */}
                                                                                    {this.state.status === 'current' &&
                                                                                    <td className="text-center">
                                                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_juristicmanager_edit' }) &&
                                                                                            <React.Fragment>
                                                                                                <span className="btn cursor" id="dropdownMenuButton"
                                                                                                    data-toggle="dropdown" aria-haspopup="true"  aria-expanded="false">
                                                                                                    <img  src={process.env.PUBLIC_URL +"/images/icons/choice-trans.png"} alt="choice"/>
                                                                                                </span>
                                                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                                    <Link  to={"/project/juristic-manager/change-manager/" +person.node.id} className="text-black">
                                                                                                        <span className="dropdown-item cursor">
                                                                                                            <span className="ml-3"> 
                                                                                                                    {t("projectManager:Edit")}
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </td>}
                                                                                </tr>
                                                                            );
                                                                        })}
    
                                                                    </tbody>
                                                                </table>
                                                            </div> 
                                                            {this.state.status === 'history' && 
                                                                <div className="row">
                                                                    <Pagination
                                                                    changePage={this.changePage}
                                                                    first={this.state.first}
                                                                    last={this.state.last}
                                                                    totalCount={juristicManagerList.totalCount}
                                                                    />
                                                                </div>
                                                            }
                                                        </div> 
                                                    </div>  
                                                </React.Fragment>                     
                                            );
                                        } 
                                        return <Loading/>
                                    }}
                                />
                                
                            </div>
                        } 
                    </Translation>
                    
                </WrapperContent>

            </Wrapper>
        );
    }


}
export default JuristicManagerList