import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import ReceiptDeposit from './receiptDeposit';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperReceiptDepositQuery($id: ID!){
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        receiptDeposit(id: $id) {
            id
            docNumber
            issuedDate
            status
            total
            taxType
            
            firstName lastName
            address district city province postalCode


            name
            taxIdNumber
            email
            phone
            
            contact {
                refNumber
                name
                taxIdNumber
                firstName
                lastName
                phone
                email
                typeOfCompany
                typeOfSupplier
                typeOfContact
                nameBranch
                registeredAddress
                registeredCountry
                registeredProvince
                registeredDistrict
                registeredCity
                registeredPostalCode
                residential {
                    id
                    size
                    ownershipRatio
                }
            }
            remark
            receiptDepositTransaction{
                edges{
                    node{
                        id
                        chartOfAccount{
                            id name chartOfAccountCode
                        }
                        vat
                        price
                        description
                        receiptDepositTransactionUsed{
                            edges{
                                node{
                                    id
                                    amount
                                    transaction{
                                        invoice{
                                            docNumber
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            receiptDepositTransactionUsed{
                edges{
                    node{
                        id
                        amount
                    }
                }
            }
            bankAccountTransaction{
                edges{
                    node{
                        id
                        price
                        date
                        imageSlip
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            cashTransaction{
                edges{
                    node{
                        id
                        price
                    }
                }
            }
            chequeDeposit{
                edges{
                    node{
                        id
                        date
                        bankName
                        price
                        chequeNumber
                        branchNumber
                    }
                }
            }
            creditCardTransaction{
                edges{
                    node{
                        amount
                    }
                }

            }
        }
        userConfig {
            id languages
        }
    }
`;

class WrapperReceiptDeposit extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.adjustHeight = this.adjustHeight.bind(this);
    }

    print() {
        window.print();
    }

    adjustHeight() {

    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Receipt deposit</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{
                        id: this.props.match.params.id,
                        ref_transaction: this.props.match.params.ref_transaction
                    }}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return <ReceiptDeposit query={props}
                                            bank_transaction={props.receiptDeposit.bankAccountTransaction.edges}
                                            cash_transaction={props.receiptDeposit.cashTransaction.edges}
                                            cheque_transaction={props.receiptDeposit.chequeDeposit.edges}
                                            remark = {props.receiptDeposit.remark}
                                            credit_card_transaction={props.receiptDeposit.creditCardTransaction.edges}
                                            />
                                            
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperReceiptDeposit;
