/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUnknownReceiveContactInput = {|
  contact: string,
  receiptDepositId: string,
  issuedDate: any,
  payGroup: string,
  clientMutationId?: ?string,
|};
export type unknownReceiveDetailMutationVariables = {|
  input: UpdateUnknownReceiveContactInput
|};
export type unknownReceiveDetailMutationResponse = {|
  +updateUnknownReceiveContact: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type unknownReceiveDetailMutation = {|
  variables: unknownReceiveDetailMutationVariables,
  response: unknownReceiveDetailMutationResponse,
|};
*/


/*
mutation unknownReceiveDetailMutation(
  $input: UpdateUnknownReceiveContactInput!
) {
  updateUnknownReceiveContact(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUnknownReceiveContactPayload",
    "kind": "LinkedField",
    "name": "updateUnknownReceiveContact",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "unknownReceiveDetailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "unknownReceiveDetailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "45b44a95cb614a4ecf10cfecf63f18b0",
    "id": null,
    "metadata": {},
    "name": "unknownReceiveDetailMutation",
    "operationKind": "mutation",
    "text": "mutation unknownReceiveDetailMutation(\n  $input: UpdateUnknownReceiveContactInput!\n) {\n  updateUnknownReceiveContact(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa0323254d6d840e07afdda9925e8095';

module.exports = node;
