import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from 'i18next'
import '../styles/register.scss'

import Navigation from "./navigation";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { commitMutation } from "react-relay";
import { fetchQuery } from "relay-runtime";
import { format, parse } from "date-fns";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import _ from 'lodash';
import getDateTime from '../../libs/getDateTime'
import updateAddressMutation from './updateAddressMutation'
import upload from '../../libs/upload'
import jwtDecode from 'jwt-decode'
import OwnerAddressDetail from './addressDetail/ownerAddressDetail';
import RenterAddressDetail from './addressDetail/renterAddressDetail';
import AgentAddressDetail from './addressDetail/agentAddressDetail';
import i18next from 'i18next';

const key = {
    "อาคารพาณิชย์": "commercial building",
    "ตึกแถว": "commercial building",
    "ทาวน์โฮม": "townhome",
    "ทาวน์เฮาส์": "townhouse",
    "บ้านแฝด": "semi-detached house",
    "บ้านเดี่ยว": "detached house",
    "คอนโด": "condominium",
    "สตูดิโอ": "studio",
    "1 ห้องนอน": "1 bedroom",
    "2 ห้องนอน": "2 bedrooms",
    "3 ห้องนอน": "3 bedrooms",
    "4 ห้องนอน": "4 bedrooms",
    "5 ห้องนอน": "5 bedrooms",
    "6 ห้องนอน": "6 bedrooms",
    "7 ห้องนอน": "7 bedrooms",
    "8 ห้องนอน": "8 bedrooms",
    "9 ห้องนอน": "9 bedrooms",
    "10 ห้องนอน": "10 bedrooms",
    "ดูเพล็กซ์": "duplex",
    "เพนท์เฮ้าส์": "penthouse",
    "ออฟฟิศสำนักงาน": "office",
    "ศูนย์การค้า": "shopping center",
    "ร้านค้า": "shop",
    "หอพัก": "dormitory",
    "ที่ดินเปล่า": "vacant land",
    "อื่นๆ": "others",
}

const mutation = graphql`
    mutation updateRoomDetailMutation($input: UpdateRoomDetailInput!){
        updateRoomDetail(input: $input){
            ok
        }
    }
`;

const query = graphql`
    query updateRoomDetailQuery($id: ID!) {
        residential(id: $id){
            id
            plan
            name
            floor
            size
            status
            otherStatus
            address
            ownershipNumber
            ownershipRatio
            homeNumber
            villageBuilding
            villageNumber
            lane
            soi
            postalCode
            province
            district
            subDistrict
            mailingVillageBuilding
            mailingVillageNumber
            mailingLane
            mailingPostalCode
            mailingProvince
            mailingDistrict
            mailingSubDistrict
            leaseCode
            phase
            building
            waterMeterCode
            electricMeterCode
            dateConveyance
            dateStay
            decorating
            dateBox
            address
            ownershipNumber
            unitNumber


            # ที่อยู๋ผู้เช่า
            renterHomeNumber
            renterVillageBuilding
            renterVillageNumber
            renterLane
            renterSoi
            renterProvince
            renterDistrict
            renterSubDistrict
            renterPostalCode

            # ที่อยู๋ตัวแทน
            agentHomeNumber
            agentVillageBuilding
            agentVillageNumber
            agentLane
            agentSoi
            agentProvince
            agentDistrict
            agentSubDistrict
            agentPostalCode

            residentialDocument{
                edges{
                    node{
                        id
                        fileName
                        fileUpload
                    }
                }
            }
            deed{
                edges{
                    node{
                        id
                        fileName
                        fileUpload
                    }
                }
            }
            residentialLeaseDocument{
                edges{
                    node{
                        id
                        fileName
                        fileUpload
                    }
                }
            }
            residentialHouseholder(type:"owner"){
                edges{
                    node{
                        id
                        tenant{
                            firstName
                            lastName
                        }
                    }
                }
            }
            type{
                id
                name
            }
            project{
                typeOfProject
            }
            conveyance
            owmerInsure{
                   edges{
                      node{
                          id
                          expDate
                          insure {
                            id
                            name
                            description
                        }
                      } 
                   } 
                }
            obligationDeveloper{
                   edges{
                      node{
                          id
                          dueDate
                          productAndService{
                              id
                              name
                          }
                      } 
                   } 
                }
            car{
                   edges{
                      node{
                          id
                          carBrand
                      } 
                   } 
                }
        }
    }

`;

class UpdateRoomDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            statusTab: "owner",
            id: '',
            name: '',
            unit_no: '',
            type_name: '',
            size: '',
            status: '',
            other_status: '',
            owner: '',
            floor: '',
            ownership_number: '',
            ownership_first_name: '',
            ownership_last_name: '',
            ownership_full_name: '',
            conveyance: '',
            date_conveyance: new Date(),
            loading: false,
            redirectToList: false,
            leaseCode: '',
            residential: {},
            owmerInsure: [],
            plan: '',
            obligationDeveloper: [],
            homeNumber: '',
            villageBuilding: '',
            villageNumber: '',
            lane: '',
            soi: '',
            postalCode: '',
            province: '',
            district: '',
            subDistrict: '',

            renterHomeNumber: '',
            renterVillageBuilding: '',
            renterVillageNumber: '',
            renterLane: '',
            renterSoi: '',
            renterPostalCode: '',
            renterProvince: '',
            renterDistrict: '',
            renterSubDistrict: '',

            agentHomeNumber: '',
            agentVillageBuilding: '',
            agentVillageNumber: '',
            agentLane: '',
            agentSoi: '',
            agentPostalCode: '',
            agentProvince: '',
            agentDistrict: '',
            agentSubDistrict: '', 


            uploadContractRent: [],
            uploadRoomPlan: [],
            uploadDocument: []
        };
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.uploadFile = this.uploadFile.bind(this)
        this.dateFile = this.dateFile.bind(this)
    }

    componentWillMount() {
        if (this.props.match.params.room_id) {
            fetchQuery(environment, query, { id: this.props.match.params.room_id }).then(data => {
                if (data.residential) {
                    this.setState({
                        id: data.residential.id,
                        name: data.residential.name,
                        type_name: data.residential.type.name,
                        size: data.residential.size,
                        status: data.residential.status,
                        other_status: data.residential.otherStatus ? data.residential.otherStatus : null,
                        floor: data.residential.floor,
                        ownership_number: data.residential.ownershipNumber,
                        conveyance: data.residential.conveyance,
                        date_conveyance: data.residential.dateConveyance ? parse(data.residential.dateConveyance) : '',
                        type_of_project: data.residential.project.typeOfProject,
                        residential: data.residential,
                        leaseCode: data.residential.leaseCode,
                        phase: data.residential.phase,
                        building: data.residential.building,
                        electricMeterCode: data.residential.electricMeterCode,
                        waterMeterCode: data.residential.waterMeterCode,
                        dateStay: data.residential.dateStay,
                        decorating: data.residential.decorating,
                        dateBox: data.residential.dateBox,
                        plan: data.residential.plan,


                        homeNumber: data.residential.homeNumber,
                        villageBuilding: data.residential.villageBuilding,
                        villageNumber: data.residential.villageNumber,
                        lane: data.residential.lane,
                        soi: data.residential.soi,
                        postalCode: data.residential.postalCode,
                        province: data.residential.province,
                        district: data.residential.district,
                        subDistrict: data.residential.subDistrict,

                        renterHomeNumber: data.residential.renterHomeNumber,
                        renterVillageBuilding: data.residential.renterVillageBuilding,
                        renterVillageNumber: data.residential.renterVillageNumber,
                        renterLane: data.residential.renterLane,
                        renterSoi: data.residential.renterSoi,
                        renterPostalCode: data.residential.renterPostalCode,
                        renterProvince: data.residential.renterProvince,
                        renterDistrict: data.residential.renterDistrict,
                        renterSubDistrict: data.residential.renterSubDistrict,

                        agentHomeNumber: data.residential.agentHomeNumber,
                        agentVillageBuilding: data.residential.agentVillageBuilding,
                        agentVillageNumber: data.residential.agentVillageNumber,
                        agentLane: data.residential.agentLane,
                        agentSoi: data.residential.agentSoi,
                        agentPostalCode: data.residential.agentPostalCode,
                        agentProvince: data.residential.agentProvince,
                        agentDistrict: data.residential.agentDistrict,
                        agentSubDistrict: data.residential.agentSubDistrict,


                        owmerInsure: data.residential.owmerInsure?.edges ? data.residential.owmerInsure.edges : [],
                        obligationDeveloper: data.residential.obligationDeveloper?.edges ? data.residential.obligationDeveloper.edges : [],
                        uploadDocument: data.residential.residentialDocument?.edges?.map((n) =>
                            ({ ...n.node, status: "", viewPtath: n.node.fileUpload })) || [],
                        uploadRoomPlan: data.residential.deed?.edges?.map((n) =>
                            ({ ...n.node, status: "", viewPtath: n.node.fileUpload })) || [],
                        uploadContractRent: data.residential.residentialLeaseDocument?.edges?.map((n) =>
                            ({ ...n.node, status: "", viewPtath: n.node.fileUpload })) || [],
                    });

                    if (data.residential.residentialHouseholder.edges.length > 0) {
                        let ownerFullName = ''
                        data.residential.residentialHouseholder.edges.forEach(owner => {
                            ownerFullName += `${owner.node.tenant.firstName} ${owner.node.tenant.lastName},`
                        });

                        ownerFullName = ownerFullName.slice(0, -1) + '';
                        this.setState({
                            ownership_first_name: data.residential.residentialHouseholder.edges[0].node.tenant.firstName,
                            ownership_last_name: data.residential.residentialHouseholder.edges[0].node.tenant.lastName,
                            ownership_full_name: ownerFullName,
                            unit_no: data.residential.unitNumber

                        });
                    }
                }
            });
        }
    }

    handleChangeInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeAddress = (e) => {
        let event = _.cloneDeep(e)
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                event.target.vale
            );
        })
    }

    onSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        let residentialLeaseDocument = this.state.uploadContractRent.filter((n) => n.status !== "" || n.id === "")
        let uploadRoomPlan = this.state.uploadRoomPlan.filter((n) => n.status !== "" || n.id === "")
        let residentialDocument = this.state.uploadDocument.filter((n) => n.status !== "" || n.id === "")

        let roomObject = {
            id: this.state.id,
            status: this.state.status,
            other_status: this.state.other_status,
            floor: this.state.floor,
            ownership_number: this.state.ownership_number,
            conveyance: this.state.conveyance,
            leaseCode: this.state.leaseCode,
            phase: this.state.phase,
            building: this.state.building,
            dateStay: this.state.dateStay === null ? null : format(this.state.dateStay, 'YYYY-MM-DD'),
            dateBox: this.state.dateBox === null ? null : format(this.state.dateBox, 'YYYY-MM-DD'),
            decorating: this.state.decorating,
            waterMeterCode: this.state.waterMeterCode,
            plan: this.state.plan,
            electricMeterCode: this.state.electricMeterCode,
            // date_conveyance: this.state.date_conveyance ? format(this.state.date_conveyance, 'YYYY-MM-DD') : '',
        };
        let variables = {
            input: {
                roomObject: JSON.stringify(roomObject),
                deed: JSON.stringify({ deed: uploadRoomPlan }),
                residentialLeaseDocument: JSON.stringify({ residentialLeaseDocument: residentialLeaseDocument }),
                residentialDocument: JSON.stringify({ residentialDocument: residentialDocument }),
            }
        };
        let addressObject = {
            id: this.state.id,
            home_number: this.state.homeNumber,
            village_building: this.state.villageBuilding,
            village_number: this.state.villageNumber,
            lane: this.state.lane,
            soi: this.state.soi,
            postal_code: this.state.postalCode,
            province: this.state.province,
            district: this.state.district,
            sub_district: this.state.subDistrict,
        };

        let renterAddressObject = {
            id: this.state.id,
            renter_home_number: this.state.renterHomeNumber,
            renter_village_building: this.state.renterVillageBuilding,
            renter_village_number: this.state.renterVillageNumber,
            renter_lane: this.state.renterLane,
            renter_soi: this.state.renterSoi,
            renter_postal_code: this.state.renterPostalCode,
            renter_province: this.state.renterProvince,
            renter_district: this.state.renterDistrict,
            renter_sub_district: this.state.renterSubDistrict,
        };

        let agentAddressObject = {
            id: this.state.id,
            agent_home_number: this.state.agentHomeNumber,
            agent_village_building: this.state.agentVillageBuilding,
            agent_village_number: this.state.agentVillageNumber,
            agent_lane: this.state.agentLane,
            agent_soi: this.state.agentSoi,
            agent_postal_code: this.state.agentPostalCode,
            agent_province: this.state.agentProvince,
            agent_district: this.state.agentDistrict,
            agent_sub_district: this.state.agentSubDistrict,
        };

        let address = {
            input: {
                addressObject: JSON.stringify(addressObject),
                renterAddressObject: JSON.stringify(renterAddressObject),
                agentAddressObject: JSON.stringify(agentAddressObject),
            }
        }
        await updateAddressMutation(address, (data) => {
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        this.setState({ loading: false });
                        if (response.updateRoomDetail.ok) {
                            Swal.fire(i18n.t("residential:Save successfully!"), '', 'success').then(() => {
                                this.setState({ redirectToList: true })
                            });
                        } else {
                            Swal.fire(i18n.t("residential:Save unsuccessfully!"), '', 'warning');
                        }
                    },
                    onError: (err) => {
                        this.setState({ loading: false });
                        Swal.fire(i18n.t("residential:Save unsuccessfully!"), '', 'error');
                    },
                },
            );
        }, (error) => {
            Swal.fire(i18n.t("residential:Save unsuccessfully!"), '', 'error');
        })
    }

    isBangkok = (code) => {
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];
        if (_.indexOf(bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix = (type, code) => {
        if (type === "subdistrict" && this.isBangkok(code)) {
            return "แขวง"
        } else if (type === "district" && this.isBangkok(code)) {
            return "เขต"
        } else if (type === "province" && this.isBangkok(code)) {
            return ""
        } else if (type === "province") {
            return "จ."
        } else if (type === "subdistrict") {
            return "ต."
        } else if (type === "district") {
            return "อ."
        } else if (type === "lane") {
            return "ถ."
        } else if (type === "soi") {
            return "ซ."
        }
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/residential" + path, file);
            resolve(res);
        });
    };
    uploadFile(e) {
        let event = _.cloneDeep(e);
        let files = event.currentTarget.files[0];
        if (files) {
            if (files?.size > 10485760) { //2097152  10485760

                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }

            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg' && files?.type !== 'application/pdf' && files?.type !== 'application/msword') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .png, .jpg, .jpeg , .pdf, .doc ${i18next.t("Allaction:Only")} ${i18next.t("Allaction:and each file should not exceed")} 10 MB`, 'error')
            }
            else {
                let user = jwtDecode(localStorage.getItem("token"))
                let typeFile = files.type.split("/")
                // fileIsSel = false
                let new_file = new File([files], `${user.user_id}-${Math.floor(Math.random() * 100001)}${format(new Date(), "YYYYMMDDHHmmss")}.${typeFile[1]}`);
                this.callUpload('', new_file).then((data) => {
                    let dese = "residential/" + new_file.name
                    let fileUpload = []
                    switch (event.target.name) {
                        case "uploadContractRent": fileUpload = _.cloneDeep(this.state.uploadContractRent);
                            break;
                        case "uploadRoomPlan": fileUpload = _.cloneDeep(this.state.uploadRoomPlan)
                            break;
                        case "uploadDocument": fileUpload = _.cloneDeep(this.state.uploadDocument)
                            break;

                        default:
                            break;
                    }
                    fileUpload.push({ id: "", fileName: new_file.name, fileUpload: dese, viewPtath: data.location, status: "" })
                    this.setState({ [event.target.name]: fileUpload })
                });
            }
        }
    }

    dateFile = (index, data, forAction) => {

        // let cloneDataFile = _.cloneDeep(this.state.fileUpload)
        let DataFile = []
        switch (forAction) {
            case "uploadContractRent": DataFile = _.cloneDeep(this.state.uploadContractRent);
                break;
            case "uploadRoomPlan": DataFile = _.cloneDeep(this.state.uploadRoomPlan)
                break;
            case "uploadDocument": DataFile = _.cloneDeep(this.state.uploadDocument)
                break;
            default:
                break;
        }

        let fileupload = []
        if (data.node) {
            _.set(DataFile, "[" + index + "].node.status", "delete")
            fileupload = _.get(DataFile, "[" + index + "].node")
        } else {
            _.set(DataFile, "[" + index + "].status", "delete")
            fileupload = _.get(DataFile, "[" + index + "]")
        }

        if (DataFile.findIndex(ex => ex.fileId === fileupload.fileId) < 0) {
            DataFile.push(fileupload)
        }

        if (data.node?.status === '') {
            // [forAction] เซตคล้าย [e.target.value]
            this.setState({ [forAction]: DataFile })
        } else if (data.status === "") {
            this.setState({ [forAction]: DataFile })
        }
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to={{
                pathname: "/register/residential/detail/room-detail/" + this.props.match.params.id
            }} />
        }

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid p-4" id="form-detail">
                        <div className="row justify-content-between">
                            <div className="col header-color">
                                <h3 className="mb-4">
                                    <Link
                                        to={{ pathname: "/register/residential/detail/room-detail/" + this.props.match.params.id }}>
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    {this.state.name}
                                </h3>
                                <div className="content-inner">
                                    <Navigation id={this.props.match.params.id} name={this.state.name} />
                                </div>
                            </div>
                        </div>
                        <form action="" onSubmit={this.onSubmit}>
                            <div className="content-inner staff-create">
                                <div className="row mb-4 fade-up">
                                    <div className="col-sm-12 col-md-6 col-xl-6">
                                        <Translation>
                                            {t =>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="col mt-5">
                                                            <React.Fragment>
                                                                {
                                                                    <div className="row  mb-3">
                                                                        <div className="col-4">
                                                                            <span>{t("residential:Unit No")}</span>
                                                                        </div>
                                                                        <div className="col">
                                                                            {this.state.unit_no ? this.state.unit_no : '-'}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                            <div className="row  mb-3">
                                                                <div className="col-4">
                                                                    {this.state.type_of_project === "CONDOMINIUM" ?
                                                                        <span>{t("residential:Room No.")}</span> : <span>{t("residential:House No.")}</span>
                                                                    }
                                                                </div>
                                                                <div className="col">
                                                                    {this.state.name}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-3">
                                                                <div className="col-4">
                                                                    {
                                                                        this.state.type_of_project === "CONDOMINIUM" ?
                                                                            <>{t("residential:Floor")}</> : <>{t("residential:Number of floor")}</>
                                                                    }
                                                                </div>
                                                                <div className="col">
                                                                    <input type="text" onChange={this.handleChangeInput} value={this.state.floor || ''} name="floor" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="row  mb-3">
                                                                <div className="col-4">
                                                                    {t("residential:Area_size")}
                                                                </div>
                                                                <div className="col">
                                                                    {this.state.size}
                                                                    {
                                                                        this.state.type_of_project === "CONDOMINIUM" ?
                                                                            t("residential:Sq m") : t("residential:Sq wa")
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="row  mb-3">
                                                                <div className="col-4">
                                                                    {t("residential:Ownership ratio")}
                                                                </div>
                                                                <div className="col">
                                                                    {this.state.residential.ownershipRatio ? this.state.residential.ownershipRatio : '-'}
                                                                </div>
                                                            </div>
                                                            <div className="row  mb-3">
                                                                <div className="col-4">
                                                                    {t("residential:Status")}
                                                                </div>
                                                                <div className="col">
                                                                    <select className="custom-select form-control"
                                                                        value={this.state.status}
                                                                        name="status"
                                                                        onChange={this.handleChangeInput}>
                                                                        <option value="LIVE">{t("residential:Live")}</option>
                                                                        <option value="FOR_RENT">{t("residential:For rent")}</option>
                                                                        <option value="SEIZURE">{t("residential:Seizure")}</option>
                                                                        <option value="AVAILABILITY">{t("residential:Availability")}</option>
                                                                        <option value="OTHER">{t("residential:Other")}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {this.state.status === "OTHER" &&
                                                                <div className="row  mb-3">
                                                                        <div className="col-4">
                                                                            {t("resident:Specify")}  
                                                                        </div>
                                                                        <div className="col">
                                                                            <input type="text" className="form-control"
                                                                                name="other_status"
                                                                                onChange={this.handleChangeInput}
                                                                                value={this.state.other_status || ''}
                                                                                //defaultValue={""}
                                                                                required={false}
                                                                            />
                                                                        </div>
                                                                </div>
                                                            }
                                                            <div className="row  mb-3">
                                                                <div className="col-4">
                                                                    {t("residential:Lease agreement no.")}
                                                                </div>
                                                                <div className="col">
                                                                    <input type="text" onChange={this.handleChangeInput} value={this.state.leaseCode || ''} name="leaseCode" className="form-control" />
                                                                </div>
                                                            </div>


                                                            {/* แนบสัญญาเช่า */}
                                                            <>
                                                                {
                                                                    // this.state.status === "FOR_RENT" &&
                                                                    <div className="row  mb-3">
                                                                        {/* <div className="col-4">
                                                                            <div className="uploadBTN">
                                                                                <label htmlFor="uploadContractRent" className="upload-btn-wrapper mt-0">
                                                                                    <button className={"btn"} type='button'>
                                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                                            className='mr-3' />
                                                                                        {"แนบสัญญาเช่า"}
                                                                                    </button>
                                                                                    <input type="file" name='uploadContractRent' id="uploadContractRent"
                                                                                        className='form-control uploadFile'
                                                                                        accept='image/*' onChange={this.uploadFile}
                                                                                    />
                                                                                </label>
                                                                            </div>
                                                                        </div> */}
                                                                        {/* <div className="col">
                                                                            <div className='withDocument'>
                                                                                <span style={{ color: '#808080' }}>{"รองรับไฟล์ในประเภท PDF ,DOC ,PNG ,JPG  และไฟล์ขนาดไม่เกิน 2 MB "}</span>
                                                                            </div>
                                                                        </div> */}



                                                                        <div className='col-12'>
                                                                            {
                                                                                this.state.uploadContractRent?.length > 0 &&
                                                                                this.state.uploadContractRent?.map((file, inx) => {
                                                                                    if (file.node) {
                                                                                        let typeFile = file.node.fileName.split(".");
                                                                                        return (
                                                                                            file.node.status !== "delete" &&
                                                                                            <p key={inx}>
                                                                                                <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                                <Link
                                                                                                    to="route"
                                                                                                    onClick={(event) => {
                                                                                                        event.preventDefault();
                                                                                                        window.open(
                                                                                                            typeof file.node.viewPtath === "string"
                                                                                                                ? file.node.viewPtath
                                                                                                                : URL.createObjectURL(file.node.viewPtath)
                                                                                                        )
                                                                                                    }}
                                                                                                    target="blank">
                                                                                                    {file.node.fileName}
                                                                                                </Link>
                                                                                                &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "uploadContractRent")}>
                                                                                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                                </label>
                                                                                            </p>
                                                                                        )
                                                                                    } else {
                                                                                        let typeFile = file.fileName.split(".")
                                                                                        return (
                                                                                            file.status !== "delete" &&
                                                                                            <p key={inx}>
                                                                                                <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                                <Link
                                                                                                    to="route"
                                                                                                    onClick={(event) => {
                                                                                                        event.preventDefault();
                                                                                                        window.open(
                                                                                                            typeof file.viewPtath === "string"
                                                                                                                ? file.viewPtath
                                                                                                                : URL.createObjectURL(file.viewPtath)
                                                                                                        )
                                                                                                    }}
                                                                                                    target="blank">
                                                                                                    {file.fileName}
                                                                                                </Link>
                                                                                                &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "uploadContractRent")}>
                                                                                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                                </label>
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>



                                                                    </div>
                                                                }



                                                            </>


                                                            <div className="row  mb-3">
                                                                <div className="col-4">
                                                                    {t("residential:Type")}
                                                                </div>
                                                                <div className="col ">
                                                                    {
                                                                        i18n.language=='th'?
                                                                        this.state.type_name :
                                                                        key[this.state.type_name]
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="row  mb-3">
                                                                <div className="col-4">
                                                                    {t("residential:Phase")}
                                                                </div>
                                                                <div className="col ">
                                                                    <input type="text" onChange={this.handleChangeInput} value={this.state.phase || ''} name="phase" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="row  mb-3">
                                                                <div className="col-4">
                                                                    {t("residential:Plan")}
                                                                </div>
                                                                <div className="col ">
                                                                    <input type="text" onChange={this.handleChangeInput} value={this.state.plan || ''} name="plan" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="row  mb-3">
                                                                <div className="col-4">
                                                                    {t("residential:Building no.")}
                                                                </div>
                                                                <div className="col ">
                                                                    <input type="text" onChange={this.handleChangeInput} value={this.state.building || ''} name="building" className="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                        </Translation>
                                        <Translation>
                                            {t =>

                                                <div className="card show mt-3">
                                                    <div className="card-body">
                                                        <div className="row  mb-3">
                                                            <div className="col-4">
                                                            {t("residential:Electric meter no.")}
                                                            </div>
                                                            <div className="col">
                                                                <input type="text" onChange={this.handleChangeInput} value={this.state.electricMeterCode || ''} name="electricMeterCode" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-4">
                                                            {t("residential:Water meter no.")}
                                                            </div>
                                                            <div className="col">
                                                                <input type="text" onChange={this.handleChangeInput} value={this.state.waterMeterCode || ''} name="waterMeterCode" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="row  mb-3">
                                                            <div className="col-4">
                                                            {t("residential:Transfer status by developer")}
                                                            </div>
                                                            <div className="row ml-1">                                                                                                                                                <div className="col">
                                                                <div
                                                                    className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio"
                                                                        className="custom-control-input"
                                                                        checked={this.state.residential.conveyance === 'ALREADY_TRANSFERRED'}
                                                                        disabled />
                                                                    <label
                                                                        className="custom-control-label">{t("residential:Transferred  ownership")}</label>
                                                                </div>
                                                            </div>
                                                                <div>
                                                                    <div
                                                                        className="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio"
                                                                            className="custom-control-input"
                                                                            checked={this.state.residential.conveyance === 'NOT_TRANSFERRED'}
                                                                            disabled />
                                                                        <label
                                                                            className="custom-control-label">{t("residential:Not transfer ownership")}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row  mb-3">
                                                            <div className="col-4">
                                                                {t("residential:Latest transfer date")}
                                                            </div>
                                                            <div className="col">
                                                                {this.state.residential.dateConveyance ? format(this.state.residential.dateConveyance, 'DD/MM/YYYY') : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="row  mb-3">
                                                            <div className="col-4">
                                                                {t("residential:Ownership")}
                                                            </div>
                                                            <div className="col">
                                                                {this.state.ownership_full_name}
                                                            </div>
                                                        </div>
                                                        <div className="row  mb-3">
                                                            <div className="col-4">
                                                            {t("residential:Stay date")}
                                                            </div>
                                                            <div className="col ">
                                                                <DatePickerAdapter
                                                                    name="dateStay"
                                                                    onChange={this.handleChangeInput}
                                                                    selected={getDateTime(this.state.dateStay)}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            }
                                        </Translation>
                                        <Translation>
                                            {t =>
                                        <div className="card show mt-3">
                                            <div className="card-body">
                                                <div className="row  mb-3">
                                                    <div className="col-4">
                                                        {t("residential:Decorating")}
                                                    </div>
                                                    <div className="col ">
                                                        <input type="text" onChange={this.handleChangeInput} value={this.state.decorating || ''} name="decorating" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            }
                                        </Translation>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-xl-5">
                                        <div className="card show">
                                            <div className="card-body mb-3 colorUse">
                                                <Translation>
                                                    {t =>
                                                        <React.Fragment>
                                                            <h6 className="mb-4 float-left">{t('register:register_address')}</h6>
                                                            <div className="clearfix" />
                                                            <div className="row" id="navigation-tab">
                                                                <div className="col">
                                                                    <ul>
                                                                        <li className={`nav-item ${this.state.statusTab === "owner" ? "active" : ""}`}>
                                                                            <a className={`nav-link`} href="#" onClick={() => this.setState({ statusTab: "owner" })}> {t("AgentRole:Resident/Co-owner")}</a>
                                                                        </li>
                                                                        <li className={`nav-item ${this.state.statusTab === "renter" ? "active" : ""}`}>
                                                                            <a className={`nav-link`} href="#" onClick={() => this.setState({ statusTab: "renter" })}> {t("AgentRole:Rent")}</a>
                                                                        </li>
                                                                        <li className={`nav-item ${this.state.statusTab === "agent" ? "active" : ""}`}>
                                                                            <a className={`nav-link`} href="#" onClick={() => this.setState({ statusTab: "agent" })}> {t("AgentRole:Agent")}</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </Translation>
                                                <div className="clearfix" />
                                                {
                                                    this.state.statusTab === "owner" ?
                                                        <OwnerAddressDetail handleChangeInputAddress={this.handleChangeInput} state={this.state} /> :
                                                    this.state.statusTab === "renter" ?
                                                        <RenterAddressDetail handleChangeInputAddress={this.handleChangeInput} state={this.state} /> :
                                                        <AgentAddressDetail handleChangeInputAddress={this.handleChangeInput} state={this.state} />
                                                }
                                            </div>
                                        </div>

                                        {/* แนบเอกสารประกอบ */}
                                        <div className="card mt-3">
                                            <div className="card-body">
                                                <div className='withDocument'>
                                                    <label className='headerLabel'>แนบเอกสารประกอบ</label><br />
                                                    <span>{"รองรับไฟล์ในประเภท .png, .jpg, และ .jpeg เท่านั้น และไฟล์ขนาดไม่เกิน 10 MB "}</span>
                                                </div>
                                            <Translation>
                                                {t =>
                                                <div className="row mt-4">
                                                    <div className="col-4" >
                                                        <div className="uploadBTN">
                                                            <label htmlFor="uploadRoomPlan" className="upload-btn-wrapper mt-0">
                                                                <button className={"btn"} type='button'>
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                        className='mr-1' />
                                                                    {t("residential:Attach a deed")}
                                                                </button>
                                                                <input type="file" name='uploadRoomPlan' id="uploadRoomPlan"
                                                                    className='form-control uploadFile'
                                                                    accept="application/pdf,image/*,.doc,.docx"
                                                                    onChange={this.uploadFile}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='withDocument'>
                                                            <span>{t("residential:Only PDF, DOC, PNG and JPG files are supported, and their size must not exceed 10 MB.")}</span>
                                                        </div>
                                                    </div>

                                                    <div className='col-12'>
                                                        {
                                                            this.state.uploadRoomPlan?.length > 0 &&
                                                            this.state.uploadRoomPlan?.map((file, inx) => {
                                                                if (file.node) {
                                                                    let typeFile = file.node.fileName.split(".")
                                                                    return (
                                                                        file.node.status !== "delete" &&
                                                                        <p key={inx}>
                                                                            <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                            <Link
                                                                                to="route"
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    window.open(
                                                                                        typeof file.node.viewPtath === "string"
                                                                                            ? file.node.viewPtath
                                                                                            : URL.createObjectURL(file.node.viewPtath)
                                                                                    )
                                                                                }}
                                                                                target="blank">
                                                                                {file.node.fileName}
                                                                            </Link>
                                                                            &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "uploadRoomPlan")}>
                                                                                <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                            </label>
                                                                        </p>
                                                                    )
                                                                } else {
                                                                    let typeFile = file.fileName.split(".")
                                                                    return (
                                                                        file.status !== "delete" &&
                                                                        <p key={inx}>
                                                                            <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                            <Link
                                                                                to="route"
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    window.open(
                                                                                        typeof file.viewPtath === "string"
                                                                                            ? file.viewPtath
                                                                                            : URL.createObjectURL(file.viewPtath)
                                                                                    )
                                                                                }}
                                                                                target="blank">
                                                                                {file.fileName}
                                                                            </Link>
                                                                            &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "uploadRoomPlan")}>
                                                                                <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                            </label>
                                                                        </p>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>}
                                                </Translation>
                                            </div>
                                        </div>

                                        {/* แนบเอกสารประกอบ */}
                                    <Translation>
                                            {t =>
                                        <div className="card mt-3">
                                            <div className="card-body">
                                                <div className='withDocument'>
                                                    <label className='headerLabel'>{t("residential:Choose File")}</label><br />
                                                    <span>{t("residential:Only png, jpg,, jpeg files are supported, and their size must not exceed 10 MB.")}</span>
                                                </div>
                                                <hr />

                                                <div className="row mt-4">
                                                    <div className='col-4' >
                                                        <div className="uploadBTN">
                                                            <label htmlFor="uploadDocument" className="upload-btn-wrapper mt-0">
                                                                <button className={"btn"} type='button' >
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                        className='mr-1' />
                                                                    {t("residential:Attach file")}
                                                                </button>

                                                                <input type="file" name='uploadDocument' id="uploadDocument"
                                                                    className='form-control uploadFile'
                                                                    accept="application/pdf,image/*,.doc,.docx"
                                                                    onChange={this.uploadFile}
                                                                />

                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='withDocument'>
                                                            <span>{t("residential:Only png, jpg, jpeg files are supported, and their size must not exceed 10 MB.")}</span>
                                                        </div>
                                                    </div>

                                                    <div className='col-12'>
                                                        {
                                                            this.state.uploadDocument?.length > 0 &&
                                                            this.state.uploadDocument?.map((file, inx) => {
                                                                if (file.node && file.node.status !== "delete") {
                                                                    let typeFile = file.node.fileName.split(".")
                                                                    return (
                                                                        <p key={inx}>
                                                                            <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                            <Link
                                                                                to="route"
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    window.open(
                                                                                        typeof file.node.viewPtath === "string"
                                                                                            ? file.node.viewPtath
                                                                                            : URL.createObjectURL(file.node.viewPtath)
                                                                                    )
                                                                                }}
                                                                                target="blank">
                                                                                {file.node.fileName}
                                                                            </Link>
                                                                            &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "uploadDocument")}>
                                                                                <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                            </label>
                                                                        </p>
                                                                    )
                                                                } else {
                                                                    let typeFile = file.fileName.split(".")
                                                                    return (
                                                                        file.status !== "delete" &&
                                                                        <p key={inx}>
                                                                            <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                            <Link
                                                                                to="route"
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    window.open(
                                                                                        typeof file.viewPtath === "string"
                                                                                            ? file.viewPtath
                                                                                            : URL.createObjectURL(file.viewPtath)
                                                                                    )
                                                                                }}
                                                                                target="blank">
                                                                                {file.fileName}
                                                                            </Link>
                                                                            &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "uploadDocument")}>
                                                                                <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                            </label>
                                                                        </p>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </Translation>
                                    <Translation>
                                        {t =>
                                        <div className="card show mt-3">
                                            <div className="card-body">
                                                <div className="row  mb-3">
                                                    <div className="col-6">
                                                        <strong>{t("residential:Warranty")}</strong>
                                                    </div>
                                                    <div className="col ">
                                                        <strong>{t("residential:Due date")}</strong>
                                                    </div>
                                                </div>
                                                {this.state.owmerInsure.length > 0 ?
                                                    this.state.owmerInsure.map((insure, index) => {
                                                        return (
                                                            <div className="row mb-3" key={insure.node.id}>
                                                                <div className="col-1">
                                                                    <input type="checkbox" disabled={true} checked={true} />
                                                                </div>
                                                                <div className="col-5">
                                                                    {insure.node.insure.name}
                                                                </div>
                                                                <div className="col">
                                                                    {format(insure.node.expDate, 'DD/MM/YYYY')}
                                                                </div>
                                                            </div>

                                                        );
                                                    })

                                                    :

                                                    <div className="row mb-3">
                                                        <div className="col">
                                                            -
                                                        </div>
                                                    </div>

                                                }
                                                <div className="row mt-4 mb-3">
                                                    <div className="col-6">
                                                        <strong>{t("residential:Marketing policy")}</strong>
                                                    </div>
                                                    <div className="col ">
                                                        <strong>{t("residential:Due date")}</strong>
                                                    </div>
                                                </div>
                                                {this.state.obligationDeveloper.length > 0 ?
                                                    this.state.obligationDeveloper.map((obligation, index) => {
                                                        return (
                                                            <div className="row mb-3" key={obligation.node.id}>
                                                                <div className="col-1">
                                                                    <input type="checkbox" disabled={true} checked={true} />
                                                                </div>
                                                                <div className="col-5">
                                                                    {obligation.node.productAndService.name}
                                                                </div>
                                                                <div className="col">
                                                                    {format(obligation.node.dueDate, 'DD/MM/YYYY')}
                                                                </div>
                                                            </div>

                                                        );
                                                    })
                                                    :
                                                    <div className="row mb-3">
                                                        <div className="col">
                                                            -
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>}
                                    </Translation>

                                    <Translation>
                                        {t =>
                                        <div className="card show mt-3">
                                            <div className="card-body">
                                                <div className="row  mb-3">
                                                    <div className="col-6">
                                                        {t("residential:Welcome box date")}
                                                    </div>
                                                    <div className="col ">
                                                        <DatePickerAdapter
                                                            name="dateBox"
                                                            onChange={this.handleChangeInput}
                                                            selected={getDateTime(this.state.dateBox)}
                                                            className="form-control"
                                                        />

                                                    </div>
                                                </div>

                                            </div>
                                        </div>}
                                    </Translation>

                                        <div className="row mt-4">
                                            <div className="col">
                                                <Translation>
                                                    {t => <button type="submit" disabled={this.state.loading}
                                                        className="btn btn-primary bt-style form-update">{t("residential:Save")}
                                                        {
                                                            this.state.loading &&
                                                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                                        }
                                                    </button>}
                                                </Translation>
                                            </div>
                                            <div className="col">
                                                <Link
                                                    to={{ pathname: "/register/residential/detail/room-detail/" + this.props.match.params.id }}>
                                                    <Translation>
                                                        {t => <button className="btn btn-secondary bt-style form-update">
                                                            {t("residential:Cancel")}
                                                        </button>}
                                                    </Translation>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default UpdateRoomDetail;
