import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import JuristicCompanyDashboard from './juristicCompanyDashboard';
import CreateUpdatePersonnel from './createUpdatePersonnel';

class JuristicCompany extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/dashboard/:status`} component={JuristicCompanyDashboard}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:statusTeam/:status/:id?`} component={CreateUpdatePersonnel}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default JuristicCompany;