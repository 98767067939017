import React, { Component } from "react";
import Header from "../components/header/";
import Sidebar from "../components/sidebar/";
import Wrapper from "../components/wrapper/";
import WrapperContent from "../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../env/environment";
import ReactQuillAdapter from "../libs/reactQuillAdapter";
import UploadImageInput from "../libs/uploadImageInput";
import NewsImageRender from "../contact/news/newsimageRender";
import { commitMutation } from "react-relay";
import DeleteNewsImage from "./mutations/deleteAnnounceNewsImage";
import deleteAnnounceNewsNews from "./mutations/deleteAnnounceNews";
import queryGroup from "./query/queryAnnounceDashboardGroup";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import DatePickerAdapter from "../libs/datePickerAdapter";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import i18n from "i18next";
import onUploadFile from "../components/Action/onUploadFile";

const _ = require("lodash");

const query = graphql`
  query createUpdateAnnounceDashboardQuery($id: ID!) {
    juristicNews(id: $id) {
      id
      title
      titleEn
      detail
      detailEn
      previewImage
      status
      newsType
      creator
      added
      scheduleTime
      highlight
      group {
        id
        name
        nameEn
      }
      juristicNewsImages {
        edges {
          node {
            id
            fileUrl
            fileUpload
          }
        }
      }
      juristicNewsFile{
          edges{
            node{
              fileName
              fileUpload
              fileUrl
            }
          }
      }
    }
    allProjects {
            edges {
            node {
            periodCreate
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation createUpdateAnnounceDashboardMutation($input: CreateUpdateJuristicNewsMainInput!) {
    createUpdateJuristicNewsMain(input: $input) {
      ok
    }
  }
`;

class CreateUpdateAnnounceDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem("language") || "th",
      redirect_to_list: false,
      post_now_loading: false,
      save_draft_loading: false,
      status: false,
      newCreate: true,
      news: {
        id: "",
        title: "",
        titleEn: "",
        previewImage: "",
        detail: "",
        detailEn: "",
        status: "",
        newsImages: {
          edges: [],
        },
        newsFile: [],
        groupId: "",
        group: "",
        highlight: false,
        newsType: "ALL"
      },
      upload_preview: {
        preview_image: "",
      },
      upload_image_list: [],
      schedule_status: false,
      temp_schedule_date: new Date(),
      temp_schedule_time: new Date(),
      schedule_date: "",
      schedule_time: "",
      status_old: "",
      fileMore: [],
      temp_month_before: '',
      temp_month_after: '',
      juristicNewsGroup: [],

      svm: true,
      sena: true,
      sklas: true,

      svm_preview_image: "",
      sena_preview_image: "",
      sklas_preview_image: "",

      svmMoreImage: {},
      senaMoreImage: {},
      sklasMoreImage: {},

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeNewsType = this.handleInputChangeNewsType.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.submitFrom = this.submitFrom.bind(this);
    this.resetPreviewImage = this.resetPreviewImage.bind(this);
    this.uploadFile = this.uploadFile.bind(this)
    this.callBackUploadFile = this.callBackUploadFile.bind(this)
    this.dateFile = this.dateFile.bind(this)
    this.toggle_checkbox = this.toggle_checkbox.bind(this);
    this.makeUrlsClickable = this.makeUrlsClickable.bind(this)
  }

  componentWillMount() {
    this.queryNews();
    this.setState({ status: this.props.match.params.status === "deleted" });
  }

  componentDidMount() {
    this.queryGroupNews()
  }

  queryGroupNews() {
    fetchQuery(environment, queryGroup).then(
      (response) => {
        if (response.allJuristicNewsGroup) {
          this.setState({
            juristicNewsGroup: response.allJuristicNewsGroup.edges
          })
        }
      })
  }


  toggle_checkbox = () => {
    this.setState((prevState) => ({
      news: {
        ...prevState.news,
        highlight: !prevState.news.highlight,
      },
    }));
  };

  queryNews() {
    if (this.props.match.params.id) {
      fetchQuery(environment, query, { id: this.props.match.params.id }).then(
        (response) => {
          if (response) {
            if (response.juristicNews.scheduleTime) {
              let scheduleTimeConvert = new Date(response.juristicNews.scheduleTime);
              this.setState({
                fileMore: response.juristicNews.juristicNewsFile?.edges?.map((n) => ({ ...n.node })),
                // news: response.news,
                news: {
                  title: response.juristicNews.title,
                  detail: response.juristicNews.detail,
                  id: response.juristicNews.id,
                  newsType: response.juristicNews.newsType,
                  titleEn: response.juristicNews.titleEn,
                  detailEn: response.juristicNews.detailEn,
                  previewImage: response.juristicNews.previewImage,
                  status: response.juristicNews.status,
                  newsImages: response.juristicNews.juristicNewsImages,
                  highlight: response.juristicNews.highlight,
                  group: response.juristicNews.group,
                  newsFile: response.juristicNews.juristicNewsFile?.edges?.map((n) => ({ ...n.node }))
                },
                temp_schedule_date: scheduleTimeConvert,
                temp_schedule_time: scheduleTimeConvert,
                schedule_status: true,
                newCreate: false,
                status_old: response.juristicNews.status,
              });
            } else {
              let date = new Date();
              this.setState({
                // news: response.news,
                news: {
                  title: response.juristicNews.title,
                  detail: response.juristicNews.detail,
                  id: response.juristicNews.id,
                  newsType: response.juristicNews.newsType,
                  titleEn: response.juristicNews.titleEn,
                  detailEn: response.juristicNews.detailEn,
                  previewImage: response.juristicNews.previewImage,
                  status: response.juristicNews.status,
                  newsImages: response.juristicNews.juristicNewsImages,
                  highlight: response.juristicNews.highlight,
                  group: response.juristicNews.group,
                  newsFile: response.juristicNews.juristicNewsFile?.edges?.map((n) => ({ ...n.node }))
                },
                temp_schedule_time: date.setDate(date.getDate() + 1), newCreate: false, status_old: response.juristicNews.status,
                fileMore: response.juristicNews.juristicNewsFile?.edges?.map((n) => ({ ...n.node })),
              });
            }
          }
        }
      );
    } else {
      fetchQuery(environment, query, { id: '' }).then(response_data => {
        if (response_data) {
          let data = _.cloneDeep(response_data);
          this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
        }
      })
    }
  }

  calPeriodMouth(val) {
    let month_before = new Date();
    let month_after = new Date();

    month_before.setMonth(month_before.getMonth() - parseInt(val));
    month_after.setMonth(month_after.getMonth() + parseInt(val));

    this.setState({
      temp_month_before: month_before,
      temp_month_after: month_after,
    })
  }

  handleInputChange(e) {
    let event = _.cloneDeep(e);
    let value = event.target.value;
    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), event.target.name, value);
    });
  }

  handleInputChangeNewsType = (e) => {
    this.setState({
      news: {
        ...this.state.news,
        newsType: e.target.value,
      },
    });
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  deleteNewsImage(news_image_id, index) {
    if (news_image_id) {
      // mutation delete
      let variables = {
        input: {
          "id": news_image_id,
        },
      };
      DeleteNewsImage(
        variables,
        () => this.queryNews(),
        () => Swal.fire(i18n.t("press_release:cannot_delete_image"), "", "error")
      );
    } else {
      // delete upload_image_list
      let upload_image_list = [...this.state.upload_image_list];
      upload_image_list.splice(index, 1);
      this.setState({ upload_image_list: upload_image_list });
    }
  }

  deleteNews(id_list) {
    if (id_list.length !== 0) {
      // mutation delete
      let variables = {
        input: {
          idList: id_list,
        }
      };
      //   DeleteNews(
      //     variables,
      //     () => this.props.history.push('/contact/news/notice'),
      //     () => Swal.fire(i18n.t("press_release:cannot_delete_image"), "", "error")
      //   );
    }
  }

  submitFrom(e) {
    e.preventDefault();
    if (this.state.news.detail === "") {
      // เช็คเนื้อหาว่าง
      Swal.fire(i18n.t("press_release:fail_to_create"), i18n.t("press_release:please_enter_content"), "warning");
      return;
    }

    if (new Date(Date.now()) > new Date(this.state.temp_schedule_time) && !this.state.newCreate && this.state.status_old !== "PUBLISH") {
      Swal.fire({
        title: i18n.t("press_release:unable_to_process_transaction"),
        text: i18n.t("press_release:posting_timeout"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: i18n.t("press_release:confirm"),
        cancelButtonText: i18n.t("press_release:cancel"),
      }).then((result) => {
        if (result.value) {
          this.deleteNews(this.state.news.id)
          return;
        } else {
          return;
        }
      })
      return;
    }


    if (this.state.news.status === "publish" || this.state.news.status === "PUBLISH") {
      this.setState({
        post_now_loading: true,
      });
    } else {
      this.setState({
        save_draft_loading: true,
      });
    }

    let variables = { input: {} }

    if (this.state.temp_schedule_date === "" && this.state.temp_schedule_time === "") {
      variables = {
        input: {
          news: this.state.news,
          scheduleDate: this.state.temp_schedule_date,
          scheduleTime: this.state.temp_schedule_time,
          scheduleStatus: this.state.schedule_status,
        },
      };
    } else {
      variables = {
        input: {
          news: this.state.news,
          scheduleDate: format(this.state.temp_schedule_date, "YYYY-MM-DD"),
          scheduleTime: format(this.state.temp_schedule_time, "HH:mm:ss"),
          scheduleStatus: this.state.schedule_status,
        },
      };
    }

    _.set(variables, `input.svm`, this.state.svm)
    _.set(variables, `input.sena`, this.state.sena)
    _.set(variables, `input.sklas`, this.state.sklas)

    let svmListImageMore = []
    let senaListImageMore = []
    let sklasListImageMore = []

    let svmListFile = []
    let senaListFile = []
    let sklasListFile = []

    this.state.upload_image_list.map((ex, indx) => {
      _.set(svmListImageMore, `svm_image${indx + 1}`, ex)
      _.set(senaListImageMore, `sena_image${indx + 1}`, ex)
      _.set(sklasListImageMore, `sklas_image${indx + 1}`, ex)
    })

    this.state.fileMore.map((ex, inx) => {
      _.set(svmListFile, `svm_file${inx + 1}`, ex.file)
      _.set(senaListFile, `sena_file${inx + 1}`, ex.file)
      _.set(sklasListFile, `sklas_file${inx + 1}`, ex.file)
    })



    let uploadables = Object.assign(
      {
        svm_preview_image: this.state.upload_preview.preview_image,
        sena_preview_image: this.state.upload_preview.preview_image,
        sklas_preview_image: this.state.upload_preview.preview_image,
      },
      svmListFile,
      senaListFile,
      sklasListFile,
      svmListImageMore,
      senaListImageMore,
      sklasListImageMore
    );
    
    commitMutation(environment, {
      mutation,
      variables,
      uploadables,
      onCompleted: (response) => {
        this.setState({ post_now_loading: false, save_draft_loading: false });
        Swal.fire(i18n.t("press_release:success"), "", "success").then(() => {
          this.setState({ redirect_to_list: true });
        });
      },
      onError: (err) => {
        this.setState({ post_now_loading: false, save_draft_loading: false });
        Swal.fire(i18n.t("press_release:unsuccess"), "", "error");
      },
    });
  }

  resetPreviewImage() {
    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), "news.previewImage", "");
    });
    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), "upload_preview.preview_image", "");
    });
  }

  changeStatus(status) {
    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), "news.status", status);
    });
  }

  async changeStatusDate(status) {
    await this.setState({ schedule_status: true, temp_schedule_date: "", temp_schedule_time: "" })
    await this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), "news.status", status);
    });
  }

  async switchLanguage(language) {
    this.setState({ language });
    await localStorage.setItem("language", language);
    this.queryNews();
  }


  //uploadFileMore
  callBackUploadFile(data) {
    let cloneNews = _.cloneDeep(this.state.news)
    _.set(cloneNews, `newsFile`, data)
    this.setState({ news: cloneNews, fileMore: data })
  }
  uploadFile(e) {
    let pathname = "news/files"
    onUploadFile(e, this.state.fileMore, pathname, this.callBackUploadFile, "postToAllSite"); // postToAllSite เพิ่มสำหรับเวลาอัพโหลด ไปไซต์อื่นที่ไม่ใช่แค่ Silverman
  }

  dateFile(index, file) {
    let cloneData = _.cloneDeep(this.state.fileMore)
    let cloneNewsData = _.cloneDeep(this.state.news)
    cloneData.splice(index, 1)
    _.set(cloneNewsData, `newsFile`, cloneData)
    this.setState({ news: cloneNewsData, fileMore: cloneData })
  }

   makeUrlsClickable (text) {
    const urlRegex = /(http[s]?:\/\/[^\s<]+)/g;
    const transformedText = text.replace(urlRegex, '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>');
    return transformedText;
  }

  render() {
    if (this.state.redirect_to_list) {
      return <Redirect to="/announce-dashboard/news-list" />;
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <form onSubmit={this.submitFrom}>
            <div className="container-fluid box" id="news">
              <div className="row justify-content-between">
                <div className="col">
                  <h3>
                    <Link to="/announce-dashboard/news-list">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/wrapperContent/back.png"
                        }
                        alt="back"
                        className="back"
                      />
                    </Link>
                    {
                      this.props.match.params.id ? <Translation>
                        {(t) => <span>{t("contact_menu:แก้ไขประกาศ")}</span>}
                      </Translation> : <Translation>
                        {(t) => <span>{t("contact_menu:เพิ่มประกาศ")}</span>}
                      </Translation>
                    }

                  </h3>
                </div>

                <div className="col mr-md-5">
                  <div className="float-right">
                    {((this.props.match.params.id &&
                      _.some(
                        JSON.parse(
                          localStorage.getItem("permission_list")
                        ),
                        { codename: "contact_news_edit" }
                      )) ||
                      (!this.props.match.params.id &&
                        _.some(
                          JSON.parse(
                            localStorage.getItem("permission_list")
                          ),
                          { codename: "contact_news_create" }
                        ))) && (
                        <button
                          type="submit"
                          className="btn btn-outline-primary mr-4"
                          hidden={
                            this.state.status || this.state.status_old !== 'PUBLISH'
                              ? true
                              : this.state.save_draft_loading
                          }
                          onClick={() => this.changeStatusDate("draft")}
                        >
                          {this.state.save_draft_loading && (
                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                          )}
                          <Translation>
                            {(t) => (
                              <span>{t("contact_menu:save_draft")}</span>
                            )}
                          </Translation>
                        </button>
                      )}
                    {this.props.match.params.id &&
                      _.some(
                        JSON.parse(localStorage.getItem("permission_list")),
                        {
                          codename: "contact_news_approve",
                        }
                      ) && (
                        <button
                          type="submit"
                          className="btn btn-primary mr-lg-5"
                          hidden={
                            this.state.status || this.state.status_old !== 'PUBLISH'
                              ? true
                              : this.state.post_now_loading
                          }
                          onClick={() => this.changeStatus("publish")}
                        >
                          {this.state.post_now_loading && (
                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                          )}
                          <Translation>
                            {(t) => <span>{t("press_release:Post now")}</span>}
                          </Translation>
                        </button>
                      )}
                  </div>
                </div>
              </div>

              <div className="content-inner mt-4 fade-up">
                <div className="row">
                  <div className="col-md-7 col-xl-6">
                    <ul className="switch-languages">
                      <li
                        onClick={() => this.switchLanguage("th")}
                        className={
                          this.state.language === "th" ? "active" : "in-active"
                        }
                      >
                        TH
                      </li>
                      <li
                        onClick={() => this.switchLanguage("en")}
                        className={
                          this.state.language === "en" ? "active" : "in-active"
                        }
                      >
                        EN
                      </li>
                    </ul>
                    <div className="card p-4">
                      <div className="row">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:หมวดหมู่")}</span>}
                            </Translation>
                          </h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <select
                            onChange={this.handleInputChange}
                            name='news.groupId'
                            className='form-control'>
                            <Translation>
                              {(t) => <span>{t("claimRequest:Choose category")} <span style={{ color: 'red' }}>*</span></span>}
                            </Translation>
                            <option value="">เลือกหมวดหมู่</option>
                            <option value="">ไม่ระบุ</option>
                            {this.state?.juristicNewsGroup?.map((n, indexGroup) => (
                              <option
                                value={n.node.id}
                                key={indexGroup}
                                selected={n.node.id === this.state.news.group?.id}
                              >
                                {n.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:ปักหมุดไว้บนสุด")}</span>}
                            </Translation>
                          </h6>
                          <input type="checkbox" name="news.highlight"
                            checked={this.state.news.highlight}
                            onChange={this.toggle_checkbox} /> เปิดใช้งาน
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:ประเภท")} <span style={{ color: 'red' }}>*</span></span>}
                            </Translation>
                          </h6>
                          <input
                            type="radio"
                            id="all"
                            name="news.newsType"
                            value="ALL"
                            className="mr-2"
                            checked={this.state.news?.newsType === "ALL"}
                            onChange={this.handleInputChangeNewsType}
                          /> ทั้งหมด

                          <input
                            type="radio"
                            name="news.newsType"
                            value="JURISTIC"
                            id="juristic"
                            className="ml-2"
                            checked={this.state.news?.newsType === "JURISTIC"}
                            onChange={this.handleInputChangeNewsType}
                          /> สำหรับนิติ

                          <input
                            type="radio"
                            id="tenant"
                            name="news.newsType"
                            value="TENANT"
                            className="ml-2"
                            checked={this.state.news?.newsType === "TENANT"}
                            onChange={this.handleInputChangeNewsType}
                          /> สำหรับลูกบ้าน

                        </div>
                      </div>

                      {
                        this.state.language === 'th' &&
                        <div className="row mt-4">
                          <div className="col">
                            <h6>
                              <Translation>
                                {(t) => <span>{t("press_release:topic")} <span style={{ color: 'red' }}>*</span> </span>}
                              </Translation>
                            </h6>
                            <input
                              type="text"
                              className="form-control"
                              name="news.title"
                              value={this.state.news.title}
                              onChange={this.handleInputChange}
                              required
                              disabled={this.state.status}
                            />
                          </div>
                        </div>
                      }
                      {
                        this.state.language === 'en' &&
                        <div className="row mt-4">
                          <div className="col">
                            <h6>
                              <Translation>
                                {(t) => <span>{t("press_release:topic_eng")} <span style={{ color: 'red' }}>*</span></span>}
                              </Translation>
                            </h6>
                            <input
                              type="text"
                              className="form-control"
                              name="news.titleEn"
                              value={this.state.news?.titleEn}
                              onChange={this.handleInputChange}
                              required
                              disabled={this.state.status}
                            />
                          </div>
                        </div>
                      }
                      <div className="row mt-4">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:title_image")}</span>}
                            </Translation> {" "}
                            <span className="ml-2 color thin red-color">
                              <Translation>
                                {(t) => <span>{t("press_release:title_image_description")}</span>}
                              </Translation>
                            </span>
                          </h6>
                          {this.state.news.previewImage ? (
                            <React.Fragment>
                              <img
                                src={this.state.news.previewImage}
                                alt="news"
                                className="img-fluid card-img"
                              />
                              {!this.state.status && (
                                <div
                                  className="change-preview-image"
                                  onClick={this.resetPreviewImage}
                                >
                                  <Translation>
                                    {(t) => <span>{t("press_release:change_image")}</span>}
                                  </Translation>
                                </div>
                              )}
                            </React.Fragment>
                          ) : (
                            <div className="btn card-wrapper border-wrapper">
                              <label htmlFor="uploadImage">
                                <div className="card-body">
                                  <div className="col text-center">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/icons/select-photo.png"
                                      }
                                      alt="select-icon"
                                    />
                                  </div>
                                  <div className="text-center mt-4">
                                    <div className="upload-file">
                                      <Translation>
                                        {(t) => <span>{t("press_release:click_hear")}</span>}
                                      </Translation>
                                      <div className="upload-input">
                                        <UploadImageInput
                                          id="uploadImage"
                                          setField="upload_preview.preview_image:news.previewImage"
                                          onChange={this.handleInputChange}
                                          required={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            </div>
                          )}
                        </div>
                      </div>

                      {
                        this.state?.language === 'th' &&
                        <div className="row mt-4">
                          <div className="col">
                            <h6>
                              <Translation>
                                {(t) => <span>{t("press_release:content")} <span style={{ color: 'red' }}>*</span></span>}
                              </Translation>
                            </h6>
                            <ReactQuillAdapter
                              name="news.detail"
                              value={this.state.news.detail}
                              onChange={this.handleInputChange}
                              readOnly={
                                this.props.match.params.status === "deleted"
                              }
                            />
                          </div>
                        </div>
                      }
                      {
                        this.state?.language === 'en' &&
                        <div className="row mt-4">
                          <div className="col">
                            <h6>
                              <Translation>
                                {(t) => <span>{t("press_release:content_eng")}<span style={{ color: 'red' }}>*</span></span>}
                              </Translation>
                            </h6>
                            <ReactQuillAdapter
                              name="news.detailEn"
                              value={this.state.news?.detailEn}
                              onChange={this.handleInputChange}
                              readOnly={
                                this.props.match.params.status === "deleted"
                              }
                            />
                          </div>
                        </div>
                      }
                      <div className="row mt-4">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:attach_more_pictures")}</span>}
                            </Translation>
                            <span className="ml-2 color thin red-color">
                              <Translation>
                                {(t) => <span>{t("press_release:title_image_description")}</span>}
                              </Translation>
                            </span>
                          </h6>

                          <div className="card-columns">
                            {this.state.news.newsImages?.edges.map(
                              (news_image, index) =>
                              (
                                <NewsImageRender
                                  key={index}
                                  src={news_image.node.fileUrl}
                                  onClickDelete={() =>
                                    !this.state.status &&
                                    this.deleteNewsImage(
                                      news_image.node.id,
                                      index
                                    )
                                  }
                                />
                              )
                            )}
                            {this.state.upload_image_list.map(
                              (news_image, index) => (
                                <NewsImageRender
                                  key={index}
                                  src={URL.createObjectURL(news_image)}
                                  onClickDelete={() =>
                                    !this.state.status &&
                                    this.deleteNewsImage(false, index)
                                  }
                                />
                              )
                            )}
                            {!this.state.status && (
                              <div className="upload-file">
                                <div>
                                  <label
                                    htmlFor="uploadNewsImages"
                                    className="create news-button-image btn btn-light mt-2"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/icons/plus-icon.png"
                                      }
                                      alt="plus-icon"
                                      className="img-fluid plus news-image"
                                    />
                                  </label>
                                </div>
                                <div className="upload-input">
                                  <UploadImageInput
                                    id="uploadNewsImages"
                                    setField={
                                      "upload_image_list[" +
                                      this.state.upload_image_list.length +
                                      "]"
                                    }
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>



                      {/* แนนไฟล์เพิ่มเติม */}
                      <div className="row mt-4">
                        <div className="col ">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:Attachment")}</span>}
                            </Translation>
                          </h6>

                          <div className="staff-create">
                            <Translation>
                              {t =>
                                <div className="uploadBTN" style={{ display: 'inline-flex', pointerEvents: this.state.fileMore.length >= 3 ? 'none' : 'all' }}>
                                  <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                    <button className={this.state.fileMore.length >= 3 ? "btn disabled" : "btn"}>
                                      <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />{t("silvermanguard:Choose File")}
                                    </button>
                                    <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>
                                      {t("PageCreate:Support File Just .PDF Only and File Not Over 10 MB (Max 3 Image Only)")}
                                    </span>

                                    <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder='ระบุเบอร์โทร' accept='application/pdf' onChange={this.uploadFile} />
                                  </label>
                                </div>
                              }
                            </Translation>

                          </div>

                          {/* FileMore */}
                          <div className="mt-2">
                            {
                              this.state.fileMore?.map((files, inx) => {
                                let file = files.node ? files.node : files
                                return (

                                  <p key={inx}>
                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} style={{ width: '30px' }} /> &nbsp;
                                    <Link
                                      to="route"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        window.open(
                                          typeof file?.viewPtath === "string" || file?.node?.fileUpload
                                          ? file.viewPtath || file.fileUrl || file.node.fileUpload
                                            : URL.createObjectURL(file?.viewPtath ? file?.viewPtath : file.node.fileUpload)
                                        )
                                      }}
                                      target="blank">{file?.fileName ? file?.fileName : file?.node?.fileName}</Link>
                                    &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file)}>X</label>
                                  </p>

                                )
                              })
                            }
                          </div>
                        </div>
                      </div>



                      {/*<div className="row mt-4">
                                            <div className="col">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input"
                                                           id="tdCheckbox"/>
                                                    <label className="custom-control-label weight" htmlFor="tdCheckbox">
                                                        ส่งประกาศนี้ให้เฉพาะห้องที่เลือก
                                                        ยังไม่ได้ทำเลือกห้อง
                                                    </label>
                                                </div>
                                            </div>
                                        </div>*/}
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-5 mt-4">
                    <div className="card p-4 mt-2">
                      <h6>
                        <Translation>
                          {(t) => <span>{t("press_release:posting_option")}</span>}
                        </Translation>
                      </h6>
                      {
                        !this.state.news.status ?
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="tdCheckbox"
                              disabled={this.state.news.status ? true : false}
                              onClick={() => this.setState({ schedule_status: !this.state.schedule_status })}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="tdCheckbox"
                            >
                              <Translation>
                                {(t) => <span>{t("press_release:set_time")}</span>}
                              </Translation>
                            </label>
                          </div> :
                          <div></div>
                      }

                      {this.state.schedule_status && this.state.status_old !== "PUBLISH" && (
                        <div className="mt-3 time-selected">
                          <div className="row">
                            <div className="col-8 noti-date" style={{ zIndex: 99 }}>
                              <p>
                                <Translation>
                                  {(t) => <span>{t("dashboard:Date")}</span>}
                                </Translation>
                              </p>

                              {
                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ?
                                  <DatePickerAdapter
                                    name="temp_schedule_date" className="form-control" id="temp_schedule_date"
                                    selected={this.state.temp_schedule_date}
                                    minDate={this.state.temp_month_before}
                                    // maxDate={this.state.temp_month_after}
                                    onChange={this.handleChange}
                                    disable={this.state.news.status ? true : false}
                                  /> :
                                  <DatePickerAdapter
                                    id="temp_schedule_date"
                                    className="form-control"
                                    name="temp_schedule_date"
                                    selected={this.state.temp_schedule_date}
                                    onChange={this.handleChange}
                                    disable={this.state.news.status ? true : false}
                                  />

                              }
                            </div>
                            <div className="col-4">
                              <div className="calendar-wrapper time-selected-input">
                                <p>
                                  <Translation>
                                    {(t) => <span>{t("press_release:time")}</span>}
                                  </Translation>
                                </p>
                                <DatePicker
                                  className="form-control"
                                  selected={this.state.temp_schedule_time}
                                  onChange={(date) =>
                                    this.setState({ temp_schedule_time: date })
                                  }
                                  timeInputLabel="Time:"
                                  dateFormat="h:mm aa"
                                  showTimeInput
                                  disabled={this.state.news.status ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row mt-3">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("dashboard:เลือกไซต์ที่ต้องการโพสประกาศ")}</span>}
                            </Translation>
                          </h6>

                          <div className="row mt-3">
                            <div className="col-4">
                              <label htmlFor="PostToSilverman">
                                <input id="PostToSilverman" type={"checkbox"} defaultChecked
                                  onChange={(e) => this.setState({ svm: e.target.checked ? true : false })} /> Silverman
                              </label>
                            </div>
                            <div className="col-4">
                              <label htmlFor="PostToSena">
                                <input id="PostToSena" type={"checkbox"} defaultChecked
                                  onChange={(e) => this.setState({ sena: e.target.checked ? true : false })} /> Sena
                              </label>
                            </div>
                            <div className="col-4">
                              <label htmlFor="PostToSklas">
                                <input id="PostToSklas" type={"checkbox"} defaultChecked
                                  onChange={(e) => this.setState({ sklas: e.target.checked ? true : false })} /> Sklas
                              </label>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="row mt-3">
                        <div className="col">
                          {((this.props.match.params.id &&
                            _.some(
                              JSON.parse(
                                localStorage.getItem("permission_list")
                              ),
                              { codename: "contact_news_edit" }
                            )) ||
                            (!this.props.match.params.id &&
                              _.some(
                                JSON.parse(
                                  localStorage.getItem("permission_list")
                                ),
                                { codename: "contact_news_create" }
                              ))) && (
                              <button
                                type="submit"
                                className="btn btn-block btn-outline-primary"
                                hidden={
                                  this.state.status || this.state.news.status === 'PUBLISH' || this.state.status_old === 'PUBLISH'
                                    ? true
                                    : this.state.save_draft_loading
                                }
                                onClick={() => this.changeStatus("draft")}
                              >
                                {this.state.save_draft_loading && (
                                  <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                )}
                                <Translation>
                                  {(t) => (
                                    <span>{t("contact_menu:save_draft")}</span>
                                  )}
                                </Translation>
                              </button>
                            )}
                        </div>
                        <div className="col">
                          {_.some(
                            JSON.parse(localStorage.getItem("permission_list")),
                            {
                              codename: "contact_news_approve",
                            }
                          ) && (
                              <button
                                type="submit"
                                className="btn btn-block btn-primary"
                                hidden={
                                  this.state.status || this.state.news.status === 'PUBLISH' || this.state.status_old === 'PUBLISH'
                                    ? true
                                    : this.state.post_now_loading
                                }
                                onClick={() => this.changeStatus("publish")}
                              >
                                {this.state.post_now_loading && (
                                  <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                )}
                                <Translation>
                                  {(t) => <span>{t("press_release:confirm")}</span>}
                                </Translation>
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                    <h6 className="text-center mt-5">
                      <Translation>
                        {(t) => <span>{t("press_release:display_ex")}</span>}
                      </Translation>
                    </h6>
                    <div className="phone-preview">
                      <div className="inner" >
                        {this.state.news.previewImage && (
                          <img
                            src={this.state.news.previewImage}
                            alt="news"
                            className="img-fluid mt-2 mb-2"
                          />
                        )}

                        {this.state.language === 'th' &&
                          <>
                            <h6>{this.state.news?.title}</h6>
                            <div
                              className="content"
                              dangerouslySetInnerHTML={{
                                __html: this.state.news.detail,
                              }} />
                          </>
                        }

                        {this.state.language === 'en' &&
                          <>
                            <h6>{this.state.news?.titleEn}</h6>
                            <div
                              className="content"
                              dangerouslySetInnerHTML={{
                                __html: this.state.news.detailEn,
                              }} />
                          </>
                        }


                        <div className="mt-2">
                          {this.state.fileMore?.map((file, inx) => {                            
                            let checkLastNameFile = file.fileName?.includes(".") ? "fileName" : "fileUpload"                            
                            let typeFile =file[checkLastNameFile]?.includes(".pdf") ? "pdf" : file[checkLastNameFile]?.includes(".jpg") || file[checkLastNameFile]?.includes(".jepg") ? "jpg" : "png";                                
                            return (
                              <p key={inx}>
                                <img
                                  src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile + "Icon.png"}
                                  style={{ width: '30px', float: 'left', marginRight: 8 }}
                                /> &nbsp;
                                <Link
                                  to="route"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    window.open(
                                      typeof file?.viewPtath === "string" || file?.fileUrl
                                        ? file.viewPtath || file.fileUrl || file.fileUpload
                                        : URL.createObjectURL(file?.viewPtath ? file?.viewPtath : file.fileUrl)
                                    )
                                  }}
                                  target="blank">{file?.fileName}</Link>
                              </p>

                            )
                          })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default CreateUpdateAnnounceDashboard;
