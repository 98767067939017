import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { encode } from 'base-64';
import format from 'date-fns/format';
import apiSing from '../../../api/sing';

import _ from 'lodash';
import i18next from 'i18next';
const allResidential = graphql`
    query fixRequestListDetailQuery {
        allResidential {
            totalCount
            edges {
                node {
                    id
                    name
                    floor
                    size
                    status
                    residentialHouseholder{
                        edges{
                            node{
                                id
                                tenant{
                                    id
                                    firstName
                                    lastName
                                    countries
                                    user{
                                        id
                                    }
                                }
                            }
                        }
                    }
                    type{
                        id
                        name
                    }
                }
            }
        }
    }
`;

class fixRequestListDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loading: true,
            data: [],
            workingTypeAll: [],
            file: []

        }
        this.printMousePosX = this.printMousePosX.bind(this)
        this.printMousePosY = this.printMousePosY.bind(this)
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    componentWillMount() {
        this.getWorkType()
        this.getDataId()
    }

    getWorkType() {
        // this.setState({ workingTypeAll: require("../json/workingType.json") })
        let forPage = this.props.match.params.for === "forcommon" ? 'public' : 'private'
        apiSing.get_task_type_service(forPage).then((res) => {
            let data = []
            if (res.data.all_task_type) {
                data = res.data.all_task_type
                res.data.all_task_type.map((n, index) => {
                    let image = "/images/typeIcon/sing/" + n.task_type_name + '.png'
                    _.set(data, '[' + index + ']images', image)
                })
                this.setState({ workingTypeAll: data })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    getDataId() {
        let idd = this.props.match.params.id
        let data = {
            index_first: "",
            index_last: "",
            page: "",
            status: "",
            id: idd,
            person_working: "",
            search: "",
            start_date: "",
            end_date: ""
        }
        apiSing.getrepairs_room_view(data).then((res) => {
            if (res.data.data) {
                let data = res.data.data[0]
                this.setState({ data: data, loading: false })
            }
        })
    }

    printMousePosX(data) {
        var cursorX = parseInt(data) - 300 - 10;
        return cursorX
    }

    printMousePosY(data) {
        var cursorY = parseInt(data) - 150 - 10;
        return cursorY
    }

    render() {

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            <div className="container-fluid box fixRequestFast" >
                                {
                                    this.state.loading == false ?
                                        <div className='createFixrequest'>
                                            <div className="row justify-content-between">
                                                <div className="col">
                                                    <h3 className="mb-4">
                                                        <Link to={this.props.match.params.detail === "detail" ?
                                                            "/contact/fix-requests/fortenant/review" :
                                                            "/contact/fix-requests/report/fortenant/all"
                                                        }>
                                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                alt="back" className="back" />
                                                        </Link>
                                                        <span style={{ fontWeight: '400' }}>{this.state.data.worksheet[0]?.doc_number ? this.state.data.worksheet[0]?.doc_number : t("PageList:Click Read More")}</span>

                                                    </h3>
                                                </div>
                                            </div>
                                            {
                                                <React.Fragment>
                                                    <div className='row'>
                                                        <div className="col-10 mx-auto">
                                                            <form onSubmit={this.onSubmit}>
                                                                <div>
                                                                    <br /><br />

                                                                    <section className='GeneralInformation'>
                                                                        <h3 style={{ fontWeight: '400' }}>{t("PageCreate:General Information")}

                                                                            <label style={{ float: 'right' }} >{t("PageList:Status")}:   &nbsp;
                                                                                {
                                                                                    this.state.data.insure_status !== "have" && this.state.data.create_by !== "fast" ?
                                                                                        <span className={'btnStatusDetail ' + this.state.data.status}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/" + this.state.data.status + ".png"} />
                                                                                            &nbsp;
                                                                                            {
                                                                                                this.state.data.status === "review" ? "รอตรวจสอบ" :
                                                                                                    this.state.data.status === "pending" ? "รอดำเนินการ" :
                                                                                                        this.state.data.status === "approve" ? "อนุมัติค่าใช้จ่าย" :
                                                                                                            this.state.data.status === "finish" ? "เสร็จสิ้น" : i18next.t("Allaction:cancel")
                                                                                            }


                                                                                        </span>
                                                                                        :
                                                                                        <span className={'btnStatusDetailsChatFast'}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/" + this.state.data.status + ".png"} />
                                                                                            &nbsp;
                                                                                            {
                                                                                                this.state.data.status === 'new' ? "งานใหม่" :
                                                                                                    this.state.data.status === 'appointment' ? "นัดตรวจ" :
                                                                                                        this.state.data.status === 'repaired' ? "กำลังซ่อม" :
                                                                                                            this.state.data.status === 'repaired' ? "กำลังซ่อม" :
                                                                                                                this.state.data.status === 'edited' ? 'แก้ไขแล้ว' :
                                                                                                                    this.state.data.status === "finish" ? "เสร็จสิ้น" : i18next.t("Allaction:cancel")
                                                                                            }


                                                                                        </span>

                                                                                }

                                                                            </label>

                                                                        </h3>
                                                                        <hr />

                                                                        <label style={{ marginTop: '24px' }}>{t("PageCreate:Find Room")} </label>
                                                                        <input type="text" className='form-control inputBox'
                                                                            defaultValue={this.state.data.residential?.name || "-"} readOnly={true}/>

                                                                        {
                                                                            this.state.data.tenant_contact ?
                                                                                <React.Fragment>
                                                                                    <label>{t("PageCreate:Choose Name Request")}</label>
                                                                                    <input type="text" name='other_contact' className='form-control inputBox'
                                                                                        defaultValue={
                                                                                            (this.state.data.tenant_contact.name_title +
                                                                                                this.state.data.tenant_contact.first_name + " " +
                                                                                                this.state.data.tenant_contact.last_name).toString()
                                                                                        } readOnly={true} />


                                                                                    <label>{t("PageCreate:Phone")}</label>
                                                                                    <input type="text" name='phone_contact' className='form-control inputBox' placeholder='XXX-XXX-XXXX'
                                                                                        defaultValue={this.state.data.phone_contact ? this.state.data.phone_contact : '-'} readOnly={true} />
                                                                                </React.Fragment>
                                                                                :
                                                                                <React.Fragment>
                                                                                    <label>{t("PageCreate:First Name - Last Name")}</label>
                                                                                    <select name='RequestBy' className="form-control inputBox" disabled={true} defaultValue={"other"}>
                                                                                        <option value={"other"}>ระบุเอง</option>
                                                                                    </select>

                                                                                    <label>{t("PageCreate:First Name - Last Name")}</label>
                                                                                    <input type="text" name='other_contact' className='form-control inputBox' placeholder='ระบุชื่อผู้แจ้ง'
                                                                                        defaultValue={this.state.data.other_contact} readOnly={true} />

                                                                                    <label>{t("PageCreate:Phone")}</label>
                                                                                    <input type="text" name='phone_contact' className='form-control inputBox' placeholder='XXX-XXX-XXXX'
                                                                                        defaultValue={this.state.data.phone_contact ? this.state.data.phone_contact : '-'} readOnly={true} />

                                                                                    <label>{"E-mail"}</label>
                                                                                    <input type="text" name='email_contact' className='form-control inputBox' placeholder='XXX-XXX-XXXX'
                                                                                        defaultValue={this.state.data.email_contact ? this.state.data.email_contact : '-'} readOnly={true} />
                                                                                </React.Fragment>
                                                                        }
                                                                    </section>

                                                                    <section className='WorkingType'>
                                                                        <label>{t("PageCreate:Category")}  <span className='text-red'>*</span></label><br />
                                                                        <div>
                                                                            {
                                                                                this.state.workingTypeAll.map((n, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            <div
                                                                                                className={this.state.data.working_type === n.task_type_name_th ? 'divTypeCar actived' : 'divTypeCar '}
                                                                                            >
                                                                                                <div className='imgTypeSelect'>
                                                                                                    <img src={process.env.PUBLIC_URL + n.images} className='imgType' /><br />
                                                                                                    {
                                                                                                        this.state.data.working_type === n.task_type_name_th &&
                                                                                                        <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Gray.png'} className='selected' />
                                                                                                    }
                                                                                                </div>
                                                                                                <p>{n.task_type_name_th}</p>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>

                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <br />
                                                                    </section>

                                                                    <section className='TypeOject'>
                                                                        <label style={{ marginTop: '10px' }}>{t("PageCreate:Work Type / Material")} <span className='text-red'>*</span></label>

                                                                        <select className='form-control inputBox' name='material' defaultValue={""} disabled={true}>
                                                                            <option value={""}>{this.state.data.material}</option>
                                                                        </select>
                                                                    </section>

                                                                    <section className='DetailWorkingType'>
                                                                        <label>{t("PageCreate:Detail Fix Request")} <span className='text-red'>*</span></label>
                                                                        <select className='form-control inputBox' name='details' defaultValue={this.state.details} disabled={true}>
                                                                            <option value={""}>{this.state.data.details}</option>
                                                                        </select>
                                                                    </section>

                                                                    <section className='floor'>
                                                                        <label>{t("PageCreate:Floor")}</label>
                                                                        <select className='form-control inputBox' name='floor' defaultValue={this.state.floor} disabled={true}>
                                                                            <option value={""}>{this.state.data.floor}</option>
                                                                        </select>

                                                                        {
                                                                            this.state.data.image ?
                                                                                <div className='imgProductBox' id='my_div_id'
                                                                                    style={{
                                                                                        backgroundPositionX: parseInt(this.state.data.mark_x / 2 - 6) + "px",
                                                                                        backgroundPositionY: parseInt(this.state.data.mark_y / 2 - 0.97 - 8) + "px"
                                                                                    }}
                                                                                >
                                                                                    <img src={this.state.data.image} style={{ height: "300px", width: "400px" }} />
                                                                                    {/* <img src={process.env.PUBLIC_URL + '/images/typeIcon/sing/imgProject.png'} style={{ height: "248px" }} /> */}
                                                                                </div>
                                                                                :
                                                                                ""

                                                                        }



                                                                    </section>

                                                                    <section className='description'>
                                                                        <label>{t("PageCreate:Detail of the problem")} </label>
                                                                        <textarea readOnly={true}
                                                                            name='description' defaultValue={this.state.data.description}
                                                                            placeholder={t("PageCreate:Detail of the problem")} rows={3}
                                                                            className='form-control'
                                                                            style={{ resize: "none", width: "475px" }} />
                                                                    </section>

                                                                    <section className='UploadFile'>
                                                                        <div style={{ display: 'inline-flex' }}>
                                                                            <h3 style={{ marginTop: '40px' }}>{t("PageCreate:Choose File Fix Request")} </h3>
                                                                            <label htmlFor="uploadFile" className="upload-btn-wrapper"
                                                                                style={{ pointerEvents: this.state.file.length > 3 ? 'none' : 'all' }}>
                                                                                <button className="btn disabled"><img src={process.env.PUBLIC_URL + '/images/typeIcon/sing/attach.png'} />{t("silvermanguard:Choose File")} </button>
                                                                                <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>{t("PageCreate:Support File Just .png, .jpg, jpeg Only and File Not Over 10 MB (Max 3 Image Only)")} </span>

                                                                                <input type="file" name='file' disabled={true} id="uploadFile" className='form-control uploadFile' placeholder='ระบุเบอร์โทร' accept='.pdf,.png,.jpg' onChange={this.uploadFile}
                                                                                />

                                                                            </label>
                                                                        </div>

                                                                        {
                                                                            this.state.data.image_per_repairs_room?.length > 0 &&
                                                                            this.state.data.image_per_repairs_room?.map((file, inx) => {
                                                                                let filesed = file.fast_url?.split("https://fastinspect-fileupload-uat.s3.ap-southeast-1.amazonaws.com") || ""
                                                                                let typeFile = file.file_upload ? file.file_upload?.split(".") : filesed[1]?.split(".")
                                                                                let nameFile = file.file_upload?.split("sing/file/")
                                                                                return (
                                                                                    <p key={inx}>
                                                                                        {typeof(typeFile) === 'object' && 
                                                                                            <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }}  />}&nbsp;
                                                                                        
                                                                                        {
                                                                                            file.file_upload ?
                                                                                                <Link
                                                                                                    to="route"
                                                                                                    onClick={(event) => {
                                                                                                        event.preventDefault();
                                                                                                        window.open(
                                                                                                            typeof file.file_name === "string"
                                                                                                                ? file.file_name
                                                                                                                : URL.createObjectURL(file.file_name)
                                                                                                        )
                                                                                                    }}
                                                                                                    target="blank">{nameFile[1]}</Link>
                                                                                                :
                                                                                                <Link to={{ pathname: file.fast_url }} target="blank">{file.file_name.substring(0, 100)}</Link>
                                                                                        }
                                                                                        {/* &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file)}>X</label> */}
                                                                                    </p>
                                                                                )
                                                                            })
                                                                        }
                                                                    </section>

                                                                    <section className='ChkWarranty'>
                                                                        <p style={{
                                                                            fontWeight: "600", fontSize: "13px", color: this.state.data.insure_status === "have" ? "#38AE3E" : "#CA0824"
                                                                        }}>
                                                                            <React.Fragment>
                                                                                {
                                                                                    this.state.data.insure_status === "have" ?
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/InsurGreen.png"} style={{ width: '18px' }} /> :
                                                                                        this.state.data.insure_status === "out" ?
                                                                                            <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/InsurRed.png"} style={{ width: '18px' }} /> :
                                                                                            ""
                                                                                }
                                                                                {
                                                                                    this.state.data.insure_status === "have" || this.state.data.insure_status === "out" ?
                                                                                        <React.Fragment>
                                                                                            &nbsp; {t("PageCreate:Warranty Expiry")} &nbsp; {this.state.data.due_date ? format(this.state.data.due_date, "DD/MM/YYYY") : "-"}

                                                                                        </React.Fragment>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                            </React.Fragment>
                                                                        </p>
                                                                    </section>

                                                                    <section className='worksheetDetail'>
                                                                        {
                                                                            this.state.data.status === "finish" &&
                                                                            <Link to={"/contact/fix-requests/fortenant/" + this.state.data.status + "?worksheetId=" + encode(this.state.data.id)}>
                                                                                {t("PageCreate:Click View Detail Wroksheet")}
                                                                            </Link>
                                                                        }

                                                                    </section>

                                                                </div>


                                                            </form>

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }


                                        </div>
                                        :
                                        <div className=" text-center" >
                                            <img src={process.env.PUBLIC_URL + '/images/typeIcon/sing/loading.gif'} />
                                        </div>
                                }





                            </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default fixRequestListDetail;
