import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from "react-i18next";


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/contact/news/all">
                                <Translation>
                                    {(t) => <span>{t("press_release:all")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact={true} to="/contact/news/notice">
                                <Translation>
                                    {(t) => <span>{t("press_release:breaking_new")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact/news/info">
                                <Translation>
                                    {(t) => <span>{t("press_release:pressRelease")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact/news/expire">
                                <Translation>
                                    {(t) => <span>{t("press_release:expire")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact/news/deleted">
                                <Translation>
                                    {(t) => <span>{t("press_release:list_of_cancellation")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation; 