import React, { Component, forwardRef } from "react";
import "./styles/commonArea.scss";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Navigation from "./navigation";
import ReactPaginate from "react-paginate";
import { Dropdown } from "reactjs-dropdown-component";
import { bookingService } from "./services";
import _ from "lodash";
import moment from "moment";
import ContactTopMenuNavigation from "../contactTopMenuNavigation";
import localstorage from "../../libs/localstorage";
import "./styles/dropdawn.scss";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Loading from "../../libs/loading"
import i18n from "../../i18n";
import BackButtonIcon from "../../components/BackBtn/indexBack";
import jwtDecode from 'jwt-decode'
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import ExportCommonAreaPDF from "./export/exportCommonAreaPDF";
import ExportCommonAreaExcel from "./export/exportCommonAreaExcel";
import { Dropdown as DropdownItem } from "react-bootstrap";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";

const locations = [
  {
    label: "All",
    value: "All"
  },
  {
    label: "Service charge",
    value: "Service charge"
  },
  {
    label: "No service charge",
    value: "No service charge"
  }
];

const query = graphql`
    query bookingCommonAreaListQuery{
        selfProject{
            name
        }
    }
`;

class BookingCommomAreaList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      flg: "",
      flgTime: "",
      flgService: "",
      status: "",
      textSearch: "",
      num_record: 0,
      loading: false,
      first: "",
      last: 10,
      perPage: 10,
      page: 0,
      pages: 0,
      startDate: "",
      endDate: "",
      namePrint: "",
      project_name: "",
      dataExport: [],
      loadingData: true
    };
    this.changePageLimit = this.changePageLimit.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  async componentDidMount() {
    await this.setState({
      last: localstorage.getPageLimit()
    })
    await this.getData();
  }

  async handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      await this.onChangeSearch(e);
      this.getData();
    }
  }

  handlePageClick = (event) => {
    let page = event.selected;
    this.setState({ page });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.status !== this.props.match.params.status) {
      this.getData();
    }
  }

  async getData() {
    this.setState({ loading: true });
    const { flgService, textSearch, last, startDate, endDate } =
      this.state;
    let token = localStorage.getItem("token");
    let text = "";

    let status = this.props.match.params.status;
    let start = format(new Date(startDate), "DD/MM/YYYY");
    let end = format(new Date(endDate), "DD/MM/YYYY");

    if (status === "all") {
      text = "";
    } else if (status === "cancel") {
      text = "void";
    } else if (status === "confirmed") {
      text = "confirmed";
    } else if (status === "unconfirmed") {
      text = "unconfirmed";
    } else if (status === "overdue") {
      text = "overdue";
    } else if (status === "used") {
      text = "used";
    }

    bookingService
      .facility(
        token,
        text,
        "",
        "",
        "",
        flgService,
        textSearch,
        start === "Invalid Date" ? "" : start,
        end === "Invalid Date" ? "" : end
      )
      .then((res) => {
        const data = res.data;
        let nameToken = jwtDecode(localStorage.getItem("token"))
        fetchQuery(environment, query).then((queryData) => {
          if (queryData?.selfProject) {
            this.setState({
              data: data.booking_list,
              num_record: data.num_record,
              loading: false,
              pages: Math.ceil(data.booking_list.length / last),

              project_name: queryData?.selfProject?.name,
              namePrint: nameToken.first_name + " " + nameToken.last_name,
              dataExport: data.booking_list,
              loadingData: false,
            });
          }
        })
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async componentWillUnmount() {
    this.setState({
      loading: false,
      data: []
    });
  }

  async onChangeService(text) {
    let value = text.value;
    await this.setState({
      flgService:
        value === "ทั้งหมด" ? "" : value === "เก็บค่าบริการ" ? true : false
    });
    this.getData();
  }

  async onChangeTime(text) {
    let value = text.value;
    let month = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    ).getDate();
    let futureDate = moment().add(1, "months").calendar();
    let two = new Date(
      new Date().getFullYear(),
      new Date(futureDate).getDate() + 1,
      0
    ).getDate();
    let three = new Date(
      new Date().getFullYear(),
      new Date(futureDate).getDate() + 2,
      0
    ).getDate();
    let threemonth = month + two + three;
    await this.setState({
      flgTime:
        value === "วันนี้"
          ? 0
          : value === "3 วัน"
            ? 3
            : value === "สัปดาห์นี้"
              ? 7
              : value === "เดือนนี้"
                ? month
                : value === "3 เดือน"
                  ? threemonth
                  : ""
    });
    this.getData();
  }

  onChangeSearch(value) {
    this.setState({
      textSearch: value.target.value
    });
  }

  async changePageLimit(value) {
    localstorage.setPageLimit(value);
    await this.setState({
      first: 0,
      last: value
    });
    this.getData();
  }

  render() {
    const { data, num_record, loading, page, pages, last, startDate, endDate } =
      this.state;
    let items = data.slice(page * last, (page + 1) * last);
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          <ContactTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/contact"}
                  LinkText={t("booking:booking_common_area")+" "+"(BK)"}

                  boxHtmlText={
                    <div className="row colorUse">
                      {
                        !this.state.loadingData ?
                          <DropdownItem>
                            <DropdownToggle id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>{t("Allaction:Print")}</DropdownToggle>
                            <DropdownMenu>
                              <ExportCommonAreaPDF
                                project_name={this.state.project_name}
                                dataExport={this.state.dataExport}
                                namePrint={this.state.namePrint}
                              />

                              <ExportCommonAreaExcel
                                state={this.state}
                              />
                            </DropdownMenu>
                          </DropdownItem> :
                          <button className="btn btn-outline bg-1567B4">
                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                            {t('meter:Preparing information')}
                          </button>

                      }

                      {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_list_create' }) &&
                        <Link to="/contact/global-area/booking/add">
                          <button
                            type="submit"
                            className="btn ml-3 h48 bg1567B4-textfff mr-3"
                          >
                            <img
                              src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                              alt="plus"
                            />
                            {t("newBookingSingha_Booking:Add new booking facility")}
                          </button>
                        </Link>
                      }
                    </div>

                  }
                />

                <div className="content-inner">
                  <Navigation />
                  <div
                    className="d-flex justify-content-between pd-0"
                    style={{ marginBottom: "24px" }}
                  >
                    <div
                      className="col-3 d-flex align-items-center"
                      style={{ padding: 0 }}
                    >
                      <h6 style={{ fontSize: "14px" }}>{t("newBookingSingha_Booking:Choose service types")}::</h6>
                      <div
                        className="calendars-wrapper d-flex align-items-center"
                        style={{ marginLeft: 20 }}
                      >
                        <Dropdown
                          name="location"
                          title={t("newBookingSingha_Booking:All")}
                          key=""
                          className="mt"
                          onChange={(text) => this.onChangeService(text)}
                          list={locations.map((n) => ({ ...n, label: i18n.t("newBookingSingha_Booking:" + n.label) }))}
                          styles={{
                            headerTitle: {
                              fontSize: "12px",
                              color: "#B3B3B3",
                              fontFamily: "light"
                            },
                            header: { border: "1px solid #B3B3B3" },
                            wrapper: {
                              width: "168px"
                            },
                            list: { fontSize: "8px" },
                            listItem: { fontSize: "12px" }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="col-6 d-flex align-items-center"
                      style={{ padding: 0, marginLeft: 20 }}
                    >
                      <h6
                        style={{
                          marginRight: 20,
                          marginBottom: "unset",
                          width: "35%",
                          fontSize: "14px",
                          textAlign: "end"
                        }}
                      >
                        {t("newBookingSingha_Booking:Sort by date")}:
                      </h6>
                      <div
                        className="calendars-wrapper d-flex align-items-center"
                        style={{ width: 310 }}
                      >
                        <DatePicker
                          className="form-control float-left colorborder"
                          selected={startDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => this.setState({ startDate: date })}
                          customInput={<ButtonDate date />}
                          style={{ height: 42 }}
                        />
                      </div>
                      <h6
                        style={{
                          marginLeft: 10,
                          marginRight: 10,
                          marginBottom: "unset",
                          fontSize: "14px"
                        }}
                      >
                        {t("newBookingSingha_Booking:to")}
                      </h6>
                      <div
                        className="calendars-wrapper d-flex align-items-center"
                        style={{ width: 310 }}
                      >
                        <DatePicker
                          className="form-control float-left colorborder"
                          selected={endDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => this.setState({ endDate: date })}
                          customInput={<ButtonDate date />}
                        />
                      </div>
                    </div>
                    <div className="col-3 calendars-wrapper" style={{}}>
                      <form>
                        <input
                          type="text"
                          placeholder={t("newBookingSingha_Booking:Search")}
                          onKeyDown={this.handleKeyDown}
                          onChange={(event) => this.onChangeSearch(event)}
                          style={{ fontFamily: "light" }}
                          className="calendars-wrapper colorborder form-control float-left"
                        />
                      </form>
                      <div
                        className="calendars float-right"
                        style={{ paddingRight: 25 }}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/search-outline.png"
                          }
                          alt="calendars"
                          style={{ width: "17.81px", height: "17.81px" }}
                          onClick={() => this.getData()}
                        />
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <div className="text-center"><Loading /></div>
                  ) : (
                    <React.Fragment>
                      <div className="card-table fade-up" id="parcel">
                        <div className="table-responsive">
                          <table className="table table-hover line">
                            <thead className="table-list">
                              <tr>
                                <Translation>
                                  {(t) => (
                                    <th className="font-header-table">
                                      {t("newBookingSingha_Booking:Booking number")}
                                    </th>
                                  )}
                                </Translation>
                                <Translation>
                                  {(t) => (
                                    <th className="font-header-table">
                                      {t("newBookingSingha_Booking:Using date")}
                                    </th>
                                  )}
                                </Translation>
                                <Translation>
                                  {(t) => (
                                    <th className="font-header-table">
                                      {t("newBookingSingha_Booking:Using time")}
                                    </th>
                                  )}
                                </Translation>
                                <Translation>
                                  {(t) => (
                                    <th className="font-header-table">
                                      {t("newBookingSingha_Booking:Booking name")}                                    </th>
                                  )}
                                </Translation>
                                <Translation>
                                  {(t) => (
                                    <th className="font-header-table">
                                      {t("newBookingSingha_Booking:Contact number")}
                                    </th>
                                  )}
                                </Translation>
                                <Translation>
                                  {(t) => (
                                    <th className="font-header-table">
                                      {t("newBookingSingha_Booking:Facility name")}
                                    </th>
                                  )}
                                </Translation>
                                <th className="font-header-table">{t("newBookingSingha_Booking:Number of rights")}</th>
                                <Translation>
                                  {(t) => (
                                    <th className="font-header-table">
                                      {t("newBookingSingha_Booking:Number of participants")}
                                    </th>
                                  )}
                                </Translation>
                                <Translation>
                                  {(t) => (
                                    <th className="font-header-table">
                                      {t("newBookingSingha_Booking:Booked by")}

                                    </th>
                                  )}
                                </Translation>
                                <Translation>
                                  {(t) => (
                                    <th className="font-header-table text-center ">
                                      {t("newBookingSingha_Booking:Booked date/time")}
                                    </th>
                                  )}
                                </Translation>
                                {this.props.match.params.status === 'cancel' && <Translation>
                                  {(t) => (
                                    <th className="font-header-table text-center ">
                                      {t("newBookingSingha_Booking:Cancled By")}
                                    </th>
                                  )}
                                </Translation>}
                                {this.props.match.params.status === 'cancel' && <Translation>
                                  {(t) => (
                                    <th className="font-header-table text-center">
                                      {t("newBookingSingha_Booking:Cancled Date")}
                                    </th>
                                  )}
                                </Translation>}
                                <Translation>
                                  {(t) => (
                                    <th className="font-header-table">
                                      {t("newBookingSingha_Booking:Status")}
                                    </th>
                                  )}
                                </Translation>
                              </tr>
                            </thead>
                            <tbody>
                              {items &&
                                items.map((item) => {
                                  return (
                                    <tr
                                      key={_.get(item, "booking_detail.id")}
                                      className="show"
                                    >
                                      <td className="no-booking">
                                        {_.get(item, "all_booking_bill") &&
                                          _.get(item, "all_booking_bill").map(
                                            (v) => (
                                              <Link key={"linkPathtoDetaiilbooking::" + _.get(item.booking_detail, "id")}
                                                to={{
                                                  pathname: `/contact/global-area/booking/detail/` + _.get(item.booking_detail, "id"),
                                                  state: _.get(item.booking_detail, "id")
                                                }}
                                              >
                                                <span className="blue-color">
                                                  {_.get(v, "doc_number")}
                                                </span>
                                              </Link>
                                            )
                                          )}
                                      </td>
                                      <td className="text-header-table">
                                        <span>
                                          {moment(
                                            _.get(item, "booking_detail.start_time")
                                          )
                                            .utc()
                                            .format("DD/MM/YYYY")}
                                        </span>
                                      </td>
                                      <td className="text-header-table">
                                        <span>
                                          {moment(
                                            _.get(item, "booking_detail.start_time")
                                          )
                                            .utc()
                                            .format("HH:mm")}{" "}
                                          -{" "}
                                          {moment(
                                            _.get(item, "booking_detail.end_time")
                                          )
                                            .utc()
                                            .format("HH:mm")}
                                        </span>
                                      </td>
                                      <td className="text-header-table">
                                        <span>
                                          {_.get(
                                            item,
                                            "booking_detail.booking_name"
                                          )}
                                        </span>
                                      </td>
                                      <td className="text-header-table">
                                        <span>
                                          {_.get(item, "booking_detail.tel")}
                                        </span>
                                      </td>
                                      <td className="text-header-table">
                                        <span>
                                          {_.get(item, "facility_detail.name")}
                                        </span>
                                      </td>
                                      <td className="text-header-table">
                                        <span>
                                          {_.get(item, "booking_detail.use_right")}
                                        </span>
                                      </td>
                                      <td className="text-header-table">
                                        <span>
                                          {_.get(item, "booking_detail.num_people")}
                                        </span>
                                      </td>
                                      <td className="text-header-table">
                                        <span>
                                          {_.get(item, "booking_detail.creator") || _.get(item, "booking_detail.booker_detail.name")}
                                        </span>
                                      </td>
                                      <td className="text-header-table text-center">
                                        <span>
                                          {moment(
                                            _.get(item, "booking_detail.added")
                                          )
                                            .utc()
                                            .format("DD/MM/YYYY HH:mm")}
                                        </span>
                                      </td>
                                      {this.props.match.params.status === 'cancel' && <td className="text-header-table text-center">
                                        <span>
                                          {_.get(item, "booking_detail.updater")}
                                        </span>
                                      </td>}
                                      {this.props.match.params.status === 'cancel' && <td className="text-header-table text-center">
                                        <span>
                                          {moment(
                                            _.get(item, "booking_detail.updated")
                                          )
                                            .utc()
                                            .format("DD/MM/YYYY HH:mm")}
                                        </span>
                                      </td>}
                                      <td
                                        className="text-left"
                                        style={{ width: "10%" }}
                                      >
                                        {_.get(item, "booking_detail.status") ===
                                          "used" ? (
                                          <span className="tag-used-mini">
                                            {t("newBookingSingha_Booking:Used")}
                                          </span>
                                        ) : _.get(item, "booking_detail.status") ===
                                          "void" ? (
                                          <span className="tag-void-mini">
                                            {t("newBookingSingha_Booking:Void")}
                                          </span>
                                        ) : _.get(item, "booking_detail.status") ===
                                          "confirmed" ? (
                                          <span className="tag-confirmed-mini">
                                            {t("newBookingSingha_Booking:Confirm")}
                                          </span>
                                        ) : _.get(item, "booking_detail.status") ===
                                          "unconfirmed" ? (
                                          <span className="tag-unconfirmed-mini">
                                            {t("newBookingSingha_Booking:Not Approve")}
                                          </span>
                                        ) : (
                                          _.get(item, "booking_detail.status") ===
                                          "overdue" && (
                                            <span className="tag-overdue-mini">
                                              {t("newBookingSingha_Booking:Over Due")}
                                            </span>
                                          )
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <div className="text-left">
                          <div className="dropdown page-limit d-none d-md-block page-limit">
                            <button
                              className="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <Translation>
                                {(t) => t("pagination:show")}
                              </Translation>
                              <span>{last}</span>
                              <Translation>
                                {(t) => t("pagination:items/page")}
                              </Translation>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <div
                                className="dropdown-item"
                                onClick={() => this.changePageLimit(10)}
                              >
                                <Translation>
                                  {(t) => t("pagination:show")}
                                </Translation>{" "}
                                10
                                <Translation>
                                  {(t) => t("pagination:items/page")}
                                </Translation>
                              </div>
                              <div
                                className="dropdown-item"
                                onClick={() => this.changePageLimit(20)}
                              >
                                <Translation>
                                  {(t) => t("pagination:show")}
                                </Translation>{" "}
                                20
                                <Translation>
                                  {(t) => t("pagination:items/page")}
                                </Translation>
                              </div>
                              <div
                                className="dropdown-item"
                                onClick={() => this.changePageLimit(50)}
                              >
                                <Translation>
                                  {(t) => t("pagination:show")}
                                </Translation>{" "}
                                50
                                <Translation>
                                  {(t) => t("pagination:items/page")}
                                </Translation>
                              </div>
                            </div>
                          </div>
                          <div className="text-right">
                            {pages > 1 && (
                              <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={
                                  <span className="btn page-link">...</span>
                                }
                                breakClassName={"break-me"}
                                pageClassName="page-item"
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={4}
                                previousClassName="sr-only"
                                containerClassName={"pagination float-right"}
                                subContainerClassName={"pagination-page"}
                                nextLinkClassName="sr-only"
                                pageLinkClassName="page-link"
                                activeClassName={"active"}
                                pageCount={pages}
                                onPageChange={this.handlePageClick}
                              />
                            )}
                            <div className="total-item-count">
                              <Translation>
                                {(t) => t("pagination:all_items")}
                              </Translation>
                              {num_record}
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            }
          </Translation>
        </div >
      </Wrapper >
    );
  }
}

const ButtonDate = forwardRef(({ value, onClick, date }, ref) => (
  <button
    type="button"
    className="button-date"
    style={{ textAlignLast: "start" }}
    onClick={onClick}
    ref={ref}
  >
    {value}
    {!date && (
      <div className="calendars">
        <span
          style={{
            color: "#0382FA",
            fontFamily: "medium"
          }}
        >
          {i18n.t("newBookingSingha_Booking:Choose time")}
        </span>
      </div>
    )}
    {date && (
      <div className="calendars float-right">
        <img
          className="icon"
          src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"}
          alt="calendars"
        />
      </div>
    )}
  </button>
));

export default BookingCommomAreaList;
