import React, { Component } from 'react'
import _ from "lodash";
import SelectCreditor from '../../selectCreditor/selectCreditor';
import SelectPaymentTerms from '../../selectPaymentTerms/selectPaymentTerms';
import { Link } from 'react-router-dom';
import DatePickerNoti from '../../../accounting/finance/depositInterest/datePickerNoti';
import SelectCatagoryServiceContact from '../../selectCatagoryServiceContact/selectCatagoryServiceContact';
import SelectCatagoryAsset from '../../selectCatagoryAsset/selectCatagoryAsset';
import { Translation } from 'react-i18next';
import "../style/manageOrder.scss"
import i18next from 'i18next';

const selectText = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
]

const customStyles = {
  control: (style, { isDisabled }) => ({
    ...style,
    height: 48,
    minHeight: 48,
    backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF',
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none'
  }),
};


export default class formHeadOrderType extends Component {

  render() {
    return (
      <Translation>
        {t =>
          <div id="formHeadOrderType">
            <div className="row">
              <div className="col-2">
                <h6>{i18next.t("formHeadOrderType:Purchase Order Date")}</h6>
                <DatePickerNoti
                  disabled
                  className="form-control"
                  selected={this.props.issuedDate}
                />
              </div>

              <div className="col-2">
                <h6>{i18next.t("formHeadOrderType:Document Number")}</h6>
                <input type="text"
                  disabled={true}
                  className="form-control"
                  value={this.props.docNumber}
                />
              </div>

              <div className="col-2">
                <h6>{i18next.t("formHeadOrderType:Reference PRF Number")}</h6>
                <input type="text"
                  disabled={true}
                  className="form-control"
                  value={this.props.procurementForm.docNumber}
                />
              </div>

              <div className="col-2">
                <h6>{i18next.t("formHeadOrderType:PRF Approval Date")}</h6>
                <DatePickerNoti
                  disabled
                  className="form-control"
                  selected={this.props.procurementForm.approveDate}
                />
              </div>

            </div>

            {(this.props.type === "asset" || this.props.type === "ASSET") &&
              <div className="row mt-5">
                <div className="col">
                  <h5>{i18next.t("formHeadOrderType:Procurement Type")}</h5>
                  <div className="row">
                    <div className="col-12 mt-5">
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          disabled
                          type="radio" className="custom-control-input"
                          id="buyMore"
                          value="buyMore"
                          checked={this.props.procurementForm.addPurchasing?.title === 'buyMore'}
                        />
                        <Translation>
                          {t => <label className="custom-control-label textDetail"
                            htmlFor="buyMore">{i18next.t("formHeadOrderType:Additional Purchase")}</label>}
                        </Translation>
                      </div>
                    </div>
                    <div className="col-12 mt-5 d-flex align-items-center">
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          disabled
                          type="radio" className="custom-control-input"
                          id="buyReplaceAssetsNo" name="purchaseRequisition.addPurchasing"
                          value="buyReplaceAssetsNo"
                          checked={this.props.procurementForm.addPurchasing?.title === 'buyReplaceAssetsNo'}
                        />
                        <Translation>
                          {t => <label className="custom-control-label textDetail"
                            htmlFor="buyReplaceAssetsNo">{i18next.t("formHeadOrderType:Replacement Purchase for Asset Number")}</label>}
                        </Translation>
                      </div>
                      <input type="text"
                        disabled
                        className="form-control col-2 ml-4"
                        name="purchaseRequisition.addPurchasing.detail"
                        value={
                          this.props.procurementForm.addPurchasing?.title === 'buyReplaceAssetsNo'
                            ? this.props.procurementForm.addPurchasing?.detail : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="row mt-5">
              <div className="col-4">
                <h6>{i18next.t("formHeadOrderType:Vendor")}</h6>
                <SelectCreditor
                  disibleInput={true}
                  creditorId={this.props.procurementForm.creditor}
                />
              </div>

              <div className="col-2">
                <h6>{i18next.t("formHeadOrderType:Purchase Request Date")}</h6>
                <DatePickerNoti
                  disabled
                  className="form-control"
                  selected={this.props.procurementForm.buyDate}
                />
              </div>

              <div className="col-2">
                <h6>{i18next.t("formHeadOrderType:PO Approval Da")}</h6>
                <DatePickerNoti
                  className="form-control"
                  name="appreve_date"
                  minDate={this.props.issuedDate}
                  selected={this.props.approvePODate}
                  onChange={this.props.handleChangeAppreveSate}
                  disabled={this.props.status === "approve" || this.props.status === "void"}
                />
              </div>


            </div>

            <div className="row mt-5">
              <div className="col-4">
                <h6>{i18next.t("formHeadOrderType:Contract / Reference Document Number")}</h6>
                <input type="text"
                  disabled={true}
                  className="form-control"
                  value={this.props.procurementForm.contractNumber}
                />
              </div>

              {this.props.procurementForm.contractNumber &&
                <div className="col-2">
                  <h6>{i18next.t("formHeadOrderType:Date")}</h6>
                  <DatePickerNoti
                    disabled
                    className="form-control"
                    selected={this.props.procurementForm.contractDate}
                  />
                </div>
              }

              <div className="col-4">
                <h6>{i18next.t("formHeadOrderType:Payment Terms")}</h6>
                <SelectPaymentTerms
                  disibleInput={true}
                  paymentTerms={this.props.procurementForm.paymentTerms}
                />
              </div>

              {this.props.procurementForm.paymentTerms === "other" &&
                <div className="col-2">
                  <div style={{ marginTop: "30px" }}>
                    <input type="text"
                      disabled
                      className="form-control"
                      value={this.props.procurementForm.otherPaymentTerms}
                    />
                  </div>
                </div>
              }
            </div>

            {(this.props.type === "service_contact" || this.props.type === "SERVICE_CONTACT") &&
              <div className="row mt-5">
                <div className="col-4">
                  <h6>{i18next.t("formHeadOrderType:Category")}</h6>
                  <SelectCatagoryServiceContact
                    disibleInput={true}
                    chartOfAccountId={this.props.procurementForm.chartOfAccountId}
                  />
                </div>
              </div>
            }

            {(this.props.type === "asset" || this.props.type === "ASSET") &&
              <div className="row mt-5">
                <div className="col-4">
                  <h6>{i18next.t("formHeadOrderType:Category")}</h6>
                  <SelectCatagoryAsset
                    disibleInput={true}
                    chartOfAccountId={this.props.procurementForm.chartOfAccountId}
                  />
                </div>

                <div className="col-4">
                  <h6>{i18next.t("formHeadOrderType:Location where the asset is used")}</h6>
                  <input type="text"
                    disabled
                    className="form-control"
                    value={this.props.procurementForm.facilityUse}
                  />
                </div>
              </div>
            }

            <div className="row mt-5">
              <div className="col-4">
                <h5>{i18next.t("formHeadOrderType:Attached Documents")}</h5>
                <div className="slide">
                  {_.map(this.props.procurementForm.procurementFormContract, (item, index) => {
                    let fileName = item.node.fileName
                    let typeFileName = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length)

                    return (
                      <div className="lineBottomAttach" key={index}>
                        <div className="item">
                          <div className="detail">
                            {typeFileName === "pdf"
                              ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} alt="pdf" />
                              : typeFileName === "png"
                                ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pngIcon.png"} alt="png" />
                                : typeFileName === "jpeg" && <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/jpgIcon.png"} alt="jpeg" />
                            }
                            <Link
                              to="route"
                              onClick={(event) => {
                                event.preventDefault();
                                window.open(item.node.fileUpload);
                              }}
                              target="blank">
                              {fileName.length >= 20 ? fileName.substr(0, 20) : fileName}
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

          </div>
        }
      </Translation>
    )
  }
}
