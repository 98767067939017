import React from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';


const Navigation = () => {

        return (
            <Translation>
            {t => 
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/income/receive/form/manage_batch/all">
                            {t('batchRt:All')}    
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/income/receive/form/manage_batch/void">
                            {t('batchRt:Cancelled Transaction')}                            
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            }</Translation>
        )
}

export default Navigation;