import React, { Component } from 'react';
import { commitMutation, QueryRenderer } from 'react-relay';
import environment from "../../../env/environment";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import Wrapper from "../../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../libs/numberWithComma";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from "i18next"
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import SearchSelect from "../../../libs/searchSelect";
import { parse } from "date-fns";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import _ from "lodash";
import { convertToTimeZone } from '../../../libs/date';
import paymentGatewayText from "../../../libs/paymentGatewayText";
import Loading from "../../../libs/loading";

const query = graphql`
    query paymentGatewayDetailQuery($id: ID!) {
        paymentGatewayGroup(id: $id) {
            id
            docNumber
            image
            depositTotal
            feeTotal
            depositDate
            description
            paymentGatewayTransaction{
                edges{
                    node{
                        id
                        description
                        amount
                        fee
                        total
                        refNumber
                        issuedDate
                        paymentType
                        bank{
                            id
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            depositTotal
            feeTotal
        }
        
        bankAccountViewer{
            allBankAccount(status: true){
                edges{
                    node{
                        id
                        accountNumber
                        accountName
                        bankName

                    }
                }
            }
        }

        selfProject {
            timeZone
        }

    }
`;

const mutation = graphql`
    mutation paymentGatewayDetailMutation($input: VoidPaymentGatewayGroupInput!){
        voidPaymentGatewayGroup(input: $input){
            clientMutationId
            ok
            warningText
        }
    }
`;

class PaymentGatewayDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            redirect: false,
        };
        this.deleteGroup = this.deleteGroup.bind(this);
    }

    deleteGroup() {
        this.setState({ loading: true });

        Swal.fire({
            title: i18n.t("paymentgateway:Are you sure to cancel payment gateway deposits?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: i18n.t("paymentgateway:Cancel"),
            confirmButtonText: i18n.t("paymentgateway:Yes")
        }).then((result) => {
            if (result.value) {

                let variables = {
                    input: {
                        id: this.props.match.params.id,
                    }
                };

                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if (response.voidPaymentGatewayGroup.ok) {
                                Swal.fire(
                                    i18n.t("paymentgateway:Success"),
                                    i18n.t("paymentgateway:Cancel payment gateway"),
                                    'success'
                                ).then(() => {
                                    this.setState({ redirect: true, loading: false });
                                });
                            } else {
                                Swal.fire(i18n.t("paymentgateway:Failed to cancel"), response.voidPaymentGatewayGroup.warningText, 'warning')
                                this.setState({ loading: false });
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18n.t("paymentgateway:Please try again"), 'warning');
                            this.setState({ loading: false });
                        },
                    },
                )

            } else {
                this.setState({ loading: false });
            }
        })
    }


    render() {

        if (this.state.redirect) {
            return <Redirect to="/accounting/finance/payment-gateway/list" />
        }

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid box">
                        <div className="row">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/finance/payment-gateway/list">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('paymentgateway:paymentgateway')+" "+"(PG)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>

                            <div className="col text-right">
                                <Link to={{
                                    pathname: "/document/payment-gateway-print/" + this.props.match.params.id
                                }}>
                                    <button type="button" className="mr-3 btn btn-outline-secondary">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'}
                                            alt="print-icon" />
                                    </button>
                                </Link>
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_credit_card_delete' }) &&
                                    <Translation>
                                        {t =>
                                            <button className="btn btn-danger add mr-3"
                                                disabled={this.state.loading}
                                                onClick={this.deleteGroup}>
                                                {this.state.loading && <span
                                                    className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                {t("paymentgateway:Cancel payment gateway")}
                                            </button>}
                                    </Translation>
                                }
                            </div>
                        </div>
                        <div className="content-inner">
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                variables={{ id: this.props.match.params.id }}
                                render={({ error, props }) => {
                                    if (error) {
                                        return <div>{error.message}</div>;
                                    } else if (props) {
                                        return <React.Fragment>

                                            <div className="table-responsive fade-up">
                                                <table className="table table-hover mt-2">
                                                    <thead className="thead-light">
                                                        <Translation>
                                                            {t =>
                                                                <tr>
                                                                    <th>{t("paymentgateway:No.")}</th>
                                                                    <th>{t("paymentgateway:Date")}</th>
                                                                    <th>{t("paymentgateway:Description")}</th>
                                                                    <th>{t("paymentgateway:bank")}</th>
                                                                    <th>{t("paymentgateway:payment Method")}</th>
                                                                    <th>{t("paymentgateway:Amount")}</th>
                                                                </tr>}
                                                        </Translation>
                                                    </thead>
                                                    <tbody>
                                                        {props.paymentGatewayGroup.paymentGatewayTransaction.edges.map((payment_gateway) => {
                                                            return (
                                                                <tr key={payment_gateway.node.id}>
                                                                    <td>
                                                                        {payment_gateway.node.refNumber}
                                                                    </td>
                                                                    <td> {format(payment_gateway.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                    <td>{payment_gateway.node.description}</td>
                                                                    <td>{payment_gateway.node.bank.bankName + " " + payment_gateway.node.bank.accountNumber}</td>
                                                                    <td>{paymentGatewayText(payment_gateway.node.paymentType)}</td>
                                                                    <Translation>{t => <td>{numberWithComma(payment_gateway.node.total)} {t("paymentgateway:Baht")}</td>}</Translation>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row mt-3 fade-up">
                                                <div className="col-9" />
                                                <div className="col-3">
                                                    <div className="col">
                                                        <div className="row p-3 mb-2 bg-light text-dark">
                                                            <Translation>{t => <div className="col text-left"><strong>{t("paymentgateway:All amount")}</strong></div>}</Translation>
                                                            <div
                                                                className="col text-right"><Translation>{t => <strong>{numberWithComma(props.paymentGatewayGroup.depositTotal + props.paymentGatewayGroup.feeTotal)} {t("credit_card:Baht")}</strong>}</Translation>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-3 fade-up">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <Translation>{t => <label className="col-2 col-form-label">{t("paymentgateway:No.")}</label>}</Translation>
                                                        <label className="col-2 col-form-label">{props.paymentGatewayGroup.docNumber}</label>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <Translation>{t => <label className="col-2 col-form-label">{t("paymentgateway:Description")}</label>}</Translation>
                                                        <div className="col-5">
                                                            <textarea className="form-control" rows="4"
                                                                value={props.paymentGatewayGroup.description}
                                                                disabled={true}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <Translation>{t => <label className="col-2 col-form-label">{t("paymentgateway:Deposit date")}</label>}</Translation>
                                                        <div className="col">
                                                            <DatePickerAdapter
                                                                name="depositDate" className="form-control"
                                                                selected={parse(convertToTimeZone(props.paymentGatewayGroup.depositDate, props.selfProject.timeZone))}
                                                                disable={true}
                                                            />

                                                        </div>
                                                    </div>


                                                    <div className="row mt-3">
                                                        <Translation>{t => <label className="col-2 col-form-label">{t("paymentgateway:Deposit channel")}</label>}</Translation>
                                                        <div className="col-3">
                                                            <SearchSelect value={props.paymentGatewayGroup.paymentGatewayTransaction.edges[0].node.bank.id}
                                                                name="bank_select"
                                                                placeholder="กรุณาทำการตั้งค่าบัตรเครดิต"
                                                                queryObject={props.bankAccountViewer.allBankAccount.edges}
                                                                keyOfValue="id"
                                                                keyOfLabel="bankName:accountName:accountNumber"
                                                                disabled={true}

                                                            />

                                                        </div>
                                                        <Translation>
                                                            {t => <label
                                                                className="col-2 col-form-label ml-3 text-center">{t("paymentgateway:Amount")}</label>}
                                                        </Translation>
                                                        <div className="col-2">
                                                            <input type="number" className="form-control text-right"
                                                                step="0.01"
                                                                name="depositTotal"
                                                                value={props.paymentGatewayGroup.depositTotal}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <Translation>{t => <label className="col-2 col-form-label">{t("paymentgateway:Difference")}</label>}</Translation>
                                                        <div className="col-3">
                                                            <input type="text" className="form-control text-center"
                                                                name="depositTotal"
                                                                value={props.paymentGatewayGroup.paymentGatewayTransaction.edges[0].node.paymentType === 'deeplink' ? "บัญชีพักค่าธรรมเนียม" : "รายรับค่าธรรมเนียมเพย์เมนท์เกตเวย์"}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <Translation>
                                                            {t => <label
                                                                className="col-2 col-form-label ml-3 text-center">{t("paymentgateway:Amount")}</label>}
                                                        </Translation>
                                                        <div className="col-2">
                                                            <input type="number" className="form-control text-right"
                                                                step="0.01"
                                                                name="feeTotal"
                                                                value={props.paymentGatewayGroup.feeTotal}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-5">
                                                        <div className="col">
                                                            <img
                                                                src={props.paymentGatewayGroup.image}
                                                                alt="" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    }
                                    return <Loading/>
                                }}
                            />
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default PaymentGatewayDetail;
