/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type dashboardQueryVariables = {||};
export type dashboardQueryResponse = {|
  +countByCountries: ?number,
  +countAllCountries: ?number,
  +countResidentialByType: ?number,
  +countForRent: ?number,
  +countSeizure: ?number,
  +countAvailability: ?number,
  +countOther: ?number,
  +checkOnline: ?boolean,
|};
export type dashboardQuery = {|
  variables: dashboardQueryVariables,
  response: dashboardQueryResponse,
|};
*/


/*
query dashboardQuery {
  countByCountries(countries: "TH")
  countAllCountries: countByCountries(allCountries: true)
  countResidentialByType(residentialType: "live")
  countForRent: countResidentialByType(residentialType: "for_rent")
  countSeizure: countResidentialByType(residentialType: "seizure")
  countAvailability: countResidentialByType(residentialType: "availability")
  countOther: countResidentialByType(residentialType: "other")
  checkOnline
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "countries",
        "value": "TH"
      }
    ],
    "kind": "ScalarField",
    "name": "countByCountries",
    "storageKey": "countByCountries(countries:\"TH\")"
  },
  {
    "alias": "countAllCountries",
    "args": [
      {
        "kind": "Literal",
        "name": "allCountries",
        "value": true
      }
    ],
    "kind": "ScalarField",
    "name": "countByCountries",
    "storageKey": "countByCountries(allCountries:true)"
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "residentialType",
        "value": "live"
      }
    ],
    "kind": "ScalarField",
    "name": "countResidentialByType",
    "storageKey": "countResidentialByType(residentialType:\"live\")"
  },
  {
    "alias": "countForRent",
    "args": [
      {
        "kind": "Literal",
        "name": "residentialType",
        "value": "for_rent"
      }
    ],
    "kind": "ScalarField",
    "name": "countResidentialByType",
    "storageKey": "countResidentialByType(residentialType:\"for_rent\")"
  },
  {
    "alias": "countSeizure",
    "args": [
      {
        "kind": "Literal",
        "name": "residentialType",
        "value": "seizure"
      }
    ],
    "kind": "ScalarField",
    "name": "countResidentialByType",
    "storageKey": "countResidentialByType(residentialType:\"seizure\")"
  },
  {
    "alias": "countAvailability",
    "args": [
      {
        "kind": "Literal",
        "name": "residentialType",
        "value": "availability"
      }
    ],
    "kind": "ScalarField",
    "name": "countResidentialByType",
    "storageKey": "countResidentialByType(residentialType:\"availability\")"
  },
  {
    "alias": "countOther",
    "args": [
      {
        "kind": "Literal",
        "name": "residentialType",
        "value": "other"
      }
    ],
    "kind": "ScalarField",
    "name": "countResidentialByType",
    "storageKey": "countResidentialByType(residentialType:\"other\")"
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "checkOnline",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dashboardQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dashboardQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0aae9780a4bde41b2ae3d688168add11",
    "id": null,
    "metadata": {},
    "name": "dashboardQuery",
    "operationKind": "query",
    "text": "query dashboardQuery {\n  countByCountries(countries: \"TH\")\n  countAllCountries: countByCountries(allCountries: true)\n  countResidentialByType(residentialType: \"live\")\n  countForRent: countResidentialByType(residentialType: \"for_rent\")\n  countSeizure: countResidentialByType(residentialType: \"seizure\")\n  countAvailability: countResidentialByType(residentialType: \"availability\")\n  countOther: countResidentialByType(residentialType: \"other\")\n  checkOnline\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0a2ed755eb2bf88e7ed9d957b1f1bbf4';

module.exports = node;
