import '../styles/main.scss';
import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import OtherExpense from './otherExpense';
import OtherExpenseAccountRecord from './otherExpenseAccountRecord';
import OtherExpenseWithholdingTax from './otherExpenseWithholdingTax';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperOtherExpenseQuery($id: ID!, $ref_transaction: String) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        otherExpense(id: $id) {
            id
            docNumber
            issuedDate
            unknownContact
            taxType
            remark
            status
            total
        
            contact {
                refNumber
                firstName
                lastName
                name
                taxIdNumber
                registeredName
                registeredAddress
                registeredCountry
                registeredProvince
                registeredCity
                registeredDistrict
                registeredPostalCode
                typeOfContact
                typeOfCompany
                nameBranch
                typeOfSupplier
                typeOfPeople
            }

            firstName 
            lastName
            name
            phone
            email
            taxIdNumber
            address
            district
            city
            province
            postalCode
            country

            taxInvoiceNumber
            taxInvoiceDate
            taxInvoicePeriod
            otherExpensePaymentChannel(status:"paid"){
                edges{
                    node{
                        id
                        price
                        date
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                        rdCash{
                            docNumber 
                        }
                    }
                }
            }
            withholdingTax{
                edges {
                    node {
                        id
                        docNumber
                    }
                }
            }
            otherExpenseTransaction {
                totalCount
                edges {
                    node {
                        id
                        typeOfIncome
                        typeOfIncomeOther
                        typeOfTax
                        typeOfTaxOther
                        taxRate
                        
                        productAndService {
                            id
                            name
                            typeOfIncome
                            typeOfIncomeOther
                            typeOfTax
                            typeOfTaxOther
                            taxRate
                            productCode
                            
                            chartOfAccount {
                                chartOfAccountCode
                                name
                                totalDebit
                                totalCredit
                            }
                        }
                        chartOfAccount {
                            chartOfAccountCode
                            name
                        }
                        description
                        unitItems
                        price
                        discount
                        vat
                        vatAmount
                        preTaxAmount
                        whtRate
                        whtRatePercent
                        
                    }
                }
            }
        }
        otherExpenseAccountRecordGroup(otherExpense: $id){
            edges{
                node{
                    id
                    totalDebit
                    totalCredit
                    accountRecord {
                        totalCount
                        edges {
                            node {
                                id
                                debit
                                credit
                                name
                                chartOfAccountCode {
                                    id
                                    chartOfAccountCode
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
        cashTransactionViewer{
            allCashTransaction(refNumber: $ref_transaction){
                edges{
                    node{
                        id
                        description
                        price
                    }
                }
            }
        }
        chequeTransactionViewer{
            allChequeTransaction(refNumber: $ref_transaction , status_In:"wait, clearing, void, bide, delete") {
                edges{
                    node {
                        id
                        date
                        bankAccount {
                            id
                            accountName
                            branch
                        }
                        chequeNumber
                        price
                    }
                }
            }
        }

        allBankAccountTransaction(refTransaction: $ref_transaction) {
            edges{
                node {
                    id
                    price
                    date
                    imageSlip
                    bankAccount {
                        bankName
                        accountNumber
                    }
                }
            }
        }

        allAccountRecordGroup(refTransaction: $ref_transaction) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }
        userConfig {
            id languages
        }
    }
`;

class WrapperOtherExpense extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.adjustHeight = this.adjustHeight.bind(this);
    }

    print() {
        window.print();
    }

    adjustHeight() {

    }

    render() {

        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                        @page {
                            size: A4;
                            margin: 0;
                        }
                        @media print {
                            html, body {
                                width: 210mm;
                                height: 297mm;
                            }
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>Other Expense</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{ use_cache: false }}
                    variables={{
                        id: this.props.match.params.id,
                        ref_transaction: this.props.match.params.ref_transaction
                        // search: this.props.match.params.ref_transaction,
                    }}
                    render={({ error, props }) => { 
                        if (error) {
                            return <div className="alert alert-danger"
                                role="alert">{error.message}</div>;
                        } else if (props) { 
                            let other_expense_count = props.otherExpense.otherExpenseTransaction.totalCount;
                            let count_page = Math.ceil((other_expense_count) / 16);
                            let arrX = Array.from(new Array(count_page));
                            let transaction_per_page = 16;



                            let credit_list = [];
                            let debit_list = [];
                            let merge_list = [];
                            props.otherExpenseAccountRecordGroup.edges[0].node.accountRecord.edges.forEach((record, index) => {
                                if (record.node.credit > 0) {
                                    credit_list.push(record)
                                } else {
                                    debit_list.push(record)
                                }
                            });

                            merge_list = [...debit_list, ...credit_list];

                            let peper = ['ฉบับที่ 1. (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)', 'ฉบับที่ 2. (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน)', 'ฉบับที่ 3. (สำหรับผู้หักภาษีเก็บไว้เป็นหลักฐาน)', 'ฉบับที่ 4. (สำหรับผู้หักภาษีเก็บไว้เป็นหลักฐาน)']
                            let account_record = merge_list.length;
                            let count_page_account_record = Math.ceil((account_record) / 18);
                            let arrX_account_record = Array.from(new Array(count_page_account_record));
                            let account_record_per_page = 18;

                            return (
                                <React.Fragment>
                                    {arrX.map((value, index) => {
                                        let other_expense_list = props.otherExpense.otherExpenseTransaction.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1));                                
                                        return (
                                            <OtherExpense query={props}
                                                key={index}
                                                other_expense_page={index + 1}
                                                end_page={count_page - 1}
                                                all_page={(count_page - 1) + (count_page_account_record + 1)}
                                                current_page={index}
                                                other_expense_list={other_expense_list}
                                                size={442} 
                                                languages={props.userConfig.languages}
                                            />
                                        )
                                    })}

                                    {arrX_account_record.map((value, index) => {
                                        let transaction_list = merge_list.slice(account_record_per_page * index, account_record_per_page * (index + 1));
                                        return (
                                            <OtherExpenseAccountRecord
                                                query={props}
                                                other_expense={props}
                                                key={index}
                                                other_expense_page={index + 1}
                                                end_page={count_page_account_record - 1}
                                                print_current_page={index + 1 + (count_page)}
                                                all_page={(count_page + count_page_account_record)}
                                                current_page={index}
                                                other_expense_account_record_list={transaction_list}
                                                account_record_group={props.otherExpenseAccountRecordGroup.edges[0].node}
                                                languages={props.userConfig.languages}
                                            />
                                        )
                                    })}

                                    {peper.map((value, index) => {
                                        let transaction_list = merge_list.slice(account_record_per_page * index, account_record_per_page * (index + 1));
                                        return (
                                            <OtherExpenseWithholdingTax
                                                query={props}
                                                other_expense={props}
                                                key={index}
                                                haedervalue={value}
                                                other_expense_page={index + 1}
                                                end_page={count_page_account_record - 1}
                                                print_current_page={index + 1 + (count_page)}
                                                all_page={(count_page + count_page_account_record)}
                                                current_page={index}
                                                other_expense_account_record_list={transaction_list}
                                                account_record_group={props.otherExpenseAccountRecordGroup.edges[0].node}
                                            />
                                        )
                                    })}


                                </React.Fragment>

                            )
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperOtherExpense;
