import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from "i18next";
import "./style/createUpdateSettingJuristic.scss"
import { graphql } from "babel-plugin-relay/macro";
import { Redirect } from 'react-router';
import NavigationForjob from './NavigationForJob';
import PermissionManagementForJob from "./permisionManagementForJob"
import PermissionAccountingForJob from "./permissionAccountingForJob";
import { fetchQuery } from "relay-runtime";
import { commitMutation } from "react-relay";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import PermisionVMS from './permisionVMS';
import "./style/settingJuristicList.scss";

const _ = require('lodash');

const query = graphql`
query permisionForJobQuery ($positionId: String) {
    allPositionPermission(positionId: $positionId){
        edges{
            node{
                id
                positionName
                permissionPerPosition{
                    edges{
                        node{
                            id
                            permission{
                                id
                                codename
                                name
                            }
                        }
                    }
                }
            }
        }
    }              
}

`;

const mutation = graphql`
mutation permisionForJobMutation ($input: CreateAndUpdatePermissionPerPositionInput!) {
    createAndUpdatePermissionPerPosition (input: $input) {
        ok
        message
    }
}`;


const management_list = [
    {
        group_name: "Setting Other",
        key: "setting",
        child: [
            {
                name: "Setting Note Invoice",
                key: "accounting_settingnoteinvoice"
            },
            {
                name: "Setting Note Receive",
                key: "accounting_settingnotereceive"
            },
            {
                name: "Setting Project",
                key: "project_settingproject"
            },
            {
                name: "Setting Board Personal",
                key: "project_boardpersonal"
            },
            {
                name: "Setting Developer",
                key: "project_developerproject"
            },
            {
                name: "Setting Gateway",
                key: "accounting_gateway"
            }
        ]

    },
    {
        group_name: "Developer",
        key: "ownership",
        child: [
            {
                name: "Ownership Unit",
                key: "ownership_ownership"
            },
            {
                name: "Unit Report",
                key: "ownership_unitreport"
            },
            {
                name: "Obligation",
                key: "ownership_obligation"
            },
            {
                name: "Developer Detail",
                key: "ownership_developer"
            },
        ]

    },
    {
        group_name: "Project detail",
        key: "project",
        child: [
            {
                name: "Project info",
                key: "project_info"
            },
            {
                name: "Emergency contact",
                key: "project_emergency"
            },
            {
                name: "Project manager",
                key: "project_manager"
            },
            // {
            //     name: "Juristic person",
            //     key: "project_juristic"
            // },
            {
                name: "Juristic Manager",
                key: "project_juristicmanager"
            },
            {
                name: "Regulation",
                key: "project_regulation"
            },
            {
                name: "Juristic Company",
                key: "project_juristiccompany"
            },
            {
                name: "Project Staff",
                key: "project_projectstaff"
            },
            // {
            //     name: "Staff",
            //     key: "project_staff"
            // },
        ]
    },
    {
        group_name: "Register",
        key: "residential",
        child: [
            //อันเก่าขึ้นด้วย register
            {
                name: "Tenant",
                key: "tenant_tenant"
            },
            {
                name: "Venhicles",
                key: "project_venhicles"
            },
            {
                name: "Keycards",
                key: "project_keycards"
            },
            {
                name: "Pets",
                key: "project_pets"
            },
            {
                name: "Ownership",
                key: "register_ownership"
            },
        ]
    },
    {
        group_name: "Manage co-owners/members",
        key: "manage",
        child: [
            {
                name: "News, announcements",
                key: "contact_news"
            },
            {
                name: "Survey",
                key: "contact_survey"
            },
            {
                name: "Letters and parcels",
                key: "contact_parcel"
            },
            {
                name: "Complaint box",
                key: "contact_chat"
            },
            {
                name: "Fix request old",
                key: "contact_fix_request"
            },
            {
                name: "Fix request",
                key: "repairs_room"
            },
        ]
    },

    {
        group_name: "Common Area",
        key: "booking",
        child: [
            {
                name: "Booking Calendar",
                key: "booking_calendar"
            },
            {
                name: "Booking List",
                key: "booking_list"
            },
            {
                name: "Facility List",
                key: "booking_facility"
            },
            {
                name: "Setting Common Area",
                key: "booking_setting"
            },
        ]
    }


];
const account_list = [
    {
        group_name: "Setting Accounting",
        key: "setting",
        child: [

            {
                name: "Setting Chart of Account",
                key: "accounting_chartofaccount"
            },
            {
                name: "Setting Bring Forward Account",
                key: "accounting_accountrecord"
            },
            {
                name: "Co-owner / member",
                key: "contact_residential"
            },
            {
                name: "Receivable",
                key: "contact_receivable"
            },
            {
                name: "Payable",
                key: "contact_payable"
            },
            {
                name: "Products",
                key: "accounting_product"
            },
            {
                name: "Services",
                key: "accounting_service"
            },
            {
                name: "Setting Expense",
                key: "accounting_expense"
            },
            {
                name: "Bank",
                key: "accounting_bank"
            },
            {
                name: "Setting Asset",
                key: "asset_settingasset"
            },
            {
                name: "Setting Auto Invoice",
                key: "preinvoicegroup"
            },

        ]

    },
    {
        group_name: "Revenue",
        key: "income",
        child: [
            {
                name: "Invoices",
                key: "accounting_invoice"
            },
            {
                name: "Receive",
                key: "accounting_receive"
            },
            {
                name: "Credit note",
                key: "accounting_credit_note"
            },
            {
                name: "Receipt deposit",
                key: "accounting_receipt_deposit"
            },
            {
                name: "Guarantee money received",
                key: "accounting_guarantee_receive"
            },
            {
                name: "Other received",
                key: "accounting_other_receiver"
            },
            {
                name: "Contract Revenue",
                key: "accounting_contract_revenue"
            },
            {
                name: "Unknow receive",
                key: "accounting_unknown_receive"
            },
            {
                name: "Water meter record",
                key: "accounting_water_meter"
            },
            {
                name: "Electric meter record",
                key: "accounting_electric_meter"
            },
            {
                name: "Fine",
                key: "accounting_fine"
            },
            {
                name: "Record of Cross Billing Payment (QR Code)",
                key: "accounting_cross_billing"
            },
        ]
    },
    {
        group_name: "Expense",
        key: "expense",
        child: [
            {
                name: "Purchase",
                key: "accounting_purchase"
            },
            {
                name: "Pay",
                key: "accounting_pay"
            },
            {
                name: "Accept credit note",
                key: "accounting_accept_credit_note"
            },
            {
                name: "Prepaid deposit",
                key: "accounting_prepaid_deposit"
            },
            {
                name: "Petty cash",
                key: "accounting_petty_cash"
            },
            {
                name: "Advance",
                key: "accounting_advance"
            },
            {
                name: "Other expenses",
                key: "accounting_other_expense"
            },
            {
                name: "Contract Expense",
                key: "accounting_contract_expense"
            },

        ]
    },
    // {
    //     group_name: "Contact",
    //     key: "contact",
    //     child: [
    //         {
    //             name: "Co-owner / member",
    //             key: "contact_residential"
    //         },
    //         {
    //             name: "Receivable",
    //             key: "contact_receivable"
    //         },
    //         {
    //             name: "Payable",
    //             key: "contact_payable"
    //         },
    //     ]
    // },
    // {
    //     group_name: "Inventory",
    //     key: "product",
    //     child: [
    //         {
    //             name: "Products",
    //             key: "accounting_product"
    //         },
    //         {
    //             name: "Services",
    //             key: "accounting_service"
    //         },
    //         {
    //             name: "Expense",
    //             key: "accounting_expense"
    //         },
    //     ]
    // },
    {
        group_name: "Procurement and Purchase",
        key: "product",
        child: [
            {
                name: "Procurement",
                key: "accounting_procurement_form"
            },
            {
                name: "Purchase Order",
                key: "accounting_purchase_orderes"
            },
        ]
    },
    {
        group_name: "Stock",
        key: "stock",
        child: [
            {
                name: "Stock and Product",
                key: "accounting_productstock"
            },
            {
                name: "Product Transaction",
                key: "accounting_producttransaction"
            },
            {
                name: "Key of Product",
                key: "accounting_numkey"
            },
        ]
    },
    {
        group_name: "Finance",
        key: "finance",
        child: [
            {
                name: "Cash deposit",
                key: "accounting_cash"
            },
            {
                name: "Credit card",
                key: "accounting_credit_card"
            },
            // {
            //     name: "Bank",
            //     key: "accounting_bank"
            // },
            {
                name: "Bank transfer",
                key: "accounting_bank_transfer"
            },
            {
                name: "Bank Unit",
                key: "accounting_bankunit"
            },
            {
                name: "Cheque",
                key: "accounting_cheque_deposit"
            },
            {
                name: "Cheque transaction",
                key: "accounting_cheque"
            },
            {
                name: "Cheque Control",
                key: "accounting_chequecontrol"
            },
            // {
            //     name: "VAT",
            //     key: "accounting_tax"
            // },
            {
                name: "Withholding tax",
                key: "accounting_withholdingTax"
            },
            // {
            //     name: "Witheld tax",
            //     key: "accounting_withheldTax"
            // },
        ]
    },
    {
        group_name: "Daily journal",
        key: "daily_journal",
        child: [
            {
                name: "Daily_Purchase",
                key: "daily_journal_purchase"
            },
            {
                name: "Sales",
                key: "daily_journal_sales"
            },
            {
                name: "Payment",
                key: "daily_journal_payment"
            },
            {
                name: "Daily_Receive",
                key: "daily_journal_receive"
            },
            {
                name: "General",
                key: "daily_journal_general"
            },
        ]
    },
    {
        group_name: "Report",
        key: "report",
        child: [
            {
                name: "Debtor",
                key: "report_receivable"
            },
            {
                name: "Creditor",
                key: "report_payable"
            },
            {
                name: "Report Financial",
                key: "report_financial"
            },
            {
                name: "Asset",
                key: "report_asset"
            },
            {
                name: "Slip Verification",
                key: "slip_verify_log"
            },
            {
                name: "download file",
                key: "download_file"
            },
        ]
    },
    {
        group_name: "Account",
        key: "account",
        child: [
            {
                name: "Chart of Accounting",
                key: "accounting_chart_of_account"
            },
            {
                name: "General ledger",
                key: "accounting_general_ledger"
            },
            {
                name: "Trial Balance",
                key: "accounting_trial_balance"
            },
            {
                name: "Work Sheet",
                key: "accounting_work_sheet"
            },
            {
                name: "Balance sheet",
                key: "accounting_balance_sheet"
            },
            {
                name: "Summary of income - expense",
                key: "accounting_accrued_revenue_expenditure"
            },
            {
                name: "Accrued revenue expenditure",
                key: "accounting_cash_flow_statement"
            },
            {
                name: "Cash flow statement",
                key: "accounting_summary_income_expense"
            },
            {
                name: "Budget",
                key: "accounting_budget"
            },
            {
                name: "Asset",
                key: "accounting_asset"
            },
        ]
    },
    {
        group_name: "Document",
        key: "document",
        child: [
            {
                name: "Setting Automatic Debit",
                key: "accounting_settingautomaticdebit"
            },
            {
                name: "Collection Letters",
                key: "accounting_collectionletters"
            },
            {
                name: "Setting Debs Free Certification",
                key: "accounting_settingdebsfreecertification"
            },
            {
                name: "Request for Debt-Free Certification",
                key: "accounting_debfreecertificationrequest"
            },
            {
                name: "Deb Free Certification",
                key: "accounting_debfreecertification"
            },
            {
                name:"Debt Collection Attorney",
                key:"collection_law_frim"
            }

        ]
    },
];
const silverman_list = require("./query/listsPermision.json");


const manage_list_not_disable = ['print', 'approve']
const account_list_not_disable = ['edit', 'approve', 'print']
// const silverman_listnot = ['edit', 'approve', 'print']


class PermissionForJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            permissions: {

                // ---------- management -------- //
                contact_news: false,
                contact_news_create: false,
                contact_news_edit: false,
                contact_news_delete: false,
                contact_news_approve: false,

                contact_survey: false,
                contact_survey_create: false,
                contact_survey_edit: false,
                contact_survey_delete: false,
                contact_survey_approve: false,
                contact_survey_print: false,

                contact_parcel: false,
                contact_parcel_create: false,
                contact_parcel_edit: false,
                contact_parcel_delete: false,
                contact_parcel_print: false,

                contact_chat: false,
                contact_chat_create: false,
                contact_chat_edit: false,
                contact_chat_approve: false,
                contact_chat_print: false,

                contact_fix_request: false,
                contact_fix_request_edit: false,
                contact_fix_request_approve: false,
                contact_fix_request_print: false,

                // แจ้งซ่อมใหม่ 
                repairs_room: false,
                repairs_room_create: false,
                repairs_room_edit: false,
                repairs_room_approve: false,
                repairs_room_print: false,
                repairs_room_delete: false,

                register_residential: false,
                register_residential_create: false,
                register_residential_edit: false,
                register_residential_delete: false,
                register_residential_print: false,

                register_vehicle: false,
                register_vehicle_create: false,
                register_vehicle_edit: false,
                register_vehicle_delete: false,

                register_key_card: false,
                register_key_card_create: false,
                register_key_card_edit: false,
                register_key_card_delete: false,

                register_pet: false,
                register_pet_create: false,
                register_pet_edit: false,
                register_pet_delete: false,

                register_ownership: false,
                register_ownership_create: false,
                register_ownership_edit: false,
                register_ownership_delete: false,

                project_info: false,
                project_info_create: false,
                project_info_edit: false,
                project_info_delete: false,

                project_emergency: false,
                project_emergency_create: false,
                project_emergency_edit: false,
                project_emergency_delete: false,

                project_manager: false,
                project_manager_create: false,
                project_manager_edit: false,
                project_manager_delete: false,

                project_juristic: false,
                project_juristic_create: false,
                project_juristic_edit: false,
                project_juristic_delete: false,

                project_staff: false,
                project_staff_create: false,
                project_staff_edit: false,
                project_staff_delete: false,

                // ------------------------ //

                // ---------- accounting ----------- //

                accounting_invoice: false,
                accounting_invoice_create: false,
                accounting_invoice_edit: false,
                accounting_invoice_delete: false,
                accounting_invoice_approve: false,
                accounting_invoice_print: false,

                accounting_receive: false,
                accounting_receive_create: false,
                accounting_receive_edit: false,
                accounting_receive_delete: false,
                accounting_receive_approve: false,
                accounting_receive_print: false,

                accounting_credit_note: false,
                accounting_credit_note_create: false,
                accounting_credit_note_edit: false,
                accounting_credit_note_delete: false,
                accounting_credit_note_approve: false,
                accounting_credit_note_print: false,

                accounting_receipt_deposit: false,
                accounting_receipt_deposit_create: false,
                accounting_receipt_deposit_edit: false,
                accounting_receipt_deposit_delete: false,
                accounting_receipt_deposit_approve: false,
                accounting_receipt_deposit_print: false,

                accounting_guarantee_receive: false,
                accounting_guarantee_receive_create: false,
                accounting_guarantee_receive_edit: false,
                accounting_guarantee_receive_delete: false,
                accounting_guarantee_receive_print: false,

                accounting_other_receiver: false,
                accounting_other_receiver_create: false,
                accounting_other_receiver_edit: false,
                accounting_other_receiver_delete: false,
                accounting_other_receiver_approve: false,
                accounting_other_receiver_print: false,

                accounting_contract_service: false,
                accounting_contract_service_create: false,
                accounting_contract_service_edit: false,
                accounting_contract_service_delete: false,
                accounting_contract_service_approve: false,
                accounting_contract_service_print: false,

                accounting_unknown_receive: false,
                accounting_unknown_receive_create: false,
                accounting_unknown_receive_edit: false,
                accounting_unknown_receive_delete: false,
                accounting_unknown_receive_approve: false,
                accounting_unknown_receive_print: false,

                // ที่มีในระบบ
                // accounting_water_meter_print
                // accounting_water_meter_delete
                // accounting_water_meter_edit
                // accounting_water_meter

                accounting_water_meter: false,
                accounting_water_meter_create: false,
                accounting_water_meter_edit: false,
                accounting_water_meter_delete: false,
                accounting_water_meter_approve: false,
                accounting_water_meter_print: false,

                // ที่มีในระบบ
                // accounting_electric_meter_print
                // accounting_electric_meter_delete
                // accounting_electric_meter_edit
                // accounting_electric_meter

                accounting_electric_meter: false,
                accounting_electric_meter_create: false,
                accounting_electric_meter_edit: false,
                accounting_electric_meter_delete: false,
                accounting_electric_meter_approve: false,
                accounting_electric_meter_print: false,

                // ที่มีในระบบ
                // accounting_fine_print
                // accounting_fine_create
                // accounting_fine
                accounting_fine: false,
                accounting_fine_create: false,
                accounting_fine_edit: false,
                accounting_fine_delete: false,
                accounting_fine_approve: false,
                accounting_fine_print: false,

                // ที่มีในระบบ
                // accounting_cross_billing_create
                accounting_cross_billing: false,
                accounting_cross_billing_create: false,
                accounting_cross_billing_edit: false,
                accounting_cross_billing_delete: false,
                accounting_cross_billing_approve: false,

                // ที่มีในระบบ
                // accounting_purchase_print
                // accounting_purchase_delete
                // accounting_purchase_create
                // accounting_purchase
                accounting_purchase: false,
                accounting_purchase_create: false,
                accounting_purchase_edit: false,
                accounting_purchase_delete: false,
                accounting_purchase_approve: false,
                accounting_purchase_print: false,

                // ที่มีในระบบ
                // accounting_pay_print
                // accounting_pay_delete
                // accounting_pay_create
                // accounting_pay
                accounting_pay: false,
                accounting_pay_create: false,
                accounting_pay_edit: false,
                accounting_pay_delete: false,
                accounting_pay_approve: false,
                accounting_pay_print: false,

                // ที่มีในระบบ
                // accounting_accept_credit_note_print
                // accounting_accept_credit_note_delete
                // accounting_accept_credit_note_create
                // accounting_accept_credit_note
                accounting_accept_credit_note: false,
                accounting_accept_credit_note_create: false,
                accounting_accept_credit_note_edit: false,
                accounting_accept_credit_note_delete: false,
                accounting_accept_credit_note_approve: false,
                accounting_accept_credit_note_print: false,

                // ที่มีในระบบ
                // accounting_prepaid_deposit_print
                // accounting_prepaid_deposit_delete
                // accounting_prepaid_deposit_create
                // accounting_prepaid_deposit
                accounting_prepaid_deposit: false,
                accounting_prepaid_deposit_create: false,
                accounting_prepaid_deposit_edit: false,
                accounting_prepaid_deposit_delete: false,
                accounting_prepaid_deposit_approve: false,
                accounting_prepaid_deposit_print: false,

                accounting_petty_cash: false,
                accounting_petty_cash_create: false,
                accounting_petty_cash_edit: false,
                accounting_petty_cash_delete: false,
                accounting_petty_cash_approve: false,
                accounting_petty_cash_print: false,

                // ที่มีในระบบ
                // accounting_advance_delete
                // accounting_advance_create
                // accounting_advance
                accounting_advance: false,
                accounting_advance_create: false,
                accounting_advance_delete: false,
                accounting_advance_approve: false,

                // ที่มีในระบบ
                // accounting_other_expense_print
                // accounting_other_expense_delete
                // accounting_other_expense_create
                // accounting_other_expense
                accounting_other_expense: false,
                accounting_other_expense_create: false,
                accounting_other_expense_edit: false,
                accounting_other_expense_delete: false,
                accounting_other_expense_approve: false,
                accounting_other_expense_print: false,

                // ที่มีในระบบ
                // contact_residential_print
                // contact_residential
                // contact_residential_edit
                contact_residential: false,
                contact_residential_create: false,
                contact_residential_edit: false,
                contact_residential_delete: false,
                contact_residential_approve: false,
                contact_residential_print: false,

                // ที่มีในระบบ
                // contact_receivable
                // contact_receivable_create
                // contact_receivable_edit
                contact_receivable: false,
                contact_receivable_create: false,
                contact_receivable_edit: false,
                contact_receivable_delete: false,
                contact_receivable_approve: false,

                // ที่มีในระบบ
                // contact_payable
                // contact_payable_create
                // contact_payable_edit
                contact_payable: false,
                contact_payable_create: false,
                contact_payable_edit: false,
                contact_payable_delete: false,
                contact_payable_approve: false,

                // ที่มีในระบบ
                // accounting_product_print
                // accounting_product_delete
                // accounting_product_edit
                // accounting_product_create
                // accounting_product
                accounting_product: false,
                accounting_product_create: false,
                accounting_product_edit: false,
                accounting_product_delete: false,
                accounting_product_approve: false,
                accounting_product_print: false,

                // ที่มีในระบบ
                // accounting_service
                // accounting_service_create
                // accounting_service_edit
                // accounting_service_delete
                accounting_service: false,
                accounting_service_create: false,
                accounting_service_edit: false,
                accounting_service_delete: false,
                accounting_service_approve: false,

                // ที่มีในระบบ
                // accounting_expense_delete
                // accounting_expense_edit
                // accounting_expense_create
                // accounting_expense
                accounting_expense: false,
                accounting_expense_create: false,
                accounting_expense_edit: false,
                accounting_expense_delete: false,
                accounting_expense_approve: false,

                // ที่มีในระบบ
                // accounting_cash_delete
                // accounting_cash_create
                // accounting_cash
                accounting_cash: false,
                accounting_cash_create: false,
                accounting_cash_edit: false,
                accounting_cash_delete: false,
                accounting_cash_approve: false,

                // ที่มีในระบบ
                // accounting_credit_card_delete
                // accounting_credit_card_create
                // accounting_credit_card
                accounting_credit_card: false,
                accounting_credit_card_create: false,
                accounting_credit_card_edit: false,
                accounting_credit_card_delete: false,
                accounting_credit_card_approve: false,

                // ที่มีในระบบ
                // accounting_bank_edit
                // accounting_bank_create
                // accounting_bank
                accounting_bank: false,
                accounting_bank_create: false,
                accounting_bank_edit: false,
                accounting_bank_delete: false,
                accounting_bank_approve: false,

                accounting_bank_transfer: false,
                accounting_bank_transfer_create: false,
                accounting_bank_transfer_print: false,

                // ที่มีในระบบ
                // accounting_cheque_deposit
                // accounting_cheque_deposit_create
                // accounting_cheque_deposit_edit
                // accounting_cheque_deposit_delete
                // accounting_cheque_deposit_print
                accounting_cheque_deposit: false,
                accounting_cheque_deposit_create: false,
                accounting_cheque_deposit_edit: false,
                accounting_cheque_deposit_delete: false,
                accounting_cheque_deposit_approve: false,
                accounting_cheque_deposit_print: false,

                // ที่มีในระบบ
                // accounting_cheque
                // accounting_cheque_create
                // accounting_cheque_edit
                // accounting_cheque_delete
                // accounting_cheque_print
                accounting_cheque: false,
                accounting_cheque_create: false,
                accounting_cheque_edit: false,
                accounting_cheque_delete: false,
                accounting_cheque_approve: false,
                accounting_cheque_print: false,

                // ที่มีในระบบ
                // accounting_tax
                accounting_tax: false,
                accounting_tax_create: false,
                accounting_tax_edit: false,
                accounting_tax_delete: false,
                accounting_tax_approve: false,

                // ที่มีในระบบ
                // accounting_withholdingTax_print
                // accounting_withholdingTax
                accounting_withholdingTax: false,
                accounting_withholdingTax_create: false,
                accounting_withholdingTax_edit: false,
                accounting_withholdingTax_delete: false,
                accounting_withholdingTax_approve: false,
                accounting_withholdingTax_print: false,

                // ที่มีในระบบ
                // accounting_withheldTax
                accounting_withheldTax: false,
                accounting_withheldTax_create: false,
                accounting_withheldTax_edit: false,
                accounting_withheldTax_delete: false,
                accounting_withheldTax_approve: false,

                // ที่มีในระบบ
                // daily_journal_purchase_print
                // daily_journal_purchase
                daily_journal_purchase: false,
                daily_journal_purchase_create: false,
                daily_journal_purchase_edit: false,
                daily_journal_purchase_delete: false,
                daily_journal_purchase_approve: false,
                daily_journal_purchase_print: false,

                // ที่มีในระบบ
                // daily_journal_sales_print
                // daily_journal_sales
                daily_journal_sales: false,
                daily_journal_sales_create: false,
                daily_journal_sales_edit: false,
                daily_journal_sales_delete: false,
                daily_journal_sales_approve: false,
                daily_journal_sales_print: false,

                // ที่มีในระบบ
                // daily_journal_payment_edit
                // daily_journal_payment_print
                // daily_journal_payment
                // daily_journal_payment_create
                daily_journal_payment: false,
                daily_journal_payment_create: false,
                daily_journal_payment_edit: false,
                daily_journal_payment_delete: false,
                daily_journal_payment_approve: false,
                daily_journal_payment_print: false,

                // ที่มีในระบบ
                // daily_journal_receive_edit
                // daily_journal_receive_print
                // daily_journal_receive
                // daily_journal_receive_create
                daily_journal_receive: false,
                daily_journal_receive_create: false,
                daily_journal_receive_edit: false,
                daily_journal_receive_delete: false,
                daily_journal_receive_approve: false,
                daily_journal_receive_print: false,

                // ที่มีในระบบ
                // daily_journal_general_edit
                // daily_journal_general_print
                // daily_journal_general
                // daily_journal_general_create
                daily_journal_general: false,
                daily_journal_general_create: false,
                daily_journal_general_edit: false,
                daily_journal_general_delete: false,
                daily_journal_general_approve: false,
                daily_journal_general_print: false,

                report_receivable: false,
                report_receivable_create: false,
                report_receivable_print: false,

                report_payable: false,
                report_payable_create: false,
                report_payable_print: false,

                report_asset: false,
                report_asset_create: false,
                report_asset_print: false,


                // ที่มีในระบบ
                // accounting_chart_of_account_print
                // accounting_chart_of_account
                // accounting_chart_of_account_create
                // accounting_chart_of_account_edit
                accounting_chart_of_account: false,
                accounting_chart_of_account_create: false,
                accounting_chart_of_account_edit: false,
                accounting_chart_of_account_delete: false,
                accounting_chart_of_account_approve: false,
                accounting_chart_of_account_print: false,

                // ที่มีในระบบ
                // accounting_general_ledger_print
                // accounting_general_ledger
                accounting_general_ledger: false,
                accounting_general_ledger_create: false,
                accounting_general_ledger_edit: false,
                accounting_general_ledger_delete: false,
                accounting_general_ledger_approve: false,
                accounting_general_ledger_print: false,

                // ที่มีในระบบ
                // accounting_trial_balance_print
                // accounting_trial_balance
                accounting_trial_balance: false,
                accounting_trial_balance_create: false,
                accounting_trial_balance_edit: false,
                accounting_trial_balance_delete: false,
                accounting_trial_balance_approve: false,
                accounting_trial_balance_print: false,

                // ที่มีในระบบ
                // accounting_balance_sheet
                // accounting_balance_sheet_print
                accounting_balance_sheet: false,
                accounting_balance_sheet_create: false,
                accounting_balance_sheet_edit: false,
                accounting_balance_sheet_delete: false,
                accounting_balance_sheet_approve: false,
                accounting_balance_sheet_print: false,

                // ที่มีในระบบ
                // accounting_accrued_revenue_expenditure
                // accounting_accrued_revenue_expenditure_print
                accounting_accrued_revenue_expenditure: false,
                accounting_accrued_revenue_expenditure_create: false,
                accounting_accrued_revenue_expenditure_edit: false,
                accounting_accrued_revenue_expenditure_delete: false,
                accounting_accrued_revenue_expenditure_approve: false,
                accounting_accrued_revenue_expenditure_print: false,

                // ที่มีในระบบ
                // accounting_cash_flow_statement
                // accounting_cash_flow_statement_print
                accounting_cash_flow_statement: false,
                accounting_cash_flow_statement_create: false,
                accounting_cash_flow_statement_edit: false,
                accounting_cash_flow_statement_delete: false,
                accounting_cash_flow_statement_approve: false,
                accounting_cash_flow_statement_print: false,

                // ที่มีในระบบ
                // accounting_summary_income_expense
                // accounting_summary_income_expense_print
                accounting_summary_income_expense: false,
                accounting_summary_income_expense_create: false,
                accounting_summary_income_expense_edit: false,
                accounting_summary_income_expense_delete: false,
                accounting_summary_income_expense_approve: false,
                accounting_summary_income_expense_print: false,

                // ที่มีในระบบ
                // accounting_asset
                // accounting_asset_create
                // accounting_asset_edit
                // accounting_asset_delete
                accounting_asset: false,
                accounting_asset_create: false,
                accounting_asset_edit: false,
                accounting_asset_delete: false,
                accounting_asset_approve: false,
                accounting_asset_print: false,


                //เพิ่มใหม่ 4/01/2565 ---- management -----

                //ตั้งค่าหมายเหตุการออกใบแจ้งหนี้
                accounting_settingnoteinvoice: false,
                accounting_settingnoteinvoice_create: false,
                accounting_settingnoteinvoice_edit: false,
                accounting_settingnoteinvoice_delete: false,
                accounting_settingnoteinvoice_approve: false,
                accounting_settingnoteinvoice_print: false,

                //ตั้งค่าหมายเหตุใบเสร็จรับเงิน
                accounting_settingnotereceive: false,
                accounting_settingnotereceive_create: false,
                accounting_settingnotereceive_edit: false,
                accounting_settingnotereceive_delete: false,
                accounting_settingnotereceive_approve: false,
                accounting_settingnotereceive_print: false,

                //ตั้งค่าข้อมูลโครงการ
                project_settingproject: false,
                project_settingproject_create: false,
                project_settingproject_edit: false,
                project_settingproject_delete: false,
                project_settingproject_approve: false,
                project_settingproject_print: false,

                //ตั้งค่าสำหรับผู้พัฒนาโครงการ
                project_developerproject: false,
                project_developerproject_create: false,
                project_developerproject_edit: false,
                project_developerproject_delete: false,
                project_developerproject_approve: false,
                project_developerproject_print: false,

                //ตั้งค่าคณะกรรมการ
                project_boardpersonal: false,
                project_boardpersonal_create: false,
                project_boardpersonal_edit: false,
                project_boardpersonal_delete: false,
                project_boardpersonal_approve: false,
                project_boardpersonal_print: false,

                //สถานะยูนิตขาย
                ownership_ownership: false,
                ownership_ownership_create: false,
                ownership_ownership_edit: false,
                ownership_ownership_delete: false,
                ownership_ownership_approve: false,
                ownership_ownership_print: false,

                //รายงารญุนิตสมทบเรียกเก็บ
                ownership_unitreport: false,
                ownership_unitreport_create: false,
                ownership_unitreport_edit: false,
                ownership_unitreport_delete: false,
                ownership_unitreport_approve: false,
                ownership_unitreport_print: false,

                //สัญญารายรับ
                ownership_obligation: false,
                ownership_obligation_create: false,
                ownership_obligation_edit: false,
                ownership_obligation_delete: false,
                ownership_obligation_approve: false,
                ownership_obligation_print: false,

                //ข้อมูลทั่วไป
                ownership_developer: false,
                ownership_developer_create: false,
                ownership_developer_edit: false,
                ownership_developer_delete: false,
                ownership_developer_approve: false,
                ownership_developer_print: false,

                //ผู้จัดการนิติบุคคล
                project_juristicmanager: false,
                project_juristicmanager_create: false,
                project_juristicmanager_edit: false,
                project_juristicmanager_delete: false,
                project_juristicmanager_approve: false,
                project_juristicmanager_print: false,

                //ข้อบังคับ
                project_regulation: false,
                project_regulation_create: false,
                project_regulation_edit: false,
                project_regulation_delete: false,
                project_regulation_approve: false,
                project_regulation_print: false,

                //บริษัทบริหารนิติบุคคล
                project_juristiccompany: false,
                project_juristiccompany_create: false,
                project_juristiccompany_edit: false,
                project_juristiccompany_delete: false,
                project_juristiccompany_approve: false,
                project_juristiccompany_print: false,

                //บุคคลากรที่เกี่ยวข้อง
                project_projectstaff: false,
                project_projectstaff_create: false,
                project_projectstaff_edit: false,
                project_projectstaff_delete: false,
                project_projectstaff_approve: false,
                project_projectstaff_print: false,

                //ทะเบียนลูกบ้าน
                tenant_tenant: false,
                tenant_tenant_create: false,
                tenant_tenant_edit: false,
                tenant_tenant_delete: false,
                tenant_tenant_approve: false,
                tenant_tenant_print: false,

                //ยานพาหนะ
                project_venhicles: false,
                project_venhicles_create: false,
                project_venhicles_edit: false,
                project_venhicles_delete: false,
                project_venhicles_approve: false,
                project_venhicles_print: false,

                //key card
                project_keycards: false,
                project_keycards_create: false,
                project_keycards_edit: false,
                project_keycards_delete: false,
                project_keycards_approve: false,
                project_keycards_print: false,

                //pet
                project_pets: false,
                project_pets_create: false,
                project_pets_edit: false,
                project_pets_delete: false,
                project_pets_approve: false,
                project_pets_print: false,

                //สรุปตารางการจอง
                booking_calendar: false,
                booking_calendar_create: false,
                booking_calendar_edit: false,
                booking_calendar_delete: false,
                booking_calendar_approve: false,
                booking_calendar_print: false,

                //รายการจองพื้นที่
                booking_list: false,
                booking_list_create: false,
                booking_list_edit: false,
                booking_list_delete: false,
                booking_list_approve: false,
                booking_list_print: false,

                //รายการพื้นที่
                booking_facility: false,
                booking_facility_create: false,
                booking_facility_edit: false,
                booking_facility_delete: false,
                booking_facility_approve: false,
                booking_facility_print: false,

                //ตั้งค่าการจอง
                booking_setting: false,
                booking_setting_create: false,
                booking_setting_edit: false,
                booking_setting_delete: false,
                booking_setting_approve: false,
                booking_setting_print: false,

                //เพิ่มใหม่ 11/01/2566 ---- management -----
                accounting_gateway: false,
                accounting_gateway_create: false,
                accounting_gateway_edit: false,
                accounting_gateway_delete: false,
                accounting_gateway_approve: false,
                accounting_gateway_print: false,


                //เพิ่มใหม่ 4/01/2565 ---- accounting -----

                //ตั้งค่าผังบัญชี
                accounting_chartofaccount: false,
                accounting_chartofaccount_create: false,
                accounting_chartofaccount_edit: false,
                accounting_chartofaccount_delete: false,
                accounting_chartofaccount_approve: false,
                accounting_chartofaccount_print: false,

                //บันทึกยอดยกมา
                accounting_accountrecord: false,
                accounting_accountrecord_create: false,
                accounting_accountrecord_edit: false,
                accounting_accountrecord_delete: false,
                accounting_accountrecord_approve: false,
                accounting_accountrecord_print: false,

                //ตั้งค่าสินทรัพย์
                asset_settingasset: false,
                asset_settingasset_create: false,
                asset_settingasset_edit: false,
                asset_settingasset_delete: false,
                asset_settingasset_approve: false,
                asset_settingasset_print: false,

                //ตั้งค่าเงื่อนไขการออกใบแจ้งหนี้
                preinvoicegroup: false,
                preinvoicegroup_create: false,
                preinvoicegroup_edit: false,
                preinvoicegroup_delete: false,
                preinvoicegroup_approve: false,
                preinvoicegroup_print: false,

                //ตั้งค่าเงื่อนไขการออกใบแจ้งหนี้
                setuppaymentgateway: false,
                setuppaymentgateway_create: false,
                setuppaymentgateway_edit: false,
                setuppaymentgateway_delete: false,
                setuppaymentgateway_approve: false,
                setuppaymentgateway_print: false,

                //บันทึกสัญญาบริการพื้นที่ส่วนกลาง
                accounting_contract_revenue: false,
                accounting_contract_revenue_create: false,
                accounting_contract_revenue_edit: false,
                accounting_contract_revenue_delete: false,
                accounting_contract_revenue_approve: false,
                accounting_contract_revenue_print: false,

                //บันทึกสัญญารายจ่าย
                accounting_contract_expense: false,
                accounting_contract_expense_create: false,
                accounting_contract_expense_edit: false,
                accounting_contract_expense_delete: false,
                accounting_contract_expense_approve: false,
                accounting_contract_expense_print: false,

                //จัดการใบขอซื้อ
                accounting_procurement_form: false,
                accounting_procurement_form_create: false,
                accounting_procurement_form_edit: false,
                accounting_procurement_form_delete: false,
                accounting_procurement_form_approve: false,
                accounting_procurement_form_print: false,
                accounting_procurement_form_limit: 0,

                //จัดการใบสั่งซื้อ
                accounting_purchase_orderes: false,
                accounting_purchase_orderes_create: false,
                accounting_purchase_orderes_edit: false,
                accounting_purchase_orderes_delete: false,
                accounting_purchase_orderes_approve: false,
                accounting_purchase_orderes_print: false,
                accounting_purchase_orderes_limit: 0,

                //การจัดการสต็อคและวัสดุสิ้นเปลือง
                accounting_productstock: false,
                accounting_productstock_create: false,
                accounting_productstock_edit: false,
                accounting_productstock_delete: false,
                accounting_productstock_approve: false,
                accounting_productstock_print: false,

                //รายงานเคลื่อนไหวสต็อคและวัสดุสิ้นเปลือง
                accounting_producttransaction: false,
                accounting_producttransaction_create: false,
                accounting_producttransaction_edit: false,
                accounting_producttransaction_delete: false,
                accounting_producttransaction_approve: false,
                accounting_producttransaction_print: false,

                //รายงานสต็อคและวัสดุสิ้นเปลืองเรียงตามเลขที่
                accounting_numkey: false,
                accounting_numkey_create: false,
                accounting_numkey_edit: false,
                accounting_numkey_delete: false,
                accounting_numkey_approve: false,
                accounting_numkey_print: false,

                //บัญชีเงินฝากประจำ
                accounting_bankunit: false,
                accounting_bankunit_create: false,
                accounting_bankunit_edit: false,
                accounting_bankunit_delete: false,
                accounting_bankunit_approve: false,
                accounting_bankunit_print: false,

                //ทะเบียนคุมเช็ค
                accounting_chequecontrol: false,
                accounting_chequecontrol_create: false,
                accounting_chequecontrol_edit: false,
                accounting_chequecontrol_delete: false,
                accounting_chequecontrol_approve: false,
                accounting_chequecontrol_print: false,

                //รายงานการเงิน
                report_financial: false,
                report_financial_create: false,
                report_financial_edit: false,
                report_financial_delete: false,
                report_financial_approve: false,
                report_financial_print: false,

                //รายงาน Slip Verification
                slip_verify_log: false,
                // slip_verify_log_create: false,
                // slip_verify_log_edit: false,
                // slip_verify_log_delete: false,
                // slip_verify_log_approve:false,
                // slip_verify_log_print:false,

                 //download menu
                download_file: false,
                download_file_create: false,
                download_file_edit: false,
                download_file_delete: false,
                download_file_approve: false,
                download_file_print: false,

                //กระดาษทำการ
                accounting_work_sheet: false,
                accounting_work_sheet_create: false,
                accounting_work_sheet_edit: false,
                accounting_work_sheet_delete: false,
                accounting_work_sheet_approve: false,
                accounting_work_sheet_print: false,

                //งบประมาณ
                accounting_budget: false,
                accounting_budget_create: false,
                accounting_budget_edit: false,
                accounting_budget_delete: false,
                accounting_budget_approve: false,
                accounting_budget_print: false,


                //ตั้งค่าหนังสือทวงถาม
                accounting_settingautomaticdebit: false,
                accounting_settingautomaticdebit_create: false,
                accounting_settingautomaticdebit_edit: false,
                accounting_settingautomaticdebit_delete: false,
                accounting_settingautomaticdebit_approve: false,
                accounting_settingautomaticdebit_print: false,

                //หนังสือทวงถาม
                accounting_collectionletters: false,
                accounting_collectionletters_create: false,
                accounting_collectionletters_edit: false,
                accounting_collectionletters_delete: false,
                accounting_collectionletters_approve: false,
                accounting_collectionletters_print: false,

                //กำหนดค่าธรรมเนียนการออกเอกสาร
                accounting_settingdebsfreecertification: false,
                accounting_settingdebsfreecertification_create: false,
                accounting_settingdebsfreecertification_edit: false,
                accounting_settingdebsfreecertification_delete: false,
                accounting_settingdebsfreecertification_approve: false,
                accounting_settingdebsfreecertification_print: false,

                //คำร้องขอหนังสือรับรองการปลอดหนี้
                accounting_debfreecertificationrequest: false,
                accounting_debfreecertificationrequest_create: false,
                accounting_debfreecertificationrequest_edit: false,
                accounting_debfreecertificationrequest_delete: false,
                accounting_debfreecertificationrequest_approve: false,
                accounting_debfreecertificationrequest_print: false,

                //หนังสือรับรองการปลอดหนี้
                accounting_debfreecertification: false,
                accounting_debfreecertification_create: false,
                accounting_debfreecertification_edit: false,
                accounting_debfreecertification_delete: false,
                accounting_debfreecertification_approve: false,
                accounting_debfreecertification_print: false,

                //VMS
                vms_manage_visitor: false,
                vms_manage_visitor_create: false,
                vms_manage_visitor_edit: false,
                vms_manage_visitor_approve: false,
                vms_manage_visitor_print: false,
                vms_manage_visitor_delete: false,
                vms_manage_visitor_view: false,

                vms_visitor_report: false,
                vms_visitor_report_create: false,
                vms_visitor_report_edit: false,
                vms_visitor_report_approve: false,
                vms_visitor_report_print: false,
                vms_visitor_report_delete: false,
                vms_visitor_report_view: false,

                vms_packing_income: false,
                vms_packing_income_create: false,
                vms_packing_income_edit: false,
                vms_packing_income_approve: false,
                vms_packing_income_print: false,
                vms_packing_income_delete: false,
                vms_packing_income_view: false,
                // ------------------------------------------ //
                checkUserJuristicAdmin: false,

                 //colloection law confrim 
                 collection_law_frim: false,
                 collection_law_frim_create: false,
                 collection_law_frim_delete: false,
                 collection_law_frim_edit: false,
                 collection_law_frim_print: false,
            },
            positionName: '',
            positionNameData: '',
            redirectToList: false

        };
        this.onSubmit = this.onSubmit.bind(this);
        this.updatePermissionCheck = this.updatePermissionCheck.bind(this);
        this.updatePermissionLimit = this.updatePermissionLimit.bind(this);
        this.clickCheckAllList = this.clickCheckAllList.bind(this);
        this.checkAllInput = this.checkAllInput.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {

        if (this.props.match.params.id) {
            fetchQuery(environment, query, { positionId: this.props.match.params.id }).then(data => {
                // console.log(data);
                if (data) {

                    let positionData = data.allPositionPermission.edges[0].node.permissionPerPosition.edges
                    // let limit = JSON.parse(data.getJuristicLimit)
                    // this.setState(prevState => _.set(_.cloneDeep(prevState), "permissions." + 'accounting_procurement_form_limit',limit.accounting_procurement_form_limit ));
                    // this.setState(prevState => _.set(_.cloneDeep(prevState), "permissions." + 'accounting_purchase_orderes_limit', limit.accounting_purchase_orderes_limit));
                    this.setState({ positionNameData: data.allPositionPermission.edges[0].node.positionName });
                    positionData.forEach((permissionPosition) => {
                        this.setState(prevState => {
                            return _.set(_.cloneDeep(prevState), "permissions." + permissionPosition.node.permission.codename, true);
                        });
                    })
                }
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let variables = {
            input: {
                clientMutationId: this.props.match.params.id,
                positionName: this.props.match.params.id ? this.state.positionNameData : this.state.positionName,
                permission: JSON.stringify(this.state.permissions),
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({ loading: false });
                    if (response) {
                        Swal.fire(i18n.t("set_permission:Save successfully!"), '', 'success').then(() => {
                            this.setState({ redirectToList: true });
                        });
                    } else {
                        Swal.fire(i18n.t("set_permission:Save unsuccessfully!"), i18n.t("set_permission:Please check again"), 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({ loading: false });
                    Swal.fire('Error!', i18n.t("set_permission:Please try again"), 'warning')
                },
            },
        );

    }


    updatePermissionCheck(key, e) {
        let value = _.cloneDeep(e);
        this.setState(prevState => {
            return _.set(_.cloneDeep(prevState), "permissions." + key, value.target.checked);
        });
    }

    updatePermissionLimit(key, e) {
        let value = _.cloneDeep(e);
        this.setState(prevState => {
            return _.set(_.cloneDeep(prevState), "permissions." + key, value.target.value);
        });
    }

    clickCheckAllList(key, group_input) {
        let type_permission = ['', 'create', 'edit', 'delete', 'approve', 'print']
        let group_input_list = _.clone(group_input)
        if (this.checkAllInput(key, group_input)) {
            type_permission.forEach((type, type_index) => {
                this.setState(prevState => {
                    return _.set(_.cloneDeep(prevState), "permissions." + key + (type_index === 0 ? '' : '_') + type, false);
                });
            })
        } else {
            type_permission.forEach((type, type_index) => {
                if (group_input_list.list_not_disable.includes(type)) { // ถ้า typeนี้อยู่ใน list_disable แสดงว่าสามารถ input ได้
                    if (_.indexOf(group_input_list[type + '_input'], key) !== -1) {
                        this.setState(prevState => {
                            return _.set(_.cloneDeep(prevState), "permissions." + key + (type_index === 0 ? '' : '_') + type, true);
                        });
                    }
                } else {
                    if (_.indexOf(group_input_list[type + '_input'], key) === -1) {
                        this.setState(prevState => {
                            return _.set(_.cloneDeep(prevState), "permissions." + key + (type_index === 0 ? '' : '_') + type, true);
                        });
                    }
                }
            })
        }
    }


    checkAllInput(key, group_input) {
        let type_permission = ['', 'create', 'edit', 'delete', 'approve', 'print']
        let true_all_permission = true;
        let group_input_list = _.clone(group_input)
        type_permission.forEach((type, type_index) => {
            if (this.state.permissions[key + (type_index === 0 ? '' : '_') + type] === false) {
                if (_.indexOf(group_input_list.list_not_disable, type) !== -1) {
                    if (_.indexOf(group_input_list[type + '_input'], key) !== -1) {
                        true_all_permission = false
                    }
                } else {
                    if (_.indexOf(group_input_list[type + '_input'], key) === -1) {
                        true_all_permission = false
                    }
                }
            }
        })

        return true_all_permission;
    }


    handleInputChange(event) {
        this.setState({ positionName: event.target.value });
    }


    //function for Permision VMS
    dataRetrun = (group_input, key) => {
        let groypInput = []
        groypInput.push("")
        if (group_input.create_input.includes(key)) {
            groypInput.push("create")
        }
        if (group_input.edit_input.includes(key)) {
            groypInput.push("edit")
        }
        if (group_input.approve_input.includes(key)) {
            groypInput.push("approve")
        }
        if (group_input.delete_input.includes(key)) {
            groypInput.push("delete")
        }
        if (group_input.print_input.includes(key)) {
            groypInput.push("print")
        }
        if (group_input.view_input.includes(key)) {
            groypInput.push("view")
        }

        return groypInput
    }

    //function for Permision VMS
    checkUpdate = (keyName, checked, type, group_input, key) => {
        let data_inout = this.dataRetrun(group_input, key)
        if (type !== "") {
            let permsinion = _.set(_.cloneDeep(this.state.permissions), keyName, checked)
            this.setState({ permissions: permsinion }, () => {
                // เช็คว่าถ้าติ้กสิทธิ์ครบแล้วให้ติ้ก check all
                let checkAll = []
                data_inout.forEach((itemType, indx) => {
                    if (indx !== 0) {
                        checkAll.push(this.state.permissions[key + "_" + itemType])
                    }
                })
                let checkList = checkAll.filter((checkItem) => checkItem == true)

                if (checkList.length === data_inout.length - 1) {
                    let permsinions = _.set(_.cloneDeep(this.state.permissions), key, true)
                    this.setState({ permissions: permsinions })
                } else {
                    let permsinions = _.set(_.cloneDeep(this.state.permissions), key, false)
                    this.setState({ permissions: permsinions })
                }
            })

        } else {
            data_inout.length > 0 &&
                data_inout.forEach((types, type_index) => {
                    let dataUpdate = _.set(this.state.permissions, key + (type_index === 0 ? '' : '_') + types, checked)
                    this.setState({ permissions: dataUpdate });
                })
        }
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/setting/juristic-setting/permissions-juristic" />
        }

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t=>      
                            <form onSubmit={this.onSubmit}>
                                <div className="container-fluid box" id="permission">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/setting/juristic-setting/permissions-juristic/">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <Translation>
                                                    {
                                                        t => <span>{t('juristic:set_permission')}</span>
                                                    }
                                                </Translation>
                                                <div className="tooltipImg">
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                            alt="question" className="question ml-1 mr-1" style={{width:'12px'}}/>
                                                     <span className="tooltiptext">
                                                         กำหนดสิทธิ์ของตำแหน่งงานนี้ <br/>
                                                         เพื่อให้เข้าถึงได้เฉพาะสิทธิ์ที่กำหนดไว้เท่านั้น
                                                    </span>
                                                </div>
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="content-inner">
                                        {
                                            this.props.match.params.id ? '' :
                                                <>
                                                    <div className="row mt-4">
                                                        <div className="col">
                                                            <div style={{ display: 'flex' }}>
                                                                <label><h6 className='pr-2' style={{ lineHeight: '30px' }}>{t("set_permission:Add position")}
                                                                <div className="tooltipImg">
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                                                    alt="question" className="question ml-1 mr-1" style={{width:'12px'}}/>
                                                                    <span className="tooltiptext" style={{width:'500px'}}>
                                                                                ระบุชื่อตำแหน่งงานที่ต้องการกำหนดสิทธิ์,<br/>
                                                                                ในกรณีที่ไม่มีตำแหน่งงานที่ต้องการ สามารถเพิ่มตำแหน่งใหม่ได้<br/>
                                                                                กรุณาเลือก "อื่นๆ" แล้วพิมพ์ชื่อตำแหน่งใหม่ที่ต้องการเพิ่ม
                                                                    </span>
                                                                </div>
                                                                </h6></label>
                                                                <input type="text" name='positionName' className="form-control"
                                                                    style={{ width: '20%' }} onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                        <NavigationForjob id={this.props.match.params.id} />
                                        {this.props.match.params.tab === 'management' ?
                                            <PermissionManagementForJob management_list={management_list}
                                                permissions={this.state.permissions}
                                                updatePermissionCheck={this.updatePermissionCheck}
                                                clickCheckAllList={this.clickCheckAllList}
                                                checkAllInput={this.checkAllInput}
                                                manage_list_not_disable={manage_list_not_disable}
                                                checkUserJuristicAdmin={this.state.checkUserJuristicAdmin}
                                            />
                                            : this.props.match.params.tab === "accounting" ?
                                                <PermissionAccountingForJob account_list={account_list}
                                                    permissions={this.state.permissions}
                                                    updatePermissionCheck={this.updatePermissionCheck}
                                                    clickCheckAllList={this.clickCheckAllList}
                                                    checkAllInput={this.checkAllInput}
                                                    account_list_not_disable={account_list_not_disable}
                                                    updatePermissionLimit={this.updatePermissionLimit}
                                                    checkUserJuristicAdmin={this.state.checkUserJuristicAdmin}
                                                />
                                                :
                                                <PermisionVMS listPermision={silverman_list}
                                                    permissions={this.state.permissions}
                                                    checkUpdate={(keyName, checked, type, group_input, key) => this.checkUpdate(keyName, checked, type, group_input, key)}
                                                    checkUserJuristicAdmin={this.state.checkUserJuristicAdmin}
                                                />
                                        }

                                        <div className="row mt-4 fade-up text-right">
                                            <div className="col-6">
                                                <Translation>
                                                    {t =>
                                                        <button type="submit" className="btn btn-primary add" disabled={this.state.loading}>
                                                            {this.state.loading &&
                                                                <span
                                                                    className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                            {t("set_permission:Save")}
                                                        </button>
                                                    }
                                                </Translation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default PermissionForJob;
