import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import Wrapper from '../../../components/wrapper';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import ContractServiceCreateForm from "./contractServiceCreateForm";

import environment from "../../../env/environment";
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import Loading from '../../../libs/loading';

const contractServiceCreate = graphql`
    query contractServiceCreateQuery{
        contactViewer {
            id
            allContact(typeOfContact: "supplier", typeOfPayment: "receivable", isActive: true){
                edges{
                    node{
                        id
                        refNumber
                        name
                        firstName
                        lastName
                        residential {
                            id
                            conveyance
                        }
                    }
                }
            }
        }
        viewer {
            allChartOfAccount(chartOfAccountCode_Istartswith: "4200") {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
        contractRevenueDocNumber
    }
`;


class ContractServiceCreate extends Component {
    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <QueryRenderer
                        environment={environment}
                        query={contractServiceCreate}
                        cacheConfig={{ use_cache: false }}
                        render={({ error, props }) => {
                            if (props) {
                                return <ContractServiceCreateForm
                                    contractServiceQuery={props}
                                    headerWord={"contract_service:Contract Service Add"}
                                    backLink={"/accounting/income/contract_service/list/all"}
                                />;
                            } else {
                                return <Loading/>
                            }
                        }}
                    />
                </WrapperContent>
            </Wrapper>
        )

    }
}

export default ContractServiceCreate;