/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type indexLoginHookQueryVariables = {|
  username?: ?string,
  password?: ?string,
  role?: ?string,
|};
export type indexLoginHookQueryResponse = {|
  +auth: ?{|
    +token: ?string,
    +permission: ?string,
    +result: ?boolean,
    +haveVat: ?boolean,
    +url: ?string,
    +projectName: ?string,
    +tenant: ?string,
    +notification: ?string,
    +acceptPolicy: ?boolean,
    +acceptTermOfUse: ?boolean,
    +acceptMarketing: ?boolean,
    +acceptPartner: ?boolean,
    +message: ?string,
    +messageTh: ?string,
    +projectSelection: ?boolean,
  |},
  +selfProject: ?{|
    +id: string,
    +name: string,
    +logo: ?string,
    +address: ?string,
    +useAccount: boolean,
    +typeOfProject: ?ProjectTypeOfProject,
  |},
  +checkOnline: ?boolean,
  +checkSpecialSite: ?boolean,
  +allExtraMenu: ?any,
|};
export type indexLoginHookQuery = {|
  variables: indexLoginHookQueryVariables,
  response: indexLoginHookQueryResponse,
|};
*/


/*
query indexLoginHookQuery(
  $username: String
  $password: String
  $role: String
) {
  auth(username: $username, password: $password, role: $role) {
    token
    permission
    result
    haveVat
    url
    projectName
    tenant
    notification
    acceptPolicy
    acceptTermOfUse
    acceptMarketing
    acceptPartner
    message
    messageTh
    projectSelection
  }
  selfProject {
    id
    name
    logo
    address
    useAccount
    typeOfProject
  }
  checkOnline
  checkSpecialSite
  allExtraMenu
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "role"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "role",
        "variableName": "role"
      },
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "concreteType": "TokenObject",
    "kind": "LinkedField",
    "name": "auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permission",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "haveVat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tenant",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notification",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acceptPolicy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acceptTermOfUse",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acceptMarketing",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acceptPartner",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "messageTh",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectSelection",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "useAccount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfProject",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "checkOnline",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "checkSpecialSite",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allExtraMenu",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "indexLoginHookQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "indexLoginHookQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "7dd8b6f01c5120125fb49372a54eb75c",
    "id": null,
    "metadata": {},
    "name": "indexLoginHookQuery",
    "operationKind": "query",
    "text": "query indexLoginHookQuery(\n  $username: String\n  $password: String\n  $role: String\n) {\n  auth(username: $username, password: $password, role: $role) {\n    token\n    permission\n    result\n    haveVat\n    url\n    projectName\n    tenant\n    notification\n    acceptPolicy\n    acceptTermOfUse\n    acceptMarketing\n    acceptPartner\n    message\n    messageTh\n    projectSelection\n  }\n  selfProject {\n    id\n    name\n    logo\n    address\n    useAccount\n    typeOfProject\n  }\n  checkOnline\n  checkSpecialSite\n  allExtraMenu\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8ce64a6f64948d66cb03b8a15b12aaa6';

module.exports = node;
