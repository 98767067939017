import React, { Component } from "react";
import getApiRoot from "../../libs/getAPIRoot";
import Helpers from '../../libs/file_download_helper';
import {format} from "date-fns";
import i18next from "i18next";

class ExportDailyJournal extends Component {

    constructor(props) {
        super(props);
        this.state = {loading: false};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (event) => {

        let path = ""
        let status = this.props.status === 'all'? "":this.props.status;
        if(this.props.start_date && this.props.end_date){
            path = 'export/daily_journal?start_date=' + format(this.props.start_date, 'DD/MM/YYYY HH:mm:ss')
            + '&end_date=' + format(this.props.end_date, 'DD/MM/YYYY HH:mm:ss')
            + '&category=' + this.props.category
            + '&search=' + this.props.search
            + '&order=' + this.props.order
            + '&status=' + status 
        }else{
            path = 'export/daily_journal?start_date=null'
            + '&end_date=null'
            + '&category=' + this.props.category
            + '&search=' + this.props.search
            + '&order=' + this.props.order
            + '&status=' + status    
        }

        this.setState({
            errors: null,
            loading: true,
        }, () => {
            Helpers.httpRequest(
                 getApiRoot() + path,
                'GET',
            )
                .then((response) => response.blob())
                .then((blob) => {
                    // create blob link
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "dailyjournal-"+this.props.category+"-"+format(this.props.start_date, 'DD/MM/YYYY')+"-to-"+format(this.props.end_date, 'DD/MM/YYYY')+".xls");

                    // append to html
                    document.body.appendChild(link);

                    // download
                    link.click();

                    // remove
                    link.parentNode.removeChild(link);

                    this.setState({
                        loading: false
                    });
                })
                .catch((error) => {
                    error.json().then((json) => {
                        this.setState({
                            errors: json,
                            loading: false
                        });
                    })
                });
        });

        event.preventDefault();
    };

    render() {

        return (
            <div className = "col">
                <form onSubmit = {this.handleSubmit}>
                    <button type="submit" className="btn print"
                            disabled={this.state.loading}>
                        {this.state.loading ?
                            <span>
                            <span
                                className="spinner-border spinner-border-sm align-middle mr-2"/> {i18next.t("Allaction:Preparing information")}</span> :
                            <span>
                            <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon"/>
                                {i18next.t("dailyjournalList:Print")}
                            </span>
                        }
                    </button>
                </form>
            </div>
        )
    }

}
export default ExportDailyJournal;