import React from 'react';
import { graphql } from "babel-plugin-relay/macro";
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from 'i18next';
import Navigation from "./navigation";
import { commitMutation, QueryRenderer } from "react-relay";
import environment from "../../env/environment";
import Pagination from "../../libs/newPagination";
import ComponentPagination from "../../libs/componentPagination";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import _ from "lodash";
import Swal from "sweetalert2";
import Loading from "../../libs/loading"
import BackButtonIcon from '../../components/BackBtn/indexBack';
import SeacrhDetail from '../../components/search/indexSearch';

const allContact = graphql`
    query contactListQuery($typeOfContact:String, $typeOfPayment: String, $first: Int, $last: Int, $search: String) {
        contactViewer {
            id
            allContact(typeOfContact: $typeOfContact, typeOfPayment: $typeOfPayment, first: $first, last: $last, search:$search, order: "ref_number") {
                totalCount
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                edges {
                    node {
                        id
                        refNumber
                        name
                        taxIdNumber
                        mainEmail
                        email
                        typeOfPayment
                        typeOfContact
                        typeOfPeople
                        typeOfSupplier
                        totalAmount
                        nameBranch
                        residential {
                            id
                            name
                        }
                        prefixName
                        firstName
                        lastName
                        mainPhone
                        phone
                        isActive
                    }
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation contactListMutation($input : ChangeContactStatusInput!){
        changeContactStatus(input: $input){
            ok
        }
    }

`;


class ContactList extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state.search = '';
        this.state.reQuery = false;
        this.state.printData = [
            { text: "residential:Owner Resident", type: "owner", link: `/document/contact/all/RESIDENTIAL/TENANT` },
            { text: "PageListHistoryResident:Rental", type: "owner", link: `/document/contact/all/RESIDENTIAL/RENT` },
            { text: "PageListHistoryResident:Agent", type: "owner", link: `/document/contact/all/RESIDENTIAL/AGENT` },
            { text: "accounting:receivable_setup", type: "debtor", link: `/document/contact/all/SUPPLIER/RECEIVABLE` },
            { text: "accounting:payable_setup", type: "creditor", link: `/document/contact/all/SUPPLIER/PAYABLE` },
        ]
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.changeActiveStatus = this.changeActiveStatus.bind(this);
        this.checkPermission = this.checkPermission.bind(this);
    }

    componentDidUpdate() {
        if (this.state.typeOfPayment !== this.props.typeOfPayment) {
            this.setState({
                typeOfPayment: this.props.typeOfPayment
            }, () => {
                this.goFirst();
            })
        }
    }

    onChangeSearch(text) {
        let search = ""
        text.forEach((e, index) => {
            if (e.name === "search") {
                search = e.value
            }
        });
        this.setState({
            search: search
        })
        // this.setState({ search: text });
    }

    changeActiveStatus(id_list, status) {
        let display_text = "";
        if (status) {
            display_text = i18n.t("contact_list:Are you sure to enable?");
        } else {
            display_text = i18n.t("contact_list:Are you sure to disable?");
        }
        Swal.fire({
            title: display_text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t("contact_list:Yes"),
            cancelButtonText: i18n.t("contact_list:No")
        }).then((result) => {
            if (result.value) {
                let variables = {
                    input: {
                        idList: id_list,
                        status: status
                    }
                };
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            this.setState({ loading: false });
                            if (response.changeContactStatus.ok) {
                                Swal.fire(i18n.t("contact_list:Successfully!"), '', 'success').then(() => {
                                    this.setState({ reQuery: !this.state.reQuery, checkList: [], check_all: false });
                                });
                            } else {
                                Swal.fire(i18n.t("contact_list:Unsuccessfully!"), '', 'warning');
                            }
                        },
                        onError: (err) => {
                            this.setState({ loading: false });
                            Swal.fire('Error!', i18n.t("contact_list:Please try again"), 'warning')
                        },
                    },
                );
            }
        });
    }

    checkPermission(typeOfContact, typeOfPayment) {
        let permission_list = JSON.parse(localStorage.getItem("permission_list"));
        if (typeOfContact === 'all') {
            return false
        }
        if (typeOfContact === 'residential') {
            return _.some(permission_list, { codename: 'contact_residential_edit' })
        } else if (typeOfPayment === 'receivable') {
            return _.some(permission_list, { codename: 'contact_receivable_edit' })
        } else {
            return _.some(permission_list, { codename: 'contact_payable_edit' })
        }

    }

    sortOrderAsc = (data) => {
        const dataSort = data?.sort(function (a, b) {
            return a.node.name.localeCompare(b.node.name, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        })
        return dataSort

    }
    render() {
        let printData = this.props.match.params.typeOfContact === 'residential' ?
            this.state.printData.filter((item) => item.type === 'owner') :
            this.props.match.params.typeOfContact === 'supplier' && this.props.match.params.typeOfPayment === 'receivable' ?
                this.state.printData.filter((item) => item.type === 'debtor') :
                this.props.match.params.typeOfContact === 'supplier' && this.props.match.params.typeOfPayment === 'payable' ?
                    this.state.printData.filter((item) => item.type === 'creditor') :
                    this.state.printData

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="contact-list">
                                {/* Link Header and Back Button */}
                                <BackButtonIcon
                                    LinkBack={"/accounting"}
                                    // LinkBack={this.props.history.goBack()}
                                    LinkText={t('accountContract:contact_list')}
                                    addLinkChk={
                                        (this.props.match.params.typeOfContact !== 'residential' &&
                                            this.props.match.params.typeOfPayment === 'receivable' &&
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_receivable_create' })) ||
                                            (this.props.match.params.typeOfContact !== 'residential' &&
                                                this.props.match.params.typeOfPayment === 'payable' &&
                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_payable_create' }))
                                            ? true : false
                                    }
                                    addLinkButton={
                                        this.props.match.params.typeOfContact !== 'residential' &&
                                            this.props.match.params.typeOfPayment === 'receivable' &&
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_receivable_create' }) ?
                                            "/accounting/contact/form/" + this.props.match.params.typeOfContact + "/receivable" :
                                            "/accounting/contact/form/" + this.props.match.params.typeOfContact + "/payable"
                                    }
                                    addLinkText={
                                        this.props.match.params.typeOfContact !== 'residential' &&
                                            this.props.match.params.typeOfPayment === 'receivable' &&
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_receivable_create' }) ?
                                            t('accountContract:add receivable') :
                                            t('accountContract:add payable')
                                    }
                                />

                                {/* Filter and Search and Print */}
                                <SeacrhDetail
                                    searchBoxId={true}
                                    search={this.state.search}
                                    handlePropsChange={this.onChangeSearch}
                                    selectDropDown={true}
                                    selectDropDownText={t("contact_list:Manage multiple")}

                                    IsPrint={true}
                                    printLink={"/document/contact/all/all/all"}
                                    printText={t("printAllContact:Print All Contact")}
                                    changeActiveStatus={this.changeActiveStatus}
                                    printList={this.state.checkList}
                                />

                                <div className="content-inner ml-0">
                                    <Navigation />
                                    <div className="row">
                                        <div className="col">
                                            <QueryRenderer
                                                environment={environment}
                                                query={allContact}
                                                cacheConfig={{ use_cache: false }}
                                                variables={{
                                                    first: this.state.first,
                                                    last: this.state.last,
                                                    search: this.state.search,
                                                    typeOfPayment: this.props.match.params.typeOfPayment,
                                                    typeOfContact: this.props.match.params.typeOfContact,
                                                    reQuery: this.state.reQuery
                                                }}

                                                render={({ error, props }) => {
                                                    if (error) {
                                                        return <div className="alert alert-danger"
                                                            role="alert">{error.message}</div>;
                                                    } else if (props) {
                                                        let sortedResidentialEdges = []
                                                        if (this.props.match.params.typeOfContact.toLowerCase() === "residential" && localStorage.getItem("site_id") === "636") {
                                                            const copyOfResidentialEdges = [...props.contactViewer.allContact.edges];
                                                            sortedResidentialEdges = this.sortOrderAsc(copyOfResidentialEdges)
                                                        } else {
                                                            sortedResidentialEdges = [...props.contactViewer.allContact.edges]
                                                        }

                                                        return sortedResidentialEdges?.length > 0 ?
                                                         <React.Fragment>
                                                            <div className="table-responsive fade-up card">
                                                                <table className="table table-hover">
                                                                    <thead className="thead-light">
                                                                        <tr onClick={() => this.getAllList(sortedResidentialEdges)}>
                                                                            <th>
                                                                                <input type="checkbox"
                                                                                    onChange={() => this.getAllList(sortedResidentialEdges)}
                                                                                    checked={this.state.check_all} />
                                                                            </th>
                                                                            <th>{t("contact_list:Code")}</th>
                                                                            {this.props.match.params.typeOfContact === 'residential' &&
                                                                                <th>{t("contact_list:Room number")}</th>
                                                                            }
                                                                            <th>{t("contact_list:Name")}</th>
                                                                            <th>{t("contact_list:Contact number")}</th>
                                                                            <th>{t("contact_list:Email")}</th>
                                                                            <th style={{ width: 100 }} className="text-center">{t("contact_list:Status")}</th>
                                                                            {/*{this.props.typeOfPayment !== 'receivable' ?*/}
                                                                            {/*    <th className="text-right">รอจ่ายเงิน</th>*/}
                                                                            {/*    :*/}
                                                                            {/*    <th/>*/}
                                                                            {/*}*/}
                                                                            {/*{this.props.typeOfPayment !== 'payable' ?*/}
                                                                            {/*    <th className="text-right">รอรับเงิน</th>*/}
                                                                            {/*    :*/}
                                                                            {/*    <th/>*/}
                                                                            {/*}*/}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            sortedResidentialEdges?.map((contact) => {
                                                                                let first_name = _.get(contact, "node.firstName", "").split(',');
                                                                                let last_name = _.get(contact, "node.lastName", "").split(',');
                                                                                let email = [];
                                                                                if (first_name.length === 1) {
                                                                                    if (first_name[0] === "") {
                                                                                        first_name = [contact.node.name]
                                                                                    }
                                                                                }
                                                                                if (contact.node.email) {
                                                                                    email = contact.node.email.split(',');
                                                                                }
                                                                                let phone = contact.node.phone.split(',');
                                                                                let prename = contact.node.prefixName !== null ? t("preFixName:" + contact.node.prefixName) : ""

                                                                                return (
                                                                                    <tr key={contact.node.id} onClick={() => this.appendToCheckListNoProps(contact.node.id)}>
                                                                                        <td>
                                                                                            <input type="checkbox"
                                                                                                onChange={() => this.appendToCheckListNoProps(contact.node.id)}
                                                                                                checked={this.checkList(contact.node.id)}

                                                                                                disabled={contact.node.taxIdNumber === '0105561063013'}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Link to={"/accounting/contact/form/" + this.props.match.params.typeOfContact + "/" + contact.node.typeOfPayment.toLowerCase() + "/" + contact.node.id}
                                                                                            >{contact.node.refNumber}
                                                                                            </Link>
                                                                                        </td>
                                                                                        {this.props.match.params.typeOfContact === 'residential' &&
                                                                                            <td>{contact.node.residential.name}</td>
                                                                                        }
                                                                                        <td>
                                                                                            {

                                                                                                (
                                                                                                    (contact.node.typeOfSupplier === "ORDINARY_PARTNERSHIP" ||
                                                                                                        contact.node.typeOfSupplier === "PERSON" ||
                                                                                                        contact.node.typeOfSupplier === "GROUP_OF_PERSON" ||
                                                                                                        contact.node.typeOfSupplier === "MERCHANT" ||
                                                                                                        contact.node.typeOfSupplier === "OTHER" ||
                                                                                                        contact.node.typeOfPeople === "JURISTIC_PEOPLE") &&
                                                                                                    contact.node.name !== "" && !prename
                                                                                                ) ? <span>{contact.node.name} {contact.node.nameBranch ? <span className='text-red'>({contact.node.nameBranch}) AA</span> : ""}</span>
                                                                                                    :
                                                                                                    contact.node.name === "" ?
                                                                                                        '-'
                                                                                                        :
                                                                                                        <React.Fragment>
                                                                                                            {
                                                                                                                first_name.map((first, index) => {
                                                                                                                    let name = first
                                                                                                                    if (first.includes("นางสาว") && first.indexOf("นางสาว") === 0) {
                                                                                                                        name = first.replace("นางสาว", '').trim();
                                                                                                                    } else if (first.includes("นาย") && first.indexOf("นาย") === 0) {
                                                                                                                        name = first.replace("นาย", '').trim();
                                                                                                                    } else if (first.includes("นาง") && first.indexOf("นาง") === 0) {
                                                                                                                        name = first.replace("นาง", '').trim();
                                                                                                                    }
                                                                                                                    
                                                                                                                    // if (first?.includes("นาง") || first?.includes("นางสาว") || first?.includes("นาย")) {
                                                                                                                    //     name = first?.includes("นางสาว") ? first?.replace("นางสาว", '') :
                                                                                                                    //         first?.includes("นาย") ? first?.replace("นาย", '') : first?.replace("นาง", '')
                                                                                                                    // }
                                                                                                                    return (
                                                                                                                        <p className="p-margin" key={index}>{prename}{name} {last_name[index]}</p>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </React.Fragment>
                                                                                            }
                                                                                        </td>

                                                                                        <td>
                                                                                            {
                                                                                                this.props.match.params.typeOfContact === 'residential' ||
                                                                                                    (this.props.match.params.typeOfContact === 'all' && contact.node.typeOfContact === 'RESIDENTIAL') ?
                                                                                                    <React.Fragment>
                                                                                                        {
                                                                                                            phone.map((phone, index) => {
                                                                                                                return (
                                                                                                                    <p className="p-margin" key={index}>{phone}</p>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </React.Fragment> : contact.node.mainPhone || '-'
                                                                                            }
                                                                                        </td>

                                                                                        <td>
                                                                                            {
                                                                                                this.props.match.params.typeOfContact === 'residential' ||
                                                                                                    (this.props.match.params.typeOfContact === 'all' && contact.node.typeOfContact === 'RESIDENTIAL') ?
                                                                                                    <React.Fragment>
                                                                                                        {
                                                                                                            email.map((email, index) => {
                                                                                                                return (
                                                                                                                    <p className="p-margin" key={index}>{email}</p>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </React.Fragment> : contact.node.mainEmail || '-'
                                                                                            }
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            {contact.node.isActive ? '✓' : '✕'}
                                                                                        </td>

                                                                                        {/*{contact.node.typeOfPayment !== 'RECEIVABLE' ?*/}
                                                                                        {/*    <td className="text-right">*/}
                                                                                        {/*        {contact.node.typeOfPayment === 'PAYABLE' ? numberWithComma(contact.node.totalAmount, '0.00') : '-'}*/}
                                                                                        {/*    </td> :*/}
                                                                                        {/*    <td className="text-right">0.00</td>*/}
                                                                                        {/*}*/}
                                                                                        {/*{contact.node.typeOfPayment !== 'PAYABLE' ?*/}
                                                                                        {/*    <td className="text-right">*/}
                                                                                        {/*        {contact.node.typeOfPayment === 'RECEIVABLE' ? numberWithComma(contact.node.totalAmount, '0.00') : '-'}*/}
                                                                                        {/*    </td> :*/}
                                                                                        {/*    <td className="text-right">0.00</td>*/}
                                                                                        {/*}*/}
                                                                                    </tr>
                                                                                )


                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div> 
                                                            
                                                            <div className="row">
                                                                <Pagination changePage={this.changePage}
                                                                    first={this.state.first}
                                                                    last={this.state.last}
                                                                    totalCount={props.contactViewer.allContact.totalCount} />
                                                            </div>
                                                        </React.Fragment> :
                                                              <div className="table-responsive fade-up card">
                                                              <table className="table table-hover">
                                                                  <thead className="thead-light">
                                                                      <tr onClick={() => this.getAllList(sortedResidentialEdges)}>
                                                                          <th>
                                                                              <input type="checkbox"
                                                                                 disabled/>
                                                                          </th>
                                                                          <th>{t("contact_list:Code")}</th>
                                                                          {this.props.match.params.typeOfContact === 'residential' &&
                                                                              <th>{t("contact_list:Room number")}</th>
                                                                          }
                                                                          <th>{t("contact_list:Name")}</th>
                                                                          <th>{t("contact_list:Contact number")}</th>
                                                                          <th>{t("contact_list:Email")}</th>
                                                                          <th style={{ width: 100 }} className="text-center">{t("contact_list:Status")}</th>
                                                                          
                                                                      </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                    <td colSpan="10" className='text-center'>ไม่พบข้อมูล</td>
                                                                  </tbody>
                                                              </table>
                                                          </div> 
                                                    }
                                                    return <Loading />;
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </Translation>
                </WrapperContent>

            </Wrapper>

        )
    }
}

export default ContactList;
