import '../../report.scss';
import React from 'react';
import _ from "lodash";
import { format, lastDayOfMonth } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithCommas from "../../../../libs/numberWithComma";
import { H6, B2 } from '../../../../components/Text/Text'
import jwtDecode from 'jwt-decode'
import './bankDeposit.scss';
import i18next from 'i18next';
import { Translation } from 'react-i18next';



const summaryAccountsReceivableAndPayableTable = ({ summary_report_residential, summary_report_supplier, summary_bank_report, loading, date, handleSummary, report, lastPageNumber, reportType }) => {
  const jwtDecodes = jwtDecode(window.localStorage.getItem('token'));
  const printBy = jwtDecodes.first_name + " " + jwtDecodes.last_name;
  let totalPages = 0;
  for (let i = 0; i < lastPageNumber; i++) {
    totalPages += 1;
  }
  return (
    <React.Fragment>
      {!loading ?
        <Translation>
          {t =>
            <React.Fragment>
              <H6 className="text-left mt-5" fontSize={report ? '12px' : ''} >{i18next.t("summaryAccountsReceivableAndPayableTable:Summary of Receivables and Payables as of")} {format(lastDayOfMonth(date), " DD MMMM YYYY ", { locale: thLocale, })}</H6>
              <div className="fade-up mt-5" id='bank-deposit-report-sub'  >
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th className="text-left"><H6 fontSize={report ? '12px' : ''}>{i18next.t("summaryAccountsReceivableAndPayableTable:Debt Type")}</H6></th>
                        <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("summaryAccountsReceivableAndPayableTable:Number of Accounts")}</H6></th>
                        <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("summaryAccountsReceivableAndPayableTable:Amount")}</H6></th>
                        <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("summaryAccountsReceivableAndPayableTable:Percentage of Remaining Amount")}</H6></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-left"><B2>{i18next.t("summaryAccountsReceivableAndPayableTable:Co-owner/Member")}</B2></td>
                        <td className="text-center" style={{ width: 130 }} ><B2 fontSize={report ? '12px' : ''}>{_.get(summary_report_residential, "numRow")}</B2></td>
                        <td className="text-center td-red" style={{ width: 130 }} ><B2 fontSize={report ? '12px' : ''}>{numberWithCommas(_.get(summary_report_residential, "sumBalance"))}</B2></td>
                        <td className="text-center " style={{ width: 130 }} ><B2>{numberWithCommas(handleSummary(_.get(summary_report_residential, "sumBalance")))}</B2></td>
                      </tr>
                      <tr>
                        <td className="text-left"><B2>{i18next.t("summaryAccountsReceivableAndPayableTable:Receivables")}</B2></td>
                        <td className="text-center" style={{ width: 130 }} ><B2 fontSize={report ? '12px' : ''}>{_.get(summary_report_supplier, "numRow")}</B2></td>
                        <td className="text-center td-red" style={{ width: 130 }} ><B2 fontSize={report ? '12px' : ''}>{numberWithCommas(_.get(summary_report_supplier, "sumBalance"))}</B2></td>
                        <td className="text-center " style={{ width: 130 }} ><B2 fontSize={report ? '12px' : ''}>{numberWithCommas(handleSummary(_.get(summary_report_supplier, "sumBalance")))}</B2></td>
                      </tr>
                      <tr className='td-sum' >
                        <td colSpan={2} className="text-right" ><H6 fontSize={report ? '12px' : ''}>{i18next.t("summaryAccountsReceivableAndPayableTable:Total Amount")}</H6></td>
                        <td className="text-center" ><H6 fontSize={report ? '12px' : ''}>{numberWithCommas(_.get(summary_report_supplier, "sumBalance") + _.get(summary_report_residential, "sumBalance"))}</H6></td>
                        <td className="text-center" ><H6 fontSize={report ? '12px' : ''}>{numberWithCommas(numberWithCommas(handleSummary(_.get(summary_report_residential, "sumBalance")) + handleSummary(_.get(summary_report_supplier, "sumBalance"))))}</H6></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="fade-up mt-5" id='bank-deposit-report-sub' >
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead className="thead-light" >
                      <tr>
                        <th className="text-left" ><H6 fontSize={report ? '12px' : ''}>{i18next.t("summaryAccountsReceivableAndPayableTable:Creditor Type")}</H6></th>
                        <th className="text-center" ><H6 fontSize={report ? '12px' : ''}>{i18next.t("summaryAccountsReceivableAndPayableTable:Number")}</H6></th>
                        <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("summaryAccountsReceivableAndPayableTable:Amount")}</H6></th>
                        <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("summaryAccountsReceivableAndPayableTable:Percentage of Remaining Amount")}</H6></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-left"><B2 fontSize={report ? '12px' : ''}>{i18next.t("summaryAccountsReceivableAndPayableTable:Payables")}</B2></td>
                        <td className="text-center" style={{ width: 130 }} ><B2 fontSize={report ? '12px' : ''}>{_.get(summary_bank_report, "numRow")}</B2></td>
                        <td className="text-center td-green" style={{ width: 130 }} ><B2 fontSize={report ? '12px' : ''}>{numberWithCommas(_.get(summary_bank_report, "sumBalance"))}</B2></td>
                        <td className="text-center" style={{ width: 130 }} ><B2 fontSize={report ? '12px' : ''}>{numberWithCommas(handleSummary(_.get(summary_bank_report, "sumBalance")))}</B2></td>
                      </tr>
                      <tr className='td-sum' >
                        <td colSpan={2} className="text-right" ><H6 fontSize={report ? '12px' : ''}>{i18next.t("summaryAccountsReceivableAndPayableTable:Total Amount")}</H6></td>
                        <td className="text-center" ><H6 fontSize={report ? '12px' : ''}>{numberWithCommas(_.get(summary_bank_report, "sumBalance"))}</H6></td>
                        <td className="text-center" ><H6 fontSize={report ? '12px' : ''}>{numberWithCommas(handleSummary(_.get(summary_bank_report, "sumBalance")))}</H6></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {reportType === 'pdf' && <div className='footer spaceTopLastPage'>
                <div className='row'>
                  <div className='col-5' />
                  <div className='col-2 text-center'>
                    <p className='f14 pb-0 mb-0'>{totalPages}  /  {totalPages}</p>
                  </div>
                  <div className='col-5 text-right'>
                    <p className='f14 pb-0 mb-0'>{i18next.t("summaryAccountsReceivableAndPayableTable:Print By")} {printBy} {i18next.t("summaryAccountsReceivableAndPayableTable:Print Date")} {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}</p>
                  </div>
                </div>
              </div>
              }

            </React.Fragment>
          }
        </Translation>

        : <div />}
    </React.Fragment>
  );
};

export default summaryAccountsReceivableAndPayableTable;