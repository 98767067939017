import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../../../env/environment";
const $ = window.jQuery;


const query = graphql`
    query otherExpenseCarParkSelectQuery($contact: [String], $car_type: String)  {
       allCars(contactList: $contact, chargedFee: "true",carType: $car_type){
           edges{
               node{
                   id
                   licencePlate
               }
           }
       }
    }
`;

class OtherExpenseCarParkSelect extends React.Component {

     constructor(props) {
        super(props);
        this.state = {
            type_fee:'',
        };
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.setState({type_fee: this.props.type_fee});
        $(this.modal).modal('show');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.type_fee !== prevState.type_fee) {
            this.setState({type_fee: prevProps.type_fee});
            $(this.modal).modal('show');
        }
    }

    submit() {
        let variables = {
            "contact": this.props.contact[0],
            "car_type" : this.props.type_fee === 'CAR_PARK' ? 'car': 'motorcycle'
        };
        fetchQuery(environment, query, variables)
            .then(data => {
                this.props.setCarParkTransaction(this.props.transaction_index, data);
                 $(this.modal).modal('hide');
            });
    }

    render() {
        return (
            <React.Fragment>
                <div
                    ref={modal => this.modal = modal} data-backdrop="static" data-keyboard="false"
                    className="modal fade" id="period-settings" tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">ดึงข้อมูลค่าที่จอดรถ</h5>
                            </div>
                            <div className="modal-body">
                                <h6>*ห้องที่ไม่มีการคิดค่าจอดรถจะมีรายการเป็น 0 และห้องอื่นๆที่มีจะทำการสร้างให้อัตโนมัติ*</h6>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={this.submit}>ตกลง
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default OtherExpenseCarParkSelect;
