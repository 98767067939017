/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingCollectionLetterLawQueryVariables = {||};
export type settingCollectionLetterLawQueryResponse = {|
  +allRegisterCollectionLawFirmSite: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +site: {|
          +id: string,
          +project: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +nameTh: ?string,
              |}
            |}>
          |},
        |}
      |}
    |}>
  |}
|};
export type settingCollectionLetterLawQuery = {|
  variables: settingCollectionLetterLawQueryVariables,
  response: settingCollectionLetterLawQueryResponse,
|};
*/


/*
query settingCollectionLetterLawQuery {
  allRegisterCollectionLawFirmSite {
    edges {
      node {
        site {
          id
          project {
            edges {
              node {
                id
                nameTh
              }
            }
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "SiteType",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectNodeConnection",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameTh",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingCollectionLetterLawQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RegisterCollectionLawFirmNodeConnection",
        "kind": "LinkedField",
        "name": "allRegisterCollectionLawFirmSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegisterCollectionLawFirmNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RegisterCollectionLawFirmNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingCollectionLetterLawQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RegisterCollectionLawFirmNodeConnection",
        "kind": "LinkedField",
        "name": "allRegisterCollectionLawFirmSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegisterCollectionLawFirmNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RegisterCollectionLawFirmNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0203196cd8a4bdc708b505dae4614f42",
    "id": null,
    "metadata": {},
    "name": "settingCollectionLetterLawQuery",
    "operationKind": "query",
    "text": "query settingCollectionLetterLawQuery {\n  allRegisterCollectionLawFirmSite {\n    edges {\n      node {\n        site {\n          id\n          project {\n            edges {\n              node {\n                id\n                nameTh\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5dd5e08fa48c0addd993db1ab67d9597';

module.exports = node;
