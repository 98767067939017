/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SettingProjectHireJuristic = "ALL" | "NO" | "UNIDENTIFIED" | "%future added value";
export type settingEnterpriseInformationQueryVariables = {||};
export type settingEnterpriseInformationQueryResponse = {|
  +allSettingProject: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +hireJuristic: SettingProjectHireJuristic,
        +hireJuristicDate: any,
        +creator: ?string,
        +editor: ?string,
      |}
    |}>
  |}
|};
export type settingEnterpriseInformationQuery = {|
  variables: settingEnterpriseInformationQueryVariables,
  response: settingEnterpriseInformationQueryResponse,
|};
*/


/*
query settingEnterpriseInformationQuery {
  allSettingProject {
    edges {
      node {
        id
        hireJuristic
        hireJuristicDate
        creator
        editor
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SettingProjectNodeConnection",
    "kind": "LinkedField",
    "name": "allSettingProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingProjectNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingProjectNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hireJuristic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hireJuristicDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingEnterpriseInformationQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingEnterpriseInformationQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8b6a1b68a81973339d217b64eeaa9969",
    "id": null,
    "metadata": {},
    "name": "settingEnterpriseInformationQuery",
    "operationKind": "query",
    "text": "query settingEnterpriseInformationQuery {\n  allSettingProject {\n    edges {\n      node {\n        id\n        hireJuristic\n        hireJuristicDate\n        creator\n        editor\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa1c10c12bfa1ede4a0360813ff7d587';

module.exports = node;
