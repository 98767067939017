import { format } from "date-fns";

export function formatDateISO(date) {
  return format(date, "YYYY-MM-DD");
}

export function formatDateISOThai(date) {
  return format(date, "DD/MM/YYYY");
}

export function convertToTimeZone(date, timeZone) {
  return new Date(date).toLocaleString("en-US", { timeZone: timeZone });
}
