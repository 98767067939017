import React, { Component } from 'react'
import _ from "lodash"
import { format } from 'date-fns';
import numberWithComma from "../../../../libs/numberWithComma";

export default class budgetLastYearCompareWithYear extends Component {

  summaryRevenueYear = (codeValue) => {
    let result = 0;
    let sumRevenue_summaryYear_total_budget = 0;
    let sumRevenue_total_happend = 0;
    let sumRevenue_total_budget = 0;

    sumRevenue_summaryYear_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
    sumRevenue_total_happend = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
    sumRevenue_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.total_budget || 0))

    switch (codeValue) {
      case "4000-00 summaryYear_total_budget":
        result = sumRevenue_summaryYear_total_budget
        break;
      case "4000-00 total_happend":
        result = sumRevenue_total_happend
        break;
      case "4000-00 total_budget":
        result = sumRevenue_total_budget
        break;
    }
    return result;
  }

  summaryExpenseYear = (codeValue) => {
    let result = 0;
    let sumExpense_otherExpenses_summaryYear_total_budget = 0;
    let sumExpense_otherExpenses_total_happend = 0;
    let sumExpense_otherExpenses_total_budget = 0;

    let sumExpense_items_summaryYear_total_budget = 0;
    let sumExpense_items_total_happend = 0;
    let sumExpense_items_total_budget = 0;

    if (codeValue.substring(0, 7) === "5800-00") {
      _.forIn(this.props.state.lists[1].children[8].children, function (item) {
        sumExpense_otherExpenses_summaryYear_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_budget || 0))
        sumExpense_otherExpenses_total_happend += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_happend || 0))
        sumExpense_otherExpenses_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.total_budget || 0))
      })
    } else {
      if (codeValue.substring(0, 7) === "5100-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5200-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5300-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5310-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5400-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5500-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5600-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.total_budget || 0))
      }
      if (codeValue.substring(0, 7) === "5700-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.total_budget || 0))
      }

    }

    switch (codeValue) {
      case "5800-00 summaryYear_total_budget":
        result = sumExpense_otherExpenses_summaryYear_total_budget
        break;
      case "5800-00 total_happend":
        result = sumExpense_otherExpenses_total_happend
        break;
      case "5800-00 total_budget":
        result = sumExpense_otherExpenses_total_budget
        break;
      case `${codeValue.substring(0, 7)} summaryYear_total_budget`:
        result = sumExpense_items_summaryYear_total_budget
        break;
      case `${codeValue.substring(0, 7)} total_happend`:
        result = sumExpense_items_total_happend
        break;
      case `${codeValue.substring(0, 7)} total_budget`:
        result = sumExpense_items_total_budget
        break;
    }
    return result;
  }

  checkMonth(monthInput) {
    let resultMonth = ""
    let monthFormat = format(new Date(monthInput), "MMM")
    let yearFormat = format(new Date(monthInput), "YY")

    switch (monthFormat) {
      case "Jan":
        resultMonth = "ม.ค."
        break;
      case "Feb":
        resultMonth = "ก.พ."
        break;
      case "Mar":
        resultMonth = "มี.ค."
        break;
      case "Apr":
        resultMonth = "เม.ย."
        break;
      case "May":
        resultMonth = "พ.ค."
        break;
      case "Jun":
        resultMonth = "มิ.ย."
        break;
      case "Jul":
        resultMonth = "ก.ค."
        break;
      case "Aug":
        resultMonth = "ส.ค."
        break;
      case "Sep":
        resultMonth = "ก.ย."
        break;
      case "Oct":
        resultMonth = "ต.ค."
        break;
      case "Nov":
        resultMonth = "พ.ย."
        break;
      case "Dec":
        resultMonth = "ธ.ค."
        break;
    }
    return resultMonth + yearFormat
  }

  render() {
    let last_index = 0;
    let last_index_child = 0;

    let sumExpense_summaryYear_total_budget = 0;
    let sumExpense_total_happend = 0;
    let sumExpense_total_budget = 0;

    return (
      <div className="fade-up" id="parcel" style={{width: "90%"}}>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th
                  rowSpan={2}
                  className="text-center"
                >
                  ลำดับ
                </th>
                <th
                  rowSpan={2}
                  className="text-center"
                  style={{ minWidth: 300 }}
                >
                  รายการ
                </th>
                <th
                  colSpan={2}
                  className="text-center"
                >
                  งบประมาณปี {this.props.state.temp_year.getFullYear() - 1}
                </th>
                <th rowSpan={2} className="text-center">
                  งบประมาณปี {format(this.props.state.temp_year, "YYYY")}<br />
                  {this.checkMonth(format(this.props.state.temp_start_date, "YYYY-MM-DD"))}-{this.checkMonth(format(this.props.state.temp_end_date, "YYYY-MM-DD"))}
                </th>
              </tr>
              <tr className="border-top">
                <th className="text-center">งบประมาณ</th>
                <th className="text-center">เกิดจริง</th>
              </tr>
            </thead>
            <tbody>
              {_.map(this.props.state.lists[0]?.children, (item, index) => {
                ++last_index;
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="text-center" width={105}>{last_index}</td>
                      <td
                        style={{ paddingLeft: 50, minWidth: 420 }}
                        width={450}
                      >
                        {index == 0 && (
                          <div style={{ marginLeft: -32 }}>
                            รายรับ
                          </div>
                        )}
                        {item.data.name}
                      </td>
                      <td
                        width={220}
                        height={24}
                      >
                        <input
                          disabled={item.data?.summaryYear?.total_budget_check || this.props.disabledApprove()}
                          type="number"
                          className="form-control text-center "
                          style={{ maxHeight: 24 }}
                          value={
                            item.data?.summaryYear?.total_budget || ""
                          }
                          onChange={this.props.handleChangeInput}
                          name={`lists[0].children[${index}].data.summaryYear.total_budget`}
                        />
                      </td>
                      <td
                        width={220}
                        height={24}
                        className="td-red-scale"
                      >
                        <input
                          disabled
                          type="number"
                          className="form-control text-center td-red-scale"
                          style={{ maxHeight: 24 }}
                          value={
                            item.data?.summaryYear?.total_happend 
                              ? parseFloat(item.data?.summaryYear?.total_happend).toFixed(2) : ""
                          }
                        />
                      </td>

                      <td width={220}>
                        <input
                          disabled={this.props.disabledApprove()}
                          type="number"
                          className="form-control text-center"
                          style={{ maxHeight: 24 }}
                          value={
                            item.data?.total_budget || ""
                          }
                          onChange={(e) =>
                            this.props.handleChangeInputMount(e,
                              `lists[0].children[${index}].data.month_budget`, `lists[0].children[${index}].data.month_budget`, "total_budget")
                          }
                          name={`lists[0].children[${index}].data.total_budget`}
                        />
                      </td>
                    </tr>
                    {index === this.props.state.lists[0].children.length - 1 && (
                      <tr className="td-sum ">
                        <td></td>
                        <td
                          style={{ paddingLeft: 50 }}
                        >
                          รวมรายรับ
                        </td>
                        <td
                          width={220}
                          height={24}
                          className="text-center"
                        >
                          {this.summaryRevenueYear("4000-00 summaryYear_total_budget") !== 0 ?
                            numberWithComma(this.summaryRevenueYear("4000-00 summaryYear_total_budget").toFixed(2)) : "-"}
                        </td>
                        <td
                          width={220}
                          height={24}
                          className="text-center"
                        >
                          {this.summaryRevenueYear("4000-00 total_happend") !== 0 ? 
                            numberWithComma(this.summaryRevenueYear("4000-00 total_happend").toFixed(2)): "-"}
                        </td>
                        <td width={220} className="text-center">
                          {this.summaryRevenueYear("4000-00 total_budget") !== 0 ? 
                            numberWithComma(this.summaryRevenueYear("4000-00 total_budget").toFixed(2)) : "-"}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
              {_.map(this.props.state.lists[1]?.children, (item, index_code) => {

                sumExpense_summaryYear_total_budget += this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`)
                sumExpense_total_happend += this.summaryExpenseYear(`${item.data.code} total_happend`)
                sumExpense_total_budget += this.summaryExpenseYear(`${item.data.code} total_budget`)

                return (
                  <React.Fragment key={index_code}>
                    <tr>
                      <td />
                      <td>
                        <div className="ml-2">
                          {index_code === 0 && <div>รายจ่าย</div>}
                          <div>{item.data.name}</div>
                        </div>
                      </td>
                      <td />
                      <td className="td-red-scale" />
                      <td />
                    </tr>
                    {_.map(item.children, (item_child, index) => {
                      if (index_code !== 8) { ++last_index }
                      return (
                        <React.Fragment key={index}>
                          {item_child.children?.length > 0 ?
                            <React.Fragment>
                              <tr>
                                <td />
                                <td
                                  style={{ paddingLeft: 50 }}
                                >
                                  {item_child.data.name}
                                </td>
                                <td />
                                <td className="td-red-scale" />
                                <td />
                              </tr>
                              {_.map(item_child?.children, (item_child_child, index_child_child) => {
                                ++last_index_child
                                return (
                                  <React.Fragment key={index_child_child}>
                                    <tr>
                                      <td className="text-center">
                                        {last_index + last_index_child}
                                      </td>
                                      <td
                                        style={{ paddingLeft: 100 }}
                                      >
                                        {item_child_child.data.name}
                                      </td>
                                      <td
                                        width={220}
                                        height={24}
                                      >
                                        <input
                                          disabled={item_child_child.data?.summaryYear.total_budget_check || this.props.disabledApprove()}
                                          type="number"
                                          className="form-control text-center"
                                          style={{ maxHeight: 24 }}
                                          value={item_child_child.data?.summaryYear?.total_budget || ""}
                                          onChange={this.props.handleChangeInput}
                                          name={`lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.summaryYear.total_budget`}
                                        />
                                      </td>
                                      <td
                                        width={220}
                                        height={24}
                                        className="td-red-scale"
                                      >
                                        <input
                                          disabled
                                          type="number"
                                          className="form-control text-center td-red-scale"
                                          style={{ maxHeight: 24 }}
                                          value={item_child_child.data?.summaryYear?.total_happend 
                                            ? parseFloat(item_child_child.data?.summaryYear?.total_happend).toFixed(2) : ""
                                          }
                                        />
                                      </td>
                                      <td width={220}>
                                        <input
                                          disabled={this.props.disabledApprove()}
                                          type="number"
                                          className="form-control text-center"
                                          style={{ maxHeight: 24 }}
                                          value={item_child_child.data?.total_budget || ""}
                                          name={`lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.total_budget`}
                                          onChange={(e) =>
                                            this.props.handleChangeInputMount(e,
                                              `lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.month_budget`,
                                              `lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.month_budget`, "total_budget"
                                          )}
                                        />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              })}
                            </React.Fragment> :
                            <React.Fragment>
                              <tr>
                                <td className="text-center">
                                  {last_index}
                                </td>
                                <td
                                  style={{ paddingLeft: 50 }}
                                >
                                  {item_child.data.name}
                                </td>
                                <td
                                  width={220}
                                  height={24}
                                >
                                  <input
                                    disabled={item_child.data?.summaryYear?.total_budget_check || this.props.disabledApprove()}
                                    type="number"
                                    className="form-control text-center"
                                    style={{ maxHeight: 24 }}
                                    value={item_child.data?.summaryYear?.total_budget || ""}
                                    onChange={this.props.handleChangeInput}
                                    name={`lists[1].children[${index_code}].children[${index}].data.summaryYear.total_budget`}
                                  />
                                </td>
                                <td
                                  width={220}
                                  height={24}
                                  className="td-red-scale"
                                >
                                  <input
                                    disabled
                                    type="number"
                                    className="form-control text-center td-red-scale"
                                    style={{ maxHeight: 24 }}
                                    value={ item_child.data?.summaryYear?.total_happend 
                                      ? parseFloat(item_child.data?.summaryYear?.total_happend).toFixed(2) : ""}
                                  />
                                </td>
                                <td width={220}>
                                  <input
                                    disabled={this.props.disabledApprove()}
                                    type="number"
                                    className="form-control text-center"
                                    style={{ maxHeight: 24 }}
                                    value={item_child.data?.total_budget || ""}
                                    name={`lists[1].children[${index_code}].children[${index}].data.total_budget`}
                                    onChange={(e) =>
                                      this.props.handleChangeInputMount(e,
                                        `lists[1].children[${index_code}].children[${index}].data.month_budget`, `lists[1].children[${index_code}].children[${index}].data.month_budget`, "total_budget")
                                    }
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          }
                        </React.Fragment>
                      );
                    })}
                    <tr className="td-sum ">
                      <td></td>
                      <td
                        style={{ paddingLeft: 50 }}
                      >
                        รวม{item.data.name}
                      </td>
                      <td
                        width={120}
                        height={24}
                        className="text-center"
                      >
                        {this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`) !== 0  ? 
                          numberWithComma(this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`).toFixed(2)) : "-"} 
                      </td>
                      <td
                        width={120}
                        height={24}
                        className="text-center"
                      >
                        {this.summaryExpenseYear(`${item.data.code} total_happend`) !== 0  ? 
                          numberWithComma(this.summaryExpenseYear(`${item.data.code} total_happend`).toFixed(2)) : "-"} 
                      </td>
                      <td width={200} className="text-center">
                        {this.summaryExpenseYear(`${item.data.code} total_budget`) !== 0 ? 
                          numberWithComma(this.summaryExpenseYear(`${item.data.code} total_budget`).toFixed(2)) : "-"} 
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
              <tr className="td-sum2 ">
                <td />
                <td
                  style={{ paddingLeft: 50 }}
                >
                  รวมรายจ่าย
                </td>
                <td
                  width={220}
                  height={24}
                  className="text-center"
                >
                  {sumExpense_summaryYear_total_budget !== 0 ? numberWithComma(sumExpense_summaryYear_total_budget.toFixed(2)) : "-"} 
                </td>
                <td
                  width={220}
                  height={24}
                  className="text-center"
                >
                  {sumExpense_total_happend !== 0 ? numberWithComma(sumExpense_total_happend.toFixed(2)) : "-"} 
                </td>
                <td width={220} className="text-center">
                  {sumExpense_total_budget !== 0 ? numberWithComma(sumExpense_total_budget.toFixed(2)) : "-"}
                </td>
              </tr>
              <tr className="td-sum3 ">
                <td />
                <td
                  style={{ paddingLeft: 50 }}
                >
                  รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด
                </td>
                <td
                  width={220}
                  height={24}
                  className="text-center"
                >
                  {(this.summaryRevenueYear("4000-00 summaryYear_total_budget") - sumExpense_summaryYear_total_budget) !== 0
                    ? numberWithComma((this.summaryRevenueYear("4000-00 summaryYear_total_budget") - sumExpense_summaryYear_total_budget).toFixed(2)) : "-"}  
                </td>
                <td
                  width={220}
                  height={24}
                  className="text-center"
                >
                  {(this.summaryRevenueYear("4000-00 total_happend") - sumExpense_total_happend) !== 0 
                    ? numberWithComma((this.summaryRevenueYear("4000-00 total_happend") - sumExpense_total_happend).toFixed(2)) :  "-"} 
                </td>
                <td width={220} className="text-center">
                  {(this.summaryRevenueYear("4000-00 total_budget") - sumExpense_total_budget) !== 0 
                    ? numberWithComma((this.summaryRevenueYear("4000-00 total_budget") - sumExpense_total_budget).toFixed(2)) : "-"} 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
