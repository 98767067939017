/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type reportProductTransactionQueryVariables = {|
  productAndServiceId?: ?string
|};
export type reportProductTransactionQueryResponse = {|
  +reportProductTransaction: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +date: ?any
      |}
    |}>
  |}
|};
export type reportProductTransactionQuery = {|
  variables: reportProductTransactionQueryVariables,
  response: reportProductTransactionQueryResponse,
|};
*/


/*
query reportProductTransactionQuery(
  $productAndServiceId: String
) {
  reportProductTransaction(productAndServiceId: $productAndServiceId) {
    edges {
      node {
        date
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productAndServiceId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "productAndServiceId",
    "variableName": "productAndServiceId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reportProductTransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductTransactionNodeConnection",
        "kind": "LinkedField",
        "name": "reportProductTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductTransactionNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductTransactionNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reportProductTransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductTransactionNodeConnection",
        "kind": "LinkedField",
        "name": "reportProductTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductTransactionNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductTransactionNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "16487e39413b46ce10f37c502c5ab7a4",
    "id": null,
    "metadata": {},
    "name": "reportProductTransactionQuery",
    "operationKind": "query",
    "text": "query reportProductTransactionQuery(\n  $productAndServiceId: String\n) {\n  reportProductTransaction(productAndServiceId: $productAndServiceId) {\n    edges {\n      node {\n        date\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2236e7a89af90f6f4dc5f104a4b7e1fc';

module.exports = node;
