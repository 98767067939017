import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import DailyJournalAll from './dailyJournalAll'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import $ from "jquery";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperDailyJournalAllQuery($id_In: [String], $ref_In: [String], $refNum: [String]) {
        
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            taxIdNumber
            juristicContactNumber
            logo
        }
        allAccountRecordGroup(id_In:$id_In,refNum: $refNum) {
            edges {
                node {
                    id
                    refNumber
                    name
                    added
                    issuedDate
                    category
                    totalDebit
                    totalCredit
                    refTransaction
                    creator
                    contact {
                        id
                        name
                        firstName
                        lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id
                                name
                                debit
                                credit
                                chartOfAccountCode {
                                    id
                                    name
                                    chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }
        cashTransactionViewer{
            allCashTransaction(ref_In: $ref_In,refNum: $refNum){
                edges{
                    node{
                        id
                        description
                        price
                        refNumber
                    }
                }
            }
        }

        chequeDepositViewer{
            allChequeDeposit(ref_In: $ref_In){
                edges{
                    node{
                        id
                        price
                        date
                        chequeNumber
                        bankName
                        branchNumber
                        refNumber
                    }
                }
            }
        }

        chequeTransactionViewer{
            allChequeTransaction(ref_In: $ref_In){
                edges{
                    node{
                        id
                        price
                        date
                        chequeNumber
                        bankAccount {
                            accountName
                            branch
                            bankName
                        }
                        refNumber
                    }
                }
            }
        }

        allBankAccountTransaction(ref_In: $ref_In) {
            edges{
                node{
                    id
                    price
                    date
                    refTransaction
                    bankAccount {
                        bankName
                        accountNumber
                    }
                }
            }
        }

        allCreditCardTransaction(ref_In: $ref_In){
            edges{
                node{
                    id
                    amount
                    refNumber
                }
            }
        }
        allPaymentGatewayMethod(ref_In: $ref_In){
            edges{
                node{
                    id
                    paymentType
                    paymentGateway{
                        id
                        paymentType
                    }
                    receive{
                        docNumber
                    }
                    amount
                    feeAmount
                    total
                    depositDate
                    image
                }
            }
        }

        paymentChannelViewer{
            allPaymentChannel(slug:"petty_cash",ref_In: $ref_In){
                edges{
                    node{
                        id
                        date
                        payRecordGroup{
                            id
                            docNumber
                            issuedDate
                        }
                        price
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }
        }

        otherExpensePaymentChannelViewer{
            allOtherExpensePaymentChannel(ref_In: $ref_In){
                edges{
                    node{
                        id
                        price
                        date
                        otherExpense{
                            id
                            docNumber
                        }
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                        rdCash{
                            docNumber 
                        }
                    }
                }
            }
        }

        allAdvancePettyCashChannel(ref_In: $ref_In){
            edges{
                node{
                    id
                    price
                    date
                    advance{
                            id
                            docNumber
                    }
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }
        
        allClearAdvancePettyCashChannel(ref_In: $ref_In){
            edges{
                node{
                    id
                    price
                    date
                    clearAdvance{
                            id
                            docNumber
                    }
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }

        allClearGuaranteeMoneyReceivedPettyCashChannel(ref_In: $ref_In){
            edges{
                node{
                    id
                    price
                    date
                    clearGuaranteeMoneyReceived{
                            id
                            docNumber
                    }
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }

        userConfig {
            id languages
        }
        
    }
`;

class WrapperDailyJournalAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkList: this.props.location.state.checkList,
            checkRefTrans: this.props.location.state.checkRefTrans,
            check_type: 'all',
            checkRefNumber: this.props.location.state.checkRefNumber,
        }
        this.print = this.print.bind(this);
        this.adjustHeight = this.adjustHeight.bind(this);
    }

    print() {
        $('.print:last-child').css({'page-break-after': 'auto' });
        window.print();
    }

    adjustHeight() {

    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>DailyJournal</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{id_In: this.state.checkList, ref_In: this.state.checkRefTrans,refNum: this.state.checkRefNumber}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return(
                                <DailyJournalAll 
                                    query={props}
                                    languages={props.userConfig.languages}
                                />
                            )
                        }
                         return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperDailyJournalAll;
