import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";

var token_id = localStorage.getItem("token");
var header = {
    authorization: `JWT ${token_id}`,
    "Content-Type": "application/json",
};


function getCompanyAll(QR) {
    return axios.get(`${getApiRoot()}insure/company`, { 
        headers: header, 
        params: { 
            qr: QR 
        } 
    },);
}

function post_create_insurance(data) { 
    return axios.post(`${getApiRoot()}insure/insurance`, data ,{ 
        headers: header ,
    },);
}

function getDataInsurance(data){
    return axios.get(`${getApiRoot()}insure/insurance` , {
        headers: header,
        params: {
            order_by: "new",
            index_first : data.index_first || 0,
            index_last: data.index_last || 10,
            end_date: data.end_date || "",
            start_date: data.start_date || "",
            search: data.search || "",
            // insurance_id : data.insurance_id || "",
        }
    })
} 
function getDataInsuranceById(insurance_id){ 
    return axios.get(`${getApiRoot()}insure/insurance` , {
        headers: header,
        params: { 
            insurance_id : parseInt(insurance_id),
        }
    })
} 

function updateDataInsurance(id, data) {
    return axios.patch(`${getApiRoot()}insure/insurance` , data , {
        headers: header,
        params: {
            insurance_id : id
        }
    })
}
 

export default {
    // Insurrance
    getCompanyAll,
    post_create_insurance,
    getDataInsurance,
    updateDataInsurance,
    getDataInsuranceById
}