import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import TaxList from '../tax/taxList';

class InputTax extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/:page`} component={TaxList}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default InputTax;