import React, { Component } from "react";
import getApiRoot from "../libs/getAPIRoot";
class TrackingDisplayBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      checkOnline: true,
      isHovered: false,
      data: null,
      hasFetchedData: false,
      hoveredIndex: -1,
      showImage: "",
      loading: false,
    };
  }

  // sort data by use merge sort
  mergeSort(arr = []) {
    if (arr.length <= 1) {
        return arr;
    }

    const mid = Math.floor(arr.length / 2);
    const left = arr.slice(0, mid);
    const right = arr.slice(mid);

    return this.merge(this.mergeSort(left), this.mergeSort(right));
  }

  merge(left, right) {
      const result = [];
      let leftIndex = 0;
      let rightIndex = 0;

      while (leftIndex < left.length && rightIndex < right.length) {
          const leftValue = Object.values(left[leftIndex])[0];
          const rightValue = Object.values(right[rightIndex])[0];

          if (leftValue >= rightValue) {
              result.push(left[leftIndex]);
              leftIndex++;
          } else {
              result.push(right[rightIndex]);
              rightIndex++;
          }
      }

      return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
  }

  handleMouseEnter = (index, menuID) => {
    this.setState({loading: true})
    if (!this.state.hasFetchedData) {
      this.setState({ 
        hoveredIndex: index,
        hasFetchedData: true
      });

      let requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };
      
      let url = `${getApiRoot()}account/tracking_image?id=${menuID}`;

      fetch(url, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.setState({
            showImage: result.image,
            loading: false,
          })
        })
        .catch(error => {
          console.log('error', error)
          this.setState({loading: false});
        });
    }

    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ 
      hasFetchedData: false,
      isHovered: false,
    });
  };

  handleMouseLeaveFromHover = () => {
    this.setState({ 
      hoveredIndex: -1,
    });
  };
  
  
  render() {
    let data = this.props.data
    const sortedData = this.mergeSort(data);

    return (
      <React.Fragment>
        <div className="d-flex flex-column" style={{ width: '100%'}}>
          <div className="displayBlockContainer">
            {sortedData?.map((menuBtn, index) => {
              return (
                <div className="innerTopContainer" key={index}>
                  <div className="innerTopContainerPic">
                    <div className="picIncontainer">
                      <img
                        src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/news-top-menu.png'}
                        alt="news-top-menu"
                        className={this.props.mini ? 'd-none' : ''}
                        onMouseEnter={() => this.handleMouseEnter(index, menuBtn.id)}
                        onMouseLeave={this.handleMouseLeave}
                      />
                    </div>
                  </div>
                  <span 
                      className={`hoverPic ${ this.state.hoveredIndex === index ? 'hovered' : 'disableHover'}`}
                      onMouseLeave={this.handleMouseLeaveFromHover}
                  >
                    {this.state.loading?
                      <span className="statusImage">loading...</span>                                                              
                      :
                      this.state.showImage ? 
                      <img src={this.state.showImage}/>
                      :
                      <span className="statusImage">No Image Added</span>
                    }
                  </span>
                  <div className="innerTopContainerAmount">
                    <div className="innerTopContainerAmountText">
                      ปุ่ม {menuBtn.name ? menuBtn.name : Object.keys(menuBtn)[0]}
                    </div>
                    <div className="innerTopContainerAmountNum">
                      {Object.values(menuBtn)[0].toLocaleString()} ครั้ง
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
      );
      
  }
}

export default TrackingDisplayBlock;