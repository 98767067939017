/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RequisitionNoteStatus = "ACTIVE" | "VOID" | "%future added value";
export type RequisitionNoteTypeRequisition = "PROJECT" | "UNIT" | "%future added value";
export type stockMenagementTableQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
  search?: ?string,
  status?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type stockMenagementTableQueryResponse = {|
  +allRequisitionNote: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +status: RequisitionNoteStatus,
        +docNumber: ?string,
        +typeRequisition: RequisitionNoteTypeRequisition,
        +typeNote: ?string,
        +issuedDate: ?any,
        +recipient: ?string,
        +firstNameRecipient: ?string,
        +lastNameRecipient: ?string,
        +username: ?string,
        +objective: ?string,
        +remark: ?string,
        +noteVoid: ?string,
        +creatorVoid: ?string,
        +contact: ?{|
          +id: string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |},
        +juristict: {|
          +id: string,
          +firstName: string,
          +lastName: string,
        |},
        +requisitionList: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +numberOfPieces: number,
              +numberOfBalance: number,
              +noNumber: ?string,
              +productAndService: {|
                +id: string,
                +noNumber: boolean,
                +name: string,
              |},
            |}
          |}>
        |},
        +requisitionNoteDocuments: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: ?string,
              +fileUpload: string,
            |}
          |}>
        |},
      |}
    |}>,
    +totalCount: ?number,
  |}
|};
export type stockMenagementTableQuery = {|
  variables: stockMenagementTableQueryVariables,
  response: stockMenagementTableQueryResponse,
|};
*/


/*
query stockMenagementTableQuery(
  $startDate: DateTime
  $endDate: DateTime
  $search: String
  $status: String
  $first: Int
  $last: Int
) {
  allRequisitionNote(startDate: $startDate, endDate: $endDate, search: $search, status: $status, first: $first, last: $last) {
    edges {
      node {
        id
        status
        docNumber
        typeRequisition
        typeNote
        issuedDate
        recipient
        firstNameRecipient
        lastNameRecipient
        username
        objective
        remark
        noteVoid
        creatorVoid
        contact {
          id
          name
          firstName
          lastName
        }
        juristict {
          id
          firstName
          lastName
        }
        requisitionList {
          edges {
            node {
              id
              numberOfPieces
              numberOfBalance
              noNumber
              productAndService {
                id
                noNumber
                name
              }
            }
          }
        }
        requisitionNoteDocuments {
          edges {
            node {
              id
              fileName
              fileUpload
            }
          }
        }
      }
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "noNumber",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "RequisitionNoteNodeConnection",
    "kind": "LinkedField",
    "name": "allRequisitionNote",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RequisitionNoteNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RequisitionNoteNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeRequisition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeNote",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "issuedDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recipient",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstNameRecipient",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastNameRecipient",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objective",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remark",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "noteVoid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creatorVoid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JuristicNode",
                "kind": "LinkedField",
                "name": "juristict",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RequisitionListNodeConnection",
                "kind": "LinkedField",
                "name": "requisitionList",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RequisitionListNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RequisitionListNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "numberOfPieces",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "numberOfBalance",
                            "storageKey": null
                          },
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductAndServiceNode",
                            "kind": "LinkedField",
                            "name": "productAndService",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v10/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RequisitionNoteDocumentsNodeConnection",
                "kind": "LinkedField",
                "name": "requisitionNoteDocuments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RequisitionNoteDocumentsNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RequisitionNoteDocumentsNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileUpload",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "stockMenagementTableQuery",
    "selections": (v11/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "stockMenagementTableQuery",
    "selections": (v11/*: any*/)
  },
  "params": {
    "cacheID": "b5daffcd5f9cd2cc425fa29f727daae3",
    "id": null,
    "metadata": {},
    "name": "stockMenagementTableQuery",
    "operationKind": "query",
    "text": "query stockMenagementTableQuery(\n  $startDate: DateTime\n  $endDate: DateTime\n  $search: String\n  $status: String\n  $first: Int\n  $last: Int\n) {\n  allRequisitionNote(startDate: $startDate, endDate: $endDate, search: $search, status: $status, first: $first, last: $last) {\n    edges {\n      node {\n        id\n        status\n        docNumber\n        typeRequisition\n        typeNote\n        issuedDate\n        recipient\n        firstNameRecipient\n        lastNameRecipient\n        username\n        objective\n        remark\n        noteVoid\n        creatorVoid\n        contact {\n          id\n          name\n          firstName\n          lastName\n        }\n        juristict {\n          id\n          firstName\n          lastName\n        }\n        requisitionList {\n          edges {\n            node {\n              id\n              numberOfPieces\n              numberOfBalance\n              noNumber\n              productAndService {\n                id\n                noNumber\n                name\n              }\n            }\n          }\n        }\n        requisitionNoteDocuments {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n            }\n          }\n        }\n      }\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e86f2d73ace26fd044933f04d256791f';

module.exports = node;
