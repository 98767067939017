/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type wrapperCashDepositQueryVariables = {|
  id_list?: ?$ReadOnlyArray<?string>
|};
export type wrapperCashDepositQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +taxIdNumber: ?string,
    +juristicContactNumber: ?string,
    +logo: ?string,
  |},
  +cashTransactionViewer: ?{|
    +allCashTransaction: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +receive: ?{|
            +docNumber: string,
            +added: any,
            +issuedDate: any,
          |},
          +invoice: ?{|
            +docNumber: string,
            +added: any,
            +issuedDate: any,
          |},
          +receiptDeposit: ?{|
            +docNumber: string,
            +added: any,
            +issuedDate: any,
          |},
          +guaranteeMoneyReceive: ?{|
            +docNumber: string,
            +added: any,
            +issuedDate: any,
          |},
          +clearAdvance: ?{|
            +docNumber: string,
            +added: any,
            +issuedDate: any,
          |},
          +otherReceive: ?{|
            +docNumber: string,
            +added: any,
            +issuedDate: any,
          |},
          +description: ?string,
          +price: number,
        |}
      |}>,
    |}
  |},
|};
export type wrapperCashDepositQuery = {|
  variables: wrapperCashDepositQueryVariables,
  response: wrapperCashDepositQueryResponse,
|};
*/


/*
query wrapperCashDepositQuery(
  $id_list: [String]
) {
  selfProject {
    id
    name
    address
    taxIdNumber
    juristicContactNumber
    logo
  }
  cashTransactionViewer {
    allCashTransaction(status: "not_pass", cashType: "cash_in", id_In: $id_list) {
      totalCount
      edges {
        node {
          id
          receive {
            docNumber
            added
            issuedDate
            id
          }
          invoice {
            docNumber
            added
            issuedDate
            id
          }
          receiptDeposit {
            docNumber
            added
            issuedDate
            id
          }
          guaranteeMoneyReceive {
            docNumber
            added
            issuedDate
            id
          }
          clearAdvance {
            docNumber
            added
            issuedDate
            id
          }
          otherReceive {
            docNumber
            added
            issuedDate
            id
          }
          description
          price
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id_list"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectNode",
  "kind": "LinkedField",
  "name": "selfProject",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxIdNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "juristicContactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "cashType",
    "value": "cash_in"
  },
  {
    "kind": "Variable",
    "name": "id_In",
    "variableName": "id_list"
  },
  {
    "kind": "Literal",
    "name": "status",
    "value": "not_pass"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v8 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v11 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperCashDepositQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CashTransactionViewer",
        "kind": "LinkedField",
        "name": "cashTransactionViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "CashTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "allCashTransaction",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CashTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CashTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveNode",
                        "kind": "LinkedField",
                        "name": "receive",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiceNode",
                        "kind": "LinkedField",
                        "name": "invoice",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiptDepositNode",
                        "kind": "LinkedField",
                        "name": "receiptDeposit",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GuaranteeMoneyReceivedNode",
                        "kind": "LinkedField",
                        "name": "guaranteeMoneyReceive",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClearAdvanceNode",
                        "kind": "LinkedField",
                        "name": "clearAdvance",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OtherReceiveNode",
                        "kind": "LinkedField",
                        "name": "otherReceive",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wrapperCashDepositQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CashTransactionViewer",
        "kind": "LinkedField",
        "name": "cashTransactionViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "CashTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "allCashTransaction",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CashTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CashTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveNode",
                        "kind": "LinkedField",
                        "name": "receive",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiceNode",
                        "kind": "LinkedField",
                        "name": "invoice",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiptDepositNode",
                        "kind": "LinkedField",
                        "name": "receiptDeposit",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GuaranteeMoneyReceivedNode",
                        "kind": "LinkedField",
                        "name": "guaranteeMoneyReceive",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClearAdvanceNode",
                        "kind": "LinkedField",
                        "name": "clearAdvance",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OtherReceiveNode",
                        "kind": "LinkedField",
                        "name": "otherReceive",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9a01a3d71855eae18726867bea7a4f67",
    "id": null,
    "metadata": {},
    "name": "wrapperCashDepositQuery",
    "operationKind": "query",
    "text": "query wrapperCashDepositQuery(\n  $id_list: [String]\n) {\n  selfProject {\n    id\n    name\n    address\n    taxIdNumber\n    juristicContactNumber\n    logo\n  }\n  cashTransactionViewer {\n    allCashTransaction(status: \"not_pass\", cashType: \"cash_in\", id_In: $id_list) {\n      totalCount\n      edges {\n        node {\n          id\n          receive {\n            docNumber\n            added\n            issuedDate\n            id\n          }\n          invoice {\n            docNumber\n            added\n            issuedDate\n            id\n          }\n          receiptDeposit {\n            docNumber\n            added\n            issuedDate\n            id\n          }\n          guaranteeMoneyReceive {\n            docNumber\n            added\n            issuedDate\n            id\n          }\n          clearAdvance {\n            docNumber\n            added\n            issuedDate\n            id\n          }\n          otherReceive {\n            docNumber\n            added\n            issuedDate\n            id\n          }\n          description\n          price\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cd8a29e42c2f8fe7af988ac765a68eeb';

module.exports = node;
