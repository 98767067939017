/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateUpdateDocumentCollectionLettersInput = {|
  fileDocument: any,
  clientMutationId?: ?string,
|};
export type collectionLetterAttachMutationVariables = {|
  input: CreateUpdateDocumentCollectionLettersInput
|};
export type collectionLetterAttachMutationResponse = {|
  +createUpdateDocumentCollectionLetters: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type collectionLetterAttachMutation = {|
  variables: collectionLetterAttachMutationVariables,
  response: collectionLetterAttachMutationResponse,
|};
*/


/*
mutation collectionLetterAttachMutation(
  $input: CreateUpdateDocumentCollectionLettersInput!
) {
  createUpdateDocumentCollectionLetters(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUpdateDocumentCollectionLettersPayload",
    "kind": "LinkedField",
    "name": "createUpdateDocumentCollectionLetters",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionLetterAttachMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "collectionLetterAttachMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6d86e945743c2685727be674dd771a8a",
    "id": null,
    "metadata": {},
    "name": "collectionLetterAttachMutation",
    "operationKind": "mutation",
    "text": "mutation collectionLetterAttachMutation(\n  $input: CreateUpdateDocumentCollectionLettersInput!\n) {\n  createUpdateDocumentCollectionLetters(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ddf75179a074aaf127b4bbaeed61a0ca';

module.exports = node;
