import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import GuaranteeMoneyReceivedReportTable from "./guaranteeMoneyReceivedReportTable";
import ExportReceive from "./server_export";
import ContactReportList from "../contactReportList";
import _ from "lodash";
import Loading from '../../../../libs/loading';
import postApiUseing from '../../../../libs/postApiUseing';
import { fetchQuery } from "relay-runtime";
import Swal from "sweetalert2";
import BackButtonIcon from '../../../../components/BackBtn/indexBack';

import './guaranteeMoneyReceivedReportPDF.scss'
import i18next from "i18next";

const query = graphql`
  query guaranteeMoneyReceivedReportQuery(
    $first: Int
    $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $search: String
    $customerType: String
    $statusType: String
    $contact: ID
    $paymentChannel: String
    $order: String
  ) {
    allGuaranteeMoneyReceived(
      first: $first
      last: $last
      startDate: $start_date
      endDate: $end_date
      search: $search
      customerType: $customerType
      statusType: $statusType
      contact: $contact
      paymentChannel: $paymentChannel
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          docNumber
          issuedDate
          amount
          description
          status
          payGroup
          contact {
            name
            refNumber
            firstName
            lastName
            typeOfContact
            residential {
              name
            }
          }
          cashDepositReceiveTransaction {
            totalCount
          }
          bankAccountTransaction {
            totalCount
          }
          chequeDeposit {
            totalCount
          }
        }
      }
    }
    summaryGuaranteeMoneyReceived(
      startDate: $start_date
      endDate: $end_date
      search: $search
      customerType: $customerType
      contact: $contact
      paymentChannel: $paymentChannel
      statusType: $statusType
    )
  }
`;

class GuaranteeMoneyReceivedReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);

    this.state.current_date = new Date();
    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.min_date = min_date;
    this.state.search = "";
    this.state.search_input = "";
    this.state.customer_type = "";
    this.state.customer_type = "";
    this.state.status_type = "";
    this.state.temp_status_type = "";
    this.state.payment_channel = "";
    this.state.temp_payment_channel = "";
    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.checkFetchState = false;
    this.state.dataResult = "";
    this.state.loading = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchDataQuery = this.fetchDataQuery.bind(this);
    this.checkLoadingExcel = this.checkLoadingExcel.bind(this);

  }

  componentWillMount() {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "guaranteeMoneyReceivedReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

    this.fetchDataQuery();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      contact: this.state.temp_contact,
      status_type: this.state.temp_status_type,
      payment_channel: this.state.temp_payment_channel,
    },
      () => {
        this.fetchDataQuery();
        this.goFirst();
      });
    // super.goFirst()
  }

  onClickGetData(data) {
    this.props.history.push(`/accounting/report/account-receivable/guarantee-money-received/PDF`, { data: data })
  }

  fetchDataQuery() {
    this.setState({
      checkFetchState: false,
      loading: true,
    })
    fetchQuery(environment, query, {
      first: this.state.first,
      last: this.state.last,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      search: this.state.search,
      contact: this.state.contact,
      customerType: this.state.customer_type,
      paymentChannel: this.state.payment_channel,
      statusType: this.state.status_type,
      order: "doc_number",
    }).then((item) => {
      this.setState({ dataResult: item, checkFetchState: true, loading: false });
    }).catch(error => {
      Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
    });
  }

  checkLoadingExcel(status) {
    this.setState({ checkFetchState: status });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-receivable"}
                  LinkText={i18next.t("guaranteeMoneyReceivedReport:Security Deposit Receipt Report")}
                  boxHtmlText={
                    <div className={`col`} id="guaranteeMoneyReceivedReport">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_receivable_print",
                      }) &&
                        <div className={`${this.state.checkFetchState ? "dropdown" : ''} printReport`}>
                          {!this.state.checkFetchState ?
                            <React.Fragment>
                              <button type="button" className="btnPrint">
                                <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                <Translation>{t => t("meter:Preparing information")}</Translation>
                              </button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                                <Translation>{t => t("PageList:Print")}</Translation>
                              </button>
                            </React.Fragment>
                          }
                          <div className={`dropdown-menu`}>
                            <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state)}>PDF</a>
                            <ExportReceive state={this.state} checkLoadingExcel={this.checkLoadingExcel} />
                          </div>
                        </div>
                      }
                    </div>
                  }
                /> 
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          // minDate={this.state.min_date}
                          maxDate={this.state.end_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <ContactReportList
                            handleChange={this.handleChange}
                            customer_type={this.state.customer_type}
                            contact={this.state.temp_contact}
                          />
                          <div className="col-md-4 border-right">
                            <div className="form-group">
                              <label>{i18next.t("guaranteeMoneyReceivedReport:Status")}</label>
                              <select
                                name="temp_status_type"
                                onChange={this.handleChange}
                                value={this.state.temp_status_type}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("guaranteeMoneyReceivedReport:All")}</option>
                                <option value="in_process">
                                {i18next.t("guaranteeMoneyReceivedReport:In Progress")}
                                </option>
                                <option value="completed">{i18next.t("guaranteeMoneyReceivedReport:Refund")}</option>
                                <option value="forfeit_all">{i18next.t("guaranteeMoneyReceivedReport:Seizure")}</option>
                                <option value="return_forfeit">
                                {i18next.t("guaranteeMoneyReceivedReport:Partial Seizure/Partial Refund")}
                                </option>
                                <option value="void">{i18next.t("guaranteeMoneyReceivedReport:Canceled Transactions")}</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("guaranteeMoneyReceivedReport:Payment Method")}</label>
                              <select
                                name="temp_payment_channel"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                                value={this.state.temp_payment_channel}
                              >
                                <option value="">{i18next.t("guaranteeMoneyReceivedReport:All")}</option>
                                <option value="cash">{i18next.t("guaranteeMoneyReceivedReport:Cash")}</option>
                                <option value="bank">{i18next.t("guaranteeMoneyReceivedReport:Bank Transfer")}</option>
                                <option value="cheque">{i18next.t("guaranteeMoneyReceivedReport:Cheque")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.loading ?
                    <Loading />
                    :
                    this.state.dataResult &&
                    <React.Fragment>
                      <div className="row mt-3">
                        <div className="col-12">
                          <div className="card fade-up">
                            <div className="table-responsive">
                              <table className="table table-hover">
                                <thead className="thead-light">
                                  <tr>
                                    <th className="text-center">{i18next.t("guaranteeMoneyReceivedReport:Order")}</th>
                                    <th className="text-center">
                                    {i18next.t("guaranteeMoneyReceivedReport:Deposit Guarantee Receipt Number")}
                                    </th>
                                    <th className="text-center">{i18next.t("guaranteeMoneyReceivedReport:Received Date")}</th>
                                    <th>{i18next.t("guaranteeMoneyReceivedReport:Room/House Number")}</th>
                                    <th>{i18next.t("guaranteeMoneyReceivedReport:Name")}</th>
                                    <th>{i18next.t("guaranteeMoneyReceivedReport:Details")}</th>
                                    <th className="text-right">{i18next.t("guaranteeMoneyReceivedReport:Amount")}</th>
                                    <th className="text-center">
                                    {i18next.t("guaranteeMoneyReceivedReport:Payment Method")}
                                    </th>
                                    <th className="text-center">{i18next.t("guaranteeMoneyReceivedReport:Status")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <GuaranteeMoneyReceivedReportTable
                                    props={this.state.dataResult}
                                    state={this.state}
                                  />
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={
                            this.state.dataResult?.allGuaranteeMoneyReceived.totalCount
                          }
                        />
                      </div>
                    </React.Fragment>
                  }
                </div>
              </div>

            }
          </Translation>
        </div>
      </Wrapper>
    );
  }
}

export default GuaranteeMoneyReceivedReport;
