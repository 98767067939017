import React, { Component } from 'react';
import Header from '../../../../components/header/index';
import Sidebar from '../../../../components/sidebar/index';
import Wrapper from '../../../../components/wrapper/index';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import AccountingTopMenuNavigation from '../../../accountingTopMenuNavigation';
import SeacrhDetails from '../../../../components/search/indexSearch';
import './slip.scss'
import ReactPaginate from "react-paginate";
import Loading from '../../../../libs/loading';
import API from '../../../../api/logHistory'
import LogsHistoryTable from './logsHistoryTable';
import Swal from 'sweetalert2';
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import jwtDecode from 'jwt-decode'
import i18next from 'i18next';

const query = graphql`
  query logsHistoryListQuery {
    allJuristic{
      totalCount
      edges {
        node {
          id
          firstName
          lastName
          role
          image
          user {
            id
            username
            lastLogin
          }
        }
      }
    }
  }
`;

class LogHistoryList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: "",
            startDate: new Date(),
            endDate: new Date(),
            searchUserId: "",
            loading: true,
            loadingPage: true,
            indexFirst: 0,
            indexLast: 10,
            countData: 0,
            pageNow: 0,
            page_get_api_now: 1,
            pageAll: 1,

            AllUser: null,
            logData: null,

            token_log: null,
            domain: '',
        }
        this.handlePropsChange = this.handlePropsChange.bind(this)
        this.getLog = this.getLog.bind(this)
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
    }

    componentDidMount() {
        let token = localStorage.getItem("token")
        let tokenDecode = jwtDecode(token)

        API.getToken().then((res) => {
            if (res?.data) {
                this.setState({
                    token_log: res?.data?.access_token, domain: tokenDecode?.site
                }, () => {
                    this.getLog(res?.data?.access_token)
                    this.getJuristicAll()
                })
            } else {

            }
        }).catch(err => console.log(err))
    }

    getJuristicAll() {
        fetchQuery(environment, query).then(data => {
            let mapNewData = data.allJuristic.edges.map((item) => ({
                ...item.node,
                // label: `(${item.node.user.username}) - ${item.node.firstName} ${item.node.lastName}`,
                value: item.node.user.id
            }))

            this.setState({ AllUser: mapNewData, loadingPage: false })
        })

    }


    getLog(access_token) {
        API.getHistoryLog({
            token: access_token,
            page_size: this.state.indexLast,
            page_num: this.state.page_get_api_now,
            domain: this.state.domain
        }).then((res_log) => {
            if (res_log?.data) {
                let logs = res_log?.data?.logs
                let countTotal = res_log?.data?.len_logs || 0
                let pageAll = Math.ceil(countTotal / this.state.indexLast)
                this.setState({
                    logData: logs?.map((item) => ({ ...item, open: false })),
                    countData: countTotal,
                    pageAll: pageAll,
                    loading: false,

                }, () => document.body.style.cursor = "auto")
            }
        })
    }

    getLogBySearch(data) {
        API.getLogBySearch(data).then((res) => {
            if (res?.data) {
                let countTotal = res?.data?.len_logs || 0
                let pageAll = Math.ceil(countTotal / this.state.indexLast)
                this.setState({
                    logData: res?.data?.logs,
                    countData: countTotal,
                    pageAll: pageAll,
                    loading: false
                })
            } else {
                this.setState({ loading: false }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), "การค้นหาไม่สำเร็จ โปรดลองใหม่อีกครั้ง", "error"))
            }
        }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), "การค้นหาไม่สำเร็จ โปรดลองใหม่อีกครั้ง", "error"))
    }

    handlePropsChange(channel_list) {
        let search = ""
        let searchUserId = ""

        channel_list.forEach((e, index) => {
            if (e.name === "search") {
                search = e.value
            } else if (e.name === "searchType") {
                searchUserId = e.value
            }
        });

        this.setState({
            search: search,
            searchUserId: searchUserId,
            loading: true
        }, () => {
            if (search || searchUserId) {
                this.getLogBySearch({ search: search, user_id: searchUserId, token: this.state.token_log, domain: this.state.domain })
            } else {
                this.getLog(this.state.token_log)
            }

        })
    }
    handlePageClick = (event) => {

        let page = event.selected;
        this.setState({ pageNow: page, page_get_api_now: page + 1, loading: true }, () => {
            document.body.style.cursor = "wait";
            this.getLog(this.state.token_log);
        })
    };
    handlePageSizeChange(e) {
        let pageSizes = e.target.value
        this.setState({ indexLast: parseInt(pageSizes), pageNow: 0, page_get_api_now: 1, loading: true }, () => {
            if (parseInt(pageSizes) === this.state.indexLast) {
                document.body.style.cursor = "wait";
                this.getLog(this.state.token_log);
            }
        })
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <Translation>
                    {(t) =>
                        !this.state.loadingPage ?
                            <div id="wrapper-content">
                                <AccountingTopMenuNavigation mini={true} />

                                <div className="container-fluid box slip-verification-list" >
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4" style={{ display: 'flex' }}>
                                                <Link to="/accounting">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <p>
                                                    {i18next.t("report:Icon History Log")}
                                                </p>
                                            </h3>
                                        </div>
                                    </div>

                                    <div className='filterSearch' style={{ zoom: '90%' }}>
                                        <SeacrhDetails
                                            text={[i18next.t("PageList:SearchUser")]}
                                            selectAndSearchIs={true}
                                            seleteDetail={this.state.AllUser}
                                            startDateIs={false}
                                            endDateIs={false}
                                            searchBoxId={true}
                                            searchBoxText={i18next.t("cheque:Document No.")}
                                            search={this.state.search}
                                            start_date={this.state.startDate}
                                            end_date={this.state.endDate}
                                            handlePropsChange={this.handlePropsChange}
                                        />
                                    </div>

                                    <div className="content-inner">
                                        {
                                            !this.state.loading ?
                                                <LogsHistoryTable
                                                    token_id={this.state.token_log}
                                                    TransferLogData={this.state.logData}
                                                    countLog={this.state.countData}
                                                    domain={this.state.domain}
                                                />
                                                :
                                                <Loading />
                                        }


                                        <div className='row fixRequestFast'>
                                            <div className='col-6'>
                                                <select className='page' onChange={this.handlePageSizeChange} value={this.state.indexLast}>
                                                    <option value="10">{i18next.t("silvermanguard:show")} 10 {i18next.t("silvermanguard:items")}</option>
                                                    <option value="20">{i18next.t("silvermanguard:show")} 20 {i18next.t("silvermanguard:items")}</option>
                                                    <option value="50">{i18next.t("silvermanguard:show")} 50 {i18next.t("silvermanguard:items")}</option>
                                                    <option value="100">{i18next.t("silvermanguard:show")} 100 {i18next.t("silvermanguard:items")}</option>
                                                </select>
                                            </div>
                                            <div className='col-6'>
                                                <div className='text-right' style={{ marginTop: '22px' }}>{i18next.t("silvermanguard:listAll")} {this.state.countData} &nbsp;
                                                    {this.state.pageAll > 0 && (
                                                        <ReactPaginate
                                                            previousLabel={"previous"}
                                                            nextLabel={"next"}
                                                            breakLabel={
                                                                <span className="btn page-link">...</span>
                                                            }
                                                            breakClassName={"break-me"}
                                                            pageClassName="page-item"
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={4}
                                                            previousClassName="sr-only"
                                                            containerClassName={"pagination float-right"}
                                                            subContainerClassName={"pagination-page"}
                                                            nextLinkClassName="sr-only"
                                                            pageLinkClassName="page-link"
                                                            activeClassName={"active"}
                                                            pageCount={this.state.pageAll}
                                                            onPageChange={this.handlePageClick}
                                                            forcePage={this.state.pageNow}
                                                        />
                                                    )}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <Loading />
                    }
                </Translation>

            </Wrapper>

        )
    }
}

export default LogHistoryList;