import React, {Component} from 'react';
import {NavLink} from "react-router-dom";


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/finance/tax/output-tax">
                                ซื้อ
                            </NavLink>
                        </li>
                        <li>
                             <NavLink to="/accounting/finance/tax/input-tax">
                                ขาย
                             </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;