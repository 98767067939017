

import React, { Component } from 'react';
import Swal from 'sweetalert2';
import UploadImageInput from "../libs/uploadImageInput";
import Resizer from "react-image-file-resizer";
import { uploadFile } from "react-s3";
import Select from 'react-select';
import API from '../api/silverGraund2'
import { encode } from 'base-64'
import _ from "lodash";
import i18next from 'i18next';


const provinceList = require('../api/Json/provinceList.json');

const customStyles = {
    control: provided => ({
      ...provided,
      fontFamily: 'kanit'
    })
}
class VMSCreate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            contactType: "",
            vmsImage: '',
            idCardImage2:"",
            carImage:"",
            isVmsImage:false,
            vmsImageCar:'',
            vmsImageCarResize:'',
            isVmsImageCar:false,
            carRegistration:'',
            isCar:false,
            province:'',
            homeId:'',
            isHomeId:false,
            isCheckedType: false,
            loading:false,
            lang: this.props.location?.state ? this.props.location?.state : false,
            allResidential:[],
            settingSecuritySystem: true,
            settingParkingFeeAccount: true,
            settingParkingFeeEstamp: true,
            settingParkingFeeCalculate: true
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.resolveUpload = this.resolveUpload.bind(this);
        this.resizeImg = this.resizeImg.bind(this)
        this.callingResizeImg = this.callingResizeImg.bind(this)
        this.handleInputChangeResidential = this.handleInputChangeResidential.bind(this)
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;

        if(event.target.name === 'check_type'){
            this.setState({
                contactType: e.target.value
            })
        }
        else{
            this.setState(prevState => { return _.set(_.cloneDeep(prevState), event.target.name, value)});   
        }
    }

      upload (keyPrefix, file) {
            let config = {
                bucketName: "silverman-storage-backup",
                dirName: "silverman-storage/private" + keyPrefix,
                region: "ap-southeast-1",
                accessKeyId: "AKIA3OESFULNFF5B7P5Y",
                secretAccessKey: "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",
              };
              return  uploadFile(file, config); 
      }
   

     resolveUpload(path, file) {
        return new Promise(async (resolve) => {
            let res =  await this.upload("/vms/" + path, file);
            resolve(res);
        });
      }
      
     asyncCall2(idcardImg,carvmsImg) {

        const allPromise = Promise.all([this.resolveUpload("image",idcardImg), this.resolveUpload("image",carvmsImg)]);

        allPromise.then(values => {
           // console.log(values); // [resolvedValue1, resolvedValue2]
          }).catch(error => {
            console.log(error); // rejectReason of any first rejected promise
          });
        return allPromise
      }
      
     
      resizeImg(file) {
        let resizeImg;
         const resizeFile = (file) =>
            new Promise((resolve) => {
                Resizer.imageFileResizer(
                file,
                450,
                450,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
                );
            });

           resizeImg =  resizeFile(file);

         return resizeImg
      }

      callingResizeImg(idCardImg,carvmsImg) {
        const allPromise = Promise.all([this.resizeImg(idCardImg), this.resizeImg(carvmsImg)]);

        allPromise.then(values => {
           // console.log(values); // [resolvedValue1, resolvedValue2]
          }).catch(error => {
            console.log(error); // rejectReason of any first rejected promise
          });

        return allPromise
      }


    handleInputChangeResidential(e) {
        let event = _.cloneDeep(e);
        if(event.name === 'residentialName'){
            this.setState({
                homeId: event.homeId,
            })
        }   
    }
      
    componentDidMount(){
        this.getAllResidential()
        this.getAllSettingGuard()
    }

   
    getAllSettingGuard(){
       let site_id =  localStorage.getItem('site_id')
       let siteId = encode("siteId:"+site_id)

       API.getAllSettingGuard().then((res) => {
        if(res.data){
          this.setState({
            settingSecuritySystem: res.data.settingManagement.settingSecuritySystem,
            settingParkingFeeAccount: res.data.settingManagement.settingParkingFeeAccount,
            settingParkingFeeEstamp: res.data.settingManagement.settingParkingFeeEstamp,
            settingParkingFeeCalculate: res.data.settingManagement.settingParkingFeeCalculate
          })
        }}).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), 'บันทึกไม่สำเร็จ', 'error'))
    }

    getAllResidential(){
        API.getAllResidential().then((res) => {
            if(res.data){
                let data_clone = _.cloneDeep(res.data);
                let allResidentialData = data_clone?.allResidential?.map((n) => 
                ({ ...n, value: n.homeId, label:  n.name,name:"residentialName"}))
                this.setState({
                    allResidential : allResidentialData
                })
            }
        }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), 'บันทึกไม่สำเร็จ', 'error'))
    }

    async onSubmit(e) {
        e.preventDefault();
       
        var checkBoxes = document.getElementsByClassName('checkType');
        var isChecked = false;
        for (var i = 0; i < checkBoxes.length; i++) {
            if ( checkBoxes[i].checked ) {
                isChecked = true;
            };
        };

       if(this.state.vmsImage === ''){
            this.setState({
                isVmsImage:true,
            }) 
        }else if(this.state.vmsImageCar === ''){
            this.setState({
                isVmsImageCar:true
            })
        }else if(this.state.carRegistration === ''){
            this.setState({
                isCar: true
            })
        }
        else if(!isChecked){
            this.setState({
                isCheckedType:true,
            }) 
        }else if(this.state.contactType === "appointment" && this.state.homeId === ''){
            this.setState({
                isHomeId: true
            })
        }else {
            this.setState({
                isVmsImage:false,
                isVmsImageCar:false,
                isCheckedType:false,
                isHomeId:false,
                isCar:false,
                loading:true
            }) 

            try {
                    let idcardImgResize ;
                    let carResize ;

                    await this.callingResizeImg(this.state.vmsImage,this.state.vmsImageCar).then((data) => {
                            idcardImgResize = data[0]
                            carResize = data[1]
                    })

                    let new_file = new File([idcardImgResize], `${'id_card_' + new Date().getTime()}.jpeg`);
                    let new_file2 = new File([carResize], `${'car_' + new Date().getTime()}.jpeg`);

                    await this.asyncCall2(new_file,new_file2).then((data) => {
                        if(data[0].result.ok && data[1].result.ok ){
                        let dese = "vms/image/"+new_file.name
                        let dese2 = "vms/image/"+new_file2.name
                        this.setState({ 
                            idCardImage2: dese,
                            carImage: dese2,
                        })  
                        }else{
                            alert("no data")
                        }
                        
                    })
            
                    let data = {
                        "code" : this.props.match.params.id,
                        "idCardImage":this.state.idCardImage2,
                        "carImage":this.state.carImage,
                        "carRegistration":this.state.carRegistration,
                        "province":this.state.province,
                        "typePerson":this.state.contactType,
                        "homeId":this.state.homeId
                    }
            
                    API.addCar(data).then((res) => {
                        if(res.data){
                            if(res.data.statusMessage === "SUCCESS"){
                                Swal.fire(this.state.lang ? 'Save successfully' :i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                                window.location.href = '/vms/vms-success/'+res.data.code+"/"+this.state.lang;
                            });
                            }else if(res.data.statusMessage === "service error"){
                                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("settingToolandConnect:Technow is experiencing an issue. Please contact the administrator."), 'error') 
                            }else{
                                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:You have already created the data."), 'error') 
                                this.setState({
                                    loading:false
                                }) 
                            }
                        }
                   
                }, () => {
                    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error')
                 })

            }catch (err) {
                alert("err aaa",err)
            }

        }       
    }
    


    render() {
        const {settingSecuritySystem,settingParkingFeeAccount,settingParkingFeeEstamp,settingParkingFeeCalculate} = this.state
        if (!settingSecuritySystem && !settingParkingFeeAccount && !settingParkingFeeEstamp && !settingParkingFeeCalculate ) {
             Swal.fire('ไม่ได้ตั้งค่า', 'กรุณาติดต่อนิติเพื่อตั้งค่างานจัดเก็บค่าที่จอดรถ', 'error') 
        }
        return (
            <>
                <div id="wrapper-content">
                    <div id='vms-wrapper'>
                        <div style={{ position: 'relative' }} >
                            <img src={process.env.PUBLIC_URL + '/images/setting-walkin/walkin.png'} style={{width:'100%'}}/> 
                            <div className="centered">
                                <div className='vms-logo-container' >
                                    <div>
                                        <img src={process.env.PUBLIC_URL + '/images/logo/SVM-full-dark.png'} alt="svm-agent-logo"/>
                                    </div>
                                </div>
                                <div>
                                    <span onClick={ () => this.setState({lang : !this.state.lang})} className='btn-lang'>
                                        <img src={this.state.lang ? (process.env.PUBLIC_URL + '/images/flags/en.png') : (process.env.PUBLIC_URL + '/images/flags/th.png')} 
                                        alt="flag-icon" className='flag'/>
                                        <h6><small>{this.state.lang ? 'EN':'ไทย'}</small></h6>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='vms-create-wrapper p-3'>
                        <form onSubmit={this.onSubmit}>
                            <div className='vms-create '>
                                <h4>1. {this.state.lang ?  'Visitor Information' : 'ข้อมูลของผู้เข้าโครงการ'}<span className='text-red'>*</span></h4>
                                <p className='pb-4'>{this.state.lang ? `Please take a photo of ID card or driver's license before leaving the project.` :'กรุณาถ่ายรูปบัตรประชาชนหรือใบขับขี่ก่อนออกจากโครงการ'}</p>
                                {
                                    this.state.vmsImage?.length === 0 &&
                                    <div className='camera-box'>
                                        <div className='text-center' style={{paddingTop:'5em'}}>
                                            <label htmlFor={"uploadFile"}
                                                 >
                                                 <img src={process.env.PUBLIC_URL + '/images/icons/camera.png'} style={{width:'78px',height:'78px'}} />
                                                 <p> {this.state.lang ?  `Please take a photo of ID card or driver's license` : 'ถ่ายรูปบัตรประชาชนหรือใบขับขี่'}</p>
                                            </label>
                                            <div className="upload-input">
                                                <UploadImageInput
                                                    id={"uploadFile"} setField="vmsImage" 
                                                    onChange={this.handleInputChange}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    this.state.vmsImage && 
                                            <div className='camera-box'>
                                                <img 
                                                 src={URL.createObjectURL(this.state.vmsImage)}
                                                 style={{ width: '100%', height: '246px',filter:'blur(3px)'}} />
                                                    <div className='text-center'>
                                                        <label htmlFor={"uploadFile"}
                                                            className="" style={{position:'relative',top:'-147px'}}>
                                                            <img src={process.env.PUBLIC_URL + '/images/setting-walkin/camera-black.png'}/>
                                                            <p> {this.state.lang ?  `Please take a new photo of ID card or new driver's license` : 'ถ่ายรูปบัตรประชาชนหรือใบขับขี่ใหม่'}</p>
                                                        </label>
                                                        <div className="upload-input">
                                                            <UploadImageInput
                                                                id={"uploadFile"}
                                                                setField="vmsImage"
                                                                onChange={this.handleInputChange}
                                                                />
                                                        </div>
                                                    </div>  
                                            </div>
                                }
                                {
                                    this.state.isVmsImage ? <span className='text-danger'>{this.state.lang ?  `Please take a photo of ID card or driver's license` : 'กรุณาใส่รูปบัตรประชาชนหรือใบขับขี่'}</span> :''
                                }
                            </div>
                            <div className='vms-create pt-5'>
                                <h4>2. {this.state.lang ? 'Vehicle information' :'ข้อมูลรถ'} <span className='text-red'>*</span></h4>
                                <p className='pb-4'>{this.state.lang ? `Please take a photo of the vehicle or enter the vehicle information before leaving the project.` :'กรุณาถ่ายรูปรถหรือกรอกข้อมูลรถก่อนออกจากโครงการ'}</p>
                                {
                                    this.state.vmsImageCar?.length === 0 && 
                                    <div className='camera-box'>
                                    <div className='text-center' style={{paddingTop:'5em'}}>
                                            <label htmlFor={"uploadFileCar"} >
                                                 <img src={process.env.PUBLIC_URL + '/images/icons/camera.png'} style={{width:'78px',height:'78px'}} />
                                                 <p> {this.state.lang ? 'Please take a photo' : 'ถ่ายรูปรถ'}</p>
                                            </label>
                                            <div className="upload-input">
                                                <UploadImageInput
                                                    id={"uploadFileCar"} setField="vmsImageCar" 
                                                    onChange={this.handleInputChange}
                                                    />
                                            </div>
                                        </div>
                                 </div>
                                }
                                {
                                    this.state.vmsImageCar && 
                                            <div className='camera-box'>
                                                <img 
                                                 src={URL.createObjectURL(this.state.vmsImageCar)}
                                                 style={{ width: '100%', height: '246px',filter:'blur(3px)'}} />
                                                    <div className='text-center'>
                                                        <label htmlFor={"uploadFileCar"}
                                                            className="" style={{position:'relative',top:'-147px'}}>
                                                            <img src={process.env.PUBLIC_URL + '/images/setting-walkin/camera-black.png'}/>
                                                            <p> {this.state.lang ? 'Please take a new photo' : 'ถ่ายรูปรถใหม่'}</p>
                                                        </label>
                                                        <div className="upload-input">
                                                            <UploadImageInput
                                                                id={"uploadFileCar"}
                                                                setField="vmsImageCar"
                                                                onChange={this.handleInputChange}
                                                                />
                                                        </div>
                                                    </div>  
                                            </div>
                                }
                                
                                {
                                    this.state.isVmsImageCar ? <span className='text-danger'>{this.state.lang ? 'Please take a new photo' : 'กรุณาใส่รูปรถ'}</span> :''
                                }
                            </div>
                            <div className='pt-4'>
                                <hr className="hr-text" />
                            </div>
                            <div className='vms-create pt-4'>
                                <p className='fw-bolder pt-2'>{this.state.lang ? `Vehicle registration` :'ทะเบียนรถ'} <span className='text-red'>*</span></p>
                                <input type='text' className='form-control' name='carRegistration' onChange={this.handleInputChange}
                                />
                                {
                                   this.state.isCar ? <span className='text-danger'>{this.state.lang ? 'Please take a vehicle registration' : 'กรุณากรอกป้ายทะเบียนรถ'}</span> :''
                                }
                                <p className='fw-bolder pt-2'>{this.state.lang ? `Province` :'จังหวัด'}</p>
                                <div className="input-group">
                                    <select className="form-control" name='province' id="province"
                                    value={this.state.province}
                                    onChange={this.handleInputChange}>
                                        <option value="">{this.state.lang ? `Please select province` :'เลือกจังหวัด'}</option>
                                        {_.map(provinceList,(val, index) => {
                                            return (
                                                <option key={index} value={val.name_th}> {this.state.lang ? val.name_en :val.name_th}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <hr />
                            <div className='vms-create'>
                                <p className='fw-bolder pt-2'>{this.state.lang ? 'Visitor type' :'ประเภทผู้ติดต่อ'} <span className='text-red'>*</span></p>
                                <div className="form-check m-2">
                                    <input className="form-check-input checkType" type="radio"
                                        name="check_type"
                                        value="contact"
                                        onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="checkTypeAll">
                                        {this.state.lang ? 'Contact juristic' :'ติดต่อนิติ/สำนักงานขาย'}
                                    </label>
                                </div>
                                <div className="form-check m-2">
                                    <input className="form-check-input checkType" type="radio"
                                        name="check_type"
                                        value="appointment"
                                        onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="checkTypeAll">
                                        {this.state.lang ? 'Advance appointment' :'ผู้ติดต่อนัดหมายล่วงหน้า (ผู้เยี่ยมเยือน)'}
                                    </label>

                                </div>
                                <div className="form-check m-2">
                                    <input className="form-check-input checkType" type="radio"
                                        name="check_type"
                                        value="person"
                                        onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="checkTypeAll">
                                        {this.state.lang ? 'Person' :'บุคคลทั่วไป'} 
                                    </label>

                                </div>
                                {this.state.isCheckedType ? <span className='text-danger'>{this.state.lang ? 'Please select a visitor type' : 'กรุณาเลือกประเภทผู้ติดต่อ'}</span> : ''}
                            </div>
                            {
                                this.state.contactType && this.state.contactType === "appointment" || 
                                this.state.contactType === "person" ?
                                    <div className='vms-create pb-5'>
                                        <p className='fw-bolder pt-2'>{this.state.lang ? 'Room number' :'บ้านเลขที่ /ห้องที่ติดต่อ'} <span className='text-red'>*</span></p>
                                        {/* <input type='text' className='form-control' name='homeId'  onChange={this.handleInputChange} /> */}
                                                 <Select
                                                        className="select"
                                                        options={this.state.allResidential}
                                                        // value={this.state.selectBank}
                                                        isSearchable={true}
                                                        onChange={this.handleInputChangeResidential}
                                                        placeholder ={this.state.lang ? 'Search room number' :'ค้นหาบ้านเลขที่ /ห้องที่ติดต่อ'} 
                                                        styles={customStyles}
                                                    />
                                        {this.state.isHomeId ? <span className='text-danger'>{this.state.lang ? 'Please take a room number' :'กรุณากรอกบ้านเลขที่ /ห้องที่ติดต่อ'}</span> : ''}
                                    </div>
                                    
                                    : ''
                            }

                            <div className='pb-4 pt-2'>
                                <button type="submit" className="btn btn-primary add mr-2" style={{ width: '100%' }}
                                disabled={this.state.loading}
                                >
                                    {this.state.loading ?  this.state.lang ? `We're saving...` : 'กำลังบันทึกข้อมูลกรุณารอสักครู่...': this.state.lang ? `Register` : 'ลงทะเบียน' }    
                                </button>
                            </div>
                        </form>
                        </div>
                     
                    </div>
                </div>
            </>

        );
    }
}

export default VMSCreate;
