/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JuristicNewsNewsType = "ALL" | "JURISTIC" | "TENANT" | "%future added value";
export type JuristicNewsStatus = "DRAFT" | "PUBLISH" | "VOID" | "%future added value";
export type announceDashboardQueryVariables = {|
  page?: ?boolean
|};
export type announceDashboardQueryResponse = {|
  +allJuristicNews: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +titleEn: ?string,
        +detail: ?string,
        +detailEn: ?string,
        +status: JuristicNewsStatus,
        +creator: ?string,
        +added: any,
        +updated: any,
        +newsType: JuristicNewsNewsType,
        +scheduleTime: ?any,
        +previewImage: string,
        +highlight: boolean,
        +group: ?{|
          +id: string,
          +name: string,
          +nameEn: ?string,
        |},
      |}
    |}>
  |}
|};
export type announceDashboardQuery = {|
  variables: announceDashboardQueryVariables,
  response: announceDashboardQueryResponse,
|};
*/


/*
query announceDashboardQuery(
  $page: Boolean
) {
  allJuristicNews(dashboard: true, newsTypes: "juristic", page: $page) {
    edges {
      node {
        id
        title
        titleEn
        detail
        detailEn
        status
        creator
        added
        updated
        newsType
        scheduleTime
        previewImage
        highlight
        group {
          id
          name
          nameEn
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "dashboard",
        "value": true
      },
      {
        "kind": "Literal",
        "name": "newsTypes",
        "value": "juristic"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      }
    ],
    "concreteType": "JuristicNewsNodeConnection",
    "kind": "LinkedField",
    "name": "allJuristicNews",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicNewsNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicNewsNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "titleEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detailEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newsType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduleTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previewImage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "highlight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JuristicNewsGroupNode",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameEn",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "announceDashboardQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "announceDashboardQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "af63a119955c058d82da3d614e8e75ae",
    "id": null,
    "metadata": {},
    "name": "announceDashboardQuery",
    "operationKind": "query",
    "text": "query announceDashboardQuery(\n  $page: Boolean\n) {\n  allJuristicNews(dashboard: true, newsTypes: \"juristic\", page: $page) {\n    edges {\n      node {\n        id\n        title\n        titleEn\n        detail\n        detailEn\n        status\n        creator\n        added\n        updated\n        newsType\n        scheduleTime\n        previewImage\n        highlight\n        group {\n          id\n          name\n          nameEn\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d0ce3881364c652cc77f79930d7270a';

module.exports = node;
