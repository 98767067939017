import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import SelectContact from '../../income/selectContact';
import PurchaseForm from "./purchaseForm";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";

class PurchaseCreate extends Component {

    constructor() {
        super();
        this.state = {
            customer_list: [],
            accountType: "purchase",
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({customer_list: contact_list})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    {this.props.match.params.status === 'select_customer' &&
                    <SelectContact updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                   nextLink="/accounting/expense/purchase/form/create"
                                   backLink="/accounting/expense/purchase/list/all"
                                   disable_select_type={true}
                    />
                    }
                    {this.props.match.params.status === 'create' &&
                    <PurchaseForm customerList={this.state.customer_list}/>
                    }

                </WrapperContent>
            </Wrapper>


        )
    }
}

export default PurchaseCreate;