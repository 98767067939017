import React, { Component } from 'react'
import { Translation } from 'react-i18next';
import SettingCollectionLetterView from './settingCollectionLetterView';
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import _ from 'lodash';
import Swal from 'sweetalert2';
import SearchSelect from '../../libs/searchSelect';
import "./settingCollection.scss"
import {commitMutation} from "react-relay";
import { format } from 'date-fns';
import getDateTime from '../../libs/getDateTime'
import FormOneQuery from './formOneQuery';
import Loading from '../../libs/loading';
import i18next from 'i18next';

const query = graphql`
query settingFormQuery($formDebit: Int){
    selfProject{
        id
        name
        address
        logo
        juristicContactNumber
        keyProjectQr
        bankCompCode
        bankServiceCode
        taxIdNumber
        typeOfProject
        typeOfCompany
        establishmentStatus
    }
    bankAccountViewer {
        allBankAccount(status: true){
            edges {
                node {
                    id
                    docNumber
                    accountNumber
                    accountName
                    accountNameEn
                    accountType
                    receivePayment
                    makePayment
                    mobilePayment
                    crossBilling
                    bankName
                    branch
                    status
                }
            }
            totalCount
        }
    }
    allSettingFormDebit(formDebit: $formDebit) {
        edges{
            node{
                id
                description
                descriptionEng
                descriptionSecond
                descriptionSecondEng
                paymentCheck
                paymentTimeStatus
                paymentTime1
                paymentTime2
                bankAccountStatus
                numberOfDays
                bankAccount{
                    id
                    accountNumber
                    bankName
                    accountName
                    accountNameEn
                    branch
                }
                channelDescription
                contactName
                contactPhone
                creatorName
                creatorNameEng
                signerName
                signerPosition
                formDebit
                refDocument
                refReport
                refCollection1
                refCollection2
                refCollection3
            }
        }
    }
}`;

const mutation = graphql`
  mutation settingFormMutation ($input: CreateAndUpdateSettingFormDebitInput!)  {
    createAndUpdateSettingFormDebit (input: $input) {
        ok
        message
        message
        newSettingFormDebit {
            id
            description
            descriptionEng
            descriptionSecond
            descriptionSecondEng
            paymentTimeStatus
            paymentTime1
            paymentTime2
            paymentCheck
            bankAccountStatus
            bankAccount{
                id
            }
            channelDescription
            contactName
            contactPhone
            creatorName
            creatorNameEng
            signerName
            signerPosition
            formDebit
            numberOfDays
            refDocument
            refReport
            refCollection1
            refCollection2
            refCollection3
        }
    }
  }
`;

const setting_detail = {
    id : '',
    description: i18next.t("settingCollectionDashBoard:Referring to the corporate regulations, in case the co-owner defaults on payment within the specified time, they shall incur additional charges and fines at the rate of 12% per annum. If the payment is overdue for more than 6 months, additional charges and fines at the rate of 20% per annum on the outstanding amount shall apply, excluding the principal. Furthermore, services may be suspended collectively."),
    descriptionEng: "",
    description2: "",
    descriptionSecond:"",
    descriptionSecondEng:"",
    numberOfDays : 7,
    creatorName:"",
    creatorNameEng:"",
    payment_channel : {
      bank : {
        bankAccountStatus : false,
        bank_payment : {
          bankName: '',
          accountNumber: '',
          accountName: '',
          accountNameEn:'',
          accountNumberId: '',
          branch: ''
        },
      },
      cheque : {
        paymentCheck : false,
      },
      cash : {
        paymentTimeStatus : false,
        paymentTime1 : new Date(),
        paymentTime2 : new Date(),
      },
      channelDescription : "",
    },
    contactName : "",
    contactPhone : "",
    signerName : "",
    signerPosition : "",
    refDocument: false,
    refReport: true,
    refCollection1: false,
    refCollection2: false,
    refCollection3: false,
  }

class SettingForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            selfProject : {},
            setting_detail : setting_detail,
            bankAccount : [],
            formDebit : this.props.formDebit,
            loading : false,
            loadingData : false,
            firstTime : true,
            attactDocument : [], 
            //check permission to edit setting
            permissionEdit : _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_settingautomaticdebit_edit'}) ?
                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_settingautomaticdebit_edit' }) : false   
        }
    }

    async componentDidMount() {
        this.fetchQuery()
    }

    componentDidUpdate(){
        if(this.state.formDebit !== this.props.formDebit){
            this.setState({
                formDebit : this.props.formDebit,
                firstTime : true,
            }, () => this.fetchQuery())
        }
    }

    onCopyForm = async() => {
        let data = await FormOneQuery(1)
        if(data.allSettingFormDebit?.edges.length === 0 ){
            Swal.fire(i18next.t("settingForm:Unable to copy data"), `${i18next.t("settingForm:Because there is no saved form that")} 1`, 'warning');
        }else{
            let setting = {
                id : this.state.setting_detail.id,
                description : data.allSettingFormDebit.edges[0].node.description ,
                numberOfDays : data.allSettingFormDebit.edges[0].node.numberOfDays ,
                payment_channel : {
                    bank : {
                        bankAccountStatus : data.allSettingFormDebit.edges[0].node.bankAccountStatus,
                        bank_payment : {
                            bankName:  data.allSettingFormDebit.edges[0].node.bankAccount ? data.allSettingFormDebit.edges[0].node.bankAccount.bankName : '',
                            accountNumber: data.allSettingFormDebit.edges[0].node.bankAccount ?  data.allSettingFormDebit.edges[0].node.bankAccount.accountNumber : '',
                            accountName: data.allSettingFormDebit.edges[0].node.bankAccount ? data.allSettingFormDebit.edges[0].node.bankAccount.accountName : '',
                            accountNameEn:data.allSettingFormDebit.edges[0].node.bankAccount ? data.allSettingFormDebit.edges[0].node.bankAccount.accountNameEn : '',
                            accountNumberId: data.allSettingFormDebit.edges[0].node.bankAccount ? data.allSettingFormDebit.edges[0].node.bankAccount.id : '',
                            branch:data.allSettingFormDebit.edges[0].node.bankAccount ?  data.allSettingFormDebit.edges[0].node.bankAccount.branch : ''
                        },
                    },
                    cheque : {
                        paymentCheck : data.allSettingFormDebit.edges[0].node.paymentCheck,
                    },
                    cash : {
                        paymentTimeStatus : data.allSettingFormDebit.edges[0].node.paymentTimeStatus,
                        paymentTime1 : getDateTime(data.allSettingFormDebit.edges[0].node.paymentTime1),
                        paymentTime2 : getDateTime(data.allSettingFormDebit.edges[0].node.paymentTime2),
                    },
                    channelDescription : data.allSettingFormDebit.edges[0].node.channelDescription ,
                },
                contactName : data.allSettingFormDebit.edges[0].node.contactName,
                contactPhone : data.allSettingFormDebit.edges[0].node.contactPhone,
                creatorName :data.allSettingFormDebit.edges[0].node.creatorName,
                creatorNameEng :data.allSettingFormDebit.edges[0].node.creatorNameEng,
                signerName : data.allSettingFormDebit.edges[0].node.signerName,
                signerPosition : data.allSettingFormDebit.edges[0].node.signerPosition,
                refDocument: data.allSettingFormDebit.edges[0].node.refDocument,
                refReport: data.allSettingFormDebit.edges[0].node.refReport,
                refCollection1: data.allSettingFormDebit.edges[0].node.refCollection1,
                refCollection2: data.allSettingFormDebit.edges[0].node.refCollection2,
                refCollection3: data.allSettingFormDebit.edges[0].node.refCollection3,
            }
            this.setState({
                setting_detail : setting
            })
        }

    }

    fetchQuery = () => {
        this.setState({loadingData : true})
        fetchQuery(environment, query, {formDebit : this.state.formDebit}).then((data) => {
            if(data.selfProject){
                this.setState({
                    selfProject : data.selfProject
                })
            }
            if(data.bankAccountViewer.allBankAccount.edges.length > 0){
                this.setState({
                  bankAccount : data.bankAccountViewer.allBankAccount.edges
                })
            }
            if(data.allSettingFormDebit.edges.length > 0){
                let desSplite =  data.allSettingFormDebit.edges[0].node.description?.split("<br>")
                // let desSpliteEng =  data.allSettingFormDebit.edges[0].node.descriptionEng?.split("<br>")
                // let desSpliteEng2 =  data.allSettingFormDebit.edges[0].node.descriptionEng2?.split("<br>")

                let setting = {
                    id : data.allSettingFormDebit.edges[0].node.id,
                    description : data.allSettingFormDebit.edges[0].node.description,
                    descriptionEng : data.allSettingFormDebit.edges[0].node.descriptionEng,
                    descriptionSecond : data.allSettingFormDebit.edges[0].node.descriptionSecond,
                    descriptionSecondEng : data.allSettingFormDebit.edges[0].node.descriptionSecondEng,
                    numberOfDays : data.allSettingFormDebit.edges[0].node.numberOfDays ,
                    payment_channel : {
                        bank : {
                            bankAccountStatus : data.allSettingFormDebit.edges[0].node.bankAccountStatus,
                            bank_payment : {
                                bankName:  data.allSettingFormDebit.edges[0].node.bankAccount ? data.allSettingFormDebit.edges[0].node.bankAccount.bankName : '',
                                accountNumber: data.allSettingFormDebit.edges[0].node.bankAccount ?  data.allSettingFormDebit.edges[0].node.bankAccount.accountNumber : '',
                                accountName: data.allSettingFormDebit.edges[0].node.bankAccount ? data.allSettingFormDebit.edges[0].node.bankAccount.accountName : '',
                                accountNameEn: data.allSettingFormDebit.edges[0].node.bankAccount ? data.allSettingFormDebit.edges[0].node.bankAccount.accountNameEn : '',
                                accountNumberId: data.allSettingFormDebit.edges[0].node.bankAccount ? data.allSettingFormDebit.edges[0].node.bankAccount.id : '',
                                branch:data.allSettingFormDebit.edges[0].node.bankAccount ?  data.allSettingFormDebit.edges[0].node.bankAccount.branch : ''
                            },
                        },
                        cheque : {
                            paymentCheck : data.allSettingFormDebit.edges[0].node.paymentCheck,
                        },
                        cash : {
                            paymentTimeStatus : data.allSettingFormDebit.edges[0].node.paymentTimeStatus,
                            paymentTime1 : getDateTime(data.allSettingFormDebit.edges[0].node.paymentTime1),
                            paymentTime2 : getDateTime(data.allSettingFormDebit.edges[0].node.paymentTime2),
                        },
                        channelDescription : data.allSettingFormDebit.edges[0].node.channelDescription ,
                    },
                    contactName : data.allSettingFormDebit.edges[0].node.contactName,
                    contactPhone : data.allSettingFormDebit.edges[0].node.contactPhone,
                    creatorName:data.allSettingFormDebit.edges[0].node.creatorName,
                    creatorNameEng:data.allSettingFormDebit.edges[0].node.creatorNameEng,
                    signerName : data.allSettingFormDebit.edges[0].node.signerName,
                    signerPosition : data.allSettingFormDebit.edges[0].node.signerPosition,
                    refDocument: data.allSettingFormDebit.edges[0].node.refDocument,
                    refReport: data.allSettingFormDebit.edges[0].node.refReport,
                    refCollection1: data.allSettingFormDebit.edges[0].node.refCollection1,
                    refCollection2: data.allSettingFormDebit.edges[0].node.refCollection2,
                    refCollection3: data.allSettingFormDebit.edges[0].node.refCollection3,
                }
                this.setState({
                    setting_detail : setting,
                    firstTime : false
                }, () => this.setState({loadingData : false}, () => this.attachItem()))
            }else{
                this.setState({
                    setting_detail : setting_detail,
                    firstTime : true
                },() => this.setState({loadingData : false}, () => this.attachItem()))
            }
        });
    }

    handleChange = (event) => {
        let e = _.cloneDeep(event)
        if (e.target.name === 'setting_detail.payment_channel.bank.bank_payment.accountNumberId') {
          // auto suggest bank name and branch bank
          this.state.bankAccount.forEach((bank) => {
              if (bank.node.id === e.target.value) {
                  this.setState(prevState => {
                      return _.set(
                          _.cloneDeep(prevState),
                          "setting_detail.payment_channel.bank.bank_payment.bankName",
                          bank.node.bankName
                      );
                  });
                  this.setState(prevState => {
                      return _.set(
                          _.cloneDeep(prevState),
                          "setting_detail.payment_channel.bank.bank_payment.accountNumber",
                          bank.node.accountNumber
                      );
                  });
                  this.setState(prevState => {
                      return _.set(
                          _.cloneDeep(prevState),
                          "setting_detail.payment_channel.bank.bank_payment.accountName",
                          bank.node.accountName
                      );
                  });
                  this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        "setting_detail.payment_channel.bank.bank_payment.accountNameEn",
                        bank.node.accountNameEn
                    );
                });
                  this.setState(prevState => {
                      return _.set(
                          _.cloneDeep(prevState),
                          "setting_detail.payment_channel.bank.bank_payment.branch",
                          bank.node.branch
                      );
                  });
                  this.setState(prevState => {
                      return _.set(
                          _.cloneDeep(prevState),
                          "setting_detail.payment_channel.bank.bank_payment.accountNumberId",
                          bank.node.id
                      );
                  });
              }
    
          })
        }else{
          this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                e.target.name,
                e.target.value
            )
          });
        }  
    }

    undoDescription = () =>{
        Swal.fire({
          title: `${i18next.t("settingForm:Do you wants to restore the description settings")}?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: i18next.t("Allaction:cancel"),
          confirmButtonText: i18next.t("Allaction:yes")
        }).then((result) => {
        if(result.value){
          this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                "setting_detail.description",
                i18next.t("settingCollectionDashBoard:Referring to the corporate regulations, in case the co-owner defaults on payment within the specified time, they shall incur additional charges and fines at the rate of 12% per annum. If the payment is overdue for more than 6 months, additional charges and fines at the rate of 20% per annum on the outstanding amount shall apply, excluding the principal. Furthermore, services may be suspended collectively."),
            )
            });
        }
        })
    }

    reset = () => {
        Swal.fire({
            title: `${i18next.t("collectionLawFirm:Do you want to reset all?")}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: i18next.t("Allaction:cancel"),
            confirmButtonText: i18next.t("Allaction:yes")
          }).then((result) => {
          if(result.value){
            this.setState({
                setting_detail : setting_detail
            })
          }
        })
    }

    toggle_checkbox = (event) =>{
        let e = _.cloneDeep(event)
        if(e.target.name === "setting_detail.payment_channel.bank.bankAccountStatus"){
            if(this.state.setting_detail.payment_channel.bank.bank_payment.accountNumberId === ""){
                Swal.fire(i18next.t("settingForm:Please select the bank code."), '', 'warning')

            }else{
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        e.target.name,
                        e.target.checked
                    )
                  }, () => this.attachItem());
            }
        }else{
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    e.target.name,
                    e.target.checked
                )
              }, () => this.attachItem());
        }
        // this.attachItem();
    }

    onSubmit = () => {
        this.setState({loading : true})
        let variables ={
            input : {
                settingForm: {
                    settingFormDebitId: this.state.setting_detail.id,
                  //  description: this.state.setting_detail.description + "<br>" + this.state.setting_detail?.description2,
                    description: this.state.setting_detail.description,
                    descriptionEng: this.state.setting_detail.descriptionEng,
                    descriptionSecond: this.state.setting_detail.descriptionSecond,
                    descriptionSecondEng: this.state.setting_detail.descriptionSecondEng,
                    numberOfDays: this.state.setting_detail.numberOfDays,
                    paymentTimeStatus: this.state.setting_detail.payment_channel.cash.paymentTimeStatus,
                    paymentTime1: this.state.setting_detail.payment_channel.cash.paymentTime1 ? format(this.state.setting_detail.payment_channel.cash.paymentTime1,'YYYY-MM-DDTHH:mm:ss') : null,
                    paymentTime2: this.state.setting_detail.payment_channel.cash.paymentTime2 ? format(this.state.setting_detail.payment_channel.cash.paymentTime2,'YYYY-MM-DDTHH:mm:ss') : null,
                    paymentCheck: this.state.setting_detail.payment_channel.cheque.paymentCheck,
                    bankAccountStatus: this.state.setting_detail.payment_channel.bank.bankAccountStatus,
                    bankAccount: this.state.setting_detail.payment_channel.bank.bank_payment.accountNumberId,
                    bankAccountName: this.state.setting_detail.payment_channel.bank.bank_payment.accountName,
                    bankAccountNameEn: this.state.setting_detail.payment_channel.bank.bank_payment.accountNameEn,
                    channelDescription: this.state.setting_detail.payment_channel.channelDescription,
                    contactName: this.state.setting_detail.contactName,
                    contactPhone: this.state.setting_detail.contactPhone,
                    creatorName: this.state.setting_detail.creatorName,
                    creatorNameEng: this.state.setting_detail.creatorNameEng,
                    signerName: this.state.setting_detail.signerName,
                    signerPosition: this.state.setting_detail.signerPosition,
                    formDebit : this.state.formDebit,
                    refDocument: this.state.setting_detail.refDocument,
                    refReport: this.state.setting_detail.refReport,
                    refCollection1: this.state.setting_detail.refCollection1,
                    refCollection2: this.state.setting_detail.refCollection2,
                    refCollection3: this.state.setting_detail.refCollection3,
                }
            }
        }
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createAndUpdateSettingFormDebit.ok) {
                        Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                            this.setState({loading : false}, () => this.fetchQuery())
                        });
                    } else {
                        this.setState({loading : false})
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                },
            },
        )

    }

    attachItem = () => {
        let attachItem = [];
        if(this.state.setting_detail.refReport) attachItem.push('รายงานลูกหนี้คงค้างแบบละเอียด')
        if(this.state.setting_detail.refCollection1) attachItem.push('หนังสือทวงถาม ครั้งที่ 1')
        if(this.state.setting_detail.refCollection2) attachItem.push('หนังสือทวงถาม ครั้งที่ 2')
        if(this.state.setting_detail.refCollection3) attachItem.push('หนังสือทวงถาม ครั้งที่ 3')
        if(this.state.setting_detail.refDocument) attachItem.push('เอกสารแนบอื่นๆ')
        this.setState({attactDocument:attachItem})
    }

    render(){
        return (
            <Translation>
                {
                    t => 
                    <React.Fragment>
                        {this.state.loadingData ?
                         <Loading/>
                            :
                            <React.Fragment>
                                <div className="row" id="setting-collection">
                                    <div className="col-md-5 input-form">
                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <h5>{i18next.t("settingCollectionDashBoard:Description of Inquiry Letter - Paragraph No.")} 1 (TH)</h5>
                                            </div>
                                            <div className="col-6 float-right">
                                                {this.state.permissionEdit && <span className="reset cursor float-right" onClick={() => this.undoDescription()}>{i18next.t("settingCollectionDashBoard:Default value")}</span>}
                                            </div>
                                            <div className="col-12">
                                                <textarea className="form-control text-area mt-2" 
                                                    rows="4"
                                                    onChange= {this.handleChange}
                                                    name="setting_detail.description"
                                                    value={this.state.setting_detail.description}
                                                    maxLength={800}
                                                    disabled={!this.state.permissionEdit}
                                                />
                                                <p className="text-danger text-count text-right">{this.state.setting_detail.description.length} / 800</p>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <h5>{i18next.t("settingCollectionDashBoard:Description of Inquiry Letter - Paragraph No.")} 1 (ENG)
                                                <div className="tooltipImg">
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                            alt="question" className="question ml-1 mr-1" style={{width:'12px'}}/>
                                                    <span className="tooltiptext">
                                                            <Translation>{t=><label htmlFor="radioOption3" className='pl-2'>{t("settingForm:Enter the information needed to issue the document in English.")}</label>}</Translation> <br/>
                                                    </span>
                                                </div>
                                                </h5>
                                            </div>
                                            <div className="col-6 float-right">
                                                {this.state.permissionEdit && <span className="reset cursor float-right" onClick={() => this.undoDescription()}>{i18next.t("settingCollectionDashBoard:Default value")}</span>}
                                            </div>
                                             <div className="col-12">
                                                <textarea className="form-control text-area mt-2" 
                                                    rows="4"
                                                    onChange= {this.handleChange}
                                                    name="setting_detail.descriptionEng"
                                                    value={this.state.setting_detail?.descriptionEng}
                                                    maxLength={800}
                                                    disabled={!this.state.permissionEdit}
                                                />
                                                <p className="text-danger text-count text-right">
                                                    {this.state.setting_detail?.descriptionEng && this.state.setting_detail?.descriptionEng.length} 
                                                    / 800</p>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <h5>{i18next.t("settingCollectionDashBoard:Description of Inquiry Letter - Paragraph No.")} 2 (TH)</h5>
                                            </div>
                                            <div className="col-6 float-right">
                                                {this.state.permissionEdit && <span className="reset cursor float-right" onClick={() => this.undoDescription()}>{i18next.t("settingCollectionDashBoard:Default value")}</span>}
                                            </div>
                                            <div className="col-12">
                                                <textarea className="form-control text-area mt-2" 
                                                    rows="4"
                                                    onChange= {this.handleChange}
                                                    name="setting_detail.descriptionSecond"
                                                    value={this.state.setting_detail?.descriptionSecond}
                                                    maxLength={800}
                                                    disabled={!this.state.permissionEdit}
                                                />
                                                <p className="text-danger text-count text-right">
                                                    {this.state.setting_detail?.descriptionSecond && this.state.setting_detail?.descriptionSecond.length} 
                                                    / 800</p>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <h5>{i18next.t("settingCollectionDashBoard:Description of Inquiry Letter - Paragraph No.")} 2 (ENG)
                                                <div className="tooltipImg">
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                            alt="question" className="question ml-1 mr-1" style={{width:'12px'}}/>
                                                    <span className="tooltiptext">
                                                            <Translation>{t=><label htmlFor="radioOption3" className='pl-2'>{t("settingForm:Enter the information needed to issue the document in English.")}</label>}</Translation> <br/>
                                                    </span>
                                                </div>
                                                </h5>
                                                
                                            </div>
                                            <div className="col-6 float-right">
                                                {this.state.permissionEdit && <span className="reset cursor float-right" onClick={() => this.undoDescription()}>{i18next.t("settingCollectionDashBoard:Default value")}</span>}
                                            </div>
                                            <div className="col-12">
                                                <textarea className="form-control text-area mt-2" 
                                                    rows="4"
                                                    onChange= {this.handleChange}
                                                    name="setting_detail.descriptionSecondEng"
                                                    value={this.state.setting_detail?.descriptionSecondEng}
                                                    maxLength={800}
                                                    disabled={!this.state.permissionEdit}
                                                />
                                                <p className="text-danger text-count text-right">
                                                    {this.state.setting_detail?.descriptionSecondEng && this.state.setting_detail?.descriptionSecondEng.length} 
                                                    / 800</p>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 mt-2 ">
                                                <h5>{i18next.t("settingCollectionDashBoard:Creator Name")+" "+"(TH)"}</h5>
                                                <input 
                                                  className="form-control" 
                                                  id="setting_detail.creatorName" 
                                                  value={this.state.setting_detail.creatorName}
                                                  name={"setting_detail.creatorName"}
                                                  onChange={this.handleChange}
                                                  placeholder={i18next.t("settingCollectionDashBoard:Creator Name")+" "+"(TH)"}
                                                />
                                            </div>

                                            <div className="col-12 mt-2 ">
                                                <h5>{i18next.t("settingCollectionDashBoard:Creator Name")+" "+"(ENG)"}</h5>
                                                <input 
                                                  className="form-control" 
                                                  id="setting_detail.creatorNameEng" 
                                                  value={this.state.setting_detail.creatorNameEng}
                                                  name={"setting_detail.creatorNameEng"}
                                                  onChange={this.handleChange}
                                                  placeholder={i18next.t("settingCollectionDashBoard:Creator Name")+" "+"(ENG)"}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 mt-2 ">
                                                <h5>{i18next.t("settingCollectionDashBoard:Number of days after the inquiry letter's due date")}</h5>
                                            </div>
                                            <div className="col-12 mt-1 form-inline">
                                                <input  className="form-control" type="number" width={'100%'} 
                                                    name={"setting_detail.numberOfDays"}
                                                    value={this.state.setting_detail.numberOfDays} 
                                                    onChange={this.handleChange}
                                                    required={true}
                                                    disabled={!this.state.permissionEdit}
                                                />
                                                <span className="ml-2">{i18next.t("settingCollectionDashBoard:Day(s)")}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 ">
                                            <div className="col-12 mt-2 "><h5>{i18next.t("settingCollectionDashBoard:Payment method")}</h5></div>
                                            {/* <div className="col-1 justify-content-center d-flex align-items-center">
                                                <input  type="checkbox"
                                                        name="setting_detail.payment_channel.cash.paymentTimeStatus"
                                                        checked={this.state.setting_detail.payment_channel.cash.paymentTimeStatus}
                                                        onChange={this.toggle_checkbox}
                                                /> 
                                            </div>
                                            <div className="col-11">
                                                <span>ชำระเงินสดได้ที่สำนักงานนิติ</span>
                                            </div>
                                            <div className="col-1 mt-2" />
                                            <div className="col-11 mt-2">
                                                <h6>เวลาทำการ</h6>
                                            </div>
                                            <div className="col-1 mt-1" />
                                            <div className="col-11 row mt-1">
                                                <div className="col-4">
                                                    <DatePicker
                                                        selected={this.state.setting_detail.payment_channel.cash.paymentTime1}
                                                        onChange={(date) =>
                                                        this.setState(prevState => {
                                                            return _.set(
                                                                _.cloneDeep(prevState),
                                                                "setting_detail.payment_channel.cash.paymentTime1",
                                                                date
                                                            );
                                                        })
                                                        }
                                                        showTimeSelect={true}
                                                        showTimeSelectOnly={true}
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="HH:mm"
                                                        timeFormat="HH:mm"
                                                        className="form-control mr-1 ml-1 text-center"
                                                    />
                                                </div>
                                                <div className="col-3"><span className="ml-2 mr-2"> ถึง </span ></div>
                                                <div className="col-4">
                                                    <DatePicker
                                                        selected={this.state.setting_detail.payment_channel.cash.paymentTime2}
                                                        onChange={(date) =>
                                                        this.setState(prevState => {
                                                            return _.set(
                                                                _.cloneDeep(prevState),
                                                                "setting_detail.payment_channel.cash.paymentTime2",
                                                                date
                                                            );
                                                        })
                                                        }
                                                        showTimeSelect={true}
                                                        showTimeSelectOnly={true}
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="HH:mm"
                                                        timeFormat="HH:mm"
                                                        className="date-picker form-control mr-1 ml-1 text-center"
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-1 mt-2 justify-content-center d-flex align-items-center ">
                                                <input  type="checkbox"
                                                        name="setting_detail.payment_channel.cheque.paymentCheck"
                                                        checked={this.state.setting_detail.payment_channel.cheque.paymentCheck}
                                                        onChange={this.toggle_checkbox} 
                                                        disabled={!this.state.permissionEdit}
                                                /> 
                                            </div>
                                            <div className="col-11 mt-2 ">
                                                <span>{i18next.t("settingCollectionDashBoard:Pay by check in the name of")} “{this.state.selfProject.name}”</span>
                                            </div>
            
                                            <div className="col-1 mt-2 justify-content-center d-flex align-items-center">
                                                <input  type="checkbox"
                                                        name="setting_detail.payment_channel.bank.bankAccountStatus"
                                                        checked={this.state.setting_detail.payment_channel.bank.bankAccountStatus}
                                                        onChange={this.toggle_checkbox}
                                                        disabled={!this.state.permissionEdit}
                                                        // disabled={this.state.setting_detail.payment_channel.bank.bank_payment.accountNumberId === ""} 
                                                /> 
                                            </div>
                                            <div className="col-11 mt-2">
                                                <span>{i18next.t("settingCollectionDashBoard:Bank code")}</span>
                                            </div>
                                            <div className="col-1 mt-3" />
                                            <div className="col-11 mt-3">
                                                <h6>{i18next.t("settingCollectionDashBoard:Select the bank code")}</h6>
                                            </div>
                                            <div className="col-1 mt-2" />
                                            <div className="col-11 mt-2">
                                                <SearchSelect onChange={this.handleChange}
                                                    value={this.state.setting_detail.payment_channel.bank.bank_payment.accountNumberId}
                                                    name="setting_detail.payment_channel.bank.bank_payment.accountNumberId"
                                                    placeholder={i18next.t("settingCollectionDashBoard:Select the bank code")}
                                                    queryObject={this.state.bankAccount}
                                                    keyOfValue="id"
                                                    require={true}
                                                    disabled={!this.state.permissionEdit}
                                                    keyOfLabel="docNumber"/>
                                            </div>
                                            <div className="col-1 mt-3" />
                                            <div className="col-11 mt-3">
                                                <h6>{i18next.t("settingCollectionDashBoard:Account number")}</h6>
                                            </div>
                                            <div className="col-1 mt-2" />
                                            <div className="col-11 mt-2">
                                                <input type="text" 
                                                    className="form-control"
                                                    name={"setting_detail.payment_channel.bank.bank_payment.accountNumber"}
                                                    id="setting_detail.payment_channel.bank.bank_payment.accountNumber" 
                                                    value={this.state.setting_detail.payment_channel.bank.bank_payment.accountNumber} 
                                                    onChange={this.handleChange}
                                                    disabled={true} 
                                                />
                                            </div >
                                            <div className="col-1 mt-3" />
                                            <div className="col-11 mt-3">
                                                <h6>{i18next.t("settingCollectionDashBoard:Bank")}</h6>
                                            </div>
                                            <div className="col-1 mt-2" />
                                            <div className="col-11 mt-2">
                                                <input type="text" 
                                                    className="form-control" 
                                                    disabled={true} 
                                                    id="setting_detail.payment_channel.bank.bank_payment.bankName" 
                                                    value={this.state.setting_detail.payment_channel.bank.bank_payment.bankName}
                                                    name={"setting_detail.payment_channel.bank.bank_payment.bankName"}
                                                    onChange={this.handleChange} />
                                            </div >
                                            <div className="col-1 mt-3" />
                                            <div className="col-11 mt-3">
                                                <h6>{i18next.t("settingCollectionDashBoard:Branch")}</h6>
                                            </div>
                                            <div className="col-1 mt-2" />
                                            <div className="col-11 mt-2">
                                                <input type="text" 
                                                    className="form-control" 
                                                    disabled={true} 
                                                    id="setting_detail.payment_channel.bank.bank_payment.branch" 
                                                    value={this.state.setting_detail.payment_channel.bank.bank_payment.branch}
                                                    name={"setting_detail.payment_channel.bank.bank_payment.branch"}
                                                    onChange={this.handleChange} />
                                            </div >
                                            <div className="col-1 mt-3" />
                                            <div className="col-11 mt-3">
                                                <h6>{i18next.t("settingCollectionDashBoard:Account Name")+" "+"(TH)"}</h6>
                                            </div>
                                            <div className="col-1 mt-2" />
                                            <div className="col-11 mt-2">
                                                <input type="text" 
                                                    className="form-control" 
                                                    disabled={true} 
                                                    id="setting_detail.payment_channel.bank.bank_payment.accountName" 
                                                    value={this.state.setting_detail.payment_channel.bank.bank_payment.accountName}
                                                    name={"setting_detail.payment_channel.bank.bank_payment.accountName"}
                                                    onChange={this.handleChange} />
                                            </div >
                                            <div className="col-1 mt-3" />
                                            <div className="col-11 mt-3">
                                            <h6>{i18next.t("settingCollectionDashBoard:Account Name")+" "+"(EN)"}</h6>
                                            </div>
                                            <div className="col-1 mt-2" />
                                            <div className="col-11 mt-2">
                                                <input type="text" 
                                                    className="form-control" 
                                                    disabled={true} 
                                                    id="setting_detail.payment_channel.bank.bank_payment.accountNameEn" 
                                                    value={this.state.setting_detail.payment_channel.bank.bank_payment.accountNameEn}
                                                    name={"setting_detail.payment_channel.bank.bank_payment.accountNameEn"}
                                                    onChange={this.handleChange} />
                                            </div >
                                        </div>
                                        <div className="row mt-3 mb-3">
                                            <div className="col-12 mt-3">
                                                <h5>{i18next.t("settingCollectionDashBoard:Description of submitting evidence (Pay-in Slip)")}</h5>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <input className="form-control"
                                                    name={"setting_detail.payment_channel.channelDescription"}
                                                    value={this.state.setting_detail.payment_channel.channelDescription} 
                                                    onChange={this.handleChange}
                                                    placeholder="Line@ , Line , Email"
                                                    disabled={!this.state.permissionEdit}
                                                    />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 mt-3">
                                                <h5>{i18next.t("settingCollectionDashBoard:If you have any questions, please contact")}:</h5>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <h6>{i18next.t("settingCollectionDashBoard:Individual/Corporate Name")}</h6>
                                            </div>
                                            <div className="col-12 mt-1">
                                                <input className="form-control" type="text"
                                                    name={"setting_detail.contactName"}
                                                    value={this.state.setting_detail.contactName} 
                                                    onChange={this.handleChange}
                                                    placeholder={i18next.t("settingCollectionDashBoard:Individual/Corporate Name")}
                                                    disabled={!this.state.permissionEdit}
                                                />  
                                            </div>
                                            <div className="col-12 mt-2">
                                                <h6>{i18next.t("settingCollectionDashBoard:Phone number")}</h6>
                                            </div>
                                            <div className="col-12 mt-1">
                                                <input className="form-control" type="text" pattern="\d*"
                                                    name={"setting_detail.contactPhone"}
                                                    value={this.state.setting_detail.contactPhone} 
                                                    onChange={this.handleChange}
                                                    placeholder={i18next.t("settingCollectionDashBoard:Corporate phone number")}
                                                    disabled={!this.state.permissionEdit}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 mt-3">
                                                <h5>{i18next.t("settingCollectionDashBoard:Signatory")}</h5>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <h6>{i18next.t("settingCollectionDashBoard:Signatory Name")}</h6>
                                            </div>
                                            <div className="col-12 mt-1">
                                                <input className="form-control" type="text"
                                                        name={"setting_detail.signerName"}
                                                        value={this.state.setting_detail.signerName} 
                                                        onChange={this.handleChange}
                                                        placeholder={i18next.t("settingCollectionDashBoard:Signatory Name")}
                                                        disabled={!this.state.permissionEdit}
                                                />  
                                            </div>
                                            <div className="col-12 mt-2">
                                                <h6>{i18next.t("settingCollectionDashBoard:Signatory Position")}</h6>
                                            </div>
                                            <div className="col-12 mt-1">
                                                <input  className="form-control" type="text" width={'100%'} 
                                                    name={"setting_detail.signerPosition"}
                                                    value={this.state.setting_detail.signerPosition} 
                                                    onChange={this.handleChange}
                                                    placeholder={i18next.t("settingCollectionDashBoard:Signatory Position")}
                                                    disabled={!this.state.permissionEdit}
                                                />
                                            </div>
                                            {/* เอกสารแนบ */}
                                            <div className="col-12 mt-2">
                                                <h6>{i18next.t("settingCollectionDashBoard:Attachments (other documents)")}
                                                    <span className="tooltipImg">
                                                        <img src={process.env.PUBLIC_URL + "/images/tooltip/light.png"} alt="light" className="light ml-1"/>
                                                        <span className="tooltiptext">
                                                        {i18next.t("settingCollectionDashBoard:The attached documents will be displayed in")}<br/>
                                                        {i18next.t("settingCollectionDashBoard:that particular debt collection letter.")}
                                                        </span>
                                                        <span className="tooltipTriangle">
                                                        <img src={process.env.PUBLIC_URL + '/images/tooltip/tooltipTriangle.png'} alt="tooltipTriangle"/>
                                                        </span>
                                                    </span>
                                                </h6>
                                            </div>
                                            <div className="col-12 mt-1">
                                                <input  type="checkbox"
                                                        name="setting_detail.refReport"
                                                        checked={this.state.setting_detail.refReport}
                                                        onChange={this.toggle_checkbox} 
                                                        disabled={!this.state.permissionEdit}
                                                /> 
                                                <span className='ml-5'>{i18next.t("settingCollectionDashBoard:Detailed Outstanding Debts Report")}</span>
                                            </div>
                                            {this.props.formDebit >=2 &&
                                                <div className="col-12 mt-1">
                                                    <input  type="checkbox"
                                                            name="setting_detail.refCollection1"
                                                            checked={this.state.setting_detail.refCollection1}
                                                            onChange={this.toggle_checkbox} 
                                                            disabled={!this.state.permissionEdit}
                                                    /> 
                                                    <span className='ml-5'>{i18next.t("settingCollectionDashBoard:Debt Collection Letter No.")} 1</span>
                                                </div> 
                                            }
                                             {this.props.formDebit >=3 &&
                                                <div className="col-12 mt-1">
                                                    <input  type="checkbox"
                                                            name="setting_detail.refCollection2"
                                                            checked={this.state.setting_detail.refCollection2}
                                                            onChange={this.toggle_checkbox} 
                                                            disabled={!this.state.permissionEdit}
                                                    /> 
                                                    <span className='ml-5'>{i18next.t("settingCollectionDashBoard:Debt Collection Letter No.")} 2</span>
                                                </div> 
                                            }
                                            {this.props.formDebit >=4 &&
                                                <div className="col-12 mt-1">
                                                    <input  type="checkbox"
                                                            name="setting_detail.refCollection3"
                                                            checked={this.state.setting_detail.refCollection3}
                                                            onChange={this.toggle_checkbox} 
                                                            disabled={!this.state.permissionEdit}
                                                    /> 
                                                    <span className='ml-5'>{i18next.t("settingCollectionDashBoard:Debt Collection Letter No.")} 3</span>
                                                </div> 
                                            }
                                            <div className="col-12 mt-1">
                                                <input  type="checkbox"
                                                        name="setting_detail.refDocument"
                                                        checked={this.state.setting_detail.refDocument}
                                                        onChange={this.toggle_checkbox} 
                                                        disabled={!this.state.permissionEdit}
                                                /> 
                                                <span className='ml-5'>{i18next.t("settingCollectionDashBoard:Other attached documents")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {this.state.formDebit !== 1 && this.state.permissionEdit &&
                                            <div className="row">
                                                <button className="copyBtn" onClick={() => this.onCopyForm()}>
                                                    <span>{i18next.t("settingCollectionDashBoard:Copy data from time(s)")} 1</span>
                                                </button>
                                            </div>  
                                        }
                                        <SettingCollectionLetterView 
                                            selfProject={this.state.selfProject}
                                            example={true}
                                            setting_detail = {this.state.setting_detail}
                                            status = {this.props.status}
                                            formDebit = {this.props.formDebit}
                                            attactDocument = {this.state.attactDocument}
                                        />
                                    </div>
                                </div>
                                {this.state.permissionEdit && 
                                <div className="row" id="setting-collection">
                                    <div className="col-11 d-flex justify-content-end p-4">
                                        <button type="button" className="btn-reset mr-3" onClick={() => this.reset()}>{i18next.t("settingCollectionDashBoard:Reset All Data")}</button>
                                        <button type="button" className="btn-confirm" onClick={() => this.onSubmit()}>
                                            {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                            {this.state.firstTime ? i18next.t("Allaction:save") : i18next.t("settingCollectionDashBoard:Save Changes")}
                                        </button>
                                    </div>
                                    <div className="col-1"/>
                                </div>
                                }
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </Translation>

        );
    }



}export default SettingForm;