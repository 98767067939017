import React from 'react';
import Wrapper from "../../components/wrapper";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import _ from 'lodash'
import RevenueListTable from "./revenueListTable";
import { addMonths, differenceInCalendarMonths, differenceInCalendarDays, lastDayOfMonth } from "date-fns";
import ExpenditureListTable from "./expenditureListTable";
import numberWithComma from "../../libs/numberWithComma";
import SummaryListTable from "./summaryListTable"
import ExportAccrualBasis from "./serverExport";
import AccrualBasisChart from './accrualBasisChart';
import './accrualBasisCharts.scss'
import Loading from '../../libs/loading';
import BackButtonIcon from '../../components/BackBtn/indexBack';
import i18next from 'i18next';


const type_of_report = [
    {
        name: "2.1 ต้นทุนขายสุทธิ / Cost of Goods Sold",
        code: "5100",
        summary_name: "รวมรายจ่ายต้นทุนขายสุทธิ / Total Cost of Goods Sold",
        header: true
    },
    {
        name: "2.2 เงินเดือนเเละค่าจ้าง / Salaries and wages",
        code: "5200",
        summary_name: "รวมรายจ่ายเงินเดือนเเละค่าจ้าง / Total Salaries and wages",

    },
    {
        name: "2.3 รายจ่าย - ตามสัญญา / Expense - by agreement",
        code: "5300",
        summary_name: "รวมรายจ่าย - ตามสัญญา / Total Expense - by agreement",
    },
    {
        name: "2.4 รายจ่ายดำเนินงานและสำนักงาน / Operation expense - Office",
        code: "5400",
        summary_name: "รวมรายจ่ายดำเนินงานและสำนักงาน / Total Operation expense - Office",
    },
    {
        name: "2.5 รายจ่ายสาธารณูปโภค / Operation expense - Utility",
        code: "5500",
        summary_name: "รวมรายจ่ายสาธารณูปโภค / Total Operation expense - Utility",
    },
    {
        name: "2.6 รายจ่าย - ซ่อมเเซมและบำรุงรักษา / Operation expense - Maintenance & Repair",
        code: "5600",
        summary_name: "รวมรายจ่าย - ซ่อมเเซมและบำรุงรักษา / Total Operation expense - Maintenance & Repair",
    },
    {
        name: "2.7 รายจ่ายค่าเสื่อมราคา / Depreciation and Amortization expense",
        code: "5700",
        summary_name: "รวมรายจ่ายค่าเสื่อมราคา / Total Depreciation and Amortization expense",
    },
    {
        name: "2.8 ค่าใช้จ่ายอื่น ๆ / Other expenses",
        code: "5800",
        summary_name: "รวมรายจ่ายค่าใช้จ่ายอื่น ๆ / Total Other expenses",
    },
];
class AccrualBasis extends React.Component {
    constructor(props) {
        super(props);
        let now = new Date();
        this.state = {
            start_period_before: new Date(now.getFullYear(), 0),
            end_period_before: lastDayOfMonth(new Date(now.getFullYear(), 11)),
            start_period: new Date(now.getFullYear(), 0),
            end_period: lastDayOfMonth(new Date(now.getFullYear(), 11)),
            total_revenue: [],
            total_expenditure: [],
            summary_index: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            month_count: 11,
            loading: true,
            totalRevenueGenChart: [],
            totalExpenditureGenChart: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.updateSummary = this.updateSummary.bind(this);
        this.calDate = this.calDate.bind(this)
    }

    handleChange(event) {
        // if (event.target.name === 'start_period'){
        //     console.log("start_period")
        //      let dif_month = differenceInCalendarMonths(this.state.end_period, event.target.value);
        //      let dif_day = differenceInCalendarDays(this.state.end_period,event.target.value);

        //     if((dif_month < 0 && dif_month !== -0) || dif_month >= 12 || (dif_month === 0 && dif_day < 0)){
        //         this.setState({end_period: lastDayOfMonth(addMonths(event.target.value, 11)), month_count:11, summary_index: Array.from(new Array(13))})
        //     }else {
        //         this.setState({month_count: dif_month, summary_index: Array.from(new Array(dif_month+2))})
        //     }
        // }
        // if (event.target.name === 'end_period') {
        //     console.log("end_period");
        //     let dif = differenceInCalendarMonths(event.target.value, this.state.start_period);
        //     this.setState({month_count: dif, summary_index: Array.from(new Array(dif+2))})

        let value = event.target.value

        if (event.target.name === 'start_period_before') {
            let dif_month = differenceInCalendarMonths(this.state.end_period_before, event.target.value);
            if (dif_month > 11) {
                let date_end = event.target.value
                this.setState({
                    end_period_before: lastDayOfMonth(new Date(date_end.getFullYear(), 11)),
                })
            }
        }
        if (event.target.name === 'end_period_before') {
            value = lastDayOfMonth(new Date(value))
        }

        this.setState({
            [event.target.name]: event.target.value,
            // total_revenue: [],
            // total_expenditure: [],
        });
    }

    componentWillMount() {
        this.calDate()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.total_revenue.length !== 0 && this.state.total_expenditure.length !== 0) {
            if (prevState.total_revenue !== this.state.total_revenue || prevState.total_expenditure !== this.state.total_expenditure) {
                this.setState({
                    totalRevenueGenChart: this.state.total_revenue,
                    totalExpenditureGenChart: this.state.total_expenditure,
                });
            }
        }
    }

    calDate() {
        this.setState({
            loading: true,
        })

        let dif_month = differenceInCalendarMonths(this.state.end_period_before, this.state.start_period_before);
        let dif_day = differenceInCalendarDays(this.state.end_period_before, this.state.start_period_before);

        if ((dif_month < 0 && dif_month !== -0) || dif_month >= 12 || (dif_month === 0 && dif_day < 0)) {
            this.setState({
                start_period: this.state.start_period_before,
                end_period: lastDayOfMonth(this.state.end_period_before),
                month_count: 11,
                summary_index: Array.from(new Array(13)),
                loading: false,
                total_expenditure: [],
                total_revenue: [],
            });
        } else {
            this.setState({
                start_period: this.state.start_period_before,
                end_period: lastDayOfMonth(this.state.end_period_before),
                month_count: dif_month,
                summary_index: Array.from(new Array(dif_month + 2)),
                loading: false,
                total_expenditure: [],
                total_revenue: [],
            });
        }
    }

    updateSummary(name, value) {
        if (name === 'total_expenditure') {
            let total = [...this.state.total_expenditure];
            value.summary.map((summary, index) => (
                _.set(total, index, _.get(total, index, 0) + summary)
            ));
            this.setState({ [name]: total })

        } else {
            this.setState({ [name]: value.summary })
        }

    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack={"/accounting"}
                                    LinkText={i18next.t('accounting:accrued revenue expenditure')}
                                    boxHtmlText={
                                        <div className="col">
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_accrued_revenue_expenditure_print' }) &&
                                                <ExportAccrualBasis state={this.state} />
                                            }
                                        </div>
                                    }
                                />

                                <div className="content-inner">
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <div className="form-inline float-left">
                                                <div className="form-group mb-2">
                                                    <label htmlFor="start_period" className="ml-2 mr-3">{i18next.t("AllFilter:Date")}</label>
                                                    <DatePickerAdapter id="start_period_before"
                                                        className="form-control" name="start_period_before"
                                                        selected={this.state.start_period_before}
                                                        onChange={this.handleChange}
                                                        showMonthYearPicker={true}
                                                        selectsStart={true}
                                                        dateFormat={"MM/yyyy"}
                                                        startDate={this.state.start_period_before}
                                                        endDate={this.state.end_period_before}
                                                    />
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label htmlFor="end_period" className="ml-3 mr-3">{i18next.t("AllFilter:to")}</label>
                                                    <DatePickerAdapter id="end_period_before" name="end_period_before"
                                                        className="form-control"
                                                        selected={this.state.end_period_before}
                                                        onChange={this.handleChange}
                                                        maxDate={lastDayOfMonth(addMonths(this.state.start_period_before, 11))}
                                                        minDate={this.state.start_period_before}
                                                        showMonthYearPicker={true}
                                                        selectsEnd={true}
                                                        dateFormat={"MM/yyyy"}
                                                        startDate={this.state.start_period_before}
                                                        endDate={this.state.end_period_before}
                                                    />
                                                </div>
                                                <div className="form-group ml-2 mb-2">
                                                    <button type="button"
                                                        className="btn btn-primary form-control"
                                                        name="search"
                                                        onClick={() => this.calDate()
                                                        }
                                                    >{i18next.t("AllFilter:Search")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            {
                                                this.state.loading ?
                                                    <Loading />
                                                    :
                                                    <div className="table-responsive fade-up card">
                                                        <table className="table table-bordered small">

                                                            <RevenueListTable
                                                                state={this.state}
                                                                updateSummary={this.updateSummary}
                                                            />

                                                            {type_of_report.map((expenditure) => (
                                                                <ExpenditureListTable
                                                                    state={this.state}
                                                                    typeOfReport={expenditure}
                                                                    key={expenditure.code}
                                                                    updateSummary={this.updateSummary}
                                                                />
                                                            ))}

                                                            <tbody>

                                                                <SummaryListTable
                                                                    text_summary={'รวมรายจ่ายทั้งสิ้น / Total Expenditure'}
                                                                    list_data_loop={this.state.summary_index}
                                                                    summary={this.state.total_expenditure}
                                                                />

                                                                <tr>
                                                                    <td width={360}><strong>รายรับสูง (ต่ำ) กว่ารายจ่ายสุทธิ / Balance Surplus (Deficit)</strong></td>
                                                                    {this.state.summary_index.map((value, index) => {
                                                                        let summary = parseFloat(this.state.total_revenue[index] || 0) - parseFloat(this.state.total_expenditure[index] || 0);
                                                                        if (summary < 0) {
                                                                            return (
                                                                                <td width={100} key={_.random(0, 1000000)}
                                                                                    className="text-right" style={{ color: 'red' }}>
                                                                                    <strong>({numberWithComma(Math.abs(summary))})</strong>
                                                                                </td>
                                                                            );

                                                                        } else {
                                                                            return (
                                                                                <td width={100} key={_.random(0, 1000000)}
                                                                                    className="text-right">
                                                                                    <strong>{numberWithComma(summary)}</strong>
                                                                                </td>
                                                                            );
                                                                        }
                                                                    })
                                                                    }
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                            }


                                        </div>
                                    </div>

                                    <div>
                                        <AccrualBasisChart
                                            totalRevenue={this.state.totalRevenueGenChart}
                                            totalExpenditure={this.state.totalExpenditureGenChart}
                                            index={this.state.summary_index}
                                            startPeriod={this.state.start_period}
                                            endPeriod={this.state.end_period}
                                        />
                                    </div>
                                </div>
                            </div>

                        }
                    </Translation>

                </WrapperContent>
            </Wrapper>
        )
    }
}

export default AccrualBasis;
