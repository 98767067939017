import React, { Component } from 'react';
import Swal from "sweetalert2";
import i18n from "i18next";
import {  withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import { encode, decode } from 'base-64';
import APiCliam from "../../../../api/claim"
import _ from 'lodash';
import i18next from 'i18next';

class SummarizeClaimPending extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingDetails: true,
            selectedBtn: "",
            selectedBtnType: "",
            // status: decode(this.props.match.params.status),
            // new = bmV3
            // consider_fail = Y29uc2lkZXJfZmFpbA==
            // pending_approve = cGVuZGluZ19hcHByb3Zl
            // not_approve = bm90X2FwcHJvdmU=
            // approve = YXBwcm92ZQ==
            // finish = ZmluaXNo

            file: [],
            deTailProperty: [],
            isamountToClaim: 1,
            amountToClaim: null,
            amountToClaimApprove: '',
            doc_name: "", 
        }
        this.selectedBtn = this.selectedBtn.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
    }
    componentWillMount() { 
        let id = decode(this.props?.id) || ""
        APiCliam.GetCliamID(id).then((res) => {
            this.setState({
                amountToClaim: res.data.claim.claim_detail.claim_amount,
                doc_name: res.data.claim.claim_detail.doc_number, 
            })
        })

    }
    selectedBtn(e) {
        let type = e
        let selectedBtn = _.cloneDeep(this.state.selectedBtn)
        if (selectedBtn !== type) {
            document.getElementById("selectedBtn" + type).classList.remove("bg-E9ECEF")
            document.getElementById("selectedBtn" + type).classList.add("bg-1983E8")
            if (selectedBtn) {
                document.getElementById("selectedBtn" + selectedBtn).classList.remove("bg-1983E8")
                document.getElementById("selectedBtn" + selectedBtn).classList.add("bg-E9ECEF")
            }
            this.setState({ selectedBtn: type })
        }
    }


    render() {
        let imageRadioTrue = "/images/iconAction/radioTrue.png"
        let imageRadioFalse = "/images/iconAction/radioFalse.png"
   
        return (
            <Translation>
                {t=>
                    <form onSubmit={this.onSubmit}>
                        <div className='ResultClaim mt-10'>
                            <label className='headerLabel28'>{t("claimRequst:Claims Summary")}</label>
                            <hr /> <br />
                            <label className='headerLabel18'>{t("claimRequst:Amount To Claim")} <span className='text-red'> * </span> {t("claimRequst:(Bath)")}</label>
                            <br />
                            <input type={"number"} name='amountCliam'
                                defaultValue={this.state.amountToClaim ? parseInt(this.state.amountToClaim).toFixed(2) : ""}
                                placeholder='0.00'
                                className='form-control inputBox text-right' style={{ width: 222 }}
                                // onKeyUp={(e) => this.setState({ amountToClaim: e.target.value })}
                                // onChange={(e) => this.setState({ amountToClaim: e.target.value })}
                                // required={true} 
                                readOnly
                            />
                            <br />
                            <div className='row'>
                                <div className='col-4'>
                                    <label className='headerLabel18'>{t("claimRequst:ChooseStatus")}</label>
                                    <br />
                                    <button id="selectedBtn1" type='button' className='btn btn-outline bg-E9ECEF m-2'
                                        style={{ width: 160 }}
                                        onClick={() => this.selectedBtn(1)}>
                                    {t("claimRequst:ApproveInsurrance")} </button>
                                    <br />
                                    <button id="selectedBtn2" type='button' className='btn btn-outline bg-E9ECEF m-2'
                                        style={{ width: 160 }}
                                        onClick={() => this.selectedBtn(2)}>{t("claimRequst:NotApproveInsurrance")}</button>
                                </div>

                                {
                                    this.state.selectedBtn === 1 &&
                                    <div className='col-8 border-left'>
                                        <label className='headerLabel18 mb-5'>{t("claimRequst:RefillAmountApprove")}</label>
                                        <div className='row ml-1 mb-5'>
                                            <div className='col-5 p-2' >
                                                <input type={'radio'} name={"isamountToClaim"} value={1} onChange={() => this.setState({ isamountToClaim: 1 })}
                                                    checked={this.state.isamountToClaim === 1} />
                                                <span className='pl-3'> {t("claimRequst:ApproveEqualToTheAmountRequested")}</span>
                                            </div>
                                        </div>
                                        <div className='row ml-1 mb-5'>
                                            <div className='col-5 p-2'>
                                                <input type={'radio'} name={"isamountToClaim"} value={2} onChange={() => this.setState({ isamountToClaim: 2 })}
                                                    checked={this.state.isamountToClaim === 2} />
                                                <span className='pl-3'> {t("claimRequst:ApproveMoreToTheAmountRequested")}</span>
                                            </div>
                                            <div className='col-5'>
                                                <input type={"number"} name='' className='form-control' disabled={this.state.isamountToClaim !== 2}
                                                    onChange={(e) => this.setState({ amountToClaimApprove: e.target.value })}
                                                    min={parseInt(this.state.amountToClaim)} />
                                            </div>
                                        </div>
                                        <div className='row ml-1 mb-5'>
                                            <div className='col-5 p-2'>
                                                <input type={'radio'} name={"isamountToClaim"} value={3} onChange={() => this.setState({ isamountToClaim: 3 })}
                                                    checked={this.state.isamountToClaim === 3} />
                                                <span className='pl-3'> {t("claimRequst:ApproveLessToTheAmountRequested")}</span>
                                            </div>
                                            <div className='col-5'>
                                                <input type={"number"} name='' className='form-control' disabled={this.state.isamountToClaim !== 3}
                                                    max={parseInt(this.state.amountToClaim)} min={0}
                                                    onChange={(e) => this.setState({ amountToClaimApprove: e.target.value })} />
                                            </div>

                                        </div>
                                    </div>

                                }

                            </div>
                        </div>



                        <div className='row mt-2'>
                            <div className='col-12 text-right'>
                                {
                                    this.state.selectedBtn !== "" ?
                                        <button type='submit' className='btn btn-primary m-2'
                                        // onClick={() => this.props.history.push("/purchasing/claim/summarize/MQ==/YXBwcm92ZQ==")} 
                                        >
                                            {t("claimRequst:SaveAndNotify")}</button>
                                        :
                                        <button type='button' disabled className='btn btn-outline bg-BFBFBF m-2'>
                                            {t("claimRequst:SaveAndNotify")}</button>
                                }

                                {/* <button type='submit' className='btn btn-primary m-2'>{"บันทึก และส่งแจ้งเตือนค่าสินไหมให้ลูกบ้าน"}</button> */}
                            </div>
                        </div>


                    </form>
                }
            </Translation>
        )
    }

    onSubmit(e) {
        e.preventDefault();   

        if (this.state.selectedBtn === 2) {
            let data = {
                "status": "not_approve" 
            }
            Swal.fire({
                title: i18next.t("Allaction:This will only take a moment, please wait"),
                type: 'info',
                showLoaderOnConfirm: true,
                showCloseButton: false,
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
                allowOutsideClick: () => !Swal.isLoading(),
            }).then(() => {
                APiCliam.UpdateCliam(data, decode(this.props.id)).then((res) => {
                    if (res.data.statusCode === "0000") {
                        Swal.fire(i18n.t('claimAction:Void'), '', 'success').then(() => { 
                            this.props.history.push(`/purchasing/claim/summarize/${this.props.id}/${encode("not_approve")}`)
                        })
                    } else {
                        Swal.fire(i18n.t('settingAccount:Failed to save', "", "warning"))
                    }
                })
            })
        } else if (this.state.selectedBtn === 1) {
            let data = {
                "status": "approve",
                "approved_amount":
                    this.state.isamountToClaim === 1 ? parseInt(this.state.amountToClaim) : parseInt(this.state.amountToClaimApprove)
            }

            APiCliam.UpdateCliam(data, decode(this.props.id)).then((res) => {
                if (res.data.statusCode === "0000") {
                    Swal.fire(i18n.t('claimAction:Alerted'), '', 'success').then(() => {

                        this.props.history.push(`/purchasing/claim/summarize/${this.props.id}/${encode("approve")}`) 
                    })
                } else {
                    Swal.fire(i18n.t('settingAccount:Failed to save', "", "warning"))
                }
            })


        }


    }
}

export default withRouter(SummarizeClaimPending);
