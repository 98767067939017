
import "../../accounting/budget/styles/createBudget.scss";
import "react-datepicker/dist/react-datepicker.css";
import React, { forwardRef, } from 'react';
import { format } from "date-fns";
import { B1 } from '../../components/Text/Text';

const ButtonYear = forwardRef(({ value, onClick, disabled }, ref) => (
    <button type="button" className="date form-control booking-form" style={{ width: "100%", height: 48 }} onClick={onClick} ref={ref} disabled={disabled}>
        <B1 className='text-left' >{format(value, 'YYYY')}</B1>
        <div
            className="calendars"
            style={{ position: "absolute", right: 0, marginRight: 8, top: '28%' }}>
            <img className="icon" src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"} alt="calendars" />
        </div>

    </button >
));

const ButtonDate = forwardRef(({ onClick, disabled, date }, ref) => (
    <button type="button" className="date form-control booking-form" style={{ width: 125, height: 48 }} onClick={onClick} ref={ref} disabled={disabled}>
        <B1 className='text-center' >{date ? format(date, 'DD/MM/YYYY') : ''}</B1>
    </button >
));

const ButtonTime = forwardRef(({ onClick, disabled, timer }, ref) => {
    return (
        <button type="button" className="date form-control booking-form" style={{ width: 125, height: 48 }} onClick={onClick} ref={ref} disabled={disabled}>
            <B1 className='text-center' >{timer ? format(timer, 'HH:mm') : ''}</B1>
        </button >
    )
});

export { ButtonDate, ButtonTime, ButtonYear }