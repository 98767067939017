import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import Navigation from "./navigationAdvance";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Translation } from "react-i18next";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import AdvanceListTable from "./advanceListTable";
import ClearAdvanceListTable from "./clearAdvanceListTable";
import _ from "lodash";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import i18next from "i18next";

class AdvanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      end_date: null,
      temp_start_date: null,
      temp_end_date: null,
      search: "",
      search_input: "",
      all_set_petty_cash_list: [],
      selected_set_petty_cash: "",
      reQuery: false,
      advance_search: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box" id="receive-list">
                <BackButtonIcon
                  LinkBack={"/accounting"}
                  LinkText={i18next.t("accounting:advance")+" "+"(AD/CAD)"}
                  boxHtmlText={
                    <div className="col text-right">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "accounting_advance_create",
                      }) && (
                          <React.Fragment>
                            {this.props.match.params.page === "withdraw" && (
                              <React.Fragment>
                                <Link to="/accounting/expense/advance/withdraw">
                                  <button
                                    type="button"
                                    className="btn btn-primary add float-right"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/icons/plus.png"
                                      }
                                      alt="plus"
                                    />
                                    <Translation>
                                      {(t) => (
                                        <span>{t("accounting:add_advance")}</span>
                                      )}
                                    </Translation>
                                  </button>
                                </Link>
                              </React.Fragment>
                            )}
                            {this.props.match.params.page === "clear-advance" && (
                              <React.Fragment>
                                <Link to="/accounting/expense/advance/clear-advance/create">
                                  <button
                                    type="button"
                                    className="btn btn-primary add float-right"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/icons/plus.png"
                                      }
                                      alt="plus"
                                    />
                                    <Translation>
                                      {(t) => (
                                        <span>{t("accounting:clear_advance")}</span>
                                      )}
                                    </Translation>
                                  </button>
                                </Link>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                    </div>
                  }
                />

                <div className="content-inner">
                  <Navigation />
                  <div className="row mb-2 mt-1">
                    <div className="col-12 text-right">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          value={this.state.search_input}
                          name="search"
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>
                      {/* <button
                    type="button"
                    className="btn btn-outline-secondary float-right"
                    onClick={() =>
                      this.setState({
                        advance_search: !this.state.advance_search,
                      })
                    }
                  >
                    <img
                      src="/images/icons/filter-icon.png"
                      alt="filter-icon"
                    />
                  </button> */}
                      <InlineAdvanceFilter
                        start_date={this.state.temp_start_date}
                        end_date={this.state.temp_end_date}
                        handleChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      {this.props.match.params.page === "withdraw" && (
                        <AdvanceListTable
                          search={this.state.search}
                          start_date={this.state.start_date}
                          end_date={this.state.end_date}
                        />
                      )}

                      {this.props.match.params.page === "clear-advance" && (
                        <ClearAdvanceListTable
                          search={this.state.search}
                          start_date={this.state.start_date}
                          end_date={this.state.end_date}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default AdvanceList;
