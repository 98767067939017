import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import "./fixRequestDashBroad.scss";
import i18next from 'i18next';

class FixRequestStatusAndTypechart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      loading: false,
      checkOnline: true,
    };
  }

  componentDidMount() {
    this.genChartStatus();
    this.genChartType();
  }

  genChartStatus() {
    if (this.root) {
      this.root.dispose();
      this.chart.dispose();
    }
    let dataBeforeSort = this.props.dataStatus;
    let data = dataBeforeSort.sort((a, b) => b.times - a.times);    

    const dataToPlot = [];
    for (const item of data) {
      const translatedCategory = i18next.t(`fix_request_dashbroad:${item.categories}`);
      dataToPlot.push({ categories: translatedCategory, times: item.times });
    }

    let root = am5.Root.new("fixRequestStatusChart");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
        radius: am5.percent(75),
        innerRadius: am5.percent(60)
      })
    );
    
    chart.children.unshift(am5.Label.new(root, {
        text: `${i18next.t("fix_request:Status of repair work")} (${i18next.t("fix_request_dashbroad:Job")})`,
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'kanit',
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0
      }));

      let series = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: "Series",
          valueField: "times",
          categoryField: "categories"
        })
      );

      series.slices.template.setAll({
        tooltipHTML: `<b style="color: black;">{category}: {value.formatNumber('#,###')} งาน</b>`,
        strokeWidth: 2,
      });
      
      series.labels.template.set("forceHidden", true);
      series.ticks.template.set("forceHidden", true);
      
      let label = series.children.push(am5.Label.new(root, {
        text: `{valueSum.formatNumber('#,###.')} ${i18next.t("fix_request:items")}`,
        fontSize: 25,
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        populateText: true,
        oversizedBehavior: "fit"
      }));
      
      series.onPrivate("width", function(width) {
        label.set("maxWidth", width * 0.7);
      });
      
      series.data.setAll(dataToPlot);
      
      series.onPrivate("valueSum", function() {
        label.text.markDirtyText();
      }); 

    // Create legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      })
    );

    legend.data.setAll(series.dataItems);
    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: dataToPlot,
      numericFields: ["จำนวนงาน"],
      numberFormat: "#,###",
      textFields: {
          text: {
            field: "สถานะของงาน",
          }
        },
      dataFields: {
        categories: "สถานะของงาน",
        times: "จำนวนงาน",
      },
      dataFieldsOrder: ["categories","times"],
    });
    let annotator = am5plugins_exporting.Annotator.new(root, {});

    exporting.get("menu").set("items", [
      {
        type: "format",
        format: "jpg",
        label: "Export Image",
      },
      {
        type: "format",
        format: "print",
        label: "Print",
      },
      {
        type: "format",
        format: "xlsx",
        label: "Export Excel",
      },
      {
        type: "separator"
      },
      {
        type: "custom",
        label: "Remark",
        callback: function () {
          this.close();
          annotator.toggle();
        },
      },
    ]);
    chart.appear(1000, 100);
    root._logo.dispose();

    this.chart = chart;
    this.root = root;
  }
  genChartType() {
    if (this.root2) {
      this.root2.dispose();
      this.chart.dispose();
    }

    let dataBeforeSort = this.props.dataFixWorkingType;
    let data = dataBeforeSort.sort((a, b) => b.times - a.times);  

    let root2 = am5.Root.new("fixRequestTypeChart");

    root2.setThemes([am5themes_Animated.new(root2)]);

    let chart = root2.container.children.push(
      am5percent.PieChart.new(root2, {
        layout: root2.verticalLayout,
        // radius: 120,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
        radius: am5.percent(75),
        innerRadius: am5.percent(60)
      })
    );

    chart.children.unshift(am5.Label.new(root2, {
        text: `${i18next.t("fix_request:Type of repair work")} (${i18next.t("fix_request_dashbroad:Job")})`,
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'kanit',
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0
      }));
      
      let series = chart.series.push(
        am5percent.PieSeries.new(root2, {
          name: "Series",
          valueField: "times",
          categoryField: "categories"
        })
      );



      series.slices.template.setAll({
        tooltipHTML: `<b style="color: black;">{category}: {value.formatNumber('#,###')} งาน</b>`,
        strokeWidth: 2,
      });
      
      series.labels.template.set("forceHidden", true);
      series.ticks.template.set("forceHidden", true);
      
      let label = series.children.push(am5.Label.new(root2, {
        text: `{valueSum.formatNumber('#,###.')} ${i18next.t("fix_request:items")}`,
        fontSize: 25,
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        populateText: true,
        oversizedBehavior: "fit"
      }));
      
      series.onPrivate("width", function(width) {
        label.set("maxWidth", width * 0.7);
      });
      
      series.data.setAll(data);
      
      series.onPrivate("valueSum", function() {
        label.text.markDirtyText();
      }); 


    // Create legend
    let legend = chart.children.push(
      am5.Legend.new(root2, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      })
    );

    legend.data.setAll(series.dataItems);
    let exporting = am5plugins_exporting.Exporting.new(root2, {
      menu: am5plugins_exporting.ExportingMenu.new(root2, {}),
      dataSource: data,
      numericFields: ["จำนวนงาน"],
      numberFormat: "#,###",
      textFields: {
          text: {
            field: "ชนิดของงานแจ้งซ่อม",
          }
        },
      dataFields: {
        categories: "ชนิดของงานแจ้งซ่อม",
        times: "จำนวนงาน",
      },
      dataFieldsOrder: ["categories","times"],
    });
    let annotator = am5plugins_exporting.Annotator.new(root2, {});

    exporting.get("menu").set("items", [
      {
        type: "format",
        format: "jpg",
        label: "Export Image",
      },
      {
        type: "format",
        format: "print",
        label: "Print",
      },
      {
        type: "format",
        format: "xlsx",
        label: "Export Excel",
      },
      {
        type: "separator"
      },
      {
        type: "custom",
        label: "Remark",
        callback: function () {
          this.close();
          annotator.toggle();
        },
      },
    ]);

    chart.appear(1000, 100);
    root2._logo.dispose();

    this.chart = chart;
    this.root2 = root2;
  }
  genChart() {
    if (this.root3) {
      this.root3.dispose();
      this.chart.dispose();
    }

    let root3 = am5.Root.new("fixRequestBarChart");

    root3.setThemes([am5themes_Animated.new(root3)]);

    let chart = root3.container.children.push(
      am5xy.XYChart.new(root3, {
        panX: false,
        panY: false,
        layout: root3.verticalLayout,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 40,
        paddingLeft: 30,
      })
    );

    chart.children.unshift(
        am5.Label.new(root3, {
          text: `${i18next.t("fix_request:Number of repairs completed by person")}`,
          fontSize: 16,
          fontWeight: 600,
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 40,
        })
      );

    // Add legend
    let legend = chart.children.push(
      am5.Legend.new(root3, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );
  
    let data = [this.props.dataFixProjectStaff];

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root3, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root3, {
        categoryField: "date",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root3, {}),
      })
    );

    xRenderer.labels.template.set('visible', false)
    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root3, {
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root3, {
          strokeOpacity: 0.1,
        }),
      })
    );

    // Add series
    function makeSeries(name, fieldName) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root3, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "date",
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.data.setAll(data);

      // Make stuff animate on load
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root3, {
          locationY: 0,
          sprite: am5.Label.new(root3, {
            text: "{valueY}",
            fill: root3.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }

    for (const key in data[0]) {
      if (key !== 'date') {
        data[0][key] >0 && makeSeries(key, key);
      }
    }

    chart.appear(1000, 100);
    root3._logo.dispose();

    this.chart = chart;
    this.root3 = root3;
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
    if (this.root2) {
      this.root2.dispose();
    }
    if (this.root3) {
      this.root3.dispose();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="fixRequestPieChartContainer">
            <div className="divContainer">
                <div id="fixRequestStatusChart"></div>
            </div>
            <div className="divContainer">
                <div id="fixRequestTypeChart"></div>
            </div>
        </div>
       
      </React.Fragment>
    );
  }
}

export default FixRequestStatusAndTypechart;
