import React from "react";
import { Translation } from "react-i18next";
import "../../styles/commonArea.scss";
import iconBooking from "./iconBooking";
const Accessories = ({
  state: { equipment_status, equipment = [] },
  handleChange,
  addEquipment,
}) => {
  return (
    <Translation>
      {t =>
        <div className="booking-form mb-5  mt-10 ">
          <div className="switch-languages-div pb-2 ">
            <h2>{t("newBookingSingha_Create_step1:Room accessories")}</h2>
          </div>
          <div className="mt-4 ">
            <div className="claim">
              <div className="create btnSwitch">
                <label className="switch mt-0 mr-3" style={{ width: 43, height: 20 }}>
                  <input type="checkbox"
                    name="equipment_status"
                    defaultChecked={equipment_status}
                    onChange={handleChange}
                  />
                  <span className={equipment_status ? "sliderNew round selected" : "sliderNew round"}></span>
                </label>
                <label className="headerLabel18 fw-400">{t("newBookingSingha_Create_step1:Display the list of accessories")}</label>
              </div>
            </div>
            {
              equipment_status &&
              <React.Fragment>
                <div className="mt-3 d-flex">
                  <h3>{t("newBookingSingha_Create_step1:Accessories list")}</h3>
                </div>

                <div className="row mt-3">
                  <div className="col-6"> <label>{t("newBookingSingha_Create_step1:Accessory name")}</label> </div>
                  <div className="col-2"> <label>{t("newBookingSingha_Create_step1:Quantity")}</label> </div>
                </div>
                {equipment?.map((item, index) => {
                  return (
                    <div className="form-row mt-3 " key={index}>
                      <div className="col-6">
                        <input
                          disabled={equipment_status === "true" && true}
                          type="text"
                          className="form-control"
                          placeholder={t("newBookingSingha_Create_step1:Accessory name")}
                          id={`equipment`}
                          name={`equipment`}
                          value={item.equipment}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                      <div className="col-2">
                        <input
                          disabled={equipment_status === "true" && true}
                          type="number"
                          className="form-control"
                          placeholder={t("newBookingSingha_Create_step1:Quantity")}
                          id={`unit_items`}
                          name={`unit_items`}
                          value={item.unit_items}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                      <div onClick={() => addEquipment("remove", index)} className="col align-self-end pb-3 ml-4" >
                        <iconBooking.iconDelte />
                        {/* <img
                          src={process.env.PUBLIC_URL + "/images/icons/icon_delete.png"}
                          alt="delete"
                          className="delete"
                        /> */}
                      </div>
                    </div>
                  );
                })}
                <div className="mt-3">
                  <button
                    type="button"
                    className="btn btn-link button-add"
                    style={
                      equipment_status === "true"
                        ? { color: "#B3B3B3" }
                        : { color: "#0382FA" }
                    }
                    disabled={equipment_status === "true"}
                    onClick={() => addEquipment("add")}
                  >
                    + {t("newBookingSingha_Create_step1:Add list")}
                  </button>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      }
    </Translation>
  );
};

export default Accessories;
