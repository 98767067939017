/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PurchaseRecordGroupStatus = "ACTIVE" | "PAID" | "PARTIAL_PAYMENT" | "VOID" | "WAITING_PAY" | "%future added value";
export type purchaseReportQueryVariables = {|
  first?: ?number,
  last?: ?number,
  startDate?: ?any,
  endDate?: ?any,
  search?: ?string,
  contact?: ?string,
  status?: ?string,
  productAndService?: ?string,
|};
export type purchaseReportQueryResponse = {|
  +allPurchaseRecordGroup: ?{|
    +totalCount: ?number,
    +pageInfo: {|
      +hasNextPage: boolean,
      +hasPreviousPage: boolean,
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +dueDate: ?any,
        +status: PurchaseRecordGroupStatus,
        +contact: {|
          +refNumber: string,
          +name: string,
        |},
        +purchaseRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +description: string,
              +total: number,
              +paid: number,
              +price: number,
              +preTaxAmount: number,
              +payRecord: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +payRecordGroup: {|
                      +id: string,
                      +docNumber: string,
                    |},
                  |}
                |}>
              |},
              +acceptCreditNoteTransaction: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +docNumber: string,
                  |}
                |}>
              |},
              +prepaidDepositTransactionUsed: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +prepaidDeposit: {|
                      +id: string,
                      +docNumber: string,
                    |},
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>,
  |},
  +summaryPurchaseRecordGroup: ?$ReadOnlyArray<?number>,
|};
export type purchaseReportQuery = {|
  variables: purchaseReportQueryVariables,
  response: purchaseReportQueryResponse,
|};
*/


/*
query purchaseReportQuery(
  $first: Int
  $last: Int
  $startDate: DateTime
  $endDate: DateTime
  $search: String
  $contact: String
  $status: String
  $productAndService: String
) {
  allPurchaseRecordGroup(productAndService: $productAndService, startDate: $startDate, endDate: $endDate, first: $first, last: $last, search: $search, contact: $contact, status: $status, order: "doc_number") {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        docNumber
        issuedDate
        dueDate
        status
        contact {
          refNumber
          name
          id
        }
        purchaseRecord {
          edges {
            node {
              id
              description
              total
              paid
              price
              preTaxAmount
              payRecord(payRecordGroup_Status: "paid") {
                edges {
                  node {
                    id
                    payRecordGroup {
                      id
                      docNumber
                    }
                  }
                }
              }
              acceptCreditNoteTransaction(status: "paid") {
                edges {
                  node {
                    id
                    docNumber
                  }
                }
              }
              prepaidDepositTransactionUsed {
                edges {
                  node {
                    id
                    prepaidDeposit {
                      id
                      docNumber
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  summaryPurchaseRecordGroup(startDate: $startDate, endDate: $endDate, search: $search, contact: $contact, status: $status, report: true, productAndService: $productAndService)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productAndService"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v8 = {
  "kind": "Variable",
  "name": "contact",
  "variableName": "contact"
},
v9 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "endDate"
},
v10 = {
  "kind": "Variable",
  "name": "productAndService",
  "variableName": "productAndService"
},
v11 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v12 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "startDate"
},
v13 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v14 = [
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": "doc_number"
  },
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v24 = [
  (v17/*: any*/),
  (v18/*: any*/)
],
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "PurchaseRecordNodeConnection",
  "kind": "LinkedField",
  "name": "purchaseRecord",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PurchaseRecordNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PurchaseRecordNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v17/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preTaxAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "payRecordGroup_Status",
                  "value": "paid"
                }
              ],
              "concreteType": "PayRecordNodeConnection",
              "kind": "LinkedField",
              "name": "payRecord",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PayRecordNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PayRecordNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v17/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PayRecordGroupNode",
                          "kind": "LinkedField",
                          "name": "payRecordGroup",
                          "plural": false,
                          "selections": (v24/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "payRecord(payRecordGroup_Status:\"paid\")"
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "status",
                  "value": "paid"
                }
              ],
              "concreteType": "AcceptCreditNoteNodeConnection",
              "kind": "LinkedField",
              "name": "acceptCreditNoteTransaction",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AcceptCreditNoteNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AcceptCreditNoteNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v24/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "acceptCreditNoteTransaction(status:\"paid\")"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
              "kind": "LinkedField",
              "name": "prepaidDepositTransactionUsed",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PrepaidDepositTransactionUsedNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v17/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PrepaidDepositNode",
                          "kind": "LinkedField",
                          "name": "prepaidDeposit",
                          "plural": false,
                          "selections": (v24/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": [
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    {
      "kind": "Literal",
      "name": "report",
      "value": true
    },
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "summaryPurchaseRecordGroup",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "purchaseReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "PurchaseRecordGroupNodeConnection",
        "kind": "LinkedField",
        "name": "allPurchaseRecordGroup",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordGroupNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseRecordGroupNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v25/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v26/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "purchaseReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "PurchaseRecordGroupNodeConnection",
        "kind": "LinkedField",
        "name": "allPurchaseRecordGroup",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordGroupNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseRecordGroupNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v25/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v26/*: any*/)
    ]
  },
  "params": {
    "cacheID": "d9ce7cefe8cdb8a4098f9dd0273988c5",
    "id": null,
    "metadata": {},
    "name": "purchaseReportQuery",
    "operationKind": "query",
    "text": "query purchaseReportQuery(\n  $first: Int\n  $last: Int\n  $startDate: DateTime\n  $endDate: DateTime\n  $search: String\n  $contact: String\n  $status: String\n  $productAndService: String\n) {\n  allPurchaseRecordGroup(productAndService: $productAndService, startDate: $startDate, endDate: $endDate, first: $first, last: $last, search: $search, contact: $contact, status: $status, order: \"doc_number\") {\n    totalCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n    }\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        dueDate\n        status\n        contact {\n          refNumber\n          name\n          id\n        }\n        purchaseRecord {\n          edges {\n            node {\n              id\n              description\n              total\n              paid\n              price\n              preTaxAmount\n              payRecord(payRecordGroup_Status: \"paid\") {\n                edges {\n                  node {\n                    id\n                    payRecordGroup {\n                      id\n                      docNumber\n                    }\n                  }\n                }\n              }\n              acceptCreditNoteTransaction(status: \"paid\") {\n                edges {\n                  node {\n                    id\n                    docNumber\n                  }\n                }\n              }\n              prepaidDepositTransactionUsed {\n                edges {\n                  node {\n                    id\n                    prepaidDeposit {\n                      id\n                      docNumber\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  summaryPurchaseRecordGroup(startDate: $startDate, endDate: $endDate, search: $search, contact: $contact, status: $status, report: true, productAndService: $productAndService)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ab31f476255b9c7e1e5763b73528d94';

module.exports = node;
