import {graphql} from "babel-plugin-relay/macro";

const queryContact = graphql`
    query queryContactQuery($id: ID!){
        contact(id: $id) {
            vat
            firstName
            lastName
            name
            phone
            email
            registeredAddress
        }
    }
`

export default queryContact;
