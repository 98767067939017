/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductAndServiceType = "EXPENSE" | "FINE" | "PRODUCT" | "SERVICE" | "%future added value";
export type dailyPaymentReceiptReportQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
  productAndService?: ?string,
  paymentChannel?: ?string,
  payment_gateway_method_type?: ?string,
  contact?: ?string,
  search?: ?string,
|};
export type dailyPaymentReceiptReportQueryResponse = {|
  +summaryDayReceiveReport: ?{|
    +dataTable: ?$ReadOnlyArray<?any>,
    +paymentChannelTable: ?any,
    +productAndServiceHeadTable: ?$ReadOnlyArray<?string>,
    +summaryTable: ?any,
    +numRow: ?number,
  |},
  +summaryDayReceiveReportExport: ?{|
    +dataTable: ?$ReadOnlyArray<?any>,
    +paymentChannelTable: ?any,
    +productAndServiceHeadTable: ?$ReadOnlyArray<?string>,
    +summaryTable: ?any,
    +numRow: ?number,
  |},
  +productViewer: ?{|
    +allProduct: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +productCode: string,
          +type: ProductAndServiceType,
          +price: ?number,
          +chartOfAccount: ?{|
            +id: string,
            +chartOfAccountCode: string,
            +name: string,
          |},
          +description: ?string,
          +totalLeftInStock: ?number,
        |}
      |}>
    |}
  |},
  +allPaymentGatewayMethodType: ?{|
    +dataTable: ?$ReadOnlyArray<?any>
  |},
  +selfProject: ?{|
    +name: string,
    +address: ?string,
    +logo: ?string,
  |},
|};
export type dailyPaymentReceiptReportQuery = {|
  variables: dailyPaymentReceiptReportQueryVariables,
  response: dailyPaymentReceiptReportQueryResponse,
|};
*/


/*
query dailyPaymentReceiptReportQuery(
  $startDate: Date
  $endDate: Date
  $productAndService: String
  $paymentChannel: String
  $payment_gateway_method_type: String
  $contact: String
  $search: String
) {
  summaryDayReceiveReport(startDate: $startDate, endDate: $endDate, productAndService: $productAndService, paymentChannel: $paymentChannel, paymentGatewayMethodType: $payment_gateway_method_type, contact: $contact, search: $search) {
    dataTable
    paymentChannelTable
    productAndServiceHeadTable
    summaryTable
    numRow
  }
  summaryDayReceiveReportExport: summaryDayReceiveReport(startDate: $startDate, endDate: $endDate, productAndService: $productAndService, paymentChannel: $paymentChannel, paymentGatewayMethodType: $payment_gateway_method_type, contact: $contact) {
    dataTable
    paymentChannelTable
    productAndServiceHeadTable
    summaryTable
    numRow
  }
  productViewer {
    allProduct(type_In: "product, service, fine") {
      edges {
        node {
          id
          name
          productCode
          type
          price
          chartOfAccount {
            id
            chartOfAccountCode
            name
          }
          description
          totalLeftInStock
        }
      }
    }
    id
  }
  allPaymentGatewayMethodType {
    dataTable
  }
  selfProject {
    name
    address
    logo
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentChannel"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payment_gateway_method_type"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productAndService"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v7 = {
  "kind": "Variable",
  "name": "contact",
  "variableName": "contact"
},
v8 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "endDate"
},
v9 = {
  "kind": "Variable",
  "name": "paymentChannel",
  "variableName": "paymentChannel"
},
v10 = {
  "kind": "Variable",
  "name": "paymentGatewayMethodType",
  "variableName": "payment_gateway_method_type"
},
v11 = {
  "kind": "Variable",
  "name": "productAndService",
  "variableName": "productAndService"
},
v12 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "startDate"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataTable",
  "storageKey": null
},
v14 = [
  (v13/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paymentChannelTable",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "productAndServiceHeadTable",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "summaryTable",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "numRow",
    "storageKey": null
  }
],
v15 = {
  "alias": null,
  "args": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    },
    (v12/*: any*/)
  ],
  "concreteType": "SummaryDayReceiveReportObject",
  "kind": "LinkedField",
  "name": "summaryDayReceiveReport",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": "summaryDayReceiveReportExport",
  "args": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "concreteType": "SummaryDayReceiveReportObject",
  "kind": "LinkedField",
  "name": "summaryDayReceiveReport",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "type_In",
      "value": "product, service, fine"
    }
  ],
  "concreteType": "ProductAndServiceNodeConnection",
  "kind": "LinkedField",
  "name": "allProduct",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAndServiceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v17/*: any*/),
            (v18/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChartOfAccountNode",
              "kind": "LinkedField",
              "name": "chartOfAccount",
              "plural": false,
              "selections": [
                (v17/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "chartOfAccountCode",
                  "storageKey": null
                },
                (v18/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalLeftInStock",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allProduct(type_In:\"product, service, fine\")"
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ListAllPaymentGatewayMethodObject",
  "kind": "LinkedField",
  "name": "allPaymentGatewayMethodType",
  "plural": false,
  "selections": [
    (v13/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "dailyPaymentReceiptReportQuery",
    "selections": [
      (v15/*: any*/),
      (v16/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v19/*: any*/)
        ],
        "storageKey": null
      },
      (v20/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "dailyPaymentReceiptReportQuery",
    "selections": [
      (v15/*: any*/),
      (v16/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v19/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": null
      },
      (v20/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b21cdb2b30eee2bee4ec03ef7f629fa7",
    "id": null,
    "metadata": {},
    "name": "dailyPaymentReceiptReportQuery",
    "operationKind": "query",
    "text": "query dailyPaymentReceiptReportQuery(\n  $startDate: Date\n  $endDate: Date\n  $productAndService: String\n  $paymentChannel: String\n  $payment_gateway_method_type: String\n  $contact: String\n  $search: String\n) {\n  summaryDayReceiveReport(startDate: $startDate, endDate: $endDate, productAndService: $productAndService, paymentChannel: $paymentChannel, paymentGatewayMethodType: $payment_gateway_method_type, contact: $contact, search: $search) {\n    dataTable\n    paymentChannelTable\n    productAndServiceHeadTable\n    summaryTable\n    numRow\n  }\n  summaryDayReceiveReportExport: summaryDayReceiveReport(startDate: $startDate, endDate: $endDate, productAndService: $productAndService, paymentChannel: $paymentChannel, paymentGatewayMethodType: $payment_gateway_method_type, contact: $contact) {\n    dataTable\n    paymentChannelTable\n    productAndServiceHeadTable\n    summaryTable\n    numRow\n  }\n  productViewer {\n    allProduct(type_In: \"product, service, fine\") {\n      edges {\n        node {\n          id\n          name\n          productCode\n          type\n          price\n          chartOfAccount {\n            id\n            chartOfAccountCode\n            name\n          }\n          description\n          totalLeftInStock\n        }\n      }\n    }\n    id\n  }\n  allPaymentGatewayMethodType {\n    dataTable\n  }\n  selfProject {\n    name\n    address\n    logo\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd6554bb680f32fac07abd8d05c9f01f5';

module.exports = node;
