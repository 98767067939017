/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreatePaymentGatewayGroupInput = {|
  paymentGatewayGroup: any,
  clientMutationId?: ?string,
|};
export type paymentGatewayCreateFormMutationVariables = {|
  input: CreatePaymentGatewayGroupInput
|};
export type paymentGatewayCreateFormMutationResponse = {|
  +createPaymentGatewayGroup: ?{|
    +clientMutationId: ?string,
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type paymentGatewayCreateFormMutation = {|
  variables: paymentGatewayCreateFormMutationVariables,
  response: paymentGatewayCreateFormMutationResponse,
|};
*/


/*
mutation paymentGatewayCreateFormMutation(
  $input: CreatePaymentGatewayGroupInput!
) {
  createPaymentGatewayGroup(input: $input) {
    clientMutationId
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePaymentGatewayGroupPayload",
    "kind": "LinkedField",
    "name": "createPaymentGatewayGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentGatewayCreateFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "paymentGatewayCreateFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d3f4fc4ffdb1ce725d5b1f218ef1c280",
    "id": null,
    "metadata": {},
    "name": "paymentGatewayCreateFormMutation",
    "operationKind": "mutation",
    "text": "mutation paymentGatewayCreateFormMutation(\n  $input: CreatePaymentGatewayGroupInput!\n) {\n  createPaymentGatewayGroup(input: $input) {\n    clientMutationId\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24f9a4c92a79e2e08c65eb73a1376b5c';

module.exports = node;
