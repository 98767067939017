import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import ProjectList from "./projectList";
// import CreateUpdateProjectInfo from './createUpdateProjectInfo'
// import CreateUpdatePlan from './createUpdatePlan'
// import CreateUpdateContactInfo from './createUpdateContactInfo'
import NoMatch from "../../components/noMatch";
// import PageNavigation from "./pageNavigation";

class ProjectInfo extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={ProjectList}/>
                {/* <PrivateRoute exact path={`${this.props.match.url}/form/:status`} component={PageNavigation}/> */}
                {/*<PrivateRoute exact path={`${this.props.match.url}/form-project-info`} component={CreateUpdateProjectInfo}/>*/}
                {/*<PrivateRoute exact path={`${this.props.match.url}/form-plan`} component={CreateUpdatePlan}/>*/}
                {/*<PrivateRoute exact path={`${this.props.match.url}/form-contact-info`} component={CreateUpdateContactInfo}/>*/}
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default ProjectInfo;