/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RenewContractExpenseInput = {|
  contractExpenseId: string,
  clientMutationId?: ?string,
|};
export type renewContractExpenseMutationVariables = {|
  input: RenewContractExpenseInput
|};
export type renewContractExpenseMutationResponse = {|
  +renewContractExpense: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type renewContractExpenseMutation = {|
  variables: renewContractExpenseMutationVariables,
  response: renewContractExpenseMutationResponse,
|};
*/


/*
mutation renewContractExpenseMutation(
  $input: RenewContractExpenseInput!
) {
  renewContractExpense(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RenewContractExpensePayload",
    "kind": "LinkedField",
    "name": "renewContractExpense",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "renewContractExpenseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "renewContractExpenseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "506271ca223c59be167eaca6d2f1d4af",
    "id": null,
    "metadata": {},
    "name": "renewContractExpenseMutation",
    "operationKind": "mutation",
    "text": "mutation renewContractExpenseMutation(\n  $input: RenewContractExpenseInput!\n) {\n  renewContractExpense(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9536d21d9c67db6425e7bdaebe7dd8a0';

module.exports = node;
