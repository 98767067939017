import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const query = graphql`
    query checkIdCardNumberQuery($idCardNumber: String) {
        checkIdCardNumber (idCardNumber: $idCardNumber) {
            result
            reason
        }
    }
`;

const checkIdCardNumberQuery = (idCardNumber) => {
    return new Promise((resolve, reject) => {
        fetchQuery(environment, query, { idCardNumber: idCardNumber }).then(data => {
            resolve(data)
        }).catch(error => {
            reject(error)
        })
    });
};

export default checkIdCardNumberQuery;