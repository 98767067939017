import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Redirect} from 'react-router';
import "./style/creditNoteSelectTransaction.scss"
import numberWithComma from "../../../libs/numberWithComma";
import { Translation } from "react-i18next";
import i18n from "i18next";


class CreditNoteCreateSelectTransaction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: this.props.customerList[0],
            on_submit: false,
            other_input: false,
            select_transaction:'',
            reason_create_credit_note:i18n.t('creditNoteSelectTransaction:Please select an item'),
            reason_text:'',
            text_header: props.category === 'credit_note'? 'Credit Note': 'Accept Credit Note',

        };


        this.handleInputChange = this.handleInputChange.bind(this);
    }


    handleInputChange(e) {
        if (e.currentTarget.dataset.name) {
            if (e.currentTarget.dataset.key === 'other') {
                this.setState({
                    other_input: true,
                    reason_create_credit_note: e.currentTarget.dataset.name,
                    reason_text: ""
                })
            } else {
                this.setState({
                    other_input: false,
                    reason_create_credit_note: e.currentTarget.dataset.name,
                    reason_text: e.currentTarget.dataset.name
                }, () => this.props.updateReasonCreate('reason_create', this.state.reason_text))
            }

        } else if (e.target.name === 'reason_text') {
            this.setState({reason_text: e.target.value}, () => this.props.updateReasonCreate('reason_create', this.state.reason_text))
        } else {
            this.setState({[e.target.name]: e.target.value}, () => this.props.updateReasonCreate('transaction', this.state.select_transaction))
        }
    }

    render() {

        if (this.props.customerList.length > 0) {
            return (

                    <Translation>
                        {t => 
                        <div className="container-fluid box" id="credit-note-select-transaction">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to={this.props.back_page}>
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                 alt="back" className="back"/>
                                        </Link>
                                        {t('creditNoteSelectTransaction:Create')}{t('creditNoteSelectTransaction:'+this.state.text_header)}
                                    </h3>
                                </div>
                            </div>
                            <div className="content-inner" id="receive-select-invoice">
                                <div className="row">
                                    <div className="col-4 text-right">
                                        <label htmlFor="select_transaction"
                                               className="col-form-label">{t('creditNoteSelectTransaction:Select a item to reference in issuing a ')}{t('creditNoteSelectTransaction:'+this.state.text_header)}</label>
                                    </div>
                                    <div className="col-5">
                                        <select className="form-control" name="select_transaction"
                                                id="select_transaction"
                                                value={this.state.select_transaction}
                                                onChange={this.handleInputChange}>
                                            <option value="">{t('creditNoteSelectTransaction:Please select an item')}</option>
                                            {this.props.category === 'credit_note' ?
                                                <React.Fragment>
                                                    {this.props.transaction_list.map((transaction) => (
                                                        <option value={transaction.node.id} key={transaction.node.id}>
                                                            {transaction.node.invoice?.docNumber}{" "} 
                                                            {transaction.node.productAndService?.productCode}{" "} 
                                                            {transaction.node.description.replace(/ *\[[^)]*\] */g, " ")}{" "}
                                                            {numberWithComma((transaction.node.total - transaction.node.paid))} {t('creditNoteSelectTransaction:Baht')}
                                                        </option>
                                                    ))}
                                                </React.Fragment> :
                                                <React.Fragment>
                                                    {this.props.transaction_list.map((transaction) => (
                                                        <option value={transaction.node.id} key={transaction.node.id}>
                                                            {transaction.node.purchaseRecordGroup?.docNumber}{" "} 
                                                            {transaction.node.productAndService?.productCode}{" "} 
                                                            {transaction.node.description}{" "}
                                                            {(transaction.node.total - transaction.node.paid).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) } {t('creditNoteSelectTransaction:Baht')}
                                                            {/* {numberWithComma((transaction.node.total - transaction.node.paid) || 0)} บาท */}
                                                        </option>
                                                    ))}
                                                </React.Fragment>
                                            }
                                        </select>
    
                                    </div>
                                </div>
    
                                {this.state.select_transaction &&
                                <div className="container-fluid card fade-up mt-5">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <h4 className="inline">{t('creditNoteSelectTransaction:Please specify the reason for the debt reduction')}</h4>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-2">
                                                <label htmlFor="reason_create_credit_note"
                                                       className="col-form-label">{t('creditNoteSelectTransaction:Type of reasons for debt reduction')}</label>
                                            </div>
                                            <div className="col-6">
                                                <div className="dropdown mb-4">
                                                    <button className="btn btn-light dropdown-toggle dropdown-select" type="button"
                                                            id="dropdownMenuButton" data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false">
                                                        {this.state.reason_create_credit_note}
                                                    </button>
                                                    {
                                                        this.props.category === "accept_credit_note" ?
                                                            <div className="dropdown-menu"
                                                                 aria-labelledby="dropdownMenuButton">
                                                                <span className="btn dropdown-item"
                                                                      data-name={'(1) '+ t('creditNoteSelectTransaction:Debt reduction')+t('creditNoteSelectTransaction:due to receiving the wrong purchase documents')}
                                                                      data-key=""
                                                                      onClick={this.handleInputChange}>
                                                                    (1) <strong><u>{t('creditNoteSelectTransaction:Debt reduction')}</u></strong> {t('creditNoteSelectTransaction:due to receiving the wrong purchase documents')}
                                                                </span>
                                                                <span className="btn dropdown-item"
                                                                      data-name={'(2) '+t('creditNoteSelectTransaction:Others')+', '+t('creditNoteSelectTransaction:please specify')}
                                                                       data-key="other"
                                                                      onClick={this.handleInputChange}>
                                                                    (2) <strong><u>{t('creditNoteSelectTransaction:Others')}</u></strong>
                                                                </span>
                                                            </div>
                                                            :
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <span className="btn dropdown-item"
                                                                      data-name={'(1) '+t('creditNoteSelectTransaction:Debt reduction')+t('creditNoteSelectTransaction:due to wrong invoice')}
                                                                      data-key=""
                                                                      onClick={this.handleInputChange}>
                                                                    (1) <strong><u>{t('creditNoteSelectTransaction:Debt reduction')}</u></strong> {t('creditNoteSelectTransaction:due to wrong invoice')}
                                                                </span>
                                                                <span className="btn dropdown-item"
                                                                      data-name={'(2) '+t('creditNoteSelectTransaction:Others')+', '+t('creditNoteSelectTransaction:please specify')}
                                                                      data-key="other"
                                                                      onClick={this.handleInputChange}>
                                                                    (2) <strong><u>{t('creditNoteSelectTransaction:Others')}</u></strong>
                                                                </span>
                                                            </div>
                                                    }
    
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.other_input &&
                                        <div className="row">
                                            <div className="col-2"/>
                                            <div className="col-5">
                                                <input type="text"
                                                       className="form-control"
                                                       value={this.state.reason_text}
                                                       onChange={this.handleInputChange}
                                                       name="reason_text" required/>
                                            </div>
                                        </div>
    
                                        }
                                    </div>
                                </div>
    
                                }
                                <div className="row mt-4">
                                    <div className="col-12 text-right">
                                        <div className="btn-group mr-2">
                                            <Link to={this.props.back_page}>
                                                <button type="button" className="btn btn-secondary add">
                                                {t('creditNoteSelectTransaction:Cancel')}
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="btn-group mr-2">
                                            {(this.state.reason_create_credit_note !== i18n.t('creditNoteSelectTransaction:Please select an item') && this.state.select_transaction !== '' && this.state.reason_text !== '') ?
                                                <Link to={this.props.next_page}>
                                                    <button type="button" className="btn btn-primary add">
                                                    {t('creditNoteSelectTransaction:Next')}
                                                    </button>
                                                </Link>
                                                :
                                                <button type="button" className="btn btn-light disabled add">
                                                    {t('creditNoteSelectTransaction:Next')}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </Translation>
            )
        }
        return <Redirect to={this.props.back_page}/>;
    }
}

export default CreditNoteCreateSelectTransaction;