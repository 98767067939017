import '../../../../document/styles/main.scss';
import '../../report.scss';
import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import format from 'date-fns/format';
import thLocale from "date-fns/locale/th";
import _ from 'lodash'
import { H5 } from '../../../../components/Text/Text';
import { formatDateISO } from "../../../../utils";
import PrintA4horizontal from './Doc/printA4horizontal';
import BankImpactReportTableTransectionPDF from './Doc/bankImpactReportTableTransectionPDF';


const query = graphql`
  query wrapperBankDepositImpactReportQuery($bankAccountId:String, $startDate: Date,$endDate: Date, $search: String) {
    selfProject {
      name
      logo
    }
    allBankAccountTransactionReport(bankAccountId:$bankAccountId,startDate: $startDate,endDate:$endDate, search: $search)
  }
`;

class WrapperBankDepositImpactReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      all_bank: '',
      summary_balance: '',
      summary_report_residential: '',
      summary_report_supplier: '',
      summary_bank_report: '',
      project_name: '',
      project_logo: '',
      loading: true,
      startDate: "",
      endDate: "",
    };
  }
  getData = async () => {
    this.setState({ loading: true })
    await fetchQuery(environment, query, {
      bankAccountId: this.props?.match?.params?.bankAccountId,
      startDate: formatDateISO(this.props?.match?.params?.startDate),
      endDate: formatDateISO(this.props?.match?.params?.endDate)
    }).then((data) => {
      // let sum = _.sumBy(data.allBankAccountTransactionReport.edges, function (o) { return parseFloat(JSON.parse(o.node).balance) });
      let data_list = [];
      // let dse = data?.allBankAccountTransactionReport?.forEach(element => data_list.push(JSON.parse(element)));

      this.setState({
        project_name: data.selfProject.name,
        project_logo: data.selfProject.logo,
        // summary_balance: sum,
        // summary_bank_report: data.summaryBankReportPurchaseRecordGroup,
        all_bank: data_list,
        startDate: format(new Date(this.props?.match?.params?.startDate), "MMMM YYYY", { locale: thLocale, }),
        endDate: format(new Date(this.props?.match?.params?.endDate), "MMMM YYYY", { locale: thLocale, }),
        loading: false,
      })
    });
  }

  handleSummary = (sumBalance) => (sumBalance / this.state.summary_balance) * 100
  async componentDidMount() { await this.getData() }

  print = () => {
    window.print();
  }

  render() {
    const { all_bank, project_name, project_logo } = this.state
    return (
      <React.Fragment>
        <PrintA4horizontal />

        <div className="print-top-menu">
          <div className="logo"><img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" /></div>
          <div className="print" onClick={this.print}>PRINT</div>
        </div>
        <div>

          {_.chunk(all_bank, 9).map((transaction, index, array) => {
            return (
              <div className="page colorUseDoc" >
                <div className="container-fluid box p-3" id='bank-deposit-report-main' >
                  <div className="">
                    <div className="logo ml-1 " style={{ position: 'absolute' }}>{project_logo !== '' && <img src={project_logo} alt="silverman" style={{ width: '90px' }} />}</div>
                    <div className="mt-5 mb-4 ">
                      <H5 className="text-center">{project_name}</H5>
                      <H5 className="text-center">รายงานกระทบยอดเงินฝากธนาคาร</H5>
                      <H5 className="text-center">ประจำเดือน {this.state.startDate} - {this.state.endDate}</H5>
                    </div>

                    <BankImpactReportTableTransectionPDF
                      all_bank={transaction}
                      lastIndex={all_bank[0][-1]}
                      page={index + 1}
                      allPage={array.length}
                      reportType={"pdf"}
                    />
                  </div>
                </div>
              </div>
            )
          })}


          {/* {_.chunk(all_bank, 12).map((transaction, index) => {
            if (index > 0) {
              return (
                <div className="page">
                  <BankDepositReportTablePDF
                    key={index}
                    all_bank={transaction}
                    summary_report_residential={summary_report_residential}
                    summary_report_supplier={summary_report_supplier}
                    summary_bank_report={summary_bank_report}
                    summary_balance={summary_balance}
                    loading={loading}
                    date={date}
                    handleSummary={this.handleSummary}
                    report
                    logo={project_logo}
                    lastPage={(index + 1 === _.chunk(all_bank, 12).length || _.chunk(all_bank, 12).length === 1) ? true : false}
                    lastPageNumber={_.chunk(all_bank, 12).length}
                    reportType='pdf'
                  />
                  {(index + 1 === _.chunk(all_bank, 12).length || _.chunk(all_bank, 12).length === 1) &&
                    <SummaryAccountsReceivableAndPayableTable
                      key={index}
                      all_bank={all_bank}
                      summary_report_residential={summary_report_residential}
                      summary_report_supplier={summary_report_supplier}
                      summary_bank_report={summary_bank_report}
                      summary_balance={summary_balance}
                      loading={loading}
                      date={date}
                      report
                      handleSummary={this.handleSummary}
                      lastPageNumber={_.chunk(all_bank, 12).length}
                      reportType='pdf'
                    />}
                </div>
              )
            }
          })} */}
        </div>

      </React.Fragment >
    );
  }
}

export default WrapperBankDepositImpactReport;
