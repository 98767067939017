import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const query = graphql`
    query allSiteBySuperAdminQuery($typeOfProject: String, $search: String, $order: String) {
      allSelectJuristic(typeOfProject: $typeOfProject, search: $search, order:$order){
          totalCount
          edges{
              node{
                  id
                  site{
                      id
                      domain
                      project{
                          edges{
                              node{
                                  id
                                  nameTh
                                  nameEn
                                  typeOfProject
                                  logo
                              }
                          }
                      }
  
                  }
                  
              }
          }
                  
      }
    }
`;

export const AllSiteBySuperAdmin = (variables, response) => {
  fetchQuery(environment, query, variables)
    .then((data) => {
      if (data) {
        response(data?.allSelectJuristic?.edges);
      }
    })
    .catch((error) => {
      response(null);
    });
};

