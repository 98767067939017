import React from 'react';
import {QueryRenderer} from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import {Link} from "react-router-dom";
import localStorage from "../../../libs/localstorage";
import Loading from '../../../libs/loading';
import i18next from 'i18next';


class PayListTable extends ComponentPagination {
    componentDidUpdate(nextProps, nextState, nextContext) {
        if (this.state.status !== this.props.status) {
            this.setState({
                status: this.props.status,
                checkList: [],
                check_all: false,
            }, () => {
                this.goFirst();
            })
        }
    }

    getColorAndText(object) {
        let text = '';
        let color = 'text-center';
        switch (object.node.status) {
            case 'PAID':
                text = i18next.t("PayList:Paid");
                break;
            case 'VOID':
                text = i18next.t("PayList:Cancelled");
                color = 'text-center text-danger';
                break;
            default:
                text = i18next.t("PayList:Pre - payment");
        }
        return <td className={color}>{text}</td>
    }

    showDocNumberByStatus(pay) {
        if (pay.node.status === "VOID") {
            return pay.node.docNumber
        } else {
            return <Link to={"/accounting/expense/pay/detail/" + pay.node.id + "/"+pay.node.docNumber}
                        >{pay.node.docNumber}</Link>
        }
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={this.props.query}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status
                }}
                render={({error, props}) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return <React.Fragment>
                            <div className="table-responsive fade-up card">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                    <tr>
                                        <th>
                                            <input type="checkbox"
                                                   onChange={() => this.getAllList(props.allPayRecordGroup.edges)}
                                                   checked={this.state.check_all}/>
                                        </th>
                                        <th className="text-center" width={130}>{i18next.t("PayList:No.")}</th>
                                        <th className="text-center">{i18next.t("PayList:Date")}</th>
                                        <th className="text-center">{i18next.t("PayList:Payable code")}</th>
                                        <th>{i18next.t("PayList:Name")}</th>
                                        {/*<th>ลูกค้า</th>*/}
                                        <th>{i18next.t("PayList:Description")}</th>
                                        <th className="text-right">{i18next.t("PayList:Amount")}</th>
                                        <th className="text-right">{i18next.t("PayList:Total amount")}</th>
                                        {this.props.status !== 'void' &&
                                            <th className="text-center">{i18next.t("PayList:Status")}</th>
                                        }
                                        <th className="text-center">{i18next.t("PayList:By")}</th>
                                        <th className="text-center">{i18next.t("PayList:Create Date")}</th>
                                        {this.props.status === 'void' &&
                                            <th className="text-center">{i18next.t("PayList:Canceled date")}</th>
                                        }
                                        <th className="text-center">{i18next.t("PayList:Payment document")}</th>
                                        {this.props.status === 'void' &&
                                        <th>{i18next.t("PayList:Reason")}</th>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {props.allPayRecordGroup.edges.map((pay, index) => {
                                        let order_id = this.state.first - localStorage.getPageLimit();
                                        let accumulate = 0;
                                        return (
                                            pay.node.payRecord.edges.map((transaction, t_index) => {
                                                let number_of_transaction = pay.node.payRecord.edges.length;
                                                accumulate = accumulate + transaction.node.amount;
                                                let row_number = (t_index === 0 && order_id + index + 1);
                                                return (
                                                    <tr key={transaction.node.id}>
                                                        <td>
                                                            {row_number &&
                                                            <input type="checkbox"
                                                                   onChange={() => this.appendToCheckList(pay.node.id)}
                                                                   checked={this.checkList(pay.node.id)}/>
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {this.showDocNumberByStatus(pay)}
                                                        </td>
                                                        <td className="text-center">{format((pay.node.refPayExpenseDate || pay.node.issuedDate), 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                        <td className="text-center">{pay.node.contact.refNumber}</td>
                                                        <td>{pay.node.contact.name}</td>
                                                        <td>{transaction.node.purchaseRecord.description}</td>
                                                         <td className="text-right">{numberWithComma(transaction.node.amount)}</td>
                                                        <td className="text-right">{
                                                            t_index === number_of_transaction - 1 && numberWithComma(accumulate)
                                                        }</td>
                                                        {this.props.status !== 'void' &&
                                                            <React.Fragment>
                                                                {this.getColorAndText(pay)}
                                                            </React.Fragment>
                                                        }                                                        
                                                        <td className="text-center">{pay.node.creator || '-'}</td>
                                                        <td className="text-center">{format(pay.node.added, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                        {this.props.status === 'void' &&
                                                            <td className="text-center">{format(pay.node.updated, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                        }
                                                        <td className="text-center">
                                                            {pay.node.refPayDoc ?
                                                                <a download="name_of_downloaded_file"
                                                                   href={pay.node.refPayDoc}>
                                                                    <img
                                                                        src={process.env.PUBLIC_URL + '/images/icons/download-file-icon.png'}
                                                                        alt="back" className="download-file-icon"/>
                                                                </a>
                                                                :
                                                                <span>-</span>
                                                            }
                                                        </td>
                                                        {this.props.status === 'void' &&
                                                        <td>{pay.node.voidRemark || '-'}</td>
                                                        }

                                                    </tr>
                                                )
                                            })
                                        )

                                    })
                                    }

                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                            last={this.state.last}
                                            totalCount={props.allPayRecordGroup.totalCount}/>
                            </div>
                        </React.Fragment>
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default PayListTable;
