import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import '../../style/claim.scss'

class InsurranceViewCoInsur extends Component {

    functionCurrentMoneyFormat(money) {
        let formatMoney = 0
        if (money) {
            formatMoney = parseFloat(money).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
        return formatMoney ? formatMoney : "-"
    }

    render() {

        let data = this.props.co_insurance
        let main = data.filter((n) => n.status === "main")
        let co = data.filter((n) => n.status === "co")
        return (
            <Translation>
                {
                    t =>
                        <div className='row'>
                            <div className='col-12'>
                                <table className='table'>
                                    <thead className='bg-gray'>
                                        <tr className='text-center'>
                                            <th></th>
                                            <th>{t("InsurancessView:No")}</th>
                                            <th>{t("InsurancessView:ComapanyInsur")}</th>
                                            <th>{t("InsurancessView:Persent")} (%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            this.props.data.main_insurance_company &&
                                            <tr className='text-center'>
                                                <td className='tag'>{<span className='tagDanger'>{t("InsurancessView:MainInsur")}</span>}</td>
                                                <td>01</td>
                                                <td>{main[0]?.co_insurance_company.name}</td>
                                                <td>{main[0]?.insurance_ratio}</td>
                                            </tr>
                                        }
                                        {
                                            co?.map((n, inx) => {
                                                let numNo = inx + 2
                                                return (
                                                    <tr className='text-center' key={inx+ "co_insurance_company"}>
                                                        <td className='tag'></td>
                                                        <td>{numNo < 10 ? "0"+ numNo :  numNo}</td>
                                                        <td>{n.co_insurance_company.name}</td>
                                                        <td>{n.insurance_ratio}</td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>

                            </div>
                        </div>
                }
            </Translation>
        );
    }
}

export default withRouter(InsurranceViewCoInsur);
