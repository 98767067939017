/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResidentialConveyance = "ALREADY_TRANSFERRED" | "NOT_TRANSFERRED" | "%future added value";
export type selectContactReceivableSirirajQueryVariables = {|
  typeOfContact?: ?string,
  conveyance?: ?string,
  isActive?: ?boolean,
  receivableFilter?: ?boolean,
  periodDate?: ?any,
  periodList?: ?$ReadOnlyArray<?any>,
|};
export type selectContactReceivableSirirajQueryResponse = {|
  +contactViewer: ?{|
    +id: string,
    +allContact: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +refNumber: string,
          +name: string,
          +firstName: string,
          +lastName: string,
          +residential: ?{|
            +id: string,
            +conveyance: ResidentialConveyance,
          |},
          +ownerName: ?string,
          +mailingAddress: string,
          +mailingDistrict: string,
          +mailingCity: string,
          +mailingProvince: string,
          +mailingPostalCode: string,
          +renterName: ?string,
          +renterAddress: ?string,
          +renterDistrict: string,
          +renterCity: string,
          +renterProvince: string,
          +renterPostalCode: string,
          +agentName: ?string,
          +agentAddress: ?string,
          +agentDistrict: string,
          +agentCity: string,
          +agentProvince: string,
          +agentPostalCode: string,
          +allAmountNotPaid: ?number,
        |}
      |}>
    |},
  |},
  +getAllPeriod: ?$ReadOnlyArray<?any>,
|};
export type selectContactReceivableSirirajQuery = {|
  variables: selectContactReceivableSirirajQueryVariables,
  response: selectContactReceivableSirirajQueryResponse,
|};
*/


/*
query selectContactReceivableSirirajQuery(
  $typeOfContact: String
  $conveyance: String
  $isActive: Boolean
  $receivableFilter: Boolean
  $periodDate: Date
  $periodList: [Date]
) {
  contactViewer {
    id
    allContact(typeOfContact: $typeOfContact, conveyance: $conveyance, isActive: $isActive, receivableFilter: $receivableFilter, periodDate: $periodDate, periodList: $periodList) {
      edges {
        node {
          id
          refNumber
          name
          firstName
          lastName
          residential {
            id
            conveyance
          }
          ownerName
          mailingAddress
          mailingDistrict
          mailingCity
          mailingProvince
          mailingPostalCode
          renterName
          renterAddress
          renterDistrict
          renterCity
          renterProvince
          renterPostalCode
          agentName
          agentAddress
          agentDistrict
          agentCity
          agentProvince
          agentPostalCode
          allAmountNotPaid
        }
      }
    }
  }
  getAllPeriod
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conveyance"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isActive"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "periodDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "periodList"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "receivableFilter"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeOfContact"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContactViewer",
    "kind": "LinkedField",
    "name": "contactViewer",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "conveyance",
            "variableName": "conveyance"
          },
          {
            "kind": "Variable",
            "name": "isActive",
            "variableName": "isActive"
          },
          {
            "kind": "Variable",
            "name": "periodDate",
            "variableName": "periodDate"
          },
          {
            "kind": "Variable",
            "name": "periodList",
            "variableName": "periodList"
          },
          {
            "kind": "Variable",
            "name": "receivableFilter",
            "variableName": "receivableFilter"
          },
          {
            "kind": "Variable",
            "name": "typeOfContact",
            "variableName": "typeOfContact"
          }
        ],
        "concreteType": "ContactNodeConnection",
        "kind": "LinkedField",
        "name": "allContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "conveyance",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ownerName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allAmountNotPaid",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "getAllPeriod",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectContactReceivableSirirajQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "selectContactReceivableSirirajQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "89e23afd78217ab8b6d34c616663d43b",
    "id": null,
    "metadata": {},
    "name": "selectContactReceivableSirirajQuery",
    "operationKind": "query",
    "text": "query selectContactReceivableSirirajQuery(\n  $typeOfContact: String\n  $conveyance: String\n  $isActive: Boolean\n  $receivableFilter: Boolean\n  $periodDate: Date\n  $periodList: [Date]\n) {\n  contactViewer {\n    id\n    allContact(typeOfContact: $typeOfContact, conveyance: $conveyance, isActive: $isActive, receivableFilter: $receivableFilter, periodDate: $periodDate, periodList: $periodList) {\n      edges {\n        node {\n          id\n          refNumber\n          name\n          firstName\n          lastName\n          residential {\n            id\n            conveyance\n          }\n          ownerName\n          mailingAddress\n          mailingDistrict\n          mailingCity\n          mailingProvince\n          mailingPostalCode\n          renterName\n          renterAddress\n          renterDistrict\n          renterCity\n          renterProvince\n          renterPostalCode\n          agentName\n          agentAddress\n          agentDistrict\n          agentCity\n          agentProvince\n          agentPostalCode\n          allAmountNotPaid\n        }\n      }\n    }\n  }\n  getAllPeriod\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '661b065c1a22fa3194b1ba5565a9dcb6';

module.exports = node;
