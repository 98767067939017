/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PayRecordGroupStatus = "DRAFT" | "PAID" | "VOID" | "%future added value";
export type payListQueryVariables = {|
  status: string,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  first?: ?number,
  last?: ?number,
|};
export type payListQueryResponse = {|
  +allPayRecordGroup: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +remark: string,
        +added: any,
        +voidRemark: ?string,
        +contact: {|
          +id: string,
          +refNumber: string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |},
        +total: number,
        +issuedDate: any,
        +creator: ?string,
        +status: PayRecordGroupStatus,
        +updated: any,
        +refPayExpenseDate: ?any,
        +payRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +amount: number,
              +purchaseRecord: {|
                +description: string
              |},
            |}
          |}>
        |},
        +refPayDoc: ?string,
      |}
    |}>,
  |}
|};
export type payListQuery = {|
  variables: payListQueryVariables,
  response: payListQueryResponse,
|};
*/


/*
query payListQuery(
  $status: String!
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $first: Int
  $last: Int
) {
  allPayRecordGroup(status: $status, search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        docNumber
        remark
        added
        voidRemark
        contact {
          id
          refNumber
          name
          firstName
          lastName
        }
        total
        issuedDate
        creator
        status
        updated
        refPayExpenseDate
        payRecord {
          edges {
            node {
              id
              amount
              purchaseRecord {
                description
                id
              }
            }
          }
        }
        refPayDoc
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidRemark",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refPayExpenseDate",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refPayDoc",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "payListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "PayRecordGroupNodeConnection",
        "kind": "LinkedField",
        "name": "allPayRecordGroup",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayRecordGroupNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PayRecordGroupNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayRecordNodeConnection",
                    "kind": "LinkedField",
                    "name": "payRecord",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PayRecordNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PayRecordNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v20/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PurchaseRecordNode",
                                "kind": "LinkedField",
                                "name": "purchaseRecord",
                                "plural": false,
                                "selections": [
                                  (v21/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "payListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "PayRecordGroupNodeConnection",
        "kind": "LinkedField",
        "name": "allPayRecordGroup",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PayRecordGroupNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PayRecordGroupNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PayRecordNodeConnection",
                    "kind": "LinkedField",
                    "name": "payRecord",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PayRecordNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PayRecordNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v20/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PurchaseRecordNode",
                                "kind": "LinkedField",
                                "name": "purchaseRecord",
                                "plural": false,
                                "selections": [
                                  (v21/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9f564ba25dbc8611c81a6a4bde0a3bbc",
    "id": null,
    "metadata": {},
    "name": "payListQuery",
    "operationKind": "query",
    "text": "query payListQuery(\n  $status: String!\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $first: Int\n  $last: Int\n) {\n  allPayRecordGroup(status: $status, search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        remark\n        added\n        voidRemark\n        contact {\n          id\n          refNumber\n          name\n          firstName\n          lastName\n        }\n        total\n        issuedDate\n        creator\n        status\n        updated\n        refPayExpenseDate\n        payRecord {\n          edges {\n            node {\n              id\n              amount\n              purchaseRecord {\n                description\n                id\n              }\n            }\n          }\n        }\n        refPayDoc\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ef5d599acd26127ac3902d11d2073a0';

module.exports = node;
