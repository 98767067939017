import React, {Component} from "react";
import jwtDecode from 'jwt-decode';
import {format} from "date-fns";

class IndividualReceiptReportPDFRenderTable extends Component {
    constructor(props) {
        super(props);
    }


    render() {    
       let dataChunked = [];
       let rowPerpage = 16 ;
       for (let i = 0; i < this.props.dataAll.length; i += rowPerpage) {
           let chunk = this.props.dataAll.slice(i, i + rowPerpage);
           dataChunked.push(chunk);            
       }
       let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
       let printBy = `${jwtDecodes.first_name} ${jwtDecodes.last_name}`;
       let atDate = this.props.atDate;
       let projectName = this.props.projectName
       let contact = this.props.contact
       let productAndService = this.props.productAndService
       return ( 
            <React.Fragment>
                {
                    dataChunked.map((data, pageIndex)=>{ 
                        console.log('data', data)                                        
                        return (                        
                            <div className='print-page-a4 hoziA4' key={pageIndex}>
                            <div>
                            <p className='f12' style={{ lineHeight: 1.5 }}>{projectName}</p>
                            <p className='f10'>รายงานลูกหนี้รายบุคคล</p>
                            <p className='f10'>เรียงตามชื่อ - {contact}</p>
                            <p className='f10'>สินค้าและบริการ - {productAndService}</p>
                            
                            <p className='f10'>ณ วันที่ {format(atDate, "DD/MM/YYYY")}</p>                   
                            </div>    
                            <table className="table tebleBorder">
                                <thead className='tableHead'>
                                    <tr>
                                        <th className="text-center width9">บ้านเลขที่/เลขที่ห้อง</th>
                                        <th className="text-center width4">ชื่อ</th>
                                        <th className="text-center width8">วันที่ออก</th>
                                        <th className="text-center width8">วันที่ครบกำหนด</th>
                                        <th className="text-center width8">เลขที่</th>
                                        <th className="text-center width5">รหัส</th>
                                        <th className="text-center">รายละเอียด</th>
                                        <th className="text-center width9">วันที่เกินกำหนด (วัน)</th>
                                        <th className="text-center width7">จำนวนเงิน</th>
                                        <th className="text-center width7">ชำระแล้ว</th>
                                        <th className="text-center width7">ค้างชำระ</th>
                                        {this.props.haveNoFilterContact && <th className="text-center width7">รวมค้างชำระ</th>}
                                    </tr>
                                </thead>                 
                                <tbody className="tableBody">
                                    {
                                        data.map((detail, index)=>{ 
                                            console.log('detail', detail)
                                            // บรรทัดแสดงชื่อ
                                            if(detail[0]){
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center f10">{detail[0]}</td>
                                                        <td className="text-left f10" colSpan={`${this.props.haveNoFilterContact ? 11 : 10}`}>
                                                            {detail[1]}</td>
                                                    </tr> ) 
                                            } else if(detail[7] === 'รวมทั้งหมด'){
                                                return (
                                                <tr key={index}>
                                                    <td className="text-center f10">{detail[0]}</td>
                                                    <td className="text-center f10">{detail[1]}</td>
                                                    <td className="text-center f10">{detail[2]}</td>                                                    
                                                    <td className="text-center f10">{detail[3]}</td>
                                                    <td className="text-center f10">{detail[4]}</td>    
                                                    <td className="text-right f10">{detail[5]}</td>                                                                                                
                                                    <td className="text-right f10">{detail[6]}</td>
                                                    <td className="text-right f10"><b>{detail[7]}</b></td>
                                                    <td className="text-right f10"><b>{detail[8]}</b></td>
                                                    <td className="text-right f10"><b>{detail[9]}</b></td>
                                                    <td className="text-right f10"><b>{detail[10]}</b></td>
                                                    {this.props.haveNoFilterContact && <td className="text-right f10"><b>{detail[11]}</b></td>}
                                                </tr> )   
                                            } else {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center f10">{detail[0]}</td>
                                                        <td className="text-center f10">{detail[1]}</td>
                                                        <td className="text-center f10">{detail[2]}</td>                                                        
                                                        <td className="text-center f10">{detail[3]}</td>
                                                        <td className="text-center f10">{detail[4]}</td>   
                                                        <td className="text-center f10">{detail[5]}</td>                                                                                                  
                                                        <td className="text-center f10">{detail[6]}</td>
                                                        <td className="text-center f10">{detail[7]}</td>
                                                        <td className="text-right f10">{detail[8]}</td>
                                                        <td className="text-right f10">{detail[9]}</td>
                                                        <td className="text-right f10">{detail[10]}</td>
                                                        {this.props.haveNoFilterContact && <td className="text-right f10">{detail[11]}</td>}
                                                    </tr> )        
                                            }                                                             
                                        })
                                    }                                                                             
                                </tbody>       
                            </table>
                            <div className="footer">
                                <div className="row">
                                    <div className="col-5" />
                                        <div className="col-2 text-center">
                                            <p className="f10 pb-0 mb-0">{pageIndex + 1}/{dataChunked.length}</p>
                                        </div>
                                        <div className="col-5 text-right">
                                            <p className="f10 pb-0 mb-0">
                                                ผู้พิมพ์ {printBy} วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                            </p>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        )})               
                }
            </React.Fragment>
        )
    }
}

export default IndividualReceiptReportPDFRenderTable;