import React, { Component } from 'react'
import { format } from 'date-fns'
import _ from "lodash"
import numberWithComma from '../../libs/numberWithComma'
import thLocale from "date-fns/locale/th";
import "./budgetLastYearCompareWithMonthPaper.scss"
import Swal from 'sweetalert2';
import i18next from 'i18next';

export default class budgetLastYearCompareWithMonthPaper extends Component {

  constructor(props) {
    super(props)

    this.state = window.localStorage.getItem("stateLastYearCompareWithMonth") ? JSON.parse(window.localStorage.getItem("stateLastYearCompareWithMonth")) : {};
    this.state.bodyTable = []
  }

  componentWillMount() {
    if (this.props.state) {
      window.localStorage.setItem("stateLastYearCompareWithMonth", JSON.stringify(this.props.state))
      this.setState(this.props.state)
    }
  }

  summaryRevenueYear = (codeValue, indexMonth) => {
    let result = 0;
    let sumRevenue_summaryYear_total_budget = 0;
    let sumRevenue_total_happend = 0;
    let sumRevenue_total_budget = 0;
    let sumRevenue_total_month = 0;

    sumRevenue_summaryYear_total_budget = _.sumBy(this.state.query.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
    sumRevenue_total_happend = _.sumBy(this.state.query.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
    sumRevenue_total_budget = _.sumBy(this.state.query.lists[0]?.children, (o) => parseFloat(o.data.total_budget || 0))
    sumRevenue_total_month = _.sumBy(this.state.query.lists[0]?.children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))

    switch (codeValue) {
      case "4000-00 summaryYear_total_budget":
        result = sumRevenue_summaryYear_total_budget
        break;
      case "4000-00 total_happend":
        result = sumRevenue_total_happend
        break;
      case "4000-00 total_budget":
        result = sumRevenue_total_budget
        break;
      case `4000-00 total_month[${indexMonth}]`:
        result = sumRevenue_total_month
        break;

      default:
        break;
    }
    return result;
  }

  summaryExpenseYear = (codeValue, indexMonth) => {
    let result = 0;
    let sumExpense_otherExpenses_summaryYear_total_budget = 0;
    let sumExpense_otherExpenses_total_happend = 0;
    let sumExpense_otherExpenses_total_budget = 0;
    let sumExpense_otherExpenses_total_month = 0;

    let sumExpense_items_summaryYear_total_budget = 0;
    let sumExpense_items_total_happend = 0;
    let sumExpense_items_total_budget = 0;
    let sumExpense_items_total_month = 0;

    if (codeValue.substring(0, 7) === "5800-00") {
      _.forIn(this.state.query.lists[1].children[8].children, function (item) {
        sumExpense_otherExpenses_summaryYear_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_budget || 0))
        sumExpense_otherExpenses_total_happend += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_happend || 0))
        sumExpense_otherExpenses_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.total_budget || 0))
        sumExpense_otherExpenses_total_month += _.sumBy(item.children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      })
    } else {
      if (codeValue.substring(0, 7) === "5100-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[0].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.state.query.lists[1].children[0].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5200-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[1].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.state.query.lists[1].children[1].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5300-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[2].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.state.query.lists[1].children[2].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5310-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[3].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.state.query.lists[1].children[3].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5400-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[4].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.state.query.lists[1].children[4].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5500-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[5].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.state.query.lists[1].children[5].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5600-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[6].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.state.query.lists[1].children[6].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5700-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.state.query.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.state.query.lists[1].children[7].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.state.query.lists[1].children[7].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }

    }

    switch (codeValue) {
      case "5800-00 summaryYear_total_budget":
        result = sumExpense_otherExpenses_summaryYear_total_budget
        break;
      case "5800-00 total_happend":
        result = sumExpense_otherExpenses_total_happend
        break;
      case "5800-00 total_budget":
        result = sumExpense_otherExpenses_total_budget
        break;
      case "5800-00 total_month":
        result = sumExpense_otherExpenses_total_month
        break;
      case `${codeValue.substring(0, 7)} summaryYear_total_budget`:
        result = sumExpense_items_summaryYear_total_budget
        break;
      case `${codeValue.substring(0, 7)} total_happend`:
        result = sumExpense_items_total_happend
        break;
      case `${codeValue.substring(0, 7)} total_budget`:
        result = sumExpense_items_total_budget
        break;
      case `${codeValue.substring(0, 7)} total_month`:
        result = sumExpense_items_total_month
        break;

      default:
        break;
    }
    return result;
  }

  checkMonth(monthInput) {
    let resultMonth = ""
    let monthFormat = format(new Date(monthInput), "MMM")
    let yearFormat = format(new Date(monthInput), "YY")

    switch (monthFormat) {
      case "Jan":
        resultMonth = "ม.ค."
        break;
      case "Feb":
        resultMonth = "ก.พ."
        break;
      case "Mar":
        resultMonth = "มี.ค."
        break;
      case "Apr":
        resultMonth = "เม.ย."
        break;
      case "May":
        resultMonth = "พ.ค."
        break;
      case "Jun":
        resultMonth = "มิ.ย."
        break;
      case "Jul":
        resultMonth = "ก.ค."
        break;
      case "Aug":
        resultMonth = "ส.ค."
        break;
      case "Sep":
        resultMonth = "ก.ย."
        break;
      case "Oct":
        resultMonth = "ต.ค."
        break;
      case "Nov":
        resultMonth = "พ.ย."
        break;
      case "Dec":
        resultMonth = "ธ.ค."
        break;

      default:
        break;
    }
    return resultMonth + yearFormat
  }

  componentDidMount() {
    this.setData()

    if(this.state.query.lists[0].children[0].data.month_budget.length > 4){
      Swal.fire(i18next.t("budgetLastYearCompareWithMonthPaper:Data exceeds paper margins."), i18next.t("budgetLastYearCompareWithMonthPaper:Please export the data to Excel"), 'warning')
    }
  }

  setData() {
    let last_index = 0;
    let last_index_child = 0;
    let summaryExpenseTotalMonth = [];
    let summaryExpenseTotalMonthDiffSumRevenue = [];
    let expenseDiffRevenueSummaryYearTotalBudget = 0;
    let expenseDiffRevenueTotalHappend = 0;
    let expenseDiffRevenueTotalBudget = 0;

    let data1 = []
    _.map(this.state.query.lists[0]?.children, (item, index) => {
      ++last_index;

      let valueData = []
      let valueNullZero = []
      let valueMonthZero = []

      _.map(item.data.month_budget, (month, index_month) => {
        valueNullZero.push({ budget: "" })
        valueMonthZero.push({ budget: month.budget ? (month.budget) : "" })
      })

      if (index === 0) {

        valueData["node"] =

        data1.push({ no: '', name: 'รายรับ', summaryYearTotle: '', summaryYearTotalHappend: '', monthBudget: valueNullZero, totalBudget: "" })
        data1.push({
          no: `${last_index}`,
          name: `${item.data.name}`,
          summaryYearTotle: `${item.data.summaryYear.total_budget ? (item.data.summaryYear.total_budget) : ""}`,
          summaryYearTotalHappend: `${item.data?.summaryYear?.total_happend ? (item.data?.summaryYear?.total_happend) : ""}`,
          monthBudget: valueMonthZero,
          totalBudget: `${item.data?.total_budget ? (item.data?.total_budget) : ""}`,
        })

      } else if (index === (this.state.query.lists[0].children.length - 1)) {

        data1.push({
          no: `${last_index}`, 
          name: `${item.data.name}`, 
          summaryYearTotle: `${item.data.summaryYear.total_budget ? (item.data.summaryYear.total_budget) : ""}`, 
          summaryYearTotalHappend: `${item.data?.summaryYear?.total_happend ? (item.data?.summaryYear?.total_happend) : ""}`, 
          monthBudget: valueMonthZero,
          totalBudget: `${item.data?.total_budget ? (item.data?.total_budget) : ""}`,
        })

        let summaryRevenueArr = []

        {
          this.state.query.lists[0].children[0].data.month_budget.map((item, index) => {

            summaryExpenseTotalMonthDiffSumRevenue[index] = this.summaryRevenueYear(`4000-00 total_month[${index}]`, index) || 0

            summaryRevenueArr.push(this.summaryRevenueYear(`4000-00 total_month[${index}]`, index) !== 0
              ? (this.summaryRevenueYear(`4000-00 total_month[${index}]`, index).toFixed(2)) : "-")
              return item
          })
        }
        data1.push({
          no: "", 
          name: "รวมรายรับ", 
          summaryYearTotle: `${this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0) !== 0 ? (this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0).toFixed(2)) : "-"}`, 
          summaryYearTotalHappend: `${this.summaryRevenueYear("4000-00 total_happend", 0) !== 0 ? (this.summaryRevenueYear("4000-00 total_happend", 0).toFixed(2)) : "-"}`, 
          monthBudget: summaryRevenueArr,
          totalBudget: `${this.summaryRevenueYear("4000-00 total_budget", 0) !== 0 ? (this.summaryRevenueYear("4000-00 total_budget", 0).toFixed(2)) : "-"}`
        })
      } else {

        data1.push({
          no: `${last_index}`, 
          name: `${item.data.name}`, 
          summaryYearTotle: `${item.data.summaryYear.total_budget ? (item.data.summaryYear.total_budget) : ""}`, 
          summaryYearTotalHappend: `${item.data?.summaryYear?.total_happend ? (item.data?.summaryYear?.total_happend) : ""}`, 
          monthBudget: valueMonthZero,
          totalBudget: `${item.data?.total_budget ? (item.data?.total_budget) : ""}`,
        })

      }
    })

    let valueNull = []

    {_.map(this.state.query.lists[0].children[0].data.month_budget, (item, index) => {
      valueNull.push({ budget: "" })
    })}

    {_.map(this.state.query.lists[1]?.children, (item, index_code) => {
      expenseDiffRevenueSummaryYearTotalBudget += this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`, 0)
      expenseDiffRevenueTotalHappend += this.summaryExpenseYear(`${item.data.code} total_happend`, 0)
      expenseDiffRevenueTotalBudget += this.summaryExpenseYear(`${item.data.code} total_budget`, 0)

      if(index_code === 0){
        data1.push({
          no: '', 
          name: 'รายจ่าย', 
          summaryYearTotle: '', 
          summaryYearTotalHappend: '', 
          monthBudget: valueNull, 
          totalBudget: ""
        })
      }

      data1.push({
        no: '', 
        name: `${item.data.name}`, 
        summaryYearTotle: '', 
        summaryYearTotalHappend: '', 
        monthBudget: valueNull, 
        totalBudget: ""
      })

      _.map(item.children, (item_child, index) => {
        if (index_code !== 8) { ++last_index }

        if(item_child.children?.length > 0){
          data1.push({
            no: '', 
            name:`${item_child.data.name}`, 
            summaryYearTotle: '', 
            summaryYearTotalHappend: '', 
            monthBudget: valueNull, 
            totalBudget: ""
          })

          _.map(item_child?.children, (item_child_child, index_child_child) => {
            ++last_index_child

            let valueMonth_child_child = []

            {_.map(item_child_child.data.month_budget, (month, index_month) => {
              valueMonth_child_child.push({ budget: month.budget ? (month.budget) : ""})
            })}

            data1.push({
              no: `${last_index + last_index_child}`, 
              name: `${item_child_child.data.name}`, 
              summaryYearTotle: `${item_child_child.data?.summaryYear?.total_budget ? (item_child_child.data?.summaryYear?.total_budget) : ""}`, 
              summaryYearTotalHappend: `${item_child_child.data?.summaryYear?.total_happend ? (item_child_child.data?.summaryYear?.total_happend) : ""}`, 
              monthBudget: valueMonth_child_child,
              totalBudget: `${item_child_child.data?.total_budget ? (item_child_child.data?.total_budget) : ""}`, 
            })

          })
        }else{

          let valueMonth_child = []

          {_.map(item_child.data.month_budget, (month, index_month) => {
            valueMonth_child.push({ budget: month.budget ? (month.budget) : ""})
          })}

          data1.push({
            no: `${last_index + last_index_child}`, 
            name:  `${item_child.data.name}`, 
            summaryYearTotle: `${item_child.data?.summaryYear?.total_budget ? (item_child.data?.summaryYear?.total_budget) : ""}`, 
            summaryYearTotalHappend: `${item_child.data?.summaryYear?.total_happend ? (item_child.data?.summaryYear?.total_happend) : ""}`, 
            monthBudget: valueMonth_child,
            totalBudget: `${item_child.data?.total_budget ? (item_child.data?.total_budget) : ""}`, 
          })
        }
      })


      let summaryExpenseYearArr = []
      
      {_.map(this.state.query.lists[0].children[0].data.month_budget, (item_month, index_month) => {

        typeof(summaryExpenseTotalMonth[index_month]) !== "number"
          ? summaryExpenseTotalMonth[index_month] = this.summaryExpenseYear(`${item.data.code} total_month`, index_month)
            : summaryExpenseTotalMonth[index_month] += this.summaryExpenseYear(`${item.data.code} total_month`, index_month);

        summaryExpenseYearArr.push({ budget: this.summaryExpenseYear(`${item.data.code} total_month`, index_month) !== 0 ?
          (this.summaryExpenseYear(`${item.data.code} total_month`, index_month)) : "-" })
      })}

      data1.push({
        no: "", 
        name: `รวม${item.data.name}`, 
        summaryYearTotle: `${this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`, 0) !== 0 ? (this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`, 0).toFixed(2)) : "-"}`, 
        summaryYearTotalHappend: `${this.summaryExpenseYear(`${item.data.code} total_happend`, 0) !== 0 ? (this.summaryExpenseYear(`${item.data.code} total_happend`, 0).toFixed(2)) : "-"}`,
        monthBudget: summaryExpenseYearArr,
        totalBudget: `${this.summaryExpenseYear(`${item.data.code} total_budget`, 0) !== 0 ? (this.summaryExpenseYear(`${item.data.code} total_budget`, 0).toFixed(2)) : "-"}`, 
      })
    })}

    let expenseDiffRevenueMonth = []

    {_.map(this.state.query.lists[0].children[0].data.month_budget, (item, index) => {

      summaryExpenseTotalMonthDiffSumRevenue[index] += (-summaryExpenseTotalMonth[index])

      expenseDiffRevenueMonth.push({ budget:
        summaryExpenseTotalMonth.length > 0 
          ? summaryExpenseTotalMonth[index] !== 0 
            ? (summaryExpenseTotalMonth[index].toFixed(2)) 
            : "-" 
          : "-"
      })
      
    })}

    data1.push({
      no: "", 
      name: `รวมรายจ่าย`, 
      summaryYearTotle: `${expenseDiffRevenueSummaryYearTotalBudget !== 0 ? numberWithComma(expenseDiffRevenueSummaryYearTotalBudget.toFixed(2)) : "-"}`, 
      summaryYearTotalHappend: `${expenseDiffRevenueTotalHappend !== 0 ? numberWithComma(expenseDiffRevenueTotalHappend.toFixed(2)) : "-"}`, 
      monthBudget: expenseDiffRevenueMonth,
      totalBudget: `${expenseDiffRevenueTotalBudget !== 0 ? expenseDiffRevenueTotalBudget.toFixed(2) : "-"}`, 
    })

    let summaryExpenseTotalMonthDiffSumRevenueMonth = []

    {_.map(this.state.query.lists[0].children[0].data.month_budget, (item, index) => {

      summaryExpenseTotalMonthDiffSumRevenueMonth.push({ budget:
        summaryExpenseTotalMonthDiffSumRevenue.length > 0 
          ? summaryExpenseTotalMonthDiffSumRevenue[index] !== 0 
            ? (summaryExpenseTotalMonthDiffSumRevenue[index].toFixed(2))
            : "-" 
          : "-"
      })
    })}

    data1.push({
      no: "", 
      name: `รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด`, 
      summaryYearTotle: `${(this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0) - expenseDiffRevenueSummaryYearTotalBudget) !== 0
          ? ((this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0) - expenseDiffRevenueSummaryYearTotalBudget).toFixed(2))
          : "-"}`, 
      summaryYearTotalHappend: `${(this.summaryRevenueYear("4000-00 total_happend", 0) - expenseDiffRevenueTotalHappend) !== 0 
          ? ((this.summaryRevenueYear("4000-00 total_happend", 0) - expenseDiffRevenueTotalHappend).toFixed(2))
          : "-"}`, 
      monthBudget: summaryExpenseTotalMonthDiffSumRevenueMonth,
      totalBudget: `${(this.summaryRevenueYear("4000-00 total_budget", 0) - expenseDiffRevenueTotalBudget) !== 0 
          ? ((this.summaryRevenueYear("4000-00 total_budget", 0) - expenseDiffRevenueTotalBudget).toFixed(2)) 
          : "-"}`
    })

    this.setState({ bodyTable: _.chunk(data1, 12) })
  }

  render() {

    let lastYear = new Date(this.state.query?.temp_year)
    lastYear = lastYear.getFullYear() - 1

    return (
      _.map(this.state.bodyTable, (valueList, index) => {
        return (
          <React.Fragment>
            <div id="lastYearCompareWithMonth" className="page">
              <div className="print-interest-report">
                <div className="subpage">
                  <div className="head">
                    <div className="headTextCenter text-center font-weight-bold">
                      <div>{this.props.selfProject.name}</div>
                      <div>{i18next.t("budgetDraftForm:Drafting a Budget")}</div>
                      <div>{i18next.t("budgetLastYearCompareWithMonthPaper:From Date")} {`${format(this.state.query.start_date, " D MMMM YYYY ", { locale: thLocale, })}`} {i18next.t("budgetLastYearCompareWithMonthPaper:To")} {`${format(this.state.query.end_date, " D MMMM YYYY ", { locale: thLocale, })}`}</div>
                    </div>
                  </div>

                  <div className="lastYearCompareWithMonth-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th rowSpan={2} className="text-center" style={{ minWidth: 20 }}>
                          {i18next.t("budgetLastYearCompareWithMonthPaper:Sequence")}
                          </th>
                          <th rowSpan={2} className="text-center" style={{ minWidth: 270 }}>
                          {i18next.t("budgetLastYearCompareWithMonthPaper:Item")}
                          </th>
                          <th
                            colSpan={2}
                            className="text-center"
                          >
                            {i18next.t("budgetLastYearCompareWithMonthPaper:Annual Budget")} {lastYear}
                          </th>
                          <th
                            colSpan={this.state.query.lists[0].children[0].data.month_budget.length > 4 ? 4 : this.state.query.lists[0].children[0].data.month_budget.length}
                            className="text-center"
                          >
                            {i18next.t("budgetLastYearCompareWithMonthPaper:Annual Budget")} {format(this.state.query.temp_start_date, "YYYY")}
                          </th>
                          <th rowSpan={2} className="text-center" style={{ minWidth: 120 }}>
                          {i18next.t("budgetLastYearCompareWithMonthPaper:Total Budget")}
                          </th>
                        </tr>
                        <tr className="border-top">
                          <th className="text-center" style={{ minWidth: 90 }}>{i18next.t("budgetLastYearCompareWithMonthPaper:Budget")}</th>
                          <th className="text-center" style={{ minWidth: 90 }}>{i18next.t("budgetLastYearCompareWithMonthPaper:Actual")}</th>
                          {this.state.query.lists[0].children[0].data.month_budget.map((item, index) => {
                            if(index < 4) {
                              return (
                                <th
                                  key={index}
                                  className="text-center"
                                  style={{ minWidth: 90 }}
                                >{this.checkMonth(item.month_date)}
                                </th>
                              )
                            }
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(valueList, (item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td className="text-center">{item.no}</td>
                                <td>{item.name}</td>
                                <td className="text-center">{item.summaryYearTotle ? numberWithComma(item.summaryYearTotle) : ""}</td>
                                <td className="text-center">{item.summaryYearTotalHappend ? numberWithComma(item.summaryYearTotalHappend) : ""}</td>
                                {_.map(item.monthBudget, (valueMonth, indexMonthBudget) => {
                                  if(indexMonthBudget < 4){
                                    return (
                                      <td className="text-center" key={indexMonthBudget}>{valueMonth.budget ? numberWithComma(valueMonth.budget) : ""}</td>
                                    )
                                  }
                                })}
                                <td className="text-center">{item.totalBudget ? numberWithComma(item.totalBudget) : ""}</td>
                              </tr>
                            </React.Fragment>
                            
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </React.Fragment>
        )
      })
    )
  }
}
