import React, { Component } from 'react'
import Header from '../../components/header'
import Sidebar from '../../components/sidebar'
import Wrapper from '../../components/wrapper'
import WrapperContent from '../../components/wrapper/wrapperContent'
import StockTopMenuNavigation from '../stockTopMenuNavigation'

export default class dashboardStock extends Component {
  render() {
    return (
      <Wrapper>
        <Header/>
        <Sidebar/>
        <WrapperContent>
          <StockTopMenuNavigation center={true}/>
        </WrapperContent>
      </Wrapper>
    )
  }
}
