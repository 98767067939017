/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type contractServiceCreateFormQueryVariables = {|
  id?: ?string,
  issuedDate?: ?any,
|};
export type contractServiceCreateFormQueryResponse = {|
  +productViewer: ?{|
    +allProduct: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +productCode: string,
        |}
      |}>,
    |}
  |},
  +contractRevenueDocNumber: ?string,
  +allProjects: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +periodCreate: string
      |}
    |}>
  |},
|};
export type contractServiceCreateFormQuery = {|
  variables: contractServiceCreateFormQueryVariables,
  response: contractServiceCreateFormQueryResponse,
|};
*/


/*
query contractServiceCreateFormQuery(
  $id: String
  $issuedDate: Date
) {
  productViewer {
    allProduct(chartOfAccountId: $id, pricingType: "static") {
      totalCount
      edges {
        node {
          id
          name
          productCode
        }
      }
    }
    id
  }
  contractRevenueDocNumber(issuedDate: $issuedDate)
  allProjects {
    edges {
      node {
        periodCreate
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issuedDate"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "chartOfAccountId",
      "variableName": "id"
    },
    {
      "kind": "Literal",
      "name": "pricingType",
      "value": "static"
    }
  ],
  "concreteType": "ProductAndServiceNodeConnection",
  "kind": "LinkedField",
  "name": "allProduct",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAndServiceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "issuedDate",
      "variableName": "issuedDate"
    }
  ],
  "kind": "ScalarField",
  "name": "contractRevenueDocNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodCreate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contractServiceCreateFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contractServiceCreateFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d99ca6ce2c891cfa47fa1c6ec6f70d5f",
    "id": null,
    "metadata": {},
    "name": "contractServiceCreateFormQuery",
    "operationKind": "query",
    "text": "query contractServiceCreateFormQuery(\n  $id: String\n  $issuedDate: Date\n) {\n  productViewer {\n    allProduct(chartOfAccountId: $id, pricingType: \"static\") {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          productCode\n        }\n      }\n    }\n    id\n  }\n  contractRevenueDocNumber(issuedDate: $issuedDate)\n  allProjects {\n    edges {\n      node {\n        periodCreate\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'adfe03ce46667756064af8fffddd218a';

module.exports = node;
