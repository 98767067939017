import React from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Translation } from "react-i18next";
import ComponentPagination from "../../../libs/componentPagination";
import { format } from "date-fns";
import "../../../project/staff/styles/staffList.scss"
import Swal from "sweetalert2";
import _ from "lodash"
import i18n from "i18next";
import { Link } from 'react-router-dom';
import SeacrhDetails from '../../../components/search/indexSearch';
import APIInsur from '../../../api/insurrance'
import ReactPaginate from 'react-paginate';
import ClaimTopMenuNavigation from '../claimTopMenuNavigation';
import i18next from 'i18next';


class InsurList extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            image_light_box: "",
            open_light_box: false,
            redirect: false,

            index_f: 0,
            index_l: 10,
            pageSize: 10,
            pageNow: 1,
            pageAll: 1,
            start_date: "",
            end_date: "",
            typeFilter: "",


            selectedShow: [
                { value: "", label: i18next.t("Insured:All") },
                { value: "กรมธรรม์เก่า-ล่าสุด", label: i18next.t("Insured:Oldest-Latest policy") },
                { value: "วงเงินสูงที่สุด", label: i18next.t("Insured:Maximum limit") },
                { value: "วงเงินต่ำที่สุด", label: i18next.t("Insured:Minimum limit") },
                { value: "เบี้ยต่ำที่สุด", label: i18next.t("Insured:Minimum premium") },
                { value: "เบี้ยสูงสุด", label: i18next.t("Insured:Maximum premium") }
            ],
            insurance_detail: [],
            totalCountRecord: 0
        }

        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.functionCurrentMoneyFormat = this.functionCurrentMoneyFormat.bind(this)
        this.handlePageSize = this.handlePageSize.bind(this)
        this.handlePropsChange = this.handlePropsChange.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        let dataParams = {
            index_first: this.state.index_f,
            index_last: this.state.index_l,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            search: this.state.search
        }

        APIInsur.getDataInsurance(dataParams).then((res) => {
            if (res.data.insurance_list) {
                this.setState({
                    insurance_detail: res.data.insurance_list,
                    totalCountRecord: res.data.num_record,
                    pageAll: Math.ceil(res.data.num_record / this.state.pageSize),
                })
            }
        })
    }

    handlePageSize = (e) => {
        this.setState({ pageSize: parseInt(e.target.value), pageNow: 1, index_f: 0, index_l: parseInt(e.target.value), insurance_detail: [] }, () => {
            this.getData()
        })
    }

    handlePageClick = (event) => {
        let pageNow = event.selected + 1;
        let index_f = this.state.pageSize * event.selected
        let index_l = this.state.pageSize * pageNow
        this.setState({ pageNow, index_f, index_l, insurance_detail: [] }, () => {
            this.getData()
        });
    };


    onChangeSearch(text) {
        this.setState({ search: text });
    }

    onDelete(insurId) {
        Swal.fire({
            title: i18n.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t("claim:Yes"),
            cancelButtonText: i18n.t("claim:No")
        }).then((result) => {
            if (result.value) {
                let variables = {
                    input: {
                        id: insurId
                    }
                };

                // commitMutation(
                //     environment,
                //     {
                //         mutation,
                //         variables,
                //         onCompleted: (response) => {
                //             if (response.deleteStaff.ok) {
                //                 Swal.fire(i18n.t("claim:Remove successfully!"), '', 'success').then(() => {
                //                     this.setState({reQuery: !this.state.reQuery})
                //                 });
                //             } else {
                //                 Swal.fire(i18n.t("claim:Remove unsuccessfully!"), '', 'warning'
                //                 );
                //             }
                //         },
                //         onError: (err) => {
                //             Swal.fire('Error!', i18n.t("claim:Please try again"), 'warning')
                //         },
                //     },
                // )
            }
        })
    }

    handlePropsChange(channel_list) {
        let search = ""
        let start_date = null
        let end_date = null
        let status = ""

        channel_list.forEach((e, index) => {

            if (e.name === "search") {
                search = e.value
            }
            else if (e.name === "start_date" && e.value !== '') {
                start_date = e.value
            }
            else if (e.name === "end_date" && e.value !== '') {
                end_date = e.value
            } else if (e.name === "status" && e.value !== '') {
                status = e.value
            }

        });

        this.setState({
            search: search,
            start_date: start_date,
            end_date: end_date,
            typeFilter: status
        }, () => this.getData())
    }

    functionCurrentMoneyFormat(money) {
        let formatMoney = 0
        if (money) {
            formatMoney = parseFloat(money).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
        return formatMoney ? formatMoney : "-"
    }

    render() {

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <ClaimTopMenuNavigation mini={true} />
                    <Translation >
                        {
                            t =>
                                <div className="container-fluid box claim">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/purchasing">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span>{t("claim:Insurrance Company Lists")}</span>

                                            </h3>
                                        </div>
                                        <div className="col text-right searchFunction">
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'claim_create' }) &&   
                                                <Link to="/purchasing/insurrance/create">
                                                    <button className="btn-primary ml-3">+&nbsp; {t("claim:Add Insurrance Company")} </button>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                    <SeacrhDetails
                                        text={[t("claim:Choose Show")]}
                                        seleteDetail={
                                            [
                                                { value: "", label: t("Insured:All") },
                                                { value: "กรมธรรม์เก่า-ล่าสุด", label: t("Insured:Oldest-Latest policy") },
                                                { value: "วงเงินสูงที่สุด", label: t("Insured:Maximum limit") },
                                                { value: "วงเงินต่ำที่สุด", label: t("Insured:Minimum limit") },
                                                { value: "เบี้ยต่ำที่สุด", label: t("Insured:Minimum premium") },
                                                { value: "เบี้ยสูงสุด", label: t("Insured:Maximum premium") }
                                            ]
                                        }
                                        selectIs={true}
                                        startDateIs={true}
                                        endDateIs={true}
                                        searchBoxId={true}
                                        search={this.state.search}
                                        start_date={this.state.start_date}
                                        end_date={this.state.end_date}
                                        handlePropsChange={this.handlePropsChange}
                                    />


                                    <div className="content-inner">
                                        <div className="row justify-content-end mb-2">
                                            <div className="col-3">
                                            </div>
                                        </div>

                                        <div className="card fade-up">
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead className="thead-light">
                                                        <tr className='text-center'>
                                                            <th />
                                                            <th>{t("claim:no.")}</th>
                                                            <th>{t("claim:Policy number")}</th>
                                                            <th>{t("claim:Start protection")}</th>
                                                            <th>{t("claim:End of contract")}</th>
                                                            <th>{t("claim:Insurrance Company")}</th>
                                                            <th>{t("claim:Protection limit (Bath)")}</th>
                                                            <th>{t("claim:Claim amount (Baht)")}</th>
                                                            <th>{t("claim:Approved amount (Baht)")}</th>
                                                            <th>{t("claim:Insurance premium (baht)")}</th>
                                                            <th>{t("claim:Tel")}</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.insurance_detail?.map((n, inx) => {
                                                                let no = parseInt((this.state.totalCountRecord - inx) - this.state.index_f)
                                                                // let no = 0
                                                                return (
                                                                    <tr key={inx} className='text-center'>
                                                                        <td className='tag'>{inx === 0 && <span className='tagDanger'>{i18n.t("Insured:Latest")}</span>}</td>
                                                                        <td>{no > 99 ? no : no > 9 ? "0" + no : "00" + no}</td>
                                                                        <td>
                                                                            <Link to={"/purchasing/insurrance/insur-detail/" + n.insurance_detail.id + "/Info"}>
                                                                                {n.insurance_detail.policy_number}
                                                                            </Link>
                                                                        </td>
                                                                        <td>{format(n.insurance_detail.insurance_start_date, "DD/MM/YYYY")}</td>
                                                                        <td>{format(n.insurance_detail.insurance_end_date, "DD/MM/YYYY")}</td>
                                                                        <td>{n.insurance_detail.main_insurance_company.name}</td>
                                                                        <td>{this.functionCurrentMoneyFormat(n.insurance_detail.coverage_limit)}</td>
                                                                        <td>{this.functionCurrentMoneyFormat(n.insurance_detail.claim_amount)}</td>
                                                                        <td>{this.functionCurrentMoneyFormat(n.insurance_detail.coverage_limit)}</td>
                                                                        <td>{this.functionCurrentMoneyFormat(n.insurance_detail.insurance_premium)}</td>
                                                                        <td>{n.insurance_detail.main_insurance_company_tel || "-"}</td>
                                                                        <td />
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className='row mt-5'>
                                            <div className='col-6'>
                                                <select className='page' onChange={this.handlePageSize}>
                                                    <option value="10">{t("silvermanguard:show")} 10 {t("silvermanguard:items")}</option>
                                                    <option value="20">{t("silvermanguard:show")} 20 {t("silvermanguard:items")}</option>
                                                    <option value="50">{t("silvermanguard:show")} 50 {t("silvermanguard:items")}</option>
                                                    <option value="100">{t("silvermanguard:show")} 100 {t("silvermanguard:items")}</option>
                                                </select>
                                            </div>
                                            <div className='col-6 text-right'>
                                                <div className='pullright' style={{ marginTop: '22px' }}>
                                                    {t("silvermanguard:listAll")} {this.state.totalCountRecord} &nbsp;

                                                    {this.state.pageAll > 0 && (
                                                        <ReactPaginate style={{ marginTop: '-7px' }}
                                                            previousLabel={"previous"}
                                                            nextLabel={"next"}
                                                            breakLabel={<span className="btn page-link">...</span>}
                                                            breakClassName={"break-me"}
                                                            pageClassName="page-item"
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={4}
                                                            previousClassName="sr-only"
                                                            containerClassName={"pagination float-right"}
                                                            subContainerClassName={"pagination-page"}
                                                            nextLinkClassName="sr-only"
                                                            pageLinkClassName="page-link"
                                                            activeClassName={"active"}
                                                            pageCount={this.state.pageAll}
                                                            onPageChange={this.handlePageClick}
                                                        />
                                                    )}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default InsurList;
