import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const query = graphql`
  query invoiceViewerQueryQuery($id: ID!) {
    invoiceViewer{
        allInvoiceByStatus(statusList:["active","overdue","partial_payment"],contact_Id: $id, order: "doc_number"){
            totalCount
            edges{
                node{
                    id
                    docNumber
                    total
                    issuedDate
                    dueDate
                    status
                    taxType
                    transaction(status_In: "active, overdue, partial_payment"){
                        totalCount
                        edges{
                            node{
                                id
                                description
                                unitItems
                                discount
                                price
                                preTaxAmount
                                vat
                                paid
                                whtRate
                                whtRatePercent
                                total
                                productAndService{
                                    productCode
                                }
                                
                                receiptDepositTransactionUsed {
                                    edges{
                                        node{
                                            id
                                            amount
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  }
`;

const invoiceViewerQuery = (id) => {
  return new Promise((resolve, reject) => {
      fetchQuery(environment, query, {id : id}).then(data => {
          resolve(data)
      });
  });

};

export default invoiceViewerQuery;
