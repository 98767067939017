
const getNameResidentialRent = (rent) => {
    let name = '';
    rent.forEach(element => {
        const first_name = element.node.tenant.firstName ? element.node.tenant.nameTitle+ element.node.tenant.firstName + ' ' : ''
        const last_name = element.node.tenant.lastName ? element.node.tenant.lastName + ',' : ''
        name += first_name + ' ' + last_name
    });

    //CO. ,LTD To CO.,LTD
    if(name.includes(". ,")){
        name = name.replace(". ,",".,")
    }

    //find last char ,
    if(name.slice(-1) === ','){
        name = name.replace(/,$/," ")
    }
    
    return name
};

export default getNameResidentialRent;