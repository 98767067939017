import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from "react-i18next";

class Navigation extends Component {
    render() {
        return (
            <Translation>
                {t =>
                    <div className="row" id="navigation-tab">
                        <div className="col">
                            <ul>
                                <li>
                                    <NavLink to="/accounting/water-meter/record">
                                        {t('meter:Record')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/water-meter/compare">
                                        {t('meter:Compare')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/water-meter/history">
                                        {t('meter:History')}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}

export default Navigation;