/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type reportCollectionLawFrimQueryVariables = {||};
export type reportCollectionLawFrimQueryResponse = {|
  +countNotiCollectionLawFirm: ?{|
    +countFollow: ?number,
    +countPaid: ?number,
  |}
|};
export type reportCollectionLawFrimQuery = {|
  variables: reportCollectionLawFrimQueryVariables,
  response: reportCollectionLawFrimQueryResponse,
|};
*/


/*
query reportCollectionLawFrimQuery {
  countNotiCollectionLawFirm {
    countFollow
    countPaid
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CountNotiCollectionLawFirm",
    "kind": "LinkedField",
    "name": "countNotiCollectionLawFirm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countFollow",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countPaid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "reportCollectionLawFrimQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "reportCollectionLawFrimQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "55a75fe9aac207934f7e8ca15a7117dd",
    "id": null,
    "metadata": {},
    "name": "reportCollectionLawFrimQuery",
    "operationKind": "query",
    "text": "query reportCollectionLawFrimQuery {\n  countNotiCollectionLawFirm {\n    countFollow\n    countPaid\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '77916a77e51ef7ec46f3fbafb077d348';

module.exports = node;
