/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PreInvoiceGroupStatus = "DEVELOPER" | "DEVELOPER_SUPPORT" | "OWNER" | "VOID" | "%future added value";
export type settingInvoiceListQueryVariables = {|
  search?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type settingInvoiceListQueryResponse = {|
  +allPreInvoiceGroup: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +contactCode: ?string,
        +status: PreInvoiceGroupStatus,
        +creator: ?string,
        +updated: any,
        +contact: ?{|
          +id: string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |},
      |}
    |}>,
  |}
|};
export type settingInvoiceListQuery = {|
  variables: settingInvoiceListQueryVariables,
  response: settingInvoiceListQueryResponse,
|};
*/


/*
query settingInvoiceListQuery(
  $search: String
  $first: Int
  $last: Int
) {
  allPreInvoiceGroup(search: $search, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        contactCode
        status
        creator
        updated
        contact {
          id
          name
          firstName
          lastName
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "PreInvoiceGroupNodeConnection",
    "kind": "LinkedField",
    "name": "allPreInvoiceGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PreInvoiceGroupNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PreInvoiceGroupNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contactCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingInvoiceListQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "settingInvoiceListQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "1c9b12362afda232abe3941e0df1e2ae",
    "id": null,
    "metadata": {},
    "name": "settingInvoiceListQuery",
    "operationKind": "query",
    "text": "query settingInvoiceListQuery(\n  $search: String\n  $first: Int\n  $last: Int\n) {\n  allPreInvoiceGroup(search: $search, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        contactCode\n        status\n        creator\n        updated\n        contact {\n          id\n          name\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '15862ed4fc02061f2150ccbdfec155a3';

module.exports = node;
