import React, { Component } from 'react'
import Modal from 'react-modal';
import ConcernManageRequest from "../mutation/concernManageRequest"
import VoidManageRequest from "../mutation/voidManageRequest"
import Swal from 'sweetalert2';
import _ from "lodash";
import "../style/manageRequest.scss"
import i18next from 'i18next';

export default class notConfirmManageRequest extends Component {

  constructor(props){
    super(props)

    this.state = {
      statusOnClick: "",
      heightModal: "304px",
      checkRadio: {title: "", detail: ""},
      textWarn: false,
    }
  }

  componentDidMount() {    
    if(this.props.open){
      document.body.style.overflow = 'hidden';
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  checkTextContentHead = () => {
    let textResult = ""

    switch (this.state.statusOnClick) {
      case "concern":
        textResult = (<p className="text-center textContentHead">{i18next.t("notConfirmMangeRequest:Please provide a comment for the purchase order modification")}</p>)
        break;
      case "void":
        textResult = (<p className="text-center textContentHead">{i18next.t("notConfirmMangeRequest:Please state the reason for disapproving the purchase order")}</p>)
        break;
      default:
        textResult = (
                      <p className="text-center textContentHead">
                        {i18next.t("notConfirmMangeRequest:Disapproving the request will result in the cancellation of purchase order number")}<br/>
                        {i18next.t("notConfirmMangeRequest:However, if you want to allow editing the information again, please choose the menu to reject back")}
                      </p>
                    )
        break;
    }

    return textResult
  }

  handleConfirm = () => {

    if(this.state.statusOnClick === "concern"){
      this.checkTextContentHead()
      this.handleConCern()

    }else if(this.state.statusOnClick === "void"){
      this.checkTextContentHead()
      this.handleVoid()

    }
  }

  handleConCern = () => {
    if(this.state.checkRadio.detail){
      this.setState({textWarn: false})

      let variableConcern = {
        input : {
          procurementFormId: this.props.procurementFormId,
          editRemark: this.state.checkRadio.detail
        }
      }

      ConcernManageRequest(
        variableConcern, 
        (res) => { 
          if(res.concernProcurementForm.ok){
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'success').then(() => {
              this.props.handleChangeInput({
                target : {
                  name: "redirectTo",
                  value: '/purchasing/manageRequest/list/all'
                }
              })
            })

          }else{
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please try again."), 'warning')
          }
        }, 
        () => { Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'warning')}
      )
      
      this.setState({statusOnClick: ""})
      this.props.handleCloseModal()
    }else{
      this.setState({textWarn: true})
    }

  }

  handleVoid = () => {
    if(this.state.checkRadio.detail){
      this.setState({textWarn: false})

      let variableVoid = {
        input : {
          procurementFormId: this.props.procurementFormId,
          voidRemark: this.state.checkRadio.detail
        }
      }

      VoidManageRequest(
        variableVoid, 
        (res) => { 
          if(res.voidProcurementForm.ok){
            Swal.fire(i18next.t("Allaction:Successful"), '', 'success').then((res) => {
              this.props.handleChangeInput({
                target : {
                  name: "redirectTo",
                  value: '/purchasing/manageRequest/list/all'
                }
              })
            })
          }else{
            Swal.fire(i18next.t("Allaction:Unsuccessful"), i18next.t("Allaction:Please try again."), 'warning')
          }
          
        }, 
        () => { Swal.fire(i18next.t("Allaction:Unsuccessful"), '', 'warning')}
      )
      
      this.setState({statusOnClick: ""})
      this.props.handleCloseModal()
    }else{
      this.setState({textWarn: true})
    }

  }

  handleCheckRadio = (e) => {

    let { name, value } = e.target
    let textDetail = ""

    switch (value) {
      case "incompleteEvidence":
        textDetail = "หลักฐานไม่ครบถ้วน"
        break;
      case "overpriced":
        textDetail = "ราคาสูงเกินไป"
        break;
      case "moreComparisons":
        textDetail = "หาคู่เทียบเพิ่มเติม"
        break;
      case "overBudget":
        textDetail = "เกินงบประมาณ"
        break;
      case "other":
        textDetail = ""
        break;
    }
    
    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), "checkRadio.detail", textDetail)
    });
    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, value)
    });
  }

  handleChangeInput = (e) => {

    let { name, value } = e.target

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, value)
    });
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.open}
          onRequestClose={this.props.handleCloseModal}
          ariaHideApp={false}
          style={{
            content: { 
              width : "640px",
              height: `${this.state.heightModal}`,
              top : '50%',
              left : '50%',
              right : 'auto',
              bottom : 'auto',
              marginRight : '-50%',
              transform : 'translate(-50%, -50%)',
              padding : '2%'
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }
          }}
        >
          <div id="modalNotConfirmManageRequest">
            <div className="d-flex justify-content-center">
              <img className="my-4 " src="/images/iconAlerts/iconAlert-Blue.png" alt="icon-blue"/>
            </div>
            {this.checkTextContentHead()}
            {(this.state.statusOnClick === "concern" || this.state.statusOnClick === "void") &&
              <div className="bodyContent mb-5">
                <div className="d-flex align-items-center">
                  <input 
                    type="radio"
                    id="incompleteEvidence" name="checkRadio.title"
                    value="incompleteEvidence"
                    checked={this.state.checkRadio.title === "incompleteEvidence"}
                    onChange={this.handleCheckRadio}
                    /> 
                  <span className="mx-4 textBodyContent">หลักฐานไม่ครบถ้วน</span>
                </div>
                <div className="d-flex align-items-center">
                  <input 
                    type="radio"
                    id="overpriced" name="checkRadio.title"
                    value="overpriced"
                    checked={this.state.checkRadio.title === "overpriced"}
                    onChange={this.handleCheckRadio}
                    /> 
                  <span className="mx-4 textBodyContent">ราคาสูงเกินไป</span>
                </div>
                <div className="d-flex align-items-center">
                  <input 
                    type="radio"
                    id="moreComparisons" name="checkRadio.title"
                    value="moreComparisons"
                    checked={this.state.checkRadio.title === "moreComparisons"}
                    onChange={this.handleCheckRadio}
                    /> 
                  <span className="mx-4 textBodyContent">หาคู่เทียบเพิ่มเติม</span>
                </div>
                <div className="d-flex align-items-center">
                  <input 
                    type="radio"
                    id="overBudget" name="checkRadio.title"
                    value="overBudget"
                    checked={this.state.checkRadio.title === "overBudget"}
                    onChange={this.handleCheckRadio}
                    />
                  <span className="mx-4 textBodyContent">เกินงบประมาณ</span>
                </div>
                <div className="d-flex align-items-center">
                  <input 
                    type="radio"
                    id="other" name="checkRadio.title"
                    value="other"
                    checked={this.state.checkRadio.title === "other"}
                    onChange={this.handleCheckRadio}
                    />
                  <span className="mx-4 textBodyContent">อื่นๆ โปรดระบุ</span>
                  <input type="text"
                    disabled={this.state.checkRadio.title !== "other"}
                    className="w-50"
                    name="checkRadio.detail" 
                    value={ 
                      this.state.checkRadio.title === 'other' 
                      ? this.state.checkRadio.detail : ""
                    }
                    onChange={this.handleChangeInput}
                    />
                </div>
              </div>
            }

            {this.state.textWarn &&
              <div className="bgWarn d-flex justify-content-center align-items-center mb-2">
                <span className="textWarn">กรุณาระบุหมายเหตุ !</span>
              </div>
            }

            <div className="d-flex justify-content-center">
              {this.state.statusOnClick === "" &&
                <React.Fragment>
                  <button 
                    type="button" 
                    className="btnConCern" 
                    onClick={
                      () => this.setState({statusOnClick: "concern", heightModal: "436px"})
                    }
                    >
                    <span>ตีกลับ</span>
                  </button>
                  <button 
                    type="button" 
                    className="btnVoid" 
                    onClick={() => 
                      this.setState({statusOnClick: "void", heightModal: "436px"})
                    }
                    >
                    <span>ไม่รับอนุมัติ</span>
                  </button>
                </React.Fragment>
              }
              {this.state.statusOnClick !== "" &&
                <button type="button" className="btnConCern" onClick={this.handleConfirm}>
                  <span>ยืนยัน</span>
                </button>
              }
              <button type="button" className="btnClose" onClick={this.props.handleCloseModal}>
                <span>ปิดหน้าต่าง</span>
              </button>
            </div>

          </div>
          
        </Modal>
      </React.Fragment>
    )
  }
}
