import React, {Component} from 'react';
import $ from "jquery";
import {graphql} from "babel-plugin-relay/macro";
import {Helmet} from "react-helmet";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import "./contact.scss"
import _ from "lodash"
import Loading from "../../libs/loading";
import getNameResidential from "../../libs/getNameResidential";

const query = graphql`
    query contactQuery($id: ID!) {
        selfProject {
            id
            name
            address
            taxIdNumber
            juristicContactNumber
            logo
        }
        contact(id: $id) {
            id
            firstName
            lastName
            phone
            mailingAddress
            mailingCity
            mailingCountry
            mailingDistrict
            mailingName
            mailingPostalCode
            mailingProvince
            mainEmail
            mainPhone
            registeredName
            registeredAddress
            registeredCountry
            registeredProvince
            registeredCity
            registeredDistrict
            registeredPostalCode
            typeOfContact
        }
    }
`;

var listBangkok = ["กรุงเทพ", "กรุงเทพมหานคร", "กทม.", "กทม", "กรุงเทพฯ"]

class Contact extends Component {

    componentDidMount() {
        setTimeout(() => {
            let page = $('.print-contact-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + signature);
            // console.log(head + detail + daily_journal + signature);
            $('tbody tr:last-child td').css({paddingBottom: diff});
        }, 200);
    }

    print() {
        window.print();
    }

    checkHeadTitle = (textInput) => {

        let textCheck = _.filter(listBangkok, (textKeyCheck) => textKeyCheck === textInput)
        if(textCheck.length > 0){
            return true
        } else {
            return false
        }
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Contact</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={() => this.print()}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{id: this.props.match.params.id}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return (
                                <div id="contact">
                                <div className="print-contact-a4">
                                    <div className="subpage">
                                        <div className="head">
                                            <div className="juristic-description">
                                                <strong style={{fontSize: 16}}>ชื่อที่อยู่ผู้ส่ง</strong>
                                                <div className="mt-3">{props.selfProject.name}</div>
                                                <div>{props.selfProject.address}</div>
                                                <div>{props.selfProject.juristicContactNumber ? ('โทร. '+ props.selfProject.juristicContactNumber) : ''}</div>
                                            </div>
                                        </div>
                                        <div className="he">
                                            <div className="document-name">
                                                <strong style={{fontSize: 16}}>ชื่อที่อยู่ผู้รับ</strong>
                                                <div className="mt-3">{props.contact.mailingName}</div>
                                                { props.contact.typeOfContact === 'RESIDENTIAL' ?
                                                    <div> {getNameResidential(props.contact.firstName? props.contact.firstName :'', props.contact.lastName?props.contact.lastName:'')} </div> 
                                                    :
                                                    <div>{props.contact.firstName?props.contact.firstName:''} {props.contact.lastName?props.contact.lastName:''} </div>                        
                                                }
                                                <div>{props.contact.mailingAddress?props.contact.mailingAddress:props.contact.registeredAddress}</div>
                                                <div>
                                                    {this.checkHeadTitle(props.contact.mailingProvince || props.contact.registeredProvince) 
                                                        ? <React.Fragment>
                                                            แขวง{props.contact.mailingDistrict?props.contact.mailingDistrict:props.contact.registeredDistrict} 
                                                            &nbsp;เขต{props.contact.mailingCity?props.contact.mailingCity:props.contact.registeredCity} 
                                                            </React.Fragment>
                                                        : <React.Fragment>
                                                            ตำบล{props.contact.mailingDistrict?props.contact.mailingDistrict:props.contact.registeredDistrict} 
                                                            &nbsp;อำเภอ{props.contact.mailingCity?props.contact.mailingCity:props.contact.registeredCity} 
                                                        </React.Fragment>
                                                    }
                                                    &nbsp;{props.contact.mailingProvince
                                                        ? this.checkHeadTitle(props.contact.mailingProvince)
                                                            ? props.contact.mailingProvince : `จังหวัด${props.contact.mailingProvince}`
                                                        : this.checkHeadTitle(props.contact.registeredProvince)
                                                            ? props.contact.registeredProvince : `จังหวัด${props.contact.registeredProvince}`
                                                    } 
                                                    &nbsp;{props.contact.mailingPostalCode?props.contact.mailingPostalCode:props.contact.registeredPostalCode}
                                                </div>
                                                <div>{props.contact.phone ? ('โทร. ' + props.contact.phone) : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            )
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />
            </React.Fragment>
        );
    }
}

export default Contact;
