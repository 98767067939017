import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import Loading from "../../../../libs/loading";
import Swal from "sweetalert2";
import _ from "lodash";
import { format } from "date-fns";
import './waterAndElectricReceiveReport.scss';
import WaterElectricUtilityReceiveReportPDFRenderTable from "./waterElectricUtilityReceiveReportPDFRenderTable";

const query = graphql`
query waterElectricUtilityReceiveReportPDFQuery
    ($atDate: Date
     $month: Int
     $year: Int
    $search: String
    $contactId: ID
    $typeOfContact: String
    $productAndService: String
    )
  {
    allContactUnitTransaction(
        atDate: $atDate,
        month: $month,
        year: $year
        search:$search,
        contactId:$contactId,
        typeOfContact:$typeOfContact,
        productAndService: $productAndService,
    )
    selfProject{
      name
    }
    productViewer{
        allProduct (type_In: "product, service, fine",servicePricing_PricingType_In:"water_meter, electric_meter"){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                    chartOfAccount{
                        id
                        chartOfAccountCode
                        name
                    }
                    description
                    totalLeftInStock
                }
            }
        }
    }
    contactViewer{
            allContact(typeOfPayment: "receivable", typeOfContact: $typeOfContact,order: "ref_number"){
                edges{
                    node{
                        id
                        refNumber
                        name
                        firstName
                        lastName
                    }
                }
    }}
}`
class WaterElectricUtilityReceiveReportPDF extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            allData: "",
            productAndServiceList: [],
            contactList: [],
        }
        this.componentWillMount = this.componentWillMount.bind(this);
        this.queryData = this.queryData.bind(this);
    }

    componentWillMount() {
        this.queryData()
    }

    queryData() {
        let props = this.props.location.state.state;
        this.setState({ 
            loading: true,
        })
          let variable = {
            atDate: props.selectedAtDate,
            month: props.selectedMonth,
            year: props.selectedYear,
            search: props.search,
            contactId:props.contact,
            productAndService: props.productAndServiceFilter ? props.productAndServiceFilter : props.productAndServiceInit,         
          }
          fetchQuery(environment, query, variable).then(data => {
            this.setState({ 
              loading: false,
              allData: data,
              productAndServiceList: data.productViewer.allProduct.edges,
              contactList: data.contactViewer.allContact.edges,
             })
          })
          .catch(error => {
            Swal.fire("Error!", "", "error"); 
          })
    }

    getContact(contact) {
        let value = ""
        let listProductAndService = []
    
        listProductAndService = _.filter(this.state.contactList, (o) => o.node.id === contact)
    
        if (listProductAndService.length > 0) {
          value = `${listProductAndService[0].node?.refNumber} ${listProductAndService[0].node?.name}`
        } else {
          value = "ทั้งหมด"
        }
    
        return value
      }
    
      getProduct(productAndService){
        let value = ""
        let listProductAndService = []
    
        listProductAndService = _.filter(this.state.productAndServiceList, (o) => o.node.id === productAndService)
    
        if (listProductAndService.length > 0) {
          value = `${listProductAndService[0].node?.productCode} ${listProductAndService[0].node?.name}`
        } else {
          value = "ทั้งหมด"
        }
    
        return value
      }

    render() {
        const style = [{
            "cssText": `
            body {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                background-color: #FAFAFA;
                }
            
                * {
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                }
            
                .page {
                width: 297mm;
                max-hight:210mm;
                min-height: 210mm;
                padding: 10mm 5mm 5mm 5mm ;
                border: 1px #000 solid;
                border-radius: 2px;
                background: white;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                position: relative;
                }
                .pageNumber{
                position: fixed;
                bottom: 0;
                }
            
                @page {
                size: a4 landscape;
                margin: 0;
                }
                @media print {
                html, body {
                    width: 297mm;
                    height: 210mm;
                }

                @page: last{
                    .pageNumber {
                        display: block;
                    }
                }
                .bg-gray{
                    background-color: #D3D3D3;
                }
                .page {
                    margin: 0;
                    border: initial;
                    border-radius: initial;
                    width: initial;
                    min-height: initial;
                    box-shadow: initial;
                    background: initial;
                    page-break-after: always;
                    position: relative;
                }
                .pageNumber{
                    position: fixed;
                    bottom: 0;
                }
                }
        `
        }]

        let props = this.props.location.state.state;
        let productAndService = this.getProduct(props.productAndServiceFilter ? props.productAndServiceFilter : props.productAndServiceInit)
        let contact = this.getContact(props.contact)
        let monthDate = "ประจำเดือน " + props.selectedMonth + "/" + props.selectedYear + " ณ วันที่ " + format(props.selectedAtDate, "DD/MM/YYYY")

        return (
            <React.Fragment>
                        <Helmet
                        style={style}>
                        <meta charSet="utf-8" />
                        <title>Water and Electric Receivable</title>
                        <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        </Helmet>
                        <div id="waterAndElectricReceiveReportPDF">
                            <div className="print-top-menu">
                                    <div className="logo">
                                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                                    </div>
                                    <div className="print" onClick={() => window.print()}>
                                        PRINT
                                    </div>
                            </div>     
                            {
                                this.state.loading ?
                                <Loading />
                                    :
                                <WaterElectricUtilityReceiveReportPDFRenderTable 
                                    allData={this.state.allData}
                                    productAndService={productAndService}
                                    contact={contact}
                                    monthDateHeader={monthDate}
                                />
                            }
                        </div>    
            </React.Fragment>
        )
    }
}
export default WaterElectricUtilityReceiveReportPDF;



