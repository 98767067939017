import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import "../../developer.scss";
import _ from "lodash";
import ModalResidential from '../../unitStatus/transferOfOwnershipCreate/modalResidential';
import revenueAPI from "../revenueContractAPI"
import Freebies from './freebies';
import ConditionContract from './conditionContract';
import Swal from 'sweetalert2';
import format from 'date-fns/format';
import {Redirect} from 'react-router';
import { lastDayOfMonth } from 'date-fns';
import i18next from 'i18next';
class ContractForm extends Component{

    constructor(props){
        super(props);
        this.state={
            contactList : this.props.contactList,
            remark : "",
            transactionFreebies : [],
            transactionCondition : [],
            token: localStorage.getItem("token"),
            productAndService : [],
            freebiesSelect : '',
            loading : false,
            conditionContract : this.props.conditionContract,
            redirectToList : false,
            defaultFreebies : [] ,
            loading_data:false,
            freebiesItems: [],
        }
    }

    

    getService = () => {
        revenueAPI.getService(this.state.token)
            .then((response) => {
                if(response.data.productAndService){
                    let serviceDefaultCode = ['S004','S005' ,'S006' ,'S007']
                    let defaultFreebies = []
                    _.forEach(serviceDefaultCode, (code) => {
                        if(_.find(response.data.productAndService , ['productCode',code])){
                            let product = _.find(response.data.productAndService , ['productCode',code])
                            let defaultProduct = {
                                averagePerMonths : '',
                                customerChartOfAccount : product.customerChartOfAccountCode + ' ' + product.customerChartOfAccount,
                                chartOfAccount : product.chartOfAccountCode +' ' + product.chartOfAccount,
                                price : 0,
                                productAndServiceID : product.productAndServiceID,
                                issuedDate : new Date(),
                                dueDate : new Date(new Date().setMonth(new Date().getMonth() + 1)),
                                receiveDeposit : product.receiveDepositCode +' ' + product.receiveDeposit,
                            }
                            defaultFreebies.push(defaultProduct)
                        }
                    })
                    this.setState({
                        productAndService : response.data.productAndService,
                        defaultFreebies : defaultFreebies
                    })
                }
            })

    }

    componentDidMount(){
        this.getService()
    }

    updateFreebies = (transactionList,freebiesSelect, freebiesItems) =>{
        this.setState({
            transactionFreebies : transactionList,
            freebiesSelect : freebiesSelect,
            conditionContract : true,
            freebiesItems : freebiesItems,
        })
    }

    onSubmit = () => {
        let freebies = [];
        this.setState({
            loading_data:true
        })

        for (const contact of this.state.contactList) {
            _.forEach(this.state.transactionFreebies , data => {
                let newDueDate = (lastDayOfMonth(data.dueDate))
                freebies.push({
                    obligationDeveloperID : '', 
                    residentialID : contact.unitStatusList.residentialID, 
                    productAndServiceID : data.productAndServiceID , 
                    freebies : this.state.freebiesSelect ,
                    price : data.price , 
                    issuedDate : format(data.issuedDate,'YYYY-MM-DD') ,
                    dueDate : format(newDueDate,'YYYY-MM-DD'),
                    status : ''
                })
            })
        }
    

        let condition = [];
        for (const contact of this.state.contactList) {
            _.forEach(this.state.transactionCondition , data => {
                condition.push({
                    obligationTenantID : '', 
                    residentialID : contact.unitStatusList.residentialID , 
                    productAndServiceID : data.productAndServiceID , 
                    charged : data.charged ,
                    billingMonth : data.billingMonth ? data.billingMonth : '', 
                    advance : data.advance,
                    issuedDate : format(data.issuedDate,'YYYY-MM-DD'),
                    burdenStatus : data.burdenStatus,
                    agentStatus : data.agentStatus,
                    activeStatus : data.activeStatus,
                    status : '',
                    dueDate: data.dueDate ? format(data.dueDate,'YYYY-MM-DD') : null
                })
            })
        }
        
        let freebiesItem = [];

        for (let i = 0; i < this.state.contactList.length; i++) {
            for (let j = 0; j < this.state.freebiesItems.length; j++) {
                freebiesItem.push({
                residentialID: this.state.contactList[i].unitStatusList.residentialID,
                ...this.state.freebiesItems[j]
              });
            }
          }


        revenueAPI.createFreebies(freebies,this.state.token).then((response1) => {
            if(response1.status === 200){
                revenueAPI.createCondition(condition,this.state.token).then((response2) => {
                    if(response2.status === 200){
                        revenueAPI.createFreebiesItem(freebiesItem, this.state.token).then((response3)=>{
                            if(response3.status === 200){
                                this.onSuccess()
                            }else{
                                this.onError()
                            }
                        })                    
            }})
            }
        })
    }

    onSuccess = () => {
        this.setState({ loading: false });
        Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => { 
            this.setState({ redirectToList: true,loading_data:false });
        });
      }
    
      onError = () => {
        this.setState({ loading: false,loading_data:false });
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
      }


    updateCondition = (transactionList) =>{
        this.setState({
            transactionCondition : transactionList,
        },() => this.onSubmit())
    }

    // backPage = (transactionList) => {
    //     this.setState({
    //         transactionCondition : transactionList,
    //         conditionContract : false
    //     })
    // }

    render(){
        let contactName = ''
        if(this.state.contactList.length === 1){
            this.state.contactList[0].unitStatusList.ownershipName.forEach((name) => {
                contactName = contactName +' ' + name
            })
        }
        if(this.state.redirectToList){
            return <Redirect to="/developer/revenue-contract/list/contracted"/>;
        }
        return(
            <Translation>
                {t =>
                        <div className="content-inner pr-4">
                            <div className="row mb-2">
                                <div className="col-5">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control input-size"
                                            value={this.state.contactList.length === 1 ? contactName : 'แบบหลายห้อง'}
                                            disabled={this.state.contactList.length === 1}
                                            readOnly={true}
                                            style= {{ borderRight: this.state.contactList.length > 1 ?'none' : '' , fontSize:'16px'}}
                                        />
                                        {this.state.contactList.length > 1 &&
                                            <ModalResidential contact={this.state.contactList}/>
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                            {!this.state.conditionContract ? 
                                <Freebies productAndService = {this.state.productAndService} updateFreebies ={this.updateFreebies}
                                freebies={this.state.defaultFreebies}/>
                            :
                                <ConditionContract productAndService = {this.state.productAndService}  contactList={this.state.contactList} 
                                updateCondition = {this.updateCondition} loading_data = {this.state.loading_data}/>}
                            
                        </div>
                }
            </Translation>
        );
    }


}export default ContractForm;