import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import InputAddress from "../../../libs/autoCompleteAddress";

class AgentAddressDetail extends Component {

    render() {
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className='row m-0 p-0'>
                            <div className='col-8 pl-0'>
                                <p className='fs-16 mt-5'>{t("add_receivable:Address")} <span className="star-button">*</span></p>
                                <textarea className="form-control h48" id="agentAddress" maxLength="256"
                                    rows={1} style={{ resize: 'none' }}
                                    name="agentAddress" value={this.props.state.agentAddress}
                                    onChange={this.props.handleInputChange}
                                    disabled={this.props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                    required
                                />
                            </div>

                            <div className='col' />

                            <div className='col-4 pl-0'>
                                <p className='fs-16 mt-5' htmlFor="agentDistrict">{t("add_receivable:Sub-district")}</p>
                                <InputAddress
                                    address="agentDistrict"
                                    value={this.props.state.agentDistrict}
                                    onChange={this.props.handleInputChange}
                                    onSelect={this.props.onSelect}
                                    mailing={false}
                                    className="form-control h48"
                                    disabled={this.props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                />

                            </div>

                            <div className='col-4 pl-0'>
                                <p className='fs-16 mt-5' htmlFor="agentCity">{t("add_receivable:District")}</p>
                                <InputAddress
                                    address="agentCity"
                                    value={this.props.state.agentCity}
                                    onChange={this.props.handleInputChange}
                                    onSelect={this.props.onSelect}
                                    mailing={false}
                                    className="form-control h48"
                                    disabled={this.props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                />
                            </div>
                            <div className='col' />

                            <div className='col-4 pl-0'>
                                <p className='fs-16 mt-5' htmlFor="agentProvince">{t("add_receivable:Province")}</p>
                                <InputAddress
                                    address="agentProvince"
                                    value={this.props.state.agentProvince}
                                    onChange={this.props.handleInputChange}
                                    onSelect={this.props.onSelect}
                                    mailing={false}
                                    className="form-control h48"
                                    disabled={this.props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                />

                            </div>

                            <div className='col-4 pl-0'>
                                <p className='fs-16 mt-5' htmlFor="agentPostalCode">{t("add_receivable:Zip code")}</p>
                                <InputAddress
                                    address="agentPostalCode"
                                    value={this.props.state.agentPostalCode}
                                    onChange={this.props.handleInputChange}
                                    onSelect={this.props.onSelect}
                                    mailing={false}
                                    className="form-control h48"
                                    disabled={this.props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                />
                            </div>

                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default AgentAddressDetail; 