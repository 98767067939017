import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";

const query = graphql`
query formOneQueryQuery($formDebit: Int){
    allSettingFormDebit(formDebit: $formDebit ) {
        edges{
            node{
                id
                description
                paymentCheck
                paymentTimeStatus
                paymentTime1
                paymentTime2
                bankAccountStatus
                numberOfDays
                bankAccount{
                    id
                    accountNumber
                    bankName
                    accountName
                    branch
                }
                channelDescription
                contactName
                contactPhone
                signerName
                signerPosition
                formDebit
            }
        }
    }
}`;



const getFormOneQuery = (formDebit) =>  {
    return new Promise((resolve, reject) => {
        fetchQuery(environment, query, {formDebit:formDebit}).then(data => {
            resolve(data)
        });
    });
}

export default getFormOneQuery 




