import React, { Component } from 'react';
import './loadingModal.css';

class LoadingModal extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const { isOpen, children } = this.props;

    if (!isOpen) return null;

    return (
      <div className="loading-modal-overlay">
        <div className="loading-modal">
          <button className="loading-close-button" onClick={this.handleClose}>
            &times;
          </button>
          {children}
        </div>
      </div>
    );
  }
}

export default LoadingModal;