import getApiRoot from "../libs/getAPIRoot";
const token = localStorage.getItem("token");
async function getCheckRightSetting() {
  let response = await fetch(
    getApiRoot() + "booking_facility/check_right_setting",
    {
      authorization: `JWT ${token}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).catch((error) => {
    console.error("Error:", error);
  });
  return response.json();
}
async function getCheckScoreSetting() {
  let response = await fetch(
    getApiRoot() + "booking_facility/check_score_setting",
    {
      authorization: `JWT ${token}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).catch((error) => {
    console.error("Error:", error);
  });
  return response.json();
}
async function getSiteId() {
  let response = await fetch(getApiRoot() + "booking_facility/get_site_id", {
    authorization: `JWT ${token}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((error) => {
    console.error("Error:", error);
  });
  return response.json();
}

export default {
  getCheckRightSetting,
  getSiteId,
  getCheckScoreSetting,
};
