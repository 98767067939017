/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type wrapperInvoiceGetAllQueryVariables = {|
  status?: ?$ReadOnlyArray<?string>,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  is_contact_online?: ?boolean,
|};
export type wrapperInvoiceGetAllQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +nameEn: ?string,
    +address: ?string,
    +addressEn: ?string,
    +logo: ?string,
    +juristicContactNumber: ?string,
    +keyProjectQr: ?string,
    +taxIdNumber: ?string,
    +bankCompCode: ?string,
    +bankServiceCode: ?string,
  |},
  +invoiceViewer: ?{|
    +getAllInvoicePrint: ?$ReadOnlyArray<?string>
  |},
  +userConfig: ?{|
    +id: string,
    +languages: string,
  |},
|};
export type wrapperInvoiceGetAllQuery = {|
  variables: wrapperInvoiceGetAllQueryVariables,
  response: wrapperInvoiceGetAllQueryResponse,
|};
*/


/*
query wrapperInvoiceGetAllQuery(
  $status: [String]
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $is_contact_online: Boolean
) {
  selfProject {
    id
    name
    nameEn
    address
    addressEn
    logo
    juristicContactNumber
    keyProjectQr
    taxIdNumber
    bankCompCode
    bankServiceCode
  }
  invoiceViewer {
    getAllInvoicePrint(status: $status, search: $search, startDate: $start_date, endDate: $end_date, isContactOnline: $is_contact_online)
    id
  }
  userConfig {
    id
    languages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "is_contact_online"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectNode",
  "kind": "LinkedField",
  "name": "selfProject",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "juristicContactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keyProjectQr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxIdNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankCompCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankServiceCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "end_date"
    },
    {
      "kind": "Variable",
      "name": "isContactOnline",
      "variableName": "is_contact_online"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    },
    {
      "kind": "Variable",
      "name": "startDate",
      "variableName": "start_date"
    },
    {
      "kind": "Variable",
      "name": "status",
      "variableName": "status"
    }
  ],
  "kind": "ScalarField",
  "name": "getAllInvoicePrint",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "UserConfigNode",
  "kind": "LinkedField",
  "name": "userConfig",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "languages",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperInvoiceGetAllQuery",
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "wrapperInvoiceGetAllQuery",
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "59e9eedb6f8a45a40c949c97cf65dd65",
    "id": null,
    "metadata": {},
    "name": "wrapperInvoiceGetAllQuery",
    "operationKind": "query",
    "text": "query wrapperInvoiceGetAllQuery(\n  $status: [String]\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $is_contact_online: Boolean\n) {\n  selfProject {\n    id\n    name\n    nameEn\n    address\n    addressEn\n    logo\n    juristicContactNumber\n    keyProjectQr\n    taxIdNumber\n    bankCompCode\n    bankServiceCode\n  }\n  invoiceViewer {\n    getAllInvoicePrint(status: $status, search: $search, startDate: $start_date, endDate: $end_date, isContactOnline: $is_contact_online)\n    id\n  }\n  userConfig {\n    id\n    languages\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd91f3109fda5e97e0ac6915fcda6550';

module.exports = node;
