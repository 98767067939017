import React, { Component } from 'react';
import { Translation } from "react-i18next";
import NavigationMenuSetup from "../libs/navigationMenuSetup";
// import getSettingMenuData from "../libs/settingMenuData";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../env/environment";
import Swal from "sweetalert2";
// import './setting.scss'
import BackButtonIcon from '../components/BackBtn/indexBack';
import Navigation from './navigation';
import SeacrhDetail from '../components/search';
import SeacrhDetails from '../components/search/indexSearch';
import DownloadFileTable from './downloadFileTable';
import i18n from 'i18next';

import VoidDownloadMutation from "./mutation/VoidDownload";

import axios from "axios";
import getApiRoot from "../libs/getAPIRoot";
import {
    EmailShareButton,
    EmailIcon,
    LineShareButton,
    LineIcon
} from "react-share";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const query = graphql`
    query downloadFileMainQuery{
        selfProject {
            useAccount
        }
    }
`;

// let menuList = [
//     {
//         value: "debit",
//         label: "รายงานลูกหนี้",
//         listSub: ["รายงานการออกใบแจ้งหนี้", "รายงานสรุปการรับชำระเงิน", "รายงานลูกหนี้คงค้างแบบละเอียด", "รายงานลูกหนี้คงค้างแบบสรุป", "รายงานสรุปบัญชีลูกหนี้ทั้งหมดแยกประเภทสินค้า/รายรับ", "รายงานการเคลื่อนไหวลูกหนี้", "รายงานใบลดหนี้", "รายงานรับเงินค้ำประกัน", "รายงานเคลื่อนไหวเงินค้ำประกัน", "รายงานรับล่วงหน้า", "รายงานเคลื่อนไหวรับล่วงหน้า", "รายงานรับชำระเงินอื่นๆ", "รายงานรวมรับชำระเงินประจำวัน"
//         ]
//     },
//     {
//         value: "credit",
//         label: "รายงานเจ้าหนี้",
//         listSub: [
//             "รายงานตั้งค่าใช้จ่าย", "รายงานสรุปการจ่ายชำระเงิน", "รายงานเจ้าหนี้คงค้างแบบละเอียด", "รายงานเจ้าหนี้คงค้างแบบสรุป", "รายงานสรุปบัญชีเจ้าหนี้ทั้งหมดแยกประเภท", "รายงานการเคลื่อนไหวเจ้าหนี้", "รายงานรับใบลดหนี้", "รายงานจ่ายล่วงหน้า", "รายงานเคลื่อนไหวจ่ายล่วงหน้า", "รายงานเคลื่อนไหวเงินสดย่อย", "รายงานเคลื่อนไหวเงินทดรองจ่าย", "รายงานบันทึกค่าใช้จ่ายอื่นๆ"
//         ]
//     },
//     {
//         value: "asset",
//         label: "รายงานสินทรัพย์",
//         listSub: [
//             "รายงานทะเบียนสินทรัพย์", "รายงานทะเบียนสินทรัพย์ตัดจำหน่าย", "รายงานรายละเอียดค่าเสื่อม"
//         ]
//     },
//     {
//         value: "finance",
//         label: "รายงานการเงิน",
//         listSub: [
//             "รายงานเงินฝากธนาคาร", "รายงานรายละเอียดประมาณการดอกเบี้ยรับบัญชีเงินฝากประจำ"
//         ]
//     }
// ]


class DownloadFileMain extends Component {
    constructor(props) {
        super(props);
        // this.state = getSettingMenuData() 
        this.state = {
            useAccount: false,
            status: "",

            startDate: null,
            endDate: null,
            search: "",
            typeReportRef: "",


            temp_startDate: new Date(),
            temp_endDate: new Date(),
            temp_search: "",
            temp_typeReportRef: "",

            select1: "",
            select2: "",

            selectItem: [],
            selectAll: false,
            re_query: false,
            getLinkToShareMail: "",
            getLinkToShareLine: "",
            showModalShare: false,
        }

        this.onSuccess = this.onSuccess.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
    }

    componentWillMount() {

        fetchQuery(environment, query).then(response_data => {
            let useAccount = false;
            if (response_data.selfProject.useAccount) {
                useAccount = response_data.selfProject.useAccount
                this.setState({ useAccount: useAccount })
            }
        });
    }

    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
    }

    checkUseAccount(useAccount) {
        if (useAccount === false) {
            Swal.fire({
                // title: 'เพราะเรื่องเงิน \n คือเรื่องใหญ่',
                html:
                    '<p style="color: #2D2D30; font-weight: 500; font-size: 48px; line-height: 30px; margin-top: 40px">เพราะเรื่องเงิน</p>' +
                    '<br><p style="color: #2D2D30; font-weight: 600; font-size: 48px; line-height: 20px;">คือเรื่องใหญ่</p>' +
                    '<br><br><p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">หมดปัญหาเก็บเงินค่าส่วนกลางไม่ได้</p>' +
                    '<p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">ตรวจสอบยาก - เงินสูญหาย - หนี้ค้างชำระเยอะ</p>' +
                    '<br><p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">ขับเคลื่อนงานนิติบุคคลด้วยระบบ AI</p>' +
                    '<p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">จัดการทุกขั้นตอนเกี่ยวกับการเงินได้อย่างมีประสิทธิภาพ</p>' +
                    '<br><br><p style="color: #1578D7; font-weight: 500; font-size: 16px; line-height: 5px;">"ขณะนี้นิติบุคคลโครงการท่าน ยังไม่ได้เปิดใช้บริการฟังก์ชันสำหรับบัญชีและการเงิน</p>' +
                    '<p style="color: #1578D7; font-weight: 500; font-size: 16px; line-height: 10px;">กรุณาติดต่อทีมงาน Silverman Support เพื่อเปิดใช้บริการฟังก์ชันนี้"</p>' +
                    '<br><a href="https://silverman.app/" style="background-color:#1578D7; color: #FFFFFF; text-decoration: none; border: none; padding: 12px 24px; border-radius: 4px;">สมัครใช้บริการ</a>' +
                    '<br><br><br><p style="color: #B3B3B3; font-weight: 300; font-size: 12px; line-height: 5px;">ติดต่อสอบถาม www.silverman.app / E-mail: hello@silverman.app</p>' +
                    '<p style="color: #B3B3B3; font-weight: 300; font-size: 12px; line-height: 5px;">Tel: 081-4426888 / Line: @Silvermanapp / Facebook: Silverman App</p>',
                showConfirmButton: false,
                showCloseButton: true,
                width: 860,
            }).then(() => {
                // Reload the Page
                window.location.reload(false);
            });
        }
    }


    selectItem = (e) => {
        this.setState({ selectItem: e })

    }


    handlePropsMultiDownload = () => {
        if (this.state.selectItem.length > 0) {
            var token_id = localStorage.getItem("token");
            var header = {
                authorization: `JWT ${token_id}`,
                "Content-Type": "application/json",
            };

            this.state.selectItem.forEach(id => {
                axios.get(`${getApiRoot()}taskdownload/download_multi`, {
                    headers: header,
                    params: {
                        id_download_record: id,
                    }
                })
                    .then((response_get_path_file) => {
                        response_get_path_file.data.data.forEach(e => {
                            axios.get(e.s3_path_url_link, {
                                responseType: 'blob'
                            }).then((response) => {
                                const a = document.createElement('a');
                                a.href = window.URL.createObjectURL(response.data);
                                a.download = e.filename
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            })

                        });

                    })
                    .catch(error => {
                        console.log(error);
                    })
            });

        }

    }

    handlePropsMultiShare = () => {
        if (this.state.selectItem.length > 0) {
            let token_id = localStorage.getItem("token");
            let header = {
                authorization: `JWT ${token_id}`,
                "Content-Type": "application/json",
            };

            this.state.selectItem.forEach(id => {
                this.setState({
                    getLinkToShareMail: "",
                    getLinkToShareLine: "",
                })
                axios.get(`${getApiRoot()}taskdownload/download_multi`, {
                    headers: header,
                    params: {
                        id_download_record: id,
                    }
                })
                    .then((response_get_path_file) => {
                        response_get_path_file.data.data.forEach((e, index) => {
                            axios.get(e.s3_path_url_link).then((response) => {
                                this.setState({
                                    getLinkToShareMail: this.state.getLinkToShareMail.concat(`${e.filename}.\n${response.config.url}\n\n`),
                                    getLinkToShareLine: this.state.getLinkToShareLine.concat(`${response.config.url}\n\n`),
                                })
                            })
                        });
                    })
                    .then(() => {
                        this.handleToggleModal(true);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            });

        } else {
            Swal.fire({
                imageUrl: `/images/iconAlerts/iconAlert-Blue4x.png`,
                imageWidth: 100,
                html: `
                    <label className='swal2-title-custom text-center'>${i18n.t('downloadFile:Please select a document before sharing')}</label>
                `,
                showConfirmButton: true,
                confirmButtonText: i18n.t('Allaction:yes'),
                customClass: {
                    confirmButton: 'swal-btn-primary-noborder',
                    container: 'swal2-container-custom',
                    content: 'swal2-content-custom',
                }
            })
        }

    }

    handlePropsOnVoid = () => {
        if (this.state.selectItem.length > 0) {
            Swal.fire({
                imageUrl: `/images/iconAlerts/iconAlert-Blue4x.png`,
                imageWidth: 100,
                html: `
                    <label className='swal2-title-custom text-center'>${i18n.t('downloadFile:Do you want to void document')}</label>
                `,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: i18n.t('Allaction:yes'),
                cancelButtonText: i18n.t('Allaction:cancel'),
                customClass: {
                    cancelButton: 'swal-btn-gray-noborder',
                    confirmButton: 'swal-btn-primary-noborder',
                    container: 'swal2-container-custom',
                    content: 'swal2-content-custom',
                }
            }).then((result) => {
                if (result.value) {
                    let input = {
                        downloadJobList: this.state.selectItem,
                    };
                    VoidDownloadMutation(
                        input,
                        this.onSuccess,
                        this.onError
                    );
                }
            });
        }

    }


    onSuccess(response) {

        this.setState({ loading_approve: false, loading_delete: false });
        if (response.voidDownloadJob?.ok) {
            Swal.fire(i18n.t('downloadFile:success_void'), response.voidDownloadJob.message, "success");
        }

        if (response.reDownloadFile?.ok) {
            Swal.fire(i18n.t('downloadFile:success_redownload'), response.reDownloadFile.message, "success");
        }
        this.setState({
            re_query: !this.state.re_query,
            selectItem: [],
            selectAll: false,
            loading: false,
        });
    }

    onError() {
        Swal.fire("Error", "", "error");
        this.setState({
            loading: false,
        });
    }

    onChangeSearch = (e) => {
        if (e[0].name === 'start_date') {
            this.setState({ temp_startDate: e[0].value })
        } else if (e[0].name === 'end_date') {
            this.setState({ temp_endDate: e[0].value })
        }
        else if (e[0].name === 'search') {
            this.setState({
                search: e[0].value,
                startDate: this.state.temp_startDate,
                endDate: this.state.temp_endDate,

            })
        }
    }

    handleToggleModal(statusModal) {
        this.setState({
            showModalShare: statusModal
        })
    }

    render() {
        return (
            <Translation>
                {t =>
                    <div className="container-fluid box colorUse">
                        <BackButtonIcon
                            LinkBack={"/home"}
                            LinkText={t("downloadFile:Document to download")}
                        />

                        <div className="content-inner">
                            <Navigation />
                            <SeacrhDetail
                                startDateIs={this.state.temp_startDate}
                                endDateIs={this.state.temp_endDate}
                                // selectIs={true}
                                // select2IsName={t("downloadFile:select to show")}
                                // selectIsName={t("downloadFile:select to topic")}
                                // selectIsClass={"floatLeft"}
                                // selectIsClassLabel={"ml-0"}
                                // select2Is={true}
                                // seleteDetail={menuList}
                                onChangeSearch={this.onChangeSearch}
                            />

                            <div className='clearfix' />
                            <SeacrhDetails
                                // startDateIs={this.state.temp_startDate}
                                // endDateIs={this.state.temp_endDate}
                                searchBoxId={"dsds"}
                                buttonDownload={true}
                                buttonDeleted={true}
                                buttonShare={true}
                                handlePropsOnVoid={this.handlePropsOnVoid}
                                handlePropsMultiDownload={this.handlePropsMultiDownload}
                                handlePropsChange={this.onChangeSearch}
                                handlePropsMultiShare={this.handlePropsMultiShare}
                            />

                            {/* Modal share document */}
                            <Modal show={this.state.showModalShare} onHide={() => this.handleToggleModal(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t('downloadFile:Share Document')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <EmailShareButton className='mr-2' url={this.state.getLinkToShareMail}>
                                        <EmailIcon size={40} onClick={() => this.handleToggleModal(false)} />
                                    </EmailShareButton>
                                    <LineShareButton className='mr-2' url={this.state.getLinkToShareLine}>
                                        <LineIcon size={40} onClick={() => this.handleToggleModal(false)} />
                                    </LineShareButton>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.handleToggleModal(false)}>
                                        {t('downloadFile:Close')}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <DownloadFileTable selectItem={this.selectItem} status={this.props.status} state={this.state} onSuccess={this.onSuccess} onError={this.onError} />
                        </div>
                    </div>
                }
            </Translation>

        );
    }
}

export default DownloadFileMain;
