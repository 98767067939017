import React, {Component} from 'react';
import './styles/fixRequest.scss';
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import { Translation } from 'react-i18next';
class FixListDetailFinish extends Component {

    render() {
        return (
            <Translation>{t=>
            <div className="row">
                <div className="col-md-2"/>
                <div className="col-md-10">
                    <div id="accordion">
                        <div className="card-wrapper">
                            <div className="card-header"
                                 id="headingOne">
                                <div className="row cursor"
                                     data-toggle="collapse"
                                     data-target={"#collapse"+this.props.index}
                                     aria-expanded="false"
                                     aria-controls={"collapse"+this.props.index}>
                                    <div className="col">
                                        <h5 className="mb-0">
                                            <span className="btn">
                                                {t("fix_request:working_sheet_detail")}
                                            </span>
                                        </h5>
                                    </div>
                                    <div
                                        className="col d-flex align-items-end justify-content-end">
                                        <h5>+</h5>
                                    </div>
                                </div>
                            </div>

                            <div id={"collapse"+this.props.index} className="collapse">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col">
                                             <h5>{this.props.room.node.detail}</h5>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-md-4">
                                            <span className="grey-color">{t("fix_request:operation_date")}</span>
                                            <h5>{this.props.room.node.fixRequestRoom.startDate ? format(new Date(this.props.room.node.fixRequestRoom.startDate), 'DD/MM/YYYY', {locale: thLocale}) : '-'}</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <span className="grey-color">{t("fix_request:time")}</span>
                                            {/*<h5>{format(new Date(this.props.room.node.fixRequestRoom.startTime), 'HH:mm น.  ', {locale: thLocale})} - {format(new Date(this.props.room.node.fixRequestRoom.endTime), 'HH:mm น.', {locale: thLocale})}</h5>*/}
                                            {this.props.room.node.fixRequestRoom.startTime && this.props.room.node.fixRequestRoom.endTime &&
                                            <h5>{this.props.room.node.fixRequestRoom.startTime + ' น.'} - {this.props.room.node.fixRequestRoom.endTime + ' น.'}</h5>
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            <span className="grey-color">{t("fix_request:responsible")}</span>
                                            <h5>{this.props.room.node.fixRequestRoom.technicianName ? this.props.room.node.fixRequestRoom.technicianName : '-'}</h5>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <span className="grey-color">{t("fix_request:performance")}</span>
                                            <div className="form-inline">
                                                <h6 className="highlight green mr-3 mt-2">{t("fix_request:finished")}</h6>
                                                <h5 className="mt-1">{t("fix_request:improvement_successful")}</h5>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="row mt-2">*/}
                                    {/*    <div className="col-md-12">*/}
                                    {/*        <img src="http://lorempixel.com/output/technics-q-c-80-80-4.jpg"*/}
                                    {/*             alt="fix"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            </Translation>
        )
    }
}

export default FixListDetailFinish;