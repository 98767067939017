import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";
 
const editSlipPayMent = async (data) => {
  let token_id = await localStorage.getItem("token");
  let header = {
    authorization: `JWT ${token_id}`,
    "Content-Type": "application/json",
  };

  return axios.post(`${getApiRoot()}graphql`, data, {
    headers: header,
  });
};

export default {
  editSlipPayMent,
};
