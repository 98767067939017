/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type paySelectQueryVariables = {|
  id?: ?string,
  status?: ?string,
|};
export type paySelectQueryResponse = {|
  +allPurchaseRecordGroup: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +dueDate: ?any,
        +total: number,
        +site: {|
          +id: string
        |},
        +purchaseRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +chartOfAccount: {|
                +chartOfAccountCode: string,
                +name: string,
              |},
              +productAndService: ?{|
                +productCode: string,
                +name: string,
              |},
              +whtRate: number,
              +description: string,
              +unitItems: number,
              +price: number,
              +total: number,
              +paid: number,
              +preTaxAmount: number,
              +vat: ?number,
              +vatAmount: number,
              +whtRatePercent: ?number,
              +prepaidDepositTransactionUsed: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +amount: ?number,
                    +prepaidDepositTransaction: {|
                      +whtRatePercent: ?number,
                      +whtAmount: number,
                    |},
                  |}
                |}>
              |},
              +acceptCreditNoteTransaction: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +price: number,
                    +total: number,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type paySelectQuery = {|
  variables: paySelectQueryVariables,
  response: paySelectQueryResponse,
|};
*/


/*
query paySelectQuery(
  $id: ID
  $status: String
) {
  allPurchaseRecordGroup(contact_Id: $id, status_In: $status, purchaseRecordType: "purchase") {
    edges {
      node {
        id
        docNumber
        issuedDate
        dueDate
        total
        site {
          id
        }
        purchaseRecord(status_In: $status) {
          edges {
            node {
              id
              chartOfAccount {
                chartOfAccountCode
                name
                id
              }
              productAndService {
                productCode
                name
                id
              }
              whtRate
              description
              unitItems
              price
              total
              paid
              preTaxAmount
              vat
              vatAmount
              whtRatePercent
              prepaidDepositTransactionUsed {
                edges {
                  node {
                    amount
                    prepaidDepositTransaction {
                      whtRatePercent
                      whtAmount
                      id
                    }
                    id
                  }
                }
              }
              acceptCreditNoteTransaction(status: "paid") {
                edges {
                  node {
                    price
                    total
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = {
  "kind": "Variable",
  "name": "status_In",
  "variableName": "status"
},
v2 = [
  {
    "kind": "Variable",
    "name": "contact_Id",
    "variableName": "id"
  },
  {
    "kind": "Literal",
    "name": "purchaseRecordType",
    "value": "purchase"
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SiteType",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v9 = [
  (v1/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtRate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitItems",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paid",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preTaxAmount",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vat",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vatAmount",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtRatePercent",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtAmount",
  "storageKey": null
},
v24 = [
  {
    "kind": "Literal",
    "name": "status",
    "value": "paid"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "paySelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PurchaseRecordGroupNodeConnection",
        "kind": "LinkedField",
        "name": "allPurchaseRecordGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordGroupNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseRecordGroupNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "PurchaseRecordNodeConnection",
                    "kind": "LinkedField",
                    "name": "purchaseRecord",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PurchaseRecordNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PurchaseRecordNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ChartOfAccountNode",
                                "kind": "LinkedField",
                                "name": "chartOfAccount",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductAndServiceNode",
                                "kind": "LinkedField",
                                "name": "productAndService",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v7/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v20/*: any*/),
                              (v21/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                                "kind": "LinkedField",
                                "name": "prepaidDepositTransactionUsed",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PrepaidDepositTransactionUsedNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v22/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PrepaidDepositTransactionNode",
                                            "kind": "LinkedField",
                                            "name": "prepaidDepositTransaction",
                                            "plural": false,
                                            "selections": [
                                              (v21/*: any*/),
                                              (v23/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": (v24/*: any*/),
                                "concreteType": "AcceptCreditNoteNodeConnection",
                                "kind": "LinkedField",
                                "name": "acceptCreditNoteTransaction",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AcceptCreditNoteNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AcceptCreditNoteNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v16/*: any*/),
                                          (v7/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "acceptCreditNoteTransaction(status:\"paid\")"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "paySelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PurchaseRecordGroupNodeConnection",
        "kind": "LinkedField",
        "name": "allPurchaseRecordGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordGroupNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseRecordGroupNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "PurchaseRecordNodeConnection",
                    "kind": "LinkedField",
                    "name": "purchaseRecord",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PurchaseRecordNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PurchaseRecordNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ChartOfAccountNode",
                                "kind": "LinkedField",
                                "name": "chartOfAccount",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductAndServiceNode",
                                "kind": "LinkedField",
                                "name": "productAndService",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  (v11/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v7/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v20/*: any*/),
                              (v21/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                                "kind": "LinkedField",
                                "name": "prepaidDepositTransactionUsed",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PrepaidDepositTransactionUsedNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v22/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PrepaidDepositTransactionNode",
                                            "kind": "LinkedField",
                                            "name": "prepaidDepositTransaction",
                                            "plural": false,
                                            "selections": [
                                              (v21/*: any*/),
                                              (v23/*: any*/),
                                              (v3/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v3/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": (v24/*: any*/),
                                "concreteType": "AcceptCreditNoteNodeConnection",
                                "kind": "LinkedField",
                                "name": "acceptCreditNoteTransaction",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AcceptCreditNoteNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AcceptCreditNoteNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v16/*: any*/),
                                          (v7/*: any*/),
                                          (v3/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "acceptCreditNoteTransaction(status:\"paid\")"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "99002f39155b04df0fcacac1fc176c5b",
    "id": null,
    "metadata": {},
    "name": "paySelectQuery",
    "operationKind": "query",
    "text": "query paySelectQuery(\n  $id: ID\n  $status: String\n) {\n  allPurchaseRecordGroup(contact_Id: $id, status_In: $status, purchaseRecordType: \"purchase\") {\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        dueDate\n        total\n        site {\n          id\n        }\n        purchaseRecord(status_In: $status) {\n          edges {\n            node {\n              id\n              chartOfAccount {\n                chartOfAccountCode\n                name\n                id\n              }\n              productAndService {\n                productCode\n                name\n                id\n              }\n              whtRate\n              description\n              unitItems\n              price\n              total\n              paid\n              preTaxAmount\n              vat\n              vatAmount\n              whtRatePercent\n              prepaidDepositTransactionUsed {\n                edges {\n                  node {\n                    amount\n                    prepaidDepositTransaction {\n                      whtRatePercent\n                      whtAmount\n                      id\n                    }\n                    id\n                  }\n                }\n              }\n              acceptCreditNoteTransaction(status: \"paid\") {\n                edges {\n                  node {\n                    price\n                    total\n                    id\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f9bf2a691d42bd0ca1a3e60c6d32bdfb';

module.exports = node;
