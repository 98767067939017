import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import PetList from "./petList";
import NoMatch from "../../components/noMatch";

class Pet extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={PetList}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Pet;