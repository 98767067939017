import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import ContactReportList from "../contactReportList";
import ReceivableOutstandingSummaryReportTable from "./receivableOutstandingSummaryReportTable";
import ExportReceivableOutstandingSummaryReport from "./server_export";
import _ from "lodash";
import { formatDateISO } from "../../../../libs/date";
import Loading from '../../../../libs/loading';
import postApiUseing from '../../../../libs/postApiUseing';
import { fetchQuery } from "relay-runtime";
import Swal from "sweetalert2";
import './receivableOutstandingSummaryReport.scss';
import i18next from "i18next";

const query = graphql`
  query receivableOutstandingSummaryReportQuery(
    $dueDate: Date!
    $contactId: ID
    $typeOfContact: String
    $search: String
  ) {
    reportOutstandingReceivableSummary(
      dueDate: $dueDate
      contactId: $contactId
      typeOfContact: $typeOfContact
      search: $search
    ) {
      edges {
        node {
          id
          name
          refNumber
          firstName
          lastName
          typeOfContact
          outstandingBalance
          notOverdueBalance
          overdueOneMonthBalance
          overdueTwoMonthsBalance
          overdueThreeMonthsBalance
          overdueFourMonthsBalance
          overdueFiveMonthsBalance
          overdueSixMonthsBalance
          overdueOverSixMonthsBalance
        }
      }
    }
  }
`;

class ReceivableOutstandingSummaryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: "",
      temp_contact: "",
      customer_type: "",
      temp_customer_type: "",
      search: "",
      search_input: "",
      dueDate: new Date(),
      temp_dueDate: new Date(),
      checkFetchState: false,
      dataResult: "",
      loading: false,
    };

    this.maxDate = new Date();
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchDataQuery = this.fetchDataQuery.bind(this);
    this.checkLoadingExcel = this.checkLoadingExcel.bind(this);

  }

  componentWillMount() {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "receivableOutstandingSummaryReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
        console.log(error);
    })

    this.fetchDataQuery();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      dueDate: this.state.temp_dueDate,
      search: event.target.value,
      contact: this.state.temp_contact,
      customer_type: this.state.temp_customer_type,
    }, 
    () => {
      this.fetchDataQuery()
    });
  }

  onClickGetData(data) {
    this.props.history.push(`/accounting/report/account-receivable/receivable_outstanding_summary/PDF`,{ data: data})
  }

  fetchDataQuery() {
    this.setState({ 
      checkFetchState: false,
      loading: true, 
    })
    fetchQuery(environment, query, {
      contactId: this.state.contact,
      typeOfContact: this.state.customer_type,
      search: this.state.search,
      dueDate: formatDateISO(this.state.dueDate),
    }).then((item) => {
      this.setState({ dataResult: item, checkFetchState: true, loading: false });
    }).catch(error => {
      Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
    });
  }

  checkLoadingExcel(status) {
    this.setState({ checkFetchState: status });
  }
  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box">
            <div className="row justify-content-between" id="receiveableOutstandingSummaryReport" >
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting/report/account-receivable">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{i18next.t("receivable_dashboard:Summary Outstanding Receivables Report")}</span>}
                  </Translation>
                </h3>
              </div>
              <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "report_receivable_print",
                }) && (
                  <div className={`${this.state.checkFetchState ? "dropdown" : ''} printReport`}>
                      {!this.state.checkFetchState ?
                          <React.Fragment>
                          <button type="button" className="btnPrint">
                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                            <Translation>{t => t("meter:Preparing information")}</Translation>
                          </button>
                        </React.Fragment>
                        :                  
                        <React.Fragment>
                          <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                            <Translation>{t => t("PageList:Print")}</Translation>
                          </button>
                        </React.Fragment>
                      }
                      <div className={`dropdown-menu`}>
                          <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state)}>PDF</a>
                          <ExportReceivableOutstandingSummaryReport state={this.state} checkLoadingExcel={this.checkLoadingExcel}/>
                      </div>
                  </div>
                )}                
              </div>
            </div>

            <Translation>
              {(t)=>
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          value={this.state.search_input}
                          name="search"
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_dueDate"
                          className="form-control"
                          selected={this.state.temp_dueDate}
                          maxDate={this.maxDate}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <div className="col-md-4 border-right">
                            <div className="form-group">
                              <label>{i18next.t("receivableOutstandingSummaryReport:Customer Type")}</label>
                              <select
                                name="temp_customer_type"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("receivableOutstandingSummaryReport:All")}</option>
                                <option value="residential">
                                {i18next.t("receivableOutstandingSummaryReport:Owners/Residents Only")}
                                </option>
                                <option value="supplier">{i18next.t("receivableOutstandingSummaryReport:Debtors Only")}</option>
                              </select>
                            </div>
                          </div>

                          {/*<ProductServiceList handleChange={this.handleChange}*/}
                          {/*                    product_and_service={this.state.product_and_service}/>*/}

                          <ContactReportList
                            handleChange={this.handleChange}
                            contact={this.state.temp_contact}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.loading ?
                  <Loading/>
                  :
                  this.state.dataResult && 
                  <React.Fragment>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table
                                      className="table table-hover"
                                      style={{ fontSize: 12 }}
                                    >
                                      <thead className="thead-light">
                                        <tr>
                                          <th rowSpan={2} className="text-center">
                                          {i18next.t("receivableOutstandingSummaryReport:Sequence")}
                                          </th>
                                          <th rowSpan={2} className="text-left">
                                          {i18next.t("receivableOutstandingSummaryReport:House/Room Number")}
                                          </th>
                                          <th rowSpan={2} className="text-left">
                                          {i18next.t("receivableOutstandingSummaryReport:Name")}
                                          </th>
                                          <th rowSpan={2} className="text-center">
                                          {i18next.t("receivableOutstandingSummaryReport:Not Exceeding Due Date")}
                                          </th>
                                          <th colSpan={7} className="text-center">
                                          {i18next.t("receivableOutstandingSummaryReport:Overdue (Days)")}
                                          </th>
                                          <th rowSpan={2} className="text-right">
                                          {i18next.t("receivableOutstandingSummaryReport:Net Outstanding")}
                                          </th>
                                        </tr>
                                        <tr className="border-top">
                                          <th className="text-center">1-30</th>
                                          <th className="text-center">31-60</th>
                                          <th className="text-center">61-90</th>
                                          <th className="text-center">91-120</th>
                                          <th className="text-center">121-150</th>
                                          <th className="text-center">151-180</th>
                                          <th className="text-center">
                                          {i18next.t("receivableOutstandingSummaryReport:More Than")}180
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <ReceivableOutstandingSummaryReportTable
                                          data={this.state.dataResult}
                                        />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                  </React.Fragment>
                  }
                </div>
              }
            </Translation>

          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ReceivableOutstandingSummaryReport;
