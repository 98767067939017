import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import WrapperContent from '../../components/wrapper/wrapperContent'
import StockTopMenuNavigation from '../stockTopMenuNavigation'
import { Translation } from 'react-i18next'
import { Link } from 'react-router-dom'
import DatePickerNoti from '../../accounting/finance/depositInterest/datePickerNoti';
import PickingList from '../pickingList/pickingList'
import './style/stockMenagement.scss'
import _ from 'lodash'
import i18next from 'i18next'

export default class createFormTenant extends Component {
  render() {
    return (
      <Wrapper>
        <Header/>
        <Sidebar/>
        <WrapperContent>
          <StockTopMenuNavigation mini={true}/>
          
          <div className="container-fluid box" id="createFormTenant">
            <div className="row">
              <div className="col">
                <Translation>
                  {
                    t =>
                      <h4 className="mb-4">
                        <Link to={this.props.action === "create"  
                          ? "/stockandsupplies/stockManagement/form/input"
                          : "/stockandsupplies/stockManagement/list/all"
                        }>
                          <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                            alt="back" className="back" />
                        </Link>
                        {t("PageList:Create requisition form")}
                      </h4>
                  }
                </Translation>

              </div>
            </div>

            <div className="content-inner">
              <form onSubmit={this.props.onsubmit}>

              {this.props.action === "edit" &&
                <div className="row mb-5">
                  <div className="col-12 d-flex justify-content-end">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: "accounting_productstock_delete"}) &&
                      <button type="button" className="btnVoid" onClick={this.props.handleOpenModal}>{i18next.t("Allaction:cancel")}</button>
                    }
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: "accounting_productstock_edit"}) && 
                      <button type="submit" className="btnSave mx-3">{i18next.t("Allaction:save")}</button>
                    }
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: "accounting_productstock_print"}) &&
                      <Link to={{
                        pathname: '/document/stockMenagement/id',
                        state: {requisitionNoteId: this.props.requisitionNoteID}
                      }} className="text-decoration-none">
                        <button type="button" className="btnPrint">{i18next.t("Allaction:Print")}</button>
                      </Link>
                    }
                  </div>
                </div>
              }

              <div className="row">
                <div className="col-4">
                  <h6>*{i18next.t("stockCreateAndUpdate:Requisition use")}</h6>
                  <input type="text"
                    disabled
                    className="form-control"
                    value={this.props.juristict}
                  />
                </div>
                <div className="col-2">
                  <h6>{i18next.t("stockCreateAndUpdate:Document No.")}</h6>
                  <input type="text"
                    disabled
                    className="form-control"
                    value={this.props.docNumber}
                  />
                </div>
                <div className="col-2">
                  <h6>{i18next.t("stockCreateAndUpdate:Requisition date")}</h6>
                  <DatePickerNoti 
                    selected={this.props.issuedDate}
                    name='requisitionNote.issuedDate'
                    onChange={this.props.handleChangeInput}
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-4">
                  <h6>*{i18next.t("keyofProduct:User product")}</h6>
                  <input type="text"
                    className="form-control"
                    name="requisitionNote.username"
                    value={this.props.username}
                    onChange={this.props.handleChangeInput}
                    required
                    />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-6">
                  <h6>{i18next.t("stockCreateAndUpdate:Requisition purpose")}</h6>
                  <div className="d-flex align-items-center">
                    <input type="radio" defaultChecked={true} />
                    <h4 className="w-50 ml-2">{i18next.t("stockCreateAndUpdate:Requisition use")} {i18next.t("stockSelectRoom:Common Area")}</h4>
                    <input type="text"
                      className="form-control"
                      name="requisitionNote.objective"
                      value={this.props.objective}
                      onChange={this.props.handleChangeInput}
                      required
                    />
                  </div>
                </div>
              </div>

              <PickingList
                action={this.props.action}
                allProductList={this.props.allProductList}
                switchSelectProduct={this.props.switchSelectProduct}
                appendRequisitionList={this.props.appendRequisitionList}
                requisitionList={this.props.requisitionList}
                requisitionNoteDocuments={this.props.requisitionNoteDocuments}
                remark={this.props.remark}
                appendFileInputRow={this.props.appendFileInputRow}
                handleRemoveImgSelect={this.props.handleRemoveImgSelect}
                handleRemoveSelect={this.props.handleRemoveSelect}
                handleChangeNumberOfPieces={this.props.handleChangeNumberOfPieces}
                handleChangeInput={this.props.handleChangeInput}
                requestingNumber={true}
                />

              {this.props.action === "create" && 
                <div className="row mt-5">
                  <div className="col-12 d-flex justify-content-end">
                    <button className="btnSave" >{i18next.t("stockCreateAndUpdate:Save")}</button>
                  </div>
                </div>
              }
              </form>

              <div className="mb-5"/>
            </div>
          </div>

        </WrapperContent>
      </Wrapper>
    )
  }
}
