import React from "react";
import Sidebar from "../../../components/sidebar";
import Wrapper from "../../../components/wrapper";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Header from "../../../components/header/index";
import { Translation } from "react-i18next";
import Navigation from "./navigation";
import DepositInterestBank from './depositInterestBank';
import './deposit-interest.scss'
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import ComponentPagination from "../../../libs/componentPagination";
import Pagination from "../../../libs/newPagination";
import _ from "lodash";
import BankAccountQuery from "./bankAccount";
import Loading from '../../../libs/loading';
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import i18next from "i18next";

const query = graphql`
    query depositInterestDashBoardQuery($id: ID) {

        allAccountProjectManager(id:$id){
            edges{
              node{
                id
                startSite
              }
            }
        }
        myUser {
            juristic {
                id
            }
        }
    }`;
class DepositInterestDashBoard extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            depositBank: [],
            totalCount: 0,
            startSite: '',
            status: this.props.match.params.status,
            loading: false,
        }

    }

    componentDidMount() {
        this.fetchQuery()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.status !== this.state.status) {
            this.setState({
                status: this.props.match.params.status,
            }, () => this.fetchQuery())
        }
        if (this.state.first !== prevState.first || this.state.last !== prevState.last) {
            this.fetchQuery()
        }
    }

    fetchQuery = async () => {
        this.setState({ loading: true })
        fetchQuery(environment, query,
            {
                id: 1
            }).then(async (data) => {
                if (data?.allAccountProjectManager?.edges) {
                    this.setState({
                        startSite: new Date(data?.allAccountProjectManager?.edges[0]?.node?.startSite)
                    })
                }
                if (data?.myUser?.juristic?.id) {
                    BankAccountQuery(data.myUser.juristic.id, this.state.first, this.state.last, this.state.status).then((data_list) => {
                        if (data_list.depositBank !== this.state.depositBank) {
                            this.setState({
                                depositBank: _.cloneDeep(data_list.depositBank),
                                // totalCount : _.cloneDeep(data_list.totalCount)
                            }, () => this.setState({ loading: false }))
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                }
            });
    }

    reQuery = () => {
        this.fetchQuery()
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="deposit-interest">
                                <BackButtonIcon
                                    LinkBack={"/accounting"}
                                    LinkText={i18next.t("depositInterestDashBoard:Fixed Deposit Account")}
                                />

                                <div className="content-inner">
                                    <Navigation />
                                    {this.state.loading ?
                                        <div className="mt-3 col ">
                                            {/* <span className="d-flex justify-content-center align-item-center">
                                    <span
                                    className="spinner-border spinner-border-sm align-middle mr-2"/> 
                                    {i18next.t("Allaction:Preparing information")}
                                </span> */}
                                            <Loading />
                                        </div>
                                        :
                                        <div className="mt-3">
                                            {this.state.depositBank.map((bankDetail, index) => {
                                                return (
                                                    <DepositInterestBank key={index} bankDetail={bankDetail.node}
                                                        startSite={this.state.startSite} reQuery={this.reQuery}
                                                        status={this.state.status}
                                                    />
                                                );
                                            })
                                            }
                                        </div>
                                    }
                                    <div className="row col-12">
                                        <Pagination
                                            changePage={this.changePage}
                                            first={this.state.first}
                                            last={this.state.last}
                                            totalCount={this.state.totalCount}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>

        );
    }




}
export default DepositInterestDashBoard;