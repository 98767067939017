/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type formOneQueryQueryVariables = {|
  formDebit?: ?number
|};
export type formOneQueryQueryResponse = {|
  +allSettingFormDebit: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +description: ?string,
        +paymentCheck: boolean,
        +paymentTimeStatus: boolean,
        +paymentTime1: ?any,
        +paymentTime2: ?any,
        +bankAccountStatus: boolean,
        +numberOfDays: number,
        +bankAccount: ?{|
          +id: string,
          +accountNumber: string,
          +bankName: string,
          +accountName: string,
          +branch: string,
        |},
        +channelDescription: ?string,
        +contactName: ?string,
        +contactPhone: ?string,
        +signerName: ?string,
        +signerPosition: ?string,
        +formDebit: number,
      |}
    |}>
  |}
|};
export type formOneQueryQuery = {|
  variables: formOneQueryQueryVariables,
  response: formOneQueryQueryResponse,
|};
*/


/*
query formOneQueryQuery(
  $formDebit: Int
) {
  allSettingFormDebit(formDebit: $formDebit) {
    edges {
      node {
        id
        description
        paymentCheck
        paymentTimeStatus
        paymentTime1
        paymentTime2
        bankAccountStatus
        numberOfDays
        bankAccount {
          id
          accountNumber
          bankName
          accountName
          branch
        }
        channelDescription
        contactName
        contactPhone
        signerName
        signerPosition
        formDebit
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "formDebit"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "formDebit",
        "variableName": "formDebit"
      }
    ],
    "concreteType": "SettingFormDebitNodeConnection",
    "kind": "LinkedField",
    "name": "allSettingFormDebit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingFormDebitNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingFormDebitNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentCheck",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentTimeStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentTime1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentTime2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bankAccountStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfDays",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountNode",
                "kind": "LinkedField",
                "name": "bankAccount",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bankName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "branch",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "channelDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contactName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contactPhone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signerPosition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formDebit",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "formOneQueryQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "formOneQueryQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c385068d81b4a48a8c6c916517560633",
    "id": null,
    "metadata": {},
    "name": "formOneQueryQuery",
    "operationKind": "query",
    "text": "query formOneQueryQuery(\n  $formDebit: Int\n) {\n  allSettingFormDebit(formDebit: $formDebit) {\n    edges {\n      node {\n        id\n        description\n        paymentCheck\n        paymentTimeStatus\n        paymentTime1\n        paymentTime2\n        bankAccountStatus\n        numberOfDays\n        bankAccount {\n          id\n          accountNumber\n          bankName\n          accountName\n          branch\n        }\n        channelDescription\n        contactName\n        contactPhone\n        signerName\n        signerPosition\n        formDebit\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0c0572d0a9006c6a304ac9aad1e69bf8';

module.exports = node;
