/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HouseHolderType = "AGENT" | "OWNER" | "RENTER" | "RESIDENT" | "%future added value";
export type QuestionType = "CHOICE" | "RADIO" | "TEXT" | "%future added value";
export type SurveyStatus = "DELETE" | "DRAFT" | "INACTIVE" | "PUBLISH" | "%future added value";
export type surveyAllQueryVariables = {|
  id: string,
  residential_vote_id_in: $ReadOnlyArray<?string>,
|};
export type surveyAllQueryResponse = {|
  +allResidentialVoteWeb: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +residential: {|
          +residentialHouseholder: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +type: HouseHolderType,
                +tenant: {|
                  +firstName: string,
                  +lastName: ?string,
                |},
              |}
            |}>
          |},
          +size: number,
          +name: string,
        |},
        +id: string,
        +survey: ?{|
          +id: string,
          +title: string,
          +detail: string,
          +status: SurveyStatus,
          +surveyQuestion: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +title: string,
                +type: QuestionType,
                +questionChoice: ?{|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +title: string,
                      +voteSet: ?{|
                        +edges: $ReadOnlyArray<?{|
                          +node: ?{|
                            +id: string,
                            +residentialVote: ?{|
                              +id: string
                            |},
                            +choice: {|
                              +id: string,
                              +title: string,
                            |},
                          |}
                        |}>
                      |},
                    |}
                  |}>
                |},
              |}
            |}>
          |},
        |},
      |}
    |}>
  |},
  +residentialSizeAll: ?{|
    +sizeAll: ?number
  |},
|};
export type surveyAllQuery = {|
  variables: surveyAllQueryVariables,
  response: surveyAllQueryResponse,
|};
*/


/*
query surveyAllQuery(
  $id: ID!
  $residential_vote_id_in: [String]!
) {
  allResidentialVoteWeb(surveyId: $id, id_In: $residential_vote_id_in) {
    edges {
      node {
        residential {
          residentialHouseholder {
            edges {
              node {
                type
                tenant {
                  firstName
                  lastName
                  id
                }
                id
              }
            }
          }
          size
          name
          id
        }
        id
        survey {
          id
          title
          detail
          status
          surveyQuestion {
            edges {
              node {
                id
                title
                type
                questionChoice {
                  edges {
                    node {
                      id
                      title
                      voteSet {
                        edges {
                          node {
                            id
                            residentialVote {
                              id
                            }
                            choice {
                              id
                              title
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  residentialSizeAll {
    sizeAll
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "residential_vote_id_in"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id_In",
    "variableName": "residential_vote_id_in"
  },
  {
    "kind": "Variable",
    "name": "surveyId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "SurveyNode",
  "kind": "LinkedField",
  "name": "survey",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionNodeConnection",
      "kind": "LinkedField",
      "name": "surveyQuestion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v7/*: any*/),
                (v8/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChoiceNodeConnection",
                  "kind": "LinkedField",
                  "name": "questionChoice",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ChoiceNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ChoiceNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v7/*: any*/),
                            (v8/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "VoteNodeConnection",
                              "kind": "LinkedField",
                              "name": "voteSet",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "VoteNodeEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "VoteNode",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v7/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "ResidentialVoteNode",
                                          "kind": "LinkedField",
                                          "name": "residentialVote",
                                          "plural": false,
                                          "selections": [
                                            (v7/*: any*/)
                                          ],
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "ChoiceNode",
                                          "kind": "LinkedField",
                                          "name": "choice",
                                          "plural": false,
                                          "selections": [
                                            (v7/*: any*/),
                                            (v8/*: any*/)
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SurveyResidentialSizeAll",
  "kind": "LinkedField",
  "name": "residentialSizeAll",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sizeAll",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "surveyAllQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialVoteNodeConnection",
        "kind": "LinkedField",
        "name": "allResidentialVoteWeb",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialVoteNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialVoteNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeConnection",
                        "kind": "LinkedField",
                        "name": "residentialHouseholder",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HouseHolderNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TenantNode",
                                    "kind": "LinkedField",
                                    "name": "tenant",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "surveyAllQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialVoteNodeConnection",
        "kind": "LinkedField",
        "name": "allResidentialVoteWeb",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialVoteNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialVoteNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeConnection",
                        "kind": "LinkedField",
                        "name": "residentialHouseholder",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HouseHolderNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TenantNode",
                                    "kind": "LinkedField",
                                    "name": "tenant",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v4/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ]
  },
  "params": {
    "cacheID": "3804e775af8d2998881c14890152d0f1",
    "id": null,
    "metadata": {},
    "name": "surveyAllQuery",
    "operationKind": "query",
    "text": "query surveyAllQuery(\n  $id: ID!\n  $residential_vote_id_in: [String]!\n) {\n  allResidentialVoteWeb(surveyId: $id, id_In: $residential_vote_id_in) {\n    edges {\n      node {\n        residential {\n          residentialHouseholder {\n            edges {\n              node {\n                type\n                tenant {\n                  firstName\n                  lastName\n                  id\n                }\n                id\n              }\n            }\n          }\n          size\n          name\n          id\n        }\n        id\n        survey {\n          id\n          title\n          detail\n          status\n          surveyQuestion {\n            edges {\n              node {\n                id\n                title\n                type\n                questionChoice {\n                  edges {\n                    node {\n                      id\n                      title\n                      voteSet {\n                        edges {\n                          node {\n                            id\n                            residentialVote {\n                              id\n                            }\n                            choice {\n                              id\n                              title\n                            }\n                          }\n                        }\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  residentialSizeAll {\n    sizeAll\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c422c70097404b32d849c4995b070158';

module.exports = node;
