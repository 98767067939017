import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import CrossBillingPaymentCreate from './crossBillingPaymentCreate';

class CreditNote extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/`} component={CrossBillingPaymentCreate}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default CreditNote;