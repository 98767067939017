import React from "react";
import getApiRoot from "../../../libs/getAPIRoot";
import Helpers from "../../../libs/file_download_helper";
import {format} from "date-fns";
import { Translation } from "react-i18next";


class ExportChequeTransaction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: false};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getDate(date){
        if(date === null){
            return ''
        }else{
            return format(date, 'DD/MM/YYYY')
        }
    }

    handleSubmit = (event) => {
        let path = ''
        if(this.props.status === 'all'){
            path =  'export/cheque_transaction?&search='+ this.props.search + 
                    '&start_date=' + this.getDate(this.props.start_date) +
                    '&end_date=' + this.getDate(this.props.end_date) 

        }else{
            path = 'export/cheque_transaction?&status=' + this.props.status  + '&search=' + this.props.search +
                    '&start_date=' + this.getDate(this.props.start_date)+
                    '&end_date=' + this.getDate(this.props.end_date)
        }
        this.setState({
            errors: null,
            loading: true,
        }, () => {
            Helpers.httpRequest(
                getApiRoot() + path,
                'GET',
            )
                .then((response) => response.blob())
                .then((blob) => {
                    // create blob link
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "cheque_transaction.xls");

                    // append to html
                    document.body.appendChild(link);

                    // download
                    link.click();

                    // remove
                    link.parentNode.removeChild(link);

                    this.setState({
                        loading: false
                    });
                })
                .catch((error) => {
                    error.json().then((json) => {
                        this.setState({
                            errors: json,
                            loading: false
                        });
                    })
                });
        });

        event.preventDefault();
    };

    render() {

        return (
            <form onSubmit={this.handleSubmit}>
                <Translation>
                    {t=>
                    <button type="submit" className="btn print float-right mr-3"
                            disabled={this.state.loading}>
                        {this.state.loading ?
                            <span>
                            <span
                                className="spinner-border spinner-border-sm align-middle mr-2"/> {t("cheque:Preparing")}</span> :
                            <span>
                            <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon"/>
                            {t("cheque:Print")}
                            </span>
                        }
                    </button>}
                </Translation>
            </form>
        )
    }
}

export default ExportChequeTransaction;
