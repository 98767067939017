import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../../env/environment';
import "./style/contractExpense.scss"
import i18next from 'i18next';

const query = graphql`
    query contractExpenseTopMenuNavigationQuery{
        getNotificationContractExpense {
            type
            count
        }
    }
`;

export default class contractExpenseTopMenuNavigation extends Component {

  constructor(props) {
    super(props)

    this.state = {
      waitContractExpense: 0,
      nearContractExpense: 0,
    }
  }

  componentDidMount() {
    fetchQuery(environment, query).then((response) => {
      if (response.getNotificationContractExpense) {
        response.getNotificationContractExpense.forEach((resNoti) => {
          if (resNoti.type === "near_end_contract_expense") {
            this.setState({ nearContractExpense: resNoti.count })
          } else if (resNoti.type === "wait_contract_expense") {
            this.setState({ waitContractExpense: resNoti.count })
          }
        });
      }
    })
  }

  render() {
    return (
      <div className="row" id="contractExpenseTopMenuNavigation">
        <div className="col">
          <ul>
            <li>
              <NavLink to="/accounting/expense/contract_expese/list/all" >
                {i18next.t("contract_expese_list:All")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/accounting/expense/contract_expese/list/wait" >
                {i18next.t("contract_expese_list:Pending")}
                {this.state.waitContractExpense > 0 &&
                  <span
                    className="badge badge-pill badge-danger"
                    style={{ width: 24, marginLeft: 4 }}
                  >
                    {this.state.waitContractExpense}
                  </span>
                }
              </NavLink>
            </li>
            <li>
              <NavLink to="/accounting/expense/contract_expese/list/in_progress" >
                {i18next.t("contract_expese_list:In Process")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/accounting/expense/contract_expese/list/near_end" >
                {i18next.t("contract_expese_list:Near Expiry")}
                {this.state.nearContractExpense > 0 &&
                  <span
                    className="badge badge-pill badge-danger"
                    style={{ width: 24, marginLeft: 4 }}
                  >
                    {this.state.nearContractExpense}
                  </span>
                }
              </NavLink>
            </li>
            <li>
              <NavLink to="/accounting/expense/contract_expese/list/renew" >
                {i18next.t("contract_expese_list:Renew")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/accounting/expense/contract_expese/list/not_renew" >
                {i18next.t("contract_expese_list:Don't Renew")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/accounting/expense/contract_expese/list/void" >
                {i18next.t("contract_expese_list:Void")}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
