import React, {Component} from "react";
import {format} from "date-fns";
import getNameResidential from "../../../../libs/getNameResidential";
import { graphql } from "babel-plugin-relay/macro";
import jwtDecode from 'jwt-decode';
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import _ from 'lodash';
import './otherReceiveReportPDF.scss'
import numberWithComma from "../../../../libs/numberWithComma";
import i18next from "i18next";

const query = graphql`
  query otherReceiveReportPDFRenderTableQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

class OtherReceiveReportPDFRenderTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectName: "",
            printBy: "",
            start_date: "",
            end_date: "",
            contactName: "",
            payment_channel: "",
            productAndServiceType: "",
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            if (res.selfProject) {
                let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
                this.setState({
                    projectName: res.selfProject.name,
                    printBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    contactName: this.props.contactName,
                    payment_channel: this.props.payment_channel,
                    customerType: this.props.customerType,
                    productAndServiceType: this.props.productAndServiceType,
                })
            }
        }
        )
    }

    removeTrailingZeros(description){
        if(description.includes("ค่าน้ำประปา")){
            description = description.replace(/ *\[[^)]*\] */g, " ")
            // description = description.replace(/ *\[[^)]*\] */g, " ").replace(/.50 /gi, ".5 ")
            // description = description.replace(/.00 /gi, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }


    getAmountPayment(default_value,price_NotwhtRate){
        if(default_value > 0 && price_NotwhtRate > 0){   
            if(default_value >= price_NotwhtRate){
                return {default_value : (default_value - price_NotwhtRate), price :price_NotwhtRate, price_NotwhtRate : 0 }
            }             
            else {
                return {default_value : 0, price :default_value, price_NotwhtRate : price_NotwhtRate - default_value}
            }
        }
    }

        //เปลี่ยน format number
        numberWithComma(num) {
            let isNegative = false;
            // if (num === 0) {
            //     return formattedNum = "-";
            // }
            // Check if the number is negative
            if (num < 0) {
              isNegative = true;
              num = Math.abs(num); // Convert negative number to positive
            }
            // Add .00 to the number
            num = num.toFixed(2);
            // Format the number with commas
            let formattedNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            if (num === 0) {
                return formattedNum = "-";
            }
            // Add a minus sign for negative numbers
            if (isNegative) {
              formattedNum = '(' + formattedNum + ')';
            }
            return formattedNum;
        }

    render() {
        let dataAll = [];
        let summaryRow = [];
        let transactionRow = "";
        let summaryTransactionRow = "";

        let amount_cash = 0;
        let amount_bank = 0;
        let amount_cheque = 0;
        let amount_other_payment = 0;
        let amount_credit_card = 0;
        let amount_unknown_receive = 0
        let amount_total = 0;
        let sum_all_total = 0;
        let heightOfRow = '30px';
        
        let accountRecordGroup = this.props.props.allOtherReceiveReport.accountRecordGroupNode.edges;
        {this.props.props.allOtherReceive.edges.map((other_receive, index) => {
            // let order_id = this.props.state.first - localStorage.getPageLimit();
            let order_id = 0; 

            //ยอดเต็มของแต่ละวิธีชำระเงิน
            let default_cash = other_receive.node.cashTransaction.totalCount > 0 ? _.sumBy(other_receive.node.cashTransaction.edges,'node.price') : 0;
            let default_bank = other_receive.node.bankAccountTransaction.totalCount > 0 ? _.sumBy(other_receive.node.bankAccountTransaction.edges,'node.price') : 0;
            let default_cheque = other_receive.node.chequeDeposit.totalCount > 0 ? _.sumBy(other_receive.node.chequeDeposit.edges,'node.price') : 0;
            let default_credit_card = other_receive.node.creditCardTransaction.totalCount > 0 ? _.sumBy(other_receive.node.creditCardTransaction.edges,'node.amount') : 0;
            let default_unknown_receive = 0;
            let sum_other_receive = other_receive.node.total
            let cash = 0;
            let bank = 0;
            let cheque = 0;
            let credit_card = 0;
            let unknown_receive = 0;
            let showCash,showBank,showCheque,showCreditCard,showUnknownReceive = false;           
            let excess_payment = 0;
            let lack_payment = 0;

            //เงินรับรอตรวจสอบ
            if(other_receive.node.unknownReceive.edges.length > 0){
                default_unknown_receive += _.sumBy(other_receive.node.unknownReceive.edges,'node.amount')
            }
            _.forEach(_.filter(accountRecordGroup,['node.refTransaction',other_receive.node.docNumber]), accountRecordList => {
                accountRecordList.node.accountRecord.edges.length > 0 && _.forEach(accountRecordList.node.accountRecord.edges , (accountRecord) => {
                    // รับอื่น 5830
                    if(accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("5830")){
                            let filterOT = _.filter(other_receive.node.otherReceiveTransaction.edges ,(o) => o.node?.accountRecord?.id === accountRecord?.node?.id)[0]
                            if(filterOT){
                                excess_payment += (accountRecord.node?.credit - filterOT.node?.total)
                            }else{
                                excess_payment += accountRecord.node.credit;
                            }
                    }
                    //  ส่วนต่างเศษสตางค์
                    if(accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("4300-12")){
                        if(accountRecord.node.credit !== 0){
                            excess_payment += accountRecord.node.credit
                        }else if(accountRecord.node.debit !== 0){
                            lack_payment += accountRecord.node.debit
                        }
                    }
                })
            })
            // ค่าธรรมเนียมธนาคาร
            if(other_receive.node.feeAccounting.edges.length > 0){
                lack_payment += _.sumBy(other_receive.node.feeAccounting.edges,'node.amount')
            }

            let other_payment = 0;
            other_payment = lack_payment - excess_payment;

            // amount_other_payment += other_payment
            // amount_cash += default_cash
            // amount_bank += default_bank
            // amount_credit_card += default_credit_card
            // amount_cheque += default_cheque
            // amount_unknown_receive += default_unknown_receive

            if(other_receive.node.status !== "VOID"){
                    {other_receive.node.otherReceiveTransaction.edges.map((other_transaction, t_index) => {
                        let number_of_transaction = other_receive.node.otherReceiveTransaction.totalCount;
                        let row_number = (t_index === 0 && order_id + index + 1);
                        
                        let price_transaction = other_transaction.node.total;
                        amount_total += other_transaction.node.total

                        //เช็คแสดงแค่บรรทัดที่มีการเรียกใช้วิธีชำระเงินนั้น
                        showUnknownReceive = default_unknown_receive > 0 ? true : false;
                        showCash = default_cash > 0 ? true : false;
                        showBank = default_bank > 0 ? true : false;
                        showCheque = default_cheque > 0 ? true : false;
                        showCreditCard = default_credit_card > 0 ? true : false;

                        // กรณีมีหลายรายการ และเป็นรายการสุดท้ายแล้วมีส่วนอื่นๆ ติดลบ(ส่วนเกิน)
                        if(t_index+1 === number_of_transaction && number_of_transaction!== 1 && other_payment < 0){
                            if(other_payment < 0){
                                let total_other_payment = Math.abs(other_payment) + price_transaction
                                if(default_unknown_receive > 0){ 
                                    //หักลบยอดวิธีชำระเงินที่ใช้โดนยึดจากเงินอื่นๆที่ต้องบวกเพิ่มและจำนวนเงินของรายการ 
                                    let amountPayment = this.getAmountPayment(default_unknown_receive,total_other_payment) 
                                    default_unknown_receive = amountPayment.default_value;
                                    unknown_receive = amountPayment.price;
                                    total_other_payment = amountPayment.price_NotwhtRate
                                }
                                if(default_cash > 0){  
                                    let amountPayment = this.getAmountPayment(default_cash,total_other_payment) 
                                    default_cash = amountPayment.default_value;
                                    cash = amountPayment.price;
                                    total_other_payment = amountPayment.price_NotwhtRate
                                }
                                if(default_bank > 0 ){
                                    let amountPayment = this.getAmountPayment(default_bank,total_other_payment)
                                    default_bank = amountPayment.default_value;
                                    bank = amountPayment.price;
                                    total_other_payment = amountPayment.price_NotwhtRate 
                                }
                                if(default_credit_card > 0){
                                    let amountPayment = this.getAmountPayment(default_credit_card,total_other_payment)
                                    default_credit_card = amountPayment.default_value;
                                    credit_card = amountPayment.price ;
                                    total_other_payment = amountPayment.price_NotwhtRate
                                }
                                if(default_cheque > 0){
                                    let amountPayment = this.getAmountPayment(default_cheque,total_other_payment)
                                    default_cheque = amountPayment.default_value;
                                    cheque = amountPayment.price;
                                    total_other_payment = amountPayment.price_NotwhtRate
                                } 
                            }
                        //กรณีมีรายการเดียว
                        }else if(t_index+1 === number_of_transaction && number_of_transaction === 1){
                            if(default_unknown_receive > 0 && price_transaction > 0){  
                                let amountPayment = this.getAmountPayment(default_unknown_receive,price_transaction) 
                                default_unknown_receive = amountPayment.default_value;
                                unknown_receive = amountPayment.price;
                                price_transaction = amountPayment.price_NotwhtRate
                            }
                            if(default_cash > 0 && price_transaction > 0){  
                                let amountPayment = this.getAmountPayment(default_cash,price_transaction) 
                                default_cash = amountPayment.default_value;
                                cash = amountPayment.price;
                                price_transaction = amountPayment.price_NotwhtRate
                            }
                            if(default_bank > 0 && price_transaction > 0){
                                let amountPayment = this.getAmountPayment(default_bank,price_transaction)
                                default_bank = amountPayment.default_value;
                                bank = amountPayment.price;
                                price_transaction = amountPayment.price_NotwhtRate 
                            }
                            if(default_credit_card > 0 && price_transaction > 0){
                                let amountPayment = this.getAmountPayment(default_credit_card,price_transaction)
                                default_credit_card = amountPayment.default_value;
                                credit_card = amountPayment.price;
                                price_transaction = amountPayment.price_NotwhtRate
                            }
                            if(default_cheque > 0 && price_transaction > 0){
                                let amountPayment = this.getAmountPayment(default_cheque,price_transaction)
                                default_cheque = amountPayment.default_value;
                                cheque = amountPayment.price;
                                price_transaction = amountPayment.price_NotwhtRate
                            }

                            if(other_payment < 0){
                                if(other_receive.node.receiptDeposit.edges.length === 0){
                                    if(default_unknown_receive > 0 ){
                                        unknown_receive += default_unknown_receive;
                                    }
                                    if(default_cash > 0 ){
                                        cash += default_cash;
                                    }
                                    if(default_bank >0){
                                        bank += default_bank
                                    }
                                    if(default_credit_card >0 ){   
                                        credit_card += default_cheque 
                                    }  
                                    if(default_cheque >0 ){   
                                        cheque += default_cheque 
                                    }  
                                }else{
                                    if(default_unknown_receive > 0 ){
                                        unknown_receive += Math.abs(other_payment);
                                    }
                                    if(default_cash > 0 ){
                                        cash += Math.abs(other_payment);
                                    }
                                    if(default_bank >0){
                                        bank += Math.abs(other_payment);
                                    }
                                    if(default_credit_card >0 ){   
                                        credit_card += Math.abs(other_payment); 
                                    }  
                                    if(default_cheque >0 ){   
                                        cheque += Math.abs(other_payment); 
                                    } 
                                }
                            }

                        //กรณีหลายรายการแต่ไม่ใช่บรรทัดสุดท้าย จะทำการหักลบเงินตามค่าของรายการ ไม่รวมส่วนเกิน 
                        }else{
                            if(default_unknown_receive > 0 && price_transaction > 0){  
                                let amountPayment = this.getAmountPayment(default_unknown_receive,price_transaction) 
                                default_unknown_receive = amountPayment.default_value;
                                unknown_receive = amountPayment.price;
                                price_transaction = amountPayment.price_NotwhtRate
                            }
                            if(default_cash > 0 && price_transaction > 0){  
                                let amountPayment = this.getAmountPayment(default_cash,price_transaction) 
                                default_cash = amountPayment.default_value;
                                cash = amountPayment.price;
                                price_transaction = amountPayment.price_NotwhtRate
                            }
                            if(default_bank > 0 && price_transaction > 0){
                                let amountPayment = this.getAmountPayment(default_bank,price_transaction)
                                default_bank = amountPayment.default_value;
                                bank = amountPayment.price;
                                price_transaction = amountPayment.price_NotwhtRate 
                            }
                            if(default_credit_card > 0 && price_transaction > 0){
                                let amountPayment = this.getAmountPayment(default_credit_card,price_transaction)
                                default_credit_card = amountPayment.default_value;
                                credit_card = amountPayment.price;
                                price_transaction = amountPayment.price_NotwhtRate
                            }
                            if(default_cheque > 0 && price_transaction > 0){
                                let amountPayment = this.getAmountPayment(default_cheque,price_transaction)
                                default_cheque = amountPayment.default_value;
                                cheque = amountPayment.price;
                                price_transaction = amountPayment.price_NotwhtRate
                            }


                        }
                            // transaction รายบรรทัด
                            transactionRow = 
                            (
                                <tr key={other_transaction.node.id + t_index} style={{height: `${heightOfRow}`}}>
                                    <td className="text-center">{row_number}</td>
                                    <td className="text-center">
                                        {t_index === 0 ? other_receive.node.docNumber : ''}
                                    </td>
                                    <td className="text-center">{t_index === 0 ? format(other_receive.node.issuedDate, 'DD/MM/YYYY') : ''}</td>
                                    <td className="text-center">
                                        {t_index === 0 ? (other_receive.node.contact && other_receive.node.contact.residential ? other_receive.node.contact.residential.name : "-") : ''}
                                    </td>
                                    <td className="text-left">
                                        {t_index === 0 ? 
                                        other_receive.node.contact ?
                                            other_receive.node.contact.typeOfContact === "RESIDENTIAL" ?
                                                getNameResidential(other_receive.node.contact.firstName, other_receive.node.contact.lastName) :
                                                other_receive.node.contact.name
                                            :
                                            other_receive.node.unknownContact ?
                                                other_receive.node.unknownContact : "-"
                                        : ''}
                                        {" "}                                            
                                        {
                                            t_index === 0 && (other_receive.node.payGroup === "RENT" || other_receive.node.payGroup === "AGENT" || other_receive.node.payGroup === "OWNER" || other_receive.node.payGroup === "DEVELOPER") &&
                                            <label>
                                                ({other_receive.node.payGroup === "RENT" || other_receive.node.payGroup === "AGENT" || other_receive.node.payGroup === "OWNER" || other_receive.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${other_receive.node.payGroup}`) : ""})
                                            </label>
                                        }                                        
                                    </td>
                                    <td className="text-left">{
                                        this.removeTrailingZeros(other_transaction.node.description)
                                        }</td>
                                    <td className="text-right">{other_receive.node.cashTransaction.totalCount > 0 &&
                                    <span>{showCash && this.numberWithComma(cash, "")}</span>}</td>
                                    <td className="text-right">{other_receive.node.bankAccountTransaction.totalCount > 0 &&
                                    <span>{showBank && this.numberWithComma(bank,"")}</span>}</td>
                                    <td className="text-right">{other_receive.node.creditCardTransaction.totalCount > 0 &&
                                    <span>{showCreditCard && this.numberWithComma(credit_card,"")}</span>}</td>
                                    <td className="text-right">{other_receive.node.chequeDeposit.totalCount > 0 &&
                                    <span>{showCheque && this.numberWithComma(cheque,"")}</span>}</td>
                                    <td className="text-right">{unknown_receive > 0 &&
                                    <span>{showUnknownReceive && this.numberWithComma(unknown_receive,"")}</span>}</td>
                                    <td className="text-right">
                                    <span>{t_index+1 === number_of_transaction &&
                                    this.numberWithComma(other_payment,"")}
                                    </span> </td>

                                    <td className="text-right">{this.numberWithComma(other_transaction.node.total)}</td>
                                    <td className="text-right">{t_index +1 === number_of_transaction && other_receive.node.receiptDeposit.edges.length === 0  && this.numberWithComma(sum_other_receive)}</td>
                                    
                                </tr>
                            )
                            dataAll.push(transactionRow);
                            // คำนวน summaryRow
                            amount_cash += cash;
                            amount_bank += bank;
                            amount_credit_card += credit_card;
                            amount_cheque += cheque;
                            amount_unknown_receive += unknown_receive;
                            t_index+1 === number_of_transaction && (amount_other_payment += other_payment);
                            sum_all_total += (t_index +1 === number_of_transaction) && (other_receive.node.receiptDeposit.edges.length === 0)  && sum_other_receive;
                            
                            // transaction บรรทัดรวม
                            summaryTransactionRow = 
                            (<tr key={"sumRow" + other_transaction.node.id + t_index}>
                                <td colSpan={6} className="text-center"><strong>รวม</strong></td>
                                <td className="text-right"><strong>{this.numberWithComma(amount_cash)}</strong></td>
                                <td className="text-right"><strong>{this.numberWithComma(amount_bank)}</strong></td>
                                <td className="text-right"><strong>{this.numberWithComma(amount_credit_card)}</strong></td>
                                <td className="text-right"><strong>{this.numberWithComma(amount_cheque)}</strong></td>
                                <td className="text-right"><strong>{this.numberWithComma(amount_unknown_receive)}</strong></td>
                                <td className="text-right"><strong>{this.numberWithComma(amount_other_payment)}</strong></td>
                                <td className="text-right"><strong>{this.numberWithComma(amount_total)}</strong></td>
                                <td className="text-right"><strong>{this.numberWithComma(sum_all_total)}</strong></td>
                            </tr>);
                            summaryRow.push(summaryTransactionRow);
                    })}


                    {other_receive.node.receiptDeposit.edges.length > 0 && other_receive.node.receiptDeposit.edges.map((receipt_deposit,t_index) => {
                        let price_transaction = receipt_deposit.node.total;
                        sum_other_receive += receipt_deposit.node.total;
                        amount_total += receipt_deposit.node.total;

                        showUnknownReceive = default_unknown_receive > 0 ? true : false;
                        showCash = default_cash > 0 ? true : false;
                        showBank = default_bank > 0 ? true : false;
                        showCheque = default_cheque > 0 ? true : false;
                        showCreditCard = default_credit_card > 0 ? true : false;

                        
                        if(default_unknown_receive > 0 && price_transaction > 0){  
                            let amountPayment = this.getAmountPayment(default_unknown_receive,price_transaction) 
                            default_unknown_receive = amountPayment.default_value;
                            unknown_receive = amountPayment.price;
                            price_transaction = amountPayment.price_NotwhtRate
                        }
                        if(default_cash > 0 && price_transaction > 0){  
                            let amountPayment = this.getAmountPayment(default_cash,price_transaction) 
                            default_cash = amountPayment.default_value;
                            cash = amountPayment.price;
                            price_transaction = amountPayment.price_NotwhtRate
                        }
                        if(default_bank > 0 && price_transaction > 0){
                            let amountPayment = this.getAmountPayment(default_bank,price_transaction)
                            default_bank = amountPayment.default_value;
                            bank = amountPayment.price;
                            price_transaction = amountPayment.price_NotwhtRate 
                        }
                        if(default_credit_card > 0 && price_transaction > 0){
                            let amountPayment = this.getAmountPayment(default_credit_card,price_transaction)
                            default_credit_card = amountPayment.default_value;
                            credit_card = amountPayment.price;
                            price_transaction = amountPayment.price_NotwhtRate
                        }
                        if(default_cheque > 0 && price_transaction > 0){
                            let amountPayment = this.getAmountPayment(default_cheque,price_transaction)
                            default_cheque = amountPayment.default_value;
                            cheque = amountPayment.price;
                            price_transaction = amountPayment.price_NotwhtRate
                        }
                        // transaction รายบรรทัด
                        transactionRow = 
                        (
                        <tr key={receipt_deposit.node.id + t_index} style={{height: `${heightOfRow}`}}>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td>{receipt_deposit.node.docNumber +' / ' + receipt_deposit.node.receiptDepositTransaction.edges[0].node.chartOfAccount.chartOfAccountCode + ' ' +
                                        receipt_deposit.node.receiptDepositTransaction.edges[0].node.chartOfAccount.name}</td>
                                    <td className="text-right">{other_receive.node.cashTransaction.totalCount > 0 &&
                                        <span>{showCash && this.numberWithComma(receipt_deposit.node.total, "")}</span>}</td>
                                    <td className="text-right">{other_receive.node.bankAccountTransaction.totalCount > 0 &&
                                        <span>{showBank && this.numberWithComma(receipt_deposit.node.total,"")}</span>}</td>
                                    <td className="text-right">{other_receive.node.creditCardTransaction.totalCount > 0 &&
                                        <span>{showCreditCard && this.numberWithComma(receipt_deposit.node.total,"")}</span>}</td>
                                    <td className="text-right">{other_receive.node.chequeDeposit.totalCount > 0 &&
                                        <span>{showCheque && this.numberWithComma(receipt_deposit.node.total,"")}</span>}</td>
                                    <td className="text-right">{unknown_receive > 0 &&
                                        <span>{showUnknownReceive && this.numberWithComma(receipt_deposit.node.total,"")}</span>}</td>   
                                    <td className="text-right"></td>

                                    <td className="text-right">{this.numberWithComma(receipt_deposit.node.total)}</td>
                                    <td className="text-right">{t_index +1 === other_receive.node.receiptDeposit.edges.length && this.numberWithComma(sum_other_receive)}</td>
                                    
                        </tr>
                        )
                        dataAll.push(transactionRow);

                        // คำนวน summaryRow
                        amount_cash += cash;
                        amount_bank += bank;
                        amount_credit_card += credit_card
                        amount_cheque += cheque
                        amount_unknown_receive += unknown_receive
                        amount_other_payment += other_payment
                        sum_all_total += (t_index +1 === other_receive.node.receiptDeposit.edges.length)  && sum_other_receive;
                                        
                        // transaction บรรทัดรวม
                        summaryTransactionRow = 
                        (<tr key={"sumRow"+receipt_deposit.node.id}>
                            <td colSpan={6} className="text-center"><strong>รวม</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_cash)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_bank)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_credit_card)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_cheque)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_unknown_receive)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_other_payment)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_total)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(sum_all_total)}</strong></td>
                        </tr>);
                        summaryRow.push(summaryTransactionRow);
                        
                    })}
            }
            else{                
                    other_receive.node.otherReceiveTransaction.edges.map((other_transaction, t_index) => {
                        let row_number = (t_index === 0 && order_id + index + 1);

                        // transaction รายบรรทัด
                        transactionRow =
                            (
                                <tr key={other_transaction.node.id + "void" + t_index} style={{height: `${heightOfRow}`}}>
                                    <td className="text-center">{row_number}</td>
                                    <td className="text-center">
                                        {t_index === 0 && other_receive.node.docNumber}
                                    </td>
                                    <td className="text-center">{ t_index === 0 && format(other_receive.node.issuedDate, 'DD/MM/YYYY')}</td>
                                    <td className="text-center">
                                        {t_index === 0 ? other_receive.node.contact && other_receive.node.contact.residential ? other_receive.node.contact.residential.name : "-" : ''}
                                    </td>
                                    <td className="text-left">
                                        {t_index === 0 ? other_receive.node.contact ?
                                            other_receive.node.contact.typeOfContact === "RESIDENTIAL" ?
                                                getNameResidential(other_receive.node.contact.firstName, other_receive.node.contact.lastName) :
                                                other_receive.node.contact.name
                                            :
                                            other_receive.node.unknownContact ?
                                                other_receive.node.unknownContact : "-"
                                        :''
                                        }
                                        {" "}
                                        {
                                             t_index === 0 && (other_receive.node.payGroup === "RENT" || other_receive.node.payGroup === "AGENT" || other_receive.node.payGroup === "OWNER" || other_receive.node.payGroup === "DEVELOPER") &&
                                            <label>
                                                ({other_receive.node.payGroup === "RENT" || other_receive.node.payGroup === "AGENT" || other_receive.node.payGroup === "OWNER" || other_receive.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${other_receive.node.payGroup}`) : ""})
                                            </label>
                                        }     
                                    </td>
                                    <td className="text-center">{
                                        this.removeTrailingZeros(other_transaction.node.description)
                                        }</td>
                                    <td className="text-right">-</td>
                                    <td className="text-right">-</td>
                                    <td className="text-right">-</td>
                                    <td className="text-right">-</td>
                                    <td className="text-right">-</td>
                                    <td className="text-right">-</td>
                                    <td className="text-right">-</td>
                                    <td className="text-right">{this.numberWithComma(0)}</td>       
                                </tr>
                            )
                        dataAll.push(transactionRow);

                        // คำนวน summaryRow
                        amount_cash += cash;
                        amount_bank += bank;
                        amount_credit_card += credit_card
                        amount_cheque += cheque
                        amount_unknown_receive += unknown_receive
                        amount_other_payment += other_payment
                        sum_all_total += 0;

                        // transaction บรรทัดรวม
                        summaryTransactionRow = 
                        (<tr key={other_transaction.node.id}>
                            <td colSpan={6} className="text-center"><strong>รวม</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_cash)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_bank)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_credit_card)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_cheque)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_unknown_receive)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_other_payment)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(amount_total)}</strong></td>
                            <td className="text-right"><strong>{this.numberWithComma(sum_all_total)}</strong></td>
                        </tr>);
                        summaryRow.push(summaryTransactionRow);
                    })             
            }
        })
        }    


        // คำนวน data เพื่อเอาไปใช้ loop ให้ แสดงต่อหนึ่งหน้า A4
        let dataChunked = [];
        let rowPerpage = 15;
        for (let i=0;i<dataAll.length ;i += rowPerpage) {
            let chunk = dataAll.slice(i, i + rowPerpage);
            chunk.push(summaryRow[i + (rowPerpage-1)]? summaryRow[i + (rowPerpage-1)] : summaryRow[summaryRow.length-1]);
            dataChunked.push(chunk);            
        }

        const summaryOtherReceive = this.props.summary;
        let sumNumber = 0;
        for (let data in summaryOtherReceive) {
          if (data.startsWith('num')) {
            sumNumber += parseFloat(summaryOtherReceive[data]);
          }
        }

        return (
            <React.Fragment>
            {/* Loop ทีละหนึ่งหน้า */}
            {dataChunked.map((data, page_index)=>(                      
                    <div className='print-page-a4 hoziA4' key={page_index}>
                                    {/* แสดงหัวกระดาษ */}
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className='f12' style={{ lineHeight: 1.5 }}> {this.state.projectName}</p>
                                            <p className='f10'>รายงานรับชำระเงินอื่นๆ</p>
                                            <p className='f10'>เรียงตามผู้ติดต่อ - {this.state.contactName}</p>
                                            <p className='f10'>ประเภทสินค้าและบริการ - {this.state.productAndServiceType}</p> 
                                            <p className='f10'>เรียงตามวิธีชำระเงิน - {this.state.payment_channel}</p>
                                            <p className='f10'>เรียงตามวันที่ - {format(this.state.start_date, "DD-MM-YYYY")} ถึง {format(this.state.end_date, "DD-MM-YYYY")}</p>
                                        </div>
                                        <div>
                                            {page_index === 0 &&
                                            <div>
                                                <table className="table tebleBorder">
                                                <thead thead className='tableHead'>
                                                    <tr className="text-center">
                                                    <th style={{ width: '20%' }}>ประเภทการรับชำระเงิน</th>
                                                        <th style={{ width: '11%' }}>เงินสด</th>
                                                        <th style={{ width: '11%' }} >เงินโอน</th>
                                                        <th style={{ width: '11%' }} >เช็ค</th>
                                                        <th style={{ width: '11%' }} >บัตรเครดิต</th>
                                                        <th style={{ width: '11%' }} >เงินรับรอตรวจสอบ</th>
                                                        <th style={{ width: '11%' }} >อื่นๆ</th>
                                                    <th style={{ width: '14%' }}>รวม</th>
                                                    </tr>
                                                    <tr className="text-center">
                                                    <th>รายการ</th>
                                                    <td>{summaryOtherReceive.num_pay_by_cash? summaryOtherReceive.num_pay_by_cash : "-"}</td>
                                                    <td>{summaryOtherReceive.num_pay_by_bank? summaryOtherReceive.num_pay_by_bank : "-"}</td>
                                                    <td>{summaryOtherReceive.num_pay_by_cheque? summaryOtherReceive.num_pay_by_cheque : "-"}</td>
                                                    <td>{summaryOtherReceive.num_pay_by_credit_card ? summaryOtherReceive.num_pay_by_credit_card : "-"}</td>
                                                    <td>{summaryOtherReceive.num_unknown_receive ? summaryOtherReceive.num_unknown_receive : "-"}</td>
                                                    <td>{summaryOtherReceive.num_pay_by_other ? summaryOtherReceive.num_pay_by_other : "-"}</td>
                                                    <th>{sumNumber ? sumNumber : "-"}</th>
                                                    </tr>
                                                    <tr className="text-center">
                                                    <th>จำนวนเงิน</th>
                                                    <td>{!summaryOtherReceive.sum_pay_by_cash || summaryOtherReceive.sum_pay_by_cash === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_pay_by_cash)}</td>
                                                    <td>{!summaryOtherReceive.sum_pay_by_bank || summaryOtherReceive.sum_pay_by_bank === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_pay_by_bank)}</td>
                                                    <td>{!summaryOtherReceive.sum_pay_by_cheque || summaryOtherReceive.sum_pay_by_cheque  === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_pay_by_cheque)}</td>
                                                    <td>{!summaryOtherReceive.sum_pay_by_credit_card || summaryOtherReceive.sum_pay_by_credit_card   === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_pay_by_credit_card)}</td>
                                                    <td>{!summaryOtherReceive.sum_unknown_receive || summaryOtherReceive.sum_unknown_receive  === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_unknown_receive)}</td>
                                                    <td>{!summaryOtherReceive.sum_pay_by_other || summaryOtherReceive.sum_pay_by_other  === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_pay_by_other)}</td>
                                                    <td>{!summaryOtherReceive.sum_other_receive_transaction || summaryOtherReceive.sum_other_receive_transaction  === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_other_receive_transaction)}</td>
                                                    </tr>
                                                </thead>
                                                </table>
                                            </div>}
                                        </div>
                                    </div>

                                    {/* แสดงตาราง */}
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <table className="table tebleBorder">
                                                <thead className='tableHead'>
                                                    <tr>
                                                        <th rowSpan="2" className="text-center">ลำดับ</th>
                                                        <th rowSpan="2" className="text-center" width={130}>เลขที่รายรับอื่นๆ</th>
                                                        <th rowSpan="2" className="text-center">วันที่</th>
                                                        <th rowSpan="2" className="text-center">เลขที่ห้อง/บ้านเลขที่</th>
                                                        <th rowSpan="2" className="text-center width15">ชื่อ</th>
                                                        <th rowSpan="2" className="text-center width15">รายละเอียด</th>
                                                        <th colSpan={6} className="text-center width25">วิธีการชำระเงิน</th>
                                                        <th rowSpan="2" className="text-center">จำนวนเงิน</th>
                                                        <th rowSpan="2" className="text-center">จำนวนเงินรวม</th>
                                                    </tr>
                                                        <tr>
                                                            <th className="text-center width4">เงินสด</th>
                                                            <th className="text-center width4">เงินโอน</th>
                                                            <th className="text-center width4">บัตรเครดิต</th>
                                                            <th className="text-center width4">เช็ค</th>
                                                            <th className="text-center width4">เงินรับรอตรวจสอบ</th>
                                                            <th className="text-center width4">อื่นๆ</th>
                                                        </tr>
                                                </thead>
                                                <tbody className='tableBody'>  
                                                    {data.map((dataRow)=>(
                                                        dataRow
                                                    ))}
                                                </tbody>
                                                <tfoot className="tableFooter">
                                                    {page_index === dataChunked.length - 1 &&
                                                        <tr>
                                                            <td colSpan={6} className="text-center"><strong>รวมทั้งหมด {this.props.props.allOtherReceive.totalCount} รายการ</strong></td>
                                                            <td className="text-right"><strong>{this.numberWithComma(amount_cash)}</strong></td>
                                                            <td className="text-right"><strong>{this.numberWithComma(amount_bank)}</strong></td>
                                                            <td className="text-right"><strong>{this.numberWithComma(amount_credit_card)}</strong></td>
                                                            <td className="text-right"><strong>{this.numberWithComma(amount_cheque)}</strong></td>
                                                            <td className="text-right"><strong>{this.numberWithComma(amount_unknown_receive)}</strong></td>
                                                            <td className="text-right"><strong>{this.numberWithComma(amount_other_payment)}</strong></td>
                                                            <td className="text-right"><strong>{this.numberWithComma(amount_total)}</strong></td>
                                                            <td className="text-right"><strong>{this.numberWithComma(sum_all_total)}</strong></td>
                                                        </tr>              
                                                    }                                                                                 
                                                </tfoot>
                                            </table>
                                        </div>    
                                    </div>    

                                    {/* แสดงเลขหน้า และผู้พิมพ์ */}
                                    <div className="footer">
                                        <div className="row">
                                            <div className="col-5" />
                                            <div className="col-2 text-center">
                                                <p className="f10 pb-0 mb-0">{page_index + 1}/{dataChunked.length}</p>
                                            </div>
                                            <div className="col-5 text-right">
                                                <p className="f10 pb-0 mb-0">
                                                    ผู้พิมพ์ {this.state.printBy} วันที่พิมพ์{format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>   
                    </div>

            ))}
            </React.Fragment>
        )
    }
}

export default OtherReceiveReportPDFRenderTable;