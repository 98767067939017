import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';

import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";

import ContactCreateUpdateForm from "./contactCreateUpdateFormNew";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";

import Loading from "../../libs/loading"

const contactEdit = graphql`
    query contactCreateUpdateQuery($id: ID!) {
        contact(id: $id) {
            id
            refNumber
            name
            taxIdNumber
            firstName
            lastName
            phone
            email
            webSite

            registeredName
            registeredAddress
            registeredCountry
            registeredProvince
            registeredCity
            registeredDistrict
            registeredPostalCode
            mailingName
            mailingAddress
            mailingCountry
            mailingDistrict
            mailingProvince
            mailingCity
            mailingPostalCode

            renterAddress
            renterCountry
            renterProvince
            renterDistrict
            renterCity
            renterPostalCode
            
            agentAddress
            agentCountry
            agentProvince
            agentDistrict
            agentCity
            agentPostalCode


            typeOfSupplier
            totalAmount
            typeOfPayment
            typeOfContact
            position
            nickName
            mainPhone
            mainEmail
            fax
            note
            vat
            typeOfCompany
            nameBranch
            prefixName
            
            typeOfSetting
            settingDate
            typeOfCountry
            typeOfPeople
            settingNumberDate
            
            bankName
            bankBranchNumber
            accountNumber
            accountName
            accountNameEn 
            imageBookBankName 
            imageBookBankUpload 
            imageBookBankShow

            prefixNameContact 
            firstNameContact 
            lastNameContact 
            nickNameContact 
            phoneContact 
            emailContact 
            contactFile{
                edges{
                    node{
                        fileName
                        fileUpload
                        fileShow
                    }
                }
            }

            statusOfCompany
            registeredCapital
            
        }
    }
`;

class ContactCreateUpdate extends Component {

    constructor(props){
        super(props);
        this.state ={
            type_payment_link : this.props.match.params.typeOfPayment && this.props.match.params.page !== 'all' ?  "/"+this.props.match.params.typeOfPayment : ''
        }
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    {
                        this.props.match.params.id ?
                            <QueryRenderer
                                environment={environment}
                                query={contactEdit}
                                cacheConfig={{use_cache: false}}
                                variables={{"id": this.props.match.params.id}}
                                render={({error, props}) => {
                                    if (props) {
                                        return <ContactCreateUpdateForm contact={props.contact}
                                                                        contact_type={props.contact.typeOfContact}
                                                                        type_payment={this.props.match.params.typeOfPayment}
                                                                        page={this.props.match.params.page}
                                                                        backPage={"/accounting/contact/" + this.props.match.params.page + this.state.type_payment_link}
                                                                        edit={true}
                                        />;
                                    } else {
                                        return <Loading/>
                                    }
                                }}
                            /> : <ContactCreateUpdateForm type_payment={this.props.match.params.typeOfPayment}
                                                          page={this.props.match.params.page}
                                                          backPage={"/accounting/contact/" + this.props.match.params.page + this.state.type_payment_link}
                                                          edit={false}/>

                        }
                        </WrapperContent>
            </Wrapper>
        )
    }
}

export default ContactCreateUpdate;
