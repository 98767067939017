import React, { Component } from 'react';
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Wrapper from "../../../components/wrapper";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from "i18next"
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import numberWithComma from "../../../libs/numberWithComma";
import "./css/unknownReceiveDetail.scss";
import { commitMutation, QueryRenderer } from "react-relay";
import SelectContactDropDown from "../selectContactDropDown";
import SearchSelect from "../../../libs/searchSelect";
import { fetchQuery } from "relay-runtime";
import Swal from "sweetalert2";
import { Redirect } from 'react-router'
import UnknownReceiveVoid from "./unknownReceiveVoid";
import { formatDateISO } from "../../../libs/date"
import Loading from '../../../libs/loading';
import SelectRoleAccounting from '../../../components/FucntionLib/selectRoleAccounting';
import getNameResidential from '../../../libs/getNameResidential';
import i18next from 'i18next';
import accounting from '../../../api/accounting';
import LightBox from '../../../libs/lightBox';
import queryCheckHouseHolderPaygroup from "../query/queryCheckHouseHolderPaygroup"

const query = graphql`
    query unknownReceiveDetailQuery($id: ID!) {
        unknownReceive(id: $id){
            id
            issuedDate
            docNumber
            description
            refNumber
            amount
            status
            receive {
                id
                docNumber
                issuedDate
                total
                firstName lastName
                payGroup
            }
            otherReceive {
                id
                docNumber
                issuedDate
                total
                firstName lastName
                payGroup
            }
            receiptDeposit{
                edges{
                    node{
                        id
                        docNumber
                        total
                        issuedDate
                        firstName lastName
                        payGroup
                    }
                }
            }
            guaranteeMoneyReceived {
                id
                docNumber                        
                issuedDate
                firstName lastName
                payGroup
                amount
            }
            # รับล่วงหน้าด้วยรับรอตรวจสอบ
            receiptDepositUsed {
                id
                docNumber                        
                issuedDate
                firstName lastName
                payGroup
                total
            }
            chartOfAccount{
                chartOfAccountCode
                name
            }
            receiveChannel
            bankAccount{
                bankName
                accountNumber
                accountType
            }
            contact{
                id
                name
                refNumber
            }
            bankAccountTransaction {
                edges{
                    node {
                        id
                        imageSlip
                    }
                }
            }
        }
        viewer {
            allChartOfAccount(chartOfAccountCode_Istartswith: "2210") {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation unknownReceiveDetailMutation($input: UpdateUnknownReceiveContactInput!){
        updateUnknownReceiveContact(input:$input){
            ok
            warningText
        }
    }`;

const _ = require('lodash');

class UnknownReceiveDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading: false,
            contact: '',
            receipt_deposit: [],
            select_receipt_deposit: '',
            redirectToList: '',
            issued_date: new Date(),
            roleShow: false,
            pay_group: "owner",
            name: "",
            nameObject: {},
            fileUpload: [],
            fileName: '',
            upload: {},
            editImage: false,
        };
        this.onChangeContact = this.onChangeContact.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillMount() {
        this.queryReceiptDeposit()
    }

    queryReceiptDeposit() {
        fetchQuery(environment, query, { "id": this.props.match.params.id })
            .then(response_data => {
                let contact = '';
                if (_.isObject(response_data.unknownReceive.contact)) {
                    contact = response_data.unknownReceive.contact.id;
                }
                this.setState(
                    {
                        receipt_deposit: response_data.viewer.allChartOfAccount.edges,
                        contact: contact,
                    })
            });
    }

    onChangeContact(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let variables = {
            input: {
                clientMutationId: this.props.match.params.id,
                contact: this.state.contact,
                receiptDepositId: this.state.select_receipt_deposit,
                issuedDate: formatDateISO(this.state.issued_date),
                payGroup: this.state.pay_group
            }
        };
       
      fetchQuery(environment, queryCheckHouseHolderPaygroup, {
        contactId: this.state.contact,
        payGroup: this.state.pay_group
       }).then((data) => {
       let checkHouseHolderPaygroup = JSON.parse(data.checkHouseHolderPaygroup)
       if(checkHouseHolderPaygroup.ok){
         // create unknowReceiveDetail
         commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response, errors) => {
                this.setState({ loading: false });
                if (response.updateUnknownReceiveContact.ok) {
                    Swal.fire(i18n.t("unknown_receive:Save successfully"), '', 'success').then(() => {
                        this.setState({ redirectToList: true });
                    });
                } else {
                    Swal.fire(i18n.t("unknown_receive:Save unsuccessfully"), response.updateUnknownReceiveContact.warningText, 'warning');
                    // Swal.fire(i18n.t("unknown_receive:Save unsuccessfully"), i18n.t("unknown_receive:Please check again"), 'warning');
                }
            },
            onError: error => {
                alert('server error')
            }
        });

       }else if(checkHouseHolderPaygroup.ok === false && this.state.pay_group === 'owner' ){
         Swal.fire({
        //    title: "ออกได้แต่ชื่อจะเป็นค่าช่องว่าง",
           type: 'warning',
           showCancelButton: true,
           text:checkHouseHolderPaygroup.message,
           confirmButtonColor: '#3085d6',
           cancelButtonColor: '#d33',
           confirmButtonText: i18n.t("Allaction:Yes"),
           cancelButtonText: i18n.t("Allaction:cancel")
         }).then((result) => {
             if (result.value) {
                // create unknowReceiveDetail
                commitMutation(environment, {
                    mutation,
                    variables,
                    onCompleted: (response, errors) => {
                        this.setState({ loading: false });
                        if (response.updateUnknownReceiveContact.ok) {
                            Swal.fire(i18n.t("unknown_receive:Save successfully"), '', 'success').then(() => {
                                this.setState({ redirectToList: true });
                            });
                        } else {
                            Swal.fire(i18n.t("unknown_receive:Save unsuccessfully"), response.updateUnknownReceiveContact.warningText, 'warning');
                            // Swal.fire(i18n.t("unknown_receive:Save unsuccessfully"), i18n.t("unknown_receive:Please check again"), 'warning');
                        }
                    },
                    onError: error => {
                        alert('server error')
                    }
                });
    
             }else{
               this.setState({ loading: false });
             }
         })
       }else{
            Swal.fire({
            title: "บันทึกไม่สำเร็จ",
            type: 'warning',
            text:checkHouseHolderPaygroup.message,
            showCancelButton: true,
            showConfirmButton:false,
            cancelButtonColor: '#d33',
            cancelButtonText: 'ปิด', })
            this.setState({ loading: false });
        }
        })
    }

    handleInputChangeContact = (e) => {
        let event = _.cloneDeep(e);
        let value = event.target.value;

        this.setState({ roleShow: event.target.role, nameObject: event.target.nameObject?.node })
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });
    }



    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });
    }

    getData = (pay_group) => {
        // let name = "";
        // if (pay_group === "agent") {
        //     name = this.props.contact.agentName 
        // } else if (pay_group === "rent") {
        //     name = this.props.contact.renterName 
        // } else {
        //     name = this.props.contact.ownerName 
        // }

        this.setState({ pay_group: pay_group })
    }

    uploadFile = (e) => {
        let data = _.cloneDeep(e);
        const file = data.target.files[0];

        if (file.size <= 10 * 1024 * 1024) {
            this.setState({ fileUpload: file, fileName: file.name, editImage: true });
        } else if (file.size > 10 * 1024 * 1024) {
            Swal.fire('Error!', i18n.t("unknown_receive:File size exceeds the limit of 10 MB. Please choose a smaller file"), 'warning')
            data.target.value = null;
        } else {
            Swal.fire("Error!", i18n.t('createReceive:Please try again'), "warning");
        }
    }

    saveSlipPayment = (event, id) => {
        event.preventDefault();

        let imageSlip = this.state.fileUpload

        let dataForm = new FormData()

        dataForm.append('query', 'mutation updateBankAccountTransaction($input:UpdateBankAccountTransactionInput!){\n   updateBankAccountTransaction(input:$input)          {\n      ok\n      message\n    }\n}');
        dataForm.append('variables', '{"input":{"bankAccountTransactionId":"' + id + '"\n    }\n}');
        dataForm.append('imageSlip', imageSlip);

        accounting.income.editSlipPayMent(dataForm).then((res) => {
            Swal.fire({
                type: 'success',
                title: i18n.t("Allaction:Saved Successfully"),
                text: i18n.t("Allaction:successcompleate"),
            }).then((ress) => {
                window.location.reload()
            })
        })
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/income/unknown-receive/list/all" />
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <div id="wrapper-content">
                    <AccountingTopMenuNavigation mini={true} />
                    <div className="container-fluid box" id="unknown-detail">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/income/unknown-receive/list/all">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('accounting:unknown receive')+" "+"(UR)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                            <div className="col text-right">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_unknown_receive_delete' }) &&

                                    <UnknownReceiveVoid id={this.props.match.params.id}
                                        redirectToList={() => this.setState({ redirectToList: true })} />
                                }
                                {/*<button className="btn btn-primary add" disabled={this.state.loading}>*/}
                                {/*    พิมพ์ใบเงินรับรอการตรวจสอบ*/}
                                {/*</button>*/}
                            </div>
                        </div>
                        <QueryRenderer
                            environment={environment}
                            query={query}
                            cacheConfig={{ use_cache: false }}
                            variables={{ "id": this.props.match.params.id }}
                            render={({ error, props }) => {
                                if (props) {
                                    return <React.Fragment>
                                        <div className="content-inner pt-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <form id="form-add" onSubmit={this.onChangeContact}>
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <Translation>{t => <strong>{t("unknown_receive:No.")}</strong>}</Translation>
                                                            </div>
                                                            <div className="col-5">
                                                                <p>{props.unknownReceive.docNumber}</p>
                                                            </div>
                                                            <div className="col-1" />
                                                        </div>

                                                        <div className="row mt-3">
                                                            <div className="col-2">
                                                                <Translation>{t => <strong>{t("unknown_receive:Date")}</strong>}</Translation>
                                                            </div>
                                                            <div className="col-5">
                                                                <div className="input-group">
                                                                    <p>{format(props.unknownReceive.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-1" />
                                                        </div>

                                                        <div className="row mt-3">
                                                            <div className="col-2">
                                                                <Translation>{t => <strong>{t("unknown_receive:Description")}</strong>}</Translation>
                                                            </div>
                                                            <div className="col-5">
                                                                <p>{props.unknownReceive.description}</p>
                                                            </div>
                                                            <div className="col-1" />
                                                        </div>

                                                        <div className="row mt-3">
                                                            <div className="col-2">
                                                                <Translation>{t => <strong>{t("unknown_receive:List")}</strong>}</Translation>
                                                            </div>
                                                            <div className="col-5 text-left">
                                                                <p>{props.unknownReceive.chartOfAccount.chartOfAccountCode} {props.unknownReceive.chartOfAccount.name}</p>
                                                            </div>
                                                            <div className="col-1" />
                                                        </div>

                                                        <div className="row mt-3">
                                                            <div className="col-2">
                                                                <Translation>{t => <strong>{t("unknown_receive:Payment method")}</strong>}</Translation>
                                                            </div>
                                                            {props.unknownReceive.bankAccount ?
                                                                <div className="col-5 text-left">
                                                                    <p>{props.unknownReceive.bankAccount.bankName} {props.unknownReceive.bankAccount.accountType === "SAVING_ACCOUNT" ? "ออมทรัพย์" : props.unknownReceive.bankAccount.accountType === "CURRENT_ACCOUNT" ? "กระแสรายวัน" : "ฝากประจำ"} {props.unknownReceive.bankAccount.accountNumber}</p>
                                                                </div>
                                                                :
                                                                <div className="col-5 text-left">
                                                                    <p>{props.unknownReceive.receiveChannel}</p>
                                                                </div>
                                                            }
                                                            <div className="col-1" />
                                                        </div>


                                                        <div className="row mt-3">
                                                            <div className="col-2">
                                                                <Translation>{t => <strong>{t("unknown_receive:Amount")}</strong>}</Translation>
                                                            </div>
                                                            <div className="col-5">
                                                                <p>{numberWithComma(props.unknownReceive.amount)}</p>
                                                            </div>
                                                            <div className="col-1" />
                                                        </div>
                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_unknown_receive_edit' }) &&
                                                            <React.Fragment>
                                                                <div className="row mt-3">
                                                                    <div className="col-2">
                                                                        <Translation>{t => <strong>{t("unknown_receive:Co-owner/member")}</strong>}</Translation>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {props.unknownReceive.contact && props.unknownReceive.receiptDeposit.edges[0]?.node ?
                                                                            <p>
                                                                                {props.unknownReceive.contact.name} {props.unknownReceive.contact.refNumber}
                                                                                {` ` + getNameResidential(props.unknownReceive.receiptDeposit.edges[0].node.firstName, props.unknownReceive.receiptDeposit.edges[0].node.lastName)}
                                                                                {props.unknownReceive.receiptDeposit.edges[0].node.payGroup === "RENT" || props.unknownReceive.receiptDeposit.edges[0].node.payGroup === "AGENT" &&
                                                                                    ` (${i18next.t("AgentRole:" + props.unknownReceive.receiptDeposit.edges[0].node.payGroup)})`
                                                                                }
                                                                            </p> 
                                                                            : props.unknownReceive.contact && props.unknownReceive.receive ?
                                                                            <p>
                                                                                {props.unknownReceive.contact.name} {props.unknownReceive.contact.refNumber}
                                                                                {` ` + getNameResidential(props.unknownReceive.receive.firstName, props.unknownReceive.receive.lastName)}
                                                                                {props.unknownReceive.receive.payGroup === "RENT" || props.unknownReceive.receive.payGroup === "AGENT" &&
                                                                                    ` (${i18next.t("AgentRole:" + props.unknownReceive.receive.payGroup)})`
                                                                                }
                                                                            </p> 
                                                                            : props.unknownReceive.contact && props.unknownReceive.otherReceive ?
                                                                            <p>
                                                                                {props.unknownReceive.contact.name} {props.unknownReceive.contact.refNumber}
                                                                                {` ` + getNameResidential(props.unknownReceive.otherReceive.firstName, props.unknownReceive.otherReceive.lastName)}
                                                                                {props.unknownReceive.otherReceive.payGroup === "RENT" || props.unknownReceive.otherReceive.payGroup === "AGENT" &&
                                                                                    ` (${i18next.t("AgentRole:" + props.unknownReceive.otherReceive.payGroup)})`
                                                                                }
                                                                            </p>
                                                                            : props.unknownReceive.contact && props.unknownReceive.guaranteeMoneyReceived ?
                                                                            <p>
                                                                                {props.unknownReceive.contact.name} {props.unknownReceive.contact.refNumber}
                                                                                {` ` + getNameResidential(props.unknownReceive.guaranteeMoneyReceived.firstName, props.unknownReceive.guaranteeMoneyReceived.lastName)}
                                                                                {props.unknownReceive.guaranteeMoneyReceived.payGroup === "RENT" || props.unknownReceive.guaranteeMoneyReceived.payGroup === "AGENT" &&
                                                                                    ` (${i18next.t("AgentRole:" + props.unknownReceive.guaranteeMoneyReceived.payGroup)})`
                                                                                }
                                                                            </p> 
                                                                            : props.unknownReceive.contact && props.unknownReceive.receiptDepositUsed ?
                                                                            <p>
                                                                                {props.unknownReceive.contact.name} {props.unknownReceive.contact.refNumber}
                                                                                {` ` + getNameResidential(props.unknownReceive.receiptDepositUsed.firstName, props.unknownReceive.receiptDepositUsed.lastName)}
                                                                                {props.unknownReceive.receiptDepositUsed.payGroup === "RENT" || props.unknownReceive.receiptDepositUsed.payGroup === "AGENT" &&
                                                                                    ` (${i18next.t("AgentRole:" + props.unknownReceive.receiptDepositUsed.payGroup)})`
                                                                                }
                                                                            </p> :
                                                                            <SelectContactDropDown
                                                                                contact_type='all' require={true}
                                                                                handleInputChange={this.handleInputChangeContact}
                                                                                name="contact"
                                                                                payment_type="receivable"
                                                                                contact={this.state.contact}
                                                                                contact_key={true}
                                                                                role={true}
                                                                                get_only_name={true}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    {/* {console.log(this.state.contact)} */}
                                                                    {
                                                                        this.state.roleShow &&
                                                                        <SelectRoleAccounting
                                                                            useDiffentDesign={true}
                                                                            handleChangeSelect={(value) => this.getData(value)}
                                                                            value={this.state.pay_group}
                                                                        />
                                                                    }
                                                                    {
                                                                        this.state.roleShow &&
                                                                        <div className='col'>
                                                                            <input type={'text'}
                                                                                className='form-control'
                                                                                disabled
                                                                                value={
                                                                                    this.state.pay_group === "owner" ?
                                                                                        this.state.nameObject?.ownerName :
                                                                                        this.state.pay_group === "rent" ?
                                                                                            this.state.nameObject?.renterName :
                                                                                            this.state.nameObject?.agentName
                                                                                } />
                                                                        </div>
                                                                    }

                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-2">
                                                                        <Translation>{t => <strong>{props.unknownReceive.contact ? <>{t("unknown_receive:Reference document No.")}</> : <>{t("unknown_receive:Select the Receipt deposit")}</>}</strong>}</Translation>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {props.unknownReceive.contact ?
                                                                            <React.Fragment>
                                                                                {
                                                                                    props.unknownReceive.receive &&
                                                                                    <p>{props.unknownReceive.receive.docNumber} วันที่ {format(props.unknownReceive.receive.issuedDate, 'DD/MM/YYYY', { locale: thLocale })} จำนวนเงิน {numberWithComma(props.unknownReceive.receive.total)}</p>
                                                                                }
                                                                                {
                                                                                    props.unknownReceive.otherReceive &&
                                                                                    <p>{props.unknownReceive.otherReceive.docNumber} วันที่ {format(props.unknownReceive.otherReceive.issuedDate, 'DD/MM/YYYY', { locale: thLocale })} จำนวนเงิน {numberWithComma(props.unknownReceive.otherReceive.total)}</p>
                                                                                }
                                                                                {
                                                                                    props.unknownReceive.guaranteeMoneyReceived &&
                                                                                    <p>{props.unknownReceive.guaranteeMoneyReceived.docNumber} วันที่ {format(props.unknownReceive.guaranteeMoneyReceived.issuedDate, 'DD/MM/YYYY', { locale: thLocale })} จำนวนเงิน {numberWithComma(props.unknownReceive.guaranteeMoneyReceived.amount)}</p>
                                                                                }
                                                                                {
                                                                                    props.unknownReceive.receiptDepositUsed &&
                                                                                    <p>{props.unknownReceive.receiptDepositUsed.docNumber} วันที่ {format(props.unknownReceive.receiptDepositUsed.issuedDate, 'DD/MM/YYYY', { locale: thLocale })} จำนวนเงิน {numberWithComma(props.unknownReceive.receiptDepositUsed.amount)}</p>
                                                                                }

                                                                                {/* {
                                                                                props.unknownReceive.receive && !props.unknownReceive.receiptDeposit.edges.length &&
                                                                                <p>
                                                                                    <strong>หมายเหตุ</strong> รายได้จากส่วนต่างเศษสตางค์ {numberWithComma(props.unknownReceive.amount - props.unknownReceive.receive.total)}
                                                                                </p>
                                                                            } */}



                                                                                {
                                                                                    props.unknownReceive.receiptDeposit.edges.length > 0 &&
                                                                                    <p>{props.unknownReceive.receiptDeposit.edges[0].node.docNumber} วันที่ {format(props.unknownReceive.receiptDeposit.edges[0].node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })} จำนวนเงิน {numberWithComma(props.unknownReceive.receiptDeposit.edges[0].node.total)}</p>
                                                                                }
                                                                            </React.Fragment>
                                                                            :
                                                                            <SearchSelect onChange={this.handleInputChange}
                                                                                value={this.state.select_receipt_deposit}
                                                                                name={"select_receipt_deposit"}
                                                                                placeholder="รายการ"
                                                                                queryObject={this.state.receipt_deposit}
                                                                                keyOfValue="id" require={true}
                                                                                keyOfLabel="chartOfAccountCode:name"
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-3 ml-1'>
                                                                    <div className='col-2'></div>
                                                                    <div className='d-flex flex-row align-items-center'>
                                                                        <Translation>{t => <label htmlFor="uploadFile" className="btn btn-primary add mr-4 mb-0">{t("unknown_receive:Upload receipt")}</label>}</Translation>
                                                                        <input type="file" name='file' id="uploadFile"
                                                                            style={{ display: 'none' }}
                                                                            className='form-control uploadFile'
                                                                            placeholder='uploadFile'
                                                                            accept="image/png, image/jpeg"
                                                                            onChange={this.uploadFile} />
                                                                        {this.state.editImage && <Translation>{t => <button className="btn btn-primary add mr-2" onClick={(event) => this.saveSlipPayment(event, props.unknownReceive.bankAccountTransaction.edges[0].node.id)}>{t("unknown_receive:Save")}</button>}</Translation>}
                                                                        {this.state.editImage && <Translation>{t => <button className="btn btn-secondary add" onClick={() => this.setState({ editImage: false, fileUpload: "" })}>{t("unknown_receive:Cancel")}</button>}</Translation>}
                                                                        {!this.state.editImage && (
                                                                            <Translation>
                                                                                {t => (
                                                                                    <span className='text-red'>{t("unknown_receive:Attach files (JPG PNG, size not exceeding 10 MB)")}</span>
                                                                                )}
                                                                            </Translation>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-3 ml-1'>
                                                                    <div className='col-2'></div>
                                                                    {this.state.fileUpload?.name && <p>{this.state.fileUpload.name}</p>}
                                                                </div>
                                                                <div className='row mt-1 ml-1'>
                                                                    <div className='col-2'></div>
                                                                    {
                                                                        props.unknownReceive.bankAccountTransaction.edges[0]?.node.imageSlip &&
                                                                        <LightBox image={props.unknownReceive.bankAccountTransaction.edges[0].node.imageSlip} />
                                                                    }
                                                                </div>
                                                                
                                                                {
                                                                    props.unknownReceive.status === 'WAIT' &&
                                                                    <div className="row mt-3">
                                                                        <div className="col text-right">
                                                                            <Link
                                                                                to="/accounting/income/unknown-receive/list/all">
                                                                                <Translation>
                                                                                    {t =>
                                                                                        <button type="button"
                                                                                            className="btn btn-secondary add mr-3"
                                                                                            disabled={this.state.loading}>
                                                                                            {this.state.loading &&
                                                                                                <span
                                                                                                    className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                                                            {t("unknown_receive:Cancel")}
                                                                                        </button>}
                                                                                </Translation>
                                                                            </Link>


                                                                            <Translation>
                                                                                {t =>
                                                                                    <button type="submit"
                                                                                        className="btn btn-primary add"
                                                                                        disabled={this.state.loading}>
                                                                                        {this.state.loading &&
                                                                                            <span
                                                                                                className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                                                        {t("unknown_receive:Save")}
                                                                                    </button>}
                                                                            </Translation>


                                                                        </div>
                                                                    </div>
                                                                }

                                                            </React.Fragment>
                                                        }


                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </React.Fragment>
                                } else {
                                    return <Loading />
                                }
                            }}
                        />

                    </div>
                </div>
            </Wrapper>
        )
    }
}

export default UnknownReceiveDetail;