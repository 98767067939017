/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type receiptDepositContactEditFormQueryVariables = {|
  id: string
|};
export type receiptDepositContactEditFormQueryResponse = {|
  +receiptDeposit: ?{|
    +id: string,
    +issuedDate: any,
    +contact: {|
      +id: string,
      +refNumber: string,
      +name: string,
      +firstName: string,
      +lastName: string,
      +registeredAddress: string,
      +registeredCountry: string,
      +registeredProvince: string,
      +registeredDistrict: string,
      +registeredCity: string,
      +registeredPostalCode: string,
      +typeOfContact: ContactTypeOfContact,
    |},
    +docNumber: string,
    +remark: string,
    +total: ?number,
    +receiptDepositTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +chartOfAccount: {|
            +id: string,
            +chartOfAccountCode: string,
            +name: string,
          |},
          +description: string,
          +price: ?number,
        |}
      |}>
    |},
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |}
      |}>
    |}
  |},
|};
export type receiptDepositContactEditFormQuery = {|
  variables: receiptDepositContactEditFormQueryVariables,
  response: receiptDepositContactEditFormQueryResponse,
|};
*/


/*
query receiptDepositContactEditFormQuery(
  $id: ID!
) {
  receiptDeposit(id: $id) {
    id
    issuedDate
    contact {
      id
      refNumber
      name
      firstName
      lastName
      registeredAddress
      registeredCountry
      registeredProvince
      registeredDistrict
      registeredCity
      registeredPostalCode
      typeOfContact
    }
    docNumber
    remark
    total
    receiptDepositTransaction {
      edges {
        node {
          id
          chartOfAccount {
            id
            chartOfAccountCode
            name
          }
          description
          price
        }
      }
    }
  }
  viewer {
    allChartOfAccount(chartOfAccountCode_Istartswith: "2123") {
      edges {
        node {
          id
          name
          chartOfAccountCode
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "ReceiptDepositNode",
  "kind": "LinkedField",
  "name": "receiptDeposit",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactNode",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "refNumber",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registeredAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registeredCountry",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registeredProvince",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registeredDistrict",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registeredCity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registeredPostalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "typeOfContact",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "docNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remark",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReceiptDepositTransactionNodeConnection",
      "kind": "LinkedField",
      "name": "receiptDepositTransaction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReceiptDepositTransactionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReceiptDepositTransactionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChartOfAccountNode",
                  "kind": "LinkedField",
                  "name": "chartOfAccount",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "chartOfAccountCode_Istartswith",
      "value": "2123"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allChartOfAccount(chartOfAccountCode_Istartswith:\"2123\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "receiptDepositContactEditFormQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "receiptDepositContactEditFormQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8a81c953a84794c29912b04455027d23",
    "id": null,
    "metadata": {},
    "name": "receiptDepositContactEditFormQuery",
    "operationKind": "query",
    "text": "query receiptDepositContactEditFormQuery(\n  $id: ID!\n) {\n  receiptDeposit(id: $id) {\n    id\n    issuedDate\n    contact {\n      id\n      refNumber\n      name\n      firstName\n      lastName\n      registeredAddress\n      registeredCountry\n      registeredProvince\n      registeredDistrict\n      registeredCity\n      registeredPostalCode\n      typeOfContact\n    }\n    docNumber\n    remark\n    total\n    receiptDepositTransaction {\n      edges {\n        node {\n          id\n          chartOfAccount {\n            id\n            chartOfAccountCode\n            name\n          }\n          description\n          price\n        }\n      }\n    }\n  }\n  viewer {\n    allChartOfAccount(chartOfAccountCode_Istartswith: \"2123\") {\n      edges {\n        node {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a07e261b4f592d3ec113b17f85a997b7';

module.exports = node;
