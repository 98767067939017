import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import {commitMutation} from 'react-relay'
import environment from "../../env/environment";
import i18next from 'i18next';
import { Translation } from 'react-i18next';

const mutation = graphql`
    mutation deleteSurveyMutation($input: DeleteSurveyInput!){
        deleteSurvey(input:$input){
            ok
        }
    }`;

class SurveyDelete extends Component {
    constructor(props) {
        super(props);
        this.onDeleteItem = this.onDeleteItem.bind(this)
    }

    onDeleteItem() {
        if (!(this.props.survey.id === "" && this.props.survey.id === undefined)) {
            this.mutationDelete()
        }
    }

    mutationDelete() {
        Swal.fire({
            title: i18next.t('survey:want_to_remove'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t('survey:yes'),
            cancelButtonText: i18next.t('survey:cancel')
        }).then((result) => {
            if (result.value) {
                const variables = {
                    input: {
                        idList: [this.props.survey.id]
                    }
                };

                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if (response.deleteSurvey.ok) {
                                Swal.fire(i18next.t('survey:remove_success'), '', 'success').then(() => this.props.reQuerySurvey());
                            } else {
                                Swal.fire(i18next.t('survey:remove_fail'), '', 'warning');
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18next.t('survey:try_again'), 'warning')
                        },
                    },
                )

            }
        })
    }

    render() {
        return (
            <Translation>{t=>
            <label className="cursor text-decoration-none"
            onClick={this.onDeleteItem}>
                <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                     alt="delete-icon" className="show-icon"
                     />
                <span className="grey-color ml-1 show-icon">{t('survey:delete')}</span>
            </label>
                }
            </Translation>
        )
    }
}

export default SurveyDelete;
