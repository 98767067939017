import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Translation } from 'react-i18next';

class Navigation extends Component {
    
    render() {
        return (
            <Translation>
                {t =>
                    <div className="row" id="navigation-tab">
                        <div className="col">
                            <ul>
                                <li>
                                    <NavLink to={"/contact/fix-requests/report/forcommon/all"}>
                                        {t("FixRequestReport:All")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/contact/fix-requests/report/forcommon/warranty"}>
                                        {t("FixRequestReport:Insured")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/contact/fix-requests/report/forcommon/expired"}>
                                        {t("FixRequestReport:Out of insurance")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/contact/fix-requests/report/forcommon/nowarranty"}>
                                        {t("FixRequestReport:No insurance")}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>}
            </Translation>
        )
    }
}

export default Navigation;