/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type PaymentGatewayPaymentType = "CROSSBILL" | "DEEPLINK" | "ETC" | "KSHER" | "SLIP_VERIFICATION" | "TWOCTWOP" | "%future added value";
export type PaymentGatewayTypeCal = "MONNEY_FAIT" | "PERCENT" | "%future added value";
export type settingPaymentgatewayQueryVariables = {||};
export type settingPaymentgatewayQueryResponse = {|
  +paymentGatewaySettingList: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +paymentType: PaymentGatewayPaymentType,
        +fee: number,
        +typeCal: PaymentGatewayTypeCal,
        +bankAccount: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
        |},
      |}
    |}>
  |},
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
        |}
      |}>,
      +totalCount: ?number,
    |}
  |},
  +crossBilling: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
          +hasCheque: boolean,
          +branch: string,
        |}
      |}>,
      +totalCount: ?number,
    |}
  |},
  +deeplink: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
          +hasCheque: boolean,
          +branch: string,
        |}
      |}>,
      +totalCount: ?number,
    |}
  |},
|};
export type settingPaymentgatewayQuery = {|
  variables: settingPaymentgatewayQueryVariables,
  response: settingPaymentgatewayQueryResponse,
|};
*/


/*
query settingPaymentgatewayQuery {
  paymentGatewaySettingList {
    edges {
      node {
        id
        paymentType
        fee
        typeCal
        bankAccount {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
        }
      }
    }
  }
  bankAccountViewer {
    allBankAccount(status: true, accountType_In: "current_account,saving_account,saving_deposit") {
      edges {
        node {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
        }
      }
      totalCount
    }
    id
  }
  crossBilling: bankAccountViewer {
    allBankAccount(status: true, crossBilling: true) {
      edges {
        node {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
          hasCheque
          branch
        }
      }
      totalCount
    }
    id
  }
  deeplink: bankAccountViewer {
    allBankAccount(status: true, accountType_In: "current_account,saving_account,saving_deposit", bankName_Icontains: "ธนาคารไทยพาณิชย์") {
      edges {
        node {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
          hasCheque
          branch
        }
      }
      totalCount
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivePayment",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "makePayment",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentGatewayNodeConnection",
  "kind": "LinkedField",
  "name": "paymentGatewaySettingList",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentGatewayNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentGatewayNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paymentType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "typeCal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "kind": "LinkedField",
              "name": "bankAccount",
              "plural": false,
              "selections": (v9/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "kind": "Literal",
  "name": "accountType_In",
  "value": "current_account,saving_account,saving_deposit"
},
v12 = {
  "kind": "Literal",
  "name": "status",
  "value": true
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": [
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v9/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v13/*: any*/)
  ],
  "storageKey": "allBankAccount(accountType_In:\"current_account,saving_account,saving_deposit\",status:true)"
},
v15 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BankAccountNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasCheque",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "branch",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v13/*: any*/)
],
v16 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "crossBilling",
      "value": true
    },
    (v12/*: any*/)
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": "allBankAccount(crossBilling:true,status:true)"
},
v17 = {
  "alias": null,
  "args": [
    (v11/*: any*/),
    {
      "kind": "Literal",
      "name": "bankName_Icontains",
      "value": "ธนาคารไทยพาณิชย์"
    },
    (v12/*: any*/)
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": "allBankAccount(accountType_In:\"current_account,saving_account,saving_deposit\",bankName_Icontains:\"ธนาคารไทยพาณิชย์\",status:true)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingPaymentgatewayQuery",
    "selections": [
      (v10/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v14/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "crossBilling",
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v16/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "deeplink",
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingPaymentgatewayQuery",
    "selections": [
      (v10/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "crossBilling",
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "deeplink",
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v17/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40b36668219167ab2a05e43dcb4b0600",
    "id": null,
    "metadata": {},
    "name": "settingPaymentgatewayQuery",
    "operationKind": "query",
    "text": "query settingPaymentgatewayQuery {\n  paymentGatewaySettingList {\n    edges {\n      node {\n        id\n        paymentType\n        fee\n        typeCal\n        bankAccount {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n        }\n      }\n    }\n  }\n  bankAccountViewer {\n    allBankAccount(status: true, accountType_In: \"current_account,saving_account,saving_deposit\") {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n        }\n      }\n      totalCount\n    }\n    id\n  }\n  crossBilling: bankAccountViewer {\n    allBankAccount(status: true, crossBilling: true) {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n          hasCheque\n          branch\n        }\n      }\n      totalCount\n    }\n    id\n  }\n  deeplink: bankAccountViewer {\n    allBankAccount(status: true, accountType_In: \"current_account,saving_account,saving_deposit\", bankName_Icontains: \"ธนาคารไทยพาณิชย์\") {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n          hasCheque\n          branch\n        }\n      }\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aeb00745f92b0aacc9ef72f76fad6e55';

module.exports = node;
