import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import GuaranteeMoneyReceived from './guaranteeMoneyReceived';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperClearGuaranteeMoneyReceivedQuery($id: ID!){
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        clearGuaranteeMoneyReceived(id: $id){
            id
            docNumber
            issuedDate
            forfeitRemark
            firstName
            lastName
            name
            taxIdNumber
            phone
            email
            address
            country
            province
            district
            city
            postalCode
            payGroup
            contact{
                typeOfContact
                name
                refNumber
                firstName
                lastName
                registeredName
                registeredAddress
                registeredCountry
                registeredProvince
                registeredCity
                registeredDistrict
                registeredPostalCode
                typeOfContact
                residential{
                    size
                    ownershipRatio
                }
            }
            guarantee{
                docNumber
                description
                firstName
                lastName
                contact{
                    name
                    refNumber
                    firstName
                    lastName
                    registeredName
                    registeredAddress
                    registeredCountry
                    registeredProvince
                    registeredCity
                    registeredDistrict
                    registeredPostalCode
                    typeOfContact
                    residential{
                        size
                        ownershipRatio
                    }
                }
                amount
                chartOfAccount{
                    id
                    chartOfAccountCode
                    name
                }
                note
            }
            guaranteeTransaction{
                totalCount
                edges{
                    node{
                        id
                        amount
                        typeOfTransaction
                    }
                }
            }
            cashTransaction{
               edges{
                    node{
                        id
                        price
                    }
                } 
            }
            bankAccountTransaction{
                edges {
                    node {
                        id
                        price
                        date
                        imageSlip
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            chequeTransaction{
                edges{
                    node{
                        id
                        date
                        price
                        chequeNumber
                        bankAccount{
                            id
                            accountName
                            branch
                            bankName
                        }
                    }
                }
            }
            clearGuaranteeMoneyReceivedPettyCashChannel{
                edges{
                    node{
                        id
                        price
                        date
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }

                    }
                }
            }
        }
        userConfig {
            id 
            languages
        }
    }
`;

class WrapperClearGuaranteeMoneyReceived extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
    }

    print() {
        window.print();
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Guarantee Money Received</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{
                        id: this.props.match.params.id,
                    }}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return <GuaranteeMoneyReceived guarantee_type={"clear_guarantee"}
                                                           selfProject={props.selfProject}
                                                           detail={props.clearGuaranteeMoneyReceived}
                                                           sub_detail={props.clearGuaranteeMoneyReceived.guarantee}
                                                           contact={props.clearGuaranteeMoneyReceived.contact? props.clearGuaranteeMoneyReceived.contact : props.clearGuaranteeMoneyReceived.guarantee.contact}
                                                           bank_transaction={props.clearGuaranteeMoneyReceived.bankAccountTransaction.edges}
                                                           cash_transaction={props.clearGuaranteeMoneyReceived.cashTransaction.edges}
                                                           cheque_transaction={props.clearGuaranteeMoneyReceived.chequeTransaction.edges}
                                                           clear_guarantee_money_received_petty_cash_channel={props.clearGuaranteeMoneyReceived.clearGuaranteeMoneyReceivedPettyCashChannel.edges}
                                                           page='WrapperClearGuaranteeMoneyReceived'
                                                           languages={props.userConfig.languages}
                                                           editClearGuarantee={this.props.location.state}
                            />
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperClearGuaranteeMoneyReceived;
