/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateupdateAutoReplayMessagesInput = {|
  autoReplay: boolean,
  text: string,
  timeStart: any,
  timeEnd: any,
  monday: boolean,
  tuesday: boolean,
  wednesday: boolean,
  thursday: boolean,
  friday: boolean,
  saturday: boolean,
  sunday: boolean,
  clientMutationId?: ?string,
|};
export type settingAutoMessageFormMutationVariables = {|
  input: CreateupdateAutoReplayMessagesInput
|};
export type settingAutoMessageFormMutationResponse = {|
  +createupdateAutoReplayMessages: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type settingAutoMessageFormMutation = {|
  variables: settingAutoMessageFormMutationVariables,
  response: settingAutoMessageFormMutationResponse,
|};
*/


/*
mutation settingAutoMessageFormMutation(
  $input: CreateupdateAutoReplayMessagesInput!
) {
  createupdateAutoReplayMessages(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateupdateAutoReplayMessagesPayload",
    "kind": "LinkedField",
    "name": "createupdateAutoReplayMessages",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingAutoMessageFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingAutoMessageFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b030df71bffad9910aa29aa035a5bf1f",
    "id": null,
    "metadata": {},
    "name": "settingAutoMessageFormMutation",
    "operationKind": "mutation",
    "text": "mutation settingAutoMessageFormMutation(\n  $input: CreateupdateAutoReplayMessagesInput!\n) {\n  createupdateAutoReplayMessages(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf9c726378eb98cda34ec42f86eac240';

module.exports = node;
