import React, { Component } from 'react'
import Swal from 'sweetalert2';

export default class fileInput extends Component {

    handleInputFile = (e) => {
        let file = e.currentTarget.files[0];
        if (file) {
            let totalBytes = file.size;
            // check gt 2 mb
            if (totalBytes > 20971520) {
                Swal.fire('ขนาดไฟล์ใหญ่เกินไป!', 'กรุณาเลือกไฟล์ใหม่', 'warning');
                return
            } else {
                this.props.appendFileInputRow({
                   node :
                   { nameFile: file.name,
                    fileUpload: file
                    }
                });
            }
        }
    }

    render() {
        return (
            <input type="file" className="custom-file-input" 
                   id={this.props.id} name={this.props.name}
                   accept="application/pdf, image/*" value=""
                   onChange={this.handleInputFile}/>
        )
    }
}
