import React from 'react'
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button/Button";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import { commitMutation } from "react-relay";
import ComponentPagination from '../../../libs/componentPagination';
import Pagination from '../../../libs/newPagination';
import _ from "lodash"
import Swal from "sweetalert2";
import localstorage from '../../../libs/localstorage';
import Loading from '../../../libs/loading';
import { format } from 'date-fns';
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import CancelBudget from './mutations/cancelBudget';
import AccountingTopMenuNavigation from '../../accountingTopMenuNavigation';
import Navigation from './navigation';
import { fetchQuery } from "relay-runtime";

const query = graphql`
  query budgetDraftAllListQuery($status: String, $first: Int, $last: Int) {
    allYearBudget(status: $status,reportOne: true,first: $first, last: $last){
      edges {
          node {
              id
              years
              startDate
              endDate
              creator
              status    
              project{
                  name
              }
          }
      }
      totalCount
    }
  }
`
const mutation = graphql`
  mutation budgetDraftAllListMutation($input: ApproveBudgetInput!) {
    approveBudget(input:$input) 
      {
        ok
        message
      }
  }
`;
class budgetDraftAllList extends ComponentPagination {

  constructor(props){
    super(props)

    this.state = {
      loading: true,
      status: "all",
      first: this.state.first,
      last: this.state.last,
      allBudget: [],
      totalCount: 10,
    }
  }

  componentWillMount(){    
    this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.first !== this.state.first || prevState.last !== this.state.last || prevProps.match.params.status !== this.props.match.params.status) {
      this.setState({
        status: this.props.match.params.status
      },()=>{
        this.getData()
      })
    }
  }

  getData = () => {
    console.log('this.state.status', this.state.status)
    fetchQuery(environment, query, {status: this.state.status, first: this.state.first, last: this.state.last }).then((data) => {
      console.log('data', data)
      let data_clone = _.cloneDeep(data);
      this.setState({
        allBudget: data_clone.allYearBudget.edges,
        totalCount: data_clone.allYearBudget.totalCount,
        loading: false,
      });
    });

  }

  handleApprove = (idInput) => {

    Swal.fire({
      title: `${i18next.t("budgetLastYearCompareWithMonthPaper:Do you want to approve the budget draft")}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: i18next.t("Allaction:yes"),
      cancelButtonText: i18next.t("Allaction:cancel")
    }).then((result) => {
      if (result.value) {

        let variables = {
          input: {
            id: idInput
          }
        };

        commitMutation(environment, {
          mutation,
          variables,
          onCompleted: (response, errors) => {
            this.setState({loading: false})
            if (errors) {
              Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'warning')
            } else {
              Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => { this.setState({loading: true}) })
            }
          },
          onError: error => {
            Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
          }
        });

      }
    })
  }

  handleCancelBudget = (budgetId, budgetYear) => {    
    let variables = {
      input :{
        id: budgetId
      }
    }
    Swal.fire({
      type: 'warning',
      title: `ต้องการยกเลิกงบประมาณปี ${budgetYear} ไหม`,            
      showCancelButton: true,
      confirmButtonText: i18next.t("Allaction:yes"),
      cancelButtonText: i18next.t("Allaction:cancel")
    }).then((result) => {
      if (result.value) {
        CancelBudget(
          variables,
          () => {
            Swal.fire(i18next.t("Allaction:Saved Successfully"), "", "success").then(() => {
              window.location.reload()
            })
          },
          () => {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
              window.location.reload()
            })        
        })
      }
    });

  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
        <AccountingTopMenuNavigation mini={true}/>
          <Translation>
            {t=>
              <div
                className="container-fluid box booking-form p-4 mt-3 "
                id="parcel"
              >
                <div className="row justify-content-start ">
                  <div className="col">
                    <h3>
                      <Link to="/accounting/budget/">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/wrapperContent/back.png"
                          }
                          alt="back"
                          className="back"
                        />
                      </Link>
                      <span className="header">{i18next.t("budgetDraftForm:Drafting a Budget")}</span>
                    </h3>
                  </div>
                </div>
                <div className="row justify-content-end mt-3 mb-5  ">
                  <div className="col">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_create'}) &&
                      <Link to="/accounting/budget/budgetDraft/createUpdateForm/create/noID">
                        <Button primary className="float-right">
                          + {i18next.t("budgetLastYearCompareWithMonthPaper:Add budget draft")}
                        </Button>
                      </Link>
                    }
                  </div>
                </div>
                {this.state.loading ? 
                    <Loading />
                      :
                    <><Navigation />
                          <React.Fragment>
                            <div className="card fade-up mt-4 " id="parcel">
                              <div className="table-responsive">
                                <table className="table table-hover">
                                  <thead>
                                    <tr>
                                      <th className="text-center">{i18next.t("budgetLastYearCompareWithMonthPaper:Sequence")}</th>
                                      <th className="text-center">{i18next.t("budgetLastYearCompareWithMonthPaper:Corporate name")}</th>
                                      <th className="text-center">{i18next.t("budgetLastYearCompareWithMonthPaper:Budgeting year")}</th>
                                      <th className="text-center">{i18next.t("budgetDraftForm:Budgeting year start date")}</th>
                                      <th className="text-center">
                                      {i18next.t("budgetDraftForm:Budgeting year end date")}
                                      </th>
                                      <th className="text-center" />
                                      <th className="text-left">{i18next.t("budgetDraftForm:Status")}</th>
                                      <th className="text-center" />
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {this.state.allBudget?.map((budget, index) => {
                                      let order_id = this.state.first - localstorage.getPageLimit();
                                      return (
                                      <React.Fragment key={index}>
                                        <tr className="show">
                                          <td className="text-center">{order_id + index + 1}</td>
                                          {budget.node.status !== "APPROVE" 
                                            ? <td className="text-center">
                                                {budget.node.project?.name}
                                              </td>
                                              : <td className="text-center">
                                                  <Link 
                                                    className="text-decoration-none"
                                                    to={`/accounting/budget/budgetDraft/createUpdateForm/${budget.node.status}/${budget.node.id}`}>
                                                    {budget.node.project?.name}
                                                  </Link>
                                                </td>
                                          }
                                          <td className="text-center">
                                            {budget.node.years}
                                          </td>
                                          <td className="text-center">
                                            {format(budget.node.startDate, "DD/MM/YYYY")}
                                          </td>
                                          <td className="text-center">
                                            {format(budget.node.endDate, "DD/MM/YYYY")}
                                          </td>
                                          {budget.node.status === "NOT_APPROVE" ? 
                                          <td className="text-center d-flex">
                                            <Link to={`/accounting/budget/budgetDraft/createUpdateForm/${budget.node.status}/${budget.node.id}`}>
                                              <button
                                                type="button"
                                                className="btn btn-outline-primary mr-3"
                                              >{i18next.t("Allaction:Draf")}
                                              </button>
                                            </Link>
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_approve'}) &&
                                            <button
                                              type="button"
                                              className="btn"
                                              style={{background: "#0382FA", color: "#ffffff"}}
                                              onClick={() => this.handleApprove(budget.node.id)}
                                            >{i18next.t("Allaction:Approve")}
                                            </button>}
                                          </td> : <td/>}
                                          <td>{i18next.t(`budgetAll:${budget.node.status.toLowerCase()}`)}</td>
                                          <td>
                                            {budget.node.status !== "CANCEL" && 
                                            <button
                                              type="button"
                                              className="btn btn-light"  
                                              onClick={()=>this.handleCancelBudget(budget.node.id, budget.node.years)}                                            
                                            >
                                              <img
                                              src={process.env.PUBLIC_URL + '/images/icons/deleteIconRed.svg'}
                                              alt="deleteRed" className="btn-delete cursor"
                                              width="20px" height="20px"
                                              />                 
                                              <span className='ml-2'>{i18next.t("Allaction:Delete")}</span>
                                            </button>}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                      )}
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={this.state.totalCount}
                              />
                            </div>
                          </React.Fragment>
                    </>                    
                }
              </div>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    )
  }
}
export default budgetDraftAllList;
