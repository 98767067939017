import React, { Component } from 'react';
import DatePickerAdapter from '../../../libs/datePickerAdapter';
import "../../developer.scss";
import _ from "lodash";
import numberWithCommas from '../../../libs/numberWithComma';
import OnDelete from './onDelete';
import SelectService from './selectService';
import { Translation } from 'react-i18next';
import getDatTime from '../../../libs/getDateTime'
import { setDate, setMonth } from 'date-fns';
import upload from '../../../libs/upload';
import Swal from 'sweetalert2';

const newTransaction ={
    averagePerMonths : '',
    customerChartOfAccount : '',
    chartOfAccount : '',
    price : '',
    productAndServiceID : '',
    issuedDate : setDate(new Date(),1),
    dueDate : new Date(new Date().setMonth(new Date().getMonth() + 1))
}
class Freebies extends Component {

    constructor(props){
        super(props);
        this.state={
            transactionList : this.props.freebies ? this.props.freebies.length > 0 ? this.props.freebies : [newTransaction] :  [newTransaction],
            productAndService : this.props.productAndService,
            freebies : false,
            payment :false,
            freebiesSelect : '',
            freebiesItems: [],
            freebiesImage: "",
        }

        this.handleChange = this.handleChange.bind(this);
        this.appendNewRow = this.appendNewRow.bind(this);
        this.setTransactionList = this.setTransactionList.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
        this.handleAddAttachForm = this.handleAddAttachForm.bind(this)
    }
    componentWillMount() {
        this.handleAddAttachForm()
    }

    componentDidUpdate(prevProps){
        const newItem = {
            giftName: "",
            giftNameEn: "",
            unitItems: 1,
            giftImage: "",
        };
        if(this.props.productAndService !== this.state.productAndService){
            this.setState({
                productAndService : this.props.productAndService
            })
        }
        if((this.props.freebies && this.props.freebies.length > 0) && (prevProps.freebies !== this.props.freebies)){
            let transactionList = []
            _.forEach(this.props.freebies , freebies => {
                transactionList.push({...freebies})
            })
            this.setState({
                transactionList : transactionList
            },() => this.getFreebiesToBoolean(this.state.transactionList[0].freebies ))

            this.setState({
                freebiesItems: this.props.freebieItemList ? this.props.freebieItemList : [newItem],
            })
        }
    }

    handleCheck(e) {
        if(e.target.name === 'payment'){
            this.setState({
                payment : !this.state.payment
            },() => this.getSelectedFreebies())
        }else if(e.target.name === 'freebies'){
            this.setState({
                freebies : !this.state.freebies
            },() => this.getSelectedFreebies())
        }
    }

    getSelectedFreebies = () => {
        let freebiesSelect = 'none'
        if(this.state.payment && this.state.freebies){
            freebiesSelect = 'freebies and payment'
        }else if(this.state.payment){
            freebiesSelect = 'payment'
        }else if(this.state.freebies){
            freebiesSelect = 'freebies'
        }
        this.setState({
            freebiesSelect : freebiesSelect
        })
    }

    getFreebiesToBoolean = (freebiesSelected) => {
        let payment = false
        let freebies = false
        if(freebiesSelected === 'freebies'){
            freebies = true
            payment = false
        }else if(freebiesSelected === 'freebies and payment'){
            payment = true
            freebies = true

        }else if(freebiesSelected === 'payment'){
            payment = true
            freebies = false
        }
        this.setState({
            payment : payment,
            freebies : freebies
        })
    }

    handleChange(e){
        if(e.target.dataset.key === 'transactionList'){
            let id = e.target.dataset.id
            let transactionList = _.cloneDeep(this.state.transactionList)
            if(e.target.name === "productAndService"){
                let productAndService = _.find(_.cloneDeep(this.state.productAndService),{productAndServiceID : e.target.value})
                transactionList[id]["productAndServiceID"] = productAndService.productAndServiceID;
                transactionList[id]["customerChartOfAccount"] = productAndService.customerChartOfAccountCode + ' ' + productAndService.customerChartOfAccount;
                transactionList[id]["chartOfAccount"] = productAndService.chartOfAccountCode +' ' + productAndService.chartOfAccount;
                transactionList[id]["receiveDeposit"] = productAndService.periodAdjustment ? productAndService.receiveDepositCode +' ' + productAndService.receiveDeposit : '';
                transactionList[id]["price"] = 0;
                transactionList[id]["periodAdjustment"] = productAndService.periodAdjustment
                this.setState({
                    transactionList : transactionList
                },() => this.updateAverage(id))
            }else if(e.target.name === 'price'){
                transactionList[id][e.target.name] = parseFloat(e.target.value);
                this.setState({
                    transactionList : transactionList
                },() => this.updateAverage(id))

            }else{
                transactionList[id][e.target.name] = e.target.value;
                this.setState({
                    transactionList : transactionList
                },() => this.updateAverage(id))
            }
        }else{
            this.setState({
                [e.target.name] :   e.target.value
            })
        }
    }

    appendNewRow(){
        this.setState({ transactionList: [...this.state.transactionList, { ...newTransaction}] });
       
    }

    setTransactionList(newTransaction) {
        this.setState({ transactionList: newTransaction });
    }

    updateAverage = (index) => {
        let issuedDate_month = getDatTime(this.state.transactionList[index].issuedDate).getMonth()
        let issuedDate_year = getDatTime(this.state.transactionList[index].issuedDate).getFullYear()
        let dueDate_month = getDatTime(this.state.transactionList[index].dueDate).getMonth()
        let dueDate_year = getDatTime(this.state.transactionList[index].dueDate).getFullYear()
        if((dueDate_year - issuedDate_year) === 0 && (dueDate_month - issuedDate_month) === 0){
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    `transactionList[${index}].averagePerMonths`,
                    this.state.transactionList[index].price
                );
            });
        }else{
            let month = (dueDate_year - issuedDate_year) * 12 + ((dueDate_month - issuedDate_month)+1)
            let avgMonth = this.state.transactionList[index].price / month
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    `transactionList[${index}].averagePerMonths`,
                    avgMonth
                );
            });
        }
    }

    onSubmit(e){
        e.preventDefault();
        this.props.updateFreebies(this.state.transactionList,this.state.freebiesSelect, this.state.freebiesItems)        
    }

    handleAddAttachForm = () => {
        const { freebiesItems } = this.state;
    
        const newItem = {
            giftName: "",
            giftNameEn: "",
            unitItems: 1,
            giftImage: "",
        };
    
        this.setState({
          freebiesItems: [...freebiesItems, newItem],
        });
      };
    
      handleDelAttachForm = (index) => {
        const { freebiesItems } = this.state;
        if (index >= 0 && index < freebiesItems.length) {
          freebiesItems.splice(index, 1); 
          this.setState({
            freebiesItems: [...freebiesItems],
          });
        }
      };

      handleContentChange = (index, field, value) => {
        const { freebiesItems } = this.state;
        const updatedItems = [...freebiesItems];
        updatedItems[index][field] = field === "unitItems" ? parseFloat(value) : value ;
        this.setState({
          freebiesItems: updatedItems,
        });
      };

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/freebies" + path, file);
          resolve(res);
        });
    };

    async handleInputFile (e, index) {
        let file = e.currentTarget.files[0];
        let uploadFile = [];
        if (file) {
            let totalBytes = file.size;
             if (totalBytes >  10485760) {
                Swal.fire('ขนาดไฟล์ใหญ่เกินไป!', 'กรุณาเลือกไฟล์ใหม่', 'warning');
                return
            } else {

                await this.callUpload('', file).then((data) => {
                uploadFile.push({
                        fileUpload : data.key.replace("silverman-storage/private/", ""), 
                        fileName :  file.name,
                        fileShow: data.location
                })});
                
                await this.handleContentChange(index, "giftImage", uploadFile[0].fileShow)
            }
        }
      }
    delImage = (index) => {
        this.handleContentChange(index, "giftImage", "")
    }

    triggerFileInput = (index) => {
        document.getElementById(`uploadImageFreebie ${index}`).click();
    };

    render(){
        return (
            <Translation>
                {t =>
                <React.Fragment>
                    <div className="row mt-4">
                        <div className="col-12">
                            <h4>{t("settingAll:Free gifts and special privileges")}</h4>
                        </div>
                        <div className="col-12">
                            <h5 className="text-red">{t("settingAll:The developer is responsible for these expenses on behalf of the customer.")}</h5>
                        </div>
                    </div>

                    {/* เพิ่มของแถม */}
                    <form onSubmit={this.onSubmit}  id="freebiesAll">
                    <div id='allFreebieContainer'>
                        {
                            this.state.freebiesItems.map((freeBie, index)=>(
                                <div className="freebieList" key={index}>
                                    <div className='uploadContainer mr-4'> 
                                        <h5>{t("settingAll:item")} {index + 1}</h5>
                                        {
                                            freeBie.giftImage ?
                                            <div className='imageUploaded'>
                                            <img 
                                                src={freeBie.giftImage}                                               
                                                width="180px"
                                                height="180px"
                                                alt="giftName"
                                            />
                                            <button
                                                className='crossBtn rounded-circle'
                                                onClick={()=>this.delImage(index)}
                                            >
                                                X
                                            </button>
                                          </div>
                                            :
                                            <div className='uploadImage'>
                                            <img 
                                                src={process.env.PUBLIC_URL + '/images/typeFiles/uploadPicture.png'}                                               
                                                width="180px"
                                                height="180px"
                                                onClick={() => this.triggerFileInput(index)}
                                            />    
                                            <input type="file" id={`uploadImageFreebie ${index}`} 
                                                accept="image/*" value=""
                                                hidden={true}
                                                onChange={(e) => this.handleInputFile(e, index)}
                                            />                                         
                                            </div>
                                        }

                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex flex-row mb-4'>
                                            <div className='inputFreebieContainer pt-9'>
                                                <label>{t("settingAll:freebie_in_thai_name")}<span className='textRed'>*</span></label>
                                                <input 
                                                    className='inputFreebieText'
                                                    placeholder={t("settingAll:freebie_in_thai_name")}
                                                    type="text"     
                                                    value={freeBie.giftName}
                                                    required
                                                    onChange={(e) =>
                                                        this.handleContentChange(
                                                        index,
                                                        "giftName",
                                                        e.target.value
                                                        )
                                                    }       
                                                />
                                            </div>
                                            <div className='inputFreebieContainer pt-9'>
                                                <label>{t("settingAll:freebie_in_english_name")}</label>
                                                <input 
                                                    className='inputFreebieText'
                                                    placeholder={t("settingAll:freebie_in_english_name")}
                                                    type="text"  
                                                    value={freeBie.giftNameEn}
                                                    onChange={(e) =>
                                                        this.handleContentChange(
                                                        index,
                                                        "giftNameEn",
                                                        e.target.value
                                                        )
                                                    }                  
                                                />
                                            </div>
                                            <button className="deleteBtn" onClick={()=>this.handleDelAttachForm(index)}>
                                                <img src={process.env.PUBLIC_URL +"/images/icons/bin-outline.png"} alt="delete-icon"/>
                                            </button>
                                        </div>
                                        <div className='inputFreebieContainer'>
                                        <label>{t("settingAll:quantity")}</label>
                                        <input 
                                            className='inputFreebieUnit'
                                            placeholder={t("settingAll:quantity")}
                                            type="number" min="1" step="0.01"   
                                            value={freeBie.unitItems}
                                            onChange={(e) =>
                                                this.handleContentChange(
                                                index,
                                                "unitItems",
                                                e.target.value
                                                )
                                            }   
                                        />
                                        </div>  
                                    </div>
                                </div>                              
                            ))
                        }
                        <button className="addFromBtn" onClick={() => this.handleAddAttachForm()}>+ {t("settingAll:addFreebie")}</button>
                    </div>
                    

                    <div className="row form-inline mt-8">
                        <div className="col-2"><h4>{t("settingAll:List")}</h4></div>
                        <div className="col form-inline mt-3 mb-3 d-flex align-items-center">
                            <input type="checkbox" className="mr-2" onChange={this.handleCheck} name="freebies" checked={this.state.freebies}/>
                                <span className="mr-3">{t("settingAll:Giveaway")}</span>  
                            <input type="checkbox" className="mr-2 " onChange={this.handleCheck} name="payment" checked={this.state.payment}/>
                                <span>{t("settingAll:Advance expenses on ownership transfer day")}</span>  
                        </div >
                    </div>
                    <hr />
                    <div id="freebies">
                        <div className="row card-bd-none transaction-table">
                            <table className="table col-12 mr-3 p-4" style={{fontSize : '16px'}}>
                                <thead  className="thead-light">
                                    <tr >
                                        <th className="text-left bg-grey" width={200}>{t("settingAll:Services code")}</th>
                                        <th className="text-left bg-grey" >{t("settingAll:Account receivables")}</th>
                                        <th className="text-left bg-grey" >{t("settingInvoiceTerms:deferred")}</th>
                                        <th className="text-right bg-grey" >{t("settingAll:Advance received")}</th>
                                        <th className="text-center bg-grey" >{t("settingAll:Income allocation start month")}</th>
                                        <th className="text-center bg-grey">{t("settingAll:Income allocation end month")}</th>
                                        <th className="text-right bg-grey" >{t("settingAll:Average")}</th>
                                            <th className="text-center bg-grey" > 
                                        </th>
                                    </tr>
                                </thead>
                                    <tbody>
                                        {this.state.transactionList.map((transaction,index) => {
                                            if((transaction.status && transaction.status !== 'delete') || !transaction.status){
                                                return(
                                                    <tr key={index}>
                                                        <td className="text-left">
                                                            <SelectService onChange={this.handleChange}
                                                                value={transaction.productAndServiceID}
                                                                name="productAndService"
                                                                placeholder={t("settingAll:List")}
                                                                queryObject={this.state.productAndService}
                                                                keyOfValue="productAndServiceID" 
                                                                require={true}
                                                                keyOfLabel="productCode:name"
                                                                data-id={index} 
                                                                data-key="transactionList"
                                                                disabled={transaction.obligationDeveloperID}
                                                                style={{fontSize:'16px'}}
                                                                />
                                                        </td>
                                                        <td className="text-left">
                                                            <span>
                                                                {!transaction.obligationDeveloperID  ?
                                                                    transaction.customerChartOfAccount
                                                                    :
                                                                    transaction.customerChartOfAccountCode + ' ' + transaction.customerChartOfAccount
                                                                }
                                                            </span>
                                                        </td>
                                                        
                                                        <td className="text-left">
                                                            <span>
                                                                {!transaction.obligationDeveloperID  ?
                                                                        (transaction.periodAdjustment ?  transaction.receiveDeposit: '')
                                                                    :
                                                                        (transaction.periodAdjustment ? transaction.receiveDepositCode +' ' + transaction.receiveDeposit : '')
                                                                }
                                                                </span>
                                                        </td>
                                                        <td className="text-right">
                                                            <input 
                                                            name="price" 
                                                            data-key="transactionList"
                                                            data-id={index} 
                                                            type="number" min="1" step="0.01"
                                                            value={transaction.price}
                                                            disabled={transaction.userInTran}
                                                            // disabled={transaction.obligationDeveloperID}
                                                            className="form-control text-right" onChange={this.handleChange} />
                                                        </td>
                                                        <td className="text-center float-center">
                                                            <DatePickerAdapter 
                                                                selected={getDatTime(transaction.issuedDate)}
                                                                className="form-control float-center"
                                                                data-key="transactionList"
                                                                data-id={index} 
                                                                name="issuedDate"
                                                                onChange={this.handleChange}
                                                                dateFormat="MM/yyyy"
                                                                showMonthYearPicker={true}
                                                                disable={transaction.userInTran}
                                                                // disable={transaction.obligationDeveloperID}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <DatePickerAdapter 
                                                                selected={getDatTime(transaction.dueDate)}
                                                                className="form-control"
                                                                data-key="transactionList"
                                                                data-id={index} 
                                                                name="dueDate"
                                                                onChange={this.handleChange}
                                                                dateFormat="MM/yyyy"
                                                                showMonthYearPicker={true}
                                                                minDate={transaction.obligationDeveloperID ? 
                                                                    setMonth(getDatTime(transaction.issuedDate),getDatTime(transaction.issuedDate).getMonth()-1) :
                                                                    setMonth(getDatTime(transaction.issuedDate),getDatTime(transaction.issuedDate).getMonth())}
                                                                disable={transaction.userInTran}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <span>{numberWithCommas(transaction.averagePerMonths,"-")}</span>
                                                        </td>
                                                        
                                                        {transaction.userInTran ?
                                                            <td width={1}/>
                                                            :
                                                            <td width={1}>
                                                                <OnDelete
                                                                    transaction={transaction}
                                                                    transactionList={this.state.transactionList}
                                                                    setTransactionList={this.setTransactionList}
                                                                />
                                                            </td>
                                                        }
                                                    </tr>
                                                );
                                            }else{
                                                return <></>
                                            }
                                        })
            
                                        }
                                    </tbody>
                                
                            </table>
                            <div className="col-12 form-inline mt-3 mb-3 d-flex align-items-center">
                                    <button
                                        type="button"
                                        className="btn add-button add"
                                        onClick={this.appendNewRow}
                                    >
                                        <span className="text-blue" >+ {t('createUpdateForm:add_list')}</span>
                                    </button>  
                            </div>
        
                        </div>
                    </div>
                    </form>
                    <div className="row justify-content-end mb-2 p-4">
                        <div className="col mt-3">
                            <button type="submit" form="freebiesAll"  className="btn btn-blue add  float-right">
                                <span>{this.props.edit ? t("Allaction:save") : t("Allaction:next")}</span>
                            </button>
                        </div>
                    </div>
                </React.Fragment> }
            </Translation>
        );
    }




}export default Freebies;