import React from "react";
import { Translation } from "react-i18next";
import Loading from "../../../../libs/loading";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { fetchQuery } from "react-relay";
import Swal from "sweetalert2";

const query = graphql`
query waterAndElectricUtilityReceiveReportTableQuery
    ($atDate: Date
     $month: Int
     $year: Int
    $search: String
    $contactId: ID
    $typeOfContact: String
    $productAndService: String
    )
  {
    allContactUnitTransaction(
        atDate: $atDate,
        month: $month,
        year: $year
        search:$search,
        contactId:$contactId,
        typeOfContact:$typeOfContact,
        productAndService: $productAndService,
    )
}`

class WaterAndElectricUtilityReceiveReportTable extends React.Component {
    constructor(props) {
        super(props); 
        let month_before = new Date();
        month_before.setMonth(month_before.getMonth() - 1);
    
        let min_date = new Date();
        min_date.setMonth(month_before.getMonth() - 12);
    
    
        this.state = {
          data: "",
          loading: false,
    
          current_date: new Date(),
          startDate: month_before,
          temp_start_date: month_before,
          endDate: new Date(),
          temp_end_date: new Date(),
    
          temp_product_and_service: "",
          productAndService: "",
          search: "",
          firstInit: true,
        };
    
      }

      componentDidUpdate(prevProps, prevState) {
        if (
          this.props.selectedAtDate !== prevProps.selectedAtDate ||
          this.props.selectedMonth !== prevProps.selectedMonth ||
          this.props.selectedYear !== prevProps.selectedYear ||
          this.props.productAndServiceInit !== prevProps.productAndServiceInit ||
          this.props.productAndServiceFilter !== prevProps.productAndServiceFilter ||
          this.props.search !== prevProps.search || 
          this.props.contact !== prevProps.contact ||
          this.props.customerType !== prevProps.customerType
        ) {
          this.queryData()
        }
      }
    

    queryData = () => {      
      if(this.props.selectedMonth >= 1 && this.props.selectedAtDate !== 'Invalid Date') {
        this.props.handleLoadingBtn(true)
        this.setState({ 
          loading: true,
          firstInit: false
        })

        fetchQuery(environment, query,{
          atDate: this.props.selectedAtDate,
          month: this.props.selectedMonth,
          year: this.props.selectedYear,
          search: this.props.search,
          contactId:this.props.contact,
          productAndService: this.props.productAndServiceFilter ? this.props.productAndServiceFilter : this.props.productAndServiceInit,         
        }).then(data => {
          this.setState({ 
            data: data,
            loading: false,
            firstInit: false,
          },()=>{
            this.props.handleFirstInit(this.state.firstInit)
            this.props.handleLoadingBtn(false)
          })
        })
        .catch(error => {
          Swal.fire("Error!", "", "error"); 
        })

        
      } else {
        Swal.fire("กรุณาระบุวันที่", "", "error"); 
      } 

    
    }
    render() {
        return (this.state.data && !this.state.loading ?
            <Translation>
            { (t) => 
            <table className="table table-hover" style={{ fontSize: 12 }}>
                    <thead className="thead-light">
                    <tr>
                        <th rowSpan={2} className="align-middle">{t('utilityReceivable:no.')}</th>
                        <th rowSpan={2} className="align-middle">{t('utilityReceivable:roomNumber')}</th>
                        <th rowSpan={2} className="align-middle text-left">{t('utilityReceivable:name')}</th>
                        <th rowSpan={2} className="align-middle text-right">{t('utilityReceivable:count')}</th>
                        <th rowSpan={2} className="align-middle text-right">{t('utilityReceivable:rate')}</th>
                        <th rowSpan={2} className="align-middle text-right">{t('utilityReceivable:total')}</th>
                        <th rowSpan={2} className="align-middle text-right">{t('utilityReceivable:paid')}</th>
                        <th rowSpan={2} className="align-middle text-right">{t('utilityReceivable:balance')}</th>
                        <th rowSpan={2} className="align-middle text-right">{t('utilityReceivable:percentBalance')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.data.allContactUnitTransaction.map((jsonString, index) => {
                    const data = JSON.parse(jsonString);    
                        return (
                            <tr key={index}>
                                <td className="align-middle text-left">{data.no}</td>
                                <td className="align-middle text-left">{data.room}</td>
                                <td className="align-middle text-left">{data.name === "รวม" ? <b>{t('utilityReceivable:totalAmount')}</b> : data.name}</td>
                                <td className="align-middle text-right">{data.name === "รวม" ? <b>{data.count}</b> : data.count}</td>
                                <td className="align-middle text-right">{data.name === "รวม" ? <b>{data.rate}</b> : data.rate}</td>
                                <td className="align-middle text-right">{data.name === "รวม" ? <b>{data.total}</b> : data.total}</td>
                                <td className="align-middle text-right">{data.name === "รวม" ? <b>{data.paid}</b> : data.paid}</td>
                                <td className="align-middle text-right">{data.name === "รวม" ? <b>{data.balance}</b> : data.balance}</td>
                                <td className="align-middle text-right">{data.name === "รวม" ? <b>{data.percent_balance}</b> : data.percent_balance}</td>
                            </tr>
                        );
                    })}
                    </tbody>               
            </table> }
            </Translation>
            :
            !this.state.firstInit && <Loading />
            )
    }
}

export default WaterAndElectricUtilityReceiveReportTable;
