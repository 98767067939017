import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import SettingAdvertiseList from './settingAdvertiseList';
import CreateAdvertise from './createAdvertise';



class SettingAdvertise extends Component {
    
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={SettingAdvertiseList}/>
                <PrivateRoute exact path={`${this.props.match.url}/create`} component={CreateAdvertise}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default SettingAdvertise;