import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import { format, parse } from "date-fns";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../env/environment";
import { fetchQuery } from "relay-runtime";
import i18n from "i18next";
import upload from '../../libs/upload'
import _ from 'lodash'
import DatePicker from "react-datepicker";
import jwtDecode from 'jwt-decode'
import i18next from 'i18next';

const mutation = graphql`
    mutation createUpdateStaffMutation($input: CreateUpdateStaffInput!) {
        createUpdateStaff(input: $input) {
            ok
        }
    }
`;

const query = graphql`
    query createUpdateStaffQuery($id: ID!) {
        projectStaff(id: $id) {
            id
            nameTitle
            nameStaff
            lastNameStaff
            image
            role
            dateWork
            endWork
            phone
            email
            department
            document
            note
            status
            projectStaffDocument{
                edges {
                    node {
                        id
                        fileName
                        fileUpload
                    }
                }
            }
        }
    }
`;

class CreateUpdateStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            imageProfile: '',
            name_title: '',
            name_staff: '',
            last_name_staff: '',
            role: '',
            department: '',
            date_work: new Date(),
            end_work: null,
            document: [],
            document_upload: [],
            reQuery: false,
            loading: false,
            loadingPage: true,
            note: "",
            status: true,
            phone: "",
            email: "",
        };

        this.handleInputImage = this.handleInputImage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.uploadFile = this.uploadFile.bind(this)
        this.dateFile = this.dateFile.bind(this)
    }

    componentWillMount() {
        fetchQuery(environment, query, { id: this.props.match.params.id || "" }).then(data => {
            if (data?.projectStaff) {
                // console.log(data);
                this.setState({
                    id: data.projectStaff.id,
                    name_title: data.projectStaff.nameTitle,
                    name_staff: data.projectStaff.nameStaff,
                    last_name_staff: data.projectStaff.lastNameStaff,
                    imageProfile: data.projectStaff.image,
                    role: data.projectStaff.role,
                    date_work: parse(data.projectStaff.dateWork),
                    end_work: parse(data.projectStaff.endWork),
                    department: data.projectStaff.department,
                    document_upload: data.projectStaff.projectStaffDocument?.edges.map((n)=> ({...n.node , status : "" , roomGroupImageId: n.node.id})),
                    note: data.projectStaff.note,
                    status: data.projectStaff.status,
                    phone: data.projectStaff.phone,
                    email: data.projectStaff.email,
                    loadingPage: false
                })
            } else {
                this.setState({ loadingPage: false })
            }
        });
        // if (this.props.match.params.id) {

        // }
    }




    handleInputImage(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        let user = jwtDecode(localStorage.getItem("token"))
        let typeFile = files.type.split("/")
        let new_file = new File([files], `profile-${user.user_id}-${format(new Date(), "YYYYMMDDHHmmss")}.${typeFile[1]}`);
        this.callUpload('', new_file).then((data) => {
            let dese = "projectInfo/staff/" + new_file.name
            let dataFile = {
                "roomGroupImageId": '',
                "fileName": new_file.name,
                "fileUpload": dese,
                "viewPtath": data.location,
                "status": "",
            }
            this.setState({ imageProfile: dataFile })
        });  
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let variables = {
            "input": {
                "projectStaff": {
                    "id": this.state.id,
                    "image": this.state.imageProfile.fileUpload,
                    "nameTitle": this.state.name_title,
                    "nameStaff": this.state.name_staff,
                    "lastNameStaff": this.state.last_name_staff,
                    "role": this.state.role,
                    "department": this.state.department,
                    "dateWork": format(this.state.date_work, 'YYYY-MM-DD'),
                    "endWork": format(this.state.end_work, 'YYYY-MM-DD') || null,
                    "phone": this.state.phone,
                    "email": this.state.email,
                    "status": "",
                    "note": "",
                    "projectStaffDocument": this.state.document_upload
                }
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                // uploadables,
                onCompleted: (response) => {
                    this.setState({ loading: false });
                    if (response?.createUpdateStaff?.ok) {
                        Swal.fire(i18n.t("staff:Save successfully!"), '', 'success').then(() => {
                            this.props.history.push("/project/staff/current")
                        });
                    } else {
                        Swal.fire(i18n.t("staff:Save unsuccessfully!"), '', 'warning');
                    }

                },
                onError: (err) => {
                    this.setState({ loading: false });
                    Swal.fire(i18n.t("staff:Save unsuccessfully!"), '', 'error');
                },
            },
        );
    }

    //UPLOADFILE
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/projectInfo/staff" + path, file);
            resolve(res);
        });
    };
    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files) {
            if (files?.size > 10485760) {  // RQ147 2097152
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }

            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), 'กรุณาอัพโหลดไฟล์ในประเภท .png, .jpg, และ .jpegเท่านั้น และไฟล์ขนาดไม่เกิน 10 MB (สูงสุด 3 รูป)', 'error')
            }

            else {
                // {Math.floor(Math.random() * 1000000001)}
                let user = jwtDecode(localStorage.getItem("token"))
                let typeFile = files.type.split("/")
                let new_file = new File([files], `${user.user_id}-${Math.floor(Math.random() * 1000000001)}-${format(new Date(), "YYYYMMDDHHmmss")}.${typeFile[1]}`);
                let fileOldList = _.cloneDeep(this.state.document_upload)
                this.callUpload('', new_file).then((data) => {
                    let dese = "projectInfo/staff/" + new_file.name
                    let dataFile = {
                        "id":"",
                        "roomGroupImageId": '',
                        "fileName": new_file.name,
                        "fileUpload": dese,
                        "viewPtath": data.location,
                        "status": "",
                    }
                    fileOldList.push(dataFile)
                    this.setState({ document_upload: fileOldList })
                });
            }
        }
    }
    dateFile = (index, data) => {
        // let cloneDataFile = _.cloneDeep(this.state.fileUpload)
        let DataFile = _.cloneDeep(this.state.document_upload)
        let fileupload = []

        if(data.node){
            _.set(DataFile, "[" + index + "].node.status", "delete")
            fileupload = _.get(DataFile, "[" + index + "].node")
        }else{
            _.set(DataFile, "[" + index + "].status", "delete")
            fileupload = _.get(DataFile, "[" + index + "]")
        }
        // const index_data_file = DataFile.findIndex(ex => ex.fileId === data.fileId) 

        if (DataFile.findIndex(ex => ex.fileId === fileupload.fileId) < 0) {
            DataFile.push(fileupload) 
        } 
        if (data.node?.status === '') {
            this.setState({ document_upload: DataFile })
        }else if(data.status === ""){
            this.setState({ document_upload: DataFile })
        }
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            <div className="container-fluid box staff-create" id="staff-create">

                                {
                                    this.state.loadingPage == false ?

                                        <React.Fragment>
                                            <div className="row justify-content-between">
                                                <div className="col">
                                                    <h3 className="mb-4">
                                                        <Link to="/project/staff/current">
                                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                alt="back" className="back" />
                                                        </Link>
                                                        <span>{this.props.match.params.id ? t('staff:edit_staff') : t('staff:Add staff')}</span>
                                                    </h3>
                                                </div>
                                            </div>

                                            <form onSubmit={this.onSubmit}>
                                                <div className="content-inner">
                                                    <div className="row mb-4 fade-up">
                                                        <div className="mx-auto" style={{ width: "984px" }}>
                                                            <div className="card p-3">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-2 text-center">
                                                                            {this.state.imageProfile ?
                                                                                <div
                                                                                    className="rounded-circle avatar-img profile-img"
                                                                                    style={{
                                                                                        backgroundImage: `url(${this.state.imageProfile.viewPtath ?
                                                                                            this.state.imageProfile.viewPtath :
                                                                                            this.state.imageProfile})`
                                                                                    }}>
                                                                                </div>
                                                                                :
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                                    className="rounded-circle avatar-img profile-img"
                                                                                    alt="juristic-profile" />
                                                                            }

                                                                            {
                                                                                this.state.status !== false &&
                                                                                <div className="uploadBTN">
                                                                                    <label htmlFor="uploadImgProfile" className="upload-btn-wrapper">
                                                                                        <button className={"btn"} type='button'>
                                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/image.png'}
                                                                                                className='mr-3' />
                                                                                            {t("staff:Change picture")}
                                                                                        </button>

                                                                                        <input type="file" name='imageProfile' id="uploadImgProfile"
                                                                                            className='form-control uploadFile'
                                                                                            accept='image/*' onChange={this.handleInputImage}
                                                                                        />

                                                                                    </label>
                                                                                </div>
                                                                            }


                                                                        </div>

                                                                        <div className="col-sm-10">
                                                                            <div className="row mt-2">
                                                                                <div className="col-6">
                                                                                    <p className='label16'>{t("staff:Name prefix")}</p>
                                                                                    <select className="form-control SelectBox"
                                                                                        value={this.state.name_title}
                                                                                        onChange={this.handleInputChange}
                                                                                        required={true}
                                                                                        disabled={!this.state.status}
                                                                                        name="name_title">
                                                                                        <option value="">{t("staff:Please select an item")}</option>
                                                                                        <option value="นาย">นาย</option>
                                                                                        <option value="นาง">นาง</option>
                                                                                        <option value="นางสาว">นางสาว</option>
                                                                                        <option value="เด็กชาย">เด็กชาย</option>
                                                                                        <option value="เด็กหญิง">เด็กหญิง</option>
                                                                                        <option value="Miss">Miss</option>
                                                                                        <option value="Mrs.">Mrs.</option>
                                                                                        <option value="Mr.">Mr.</option>
                                                                                        <option value="Master">Master</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mt-4">
                                                                                <div className="col">
                                                                                    <p className='label16'>{t("staff:Name")}</p>
                                                                                    <input readOnly={!this.state.status} type="text" className="form-control inputBox"
                                                                                        id="name_staff"
                                                                                        value={this.state.name_staff}
                                                                                        onChange={this.handleInputChange}
                                                                                        name="name_staff" required />
                                                                                </div>

                                                                                <div className="col">
                                                                                    <p className='label16'>{t("staff:Surname")}</p>
                                                                                    <input readOnly={!this.state.status} type="text" className="form-control inputBox"
                                                                                        id="last_name_staff"
                                                                                        value={this.state.last_name_staff}
                                                                                        onChange={this.handleInputChange}
                                                                                        name="last_name_staff" required />
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mt-4">
                                                                                <div className="col">
                                                                                    <p className='label16'>{t("staff:Department")}</p>
                                                                                    <input readOnly={!this.state.status} type="text" className="form-control inputBox"
                                                                                        id="department"
                                                                                        value={this.state.department}
                                                                                        onChange={this.handleInputChange}
                                                                                        name="department" />
                                                                                </div>
                                                                                <div className="col">
                                                                                    <p className='label16'>{t("staff:Position")}</p>
                                                                                    <input readOnly={!this.state.status} type="text" className="form-control inputBox"
                                                                                        id="role"
                                                                                        value={this.state.role}
                                                                                        onChange={this.handleInputChange}
                                                                                        name="role" required />
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mt-4">
                                                                                <div className="col-6">
                                                                                    <p className='label16'>{t("staff:Start date")}</p>

                                                                                    {
                                                                                        this.state.status !== false ?
                                                                                            <div className="datePicker">
                                                                                                <DatePicker
                                                                                                    className="form-control inputBoxDate"
                                                                                                    selected={this.state.date_work}
                                                                                                    onChange={(date) => this.setState({ date_work: date })}
                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                    disabled={!this.state.status}
                                                                                                />
                                                                                            </div>

                                                                                            :
                                                                                            <input
                                                                                                readOnly={!this.state.status}
                                                                                                type="text"
                                                                                                className="form-control inputBox"
                                                                                                value={format(this.state.date_work, "DD/MM/YYYY")}
                                                                                            />
                                                                                    }
                                                                                </div>

                                                                                <div className="col-6">
                                                                                    <p className='label16'>{t("staff:End date")}</p>
                                                                                    {
                                                                                        this.state.status !== false ?
                                                                                            <div className="datePicker">
                                                                                                <DatePicker
                                                                                                    className="form-control inputBoxDate"
                                                                                                    selected={this.state.end_work}
                                                                                                    onChange={(date) => this.setState({ end_work: date })}
                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                    disabled={!this.state.status}
                                                                                                />
                                                                                            </div>
                                                                                            :
                                                                                            <input
                                                                                                readOnly={!this.state.status}
                                                                                                type="text"
                                                                                                className="form-control inputBox"
                                                                                                value={format(this.state.end_work, "DD/MM/YYYY")}
                                                                                            />

                                                                                    }

                                                                                </div>
                                                                            </div>

                                                                            <React.Fragment>
                                                                                {
                                                                                    this.state.status === false &&
                                                                                    <div className="row mt-4">
                                                                                        <div className="col">
                                                                                            <p className='label16'>{t("staff:Note")}</p>
                                                                                            <input readOnly={!this.state.status} type="text" className="form-control inputBox"
                                                                                                id="Note"
                                                                                                value={this.state.note}
                                                                                                onChange={this.handleInputChange}
                                                                                                name="Note" />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </React.Fragment>

                                                                            <div className="row mt-4">
                                                                                <div className='col-12'>
                                                                                    <p className='label16'>{t("staff:contactChannel")}</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className='row mt-4'>
                                                                                <div className='col-3'>
                                                                                    <p className='label16'>
                                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/phone.png'}
                                                                                            className='iconImg' />
                                                                                        {t("staff:Phone")}</p>
                                                                                </div>
                                                                                <div className='col-9'>

                                                                                    <input readOnly={!this.state.status} type="text" className="form-control inputBox"
                                                                                        id="phone" pattern='[0-9]{10}' maxLength={10}
                                                                                        value={this.state.phone || ""}
                                                                                        onChange={this.handleInputChange}
                                                                                        name="phone" required />
                                                                                </div>
                                                                            </div>

                                                                            <div className='row mt-4'>
                                                                                <div className='col-3'>
                                                                                    <p className='label16'>
                                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/mail.png'}
                                                                                            className='iconImg' />
                                                                                        {t("staff:Email")}</p>
                                                                                </div>
                                                                                <div className='col-9'>
                                                                                    <input readOnly={!this.state.status} type="text" className="form-control inputBox"
                                                                                        id="email"
                                                                                        value={this.state.email || ""}
                                                                                        onChange={this.handleInputChange}
                                                                                        name="email" />
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mt-4">
                                                                                <div className="col-12">
                                                                                    <p className='label16'>{t("staff:Document")}</p>
                                                                                    {
                                                                                        this.state.status !== false &&
                                                                                        <div className="uploadBTN">
                                                                                            <label htmlFor="uploadFiles" className="upload-btn-wrapper">
                                                                                                <button className={"btn"} type='button'>
                                                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/image.png'}
                                                                                                        className='mr-3' />
                                                                                                    {t("staff:Attach document")}
                                                                                                </button>

                                                                                                <span>{t("staff:Attach document Support")}</span>
                                                                                                <input type="file" name='decument_upload' id="uploadFiles"
                                                                                                    className='form-control uploadFile'
                                                                                                    accept='application/.jpeg,.pdf,.jpg' onChange={this.uploadFile}
                                                                                                />

                                                                                            </label>
                                                                                        </div>
                                                                                    }

                                                                                    {
                                                                                        this.state.document_upload?.length > 0 &&
                                                                                        this.state.document_upload?.map((file, inx) => {
                                                                                            if (file.node) {
                                                                                                let typeFile = file.node.fileName.split(".")
                                                                                                return (
                                                                                                    file.node.status !== "delete" &&
                                                                                                    <p key={inx}>
                                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                                        <Link
                                                                                                            to="route"
                                                                                                            onClick={(event) => {
                                                                                                                event.preventDefault();
                                                                                                                file.node.viewPtath ?
                                                                                                                    window.open(
                                                                                                                        typeof file.node.viewPtath === "string"
                                                                                                                            ? file.node.viewPtath
                                                                                                                            : URL.createObjectURL(file.node.viewPtath)
                                                                                                                    )
                                                                                                                    :
                                                                                                                    window.open(
                                                                                                                        typeof file.node.fileUpload === "string"
                                                                                                                            ? file.node.fileUpload
                                                                                                                            : URL.createObjectURL(file.node.fileUpload)
                                                                                                                    )
                                                                                                            }}
                                                                                                            target="blank">
                                                                                                            {file.node.fileName}
                                                                                                        </Link>
                                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file)}>
                                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                                        </label>
                                                                                                    </p>
                                                                                                )
                                                                                            } else {
                                                                                                let typeFile = file.fileName.split(".")
                                                                                                return (
                                                                                                    file.status !== "delete" &&
                                                                                                    <p key={inx}>
                                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                                        <Link
                                                                                                            to="route"
                                                                                                            onClick={(event) => {
                                                                                                                event.preventDefault();
                                                                                                                file.viewPtath ?
                                                                                                                    window.open(
                                                                                                                        typeof file.viewPtath === "string"
                                                                                                                            ? file.viewPtath
                                                                                                                            : URL.createObjectURL(file.viewPtath)
                                                                                                                    )
                                                                                                                    :
                                                                                                                    window.open(
                                                                                                                        typeof file.fileUpload === "string"
                                                                                                                            ? file.fileUpload
                                                                                                                            : URL.createObjectURL(file.fileUpload)
                                                                                                                    )
                                                                                                            }}
                                                                                                            target="blank">
                                                                                                            {file.fileName}
                                                                                                        </Link>
                                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file)}>
                                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                                        </label>
                                                                                                    </p>
                                                                                                )
                                                                                            }

                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                this.state.status !== false &&
                                                                                <div className="row mt-4">
                                                                                    <div className="col-8"> </div>
                                                                                    <div className='col-2 text-right'>
                                                                                        <Link to="/project/staff/current">
                                                                                            <button className="btnCancled"> {t("staff:Cancel")} </button>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="col-2 text-right">
                                                                                        <button type="submit" disabled={this.state.loading}
                                                                                            className="btnSubmit">
                                                                                            {t("staff:Confirm")}
                                                                                            {
                                                                                                this.state.loading &&
                                                                                                <span
                                                                                                    className="spinner-border spinner-border-sm align-middle mr-2" />
                                                                                            }
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </React.Fragment>

                                        :
                                        <div className="container-fluid box fixRequestFast text-center" >
                                            <img src={process.env.PUBLIC_URL + '/images/iconAlerts/loading.gif'} />
                                        </div>

                                }

                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default CreateUpdateStaff;
