import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import i18n from "i18next";
import '../styles/register.scss'

import Navigation from "./navigation";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {commitMutation, QueryRenderer} from "react-relay";
import Swal from "sweetalert2";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import _ from "lodash";

import Loading from "../../libs/loading";

const mutation = graphql`
    mutation keyCardDetailMutation($input: DeleteKeyCardInput!) {
        deleteKeyCard(input: $input) {
            clientMutationId
            ok
        }
    }
`;

const allResidential = graphql`
    query keyCardDetailQuery($id: ID!) {
        residential(id: $id){
            name
            keyCard{
                edges{
                    node{
                       id
                        keyCardCode
                        typeKeyCard
                        keyCardActive
                        note
                        updated  
                    }
                }
            }
        }
    }
`;

class KeyCardDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            reQuery: false,
            key_card_id: '',
            note: '',
            key_card_active: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.clearInput = this.clearInput.bind(this);
    }

    componentWillMount() {
        this.setState({
            id: this.props.match.params.id,
        });
    }

    clearInput() {
        this.setState({
            note: ''
        });
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(id) {
        this.setState({
            loading: true,
            note: '',
            key_card_id: id,
        });

        Swal.fire({
            title: i18n.t("keycard:Note"),
            input: 'textarea',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: i18n.t("keycard:Save"),
            cancelButtonText: i18n.t("keycard:Cancel"),
            showLoaderOnConfirm: true,

        }).then((result) => {
            if (result.value || result.value === '') {
                let keyCardObject = {
                    id: this.state.key_card_id,
                    note: result.value,
                    key_card_active: this.state.key_card_active
                };
                let variables = {
                    input: {
                        keyCardObject: JSON.stringify(keyCardObject)
                    }
                };

                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            this.setState({loading: false});
                            if (response.deleteKeyCard.ok) {
                                Swal.fire(i18n.t("keycard:Remove successfully!"), '', 'success').then(() => {
                                    this.setState({reQuery: !this.state.reQuery})
                                })
                            } else {
                                Swal.fire(i18n.t("keycard:Remove unsuccessfully!"), '', 'warning');
                            }
                        },
                        onError: (err) => {
                            this.setState({loading: false});
                            Swal.fire(i18n.t("keycard:Remove unsuccessfully!"), i18n.t("keycard:Please try again"), 'error');
                        },
                    },
                );

            }
        })
    }


    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <QueryRenderer
                        environment={environment}
                        query={allResidential}
                        fetchPolicy="no-cache"
                        variables={{
                            "id": this.state.id,
                            reQuery: this.state.reQuery
                        }}
                        render={({error, props}) => {
                            if (error) {
                                return <div>{error.message}</div>;
                            } else if (props) {
                                return (
                                    <div className="container-fluid p-4" id="detail">
                                        <div className="row justify-content-between">
                                            <div className="col header-color">
                                                <h3 className="mb-4">
                                                    <Link to="/register/residential/list/all">
                                                        <img
                                                            src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                            alt="back" className="back"/>
                                                    </Link>
                                                    {props.residential.name}
                                                </h3>
                                                <div className="content-inner">
                                                    <Navigation id={this.state.id}/>
                                                </div>
                                            </div>
                                        </div>

                                        <React.Fragment>
                                            <div className="content-inner">
                                                <h4 className="mb-4">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('register:key_card_current')}</span>
                                                        }
                                                    </Translation>
                                                </h4>
                                                <div className="row mb-4 fade-up">
                                                    {
                                                        props.residential.keyCard.edges.map((key_card) =>
                                                            <React.Fragment key={key_card.node.id}>
                                                                {
                                                                    key_card.node.keyCardActive &&
                                                                    <div className="col-md-4 mb-4">
                                                                        <div className="card p-2 show">
                                                                            <div className="card-body text-center">
                                                                                <h3>{key_card.node.keyCardCode}</h3>
                                                                                <p>{key_card.node.typeKeyCard}</p>

                                                                                <div
                                                                                    className="row d-flex justify-content-center">
                                                                                    <div className="padding-hover"/>
                                                                                    <div className="show-icon">
                                                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_keycards_edit'}) &&
                                                                                        <Link
                                                                                            to={{
                                                                                                pathname: "/register/residential/detail/form-key-card/" + this.state.id + "/" + key_card.node.id
                                                                                            }}>

                                                                                            <img
                                                                                                src={process.env.PUBLIC_URL + '/images/icons/edit.png'}
                                                                                                alt="edit-icon"/>
                                                                                            <Translation>
                                                                                                {t=><span
                                                                                                    className="grey-color cursor ml-1">{t("keycard:Edit")}</span>}
                                                                                            </Translation>
                                                                                        </Link>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="show-icon ml-3">
                                                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_keycards_delete'}) &&
                                                                                        <React.Fragment>
                                                                                            <img
                                                                                                src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                                alt="delete-icon"/>
                                                                                            <Translation>
                                                                                                {t=><span
                                                                                                    className="grey-color underline hover ml-1"
                                                                                                    onClick={() => this.onSubmit(key_card.node.id)}>{t("keycard:Remove")}</span>}
                                                                                            </Translation>
                                                                                        </React.Fragment>
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    <div className="col-md-4 mb-4">
                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_keycards_create'}) &&
                                                        <Link
                                                            to={{
                                                                pathname: "/register/residential/detail/form-key-card/" + this.state.id
                                                            }}>
                                                            <div className="card card-add">
                                                                <div
                                                                    className="card-body d-flex align-middle text-center">
                                                                    <div className="col">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/plus-icon.png'}
                                                                            alt="plus-icon"/>
                                                                        <Translation>{t=><h4>{t("keycard:Add keycard")}</h4>}</Translation>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        }
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h4 className="mb-4">
                                                            <Translation>
                                                                {
                                                                    t => <span>{t('register:key_card_before')}</span>
                                                                }
                                                            </Translation>
                                                        </h4>
                                                    </div>
                                                </div>


                                                <div className="row fade-up">
                                                    {
                                                        props.residential.keyCard.edges.map((key_card_inactive) =>
                                                            <React.Fragment key={key_card_inactive.node.id}>
                                                                {
                                                                    !key_card_inactive.node.keyCardActive &&
                                                                    <div className="col-md-4 mb-4">
                                                                        <div className="card card-key-card p-2">
                                                                            <div className="card-body text-center">
                                                                                <div className="grey-color">
                                                                                    <h3>{key_card_inactive.node.keyCardCode}</h3>
                                                                                    <p>{key_card_inactive.node.typeKeyCard}</p>
                                                                                </div>
                                                                                <Translation>{t=><span><strong>{t("keycard:Note")}</strong></span>}</Translation>
                                                                                <p>{key_card_inactive.node.note} {format(key_card_inactive.node.updated, 'DD/MM/YYYY', {locale: thLocale})}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </React.Fragment>

                                    </div>
                                );
                            }
                            return <Loading/>
                        }}
                    />
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default KeyCardDetail;
