// import '../../report.scss';
import React from 'react';
import _ from "lodash";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Dropdown } from "react-bootstrap";
import numberWithCommaReport from '../../../libs/numberWithCommaReport'
import axios from 'axios';
import getApiRoot from "../../../libs/getAPIRoot";
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const MonthlyBudgetUsageReportExcelExports = ({ state }) => {
  const getData = () => downloadExcel()
  const downloadExcel = async (data) => {
    var token_id = localStorage.getItem("token");
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('total receipt');
    // setWidthcolumns
    let columns = [{ width: 10 }, { width: 50 }]
    // setBorder
    let borders = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    // let fontSizes = { size: 11.5 }
    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let textRight = { vertical: 'middle', horizontal: 'right' };
    let textLeft = { vertical: 'middle', horizontal: 'left' };
    let fontBold = { size: 11.5, bold: true };

    //textHeader 
    worksheet.getCell('A1').value = state.project_name
    worksheet.getCell('A1').font = { size: 11.5, bold: true };
    worksheet.getCell('A2').value = "รายงานการใช้งบประมาณรายเดือน เทียบตั้งงบประมาณรายเดือน"
    worksheet.getCell('A2').font = { size: 11.5, bold: true };
    worksheet.getCell('A3').value = `ตั้งแต่วันที่${format(new Date(state.start_date), " DD MMMM YYYY ", { locale: thLocale, })}ถึง${format(new Date(state.end_date), " DD MMMM YYYY", { locale: thLocale, })}`
    worksheet.getCell('A3').font = { size: 11.5, bold: true };

    //table
    let headerRow, cell, last_index = 0
    let month = state.list_month
    //header
    worksheet.mergeCells("A5", "A6");
    headerRow = worksheet.getRow(5);
    cell = headerRow.getCell(1);
    cell.value = 'ลำดับ';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textCenter

    worksheet.mergeCells("B5", "B6");
    headerRow = worksheet.getRow(5);
    cell = headerRow.getCell(2);
    cell.value = 'รายการ';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textCenter

    let row_number = ['C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
      'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO',]

    let count_1 = 0
    let count_2 = 1

    month.forEach((element, index) => {
      worksheet.mergeCells(`${row_number[count_1]}${5}:${row_number[count_2]}${5}`);
      headerRow = worksheet.getRow(5);
      cell = headerRow.getCell(3 + count_1);
      cell.value = format(element, "MMMYY", { locale: thLocale });
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter

      headerRow = worksheet.getRow(6);
      cell = headerRow.getCell(3 + count_1);
      columns.push({ width: 15 })
      cell.value = 'งบประมาณ'
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter

      headerRow = worksheet.getRow(6);
      cell = headerRow.getCell(3 + count_2);
      columns.push({ width: 15 })
      cell.value = 'เกิดจริง'
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter

      if (month.length === index + 1) {
        worksheet.mergeCells(`${row_number[count_1 + 2]}${5}:${row_number[count_1 + 2]}${6}`);
        headerRow = worksheet.getRow(5);
        cell = headerRow.getCell(3 + count_1 + 2);
        columns.push({ width: 20 })
        cell.value = 'รวมงบประมาณ';
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter
        worksheet.mergeCells(`${row_number[count_1 + 3]}${5}:${row_number[count_1 + 3]}${6}`);
        headerRow = worksheet.getRow(5);
        cell = headerRow.getCell(3 + count_1 + 3);
        columns.push({ width: 20 })
        cell.value = 'รวมเกิดจริง';
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter

        worksheet.mergeCells(`${row_number[count_1 + 4]}${5}:${row_number[count_2 + 4]}${5}`);
        headerRow = worksheet.getRow(5);
        cell = headerRow.getCell(3 + count_1 + 4);
        cell.value = 'เปรียบเทียบกับเกิดจริง'
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter

        headerRow = worksheet.getRow(6);
        cell = headerRow.getCell(3 + count_1 + 4);
        columns.push({ width: 20 })
        cell.value = 'ผลต่าง'
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter
        headerRow = worksheet.getRow(6);
        cell = headerRow.getCell(3 + count_1 + 5);
        columns.push({ width: 20 })
        cell.value = '%'
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter
      }
      count_1 += 2
      count_2 += 2

    });

    //body
    state.lists[4].forEach((element, index) => {
      last_index++

      if (index == 0) {
        var dataRow = worksheet.addRow();
        cell = dataRow.getCell(1);
        cell.border = borders

        cell = dataRow.getCell(2);
        cell.value = 'รายรับ';
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textLeft

        let count_1 = 0
        let count_2 = 1
        element.month_budget.forEach((element_2, index) => {
          headerRow = worksheet.getRow(worksheet.rowCount);
          cell = headerRow.getCell(3 + count_1);
          cell.border = borders

          cell = headerRow.getCell(3 + count_2);
          cell.border = borders

          if (element.month_budget.length === index + 1) {
            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 2);
            cell.border = borders

            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 3);
            cell.border = borders

            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 4);
            cell.border = borders

            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 5);
            cell.border = borders
          }
          count_1 += 2
          count_2 += 2
        });
      }

      var dataRow = worksheet.addRow();
      cell = dataRow.getCell(1);
      cell.value = last_index;
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter

      cell = dataRow.getCell(2);
      cell.value = element.name;
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textLeft

      let count_1 = 0
      let count_2 = 1
      element.month_budget.forEach((element_2, index) => {
        headerRow = worksheet.getRow(worksheet.rowCount);
        cell = headerRow.getCell(3 + count_1);
        cell.value = numberWithCommaReport(element_2.budget);
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight

        cell = headerRow.getCell(3 + count_2);
        cell.value = numberWithCommaReport(element.month_budget_happend[index].totalMonthHappend);
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight
        if (element.month_budget.length === index + 1) {
          headerRow = worksheet.getRow(worksheet.rowCount);
          cell = headerRow.getCell(3 + count_1 + 2);
          cell.value = numberWithCommaReport(element.allChartOfAccountBudget.node.summaryYear.total_past_budget);
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textRight

          headerRow = worksheet.getRow(worksheet.rowCount);
          cell = headerRow.getCell(3 + count_1 + 3);
          cell.value = numberWithCommaReport(element.allChartOfAccountBudget.node.summaryYear.total_happend);
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textRight

          headerRow = worksheet.getRow(worksheet.rowCount);
          cell = headerRow.getCell(3 + count_1 + 4);
          cell.value = numberWithCommaReport(element.allChartOfAccountBudget.node.summaryYear.diff_past_happend)
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textRight
          headerRow = worksheet.getRow(worksheet.rowCount);
          cell = headerRow.getCell(3 + count_1 + 5);
          cell.value = numberWithCommaReport(element.allChartOfAccountBudget.node.summaryYear.percent_diff_past_happend)
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textRight
        }
        count_1 += 2
        count_2 += 2
      });

      if (state.lists[4].length === index + 1) {
        var dataRow = worksheet.addRow();
        cell = dataRow.getCell(1);
        cell.value = '';
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter

        cell = dataRow.getCell(2);
        cell.value = 'รวมรายรับ';
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textLeft

        let count_1 = 0
        let count_2 = 1
        state.sum_income.total_month_budget.forEach((element_2, index) => {
          headerRow = worksheet.getRow(worksheet.rowCount);
          cell = headerRow.getCell(3 + count_1);
          cell.value = numberWithCommaReport(element_2);
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textRight

          cell = headerRow.getCell(3 + count_2);
          cell.value = numberWithCommaReport(state.sum_income.total_month_budget_happend[index]);
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textRight

          if (state.sum_income.total_month_budget.length === index + 1) {
            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 2);
            cell.value = numberWithCommaReport(state.sum_income.total_past_budget);
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textRight

            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 3);
            cell.value = numberWithCommaReport(state.sum_income.total_happend);
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textRight

            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 4);
            cell.value = numberWithCommaReport(state.sum_income.total_diff_past_happend)
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textRight
            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 5);
            cell.value = numberWithCommaReport(state.sum_income.total_percent_diff_past_happend)
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textRight
          }
          count_1 += 2
          count_2 += 2
        });

      }
    })

    _.map(state.lists[5], (item, index_code) => {
      _.map(item.child, (item_child, index) => {
        last_index++

        if (index == 0 && index_code == 0) {
          var dataRow = worksheet.addRow();
          cell = dataRow.getCell(1);
          cell.border = borders

          cell = dataRow.getCell(2);
          cell.value = 'รายจ่าย';
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textLeft

          let count_1 = 0
          let count_2 = 1
          item_child.month_budget.forEach((element_2, index) => {
            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1);
            cell.border = borders

            cell = headerRow.getCell(3 + count_2);
            cell.border = borders

            if (item_child.month_budget.length === index + 1) {
              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 2);
              cell.border = borders

              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 3);
              cell.border = borders

              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 4);
              cell.border = borders

              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 5);
              cell.border = borders
            }
            count_1 += 2
            count_2 += 2
          });

        }

        if (index == 0) {
          var dataRow = worksheet.addRow();
          cell = dataRow.getCell(1);
          cell.border = borders

          cell = dataRow.getCell(2);
          cell.value = item.name;
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textLeft

          let count_1 = 0
          let count_2 = 1
          item_child.month_budget.forEach((element_2, index) => {
            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1);
            cell.border = borders

            cell = headerRow.getCell(3 + count_2);
            cell.border = borders

            if (item_child.month_budget.length === index + 1) {
              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 2);
              cell.border = borders

              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 3);
              cell.border = borders

              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 4);
              cell.border = borders

              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 5);
              cell.border = borders
            }
            count_1 += 2
            count_2 += 2
          });
        }

        var dataRow = worksheet.addRow();
        cell = dataRow.getCell(1);
        cell.value = last_index;
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter

        cell = dataRow.getCell(2);
        cell.value = item_child.name;
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textLeft

        let count_1 = 0
        let count_2 = 1
        item_child.month_budget.forEach((element_2, index) => {
          headerRow = worksheet.getRow(worksheet.rowCount);
          cell = headerRow.getCell(3 + count_1);
          cell.value = numberWithCommaReport(element_2.budget);
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textRight

          cell = headerRow.getCell(3 + count_2);
          cell.value = numberWithCommaReport(item_child.month_budget_happend[index].totalMonthHappend);
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textRight
          if (item_child.month_budget.length === index + 1) {
            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 2);
            cell.value = numberWithCommaReport(item_child.allChartOfAccountBudget.node.summaryYear.total_past_budget);
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textRight

            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 3);
            cell.value = numberWithCommaReport(item_child.allChartOfAccountBudget.node.summaryYear.total_happend);
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textRight

            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 4);
            cell.value = numberWithCommaReport(item_child.allChartOfAccountBudget.node.summaryYear.diff_past_happend)
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textRight
            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1 + 5);
            cell.value = numberWithCommaReport(item_child.allChartOfAccountBudget.node.summaryYear.percent_diff_past_happend)
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textRight
          }
          count_1 += 2
          count_2 += 2
        });

        if (item.child.length === index + 1) {
          var dataRow = worksheet.addRow();
          cell = dataRow.getCell(1);
          cell.value = '';
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textCenter

          cell = dataRow.getCell(2);
          cell.value = `รวม${item.name}`;
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textLeft

          let count_1 = 0
          let count_2 = 1

          state.sum_expenses[item.key].total_month_budget.forEach((element_2, index) => {
            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + count_1);
            cell.value = numberWithCommaReport(element_2);
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textRight

            cell = headerRow.getCell(3 + count_2);
            cell.value = numberWithCommaReport(state.sum_expenses[item.key]?.total_month_budget_happend[index]);
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textRight

            if (state.sum_income.total_month_budget.length === index + 1) {
              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 2);
              cell.value = numberWithCommaReport(state.sum_expenses[item.key].total_past_budget);
              cell.border = borders
              cell.font = fontBold
              cell.alignment = textRight

              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 3);
              cell.value = numberWithCommaReport(state.sum_expenses[item.key].total_happend);
              cell.border = borders
              cell.font = fontBold
              cell.alignment = textRight

              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 4);
              cell.value = numberWithCommaReport(state.sum_expenses[item.key].total_diff_past_happend)
              cell.border = borders
              cell.font = fontBold
              cell.alignment = textRight

              headerRow = worksheet.getRow(worksheet.rowCount);
              cell = headerRow.getCell(3 + count_1 + 5);
              cell.value = numberWithCommaReport(state.sum_expenses[item.key].total_percent_diff_past_happend)
              cell.border = borders
              cell.font = fontBold
              cell.alignment = textRight
            }
            count_1 += 2
            count_2 += 2
          });

        }
      })

    })

    var dataRow = worksheet.addRow();
    cell = dataRow.getCell(1);
    cell.value = '';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textCenter

    cell = dataRow.getCell(2);
    cell.value = 'รวมรายจ่าย';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textLeft

    let count_sum_1 = 0
    let count_sum_2 = 1

    state.total_sum_expenses.total_month_budget.forEach((element_2, index) => {
      headerRow = worksheet.getRow(worksheet.rowCount);
      cell = headerRow.getCell(3 + count_sum_1);
      cell.value = numberWithCommaReport(element_2);
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textRight

      cell = headerRow.getCell(3 + count_sum_2);
      cell.value = numberWithCommaReport(state.total_sum_expenses.total_month_budget_happend[index]);
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textRight

      if (state.total_sum_expenses.total_month_budget.length === index + 1) {
        headerRow = worksheet.getRow(worksheet.rowCount);
        cell = headerRow.getCell(3 + count_sum_1 + 2);
        cell.value = numberWithCommaReport(state.total_sum_expenses.total_past_budget);
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight

        headerRow = worksheet.getRow(worksheet.rowCount);
        cell = headerRow.getCell(3 + count_sum_1 + 3);
        cell.value = numberWithCommaReport(state.total_sum_expenses.total_happend);
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight

        headerRow = worksheet.getRow(worksheet.rowCount);
        cell = headerRow.getCell(3 + count_sum_1 + 4);
        cell.value = numberWithCommaReport(state.total_sum_expenses.total_diff_past_happend)
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight

        headerRow = worksheet.getRow(worksheet.rowCount);
        cell = headerRow.getCell(3 + count_sum_1 + 5);
        cell.value = numberWithCommaReport(state.total_sum_expenses.total_percent_diff_past_happend)
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight
      }
      count_sum_1 += 2
      count_sum_2 += 2
    });

    var dataRow = worksheet.addRow();
    cell = dataRow.getCell(1);
    cell.value = '';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textCenter

    cell = dataRow.getCell(2);
    cell.value = 'รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textLeft

    let count_sum_11 = 0
    let count_sum_22 = 1

    state.total_sum_expenses.total_month_budget.forEach((element_2, index) => {
      headerRow = worksheet.getRow(worksheet.rowCount);
      cell = headerRow.getCell(3 + count_sum_11);
      cell.value = numberWithCommaReport(state.sum_income.total_month_budget[index] - element_2);
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textRight

      cell = headerRow.getCell(3 + count_sum_22);
      cell.value = numberWithCommaReport(state.sum_income.total_month_budget_happend[index] - state.total_sum_expenses.total_month_budget_happend[index]);
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textRight

      if (state.total_sum_expenses.total_month_budget.length === index + 1) {
        headerRow = worksheet.getRow(worksheet.rowCount);
        cell = headerRow.getCell(3 + count_sum_11 + 2);
        cell.value = numberWithCommaReport(state.sum_income.total_past_budget - state.total_sum_expenses.total_past_budget);
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight

        headerRow = worksheet.getRow(worksheet.rowCount);
        cell = headerRow.getCell(3 + count_sum_11 + 3);
        cell.value = numberWithCommaReport(state.sum_income.total_happend - state.total_sum_expenses.total_happend);
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight

        headerRow = worksheet.getRow(worksheet.rowCount);
        cell = headerRow.getCell(3 + count_sum_11 + 4);
        cell.value = numberWithCommaReport(state.sum_income.total_diff_past_happend - state.total_sum_expenses.total_diff_past_happend)
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight

        headerRow = worksheet.getRow(worksheet.rowCount);
        cell = headerRow.getCell(3 + count_sum_11 + 5);
        cell.value = numberWithCommaReport(state.sum_income.total_percent_diff_past_happend - state.total_sum_expenses.total_percent_diff_past_happend)
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight
      }
      count_sum_11 += 2
      count_sum_22 += 2
    });

    worksheet.columns = columns;

    workbook.xlsx.writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let nameFile = 'Monthly Budget Usage Report (Compare with Monthly Budget)'
        let formData = new FormData();
        formData.append('file', blob , "report.xlsx");
        formData.append('type', "excel");
        formData.append('type_report_ref', "monthly_budget_usage_report");
        axios.post(`${getApiRoot()}taskdownload/add_download`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `JWT ${token_id}`,
          }
        })
        .then(response => {
          console.log('File uploaded successfully:', response.data);
        })
        .catch(error => {
          console.error('There was an error sending the file to the server:', error);
        })
        .finally(() => {
          // Download the file after sending it to the API
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = nameFile;
          a.click();
        });
      });
  }
  return (
    <Dropdown.Item  ><div className="text-black" onClick={getData} style={{ display: 'flex', justifyItems: 'center' }} >Excel</div></Dropdown.Item>
  );
};

export default MonthlyBudgetUsageReportExcelExports;