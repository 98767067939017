import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import PettyCashReportTable from "./pettyCashReportTable";
import ExportPettyCashReport from "./server_export";
import _ from "lodash";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import SearchSelect from "../../../../libs/searchSelect";
import { fetchQuery } from "react-relay";
import { formatDateISO } from "../../../../utils";

import Loading from "../../../../libs/loading";

import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from "../../../../components/BackBtn/indexBack";
import i18next from "i18next";

const query = graphql`
  query pettyCashReportQuery($first: Int, $last: Int, $search: String, $end_date: DateTime, $issuedDate: Date, $id: ID) {
    allSetPettyCash(first: $first, last: $last, report: true, search: $search, endDate: $end_date, id:$id) {
      totalCount
      edges {
        node {
          id
          issuedDate
          docNumber
          withdrawer
          description
          withdrawAmount
          status
          
          pettyCashRecord(status_In:"wait,withdraw",issuedDate_Lte: $issuedDate){
            edges {
              node {
                id
                issuedDate
                docNumber
                chartOfAccount {
                  chartOfAccountCode
                }
                description
                price
      
                pettyCashPayment {
                  id
                  docNumber
                  issuedDate
                  description
                  total
                  paymentChannel
                  bankAccountTransaction {
                    totalCount
                    edges {
                      node {
                        bankAccount {
                          bankName
                          accountNumber
                        }
                      }
                    }
                  }
                  chequeTransaction {
                    totalCount
                  }
                  cashTransaction {
                    totalCount
                  }
                }
              }
            }
          }

          paymentChannel(date_Lte: $issuedDate){
            edges{
              node{
                id
                payRecordGroup{
                    id
                    docNumber
                    issuedDate
                    payRecord{
                        edges{
                            node{
                                id
                                purchaseRecord{
                                    id
                                    productAndService{
                                        id
                                        name
                                        productCode
                                    }
                                    chartOfAccount{
                                        id
                                        name
                                        chartOfAccountCode
                                    }
                                }
                            }
                        }
                    }
                }
                price
                payPettyCashPayment {
                  id
                  docNumber
                  issuedDate
                  description
                  total
                  paymentChannel
                  bankAccountTransaction {
                    totalCount
                    edges {
                      node {
                        bankAccount {
                          bankName
                          accountNumber
                        }
                      }
                    }
                  }
                  chequeTransaction {
                    totalCount
                  }
                  cashTransaction {
                    totalCount
                  }
                }
              }
            }
          }

          otherExpensePaymentChannel(date_Lte: $issuedDate){
            edges{
              node{
                id
                otherExpense{
                    id
                    docNumber
                    issuedDate
                    otherExpenseTransaction{
                        edges{
                            node{
                                id
                                productAndService{
                                    id
                                    name
                                    productCode
                                }
                                chartOfAccount{
                                    id
                                    name
                                    chartOfAccountCode
                                }

                            }
                        }
                    }
                }
                price
                pettyCashPayment{
                  id
                  docNumber
                  issuedDate
                  description
                  total
                  paymentChannel
                  bankAccountTransaction {
                    totalCount
                    edges {
                      node {
                        bankAccount {
                          bankName
                          accountNumber
                        }
                      }
                    }
                  }
                  chequeTransaction {
                    totalCount
                  }
                  cashTransaction {
                    totalCount
                  }
                }
              }
            }
          }

          advancePettyCashChannel{
            edges{
                node{
                    id
                    advance{
                        id
                        docNumber
                        issuedDate
                        chartOfAccount{
                            id
                            name
                            chartOfAccountCode
                        }
                    }
                    clearAdvance{
                      id
                      docNumber
                      description
                      issuedDate
                      status
                      advance{
                          id
                          withdrawer
                      }
                      clearAdvanceTransaction{
                          edges{
                              node{
                                  id
                                  description
                                  price
                                  refClearAdvanceTransactionDoc
                                  chartOfAccount{
                                      id
                                      name
                                      chartOfAccountCode
                                  }
                              }
                          }
                        }
                      }
                    price
                    pettyCashPayment{
                      id
                      docNumber
                      issuedDate
                      description
                      total
                      paymentChannel
                      bankAccountTransaction {
                        totalCount
                        edges {
                          node {
                            bankAccount {
                              bankName
                              accountNumber
                            }
                          }
                        }
                      }
                      chequeTransaction {
                        totalCount
                      }
                      cashTransaction {
                        totalCount
                      }
                    }
                }
            }
          }

          clearGuaranteeMoneyReceivedPettyCashChannel{
            edges{
                node{
                    id
                    clearGuaranteeMoneyReceived{
                        id
                        docNumber
                        issuedDate
                        status
                        contact{
                            refNumber
                            firstName
                            lastName
                            name
                        }
                        guarantee {
                            docNumber
                            chartOfAccount {
                                id
                                chartOfAccountCode
                                name
                            }
                        }
                    }
                    description
                    price
                    statusSetPettyCash
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
          }
        }
      }
    }

    allSetPettyCashList : allSetPettyCash{
      totalCount
      edges {
        node {
          id
          issuedDate
          docNumber
          withdrawer
          description
          withdrawAmount
        }
      }
    }
  }
`;

class PettyCashReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let first_date = new Date();
    first_date.setDate(1);

    this.state.temp_start_date = first_date;
    this.state.temp_end_date = new Date();
    this.state.search = "";
    this.state.search_input = "";
    this.state.contact = "";
    this.state.status = "";
    this.state.start_date = first_date;
    this.state.end_date = new Date();
    this.state.all_set_petty_cash_list = [];
    this.state.temp_selected_set_petty_cash = ""
    this.state.selected_set_petty_cash = "";


    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "pettyCashReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch();
    }
  }

  handleSearch() {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: this.state.search_input,
      selected_set_petty_cash: this.state.temp_selected_set_petty_cash
    });
    super.goFirst()
  }


  componentDidMount() {
    let all_set_petty_cash_list = [];
    fetchQuery(environment, query, {}).then(data => {
      let data_clone = _.cloneDeep(data);
      all_set_petty_cash_list = data_clone.allSetPettyCashList.edges
      this.setState({
        all_set_petty_cash_list: _.sortBy(all_set_petty_cash_list, 'node.docNumber'),
        selected_set_petty_cash: all_set_petty_cash_list.length !== 0
          ? ""
          : "",
      }, () => this.setState({
        temp_selected_set_petty_cash: this.state.selected_set_petty_cash
      }))
    });
  }


  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-payable"}
                  LinkText={i18next.t("DashboardPayable:Petty Cash Movement Report")}
                  boxHtmlText={
                    <div className="col">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_payable_print",
                      }) && <ExportPettyCashReport state={this.state} />}
                    </div>
                  }
                />
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col-7" />
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search_input"
                          value={this.state.search_input}
                          onChange={this.handleChange}
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search_input"
                          value={this.state.search_input}
                          onClick={this.handleSearch}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="float-right">
                          {i18next.t("AllFilter:Date")}
                        </div>
                        <div className="float-right ml-2">
                          <DatePickerAdapter
                            name="temp_end_date"
                            className="form-control"
                            selected={this.state.temp_end_date}
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>

                      </div>
                    </div>

                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("AllFilter:List")}</label>
                              <SearchSelect
                                onChange={this.handleChange}
                                value={this.state.temp_selected_set_petty_cash}
                                name="temp_selected_set_petty_cash"
                                placeholder="รายการ"
                                data-key="set_petty_cash"
                                queryObject={this.state.all_set_petty_cash_list}
                                keyOfValue="id"
                                require={true}
                                optionAll={true}
                                keyOfLabel="docNumber:withdrawer:description"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <QueryRenderer
                    environment={environment}
                    query={query}
                    variables={{
                      first: this.state.first,
                      last: this.state.last,
                      search: this.state.search,
                      end_date: this.state.end_date,
                      issuedDate: formatDateISO(this.state.end_date),
                      setPettyCash_Id: this.state.selected_set_petty_cash,
                      id: this.state.selected_set_petty_cash
                    }}
                    render={({ error, props }) => {
                      if (props) {
                        return (
                          <React.Fragment>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table
                                      className="table table-hover table-bordered"
                                      style={{ fontSize: 12 }}
                                    >
                                      <thead className="thead-light">
                                        <tr>
                                          <th className="text-center">
                                            {i18next.t("PettyCashReport:Date of Petty Cash Withdrawal")}
                                          </th>
                                          <th className="text-center">{i18next.t("PettyCashReport:Petty Cash Withdrawal Number")}</th>
                                          <th className="text-center">{i18next.t("PettyCashReport:Transaction / Supervisor")}</th>
                                          <th className="text-center">{i18next.t("PettyCashReport:Amount")}</th>
                                          <th className="text-center">
                                            {i18next.t("PettyCashReport:Date of Petty Cash Recording")}
                                          </th>
                                          <th className="text-center">{i18next.t("PettyCashReport:Petty Cash Recording Number")}</th>
                                          <th className="text-center">{i18next.t("PettyCashReport:Account Number")}</th>
                                          <th className="text-center">{i18next.t("PettyCashReport:Transaction")}</th>
                                          <th className="text-center">{i18next.t("PettyCashReport:Amount")}</th>
                                          <th className="text-center">
                                            {i18next.t("PettyCashReport:Remaining Amount")}
                                          </th>
                                          <th className="text-center">
                                            {i18next.t("PettyCashReport:Date of Petty Cash Return")}
                                          </th>
                                          <th className="text-center">{i18next.t("PettyCashReport:Petty Cash Return Number")}</th>
                                          <th className="text-center">{i18next.t("PettyCashReport:Transaction")}</th>
                                          <th className="text-center">{i18next.t("PettyCashReport:Amount")}</th>
                                          <th className="text-center">
                                            {i18next.t("PettyCashReport:Payment Method")}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <PettyCashReportTable
                                          props={props}
                                          state={this.state}
                                        />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={props.allSetPettyCash.totalCount}
                              />
                            </div>
                          </React.Fragment>
                        );
                      } else {
                        return <Loading />
                      }
                    }}
                  />
                </div>
              </div>
            }
          </Translation>

        </WrapperContent>
      </Wrapper>
    );
  }
}

export default PettyCashReport;
