import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import '../../style/claim.scss'

class InsurranceViewPolicy extends Component {

    functionCurrentMoneyFormat(money) {
        let formatMoney = 0
        if (money) {
            formatMoney = parseFloat(money).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
        return formatMoney ? formatMoney : "-"
    }

    render() {

        return (
            <Translation>
                {
                    t =>
                        <div className='row'>
                            <div className='col-8'>
                                <label className='headerLabel18'>{t("InsurancessView:address")}</label><br />
                                <label className='label16 colorGray text300'>
                                    {this.props.data?.insurance_company_address || "-"}</label>
                                <p></p>

                                <label className='headerLabel18'>{t("InsurancessView:TaxID")}</label><br />
                                <label className='label16 colorGray text300'>
                                    {this.props.data?.main_insurance_company_tax_number || "-"}</label>

                                <p></p>

                                <p></p>
                                <label className='headerLabel18'>{t("InsurancessView:Contact")}</label><br />

                                <label className='headerLabel18 labelW150'>
                                    <img className='imgIcon' src={process.env.PUBLIC_URL + "/images/iconAction/claim/language.png"} style={{ width: 24, height: 24 }} />
                                    <span className="mr-2">{t("InsurancessView:WebSite")}</span>
                                </label>
                                <label className='pl-5 mr-5'>:</label>
                                <label className='label16 colorGray'>
                                    {this.props.data?.main_insurance_company_website}
                                </label>
                                <p></p>

                                <label className='headerLabel18 labelW150'>
                                    <img className='imgIcon'  src={process.env.PUBLIC_URL + "/images/iconAction/claim/phone.png"} style={{ width: 24, height: 24 }} />
                                    <span className="mr-2">{t("InsurancessView:Phone")}</span>
                                </label>
                                <label className='pl-5 mr-5'>:</label>
                                <label className='label16 colorGray'>
                                    {this.props.data?.main_insurance_company_tel}
                                </label>
                                <p></p>

                                <label className='headerLabel18 labelW150'>
                                    <img className='imgIcon'  src={process.env.PUBLIC_URL + "/images/iconAction/claim/mail.png"} style={{ width: 24, height: 24 }} />
                                    <span className="mr-2">{t("InsurancessView:Email")}</span>
                                </label> 
                                <label className='pl-5 mr-5'>:</label>
                                <label className='label16 colorGray'>
                                    {this.props.data?.main_insurance_company_email}
                                </label>
                                <p></p>

                            </div>
                        </div>
                }


            </Translation>
        );
    }
}

export default withRouter(InsurranceViewPolicy);
