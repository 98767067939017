/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoiceStatus = "ACTIVE" | "DRAFT" | "OVERDUE" | "PAID" | "PARTIAL_PAYMENT" | "VOID" | "WAITING_FIX" | "%future added value";
export type InvoiceTaxType = "EXCLUDE_TAX" | "INCLUDE_TAX" | "NO_TAX" | "%future added value";
export type crossBillingPaymentGetQueryVariables = {|
  id?: ?string
|};
export type crossBillingPaymentGetQueryResponse = {|
  +invoiceViewer: ?{|
    +id: string,
    +allInvoiceByStatus: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +taxType: InvoiceTaxType,
          +status: InvoiceStatus,
          +transaction: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +total: ?number,
                +paid: ?number,
                +discount: ?number,
                +vat: ?number,
                +whtRatePercent: ?number,
              |}
            |}>
          |},
        |}
      |}>,
    |},
  |}
|};
export type crossBillingPaymentGetQuery = {|
  variables: crossBillingPaymentGetQueryVariables,
  response: crossBillingPaymentGetQueryResponse,
|};
*/


/*
query crossBillingPaymentGetQuery(
  $id: ID
) {
  invoiceViewer {
    id
    allInvoiceByStatus(id: $id) {
      totalCount
      edges {
        node {
          id
          docNumber
          taxType
          status
          transaction {
            edges {
              node {
                id
                total
                paid
                discount
                vat
                whtRatePercent
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InvoiceViewer",
    "kind": "LinkedField",
    "name": "invoiceViewer",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "concreteType": "InvoiceNodeConnection",
        "kind": "LinkedField",
        "name": "allInvoiceByStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InvoiceNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "docNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taxType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionNodeConnection",
                    "kind": "LinkedField",
                    "name": "transaction",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TransactionNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "total",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paid",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "discount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "vat",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "whtRatePercent",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "crossBillingPaymentGetQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "crossBillingPaymentGetQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ee390fad19145aeda98a56b3301a91a8",
    "id": null,
    "metadata": {},
    "name": "crossBillingPaymentGetQuery",
    "operationKind": "query",
    "text": "query crossBillingPaymentGetQuery(\n  $id: ID\n) {\n  invoiceViewer {\n    id\n    allInvoiceByStatus(id: $id) {\n      totalCount\n      edges {\n        node {\n          id\n          docNumber\n          taxType\n          status\n          transaction {\n            edges {\n              node {\n                id\n                total\n                paid\n                discount\n                vat\n                whtRatePercent\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49dad824b9a1807cce06e36c147351cd';

module.exports = node;
