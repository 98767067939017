import '../styles/withholdingTax3Print.scss';
import React, {Component} from 'react';
import _ from "lodash";
import {format} from "date-fns";
import numberWithComma from "../../libs/numberWithComma";
import getNumberSplitDecimal from "../../libs/getNumberSplitDecimal";
import { getTotalpayRecord } from "../../libs/getTotalpayRecord";
const bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
    10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
    10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
    10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
    10160, 10210, 10310];

class WithholdingAttachmentTax3WithDataPrint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bangkokCode: bangkokCode,
            taxIdNumber: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        };
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
        this.isTypeOf = this.isTypeOf.bind(this)
        this.dynamicFont = this.dynamicFont.bind(this)
    }

    componentDidMount() {
        if (this.props.project) {
            if (_.isObject(this.props.project)) {
                if (this.props.project.taxIdNumber) {
                    this.setState({taxIdNumber: this.props.project.taxIdNumber.split('')})
                }
            }
        }
    }
    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }
    
    isTypeOf(contact){
        if(contact === 'PERSON'){
            return true;
        }else{
            return false;
        }
    }

    getPrefix(type, code) {
        if (type === "district" && this.isBangkok(code)) {
            return "แขวง"
        } else if (type === "city" && this.isBangkok(code)) {
            return "เขต"
        } else if (type === "province" && this.isBangkok(code)) {
            return ""
        } else if (type === "province") {
            return "จ."
        } else if (type === "district") {
            return "ต."
        } else if (type === "city") {
            return "อ."
        }
    }

    dynamicFont(){
        if (this.props.maxLength <= 24) {
            return { zoom : '100%'}
        }else if (this.props.maxLength <= 35) {
            return { zoom : '92%'}
        }else if (this.props.maxLength <= 50) {
            return { zoom : '55%'}
        }else{
            return { zoom : '55%'}
        }
    }

    render() {
        let sum_total_paid = 0;
        let sum_wht_rate = 0;

        return (
            <div id="withholding-tax-3-print">
                <div className="print-page-withholding-a4">
                    <div className="subpage">
                        <div className="withholding-tax-body">
                            <div>
                                <span className="text-header">ใบแนบ <span className="text-header-3">ภ.ง.ด.3</span> เลขประจำตัวผู้เสียภาษีอากร (ของผู้มีหน้าที่หักภาษี ณ ที่จ่าย )</span>
                                <span className="ml-3 mr-3">
                                       {this.state.taxIdNumber.map((value, index) => {
                                           if (index === 1 || index === 5 || index === 10 || index === 12) {
                                               return (
                                                   <React.Fragment key={'taxNum' + index}>
                                                       <span>-</span>
                                                       <span className="border-id-number">{value}</span>
                                                   </React.Fragment>
                                               )
                                           }
                                           return (
                                               <span className="border-id-number"
                                                     key={'taxNum' + index}>{value}</span>
                                           )
                                       })}
                                   </span>
                                <span className="text-header mr-2">สาขาที่</span>
                                {[0, 0, 0, 0, 0].map((value, index) => {
                                    return (
                                        <span key={'branchNum' + index}
                                              className="border-branch-number">{value}</span>
                                    )
                                })}
                            </div>
                            <div className="header-right">แผ่นที่..{this.props.page}..ในจำนวน..{this.props.total_page}..แผ่น</div>

                            <table className="table table-bordered mt-2">
                                <tbody>
                                <tr>
                                    <th rowSpan="3" className="text-header-center">ลำดับที่</th>
                                    <th colSpan="4" className="text-header-center" width={"26%"}>
                                        <span className="text-th-bold">เลขประจำตัวผู้เสียภาษีอากร</span>
                                        (ของผู้มีเงินได้)
                                    </th>
                                    <th colSpan="2" className="text-header-center">สาขาที่</th>
                                    <th colSpan="7" className="text-header-center">รายละเอียดเกี่ยวกับการจ่ายเงิน</th>
                                    <th colSpan="7" className="text-header-center" width="200">
                                        รวมเงินภาษีที่หักและ <br/>นำส่งในครั้งนี้
                                    </th>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <span className="text-th-bold">ชื่อผู้มีเงินได้</span> (ให้ระบุให้ชัดเจนว่าเป็น
                                        นาย นาง นางสาว หรือยศ)
                                    </td>
                                    <td rowSpan="2" className="text-header-center" width="90">วัน เดือน ปี ที่จ่าย</td>
                                    <td rowSpan="2" className="text-header-center text-th-number" width="200">
                                        <img src={process.env.PUBLIC_URL + '/images/print/number-one.png'}
                                             alt="correct-signal" className="number"/>
                                        <span className="text-th-bold"> ประเภทเงินได้</span><br/>
                                        (ถ้ามากกว่าหนึ่งประเภทให้กรอกเรียงลงไป)
                                    </td>
                                    <td rowSpan="2" className="text-header-center" width={50}>อัตราภาษี <br/>ร้อยละ</td>
                                    <td colSpan="4" rowSpan="2" className="text-header-center" width={360}>
                                        จำนวนเงินที่จ่ายแต่ละประเภท <br/>เฉพาะคนหนึ่งๆ ในครั้งนี้
                                    </td>
                                    <td colSpan="4" rowSpan="2" className="text-header-center" width="300">จำนวนเงิน</td>
                                    <td rowSpan="2" className="text-header-center text-land p-0">
                                        เงื่อนไข <img src={process.env.PUBLIC_URL + '/images/print/number-two.png'}
                                                      alt="correct-signal" className="number"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <span className="text-th-bold">ที่อยู่ของผู้มีเงินได้</span> (ให้ระบุเลขที่
                                        ตรอก/ซอย ถนน ตำ บล/แขวง อำ เภอ/เขต จังหวัด)
                                    </td>
                                </tr>

                                {[0, 1, 2, 3, 4, 5].map((index) => {
                                    let tax_id = ["\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0"];
                                    let branch_num = ["\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0"];
                                    let issued_date = "\u00a0";
                                    let wht_rate = 0;
                                    let condition = 1;
                                    let description = [];
                                    let rate_list = [];
                                    let total_tax_list = [];
                                    let total_list = [];
                                    let isSliceData = false;
                                    let order = 0;
                                    let whitespace = "\u00a0\u00a0";
                                    let PayRecordSum = 0
                                    let OtherExpenseSum = 0

                                    if (this.props.data_array[index]) {
                                
                                        if(this.props.data_array[index].isSliceData){
                                            isSliceData = this.props.data_array[index].isSliceData
                                        }
                                        if(this.props.data_array[index].order){
                                            order = this.props.data_array[index].order
                                        }
                                        if (this.props.data_array[index].node.contact.taxIdNumber) {
                                            tax_id = this.props.data_array[index].node.contact.taxIdNumber.split('');
                                            branch_num = [0, 0, 0, 0, 0];
                                        }

                                        issued_date = format(this.props.data_array[index].node.issuedDate, 'DD/MM/YYYY');

                                        if (this.props.data_array[index].node.otherExpense) {

                                            if (this.props.data_array[index].node.otherExpense.otherExpenseTransaction.edges[0].node.productAndService) {
                                                condition = this.props.data_array[index].node.otherExpense.otherExpenseTransaction.edges[0].node.productAndService.typeOfTax
                                            }
                                            
                                            this.props.data_array[index].node.otherExpense.otherExpenseTransaction.edges.map((payRecord)=>{
                                                if (payRecord.node.whtRate) {
                                                    OtherExpenseSum += payRecord.node.preTaxAmount
                                                    total_list.push(payRecord.node.preTaxAmount)
                                                    // description.push(payRecord.node.description)
                                                    description.push(payRecord.node.typeOfIncomeOther ? payRecord.node.typeOfIncomeOther : payRecord.node.description)
                                                    rate_list.push(payRecord.node.whtRatePercent)
                                                    total_tax_list.push(payRecord.node.whtRate)
                                                }
                                            })

                                        }else if (this.props.data_array[index].node.payRecordGroup) {

                                            if (this.props.data_array[index].node.payRecordGroup.payRecord.edges[0].node.purchaseRecord.productAndService) {
                                                condition = this.props.data_array[index].node.payRecordGroup.payRecord.edges[0].node.purchaseRecord.productAndService.typeOfTax
                                            }

                                            this.props.data_array[index].node.payRecordGroup.payRecord.edges.map((payRecord)=>{
                                                if (payRecord.node.purchaseRecord.whtRate) {
                                                    PayRecordSum += getTotalpayRecord(payRecord);
                                                    total_list.push(getTotalpayRecord(payRecord))
                                                    // description.push(payRecord.node.purchaseRecord.description)
                                                    description.push(payRecord.node.purchaseRecord.typeOfIncomeOther ? payRecord.node.purchaseRecord.typeOfIncomeOther : payRecord.node.purchaseRecord.description)
                                                    total_tax_list.push(payRecord.node.whtAmount)
                                                    if (payRecord.productAndService) {
                                                        let product = payRecord.productAndService;
                                                        rate_list.push(product.taxRate ? product.taxRate : product.taxRateOther)
                                                    }else{
                                                        rate_list.push(payRecord.node.purchaseRecord.whtRatePercent)
                                                    }
                                                }
                                            })

                                        }else if (this.props.data_array[index].node.prepaidDeposit) {
                                            this.props.data_array[index].node.prepaidDeposit.prepaidDepositTransaction.edges.map((payRecord)=>{
                                                if (payRecord.node.whtRatePercent) {
                                                    total_list.push(payRecord.node.price)
                                                    description.push(payRecord.node.description)
                                                    rate_list.push(payRecord.node.whtRatePercent)
                                                    total_tax_list.push(payRecord.node.whtAmount)
                                                }
                                            })
                                        }

                                        wht_rate = this.props.data_array[index].node.total
                                    }
                                    sum_total_paid += PayRecordSum + OtherExpenseSum + (this.props.data_array[index]?.node?.totalPrepaidDeposit || 0);
                                    sum_wht_rate += wht_rate;
                                    return (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td rowSpan="3" className="text-center align-top" width={"10px"}>{(this.props.data_array[index] && !isSliceData) && order}</td>
                                                <td colSpan="4" className="no-border-bottom no-border-right">
                                                    {
                                                        tax_id.map((value, index) => {
                                                            
                                                            if (index === 1 || index === 5 || index === 10 || index === 12) {
                                                                return (
                                                                    <React.Fragment key={'rowTaxNum' + index}>
                                                                        <span>-</span>
                                                                        <span
                                                                            className="border-id-number">{isSliceData ? whitespace : value}</span>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                            return (
                                                                <span className="border-id-number"
                                                                      key={'rowTaxNum' + index}>{isSliceData ? whitespace : value}</span>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td colSpan="2" className="text-header-center no-border-bottom">
                                                    {
                                                        branch_num.map((value, v_index) => {
                                                            return (
                                                                <span key={'rowBranchNum' + v_index}
                                                                      className="border-branch-number">{isSliceData ? whitespace : value}</span>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot text-center">{!isSliceData ? issued_date : "\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    {description[0]?
                                                            <p className="border-dot" style={this.dynamicFont()} >{description[0].substr(0, 50)}</p>:
                                                            <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                </td>
                                                <td className="no-border-bottom text-center">
                                                    {rate_list[0]?<p className="border-dot">{numberWithComma(rate_list[0], '-')}</p>:
                                                        <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td colSpan="3" className="no-border-bottom text-right">
                                                    {total_list[0]?<p className="border-dot">{getNumberSplitDecimal(total_list[0], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td className="no-border-bottom text-left" width={"2%"}>
                                                    {total_list[0]?<p className="border-dot">{getNumberSplitDecimal(total_list[0], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td colSpan="3" className="no-border-bottom text-right">
                                                    {total_tax_list[0]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[0], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td className="no-border-bottom text-left" width={"2%"}>
                                                    {total_tax_list[0]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[0], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}

                                                </td>
                                                <td className="no-border-bottom text-center">
                                                    {this.props.data_array[index]?<p className="border-dot">{condition? condition:'1'}</p>:
                                                    <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                            </tr>

                                            <tr>
                                                {(this.props.data_array[index] && !isSliceData) ?
                                                <React.Fragment>
                                                    <td width="180" className="no-border-bottom no-border-right">
                                                        { this.isTypeOf(this.props.data_array[index].node.contact.typeOfSupplier) ? 
                                                        <p className="border-dot">ชื่อ {this.props.data_array[index].node.contact.firstName} </p> 
                                                            : <p className="border-dot">ชื่อ {this.props.data_array[index].node.contact.name} </p>
                                                        }
                                                    </td>
                                                    <td colSpan="5" className="no-border-bottom">
                                                        { this.isTypeOf(this.props.data_array[index].node.contact.typeOfSupplier) ? 
                                                        <p className="border-dot">ชื่อสกุล {this.props.data_array[index].node.contact.lastName}</p> 
                                                            : <p className="border-dot">ชื่อสกุล</p>}
                                                    </td>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <td width="180" className="no-border-bottom no-border-right">
                                                        <p className="border-dot">ชื่อ</p>
                                                    </td>
                                                    <td colSpan="5" className="no-border-bottom">
                                                        <p className="border-dot">ชื่อสกุล</p>
                                                    </td>
                                                </React.Fragment>
                                            }

                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    {description[1]?
                                                            <p className="border-dot" style={this.dynamicFont()} >{description[1].substr(0, 50)}</p>:
                                                            <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                </td>
                                                <td className="no-border-bottom text-center">
                                                    {rate_list[1]?<p className="border-dot">{numberWithComma(rate_list[1], '-')}</p>:
                                                        <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td colSpan="3" className="no-border-bottom text-right">
                                                    {total_list[1]?<p className="border-dot ">{getNumberSplitDecimal(total_list[1], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td className="no-border-bottom text-left ">
                                                    {total_list[1]?<p className="border-dot">{getNumberSplitDecimal(total_list[1], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td colSpan="3" className="no-border-bottom text-right">
                                                    {total_tax_list[1]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[1], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td className="no-border-bottom text-left">
                                                    {total_tax_list[1]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[1], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td className="no-border-bottom text-center">
                                                    {total_tax_list[1]&&this.props.data_array[index]?<p className="border-dot">{condition? condition:'1'}</p>:
                                                        <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="6">
                                                    {
                                                        (this.props.data_array[index]&& !isSliceData) ?
                                                            <p className="border-dot">ที่อยู่
                                                                {" "+this.props.data_array[index].node.contact.registeredAddress + " "}
                                                                {this.props.data_array[index].node.contact.registeredDistrict && this.getPrefix("district", this.props.data_array[index].node.contact.registeredPostalCode) + this.props.data_array[index].node.contact.registeredDistrict + " "}
                                                                {this.props.data_array[index].node.contact.registeredCity && this.getPrefix("city", this.props.data_array[index].node.contact.registeredPostalCode) + this.props.data_array[index].node.contact.registeredCity + " "}
                                                                {this.props.data_array[index].node.contact.registeredProvince && this.getPrefix("province", this.props.data_array[index].node.contact.registeredPostalCode) + this.props.data_array[index].node.contact.registeredProvince + " "}
                                                                {" "+this.props.data_array[index].node.contact.registeredPostalCode + " "}
                                                            </p> :
                                                            <p className="border-dot">ที่อยู่</p>
                                                    }
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td>
                                                    {description[2]?
                                                            <p className="border-dot" style={this.dynamicFont()} >{description[2].substr(0, 50)}</p>:
                                                            <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                </td>
                                                <td>
                                                    {rate_list[2]?<p className="border-dot text-center">{numberWithComma(rate_list[2], '-')}</p>:
                                                        <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td colSpan="3" className="text-right">
                                                    {total_list[2]?<p className="border-dot">{getNumberSplitDecimal(total_list[2], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td className='text-left'>
                                                    {total_list[2]?<p className="border-dot">{getNumberSplitDecimal(total_list[2], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td colSpan="3" className="text-right">
                                                    {total_tax_list[2]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[2], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td className='text-left' > 
                                                    {total_tax_list[2]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[2], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                                <td className="text-center" >
                                                    {total_tax_list[2]&&this.props.data_array[index]?<p className="border-dot">{condition? condition:'1'}</p>:
                                                        <p className="border-dot">{"\u00a0"}</p>}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}

                                <tr>
                                    <td colSpan="10" className="text-right"><span className="text-th-bold">รวม</span>ยอดเงินได้และภาษีที่นำส่ง (นำไปรวมกับ<span className="text-th-bold">ใบแนบ ภ.ง.ด.3</span> แผ่นอื่น (ถ้ามี))
                                    </td>
                                    <td className="text-right" colSpan="3">{sum_total_paid && getNumberSplitDecimal(sum_total_paid, "number")}</td>
                                    <td className="text-left">{sum_total_paid && getNumberSplitDecimal(sum_total_paid, "decimal")}</td>
                                    <td className="text-right" colSpan="3">{sum_wht_rate && getNumberSplitDecimal(sum_wht_rate, "number")}</td>
                                    <td className="text-left">{sum_wht_rate && getNumberSplitDecimal(sum_wht_rate, "decimal")}</td>
                                    <td/>
                                </tr>
                                <tr>
                                    <td colSpan="8">
                                        <div className="row mt-1">
                                            <div className="col">
                                                <i>(ให้กรอกลำดับที่ต่อเนื่องกันไปทุกแผ่นตามเงินได้แต่ละประเภท)</i></div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-1">
                                                <strong><u>หมายเหตุ</u></strong>
                                            </div>
                                            <img src={process.env.PUBLIC_URL + '/images/print/number-one.png'}
                                                 alt="correct-signal" className="number-foot ml-4"/>
                                            <div className="col">
                                                ให้ระบุว่าจ่ายเป็นค่าอะไร เช่น ค่าเช่าอาคาร ค่าสอบบัญชี ค่าทนายความ
                                                ค่าวิชาชีพของแพทย์
                                                ค่าก่อสร้าง รางวัล ส่วนลดหรือประโยชน์ใดๆ เนื่องจากการส่งเสริมการขาย
                                                รางวัลในการประกวด
                                                การแข่งขัน การชิงโชค ค่าจ้างแสดงภาพยนตร์ ร้องเพลงดนตรี ค่าจ้างทำของ
                                                ค่าจ้างโฆษณา ค่าขนส่งสินค้า ฯลฯ
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-1"/>
                                            <img src={process.env.PUBLIC_URL + '/images/print/number-two.png'}
                                                 alt="correct-signal" className="number-foot ml-4"/>
                                            <div className="col">
                                                เงื่อนไขการหักภาษีให้กรอกดังนี้
                                                <p className="mb-0 mt-1"><span className="ballot-box">&#9745;</span> หัก ณ ที่จ่าย กรอก 1</p>
                                                <p className="mb-0 mt-1"><span className="ballot-box">&#9745;</span> ออกให้ตลอดไป กรอก 2</p>
                                                <p className="mb-0 mt-1"><span className="ballot-box">&#9745;</span> ออกให้ครั้งเดียว กรอก 3</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td colSpan="11">
                                        <div className="row mt-5">
                                            <div className="col-3 div-seal pr-0">
                                                <div className="border-seal">
                                                    <span className="text-center">ประทับตรานิติบุคคล (ถ้ามี)</span>
                                                </div>
                                            </div>
                                            <div className="col-9 pl-0">
                                                <div className="row">
                                                    <div className="col text-center">
                                                        <p className="mb-2">ลงชื่อ..................................................................................ผู้จ่ายเงิน</p>
                                                        <p className="mb-2">(..........................................................................)</p>
                                                        <p className="mb-2">ตำแหน่ง........................................................................................</p>
                                                        <p className="mb-2">ยื่นวันที่.......................เดือน...................................พ.ศ....................</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div className="row mt-1">
                                <div className="col">
                                    <strong><i>สอบถามข้อมูลเพิ่มเติมได้ที่ศูนย์สารนิเทศสรรพากร RD Intelligence Center
                                        โทร. 1161</i></strong>
                                </div>
                                <div className="col text-right">
                                    <strong>พิมพ์ มี.ค. 2560</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithholdingAttachmentTax3WithDataPrint;
