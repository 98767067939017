import { format } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import ExportFixReportPDF from '../export/fixRepairPDF';
import ExportFixReportPDFEn from '../export/fixRepairPDFen';


const ModalReportRepairEditBeforePrint = (props) => {
    const [dataExport, SetDataExport] = useState(props.dataExport)
    const [nameCreate, SetNameCreate] = useState("")

    useEffect(() => {
        let nameRequest = props.dataExport?.tenant_contact ?
        props.dataExport?.tenant_contact?.first_name + " " + props.dataExport?.tenant_contact?.last_name :
        props.dataExport?.juristic.first_name + " " + props.dataExport?.juristic.last_name


        let dataStaff =  props.dataExport.worksheet[0]?.project_staff ? props.dataExport.worksheet[0]?.project_staff?.name_title + "" +
        props.dataExport.worksheet[0]?.project_staff?.name_staff + " " +
        props.dataExport.worksheet[0]?.project_staff?.last_name_staff : ''

        let dataDetails =   props.dataExport.working_type + ", " + props.dataExport.details + ", " + props.dataExport.material
        
        let data = _.cloneDeep(props.dataExport)
        _.set(data, `createName`, nameRequest)
        _.set(data, `dataStaff`, dataStaff)
        _.set(data, `dataDetails`, dataDetails)

        SetDataExport(data)

        if (props.dataExport?.tenant_contact) {
            SetNameCreate(props.dataExport?.tenant_contact?.first_name + " " + props.dataExport?.tenant_contact?.last_name)
        }

        if (props.dataExport.juristic && !props.dataExport?.tenant_contact) {
            SetNameCreate(props.dataExport?.juristic?.first_name + " " + props.dataExport?.juristic?.last_name)
        }

    }, [props.dataExport])

    return (
        <React.Fragment>
            <img src={process.env.PUBLIC_URL + '/images/icons/addnote.png'} className='ml-3 cursor'
                data-toggle="modal" data-target={`.modalEditDataBeforePrintFixRepair${props.doc_nameNumber}`} />

            <div className={"colorUse modal fade bd-example-modal-lg modalEditDataBeforePrintFixRepair" + props.doc_nameNumber} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog  modal-lg" role="document" style={{ marginTop: '3%' }}>
                    <div className="modal-content">
                        <Translation>
                            {(t, lng) =>
                             
                                <React.Fragment>
                                    <div className="modal-header" style={{ borderBottom: '0', padding: "15px 30px" }}>
                                        <h2 className="modal-title text_171B21" style={{ fontSize: "24px" }}>
                                            {t("fix_request:Header_Edit")} <br />
                                            <p className='fs-18 text_171B21'>({t("fix_request:Unable_to_view_past_data")})</p>
                                        </h2>
                                        <button type="button" className="close" data-dismiss="modal">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <div className='modal-body'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <p className='fs-18 fw-400 text_171B21 text-center'>{t("fix_request:repair_form")}</p>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:part_1")}</p>
                                            </div>

                                            <div className='col-6'>

                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:item_1")} <input type={'text'} className='form-control w300'
                                                    onKeyUp={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `step1Name`, e.target.value))}
                                                    defaultValue={nameCreate} /></p>
                                            </div>
                                            <div className='col-6'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("residential:House No.")} <input type={'text'} className='form-control w300' value={dataExport.residential?.name || ""} readOnly /></p>
                                            </div>

                                            <div className='col-12'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:item_2")} <input type={'text'} className='form-control w300'
                                                    defaultValue={dataExport.tenant_contact?.phone || dataExport.juristic?.phone}
                                                    onKeyUp={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `tenant_contact.phone`, e.target.value))} /></p>
                                            </div>

                                            <div className='col-6'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:item_3")} <input type={'text'} className='form-control w300' value={ dataExport?.juristic ?(dataExport?.juristic?.first_name || "") + " " + (dataExport?.juristic?.last_name || "") : nameCreate } readOnly /></p>
                                            </div>
                                            <div className='col-3'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:time")} <input type={'text'} className='form-control w100' value={format(new Date(dataExport?.added), "HH:mm น.")} readOnly /></p>
                                            </div>
                                            <div className='col-3'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:date")} <input type={'text'} className='form-control w100' value={format(new Date(dataExport?.added), "DD/MM/YYYY")} readOnly /></p>
                                            </div>

                                            <div className='col-12'>
                                                <p className='fs-18 text_171B21'>{t("fix_request:item_4")} <input type={'text'} className='form-control w700'
                                                    onKeyUp={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `dataDetails`, e.target.value))}
                                                    defaultValue={dataExport.dataDetails} /></p>
                                            </div>

                                            <div className='col-12'>
                                                <p className='fs-18 text_171B21'>{t("fix_request:note")} <input type={'text'} className='form-control w700'
                                                    onKeyUp={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `step4_2`, e.target.value))} /></p>
                                            </div>

                                            <div className='col-6'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:item_5")} <input type={'text'} className='form-control w300'
                                                    onKeyUp={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `dataStaff`, e.target.value))}
                                                    defaultValue={dataExport.dataStaff} /></p>
                                            </div>
                                            <div className='col-6'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:position")} <input type={'text'} className='form-control w300'
                                                    onKeyUp={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `worksheet[0].project_staff.role`, e.target.value))}
                                                    defaultValue={dataExport.worksheet[0]?.project_staff?.role} /></p>
                                            </div>

                                            <div className='col-12'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:ข้อความเพิ่มเติม")}
                                                    <textarea className='form-control w700 h80' onKeyUp={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `worksheet[0].description`, e.target.value))}
                                                        defaultValue={dataExport.worksheet[0].description}>
                                                    </textarea>
                                                </p>
                                            </div>

                                            <div className='col-12'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:part_2")}</p>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:item_6")}
                                                    <textarea className='form-control w700 h80' onKeyUp={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `reason6`, e.target.value))}
                                                        defaultValue={""}>
                                                    </textarea>
                                                </p>
                                            </div>

                                            <div className='col-12'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:item_7")}
                                                    <textarea className='form-control w700 h80' onKeyUp={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `step7`, e.target.value))}
                                                        defaultValue={""}>
                                                    </textarea>
                                                </p>
                                            </div>

                                            <div className='col-6'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:item_8")} <input type={'checkbox'} className=''
                                                    defaultChecked={dataExport.worksheet[0]?.technician === "inside" ? 'checked' : ''}
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `worksheet[0].technician`, e.target.checked ? "inside" : ""))} /> {t("fix_request:in_house_Handyman")} </p>
                                            </div>

                                            <div className='col-6'>
                                                <p className='fs-18 fw-400 text_171B21 mb-0'><input type={'checkbox'} className=''
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `insure_status`, e.target.checked ? "have" : "not"))}
                                                    defaultChecked={dataExport.insure_status === "have" ? "checked" : false} /> {t("fix_request:warranty")}</p>

                                                <p className='fs-18 fw-400 text_171B21 mb-0'><input type={'checkbox'} className=''
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `worksheet[0].technician`, e.target.checked ? "outside" : ""))}
                                                    defaultChecked={dataExport.worksheet[0]?.technician === "outside" ? "checked" : false} /> {t("fix_request:commercial_Handyman")}</p>

                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:no")} <input type={'text'} className='form-control w300'
                                                    onKeyUp={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `numberTechnicianChoices`, e.target.value))} /></p>
                                            </div>



                                            <div className='col-12'>
                                                <p className='fs-18 fw-400 text_171B21 mb-0'>{t("fix_request:item_9")} <input type={'text'} className='form-control w700'
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `worksheet_status`, e.target.value))}
                                                    defaultValue={""} /></p>
                                            </div>

                                            <div className='col-6'>
                                                <p className='fs-18 fw-400 text_171B21 mb-0'>{t("fix_request:evaluator")} <input type={'text'} className='form-control w300'
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `job_assigned_by_staff_name`, e.target.value))}
                                                    defaultValue={""} /></p>
                                                <p className='fs-18 fw-400 text_171B21 mb-0'>{t("fix_request:date")} <input type={'date'} className='form-control w300'
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `job_inspection_date`, e.target.value))}
                                                    defaultValue={""} /></p>
                                            </div>

                                            <div className='col-6'>
                                                <p className='fs-18 fw-400 text_171B21 mb-0'>{t("fix_request:approver")} <input type={'text'} className='form-control w300'
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `job_resolved_by_staff_name`, e.target.value))}
                                                    defaultValue={""} /></p>
                                                <p className='fs-18 fw-400 text_171B21 mb-0'>{t("fix_request:date")} <input type={'date'} className='form-control w300'
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `job_resolved_date`, e.target.value))}
                                                    defaultValue={""} /></p>
                                            </div>

                                            <div className='col-12 mt-3'>
                                                <p className='fs-18 fw-400 text_171B21'> {t("fix_request:part_3")}</p>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:item_10")}
                                                    <textarea className='form-control w700 h80' onKeyUp={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `remark`, e.target.value))}
                                                        defaultValue={dataExport.remark}>
                                                    </textarea>
                                                </p>
                                            </div>


                                            <div className='col-12'>
                                                <p className='fs-18 fw-400 text_171B21'>{t("fix_request:item_11")}</p>
                                            </div>

                                            <div className='col-6'>
                                                <p className='fs-18 fw-400 text_171B21 mb-0'> <input type={'checkbox'} className=''
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `status`, e.target.checked ? "finish" : "not_pass"))}
                                                    defaultChecked={dataExport.status === "finish"} /> {t("fix_request:pass")}</p>
                                            </div>
                                            <div className='col-6'>
                                                <p className='fs-18 fw-400 text_171B21 mb-0'> <input type={'checkbox'} className=''
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `status`, e.target.checked ? "not_pass" : "finish"))}
                                                    defaultChecked={dataExport.status === "not_pass"} /> {t("fix_request:not_approve")}</p>
                                            </div>

                                            <div className='col-6'>
                                                <p className='fs-18 fw-600 text_171B21 mb-0'>{t("fix_request:inspector")} <input type={'text'} className='form-control w300'
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `job_closed_by_staff_name`, e.target.value))}
                                                    defaultChecked={''} /></p>
                                            </div>

                                            <div className='col-6'>
                                                <p className='fs-18 fw-600 text_171B21 mb-0'>{t("fix_request:date")} <input type={'date'} className='form-control w300'
                                                    onChange={(e) => SetDataExport(_.set(_.cloneDeep(dataExport), `job_closed_date`, e.target.value))}
                                                    defaultChecked={""} /></p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="modal-footer" style={{ borderTop: '0' }}>
                                        <button type="button" className="btn btn-primary-outlie"
                                            style={{ width: "112px", height: "48px" }}
                                            data-dismiss="modal">{t("Allaction:cancel")}</button>
                                        {
                                            lng.lng === "en" ?
                                                <ExportFixReportPDFEn
                                                    dataExport={dataExport}
                                                    selfProject={props.selfProject}
                                                    checkBox={props.checkBox}
                                                    checkboxTrue={props.checkboxTrue}
                                                    editDataExport={true}
                                                    namePrint={props.namePrint}
                                                />
                                                :
                                                <ExportFixReportPDF
                                                    dataExport={dataExport}
                                                    selfProject={props.selfProject}
                                                    checkBox={props.checkBox}
                                                    checkboxTrue={props.checkboxTrue}
                                                    editDataExport={true}
                                                    namePrint={props.namePrint}
                                                />
                                        }

                                    </div>
                                </React.Fragment>
                            }
                        </Translation>
                    </div>
                </div>
            </div>
        </React.Fragment>


    );

}

export default ModalReportRepairEditBeforePrint

