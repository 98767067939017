/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BatchReceiveStatus = "ACTIVE" | "VOID" | "%future added value";
export type manageBatchQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
  search?: ?string,
  first?: ?number,
  last?: ?number,
  status?: ?string,
|};
export type manageBatchQueryResponse = {|
  +allBatchReceive: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +batchDate: any,
        +startRt: ?string,
        +endRt: ?string,
        +creator: ?string,
        +voider: ?string,
        +remark: ?string,
        +status: BatchReceiveStatus,
        +added: any,
        +receiveCount: ?number,
        +importReceive: ?{|
          +id: string,
          +added: any,
          +allRow: number,
        |},
      |}
    |}>,
    +totalCount: ?number,
  |},
  +selfProject: ?{|
    +name: string,
    +logo: ?string,
  |},
|};
export type manageBatchQuery = {|
  variables: manageBatchQueryVariables,
  response: manageBatchQueryResponse,
|};
*/


/*
query manageBatchQuery(
  $startDate: Date
  $endDate: Date
  $search: String
  $first: Int
  $last: Int
  $status: String
) {
  allBatchReceive(startDate: $startDate, endDate: $endDate, search: $search, first: $first, last: $last, status: $status) {
    edges {
      node {
        id
        docNumber
        batchDate
        startRt
        endRt
        creator
        voider
        remark
        status
        added
        receiveCount
        importReceive {
          id
          added
          allRow
        }
      }
    }
    totalCount
  }
  selfProject {
    name
    logo
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "endDate"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    },
    {
      "kind": "Variable",
      "name": "startDate",
      "variableName": "startDate"
    },
    {
      "kind": "Variable",
      "name": "status",
      "variableName": "status"
    }
  ],
  "concreteType": "BatchReceiveNodeConnection",
  "kind": "LinkedField",
  "name": "allBatchReceive",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BatchReceiveNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BatchReceiveNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "batchDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startRt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endRt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creator",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voider",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remark",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "receiveCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ImportReceiveNode",
              "kind": "LinkedField",
              "name": "importReceive",
              "plural": false,
              "selections": [
                (v6/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allRow",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "manageBatchQuery",
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "manageBatchQuery",
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f466d62c8a18b663209429ee8289faa",
    "id": null,
    "metadata": {},
    "name": "manageBatchQuery",
    "operationKind": "query",
    "text": "query manageBatchQuery(\n  $startDate: Date\n  $endDate: Date\n  $search: String\n  $first: Int\n  $last: Int\n  $status: String\n) {\n  allBatchReceive(startDate: $startDate, endDate: $endDate, search: $search, first: $first, last: $last, status: $status) {\n    edges {\n      node {\n        id\n        docNumber\n        batchDate\n        startRt\n        endRt\n        creator\n        voider\n        remark\n        status\n        added\n        receiveCount\n        importReceive {\n          id\n          added\n          allRow\n        }\n      }\n    }\n    totalCount\n  }\n  selfProject {\n    name\n    logo\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '35672c1949b31ff4ba4194ef5efffdb6';

module.exports = node;
