import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import ContactReportList from "../contactReportList";
import ReceiptDepositReportTable from "./receiptDepositReportTable";
import ExportReceiptDeposit from "./server_export";
import Loading from '../../../../libs/loading';
import _ from "lodash";
import postApiUseing from '../../../../libs/postApiUseing';
import { fetchQuery } from "relay-runtime";
import Swal from "sweetalert2";
import './receiptDepositReportPDF.scss'
import BackButtonIcon from "../../../../components/BackBtn/indexBack";
import i18next from "i18next";

const query = graphql`
  query receiptDepositReportQuery(
    $first: Int
    $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $newEndDate: Date
    $search: String
    $payment_channel: String
    $status: String
    $contact: String
    $order: String
  ) {
    receiptDepositViewer {
      allReceiptDeposit(
        first: $first
        last: $last
        startDate: $start_date
        endDate: $end_date
        search: $search
        statusNew: $status
        paymentChannel: $payment_channel
        contact: $contact
        order: $order
        report:true
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            docNumber
            issuedDate
            total
            status
            payGroup
            firstName
            lastName
            name
            contact {
              refNumber
              name
              residential {
                name
              }
              firstName
              lastName
              typeOfContact
            }
            receiptDepositTransaction {
              edges {
                node {
                  id
                  description
                  price
                  balance
                }
              }
            }
            receiptDepositTransactionUsed(useDate_Lte:$newEndDate){
              edges {
                node {
                  id
                  amount
                }
              }
            }
            bankAccountTransaction {
              totalCount
            }
            cashTransaction {
              totalCount
            }
            chequeDeposit {
              totalCount
            }
          }
        }
      }
      summaryReceiptDeposit(
        startDate: $start_date
        endDate: $end_date
        search: $search
        statusNew: $status 
        paymentChannel: $payment_channel
        contact: $contact
      )
      summaryUsePartialReceiptDeposit(
        search: $search
        startDate: $start_date
        endDate: $end_date 
        paymentChannel: $payment_channel 
        contact: $contact 
        statusNew: $status 
      )
    }
  }
`;

class ReceiptDepositReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);

    this.state.current_date = new Date();
    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.min_date = min_date;
    this.state.customer_type = "";
    this.state.search = "";
    this.state.search_input = "";
    this.state.status = "";
    this.state.temp_status = "";
    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.payment_channel = "";
    this.state.temp_payment_channel = "";
    this.state.checkFetchState = false;
    this.state.dataResult = "";
    this.state.loading = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchDataQuery = this.fetchDataQuery.bind(this);
    this.checkLoadingExcel = this.checkLoadingExcel.bind(this);

  }

  componentWillMount() {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "receiptDepositReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

    this.fetchDataQuery();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.first !== this.state.first || prevState.last !== this.state.last) {
      this.fetchDataQuery();
    }
  }
  
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      contact: this.state.temp_contact,
      status: this.state.temp_status,
      payment_channel: this.state.temp_payment_channel,
    },
      () => {
        this.fetchDataQuery();
        this.goFirst();
      });
    // super.goFirst()
  }

  onClickGetData(data) {
    this.props.history.push(`/accounting/report/account-receivable/receipt_deposit/PDF`, { data: data })
  }

  fetchDataQuery() {
    this.setState({
      checkFetchState: false,
      loading: true,
    })
    fetchQuery(environment, query, {
      first: this.state.first,
      last: this.state.last,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      newEndDate: this.state.end_date.toISOString().slice(0, 10),
      search: this.state.search,
      status: this.state.status,
      payment_channel: this.state.payment_channel,
      contact: this.state.contact,
      order: "doc_number",
    }).then((item) => {
      this.setState({ dataResult: item, checkFetchState: true, loading: false });
    }).catch(error => {
      Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
    });
  }

  checkLoadingExcel(status) {
    this.setState({ checkFetchState: status });
  }

  render() {
    let summaryUsePartialReceiptDeposit = null
    if (this.state.dataResult) {
      if (this.state.dataResult.receiptDepositViewer?.summaryUsePartialReceiptDeposit) {
        summaryUsePartialReceiptDeposit = JSON.parse(this.state.dataResult.receiptDepositViewer?.summaryUsePartialReceiptDeposit)
      }
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-receivable"}
                  LinkText={i18next.t("receivable_dashboard:Advance Payment Receipt Report")}
                  boxHtmlText={
                    <div className={`col`} id="receiptDepositReport">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_receivable_print",
                      }) &&
                        <div className={`${this.state.checkFetchState ? "dropdown" : ''} printReport`}>
                          {!this.state.checkFetchState ?
                            <React.Fragment>
                              <button type="button" className="btnPrint">
                                <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                <Translation>{t => t("meter:Preparing information")}</Translation>
                              </button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                                <Translation>{t => t("PageList:Print")}</Translation>
                              </button>
                            </React.Fragment>
                          }
                          <div className={`dropdown-menu`}>
                            <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state)}>PDF</a>
                            <ExportReceiptDeposit state={this.state} checkLoadingExcel={this.checkLoadingExcel} />
                          </div>
                        </div>
                      }
                    </div>
                  }
                />
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          minDate={this.state.temp_start_date}
                          // maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          maxDate={this.state.temp_end_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <ContactReportList
                            handleChange={this.handleChange}
                            customer_type={this.state.customer_type}
                            contact={this.state.temp_contact}
                          />
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("receiveReport:Payment Method")}</label>
                              <select
                                name="temp_payment_channel"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("receiveReport:All")}</option>
                                <option value="cash">{i18next.t("receiveReport:Cash")}</option>
                                <option value="bank">{i18next.t("receiveReport:Transfer")}</option>
                                <option value="cheque">{i18next.t("receiveReport:Cheque")}</option>
                                <option value="other">{i18next.t("receiveReport:Other")}</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 border-right">
                            <div className="form-group">
                              <label>{i18next.t("receipt_deposit:Status")}</label>
                              <select
                                name="temp_status"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("receipt_deposit:All")}</option>
                                <option value="active">{i18next.t("receipt_deposit:Active")}</option>
                                <option value="use_all">
                                {i18next.t("receipt_deposit:Use all")}
                                </option>
                                <option value="use_partial">
                                {i18next.t("receipt_deposit:Use partial")}
                                </option>
                                <option value="void">{i18next.t("receipt_deposit:Void")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.loading ?
                    <Loading />
                    :
                    this.state.dataResult &&
                    <React.Fragment>
                      <div className="row mt-3">
                        <div className="col-12">
                          <div className="card fade-up">
                            <div className="table-responsive">
                              <table className="table table-hover">
                                <thead className="thead-light">
                                  <tr>
                                    <th className="text-center">{i18next.t("ReceiptDepositReport:Order")}</th>
                                    <th className="text-center">
                                    {i18next.t("ReceiptDepositReport:Order Number")}
                                    </th>
                                    <th className="text-center">
                                    {i18next.t("ReceiptDepositReport:Date of Advance Receipt")}
                                    </th>
                                    <th>{i18next.t("ReceiptDepositReport:Room/House Number")}</th>
                                    <th>{i18next.t("ReceiptDepositReport:Name")}</th>
                                    <th>{i18next.t("ReceiptDepositReport:Details")}</th>
                                    <th className="text-right">{i18next.t("ReceiptDepositReport:Amount")}</th>
                                    <th className="text-right">{i18next.t("ReceiptDepositReport:Paid")}</th>
                                    <th className="text-right">{i18next.t("ReceiptDepositReport:Remaining")}</th>
                                    <th className="text-center">{i18next.t("ReceiptDepositReport:Payment Method")}</th>
                                    <th className="text-center">{i18next.t("ReceiptDepositReport:Status")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ReceiptDepositReportTable
                                    props={this.state.dataResult}
                                    state={this.state}
                                    use_partial={this.state.status === 'use_partial'}
                                    summaryUsePartialReceiptDeposit={summaryUsePartialReceiptDeposit}
                                  />
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={
                            this.state.dataResult?.receiptDepositViewer.allReceiptDeposit
                              .totalCount
                          }
                        />
                      </div>
                    </React.Fragment>
                  }

                </div>
              </div>

            }
          </Translation>

        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ReceiptDepositReport;
