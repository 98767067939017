import React, { Component } from 'react';
import SilvermanAgentStatus from './silvermanAgentStatus'
import SilvermanAgentLocation from './silvermanAgentLocation'
import SilvermanAgentDetail from './silvermanAgentDetail'
import SilvermanAgentPrice from './silvermanAgentPrice'
import environment from "../env/environment";
import {commitMutation} from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import {Redirect} from 'react-router';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import i18n from 'i18next';
import upload from '../libs/upload'
import _ from "lodash";
import './silvermanAgent.scss'
import i18next from 'i18next';
const $ = window.jQuery;

const query = graphql`
    query silvermanAgentCreateQuery($residential_id: ID!) {
        myUserAgent {
            id username
            tenant {
            id
            nameTitle
            firstName
            lastName
            householder(residential_Id:$residential_id){
                edges
                {
                    node {
                        id
                        residential{
                            id
                            name
                            district
                            province
                            project{
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
        }
    }
`;

const mutation = graphql`
    mutation silvermanAgentCreateMutation($input: CreateSilvermanAgentInput!){
        createSilvermanAgent (input: $input) {
        ok
        message
        }
    }
`;

class SilvermanAgentCreate extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            langauge: this.props.match.params.lang ? this.props.match.params.lang : "th",
            propertyType :'',

            visible: false,
            svmAgentStep1: true,
            svmAgentStep1Finish: false,

            svmAgentStep2: false,
            svmAgentStep2Finish: false,

            svmAgentStep3: false,
            svmAgentStep3Finish: false,

            svmAgentStep4: false,
            svmAgentStep4Finish: false,

            autoCheckData:false,
            autoCheckContact:false,
            autoCheckRemark:false,

            // agent:{
                userType:'',
                residentialType:'',
                residentialOther:'',
                announceSell:false, //ขาย
                announceForRent:false, //ให้เช่า
                announceBuy:false, //ซื้อ
                announceDown:false, //ขายดาวน์
                announceLease:false, //เซ้ง
                announceLookRent:false, // หาเช่า
                announceSellRent:false,
                province:'',
                district:'',
                projectName:'',
                area:'',
                topic:'',
                description:'',
                sizeArea:0.00,
                bedroom:0,
                bathroom:0,
                floor:"",
                floorSize:"",
                rai:0,
                ngan:0,
                squareWa:0,
                remarkPet:false,
                remarkForeignt:false,
                remarkDuplex:false,
                remarkPenthouse:false,
                remark:'',
                remarkHighFloor:false,
                contactName:'',
                callName:'',
                phone:'',
                line:'',
                email:'',
                silvermanPhone:false,
                silvermanLine:false,
                silvermanEmail:false,
                price:0.00,
                rentDate: null,
                rentPrice: 0.00,
                downPrice: 0.00,
                realPrice: 0.00,
                leasePrice: 0.00,
                buyPrice: 0.00,
                lookRentPrice: 0.00,
                thirdTerm: false,
                sixTerm: false,
                twelveTerm: false,
                silvermanAgentImage: [],
            // }

            districtUser:'',
            provinceUser:'',
            projectNameUser:'',

            redirectToList:false,
            //vadil
            showLengthAlert:false,
            showLengthProvincAlert:false,
            showLengthProjectNameAlert:false,
            showAlertResidentialType:false,
            announceAlert:false,
            topicAlert:false,
            sizeAreaAlert:false,
            bedroomBathroomFloorAlert:false,
            landAlert:false,
            onlyNumberAlert:false,
            onlyNumberBedroomBathroomFloorAlert:false,
            onlyNumberLandAlert:false,
            onlyNumberPhoneAlert:false,
            onlyNumberPrice:false,
            onlyNumberSocial:false,
            remarkAlert:false,
            remarkTextAlert:false,
            priceAlert:false,
            contactAlert:false,
            socialAlert:false,
            svmContactAlert:false,

            numRoom :''
        }

        this.onSetStepFinish = this.onSetStepFinish.bind(this)
        this.onSetStepFinish2 = this.onSetStepFinish2.bind(this)
        this.onSetStepFinish3 = this.onSetStepFinish3.bind(this)
        this.onSetAgentStatus = this.onSetAgentStatus.bind(this)
        this.onSetPropertyType = this.onSetPropertyType.bind(this)
        this.handleInputChangeCheckBox = this.handleInputChangeCheckBox.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputChangeReceive = this.handleInputChangeReceive.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onSelect = this.onSelect.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.deleteImage = this.deleteImage.bind(this)
        this.onSetAutoCheckData = this.onSetAutoCheckData.bind(this)
        this.onSetAutoCheckContact = this.onSetAutoCheckContact.bind(this)
        this.onSetAutoCheckRemark = this.onSetAutoCheckRemark.bind(this)
        this.checkNumber = this.checkNumber.bind(this)
        
    }

    onSetStepFinish (val) {
        if(!this.state.userType){        
        }else{
            this.setState({
                svmAgentStep1Finish:val,
                svmAgentStep1: false,
                svmAgentStep2: true,
            })
           
        }
       
    }

    onSetStepFinish2 (val) {
      let {
        projectName,
        residentialType,
        district,
        province,
        provinceUser,
        projectNameUser,
        districtUser,
        autoCheckData
    } = this.state;

        var checkBoxes = document.getElementsByClassName('myCheckBox');
        var isChecked = false;
        for (var i = 0; i < checkBoxes.length; i++) {
            if ( checkBoxes[i].checked ) {
                isChecked = true;
            };
        };

         if(!isChecked) {
            this.setState({
                announceAlert:true,
            }) 
        }else if (residentialType === '') {
            this.setState({
                showAlertResidentialType: true,
            }) 
        } 
         else if(autoCheckData === false && province === ''){
            this.setState({
                showLengthAlert:true
            })
        }else if(autoCheckData === false &&  district === ''){
            this.setState({
                showLengthProvincAlert:true
            })
        }else if(autoCheckData === false && projectName === ''){
            this.setState({
                 showLengthProjectNameAlert:true
            })
        }else if (autoCheckData){
            this.setState({
                svmAgentStep2Finish:val,
                svmAgentStep2: false,
                svmAgentStep3: true,
                showLengthAlert: false,
                showLengthProvincAlert:false,
                showLengthProjectNameAlert:false,
                showAlertResidentialType:false,
                announceAlert:false,
            })
        }
        else {
               this.setState({
                svmAgentStep2Finish:val,
                svmAgentStep2: false,
                svmAgentStep3: true,
                showLengthAlert: false,
                showLengthProvincAlert:false,
                showLengthProjectNameAlert:false,
                showAlertResidentialType:false,
                announceAlert:false,
            })
       } 
    }

    checkValiInput(val){
        if(val.length < 0){
            return false
        }
        return true
    }


    checkNumber(val){
        
        if(val || val === ''){
            const re = /^\d*(\.\d+)?$/
            if(val.match(re) || val === ""){
                return true
            }else{
                return false
            }
        }
    }

    onSetStepFinish3 (val) {
        let {
            topic,
            sizeArea,
            bedroom,
            bathroom,
            floor,
            rai,
            ngan,
            squareWa,
            autoCheckRemark
        } = this.state;

        var checkBoxes = document.getElementsByClassName('myRemark');
        var isChecked = false;
        for (var i = 0; i < checkBoxes.length; i++) {
            if ( checkBoxes[i].checked ) {
                isChecked = true;
            };
        };

        // if(topic === ''){
        //     this.setState({
        //         topicAlert:true
        //  })
        // }else 
        if(this.state.residentialType !== "land" && sizeArea <= 0){
            this.setState({
                sizeAreaAlert:true
            })
        }else if(this.state.residentialType !== "land" && !this.checkNumber(sizeArea)){
            this.setState({
                onlyNumberAlert:true
            })
        }else if(this.state.residentialType !== "land" && bedroom <= 0 ){
            this.setState({
                sizeAreaAlert:true
            })
        }else if(this.state.residentialType !== "land" && !this.checkNumber(bedroom)){
            this.setState({
                onlyNumberBedroomBathroomFloorAlert:true
            })
        }else if(this.state.residentialType !== "land" && bathroom <= 0 ){
            this.setState({
                sizeAreaAlert:true
            })
        }else if(this.state.residentialType !== "land" && !this.checkNumber(bathroom)){
            this.setState({
                onlyNumberBedroomBathroomFloorAlert:true
            })
        }else if(this.state.userType ==='owner' && this.state.floor === ''){
            this.setState({
                sizeAreaAlert:true
            })

        // }else if (this.state.residentialType === 'land' && parseInt(rai) <= 0  ){
        //     this.setState({
        //         landAlert:true
        //     })
        // }else if(this.state.residentialType === "land" && !this.checkNumber(rai)){
        //     this.setState({
        //         onlyNumberLandAlert:true
        //     })
        // }else if (this.state.residentialType === 'land' &&  parseInt(ngan) <= 0  ){
        //     this.setState({
        //         landAlert:true
        //     })
        // }else if(this.state.residentialType === "land" && !this.checkNumber(ngan)){
        //     this.setState({
        //         onlyNumberLandAlert:true
        //     })
        // }else if (this.state.residentialType === 'land' && parseInt(squareWa) <= 0 ){
        //     this.setState({
        //         landAlert:true
        //     })
        // }else if(this.state.residentialType === "land" && !this.checkNumber(squareWa)){
        //     this.setState({
        //             onlyNumberLandAlert:true
        //     })
        }else if(!isChecked) {
            this.setState({
                remarkAlert:true,
            }) 
        }
        else if(autoCheckRemark && this.state.remark === ''){
            this.setState({
                remarkTextAlert:true
            })
        }
        else{
            this.setState({
                svmAgentStep3Finish:val,
                svmAgentStep3: false,
                svmAgentStep4: true,
                topicAlert:false,
                sizeAreaAlert:false,
                onlyNumberAlert:false,
                bedroomBathroomFloorAlert:false,
                landAlert:false,
                onlyNumberLandAlert:false
            })
        }
        
    }

    onSetAgentStatus (val) {
        this.setState({
            userType: val
        })
    }

    onSetPropertyType(val){
        this.setState({
            residentialType: val,
            showAlertResidentialType:false
        })
    }

    onSetAutoCheckData(){
        this.setState({
            autoCheckData: !this.state.autoCheckData,
        })
    }


    onSetAutoCheckContact(){
        this.setState({
            autoCheckContact: !this.state.autoCheckContact,
        })
    }

    onSetAutoCheckRemark(){
        this.setState({
            autoCheckRemark: !this.state.autoCheckRemark,
        })
    }

    handleDateChange(val){
        this.setState({
            rentDate: val
        })
    }

    handleInputChange(e) {
        // let event = _.cloneDeep(e);
        // this.setState(prevState => {
        //         return _.set(
        //             _.cloneDeep(prevState),
        //             event.target.name,
        //             event.target.value
        //         );
        // });


        // if(e.target.name === 'sizeArea' ){
            
        //     this.setState({
        //         [e.target.name]: e.target.value.replace(/^\d*(\.\d+)?$/),
        //     });
        // }else{
            this.setState({
                [e.target.name]: e.target.value,
                showLengthProjectNameAlert:false,
                contactAlert:false,
                socialAlert:false,
                priceAlert:false,
                topicAlert:false,
                sizeAreaAlert:false,
                landAlert:false,
                remarkTextAlert:false,
                onlyNumberAlert:false,
                onlyNumberBedroomBathroomFloorAlert:false,
                onlyNumberLandAlert:false,
                onlyNumberPrice:false,
                onlyNumberSocial:false
    
            });
        // }
        
    }

    handleInputChangeCheckBox = (name,value) => {
        this.setState({
            [name] : value,
            announceAlert:false,
            svmContactAlert:false,
            remarkAlert:false
        })
    }

    onSelect(fullAddress) {
        const { district, province } = fullAddress
        this.setState({
            province:province,
            district:district,
            showLengthAlert:false
        })
    
    }

    handleInputChangeReceive(e) {
        let name = e.target.name;
        let value = e.target.value;
        if(name === 'province'){
            this.setState({
                province:value,
                showLengthAlert:false
            })
        }else{
            this.setState({
                district:value,
                showLengthAlert:false
            })
        }
        
    }

    componentWillMount() {
        this.getQuery()
    }

    getQuery = async () => {
        await fetchQuery(environment, query,{residential_id:this.props.match.params.id}).then(response_data => {
            if (response_data.myUserAgent) {
                this.setState({
                    districtUser: response_data.myUserAgent.tenant?.householder?.edges[0].node.residential.district,
                    provinceUser:response_data.myUserAgent.tenant?.householder?.edges[0].node.residential.province,
                    projectNameUser:response_data.myUserAgent.tenant?.householder?.edges[0].node.residential.project.name,
                    numRoom:response_data.myUserAgent.tenant?.householder?.edges[0].node.residential.name
                })
            }
        });
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/user/" + path, file);
            resolve(res);
        });
    };

    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];

        if (files) {
            if (files?.size > 10485760) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }
            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), 'กรุณาอัพโหลดไฟล์ในประเภท .png, .jpg, และ .jpegเท่านั้น และไฟล์ขนาดไม่เกิน 2 MB (สูงสุด 10 รูป)', 'error')
            }
            else {
                let new_file = new File([files], `${format(new Date(), "picture" + "_YYYYMMDDHHmmss")}${Math.floor(Math.random() * 1000000001)}${files.name}`);

                this.callUpload("image", new_file).then((data) => {
                        let fileOldList = _.cloneDeep(this.state.silvermanAgentImage);
                        let dese = "user/image/" + new_file.name
                        fileOldList.push({ 
                            "fileName": new_file.name, 
                            "fileUpload": dese,
                            "viewPtath": data.location
                        })
                        this.setState({ 
                            silvermanAgentImage: fileOldList,
                        })
                });
            }
        }

    }

    deleteImage(data, index) {
        let imageData = _.cloneDeep(this.state.silvermanAgentImage)
        imageData.splice(index, 1)
        this.setState({ silvermanAgentImage: imageData })
    }


    onSubmit(e){
        e.preventDefault();
        this.setState({loading: true});
        let isSubmit = false
        let {announceSell,announceForRent,announceBuy,announceDown,announceLease,announceLookRent,price,downPrice,realPrice,leasePrice,buyPrice,
            lookRentPrice,rentDate,rentPrice,contactName,callName,phone,line,email,autoCheckContact} = this.state

        var checkBoxesLookRent = document.getElementsByClassName('myLookRentPric');
        var isChecked = false;
        for (var i = 0; i < checkBoxesLookRent.length; i++) {
            if ( checkBoxesLookRent[i].checked ) {
                isChecked = true;
            };
        };

        var checkBoxesSvmContact = document.getElementsByClassName('svmContact');
        var isCheckedSvmContact = false;
        for (var i = 0; i < checkBoxesSvmContact.length; i++) {
            if ( checkBoxesSvmContact[i].checked ) {
                isCheckedSvmContact = true;
            };
        };
        
        if(announceSell && price <= 0){
            this.setState({ 
                priceAlert:true
             })
        }else if(announceSell && !this.checkNumber(price)){
            this.setState({ 
                onlyNumberPrice:true
            })

        // }else if(announceDown && downPrice <= 0){
        //     this.setState({ 
        //         priceAlert:true
        //     })
        // }else if(announceDown && !this.checkNumber(downPrice)){
        //     this.setState({ 
        //         onlyNumberPrice:true
        //     })
        // }else if(announceDown && realPrice <= 0){
        //     this.setState({ 
        //         priceAlert:true
        //     })
        // }else if(announceDown && !this.checkNumber(realPrice)){
        //     this.setState({ 
        //         onlyNumberPrice:true
        //     })
        // }else if(announceLease && leasePrice <= 0){
        //     this.setState({ 
        //         priceAlert:true
        //     })
        // }else if(announceLease && !this.checkNumber(leasePrice)){
        //     this.setState({ 
        //         onlyNumberPrice:true
        //     })
        }else if(announceBuy && buyPrice <= 0){
            this.setState({ 
                priceAlert:true
            })
        }else if(announceBuy && !this.checkNumber(buyPrice)){
            this.setState({ 
                onlyNumberPrice:true
            })
        }else if(announceLookRent && lookRentPrice <= 0){
            this.setState({ 
                priceAlert:true
            })
        }else if(announceLookRent && !this.checkNumber(lookRentPrice)){
            this.setState({ 
                onlyNumberPrice:true
            })
        }else if(announceLookRent && !isChecked){ 
            this.setState({ 
                priceAlert:true
            })
        }else if(announceForRent && this.state.rentDate === null){
            this.setState({ 
                priceAlert:true
            })
        }else if(announceForRent && rentPrice <= 0){
            this.setState({ 
                priceAlert:true
            })
        }else if(announceForRent && !this.checkNumber(rentPrice)){
            this.setState({ 
                onlyNumberPrice:true
            })
        }else if(contactName === ''){
            this.setState({ 
                contactAlert:true
            })
        // }else if(autoCheckContact === false && callName === ''){
        //     this.setState({
        //         contactAlert:true
        //     })
        }else if(phone === '' ){
            this.setState({
                socialAlert:true
            })
        }else if(!this.checkNumber(phone)){
            this.setState({
                onlyNumberSocial:true
            })
        }else if(line === ''){
            this.setState({
                socialAlert:true
            })
        }else if(email === ''){
            this.setState({
                socialAlert:true
            })
        }else if(!isCheckedSvmContact){
            this.setState({
                svmContactAlert:true
            })
        }
        else{
            isSubmit = true
            this.setState({ 
                priceAlert:false
            })
        }


        if(isSubmit) {

            let silvermanAgentImageUser = this.state.silvermanAgentImage.map(({fileName, fileUpload}) => ({fileName, fileUpload}));

            let agent = {
                userType:this.state.userType,
                residentialType:this.state.residentialType,
                residentialOther:this.state.residentialOther,
                announceSell:this.state.announceSell, //ขาย
                announceForRent:this.state.announceForRent, //ให้เช่า
                announceBuy:this.state.announceBuy, //ซื้อ
                announceDown:this.state.announceDown, //ขายดาวน์
                announceLease:this.state.announceLease, //เซ้ง
                announceLookRent:this.state.announceLookRent, // หาเช่า
                announceSellRent:this.state.announceSellRent,
                province:this.state.province ? this.state.province  : this.state.provinceUser ,
                district:this.state.district ? this.state.district  : this.state.districtUser,
                projectName:this.state.projectName ? this.state.projectName  : this.state.projectNameUser,
                area:this.state.area,
                topic:this.state.topic,
                description:this.state.description,
                sizeArea:parseFloat(this.state.sizeArea),
                bedroom:parseInt(this.state.bedroom),
                bathroom:parseInt(this.state.bathroom),
                floor:this.state.floor,
                floorSize:this.state.floorSize,
                rai:parseInt(this.state.rai),
                ngan:parseInt(this.state.ngan),
                squareWa:parseInt(this.state.squareWa),
                remarkPet:this.state.remarkPet,
                remarkForeignt:this.state.remarkForeignt,
                remarkDuplex:this.state.remarkDuplex,
                remarkPenthouse:this.state.remarkPenthouse,
                remarkHighFloor:this.state.remarkHighFloor,
                remark:this.state.remark,
                contactName:this.state.contactName,
                callName:this.state.callName ? this.state.callName : this.state.contactName,
                phone:this.state.phone,
                line:this.state.line,
                email:this.state.email,
                silvermanPhone:this.state.silvermanPhone,
                silvermanLine:this.state.silvermanLine,
                silvermanEmail:this.state.silvermanEmail,
                price:this.state.price,
                rentDate:this.state.rentDate ? format(this.state.rentDate,'YYYY-MM-DD') : null,
                rentPrice:this.state.rentPrice,
                downPrice:this.state.downPrice,
                realPrice:this.state.realPrice,
                leasePrice:this.state.leasePrice,
                buyPrice:this.state.buyPrice,
                lookRentPrice:this.state.lookRentPrice,
                thirdTerm: this.state.thirdTerm,
                sixTerm: this.state.sixTerm,
                twelveTerm: this.state.twelveTerm,
                silvermanAgentImage: silvermanAgentImageUser,
            }

            let variables = {
                input: {
                    agent: agent,
                }
            };

            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        if (response.createSilvermanAgent.ok) {
                            Swal.fire(this.state.langauge ==='en' ? 'Save successfully!' :i18next.t("Allaction:successcompleate"),this.state.langauge ==='en' ? `We'll contact you back in 48 hr.` :'ทีมงานจะติดต่อกลับภายใน 48 ชม.', 'success').then(() => {
                                this.setState({redirectToList: true,loading: false});
                            });
                        } else {
                            Swal.fire(i18n.t("electric_meter_record:Failed to save"), response.createSilvermanAgent.message, 'warning');
                            this.setState({redirectToList: true,loading: false});
                        }
                    },
                    onError: (err) => {
                        Swal.fire('Error!', i18n.t("electric_meter_record:Please try again"), 'warning');
                        this.setState({redirectToList: true,loading: false});
                    },
                },
            )
        }
      
    }

    
    render() {
        if (this.state.redirectToList) {
         return <Redirect to={`/silvermanagent/silvermanagent-accept`+'?token='+this.props.match.params.token+'&selected_unit='+this.props.match.params.id+'&lang='+this.props.match.params.lang+'?language='+this.props.match.params.lang} /> 
        }
        return (
        <>
         <div id="wrapper-content" style={{backgroundColor:'#E5E5E5'}}>
                 {
                       this.state.svmAgentStep1 &&
                        <SilvermanAgentStatus onSetStepFinish={this.onSetStepFinish} 
                        onSetAgentStatus={this.onSetAgentStatus} lang={this.state.langauge}/>
                    }
                    {
                         this.state.svmAgentStep2 && this.state.svmAgentStep1Finish &&
                        <>
                            <SilvermanAgentLocation onSetStepFinish={this.onSetStepFinish2} 
                                onSetPropertyType={this.onSetPropertyType}
                                lang={this.state.langauge}
                                svmAgentStepFinish={this.state.svmAgentStep1Finish}  
                                handleInputChange={this.handleInputChange}
                                handleInputChangeReceive={this.handleInputChangeReceive}
                                handleInputChangeCheckBox={this.handleInputChangeCheckBox}
                                districtUser={this.state.districtUser}
                                provinceUser={this.state.provinceUser}
                                projectNameUser={this.state.projectNameUser}
                                projectName={this.state.projectName}
                                province={this.state.province}
                                district={this.state.district}
                                onSelect={this.onSelect}
                                showLengthAlert={this.state.showLengthAlert}
                                showLengthProvincAlert={this.state.showLengthProvincAlert}
                                showLengthProjectNameAlert={this.state.showLengthProjectNameAlert}
                                showAlertResidentialType={this.state.showAlertResidentialType}
                                announceAlert={this.state.announceAlert}
                                residentialType={this.state.residentialType}
                                autoCheckData={this.state.autoCheckData}
                                onSetAutoCheckData={this.onSetAutoCheckData}
                                announceSell={this.state.announceSell}
                                announceForRent={this.state.announceForRent} //ให้เช่า
                                announceBuy={this.state.announceBuy} //ซื้อ
                                announceDown={this.state.announceDown} //ขายดาวน์
                                announceLease={this.state.announceLease} //เซ้ง
                                announceLookRent={this.state.announceLookRent} // หาเช่า
                                numRoom={this.state.numRoom}
                                userType={this.state.userType}
                                
                            />
                        </>
                    } 


                    {
                     this.state.svmAgentStep3 && this.state.svmAgentStep2Finish &&
                        <>
                            <SilvermanAgentDetail svmAgentStepFinish2={this.state.svmAgentStep3}
                                onSetStepFinish={this.onSetStepFinish3} 
                                userType={this.state.userType}
                                lang={this.state.langauge}
                                propertyType={this.state.residentialType}  handleInputChange={this.handleInputChange}  
                                handleInputChangeCheckBox={this.handleInputChangeCheckBox} uploadFile={this.uploadFile} 
                                silvermanAgentImage={this.state.silvermanAgentImage}
                                topicAlert={this.state.topicAlert}
                                deleteImage={this.deleteImage}
                                sizeAreaAlert={this.state.sizeAreaAlert}
                                bedroomBathroomFloorAlert={this.state.bedroomBathroomFloorAlert}
                                landAlert={this.state.landAlert}
                                onlyNumberAlert={this.state.onlyNumberAlert}
                                onlyNumberBedroomBathroomFloorAlert={this.state.onlyNumberBedroomBathroomFloorAlert}
                                onlyNumberLandAlert={this.state.onlyNumberLandAlert}
                                remarkAlert={this.state.remarkAlert}
                                remarkTextAlert={this.state.remarkTextAlert}
                                onSetAutoCheckRemark={this.onSetAutoCheckRemark}
                                autoCheckRemark={this.state.autoCheckRemark}
                                sizeArea={this.state.sizeArea}
                                remarkPet={this.state.remarkPet}
                                remarkForeignt={this.state.remarkForeignt}
                                remarkDuplex={this.state.remarkDuplex}
                                remarkPenthouse={this.state.remarkPenthouse}
                            />
                        </>
                    }

                    {
                     this.state.svmAgentStep4 && this.state.svmAgentStep3Finish &&
                        <>
                            <SilvermanAgentPrice svmAgentStepFinish3={this.state.svmAgentStep4} 
                                lang={this.state.langauge}
                                announceSell={this.state.announceSell} 
                                announceForRent={this.state.announceForRent}
                                announceBuy={this.state.announceBuy}
                                announceDown={this.state.announceDown}
                                announceLease={this.state.announceLease}
                                announceLookRent={this.state.announceLookRent}
                                handleInputChangeCheckBox={this.handleInputChangeCheckBox}
                                handleInputChange={this.handleInputChange}
                                handleDateChange={this.handleDateChange}
                                copyContactName={this.state.contactName}
                                rentDate={this.state.rentDate}
                                priceAlert={this.state.priceAlert}
                                contactAlert={this.state.contactAlert}
                                socialAlert={this.state.socialAlert}
                                onSetAutoCheckContact={this.onSetAutoCheckContact}
                                autoCheckContact={this.state.autoCheckContact}
                                thirdTerm={this.state.thirdTerm}
                                sixTerm={this.state.sixTerm}
                                twelveTerm={this.state.twelveTerm}
                                svmContactAlert={this.state.svmContactAlert}
                                silvermanPhone={this.state.silvermanPhone}
                                silvermanLine={this.state.silvermanLine}
                                silvermanEmail={this.state.silvermanEmail}
                                onlyNumberPrice={this.state.onlyNumberPrice}
                                onlyNumberSocial={this.state.onlyNumberSocial}
                                onSubmit={this.onSubmit}
                                loading={this.state.loading}
                            />
                            {/* <div id='svm-agent-status'>
                                <div className='footer-svm-agent'>
                                    <div className='pt-6 pb-6'>
                                        <button  onClick={ (e) => this.onSubmit(e)}  className="btn btn-primary btn-svm-agent">
                                            ส่งข้อมูล
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                        </>
                    }         
        </div>
        </>
        )
    }
}

export default SilvermanAgentCreate;
