import getApiRoot from "./getAPIRoot";
import axios from "axios";

async function PostApiUseing(data){
    let token_id = localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };

    return axios.post(`${getApiRoot()}api/using`, data, {
        headers: header,
    });
}

export default {PostApiUseing}