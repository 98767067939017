import getApiRoot from "../../libs/getAPIRoot";
import axios from "axios";

async function getProfile(token){
    return axios.get(`${getApiRoot()}ownership/profile`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
    });
}

async function createProfile(data,token){
    let header = {
        authorization: `JWT ${token}`,
        "Content-Type": "application/json",
    };

    return axios.post(`${getApiRoot()}ownership/profile`, data, {
        headers: header,
    });
}

async function createDeveloper(data,token){
    let header = {
        authorization: `JWT ${token}`,
        "Content-Type": "application/json",
    };

    return axios.post(`${getApiRoot()}ownership/general`, data, {
        headers: header,
    });
}




export default {getProfile,createProfile,createDeveloper}