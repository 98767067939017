import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';
import './settingAdvertise.scss'

class Navigation extends Component {

    render() {
        return (
            <div className="row" id="advertise">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/setting/setting-advertise" >
                            <Translation>
                                        {
                                            t => <span>{t('settingAds:Ads List')}</span>
                                        }
                                </Translation>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;