import React from "react";
import localStorage from "../../../../libs/localstorage";
import numberWithComma from "../../../../libs/numberWithComma";
import {format} from "date-fns";
import Link from "react-router-dom/es/Link";
import "../../report.scss";

class PayableMovementTable extends React.Component {
    render() {
        return (
            this.props.query.allPurchaseRecordGroup.edges.map((purchase_record_group, index) => {
                let order_id = this.props.state.first - localStorage.getPageLimit();
                return (
                    purchase_record_group.node.purchaseRecord.edges.map((purchase_record, t_index) => {
                        let row_number = (t_index === 0 && order_id + index + 1);
                        let balance = purchase_record.node.total;
                        let pd_info = [];
                        purchase_record.node.prepaidDepositTransactionUsed.edges.map((rd_transaction_id) => {
                            return pd_info.push(rd_transaction_id);
                        });
                        return (
                            <React.Fragment key={purchase_record.node.id}>
                                <tr>
                                    <td className="text-center">{row_number}</td>
                                    <td className="text-center">{format(purchase_record_group.node.issuedDate, 'DD/MM/YYYY')}</td>
                                    <td className="text-center">

                                        <Link to={"/accounting/expense/purchase/purchase-detail/"+purchase_record_group.node.id} target={"_blank"}>
                                            {purchase_record_group.node.docNumber}
                                        </Link>
                                    </td>
                                    <td>{purchase_record.node.description}</td>
                                    <td className="text-right">{numberWithComma(purchase_record.node.total)}</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td className="text-right">{numberWithComma(balance)}</td>
                                </tr>

                                {
                                    purchase_record.node.payRecord.edges.map((pay_record) => {
                                        balance -= (pay_record.node.amount + pay_record.node.whtAmount);
                                        return (
                                            <tr key={pay_record.node.id}>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-center">
                                                    {format(pay_record.node.payRecordGroup.issuedDate, 'DD/MM/YYYY')}
                                                </td>
                                                <td className="text-center">
                                                    <Link to={"/accounting/expense/pay/detail/" + pay_record.node.payRecordGroup.id}
                                                        target={'_blank'}>
                                                        {pay_record.node.payRecordGroup.docNumber}
                                                    </Link>
                                                </td>
                                                <td className="text-right">
                                                    {numberWithComma((pay_record.node.amount + pay_record.node.whtAmount))}
                                                </td>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-right">{numberWithComma(balance, "0.00")}</td>
                                            </tr>
                                        )
                                    })
                                }

                                {
                                    purchase_record.node.acceptCreditNoteTransaction.edges.map((cn_transaction) => {
                                        balance -= cn_transaction.node.total;
                                        return (
                                            <tr key={cn_transaction.node.id} id="history">
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-center">{format(cn_transaction.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                <td className="text-center">
                                                    <Link to={"/accounting/expense/accept-credit-note/detail/" + cn_transaction.node.id}
                                                        target={'_blank'}>
                                                        {cn_transaction.node.docNumber}
                                                    </Link>
                                                </td>
                                                <td className="text-right red">
                                                    ({numberWithComma(cn_transaction.node.price)})
                                                </td>
                                                <td className="text-right">{numberWithComma(balance)}</td>
                                            </tr>
                                        )
                                    })
                                }

                                {
                                    pd_info.map((pd_transaction) => {
                                        balance -= pd_transaction.node.amount;
                                        return (
                                            <tr key={pd_transaction.node.id} id="history">
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-center">{format(pd_transaction.node.prepaidDeposit.issuedDate, 'DD/MM/YYYY')}</td>
                                                <td className="text-center">
                                                    <Link to={"/accounting/expense/accept-credit-note/detail/" + pd_transaction.node.prepaidDeposit.id}
                                                        target={'_blank'}>
                                                        {pd_transaction.node.prepaidDeposit.docNumber}
                                                    </Link>
                                                </td>
                                                <td className="text-right">
                                                    {numberWithComma(pd_transaction.node.amount)}
                                                </td>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-right">{numberWithComma(balance, "0.00")}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    })
                )
            })
        )
    }
}

export default PayableMovementTable;
