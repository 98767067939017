import React from 'react';
import { QueryRenderer } from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import getNameResidential from "../../../libs/getNameResidential";
import Loading from '../../../libs/loading';
import i18next from 'i18next';

class AcceptCreditNoteListTable extends ComponentPagination {

    getColorAndText(object) {
        let text = '';
        let color = 'text-center';
        switch (object.node.status) {
            case 'WAIT':
                text = 'ยังไม่ชำระ';
                break;
            case 'PAID':
                text = 'จ่ายชำระแล้ว';
                break;
            case 'WAITING_PAY':
                text = 'รอจ่ายชำระ';
                break;
            default:
                text = 'รายการที่ยกเลิก';
                color = 'text-center text-danger';
        }
        return <td className={color}>{text}</td>
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={this.props.query}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status
                }}
                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return <React.Fragment>
                            <div className="table-responsive fade-up card">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center">{i18next.t("PurchaseCreditNote:No.")}</th>
                                            <th className="text-center">{i18next.t("PurchaseCreditNote:Date")}</th>
                                            <th className="text-center">{i18next.t("PurchaseCreditNote:Payable code")}</th>
                                            <th>{i18next.t("PurchaseCreditNote:Name")}</th>
                                            <th>{i18next.t("PurchaseCreditNote:Description")}</th>
                                            <th className="text-right">{i18next.t("PurchaseCreditNote:Amount")}</th>
                                            <th className="text-center">{i18next.t("PurchaseCreditNote:Create Date")}</th>
                                            {this.props.status === 'void' &&
                                                <th className="text-center">{i18next.t("PurchaseCreditNote:Canceled Date")}</th>
                                            }
                                            {this.props.status === 'all' &&
                                                <th className="text-center">{i18next.t("PurchaseCreditNote:Status")}</th>
                                            }
                                            <th className="text-center">{i18next.t("PurchaseCreditNote:By")}</th>
                                            {this.props.status === 'void' &&
                                                <th className="text-center">{i18next.t("PurchaseCreditNote:Reason")}เหตุผลการยกเลิก</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {props.acceptCreditNoteViewer.allAcceptCreditNote.edges.map((accept_credit_note) => (
                                            <tr key={accept_credit_note.node.id}>
                                                <td className="text-center">
                                                    {accept_credit_note.node.status === "VOID" ?
                                                        accept_credit_note.node.docNumber :
                                                        <Link
                                                            to={"/accounting/expense/accept-credit-note/detail/" + accept_credit_note.node.id}
                                                            >
                                                            {accept_credit_note.node.docNumber}
                                                        </Link>
                                                    }
                                                </td>
                                                <td className="text-center">{format(accept_credit_note.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                <td className="text-center">{accept_credit_note.node.contact.refNumber}</td>
                                                <td>
                                                    {accept_credit_note.node.contact.typeOfContact === "RESIDENTIAL" ?
                                                        getNameResidential(accept_credit_note.node.contact.firstName, accept_credit_note.node.contact.lastName)
                                                        : accept_credit_note.node.contact.name}{" "}
                                                    {/* {accept_credit_note.node.contact.name} {accept_credit_note.node.contact.firstName} {accept_credit_note.node.contact.lastName} */}
                                                </td>
                                                <td>{accept_credit_note.node.description}</td>
                                                <td className="text-right">{numberWithComma(accept_credit_note.node.total)}</td>
                                                <td className="text-center">{format(accept_credit_note.node.added, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                {this.props.status === 'void' &&
                                                    <td className="text-center">{format(accept_credit_note.node.updated, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                }
                                                {this.props.status === 'all' &&
                                                    this.getColorAndText(accept_credit_note)
                                                }
                                                <td className="text-center">{accept_credit_note.node.creator}</td>
                                                {this.props.status === 'void' &&
                                                    <td className="text-center">{accept_credit_note.node.voidRemark || '-'}</td>
                                                }
                                            </tr>
                                        ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props.acceptCreditNoteViewer.allAcceptCreditNote.totalCount} />
                            </div>
                        </React.Fragment>
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default AcceptCreditNoteListTable;
