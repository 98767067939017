/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingChatQueryVariables = {||};
export type settingChatQueryResponse = {|
  +allChatSettinSetting: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +useRole: boolean,
        +showOld: boolean,
        +showBefore: boolean,
      |}
    |}>
  |}
|};
export type settingChatQuery = {|
  variables: settingChatQueryVariables,
  response: settingChatQueryResponse,
|};
*/


/*
query settingChatQuery {
  allChatSettinSetting {
    edges {
      node {
        id
        useRole
        showOld
        showBefore
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ChatSettingNodeConnection",
    "kind": "LinkedField",
    "name": "allChatSettinSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChatSettingNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatSettingNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useRole",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showOld",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showBefore",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingChatQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingChatQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ddde04cc0c95748c2db39db1a6a34064",
    "id": null,
    "metadata": {},
    "name": "settingChatQuery",
    "operationKind": "query",
    "text": "query settingChatQuery {\n  allChatSettinSetting {\n    edges {\n      node {\n        id\n        useRole\n        showOld\n        showBefore\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '877d03af1036138aee7adab0d08512f2';

module.exports = node;
