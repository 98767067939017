import React, { useState } from 'react';
import Header from './headerLogin';
import './styles/termOfuser.scss'
import { Link } from "react-router-dom";

const AcceptForPolicy = () => {

    const [langauge, setLangauge] = useState(localStorage.getItem("language")?? 'th')
    const [forPolicy, checkAcceptForPolicy] = useState(true)

    const nextPage = () => {
        localStorage.removeItem('')
        localStorage.setItem('checkStatusAcceptForPolicy', forPolicy)
    }

    const changeLangauge = (langBoon,lang) => {
        setLangauge(lang)
    }

    const acceptForPolicys = (e) => {
        if (e.target.value === "true") {
            checkAcceptForPolicy(true)
        } else if (e.target.value === "false") {
            checkAcceptForPolicy(false)
        }
    }

    return (
        <div className="height-100">
            <Header lang={changeLangauge} />
            <div className="col-md-10 container-fluid box " id='pdpa-menu'>
                {langauge === "th"
                    ?
                    <div className="height-80 justify-content-center" id="accept-for-marketing">
                        <div className="col-11 ml-8 selectManagementAndPolicy">
                            <h3>ความยินยอมในการเปิดเผยข้อมูลเพื่อวัตถุประสงค์ทางการตลาด</h3>
                            <p>เราอาจเปิดเผยข้อมูลส่วนบุคคลและข้อมูลใดๆ ของคุณ ให้แก่บริษัท ในกลุ่มและพันธมิตรทางธุรกิจที่เชื่อถือได้ของ Silverman เพื่อวัตถุประสงค์ดังนี้</p>
                            <ul>
                                <li>วิจัย ทำข้อมูลสถิติ พัฒนา วิเคราะห์ ผลิตภัณฑ์ บริการ และสิทธิประโยชน์ที่ตอบสนองความต้องการของคุณ</li>
                                <li>ติดต่อคุณเพื่อเสนอหรือจัดให้มีผลิตภัณฑ์ บริการ และสิทธิประโยชน์ที่เหมาะสมแก่คุณ</li>
                            </ul>


                            <p>กรุณากด <b>"ยินยอม"</b> หากคุณตกลงให้ บริษัท พอร์ต 80 จำกัด เปิดเผยข้อมูลส่วนบุคคลและข้อมูลใดๆ ของคุณ ให้แก่บริษัทในกลุ่มของ Silverman
                                <br /> สามารถดูรายละเอียดได้ที่เว็บไซต์ https://silverman.app/privacy และพันธมิตรทางธุรกิจที่เชื่อถือได้ของ Silverman เพื่อวัตถุประสงค์ทางการตลาดของบุคคลดังกล่าว</p>


                            <p className='mt-10'>ยินยอมในการเปิดเผยข้อมูลเพื่อวัตถุประสงค์การตลาดได้</p>

                            <div style={{ display: 'inline-flex' }}>
                                <label className="containerRadio">ยินยอม
                                    <input type={"radio"}
                                        defaultChecked={forPolicy == true}
                                        value={true}
                                        name='acceptPolicy' onChange={acceptForPolicys} />
                                    <span className="checkmark"></span></label>

                                <label className="containerRadio">ปฏิเสธ
                                    <input type={"radio"}
                                        defaultChecked={forPolicy == false}
                                        value={false}
                                        name='acceptPolicy' onChange={acceptForPolicys} />
                                    <span className="checkmark"></span></label>
                            </div>




                            {/* <hr className='mt-10' />
                            <div className='apcentPolicy'>
                                <p>คุณอาจจะพลาด โอกาสในการรับข้อเสนอผลิตภัณฑ์บริการ และสิทธิประโยชน์ที่เหมาะสมกับความต้องการของคุณ
                                    <br />โดยเฉพาะจาก Silverman ในกรณีที่คุณไม่ให้ความยินยอมหรือขอถอนความยินยอม</p>
                            </div> */}
                        </div>

                    </div>
                    :
                    <div className="height-80 justify-content-center" id="accept-for-marketing">
                        <div className="col-11 ml-8 selectManagementAndPolicy">
                            <h3>Consent to disclosure of information for marketing purposes</h3>
                            <p>We may disclose your personal and any information to Silverman and affiliations of Silverman for the purposes of:</p>
                            <ul>
                                <li>Research for statistical data, develop, and analyze our products, services, and specific benefits for your needs.</li>
                                <li>Contact you for offering our products, services, and specific benefits for your needs.</li>
                            </ul>


                            <p>Please press <b>"Accept"</b> if you agree that Port 80 Co., Ltd. discloses your personal and any information to Silverman affiliations. Details are available on the website "https://silverman.app/privacy" and the website of Silverman affiliations for marketing purposes of them.</p>


                            <p className='mt-10'>Consent to disclosure of information for marketing purposes</p>

                            <div style={{ display: 'inline-flex' }}>
                                <label className="containerRadio">Accept
                                    <input type={"radio"}
                                        defaultChecked={forPolicy == true}
                                        value={true}
                                        name='acceptPolicy' onChange={acceptForPolicys} />
                                    <span className="checkmark"></span></label>

                                <label className="containerRadio">Reject
                                    <input type={"radio"}
                                        defaultChecked={forPolicy == false}
                                        value={false}
                                        name='acceptPolicy' onChange={acceptForPolicys} />
                                    <span className="checkmark"></span></label>
                            </div>

 
                        </div>
                    </div>
                }
                <div id="term-of-user">
                    <div className="col-mt-10 btnLogin text-right"> 
                        <Link to={'/accept-for-partner'} >
                            <button className='nextStep2' onClick={nextPage}
                                disabled={forPolicy == null}

                            >{langauge === 'th' ? "ถัดไป" : "Next"}</button>
                        </Link>

                    </div>
                </div>

            </div>
        </div>
    )
}
export default AcceptForPolicy