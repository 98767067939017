import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import {Link} from "react-router-dom";
import {QueryRenderer} from 'react-relay';
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import './debtFreeCertification.scss'
import SearchProject from '../../project/searchProject';
import _ from 'lodash'
import { format } from 'date-fns';
import {Redirect} from 'react-router';
import {fetchQuery} from "relay-runtime";
import { Translation } from 'react-i18next';
import i18next from 'i18next';



const query = graphql`
query debtSelectCertificationQuery($search: String) {
    allDebsFreeCertification(status: ["draft"], search: $search){
        totalCount
        edges{
            node{
                id
                docNumber
                status
                statusPrint
                dateStamp
                printDate
                dueDate
                creatorVoid
                voidDate
                voidNote
                debsFreeCertificationRequest{
                    id
                    docNumber
                    added
                    updated
                    contact{
                        id
                        name
                        firstName
                        lastName
                    }
                    requestingDate
                    creatorApprove
                    approvalDate
                    transactionDebtType
                    tenant{
                        firstName
                        lastName
                    }
                    receive{
                        id
                        docNumber
                    }
                    otherReceive{
                        id
                        docNumber
                    } 
                    status 
                }
            }
        }
    }
}`;

class DebtSelectCertification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search : '',
            typOfContact : 'residential',
            startDate : new Date(),
            loading : false,
            selectedContact : [],
            selectedContactID : [],
            redirect : false,
            checkAll : false
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    selectContact(param, detail) {
        let selectedContact = _.cloneDeep(this.state.selectedContact)
        let selectedContactID = _.cloneDeep(this.state.selectedContactID)
        if(!selectedContactID.includes(param)){
            selectedContactID.push(param)
            selectedContact.push(detail)
        }
        this.setState({
            selectedContact: selectedContact,
            selectedContactID:selectedContactID
        });
    }

    onDelete = (param) =>{
        let selectedContactID = _.cloneDeep(this.state.selectedContactID);
        let selectedContact = _.cloneDeep(this.state.selectedContact)
        let index_id = selectedContactID.indexOf(param);
        if (index_id !== -1) {
            selectedContactID.splice(index_id, 1)
        }
        let index_contact = _.findIndex(selectedContact, {'node' : {'id' : param}})

        if(index_contact !== -1) {
            selectedContact.splice(index_contact, 1)
        }
        this.setState({
            selectedContact: selectedContact,
            selectedContactID:selectedContactID
        });
    }

    clearList = () => {
        this.setState({
            selectedContact : [],
            selectedContactID : [],
            checkAll : false,
        })
    }



    handleSearch = (text) => {
        this.setState({
            search: text,
        })
    }

    

    checkAll = () => {
        if(this.state.checkAll){
            this.clearList()
        }else{
            fetchQuery(environment, query, {
                search : this.state.search
            }).then(data => {
                let selectedContact = []
                let selectedContactID = []
                if(data.allDebsFreeCertification && data.allDebsFreeCertification.edges.length > 0){
                    _.forEach(data.allDebsFreeCertification.edges , list => {
                        selectedContact.push(list)
                        selectedContactID.push(list.node.id)
                    })
                }
                this.setState({
                    checkAll : true,
                    selectedContact : selectedContact,
                    selectedContactID : selectedContactID,
                })
            }).catch(err => {
                alert(err)
            })
        }
    }

    changeType = (type) => {
        if(type !== this.state.typOfContact){
            this.setState({
                typOfContact:type,
                checkAll : false,
                selectedContact : [],
                selectedContactID : [],
            }) 
        }
        
    }
    

    render() {
        if (this.state.redirect) {
            return <Redirect to="/accounting/debt_free_certification/list/all"/>;
        }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div  className="container-fluid box" id="debt-free-certification">
                        <div className="row justify-content-between">
                            <div className="col-12">
                                <Translation>
                                            {
                                        t => 
                                        <h4 className="mb-4 form-inline">
                                            <Link to="/accounting/debt_free_certification/list/all">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back-new.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <div className="mt-1">พิมพ์หนังสือ</div>
                                        </h4>
                                    }
                                </Translation>
                            </div>
                        </div>
                        <div className="content-inner">
                            <div className="row mt-3 mb-4">
                                <div className="col-6">
                                    <h6>ค้นหารายการ</h6>
                                </div>
                                <div className="col-6">
                                </div>
                                <div className="col-6 mb-3">
                                    <SearchProject callback_function={this.handleSearch} search={this.state.search}/>
                                    
                                </div>
                                <div className="col-2 mb-3">
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 form-inline">
                                    <div className="col-12 form-inline">
                                        <h5 className="mr-3">รายการที่ยังไม่ได้พิมพ์</h5>
                                        <input type="checkbox" className="custom-checkbox mr-3" onClick={() =>this.checkAll() } />
                                        <span>เลือกทุกรายการ</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="col-12"><h5>รายการที่เลือก</h5></div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <div className="col card colSelect">
                                        <div className="col-12">
                                            <div className="row scrollTable" >
                                                <div className="col" >
                                                    <div id="list-contact" >
                                                        <table className="table table-hover mt-4" style={{fontSize:'16px'}}>
                                                            <thead className="blue-head">
                                                                <tr>
                                                                    <th width={200} className="text-left">รหัส</th>
                                                                    <th width={100}className="text-center">เลขห้อง</th>
                                                                    <th width={200}className="text-left">ชื่อ</th>
                                                                    <th width={200}className="text-center">วันที่ขอหนังสือ</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <QueryRenderer 
                                                                    environment={environment}
                                                                    query={query}
                                                                    cacheConfig={{use_cache: false}}
                                                                    variables={{search:this.state.search}}
                                                                    render={({error, props}) => {
                                                                        if(error){
                                                                            return <div>{error.message}</div>;
                                                                        }else if(props){
                                                                            return (
                                                                                props.allDebsFreeCertification.edges.length > 0 &&
                                                                                props.allDebsFreeCertification.edges.map((debtFreeCertification,index) => {
                                                                                    return(
                                                                                        <tr key={debtFreeCertification.node.id}
                                                                                            id={debtFreeCertification.node.id}
                                                                                            onClick={() => this.selectContact(debtFreeCertification.node.id, debtFreeCertification)}
                                                                                            >
                                                                                            <td className="text-left">{debtFreeCertification.node.docNumber}</td>
                                                                                            <td className="text-center">{debtFreeCertification.node.debsFreeCertificationRequest.contact.name}</td>
                                                                                            <td className="text-left">{debtFreeCertification.node.debsFreeCertificationRequest.tenant.firstName} {debtFreeCertification.node.debsFreeCertificationRequest.tenant.lastName} </td>
                                                                                            <td className="text-center">{format(debtFreeCertification.node.debsFreeCertificationRequest.requestingDate,'DD/MM/YYYY')}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            ) 
                                                                        }else{
                                                                            return (
                                                                                <tr>
                                                                                    <td><span><span className="spinner-border spinner-border-sm align-middle mr-2"/> {i18next.t("Allaction:Preparing information")}</span></td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    }}
                                                                />
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="col card colSelect">
                                        <div className="col-12">
                                            <div className="row scrollTable">
                                                <div className="col">
                                                    <div id="list-contact">
                                                        <table className="table table-hover mt-4"  style={{fontSize:'16px'}}>
                                                            <thead className="blue-head">
                                                                <tr>
                                                                    <th width={200} className="text-left">รหัส</th>
                                                                    <th width={120} className="text-center">เลขห้อง</th>
                                                                    <th width={200}className="text-left">ชื่อ</th>
                                                                    <th width={200} className="text-center">วันที่ขอหนังสือ</th>
                                                                    <th width={50} className="text-center"/>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.selectedContact.length > 0 &&
                                                                   this.state.selectedContact.map((debtFreeCertification,index) => {
                                                                    return(
                                                                        <tr key={debtFreeCertification.node.id + ' select'}
                                                                            id={debtFreeCertification.node.id}
                                                                            >
                                                                            <td className="text-left">{debtFreeCertification.node.docNumber}</td>
                                                                            <td className="text-center">{debtFreeCertification.node.debsFreeCertificationRequest.contact.name}</td>
                                                                            <td className="text-left">{debtFreeCertification.node.debsFreeCertificationRequest.tenant.firstName} {debtFreeCertification.node.debsFreeCertificationRequest.tenant.lastName} </td>
                                                                            <td className="text-center">{format(debtFreeCertification.node.debsFreeCertificationRequest.requestingDate,'DD/MM/YYYY')}</td>
                                                                            <td className="text-center"> 
                                                                                <img className="cursor"  src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="plus"
                                                                                        onClick={() => this.onDelete(debtFreeCertification.node.id)}/>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-end mb-2">
                                                <div className="col">
                                                    <button type="button" className="btn btn-clear float-right" onClick={this.clearList}>
                                                        <span className="clear-list">ล้างรายการ</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end p-4">
                                    <Link to={{
                                            pathname: "/accounting/debt_free_certification/printed",
                                            state: {
                                                selectedContactID: this.state.selectedContactID,
                                            },
                                        }}>
                                        <button type="button" className="btn-add" disabled={this.state.selectedContact.length === 0}>
                                            ถัดไป
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-1"/>
                            </div>
                            
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>


        )
    }
}

export default DebtSelectCertification;
