import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import "../styles/receive.scss"
import _ from "lodash";
import getNameResidential from "../../libs/getNameResidential";
import { getTypeProject } from "../../libs/getTypeOfProject";
import "../styles/new-styles.scss"
import { Translation } from 'react-i18next';
import i18n from "i18next"
import AddressDataShow from '../../components/FucntionLib/addressDataShow';

const ThaiBaht = require('thai-baht-text');

class OtherReceiverAll extends Component {

    constructor(props) {
        super(props);
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310, 10280];
        this.state = {
            bangkokCode: bangkokCode,
            total: 0,
            total_cash_pay: 0,
            total_credit_card_pay: 0,
            total_all_pay: 0,
            total_other_pay: 0,
            //เงินรอรับการตรวจสอบ
            total_unknown_pay: 0,

            sum_amount: 0,
            sum_vat: 0,
            sum_wht_rate: 0,
            receiveDeposit: 0,
            sum_all: 0,
            sum_discount: 0,
            discount: 0,
            discount_receive: 0,
            sum_receive_deposit: 0,

            have_vat: "",
            discount_status: "",
            otherCode: ["2210-01", "2210-02", "2210-03", "5400-07", '4300-12'],
            page_record: {},
            end_page: 0,
            list_map: [],
            check_type: 'all',
            languages: "th"
        };
        this.calSummary = this.calSummary.bind(this);
        this.calPayAmount = this.calPayAmount.bind(this);
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
        this.getDiff = this.getDiff.bind(this);
    }

    componentWillMount() {
        this.calSummary();

    }

    componentDidMount() {
        i18n.changeLanguage(this.props.userConfig.languages);
        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + signature);
            $('tbody tr:last-child td').css({ paddingBottom: diff });

        }, 200);
        this.setPage()
        this.setState({ list_map: [1, 2], languages: this.props.userConfig.languages })
    }

    componentDidUpdate() {
        if (this.state.check_type !== this.props.check_type) {
            if (this.props.check_type === 'all') {
                setTimeout(() => {
                    this.setState({ list_map: [1, 2] })
                }, 300);
            } else if (this.props.check_type === 'manuscript') {
                this.setState({ list_map: [1] })
            } else {
                this.setState({ list_map: [2] })
            }
            this.setState({ check_type: this.props.check_type });
        }
    }

    setPage() {
        //default table:15 record per page
        if (this.props.query.otherReceiveTransaction.edges.length > 12) {
            let page = {};
            let index_page = 1;
            let count = 1;
            let order_num = 0
            //check คือ last page
            let check = 0
            this.props.query.otherReceiveTransaction.edges.map((value, index) => {
                if (count <= 20) {
                    if (!page[index_page]) {
                        page[index_page] = [];
                    }
                    order_num += 1
                    let record = { ...value, ...{ order: order_num } }
                    page[index_page].push(record);
                    count += 1;
                } else {
                    count = 1;
                    index_page += 1;
                    if (!page[index_page]) {
                        page[index_page] = [];
                        order_num += 1
                        let record = { ...value, ...{ order: order_num } }
                        page[index_page].push(record);
                    }
                }
                check = index_page
            })
            if (page[check].length > 12) {
                index_page += 1
                page[check + 1] = [];
                if (page[check].length !== 13) {
                    page[check + 1] = page[check].slice(13, page[check].length)
                } else {
                    page[check + 1] = page[check].slice(13)
                }
                page[check] = page[check].slice(0, 13)
                check = index_page
            }
            this.setState({
                page_record: page,
                checked: check,
                end_page: check
            })
        } else {
            let page = {};
            page[1] = [];
            let order_num = 0
            this.props.query.otherReceiveTransaction.edges.map(value => {
                order_num += 1
                let record = { ...value, ...{ order: order_num } }
                page[1].push(record);

            })
            this.setState({ page_record: page, checked: 1, end_page: 1 })
        }

    }

    calSummary() {
        let amount = 0; //จน.ของ * ราคา
        let sumAmount = 0; // amountทั้งหมด
        let discount = 0; //ส่วนลด
        let sumDiscount = 0; //ส่วนลดทั้งหมด
        let sumWhtRate = 0; //หัก ณ ที่จ่ายทั้งหมด
        let receiveDeposit = 0; //รับล่วงหน้า
        let sumReceiveDeposit = 0; //รวมหลังหักรับล่วงหน้า
        let sumVat = 0;//VAT ทั้งหมด
        let sumTotal = 0;// รวมหลังหักรับล่วงหน้า + VAT ทั้งหมด
        let discountReceive = 0;// ส่วนลด receive
        let sumGrandTotal = 0;//ยอดรวมสุทธิ

        this.props.query.otherReceiveTransaction.edges.forEach((transactions) => {
            // if(this.props.query.otherReceive.contact.typeOfContact !== 'RESIDENTIAL' && transactions.node.vat !== null){
            amount = parseFloat(transactions.node.unitItems) * parseFloat(transactions.node.price);
            // }else{
            //     amount = parseFloat(transactions.node.preTaxAmount);
            // }
            sumAmount += parseFloat(amount);
            discount += parseFloat(transactions.node.discount);
            discountReceive = parseFloat(discountReceive) + transactions.node.discount;//ส่วนลดของreceive
            sumDiscount += amount - parseFloat(transactions.node.discount);

            // transactions.node.transaction.receiptDepositTransactionUsed.edges.forEach((rd_dept) => {
            //     receiveDeposit += rd_dept.node.amount;
            // });

            //คำนวนรับล่วงหน้า
            if (this.props.taxType === "INCLUDE_TAX") {
                sumReceiveDeposit = (((parseFloat(sumDiscount) - receiveDeposit) * 100) / (100 + transactions.node.vat));
            } else {
                sumReceiveDeposit = parseFloat(sumDiscount) - receiveDeposit;
            }
            //คำนวนvat
            sumVat = parseFloat(sumReceiveDeposit) * (transactions.node.vat / 100);

            //คำนวนหัก ณ ที่จ่าย
            if (transactions.node.whtRatePercent !== 0) {
                sumWhtRate = parseFloat(sumReceiveDeposit) * (transactions.node.whtRatePercent / 100);
            }


            //คำนวนจำนวนเงินรวม
            sumTotal = sumReceiveDeposit + sumVat;

            //คำนวนยอดรวมทั้งสิ้น
            if (this.props.query.taxType === "INCLUDE_TAX") {
                sumGrandTotal = parseFloat(sumTotal);
            } else {
                sumGrandTotal = sumReceiveDeposit + sumVat;
            }
        });
        
    

        this.setState({
            sum_wht_rate: sumWhtRate.toFixed(2),
            sum_vat: sumVat.toFixed(2),
            sum_grand_total: sumGrandTotal.toFixed(2),
            discount_receive: discountReceive.toFixed(2),
            sum_amount: sumAmount.toFixed(2),
            sum_total: sumTotal.toFixed(2),
            sum_receive_deposit: sumReceiveDeposit.toFixed(2),
            receiveDeposit: receiveDeposit.toFixed(2),
            sum_discount: sumDiscount.toFixed(2),
            discount: discount.toFixed(2),
        }, () => this.calPayAmount());
    }



    calPayAmount() {
        let total_all_pay = 0;
        let total_cash_pay = 0;
        let total_credit_card_pay = 0;
        let total_other_pay = 0;
        let unknown_pay = 0;

        if (this.props.cash_transaction.length > 0) {
            this.props.cash_transaction.forEach((cash) => {
                total_cash_pay = total_cash_pay + cash.node.price;
                total_all_pay = total_all_pay + cash.node.price;
            })
        }

        if (this.props.bank_transaction.length > 0) {
            this.props.bank_transaction.forEach((bank) => {
                total_all_pay = total_all_pay + bank.node.price;
            })
        }
        if (this.props.cheque_transaction.length > 0) {
            this.props.cheque_transaction.forEach((cheque) => {
                total_all_pay = total_all_pay + cheque.node.price;
            })
        }
        if (this.props.credit_card_transaction.length > 0) {
            this.props.credit_card_transaction.forEach((credit_card) => {
                total_credit_card_pay = total_credit_card_pay + credit_card.node.amount;
                total_all_pay = total_all_pay + credit_card.node.amount;
            })
        }

        if(this.props.allAccountRecordGroup.length > 0) {
        this.props.allAccountRecordGroup.forEach((accountRecord) => {
            if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" ||
                accountRecord.node.chartOfAccountCode.chartOfAccountCode === "5400-07" ||
                accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("2210") ||
                accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("5830")) {
                total_other_pay = total_other_pay + (accountRecord.node.credit > 0 ? accountRecord.node.credit : accountRecord.node.debit)
            }
            if (accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("2230")) {
                unknown_pay = unknown_pay + (accountRecord.node.credit > 0 ? accountRecord.node.credit : accountRecord.node.debit)
            }

        })}

        this.setState({
            total_cash_pay: total_cash_pay.toFixed(2),
            total_all_pay: total_all_pay.toFixed(2),
            total_other_pay: total_other_pay.toFixed(2),
            total_credit_card_pay: total_credit_card_pay.toFixed(2),
            total_unknown_pay: unknown_pay.toFixed(2),
        });
    }

    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {
        let languages = this.state.languages
        if (type === "district" && this.isBangkok(code)) {
            return languages == "th" ? "แขวง" : "District "
        }
        else if (type === "city" && this.isBangkok(code)) {
            return languages == "th" ? "เขต" : "County "
        }
        else if (type === "province" && this.isBangkok(code)) {
            return languages == "th" ? "" : ""
        }
        else if (type === "province") {
            return languages == "th" ? "จ." : "Province "
        }
        else if (type === "district") {
            return languages == "th" ? "ต." : "District "
        }
        else if (type === "city") {
            return languages == "th" ? "อ." : "County "
        }
    }

    isOther(code) {
        if (_.indexOf(this.state.otherCode, code) >= 0) {
            return true;
        }
        return false;
    }

    //ค่าว่างที่เหลืออยู่ในหน้ากระดาษ ->  เพิ่มให้เต็ม
    getDiff(key, index, count_receive) {
        setTimeout(() => {
            let page = $('.' + key + '-' + index + '-' + count_receive + '-' + this.props.query.docNumber);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height() > 0 ? page.find('.footer').height() : 0;
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + signature);
            $('.' + key + "-" + index + '-' + count_receive + '-' + this.props.query.docNumber + ' .lastRow td.lasttd').css({ paddingBottom: diff });
        }, 200);
    }

    nextLine(address) {
        return address = address.replace('แขวง', '\nแขวง')
    }

    render() {
        let req_2210 = true        
        this.props.otherTransaction.accountRecord.edges.forEach(accountRecord => {
                if (this.props.otherTransaction.refTransaction.startsWith("OR-") && accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("2210")
                    && accountRecord.node.name.includes("(unit")) {
                    req_2210 = false
                }
        })        

        let getType = getTypeProject(this.props.selfProject.typeOfProject)
        return (
            <React.Fragment>
                {
                    this.state.list_map.map((count_receive, index1) => {
                        let order = 0;
                        return (
                            Object.entries(this.state.page_record).map(([key, other_record], index) => {
                                this.getDiff(key, index, count_receive);
                                return (
                                    <React.Fragment key={index1}>
                                        <div className={"print-page-a4 " + key + '-' + index + '-' + count_receive + '-' + this.props.query.docNumber} id="receive" key={index}>
                                            <div className="subpage" id="style-document">
                                                <div className="head">
                                                    <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                                                        <div style={{ minHeight: 100, minWidth: 100 }}>
                                                            <img src={this.props.selfProject?.logo} alt="silverman" />
                                                        </div>
                                                        <div>
                                                            <strong>{this.props.selfProject.name}</strong>
                                                            {/* <Translation>{t=><div>{this.props.query.selfProject.address} {t("document:Tel")} {this.props.query.selfProject.juristicContactNumber || '-'}</div>}</Translation> */}
                                                            {
                                                                (this.props.selfProject.id === "UHJvamVjdE5vZGU6NDE2" || this.props.selfProject.id === "UHJvamVjdE5vZGU6MzQz") ?
                                                                    <div>
                                                                        <Translation>{t => <div className="address-header">{this.nextLine(this.props.selfProject.address)} </div>}</Translation>
                                                                        <Translation>{t => <div className="address-header">{t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                                                    </div>
                                                                    :
                                                                    <Translation>{t => <div className="address-header">{this.props.selfProject.address} {t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                                            }
                                                            <Translation>{t => <div>{t("document:Taxpayer identification number")} {this.props.selfProject.taxIdNumber || '-'}</div>}</Translation>
                                                        </div>
                                                    </div>
                                                    {/*<div className={this.props.query.otherReceive.contact.typeOfContact !== 'RESIDENTIAL' &&*/}
                                                    <div className="document-name">
                                                        <Translation>
                                                            {t =>
                                                                <React.Fragment>
                                                                    {
                                                                        t("document:Receipt") === "Receipt" ?
                                                                            <div className="title-head">Receipt</div>
                                                                            :
                                                                            <>
                                                                                <div className="title-head">ใบเสร็จรับเงิน</div>
                                                                                <div className="title-head">Receipt</div>
                                                                            </>
                                                                    }
                                                                </React.Fragment>}
                                                        </Translation>

                                                        {
                                                            count_receive === 1 ?
                                                                <Translation>{t => <div className="title small">({t("document:Original")})</div>}</Translation>
                                                                :
                                                                <Translation>{t => <div className="title small">({t("document:Copy")})</div>}</Translation>
                                                        }
                                                    </div>
                                                    {/*}*/}
                                                </div>

                                                {this.props.query.contact ?
                                                    <div className="detail">
                                                        <div className="customer-detail">
                                                            <Translation>{t => <strong>{t("document:ID")}:</strong>}</Translation> {this.props.query.contact.refNumber}<br />
                                                            {this.props.query.contact.typeOfContact === 'RESIDENTIAL' ?
                                                                <React.Fragment>
                                                                    <Translation>{t => <strong>{t("document:Customer")}: </strong>}</Translation>
                                                                    {getNameResidential(this.props.query.firstName, this.props.query.lastName)}
                                                                    <br />
                                                                </React.Fragment>
                                                                :
                                                                <React.Fragment>
                                                                    <Translation>{t => <strong>{t("document:Customer")}: </strong>}</Translation> {this.props.query.name} <br />
                                                                </React.Fragment>
                                                            }
                                                            <Translation>{t => <strong>{t("document:Address")}:</strong>}</Translation>

                                                            <div className="customer-detail address-space">
                                                                {
                                                                    this.props.query.address || this.props?.query?.contact ?
                                                                    <AddressDataShow
                                                                        from={true}
                                                                        report={true}
                                                                        query={this.props}
                                                                        bangkokCode={this.state.bangkokCode}
                                                                        address={
                                                                                this.props?.query?.address ?
                                                                                    {
                                                                                        address: this.props.query.address,
                                                                                        district: this.props.query.district,
                                                                                        city: this.props.query.city,
                                                                                        province: this.props.query.province,
                                                                                        postalCode: this.props.query.postalCode
                                                                                    }
                                                                                    :
                                                                                    {
                                                                                        address: this.props.query.contact.registeredAddress,
                                                                                        district: this.props.query.contact.registeredDistrict,
                                                                                        city: this.props.query.contact.registeredCity,
                                                                                        province: this.props.query.contact.registeredProvince,
                                                                                        postalCode: this.props.query.contact.registeredPostalCode,

                                                                                    }
                                                                        }
                                                                    />
                                                                    :
                                                                    ''
                                                                }
                                                                {
                                                                    (this.props.query.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.query.contact.typeOfSupplier === "COMPANY") &&
                                                                    <Translation>
                                                                        {t =>
                                                                            <React.Fragment>
                                                                                {
                                                                                    this.props.query.contact.typeOfCompany === "HEADQUARTERS" ?
                                                                                        t("document:Head office")
                                                                                        : this.props.query.contact.typeOfCompany === "BRANCH" &&
                                                                                        `${t("document:Branch")} ` + this.props.query.contact.nameBranch
                                                                                }
                                                                            </React.Fragment>}
                                                                    </Translation>
                                                                }
                                                            </div>
                                                            <br />

                                                            {/* พิเศษเฉพาะ site นี้ */}
                                                            {this.props.query.contact.typeOfContact === 'RESIDENTIAL' &&
                                                                //  localStorage.getItem('site_id') &&
                                                                //   localStorage.getItem('site_id') === '434' &&
                                                                <React.Fragment>
                                                                    <Translation>{t => <strong>{t("document:Tax ID")}:</strong>}</Translation> {this.props.query.contact.taxIdNumber || '-'} <br />
                                                                </React.Fragment>
                                                            }

                                                            {this.props.query.contact.typeOfContact !== 'RESIDENTIAL' &&
                                                                <React.Fragment>
                                                                    <Translation>{t => <strong>{t("document:Tax ID")}:</strong>}</Translation> { this.props.query.taxIdNumber} <br />
                                                                    <Translation>{t => <strong>{t("document:Attention")}:</strong>}</Translation>
                                                                    {this.props.query.firstName} {this.props.query.lastName}
                                                                    &emsp;{this.props.query.phone && "T: " + this.props.query.phone}&emsp;{this.props.query.email && "E: " + this.props.query.email}
                                                                </React.Fragment>
                                                            }
                                                            <br />

                                                            {/* <div className={this.props.query.otherReceive.contact.typeOfContact === 'RESIDENTIAL' ? "document-detail sub-document-detail" : "document-detail detail-outsider sub-document-detail"}>
                                                                <Translation>{t=><strong className={this.props.query.otherReceive.contact.typeOfContact !== 'RESIDENTIAL' ? "outsider sub-document-detail" : 'sub-document-detail'}>{t("document:No.")}:</strong>}</Translation> {this.props.query.otherReceive.docNumber} <br/>
                                                                <Translation>{t=><strong className={this.props.query.otherReceive.contact.typeOfContact !== 'RESIDENTIAL' ? "outsider sub-document-detail" : 'sub-document-detail'}>{t("document:Date")}:</strong>}</Translation> {format(this.props.query.otherReceive.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}<br/>
                                                                {this.props.query.otherReceive.contact.typeOfContact === 'RESIDENTIAL' &&
                                                                    <React.Fragment>
                                                                        <Translation>{t=><strong className="sub-document-detail">{getType === "highType" ? `${t("document:Room No.")}:` : `${t("document:House No.")}:`}</strong>}</Translation> {this.props.query.otherReceive.contact.name} &nbsp;<br/>
                                                                        <Translation>{t=><><strong className="sub-document-detail">{t("document:Ratio/Space")}:</strong> {numberWithComma(this.props.query.otherReceive.contact.residential.ownershipRatio)}/{numberWithComma(this.props.query.otherReceive.contact.residential.size)}</>}</Translation> <br/>
                                                                    </React.Fragment>
                                                                }
                                                            </div> */}
                                                        </div>
                                                        <div className={this.props.query.contact.typeOfContact === 'RESIDENTIAL' ? "document-detail sub-document-detail" : "document-detail detail-outsider sub-document-detail"}>
                                                            <Translation>{t => <strong className={this.props.query.contact.typeOfContact !== 'RESIDENTIAL' ? "outsider sub-document-detail" : 'sub-document-detail'}>{t("document:No.")}:</strong>}</Translation> {this.props.query.docNumber} <br />
                                                            <Translation>{t => <strong className={this.props.query.contact.typeOfContact !== 'RESIDENTIAL' ? "outsider sub-document-detail" : 'sub-document-detail'}>{t("document:Date")}:</strong>}</Translation> {format(this.props.query.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                                                            {this.props.query.contact.typeOfContact === 'RESIDENTIAL' &&
                                                                <React.Fragment>
                                                                    <Translation>{t => <strong className="sub-document-detail">{this.props.selfProject.typeOfProject === "CONDOMINIUM" ? `${t("document:Room No.")}:` : `${t("document:House No.")}:`}</strong>}</Translation> {this.props.query.contact.name} &nbsp;<br />
                                                                    <Translation>{t => <><strong className="sub-document-detail">{t("document:Ratio/Space")}:</strong> {numberWithComma(this.props.query.contact.residential.ownershipRatio)}/{numberWithComma(this.props.query.contact.residential.size)}</>}</Translation> <br />
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="detail">
                                                        <div className="customer-detail">
                                                            <Translation>{t => <strong>{t("document:Customer")}:</strong>}</Translation> {this.props.query.unknownContact || '-'}
                                                        </div>
                                                        <div className="document-detail detail-outsider">
                                                            <Translation>{t => <strong>{t("document:No.")}:</strong>}</Translation> {this.props.query.docNumber} <br />
                                                            <Translation>{t => <strong>{t("document:Date")}:</strong>}</Translation> {format(this.props.query.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                                                        </div>
                                                    </div>
                                                }


                                                <div className="invoice-body">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <Translation>
                                                                {t =>
                                                                    <tr>
                                                                        <th
                                                                            width={50}
                                                                            className="text-center"
                                                                            dangerouslySetInnerHTML={{ __html: t("document:No._br") }} />
                                                                        <th
                                                                            className="text-center"
                                                                            dangerouslySetInnerHTML={{ __html: t("document:Description_br") }} />

                                                                        <th
                                                                            width={90}
                                                                            className="text-center"
                                                                            dangerouslySetInnerHTML={{ __html: t("document:Amount_br") }} />
                                                                    </tr>}
                                                            </Translation>
                                                        </thead>
                                                        <tbody>
                                                            {other_record.map((transaction, index) => {
                                                                let text = transaction.node.description
                                                                if (transaction.node.keycardPerOtherReceiveTransaction.edges.length > 0) {
                                                                    text += "("
                                                                    text += i18n.t("other_received:No.Number")
                                                                    text += " "
                                                                    text += transaction.node.keycardPerOtherReceiveTransaction.edges[0].node.numberKey.noNumber
                                                                    text += ")"
                                                                }
                                                                return (<tr key={transaction.node.id}>
                                                                    <td className="text-center">{transaction.order}</td>
                                                                    <td>{text}</td>
                                                                    <td className="text-right">{numberWithComma(transaction.node.preTaxAmount)}</td>

                                                                    {/*{*/}
                                                                    {/*    this.props.query.otherReceive.contact.typeOfContact !== 'RESIDENTIAL' &&*/}
                                                                    {/*    this.state.have_vat ?*/}
                                                                    {/*        <React.Fragment>*/}
                                                                    {/*            <td className="text-right">{numberWithComma(transaction.node.transaction.unitItems)}</td>*/}
                                                                    {/*            <td className="text-right">{numberWithComma(transaction.node.transaction.price)}</td>*/}
                                                                    {/*            <td className="text-right">{numberWithComma(transaction.node.transaction.price * transaction.node.transaction.unitItems)}</td>*/}
                                                                    {/*        </React.Fragment>*/}
                                                                    {/*        :*/}
                                                                    {/*        <React.Fragment>*/}
                                                                    {/*            <td className="text-right">{numberWithComma(transaction.node.amount)}</td>*/}
                                                                    {/*        </React.Fragment>*/}
                                                                    {/*}*/}
                                                                </tr>
                                                                )
                                                            })


                                                            }

                                                        </tbody>
                                                        <tfoot>
                                                            {/*{*/}
                                                            {/*    this.props.query.otherReceive.contact.typeOfContact !== 'RESIDENTIAL' &&*/}
                                                            {/*    this.state.have_vat &&*/}
                                                            {/*        <React.Fragment>*/}
                                                            {/*            <tr>*/}
                                                            {/*                <td colSpan={3} rowSpan={*/}
                                                            {/*                    !this.state.discount_status ? 4 :*/}
                                                            {/*                    this.state.discount_status && 6*/}
                                                            {/*                }/>*/}
                                                            {/*                <td colSpan={2}>*/}
                                                            {/*                    จำนวนเงิน/Amount*/}
                                                            {/*                </td>*/}
                                                            {/*                <td className="text-right">*/}
                                                            {/*                    {numberWithComma(this.state.sum_amount,0)}*/}
                                                            {/*                </td>*/}
                                                            {/*            </tr>*/}

                                                            {/*            {*/}
                                                            {/*                this.state.discount_status &&*/}
                                                            {/*                <React.Fragment>*/}
                                                            {/*                    <tr>*/}
                                                            {/*                        <td colSpan={2}>*/}
                                                            {/*                            <strong>หัก&nbsp;</strong>ส่วนลด/Discount*/}
                                                            {/*                        </td>*/}
                                                            {/*                        <td className="text-right">*/}
                                                            {/*                            {numberWithComma(this.props.query.otherReceive.otherReceiveTransaction.edges.length > 0 &&*/}
                                                            {/*                                this.props.query.otherReceive.otherReceiveTransaction.edges[0].node.transaction.discount, 0)}*/}
                                                            {/*                        </td>*/}
                                                            {/*                    </tr>*/}
                                                            {/*                    <tr>*/}
                                                            {/*                        <td colSpan={2}>*/}
                                                            {/*                            จำนวนเงินหลังหักส่วนลด/Total Discount*/}
                                                            {/*                        </td>*/}
                                                            {/*                        <td className="text-right">*/}
                                                            {/*                            {numberWithComma(this.state.sum_discount, 0)}*/}
                                                            {/*                        </td>*/}
                                                            {/*                    </tr>*/}
                                                            {/*                </React.Fragment>*/}
                                                            {/*            }*/}

                                                            {/*            <tr>*/}
                                                            {/*                <td colSpan={2}>*/}
                                                            {/*                    <strong>หัก&nbsp;</strong>รับชำระล่วงหน้า/Receipt Deposit*/}
                                                            {/*                </td>*/}
                                                            {/*                <td className="text-right">*/}
                                                            {/*                    {numberWithComma(this.state.receiveDeposit, 0)}*/}
                                                            {/*                </td>*/}
                                                            {/*            </tr>*/}

                                                            {/*            <tr>*/}
                                                            {/*                <td colSpan={2}>*/}
                                                            {/*                    จำนวนเงินหลังหักรับชำระล่วงหน้า/Total Receipt*/}
                                                            {/*                    Deposit*/}
                                                            {/*                </td>*/}
                                                            {/*                <td className="text-right">*/}
                                                            {/*                    {numberWithComma(this.state.sum_receive_deposit, 0)}*/}
                                                            {/*                </td>*/}
                                                            {/*            </tr>*/}

                                                            {/*            <tr>*/}
                                                            {/*                <td colSpan={2}>*/}
                                                            {/*                    ภาษีมูลค่าเพิ่ม (บาท)/VAT*/}
                                                            {/*                </td>*/}
                                                            {/*                <td className="text-right">*/}
                                                            {/*                    {numberWithComma(this.state.sum_vat, 0)}*/}
                                                            {/*                </td>*/}
                                                            {/*            </tr>*/}

                                                            {/*        </React.Fragment>*/}
                                                            {/*}*/}
                                                            {/* {index+1 !== Object.keys(this.state.page_record).length && */}
                                                            <tr className="lastRow">
                                                                <td style={{ borderTop: 'none' }} className="lasttd" />
                                                                <td style={{ borderTop: 'none' }} className="lasttd" />
                                                                <td style={{ borderTop: 'none' }} className="lasttd" />
                                                            </tr>
                                                            {/* } */}

                                                            {index + 1 === Object.keys(this.state.page_record).length &&
                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        <div className="row">
                                                                            <div className="col-1">
                                                                                <Translation>{t => <strong>{t("document:Total")}</strong>}</Translation>
                                                                            </div>
                                                                            <div className="col-10 text-center">
                                                                                <strong>({ThaiBaht(this.props.query.total)})</strong>
                                                                            </div>
                                                                            <div className="col-1" />
                                                                        </div>
                                                                    </td>

                                                                    <td className="text-right">
                                                                        <strong>{numberWithComma(this.props.query.total, 0)}</strong>
                                                                    </td>
                                                                    {/*{*/}
                                                                    {/*    this.props.query.otherReceive.contact.typeOfContact !== 'RESIDENTIAL' &&*/}
                                                                    {/*    this.state.have_vat ?*/}
                                                                    {/*        <td colSpan={3} className="text-center">*/}
                                                                    {/*            ({ThaiBaht(this.state.sum_all)})*/}
                                                                    {/*        </td>*/}
                                                                    {/*        :*/}
                                                                    {/*        <td colSpan={3}>*/}
                                                                    {/*            <div className="row">*/}
                                                                    {/*                <div className="col-1">*/}
                                                                    {/*                    รวม/Total*/}
                                                                    {/*                </div>*/}
                                                                    {/*                <div className="col-10 text-center">*/}
                                                                    {/*                    ({this.props.query.otherReceive.otherReceiveTransaction.edges.length > 0 &&*/}
                                                                    {/*                ThaiBaht(this.props.query.otherReceive.otherReceiveTransaction.edges[0].node.amount)})*/}
                                                                    {/*                </div>*/}
                                                                    {/*                <div className="col-1"/>*/}
                                                                    {/*            </div>*/}
                                                                    {/*        </td>*/}
                                                                    {/*}*/}

                                                                    {/*{*/}
                                                                    {/*    this.props.query.otherReceive.contact.typeOfContact !== 'RESIDENTIAL' &&*/}
                                                                    {/*    this.state.have_vat ?*/}
                                                                    {/*        <React.Fragment>*/}
                                                                    {/*            <td colSpan={2}>*/}
                                                                    {/*                จำนวนเงินรวมทั้งสิ้น/Grand Total*/}
                                                                    {/*            </td>*/}
                                                                    {/*            <td className="text-right">*/}
                                                                    {/*                {numberWithComma(this.state.sum_all)}*/}
                                                                    {/*            </td>*/}
                                                                    {/*        </React.Fragment>*/}
                                                                    {/*        :*/}
                                                                    {/*        <React.Fragment>*/}
                                                                    {/*            <td className="text-right">*/}
                                                                    {/*                {this.props.query.otherReceive.otherReceiveTransaction.edges.length > 0 &&*/}
                                                                    {/*                numberWithComma(this.props.query.otherReceive.otherReceiveTransaction.edges[0].node.amount)}*/}
                                                                    {/*            </td>*/}
                                                                    {/*        </React.Fragment>*/}
                                                                    {/*}*/}
                                                                </tr>}
                                                        </tfoot>
                                                    </table>

                                                    {index + 1 === Object.keys(this.state.page_record).length &&
                                                        <React.Fragment>
                                                            <div className="remark">
                                                                <Translation>{t => <strong>{t("document:Remarks")}:</strong>}</Translation> <br />
                                                            </div>
                                                            <div className="remark">
                                                                {this.props.remark}<br />
                                                            </div>
                                                            <div className="remark">
                                                                <Translation>{t => <strong>{t("document:Conditions of Payments")}</strong>}</Translation><br />
                                                                {this.props.cash_transaction.length > 0 &&
                                                                    <div className="row col ml-1" key="cash">
                                                                        <Translation>{t => <span className="align-middle">{t("document:Cash")} </span>}</Translation>
                                                                        <div className="col">
                                                                            <Translation>{t => <span>{t("document:Amount_")} {numberWithComma(this.state.total_cash_pay)} {t("document:Baht")} </span>}</Translation>
                                                                        </div>
                                                                    </div>

                                                                }
                                                                {this.props.bank_transaction.length > 0 &&
                                                                    <div className="col ml-1" key="bank">
                                                                        <Translation>{t => <span className="align-middle">{t("document:Bank Transfer")} </span>}</Translation><br />
                                                                        <div className="col ml-2">
                                                                            {this.props.bank_transaction.map((bank, index_bank) => {
                                                                                return <div className="row" key={bank.node.bankAccount.accountNumber + index_bank}><Translation>{t => <span key={bank.node.bankAccount.accountNumber}> - {bank.node.bankAccount.bankName}&emsp;{`${t("document:Account No.")} ` + bank.node.bankAccount.accountNumber}&emsp;{`${t("document:Date_")} ` + format(bank.node.date, 'DD/MM/YYYY', { locale: thLocale })}&emsp;{`${t("document:Amount_")} ` + numberWithComma(bank.node.price)} {t("document:Baht")}<br /></span>}</Translation></div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {this.props.cheque_transaction.length > 0 &&
                                                                    <div className="col ml-1" key="cheque">
                                                                        <Translation>{t => <span className="align-middle"> {t("document:Cheque Bank")} </span>}</Translation><br />
                                                                        <div className="col ml-2">
                                                                            {this.props.cheque_transaction.map((cheque, index_cheque) => {
                                                                                return <div className="row" key={cheque.node.chequeNumber + index_cheque}><Translation>{t => <span key={cheque.node.chequeNumber}>- {t("document:Cheque No.")} {cheque.node.chequeNumber}&emsp;{cheque.node.bankName}&emsp;{t("document:Branch")} {cheque.node.branchNumber}&emsp;{t("document:Date_")} {format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale })}&emsp;{t("document:Amount_baht")}&nbsp;&nbsp;{numberWithComma(cheque.node.price)} {t("document:Baht")} <br /></span>}</Translation></div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {this.props.credit_card_transaction.length > 0 &&
                                                                    <div className="row col ml-1" key="credit-card">
                                                                        <Translation>{t => <span className="align-middle">{t("document:Credit Card")}</span>}</Translation>
                                                                        <div className="col">
                                                                            <Translation>{t => <span>{t("document:Amount_")} {numberWithComma(this.state.total_credit_card_pay)} {t("document:Baht")} </span>}</Translation>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {this.state.sum_wht_rate > 0 &&
                                                                    <div className="row col ml-1" key="With holding tax">
                                                                        <Translation>{t => <span className="align-middle">{t("document:With holding tax")}</span>}</Translation>
                                                                        <div className="col">
                                                                            <Translation>{t => <span>{t("document:Amount_")} {numberWithComma(this.state.sum_wht_rate)} {t("document:Baht")} </span>}</Translation>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {this.state.total_unknown_pay > 0 &&
                                                                    <div className="row col ml-1" key="unknown-receive">
                                                                        <Translation>{t => <span className="align-middle">{t("document:Unknown Receive")}</span>}</Translation>
                                                                        <div className="col">
                                                                            <Translation>{t => <span>{t("document:Amount_")} {numberWithComma(this.state.total_unknown_pay)} {t("document:Baht")} </span>}</Translation>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {(parseFloat(this.state.total_other_pay) !== 0.0) &&
                                                                    (this.props.query.otherTransaction && this.props.query.otherTransaction.length > 0 && req_2210) &&
                                                                    <div className="col ml-1" key="other">
                                                                        <Translation>{t => <span className="align-middle">{t("document:Other")}</span>}</Translation><br />
                                                                        <div className="col">
                                                                            {this.props.query.otherTransaction[0].node.accountRecord.edges.map((other) => {

                                                                                let filterOTSum = 0;
                                                                                let checkfilterOTSum = 0;

                                                                                if (other.node.chartOfAccountCode.chartOfAccountCode.includes("5830")) {
                                                                                    let filterOT = _.filter(this.props.query.otherTransaction.otherReceiveTransaction, (o) => o[0].node?.accountRecord?.id === other?.node?.id)[0]

                                                                                    if (filterOT) {
                                                                                        filterOTSum = other.node?.credit - filterOT[0].node?.total;
                                                                                        checkfilterOTSum = filterOT[0].node?.total;
                                                                                    }
                                                                                }

                                                                                if (other.node.chartOfAccountCode.chartOfAccountCode.includes("5830") && other.node?.credit === checkfilterOTSum) {
                                                                                    return null;
                                                                                } else {
                                                                                    return (
                                                                                        this.isOther(other.node.chartOfAccountCode.chartOfAccountCode) || other.node.chartOfAccountCode.chartOfAccountCode.includes("5830") &&
                                                                                        <div key={other.node.id} className="row">
                                                                                            <Translation>{t => <span key={other.node.id}>- {other.node.chartOfAccountCode.name} {t("document:Amount_")}

                                                                                                {filterOTSum ? " " + numberWithComma(filterOTSum) : other.node.credit > 0 ? " " + numberWithComma(other.node.credit) : numberWithComma(other.node.debit)} {t("document:Baht")} <br /></span>}
                                                                                            </Translation>
                                                                                        </div>
                                                                                    )


                                                                                }
                                                                                // return(

                                                                                //     this.isOther(other.node.chartOfAccountCode.chartOfAccountCode) && 

                                                                                // <div key = {other.node.id} className="row">
                                                                                //     <Translation>{t=><span key = {other.node.id}>- {other.node.chartOfAccountCode.name } {t("document:Amount_")}

                                                                                //      {other.node.credit > 0 ? numberWithComma(other.node.credit) : numberWithComma(other.node.debit)} {t("document:Baht")} <br/></span>}
                                                                                //      {/* {other.node.credit > 0 ? numberWithComma(other.node.credit) : numberWithComma(other.node.debit)} {t("document:Baht")} <br/></span>} */}
                                                                                //     </Translation>
                                                                                // </div>      
                                                                                // )
                                                                            }
                                                                            )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }


















                                                                {/* <div className="row">
                                                            <div className="col-5 ml-3">
                                                                <div className="row">
                                                                    <div className="col-1">
                                                                        {parseFloat(this.state.total_other_pay) !== 0.0 &&
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/correct-signal.png'}
                                                                            alt="correct-signal" className="collect"/>
                                                                        }
                                                                    </div>
                                                                    <div className="col ml-2">
                                                                        อื่น ๆ/Other
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(parseFloat(this.state.total_other_pay) !== 0.0) &&
                                                                (this.props.otherTransaction && this.props.otherTransaction.length > 0) &&
                                                                    <div className = "col ">
                                                                        {this.props.otherTransaction[0].node.accountRecord.edges.map((other) => (
                                                                            this.isOther(other.node.chartOfAccountCode.chartOfAccountCode) && 
                                                                            [   
                                                                                <div key = {other.node.id}>
                                                                                    <span key = {other.node.id}> {other.node.chartOfAccountCode.name } จำนวน {other.node.credit > 0 ? numberWithComma(other.node.credit) : numberWithComma(other.node.debit)} บาท</span>
                                                                                </div>      
                                                                            ]
                                                                        ))
                                                                        }
                                                                    </div>
                                                                
                                                            }
                                                        </div> */}
                                                            </div>
                                                        </React.Fragment>
                                                    }

                                                </div>
                                                {index + 1 === Object.keys(this.state.page_record).length &&
                                                    <div className="footer">
                                                        <div className="signature">
                                                            <div className="left">
                                                                ...................................................... <br />
                                                                <Translation>{t => <strong>{t("document:Authorized")}</strong>}</Translation>
                                                            </div>
                                                            <div className="right">
                                                                ...................................................... <br />
                                                                <Translation>{t => <strong>{t("document:Receiver")}</strong>}</Translation>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="number-page">
                                                    <br />
                                                    {index + 1}/{this.state.end_page}
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            )
                        )
                    })
                }

            </React.Fragment>
        );
    }
}

export default OtherReceiverAll;
