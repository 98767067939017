import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import localStoragePage from "../../../../libs/localstorage";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import getNameResidential from "../../../../libs/getNameResidential";
import i18next from "i18next";


class PrepaidDepositReportTable extends React.Component {

    getColorAndText(status) {
        let text = '';
        let color = 'text-center';
        switch (status) {
            case 'DRAFT':
                text = 'แบบร่าง';
                break;
            case 'ACTIVE':
                text = 'ยังไม่ตัดชำระ';
                break;
            case 'USE_PARTIAL':
                text = 'ตัดจ่ายชำระแล้วบางส่วน';
                break;
            case 'USE_ALL':
                text = 'ตัดจ่ายชำระแล้วทั้งจำนวน';
                break;
            default:
                text = 'รายการที่ยกเลิก';
                color = 'text-center text-danger';
        }
        return <>{text}</>
    }

    render() {
        let order_id = this.props.state.first - localStoragePage.getPageLimit();
        let total = 0.0;
        let color 
        let status_void = false
        let cut_pay = 0;
        let total_balance_all = 0;
        return (
            <React.Fragment>
                {this.props.props.prepaidDepositViewer.allPrepaidDeposit.edges.map((prepaid_deposit, index)=>{
                    let balance = prepaid_deposit.node.prepaidDepositTransaction?.edges[0]?.node.balance
                    let amount = (prepaid_deposit.node.prepaidDepositTransactionUsed?.edges[0]?.node.amount ? prepaid_deposit.node.prepaidDepositTransactionUsed?.edges[0]?.node.amount : 0)
                    total += prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.total;
                    status_void = prepaid_deposit.node.status === 'VOID'?true:false
                    color = status_void?{color:'red'}:null
                    cut_pay += amount
                    total_balance_all += balance
                    return(
                        <tr key={prepaid_deposit.node.id}>
                            <td style={color} className="text-center">{order_id + index +1 }</td>
                            <td style={color} className="text-center">{prepaid_deposit.node.docNumber}</td>
                            <td style={color} className="text-center">{format(prepaid_deposit.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                            <td style={color} className="text-center">{prepaid_deposit.node.contact.refNumber}</td>
                            <td style={color} >{prepaid_deposit.node.contact.typeOfContact === 'RESIDENTIAL' ? getNameResidential(prepaid_deposit.node.contact.firstName, prepaid_deposit.node.contact.lastName) : prepaid_deposit.node.contact.name}</td>
                            <td style={color} >{prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.description}</td>
                            <td style={color} className="text-right">{ status_void?'-': numberWithComma(prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.total)}</td>
                            {this.props.state.status === 'use_partial' && <>
                                <td style={color} className="text-center">{numberWithComma(amount)}</td>
                                <td style={color} className="text-center">{numberWithComma(balance)}</td>
                            </>}
                            <td style={color} className="text-center">
                                {prepaid_deposit.node.bankAccountTransaction.totalCount > 0 && "เงินโอน"}
                                {prepaid_deposit.node.cashTransaction.totalCount > 0 && "เงินสด"}
                                {prepaid_deposit.node.chequeTransaction.totalCount > 0 && "เช็ค"}
                            </td>
                            <td style={color} className="text-center">{this.getColorAndText(prepaid_deposit.node.status)}</td>
                        </tr>
                    )
                })}
                {
                    (this.props.props.prepaidDepositViewer.allPrepaidDeposit.pageInfo.hasNextPage || this.props.props.prepaidDepositViewer.allPrepaidDeposit.pageInfo.hasPreviousPage) &&
                    <tr>
                        <td colSpan={6} className="text-right"><strong>{i18next.t("PrepaidDepositReport:Sum")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(total)}</strong></td>
                        <td/>
                    </tr>
                }
                {!this.props.props.prepaidDepositViewer.allPrepaidDeposit.pageInfo.hasNextPage &&
                <tr>
                    <td colSpan={6} className="text-right"><strong>{i18next.t("PrepaidDepositReport:Total")}</strong></td>
                    <td className="text-right">
                        <strong>{numberWithComma(this.props.props.prepaidDepositViewer.summaryPrepaidDeposit)}</strong>
                    </td>
                    {this.props.state.status === 'use_partial' && <>
                        <td style={color} className="text-center">{numberWithComma(cut_pay)}</td>
                        <td style={color} className="text-center">{numberWithComma(total_balance_all)}</td>
                    </>}
                    <td/>
                    <td/>
                </tr>
                }
            </React.Fragment>
        )
    }
}

export default PrepaidDepositReportTable;
