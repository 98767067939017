import axios from "axios";

// var token_id = localStorage.getItem("token");
// var header = {
//     // authorization: `JWT ${token_id}`,
//     "Content-Type": "application/json",
// };


function getDataFromIdCard(dataIdCard) {
    return axios.get(`https://openapi.dbd.go.th/api/v1/juristic_person/${dataIdCard}`);
}

export default {
    getDataFromIdCard
}