import React, { Component } from "react";
import Header from "../../../../components/header/index";
import Sidebar from "../../../../components/sidebar/index";
import Wrapper from "../../../../components/wrapper/index";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import { Translation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import ReceiveDetailTable from "./receiveDetailTable";
import numberWithComma from "../../../../libs/numberWithComma";
import getNameResidential from "../../../../libs/getNameResidential";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { graphql } from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import { commitMutation } from "react-relay";
import environment from "../../../../env/environment";
import Redirect from "react-router-dom/es/Redirect";
import PayChannel from "../../../expense/pay/payChannel";
import getDocumentNumber from "../../../../libs/getDocumentNumber";
import InputAddress from "../../../../libs/autoCompleteAddress";
import CreateEditorDocumentLogMutation from "../../invoice/invoice_detail/mutations/CreateEditorDocumentLogMutation"


import _ from "lodash";
import { Modal } from 'react-bootstrap';
import i18next from "i18next";
import ExportReceiveDetailPDF from "./receiveDetailPDF";

const mutation = graphql`
  mutation receiveDetailMutation($input: UpdateReceiveInput!) {
    updateReceive(input: $input) {
      warningText
      newReceive {
        id
      }
    }
  }
`;
const receiveEditKey =
{
  firstName: "",
  lastName: "",
  refNumber: "",
  phone: "",
  email: "",
  taxIdNumber: "",
  registeredAddress: "",
  registeredCity: "",
  registeredCountry: "",
  registeredDistrict: "",
  registeredName: "",
  registeredPostalCode: "",
  registeredProvince: "",
}
class ReceiveDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issuedDate: "",
      dueDate: "",
      dateCount: "",
      status: "",
      redirect: false,
      transaction_list: [props.transactions],
      receiveDeposit: 0,
      loading: false,
      receipt_deposit_list: [],
      unknown_receive_list: props.unknown_receive,
      credit_note_receive_list: props.credit_note,
      max_date_invoice: new Date(),
      remark: this.props.detail.receive.remark ? this.props.detail.receive.remark : "",
      showModal: false,
      invoiceEdit: []
    };

    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.handleInputChangeReceive = this.handleInputChangeReceive.bind(this)
    this.onSelect = this.onSelect.bind(this);
    this.sendLog = this.sendLog.bind(this);


  }

  componentDidMount() {
    let receive_transaction_list = [...this.props.transactions];
    let receipt_deposit_list = [];

    receive_transaction_list.forEach((receive_transaction) => {
      if (receive_transaction.node.receiptDepositTransactionUsed.edges.length > 0) {
        receipt_deposit_list = [...receipt_deposit_list, ...receive_transaction.node.receiptDepositTransactionUsed.edges,];

        receipt_deposit_list = _.sortBy(receipt_deposit_list, e => e.node.receiptDepositTransaction.receiptDeposit.docNumber)
      }
    });
    this.setState({ receipt_deposit_list });
    let allDate = [];
    if (this.props.transactions.length > 1) {
      this.props.transactions.forEach((value) => {
        allDate.push(new Date(value.node.transaction.invoice.issuedDate))
      })
    } else {
      allDate.push(new Date(this.props.transactions[0].node.transaction.invoice.issuedDate))
    }
    let sortDate = allDate.sort((date1, date2) => {
      return date1 - date2
    })
    let max_issuedDate = 0
    if (sortDate.length > 0) {
      max_issuedDate = sortDate.length - 1
    }
    this.setState({
      max_date_invoice: new Date(sortDate[max_issuedDate])
    })

    // for edit receive detail 
    let state = { ...this.state };

    if (this.props.contact) {
      let key = Object.keys(this.props.contact);
      key.forEach((key) => {
        state[key] = this.props.contact[key];
      });
    } else {
      receiveEditKey.forEach((key) => {
        state[key] = '';
      });
    }

    _.set(state, `firstName`, this.props.detail.receive.receiveTransaction.edges[0].node.transaction.invoice?.firstName)
    _.set(state, `lastName`, this.props.detail.receive.receiveTransaction.edges[0].node.transaction.invoice?.lastName)

    this.setState({
      invoiceEdit: state
    });
  }

  onChangeStatus(status = "void") {
    let action_msg = i18next.t('createReceive:Cancel');
    let isOverDate = false;
    if (this.state.max_date_invoice.getTime() > new Date(this.props.detail.receive.issuedDate).getTime()) {
      isOverDate = true;
    }

    if (this.props.detail.receive.status === "WAIT") {
      if (status === "paid") {
        action_msg = i18next.t('receiveDetail:Confirm');
      } else {
        action_msg = i18next.t('receiveDetail:Cancel');
      }
    }

    if (isOverDate && status === "paid") {
      Swal.fire(
        i18next.t("receiveDetail:Unable to confirm the payment."),
        i18next.t("receiveDetail:As the invoice date has not yet arrived, the system cannot process the transaction. Please proceed by recording an advance payment only."),
        "warning"
      );
      this.setState({ loading: false });
    } else {
      Swal.fire({
        title: i18next.t('receiveDetail:Have to') + action_msg + i18next.t('receiveDetail:Receive'),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: i18next.t('createReceive:Cancel'),
        confirmButtonText: i18next.t('createReceive:Confirm'),
      }).then(async (result) => {
        if (result.value) {
          let void_remark = "";
          if (status === "void") {
            let value = await Swal.fire({
              title: i18next.t('createReceive:Note'),
              input: "text",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              cancelButtonText: i18next.t('createReceive:Cancel'),
              confirmButtonText: i18next.t('receiveDetail:Confirm'),
              inputValidator: (value) => {
                if (!value) {
                  return i18next.t('receiveDetail:Please enter a cancellation note');
                }
              },
            });
            void_remark = value.value;
          }

          if ((status === "void" && void_remark) || status !== "void") {
            this.setState({ loading: true });
            let variables = {
              input: {
                clientMutationId: this.props.detail.receive.id,
                status: status,
                voidRemark: void_remark,
                remark: this.state.remark,
              },
            };

            commitMutation(environment, {
              mutation,
              variables,
              onCompleted: (response) => {
                if (response.updateReceive.newReceive) {
                  Swal.fire(
                    (action_msg === i18next.t("Allaction:Confirm") || action_msg === "Confirm") ?
                      i18next.t('receiveDetail:Confirmed payment successfully!')
                      : i18next.t('receiveDetail:Canceled successfully!'),
                    "",
                    "success"
                  ).then(() => {
                    this.setState({ redirect: true, loading: false });
                  });
                } else {
                  Swal.fire(
                    // action_msg + 
                    i18next.t('receiveDetail:Failed to cancel the receipt!'),
                    response.updateReceive.warningText,
                    "warning"
                  );
                  this.setState({ loading: false });
                }
              },
              onError: (err) => {
                Swal.fire("Error!", i18next.t('createReceive:Please try again'), "warning");
                this.setState({ loading: false });
              },
            });
          }
        } else {
          this.setState({ loading: false });
        }
      });
    }
  }

  getTotalReceive() {
    let sum_bank = this.props.detail.receive.bankAccountTransactionReceive.edges.reduce(
      function (total, obj) {
        return total + parseFloat(obj.node.price);
      },
      0
    );
    let sum_cash = this.props.detail.receive.cashDepositReceiveTransaction.edges.reduce(
      function (total, obj) {
        return total + parseFloat(obj.node.price);
      },
      0
    );
    let sum_cheque = this.props.detail.receive.chequeDeposit.edges.reduce(
      function (total, obj) {
        return total + parseFloat(obj.node.price);
      },
      0
    );
    let sum_payment_gateway = this.props.detail.receive.paymentGatewayMethod.edges.reduce(
      function (total, obj) {
        return total + (parseFloat(obj.node.amount) + parseFloat(obj.node.feeAmount));
      },
      0
    );

    let sum_credit_card = 0;
    if (
      this.props.detail.receive.creditCardTransaction &&
      this.props.detail.receive.creditCardTransaction.edges.length > 0
    ) {
      sum_credit_card = this.props.detail.receive.creditCardTransaction.edges.reduce(
        function (total, obj) {
          return total + parseFloat(obj.node.amount);
        },
        0
      );
    }

    return sum_bank + sum_cash + sum_cheque + sum_credit_card + sum_payment_gateway;
  }

  handleInputChange(e) {
    let event = _.cloneDeep(e);

    this.setState(prevState => {
      return _.set(
        _.cloneDeep(prevState),
        event.target.name,
        event.target.value
      );
    });
  }

  handleInputChangeReceive(e) {
    let name = e.target.name;
    let value = e.target.value;
    let state = _.set(_.cloneDeep(this.state), name, value);

    this.setState(state)

  }

  onSelect(fullAddress) {
    const { registeredDistrict, registeredCity, registeredProvince, registeredPostalCode } = fullAddress


    let value = _.cloneDeep(this.state.invoiceEdit);
    _.set(value, 'registeredDistrict', registeredDistrict)
    _.set(value, 'registeredCity', registeredCity)
    _.set(value, 'registeredProvince', registeredProvince)
    _.set(value, 'registeredPostalCode', registeredPostalCode)

    this.setState({
      invoiceEdit: value
    })

  }

  onChangeEdit() {
    Swal.fire({
      title: i18next.t('receiveDetail:Are you sure to edit this receive?'),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: i18next.t('invoiceDetail:No'),
      confirmButtonText: i18next.t('invoiceDetail:Yes'),
    }).then(async (result) => {
      if (result.value) {
        this.setState({ showModal: true });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  sendLog(e) {
    e.preventDefault();

    this.props.history.push({
      pathname: `/document/receive/${this.props.detail.receive.id}/${this.props.detail.receive.docNumber}`,
      state: {
        firstName: this.state.invoiceEdit.firstName,
        lastName: this.state.invoiceEdit.lastName,
        taxIdNumber: this.state.invoiceEdit.taxIdNumber,
        registeredAddress: this.state.invoiceEdit.registeredAddress,
        registeredCity: this.state.invoiceEdit.registeredCity,
        registeredProvince: this.state.invoiceEdit.registeredProvince,
        registeredCountry: this.state.invoiceEdit.registeredCountry,
        registeredDistrict: this.state.invoiceEdit.registeredDistrict,
        registeredPostalCode: this.state.invoiceEdit.registeredPostalCode,
        email: this.state.invoiceEdit.email,
        phone: this.state.invoiceEdit.phone,
      },
    })

    let input = {
      docNumber: this.props.detail.receive.docNumber,
      firstName: this.state.invoiceEdit.firstName,
      lastName: this.state.invoiceEdit.lastName,
      taxIdNumber: this.state.invoiceEdit.taxIdNumber,
      phone: this.state.invoiceEdit.phone,
      email: this.state.invoiceEdit.email,
      address: this.state.invoiceEdit.registeredAddress,
      city: this.state.invoiceEdit.registeredCity,
      province: this.state.invoiceEdit.registeredProvince,
      country: this.state.invoiceEdit.registeredCountry,
      district: this.state.invoiceEdit.registeredDistrict,
      postalCode: this.state.invoiceEdit.registeredPostalCode,

    };

    CreateEditorDocumentLogMutation(
      input,
      (response) => {
        // console.log(response)
      }
    );



  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/accounting/income/receive/list/all" />;
    }


    let firstName = this.props.detail.receive.receiveTransaction.edges[0].node.transaction.invoice?.firstName;  // get from contact
    let lastName = this.props.detail.receive.receiveTransaction.edges[0].node.transaction.invoice?.lastName;

    return (

      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="invoice-detail">
            <div className="row justify-content-between pb-2">
              <div className="col">
                <h3 className="mb-4 contain-inner">
                  <Link to="/accounting/income/receive/list/all">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("accounting:receive")+ " " + "(RT)"}</span>}
                  </Translation>
                </h3>
              </div>

              {this.props.detail.receive.status !== "WAIT" && (
                <Translation>
                  {t =>
                    <div className="col text-right colorUse">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "accounting_receive_delete",
                      }) && (
                          <button
                            className="btn btn-danger add mr-3"
                            disabled={this.state.loading}
                            onClick={() => this.onChangeStatus()}
                          >
                            {this.state.loading && (
                              <span className="spinner-border spinner-border-sm align-middle mr-2" />
                            )}
                            {t('receiveDetail:Cancel this receipt')}
                          </button>
                        )}

                      <button
                        className="btn btn-create add mr-3"
                        disabled={this.state.loading}
                        onClick={this.onChangeEdit}
                      >
                        {this.state.loading && (
                          <span className="spinner-border spinner-border-sm align-middle mr-2" />
                        )}
                        {t('receiveDetail:Edit receive')}
                      </button>

                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "accounting_receive_print",
                      }) && (                        
                        localStorage.getItem('site_id') === '199' ?
                        <ExportReceiveDetailPDF
                          dataExport={[this.props.detail.receive]}
                          selfProject={this.props.detail.selfProject}
                          btnInDetail={true}
                        />:
                          <Link
                            to={
                              "/document/receive/" +
                              this.props.detail.receive.id +
                              "/" +
                              this.props.detail.receive.docNumber
                            }
                            target={"_blank"}>
                            <button
                              className="btn btn-primary add"
                              disabled={this.state.loading}
                            >
                              {t('receiveDetail:Print this receipt')}
                            </button>
                          </Link>
                        )}
                    </div>
                  }
                </Translation>
              )}
            </div>

            <Modal show={this.state.showModal} dialogClassName="modal-approve" id="modal" >
              <Modal.Body style={{ fontFamily: 'Kanit', borderBottom: 'none' }}>
                <div>
                  <form onSubmit={this.sendLog}>
                    <h3 className="pt-2 pb-2">{i18next.t("editAddress:Edit customer name-surname / address")}</h3>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label htmlFor="name">{i18next.t("editAddress:Name")}</label>
                        <input type="text" class="form-control" id="firstName" name="invoiceEdit.firstName" value={this.state.invoiceEdit.firstName}
                          onChange={this.handleInputChangeReceive}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label htmlFor="surname">{i18next.t("editAddress:Surname")}</label>
                        <input type="text" class="form-control" id="lastName" name="invoiceEdit.lastName" value={this.state.invoiceEdit.lastName}
                          onChange={this.handleInputChangeReceive} />
                      </div>
                    </div>
                    <div class="form-group">
                      <label htmlFor="taxIdNumber">{i18next.t("editAddress:Tax payer identification/Identification number")}</label>
                      <input type="text" class="form-control" id="taxIdNumber" name="invoiceEdit.taxIdNumber" value={this.state.invoiceEdit.taxIdNumber}
                        onChange={this.handleInputChangeReceive} />
                    </div>
                    <div class="form-group">
                      <label htmlFor="inputAddress2">{i18next.t("editAddress:Address")}</label>
                      <textarea cols="" rows="3" className="form-control" id="address"
                        name="invoiceEdit.registeredAddress" maxLength={256} value={this.state.invoiceEdit.registeredAddress} onChange={this.handleInputChangeReceive} />
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label htmlFor="district">{i18next.t("editAddress:Sub-district")}</label>
                        <InputAddress
                          address="invoiceEdit.registeredDistrict"
                          value={this.state.invoiceEdit.registeredDistrict}
                          onChange={this.handleInputChangeReceive}
                          onSelect={this.onSelect}
                          mailing={false}
                          className="form-control"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label htmlFor="city">{i18next.t("editAddress:District")}</label>
                        <InputAddress
                          address="invoiceEdit.registeredCity"
                          value={this.state.invoiceEdit.registeredCity}
                          onChange={this.handleInputChangeReceive}
                          onSelect={this.onSelect}
                          mailing={false}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label htmlFor="inputState">{i18next.t("editAddress:Province")}</label>
                        <InputAddress
                          address="invoiceEdit.registeredProvince"
                          value={this.state.invoiceEdit.registeredProvince}
                          onChange={this.handleInputChangeReceive}
                          onSelect={this.onSelect}
                          mailing={false}
                          className="form-control"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label htmlFor="inputZip">{i18next.t("editAddress:Zip code")}</label>
                        <InputAddress
                          address="invoiceEdit.registeredPostalCode"
                          value={this.state.invoiceEdit.registeredPostalCode}
                          onChange={this.handleInputChangeReceive}
                          onSelect={this.onSelect}
                          mailing={false}
                          className="form-control"
                        />
                      </div>
                    </div>
                    {/* <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="city">อีเมล</label>
                        <input type="text" className="form-control" id="email" name="invoiceEdit.email" value={this.state.invoiceEdit.email} onChange={this.handleInputChangeReceive} />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="phone">โทร</label>
                        <input type="number" className="form-control" id="phone" name="invoiceEdit.phone" value={this.state.invoiceEdit.phone} onChange={this.handleInputChangeReceive} />
                      </div>
                    </div> */}
                    <div className="col-12  mt-3 text-right">
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger mr-2" onClick={() => this.setState({ showModal: false })}>
                        <span>{i18next.t("editAddress:Cancel")}</span>
                      </button>
                      <button
                        className="btn btn-primary" type="submit"
                      >
                        {i18next.t("editAddress:Print")}{localStorage.getItem("language") === "th" && "ใบเสร็จรับเงิน"}
                      </button>

                    </div>
                  </form>
                </div>
              </Modal.Body>

            </Modal>

            <Translation>
              {t =>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <p>
                          <strong>{t('createReceive:Customer')}</strong>
                        </p>
                        <p>
                          {this.props.contact.refNumber + " "}
                          {this.props.contact.typeOfContact === "RESIDENTIAL"
                            ? getNameResidential(
                              firstName,
                              lastName
                            )
                            : this.props.contact.name}
                          <span style={{ color: '#F43853' }}>
                            &nbsp;{this.props.detail.receive.payGroup === "RENT" ? `(${i18next.t("AgentRole:Renter")})` :
                              this.props.detail.receive.payGroup === "AGENT" ? `(${i18next.t("AgentRole:Agent")})` :
                                ""
                            }
                          </span>
                        </p>
                      </div>
                      <div className="col">
                        <p>
                          <strong>{t('createReceive:No.')}</strong>
                        </p>
                        <p>
                          {this.props.detail.receive.docNumber ||
                            getDocumentNumber.get(
                              "receive",
                              "XXXX",
                              new Date(this.props.detail.receive.issuedDate)
                            )}{" "}
                        </p>
                      </div>
                      <div className="col">
                        <p>
                          <strong>{t('createReceive:Date')}</strong>
                        </p>
                        <p>
                          {format(
                            this.props.detail.receive.issuedDate,
                            "DD/MM/YYYY",
                            { locale: thLocale }
                          )}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col">
                        <p>
                          <strong>{t('createReceive:Address')}</strong>
                        </p>
                        <p>
                          {this.props.contact?.registeredProvince.includes("กรุงเทพ") || this.props.contact?.registeredProvince.includes("กทม") ? (
                            <>
                              {this.props.contact.registeredAddress}
                              {this.props.contact.registeredDistrict && ` แขวง${this.props.contact.registeredDistrict}`}
                              {this.props.contact.registeredCity && ` เขต${this.props.contact.registeredCity}`}
                              {this.props.contact.registeredProvince && ` ${this.props.contact.registeredProvince} `}
                              {this.props.contact.registeredPostalCode}{" "}
                              {this.props.contact.registeredCountry}
                            </>
                          ) : (
                            <>
                              {this.props.contact.registeredAddress}
                              {this.props.contact.registeredDistrict && ` ตำบล${this.props.contact.registeredDistrict}`}
                              {this.props.contact.registeredCity && ` อำเภอ${this.props.contact.registeredCity}`}
                              {this.props.contact.registeredProvince && ` จังหวัด${this.props.contact.registeredProvince} `}
                              {this.props.contact.registeredPostalCode}{" "}
                              {this.props.contact.registeredCountry}
                            </>
                          )}
                        </p>

                      </div>
                    </div>

                    <ReceiveDetailTable
                      receive_transactions={this.props.transactions}
                      credit_note={this.props.credit_note}
                    />
                  </div>
                </div>}
            </Translation>

            {this.state.receipt_deposit_list.length > 0 && (
              <Translation>
                {t =>
                  <div className="card mt-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h6>{t('receiveDetail:List of Receipt Deposit')}</h6>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col">
                          <div className="table-responsive fade-up">
                            <table className="table table-hover">
                              <thead className="thead-light">
                                <tr>
                                  <th style={{ width: "20%" }}>{t('createReceive:No.')}</th>
                                  <th style={{ width: "30%" }}>{t('createReceive:List')}</th>
                                  <th style={{ width: "40%" }}>{t('createReceive:Description')}</th>
                                  <th
                                    className="text-right"
                                    style={{ width: "10%" }}
                                  >
                                    {t('createReceive:Amount')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.receipt_deposit_list.map(
                                  (receipt_deposit) => (
                                    <tr key={receipt_deposit.node.id}>
                                      <td>
                                        {
                                          receipt_deposit.node
                                            .receiptDepositTransaction
                                            .receiptDeposit.docNumber
                                        }
                                      </td>
                                      <td>
                                        {
                                          receipt_deposit.node
                                            .receiptDepositTransaction
                                            .chartOfAccount.chartOfAccountCode
                                        }{" "}
                                        {
                                          receipt_deposit.node
                                            .receiptDepositTransaction
                                            .chartOfAccount.name
                                        }
                                      </td>
                                      <td>
                                        {
                                          receipt_deposit.node
                                            .receiptDepositTransaction.description
                                        }
                                      </td>
                                      <td className="text-right">
                                        {numberWithComma(
                                          receipt_deposit.node.amount
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </Translation>
            )}

            {this.state.unknown_receive_list &&
              this.state.unknown_receive_list?.edges.length > 0 && (
                <Translation>
                  {t =>
                    <div className="card mt-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h6>{t('createReceive:Cash/receipt pending review')}</h6>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col">
                            <div className="table-responsive fade-up">
                              <table className="table table-hover">
                                <thead className="thead-light">
                                  <tr>
                                    <th style={{ width: "20%" }}>{t('createReceive:No.')}</th>
                                    <th style={{ width: "30%" }}>{t('createReceive:List')}</th>
                                    <th style={{ width: "40%" }}>{t('createReceive:Description')}</th>
                                    <th
                                      className="text-right"
                                      style={{ width: "10%" }}
                                    >
                                      {t('createReceive:Amount')}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.unknown_receive_list.edges.map(
                                    (unknown_receive) => (
                                      <tr key={unknown_receive.node.id}>
                                        <td>{unknown_receive.node.docNumber}</td>
                                        <td>
                                          {
                                            unknown_receive.node.chartOfAccount
                                              .chartOfAccountCode
                                          }{" "}
                                          {unknown_receive.node.chartOfAccount.name}
                                        </td>
                                        <td>{unknown_receive.node.description}</td>
                                        <td className="text-right">
                                          {numberWithComma(
                                            unknown_receive.node.amount
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                </Translation>
              )}

            {this.state.credit_note_receive_list &&
              this.state.credit_note_receive_list.length > 0 && (
                <Translation>
                  {t =>
                    <div className="card mt-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h6>{t('receiveDetail:List of Credit Note')}</h6>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col">
                            <div className="table-responsive fade-up">
                              <table className="table table-hover">
                                <thead className="thead-light">
                                  <tr>
                                    <th style={{ width: "20%" }}>{t('createReceive:No.')}</th>
                                    <th style={{ width: "30%" }}>{t('createReceive:List')}</th>
                                    <th style={{ width: "40%" }}>{t('createReceive:Description')}</th>
                                    <th
                                      className="text-right"
                                      style={{ width: "10%" }}
                                    >
                                      {t('createReceive:Amount')}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.credit_note_receive_list.map(
                                    (credit_note) => (
                                      <tr key={credit_note.node.id}>
                                        <td>{credit_note.node.docNumber}</td>
                                        <td>
                                          {
                                            credit_note.node.returnChartOfAccount
                                              .chartOfAccountCode
                                          }{" "}
                                          {
                                            credit_note.node.returnChartOfAccount
                                              .name
                                          }
                                        </td>
                                        <td>{credit_note.node.description}</td>
                                        <td className="text-right">
                                          {numberWithComma(credit_note.node.price)}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </Translation>
              )}

            <Translation>
              {t =>
                <PayChannel
                  total={this.props.detail.receive.total}
                  bankTransaction={this.props.detail.receive.bankAccountTransactionReceive.edges}
                  cashTransaction={this.props.detail.receive.cashDepositReceiveTransaction.edges}
                  chequeTransaction={this.props.detail.receive.chequeDeposit.edges}
                  creditCardTransaction={this.props.detail.receive.creditCardTransaction.edges}
                  paymentGatewayTransaction={this.props.detail.receive.paymentGatewayMethod.edges}
                  otherTransaction={this.props.detail.allAccountRecordGroup}
                  receive_transactions={this.props.transactions}
                  account_type="receive"
                  receive_status={this.props.detail.receive.status}
                  unknownReceive={this.props.detail.receive.unknownReceive}
                />
              }
            </Translation>


            <Translation>
              {t =>
                <div className="row mt-4">
                  <div className="col">
                    <label htmlFor="note">
                      <strong>{t('createReceive:Note')}</strong>
                    </label>
                    <textarea
                      className="form-control"
                      rows="2"
                      name="remark"
                      value={this.state.remark}
                      disabled={this.props.detail.receive.status !== "WAIT" ? true : false}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="col offset-6 bg-light">
                    <div className="row p-3 mb-2 text-dark">
                      <div className="col text-left">{t('createReceive:Amount received')}</div>
                      <div className="col text-right">
                        {" "}
                        {numberWithComma(this.getTotalReceive(), "0.00")} {t('createReceive:Baht')}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Translation>

            {this.props.detail.receive.status === "WAIT" &&
              _.some(JSON.parse(localStorage.getItem("permission_list")), {
                // จาก RQT258 เปิดสิทธิ์ให้การสร้างใบเสร็จได้ เท่ากับอนุมัติได้ด้วย
                codename: "accounting_receive_create",
                // codename: "accounting_receive_approve",
              }) && (
                <Translation>
                  {t =>
                    <div className="row mt-4">
                      <div className="col text-right">
                        <button
                          className="btn btn-danger add mr-3"
                          disabled={this.state.loading}
                          onClick={() => this.onChangeStatus()}
                        >
                          {this.state.loading && (
                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                          )}
                          {t('receiveDetail:Refuse payment')}
                        </button>
                        <button
                          className="btn btn-success add"
                          disabled={this.state.loading}
                          onClick={() => this.onChangeStatus("paid")}
                        >
                          {this.state.loading && (
                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                          )}
                          {t('receiveDetail:Confirm payment')}
                        </button>
                      </div>
                    </div>
                  }
                </Translation>
              )}
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default withRouter(ReceiveDetail);