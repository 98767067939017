import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import {fetchQuery} from "relay-runtime";
import SummaryListTable from "../accrualBasis/summaryListTable";
import { formatDateISO } from "../../libs/date"
import Loading from "../../libs/loading";

const query = graphql`
    # query payListTableQuery ($typeOfReport: String, $start_date: DateTime, $end_date: DateTime, $month_count: Int) {
    #    chartOfAccountCashBasisPay(startDate: $start_date, endDate: $end_date, monthCount: $month_count, typeOfReport: $typeOfReport , typeOfPay: "pay") {
    #        edges {
    #             node {
    #                 id
    #                 name nameEn
    #                 chartOfAccountCode
    #                 col1Debit col2Debit col3Debit col4Debit col5Debit col6Debit col7Debit col8Debit col9Debit col10Debit col11Debit col12Debit
    #             }
    #         }
    #         totalCount
    #     }
        
    # }
    query payListTableQuery($dateRanges: [DateRange]!, $typeOfReport: String) {
        reportCashBasisExpense(dateRanges: $dateRanges, typeOfReport: $typeOfReport, typeOfPay: "pay") {
           edges {
                node {
                    id
                    name 
                    nameEn
                    chartOfAccountCode
                    data
                }
            }
        }
        
    }
`;

class PayListTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            month_list: [],
            chartOfAccountCashBasisPay: [],
            summary: [],
            total_row: [],
            list_data_loop: Array.from(new Array(this.props.state.month_count+1)),
            chart_of_account_cash_basis_pay_list: [],
            dateRanges: [],
            loading: true,
        };
        this.setPeriod = this.setPeriod.bind(this);
        this.queryChartOfAccount = this.queryChartOfAccount.bind(this);
        this.checkValueArray = this.checkValueArray.bind(this);
    }

    componentWillMount() {
        this.setPeriod();
        this.queryChartOfAccount();
    }

    queryChartOfAccount() {
        this.setState({
            loading: true,
        })
        this.setState({summary: [], total_row: []}, async () => {
            let chart_of_account_cash_basis_pay_list = [];
            let summary = [];
            await fetchQuery(environment, query, {
                dateRanges: this.state.dateRanges,
                typeOfReport: this.props.typeOfReport.code
            }).then((data) => {
                let data_set = _.cloneDeep(data);
                data_set.reportCashBasisExpense.edges.forEach((chart, index) => {
                    let node = chart.node;
                    let empty_value = true;
                    let total = 0
                    node.data.forEach((result) => {
                        if (result !== 0) {
                            empty_value = false
                        }
                        total += result
                    });

                    _.set(chart, 'node.total_row', total);
                    _.set(chart, 'node.empty_value', empty_value);

                    for (let i = 0; i < this.props.state.month_count + 2; i++) {
                        if (i === this.props.state.month_count + 1) {
                            _.set(summary, i, _.get(summary, i, 0) + total)

                        } else {
                            _.set(summary, i, _.get(summary, i, 0) + (node.data[i] || 0))
                        }
                    }

                    if (!_.get(chart, 'node.empty_value')) {
                        chart_of_account_cash_basis_pay_list.push(_.get(chart, 'node'))
                    }
                });

            });

            // if(this.props.typeOfReport.code !== 'tax'){

            //     await fetchQuery(environment, this.props.query_expense , {
            //         start_date: this.props.state.start_period,
            //         end_date: this.props.state.end_period,
            //         month_count: this.props.state.month_count,
            //         typeOfReport: this.props.typeOfReport.code
            //     }).then((data) => {
            //         let data_set = _.cloneDeep(data);
            //         data_set.chartOfAccountCashBasisPay.edges.forEach((chart, index) => {
            //             let node = chart.node;
            //             let total = node.col1Debit + node.col2Debit + node.col3Debit + node.col4Debit
            //                 + node.col5Debit + node.col6Debit + node.col7Debit + node.col8Debit
            //                 + node.col9Debit + node.col10Debit + node.col11Debit + node.col12Debit;

            //             let empty_value = true;
            //             if (total > 0) {
            //                 empty_value = false
            //             }
            //             _.set(chart, 'node.empty_value', empty_value);

            //             for (let i = 0; i < this.props.state.month_count + 2; i++) {
            //                 if (i === this.props.state.month_count + 1) {
            //                     _.set(summary, i, _.get(summary, i, 0) + total)

            //                 } else {
            //                     _.set(summary, i, _.get(summary, i, 0) + (node['col' + (i + 1) + 'Debit'] || 0))
            //                 }
            //             }

            //             if (!_.get(chart, 'node.empty_value')) {
            //                 index = this.checkValueArray(chart_of_account_cash_basis_pay_list, chart.node.id);

            //                 if (index !== -1) {
            //                     for (let i = 1; i < this.props.state.month_count + 2; i++) {
            //                         _.set(chart_of_account_cash_basis_pay_list[index], 'col' + i + 'Debit', (_.get(chart_of_account_cash_basis_pay_list[index], 'col' + i + 'Debit', 0) + _.get(node, 'col' + i + 'Debit', 0)))
            //                     }
            //                     _.set(chart_of_account_cash_basis_pay_list[index], 'total_row', (_.get(chart_of_account_cash_basis_pay_list[index], 'total_row', 0) + total))

            //                 } else {
            //                     _.set(chart, 'node.total_row', total);
            //                     chart_of_account_cash_basis_pay_list.push(_.get(chart, 'node'))
            //                 }

            //             }
            //         });
            //     });

            // }
            this.setState({
                chart_of_account_cash_basis_pay_list: chart_of_account_cash_basis_pay_list,
                summary: summary,
                loading: false,
            }, () => {
                this.props.updateSummary('total_pay', this.state);
            })

        });
    }
    checkValueArray(list, id){
       return list.findIndex(x => x.id === id)
    }

    componentDidMount() {
        this.setPeriod();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.start_period !== this.props.state.start_period || prevProps.state.end_period !== this.props.state.end_period) {
            this.setPeriod();
            this.queryChartOfAccount();
        }
    }

    setPeriod() {
        let start_month = this.props.state.start_period.getMonth();
        let start_year = this.props.state.start_period.getFullYear();
        let month_list = [this.props.state.start_period];
        let list = Array.from(new Array(this.props.state.month_count));
        let list_data_loop = Array.from(new Array(this.props.state.month_count + 2));

        list.map((month, index) => {
            let new_date = new Date(start_year, start_month + (index + 1));
            if (index === this.props.state.month_count - 1) {
                new_date = this.props.state.end_period
            }
            return month_list.push(new_date);
        });

        const dateRanges = [];
        for (let iter = this.props.state.start_period.getTime(), end = this.props.state.end_period.getTime(); iter <= end;) {
            const first = new Date(iter);
            const last = new Date(first.getFullYear(), first.getMonth() + 1, 0);
            dateRanges.push({
                start: formatDateISO(first),
                end: formatDateISO(last)
            })
            iter = last.getTime() + (24 * 60 * 60 * 1000);
        }
        this.setState({month_list: month_list, list_data_loop: list_data_loop, dateRanges: dateRanges});
    }


    render() {
        return (this.state.loading ? 
                <Loading />
                :
                <React.Fragment>
                <tbody>
                {this.props.typeOfReport.header &&
                <tr>
                    <td width={360}><strong>2. รายจ่าย / Expense</strong></td>
                    {this.state.month_list.map((month, index) => {
                        return (
                            <td width={100} key={'td-expense-' + index}/>
                        )
                    })}
                    <td/>
                </tr>
                }
                <tr>
                    <td width={360}><strong>{this.props.typeOfReport.name}</strong></td>
                   {this.state.month_list.map((month, index) => {
                        return (
                            <td width={100} key={'td-'+index}/>
                        )
                    })}
                    <td/>
                </tr>

                {this.state.chart_of_account_cash_basis_pay_list.map((chart, index) => {
                    if (chart.empty_value) {
                        return <React.Fragment key={index}/>;
                    }
                    return (
                        <React.Fragment key={chart.id}>
                            <tr>
                                <td>&nbsp;&nbsp;&nbsp;&nbsp;{chart.name} {chart.nameEn ? " / "+chart.nameEn: ""}</td>
                                {chart.data.map((expense, index) => {
                                    return (
                                        <td className="text-right" key={'data-td-'+index}>
                                            {numberWithComma(expense)}
                                        </td>
                                    )
                                })}
                                <td className="text-right">{numberWithComma(chart.total_row)}</td>
                            </tr>
                        </React.Fragment>

                    )
                })}

                <SummaryListTable text_summary={this.props.typeOfReport.summary_name}
                                  summary={this.state.summary}
                                  list_data_loop={this.state.list_data_loop}/>
                </tbody>
                </React.Fragment>
            )
    }
}

export default PayListTable;
