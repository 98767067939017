import { commitMutation } from 'react-relay'
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";


const mutation = graphql`
    mutation DeleteKeyButtonMutation($input:DeleteButtonKeyInput!){
        deleteButtonKey(input:$input){
            ok
            message
        }
    }
`; 
export default function DeleteKeyButton(variables , callback , error_callback) { 
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}



