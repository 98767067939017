import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import _ from "lodash";
import UploadImageInput from '../../libs/uploadImageInput';
import Swal from 'sweetalert2';
import "./juristicCompany.scss"
import {graphql} from "babel-plugin-relay/macro";
import environment from '../../env/environment';
import {fetchQuery} from "relay-runtime";
import FileInput from '../projectInfo/componentProjectList/fileInput';
import upload from '../../libs/upload';
import UpdateCompany from './graphQL/updateCompany';
import i18n from "i18next";
import createCompany from "./graphQL/createCompany";

const query = graphql `
query generalInformationQuery{
    allJuristicCompany {
        edges {
            node {
                id
                name
                address
                taxId
                website
                phone
                email
                facebook
                line
                logo
                description
                contactPerson {
                    edges {
                        node {
                        id
                        name
                        phone
                        email
                        }
                    }
                }
                documentation {
                    edges {
                        node {
                        id
                        fileName
                        fileUpload
                        fileUploadUrl
                        }
                    }
                }
            }
        }
    }
}

`


const juristicCompany = {
    description : "",
    name : "",
    address : "",
    taxtId : '',
    website : '',
    phone : '',
    email : '',
    facebook : '',
    line : '',
    logo : '',
    contactPerson : {edges : []},
    documentation : {edges : []}
}


class GeneralInformation extends Component{
    constructor(props){
        super(props);
        this.state = {
            juristicCompany : juristicCompany,
            editInformation: false,
            contact: {
                node :{
                    name: '',
                    phone : '',
                    email : '',
                    id : ''
                }
            },
            document: {
                node : {
                    fileName : '',
                    fileUpload : '',
                    fileUploadUrl:'',
                    id : ''
                }
            },
            uploadlogo : '',
            statusData : 'create',
            editInformation2 : false, 
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    

    componentWillMount(){
        this.fetchQuery()
    }

    fetchQuery = () => {
        fetchQuery(environment, query).then(response_data => {
            if(response_data.allJuristicCompany?.edges?.length > 0){
                this.setState({
                    statusData:'update',
                    juristicCompany : response_data.allJuristicCompany?.edges[0].node
                })
            }
        });
    }

    handleChangInput = (e) => {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        this.setState((prevState) => {
            return _.set(_.cloneDeep(prevState), event.target.name, value);
        });
        if(event.target.name === 'juristicCompany.upload_image'){
            this.setState({
                uploadlogo : value
            })
        }
    }

    appendRow = (name) =>{
        if(name === "juristicCompany.contactPerson.edges"){
            this.setState((prevState) => {
                return _.set(_.cloneDeep(prevState), "juristicCompany.contactPerson.edges", [...this.state.juristicCompany.contactPerson.edges, {...this.state.contact}]);
            });
        }
    }

    appendFileInputRow = (fileInput) => {
        let file = {node : {id : '',fileName : fileInput.node.nameFile , fileUpload : fileInput.node.fileUpload }}
        let newFile = [...this.state.juristicCompany.documentation.edges, {...file}]
        this.setState((prevState) => {
            return _.set(_.cloneDeep(prevState), "juristicCompany.documentation.edges", newFile);
        });
    }

    onDelete = (data,id,name) => {
        Swal.fire({
            title: i18n.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t('juristicCompany:Confirmm'),
            cancelButtonText: i18n.t('juristicCompany:Cancel')
        }).then((result) => {
            if (result.value) {
                let getData = _.cloneDeep(data)
                let index = _.findIndex(_.cloneDeep(getData),{node : {id : id}})
                if(index === -1){
                    getData.splice(id, 1)
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            name,
                            getData
                        );
                    });
                }else{
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            name+'['+index+'].node.status',
                            'delete'
                        );
                    });
                }
            }
        })
    }

    handleInputDocument = (e,nameIndex) =>{
        let name = _.cloneDeep(nameIndex);
        let value = e.currentTarget.files[0];

        this.setState((prevState) => {
            return _.set(_.cloneDeep(prevState), name, value);
        });
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/juristicCompany/general" + path, file);
          resolve(res);
        });
      };

    async onSubmit(e) {
        e.preventDefault();
        let variables = {
            input : {
                juristicCompany :{...this.state.juristicCompany}
            }
        }
        delete variables.input.juristicCompany.documentation
        delete variables.input.juristicCompany.contactPerson
        delete variables.input.juristicCompany.logo

        if(this.state.uploadlogo !== ''){
            await this.callUpload('', this.state.uploadlogo).then((data) => {
                variables.input.juristicCompany["logo"] = data.key.replace("silverman-storage/private/", "");
            });
        }
        let newFilePdf = []
        for (const file of this.state.juristicCompany.documentation.edges) {
            if(file.node.fileUpload !== '' && file.node.id === ''){
                await this.callUpload('', file.node.fileUpload).then((data) => {
                    newFilePdf.push({id: file.node.id,fileName : file.node.fileName , 
                        fileUpload : data.key.replace("silverman-storage/private/", ""),
                        status : file.node.status ? file.node.status : '' })
                });
            }else{
                newFilePdf.push({id: file.node.id,fileName : file.node.fileName , 
                    fileUpload : file.node.fileUpload , status : file.node.status ? file.node.status : ''})
            }
        }

        let newContact = []
        _.forEach(this.state.juristicCompany.contactPerson.edges , (contact)  => {
            if(this.state.statusData === 'create'){
                newContact.push({id: contact.node.id,name : contact.node.name , 
                    email : contact.node.email , phone : contact.node.phone})
            }else{
                newContact.push({id: contact.node.id,name : contact.node.name , 
                    email : contact.node.email , phone : contact.node.phone , status : contact.node.status ? contact.node.status : ''})
            }
        })
        variables.input.juristicCompany["document"] = _.clone(newFilePdf)
        variables.input.juristicCompany["contact"] = _.clone(newContact)
        if(this.state.statusData === 'update'){
            UpdateCompany(variables, this.onSubmitSuccess,this.onSubmitError)
        }else{
            createCompany(variables, this.onSubmitSuccess,this.onSubmitError)
        }
    
    }

    onSubmitSuccess = (response) => {
        this.setState({loading: false, editInformation: false , editInformation2:false});
        if (response) {
            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                // this.setState({redirectToList: true})
                this.fetchQuery()
            });
        } else {
            Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
        }
    }

    onSubmitError = (err) => {
        this.setState({loading: false , editInformation: false});
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }

    render(){
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        return(
            <Translation>
                {t =>
                
                    <div className="row mt-3 pr-3">
                        <div className="col-md-6 col-sm-12">
                            {/* <form onSubmit={this.onSubmit} id="generalInformation">   */}
                              <div className="card card-general p-4">
                                    <div className="row">
                                        <div className="col-6">
                                            <h4>{t('juristicCompany:General Information')}</h4>
                                        </div>
                                        <div className="col-6">
                                            {this.state.editInformation ?
                                                <button 
                                                    className="btn btn-outline-primary btn-sm float-right"
                                                    type="button" onClick={(e) => this.onSubmit(e)}
                                                    >{t('juristicCompany:Save')}</button>
                                                :
                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_juristiccompany_edit' }) &&
                                                <button className="btn btn-outline-primary float-right" onClick={() => this.setState({editInformation:true})}>
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/edit-blue-color.png'} 
                                                    alt="edit-icon" className="mr-1"/>{t('juristicCompany:Edit')}
                                                </button>
                                            }
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <h4>{
                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                            textData?.data?.juristicCompany.information
                                                :                                                   
                                            t('juristicCompany:Company information')
                                            }</h4>
                                        </div>
                                        <div className="col-12">
                                            <span></span>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-12">
                                            {this.state.editInformation ? 
                                                <div className="input-group mb-2 mr-sm-2">
                                                    <div className="input-group-prepend">
                                                    <div className="input-group-text">{t('juristicCompany:Company name')}</div>
                                                    </div>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={this.state.juristicCompany.name}
                                                        name="juristicCompany.name" 
                                                        onChange={this.handleChangInput}
                                                        required/>
                                                </div>
                                                :
                                                <h5>{this.state.juristicCompany.name}</h5>
                                            }
                                        </div>
                                        <div className="col-7">
                                            {this.state.editInformation ? 
                                                <textarea 
                                                    className="form-control mt-3" 
                                                    style={{height: "160px", maxHeight: "160px"}}
                                                    name="juristicCompany.description"
                                                    value={this.state.juristicCompany.description}
                                                    onChange={this.handleChangInput}
                                                    />
                                                    :
                                                <span>{this.state.juristicCompany.description}</span>
                                            }
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <strong><span>{t('juristicCompany:Taxpayer identification')}</span></strong>
                                        </div>
                                        <div className="col-6">
                                            {this.state.editInformation ? 
                                                <input type="text" 
                                                    className="form-control form-control-sm " 
                                                    name="juristicCompany.taxId"
                                                    maxLength={13}
                                                    value={this.state.juristicCompany.taxId}
                                                    onChange={this.handleChangInput}
                                                    />
                                                :
                                                <span>{this.state.juristicCompany.taxId}</span>
                                            }
                                        </div>
                                    </div>

                                    {this.state.editInformation 
                                        ? 
                                            (<div className="row mt-4">
                                                <div className="col-md-6 col-sm-4 mt-2">
                                                    <strong><span><img src={process.env.PUBLIC_URL + '/images/icons/website.png'} alt="website-icon"/>&emsp;{t('juristicCompany:Website')}</span></strong>
                                                    </div>
                                                <div className="col-md-6 col-sm mt-2">
                                                    <input type="text" 
                                                        className="form-control form-control-sm " 
                                                        name="juristicCompany.website" 
                                                        value={this.state.juristicCompany.website || ''}
                                                        onChange={this.handleChangInput}
                                                        />
                                                    </div>

                                                <div className="col-md-6 col-sm-4 mt-2">
                                                    <strong><span><img src={process.env.PUBLIC_URL + '/images/icons/phone-black.png'} alt="phone-icon"/>&emsp;{t('juristicCompany:Phone')}</span></strong>
                                                    </div>
                                                <div className="col-md-6 col-sm mt-2">
                                                    <input type="text" 
                                                        className="form-control form-control-sm" 
                                                        name="juristicCompany.phone" 
                                                        value={this.state.juristicCompany.phone || ''}
                                                        onChange={this.handleChangInput} 
                                                        />
                                                    </div>

                                                <div className="col-md-6 col-sm-4 mt-2">
                                                    <strong><span><img src={process.env.PUBLIC_URL + '/images/icons/email-black.png'} alt="email-icon"/>&emsp;{t('juristicCompany:Email')}</span></strong>
                                                    </div>
                                                <div className="col-md-6 col-sm mt-2">
                                                    <input type="text" 
                                                        className="form-control form-control-sm " 
                                                        name="juristicCompany.email" 
                                                        value={this.state.juristicCompany.email || ''}
                                                        onChange={this.handleChangInput}
                                                        />
                                                    </div>

                                                <div className="col-md-6 col-sm-4 mt-2">
                                                    <strong><span><img src={process.env.PUBLIC_URL + '/images/icons/facebook-black.png'} alt="facebook-icon"/>&emsp;{t('juristicCompany:Facebook')}</span></strong>
                                                    </div>
                                                <div className="col-md-6 col-sm mt-2">
                                                    <input type="text" 
                                                        className="form-control form-control-sm" 
                                                        name="juristicCompany.facebook" 
                                                        value={this.state.juristicCompany.facebook || ''}
                                                        onChange={this.handleChangInput}
                                                        />
                                                    </div>

                                                <div className="col-md-6 col-sm-4 mt-2">
                                                    <strong><span><img src={process.env.PUBLIC_URL + '/images/icons/line-black.png'} alt="line-icon"/>&emsp;{t('juristicCompany:Line')}</span></strong>
                                                    </div>
                                                <div className="col-md-6 col-sm mt-2">
                                                    <input type="text" 
                                                        className="form-control form-control-sm " 
                                                        name="juristicCompany.line" 
                                                        value={this.state.juristicCompany.line || ''}
                                                        onChange={this.handleChangInput}
                                                        />
                                                    </div>
                                            </div>)
                                        :
                                            (<div className="row mt-4">
                                                <div className="col-md-6 col-sm-4 mt-2">
                                                    <strong><span><img src={process.env.PUBLIC_URL + '/images/icons/website.png'} alt="website-icon"/>&emsp;{t('juristicCompany:Website')}</span></strong>
                                                    </div>
                                                <div className="col-md-6 col-sm mt-2">
                                                    <span>{this.state.juristicCompany.website}</span>
                                                    </div>

                                                <div className="col-md-6 col-sm-4 mt-2">
                                                    <strong><span><img src={process.env.PUBLIC_URL + '/images/icons/phone-black.png'} alt="phone-icon"/>&emsp;{t('juristicCompany:Phone')}</span></strong>
                                                    </div>
                                                <div className="col-md-6 col-sm mt-2">
                                                    <span>{this.state.juristicCompany.phone}</span>
                                                    </div>

                                                <div className="col-md-6 col-sm-4 mt-2">
                                                    <strong><span><img src={process.env.PUBLIC_URL + '/images/icons/email-black.png'} alt="email-icon"/>&emsp;{t('juristicCompany:Email')}</span></strong>
                                                    </div>
                                                <div className="col-md-6 col-sm mt-2">
                                                    <span>{this.state.juristicCompany.email}</span>
                                                    </div>

                                                <div className="col-md-6 col-sm-4 mt-2">
                                                    <strong><span><img src={process.env.PUBLIC_URL + '/images/icons/facebook-black.png'} alt="facebook-icon"/>&emsp;{t('juristicCompany:Facebook')}</span></strong>
                                                    </div>
                                                <div className="col-md-6 col-sm mt-2">
                                                    <span>{this.state.juristicCompany.facebook}</span>
                                                    </div>

                                                <div className="col-md-6 col-sm-4 mt-2">
                                                    <strong><span><img src={process.env.PUBLIC_URL + '/images/icons/line-black.png'} alt="line-icon"/>&emsp;{t('juristicCompany:Line')}</span></strong>
                                                    </div>
                                                <div className="col-md-6 col-sm mt-2">
                                                    <span>{this.state.juristicCompany.line}</span>
                                                    </div>
                                            </div>)
                                    }

                                </div>
                                {/* </form> */}
                            </div>
                        

                        
                        <div className="col-md-6 col-sm-12">
                            {/* <form onSubmit={this.onSubmit} id="generalInformation2"> */}
                                <div className="card card-general p-4">
                                    <div className="row">
                                        <div className="col-6 ">
                                            <img
                                                src={ this.state.juristicCompany.logo ? this.state.juristicCompany.logo : process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                className="rounded-circle avatar-img profile-img"
                                                alt="project-manager-profile"/>
                                            {this.state.editInformation &&
                                                <div className="upload-file">
                                                    <label className="btn-link pr-2 text-decoration-none" htmlFor="validatedCustomFile">{t('juristicCompany:Edit picture')}</label>
                                                    <div className="upload-input">
                                                        <UploadImageInput
                                                            id="validatedCustomFile"
                                                            setField="juristicCompany.upload_image:juristicCompany.logo"
                                                            onChange={this.handleChangInput}
                                                            />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-6">
                                            {this.state.editInformation2 ?
                                                <button 
                                                    className="btn btn-outline-primary btn-sm float-right"
                                                    type="button" onClick={(e) => this.onSubmit(e)}
                                                    >{t('juristicCompany:Save')}</button>
                                                :
                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_juristiccompany_edit' }) &&
                                                <button className="btn btn-outline-primary float-right" onClick={() => this.setState({editInformation2:true})}>
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/edit-blue-color.png'} 
                                                    alt="edit-icon" className="mr-1"/>{t('juristicCompany:Edit')}
                                                </button>
                                            }
                                        </div>
                                    </div>
    
                                    <div className="row mt-4">
                                        <div className="col-12 d-flex align-items-center ml-4">
                                            <h5 className="m-0">{t('juristicCompany:Contact')}</h5>
                                        </div>
                                    </div>
                                       
                                    <div className="table-responsive fade-up mt-2">
                                        <table className="table">
                                            <thead className="header2">
                                                <tr className="header2">
                                                    <th className="text-left">{t('juristicCompany:Name-Surname')}</th>
                                                    <th className="text-left">{t('juristicCompany:Tel')}</th>
                                                    <th className="text-left">{t('juristicCompany:Email')}</th>
                                                    <th/>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.juristicCompany.contactPerson?.edges?.length > 0 &&
                                                this.state.juristicCompany.contactPerson.edges.map((contact,index) => {
                                                    if((contact.node.status && contact.node.status !== 'delete')|| !contact.node.status){
                                                        if(this.state.editInformation2){
                                                            return(
                                                                <tr key={index}>
                                                                    <td> 
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control form-control-sm" 
                                                                            placeholder="ชื่อ - นามสกุล"
                                                                            name={"juristicCompany.contactPerson.edges[" + index + "].node.name"}
                                                                            value={contact.node.name}
                                                                            onChange={this.handleChangInput}
                                                                            required
                                                                            />
                                                                    </td>
                                                                    <td>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control form-control-sm" 
                                                                            placeholder="เบอร์โทรศัพท์"
                                                                            name={"juristicCompany.contactPerson.edges[" + index + "].node.phone"}
                                                                            value={contact.node.phone}
                                                                            onChange={this.handleChangInput}
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control form-control-sm" 
                                                                            placeholder="อีเมล"
                                                                            name={"juristicCompany.contactPerson.edges[" + index + "].node.email"}
                                                                            value={contact.node.email}
                                                                            onChange={this.handleChangInput}
                                                                            required
                                                                            />
                                                                    </td>
                                                                    <td>
                                                                        <img 
                                                                            src="/images/icons/icon_delete.png" 
                                                                            alt="bin"
                                                                            className="delete cursor"
                                                                            onClick={() => this.onDelete(this.state.juristicCompany.contactPerson.edges,contact.node.id !== '' ? contact.node.id : index,'juristicCompany.contactPerson.edges')}
                                                                            />
                                                                    </td>
                                                                </tr>
    
                                                            );
                                                        }else{
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{contact.node.name}</td>
                                                                    <td>{contact.node.phone}</td>
                                                                    <td>{contact.node.email}</td>
                                                                    <td></td>
                                                                </tr>
    
                                                            );
                                                        }
                                                        
                                                    }else{
                                                        return <></>
                                                    }
                                                }
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row ">
                                        <div className="col-12 d-flex align-items-center">
                                            {this.state.editInformation2 && 
                                                <small 
                                                    className="btn text-primary ml-2 cursor" 
                                                    style={{fontSize: "10px"}}
                                                    onClick={() => this.appendRow("juristicCompany.contactPerson.edges")}
                                                    >+ {t('juristicCompany:Add list')}</small>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 d-flex align-items-center">
                                            <h5 className="m-0 col-4">{t('juristicCompany:Document')}</h5>
                                            {this.state.editInformation2 &&
                                            <>
                                            
                                            <div className="upload-file mx-2 col-3 cursor border-primary">
                                                <label htmlFor="CustomFile" className="text-blue pt-2">
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/icon_attach.png'} alt="attach" className="icon mr-2"/>
                                                    {t('projectManager:Attach File')}
                                                </label>
                                                <div className="upload-input custom-file">
                                                    <FileInput
                                                        id="CustomFile"
                                                        name="projectManager.filepdf"
                                                        appendFileInputRow={this.appendFileInputRow}
                                                    />
                                                </div>
                                              
                                            </div>
                                            <span className="text-red pt-3"> {t('projectManager:The system supports .pdf .docx files up to 10MB in size.')}  </span>
                                            </>
                                            }
                                        </div>
                                    </div>
                                    {this.state.juristicCompany.documentation?.edges.length > 0 &&
                                    this.state.juristicCompany.documentation.edges.map((document,index) => {
                                        if((document.node.status && document.node.status !== 'delete') || !document.node.status){
                                            return(
                                                <React.Fragment key={index}>
                                                    {this.state.editInformation2 
                                                        ? 
                                                        <div className="row mt-2" key={index}>
                                                            <div className="col-11">
                                                                <div className="col-12">
                                                                    <span>{document.node.fileName}</span>
                                                                    {/* <a download={document.node.fileName}
                                                                        href={document.node.fileUpload}>
                                                                        <img src="/images/icons/icon_download.png" 
                                                                            alt="bin"
                                                                            className="cursor"/>
                                                                    </a> */}
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <img src="/images/icons/cancel-icon.png" 
                                                                    alt="bin"
                                                                    className="cursor"
                                                                    onClick={() => 
                                                                        this.onDelete(this.state.juristicCompany.documentation.edges,
                                                                            document.node.id !== '' ? document.node.id : index
                                                                            ,'juristicCompany.documentation.edges')}
                                                                    />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="row mt-2" key={index}>
                                                            <div className="col ml-4">
                                                                <span>{document.node.fileName}</span>
                                                            </div>
                                                            <div className="col">
                                                                <a download={document.node.fileName}
                                                                    href={document.node.fileUploadUrl}>
                                                                    <img src="/images/icons/icon_download.png" 
                                                                            alt="bin"
                                                                            className="cursor"/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        
                                                    }
                                                </React.Fragment>
                                            )
                                        }else{
                                            return <></>
                                        }
                                        })
                                    }
                                    
                                </div>
                            {/* </form> */}
                        </div>
                        
                    </div>
                } 
            </Translation>


        );
    }


}
export default GeneralInformation;