import React from "react";
import "../../styles/commonArea.scss";
import i18n from "i18next";
const Terms = ({
  state: { rule = [], public_private, rule_pdf },
  handleChange,
  addRule,
  subRule,
  delePDF
}) => {

  return (
    <div className="booking-form mb-3  mt-5">
      <div className="switch-languages-div pb-2 ">
        <h2>{i18n.t("newBookingSingha_Create_step1:Facility use agreement")}</h2>
      </div>
      <div className="mt-4">
        <h3>{i18n.t("newBookingSingha_Create_step1:Open this facility as public area")}</h3>
        <div className="text-grey">
          {i18n.t("newBookingSingha_Create_step1:Enabling public areas to allow users to reserve multiple groups without exceeding the specified number of users")}
        </div>
        <div className="form-check mt-2">
          <input
            className="form-check-input"
            type="radio"
            name="public_private"
            id="public_private2"
            value="private"
            onChange={handleChange}
            defaultChecked={public_private === "private"}
          />
          <label className="form-check-label" htmlFor="public_private2">
            {i18n.t("newBookingSingha_Create_step1:Disabled")}
          </label>
        </div>
        <div className="form-check mt-2">
          <input
            className="form-check-input"
            type="radio"
            name="public_private"
            id="public_private1"
            value="public"
            onChange={handleChange}
            defaultChecked={public_private === "public"}
          />
          <label className="form-check-label" htmlFor="public_private1">
            {i18n.t("newBookingSingha_Create_step1:Enabling")}
          </label>
        </div>
        <div className="d-flex">
          <div>
            <h3 className="mt-3">{i18n.t("newBookingSingha_Create_step1:Regulation/Rules")}</h3>
            <div className="text-grey">
              {i18n.t("newBookingSingha_Create_step1:Attach a regulations/rules file or add topics individually")}
            </div>
            {rule.map((item, index) => {
              return (
                <div key={index} className="d-flex align-items-center ">
                  <div className="mt-3 col-11">
                    <label htmlFor="exampleInputEmail4" className="bold">
                      {i18n.t("newBookingSingha_Create_step1:No.")} {index + 1}
                    </label>
                    <textarea
                      className="form-control col-12 "
                      id="rule_description"
                      rows="3"
                      placeholder={`กฏระเบียบการ ข้อ${item.rule_no}`}
                      name="rule_description"
                      value={item.rule_description}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>
                  <div onClick={() => subRule(index)} className="pt-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/icons/icon_delete.png"
                      }
                      alt="delete"
                      className="delete"
                    />
                  </div>
                </div>
              );
            })}
            <button
              type="button"
              className="btn btn-link button-add mt-3 "
              onClick={addRule}
            >
              + {i18n.t("newBookingSingha_Create_step1:Add regulation/Rules topic")}
            </button>
          </div>
          <div className="custon-input pl-3 mt-4 ">
            <h3>{i18n.t("newBookingSingha_Create_step1:Regulation/Rules")} </h3>
            <div>
              <span className="d-flex align-items-end">
                <div
                  className="btn ml-2 mt-2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderColor: "#0382fa",
                    maxWidth: 100,
                  }}
                >
                  <label htmlFor="rule_pdf" className="cursor">
                    {i18n.t("newBookingSingha_Create_step1:Choose Files")}
                    <input
                      type="file"
                      name="rule_pdf"
                      id="rule_pdf"
                      value=""
                      accept="application/pdf"
                      hidden
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <span
                  className="ml-2"
                  style={{ color: "#E14558", fontSize: 12 }}
                >
                  {i18n.t("newBookingSingha_Create_step1:Only .pdf files are supported, and their size must not exceed 10 MB.")}
                </span>
              </span>
              <div className="d-flex mt-4 ">
                {/* {rule_pdf && (
                  <img
                    className="ml-2"
                    src={process.env.PUBLIC_URL + "/images/icons/icon_pdf.png"}
                    alt="pdf"
                    style={{ width: 24, height: 24 }}
                  />
                )} */}
                {rule_pdf.name ? (
                  <div className="ml-2 d-flex" style={{ color: "#0382FA" }}>
                    <a href={rule_pdf.rule_pdf} target="_blank">
                      {
                        rule_pdf.name ?  
                        <img
                        className="ml-2"
                        src={process.env.PUBLIC_URL + "/images/icons/icon_pdf.png"}
                        alt="pdf"
                        style={{ width: 24, height: 24 }}
                      /> : ''
                      }
                      
                      {rule_pdf ? rule_pdf.name : rule_pdf.rule_description}
                    </a>
                    <p onClick={() => delePDF(rule_pdf.id)} className="pl-3">
                        {
                          rule_pdf.name  ? <label> X </label> :''
                        }
                    </p>
                  </div>
                ):''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
