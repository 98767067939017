import React from 'react'
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import _ from 'lodash';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts";
import numberWithComma from '../../../../../libs/numberWithComma';
import i18next from 'i18next';

import jwtDecode from 'jwt-decode';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNewBold.ttf',
        italics: 'THSarabunNewItalic.ttf',
        bolditalics: 'THSarabunNewBoldItalic.ttf'
    },
    Sarabun: {
        normal: 'Sarabun-Light.ttf',
        bold: 'Sarabun-Bold.ttf',
        italics: 'Sarabun-Italic.ttf',
        bolditalics: 'Sarabun-BoldItalic.ttf'
    }
}

const calBatchRange = (startRt, endRt) => {   
    let results = ""    
    if(startRt && endRt) {
        const containsComma = startRt.includes(',');
        if(containsComma) {
            const startData = startRt.split(",");
            const endData = endRt.split(",");            
            let resultConcat = startData.map((start, index) => `${start} - ${endData[index]}`);
            results = resultConcat.join(', ')
        } else if(startRt === endRt){
            results = startRt
        } else {
            results = `${startRt} - ${endRt}`
        }
    }     
    return results

}

const ExportFixReportPDF = (props) => {
    let batchData = []
    let rtDataAll = []
    let rtDetail = props.rtDetail || []

    const jwtDecodes = jwtDecode(window.localStorage.getItem('token'));
    const printBy = jwtDecodes.first_name + " " + jwtDecodes.last_name

    batchData = [
        `${props.allBatchDetail?.docNumber}`,
        `${format(props.allBatchDetail?.batchDate, "DD/MM/YYYY")}`,
        `${format(props.allBatchDetail?.added, 'HH.mm น.')}`,
        `${props.transactionCount} ${i18next.t(`batchRt:Transaction`)}`,
        `${calBatchRange(props.allBatchDetail?.startRt, props.allBatchDetail?.endRt)}` ,
        `${props.allBatchDetail?.remark || "-"}`,
    ]

    const getColorAndText = (receive) => {
        let text = "";
        switch (receive) {
          case "WAIT":
            text = "Waiting for review";
            break;
          case "PAID":
            text = "paid_info";
            break;
          default:
            text = "Canceled list";        
        }
        return `${i18next.t(`receive:${text}`)}`
    }


    for (let data of rtDetail) {
        let rtData = data.node.receive.receiveTransaction.edges;
        let total = 0;
        let rows = [];
    
        
        for (let [innerIndex, rt] of rtData.entries()) {
            total += rt.node.amount;
            const maxLength = 50;
            let fullName = `${data.node.receive.firstName} ${data.node.receive.lastName}`;
            
            if (fullName.length > maxLength) {
                fullName = fullName.substring(0, maxLength - 3) + '...';
            }
                
            let rowRt = []
            if(innerIndex === 0){
                rowRt = [
                    `${data.node.receive.docNumber}`,
                    `${format(data.node.receive.issuedDate, "DD/MM/YYYY")}`,
                    `${data.node.receive.contact.name}`,
                    `${fullName}`,
                    `${rt.node.transaction.description}`,
                    { text: `${numberWithComma(rt.node.amount)}`, alignment: 'right' },
                    { text: `${innerIndex === rtData.length - 1 ? numberWithComma(total) : ""}`, alignment: 'right' },
                    `${i18next.t(`batchRt:Transfer Cash`)}`,
                    `${getColorAndText(data.node.receive.status)}`,
                    `${format(data.node.receive.added, "DD/MM/YYYY")}`,
                    `${data.node.receive.creator}`,
                ];
            } else {
                rowRt = [
                    ``,
                    ``,
                    ``,
                    ``,
                    `${rt.node.transaction.description}`,
                    { text: `${numberWithComma(rt.node.amount)}`, alignment: 'right' },
                    { text: `${innerIndex === rtData.length - 1 ? numberWithComma(total) : ""}`, alignment: 'right' },
                    `${i18next.t(`batchRt:Transfer Cash`)}`,
                    `${getColorAndText(data.node.receive.status)}`,
                    `${format(data.node.receive.added, "DD/MM/YYYY")}`,
                    `${data.node.receive.creator}`,
                ];
            }
    
            rows.push(rowRt);
        }
    
        rtDataAll.push(...rows);
    }
    

    const generatePdf = async () => {
        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape', //portrait ตั้ง  || landscape นอน
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {
                            text: `${currentPage}/${pageCount}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [0, 0],
                        },
                        {
                            text: `${i18next.t(`batchRt:Print By`)} ${printBy} ${i18next.t(`batchRt:Print Date`)} ${format(new Date(), "DD/MM/YYYY HH:mm")}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [20, 0],
                        },
                    ],
                };
            },
            content: [                
                `${props.projectName}`,
                {text: `${i18next.t(`batchRt:Batch Report`)}`, style: ''},
                {
                    style: 'tableBatchDetail',
                    table: {
                        headerRows: 1,
                        widths: [100, 45, 40, 40, 150, 180],
                        body: [                            
                            [
                                { text:`${i18next.t(`batchRt:Batch number`)}`, alignment: 'center' }, 
                                { text:`${i18next.t(`batchRt:Date`)}	`, alignment: 'center' },
                                { text:`${i18next.t(`batchRt:Time`)}`, alignment: 'center' }, 
                                { text:`${i18next.t(`batchRt:Quantity`)}`, alignment: 'center' }, 
                                { text:`${i18next.t(`batchRt:Document number`)}`, alignment: 'center' }, 
                                { text:`${i18next.t(`batchRt:Reason for Cancellation`)}`, alignment: 'center' },
                            ],
                            batchData,
                        ]
                    }
                },
                {
                    style: 'tableBatchDetail',
                    table: {
                        headerRows: 1,
                        widths: [60, 50, 40, 100, 150, 50, 50, 40, 55, 50, 50],
                        body: [
                            [
                                { text:`${i18next.t(`batchRt:No.`)}`, alignment: 'center' },
                                { text:`${i18next.t(`batchRt:Date`)}`, alignment: 'center' },
                                { text:`${i18next.t(`batchRt:Room No.`)}`, alignment: 'center' },
                                { text:`${i18next.t(`batchRt:Name`)}`, alignment: 'center' },
                                { text:`${i18next.t(`batchRt:Description`)}`, alignment: 'center' },  
                                { text: `${i18next.t(`batchRt:Amount`)}`, alignment: 'center' },
                                { text: `${i18next.t(`batchRt:Total Amount`)}`, alignment: 'center' },
                                { text:`${i18next.t(`batchRt:Payment Method`)}`, alignment: 'center' },
                                { text:`${i18next.t(`batchRt:Status`)}`, alignment: 'center' },
                                { text:`${i18next.t(`batchRt:Create Date`)}`, alignment: 'center' },
                                { text:`${i18next.t(`batchRt:Create By`)}`, alignment: 'center' },
                             ],
                            ...rtDataAll
                        ]
                    }
                },
            ],                 
            defaultStyle: {
                font: 'Sarabun'
            },
            styles: {
                tableBatchDetail: {
                    margin: [0, 5, 0, 15],
                    fontSize: 8,
                },
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBlob((blob) => {
            let preview = URL.createObjectURL(blob)
            window.open(preview)
        });
    };

    return (
                <Link
                    className="dropdown-item printItem"
                    to="route"
                    target="blank"
                    onClick={(event) => {
                        event.preventDefault();
                        generatePdf();
                    }}>
                    {i18next.t(`batchRt:Print PDF`)}                    
                </Link>
    );


}

export default ExportFixReportPDF;