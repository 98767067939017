/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type reportRegisterDetailsQueryVariables = {|
  yearId?: ?string
|};
export type reportRegisterDetailsQueryResponse = {|
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +nameEn: ?string,
          +chartOfAccountCode: string,
          +totalHistoricalDebit: ?number,
          +totalHistoricalCredit: ?number,
        |}
      |}>,
    |}
  |},
  +oneYearBudget: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +years: string,
        +startDate: any,
        +endDate: any,
        +project: {|
          +name: string
        |},
      |}
    |}>
  |},
|};
export type reportRegisterDetailsQuery = {|
  variables: reportRegisterDetailsQueryVariables,
  response: reportRegisterDetailsQueryResponse,
|};
*/


/*
query reportRegisterDetailsQuery(
  $yearId: String
) {
  viewer {
    allChartOfAccount(getHistoricalValue: true) {
      totalCount
      edges {
        node {
          id
          name
          nameEn
          chartOfAccountCode
          totalHistoricalDebit
          totalHistoricalCredit
        }
      }
    }
    id
  }
  oneYearBudget(yearId: $yearId) {
    edges {
      node {
        years
        startDate
        endDate
        project {
          name
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "yearId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "getHistoricalValue",
      "value": true
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameEn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chartOfAccountCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalCredit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allChartOfAccount(getHistoricalValue:true)"
},
v4 = [
  {
    "kind": "Variable",
    "name": "yearId",
    "variableName": "yearId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "years",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reportRegisterDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "YearBudgetNodeConnection",
        "kind": "LinkedField",
        "name": "oneYearBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "YearBudgetNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "YearBudgetNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectNode",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reportRegisterDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "YearBudgetNodeConnection",
        "kind": "LinkedField",
        "name": "oneYearBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "YearBudgetNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "YearBudgetNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectNode",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "006f9bd245f10df6bbba6ebd7ca80947",
    "id": null,
    "metadata": {},
    "name": "reportRegisterDetailsQuery",
    "operationKind": "query",
    "text": "query reportRegisterDetailsQuery(\n  $yearId: String\n) {\n  viewer {\n    allChartOfAccount(getHistoricalValue: true) {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          nameEn\n          chartOfAccountCode\n          totalHistoricalDebit\n          totalHistoricalCredit\n        }\n      }\n    }\n    id\n  }\n  oneYearBudget(yearId: $yearId) {\n    edges {\n      node {\n        years\n        startDate\n        endDate\n        project {\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e5de68926d09a8fb5fdbc00950c72cb';

module.exports = node;
