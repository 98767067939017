

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import _ from "lodash";
import './vms.scss'

class VMSAccept extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAccept: false,
            lang:this.props.location.state.lang ? this.props.location.state.lang : false
        }

        this.downloadQR = this.downloadQR.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
    }


    handleInputChange(e) {
        // let event = _.cloneDeep(e);
        // let value = event.target.value;
        // this.setState(prevState => { return _.set(_.cloneDeep(prevState), event.target.name, value)});   
        this.setState(prevState => _.set(_.cloneDeep(prevState), "isAccept", _.get(prevState, 'isAccept') ? false : true ));
    }

     downloadQR = () => {
        const canvas = document.getElementById("react-qrcode-logo");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qrcode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    render() {

        return (
            <>
                <div id="wrapper-content">
                    <div id='vms-wrapper'>
                    <div>
                        <span onClick={ () => this.setState({lang : !this.state.lang})} className='btn-lang' style={{position:'absolute',right:'-9',top:'39px'}}>
                            <img src={this.state.lang ? (process.env.PUBLIC_URL + '/images/flags/en.png') : (process.env.PUBLIC_URL + '/images/flags/th.png')} 
                            alt="flag-icon" className='flag'/>
                            <h6><small>{this.state.lang ? 'EN':'ไทย'}</small></h6>
                        </span>
                    </div>
                     <div className='text-center pt-8 pb-8'>
                        <img
                            src={process.env.PUBLIC_URL + '/images/logo/SVM-full-dark.png'}
                            alt="svm"
                            className="svm"
                         />
                     </div>
                     <div className='head-title'>
                         <h4>{ this.state.lang ? 'Consent Form for the Collection, Use, and/or Disclosure of Personal Information' : 'หนังสือขอความยินยอมสำหรับการเก็บรวบรวมใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคล' }</h4> 
                     </div>
                     {
                        this.state.lang ?  
                      <div className='pl-10 pr-10 pt-2'>
                        <div className='pt-2'>
                        <p>
                        This document has been prepared by Port 80 Co., Ltd. for the purpose of collecting, using, and/or disclosing your personal information as detailed 
                        in this document. Given that the information Port 80 Co., Ltd. intends to obtain from you is personal data protected under the Personal Data Protection Act B.E. 2562 ("Act"),
                         to ensure these data collection activities comply with the provisions of the Act, Port 80 Co., Ltd. has prepared this document for your acknowledgment and understanding of your legal rights,
                          as well as the necessity and purpose of collecting, using, and/or disclosing your personal information. Please read carefully and understand before granting consent for the use of your personal information 
                          or any consent in this document.
                        </p>

                        <p>  If you agree to let Port 80 Co., Ltd. collect, use, and disclose your personal information according to the purpose and details about the collection, use, and disclosure of information below, please confirm your
                             intention by checking the “✔” "I consent" box at the end of this document. </p>
                        
                        <p><strong>1. Consent for the collection and use of personal data by Port 80 Co., Ltd.</strong></p>
                        <p><u>Types of persons from whom Port 80 Co., Ltd. collects data:</u></p>
                        <p>- Persons contacting to access condominium, housing estate projects, and/or  all types of property projects that use the Silverman Application software, who register for services through the following channels :</p>
                        <ul style={{listStyleType:'decimal'}}>
                            <li>Submitting registration in person at the entrance guard post of the project by registering through the Silverman Application Online Platform WebView</li>
                            <li>Registering through the Silverman Application Online Platform</li>
                        </ul>

                        <p><u>Personal data to be collected and used include:</u></p>
                        <ul>
                            <li>Date, month, and year of birth</li>
                            <li>Nationality</li>
                            <li>Gender</li>
                            <li>Citizen ID number and copy of the Citizen ID</li>
                            <li>Driver license number and copy of the driver license</li>
                            <li>Passport number and copy of the passport</li>
                            <li>Address</li>
                            <li>Phone number and email</li>
                            <li>Car or motorcycle license plate number</li>
                            <li>Credit card number</li>
                            <li>Still and moving images, such as from CCTV closed-circuit cameras, etc.</li>
                            <li>Computer traffic data</li>
                            <li>Electronic and electronic signature data</li>
                            <li>Vehicle use data within the  condominium, housing estate projects, and/or  all types of property projects </li>
                            <li>Parking fee payment/delinquency data</li>
                            <li>Fee collection/notification data</li>
                        </ul>

                        <p><u>Purpose of collecting and using personal data:</u></p>
                        <ul>
                            <li>For registration to use the Silverman Application Online Platform</li>
                            <li>For entry to the  condominium, housing estate projects, and/or  all types of property projects </li>
                            <li>To use as a username</li>
                            <li>To confirm identity</li>
                            <li>To be a database for coordination and legal claim exercises</li>
                            <li>For legal action under the parking fee regulations for cars and motorcycles within the condominium, housing estate projects, and/or  all types of property projects or other related laws, such as the Condominium Act, the Land Allocation Act, condominium regulations, etc. </li>
                            <li>To use for collection/demand/warning to pay parking fees, and notification or any actions under the terms, standards, conditions in registering the Silverman Application system</li>
                            <li>To use for electronic parking fee payment</li>
                            <li>To use for tracking and maintaining security</li>
                            <li>For marketing purposes</li>
                        </ul>

                        <p><u>Duration of personal data storage:</u></p>
                        <p>  Throughout the period the person has activities or contacts with the  condominium, housing estate projects, and/or  all types of property projects, and continuing for another 90 days from the last day the person has activities or contacts with the property project's jurisdiction.
                        </p>

                        <p><strong>2. Consent for the Disclosure of Personal Data</strong></p>
                        <p><u>Types of persons from whom Port 80 Co., Ltd. collects data:</u></p>
                        <p>- Persons contacting to access condominium, housing estate projects, and/or  all types of property projects using the Silverman Application software, who register for services through the following channels:</p>
                        <ul style={{listStyleType:'decimal'}}>
                            <li>Submitting registration in person at the entrance guard post of the property project by registering through the Silverman Application Online Platform WebView</li>
                            <li>Registering through the Silverman Application Online Platform</li>
                        </ul>

                        <p><u>Personal data to be disclosed:</u></p>
                        <ul>
                            <li>Full name</li>
                            <li>Date, month, and year of birth</li>
                            <li>Nationality</li>
                            <li>Gender</li>
                            <li>Citizen ID number and copy of the Citizen ID</li>
                            <li>Driver license number and copy of the driver license</li>
                            <li>Passport number and copy of the passport</li>
                            <li>Address</li>
                            <li>Phone number and email</li>
                            <li>Car or motorcycle license plate number</li>
                            <li>Credit card number</li>
                            <li>Still and moving images, such as from CCTV closed-circuit cameras, etc.</li>
                            <li>Computer traffic data</li>
                            <li>Electronic and electronic signature data</li>
                            <li>Vehicle use data within the condominium, housing estate projects, and/or  all types of property projects </li>
                            <li>Parking fee payment/delinquency data</li>
                            <li>Fee collection/notification data</li>
                        </ul>

                        <p><u>Purpose and rationale for disclosing data:</u></p>
                        <ul>
                            <li>For registration to use the Silverman Application Online Platform</li>
                            <li>For entry to the condominium, housing estate projects, and/or  all types of property projects </li>
                            <li>To use as a username</li>
                            <li>To confirm identity</li>
                            <li>To be a database for coordination and legal claim exercises</li>
                            <li>For legal action under the parking fee regulations for cars and motorcycles within the condominium, housing estate projects, and/or  all types of property projects or other related laws, such as the Condominium Act, the Land Allocation Act, condominium regulations, etc. </li>
                            <li>To use for collection/demand/warning to pay parking fees, and notification or any actions under the terms, standards, conditions in registering the Silverman Application system</li>
                            <li>To use for electronic parking fee payment</li>
                            <li>To use for tracking and maintaining security</li>
                            <li>For marketing purposes</li>
                        </ul>

                        <p><u>Types of individuals receiving disclosed data:</u></p>
                          <u>
                            <li>Condominium juristic persons, land allocation juristic persons, all types of property projects that the person contacting has entered, such as condominium juristic persons, land allocation juristic persons, all types of property projects</li>
                            <li>Relevant or authorized government agencies, or those with legal duties concerning the case, such as the Department of Land Transport, the Department of Local Administration, the Royal Thai Police, banks or private companies that the juristic person is contracted with or has legal relations with.</li>
                          </u>

                        <p className='pt-4'><strong>3. Withdrawal of Consent</strong></p>
                        <p> You have the right to withdraw the consent you have given to Port 80 Co., Ltd. under this document for the collection, use, and/or disclosure of your personal data at any time. In case your withdrawal of consent may have any impact, the personal data protection officer will notify you of such impact. The personal data protection officer may request additional documents or information if you are a minor, a person lacking capacity, or a quasi-incompetent person.</p>

                        <p><strong>4. Questions Regarding the Personal Data Policy</strong></p>
                        <p> If you have suggestions or inquiries regarding the details of your personal data collection, use, and disclosure, including exercising rights under this policy, you may contact the company and/or the personal data protection officer through the following channels:</p>
                        <span>Company Name: Port 80 Co., Ltd.</span><br/>
                        <span>Address: 1086/9-10, 2nd Floor, Room A, Charoen Krung Road, Bang Rak Subdistrict, Bang Rak District, Bangkok 10500</span><br/>
                        <span>Company Website: 	https://silverman.app/</span><br/>
                        <span>Company Call Center: 	081-442-6888</span><br/>
                        <span>Email: hello@silverman.app</span><br/>
                
                        <p className='pt-4'><strong>5. Contact Channels</strong></p>
                        <p> If you have any queries about the company's privacy policy, data that the company collects, or wish to exercise any rights under the Personal Data Protection law as per clause 11, you can contact us at:</p>
                        <span>Company Name: Port 80 Co., Ltd.</span><br/>
                        <span>Address: 1086/9-10, 2nd Floor, Room A, Charoen Krung Road, Bang Rak Subdistrict, Bang Rak District, Bangkok 10500</span><br/>
                        <span>Company Website: 	https://silverman.app/</span><br/>
                        <span>Company Call Center: 	081-442-6888</span><br/>
                        <span>Email: hello@silverman.app</span><br/>
                        </div>

                        <div className='row pt-4'>
                            <div className='col-2 text-right'>
                                <input className="form-check-input checkType" type="checkbox"
                                    name="isAccept"
                                    id="isAccept"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className='col-10'>
                            I confirm that I have read and filled in my information 
                            in the part where I consent to Port 80 Co., Ltd. 
                            to collect, use, and/or disclose to third parties according 
                            to this consent request form.
                            </div>
                        </div>
                        <div style={{paddingTop:'5%',paddingBottom:'10%'}}>
                                    <Link to={{ pathname:"/vms/vms-create/"+this.props.match.params.id,
                                                state:this.props.location.state.lang?this.props.location.state.lang:false,
                                    }} >
                                <button className="btn btn-primary add mr-2" style={{width:'100%'}} disabled={!this.state.isAccept}>
                                    Next
                                </button>
                            </Link>
                        </div>
                 </div> :  <div className='pl-10 pr-10 pt-2'>
                            <div className='pt-2'>
                            <p>เอกสารฉบับนี้จัดเตรียมขึ้นโดยบริษัท พอร์ต 80 จำกัด เพื่อวัตถุประสงค์ในการเก็บรวบรวม ใช้ และ หรือเปิดเผยข้อมูล
                                ส่วนบุคคลเกี่ยวกับตัวท่านตามรายละเอียดที่ปรากฏในเอกสารฉบับนี้ และเนื่องจากข้อมูลที่บริษัท พอร์ต 80 จำกัด ประสงค์จะได้รับจากท่านนี้ มีลักษณะเป็นข้อมูลส่วนบุคคลซึ่งได้รับการคุ้มครองตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (“พ.ร.บ.”) เพื่อให้การดำเนินการ
                                เก็บข้อมูลเหล่านี้เป็นไปตามข้อกาหนดของ พ.ร.บ. บริษัท พอร์ต 80 จำกัด จึงจัดทำเอกสารฉบับนี้ขึ้นเพื่อให้ท่านได้รับทราบและเข้าใจสิทธิ
                                ของท่านตามกฎหมาย รวมทั้งความจำเป็นและวัตถุประสงค์ในการเก็บ
                                รวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน โปรดอ่าน
                                โดยละเอียดและทำความเข้าใจก่อนจะทำการอนุญาตให้ใช้ข้อมูล
                                ส่วนบุคคลของท่านหรือให้ความยินยอมใด ๆ ในเอกสารฉบับนี้</p>

                            <p>หากท่านยินดีให้บริษัท พอร์ต 80 จำกัด ทำการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่านตามวัตถุประสงค์และรายละเอียด
                                เกี่ยวกับการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลด้านล่าง โปรดทำการ
                                ยืนยันความประสงค์ของท่าน โดยการทำเครื่องหมาย “✔” ลงในช่อง “ตกลงยินยอม” 
                                ในช่องด้านล่างสุดของเอกสารนี้</p>
                            
                            <p><strong>1. ความยินยอมในการเก็บรวบรวม และใช้ข้อมูลส่วนบุคคลโดยบริษัท พอร์ต 80 จำกัด
ประเภทของบุคคล ที่บริษัท พอร์ต 80 จำกัด เก็บข้อมูล</strong></p>
                            <p><u>ประเภทของบุคคล ที่บริษัท พอร์ต 80 จำกัด เก็บข้อมูล</u></p>
                            <p>- ผู้มาติดต่อขอเข้าไปในโครงการนิติบุคคล หรือ โครงการอสังหาริมทรัพย์ทุกประเภทที่ใช้งานซอฟแวร์ Silverman Application ซึ่งลงทะเบียนสมัครใช้บริการตามช่องทาง ดังนี้:</p>
                            <ul style={{listStyleType:'decimal'}}>
                                <li>ยื่นขอลงทะเบียนด้วยตนเองที่หน้าป้อมรปภ. ทางเข้าโครงการ โดยลงทะเบียนผ่าน Online Platform Web View ของ Silverman Application</li>
                                <li>ลงทะเบียนผ่าน Online Platform Silverman Application</li>
                            </ul>

                            <p><u>ข้อมูลส่วนบุคคล ที่จะมีการเก็บรวบรวม และใช้ชื่อและนามสกุล</u></p>
                            <ul>
                                <li>วัน เดือน ปี พ.ศ. เกิด</li>
                                <li>สัญชาติ</li>
                                <li>เพศ</li>
                                <li>เลขประจำตัวประชาชนและสำเนาบัตรประจำตัวประชาชน</li>
                                <li>เลขประจำตัวใบขับขี่และสำเนาบัตรประจำตัวใบขับขี่</li>
                                <li>หมายเลขหนังสือเดินทางและสำเนาหนังสือเดินทาง</li>
                                <li>ที่อยู่</li>
                                <li>เบอร์โทรศัพท์ และอีเมล</li>
                                <li>หมายเลขทะเบียนรถยนต์ หรือ รถจักรยานยนต์</li>
                                <li>หมายเลขบัตรเครดิต</li>
                                <li>ภาพนิ่ง, ภาพเคลื่อนไหว เช่น CCTV กล้องวงจรปิด เป็นต้น</li>
                                <li>ข้อมูลจราจรทางคอมพิวเตอร์</li>
                                <li>ข้อมูลอิเล็กทรอนิกส์และลายมือชื่ออิเล็กทรอนิกส์</li>
                                <li>ข้อมูลการใช้รถยนต์ในโครงการนิติบุคคล</li>
                                <li>ข้อมูลการชำระ/ค้างชำระค่าจอดรถ</li>
                                <li>ข้อมูลการแจ้งเรียกเก็บ/การเตือน ค่าธรรมเนียม</li>
                            </ul>

                            <p><u>วัตถุประสงค์และเหตุผลในการเปิดเผยข้อมูล</u></p>
                            <ul>
                                <li>เพื่อใช้ในการลงทะเบียนใช้บริการ Online Platform Silverman Application</li>
                                <li>เพื่อใช้ในการเข้าโครงการนิติบุคคล</li>
                                <li>เพื่อใช้เป็น Username</li>
                                <li>เพื่อใช้ยืนยันตัวตน</li>
                                <li>เพื่อเป็นฐานข้อมูลในการติดต่อประสานงาน และการใช้สิทธิเรียกร้องใด ๆ ตามกฎหมาย</li>
                                <li>เพื่อประโยชน์ในการดำเนิน การตามกฎหมายว่าด้วยการกำหนดค่าธรรมเนียมการจอดรถยนตร์ และรถจักรยานยนต์ภายในโครงการนิติบุคคล 
                                    หรือการดำเนินการตามกฎหมายอื่นที่เกี่ยวข้อง 
                                    เช่น พรบ.อาคารชุด, พรบ.หมู่บ้านจัดสรร, ระเบียบข้อบังคับของนิติบุคคล เป็นต้น </li>
                                <li>เพื่อใช้ในการแจ้งเก็บ/เรียกเก็บ/เตือนให้ชำระค่าจอดรถ รวมทั้งการแจ้ง หรือดำเนินการใด ๆ ตามข้อกำหนด 
                                    หลักเกณฑ์ เงื่อนไขในการลงทะเบียนระบบ Silverman Application</li>
                                <li>เพื่อใช้ในการชำระค่าจอดรถ โดยวิธีการทางอิเล็กทรอนิกส์</li>
                                <li>เพื่อใช้ในการติดตามตัว และรักษาความปลอดภัย</li>
                                <li>เพื่อใช้ในการทำการตลาด</li>
                            </ul>

                            <p><u>ระยะเวลาในการ เก็บรักษาข้อมูลส่วนบุคคล</u></p>
                            <p> ตลอดระยะเวลาที่บุคคลดังกล่าวมีกิจกรรมหรือมีการติดต่อ
                                กับโครงการนิติบุคคล และต่อไปอีก 90 วันนับจากวันสุดท้ายที่บุคคล
                                ดังกล่าวมีกิจกรรมหรือการติดต่อกับนิติบุคคลของโครงการ
                            </p>

                            <p><strong>2. ความยินยอมในการเปิดเผยข้อมูลส่วนบุคคล</strong></p>
                            <p><u>ประเภทของบุคคล ที่บริษัท พอร์ต 80 จำกัด เก็บข้อมูล</u></p>
                            <p>- ผู้มาติดต่อขอเข้าไปในโครงการนิติบุคคล ที่ใช้งานซอฟแวร์ Silverman Application ซึ่งลงทะเบียนสมัครใช้บริการตามช่องทาง ดังนี้</p>
                            <ul style={{listStyleType:'decimal'}}>
                                <li>ยื่นขอลงทะเบียนด้วยตนเองที่หน้าป้อมรปภ. ทางเข้าโครงการ โดยลงทะเบียนผ่าน Online Platform Web View ของ Silverman Application</li>
                                <li>ลงทะเบียนผ่าน Online Platform Silverman Application</li>
                            </ul>

                            <p><u>ข้อมูลส่วนบุคคลที่จะมีการเปิดเผย</u></p>
                            <ul>
                                <li>ชื่อและนามสกุล</li>
                                <li>วัน เดือน ปี พ.ศ. เกิด</li>
                                <li>สัญชาติ</li>
                                <li>เพศ</li>
                                <li>เลขประจำตัวประชาชนและสำเนาบัตรประจำตัวประชาชน</li>
                                <li>เลขประจำตัวใบขับขี่และสำเนาบัตรประจำตัวใบขับขี่</li>
                                <li>หมายเลขหนังสือเดินทางและสำเนาหนังสือเดินทาง</li>
                                <li>ที่อยู่</li>
                                <li>เบอร์โทรศัพท์ และอีเมล</li>
                                <li>หมายเลขทะเบียนรถยนต์ หรือ รถจักรยานยนต์</li>
                                <li>หมายเลขบัตรเครดิต</li>
                                <li>ภาพนิ่ง, ภาพเคลื่อนไหว เช่น CCTV กล้องวงจรปิด เป็นต้น</li>
                                <li>ข้อมูลจราจรทางคอมพิวเตอร์</li>
                                <li>ข้อมูลอิเล็กทรอนิกส์และลายมือชื่ออิเล็กทรอนิกส์</li>
                                <li>ข้อมูลการใช้รถยนต์ในโครงการนิติบุคคล</li>
                                <li>ข้อมูลการชำระ/ค้างชำระค่าจอดรถ</li>
                                <li>ข้อมูลการแจ้งเรียกเก็บ/การเตือน ค่าธรรมเนียม</li>
                            </ul>

                            <p><u>วัตถุประสงค์และเหตุผลในการเปิดเผยข้อมูล</u></p>
                            <ul>
                                <li>เพื่อใช้ในการลงทะเบียนใช้บริการ Online Platform Silverman Application</li>
                                <li>เพื่อใช้ในการเข้าโครงการนิติบุคคล</li>
                                <li>เพื่อใช้เป็น Username</li>
                                <li>เพื่อใช้ยืนยันตัวตน</li>
                                <li>เพื่อเป็นฐานข้อมูลในการติดต่อประสานงาน และการใช้สิทธิเรียกร้องใด ๆ ตามกฎหมาย</li>
                                <li>เพื่อประโยชน์ในการดำเนิน การตามกฎหมายว่าด้วยการกำหนดค่าธรรมเนียมการจอดรถยนตร์ และรถจักรยานยนต์ภายในโครงการนิติบุคคล
                                     หรือการดำเนินการตามกฎหมายอื่นที่เกี่ยวข้อง เช่น พรบ.อาคารชุด, พรบ.หมู่บ้านจัดสรร, ระเบียบข้อบังคับของนิติบุคคล เป็นต้น</li>
                                <li>เพื่อใช้ในการแจ้งเก็บ/เรียกเก็บ/เตือนให้ชำระค่าจอดรถ รวมทั้งการแจ้ง หรือดำเนินการใด ๆ ตามข้อกำหนด 
                                    หลักเกณฑ์ เงื่อนไขในการลงทะเบียนระบบ Silverman Application</li>
                                <li>เพื่อใช้ในการชำระค่าจอดรถ โดยวิธีการทางอิเล็กทรอนิกส์</li>
                                <li>เพื่อใช้ในการติดตามตัว และรักษาความปลอดภัย</li>
                                <li>เพื่อใช้ในการทำการตลาด</li>
                            </ul>

                            <p><u>ประเภทของบุคคลที่จะได้รับการเปิดเผย</u></p>
                              <u>
                                <li>นิติบุคคลที่ผู้มาติดต่อ เข้าไปในโครงการ ได้แก่ นิติบุคคลอาคารชุด, นิติบุคคลหมู่บ้านจัดสรร, โครงการอสังหาริมทรัพย์ทุกประเภท</li>
                                <li>หน่วยงานของรัฐที่เกี่ยวข้อง หรือที่มีอำนาจหน้าที่ตามกฎหมายเกี่ยวกับกรณีนั้น ๆ เช่น กรมการขนส่งทางบก กรมการปกครอง 
                                    สำนักงานตำรวจแห่งชาติ ธนาคารหรือบริษัทเอกชน ที่ทางนิติบุคคลเป็นคู่สัญญาหรือมีนิติสัมพันธ์ด้วย</li>
                              </u>

                            <p className='pt-4'><strong>3. การเพิกถอนความยินยอม</strong></p>
                            <p>ท่านมีสิทธิเพิกถอนความยินยอมที่ท่านตกลงให้กับบริษัท 
                            พอร์ต 80 จำกัด ตามเอกสารฉบับนี้ เพื่อทำการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านได้ทุกเมื่อ ในกรณีที่การเพิกถอน
                            ความยินยอมของท่านอาจส่งผลกระทบใด ๆ เจ้าหน้าที่คุ้มครองข้อมูล
                            ส่วนบุคคลจะแจ้งให้ท่านทราบถึงผลกระทบดังกล่าว เจ้าหน้าที่คุ้มครอง
                            ข้อมูลส่วนบุคคลอาจขอเอกสารหรือข้อมูลเพิ่มเติมในกรณีที่ท่านเป็น
                            ผู้เยาว์ บุคคลไร้ความสามารถ หรือบุคคลเสมือนไร้ความสามารถ</p>

                            <p><strong>4. ช่องทางการติดต่อ</strong></p>
                            <p>หากท่านมีข้อเสนอแนะหรือต้องการสอบถามข้อมูลเกี่ยวกับ
รายละเอียดการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน รวมถึงการขอใช้สิทธิตามนโยบายฉบับนี้ ท่านสามารถติดต่อบริษัท และ/หรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล ผ่านช่องทางดังนี้</p>
                            <span>ชื่อบริษัท: บริษัท พอร์ต 80 จำกัด</span><br/>
                            <span>ที่อยู่: เลขที่ 1086/9-10, ชั้น 2, ห้อง เอ, ถนนเจริญกรุง แขวงบางรัก เขตบางรัก กรุงเทพมหานคร 10500</span><br/>
                            <span>เว็บไซต์ของบริษัท: https://silverman.app/</span><br/>
                            <span>ศูนย์บริการลูกค้าทางโทรศัพท์ของบริษัท (Call Center): 081-442-6888</span><br/>
                            <span>อีเมล: hello@silverman.app</span><br/>

                            <p className='pt-4'><strong>5. ช่องทางการติดต่อ</strong></p>
                            <p>หากท่านมีข้อสงสัยเกี่ยวกับนโยบายความเป็นส่วนตัวของบริษัท
                                ข้อมูลที่บริษัทเก็บรวบรวมหรือต้องการใช้สิทธิตามกฎหมายคุ้มครอง
                                ข้อมูลส่วนบุคคลอย่างใดอย่างหนึ่งตามข้อ 11. ท่านสามารถติดต่อมายัง</p>
                            <span>ชื่อบริษัท: บริษัท พอร์ต 80 จำกัด</span><br/>
                            <span>ที่อยู่: เลขที่ 1086/9-10, ชั้น 2, ห้อง เอ, ถนนเจริญกรุง แขวงบางรัก เขตบางรัก กรุงเทพมหานคร 10500</span><br/>
                            <span>เว็บไซต์ของบริษัท: https://silverman.app/</span><br/>
                            <span>ศูนย์บริการลูกค้าทางโทรศัพท์ของบริษัท (Call Center): 081-442-6888</span><br/>
                            <span>อีเมล: hello@silverman.app</span><br/>
                            </div>

                            <div className='row pt-4'>
                                <div className='col-2 text-right'>
                                    <input className="form-check-input checkType" type="checkbox"
                                        name="isAccept"
                                        id="isAccept"
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className='col-10'>
                                    ข้าพเจ้ายืนยันว่าข้าพเจ้าได้อ่าน และกรอกข้อมูลของข้าพเจ้า
                                    ในส่วนที่ข้าพเจ้าตกลงยินยอมให้บริษัท พอร์ต 80 จำกัด
                                    เก็บรวบรวม ใช้ และ/หรือเปิดเผยต่อบุคคลภายนอก
                                    ตามหนังสือแจ้งขอความยินยอมฉบับนี้ไว้โดยเรียบร้อยแล้ว
                                </div>
                            </div>
                            <div style={{paddingTop:'5%',paddingBottom:'10%'}}>
                                 {/* <Link to={"/vms/vms-create/"+this.props.match.params.id} > */}
                                 <Link to={{ pathname:"/vms/vms-create/"+this.props.match.params.id,
                                                state:this.props.location.state.lang?this.props.location.state.lang:false,
                                          }} >
                                    <button className="btn btn-primary add mr-2" style={{width:'100%'}} disabled={!this.state.isAccept}>
                                        ถัดไป
                                    </button>
                                </Link>
                            </div>
                     </div>
                     }
                    
                    
                    </div>
                </div>
            </>
                
        );
    }
}

export default VMSAccept;
