import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { decode } from 'base-64';
import APiCliam from "../../../../api/claim"

import _ from 'lodash';
import i18next from 'i18next';
// const allResidential = graphql`
//     query createClaimQuery {
//         allResidential {
//             totalCount
//             edges {
//                 node {
//                     id
//                     name
//                     floor
//                     size
//                     status
//                     residentialHouseholder{
//                         edges{
//                             node{
//                                 id
//                                 tenant{
//                                     id
//                                     firstName
//                                     lastName
//                                     countries
//                                     phone
//                                     user{
//                                         id
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     type{
//                         id
//                         name
//                     }
//                 }
//             }
//         }
//     }
// `;

class SummarizeClaimCancel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingDetails: true,
            selectedBtn: "",
            status: "cGVuZGluZ19hcHByb3Zl" || decode(this.props.match.params.status),
            // new = bmV3
            // consider_fail = Y29uc2lkZXJfZmFpbA==
            // pending_approve = cGVuZGluZ19hcHByb3Zl
            // not_approve = bm90X2FwcHJvdmU=
            // approve = YXBwcm92ZQ==
            // finish = ZmluaXNo

            file: [],
            deTailProperty: [],
            isamountToClaim: 1,
            amountToClaim: null,
            doc_name: "CR2021080001"
        }
        this.selectedBtn = this.selectedBtn.bind(this)
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
    }
    componentWillMount() {
        let id = decode(this.props?.id) || ""
        APiCliam.GetCliamID(id).then((res) => {
            this.setState({
                amountToClaim: res.data.claim.claim_detail.claim_amount,
                doc_name: res.data.claim.claim_detail.doc_number
            })
        })
    }


    onSubmit(e) {
        e.preventDefault();
        let residential_id = this.state.Requsetfrom === "Common" ? "" : decode(this.state.residential_id)
        residential_id = this.state.Requsetfrom === "Common" ? "" : residential_id.split(":")

        let dataSubmit = {

        }
    }

    selectedBtn(e) {
        let type = e.target.value
        let selectedBtn = _.cloneDeep(this.state.selectedBtn)
        if (selectedBtn !== type) {
            document.getElementById("selectedBtn" + type).classList.remove("bg-E9ECEF")
            document.getElementById("selectedBtn" + type).classList.add("bg-1983E8")
            if (selectedBtn) {
                document.getElementById("selectedBtn" + selectedBtn).classList.remove("bg-1983E8")
                document.getElementById("selectedBtn" + selectedBtn).classList.add("bg-E9ECEF")
            }
            this.setState({ selectedBtn: type })
        }
    }


    render() {
        let imageRadioTrue = "/images/iconAction/radioTrue.png"
        let imageRadioFalse = "/images/iconAction/radioFalse.png"
        return (
            <form onSubmit={this.onSubmit}>
                <div className='ResultClaim mt-10'>
                    <label className='headerLabel28'>สรุปค่าสินไหม</label>
                    <hr /> <br />
                    <label className='headerLabel18'>จำนวนเงินที่ขอเคลม <span className='text-red'> * </span> (บาท)</label>
                    <br />
                    <input type={"number"} name='amountCliam'
                        defaultValue={this.state.amountToClaim ? parseInt(this.state.amountToClaim).toFixed(2) : ""}
                        placeholder='0.00'
                        className='form-control inputBox text-right' style={{ width: 222 }} 
                        // required={true} 
                        readOnly
                    />
                    <br />
                    <div className='row'>
                        <div className='col-4'>
                            <label className='headerLabel18'>เลือกสถานะประกัน</label>
                            <br />
                            <button id="selectedBtn1" type='button' value={1} className='btn btn-outline bg-E9ECEF m-2'
                                style={{ width: 160 }} >
                                ประกันอนุมัติ</button>
                            <br />
                            <button id="selectedBtn2" value={2} type='button' className='btn btn-outline bg-808080 m-2'
                                style={{ width: 160 }} >{i18next.t("claimRequstList:Warranty disapproved")}</button>
                        </div>
                    </div>
                </div>



                <div className='text-right'>
                    <Link to={"/purchasing/claim/void"}>
                        <button className='btn btn-outline bg-1567B4 w222'>กลับสู่หน้าเคลม</button>
                    </Link>
                </div>


            </form>
        )
    }
}

export default SummarizeClaimCancel;
