import React, { Component } from 'react'
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Translation } from 'react-i18next';
import Navigation from './navigation';
import {fetchQuery} from "relay-runtime";
import environment from "../../../env/environment";
import { Link } from 'react-router-dom';
import { graphql } from "babel-plugin-relay/macro";
import ReportCollectionLawFrimTable from "./reportCollectionLawFrimTable"

const query = graphql`
    query reportCollectionLawFrimQuery{
        countNotiCollectionLawFirm {
          countFollow
          countPaid
     }
    }
`;
class ReportCollectionLawFrim extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countFollow:null,
            countPaid:null
        }
    }
    componentDidMount(){
        this.queryCheckUserActive()
    }
    queryCheckUserActive(){
        fetchQuery(environment, query).then(response => { 
          if(response.countNotiCollectionLawFirm){
            this.setState({
              countFollow: response.countNotiCollectionLawFirm.countFollow,
              countPaid: response.countNotiCollectionLawFirm.countPaid
          })
          }
        }
     )}

    render(){
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <Translation>
                        {
                            t => 
                            <div className="container-fluid box" id="lawyer-summary-report">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/accounting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <Translation>
                                                {
                                                    t => <span>{t('collectionLawFirm:Report summarizing the expenses for legal document tracking by attorneys')}</span>
                                                }
                                            </Translation>                                           
                                        </h3>
                                    </div>
                                </div>
                                <div className='content-inner'>
                                    <Navigation countFollow={this.state.countFollow} countPaid={this.state.countPaid} />
                                    <ReportCollectionLawFrimTable status={this.props.match.params.status}/>
                                </div>
                            </div>
                        }
                    </Translation>


                </WrapperContent>
            </Wrapper>
        );
    }




}
export default ReportCollectionLawFrim;