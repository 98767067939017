import React, { Component } from 'react'
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import _ from "lodash"
import numberWithCommas from "../../../libs/numberWithCommaReport";
import { Dropdown  } from "react-bootstrap";
import axios from 'axios';
import getApiRoot from "../../../libs/getAPIRoot";

require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

export default class reportRegisterListExport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      projectName: "",
    }
  }
  componentWillMount() {
    this.setState({ projectName: this.props.projectName });
  }

  getRowColCode = (indexRow, indexCol) => {
    let value = ""
    switch (indexCol+1) {
      case 1:
        value = "A"
        break;
      case 2:
        value = "B"
        break;
      case 3:
        value = "C"
        break;
      case 4:
        value = "D"
        break;
      case 5:
        value = "E"
        break;
      case 6:
        value = "F"
        break;
      case 7:
        value = "G"
        break;
      case 8:
        value = "H"
        break;
      case 9:
        value = "I"
        break;
      case 10:
        value = "J"
        break;
      case 11:
        value = "K"
        break;
      case 12:
        value = "L"
        break;
      case 13:
        value = "M"
        break;
      case 14:
        value = "N"
        break;
      case 15:
        value = "O"
        break;
      case 16:
        value = "P"
        break;
      case 17:
        value = "Q"
        break;
      case 18:
        value = "R"
        break;
      case 19:
        value = "S"
        break;

      default:
        break;        
    }

    return `${value}${indexRow}`
  }

  downloadExcel = () => {
    var token_id = localStorage.getItem("token");
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('report register');

    // setWidthcolumns
    let columns = [
      { width: 15 },
      { width: 25},
      { width: 15},
      { width: 15},
      { width: 15},
      { width: 15},
      { width: 15},
    ]

    // setBorder
    let borders = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    }

    let fontSizes = { size: 11.5}
    let textCenter = { vertical: 'middle', horizontal: 'center' };
    // let fontBold = { size: 11.5, bold: true};

    worksheet.getCell('A1').value = this.state.projectName;
    worksheet.getCell('A1').font = {
      size: 11.5,
      bold: true
    };

    worksheet.getCell('A2').value = `ทะเบียนควบคุมการใช้งบประมาณ`
    worksheet.getCell('A2').font = {
      size: 11.5,
      bold: true
    };

    worksheet.getCell('A3').value = `สำหรับรอบระยะเวลา ${this.props.rangeDate} เดือน วันที่ ${format(this.props.queryDate.startDate, " D MMMM YYYY ", { locale: thLocale })} ถึง ${format(this.props.date, " D MMMM YYYY ", { locale: thLocale })}`
    worksheet.getCell('A3').font = {
      size: 11.5,
      bold: true
    };

    let dataTable =[]

    {_.map(this.props.queryDataReport.edges, (item, index) => {
      let sumTotalHappend = 0
      let accountRecordSordIsuedate = this.props.sortData(item.node.chartOfAccount.accountRecord.edges)

      dataTable.push( 
        [`${item.node.chartOfAccount.chartOfAccountCode} ${item.node.chartOfAccount.name}`],
        [''],
        ['วันที่', 'คำอธิบาย', '', 'เลขที่เอกสาร', 'เกิดจริง', 'งบประมาณ/ปี', `${numberWithCommas(parseFloat(item.node.totalBudget).toFixed(2))}`],
        ['', '', '', '', '', 'งบประมาณ/เดือน', 'เกิดจริง มาก(น้อย)กว่างบฯ'],
      )

      {_.map(accountRecordSordIsuedate, (item_child, index_child) => {
        let sumHappendOfMonth = 0
        let totalOfMonth = _.filter(item.node.monthBudget.edges, (o) => o.node.months.substring(0, 7) === item_child.month)

        _.map(item_child.accountRecord, (item_c_child, index_c_child) => {
          if(item.node.chartOfAccount.chartOfAccountCode.substring(0,1) === "4"){
            sumHappendOfMonth += item_c_child.node.credit - item_c_child.node.debit
            sumTotalHappend += item_c_child.node.credit - item_c_child.node.debit
          }else if(item.node.chartOfAccount.chartOfAccountCode.substring(0,1) === "5" ){
            sumHappendOfMonth += item_c_child.node.debit - item_c_child.node.credit
            sumTotalHappend += item_c_child.node.debit - item_c_child.node.credit
          }
          dataTable.push([
            `${format(item_c_child.node.group.issuedDate, "DD/MM/YYYY")}`,
            `${item_c_child.node.name} ${item_c_child.node.group.name}`,
            "",
            item_c_child.node.group.refNumber || item_c_child.node.group.refTransaction,
            item.node.chartOfAccount.chartOfAccountCode.substring(0,1) === "4" 
              ? numberWithCommas((item_c_child.node.credit - item_c_child.node.debit).toFixed(2))
              : item.node.chartOfAccount.chartOfAccountCode.substring(0,1) === "5" 
                  && numberWithCommas((item_c_child.node.debit - item_c_child.node.credit).toFixed(2)),
            "",
            ""
          ])
        })

        dataTable.push(
          [
            "", 
            "", 
            "รวม", 
            format(item_child.month, "MMMM YYYY", { locale: thLocale }), 
            numberWithCommas(sumHappendOfMonth.toFixed(2)),
            (totalOfMonth[0]?.node?.budget || 0) !== 0 
              ?  numberWithCommas(totalOfMonth[0]?.node?.budget)
              :  "-",
            numberWithCommas(sumHappendOfMonth - (totalOfMonth[0]?.node?.budget || 0))
          ]
        )
      })}

      dataTable.push(["", "", "", "", "รวมการใช้งบประมาณ", "", sumTotalHappend !== 0 ? numberWithCommas(sumTotalHappend.toFixed(2)) : "-" ])
      dataTable.push(["", "", "", "", 
        "งบประมาณคงเหลือ / ปี+", 
        "", 
        (item.node.totalBudget - sumTotalHappend) !== 0   
          ? numberWithCommas(((item.node.totalBudget || 0) - sumTotalHappend))
          : "-" 
      ])

    })}


    _.map(dataTable, (value, index) => {
      worksheet.getRow(4 + index).values = value
    })

    _.map(dataTable, (value, index) => {
      if(dataTable[index].length ===  7){
        _.map(value, (value2, indexValue) => {
          worksheet.getRow(4 + index).getCell(1 + indexValue).font = fontSizes;
          worksheet.getRow(4 + index).getCell(1 + indexValue).border = borders;
          worksheet.getRow(4 + index).getCell(1 + indexValue).alignment = textCenter;

          if(value2){
            switch (value2) {
              case "วันที่":
                worksheet.mergeCells(this.getRowColCode(4 + index,indexValue), this.getRowColCode(5 + index,indexValue));
                break;
              case "คำอธิบาย":
                worksheet.mergeCells(this.getRowColCode(4 + index,indexValue), this.getRowColCode(5 + index,indexValue));
                break;
              case "เลขที่เอกสาร":
                worksheet.mergeCells(this.getRowColCode(4 + index,indexValue - 1), this.getRowColCode(5 + index,indexValue - 1));
                worksheet.mergeCells(this.getRowColCode(4 + index,indexValue), this.getRowColCode(5 + index,indexValue));
                break;
              case "เกิดจริง":
                worksheet.mergeCells(this.getRowColCode(4 + index,indexValue), this.getRowColCode(5 + index,indexValue));
                break;

              default:
                break;
            }
          }
        })
      }
    })

    worksheet.columns = columns;

    workbook.xlsx.writeBuffer()
    .then((data) => {
      const blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      let nameFile = `reportRegister_${format(this.props.date, "DD/MM/YYYY")}`
      let formData = new FormData();
      formData.append('file', blob , "report.xlsx");
      formData.append('type', "excel");
      formData.append('type_report_ref', "report_register_list");
      axios.post(`${getApiRoot()}taskdownload/add_download`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `JWT ${token_id}`,
        }
      })
      .then(response => {
        console.log('File uploaded successfully:', response.data);
      })
      .catch(error => {
        console.error('There was an error sending the file to the server:', error);
      })
      .finally(() => {
        // Download the file after sending it to the API
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = nameFile;
        a.click();
      });
    });
  }

  render() {
    return (
      <Dropdown.Item onClick={this.downloadExcel}>
        <p className="text-black">Excel</p>
      </Dropdown.Item>
    )
  }
}
