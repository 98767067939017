import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { Helmet } from "react-helmet";
import environment from "../../env/environment";
import { commitMutation } from "react-relay";
import { QueryRenderer } from "react-relay";
import "./fix_request.scss"
import i18n from 'i18next';
import api from '../../api/sing'
import { decode } from 'base-64';
import { format } from 'date-fns';
import jwtDecode from "jwt-decode";
const query = graphql`
query fixRequestWorksheetQuery{
    selfProject{
        id
        name
        address
        logo
        juristicContactNumber
        keyProjectQr
        bankCompCode
        bankServiceCode
        taxIdNumber
        typeOfProject
        postcodeProject
        provinceProject
        districtProject
        subDistrictProject
    }
    userConfig {
        id 
        languages
    }
}`

const mutation = graphql`
    mutation fixRequestWorksheetMutation($input: UpdateUserConfigInput!) {
        UpdateUserConfig(input: $input) {
            newUserConfig {
                id languages
            }
        }
    }
`;

class fixRequestWorksheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: {},
            lang: "th"

        }
        this.print = this.print.bind(this);
    }
    componentWillMount() {
        this.getData()
        let lang = localStorage.getItem("language")

        if (lang === 'en') {
            let variables = {
                input: { languages: "en" }
            };
            commitMutation(environment, {
                mutation,
                variables,
                onCompleted: (response, errors) => { },
                onError: error => {
                    alert('server error')
                },
                optimisticUpdater: (store) => {
                    // store.get(props.userConfig.id).setValue(languages, 'languages');
                    i18n.changeLanguage("en");
                }
            });
        }

    }
    // componentDidUpdate(prevProps) {
    //     console.log(prevProps);
    //     console.log(this.props.match.params.repairroomid);
    //     if (prevProps.repairroomid !== this.props.match.params.repairroomid) {
    //         this.getData()
    //     }else{
    //         console.log(prevProps);
    //     }
    // }

    getData() {
        let lang = localStorage.getItem("language")
        let worksheetId = decode(this.props.match.params.repairroomid)
        let data = {
            "id": worksheetId,
            "repairs_room_id": ""
        }

        api.getRepairs_room_worksheet(data).then((res) => {
            this.setState({ data: res.data.data[0], loading: false, lang: lang })
        })

    }

    repeatDot(count) {
        let dot = '';
        let i;
        for (i = 0; i < count; i++) {
            dot = dot + '.'
        }
        return dot
    }

    print() {
        window.print();
    }

    render() {
        return (
            <div className='fixrequestWorksheet' id="fix_request">
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>Fix request</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>
                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{ use_cache: false }}
                    render={({ error, props }) => {
                        if (error) {
                            return <div className="alert alert-danger" role="alert">{error.message}</div>;
                        } else if (props) {
                            // this.setState({typeOfProject : props.selfProject.typeOfProject}) 
                            let paseName = jwtDecode(localStorage.getItem("token"))
                 
                            return (
                                <React.Fragment>
                                    {
                                        this.state.loading == false ?
                                            <div className="print-page-a4">
                                                <div className="subpage">
                                                    <div className="head">
                                                        <div className="juristic-description">
                                                            <div>
                                                                <img src={process.env.PUBLIC_URL + props.selfProject.logo} alt="Logo" />
                                                                <strong>{props.selfProject.name}</strong>
                                                                <div>{props.selfProject.address} โทร {props.selfProject.juristicContactNumber || '-'}</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="detail" style={{ wordBreak: "break-word", wordWrap: 'break-word' }}>
                                                        <>
                                                            <h5 className="text-center">{i18n.t("fix_request:repair_form")}</h5>

                                                            <p className='text-right'>
                                                                {i18n.t("fix_request:no")}
                                                                <span className='spanWordonLine' style={{ marginLeft: "5px" }}>
                                                                    {
                                                                        this.state.data?.repairs_room.fast_job_code ?
                                                                            this.state.data.repairs_room.fast_job_code :
                                                                            this.state.data?.doc_number
                                                                    }
                                                                </span>
                                                                {this.repeatDot(50)}
                                                            </p>
                                                            <b>{i18n.t("fix_request:part_1")}</b>
                                                            {/* 1 */}
                                                            <p>
                                                                {i18n.t("fix_request:item_1")}

                                                                <span className='spanWordonLine left'>
                                                                    {this.state.data.repairs_room?.other_contact ?
                                                                        this.state.data.repairs_room?.other_contact :
                                                                        this.state.data.repairs_room?.tenant_contact.name_title + this.state.data.repairs_room?.tenant_contact?.first_name + " " + this.state.data.repairs_room?.tenant_contact?.last_name}
                                                                </span>
                                                                {this.repeatDot(this.state.lang === "th" ? 105 : 90)}
                                                                {props.selfProject.typeOfProject === "VILLAGE" ? ` ${i18n.t("silvermanguard:HomeId")}` : ` ${i18n.t("residential:Room No.")}`}
                                                                <span className='spanWordonLine' style={{ left: "450px" }}>
                                                                    {' ' + this.state.data.repairs_room.residential?.name}
                                                                </span>
                                                                {this.repeatDot(95)}
                                                            </p>
                                                            {/* 2 */}
                                                            <p>{i18n.t("fix_request:item_2")}
                                                                <span className='spanWordonLine left'>
                                                                    {this.state.data.repairs_room?.phone_contact}
                                                                </span>
                                                                {this.repeatDot(this.state.lang === "th" ? 106 : 101)}

                                                            </p>
                                                            {/* 3 */}
                                                            <p>{i18n.t("fix_request:item_3")}
                                                                <span className='spanWordonLine' style={{ left: '100px' }}>
                                                                    {
                                                                        this.state.data.repairs_room.juristic ?
                                                                            this.state.data.repairs_room.juristic.first_name + " " + this.state.data.repairs_room.juristic.last_name
                                                                            :
                                                                            paseName?.first_name + " " +
                                                                            paseName?.last_name
                                                                    }
                                                                </span>
                                                                {this.repeatDot(90)}
                                                                {i18n.t("fix_request:time")}
                                                                <span className='spanWordonLine' style={{ left: '380px' }}>
                                                                    {format(this.state.data.repairs_room.added, `${this.state.lang === "th" ? "HH:mm น." : "hh:mm A"}`)}
                                                                </span>
                                                                {this.repeatDot(this.state.lang === "th" ? 59 : 54)}
                                                                {i18n.t("fix_request:date")}
                                                                <span className='spanWordonLine' style={{ left: this.state.lang === "th" ? '540px' : "570px" }}>
                                                                    {format(this.state.data.repairs_room.added, "DD/MM/YYYY")}
                                                                </span>

                                                                {this.repeatDot(58)}</p>
                                                            {/* 4 */}
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                                                <p>{i18n.t("fix_request:item_4")}
                                                                    <span className='spanWordonLine' style={{ left: "160px" }}>
                                                                        {this.state.data.repairs_room?.working_type + ", " + this.state.data.repairs_room?.material + ", " + this.state.data.repairs_room?.details}
                                                                    </span>

                                                                    {this.repeatDot(this.state.lang === "th" ? 200 : 209)}
                                                                </p>
                                                                {/* {
                                                                            this.state.data.repairs_room.image ?
                                                                                <p><img style={{ height: 100, marginRight: 8 }} src={this.state.data.repairs_room.image} alt="fix_request" /></p> : ""
                                                                        } */}

                                                            </div>
                                                            {/* {!this.state.data.room.detail &&
                                                        <React.Fragment>
                                                            <p>{this.repeatDot(250)}</p>
                                                            <p>{this.repeatDot(250)}</p>
                                                        </React.Fragment>
                                                    } */}

                                                            <p>{i18n.t("fix_request:note")}
                                                                <span className='spanWordonLine' style={{ left: '100px' }}>
                                                                    {this.state.data.remark}
                                                                </span>
                                                                {this.repeatDot(this.state.lang === "th" ? 232 : 240)}
                                                            </p>

                                                            {/* 5 */}
                                                            <p>{i18n.t("fix_request:item_5")}
                                                                <span className='spanWordonLine' style={{ left: this.state.lang === "th" ? '170px' : 200 }}>
                                                                    {
                                                                        this.state.data.job_responsed_by_staff_name ?
                                                                            this.state.data.job_responsed_by_staff_name :
                                                                            this.state.data.project_staff ?
                                                                                this.state.data.project_staff.name_title + this.state.data.project_staff.name_staff + " " + this.state.data.project_staff.last_name_staff :
                                                                                this.state.data.project_staff_other

                                                                    }
                                                                </span>

                                                                {this.repeatDot(80)}
                                                                {i18n.t("fix_request:position")}

                                                                <span className='spanWordonLine' style={{ left: this.state.lang === "th" ? '433px' : 480 }}>
                                                                    {this.state.data.project_staff?.role}
                                                                </span>

                                                                {this.repeatDot(this.state.lang === "th" ? 80 : 90)}
                                                            </p>
                                                            <b>{i18n.t("fix_request:part_2")}</b>

                                                            {/* 6 */}
                                                            <p>{i18n.t("fix_request:item_6")} {this.repeatDot(this.state.lang === "th" ? 235 : 215)}</p>
                                                            <p>{this.repeatDot(250)}</p>
                                                            <p>{this.repeatDot(250)}</p>

                                                            {/* 7 */}
                                                            <p>{i18n.t("fix_request:item_7")} {this.repeatDot(this.state.lang === "th" ? 161 : 155)}</p>
                                                            <p>{this.repeatDot(250)}</p>
                                                            <p>{this.repeatDot(250)}</p>


                                                            {/* 8 */}
                                                            <div className='row'>
                                                                <div className={this.state.lang === "th" ? `col-7`: `col-5`}>
                                                                    <p>{i18n.t("fix_request:item_8")}
                                                                        <input type="checkbox" id="technician-in" style={{ marginLeft: '10px', position: "relative", top: "2px" }} /><label htmlFor="technician-in">&emsp;{i18n.t("fix_request:in_house_Handyman")}</label>
                                                                    </p>
                                                                </div>
                                                                <div className={this.state.lang === "th" ? `col-5`: `col-7`}>
                                                                    <p><input type="checkbox" id="warranty" style={{ marginLeft: '10px', position: "relative", top: "2px" }} /><label
                                                                        htmlFor="warranty">&emsp;{i18n.t("fix_request:warranty")}</label></p>
                                                                    <div><input type="checkbox" id="technician-out" style={{ marginLeft: '10px', position: "relative", top: "2px" }} /><label
                                                                        htmlFor="technician-out">&emsp;{i18n.t("fix_request:commercial_Handyman")}</label>
                                                                    </div>
                                                                    <p>&emsp;&emsp;{i18n.t("fix_request:no")} {this.repeatDot(65)}</p>
                                                                </div>
                                                            </div>

                                                            <br />
                                                            {/* 9 */}
                                                            <p>{i18n.t("fix_request:item_9")} {this.repeatDot(this.state.lang === "th" ? 218 : 197)}</p>
                                                            <br />
                                                            <div className='row'>
                                                                <div className='col-7'>
                                                                    <p>{i18n.t("fix_request:evaluator")} {this.repeatDot(100)}</p>
                                                                    <p>{i18n.t("fix_request:date")} {this.repeatDot(108)}</p>
                                                                </div>
                                                                <div className='col-5'>
                                                                    <p>{i18n.t("fix_request:approver")} {this.repeatDot(this.state.lang === "th" ? 85 : 79)}</p>
                                                                    <p>{i18n.t("fix_request:date")} {this.repeatDot(this.state.lang === "th" ? 90 : 86)}</p>
                                                                </div>
                                                            </div>

                                                            <b>{i18n.t("fix_request:part_3")}</b>


                                                            {/* 10 */}
                                                            <p>{i18n.t("fix_request:item_10")} {this.repeatDot(this.state.lang === "th" ? 205 : 201)}</p>
                                                            <p>{this.repeatDot(250)}</p>
                                                            <p>{this.repeatDot(250)}</p>

                                                            {/* 11 */}
                                                            <p>{i18n.t("fix_request:item_11")}</p>
                                                            <div className='row'>
                                                                <div className='col-7'>
                                                                    <input type="checkbox" id="pass" /><label
                                                                        htmlFor="pass">&emsp;{i18n.t("fix_request:pass")}</label>
                                                                    <br />
                                                                    <b>
                                                                        {i18n.t("fix_request:inspector")}&nbsp;
                                                                    </b>
                                                                    {this.repeatDot(70)}
                                                                </div>
                                                                <div className='col-5'>
                                                                    <input type="checkbox" id="no-pass" /><label
                                                                        htmlFor="no-pass">&emsp;{i18n.t("fix_request:not_approve")}</label>
                                                                    <br />
                                                                    <b>
                                                                        {i18n.t("fix_request:date")}&nbsp;
                                                                    </b>
                                                                    {this.repeatDot(70)}
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div>Loading...</div>
                                    }
                                </React.Fragment>
                            )
                        }
                        return <div className="text-center">Loading</div>;
                    }}
                />

            </div>

        );
    }
}

export default fixRequestWorksheet;
