import { graphql } from "babel-plugin-relay/macro";

const query = graphql`
    query oldBoardQuery($search: String,$first: Int, $last: Int){
        oldTenants (search: $search,first: $first, last: $last) {
            totalCount
            edges{
                node{
                    id
                    firstName
                    lastName
                    householder {
                        edges {
                            node {
                                residential {
                                    name
                                }
                            }
                        }
                    }
                    boadPersonal{
                    edges{
                        node{
                            id
                            image
                            signature
                            position
                            authorization
                            financial
                            status
                            note
                        } 
                    } 
                    }
                    boadWork{
                    edges{
                        node{
                            id
                            nameOfCompany
                            position
                            dateOfPosition
                            dateOfEndPosition
                        } 
                    } 
                    }
                    boadEducation{
                    edges{
                        node{
                            id
                            name
                            degree
                            state
                        } 
                    } 
                    }
                    boadFilepdf{
                    edges{
                        node{
                            id
                            nameFile
                            fileUpload
                        } 
                    } 
                    }
                    boadTerm{
                    edges{
                        node{
                            id
                            startDate
                            expDate  
                        } 
                    } 
                    }
                }
            }
        }
    }
`

export default function getQueryOldBoard() {
    return query
}