import React , {useState} from 'react'
import {Translation} from "react-i18next"
import _ from 'lodash';
import { Modal} from 'react-bootstrap';
import unitStatusAPI from '../unitStatusAPI';


function AddEditList(props) {
    const [show, setShow] = useState(false);
    const [type] = useState(props.type)
    const [transaction , setTransaction] = useState(props.transaction)
    const [status] = useState(props.status)
    const [index] = useState(props.index)
  
    const handleClose = () => {
      setShow(false)
      setTransaction(props.transaction)
    }

    const handleShow = () => {
      setShow(true)
    }
      
    const handleConfirm = e => {
      e.preventDefault();
      if(status === 'add'){
        setTransaction(props.transaction)
        unitStatusAPI.createInsurance(transaction,localStorage.getItem("token")).then(() => {
          props.appendNewRow(type)
        })
      }else{
        props.editTransaction(index,type,transaction)
      }
      
      setShow(false)
    }

    const handleChange = e => {
      const {name , value} = e.target
      let newTransaction = _.cloneDeep(transaction)
      newTransaction[name] = value
      if(type === 'extraCost'){
        newTransaction['isExtra'] = props.transaction.isExtra
      }else{
        newTransaction['isInsure'] = props.transaction.isInsure
      }
      setTransaction(newTransaction)
    }
    
  
    return (
      <Translation>
        {t =>
          <React.Fragment>
            {status === 'add' ?
              <button
                  type="button"
                  className="btn add-button add mb-4 "
                  onClick={handleShow}
                  >
                  <span className="text-blue">+ {t('createUpdateForm:add_list')}</span>
              </button> 
              :
              <span className="dropdown-item cursor" onClick={handleShow} >
                <img src={process.env.PUBLIC_URL +"/images/icons/edit.png"} alt="edit"/>
                <Translation>{t=><span className="ml-3">{t("unitStatus:Edit")}</span>}</Translation>
              </span>
            }

            <Modal show={show}  onHide={handleClose}>
              <Modal.Header style={{borderBottom:'none'}} >
                <Modal.Title>
                  <h4 className="modal-title">{t(type+':'+ (status === 'edit' ? 'Edit':'Add'))}</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                <form id="form-transfer" onSubmit={handleConfirm}>
                  <div className="row input-group mt-1 mb-1">
                    <div className="col-12">
                            <span>{t(type+':Name')}</span>
                        </div>
                        <div className="col-12">
                            <input type="text" className="form-control" name="name" 
                              onChange={handleChange} 
                              value ={transaction !== '' ? transaction.name : ''}
                              required={true}/>
                      </div>
                  </div>
                  {type === 'insurance' &&
                      <React.Fragment>
                          <div className="row input-group mt-3 mb-1">
                              <div className="col-12">
                                  <span>{t('insurance:Description')}</span>
                              </div>
                              <div className="col-12">
                                  <input type="text" className="form-control" name="description"
                                      onChange={handleChange}
                                      value={transaction !== '' ? transaction.description : ''}
                                      required={type === 'insurance'}/>
                              </div>
                          </div>
                          {/* <div className="row input-group mt-3 mb-1">
                              <div className="col-12">
                                  <span>{t('insurance:Expiration insure date')}</span>
                              </div>
                              <div className="col-12">
                                  <DatePickerAdapter 
                                      selected={transaction !== '' ? transaction.expDate : ''}
                                      onChange={handleChange}
                                      name="expDate"
                                      required={type === 'insurance'}/>
                              </div>
                          </div> */}
                      </React.Fragment>
                  }
                  {type === 'extraCost' &&
                      <React.Fragment>
                          <div className="row input-group mt-2">
                              <div className="col-12">
                                  <span>{t('extraCost:Price')}</span>
                              </div>
                              <div className="col-12">
                                  <input type="number" className="form-control" name="price"
                                      onChange={handleChange}
                                      value={transaction !== '' ? (transaction.price ? transaction.price : '') : ''}
                                      required={type === 'extraCost'}/>
                              </div>
                          </div>
                      </React.Fragment>
                  }
                </form>
              </Modal.Body>
              <Modal.Footer style={{borderTop:'none'}}>
                <button type="submit" form="form-transfer" className="btn add-button add" >
                  <span style={{color:'#0382FA'}}>{t('unitStatus:'+ (status === 'edit'? 'Confirm' : 'Add List'))}</span>
                </button>
                <button type="button" className="btn add-button add" data-dismiss="modal"onClick={handleClose}>
                  <span className="text-blue">{t('unitStatus:Close')}</span>
                </button>
              </Modal.Footer>
            </Modal>
          </React.Fragment>
          }
      </Translation>
    );
  }

export default AddEditList;