import React, { Component } from 'react'
import numberWithComma from '../../../../libs/numberWithComma'
import SelectNotiDate from "../selectNotiDate/selectNotidate"
import "../style/contractExpense.scss"
import i18next from 'i18next'

export default class summarizeContractExpense extends Component {

  constructor(props){
    super(props)

    this.state = {
      sumItemsTotal: "",
      sumVat: "",
      sumTotalAmount: ""
    }
  }

  render() {
    return (
      <div id="summarizeContractExpense">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-12 mt-3">
                <h6>{i18next.t("updateFormContractExpense:Note")}</h6>
                <div className="input-group">
                  <textarea 
                    disabled
                    className="bodyTextArea-disibled"
                    value={this.props.remark}
                    />
                </div>
              </div>

              <div className="col-8 mt-3">
                <h6>{i18next.t("updateFormContractExpense:Notification Days Before Expiry")}</h6>
                <SelectNotiDate
                  disibleInput={this.props.status === "WAIT" ? false : true}
                  notiDay={this.props.notiDay}
                  handleChangeInput={this.props.handleChangeInput}
                  name="notiDay"
                  />
              </div>
            </div>
          </div>

          <div className="col-6">
           <div className="frameSummary">
              <div className="textSummary">
                <h5 className="headItems">{i18next.t("updateFormContractExpense:Amount")}</h5>
                <p className="detailItems">{numberWithComma(this.props.sumItemsTotal)}</p>
              </div>
              <div className="textSummary">
                <h5 className="headItems">{i18next.t("updateFormContractExpense:Value Added Tax (VAT)")}</h5>
                <p className="detailItems">{numberWithComma(this.props.taxAmount || "0.00")}</p>
              </div>
              <div className="lineBetweenSummary"/>
              <div className="textSummary">
                <h5 className="headSummary">{i18next.t("updateFormContractExpense:Total Amount (THB)")}</h5>
                <p className="detailSummary">{numberWithComma(this.props.serviceFeeOfInstalment)}</p>
              </div>
           </div>
          </div>

        </div>
      </div>
    )
  }
}
