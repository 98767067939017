import React, { Component } from 'react'
import _ from "lodash"
import { Link } from 'react-router-dom'
import getNameResidential from '../../../libs/getNameResidential'
import { Translation } from 'react-i18next'

export default class selectPrivacy extends Component {
  render() {
    let listSelect = [] 
    listSelect = _.filter(this.props.allContactList.edges, (o) => o.node.id === this.props.contactIdRoom)

    return (<Translation>{t=>
    <React.Fragment>
        <div className="row mt-4">
          <div className="col-6">
            <h4>{t("stockSelectRoom:Choose a room")}</h4>
            <div className="cardTable">
              <div className="bglight">
                <div className="row items">
                  <div className="col-2 text-center textHead">{t("stockSelectRoom:Code")}</div>
                  <div className="col-4 textHead">{t("stockSelectRoom:Room No.")}</div>
                  <div className="col-6 textHead">{t("stockSelectRoom:Name")}</div>
                </div>
              </div>
              <div className="slide">
                {_.map(this.props.allContactList.edges, (item, index) => {

                  let name = getNameResidential(item.node.firstName, item.node.lastName)
                  return (
                    <div type="button" key={index}
                      className={this.props.contactIdRoom === item.node.id  ? "bgSelect" : ""} 
                      onClick={() => this.props.handleSelectContactId(item.node.id)}
                      >
                        <div className="lineTop"/>
                        <div className="row items">
                          <div className="col-2 text-center textDetail">{item.node.refNumber}</div>
                          <div className="col-4 textDetail">{item.node.name}</div>
                          <div className="col-6 textDetail">{name}</div>
                        </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="col-6">
            <h4>{t("stockSelectRoom:Selected item")}</h4>
            <div className="cardTable">
              <div className="bglight">
                <div className="row items">
                  <div className="col-2 text-center textHead">{t("stockSelectRoom:Code")}</div>
                  <div className="col-4 textHead">{t("stockSelectRoom:Room No.")}</div>
                  <div className="col-6 textHead">{t("stockSelectRoom:Name")}</div>
                </div>
              </div>
              <div className="slideSelect">
                <React.Fragment>
                  <div className="lineTop"/>
                  <div className="row items">
                    <div className="col-2 text-center textDetail">{listSelect[0]?.node?.refNumber}</div>
                    <div className="col-4 textDetail">{listSelect[0]?.node?.name}</div>
                    <div className="col-6 textDetail">{getNameResidential(listSelect[0]?.node?.firstName || "", listSelect[0]?.node?.lastName || "")}</div>
                  </div>
                </React.Fragment>
              </div>
              <div className="float-right">
                <span className="clearList" type="button" onClick={() => this.props.handleResetList("contactIdRoom")}>{t("stockSelectRoom:Clear all")}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-4 float-right">
          <div className="col-12">
            <Link to={{
                pathname: this.props.contactIdRoom 
                ? `/stockandsupplies/stockManagement/createUpdateForm/create/${this.props.typeRequisition === "unit" ? "unit" : "tenant"}`
                : '/stockandsupplies/stockManagement/list/all',
                state: {typeRequisition: this.props.typeRequisition ,contactList: listSelect }
              }}>
              <button className="btnNext">{t("Allaction:next")}</button>
            </Link>
          </div>
          <div style={{marginBottom: 50}}/>
        </div>
        
      </React.Fragment>
    
    }</Translation>
      
    )
  }
}
