/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectTypeOfAccount = "NOT_USE_ACCOUNT" | "USE_ACCOUNT" | "%future added value";
export type reportQueryVariables = {||};
export type reportQueryResponse = {|
  +selfProject: ?{|
    +name: string,
    +logo: ?string,
    +typeOfAccount: ?ProjectTypeOfAccount,
  |}
|};
export type reportQuery = {|
  variables: reportQueryVariables,
  response: reportQueryResponse,
|};
*/


/*
query reportQuery {
  selfProject {
    name
    logo
    typeOfAccount
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfAccount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "reportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "reportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bff4ad943f314597185707ef88d739c1",
    "id": null,
    "metadata": {},
    "name": "reportQuery",
    "operationKind": "query",
    "text": "query reportQuery {\n  selfProject {\n    name\n    logo\n    typeOfAccount\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e2143172385b35ba5684999a5fc80ae7';

module.exports = node;
