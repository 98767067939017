import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import environment from "../../../env/environment";
import i18n from "i18next";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import _ from 'lodash';
import ContactTopMenuNavigation from '../../contactTopMenuNavigation';
import { decode } from 'base-64';
import getWSRoot from '../../../libs/getWSRoot';
import API_sing from '../../../api/sing'
import { format } from 'date-fns';
import ModalTag from '../modal/tag';
import upload from '../../../libs/upload'
import { fetchQuery } from 'relay-runtime';
import Lightbox from 'react-images';
import i18next from 'i18next';

const query = graphql`
    query fixRequestChatQuery{
        selfProject{
            openTime
            endTime
        } 
    }
`;
let chatSocket = {};


class fixRequestChat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedId: "",
            account: [],
            mapAccount: [],
            redirect: false,
            homeAll: [],
            message_list: [],
            message: "",

            repairDetail: [],

            typeFix: "1",
            RequestBy: "",
            RequestKey: "",
            isRequestKey: false,
            imgProject: "/images/typeIcon/sing/imgProject.png",
            floor: "",
            pageX: "0px",
            pageY: "0px",
            display: "none",
            file: [],


            keyGen: "",
            workingSheet: [],
            sendStatus: false,

            listTag: [],
            tag_repairs_room: [],
            loadingTag: false,
            loading: true,
            oleWork: [],
            openTime: '',
            endTime: '',

            image_light_box: '',
            open_light_box: false,
        }
        this.sendMessage = this.sendMessage.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onkeyUp = this.onkeyUp.bind(this)
        this.selectTag = this.selectTag.bind(this)
        this.removeTagFromRepairRoom = this.removeTagFromRepairRoom.bind(this)
        this.removeTagFromGlobal = this.removeTagFromGlobal.bind(this)
        this.uploadFile = this.uploadFile.bind(this);
    }

    componentWillMount() {

        this.connectSocket()
        this.readHistoryChat()
        this.getListTag()
    }

    componentWillUnmount() {
        chatSocket.close();
    }

    fetchData = () => {
        fetchQuery(environment, query).then(data => {
            this.setState({ openTime: data.selfProject?.openTime, endTime: data.selfProject?.endTime })
        });
    }

    connectSocket() {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        // let keyGen = this.setState({ keyGen :   })
        chatSocket = new WebSocket(getWSRoot() + '/singha_chat/' + decode(this.props.match.params.id) + '/', window.localStorage.getItem('token'));
        let _this = this;
        chatSocket.onmessage = function (e) {
            let data = JSON.parse(e.data);

            let message_list = [..._this.state.message_list];

            let update = false;
            message_list.forEach((message) => {
                if (data['message_id'] === message['message_id']) {
                    message['updated'] = data['updated'];
                    update = true;
                }
            });

            if (update) {
                // update updated time
                _this.setState({ message_list: [..._this.state.message_list, data] }, () => {
                    _this.scrollToBottom();
                })
            } else {
                // receive from web socket
                _this.setState({ message_list: [..._this.state.message_list, data] }, () => {
                    _this.scrollToBottom();
                })
            }
        };

        chatSocket.onclose = function (event) {
            if (event.code !== 1000 && event.code !== 1006) {
                Swal.fire({
                    title: i18n.t("fix_request:Chat system crashes"),
                    text: i18n.t("fix_request:connection_fail"),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: i18n.t("fix_request:Try again"),
                    cancelButtonText: i18n.t("fix_request:close")
                }).then((result) => {
                    if (result.value) {
                        window.location.reload();
                    }
                })
            }
        };
    }
    scrollToBottom() {
        let objDiv = document.getElementById("chatMessageFixRequest");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    readHistoryChat() {
        let data = {
            repairsRoomId64: this.props.match.params.status
        }
        API_sing.readChat(data);
        API_sing.getChat(data).then((res) => {
            if (res.data.repairsRoom) {
                this.setState({ repairDetail: res.data.repairsRoom[0], loading: false })
                this.scrollToBottom()
                this.getOldWork()
                this.getRepairs_room_worksheet()
                this.fetchData()
            }
        })
    }

    getRepairs_room_worksheet() {
        let repairs_room_id = {
            "id": "",
            "repairs_room_id": decode(this.props.match.params.id)
        }
        let data = {
            index_first: "",
            index_last: "",
            page: "",
            status: "",
            id: decode(this.props.match.params.id),
            person_working: "",
            search: "",
            start_date: "",
            end_date: "",
            repair_type: ""
        }
        API_sing.getRepairs_room_worksheet(repairs_room_id).then((res) => {
            API_sing.getrepairs_room_view(data).then((re) => {
                if (res.data.data && re.data.data) {
                    this.setState({ workingSheet: res.data.data[0], tag_repairs_room: re.data.data[0].tag_repairs_room })
                }
            })
        })
    }
    onkeyUp(e) {

        if (e.key === "Enter") {
            this.setState({ message: e.target.value, sendStatus: true })
            this.sendMessage()
            e.target.value = ""

        }
    }

    onChange(e) {
        this.setState({ message: e.target.value, sendStatus: true })
    }

    sendMessage() {
        this.scrollToBottom();
        // document.getElementsByClassName("boxSendChatMessage").value = ""
        let messageBox = document.getElementsByClassName("boxSendChatMessage")[0];
        messageBox.value = "";
        let stautSend = 1
        if (this.state.sendStatus) {
            let keyGen = new Date().getTime() + Math.floor(Math.random() * 10000000)
            let sendData = {
                "repairsRoomId": decode(this.props.match.params.id),
                "key": keyGen,
                "message": this.state.message,
                "image": "",
                "profile": "",
                "role": "juristic",
                "token": window.localStorage.getItem('token') || null
            }
            let data = {
                repairsRoomId64: this.props.match.params.status
            }
            API_sing.readChat(data);
            API_sing.postChatMessage(sendData).then((res) => {
                if (res.data) {
                    this.setState({ keyGen: keyGen, message: '', sendStatus: false })
                    chatSocket.send(JSON.stringify(sendData));

                    sendData = {}
                }
            })
        }
    }

    getListTag() {
        API_sing.getListTag().then((res) => {
            if (res.data.data) {
                this.setState({ listTag: res.data.data })
            }
        })
    }

    selectTag(TagId, RepairRoomID) {
        let data = {
            "tagGlobalId": TagId,
            "repairsRoomId": RepairRoomID
        }
        API_sing.postTagToRepairRoom(data).then((res) => {
            this.getNewTag()

        })
    }

    getOldWork() {
        let data = {
            index_first: 0,
            index_last: 100,
            page: 0,
            status: "",
            id: "",
            person_working: "",
            search: this.state.repairDetail?.residentialName,
            start_date: "",
            end_date: "",
            repair_type: ""
        }
        API_sing.getrepairs_room_view(data).then((re) => {
            if (re.data.data) {
                this.setState({ oleWork: re.data.data })
            }
        })
    }

    getNewTag() {
        this.setState({ loadingTag: true })
        let repairs_room_id = decode(this.props.match.params.id)

        let data = {
            index_first: "",
            index_last: "",
            page: "",
            status: "",
            id: repairs_room_id,
            person_working: "",
            search: "",
            start_date: "",
            end_date: "",
            repair_type: ""
        }
        API_sing.getrepairs_room_view(data).then((re) => {
            if (re.data.data) {
                this.setState({ tag_repairs_room: re.data.data[0].tag_repairs_room, loadingTag: false })
            }
        })
    }

    removeTagFromRepairRoom(TagRepairsRoomID, RepairRoomID) {
        let data = {
            "id": TagRepairsRoomID,
            "repairs_room_id": RepairRoomID
        }
        API_sing.removeTagFromRepairRoom(data).then((res) => {
            this.getNewTag()
        })
    }

    removeTagFromGlobal(TagId) {
        let data = {
            "id": TagId
        }
        API_sing.removeTagFromGlobal(data).then((res) => {
            this.getListTag()
            this.getNewTag()

        })
    }

    //UPLOADFILE
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/sing/file" + path, file);
            resolve(res);
        });
    };
    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files) {
            if (files?.size > 2097152) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }

            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .png, .jpg, .jpeg ${i18next.t("Allaction:Only")} ${i18next.t("Allaction:and each file should not exceed")} 2 MB (${i18next.t("Allaction:maximum of")} 3 ${i18next.t("Allaction:images")} )`, 'error')
            }

            else {
                let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
                let fileOldList = _.cloneDeep(this.state.file);
                this.callUpload('', new_file).then((data) => {
                    let dese = "sing/file/" + new_file.name
                    // fileOldList.push({ fileId: "", fileName: new_file.name, filePath: dese, status: "", viewPtath: data.location })
                    let keyGen = new Date().getTime() + Math.floor(Math.random() * 10000000)
                    let sendData = {
                        "repairsRoomId": decode(this.props.match.params.id),
                        "key": keyGen,
                        "message": this.state.message,
                        "image": dese,
                        "profile": "",
                        "role": "juristic",
                        "token": window.localStorage.getItem('token') || null
                    }
                    API_sing.postChatMessage(sendData).then((res) => {
                        if (res.data) {
                            this.setState({ keyGen: keyGen, message: '', sendStatus: false, view: data.location })
                            chatSocket.send(JSON.stringify(sendData));

                            sendData = {}
                        }
                    })
                });
            }
        }
    }
    // END UPLOAD

    toggleLightBox = (open_light_box, image = '') => {
        this.setState({ open_light_box: open_light_box, image_light_box: image })
    }



    render() {
        let data = []
        data = this.state.repairDetail
        let workingSheet = this.state.workingSheet

        let tag_repairs_room = this.state.tag_repairs_room
        let olDWork = this.state.oleWork
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <ContactTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid fixRequestFast" style={{ marginTop: '15px' }}>
                                {
                                    this.state.loading == false ?
                                        <div className="row justify-content-between chatFixReqeust">
                                            <div className="col-12" style={{ borderBottom: '2px solid #E9ECEF' }}>
                                                <h3 className="">
                                                    <Link to=
                                                        {this.props.match.params.for === "fortenant" ?
                                                            "/contact/fix-requests/fortenant/review" :
                                                            "/contact/fix-requests/forcommon/review"}


                                                        style={{ lineHeight: 1.7 }}>
                                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                            alt="back" className="back" />
                                                    </Link>
                                                    <div>
                                                        {
                                                            data.tenantContactImage ?
                                                                <img className='imgTenant'
                                                                    src={data.tenantContactImage} />
                                                                :
                                                                <img className='imgTenant'
                                                                    src={process.env.PUBLIC_URL + "/images/icons/accout-icon.png"} />
                                                        }


                                                        <div style={{ marginTop: "10px", paddingLeft: "60px" }}>
                                                            <p className='roomHeaderchat'>{data.otherContact ? data.otherContact : data.tenantContactName}</p>
                                                            <p className='roomHeaderchat'>{data.residentialName}</p>
                                                        </div>
                                                    </div>
                                                </h3>
                                            </div>


                                            <div className='col-8' style={{
                                                backgroundColor: "rgb(244,244,248)",
                                                paddingBottom: '30px'
                                            }}>

                                                <div className='chatMessage' id="chatMessageFixRequest" >
                                                    <div style={{ margin: "0 4vw" }}>
                                                        <div className='boxRequest text-center mx-auto mb-5 pt-5'>

                                                            {
                                                                data.status === "new" || data.status === "appointment" || data.status === 'repaired' || data.status === 'edited' ?
                                                                    <span className={'btnStatusDetailsChatFast'}>
                                                                        <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/" + data.status + ".png"} />
                                                                        &nbsp;
                                                                        {
                                                                            data.status === "new" ? "งานใหม่" :
                                                                                data.status === "appointment" ? "นัดตรวจ" :
                                                                                    data.status === 'repaired' ? "กำลังซ่อม" :
                                                                                        data.status === 'edited' ? "แก้ไขแล้ว" :
                                                                                            data.status === "finish" ? "เสร็จสิ้น" : i18next.t("Allaction:cancel")
                                                                        }
                                                                    </span>
                                                                    :
                                                                    <span className={'btnStatusDetailsChat ' + data.status}>
                                                                        <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/" + data.status + ".png"} />
                                                                        &nbsp;
                                                                        {
                                                                            data.status === "review" ? "รอตรวจสอบ" :
                                                                                data.status === "pending" ? "รอดำเนินการ" :
                                                                                    data.status === "approve" ? "อนุมัติค่าใช้จ่าย" :
                                                                                        data.status === "finish" ? "เสร็จสิ้น" : i18next.t("Allaction:cancel")
                                                                        }
                                                                    </span>
                                                            }

                                                            <br />
                                                            <div style={{ padding: "22px" }} className='row'>
                                                                <div className='col-sm-6 text-left'>
                                                                    <p className='pHeader'>{data.workingType}</p>
                                                                    <p className='pDetail'>{data.material + " , " + data.details + `${data.description ? ", " + data.description : ""}`}</p>
                                                                    <p className='pDescription'>{ }</p>
                                                                    <p style={{ marginTop: '30px' }}>{i18n.t('Allaction:Office hours')}: {this.state.openTime} - {this.state.endTime} น.</p>
                                                                </div>
                                                                <div className='col-sm-6 ' style={{ textAlign: 'right' }}>
                                                                    {
                                                                        data.image_per_repairs_room?.map((n, index) => {
                                                                            let typeFile = n.file_name.split(".") // Retuen "Array"
                                                                            return (
                                                                                <React.Fragment key={"image_per_repairs_room" + index}>
                                                                                    {
                                                                                        (typeFile[1] === "png" || typeFile[1] === "jpg" || typeFile[1] === 'jpeg') && index < 3 ?
                                                                                            <img src={n.file_upload} style={{ width: '20%' }} className='imgRepairRoom cursor' onClick={() => this.toggleLightBox(true, n.file_upload)} /> : ""
                                                                                    }
                                                                                </React.Fragment>

                                                                            )

                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        {
                                                            data.messageFastinspect?.map((n, index) => {
                                                                return (
                                                                    <React.Fragment key={"messageFastinspect" + index}>
                                                                        <div className='inlineChat' style={{
                                                                            float: n.role === "juristic" ? 'right' : 'left',
                                                                            textAlign: n.role === "juristic" ? 'end' : 'start',
                                                                        }}>

                                                                            <div style={{ margin: n.role === "juristic" ? "0px 4vw 0 0px" : "0px 0 0px 4vw" }}>
                                                                             <p className='timeChatSend'>{n.firstName + " " +n.lastName}</p>
                                                                                <p>
                                                                                    {/* <img className='' style={{position: "absolute",marginLeft: "-45px" }}
                                                                                src={process.env.PUBLIC_URL + "/images/icons/accout-icon.png"} /> */}
                                                                                    {
                                                                                        n.image ?
                                                                                            <img
                                                                                                // src={process.env.PUBLIC_URL + "/images/logo/silverman.jpeg"}
                                                                                                src={n.image}
                                                                                                style={{ maxWidth: '300px' }}
                                                                                                onError={this.src = this.state.view}
                                                                                            />
                                                                                            :
                                                                                            n.role === "juristic" ?
                                                                                                <span className='ChatP active'>{n.message}</span>
                                                                                                :
                                                                                                <span className='ChatP'>{n.message}</span>
                                                                                    }

                                                                                </p>

                                                                                <span className='timeChatSend'>{format(n.update, "DD/MM/YYYY - HH:mm น.")}</span>
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            this.state.message_list?.map((n, index) => {
                                                                return (
                                                                    <React.Fragment key={"message_list" + index}>
                                                                        <div className='inlineChat' style={{
                                                                            float: n.role === "juristic" ? 'right' : 'left',
                                                                            textAlign: n.role === "juristic" ? 'end' : 'start',


                                                                        }}>

                                                                            <div style={{ margin: n.role === "juristic" ? "0px 4vw 0 0px" : "0px 0 0px 4vw" }}>
                                                                                <p className='timeChatSend'>{n.firstName + " " +n.lastName}</p>
                                                                                <p>
                                                                                    {/* <img className='' style={{position: "absolute",marginLeft: "-45px" }}
                                                                                src={process.env.PUBLIC_URL + "/images/icons/accout-icon.png"} /> */}
                                                                                    {
                                                                                        n.image ?
                                                                                            <img
                                                                                                // src={process.env.PUBLIC_URL + "/images/logo/silverman.jpeg"}
                                                                                                src={n.image}
                                                                                                style={{ maxWidth: '300px' }}
                                                                                            />
                                                                                            :
                                                                                            n.role === "juristic" ?
                                                                                                <span className='ChatP active'>{n.message}</span>
                                                                                                :
                                                                                                <span className='ChatP'>{n.message}</span>
                                                                                    }

                                                                                </p>

                                                                                <span className='timeChatSend'>{format(n.updated, "DD/MM/YYYY - HH:mm น.")}</span>
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                                <div style={{ clear: 'both' }}></div>

                                                {/* BOXCHAT */}
                                                <div style={{ marginTop: '3%' }}>
                                                    {
                                                        data.status === "finish" || data.status === "void" ?
                                                            <div className='endChat'>จบการบทสนทนา</div>
                                                            :
                                                            data.insure_status !== "have" ?
                                                                <div className='row boxMessageChat'>
                                                                    <div className='col-12 text-center' style={{ display: 'inline-flex' }}>
                                                                        <div style={{ display: 'inline-flex', pointerEvents: this.state.file.length >= 3 ? 'none' : 'all' }}>
                                                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'repairs_room_edit' }) &&
                                                                                <label htmlFor="uploadFile" className="upload-btn-wrapper"
                                                                                >
                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeIcon/chat/uploadFile.svg"} />
                                                                                    <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder='ระบุเบอร์โทร'
                                                                                        accept='application/.pdf,.png,.jpg' onChange={this.uploadFile}
                                                                                    />

                                                                                </label>
                                                                            }
                                                                        </div>
                                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'repairs_room_edit' }) &&
                                                                            <input type={"text"} className='form-control boxSendChatMessage' style={{ borderRadius: "24px", width: '95%', margin: '0 10px' }}
                                                                                onChange={this.onChange} onKeyPress={this.onkeyUp} defaultValue={this.state.message}
                                                                            />
                                                                        }
                                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'repairs_room_edit' }) &&
                                                                            <button style={{ backgroundColor: "transparent", border: 'transparent' }} onClick={this.sendMessage}>
                                                                                <img src={process.env.PUBLIC_URL + "/images/typeIcon/chat/send.svg"} />
                                                                            </button>
                                                                        }
                                                                    </div>

                                                                </div>
                                                                :
                                                                <div className='endChat'>S Homecare</div>
                                                    }

                                                </div>

                                            </div>
                                            <div className='col-4' style={{ height: '82vh', overflowY: 'auto' }}>
                                                {
                                                    workingSheet ?
                                                        <div className='boxWorkCurrent'>
                                                            <p className='pTime'>{workingSheet?.repairs_room?.added ? format(new Date(workingSheet?.repairs_room?.added), "DD/MM/YYYY - HH:mm น.") : format(data.time, "DD/MM/YYYY - HH:mm น.")}</p>
                                                            <div>
                                                                {
                                                                    tag_repairs_room?.map((tag, index) => {

                                                                        return (
                                                                            <React.Fragment key={"tag_repairs_room" + index} >

                                                                                <button className={data.status === "void" ? "tagBtnGray" : 'tagBtnBlue'} style={{ float: 'left' }}>
                                                                                    {tag.tag_global.tag_name}
                                                                                    {
                                                                                        data.status !== "void" &&
                                                                                        <span onClick={() => this.removeTagFromRepairRoom(tag.id, decode(this.props.match.params.id))}>X</span>
                                                                                    }


                                                                                </button>
                                                                            </React.Fragment>

                                                                        )
                                                                    })
                                                                }

                                                                {
                                                                    data.status !== "finish" && data.status !== "void" ?
                                                                        <div className="dropdown" style={{ float: "left" }}>
                                                                            <button className="tagBtnGray dropdown-toggle" data-toggle="dropdown">+{i18n.t("PageList:Add Tag")}</button>
                                                                            <ul className="dropdown-menu">
                                                                                {
                                                                                    this.state.listTag?.map((tagLi, index) => {
                                                                                        return (
                                                                                            <React.Fragment key={"listTag" + index} >
                                                                                                {
                                                                                                    tagLi.id > 3 ?
                                                                                                        <li className='dropdown-item tagLi' onClick={() => this.selectTag(tagLi.id, data.repairsRoomId)}
                                                                                                            style={{ cursor: 'pointer', borderBottom: '#BFBFBF 1px silid' }}>{tagLi.tag_name}
                                                                                                            <span style={{ float: 'right' }} onClick={() => this.removeTagFromGlobal(tagLi.id)}>X</span></li>
                                                                                                        :
                                                                                                        <li key={index} style={{ cursor: 'pointer' }} className='dropdown-item tagLi'
                                                                                                            onClick={() => this.selectTag(tagLi.id, data.repairsRoomId)}>{tagLi.tag_name}</li>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        )

                                                                                    })
                                                                                }


                                                                                <li className='dropdown-item' data-toggle="modal" data-target={".tagModal" + data.repairsRoomId} style={{ color: "#BFBFBF" }}>+ {i18n.t("PageList:Add Tag New")}</li>
                                                                            </ul>
                                                                        </div>
                                                                        :
                                                                        ""
                                                                }

                                                                <ModalTag indexID={data.repairsRoomId} />



                                                            </div>
                                                            <div style={{ clear: 'both' }}></div>
                                                            <br />
                                                            <p className='pWork'>{data.workingType}</p>
                                                            <p className='pCompany'>{data.person_working}</p>
                                                            {
                                                                workingSheet ?
                                                                    <React.Fragment>
                                                                        <div className='row' style={{ padding: " 4px 0" }}>
                                                                            <div className='col-sm-5 pCompany'>{i18n.t("FixRequestModal:Work Type")}</div>
                                                                            <div className='col-sm-7 pCompany Black'>
                                                                                {
                                                                                    workingSheet.working_type === "repair" ? t("FixRequestModal:Repair") :
                                                                                        workingSheet.working_type === "improvement" ? t("FixRequestModal:Improvement") : i18n.t("FixRequestModal:etc.")
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='row' style={{ padding: " 4px 0" }}>
                                                                            <div className='col-sm-5 pCompany'>{i18n.t("FixRequestModal:Technician type")}</div>
                                                                            <div className='col-sm-7 pCompany Black'> {workingSheet.technician === "outside" ? i18n.t("FixRequestModal:Technician outside the project") : i18n.t("FixRequestModal:Technician in the project")} </div>
                                                                        </div>
                                                                        <div className='row' style={{ padding: " 4px 0" }}>
                                                                            <div className='col-sm-5 pCompany'>{i18n.t("FixRequestModal:Responsible staff")}</div>
                                                                            <div className='col-sm-7 pCompany Black'>
                                                                                {
                                                                                    workingSheet.project_staff ?
                                                                                        workingSheet.project_staff.name_title + workingSheet.project_staff.name_staff + " " + workingSheet.project_staff.last_name_staff :
                                                                                        workingSheet.project_staff_other

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='row' style={{ padding: " 4px 0" }}>
                                                                            <div className='col-sm-5 pCompany'>{i18n.t("newBookingSingha_Booking:Contact number")}</div>
                                                                            <div className='col-sm-7 pCompany Black'>  {workingSheet.phone}</div>
                                                                        </div>
                                                                        <div className='row' style={{ padding: " 4px 0" }}>
                                                                            <div className='col-sm-5 pCompany'>{i18n.t("PageList:Date and time appointment")}</div>
                                                                            <div className='col-sm-7 pCompany Black'> {format(workingSheet.appointments, "DD/MM/YYYY | HH:mm น.")}</div>
                                                                        </div>

                                                                    </React.Fragment>


                                                                    :
                                                                    ""


                                                            }

                                                        </div>
                                                        : ""

                                                }

                                                <p className='pWorkAgo'>{i18n.t("chat:previous_work")}</p>
                                                {
                                                    olDWork?.map((oldWorksItem, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    data.repairsRoomId !== oldWorksItem.id ?
                                                                        <div className='boxWorkOld' >
                                                                            <p className='pTime'>{format(oldWorksItem.added, "DD/MM/YYYY - HH:mm น.")}</p>
                                                                            <p className='pWork'>{oldWorksItem.working_type}

                                                                                {
                                                                                    oldWorksItem.insure_status === "have" ?
                                                                                        <button className='haveInsur ml-2 mr-2'>{i18n.t("FixRequestReport:Insured")}</button>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                            </p>
                                                                            <p className='pCompany'>{oldWorksItem.person_working}</p>
                                                                        </div>
                                                                        :
                                                                        ""

                                                                }

                                                            </React.Fragment>

                                                        )
                                                    })
                                                }

                                            </div>
                                        </div> :
                                        "LOADING.."
                                }

                                <Lightbox
                                    images={[{ src: this.state.image_light_box }]}
                                    onClose={() => this.toggleLightBox(false)}
                                    isOpen={this.state.open_light_box}
                                    backdropClosesModal={true}
                                    showImageCount={false}
                                />

                            </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default fixRequestChat;