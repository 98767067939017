import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';

class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <Translation>
                        {
                            t=>
                        
                    <ul>
                        <li>
                            <NavLink exact={true} to="/contact/fix-request/open">
                                <div>
                                    {t("fix_request:new_request")}
                                    {/*<span className="badge navigation">8</span>*/}
                                </div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact/fix-request/approve">
                                {t("fix_request:approve")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact/fix-request/finished">
                                {t("fix_request:finished")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact/fix-request/not-approve">
                                {t("fix_request:cancel")}
                            </NavLink>
                        </li>
                    </ul>
                        }
                    </Translation>
                </div>
            </div>
        )
    }
}

export default Navigation;