/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApproveAssetHistoryApproveStatus = "APPROVE" | "NOT_APPROVE" | "%future added value";
export type AssetSaleStatus = "ACTIVE" | "DETERIORATE" | "DISAPPEAR" | "SOLD" | "%future added value";
export type AssetType = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type assetListQueryVariables = {|
  asset_type?: ?string,
  date?: ?any,
  first?: ?number,
  last?: ?number,
  search?: ?string,
|};
export type assetListQueryResponse = {|
  +allAsset: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +purchaseDate: ?any,
        +docNumber: string,
        +storage: ?string,
        +saleStatus: AssetSaleStatus,
        +seller: ?{|
          +firstName: string,
          +lastName: string,
          +name: string,
        |},
        +sellerOther: ?string,
        +chartOfAccountType: {|
          +chartOfAccountCode: string,
          +name: string,
        |},
        +name: string,
        +costPrice: ?number,
        +carcassPrice: ?number,
        +calDepreciation: boolean,
        +description: ?string,
        +historicalAmount: number,
        +depreciationRatePercent: ?number,
        +type: AssetType,
        +assetDepreciate: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +depreciatePrice: number,
            |}
          |}>
        |},
        +remark: string,
        +internalAssetCode: ?string,
        +approveAsset: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +approveStatus: ApproveAssetHistoryApproveStatus,
              +approver: ?string,
              +approveDate: ?any,
              +approveTime: ?any,
              +approveTerm: number,
              +costPrice: ?number,
              +remark: ?string,
            |}
          |}>
        |},
      |}
    |}>,
  |},
  +fixAssetDepreciate: ?boolean,
|};
export type assetListQuery = {|
  variables: assetListQueryVariables,
  response: assetListQueryResponse,
|};
*/


/*
query assetListQuery(
  $asset_type: String
  $date: Date
  $first: Int
  $last: Int
  $search: String
) {
  allAsset(type: $asset_type, first: $first, last: $last, search: $search) {
    totalCount
    edges {
      node {
        id
        purchaseDate
        docNumber
        storage
        saleStatus
        seller {
          firstName
          lastName
          name
          id
        }
        sellerOther
        chartOfAccountType {
          chartOfAccountCode
          name
          id
        }
        name
        costPrice
        carcassPrice
        calDepreciation
        description
        historicalAmount
        depreciationRatePercent
        type
        assetDepreciate(date_Lte: $date) {
          edges {
            node {
              id
              depreciatePrice
            }
          }
        }
        remark
        internalAssetCode
        approveAsset {
          edges {
            node {
              id
              approveStatus
              approver
              approveDate
              approveTime
              approveTerm
              costPrice
              remark
            }
          }
        }
      }
    }
  }
  fixAssetDepreciate
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "asset_type"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "asset_type"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purchaseDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saleStatus",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sellerOther",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costPrice",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carcassPrice",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calDepreciation",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "historicalAmount",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depreciationRatePercent",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "date_Lte",
      "variableName": "date"
    }
  ],
  "concreteType": "AssetDepreciateNodeConnection",
  "kind": "LinkedField",
  "name": "assetDepreciate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetDepreciateNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetDepreciateNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "depreciatePrice",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalAssetCode",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "ApproveAssetHistoryNodeConnection",
  "kind": "LinkedField",
  "name": "approveAsset",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ApproveAssetHistoryNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ApproveAssetHistoryNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approveStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approver",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approveDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approveTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approveTerm",
              "storageKey": null
            },
            (v13/*: any*/),
            (v21/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fixAssetDepreciate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "assetListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssetNodeConnection",
        "kind": "LinkedField",
        "name": "allAsset",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccountType",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v24/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "assetListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssetNodeConnection",
        "kind": "LinkedField",
        "name": "allAsset",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccountType",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v10/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v24/*: any*/)
    ]
  },
  "params": {
    "cacheID": "72675a527c4707f48fd6446e907f54e6",
    "id": null,
    "metadata": {},
    "name": "assetListQuery",
    "operationKind": "query",
    "text": "query assetListQuery(\n  $asset_type: String\n  $date: Date\n  $first: Int\n  $last: Int\n  $search: String\n) {\n  allAsset(type: $asset_type, first: $first, last: $last, search: $search) {\n    totalCount\n    edges {\n      node {\n        id\n        purchaseDate\n        docNumber\n        storage\n        saleStatus\n        seller {\n          firstName\n          lastName\n          name\n          id\n        }\n        sellerOther\n        chartOfAccountType {\n          chartOfAccountCode\n          name\n          id\n        }\n        name\n        costPrice\n        carcassPrice\n        calDepreciation\n        description\n        historicalAmount\n        depreciationRatePercent\n        type\n        assetDepreciate(date_Lte: $date) {\n          edges {\n            node {\n              id\n              depreciatePrice\n            }\n          }\n        }\n        remark\n        internalAssetCode\n        approveAsset {\n          edges {\n            node {\n              id\n              approveStatus\n              approver\n              approveDate\n              approveTime\n              approveTerm\n              costPrice\n              remark\n            }\n          }\n        }\n      }\n    }\n  }\n  fixAssetDepreciate\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2a522d5230c37bc80062279671e75690';

module.exports = node;
