import './styles/headerLogin.scss';
import React, { useState } from 'react';
import environment from "../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation} from "react-relay";
import i18n from "i18next";

const query = graphql`
    query headerLoginQuery {
        userConfig {
            id languages
        }
    }
`;

const mutation = graphql`
    mutation headerLoginMutation($input: UpdateUserConfigInput!) {
        UpdateUserConfig(input: $input) {
            newUserConfig {
                id languages
            }
        }
    }
`;

const Header = (props) => {
    const [language, setLangauge] = useState(localStorage.getItem("language") === "en" ? false : true)
    const [languages, setLangauges] = useState(localStorage.getItem("language") === "en" ? 'en' : 'th')

    const changeLangauge = (lang) => {
        setLangauges(lang)
        setLangauge(lang === 'en' ? false : true)

        let variables = {
            input: { languages: lang }
        };
        commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response, errors) => {
                props.lang(lang === 'en' ? false : true , lang)
                localStorage.setItem("language", lang)

            },
            onError: error => {
                alert('server error')
            },
            optimisticUpdater: (store) => {
                // store.get(props.userConfig.id).setValue(languages , 'languages');
                i18n.changeLanguage(lang);
            }
        });
    }

    return (
        <div id="headerLogin">
            <div className="logoHeader ">
                <a href="https://silverman.app/" target="_blank">
                    <span >
                        <img src={process.env.PUBLIC_URL + '/images/header/silver-black.png'}
                            alt="silverman" className="logo"
                        />
                    </span>
                </a>
            </div>
            <div className="col" style={{ marginTop: '25px', marginRight: '100px' }}>
                <div className="row justify-content-between">
                    <div className="col-md-6">
                        <h5>{language ? 'โปรแกรมบริหารจัดการทรัพย์สินที่ดีที่สุด สำหรับโครงการ' : 'The best property management system'}</h5>
                    </div>
                    <div className="col">
                        <div className="row">
                            <span className="row mr-4">
                                <img src={process.env.PUBLIC_URL + '/images/header/phone-icon.png'}
                                    alt="phone-icon" className="icons" />
                                <h6><small>{language ? 'โทร ' : 'Tel '} 081-4426888</small></h6>
                            </span>
                            <span onClick={() => changeLangauge(languages === "th" ? 'en' : 'th')} style={{ cursor: 'pointer' }} className="row">
                                <img src={language ? (process.env.PUBLIC_URL + '/images/flags/th.png') : (process.env.PUBLIC_URL + '/images/flags/en.png')}
                                    alt="flag-icon" className="flag" />
                                <h6><small>{language ? 'ไทย' : 'EN'}</small></h6>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
export default Header