import './styles/index.scss';
import React, { Component } from 'react';

class WrapperContent extends Component {
    render() {
        const { children, disabledOverflowX } = this.props;
        return (
            <div id="wrapper-content" style={disabledOverflowX ? {} : { overflowX: 'hidden' }}>
                {children}
            </div>
        );
    }
}

export default WrapperContent;