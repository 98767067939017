import React, {Component} from 'react';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import Wrapper from '../components/wrapper';
import WrapperContent from '../components/wrapper/wrapperContent';
import {fetchQuery} from "relay-runtime";
import environment from "../env/environment";
import queryCheckSiteOnline from "../libs/checkSiteOnline"
import Loading from "../libs/loading"
import AlertWithholdPage from '../libs/alertWithholdPage';
import DownloadFileMain from './downloadFileMain';
// import {Translation} from "react-i18next";
import {Redirect} from 'react-router';

class DownloadFileDashboard extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            checkOnline: true
        }
    }

    componentWillMount(){
        this.setState({loading: true})
        fetchQuery(environment, queryCheckSiteOnline).then(data => {
            this.setState({checkOnline: data.checkOnline, loading: false})
        });
    }
    
    render() {
        if(this.props.match.params.status === undefined){
            return <Redirect to={"all"}/>
        }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    {this.state.loading ? <Loading/>
                        : !this.state.checkOnline ? <AlertWithholdPage/>
                            : <DownloadFileMain center={true} status={ this.props.match.params.status}/>
                    }
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default DownloadFileDashboard;
