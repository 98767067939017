import React, { Component } from 'react';
import Swal from "sweetalert2";
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Navigation from './navigation'
import API from '../../api/silverGraund'
import ReactPaginate from "react-paginate";
import Loading from "../../libs/loading"
import { format } from "date-fns";
import SettingTopMenuNavigation from '../settingTopMenuNavigation';
import ExportReport from '../report/exportReport'
import i18next from 'i18next';
import BackButtonIcon from '../../components/BackBtn/indexBack';
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import ExportSvmGuardReportPDF from './svmGuardReportPDF';
import ExportsvmGuardReportExcel from './svmGuardReportExcel';
import jwtDecode from 'jwt-decode'

import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import { graphql } from "babel-plugin-relay/macro";

let search = ''
let startDate = ''
let endDate = ''

const query = graphql`
    query visitReportQuery{
        selfProject{
            name
            address
            logo
        }
    }
`;
class VisitReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            allRow: 4,
            LimitStRow: 1,
            LimitEnRow: 4,


            flg: "",
            flgTime: "",
            flgService: "",
            status: "",
            textSearch: "",
            num_record: 0,
            loading: true,
            first: "",
            last: 10,
            perPage: 10,
            page: 0,
            pages: 0,
            startDate: "",
            endDate: "",
            dataExport: []
        }

        this.SearchChange = this.SearchChange.bind(this)
        this.onSreach = this.onSreach.bind(this)
        this.checkLoadingExcel = this.checkLoadingExcel.bind(this)
    }
    componentWillMount() {
        this.getDataVisitor();
        this.getData()
    }

    getData = () => {
        fetchQuery(environment, query, { search: this.state.search }).then(response => {
            if (response?.selfProject) {
                let nameToken = jwtDecode(localStorage.getItem("token"))
                this.setState({
                    project_name: response?.selfProject?.name,
                    namePrint: nameToken.first_name + " " + nameToken.last_name
                })
            }
        })
    }

    getDataVisitor() {
        this.setState({ 
            checkFetchState: false
          })
        API.getVisitorVMSReport().then((res) => {
            if (res.data.data) {
                this.setState({ data: res.data.data, allRow: res.data.allRow, dataExport: res.data.data })
                const { flgService, textSearch, first, last, startDate, endDate } = this.state;
                this.setState({
                    num_record: res.data.data.allRow,
                    pages: Math.ceil(res.data.data.length / last)
                });
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error')).finally((
            this.setState({ loading: false })
        ))
    }


    onError(imgData) {
        if (imgData !== "" && imgData !== null) {
            this.setState({
                imgLoad: imgData
            });
        } else {
            this.setState({
                imgLoad: "/images/icons/select-photo.png"
                // imgLoad: "/images/carUploadRgis/20210101121155.png"
            });
        }

    }

    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({ page });
    };


    onError(imgData, action) {
        if (action) {
            this.setState({
                imgLoad: "/images/icons/select-photo.png"
            });
        } else {
            this.setState({ imgLoad: imgData })
        }
    }

    onSreach() {

        API.getSearchReportVisitmange(search, startDate, endDate).then((res) => {
            if (res.data.data) {
                let datas = []
                datas = res.data.data
                this.setState({ data: datas })
            }
        }, () => Swal.fire(i18next.t("manageVisitor:Unable to search"), i18next.t("manageVisitor:Unable to search at this time"), 'error'))
    }

    SearchChange(e) {

        if (e.target.name === "search") {
            search = e.target.value
        } else if (e.target.name.includes("startDate")) {
            startDate = format(e.target.value, "YYYY-MM-DD")
        } else if (e.target.name === "endDate") {
            endDate = format(e.target.value, "YYYY-MM-DD")
        }

        if (e.key === "Enter") {
            API.getSearchReportVisitmange(search, startDate, endDate).then((res) => {
                if (res.data.data) {
                    let datas = []
                    datas = res.data.data
                    this.setState({ data: datas })
                }
            }, () => Swal.fire(i18next.t("manageVisitor:Unable to search"), i18next.t("manageVisitor:Unable to search at this time"), 'error'))
        }
    }

    funCSubstring(value) {
        let idCard = value !== "" && value !== null ? value.substring(0, 1) + "-XXXX-XXXXX-" + value.substring(10, 12) + "-" + value.substring(12) : "-"
        return idCard
    }

    checkLoadingExcel(status) {
        this.setState({ checkFetchState: status });
    }


    render() {
        let sta = this.props.match.params.tabName
        const { data, num_record, loading, page, pages, last, startDate, endDate } =
            this.state;
        let items = []
        let lists = []
        let pageA = 0
        if (sta === 'notpay') {
            lists = data.filter((n) => parseInt(n.totalPacking || 0) < 1 && n.estampStatus == false)
            items = lists.slice(page * last, (page + 1) * last);
            pageA = Math.ceil(lists.length / last)
        } else if (sta === 'packing') {
            lists = data.filter((n) => parseInt(n.totalPacking) > 0)
            items = lists.slice(page * last, (page + 1) * last);
            pageA = Math.ceil(lists.length / last)
        } else if (sta === 'all') {
            lists = data
            items = data.slice(page * last, (page + 1) * last);
            pageA = Math.ceil(data.length / last)
        }

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <SettingTopMenuNavigation mini={true} />
                    <div className="container-fluid box" id="reportGraund">
                        <Translation>
                            {t=>
                                <BackButtonIcon
                                    LinkBack={"/silvermanguard"}
                                    LinkText={t('graud:reportPacking')}
                                />
                            }
                        </Translation>
                        <Navigation />
                        <div className='col text-right pb-6'>
                                <div style={{display:'inline-flex'}}>
                                <div className={`${this.state.checkFetchState ? "dropdown" : ''} printReport`}>
                                            {/* {!this.state.checkFetchState ? */}
                                                {/* <React.Fragment>
                                                <button type="button" className="btnPrint">
                                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                                    <Translation>{t => t("meter:Preparing information")}</Translation>
                                                </button>
                                                </React.Fragment>
                                                :                   */}
                                                <React.Fragment>
                                                <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                                                    <Translation>{t => t("PageList:Print")}</Translation>
                                                </button>
                                                </React.Fragment>
                                            {/* } */}
                                            <div className={`dropdown-menu`}>
                                                <ExportReport state={this.state} />                                                
                                            </div>
                                    </div>
                                </div>
                        </div>
                        <Translation>
                            {t =>
                                this.state.loading == false &&
                                <React.Fragment>
                                    <div className='row'>
                                        <div className='col-12 text-right' style={{ display: 'inline' }}>
                                            <div className='floatRight'>
                                                <input type="text" name='search' className='form-control searchBox' placeholder='ค้นหา'
                                                    onChange={this.SearchChange} onKeyUp={this.SearchChange} />
                                                <span className='spanINBox' onClick={this.onSreach}><img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} /></span>
                                            </div>
                                            <div className='floatRight'>
                                                <label className='floatLeft labelInline'>{i18next.t("AllFilter:Date")}:</label>
                                                <input type="date" name='startDate' className='floatLeft form-control searchDate' placeholder='01/07/2021' onChange={this.SearchChange} />

                                                <label className='floatLeft labelInline'>{i18next.t("AllFilter:to")}</label>
                                                <input type="date" name='endDate' className='floatLeft form-control searchDate' placeholder='01/07/2021' onChange={this.SearchChange} />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="table-responsive fade-up card" style={{ marginTop: '24px' }}>
                                        <table className="table table-hover">
                                            <thead className="thead-light">
                                                <tr className='text-center'>
                                                    <th>{i18next.t("vmsDashboard:Document No.")}</th>
                                                    <th>{i18next.t("vmsDashboard:Start Date")}</th>
                                                    <th>{i18next.t("vmsDashboard:End Date")}</th>
                                                    <th>{i18next.t("vmsDashboard:Visitor Name")}</th>
                                                    <th>{i18next.t("vmsDashboard:Citizen ID")}</th>
                                                    <th>{i18next.t("vmsDashboard:Visitors Type")}</th>
                                                    <th>{i18next.t("vmsDashboard:Image")}</th>
                                                    <th>{i18next.t("vmsDashboard:RoleType")}</th>
                                                    <th>{i18next.t("vmsDashboard:Tool")}</th>
                                                    <th>{i18next.t("vmsDashboard:Create By")}</th>
                                                    <th>{i18next.t("vmsDashboard:Status")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    items.map((n) => {
                                                        return (
                                                            <tr className='text-center' key={n.carTransactionId}>
                                                                <td><Link to={"/silvermanguard/report/detail/" + n.reportCode}>{n.reportCode !== "" ? n.reportCode : "-"}</Link></td>
                                                                <td>{n.dateUsingSt}-{n.timeUsingSt}</td>
                                                                <td>{n.dateCheckOut}-{n.timeCheckOut}</td>
                                                                <td>{n.fullnameVisit ? n.fullnameVisit : '-'}</td>
                                                                <td>{this.funCSubstring(n.idCard)}</td>
                                                                <td>{
                                                                    n.typePerson === "person" ? i18next.t("visitorManage:Guest") :
                                                                        n.typePerson === "contact" ? i18next.t("visitorManage:Contact with sale office ") :
                                                                            n.typePerson === "appointment" ? i18next.t("visitorManage:Make an appointment in advance") : i18next.t("vmsDashboard:Resident / Tenant")}
                                                                </td>
                                                                <td>

                                                                    {/* {
                                                            n.img !== "" &&
                                                            <div className="tooltipsSpan">
                                                                <img src={process.env.PUBLIC_URL + this.state.imgLoad} style={{ width: '50px' }} />
                                                                <img src={process.env.PUBLIC_URL + this.state.imgLoad}
                                                                    className="back tooltiptext"
                                                                    // onError={() => this.onError(n.img)}
                                                                     />
                                                            </div>

                                                            ||

                                                            n.img === "" &&
                                                            <img src={process.env.PUBLIC_URL + "/images/icons/select-photo.png"} style={{ width: '50px' }} />

                                                        } */}
                                                                </td>
                                                                <td>{n.typeRole == "temp" ? i18next.t("silvermanguard:temp") : i18next.t("silvermanguard:perm")}</td>
                                                                <td>{n.typeRole == "perm" ? 'LPR' : 'QR CODE'}</td>
                                                                <td>{n.ceateBy ? n.ceateBy : '-'}</td>
                                                                <td>
                                                                    {
                                                                        parseInt(n.totalPacking || 0) < 1 && n.estampStatus == false ?
                                                                            <label className='badge badge-danger'>{i18next.t("graudreport:notpay")}</label> :
                                                                            <label className='badge badge-success'>{i18next.t("graudreport:complete")}</label>
                                                                    }

                                                                </td>
                                                            </tr>
                                                        )

                                                    })
                                                }

                                                {
                                                    items.length === 0 &&
                                                    <tr className='text-center'>
                                                        <td colSpan="11">{i18next.t("silvermanguard:notFound")}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className='row'>
                                        <div className='col-6'>
                                            <select className='page' onChange={(e) => this.setState({ last: parseInt(e.target.value) })}>
                                                <option value="10">{i18next.t("silvermanguard:show")} 10 {i18next.t("silvermanguard:items")}</option>
                                                <option value="20">{i18next.t("silvermanguard:show")} 20 {i18next.t("silvermanguard:items")}</option>
                                                <option value="50">{i18next.t("silvermanguard:show")} 50 {i18next.t("silvermanguard:items")}</option>
                                                <option value="100">{i18next.t("silvermanguard:show")} 100 {i18next.t("silvermanguard:items")}</option>
                                            </select>
                                        </div>
                                        <div className='col-6 text-right'>
                                            <div className='pullright' style={{ marginTop: '22px' }}>{i18next.t("silvermanguard:listAll")} {lists.length} &nbsp;

                                                {pages > 1 && (
                                                    <ReactPaginate style={{ marginTop: '-7px' }}
                                                        previousLabel={"previous"}
                                                        nextLabel={"next"}
                                                        breakLabel={
                                                            <span className="btn page-link">...</span>
                                                        }
                                                        breakClassName={"break-me"}
                                                        pageClassName="page-item"
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={4}
                                                        previousClassName="sr-only"
                                                        containerClassName={"pagination float-right"}
                                                        subContainerClassName={"pagination-page"}
                                                        nextLinkClassName="sr-only"
                                                        pageLinkClassName="page-link"
                                                        activeClassName={"active"}
                                                        pageCount={pageA}
                                                        onPageChange={this.handlePageClick}
                                                    />
                                                )}

                                            </div>

                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </Translation>
                        {
                            this.state.loading == true && <Loading />
                        }

                    </div>


                </WrapperContent>
            </Wrapper>
        )
    }
}

export default VisitReport;
