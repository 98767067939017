import React, { useState } from 'react';

const ModalTagInList = (props) => {
    const [state , setState] = useState({
        tagName : "",
        chkBox: false
    });

    const onSubmit = (e) => {
        
    }
    const onChange = (e) => {
        
    }

    return (
        <React.Fragment>
            {
                <div className={"modal fade bd-example-modal-lg tagModals tagModal" + props.indexID} tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog  " role="document" style={{ marginTop: "15%", width: "390px" }}>
                        <div className="modal-content">
                            <form onSubmit={onSubmit}>
                                <div className="modal-header" style={{ borderBottom: '0', padding: "15px 30px" }}>
                                    <h2 className="modal-title text-center" style={{ fontSize: "24px" }}>สร้าง Tag ใหม่</h2>
                                </div>
                                <div className="modal-body" style={{ padding: "10px 30px", marginTop: "-10px" }}>
                                    <input type={"text"} defaultValue={state.tagName} name='tagName' className='form-control'
                                        onKeyPress={onChange} onChange={onChange} placeholder='ระบุชื่อ Tag' />
                                    {
                                        state.chkBox == true ?
                                            <span className='text-red'>กรุณาระบุชื่อ Tag</span>
                                            : ''
                                    }

                                </div>
                                <div className="modal-footer" style={{ borderTop: '0', justifyContent: 'center' }}>
                                    <button type="button" className="btn btn-primary-outlie" data-dismiss="modal" aria-label="Close" style={{ width: '160px' }}>ยกเลิก</button>
                                    <button type="submit" className="btn btn-primary" style={{ width: '160px' }}>สร้าง Tag</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            }


        </React.Fragment>
    )

}

export default ModalTagInList