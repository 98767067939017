import React from 'react'
import ManageRequestTopMenuNavigation from './manageRequestTopMenuNavigation'
import _ from "lodash"
import { Link } from 'react-router-dom';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import ComponentPagination from '../../libs/componentPagination';
import Pagination from '../../libs/newPagination';
import format from 'date-fns/format';
import Loading from '../../libs/loading';
import DatePickerNoti from '../../accounting/finance/depositInterest/datePickerNoti';
import numberWithCommas from '../../libs/numberWithComma';
import "./style/manageRequest.scss"
import { Translation } from 'react-i18next';
import i18n from 'i18next';
import i18next from 'i18next';
const query = graphql`
  query manageRequestTableQuery($status: String, $first: Int, $last: Int, $search: String, $startDate: Date, $endDate: Date){
    allProcurementForm(status: $status, first: $first, last: $last, search: $search, startDate: $startDate, endDate: $endDate){
      edges {
        node {
          id
          docNumber
          creditor{
              id
              name
          }
          buyDate
          type
          status
          total
          comparativeCompany
          lowestOffer
          highestOffer  
          contractor
          urgent
          voidRemark
          editRemark
          approveDate
          voidDate
          voider
          approver
          procurementList {
            edges {
              node {
                productAndService {
                  productCode
                  name
                }
                chartOfAccount{
                  name 
                  nameEn 
                  chartOfAccountCode 
                }
                description
              }
            }
          }
        }
      }
      totalCount
    }
  }
`

class manageRequestTable extends ComponentPagination {

  constructor(props) {
    super(props)

    this.state = {
      startDate: null,
      endDate: null,
      textSearch: "",
      manageRequestList: [],
      loading: false
    }
  }

  componentWillMount() {
    this.goFirst()
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.status !== this.props.status || prevState.first !== this.state.first || prevState.last !== this.state.last) {
      this.getData()
    }
  }

  getData = () => {

    let vilable = {
      status: this.props.status !== "all" ? this.props.status : "",
      first: this.state.first,
      last: this.state.last,
      search: this.state.textSearch,
    }

    if (this.state.startDate) {
      vilable.startDate = format(this.state.startDate, "YYYY-MM-DD")
    }
    if (this.state.endDate) {
      vilable.endDate = format(this.state.endDate, "YYYY-MM-DD")
    }

    fetchQuery(
      environment,
      query,
      vilable
    ).then((response) => {
      this.setState({ manageRequestList: response.allProcurementForm })
    })
  }

  getStatus = (text) => {

    let valueResult = ""
    let colorValue = ""

    switch (text) {
      case "draft":
        valueResult = "Draft"
        colorValue = "statusGrey"
        break;
      case "active":
        valueResult = "Present"
        colorValue = "statusYellow"
        break;
      case "approve":
        valueResult = "Approve"
        colorValue = "statusGreen"
        break;
      case "edit":
        valueResult = "Wait Edit"
        colorValue = "statusYellow"
        break;
      case "void":
        valueResult = "Cancled"
        colorValue = "statusRed"
        break;
    }

    return (
      <Translation>
        {t =>
          <div className={`${colorValue}` + " ml-3"}>
            <span>{i18next.t("PageListPRPO:"+valueResult)}</span>
          </div>
        }
      </Translation>
    )
  }

  checkPurchaseRequisitionType = (type) => {

    let valueResult = ""

    switch (type) {
      case "PRODUCT":
        valueResult = "สินค้า"
        break;
      case "SERVICE":
        valueResult = "จ้างบริการ / ค่าใช้จ่าย (งานทั่วไป)"
        break;
      case "SERVICE_CONTACT":
        valueResult = "จ้างบริการ / ค่าใช้จ่าย (สัญญาจ้าง)"
        break;
      case "ASSET":
        valueResult = "สินทรัพย์"
        break;
    }
    return valueResult
  }

  handleSearch = () => {
    this.getData()
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.getData()
    }
  }

  render() {
    return (
      <Translation>
        {(t) =>
          <div id="manageRequestTable">
            <ManageRequestTopMenuNavigation />

            <div className="row my-5">
              <div className="col d-flex justify-content-end">
                <div className="d-flex align-items-center">
                  <h5 className="m-0">{t("AllFilter:Date")}:</h5>
                  <div
                    className="calendars-wrapper mx-4"
                    style={{ width: 160 }}
                  >
                    <DatePickerNoti
                      selected={this.state.startDate}
                      name='startDate'
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h5 className="m-0">{t("AllFilter:to")}:</h5>
                  <div
                    className="calendars-wrapper mx-4"
                    style={{ width: 160 }}
                  >
                    <DatePickerNoti
                      selected={this.state.endDate}
                      name='endDate'
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="calendars-wrapper">
                  <form>
                    <input
                      type="text"
                      placeholder={t("AllFilter:Search")}
                      onKeyDown={this.handleKeyDown}
                      value={this.state.textSearch}
                      onChange={(event) => this.setState({ textSearch: event.target.value })}
                      style={{ fontFamily: "light", height: "48px", width: "300px" }}
                      className="calendars-wrapper colorborder form-control float-left"
                    />
                  </form>
                  <div
                    className="calendars float-right"
                    style={{ paddingRight: 25 }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/search-outline.png"
                      }
                      alt="calendars"
                      style={{ width: "17.81px", height: "17.81px" }}
                      onClick={this.handleSearch}
                    />
                  </div>
                </div>
              </div>
            </div>

            {this.state.loading
              ? <Loading /> :
              <React.Fragment>
                <div className="row">
                  <div className="col">
                    <div className="fade-up">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th />
                              <th>{t("PageListPRPO:PR No")}</th>
                              <th>{t("PageListPRPO:PR Date")}</th>
                              <th>{t("PageListPRPO:Company Present")}</th>
                              {this.props.status !== "all" &&
                                <React.Fragment>
                                  <th>{t("PageDetailProjectInfo:Lists")}</th>
                                  <th>{t("creditNote:Description")}</th>
                                </React.Fragment>
                              }
                              {(this.props.status === "all" || this.props.status === "draft" || this.props.status === "active" || this.props.status === "approve") &&
                                <th className="text-center">{t("PageListPRPO:Expenses")}</th>
                              }
                              {this.props.status !== "approve" &&
                                <React.Fragment>
                                  <th className="text-center">{t("PageListPRPO:Expenses Min")}</th>
                                  <th className="text-center">{t("PageListPRPO:Expenses Max")}</th>
                                  {this.props.status !== "void" && <th>{t("PageListPRPO:Amount Presents")}</th>}
                                </React.Fragment>
                              }
                              <th>{t("PageListPRPO:Type")}</th>
                              {this.props.status === "approve" &&
                                <React.Fragment>
                                  <th>{t("PageListPRPO:Approve Date")}</th>
                                  <th>{t("PageListPRPO:Approve By")}</th>
                                </React.Fragment>
                              }
                              {this.props.status === "edit" &&
                                <th>{t("PageListPRPO:Reason to Edit")}</th>
                              }
                              {this.props.status === "void" &&
                                <React.Fragment>
                                  <th>{t("PageListPRPO:Cancled date")}</th>
                                  <th>{t("PageListPRPO:Cancled By")}</th>
                                </React.Fragment>
                              }
                              {this.props.status === "all" &&
                                <th>{t("PageListPRPO:Status")}</th>
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(this.state.manageRequestList.edges, (data, index) => {
                              return (
                                <tr key={index} style={{ fontWeight: 300 }}>
                                  {data.node.urgent
                                    ? <td>
                                      <div className="gradingRed">
                                        <span>{t("PageListPRPO:News")}</span>
                                      </div>
                                    </td>
                                    : <td />
                                  }
                                  <td width={140} className="textPrimary">
                                    <Link
                                      to={{
                                        pathname: `/purchasing/manageRequest/updateFormManageRequest/${data.node.type !== "SERVICE_CONTACT" ? _.lowerCase(data.node.type) : "service_contact"}/${_.lowerCase(data.node.status)}/${data.node.id}/${data.node.docNumber}`,
                                      }}
                                    >
                                      {data.node.docNumber}
                                    </Link>
                                  </td>
                                  <td>{format(data.node.buyDate, "DD/MM/YYYY")}</td>
                                  <td>{data.node.creditor.name}</td>
                                  {this.props.status !== "all" &&
                                    <React.Fragment>
                                      <td width="220">
                                        {_.map(data.node.procurementList.edges, (o, indexProductAndService) => {
                                          return (
                                            <React.Fragment key={indexProductAndService}>
                                              <span>{
                                                o.node.productAndService
                                                  ? o.node.productAndService.productCode +" "+ o.node.productAndService.name
                                                  : o.node.chartOfAccount.name
                                              }
                                              </span><br />
                                            </React.Fragment>
                                          )
                                        })}
                                      </td>
                                      <td width="220">
                                        {_.map(data.node.procurementList.edges, (o, indexDescription) => {
                                          return (
                                            <React.Fragment key={indexDescription}>
                                              <span>{o.node.description}</span><br />
                                            </React.Fragment>
                                          )
                                        })}
                                      </td>
                                    </React.Fragment>
                                  }
                                  {(this.props.status === "all" || this.props.status === "draft" || this.props.status === "active" || this.props.status === "approve") &&
                                    <td className="text-center">{numberWithCommas(data.node.total)}</td>
                                  }
                                  {this.props.status !== "approve" &&
                                    <React.Fragment>
                                      <td className="text-center">{numberWithCommas(data.node.lowestOffer)}</td>
                                      <td className="text-center">{numberWithCommas(data.node.highestOffer)}</td>
                                      {this.props.status !== "void" && <td className="text-center">{data.node.comparativeCompany}</td>}
                                    </React.Fragment>
                                  }
                                  <td>{this.checkPurchaseRequisitionType(data.node.type)}</td>
                                  {this.props.status === "approve" &&
                                    <React.Fragment>
                                      <td>{format(data.node.approveDate, "DD/MM/YYYY")}</td>
                                      <td>{data.node.approver}</td>
                                    </React.Fragment>
                                  }
                                  {this.props.status === "edit" &&
                                    <td>{data.node.editRemark}</td>
                                  }
                                  {this.props.status === "void" &&
                                    <React.Fragment>
                                      <td>{format(data.node.voidDate, "DD/MM/YYYY")}</td>
                                      <td>{data.node.voider}</td>
                                    </React.Fragment>
                                  }
                                  {this.props.status === "all" &&
                                    <td width={100}>
                                      {this.getStatus(_.lowerCase(data.node.status))}
                                    </td>
                                  }
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <Pagination
                    changePage={this.changePage}
                    first={this.state.first}
                    last={this.state.last}
                    totalCount={this.state.manageRequestList.totalCount}
                  />
                </div>

              </React.Fragment>
            }
          </div>
        }
      </Translation>

    )
  }
}

const ButtonDate = React.forwardRef(({ value, onClick, date }, ref) => (
  <button
    type="button"
    className="button-date"
    style={{ textAlignLast: "start" }}
    onClick={onClick}
    ref={ref}
  >
    {value}
    {!date && (
      <div className="calendars">
        <span
          style={{
            color: "#0382FA",
            fontFamily: "medium"
          }}
        >
          {i18n.t("newBookingSingha_Booking:Choose time")}
        </span>
      </div>
    )}
    {date && (
      <div className="calendars float-right">
        <img
          className="icon"
          src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"}
          alt="calendars"
        />
      </div>
    )}
  </button>
));

export default manageRequestTable;
