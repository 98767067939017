
import React, { Component } from 'react';
import format from 'date-fns/format';
import _ from 'lodash'
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from '../../../../env/environment';
import jwtDecode from 'jwt-decode';
import getNameResidential from "../../../../libs/getNameResidential";
import './invoiceReportPDF.scss'
import i18next from 'i18next';

const query = graphql`
  query invoiceReportPDFTableQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

class InvoiceReportPDFTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: [],
            status: "",
            printBy: "",
            startDate: "",
            endDate: "",
            props:"",
            productAndService: "",
            contactName: "",
        }
    }
    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            if (res.selfProject) {
                let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
                this.setState({
                    data: this.props.state,
                    status: this.props.status,
                    projectName: res.selfProject.name,
                    printBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
                    startDate: this.props.startDate,
                    endDate: this.props.endDate,
                    props: this.props.props,
                    productAndService: this.props.productAndService,
                    contactName: this.props.contactName
                })
            }
        })
    }
    getStatus(invoice) {
        let text = '';
        switch (invoice) {
            case 'DRAFT':
                text = 'แบบร่าง';
                break;
            case 'ACTIVE':
                text = 'รอรับชำระ';
                break;
            case 'OVERDUE':
                text = 'เกินกำหนดชำระ';
                break;
            case 'PAID':
                text = 'รับชำระแล้ว';
                break;
            case 'PARTIAL_PAYMENT':
                text = 'รับชำระบางส่วน';
                break;
            case 'WAITING_FIX':
                text = 'รายการที่รอการแก้ไข';
                break;
            default:
                text = 'รายการที่ยกเลิก';
        }
        return text
    }
    removeTrailingZeros(description){
        if(description.includes("ค่าน้ำประปา")){
            description = description.replace(/ *\[[^)]*\] */g, " ")
            // description = description.replace(/ *\[[^)]*\] */g, " ").replace(/.50 /gi, ".5 ")
            // description = description.replace(/.00 /gi, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }

    numberWithComma(num) {
        let isNegative = false;
        // if (num === 0) {
        //     return formattedNum = "-";
        // }
        // Check if the number is negative
        if (num < 0) {
          isNegative = true;
          num = Math.abs(num); // Convert negative number to positive
        }
        // Add .00 to the number
        num = num.toFixed(2);
        // Format the number with commas
        let formattedNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (num === 0) {
            return formattedNum = "-";
        }
        // Add a minus sign for negative numbers
        if (isNegative) {
          formattedNum = '(' + formattedNum + ')';
        }
        return formattedNum;
      }
    render() {
        let total = 0.0;
        let total_partial_payment = 0;
        let total_amount_partial_payment = 0;
        let summary = this.props.props?.invoiceViewer?.summaryOnlyReportInvoice !== null  ? JSON.parse(this.props.props.invoiceViewer?.summaryOnlyReportInvoice[0]) : null
        let dataALL = [];
        let dataSumRow = [];
        let countRow = 0;
        let statusHeader = '';
        switch(this.state.status) {
            case 'active':
                statusHeader = 'รอรับชำระ'
                break;
            case 'overdue':
                statusHeader = 'เกินกำหนดชำระ'
                break;
            case 'paid':
                statusHeader = 'รับชำระแล้ว'
                break;
            case 'partial_payment':
                statusHeader = 'รับชำระบางส่วน'
                break;
            case 'void':
                statusHeader = 'ยกเลิก'
                break;
            default:
                statusHeader = 'ทั้งหมด';
        }
        const rowPerPage = 18   ;
        // const lastRowIndex = rowPerPage - 1;
                {this.props.props.invoiceViewer.allInvoice.edges.map((invoice, index) => {
                    let order_id = 0;
                    let accumulate = 0;
                    let invoiceTransactionSort = _.orderBy(invoice.node.transaction.edges, ['node.total'], [this.props.state.typeSort || "asc"]);
                    let old_order = 0;
                    let multi_invoice = false;
                    let partial_payment = 0; 
                    let amount_partial_payment = 0;
                    invoiceTransactionSort.map((transaction, t_index) => {     
                            let number_of_transaction = invoiceTransactionSort.length;
                            let ref_doc_number_list = [];
                            partial_payment = transaction.node.paid
                            amount_partial_payment = transaction.node.total - partial_payment;

                            total_partial_payment += partial_payment;
                            total_amount_partial_payment += amount_partial_payment;
                              //คำนวน transaction แต่ละยอด
                              if(this.props.productAndServiceID &&
                                this.props.productAndServiceID !== transaction.node.productAndService.id){
                                    return true;
                                }
                            if (invoice.node.status !== "VOID"){
                                accumulate = accumulate + transaction.node.total;
                            }
                            let row_number = (t_index === 0 && order_id + index + 1);
                            if (this.props.productAndServiceID &&
                                    this.props.productAndServiceID === transaction.node.productAndService.id) {
                                    row_number = order_id  + index + 1;
                                    if(old_order === row_number){
                                        multi_invoice = true; //ใน invoice มีหลายรายการ ให้แสดงเลขลำดับเฉพาะค่าบนสุดเท่านั้น true = ไม่ใช่ลำดับแรก
                                    }else{
                                        multi_invoice = false;
                                        old_order = row_number;
                                    }
                            }
                            // // คำนวนยอดรวมทั้งหมด
                            // if (this.props.productAndServiceID) {
                            //     total += accumulate;
                            // } 
                            // else {
                            //     if(t_index === number_of_transaction - 1) total += accumulate;                                
                            // }

                             // คำนวนยอดรวมทั้งหมด
                             if (t_index === number_of_transaction - 1) {
                                total += accumulate;
                            }

                            // แสดง Ref Number
                            if (transaction.node.receiptDepositTransactionUsed.edges.length !== 0) {
                                transaction.node.receiptDepositTransactionUsed.edges.forEach((receive_deposit) => {
                                    if (receive_deposit.node.receiptDepositTransaction.couple !== "NO" && receive_deposit.node.coupleUse !== "NO")
                                        ref_doc_number_list.push({
                                            type: 'receive_deposit',
                                            id: receive_deposit.node.receiptDeposit.id,
                                            doc_number: receive_deposit.node.receiptDeposit.docNumber
                                        });
                                })
                            }
                            if (transaction.node.creditNoteTransaction.edges.length !== 0) {
                                transaction.node.creditNoteTransaction.edges.forEach((credit_note) => {
                                    ref_doc_number_list.push({
                                        type: 'credit_note',
                                        id: credit_note.node.id,
                                        doc_number: credit_note.node.docNumber
                                    });
                                })
                            }
                            if (transaction.node.creditNoteTransactionRecord.edges.length !== 0) {
                                transaction.node.creditNoteTransactionRecord.edges.forEach(
                                  (credit_note_transaction) => {
                                    ref_doc_number_list.push({
                                      type: "credit_note",
                                      id: credit_note_transaction.node.creditNote.id,
                                      doc_number: credit_note_transaction.node.creditNote.docNumber,
                                    });
                                  }
                                );
                            }
                            if (transaction.node.receiveTransaction.edges.length !== 0) {
                                transaction.node.receiveTransaction.edges.forEach((receive) => {
                                    if (receive.node.receive.status !== 'VOID') {
                                        ref_doc_number_list.push({
                                            type: 'receive',
                                            id: receive.node.receive.id,
                                            doc_number: receive.node.receive.docNumber
                                        });
                                    }
                                })
                            }          
                            if (invoice.node.status !== "VOID") {
                                let transactionRow = (
                                <tr key={transaction.node.id}>
                                <td className="text-center">{!multi_invoice && row_number}</td>
                                <td className="text-center">{invoice.node.docNumber}</td>
                                <td className="text-center">{format(invoice.node.issuedDate, 'DD/MM/YYYY')}</td>
                                <td className="text-center">{format(invoice.node.dueDate, 'DD/MM/YYYY')}</td>
                                <td className="text-center">
                                    {invoice.node.contact.residential ? invoice.node.name : "-"}
                                </td>
                                <td>
                                    {invoice.node.contact.typeOfContact === "RESIDENTIAL" &&
                                        getNameResidential(invoice.node.firstName, invoice.node.lastName)
                                    }

                                    {invoice.node.contact.typeOfContact === "SUPPLIER" &&
                                        invoice.node.name
                                    }
                                    {" "}
                                    {
                                        (invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER") &&
                                        <label>
                                            ({invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${invoice.node.payGroup}`) : ""})
                                        </label>
                                    }    
                                </td>
                                <td className="text-center">
                                    {(transaction.node.productAndService.productCode)}
                                </td>
                                <td>{
                                    this.removeTrailingZeros(transaction.node.description)
                                    }</td>
                                <td className="text-center">{this.getStatus(invoice.node.status)}</td>
                                <td className="text-right">{this.numberWithComma(transaction.node.total)}</td>
                                {this.props.state.status === 'partial_payment' && <td className="text-right">{this.numberWithComma(partial_payment,'0.00')}</td>}
                                {this.props.state.status === 'partial_payment' && <td className="text-right">{this.numberWithComma(amount_partial_payment,'0.00')}</td>}
                                 {this.props.state.status !== 'partial_payment' &&
                                    <td className="text-right">{
                                        // this.props.productAndServiceID ?
                                        // this.numberWithComma(accumulate)
                                        // :
                                        t_index === number_of_transaction - 1 && this.numberWithComma(accumulate)
                                    } </td>
                                }

                                {<td className="text-center" width={130}>
                                    {ref_doc_number_list.length > 0 ?
                                        ref_doc_number_list.map((ref_doc, index) =>
                                            <span key={ref_doc.id+index}>
                                                {ref_doc.type === 'receive_deposit' || ref_doc.type === 'credit_note' ?
                                                    ref_doc.type === 'receive_deposit' ?
                                                        ref_doc.doc_number
                                                        :
                                                        ref_doc.doc_number
                                                        :
                                                        ref_doc.doc_number
                                                }

                                                {index + 1 < ref_doc_number_list.length && ', '}
                                                <br/>
                                            </span>
                                        )
                                        : '-'
                                    }
                                </td>
                                }
                                </tr>
                                )
                                dataALL.push(transactionRow)
                                countRow+=1;
                                let summaryRow = (
                                    <React.Fragment key={invoice.node.id+countRow}>
                                        {this.props.state.status !== 'partial_payment' ? 
                                            <tr className="summaryRow">
                                                <td colSpan={10} className="text-center"><strong>รวม</strong></td>
                                                <td className="text-right"><strong>{this.numberWithComma(total)}</strong></td>
                                            </tr>  
                                            :
                                            <tr>
                                                <td colSpan={9} className="text-center"><strong>รวม</strong></td>
                                                {/* <td className="text-right"><strong>{this.numberWithComma(this.props.props.invoiceViewer.summaryReportInvoice[0])}</strong></td> */}
                                                <td className="text-right"><strong>{this.numberWithComma(total)}</strong></td>
                                                <td className="text-right"><strong>{this.numberWithComma(total_partial_payment)}</strong></td>
                                                <td className="text-right"><strong>{this.numberWithComma(total_amount_partial_payment)}</strong></td>
                                                <td/>
                                            </tr> 
                                        }
                                    </React.Fragment>
                                    )
                                    dataSumRow.push(summaryRow)
                            } 
                            else {
                                let transactionRow = (
                                    <tr key={transaction.node.id}>
                                        <td className="text-center">{!multi_invoice && row_number}</td>
                                        <td className="text-center">
                                                {invoice.node.docNumber}
                                        </td>
                                        <td className="text-center">{format(invoice.node.issuedDate, 'DD/MM/YYYY')}</td>
                                        <td className="text-center">{format(invoice.node.dueDate, 'DD/MM/YYYY')}</td>
                                        <td className="text-center">
                                            {invoice.node.contact.residential ? invoice.node.name : "-"}
                                        </td>
                                        <td>
                                            {invoice.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                getNameResidential(invoice.node.firstName, invoice.node.lastName)
                                            }

                                            {invoice.node.contact.typeOfContact === "SUPPLIER" &&
                                                invoice.node.name
                                            }
                                        {" "}
                                        {
                                        (invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER") &&
                                        <label>
                                            ({invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${invoice.node.payGroup}`) : ""})
                                        </label>
                                        }    
                                        </td>
                                        <td className="text-center">{(transaction.node.productAndService.productCode)}</td>
                                        <td>{
                                            this.removeTrailingZeros(transaction.node.description)
                                            }</td>
                                        <td className="text-center">{this.getStatus(invoice.node.status)}</td>
                                        <td className="text-right">{this.numberWithComma(0)}</td>
                                        <td className="text-right">{
                                            t_index === number_of_transaction - 1 && this.numberWithComma(0)
                                        }</td>
                                        <td className="text-center">-</td>
                                    </tr>
                                )    
                                dataALL.push(transactionRow)   
                                countRow+=1;     
                                let summaryRow = (
                                    <React.Fragment key={invoice.node.id+countRow}>
                                        {this.props.state.status !== 'partial_payment' ? 
                                            <tr className="summaryRow">
                                                <td colSpan={10} className="text-center"><strong>รวม</strong></td>
                                                <td className="text-right"><strong>{this.numberWithComma(total)}</strong></td>
                                            </tr>  
                                            :
                                            <tr>
                                                <td colSpan={9} className="text-center"><strong>รวม</strong></td>
                                                {/* <td className="text-right"><strong>{this.numberWithComma(this.props.props.invoiceViewer.summaryReportInvoice[0])}</strong></td> */}
                                                <td className="text-right"><strong>{this.numberWithComma(total)}</strong></td>
                                                <td className="text-right"><strong>{this.numberWithComma(total_partial_payment)}</strong></td>
                                                <td className="text-right"><strong>{this.numberWithComma(total_amount_partial_payment)}</strong></td>
                                                <td/>
                                            </tr> 
                                        }
                                    </React.Fragment>
                                    )
                                    dataSumRow.push(summaryRow)
                            }                                               
                    })
                })}
                    const dataChunked = [];
                    let chunked = "";
                    for (let i = 0; i < dataALL.length; i += rowPerPage) {
                            chunked = dataALL.slice(i, i + rowPerPage)
                            chunked.push(dataSumRow[i + rowPerPage - 1])
                            if(i + rowPerPage > dataALL.length) {
                                chunked.push(dataSumRow[dataSumRow.length - 1])
                            }
                            dataChunked.push(chunked);
                    }
                    const totalPage = dataChunked.length;
                return(
                    <React.Fragment>
                        {dataChunked.map((transactionData, index_page)=>{
                            return(
                             <React.Fragment key={index_page}>
                                <div className='print-page-a4 hoziA4'> 
                                    <div className='text-left'>
                                        <p className='f12' style={{ lineHeight: 1.5 }}>{this.state.projectName}</p>
                                        <p className='f10'>รายงานการออกใบเเจ้งหนี้</p>
                                        <p className='f10'>เรียงตามผู้ติดต่อ{" "}{this.state.contactName}</p>
                                        <p className='f10'>เรียงตามประเภทสินค้าและบริการ{" "}{this.state.productAndService}</p>
                                        <p className='f10'>เรียงตามสถานะ{" "} {statusHeader}</p>
                                        <p className='f10'>เรียงตามวันที่ {format(this.state.startDate, "DD/MM/YYYY")} ถึง {format(this.state.endDate, "DD/MM/YYYY")}</p>
                                    </div>
                                    <section className="body">
                                        <table className="table tebleBorder">
                                            <thead className='tableHead'>
                                                    <tr>
                                                    <th className="text-center" rowSpan={3} style={{ width: 40 }}>ลำดับ</th>
                                                    <th className="text-center" rowSpan={3} style={{ width: 90 }}>เลขที่ใบแจ้งหนี้</th>
                                                    <th className="text-center" rowSpan={3} style={{ width: 90 }}>วันที่ออก</th>
                                                    <th className="text-center" rowSpan={3} style={{ width: 90 }}>กำหนดชำระ</th>
                                                    <th className="text-center" rowSpan={3} style={{ width: 70 }}>เลขที่ห้อง/<br />บ้านเลขที่</th>
                                                    <th className="text-center" rowSpan={3} style={{ width: 200 }}>ชื่อ</th>
                                                    <th className="text-center" rowSpan={3} style={{ width: 40 }}>รหัส</th>
                                                    <th className="text-center" rowSpan={3} style={{ width: 200 }}>รายละเอียด</th>
                                                    <th className="text-center" rowSpan={3} style={{ width: 50 }}>สถานะ</th>
                                                    <th className="text-center" rowSpan={3} style={{ width:50 }}>จำนวนเงิน</th>
                                                    {this.state.status === 'partial_payment' &&
                                                            <th className="text-right" rowSpan={3} style={{ width:50 }}>ชำระแล้ว</th>
                                                    }
                                                    {this.state.status === 'partial_payment' &&
                                                            <th className="text-right" rowSpan={3} style={{ width:50 }}>คงเหลือ</th>
                                                    }
                                                    {this.state.status !== 'partial_payment' &&
                                                            <th className="text-center" rowSpan={3} style={{ width:50 }}>จำนวนเงินรวม</th>
                                                    }
                                                    
                                                    <th className="text-center" rowSpan={3} style={{ width:50 }}>เลขที่ใบเสร็จรับเงิน</th>
                                                    </tr>
                                            </thead>
                                            <tbody className='tableBody'>
                                                {transactionData}
                                            </tbody>
                                            {index_page === dataChunked.length - 1 &&
                                            <tfoot>  
                                            {
                                                this.props.state.status !== 'partial_payment' ? 
                                                (
                                                    <React.Fragment>
                                                    <tr className="tableFooter">
                                                        <td colSpan={10} className="text-center">
                                                        <strong>รวมทั้งหมด</strong>
                                                        </td>
                                                        <td className="text-right">
                                                        <strong>{this.numberWithComma(summary !== null ? summary.sum_total : 0)}</strong>
                                                        </td>
                                                    </tr>
                                                    </React.Fragment>
                                                ) : 
                                                (
                                                    <tr className="tableFooter">
                                                    <td colSpan={9} className="text-center">
                                                        <strong>รวมทั้งหมด</strong>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>{this.numberWithComma(summary !== null ? summary.sum_total : 0)}</strong>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>{this.numberWithComma(summary !== null ? summary.sum_total_partial_payment : 0)}</strong>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>{this.numberWithComma(summary !== null ? summary.amount_partial_payment : 0)}</strong>
                                                    </td>
                                                    <td />
                                                    </tr>
                                                )
                                            }
                                            </tfoot>
                                            }
                                        </table>
                                    </section>  
                                    <div className="footer">
                                        <div className="row">
                                            <div className="col-5" />
                                                <div className="col-2 text-center">
                                                    <p className="f10 pb-0 mb-0">{index_page+1}/{totalPage}</p>
                                                </div>
                                                <div className="col-5 text-right">
                                                    <p className="f10 pb-0 mb-0">
                                                        ผู้พิมพ์ {this.state.printBy} วันที่พิมพ์{" "}{format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                                    </p>
                                                </div>
                                        </div>
                                    </div>      
                                </div>
                            </React.Fragment>  
                            )
                        })}
                    </React.Fragment>
                )


    }
}

export default InvoiceReportPDFTable






