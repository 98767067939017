import React, { Component } from 'react';

import Wrapper from "../../components/wrapper";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import { Translation } from "react-i18next";
import Pagination from "../../libs/newPagination";
import { commitMutation, fetchQuery } from "relay-runtime";

import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import Loading from '../../libs/loading';

import BackButtonIcon from '../../components/BackBtn/indexBack';
import Swal from 'sweetalert2';
import deleteEmergencyCallGroup from './mutations/deleteEmergencyCallGroupMutation';
import onError from '../../components/Action/onError';
import i18n from '../../i18n';
import i18next from 'i18next';

const query = graphql` 
   query emergencyGroupQuery{
        emergencyCallGroupViewer{
            allEmergencyCallGroup{
                edges{
                    node{
                        id
                        name
                        pinIt
                    }
                }
                totalCount
            }

        }
    }
`;


const mutationGetAndUpdate = graphql`
    mutation emergencyGroupMutation($input:CreateAndUpdateEmergencyCallGroupInput!){
        createAndUpdateEmergencyCallGroup(input:$input) {
            ok
        }
    }
`;





class EmergencyGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: [],
            first: 0,
            last: 10,
            totalCount: 0,
            loading: true,
            loadingNewData: false
        }
        this.addOnModalCatory = this.addOnModalCatory.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.DeleteGroup = this.DeleteGroup.bind(this)
        this.onSuccesss = this.onSuccesss.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    componentDidUpdate(prevState) {
        if (this.state.loading == true && this.state.loadingNewData == true) {
            this.getData()
        }
    }

    getData() {
        fetchQuery(environment, query).then(response_data => {
            if (response_data?.emergencyCallGroupViewer) {
                this.setState({
                    group: response_data?.emergencyCallGroupViewer?.allEmergencyCallGroup?.edges,
                    totalCount: response_data?.emergencyCallGroupViewer?.allEmergencyCallGroup?.totalCount,
                    loading: false,
                    loadingNewData: false
                })
            }

        })
    }


    addOnModalCatory(GroupID, GroupName) {
        Swal.fire({
            title: GroupID ? i18n.t('emergency:Edit category name') : i18n.t('emergency:Add phone directory'),
            customClass: {
                title: 'swal2-title-custom',
                header: 'swal2-header-custom',
                container: 'swal2-container-custom',
                content: 'swal2-content-custom',
                validationMessage: 'text-red',
            },
            input: 'text',
            inputValue: GroupName ? GroupName : '',
            inputPlaceholder: i18n.t('emergency:Specify your phone category'),
            inputValidator: (value) => {
                return !value && 'โปรดระบุชื่อหมวดหมู่เบอร์โทรที่ต้องการ!'
            },
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: i18n.t('emergency:Save'),
            cancelButtonText: i18n.t('emergency:Cancel'),
            // preConfirm: () => ({
            //     nameGroup: document.getElementById("AddGroupPhone").value,
            // }),

        }).then((res) => {
            if (res?.value !== "" && !res.dismiss) {
                let variables = GroupID ? {
                    "input": {
                        "emergencyGroupObject": {
                            "id": GroupID,
                            "name": res?.value
                        }
                    }
                } :
                    {
                        "input": {
                            "emergencyGroupObject": {
                                "name": res?.value
                            }
                        }
                    }


                let mutation = mutationGetAndUpdate
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if (response?.createAndUpdateEmergencyCallGroup?.ok) {
                                Swal.fire(i18n.t('emergency:Saved Successfully'), '', 'success').then(() => {
                                    this.setState({ loading: true, loadingNewData: true })
                                });
                            } else {
                                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
                            }
                        },
                        onError: (err) => {
                            this.setState({ loading: false });
                            Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                        },
                    },
                )
            } else {
                return
            }
        })
    }

    onSuccesss(response) {
        if (response) {
            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                this.setState({ loading: true, loadingNewData: true })
            });
        } else {
            Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
        }
    }

    DeleteGroup(GroupID, GroupName) {
        Swal.fire({
            title: i18n.t('emergency:Are you sure to remove this category?'),
            text: GroupName,
            type: 'question',
            customClass: {
                container: 'swal2-container-custom',
                content: 'swal2-content-custom',
            },
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: i18n.t('emergency:Confirm'),
            cancelButtonText: i18n.t('emergency:Cancel'),
        }).then((res) => {
            if (res?.value) {
                let variables = {
                    "input": {
                        "id": GroupID
                    }
                }

                deleteEmergencyCallGroup(
                    variables,
                    this.onSuccesss,
                    onError
                )
            }
        })
    }

    handleChange = (e,GroupID, GroupName, GroupPin) => {
        // toggle GroupPin
        let variables = {
            "input": {
                "emergencyGroupObject": {
                    "id": GroupID,
                    "name": GroupName,
                    "pin" : !GroupPin
                }
            }
        }
        let mutation = mutationGetAndUpdate

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    if (response?.createAndUpdateEmergencyCallGroup?.ok) {
                        Swal.fire(i18n.t('emergency:Saved Successfully'), '', 'success').then(() => {
                            this.setState({ loading: true, loadingNewData: true })
                        });
                    } else {
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({ loading: false });
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                },
            },
        )

    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <ProjectTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>

                            this.state.loading == false ?
                                <div className="container-fluid box" >
                                    <BackButtonIcon
                                        LinkBack={"/project/emergency"}
                                        LinkText={t('emergency:emergency')}
                                        addOnModalChk={true}
                                        addOnModalClick={() => this.addOnModalCatory(null, null)}
                                        addOnModalText={t("emergency_contact:Add emergency contact Group")}
                                    />

                                    <div className='content-inner mt-5'>
                                        <div className='card fade-up'>
                                            <div className='table-responsive'>
                                                <table className='table table-hover'>
                                                    <thead>
                                                        <tr className='bg-gray'>
                                                            <th className='text-left'>{t("emergency:Phone Catagory Name")}</th>
                                                            <th className='text-center '>{t("emergency:manage")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state?.group?.map((n, indexGroupEmergency) => {
                                                                return (
                                                                    this.state.loading == false ?
                                                                    <tr key={indexGroupEmergency}>

                                                                        <td>{n?.node?.name}</td>
                                                                        <td className='text-center'>
                                                                            <span style={{fontFamily:'medium',marginRight:'5px'}}>{t("emergency:Pin")}</span>
                                                                                <input type="checkbox" name={"pinPhoneBook"} 
                                                                                checked={n.node.pinIt}
                                                                                onChange={(e) => this.handleChange(e,n.node.id, n.node.name, n.node.pinIt)}
                                                                                ></input>
                                                                           
                                                                            <button className='btn btn-primary m-2'
                                                                                onClick={() => this.addOnModalCatory(n.node.id, n.node.name)}>
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + '/images/icons/edit.png'}
                                                                                    alt="edit-icon" className="show-icon"
                                                                                />
                                                                                &nbsp;&nbsp;
                                                                                <span>{t("emergency_contact:Edit")}</span>
                                                                            </button>

                                                                            <button className='btn btn-danger m-2'
                                                                                onClick={() => this.DeleteGroup(n.node.id, n.node.name)}>
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                    alt="delete-icon" className="show-icon"
                                                                                />
                                                                                &nbsp;&nbsp;
                                                                                <span>{t("emergency_contact:Remove")}</span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    :   <Loading />
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Pagination
                                            // changePage={this.changePage} 
                                            first={this.state.first}
                                            last={this.state.last}
                                            totalCount={this.state.totalCount} />
                                    </div>
                                </div>
                                :
                                <Loading />
                        }
                    </Translation>


                </WrapperContent>
            </Wrapper>
        )
    }
}

export default EmergencyGroup;



