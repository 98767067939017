import i18next from "i18next";
import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import "../../report.scss";

class HistorySummary extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            invoiceTotal: 0,
            receiveTotal: 0,
            creditNoteTotal: 0,
            balance: 0,
            sumAll: 0,
        };
        this.summary = this.summary.bind(this);
    }

    componentWillMount() {
        this.summary();
    }

    summary() {
        let invoiceTotal = 0;
        let receiveTotal = 0;
        let creditNoteTotal = 0;
        let receiptDepositTotal = 0;
        let balance = 0;
        let sumAll = 0;

        this.props.query.invoiceViewer.allInvoice.edges.forEach((invoice) => {
            invoice.node.transaction.edges.forEach((transaction) => {

                balance = transaction.node.total;

                if(Math.round(balance) < 0){
                    balance = 0
                }

                invoiceTotal += transaction.node.total;
                sumAll += balance;
                
                transaction.node.receiveTransaction.edges.forEach((rt_transaction) => {
                    let amount_total = 0;
                    let match = false;
                    if(transaction.node.creditNoteTransaction?.edges?.length > 0) {
                    transaction.node.creditNoteTransaction.edges.forEach((cn_transaction) => {
                            if(cn_transaction.node.receive && cn_transaction.node.receive.id === rt_transaction.node.receive.id) {
                                match = true;
                            }
                    })
                    }
                    if (match) {
                        amount_total += 0;
                    } else {
                        amount_total += rt_transaction.node.amount;
                    }
                    // let amount_total = rt_transaction.node.amount
                    rt_transaction.node.receive.creditNoteReceive.edges.map((cd) => {
                        return amount_total = amount_total
                    })
                        balance -= amount_total;  
                        receiveTotal += amount_total;
                        sumAll -= amount_total;
                        if(Math.round(sumAll) < 0){
                            sumAll = 0
                        }
                });

                transaction.node.creditNoteTransaction.edges.forEach((cn_transaction) => {
                    if(cn_transaction.node.status !== "VOID"){
                        balance -= cn_transaction.node.price;
                        creditNoteTotal += cn_transaction.node.price;
                        sumAll -= cn_transaction.node.price;
                        if(Math.round(sumAll) < 0){
                            sumAll = 0
                        }
                    }
                });
                transaction.node.creditNoteTransactionRecord.edges.forEach((cn_transaction) => {
                    if(cn_transaction.node.status !== "VOID"){
                        balance -= cn_transaction.node.price;
                        creditNoteTotal += cn_transaction.node.price;
                        sumAll -= cn_transaction.node.price;
                        if(Math.round(sumAll) < 0){
                            sumAll = 0
                        }
                    }
                });

                transaction.node.receiptDepositTransactionUsed.edges.forEach((rd_transaction) => {
                    if(rd_transaction.node.receiptDepositTransaction.couple === "HAVE" && rd_transaction.node.coupleUse === "HAVE"){
                        balance -= rd_transaction.node.amount;
                        receiptDepositTotal += rd_transaction.node.amount;
                        sumAll -= rd_transaction.node.amount;
                        if(Math.round(sumAll) < 0){
                            sumAll = 0
                        }
                    }
                });

            });
        });

        this.setState({
            invoiceTotal: invoiceTotal.toFixed(2),
            receiveTotal: receiveTotal.toFixed(2),
            creditNoteTotal: creditNoteTotal.toFixed(2),
            receiptDepositTotal: receiptDepositTotal.toFixed(2),
            sumAll: sumAll.toFixed(2),
        });
    }
    
    render() {
        return (
            <tr>
                <td className="text-center font-weight-bold" colSpan={4}>
                    {i18next.t("historyReport:Total")}
                </td>
                <td className="text-right font-weight-bold">
                    {numberWithComma(this.state.invoiceTotal, "-")}
                </td>
                <td className="text-right font-weight-bold" colSpan={3}>
                    {numberWithComma(this.state.receiveTotal, "-")}
                </td>
                <td className="text-right font-weight-bold" colSpan={3}>
                    {numberWithComma(this.state.creditNoteTotal, "-")}
                </td>
                <td className="text-right font-weight-bold" colSpan={3}>
                    {numberWithComma(this.state.receiptDepositTotal, "-")}
                </td>
                <td className="text-right font-weight-bold">
                    {/* {numberWithComma(this.state.sumAll, "-")} */}
                    {numberWithComma(this.state.invoiceTotal - this.state.receiveTotal - this.state.creditNoteTotal - this.state.receiptDepositTotal, "-")}
                </td>
            </tr>
        )
    }
}

export default HistorySummary;
