import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import getNameResidential from "../../../../libs/getNameResidential";
import i18next from "i18next";


class ReceivableOutstandingSummaryReportTable extends React.Component {

    render() {
        let sumOutstandingBalance = 0;
        let sumNotOverdueBalance = 0;
        let sumOverdueOneMonthBalance = 0;
        let sumOverdueTwoMonthsBalance = 0;
        let sumOverdueThreeMonthsBalance = 0;
        let sumOverdueFourMonthsBalance = 0;
        let sumOverdueFiveMonthsBalance = 0;
        let sumOverdueSixMonthsBalance = 0;
        let sumOverdueOverSixMonthsBalance = 0;
    

        return (
            <React.Fragment>
                {
                    this.props.data.reportOutstandingReceivableSummary.edges.map((dataRow, index) => {
                        sumOutstandingBalance += dataRow.node.outstandingBalance;
                        sumNotOverdueBalance += dataRow.node.notOverdueBalance;
                        sumOverdueOneMonthBalance += dataRow.node.overdueOneMonthBalance;
                        sumOverdueTwoMonthsBalance += dataRow.node.overdueTwoMonthsBalance;
                        sumOverdueThreeMonthsBalance += dataRow.node.overdueThreeMonthsBalance;
                        sumOverdueFourMonthsBalance += dataRow.node.overdueFourMonthsBalance;
                        sumOverdueFiveMonthsBalance += dataRow.node.overdueFiveMonthsBalance;
                        sumOverdueSixMonthsBalance += dataRow.node.overdueSixMonthsBalance;
                        sumOverdueOverSixMonthsBalance += dataRow.node.overdueOverSixMonthsBalance;

                        return (
                            <tr key={dataRow.node.id}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-left">{dataRow.node.typeOfContact === 'RESIDENTIAL' ? dataRow.node.name : dataRow.node.refNumber}</td>
                                <td className="text-left">{dataRow.node.typeOfContact === 'RESIDENTIAL' ?
                                    getNameResidential(dataRow.node.firstName, dataRow.node.lastName)
                                    :
                                    dataRow.node.name
                                }
                                {/* {" "}                                            
                                {
                                (dataRow.node.payGroup === "RENT" || dataRow.node.payGroup === "AGENT" || dataRow.node.payGroup === "OWNER" || dataRow.node.payGroup === "DEVELOPER") &&
                                <label className="text-danger">
                                ({dataRow.node.payGroup === "RENT" || dataRow.node.payGroup === "AGENT" || dataRow.node.payGroup === "OWNER" || dataRow.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${dataRow.node.payGroup}`) : ""})
                                </label>
                                }                               */}
                                </td>
                                <td className="text-center">{numberWithComma(dataRow.node.notOverdueBalance, "")}</td>
                                <td className="text-center">{numberWithComma(dataRow.node.overdueOneMonthBalance, "")}</td>
                                <td className="text-center">{numberWithComma(dataRow.node.overdueTwoMonthsBalance, "")}</td>
                                <td className="text-center">{numberWithComma(dataRow.node.overdueThreeMonthsBalance, "")}</td>
                                <td className="text-center">{numberWithComma(dataRow.node.overdueFourMonthsBalance, "")}</td>
                                <td className="text-center">{numberWithComma(dataRow.node.overdueFiveMonthsBalance, "")}</td>
                                <td className="text-center">{numberWithComma(dataRow.node.overdueSixMonthsBalance, "")}</td>
                                <td className="text-center">{numberWithComma(dataRow.node.overdueOverSixMonthsBalance, "")}</td>
                                <td className="text-right">{numberWithComma(dataRow.node.outstandingBalance, "")}</td>
                            </tr>
                        )
                    })
                }
                <tr>
                    <td colSpan={3} className="text-right"><strong>{i18next.t("receivableOutstandingSummaryReport:Total Outstanding")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumNotOverdueBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueOneMonthBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueTwoMonthsBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueThreeMonthsBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueFourMonthsBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueFiveMonthsBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueSixMonthsBalance, "")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(sumOverdueOverSixMonthsBalance, "")}</strong></td>
                    <td className="text-right"><strong>{numberWithComma(sumOutstandingBalance, "")}</strong></td>
                </tr>
            </React.Fragment>
        )
    }
}

export default ReceivableOutstandingSummaryReportTable;
