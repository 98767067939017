import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';
class Navigation extends Component {

    render() {
        return (
            <Translation>
            { 
            (t) => (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/setting/setting-collection-law/register" >
                                {t("collectionLawFirm:Register For Debt Collection Attorney Service")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/setting/setting-collection-law/issue" >
                                {t("collectionLawFirm:Issue a follow-up letter by an attorney")} 
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            )}
            </Translation>
        )
    }
}

export default Navigation;