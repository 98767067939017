import React, { useState } from 'react';
import { Translation } from "react-i18next";
import '../../libs/styles/styleColor.scss'
import onUploadFile from "../../components/Action/onUploadFile";

const UploadFiles = (props) => {
    const [fileList, setFileList] = useState(props.fileList ?? []);
    const [accept, setAccept] = useState(props.accept);
    const [loading, setLoading] = useState(false)

    //uploadFileMore
    const callBackUploadFile = (data) => {
        setFileList(data)
        props.onUpload(data)
        setLoading(false)
        // props.callBack()
    }
    const uploadFile = (e) => {
        setLoading(true)
        let pathname = props?.pathname ?? "contact"
        onUploadFile(e, fileList, pathname, callBackUploadFile);
    }

    if (!props.accept) { setAccept("application/.jpeg,.png,.jpg") }

    return (
        <Translation >
            {t =>
                <React.Fragment>
                    {props.tagP ?
                        <p htmlFor="uploadFile" className="upload-btn-wrapper ml-0">
                            <button className={"btn"}>
                                <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} alt='fileUpload' /> {t("chatGroup:Choose File")}
                            </button>
                            &nbsp;&nbsp;

                            {props.length && <span style={{ color: '#bfbfbf', fontWeight: 300 }}>({props.fileList?.length})</span>}

                            <input type="file" name='file' id="uploadFile"
                                className='form-control uploadFile'
                                placeholder='uploadFile'
                                accept={accept}
                                onChange={uploadFile}
                                disabled={props?.disabled}
                            />
                        </p>
                        :
                        !props.tagIma ?
                            <label htmlFor="uploadFile" className="upload-btn-wrapper ml-0">
                                <button className={`btn ${props?.className}`}>
                                    {
                                        loading ?
                                        <img src={process.env.PUBLIC_URL + '/images/icons/alert/loadinggif.gif'} />
                                        :
                                        <React.Fragment>
                                            <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} className={`${props?.imgHeight}`} alt='fileUpload' /> {t("chatGroup:Choose File")}
                                        </React.Fragment>
                                    }
                                </button>
                                &nbsp;&nbsp;

                                {props.length && <span style={{ color: '#bfbfbf', fontWeight: 300 }}>({props.fileList?.length})</span>}

                                <input type="file" name='file' id="uploadFile"
                                    className='form-control uploadFile'
                                    placeholder='uploadFile'
                                    accept={accept} onChange={uploadFile}
                                    disabled={props?.disabled}
                                />
                            </label>
                            :
                            <label htmlFor="uploadFile" className="upload-btn-wrapper ml-0" style={{position: 'relative'}}>
                                <span className='' style={{position: 'absolute',zIndex: 0,left: "30px",top: '84px',backgroundColor: 'white',color: '#1d6ab6',}}>{t("contact:Upload image")}</span>
                                <img src={process.env.PUBLIC_URL + '/images/icons/ImageAdd.png'} width={150} height={150} />
                                <input type="file" name='file' id="uploadFile"
                                    className='form-control uploadFile'
                                    placeholder='uploadFile'
                                    accept={accept} onChange={uploadFile}
                                    disabled={props?.disabled}
                                />
                            </label>


                    }

                </React.Fragment>
            }
        </Translation>
    )
}

export default UploadFiles;