/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateDebsFreeCertificationInput = {|
  debsFreeCertification: any,
  clientMutationId?: ?string,
|};
export type confirmModalMutationVariables = {|
  input: UpdateDebsFreeCertificationInput
|};
export type confirmModalMutationResponse = {|
  +updateDebsFreeCertification: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type confirmModalMutation = {|
  variables: confirmModalMutationVariables,
  response: confirmModalMutationResponse,
|};
*/


/*
mutation confirmModalMutation(
  $input: UpdateDebsFreeCertificationInput!
) {
  updateDebsFreeCertification(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDebsFreeCertificationPayload",
    "kind": "LinkedField",
    "name": "updateDebsFreeCertification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "confirmModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "confirmModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bb740d5a0a727b65c99fca3e87c16006",
    "id": null,
    "metadata": {},
    "name": "confirmModalMutation",
    "operationKind": "mutation",
    "text": "mutation confirmModalMutation(\n  $input: UpdateDebsFreeCertificationInput!\n) {\n  updateDebsFreeCertification(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a566a99a107b15e7617f28d795b4a816';

module.exports = node;
