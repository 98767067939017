/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateRegulationInput = {|
  id?: ?string,
  subject?: ?string,
  subjectEn?: ?string,
  description?: ?string,
  descriptionEn?: ?string,
  datePosted?: ?any,
  scheduleTime?: ?any,
  status?: ?string,
  type?: ?string,
  previewImage?: ?string,
  file?: ?$ReadOnlyArray<?any>,
  clientMutationId?: ?string,
|};
export type updateRegulationMutationVariables = {|
  input: UpdateRegulationInput
|};
export type updateRegulationMutationResponse = {|
  +updateRegulation: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type updateRegulationMutation = {|
  variables: updateRegulationMutationVariables,
  response: updateRegulationMutationResponse,
|};
*/


/*
mutation updateRegulationMutation(
  $input: UpdateRegulationInput!
) {
  updateRegulation(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateRegulationPayload",
    "kind": "LinkedField",
    "name": "updateRegulation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateRegulationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateRegulationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9f2206eb4c85b7dc95c4049cde091f03",
    "id": null,
    "metadata": {},
    "name": "updateRegulationMutation",
    "operationKind": "mutation",
    "text": "mutation updateRegulationMutation(\n  $input: UpdateRegulationInput!\n) {\n  updateRegulation(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5052a7c60c019bbb55d83f7f37617964';

module.exports = node;
