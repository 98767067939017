import React, { Component } from "react";
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import DeveloperTopMenuNavigation from "../developerTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Navigation from "./navigation";
import "../developer.scss"
import RevenueContractTable from "./revenueContractTable";
import revenueAPI from "./revenueContractAPI"
import SelectDate from "../selectDate";
import SearchProject from "../../project/searchProject";
import Loading from "../../libs/loading";
import ReactPaginate from "../../libs/ReactPaginate";
import _ from 'lodash'

class RevenueContractList extends Component {
    constructor(props){
        super(props);
        this.state={
            status : this.props.match.params.status,
            date : 'All',
            revenueContractList : [],
            search : '',
            token : localStorage.getItem("token"),
            startDate : '',
            loading : false,
            num_record: 0,
            totalCount : 0,
            first: 0,
            last: 10,
            page_count: 0,
            all_item: 10,
            page_select: 0,
            index_pages: {},
            indexFirst : 0
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.state.status !== this.props.match.params.status){
            this.setState({
                status : this.props.match.params.status,
                date : 'All',
                first : 0,
                last : 10,
                page_count: 0,
                num_record: 0,
                all_item: 10,
                page_select: 0,
            })
            this.fetchData()
        }
        if (prevState.page_select !== this.state.page_select) {
            this.fetchData(true);
        }
    }

    handleChange(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = (update) => {
        this.setState({loading:true})
        let obligation = 'all_obligation'
        if(this.props.match.params.status === 'contracted'){
            obligation = 'already_obligation'
        }else if(this.props.match.params.status === 'uncontracted'){
            obligation = 'not_obligation'
        }
        this.setState({loading : true})
        revenueAPI.getViewRevenueContract(this.state.token,this.state.search,obligation,this.state.startDate,
            update ? this.state.index_pages[this.state.page_select].first : this.state.first,
            update ? this.state.index_pages[this.state.page_select].last : this.state.last)
            .then((response) => {
                if(response.data.totalCount !== 0){
                    let first_index = _.cloneDeep(this.state.first);
                    let last_index = _.cloneDeep(this.state.last);
                    let index_pages = {};
                    let page_count = Math.ceil(response.data.totalCount / this.state.all_item);
                    for (let index = 0; index < page_count; index++) {
                        index_pages[index] = {
                        first: first_index,
                        last: last_index,
                        };
                        if (index === page_count - 2) {
                        first_index += this.state.all_item;
                        last_index +=
                            response.data.totalCount % this.state.all_item !== 0
                            ? response.data.totalCount % this.state.all_item
                            : this.state.all_item;
                        } else {
                        first_index += this.state.all_item;
                        last_index += this.state.all_item;
                        }
                    }
                    this.setState({
                        page_count: page_count,
                        num_record: response.data.totalCount,
                        index_pages: index_pages,
                    
                    },() => this.setState({
                        indexFirst : this.state.index_pages[this.state.page_select].first
                    }))
                }else{
                    this.setState({
                        page_count: 0,
                        num_record: response.data.totalCount,
                        index_pages: {},
                    },() => this.setState({
                        indexFirst : 0
                    }))
                }
                if(this.state.revenueContractList !== response.data.residential_list){
                    this.setState({
                        revenueContractList : response.data.residential_list
                    },() => this.setState({loading:false}))
                }
            })
    }

    handleSearch = (text) => {
        this.setState({
            search: text,
            first : 0,
            last : 10,
            page_count: 0,
            num_record: 0,
            all_item: 10,
            page_select: 0,
        } ,() => this.fetchData())
    }
    handleDate = (date) => {
        this.setState({
            startDate: date,
            first : 0,
            last : 10,
            page_count: 0,
            num_record: 0,
            all_item: 10,
            page_select: 0,
        } ,() => this.fetchData())
    }

    changePageLimit = (value) => {
        this.setState({
          first: 0,
          last: value,
          all_item: value,
        },() => this.fetchData());
    }

    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({ page_select: page });
    };

    render() {
        return (
        <Wrapper>
            <Header />
            <Sidebar />
            <WrapperContent disabledOverflowX={true}>
            <DeveloperTopMenuNavigation mini={true} />
            <div className="container-fluid box" id="developer-form">
                <div className="row justify-content-between">
                    <div className="col-md-4">
                        <Translation>
                            {(t) => 
                                <h4>
                                    <Link to="/developer">
                                        <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/wrapperContent/back.png"
                                        }
                                        alt="back"
                                        className="back"
                                        />
                                    </Link>
                                    {t("developer:Revenue Contract")}
                                </h4>
                            }
                        </Translation>
                    </div>
                    <div className="col-md-8">
                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'ownership_obligation_create'}) &&
                        <Link to="/developer/revenue-contract/create/select_customer">
                            <button type="button" className="btn btn-primary add float-right">
                                <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                <Translation>
                                    {
                                        t => <span>{t('developer:Bind revenue contract')}</span>
                                    }
                                </Translation>
                            </button>
                        </Link>
                        }
                    </div>
                </div>

                <Translation>
                    {t => 
                    <div className="content-inner">
                        <Navigation />
                        <div className="row justify-content-end mb-2">
                            <SelectDate callBackStartDate={this.handleDate}/>

                            <div className="col-3 float-right w-auto mb-2">
                                <SearchProject callback_function={this.handleSearch} search={this.state.search}/>
                            </div>
                            
                        </div>
                        {this.state.loading ?
                         <Loading />
                         :
                        <React.Fragment>
                            <div className="card fade-up card-bd-none" >
                                <div className="table-responsive" >
                                    <table className="table table-hover">
                                        <thead className="thead-light ">
                                            <tr>
                                                <th className="text-center bg-grey">{t("developer:No.")}</th>
                                                <th className="text-center bg-grey">{t("developer:Unit")}</th>
                                                <th className="text-left bg-grey">{t("developer:Ownership")}</th>
                                                <th className="text-center bg-grey">{t("developer:Type")}</th>
                                                <th className="text-center bg-grey">{t("developer:Size (Sq m)")}</th>
                                                <th className="text-center bg-grey">{t("developer:Ownership ratio")} </th>
                                                <th className="text-center bg-grey">{t('developer:Floor')}</th>
                                                <th className="text-center bg-grey">{t('developer:Transfer date')}</th>
                                                <th className="text-center bg-grey">{t('developer:Creator')}</th>
                                                {this.state.status === 'all' &&
                                                    <th className="text-center bg-grey">{t("developer:Status")}</th>
                                                }
                                            </tr>
                                            
                                        </thead>
                                        <RevenueContractTable status = {this.state.status} revenueContractList = {this.state.revenueContractList}
                                        indexFirst={this.state.indexFirst} />
                                        
                                    </table>
                                </div>
                            </div>
                        </React.Fragment>}
    
                            <ReactPaginate
                                state={this.state}
                                changePageLimit={this.changePageLimit}
                                handlePageClick={this.handlePageClick}
                            />
                        
                    </div>}
                </Translation>
            </div>
            </WrapperContent>
        </Wrapper>
        );
    }
}

export default RevenueContractList;
