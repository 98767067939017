import '../styles/main.scss';
import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import Invoice from './invoice';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import Loading from "../../libs/loading";
import InvoiceTempleteSingha from './invoiceTempleteSingha';
import InvoiceTemplete3 from './invoiceTemplete3';
import InvoiceTemplete4 from './invoiceTemplete4';
import InvoiceTemplete5 from './invoiceTemplete5';
import { fetchQuery } from "relay-runtime";

const query = graphql`
    query wrapperInvoiceAllQuery($id_in: [String]!) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            bankCompCode
            bankServiceCode
            prompayQrShow
        }
        userConfig {
            id languages
        }
        invoiceViewer{
            groupByInvoice(id_In: $id_in)
            allInvoice(id_In: $id_in, status_In: "active, overdue, paid, partial_payment"){
                edges{
                    node{
                        id
                        docNumber
                        remark
                        status
                        total
                        taxType
                        topupCrossbill

                        firstName
                        lastName
                        name
                        taxIdNumber
                        email
                        phone
                        
                        address
                        country
                        province
                        district
                        city
                        postalCode
                        payGroup
                        
                        contact {
                            refNumber
                            name
                            taxIdNumber
                            firstName
                            lastName
                            phone
                            email
                            typeOfCompany
                            typeOfSupplier
                            nameBranch
                            typeOfContact
                            typeOfPayment
                            
                            residential {
                                id
                                name
                                size
                                ownershipRatio
                                unitNumber
                                residentialHouseholder(type:"renter"){ 
                                    edges{
                                        node{
                                            id
                                            tenant{
                                                nameTitle
                                                firstName
                                                lastName
                                                phone
                                                email
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        docNumber
                        issuedDate
                        dueDate
                        creator
                        remark
                        historyShow
                        transaction{
                            edges{
                                node{
                                    id
                                    find {
                                        edges {
                                            node{
                                                id
                                                group {
                                                    fineDate
                                                }
                                            }
                                        }
                                    }
                                    productAndService {
                                        productCode
                                    }
                                    chartOfAccount{
                                        chartOfAccountCode
                                    }
                                    description
                                    unitItems
                                    price
                                    preTaxAmount
                                    discount
                                    vat
                                    whtRate
                                    whtRatePercent
                                    total
                                    receiptDepositTransactionUsed{
                                        edges{
                                            node{
                                                id
                                                amount
                                                coupleUse
                                                receiptDepositTransaction{
                                                    couple
                                                }
                                            }
                                        }
                                    }
                                    keycardPerInvoice{
                                        edges{
                                            node {
                                                id
                                                numberKey{
                                                    id
                                                    noNumber
                                                    status
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        checkInvoiceTemplate{
        edges{
            node{
                id 
                templateFirst
                descriptionFirst
                topicThFirst
                topicEnFirst
                templateSecond
                descriptionSecond
                topicThSecond,
                topicEnSecond,
                templateThird,
                descriptionThird,
                topicThThird,
                topicEnThird,
                templateFourth
                descriptionFourth
                topicThFourth,
                topicEnFourth,
                templateFifth
                descriptionFifth
                topicThFifth
                topicEnFifth
            }
        }
    }
    }
`;


class WrapperInvoiceAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkList: props.location.state.checkList,
            check_type: 'all',
            check_templete: 'template1',//default,
            template:'',
            templateSelete:'template1'
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount(){
         //template1 = default 
        //template2 = templateSingha 
        // let token = jwtDecode(localStorage.getItem("token"))
        // let siteChk = token?.site?.includes("sklasmanagement")
        // this.setState({ check_templete: siteChk ? 'template2' : 'template1' })

        this.getData()
    }

    getData() {
        fetchQuery(environment, query,{ id_in: this.state.checkList, check_type: this.state.check_type  }).then(data => {
            
            let result = '';

            if(data.checkInvoiceTemplate.edges.length > 0){
                if(data.checkInvoiceTemplate.edges[0].node.templateFirst === true){
                        result = 'template1'
                }else if(data.checkInvoiceTemplate.edges[0].node.templateSecond === true){
                        result = 'template2'
                }else if(data.checkInvoiceTemplate.edges[0].node.templateThird === true){
                        result =  'template3'
                }else if(data.checkInvoiceTemplate.edges[0].node.templateFourth === true){
                        result =  'template4'
                }else if(data.checkInvoiceTemplate.edges[0].node.templateFifth === true){
                        result =  'template5'
                }else{
                        result = 'template1' //default
                }
            }else{
                result =  'template1'
            }

           this.setState({
            templateSelete:result
           })
           
        }).catch(err => {
            alert(err)
        })
    }
    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>Invoice Print</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeAll"
                                    onChange={this.handleInputChange}
                                    value="all"
                                    checked={this.state.check_type === 'all'} />
                                <label className="form-check-label" htmlFor="checkTypeAll">
                                    ทั้งหมด
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeManuscript"
                                    value="manuscript"
                                    onChange={this.handleInputChange}
                                    checked={this.state.check_type === 'manuscript'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    ต้นฉบับ
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeCopy"
                                    value="copy"
                                    onChange={this.handleInputChange}
                                    checked={this.state.check_type === 'copy'} />
                                <label className="form-check-label" htmlFor="checkTypeCopy">
                                    สำเนา
                                </label>
                            </div>
                        </div>


                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate1"
                                    onChange={this.handleInputChange}
                                    value="template1" disabled
                                    checked={this.state.templateSelete === 'template1'} />
                                <label className="form-check-label" htmlFor="checkTypeAll">
                                    รูปแบบที่ 1
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate2"
                                    value="template2" disabled
                                    onChange={this.handleInputChange}
                                    checked={this.state.templateSelete === 'template2'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    รูปแบบที่ 2
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate3"
                                    value="template3" disabled
                                    onChange={this.handleInputChange}
                                    checked={this.state.templateSelete === 'template3'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    รูปแบบที่ 3
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate4"
                                    value="template4" disabled
                                    onChange={this.handleInputChange}
                                    checked={this.state.templateSelete === 'template4'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    รูปแบบที่ 4
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate5"
                                    value="template5" disabled
                                    onChange={this.handleInputChange}
                                    checked={this.state.templateSelete === 'template5'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    รูปแบบที่ 5
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{ use_cache: false }}
                    variables={{ id_in: this.state.checkList, check_type: this.state.check_type }}
                    render={({ error, props }) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                role="alert">{error.message}</div>;
                        } else if (props) {

                            let result = ""

                            if(props.checkInvoiceTemplate?.edges.length > 0){
                                if(props?.checkInvoiceTemplate?.edges[0]?.node?.templateFirst === true){
                                    result = 'template1'
                                }else if(props?.checkInvoiceTemplate?.edges[0]?.node?.templateSecond === true){
                                    result = 'template2'
                                }else if(props?.checkInvoiceTemplate?.edges[0]?.node?.templateThird === true){
                                    result =  'template3'
                                }else if(props?.checkInvoiceTemplate?.edges[0]?.node?.templateFourth === true){
                                    result =  'template4'
                                }else if(props?.checkInvoiceTemplate?.edges[0]?.node?.templateFifth === true){
                                    result =  'template5'
                                }else{
                                    result = 'template1' //default
                                }
                            }else{
                                result = 'template1' //default
                            }
                           
                            return (
                                result === "template1" ?
                                props.invoiceViewer.allInvoice.edges.map((invoice, index) =>
                                    <Invoice query={props} selfProject={props.selfProject}
                                        invoice={invoice.node} key={invoice.node.id}
                                        check_type={this.state.check_type}
                                        invoiceNumber={atob(invoice.node.id).split(':')[1]}
                                        history={props.invoiceViewer.groupByInvoice}
                                        userConfig={props.userConfig}
                                        langTitleTH = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicThFirst}
                                        langTitleEN = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicEnFirst}
                                    />
                                )
                                : result === "template2" ?
                                props.invoiceViewer.allInvoice.edges.map((invoice, index) => {
                                    return (
                                        <InvoiceTempleteSingha
                                            query={props} selfProject={props.selfProject}
                                            invoice={invoice.node} key={invoice.node.id}
                                            check_type={this.state.check_type}
                                            invoiceNumber={atob(invoice.node.id).split(':')[1]}
                                            history={props.invoiceViewer.groupByInvoice}
                                            findUniNo={invoice.node?.contact?.residential?.unitNumber}
                                            userConfig={props.userConfig}
                                            langTitleTH = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicThSecond}
                                            langTitleEN = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicEnSecond}
                                        />
                                    )
                                })
                                : result === "template3" ?
                                props.invoiceViewer.allInvoice.edges.map((invoice, index) => {
                                    return (
                                        <InvoiceTemplete3
                                            query={props} selfProject={props.selfProject}
                                            invoice={invoice.node} key={invoice.node.id}
                                            check_type={this.state.check_type}
                                            invoiceNumber={atob(invoice.node.id).split(':')[1]}
                                            history={props.invoiceViewer.groupByInvoice}
                                            findUniNo={invoice.node?.contact?.residential?.unitNumber}
                                            userConfig={props.userConfig}
                                            langTitleTH = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicThThird}
                                            langTitleEN = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicEnThird}
                                        />
                                    )
                                })
                                : result === "template4" ?
                                props.invoiceViewer.allInvoice.edges.map((invoice, index) => {
                                    return (
                                        <InvoiceTemplete4
                                            query={props} selfProject={props.selfProject}
                                            invoice={invoice.node} key={invoice.node.id}
                                            check_type={this.state.check_type}
                                            invoiceNumber={atob(invoice.node.id).split(':')[1]}
                                            history={props.invoiceViewer.groupByInvoice}
                                            findUniNo={invoice.node?.contact?.residential?.unitNumber}
                                            userConfig={props.userConfig}
                                            langTitleTH = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicThFourth}
                                            langTitleEN = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicEnFourth}
                                        />
                                    )
                                })
                                : result === "template5" ?
                                props.invoiceViewer.allInvoice.edges.map((invoice, index) => {
                                    return (
                                        <InvoiceTemplete5
                                            query={props} selfProject={props.selfProject}
                                            invoice={invoice.node} key={invoice.node.id}
                                            check_type={this.state.check_type}
                                            invoiceNumber={atob(invoice.node.id).split(':')[1]}
                                            history={props.invoiceViewer.groupByInvoice}
                                            findUniNo={invoice.node?.contact?.residential?.unitNumber}
                                            userConfig={props.userConfig}
                                            langTitleTH = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicThFifth}
                                            langTitleEN = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicEnFifth}
                                        />
                                    )
                                })
                                : props.invoiceViewer.allInvoice.edges.map((invoice, index) => {
                                    return (
                                        <Invoice
                                            query={props} selfProject={props.selfProject}
                                            invoice={invoice.node} key={invoice.node.id}
                                            check_type={this.state.check_type}
                                            invoiceNumber={atob(invoice.node.id).split(':')[1]}
                                            history={props.invoiceViewer.groupByInvoice}
                                            findUniNo={invoice.node?.contact?.residential?.unitNumber}
                                            userConfig={props.userConfig}
                                            langTitleTH = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicThFirst}
                                            langTitleEN = {props?.checkInvoiceTemplate?.edges[0]?.node?.topicEnFirst}
                                        />
                                    )
                                })
                            )
                        }
                        return <div className="text-center"><Loading /></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperInvoiceAll;
