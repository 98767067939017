import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import CollectionLawFirmList from "./collectionLawFirmList";
import CollectionLawFirmDetail from "./collectionLawFirmDetail";
import RegisCollectionLawFrimCreate from "./regisCollectionLawFrimCreate";
import CollectionLawFrimCreate from "./collectionLawFrimCreate"
import CollectionLawFrimSetting from "./setting/collectionLawFrimSetting"
import ReportCollectionLawFrim from "./report/reportCollectionLawFrim"
import CollectionLawFirmENotice from './collectionLawFirmENotice';
import CollectionLawFirmENoticeDocument from './printDoc/collectionLawFirmENoticeDocument';
import CollectionLawFrimUploadFile from './collectionLawFrimUploadFile';
class CollectionLawFirm extends Component {
    
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={CollectionLawFirmList}/>
                <PrivateRoute exact path={`${this.props.match.url}/regis`} component={RegisCollectionLawFrimCreate} />
                <PrivateRoute exact path={`${this.props.match.url}/list/:status?`} component={CollectionLawFirmList}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id/attach`} component={CollectionLawFrimUploadFile}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id`} component={CollectionLawFirmDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/create`} component={CollectionLawFrimCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/setting`} component={CollectionLawFrimSetting}/>
                <PrivateRoute exact path={`${this.props.match.url}/report/:status?`} component={ReportCollectionLawFrim}/>
                <PrivateRoute exact path={`${this.props.match.url}/createENotice`} component={CollectionLawFirmENotice}/>
                <PrivateRoute exact path={`${this.props.match.url}/printDocument/:id?`} component={CollectionLawFirmENoticeDocument}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default CollectionLawFirm;