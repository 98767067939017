import React from 'react';
import { QueryRenderer } from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import _ from "lodash";
import Loading from '../../../libs/loading';
import i18next from 'i18next';

const guaranteeMoneyReceivedPaymentChannel = graphql`
    query pettyCashRecordListTableGuaranteeMoneyReceivedQuery($search: String, $start_date: DateTime, $end_date: DateTime, $first: Int, $last: Int, $setPettyCash_Id: ID) {
        allClearGuaranteeMoneyReceivedPettyCashChannel(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last, setPettyCash_Id: $setPettyCash_Id){
            totalCount
            edges{
                node{
                    id
                    clearGuaranteeMoneyReceived{
                        id
                        docNumber
                        issuedDate
                        status
                        contact{
                            refNumber
                            firstName
                            lastName
                            name
                        }
                        guarantee {
                            chartOfAccount {
                                id
                                chartOfAccountCode
                                name
                            }
                        }
                    }
                    description
                    price
                    statusSetPettyCash
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }
    }
`;

class PettyCashRecordListTableGuaranteeMoneyReceived extends ComponentPagination {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            redirect: false,
            reQuery: false,
            first: this.state.first,
            last: this.state.last
        };
    }

    handleChange() {
        this.setState({ reQuery: true });
    }

    append_list(valuse) {
        let list_return = {};
        let docNumber = null;
        valuse.edges.map((result, index) => {
            if (docNumber === null) {
                docNumber = result.node.clearGuaranteeMoneyReceived.docNumber
                list_return[result.node.clearGuaranteeMoneyReceived.docNumber] = [];
                list_return[result.node.clearGuaranteeMoneyReceived.docNumber].push(result)
            } else {
                if (docNumber !== result.node.clearGuaranteeMoneyReceived.docNumber) {
                    docNumber = result.node.clearGuaranteeMoneyReceived.docNumber
                    list_return[result.node.clearGuaranteeMoneyReceived.docNumber] = [];
                    list_return[result.node.clearGuaranteeMoneyReceived.docNumber].push(result)
                } else {
                    list_return[result.node.clearGuaranteeMoneyReceived.docNumber].push(result)
                }
            }
            return result
        })
        return list_return
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={guaranteeMoneyReceivedPaymentChannel}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status,
                    setPettyCash_Id: this.props.set_petty_cash_id,
                    reQuery: this.state.reQuery
                }}
                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        let clearGuaranteeMoney_allPettyCashRecord = this.append_list(props.allClearGuaranteeMoneyReceivedPettyCashChannel)
                        return (<React.Fragment>
                            <div className="table-responsive fade-up card">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center">{i18next.t("pettyCashView:Document No.")}</th>
                                            <th className="text-center">{i18next.t("pettyCashView:Date ")}</th>
                                            <th>{i18next.t("pettyCashView:List of petty cash")}</th>
                                            <th>{i18next.t("pettyCashView:Code")}</th>
                                            <th>{i18next.t("pettyCashView:Account")}</th>
                                            <th>{i18next.t("pettyCashView:List")}</th>
                                            <th className="text-right" style={{ whiteSpace: 'nowrap' }}>{i18next.t("pettyCashList:Amount withdrawal")}</th>
                                            <th className="text-center">{i18next.t("pettyCashView:Status")}</th>
                                            <th />
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {Object.entries(clearGuaranteeMoney_allPettyCashRecord).map(([index, clear_guarantee_money_petty_cash_record], index_stack) => {

                                            return (
                                                clear_guarantee_money_petty_cash_record.map((clear_guarantee_money_petty_cash, index_question) => {
                                                    let code = "";
                                                    let name_code = "";

                                                    code = clear_guarantee_money_petty_cash.node.clearGuaranteeMoneyReceived.guarantee.chartOfAccount.chartOfAccountCode;
                                                    name_code = clear_guarantee_money_petty_cash.node.clearGuaranteeMoneyReceived.guarantee.chartOfAccount.name;
                                                    
                                                    return (
                                                        <tr key={clear_guarantee_money_petty_cash.node.id + index}>
                                                            <td style={{ whiteSpace: 'nowrap' }} className={clear_guarantee_money_petty_cash.node.statusSetPettyCash === "VOID" ? "text-center text-danger" : "text-center"}>
                                                                {clear_guarantee_money_petty_cash.node.clearGuaranteeMoneyReceived.docNumber}
                                                            </td>
                                                            <td className="text-center">{format(clear_guarantee_money_petty_cash.node.clearGuaranteeMoneyReceived.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                            <td>{clear_guarantee_money_petty_cash.node.setPettyCash.docNumber} {clear_guarantee_money_petty_cash.node.setPettyCash.withdrawer} {clear_guarantee_money_petty_cash.node.setPettyCash.description}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{code}</td>
                                                            <td>{name_code}</td>
                                                            <td>{clear_guarantee_money_petty_cash.node.clearGuaranteeMoneyReceived.contact.name} - {clear_guarantee_money_petty_cash.node.description}</td>
                                                            <td className="text-right">{numberWithComma(clear_guarantee_money_petty_cash.node.price)}</td>
                                                            <td className={clear_guarantee_money_petty_cash.node.statusSetPettyCash === "VOID" ? "text-center text-danger" : "text-center"}>{clear_guarantee_money_petty_cash.node.statusSetPettyCash === 'WAIT' ? 'รอเบิก' : clear_guarantee_money_petty_cash.node.statusSetPettyCash === "VOID" ? 'ยกเลิก' : 'เบิกแล้ว'}</td>
                                                            <td className="text-center">
                                                                {clear_guarantee_money_petty_cash.node.refPettyCashRecordDoc ?
                                                                    <a download="name_of_downloaded_file"
                                                                        href={clear_guarantee_money_petty_cash.node.refPettyCashRecordDoc}
                                                                        target={"_blank"}>
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/download-file-icon.png'}
                                                                            alt="back"
                                                                            className="download-file-icon" />
                                                                    </a>
                                                                    :
                                                                    "-"
                                                                }
                                                            </td>
                                                            <td>
                                                                {index_question === 0 && clear_guarantee_money_petty_cash.node.clearGuaranteeMoneyReceived.status !== "VOID" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_print' }) &&
                                                                    <Link
                                                                        to={"/accounting/guarantee-money-received/view-clear/" + clear_guarantee_money_petty_cash.node.clearGuaranteeMoneyReceived.id}
                                                                        target={"_blank"}>
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/print.png'}
                                                                            alt="print" className="print" />
                                                                    </Link>

                                                                }
                                                            </td>

                                                        </tr>
                                                    )

                                                })

                                            )//return Object
                                        }
                                        )}

                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <div className="col" />
                                <div className="col">
                                    <div className="form-inline float-right p-3 mb-2 mt-2 bg-light text-dark">
                                        <div className="text-left">{i18next.t("pettyCashView:Balance")}</div>
                                        <div className="text-right ml-5">{
                                            this.props.all_set_petty_cash_list.map((petty) => petty.node.id === this.props.set_petty_cash_id &&
                                            numberWithComma(Math.round(((petty.node.withdrawAmount - petty.node.totalPettyCashRecord) + Number.EPSILON) * 100) / 100)
                                            )
                                        } {i18next.t("pettyCashView:Baht")}</div>
                                    </div>
                                    <div className="form-inline float-right p-3 mb-2 mt-2 bg-light text-dark mr-4">
                                        <div className="text-left">{i18next.t("pettyCashView:Total amount withdrawal")}</div>
                                        <div className="text-right ml-5">{
                                            this.props.all_set_petty_cash_list.map((petty) => petty.node.id === this.props.set_petty_cash_id &&
                                            numberWithComma( Math.round(((petty.node.totalPettyCashRecord) + Number.EPSILON) * 100) / 100 )
                                            )
                                        } {i18next.t("pettyCashView:Baht")}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props.allClearGuaranteeMoneyReceivedPettyCashChannel.totalCount} />
                            </div>
                        </React.Fragment>
                        )
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default PettyCashRecordListTableGuaranteeMoneyReceived;