/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreditCardDepositGroupCreateInput = {|
  creditCardDepositGroup: CreditCardDepositGroupInput,
  clientMutationId?: ?string,
|};
export type CreditCardDepositGroupInput = {|
  description: string,
  depositDateTime: any,
  depositTotal: number,
  feeTotal: number,
  bankAccountId: string,
  transactions: any,
|};
export type creditCardDepositCreateFormMutationVariables = {|
  input: CreditCardDepositGroupCreateInput
|};
export type creditCardDepositCreateFormMutationResponse = {|
  +creditCardDepositGroupCreate: ?{|
    +clientMutationId: ?string,
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type creditCardDepositCreateFormMutation = {|
  variables: creditCardDepositCreateFormMutationVariables,
  response: creditCardDepositCreateFormMutationResponse,
|};
*/


/*
mutation creditCardDepositCreateFormMutation(
  $input: CreditCardDepositGroupCreateInput!
) {
  creditCardDepositGroupCreate(input: $input) {
    clientMutationId
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreditCardDepositGroupCreatePayload",
    "kind": "LinkedField",
    "name": "creditCardDepositGroupCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "creditCardDepositCreateFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "creditCardDepositCreateFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3df6eb5b67fe86d3df80573346388966",
    "id": null,
    "metadata": {},
    "name": "creditCardDepositCreateFormMutation",
    "operationKind": "mutation",
    "text": "mutation creditCardDepositCreateFormMutation(\n  $input: CreditCardDepositGroupCreateInput!\n) {\n  creditCardDepositGroupCreate(input: $input) {\n    clientMutationId\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8415c8bc8eeff7eae3e258324f91898a';

module.exports = node;
