import { _ } from 'core-js';
import i18next from 'i18next';
import React, { Component } from 'react'
import { Translation } from 'react-i18next';
import DatePickerNoti from '../../../accounting/finance/depositInterest/datePickerNoti';
import "../style/manageOrder.scss"

export default class formContactDetails extends Component {

  render() {
    return (
      <Translation>
        {t =>
          <div id="formContactDetails">
            <div className="row">
              <div className="col">
                <h4>{i18next.t("purchaseRequisition:Contact detail")}</h4>
                <div className="lineBottom mb-5" />
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <h6>{i18next.t("purchaseRequisition:Contact name")}</h6>
                <div className="input-group">
                  <input type="text"
                    disabled
                    className="form-control"
                    placeholder="Name Lastname"
                    // name="purchaseRequisition.creditor" 
                    value={this.props.procurementForm.contractor}
                  // onChange={this.props.handleChangeInput}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-4">
                <h6>{i18next.t("purchaseRequisition:Phone number")} <span className='text-red'>* {i18next.t("purchaseRequisition:maximum 10 digits")}</span></h6>
                <div className="input-group">
                  <input type="text"
                    disabled
                    className="form-control"
                    placeholder="099 999 0909"
                    // name="purchaseRequisition.creditor" 
                    value={this.props.procurementForm.contractorTel}
                  // onChange={this.props.handleChangeInput}
                  />
                </div>
              </div>

              <div className="col-4">
                <h6>{i18next.t("purchaseRequisition:E-Mail")}</h6>
                <div className="input-group">
                  <input type="text"
                    disabled
                    className="form-control"
                    placeholder="example: silverman@mail.com"
                    // name="purchaseRequisition.creditor" 
                    value={this.props.procurementForm.contractorEmail}
                  // onChange={this.props.handleChangeInput}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-4">
                <h6>{i18next.t("purchaseRequisition:Delivery address")}</h6>
                <div className="input-group">
                  <textarea
                    disabled
                    className="bodyTextArea-disibled"
                    placeholder={i18next.t("purchaseRequisition:Delivery address")}
                    // name="purchaseRequisition.creditor" 
                    value={this.props.procurementForm.deliveryAddress}
                  // onChange={this.props.handleChangeInput}
                  />
                </div>
              </div>

              <div className="col-2 customDatePickerWidth">
                <h6>{i18next.t("purchaseRequisition:Delivery date")}</h6>
                <DatePickerNoti
                  disabled
                  className="form-control"
                  selected={this.props.procurementForm.deliveryDate}
                />
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-4">
                <h5>{i18next.t("purchaseRequisition:Album")}</h5>
                <div className="row col-12">
                  {_.map(this.props.procurementForm.procurementFormImage, (item, index) => {
                    return (
                      <div className="mt-5 mr-2" key={index}>
                        <img className="imgAlbum"
                          src={item.node.fileUpload}
                          alt="picture"
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

          </div>
        }
      </Translation>
    )
  }
}
