/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccountRecordGroupCategory = "GENERAL" | "HISTORICAL" | "PAYMENT" | "PURCHASE" | "RECEIVE" | "SALES" | "VOIDED" | "%future added value";
export type libcurrentRecordQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
  betweenStartId?: ?string,
  betweenEndId?: ?string,
|};
export type libcurrentRecordQueryResponse = {|
  +currentRecord: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +debit: number,
        +credit: number,
        +added: any,
        +group: ?{|
          +id: string,
          +name: string,
          +issuedDate: ?any,
          +refNumber: ?string,
          +totalDebit: number,
          +totalCredit: number,
          +refTransaction: ?string,
          +added: any,
          +category: AccountRecordGroupCategory,
        |},
        +chartOfAccountCode: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |},
      |}
    |}>
  |}
|};
export type libcurrentRecordQuery = {|
  variables: libcurrentRecordQueryVariables,
  response: libcurrentRecordQueryResponse,
|};
*/


/*
query libcurrentRecordQuery(
  $startDate: DateTime
  $endDate: DateTime
  $betweenStartId: String
  $betweenEndId: String
) {
  currentRecord: allAccountRecord(startDate: $startDate, endDate: $endDate, typeOfRecord: "current", betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {
    edges {
      node {
        id
        name
        debit
        credit
        added
        group {
          id
          name
          issuedDate
          refNumber
          totalDebit
          totalCredit
          refTransaction
          added
          category
        }
        chartOfAccountCode {
          id
          name
          chartOfAccountCode
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "betweenEndId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "betweenStartId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v7 = [
  {
    "alias": "currentRecord",
    "args": [
      {
        "kind": "Variable",
        "name": "betweenEndId",
        "variableName": "betweenEndId"
      },
      {
        "kind": "Variable",
        "name": "betweenStartId",
        "variableName": "betweenStartId"
      },
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Literal",
        "name": "typeOfRecord",
        "value": "current"
      }
    ],
    "concreteType": "AccountRecordNodeConnection",
    "kind": "LinkedField",
    "name": "allAccountRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountRecordNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountRecordNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "credit",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountRecordGroupNode",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "issuedDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalDebit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCredit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refTransaction",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "chartOfAccountCode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "chartOfAccountCode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "libcurrentRecordQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "libcurrentRecordQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "2f328de5b48daf906f24f521ad50f839",
    "id": null,
    "metadata": {},
    "name": "libcurrentRecordQuery",
    "operationKind": "query",
    "text": "query libcurrentRecordQuery(\n  $startDate: DateTime\n  $endDate: DateTime\n  $betweenStartId: String\n  $betweenEndId: String\n) {\n  currentRecord: allAccountRecord(startDate: $startDate, endDate: $endDate, typeOfRecord: \"current\", betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {\n    edges {\n      node {\n        id\n        name\n        debit\n        credit\n        added\n        group {\n          id\n          name\n          issuedDate\n          refNumber\n          totalDebit\n          totalCredit\n          refTransaction\n          added\n          category\n        }\n        chartOfAccountCode {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '16345e8f33253617c7287b83ec7fe679';

module.exports = node;
