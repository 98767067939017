import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import WithholdingTaxPrint from "./withholdingTaxPrint";
import Loading from '../../libs/loading';

const query = graphql`
    query wrapperWithholdingTaxQuery($id: ID!) {
        selfProject {
            id
            name
            address
            logo
            stamp
            taxIdNumber
        }
        withholdingTax(id: $id) {
            id
            docNumber
            total
            unknownContact
            
            otherExpense {
                id
                docNumber
                issuedDate
                unknownContact

                firstName 
                lastName
                name
                phone
                email
                taxIdNumber
                address
                district
                city
                province
                postalCode
                country
                
                otherExpenseTransaction {
                    edges {
                        node {
                            id
                            total
                            preTaxAmount
                            whtRate
                            whtRatePercent
                            description

                            typeOfIncome
                            typeOfIncomeOther
                            typeOfTax
                            typeOfTaxOther
                            taxRate

                            productAndService{
                                id
                                name
                                typeOfIncome
                                typeOfIncomeOther
                                typeOfTax
                                typeOfTaxOther
                                taxRate
                            }
                        }
                    }
                }
            }

            prepaidDeposit{
                id
                docNumber
                issuedDate
                status
                creator
                remark
                total
                refNumber
                taxType

                prepaidDepositTransaction {
                    totalCount
                    edges{
                        node{
                            id
                            chartOfAccount{
                                id name chartOfAccountCode
                            }
                            total
                            vat
                            whtRatePercent
                            price
                            description
                            vatAmount
                            whtAmount
                        }
                    }
                }
            }
            
            payRecordGroup {
                id
                total
                docNumber
                issuedDate

                firstName 
                lastName
                name
                phone
                email
                taxIdNumber
                address
                district
                city
                province
                postalCode
                country
                refPayExpenseDate
                
                payRecord {
                    edges {
                        node {
                            id
                            amount
                            whtAmount
                            whtRatePercent
                            purchaseRecord{
                                id
                                total
                                preTaxAmount
                                whtRate
                                whtRatePercent
                                description

                                typeOfIncome
                                typeOfIncomeOther
                                typeOfTax
                                typeOfTaxOther
                                taxRate
                                
                                productAndService{
                                    id
                                    name
                                    typeOfIncome
                                    typeOfIncomeOther
                                    typeOfTax
                                    typeOfTaxOther
                                    taxRate
                                }
                                prepaidDepositTransactionUsed {
                                    edges {
                                        node {
                                            amount
                                            prepaidDepositTransaction{
                                                whtRatePercent
                                                vat
                                            }
                                        }
                                    }
                                }
                                acceptCreditNoteTransaction(status: "paid"){
                                    edges {
                                        node {
                                            price
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            contact {
                id
                taxIdNumber
                typeOfSupplier
                typeOfPeople
                name
                refNumber
                firstName
                lastName
                registeredAddress
                registeredCity
                registeredCountry
                registeredDistrict
                registeredName
                registeredPostalCode
                registeredProvince
            }
            issuedDate
            added
            total
            remark
        }
    }
`;


class WrapperWithholdingTax extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.adjustHeight = this.adjustHeight.bind(this);
    }

    print() {
        window.print();
    }

    adjustHeight() {

    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>WithholdingTax</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>

                <QueryRenderer
                    environment={environment}
                    query={query}
                    variables={{ id: this.props.match.params.id }}
                    render={({ error, props }) => {
                        if (error) {
                            return <div>{error.message}</div>;
                        } else if (props) {
                            return <React.Fragment>
                                {
                                    [1, 2, 3, 4].map((count_paper,index) => {
                                        return (<WithholdingTaxPrint query={props} withholdingTax={props.withholdingTax} count_paper={count_paper} key={index}/>)
                                    })
                                }
                            </React.Fragment>
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperWithholdingTax;
