
import React, { Component } from 'react';
import './regisCollectionLawFrim.scss'
import { Translation } from 'react-i18next';
import CollectionLawFrimStep from "./collectionLawFrimStep"

class ConfrimCollectionLawFrim extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }


    render() {
        const { pdpaAcceptMarketing, pdpaAccept } = this.props;
        const isButtonEnabled = pdpaAccept && pdpaAcceptMarketing;
        return (
            <>
                <Translation>
                     {
                      t => (
                    <div>
                        <CollectionLawFrimStep  />
                        <div className='wrapper-box-accept' style={{height:'400px'}}>
                            <div>
                                <h2 className='pt-2'> {t(`collectionLawFirm:The conditions for accessing the system to request common area maintenance fees`)} 
                                <br/>{t(`collectionLawFirm:Or the public utility service fees through E-Notice`)}</h2>
                            </div>
                            <div className='coll-law-text pt-6'>
                            {t(`collectionLawFirm:1. The E-Notice system charges a fee of 150 Baht per unit for Thai co-owners and 200 Baht per unit for foreign co-owners`)} <br/>
                            {t(`collectionLawFirm:2. Customers are responsible for printing documents, sending them by registered mail, and keeping the delivery receipts for the homeowner and co-owners, and they are required to cover the expenses themselves`)}<br/>
                            {t(`collectionLawFirm:3. Customers who grant power of attorney to an attorney to issue demand letters for common area maintenance fees through the E-Notice system via the Silverman platform must provide an affidavit certifying that they are authorized to act on behalf of the legal entity`)} <br/>
                            {t(`collectionLawFirm:4. The service provider is not responsible for the accuracy of the data entered into the system, and it is the responsibility of the users through the system to verify the information of homeowners or co-owners before confirming and recording the data`)}<br/>
                            {t(`collectionLawFirm:5. The law firm and condominium legal services are provided by the company Prime Associates Co., Ltd. They are authorized to issue demand letters for common area maintenance fees before initiating legal proceedings`)}<br/>
                            {t(`collectionLawFirm:The E-Notice system, which users are informed about before confirming their access to the system, states that when they grant power of attorney to the attorney to issue demand letters through the E-Notice system, if there is a number of homeowners`)}ระบบE-Notice ซึ่งผู้ใช้บริการได้รับทราบก่อนยืนยันเข้าใช้ระบบแล้วว่า เมื่อได้มอบอำนาจให้ทางทนายความออกเอกสารทวงถามผ่านระบบ E-Notice แล้ว หากมีจำนวนลูกบ้าน <br/>
                            {t(`collectionLawFirm:or co-owners who have not yet paid their common area maintenance fees within the stipulated time frame, the users who have granted power of attorney must inform the service provider for further legal action`)}
                            <div className='pt-6'>
                            {t(`collectionLawFirm:LawNote`)}
                                {/* *หมายเหตุ : ค่าบริการในการฟ้องคดีในศาลชั้นต้น ผู้ใช้บริการจะต้องชำระค่าบริการก่อนการใช้บริการ ทั้งนี้หากมีการใช้บริการฟ้องคดีเกินกว่า 10ราย 
                                ต่อปี ใบเสร็จรับเงินชำระค่าออกเอกสารE-Notice ในปีนั้นๆ สามารถนำมาเป็นส่วนลดค่าบริการทนายความในการฟ้องคดีค่าส่วนกลางในศาลชั้นต้นได้
                                (หากใช้สิทธิส่วนลดแล้วไม่สามารถนำมาใช้ซ้ำ หรือใช้ร่วมกับโปรโมชั่นอื่นของทางแอพพลิเคชั่นsilver man หรือ สำนักงานทนายบ้านและคอนโดได้อีก */}
                            </div>
                            </div>
                        </div>
                        <div className='checkbox-wrapper'>
                        <p><input type='checkbox' className="check-policy" name="accept-1" id="accept-1" required
                            onChange={this.props.handleInputChange}/>
                            <span className='pl-2'>
                            {t(`collectionLawFirm:I've read and consented to all the terms and conditions for using the system to issue demands for common area fees public service fees`)}
                            <br/> {t(`collectionLawFirm:and public utility fees through E-Notice`)}
                            </span>
                           
                            </p>
                        <p><input type='checkbox' className="check-policy" name="accept-2" id="accept-2" required
                            onChange={this.props.handleInputChange2}/><span className='pl-2'> {t(`collectionLawFirm:I am glad to receive Silverman's public relations updates and information`)}</span></p>
                        </div>
                        <div className='row pb-2'>
                            <div className='col-12'>
                                <button type='button' onClick={() => this.props.onSetStepFinish(true)} className="btn btn-primary" style={{width:'80%',height:'48px'}}
                                disabled={!isButtonEnabled} >
                                 {t(`collectionLawFirm:Confirm the application`)} 
                                </button>
                            </div>                         
                        </div>
                    </div>
                    )}
                  </Translation>  
            </>
        );
    }
}

export default ConfrimCollectionLawFrim;