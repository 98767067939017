import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import Navigation from "./navigation";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Translation } from "react-i18next";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import CreditNoteListTable from "./creditNoteListTable";
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";

const allCreditNote = graphql`
  query creditNoteListQuery(
    $status: String!
    $search: String
    $start_date: DateTime
    $end_date: DateTime
    $first: Int
    $last: Int
  ) {
    creditNoteViewer {
      allCreditNote(
        status: $status
        search: $search
        startDate: $start_date
        endDate: $end_date
        first: $first
        last: $last
      ) {
        totalCount
        edges {
          node {
            id
            docNumber
            firstName
            lastName
            payGroup
            contact {
              id
              refNumber
              name
              firstName
              lastName
              typeOfContact
            }
            issuedDate
            price
            status
            description
            added
            updated
            voidRemark
            creator
            version

            creditNoteTransactionRecord{
              edges{
                node{
                  id
                  price
                  status
                  description
                }
              }
            }
            
          }
        }
      }
    }
  }
`;

class CreditNoteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      temp_start_date: null,
      temp_end_date: null,
      end_date: null,
      search: "",
      search_input: "",
      advance_search: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content" > 
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="receive-list">
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("credit_note:credit_note")+ " "+"(CN)"}</span>}
                  </Translation>
                </h3>
              </div>
              <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "accounting_credit_note_create",
                }) && (
                  <Link to="/accounting/income/credit-note/form/select_customer">
                    <button
                      type="button"
                      className="btn btn-primary add float-right"
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                        alt="plus"
                      />
                      <Translation>
                        {(t) => (
                          <span>{t("credit_note:create_credit_note")}</span>
                        )}
                      </Translation>
                    </button>
                  </Link>
                )}
              </div>
            </div>

            <div className="content-inner">
              <Navigation />

              <div className="row mb-2 mt-1">
                <div className="col">
                  <div className="input-group float-right w-auto ml-2">
                    <Translation>
                      {t =>
                        <input
                          type="text"
                          className="form-control input-size col-8"
                          placeholder={t('filterFunction:search')}
                          value={this.state.search_input}
                          name="search"
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                      }
                    </Translation>
                    <button
                      type="submit"
                      className="btn btn-primary form-control search-button"
                      name="search"
                      value={this.state.search_input}
                      onClick={(e) => this.handleSearch(e)}
                    >
                      <Translation>{t => t('filterFunction:search')}</Translation>
                    </button>
                  </div>
                  {/* <button
                    type="button"
                    className="btn btn-outline-secondary float-right"
                    onClick={() =>
                      this.setState({
                        advance_search: !this.state.advance_search,
                      })
                    }
                  >
                    <img
                      src="/images/icons/filter-icon.png"
                      alt="filter-icon"
                    />
                  </button> */}
                  <InlineAdvanceFilter
                    start_date={this.state.temp_start_date}
                    end_date={this.state.temp_end_date}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <CreditNoteListTable
                    query={allCreditNote}
                    search={this.state.search}
                    start_date={this.state.start_date}
                    end_date={this.state.end_date}
                    status={this.props.match.params.status}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default CreditNoteList;
