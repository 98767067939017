/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type trialBalanceListQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
  betweenStartId?: ?string,
  betweenEndId?: ?string,
  before_start_date_year?: ?any,
  before_end_date_year?: ?any,
|};
export type trialBalanceListQueryResponse = {|
  +viewer: ?{|
    +allChart: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |}
      |}>
    |}
  |},
  +allActiveChartOfAccount: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +chartOfAccountCode: string,
        +name: string,
        +totalDebit: ?number,
        +totalCredit: ?number,
        +totalHistoricalDebit: ?number,
        +totalHistoricalCredit: ?number,
        +totalPeriodDebit: ?number,
        +totalPeriodCredit: ?number,
        +beforeTotalDebit: ?number,
        +beforeTotalCredit: ?number,
        +beforeTotalHistoricalDebit: ?number,
        +beforeTotalHistoricalCredit: ?number,
        +beforeTotalPeriodDebit: ?number,
        +beforeTotalPeriodCredit: ?number,
      |}
    |}>
  |},
  +sumAllActiveChartOfAccount: ?any,
  +allAccountProjectManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +tableAccount: ?any,
      |}
    |}>
  |},
|};
export type trialBalanceListQuery = {|
  variables: trialBalanceListQueryVariables,
  response: trialBalanceListQueryResponse,
|};
*/


/*
query trialBalanceListQuery(
  $startDate: DateTime
  $endDate: DateTime
  $betweenStartId: String
  $betweenEndId: String
  $before_start_date_year: DateTime
  $before_end_date_year: DateTime
) {
  viewer {
    allChart: allChartOfAccount {
      edges {
        node {
          id
          name
          chartOfAccountCode
        }
      }
    }
    id
  }
  allActiveChartOfAccount(startDate: $startDate, endDate: $endDate, beforeStartDate: $before_start_date_year, beforeEndDate: $before_end_date_year, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, trialBalanceSelect: true) {
    edges {
      node {
        id
        chartOfAccountCode
        name
        totalDebit
        totalCredit
        totalHistoricalDebit
        totalHistoricalCredit
        totalPeriodDebit
        totalPeriodCredit
        beforeTotalDebit
        beforeTotalCredit
        beforeTotalHistoricalDebit
        beforeTotalHistoricalCredit
        beforeTotalPeriodDebit
        beforeTotalPeriodCredit
      }
    }
  }
  sumAllActiveChartOfAccount(startDate: $startDate, endDate: $endDate, beforeStartDate: $before_start_date_year, beforeEndDate: $before_end_date_year, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, trialBalanceSelect: true)
  allAccountProjectManager {
    edges {
      node {
        id
        tableAccount
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before_end_date_year"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before_start_date_year"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "betweenEndId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "betweenStartId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v9 = {
  "alias": "allChart",
  "args": null,
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "beforeEndDate",
    "variableName": "before_end_date_year"
  },
  {
    "kind": "Variable",
    "name": "beforeStartDate",
    "variableName": "before_start_date_year"
  },
  {
    "kind": "Variable",
    "name": "betweenEndId",
    "variableName": "betweenEndId"
  },
  {
    "kind": "Variable",
    "name": "betweenStartId",
    "variableName": "betweenStartId"
  },
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  },
  {
    "kind": "Literal",
    "name": "trialBalanceSelect",
    "value": true
  }
],
v11 = {
  "alias": null,
  "args": (v10/*: any*/),
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allActiveChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v8/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPeriodDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPeriodCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "beforeTotalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "beforeTotalCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "beforeTotalHistoricalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "beforeTotalHistoricalCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "beforeTotalPeriodDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "beforeTotalPeriodCredit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": (v10/*: any*/),
  "kind": "ScalarField",
  "name": "sumAllActiveChartOfAccount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountProjectManagerNodeConnection",
  "kind": "LinkedField",
  "name": "allAccountProjectManager",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountProjectManagerNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountProjectManagerNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tableAccount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "trialBalanceListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "trialBalanceListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ]
  },
  "params": {
    "cacheID": "626dc6d09f99138bcfc43427bdad2a1b",
    "id": null,
    "metadata": {},
    "name": "trialBalanceListQuery",
    "operationKind": "query",
    "text": "query trialBalanceListQuery(\n  $startDate: DateTime\n  $endDate: DateTime\n  $betweenStartId: String\n  $betweenEndId: String\n  $before_start_date_year: DateTime\n  $before_end_date_year: DateTime\n) {\n  viewer {\n    allChart: allChartOfAccount {\n      edges {\n        node {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n    id\n  }\n  allActiveChartOfAccount(startDate: $startDate, endDate: $endDate, beforeStartDate: $before_start_date_year, beforeEndDate: $before_end_date_year, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, trialBalanceSelect: true) {\n    edges {\n      node {\n        id\n        chartOfAccountCode\n        name\n        totalDebit\n        totalCredit\n        totalHistoricalDebit\n        totalHistoricalCredit\n        totalPeriodDebit\n        totalPeriodCredit\n        beforeTotalDebit\n        beforeTotalCredit\n        beforeTotalHistoricalDebit\n        beforeTotalHistoricalCredit\n        beforeTotalPeriodDebit\n        beforeTotalPeriodCredit\n      }\n    }\n  }\n  sumAllActiveChartOfAccount(startDate: $startDate, endDate: $endDate, beforeStartDate: $before_start_date_year, beforeEndDate: $before_end_date_year, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, trialBalanceSelect: true)\n  allAccountProjectManager {\n    edges {\n      node {\n        id\n        tableAccount\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cbe8f986b8a81741bff2b33e0bd85267';

module.exports = node;
