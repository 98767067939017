import "../styles/createBudget.scss";
import React, { forwardRef } from "react";
import Swal from "sweetalert2";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { setYear, addMonths, format, setMonth } from "date-fns";
import { Redirect } from "react-router";
import Loading from "../../../libs/loading";
import EditBudgetMutations from "../budgetDraft/mutations/editBudgetMutations";
import code from "../json/chart_of_account.json";
import numberWithCommas from "../../../libs/numberWithComma";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import $ from "jquery"
import i18next from "i18next";
import BackButtonIcon from "../../../components/BackBtn/indexBack";

const query = graphql`
  query editBudgetQuery($yearId: String) {
    oneYearBudget(yearId: $yearId) {
      edges {
        node {
          id
          years
          startDate
          endDate
          chartOfAccountBudget {
            edges {
              node {
                totalBudget
                chartOfAccount {
                  id
                  name
                  nameEn
                  chartOfAccountCode
                }
                monthBudget {
                  edges {
                    node {
                      months
                      budget
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

class CreateBudget extends React.Component {
  constructor(pros) {
    super(pros);
    this.state = {
      lists: _.cloneDeep(code),
      loading: true,
      year: new Date(),
      start_date: new Date(2021, 0, 1),
      end_date: new Date(2021, 11, 1),
      list_month: [],
      value: [],
      save: false,
      redirectToList: false,
      disabled_table: false,
      id: "",
      year_search: this.props.match.params.id,
      widthTable1: 0,
      permissionEdit: _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_edit' }) ?
        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_edit' }) : false
    };
  }

  handleSearch = () => {
    // if (this.state.disabled_table) {
    //   checkRangeYearBudget({ startDate: format(this.state.start_date, "YYYY-MM-DD"), endDate: format(this.state.end_date, "YYYY-MM-DD") }, (data) => {
    //     if (data) {
    //       if (data.checkRangeYearBudget) {
    //         this.getData();
    //       } else {
    //         Swal.fire("ช่วงวันที่มีการใช้งานไปแล้ว กรุณาเลือกใหม่", "", "warning");
    //       }
    //     } else {
    //       console.log("error ");
    //     }
    //   });
    // } else {
    //   this.setState({ disabled_table: !this.state.disabled_table, lists: code });
    // }
  };
  componentDidMount() {
    this.getData();
    let width = $('table');
    this.setState({ widthTable1: width.width() })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.widthTable1 !== this.state.widthTable1 || prevState.loading !== this.state.loading) {
      let width = $('table');
      this.setState({ widthTable1: width.width() })
    }
  }

  getData = async () => {
    let budget;
    await fetchQuery(environment, query, {
      yearId: this.state.year_search,
    })
      .then((data) => {
        budget = data;
      })
      .catch((error) => { });

    let start = new Date(budget.oneYearBudget.edges[0].node.startDate);
    let end = addMonths(new Date(budget.oneYearBudget.edges[0].node.endDate), 1);
    let list = _.cloneDeep(this.state.lists)
    let list_month = [];
    do {
      list_month.push(start);
      start = addMonths(start, 1);
    } while (format(start, "MM-YYYY") !== format(end, "MM-YYYY"));

    for (const item of budget.oneYearBudget.edges[0].node.chartOfAccountBudget.edges) {
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 1) === "4") {
        list[4].push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "51") {
        list[5][0].child.push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "52") {
        list[5][1].child.push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "53" && item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) !== "531") {
        list[5][2].child.push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "531") {
        list[5][3].child.push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "54") {
        list[5][4].child.push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "55") {
        list[5][5].child.push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "56") {
        list[5][6].child.push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "57") {
        list[5][7].child.push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "581") {
        list[5][8].child.push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "582") {
        list[5][8].child.push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "583") {
        list[5][8].child.push({
          ...item.node.chartOfAccount,
          month_budget: item.node.monthBudget.edges,
          total_budget: item.node.totalBudget || "",
        });
      }
    }
    this.setState({
      lists: list,
      loading: false,
      list_month,
      year: new Date(budget.oneYearBudget.edges[0].node.years),
      start_date: new Date(budget.oneYearBudget.edges[0].node.startDate),
      end_date: new Date(budget.oneYearBudget.edges[0].node.endDate),
      id: budget.oneYearBudget.edges[0].node.id,
    });
  };

  handleChange = (e, chartOfAccountCode, main_code, sub_code) => {
    const { name, value } = e.target;
    let lists = _.cloneDeep(this.state.lists);
    let update;
    if (main_code === 4) {
      update = lists[main_code].map((item) => {
        if (chartOfAccountCode === item.chartOfAccountCode) {
          let total_budget = 0;
          let month_budget = _.cloneDeep(item.month_budget);
          month_budget.map((month) => {
            if (month.node.months === name) {
              let sub = parseFloat(value);
              sub = sub.toString();
              if (sub.indexOf(".") >= 0) {
                sub = sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3);
              } else {
                sub = sub;
              }
              month.node.budget = parseFloat(sub);
            }
            if (month.node.budget !== "") {
              total_budget = parseFloat(total_budget) + parseFloat(month.node.budget || 0);
            }
          });
          item.month_budget = month_budget;
          item.total_budget = total_budget;
        }
        return item;
      });
    } else {
      update = lists[main_code][sub_code].child.map((item) => {
        if (chartOfAccountCode === item.chartOfAccountCode) {
          let total_budget = 0;
          let month_budget = _.cloneDeep(item.month_budget);
          month_budget.map((month) => {
            if (month.node.months === name) {
              let sub = parseFloat(value);
              sub = sub.toString();
              if (sub.indexOf(".") >= 0) {
                sub = sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3);
              } else {
                sub = sub;
              }
              month.node.budget = parseFloat(sub);
            }
            if (month.node.budget !== "") {
              total_budget = parseFloat(total_budget) + parseFloat(month.node.budget || 0);
            }
          });
          item.month_budget = month_budget;
          item.total_budget = total_budget;
        }
        return item;
      });
    }

    if (main_code === 4) {
      this.setState({
        lists: { ...this.state.lists, [main_code]: update },
      });
    } else {
      this.setState({
        lists: {
          ...this.state.lists,
          [main_code]: {
            ...this.state.lists[main_code],
            [sub_code]: {
              ...this.state.lists[main_code][sub_code],
              child: update,
            },
          },
        },
      });
    }
  };

  handleChangeSum = (e, chartOfAccountCode, main_code, sub_code) => {
    const { name, value } = e.target;
    let lists = _.cloneDeep(this.state.lists);
    let update;
    if (main_code === 4) {
      update = lists[main_code].map((item) => {
        if (chartOfAccountCode === item.chartOfAccountCode) {
          let total_budget = value;
          let month_budget = _.cloneDeep(item.month_budget);
          let sum = value / item.month_budget.length;
          month_budget.map((month, index) => {
            if (value === "") {
              month.node.budget = "";
            } else {
              let sub = parseFloat(sum);
              sub = sub.toString();
              if (sub.indexOf(".") >= 0) {
                if (index === item.month_budget.length - 1) {
                  let list_index = (value - ((item.month_budget.length - 1) * parseFloat(sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3)))).toString()
                  sub = list_index.substr(0, list_index.indexOf(".")) + list_index.substr(list_index.indexOf("."), 3);
                } else {
                  sub = sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3);
                }
              } else {
                sub = sub;
              }
              month.node.budget = parseFloat(sub);
            }
          });
          item.month_budget = month_budget;
          item.total_budget = total_budget;
        }
        return item;
      });
    } else {
      update = lists[main_code][sub_code].child.map((item) => {
        if (chartOfAccountCode === item.chartOfAccountCode) {
          let total_budget = value;
          let month_budget = _.cloneDeep(item.month_budget);
          let sum = value / item.month_budget.length;

          month_budget.map((month, index) => {
            if (value === "") {
              month.node.budget = "";
            } else {
              let sub = parseFloat(sum);
              sub = sub.toString();
              if (sub.indexOf(".") >= 0) {
                if (index === item.month_budget.length - 1) {
                  let list_index = (value - ((item.month_budget.length - 1) * parseFloat(sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3)))).toString()
                  sub = list_index.substr(0, list_index.indexOf(".")) + list_index.substr(list_index.indexOf("."), 3);
                } else {
                  sub = sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3);
                }
              } else {
                sub = sub;
              }
              month.node.budget = parseFloat(sub);
            }
          });
          item.month_budget = month_budget;
          item.total_budget = total_budget;
        }
        return item;
      });
    }
    if (main_code === 4) {
      this.setState({
        lists: { ...this.state.lists, [main_code]: update },
      });
    } else {
      this.setState({
        lists: {
          ...this.state.lists,
          [main_code]: {
            ...this.state.lists[main_code],
            [sub_code]: {
              ...this.state.lists[main_code][sub_code],
              child: update,
            },
          },
        },
      });
    }
  };

  handleSubmit = () => {
    this.setState({ save: true });
    let data = [];
    _.map(this.state.lists, (item, index) => {
      if (index == 4) {
        _.map(item, (item_2, index) => {
          data.push({
            id: item_2.id,
            name: item_2.name,
            nameEn: item_2.nameEn,
            chartOfAccountCode: item_2.chartOfAccountCode,
            totalHistoricalDebit: item_2?.totalHistoricalDebit,
            totalHistoricalCredit: item_2?.totalHistoricalCredit,
            total_budget: item_2.total_budget,
            month_budget: _.map(item_2.month_budget, (month, index) => {
              return {
                budget: month.node.budget,
                month_date: month.node.months,
              };
            }),
          });
        });
      } else if (index == 5) {
        _.map(item, (item_3, index) => {
          _.map(item_3.child, (item_4, index) => {
            data.push({
              id: item_4.id,
              name: item_4.name,
              nameEn: item_4.nameEn,
              chartOfAccountCode: item_4.chartOfAccountCode,
              totalHistoricalDebit: item_4?.totalHistoricalDebit,
              totalHistoricalCredit: item_4?.totalHistoricalCredit,
              total_budget: item_4.total_budget,
              month_budget: _.map(item_4.month_budget, (month, index) => {
                return {
                  budget: month.node.budget,
                  month_date: month.node.months,
                };
              }),
            });
          });
        });
      }
    });
    let variables = {
      input: {
        id: this.state.id,
        budgetPayload: {
          years: format(this.state.year, "YYYY"),
          start_date: format(this.state.start_date, "YYYY-MM-DD"),
          end_date: format(this.state.end_date, "YYYY-MM-DD"),
          chart_of_account_budget: data,
          status: "approve",
        },
      },
    };
    EditBudgetMutations(
      variables,
      () => {
        this.setState({ save: false });
        Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => {
          this.setState({ redirectToList: true });
        });
      },
      () => {
        this.setState({ save: false });
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error");
      }
    );
  };

  toFixedNumber = (value) => {
    let sub = parseFloat(value);
    sub = sub.toString();
    if (sub.indexOf(".") >= 0) {
      return sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3);
    } else {
      return sub;
    }
  };
  toFixedNumberSum = (value) => {
    return numberWithCommas(value);
  };

  toggleHandleScroll = (scroll) => {
    let scroller = document.querySelector('.table-responsive2');
    let table = document.querySelector('.table-responsive');

    if (scroll === "table-responsive2") {
      table.scrollTo(scroller.scrollLeft, 0);
    } if (scroll === "table-responsive") {
      scroller.scrollTo(table.scrollLeft, 0);
    }
  }

  render() {
    let last_index = 0;
    if (this.state.redirectToList) {
      return <Redirect to="/accounting/budget/list/all" />;
    }
    if (this.state.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    let total_month_income = {};
    let total_income = null;
    let expenses_type = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
    let expenses_type_sum = [null, null, null, null, null, null, null, null, null];
    let total_month_expenses = {};
    let total_expenses = null;
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box budget p-4">
            <BackButtonIcon
              LinkBack={"/accounting/budget/list/all"}
              LinkText={i18next.t("menuListBudget:Record Budget Setting")}
            />
            <div className="d-flex mt-3 ">
              <div className="form-row pl-3 col-12 ">
                <div className="col-2 customDatePickerWidth ">
                  <div>{i18next.t("budgetAllList:Select Budget Year")}</div>
                  <DatePicker
                    className="form-control float-left colorborder"
                    selected={this.state.year}
                    onChange={(date) =>
                      this.setState({
                        year: date,
                        start_date: setMonth(date, 0),
                        end_date: setMonth(date, 11),
                      })
                    }
                    showYearPicker
                    locale="th-TH"
                    dateFormat="yyyy"
                    customInput={<ButtonDate date />}
                    disabled={!this.state.disabled_table}
                  />
                </div>
                <div className="col-2 customDatePickerWidth ml-3 ">
                  <div>{i18next.t("budgetAllList:Start Date of Budget Year")}</div>
                  <DatePicker
                    className="form-control float-left colorborder"
                    selected={this.state.start_date}
                    onChange={(date) => this.setState({ start_date: date })}
                    dateFormat="dd/MM/yyyy"
                    customInput={<ButtonDate date />}
                    minDate={setYear(new Date(2021, 0, 1), this.state.year.getFullYear())}
                    disabled={!this.state.disabled_table}
                  />
                </div>
                <div className="col-2 customDatePickerWidth ">
                  <div>{i18next.t("budgetAllList:End Date of Budget Year")}</div>
                  <DatePicker
                    className="form-control float-left colorborder"
                    selected={this.state.end_date}
                    onChange={(date) => this.setState({ end_date: date })}
                    dateFormat="dd/MM/yyyy"
                    customInput={<ButtonDate date />}
                    minDate={this.state.start_date}
                    maxDate={setYear(new Date(2021, 11, 31), this.state.year.getFullYear())}
                    disabled={!this.state.disabled_table}
                  />
                </div>
                {/* <div className="row align-items-end ml-3">
                  <button
                    type="button"
                    className={this.state.disabled_table ? "btn btn-primary add " : "btn btn-secondary add "}
                    style={{ height: 48 }}
                  // onClick={this.handleSearch}
                  >
                    {this.state.disabled_table ? "สร้างตาราง" : "เปลี่ยนแปลงวันที่"}
                  </button>
                </div> */}
              </div>
            </div>
            <React.Fragment>
              <div className="card fade-up mt-4 ">
                <div className="table-responsive2" style={{ overflowX: "auto", width: "100%", display: "block" }} onScroll={() => this.toggleHandleScroll("table-responsive2")}>
                  <div className="table2" style={{ width: `${this.state.widthTable1}px`, height: "1px" }}></div>
                </div>
                <div className="table-responsive" onScroll={() => this.toggleHandleScroll("table-responsive")}>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th rowSpan={2} className="text-center">
                          {i18next.t("budgetAllList:Sequence")}
                        </th>
                        <th rowSpan={2} className="text-center">
                          {i18next.t("budgetAllList:Item")}
                        </th>
                        <th colSpan={this.state.list_month.length} className="text-center">
                          {i18next.t("budgetAllList:Monthly Budget")}
                        </th>
                        <th rowSpan={2} className="text-center">
                          {i18next.t("budgetAllList:Total Budget")}
                        </th>
                      </tr>
                      <tr className="border-top">
                        {this.state.list_month.map((item, index) => {
                          return (
                            <th key={index} className="text-center">
                              {i18next.t("monthNameShort:"+format(item, "MMM"))} {format(item, "YY")} 
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(this.state.lists[4], (item, index) => {
                        ++last_index;
                        if (item.total_budget !== "") {
                          total_income += parseFloat(item.total_budget);
                        }
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td className="text-center">{last_index}</td>
                              <td
                                style={{
                                  paddingLeft: 50,
                                  minWidth: 300,
                                }}>
                                {index == 0 && <div style={{ marginLeft: -32 }}>รายรับ</div>}
                                {item.name}
                              </td>
                              {item.month_budget.map((month, index_month) => {
                                let sum = total_month_income[index_month] || 0;
                                total_month_income[index_month] = parseFloat(sum) + parseFloat(month.node.budget || 0);
                                return (
                                  <td key={index_month} style={{ width: 1000 }}>
                                    <input
                                      type="number"
                                      className="form-control text-center "
                                      style={{ maxHeight: 24 }}
                                      value={month.node.budget || ""}
                                      onChange={(e) => this.handleChange(e, item.chartOfAccountCode, 4)}
                                      name={month.node.months}
                                      disabled={!this.state.permissionEdit}
                                    />
                                  </td>
                                );
                              })}

                              <td>
                                <input
                                  type="number"
                                  className="form-control text-center"
                                  style={{ maxHeight: 24, minWidth: 200 }}
                                  value={this.toFixedNumber(item.total_budget) || ""}
                                  name={item.chartOfAccountCode}
                                  disabled={!this.state.permissionEdit}
                                  // disabled
                                  onChange={(e) => this.handleChangeSum(e, item.chartOfAccountCode, 4)}
                                />
                              </td>
                            </tr>
                            {index === this.state.lists[4].length - 1 && (
                              <tr className="td-sum ">
                                <td></td>
                                <td
                                  style={{
                                    paddingLeft: 50,
                                    minWidth: 300,
                                  }}>
                                  รวมรายรับ
                                </td>
                                {this.state.list_month.map((item, index) => {
                                  return (
                                    <td key={index} height={24} className="text-center">
                                      {this.toFixedNumberSum(total_month_income[index])}
                                    </td>
                                  );
                                })}
                                <td className="text-center">{this.toFixedNumberSum(total_income)}</td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                      {_.map(this.state.lists[5], (item, index_code) => {
                        return (
                          <React.Fragment key={index_code}>
                            <tr>
                              <td></td>
                              <td>
                                <div className="ml-2">
                                  {index_code == 0 && <div>รายจ่าย</div>}
                                  <div>{item.name}</div>
                                </div>
                              </td>
                            </tr>
                            {_.map(item.child, (item_child, index) => {
                              ++last_index;
                              if (item_child.total_budget !== "") {
                                expenses_type_sum[index_code] += parseFloat(item_child.total_budget);
                                total_expenses += parseFloat(item_child.total_budget);
                              }
                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td className="text-center">{last_index}</td>
                                    <td
                                      style={{
                                        paddingLeft: 50,
                                        minWidth: 300,
                                      }}>
                                      {item_child.name}
                                    </td>
                                    {item_child.month_budget.map((month, index_month) => {
                                      if (month.node.budget !== "" && month.node.budget !== null) {
                                        let sum = parseFloat(expenses_type[index_code][index_month] || 0);
                                        expenses_type[index_code][index_month] = sum + parseFloat(month.node.budget);
                                      }
                                      let sum_all = parseFloat(total_month_expenses[index_month] || 0);
                                      total_month_expenses[index_month] = sum_all + parseFloat(month.node.budget || 0);
                                      return (
                                        <td key={index_month} height={24}>
                                          <input
                                            type="number"
                                            className="form-control text-center "
                                            style={{ maxHeight: 24 }}
                                            value={this.toFixedNumber(month.node.budget) || ""}
                                            onChange={(e) => this.handleChange(e, item_child.chartOfAccountCode, 5, index_code)}
                                            name={month.node.months}
                                            disabled={!this.state.permissionEdit}
                                          />
                                        </td>
                                      );
                                    })}

                                    <td>
                                      <input
                                        type="number"
                                        className="form-control text-center"
                                        style={{ maxHeight: 24 }}
                                        onChange={(e) => this.handleChangeSum(e, item_child.chartOfAccountCode, 5, index_code)}
                                        value={item_child.total_budget || ""}
                                        disabled={!this.state.permissionEdit}
                                      />
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}

                            <tr className="td-sum ">
                              <td></td>
                              <td
                                style={{
                                  paddingLeft: 50,
                                  minWidth: 300,
                                }}>
                                รวม{item.name}
                              </td>
                              {this.state.list_month.map((item, index) => {
                                return (
                                  <td key={index} height={24} className="text-center">
                                    {this.toFixedNumberSum(expenses_type[index_code][index])}
                                  </td>
                                );
                              })}
                              <td className="text-center">{this.toFixedNumberSum(expenses_type_sum[index_code])}</td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                      <tr className="td-sum2 ">
                        <td></td>
                        <td
                          style={{
                            paddingLeft: 50,
                            minWidth: 300,
                          }}>
                          รวมรายจ่าย
                        </td>
                        {this.state.list_month.map((item, index) => {
                          return (
                            <td key={index} height={24} className="text-center">
                              {this.toFixedNumberSum(total_month_expenses[index])}
                            </td>
                          );
                        })}
                        <td className="text-center">{this.toFixedNumberSum(total_expenses)}</td>
                      </tr>
                      <tr className="td-sum3 ">
                        <td></td>
                        <td
                          style={{
                            paddingLeft: 50,
                            minWidth: 300,
                          }}>
                          รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด
                        </td>
                        {this.state.list_month.map((item, index) => {
                          return (
                            <td key={index} height={24} className="text-center">
                              {/* {total_month_income[index] - total_month_expenses[index] < 0
                                ? `(${this.toFixedNumber(total_month_income[index] - total_month_expenses[index]) * -1})`
                                : this.toFixedNumber(total_month_income[index] - total_month_expenses[index])} */}
                              {this.toFixedNumberSum(total_month_income[index] - total_month_expenses[index])}
                            </td>
                          );
                        })}
                        <td className="text-center">
                          {this.toFixedNumberSum(total_income - total_expenses)}
                          {/* {(total_income - total_expenses < 0
                            ? `(${this.toFixedNumber(total_income - total_expenses) * -1})`
                            : this.toFixedNumber(total_income - total_expenses)) || 0} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex justify-content-end mb-2  mt-3 ">
                {/* <button type="button" className="btn btn-primary add float-right " onClick={this.handleSubmit} disabled={this.state.save}>
                  บันทึก
                </button> */}
                {this.state.permissionEdit &&
                  <button
                    disabled={this.state.save}
                    type="button"
                    className="btn btn-primary add float-right"
                    onClick={this.handleSubmit}
                  >
                    {this.state.save
                      ?
                      <span style={{ color: '#FFFFFF' }} >
                        <span className="spinner-border spinner-border-sm align-middle mr-2 text-light" /> {i18next.t("Allaction:waitingSaving")}
                      </span> : <span style={{ color: '#FFFFFF' }}>{i18next.t("Allaction:save")}</span>
                    }
                  </button>
                }
              </div>
            </React.Fragment>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

const ButtonDate = forwardRef(({ value, onClick, disabled, date }, ref) => (
  <button type="button" className="date form-control mt-2 booking-form" style={{ width: "100%" }} onClick={onClick} ref={ref} disabled={disabled}>
    {value}
    {date && (
      <div
        className="calendars"
        style={{
          position: "absolute",
          right: 0,
          marginRight: 8,
        }}>
        <img className="icon" src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"} alt="calendars" />
      </div>
    )}
  </button>
));

export default CreateBudget;
