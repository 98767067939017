import React, { Component } from 'react'
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import _ from "lodash";
import VoidNotRenewContractExpense from "../mutation/voidNotRenewContractExpense"

import "../style/contractExpense.scss"
import i18next from 'i18next';

export default class modalVoid extends Component {

  constructor(props){
    super(props)

    this.state = {
      checkRadio: {title: "", detail: ""},
      textWarn: false,
    }
  }

  componentDidMount() {    
    if(this.props.open){
      document.body.style.overflow = 'hidden';
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  checkTextContentHead = (statusVoidInput) => {
    let textResult = ""

    switch (statusVoidInput) {
      case "not_renew":
        textResult = (<p className="text-center textContentHead">{i18next.t("VoidContract:Please specify the reason for not renewing the contract number.")+` ${this.props.docNumber}`}</p>)
        break;
      case "void":
        textResult = (<p className="text-center textContentHead">{i18next.t("VoidContract:Please choose the reason for terminating contract number")+` ${this.props.docNumber}`}</p>)
        break;

      default:
        break;
    }

    return textResult
  }

  handleCheckRadio = (e) => {

    let { name, value } = e.target
    let textDetail = ""

    switch (value) {
      case "pastDeadline":
        textDetail = i18next.t("VoidContract:Overdue")
        break;
      case "notSpecs":
        textDetail = i18next.t("VoidContract:Poor quality / Do not conform to standards")
        break;
      case "breachOfAgreement":
        textDetail =  i18next.t("VoidContract:Breach of contract")
        break;
      case "terminateContract":
        textDetail = i18next.t("VoidContract:The contracting party terminates the contract.")
        break;
      case "other":
        textDetail = ""
        break;

      default:
        break;
    }
    
    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), "checkRadio.detail", textDetail)
    });
    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, value)
    });
  }

  handleChangeInput = (e) => {

    let { name, value } = e.target

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, value)
    });
  }

  handleConfirm = () => {
    if(this.state.checkRadio.detail){
      this.setState({textWarn: false})
      let textVoidSuccess = ""
      let textVoidUnSuccess = ""

      if(this.props.statusVoid === "void"){
        textVoidSuccess = "ยกเลิกสำเร็จ!"
        textVoidUnSuccess = "ยกเลิกไม่สำเร็จ!"
      }else if(this.props.statusVoid === "not_renew"){
        textVoidSuccess = "ยกเลิกต่อสัญญาสำเร็จ!"
        textVoidUnSuccess = "ยกเลิกต่อสัญญาไม่สำเร็จ!"
      }

      let vilableVoid = {
        input: {
          contractExpenseId: this.props.contractExpenseId,
          voidStatus: this.props.statusVoid,
          voidRemark: this.state.checkRadio.detail
        }
      }

      VoidNotRenewContractExpense(
        vilableVoid, 
        (res) => { 
          if(res.voidNotRenewContractExpense.ok){
            Swal.fire(textVoidSuccess, '', 'success').then(() => {
              this.props.handleChangeInput({
                target : {
                  name: "redirectTo",
                  value: "/accounting/expense/contract_expese/list/all"
                }
              })
            })

          }else{
            Swal.fire(textVoidUnSuccess, i18next.t("Allaction:Please try again."), 'warning')
          }
        }, 
        () => { Swal.fire(textVoidUnSuccess, '', 'warning')}
      )

    }else{
      this.setState({textWarn: true})
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.open}
          onRequestClose={this.props.handleCloseModal}
          ariaHideApp={false}
          style={{
            content: { 
              width : "640px",
              height: "436px",
              top : '50%',
              left : '50%',
              right : 'auto',
              bottom : 'auto',
              marginRight : '-50%',
              transform : 'translate(-50%, -50%)',
              padding : '2%'
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }
          }}
          >
          <div id="modalVoid">
            <div className="d-flex justify-content-center">
              <img className="my-4 " src="/images/iconAlerts/iconAlert-Blue.png" alt="icon-blue"/>
            </div>

            {this.checkTextContentHead(this.props.statusVoid)}

            <div className="bodyContent mb-5">
              <div className="d-flex align-items-center">
                <input 
                  type="radio"
                  id="pastDeadline" name="checkRadio.title"
                  value="pastDeadline"
                  checked={this.state.checkRadio.title === "pastDeadline"}
                  onChange={this.handleCheckRadio}
                  /> 
                <span className="mx-4 textBodyContent">{i18next.t("VoidContract:Overdue")}</span>
              </div>
              <div className="d-flex align-items-center">
                <input 
                  type="radio"
                  id="notSpecs" name="checkRadio.title"
                  value="notSpecs"
                  checked={this.state.checkRadio.title === "notSpecs"}
                  onChange={this.handleCheckRadio}
                  /> 
                <span className="mx-4 textBodyContent">{i18next.t("VoidContract:Poor quality / Do not conform to standards")}</span>
              </div>
              <div className="d-flex align-items-center">
                <input 
                  type="radio"
                  id="breachOfAgreement" name="checkRadio.title"
                  value="breachOfAgreement"
                  checked={this.state.checkRadio.title === "breachOfAgreement"}
                  onChange={this.handleCheckRadio}
                  /> 
                <span className="mx-4 textBodyContent">{i18next.t("VoidContract:Breach of contract")}</span>
              </div>
              <div className="d-flex align-items-center">
                <input 
                  type="radio"
                  id="terminateContract" name="checkRadio.title"
                  value="terminateContract"
                  checked={this.state.checkRadio.title === "terminateContract"}
                  onChange={this.handleCheckRadio}
                  /> 
                <span className="mx-4 textBodyContent">{i18next.t("VoidContract:The contracting party terminates the contract.")}</span>
              </div>
              <div className="d-flex align-items-center">
                <input 
                  type="radio"
                  id="other" name="checkRadio.title"
                  value="other"
                  checked={this.state.checkRadio.title === "other"}
                  onChange={this.handleCheckRadio}
                  />
                <span className="mx-4 textBodyContent">{i18next.t("VoidContract:Other reason")}</span>
                <input type="text"
                  disabled={this.state.checkRadio.title !== "other"}
                  className="w-50"
                  name="checkRadio.detail" 
                  value={ 
                    this.state.checkRadio.title === 'other' 
                    ? this.state.checkRadio.detail : ""
                  }
                  onChange={this.handleChangeInput}
                  />
              </div>
            </div>

            {this.state.textWarn &&
              <div className="bgWarn d-flex justify-content-center align-items-center mb-2">
                <span className="textWarn">{i18next.t("VoidContract:Please fill out a note")}  !</span>
              </div>
            }

            <div className="d-flex justify-content-center">
              <button type="button" className="btnConfirm" onClick={this.handleConfirm}>
                <span>{i18next.t("VoidContract:Confirm")}</span>
              </button>
              <button type="button" className="btnClose" onClick={this.props.handleCloseModal}>
                <span>{i18next.t("VoidContract:Close")}</span>
              </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
