import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import DailyJournal from './dailyJournal';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperDailyJournalQuery($id: ID!, $ref_transaction: String) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            taxIdNumber
            juristicContactNumber
            logo
        }
        allAccountRecordGroup(id:$id) {
            edges {
                node {
                    id
                    refNumber
                    name
                    added
                    issuedDate
                    refPayExpenseDate
                    category
                    totalDebit
                    totalCredit
                    refTransaction
                    creator
                    contact {
                        id
                        name
                        firstName
                        lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id
                                name
                                debit
                                credit
                                chartOfAccountCode {
                                    id
                                    name
                                    chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }
        cashTransactionViewer{
            allCashTransaction(refNumber: $ref_transaction){
                edges{
                    node{
                        id
                        description
                        price
                    }
                }
            }
        }

        chequeDepositViewer{
            allChequeDeposit(refNumber: $ref_transaction){
                edges{
                    node{
                        id
                        price
                        date
                        chequeNumber
                        bankName
                        branchNumber
                    }
                }
            }
        }

        chequeTransactionViewer{
            allChequeTransaction(refNumber: $ref_transaction){
                edges{
                    node{
                        id
                        price
                        date
                        chequeNumber
                        bankAccount {
                            accountName
                            branch
                            bankName
                        }
                    }
                }
            }
        }

        allBankAccountTransaction(refTransaction: $ref_transaction) {
            edges{
                node{
                    id
                    price
                    date
                    refTransaction
                    bankAccount {
                        bankName
                        accountNumber
                    }
                }
            }
        }

        allCreditCardTransaction(refNumber: $ref_transaction){
            edges{
                node{
                    id
                    amount
                }
            }
        }

        allPaymentGatewayMethod(receive_DocNumber: $ref_transaction){
            edges{
                node{
                    id
                    paymentType
                    paymentGateway{
                        id
                        paymentType
                    }
                    amount
                    feeAmount
                    total
                    depositDate
                    image
                }
            }
        }
        
        paymentChannelViewer{
            allPaymentChannel(slug:"petty_cash",payRecordGroup_DocNumber:$ref_transaction){
                edges{
                    node{
                        id
                        date
                        payRecordGroup{
                            id
                            docNumber
                            issuedDate
                        }
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                        price
                    }
                }
            }
        }
        otherExpensePaymentChannelViewer{
            allOtherExpensePaymentChannel(otherExpense_DocNumber:$ref_transaction){
                edges{
                    node{
                        id
                        price
                        date
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                        rdCash{
                            docNumber 
                        }
                    }
                }
            }
        }

        allAdvancePettyCashChannel(advance_DocNumber:$ref_transaction){
            edges{
                node{
                    id
                    price
                    date
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }
        
        allClearAdvancePettyCashChannel(clearAdvance_DocNumber:$ref_transaction){
            edges{
                node{
                    id
                    price
                    date
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }
        allClearGuaranteeMoneyReceivedPettyCashChannel(clearGuaranteeMoneyReceived_DocNumber:$ref_transaction){
            edges{
                node{
                    id
                    price
                    date
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }
        userConfig {
            id languages
        }
    }
`;

class WrapperDailyJournal extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.adjustHeight = this.adjustHeight.bind(this);
    }

    print() {
        window.print();
    }

    adjustHeight() {

    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>DailyJournal</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{id: this.props.match.params.id, ref_transaction: this.props.match.params.ref_transaction}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return(
                                <DailyJournal 
                                    query={props}
                                    languages={props.userConfig.languages}
                                />
                            )
                        }
                         return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperDailyJournal;
