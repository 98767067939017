import React, { Component } from 'react';
import Api from '../../../api/sing'
import Swal from 'sweetalert2';
import format from 'date-fns/format';
import _ from 'lodash';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import i18n from 'i18next';
import i18next from 'i18next';

class ModalworksheetUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingDate: false,
            staff_technician: [],

            id: this.props.worksheet?.id,
            repairs_room_id: this.props.worksheet?.repairs_room,
            working_type: this.props.worksheet?.working_type || "", //repair,improvement,other
            technician: this.props.worksheet?.technician || "", //inside , outside
            project_staff_id: this.props.worksheet?.project_staff?.id,
            project_staff_other: this.props.worksheet?.project_staff_other,
            phone: this.props.worksheet?.phone,
            description: this.props.worksheet?.description,
            // appointments: new Date(this.props.worksheet?.appointments),
            appointments: this.props.worksheet?.appointments,
            appiontmentTimeSt: format(this.props.worksheet?.appointments, 'HH:mm'),
            appiontmentTimeEn: ""
        }
        this.onChangeData = this.onChangeData.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onChangeData(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentWillMount() {
        this.getDataTechnic();
        // console.log(this.state , this.props.indexID);
    }

    getDataTechnic() {
        try {
            Api.getstaff_technician().then((res) => {
                let data = []
                if (res?.data?.data) {
                    data = res.data.data
                    let dataNewSet = []
                    data.forEach((n, index) => {
                        _.set(dataNewSet, '[' + index + '].value', n.id);
                        _.set(dataNewSet, '[' + index + '].label', n.name_title + n.name_staff + " " + n.last_name_staff);
                    })
                    this.setState({ loading: false, staff_technician: dataNewSet })
                }
            })
        } catch (err) {
            Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error').then(() => {
                this.setState({ loading: false })
            })
        }

    }

    onSubmit(e) {
        e.preventDefault()
        let data = {
            id: this.state.id,
            workingType: this.state.working_type, //repair,improvement,other
            technician: this.state.technician, //inside , outside
            projectStaf: this.state.technician === "inside" ? this.state.project_staff_id : "",
            projectStaffOther: this.state.technician !== "inside" ? this.state.project_staff_other : "",
            phone: this.state.phone,
            description: this.state.description,
            appointments: format(this.state.appointments, "YYYY-MM-DD") + "T" + this.state.appiontmentTimeSt,
            // appointments: this.state.appointments+"T"+ this.state.appiontmentTimeSt,

            // repair_type: this.props.data.repair_type
        }

        // console.log(data);

        Api.repairs_room_work_update(data).then((res) => {
            Swal.fire(i18n.t("Allaction:Saved Successfully"), "", "success").then(() => {
                window.location.reload()
            })
        })
    }

    render() {
        let data = this.props.worksheet

        let filterStaff = this.state.staff_technician.map(item => item.value).indexOf(this.state.project_staff_id)
        let appointments = new Date(this.state.appointments).getTime()
        // console.log(appointments);

        return (
            // <form onSubmit={()=>this.onSubmit(this ,this.props.onClick )}>
            <div className={"modal fade bd-example-modal-lg worksheetFix worksheetFixRequset1" + this.props.onClick} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog  modal-lg" role="document" style={{ width: "750px" }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ padding: "19px 56px" }}>
                            <input type={"text"} className='inputWorkSheet text-center'
                                value={data?.doc_number} readOnly={true}
                            />


                            <h2 className="modal-title text-center" style={{ fontSize: "24px" }}>{i18n.t('PageList:Wroksheet')}</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={this.onSubmit}>
                            <div className="modal-body" style={{ padding: "19px 56px", textAlign: 'left' }}>

                                <div className='row'>
                                    <div className='col-12 claim create'>
                                        <label style={{ color: "#808080", fontWeight: "normal", fontSize: "18px", textAlign: 'left' }}>
                                            {this.props.data?.working_type} : &nbsp;
                                            {this.props.data?.material} ;  &nbsp;
                                            {this.props.data?.details} &nbsp; &nbsp; </label>
                                        {this.props.data?.tag_repairs_room?.map((tag, index) => {
                                            return (
                                                <React.Fragment key={index} >
                                                    <button className={'tag tagBtnBlue'}>
                                                        {tag.tag_global.tag_name}
                                                    </button>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </div>
                                {/* {i18n.t("FixRequestModal:Type")} */}
                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "10px 0" }}>{i18n.t("FixRequestModal:Type")}</label>
                                    <div className='col-9'>
                                        <input type={"text"} onChange={this.onChangeData}
                                            name='workArea' defaultValue={this.props.data.repair_type === "private" ? "ซ่อมส่วนตัว" : "ซ่อมส่วนกลาง"} readOnly={true}
                                            className='form-control inputBoxModal' />
                                    </div>
                                </div>
                                {/* {i18n.t("FixRequestModal: Responsible staff")} */}
                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "10px 0" }}>{i18n.t("FixRequestModal:Responsible staff")}</label>
                                    <div className='col-9'>
                                        <input type={"text"} onChange={this.onChangeData}
                                            name='responsiblePerson' readOnly={true}
                                            defaultValue={"S KLAS(Juristic Person)"}
                                            className='form-control inputBoxModal' />
                                    </div>
                                </div>
                                {/* {i18n.t("FixRequestModal:Work Type")} */}
                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "-1px 0" }}>{i18n.t("FixRequestModal:Work Type")} <span className='text-red'>*</span></label>
                                    <div className='col-9'>
                                        <input type={"checkbox"} name='repair' value={"repair"}
                                            checked={this.state.working_type === "repair"}
                                            onChange={() => this.setState({ working_type: "repair" })} />
                                        <span className='span'> ซ่อมแซม</span>
                                        <input type={"checkbox"} name='improvement' value={"improvement"}
                                            checked={this.state.working_type === "improvement"}
                                            onChange={() => this.setState({ working_type: "improvement" })} />
                                        <span className='span'> ต่อเติม</span>
                                        <input type={"checkbox"} name='other' value={"improvement"}
                                            checked={this.state.working_type === "other"}
                                            onChange={() => this.setState({ working_type: "other" })} />
                                        <span className='span'> {i18n.t("FixRequestModal:etc.")}</span>

                                        <input type={"text"} name='working_type' defaultValue={this.state.working_type} style={{ opacity: 0, position: "absolute" }} />

                                    </div>
                                </div>
                                {/* {i18n.t("FixRequestModal:Technician type")} */}
                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "-1px 0" }}>{i18n.t("FixRequestModal:Technician type")} <span className='text-red'>*</span></label>
                                    <div className='col-9'>
                                        <input type={"radio"} name='technician' value={"inside"}
                                            checked={this.state.technician === "inside"}
                                            onChange={() => this.setState({ technician: "inside" })} required
                                        />
                                        <span className='span'> ช่างในโครงการ</span>
                                        <input type={"radio"} name='technician' value={"outside"}
                                            checked={this.state.technician === "outside"}
                                            onChange={() => this.setState({ technician: "outside" })} required
                                        />
                                        <span className='span'> ช่างนอกโครงการ</span>

                                        <p></p>

                                        {
                                            this.state.technician === "inside" ?
                                                <div style={{ position: 'relative', textAlign: 'left' }}>
                                                    <Select
                                                        style={{ width: '444px', height: '48px' }}
                                                        options={this.state.staff_technician}
                                                        onChange={(e) => this.setState({ project_staff_id: e.value })}
                                                        required={true}
                                                        isSearchable={false}
                                                        placeholder={`${i18n.t("FixRequestModal:Choose the technician of your project")}`}
                                                        value={this.state.staff_technician[filterStaff]}
                                                    />
                                                    <input name="project_staff_id" type="text" value={this.state.project_staff_id}
                                                        style={{ position: "absolute", top: 0, left: 110, width: 5, zIndex: -1 }}
                                                        onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                        required={true} />
                                                </div>

                                                :
                                                <input type={"text"} onChange={this.onChangeData} required
                                                    name='project_staff_other' defaultValue={this.state.project_staff_other} className='form-control inputBoxModal' />
                                        }

                                    </div>
                                </div>
                                {/* เบอร์โทรติดต่อ */}
                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "10px 0" }}>{i18n.t("newBookingSingha_Booking:Contact number")} <span className='text-red'>*</span></label>
                                    <div className='col-9'>
                                        <input type={"text"} onChange={this.onChangeData}
                                            name='phone' defaultValue={this.state.phone}
                                            placeholder={`${i18n.t("newBookingSingha_Booking:Specify phone number")}`} required={true} minLength={9}
                                            maxLength={10} pattern='[0-9]{9,10}'
                                            className='form-control inputBoxModal' />
                                    </div>
                                </div>
                                {/* {i18n.t("FixRequestModal:Appointment Date")} */}
                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "10px 0" }}>{i18n.t("FixRequestModal:Appointment Date")} </label>
                                    <div className='col-9'>
                                        <DatePicker
                                            id="start_date"
                                            className="form-control inputBoxModal"
                                            selected={appointments}
                                            onChange={(date) => this.setState({ appointments: date })}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        {/* <input type={"date"} onChange={this.onChangeData} required
                                            name='appointments' defaultValue={format(this.state.appointments, "YYYY-MM-DD")} className='form-control inputBoxModal' /> */}
                                    </div>
                                </div>
                                {/* {i18n.t("FixRequestModal:Appointment Time")} */}
                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "10px 0" }}>{i18n.t("FixRequestModal:Appointment Time")}</label>
                                    <div className='col-9 text-center'>
                                        <input type={"time"} onChange={this.onChangeData}
                                            defaultValue={format(this.state.appointments, "HH:mm")} name='appiontmentTimeSt'
                                            className='form-control inputBoxModal' />
                                    </div>
                                </div>
                                {/* {i18n.t("FixRequestModal:Additional message")} */}
                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "-1px 0" }}>{i18n.t("FixRequestModal:Additional message")}</label>
                                    <div className='col-9 text-center'>
                                        <textarea onChange={this.onChangeData} name='description'
                                            className='form-control' defaultValue={this.state.description}
                                            style={{ resize: 'none', height: '128px' }}
                                        />
                                    </div>
                                </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className='btn ' style={{ color: "#1567B4" }} data-dismiss="modal" aria-label="Close">{i18n.t("Allaction:cancel")}</button>
                                <button type="submit" className="btn btn-primary">{i18n.t("FixRequestModal:Save")}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            // </form>

        )
    }
}

export default ModalworksheetUpdate

