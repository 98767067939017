import React from "react";
import localStorage from "../../../../libs/localstorage";
import numberWithComma from "../../../../libs/numberWithComma";
import {format} from "date-fns";
import getNameResidential from "../../../../libs/getNameResidential";
import _ from 'lodash';
import i18next from "i18next";
class OtherReceiveReportTable extends React.Component {

    removeTrailingZeros(description){
        if(description.includes("ค่าน้ำประปา")){
            description = description.replace(/ *\[[^)]*\] */g, " ")
            // description = description.replace(/ *\[[^)]*\] */g, " ").replace(/.50 /gi, ".5 ")
            // description = description.replace(/.00 /gi, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }


    getAmountPayment(default_value,price_NotwhtRate){
        if(default_value > 0 && price_NotwhtRate > 0){   
            if(default_value >= price_NotwhtRate){
                return {default_value : (default_value - price_NotwhtRate), price :price_NotwhtRate, price_NotwhtRate : 0 }
            }             
            else {
                return {default_value : 0, price :default_value, price_NotwhtRate : price_NotwhtRate - default_value}
            }
        }
    }



    render() {
        let amount_cash = 0;
        let amount_bank = 0;
        let amount_cheque = 0;
        let amount_other_payment = 0;
        let amount_credit_card = 0;
        let amount_unknown_receive = 0
        let amount_total = 0;
        let accountRecordGroup = this.props.props.allOtherReceiveReport.accountRecordGroupNode.edges;
        return (
            <React.Fragment>
                {this.props.props.allOtherReceive.edges.map((other_receive, index) => {
                    let order_id = this.props.state.first - localStorage.getPageLimit();
                    
                  
                    //ยอดเต็มของแต่ละวิธีชำระเงิน
                    let default_cash = other_receive.node.cashTransaction.totalCount > 0 ? _.sumBy(other_receive.node.cashTransaction.edges,'node.price') : 0;
                    let default_bank = other_receive.node.bankAccountTransaction.totalCount > 0 ? _.sumBy(other_receive.node.bankAccountTransaction.edges,'node.price') : 0;
                    let default_cheque = other_receive.node.chequeDeposit.totalCount > 0 ? _.sumBy(other_receive.node.chequeDeposit.edges,'node.price') : 0;
                    let default_credit_card = other_receive.node.creditCardTransaction.totalCount > 0 ? _.sumBy(other_receive.node.creditCardTransaction.edges,'node.amount') : 0;
                    let default_unknown_receive = 0;

                    let sum_other_receive = other_receive.node.total

                    let cash = 0;
                    let bank = 0;
                    let cheque = 0;
                    let credit_card = 0;
                    let unknown_receive = 0;
                    let showCash,showBank,showCheque,showCreditCard,showUnknownReceive = false;
                    
                    let excess_payment = 0;
                    let lack_payment = 0;

                    //เงินรับรอตรวจสอบ
                    if(other_receive.node.unknownReceive.edges.length > 0){
                        default_unknown_receive += _.sumBy(other_receive.node.unknownReceive.edges,'node.amount')
                    }

                    _.forEach(_.filter(accountRecordGroup,['node.refTransaction',other_receive.node.docNumber]), accountRecordList => {
                        accountRecordList.node.accountRecord.edges.length > 0 && _.forEach(accountRecordList.node.accountRecord.edges , (accountRecord) => {
                            // รับอื่น 5830
                            if(accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("5830")){
                                    let filterOT = _.filter(other_receive.node.otherReceiveTransaction.edges ,(o) => o.node?.accountRecord?.id === accountRecord?.node?.id)[0]
                                    if(filterOT){
                                        excess_payment += (accountRecord.node?.credit - filterOT.node?.total)
                                    }else{
                                        excess_payment += accountRecord.node.credit;
                                    }
                            }
                            //  ส่วนต่างเศษสตางค์
                            if(accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("4300-12")){
                                if(accountRecord.node.credit !== 0){
                                    excess_payment += accountRecord.node.credit
                                }else if(accountRecord.node.debit !== 0){
                                    lack_payment += accountRecord.node.debit
                                }
                            }
                        })
                    })
                    
                    // ค่าธรรมเนียมธนาคาร
                    if(other_receive.node.feeAccounting.edges.length > 0){
                        lack_payment += _.sumBy(other_receive.node.feeAccounting.edges,'node.amount')
                    }

                    let other_payment = 0;
                    other_payment = lack_payment - excess_payment;

                    amount_other_payment += other_payment
                    amount_cash += default_cash
                    amount_bank += default_bank
                    amount_credit_card += default_credit_card
                    amount_cheque += default_cheque
                    amount_unknown_receive += default_unknown_receive


                    if(other_receive.node.status !== "VOID"){
                    return (
                        <React.Fragment key ={'other_receive' + index}>
                            {other_receive.node.otherReceiveTransaction.edges.map((other_transaction, t_index) => {
                                let number_of_transaction = other_receive.node.otherReceiveTransaction.totalCount;
                                let row_number = (t_index === 0 && order_id + index + 1);
                                
                                let price_transaction = other_transaction.node.total;
                                amount_total += other_transaction.node.total

                                //เช็คแสดงแค่บรรทัดที่มีการเรียกใช้วิธีชำระเงินนั้น
                                showUnknownReceive = default_unknown_receive > 0 ? true : false;
                                showCash = default_cash > 0 ? true : false;
                                showBank = default_bank > 0 ? true : false;
                                showCheque = default_cheque > 0 ? true : false;
                                showCreditCard = default_credit_card > 0 ? true : false;

                                // กรณีมีหลายรายการ และเป็นรายการสุดท้ายแล้วมีส่วนอื่นๆ ติดลบ(ส่วนเกิน)
                                if(t_index+1 === number_of_transaction && number_of_transaction!== 1 && other_payment < 0){
                                    if(other_payment < 0){
                                        let total_other_payment = Math.abs(other_payment) + price_transaction
                                        if(default_unknown_receive > 0){ 
                                            //หักลบยอดวิธีชำระเงินที่ใช้โดนยึดจากเงินอื่นๆที่ต้องบวกเพิ่มและจำนวนเงินของรายการ 
                                            let amountPayment = this.getAmountPayment(default_unknown_receive,total_other_payment) 
                                            default_unknown_receive = amountPayment.default_value;
                                            unknown_receive = amountPayment.price;
                                            total_other_payment = amountPayment.price_NotwhtRate
                                        }
                                        if(default_cash > 0){  
                                            let amountPayment = this.getAmountPayment(default_cash,total_other_payment) 
                                            default_cash = amountPayment.default_value;
                                            cash = amountPayment.price;
                                            total_other_payment = amountPayment.price_NotwhtRate
                                        }
                                        if(default_bank > 0 ){
                                            let amountPayment = this.getAmountPayment(default_bank,total_other_payment)
                                            default_bank = amountPayment.default_value;
                                            bank = amountPayment.price;
                                            total_other_payment = amountPayment.price_NotwhtRate 
                                        }
                                        if(default_credit_card > 0){
                                            let amountPayment = this.getAmountPayment(default_credit_card,total_other_payment)
                                            default_credit_card = amountPayment.default_value;
                                            credit_card = amountPayment.price ;
                                            total_other_payment = amountPayment.price_NotwhtRate
                                        }
                                        if(default_cheque > 0){
                                            let amountPayment = this.getAmountPayment(default_cheque,total_other_payment)
                                            default_cheque = amountPayment.default_value;
                                            cheque = amountPayment.price;
                                            total_other_payment = amountPayment.price_NotwhtRate
                                        } 
                                    }
                                //กรณีมีรายการเดียว
                                }else if(t_index+1 === number_of_transaction && number_of_transaction === 1){
                                    if(default_unknown_receive > 0 && price_transaction > 0){  
                                        let amountPayment = this.getAmountPayment(default_unknown_receive,price_transaction) 
                                        default_unknown_receive = amountPayment.default_value;
                                        unknown_receive = amountPayment.price;
                                        price_transaction = amountPayment.price_NotwhtRate
                                    }
                                    if(default_cash > 0 && price_transaction > 0){  
                                        let amountPayment = this.getAmountPayment(default_cash,price_transaction) 
                                        default_cash = amountPayment.default_value;
                                        cash = amountPayment.price;
                                        price_transaction = amountPayment.price_NotwhtRate
                                    }
                                    if(default_bank > 0 && price_transaction > 0){
                                        let amountPayment = this.getAmountPayment(default_bank,price_transaction)
                                        default_bank = amountPayment.default_value;
                                        bank = amountPayment.price;
                                        price_transaction = amountPayment.price_NotwhtRate 
                                    }
                                    if(default_credit_card > 0 && price_transaction > 0){
                                        let amountPayment = this.getAmountPayment(default_credit_card,price_transaction)
                                        default_credit_card = amountPayment.default_value;
                                        credit_card = amountPayment.price;
                                        price_transaction = amountPayment.price_NotwhtRate
                                    }
                                    if(default_cheque > 0 && price_transaction > 0){
                                        let amountPayment = this.getAmountPayment(default_cheque,price_transaction)
                                        default_cheque = amountPayment.default_value;
                                        cheque = amountPayment.price;
                                        price_transaction = amountPayment.price_NotwhtRate
                                    }

                                    if(other_payment < 0){
                                        if(other_receive.node.receiptDeposit.edges.length === 0){
                                            if(default_unknown_receive > 0 ){
                                                unknown_receive += default_unknown_receive;
                                            }
                                            if(default_cash > 0 ){
                                                cash += default_cash;
                                            }
                                            if(default_bank >0){
                                                bank += default_bank
                                            }
                                            if(default_credit_card >0 ){   
                                                credit_card += default_cheque 
                                            }  
                                            if(default_cheque >0 ){   
                                                cheque += default_cheque 
                                            }  
                                        }else{
                                            if(default_unknown_receive > 0 ){
                                                unknown_receive += Math.abs(other_payment);
                                            }
                                            if(default_cash > 0 ){
                                                cash += Math.abs(other_payment);
                                            }
                                            if(default_bank >0){
                                                bank += Math.abs(other_payment);
                                            }
                                            if(default_credit_card >0 ){   
                                                credit_card += Math.abs(other_payment); 
                                            }  
                                            if(default_cheque >0 ){   
                                                cheque += Math.abs(other_payment); 
                                            } 
                                        }
                                    }

                                //กรณีหลายรายการแต่ไม่ใช่บรรทัดสุดท้าย จะทำการหักลบเงินตามค่าของรายการ ไม่รวมส่วนเกิน 
                                }else{
                                    if(default_unknown_receive > 0 && price_transaction > 0){  
                                        let amountPayment = this.getAmountPayment(default_unknown_receive,price_transaction) 
                                        default_unknown_receive = amountPayment.default_value;
                                        unknown_receive = amountPayment.price;
                                        price_transaction = amountPayment.price_NotwhtRate
                                    }
                                    if(default_cash > 0 && price_transaction > 0){  
                                        let amountPayment = this.getAmountPayment(default_cash,price_transaction) 
                                        default_cash = amountPayment.default_value;
                                        cash = amountPayment.price;
                                        price_transaction = amountPayment.price_NotwhtRate
                                    }
                                    if(default_bank > 0 && price_transaction > 0){
                                        let amountPayment = this.getAmountPayment(default_bank,price_transaction)
                                        default_bank = amountPayment.default_value;
                                        bank = amountPayment.price;
                                        price_transaction = amountPayment.price_NotwhtRate 
                                    }
                                    if(default_credit_card > 0 && price_transaction > 0){
                                        let amountPayment = this.getAmountPayment(default_credit_card,price_transaction)
                                        default_credit_card = amountPayment.default_value;
                                        credit_card = amountPayment.price;
                                        price_transaction = amountPayment.price_NotwhtRate
                                    }
                                    if(default_cheque > 0 && price_transaction > 0){
                                        let amountPayment = this.getAmountPayment(default_cheque,price_transaction)
                                        default_cheque = amountPayment.default_value;
                                        cheque = amountPayment.price;
                                        price_transaction = amountPayment.price_NotwhtRate
                                    }


                                }
                                
                                return (
                                    (
                                        <tr key={other_transaction.node.id + t_index}>
                                            <td className="text-center">{row_number}</td>
                                            <td className="text-center">
                                                {t_index === 0 ? other_receive.node.docNumber : ''}
                                            </td>
                                            <td className="text-center">{t_index === 0 ? format(other_receive.node.issuedDate, 'DD/MM/YYYY') : ''}</td>
                                            <td>
                                                {t_index === 0 ? (other_receive.node.contact && other_receive.node.contact.residential ? other_receive.node.contact.residential.name : "-") : ''}
                                            </td>
                                            <td>
                                                {t_index === 0 ? 
                                                other_receive.node.contact ?
                                                    other_receive.node.contact.typeOfContact === "RESIDENTIAL" ?
                                                        getNameResidential(other_receive.node.contact.firstName, other_receive.node.contact.lastName) :
                                                        other_receive.node.contact.name
                                                    :
                                                    other_receive.node.unknownContact ?
                                                        other_receive.node.unknownContact : "-"
                                                : ''}                                                                                                
                                                {" "}                                                                                            
                                                {
                                                    t_index === 0 && (other_receive.node.payGroup === "RENT" || other_receive.node.payGroup === "AGENT" || other_receive.node.payGroup === "OWNER" || other_receive.node.payGroup === "DEVELOPER") &&
                                                    <label className="text-danger">
                                                        ({other_receive.node.payGroup === "RENT" || other_receive.node.payGroup === "AGENT" || other_receive.node.payGroup === "OWNER" || other_receive.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${other_receive.node.payGroup}`) : ""})
                                                    </label>
                                                }   
                                            </td>
                                            
                                            <td>
                                                {t_index === 0 ? 
                                                other_transaction.node.productAndService?.productCode || other_transaction.node.chartOfAccount?.chartOfAccountCode
                                                : ''}
                                            </td>
                                            <td>{
                                                this.removeTrailingZeros(other_transaction.node.description)
                                                }</td>
                                            <td className="text-center">{other_receive.node.cashTransaction.totalCount > 0 &&
                                            <span>{showCash && numberWithComma(cash, "")}</span>}</td>
                                            <td className="text-center">{other_receive.node.bankAccountTransaction.totalCount > 0 &&
                                            <span>{showBank && numberWithComma(bank,"")}</span>}</td>
                                            <td className="text-center">{other_receive.node.chequeDeposit.totalCount > 0 &&
                                            <span>{showCheque && numberWithComma(cheque,"")}</span>}</td>
                                            <td className="text-center">{other_receive.node.creditCardTransaction.totalCount > 0 &&
                                            <span>{showCreditCard && numberWithComma(credit_card,"")}</span>}</td>
                                            <td className="text-center">{unknown_receive > 0 &&
                                            <span>{showUnknownReceive && numberWithComma(unknown_receive,"")}</span>}</td>
                                            <td className="text-center">
                                            <span>{t_index+1 === number_of_transaction &&
                                            numberWithComma(other_payment,"")}
                                            </span> </td>
    
                                            <td className="text-right">{numberWithComma(other_transaction.node.total)}</td>
                                            <td className="text-right">{t_index +1 === number_of_transaction && other_receive.node.receiptDeposit.edges.length === 0  && numberWithComma(sum_other_receive)}</td>
                                            
                                        </tr>
                                    )
                                )
                            })}
                            {other_receive.node.receiptDeposit.edges.length > 0 && other_receive.node.receiptDeposit.edges.map((receipt_deposit,t_index) => {
                                let price_transaction = receipt_deposit.node.total;
                                sum_other_receive += receipt_deposit.node.total;
                                amount_total += receipt_deposit.node.total;

                                showUnknownReceive = default_unknown_receive > 0 ? true : false;
                                showCash = default_cash > 0 ? true : false;
                                showBank = default_bank > 0 ? true : false;
                                showCheque = default_cheque > 0 ? true : false;
                                showCreditCard = default_credit_card > 0 ? true : false;

                                
                                if(default_unknown_receive > 0 && price_transaction > 0){  
                                    let amountPayment = this.getAmountPayment(default_unknown_receive,price_transaction) 
                                    default_unknown_receive = amountPayment.default_value;
                                    unknown_receive = amountPayment.price;
                                    price_transaction = amountPayment.price_NotwhtRate
                                }
                                if(default_cash > 0 && price_transaction > 0){  
                                    let amountPayment = this.getAmountPayment(default_cash,price_transaction) 
                                    default_cash = amountPayment.default_value;
                                    cash = amountPayment.price;
                                    price_transaction = amountPayment.price_NotwhtRate
                                }
                                if(default_bank > 0 && price_transaction > 0){
                                    let amountPayment = this.getAmountPayment(default_bank,price_transaction)
                                    default_bank = amountPayment.default_value;
                                    bank = amountPayment.price;
                                    price_transaction = amountPayment.price_NotwhtRate 
                                }
                                if(default_credit_card > 0 && price_transaction > 0){
                                    let amountPayment = this.getAmountPayment(default_credit_card,price_transaction)
                                    default_credit_card = amountPayment.default_value;
                                    credit_card = amountPayment.price;
                                    price_transaction = amountPayment.price_NotwhtRate
                                }
                                if(default_cheque > 0 && price_transaction > 0){
                                    let amountPayment = this.getAmountPayment(default_cheque,price_transaction)
                                    default_cheque = amountPayment.default_value;
                                    cheque = amountPayment.price;
                                    price_transaction = amountPayment.price_NotwhtRate
                                }

                                return(
                                    <tr key={receipt_deposit.node.id + t_index}>
                                            <td className="text-center"></td>
                                            <td className="text-center"></td>
                                            <td className="text-center"></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{receipt_deposit.node.docNumber +' / ' + receipt_deposit.node.receiptDepositTransaction.edges[0].node.chartOfAccount.chartOfAccountCode + ' ' +
                                                receipt_deposit.node.receiptDepositTransaction.edges[0].node.chartOfAccount.name}</td>
                                            <td className="text-center">{other_receive.node.cashTransaction.totalCount > 0 &&
                                                <span>{showCash && numberWithComma(receipt_deposit.node.total, "")}</span>}</td>
                                            <td className="text-center">{other_receive.node.bankAccountTransaction.totalCount > 0 &&
                                                <span>{showBank && numberWithComma(receipt_deposit.node.total,"")}</span>}</td>
                                            <td className="text-center">{other_receive.node.chequeDeposit.totalCount > 0 &&
                                                <span>{showCheque && numberWithComma(receipt_deposit.node.total,"")}</span>}</td>
                                            <td className="text-center">{other_receive.node.creditCardTransaction.totalCount > 0 &&
                                                <span>{showCreditCard && numberWithComma(receipt_deposit.node.total,"")}</span>}</td>
                                            <td className="text-center">{unknown_receive > 0 &&
                                                <span>{showUnknownReceive && numberWithComma(receipt_deposit.node.total,"")}</span>}</td>   
                                            <td className="text-center"></td>
    
                                            <td className="text-right">{numberWithComma(receipt_deposit.node.total)}</td>
                                            <td className="text-right">{t_index +1 === other_receive.node.receiptDeposit.edges.length && numberWithComma(sum_other_receive)}</td>
                                            
                                        </tr>
                                )
                                
                            })}
                        </React.Fragment>
                    )}
                    else{
                        return(
                            other_receive.node.otherReceiveTransaction.edges.map((other_transaction, t_index) => {
                                let row_number = (t_index === 0 && order_id + index + 1);
                                return (
                                    (
                                        <tr key={other_transaction.node.id + "void" + t_index}>
                                            <td className="text-center text-danger">{row_number}</td>
                                            <td className="text-center text-danger">
                                                {t_index === 0 && other_receive.node.docNumber}
                                            </td>
                                            <td className="text-center text-danger">{ t_index === 0 && format(other_receive.node.issuedDate, 'DD/MM/YYYY')}</td>
                                            <td className="text-danger">
                                                {t_index === 0 ? other_receive.node.contact && other_receive.node.contact.residential ? other_receive.node.contact.residential.name : "-" : ''}
                                            </td>
                                            <td className="text-danger">
                                                {t_index === 0 ? other_receive.node.contact ?
                                                    other_receive.node.contact.typeOfContact === "RESIDENTIAL" ?
                                                        getNameResidential(other_receive.node.contact.firstName, other_receive.node.contact.lastName) :
                                                        other_receive.node.contact.name
                                                    :
                                                    other_receive.node.unknownContact ?
                                                        other_receive.node.unknownContact : "-"
                                                :''
                                                }
                                                {" "}                                            
                                                {
                                                     t_index === 0 && (other_receive.node.payGroup === "RENT" || other_receive.node.payGroup === "AGENT" || other_receive.node.payGroup === "OWNER" || other_receive.node.payGroup === "DEVELOPER") &&
                                                     <label className="text-danger">
                                                         ({other_receive.node.payGroup === "RENT" || other_receive.node.payGroup === "AGENT" || other_receive.node.payGroup === "OWNER" || other_receive.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${other_receive.node.payGroup}`) : ""})
                                                     </label>
                                                }                                                
                                            </td>
                                            <td className="text-danger">
                                                {t_index === 0 ? 
                                                    other_transaction.node.productAndService?.productCode || other_transaction.node.chartOfAccount?.chartOfAccountCode
                                                : ''}
                                            </td>
                                            <td className="text-danger">{
                                                this.removeTrailingZeros(other_transaction.node.description)
                                                }</td>
                                            <td className="text-center text-danger">-</td>
                                            <td className="text-center text-danger">-</td>
                                            <td className="text-center text-danger">-</td>
                                            <td className="text-center text-danger">-</td>
                                            <td className="text-center text-danger">-</td>
                                            <td className="text-center text-danger">-</td>
                                            <td className="text-center text-danger">-</td>
    
                                            <td className="text-center text-danger">{numberWithComma(0)}</td>
                                            
                                        </tr>
                                    )
                                )
                            })
                        )
                    }
                })
                }
                {(this.props.props.allOtherReceive.pageInfo.hasNextPage || this.props.props.allOtherReceive.pageInfo.hasPreviousPage) &&
                <tr>
                    <td colSpan={7} className="text-right"><strong>{i18next.t("OtherReceiveReport:Sum")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(amount_cash)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(amount_bank)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(amount_cheque)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(amount_credit_card)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(amount_unknown_receive)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(amount_other_payment)}</strong></td>
                    <td className="text-right"><strong>{numberWithComma(amount_total)}</strong></td>
                    <td className="text-right"><strong>{numberWithComma(amount_total)}</strong></td>
                </tr>
                }
                <tr>
                    <td colSpan={7} className="text-right"><strong>{i18next.t("OtherReceiveReport:Total")} {this.props.props.allOtherReceive.totalCount} {i18next.t("OtherReceiveReport:Item(s)")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(this.props.summary.sum_pay_by_cash))}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(this.props.summary.sum_pay_by_bank))}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(this.props.summary.sum_pay_by_cheque))}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(this.props.summary.sum_pay_by_credit_card))}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(this.props.summary.sum_unknown_receive))}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(this.props.summary.sum_pay_by_other))}</strong></td>
                    <td className="text-right"><strong>{numberWithComma(parseFloat(this.props.summary.sum_other_receive_transaction))}</strong></td>
                    <td className="text-right"><strong>{numberWithComma(parseFloat(this.props.summary.sum_other_receive_transaction))}</strong></td>
                    
                </tr>
            </React.Fragment>
        )
    }
}

export default OtherReceiveReportTable;
