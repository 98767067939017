/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReceiveCollectionLawFirmLawyerApproval = "APPROVAL" | "OTHER" | "UNAPPROVAL" | "WAIT" | "%future added value";
export type ReceiveCollectionLawFirmRecordStatus = "PAID" | "UNACCEPT" | "VOID" | "WAIT" | "%future added value";
export type ReceiveCollectionLawFirmRecordType = "FEE" | "OTHER" | "%future added value";
export type ReceiveCollectionLawFirmStatus = "CANCEL_SOME_PORTIONS" | "PAID" | "PAID_SOME_PORTIONS" | "UNACCEPT" | "VOID" | "WAIT" | "%future added value";
export type ReceiveCollectionLawFirmType = "FEE" | "OTHER" | "%future added value";
export type ReceiveCollectionLawFirmTypePaid = "BANK" | "CASH" | "OTHER" | "PAYMENT_GATEWAY_KHECH" | "QRCODE" | "%future added value";
export type settingDocumentPaidLawyerDetailQueryVariables = {|
  id?: ?string
|};
export type settingDocumentPaidLawyerDetailQueryResponse = {|
  +allReceiveCollectionLawFirm: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: ?any,
        +status: ReceiveCollectionLawFirmStatus,
        +type: ReceiveCollectionLawFirmType,
        +total: number,
        +paid: number,
        +typePaid: ReceiveCollectionLawFirmTypePaid,
        +lawyerApproval: ReceiveCollectionLawFirmLawyerApproval,
        +voidNote: ?string,
        +fileName: ?string,
        +fileUpload: ?string,
        +billingName: ?string,
        +billingUpload: ?string,
        +receiptName: ?string,
        +receiptUpload: ?string,
        +language: string,
        +creator: ?string,
        +creatorDelete: ?string,
        +creatorApproval: ?string,
        +receiveCollectionLawFirmRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +status: ReceiveCollectionLawFirmRecordStatus,
              +type: ReceiveCollectionLawFirmRecordType,
              +total: number,
              +paid: number,
              +creator: ?string,
              +collectionLawFirm: {|
                +id: string,
                +docNumber: string,
                +totalDebt: number,
                +totalFine: number,
                +contact: {|
                  +id: string,
                  +refNumber: string,
                  +name: string,
                  +firstName: string,
                  +lastName: string,
                |},
                +purchaseRecordGroupLawFirm: ?{|
                  +totalCount: ?number,
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +purchaseRecordGroup: {|
                        +id: string,
                        +docNumber: string,
                      |}
                    |}
                  |}>,
                |},
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +summaryReceiveCollectionLawFirm: ?number,
|};
export type settingDocumentPaidLawyerDetailQuery = {|
  variables: settingDocumentPaidLawyerDetailQueryVariables,
  response: settingDocumentPaidLawyerDetailQueryResponse,
|};
*/


/*
query settingDocumentPaidLawyerDetailQuery(
  $id: ID
) {
  allReceiveCollectionLawFirm(id: $id) {
    edges {
      node {
        id
        docNumber
        issuedDate
        status
        type
        total
        paid
        typePaid
        lawyerApproval
        voidNote
        fileName
        fileUpload
        billingName
        billingUpload
        receiptName
        receiptUpload
        language
        creator
        creatorDelete
        creatorApproval
        receiveCollectionLawFirmRecord {
          edges {
            node {
              id
              status
              type
              total
              paid
              creator
              collectionLawFirm {
                id
                docNumber
                totalDebt
                totalFine
                contact {
                  id
                  refNumber
                  name
                  firstName
                  lastName
                }
                purchaseRecordGroupLawFirm {
                  totalCount
                  edges {
                    node {
                      purchaseRecordGroup {
                        id
                        docNumber
                      }
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  summaryReceiveCollectionLawFirm
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paid",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typePaid",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lawyerApproval",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidNote",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileUpload",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingUpload",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receiptName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receiptUpload",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorDelete",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorApproval",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDebt",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalFine",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "PurchaseRecordGroupNode",
  "kind": "LinkedField",
  "name": "purchaseRecordGroup",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summaryReceiveCollectionLawFirm",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingDocumentPaidLawyerDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReceiveCollectionLawFirmNodeConnection",
        "kind": "LinkedField",
        "name": "allReceiveCollectionLawFirm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReceiveCollectionLawFirmNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReceiveCollectionLawFirmNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiveCollectionLawFirmRecordNodeConnection",
                    "kind": "LinkedField",
                    "name": "receiveCollectionLawFirmRecord",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveCollectionLawFirmRecordNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReceiveCollectionLawFirmRecordNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CollectionLawFirmNode",
                                "kind": "LinkedField",
                                "name": "collectionLawFirm",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  (v22/*: any*/),
                                  (v23/*: any*/),
                                  (v24/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PurchaseRecordGroupLawFirmNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "purchaseRecordGroupLawFirm",
                                    "plural": false,
                                    "selections": [
                                      (v25/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PurchaseRecordGroupLawFirmNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PurchaseRecordGroupLawFirmNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v26/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v27/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingDocumentPaidLawyerDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReceiveCollectionLawFirmNodeConnection",
        "kind": "LinkedField",
        "name": "allReceiveCollectionLawFirm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReceiveCollectionLawFirmNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReceiveCollectionLawFirmNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiveCollectionLawFirmRecordNodeConnection",
                    "kind": "LinkedField",
                    "name": "receiveCollectionLawFirmRecord",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveCollectionLawFirmRecordNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReceiveCollectionLawFirmRecordNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CollectionLawFirmNode",
                                "kind": "LinkedField",
                                "name": "collectionLawFirm",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  (v22/*: any*/),
                                  (v23/*: any*/),
                                  (v24/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PurchaseRecordGroupLawFirmNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "purchaseRecordGroupLawFirm",
                                    "plural": false,
                                    "selections": [
                                      (v25/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PurchaseRecordGroupLawFirmNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PurchaseRecordGroupLawFirmNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v26/*: any*/),
                                              (v2/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v27/*: any*/)
    ]
  },
  "params": {
    "cacheID": "ef5db0e78af6063f26ef88c1161226ea",
    "id": null,
    "metadata": {},
    "name": "settingDocumentPaidLawyerDetailQuery",
    "operationKind": "query",
    "text": "query settingDocumentPaidLawyerDetailQuery(\n  $id: ID\n) {\n  allReceiveCollectionLawFirm(id: $id) {\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        status\n        type\n        total\n        paid\n        typePaid\n        lawyerApproval\n        voidNote\n        fileName\n        fileUpload\n        billingName\n        billingUpload\n        receiptName\n        receiptUpload\n        language\n        creator\n        creatorDelete\n        creatorApproval\n        receiveCollectionLawFirmRecord {\n          edges {\n            node {\n              id\n              status\n              type\n              total\n              paid\n              creator\n              collectionLawFirm {\n                id\n                docNumber\n                totalDebt\n                totalFine\n                contact {\n                  id\n                  refNumber\n                  name\n                  firstName\n                  lastName\n                }\n                purchaseRecordGroupLawFirm {\n                  totalCount\n                  edges {\n                    node {\n                      purchaseRecordGroup {\n                        id\n                        docNumber\n                      }\n                      id\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  summaryReceiveCollectionLawFirm\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b690deaa6560556df0e54bd14a1298d9';

module.exports = node;
