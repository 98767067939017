import i18next from 'i18next';
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class DailyJournalNavigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to={`/accounting/daily-journal/${this.props.category}/list/all`}>
                                {i18next.t("dailyjournalList:All")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/accounting/daily-journal/${this.props.category}/list/valid`}>
                                {i18next.t("dailyjournalList:Normal")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/accounting/daily-journal/${this.props.category}/list/invalid`}>
                                {i18next.t("dailyjournalList:Waiting for correction")}

                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/accounting/daily-journal/${this.props.category}/list/void`}>
                                {i18next.t("dailyjournalList:Cancel")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default DailyJournalNavigation;