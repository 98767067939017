import React, { Component } from 'react'
import Swal from 'sweetalert2';
import _ from "lodash";
import Modal from 'react-modal';
import VoidPurchaerOrderes from "../mutation/voidPurchaseOrderes"
import i18next from 'i18next';
import { Translation } from 'react-i18next';

export default class notConfirmManageOrder extends Component {

  constructor(props) {
    super(props)

    this.state = {
      statusOnClick: "",
      heightModal: "304px",
      checkRadio: { title: "", detail: "" },
      textWarn: false,
    }
  }

  componentDidMount() {
    if (this.props.open) {
      document.body.style.overflow = 'hidden';
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  checkTextContentHead = () => {
    let textResult = ""

    switch (this.state.statusOnClick) {
      case "confirm":
        textResult = (<p className="text-center textContentHead">{i18next.t("notConfirmManageOrder:Please specify the reason for not approving the purchase order.")}</p>)
        break;
      default:
        textResult = (
          <p className="text-center textContentHead">
            {i18next.t("notConfirmManageOrder:Not approving the request will result in the cancellation of the corresponding purchase order.")}<br />
            {i18next.t("notConfirmManageOrder:It cannot be modified later. Do you want to proceed with the transaction or not")}?
          </p>
        )
        break;
    }

    return textResult
  }

  handleCheckRadio = (e) => {

    let { name, value } = e.target
    let textDetail = ""

    switch (value) {
      case "cancelOrder":
        textDetail = "ยกเลิกคำสั่งซื้อ"
        break;
      case "notSpecs":
        textDetail = "สินค้า / บริการไม่ตรงตามสเปค"
        break;
      case "duplicateDocuments":
        textDetail = "เอกสารซ้ำ"
        break;
      case "other":
        textDetail = ""
        break;

      default:
        break;
    }

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), "checkRadio.detail", textDetail)
    });
    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, value)
    });
  }

  handleChangeInput = (e) => {

    let { name, value } = e.target

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, value)
    });
  }

  handleConfirm = () => {
    if (this.state.checkRadio.detail) {

      let variableVoid = {
        input: {
          purchaseOrderesId: this.props.purchaseOrderesId,
          voidRemark: this.state.checkRadio.detail
        }
      }

      VoidPurchaerOrderes(
        variableVoid,
        (res) => {
          if (res.voidPurchaseOrderes.ok) {
            Swal.fire(i18next.t("Allaction:Canceled Successfully"), '', 'success').then((res) => {
              this.props.handleChangeInput({
                target: {
                  name: "redirectTo",
                  value: '/purchasing/manageOrder/list/all'
                }
              })
            })
          } else {
            Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), i18next.t("Allaction:Please try again."), 'warning')
          }

        },
        () => { Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), '', 'warning') }
      )

      this.setState({ statusOnClick: "" })
      this.props.handleCloseModal()

    } else {
      this.setState({ textWarn: true })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.open}
          onRequestClose={this.props.handleCloseModal}
          ariaHideApp={false}
          style={{
            content: {
              width: "640px",
              height: `${this.state.heightModal}`,
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              padding: '2%'
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }
          }}
        >
          <Translation>
            {t =>
              <div id="notConfirmManageOrder">
                <div className="d-flex justify-content-center">
                  <img className="my-4 " src="/images/iconAlerts/iconAlert-Blue.png" alt="icon-blue" />
                </div>

                {this.checkTextContentHead()}

                {this.state.statusOnClick === "confirm" &&
                  <div className="bodyContent mb-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        id="cancelOrder" name="checkRadio.title"
                        value="cancelOrder"
                        checked={this.state.checkRadio.title === "cancelOrder"}
                        onChange={this.handleCheckRadio}
                      />
                      <span className="mx-4 textBodyContent">{i18next.t("notConfirmManageOrder:Cancel Purchase Order")}</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        id="notSpecs" name="checkRadio.title"
                        value="notSpecs"
                        checked={this.state.checkRadio.title === "notSpecs"}
                        onChange={this.handleCheckRadio}
                      />
                      <span className="mx-4 textBodyContent">{i18next.t("notConfirmManageOrder:Products/Services not meeting specifications.")}</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        id="duplicateDocuments" name="checkRadio.title"
                        value="duplicateDocuments"
                        checked={this.state.checkRadio.title === "duplicateDocuments"}
                        onChange={this.handleCheckRadio}
                      />
                      <span className="mx-4 textBodyContent">{i18next.t("notConfirmManageOrder:Duplicate document.")}</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        id="other" name="checkRadio.title"
                        value="other"
                        checked={this.state.checkRadio.title === "other"}
                        onChange={this.handleCheckRadio}
                      />
                      <span className="mx-4 textBodyContent">{i18next.t("notConfirmManageOrder:Other (please specify)")}</span>
                      <input type="text"
                        disabled={this.state.checkRadio.title !== "other"}
                        className="w-50"
                        name="checkRadio.detail"
                        value={
                          this.state.checkRadio.title === 'other'
                            ? this.state.checkRadio.detail : ""
                        }
                        onChange={this.handleChangeInput}
                      />
                    </div>
                  </div>
                }

                {this.state.textWarn &&
                  <div className="bgWarn d-flex justify-content-center align-items-center mb-2">
                    <span className="textWarn">{i18next.t("notConfirmManageOrder:Please provide comments")} !</span>
                  </div>
                }

                <div className="d-flex justify-content-center">
                  {this.state.statusOnClick === "" &&
                    <React.Fragment>
                      <button
                        type="button"
                        className="btnRed"
                        onClick={
                          () => this.setState({ statusOnClick: "confirm", heightModal: "420px" })
                        }
                      >
                        <span>{i18next.t("notConfirmManageOrder:Continue")}</span>
                      </button>
                    </React.Fragment>
                  }

                  {this.state.statusOnClick === "confirm" &&
                    <button type="button" className="btnPrimary" onClick={this.handleConfirm}>
                      <span>{i18next.t("notConfirmManageOrder:Confirm")}</span>
                    </button>
                  }

                  <button type="button" className="btnClose" onClick={this.props.handleCloseModal}>
                    <span>{i18next.t("notConfirmManageOrder:Close Window")}</span>
                  </button>
                </div>
              </div>
            }
          </Translation>
        </Modal>
      </React.Fragment>
    )
  }
}
