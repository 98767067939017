import React, { Component } from 'react'
import Sidebar from '../../components/sidebar'
import Wrapper from '../../components/wrapper'
import Header from '../../components/header/index'
import WrapperContent from '../../components/wrapper/wrapperContent'
import Link from 'react-router-dom/es/Link';
import { Translation } from 'react-i18next'
import './styles.scss'


class lawyerView extends Component {
    constructor(props){
        super(props)
        this.state = {
            iFrameHeight: '0px'
        }
    }

    render(){
        return(
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid box" id='jsb'>
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/addon">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('settingAll:addon_rules')}</span>
                                        }
                                    </Translation>
                                    
                                </h3>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center'>
                            <div className='col'>
                                    <div className='pt-4'>
                                      <a href="https://silverman.app/" target="_blank">
                                        <img style={{width:'100%',height:'800px'}} src={process.env.PUBLIC_URL + '/images/partner-icon/service/lawyer-service.png'} />
                                      </a>
                                    </div>
                            </div>
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default lawyerView;