import React, { Component } from 'react';
import { QueryRenderer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import InvoiceCreateUpdateForm from "./invoiceCreateUpdateForm"
import { Redirect } from 'react-router'
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Loading from '../../../libs/loading';
import _ from "lodash";
const invoiceForm = graphql`
    query invoiceFormQuery($id: ID!,$contact: String) {
#    query invoiceFormQuery($id: ID!, $contact: [String]!) {
        contact(id: $id) {
            id
            refNumber
            name
            firstName
            lastName
            registeredAddress
            registeredCountry
            registeredProvince
            registeredDistrict
            registeredCity
            registeredPostalCode
            typeOfContact
            typeOfPayment
        }
        productViewer{
            allProduct (type_In: "product, service, fine",isActive: true){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                        cost
                        noNumber
                        autoOrder
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        servicePricing {
                            edges {
                                node {
                                    id
                                    periodAdjustment
                                    pricingType
                                }
                            }
                        }
                        numberKey(status:true){
                            edges {
                                node {
                                    id
                                    noNumber
                                    status
                                    keycardPerInvoice{
                                        totalCount
                                        # edges {
                                        #     node {
                                        #         id
                                        #         transaction{
                                        #             id
                                        #             status
                                        #         }
                                        #     }
                                        # }
                                    }
                                    keycardPerOtherReceiveTransaction{
                                        totalCount
                                    }
                                }
                            }
                        }
                        description
                        totalLeftInStock
                        taxRate
                    }
                }
            }
        }
        selfProject{
            haveVat
        }
        viewer {
            allChartOfAccount(chartOfAccountCode: "1131-12"){
                totalCount
                edges{
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
        }
        group113110: viewer {
            allChartOfAccount(chartOfAccountCode: "1131-10"){
                totalCount
                edges{
                    node {
                        id
                        chartOfAccountCode
                        name
                        
                    }
                }
            }
        }
        
        allContractRevenue(contact: $contact,startPaid_In: "active, partial_payment"){
            edges {
                node {
                    id
                    docNumber
                    issuedDate
                    debtor{
                        id
                        refNumber 
                        name
                    }
                    contractNumber
                    contractForm
                    otherContractForm
                    status
                    total
                    chartOfAccount{
                        id
                        name
                    }
                    contractRevenueList{
                        edges{
                            node{
                                id
                                productAndService{
                                    id
                                    name
                                    productCode
                                    type
                                    price
                                    chartOfAccount{
                                        id
                                        chartOfAccountCode
                                        name
                                    }
                                    servicePricing {
                                        edges {
                                            node {
                                                id
                                                periodAdjustment
                                                pricingType
                                            }
                                        }
                                    }
                                    description
                                    totalLeftInStock
                                }
                                description
                                numPeriod
                                pricePerPeriod
                                total
                                paid
                            }
                        }
                    }
                    startContractDate
                    endContractDate
                }
            }
        }
        
#        receiptDepositTransactionActive(contact: $contact){
#            chartOfAccount_Id
#            chartOfAccount_Name
#            chartOfAccount_Code
#            totalBalance
#        }
    }
`;

class InvoiceForm extends Component {

    render() {
        if (this.props.customerList.length > 0 || this.props.preinvoice) {
            return (
                <div className="container-fluid box">
                    <div className="row">
                        <div className="col">
                            <h3 className="mb-4">
                                <Link to="/accounting/income/invoice/form/select_customer">
                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                        alt="back" className="back" />
                                </Link>
                                <Translation>
                                    {
                                        t => <span>{t('accounting:add_invoice')}</span>
                                    }
                                </Translation>
                            </h3>
                        </div>
                    </div>
                    <div className="content-inner">

                        {this.props.customerList.length > 0 ?
                            <QueryRenderer
                                environment={environment}
                                query={invoiceForm}
                                variables={{ 
                                    id: this.props.customerList[0],
                                    contact: this.props.customerList[0],
                                 }}
                                cacheConfig={{ use_cache: false }}
                                render={({ error, props }) => {
                                    if (props) {
                                        return <InvoiceCreateUpdateForm contactList={this.props.customerList}
                                            contactResidential={this.props.contactResidential}
                                            pre_Invoice={this.props.preinvoice}
                                            contact={props.contact} invoiceQuery={props}
                                            query={invoiceForm}
                                            category={'invoice'}
                                            product_and_service={props.productViewer.allProduct.edges}
                                            fine_chart_of_account={_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'chart_of_account_sena_v2'])?.menu_on ? 
                                                            props.group113110.allChartOfAccount.edges 
                                                            : 
                                                            props.viewer.allChartOfAccount.edges}
                                            next_page={'/accounting/income/invoice/list/draft'}
                                            back_page={'/accounting/income/invoice/list/all'}
                                            mode="create"
                                            project_have_vat={props.selfProject.haveVat}
                                            all_contract_revenue={props.allContractRevenue.edges}
                                            role={this.props.role}
                                        />;
                                    } else {
                                        return <Loading/>
                                    }
                                }}
                            />
                            :
                            <InvoiceCreateUpdateForm
                                pre_Invoice={this.props.preinvoice}
                                category={'invoice'}
                                next_page={'/accounting/income/invoice/list/draft'}
                                back_page={'/accounting/income/invoice/list/all'}
                                mode="create"
                            />
                        }
                    </div>
                </div>
            )
        }
        return <Redirect to="/accounting/income/invoice/form/select_customer" />;
    }
}

export default InvoiceForm;