
import React, {Component} from 'react';
import './invoice.scss';
import '../../document/styles/main.scss';
import i18n from "i18next"
import { Translation } from 'react-i18next';
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import environment from "../../env/environment";
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
require('number-to-text/converters/en-us');

const query = graphql`
    query settingInvoiceTempateViewQuery {
        selfProject{
            id
            name
            nameTh
            nameEn
            address
            logo
            juristicContactNumber
            keyProjectQr
            bankCompCode
            bankServiceCode
            taxIdNumber
            typeOfProject
            prompayQr
            prompayQrShow
        }
    }
`;

class SettingInvoiceTempateView extends Component {

    constructor(props){
        super(props);
        this.state = {
            sum_vat: 0,
            sum_amount: 0,
            sum_grand_total:0,
            sum_discount:0,
            discount:0,
            sum_total:0,
            sum_wht_rate: 0,
            sum_receive_deposit: 0,
            total_wht_rate: 0,
            receiveDeposit: 0,
            total_pay_qr: 0,
            // project : atob(this.props?.selfProject.id).split(":")[1],
            doc_num_for_qr : '12333',
            contact_room_name : '' ,
            
            have_vat:"",
            no_vat:"",
            have_wht_rate_percent:"",
            no_wht_rate_percent:"",
            discount_status:"",
            list_history:[],
            list_map: [],
            diff: 0,
            check_type: 'all',
            over_page:false,
            page_invoice : [],
            remark : "",
            show_tax:false,
            languages:"th",
            keyProjectQr:"",
            prompayQrShow: null
        };
    }

    queryLanguages(){
        this.setState({languages:this.props.userConfig.languages})
        i18n.changeLanguage(this.props.userConfig.languages)
    }

    componentDidMount() {
        this.getData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.check_type !== this.props.check_type) {
            if (this.props.check_type === 'all') {
                this.setState({list_map: [1, 2]})
            } else if (this.props.check_type === 'manuscript') {
                this.setState({list_map: [1]})
            } else {
                this.setState({list_map: [2]})
            }
            this.setState({check_type: this.props.check_type});
        }
    }

    nextLine(address) {
        return address = address.replace('แขวง', '\nแขวง')
    }

    getWordTH(type,wordTH){
        let result 
        switch (type) {
        case 'template1':
            result = wordTH
            break;
        case 'template2':
            result = wordTH
            break;
        case 'template3':
            result = wordTH
            break;
        case 'template4':
            result = wordTH
            break;
        default:
            result = 'ใบแจ้งหนี้'
        }
        return result
    }

    getWordEN(w){
        return w ? w : 'Invoice'
    }

    getWord(w){
        return w ? w : 'ใบแจ้งหนี้'
    }

    getData() {
        fetchQuery(environment, query).then(data => {
            if(data.selfProject){
                this.setState({
                    keyProjectQr: data.selfProject.keyProjectQr,
                    prompayQrShow: data.selfProject.prompayQrShow
                })
            }
        }).catch(err => {
            alert(err)
        })
    }

    render() {
        const myAddress = '881086 ถนนเจริญกรุง แขวงบางรัก เขตบางรัก กรุงเทพมหานคร 10501'
     
        return (
            <React.Fragment >
                <div className='print-page-a4' id="invoice">
                    <div className="subpage" >
                        <div className="head">
                            <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                            <div style={{minHeight:100,minWidth:100}}> 
                                <img  src={process.env.PUBLIC_URL + "/images/logo/silverman.jpeg"}/>
                            </div>
                            <div>  
                                <div style={{minWidth:'160%'}}>
                                <strong> นิติบุคคลอาคารชุด เอส วี เอ็ม ซัพพอร์ต</strong>
                                </div>
                                {
                                    this.props.type === 'template3' ? 
                                    <div>
                                        <Translation>{t=><div className="address-header">{this.nextLine(myAddress)} </div>}</Translation>
                                        <Translation>{t=><div className="address-header">{t("document:Tel")} 099400141486676 </div>}</Translation>
                                    </div>
                                    :
                                    <Translation>{t=><div className="address-header">{myAddress} {t("document:Tel")} 099400141486676</div>}</Translation>
                                }

                                <Translation>{t=><div>{t("document:Taxpayer identification number")} 099400141486676</div>}</Translation>
                            </div> 
                            </div>

                            <div className="document-name">
                                <div className="title-invoice">
                                {
                                    this.props.type === 'template1' && this.getWord(this.props.langTH1)
                                }

                                {
                                    this.props.type === 'template2' && this.getWord(this.props.langTH2)
                                }

                                {
                                    this.props.type === 'template3' && this.getWord(this.props.langTH3)
                                }   

                                {
                                    this.props.type === 'template4' && this.getWord(this.props.langTH4)
                                }   

                                {
                                    this.props.type === 'template5' && this.getWord(this.props.langTH5)
                                }       
                                </div>

                                <div className="title-invoice">
                                {
                                    this.props.type === 'template1' && this.getWordEN(this.props.langEN1)
                                }

                                {
                                    this.props.type === 'template2' && this.getWordEN(this.props.langEN2)
                                }

                                {
                                    this.props.type === 'template3' && this.getWordEN(this.props.langEN3)
                                }   

                                {
                                    this.props.type === 'template4' && this.getWordEN(this.props.langEN4)
                                }   

                                {
                                    this.props.type === 'template5' && this.getWordEN(this.props.langEN5)
                                }   
                                </div>       
                            </div>
                        </div>

                        <div className="detail">
                            <div className="customer-detail">
                                <strong>รหัสลูกค้า/ID:</strong> H00022 <br/>
                                <strong>ชื่อลูกค้า/Customer:</strong>คุณซิลเวอร์แมน ซัพพอร์ต<br/>
                                <strong>ที่อยู่/Address:</strong>
                                <div className="customer-detail address-space">
                                {
                                    this.props.type === 'template1' ? 'เลขที่ 1086/04 ถนนเจริญกรุง แขวงบางรัก เขตบางรัก กรุงเทพมหานคร 10500' : 
                                        this.props.type === 'template2' ? '1086/04 (H00004) ถนนเจริญกรุง แขวงบางรัก เขตบางรัก กรุงเทพมหานคร 10501' 
                                        :'เลขที่ 1086/04 ถนนเจริญกรุง แขวงบางรัก เขตบางรัก กรุงเทพมหานคร 10500'

                                }
                                </div><br/>
                                </div>
                                <div className="document-detail"><strong className="sub-document-detail">เลขที่/No.:</strong> IV-2023050004 <br/>
                                <strong className="sub-document-detail">วันที่/Date:</strong> 08/05/2023 <br/>
                                <strong className="sub-document-detail">ครบกำหนด/Due Date:</strong> 15/05/2023 <br/>
                                <strong className="sub-document-detail">บ้านเลขที่/House No.:</strong> 1086/20 &nbsp;<br/>
                                <strong className="sub-document-detail">อัตราRatio/พื้นที่Space:</strong> -/42.91 <br/>
                                </div>
                        </div>
                        <div className="invoice-body">
                            <table className="table table-bordered"> 
                            <thead>
                            <tr>
                            <th width="50" className="text-center">ลำดับ No.</th>
                            <th width="50" className="text-center">รหัส Code</th>
                            <th className="text-center" colSpan="2">รายละเอียด Description</th>
                            <th width="90" className="text-center"> จำนวนหน่วย Unit</th>
                            <th width="90" className="text-center">ราคาต่อหน่วย Unit</th>
                            <th width="90" className="text-center">จำนวนเงิน Amount</th>
                            </tr>
                        </thead>
                            <tbody className="current">
                                <tr>
                                    <td className="text-center">1</td>
                                    <td className="text-center">P006</td>
                                    <td colSpan="2">ค่าส่วนกลาง / common fee</td>
                                    <td className="text-right">1.00</td>
                                    <td className="text-right">1,500.00</td>
                                    <td className="text-right">1,500.00</td>
                                </tr>
                                <tr className="lastRow">
                                    <td className="lasttd"></td>
                                    <td className="lasttd"></td>
                                    <td colSpan="2" className="lasttd"></td>
                                    <td className="lasttd"></td>
                                    <td className="lasttd"></td>
                                    <td className="lasttd"></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td colSpan="2" style={{borderRight:'none'}}><strong>รายการค้างชำระยกมา / Previous Balance  1,500.00 (1 ใบ)</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td style={{borderRight:'none'}}>
                                        <ul>
                                            <li>- ค่าส่วนกลาง / common fee</li>
                                        </ul>
                                    </td>
                                    <td className="text-right" style={{borderLeft:'none'}}>
                                        <ul>
                                            <li>1,500.00 บาท</li>
                                        </ul>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    </tr>
                            
                                </tbody>
                            {
                                this.props.type === 'template5' ? 
                                <tfoot>
                                <tr>
                                    <td rowSpan="2" style={{borderTop:'none',borderBottom:'none'}}></td>
                                    <td rowSpan="2" style={{borderTop:'none',borderBottom:'none'}}></td>
                                    <td colSpan="2" rowSpan="2" style={{borderTop:'none',borderBottom:'none'}}></td>
                                    <td colSpan="2">จำนวนเงิน/Amount</td>
                                    <td className="text-right">1,500.00</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">จำนวนเงินค้างชำระ</td>
                                    <td className="text-right">1,500.00</td>
                                </tr>
                                <tr>
                                <td style={{borderTop:'none',borderBottom:'none'}}></td>
                                <td style={{borderTop:'none',borderBottom:'none'}}></td>
                                <td colSpan="2" style={{borderTop:'none'}}> </td>
                                <td colSpan="2"><strong>หัก&nbsp;</strong>รับล่วงหน้า/Receipt Deposit</td><td className="text-right">0.00</td>
                                </tr>
                                <tr>
                                    <td style={{borderTop:'none'}}></td>
                                    <td style={{borderTop:'none'}}></td>
                                    <td colSpan={2}><strong className="text-center" style={{display:'block'}}>(สามพันบาทถ้วน) </strong></td>
                                    <td colSpan="2">จำนวนเงินรวมทั้งสิ้น/Grand Total</td>
                                    <td className="text-right">3,000.00</td>
                                </tr>
                            </tfoot>
                                    : 
                            <tfoot>
                                    <tr>
                                        <td rowSpan="2" style={{borderTop:'none',borderBottom:'none'}}></td>
                                        <td rowSpan="2"  style={{borderTop:'none',borderBottom:'none'}}></td>
                                        <td colSpan="2" rowSpan="2" className="text-center"><strong style={{display:'block'}}> 
                                        ยอดค้างชำระทั้งสิ้น 3,000.00 บาท </strong><strong className="mt-2" style={{display:'block'}}>(สามพันบาทถ้วน) </strong></td>
                                        <td colSpan="2">จำนวนเงิน/Amount</td>
                                        <td className="text-right">1,500.00</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2"><strong>หัก&nbsp;</strong>รับล่วงหน้า/Receipt Deposit</td>
                                        <td className="text-right">0.00</td></tr><tr><td style={{borderTop:'none'}}></td>
                                        <td style={{borderTop:'none'}}></td><td colSpan="2" className="text-center"> </td>
                                        <td colSpan="2">จำนวนเงินรวมทั้งสิ้น/Grand Total</td><td className="text-right">3,000.00</td>
                                    </tr>
                                </tfoot>
                            }
                        </table>
                        </div>
                                
                        
                        <div className='footer'>
                            <div className="remark" style={{whiteSpace:'pre-line'}}><strong style={{fontWeight:'bold'}}>หมายเหตุ/Remarks : </strong> <br/></div>
                                 {this.state.keyProjectQr && 
                                 <div className="cross-payment">
                                    <div className='row'>
                                        <div className='col-9'>
                                        <Translation>
                                        {t=>
                                        <div className="w-100">
                                            {"("+t("document:Fees should not exceed 5 THB per transaction via electronic channels and 20 THB per transaction at branch locations.")+")"}
                                            <br/>
                                            <div className="bank-space"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/bay.svg'}
                                                alt="bay" className="bank"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/bbl.svg'}
                                                alt="bay" className="bank"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/cimb.svg'}
                                                alt="bay" className="bank"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/gsb.svg'}
                                                alt="bay" className="bank"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/icbc.svg'}
                                                alt="bay" className="bank"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/kbank.svg'}
                                                alt="bay" className="bank"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/kk.svg'}
                                                alt="bay" className="bank"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/ktb.svg'}
                                                alt="bay" className="bank"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/mb.svg'}
                                                alt="bay" className="bank"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/scb.svg'}
                                                alt="bay" className="bank"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/ttb.svg'}
                                                alt="bay" className="bank"/>
                                            <img src={process.env.PUBLIC_URL + '/images/banks/uob.svg'}
                                                alt="bay" className="bank"/>
                                            
                                        </div>}
                                        </Translation>
                                        <div className="clearfix"/>
                                        <div className="barcode">
                                        <Barcode
                                            width={1.1} height={45} fontSize={0}
                                            value={"|" + "111213"+ "\r" + "11212" + "\r" + "66456456"+ "\r" + 11 * 100}
                                                />
                                        <div>{"|12132323222222280005202406001618720000"}</div>
                                        </div>
                                        <div className="clearfix"/>
                                        <div className='pt-2'>
                                        <Translation>{t=><p style={{fontSize:'10px'}}> - {t("document:You can check the list of participating banks and service providers on the Bank of Thailand's website.")} </p>}</Translation> 
                                        <Translation>{t=><p style={{fontSize:'10px'}}> - {t("document:Fees are subject to the terms and conditions of each bank/service provider.")} </p>}</Translation> 
                                        </div>
                                        </div>
                                        <div className='col-3'>
                                        
                                     
                                            <>
                                                <div style={{float:'right'}}><p>BILLER ID: {this.state.keyProjectQr},</p>  <p>REF1:108620,</p><p>REF2:2023050004</p></div>
                                                <div className="clearfix"/>
                                                <div className="qr">
                                                    <QRCode value="" size={100}/> 
                                            </div>
                                            </>
                                        
                                        
                                       
                                        </div>
                                    </div>
                                 </div>
                                 }
                                {
                                 this.state.prompayQrShow && 
                                    <>
                                    <div className='cross-payment'>
                                        <div className='row'>
                                            <div className='col-9'>
                                            <Translation>{t=> <strong style={{fontWeight:'bold'}}>{t("document:For payment through PromptPay")} :</strong>}</Translation> <br/>
                                            <Translation>{t=> <span>{t("document:Please scan the QR Code, check the amount to be paid, and press 'Confirm' to make the payment for the service")}</span>}</Translation>
                                            </div>
                                            <div className='col-3'>
                                                <img src={this.state.prompayQrShow} style={{width:'100px',height:'100px'}} />
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                }
                            <div className="signature" style={{fontSize:'12px'}}>............................................................... <br/>
                            <strong style={{fontSize:'12px',fontWeight:'bold'}}>ผู้มีอำนาจลงนาม/Authorized</strong></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SettingInvoiceTempateView;