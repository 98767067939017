import React, { Component } from 'react'
import Slider from "react-slick";
import UploadImageInput from '../../../libs/uploadImageInput';
import "../../../../node_modules/slick-carousel/slick/slick.css"
import "../../../../node_modules/slick-carousel/slick/slick-theme.css"
import '../styles/project.scss'
import SelectPicModal from './selectPicModal';
import _ from 'lodash'
import { Translation } from 'react-i18next';
const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <React.Fragment>
        <img {...props}
            src={process.env.PUBLIC_URL + '/images/icons/arrow-left.png'}
            alt="back"
            aria-disabled={currentSlide === 0 ? true : false} />
    </React.Fragment> 
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <React.Fragment>
        <img {...props}
            src={process.env.PUBLIC_URL + '/images/icons/arrow-right.png'} 
            alt="next" aria-disabled={currentSlide === 0 ? true : false} />
    </React.Fragment>
);
const settings = {
    dots: false,
    speed: 500,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />
};
export default class pictureProjectInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            projectInfo : this.props.projectInfo,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
            projectInfo : this.props.projectInfo
        });
    }

    componentDidUpdate(){
        if(this.props.projectInfo !== this.state.projectInfo){
            this.setState({
                projectInfo:this.props.projectInfo
            })
        }
    }

    getData = (upload,item) => {
        this.props.getFloor(upload,this.renameKey(item,'source','fileUpload'))
    }


    renameKey = (objectItem,newKey,oldKey) => {
        let newObject = []
        _.forEach(objectItem,object =>{
            newObject.push({node:{id:object.node.id , [newKey] : object.node[oldKey] , 
                title : object.node.title , status:object.node.status ? object.node.status : ''}})
        })
        return newObject
    }

    componentDidUpdate(prevProps) {
        if (prevProps.editProjectInfo !== this.props.editProjectInfo) {
            this.setState({
                nav1: this.slider1,
                nav2: this.slider2,
            nav2: this.slider2,projectInfo : this.props.projectInfo
            });
        }
    }

    handleChangInput = (e) => {
        this.props.onChange({
            target: {
                name: e.target.name,
                value: e.target.value,
            }
        });
    }

    render() {
        return (
            <Translation>
                {
                    t=>
                    <React.Fragment>
                        {this.props.projectInfo.image && this.props.projectInfo.floorPlan &&
                        <div className="reactSlick position-relative card-image">
                            {this.props.editProjectInfo 
                                ?
                                    <div className="position-relative">
                                        {this.props.projectInfo.image.edges.length  > 0 
                                            ? 
                                                <img style={{width: "100%", height: "315px"}} src={this.props.projectInfo.image.edges[0].node.source} alt='img'/>
                                            :
                                                <div style={{backgroundColor: "#fafafa", width: "100%", height: "315px", border: "1px solid #dfdfdf", borderRadius: "3px" }}/>
                                        }
                                        
                                        {/* รูปโครงการ */}
                                        <div className="upload-file">
                                            <label 
                                                type="button"
                                                className="position-absolute text-primary"
                                                style={{right: "10px", bottom: "10px", background: "#ffffff", padding: "3px"}}
                                                htmlFor="changProfileInformation"
                                                >{t("PageDetailProjectInfo:ChangeImageProject")}</label>
                                            <div className="upload-input">
                                                <UploadImageInput
                                                    id="changProfileInformation"
                                                    setField="projectInfo.upload_image:projectInfo.image.edges[0].node.source"
                                                    onChange={this.handleChangInput}
                                                    />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <SelectPicModal item={this.renameKey(_.clone(this.props.projectInfo.floorPlan.edges),'fileUpload','source')} getData={this.getData}/>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <React.Fragment>
                                        <div className="position-relative">
                                            {this.props.projectInfo.image?.edges?.length  > 0 
                                                ? 
                                                    <img style={{width: "100%", height: "315px" , objectFit: 'cover'}} src={this.props.projectInfo.image.edges[0].node.source} alt='img'/>
                                                :
                                                    <div style={{backgroundColor: "#fafafa", width: "100%", height: "315px", border: "1px solid #dfdfdf", borderRadius: "3px" }}/>
                                            }
                                            
                                        </div> 
                                    </React.Fragment>
                            }
                            <div className="col-12 reactSlick mt-4">
                                <Slider {...settings}>
                                    {this.state.projectInfo.floorPlan.edges.length > 0 && this.state.projectInfo.floorPlan.edges.map((img, index) => {
                                        return (
                                            <div key={index} className="ml-1 mr-1 col">
                                                <img  style={{width: "auto", height: "80px"}} src={img.node.source} alt="PicturesSub"/>
                                            </div>
                                        )
                                        })
                                    }
                                </Slider>
                            </div>
                            
                        </div>}
                        <div className="logo position-absolute" style={{top: "10px",right: "10px"}}>
                            <div className="position-relative">
                                <img
                                    src={this.props.projectInfo.logo ? this.props.projectInfo.logo : process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                    style={{boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset"}}
                                    className="rounded-circle avatar-img profile-img"
                                    alt="project-manager-profile"/>
                                {this.props.editProjectInfo &&
                                    <div className="upload-file">
                                        <label type="button" 
                                            className="position-absolute text-primary" 
                                            style={{bottom: "10px",right: "5px", background: "#ffffff"}}
                                            htmlFor="changLogoInformation"
                                            >{t("PageDetailProjectInfo:Change")}</label>
                                        <div className="upload-input">
                                            <UploadImageInput
                                                id="changLogoInformation"
                                                setField="projectInfo.upload_image_logo:projectInfo.logo"
                                                onChange={this.handleChangInput}
                                                />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}
