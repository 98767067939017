import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import VMSAccept from "./vmsAccept"
import VMSCreate from "./vmsCreate"
import VMSSuccess  from './vmsSuccess';
import VMSPayment  from './vmsPayment';
import VMSView  from './vmsView';
import VMSPaymentCheck from "./vmsPaymentCheck"
import VmsAcceptQRcode from "./vmsAcceptQRcode"

class VMS extends Component {

    render() {
    return (
        <Switch>
            <Route path={`${this.props.match.url}/vms-accept/:id?`} component={VMSAccept}/>
            <Route exact path={`${this.props.match.url}/vms-create/:id?`} component={VMSCreate}/>
            <Route exact path={`${this.props.match.url}/vms-success/:id?/:lang?`} component={VMSSuccess}/>
            <Route path={`${this.props.match.url}/vms-payment/:id?`} component={VMSPayment}/>
            <Route exact path={`${this.props.match.url}/vms-view/:id?`} component={VMSView}/>
            <Route exact path={`${this.props.match.url}/vms-accept-qrcode/:qrcode?`} component={VmsAcceptQRcode} />
            <Route path={`${this.props.match.url}/vms-payment-check`} component={VMSPaymentCheck}/>
        </Switch>
    )
    }
}

export default VMS;