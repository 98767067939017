import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../../env/environment";
import {Translation} from "react-i18next";

const query = graphql`
    query navigationReceiveQuery{
        getNotificationAccounting {
            type
            count
        }
    }
`;

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            count_notification: 0
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then(response_data => {
            let count = 0;
            if (response_data.getNotificationAccounting) {
                response_data.getNotificationAccounting.forEach((notification) => {
                    count += notification.count
                });
                this.setState({count_notification: count})
            }
        });
    }

    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/income/receive/list/all">
                                <Translation>{t => t('receive:All')}</Translation>
                            </NavLink>
                        </li>
                        <li>
                            
                             <NavLink to="/accounting/income/receive/list/wait">
                                <Translation>{t => t('receive:Waiting for review')}</Translation> 
                                {(this.state.count_notification > 0) ? 
                                <p className="float-right font-weight-bold badge text-light bg-danger">{this.state.count_notification}</p> : false}
                            </NavLink>
                            
                        </li>
                        {/*<li>*/}
                        {/*    <NavLink to="/accounting/income/receive/list/paid">*/}
                        {/*        รับชำระแล้ว*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                        <li>
                             <NavLink to="/accounting/income/receive/list/void">
                                <Translation>{t => t('receive:Canceled list')}</Translation> 
                             </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;