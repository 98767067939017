/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type emergencyGroupQueryVariables = {||};
export type emergencyGroupQueryResponse = {|
  +emergencyCallGroupViewer: ?{|
    +allEmergencyCallGroup: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +pinIt: boolean,
        |}
      |}>,
      +totalCount: ?number,
    |}
  |}
|};
export type emergencyGroupQuery = {|
  variables: emergencyGroupQueryVariables,
  response: emergencyGroupQueryResponse,
|};
*/


/*
query emergencyGroupQuery {
  emergencyCallGroupViewer {
    allEmergencyCallGroup {
      edges {
        node {
          id
          name
          pinIt
        }
      }
      totalCount
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "EmergencyCallGroupNodeConnection",
  "kind": "LinkedField",
  "name": "allEmergencyCallGroup",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EmergencyCallGroupNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmergencyCallGroupNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pinIt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "emergencyGroupQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmergencyCallGroupViewer",
        "kind": "LinkedField",
        "name": "emergencyCallGroupViewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "emergencyGroupQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmergencyCallGroupViewer",
        "kind": "LinkedField",
        "name": "emergencyCallGroupViewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b8eeee6dcc9f527ab5ebde04c14d5e0c",
    "id": null,
    "metadata": {},
    "name": "emergencyGroupQuery",
    "operationKind": "query",
    "text": "query emergencyGroupQuery {\n  emergencyCallGroupViewer {\n    allEmergencyCallGroup {\n      edges {\n        node {\n          id\n          name\n          pinIt\n        }\n      }\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5f30ab2eaf14fb834f0c5d2fc993f17f';

module.exports = node;
