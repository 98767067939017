import "../styles/register.scss"
import React from 'react';
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import { Dropdown } from "react-bootstrap";
import { QueryRenderer } from "react-relay";
import Search from "../SearchInput";
import ComponentPagination from "../../libs/componentPagination";
import RegisterTopMenuNavigation from "../registerTopMenuNavigation";
import Pagination from "../../libs/newPagination";
import environment from "../../env/environment";
import Lightbox from "react-images";
import Loading from "../../libs/loading";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import ExportPetExcel from './petExcel';
import ExportPetPDF from "./petPDF";
import jwtDecode from 'jwt-decode'

const allResidential = graphql`
    query petListQuery($search: String, $first: Int, $last: Int){
        selfProject{
            name
            address
            logo
        }
        allResidential(search: $search, first: $first, last: $last, queryType: "pet"){
            totalCount
            edges{
                node{
                    id
                    name
                    pet{
                        edges{
                            node{
                                id
                                petType
                                imagePet
                                description
                                healthCheckPath
                                healthCheckName
                            }
                        }
                    }
                }
            }
        }
    }
`;

class PetList extends ComponentPagination {

    constructor(props) {
        super(props);
        this.state.search = '';
        this.state.searchimage_light_box = '';
        this.state.open_light_box = false;
        this.state.dataExport = [];
        this.state.project_name = "";
        this.state.namePrint = "";
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.toggleLightBox = this.toggleLightBox.bind(this);
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        fetchQuery(environment, allResidential, {
            search: this.state.search
        }).then(response => {
            if (response?.allResidential) {
                let nameToken = jwtDecode(localStorage.getItem("token"))
                this.setState({
                    dataExport: response?.allResidential?.edges,
                    project_name: response?.selfProject?.name,
                    namePrint: nameToken.first_name + " " + nameToken.last_name
                })
            }
        })
    }

    onChangeSearch(text) {
        this.setState({ search: text }, () => {
            this.getData()
        });
    }

    toggleLightBox(open_light_box, image = '') {
        this.setState({ open_light_box: open_light_box, image_light_box: image })
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <RegisterTopMenuNavigation mini={true} />
                    <div className="container-fluid box" id="pet">
                        <Translation>
                            {t =>
                                <BackButtonIcon
                                    LinkBack={"/register"}
                                    LinkText={t('register:pet')}
                                    boxHtmlText={
                                        <Dropdown >
                                            <DropdownToggle id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>{t("Allaction:Print")}</DropdownToggle>
                                            <DropdownMenu>
                                                <ExportPetPDF
                                                    project_name={this.state.project_name}
                                                    dataExport={this.state.dataExport}
                                                    namePrint={this.state.namePrint}
                                                />

                                                <ExportPetExcel
                                                    state={this.state}
                                                />
                                            </DropdownMenu>
                                        </Dropdown>
                                    }
                                />
                            }
                        </Translation>

                        <div className="content-inner">
                            <div className="row justify-content-end mb-2">
                                {/*<button type="button" className="btn btn-outline-secondary float-right">*/}
                                {/*    <img src={process.env.PUBLIC_URL + '/images/icons/filter-icon.png'}*/}
                                {/*         alt="filter-icon"/>*/}
                                {/*</button>*/}
                                <div className="col-3">
                                    <Search callback_function={this.onChangeSearch}
                                        search={this.state.search} />
                                </div>
                            </div>

                            <QueryRenderer
                                environment={environment}
                                query={allResidential}
                                cacheConfig={{ use_cache: false }}
                                variables={{
                                    search: this.state.search,
                                    first: this.state.first,
                                    last: this.state.last,
                                }}
                                render={({ error, props }) => {
                                    if (error) {
                                        return <div className="alert alert-danger"
                                            role="alert">{error.message}</div>;
                                    } else if (props) {
                                        return <React.Fragment>
                                            <div className="card fade-up">
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead className="thead-light">
                                                            <Translation>
                                                                {t =>
                                                                    <tr>
                                                                        <th>{t("pats:Room")}</th>
                                                                        <th>{t("pats:Pet type")}</th>
                                                                        <th>{t("pats:Pet feature")}</th>
                                                                        <th className="text-center">{t("pats:Picture")}</th>
                                                                        <th className="text-center">{t("pats:Health check docs")}</th>
                                                                    </tr>}
                                                            </Translation>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                props.allResidential.edges.map((residential_pet) =>
                                                                    <tr key={residential_pet.node.id}>
                                                                        <td width="200">
                                                                            <Link
                                                                                to={{
                                                                                    pathname: "/register/residential/detail/pet-detail/" + residential_pet.node.id
                                                                                }}>
                                                                                {residential_pet.node.name}
                                                                            </Link>
                                                                        </td>
                                                                        <td width="200">
                                                                            {
                                                                                residential_pet.node.pet.edges.map((pet_type) =>
                                                                                    <p key={pet_type.node.id}>{pet_type.node.petType}</p>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                residential_pet.node.pet.edges.map((pet_description) =>
                                                                                    <p key={pet_description.node.id}>{pet_description.node.description}</p>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">

                                                                            {
                                                                                residential_pet.node.pet.edges.map((pet_img) =>
                                                                                    <p key={pet_img.node.id}>
                                                                                        <img src={pet_img.node.imagePet}
                                                                                            alt="pet-profile"
                                                                                            className="img-fluid cursor pet"
                                                                                            onClick={() => this.toggleLightBox(true, pet_img.node.imagePet)}
                                                                                        />
                                                                                    </p>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">

                                                                            {
                                                                                residential_pet.node.pet.edges.map((pet_docs) => {
                                                                                    let opt2 = pet_docs.node?.healthCheckName && pet_docs.node?.healthCheckName
                                                                                    let typeFile
                                                                                    if (opt2) {
                                                                                        typeFile = opt2.slice(opt2.lastIndexOf('.') + 1)
                                                                                    }
                                                                                    return (

                                                                                        pet_docs.node.healthCheckName ?
                                                                                            <p key={"PetHealthCheckName::" + pet_docs.node.id}>
                                                                                                <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile + "Icon.png"} style={{ width: '30px' }} /> &nbsp;
                                                                                                <Link
                                                                                                    to="route"
                                                                                                    onClick={(event) => {
                                                                                                        event.preventDefault();
                                                                                                        window.open(
                                                                                                            pet_docs.node.healthCheckPath
                                                                                                        )
                                                                                                    }}
                                                                                                    target="blank">
                                                                                                    {pet_docs.node.healthCheckName}
                                                                                                </Link>
                                                                                            </p> : <p className='pb-2'> - </p>

                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <Pagination changePage={this.changePage}
                                                    first={this.state.first}
                                                    last={this.state.last}
                                                    totalCount={props.allResidential.totalCount} />
                                            </div>
                                        </React.Fragment>
                                    }
                                    return <Loading />
                                }}
                            />

                            <Lightbox
                                images={[{ src: this.state.image_light_box }]}
                                onClose={() => this.toggleLightBox(false)}
                                isOpen={this.state.open_light_box}
                                backdropClosesModal={true}
                                showImageCount={false}
                            />
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default PetList;
