import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import WrapperContent from '../../components/wrapper/wrapperContent'
import StockTopMenuNavigation from '../stockTopMenuNavigation'
import { Link } from 'react-router-dom'
import { Translation } from 'react-i18next'
import StockManagementTable from './stockMenagementTable'

import "./style/stockMenagement.scss"

export default class stockManagementList extends Component {
  render() {
    return (
      <Wrapper>
        <Header/>
        <Sidebar/>
        <WrapperContent disabledOverflowX={true}>
          <StockTopMenuNavigation mini={true}/>
          <div className="container-fluid box" id="stockManagementList">
            <div className="row">
              <div className="col">
                <Translation>
                  {
                    t =>
                      <h4 className="mb-4">
                        <Link to="/stockandsupplies">
                          <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                            alt="back" className="back" />
                        </Link>
                        {t('PageList:Stock and Supplies Management')+" "+"(STF)"}
                      </h4>
                  }
                </Translation>

              </div>
            </div>

            <div className="content-inner">
              <StockManagementTable status={this.props.match.params.status}/>
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    )
  }
}
