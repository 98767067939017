import React from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import './styles/project_manager.scss'
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import environment from "../../env/environment";
import {fetchQuery} from "relay-runtime";
import {format} from "date-fns";
import {QueryRenderer} from "react-relay";
import Navigation from './navigation';
import "../projectDashboard.scss"
import SearchProject from '../searchProject';
import numberWithCommas from '../../libs/numberWithComma';
import ModalChangeCommittee from './modalChangeCommittee';
import getQueryCurrentBoard from './graphQL/currentBoard';
import getQueryOldBoard from './graphQL/oldBoard';
import {graphql} from "babel-plugin-relay/macro";
import ComponentPagination from '../../libs/componentPagination';
import Pagination from '../../libs/newPagination';
import localstorage from '../../libs/localstorage';
import Loading from "../../libs/loading"
import { encode } from 'base-64';
import i18next from 'i18next';

const _ = require('lodash');

const query = graphql`
    query projectManagerListQuery ($status: String) {
        chooseTerm (status: $status) {
            edges{
                node{
                    id
                    note
                    startDate
                    expDate
                    numberOfPersons
                }
                    
            }
        }
}

`

class ProjectManagerList extends ComponentPagination {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            status : this.props.match.params.status,
            startDate : new Date(),
            endDate : new Date(),
            search : '',
            settingProjectManager: false,
            numberOfCommittee : 0,
            redirect : false,
            chooseTerm : {
                node : {
                    numberOfPersons : 3,
                    startDate: '',
                    expDate : ''
                }
            },
            siteId: localStorage?.getItem('site_id'),

        };
        this.translatePosition = this.translatePosition.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    handleSearch(text){
        this.setState({search: text})
    }

    componentDidUpdate(){
        if(this.state.status !== this.props.match.params.status){
            this.setState({
                status : this.props.match.params.status
            })
        }
    }

    componentWillMount(){
        this.goFirst()
        let mySiteId = encode("SiteNode:"+this.state.siteId)
        fetchQuery(environment, getQueryCurrentBoard(),{search:'',site_Id:mySiteId}).then(response_data => {
            this.setState({
                numberOfCommittee : response_data.currentTenants.edges.length

            })
        });
        fetchQuery(environment, query ,{status:'current'}).then(response_data => {
            if(response_data.chooseTerm.edges.length > 0){
                this.setState({
                chooseTerm : response_data.chooseTerm.edges[0],
                settingProjectManager : true
            })
        }  
        });
    }


    translatePosition(position) {
        switch (position) {
            case "president":
                return "President";
            case "committee":
                return "Committee";
            case "treasurer":
                return "Treasurer";
            case "vice_president":
                return "Vice - president";
            case "secretary":
                return "Secretary";
            default:
                return position;
        }
    }

    onDelete(){
        this.setState({redirect : true},() => {
            window.location.reload();
        })
    }



    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <ProjectTopMenuNavigation mini={true}/>
                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="projectDashboard">
                                <div className="row">
                                    <div className="col-md-3 col-xl-3">
                                        <h3 className="mb-4">
                                            <Link to="/project">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <span>{t('project_manager:project_manager')}</span>
                                        </h3>
                                    </div>
                                    <div className="col-md-9 col-xl">
                                        <div className="row col-12 float-right">
                                            <div className="col-12 float-right text-right p-0">                                              
                                                <h4 className='projectDashboardToolTip'>
                                                    <div className="tooltipImg mr-3">
                                                        <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                            alt="question" className="question ml-1" style={{width:'14px'}}/>
                                                        <span className="tooltiptext tooltiptextPositionLeft">                                                                                                                        
                                                            {i18next.t('projectManager:Agenda and Settings')}
                                                        </span>
                                                    </div>     
                                                    {t('projectManager:Current Board')}
                                                </h4>
                                            </div>

                                            <div className="col-12 float-right text-right p-0"><span>{this.state.chooseTerm.node?.startDate !== '' ? format(this.state.chooseTerm.node?.startDate,'DD/MM/YYYY') : '-'} {t('projectManager:to')} {this.state.chooseTerm.node?.expDate !== '' ? format(this.state.chooseTerm.node?.expDate,'DD/MM/YYYY') : '-'} </span></div>
                                            <div className="col-12 float-right text-right p-0"><h4 className='mt-2'>{t('projectManager:The total number of committees is')}  {this.state.numberOfCommittee} {t('projectManager:people')}</h4></div>

                                        </div>
                                    </div>
                                </div>
                                <div className="content-inner">
                                    <Navigation status = {this.state.status} numberOfCommittee = {this.state.numberOfCommittee}/>
                                    <div className="row">
                                        <div className="col-2" />
                                        <div className="col-10  float-right">
                                            {(this.state.status === 'current-board' && this.state.settingProjectManager) && 
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_manager_create'}) &&
                                            this.state.numberOfCommittee < this.state.chooseTerm.node?.numberOfPersons &&
                                            <Link to="/project/project-manager/form-manager/create">
                                                <button type="button" className="add btn btn-primary float-right">
                                                    <span>{t('projectManager:Add committee')}</span>
                                                </button>
                                            </Link>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-2" />
                                        <div className="col-7"/>
                                        <div className="col-3 float-right input-group">
                                            <SearchProject callback_function={this.handleSearch} search={this.state.search}/>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <QueryRenderer 
                                            environment={environment}
                                            query={this.state.status === 'current-board' ? getQueryCurrentBoard() : getQueryOldBoard()}
                                            cacheConfig={{use_cache: false}}
                                            variables={{
                                                search: this.state.search,
                                                status : this.state.status,
                                                first : this.state.first,
                                                last : this.state.last
                                            }}
                                            render ={({error, props}) => {
                                                if(error){
                                                    return <div className="alert alert-danger"
                                                        role="alert">{error.message}</div>;
                                                }else if(props){
                                                    let projectManagerlist = this.state.status === 'current-board' ? props.currentTenants.edges : props.oldTenants.edges
                                                    let totalCount = this.state.status === 'current-board' ? props.currentTenants.totalCount : props.oldTenants.totalCount 
                                                    return(
                                                        <React.Fragment>
                                                            <div className="table-responsive personal-info" >
                                                                <table className="table table-hover">
                                                                    <thead className="thead-light ">
                                                                        <tr>
                                                                            <th className="text-center " >{t("project_manager:No")}</th>
                                                                            <th className="text-center " >{t("project_manager:Picture")}</th>
                                                                            <th className="text-center" width={150} >{t("project_manager:Name - surname")}</th>
                                                                            <th className="text-center " >{t("project_manager:Unit")}</th>
                                                                            <th className="text-center " >{t("project_manager:Position")}</th>
                                                                            <th className="text-center " >{t("project_manager:Authorization right")}</th>
                                                                            <th className="text-center " >{t("project_manager:Maximum approval limit")}</th>
                                                                            <th className="text-center " >{t("project_manager:Term start")}</th>
                                                                            <th className="text-center" width={100} >{t("project_manager:Signature")}</th>
                                                                            <th className="text-center " >{t("project_manager:Number of times to take position")}</th>
                                                                            {this.state.status === 'current-board' && <th width={20} />}
                                                                            {this.state.status !== 'current-board' && <th>{t("project_manager:Note")}</th>}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {projectManagerlist.map((member,index) => {
                                                                            let order_id = this.state.first - localstorage.getPageLimit();
                                                                            return(
                                                                                <tr key ={member.node.id}>
                                                                                    <td className="text-center">{order_id+index+1}</td>
                                                                                    <td className="text-center">
                                                                                    {member.node.boadPersonal?.edges[0]?.node.image ?
                                                                                            <div
                                                                                                className="rounded-circle juristic avatar-img profile-img "
                                                                                                style={{backgroundImage: `url(${member.node.boadPersonal?.edges[0]?.node.image})`}}>
                                                                                            </div>
                                                                                            :
                                                                                            <img
                                                                                                src={process.env.PUBLIC_URL + '/images/icons/profile-manager-icon.png'}
                                                                                                className="rounded-circle avatar-img juristic profile-img"
                                                                                                alt="project-manager-profile"/>
                                                                                    }
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        <Link
                                                                                            to={"/project/project-manager/detail-manager/" +
                                                                                                member.node.id
                                                                                            }
                                                                                        >
                                                                                            {member.node.firstName} {member.node.lastName}
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td className="text-center">{member.node.boadPersonal?.edges[0]?.node?.residential?.name}</td>
                                                                                    <td className="text-center">{t(`change_project_manager:${(this.translatePosition(member.node.boadPersonal?.edges[0]?.node.position))}`)}</td>
                                                                                    <td className="text-center">
                                                                                        <input type="checkbox" disabled={true} checked={member.node.boadPersonal?.edges[0]?.node.authorization}/>
                                                                                    </td>
                                                                                    <td className="text-center">{numberWithCommas(member.node.boadPersonal?.edges[0]?.node.financial,'-')}</td>
                                                                                    <td className="text-center">{format(_.last(member.node.boadTerm.edges).node.startDate,'DD/MM/YYYY')}</td>
                                                                                    <td className="text-center">
                                                                                        {member.node.boadPersonal?.edges[0]?.node.signature ?
                                                                                            <div
                                                                                                className=" avatar-img profile-img juristic"
                                                                                                style={{backgroundImage: `url(${member.node.boadPersonal?.edges[0]?.node.signature})`}}>
                                                                                            </div>
                                                                                            :
                                                                                            <img
                                                                                                src={process.env.PUBLIC_URL + '/images/icons/signature-manager-icon.png'}
                                                                                                className="rounded-circle avatar-img juristic profile-img"
                                                                                                alt="project-manager-profile"/>
                                                                                    }</td>
                                                                                    <td className="text-center">{member.node.boadTerm.edges.length}</td>
                                                                                    {this.state.status === 'current-board' &&
                                                                                        <td className="text-center">
                                                                                            {(_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_manager_edit'}) || _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_manager_delete'})) &&
                                                                                                <span className="btn cursor" id="dropdownMenuButton"
                                                                                                    data-toggle="dropdown" aria-haspopup="true"  aria-expanded="false">
                                                                                                    <img  src={process.env.PUBLIC_URL +"/images/icons/choice-trans.png"} alt="choice"/>
                                                                                                </span>
                                                                                            }
                                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_manager_edit'}) &&
                                                                                                <Link  to={"/project/project-manager/form-manager/edit/" +member.node.id} className="text-black">
                                                                                                    <span className="dropdown-item  cursor">
                                                                                                        <span className="ml-3 text-black"> 
                                                                                                                {t("projectManager:Edit")}
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </Link>
                                                                                            }
                                                                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_manager_delete'}) && 
                                                                                                <ModalChangeCommittee status="delete" id={member.node.boadPersonal?.edges[0]?.node.id} onDelete = {this.onDelete}/>
                                                                                            }
                                                                                            </div>
                                                                                        </td>
                                                                                    }
                                                                                    {this.state.status === 'old-board' &&
                                                                                    <td>{member.node.boadPersonal.edges[0].node.note}</td>

                                                                                    }
                                                            
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="row">
                                                                    <Pagination
                                                                    changePage={this.changePage}
                                                                    first={this.state.first}
                                                                    last={this.state.last}
                                                                    totalCount={totalCount}
                                                                    />
                                                                </div>
                                                        </React.Fragment>
                                                    )

                                                }
                                                return <tbody><tr><td><Loading/></td></tr></tbody>
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default ProjectManagerList;
