import React, { Component } from 'react';
import { Switch } from "react-router-dom";
import PrivateRoute from "../../../libs/privateRoute";

// component
import BudgetDraftAllList from './budgetDraftAllList';
import BudgetDraftCreateUpdate from './budgetDraftCreateUpdate';

class index extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={BudgetDraftAllList} />
        <PrivateRoute path={`${this.props.match.url}/createUpdateForm/:status/:id`} component={BudgetDraftCreateUpdate} />
      </Switch>
    )
  }
}
export default index;