import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import PettyCashAll from "./pettyCashAll"
import _ from "lodash";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperPettyCashAllQuery($setPettyCash_Id: ID!, $start_date: DateTime, $end_date: DateTime) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            taxIdNumber
            juristicContactNumber
            logo
        }
        allPettyCashRecord(setPettyCash_Id: $setPettyCash_Id, status_In: "wait", startDate: $start_date, endDate: $end_date,order: "doc_number"){
            totalCount
            edges{
                node{
                    id
                    issuedDate
                    docNumber
                    chartOfAccount {
                        id
                        chartOfAccountCode
                        name
                    }
                    description
                    price
                    status
                }
            }
        }

        otherExpensePaymentChannelViewer{
            allOtherExpensePaymentChannel(setPettyCash_Id: $setPettyCash_Id , startDate: $start_date, endDate: $end_date ,statusSetPettyCash_In: "wait"){
                totalCount
                edges{
                    node{
                        id
                        otherExpense{
                            id
                            docNumber
                            issuedDate
                            otherExpenseTransaction{
                                edges{
                                    node{
                                        id
                                        description
                                        productAndService{
                                            id
                                            name
                                            productCode
                                        }
                                        chartOfAccount{
                                            id
                                            name
                                            chartOfAccountCode
                                        }

                                    }
                                }
                            }
                        }
                        date
                        description
                        price
                        status
                        statusSetPettyCash
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }
        }

        paymentChannelViewer{
            allPaymentChannel(setPettyCash_Id: $setPettyCash_Id , startDate: $start_date, endDate: $end_date ,statusSetPettyCash_In: "wait"){
                totalCount
                edges{
                    node{
                        id
                        payRecordGroup{
                            id
                            docNumber
                            issuedDate
                            payRecord{
                                edges{
                                    node{
                                        id
                                        purchaseRecord{
                                            id
                                            description
                                            productAndService{
                                                id
                                                name
                                                productCode
                                            }
                                            chartOfAccount{
                                                id
                                                name
                                                chartOfAccountCode
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        date
                        description
                        price
                        status
                        statusSetPettyCash
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }
        }

        allAdvancePettyCashChannel(setPettyCash_Id: $setPettyCash_Id , startDate: $start_date, endDate: $end_date ,statusSetPettyCash: "wait"){
            totalCount
            edges{
                node{
                    id
                    advance{
                        id
                        docNumber
                        issuedDate
                        dueDate
                        withdrawer
                        description
                        amount
                        status
                        voidRemark
                        chartOfAccount{
                            id
                            name
                            chartOfAccountCode
                        }
                    }
                    description
                    price
                    statusSetPettyCash
                    date
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }

        allClearAdvancePettyCashChannel(setPettyCash_Id: $setPettyCash_Id , startDate: $start_date, endDate: $end_date ,statusSetPettyCash: "wait"){
            totalCount
            edges{
                node{
                    id
                    clearAdvance{
                        id
                        docNumber
                        description
                        issuedDate
                        status
                        advance{
                            id
                            withdrawer
                        }
                        clearAdvanceTransaction{
                            edges{
                                node{
                                    id
                                    description
                                    price
                                    refClearAdvanceTransactionDoc
                                    chartOfAccount{
                                        id
                                        name
                                        chartOfAccountCode
                                    }
                                }
                            }
                        }
                    }
                    description
                    price
                    statusSetPettyCash
                    date
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }

        allClearGuaranteeMoneyReceivedPettyCashChannel(setPettyCash_Id: $setPettyCash_Id , startDate: $start_date, endDate: $end_date ,statusSetPettyCash: "wait"){
            totalCount
            edges{
                node{
                    id
                    clearGuaranteeMoneyReceived{
                        id
                        docNumber
                        issuedDate
                        status
                        contact{
                            refNumber
                            firstName
                            lastName
                            name
                        }
                        guarantee {
                            docNumber
                            chartOfAccount {
                                id
                                chartOfAccountCode
                                name
                            }
                        }
                    }
                    description
                    price
                    statusSetPettyCash
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }

        setPettyCash(id: $setPettyCash_Id){
            withdrawer
            description
            docNumber
        }
        userConfig {
            id languages
        }
    }
`;

class WrapperPettyCashAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_set_petty_cash: props.location.state.selected_set_petty_cash,
        };
        this.print = this.print.bind(this);
    }


    print() {
        window.print();
    }
    MergeInfo(props){
        let Merge_info = [];

        //จ่ายเงินสดย่อย
        props.allPettyCashRecord.edges.forEach(element => {
            Merge_info.push(element);
        });
        //จ่ายค่าใช้จ่าย
        let query_petty_case_pay = _.cloneDeep(props.paymentChannelViewer.allPaymentChannel.edges);
        query_petty_case_pay.forEach((payRecordGroup) => {
            Merge_info.push(payRecordGroup);
            // _.set(payRecordGroup,"node.docNumber", payRecordGroup.node.payRecordGroup.docNumber);
            // _.set(payRecordGroup,"node.issuedDate", payRecordGroup.node.payRecordGroup.issuedDate);
            // payRecordGroup.node.payRecordGroup.payRecord.edges.forEach((payRecord,index)=>{
            //     let code = "";
            //     let name_code = "";
            //     if (payRecord.node.purchaseRecord.productAndService) {
            //         code = payRecord.node.purchaseRecord.productAndService.productCode;
            //         name_code = payRecord.node.purchaseRecord.productAndService.name;
            //     } else {
            //         code = payRecord.node.purchaseRecord.chartOfAccount.chartOfAccountCode;
            //         name_code = payRecord.node.purchaseRecord.chartOfAccount.name;
            //     }
            //     _.set(payRecordGroup,"node.code", code);
            //     _.set(payRecordGroup,"node.name_code", name_code);
            //     _.set(payRecordGroup,"node.description", payRecord.node.purchaseRecord.description);
            //     if(payRecordGroup.node.payRecordGroup.payRecord.edges.length === index + 1){
            //         _.set(payRecordGroup,"node.price", payRecordGroup.node.price);
            //     }else{
            //         _.set(payRecordGroup,"node.price", 0);
            //     }
            // })
        });

        //จ่ายอื่นๆ
        let query_petty_case_date = _.cloneDeep(props.otherExpensePaymentChannelViewer.allOtherExpensePaymentChannel.edges);
        query_petty_case_date.forEach((otherExpense) => {
            Merge_info.push(otherExpense);
            // _.set(otherExpense,"node.docNumber", otherExpense.node.otherExpense.docNumber);
            // _.set(otherExpense,"node.issuedDate", otherExpense.node.otherExpense.issuedDate);
            // otherExpense.node.otherExpense.otherExpenseTransaction.edges.forEach((otherExpenseTransaction,index)=>{
            //     let code = "";
            //     let name_code = "";
            //     if (otherExpenseTransaction.node.productAndService) {
            //         code = otherExpenseTransaction.node.productAndService.productCode;
            //         name_code = otherExpenseTransaction.node.productAndService.name;
            //     } else {
            //         code = otherExpenseTransaction.node.chartOfAccount.chartOfAccountCode;
            //         name_code = otherExpenseTransaction.node.chartOfAccount.name;
            //     }
            //     _.set(otherExpense,"node.code", index);
            //     _.set(otherExpense,"node.name_code", name_code);
            //     _.set(otherExpense,"node.description", otherExpenseTransaction.node.description);
            //     if(otherExpense.node.otherExpense.otherExpenseTransaction.edges.length === index + 1){
            //         _.set(otherExpense,"node.price", otherExpense.node.price);
            //     }else{
            //         _.set(otherExpense,"node.price", 0);
            //     }
            //     Merge_info.push(otherExpense);
            // })
        });

        //เงินทดรองจ่าย
        let query_petty_case_advance = _.cloneDeep(props.allAdvancePettyCashChannel.edges);
        query_petty_case_advance.forEach((advance) => {
            Merge_info.push(advance);
        });

        //เคลียร์เงินทดรองจ่าย
        let query_petty_case_clear_advance = _.cloneDeep(props.allClearAdvancePettyCashChannel.edges);
        query_petty_case_clear_advance.forEach((clear_advance) => {
            Merge_info.push(clear_advance);
        });

        //เคลียร์เงินค้ำประกัน
        let query_petty_case_clear_guarantee_money_received = _.cloneDeep(props.allClearGuaranteeMoneyReceivedPettyCashChannel.edges);
        query_petty_case_clear_guarantee_money_received.forEach((clear_guarantee_money_received) => {
            Merge_info.push(clear_guarantee_money_received);
        });
        return Merge_info;
    }


    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                        @media print {
                            html, body {
                                width: 210mm;
                                height: 297mm;
                            }    
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>CashDeposit</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png"
                             alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: true}}
                    variables={{
                        setPettyCash_Id: this.state.selected_set_petty_cash,
                        start_date: this.props.location.state.start_date,
                        end_date: this.props.location.state.end_date
                    }}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            let transaction_per_page = 15;

                            let Merge_info = this.MergeInfo(props);
                            // let petty_cash_count = props.allPettyCashRecord.totalCount;


                            // หาค่า transaction_per_page ทั้งหมดแบบง่าย
                            let sumLine = 0
                            _.forEach(Merge_info , (value , index) => {
                                sumLine += value.node.description.length
                                if(index+1 === 15){
                                    if(sumLine >= 1100){
                                        transaction_per_page -= 1
                                    }
                                    else if(value.node.description.length >= 1200){
                                        transaction_per_page -= 2
                                    }
                                    else if(value.node.description.length >= 1300){
                                        transaction_per_page -= 3
                                    }
                                    else if(value.node.description.length >= 1400){
                                        transaction_per_page -= 4
                                    }
                                    else if(value.node.description.length >= 1500){
                                        transaction_per_page -= 5
                                    }
                                    else if(value.node.description.length >= 1600){
                                        transaction_per_page = 10
                                    }
                                }
                            })

                            let petty_cash_count = Merge_info.length;
                            let count_page = Math.ceil((petty_cash_count) / transaction_per_page);
                            let arrX = Array.from(new Array(count_page));
                            

                            let first_date = this.props.location.state.start_date;
                            let last_date = this.props.location.state.end_date;
                            if (!(first_date && last_date) && petty_cash_count > 0) {
                                if (petty_cash_count === 1) {
                                    first_date = Merge_info[0].node.issuedDate ? Merge_info[0].node.issuedDate : Merge_info[0].node.date
                                } else {
                                    first_date = Merge_info[0].node.issuedDate ? Merge_info[0].node.issuedDate : Merge_info[0].node.date
                                    last_date = Merge_info[petty_cash_count - 1].node.issuedDate
                                }
                            }
                            let total_sum_page = 0.0;
                            return (
                                <React.Fragment>
                                    {arrX.map((value, index) => {
                                        let petty_cash_list = Merge_info.slice(transaction_per_page * index, transaction_per_page * (index + 1));
                                        let total = petty_cash_list.reduce(function (total, obj) {
                                            return total + parseFloat(obj.node.price)
                                        }, 0);
                                        total_sum_page += total;
                                        return (
                                            <PettyCashAll query={props} start_date={first_date}
                                                          end_date={last_date}
                                                          key={index}
                                                          petty_cash_page={index + 1}
                                                          end_page={count_page - 1}
                                                          current_page={index}
                                                          total={total}
                                                          total_sum_page={total_sum_page}
                                                          petty_cash_list={petty_cash_list}
                                                          languages={props.userConfig.languages}
                                            />
                                        )
                                    })}

                                </React.Fragment>

                            )
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperPettyCashAll;
