import i18next from 'i18next';
import React, { Component } from 'react'
import { Translation } from 'react-i18next';
import DatePickerNoti from '../../../accounting/finance/depositInterest/datePickerNoti';
class formContactDetails extends Component {

  render() {
    return (
      <Translation>
        {t =>
          <div id="formContactDetails">
            <h4>{i18next.t("purchaseRequisition:Contact detail")}</h4>
            <div className="lineBottom" />

            <div className="row">
              <div className="col-4">
                <h6>{i18next.t("purchaseRequisition:Contact name")}</h6>
                <div className="input-group">
                  <input type="text"
                    disabled={this.props.disibleInput}
                    className="form-control"
                    name="purchaseRequisition.contractor"
                    value={this.props.purchaseRequisition.contractor}
                    onChange={this.props.handleChangeInput}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-4">
                <h6>{i18next.t("purchaseRequisition:Phone number")} {!this.props.disibleInput && <span className='text-danger'>* {i18next.t("purchaseRequisition:maximum 10 digits")}</span>}</h6>
                <div className="input-group">
                  <input
                    disabled={this.props.disibleInput}
                    type="text" maxLength="10"
                    className="form-control"
                    name="purchaseRequisition.contractorTel"
                    value={this.props.purchaseRequisition.contractorTel}
                    onChange={this.props.handleChangeInput}
                  />
                </div>
              </div>

              <div className="col-4">
                <h6>{i18next.t("purchaseRequisition:E-Mail")}</h6>
                <div className="input-group">
                  <input
                    disabled={this.props.disibleInput}
                    type="text"
                    className="form-control"
                    name="purchaseRequisition.contractorEmail"
                    value={this.props.purchaseRequisition.contractorEmail}
                    onChange={this.props.handleChangeInput}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-4">
                <h6>{i18next.t("purchaseRequisition:Delivery address")}</h6>
                <div className="input-group">
                  <textarea
                    disabled={(this.props.disibleInput || this.props.type === "service" || this.props.type === "service_contact")}
                    className={(this.props.disibleInput || this.props.type === "service" || this.props.type === "service_contact") ? "bodyTextArea-disibled" : "bodyTextArea"}
                    name="purchaseRequisition.deliveryAddress"
                    value={this.props.purchaseRequisition.deliveryAddress}
                    onChange={this.props.handleChangeInput}
                  />
                </div>
              </div>

              <div className="col-2 customDatePickerWidth">
                <h6>{i18next.t("purchaseRequisition:Delivery date")}</h6>
                <DatePickerNoti
                  disabled={this.props.disibleInput}
                  selected={this.props.purchaseRequisition.deliveryDate}
                  name='purchaseRequisition.deliveryDate'
                  onChange={this.props.handleChangeInput}
                />
              </div>
            </div>
          </div>
        }
      </Translation>
    )
  }
}

export default formContactDetails;
