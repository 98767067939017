/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollectionLettersStatus = "ACTIVE" | "DRAFT" | "PAID" | "VOID" | "%future added value";
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type collectionLetterDetailQueryVariables = {|
  collectionLettersId?: ?$ReadOnlyArray<?string>
|};
export type collectionLetterDetailQueryResponse = {|
  +allCollectionLetters: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +numberOfTimes: number,
        +refDocNumber: ?string,
        +docNumber: string,
        +added: any,
        +issuedDate: ?any,
        +creator: ?string,
        +status: CollectionLettersStatus,
        +creatorDelete: ?string,
        +voidNote: ?string,
        +updated: ?any,
        +sumTotal: ?number,
        +contact: {|
          +id: string,
          +name: string,
          +refNumber: string,
          +firstName: string,
          +lastName: string,
          +registeredName: string,
          +registeredAddress: string,
          +registeredCountry: string,
          +registeredProvince: string,
          +registeredCity: string,
          +registeredDistrict: string,
          +registeredPostalCode: string,
          +typeOfContact: ContactTypeOfContact,
        |},
        +documentCollectionLetters: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: string,
              +fileUpload: string,
              +numPage: number,
            |}
          |}>
        |},
        +recordCollectionLetters: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +sumTotalRecord: ?number,
              +transaction: {|
                +id: string,
                +description: string,
                +total: ?number,
                +invoice: {|
                  +id: string,
                  +docNumber: string,
                  +dueDate: any,
                  +total: number,
                |},
                +productAndService: ?{|
                  +id: string,
                  +productCode: string,
                  +name: string,
                |},
                +chartOfAccount: ?{|
                  +chartOfAccountCode: string
                |},
              |},
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type collectionLetterDetailQuery = {|
  variables: collectionLetterDetailQueryVariables,
  response: collectionLetterDetailQueryResponse,
|};
*/


/*
query collectionLetterDetailQuery(
  $collectionLettersId: [String]
) {
  allCollectionLetters(collectionLettersId: $collectionLettersId) {
    edges {
      node {
        id
        numberOfTimes
        refDocNumber
        docNumber
        added
        issuedDate
        creator
        status
        creatorDelete
        voidNote
        updated
        sumTotal
        contact {
          id
          name
          refNumber
          firstName
          lastName
          registeredName
          registeredAddress
          registeredCountry
          registeredProvince
          registeredCity
          registeredDistrict
          registeredPostalCode
          typeOfContact
        }
        documentCollectionLetters {
          edges {
            node {
              id
              fileName
              fileUpload
              numPage
            }
          }
        }
        recordCollectionLetters {
          edges {
            node {
              id
              sumTotalRecord
              transaction {
                id
                description
                total
                invoice {
                  id
                  docNumber
                  dueDate
                  total
                }
                productAndService {
                  id
                  productCode
                  name
                }
                chartOfAccount {
                  chartOfAccountCode
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collectionLettersId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "collectionLettersId",
    "variableName": "collectionLettersId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfTimes",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refDocNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorDelete",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidNote",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sumTotal",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredProvince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredDistrict",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredPostalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfContact",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "DocumentCollectionLettersNodeConnection",
  "kind": "LinkedField",
  "name": "documentCollectionLetters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentCollectionLettersNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DocumentCollectionLettersNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileUpload",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sumTotalRecord",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "InvoiceNode",
  "kind": "LinkedField",
  "name": "invoice",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    (v19/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductAndServiceNode",
  "kind": "LinkedField",
  "name": "productAndService",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productCode",
      "storageKey": null
    },
    (v14/*: any*/)
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionLetterDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionLettersNodeConnection",
        "kind": "LinkedField",
        "name": "allCollectionLetters",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionLettersNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionLettersNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecordCollectionLettersNodeConnection",
                    "kind": "LinkedField",
                    "name": "recordCollectionLetters",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecordCollectionLettersNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RecordCollectionLettersNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v17/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TransactionNode",
                                "kind": "LinkedField",
                                "name": "transaction",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v18/*: any*/),
                                  (v19/*: any*/),
                                  (v20/*: any*/),
                                  (v21/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ChartOfAccountNode",
                                    "kind": "LinkedField",
                                    "name": "chartOfAccount",
                                    "plural": false,
                                    "selections": [
                                      (v22/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "collectionLetterDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionLettersNodeConnection",
        "kind": "LinkedField",
        "name": "allCollectionLetters",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionLettersNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionLettersNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RecordCollectionLettersNodeConnection",
                    "kind": "LinkedField",
                    "name": "recordCollectionLetters",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecordCollectionLettersNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RecordCollectionLettersNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v17/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TransactionNode",
                                "kind": "LinkedField",
                                "name": "transaction",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v18/*: any*/),
                                  (v19/*: any*/),
                                  (v20/*: any*/),
                                  (v21/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ChartOfAccountNode",
                                    "kind": "LinkedField",
                                    "name": "chartOfAccount",
                                    "plural": false,
                                    "selections": [
                                      (v22/*: any*/),
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2226f68ec32f8af7dd75011ab1ae8f7d",
    "id": null,
    "metadata": {},
    "name": "collectionLetterDetailQuery",
    "operationKind": "query",
    "text": "query collectionLetterDetailQuery(\n  $collectionLettersId: [String]\n) {\n  allCollectionLetters(collectionLettersId: $collectionLettersId) {\n    edges {\n      node {\n        id\n        numberOfTimes\n        refDocNumber\n        docNumber\n        added\n        issuedDate\n        creator\n        status\n        creatorDelete\n        voidNote\n        updated\n        sumTotal\n        contact {\n          id\n          name\n          refNumber\n          firstName\n          lastName\n          registeredName\n          registeredAddress\n          registeredCountry\n          registeredProvince\n          registeredCity\n          registeredDistrict\n          registeredPostalCode\n          typeOfContact\n        }\n        documentCollectionLetters {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n              numPage\n            }\n          }\n        }\n        recordCollectionLetters {\n          edges {\n            node {\n              id\n              sumTotalRecord\n              transaction {\n                id\n                description\n                total\n                invoice {\n                  id\n                  docNumber\n                  dueDate\n                  total\n                }\n                productAndService {\n                  id\n                  productCode\n                  name\n                }\n                chartOfAccount {\n                  chartOfAccountCode\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b848c92ef4af9932f4ad3222d4148de3';

module.exports = node;
