import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <Translation>
                        {t=>
                        <ul>
                            <li>
                                <NavLink exact={true} to="/register/vehicle/all">
                                    {t("venhicle:All")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/register/vehicle/car">
                                    {t("venhicle:Car")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/register/vehicle/motorcycle">
                                    {t("venhicle:Motorcycle")}
                                </NavLink>
                            </li>
                        </ul>}
                    </Translation>
                </div>
            </div>
        )
    }
}

export default Navigation;