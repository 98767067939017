import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import LogTransferList from './logsTransfer/logsTransferList';
import LogHistoryList from './logsHistory/logsHistoryList';
import vmsLogsList from './logsVMS/vmsLogsList';
import repairsRoomLogsList from './logsRepairsRoom/repairsRoomLogsList';

class Logs extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute path={`${this.props.match.url}/log-transfer`} component={LogTransferList}/>
                <PrivateRoute path={`${this.props.match.url}/log-history`} component={LogHistoryList}/>
                <PrivateRoute path={`${this.props.match.url}/log-vms`} component={vmsLogsList}/>
                <PrivateRoute path={`${this.props.match.url}/log-repairs-room`} component={repairsRoomLogsList}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Logs;