import React from "react";
import ComponentPagination from "../../../../libs/componentPagination";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import {format} from "date-fns";
import numberWithComma from "../../../../libs/numberWithComma";
import {QueryRenderer} from "react-relay";
import Pagination from "../../../../libs/newPagination";
import Loading from "../../../../libs/loading";
import i18next from "i18next";


const query = graphql`
    query payableClassifiedReportChartTableQuery($first: Int, $last: Int, $startMonth: Int, $startYear: Int, $endMonth: Int, $endYear: Int, $search: String){
        payableClassifiedSummary(startMonth: $startMonth,startYear: $startYear,first: $first, last: $last, search: $search, endMonth: $endMonth,endYear: $endYear){
            totalCount
            edges{
                node{
                    id
                    chartOfAccountCode
                    name
                    
                    col1Total
                    col2Total
                    col3Total
                    col4Total
                    col5Total
                    col6Total
                    col7Total
                    col8Total
                    col9Total
                    col10Total
                    col11Total
                    col12Total
                    colAllTotal

                    col1Paid
                    col2Paid
                    col3Paid
                    col4Paid
                    col5Paid
                    col6Paid
                    col7Paid
                    col8Paid
                    col9Paid
                    col10Paid
                    col11Paid
                    col12Paid
                    colAllPaid

                    col1NotPay
                    col2NotPay
                    col3NotPay
                    col4NotPay
                    col5NotPay
                    col6NotPay
                    col7NotPay
                    col8NotPay
                    col9NotPay
                    col10NotPay
                    col11NotPay
                    col12NotPay
                    colAllNotPay
                }
            }
        }
    }
`;

class PayableClassifiedReportChartTable extends ComponentPagination {

    calPercent(not_pay, all_total) {
        let cal = (not_pay / all_total) * 100;
        if (cal > 0) {
            return numberWithComma(cal) + '%'
        } else {
            return '-'
        }
    }

    render() {
        let all_total = 0;
        let all_paid = 0;
        let all_not_pay = 0;
        return (
            <React.Fragment>
                <div className="row mt-3">
                    <div className="col-12">
                        <QueryRenderer
                            environment={environment}
                            query={query}
                            cacheConfig={{use_cache: false}}
                            variables={{
                                first: this.props.state.first,
                                last: this.props.state.last,
                                startMonth: this.props.state.start_period.getMonth(),
                                startYear: this.props.state.start_period.getFullYear(),
                                endMonth: this.props.state.end_period.getMonth(),
                                endYear: this.props.state.end_period.getFullYear(),
                                search: this.props.state.search
                            }}
                            render={({error, props}) => {

                                if (props) {
                                    props.payableClassifiedSummary.edges.forEach((chart) => {
                                        let node = chart.node;
                                        all_total = all_total + node.colAllTotal;
                                        all_paid = all_paid + node.colAllPaid;
                                        all_not_pay = all_not_pay + node.colAllNotPay;
                                    });
                                    return (
                                        <React.Fragment>
                                            <div className="card fade-up">
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead className="thead-light">
                                                            <tr>
                                                            <th className="text-center">{i18next.t("PayableClassifiedReport:Order")}</th>
                                                                <th className="text-center">{i18next.t("PayableClassifiedReport:Account Code")}</th>
                                                                <th>{i18next.t("PayableClassifiedReport:Description")}</th>
                                                                <th className="text-center">{i18next.t("PayableClassifiedReport:Month")}</th>
                                                                <th className="text-right">{i18next.t("PayableClassifiedReport:Amount")}</th>
                                                                <th className="text-right">{i18next.t("PayableClassifiedReport:Paid")}</th>
                                                                <th className="text-right">{i18next.t("PayableClassifiedReport:Outstanding Amount")}</th>
                                                                <th className="text-right">{i18next.t("PayableClassifiedReport:Percentage Outstanding")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { props.payableClassifiedSummary.edges.map((product, product_index) => {
                                                                let index_row = 0;
                                                                return (
                                                                    <React.Fragment key={product.node.id}>
                                                                        {this.props.state.month_list.map((month, index) => {
                                                                            if (product.node["col" + (index + 1) + "Total"] !== 0) {
                                                                                index_row += 1;
                                                                            }
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    {product.node["col" + (index + 1) + "Total"] !== 0 && (
                                                                                        <tr>
                                                                                            <td className="text-center">
                                                                                                {index_row === 1 && product_index + 1}
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                {index_row === 1 && product.node.productCode}
                                                                                            </td>
                                                                                            <td>
                                                                                                {index_row === 1 && product.node.name}
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                {format(month, "MM/YYYY")}
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                {numberWithComma(product.node["col" + (index + 1) + "Total"])}
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                {numberWithComma(product.node["col" + (index + 1) + "Paid"])}
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                {numberWithComma(product.node["col" + (index + 1) + "NotPay"])}
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                {this.calPercent(product.node["col" + (index + 1) + "NotPay"], product.node["col" + (index + 1) + "Total"])}
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                    {index === this.props.state.last_index && product.node.colAllTotal !== 0 && (
                                                                                        <tr>
                                                                                            <td colSpan={3}></td>
                                                                                            <td className="text-center">
                                                                                                <strong>{i18next.t("PayableClassifiedReport:Sum")}</strong>
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                <strong>
                                                                                                    {numberWithComma(product.node.colAllTotal)}
                                                                                                </strong>
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                <strong>
                                                                                                    {numberWithComma(product.node.colAllPaid)}
                                                                                                </strong>
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                <strong>
                                                                                                    {numberWithComma(product.node.colAllNotPay)}
                                                                                                </strong>
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                <strong>
                                                                                                    {this.calPercent(product.node.colAllNotPay, product.node.colAllTotal)}
                                                                                                </strong>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            );
                                                                        })}
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                            )}
                                                            <tr>
                                                                <td colSpan={3} />
                                                                <td className="text-center">
                                                                    <strong>{i18next.t("PayableClassifiedReport:Total")}</strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <strong>
                                                                        {numberWithComma(all_total)}
                                                                    </strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <strong>
                                                                        {numberWithComma(all_paid)}
                                                                    </strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <strong>
                                                                        {numberWithComma(all_not_pay)}
                                                                    </strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <strong>
                                                                        {this.calPercent(all_not_pay, all_total)}
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <Pagination
                                                    changePage={this.changePage}
                                                    first={this.props.state.first}
                                                    last={this.props.state.last}
                                                    totalCount={props.payableClassifiedSummary.totalCount}
                                                />
                                            </div>
                                        </React.Fragment>
                                    )
                                } else {
                                    return <Loading/>
                                }
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PayableClassifiedReportChartTable;
