import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";

var token_id = localStorage.getItem("token");
var header = {
    authorization: `JWT ${token_id}`,
    "Content-Type": "application/json",
};


function getSlipVerifyLog(dataParams) {
    return axios.get(`${getApiRoot()}kbank/slipverifylog`, { 
        headers: header, 
        params: { 
            qrcode: dataParams.qrcode,
            siteId: dataParams.siteId,
            flagMonth: dataParams.flagMonth
        } 
    },);
}

export default {
    getSlipVerifyLog,
} 