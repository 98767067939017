import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { format, lastDayOfMonth, startOfMonth } from "date-fns";
import numberWithComma from "../../../../libs/numberWithComma";
import { fetchQuery } from "relay-runtime";
import _ from "lodash";
import ProductServiceList from "../productServiceList";
import ExportReceivableClassifiedReport from "./server_export";
import { addMonths, differenceInMonths } from "date-fns";
import postApiUseing from '../../../../libs/postApiUseing';
import Swal from "sweetalert2";
import i18n from 'i18next';
import Loading from "../../../../libs/loading";
import i18next from "i18next";

const query = graphql`
  query receivableClassifiedReportQuery(
    $startMonth: Int
    $startYear: Int
    $endMonth: Int
    $endYear: Int
    $product_and_service: String,
    $asDate: Date
  ) {
    productViewer {
      productSummaryByStatus(
        startMonth: $startMonth
        startYear: $startYear
        productAndService: $product_and_service
        endMonth: $endMonth
        endYear: $endYear
        asDate: $asDate
      ) {
        productServiceNode{
          edges{
              node{
                  id
                  name
                  productCode
              }
            }
          }
        colAllsList
      }
    }
  }
`;
class ReceivableClassifiedReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let now = new Date();
    this.state.start_period = new Date(now.getFullYear(), 0);
    this.state.end_period = new Date(now.getFullYear(), now.getMonth());
    this.state.temp_start_period = new Date(now.getFullYear(), 0);
    this.state.temp_end_period = new Date(now.getFullYear(), now.getMonth());
    this.state.current_year = new Date(now.getFullYear(), 11)
    this.state.search = "";
    this.state.month_list = [];
    this.state.summary_total = 0;
    this.state.summary_paid = 0;
    this.state.summary_not_pay = 0;
    this.state.product_and_service = "";
    this.state.temp_product_and_service = "";
    this.state.last_index = 0;
    this.state.loading = true;
    this.state.asDate = null;
    this.state.search_status = false;

    this.state.list_product_code = [];
    this.state.checkFetchState = true;

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
    this.queryProductSummary = this.queryProductSummary.bind(this);
    this.checkLoadingExcel = this.checkLoadingExcel.bind(this);

  }

  componentWillMount() {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "receivableClassifiedReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
        console.log(error);
    })
  }

  async handleChange(e) {
    if (e.target.name === "temp_start_period") {
      let dif = differenceInMonths(this.state.end_period, e.target.value);
      if ((dif < 0 && dif !== -0) || dif > 12) {
        this.setState({ end_period: addMonths(e.target.value, 11) });
      }
    }
    await this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleSearch(event) {
    if (this.state.asDate) {
      this.setState(
        {
          start_period: this.state.temp_start_period,
          end_period: this.state.temp_end_period,
          search: event.target.value,
          product_and_service: this.state.temp_product_and_service,
          search_status : true
        },
        () => {
          this.setPeriod();
          this.queryProductSummary();
        }
        );
      }else{
        Swal.fire(i18n.t('receivableClassifiedReport:Please select a complete date'), '', 'warning');
      }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.state.asDate !== prevState.asDate) {
    //   this.queryProductSummary();
    // }
    if (this.state.temp_end_period !== prevState.temp_end_period) {
      if (this.state.asDate > this.state.temp_end_period) {
        this.setState({ asDate: null })
      }
    }
  }


  componentDidMount() {
    this.setPeriod();
  }

  setPeriod() {
    let start_month = this.state.start_period.getMonth();
    let start_year = this.state.start_period.getFullYear();
    let month_list = [this.state.start_period];
    let diff_month = Math.abs(
      differenceInMonths(this.state.start_period, this.state.end_period)
    );

    for (let step = 1; step <= diff_month; step++) {
      let new_date = new Date(start_year, start_month + step);
      month_list.push(new_date);
    }
    this.setState({ month_list: month_list, last_index: diff_month });
  }

  queryProductSummary() {
    this.setState({ loading: true })
    this.setState({ checkFetchState: false })
    fetchQuery(environment, query, {
      startMonth: this.state.start_period.getMonth() + 1,
      startYear: this.state.start_period.getFullYear(),
      endMonth: this.state.end_period.getMonth() + 1,
      endYear: this.state.end_period.getFullYear(),
      product_and_service: this.state.product_and_service,
      asDate:format(this.state.asDate, "YYYY-MM-DD")
    }).then((data) => {
      if (_.isObject(data.productViewer.productSummaryByStatus)){
        let list_product_code = []
        let col_list = []

        _.forEach(data.productViewer.productSummaryByStatus.colAllsList, col => {
          col_list.push(JSON.parse(col))
        })

        _.forEach(data.productViewer.productSummaryByStatus.productServiceNode.edges, (product , index) => {
          let col_List = _.filter(col_list,['product_code',product.node.productCode])
          list_product_code.push({...product , col_List : col_List[0] })
        })

        let summary_total = _.sumBy(list_product_code,'col_List.col_all_total');
        let summary_paid = _.sumBy(list_product_code,'col_List.col_all_paid');
        let summary_not_pay = _.sumBy(list_product_code,'col_List.col_all_not_pay');

        this.setState({
          list_product_code : list_product_code,
          summary_total : summary_total,
          summary_paid : summary_paid,
          summary_not_pay : summary_not_pay,
          loading : false,
          search_status : false,
          checkFetchState: true,
        })
      }
      this.setState({ checkFetchState: true })
    }).catch(error => {
      Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
    });
  }

  calPercent(not_pay, all_total) {
    let cal = (not_pay * 100) / all_total;
    if (cal > 0) {
      return numberWithComma(cal) + "%";
    } else {
      return "-";
    }
  }

  onClickGetData(data) {
    this.props.history.push(`/accounting/report/account-receivable/receivable_classified/PDF`,{ 
      data: data,
      list_product_code: this.state.list_product_code,
      summary_total: this.state.summary_total,
      summary_paid: this.state.summary_paid,
      search_status: this.state.search_status,
      month_list: this.state.month_list,
      last_index: this.state.last_index,
    })
  }

  checkLoadingExcel(status) {
    this.setState({ checkFetchState: status });
  }

  render() {
    let order = 0;
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box">
            <div className="row justify-content-between" id="receivableClassifiedReport">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting/report/account-receivable">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => (
                      <span>
                        {i18next.t("receivable_dashboard:Total Accounts Receivable Summary Report")}
                      </span>
                    )}
                  </Translation>
                </h3>
              </div>
              {/* <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "report_receivable_print",
                }) && <ExportReceivableClassifiedReport state={this.state} />}
              </div> */}
              <div className={`col`}>
                  {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                      codename: "report_receivable_print",
                    }) && 
                    // <div className= "dropdown printReport">
                    <div className={`${this.state.checkFetchState ? "dropdown" : ''} printReport`}>
                          {!this.state.checkFetchState ?
                            <React.Fragment>
                              <button type="button" className="btnPrint">
                                <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                <Translation>{t => t("meter:Preparing information")}</Translation>
                              </button>
                            </React.Fragment>
                            :                  
                            <React.Fragment>
                              <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                                <Translation>{t => t("PageList:Print")}</Translation>
                              </button>
                            </React.Fragment>
                          }
                          <div className={`dropdown-menu`}>
                              <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state)}>PDF</a>
                              <ExportReceivableClassifiedReport state={this.state} checkLoadingExcel={this.checkLoadingExcel}/>
                          </div>
                    </div>
                  }  
              </div>
            </div>

            <Translation>
              {t=>
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="float-right ml-2">
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2 d-flex align-items-center justify-content-center">
                      <Translation>
                        {(t) => (
                          <a className="mr-2">
                            <span className="text-danger" style={{fontSize:14}} >*</span>
                            {t('settingAccount:at')}
                          </a>
                        )}
                      </Translation>    

                        <DatePickerAdapter
                          name="asDate"
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.asDate}
                          onChange={this.handleChange}
                          maxDate={lastDayOfMonth(this.state.temp_end_period) }
                          minDate={startOfMonth(this.state.temp_start_period)}
                        />
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_period"
                          showMonthYearPicker={true}
                          className="form-control"
                          dateFormat="MM/yyyy"
                          selected={this.state.temp_end_period}
                          maxDate={this.state.current_year}
                          minDate={this.state.temp_start_period}
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_period"
                          showMonthYearPicker={true}
                          className="form-control"
                          dateFormat="MM/yyyy"
                          selected={this.state.temp_start_period}
                          maxDate={this.state.current_year}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <ProductServiceList
                            handleChange={this.handleChange}
                            product_and_service={
                              this.state.temp_product_and_service
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="card fade-up">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead className="thead-light">
                              <tr>
                                <th className="text-center">{i18next.t("receivableClassifiedReport:Sequence")}</th>
                                <th className="text-center">{i18next.t("receivableClassifiedReport:Account Code")}</th>
                                <th>{i18next.t("receivableClassifiedReport:Description")}</th>
                                <th className="text-center">{i18next.t("receivableClassifiedReport:Month")}</th>
                                <th className="text-right">{i18next.t("receivableClassifiedReport:Amount")}</th>
                                <th className="text-right">{i18next.t("receivableClassifiedReport:Paid")}</th>
                                <th className="text-right">{i18next.t("receivableClassifiedReport:Outstanding Amount")}</th>
                                <th className="text-right">{i18next.t("receivableClassifiedReport:Percentage Outstanding")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.loading ?
                                <tr style={{ height: "50vh" }}>
                                  <td colSpan={8}>
                                    {!this.state.search_status ?
                                      <Translation>
                                        {(t) => (
                                          <span className="d-flex justify-content-center align-item-center" >
                                            {t('receivableClassifiedReport:Please select a complete date')}
                                          </span>)}
                                      </Translation>
                                      :
                                      <Loading />
                                    } 
                                  </td>
                                </tr> :
                                <React.Fragment>
                                  {_.sortBy(this.state.list_product_code, 'node.productCode').map((product, product_index) => {
                                    let index_row = 0;
                                    if (product.col_List.col_all_total !== 0) {
                                      order += 1
                                    }
                                    return (
                                      <React.Fragment key={product.node.id}>
                                        {this.state.month_list.map((month, index) => {
                                          if (product.col_List["col" + (index + 1) + '_total'] !== 0) {
                                            index_row += 1;
                                          }
                                          return (
                                            <React.Fragment key={index}>
                                              {product.col_List["col" + (index + 1) + '_total'] !== 0 && (
                                                <tr>
                                                  <td className="text-center">
                                                    {index_row === 1 && order}
                                                  </td>
                                                  <td className="text-center">
                                                    {index_row === 1 && product.node.productCode}
                                                  </td>
                                                  <td>
                                                    {index_row === 1 && product.node.name}
                                                  </td>
                                                  <td className="text-center">
                                                    {format(month, "MM/YYYY")}
                                                  </td>
                                                  <td className="text-right">
                                                    {numberWithComma(product.col_List["col" + (index + 1) + '_total'])}
                                                  </td>
                                                  <td className="text-right">
                                                    {numberWithComma(product.col_List["col" + (index + 1) + '_paid'])}
                                                  </td>
                                                  <td className="text-right">
                                                    {numberWithComma(product.col_List["col" + (index + 1) + '_not_pay'])}
                                                  </td>
                                                  <td className="text-right">
                                                    {this.calPercent(product.col_List["col" + (index + 1) + '_not_pay'], product.col_List["col" + (index + 1) + '_total'])}
                                                  </td>
                                                </tr>
                                              )}
                                              {index === this.state.last_index && product.col_List["col_all_total"] !== 0 && (
                                                <tr>
                                                  <td colSpan={3}></td>
                                                  <td className="text-center">
                                                    <strong>{i18next.t("receivableClassifiedReport:Sum")}</strong>
                                                  </td>
                                                  <td className="text-right">
                                                    <strong>
                                                      {numberWithComma(product.col_List["col_all_total"])}
                                                    </strong>
                                                  </td>
                                                  <td className="text-right">
                                                    <strong>
                                                      {numberWithComma(product.col_List["col_all_paid"])}
                                                    </strong>
                                                  </td>
                                                  <td className="text-right">
                                                    <strong>
                                                      {numberWithComma(product.col_List["col_all_not_pay"])}
                                                    </strong>
                                                  </td>
                                                  <td className="text-right">
                                                    <strong>
                                                      {this.calPercent(product.col_List["col_all_not_pay"], product.col_List["col_all_total"])}
                                                    </strong>
                                                  </td>
                                                </tr>
                                              )}
                                            </React.Fragment>
                                          );
                                        })}
                                      </React.Fragment>
                                    );
                                  }
                                  )}
                                  <tr>
                                    <td colSpan={3} />
                                    <td className="text-center">
                                      <strong>{i18next.t("receivableClassifiedReport:Total")}</strong>
                                    </td>
                                    <td className="text-right">
                                      <strong>
                                        {numberWithComma(this.state.summary_total)}
                                      </strong>
                                    </td>
                                    <td className="text-right">
                                      <strong>
                                        {numberWithComma(this.state.summary_paid)}
                                      </strong>
                                    </td>
                                    <td className="text-right">
                                      <strong>
                                        {numberWithComma(this.state.summary_not_pay)}
                                      </strong>
                                    </td>
                                    <td className="text-right">
                                      <strong>
                                        {this.calPercent(this.state.summary_not_pay, this.state.summary_total)}
                                      </strong>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Translation>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ReceivableClassifiedReport;
