import { Creatable } from 'react-select';
import React  from 'react';
const _ = require('lodash');

class CreatableSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: {},
            options: undefined,
        };
        this.handleChange = this.handleChange.bind(this);
        this.setDropDown = this.setDropDown.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.options !== this.props.options) {
            this.setDropDown(this.props.options)
        }
    }

    setDropDown(list) {
        let residential_householder = list?.node?.residentialHouseholder?.edges
        let options = undefined
        if (residential_householder) {
            options = _.map(residential_householder, ({ node }) => {
                return { value: node?.tenant?.id, label: `${node?.tenant?.firstName} ${node?.tenant?.lastName}` }
            })
            this.setState({ options })
        }
    }

    handleChange(changed_value) {
        let value = changed_value || { value: "" };
        let event = {
            target: {
                name: this.props.name,
                value: value,
                className: this.props.className,
                dataset: { id: this.props['data-id'], key: this.props['data-key'] }
            }
        };
        this.props.onChange(event);
    }


    render() {
        return (
            <>
                <div style={{ position: 'relative', whiteSpace: this.props.pre_wrap ? 'pre-wrap' : '' }}>
                    <Creatable
                        isClearable
                        options={this.state.options}
                        onChange={this.handleChange}
                        require
                    />

                    <input name="req" type="text" value={this.props.value || ""}
                        style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                        onChange={() => { this.inputTitle.value = "" }}
                        ref={el => this.inputTitle = el}
                        required={this.props.require} />
                </div>

            </>
        );
    };
};


export default CreatableSelect;