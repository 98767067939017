import React, { Component, forwardRef } from 'react'
import DatePicker from "react-datepicker";
import {addMonths, format, setMonth , addDays} from "date-fns";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import _ from "lodash"
import chartJson from "../json/chart_of_account_budget.json"
import Loading from '../../../libs/loading';
import Swal from 'sweetalert2';
import '../styles/createBudget.scss'
import { Redirect } from "react-router-dom";
import BudgetLastYearCompareWithYear from './budgetYearCompareWithYear/budgetLastYearCompareWithYear';
import BudgetLastYearCompareWithMonth from './budgetLastYearCompareWithMonth/budgetLastYearCompareWithMonth';
import BudgetDraftYearCompareAverageYear from './budgetYearCompareAverageYear/budgetDraftYearCompareAverageYear';
import CreateBudgetMutations from './mutations/createBudgetMutations';
import EditBudgetMutations from './mutations/editBudgetMutations'
import CreatePreviousBudget from './mutations/createPreviousBudget';
import EditPreviousBudget from './mutations/editPreviousBudget'
import DeleteBudget from './mutations/deleteBudget';
import DownloadDocument from './downloadDocument';
import i18next from 'i18next';
import { Translation } from 'react-i18next';


const query = graphql`
  query budgetDraftFormQuery($previousYear: String, $yearId: String, $previousStartDate: Date, $previousEndDate: Date, $startDate: Date, $endDate: Date) {
    viewer {
      allChartOfAccount(getHistoricalValue: true) {
        totalCount
        edges {
          node {
            id
            name
            nameEn
            chartOfAccountCode
            totalHistoricalDebit
            totalHistoricalCredit
          }
        }
      }
    }
    previousYearChartOfAccountBudget(previousYear: $previousYear, startDate: $previousStartDate, endDate: $previousEndDate){
      edges{
        node{
          sumBudget
          totalHappend
          chartOfAccount{
            id 
            name 
            nameEn 
            chartOfAccountCode 
          }
        }
      }
    } 
    oneYearBudget(yearId: $yearId) {
      edges {
        node {
          type
          years
          startDate
          endDate
          previousYearBudget{
            edges{
              node{
                id
                startDate
                endDate
                previousChartOfAccountBudget{
                  edges{
                    node{
                      chartOfAccount{
                        id 
                        name 
                        nameEn 
                        chartOfAccountCode 
                      }
                      totalBudget
                      totalHappend
                    }
                  }
                }
              }
            }
          }
          chartOfAccountBudget{
            edges{
              node{
                totalBudget
                chartOfAccount{
                  id 
                  name 
                  nameEn 
                  chartOfAccountCode 
                }
                monthBudget{
                  edges{
                    node{
                      months
                      budget
                    }
                  }
                }
              }
              
            }
          }
          approveYearBudget{
            edges{
              node{
                years
                startDate
                endDate
                approveChartOfAccountBudget{
                  edges{
                    node{
                      totalBudget
                      chartOfAccount{
                        id 
                        name 
                        nameEn 
                        chartOfAccountCode 
                      }
                      approveMonthBudget{
                        edges{
                          node{
                            months
                            budget
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } 
    checkRangeYearBudget(startDate: $startDate, endDate: $endDate)
    checkRangeLastYearBudget: checkRangeYearBudget(startDate: $startDate, endDate: $endDate, yearId: $yearId)
  }
`

class budgetDraftForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      lists: [],
      year: new Date(),
      start_date: new Date(2021, 0, 1),
      end_date: new Date(2021, 11, 31),
      temp_year: new Date(),
      temp_start_date: new Date(2021, 0, 1),
      temp_end_date: new Date(2021, 11, 31),
      textSelect: "",
      checkTextSelect: "",
      loadingFetch: false,
      checkDataFetch: false,
      loadingSave: false,
      redirectTo: false,
      queryAllData: [],
      permissionEdit: _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_edit' }) ?
        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_edit' }) : false
    }
  }

  async componentDidMount() {
    if (this.props.mode === "edit") {
      await this.setState({
        year: new Date(this.props.query.years),
        start_date: new Date(this.props.query.startDate),
        end_date: new Date(this.props.query.endDate),
        temp_year: new Date(this.props.query.years),
        temp_start_date: new Date(this.props.query.startDate),
        temp_end_date: new Date(this.props.query.endDate),
      })

      await this.queryBudgetLYComparedAnnualBudget("first")
    }
  }

  queryBudgetLYComparedAnnualBudget(first) {
    let lastYear = _.cloneDeep(this.state.year)
    lastYear.setFullYear(lastYear.getFullYear() - 1)
    let lastStartDate = _.cloneDeep(this.state.start_date)
    lastStartDate.setFullYear(lastStartDate.getFullYear() - 1)
    let lastEndDate = _.cloneDeep(this.state.end_date)
    lastEndDate.setFullYear(lastEndDate.getFullYear() - 1)

    if (this.props.mode === "create") {

      fetchQuery(
        environment,
        query,
        {
          previousYear: format(lastYear, "YYYY"),
          previousStartDate: format(lastStartDate, "YYYY-MM-DD"),
          previousEndDate: format(lastEndDate, "YYYY-MM-DD"),
          startDate: format(this.state.start_date, "YYYY-MM-DD"),
          endDate: format(this.state.end_date, "YYYY-MM-DD"),
        }).then((data) => {          
          if (data.checkRangeYearBudget) {
            let chartTree = [];
            this.genChartList(chartJson, chartTree, data)
            this.setState({ lists: chartTree, checkDataFetch: true, loadingFetch: false, queryAllData: data, })
          } else {
            Swal.fire(i18next.t("budgetDraftForm:The selected budgeting period is already in the system."), i18next.t("budgetDraftForm:Please select a new budgeting year."), "warning")
            this.setState({ loadingFetch: false })
          }
        })
    } else if (this.props.mode === "edit") {
      this.setState({ loadingFetch: true })

      fetchQuery(
        environment,
        query,
        {
          previousYear: format(lastYear, "YYYY"),
          previousStartDate: format(lastStartDate, "YYYY-MM-DD"),
          previousEndDate: format(lastEndDate, "YYYY-MM-DD"),
          startDate: format(this.state.start_date, "YYYY-MM-DD"),
          endDate: format(this.state.end_date, "YYYY-MM-DD"),
          yearId: this.props.id,
        }).then((data) => {          
          if (data.checkRangeLastYearBudget) {
            let chartTree = [];
            this.genChartList(chartJson, chartTree, data)
            this.setState({
              lists: chartTree,
              checkDataFetch: true,
              loadingFetch: false,
              checkTextSelect: first ? data.oneYearBudget.edges[0].node.type : this.state.textSelect,
              queryAllData: data,
            })            
          } else {
            Swal.fire(i18next.t("budgetDraftForm:The selected budgeting period is already in the system."), i18next.t("budgetDraftForm:Please select a new budgeting year."), "warning")
            this.setState({ loadingFetch: false })
          }
        })
    }
  }

  genChartList(chart, chartTree, data) {
    chart.map((chart) => {
      let child = [];
      if (_.get(chart, 'child', []).length === 0) {
        // append data form database
        child = this.getChartQueryData(chart, data);
      }

      chartTree.push({
        data: {
          code: chart.key + "-00",
          name: chart.name,
        },
        children: child
      });

      if (_.get(chart, 'child', []).length > 0) {
        this.genChartList(chart['child'], child, data);
      }
      return true;
    })
  }

  getChartQueryData(parent, data) {
    let chartList = [];

    data.viewer.allChartOfAccount.edges.map((chart) => {
      let chartCode = chart.node.chartOfAccountCode;
      let summaryYears = {}
      let summaryYearsFilter = []
      let listSummaryYears = {
        "total_budget": "",
        "total_happend": ""
      }
      let list_month = []
      let start = this.state.start_date
      let listBudgetEdit = []

      if ((chartCode.substring(0, 1) === "4" && parent.key.substring(0, 1) === "4") ||
        (chartCode.substring(0, 4) === parent.key && chartCode.substring(0, 1) === "5")) {

        if (data.previousYearChartOfAccountBudget || data.oneYearBudget) {

          if (data.previousYearChartOfAccountBudget) {
            summaryYearsFilter = _.filter(data.previousYearChartOfAccountBudget.edges, (o) => o.node.chartOfAccount.chartOfAccountCode === chartCode)
          }

          if (summaryYearsFilter.length > 0 || data.oneYearBudget) {
            summaryYears = _.cloneDeep(listSummaryYears)

            if (data.oneYearBudget?.edges[0]?.node?.previousYearBudget?.edges[0]?.node?.previousChartOfAccountBudget?.edges.length > 0) {
              let previousYear = _.filter(_.get(data.oneYearBudget, "edges[0].node.previousYearBudget.edges[0].node.previousChartOfAccountBudget.edges"),
                (o) => o.node.chartOfAccount.chartOfAccountCode === chartCode)
              if (previousYear.length > 0) {
                _.set(summaryYears, "total_budget", (previousYear[0].node.totalBudget || ""))
                _.set(summaryYears, "total_happend", (previousYear[0].node.totalHappend || ""))
              } else {
                _.set(summaryYears, "total_budget", (""))
                _.set(summaryYears, "total_happend", (""))
              }
            } else if (summaryYearsFilter.length > 0) {
              _.set(summaryYears, "total_budget", (summaryYearsFilter[0].node.sumBudget || ""))
              _.set(summaryYears, "total_happend", (summaryYearsFilter[0].node.totalHappend || ""))
            }
          }
          else {
            summaryYears = _.cloneDeep(listSummaryYears)
          }
          _.set(summaryYears, "total_budget_check", summaryYears?.total_budget === "" ? false : true)

        } else {
          summaryYears = _.cloneDeep(listSummaryYears)
          _.set(summaryYears, "total_budget_check", false)
        }

        if (this.props.mode === "create") {

          do {
            list_month.push({
              month_date: format(start, "YYYY-MM-DD"),
              budget: "",
            });
            start = addMonths(start, 1);
          } while (format(start, "MM-YYYY") !== format(addMonths(this.state.end_date, 1), "MM-YYYY"));

          chartList.push({
            data: {
              id: chart.node.id,
              code: chartCode,
              name: chart.node.name,
              nameEn: chart.node.nameEn,
              totalHistoricalDebit: chart.node.totalHistoricalDebit,
              totalHistoricalCredit: chart.node.totalHistoricalCredit,
              total_budget: "",
              summaryYear: summaryYears,
              month_budget: list_month,
            }
          });
          return true;
        }
        // mode approve
        else if (this.props.mode === "edit" && this.props.status === "APPROVE") {

          listBudgetEdit = _.filter(_.get(data.oneYearBudget.edges, "[0].node.approveYearBudget.edges[0].node.approveChartOfAccountBudget.edges"),
            (o) => o.node.chartOfAccount.chartOfAccountCode === chartCode)

          if (_.get(listBudgetEdit[0], "node.approveMonthBudget.edges")) {
            _.forIn(_.get(listBudgetEdit[0], "node.approveMonthBudget.edges"), (item) => {

              if (format(start, "MM-YYYY") !== format(addMonths(this.state.end_date, 1), "MM-YYYY")) {
                if (format(start, "YYYY-MM-DD") === item.node.months) {
                  list_month.push({
                    month_date: item.node.months,
                    budget: item.node.budget,
                  });

                } else {
                  list_month.push({
                    month_date: format(start, "YYYY-MM-DD"),
                    budget: "",
                  });
                }
              }
              start = addMonths(start, 1);
            })
          } else {
            listBudgetEdit = _.filter(_.get(data.oneYearBudget.edges, "[0].node.chartOfAccountBudget.edges"),
              (o) => o.node.chartOfAccount.chartOfAccountCode === chartCode)

            if (_.get(listBudgetEdit[0], "node.monthBudget.edges") && _.get(listBudgetEdit[0], "node.monthBudget.edges").length > 0) {

              do {
                let monthDate = _.filter(_.get(listBudgetEdit[0], "node.monthBudget.edges"), (o) => o.node.months === format(start, "YYYY-MM-DD"))
                if (monthDate.length > 0) {
                  list_month.push({
                    month_date: monthDate[0].node.months || format(start, "YYYY-MM-DD"),
                    budget: monthDate[0].node.budget || "",
                  });
                } else {
                  list_month.push({
                    month_date: format(start, "YYYY-MM-DD"),
                    budget: "",
                  });
                }
                start = addMonths(start, 1);
              } while (format(start, "MM-YYYY") !== format(addMonths(this.state.end_date, 1), "MM-YYYY"));

            } else {
              do {
                list_month.push({
                  month_date: format(start, "YYYY-MM-DD"),
                  budget: "",
                });
                start = addMonths(start, 1);
              } while (format(start, "MM-YYYY") !== format(addMonths(this.state.end_date, 1), "MM-YYYY"));
            }
          }

          chartList.push({
            data: {
              id: chart.node.id,
              code: chartCode,
              name: chart.node.name,
              nameEn: chart.node.nameEn,
              totalHistoricalDebit: chart.node.totalHistoricalDebit,
              totalHistoricalCredit: chart.node.totalHistoricalCredit,
              total_budget: listBudgetEdit[0] ? (listBudgetEdit[0].node.totalBudget || "") : "",
              summaryYear: summaryYears,
              month_budget: list_month,
            }
          });
          return true;

        }
        // mode Not_approve 
        else if (this.props.mode === "edit" && this.props.status === "NOT_APPROVE") {

          listBudgetEdit = _.filter(_.get(data.oneYearBudget.edges, "[0].node.chartOfAccountBudget.edges"),
            (o) => o.node.chartOfAccount.chartOfAccountCode === chartCode)

          if (_.get(listBudgetEdit[0], "node.monthBudget.edges") && _.get(listBudgetEdit[0], "node.monthBudget.edges").length > 0) {
            do {

              let monthDate = _.filter(_.get(listBudgetEdit[0], "node.monthBudget.edges"), (o) => o.node.months === format(start, "YYYY-MM-DD"))
              if (monthDate.length > 0) {
                list_month.push({
                  month_date: monthDate[0].node.months || format(start, "YYYY-MM-DD"),
                  budget: monthDate[0].node.budget || "",
                });
              } else {
                list_month.push({
                  month_date: format(start, "YYYY-MM-DD"),
                  budget: "",
                });
              }
              start = addMonths(start, 1);
            } while (format(start, "MM-YYYY") !== format(addMonths(this.state.end_date, 1), "MM-YYYY"));

          } else {
            do {
              list_month.push({
                month_date: format(start, "YYYY-MM-DD"),
                budget: "",
              });
              start = addMonths(start, 1);
            } while (format(start, "MM-YYYY") !== format(addMonths(this.state.end_date, 1), "MM-YYYY"));
          }

          chartList.push({
            data: {
              id: chart.node.id,
              code: chartCode,
              name: chart.node.name,
              nameEn: chart.node.nameEn,
              totalHistoricalDebit: chart.node.totalHistoricalDebit,
              totalHistoricalCredit: chart.node.totalHistoricalCredit,
              total_budget: listBudgetEdit[0] ? (listBudgetEdit[0].node.totalBudget || "") : "",
              summaryYear: summaryYears,
              month_budget: list_month,
            }
          });
          return true;

        }

      }//if 4 & 5
    });
    return chartList;
  }

  onChangeWordSelect = (text) => {
    let value = text;    
    this.setState({
      textSelect: value,      
    });
  }

  handleSearch = async () => {
    this.setState({ checkDataFetch: false })
    await this.setState({
      loadingFetch: true,
      checkTextSelect: this.state.textSelect,
      disabledTable: true,
      temp_year: this.state.year,
      temp_start_date: this.state.start_date,
      temp_end_date: this.state.end_date
    })    
    await this.queryBudgetLYComparedAnnualBudget()
  }

  handleChangeInput = (e) => {
    let { name, value } = e.target;

    if (value.indexOf(".") >= 0) {
      value = (value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), 3))
    } else {
      value = value
    }

    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), name, value);
    });
  }

  handleChangeInputMount = async (e, getData, nameSet, type) => {
    let { name, value } = e.target;
    let arrMonth = []

    if (value.indexOf(".") >= 0) {
      value = (value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), 3))
    }

    await this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), name, value);
    });

    if (await type === "total_budget") {
      let valueMonth = ""

      if (value !== "") {
        valueMonth = (value / _.get(this.state, getData).length).toString()
        if (valueMonth.indexOf(".") >= 0) {
          valueMonth = (valueMonth.substr(0, valueMonth.indexOf(".")) + valueMonth.substr(valueMonth.indexOf("."), 3))
        }
      }

      _.forIn(_.get(this.state, getData), function (item, index, arr) {
        let arrMonths = {}

        if ((arr.length - 1) === parseInt(index)) {
          let valueLast = ""
          let sumValueLast = 0

          if (value !== "") {
            sumValueLast = (parseFloat(value) - ((arr.length - 1) * parseFloat(valueMonth))).toFixed(2)
            valueLast = sumValueLast
          }
          arrMonths = {
            month_date: item.month_date,
            budget: valueLast,
          }
        } else {
          arrMonths = {
            month_date: item.month_date,
            budget: parseFloat(valueMonth).toFixed(2),
          }
        }
        arrMonth.push(arrMonths)
      })

      this.setState((prevState) => {
        return _.set(_.cloneDeep(prevState), nameSet, arrMonth);
      });

    } else if (await type === "month_budget") {

      let sumValueMonth = await (_.sumBy(_.get(this.state, getData), (o) => parseFloat(o.budget || 0))).toString();

      if (sumValueMonth.indexOf(".") >= 0) {
        sumValueMonth = (sumValueMonth.substr(0, sumValueMonth.indexOf(".")) + sumValueMonth.substr(sumValueMonth.indexOf("."), 3))
      }

      this.setState((prevState) => {
        return _.set(_.cloneDeep(prevState), nameSet, sumValueMonth);
      });

    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let inputBudgetYear = [];
    let inputBudgetLastYear = [];
    this.setState({ loadingSave: true })

    // format Value && variables this year
    _.forIn(this.state.lists, function (value1) {
      if (value1.data.code === "4000-00") {
        _.forIn(value1.children, function (value2) {
          let inputFormatValue = {
            id: value2.data.id,
            name: value2.data.name,
            nameEn: value2.data.nameEn,
            chartOfAccountCode: value2.data.code,
            totalHistoricalDebit: value2.data.totalHistoricalDebit,
            totalHistoricalCredit: value2.data.totalHistoricalCredit,
            total_budget: value2.data.total_budget,
            month_budget: value2.data.month_budget
          }
          inputBudgetYear.push(inputFormatValue)
        })
      } else if (value1.data.code === "5000-00") {
        _.forIn(value1.children, function (value3) {
          _.forIn(value3.children, function (value4) {
            if (value3.data.code.substring(0, 2) === "58") {
              _.forIn(value4.children, function (value5) {
                let inputFormatValue = {
                  id: value5.data.id,
                  name: value5.data.name,
                  nameEn: value5.data.nameEn,
                  chartOfAccountCode: value5.data.code,
                  totalHistoricalDebit: value5.data.totalHistoricalDebit,
                  totalHistoricalCredit: value5.data.totalHistoricalCredit,
                  total_budget: value5.data.total_budget,
                  month_budget: value5.data.month_budget
                }
                inputBudgetYear.push(inputFormatValue)
              })
            } else {
              let inputFormatValue = {
                id: value4.data.id,
                name: value4.data.name,
                nameEn: value4.data.nameEn,
                chartOfAccountCode: value4.data.code,
                totalHistoricalDebit: value4.data.totalHistoricalDebit,
                totalHistoricalCredit: value4.data.totalHistoricalCredit,
                total_budget: value4.data.total_budget,
                month_budget: value4.data.month_budget
              }
              inputBudgetYear.push(inputFormatValue)
            }
          })
        })
      }
    })

    // format Value && variables last year
    _.forIn(this.state.lists, function (value1) {
      if (value1.data.code === "4000-00") {
        _.forIn(value1.children, function (value2) {
          let inputFormatValue = {
            id: value2.data.id,
            name: value2.data.name,
            nameEn: value2.data.nameEn,
            chartOfAccountCode: value2.data.code,
            totalHistoricalDebit: parseFloat(value2.data.totalHistoricalDebit || 0),
            totalHistoricalCredit: parseFloat(value2.data.totalHistoricalCredit || 0),
            total_budget: parseFloat(value2.data.summaryYear.total_budget || 0),
            total_happend: parseFloat(value2.data.summaryYear.total_happend || 0)
          }
          inputBudgetLastYear.push(inputFormatValue)
        })
      } else if (value1.data.code === "5000-00") {
        _.forIn(value1.children, function (value3) {
          _.forIn(value3.children, function (value4) {
            if (value3.data.code.substring(0, 2) === "58") {
              _.forIn(value4.children, function (value5) {
                let inputFormatValue = {
                  id: value5.data.id,
                  name: value5.data.name,
                  nameEn: value5.data.nameEn,
                  chartOfAccountCode: value5.data.code,
                  totalHistoricalDebit: parseFloat(value5.data.totalHistoricalDebit || 0),
                  totalHistoricalCredit: parseFloat(value5.data.totalHistoricalCredit || 0),
                  total_budget: parseFloat(value5.data.summaryYear.total_budget || 0),
                  total_happend: parseFloat(value5.data.summaryYear.total_happend || 0)
                }
                inputBudgetLastYear.push(inputFormatValue)
              })
            } else {
              let inputFormatValue = {
                id: value4.data.id,
                name: value4.data.name,
                nameEn: value4.data.nameEn,
                chartOfAccountCode: value4.data.code,
                totalHistoricalDebit: parseFloat(value4.data.totalHistoricalDebit || 0),
                totalHistoricalCredit: parseFloat(value4.data.totalHistoricalCredit || 0),
                total_budget: parseFloat(value4.data.summaryYear.total_budget || 0),
                total_happend: parseFloat(value4.data.summaryYear.total_happend || 0)
              }
              inputBudgetLastYear.push(inputFormatValue)
            }
          })
        })
      }
    })

    let lastYear = _.cloneDeep(this.state.year)
    lastYear.setFullYear(lastYear.getFullYear() - 1)
    let lastStartDate = _.cloneDeep(this.state.start_date)
    lastStartDate.setFullYear(lastStartDate.getFullYear() - 1)
    let lastEndDate = _.cloneDeep(this.state.end_date)
    lastEndDate.setFullYear(lastEndDate.getFullYear() - 1)

    if (this.props.mode === "create") {

      let variablesThisYear = {
        input: {
          budgetPayload: {
            type: this.state.checkTextSelect,
            years: format(this.state.year, "YYYY"),
            start_date: format(this.state.start_date, "YYYY-MM-DD"),
            end_date: format(this.state.end_date, "YYYY-MM-DD"),
            status: "not_approve",
            chart_of_account_budget: inputBudgetYear
          }
        }
      };

      CreateBudgetMutations(
        variablesThisYear,
        (res) => {

          let variableDeleteIDThisYear = {
            input: {
              id: btoa(`YearBudgetNode:${res.setupBudget.budgetId}`)
            }
          }

          if (res.setupBudget.ok) {

            let variablesLastYear = {
              input: {
                budgetId: btoa(`YearBudgetNode:${res.setupBudget.budgetId}`),
                budgetPayload: {
                  years: format(lastYear, "YYYY"),
                  start_date: format(lastStartDate, "YYYY-MM-DD"),
                  end_date: format(lastEndDate, "YYYY-MM-DD"),
                  chart_of_account_budget: inputBudgetLastYear
                }
              }
            };


            CreatePreviousBudget(
              variablesLastYear,
              (res) => {
                if (res.submitPreviousBudget.ok) {
                  this.setState({ loadingSave: false })
                  Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => {
                    this.setState({
                      redirectTo: '/accounting/budget/budgetDraft/list/all'
                    })
                  })

                } else {

                  this.setState({ loadingSave: false })
                  let variableDeleteIDLastYear = {
                    input: {
                      id: btoa(`YearBudgetNode:${res.submitPreviousBudget.budgetId}`)
                    }
                  }

                  DeleteBudget(variableDeleteIDLastYear, () => { return true }, () => { return true })
                  DeleteBudget(
                    variableDeleteIDThisYear,
                    () => {
                      Swal.fire(i18next.t("Allaction:Saved Successfully"), "", "error").then(() => {
                        this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
                      })
                    },
                    () => {
                      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
                        this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
                      })
                    }
                  )
                }
              },
              () => {
                this.setState({ loadingSave: false })
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
                  this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
                })
              }
            )


          } else {
            this.setState({ loadingSave: false })
            DeleteBudget(
              variableDeleteIDThisYear,
              () => {
                Swal.fire(i18next.t("Allaction:Saved Successfully"), "", "error").then(() => {
                  this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
                })
              },
              () => {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
                  this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
                })
              }
            )
          }
        },
        () => {
          this.setState({ loadingSave: false })
          Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
            this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
          })
        }
      );

    } else if (this.props.mode === "edit") {

      let variablesThisYear = {
        input: {
          id: this.props.id,
          budgetPayload: {
            years: format(this.state.year, "YYYY"),
            start_date: format(this.state.start_date, "YYYY-MM-DD"),
            end_date: format(this.state.end_date, "YYYY-MM-DD"),
            status: "not_approve",
            chart_of_account_budget: inputBudgetYear
          }
        }
      };

      EditBudgetMutations(
        variablesThisYear,
        (res) => {

          let variableDeleteIDThisYear = {
            input: {
              id: btoa(`YearBudgetNode:${res.updateBudget.budgetId}`)
            }
          }

          if (res.updateBudget.ok) {
            let variablesLastYear = {
              input: {
                budgetId: btoa(`YearBudgetNode:${res.updateBudget.budgetId}`),
                previousId: _.get(this.state.queryAllData, "oneYearBudget.edges[0].node.previousYearBudget.edges[0].node.id"),
                budgetPayload: {
                  years: format(lastYear, "YYYY"),
                  start_date: format(lastStartDate, "YYYY-MM-DD"),
                  end_date: format(lastEndDate, "YYYY-MM-DD"),
                  chart_of_account_budget: inputBudgetLastYear
                }
              }
            };

            EditPreviousBudget(
              variablesLastYear,
              (res) => {
                if (res.updatePreviousBudget.ok) {
                  this.setState({ loadingSave: false })
                  Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => {
                    this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
                  })

                } else {

                  this.setState({ loadingSave: false })
                  let variableDeleteIDLastYear = {
                    input: {
                      id: btoa(`YearBudgetNode:${res.updatePreviousBudget.budgetId}`)
                    }
                  }

                  DeleteBudget(variableDeleteIDLastYear, () => { return true }, () => { return true })
                  DeleteBudget(
                    variableDeleteIDThisYear,
                    () => {
                      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
                        this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
                      })
                    },
                    () => {
                      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
                        this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
                      })
                    }
                  )
                }
              },
              () => {
                this.setState({ loadingSave: false })
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
                  this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
                })
              }
            )
          } else {
            this.setState({ loadingSave: false })
            DeleteBudget(
              variableDeleteIDThisYear,
              () => {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
                  this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
                })
              },
              () => {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
                  this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
                })
              }
            )
          }
        },
        () => {
          this.setState({ loadingSave: false })
          Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
            this.setState({ redirectTo: '/accounting/budget/budgetDraft/list/all' })
          })
        }
      );
    } //else if
  }

  disabledInput = () => {    
    if (this.props.status === "APPROVE" || !this.state.permissionEdit) {
      return true
    } else {      
      return false
    }
  }

  render() {

    let monthEndDate = setMonth(new Date(this.state.start_date), this.state.start_date.getMonth() + 23)
    let maxEndDate = new Date(monthEndDate.getFullYear(), monthEndDate.getMonth(), 0)

    let monthStartDate = setMonth(new Date(this.state.end_date), this.state.end_date.getMonth() - 22)
    let minStartDate = new Date(monthStartDate.getFullYear(), monthStartDate.getMonth(), 1)

    if (this.state.redirectTo) {
      return <Redirect to={{ pathname: this.state.redirectTo }} />
    }
    return (
      <Translation>
        {(t) =>
          <React.Fragment>
            <div className="d-flex mt-3 " id="budgetDraftForm">
              <div className="form-row pl-3 col-12 ">
                <div className="col-2 customDatePickerWidth ">
                  <div>{i18next.t("budgetDraftForm:Select the budgeting year")}</div>
                  <DatePicker
                    disabled={this.disabledInput()}
                    className="form-control float-left colorborder"
                    selected={this.state.year}
                    onChange={(date) =>
                      this.setState({
                        year: date,
                        start_date: setMonth(date, 0),
                        end_date: setMonth(date, 11),
                      })
                    }
                    showYearPicker
                    locale="th-TH"
                    dateFormat="yyyy"
                    customInput={<ButtonDate date />}
                  />
                </div>
                <div className="col-2 customDatePickerWidth ml-3 ">
                  <div>{i18next.t("budgetDraftForm:Budgeting year start date")}</div>
                  <DatePicker
                    disabled={this.disabledInput()}
                    className="form-control float-left colorborder"
                    selected={this.state.start_date}
                    onChange={(date) => this.setState({ start_date: date })}
                    dateFormat="dd/MM/yyyy"
                    customInput={<ButtonDate date />}
                    minDate={minStartDate}
                    maxDate={addDays(this.state.end_date, -1)}
                  />
                </div>
                <div className="col-2 customDatePickerWidth ">
                  <div>{i18next.t("budgetDraftForm:Budgeting year end date")}</div>
                  <DatePicker
                    disabled={this.disabledInput()}
                    className="form-control float-left colorborder"
                    selected={this.state.end_date}
                    onChange={(date) => this.setState({ end_date: date })}
                    dateFormat="dd/MM/yyyy"
                    customInput={<ButtonDate date />}
                    minDate={addDays(this.state.start_date, 1)}
                    maxDate={maxEndDate}
                  />
                </div>
                <div className="col-4 ml-3">
                  <div className="mb-2 colorUse">{i18next.t("budgetDraftForm:Select the comparative form")}</div>

                  <select name='compare' className='form-control h48' onChange={(text) => this.onChangeWordSelect(text.target.value)} value={this.state.textSelect}>
                    <option value={""}>{i18next.t("budgetDraftForm:Select the comparative form")}</option>
                    <option value={"Budget, Actual Previous Year, Comparison with Budget for the Current Year"}>{i18next.t("budgetDraftForm:Budget, Actual Previous Year, Comparison with Budget for the Current Year")}</option>
                    <option value={"Budget, Actual Previous Year, Monthly Comparison"}>{i18next.t("budgetDraftForm:Budget, Actual Previous Year, Monthly Comparison")}</option>
                    <option value={"Actual Previous Year Average Monthly Budget, Average Monthly Comparison with Current Year"}>{i18next.t("budgetDraftForm:Actual Previous Year Average Monthly Budget, Average Monthly Comparison with Current Year")}</option>
                  </select>
                  {/* 
                  ใช้ LIB Dropdown แล้วเวลากด เปลี่ยนภาษา ไม่ทำงาน เลย Comment ทิ้งไว้ก่อนเผื่อ มีบัคตรงนี้จะได้ Back กลับมาได้
                  <Dropdown
                    name="compare"
                    title={i18next.t("budgetDraftForm:Select the comparative form")}
                    className="mt"
                    list={[
                      {
                        label: i18next.t("budgetDraftForm:Budget, Actual Previous Year, Comparison with Budget for the Current Year"),
                        value: "Budget, Actual Previous Year, Comparison with Budget for the Current Year"
                      },
                      {
                        label:i18next.t("budgetDraftForm:Budget, Actual Previous Year, Monthly Comparison"),
                        value: "Budget, Actual Previous Year, Monthly Comparison"
                      },
                      {
                        label: i18next.t("budgetDraftForm:Actual Previous Year Average Monthly Budget, Average Monthly Comparison with Current Year"),
                        value: "Actual Previous Year Average Monthly Budget, Average Monthly Comparison with Current Year"
                      }
                    ]}
                    onChange={(text) => this.onChangeWordSelect(text)}
                    styles={{
                      headerTitle: { fontSize: "14px", marginRight: "unset" },
                      header: { border: "1px solid #e2e2e2", height: "3rem" },
                      wrapper: { width: "100%" },
                      listItem: { fontSize: "14px" }
                    }}
                  /> */}
                </div>
                <div className="col-1 ml-3">
                  <button
                    type="button"
                    className={"btn add"}
                    style={{
                      height: 48,
                      marginTop: "30px",
                      background: "#0382FA",
                      color: "#ffffff",
                    }}
                    onClick={this.handleSearch}
                  >
                    {i18next.t("Allaction:yes")}
                  </button>
                </div>
              </div>
            </div>

            <React.Fragment>
              {this.state.loadingFetch ?
                <Loading />
                :
                <React.Fragment>
                  {this.state.checkDataFetch &&
                    <form onSubmit={this.handleSubmit}>
                      {this.props.mode === "edit" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_print' }) &&
                        <div className="row col-12 mt-3 d-flex justify-content-end">
                          <DownloadDocument
                            checkTextSelect={this.state.checkTextSelect}
                            state={this.state}
                            yearId={this.props.id}
                          />
                        </div>
                      }
                      <div className="mr-2 mt-5">
                        <h3>
                          {
                            this.state.checkTextSelect &&
                            <span className="header">
                              {i18next.t("budgetDraftForm:" + this.state.checkTextSelect)}
                            </span>
                          }
                        </h3>
                      </div>                                           
                      {this.state.checkTextSelect === "Budget, Actual Previous Year, Comparison with Budget for the Current Year" &&
                        <BudgetLastYearCompareWithYear
                          state={this.state}
                          handleChangeInput={this.handleChangeInput}
                          handleChangeInputMount={this.handleChangeInputMount}
                          disabledApprove={this.disabledInput}
                        />
                      }

                      {this.state.checkTextSelect === "Budget, Actual Previous Year, Monthly Comparison" &&
                        <BudgetLastYearCompareWithMonth
                          state={this.state}
                          handleChangeInput={this.handleChangeInput}
                          handleChangeInputMount={this.handleChangeInputMount}
                          disabledApprove={this.disabledInput}
                        />
                      }

                      {this.state.checkTextSelect === "Actual Previous Year Average Monthly Budget, Average Monthly Comparison with Current Year" &&
                        <BudgetDraftYearCompareAverageYear
                          state={this.state}
                          handleChangeInput={this.handleChangeInput}
                          handleChangeInputMount={this.handleChangeInputMount}
                          disabledApprove={this.disabledInput}
                        />
                      }

                      <div className="d-flex justify-content-end mb-2  mt-3" style={{ width: "80%" }}>
                        <button
                          disabled={this.disabledInput() || this.state.loadingSave}
                          type="submit"
                          className="btn add float-right"
                          style={{ background: "#0382FA" }}
                        >
                          {this.state.loadingSave
                            ?
                            <span style={{ color: "#ffffff" }}>
                              <span className="spinner-border spinner-border-sm align-middle mr-2" /> {i18next.t("Allaction:waitingSaving")}
                            </span> : <span style={{ color: "#ffffff" }}>{i18next.t("debtFreeCertification:save")}</span>
                          }
                        </button>
                      </div>
                    </form>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          </React.Fragment>
        }
      </Translation>
    )
  }
}

const ButtonDate = forwardRef(({ value, onClick, disabled, date }, ref) => (
  <button
    type="button"
    className="date form-control mt-2 booking-form"
    style={{ width: "100%" }}
    onClick={onClick}
    ref={ref}
    disabled={disabled}
  >
    {value}
    {date && (
      <div
        className="calendars"
        style={{
          position: "absolute",
          right: 0,
          marginRight: 8,
        }}
      >
        <img
          className="icon"
          src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"}
          alt="calendars"
        />
      </div>
    )}
  </button>
));

export default budgetDraftForm;