import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import ChartOfAccount from './chartOfAccount';
import {Route, Switch} from "react-router-dom";
import ChartOfAccountCreateUpdate from "./chartOfAccountCreateUpdate";
import NoMatch from "../../components/noMatch";

class Account extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/form/:id?`} component={ChartOfAccountCreateUpdate}/>
                <PrivateRoute exact path={`${this.props.match.url}/:category`} component={ChartOfAccount}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Account;