import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import WrapperContent from '../../components/wrapper/wrapperContent'
import StockTopMenuNavigation from '../stockTopMenuNavigation'
import { Translation } from 'react-i18next'
import { Link } from 'react-router-dom'
import _ from "lodash"
import SelectPrivacy from './selectPrivacy/selectPrivacy'
import SelectProduct from './selectProduct/selectProduct'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import './style/stockMenagement.scss'

const query = graphql`
  query stockMenagementFormInputQuery($search: String){
    contactViewer{
      allContact(typeOfContact: "residential", isActive: true, search: $search){
        edges{
          node{
            id
            refNumber
            name
            firstName
            lastName
          }
        }
      }
    }
    productViewer{
      allProduct (type_In: "product", isActive: true, search: $search){
        edges{
          node{
            id
            name
            productCode
            noNumber
            stock {
              edges {
                node {
                  id
                  cost
                  leftInStock
                  added
                  transaction{
                      date
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default class stockManagermentFormInput extends Component {

  constructor(props){
    super(props)

    this.state = {
      typeRequisition: "unit",
      typeNote: "tenant",
      selectedId: [],
      checkAll : false,
      contactIdRoom: "",
      allContactList: [],
      textSearch: "",
      allProductList: [],
    }
  }

  componentDidMount(){
    this.getData()
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.typeRequisition !== prevState.typeRequisition){
      this.setState({contactIdRoom: ""})
    }
    if(this.state.typeRequisition !== prevState.typeRequisition || this.state.typeNote !== prevState.typeNote){
      this.setState({textSearch: ""})
      this.getData("change")
    }
    if(this.state.selectedId !== prevState.selectedId && this.state.selectedId.length === 0){
      this.setState({
        checkAll : false
      })
    }
  }

  getData = (input) => {
    fetchQuery(environment, query, { search: input ? "" : this.state.textSearch }).then((res) => {
      this.setState({
        allContactList: res.contactViewer.allContact, 
        allProductList: res.productViewer.allProduct
      })
    })
  }

  handleCheck= (name, inputSet) => {
    this.setState({ [inputSet]: name })
  }

  onCheckAll = (props) => {
    let selected = []
    this.setState({checkAll : !this.state.checkAll} , () => {
        if(this.state.checkAll){
            _.forEach(props, (item) =>{
                selected.push(item.node.id)
            })
            this.setState({
                selectedId : selected
            })
        }else{
            this.setState({
                selectedId : []
            })
        }
    })
  }

  onCheck = (id) => {
    let index = this.state.selectedId.indexOf(id);
    let selected = _.cloneDeep(this.state.selectedId)
    if(index === -1){
        selected.push(id)
    }else{
        selected.splice(index, 1)
    }
    this.setState({
        selectedId : selected
    })
  }

  handleSearch = () => {
    this.getData()
  }

  handleSelectContactId = (contactIdInput) => {
    this.setState({ contactIdRoom: contactIdInput })
  }

  handleResetList = (name) => {
    if(name === "contactIdRoom"){
      this.setState({contactIdRoom: ""})
    }else if(name = "selectedId"){
      this.setState({selectedId: []})
    }
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.getData()
    }
  }

  render() {
    return (
      <Wrapper>
        <Header/>
        <Sidebar/>
        <WrapperContent>
          <StockTopMenuNavigation mini={true}/>
          <Translation>{t=>
          <div className="container-fluid box" id="stockManagementFormInput">
            <div className="row">
              <div className="col">
                <Translation>
                  {
                    t =>
                      <h4 className="mb-4">
                        <Link to="/stockandsupplies/stockManagement/list/all">
                          <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                            alt="back" className="back" />
                        </Link>
                        {t("PageList:Create requisition form")}
                      </h4>
                  }
                </Translation>

              </div>
            </div>

            <div className="content-inner">
              <div className="row">
                <div className="col-12">
                  <h4>{t("stockSelectRoom:Choose item type")}</h4>
                </div>

                <div className="col-12 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <input 
                      type="radio" 
                      name="unit" 
                      checked={this.state.typeRequisition === 'unit'}
                      onChange={(e) => this.handleCheck(e.target.name, "typeRequisition")}/>
                    <h5 className="mx-2 mb-1">{t("stockSelectRoom:Rights")}</h5>
                    <span style={{color: "#555555"}}>{t("stockSelectRoom:Rights of each unit")}</span>
                  </div>
                  <div className="d-flex align-items-center" style={{marginLeft: "50px"}}>
                    <input 
                      type="radio" 
                      name="project" 
                      checked={this.state.typeRequisition === 'project'}
                      onChange={(e) => this.handleCheck(e.target.name, "typeRequisition")}/>
                    <h5 className="mx-2 mb-1">{t("stockSelectRoom:Requisition")}</h5>
                    <span style={{color: "#555555"}}>{t("stockSelectRoom:Requisition for use as consumables within the project")}</span>
                  </div>
                </div>

                {this.state.typeRequisition === 'project' &&
                  <div className="col-12 d-flex mt-3">
                    <div style={{marginLeft: "240px"}}>
                      <button 
                        className={this.state.typeNote === "tenant" ? "btnSelect" : "btnDefault"} 
                        onClick={() => this.handleCheck("tenant", "typeNote")}
                        >
                        {t("stockSelectRoom:Tenant")}
                      </button>
                      <button 
                        className={this.state.typeNote === "juristic" ? "btnSelect ml-4" : "btnDefault ml-4"} 
                        onClick={() => this.handleCheck("juristic", "typeNote")}
                        >
                         {t("stockSelectRoom:Common Area")}
                      </button>
                    </div>
                  </div>
                }

                <div className="col-12 my-5">
                  <div className="lineBottom"/>
                </div>
              </div>

              <div className="row">
                {
                  this.state.typeNote === 'juristic' && 
                  <div className="col-12 d-flex align-items-center">
                    <h5>{t("stockSelectRoom:Inventory")}</h5>
                  </div>
                }

                {
                  this.state.typeNote === 'tenant' && 
                  <div className="col-12 d-flex align-items-center">
                    <h5>{t("stockSelectRoom:Search room")}</h5>
                    <span className="ml-3" style={{color: "#E11818", fontSize: "10px"}}>*{t("stockSelectRoom:Choose only 1 room")}</span>
                  </div>
                }
                

                <div className="col-6">
                  <div className="calendars-wrapper">
                    <form>
                      <input
                        type="text"
                        placeholder="ค้นหา"
                        value={this.state.textSearch}
                        onChange={(event) => this.setState({textSearch: event.target.value})}
                        className="form-control inputSearch"
                        onKeyDown={this.handleKeyDown}
                      />
                    </form>
                    <div
                      className="calendars float-right"
                      style={{ paddingRight: 25 }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/search-outline.png"
                        }
                        alt="calendars"
                        style={{ width: "17.81px", height: "17.81px" }}
                        onClick={this.handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>
              

              { (this.state.typeRequisition === "unit" || (this.state.typeRequisition === "project" && this.state.typeNote === "tenant")) &&
                <SelectPrivacy
                  allContactList={this.state.allContactList}
                  contactIdRoom={this.state.contactIdRoom}
                  handleSelectContactId={this.handleSelectContactId}
                  handleResetList={this.handleResetList}
                  typeRequisition={this.state.typeRequisition}
                  />
              }

              {(this.state.typeRequisition === "project" && this.state.typeNote === "juristic") &&  
                <SelectProduct
                  onCheckAll={this.onCheckAll}
                  onCheck={this.onCheck}
                  selectedId={this.state.selectedId}
                  checkAll={this.state.checkAll}
                  handleResetList={this.handleResetList}
                  allProductList={this.state.allProductList}
                  /> 
              }

            </div>
          </div>
          }</Translation>
        </WrapperContent>
      </Wrapper>
    
    )
  }
}
