/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccountRecordGroupCategory = "GENERAL" | "HISTORICAL" | "PAYMENT" | "PURCHASE" | "RECEIVE" | "SALES" | "VOIDED" | "%future added value";
export type AccountRecordGroupStatus = "INVALID" | "VALID" | "VOID" | "%future added value";
export type dailyJournalAllQueryVariables = {|
  first?: ?number,
  last?: ?number,
  category?: ?string,
  status?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  search?: ?string,
  order?: ?string,
|};
export type dailyJournalAllQueryResponse = {|
  +allAccountRecordGroup: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +refNumber: ?string,
        +refTransaction: ?string,
        +refNumberSecond: ?string,
        +name: string,
        +added: any,
        +issuedDate: ?any,
        +refPayExpenseDate: ?any,
        +category: AccountRecordGroupCategory,
        +status: AccountRecordGroupStatus,
        +totalDebit: number,
        +totalCredit: number,
        +contact: ?{|
          +id: string,
          +name: string,
        |},
      |}
    |}>,
    +totalCount: ?number,
  |}
|};
export type dailyJournalAllQuery = {|
  variables: dailyJournalAllQueryVariables,
  response: dailyJournalAllQueryResponse,
|};
*/


/*
query dailyJournalAllQuery(
  $first: Int
  $last: Int
  $category: String
  $status: String
  $start_date: DateTime
  $end_date: DateTime
  $search: String
  $order: String
) {
  allAccountRecordGroup(first: $first, last: $last, category: $category, status: $status, startDate: $start_date, endDate: $end_date, search: $search, order: $order) {
    edges {
      node {
        id
        refNumber
        refTransaction
        refNumberSecond
        name
        added
        issuedDate
        refPayExpenseDate
        category
        status
        totalDebit
        totalCredit
        contact {
          id
          name
        }
      }
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "category",
        "variableName": "category"
      },
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "AccountRecordGroupNodeConnection",
    "kind": "LinkedField",
    "name": "allAccountRecordGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountRecordGroupNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountRecordGroupNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refTransaction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refNumberSecond",
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "issuedDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refPayExpenseDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalDebit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCredit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "dailyJournalAllQuery",
    "selections": (v10/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "dailyJournalAllQuery",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "715efc17e4aba2e0b48f7c115c0d12ea",
    "id": null,
    "metadata": {},
    "name": "dailyJournalAllQuery",
    "operationKind": "query",
    "text": "query dailyJournalAllQuery(\n  $first: Int\n  $last: Int\n  $category: String\n  $status: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $search: String\n  $order: String\n) {\n  allAccountRecordGroup(first: $first, last: $last, category: $category, status: $status, startDate: $start_date, endDate: $end_date, search: $search, order: $order) {\n    edges {\n      node {\n        id\n        refNumber\n        refTransaction\n        refNumberSecond\n        name\n        added\n        issuedDate\n        refPayExpenseDate\n        category\n        status\n        totalDebit\n        totalCredit\n        contact {\n          id\n          name\n        }\n      }\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54b469b79dd6a7aab565458cdf05209a';

module.exports = node;
