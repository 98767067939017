
import React, { Component } from 'react'
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import DeveloperTopMenuNavigation from "../developerTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import residentialAPI from '../../register/serviceAPI';
import _ from 'lodash';
import revenueContractAPI from './revenueContractAPI';
import { format } from 'date-fns';
import '../developer.scss'
import numberWithCommas from '../../libs/numberWithComma';
import Swal from 'sweetalert2';
import i18next from 'i18next';
import BackButtonIcon from '../../components/BackBtn/indexBack';

const advance = [
    { value: 0, label: i18next.t("settingAll:No") },
    { value: 1, label: i18next.t("settingAll:1 month in advance") },
    { value: 2, label: i18next.t("settingAll:2 months in advance") },
    { value: 3, label: i18next.t("settingAll:3 months in advance") },
    { value: 4, label: i18next.t("settingAll:4 months in advance") },
    { value: 5, label: i18next.t("settingAll:5 months in advance") },
    { value: 6, label: i18next.t("settingAll:6 months in advance") },
    { value: 7, label: i18next.t("settingAll:7 months in advance") },
    { value: 8, label: i18next.t("settingAll:8 months in advance") },
    { value: 9, label: i18next.t("settingAll:9 months in advance") },
    { value: 10, label: i18next.t("settingAll:10 months in advance") },
]


class RevenueContractDetail extends Component {

    constructor(props){
        super(props);
        this.state = {
            roomNo : '',
            ownershipName : '',
            token : localStorage.getItem("token"),
            refNumber : '',
            freebies : [],
            condition : []
        }
    }

    changeStatus = (transactionClose) => {
        Swal.fire({
            title: i18next.t("settingAll:Disabled condition"),
            type: 'warning',
            text: i18next.t("settingAll:If disabled. This will close the item permanently. Unable to re-open or edit information again Do you want to disable this item?"),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Confirm"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let condition = []; 
                condition.push({
                    obligationTenantID : transactionClose.obligationTenantID ? transactionClose.obligationTenantID : '', 
                    residentialID : this.props.match.params.id , 
                    productAndServiceID : transactionClose.productAndServiceID , 
                    charged : transactionClose.charged ,
                    billingMonth : transactionClose.billingMonth ? transactionClose.billingMonth : '', 
                    advance : transactionClose.advance,
                    issuedDate : format(transactionClose.issuedDate,'YYYY-MM-DD'),
                    dueDate : transactionClose.dueDate ? format(transactionClose.dueDate,'YYYY-MM-DD') : null ,
                    burdenStatus : transactionClose.burdenStatus,
                    activeStatus : false,
                    status : '',
                })
                
                revenueContractAPI.createCondition(condition,localStorage.getItem("token")).then((response) => {
                    if(response.status === 200){
                        Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => {
                            this.fetchData()
                        })
                    }else{
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
                    }
                })
            }
        })
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = async() => {
        await residentialAPI.getResidential(this.state.token,this.props.match.params.id).then((response) => {
            if(response.data){
                let name = '';
                _.forEach(response.data.ownershipName , (contact,index) => {
                    name = index === 0 ? contact : name + " , " + contact
                 })
                this.setState({
                    roomNo : response.data.name,
                    ownershipName : name,
                    refNumber : response.data.refNumber
                })
            }
        })
        
        await revenueContractAPI.getFreebies(this.state.token,this.props.match.params.id).then((response) => {
            if(response.data){
                this.setState({
                    freebies : response.data.obligationDeveloper
                })
            }
        })
        await revenueContractAPI.getCondition(this.state.token,this.props.match.params.id).then((response) => {
            if(response.data){
                this.setState({
                    condition : response.data.obligationTenant
                })
            }
        })
    }

    render(){
        return(
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <DeveloperTopMenuNavigation mini={true} />
                    <div className="container-fluid box" id="developer-form">
                        <BackButtonIcon
                            LinkBack={"/developer/revenue-contract/list/all"} 
                            LinkText={this.state.refNumber}
                        /> 
                        <Translation>
                            {t => 
                            <div className="content-inner mt-3">
                                <div className="row d-flex align-items-center">
                                    <div className="col form-inline">
                                        <h4 className="mr-4">{t("settingAll:Co-owner's information")}</h4>
                                    </div>
                                </div>
                                <hr />
                                <div className="row d-flex mt-3">
                                    <div className="row col-12">
                                        <div className="col-2">
                                            <h6>{i18next.t("revenueContractDetail:Room Number")}</h6>
                                        </div>​
                                        <div className="col-2">
                                            <h6>{i18next.t("revenueContractDetail:Name")}</h6>
                                        </div>​
                                    </div>
                                    <div className="row col-12">
                                        <div className="col-2">
                                            <span className="text-grey">{this.state.roomNo}</span>
                                        </div>​
                                        <div className="col-10">
                                            <span className="text-grey">{this.state.ownershipName}</span>
                                        </div>​
                                    </div>
                                </div>

                                <div className="row form-inline mt-4">
                                    <div className="col-2"><h4>{t("settingAll:List gifts")}</h4></div>
                                    <div className="col form-inline mt-3 mb-3 d-flex align-items-center">
                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'ownership_obligation_edit'}) &&
                                            <Link to={"/developer/revenue-contract/edit/"+this.props.match.params.id+"/freebies"}>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/edit-icon.png'} 
                                                alt="edit-icon" className="mr-1"/>
                                                <span className="text-blue">{t("Allaction:edit")}</span>
                                            </Link>
                                        }
                                    </div >
                                </div>
                                <div className="row form-inline mt-1">
                                    <div className="col-12">
                                        <h5 className="text-red">{t("settingAll:The developer is responsible for these expenses on behalf of the customer.")}.</h5>
                                    </div>
                                </div>
                                <table className="table detail col-12">
                                    <thead  className="thead-blue">
                                        <tr >
                                            <th className="text-left" width={200}>{t("settingAll:Services code")}</th>
                                            <th className="text-left" >{t("settingAll:Account receivables")}</th>
                                            <th className="text-left " >{t("settingInvoiceTerms:deferred")}</th>
                                            <th className="text-right" >{t("settingAll:Advance received")}</th>
                                            <th className="text-center" >{t("settingAll:Income allocation start month")}</th>
                                            <th className="text-center">{t("settingAll:Income allocation end month")}</th>
                                            <th className="text-right" >{t("settingAll:Average")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.freebies.map((freebies,index) => {
                                            return (
                                                 <tr key={index}>
                                                    <td>{freebies.productCode + " " + freebies.name}</td>
                                                    <td>{freebies.customerChartOfAccountCode + ' ' +freebies.customerChartOfAccount}</td>
                                                    <td>{freebies.periodAdjustment &&  ( freebies.receiveDepositCode + ' '+  freebies.receiveDeposit)}</td>
                                                    <td className="text-right">{numberWithCommas(freebies.price)}</td>
                                                    <td className="text-center">{format(freebies.issuedDate , 'MM/YYYY')}</td>
                                                    <td className="text-center">{format(freebies.dueDate , 'MM/YYYY')}</td>
                                                    <td className="text-right">{numberWithCommas(freebies.averagePerMonths)}</td>
                                                    <td></td>
                                                </tr>
                                            )
                                        })
                                           
                                        }
                                    </tbody>
                                </table>

                                <div className="row form-inline mt-4">
                                    <div className="col-2"><h4>{t("settingAll:")}</h4></div>
                                    <div className="col form-inline mt-3 mb-3 d-flex align-items-center">
                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'ownership_obligation_edit'}) &&
                                            <Link to={"/developer/revenue-contract/edit/"+this.props.match.params.id+"/condition"}>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/edit-icon.png'} 
                                                alt="edit-icon" className="mr-1"/>
                                                <span className="text-blue">{t("Allaction:edit")}</span>
                                            </Link>
                                        }
                                    </div >
                                </div>
                                <div className="row form-inline mt-1">
                                    <div className="col-12">
                                        <h5 className="text-red">{t("settingAll:Resident is responsible for these expenses.")}</h5>
                                    </div>
                                </div>
                                <table className="table detail col-12 mr-3 p-4">
                                    <thead  className="thead-blue">
                                    <tr >
                                            <th className="text-left" width={200}>{t("settingAll:Services code")}</th>
                                            <th className="text-left" >{t("settingAll:Income account")}</th>
                                            <th className="text-left" >{t("settingAll:Account receivables")}</th>
                                            <th className="text-center" >{t("settingAll:Calculation method")}</th>
                                            <th className="text-center">{t("revenue_setting:Price")}</th>
                                            <th className="text-center" >{t("settingAll:Average income")}</th>
                                            <th className="text-left" >{t("settingInvoiceTerms:deferred")}</th>
                                            <th className="text-left"width={130} >{t("settingAll:Billing period")}</th>
                                            <th className="text-left" width={150}>{t("settingAll:Billing month")}</th>
                                            <th className="text-center" >{t("settingAll:Advance notice(month)")}</th>
                                            <th className="text-right" >{t("settingAll:Price/Billing period")}</th>
                                            <th className="text-center" >{t("settingAll:Invoiced start date")}</th>
                                            <th className="text-center" >{t("settingAll:Invoiced due date")}</th>
                                            <th className="text-center" >{t("settingAll:Tenants participate")}</th>
                                            <th className="text-center" >{t("settingAll:Agent participate")}</th>
                                            <th width={100} />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.condition.map((condition,index) => {
                                            let month = ''
                                            _.forEach(condition.billingMonth, (months,index) => {
                                                month = index === 0 ? months : month + " , " + months
                                            })
                                            return (
                                                <tr key={index}>
                                                    <td>{condition.productCode + " " + condition.name}</td>
                                                    <td>{condition.chartOfAccountCode + ' '+condition.chartOfAccount}</td>
                                                    <td>{condition.customerChartOfAccountCode +' ' +condition.customerChartOfAccount}</td>
                                                    <td className="text-left">
                                                        <span>{t(`revenueContract:${condition.pricingType}`)}</span>
                                                    </td>
                                                    <td>{numberWithCommas(condition.price)}</td>
                                                    <td className="text-center">
                                                        <input type="checkbox" disabled={true} checked={condition.periodAdjustment} />
                                                    </td>
                                                    <td>{condition.periodAdjustment && (condition.receiveDepositCode +' '+condition.receiveDeposit)}</td>
                                                    <td>{t(`revenueContract:${condition.charged}`)}</td>
                                                    <td>{month}</td>
                                                    <td>{_.filter(advance,['value',condition.advance])[0].label}</td>
                                                    <td className="text-right">{numberWithCommas(condition.totalPrice)}</td>
                                                    <td className="text-center"> {format(condition.issuedDate,'DD/MM/YYYY')}</td>
                                                    <td className="text-center"> {condition.dueDate ? format(condition.dueDate,'DD/MM/YYYY') : '-'}</td>
                                                    <td className="text-center">
                                                        <input type="checkbox" disabled={true} checked={condition.burdenStatus} />
                                                    </td>
                                                    <td className="text-center">
                                                        <input type="checkbox" disabled={true} checked={condition.agentStatus} />
                                                    </td>
                                                    <td>
                                                        <button onClick={() => this.changeStatus(condition)} disabled={!condition.activeStatus || !_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'ownership_obligation_edit'})} className={condition.activeStatus ? "close-status text-center" : "open-status text-center" } >{condition.activeStatus ? 'ปิดใช้งาน' : 'ปิดใช้งานแล้ว'}</button>
                                                    </td>

                                                </tr>


                                            );
                                        })

                                        }
                                    </tbody>
                                </table>
                                
                            </div>}
                        </Translation>
                    </div>
                </WrapperContent>
            </Wrapper>
            
        );
    }



}
export default RevenueContractDetail;