import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import SettingCollectionDashBoard from './settingCollectionDashBoard';



class SettingCollectionLetter extends Component {
    
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={SettingCollectionDashBoard}/>
                <PrivateRoute exact path={`${this.props.match.url}/:status`} component={SettingCollectionDashBoard}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default SettingCollectionLetter;