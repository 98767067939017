import React from "react";
import "../styles/commonArea.scss";
import "../../../libs/styles/styleColor.scss"
import iconBooking from "../CommonAreaList/create/iconBooking";
import { Translation } from "react-i18next";
const SettingBookingStep3 = ({
    state: {
        MoneytoPoint,
        score_exchange,
        max_topup,
        min_topup,
        savingData
    },
    selectChange,
    data,
    onClickPrev,
    onClickNext
}) => {
    return (
        <Translation>
            {t =>
                <React.Fragment>
                    <div className="d-flex justify-content-center mb-3  mt-5 claim">
                        <div className="create col-8" style={{zoom: '80%'}}>
                            <label>
                                <p className="headerLabel18 fw-600 ml-2">01 <span className="label16 ml-2">{t("newBookingSingha_Setting:Advance booking")}</span></p>
                                <iconBooking.iconStep1 />
                            </label>
                            <label className="ml-2 mr-2">
                                <p className="headerLabel18 fw-600 ml-2">02 <span className="label16 ml-2">{t("newBookingSingha_Setting:Point setup")}</span></p>
                                <iconBooking.iconStep2_1 />
                            </label>
                            <label>
                                <p className="headerLabel18 fw-600 ml-2">03 <span className="label16 ml-2">Money to Point</span></p>
                                <iconBooking.iconStep3 />
                            </label>
                        </div>
                    </div>

                    {/* จำนวนแต้มที่มอบให้ */}
                    <div className="d-flex justify-content-center  mb-3  mt-5 ">
                        <div className="col-8 col-sm-8">
                            <div className="line">
                                <div className="header">Money to Point</div>
                            </div>

                            <div className="claim">
                                <div className="create btnSwitch">
                                    <label className="switch mt-3 mr-3" style={{ width: 43, height: 20 }}>
                                        <input type="checkbox"
                                            name="MoneytoPoint"
                                            defaultChecked={MoneytoPoint}
                                            onChange={selectChange}
                                        />
                                        <span className={MoneytoPoint ? "sliderNew round selected" : "sliderNew round"}></span>
                                    </label>
                                    <label className="headerLabel18 fw-400">{t("newBookingSingha_Setting:Convert money into points")}</label>
                                </div>
                                <div className="text_808080 fs-16 fw-300 mt-2">
                                    {t("newBookingSingha_Setting:Money to Points is a feature where juristic persons can add balance for residents, ")}
                                    <br />
                                    {t("newBookingSingha_Setting:and the program calculates the resulting points for customers automatically based on the calculation formula specified. This minimizes human error and eliminates the need for manual calculation.")}
                                </div>

                                {/* จำนวนเงิน */}
                                {
                                    MoneytoPoint == true &&
                                    <div className="colorUse mt-5">
                                        <label className="text_171B21 mr-3 floatLeft">{t("newBookingSingha_Setting:Amount")}</label>
                                        <label className="text_171B21 ml-3 mr-3 floatLeft">1</label>
                                        <label className="text_171B21 ml-3 mr-3 floatLeft">{t("newBookingSingha_Setting:Baht")}</label>
                                        <label className="text_171B21 ml-3 mr-3 floatLeft">{t("newBookingSingha_Setting:per")}</label>
                                        <input className="form-control w100 floatLeft text-center"
                                            onChange={selectChange}
                                            type={"number"}
                                            pattern="[0-9]+$"
                                            required={MoneytoPoint == true}
                                            name="score_exchange"
                                            defaultValue={score_exchange}
                                        />
                                        <label className="text_171B21 ml-3 mr-3 floatLeft">{t("newBookingSingha_Setting:Point")} </label>

                                    </div>
                                }

                            </div>


                            <div style={{ clear: 'both' }} />
                            <div className="row claim mt-10">
                                <div className="col-6">
                                    <button className="btn-primary-outline" type="button" 
                                        onClick={onClickPrev}>
                                        {t("Allaction:back")}
                                    </button>
                                </div>
                                <div className="col-6 text-right">
                                    <button className="btn-primary" type="submit"
                                        onClick={onClickNext}
                                        disabled={savingData}
                                    >
                                        {savingData &&  <span className="spinner-border spinner-border-sm align-middle mr-3"></span>}
                                        {t("Allaction:save")}
                                    </button>
                                </div>
                                <div className="mt-10 mb-10" />
                            </div>
                        </div>
                    </div>


                    {/* ยอดเงินขั้นต่ำ  - สูงสุดต่อการเติม */}
                    {/* <div className="d-flex justify-content-center  mb-3  mt-5 ">
                <div className="col-8">
                    <div className="line">
                        <div className="header">ยอดเงินขั้นต่ำ  - สูงสุดต่อการเติม</div>
                    </div>

                    <label className="label16 fw-400">ยอดขั้นต่ำ ( บาท )</label>
                    <div className="usedAll">
                        <button className="btnSelectItem selected m-3 ml-0 floatLeft" type="button">
                            <label className="label16 fw-300 mt-2">ไม่มีขั้นต่ำ</label>
                        </button>

                        <button className="btnSelectItem m-3 floatLeft" type="button">
                            <label className="label16 fw-300 mt-2">+ 50</label>
                        </button>

                        <button className="btnSelectItem m-3 floatLeft" type="button">
                            <label className="label16 fw-300 mt-2">+ 100</label>
                        </button>

                        <button className="btnSelectItem m-3 floatLeft" type="button">
                            <label className="label16 fw-300 mt-2">+ 200</label>
                        </button>

                        <button className="btnSelectItem m-3 floatLeft" type="button">
                            <label className="label16 fw-300 mt-2">+ 500</label>
                        </button>

                        <button className="btnSelectItem m-3 floatLeft" type="button">
                            <label className="label16 fw-300 mt-2">+ 1,000</label>
                        </button>
                    </div>

                    

                    <label className="label16 fw-400">ยอดสูงสุด ( บาท )</label>
                    <div className="usedAll">
                        <button className="btnSelectItem selected m-3 ml-0 floatLeft" type="button">
                            <label className="label16 fw-300 mt-2">ไม่จำกัด</label>
                        </button>

                        <button className="btnSelectItem m-3 floatLeft" type="button">
                            <label className="label16 fw-300 mt-2">2,000 +</label>
                        </button>

                        <button className="btnSelectItem m-3 floatLeft" type="button">
                            <label className="label16 fw-300 mt-2">5,000 + </label>
                        </button>

                        <button className="btnSelectItem m-3 floatLeft" type="button">
                            <label className="label16 fw-300 mt-2">10,000 +</label>
                        </button>
                    </div>

                </div>
            </div> */}



                </React.Fragment>
            }
        </Translation>
    );
};

export default SettingBookingStep3;