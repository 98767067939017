/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type bankDepositImpactReportQueryVariables = {|
  lastDate?: ?any
|};
export type bankDepositImpactReportQueryResponse = {|
  +selfProject: ?{|
    +name: string
  |},
  +allBankAccountReport: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +bankName: string,
        +branch: string,
        +accountType: BankAccountAccountType,
        +accountNumber: string,
        +summaryBankTransaction: ?any,
        +bankUnit: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +issuedDate: ?any,
              +dueDate: ?any,
              +price: number,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +summaryBankReportPurchaseRecordGroup: ?{|
    +numRow: number,
    +sumBalance: number,
  |},
|};
export type bankDepositImpactReportQuery = {|
  variables: bankDepositImpactReportQueryVariables,
  response: bankDepositImpactReportQueryResponse,
|};
*/


/*
query bankDepositImpactReportQuery(
  $lastDate: Date
) {
  selfProject {
    name
    id
  }
  allBankAccountReport(lastDate: $lastDate, bankAccountTransactionReport: true) {
    edges {
      node {
        id
        bankName
        branch
        accountType
        accountNumber
        summaryBankTransaction
        bankUnit {
          edges {
            node {
              issuedDate
              dueDate
              price
              id
            }
          }
        }
      }
    }
  }
  summaryBankReportPurchaseRecordGroup(lastDate: $lastDate) {
    numRow
    sumBalance
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lastDate"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "lastDate",
  "variableName": "lastDate"
},
v3 = [
  {
    "kind": "Literal",
    "name": "bankAccountTransactionReport",
    "value": true
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "branch",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summaryBankTransaction",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    (v2/*: any*/)
  ],
  "concreteType": "summaryBankReportObject",
  "kind": "LinkedField",
  "name": "summaryBankReportPurchaseRecordGroup",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numRow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sumBalance",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bankDepositImpactReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "BankAccountNodeConnection",
        "kind": "LinkedField",
        "name": "allBankAccountReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankUnitNodeConnection",
                    "kind": "LinkedField",
                    "name": "bankUnit",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankUnitNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BankUnitNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v13/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bankDepositImpactReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "BankAccountNodeConnection",
        "kind": "LinkedField",
        "name": "allBankAccountReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankUnitNodeConnection",
                    "kind": "LinkedField",
                    "name": "bankUnit",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankUnitNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BankUnitNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v13/*: any*/)
    ]
  },
  "params": {
    "cacheID": "9af2b130958e1fbed183839030894a70",
    "id": null,
    "metadata": {},
    "name": "bankDepositImpactReportQuery",
    "operationKind": "query",
    "text": "query bankDepositImpactReportQuery(\n  $lastDate: Date\n) {\n  selfProject {\n    name\n    id\n  }\n  allBankAccountReport(lastDate: $lastDate, bankAccountTransactionReport: true) {\n    edges {\n      node {\n        id\n        bankName\n        branch\n        accountType\n        accountNumber\n        summaryBankTransaction\n        bankUnit {\n          edges {\n            node {\n              issuedDate\n              dueDate\n              price\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n  summaryBankReportPurchaseRecordGroup(lastDate: $lastDate) {\n    numRow\n    sumBalance\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '23f8da664611bd07d6b8e304d1a20dc0';

module.exports = node;
