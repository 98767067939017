import React from 'react';
import ReactPaginate from 'react-paginate';
import localStorage from './localstorage';
import {Translation} from "react-i18next";

export default class pagination extends React.Component {

    constructor(props) {
        super(props);
        this.changePageLimit = this.changePageLimit.bind(this);
    }

    changePageLimit(value) {
        localStorage.setPageLimit(value);
        this.props.changePage({first: value, last: value});
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-6 text-left">
                    {/*{this.props.totalCount > localStorage.getPageLimit() &&*/}
                    <div className="dropdown page-limit d-none d-md-block page-limit">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <Translation>{t => t('pagination:show')}</Translation> 
                            {localStorage.getPageLimit()} <Translation>{t => t('pagination:items/page')}</Translation> 
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {/* <div className="dropdown-item" onClick={() => this.changePageLimit(5)}>แสดง 5
                                รายการ/หน้า
                            </div> */}
                            <div className="dropdown-item" onClick={() => this.changePageLimit(10)}><Translation>{t => t('pagination:show')}</Translation>  10
                                <Translation>{t => t('pagination:items/page')}</Translation> 
                            </div>
                            <div className="dropdown-item" onClick={() => this.changePageLimit(20)}><Translation>{t => t('pagination:show')}</Translation>  20
                                <Translation>{t => t('pagination:items/page')}</Translation> 
                            </div>
                            <div className="dropdown-item" onClick={() => this.changePageLimit(50)}><Translation>{t => t('pagination:show')}</Translation>  50
                                <Translation>{t => t('pagination:items/page')}</Translation> 
                            </div>

                            <div className="dropdown-item" onClick={() => this.changePageLimit(100)}><Translation>{t => t('pagination:show')}</Translation>  100
                                <Translation>{t => t('pagination:items/page')}</Translation> 
                            </div>
                            {/*<div className="dropdown-item" onClick={() => this.changePageLimit(100)}>แสดง 100*/}
                            {/*    รายการ/หน้า*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {/*}*/}
                </div>
                <div className="col-6 text-right">
                    {this.props.totalCount > localStorage.getPageLimit() &&
                    <ReactPaginate
                        onPageChange={
                            (pagination) => { // total 11 item page 2
                                let is_last_page = (this.props.totalCount - localStorage.getPageLimit() * (pagination.selected + 1) < 0);
                                this.props.changePage({
                                    first: localStorage.getPageLimit() * (pagination.selected + 1),
                                    last: is_last_page ? this.props.totalCount - localStorage.getPageLimit() * (pagination.selected) : localStorage.getPageLimit(),
                                });
                            }
                        }
                        pageCount={(this.props.totalCount / localStorage.getPageLimit())}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={<span className="btn page-link">...</span>}
                        breakClassName={"break-me"}
                        pageClassName="page-item"
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={4}
                        previousClassName="sr-only"
                        containerClassName={"pagination float-right"}
                        subContainerClassName={"pagination-page"}
                        nextLinkClassName="sr-only"
                        pageLinkClassName="page-link"
                        activeClassName={"active"}
                        forcePage={Math.ceil(this.props.first / localStorage.getPageLimit()) - 1}
                    />
                    }
                    <div className="total-item-count"><Translation>{t => t('pagination:all_items')}</Translation>  {this.props.totalCount && this.props.totalCount.toLocaleString()}</div>
                </div>
            </React.Fragment>
        );
    }
}
