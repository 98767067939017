import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from "react-i18next";


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab"  >
                <div className="col">
                    <ul>
                        <li>
                            <NavLink exact={true} to="/project/juristic-manager/list/current">
                                <Translation>
                                    {(t) => <span>{t("juristicManager:Current Juristic Manager")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/project/juristic-manager/list/history">
                                <Translation>
                                    {(t) => <span>{t("juristicManager:History Juristic Manager")}</span>}
                                </Translation>
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation; 