import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import i18n from 'i18next';
import '../styles/register.scss'
import _ from "lodash";
import jwtDecode from 'jwt-decode'
import {format} from "date-fns";

import Navigation from "./navigation";
import {graphql} from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import {fetchQuery} from "relay-runtime";
import upload from '../../libs/upload'
import i18next from 'i18next';

const mutation = graphql`
    mutation createUpdatePetMutation($input: CreateAndUpdatePetInput!){
        createAndUpdatePet(input: $input){
            ok
        }
    }`
;

const query = graphql`
    query createUpdatePetQuery($id :ID!, $residential_id :ID!){
        residential(id: $residential_id){
            name
        }
        pet(id: $id){
            id
            petType
            description
            imagePet
            healthCheckPath
            healthCheckName
        }
    }
`;

class CreateUpdatePet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image_upload: '',
            image: '',
            id: '',
            pet_type: '',
            description: '',
            redirectToList: false,
            residential_name:"",
            healthCheckPath:'',
            healthCheckName:''

        };
        this.handleInputImage = this.handleInputImage.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.uploadFile = this.uploadFile.bind(this)
    }

    componentWillMount() {
        fetchQuery(environment, query, {id: this.props.match.params.pet_id|| "", residential_id: this.props.match.params.id}).then(data => {
            if (data.pet) {
                this.setState({
                    id: data.pet.id,
                    pet_type: data.pet.petType,
                    description: data.pet.description,
                    image: data.pet.imagePet,
                    healthCheckPath: data.pet.healthCheckPath,
                    healthCheckName: data.pet.healthCheckName,
                })
            }
            if(data.residential){
                this.setState({residential_name: data.residential.name})
            }
        });

    }

    handleInputImage(e) {
        if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === 'image') {
            this.setState({
                image_upload: e.currentTarget.files[0],
                image: URL.createObjectURL(e.target.files[0])
            });
        }
    }

    handleChangeInput(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/pet" + path, file);
            resolve(res);
        });
    };

    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files) {
            if (files?.size > 10485760) { //2097152  10485760
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg' && files?.type !== 'application/pdf' && files?.type !== 'application/msword') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .png, .jpg, .jpeg , .pdf, .doc ${i18next.t("Allaction:Only")} ${i18next.t("Allaction:and each file should not exceed")} 10 MB`, 'error')
            }else {
                let user = jwtDecode(localStorage.getItem("token"))
                let typeFile = files.type.split("/")
                // fileIsSel = false
                let new_file = new File([files], `${user.user_id}-${Math.floor(Math.random() * 100001)}${format(new Date(), "YYYYMMDDHHmmss")}.${typeFile[1]}`);
                this.callUpload('', new_file).then((data) => {
                    let dese = "pet/" + new_file.name
                    let fileUpload = []
                   
                    fileUpload.push({ id: "", fileName: new_file.name, fileUpload: dese, viewPtath: data.location, status: "" })
                    this.setState({ healthCheckPath: dese, healthCheckName:new_file.name  })
                });
            }
        }
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let petObject = {
            id: this.state.id,
            residential_id: this.props.match.params.id,
            pet_type: this.state.pet_type,
            description: this.state.description,
            health_check_path: this.state.healthCheckPath,
            health_check_name: this.state.healthCheckName,
        };
        let uploadables = {image_pet: this.state.image_upload};
        let variables = {
            input: {
                petObject: JSON.stringify(petObject)
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                uploadables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createAndUpdatePet.ok) {
                        Swal.fire(i18n.t("pats:Save successfully!"), '', 'success').then(() => {
                            this.setState({redirectToList: true})
                        });
                    } else {
                        Swal.fire(i18n.t("pats:Save unsuccessfully!"), '', 'warning');
                    }

                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire(i18n.t("pats:Save unsuccessfully!"), '', 'error');
                },
            },
        );


    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to={{
                pathname: "/register/residential/detail/pet-detail/" + this.props.match.params.id}}/>
        }

        let opt2 =  this.state.healthCheckName && this.state.healthCheckName
        let typeFile 
            if(opt2){
                typeFile =  opt2.slice(opt2.lastIndexOf('.')+1)
            }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <div className="container-fluid p-4 pet-create" id="form-detail">
                        <div className="row justify-content-between">
                            <div className="col header-color">
                                <h3 className="mb-4">
                                    <Link
                                        to={{
                                            pathname: "/register/residential/detail/pet-detail/" + this.props.match.params.id}}>
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    {this.state.residential_name}
                                </h3>
                                <div className="content-inner">
                                    <Navigation id={this.props.match.params.id}/>
                                </div>
                            </div>
                        </div>

                        <div className="content-inner">
                            <div className="row mb-4 fade-up">
                                <div className="col-md-4">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <Translation>
                                                        {t=><h6>{t("pats:Pet type")}</h6>}
                                                    </Translation>
                                                    <Translation>
                                                        {t=>
                                                            <h4 className="mb-3">
                                                                {
                                                                    this.props.match.params.id ? t('register:Edit pet details') : 
                                                                    <span> {t('register:add_new_pet')}</span>
                                                                }                                                        
                                                            </h4>
                                                        }
                                                    </Translation>
                                                </div>
                                            </div>
                                            <form action="" onSubmit={this.onSubmit} id="create-update-pet">
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        {this.state.image ?
                                                            <div
                                                                className="rounded-circle avatar-img profile-img img-border"
                                                                style={{backgroundImage: `url(${this.state.image})`}}>
                                                            </div>
                                                            :
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/images/icons/pet-profile-icon.png'}
                                                                className="rounded-circle avatar-img profile-img"
                                                                alt="project-manager-profile"/>
                                                        }
                                                    </div>
                                                    <div className="col">
                                                        <Translation>{t=><h6 className="mt-4">{t("pats:Picture")}</h6>}</Translation>
                                                        <div className="upload-file">
                                                            <div>
                                                                <Translation>
                                                                    {t=><label className="btn-link"
                                                                           htmlFor="uploadImage">{t("pats:Change picture")}</label>}
                                                                </Translation>
                                                            </div>
                                                            <div className="upload-input">
                                                                <input type="file" className="custom-file-input"
                                                                       id="uploadImage" name="image"
                                                                       accept="image/*"
                                                                       onChange={this.handleInputImage}
                                                                       required={this.state.image ? false : "disabled"}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>
                                                            {t=><h6>{t("pats:Pet type")}</h6>}
                                                        </Translation>
                                                        <input type="text" className="form-control"
                                                               value={this.state.pet_type}
                                                               onChange={this.handleChangeInput}
                                                               name="pet_type" required/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("pats:Additional characteristics (such as color, defect)")}</h6>}</Translation>
                                                        <input type="text" className="form-control"
                                                               value={this.state.description}
                                                               onChange={this.handleChangeInput}
                                                               name="description" required/>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                <div className="col">
                                                    <div className="uploadBTN">
                                                        <Translation>{t=><h6>{t("pats:Health check docs")}</h6>}</Translation>
                                                         <label htmlFor="FileApprove" className="upload-btn-wrapper">
                                                         <Translation>{t=>
                                                                <button className={"btn"} type='button btn-'>
                                                                <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'} className='mr-3' style={{height:'10px'}} />
                                                                {t("pats:Attach pet picture")}
                                                                </button>}
                                                        </Translation>
                                                            
                                                          <Translation>{t=><span>{t("projectManager:The system supports .pdf .docx files up to 10MB in size.")}</span>}</Translation>
                                                          <input type="file" name='verifyIdentityDocument' id="FileApprove" className='form-control uploadFile'
                                                            accept="application/pdf,image/*" onChange={this.uploadFile}
                                                          />

                                                    </label>
                                                    </div>
                                                </div>
                                                </div>

                                                <div className="row mt-4">
                                                <div className="col">
                                                {
                                                    this.state.healthCheckName && 
                                                    <p>
                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile + "Icon.png"} style={{ width: '30px' }} /> &nbsp;
                                                                 <Link
                                                                     to="route"
                                                                     onClick={(event) => {
                                                                        event.preventDefault();
                                                                        window.open(
                                                                            this.state.healthCheckPath
                                                                        )
                                                                    }} 
                                                                    target="blank">
                                                                          {this.state.healthCheckName}
                                                                  </Link>
                                                        </p>
                                                }
                                                
                                                </div>
                                                </div>
                                            </form>

                                            <div className="row mt-4">
                                                <div className="col">
                                                    <Link
                                                        to={{
                                                            pathname: "/register/residential/detail/pet-detail/" + this.props.match.params.id}}>
                                                        <Translation>
                                                            {t=>
                                                            <button type="button"
                                                                    className="btn btn-secondary bt-style form-update">
                                                                {t("pats:Cancel")}
                                                            </button>}
                                                        </Translation>
                                                    </Link>
                                                </div>
                                                <div className="col">
                                                    <Translation>
                                                        {t=>
                                                        <button type="submit" form="create-update-pet"
                                                                className="btn btn-primary bt-style form-update"
                                                                disabled={this.state.loading}>
                                                            {this.state.loading &&
                                                            <span
                                                                className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                            {t("pats:Save")}
                                                        </button>}
                                                    </Translation>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default CreateUpdatePet;
