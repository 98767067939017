import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5xy from "@amcharts/amcharts5/xy";
import { format } from "date-fns";
import i18next from "i18next";

class TrackingDisplayBarchart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      loading: false,
      checkOnline: true,
    };
  }
  componentDidMount() {
    if (this.props.data.status === true) {
      this.processData(this.props.data);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && this.props.data.status === true) {
      this.processData(this.props.data);
    }
  }
  // sort data by use merge sort
  mergeSort(arr = []) {
    if (arr.length <= 1) {
        return arr;
    }

    const mid = Math.floor(arr.length / 2);
    const left = arr.slice(0, mid);
    const right = arr.slice(mid);

    return this.merge(this.mergeSort(left), this.mergeSort(right));
  }

  merge(left, right) {
      const result = [];
      let leftIndex = 0;
      let rightIndex = 0;

      while (leftIndex < left.length && rightIndex < right.length) {
          const leftValue = Object.values(left[leftIndex])[0];
          const rightValue = Object.values(right[rightIndex])[0];

          if (leftValue >= rightValue) {
              result.push(left[leftIndex]);
              leftIndex++;
          } else {
              result.push(right[rightIndex]);
              rightIndex++;
          }
      }

      return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
  }

  processData(trackingData) {
    const uniqueCodes = new Set();
    const sortedData = this.mergeSort(trackingData.data[0].code_data);
    
    const dataWithName = sortedData.map(item => {
      if (item.name) {
          const firstKey = Object.keys(item)[0];
          const {[firstKey]: oldValue, ...rest} = item;
          return {
              [item.name]: oldValue,
              ...rest
          };
      }
      return item;
    });

    dataWithName.forEach((codeEntry) => {
        Object.keys(codeEntry).forEach((code, index) => {
          index === 0 && uniqueCodes.add(code);
        });
    });

    const dataBtn = Array.from(uniqueCodes);
    let data = [];

    let dateFormat = trackingData.data?.length > 7 ? "DD-MMM" : "DD-MMM-YY";
    trackingData.data.forEach((item) => {
      const newEntry = { period: format(item.date, dateFormat) };
      item.code_data.forEach((code) => {
          for (const key in code) {
            if((key !== 'id' && key !== 'page' && key !== 'name') && code.name){
                // กรณีที่มี name (ข้อมูลใหม่)
                newEntry[code.name] = code[Object.keys(code)[0]];
            } else if(((key !== 'id' && key !== 'page' && key !== 'name') && !code.name)){
                // กรณีที่ไม่มี name (ข้อมูลเก่า)
                newEntry[key] = code[key];
            }
          }
      });
      data.push(newEntry);
    });
    this.genChart(data, dataBtn);
  }

  genChart(chartData, dataBtn) {
    if (this.root) {
      this.root.dispose();
      this.chart.dispose();
    }
    let data = chartData.reverse();

    let root = am5.Root.new("trackingDisplayBarChart");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 40,
        paddingLeft: 30,
      })
    );

    chart.children.unshift(
      am5.Label.new(root, {
        text: `${i18next.t("dataAnalyticTrackingMenu:Show comparison button")} ${this.props.dateHeaderChart}`,
        fontSize: 16,
        fontWeight: 600,
        textAlign: "left",
        paddingTop: 0,
        paddingBottom: 80,
      })
    );

    let scrollbarX = am5.Scrollbar.new(root, {
      orientation: "horizontal",
    });

    chart.set("scrollbarX", scrollbarX);
    chart.bottomAxesContainer.children.push(scrollbarX);
    chart.set(scrollbarX);

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        paddingTop: 30,
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 10,
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
    });

    if(data?.length > 7){
      xRenderer.labels.template.setAll({
        rotation: 30,
        centerX: am5.p50
      });
    }

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "period",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
    });

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    function makeSeries(name, fieldName) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "period",
        })
      );

      series.columns.template.setAll({
        tooltipText: `${name}: {valueY} ${i18next.t("dataAnalyticTrackingMenu:Time(s)")}`,
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.data.setAll(data);

      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }

    // loop btn use for gen chart
    let dataMakeSeries = dataBtn;
    dataMakeSeries.map((item, index) => {
      makeSeries(`${i18next.t("dataAnalyticTrackingMenu:Button")} ${item}`, `${item}`);
    });

    // Export
    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
    });

    let annotator = am5plugins_exporting.Annotator.new(root, {});

    let menuitems = exporting.get("menu").get("items");

    menuitems.push({
      type: "separator",
    });

    menuitems.push({
      type: "custom",
      label: "Annotate",
      callback: function () {
        this.close();
        annotator.toggle();
      },
    });

    chart.appear(1000, 100);
    root._logo.dispose();

    this.chart = chart;
    this.root = root;
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="trackingDisplayBarChart"></div>
      </React.Fragment>
    );
  }
}

export default TrackingDisplayBarchart;
