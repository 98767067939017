import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const query = graphql`
query positionPermissionSiteQuery ($siteId: String) {
    positionPermissionSite(siteId: $siteId){
        edges{
            node{
                id
                positionName
                permissionPerPosition{
                    edges{
                        node{
                            id
                            permission{
                                id
                                codename
                                name
                            }
                        }
                    }
                }
            }
        }
    }              
}
`

export const GetPositionPermissionSite = (variables, response) => {
    fetchQuery(environment, query, variables)
        .then((data) => {
            if (data) {
                response(data?.positionPermissionSite?.edges);
            }else{
                response(null);
            }
        })
        .catch((error) => {
            response(null);
        });
};
