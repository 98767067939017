import React, {Component} from "react";
import {format} from "date-fns";
import getNameResidential from "../../../../libs/getNameResidential";
import { graphql } from "babel-plugin-relay/macro";
import jwtDecode from 'jwt-decode';
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import './guaranteeMoneyReceivedReportPDF.scss';
import i18next from "i18next";

const query = graphql`
  query guaranteeMoneyReceivedReportPDFRenderTableQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

class GuaranteeMoneyReceivedReportPDFRenderTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            projectName: "",
            printBy: "",
            start_date: "",
            end_date: "",
            contactName: "",
            statusType: "",
            paymentChannel: "",
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            if (res.selfProject) {
                let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
                this.setState({
                    data: this.props.props,
                    projectName: res.selfProject.name,
                    printBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    contactName: this.props.contactName,
                    statusType: this.props.statusType,
                    paymentChannel: this.props.paymentChannel,
                })
            }
        }
        )
    }
    paymentChannel(guarantee) {
        let text = '-';
        if (guarantee.cashDepositReceiveTransaction.totalCount > 0) {
            text = "เงินสด";
        } else if (guarantee.bankAccountTransaction.totalCount > 0) {
            text = "โอนเงิน";
        } else if (guarantee.chequeDeposit.totalCount > 0) {
            text = "เช็ค";
        }
        return text
    }

    getStatus(status) {
        let list = {
            "IN_PROCESS": "อยู่ระหว่างดำเนินการ",
            "FORFEIT_ALL": "ทำการยึด",
            "RETURN_FORFEIT": "ยึดบางส่วน/คืนบางส่วน",
            "COMPLETED": "ทำการคืน",
            "VOID": "รายการที่ยกเลิก",
        };
        return list[status]
    }

        //เปลี่ยน format number
        numberWithComma(num) {
            let isNegative = false;

            // if (num === 0) {
            //     return formattedNum = "-";
            //   }
            // Check if the number is negative
            if (num < 0) {
              isNegative = true;
              num = Math.abs(num); // Convert negative number to positive
            }
            // Add .00 to the number
            num = num.toFixed(2);
            // Format the number with commas
            let formattedNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            if (num === 0) {
                return formattedNum = "-";
              }
            // Add a minus sign for negative numbers
            if (isNegative) {
              formattedNum = '(' + formattedNum + ')';
            }
            return formattedNum;
        }

    render() {
        //  ประมวลผลข้อมูล
        // let summaryPrice = 0;
        let order = 0;
        let dataChunked = [];
        let rowPerpage = 30 ;
        for (let i=0;i<this.props.props.allGuaranteeMoneyReceived.edges.length ;i += rowPerpage) {
            let chunk = this.props.props.allGuaranteeMoneyReceived.edges.slice(i, i + rowPerpage);
            dataChunked.push(chunk);            
        }

        let total = 0;      
        return (
            <React.Fragment>
                {dataChunked.map((data,page_index)=>(
                        <div className='print-page-a4 hoziA4' key={page_index}>
                                        {/* แสดงหัวกระดาษ */}
                                        <div>
                                                <p className='f12' style={{ lineHeight: 1.5 }}> {this.state.projectName}</p>
                                                <p className='f10'>รายงานสรุปรับเงินค้ำประกัน</p>
                                                <p className='f10'>เรียงตามประเภทลูกค้า - {this.state.contactName}</p>
                                                <p className='f10'>เรียงตามผู้ติดต่อ - {this.state.contactName}</p>
                                                <p className='f10'>เรียงตามสถานะ - {this.state.statusType}</p>
                                                <p className='f10'>เรียงตามวิธีการชำระเงิน - {this.state.paymentChannel}</p>
                                                <p className='f10'>เรียงตามวันที่ - {format(this.state.start_date, "DD-MM-YYYY")} ถึง {format(this.state.end_date, "DD-MM-YYYY")}</p>
                                        </div>
                                        {/* แสดงตาราง */}
                                        <table className="table tebleBorder">
                                            <thead className='tableHead'>
                                                    <tr>
                                                        <th className="text-center">ลำดับ</th>
                                                        <th className="text-center width10">
                                                            เลขที่ใบรับเงินค้ำประกัน
                                                        </th>
                                                        <th className="text-center">วันที่รับ</th>
                                                        <th className="text-center">เลขที่ห้อง/บ้านเลขที่</th>
                                                        <th className="text-center width20">ชื่อ</th>
                                                        <th className="text-center width20">รายละเอียด</th>
                                                        <th className="text-center width10">จำนวนเงิน</th>
                                                        <th className="text-center">วิธีการรับชำระ</th>
                                                        <th className="text-center">สถานะ</th>
                                                    </tr>
                                            </thead>

                                            <tbody className='tableBody'>                
                                                {data.map((guarantee, index) => {
                                                // let row_number = index + 1;
                                                // let order_id = this.props.state.first - localStorage.getPageLimit();
                                                // let order_id = 0;
                                                if (guarantee.node.status !== "VOID") {
                                                    total += guarantee.node.amount;
                                                    return (
                                                        <React.Fragment>
                                                            <tr key={guarantee.node.id}>
                                                                <td className="text-center">{order+=1}</td>
                                                                <td className="text-center">{guarantee.node.docNumber}</td>
                                                                <td className="text-center">{format(guarantee.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                                <td className="text-center">
                                                                    {guarantee.node.contact.residential ? guarantee.node.contact.residential.name : "-"}
                                                                </td>
                                                                <td className="text-left">
                                                                    {guarantee.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                                        getNameResidential(guarantee.node.contact.firstName, guarantee.node.contact.lastName)
                                                                    }

                                                                    {guarantee.node.contact.typeOfContact === "SUPPLIER" &&
                                                                        guarantee.node.contact.name
                                                                    }
                                                                    {" "}                                            
                                                                    {
                                                                    (guarantee.node.payGroup === "RENT" || guarantee.node.payGroup === "AGENT" || guarantee.node.payGroup === "OWNER" || guarantee.node.payGroup === "DEVELOPER") &&
                                                                    <label>
                                                                    ({guarantee.node.payGroup === "RENT" || guarantee.node.payGroup === "AGENT" || guarantee.node.payGroup === "OWNER" || guarantee.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${guarantee.node.payGroup}`) : ""})
                                                                    </label>
                                                                    }                                                                           
                                                                </td>
                                                                <td className="text-left">{guarantee.node.description}</td>
                                                                <td className="text-right">{this.numberWithComma(guarantee.node.amount)}</td>
                                                                <td className="text-center">{this.paymentChannel(guarantee.node)}</td>
                                                                <td className="text-center">{this.getStatus(guarantee.node.status)}</td>
                                                            </tr>
                                                            {   
                                                                (index === data.length - 1) &&
                                                                (<tr>
                                                                    <td colSpan={6} className="text-center"><strong>รวม</strong></td>
                                                                    <td className="text-right"><strong>{this.numberWithComma(total)}</strong></td>
                                                                    <td colSpan={9} />
                                                                </tr>)
                                                            }
                                                        </React.Fragment>                
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <tr key={guarantee.node.id}>
                                                            <td className="text-center">{order+=1}</td>
                                                            <td className="text-center">
                                                                    {guarantee.node.docNumber}
                                                            </td>
                                                            <td className="text-center">{format(guarantee.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                            <td className="text-center">
                                                                {guarantee.node.contact.residential ? guarantee.node.contact.residential.name : "-"}
                                                            </td>
                                                            <td className="text-center">
                                                                {guarantee.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                                    getNameResidential(guarantee.node.contact.firstName, guarantee.node.contact.lastName)
                                                                }

                                                                {guarantee.node.contact.typeOfContact === "SUPPLIER" &&
                                                                    guarantee.node.contact.name
                                                                }
                                                                    {" "}                                            
                                                                    {
                                                                    (guarantee.node.payGroup === "RENT" || guarantee.node.payGroup === "AGENT" || guarantee.node.payGroup === "OWNER" || guarantee.node.payGroup === "DEVELOPER") &&
                                                                    <label>
                                                                    ({guarantee.node.payGroup === "RENT" || guarantee.node.payGroup === "AGENT" || guarantee.node.payGroup === "OWNER" || guarantee.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${guarantee.node.payGroup}`) : ""})
                                                                    </label>
                                                                    }                                                                    
                                                            </td>
                                                            <td className="text-center">{guarantee.node.description}</td>
                                                            <td className="text-center">{this.numberWithComma(0)}</td>
                                                            <td className="text-center">{this.paymentChannel(guarantee.node)}</td>
                                                            <td className="text-center">{this.getStatus(guarantee.node.status)}</td>
                                                        </tr>
                                                    )
                                                }
                                                })}
                                            </tbody>
                                            <tfoot className="tableFooter">
                                                {page_index === dataChunked.length -1 &&                                                
                                                    <tr>
                                                        <td colSpan={6} className="text-center"><strong>รวมทั้งหมด</strong></td>
                                                        <td className="text-right"><strong>{this.numberWithComma(this.props.props.summaryGuaranteeMoneyReceived)}</strong></td>
                                                        <td colSpan={9} />
                                                    </tr>
                                                } 
                                            </tfoot>
                                        </table>

                                        {/* แสดงเลขหน้า และผู้พิมพ์ */}
                                        <div className="footer">
                                            <div className="row">
                                                <div className="col-5" />
                                                <div className="col-2 text-center">
                                                    <p className="f10 pb-0 mb-0">{page_index + 1}/{dataChunked.length}</p>
                                                </div>
                                                <div className="col-5 text-right">
                                                    <p className="f10 pb-0 mb-0">
                                                        ผู้พิมพ์ {this.state.printBy} วันที่พิมพ์{format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>   
                        </div>
                ))}      
            </React.Fragment>  
        )
    }
}

export default GuaranteeMoneyReceivedReportPDFRenderTable;