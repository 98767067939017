export default function GetAddressRole(contact, role) {

    let prefixCity = "";
    let prefixDistrict = "";
    let prefixProvince = "";

    let address, district, city, provice, postalCode = "";


    if (role === "OWNER") {
        address = contact.registeredAddress?.replace("เลขที่", '');
        district = contact.registeredDistrict;
        city = contact.registeredCity;
        provice = contact.registeredProvince;
        postalCode = contact.registeredPostalCode;
    }else{
        address = contact.registeredAddress?.replace("เลขที่", '');
        district = contact.registeredDistrict;
        city = contact.registeredCity;
        provice = contact.registeredProvince;
        postalCode = contact.registeredPostalCode;
    }

    let provincCk = false
    provincCk = provice?.includes("กทม") || provice?.includes("กรุงเทพ")

    prefixDistrict = provincCk ? "แขวง" : "ต."
    prefixCity = provincCk ? "เขต" : "อ."
    prefixProvince = !provincCk ? "จ." : ""

    let province = provincCk ? "กรุงเทพมหานคร" : provice




    return {
        address, district, city, province, postalCode,
        prefixDistrict, prefixCity, prefixProvince
    };
}
