import React from "react";
import ReactExport from "react-data-export";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class ExportfixRequestExcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            multiDataSet: [],
        };
        this.buttonElement = this.buttonElement.bind(this);
        this.setData = this.setData.bind(this)
    }

    // componentWillMount(){
    //     this.setData(this.props.data)
    // }
    
    componentDidUpdate(prevProps, prevState, snapshot) {  
        if (this.props.data && (this.props.data !== prevProps.data)) {
            this.setData(this.props.data)
        } 
    }
    componentDidMount(){
        this.setData(this.props.data)
    }

    setData(data_query) {

        // console.log(data_query);
        let data = [];
        let stauts =
            this.props.status === "pending" ? "เรียงตามสถานะ - รอดำเนินการ" :
                this.props.status === "approve" ? "เรียงตามสถานะ - อนุมัติค่าใช้จ่าย" :
                    this.props.status === "finish" ? "เรียงตามสถานะ - เสร็จสิ้น" : "เรียงตามสถานะ - ยกเลิก"
        let date = this.props.startDate && this.props.endDate ? 
            "เรียงตามวันที่ " + format(this.props.startDate , "DD/MM/YYYY") + " ถึง " + format(this.props.endDate , "DD/MM/YYYY") : 
            "เรียงตามวันที่ - ถึง -"
        data.push([{ value: stauts }]);
        data.push([{ value: date }]);

        this.props.status !== "void" ?
            data.push([
                { value: "ลำดับ", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "ห้อง", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "หมวดงาน", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "ชนิดงาน /รายละเอียดงาน", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "พื้นที่งาน", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "ผู้รับผิดชอบ", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "ประเภท", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "เจ้าหน้าที่", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "ชื่อช่าง / ผู้รับเหมา", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "เบอร์โทรติดต่อ", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "วันที่ดำเนินการ", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "เวลา", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
            ])
            :
            data.push([
                { value: "ลำดับ", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "ห้อง", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "หมวดงาน", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "เหตุผลที่ปฏิเสธงาน", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "พื้นที่งาน", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
                { value: "ผู้รับผิดชอบ", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
            ]);

        data_query.map((n, index) => {
            let person_working = n.person_working === "S-Homecare" ? "S-Homecare" : "S KLAS"
            let workSheet = n.worksheet[0]
            this.props.status !== "void" ?
                data.push([
                    { value: index + 1, style: { alignment: { horizontal: "center", vertical: 'center' } } },
                    { value: n.residential?.name || 'นิติบุคคล', style: { alignment: { horizontal: "center", vertical: 'center' } } },
                    { value: n.working_type, style: { alignment: { horizontal: "center", vertical: 'center' } } },
                    { value: n.material + ":" + n.details, style: { alignment: { vertical: 'center', wrapText: true } } },
                    { value: n.repair_type === "private" ? "ส่วนตัว" : "ส่วนกลาง", style: { alignment: { horizontal: "center", vertical: 'center' } } },
                    { value: person_working, style: { alignment: { horizontal: "center", vertical: 'center' } } },
                    { value: workSheet?.working_type === "repair" ? "ซ่อมแซม" : workSheet?.working_type === "improvement" ? "ต่อเติม" : "อื่นๆ", style: { alignment: { horizontal: "center", vertical: 'center' } } },
                    { value: workSheet?.technician === "inside" ? 'ช่างในโครงการ' : "ช่างนอกโครงการ", style: { alignment: { horizontal: "center", vertical: 'center' } } },
                    {
                        value: workSheet?.job_responsed_by_staff_name ? workSheet.job_responsed_by_staff_name :
                            workSheet?.project_staff ?
                                workSheet?.project_staff.name_title + workSheet?.project_staff.name_staff + " " + workSheet?.project_staff.last_name_staff :
                                workSheet?.project_staff_other, style: { alignment: { horizontal: "center", vertical: 'center', wrapText: true } }
                    },
                    { value: workSheet?.phone, style: { alignment: { horizontal: "center", vertical: 'center', wrapText: true } } },
                    { value: format(workSheet?.appointments, "DD/MM/YYYY"), style: { alignment: { horizontal: "center", vertical: 'center', wrapText: true } } },
                    { value: format(workSheet?.appointments, "HH:mm น."), style: { alignment: { horizontal: "center", vertical: 'center', wrapText: true } } },
                ])
                :
                data.push([
                    { value: index + 1, style: { alignment: { horizontal: "center" } } },
                    { value: n.residential?.name || 'นิติบุคคล', style: { alignment: { horizontal: "center" } } },
                    { value: n.working_type, style: { alignment: { horizontal: "center" } } },
                    { value: n.remark, style: { alignment: { horizontal: "center", vertical: 'center', wrapText: true } } },
                    { value: n.repair_type === "private" ? "ส่วนตัว" : "ส่วนกลาง", style: { alignment: { horizontal: "center" } } },
                    { value: person_working, style: { alignment: { horizontal: "center" } } },
                ]);
                return n
        })

        let multiDataSet = []
        let header = this.props.for === "tenant" ? "รายงานคำร้องงานแจ้งซ่อมของลูกบ้าน" : "รายงานคำร้องงานแจ้งซ่อมของส่วนกลาง"

        this.props.status !== "void" ?
            multiDataSet = [
                {
                    columns: [
                        { title: header, width: { wpx: 40 }, },
                        { title: "", width: { wpx: 90 } },
                        { title: "", width: { wpx: 150 } },
                        { title: "", width: { wpx: 160 } },
                        { title: "", width: { wpx: 90 }, style: { alignment: { horizontal: "center" } } },
                        { title: "", width: { wpx: 90 } },
                        { title: "", width: { wpx: 120 } },
                        { title: "", width: { wpx: 140 } },
                        { title: "", width: { wpx: 140 } },
                        { title: "", width: { wpx: 90 } },
                        { title: "", width: { wpx: 90 } },
                        { title: "", width: { wpx: 60 } },
                        ,],
                    data: data
                },
            ] :
            multiDataSet = [
                {
                    columns: [
                        { title: header, width: { wpx: 40 }, },
                        { title: "", width: { wpx: 90 } },
                        { title: "", width: { wpx: 150 } },
                        { title: "", width: { wpx: 160 } },
                        { title: "", width: { wpx: 90 }, style: { alignment: { horizontal: "center" } } },
                        { title: "", width: { wpx: 90 } }
                    ],
                    data: data
                },
            ]



 
        this.setState({ multiDataSet })
        
    }

    buttonElement() {
        return (
            <a className="dropdown-item" href="#">EXCEL</a>
        );
    }

    render() {
        return (
            <ExcelFile
                element={this.buttonElement()}
                filename={"fixRequestExcel" + format(new Date(), 'DD/MM/YYYY', { locale: thLocale })}>
                <ExcelSheet
                    dataSet={this.state.multiDataSet}
                    name="fixRequestExcel"
                />
            </ExcelFile>
        )
    }
}

export default ExportfixRequestExcel