import i18next from "i18next";
import React from "react";

const StatusLawDebt = (props) => {
    // const getClassName = (statusChoices) => {
    //     return statusChoices === "doing" ? "bg-1567b4" : statusChoices === "finished" ? "bg-4eb4a9" : statusChoices === "void" ? "badge-danger" : ""

    // }

    const getStatusName = (statusChoices) => {
        return statusChoices === "doing" ? "Pending" : statusChoices === "finished" ? "Finished" : statusChoices === "Canceled" ? "Canceled" : statusChoices
    }

    return (
        <div className="useAll colorUse">
            <label className={`badge ${props?.float} ${props.className}`}>
                <span className="text_FFFFFF">{i18next.t("DailyCheckList:" + getStatusName(props.status))}</span>
            </label>
        </div>
    )
}

export default StatusLawDebt