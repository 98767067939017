import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithCommas from "../../../../libs/numberWithComma";
import Swal from "sweetalert2";
import './style/paySelect.scss'
import i18next from 'i18next';
import { Translation } from 'react-i18next';

const _ = require('lodash');

const query = graphql`
    query paySelectQuery($id: ID, $status: String){
        allPurchaseRecordGroup(contact_Id: $id, status_In: $status,purchaseRecordType:"purchase"){
            edges{
                node{
                    id
                    docNumber
                    issuedDate
                    dueDate
                    total
                    site{
                        id
                    }
                    purchaseRecord(status_In: $status){
                        edges{
                            node{
                                id
                                chartOfAccount{
                                    chartOfAccountCode
                                    name
                                }
                                productAndService{
                                    productCode
                                    name
                                }
                                whtRate
                                description
                                unitItems
                                price
                                total
                                paid
                                preTaxAmount
                                vat
                                vatAmount
                                whtRatePercent
                                whtRate
                                prepaidDepositTransactionUsed{
                                    edges {
                                        node {
                                            amount
                                            prepaidDepositTransaction{
                                                whtRatePercent
                                                whtAmount
                                            }
                                        }
                                    }
                                }
                                acceptCreditNoteTransaction(status: "paid"){
                                    edges {
                                        node {
                                            price
                                            total
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;


class PaySelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            purchase_group: false,
            all_total: 0,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.calTotal = this.calTotal.bind(this);
        this.rowSelect = this.rowSelect.bind(this);
        this.getAmount = this.getAmount.bind(this);

    }

    componentDidMount() {
        let _this = this
        fetchQuery(environment, query, { id: this.props.contactList, status: "active, partial_payment" })
            .then(response_data => {
                if (_.isObject(response_data.allPurchaseRecordGroup)) {
                    let data_set = _.cloneDeep(response_data);
                    data_set.allPurchaseRecordGroup.edges.forEach(function (data) {
                        _.set(data, 'node.select', "");
                        data.node.purchaseRecord.edges.forEach(function (purchase_record) {
                            _.set(purchase_record, 'node.discount_transaction', purchase_record.node.discount);
                            _.set(purchase_record, 'node.select', "");
                            _.set(purchase_record, 'node.amount_pay', "");

                            //Check wht
                            if (purchase_record.node.whtRatePercent) {
                                let wht_balance = 0;
                                let acnsums = 0;
                                let sumpd = 0;
                                purchase_record.node.acceptCreditNoteTransaction.edges.forEach((acnsum) => {
                                    acnsums = + acnsum.node.price
                                }
                                )
                                if (_.find(purchase_record.node.prepaidDepositTransactionUsed.edges, { node: { prepaidDepositTransaction: { whtRatePercent: null } } }) === undefined) {
                                    purchase_record.node.prepaidDepositTransactionUsed.edges.forEach((pdsum) => {
                                        if (pdsum.node.prepaidDepositTransaction.whtRatePercent) {
                                            sumpd = sumpd + Math.round((parseFloat(pdsum.node.amount) / (1 + parseFloat(purchase_record.node.vat || 0) / 100) + Number.EPSILON) * 100) / 100;
                                        }
                                    }
                                    )
                                }

                                const raw_wht = parseFloat(purchase_record.node.preTaxAmount - sumpd - acnsums) * parseFloat(purchase_record.node.whtRatePercent) / 100;
                                wht_balance = sumpd > 0 || acnsums > 0 ? Math.round(((raw_wht + Number.EPSILON)) * 100) / 100
                                    : parseFloat(purchase_record.node.whtRate);
                                const balance_vat_wht = Math.round((parseFloat((purchase_record.node.total - purchase_record.node.paid - wht_balance) + Number.EPSILON)) * 100) / 100;
                                _.set(purchase_record, 'node.balance_wht', wht_balance);
                                _.set(purchase_record, 'node.balance_vat_wht', balance_vat_wht);
                            }
                            else {
                                _.set(purchase_record, 'node.balance_wht', 0);
                                _.set(purchase_record, 'node.balance_vat_wht', parseFloat(purchase_record.node.total - purchase_record.node.paid));
                            }

                        });
                    });

                    this.setState({ purchase_group: data_set });
                }
            });
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        this.setState(prevState => {
            if (event.target.type === 'checkbox') {
                value = !_.get(prevState, event.target.name);
                let type_select = event.target.name.split(".")[1];

                let lastIndex = event.target.name.lastIndexOf(".select");
                let purchase_record_name = event.target.name.substring(0, lastIndex);
                let amount_pay_name = purchase_record_name + ".amount_pay";
                let amount_pay_value = "";

                if (value && type_select === "allPurchaseRecordGroup") {
                    amount_pay_value = _.get(prevState, purchase_record_name + ".balance_vat_wht");
                    _.set(prevState, amount_pay_name, parseFloat(amount_pay_value).toFixed(2));
                }

                if (event.target.dataset.inputType) {
                    _.set(prevState, event.target.name, value);
                    _.forOwn(_.get(prevState, event.target.dataset.inputType), function (purchase_record, key) {
                        _.set(purchase_record, 'node.select', value);
                        if (value) {
                            _.set(purchase_record, 'node.amount_pay', _.get(purchase_record, "node.balance_vat_wht"));

                        } else {
                            _.set(purchase_record, 'node.amount_pay', "");
                        }
                    });
                    return _.set(_.cloneDeep(prevState), prevState)
                }
            }
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        }, () => this.calTotal());

    }

    rowSelect(name) {
        let event = {
            target: {
                name: name,
                type: 'checkbox',
                dataset: {}
            }
        };
        this.handleInputChange(event)
    }

    calTotal() {
        let total = 0;
        this.state.purchase_group.allPurchaseRecordGroup.edges.forEach(function (data) {
            data.node.purchaseRecord.edges.forEach(function (purchase_record) {
                if (purchase_record.node.select && purchase_record.node.amount_pay) {
                    total += parseFloat(_.get(purchase_record, 'node.amount_pay') || 0);
                }
            });
        });
        this.setState({ all_total: total });
    }

    onSubmit(e) {
        e.preventDefault();
        let selected_list = [];
        this.state.purchase_group.allPurchaseRecordGroup.edges.forEach(function (data) {
            data.node.purchaseRecord.edges.forEach(function (purchase_record) {
                if (purchase_record.node.select && purchase_record.node.amount_pay) {
                    selected_list.push({ "transaction_id": purchase_record.node.id, "whtRate": purchase_record.node.balance_wht, "amount_pay": purchase_record.node.amount_pay })
                }
            });

        });

        if (selected_list.length > 0 && this.state.all_total >= 0) {
            let checkAmountPay = true
            selected_list.forEach((list) => {
                let check = parseFloat(list.amount_pay)
                if (check === "" || check === 0) {
                    checkAmountPay = false
                }
            })
            if (checkAmountPay) {
                this.props.updateSelectPurchaseList(selected_list);
                this.setState({ on_submit: true })
            } else {
                Swal.fire('Error!', 'กรุณาระบุจำนวนเงินให้ถูกต้อง', 'warning')
            }
        } else if (this.state.all_total < 0) {
            Swal.fire('Error!', 'เลือกรายการไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง', 'warning')
        }
        else {
            Swal.fire('Error!', 'กรุณาเลือกรายการและระบุจำนวนเงิน', 'warning')
        }
    }

    getAmount(purchase_record) {

        let acnsums = 0
        let sumpd = 0
        purchase_record.node.acceptCreditNoteTransaction.edges.forEach((acnsum) => {
            acnsums = + acnsum.node.total
        }
        )
        if (_.find(purchase_record.node.prepaidDepositTransactionUsed.edges, { node: { prepaidDepositTransaction: { whtRatePercent: null } } }) === undefined) {
            purchase_record.node.prepaidDepositTransactionUsed.edges.forEach((pdsum) => {
                if (pdsum.node.prepaidDepositTransaction.whtRatePercent) {
                    sumpd = sumpd + Math.round((parseFloat(pdsum.node.amount) / (1 + parseFloat(purchase_record.node.vat || 0) / 100) + Number.EPSILON) * 100) / 100;
                }
            }
            )
        }
        // let vat = (purchase_record.node.vat || 0);
        // let amount = (purchase_record.node.preTaxAmount * (1 + vat / 100) || 0) - (purchase_record.node.paid / (1 + (vat - (purchase_record.node.whtRatePercent || 0)) / 100)) * (1 + vat / 100);
        let amount = (purchase_record.node.preTaxAmount + purchase_record.node.vatAmount) - (acnsums - sumpd)
        return amount
    }

    getWht(purchase_record) {
        let amount = this.getAmount(purchase_record);
        let pre_tax = amount * 100 / (100 + (purchase_record.node.vat || 0));
        let wht = pre_tax * ((purchase_record.node.whtRatePercent || 0) / 100);
        return wht
    }

    render() {

        if (this.state.on_submit) {
            return <Redirect to="/accounting/expense/pay/form/create" />;
        }

        if (this.props.contactList.length > 0) {
            return (
                <Translation>
                    {t =>
                        <form onSubmit={this.onSubmit}>
                            <div className="container-fluid box">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/accounting/expense/pay/form/select_customer">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>
                                            {i18next.t("payCreateAccrued:Accrued lists")}
                                        </h3>

                                    </div>


                                </div>
                                <div className="content-inner" id="pay-select">

                                    {
                                        this.state.purchase_group &&
                                        this.state.purchase_group.allPurchaseRecordGroup.edges.map((purchase_group, purchase_index) => (
                                            <div className="table-responsive fade-up mt-3" key={purchase_group.node.id}>
                                                <div>{purchase_group.id}</div>
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={7}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        name={"purchase_group.allPurchaseRecordGroup.edges[" + purchase_index + "].node.select"}
                                                                        data-input-type={"purchase_group.allPurchaseRecordGroup.edges[" + purchase_index + "].node.purchaseRecord.edges"}
                                                                        onChange={this.handleInputChange}
                                                                        checked={this.state.purchase_group.allPurchaseRecordGroup.edges[purchase_index].node.select}
                                                                        id={"purchase_group-" + purchase_group.node.id}
                                                                        className="mr-2"
                                                                    />
                                                                    {purchase_group.node.docNumber}
                                                                    <span className="ml-3">
                                                                        {i18next.t("payCreateAccrued:Due date")} {format(purchase_group.node.dueDate, 'DD/MM/YYYY', { locale: thLocale })}
                                                                        {purchase_group.node.status === 'OVERDUE' &&
                                                                            <span className="text-fine"> {i18next.t("payCreateAccrued:(Overdue)")}</span>
                                                                        }
                                                                    </span>
                                                                </label>
                                                            </th>
                                                        </tr>
                                                        <tr className="bg-light">
                                                            <td width={50}>{i18next.t("payCreateAccrued:List")}</td>
                                                            <td width={250}>{i18next.t("payCreateAccrued:Description")}</td>
                                                            <td className="text-right">{i18next.t("payCreateAccrued:Amount")}</td>
                                                            <td className="text-right">{i18next.t("payCreateAccrued:Withholding")}</td>
                                                            {/*<td className="text-right">ชำระแล้ว</td>*/}
                                                            <td className="text-right">{i18next.t("payCreateAccrued:Overdue")}</td>
                                                            <td className="text-right">{i18next.t("payCreateAccrued:Amount to be paid")}</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {purchase_group.node.purchaseRecord.edges.map((purchase_record, purchase_record_index) => (
                                                            <tr key={purchase_record.node.id} onDoubleClick={() =>
                                                                this.rowSelect("purchase_group.allPurchaseRecordGroup.edges[" + purchase_index + "].node.purchaseRecord.edges[" + purchase_record_index + "].node.select")}>
                                                                <td>
                                                                    <input type="checkbox"
                                                                        name={"purchase_group.allPurchaseRecordGroup.edges[" +
                                                                            purchase_index + "].node.purchaseRecord.edges[" + purchase_record_index + "].node.select"}
                                                                        onChange={this.handleInputChange}
                                                                        checked={this.state.purchase_group.allPurchaseRecordGroup.edges[purchase_index].node.purchaseRecord.edges[purchase_record_index].node.select}
                                                                    />
                                                                </td>
                                                                <td>{purchase_record.node.description}</td>
                                                                {/*<td className="text-right">{numberWithCommas(purchase_record.node.total || 0)}</td>*/}
                                                                <td className="text-right">
                                                                    {numberWithCommas(this.getAmount(purchase_record))}
                                                                </td>
                                                                <td className="text-right">{numberWithCommas(purchase_record.node.balance_wht)}</td>
                                                                <td className="text-right text-paid">
                                                                    {numberWithCommas(purchase_record.node.balance_vat_wht)}
                                                                </td>
                                                                <td className="text-right" style={{ width: 150 }}>

                                                                    <input type="text" onChange={this.handleInputChange}
                                                                        className="form-control text-right"
                                                                        step="0.01"
                                                                        // max={purchase_record.node.total - purchase_record.node.paid}
                                                                        max={parseFloat(purchase_record.node.balance_vat_wht)}
                                                                        name={"purchase_group.allPurchaseRecordGroup.edges[" +
                                                                            purchase_index + "].node.purchaseRecord.edges[" + purchase_record_index + "].node.amount_pay"}
                                                                        value={this.state.purchase_group.allPurchaseRecordGroup.edges[purchase_index].node.purchaseRecord.edges[purchase_record_index].node.amount_pay}
                                                                        disabled={purchase_record.node.whtRatePercent ? true : false}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        ))
                                    }

                                    <div className="row mt-4">
                                        <div className="col-8">
                                            <div className="col-4">
                                                <div className="row p-3 mb-2 bg-light text-dark">
                                                    <div className="col text-left"> {i18next.t("payCreateAccrued:Total amount")}</div>
                                                    <div className="col text-right"> {numberWithCommas(this.state.all_total)} {i18next.t("payCreateAccrued:Baht")}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-right">
                                            <div className="btn-group mr-2">
                                                <Link to="/accounting/expense/pay/form/select_customer">
                                                    <button type="button" className="btn btn-secondary add">
                                                        {i18next.t("payCreateAccrued:Cancel")}
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="btn-group mr-2">
                                                <button type="submit" className="btn btn-primary add">
                                                    {i18next.t("payCreateAccrued:Next")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                </Translation>
            )
        }
        return <Redirect to="/accounting/expense/pay/list/all" />;
    }
}

export default PaySelect;
