
import { graphql } from "babel-plugin-relay/macro";

const querySumaryOwnershipRatio = graphql`
    query querySumaryOwnershipRatioQuery{
        allResidential{
            edges {
                node {
                    id name size ownershipRatio ownershipRatioShow
                }
            }
        }
    }
`

export default querySumaryOwnershipRatio;