/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type receivableOutstandingReportSummaryQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
  search?: ?string,
  customerType?: ?string,
  productAndService?: ?string,
  contact?: ?string,
  overdueDate?: ?string,
  rangeOverdueDate?: ?$ReadOnlyArray<?number>,
  dueDate: any,
|};
export type receivableOutstandingReportSummaryQueryResponse = {|
  +invoiceViewer: ?{|
    +summaryReceivableOutstanding: ?$ReadOnlyArray<?number>
  |},
  +reportOutstandingReceivableSummary: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +refNumber: string,
        +firstName: string,
        +lastName: string,
        +outstandingBalance: number,
      |}
    |}>
  |},
|};
export type receivableOutstandingReportSummaryQuery = {|
  variables: receivableOutstandingReportSummaryQueryVariables,
  response: receivableOutstandingReportSummaryQueryResponse,
|};
*/


/*
query receivableOutstandingReportSummaryQuery(
  $startDate: DateTime
  $endDate: DateTime
  $search: String
  $customerType: String
  $productAndService: String
  $contact: String
  $overdueDate: String
  $rangeOverdueDate: [Int]
  $dueDate: Date!
) {
  invoiceViewer {
    summaryReceivableOutstanding(approveOnly: true, receivableOutstanding: true, startDate: $startDate, endDate: $endDate, search: $search, customerType: $customerType, productAndService: $productAndService, statusIn: ["active", "overdue", "partial_payment", "paid"], contact: $contact, overdueDate: $overdueDate, rangeOverdueDate: $rangeOverdueDate)
    id
  }
  reportOutstandingReceivableSummary(dueDate: $dueDate, search: $search, rangeOverdueDate: $rangeOverdueDate, overdueDate: $overdueDate) {
    edges {
      node {
        id
        name
        refNumber
        firstName
        lastName
        outstandingBalance
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dueDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "overdueDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productAndService"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rangeOverdueDate"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v9 = {
  "kind": "Variable",
  "name": "overdueDate",
  "variableName": "overdueDate"
},
v10 = {
  "kind": "Variable",
  "name": "rangeOverdueDate",
  "variableName": "rangeOverdueDate"
},
v11 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v12 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "approveOnly",
      "value": true
    },
    {
      "kind": "Variable",
      "name": "contact",
      "variableName": "contact"
    },
    {
      "kind": "Variable",
      "name": "customerType",
      "variableName": "customerType"
    },
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "endDate"
    },
    (v9/*: any*/),
    {
      "kind": "Variable",
      "name": "productAndService",
      "variableName": "productAndService"
    },
    (v10/*: any*/),
    {
      "kind": "Literal",
      "name": "receivableOutstanding",
      "value": true
    },
    (v11/*: any*/),
    {
      "kind": "Variable",
      "name": "startDate",
      "variableName": "startDate"
    },
    {
      "kind": "Literal",
      "name": "statusIn",
      "value": [
        "active",
        "overdue",
        "partial_payment",
        "paid"
      ]
    }
  ],
  "kind": "ScalarField",
  "name": "summaryReceivableOutstanding",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "dueDate",
      "variableName": "dueDate"
    },
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "concreteType": "OutstandingReceivableSummaryConnection",
  "kind": "LinkedField",
  "name": "reportOutstandingReceivableSummary",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OutstandingReceivableSummaryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OutstandingReceivableSummary",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "outstandingBalance",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "receivableOutstandingReportSummaryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      (v14/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v8/*: any*/),
      (v3/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "receivableOutstandingReportSummaryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      },
      (v14/*: any*/)
    ]
  },
  "params": {
    "cacheID": "c91ca909b00bdcaf325b002df6d7997f",
    "id": null,
    "metadata": {},
    "name": "receivableOutstandingReportSummaryQuery",
    "operationKind": "query",
    "text": "query receivableOutstandingReportSummaryQuery(\n  $startDate: DateTime\n  $endDate: DateTime\n  $search: String\n  $customerType: String\n  $productAndService: String\n  $contact: String\n  $overdueDate: String\n  $rangeOverdueDate: [Int]\n  $dueDate: Date!\n) {\n  invoiceViewer {\n    summaryReceivableOutstanding(approveOnly: true, receivableOutstanding: true, startDate: $startDate, endDate: $endDate, search: $search, customerType: $customerType, productAndService: $productAndService, statusIn: [\"active\", \"overdue\", \"partial_payment\", \"paid\"], contact: $contact, overdueDate: $overdueDate, rangeOverdueDate: $rangeOverdueDate)\n    id\n  }\n  reportOutstandingReceivableSummary(dueDate: $dueDate, search: $search, rangeOverdueDate: $rangeOverdueDate, overdueDate: $overdueDate) {\n    edges {\n      node {\n        id\n        name\n        refNumber\n        firstName\n        lastName\n        outstandingBalance\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7a50f4b8d5981f5ad194b295c0e539c5';

module.exports = node;
