import React, { Component } from 'react'
import { format } from "date-fns";
import _ from "lodash"
import numberWithComma from "../../../../libs/numberWithCommaReport";
import { Dropdown } from "react-bootstrap";

require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

export default class budgetDraftYearCompareAverageYearExport extends Component {

  summaryRevenue = (codeValue) => {
    let result = 0;
    let sumRevenue_year_summaryYear_total_budget = 0;
    let sumRevenue_year_total_budget = 0;
    let sumRevenue_mount_summaryYear_total_budget = 0;
    let sumRevenue_mount_total_budget = 0;

    sumRevenue_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
    sumRevenue_year_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.total_budget || 0))
    sumRevenue_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
    sumRevenue_mount_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.total_budget / 12))

    switch (codeValue) {
      case "4000-00 year_summaryYear_total_budget":
        result = sumRevenue_year_summaryYear_total_budget
        break;
      case "4000-00 year_total_budget":
        result = sumRevenue_year_total_budget
        break;
      case "4000-00 mount_summaryYear_total_budget":
        result = sumRevenue_mount_summaryYear_total_budget
        break;
      case "4000-00 mount_total_budget":
        result = sumRevenue_mount_total_budget
        break;

      default:
        break;
    }
    return result;

  }

  summaryExpense = (codeValue) => {
    let result = 0;
    let sumExpense_year_otherExpenses_summaryYear_total_budget = 0;
    let sumExpense_year_otherExpenses_total_budget = 0;
    let sumExpense_mount_otherExpenses_summaryYear_total_budget = 0;
    let sumExpense_mount_otherExpenses_total_budget = 0;

    let sumExpense_year_summaryYear_total_budget = 0;
    let sumExpense_year_total_budget = 0;
    let sumExpense_mount_summaryYear_total_budget = 0;
    let sumExpense_mount_total_budget = 0;

    if (codeValue.substring(0, 7) === "5800-00") {
      _.forIn(this.props.state.lists[1].children[8].children, function (item) {
        sumExpense_year_otherExpenses_summaryYear_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_budget || 0))
        sumExpense_year_otherExpenses_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.total_budget || 0))
        sumExpense_mount_otherExpenses_summaryYear_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_budget / 12))
        sumExpense_mount_otherExpenses_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.total_budget / 12))
      })
    } else {
      if (codeValue.substring(0, 7) === "5100-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5200-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5300-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5310-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5400-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5500-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5600-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.total_budget / 12) )
      }
      if (codeValue.substring(0, 7) === "5700-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.total_budget / 12))
      }
    }

    switch (codeValue) {
      case "5800-00 year_summaryYear_total_budget":
        result = sumExpense_year_otherExpenses_summaryYear_total_budget
        break;
      case "5800-00 mount_summaryYear_total_budget":
        result = sumExpense_mount_otherExpenses_summaryYear_total_budget
        break;
      case "5800-00 year_total_budget":
        result = sumExpense_year_otherExpenses_total_budget
        break;
      case "5800-00 mount_total_budget":
        result = sumExpense_mount_otherExpenses_total_budget
        break;
      case `${codeValue.substring(0, 7)} year_summaryYear_total_budget`:
        result = sumExpense_year_summaryYear_total_budget
        break;
      case `${codeValue.substring(0, 7)} mount_summaryYear_total_budget`:
        result = sumExpense_mount_summaryYear_total_budget
        break;
      case `${codeValue.substring(0, 7)} year_total_budget`:
        result = sumExpense_year_total_budget
        break;
      case `${codeValue.substring(0, 7)} mount_total_budget`:
        result = sumExpense_mount_total_budget
        break;

      default:
        break;
    }
    return result;

  }

  downloadExcel = () => {
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('LastYearCampareAverageYear');

    // setWidthcolumns
    let columns = [
      { width: 15 },
      { width: 50 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ]

    // setBorder
    let borders = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }

    let fontSizes = { size: 12 }
    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let fontBold = { size: 12, bold: true };

    worksheet.getCell('A1').value = this.props.checkTextSelect
    worksheet.getCell('A1').font = {
      size: 20,
      bold: true
    };

    worksheet.getCell('A2').value = `ปีตั้งงบประมาณ ${format(this.props.state.temp_year, "YYYY")}`
    worksheet.getCell('A2').font = fontBold;

    worksheet.getCell('A3').value = `ระหว่างวันที่ ${format(this.props.state.temp_start_date, "DD/MM/YYYY")} ถึง ${format(this.props.state.temp_end_date, "DD/MM/YYYY")}`
    worksheet.getCell('A3').font = fontBold;

    let table = []
    table.push(['ลำดับ', 'รายการ', `งบประมาณปี ${this.props.state.temp_year.getFullYear() - 1}`, '', `งบประมาณปี ${format(this.props.state.temp_start_date, "YYYY")}`, ''])
    table.push(['', '', 'รายเดือน', 'รายปี', 'รายเดือน', 'รายปี'])

    _.map(table, (value, index) => {
      worksheet.getRow(5 + index).values = value
    })

    _.map(table, (value, index) => {
      _.map(value, (value2, indexValue) => {

        let cell = worksheet.getRow(5 + index).getCell(1 + indexValue);

        cell.font = fontBold;
        cell.border = borders;
        cell.alignment = textCenter;

        switch (value2) {
          case "ลำดับ":
            worksheet.mergeCells("A5", "A6");
            break;
          case "รายการ":
            worksheet.mergeCells("B5", "B6");
            break;
          case `งบประมาณปี ${this.props.state.temp_year.getFullYear() - 1}`:
            worksheet.mergeCells("C5", "D5");
            break;
          case `งบประมาณปี ${format(this.props.state.temp_start_date, "YYYY")}`:
            worksheet.mergeCells("E5", "F5");
            break;

          default:
            break;
        }
      })
    })

    let last_index = 0;
    let last_index_child = 0;
    let sumExpense_year_summaryYear_total_budget = 0;
    let sumExpense_mount_summaryYear_total_budget = 0;
    let sumExpense_year_total_budget = 0;
    let sumExpense_mount_total_budget = 0;

    // table
    let data1 = []
    {_.map(this.props.state.lists[0]?.children, (item, index) => {
      ++last_index;

      if(index == 0){
        data1.push(['', 'รายรับ', '', '', '', ''])
        data1.push([
          `${last_index}`, 
          `${item.data.name}`, 
          `${item.data?.summaryYear?.total_budget !== "" ? numberWithComma(parseFloat(item.data?.summaryYear?.total_budget / 12).toFixed(2)) : ""}`, 
          `${item.data?.summaryYear?.total_budget ? numberWithComma(item.data?.summaryYear?.total_budget) : ""}`, 
          `${item.data?.total_budget !== "" ? numberWithComma(parseFloat(item.data?.total_budget / 12).toFixed(2)) : ""}`,
          `${item.data?.total_budget ? numberWithComma(item.data?.total_budget) : ""}`
        ])

      }else if(index === (this.props.state.lists[0].children.length - 1)){
        data1.push([
          `${last_index}`, 
          `${item.data.name}`, 
          `${item.data?.summaryYear?.total_budget !== "" ? numberWithComma(parseFloat(item.data?.summaryYear?.total_budget / 12).toFixed(2)) : ""}`, 
          `${item.data?.summaryYear?.total_budget ? numberWithComma(item.data?.summaryYear?.total_budget) : ""}`, 
          `${item.data?.total_budget !== "" ? numberWithComma(parseFloat(item.data?.total_budget / 12).toFixed(2)) : ""}`,
          `${item.data?.total_budget ? numberWithComma(item.data?.total_budget) : ""}`
        ])
        data1.push([
          "", 
          "รวมรายรับ", 
          `${this.summaryRevenue("4000-00 mount_summaryYear_total_budget") !== 0 ? numberWithComma(this.summaryRevenue("4000-00 mount_summaryYear_total_budget").toFixed(2)) : "-"}`, 
          `${this.summaryRevenue("4000-00 year_summaryYear_total_budget") !== 0 ? numberWithComma(this.summaryRevenue("4000-00 year_summaryYear_total_budget").toFixed(2)) : "-"}`, 
          `${this.summaryRevenue("4000-00 mount_total_budget") !== 0 ? numberWithComma(this.summaryRevenue("4000-00 mount_total_budget").toFixed(2)) : "-"}`,
          `${this.summaryRevenue("4000-00 year_total_budget") !== 0 ? numberWithComma(this.summaryRevenue("4000-00 year_total_budget").toFixed(2)) : "-"}`
        ])
      }else {
        data1.push([
          `${last_index}`, 
          `${item.data.name}`, 
          `${item.data?.summaryYear?.total_budget !== "" ? numberWithComma(parseFloat(item.data?.summaryYear?.total_budget / 12).toFixed(2)) : ""}`, 
          `${item.data?.summaryYear?.total_budget ? numberWithComma(item.data?.summaryYear?.total_budget) : ""}`, 
          `${item.data?.total_budget !== "" ? numberWithComma(parseFloat(item.data?.total_budget / 12).toFixed(2)) : ""}`,
          `${item.data?.total_budget ? numberWithComma(item.data?.total_budget) : ""}`
        ])
      }
    })}

    {_.map(this.props.state.lists[1]?.children, (item, index_code) => {

      sumExpense_year_summaryYear_total_budget += this.summaryExpense(`${item.data.code} year_summaryYear_total_budget`)
      sumExpense_mount_summaryYear_total_budget += this.summaryExpense(`${item.data.code} mount_summaryYear_total_budget`)
      sumExpense_year_total_budget += this.summaryExpense(`${item.data.code} year_total_budget`)
      sumExpense_mount_total_budget += this.summaryExpense(`${item.data.code} mount_total_budget`)

      if(index_code === 0){
        data1.push(['', 'รายจ่าย', '', '', '', ''])
      }

      data1.push(['', `${item.data.name}`, '', '', '', ''])

      _.map(item.children, (item_child, index) => {
        if (index_code !== 8) { ++last_index }

        if(item_child.children?.length > 0){
          data1.push(['', `${item_child.data.name}`, '', '', '', ''])

          _.map(item_child?.children, (item_child_child, index_child_child) => {
            ++last_index_child

            data1.push([
              `${last_index + last_index_child}`, 
              `${item_child_child.data.name}`, 
              `${item_child_child.data?.summaryYear?.total_budget !== "" ? numberWithComma(parseFloat(item_child_child.data?.summaryYear?.total_budget / 12).toFixed(2)) : ""}`, 
              `${item_child_child.data?.summaryYear?.total_budget ? numberWithComma(item_child_child.data?.summaryYear?.total_budget) : ""}`, 
              `${item_child_child.data?.total_budget !== "" ? numberWithComma(parseFloat(item_child_child.data?.total_budget / 12).toFixed(2)) : ""}`, 
              `${item_child_child.data?.total_budget ? numberWithComma(item_child_child.data?.total_budget) : ""}`
            ])
          })
        }else{
          data1.push([
            `${last_index}`, 
            `${item_child.data.name}`, 
            `${item_child.data?.summaryYear?.total_budget !== "" ? numberWithComma(parseFloat(item_child.data?.summaryYear?.total_budget / 12).toFixed(2)) : ""}`, 
            `${item_child.data?.summaryYear?.total_budget ? numberWithComma(item_child.data?.summaryYear?.total_budget) : ""}`, 
            `${item_child.data?.total_budget !== "" ? numberWithComma(parseFloat(item_child.data?.total_budget / 12).toFixed(2)) : ""}`, 
            `${item_child.data?.total_budget ? numberWithComma(item_child.data?.total_budget) : ""}`
          ])
        }
      })

      data1.push([
        "", 
        `รวม${item.data.name}`, 
        `${this.summaryExpense(`${item.data.code} mount_summaryYear_total_budget`) !== 0 ? numberWithComma(this.summaryExpense(`${item.data.code} mount_summaryYear_total_budget`).toFixed(2)) : "-"}`, 
        `${this.summaryExpense(`${item.data.code} year_summaryYear_total_budget`) !== 0 ? numberWithComma(this.summaryExpense(`${item.data.code} year_summaryYear_total_budget`).toFixed(2)) : "-"}`, 
        `${this.summaryExpense(`${item.data.code} mount_total_budget`) !== 0 ? numberWithComma(this.summaryExpense(`${item.data.code} mount_total_budget`).toFixed(2)) : "-"}`, 
        `${this.summaryExpense(`${item.data.code} year_total_budget`) !== 0 ? numberWithComma(this.summaryExpense(`${item.data.code} year_total_budget`).toFixed(2)) : "-"}`
      ])
    })}

    data1.push([
      "", 
      `รวมรายจ่าย`, 
      `${sumExpense_mount_summaryYear_total_budget !== 0 ? numberWithComma(sumExpense_mount_summaryYear_total_budget.toFixed(2)) : "-"}`, 
      `${sumExpense_year_summaryYear_total_budget !== 0 ? numberWithComma(sumExpense_year_summaryYear_total_budget.toFixed(2)) : "-"}`, 
      `${sumExpense_mount_total_budget !== 0 ? numberWithComma(sumExpense_mount_total_budget.toFixed(2)) : "-"}`, 
      `${sumExpense_year_total_budget !== 0 ? numberWithComma(sumExpense_year_total_budget.toFixed(2)) : "-"}`
    ])

    data1.push([
      "", 
      `รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด`, 
      `${(this.summaryRevenue("4000-00 mount_summaryYear_total_budget") - sumExpense_mount_summaryYear_total_budget) !== 0
          ? numberWithComma((this.summaryRevenue("4000-00 mount_summaryYear_total_budget") - sumExpense_mount_summaryYear_total_budget).toFixed(2)) 
          : "-"}`, 
      `${(this.summaryRevenue("4000-00 year_summaryYear_total_budget") - sumExpense_year_summaryYear_total_budget) !== 0 
          ? numberWithComma((this.summaryRevenue("4000-00 year_summaryYear_total_budget") - sumExpense_year_summaryYear_total_budget).toFixed(2)) 
          : "-"}`, 
      `${(this.summaryRevenue("4000-00 mount_total_budget") - sumExpense_mount_total_budget) !== 0
          ? numberWithComma((this.summaryRevenue("4000-00 mount_total_budget") - sumExpense_mount_total_budget).toFixed(2)) 
          : "-"}`, 
      `${(this.summaryRevenue("4000-00 year_total_budget") - sumExpense_year_total_budget) !== 0 
          ? numberWithComma((this.summaryRevenue("4000-00 year_total_budget") - sumExpense_year_total_budget).toFixed(2)) 
          : "-"}`
    ])

    _.map(data1, (value, index) => {
      worksheet.getRow(7 + index).values = value
    })

    _.map(data1, (value, index) => {

      _.map(value, (value2, indexValue) => {
        let cell = worksheet.getRow(7 + index).getCell(1 + indexValue);
        let textRight = { vertical: 'middle', horizontal: 'right' }

        cell.font = fontSizes;
        cell.border = borders;

        if(indexValue === 0){
          cell.alignment = textCenter;
        }else if(indexValue !== 0 && indexValue !== 1){
          cell.alignment = textRight;
        }

      })

    })

    worksheet.columns = columns;

    workbook.xlsx.writeBuffer()
    .then((data) => {
      const blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      let nameFile = `ExcelLastYearCompareAverageYear_${format(this.props.state.temp_start_date, "DD/MM/YYYY")}-${format(this.props.state.temp_end_date, "DD/MM/YYYY")}`
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = nameFile;
      a.click();
    });
  }

  render() {
    return (
      <Dropdown.Item onClick={this.downloadExcel}>
        Excel
      </Dropdown.Item>
    )
  }
}
