import "../styles/createBudget.scss";
import React from "react";
import _ from "lodash";
import Loading from "../../../libs/loading";
import { H6, P2, Td,Th } from '../../../components/Text/Text'
import numberWithCommas from "../../../libs/numberWithComma";
import i18next from "i18next";
import GetdateOnEngAndTh from "../../../components/Action/getdateOnEngAndTh";

const MonthlyBudgetUsageTable = ({ state, last_index, report = false, toggleHandleScroll }) => {
  return (
    !state.disabled_table ? <React.Fragment >
      <div className=" fade-up mt-4">
        <div className="table-responsive2" style={{ overflowX: "auto", width: "100%", display: "block" }} onScroll={() => toggleHandleScroll("table-responsive2")}>
          <div className="table" style={{ width: `${state.widthTable1}px`, height: "20px", margin: 0 }}></div>
        </div>
        <div className="table-responsive" onScroll={() => toggleHandleScroll("table-responsive")}>
          <table className="table table-hover">
            <thead>
              <tr>
                <Th rowSpan={2} className="text-center" report={report} >{i18next.t("monthlyBudgetUsageTable:Sequence")}</Th>
                <Th rowSpan={2} className="text-center" report={report} >{i18next.t("monthlyBudgetUsageTable:Items")}</Th>
                {state.list_month.map((item, index) => {
                  return (
                    <Th colSpan={2} key={index} className="text-center" report={report} >
                      <GetdateOnEngAndTh 
                        date={item}
                        withMonthShort={true}
                        withYearShort={true}
                      />
                    </Th>
                  );
                })}
                <Th rowSpan={2} className="text-center" report={report} >{i18next.t("monthlyBudgetUsageTable:Total Budget")}</Th>
                <Th rowSpan={2} className="text-center" report={report} >{i18next.t("monthlyBudgetUsageTable:Total Actual")}</Th>
                <Th colSpan={2} className="text-center" report={report} >{i18next.t("monthlyBudgetUsageTable:Compare To Actual")}</Th>
              </tr>
              <tr className="border-top">
                {state.list_month.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Th className="text-center" report={report} >{i18next.t("monthlyBudgetUsageTable:Budget")}</Th>
                      <Th className="text-center" report={report} >{i18next.t("monthlyBudgetUsageTable:Actual")}</Th>
                    </React.Fragment>
                  );
                })}
                <Th className="text-center" report={report} >{i18next.t("monthlyBudgetUsageTable:Difference")}</Th>
                <Th className="text-center" report={report} >%</Th>
              </tr>
            </thead>
            <tbody>
              {_.map(state.lists[4], (item, index) => {
                ++last_index;
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <Td className="text-center">{last_index}</Td>
                      <Td style={{ paddingLeft: 50, minWidth: 300 }}>
                        {index == 0 && <H6 style={{ marginLeft: -32 }}>รายรับ</H6>}
                        {item.name}
                      </Td>
                      {item.month_budget.map((month, index_month) => {
                        return (
                          <React.Fragment key={index_month}>
                            <Td style={{ minWidth: 85 }} height={24} className="text-center"> {numberWithCommas(month.budget)} </Td>
                            <Td style={{ minWidth: 85 }} height={24} className="text-center td-red"> {numberWithCommas(item.month_budget_happend[index_month].totalMonthHappend)} </Td>
                          </React.Fragment>
                        );
                      })}

                      <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.total_past_budget)}</Td>
                      <Td style={{ minWidth: 130 }} className="text-center td-red">{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.total_happend)}</Td>
                      <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.diff_past_happend)}</Td>
                      <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.percent_diff_past_happend)}</Td>
                    </tr>
                    {index === state.lists[4].length - 1 && (
                      <tr className="td-sum ">
                        <Td />
                        <Td style={{ paddingLeft: 50, minWidth: 300 }}>รวมรายรับ</Td>
                        {_.map(state.sum_income.total_month_budget, (item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Td width={180} height={24} className="text-center">{numberWithCommas(item)}</Td>
                              <Td width={180} height={24} className="text-center">{numberWithCommas(state.sum_income.total_month_budget_happend[index])}</Td>
                            </React.Fragment>
                          );
                        })}
                        <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(state.sum_income.total_past_budget)}</Td>
                        <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(state.sum_income.total_happend)}</Td>
                        <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(state.sum_income.total_diff_past_happend)}</Td>
                        <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(state.sum_income.total_percent_diff_past_happend)}</Td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
              {_.map(state.lists[5], (item, index_code) => {
                return (
                  <React.Fragment key={index_code}>
                    {item.child.length > 0 && (
                      <React.Fragment>
                        <tr>
                          <Td />
                          <Td className="ml-2">
                            {index_code == 0 && <H6>รายจ่าย</H6>}
                            <P2>{item.name}</P2>
                          </Td >
                          {state.list_month.map((month, index_month) => {
                            return (
                              <React.Fragment key={index_month}>
                                <Td />
                                <Td className="td-red" />
                              </React.Fragment>
                            );
                          })}
                          <Td />
                          <Td className="td-red" />
                          <Td />
                          <Td />
                        </tr>
                        {_.map(item.child, (item_child, index) => {
                          ++last_index;
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <Td className="text-center">{last_index}</Td>
                                <Td style={{ paddingLeft: 50, minWidth: 300, }}>{item_child.name}</Td>
                                {item_child.month_budget.map((month, index_month) => {
                                  return (
                                    <React.Fragment key={index_month}>
                                      <Td height={24} className="text-center">{numberWithCommas(month.budget)}</Td>
                                      <Td height={24} className="text-center td-red">{numberWithCommas(item_child.month_budget_happend[index_month].totalMonthHappend)}</Td>
                                    </React.Fragment>
                                  );
                                })}

                                <Td className="text-center">{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.total_past_budget)}</Td>
                                <Td className="text-center td-red">{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.total_happend)}</Td>
                                <Td className="text-center">{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.diff_past_happend)}</Td>
                                <Td className="text-center">{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.percent_diff_past_happend)}</Td>
                              </tr>
                            </React.Fragment>
                          );
                        })}

                        <tr className="td-sum ">
                          <Td />
                          <Td style={{ paddingLeft: 50, minWidth: 300, }}>รวม{item.name}</Td>
                          {_.map(state.sum_expenses[item.key].total_month_budget, (month, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Td height={24} className="text-center">{numberWithCommas(month)}</Td>
                                <Td height={24} className="text-center">{numberWithCommas(state.sum_expenses[item.key]?.total_month_budget_happend[index])}</Td>
                              </React.Fragment>
                            );
                          })}
                          <Td className="text-center">{numberWithCommas(state.sum_expenses[item.key].total_past_budget)}</Td>
                          <Td className="text-center">{numberWithCommas(state.sum_expenses[item.key].total_happend)}</Td>
                          <Td className="text-center">{numberWithCommas(state.sum_expenses[item.key].total_diff_past_happend)}</Td>
                          <Td className="text-center">{numberWithCommas(state.sum_expenses[item.key].total_percent_diff_past_happend)}</Td>
                        </tr>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
              <tr className="td-sum2 ">
                <Td />
                <Td style={{ paddingLeft: 50, minWidth: 300, }}>รวมรายจ่าย </Td>
                {state.total_sum_expenses.total_month_budget.map((month_budget, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Td height={24} className="text-center">{numberWithCommas(month_budget)}</Td>
                      <Td height={24} className="text-center">{numberWithCommas(state.total_sum_expenses.total_month_budget_happend[index])}</Td>
                    </React.Fragment>
                  );
                })}
                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.total_past_budget)}</Td>
                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.total_happend)}</Td>
                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.total_diff_past_happend)}</Td>
                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.total_percent_diff_past_happend)}</Td>
              </tr>
              <tr className="td-sum3 ">
                <Td />
                <Td style={{ paddingLeft: 50, minWidth: 300, }}> รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด</Td>
                {state.total_sum_expenses.total_month_budget.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Td height={24} className="text-center">{numberWithCommas(state.sum_income.total_month_budget[index] - item)}</Td>
                      <Td height={24} className="text-center">{numberWithCommas(state.sum_income.total_month_budget_happend[index] - state.total_sum_expenses.total_month_budget_happend[index])}</Td>
                    </React.Fragment>
                  );
                })}
                <Td className="text-center">{numberWithCommas(state.sum_income.total_past_budget - state.total_sum_expenses.total_past_budget)}</Td>
                <Td className="text-center">{numberWithCommas(state.sum_income.total_happend - state.total_sum_expenses.total_happend)}</Td>
                <Td className="text-center">{numberWithCommas(state.sum_income.total_diff_past_happend - state.total_sum_expenses.total_diff_past_happend)}</Td>
                <Td className="text-center">{numberWithCommas(state.sum_income.total_percent_diff_past_happend - state.total_sum_expenses.total_percent_diff_past_happend)}</Td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment > : <Loading />
  );
};

export default MonthlyBudgetUsageTable;