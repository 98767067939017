/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type ContactTypeOfSupplier = "ASSOCIATION" | "COMPANY" | "FOUNDATION" | "GROUP_OF_PERSON" | "JOINT_VENTURE" | "MERCHANT" | "ORDINARY_PARTNERSHIP" | "OTHER" | "PARTNERSHIP" | "PERSON" | "PUBLIC_COMPANY" | "%future added value";
export type receiptDepositContactCreateFormQueryVariables = {|
  id: string,
  chartOfAccountCode?: ?string,
|};
export type receiptDepositContactCreateFormQueryResponse = {|
  +contact: ?{|
    +id: string,
    +refNumber: string,
    +name: string,
    +firstName: string,
    +lastName: string,
    +registeredAddress: string,
    +registeredCountry: string,
    +registeredProvince: string,
    +registeredDistrict: string,
    +registeredCity: string,
    +registeredPostalCode: string,
    +typeOfContact: ContactTypeOfContact,
    +typeOfSupplier: ContactTypeOfSupplier,
    +ownerName: ?string,
    +mailingAddress: string,
    +mailingDistrict: string,
    +mailingCity: string,
    +mailingProvince: string,
    +mailingPostalCode: string,
    +renterName: ?string,
    +renterAddress: ?string,
    +renterDistrict: string,
    +renterCity: string,
    +renterProvince: string,
    +renterPostalCode: string,
    +agentName: ?string,
    +agentAddress: ?string,
    +agentDistrict: string,
    +agentCity: string,
    +agentProvince: string,
    +agentPostalCode: string,
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |}
      |}>
    |}
  |},
|};
export type receiptDepositContactCreateFormQuery = {|
  variables: receiptDepositContactCreateFormQueryVariables,
  response: receiptDepositContactCreateFormQueryResponse,
|};
*/


/*
query receiptDepositContactCreateFormQuery(
  $id: ID!
  $chartOfAccountCode: String
) {
  contact(id: $id) {
    id
    refNumber
    name
    firstName
    lastName
    registeredAddress
    registeredCountry
    registeredProvince
    registeredDistrict
    registeredCity
    registeredPostalCode
    typeOfContact
    typeOfSupplier
    ownerName
    mailingAddress
    mailingDistrict
    mailingCity
    mailingProvince
    mailingPostalCode
    renterName
    renterAddress
    renterDistrict
    renterCity
    renterProvince
    renterPostalCode
    agentName
    agentAddress
    agentDistrict
    agentCity
    agentProvince
    agentPostalCode
  }
  viewer {
    allChartOfAccount(chartOfAccountCode_Istartswith: $chartOfAccountCode) {
      edges {
        node {
          id
          name
          chartOfAccountCode
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chartOfAccountCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredProvince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredDistrict",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredPostalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfContact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfSupplier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailingAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailingDistrict",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailingCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailingProvince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailingPostalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "renterName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "renterAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "renterDistrict",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "renterCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "renterProvince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "renterPostalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentDistrict",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentProvince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentPostalCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "chartOfAccountCode_Istartswith",
      "variableName": "chartOfAccountCode"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chartOfAccountCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "receiptDepositContactCreateFormQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "receiptDepositContactCreateFormQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "53c677cc8c6bc98d42253d465fa40dd3",
    "id": null,
    "metadata": {},
    "name": "receiptDepositContactCreateFormQuery",
    "operationKind": "query",
    "text": "query receiptDepositContactCreateFormQuery(\n  $id: ID!\n  $chartOfAccountCode: String\n) {\n  contact(id: $id) {\n    id\n    refNumber\n    name\n    firstName\n    lastName\n    registeredAddress\n    registeredCountry\n    registeredProvince\n    registeredDistrict\n    registeredCity\n    registeredPostalCode\n    typeOfContact\n    typeOfSupplier\n    ownerName\n    mailingAddress\n    mailingDistrict\n    mailingCity\n    mailingProvince\n    mailingPostalCode\n    renterName\n    renterAddress\n    renterDistrict\n    renterCity\n    renterProvince\n    renterPostalCode\n    agentName\n    agentAddress\n    agentDistrict\n    agentCity\n    agentProvince\n    agentPostalCode\n  }\n  viewer {\n    allChartOfAccount(chartOfAccountCode_Istartswith: $chartOfAccountCode) {\n      edges {\n        node {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '457b7984b261bd1b78ec992f9ec9fbb3';

module.exports = node;
