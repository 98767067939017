import React, { Component } from "react";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { Translation } from "react-i18next";
// import "../css/invoiceDetail.scss";

import { Link, Redirect } from "react-router-dom";

import { format, differenceInCalendarDays } from "date-fns";
import thLocale from "date-fns/locale/th";
import Swal from "sweetalert2";

import PurchaseDetailTable from "./purchaseDetailTable";
import PurchaseSummary from "./core/PurchaseSummary";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../../env/environment";
import numberWithComma from "../../../libs/numberWithComma";
import _ from "lodash";
import BackButtonIcon from '../../../components/BackBtn/indexBack';
import LightBox from "../../../libs/lightBox";
import i18next from "i18next";

const mutation = graphql`
  mutation purchaseDetailMutation(
    $input: UpdatePurchaseRecordGroupStatusInput!
  ) {
    updatePurchaseRecordGroupStatus(input: $input) {
      ok
      warningText
    }
  }
`;

class PurchaseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issuedDate: "",
      dueDate: "",
      dateCount: "",
      status: "",
      redirect: false,
      transaction_list: [],
      prepaidDeposit: 0,
      loading: false,
      vat: 0,
      taxType: "",
      prepaid_deposit_transaction: [],
    };
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.setPrepaidDepositUse = this.setPrepaidDepositUse.bind(this);
  }

  componentWillMount() {
    let list = [...this.state.transaction_list];
    let transactions = [...this.props.transactions];
    let prepaid_deposit_transaction = [];
    let vat = 0;
    if (transactions[0].node) {
      vat = transactions[0].node.vat || 0;
    }
    transactions.forEach((transaction) => {
      list = [...list, { ...transaction.node }];
      prepaid_deposit_transaction = [...prepaid_deposit_transaction, ...transaction.node.prepaidDepositTransactionUsed.edges,];
    });
    this.setState(
      {
        transaction_list: list,
        prepaid_deposit_transaction: prepaid_deposit_transaction,
        vat: vat,
      },
      () => {
        this.setPrepaidDepositUse();
      }
    );
  }

  setPrepaidDepositUse() {
    let amountUse = 0;
    this.state.prepaid_deposit_transaction.forEach((prepaid) => {
      // const vatAmountPd = parseFloat(prepaid.node.amount * ((prepaid.node.prepaidDepositTransaction.vat || 0) / 100));
      // amountUse = amountUse + (prepaid.node.amount + vatAmountPd );
      amountUse = amountUse + prepaid.node.amount;
    });
    this.setState({ prepaidDeposit: amountUse });
  }

  getVat(price, vat) {
    let amount = 0;
    if (this.props.purchaseQuery.purchaseRecordGroup.taxType === "INCLUDE_TAX") {
      amount = parseFloat(price) * ((vat || 0) / 100);
    }
    return parseFloat(amount.toFixed(2));
  }

  onChangeStatus() {
    Swal.fire({
      title: `${i18next.t("Allaction:Do you want to void for this document")}?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: i18next.t("Allaction:cancel"),
      confirmButtonText: i18next.t("Allaction:Yes"),
    }).then(async (result) => {
      if (result.value) {
        let { value: void_remark } = await Swal.fire({
          title: i18next.t("Allaction:Note"),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: i18next.t("Allaction:cancel"),
          confirmButtonText: i18next.t("Allaction:Confirm"),
          inputValidator: (value) => {
            if (!value) {
              return i18next.t("Allaction:Please enter a cancellation note.");
            }
          },
        });

        if (void_remark) {
          this.setState({ loading: true });
          let variables = {
            input: {
              purchaseId: this.props.purchaseQuery.purchaseRecordGroup.id,
              status: "void",
              voidRemark: void_remark,
            },
          };
          commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
              this.setState({ loading: false });
              if (response.updatePurchaseRecordGroupStatus.ok) {
                Swal.fire(i18next.t("Allaction:Canceled Successfully"), "", "success").then(
                  () => {
                    this.setState({ redirect: true });
                  }
                );
              } else {
                Swal.fire(
                  i18next.t("Allaction:Canceled Unsuccessful"),
                  response.updatePurchaseRecordGroupStatus.warningText,
                  "warning"
                );
              }
            },
            onError: (err) => {
              this.setState({ loading: false });
              Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), "", "error");
            },
          });
        }
      } else {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/accounting/expense/purchase/list/all"} />;
    }
    return (
      <WrapperContent>
        <AccountingTopMenuNavigation mini={true} />
        <Translation>
          {t =>
            <div className="container-fluid box" id="invoice-detail">
              <BackButtonIcon
                LinkBack={"/accounting/expense/purchase/list/all"}
                LinkText={t("accounting:purchase")+" "+"(PR)"}
                boxHtmlText={
                  <div className="col text-right">
                    {this.props.purchaseQuery.purchaseRecordGroup.status !== "VOID" &&
                      _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_purchase_delete", }) &&
                      (
                        <button
                          className="btn btn-danger add mr-3"
                          disabled={this.state.loading}
                          onClick={this.onChangeStatus}
                        >
                          {this.state.loading && (
                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                          )}
                          {i18next.t("purchaseDetail:Cancel Expense Config Record")}
                        </button>
                      )}
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_purchase_print", }) && (
                      <Link
                        to={
                          "/document/purchase/" +
                          this.props.purchaseQuery.purchaseRecordGroup.id
                        }
                        target="_blank"
                      >
                        <button
                          className="btn btn-primary add"
                          disabled={this.state.loading}
                        >
                          {i18next.t("purchaseDetail:Print Expense Config Record")}
                        </button>
                      </Link>
                    )}
                  </div>
                }
              />

              <div className="content-inner pt-2">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <p>
                          <strong>{i18next.t("purchaseDetail:Creditor")}</strong>
                        </p>
                        <p>
                          {this.props.contact.refNumber} {this.props.contact.name}
                        </p>
                      </div>
                      <div className="col">
                        <p>
                          <strong>{i18next.t("purchaseDetail:Number")}</strong>
                        </p>
                        <p>
                          {this.props.purchaseQuery.purchaseRecordGroup.docNumber}{" "}
                        </p>
                      </div>
                      <div className="col">
                        <p>
                          <strong>{i18next.t("purchaseDetail:Issue Date")}</strong>
                        </p>
                        <p>
                          {format(this.props.purchaseQuery.purchaseRecordGroup.issuedDate, "DD/MM/YYYY", { locale: thLocale })}
                        </p>
                      </div>
                      <div className="col">
                        <p>
                          <strong>{i18next.t("purchaseDetail:Due Date")}</strong>
                        </p>
                        <p>
                          {format(this.props.purchaseQuery.purchaseRecordGroup.dueDate, "DD/MM/YYYY", { locale: thLocale })}{" "}
                          (
                          {differenceInCalendarDays(
                            this.props.purchaseQuery.purchaseRecordGroup.dueDate,
                            this.props.purchaseQuery.purchaseRecordGroup.issuedDate
                          )}{" "}
                          {i18next.t("purchaseDetail:Day")})
                        </p>
                      </div>
                    </div>
                    <div className="row">

                      <div className="col">
                        <p>
                          <strong>{i18next.t("purchaseDetail:Invoice Number")}</strong>
                        </p>
                        <p>
                          {this.props.purchaseQuery.purchaseRecordGroup.taxInvoiceNumber}
                        </p>
                      </div>

                      {this.props.purchaseQuery.purchaseRecordGroup.taxInvoiceNumber ? <div className="col">
                        <p>
                          <strong>{i18next.t("purchaseDetail:Entry Date")}</strong>
                        </p>
                        <p>
                          {format(this.props.purchaseQuery.purchaseRecordGroup.taxInvoiceDate, "DD/MM/YYYY", { locale: thLocale })}
                        </p>
                      </div>
                        :
                        <div className="col"></div>
                      }

                      <div className="col">
                        <p>
                          <strong></strong>
                        </p>
                        {/* <p>{format(this.props.purchaseQuery.purchaseRecordGroup.taxInvoicePeriod, 'MM/YYYY', {locale: thLocale})}</p> */}
                        <p></p>
                      </div>

                      <div className="col">
                        <p>
                          <strong>{i18next.t("purchaseDetail:Ref Doc Number")}</strong>
                        </p>
                        {this.props.purchaseQuery.purchaseRecordGroup.refNumber || this.props.purchaseQuery.purchaseRecordGroup.refPurchaseDoc ?
                          (
                            <React.Fragment>
                              <span>
                                {
                                  this.props.purchaseQuery.purchaseRecordGroup.refNumber
                                }
                              </span>
                              <span className="ml-2">
                                {this.props.purchaseQuery.purchaseRecordGroup.refPurchaseDoc && (
                                  <a
                                    download="name_of_downloaded_file"
                                    href={
                                      this.props.purchaseQuery.purchaseRecordGroup.refPurchaseDoc
                                    }
                                  >
                                    {/* <div className="p-2"> */}
                                    <LightBox
                                      image={
                                        this.props.purchaseQuery.purchaseRecordGroup.refPurchaseDoc
                                      }
                                    />
                                    {/* </div> */}
                                    {/* <img
                                                        src={this.props.purchaseQuery.purchaseRecordGroup.refPurchaseDoc}
                                                        alt="back" className="download-file-icon"/> */}
                                  </a>
                                )}
                              </span>
                            </React.Fragment>
                          ) : (
                            <p>-</p>
                          )}
                      </div>
                    </div>

                    <div className="row">
                      {this.props.purchaseQuery.purchaseRecordGroup.purchaseOrderes &&
                        <div className="col">
                          <p>
                            <strong>{i18next.t("purchaseDetail:Purchase Order Number")}</strong>
                          </p>
                          <p>
                            <Link to={"/purchasing/manageOrder/updateFormManageOrder/" + this.props.purchaseQuery.purchaseRecordGroup.purchaseOrderes.procurementForm.type.toLowerCase() + "/approve/" + this.props.purchaseQuery.purchaseRecordGroup.purchaseOrderes.id}>
                              {this.props.purchaseQuery.purchaseRecordGroup.purchaseOrderes.docNumber}
                            </Link>
                          </p>
                        </div>
                      }
                    </div>

                    <hr />
                    <div className="row">
                      <div className="col">
                        <p>
                          <strong>{i18next.t("purchaseDetail:Address")}</strong>
                        </p>
                        {/* <p>
                      {this.props.contact.registeredProvince.includes("กรุงเทพ") || this.props.contact.registeredProvince.includes("กทม") ? (
                        <>
                          {this.props.contact.registeredAddress}
                          {this.props.contact.registeredDistrict && ` แขวง${this.props.contact.registeredDistrict}`}
                          {this.props.contact.registeredCity && ` เขต${this.props.contact.registeredCity}`}
                          {this.props.contact.registeredProvince && ` ${this.props.contact.registeredProvince} `}
                          {this.props.contact.registeredPostalCode}{" "}
                          {this.props.contact.registeredCountry}
                        </>
                      ) : (
                        <>
                          {this.props.contact.registeredAddress}
                          {this.props.contact.registeredDistrict && ` ตำบล${this.props.contact.registeredDistrict}`}
                          {this.props.contact.registeredCity && ` อำเภอ${this.props.contact.registeredCity}`}
                          {this.props.contact.registeredProvince && ` จังหวัด${this.props.contact.registeredProvince} `}
                          {this.props.contact.registeredPostalCode}{" "}
                          {this.props.contact.registeredCountry}
                        </>
                      )}
                    </p> */}

                        {/* BTA685 new rq */}
                        <p>
                          {this.props.purchaseRecordGroup?.province === "กรุงเทพ" || this.props.purchaseRecordGroup?.province === "กรุงเทพมหานคร" || this.props.purchaseRecordGroup?.province === "กทม" ? (
                            <>
                              {this.props.purchaseRecordGroup?.address + " "}
                              {this.props.purchaseRecordGroup?.district && ` แขวง${this.props.purchaseRecordGroup?.district}`}
                              {this.props.purchaseRecordGroup?.city && ` เขต${this.props.purchaseRecordGroup?.city}`}
                              {this.props.purchaseRecordGroup?.province && ` ${this.props.purchaseRecordGroup?.province} `}
                              {this.props.purchaseRecordGroup?.postalCode} {" "}
                              {this.props.purchaseRecordGroup?.country}
                            </>
                          ) : (
                            <>
                              {this.props.purchaseRecordGroup?.address}
                              {this.props.purchaseRecordGroup?.district && ` ตำบล${this.props.purchaseRecordGroup?.district}`}
                              {this.props.purchaseRecordGroup?.city && ` อำเภอ${this.props.purchaseRecordGroup?.city}`}
                              {this.props.purchaseRecordGroup?.province && ` จังหวัด${this.props.purchaseRecordGroup?.province} `}
                              {this.props.purchaseRecordGroup?.postalCode}{" "}
                              {this.props.purchaseRecordGroup?.country}
                            </>
                          )}
                        </p>
                      </div>
                    </div>

                    <PurchaseDetailTable
                      purchase_group={this.props.purchaseQuery.purchaseRecordGroup}
                    />
                  </div>
                </div>

                {this.state.prepaid_deposit_transaction.length > 0 && (
                  <div className="card mt-5">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h6>{i18next.t("purchaseDetail:Advance Payment Item")}</h6>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col">
                          <div className="table-responsive fade-up">
                            <table className="table table-hover">
                              <thead className="thead-light">
                                <tr>
                                  <th>{i18next.t("purchaseDetail:Number")}</th>
                                  <th>{i18next.t("purchaseDetail:")}</th>
                                  <th>{i18next.t("purchaseDetail:Description")}</th>
                                  <th className="text-right">{i18next.t("purchaseDetail:Amount")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.prepaid_deposit_transaction.map(
                                  (prepaid_deposit) => (
                                    <tr key={prepaid_deposit.node.id}>
                                      <td>
                                        {prepaid_deposit.node.prepaidDepositTransaction.prepaidDeposit.docNumber
                                        }
                                      </td>
                                      <td>
                                        {prepaid_deposit.node.prepaidDepositTransaction.chartOfAccount.chartOfAccountCode
                                        }{" "}
                                        {prepaid_deposit.node.prepaidDepositTransaction.chartOfAccount.name
                                        }
                                      </td>
                                      <td>
                                        {prepaid_deposit.node.prepaidDepositTransaction.description
                                        }
                                      </td>
                                      <td className="text-right">
                                        {
                                          numberWithComma(prepaid_deposit.node.amount)
                                        }
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <PurchaseSummary
                  taxType={this.props.purchaseQuery.purchaseRecordGroup.taxType}
                  invoice={this.props.purchaseQuery.purchaseRecordGroup}
                  remark={this.props.purchaseQuery.purchaseRecordGroup.remark}
                  transaction_list={this.state.transaction_list}
                  prepaidDeposit={this.state.prepaidDeposit}
                  remark_edit={true}
                  category={"purchase"}
                />
              </div>
            </div>

          }
        </Translation>
      </WrapperContent>
    );
  }
}

export default PurchaseDetail;
