/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResidentialStatus = "AVAILABILITY" | "FOR_RENT" | "LIVE" | "OTHER" | "SEIZURE" | "%future added value";
export type createUpdateProjectManagerQueryVariables = {|
  status?: ?string
|};
export type createUpdateProjectManagerQueryResponse = {|
  +allResidential: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +floor: ?string,
        +size: number,
        +status: ResidentialStatus,
        +residentialHouseholder: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +tenant: {|
                +id: string,
                +firstName: string,
                +lastName: ?string,
                +countries: string,
                +phone: ?string,
                +email: ?string,
                +user: ?{|
                  +id: string
                |},
              |},
            |}
          |}>
        |},
        +type: {|
          +id: string,
          +name: string,
        |},
      |}
    |}>,
  |},
  +chooseTerm: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +startDate: ?any,
        +expDate: ?any,
      |}
    |}>
  |},
|};
export type createUpdateProjectManagerQuery = {|
  variables: createUpdateProjectManagerQueryVariables,
  response: createUpdateProjectManagerQueryResponse,
|};
*/


/*
query createUpdateProjectManagerQuery(
  $status: String
) {
  allResidential {
    totalCount
    edges {
      node {
        id
        name
        floor
        size
        status
        residentialHouseholder {
          edges {
            node {
              id
              tenant {
                id
                firstName
                lastName
                countries
                phone
                email
                user {
                  id
                }
              }
            }
          }
        }
        type {
          id
          name
        }
      }
    }
  }
  chooseTerm(status: $status) {
    edges {
      node {
        startDate
        expDate
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ResidentialNodeConnection",
  "kind": "LinkedField",
  "name": "allResidential",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResidentialNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResidentialNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "floor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "size",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "HouseHolderNodeConnection",
              "kind": "LinkedField",
              "name": "residentialHouseholder",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "HouseHolderNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "HouseHolderNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TenantNode",
                          "kind": "LinkedField",
                          "name": "tenant",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "firstName",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "lastName",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "countries",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "phone",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "email",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "UserNode",
                              "kind": "LinkedField",
                              "name": "user",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ResidentialTypeNode",
              "kind": "LinkedField",
              "name": "type",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createUpdateProjectManagerQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "SettingTermNodeConnection",
        "kind": "LinkedField",
        "name": "chooseTerm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingTermNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SettingTermNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createUpdateProjectManagerQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "SettingTermNodeConnection",
        "kind": "LinkedField",
        "name": "chooseTerm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingTermNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SettingTermNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd871feca1362512e544ec0e94e7fe02",
    "id": null,
    "metadata": {},
    "name": "createUpdateProjectManagerQuery",
    "operationKind": "query",
    "text": "query createUpdateProjectManagerQuery(\n  $status: String\n) {\n  allResidential {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        floor\n        size\n        status\n        residentialHouseholder {\n          edges {\n            node {\n              id\n              tenant {\n                id\n                firstName\n                lastName\n                countries\n                phone\n                email\n                user {\n                  id\n                }\n              }\n            }\n          }\n        }\n        type {\n          id\n          name\n        }\n      }\n    }\n  }\n  chooseTerm(status: $status) {\n    edges {\n      node {\n        startDate\n        expDate\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ddc29aa0b78fbc033196f4c4d8748a50';

module.exports = node;
