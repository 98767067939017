/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type workSheetQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
  betweenStartId?: ?string,
  betweenEndId?: ?string,
|};
export type workSheetQueryResponse = {|
  +selfProject: ?{|
    +name: string
  |},
  +allActiveChartOfAccount: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +chartOfAccountCode: string,
        +name: string,
        +totalDebit: ?number,
        +totalCredit: ?number,
        +totalHistoricalDebit: ?number,
        +totalHistoricalCredit: ?number,
        +totalPeriodDebit: ?number,
        +totalPeriodCredit: ?number,
        +balanceDebit: ?number,
        +balanceCredit: ?number,
        +balance: ?number,
        +update: ?any,
      |}
    |}>
  |},
|};
export type workSheetQuery = {|
  variables: workSheetQueryVariables,
  response: workSheetQueryResponse,
|};
*/


/*
query workSheetQuery(
  $startDate: DateTime
  $endDate: DateTime
  $betweenStartId: String
  $betweenEndId: String
) {
  selfProject {
    name
    id
  }
  allActiveChartOfAccount(startDate: $startDate, endDate: $endDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {
    edges {
      node {
        id
        chartOfAccountCode
        name
        totalDebit
        totalCredit
        totalHistoricalDebit
        totalHistoricalCredit
        totalPeriodDebit
        totalPeriodCredit
        balanceDebit
        balanceCredit
        balance
        update
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "betweenEndId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "betweenStartId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "betweenEndId",
      "variableName": "betweenEndId"
    },
    {
      "kind": "Variable",
      "name": "betweenStartId",
      "variableName": "betweenStartId"
    },
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "endDate"
    },
    {
      "kind": "Variable",
      "name": "startDate",
      "variableName": "startDate"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allActiveChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chartOfAccountCode",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPeriodDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPeriodCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "balanceDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "balanceCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "balance",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "update",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "workSheetQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "workSheetQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ]
  },
  "params": {
    "cacheID": "6f59d3b4bc8512e5bce2868db7ceb6ec",
    "id": null,
    "metadata": {},
    "name": "workSheetQuery",
    "operationKind": "query",
    "text": "query workSheetQuery(\n  $startDate: DateTime\n  $endDate: DateTime\n  $betweenStartId: String\n  $betweenEndId: String\n) {\n  selfProject {\n    name\n    id\n  }\n  allActiveChartOfAccount(startDate: $startDate, endDate: $endDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {\n    edges {\n      node {\n        id\n        chartOfAccountCode\n        name\n        totalDebit\n        totalCredit\n        totalHistoricalDebit\n        totalHistoricalCredit\n        totalPeriodDebit\n        totalPeriodCredit\n        balanceDebit\n        balanceCredit\n        balance\n        update\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '678daff888748fa7054a2227ca26048f';

module.exports = node;
