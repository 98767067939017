import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Translation } from 'react-i18next';
import API from '../../../api/setting'
import _ from "lodash"
import Swal from 'sweetalert2';


class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            account: [{}],
            settingSecurity: [{}],
            role: [{}],
            settingCalu: [{}]
        }
        // this.setStates = this.setStates.bind(this)
    }
    // componentWillMount() {
    //     this.getDataAccount();
    // }
    componentWillMount() {
        this.getDataAccount();
        this.getDataEstamp();
        this.getDataPackingCal();
        this.getDataCompaynSecurity()
    }

    getDataCompaynSecurity() {
        API.getCompanySetting().then((res) => {
            if (res.data.settingSecuritySystem) {
                let data = res.data.settingSecuritySystem
                this.setState({ settingSecurity: data, selectedId: data.settingSecuritySystemId })
            }else{
                this.setState({ settingSecurity: [] })
            }
        })
    }


    getDataAccount() {
        API.getAccountSettingPacking().then((res) => {
            // console.log(res);
            if (res.data.account) {
                let account = []
                _.map(res.data, (n) => {
                    account.push(n)
                })
                this.setState({ account: account })
            }else{
                this.setState({ account: [] })
            }
        })
    }

    getDataEstamp() {
        API.getEstampSetting().then((res) => {
            // console.log(res);
            if (res.data.role) {
                let role = []
                _.map(res.data, (n) => {
                    role.push(n)
                })
                this.setState({ role: res.data.role.settingParkingFeeEstampId })
            }else{
                this.setState({ role: [] })
            }
        })
    }

    getDataPackingCal() {
        API.getcaluletePackingSetting().then((res) => {
            // console.log(res);
            if (res.data.settingCalu) {
                let role = []
                _.map(res.data, (n) => {
                    role.push(n)
                })
                this.setState({
                    settingCalu: res.data.settingCalu,
                    etampTimerVistor: res.data.settingCalu.etampTimerVistor == null ? "0" : res.data.settingCalu.etampTimerVistor,
                    etampTimerVistorHH: res.data.settingCalu.etampTimerVistorHH,
                    etampTimerVistorMM: res.data.settingCalu.etampTimerVistorMM,
                    etampTimer: res.data.settingCalu.etampTimer,
                    etampTimerHH: res.data.settingCalu.etampTimerHH,
                    etampTimerMM: res.data.settingCalu.etampTimerMM,
                    toPayCal: res.data.settingCalu.toPayCal,
                    toCalOverTime: res.data.settingCalu.toCalOverTime,
                })
            }else{
                this.setState({ settingCalu: [] })
            }
        })
    }

    alertError() {
        Swal.fire({
            icon: 'error', title: "กรุณาบันทึกบัญชีหลัก"
        })
    }

    render() {
        return (
            <Translation>
                {t =>
                    <div className="row" id="navigation-tab">
                        <div className="col">
                            <ul>
                                {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_residential'}) && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_receivable'}) && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_payable'}) && */}
                                <li style={{ width: "180px" , textAlign: "center" }}>
                                    <NavLink exact={true} to="/setting/setting-keep-packing/companyDetail">
                                        {t("settingPacking:SettingCompanyDetail")}
                                        {this.state.settingSecurity.length === 0 ?
                                            <img src={process.env.PUBLIC_URL + '/images/icons/alert-red.png'} style={{ width: "15px" }} /> : ""}
                                    </NavLink>
                                </li>
                                {/* <li style={{ width: "137px", textAlign: "center", pointerEvents: this.state.settingSecurity.length === 0 ? 'none' : 'all' }}>
                                    <NavLink exact={true} to="/setting/setting-keep-packing/all">
                                        {t("settingPacking:settingPackAccount")}  &nbsp;&nbsp;

                                        {this.state.account.length === 0 ?
                                            <img src={process.env.PUBLIC_URL + '/images/icons/alert-red.png'} style={{ width: "15px" }}  /> : ""}
                                    </NavLink>
                                </li> */}
                                <li style={{ width: "220px", textAlign: "center", pointerEvents: this.state.settingSecurity.length === 0 ? 'none' : 'all' }}>
                                    <NavLink exact={true} to="/setting/setting-keep-packing/all">
                                        {t("settingPacking:Setting up a bank account")}  &nbsp;&nbsp;
                                        {/* ตั้งค่าบัญชีธนาคารเพื่อรับเงิน &nbsp; */}
                                        {this.state.account.length === 0 ?
                                            <img src={process.env.PUBLIC_URL + '/images/icons/alert-red.png'} style={{ width: "15px" }}  /> : ""}
                                    </NavLink>
                                </li>
                                {/* } */}
                                {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_residential'}) && */}
                                {/* style={this.state.account == null ? {pointerEvents : 'none'} : {}} */}
                                <li style={{ width: "207px", textAlign: "center",pointerEvents: this.state.account.length === 0 ? 'none' : 'all' }}>
                                    <NavLink to="/setting/setting-keep-packing/estamp">
                                        {t("settingPacking:settingPackEstamp")} &nbsp;&nbsp;
                                        {this.state.role.length === 0 ?
                                            <img src={process.env.PUBLIC_URL + '/images/icons/alert-red.png'} style={{ width: "15px" }}  /> : ""}
                                    </NavLink>
                                </li>
                                {/* } */}
                                {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_receivable'}) && */}
                                <li style={{ width: "212px", textAlign: "center",pointerEvents: this.state.role.length === 0 ? 'none' : 'all' }}>
                                    <NavLink to="/setting/setting-keep-packing/calculetepacking">
                                        {t("settingPacking:settingPackCalulate")} &nbsp;&nbsp;
                                        {this.state.settingCalu.length === 0 ?
                                            <img src={process.env.PUBLIC_URL + '/images/icons/alert-red.png'} style={{ width: "15px" }}  /> : ""}
                                    </NavLink>
                                </li>
                                {/* }  */}
                            </ul>
                        </div>
                    </div>}
            </Translation>
        )
    }
}

export default Navigation;