import React, {Component} from 'react';
import Dashboard from './dashboard/';
import PrivateRoute from '../libs/privateRoute';
import News from './news';
import Parcel from './parcel';
import Survey from './survey';
import FixRequest from './fixRequest';
import Complaint from './complaint';
import CommonArea from './commonArea';
import Chat from './chatgroup/index';
import SettingInSur from './fixRequest/setting/settingInsur';
import FixRequestSingha from './fixRequest/indexSingha'; 
import edm from './EDM';
import CommonAreaSingha from './commonAreaForSingha';
import FixRequestDashbroad from './fixRequest/fixRequestDashbroad';

class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <PrivateRoute exact path={`${this.props.match.url}`} component={Dashboard}/>
                <PrivateRoute path={`${this.props.match.url}/news`} component={News}/>
                <PrivateRoute path={`${this.props.match.url}/survey`} component={Survey}/>
                <PrivateRoute path={`${this.props.match.url}/parcel`} component={Parcel}/>

                <PrivateRoute path={`${this.props.match.url}/fix-request`} component={FixRequest}/> 
                <PrivateRoute path={`${this.props.match.url}/fix-requests`} component={FixRequestSingha}/>

                <PrivateRoute path={`${this.props.match.url}/complaint`} component={Complaint}/>
                <PrivateRoute path={`${this.props.match.url}/global-area`} component={CommonArea}/>
                <PrivateRoute exact path={`${this.props.match.url}/settinginsurance`} component={SettingInSur}/>
                <PrivateRoute path={`${this.props.match.url}/dashbroad`} component={FixRequestDashbroad}/>

                {/* <PrivateRoute path={`${this.props.match.url}/fix-request/create`} component={fixRequestListCreate}/> */}
                {/* <PrivateRoute exact path={`${this.props.match.url}/fix-request/fortenant/:tabName`} component={fixRequestListForTenent}/> */}


                <PrivateRoute path={`${this.props.match.url}/EDM`} component={edm}/> 
                <PrivateRoute path={`${this.props.match.url}/committee`} component={Chat}/>

                {/* Booking Singha */}
                <PrivateRoute path={`${this.props.match.url}/global-area-singha`} component={CommonAreaSingha}/>
            </React.Fragment>
        );
    }
}

export default Contact;