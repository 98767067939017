import React, { Component } from 'react'
import { format } from 'date-fns'
import _ from "lodash"
import thLocale from "date-fns/locale/th";
import numberWithComma from '../../libs/numberWithComma'
import "./budgetYearCompareAverageYearPapar.scss"
import i18next from 'i18next';

export default class budgetYearCompareAverageYearPapar extends Component {

  constructor(props) {
    super(props)

    this.state = window.localStorage.getItem("stateLastYearCompareAverageYear") ? JSON.parse(window.localStorage.getItem("stateLastYearCompareWithYear")) : {};
    this.state.bodyTable = []
  }

  componentWillMount() {
    if (this.props.state) {
      window.localStorage.setItem("stateLastYearCompareAverageYear", JSON.stringify(this.props.state))
      this.setState(this.props.state)
    }
  }

  summaryRevenue = (codeValue) => {
    let result = 0;
    let sumRevenue_year_summaryYear_total_budget = 0;
    let sumRevenue_year_total_budget = 0;
    let sumRevenue_mount_summaryYear_total_budget = 0;
    let sumRevenue_mount_total_budget = 0;

    sumRevenue_year_summaryYear_total_budget = _.sumBy(this.state.query.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
    sumRevenue_year_total_budget = _.sumBy(this.state.query.lists[0]?.children, (o) => parseFloat(o.data.total_budget || 0))
    sumRevenue_mount_summaryYear_total_budget = _.sumBy(this.state.query.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
    sumRevenue_mount_total_budget = _.sumBy(this.state.query.lists[0]?.children, (o) => parseFloat(o.data.total_budget / 12))

    switch (codeValue) {
      case "4000-00 year_summaryYear_total_budget":
        result = sumRevenue_year_summaryYear_total_budget
        break;
      case "4000-00 year_total_budget":
        result = sumRevenue_year_total_budget
        break;
      case "4000-00 mount_summaryYear_total_budget":
        result = sumRevenue_mount_summaryYear_total_budget
        break;
      case "4000-00 mount_total_budget":
        result = sumRevenue_mount_total_budget
        break;

      default:
        break;
    }
    return result;

  }

  summaryExpense = (codeValue) => {
    let result = 0;
    let sumExpense_year_otherExpenses_summaryYear_total_budget = 0;
    let sumExpense_year_otherExpenses_total_budget = 0;
    let sumExpense_mount_otherExpenses_summaryYear_total_budget = 0;
    let sumExpense_mount_otherExpenses_total_budget = 0;

    let sumExpense_year_summaryYear_total_budget = 0;
    let sumExpense_year_total_budget = 0;
    let sumExpense_mount_summaryYear_total_budget = 0;
    let sumExpense_mount_total_budget = 0;

    if (codeValue.substring(0, 7) === "5800-00") {
      _.forIn(this.state.query.lists[1].children[8].children, function (item) {
        sumExpense_year_otherExpenses_summaryYear_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_budget || 0))
        sumExpense_year_otherExpenses_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.total_budget || 0))
        sumExpense_mount_otherExpenses_summaryYear_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_budget / 12))
        sumExpense_mount_otherExpenses_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.total_budget / 12))
      })
    } else {
      if (codeValue.substring(0, 7) === "5100-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.state.query.lists[1].children[0].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.state.query.lists[1].children[0].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5200-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.state.query.lists[1].children[1].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.state.query.lists[1].children[1].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5300-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.state.query.lists[1].children[2].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.state.query.lists[1].children[2].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5310-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.state.query.lists[1].children[3].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.state.query.lists[1].children[3].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5400-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.state.query.lists[1].children[4].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.state.query.lists[1].children[4].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5500-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.state.query.lists[1].children[5].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.state.query.lists[1].children[5].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5600-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.state.query.lists[1].children[6].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.state.query.lists[1].children[6].children, (o) => parseFloat(o.data.total_budget / 12) )
      }
      if (codeValue.substring(0, 7) === "5700-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.state.query.lists[1].children[7].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.state.query.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.state.query.lists[1].children[7].children, (o) => parseFloat(o.data.total_budget / 12))
      }
    }

    switch (codeValue) {
      case "5800-00 year_summaryYear_total_budget":
        result = sumExpense_year_otherExpenses_summaryYear_total_budget
        break;
      case "5800-00 mount_summaryYear_total_budget":
        result = sumExpense_mount_otherExpenses_summaryYear_total_budget
        break;
      case "5800-00 year_total_budget":
        result = sumExpense_year_otherExpenses_total_budget
        break;
      case "5800-00 mount_total_budget":
        result = sumExpense_mount_otherExpenses_total_budget
        break;
      case `${codeValue.substring(0, 7)} year_summaryYear_total_budget`:
        result = sumExpense_year_summaryYear_total_budget
        break;
      case `${codeValue.substring(0, 7)} mount_summaryYear_total_budget`:
        result = sumExpense_mount_summaryYear_total_budget
        break;
      case `${codeValue.substring(0, 7)} year_total_budget`:
        result = sumExpense_year_total_budget
        break;
      case `${codeValue.substring(0, 7)} mount_total_budget`:
        result = sumExpense_mount_total_budget
        break;
      default:
        break;
    }
    return result;

  }

  componentDidMount(){
    this.setData()
  }

  setData(){
    let last_index = 0;
    let last_index_child = 0;
    let sumExpense_year_summaryYear_total_budget = 0;
    let sumExpense_mount_summaryYear_total_budget = 0;
    let sumExpense_year_total_budget = 0;
    let sumExpense_mount_total_budget = 0;

    let data1 = []
    {_.map(this.state.query.lists[0]?.children, (item, index) => {
      ++last_index;

      if(index == 0){
        data1.push({
          no: '', 
          name: 'รายรับ', 
          averageSummaryYearTotalBudget: '', 
          summaryYearTotalBudget: '', 
          averageTotalBudget: '', 
          totalBudget: ''
        })
        data1.push({
          no: `${last_index}`, 
          name: `${item.data.name}`, 
          averageSummaryYearTotalBudget: `${item.data?.summaryYear?.total_budget !== "" ? (parseFloat(item.data?.summaryYear?.total_budget / 12).toFixed(2)) : ""}`, 
          summaryYearTotalBudget: `${item.data?.summaryYear?.total_budget ? (item.data?.summaryYear?.total_budget) : ""}`, 
          averageTotalBudget: `${item.data?.total_budget !== "" ? (parseFloat(item.data?.total_budget / 12).toFixed(2)) : ""}`,
          totalBudget: `${item.data?.total_budget ? (item.data?.total_budget) : ""}`
        })

      }else if(index === (this.state.query.lists[0].children.length - 1)){
        data1.push({
          no: `${last_index}`, 
          name: `${item.data.name}`, 
          averageSummaryYearTotalBudget: `${item.data?.summaryYear?.total_budget !== "" ? (parseFloat(item.data?.summaryYear?.total_budget / 12).toFixed(2)) : ""}`, 
          summaryYearTotalBudget: `${item.data?.summaryYear?.total_budget ? (item.data?.summaryYear?.total_budget) : ""}`, 
          averageTotalBudget: `${item.data?.total_budget !== "" ? (parseFloat(item.data?.total_budget / 12).toFixed(2)) : ""}`,
          totalBudget: `${item.data?.total_budget ? (item.data?.total_budget) : ""}`
        })
        data1.push({
          no: "", 
          name: "รวมรายรับ", 
          averageSummaryYearTotalBudget: `${this.summaryRevenue("4000-00 mount_summaryYear_total_budget") !== 0 ? (this.summaryRevenue("4000-00 mount_summaryYear_total_budget").toFixed(2)) : "-"}`, 
          summaryYearTotalBudget: `${this.summaryRevenue("4000-00 year_summaryYear_total_budget") !== 0 ? (this.summaryRevenue("4000-00 year_summaryYear_total_budget").toFixed(2)) : "-"}`, 
          averageTotalBudget: `${this.summaryRevenue("4000-00 mount_total_budget") !== 0 ? (this.summaryRevenue("4000-00 mount_total_budget").toFixed(2)) : "-"}`,
          totalBudget: `${this.summaryRevenue("4000-00 year_total_budget") !== 0 ? (this.summaryRevenue("4000-00 year_total_budget").toFixed(2)) : "-"}`
        })
      }else {
        data1.push({
          no: `${last_index}`, 
          name: `${item.data.name}`, 
          averageSummaryYearTotalBudget: `${item.data?.summaryYear?.total_budget !== "" ? (parseFloat(item.data?.summaryYear?.total_budget / 12).toFixed(2)) : ""}`, 
          summaryYearTotalBudget: `${item.data?.summaryYear?.total_budget ? (item.data?.summaryYear?.total_budget) : ""}`, 
          averageTotalBudget: `${item.data?.total_budget !== "" ? (parseFloat(item.data?.total_budget / 12).toFixed(2)) : ""}`,
          totalBudget: `${item.data?.total_budget ? (item.data?.total_budget) : ""}`
        })
      }
    })}

    {_.map(this.state.query.lists[1]?.children, (item, index_code) => {

      sumExpense_year_summaryYear_total_budget += this.summaryExpense(`${item.data.code} year_summaryYear_total_budget`)
      sumExpense_mount_summaryYear_total_budget += this.summaryExpense(`${item.data.code} mount_summaryYear_total_budget`)
      sumExpense_year_total_budget += this.summaryExpense(`${item.data.code} year_total_budget`)
      sumExpense_mount_total_budget += this.summaryExpense(`${item.data.code} mount_total_budget`)

      if(index_code === 0){
        data1.push({
          no: '', 
          name: 'รายจ่าย', 
          averageSummaryYearTotalBudget: '', 
          summaryYearTotalBudget: '', 
          averageTotalBudget: '', 
          totalBudget: ''
        })
      }

      data1.push({
        no: '', 
        name: `${item.data.name}`, 
        averageSummaryYearTotalBudget: '', 
        summaryYearTotalBudget: '', 
        averageTotalBudget: '', 
        totalBudget: ''
      })

      _.map(item.children, (item_child, index) => {
        if (index_code !== 8) { ++last_index }

        if(item_child.children?.length > 0){
          data1.push({
            no: '', 
            name: `${item_child.data.name}`, 
            averageSummaryYearTotalBudget: '', 
            summaryYearTotalBudget: '', 
            averageTotalBudget: '', 
            totalBudget: ''
          })

          _.map(item_child?.children, (item_child_child, index_child_child) => {
            ++last_index_child

            data1.push({
              no: `${last_index + last_index_child}`, 
              name:  `${item_child_child.data.name}`, 
              averageSummaryYearTotalBudget: `${item_child_child.data?.summaryYear?.total_budget !== "" ? (parseFloat(item_child_child.data?.summaryYear?.total_budget / 12).toFixed(2)) : ""}`, 
              summaryYearTotalBudget: `${item_child_child.data?.summaryYear?.total_budget ? (item_child_child.data?.summaryYear?.total_budget) : ""}`, 
              averageTotalBudget: `${item_child_child.data?.total_budget !== "" ? (parseFloat(item_child_child.data?.total_budget / 12).toFixed(2)) : ""}`, 
              totalBudget: `${item_child_child.data?.total_budget ? (item_child_child.data?.total_budget) : ""}`
            })
          })
        }else{
          data1.push({
            no: `${last_index}`, 
            name: `${item_child.data.name}`, 
            averageSummaryYearTotalBudget: `${item_child.data?.summaryYear?.total_budget !== "" ? (parseFloat(item_child.data?.summaryYear?.total_budget / 12).toFixed(2)) : ""}`, 
            summaryYearTotalBudget: `${item_child.data?.summaryYear?.total_budget ? (item_child.data?.summaryYear?.total_budget) : ""}`, 
            averageTotalBudget: `${item_child.data?.total_budget !== "" ? (parseFloat(item_child.data?.total_budget / 12).toFixed(2)) : ""}`, 
            totalBudget: `${item_child.data?.total_budget ? (item_child.data?.total_budget) : ""}`
          })
        }
      })

      data1.push({
        no: "", 
        name: `รวม${item.data.name}`, 
        averageSummaryYearTotalBudget: `${this.summaryExpense(`${item.data.code} mount_summaryYear_total_budget`) !== 0 ? (this.summaryExpense(`${item.data.code} mount_summaryYear_total_budget`).toFixed(2)) : "-"}`, 
        summaryYearTotalBudget: `${this.summaryExpense(`${item.data.code} year_summaryYear_total_budget`) !== 0 ? (this.summaryExpense(`${item.data.code} year_summaryYear_total_budget`).toFixed(2)) : "-"}`, 
        averageTotalBudget: `${this.summaryExpense(`${item.data.code} mount_total_budget`) !== 0 ? (this.summaryExpense(`${item.data.code} mount_total_budget`).toFixed(2)) : "-"}`, 
        totalBudget: `${this.summaryExpense(`${item.data.code} year_total_budget`) !== 0 ? (this.summaryExpense(`${item.data.code} year_total_budget`).toFixed(2)) : "-"}`
      })
    })}

    data1.push({
      no: "", 
      name: `รวมรายจ่าย`, 
      averageSummaryYearTotalBudget: `${sumExpense_mount_summaryYear_total_budget !== 0 ? (sumExpense_mount_summaryYear_total_budget.toFixed(2)) : "-"}`, 
      summaryYearTotalBudget: `${sumExpense_year_summaryYear_total_budget !== 0 ? (sumExpense_year_summaryYear_total_budget.toFixed(2)) : "-"}`, 
      averageTotalBudget: `${sumExpense_mount_total_budget !== 0 ? (sumExpense_mount_total_budget.toFixed(2)) : "-"}`, 
      totalBudget: `${sumExpense_year_total_budget !== 0 ? (sumExpense_year_total_budget.toFixed(2)) : "-"}`
    })

    data1.push({
      no: "", 
      name: `รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด`, 
      averageSummaryYearTotalBudget: `${(this.summaryRevenue("4000-00 mount_summaryYear_total_budget") - sumExpense_mount_summaryYear_total_budget) !== 0
          ? ((this.summaryRevenue("4000-00 mount_summaryYear_total_budget") - sumExpense_mount_summaryYear_total_budget).toFixed(2))
          : "-"}`, 
      summaryYearTotalBudget: `${(this.summaryRevenue("4000-00 year_summaryYear_total_budget") - sumExpense_year_summaryYear_total_budget) !== 0 
          ? ((this.summaryRevenue("4000-00 year_summaryYear_total_budget") - sumExpense_year_summaryYear_total_budget).toFixed(2))
          : "-"}`, 
      averageTotalBudget: `${(this.summaryRevenue("4000-00 mount_total_budget") - sumExpense_mount_total_budget) !== 0
          ? ((this.summaryRevenue("4000-00 mount_total_budget") - sumExpense_mount_total_budget).toFixed(2)) 
          : "-"}`, 
      totalBudget: `${(this.summaryRevenue("4000-00 year_total_budget") - sumExpense_year_total_budget) !== 0 
          ? ((this.summaryRevenue("4000-00 year_total_budget") - sumExpense_year_total_budget).toFixed(2)) 
          : "-"}`
    })

    this.setState({ bodyTable: _.chunk(data1, 16) })
  }

  render() {

    let lastYear = new Date(this.state.query?.temp_year)
    lastYear = lastYear.getFullYear() - 1

    return (

      _.map(this.state.bodyTable, (valueList, index) => {
        return (
          <React.Fragment key={index}>
            <div className="print-page-a4" id="budgetYearCompareAverageYearPapar">
              <div className="subpage" >
                <div className="head">
                  <div className="headTextCenter text-center font-weight-bold">
                    <div>{this.props.selfProject.name}</div>
                    <div>{i18next.t("budgetDraftForm:Drafting a Budget")}</div>
                    <div>{i18next.t("budgetLastYearCompareWithMonthPaper:From Date")} {`${format(this.state.query.start_date, " D MMMM YYYY ", { locale: thLocale, })}`} {i18next.t("budgetLastYearCompareWithMonthPaper:To")} {`${format(this.state.query.end_date, " D MMMM YYYY ", { locale: thLocale, })}`}</div>
                  </div>
                </div>

                <div className="lastYearCompareAverageYear-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th
                          rowSpan={2}
                          className="text-center"
                        >
                          {i18next.t("budgetLastYearCompareWithMonthPaper:Sequence")}
                        </th>
                        <th
                          rowSpan={2}
                          className="text-center"
                          style={{ width: 240 }}
                        >
                          {i18next.t("budgetLastYearCompareWithMonthPaper:Item")}
                        </th>
                        <th
                          colSpan={2}
                          className="text-center"
                        >
                          {i18next.t("budgetLastYearCompareWithMonthPaper:Annual Budget")} {lastYear}
                        </th>
                        <th colSpan={2} className="text-center">
                        {i18next.t("budgetLastYearCompareWithMonthPaper:Annual Budget")} {format(this.state.query.temp_year, "YYYY")}
                        </th>
                      </tr>
                      <tr className="border-top">
                        <th className="text-center" style={{ minWidth: 90 }}>{i18next.t("budgetLastYearCompareWithMonthPaper:Monthly")}</th>
                        <th className="text-center" style={{ minWidth: 90 }}>{i18next.t("budgetLastYearCompareWithMonthPaper:Monthly")}</th>
                        <th className="text-center" style={{ minWidth: 90 }}>{i18next.t("budgetLastYearCompareWithMonthPaper:Monthly")}</th>
                        <th className="text-center" style={{ minWidth: 90 }}>{i18next.t("budgetLastYearCompareWithMonthPaper:Monthly")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(valueList, (item, indexItem) => {

                        return (
                          <tr key={indexItem}>
                            <td className="text-center">{item.no}</td>
                            <td>{item.name}</td>
                            <td className="text-center">{item.averageSummaryYearTotalBudget ? numberWithComma(item.averageSummaryYearTotalBudget) : ""}</td>
                            <td className="text-center">{item.summaryYearTotalBudget ? numberWithComma(item.summaryYearTotalBudget) : ""}</td>
                            <td className="text-center">{item.averageTotalBudget ? numberWithComma(item.averageTotalBudget) : ""}</td>
                            <td className="text-center">{item.totalBudget ? numberWithComma(item.totalBudget) : ""}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      })
    )
  }
}
