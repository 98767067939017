import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
// import "../styles/receive.scss"
import _ from "lodash";
import getNameResidential from "../../libs/getNameResidential";
import {getTypeProject} from "../../libs/getTypeOfProject";
import { formatDateLocale } from "../../utils";
import i18next from 'i18next';
import i18n from "i18next";
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';
const ThaiBaht = require('thai-baht-text');

class Pay extends Component {

    constructor(props) {
        super(props);

        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];

        this.state = {
            bangkokCode: bangkokCode,
            sum_wht_rate: 0,
            diff: 0,
            sum_vat: 0,
            languages: "th",
        };
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
        this.paymentChannel = this.paymentChannel.bind(this);
    }
    
    componentWillMount() {
        i18n.changeLanguage(this.props.languages);
    }

    componentDidMount() {
        setTimeout(() => {
            let page = $('.' + this.props.pageNumber);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + signature);
            $('.' + this.props.pageNumber + ' table').css({ borderBottom: "1px solid" });
            if (this.props.current_page !== this.props.end_page) {
                $('.' + this.props.pageNumber + ' tbody tr:last-child td').css({ paddingBottom: "35px" });
            } else {
                $('.' + this.props.pageNumber + ' tbody tr:last-child td').css({ paddingBottom: diff });
            }
            this.setState({ diff: diff });
        }, 200);
        let sum_wht = this.props.prepaidDepositTransaction.reduce(function (total, obj) {
            return total + parseFloat(obj.node.whtAmount)
        }, 0);
        let sum_vat = this.props.prepaidDepositTransaction.reduce(function (total, obj) {
            return total + parseFloat(obj.node.vatAmount)
        }, 0);
        this.setState({ sum_wht_rate: sum_wht, sum_vat: sum_vat, languages: this.props.languages });
    }

    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }
    getPrefix(type, code) {
        if (type === "district" && this.isBangkok(code)) {
            return "แขวง"
        }
        else if (type === "city" && this.isBangkok(code)) {
            return "เขต"
        }
        else if (type === "province" && this.isBangkok(code)) {
            return ""
        }
        else if (type === "province") {
            return "จ."
        }
        else if (type === "district") {
            return "ต."
        }
        else if (type === "city") {
            return "อ."
        }
    }

    getValueAndType(transaction, path) {
        if (path === "unitItems") {
            return 1
        }
        return _.get(transaction.node, path, i18next.t("Allaction:Not found"))
    }

    paymentChannel() {
        let text = "";
        let channel = "";
        if (this.props.prepaidDeposit.cashTransaction.edges.length) {
            text = "เงินสด";
            channel = "cash";
        }
        
        if (this.props.prepaidDeposit.bankAccountTransaction.edges.length) {
            let bank = this.props.prepaidDeposit.bankAccountTransaction.edges[0];
            text = bank.node.bankAccount.bankName + " เลขที่บัญชี " + bank.node.bankAccount.accountNumber + " วันที่ " + format(bank.node.date, 'DD/MM/YYYY', { locale: thLocale });
            channel = "bank";
        }
        if (this.props.prepaidDeposit.chequeTransaction.edges.length) {
            let cheque = this.props.prepaidDeposit.chequeTransaction.edges[0];
            text = "เช็คเลขที่ " + cheque.node.chequeNumber + " " + cheque.node.bankAccount.bankName + " สาขา " + cheque.node.bankAccount.branch  + " วันที่ " + format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale });
            channel = "cheque";

        }
        return { text, channel }
    }

    getPrice(total, vat, wht, tax_type) {
        let total_all = total;
        let grand_total_all = total - wht;
        if (tax_type !== "INCLUDE_TAX") {
            total_all = total_all + vat;
            grand_total_all = grand_total_all + vat;
        }
        return { total: total_all, grand_total: grand_total_all }
    }

    render() {
        let paymentChannel = this.paymentChannel()
        let getType = getTypeProject(this.props.selfProject.typeOfProject)
        return (
            <div className={"print-page-a4 " + this.props.pageNumber} id="receive">
                <div className="subpage">
                    <div className="head">
                        <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                            <div style={{ minHeight: 100, minWidth: 100 }}>
                                <img src={this.props.selfProject.logo} alt="silverman" />
                            </div>
                            <div>
                                <div style={{minWidth:'115%'}}>                                    
                                    <strong>{this.state.languages === "en" ? (this.props.selfProject.nameEn || this.props.selfProject.name) : this.props.selfProject.name}</strong>
                                </div>
                                <div>{this.state.languages === "en" ?  (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address} โทร {this.props.selfProject.juristicContactNumber || '-'}</div>
                                <div>เลขประจำตัวผู้เสียภาษี {this.props.selfProject.taxIdNumber || '-'}</div>
                            </div>

                        </div>

                        <div className={this.props.prepaidDeposit.contact.typeOfContact !== 'RESIDENTIAL' ? "document-name customer-vat" : "document-name"}>
                            {
                                this.props.prepaidDeposit.contact.typeOfContact !== 'RESIDENTIAL' &&
                                <React.Fragment>
                                    <div className="title">ใบสำคัญจ่าย</div>
                                    <div className="title">Payment Voucher</div>
                                </React.Fragment>
                            }

                            {
                                this.props.check_type === "manuscript" ?
                                    <div className="title small">(ต้นฉบับ/Original)</div>
                                    :
                                    <div className="title small">(สำเนา/Copy)</div>
                            }
                        </div>
                    </div>

                    <div className="detail">
                        <div className="customer-detail">
                            <strong>รหัสลูกค้า/ID:</strong> {this.props.prepaidDeposit.contact.refNumber}<br />
                            {this.props.prepaidDeposit.contact.typeOfContact === 'RESIDENTIAL' ?
                                <React.Fragment>
                                    <strong>ชื่อลูกค้า/Customer:</strong>
                                    {getNameResidential(this.props.prepaidDeposit.contact.firstName, this.props.prepaidDeposit.contact.lastName)}
                                    <br />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <strong>ชื่อลูกค้า/Customer:</strong> {this.props.prepaidDeposit.contact.name} <br />
                                </React.Fragment>
                            }
                            <strong>ที่อยู่/Address:</strong>

                            <div className="customer-detail address-space">
                                {this.props.prepaidDeposit.contact.registeredAddress + " "}
                                {this.props.prepaidDeposit.contact.registeredDistrict && this.getPrefix("district", this.props.prepaidDeposit.contact.registeredPostalCode) + this.props.prepaidDeposit.contact.registeredDistrict + " "}
                                {this.props.prepaidDeposit.contact.registeredCity && this.getPrefix("city", this.props.prepaidDeposit.contact.registeredPostalCode) + this.props.prepaidDeposit.contact.registeredCity + " "}
                                {this.props.prepaidDeposit.contact.registeredProvince && this.getPrefix("province", this.props.prepaidDeposit.contact.registeredPostalCode) + this.props.prepaidDeposit.contact.registeredProvince + " "}
                                {this.props.prepaidDeposit.contact.registeredPostalCode + " "}

                                {
                                    (this.props.prepaidDeposit.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.prepaidDeposit.contact.typeOfSupplier === "COMPANY") &&
                                    <React.Fragment>
                                        {
                                            this.props.prepaidDeposit.contact.typeOfCompany === "HEADQUARTERS" ?
                                                "สำนักงานใหญ่"
                                                : this.props.prepaidDeposit.contact.typeOfCompany === "BRANCH" &&
                                                "สาขา " + this.props.prepaidDeposit.contact.nameBranch
                                        }
                                    </React.Fragment>
                                }
                            </div>
                            <br />
                            {this.props.prepaidDeposit.contact.typeOfContact !== 'RESIDENTIAL' &&
                                <React.Fragment>
                                    <strong>เลขผู้เสียภาษี/Tax ID:</strong> {this.props.prepaidDeposit.contact.taxIdNumber} <br />
                                    <strong>ผู้ติดต่อ/Attention:</strong> {this.props.prepaidDeposit.contact.firstName} {this.props.prepaidDeposit.contact.lastName}
                                &emsp;{this.props.prepaidDeposit.contact.phone && "T: " + this.props.prepaidDeposit.contact.phone}&emsp;{this.props.prepaidDeposit.contact.email && "E: " + this.props.prepaidDeposit.contact.email} <br />
                                <strong>เลขที่ใบกำกับภาษี:</strong> {this.props.prepaidDeposit.taxInvoiceNumber || "-"} <br />
                                    <strong>วันที่ใบกำกับภาษี:</strong> {this.props.prepaidDeposit.taxInvoiceNumber ? formatDateLocale(this.props.prepaidDeposit.taxInvoiceDate) : "-"}
                                </React.Fragment>
                            }
                            {this.props.prepaidDeposit.contact.firstName && this.props.prepaidDeposit.contact.firstName.substring(0, 6) === "บริษัท" && !this.props.prepaidDeposit.contact.lastName &&
                                <React.Fragment>
                                    <strong>เลขผู้เสียภาษี/Tax
                                    ID:</strong> {this.props.prepaidDeposit.contact.taxIdNumber} <br />
                                </React.Fragment>
                            }
                            <br />
                        </div>

                        <div
                            className={this.props.prepaidDeposit.contact.typeOfContact === 'RESIDENTIAL' ? "document-detail" : "document-detail detail-outsider"}>
                            <strong
                                className={this.props.prepaidDeposit.contact.typeOfContact !== 'RESIDENTIAL' ? "outsider" : ''}>เลขที่/No:</strong> {this.props.prepaidDeposit.docNumber}
                            <br />
                            <strong
                                className={this.props.prepaidDeposit.contact.typeOfContact !== 'RESIDENTIAL' ? "outsider" : ''}>วันที่/Date:</strong> {format(this.props.prepaidDeposit.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                            {this.props.prepaidDeposit.contact.typeOfContact === 'RESIDENTIAL' &&
                                <React.Fragment>
                                    <strong>{getType === "highType" ? "เลขที่ห้องชุด/Room No.:" : "บ้านเลขที่/House No."}</strong> {this.props.prepaidDeposit.contact.name} &nbsp;
                                <br />
                                    <strong>พื้นที่/Space:</strong> {numberWithComma(this.props.prepaidDeposit.contact.residential.size)} {getType === "highType" ? "ตร.ม." : "ตร.ว."}
                                    <br />
                                </React.Fragment>
                            }
                        </div>
                    </div>

                    <div className="invoice-body">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th width={50} className="text-center">
                                        ลำดับ <br />
                                    No.
                                </th>

                                    <th className="text-center">
                                        รายละเอียด <br />
                                    Description
                                </th>

                                    <th width={100} className="text-center">
                                        จำนวนเงิน <br />
                                    Amount
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.prepaidDepositTransaction.map((transaction, index) => {
                                    return (
                                        <tr key={transaction.node.id}>
                                            <td className="text-center">{(this.props.current_page * 20) + index + 1}</td>

                                            <td>
                                                {this.getValueAndType(transaction, "description")}
                                            </td>

                                            <td className="text-right">
                                                {numberWithComma(this.getPrice(this.props.prepaidDeposit.total, this.state.sum_vat, 0, this.props.prepaidDeposit.taxType).total, "0.00")}
                                            </td>
                                        </tr>
                                    )
                                }
                                )}

                            </tbody>
                            <tfoot className="font-weight-bold">
                                <tr>
                                    <td colSpan={1} rowSpan={2} />
                                    <td colSpan={1} className="text-right">
                                        จำนวนเงิน/Amount
                                    </td>
                                    <td className="text-right">
                                        {numberWithComma(this.getPrice(this.props.prepaidDeposit.total, this.state.sum_vat, 0, this.props.prepaidDeposit.taxType).total, "0.00")}
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={1} className="text-right">
                                        ภาษีหัก ณ ที่จ่าย/With holding tax
                                    </td>
                                    <td className="text-right">
                                        {numberWithComma(this.state.sum_wht_rate, "0.00")}
                                    </td>
                                </tr>


                                {this.props.current_page === this.props.end_page &&
                                    <tr>
                                        <td colSpan={2}>
                                            จำนวนเงินรวมทั้งสิ้น/Grand Total &emsp;&emsp;
                                            {
                                                i18n.languages[0] === 'th' ?
                                                    (ThaiBaht(this.getPrice(this.props.prepaidDeposit.total, this.state.sum_vat, this.state.sum_wht_rate, this.props.prepaidDeposit.taxType).grand_total)):
                                                    (ConvertNumberToTextEng(this.getPrice(this.props.prepaidDeposit.total, this.state.sum_vat, this.state.sum_wht_rate, this.props.prepaidDeposit.taxType).grand_total))
                                            }                                            
                                    </td>
                                        <td className="text-right">
                                            {numberWithComma(this.getPrice(this.props.prepaidDeposit.total, this.state.sum_vat, this.state.sum_wht_rate, this.props.prepaidDeposit.taxType).grand_total, "0.00")}
                                        </td>
                                    </tr>
                                }
                            </tfoot>
                        </table>

                        {this.props.current_page === this.props.end_page &&
                            <React.Fragment>
                                <div className="remark">
                                    <span className="font-weight-bold">หมายเหตุ/Remarks: </span><br />
                                    {this.props.prepaidDeposit.remark || "-"}
                                </div>
                                <div className="remark">
                                    <span className="font-weight-bold">การชำระเงิน(Conditions of Payments)</span><br />
                                    {paymentChannel.channel === "cash" &&
                                        <div className="row col ml-1">
                                            <span className=" align-middle"> เงินสด/Cash </span>
                                                <div className="col">
                                                    <span>&emsp;จำนวน&emsp;{numberWithComma(this.getPrice(this.props.prepaidDeposit.total, this.state.sum_vat, this.state.sum_wht_rate, this.props.prepaidDeposit.taxType).grand_total, "0.00")} บาท</span>
                                                </div>
                                        </div>
                                    }
                                    {paymentChannel.channel  === "bank"  && 
                                        <div className="col ml-1">
                                            <span className="align-middle"> เงินโอน/Bank Transfer </span><br/>
                                            <div className = "col ml-2">
                                                <div className="row" key = {paymentChannel.channel}><span> - {paymentChannel.text}&emsp;จำนวน&emsp;{numberWithComma(this.getPrice(this.props.prepaidDeposit.total, this.state.sum_vat, this.state.sum_wht_rate, this.props.prepaidDeposit.taxType).grand_total, "0.00")} บาท<br /></span></div>
                                            </div> 
                                        </div>
                                    }
                                    
                                    {paymentChannel.channel === 'cheque' &&
                                        <div className="col ml-1">
                                            <span className="align-middle"> เช็คธนาคาร/Cheque Bank </span><br/>
                                            <div className = "col ml-2">
                                                <div className="row" key = {paymentChannel.channel}><span>- {paymentChannel.text}&emsp;จำนวน {numberWithComma(this.getPrice(this.props.prepaidDeposit.total, this.state.sum_vat, this.state.sum_wht_rate, this.props.prepaidDeposit.taxType).grand_total, "0.00")} บาท <br /></span></div>
                                            </div>
                                        </div>
                                    } 
                                    <br />
                                </div>
                            </React.Fragment>
                        }
                    </div>

                    {this.props.current_page === this.props.end_page &&
                        <div className="footer">

                            <div className="signature-large">
                                <div className="column-foursign" >
                                    ..................................................<br/>
                                        <span className="font-weight-bold">ผู้จัดทำ / Organizer</span>
                                    </div >
                                <div className="column-foursign">
                                    ..................................................<br/>
                                        <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span>
                                    </div>
                                <div className="column-foursign">
                                    ..................................................<br/>
                                        <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span>
                                    </div>
                                <div className="column-foursign">
                                    ..................................................<br/>
                                        <span className="font-weight-bold">ผู้รับเงิน / Receiver</span>
                                    </div>
                            </div>
                        </div>
                    }

                    {/* <div className="number-page">
                        <br/>
                        {this.props.current_page + 1}/{this.props.end_page + 1}
                    </div> */}

                </div>
            </div>
        );
    }
}

export default Pay;
