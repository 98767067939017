import React from "react";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../env/environment";
import ComponentPagination from "../../libs/componentPagination";

import _ from "lodash";
import Pagination from "../../libs/newPagination";
import { fetchQuery } from "relay-runtime";
import { setDate, getMonth, getYear, subMonths } from "date-fns";
import Loading from "../../libs/loading"
import BackButtonIcon from "../../components/BackBtn/indexBack";
import Navigation from "./navigation";
import SortMeterWater from "./sortMeterWater";
import upload from "../../libs/upload";
import { format } from "date-fns";
import Swal from "sweetalert2";
import i18n from "i18next";
import i18next from "i18next";

var time;
const query = graphql`
  query waterMeterListCompareQuery(
    $search: String
    $first: Int
    $last: Int
    $month: Float
    $year: Float
    $previous_month: Float
    $pre_year: Float
  ) {
    current_month: allResidential(
      search: $search
      first: $first
      last: $last
      order: "added"
    ) {
      edges {
        node {
          id
          name
          waterMeterUnit(month: $month, year: $year) {
            edges {
              node {
                  id
                  month
                  year
                  issuedDate
                  meterValue
                  changeWaterMeter
                  initialChangeMeterValue
                  invoiceStatus
                  remark
                  editDate
                  creatorApp
                  creator
                  editCreatorDate
                  editCreatorDateApp
                  updated
                  waterMeterImage{
                      edges {
                          node{
                              id
                              fileName
                              fileUpload
                          }
                      }
                  }
                  waterMeterTag{
                      edges {
                          node{
                              id
                              name
                          }
                      }
                  }
              }
            }
          }
        }
      }
      totalCount
    }
    previous_month: allResidential(
      search: $search
      first: $first
      last: $last
      order: "added"
    ) {
      edges {
        node {
          id
          name
          waterMeterUnit(month: $previous_month, year: $pre_year) {
            edges {
              node {
                  id
                  invoiceStatus
                  month
                  year
                  issuedDate
                  meterValue
                  changeWaterMeter
                  initialChangeMeterValue
                  invoiceStatus
                  remark
                  editDate
                  creatorApp
                  creator
                  editCreatorDate
                  editCreatorDateApp
                  updated
                  waterMeterImage{
                      edges {
                          node{
                              id
                              fileName
                              fileUpload
                          }
                      }
                  }
                  waterMeterTag{
                      edges {
                          node{
                              id
                              name
                          }
                      }
                  }
              }
            }
          }
        }
      }
      totalCount
    }
    productViewer {
      allProduct(
        isActive: true
        type: "service"
        servicePricing_PricingType: "water_meter"
      ) {
        edges {
          node {
            id
            name
            price
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation waterMeterListCompareMutation($input: UpdateWaterMeterRecordInput!) {
    updateWaterMeterRecord(input: $input) {
      ok
      warningText
      waterMeterList {
        edges {
          node {
            id
            month
            year
            meterValue
          }
        }
      }
    }
  }
`;
class WaterMeterListCompare extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.meter_data = false;
    this.state.search = "";
    this.state.search_input = "";
    this.state.total_water_price = 0;
    this.state.editValue = true;
    this.state.previous_month_current_month = {};
    this.state.listTag = [];
    this.state.editBtn = false
    this.state.selectYear = new Date().getFullYear();
    this.state.selectMonth = parseInt(new Date().getMonth() + 1) < 10 ? `0${parseInt(new Date().getMonth() + 1)}` : parseInt(new Date().getMonth() + 1)  ;
    this.state.monthList = [
      { value: '01', label: 'มกราคม' },
      { value: '02', label: 'กุมภาพันธ์' },
      { value: '03', label: 'มีนาคม' },
      { value: '04', label: 'เมษายน' },
      { value: '05', label: 'พฤษภาคม' },
      { value: '06', label: 'มิถุนายน' },
      { value: '07', label: 'กรกฏาคม' },
      { value: '08', label: 'สิงหาคม' },
      { value: '09', label: 'กันยายน' },
      { value: '10', label: 'ตุลาคม' },
      { value: '11', label: 'พฤศจิกายน' },
      { value: '12', label: 'ธันวาคม' },
    ]
    this.getMeterList = this.getMeterList.bind(this);
    this.meterInputHandle = this.meterInputHandle.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onEditMeter = this.onEditMeter.bind(this)
    this.selectTag = this.selectTag.bind(this)
    this.uploadFile = this.uploadFile.bind(this);
    this.onEditCancel = this.onEditCancel.bind(this)

  }

  componentWillMount() {
    let date_month_now = new Date();
    let current_month = getMonth(date_month_now) + 1;

    this.setState({
      current_month: current_month,
      table_data_list: {}, // meter data for render table
      start_date: setDate(date_month_now, 1),
      listTag: []
    });
  }

  componentDidMount() {
    this.getMeterList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.first !== this.state.first ||
      prevState.last !== this.state.last
    ) {
      this.getMeterList();
    }

    if (prevState.search !== this.state.search || prevState.selectYear !== this.state.selectYear || prevState.selectMonth !== this.state.selectMonth) {
      clearTimeout(time);
      time = setTimeout(() => {
        this.getMeterList();
      }, 500);
    }
  }

  getMeterList() {
    this.setState({ meter_data: false }, () => {

      let start_date = new Date(`${this.state.selectYear}-${this.state.selectMonth}-01`);
      let pre_month = subMonths(start_date, 1);
      let variables = {
        first: this.state.first,
        last: this.state.last,

        // previous_month: getMonth(pre_month) === 0 ? 12 : getMonth(pre_month),
        // month: getMonth(start_date),
        // year: getYear(start_date),
        // pre_year: getMonth(pre_month) === 0 ? getYear(pre_month) - 1 : getYear(pre_month),

        previous_month: getMonth(pre_month) + 1,
        month: getMonth(this.state.start_date) + 1,
        year: getYear(this.state.start_date),
        pre_year: getYear(pre_month),

        search: this.state.search,
      };

      fetchQuery(environment, query, variables).then((results) => {
        let data = _.cloneDeep(results);

        let table_data_list = {};
        let residential_id = [];

        // มิเตอร์น้ำเดือนก่อน
        data.previous_month.edges.forEach((residential, index) => {
          table_data_list[residential.node.id] = [];
          residential_id.push(residential.node.id);

          residential.node.waterMeterUnit.edges.forEach((water) => {
            table_data_list[residential.node.id].push({
              value: water.node.meterValue,
              edit: false,
              warning: false,
              disable: false,
            });
          });

          if (residential.node.waterMeterUnit.edges.length < 1) {
            table_data_list[residential.node.id].push({
              value: 0,
              edit: false,
              warning: false,
              disable: false,
            });
          }

        });

        // มิเตอร์น้ำเดือนปัจจุบัน
        data.current_month.edges.forEach((residential, index) => {
          residential.node.waterMeterUnit.edges.forEach((water) => {
            table_data_list[residential.node.id].push({
              value: water.node.meterValue,
              initialValue: water.node.initialChangeMeterValue,
              edit: false,
              warning: false,
              disable: false,
              is_change: water.node.changeWaterMeter,
            });

            table_data_list[residential.node.id].push({
              value: 0,
              disable: true,
            });

          });

          if (residential.node.waterMeterUnit.edges.length < 1) {
            table_data_list[residential.node.id].push({
              value: 0,
              edit: false,
              warning: false,
              disable: false,
            });

            table_data_list[residential.node.id].push({
              value: 0,
              disable: true,
            });

          }
        });

        data.productViewer.allProduct.edges.forEach((service, index) => {
          residential_id.forEach((id) => {
            table_data_list[id].push({ value: 0, disable: true });
          });
        });

        this.setState(
          {
            meter_data: data,
            table_data_list: table_data_list,
          },
          () => this.calMeterUsed()
        );
      });
    });
  }

  calMeterUsed() {
    let table_data_list = _.cloneDeep(this.state.table_data_list);
    let meter_data = _.cloneDeep(this.state.meter_data);

    let previous_month_current_month = {}
    let total_water_price = 0;


    // เก็บค่ามิเตอร์เดือนปัจจุบัน
    meter_data.current_month.edges.forEach((residential, index) => {
      previous_month_current_month[residential.node.id] = [];
      if (residential.node.waterMeterUnit.edges.length < 1) {
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].id", null);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].month", null);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].year", null);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].meterValue", null);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].invoiceStatus", false);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterTag.edges", []);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterImage.edges", []);

      } else {
        residential.node.waterMeterUnit.edges.forEach((waterMeterUnit) => {
          _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "]", waterMeterUnit.node);
        });
      }

    })

    //นำมิเตอร์เดือนก่อนไปรวมกับมิเตอร์เดือนปัจจุบัน
    meter_data.previous_month.edges.forEach((residential, index) => {
      if (residential.node.waterMeterUnit.edges.length < 1) {
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].id_previous", null);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].month_previous", null);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].year_previous", null);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].meterValue_previous", null);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].changeWaterMeter_previous", null);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].initialChangeMeterValue_previous", null);
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].editChk", false);

      } else {
        residential.node.waterMeterUnit.edges.forEach((waterMeterUnit) => {
          _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].id_previous", waterMeterUnit.node.id);
          _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].month_previous", waterMeterUnit.node.month);
          _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].year_previous", waterMeterUnit.node.year);
          _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].meterValue_previous", waterMeterUnit.node.meterValue);
          _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].changeWaterMeter_previous", waterMeterUnit.node.changeWaterMeter);
          _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].initialChangeMeterValue_previous", waterMeterUnit.node.initialChangeMeterValue);
          _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].editChk", false);

        });
      }

    })

    this.state.meter_data.current_month.edges.forEach((residential, index) => {
      this.state.table_data_list[residential.node.id].forEach(
        (meter, meter_index) => {
          if (meter_index === 2) {
            let used = 0;
            if (table_data_list[residential.node.id][1].is_change) {
              used = table_data_list[residential.node.id][1].value - table_data_list[residential.node.id][1].initialValue;
            } else {
              used = table_data_list[residential.node.id][1].value - table_data_list[residential.node.id][0].value;
            }
            _.set(table_data_list, "[" + residential.node.id + "][" + meter_index + "].value", used);
          }
          if (meter_index !== 0 && meter_index !== 1 && meter_index !== 2) {
            let cal_water_price = _.get(table_data_list, "[" + residential.node.id + "][2].value") * this.state.meter_data.productViewer.allProduct.edges[meter_index - 3].node.price;
            total_water_price += cal_water_price;
            _.set(table_data_list, "[" + residential.node.id + "][" + meter_index + "].value", cal_water_price);
          }
        }
      );
    });

    this.setState({
      table_data_list: table_data_list,
      total_water_price: total_water_price,
      previous_month_current_month: previous_month_current_month,
    });
  }


  //Save
  meterInputHandle(e) {
    e.preventDefault();
    let update_meter_list = [];

    let previous_month_current_month_for_update = [];

    Object.entries(_.cloneDeep(this.state.previous_month_current_month)).map((previous_month_current_month) => {
      previous_month_current_month_for_update.push(previous_month_current_month)
    })

    previous_month_current_month_for_update.forEach(e => {

      // Check ว่ามีการกด แก้ไขไหม
      if (e[1][0].editChk) {
        let waterMeterTag = []
        let waterMeterImage = []
        // Tag
        e[1][0].waterMeterTag.edges.forEach(water_meter_tag => {
          if (water_meter_tag.node.id === null) {
            waterMeterTag.push({
              id: water_meter_tag.node.id_backup,
              name: water_meter_tag.node.name
            })
          }
          else if (water_meter_tag.node.id === 'new') {
            waterMeterTag.push({
              name: water_meter_tag.node.name
            })
          }
        });

       
        //Image
        e[1][0].waterMeterImage.edges.forEach(water_meter_image => {
          if (e[1][0].waterMeterImage.edges.length > 1) {
            waterMeterImage.push(water_meter_image.node)
          }
          else if (water_meter_image.node.id === '') {
            waterMeterImage.push(water_meter_image.node)
          }

        });

        //Data for upload
        update_meter_list.push({
          room_id: e[0],
          month: e[1][0].month | this.state.selectMonth,
          value: e[1][0].meterValue,
          waterMeterTag: waterMeterTag,
          waterMeterImage: waterMeterImage,
          channel: "web",
        });

        // console.log(update_meter_list);
      }
    });

    let variables = {
      input: {
        year: this.state.selectYear,
        meterStack: JSON.stringify(update_meter_list),
        channel:"web"
      },
    };

    // console.log(variables);
    this.setState({ loading: true });
    commitMutation(environment, {
      mutation,
      variables,
      // uploadables,
      onCompleted: (response) => {
        if (response?.updateWaterMeterRecord?.ok) {
          Swal.fire(i18n.t('meter:Save successfully'), '', 'success').then(() => {
            this.getMeterList();
            this.setState({ loading: false , editBtn: false });
          });
        } else {
          Swal.fire(i18n.t('meter:Failed to save'), response?.updateWaterMeterRecord?.warningText, 'warning').then(() => {
            this.getMeterList();
            this.setState({ loading: false, editBtn: false });
          });
        }
      },
      onError: (err) => {
        Swal.fire(i18n.t('meter:Failed to save'), err, 'warning');
        this.setState({ loading: false, editBtn: false });
      },
    });
  }

  //ค้นหา
  handleChangeStart(date) {
    this.setState(
      {
        search: date[0].value,
        selectMonth: date[4].value,
        selectYear: parseInt(date[5].value),
        start_date: new Date(`${date[5].value}-${date[4].value}-01`)
      }
    );
  }

  handleChange(event) {
    if (event.target.name === "meterValue") {
      let dataclone = _.cloneDeep(this.state.previous_month_current_month)
      _.set(dataclone, "[" + event.target.dataset.key + "][" + 0 + "]." + event.target.name, event.target.value);
      this.setState({ previous_month_current_month: dataclone });
    }
    else {
      this.setState({ [event.target.name]: event.target.value });
    }

  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState(
      {
        search: event.target.value,
      },
      () => this.getMeterList()
    );
  }
  isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
  }

  //EDIT
  onEditMeter(residential) {
    let dataclone = _.cloneDeep(this.state.previous_month_current_month)
    if (dataclone[residential.node.id][0].meterValue_previous > 0 && dataclone[residential.node.id][0].meterValue_previous !== null) {
      _.set(dataclone, "[" + residential.node.id + "][" + 0 + "].editChk", true);
      let listTag = [
        { name: "หน้าปัดมาตรวัดน้ำแตก", status: false },
        { name: "รั่วบริเวณใต้ฝา", status: false },
        { name: "รั่วตามตะเข็บรอยต่อ", status: false },
        { name: "รั่วตามตัวมาตรวัดน้ำ", status: false },
        { name: "มิเตอร์น้ำหมุนถอยหลัง", status: false },
        { name: "มาตรวัดน้ำไม่เดิน", status: false },
        { name: "ใบพัดไม่หมุน", status: false },
        { name: "อื่นๆ", status: false },
      ]
      let des = dataclone[residential.node.id][0].waterMeterTag?.edges?.map((tagName) => {
        let da = listTag.findIndex(ex => ex.name === tagName.node.name)
        if (da >= 0) {
          _.set(listTag, `[${da}].status`, true)
        }
      })
      this.setState({
        previous_month_current_month: dataclone, editValue: false,
        listTag: listTag, editBtn: true,
      });
    }
  }

  onEditCancel() {
    let previous_month_current_month = _.cloneDeep(this.state.previous_month_current_month)

    Object.entries(previous_month_current_month).forEach((value) => {
      _.set(previous_month_current_month, "[" + value[0] + "][" + 0 + "].editChk", false);
    })

    this.setState({ previous_month_current_month, editBtn: false });
  }

  selectTag(tagNameSelect, indexxTag, type, residential) {
    // $(".dropdown-menu.tagDropdownList").addClass("show")

    let previous_month_current_month = _.cloneDeep(this.state.previous_month_current_month)

    let listTag = _.cloneDeep(this.state.listTag)
    let findINdex = indexxTag ? indexxTag : listTag.findIndex(ex => ex.name === tagNameSelect)

    if (type) {
      _.set(listTag, `[${indexxTag}].status`, true)

      //หา index ของ waterMeterTag
      let index_waterMeterTag = _.findIndex(previous_month_current_month[residential.node.id][0]?.waterMeterTag?.edges, { 'node': { 'name': _.get(listTag, `[${findINdex}].name`) } })
      //ถ้าไม่มีจะทำการเพิ่ม Tag กรณีไม่มี ID จากหลังบ้าน
      if (index_waterMeterTag < 0) {
        index_waterMeterTag = previous_month_current_month[residential.node.id][0].waterMeterTag?.edges?.length | 0
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterTag.edges[" + index_waterMeterTag + "].node.id", 'new');
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterTag.edges[" + index_waterMeterTag + "].node.name", _.get(listTag, `[${findINdex}].name`));
      } else {
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterTag.edges[" + index_waterMeterTag + "].node.id", _.get(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterTag.edges[" + index_waterMeterTag + "].node.id_backup"));
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterTag.edges[" + index_waterMeterTag + "].node.id_backup", null);
      }


    } else {
      _.set(listTag, `[${findINdex}].status`, false)

      //หา index ของ waterMeterTag
      let index_waterMeterTag = _.findIndex(previous_month_current_month[residential.node.id][0].waterMeterTag.edges, { 'node': { 'name': _.get(listTag, `[${findINdex}].name`) } })
      //กรณีมี ID ใน Tag จากหลังบ้าน
      if (_.get(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterTag.edges[" + index_waterMeterTag + "].node.id") !== 'new') {
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterTag.edges[" + index_waterMeterTag + "].node.id_backup", _.get(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterTag.edges[" + index_waterMeterTag + "].node.id"));
        _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterTag.edges[" + index_waterMeterTag + "].node.id", null);
      } else {
        //กรณีไม่มี ID ใน Tag สามารถ ลบได้เลย
        _.remove(previous_month_current_month[residential.node.id][0].waterMeterTag.edges, function (n, index) {
          return index === index_waterMeterTag;
        });
      }
    }
    // console.log(previous_month_current_month[residential.node.id][0]);
    this.setState({ listTag: listTag, previous_month_current_month })
  }

  //UPLOADFILE
  callUpload = async (path, file) => {
    return new Promise(async (resolve, reject) => {
      let res = await upload("/waterMeter" + path, file);
      resolve(res);
    });
  };
  uploadFile(e, residential) {
    let event = _.cloneDeep(e);
    let value = event.target.value
    let files = event.currentTarget.files[0];
    if (files) {
      if (files?.size > 2097152) {
        Swal.fire(i18next.t("Allaction:Unsuccessful "), `${i18next.t("Allaction:Please upload a new file as it exceeds the specified size of")} 2 MB`, 'error')
      }

      else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
        Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .png, .jpg, .jpeg ${i18next.t("Allaction:Only")} ${i18next.t("Allaction:and each file should not exceed")} 2 MB (${i18next.t("Allaction:maximum of")} 3 ${i18next.t("Allaction:images")} )`, 'error')
      }

      else {
        // fileIsSel = false
        let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
        let fileOldList = _.cloneDeep(this.state.file);
        let previous_month_current_month = _.cloneDeep(this.state.previous_month_current_month)
        // let file =  previous_month_current_month[residential.node.id][0].waterMeterImage?.edges
        this.callUpload('', new_file).then((data) => {
          let dese = "waterMeter/" + new_file.name

          if (_.get(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterImage.edges[" + 0 + "].node.id")) {
            _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterImage.edges[1].node.fileUpload", dese);
            _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterImage.edges[1].node.viewPtath", data.location);
          } else {

            _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterImage.edges[0].node.id", "");
            _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterImage.edges[0].node.fileUpload", dese);
            _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterImage.edges[0].node.fileName", new_file.name);
            _.set(previous_month_current_month, "[" + residential.node.id + "][" + 0 + "].waterMeterImage.edges[0].node.viewPtath", data.location);
          }

          // console.log(previous_month_current_month[residential.node.id][0]);
          // file.push({ fileId: "", fileName: new_file.name, fileUpload: dese, status: "", viewPtath: data.location })
          this.setState({ previous_month_current_month })
        });
      }
    }
  }

  render() {
    let priceUnit = this.state?.meter_data?.productViewer?.allProduct?.edges[0]?.node?.price || 0
    return (
      <Translation>
        {t =>
          <React.Fragment>
            <BackButtonIcon
              printdocLinkChk={true}
              addLinkButton={"/accounting/water-meter/createQRocde"}
              addLinkText={t("meter:createqrcode")}
              LinkBack={"/accounting"}
              LinkText={t("materWater:Water Meter Compare")}
              btnOutlineChk={true}
              btnOutlineLink={"/accounting/water-meter/viewHistory"}
              btnOutlineText={t("meter:viewHistory")}
              btnprintChkCompre={true}
              state={this.state}
            />
            <Navigation />
            <div className="row fixRequestFast">
              <div className="col">
                <div>
                  <SortMeterWater
                    selectIs={true}
                    selectMonthIs={true}
                    searchBoxId={true}
                    changMeter={true}
                    handlePropsChange={this.handleChangeStart}
                    selectYear={this.state.selectYear}
                    selectMonth={this.state.selectMonth}
                    search={this.state.search}
                  />
                </div>
              </div>
            </div>
            <div className="row meterWaterFunction">
              {this.state.meter_data && (
                <React.Fragment>
                  <div className="col-12">
                    <form onSubmit={this.meterInputHandle}>
                      <div className="card">
                        <table className="table">
                          <thead>
                            <tr className="bg-light text-center">
                              <th style={{ width: 20 }}>{t('meter:Room No.')}</th>
                              <th style={{ width: 60 }}>{t('meter:Previous month meter')}</th>
                              <th style={{ width: 60 }}>{t('meter:Current month meter')}</th>
                              <th style={{ width: 60 }}>{t('meter:Used')}</th>
                              <th style={{ width: '10%' }}>{t('meter:ImageMeter')}</th>
                              <th style={{ width: 60 }}>{t('meter:AbnormalSymptoms')}</th>
                              <th style={{ width: 60 }}>{t('meter:CheckBy')}</th>
                              <th style={{ width: 60 }}>{t('meter:LastEdit')}</th>
                              <th style={{ width: 60 }}>
                                {t('meter:Amount')}
                                <br />
                                (
                                {t('meter:PerUnit')}
                                {this.state.meter_data.productViewer.allProduct?.edges[0]?.node.price}
                                )
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.meter_data.current_month.edges.map(
                              (residential, residential_index) => {

                                //ดึงด้าต้าที่รวม มิเตอร์ก่อนหน้าและปัจจุบันมา
                                let data_value = this.state.previous_month_current_month[residential.node.id];
                                let previous_meter = 0 //เลขมิเตอร์เดือนก่อนหน้า
                                let meter = 0 //เลขมิเตอร์เดือนปัจจุบัน	
                                let value = null //จำนวนหน่วยที่ใช้	
                                let waterMeterImage = null //รูปมิเตอร์
                                let waterMeterTag = [] //อาการผิดปกติ
                                let creatorApp = "-" //ผู้ตรวจมิเตอร์
                                let editCreatorDateApp = null
                                let creator = "-" //แก้ไขล่าสุดโดย
                                let editCreatorDate = "-" //แก้ไขเวลาล่าสุดโดยนิติ
                                let editDate = "-" //แก้ไขเวลาล่าสุดโดยนิติ
                                let editChk = null
                                let viewPtath = null
                                let invoiceStatus = false
                                let style_td_col3 = ''
                                if (data_value) {
                                  previous_meter = data_value[0].meterValue_previous || 0 //เลขมิเตอร์เดือนก่อนหน้า
                                  meter = data_value[0].meterValue || 0//เลขมิเตอร์เดือนปัจจุบัน

                                  //จำนวนหน่วยที่ใช้	
                                  if (data_value[0].changeWaterMeter) {
                                    value = meter - data_value[0].initialChangeMeterValue;
                                    style_td_col3 = '2px solid #FFBD2D'
                                  } else {
                                    value = meter - previous_meter;
                                    value = value < 0 ? 0 : value

                                    // มิเตอร์น้อยกว่าเดือนก่อน หรือ มิเตอร์เดือนปัจจุบันมีค่ามากกว่ามิเตอร์เดือนก่อน3เท่า
                                    if( (meter < previous_meter || previous_meter*3 < meter) && meter != 0){
                                      style_td_col3 = '2px solid #EC2340'
                                    }
                                    
                                  }

                                  //ยังไม่ได้ใส่ให้
                                  waterMeterImage = data_value[0].waterMeterImage?.edges[data_value[0].waterMeterImage.edges.length - 1]?.node.fileUpload //รูปมิเตอร์
                                  viewPtath = data_value[0].waterMeterImage?.edges[data_value[0].waterMeterImage.edges.length - 1]?.node?.viewPtath //รูปมิเตอร์ViewPath

                                  waterMeterTag = data_value[0].waterMeterTag?.edges //อาการผิดปกติ

                                  creatorApp = data_value[0]?.creatorApp ? data_value[0]?.creatorApp : "-" //ผู้ตรวจมิเตอร์
                                  creator = data_value[0]?.creator ? data_value[0]?.creator : "-" //แก้ไขล่าสุดโดยโดยนิติ
                                  editCreatorDate = data_value[0].editCreatorDate // วันที่แก้ไขล่าสุดโดยนิติ
                                  editCreatorDateApp = data_value[0].editCreatorDateApp // วันที่แก้ไข จาก แอปนิติ
                                  editDate = data_value[0].editDate //วันที่บันทึกข้อมูลโดยช่าง
                                  editChk = data_value[0].editChk // สถานะการแก้ไขของ บรรทัด นั้น ใช่หรือไม่
                                  invoiceStatus = data_value[0].invoiceStatus
                                }

                                let wate = residential?.node?.waterMeterUnit?.edges[0]?.node
                                // let usedMeter = wate?.changeWaterMeter ? wate?.meterValue : parseInt(wate?.meterValue - wate?.initialChangeMeterValue)
                                // let toPay = parseInt(priceUnit * usedMeter) || 0
                                let toPay = parseInt(priceUnit * value) || 0
                                let mustTopay = toPay !== 0 ? toPay?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ""
                                return (
                                  <tr key={residential.node.id} className="text-center claim">
                                    <td className="bg-light text-center">
                                      {residential.node.name}
                                    </td>
                                    <td>{previous_meter}</td>

                                    <td style={{ border: style_td_col3 }} >
                                      {
                                        editChk && previous_meter !== 0 ?
                                          <input type="number" className="border meter form-control text-center"
                                            data-id={residential_index}
                                            data-key={residential.node.id}
                                            name="meterValue"
                                            onChange={this.handleChange}
                                            value={meter}
                                          />
                                          :
                                          <React.Fragment>
                                            {meter}  {wate?.changeWaterMeter ? "("+t("materWater:The water meter has been changed")+")" : ""}
                                          </React.Fragment>

                                      }
                                    </td>

                                    <td className="text-red">{value}</td>

                                    {/* รูปมิเตอร์ */}
                                    <td>
                                      {
                                        waterMeterImage || viewPtath ?
                                          !editChk ?
                                            <img src={waterMeterImage}
                                              style={{ width: 104, height: 80, objectFit: 'cover' }}
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = process.env.PUBLIC_URL + '/images/iconAction/Meter/meterWaterNull.png';
                                              }}
                                            />
                                            :
                                            <label htmlFor="uploadFile" className="upload-btn-wrapper m-0">

                                              {
                                                viewPtath || waterMeterImage ?
                                                  <img src={viewPtath || waterMeterImage}
                                                    style={{ width: 104, height: 80, objectFit: 'cover' }}
                                                    onError={({ currentTarget }) => {
                                                      currentTarget.onerror = null; // prevents looping
                                                      currentTarget.src = process.env.PUBLIC_URL + '/images/iconAction/Meter/meterWaterNull.png';
                                                    }}
                                                  />
                                                  :
                                                  <div className="bg-gray imageUpload">
                                                    <div className="imageChildUpload">
                                                      <img src={process.env.PUBLIC_URL + '/images/iconAction/Meter/defualtImg.svg'}
                                                        style={{ width: 24, height: 24 }} />
                                                      <br />
                                                      {/* เพิ่มรูปภาพ */}
                                                      <label className="label16 fw-600" style={{ color: '#808080' }}> {t("materWater:Add picture")} </label>
                                                    </div>
                                                  </div>

                                              }

                                              <br />
                                              <input type="file" name='file' id="uploadFile"
                                                className='form-control uploadFile'
                                                placeholder='ระบุเบอร์โทร'
                                                accept='application/.pdf,.png,.jpg'
                                                onChange={(e) => this.uploadFile(e, residential)} />

                                            </label>

                                          :
                                          <div>
                                            <label htmlFor="uploadFile" className="upload-btn-wrapper m-0">
                                              <div className="bg-gray imageUpload">
                                                <div className="imageChildUpload">
                                                  <img src={process.env.PUBLIC_URL + '/images/iconAction/Meter/defualtImg.svg'}
                                                    style={{ width: 24, height: 24 }} />
                                                  <br />
                                                  {/* เพิ่มรูปภาพ */}
                                                  <label className="label16 fw-600" style={{ color: '#808080' }}> {t("materWater:Add picture")} </label>
                                                </div>
                                              </div>
                                              {
                                                editChk &&
                                                <input type="file" name='file' id="uploadFile"
                                                  className='form-control uploadFile'
                                                  placeholder='ระบุเบอร์โทร'
                                                  accept='application/.pdf,.png,.jpg'
                                                  onChange={(e) => this.uploadFile(e, residential)} />
                                              }
                                            </label>

                                          </div>
                                      }

                                    </td>

                                    {/* อาการผิดปกติ */}
                                    <td className={waterMeterTag ? 'fw-300 text-left' : 'fw-300'}>
                                      {
                                        editChk ?
                                          <React.Fragment>
                                            {waterMeterTag?.map((e, indexTagMetr) => {
                                              if (e.node.id !== null) {
                                                return (
                                                  <p key={"tagWaterMeterTag" + indexTagMetr}
                                                    className="tag tagBtnBlue m-0 mb-1 cursor  fw-600"
                                                    style={{ position: 'relative' }}>
                                                    {e.node.name} &nbsp;&nbsp;
                                                    <span style={{ position: 'absolute', right: 10 }}
                                                      onClick={() => this.selectTag(e.node.name, '', false, residential)}>x</span></p>
                                                )
                                              } else {
                                                return null;
                                              }
                                            })}

                                            <button className="tag tagBtnGray dropdown-toggle fw-600" data-toggle="dropdown">+ เพิ่ม Tag</button>
                                            <ul className="dropdown-menu tagDropdownList ulTag" style={{ listStyleType: 'none', width: 225 }}>
                                              {
                                                this.state.listTag?.map((tagLi, indexxTag) => {
                                                  if (tagLi.status == false) {
                                                    return (
                                                      <React.Fragment key={indexxTag} >
                                                        <li className='dropdown-item tagLi' onClick={() => this.selectTag(tagLi.name, indexxTag, true, residential)}
                                                          style={{ width: 180, cursor: 'pointer', borderBottom: '#BFBFBF 1px silid' }}>{tagLi.name}
                                                        </li>
                                                      </React.Fragment>
                                                    )
                                                  } else {
                                                    return (
                                                      <React.Fragment key={indexxTag} >
                                                        <li className='dropdown-item tagLi' onClick={() => this.selectTag(tagLi.name, indexxTag, false, residential)}
                                                          style={{ width: 180, cursor: 'pointer', borderBottom: '#BFBFBF 1px silid', backgroundColor: '#e6f2fe' }}>
                                                          {tagLi.name}
                                                        </li>
                                                      </React.Fragment>
                                                    )
                                                  }


                                                })
                                              }
                                            </ul>
                                          </React.Fragment>
                                          :
                                          waterMeterTag?.length > 0 ?
                                            <ul style={{ listStyleType: 'none' }} className="p-0 ulTag">
                                              {waterMeterTag?.map((e, indTagMeter) => {
                                                if (e.node.id !== null) {
                                                  return (<li key={"TagMeter" + indTagMeter}>{e.node.name}</li>)
                                                } else {
                                                  return null;
                                                }
                                              })}
                                            </ul>
                                            :
                                            <p className="text-center">-</p>
                                      }
                                    </td>

                                    {/* ผู้ตรวจมิเตอร์ */}
                                    <td>
                                      {creatorApp}<br />
                                      {
                                        (creatorApp !== "-" && editCreatorDateApp) && 
                                        <span className="fw-300" >
                                          {
                                            t("materWater:am") === "En"?
                                            format(new Date(editCreatorDateApp), "DD/MM/YYYY hh:mm A") :
                                            format(new Date(editCreatorDateApp), "DD/MM/YYYY HH:mm น.")
                                          }
                                        </span>
                                      }
                                    </td>

                                    {/* นิติแก้ไข */}
                                    <td>
                                      {creator}<br />
                                      {
                                        (creator !== "-" && editCreatorDate) && 
                                        <span className="fw-300" >
                                          {
                                             t("materWater:am") === "En"?
                                             format(new Date(editCreatorDate), "DD/MM/YYYY hh:mm A") :
                                             format(new Date(editCreatorDate), "DD/MM/YYYY HH:mm น.")
                                          }
                                        </span>
                                      }
                                    </td>

                                    {/* จำนวนเงินที่เก็บ */}
                                    <td className="text-red" style={{ position: 'relative' }}>
                                      {mustTopay ? mustTopay : '-'}
                                      {/* สามจุด ตัวเลือกเพิ่มเติม  */}

                                      {
                                        previous_meter > 0 && invoiceStatus === false ?
                                          <span style={{ position: 'absolute', right: 10 }}>
                                            <div className="dropdown printDoc cursor">
                                              <img
                                                className='dropdown-toggle'
                                                data-toggle="dropdown"
                                                src={process.env.PUBLIC_URL + "/images/icons/choiceNoBG.png"} />
                                              <div className="dropdown-menu optionMenu">
                                                {/* แก้ไข  */}
                                                <a className="dropdown-item" target={"_blank"}
                                                  onClick={() => this.onEditMeter(residential)}>{t('meter:Edit')}</a>
                                              </div>
                                            </div>
                                          </span>
                                          :
                                          <span style={{ position: 'absolute', right: 10 }}></span>
                                      }

                                    </td>

                                  </tr>
                                )
                              }
                            )}

                          </tbody>
                        </table>
                      </div>
                      {
                        this.state.editBtn ?
                          <div className="col text-right mt-5 mb-5 claim">
                            <button
                              className='btn-primary-outline mr-5'
                              type={"button"}
                              onClick={() => this.onEditCancel()}>
                              {t("Allaction:cancel")}
                            </button>

                            <button
                              className='btn-primary'
                              style={{ height: 50 }}
                              type={"submit"}
                              disabled={this.state.editValue} >
                              {t("Allaction:save")}
                            </button>
                          </div>
                          :
                          <div className="text-right mt-5 mb-5 claim">
                          </div>
                      }


                    </form>

                  </div>

                  <Pagination
                    changePage={this.changePage}
                    first={this.state.first}
                    last={this.state.last}
                    totalCount={this.state.meter_data.current_month.totalCount}
                  />

                  <div style={{ height: 200 }} />
                </React.Fragment>
              )}
              {!this.state.meter_data && <Loading />}
            </div>
          </React.Fragment>
        }
      </Translation>
    );
  }
}

export default WaterMeterListCompare;
