import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import DeveloperTopMenuNavigation from "../developerTopMenuNavigation";
import { Link } from "react-router-dom";
import "./generalInfo.scss";
import GeneralDetail from './generalDetail';
import DeveloperDetail from './developerDetail';
import _ from 'lodash'
import generalAPI from './generalAPI';
import upload from '../../libs/upload';

const generalData  = {
    description : '', 
    address : '',
    taxId : '',
    website : '',
    tel : '',
    email : '',
    facebook : '',
    line : '',
    documentData : [],
    contactData : [],
    developer : '',
    prefix: 'บริษัท',
    image:''
}

const documentData = [{
    nameFile : '', //ชื่อไฟล์
    documentUpload : '' //file ที่แนบ
}]

const contactData =[{
    name: '', //ชื่อ นามสกุล
    tel : '', //เบอร์โทร
    email: '' 
}]

const developerData = {
    image : '' , //รูปโลโก้
    developerName : '' //ชื่อผู้พัฒนาโครงการ
}


class GeneralInfoDashboard extends Component {

    constructor(props){
        super(props);
        this.state = {
            generalData : generalData,
            developerData : developerData,
            contactData : [],
            documentData : [],
            token : localStorage.getItem("token"),
            imageUpload : '',
            prefixInput : '',
            developerDisable: false
        }
        this.updateContact =this.updateContact.bind(this);

    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = () => {
        generalAPI.getProfile().then((response) => {
            if(response.data.generalData && response.data.generalData.prefix !== undefined){
                if(response.data.generalData !== this.state.generalData){
                this.setState({
                    generalData : response.data.generalData
                },() => {
                    if(response.data.generalData.prefix !== 'บริษัท' && response.data.generalData.prefix !== 'ห้างหุ้นส่วน'){
                        let generalData = _.cloneDeep(this.state.generalData)
                        generalData['prefix'] = 'other'
                        this.setState({
                            prefixInput : response.data.generalData.prefix,
                            generalData : generalData
                        })
                    }
                })
            }}
            this.setState({ developerDisable: response.data.generalData.developer && true })
        })
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/developer/data" + path, file);
          resolve(res);
        });
    };



    async updateContact() {
        let image = ''
        let generalData = this.state.generalData
        if(this.state.imageUpload !== ''){
            await this.callUpload('', this.state.imageUpload).then((data) => {
                image = data.key.replace("silverman-storage/private/", "");
            });
        }
        let document = []
        for (const file of generalData.documentData) {
            // if(file.documentUpload !== ''){
            //     await this.callUpload('', file.documentUpload).then((data) => {
            //         newFilePdf.push({id : file.node.id , nameFile : file.node.nameFile, 
            //             fileUpload : data.key.replace("silverman-storage/private/", "") , status : file.node.state})
            //     });
            // }else{
            //     newFilePdf.push({id : file.node.id , nameFile : file.node.nameFile, 
            //         fileUpload : file.node.fileUpload , status : file.node.state})
            // }
        }
        let data = {
            image : image,
            contactData : generalData.contactData,
            documentData : generalData.documentData,
        }

    }

    render(){
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <DeveloperTopMenuNavigation mini={true} />
                    <div className="container-fluid box" id="developer-form">
                        <div className="row justify-content-between">
                            <div className="col-md-4">
                                <Translation>
                                    {(t) => 
                                    <h4>
                                    <Link to="/developer">
                                        <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/wrapperContent/back.png"
                                        }
                                        alt="back"
                                        className="back"
                                        />
                                    </Link>
                                        {t("developer:General Information")}
                                
                                    </h4>
                                }</Translation>
                            </div>
                            <div className="col-md-8">
                                
                            </div>
                        </div>
    
                        <Translation>
                            {t =>
                                <div className="content-inner row mt-4">
                                    <div className="col-md-5 col-sm-12 p-4">
                                        <div className="card row">
                                            <GeneralDetail generalData={this.state.generalData} fetchData={this.fetchData} prefixInput={this.state.prefixInput} developerDisable={this.state.developerDisable}/>
                                        </div>

                                    </div>
                                    <div className="col-md-7 col-sm-12 p-4">
                                        <div className="row card col-12 mb-3">
                                            <DeveloperDetail generalData={this.state.generalData} callUpload={this.callUpload} fetchData={this.fetchData} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </Translation>
                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }

}
export default GeneralInfoDashboard;