import React, {Component} from 'react';
import Header from '../../../../components/header/index';
import Sidebar from '../../../../components/sidebar/index';
import Wrapper from '../../../../components/wrapper/index';
import WrapperContent from '../../../../components/wrapper/wrapperContent';
import ReceiveSelectInvoice from './receiveSelectInvoice';
import SelectContactReceivable from '../../selectContactReceivable';
import ContactCreateForm from './receiveCreateForm';

import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";

import SelectContactReceivableSiriraj from '../../selectContactReceivableSiriraj';
import ReceiveCreateFormSiriraj from './receiveCreateFormSiriraj';
import FileImportBatch from '../batch/importBatch/fileImportBatch';
import MangeBatch from '../batch/manageBatch/manageBatch';
import BatchDetail from '../batch/manageBatch/batchDetail';
class ReceiveCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountType: "receive",
            customer: "",
            transactions: [],
            periodRtBatch: "",
            totalSummaryRtBatch: ""
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
        this.updateSelectInvoiceList = this.updateSelectInvoiceList.bind(this);
        this.updateCustomerListAdd = this.updateCustomerListAdd.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({customer: contact_list})
    }

    updateSelectInvoiceList(transactions) {
        this.setState({ transactions: transactions})
    }

    updateCustomerListAdd(date) {
        this.setState({periodRtBatch: date})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    {this.props.match.params.status === 'select_customer' &&
                    <SelectContactReceivable updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                   initialContactType="residential"
                                   nextLink="/accounting/income/receive/form/select_invoice"
                                   backLink="/accounting/income/receive/list/all"/>
                    }

                    {this.props.match.params.status === 'select_invoice' &&
                    <ReceiveSelectInvoice contactList={this.state.customer}
                                          updateSelectInvoiceList={this.updateSelectInvoiceList}/>
                    }

                    {this.props.match.params.status === 'create' &&
                    <ContactCreateForm
                        transactions={this.state.transactions}
                        customer={this.state.customer}/>
                    }


                    {/* ==================== Siriraj ==================== */}
                    
                    {this.props.match.params.status === 'select_room_siriraj' &&
                    <SelectContactReceivableSiriraj 
                            updateCustomerList={this.updateCustomerList} 
                            updateCustomerListAdd={this.updateCustomerListAdd}
                            accountType={this.state.accountType}
                            nextLink="/accounting/income/receive/form/create_siriraj"
                            backLink="/accounting/income/receive/list/all"/>
                    }                    
                    {this.props.match.params.status === 'create_siriraj' &&
                    <ReceiveCreateFormSiriraj
                        transactions={this.state.transactions}
                        customer={this.state.customer}                    
                        periodRtBatch={this.state.periodRtBatch}
                    />}
                    {this.props.match.params.status === 'manage_batch' &&
                        <MangeBatch 
                            status={this.props.match.params.id}
                        />
                    }
                    {this.props.match.params.status === 'manage_batch_detail' &&
                        <BatchDetail 
                            batchId={this.props.match.params.id}
                        />
                    }
                    {this.props.match.params.status === 'import_batch' &&
                        <FileImportBatch />
                    }
                </WrapperContent>   
            </Wrapper>


        )
    }
}

export default ReceiveCreate;