import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import RoomDetail from "./roomDetail";
import OwnerDetail from "./ownerDetail";
import KeyCardDetail from "./keyCardDetail";
import VehicleDetail from "./vehicleDetail";
import PetDetail from "./petDetail";
import HistoryDetail from './historyDetail';
import NoMatch from "../../components/noMatch";
import UpdateRoomDetail from "./updateRoomDetail";
import UpdateAddress from "./updateAddress";
import UpdateDocumentAddress from "./updateDocumentAddress";
import CreateUpdateOwnerDetail from "./createUpdateOwnerDetail";
import CreateUpdateKeyCard from "./createUpdateKeyCard";
import VehicleEdit from "./vehicleEdit";
import CreateUpdatePet from "./createUpdatePet";
import OwnershipListDetail from './ownershipDetail/ownershipListDetail';
import ownershipChangeOwnership from './ownershipDetail/ownershipChangeOwnership'; 
import PeaviewOwnerDetail from './peaviewOwnerDetail';
import ViewOwnerHistoryDetail from './viewOwnerHistoryDetail';
import ViewResidentialHistoryDetail from './viewResidentialHistoryDetail';
class ResidentialDetail extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/room-detail/:id`} component={RoomDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/owner-detail/:id`} component={OwnerDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/key-card-detail/:id`} component={KeyCardDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/vehicle-detail/:id`} component={VehicleDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/pet-detail/:id`} component={PetDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/form-room-detail/:id/:room_id?`} component={UpdateRoomDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/form-address/:id/:room_id?`} component={UpdateAddress}/>
                <PrivateRoute exact path={`${this.props.match.url}/form-document-address/:id/:room_id?`} component={UpdateDocumentAddress}/>
                <PrivateRoute exact path={`${this.props.match.url}/form-owner-detail/:id/:owner_id?`} component={CreateUpdateOwnerDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/preview-owner-detail/:id/:owner_id?`} component={PeaviewOwnerDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/form-key-card/:id/:key_card_id?`} component={CreateUpdateKeyCard}/>
                <PrivateRoute exact path={`${this.props.match.url}/form-vehicle/:id/:vehicle_id?`} component={VehicleEdit}/>
                <PrivateRoute exact path={`${this.props.match.url}/form-pet/:id/:pet_id?`} component={CreateUpdatePet}/>
                <PrivateRoute exact path={`${this.props.match.url}/ownership-detail/:id`} component={OwnershipListDetail} />
                <PrivateRoute exact path={`${this.props.match.url}/change-ownership-detail/:id`} component={ownershipChangeOwnership} />
                <PrivateRoute exact path={`${this.props.match.url}/history-detail/:id`} component={HistoryDetail} />
                <PrivateRoute exact path={`${this.props.match.url}/view-owner-history-detail/:id`} component={ViewOwnerHistoryDetail} />
                <PrivateRoute exact path={`${this.props.match.url}/residentialhistory-view/:id`} component={ViewResidentialHistoryDetail}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default ResidentialDetail;