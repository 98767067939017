import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from 'i18next';
import './style/contactCreateUpdateForm.scss'
import Swal from "sweetalert2";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../env/environment";
import { Redirect } from "react-router";
import InputAddress from "../../libs/autoCompleteAddress";
import _ from "lodash"
import { fetchQuery } from "relay-runtime";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import UploadFiles from '../../components/FucntionLib/uploadFile';
import IdCardForm from './contactForm/idCardForm'
import apioutsite from "../../api/apiOutsite"
import { format } from 'date-fns';
import ThaiAddress from 'react-thai-address'

import OwnerAddressDetail from './addressDetail/ownerAddressDetail';
import RenterAddressDetail from './addressDetail/renterAddressDetail';
import AgentAddressDetail from './addressDetail/agentAddressDetail';


const $ = window.jQuery;

const contact_key = ["id", "name", "taxIdNumber", "firstName", "lastName", "nickName", "position", "phone", "email", "mainPhone",
    "mainEmail", "fax", "webSite", "registeredName", "registeredAddress", "registeredCountry", "registeredProvince",
    "registeredDistrict", "registeredCity", "registeredPostalCode", "mailingName", "mailingAddress", "mailingCountry",
    "mailingProvince", "mailingDistrict", "mailingCity", "mailingPostalCode", "typeOfPayment", "typeOfContact",
    "typeOfSupplier", "note", "typeOfCompany", "nameBranch", "vat", "typeOfPeople", "typeOfCountry", "typeOfSetting", "bankName",
    "bankBranchNumber", "accountNumber", "accountName", "accountNameEn", "bookBankFileName", "bookBankFileUpload",
    "firstNameContact", "lastNameContact", "nickNameContact", "phoneContact", "emailContact",
    "statusOfCompany", "registeredCapital"
];

const mutation = graphql`
    mutation contactCreateUpdateFormNewMutation($input: CreateUpdateContactInput!){
        createUpdateContact(input:$input){
            newContact{
                id
            }
        }
    }
`;

const query = graphql` 
      query contactCreateUpdateFormNewQuery($idCardNumber: String,$contactType:String,$branchName: String) {
           checkIdCardContact(idCardNumber: $idCardNumber,contactType:$contactType){
                    result
                    reason
                }
           checkIdCardContactBranch(idCardNumber: $idCardNumber, contactType: $contactType, branchName: $branchName) {
                    result
                    reason
                }
}
`;


class ContactCreateUpdateFormNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check_perm: false,
            check_perm_print: false,
            isNotDuplicateIDCard: true,
            paramLink: "info",

            statusTab: "owner",

            contactFile: [],
            imageBookBank: [],
            idCardNumber: [],
            foundDataFromApi: "",
            isNotDuplicateIDBranch: [],
            prevNameBranch: "",
            tempBankName: null,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onCopyToMailing = this.onCopyToMailing.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.checkInvalidTaxIdNumber = this.checkInvalidTaxIdNumber.bind(this);
        this.changeClassTaxIdNumber = this.changeClassTaxIdNumber.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSelectMailing = this.onSelectMailing.bind(this);
        this.onCheckAddress = this.onCheckAddress.bind(this)
        this.queryCheckUserIDCard = this.queryCheckUserIDCard.bind(this)
    }

    handleInputChange(e) {
        let name = e.target.name;
        if (name.substr(0, 10) === 'registered') {
            this.setState({ [name]: e.target.value }, () => this.onCopyToMailing())
        } else {
            this.setState({ [name]: e.target.value })
        }
        this.setState({ [name]: e.target.value }, () => {
            if (name === "taxIdNumber") {
                //    this.changeClassTaxIdNumber(this.state.taxIdNumber)
                this.queryCheckUserIDCard(this.state.taxIdNumber, this.props.type_payment)
            }
        });
        if (name === "nameBranch" && this.state.taxIdNumber?.length === 13) {
            this.setState({
                nameBranch: e.target.value
            }, () => {
                this.queryCheckUserIDBranch(this.state.taxIdNumber, this.props.type_payment, this.state.nameBranch)
            })
        }
    }

    checkInvalidTaxIdNumber(taxIdNumber) {
        let req = /^[0-9]{13}$/;
        return req.test(taxIdNumber)
    }

    changeClassTaxIdNumber(taxIdNumber) {
        let element = document.getElementById("taxIdNumber");
        if (this.checkInvalidTaxIdNumber(taxIdNumber)) {
            element.className = "form-control is-valid"
        } else {
            element.className = "form-control is-invalid"
        }
    }

    componentWillMount() {
        let state = { ...this.state };
        if (this.props.contact) {
            let key = Object.keys(this.props.contact);
            key.forEach((key) => {
                state[key] = this.props.contact[key];
            });
            let prefixName = this.props.contact.prefixName
            let prefixNameContact = this.props.contact.prefixNameContact
            let firstName = this.props.contact.firstName
            state['idCardNumber'] = this.props.contact.taxIdNumber.split('');
            state['contactFile'] = this.props.contact.contactFile.edges
            state['imageBookBankShow'] = this.props.contact.imageBookBankShow

            state['prefixNameContact'] = (prefixNameContact !== "Mr." && prefixNameContact !== "Mrs." && prefixNameContact !== "Miss") ? 'etc' : this.props.contact.prefixNameContact
            state['prefixNameContactEtc'] = (prefixNameContact !== "Mr." && prefixNameContact !== "Mrs." && prefixNameContact !== "Miss") ? this.props.contact.prefixNameContact : ""
            if (prefixName) {
                switch (prefixName) {
                    case "นาย": state['prefixName'] = "Mr."; break;
                    case "นาง": state['prefixName'] = "Mrs."; break;
                    case "นางสาว": state['prefixName'] = "Miss"; break;
                    case !"Mr.": state['prefixName'] = "etc"; break;
                    case !"Mrs.": state['prefixName'] = "etc"; break;
                    case !"Miss": state['prefixName'] = "etc"; break;
                    default: state['prefixName'] = this.props.contact.prefixName; break;
                }
                // state['prefixName'] = (prefixName !== "Mr." && prefixName !== "Mrs." && prefixName !== "Miss") ? 'etc' : this.props.contact.prefixName
                state['prefixNameEtc'] = (prefixName !== "Mr." && prefixName !== "Mrs." && prefixName !== "Miss") ? this.props.contact.prefixName : ""
            }
            if (firstName?.includes("นาง") || firstName?.includes("นางสาว") || firstName?.includes("นาย")) {
                state['firstName'] =
                    firstName?.includes("นางสาว") ? firstName?.replace("นางสาว", '') :
                        firstName?.includes("นาย") ? firstName?.replace("นาย", '') : firstName?.replace("นาง", '')
            }
        } else {
            contact_key.forEach((key) => {
                state[key] = '';
            });
            state['typeOfCountry'] = 'inside';
            state['typeOfSetting'] = 'default';
            state['typeOfPeople'] = 'juristic_people';
            state['typeOfSupplier'] = 'COMPANY';
            state['typeOfCompany'] = 'HEADQUARTERS';
            state['settingDate'] = new Date()
            state['typeOfPayment'] = this.props.type_payment.toUpperCase();
            state['prefixNameContact'] = ""
            state['prefixName'] = "None"
        }
        this.setState(state);
        this.setState({ redirect: false, loading: false });
        this.onCheckPermission();

    }

    // componentDidMount() {
    //     this.changeClassTaxIdNumber(this.state.taxIdNumber)
    // }

    onCopyToMailing() {
        let copy_key_and_value = [
            ["mailingName", "registeredName"],
            ["mailingAddress", "registeredAddress"],
            ["mailingCountry", "registeredCountry"],
            ["mailingProvince", "registeredProvince"],
            ["mailingDistrict", "registeredDistrict"],
            ["mailingCity", "registeredCity"],
            ["mailingPostalCode", "registeredPostalCode"]
        ];

        copy_key_and_value.forEach((copy) => {
            this.setState({
                [copy[0]]: this.state[copy[1]]
            })
        });
    }

    queryCheckUserIDCard(value, type_payment) {
        fetchQuery(environment, query, { idCardNumber: value, contactType: type_payment, branchName: this.state.branchName }).then(response => {
            if (response) {
                this.setState({
                    isNotDuplicateIDCard: response.checkIdCardContact.result,
                    isNotDuplicateIDBranch: response.checkIdCardContactBranch.result
                });
            }
        });
    }
    queryCheckUserIDBranch(value, type_payment, branchName) {
        this.setState({
            isNotDuplicateIDCard: true,
            isNotDuplicateIDBranch: true
        });
        fetchQuery(environment, query, { idCardNumber: value, contactType: type_payment, branchName: branchName }).then(response => {
            if (response) {
                this.setState({
                    isNotDuplicateIDCard: response.checkIdCardContact.result,
                    isNotDuplicateIDBranch: response.checkIdCardContactBranch.result
                });
            }
        });
    }

    onSubmit(e) {
        e.preventDefault();
        // this.setState({ loading: true });

        // if (!this.state.isNotDuplicateIDCard) {
        //     $('#taxIdNumber').focus();
        //     this.setState({ loading: false });
        //     return
        // } 

        if ((this.state.nameBranch && !this.state.isNotDuplicateIDCard && !this.state.isNotDuplicateIDBranch && this.state.typeOfPeople.toLowerCase() === 'juristic_people')) {
            Swal.fire(i18n.t("Allaction:Saved Unsuccessful"), i18n.t("add_receivable:Please specify a unique branch number"), "error")
            return
        }

        if (!this.state.isNotDuplicateIDCard && this.state.nameBranch && this.state.typeOfPeople === 'juristic_people') {
            this.setState({
                isNotDuplicateIDCard: true
            })
        } else if (!this.state.isNotDuplicateIDCard && this.state.typeOfPeople === 'juristic_people') {
            //check ว่าซ้ำในระบบเราหรือไม่ 
            Swal.fire({
                title: i18n.t("contact:Do you want to confrim save a duplicate ID Card / ID tax?"),
                text: i18n.t("contact:If you confrim please fill a branch name"),
                showCancelButton: true,
                confirmButtonText: i18n.t("Allaction:yes"),
                cancelButtonText: i18n.t("Allaction:cancel")
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        typeOfCompany: "BRANCH",
                        isNotDuplicateIDCard: true
                    })
                    window.location.href = '#companyType';
                }
            })
            return
        } else if (!this.state.isNotDuplicateIDCard && this.state.typeOfPeople === 'general_people') {
            Swal.fire(i18n.t("Allaction:Saved Unsuccessful"), i18n.t("contact:Please do not enter the same tax ID/ID number twice."), "error")
            return
        }


        if (this.state.taxIdNumber.length >= 13 || this.state.isNotDuplicateIDCard) {
            let input = {};
            contact_key.forEach((key) => {
                if (key !== 'id') {
                    input[key] = this.state[key]
                }
            });
            let contactFileUpload = []
            let contactFile = _.cloneDeep(this.state.contactFile);
            contactFile.forEach(element => {
                let datasFile = element.node ? element.node : element
                contactFileUpload.push(JSON.stringify(datasFile))
            });

            if (input['typeOfPayment'] === 'PAYABLE' && input['typeOfSupplier'] === 'PERSON') {
                input["name"] = null
            }

            input["clientMutationId"] = this.state.id;
            input["typeOfPeople"] = this.state.typeOfPeople?.toLowerCase();
            input["typeOfContact"] = this.state.typeOfContact;
            input["bookBankFileName"] = this.state.imageBookBank.length ? this.state.imageBookBank[0].fileName : this.state.imageBookBankUpload ? this.state.imageBookBankUpload : null
            input["bookBankFileUpload"] = this.state.imageBookBank.length ? this.state.imageBookBank[0].fileUpload : this.state.imageBookBankUpload ? this.state.imageBookBankUpload : null
            input["contactFile"] = this.state.contactFile.length > 0 ? contactFileUpload : null
            input["settingDate"] = this.state.settingDate ? format(new Date(this.state.settingDate), "YYYY-MM-DD") : null
            input["settingNumberDate"] = this.state.settingNumberDate ? parseInt(this.state.settingNumberDate) : null
            input["prefixName"] = this.state.prefixName === "etc" ? this.state.prefixNameEtc : this.state.prefixName
            input['prefixNameContact'] = this.state.prefixNameContact !== "etc" ? this.state.prefixNameContact : this.state.prefixNameContactEtc
            input['nameBranch'] = this.state.nameBranch;

            let variables = { input: input };            
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        Swal.fire(i18n.t("add_receivable:Save successfully!"), '', 'success').then((result) => {
                            if (result.value) {
                                this.setState({
                                    redirect: true,
                                    loading: false,
                                })
                            }
                        });
                    },
                    onError: () => {
                        Swal.fire('Error', '', 'error').then((result) => {
                            if (result.value) {
                                this.setState({
                                    loading: false,
                                })
                            }
                        });
                    },
                },
            )
        }
        else {
            this.setState({
                loading: false,
            }, () => Swal.fire('กรุณากรอกข้อมูลให้ถูกต้อง', 'กรุณาตรวจสอบเลขประจำตัวผู้เสียภาษี/เลขประจำตัวประชาชนอีกครั้ง', 'error'))

        }
        if (this.checkInvalidTaxIdNumber(this.state.taxIdNumber) || this.state.taxIdNumber === "") {


        }
    }

    onSelect(fullAddress) {
        const { registeredDistrict, registeredCity, registeredProvince, registeredPostalCode } = fullAddress
        this.setState({
            registeredDistrict,
            registeredCity,
            registeredProvince,
            registeredPostalCode
        }, () => this.setState({
            mailingDistrict: registeredDistrict,
            mailingCity: registeredCity,
            mailingProvince: registeredProvince,
            mailingPostalCode: registeredPostalCode
        }))
    }

    onSelectMailing(fullAddress) {
        const { mailingDistrict, mailingCity, mailingProvince, mailingPostalCode } = fullAddress
        this.setState({
            mailingDistrict,
            mailingCity,
            mailingProvince,
            mailingPostalCode
        })
    }

    onCheckPermission() {
        let check_perm = false;
        let check_perm_print = false;
        if (this.props.edit) {
            let typeOfContact = this.props.contact.typeOfContact.toLowerCase();
            let typeOfPayment = this.props.contact.typeOfPayment.toLowerCase();
            if (typeOfContact === 'residential' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_residential_edit' })) {
                check_perm = true
            } else if (typeOfContact === 'supplier' && typeOfPayment === 'receivable' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_receivable_edit' })) {
                check_perm = true
            } else if (typeOfContact === 'supplier' && typeOfPayment === 'payable' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_payable_edit' })) {
                check_perm = true
            }

            if (typeOfContact === 'residential' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_residential_print' })) {
                check_perm_print = true
            } else if (typeOfContact === 'supplier' && typeOfPayment === 'receivable' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_receivable_print' })) {
                check_perm_print = true
            } else if (typeOfContact === 'supplier' && typeOfPayment === 'payable' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_payable_print' })) {
                check_perm_print = true
            }

        } else {
            if (this.props.page === 'residential' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_residential_create' })) {
                check_perm = true
            } else if (this.props.page === 'supplier' && this.props.type_payment === 'receivable' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_receivable_create' })) {
                check_perm = true
            } else if (this.props.page === 'supplier' && this.props.type_payment === 'payable' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'contact_payable_create' })) {
                check_perm = true
            }
        }

        this.setState({ check_perm: check_perm, check_perm_print: check_perm_print }, () => this.onCheckAddress())
    }

    onCheckAddress() {
        if (this.state.mailingAddress !== this.state.registeredAddress && !this.state.mailingAddress) {
            this.setState({
                mailingAddress: this.state.registeredAddress
            })
        }
        if (this.state.mailingCity !== this.state.registeredCity && !this.state.mailingCity) {
            this.setState({
                mailingCity: this.state.registeredCity
            })
        }
        if (this.state.mailingDistrict !== this.state.registeredDistrict && !this.state.mailingDistrict) {
            this.setState({
                mailingDistrict: this.state.registeredDistrict
            })
        }
        if (this.state.mailingPostalCode !== this.state.registeredPostalCode && !this.state.mailingPostalCode) {
            this.setState({
                mailingPostalCode: this.state.registeredPostalCode
            })
        }
        if (this.state.mailingProvince !== this.state.registeredProvince && !this.state.mailingProvince) {
            this.setState({
                mailingProvince: this.state.registeredProvince
            })
        }
    }

    onkeyIdCard = (idCardNumber, taxIdNumber) => {
        if (!idCardNumber.includes("")) {
            this.setState({
                idCardNumber: idCardNumber, taxIdNumber: taxIdNumber
            })

            if (idCardNumber.length >= 13) {

                for (let index = 0; index <= idCardNumber.length; index++) {
                    $('#taxIdNumber_' + (index + 1)).val(idCardNumber[index])
                }
                this.onFindIDCard(idCardNumber, taxIdNumber)
            }
        }

    }

    onFindIDCard = (idCardNumber, taxIdNumber) => {
        if (idCardNumber.length >= 13) {
            apioutsite.getDataFromIdCard(taxIdNumber).then((res) => {
                if (res?.data?.data) {
                    let typeOfSupplier = this.state.typeOfSupplier
                    let typeOfCompany = this.state.typeOfCompany
                    // let nameBranch = this.state.nameBranch
                    let name = this.state.name
                    let address = this.state.mailingAddress
                    let distrct = this.state.mailingDistrict
                    let city = this.state.mailingCity
                    let province = this.state.mailingProvince
                    let statusOfCompany = this.state.statusOfCompany
                    let registeredCapital = this.state.registeredCapital
                    try {
                        statusOfCompany = res.data.data[0]["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicStatus"]
                        registeredCapital = res.data.data[0]["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicRegisterCapital"]


                        typeOfSupplier = res.data.data[0]["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicType"]

                        typeOfSupplier = typeOfSupplier === "บริษัทจำกัด" ? "COMPANY" :
                            typeOfSupplier === "บริษัทมหาชนจำกัด" ? "PUBLIC_COMPANY" :
                                typeOfSupplier === "ห้างหุ้นส่วนจำกัด" ? "PARTNERSHIP" :
                                    typeOfSupplier === "ห้างหุ้นส่วนสามัญ" ? "ORDINARY_PARTNERSHIP" :
                                        typeOfSupplier === "บุคคลธรรมดา" ? "PERSON" :
                                            typeOfSupplier === "คณะบุคคล" ? "GROUP_OF_PERSON" :
                                                typeOfSupplier === "มูลนิธิ" ? "FOUNDATION" :
                                                    typeOfSupplier === "สมาคม" ? "ASSOCIATION" :
                                                        typeOfSupplier === "กิจกรรมร่วมค้า" ? "JOINT_VENTURE" :
                                                            typeOfSupplier === "ร้านค้า" ? "MERCHANT" : "OTHER";


                        typeOfCompany = res.data.data[0]["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicBranchName"]
                        typeOfCompany = typeOfCompany === "สำนักงานใหญ่" ? "HEADQUARTERS" : "BRANCH"

                        // nameBranch = res.data.data[0]["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicBranchName"]
                        // nameBranch = typeOfCompany !== "สำนักงานใหญ่" ? nameBranch : ""

                        name = res.data.data[0]["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicNameTH"]

                        address = res.data.data[0]["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicAddress"]["cr:AddressType"]["cd:Address"]
                        distrct = res.data.data[0]["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicAddress"]["cr:AddressType"]["cd:CitySubDivision"]["cr:CitySubDivisionTextTH"]
                        city = res.data.data[0]["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicAddress"]["cr:AddressType"]["cd:City"]["cr:CityTextTH"]
                        province = res.data.data[0]["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicAddress"]["cr:AddressType"]["cd:CountrySubDivision"]["cr:CountrySubDivisionTextTH"]
                    } catch (err) {

                    } finally {
                        city = city?.replace("เขต", "")
                        const search = ThaiAddress.search({ tumbon: distrct, city: city, province: province });
                        this.setState({
                            statusOfCompany: statusOfCompany,
                            registeredCapital: registeredCapital,
                            typeOfSupplier: typeOfSupplier,
                            typeOfCompany: typeOfCompany,
                            name: name,

                            registeredAddress: address,
                            mailingAddress: address,

                            registeredDistrict: distrct,
                            mailingDistrict: distrct,

                            registeredCity: city,
                            mailingCity: city,

                            registeredProvince: province,
                            mailingProvince: province,

                            mailingPostalCode: search[0] ? search[0].zipcode : "",
                            registeredPostalCode: search[0] ? search[0].zipcode : "",

                            foundDataFromApi: res?.data?.data[0] ? "yes" : 'no',

                        })
                    }

                }
            })
            this.queryCheckUserIDCard(taxIdNumber, this.props.type_payment)
        }
    }

    dateFile = (index, data, dataBy) => {

        if (dataBy === "imageBookBankUpload") {
            this.setState({ imageBookBankUpload: null, imageBookBankName: null })
        } else {
            let DataFile = _.cloneDeep(this.state.contactFile)
            DataFile.splice(index, 1)

            this.setState({ [dataBy]: DataFile })
        }
    }

    onChangeSpecifiyBank = (e) => {
        const value = e.target.value;        
        this.setState({
            tempBankName: value,            
        })
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.props.backPage} />
        }

        let reqID = true;
        if (this.state.typeOfPeople?.toLowerCase() === 'general_people' && this.state.typeOfSupplier?.toLowerCase() === "other") {
            reqID = false;
        }

        const checkSpecifyBank = ![null ,"", "BBL", "KTB", "BAY", "KBANK", "TTB", "CIMB", "SCB", 
            "Standard Chartered Bank", "UOB", "TISCO", "KKP", "LHB", 
            "ICBC", "TCB", "MEGA ICBC", "Bank of China (Thai)", 
            "Sumitomo Mitsui Banking", "CITIBANK, N.A. CIMBT.", 
            "ไม่มี","ธนาคารกรุงเทพ","ธนาคารกรุงไทย","ธนาคารกรุงศรีอยุธยา","ธนาคารกสิกรไทย",
            "ธนาคารทหารไทยธนชาต","ธนาคารซีไอเอ็มบี ไทย","ธนาคารไทยพาณิชย์","ธนาคารสแตนดาร์ดชาร์เตอร์ด",
            "ธนาคารยูโอบี","ธนาคารทิสโก้","ธนาคารเกียรตินาคินภัทร","ธนาคารแลนด์ แอนด์ เฮ้าส์",
            "ธนาคารไอซีบีซี (ไทย)","ธนาคารไทยเครดิต เพื่อรายย่อย","ธนาคารเมกะ สากลพาณิชย์","ธนาคารแห่งประเทศจีน (ไทย)",
            "ธนาคารซูมิโตโม มิตซุย","ธนาคารซิตี้แบงก์",
            ].includes(this.state.bankName)        

        return (

            <Translation>
                {t =>
                    <div className="container-fluid box pb-0" id="contact-create-update">
                        <BackButtonIcon
                            LinkBack={this.props.backPage}
                            LinkText={this.props.contact ? t('accountContract:edit contact') : t('accountContract:add ' + this.props.type_payment)}
                            boxHtmlText={
                                <div className="col-md-2 col-2">
                                    <div className="row">
                                        <div className="col-md-2 col-2 mt-2">
                                            <p>{t("add_receivable:Code")}</p>
                                        </div>
                                        <div className="col-md-10 col-10">
                                            {
                                                this.props.contact ?
                                                    <input type="text" className="form-control text-center"
                                                        defaultValue={this.state.refNumber} disabled />
                                                    : <input type="text" className="form-control text-center"
                                                        defaultValue={this.props.type_payment === 'receivable' ? 'CXXXXX' : 'SXXXXX'} disabled />
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                        <div className='border border-bottom' />

                        <form onSubmit={this.onSubmit}>
                            <div className="content-inner mb-10">
                                <div className='row colorUse'>
                                    <div className='col-2 border-right mr-0 p-0'>
                                        <div className='mt-5'>
                                            <p className={`p-2 ${this.state.paramLink === "info" ? 'bg-BFBFBF' : ""}`}>
                                                <a href='#info' className='m-0 cursor fs-16 text_000000'
                                                    onClick={() => this.setState({ paramLink: "info" })}>
                                                    {t("contact:Partner Information")}</a></p>
                                            <p className={`p-2 ${this.state.paramLink === "address" ? 'bg-BFBFBF' : ""}`}>
                                                <a href='#address' className='m-0 cursor fs-16 text_000000'
                                                    onClick={() => this.setState({ paramLink: "address" })}>
                                                    {t("contact:Partner Address")}</a></p>
                                            <p className={`p-2 ${this.state.paramLink === "addresParcel" ? 'bg-BFBFBF' : ""}`}>
                                                <a href='#addresParcel' className='m-0 cursor fs-16 text_000000'
                                                    onClick={() => this.setState({ paramLink: "addresParcel" })}>
                                                    {t("contact:Address for Delivery of Documents")}</a></p>
                                            <p className={`p-2 ${this.state.paramLink === "contact" ? 'bg-BFBFBF' : ""}`}>
                                                <a href='#contact' className='m-0 cursor fs-16 text_000000'
                                                    onClick={() => this.setState({ paramLink: "contact" })}>
                                                    {t("contact:Contact")}</a></p>
                                            <p className={`p-2 ${this.state.paramLink === "peopleContact" ? 'bg-BFBFBF' : ""}`}>
                                                <a href='#peopleContact' className='m-0 cursor fs-16 text_000000'
                                                    onClick={() => this.setState({ paramLink: "peopleContact" })}>
                                                    {t("contact:Cotact Address Urgent")}</a></p>
                                            <p className={`p-2 ${this.state.paramLink === "bookbank" ? 'bg-BFBFBF' : ""}`}>
                                                <a href='#bookbank' className='m-0 cursor fs-16 text_000000'
                                                    onClick={() => this.setState({ paramLink: "bookbank" })}>
                                                    {t("contact:Partner bank account")}</a></p>
                                            {/* <p className={`p-2 ${this.state.paramLink === "settingInvoice" ? 'bg-BFBFBF' : ""}`}>
                                                <a href='#settingInvoice' className='m-0 cursor fs-16 text_000000'
                                                    onClick={() => this.setState({ paramLink: "settingInvoice" })}>
                                                    {t("contact:Payment due setting")}</a></p> */}
                                        </div>

                                    </div>

                                    <div className='col-10 colorUse' id='DetailBoxContact'>
                                        <div className='ml-2 pb-10' style={{ height: '65vh', overflow: 'auto', overflowX: 'hidden' }} >
                                            {/* ข้อมูลคู่ค้า */}
                                            <section id="info" onChange={() => this.setState({ paramLink: "info" })} onPointerOver={() => this.setState({ paramLink: "info" })} onPointerMove={() => this.setState({ paramLink: "info" })}>
                                                <p className='fs-18 pt-5 pb-5 border-bottom'>{t("contact:Partner Information")}</p>
                                                <div className='row'>
                                                    <div className="col-8 mt-3">
                                                        <React.Fragment>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" className="custom-control-input" id="juristic_people"
                                                                    disabled={this.state.taxIdNumber === "0105561063013"}
                                                                    name="typeOfPeople"
                                                                    value="juristic_people" onChange={this.handleInputChange}
                                                                    defaultChecked={this.state.typeOfPeople?.toLowerCase() === 'juristic_people'} />
                                                                <label className="custom-control-label fs-16"
                                                                    htmlFor="juristic_people">{t("contact:Juristic people")}</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" className="custom-control-input" id="general_people"
                                                                    disabled={this.state.taxIdNumber === "0105561063013"}
                                                                    name="typeOfPeople"
                                                                    value="general_people" onChange={this.handleInputChange}
                                                                    defaultChecked={this.state.typeOfPeople?.toLowerCase() === 'general_people'} />
                                                                <label className="custom-control-label fs-16" htmlFor="general_people">{t("contact:General people")}</label>
                                                            </div>


                                                        </React.Fragment>
                                                    </div>
                                                    {/* ปชช */}
                                                    <div className="col-12 mt-3">
                                                        <p className='fs-16' style={{ width: '722px' }}>{t("add_receivable:Tax payer identification/Identification number")}
                                                            <label style={{ float: 'right' }}>
                                                                <span className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" className="custom-control-input" id="inside"
                                                                        disabled={this.state.taxIdNumber === "0105561063013"}
                                                                        name="typeOfCountry"
                                                                        value="inside" onChange={this.handleInputChange}
                                                                        defaultChecked={this.state.typeOfCountry?.toLowerCase() === 'inside'} />
                                                                    <label className="custom-control-label fs-16"
                                                                        htmlFor="inside">{t("contact:In country")}</label>
                                                                </span>
                                                                <span className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" className="custom-control-input" id="outside"
                                                                        disabled={this.state.taxIdNumber === "0105561063013"}
                                                                        name="typeOfCountry"
                                                                        value="outside" onChange={this.handleInputChange}
                                                                        defaultChecked={this.state.typeOfCountry?.toLowerCase() === 'outside'} />
                                                                    <label className="custom-control-label fs-16" htmlFor="outside">{t("contact:Other country")}</label>
                                                                </span>
                                                            </label>
                                                        </p>
                                                        {
                                                            this.state.typeOfCountry?.toLowerCase() === "inside" ?
                                                                <React.Fragment>
                                                                    <p className='pb-0 mb-0'>
                                                                        <IdCardForm
                                                                            onkeyIdCard={(idCardNumber, taxIdNumber) => this.onkeyIdCard(idCardNumber, taxIdNumber)}
                                                                            state={this.state}
                                                                            taxIdNumber={this.state.taxIdNumber}
                                                                            require={reqID}
                                                                        />
                                                                        <button
                                                                            onClick={() => this.onFindIDCard(this.state.idCardNumber, this.state.taxIdNumber)}
                                                                            type='button' className='col-2 btn btn-primary h48 mt-0 ml-3'
                                                                            style={{ width: '10%' }}>{t("newBookingSingha_Booking:Search")}</button>
                                                                    </p>
                                                                    {
                                                                        this.state.foundDataFromApi === "yes" ?
                                                                            <p className='text_68DD6E fw-300'>{t("contact:The taxpayer/citizen identification number has been found.")}</p>
                                                                            :
                                                                            this.state.foundDataFromApi === "no" ?
                                                                                <p className='text-red fw-300'>{t("contact:This taxpayer/personal identification number was not found.")}</p> :
                                                                                !this.state.isNotDuplicateIDCard ?
                                                                                    <p className='text-red fw-300'>{t("contact:Please do not enter the same tax ID/ID number twice.")}</p>
                                                                                    :
                                                                                    ""
                                                                    }

                                                                </React.Fragment>
                                                                :
                                                                <p style={{ width: '722px' }}>
                                                                    <input
                                                                        //  + (this.state.taxIdNumber !== '' && (this.state.isNotDuplicateIDCard ? " is-valid" : " is-invalid"))
                                                                        className={"form-control h48 "}
                                                                        type="text" id="taxIdNumber"
                                                                        name="taxIdNumber"
                                                                        defaultValue={this.state.taxIdNumber || ''}
                                                                        onChange={this.handleInputChange}
                                                                        maxLength={40}
                                                                        // required={(this.state.typeOfSupplier === "MERCHANT" || this.state.typeOfSupplier === "OTHER") ? false : true}
                                                                        disabled={this.state.taxIdNumber === "0105561063013"}
                                                                    />
                                                                    <label className='fs-16 fw-300 text_808080' style={{ float: 'right', marginTop: "-36px", marginRight: "16px", }}>{this.state.taxIdNumber.length}/40</label>
                                                                </p>
                                                        }
                                                        {/* <div className="invalid-feedback">
                                                            {
                                                                !this.state.isNotDuplicateIDCard && t("contact:Please do not enter the same tax ID/ID number twice.")
                                                            }
                                                        </div> */}
                                                    </div>

                                                    {this.state.typeOfContact !== 'RESIDENTIAL' &&
                                                        <div className='col-8'>
                                                            <p className='fs-16 mt-5' htmlFor="prename">{t("add_receivable:Tax type")} </p>
                                                            <select className="form-control h48" id="vat" name="vat"
                                                                disabled={this.state.taxIdNumber === "0105561063013"}
                                                                defaultValue={this.state.vat}
                                                                onChange={this.handleInputChange}>
                                                                <option value="">{t("add_receivable:None")}</option>
                                                                <option value="0">0%</option>
                                                                <option value="7">7%</option>
                                                            </select>
                                                        </div>
                                                    }

                                                    <div className='col-4' />

                                                    {this.state.typeOfContact !== 'RESIDENTIAL' &&
                                                        <React.Fragment>
                                                            <div className='col-4 mt-3'>
                                                                <p className='fs-16' htmlFor="statusOfCompany">{t("add_receivable:Status Of Company")}</p>
                                                                <input type="text" className="form-control h48" id="statusOfCompany"
                                                                    name="statusOfCompany" disabled={this.state.taxIdNumber === "0105561063013"}
                                                                    defaultValue={this.state.statusOfCompany} onChange={this.handleInputChange}

                                                                />
                                                            </div>
                                                            <div className='col-4 mt-3'>
                                                                <p className='fs-16' htmlFor="registeredCapital">{t("add_receivable:Registered Capital")}</p>
                                                                <input type="text" className="form-control h48" id="registeredCapital"
                                                                    name="registeredCapital" disabled={this.state.taxIdNumber === "0105561063013"}
                                                                    defaultValue={this.state.registeredCapital} />
                                                            </div>
                                                            <div className='col-4 mt-3' />
                                                        </React.Fragment>
                                                    }

                                                    {//ชื่อ นามสกุล / สาขา ประเภท 
                                                        this.state.typeOfPeople?.toLowerCase() === "general_people" && this.state.typeOfContact !== 'RESIDENTIAL' ?
                                                            <React.Fragment>
                                                                <div className="col-8 mt-3">
                                                                    <div className='row'>
                                                                        <div className='col-4 mt-0'>
                                                                            <p className='fs-16' htmlFor="typeOfSupplier">{t("add_receivable:Type")}</p>
                                                                            <select className="form-control h48" id="typeOfSupplier" name="typeOfSupplier"
                                                                                defaultValue={this.state.typeOfSupplier} disabled={this.state.taxIdNumber === "0105561063013"}
                                                                                onChange={this.handleInputChange} required>
                                                                                <option value="ORDINARY_PARTNERSHIP">{t("add_receivable:Ordinary partnership")}</option>
                                                                                <option value="PERSON">{t("add_receivable:Person")}</option>
                                                                                <option value="GROUP_OF_PERSON">{t("add_receivable:Group person")}</option>
                                                                                <option value="MERCHANT">{t("add_receivable:Store")}</option>
                                                                                <option value="OTHER">{t("add_receivable:Others")}</option>
                                                                            </select>
                                                                        </div>


                                                                        <div className='col-8 mt-1'>
                                                                            {
                                                                                this.state.typeOfSupplier !== "PERSON" &&
                                                                                <React.Fragment>
                                                                                    <p htmlFor="typeOfSupplier">{t("contact:Partner name")}</p>
                                                                                    <input disabled={this.state.taxIdNumber === "0105561063013"}
                                                                                        className={"form-control h48 " + (this.state.name !== '' && (this.state.name === '' ? " is-invalid" : " is-valid"))}
                                                                                        type="text" name="name"
                                                                                        defaultValue={this.state.name}
                                                                                        onChange={this.handleInputChange}
                                                                                        disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                                                                        required />
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className='col-4' />

                                                                {/* ประเภทภาษี === บุคคลธรรมดา */}
                                                                {this.state.typeOfSupplier === "PERSON" &&
                                                                    <React.Fragment>
                                                                        {/* คำนำหน้า */}
                                                                        <div className='col-8'>
                                                                            <div className='row'>
                                                                                <div className='col-4'>
                                                                                    <p className='fs-16 mt-5' htmlFor="prefixName">{t("contact:Prefix name")} <span className="star-button">*</span></p>
                                                                                    <select className='form-control h48'
                                                                                        disabled={this.state.taxIdNumber === "0105561063013"}
                                                                                        value={this.state.prefixName}
                                                                                        required name='prefixName' onChange={this.handleInputChange}>
                                                                                        <option value={"None"}>{t("preFixName:None")}</option>
                                                                                        <option value={"Mr."}>{t("preFixName:Mr.")}</option>
                                                                                        <option value={"Mrs."}>{t("preFixName:Mrs.")}</option>
                                                                                        <option value={"Miss"}>{t("preFixName:Miss")}</option>
                                                                                        <option value={"etc"}>{t("preFixName:etc.")}</option>
                                                                                    </select>
                                                                                </div>

                                                                                {
                                                                                    (this.state.prefixName === "etc") &&
                                                                                    <div className='col-4'>
                                                                                        <p className='fs-16 mt-5' htmlFor="prefixNameEtc">{t("contact:Prefix name")} {t("preFixName:etc.")} <span className="star-button">*</span></p>
                                                                                        <input className="form-control h48" disabled={this.state.taxIdNumber === "0105561063013"} type="text" id="prefixNameEtc"
                                                                                            name="prefixNameEtc"
                                                                                            defaultValue={this.state.prefixNameEtc}
                                                                                            onChange={this.handleInputChange}
                                                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                                                                            required={(this.state.prefixName !== "Mr." && this.state.prefixName !== "Mrs." && this.state.prefixName !== "Miss" && this.state.prefixName)}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className='col' />
                                                                        {/* ชื่อ _ นามสกุล */}
                                                                        <div className='col-4'>
                                                                            <p className='fs-16 mt-5' htmlFor="firstName">{t("add_receivable:Name")} <span className="star-button">*</span></p>
                                                                            <input className="form-control h48" type="text" id="firstName"
                                                                                name="firstName"
                                                                                defaultValue={this.state.firstName}
                                                                                onChange={this.handleInputChange}
                                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <p className='fs-16 mt-5' htmlFor="lastName">{t("add_receivable:Surname")} <span className="star-button">*</span></p>
                                                                            <input className="form-control h48" type="text" id="lastName"
                                                                                name="lastName"
                                                                                defaultValue={this.state.lastName}
                                                                                onChange={this.handleInputChange}
                                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className='col' />
                                                                    </React.Fragment>
                                                                }

                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                {/* สาขา */}
                                                                <div className="col-8 mt-3" id="companyType" >
                                                                    {
                                                                        (this.state.typeOfSupplier === 'COMPANY' || this.state.typeOfSupplier === 'PUBLIC_COMPANY') &&
                                                                        <React.Fragment>
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" className="custom-control-input" id="headquarters"
                                                                                    name="typeOfCompany" disabled={this.state.taxIdNumber === "0105561063013"}
                                                                                    value="HEADQUARTERS" onChange={this.handleInputChange}
                                                                                    checked={this.state.typeOfCompany === 'HEADQUARTERS'} />
                                                                                <label className="custom-control-label fs-16"
                                                                                    htmlFor="headquarters">{t("add_receivable:Head office")}</label>
                                                                            </div>
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" className="custom-control-input" id="branch"
                                                                                    name="typeOfCompany" disabled={this.state.taxIdNumber === "0105561063013"}
                                                                                    value="BRANCH" onChange={this.handleInputChange}
                                                                                    checked={this.state.typeOfCompany === 'BRANCH'} />
                                                                                <label className="custom-control-label fs-16" htmlFor="branch">{t("add_receivable:Branch")}</label>
                                                                            </div>

                                                                            {this.state.typeOfCompany === 'BRANCH' &&
                                                                                <div className="custom-control-inline">
                                                                                    <div className='d-flex flex-column'>
                                                                                        <Translation>
                                                                                            {t =>
                                                                                                <input className="form-control" type="text"
                                                                                                    id="nameBranch" disabled={this.state.taxIdNumber === "0105561063013"}
                                                                                                    name="nameBranch"
                                                                                                    placeholder={t("add_receivable:Specifies")}
                                                                                                    // maxLength={5}
                                                                                                    defaultValue={this.state.nameBranch}
                                                                                                    onChange={this.handleInputChange}
                                                                                                    required={true} />}
                                                                                        </Translation>
                                                                                        {
                                                                                            (this.state.nameBranch && !this.state.isNotDuplicateIDCard && !this.state.isNotDuplicateIDBranch) ?
                                                                                                <p className='text-red fw-300'>{i18n.t("add_receivable:Duplicate branch No.")}</p>
                                                                                                :
                                                                                                ""
                                                                                        }
                                                                                    </div>
                                                                                </div>


                                                                            }

                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" className="custom-control-input" id="nobrach"
                                                                                    name="typeOfCompany" disabled={this.state.taxIdNumber === "0105561063013"}
                                                                                    value="NOBRANCH" onChange={this.handleInputChange}
                                                                                    checked={this.state.typeOfCompany === 'NOBRANCH'} />
                                                                                <label className="custom-control-label fs-16" htmlFor="nobrach">{t("contact:Not specified")}</label>
                                                                            </div>


                                                                        </React.Fragment>
                                                                    }
                                                                </div>

                                                                {/* ประเภท */}
                                                                <div className="col-8 mt-3">

                                                                    <div className='row'>
                                                                        <div className='col-4 mt-0'>
                                                                            <p className='fs-16' htmlFor="typeOfSupplier">{t("add_receivable:Type")}</p>
                                                                            <select className="form-control h48" id="typeOfSupplier" name="typeOfSupplier"
                                                                                defaultValue={this.state.typeOfSupplier}
                                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                                                onChange={this.handleInputChange} required>
                                                                                <option value="COMPANY">{t("add_receivable:Limited company")}</option>
                                                                                <option value="PUBLIC_COMPANY">{t("add_receivable:Public company limited")}</option>
                                                                                <option value="PARTNERSHIP">{t("add_receivable:Limited partnership")}</option>
                                                                                {/* <option value="ORDINARY_PARTNERSHIP">{t("add_receivable:Ordinary partnership")}</option> */}
                                                                                <option value="PERSON">{t("add_receivable:Person")}</option>
                                                                                {/* <option value="GROUP_OF_PERSON">{t("add_receivable:Group person")}</option> */}
                                                                                <option value="FOUNDATION">{t("add_receivable:Foundation")}</option>
                                                                                <option value="ASSOCIATION">{t("add_receivable:Association")}</option>
                                                                                <option value="JOINT_VENTURE">{t("add_receivable:Joint venture")}</option>
                                                                                {/* <option value="MERCHANT">{t("add_receivable:Store")}</option> */}
                                                                                <option value="OTHER">{t("add_receivable:Others")}</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className='col-8 mt-1'>
                                                                            <p htmlFor="typeOfSupplier">{t("contact:Partner name")}</p>
                                                                            <input
                                                                                className={"form-control h48 " + (this.state.name !== '' && (this.state.name === '' ? " is-invalid" : " is-valid"))}
                                                                                type="text" name="name"
                                                                                defaultValue={this.state.name}
                                                                                onChange={this.handleInputChange}
                                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                                                required />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </React.Fragment>
                                                    }

                                                    {this.state.typeOfContact === 'RESIDENTIAL' &&
                                                        // {/* ชื่อ _ นามสกุล เจ้าของร่วม */ }
                                                        <React.Fragment>
                                                            <div className='col' />
                                                            <div className='col-4'>
                                                                <p className='fs-16 mt-5' htmlFor="firstName">{t("add_receivable:Name")} <span className="star-button">*</span></p>
                                                                {/* <textarea className='form-control' rows={this.state.firstName?.split(",").length} disabled defaultValue={
                                                                    this.state.firstName?.split(",").map((n)=> `${n}`)
                                                                } />  */}
                                                                <input className="form-control h48" type="text" id="firstName"
                                                                    name="firstName" disabled={this.state.taxIdNumber === "0105561063013"}
                                                                    value={this.state.firstName}
                                                                    onChange={this.handleInputChange}
                                                                    disabled={true}
                                                                />
                                                                {/* {
                                                                    this.state.firstName?.split(",").length > 1 &&
                                                                    <input className="form-control h48" type="text" id="firstName"
                                                                        name="firstName"
                                                                        defaultValue={this.state.firstName}
                                                                        onChange={this.handleInputChange}
                                                                        disabled={true}
                                                                    />

                                                                } */}
                                                            </div>
                                                            <div className="col-4">
                                                                <p className='fs-16 mt-5' htmlFor="lastName">{t("add_receivable:Surname")} <span className="star-button">*</span></p>
                                                                {/* <textarea className='form-control h48' />   */}
                                                                <input className="form-control h48" type="text" id="lastName"
                                                                    name="lastName" disabled={this.state.taxIdNumber === "0105561063013"}
                                                                    value={this.state.lastName}
                                                                    onChange={this.handleInputChange}
                                                                    disabled={true}
                                                                />
                                                                {/* {
                                                                    this.state.lastName?.split(",").length > 1 &&
                                                                    <input className="form-control h48" type="text" id="lastName"
                                                                        name="lastName"
                                                                        defaultValue={this.state.lastName}
                                                                        onChange={this.handleInputChange}
                                                                        disabled={true}
                                                                    />

                                                                } */}

                                                            </div>
                                                            <div className='col' />
                                                        </React.Fragment>

                                                    }

                                                    {/* แนบไฟล์ */}
                                                    <div className='col-8 mt-3 meterWaterFunction'>
                                                        <p className='fs-16'>{t("PageDetailProjectInfo:ChooseFile")}</p>
                                                        <p className='text-red fw-300'>
                                                            {`${t('fileType:Support',)} ' PDF, DOC, JPG, PNG' ${t('fileType:and')} ${t('fileType:size not over 10 MB')} ${t('fileType:only')}`}
                                                        </p>
                                                        <UploadFiles
                                                            fileList={this.state.contactFile}
                                                            tagP={true}
                                                            accept={'application/.jpeg,.png,.jpg,.pdf,.doc,.docx'}
                                                            // length
                                                            disabled={this.state.taxIdNumber === "0105561063013"}
                                                            onUpload={(file) => this.setState({ contactFile: file })} />
                                                        {
                                                            this.state.contactFile?.length > 0 &&
                                                            this.state.contactFile?.map((fi, inx) => {
                                                                let file = fi.node ? fi.node : fi
                                                                let typeFile = file.fileName?.split(".")
                                                                return (
                                                                    <p key={inx}>
                                                                        {typeof (typeFile) === 'object' &&
                                                                            <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} />}&nbsp;

                                                                        <Link
                                                                            to="route"
                                                                            onClick={(event) => {
                                                                                event.preventDefault();
                                                                                window.open(
                                                                                    typeof file.viewPtath === "string"
                                                                                        ? file.viewPtath
                                                                                        : file.fileShow
                                                                                )
                                                                            }}
                                                                            target="blank">{file.fileName}</Link>

                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "contactFile")}>X</label>
                                                                    </p>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </section>

                                            {/* ที่อยู่คู่ค้า */}
                                            <section id='address' onChange={() => this.setState({ paramLink: "address" })} onPointerOver={() => this.setState({ paramLink: "address" })} onPointerMove={() => this.setState({ paramLink: "address" })}>
                                                <p className='fs-18 pt-5 pb-5 border-bottom'>{t("contact:Partner Address")}</p>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-8 pl-0'>
                                                        <div>
                                                            <p className='fs-16 mt-5'>{t("add_receivable:Address")} <span className="star-button">*</span></p>
                                                            <textarea className="form-control h48" id="registeredAddress" maxLength="256"
                                                                rows={1} style={{ resize: 'none' }}
                                                                name="registeredAddress" value={this.state.registeredAddress}
                                                                onChange={this.handleInputChange}
                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                                required
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className='col' />

                                                    <div className='col-4 pl-0'>
                                                        <div>
                                                            <p className='fs-16 mt-5' htmlFor="registeredDistrict">{t("add_receivable:Sub-district")}</p>
                                                            <InputAddress
                                                                address="registeredDistrict"
                                                                value={this.state.registeredDistrict}
                                                                onChange={this.handleInputChange}
                                                                onSelect={this.onSelect}
                                                                mailing={false}
                                                                className="form-control h48"
                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col-4 pl-0'>
                                                        <div>
                                                            <p className='fs-16 mt-5' htmlFor="registeredCity">{t("add_receivable:District")}</p>
                                                            <InputAddress
                                                                address="registeredCity"
                                                                value={this.state.registeredCity}
                                                                onChange={this.handleInputChange}
                                                                onSelect={this.onSelect}
                                                                mailing={false}
                                                                className="form-control h48"
                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col' />

                                                    <div className='col-4 pl-0'>
                                                        <div>
                                                            <p className='fs-16 mt-5' htmlFor="registeredProvince">{t("add_receivable:Province")}</p>
                                                            <InputAddress
                                                                address="registeredProvince"
                                                                value={this.state.registeredProvince}
                                                                onChange={this.handleInputChange}
                                                                onSelect={this.onSelect}
                                                                mailing={false}
                                                                className="form-control h48"
                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col-4 pl-0'>
                                                        <div>
                                                            <p className='fs-16 mt-5' htmlFor="registeredPostalCode">{t("add_receivable:Zip code")}</p>
                                                            <InputAddress
                                                                address="registeredPostalCode"
                                                                value={this.state.registeredPostalCode}
                                                                onChange={this.handleInputChange}
                                                                onSelect={this.onSelect}
                                                                mailing={false}
                                                                className="form-control h48"
                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            {/* ที่อยู่จัดส่งเอกสาร */}
                                            <section id='addresParcel' onChange={() => this.setState({ paramLink: "addresParcel" })} onPointerOver={() => this.setState({ paramLink: "addresParcel" })} onPointerMove={() => this.setState({ paramLink: "addresParcel" })}>
                                                <p className='fs-18 pt-5 pb-5 border-bottom'>{t("contact:Address for Delivery of Documents")}</p>
                                                {this.state.check_perm_print &&
                                                    // <Link to={"/document/contact/" + this.state.id}>
                                                    <button className="btn btn-primary add" onClick={() => window.open("/document/contact/" + this.state.id)}>
                                                        {t("add_receivable:Print")}
                                                    </button>
                                                    // </Link>
                                                }

                                                {this.state.typeOfContact === 'RESIDENTIAL' ?
                                                    <React.Fragment>
                                                        <div className="row" id="navigation-tab">
                                                            <div className="col">
                                                                <ul>
                                                                    <li className={`nav-item ${this.state.statusTab === "owner" ? "active" : ""}`}>
                                                                        <a className={`nav-link`} href="javascript:void(0)" onClick={() => this.setState({ statusTab: "owner" })}> {t("PageListHistoryResident:Owner")}</a>
                                                                    </li>
                                                                    <li className={`nav-item ${this.state.statusTab === "renter" ? "active" : ""}`}>
                                                                        <a className={`nav-link`} href="javascript:void(0)" onClick={() => this.setState({ statusTab: "renter" })}> {t("PageListHistoryResident:Rental")}</a>
                                                                    </li>
                                                                    <li className={`nav-item ${this.state.statusTab === "agent" ? "active" : ""}`}>
                                                                        <a className={`nav-link`} href="javascript:void(0)" onClick={() => this.setState({ statusTab: "agent" })}> {t("PageListHistoryResident:Agent")}</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        {this.state.statusTab === "owner" ?
                                                            <OwnerAddressDetail handleInputChange={this.handleInputChange} state={this.state} /> :
                                                            this.state.statusTab === 'renter' ?
                                                                <RenterAddressDetail handleInputChange={this.handleInputChange} state={this.state} /> :
                                                                <AgentAddressDetail handleInputChange={this.handleInputChange} state={this.state} />}
                                                    </React.Fragment>
                                                    :
                                                    <div className='row m-0 p-0'>
                                                        <div className='col-8 pl-0'>
                                                            <p className='fs-16 mt-5'>{t("add_receivable:Address")} <span className="star-button">*</span></p>
                                                            <textarea className="form-control h48" id="mailingAddress" maxLength="256"
                                                                rows={1} style={{ resize: 'none' }}
                                                                name="mailingAddress" value={this.state.mailingAddress}
                                                                onChange={this.handleInputChange}
                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                                required
                                                            />
                                                        </div>

                                                        <div className='col' />

                                                        <div className='col-4 pl-0'>
                                                            <p className='fs-16 mt-5' htmlFor="mailingDistrict">{t("add_receivable:Sub-district")}</p>
                                                            <InputAddress
                                                                address="mailingDistrict"
                                                                value={this.state.mailingDistrict}
                                                                onChange={this.handleInputChange}
                                                                onSelect={this.onSelect}
                                                                mailing={false}
                                                                className="form-control h48"
                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            />

                                                        </div>

                                                        <div className='col-4 pl-0'>
                                                            <p className='fs-16 mt-5' htmlFor="mailingCity">{t("add_receivable:District")}</p>
                                                            <InputAddress
                                                                address="mailingCity"
                                                                value={this.state.mailingCity}
                                                                onChange={this.handleInputChange}
                                                                onSelect={this.onSelect}
                                                                mailing={false}
                                                                className="form-control h48"
                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            />
                                                        </div>
                                                        <div className='col' />

                                                        <div className='col-4 pl-0'>
                                                            <p className='fs-16 mt-5' htmlFor="mailingProvince">{t("add_receivable:Province")}</p>
                                                            <InputAddress
                                                                address="mailingProvince"
                                                                value={this.state.mailingProvince}
                                                                onChange={this.handleInputChange}
                                                                onSelect={this.onSelect}
                                                                mailing={false}
                                                                className="form-control h48"
                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            />

                                                        </div>

                                                        <div className='col-4 pl-0'>
                                                            <p className='fs-16 mt-5' htmlFor="mailingPostalCode">{t("add_receivable:Zip code")}</p>
                                                            <InputAddress
                                                                address="mailingPostalCode"
                                                                value={this.state.mailingPostalCode}
                                                                onChange={this.handleInputChange}
                                                                onSelect={this.onSelect}
                                                                mailing={false}
                                                                className="form-control h48"
                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            />
                                                        </div>

                                                    </div>
                                                }

                                            </section>

                                            {/* ช่องทางติดต่อ */}
                                            <section id='contact' onChange={() => this.setState({ paramLink: "contact" })} onPointerOver={() => this.setState({ paramLink: "contact" })} onPointerMove={() => this.setState({ paramLink: "contact" })}>
                                                <p className='fs-18 pt-5 pb-5 border-bottom'>{t("contact:Contact")}</p>
                                                <div className="row mt-2 pl-5 pr-5">
                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5' htmlFor="phone">{t("add_receivable:Phone number")} {this.state.typeOfContact === 'RESIDENTIAL' && <span className="star-button">*</span>}</p>
                                                        <input className="form-control h48" id="phone" name="phone"
                                                            defaultValue={this.state.taxIdNumber === "0105561063013" ? "" : this.state.phone}
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                        />
                                                    </div>

                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5' htmlFor="email">{t("add_receivable:E-mail")} {this.state.typeOfContact === 'RESIDENTIAL' && <span className="star-button">*</span>}</p>
                                                        <input className="form-control h48" type="email" id="email" name="email"
                                                            defaultValue={this.state.taxIdNumber === "0105561063013" ? '' :this.state.email}
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                        />

                                                    </div>

                                                    <div className='col' />
                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5' htmlFor="mainPhone">{t("add_receivable:Centralize phone number")}</p>
                                                        <input className="form-control h48" id="mainPhone"
                                                            name="mainPhone"
                                                            defaultValue={ this.state.taxIdNumber === "0105561063013" ? this.state.phone : this.state.mainPhone}
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                        />
                                                    </div>
                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5' htmlFor="mainEmail">{t("add_receivable:Centralize E-Mail")}</p>
                                                        <input className="form-control h48" type="email" id="mainEmail"
                                                            name="mainEmail"
                                                            defaultValue={this.state.taxIdNumber === "0105561063013" ? this.state.email : this.state.mainEmail}
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                        />

                                                    </div>
                                                    <div className='col' />

                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5' htmlFor="fax">{t("add_receivable:Fax")}</p>
                                                        <input className="form-control h48" id="fax" name="fax"
                                                            defaultValue={this.state.fax}
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            onChange={this.handleInputChange} />

                                                    </div>
                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5' htmlFor="webSite">{t("add_receivable:Website")}</p>
                                                        <input className="form-control h48" type="text" id="webSite"
                                                            name="webSite"
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            defaultValue={this.state.webSite}
                                                            onChange={this.handleInputChange} />
                                                    </div>
                                                    <div className='col-4 pl-0'></div>
                                                    <div className='col-4 pl-0'></div>
                                                    <div className='col-4 pl-0'></div>
                                                </div>
                                            </section>

                                            {/* ข้อมูลบุคคลที่ติดต่อได้ */}
                                            <section id='peopleContact' onChange={() => this.setState({ paramLink: "peopleContact" })} onPointerOver={() => this.setState({ paramLink: "peopleContact" })} onPointerMove={() => this.setState({ paramLink: "peopleContact" })}>
                                                <p className='fs-18 pt-5 pb-5 border-bottom'>{t("contact:Cotact Address Urgent")}</p>

                                                <div className="row mt-2 pl-5 pr-5">
                                                    <div className='col-2 pl-0'>
                                                        <p className='fs-16 mt-5' htmlFor="prename">{t("contact:Prefix name")}</p>
                                                        <select className='form-control h48'
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            defaultValue={this.state.prefixNameContact} name='prefixNameContact' onChange={this.handleInputChange}>
                                                            <option defaultValue={""}>{t("preFixName:None")}</option>
                                                            <option defaultValue={"Mr."}>{t("preFixName:Mr.")}</option>
                                                            <option defaultValue={"Mrs."}>{t("preFixName:Mrs.")}</option>
                                                            <option defaultValue={"Miss"}>{t("preFixName:Miss")}</option>
                                                            <option defaultValue={"etc"}>{t("preFixName:etc.")}</option>
                                                        </select>
                                                    </div>
                                                    {

                                                        (this.state.prefixNameContact !== "Mr." && this.state.prefixNameContact !== "Mrs." && this.state.prefixNameContact !== "Miss" && this.state.prefixNameContact) &&
                                                        <div className='col-6'>
                                                            <p className='fs-16 mt-5' htmlFor="prefixNameContactEtc">{t("contact:Prefix name")} {t("preFixName:etc.")}</p>
                                                            <input className="form-control h48" type="text" id="prefixNameContactEtc"
                                                                disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                                name="prefixNameContactEtc"
                                                                defaultValue={this.state.prefixNameContactEtc}
                                                                onChange={this.handleInputChange}
                                                                // disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        (this.state.prefixNameContact !== "Mr." && this.state.prefixNameContact !== "Mrs." && this.state.prefixNameContact !== "Miss") ?
                                                            <div className='col' />
                                                            :
                                                            <div className='col-10' />
                                                    }

                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5' htmlFor="firstNameContact">{t("add_receivable:Name")}</p>
                                                        <input className="form-control h48" type="text" id="firstNameContact"
                                                            name="firstNameContact"
                                                            defaultValue={this.state.firstNameContact}
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}

                                                        />
                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <p className='fs-16 mt-5' htmlFor="lastNameContact">{t("add_receivable:Surname")}</p>
                                                        <input className="form-control h48" type="text" id="lastNameContact"
                                                            name="lastNameContact"
                                                            defaultValue={this.state.lastNameContact}
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}

                                                        />
                                                    </div>
                                                    <div className='col' />


                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5' htmlFor="nickNameContact">{t("add_receivable:Nickname")}</p>
                                                        <input className="form-control h48" type="text" id="nickNameContact"
                                                            name="nickNameContact"
                                                            defaultValue={this.state.nickNameContact}
                                                            disabled={this.state.taxIdNumber === "0105561063013"}
                                                            onChange={this.handleInputChange} />
                                                    </div>
                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5' htmlFor="emailContact">{t("add_receivable:Email")}</p>
                                                        <input className="form-control h48" type="text" id="emailContact"
                                                            name="emailContact"
                                                            disabled={this.state.taxIdNumber === "0105561063013"}
                                                            defaultValue={this.state.emailContact}
                                                            onChange={this.handleInputChange} />
                                                        {/* <input className="form-control h48" type="text" id="position"
                                                        name="position"
                                                        defaultValue={this.state.position}
                                                        onChange={this.handleInputChange} /> */}
                                                    </div>
                                                    <div className='col' />
                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5' htmlFor="phoneContact">{t("add_receivable:Phone number")}</p>
                                                        <input className="form-control h48" type="text" id="phoneContact"
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}

                                                            name="phoneContact"
                                                            defaultValue={this.state.phoneContact}
                                                            onChange={this.handleInputChange} />
                                                    </div>


                                                </div>

                                            </section>

                                            {/* ข้อมูลธนาคารของคู่ค้า */}
                                            <section id='bookbank' onChange={() => this.setState({ paramLink: "bookbank" })} onPointerOver={() => this.setState({ paramLink: "bookbank" })} onPointerMove={() => this.setState({ paramLink: "bookbank" })}>
                                                <p className='fs-18 pt-5 pb-5 border-bottom'>{t("contact:Partner bank account")}</p>
                                                <div className='row mt-2 pl-5 pr-5'>
                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5'>{t("contact:Bank")}</p>
                                                        <select name='bankName' id='bankName'
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            onChange={this.handleInputChange}                                                             
                                                            value={checkSpecifyBank ? "อื่นๆ" : this.state.bankName}
                                                            className={`form-control h48 customSelect ImageArrow ${this.state.typeOfContact === 'RESIDENTIAL' || this.state.taxIdNumber === "0105561063013" ? "bg-gray" : ''}`}>
                                                            <option defaultValue={"none"}>{t("preFixName:None")}</option>
                                                            <option defaultValue={"BBL"}>{t("bankName:BANGKOK BANK PUBLIC COMPANY LIMITED")}</option>
                                                            <option defaultValue={"KTB"}>{t("bankName:Krungthai BANK")}</option>
                                                            <option defaultValue={"BAY"}>{t("bankName:Bank of Ayudhya Public Company Limited")}</option>
                                                            <option defaultValue={"KBANK"}>{t("bankName:KASIKORN BANK")}</option>
                                                            <option defaultValue={"TTB"}>{t("bankName:Thanachart Bank")}</option>
                                                            <option defaultValue={"CIMB"}>{t("bankName:CIMB Thai Bank")} </option>
                                                            <option defaultValue={"SCB"}>{t("bankName:Siam Commercial Bank")}</option>
                                                            <option defaultValue={"Standard Chartered Bank"}>{t("bankName:Standard Chartered Bank")}</option>
                                                            <option defaultValue={"UOB"}>{t("bankName:UOB Bank")}</option>
                                                            <option defaultValue={"TISCO"}>{t("bankName:TISCO Bank")}</option>
                                                            <option defaultValue={"KKP"}>{t("bankName:Kiatnakin Phatra Bank")}</option>
                                                            <option defaultValue={"LHB"}>{t("bankName:Land and Houses Bank")}</option>
                                                            <option defaultValue={"ICBC"}>{t("bankName:ICBC (Thai) Bank")}</option>
                                                            <option defaultValue={"TCB"}>{t("bankName:Thai Credit Retail Bank")}</option>
                                                            <option defaultValue={"MEGA ICBC"}>{t("bankName:Mega International Commercial Bank")}</option>
                                                            <option defaultValue={"Bank of China (Thai)"}>{t("bankName:Bank of China (Thai)")}</option>
                                                            <option defaultValue={"Sumitomo Mitsui Banking"}>{t("bankName:Sumitomo Mitsui Bank")}</option>
                                                            <option defaultValue={"CITIBANK, N.A. CIMBT."}>{t("bankName:CITIBANK, N.A. CIMBT.")}</option>                                                            
                                                            <option defaultValue={"other"}>{t("bankName:Other")}</option>
                                                        </select>                                                                                                                                                                                       
                                                            {checkSpecifyBank  && 
                                                            <input 
                                                                type="text"
                                                                name="bankName"
                                                                className="form-control h48 mt-3"
                                                                value={this.state.tempBankName ?? (this.state.bankName === "อื่นๆ" ? "" : this.state.bankName)}
                                                                onChange={this.onChangeSpecifiyBank}
                                                                onBlur={this.handleInputChange}
                                                            />}                                                         
                                                    </div>
                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5'>{t("contact:Account name")}</p>
                                                        <input className="form-control h48" type="text" id="accountName"
                                                            name="accountName"
                                                            defaultValue={this.state.accountName}
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            // required={this.state.bankName && this.state.typeOfSupplier === 'PERSON' ? true : false}
                                                            required={this.state.bankName ? true : false}
                                                        />
                                                    </div>

                                                    <div className='col' />

                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5'>{t("contact:Account number")}</p>
                                                        <input className="form-control h48" type="text" id="accountNumber"
                                                            name="accountNumber"
                                                            defaultValue={this.state.accountNumber}
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            // required={this.state.bankName && this.state.typeOfSupplier === 'PERSON' ? true : false}
                                                            required={this.state.bankName ? true : false}

                                                        />
                                                    </div>
                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5'>{t("contact:Brach number")}</p>
                                                        <input className="form-control h48" type="text" id="bankBranchNumber"
                                                            name="bankBranchNumber"
                                                            defaultValue={this.state.bankBranchNumber}
                                                            onChange={this.handleInputChange}
                                                            disabled={this.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false || this.state.taxIdNumber === "0105561063013"}
                                                            // required={this.state.bankName && this.state.typeOfSupplier === 'PERSON' ? true : false}
                                                            required={this.state.bankName ? true : false}
                                                        />
                                                    </div>

                                                    <div className='col' />


                                                    {/* แนบไฟล์ */}
                                                    <div className='col-6 pl-0 meterWaterFunction'>
                                                        <p className='fs-16 mt-5'>{t("PageDetailProjectInfo:ChooseFile")}</p>
                                                        <p className='text-red fw-300'>  {`${t('fileType:Support',)} ' JPG, PNG' ${t('fileType:and')} ${t('fileType:size not over 10 MB')} ${t('fileType:only')}`} </p>
                                                        <p>

                                                            {
                                                                (!this.state.imageBookBankUpload && !this.state.imageBookBank.length) &&
                                                                <UploadFiles
                                                                    fileList={this.state.imageBookBank}
                                                                    tagIma={true}
                                                                    accept={'application/.jpeg,.png,.jpg'}
                                                                    onUpload={(file) => this.setState({ imageBookBank: file })}
                                                                    disabled={this.state.taxIdNumber === "0105561063013"}
                                                                />
                                                            }

                                                            {
                                                                (this.state.imageBookBankUpload &&
                                                                    this.state.imageBookBank.length < 1) &&
                                                                <React.Fragment>
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/closeImg.png'} onClick={() => this.dateFile("", "", 'imageBookBankUpload')}
                                                                        className='cursor' style={{ position: 'absolute', left: '125px' }} />
                                                                    <Link to="route"
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            window.open(this.state.imageBookBankShow)
                                                                        }}
                                                                        target="blank">
                                                                        <img src={this.state.imageBookBankShow} width={150} height={150} />
                                                                    </Link>
                                                                </React.Fragment>
                                                            }
                                                            {
                                                                this.state.imageBookBank.length > 0 &&
                                                                this.state.imageBookBank?.map((file, inx) => {
                                                                    return (
                                                                        this.state.imageBookBank.length === (inx + 1) &&
                                                                        <React.Fragment>
                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/closeImg.png'} onClick={() => this.dateFile(inx, file, 'imageBookBank')}
                                                                                className='cursor' style={{ position: 'absolute', left: '125px' }} />
                                                                            <Link to="route"
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    window.open(file.viewPtath)
                                                                                }}
                                                                                target="blank">
                                                                                <img src={file.viewPtath} width={150} height={150} />
                                                                            </Link>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </section>

                                            {/* ตั้งค่าครบกำหนดชำระ */} {/* ปิดไว้ก่อน */}
                                            <>
                                                {/* <section id='settingInvoice' onChange={() => this.setState({ paramLink: "settingInvoice" })} onPointerOver={() => this.setState({ paramLink: "settingInvoice" })} onPointerMove={() => this.setState({ paramLink: "settingInvoice" })}>
                                                <p className='fs-18 pt-5 pb-5 border-bottom'>{t("contact:Payment due setting")}</p>
                                                <div className='row mt-2 pl-5 pr-5'>
                                                    <div className='col-4 pl-0'>
                                                        <p className='fs-16 mt-5'>ครบกำหนดชำระใบแจ้งหนี้</p>
                                                        <select
                                                            className='form-control h48'
                                                            defaultValue={this.state.typeOfSetting}
                                                            name='typeOfSetting'
                                                            onChange={this.handleInputChange}>
                                                            <option defaultValue={""}>เลือก</option>
                                                            <option defaultValue={"default"}>ตามการตั้งค่าของคู่ค้า</option>
                                                            <option defaultValue={"x_after_out"}>X วันหลังวันที่ออกเอกสาร</option>
                                                            <option defaultValue={"x_next_month"}>วันที่ X ของเดือนถัดไป</option>
                                                            <option defaultValue={"x_after_due"}>X วันหลังที่ครบกำหนด</option>
                                                            <option defaultValue={"lastmonth_out"}>สิ้นเดือนของวันที่ออกเอกสาร</option>
                                                            <option defaultValue={"lastmonth_next"}>สิ้นเดือนของเดือนถัดไป</option>
                                                        </select>
                                                    </div>

                                                    <div className='col-4 pl-0'>
                                                        {
                                                            <React.Fragment>
                                                                <p className='fs-16 mt-5'>
                                                                    {
                                                                        this.state.typeOfSetting === "x_after_out" ? "X วันหลังวันที่ออกเอกสาร" :
                                                                            this.state.typeOfSetting === "x_next_month" ? "วันที่ X ของเดือนถัดไป" :
                                                                                this.state.typeOfSetting === "x_after_due" ? "X วันหลังที่ครบกำหนด" :
                                                                                    this.state.typeOfSetting === "lastmonth_out" ? "สิ้นเดือนของวันที่ออกเอกสาร" :
                                                                                        this.state.typeOfSetting === "lastmonth_next" ? "สิ้นเดือนของเดือนถัดไป" :
                                                                                            this.state.typeOfSetting === "default" ? "ระบุวันที่ครบกำหนดชำระ" : ""
                                                                    }
                                                                </p>
                                                                {
                                                                    this.state.typeOfSetting?.toLowerCase() === "default" ?
                                                                        <input type={'date'} onChange={this.handleInputChange}
                                                                            name='settingDate' defaultValue={new Date(this.state.settingDate)} className='form-control h48 w150' />
                                                                        :
                                                                        this.state.typeOfSetting?.toLowerCase() ?
                                                                            <div>
                                                                                <select
                                                                                    className='form-control h48 w150'
                                                                                    defaultValue={1}
                                                                                    name='settingNumberDate'
                                                                                    onChange={this.handleInputChange}>
                                                                                    <option defaultValue={1}>1</option>
                                                                                    <option defaultValue={2}>2</option>
                                                                                    <option defaultValue={3}>3</option>
                                                                                    <option defaultValue={4}>4</option>
                                                                                    <option defaultValue={5}>5</option>
                                                                                    <option defaultValue={6}>6</option>
                                                                                    <option defaultValue={7}>7</option>
                                                                                    <option defaultValue={8}>8</option>
                                                                                    <option defaultValue={9}>9</option>
                                                                                    <option defaultValue={10}>10</option>
                                                                                    <option defaultValue={11}>11</option>
                                                                                    <option defaultValue={12}>12</option>
                                                                                    <option defaultValue={13}>13</option>
                                                                                    <option defaultValue={14}>14</option>
                                                                                    <option defaultValue={15}>15</option>
                                                                                </select>
                                                                                {
                                                                                    this.state.typeOfSetting === "x_after_out" ? "วัน" : ''
                                                                                }
                                                                            </div>

                                                                            :
                                                                            ""
                                                                }


                                                            </React.Fragment>
                                                        }

                                                    </div>

                                                </div>
                                            </section> */}
                                            </>

                                            <div className="col-8">
                                                <p className='fs-18 pt-5 pb-5 border-bottom'>{t("add_receivable:Note")}</p>
                                                <textarea className="form-control" id="note" maxLength="256" name="note"
                                                    disabled={this.state.taxIdNumber === "0105561063013"}
                                                    defaultValue={this.state.note}
                                                    onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.check_perm &&
                                <div style={{ position: 'fixed', bottom: 24, width: '80%' }}>
                                    <div className="row mt-2">
                                        <div className="col text-right">
                                            <Link to={this.props.backPage}>
                                                <button type="button" className="btn btn-secondary add mr-2 ">
                                                    {t("add_receivable:Cancel")}
                                                </button>
                                            </Link>
                                            {/* <button type="submit" className="btn btn-primary add " onClick={this.onSubmit}> */}
                                            <button type="submit" className="btn btn-primary add " disabled={this.state.taxIdNumber === "0105561063013"}>
                                                {this.state.loading &&
                                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                                }
                                                {t("add_receivable:Save")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </form >
                    </div >
                }
            </Translation>
        )
    }
}

export default ContactCreateUpdateFormNew;
