/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoiceStatus = "ACTIVE" | "DRAFT" | "OVERDUE" | "PAID" | "PARTIAL_PAYMENT" | "VOID" | "WAITING_FIX" | "%future added value";
export type InvoiceTaxType = "EXCLUDE_TAX" | "INCLUDE_TAX" | "NO_TAX" | "%future added value";
export type QuickMassageTypeNote = "INVOICE" | "PAYMENT_QUOTATION" | "QUOTATION" | "RECEIVED" | "%future added value";
export type receiveCreateFormSirirajQueryVariables = {|
  statusList?: ?$ReadOnlyArray<?string>,
  contactIdIn?: ?$ReadOnlyArray<?string>,
  periodDate?: ?any,
  periodList?: ?$ReadOnlyArray<?any>,
  type?: ?string,
|};
export type receiveCreateFormSirirajQueryResponse = {|
  +invoiceViewer: ?{|
    +allInvoiceByStatus: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +total: number,
          +issuedDate: any,
          +dueDate: any,
          +status: InvoiceStatus,
          +taxType: InvoiceTaxType,
          +contact: {|
            +id: string,
            +name: string,
            +firstName: string,
            +lastName: string,
          |},
          +allAmountNotPaid: ?number,
        |}
      |}>,
    |}
  |},
  +quickMassageViewer: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +massageNote: ?string,
        +typeNote: QuickMassageTypeNote,
        +creator: ?string,
      |}
    |}>
  |},
  +nowDateReceive: ?any,
|};
export type receiveCreateFormSirirajQuery = {|
  variables: receiveCreateFormSirirajQueryVariables,
  response: receiveCreateFormSirirajQueryResponse,
|};
*/


/*
query receiveCreateFormSirirajQuery(
  $statusList: [String]
  $contactIdIn: [String]
  $periodDate: Date
  $periodList: [Date]
  $type: String
) {
  invoiceViewer {
    allInvoiceByStatus(statusList: $statusList, contactIdIn: $contactIdIn, periodDate: $periodDate, periodList: $periodList) {
      totalCount
      edges {
        node {
          id
          docNumber
          total
          issuedDate
          dueDate
          status
          taxType
          contact {
            id
            name
            firstName
            lastName
          }
          allAmountNotPaid
        }
      }
    }
    id
  }
  quickMassageViewer(typeNote: $type) {
    edges {
      node {
        id
        massageNote
        typeNote
        creator
      }
    }
  }
  nowDateReceive
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactIdIn"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "periodDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "periodList"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "statusList"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "contactIdIn",
      "variableName": "contactIdIn"
    },
    {
      "kind": "Variable",
      "name": "periodDate",
      "variableName": "periodDate"
    },
    {
      "kind": "Variable",
      "name": "periodList",
      "variableName": "periodList"
    },
    {
      "kind": "Variable",
      "name": "statusList",
      "variableName": "statusList"
    }
  ],
  "concreteType": "InvoiceNodeConnection",
  "kind": "LinkedField",
  "name": "allInvoiceByStatus",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvoiceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "issuedDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dueDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ContactNode",
              "kind": "LinkedField",
              "name": "contact",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allAmountNotPaid",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "typeNote",
      "variableName": "type"
    }
  ],
  "concreteType": "QuickMassageNodeConnection",
  "kind": "LinkedField",
  "name": "quickMassageViewer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickMassageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuickMassageNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "massageNote",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "typeNote",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creator",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nowDateReceive",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "receiveCreateFormSirirajQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "receiveCreateFormSirirajQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "eaec5420885822077947a9ff6796765f",
    "id": null,
    "metadata": {},
    "name": "receiveCreateFormSirirajQuery",
    "operationKind": "query",
    "text": "query receiveCreateFormSirirajQuery(\n  $statusList: [String]\n  $contactIdIn: [String]\n  $periodDate: Date\n  $periodList: [Date]\n  $type: String\n) {\n  invoiceViewer {\n    allInvoiceByStatus(statusList: $statusList, contactIdIn: $contactIdIn, periodDate: $periodDate, periodList: $periodList) {\n      totalCount\n      edges {\n        node {\n          id\n          docNumber\n          total\n          issuedDate\n          dueDate\n          status\n          taxType\n          contact {\n            id\n            name\n            firstName\n            lastName\n          }\n          allAmountNotPaid\n        }\n      }\n    }\n    id\n  }\n  quickMassageViewer(typeNote: $type) {\n    edges {\n      node {\n        id\n        massageNote\n        typeNote\n        creator\n      }\n    }\n  }\n  nowDateReceive\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3de2fe08e4de0d73d0ee9e9d365b74cd';

module.exports = node;
