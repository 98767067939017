import {commitMutation} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
  
  
  const mutation = graphql`
  mutation createAndUpdateManagerMutation($input: CreateAndUpdateManagerInput!){
    createAndUpdateManager(input: $input){
        ok
        message
    }
  }
  `;
  
  export default function CreateAndUpdateManager(variables, callback, error_callback) {
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response) => {
          callback(response.createAndUpdateManager.ok)
        },
        onError: (err) => {
            error_callback(err);
        },
      },
    )
  }
  