const $ = window.jQuery;

const NavigationMenuSetup = () => {
    let menu = $('.col-12>ul>li');
    let max_menu = menu.length;
    let menu_per_column = Math.ceil(max_menu / 2);
    let menu_body = $('.container-fluid>.center-navigation>.col-12:eq(1)');
    menu_body.append("<ul></ul>");
    let second_menu = menu_body.find("ul").eq(1);
    for (let i = 0; i <= menu_per_column; i++) {
        second_menu.append(menu.eq(i + menu_per_column))
    }
};

export default NavigationMenuSetup;
