import { commitMutation } from 'react-relay'
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";


const mutation = graphql`
mutation changeStatusStaffMutation ($input: CreateUpdateStaffInput!) {
    createUpdateStaff (input: $input) {
        ok
        message
    }
}
`;

export default function CreateUpdateStaff(data , callback , error_callback) {
    let variables = {
        "input": {
            "projectStaff": {
                "id": data.id, 
                "status": false,
                "note": data.note,
                "endWork": data.endWork,
                "projectStaffDocument": []
            }
        } 
    }

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
