import React from "react";
import ContractExpenseTopMenuNavigation from "./contractExpenseTopMenuNavigation";
import DatePickerNoti from "../../finance/depositInterest/datePickerNoti";
import _ from "lodash";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import numberWithCommas from "../../../libs/numberWithComma";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import ComponentPagination from "../../../libs/componentPagination";
import Loading from "../../../libs/loading";
import Pagination from "../../../libs/newPagination";
import "./style/contractExpense.scss";
import i18next from "i18next";

const query = graphql`
  query contractExpenseTableQuery(
    $status: String
    $search: String
    $startDate: Date
    $endDate: Date
    $first: Int
    $last: Int
  ) {
    allContractExpense(
      status: $status
      search: $search
      startDate: $startDate
      endDate: $endDate
      first: $first
      last: $last
    ) {
      edges {
        node {
          id
          docNumber
          status
          issuedDate
          renewer
          renewDate
          voidRemark
          voidDate
          voider
          added
          purchaseOrderes {
            id
            docNumber
            issuedDate
            status
            approver
            procurementForm {
              id
              docNumber
              issuedDate
              creditor {
                refNumber
                name
              }
              chartOfAccount {
                id
                name
                nameEn
                chartOfAccountCode
              }
              startContractDate
              endContractDate
              total
            }
          }
        }
      }
      totalCount
    }
  }
`;

export default class contractExpenseTable extends ComponentPagination {
  constructor(props) {
    super(props);

    this.state = {
      allContractExpenseList: [],
      startDate: null,
      endDate: null,
      textSearch: "",
      loading: false,
      query: query,
    };
  }

  componentWillMount() {
    this.goFirst();
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.status !== this.props.status ||
      prevState.first !== this.state.first ||
      prevState.last !== this.state.last
    ) {
      this.getData();
    }
  }

  getData = () => {
    this.setState({ loading: true });

    let vilable = {
      status: this.props.status === "all" ? "" : this.props.status,
      search: this.state.textSearch,
      first: this.state.first,
      last: this.state.last,
    };
    
    if (this.state.startDate) {
      vilable.startDate = format(this.state.startDate, "YYYY-MM-DD");
    }
    if (this.state.endDate) {
      vilable.endDate = format(this.state.endDate, "YYYY-MM-DD");
    }

    fetchQuery(environment, query, vilable).then((response) => {
      this.setState({
        allContractExpenseList: response.allContractExpense,
        loading: false,
      });
    });

    this.props.parentCallback(this.props.status, this.state.startDate? format(this.state.startDate, "YYYY-MM-DD"): null, this.state.endDate ? format(this.state.endDate, "YYYY-MM-DD"): null)
  };

  getStatus = (statusInput) => {
    let valueResule = "";
    let colorStatus = "";

    switch (statusInput) {
      case "WAIT":
        valueResule = i18next.t("contract_expese_list:Pending");
        colorStatus = "statusYellow";
        break;
      case "IN_PROGRESS":
        valueResule = i18next.t("contract_expese_list:In Process")
        colorStatus = "statusGreen";
        break;
      case "NEAR_END":
        valueResule = i18next.t("contract_expese_list:Near Expiry");
        colorStatus = "statusYellow";
        break;
      case "RENEW":
        valueResule = i18next.t("contract_expese_list:Renew");
        colorStatus = "statusGreen";
        break;
      case "NOT_RENEW":
        valueResule = i18next.t("contract_expese_list:Don't Renew");
        colorStatus = "statusRed";
        break;
      case "VOID":
        valueResule = i18next.t("contract_expese_list:Void");
        colorStatus = "statusRed";
        break;
    }

    return (
      <div className={colorStatus}>
        <span>{valueResule}</span>
      </div>
    );
  };

  handleChange = (even) => {
    let { name, value } = even.target;

    this.setState({ [name]: value });
  };

  handleSearch = () => {
    this.getData();
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.getData();
    }
  };

  render() {
    return (
      <div id="contractExpenseTable">
        <ContractExpenseTopMenuNavigation />
        <div className="row my-5">
          <div className="col d-flex justify-content-end">
            <div className="d-flex align-items-center">
              <h5 className="m-0">{i18next.t("AllFilter:Date")}:</h5>
              <div className="mx-4" style={{ width: 160 }}>
                <DatePickerNoti
                  selected={this.state.startDate}
                  name="startDate"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <h5 className="m-0">{i18next.t("AllFilter:to")}:</h5>
              <div className="mx-4" style={{ width: 160 }}>
                <DatePickerNoti
                  selected={this.state.endDate}
                  name="endDate"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="calendars-wrapper">
              <form>
                <input
                  type="text"
                  placeholder={i18next.t("AllFilter:Search")}
                  value={this.state.textSearch}
                  onChange={(event) =>
                    this.setState({ textSearch: event.target.value })
                  }
                  style={{
                    fontFamily: "light",
                    height: "48px",
                    width: "300px",
                  }}
                  className="form-control"
                  onKeyDown={this.handleKeyDown}
                />
              </form>
              <div
                className="calendars float-right"
                style={{ paddingRight: 25 }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/icons/search-outline.png"
                  }
                  alt="calendars"
                  style={{ width: "17.81px", height: "17.81px" }}
                  onClick={this.handleSearch}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <div className="fede-up">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th>{i18next.t("contract_expese_list:Contract No.")}</th>
                          <th>{i18next.t("contract_expese_list:Date")}</th>
                          <th>{i18next.t("contract_expese_list:Creditor code")}</th>
                          <th>{i18next.t("contract_expese_list:Counterparty company")}</th>
                          <th>{i18next.t("contract_expese_list:Category")}</th>
                          <th>{i18next.t("contract_expese_list:Start date of contract")}</th>
                          <th>{i18next.t("contract_expese_list:End date of contract")}</th>
                          {(this.props.status === "all" ||
                            this.props.status === "wait" ||
                            this.props.status === "in_progress" ||
                            this.props.status === "near_end" ||
                            this.props.status === "renew") && (
                            <React.Fragment>
                              <th>{i18next.t("contract_expese_list:Fee")}</th>
                              <th>{i18next.t("contract_expese_list:Recorded by")}</th>
                            </React.Fragment>
                          )}
                          {(this.props.status === "wait" ||
                            this.props.status === "in_progress" ||
                            this.props.status === "near_end" ||
                            this.props.status === "renew") && (
                            <th>{i18next.t("contract_expese_list:Date of recording")}</th>
                          )}
                          {this.props.status === "all" && (
                            <React.Fragment>
                              <th>{i18next.t("contract_expese_list:Ref PO.")}</th>
                              <th>{i18next.t("contract_expese_list:Status")}</th>
                            </React.Fragment>
                          )}
                          {this.props.status === "not_renew" && (
                            <React.Fragment>
                              <th>{i18next.t("contract_expese_list:Recorder")}</th>
                              <th>{i18next.t("contract_expese_list:Reason for not renewing the contract")}</th>
                            </React.Fragment>
                          )}
                          {this.props.status === "void" && (
                            <React.Fragment>
                              <th>{i18next.t("contract_expese_list:Cancellation date")}</th>
                              <th>{i18next.t("contract_expese_list:Canceller")}</th>
                              <th>{i18next.t("contract_expese_list:Reason for cancellation")}</th>
                            </React.Fragment>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(
                          this.state.allContractExpenseList.edges,
                          (item, indexItem) => {
                            return (
                              <tr key={indexItem}>
                                <td width="125">
                                  <Link
                                    to={`/accounting/expense/contract_expese/updateFormContractExpense/${item.node.docNumber}/${item.node.id}/${item.node.status}`}
                                  >
                                    {item.node.docNumber}
                                  </Link>
                                </td>
                                <td>
                                  {format(item.node.issuedDate, "DD/MM/YYYY")}
                                </td>
                                <td>
                                  {
                                    item.node.purchaseOrderes.procurementForm
                                      .creditor.refNumber
                                  }
                                </td>
                                <td>
                                  {
                                    item.node.purchaseOrderes.procurementForm
                                      .creditor.name
                                  }
                                </td>
                                <td>
                                  {item.node.purchaseOrderes.procurementForm
                                    .chartOfAccount
                                    ? item.node.purchaseOrderes.procurementForm
                                        .chartOfAccount.name
                                    : "-"}
                                </td>
                                <td>
                                  {format(
                                    item.node.purchaseOrderes.procurementForm
                                      .startContractDate,
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  {format(
                                    item.node.purchaseOrderes.procurementForm
                                      .endContractDate,
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                {(this.props.status === "all" ||
                                  this.props.status === "wait" ||
                                  this.props.status === "in_progress" ||
                                  this.props.status === "near_end" ||
                                  this.props.status === "renew") && (
                                  <React.Fragment>
                                    <td>
                                      {numberWithCommas(
                                        item.node.purchaseOrderes
                                          .procurementForm.total
                                      )}
                                    </td>
                                    <td>
                                      {item.node.purchaseOrderes.approver}
                                    </td>
                                  </React.Fragment>
                                )}
                                {(this.props.status === "wait" ||
                                  this.props.status === "in_progress" ||
                                  this.props.status === "near_end" ||
                                  this.props.status === "renew") && (
                                  <td>
                                    {format(item.node.added, "DD/MM/YYYY")}
                                  </td>
                                )}
                                {this.props.status === "all" && (
                                  <React.Fragment>
                                    <td width="125">
                                      {item.node.purchaseOrderes.docNumber}
                                    </td>
                                    <td>{this.getStatus(item.node.status)}</td>
                                  </React.Fragment>
                                )}
                                {this.props.status === "not_renew" && (
                                  <React.Fragment>
                                    <td>{item.node.voider}</td>
                                    <td>{item.node.voidRemark}</td>
                                  </React.Fragment>
                                )}
                                {this.props.status === "void" && (
                                  <React.Fragment>
                                    <td>
                                      {format(item.node.voidDate, "DD/MM/YYYY")}
                                    </td>
                                    <td>{item.node.voider}</td>
                                    <td>{item.node.voidRemark}</td>
                                  </React.Fragment>
                                )}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <Pagination
                changePage={this.changePage}
                first={this.state.first}
                last={this.state.last}
                totalCount={this.state.allContractExpenseList.totalCount}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
