import React from "react";
import { Translation } from "react-i18next";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import ReceiptDepositReportTable from "./receiptDepositMovementReportTable";
import ExportReceiptDepositMovement from "./server_export";
import _ from "lodash";
import { Redirect } from "react-router";
import Loading from '../../../../libs/loading';
import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from '../../../../components/BackBtn/indexBack';
import i18next from "i18next";

const query = graphql`
  query receiptDepositMovementReportQuery(
    $first: Int
    $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $newEndDate: Date
    $search: String
    $payment_channel: String
    $status: String
    $contact: String
    $order: String
  ) {
    receiptDepositViewer {
      allReceiptDeposit(
        first: $first
        last: $last
        startDate: $start_date
        endDate: $end_date
        search: $search
        statusNew: $status
        paymentChannel: $payment_channel
        contact: $contact
        order: $order
        report:true
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            docNumber
            issuedDate
            total
            status
            contact {
              refNumber
              name
              residential {
                name
              }
              firstName
              lastName
              typeOfContact
            }
            receiptDepositTransaction {
              edges {
                node {
                  id
                  balance
                  chartOfAccount {
                    chartOfAccountCode
                    name
                  }
                  description
                }
              }
            }
            receiptDepositTransactionUsed(useDate_Lte:$newEndDate) {
              edges {
                node {
                  id
                  amount
                  transaction {
                    description
                    invoice {
                      issuedDate
                      docNumber
                    }
                  }
                  receiveTransaction {
                    receive {
                      issuedDate
                      docNumber
                    }
                    transaction {
                      description
                      invoice {
                        issuedDate
                        docNumber
                      }
                    }
                  }
                  otherExpense {
                      id
                      docNumber
                      name
                      issuedDate
                      otherExpenseTransaction {
                        edges {
                            node {
                              id
                              description
                            }
                        }
                      }
                  }

                
                }
              }
            }
            bankAccountTransaction {
              totalCount
            }
            cashTransaction {
              totalCount
            }
            chequeDeposit {
              totalCount
            }
          }
        }
      }
      summaryReceiptDeposit(
        startDate: $start_date
        endDate: $end_date
        search: $search
        statusNew: $status 
        paymentChannel: $payment_channel
        contact: $contact
      )
    }
  }
`;

class ReceiptDepositMovementReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);

    this.state.current_date = new Date();
    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.min_date = min_date;
    this.state.customer_type = "";
    this.state.search = "";
    this.state.search_input = "";
    this.state.status = "";
    this.state.temp_status = "";
    this.state.contact = "";
    this.state.payment_channel = "";

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "receiptDepositMovementReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      status: this.state.temp_status,
    });
    super.goFirst()
  }

  render() {

    if (this.props.customerList.length > 0) {
      return (
        <React.Fragment>
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-receivable"}
                  LinkText={i18next.t("receivable_dashboard:Advance Payment Movement Report")}
                  boxHtmlText={
                    <div className="col">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_receivable_print",
                      }) && (

                          <ExportReceiptDepositMovement
                            state={this.state}
                            customerList={this.props.customerList}
                          />
                        )}
                    </div>
                  }
                />
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          // maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          // minDate={this.state.min_date}
                          maxDate={this.state.end_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          {/*<ContactReportList handleChange={this.handleChange}*/}
                          {/*                   customer_type={this.state.customer_type}*/}
                          {/*                   contact={this.state.contact}/>*/}
                          {/*<div className="col-md-4">*/}
                          {/*    <div className="form-group">*/}
                          {/*        <label>วิธีชำระเงิน</label>*/}
                          {/*        <select name="payment_channel" onChange={this.handleChange}*/}
                          {/*                className="form-control dropdown-custom-arrow">*/}
                          {/*            <option value="">ทั้งหมด</option>*/}
                          {/*            <option value="cash">เงินสด</option>*/}
                          {/*            <option value="bank">เงินโอน</option>*/}
                          {/*            <option value="cheque">เช็ค</option>*/}
                          {/*        </select>*/}
                          {/*    </div>*/}
                          {/*</div>*/}
                          <div className="col-md-4 border-right">
                            <div className="form-group">
                              <label>{i18next.t("receipt_deposit:Status")}</label>
                              <select
                                name="temp_status"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("receipt_deposit:All")}</option>
                                <option value="active">{i18next.t("receipt_deposit:Active")}</option>
                                <option value="use_all">
                                {i18next.t("receipt_deposit:Use all")}
                                </option>
                                <option value="use_partial">
                                {i18next.t("receipt_deposit:Use partial")}
                                </option>
                                <option value="void">{i18next.t("receipt_deposit:Void")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{ use_cache: false }}
                    variables={{
                      first: this.state.first,
                      last: this.state.last,
                      start_date: this.state.start_date,
                      end_date: this.state.end_date,
                      newEndDate: this.state.end_date.toISOString().slice(0, 10),
                      search: this.state.search,
                      status: this.state.status,
                      payment_channel: this.state.payment_channel,
                      contact: this.props.customerList,
                      order: "doc_number",
                    }}
                    render={({ error, props }) => {  
                      if (props) {
                        return (
                          <React.Fragment>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table className="table table-hover">
                                      <thead className="thead-light">
                                        <tr>
                                          <th className="text-center">{i18next.t("ReceiptDepositMovementReport:Order")}</th>
                                          <th className="text-center">{i18next.t("ReceiptDepositMovementReport:Number")}</th>
                                          <th className="text-center">{i18next.t("ReceiptDepositMovementReport:Date of Issue")}</th>
                                          <th>{i18next.t("ReceiptDepositMovementReport:Item")}</th>
                                          <th>{i18next.t("ReceiptDepositMovementReport:Description")}</th>
                                          <th className="text-right">{i18next.t("ReceiptDepositMovementReport:Amount")}</th>
                                          <th className="text-right">
                                          {i18next.t("ReceiptDepositMovementReport:Amount Received")}
                                          </th>
                                          <th className="text-right">
                                          {i18next.t("ReceiptDepositMovementReport:Remaining Amount")}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <ReceiptDepositReportTable
                                          props={props}
                                          state={this.state}
                                        />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={
                                  props.receiptDepositViewer.allReceiptDeposit
                                    .totalCount
                                }
                              />
                            </div>
                          </React.Fragment>
                        );
                      } else {
                        return <Loading />
                      }
                    }}
                  />
                </div>
              </div>
            }
          </Translation>
        </React.Fragment>
      );
    }
    return (
      <Redirect to="/accounting/report/account-receivable/receipt_deposit_movement/select-customer" />
    );
  }
}

export default ReceiptDepositMovementReport;
