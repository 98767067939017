import React from "react";
import { QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import { format } from "date-fns";
import ComponentPagination from "../../../libs/componentPagination";
import thLocale from "date-fns/locale/th";
import Pagination from "../../../libs/newPagination";
import numberWithComma from "../../../libs/numberWithComma";
import "./style/chequeListTable.scss";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Translation } from "react-i18next";
import { Tag } from '../../../components/Text/Text'
import Loading from "../../../libs/loading"
import i18next from "i18next";

class ChequeTransactionListTable extends ComponentPagination {

    constructor(props) {
        super(props)

        this.getList = this.getList.bind(this)
        this.getListAll = this.getListAll.bind(this)
        // this.getBank = this.getBank.bind(this)

    }

    sortList(chequeList, status) {
        if (status === 'clearing') {
            return _.sortBy(chequeList, 'node.docNumber').reverse()
        } else return chequeList
    }

    async getListAll(props) {
        await this.getAllList(props.allChequeTransactions.edges)
        this.props.getListPrint(this.state.checkList)
    }

    async getList(cheque_id) {
        await this.appendToCheckList(cheque_id)
        this.props.getListPrint(this.state.checkList)

    }

    getBank = (bank) => {
        switch (bank) {
            case "ธนาคารกรุงศรีอยุธยา":
                return "BAY"
            case "ธนาคารกรุงเทพ":
                return "BBL"
            case "ธนาคารกรุงไทย":
                return "KTB"
            case "ธนาคารกสิกรไทย":
                return "KBANK"
            case "ธนาคารซีไอเอ็มบีไทย":
                return "CIMB"
            case "ธนาคารทหารไทย":
                return "TMB"
            case "ธนาคารทิสโก้":
                return "TISCO"
            case "ธนาคารธนชาต":
                return "TBANK"
            case "ธนาคารยูโอบี":
                return "UOB"
            case "ธนาคารเกียรตินาคิน":
                return "KKP"
            case "ธนาคารไทยพาณิชย์":
                return "SCB"
            case "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร":
                return "BAAC"
            case "ธนาคารแลนด์ แอนด์ เฮ้าส์":
                return "LHFG"
            case "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)":
                return "STAN"
            case "ธนาคารออมสิน":
                return "GSB"
            case "ธนาคารอาคารสงเคราะห์":
                return "GHB"
            case "ธนาคารอิสลามแห่งประเทศไทย":
                return "ISBT"
            case "ธนาคารทีเอ็มบีธนชาต":
                return "TTB"
            default:
                return "OTHER"
        }
    }

    getStatus = (status = '', historical = '') => {
        if(historical) {
            switch (status.toLocaleLowerCase()) {
                case "wait":
                    return { text: "Pending Clearance/Printing of Cheque", color: '#FFBD2D' };
                case "clearing":
                    return { text: "Passed Through Cheque Payment", color: '#68DD6E' };
                case "void":
                    return { text: "Cheque Returned", color: '#E14558' };
                case "cancel":
                    return { text: "Cheque Pending Clearance", color: '#E14558' };
                case "void_historical":
                    return { text: "Cancel Incoming Cheque", color: '#E14558' };
                default:
                    return "Pending Clearance/Printing of Cheque";
            }
        } else {
            switch (status.toLocaleLowerCase()) {
                case "wait":
                    return { text: "Pending Clearance/Printing of Cheque", color: '#FFBD2D' };
                case "clearing":
                    return { text: "Passed Through Cheque Payment", color: '#68DD6E' };
                case "void":
                    return { text: "Cheque Returned", color: '#E14558' };
                case "bide":
                    return { text: "Cheque Pending Clearance", color: '#4EB4A9' };
                case "delete":
                    return { text: "Remove", color: '#E14558' };
                case "cancel":
                    return { text: "Cancel Passed Through Cheque Payment", color: '#E14558' };
                default:
                    return "Pending Clearance/Printing of Cheque";
            }
        }
    }



    render() {
        return (
            <React.Fragment>
                <div className="row" id="cheque-list">
                    <div className="col">
                        <QueryRenderer
                            environment={environment}
                            query={this.props.query}
                            variables={{
                                status: this.props.status === 'all' || this.props.status === 'historical' ?
                                        '' 
                                        : 
                                        (this.props.status === 'cancelhistorical' ? 
                                        'void_historical'
                                        :
                                        this.props.status),
                                first: this.state.first,
                                last: this.state.last,
                                search: this.props.search,
                                start_date: this.props.start_date,
                                end_date: this.props.end_date,
                                historical: this.props.status === 'historical' || this.props.status === 'cancelhistorical' ? true : '',
                            }}
                            render={({ error, props }) => {
                                if (error) {
                                    return <div>{error.message}</div>;
                                } else if (props) {
                                    return <React.Fragment>
                                        <Translation>
                                            {t =>
                                                <div className="table-responsive fade-up card">
                                                    <table className="table table-hover">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                {this.props.status === 'clearing' || this.props.status === 'all' ||  this.props.status === 'cancel' || this.props.status === 'historical' || this.props.status === 'cancelhistorical'?
                                                                    <th className="text-center" style={{ minWidth: 130 }}>{i18next.t("ChequeTransaction:Cheque Clearance Number")}</th> :''
                                                                }
                                                                <th className="text-center" style={{ minWidth: 100 }} >{i18next.t("ChequeTransaction:Chequebook Number")}</th>
                                                                <th className="text-center">{i18next.t("ChequeTransaction:Cheque Number")}</th>
                                                                {this.props.status === 'clearing' &&
                                                                    <th className="text-center">{i18next.t("ChequeTransaction:Cheque Clearance Date")}</th>
                                                                }
                                                                <th className="text-center">{i18next.t("ChequeTransaction:Cheque Date")}</th>
                                                                <th className="text-center">{i18next.t("ChequeTransaction:Bank")}</th>
                                                                <th >{i18next.t("ChequeTransaction:Pay to")}</th>
                                                                <th style={{ minWidth: 150 }} >{i18next.t("ChequeTransaction:Reference Document Number")}</th>
                                                                <th >{i18next.t("ChequeTransaction:Details")}</th>
                                                                <th className="text-center" style={{ minWidth: 100 }}>{i18next.t("ChequeTransaction:Amount (Baht)")}</th>
                                                                {this.props.status === 'all' || this.props.status === 'historical' ?
                                                                    <th className="text-center" style={{ minWidth: 140 }}>{i18next.t("ChequeTransaction:Status")}</th> : ''
                                                                }
                                                        
                                                                {this.props.status === 'void' &&
                                                                    <th className="text-center">{i18next.t("ChequeTransaction:Recording Date")}</th>
                                                                }
                                                                {this.props.status === 'void' &&
                                                                    <th className="text-center">{i18next.t("ChequeTransaction:Recorder")}</th>
                                                                }
                                                                {this.props.status === 'void' &&
                                                                    <th className="text-center">{i18next.t("ChequeTransaction:Reason for Loss")}</th> 
                                                                }
                                                                {this.props.status === 'cancel' || this.props.status === 'cancelhistorical' ?
                                                                    <th className="text-center">{i18next.t("ChequeTransaction:Cancellation Reason")}</th> : ""
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {props.allChequeTransactions?.edges.map((cheque) => {
                                                                let noNumber = cheque.node.chequeDetails?.noNumber || cheque.node.chequeDetailsCustom
                                                                return (
                                                                    <tr key={cheque.node.id}>
                                                                        {this.props.status === 'clearing' || this.props.status === 'all' ||  this.props.status === 'cancel' || this.props.status === 'historical' || this.props.status === 'cancelhistorical' ? 
                                                                            <td className="text-center" >{cheque?.node?.docNumber || '-'}</td> : ''
                                                                        }
                                                                        <td className="text-center">{noNumber || '-'}</td>
                                                                        <td className="text-center"> {cheque.node.status === "CANCEL" ? cheque.node.chequeNumber : <Link to={"/accounting/finance/cheque/clearing/view/" + cheque.node.id} >{cheque.node.chequeNumber}</Link> }</td>
                                                                        {this.props.status === 'clearing' &&
                                                                            <td className="text-center">{format(cheque.node.depositDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                        }
                                                                        <td className="text-center">{format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale })}</td> 
                                                                        <td className="text-center">{this.getBank(cheque.node.bankAccount?.bankName)}</td>
                                                                        <td >{cheque.node.payName}</td>
                                                                        <td >{cheque.node.refNumber}</td>
                                                                        <td >{cheque.node.description}</td>
                                                                        <td className="text-center">{numberWithComma(cheque.node.price)}</td>
                                                                        {this.props.status === 'all' || this.props.status === 'historical' ?
                                                                            <td className="text-center"><Tag color={this.getStatus(cheque.node.status, cheque.node.historical).color} >{i18next.t("ChequeTransaction:"+this.getStatus(cheque.node.status, cheque.node.historical).text)}</Tag></td> : ""
                                                                        }
                                                                        {this.props.status === 'void' && 
                                                                            <td className="text-center">{format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                        }
                                                                        {this.props.status === 'void' &&
                                                                            <td className="text-center">{cheque.node.juristic.firstName + ' ' + cheque.node.juristic.lastName}</td>
                                                                        }
                                                                        {(this.props.status === 'void' || this.props.status === 'cancel' || this.props.status === 'cancelhistorical') &&
                                                                            <td className="text-center">{cheque.node.note}</td>
                                                                        }
                                                                    </tr>
                                                                )}
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>}
                                        </Translation>
                                        <div className="row">
                                            <Pagination changePage={this.changePage} first={this.state.first}
                                                last={this.state.last}
                                                totalCount={props.allChequeTransactions.totalCount} />
                                        </div>
                                    </React.Fragment>
                                }
                                return <Loading/>
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ChequeTransactionListTable;
