import React from "react";
import { format } from 'date-fns'
import thLocale from "date-fns/locale/th";
import i18next from "i18next";
import { Translation } from "react-i18next";

class BalanceSheetAccountHeaderStatements extends React.Component {

    render() {

        let month_select = this.props.month_select


        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <thead>
                            <tr>
                                {this.props.first_statements ?
                                    <td colSpan={3}><h5 className="text-center mb-0"><u>{this.props.header_text}</u></h5></td>
                                    :
                                    <td colSpan={3} />
                                }
                            </tr>
                            <tr>
                                <td></td>
                                <td className="text-center align-middle"></td>
                                {/* ปี พ ศ */}
                                <td className="text-center align-bottom" width={100}>
                                    <small>{i18next.t("BalanceSheet:Unit")}:{i18next.t("BalanceSheet:THB")}</small>
                                    <hr />
                                    <h6 className="m-0 p-0 pt-1 pb-1">
                                        {/* <span>{format(this.props.state.end_date_first, 'MMM YYYY', {locale: thLocale})}</span> */}
                                        <span> {month_select !== 'month' ?
                                            (format(this.props.state.start_date_first, 'MMM YY', { locale: thLocale }) === format(this.props.state.end_date_first, 'MMM YY', { locale: thLocale }) ?
                                                `${i18next.t("monthNameShort:" + format(this.props.state.start_date_first, 'MMM'))} ${format(this.props.state.start_date_first, 'YY')}`
                                                :
                                                `${i18next.t("monthNameShort:" + format(this.props.state.start_date_first, 'MMM'))} ${format(this.props.state.start_date_first, 'YY')} - ${i18next.t("monthNameShort:" + format(this.props.state.end_date_first, 'MMM'))} ${format(this.props.state.end_date_first, 'YY')}`
                                            )
                                            :
                                            `${i18next.t("monthNameShort:" + format(this.props.state.end_date_first, 'MMM'))} ${format(this.props.state.end_date_first, 'YY')}`
                                        }
                                        </span>
                                    </h6>
                                    <hr />
                                </td>
                                <td className="text-center align-bottom" width={100}>
                                    <small>{i18next.t("BalanceSheet:Unit")}:{i18next.t("BalanceSheet:THB")}</small>
                                    <hr />
                                    <h6 className="m-0 p-0 pt-1 pb-1">
                                        {/* <span>{format(this.props.state.end_date_second, 'MMM YYYY', {locale: thLocale})}</span> */}
                                        <span> {month_select !== 'month' ?
                                            format(this.props.state.start_date_second, 'MMM YY', { locale: thLocale }) === format(this.props.state.end_date_second, 'MMM YY', { locale: thLocale }) ?
                                                `${i18next.t("monthNameShort:" + format(this.props.state.start_date_second, 'MMM'))} ${format(this.props.state.start_date_second, 'YY')}`
                                                :
                                                `${i18next.t("monthNameShort:" + format(this.props.state.start_date_second, 'MMM'))} ${format(this.props.state.start_date_second, 'YY')} - ${i18next.t("monthNameShort:" + format(this.props.state.end_date_second, 'MMM'))} ${format(this.props.state.end_date_second, 'YY')}`
                                            : 
                                            `${i18next.t("monthNameShort:" + format(this.props.state.end_date_second, 'MMM'))} ${format(this.props.state.end_date_second, 'YY')}`
                                        }
                                        </span>
                                    </h6>
                                    <hr />
                                </td>
                                {/* ปี พ ศ */}

                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <h6>{this.props.first_title}</h6>
                                </td>
                            </tr>
                        </thead>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}
export default BalanceSheetAccountHeaderStatements;
