import React from 'react';
import ComponentPagination from "../../../libs/componentPagination";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Wrapper from "../../../components/wrapper";
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../../env/environment";
import numberWithCommas from "../../../libs/numberWithComma";
import Pagination from "../../../libs/newPagination";
import Swal from "sweetalert2";
import CreateServicePricingEachUnitMutation from "./mutations/CreateServicePricingEachUnitMutation";
import Redirect from "react-router-dom/es/Redirect";
import NavigationPricing from "./navigationPricing";

import Loading from "../../../libs/loading"
import i18next from 'i18next';

const _ = require('lodash');

const query = graphql`
    query individualPriceListQuery ($first: Int, $last: Int, $service_id: ID!,$service_status: String, $search:String) {
        allResidential(first: $first, last: $last, servicePricingStatus: $service_status, serviceId:$service_id, name_Icontains: $search) {
            edges {
                node {
                    id name size
                    contact {
                        edges {
                            node {
                                id firstName lastName
                            }
                        }
                    }
                    servicePricing(service_Id: $service_id) {
                        edges {
                            node {
                                id pricing
                            }
                        }
                    }
                }
            }
            totalCount
        }
        areaSummary
        totalServicePricingEachUnit(serviceId: $service_id)
    }
`;


class IndividualPriceList extends ComponentPagination {

    constructor(props) {
        super(props);
        this.state.init = 10;
        this.state.data = false;
        this.state.loading = false;
        this.state.status = '';
        this.state.search = '';
        this.state.alldata = false;
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMutationComplete = this.onMutationComplete.bind(this);
        this.onMutationError = this.onMutationError.bind(this);
        this.onCancelToEdit = this.onCancelToEdit.bind(this);
    }

    componentDidMount() {
        this.query();
        this.queryAll()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.status !== this.state.status) {
            this.setState({status:this.props.match.params.status});
            this.query();
        }
    }

    query() {
        let service_id = this.props.match.params.id;
        this.setState({status:this.props.match.params.status, data: false}, () => {
            fetchQuery(environment, query, {
                first: this.state.first,
                last: this.state.last,
                service_id: service_id,
                service_status: this.props.match.params.status,
                search : this.state.search
            }).then(data => {
                this.setState({data: data});
            })
        });

    }

    queryAll() {
        let service_id = this.props.match.params.id;
        this.setState({status:this.props.match.params.status, data: false}, () => {
            fetchQuery(environment, query, {
                service_id: service_id,
                service_status: this.props.match.params.status,
                search : this.state.search
            }).then(data => {
                this.setState({alldata: data});
            })
        });

    }


    handleInputChange(e) {
        let name = e.target.name;
        this.setState({[name]: e.target.value}, () => {
            if (name === 'taxIdNumber') {
                this.changeClassTaxIdNumber(this.state.taxIdNumber)
            }
        });
    }


    handleChange(event) {
        event.preventDefault();
        let e = _.cloneDeep(event);
        this.setState(prevState => {
            return _.set(_.cloneDeep(prevState), e.target.name, e.target.name !== 'search' ? e.target.value === "" ? 0 : e.target.value : e.target.value);
        },()=>{
            if (e.target.name === 'search'){
                this.query()
            }
        });
    }

    submit(e) {
        e.preventDefault();
        let data2 = this.state.alldata;
        let isFirst = false; //กดบันทึกครั้งแรก
        let isNotInput = true; //จะแจ้งเตือนเฉพาะครั้งแรก ไม่เคยมีการบันทึกค่ายูนิต และเป็นค่าว่างทั้งหมด
        this.state.data.allResidential.edges.map((data,index) => {
            let value = 0
            if(data.node.servicePricing.edges.length > 0){
                value = data.node.servicePricing.edges[0].node.pricing
                data2 = _.set(data2, 'allResidential.edges.'+index+'.node.servicePricing.edges.0.node.pricing', value);
                isNotInput = false;
            }else{
                data2 = _.set(data2, 'allResidential.edges.'+index+'.node.servicePricing.edges.0.node.pricing', 0);
            }
            
        })
        let final_data = data2;
        data2.allResidential.edges.map((data,index) => {
            let value = parseInt(0);
            if(data.node.servicePricing.edges.length > 0){
                value = data.node.servicePricing.edges[0].node.pricing
                final_data = _.set(final_data, 'allResidential.edges.'+index+'.node.servicePricing.edges.0.node.pricing', value);
            }else{
                final_data =  _.set(final_data, 'allResidential.edges.'+index+'.node.servicePricing.edges.0.node.pricing', parseInt(0));
                isFirst = true;
            }
        })
        this.setState({loading: true});
        let data = this.state.data
        if(isFirst){
            data = final_data;
        }
        let variables = {
            input: {
                data: JSON.stringify(data),
                serviceId: this.props.match.params.id
            }
        };
        if(isNotInput){
            Swal.fire('ไม่สามารถบันทึกได้!', 'กรุณาใส่จำนวนเงินเรียกเก็บ (ค่าต่ำสุด 0) อย่างน้อย 1 ห้อง', 'warning');
            this.setState({loading: false});
        }else{
            CreateServicePricingEachUnitMutation(variables, (response) => this.onMutationComplete(response), this.onMutationError)
        }
    }

    onMutationComplete() {
        this.setState({loading: false});
        Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
            this.setState({redirectToList: true});
        });
        
    }

    onMutationError() {
        this.setState({loading: false});
        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
    }

    onCancelToEdit() {
        Swal.fire({
            title: 'คุณแน่ใจที่จะระบุภายหลัง?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                this.setState({redirectToList: true});
            }
        });
    }


    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/inventory/product/list/service"/>
        }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box">

                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/blank.png'}
                                         alt="back" className="blank"/>
                                    {i18next.t("revenue_setting:Price")}
                                </h3>
                            </div>
                            <div className="col text-right">

                                <button type="button" className="btn btn-secondary add mr-2"
                                        onClick={this.onCancelToEdit}>
                                    ระบุภายหลัง
                                </button>
                                <button type="button" className="btn btn-primary add" onClick={this.submit}
                                        disabled={this.state.loading}>
                                    {this.state.loading &&
                                    <span className="spinner-border spinner-border-sm align-middle mr-2"/>
                                    }
                                    บันทึก
                                </button>
                                <div className="w-100"/>
                                <span className="small text-secondary">กรณีระบุภายหลังคุณสามารถเข้ามาที่สินค้า/บริการนั้นๆ แล้วกดแก้ไขข้อมูล</span>

                            </div>
                        </div>


                        <div className="content-inner fade-up">
                            <NavigationPricing id={this.props.match.params.id}/>

                            <div className="row">
                                <div className="col" id="pricingTableLabel">
                                    จำนวนที่เรียกเก็บ <span className="cursor">(ข้อมูลจะเปลี่ยนแปลงหลังการบันทึก)</span>
                                    <br/>
                                    <strong>{numberWithCommas(this.state.data.totalServicePricingEachUnit)} บาท</strong>
                                </div>
                                <div className="col-3 text-right">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="ค้นหา"
                                               name="search" value={this.state.search} onChange={this.handleChange}/>
                                    </div>
                                </div>
                            </div>

                            {this.state.data &&
                            <React.Fragment>
                                <div className="mt-3 table-responsive card">
                                    <table className="table">
                                        <thead>
                                        <tr className="bg-light">
                                            <th>ห้อง</th>
                                            <th>ชื่อ นามสกุล</th>
                                            <th className="text-right">สัดส่วนกรรมสิทธิ์</th>
                                            <th className="text-right">พื้นที่</th>
                                            <th className="text-right">เรียกเก็บ</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.data.allResidential.edges.map((resident, index) => (
                                            <tr key={resident.node.id}>
                                                <td>{resident.node.name}</td>
                                                <td>
                                                    {_.get(resident, 'node.contact.edges.0.node.firstName')} &nbsp;
                                                    {_.get(resident, 'node.contact.edges.0.node.lastName')}
                                                </td>
                                                <td className="text-right">
                                                    {numberWithCommas((resident.node.size / this.state.data.areaSummary) * 100)}%
                                                </td>
                                                <td className="text-right">{numberWithCommas(resident.node.size)}</td>
                                                <td className="text-right" width={120}>
                                                    {this.props.match.params.status === 'complete-price' ?

                                                        <span>{_.get(this.state, "data.allResidential.edges." + index + ".node.servicePricing.edges.0.node.pricing", "")}</span>
                                                        :
                                                        <input
                                                            onKeyPress={(e) => {
                                                                e.key === "Enter" && e.preventDefault()
                                                            }}
                                                            name={"data.allResidential.edges." + index + ".node.servicePricing.edges.0.node.pricing"}
                                                            value={_.get(this.state, "data.allResidential.edges." + index + ".node.servicePricing.edges.0.node.pricing", "")}
                                                            type="number" className="form-control text-right"
                                                            onChange={this.handleChange}/>

                                                    }



                                                </td>
                                            </tr>
                                        ))}

                                        </tbody>
                                    </table>
                                </div>

                                <div className="row">
                                    <Pagination changePage={this.changePage}
                                                first={this.state.first}
                                                last={this.state.last}
                                                totalCount={this.state.data.allResidential.totalCount}/>
                                </div>
                            </React.Fragment>
                            }

                            {!this.state.data &&
                            <Loading/>
                            }

                        </div>
                    </div>
                </WrapperContent>

            </Wrapper>
        )
    }
}

export default IndividualPriceList;
