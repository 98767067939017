import React, { Component } from 'react'
import PrivateRoute from '../../libs/privateRoute'
import NoMatch from '../../components/noMatch'
import { Route, Switch } from "react-router-dom";
import ManageRequestList from "./manageRequestList"
import CreateManageRequest from './createManageRequest';
import CreateFormManageRequest from './createFormManageRequest'
import UpdateFormManageRequest from './updateFormManageRequest';

export default class manageRequest extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={ManageRequestList} />
        <PrivateRoute exact path={`${this.props.match.url}/createManageRequest`} component={CreateManageRequest} />
        <PrivateRoute exact path={`${this.props.match.url}/createManageRequest/formManageRequest/:type`} component={CreateFormManageRequest} />
        <PrivateRoute exact path={`${this.props.match.url}/updateFormManageRequest/:type/:status/:id/:docNumber`} component={UpdateFormManageRequest} />
        <Route component={NoMatch} />
      </Switch>
    )
  }
}
