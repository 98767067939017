import React, { useState, useEffect } from 'react'
import { format } from 'date-fns';
import _ from 'lodash';
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from '../../../../../env/environment';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import jwtDecode from 'jwt-decode';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const query = graphql`
    query batchPrintPDFAllQuery($startDate: Date, $endDate: Date, $search: String, $status: String) {    
        allBatchReceive(startDate: $startDate, endDate: $endDate, search: $search, status:$status){
            edges{
                node{
                    id
                    docNumber
                    batchDate
                    startRt
                    endRt
                    creator
                    voider
                    remark
                    status
                    added
                    receiveCount
                    importReceive
                    {
                        id
                        added
                        allRow
                    }
                }
            }
            totalCount
        }
}`;


const BatchPrintPdf = (props) => {
    const jwtDecodes = jwtDecode(window.localStorage.getItem('token'));
    const printBy = jwtDecodes.first_name + " " + jwtDecodes.last_name
    const [loading, setLoading] = useState(false)
    const [dataGenPdf, setDataGenPdf] = useState()


    useEffect(() => {
        fetchBatchPDFData();
    }, [props]);

    const calBatchRange = (startRt, endRt) => {
        const containsComma = startRt ? startRt.includes(',') : "";
        let results = '';
        if (containsComma) {
            const startData = startRt.split(',');
            const endData = endRt.split(',');
            let resultConcat = startData.map((start, index) => `${start} - ${endData[index]}`);
            results = resultConcat.join(', ');
        } else if (startRt === endRt) {
            results = startRt;
        } else {
            results = `${startRt} - ${endRt}`;
        }
        return results;
    };
   
    const fetchBatchPDFData = () => {
        setLoading(true)
        const variables = {
            startDate: props.startDate ? format(props.startDate, "YYYY-MM-DD") : null,
            endDate: props.endDate ? format(props.endDate, "YYYY-MM-DD") : null,
            search: props.search,
            status: props.status
          };
        fetchQuery(environment, query, variables).then(data => {

            getRtInBatch(data.allBatchReceive?.edges)
        })
    }

    const getRtInBatch = (data) => {   
        let dataForPdf = [
            `${props.projectName}`,
            { text: i18next.t('batchRt:Batch Report'), style: '' },
            { text: `${i18next.t('batchRt:Sort by')} ${props.startDate? format(props.startDate,"DD/MM/YYYY"): "-"} ${i18next.t('batchRt:To')} ${props.endDate? format(props.endDate,"DD/MM/YYYY") : "-"}`, style: '' },
        ];

        let allRtData = [
            [
                { text:`${i18next.t('batchRt:Batch number')}`, alignment: 'center' }, 
                { text:`${i18next.t('batchRt:Date')}`, alignment: 'center' }, 
                { text:`${i18next.t('batchRt:Time')}`, alignment: 'center' }, 
                { text:`${i18next.t('batchRt:Quantity')}`, alignment: 'center' }, 
                { text:`${i18next.t('batchRt:Document number')}`, alignment: 'center' }, 
                { text:`${i18next.t('batchRt:Reason for Cancellation')}`, alignment: 'center' }, 
            ],
        ];

        data.map((rt)=>{
            let batchData = [
                { text: rt.node.docNumber, alignment: 'center' }, 
                { text: format(rt.node.batchDate, 'DD/MM/YYYY') , alignment: 'center' }, 
                { text: format(rt.node.added, 'HH.mm'), alignment: 'center' }, 
                { text: `${rt.node.receiveCount} ${i18next.t('batchRt:Transaction')}`, alignment: 'center' }, 
                { text: calBatchRange(rt.node.startRt, rt.node.endRt), alignment: 'left' }, 
                { text: rt.node.remark || '-', alignment: 'left' }, 
            ];
            allRtData.push(batchData);            
        })

        let batchHeader = {
            style: 'tableBatchDetail',
            table: {
                headerRows: 1,
                layout: 'noBorders',
                widths: [100, 45, 40, 50, 150, 180],
                body: allRtData,
            },
        };

        dataForPdf.push(batchHeader)
        setDataGenPdf(dataForPdf)
        setLoading(false)
    }


    const generatePdf = async () => {
        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [30, 30, 30, 30],
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {
                            text: `${currentPage}/${pageCount}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [0, 0],
                        },
                        {
                            text: `${i18next.t('batchRt:Print By')} ${printBy} ${i18next.t('batchRt:Print Date')} ${format(new Date(), 'DD/MM/YYYY HH:mm')}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [20, 0],
                        },
                    ],
                };
            },
            content: dataGenPdf,
            defaultStyle: {
                font: 'Sarabun',
            },
            styles: {
                tableBatchDetail: {
                    margin: [0, 5, 0, 0],
                    fontSize: 8,
                },
                tableRtDetail: {
                    margin: [0, 2, 0, 20],
                    fontSize: 8,
                },
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBlob((blob) => {
            let preview = URL.createObjectURL(blob);
            window.open(preview);
        });
    };

    

    return (
            <Link
                className="dropdown-item printItem"
                to="route"
                target="blank"
                onClick={(event) => {
                    event.preventDefault();
                    generatePdf();
                }}
            >
                {loading && <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                {i18next.t('batchRt:Print PDF')}
            </Link>            
    );


}

export default BatchPrintPdf;