import React, { Component } from 'react';
import { format } from "date-fns";
import QRCode from "qrcode.react";
import API from '../../api/silverGraund'
import Swal from 'sweetalert2';
import i18next from 'i18next';

class manageVisitorPrint extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.state = {
            data: {},
            allRow: 0
        }

    }

    componentWillMount() {
        this.getDataId()
    }

    getDataId() {
        let idCode = this.props.match.params.id
        API.getDataDetail(idCode).then((res) => {
            if (res.data.data) {
                this.setState({ data: res.data.data[0], allRow: res.data.allRow })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    print() {
        window.print();
    }

    render() {

        return (

            <div id='silvermangrauadPrint'>
                <div className="barLogo" style={{ background: "#FFFFFF" }}>
                    <div className="logo" style={{ float: 'left' }}>
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                    </div>
                    <div className="btn btn-primary" onClick={this.print} style={{ float: 'right' }}>
                        <img src={process.env.PUBLIC_URL + '/images/icons/iconPrint.png'} /> พิมพ์
                    </div>
                </div>
                <div style={{ background: '#F4F4F8', textAlign: 'center' }}>
                    <div className='row'>
                        <div className='mx-auto divToPrint' style={{ width: '474px', background: '#FFF', marginTop: '35px', paddingLeft: '35px', paddingRight: '35px', marginBottom: '141px' }}>

                            <h3 style={{ marginTop: '30px' }}>บัตรหมายเลขที่ {this.state.data.codeVisit}</h3>
                            <span style={{ fontSize: '16px' }}>{this.state.data.fullnameVisit}</span><br />

                            <div className='row'>
                                <div className='col-6 text-left'>
                                    <label>{this.state.data.brandCar && this.state.data.brandCar + ' ,' + this.state.data.brandCar && this.state.data.colorCar}</label>
                                </div>
                                <div className='col-6 text-right'>
                                    <label className='carRigis'>{this.state.data.carRegistration}</label><br />
                                    <label className='carProvine'>{this.state.data.province}</label>

                                </div>
                            </div>

                            <div className='boxQRCode mx-auto'>
                                <QRCode
                                    value=
                                    {
                                        JSON.stringify({
                                            carTransactionId: this.state.data.carTransactionId,
                                            carId: this.state.data.carId,
                                            status: "park",
                                            startDate: this.state.data.startDate,
                                        })
                                    }
                                    size={180}
                                />
                            </div>

                            <p className='typeCar'>
                                {
                                    this.state.data.typeTrackandCar === "sedan" ? "รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน" :
                                        this.state.data.typeTrackandCar === "pickup" ? "รถยนต์บรรทุกส่วนบุคคล" :
                                            this.state.data.typeTrackandCar === "van" ? "รถยนต์นั่งส่วนบุคคลเกิน 7 คน" :
                                                this.state.data.typeTrackandCar === "taxi" ? "รถแท็กซี่" :
                                                    this.state.data.typeTrackandCar === "motorcy" ? "รถจักรยานยนต์ไม่เกิน 150 cc" : "รถจักรยานยนต์ 150cc ขึ้นไป"
                                }
                            </p>

                            <div className='row' style={{ marginTop: '25px' }}>
                                <div className='col-5 text-left'>
                                    <label>เวลาสิทธิ์เข้าจอด</label>
                                    <h4>{format(this.state.data.startDate, "HH:mm A")}</h4>
                                    <span>{format(this.state.data.startDate, "ddd,MMM D")}</span>
                                </div>
                                <div className='col-2'>
                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconArrowRight.png'}
                                        style={{ height: '30px', marginTop: "30px" }} />
                                </div>
                                <div className='col-5 text-left'>
                                    <label>สิ้นสุดสิทธิ์จอดรถ</label>
                                    <h4 style={{ color: '#CA0824' }}>{format(this.state.data.endDate, "HH:mm A")}</h4>
                                    <span>{format(this.state.data.endDate, "ddd,MMM D")}</span>

                                </div>
                            </div>
                            <hr />

                            <div className='row'>
                                <div className='col-6 text-left'>
                                    <label>สิทธิ์การใช้งาน</label><br />
                                    <span>วัตถุประสงค์</span>

                                </div>
                                <div className='col-6 text-right'>
                                    <label className='reason'>
                                        {
                                            this.state.data.typePerson === "person" ? "บุคคลทั่วไป" :
                                                this.state.data.typePerson === "contact" ? "ติดต่อสำนักงานขาย" :
                                                    this.state.data.typePerson === "appointment" ? "ผู้ติดต่อนัดหมายล่วงหน้า" : "ผู้เช่า/ลูกบ้าน"
                                        }</label><br />
                                    <span>{this.state.data.reason !== "" ? this.state.data.reason : "-"}</span>
                                </div>
                            </div>
                            <hr />

                            {
                                this.state.data.homeName && 
                                <h4>ติดต่อบ้านเลขที่ : {this.state.data.homeName}</h4>
                            }


                            








                        </div>

                    </div>
                </div>


            </div>
        )
    }
}

export default manageVisitorPrint;
