/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type checkSiteQueryVariables = {|
  username?: ?string,
  domain?: ?string,
|};
export type checkSiteQueryResponse = {|
  +authAdmin: ?{|
    +token: ?string,
    +permission: ?string,
    +result: ?boolean,
    +haveVat: ?boolean,
    +url: ?string,
    +projectName: ?string,
    +tenant: ?string,
    +notification: ?string,
    +acceptPolicy: ?boolean,
    +acceptTermOfUse: ?boolean,
    +acceptMarketing: ?boolean,
    +acceptPartner: ?boolean,
    +message: ?string,
    +messageTh: ?string,
  |},
  +allExtraMenu: ?any,
|};
export type checkSiteQuery = {|
  variables: checkSiteQueryVariables,
  response: checkSiteQueryResponse,
|};
*/


/*
query checkSiteQuery(
  $username: String
  $domain: String
) {
  authAdmin(username: $username, domain: $domain) {
    token
    permission
    result
    haveVat
    url
    projectName
    tenant
    notification
    acceptPolicy
    acceptTermOfUse
    acceptMarketing
    acceptPartner
    message
    messageTh
  }
  allExtraMenu
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "domain"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "domain",
        "variableName": "domain"
      },
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "concreteType": "AuthAdminObject",
    "kind": "LinkedField",
    "name": "authAdmin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permission",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "haveVat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tenant",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notification",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acceptPolicy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acceptTermOfUse",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acceptMarketing",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acceptPartner",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "messageTh",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allExtraMenu",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "checkSiteQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "checkSiteQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1941c1a7cd0fff711a29dcac9fd1f763",
    "id": null,
    "metadata": {},
    "name": "checkSiteQuery",
    "operationKind": "query",
    "text": "query checkSiteQuery(\n  $username: String\n  $domain: String\n) {\n  authAdmin(username: $username, domain: $domain) {\n    token\n    permission\n    result\n    haveVat\n    url\n    projectName\n    tenant\n    notification\n    acceptPolicy\n    acceptTermOfUse\n    acceptMarketing\n    acceptPartner\n    message\n    messageTh\n  }\n  allExtraMenu\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '77c99721beb9e182d0250533fd6695d9';

module.exports = node;
