import styled from "styled-components";
const Button = styled.button`
  background: ${(props) => (props.primary ? "#0382FA" : "white")};
  color: ${(props) => (props.primary ? "white" : "#0382FA")};
  font-size: 1em;
  /* margin: 1em; */
  padding: 0.5em 1em;
  border: 1px solid #0382fa;
  border-radius: 4px;
  min-height: 2.125rem;
`;
export { Button };
