import './styles/receiveCreateForm.scss'
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import { Translation } from "react-i18next";
import _ from "lodash"
import '../receive_create/styles/receiveCreateFormSiriraj.scss'
import CustomerDetailModal from './modal/customerDetailModal';
import ReceiveMethod from './receiveMethod';
import i18next from 'i18next';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, commitMutation } from 'react-relay';
import environment from '../../../../env/environment';
import getDocumentNumber from '../../../../libs/getDocumentNumber';
import DatePicker from "react-datepicker";
import format from 'date-fns/format';
import numberWithComma from '../../../../libs/numberWithComma';
import Swal from 'sweetalert2';
import Loading from '../../../../libs/loading';
import SearchSelect from '../../../../libs/searchSelect';

const query = graphql`
    query receiveCreateFormSirirajQuery($statusList:[String],$contactIdIn:[String], $periodDate:Date, $periodList:[Date], $type:String){
        invoiceViewer{
            allInvoiceByStatus(statusList:$statusList,contactIdIn: $contactIdIn, periodDate:$periodDate, periodList:$periodList){
                    totalCount
                    edges{
                        node{
                            id
                            docNumber
                            total
                            issuedDate
                            dueDate
                            status
                            taxType
                            contact{
                                id
                                name
                                firstName
                                lastName
                            }
                            allAmountNotPaid
                        }
                    }
                }
        },        
        quickMassageViewer(typeNote:$type){
            edges{
              node{
                id
                massageNote
                typeNote
                creator
              }
            }
        }

        nowDateReceive,
}`

const mutation = graphql`   
    mutation receiveCreateFormSirirajMutation($input:CreateReceiveBatchInput!){
    createReceiveBatch(input:$input) 
    {
      ok
      warningText
    }
}
`
const ReceiveCreateFormSiriraj = (props) => {
    const $ = window.jQuery;
    let now = new Date();
    const [showModal, setShowModal] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [allAmountSummary, setAllAmountSummary] = useState(0)
    const [allData, setAllData] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const [bankId, setBankId] = useState("")
    const [remark, setRemark] = useState("")
    const [receive, setReceive] = useState(true)
    const [loading, setLoading] = useState(true)


    const [noteList, setNoteList] = useState([{ node: { creator: " ", id: "other", name: "อื่นๆ", typeNote: "RECEIVED" } }])
    const [isOther, setIsOther] = useState(false)

    useEffect(() => {        
        if(!props.customer) {
            setRedirect(true)
        } else {
            fecthAllData();
        }
    }, [props.customer]);
    
    const fecthAllData = async () => {     
        let variables = ""
        if(props.periodRtBatch){
            variables = {
                statusList:["active","overdue","partial_payment"],
                contactIdIn: props.customer, 
                periodList: props.periodRtBatch,
                type: 'received'
            }
        } else {
            variables = {
                statusList:["active","overdue","partial_payment"],
                contactIdIn: props.customer, 
                type: 'received'
            }
        }

         setLoading(true)
         await fetchQuery(environment, query, variables).then(data => {      

            let noteListData = []
            data.quickMassageViewer && data.quickMassageViewer.edges.forEach((data) => {
                noteListData.push({ node: { creator: data.node.creator, id: data.node.id, name: data.node.massageNote, typeNote: data.node.typeNote } })
            })
            noteListData.push({ node: { creator: " ", id: "other", name: "อื่นๆ", typeNote: "RECEIVED" } })            
            setNoteList(noteListData)


            let allAmountSummary = 0;
            const allInvoiceEdges = data?.invoiceViewer?.allInvoiceByStatus?.edges || [];
    
            allInvoiceEdges.forEach((node) => {
                allAmountSummary += node?.node?.allAmountNotPaid || 0;
            });
    
            setSelectedDate(new Date(data?.nowDateReceive));

            setAllData(allInvoiceEdges);
            setAllAmountSummary(allAmountSummary)            
         })
         setLoading(false)
    }

    const showBatchDataHandler = (receiveCash) => {
        setReceive(receiveCash)
        setShowModal(true)
    }

    const closeBatchDataHandler = () => {
        setShowModal(false)        
    }

    const receiveCash = () => {        
        let variables = ""
        if(props.periodRtBatch){
            variables = {"input": {
                "issuedDate": format(selectedDate, "YYYY-MM-DD"),
                "periodList": props.periodRtBatch,
                "bankId": bankId,
                "contactId": props.customer,
                "remark": remark
            }}
        } else {
            variables = {"input":{
                "issuedDate": format(selectedDate, "YYYY-MM-DD"),
                "bankId": bankId,
                "contactId": props.customer,
                "remark": remark
            }}
        }

        commitMutation(
            environment,
            {
              mutation,
              variables,
              onCompleted: (response) => {
                if(response.createReceiveBatch.ok) {
                    Swal.fire(`${i18next.t("batchRt:Create Successful Payment Receipt")}`, '', 'success').then(() => {
                        const redirect = document.createElement('a');
                        redirect.href = '/accounting/income/receive/list/all';
                        redirect.click();
                      });

                } else {
                    Swal.fire(`${i18next.t("batchRt:Create Unsuccessful Payment Receipt")}`, '', 'warning').then(() => {
                        this.setState({loading:false})
                        setRedirect(true)
                    });
                }
              },
              onError: (err) => {
                Swal.fire(`${i18next.t("batchRt:Create Unsuccessful Payment Receipt")}`, '', 'warning').then(() => {
                    this.setState({loading:false})
                    setRedirect(true)
                });
              },
            },
        )
    }

    const handleChange = (e) => {
        setSelectedDate(e)
    }


    const handleChangeBank = (bank) => {
        setBankId(bank)
    }

    const updateRemark = (e) => {
        setRemark(e.target.value);
    }

    const cancelCreateHandle = () => {
        setRedirect(true)
    }


    const handleInputChange = (e) => {
        if (e.target.name === 'noteListInvoice') {
            if (e.target.value !== "other") {
                noteList.forEach((value) => {
                    if (value.node.id === e.target.value) {
                        setIsOther(false)
                        setRemark(value.node.name)
                    }
                })

            } else {
                setIsOther(true)
                setRemark("")

                let max_rows = 6;
                $(document).ready(function () {
                    $('textarea[data-limit-rows=true]')
                        .on('keypress', function (event) {
                            var textarea = $(this),
                                text = textarea.val(),
                                numberOfLines = (text.match(/\n/g) || []).length + 1,
                                maxRows = max_rows

                            if (event.which === 13 && numberOfLines === maxRows) {
                                return false;
                            }
                        });
                });
            }
        }
    }

    return (
        redirect ?
        <Redirect to={"/accounting/income/receive/form/select_room_siriraj"} />
            :    
        
        loading ?
            <Loading />
                :
            <Translation>{ 
                t=>
                <div id="receive-create-form-siriraj">
                        <div className="container-fluid box">

                            <div className="row justify-content-between">

                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/accounting/income/receive/form/select_room_siriraj">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back" className="back" />
                                        </Link>
                                        <span>{t('batchRt:Create Payment Receipt')}</span>                            
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid">            
                            <div className="row">
                                <div className="col-3">
                                    <p>
                                        <strong>
                                        {t('batchRt:Customers')}
                                        </strong>
                                    </p>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control inputDetailContactLeft" value={i18next.t("AgentRole:Multi-room")}
                                            disabled />
                                        <div className="input-group-append inputDetailContactRight">
                                            <span className="input-group-text" id="">
                                                <u onClick={()=>showBatchDataHandler(false)}
                                                className="textDetail"> {t('batchRt:View Information')}</u></span>
                                        </div>
                                    </div>
                                </div>

                            <div className="col-3">
                                <p>
                                <strong>{t('batchRt:No.')}</strong>
                                </p>
                                <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={getDocumentNumber.get('receive', 'XXX', "")}
                                    disabled
                                />
                                </div>
                            </div>

                            <div className="col-2">
                                <p>
                                <strong>{t('batchRt:Issue Date')}</strong>
                                </p>
                                <div className="input-group">
                                <DatePicker
                                    name="dueDate"
                                    className="form-control"
                                    selected={selectedDate}
                                    value={format(selectedDate, "DD/MM/YYYY")}
                                    onChange={(e)=>handleChange(e)}      
                                />
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="container-fluid mt-5">
                            <p><strong>{t('batchRt:Total Balance')}</strong></p>
                            <ReceiveMethod 
                                changeBankId={handleChangeBank}
                            />
                        </div>

                        <div className="container-fluid mt-5">
                            <div className='col-4'>
                                    <label htmlFor="note"><Translation>{t => <strong>{t('createReceive:Note')}</strong>}</Translation></label>
                                    <SearchSelect
                                        onChange={handleInputChange}
                                        name="noteListInvoice"
                                        placeholder='เลือกหมายเหตุ'
                                        queryObject={noteList}
                                        keyOfValue="id"
                                        keyOfLabel="name"
                                        required={false}
                                        pre_wrap={true} />
                            </div>
                        </div>
                        <div className="container-fluid mt-5">
                                {isOther &&
                                         <div className='col-4'>
                                            <Translation>
                                                {t => <p className="text-danger" style={{ fontSize: '11px', marginBottom: '8px' }}> *{t('createReceive:Enter message not more than')} 800  {t('createReceive:characters')} {remark.length}/800 </p>}
                                            </Translation>
                                            <textarea className="form-control mt-2" name="remark" value={remark}
                                                onChange={updateRemark}
                                                data-limit-rows="true"
                                                maxLength={800}
                                            />
                                        </div>
                                }
                        </div>



                        <div className="container-fluid mt-5 d-flex flex-row justify-content-between">
                            <div className='d-flex flex-row justify-content-between totalSummary'>
                                <p><strong>{t('batchRt:Total Balance')}</strong></p>
                                <p>{numberWithComma(allAmountSummary)} {t('batchRt:Baht')}</p>
                            </div>
                            <div>
                                <button className='cancelBtn mr-2' onClick={cancelCreateHandle}>
                                    {t('batchRt:Cancel')}
                                </button>
                                <button className='receiveCashBtn' 
                                onClick={()=>showBatchDataHandler(true)}
                                disabled={
                                    !selectedDate || !bankId
                                }
                                >
                                    {t('batchRt:Receive Payment')}</button>
                            </div>
                        </div>

                        <CustomerDetailModal 
                            allData={allData}
                            closeBatchDataHandler={closeBatchDataHandler}
                            showModal={showModal}
                            receiveCash={receiveCash}
                            receive={receive}
                            allAmountSummary={allAmountSummary}
                        />
                </div>    
            }</Translation>
        
    )        
}

export default ReceiveCreateFormSiriraj;