import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import CreditNote from './creditNote';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import Loading from "../../libs/loading";
import { Translation } from 'react-i18next';
import _ from "lodash";

const query = graphql`
    query wrapperCreditNoteQuery($id: ID!){
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        creditNote(id: $id) {
            id
            docNumber
            firstName lastName
            address district city province postalCode
            issuedDate
            status
            price
            remark
            version
            returnChartOfAccount{
                id
                chartOfAccountCode
                name
            }
            description
            contact {
                refNumber
                name
                taxIdNumber
                firstName
                lastName
                phone
                email
                typeOfCompany
                typeOfSupplier
                typeOfContact
                nameBranch
                registeredAddress
                registeredCountry
                registeredProvince
                registeredDistrict
                registeredCity
                registeredPostalCode
                residential {
                    id
                    size
                    ownershipRatio
                }
            }
            transaction{
                id
                invoice{
                    id
                    docNumber
                    total
                }
                productAndService{
                    productCode
                }
            }
            creditNoteTransactionRecord{
                edges{
                    node{
                        id
                        price
                        status
                        description
                        returnChartOfAccount {
                        id
                        chartOfAccountCode
                        name
                        }
                        transaction {
                            id
                            total
                            productAndService {
                                productCode
                                name
                                chartOfAccount {
                                chartOfAccountCode
                                name
                                }
                            }
                            invoice {
                                docNumber
                            }
                        }
                    }
                }
            }
        }
        userConfig {
            id languages
        }
    }
`;

class WrapperCreditNote extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type: 'all'
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }
    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Credit Note</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeAll"
                                       onChange={this.handleInputChange}
                                       value="all"
                                       checked={this.state.check_type === 'all'}/>
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeAll">
                                        {t("document:All")}
                                    </label>}
                                </Translation>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeManuscript"
                                       value="manuscript"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'manuscript'}/>
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeManuscript">
                                        {t("document:Original_")}
                                    </label>}
                                </Translation>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeCopy"
                                       value="copy"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'copy'}/>
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeCopy">
                                        {t("document:Copy_")}
                                    </label>}
                                </Translation>
                            </div>
                        </div>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{
                        id: this.props.match.params.id,
                    }}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {                    
                         if(props.creditNote.version === 'V1'){
                            return <CreditNote query={props}  />
                         }else{
                            let allsort = _.map(props.creditNote.creditNoteTransactionRecord.edges, (val) =>  {
                                return val.node.description.length
                             }); 
                            let descriptionMaxlength = _.sortBy(allsort,sort => sort).reverse().shift();
                            let rowPerPage = 0;
                            let getLine = 45; //45 per one line on description
                            let rowPerLine = descriptionMaxlength / getLine;
                                rowPerLine = rowPerLine;
   
                                if(descriptionMaxlength <= 43 && rowPerLine === 0){
                                    rowPerPage = 14;
                                }
                                else if(descriptionMaxlength > 45 && rowPerLine <= 2){
                                    rowPerPage = 14;
                                }else if(descriptionMaxlength > 45 && rowPerLine <= 4){
                                    rowPerPage = 10;
                                }else if(descriptionMaxlength > 45 && rowPerLine <= 8){
                                    rowPerPage = 4;
                                }else{
                                    rowPerPage = 16;
                                }
                       
   
                            let count_page = Math.ceil(props.creditNote.creditNoteTransactionRecord.edges.length / rowPerPage); //perPage
                            let arrX = Array.from(new Array(count_page));
                           
                            if (this.state.check_type === "all") {
                               return (
                                   <React.Fragment>
                                   {arrX.map((value, index) => {
                                       // original
                                       return (
                                           <CreditNote query={props} 
                                               check_type={"manuscript"}
                                               end_page={count_page - 1}
                                               current_page={index}
                                               key={index}
                                               number_order={20*index}
                                               num_perpage={rowPerPage}
                                               creditNoteTransaction={props.creditNote.creditNoteTransactionRecord.edges.slice(rowPerPage * index, rowPerPage * (index + 1))}
                                               languages={props.userConfig.languages}
                                               selfProject={props.selfProject}
                                           />
                                       )
                                       }
                                   )}
   
                                   {arrX.map((value, index) => {
                                           // copy
                                           return (
                                               <CreditNote query={props} 
                                                   check_type={"copy"}
                                                   end_page={count_page - 1}
                                                   current_page={index}
                                                   number_order={20*index}
                                                   num_perpage={rowPerPage}
                                                   creditNoteTransaction={props.creditNote.creditNoteTransactionRecord.edges.slice(rowPerPage * index, rowPerPage * (index + 1))}
                                                   key={index}
                                                   languages={props.userConfig.languages}
                                                   selfProject={props.selfProject}
                                               />
                                           )
                                       }
                                   )}
                                   </React.Fragment>
                               )
   
                            } else {
                               return (
                                   <React.Fragment>
                                       {arrX.map((value, index) => {
                                           return (
                                               <CreditNote query={props} 
                                                   check_type={this.state.check_type}
                                                   end_page={count_page - 1}
                                                   current_page={index}
                                                   number_order={20*index}
                                                   num_perpage={rowPerPage}
                                                   creditNoteTransaction={props.creditNote.creditNoteTransactionRecord.edges.slice(rowPerPage * index, rowPerPage * (index + 1))}
                                                   key={index}
                                                   languages={props.userConfig.languages}
                                                   selfProject={props.selfProject}
                                               />
                                           )
                                       })}
                                   </React.Fragment>
                               )
                             }
                         }
                     
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperCreditNote;
