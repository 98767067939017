/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RegisterCollectionLawFirmStatus = "ACCEPT" | "FINISH" | "PDPA_ACCEPT" | "REVIEW" | "UNACCEPT" | "WAIT_DOC" | "%future added value";
export type accountingTopMenuNavigationQueryVariables = {||};
export type accountingTopMenuNavigationQueryResponse = {|
  +getNotificationAccounting: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
  +getNotificationAdvance: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
  +getNotificationContractExpense: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
  +selfProject: ?{|
    +useAccount: boolean
  |},
  +myUser: ?{|
    +juristic: ?{|
      +id: string
    |}
  |},
  +countNotiCollection: ?{|
    +countNoti: ?number
  |},
  +allRegisterCollectionLawFirm: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +serviceContract: boolean,
        +email: ?string,
        +linkDowaloadFile: ?string,
        +status: RegisterCollectionLawFirmStatus,
        +accept: boolean,
        +pdpaAccept: boolean,
        +pdpaAcceptMarketing: boolean,
      |}
    |}>
  |},
|};
export type accountingTopMenuNavigationQuery = {|
  variables: accountingTopMenuNavigationQueryVariables,
  response: accountingTopMenuNavigationQueryResponse,
|};
*/


/*
query accountingTopMenuNavigationQuery {
  getNotificationAccounting {
    type
    count
  }
  getNotificationAdvance {
    type
    count
  }
  getNotificationContractExpense {
    type
    count
  }
  selfProject {
    useAccount
    id
  }
  myUser {
    juristic {
      id
    }
    id
  }
  countNotiCollection {
    countNoti
  }
  allRegisterCollectionLawFirm {
    edges {
      node {
        id
        serviceContract
        email
        linkDowaloadFile
        status
        accept
        pdpaAccept
        pdpaAcceptMarketing
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationType",
  "kind": "LinkedField",
  "name": "getNotificationAccounting",
  "plural": true,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationType",
  "kind": "LinkedField",
  "name": "getNotificationAdvance",
  "plural": true,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationType",
  "kind": "LinkedField",
  "name": "getNotificationContractExpense",
  "plural": true,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useAccount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "JuristicNode",
  "kind": "LinkedField",
  "name": "juristic",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "CountNotiCollection",
  "kind": "LinkedField",
  "name": "countNotiCollection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countNoti",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "RegisterCollectionLawFirmNodeConnection",
  "kind": "LinkedField",
  "name": "allRegisterCollectionLawFirm",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RegisterCollectionLawFirmNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RegisterCollectionLawFirmNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serviceContract",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "linkDowaloadFile",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accept",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pdpaAccept",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pdpaAcceptMarketing",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountingTopMenuNavigationQuery",
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "myUser",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "accountingTopMenuNavigationQuery",
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "myUser",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "4db45df0983dc9556108cafa57e936d9",
    "id": null,
    "metadata": {},
    "name": "accountingTopMenuNavigationQuery",
    "operationKind": "query",
    "text": "query accountingTopMenuNavigationQuery {\n  getNotificationAccounting {\n    type\n    count\n  }\n  getNotificationAdvance {\n    type\n    count\n  }\n  getNotificationContractExpense {\n    type\n    count\n  }\n  selfProject {\n    useAccount\n    id\n  }\n  myUser {\n    juristic {\n      id\n    }\n    id\n  }\n  countNotiCollection {\n    countNoti\n  }\n  allRegisterCollectionLawFirm {\n    edges {\n      node {\n        id\n        serviceContract\n        email\n        linkDowaloadFile\n        status\n        accept\n        pdpaAccept\n        pdpaAcceptMarketing\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4ba2d55bb0e900e3e6c25567264c3a32';

module.exports = node;
