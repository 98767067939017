import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import ContactReportList from "../contactPayableReportList";
import OtherExpenseReportTable from "./otherExpenseReportTable";
import ExportOtherExpenseReport from "./server_export";
import ProductServiceOtherExpenseList from "./productServiceOtherExpense";
import _ from "lodash";

import Loading from "../../../../libs/loading";

import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from "../../../../components/BackBtn/indexBack";
import i18next from "i18next";

const query = graphql`
  query otherExpenseReportQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $search: String
    $order: String
    $contact: String
    $productAndService: String
    $payment_channel: String
  ) {
    allOtherExpense(
      first: $first
      last: $last
      startDate: $startDate
      endDate: $endDate
      search: $search
      order: $order
      contact: $contact
      report: true
      productAndService: $productAndService
      paymentChannel: $payment_channel
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          id
          docNumber
          issuedDate
          unknownContact
          status
          contact {
            refNumber
            name
          }
          otherExpensePaymentChannel {
            totalCount
            edges {
              node {
                slug
                price
              }
            }
          }
          otherExpenseTransaction {
            totalCount
            edges {
              node {
                id
                description
                total
                price
                discount
                vat
                vatAmount
                whtRate
                whtRatePercent
                preTaxAmount
              }
            }
          }
          cashTransaction {
            totalCount
            edges {
              node {
                price
              }
            }
          }
          bankAccountTransaction {
            totalCount
            edges {
              node {
                price
              }
            }
          }
          chequeTransaction {
            totalCount
            edges {
              node {
                price
              }
            }
          }
        }
      }
    }
    summaryOtherExpense(
      startDate: $startDate
      endDate: $endDate
      search: $search
      order: $order
      contact: $contact
      report: true
      paymentChannel: $payment_channel
      productAndService: $productAndService

    )
  }
`;

class OtherExpenseReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth(), 1);

    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.search = "";
    this.state.search_input = "";
    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.status = "";
    this.state.payment_channel = "";
    this.state.temp_payment_channel = "";
    this.state.temp_product_and_service = "";
    this.state.product_and_service = "";

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "otherExpenseReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      contact: this.state.temp_contact,
      product_and_service: this.state.temp_product_and_service,
      payment_channel: this.state.temp_payment_channel,
    });
    super.goFirst()
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-payable"}
                  LinkText={i18next.t("DashboardPayable:Miscellaneous Expense Record Report")}
                  boxHtmlText={
                    <div className="col">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_payable_print",
                      }) && <ExportOtherExpenseReport state={this.state} />}
                    </div>
                  }
                />

                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <ContactReportList
                            handleChange={this.handleChange}
                            contact={this.state.temp_contact}
                            contact_other={true}
                          />
                          <ProductServiceOtherExpenseList
                            handleChange={this.handleChange}
                            product_and_service={
                              this.state.temp_product_and_service
                            }
                          />

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("FilterPayable:Payment Method")}</label>
                              <select
                                name="temp_payment_channel"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("FilterPayable:All")}</option>
                                <option value="cash">{i18next.t("PaymentChannel:Cash")}</option>
                                <option value="bank">{i18next.t("PaymentChannel:Transfer")}</option>
                                <option value="cheque">{i18next.t("PaymentChannel:Cheque")}</option>
                                <option value="petty_cash">{i18next.t("FilterPayable:Petty Cash")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <QueryRenderer
                    environment={environment}
                    query={query}
                    variables={{
                      first: this.state.first,
                      last: this.state.last,
                      search: this.state.search,
                      startDate: this.state.start_date,
                      endDate: this.state.end_date,
                      order: "doc_number",
                      productAndService: this.state.product_and_service,
                      contact: this.state.contact,
                      payment_channel: this.state.payment_channel,

                    }}
                    render={({ error, props }) => {
                      let col_span = 5;

                      if (props) {
                        return (
                          <React.Fragment>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table
                                      className="table table-hover"
                                      style={{ fontSize: 12 }}
                                    >
                                      <thead className="thead-light">
                                        <tr>
                                          <th rowSpan="2" className="text-center">
                                            {i18next.t("OtherExpenseReport:Order")}
                                          </th>
                                          <th rowSpan="2" className="text-center">
                                            {i18next.t("OtherExpenseReport:Expense Number")}
                                          </th>
                                          <th rowSpan="2" className="text-center">
                                            {i18next.t("OtherExpenseReport:Date")}
                                          </th>
                                          <th rowSpan="2" className="text-center">
                                            {i18next.t("OtherExpenseReport:Supplier Code")}
                                          </th>
                                          <th rowSpan="2">{i18next.t("OtherExpenseReport:Name")}</th>
                                          <th rowSpan="2">{i18next.t("OtherExpenseReport:Item")}</th>
                                          <th rowSpan="2" className="text-right">
                                            {i18next.t("OtherExpenseReport:Amount")}
                                          </th>
                                          <th
                                            colSpan={col_span}
                                            className="text-center"
                                          >
                                            {i18next.t("OtherExpenseReport:Payment Method")}
                                          </th>
                                          <th rowSpan="2" className="text-right">
                                            {i18next.t("OtherExpenseReport:Withholding Tax")}
                                          </th>

                                        </tr>
                                        <tr>
                                          <th className="text-center">
                                            {i18next.t("OtherExpenseReport:Cash")}
                                          </th>
                                          <th className="text-center">
                                            {i18next.t("OtherExpenseReport:Bank Transfer")}
                                          </th>
                                          <th className="text-center">{i18next.t("OtherExpenseReport:Cheque")}</th>
                                          <th className="text-center">{i18next.t("OtherExpenseReport:Petty Cash")}</th>
                                          <th className="text-center">{i18next.t("OtherExpenseReport:Other")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <OtherExpenseReportTable
                                          props={props}
                                          state={this.state}
                                        />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={props.allOtherExpense.totalCount}
                              />
                            </div>
                          </React.Fragment>
                        );
                      } else {
                        return <Loading />
                      }
                    }}
                  />
                </div>
              </div>

            }
          </Translation>

        </WrapperContent>
      </Wrapper>
    );
  }
}

export default OtherExpenseReport;
