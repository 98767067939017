import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import Receive from "./receive";
import _ from "lodash"
import Loading from '../../libs/loading';

const query = graphql`
    query wrapperReceiveAllQuery($id_in: [String]!,$ref_in: [String]) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
        }
        userConfig {
            id languages
        }
        receiveViewer {
            allReceive(id_In:$id_in, status_In: "paid") {
                edges {
                    node {
                        id
                        contact {
                            refNumber
                            name
                            taxIdNumber
                            firstName
                            lastName
                            phone
                            email
                            typeOfCompany
                            typeOfSupplier
                            typeOfContact
                            nameBranch
                            registeredAddress
                            registeredCountry
                            registeredProvince
                            registeredDistrict
                            registeredCity
                            registeredPostalCode
                            residential {
                                id
                                size
                                ownershipRatio
                            }
                        }
                        docNumber
                        issuedDate
                        creator
                        remark
                        total
                        
                        firstName
                        lastName
                        name
                        taxIdNumber
                        email
                        phone
                        
                        receiveTransaction {
                            totalCount
                            edges {
                                node {
                                    id
                                    amount
                                    discount
                                    transaction {
                                        invoice {
                                            docNumber
                                            taxType
                                            firstName
                                            lastName
                                        }
                                        id
                                        productAndService {
                                            productCode
                                        }
                                        description
                                        unitItems
                                        price
                                        whtRatePercent
                                        preTaxAmount
                                        discount
                                        vat
                                        whtRate
                                        total
                                        receiptDepositTransactionUsed {
                                            edges {
                                                node {
                                                    id
                                                    amount
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        creditNoteReceive{
                            totalCount
                            edges{
                                node{
                                    id
                                    docNumber
                                    description
                                    price
                                }
                            }
                        }
                        cashDepositReceiveTransaction {
                            edges {
                                node {
                                    price
                                }
                            }
                        }
                        chequeDeposit {
                            edges {
                                node {
                                    id
                                    date
                                    bankName
                                    chequeNumber
                                    branchNumber
                                    price
                                }
                            }
                        }
                        withheldTaxReceive {
                            edges {
                                node {
                                    total
                                }
                            }
                        }
                        bankAccountTransactionReceive {
                            edges {
                                node {
                                    id
                                    price
                                    date
                                    bankAccount {
                                        bankName
                                        accountNumber
                                    }
                                }
                            }
                        }
                        creditCardTransaction {
                            edges {
                                node {
                                    amount
                                }
                            }
                        }
                        smallChange{
                            edges{
                                node{
                                    id
                                    amount
                                }
                            }
                        }
                        feeAccounting{
                            edges{
                                node{
                                    id
                                    amount
                                }
                            }
                        }
                        paymentGatewayMethod{
                            edges{
                                node{
                                    id
                                    paymentType
                                    paymentGateway{
                                        id
                                        paymentType
                                    }
                                    amount
                                    feeAmount
                                    total
                                    depositDate
                                    image
                                }
                            }
                        }
                        
                    }
                }
            }
        }
        allAccountRecordGroup(ref_In: $ref_in) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;


class WrapperReceiveAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkList: props.location.state.checkList,
            checkListdoc: props.location.state.checkListdoc,
            check_type: 'all'
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Receive</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeAll"
                                       onChange={this.handleInputChange}
                                       value="all"
                                       checked={this.state.check_type === 'all'}/>
                                <label className="form-check-label" htmlFor="checkTypeAll">
                                    ทั้งหมด
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeManuscript"
                                       value="manuscript"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'manuscript'}/>
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    ต้นฉบับ
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeCopy"
                                       value="copy"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'copy'}/>
                                <label className="form-check-label" htmlFor="checkTypeCopy">
                                    สำเนา
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{id_in: this.state.checkList,ref_in: this.state.checkListdoc ,check_type: this.state.check_type}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return (
                                <React.Fragment>
                                    {props.receiveViewer.allReceive.edges.map((receive,index_r) => {

                                        // let count_page = Math.ceil((receive.node.receiveTransaction.totalCount + receive.node.creditNoteReceive.totalCount) / 16);
                                        // let arrX = Array.from(new Array(count_page));
                                        // let transaction_per_page = 16;

                                        let allsort = _.map(receive.node.receiveTransaction.edges, (val) =>  {
                                            return val.node.transaction.description.length
                                         }); 
            
                                        let descriptionMaxlength = _.sortBy(allsort,sort => sort).reverse().shift();
                    
                                        let rowPerPage = 0;
            
                                        let getLine = 45; //45 per one line on description
            
                                        let rowPerLine = descriptionMaxlength / getLine;

                                        if(descriptionMaxlength <= 43 && rowPerLine === 0){
                                            rowPerPage = 14;
                                        }
                                        else if(descriptionMaxlength > 45 && rowPerLine <= 2){
                                            rowPerPage = 16;
                                        }else if(descriptionMaxlength > 45 && rowPerLine <= 4){
                                            rowPerPage = 10;
                                        }else if(descriptionMaxlength > 45 && rowPerLine <= 8){
                                            rowPerPage = 4;
                                        }else{
                                            rowPerPage = 16;
                                        }
                                        
                                        let count_page = Math.ceil((receive.node.receiveTransaction.totalCount + receive.node.creditNoteReceive.totalCount) / rowPerPage);
                                        let arrX = Array.from(new Array(count_page));
                                        let transaction_per_page = rowPerPage;

                                        let find_index_acc = _.findIndex(props.allAccountRecordGroup.edges, { node: { refTransaction: receive.node.docNumber } });
                                        if (this.state.check_type === "all") {
                                            return (
                                                <React.Fragment key={receive.node.id}>
                                                    {arrX.map((value, index) => {
                                                        // original
                                                            return (
                                                                <Receive selfProject={props.selfProject}
                                                                         receive={receive.node}
                                                                         receiveTransaction={receive.node.receiveTransaction.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1))}
                                                                         bank_transaction={receive.node.bankAccountTransactionReceive.edges}
                                                                         cash_transaction={receive.node.cashDepositReceiveTransaction.edges}
                                                                         cheque_transaction={receive.node.chequeDeposit.edges}
                                                                         withheld_tax={receive.node.withheldTaxReceive.edges}
                                                                         credit_card_transaction={receive.node.creditCardTransaction.edges}
                                                                         small_change={receive.node.smallChange.edges}
                                                                         fee={receive.node.feeAccounting.edges}
                                                                         check_type={"manuscript"}
                                                                         receiveNumber={index + "manuscript" + atob(receive.node.id).split(':')[1]}
                                                                         end_page={count_page - 1}
                                                                         current_page={index}
                                                                        //  number_order={20*index}
                                                                         key={index}
                                                                         otherTransaction = {props.allAccountRecordGroup.edges[find_index_acc]}
                                                                         otherTransaction_all = {'all'}
                                                                         num_perpage={rowPerPage}
                                                                />
                                                            )
                                                        }
                                                    )}

                                                    {arrX.map((value, index) => {
                                                        // copy
                                                            return (
                                                                <Receive selfProject={props.selfProject}
                                                                         receive={receive.node}
                                                                         receiveTransaction={receive.node.receiveTransaction.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1))}
                                                                         bank_transaction={receive.node.bankAccountTransactionReceive.edges}
                                                                         cash_transaction={receive.node.cashDepositReceiveTransaction.edges}
                                                                         cheque_transaction={receive.node.chequeDeposit.edges}
                                                                         withheld_tax={receive.node.withheldTaxReceive.edges}
                                                                         credit_card_transaction={receive.node.creditCardTransaction.edges}
                                                                         paymentGatewayTransaction={receive.node.paymentGatewayMethod.edges}
                                                                         small_change={receive.node.smallChange.edges}
                                                                         fee={receive.node.feeAccounting.edges}
                                                                         check_type={"copy"}
                                                                         receiveNumber={index + "copy" + atob(receive.node.id).split(':')[1]}
                                                                         end_page={count_page - 1}
                                                                         current_page={index}
                                                                         key={index}
                                                                        // number_order={20*index}
                                                                         otherTransaction = {props.allAccountRecordGroup.edges[find_index_acc]}
                                                                         otherTransaction_all = {'all'}
                                                                         num_perpage={rowPerPage}
                                                                         languages={props.userConfig.languages}
                                                                />
                                                            )
                                                        }
                                                    )}
                                                </React.Fragment>
                                            )
                                        } else {
                                            return (
                                                <React.Fragment>
                                                    {arrX.map((value, index) => {
                                                        return (
                                                            <Receive selfProject={props.selfProject}
                                                                     receive={receive.node}
                                                                     receiveTransaction={receive.node.receiveTransaction.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1))}
                                                                     bank_transaction={receive.node.bankAccountTransactionReceive.edges}
                                                                     cash_transaction={receive.node.cashDepositReceiveTransaction.edges}
                                                                     cheque_transaction={receive.node.chequeDeposit.edges}
                                                                     withheld_tax={receive.node.withheldTaxReceive.edges}
                                                                     credit_card_transaction={receive.node.creditCardTransaction.edges}
                                                                     paymentGatewayTransaction={receive.node.paymentGatewayMethod.edges}
                                                                     small_change={receive.node.smallChange.edges}
                                                                     fee={receive.node.feeAccounting.edges}
                                                                     check_type={this.state.check_type}
                                                                     receiveNumber={index + "receive" + atob(receive.node.id).split(':')[1]}
                                                                     end_page={count_page - 1}
                                                                     current_page={index}
                                                                    //  number_order={20*index}
                                                                     key={index}
                                                                     otherTransaction = {props.allAccountRecordGroup.edges[find_index_acc]}
                                                                     otherTransaction_all = {'all'}
                                                                     num_perpage={rowPerPage}
                                                                     languages={props.userConfig.languages}
                                                            />
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )
                                        }
                                    })}
                                </React.Fragment>
                            )
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperReceiveAll;
