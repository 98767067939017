import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { decode } from 'base-64';

import format from 'date-fns/format';
import APIClaim from '../../../../api/claim' 

class SummarizeClaimFinish extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            id: "",
            amountToClaim: 8000,
            dateFinish: "2022-01-01",
            loading : true
        }
    }
    componentWillUnmount() { }
    componentWillMount() {
        APIClaim.GetCliamID(decode(this.props.id)).then((res)=> {
            if(res.data.claim){
                this.setState({data: res.data.claim.claim_detail ,  id: decode(this.props.id) , loading: false },()=> {
                    // console.log(this.state);
                })
            }
        })
     }


    render() {
        return (
            this.state.loading == false &&
            <div className='ResultClaim' style={{ marginTop: 100 }}>
                <label className='headerLabel28'>สรุปค่าสินไหม</label>
                <hr /> <br />

                <div style={{ flexDirection: 'row' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <div className="w250">
                            <label className='headerLabel18'>จำนวนเงินที่ขอเคลม <span className='text-red'></span> (บาท)</label>
                            <input readOnly value={parseInt(this.state.data?.claim_amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                className='form-control inputBox text-right w222' />
                        </div>

                        <div className="w250">
                            <label className='headerLabel18'>จำนวนเงินเคลมที่อนุมัติ <span className='text-red'></span> (บาท)</label>
                            <input readOnly value={parseInt(this.state.data?.approved_amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                className='form-control inputBox text-right w222' />
                        </div>
                    </div>
                </div>

                <div style={{ flexDirection: 'row' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <div className="w250 mt-3">
                            <label className='headerLabel18'>ส่วนต่างค่าสินไหม <span className='text-red'></span> (บาท)</label>
                            <input readOnly value={parseInt(this.state.data?.claim_amount - this.state.data?.approved_amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                className='form-control inputBox text-right w222' />
                        </div>
                    </div>
                </div>


                <div style={{ flexDirection: 'row' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <div className="w250 mt-10">
                            <label className='headerLabel18'>วันที่ปิดเคลม </label>
                            <input readOnly value={format(this.state.dateFinish , "DD/MM/YYYY")}
                                className='form-control inputBox' />
                        </div>
                    </div>
                </div>



                <div className='text-right'>
                    <Link to={"/purchasing/claim/finish"}>
                        <button className='btn btn-outline bg-1567B4 w222'>กลับสู่หน้าเคลม</button>
                    </Link>
                </div>







            </div>
        )
    }
}

export default SummarizeClaimFinish;
