/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type juristicManagerChangeQueryVariables = {|
  id?: ?string
|};
export type juristicManagerChangeQueryResponse = {|
  +personeManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +image: ?string,
        +signature: ?string,
        +prefix: ?string,
        +firstName: ?string,
        +lastName: ?string,
        +address: ?string,
        +department: ?string,
        +position: ?string,
        +affiliation: ?string,
        +phone: ?string,
        +email: ?string,
        +state: string,
        +note: ?string,
        +online: ?any,
        +workExperience: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +company: ?string,
              +position: ?string,
              +startDate: ?any,
              +expDate: ?any,
              +state: string,
            |}
          |}>
        |},
        +education: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +degree: ?string,
              +state: string,
            |}
          |}>
        |},
        +term: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +startDate: ?any,
              +expDate: ?any,
            |}
          |}>
        |},
        +filepdf: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +pdf: ?string,
              +state: string,
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type juristicManagerChangeQuery = {|
  variables: juristicManagerChangeQueryVariables,
  response: juristicManagerChangeQueryResponse,
|};
*/


/*
query juristicManagerChangeQuery(
  $id: String
) {
  personeManager(id: $id) {
    edges {
      node {
        id
        image
        signature
        prefix
        firstName
        lastName
        address
        department
        position
        affiliation
        phone
        email
        state
        note
        online
        workExperience {
          edges {
            node {
              id
              company
              position
              startDate
              expDate
              state
            }
          }
        }
        education {
          edges {
            node {
              id
              name
              degree
              state
            }
          }
        }
        term {
          edges {
            node {
              startDate
              expDate
              id
            }
          }
        }
        filepdf {
          edges {
            node {
              id
              name
              pdf
              state
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signature",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefix",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "department",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affiliation",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "online",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expDate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "WorkExperienceNodeConnection",
  "kind": "LinkedField",
  "name": "workExperience",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkExperienceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkExperienceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "company",
              "storageKey": null
            },
            (v10/*: any*/),
            (v17/*: any*/),
            (v18/*: any*/),
            (v14/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "EducationNodeConnection",
  "kind": "LinkedField",
  "name": "education",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EducationNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v20/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "degree",
              "storageKey": null
            },
            (v14/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "FilepdfNodeConnection",
  "kind": "LinkedField",
  "name": "filepdf",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FilepdfNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FilepdfNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v20/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pdf",
              "storageKey": null
            },
            (v14/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "juristicManagerChangeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JuristicManagerNodeConnection",
        "kind": "LinkedField",
        "name": "personeManager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicManagerNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JuristicManagerNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v19/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TermNodeConnection",
                    "kind": "LinkedField",
                    "name": "term",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TermNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TermNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "juristicManagerChangeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JuristicManagerNodeConnection",
        "kind": "LinkedField",
        "name": "personeManager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicManagerNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JuristicManagerNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v19/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TermNodeConnection",
                    "kind": "LinkedField",
                    "name": "term",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TermNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TermNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33be704d355a35e15c980c374a533c5d",
    "id": null,
    "metadata": {},
    "name": "juristicManagerChangeQuery",
    "operationKind": "query",
    "text": "query juristicManagerChangeQuery(\n  $id: String\n) {\n  personeManager(id: $id) {\n    edges {\n      node {\n        id\n        image\n        signature\n        prefix\n        firstName\n        lastName\n        address\n        department\n        position\n        affiliation\n        phone\n        email\n        state\n        note\n        online\n        workExperience {\n          edges {\n            node {\n              id\n              company\n              position\n              startDate\n              expDate\n              state\n            }\n          }\n        }\n        education {\n          edges {\n            node {\n              id\n              name\n              degree\n              state\n            }\n          }\n        }\n        term {\n          edges {\n            node {\n              startDate\n              expDate\n              id\n            }\n          }\n        }\n        filepdf {\n          edges {\n            node {\n              id\n              name\n              pdf\n              state\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b233221265d5180effb3e53d2cbde1f3';

module.exports = node;
