import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import Information from "./create/information";
import Accessories from "./create/accessories";
import Terms from "./create/Terms";
import PublicServiceOpening from "./create/publicServiceOpening";
import Rights from "./create/rights";
import TimeLimit from "./create/timeLimit";
import Gallery from "./create/gallery";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import "../styles/commonArea.scss";
import ComponentPagination from "../../../libs/componentPagination";
import _ from "lodash";
import { format } from "date-fns";
// import api from "../../../api/index";
import i18n from "i18next";
import Swal from "sweetalert2";
import { Redirect } from "react-router";
import upload from "../../../libs/upload";
import api from "./services";
import Loading from "../../../libs/loading";
import BackButtonIcon from '../../../components/BackBtn/indexBack';
import { Translation } from "react-i18next";
import i18next from "i18next";

const allDay = new Date().setHours(0, 0, 0, 0);
const endDay = new Date().setHours(23, 59, 59);
class AddNewFacility extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      //path 1
      status: "open",
      name: "",
      name_en: "",
      location: "",
      location_en: "",
      size: "",
      max_people: "",
      remark: "",
      //path2
      equipment_status: "true",
      equipment: [
        {
          equipment: "",
          unit_items: "",
        },
      ],
      //path3
      rule_status: "true",
      rule: [{ rule_no: 1, rule_description: "" }],
      public_private: "private", //public
      rule_pdf: "",
      rule_pdf_status: "old",
      //path4
      every_day_all_day: {
        status: false,
      },
      open_status: "set_once",
      holiday: [],
      holiday_status: "false",
      //path 5
      round: [],
      right_per_round: "",
      right_per_round_unit: "hours", //time round
      max_right: "3",
      people_per_right: "1",
      reservation_form: "default",
      advance_payment_value: "1",
      advance_payment_unit: "minute",
      price: 0,
      //path 6
      limit_time_reservation: {
        start_reservation_day: "",
        end_reservation_day: "",
        start_reservation_time: "",
        end_reservation_time: "",
      },
      limit_time_status: "true",
      limit_time: "old",
      redirectToList: false,
      image_cover: "",
      image_cover_status: "old",
      facility_image: [],
      change_image_upload: false,
      open_check: [false, false, false, false, false, false, false],
      loading: true,
      button_load: false,
      new_open: {
        monday_start: "",
        monday_end: "",
        status_monday: false,
        monday_24h: false,
        tuesday_start: "",
        tuesday_end: "",
        status_tuesday: false,
        tuesday_24h: false,
        wednesday_start: "",
        wednesday_end: "",
        status_wednesday: false,
        wednesday_24h: false,
        thursday_start: "",
        thursday_end: "",
        status_thursday: false,
        thursday_24h: false,
        every_startfriday_start: "",
        friday_start: "",
        friday_end: "",
        status_friday: false,
        friday_24h: false,
        saturday_start: "",
        saturday_end: "",
        status_saturday: false,
        saturday_24h: false,
        sunday_start: "",
        sunday_end: "",
        status_sunday: false,
        sunday_24h: false,
        //
        every_start: "",
        every_end: "",
        status_every: false,
        // every_24h: false,
        every_day: false,
      },
    };
  }
  setDefaultOpen = () => {
    document.getElementById("status_monday").checked = false;
    document.getElementById("status_tuesday").checked = false;
    document.getElementById("status_wednesday").checked = false;
    document.getElementById("status_thursday").checked = false;
    document.getElementById("status_friday").checked = false;
    document.getElementById("status_saturday").checked = false;
    document.getElementById("status_sunday").checked = false;

    document.getElementById("monday_24h").checked = false;
    document.getElementById("tuesday_24h").checked = false;
    document.getElementById("wednesday_24h").checked = false;
    document.getElementById("thursday_24h").checked = false;
    document.getElementById("friday_24h").checked = false;
    document.getElementById("saturday_24h").checked = false;
    document.getElementById("sunday_24h").checked = false;
    this.setState({
      new_open: {
        ...this.state.new_open,
        monday_start: "",
        monday_end: "",
        status_monday: false,
        monday_24h: false,
        tuesday_start: "",
        tuesday_end: "",
        status_tuesday: false,
        tuesday_24h: false,
        wednesday_start: "",
        wednesday_end: "",
        status_wednesday: false,
        wednesday_24h: false,
        thursday_start: "",
        thursday_end: "",
        status_thursday: false,
        thursday_24h: false,
        friday_start: "",
        friday_end: "",
        status_friday: false,
        friday_24h: false,
        saturday_start: "",
        saturday_end: "",
        status_saturday: false,
        saturday_24h: false,
        sunday_start: "",
        sunday_end: "",
        status_sunday: false,
        sunday_24h: false,
      },
    });
  };

  handleOpenNew = (name, value, date) => {
    this.setState(
      {
        new_open: {
          ...this.state.new_open,
          [name]: value,
        },
      },
      () => {
        if (date) {
          this.handleCheck24h(date, this.state.new_open[`${date}_24h`]);
        }
      }
    );
  };

  handleCheck24h = (name, status) => {
    if (status) {
      this.setState({
        new_open: {
          ...this.state.new_open,
          [`${name}_start`]: allDay,
          [`${name}_end`]: endDay,
        },
      });
    } else {
      this.setState({
        new_open: {
          ...this.state.new_open,
          [`${name}_start`]: "",
          [`${name}_end`]: "",
        },
      });
    }
  };

  async componentDidMount() {
    await api
      .getOneFacility(this.props.match.params.id)
      .then(async (res) => {
        let state = {
          new_open: { ...this.state.new_open },
          holiday: [],
          round: [],
          facility_image: [],
          every_day_all_day: {
            status: false,
            every_day_start: "",
            every_day_end: "",
          },
          limit_time_reservation: { ...this.state.limit_time_reservation },
        };
        state = { ...state, ...res.facility };
        if (res.facility_equipment && res.facility_equipment.length >= 1) {
          state["equipment_status"] = "false";
          state["equipment"] = res.facility_equipment;
        }

        if (res.facility_rule && res.facility_rule.length >= 1) {
          let facility = [];
          res.facility_rule.map((item) => {
            if (item.rule_no == 0) {
              state["rule_pdf"] = {
                name: item.rule_description,
                ...item,
              };
            } else if (item.rule_no !== 0) {
              return facility.push(item);
            }
          });
          state["rule_pdf_status"] = "old";
          state["rule"] = facility;
        }

        if (res.facility_image && res.facility_image.length >= 1) {
          res.facility_image.forEach((element) => {
            state["facility_image"].push({
              ...element,
              status_image: "old",
            });
          });
        }

        if (res.facility_open) {
          await _.forIn(res.facility_open, function (value, key) {
            if (key === "site" || key === "facility" || key === "every_day" || key === "id") {
              if (key === "every_day") {
                state["new_open"] = {
                  ...state.new_open,
                  every_day: value,
                };
                state["every_day_all_day"] = {
                  ...state.every_day_all_day,
                  ["status"]: !value,
                };
              } else {
                state["new_open"] = { ...state.new_open, [key]: value };
              }
            } else if (value) {
              let time = value.split(":");
              state["new_open"] = {
                ...state.new_open,
                [key]: new Date().setHours(time[0], time[1], time[2], 0),
              };
              if (
                key === "monday_start" ||
                key === "tuesday_start" ||
                key === "wednesday_start" ||
                key === "thursday_start" ||
                key === "thursday_start" ||
                key === "friday_start" ||
                key === "saturday_start" ||
                key === "sunday_start"
              ) {
                let _key = key.split("_");
                state["new_open"] = {
                  ...state.new_open,
                  [`status_${_key[0]}`]: true,
                };

                // document.getElementById(`status_${_key[0]}`).checked = true;
              }
            }
          });
        }
        if (res.facility_holiday && res.facility_holiday.length >= 1) {
          res.facility_holiday.forEach((element) => {
            state["holiday"] = [
              ...state.holiday,
              {
                ["holidays"]: new Date(element.holidays),
              },
            ];
          });
          state["holiday_status"] = false;
        }

        if (res.facility.reservation_form === "round" && res.facility_round && res.facility_round.length > 0) {
          res.facility_round.forEach((element) => {
            let start = element.start_round_time.split(":");
            let end = element.end_round_time.split(":");
            state["round"] = [
              ...state.round,
              {
                ["start_round_time"]: new Date(new Date().setHours(start[0], start[1], start[2], 0)),
                ["end_round_time"]: new Date().setHours(end[0], end[1], end[2], 0),
              },
            ];
          });
          // state["holiday_status"] = false;
        }

        if (res.facility.reservation_form) {
          state["reservation_form"] = res.facility.reservation_form;
        }

        if (res.limit_time_reservation) {
          let start_time = res.limit_time_reservation.start_reservation_time.split(":");
          let end_time = res.limit_time_reservation.end_reservation_time.split(":");
          state["limit_time_reservation"].start_reservation_day = new Date(res.limit_time_reservation.start_reservation_day);
          state["limit_time_reservation"].end_reservation_day = new Date(res.limit_time_reservation.end_reservation_day);
          state["limit_time_reservation"].start_reservation_time = new Date().setHours(start_time[0], start_time[1], start_time[2], 0);
          state["limit_time_reservation"].end_reservation_time = new Date().setHours(end_time[0], end_time[1], end_time[2], 0);
          state["limit_time_status"] = "false";
          state["limit_time"] = "new";
        }

        return state;
      })
      .then((data) => {
        this.setState(
          {
            ...data,
            loading: false,
          },
          () => {
            if (this.state.new_open.every_day) {
              this.setState(
                {
                  new_open: {
                    ...this.state.new_open,
                    every_start: this.state.new_open.monday_start,
                    every_end: this.state.new_open.monday_end,
                  },
                  open_status: "set_once",
                },
                () => {
                  this.setDefaultOpen();
                }
              );
            } else {
              this.setState({ open_status: "custom" });
              document.getElementById(`status_monday`).checked = this.state.new_open.status_monday;
              document.getElementById(`status_tuesday`).checked = this.state.new_open.status_tuesday;
              document.getElementById(`status_wednesday`).checked = this.state.new_open.status_wednesday;
              document.getElementById(`status_thursday`).checked = this.state.new_open.status_thursday;
              document.getElementById(`status_friday`).checked = this.state.new_open.status_friday;
              document.getElementById(`status_saturday`).checked = this.state.new_open.status_saturday;
              document.getElementById(`status_sunday`).checked = this.state.new_open.status_sunday;
            }
          }
        );
      });
  }

  handleInputImage = (e) => {
    let value = e.target.value;
    if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === "image") {
      if (e.target.name === "image_upload") {
        this.setState({
          image_cover: e.currentTarget.files[0],
          change_image_upload: true,
          image_cover_status: "new",
        });
      } else {
        this.setState({
          facility_image: [
            ...this.state.facility_image,
            {
              facility_image: e.currentTarget.files[0],
              id: this.state.facility_image.length !== 0 ? this.state.facility_image[this.state.facility_image.length - 1].id + 1 : 1,
              status_image: "new",
            },
          ],
        });
      }
    } else {
      Swal.fire(i18next.t("Allaction:Invalid file type"), i18next.t("Allaction:Please check again"), "warning");
    }
  };

  onDelete = (index) => {
    this.state.facility_image[index].status_image = "delete";
    this.state.facility_image[index].facility_image = "";
    this.setState({ facility_image: this.state.facility_image });
  };

  handleLimitTime = (name, date) => {
    this.setState({
      limit_time_reservation: {
        ...this.state.limit_time_reservation,
        [name]: date,
      },
    });
  };

  handleOpen = (e) => {
    const { name, value } = e.target;
    if (name === "open_status") {
      if (value === "set_once") {
        this.setDefaultOpen();
      } else if (value === "custom") {
        this.setState({
          new_open: {
            ...this.state.new_open,
            every_start: "",
            every_end: "",
            every_24h: false,
          },
        });
        document.getElementById("every_24h").checked = false;
      }
    }
  };

  handleChange = (e, index) => {
    const { name, value } = e.target;
    if ("right_per_round_unit" === name) {
      if (value === "round") {
        document.getElementById("reservation_form3").checked = true;
        this.setState({ reservation_form: "round" });
      }
    }
    if (name === "equipment" || name === "unit_items") {
      let equipment_list = this.state.equipment;
      equipment_list[index][name] = value;
      this.setState({ equipment: equipment_list });
    } else if (name === "rule_description") {
      let rule_list = this.state.rule;
      rule_list[index][name] = value;
      this.setState({ rule: rule_list });
    } else if (name === "holiday_status") {
      this.setState({ holiday_status: !this.state.holiday_status });
    } else if (name === "rule_pdf") {
      this.setState({
        rule_pdf: e.currentTarget.files[0],
        rule_pdf_status: "new",
      });
    } else this.setState({ [name]: value });
  };

  addEquipment = (status, index_equipment) => {
    let equipment_list = this.state.equipment;

    if (status === "add") {
      equipment_list.push({ equipment: "", unit_items: "" });
    } else {
      var evens = _.remove(equipment_list, function (n, index) {
        return index !== index_equipment;
      });
      equipment_list = evens;
    }
    this.setState({ equipment: equipment_list });
  };

  addRule = () => {
    let rule_list = this.state.rule;
    rule_list.push({
      rule_no: this.state.rule.length + 1,
      rule_description: "",
    });
    this.setState({ rule: rule_list });
  };

  subRule = (status) => {
    var evens = _.remove(this.state.rule, function (n, index) {
      return index !== status;
    });
    this.setState({ rule: evens });
  };

  delePDF = (id) => {
    var evens = _.remove(this.state.rule_pdf, function (n, index) {
      return index !== id;
    });
    this.setState({ rule_pdf: evens });
  }

  addHoliday = (date, status) => {
    let holiday_list = this.state.holiday;
    if (status === "add") {
      holiday_list.push({
        holidays: date,
        desciption: "หยุดเอง",
      });
      this.setState({ holiday: holiday_list });
    } else {
      var evens = _.remove(this.state.holiday, function (n, index) {
        return index !== status;
      });
      holiday_list = evens;
    }
    this.setState({ holiday: holiday_list });
  };
  addRound = (start, end) => {
    let round_list = this.state.round;
    round_list.push({
      start_round_time: start,
      end_round_time: end,
    });
    this.setState({ round: round_list });
  };
  subRound = (status) => {
    let round_list = this.state.round;
    var evens = _.remove(this.state.round, function (n, index) {
      return index !== status;
    });
    round_list = evens;
    this.setState({ round: round_list });
  };

  sendApi = () => { };
  callUpload = async (path, file) => {
    return new Promise(async (resolve, reject) => {
      let res = await upload("/booking/" + path, file);
      resolve(res);
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ button_load: true });
    let {
      max_people,
      right_per_round,
      right_per_round_unit,
      max_right,
      people_per_right,
      reservation_form,
      price,
      advance_payment_value,
      advance_payment_unit,
      public_private,
      status,
      open_status,
      rule_pdf,
      name,
      name_en,
      location,
      location_en,
      size,
      remark,
      equipment_status,
      equipment,
      rule,
      holiday,
      round,
      limit_time_status,
      limit_time_reservation,
      image_cover,
      facility_image,
      new_open,
      rule_pdf_status,
      image_cover_status,
      limit_time,
    } = this.state;

    var bodyFormData = new FormData();
    
    if (rule_pdf) {
      if (rule_pdf_status === "new") {
        await this.callUpload("rule_pdf", rule_pdf).then((data) => {
          bodyFormData.append(
            "facility_rule",
            JSON.stringify({
              rule_no: 0,
              rule_description: rule_pdf.name,
              rule_pdf: data.key.replace("silverman-storage/private/", ""),
            })
          );
        });
      } else {
        bodyFormData.append(
          "facility_rule",
          JSON.stringify({
            rule_no: 0,
            rule_description: rule_pdf.name ? rule_pdf.name : null,
            rule_pdf: rule_pdf.pdf_path ? rule_pdf.pdf_path : null ,
          })
        );
      }
    }

    if (image_cover) {
      if (image_cover_status === "new") {
        await this.callUpload("image_cover", image_cover).then((data) => {
          bodyFormData.append("image_cover", data.key.replace("silverman-storage/private/", ""));
        });
      }
    }
    if (facility_image.length > 0) {
      for (const image of facility_image) {
        if (image.status_image === "delete") {
          bodyFormData.append("facility_image", JSON.stringify({ ...image, facility_image: null }));
        } else if (image.status_image === "new") {
          await upload("/booking/facility_image", image.facility_image).then((res) => {
            bodyFormData.append(
              "facility_image",
              JSON.stringify({
                ...image,
                facility_image: res.key.replace("silverman-storage/private/", ""),
              })
            );
          });
        } else {
          bodyFormData.append("facility_image", JSON.stringify(image));
        }
      }
    }

    status && bodyFormData.append("status", status);
    bodyFormData.append("name", name || null);
    bodyFormData.append("name_en", name_en || null);
    bodyFormData.append("location", location || null);
    bodyFormData.append("location_en", location_en || null);
    bodyFormData.append("size", size || null);
    bodyFormData.append("max_people", max_people || null);
    bodyFormData.append("remark", remark || null);

    if (equipment_status !== "true") {
      equipment.forEach((item) => bodyFormData.append("facility_equipment", JSON.stringify(item)));
    } else if (equipment.length === 0) {
      bodyFormData.append("facility_equipment", JSON.stringify([]));
    }

    if (rule.length >= 1) {
      rule.forEach((item) => bodyFormData.append("facility_rule", JSON.stringify(item)));
    }

    if (open_status === "set_once") {
      bodyFormData.append(
        "facility_open",
        JSON.stringify({
          monday_start: new_open.every_start ? format(new_open.every_start, "HH:mm:ss") : null,
          monday_end: new_open.every_end ? format(new_open.every_end, "HH:mm:ss") : null,
          tuesday_start: new_open.every_start ? format(new_open.every_start, "HH:mm:ss") : null,
          tuesday_end: new_open.every_end ? format(new_open.every_end, "HH:mm:ss") : null,
          wednesday_start: new_open.every_start ? format(new_open.every_start, "HH:mm:ss") : null,
          wednesday_end: new_open.every_end ? format(new_open.every_end, "HH:mm:ss") : null,
          thursday_start: new_open.every_start ? format(new_open.every_start, "HH:mm:ss") : null,
          thursday_end: new_open.every_end ? format(new_open.every_end, "HH:mm:ss") : null,
          friday_start: new_open.every_start ? format(new_open.every_start, "HH:mm:ss") : null,
          friday_end: new_open.every_end ? format(new_open.every_end, "HH:mm:ss") : null,
          saturday_start: new_open.every_start ? format(new_open.every_start, "HH:mm:ss") : null,
          saturday_end: new_open.every_end ? format(new_open.every_end, "HH:mm:ss") : null,
          sunday_start: new_open.every_start ? format(new_open.every_start, "HH:mm:ss") : null,
          sunday_end: new_open.every_end ? format(new_open.every_end, "HH:mm:ss") : null,
          every_day: "True",
        })
      );
    } else if (open_status === "custom") {
      bodyFormData.append(
        "facility_open",
        JSON.stringify({
          monday_start: new_open.monday_start ? format(new_open.monday_start, "HH:mm:ss") : null,
          monday_end: new_open.monday_end ? format(new_open.monday_end, "HH:mm:ss") : null,
          tuesday_start: new_open.tuesday_start ? format(new_open.tuesday_start, "HH:mm:ss") : null,
          tuesday_end: new_open.tuesday_end ? format(new_open.tuesday_end, "HH:mm:ss") : null,
          wednesday_start: new_open.wednesday_start ? format(new_open.wednesday_start, "HH:mm:ss") : null,
          wednesday_end: new_open.wednesday_end ? format(new_open.wednesday_end, "HH:mm:ss") : null,
          thursday_start: new_open.thursday_start ? format(new_open.thursday_start, "HH:mm:ss") : null,
          thursday_end: new_open.thursday_end ? format(new_open.thursday_end, "HH:mm:ss") : null,
          friday_start: new_open.friday_start ? format(new_open.friday_start, "HH:mm:ss") : null,
          friday_end: new_open.friday_end ? format(new_open.friday_end, "HH:mm:ss") : null,
          saturday_start: new_open.saturday_start ? format(new_open.saturday_start, "HH:mm:ss") : null,
          saturday_end: new_open.saturday_end ? format(new_open.saturday_end, "HH:mm:ss") : null,
          sunday_start: new_open.sunday_start ? format(new_open.sunday_start, "HH:mm:ss") : null,
          sunday_end: new_open.sunday_end ? format(new_open.sunday_end, "HH:mm:ss") : null,
          every_day: "False",
        })
      );
    }

    if (holiday.length > 0) {
      holiday.forEach((item) => {
        bodyFormData.append(
          "facility_holiday",
          JSON.stringify({
            holidays: format(item.holidays, "YYYY-MM-DD"),
            description: "",
          })
        );
      });
    } else if (holiday.length === 0) {
    }

    bodyFormData.append("right_per_round", right_per_round);
    bodyFormData.append("right_per_round_unit", right_per_round_unit);
    bodyFormData.append("max_right", max_right);
    bodyFormData.append("people_per_right", people_per_right);
    if (reservation_form === "round" && round.length > 0) {
      bodyFormData.append("reservation_form", reservation_form);
      round.forEach((item) =>
        bodyFormData.append(
          "facility_round",
          JSON.stringify({
            start_round_time: format(item.start_round_time, "H:mm:ss"),
            end_round_time: format(item.end_round_time, "H:mm:ss"),
          })
        )
      );
    }
    if (reservation_form !== "round") {
      bodyFormData.append("reservation_form", reservation_form);
    }
    bodyFormData.append("price", price);
    bodyFormData.append("advance_payment_value", advance_payment_value ? advance_payment_value : null);
    bodyFormData.append("advance_payment_unit", advance_payment_unit);

    if (status === "temporarily_open" && limit_time_status == "false") {
      bodyFormData.append(
        "limit_time_reservation",
        JSON.stringify({
          start_reservation_day: format(limit_time_reservation.start_reservation_day, "YYYY-MM-DD"),
          end_reservation_day: format(limit_time_reservation.end_reservation_day, "YYYY-MM-DD"),
          start_reservation_time: format(limit_time_reservation.start_reservation_time, "H:mm:ss"),
          end_reservation_time: format(limit_time_reservation.end_reservation_time, "H:mm:ss"),
        })
      );
    } else if (status === "temporarily_open" && limit_time_status == "true") {
      bodyFormData.append("limit_time_reservation", "False");
    } else if (limit_time_status == "false" && status !== "temporarily_open") {
      bodyFormData.append("limit_time_reservation", "False");
    }

    bodyFormData.append("public_private", public_private);

    const validate = {
      max_people: false,
      right_per_round: false,
      right_per_round_unit: false,
      max_right: false,
      people_per_right: false,
      reservation_form: false,
      advance_payment_value: false,
      advance_payment_unit: false,
      new_open: false,
      public_private: false,
      status: false,
      limit_time_reservation: false,
      equipment: false,
    };

    if (equipment_status === "true" || equipment_status === true) {
      validate.equipment = true;
    } else {
      if (equipment.length > 1 && equipment[equipment.length - 1].equipment !== "" && equipment[equipment.length - 1].unit_items !== "") {
        validate.equipment = true;
      } else if (equipment_status === "false" && equipment.length === 1 && equipment[0].equipment !== "" && equipment[0].unit_items !== "") {
        validate.equipment = true;
      } else {
        Swal.fire("กรุณาระบุรายการอุปกรณ์และจำนวนอุปกรณ์", "", "warning");
      }
    }

    if (max_people) {
      validate.max_people = true;
    }
    if (right_per_round) {
      validate.right_per_round = true;
    }
    if (right_per_round_unit) {
      validate.right_per_round_unit = true;
    }
    if (max_right) {
      validate.max_right = true;
    }
    if (people_per_right) {
      validate.people_per_right = true;
    }
    if (reservation_form === "round") {
      if (round.length > 0) {
        validate.reservation_form = true;
      } else {
        Swal.fire("กรุณาระบุรอบจองอย่างน้อยหนึ่งรายการ", "", "warning");
      }
    } else {
      if (reservation_form === "default" || reservation_form === "free") {
        validate.reservation_form = true;
      }
    }
    if (advance_payment_value) {
      validate.advance_payment_value = true;
    }
    if (advance_payment_unit) {
      validate.advance_payment_unit = true;
    }
    if (public_private) {
      validate.public_private = true;
    }
    if (status) {
      validate.status = true;
    }

    if (open_status === "set_once") {
      if (!new_open.every_start) {
        Swal.fire("กรุณากรอกเวลาเริ่ม", "", "warning");
      } else if (!new_open.every_end) {
        Swal.fire("กรุณากรอกเวลาสิ้นสุด", "", "warning");
      } else {
        validate.new_open = true;
      }
    } else {
      if (
        (new_open.sunday_start && new_open.sunday_end) ||
        (new_open.monday_start && new_open.monday_end) ||
        (new_open.tuesday_start && new_open.tuesday_end) ||
        (new_open.wednesday_start && new_open.wednesday_end) ||
        (new_open.thursday_start && new_open.thursday_end) ||
        (new_open.friday_start && new_open.friday_end) ||
        (new_open.saturday_start && new_open.saturday_end)
      ) {
        validate.new_open = true;
      } else {
        Swal.fire("กำหนดวันเวลาเปิดให้บริการอย่างน้อยหนึ่งรายการ", "", "warning");
      }
    }
    if (limit_time_status === "false") {
      if (
        limit_time_reservation.start_reservation_day &&
        limit_time_reservation.end_reservation_day &&
        limit_time_reservation.start_reservation_time &&
        limit_time_reservation.end_reservation_time
      ) {
        validate.limit_time_reservation = true;
      } else {
        Swal.fire("กรุณากำหนดช่วงเวลาในการเปิดจองพื้นที่", "", "warning");
      }
    } else {
      validate.limit_time_reservation = true;
    }

    let check_validate = true;
    _.forIn(validate, function (value, key) {
      if (value === false) {
        check_validate = false;
      }
    });
    if (check_validate) {
      api.editBookingFacility(bodyFormData, this.state.id).then((data) => {
        if (data) {
          Swal.fire(i18n.t("createUpdateForm:Successful record"), "", "success").then(() => {
            this.setState({ redirectToList: true, button_load: false });
          });
        } else {
          this.setState({ button_load: false });
          Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
        }
      });
    }
  };

  handleImage = (image) => {
    this.setState({
      image_cover: image.image_upload,
      facility_image: image.image,
    });
  };

  deleteFacility = () => {
    this.setState({ button_load: true });
    api.deleteFacility(this.state.id).then((data) => {
      if (data) {
        Swal.fire(
          // i18n.t("createUpdateForm:Successful record"),
          "ลบสำเร็จ",
          "",
          "success"
        ).then(() => {
          this.setState({ redirectToList: true, button_load: false });
        });
      } else {
        this.setState({ button_load: false });
        Swal.fire("ลบไม่สำเร็จ!", "", "warning");
      }
    });
  };

  render() {
    if (this.state.redirectToList) {
      return <Redirect to={"/contact/global-area/booking/common-area/all"} />;
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
        <Translation>
          {(t) => (
          <div id="booking-form ">
            <div className="container-fluid box">
              <BackButtonIcon
                LinkBack={"/contact/global-area/booking/common-area/all"}
                LinkText={t("newBookingSingha_FacilityList:Edit facility")}
              />
            </div>
            <div className="d-flex justify-content-center">
              <div className="col-8">
                {!this.state.loading ? (
                  <form id="form-transfer-owner" onSubmit={this.handleSubmit}>
                    <Information handleChange={this.handleChange} state={this.state} />
                    <Accessories handleChange={this.handleChange} state={this.state} addEquipment={this.addEquipment} />
                    <Terms handleChange={this.handleChange} state={this.state} addRule={this.addRule} subRule={this.subRule}
                    delePDF={this.delePDF}
                    />
                    <PublicServiceOpening
                      handleChange={this.handleChange}
                      state={this.state}
                      addHoliday={this.addHoliday}
                      handleOpen={this.handleOpen}
                      handleOpenNew={this.handleOpenNew}
                      handleCheck24h={this.handleCheck24h}
                    />
                    <Rights handleChange={this.handleChange} state={this.state} addRound={this.addRound} subRound={this.subRound} />
                    {this.state.status === "temporarily_open" && (
                      <TimeLimit handleChange={this.handleChange} state={this.state} handleLimitTime={this.handleLimitTime} />
                    )}
                    <Gallery
                      handleChange={this.handleImage}
                      handleInputImage={this.handleInputImage}
                      onDelete={this.onDelete}
                      deleteFacility={this.deleteFacility}
                      state={this.state}
                      edit
                    />
                  </form>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
          )}
        </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default AddNewFacility;
