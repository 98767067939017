/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateProjectInfoInput = {|
  projectImage?: ?any,
  floorPlan?: ?any,
  projectBuilding?: ?any,
  fileBuilding?: ?any,
  insideBuilding?: ?any,
  unitBuilding?: ?any,
  developer?: ?string,
  nameTh?: ?string,
  nameEn?: ?string,
  typeOfProject?: ?string,
  address?: ?string,
  addressEn?: ?string,
  long?: ?string,
  lat?: ?string,
  numberOfBuilding?: ?number,
  numberOfRoom?: ?number,
  space?: ?string,
  usableSpace?: ?string,
  parkingSpace?: ?string,
  parkingSlot?: ?string,
  description?: ?string,
  registrationDate?: ?any,
  bringForwardStartDate?: ?any,
  keyProjectQr?: ?string,
  registrationNumber?: ?string,
  taxIdNumber?: ?string,
  typeOfCompany?: ?string,
  establishmentStatus?: ?string,
  juristicContactNumber?: ?string,
  securityContactNumber?: ?string,
  technicianContactNumber?: ?string,
  haveVat?: ?boolean,
  vatChoice?: ?string,
  vatAmount?: ?number,
  logo?: ?string,
  typeOfBranch?: ?string,
  nameBranch?: ?string,
  openTime?: ?string,
  endTime?: ?string,
  clientMutationId?: ?string,
|};
export type createProjectMutationVariables = {|
  input: CreateProjectInfoInput
|};
export type createProjectMutationResponse = {|
  +createProjectInfo: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type createProjectMutation = {|
  variables: createProjectMutationVariables,
  response: createProjectMutationResponse,
|};
*/


/*
mutation createProjectMutation(
  $input: CreateProjectInfoInput!
) {
  createProjectInfo(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateProjectInfoPayload",
    "kind": "LinkedField",
    "name": "createProjectInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createProjectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createProjectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "008853c8ea5e37a2ea1f804388ab4174",
    "id": null,
    "metadata": {},
    "name": "createProjectMutation",
    "operationKind": "mutation",
    "text": "mutation createProjectMutation(\n  $input: CreateProjectInfoInput!\n) {\n  createProjectInfo(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '74aeccc7c7b54bd8f524354cedaca106';

module.exports = node;
