import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import {fetchQuery} from "relay-runtime";
import UploadImageInput from "../../libs/uploadImageInput";
import SearchSelect from "../../libs/searchSelect";
import {Redirect} from 'react-router';
import i18n from "i18next";
import format from 'date-fns/format';
import DatePickerAdapter from "../../libs/datePickerAdapter"
import getQueryTenant from './graphQL/chooseTenant';
import getDateTime from "../../libs/getDateTime"
import createAndUpdateBoardTenant from './graphQL/createAndUpdateBoardTenant';
import upload from "../../libs/upload"
import FileInput from '../projectInfo/componentProjectList/fileInput';
import { encode } from 'base-64';
import i18next from 'i18next';

const _ = require('lodash');

const allResidential = graphql`
    query createUpdateProjectManagerQuery($status: String) {
        allResidential {
            totalCount
            edges {
                node {
                    id
                    name
                    floor
                    size
                    status
                    residentialHouseholder{
                        edges{
                            node{
                                id
                                tenant{
                                    id
                                    firstName
                                    lastName
                                    countries
                                    phone
                                    email
                                    user{
                                        id
                                    }
                                }
                            }
                        }
                    }
                    type{
                        id
                        name
                    }
                }
            }
        }
        chooseTerm (status: $status) {
            edges{
                node{
                    startDate
                    expDate
                }
                    
            }
        }
    }
`;

const newWorkExperience = {
   node :{
        id : '',
        nameOfCompany : '', 
        position : '',
        dateOfPosition : '',
        dateOfEndPosition : '' , 
        state : ''
    }
}

const newEducation = {
    node :{
        id : '',
        name : '',
        degree : '' ,
        state : ''
    }
}


const newProjectManager = {
    residential_id : '',
    residential_house_holder: '',
    firstName : '', 
    lastName : '', 
    image : '', 
    signature : '',
    position : "president", 
    phone : '', 
    email : '', 
    authorization : true, 
    financial : 0,
    workExperience : [],
    education : [],
    term: [],
    filepdf : [], 
    note : '' ,
}

class CreateUpdateProjectManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            group_project_id: props.match.params.id,
            residential_list: [],
            residentialHouseholder: [],
            loading: false,
            redirectToList: false,
            authorization : 'right',
            statusManager : 'new',
            chooseTerm : {},
            tenantId : '',
            boardId : '',
            status : this.props.match.params.status,
            projectManager : newProjectManager,
            uploadImage : '',
            uploadSignature: '',
            siteId: localStorage?.getItem('site_id')
            
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRadioButton = this.handleRadioButton.bind(this);
        this.appendEducation = this.appendEducation.bind(this);
        this.appendWork = this.appendWork.bind(this);
    }

    componentWillMount() {
        fetchQuery(environment, allResidential, {status:'current'})
            .then(response_data => {
                this.setState({
                    chooseTerm : response_data.chooseTerm.edges[0],
                    residential_list: response_data.allResidential.edges,
                });
            });
        if(this.props.match.params.status === 'edit'){
            let mySiteId = encode("SiteNode:"+this.state.siteId) 
            fetchQuery(environment, getQueryTenant() ,{tenantid:this.props.match.params.id,site_Id:mySiteId}).then(data => {
                if(data.chooseTenant.edges.length > 0){
                    this.setState({
                        tenantId : this.props.match.params.id,
                        email : data.chooseTenant.edges[0].node.email,
                        phone : data.chooseTenant.edges[0].node.phone,
                        unit : data.chooseTenant.edges[0].node.householder.edges[0].node.residential.name,
                        name : data.chooseTenant.edges[0].node.firstName + " " + data.chooseTenant.edges[0].node.lastName
                    }, () => {this.setPersonal(data.chooseTenant.edges[0].node)})
                }
                
            });
        }

    }

    getPosition = (position) => {
        switch (position) {
            case "president":
                return position;
            case "committee":
                return position;
            case "treasurer":
                return position;
            case "vice_president":
                return position;
            case "secretary":
                return position;
            default:
                return '';
        }
    }
    

    handleRadioButton(e){
        if(e.target.name === 'authorization'){
            if(e.target.id === 'right'){
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        'projectManager.authorization',
                        true
                    );
                });
            }else{
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        'projectManager.authorization',
                        false
                    );
                });
            }
        }

    }
    

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        let name = event.target.name;

        if (_.endsWith(name, 'residential_id')) {
            let index_object = name.split(".");
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    index_object[0] + ".residential_house_holder",
                    ''
                );
            });

            this.state.residential_list.forEach((residential) => {
                if (residential.node.id === value) {
                    this.setState({residentialHouseholder: _.get(residential, 'node.residentialHouseholder.edges')})

                }
            });
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    name,
                    value
                );
            });
        }else if(_.endsWith(name, 'residential_house_holder')){
            let detail = _.find(this.state.residentialHouseholder,['node.id',value])
            let mySiteId = encode("SiteNode:"+this.state.siteId) 
            if(this.state.projectManager.residential_house_holder !== ''){
                Swal.fire({
                    title: i18n.t('projectManager:Are you sure to change this residential?'),
                    text: i18n.t('projectManager:The information you entered a moment ago will be deleted.'),
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: i18n.t('projectManager:Confirm'),
                    cancelButtonText: i18n.t('projectManager:Cancel'),
                  }).then(async (result) => {
                    if (result.value){
                        this.setState(prevState => {
                            return _.set(
                                _.cloneDeep(prevState),
                                name,
                                value
                            ); 
                        }, () => {
                            fetchQuery(environment, getQueryTenant() ,{tenantid:detail.node.tenant.id,site_Id:mySiteId}).then(data => {
                                this.setState({
                                    statusManager : 'new',
                                    tenantId : detail.node.tenant.id
                                }, () => {this.setPersonal(data.chooseTenant.edges[0].node)})
                            });
                        });
                        this.setState(prevState => {
                            return _.set(
                                _.cloneDeep(prevState),
                                'projectManager.email',
                                detail.node.tenant.email
                            );
                        });
                        this.setState(prevState => {
                            return _.set(
                                _.cloneDeep(prevState),
                                'projectManager.phone',
                                detail.node.tenant.phone
                            );
                        });
                    }
                  })
            }else{
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        name,
                        value
                    ); 
                }, () => {
                    fetchQuery(environment, getQueryTenant() ,{tenantid:detail.node.tenant.id,site_Id:mySiteId}).then(data => {
                        this.setState({
                            statusManager : 'new',
                            tenantId : detail.node.tenant.id
                        }, () => {this.setPersonal(data.chooseTenant.edges[0].node)})
                    });
                });
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        'projectManager.email',
                        detail.node.tenant.email
                    );
                });
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        'projectManager.phone',
                        detail.node.tenant.phone
                    );
                });
            }
            
            
        }else if(name === 'projectManager.image.upload_image'){
            this.setState({
                uploadImage : value
            })
        }else if(name === 'projectManager.signature.upload_image'){
            this.setState({
                uploadSignature : value
            })
        }
        else{
                this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    name,
                    value
                );
            });
        }     
    }

    setPersonal(data){

        let setData ={
            education : data.boadEducation.edges.length !== 0 ? data.boadEducation.edges : [],
            filepdf : data.boadFilepdf.edges.length !== 0 ? data.boadFilepdf.edges : [],
            authorization : data.boadPersonal.edges.length !== 0 ? data.boadPersonal.edges[0].node.authorization : true,
            financial : data.boadPersonal.edges.length !== 0 ? data.boadPersonal.edges[0].node.financial : 0,
            image : data.boadPersonal.edges.length !== 0 ? data.boadPersonal.edges[0].node.image : '',
            note : data.boadPersonal.edges.length !== 0 ? data.boadPersonal.edges[0].node.note : '',
            signature : data.boadPersonal.edges.length !== 0 ? data.boadPersonal.edges[0].node.signature : '',
            position : data.boadPersonal.edges.length !== 0 ? data.boadPersonal.edges[0].node.position : 'president',
            workExperience : data.boadWork.edges.length !== 0 ? data.boadWork.edges : []
        }
        let projectManagerSetData = _.assign(_.cloneDeep(this.state.projectManager),setData);
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                'projectManager',
                projectManagerSetData
            );
        });
        if(data.boadPersonal.edges.length !== 0){
            this.setState({
                boardId : data.boadPersonal.edges[0].node.id,
                statusManager : 'old',
            })
        }
        if(data.boadTerm.edges.length !== 0){

            let sortBoadTermData = _.orderBy( data.boadTerm.edges,'node.expDate','asc')
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    'projectManager.term',
                    sortBoadTermData
                );
            },() => {
         
                let lastTerm = this.state.projectManager?.term[Object.keys(this.state.projectManager?.term).pop()]

                //let lastTerm = _.orderBy(this.state.projectManager?.term,'node.expDate','asc').pop()

                let chooseTermStart = format(this.state.chooseTerm.node?.startDate, "YYYY-MM-DD");
                let chooseTermExp = format(this.state.chooseTerm.node?.expDate, "YYYY-MM-DD")
              

                let lastTermStart = format(lastTerm.node?.startDate, "YYYY-MM-DD");
                let lastTermExp =  format(lastTerm.node?.expDate, "YYYY-MM-DD");

                if(this.state.status === 'create' && chooseTermStart !== lastTermStart && chooseTermExp !== lastTermExp ){ //&& chooseTermExp !== lastTermExp
                    this.appendTerm()
                }
            //   this.state.status === 'create' && this.appendTerm()
            });
        }else{
            const term = [
                {node : {
                    id : '',
                    startDate : new Date(this.state.chooseTerm.node.startDate) ,
                    expDate : new Date(this.state.chooseTerm.node.expDate) ,
                }}
            ]
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    'projectManager.term',
                    term
                );
            });
        }
    }

    appendTerm(){
        const newTerm = { 
            node :{
                id : '',
                startDate : new Date(this.state.chooseTerm.node.startDate) ,
                expDate : new Date(this.state.chooseTerm.node.expDate) ,
            }
        }
        
        let term = [...this.state.projectManager.term, {...newTerm}]
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                'projectManager.term',
                term
            );
        }); 
    }

    appendFileInputRow = (fileInput) => {
        let file = _.merge(fileInput,{node : {state : '',id: ''}})
        let newFile = [...this.state.projectManager.filepdf, {...file}]
        this.setState((prevState) => {
            return _.set(_.cloneDeep(prevState), "projectManager.filepdf", newFile);
        });
    }

    appendEducation(){
        let education = [...this.state.projectManager.education, {...newEducation}]
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                'projectManager.education',
                education
            );
        });
    }

    appendWork(){
        let work = [...this.state.projectManager.workExperience, {...newWorkExperience}]
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                'projectManager.workExperience',
                work
            );
        });
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/projectManager/data" + path, file);
          resolve(res);
        });
      };

    onSubmitSuccess = (response) => {
        this.setState({loading: false});
        if (response) {
            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                this.setState({redirectToList: true})
            });
        } else {
            Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
        }
    }

    onSubmitError = (err) => {
        this.setState({loading: false});
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }
    
    onDelete = (data,id,name) => {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let getData = _.cloneDeep(data)
                let index = _.findIndex(_.cloneDeep(getData),{node : {id : id}})
                if(index === -1){
                    getData.splice(id, 1)
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            name,
                            getData
                        );
                    });
                }else{
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            name+'['+index+'].node.state',
                            'delete'
                        );
                    });
                }
            }
        })
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({loading:true})
        let projectManager = this.state.projectManager

        let variables = {
            input : {
                position : projectManager.position,
                authorization : projectManager.authorization,
                financial : projectManager.financial,
                tenantId : this.state.tenantId ,
                boardId : this.state.boardId,
            }
        }
        if(this.state.status === 'edit'){
            variables.input["status"] = ''
        }else{
            variables.input["status"] = 'current'
        }

        if(this.state.uploadImage !== ''){
            await this.callUpload('', this.state.uploadImage).then((data) => {
                variables.input["image"] = data.key.replace("silverman-storage/private/", "");
            });
        }
        if(this.state.uploadSignature !== ''){
            await this.callUpload('', this.state.uploadSignature).then((data) => {
                variables.input["signature"] = data.key.replace("silverman-storage/private/", "");
            });
        }

        let educationData = []
        _.forEach(projectManager.education,(education) => {
            educationData.push({id : education.node.id , name : education.node.name ,
                degree : education.node.degree , status : education.node.state})
        })
        
        let newFilePdf = []
        for (const file of projectManager.filepdf) {
            if(file.node.fileUpload !== '' && file.node.id === ''){
                await this.callUpload('', file.node.fileUpload).then((data) => {
                    newFilePdf.push({id : file.node.id , nameFile : file.node.nameFile, 
                        fileUpload : data.key.replace("silverman-storage/private/", "") , status : file.node.state})
                });
            }else{
                newFilePdf.push({id : file.node.id , nameFile : file.node.nameFile, 
                    fileUpload : file.node.fileUpload , status : file.node.state})
            }
        }

        let newWorkExperience = []
        _.forEach(projectManager.workExperience,(work) => {
            newWorkExperience.push({id :work.node.id , nameOfCompany : work.node.nameOfCompany ,position : work.node.position , dateOfPosition :format(work.node.dateOfPosition,'YY-M-D') , 
            dateOfEndPosition: format(work.node.dateOfEndPosition,'YY-M-D') , status : work.node.state})
        })

        let newTerm = []

        let sortBoadTermData = _.orderBy(projectManager.term,'node.expDate','asc')
        _.forEach(sortBoadTermData,(term) => {
            newTerm.push({id : term.node.id ,startDate : format(term.node.startDate ,'YY-M-D'), expDate : format(term.node.expDate ,'YY-M-D')}) 
        })

        variables.input["boardTerm"] = JSON.stringify({boardTerm : newTerm})
        variables.input["education"] = JSON.stringify({education : educationData})
        variables.input["workExperience"] = JSON.stringify({workExperience : newWorkExperience})
        variables.input["filepdf"] = JSON.stringify({filepdf :  newFilePdf})
        variables.input["residentialId"] = this.state.projectManager.residential_id

        createAndUpdateBoardTenant(variables, this.onSubmitSuccess,this.onSubmitError)
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/project/project-manager/list/current-board"/>
        }

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <div id="wrapper-content">
                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="projectDashboard">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/project/project-manager/list/current-board">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <span>{this.state.status === 'edit' ? t('projectManager:Edit committee') : t('projectManager:Add committee')}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div className="content-inner personal-info">
                                    <form onSubmit={this.onSubmit}>
                                        <div className="row mb-3" >
                                            <div className="col-7">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="col-12 d-flex justify-content-center">
                                                                    <img
                                                                        src={this.state.projectManager.image ? this.state.projectManager.image: process.env.PUBLIC_URL + '/images/icons/profile-manager-icon.png'}
                                                                        alt="project-manager-profile"
                                                                        className="rounded-circle avatar-img profile-img"/>
                                                                </div>
                                                                <div className="col-12 d-flex justify-content-center mt-2">
                                                                    <div className="upload-file">
                                                                        <div>
                                                                            <label className="btn-link"
                                                                                    htmlFor="uploadProfileImages">{t('projectManager:Change profile image')}</label>
                                                                        </div>
                                                                        <div className="upload-input">
                                                                            <UploadImageInput
                                                                                id="uploadProfileImages"
                                                                                setField={"projectManager.image.upload_image:projectManager.image"}
                                                                                onChange={this.handleInputChange}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 d-flex justify-content-center mt-3">
                                                                    <img
                                                                        src={this.state.projectManager.signature ? this.state.projectManager.signature: process.env.PUBLIC_URL + '/images/icons/signature-manager-icon.png'}
                                                                        alt="project-manager-signature"
                                                                        className="avatar-img profile-img"/>
                                                                </div>
                                                                <div className="col-12 d-flex justify-content-center mt-2">
                                                                    <div className="upload-file">
                                                                        <div>
                                                                            <label className="btn-link text-center"
                                                                                    htmlFor="uploadSignature">{t('projectManager:Change signature')}</label>
                                                                        </div>
                                                                        <div className="upload-input">
                                                                            <UploadImageInput
                                                                                id="uploadSignature"
                                                                                setField={"projectManager.signature.upload_image:projectManager.signature"}
                                                                                onChange={this.handleInputChange}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <div className="col-12">
                                                                            <span>{t('projectManager:Unit')}</span>
                                                                        </div>
                                                                        <div className="col-12 mt-1">
                                                                            {this.state.status === 'create' ?
                                                                                <SearchSelect onChange={this.handleInputChange}
                                                                                    value={this.state.projectManager.residential_id}
                                                                                    name={"projectManager.residential_id"}
                                                                                    placeholder="รายการ"
                                                                                    queryObject={this.state.residential_list}
                                                                                    keyOfValue="id"
                                                                                    require={true}
                                                                                    keyOfLabel="name" 
                                                                                />
                                                                            :
                                                                            <span>{this.state.unit || ''}</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <div className="col-12">
                                                                            <span >{t('projectManager:Name - Surname')}</span>
                                                                        </div>
                                                                        <div className="col-12 mt-1">
                                                                            {this.state.status === 'create' ?
                                                                                <SearchSelect onChange={this.handleInputChange}
                                                                                            value={this.state.projectManager.residential_house_holder}
                                                                                            name={"projectManager.residential_house_holder"}
                                                                                            placeholder="รายการ"
                                                                                            queryObject={this.state.residentialHouseholder}
                                                                                            keyOfValue="id"
                                                                                            require={true}
                                                                                            keyOfLabel="tenant.firstName:tenant.lastName"
                                                                                />
                                                                                :
                                                                                <span>{this.state.name || ''}</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4">
                                                                    <div className="col-12">
                                                                        <div className="col-12">
                                                                            <span >{t('projectManager:Position')}</span>
                                                                        </div >
                                                                        <div className="col-12 mt-1">
                                                                            <select className="custom-select"
                                                                                    name={"projectManager.position"}
                                                                                    data-key="projectManager"
                                                                                    value={this.getPosition(this.state.projectManager.position)}
                                                                                    onChange={this.handleInputChange}>
                                                                                <option value="president">{t("change_project_manager:President")}</option>
                                                                                <option value="vice_president">{t("change_project_manager:Vice - president")}</option>
                                                                                <option value="committee">{t("change_project_manager:Committee")}</option>
                                                                                <option value="secretary">{t("change_project_manager:Secretary")}</option>
                                                                                <option value="treasurer">{t("change_project_manager:Treasurer")}</option>
                                                                                <option value="">{t("change_project_manager:Other")}</option>
                                                                            </select>
                                                                        </div>
                                                                        {this.getPosition(this.state.projectManager.position) === '' &&
                                                                            <div className="col-12 mt-2">
                                                                                <input type ="text" className="form-control" name="projectManager.position" onChange={this.handleInputChange} value={this.state.projectManager.position}/>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4">
                                                                    <div className="col-12">
                                                                        <div className="col-12">
                                                                            <span>{t('projectManager:Authorization Right')}</span>
                                                                        </div>
                                                                        <div className="col-12">
                                                                                <div className="custom-control custom-radio custom-control-inline" >
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="authorization"
                                                                                        className="custom-control-input"
                                                                                        id='right'
                                                                                        onChange={this.handleRadioButton}
                                                                                        checked={this.state.projectManager.authorization}
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label cursor"
                                                                                        htmlFor='right'
                                                                                        >
                                                                                        {t('projectManager:Have right')}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="custom-control custom-radio custom-control-inline" >
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="authorization"
                                                                                        className="custom-control-input"
                                                                                        id='not-right'
                                                                                        onChange={this.handleRadioButton}
                                                                                        checked={!this.state.projectManager.authorization}
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label cursor"
                                                                                        htmlFor='not-right'
                                                                                        >
                                                                                        {t('projectManager:Dont have right')}
                                                                                    </label>
                                                                                </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4 ">
                                                                    <div className="col-12">
                                                                        <div className="col-12">
                                                                            <span>{t('projectManager:Maximum approval limit')}</span>
                                                                        </div>
                                                                        <div className="col-12 mt-1">
                                                                            <input type="text" className="form-control text-right" 
                                                                            name="projectManager.financial" 
                                                                            value={this.state.projectManager.financial}
                                                                            disabled={!this.state.projectManager.authorization}
                                                                            onChange={this.handleInputChange}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4">
                                                                    <div className="col-12">
                                                                        <div className="col-12">
                                                                            <span>{t('projectManager:Work experience')}</span>
                                                                        </div>
                                                                        {this.state.projectManager.workExperience.length > 0 &&
                                                                            this.state.projectManager.workExperience.map((work,index) => {
                                                                                if(work.node.state !== 'delete'){
                                                                                    return (
                                                                                        <div className="mb-3 mt-3" key={index+'work'}>
                                                                                            <div className="col-12">
                                                                                                <span>{t('projectManager:Name of company')}</span>
                                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                                        alt="delete" className="btn-delete cursor ml-3 align-self-center"
                                                                                                        onClick={() => this.onDelete(this.state.projectManager.workExperience,work.node.id !== '' ? 
                                                                                                        work.node.id : index,'projectManager.workExperience')}/>
                                                                                            </div>
                                                                                            <div className="col-12 mt-1">
                                                                                                <input type="text" className="form-control" 
                                                                                                name={"projectManager.workExperience[" + index + "].node.nameOfCompany"} 
                                                                                                value={work.node.nameOfCompany} onChange={this.handleInputChange}
                                                                                                required={true} />
                                                                                            </div>
                                                                                            <div className="col-12 mt-1">
                                                                                                <span>{t('projectManager:Position')}</span>
                                                                                            </div>
                                                                                            <div className="col-12 mt-1">
                                                                                                <input type="text" className="form-control" 
                                                                                                name={"projectManager.workExperience[" + index + "].node.position"} 
                                                                                                value={work.node.position} required={true}  onChange={this.handleInputChange}/>
                                                                                            </div>
                                                                                            <div className="col-12 mt-1">
                                                                                                <span>{t('projectManager:Working period')}</span>
                                                                                            </div>
                                                                                            <div className="col-12 mt-1 custom-control-inline">
                                                                                                <DatePickerAdapter
                                                                                                    name={"projectManager.workExperience[" + index + "].node.dateOfPosition"}
                                                                                                    className="form-control mr-2"
                                                                                                    selected={getDateTime(work.node.dateOfPosition)}
                                                                                                    maxDate={getDateTime(work.node.dateOfEndPosition)}
                                                                                                    onChange={this.handleInputChange}
                                                                                                    required={true}
                                                                                                />
                                                                                                <label className="ml-2 align-self-center mr-2">{t('projectManager:to')}</label>
                                                                                                <DatePickerAdapter
                                                                                                    name={"projectManager.workExperience[" + index + "].node.dateOfEndPosition"}
                                                                                                    className="form-control"
                                                                                                    selected={getDateTime(work.node.dateOfEndPosition)}
                                                                                                    onChange={this.handleInputChange}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }else{
                                                                                    return <></>
                                                                                }
                                                                                
                                                                            })
                                                                        }
                                                                        <div className="col-12 mt-2 mb-2">
                                                                            <span className="text-blue cursor" onClick={() => this.appendWork()}> + {t('projectManager:Add work experience')}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4">
                                                                    <div className="col-12">
                                                                        <div className="col-12">
                                                                            <span>{t('projectManager:Education')}</span>
                                                                        </div>
                                                                        {this.state.projectManager.education.length > 0 &&
                                                                            this.state.projectManager.education.map((education,index) => {
                                                                                if(education.node.state !== 'delete'){
                                                                                    return (
                                                                                        <div className="mt-3 mb-3" key={index+'education'}>
                                                                                            <div className="col-12">
                                                                                                <span>{t('projectManager:Name')}</span>
                                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                                    alt="delete" className="btn-delete cursor ml-3 align-self-center"
                                                                                                    onClick={() => this.onDelete(this.state.projectManager.education,education.node.id !== '' ? 
                                                                                                    education.node.id : index,'projectManager.education')}/>
                                                                                            </div>
                                                                                            <div className="col-12 mt-1">
                                                                                                <input type="text" className="form-control" 
                                                                                                name={"projectManager.education[" + index + "].node.name"}
                                                                                                value={education.node.name}
                                                                                                onChange={this.handleInputChange}
                                                                                                required={true} />
                                                                                            </div>
                                                                                            <div className="col-12 mt-1">
                                                                                                <span>{t('projectManager:Degree')}</span>
                                                                                            </div>
                                                                                            <div className="col-12 mt-1">
                                                                                                <input type="text" className="form-control" 
                                                                                                name={"projectManager.education[" + index + "].node.degree"}
                                                                                                value={education.node.degree}
                                                                                                onChange={this.handleInputChange}
                                                                                                required={true} />
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }else{
                                                                                    return <></>
                                                                                }
                                                                                
                                                                            })
                                                                        }
                                                                        <div className="col-12 mt-2 mb-2">
                                                                            <span className="text-blue cursor" onClick={() => this.appendEducation()}> + {t('projectManager:Add education')}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card p-1 ">
                                                    <div className="card-body">
                                                        <div className="row input-group d-flex align-items-center">
                                                            <div className="col-12">
                                                                <h5>{t('projectManager:Number of term')} : {this.state.projectManager.term.length}  </h5>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.projectManager.term.length > 0 &&   _.orderBy(this.state.projectManager.term,'node.expDate','asc' ).map((term,index) => {
                                                    return (
                                                        <div className="card mt-3" key={index}>
                                                            <div className="card-body">
                                                                <div className="row input-group d-flex align-items-center">
                                                                    <div className="col-12">
                                                                        <h4>{t('projectManager:Agenda')} {index+1}</h4>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span>{t('projectManager:Date to start position')}</span>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <DatePickerAdapter
                                                                            name={"projectManager.term[" + index + "].node.startDate"}
                                                                            className="form-control"
                                                                            selected={getDateTime(term.node.startDate)}
                                                                            maxDate={getDateTime(term.node.expDate)}
                                                                            onChange={this.handleInputChange}
                                                                            required={true}
                                                                        />
                                                                    </div >
                                                                    <div className="col-6">
                                                                        <span>{t('projectManager:Date to end position')}</span>
                                                                    </div>
                                                                    <div className="col-6">
                                                                    <DatePickerAdapter
                                                                            name={"projectManager.term[" + index + "].node.expDate"}
                                                                            className="form-control"
                                                                            selected={getDateTime(term.node.expDate)}
                                                                            onChange={this.handleInputChange}
                                                                            required={true}
                                                                        />
                                                                    </div >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
    
                                                }
                                                
                                                <div className="card p-1 mt-3">
                                                    <div className="card-body">
                                                        <div className="row input-group d-flex align-items-center">
                                                            <div className="col-4 d-flex felx-row projectDashboardToolTip">
                                                                {t('projectManager:Contact')}
                                                                <div className="tooltipImg">
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                                            alt="question" className="question ml-1" style={{width:'14px',marginBottom:'10px'}}/>
                                                                    <span className="tooltiptext">
                                                                        {t("projectManager:If you want to edit contact number and email information, you must edit them in the menu. Register of co-owners/members > Co-owners/members")}                                                                       
                                                                    </span>
                                                                </div>   
                                                            </div>
                                                            <div className="col"> 
                                                                <div className="row">
                                                                    <div className="col-1 align-self-center">
                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/phone-manager-icon.png'} alt="phone" className="icon"/>
                                                                    </div>
                                                                    <div className="col">
                                                                        {this.state.status === 'create' ?
                                                                            <input type="text" className="form-control custom-control-inline"  value={this.state.projectManager.phone}/>
                                                                            :
                                                                            <span>{this.state.phone}</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row input-group d-flex align-items-center mt-2">
                                                                <div className="col-4">                                                    
                                                                    <Link to={'/register/residential/list/all'}><span>{t('projectManager:ทะเบียนเจ้าของร่วม/สมาชิก')}</span></Link>
                                                                </div>
                                                                <div className="col"> 
                                                                    <div className="row">
                                                                        <div className="col-1 align-self-center">
                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/mail-icon.png'} alt="mail" className="icon" />
                                                                        </div>
                                                                        <div className="col">
                                                                        {this.state.status === 'create' ?
                                                                            <input type="text" className="form-control custom-control-inline"  value={this.state.projectManager.email}/>
                                                                        :
                                                                            <span>{this.state.email}</span>
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div className="card p-1 mt-3">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <h5>{t('projectManager:Documentation')}</h5> 
                                                            </div>
                                                            <div className="col">
                                                                <div className="upload-file mx-2 col-3 cursor col-12">
                                                                    <label htmlFor="CustomFile" className="text-blue">
                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/clip-icon.png'} alt="attach" className="icon mr-2"/>
                                                                        {t('projectManager:Attach File')}
                                                                    </label>
                                                                    <div className="upload-input custom-file">
                                                                        <FileInput
                                                                            id="CustomFile"
                                                                            name="projectManager.filepdf"
                                                                            appendFileInputRow={this.appendFileInputRow}
                                                                        />
                                                                    </div>
                                                                </div>
                                                               
                                                                
                                                            </div>
                                                        </div>

                                                        {this.state.projectManager.filepdf?.length > 0 &&
                                                            this.state.projectManager.filepdf?.map((file,index) => {
                                                                if(file.node.state !== 'delete'){
                                                                    return(
                                                                        <div className="row" key={index}>
                                                                            <div className="col">
                                                                                <a download={file.node.nameFile}
                                                                                    href={file.node.fileUpload}>
                                                                                    <span className="cursor text-blue">{file.node.nameFile}</span>
                                                                                </a>
                                                                            </div>
                                                                            <div className="col-1 align-self-center">
                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                alt="delete" className="btn-delete cursor"
                                                                                onClick={() => this.onDelete(this.state.projectManager.filepdf,file.node.id !== '' ? 
                                                                                    file.node.id : index,'projectManager.filepdf')}/>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }else{
                                                                    return <></>
                                                                }
                                                                
                                                            })
                                                        }
                                                        
                                                    </div>
                                                </div>
                                                {/* <div className="card p-1 mt-3">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h5>{t('projectManager:Note')} : </h5>
                                                            </div>
                                                            <div className="col-12">
                                                                <textarea className="form-control" onChange={this.handleInputChange} 
                                                                name="projectManager.note" value={this.state.projectManager.note ? this.state.projectManager.note : ''}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row mt-4 ">
                                                    <div className="col d-flex justify-content-center">
                                                       
                                                        <Link to="/project/project-manager/list/current-board">
                                                            <button type="button"
                                                                    className="btn btn-secondary add bt-style mr-3">
                                                                {t("change_project_manager:Cancel")}
                                                            </button>
                                                        </Link>
                                                        <button type="submit" className="btn btn-primary bt-style"
                                                                disabled={this.state.loading}>
                                                                {t("change_project_manager:Save")}
                                                            {this.state.loading &&
                                                            <span
                                                                className="spinner-border spinner-border-sm align-middle ml-2"/>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>      
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    </Translation>
                </div>
            </Wrapper>
        );
    }
}

export default CreateUpdateProjectManager;
