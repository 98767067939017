import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";

function getToken() {

    let getApi = getApiRoot()

    const qs = require('qs');
    let data = qs.stringify({
    'grant_type': 'client_credentials' 
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${getApi}o/token/`,
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded ', 
            'authorization': 'Basic ajFKY2lXUVB1eHV4M0Q0MWJvb3Q2Vmk0ZFYwQUEzR0dVT0RxR1BXcjp1d2R6V1pTVXhKcjBoRUF3Yzd4ZFNPaTFpdnhac2ltZkdsaDhpcmRzc3BoMm9tU1BRemVHaDBUMkRnekhONlR6cEhFNVF3b2RZRUVoWXVlQ1VVdFJkdlJJektwazJaNEpNb2E0ZTBITGVTaUlYeHBlYldYSlRhamgycEhzellsTQ==', 
        },
        data : data
    };

    return axios.request(config)
}

export default {
    getToken
}