import React, {Component} from 'react';
import Header from '../components/header/';
import Sidebar from '../components/sidebar/';
import Wrapper from '../components/wrapper/';
import WrapperContent from '../components/wrapper/wrapperContent';
import ProjectTopMenuNavigation from "./projectTopMenuNavigation";
import {fetchQuery} from "relay-runtime";
import environment from "../env/environment";
import queryCheckSiteOnline from "../libs/checkSiteOnline"
import Loading from "../libs/loading"
import AlertWithholdPage from '../libs/alertWithholdPage';
// import {Translation} from "react-i18next";

class ProjectDashboard extends Component {

    constructor(props){
        super(props)

        this.state = {
            loading: false,
            checkOnline: true
        }
    }

    componentWillMount(){
        this.setState({loading: true})
        fetchQuery(environment, queryCheckSiteOnline).then(data => {
            this.setState({checkOnline: data.checkOnline, loading: false})
        });
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    {this.state.loading ? <Loading/>
                        : !this.state.checkOnline ? <AlertWithholdPage/>
                            : <ProjectTopMenuNavigation center={true}/>
                    }
                    {/*<div className="container-fluid box">*/}
                    {/*    <div className="content-inner">*/}
                    {/*        <div className="row justify-content-between">*/}
                    {/*            <div className="col">*/}
                    {/*                <h3 className="mb-4">*/}
                    {/*                    <Translation>*/}
                    {/*                        {*/}
                    {/*                            t => <span>{t('project:dashboard')}</span>*/}
                    {/*                        }*/}
                    {/*                    </Translation>*/}
                    {/*                </h3>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="row">*/}
                    {/*            <div className="col">*/}
                    {/*                <div className="card-wrapper">*/}
                    {/*                    <div className="card-body">*/}
                    {/*                        This is some text within a card body.*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default ProjectDashboard;
