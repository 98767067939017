/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type purchasingTopMenuNavigationQueryVariables = {||};
export type purchasingTopMenuNavigationQueryResponse = {|
  +getNotificationProcurementForm: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
  +getNotificationWaitPurchaseOrderes: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
|};
export type purchasingTopMenuNavigationQuery = {|
  variables: purchasingTopMenuNavigationQueryVariables,
  response: purchasingTopMenuNavigationQueryResponse,
|};
*/


/*
query purchasingTopMenuNavigationQuery {
  getNotificationProcurementForm {
    type
    count
  }
  getNotificationWaitPurchaseOrderes {
    type
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NotificationType",
    "kind": "LinkedField",
    "name": "getNotificationProcurementForm",
    "plural": true,
    "selections": (v0/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "NotificationType",
    "kind": "LinkedField",
    "name": "getNotificationWaitPurchaseOrderes",
    "plural": true,
    "selections": (v0/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "purchasingTopMenuNavigationQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "purchasingTopMenuNavigationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6bb2b5fc6badeb3c8516f1abfc4dc1f2",
    "id": null,
    "metadata": {},
    "name": "purchasingTopMenuNavigationQuery",
    "operationKind": "query",
    "text": "query purchasingTopMenuNavigationQuery {\n  getNotificationProcurementForm {\n    type\n    count\n  }\n  getNotificationWaitPurchaseOrderes {\n    type\n    count\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ca585200b6d1441912e4decc32083e7';

module.exports = node;
