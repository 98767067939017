import React from 'react';
import { Translation } from 'react-i18next';
import "./juristicCompany.scss"
import SearchProject from '../searchProject';
import ComponentPagination from '../../libs/componentPagination';
import Pagination from '../../libs/newPagination';
import numberWithComma from "../../libs/numberWithComma"
import {Link} from "react-router-dom";
import { format } from 'date-fns';
import {graphql} from "babel-plugin-relay/macro";
import environment from '../../env/environment';
import {QueryRenderer , commitMutation} from 'react-relay';
import Swal from 'sweetalert2';
import i18n from 'i18next';
import _ from 'lodash'
import Loading from "../../libs/loading";

const query = graphql `
query detailTeamQuery ($teamType: String, $search: String,$first: Int, $last: Int, $employmentStatus: String) {
    allTeam (teamType: $teamType, search: $search ,  first:$first, last:$last, employmentStatus:$employmentStatus) {
        totalCount
        edges {
            node {
                id
                teamType
                firstName
                lastName
                department
                position
                authorizationRight
                maximumApprovalLimit
                dateOfPosition
                onlineStatus
                image
                employmentStatus
            }
        }
    }
}

`

const mutation = graphql `
    mutation detailTeamMutation($input: UpdateTeamInput!){
        updateTeam(input: $input) {
            ok
        }       
    }
`

class DetailTeam extends ComponentPagination{
    constructor(props){
        super(props);
        this.state = {
            person : [],
            status: this.props.status,
            search : '',
            employmentStatus : '',
            loading : false,
            redirect : false
        }
        this.handleSearch = this.handleSearch.bind(this)
    }

    handleSearch(text){
        this.setState({search: text})
    }

    componentDidUpdate(){
        if(this.state.status !== this.props.status){
            this.setState({
                status : this.props.status
            })
        }
    }

    handleChange = (e) =>{
        this.setState({
            employmentStatus : e.target.value
        })
    }

    onDelete = (id) => {
        Swal.fire({
            title: i18n.t('juristicManager:Are you sure to remove the staff?'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t('juristicManager:Confirm'),
            cancelButtonText: i18n.t('juristicManager:Cancel')
        }).then(async (result) => {
            if (result.value) {
                this.setState({ loading: true });
                let variables = {
                    input: {
                        team :
                        {   id: id,
                            status : 'delete',
                            document : []
                        }
                    }
                };
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted:(response) => {
                            if (response.updateTeam.ok) {
                                Swal.fire(
                                    i18n.t("juristicManager:Success!"),
                                    i18n.t("juristicManager:Changed Success"),
                                    "success"
                                    ).then(() => this.setState({ redirect: true, loading: false }) );
                                } 
                            },
                        onError: (err) => {
                            this.setState({ loading: false });
                            Swal.fire(
                                'ไม่สามารถลบบุคลากรได้',
                                i18n.t("juristicManager:Please try again"),
                                "error"
                            );
                        },
                    },
                )
                  
            }else{
                this.setState({loading: false});
            }
        });
    }

    render(){
        return(
            <Translation>
                {t =>
                    <div className="pr-3">
                        <div className="row mt-3 ">
                            <div className="col-9">
                                <div className="row">
                                    <label className="col-md-2" htmlFor="employmentStatus">{t('juristicCompany:Choose status')}</label>
                                    <select className="form-control dropdown-custom-arrow col-3"
                                        value={this.state.employmentStatus}
                                        onChange={this.handleChange}
                                        name="person.employmentStatus"
                                        id="employmentStatus">
                                        <option value="">{t('juristicCompany:All')}</option>
                                        <option value="available">{t('juristicCompany:Working')}</option>
                                        <option value="non-available">{t('juristicCompany:Out of contact')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-3 float-right input-group">
                                <SearchProject callback_function={this.handleSearch} search={this.state.search}/>
                            </div>
                        </div>
                        <div className="mt-3">
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                variables ={{
                                    teamType: this.state.status,
                                    search:this.state.search,
                                    first : this.state.first,
                                    last : this.state.last,
                                    employmentStatus : this.state.employmentStatus,
                                    redirect : this.state.redirect
                                }}
                                cacheConfig={{use_cache: false}}
                                render={({error, props}) => {
                                    if(error){
                                        return <div>{error.message}</div>;
                                    }else if(props){
                                            return(
                                                <React.Fragment>
                                                    <div className="table-responsive " >
                                                        <table className="table table-hover">
                                                            <thead className="thead-light ">
                                                                <tr>
                                                                    <th/>
                                                                    <th className="text-left" >{t("juristicCompany:Name - Surname")}</th>
                                                                    <th className="text-center" >{t("juristicCompany:Department")}</th>
                                                                    <th className="text-center" >{t("juristicCompany:Position")}</th>
                                                                    <th className="text-center" >{t("juristicCompany:Authorization right")}</th>
                                                                    <th className="text-center" >{t("juristicCompany:Maximum approval limit")}</th>
                                                                    <th className="text-center" >{t("juristicCompany:Date of position")}</th>
                                                                    <th className="text-center" >{t("juristicCompany:Status")}</th>
                                                                    {/* <th className="text-center" >{t("juristicCompany:Online status")}</th> */}
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                               {props.allTeam.edges.map((person) => { 
                                                                    return(
                                                                        <tr key={person.node.id}>
                                                                            <td className="text-center">
                                                                                {person.node.image ?
                                                                                    <div
                                                                                        className="rounded-circle avatar-img profile-img juristic img-border"
                                                                                        style={{backgroundImage: `url(${person.node.image})`}}>
                                                                                    </div>
                                                                                    :
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                                        className="rounded-circle avatar-img  juristic profile-img"
                                                                                        alt="project-manager-profile"/>
                                                                                }
                                                                            </td>
                                                                            <td className="text-left">
                                                                                <Link
                                                                                    to={"/project/juristic-company/form/" + this.state.status + "/edit/" +
                                                                                        person.node.id
                                                                                    }
                                                                                >
                                                                                    {person.node.firstName} {person.node.lastName}
                                                                                </Link></td>
                                                                            <td className="text-left">{person.node.department}</td>
                                                                            <td className="text-left">{person.node.position}</td>
                                                                            <td className="text-center">
                                                                                <input type="checkbox" disabled={true} checked={person.node.authorizationRight}/>
                                                                            </td>
                                                                            <td className="text-right">{numberWithComma(person.node.maximumApprovalLimit,'-')}</td>
                                                                            <td className="text-center">{format(person.node.dateOfPosition,'DD/MM/YYYY')}</td>
                                                                            <td className ="text-center">
                                                                                <div className={person.node.employmentStatus === 'available' ? "round-status-ownership" :  "round-status-not-ownership"}><span>{person.node.employmentStatus === 'available' ? t('juristicCompany:Working') : t('juristicCompany:Out of contact')}</span></div>
                                                                            </td>
                                                                            <td>
                                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_juristiccompany_delete' }) &&
                                                                                    <React.Fragment>
                                                                                        <span className="btn cursor" id="dropdownMenuButton"
                                                                                            data-toggle="dropdown" aria-haspopup="true"  aria-expanded="false">
                                                                                            <img  src={process.env.PUBLIC_URL +"/images/icons/choice-trans.png"} alt="choice"/>
                                                                                        </span>
                                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                            <span className="dropdown-item cursor" onClick={() => this.onDelete(person.node.id)}>
                                                                                                <span className="ml-3 text-black">
                                                                                                {t("projectManager:Delete")}
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </td>
                                                                            {/* <td className="text-center">{format(person.node.onlineStatus , 'DD/MM/YYYY HH:mm')}</td> */}
                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="row">
                                                        <Pagination
                                                        changePage={this.changePage}
                                                        first={this.state.first}
                                                        last={this.state.last}
                                                        totalCount={props.allTeam.totalCount}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            );
                                    }
                                    return <Loading/>
                                }
                            }
                            />
                                        
                        </div>
                    </div>
                } 
            </Translation>


        );
    }


}
export default DetailTeam;