import React from "react";
import _ from "lodash";
import numberWithComma from "../../libs/numberWithComma";
import {Link} from "react-router-dom";

class RowRender extends React.Component {

    constructor(props) {
        super(props);
        this.state = {shutter: {}};
        this.clickOpen = this.clickOpen.bind(this);
    }

    componentWillMount() {
        let chartShutter = {};
        this.props.chartTree.map((chart) => {
            return _.set(chartShutter, chart.data.code, this.props.open);
        });
        this.setState({shutter: chartShutter})
    }

    clickOpen(code) {
        let chartShutter = _.cloneDeep(this.state.shutter);
        _.set(chartShutter, code, !_.get(chartShutter, code));
        this.setState({shutter: chartShutter})
    }

    render() {
        return (
            this.props.chartTree.map((chart) => {
                return (
                    <React.Fragment key={chart.data.code}>
                    <tr className={this.props.level === 0 ? "head" : "sub fade-up"}
                        onClick={() => {if (chart.data.code.split("-")[1] === "00") {this.clickOpen(chart.data.code)}}}>
                        <td>{chart.data.code}</td>
                        <td>
                            {Array(this.props.level).fill().map((value, index) => {
                                return <span className="partition" key={chart.data.code+'-'+index}>
                                    |&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            })}
                            {chart.data.code.split("-")[1] === "00" && 
                            <strong> 
                                {chart.data.name}
                            </strong>
                            }
                            {chart.data.code.split("-")[1] !== "00" &&
                            <span>
                                {
                                  chart.data.name === 'ค่าบริการออกเอกสารติดตามโดยทนายความ' ?  
                                  'ค่าบริการออกเอกสารติดตามโดยทนายความ / service fee notice':
                                  <Link to={"/accounting/chart-of-account/form/"+chart.data.id}>
                                    {chart.data.name} {chart.data.nameEn ? " / "+chart.data.nameEn : ""}
                                 </Link>
                                }
                            </span>
                            }
                            
                        </td>
                        <td className="text-center">
                            {chart.data.code.split("-")[1] === "00" &&
                                <span>✓</span>
                            }
                        </td>
                        <td>
                            {chart.data.category}
                            {this.props.level === 0 &&
                            <span className="ml-1 small">
                                ({_.get(this.props.chartCount, chart.data.code.charAt(0), 0)})
                            </span>
                            }
                        </td>

                        <td className="text-right">
                            {chart.data.code.split("-")[1] !== "00" && numberWithComma(chart.data.totalHistoricalDebit)}
                        </td>
                        <td className="text-right">
                            {chart.data.code.split("-")[1] !== "00" && numberWithComma(chart.data.totalHistoricalCredit)}
                        </td>
                    </tr>
                    {this.state.shutter[chart.data.code] && _.get(chart, 'children') &&
                        <RowRender chartTree={chart['children']} child={true} level={this.props.level+1} open={this.state.shutter[chart.data.code]} />
                    }
                    </React.Fragment>
                )
            })
        )
    }
}

export default RowRender;

