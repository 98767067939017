import React from "react";
import "../../styles/commonArea.scss";
import "./styleGallery.scss";
import { Translation } from "react-i18next";
const Gallery = (props) => {
  return (
    <Translation>
      {t =>
        <div className="booking-form mb-3  mt-5 col-12" id="gallery">
          <div className="switch-languages-div">
            <h2>{t("newBookingSingha_Create_step3:Gallery")}</h2>
          </div>
          <div className="row">
            <div className="col-12 mt-3 mb-2">
              <h3>{t("newBookingSingha_Create_step3:Cover picture")}</h3>
            </div>
          </div>
          <div className="upload mt-3 mb-3">
            {props.state.image_cover ? (
              <React.Fragment>
                <img
                  src={
                    props.state.change_image_upload
                      ? URL.createObjectURL(props.state.image_cover)
                      : props.state.image_cover
                  }
                  alt="news_preview"
                  className="img-fluid image card-img fit-image"
                />
                <div className="change-preview-image">
                  <label className="cursor">
                    <span className="text-blue">{t("newBookingSingha_Create_step3:Change picture")}</span>
                    <input
                      type="file"
                      name="image_upload"
                      accept="image/*"
                      hidden
                      onChange={props.handleInputImage}
                    />
                  </label>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <label className="btn btn-default button-upload cursor">
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/image-blue.png"}
                    alt="news_preview_delete"
                  />
                  <span className="text-blue">{t("newBookingSingha_Create_step3:Upload picture")}</span>
                  <input
                    type="file"
                    name="image_upload"
                    hidden
                    accept="image/*"
                    onChange={props.handleInputImage}
                  />
                </label>
                <div className="mt-2">
                  <div className="text-upload">
                    {t("newBookingSingha_Create_step3:Image files .jpg, .png only and file size not over 2000 x 2000 pixels")}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>

          <div className="row mt-1">
            <div className="col-12 pl-3 mb-3 mt-2">
              <h3>{t("newBookingSingha_Create_step3:Album")}</h3>
            </div>
            <div className="col-8">
              <div className="row col-12">
                {props.state.facility_image.length > 0 &&
                  props.state.facility_image.map((image, index) => {
                    let Image_show = (
                      <div className="mb-2 ml-2 " key={index}>
                        <div className="upload-new-image ">
                          <img
                            src={
                              typeof image.facility_image == "string"
                                ? image.facility_image
                                : URL.createObjectURL(image.facility_image)
                            }
                            alt="news_preview"
                            className="image"
                          />
                        </div>
                        <img
                          src={process.env.PUBLIC_URL + "/images/icons/delete.png"}
                          alt="news_preview_delete"
                          className="cursor"
                          onClick={() => props.onDelete(index)}
                        />
                      </div>
                    );

                    return image.status_image !== "delete" && Image_show;
                  })}
                <div className="col-3">
                  <div className="upload-new-image border-line-blue btn btn-light ">
                    <label htmlFor="uploadNewsImages" className="cursor">
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/icon_plus.png"}
                        alt="plus-icon"
                        className="plus"
                      />
                      <input
                        type="file"
                        name="image"
                        id="uploadNewsImages"
                        value=""
                        accept="image/*"
                        hidden
                        onChange={props.handleInputImage}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row justify-content-end p-3 ">
        {props.edit ?
          <React.Fragment>
             {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_facility_edit' }) && 
               <button
                disabled={props.button_load}
                type="submit"
                className="btn btn-primary mr-3 pl-3 pr-3 pt-2 pb-2"
              >
                เปลี่ยนแปลง
              </button>
            }
           {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_facility_delete' }) && 
            <button
              type="button"
              onClick={props.deleteFacility}
              disabled={props.button_load}
              className={
                props.edit ? " btn btn-outline-danger" : "btn btn-outline-primary"
              }
            >
              ลบส่วนกลาง
            </button>
            }
            
          </React.Fragment>
          :
          <React.Fragment>
             <button
              disabled={props.button_load}
              type="submit"
              className="btn btn-primary mr-3 pl-3 pr-3 pt-2 pb-2"
            >
              บันทึก
            </button>
            <button
              type="button"
              onClick={props.deleteFacility}
              disabled={props.button_load}
              className={
                props.edit ? " btn btn-outline-danger" : "btn btn-outline-primary"
              }
            >
              ยกเลิก
            </button>

          </React.Fragment>

        }
       
      </div> */}
        </div>
      }
    </Translation>
  );
};

export default Gallery;
