import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, commitMutation } from "relay-runtime";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import { format } from "date-fns";
import _ from "lodash";
import i18n from "i18next";
import "./settingDeveloper.scss"

const mutation = graphql`
    mutation settingDeveloperMutation($input: CreateDeveloperProjectInput!){
        createDeveloperProject(input:$input){
            ok
        }
    }`

const query = graphql`
    query settingDeveloperQuery {
        allDeveloperProject{
        edges{
            node{
                id
                transferOwnership
                transferOwnershipDate
                crmLink
                crmLinkConnect
                crmLinkConnectDate
                creator
                editor
            }
        }
    }
    }`;

let developerData ={
    transferOwnership: "",
    transferOwnershipDate: new Date(),
    crmLink: "",
    crmLinkConnect: "Icon Rem",
    crmLinkConnectDate: new Date(),
}

const SettingDeveloper = () => {
    const [questionOne, setQuestionOne] = useState("unidentified")
    const [questionTwo, setQuestionTwo] = useState("unidentified")
    const [needChoice, setNeedChoice] = useState("unidentified")
    const [transferDate, setTransferDate] =useState(new Date())
    // const [connectApi, setConnectApi] = useState(connectApiData)

    useEffect(() => {
        fetchData()
    }, [])

    const onSubmit =()=>{

        developerData = 
            {
            transferOwnership: questionOne,
            transferOwnershipDate: format(new Date()),
            crmLink: questionTwo,
            crmLinkConnect: "Icon Rem",
            crmLinkConnectDate: format(new Date())
            }
        

        let variables = {
            input:{
                developer: JSON.stringify(developerData)
            }
        }

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    if (response.createDeveloperProject !==null) {
                        Swal.fire(i18n.t('settingNote:Save successfully'), '', 'success').then(() => {
                            // this.setState({ redirectToList: true, loading: false });
                            fetchData()
                        })
                    } else {
                        Swal.fire(i18n.t('settingNote:Can\'t save'), '', 'warning');

                    }
                },
                onError: (err) => {
                    console.log("error", err);
                },
            },
        );
    }

    const fetchData =()=>{
        fetchQuery(environment, query, {
        }).then(data => {
            let data_clone = _.cloneDeep(data);
            if (data_clone) {
                let data = data_clone.allDeveloperProject.edges
                if (data.length > 0) {
                    let text = ""
                    let textQuestionTwo =""
                    switch (data[0].node?.transferOwnership) {
                        case "ALL":
                            text = "all";
                            break;
                        case "NO":
                            text = "no";
                            break;
                        default:
                            text = "unidentified";
                    }
                    switch (data[0].node?.crmLink) {
                        case "ALL":
                            textQuestionTwo = "all";
                            break;
                        case "NO":
                            textQuestionTwo = "no";
                            break;
                        default:
                            textQuestionTwo = "unidentified";
                    }
                    setQuestionOne(text)
                    setQuestionTwo(textQuestionTwo)
                    setTransferDate(data[0].node?.transferOwnershipDate)
                }
            }
        })
    }

    return (
        <Wrapper>
            <Header />
            <Sidebar />
            <Translation>
                {
                    t =>
                        <WrapperContent>
                            <div className="container-fluid box" id="developer-setting">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/setting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>
                                            <span>{t('setting:settingDeveloper')}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div className="content-inner row fade-up">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <form >
                                            <div className="col mt-5">
                                                <span className="h1">{t('settingDeveloper:Question1')}</span>
                                                <div className="custom-control custom-radio mt-3">
                                                    <input type="radio" className="custom-control-input"
                                                        id="transferOwnershipAll" name="firstQuestion"
                                                        value="all"
                                                        checked={questionOne === "all"}
                                                        onChange={(e) => setQuestionOne(e.target.value)}
                                                        disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_developerproject_edit'})}
                                                    />
                                                    <label className="custom-control-label"
                                                        htmlFor="transferOwnershipAll">
                                                        <div className="row pl-3">
                                                            <span className="h2">{t('settingDeveloper:Question1_ans1')}</span>
                                                            {questionOne ==="all" ?
                                                            <span className="h3">{t('settingDeveloper:date')} {format(transferDate,"DD/MM/YYYY")}</span>
                                                            : null
                                                            }
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-radio mt-2">
                                                    <input type="radio" className="custom-control-input"
                                                        id="transferOwnershipNo" name="firstQuestion"
                                                        value="no"
                                                        checked={questionOne === "no"}
                                                        onChange={(e) => setQuestionOne(e.target.value)}
                                                        disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_developerproject_edit'})}

                                                    />
                                                    <label className="custom-control-label"
                                                        htmlFor="transferOwnershipNo">
                                                        <span className="h2">{t('settingDeveloper:Question1_ans2')}</span>
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-radio mt-2">
                                                    <input type="radio" className="custom-control-input"
                                                        id="transferOwnershipUnidentified" name="firstQuestion"
                                                        value="unidentified"
                                                        checked={questionOne === "unidentified"}
                                                        onChange={(e) => setQuestionOne(e.target.value)}
                                                        disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_developerproject_edit'})}

                                                    />
                                                    <label className="custom-control-label"
                                                        htmlFor="transferOwnershipUnidentified">
                                                        <span className="h2">{t('settingDeveloper:na')}</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col mt-5">
                                                <span className="h1">{t('settingDeveloper:Question2')}</span>
                                                <div className="custom-control custom-radio mt-3">
                                                    <input type="radio" className="custom-control-input"
                                                        id="crm_api_yes" name="secondQuestion"
                                                        value="all"
                                                        checked={questionTwo === "all"}
                                                        onChange={(e) => setQuestionTwo(e.target.value)}
                                                        disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_developerproject_edit'})}

                                                    />
                                                    <label className="custom-control-label"
                                                        htmlFor="crm_api_yes">
                                                        <span className="h2">{t('settingDeveloper:yes')}</span>
                                                    </label>
                                                </div>
                                                {questionTwo === "all" ?
                                                    <div className='col mt-3 ml-3'>
                                                        <span className="h2">ระบุเครื่องมือที่ต้องการเชื่อมต่อ  API</span>
                                                        <div className=" row custom-control custom-radio mt-2 ml-2">
                                                            <input type="radio" className="custom-control-input"
                                                                id="crm_api_need" name="need_choice_api"
                                                                value="iconRem"
                                                                checked={needChoice === "iconRem"}
                                                                onChange={(e) => setNeedChoice(e.target.value)}
                                                                disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_developerproject_edit'})}

                                                            />
                                                            <label className="custom-control-label"
                                                                htmlFor="crm_api_need">
                                                                <span className="h2">Icon REM</span>
                                                            </label>
                                                        </div>
                                                        <div className="row ml-2">
                                                            <div className="custom-control custom-radio mt-2">
                                                                <input type="radio" className="custom-control-input"
                                                                    id="other_unidentified" name="need_choice_api"
                                                                    value="unidentified"
                                                                    checked={needChoice === "unidentified"}
                                                                    onChange={(e) => setNeedChoice(e.target.value)}
                                                                    disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_developerproject_edit'})}

                                                                />
                                                                <label className="custom-control-label"
                                                                    htmlFor="other_unidentified">
                                                                    <span className="h2">{t('settingDeveloper:na')}</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-6">
                                                                <input type="text" className="form-control"
                                                                    name="unidentified"
                                                                    disabled={needChoice === "unidentified" ? false : true || !_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_developerproject_edit'})} />
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    null
                                                }

                                                <div className="custom-control custom-radio mt-2">
                                                    <input type="radio" className="custom-control-input"
                                                        id="crm_api_no" name="secondQuestion"
                                                        value="no"
                                                        checked={questionTwo === "no"}
                                                        onChange={(e) => setQuestionTwo(e.target.value)}
                                                        disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_developerproject_edit'})}

                                                    />
                                                    <label className="custom-control-label"
                                                        htmlFor="crm_api_no">
                                                        <span className="h2">{t('settingDeveloper:no')}</span>
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-radio mt-2">
                                                    <input type="radio" className="custom-control-input"
                                                        id="crm_api_unidentified" name="secondQuestion"
                                                        value="unidentified"
                                                        checked={questionTwo === "unidentified"}
                                                        onChange={(e) => setQuestionTwo(e.target.value)}
                                                        disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_developerproject_edit'})}

                                                    />
                                                    <label className="custom-control-label"
                                                        htmlFor="crm_api_unidentified">
                                                        <span className="h2">{t('settingDeveloper:na')}</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row mt-5 ">
                                                <div className="col"></div>
                                                <div className="row">
                                                   {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_developerproject_edit'}) &&
                                                    <div className="col text-left mt-2 button-grp">
                                                            <button type="button" className="add btn btn-outline-primary float-left mr-3"
                                                                onClick={onSubmit}>
                                                                {t('Allaction:save')}
                                                            </button>
                                                            <Link to={"/setting"}>
                                                                <button
                                                                    type="button"
                                                                    className="add btn btn-outline-secondary float-left mr-3"
                                                                >
                                                                    <span>{t('Allaction:cancel')}</span>
                                                                </button>
                                                            </Link>
                                                        </div>  
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </WrapperContent>
                }
            </Translation>
        </Wrapper>
    )
}

export default SettingDeveloper