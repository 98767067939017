import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import WrapperContent from '../../components/wrapper/wrapperContent'
import { Translation } from 'react-i18next'
import ManageOrderTable from './manageOrderTable'
import ClaimTopMenuNavigation from '../claim/claimTopMenuNavigation'
import BackButtonIcon from "../../components/BackBtn/indexBack";
import i18next from 'i18next'

export default class manageOrderList extends Component {
  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <ClaimTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box" id="manageOrderList">
                <BackButtonIcon
                  LinkBack={"/accounting"}
                  LinkText={i18next.t("PageListPRPO:Purchase Order")+" "+"(PO)"}
                />

                <div className="content-inner">
                  <ManageOrderTable status={this.props.match.params.status} />
                </div>
              </div>
            }
          </Translation>

        </WrapperContent>
      </Wrapper>
    )
  }
}
