
import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import {getYear} from "date-fns";
import FirstLotteryAward from "./firstLotteryAward"
import Loading from '../libs/loading';
import './lottery.scss'


class viewLotteryAwardAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            period_date: "",
            lottery_year_list: [],
            lotteryAward:[],
            dateLotteryAward:""
        }

        this.getThaiYear = this.getThaiYear.bind(this);
        this.getLotteryDate = this.getLotteryDate.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.getLotteryAward = this.getLotteryAward.bind(this)
    }

    componentDidMount() {
        let year_now =  getYear(new Date)
        this.getLotteryDate(year_now)
     }

    getDateList(data){ 
        const result = data?.filter((obj, index) => {
            return index === data?.findIndex(o => obj.date === o.date);
          });
          
        return result
    }

    getThaiYear(year) {
        let year_thai = parseInt(year) + 543
        return (year_thai).toString()
    }

    getLotteryDate(year){
        var raw = JSON.stringify({
            year: year,
            type: "CHECKED"
        });

        fetch("https://svmsupport.silverman.cloud/open/lottery/getPeriodsByYear", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
            },
            body: raw
        }).then((res) => res.json())
            .then((data) => {
                if(data){
                    this.getLotteryAward(data?.response?.result[0].date)
                    this.setState({
                        lottery_year_list:data?.response?.result,
                        period_date: data?.response?.result[0].date
                    })
                }
        });
    }

    getLotteryAward(data){

        let dayFormat = format(new Date(data), "DD")
        let monthFormat = format(new Date(data), "MM")
        let yearFormat = format(new Date(data), "YYYY")
 
        var raw = JSON.stringify({
             date: dayFormat,
             month: monthFormat,
             year: yearFormat
           });
 
         fetch("https://svmsupport.silverman.cloud/open/lottery/getLotteryAward", {
             method: 'POST',
             headers: {
                 'Content-Type': 'application/json', 
             },
             body: raw
         }).then((res) => res.json())
           .then((data) => {
                 if(data?.response){
                     this.setState({
                         lotteryAward : data?.response?.data,
                         dateLotteryAward: data?.response?.date
                     })
                 }
         });
     }


    handleInputChange(e) {        
        if(e.target.name === 'year'){
            this.getLotteryDate(e.target.value)
        }else{
            this.getLotteryAward(e.target.value)
        }
      
        this.setState({[e.target.name]: e.target.value});
    }

    render(){

        let lottery_date_list = this.getDateList(this.state.lottery_year_list);
        let myLang = this.props.match?.params?.lang
        return(
            <>
            <div id="wrapper-content">
                <div id='svm-lottery' className='p-3' style={{minHeight:'670px'}}>
                    <Link to="/lottery-award/">
                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'} alt="back" className="back" />
                    </Link>
                    <div className='row justify-content-center p-2'>
                        <div className='col-6'>
                         <div className="input-group">
                              <select className="form-control preiod_date" name="year"
                                        onChange={this.handleInputChange}>
                                       {[2024,2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013].map((value) => {
                                            return (
                                                <option key={'year' + value} value={value}>
                                                    {this.getThaiYear(value)}
                                                </option>
                                            )
                                        })}
                              </select>
                        </div>
                        </div>
                        <div className='col-6'>
                                <div className="input-group">
                                    <select className="form-control preiod_date" name="period_date" value={this.state.period_date}
                                            onChange={this.handleInputChange}>
                                            {
                                                lottery_date_list.map((value) => {
                                                    let dateInThai = format(new Date(value.date).setFullYear(parseInt(new Date(value.date).getFullYear()) + 543), "DD MMM YYYY", { locale: thLocale})
                                                return (
                                                    <option value={value.date}>
                                                        {dateInThai}
                                                    </option>
                                                )
                                            })
                                            }
                                    </select>
                                </div>
                        </div>
                    </div>
                    {
                    this.state.dateLotteryAward ?  
                         <div className='lottery-award-content'>
                         <div className='p-4 mt-4' style={{backgroundColor:'#FFFFFF'}}>
                             <div className='row p-2'>
                                 <div className='col'>
                                     <div className=''>
                                         <h4 style={{margin:'0'}}>{myLang === 'en' ? 'Date' : 'งวดวันที่'}</h4>
                                         <span style={{color:'#808080'}}>{this.state.dateLotteryAward && format(new Date(this.state.dateLotteryAward && this.state.dateLotteryAward ).setFullYear(parseInt(new Date(this.state.dateLotteryAward && this.state.dateLotteryAward ).getFullYear()) + 543), "DD MMM YYYY", { locale: thLocale})}</span> 
                                     </div>
                                 </div>
                                 <div className='col mb-3 mt-3'>
                                     <Link to={{
                                             pathname:"/lottery-award/view-more/"+myLang,
                                             state:this.state
                                     }} >
                                        <div className='text-center' style={{color:'#1567B4'}}>
                                            <span>{myLang === 'en' ? 'View All' : 'ดูผลรางวัลทั้งหมด'}</span>
                                        </div>
                                     </Link>
                                 </div>
                             </div>
                             <div className='row p-2' style={{borderTop:'0.5px solid #BFBFBF'}}> 

                             </div>
                             {
                                 Object.keys(this.state.lotteryAward).length > 1 ?
                                     <FirstLotteryAward
                                     lotteryAward={this.state.lotteryAward}
                                     />  :<Loading/>
                             }
                         </div>
                        </div> : <Loading/>
                    }
                   
                </div>
            </div>
            </>
        )
    }

}

export default viewLotteryAwardAll;