import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import WrapperContent from '../../components/wrapper/wrapperContent'
import Sidebar from '../../components/sidebar'
import Header from "../../components/header/index"
import { Link } from 'react-router-dom'
import "./styles/commonArea.scss"
import { Translation } from 'react-i18next'

export default class addUpdateServiceSetting extends Component {

    render() {
        return (
            <Translation>
                {t =>
                    <Wrapper>
                        <Header />
                        <Sidebar />
                        <WrapperContent>
                            <div className="container-fluid gray" style={{ width: "100%", height: "48px" }} />
                            <div className="container-fluid box">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3>
                                            <Link to="/contact/global-area/services/all">
                                                <img
                                                    src={process.env.PUBLIC_URL +
                                                        "/images/icons/chevron-back.png"}
                                                    alt="back"
                                                    className="back"
                                                />
                                            </Link>
                                            {this.props.match.params.status === "create" ? t("commonAreaSetting:Add service") : this.props.match.params.id}

                                        </h3>
                                    </div>
                                </div>

                                <div className="frameMargin">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h6>รายละเอียดบริการ</h6>
                                                <form className="form-inline">
                                                    <label className="mr-2">{t("commonAreaSetting:Status")}:</label>
                                                    <select className="form-control">
                                                        <option value="เปิดให้บริการ">{t("commonAreaSetting:Open")}</option>
                                                        <option value="ยังไม่เปิดให้บริการ">{t("commonAreaSetting:Still closed")}</option>
                                                    </select>
                                                </form>
                                            </div>
                                            <div className="my-2" style={{ width: "100%", height: "1px", backgroundColor: "#E2E2E2" }} />

                                            <div>
                                                <p>{t("commonAreaSetting:Service No.")}</p>
                                                <input type="text" className="form-control col-8" disabled />
                                            </div>

                                            <div className="mt-2">
                                                <p>{t("commonAreaSetting:Service Name")}</p>
                                                <input type="text" className="form-control col-8" />
                                            </div>

                                            <div className="mt-2">
                                                <p>{t("commonAreaSetting:Connection to common areas")}</p>
                                                <div className="d-flex">
                                                    <input type="radio" className="mt-1 mr-2" />
                                                    <p>{t("commonAreaSetting:Not connect")}</p>
                                                </div>
                                                <div className="d-flex">
                                                    <input type="radio" className="mt-1 mr-2" />
                                                    <p >{t("commonAreaSetting:Connect with common areas")}</p>
                                                </div>
                                                {true &&
                                                    <div className="col-12">
                                                        <p>{t("commonAreaSetting:Select the common area to connect with services")}</p>
                                                        <select className="form-control col-4">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                        </select>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <h6>{t("commonAreaSetting:Service fee details")}</h6>
                                            <div className="mb-2 mt-3" style={{ width: "100%", height: "1px", backgroundColor: "#E2E2E2" }} />

                                            <div className="row">
                                                <div className="col-6">
                                                    <p>{t("commonAreaSetting:Service charge per unit (Baht)")}</p>
                                                    <input type="text" className="form-control" />
                                                </div>
                                                <div className="col-6">
                                                    <p>{t("commonAreaSetting:Unit")}</p>
                                                    <select className="form-control col-lg-4 col-6">
                                                        <option value="ชั่วโมง">{t("commonAreaSetting:Hour(s)")}</option>
                                                        <option value="2">2</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <h6>{t("commonAreaSetting:Account mapping")}</h6>
                                            <div className="mb-2 mt-3" style={{ width: "100%", height: "1px", backgroundColor: "#E2E2E2" }} />

                                            <div className="row">
                                                <div className="col-6">
                                                    <p>{t("settingAll:Income account")}</p>
                                                    <select className="form-control">
                                                        <option value="4100-06">4100-06 รายรับ - ค่าคีย์การ์ด</option>
                                                        <option value="4100-06">4100-06 รายรับ - ค่าคีย์การ์ด</option>
                                                    </select>
                                                </div>
                                                <div className="col-6">
                                                    <p>{t("settingAll:Account receivables")}</p>
                                                    <select className="form-control">
                                                        <option value="4100-06">4100-06 รายรับ - ค่าคีย์การ์ด</option>
                                                        <option value="4100-06">4100-06 รายรับ - ค่าคีย์การ์ด</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <h6>{t("commonAreaSetting:Refund account details or item cancellation")}</h6>
                                            <div className="mb-2 mt-3" style={{ width: "100%", height: "1px", backgroundColor: "#E2E2E2" }} />

                                            <div>
                                                <p>{t("settingAll:Income account")}</p>
                                                <input type="text" className="form-control col-6" disabled />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col d-flex justify-content-end">
                                            <button type="button" className="btn btn-primary">{t("Allaction:save")}</button>
                                            <button type="button" className="btn btn-outline-primary ml-3">{t("Allaction:cancel")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </WrapperContent>
                    </Wrapper>
                }
            </Translation>

        )
    }
}
