/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChequeTransactionStatus = "BIDE" | "CANCEL" | "CLEARING" | "DELETE" | "VOID" | "VOID_HISTORICAL" | "WAIT" | "%future added value";
export type chequeTransactionListAllQueryVariables = {|
  status?: ?string,
  first?: ?number,
  last?: ?number,
  chequeTransactionId?: ?string,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  historical?: ?string,
|};
export type chequeTransactionListAllQueryResponse = {|
  +allChequeTransactions: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +date: any,
        +note: ?string,
        +refNumber: ?string,
        +depositDate: ?any,
        +chequeDetails: ?{|
          +id: string,
          +noNumber: string,
        |},
        +chequeNo: ?{|
          +id: string,
          +noNumber: string,
        |},
        +chequeDate: ?any,
        +bankAccount: ?{|
          +id: string,
          +bankName: string,
        |},
        +chequeNumber: string,
        +chequeNoCustom: ?string,
        +chequeDetailsCustom: ?string,
        +payName: ?string,
        +docNumber: ?string,
        +description: ?string,
        +price: number,
        +status: ChequeTransactionStatus,
        +juristic: ?{|
          +firstName: string,
          +lastName: string,
        |},
        +chequeDocuments: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: ?string,
              +fileUpload: string,
            |}
          |}>
        |},
        +historical: boolean,
      |}
    |}>,
  |}
|};
export type chequeTransactionListAllQuery = {|
  variables: chequeTransactionListAllQueryVariables,
  response: chequeTransactionListAllQueryResponse,
|};
*/


/*
query chequeTransactionListAllQuery(
  $status: String
  $first: Int
  $last: Int
  $chequeTransactionId: String
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $historical: String
) {
  allChequeTransactions(chequeTransactionId: $chequeTransactionId, status: $status, first: $first, last: $last, search: $search, startDate: $start_date, endDate: $end_date, historical: $historical) {
    totalCount
    edges {
      node {
        id
        date
        note
        refNumber
        depositDate
        chequeDetails {
          id
          noNumber
        }
        chequeNo {
          id
          noNumber
        }
        chequeDate
        bankAccount {
          id
          bankName
        }
        chequeNumber
        chequeNoCustom
        chequeDetailsCustom
        payName
        docNumber
        description
        price
        status
        juristic {
          firstName
          lastName
          id
        }
        chequeDocuments {
          edges {
            node {
              id
              fileName
              fileUpload
            }
          }
        }
        historical
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chequeTransactionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "historical"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v8 = [
  {
    "kind": "Variable",
    "name": "chequeTransactionId",
    "variableName": "chequeTransactionId"
  },
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "historical",
    "variableName": "historical"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depositDate",
  "storageKey": null
},
v15 = [
  (v10/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "noNumber",
    "storageKey": null
  }
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ChequeDetailsNode",
  "kind": "LinkedField",
  "name": "chequeDetails",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "ChequeNoNode",
  "kind": "LinkedField",
  "name": "chequeNo",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chequeDate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccountNode",
  "kind": "LinkedField",
  "name": "bankAccount",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chequeNumber",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chequeNoCustom",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chequeDetailsCustom",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payName",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "ChequeDocumentsNodeConnection",
  "kind": "LinkedField",
  "name": "chequeDocuments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChequeDocumentsNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChequeDocumentsNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileUpload",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "historical",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "chequeTransactionListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ChequeTransactionNodeConnection",
        "kind": "LinkedField",
        "name": "allChequeTransactions",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ChequeTransactionNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChequeTransactionNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JuristicNode",
                    "kind": "LinkedField",
                    "name": "juristic",
                    "plural": false,
                    "selections": [
                      (v28/*: any*/),
                      (v29/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v30/*: any*/),
                  (v31/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "chequeTransactionListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ChequeTransactionNodeConnection",
        "kind": "LinkedField",
        "name": "allChequeTransactions",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ChequeTransactionNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChequeTransactionNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JuristicNode",
                    "kind": "LinkedField",
                    "name": "juristic",
                    "plural": false,
                    "selections": [
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v30/*: any*/),
                  (v31/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a0c0fa2cbd861073ff93827590b37c45",
    "id": null,
    "metadata": {},
    "name": "chequeTransactionListAllQuery",
    "operationKind": "query",
    "text": "query chequeTransactionListAllQuery(\n  $status: String\n  $first: Int\n  $last: Int\n  $chequeTransactionId: String\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $historical: String\n) {\n  allChequeTransactions(chequeTransactionId: $chequeTransactionId, status: $status, first: $first, last: $last, search: $search, startDate: $start_date, endDate: $end_date, historical: $historical) {\n    totalCount\n    edges {\n      node {\n        id\n        date\n        note\n        refNumber\n        depositDate\n        chequeDetails {\n          id\n          noNumber\n        }\n        chequeNo {\n          id\n          noNumber\n        }\n        chequeDate\n        bankAccount {\n          id\n          bankName\n        }\n        chequeNumber\n        chequeNoCustom\n        chequeDetailsCustom\n        payName\n        docNumber\n        description\n        price\n        status\n        juristic {\n          firstName\n          lastName\n          id\n        }\n        chequeDocuments {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n            }\n          }\n        }\n        historical\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9216e5332f9232ee4e11148d22ff0977';

module.exports = node;
