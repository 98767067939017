/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingBringForwardQueryVariables = {||};
export type settingBringForwardQueryResponse = {|
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +nameEn: ?string,
          +chartOfAccountCode: string,
          +totalHistoricalDebit: ?number,
          +totalHistoricalCredit: ?number,
        |}
      |}>,
    |}
  |},
  +allAccountProjectManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +tableAccount: ?any,
      |}
    |}>
  |},
|};
export type settingBringForwardQuery = {|
  variables: settingBringForwardQueryVariables,
  response: settingBringForwardQueryResponse,
|};
*/


/*
query settingBringForwardQuery {
  viewer {
    allChartOfAccount(getHistoricalValue: true) {
      totalCount
      edges {
        node {
          id
          name
          nameEn
          chartOfAccountCode
          totalHistoricalDebit
          totalHistoricalCredit
        }
      }
    }
    id
  }
  allAccountProjectManager {
    edges {
      node {
        id
        tableAccount
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "getHistoricalValue",
      "value": true
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameEn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chartOfAccountCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalCredit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allChartOfAccount(getHistoricalValue:true)"
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountProjectManagerNodeConnection",
  "kind": "LinkedField",
  "name": "allAccountProjectManager",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountProjectManagerNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountProjectManagerNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tableAccount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingBringForwardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingBringForwardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "ca1d7ffc0efdf961e3ac6fe32619670d",
    "id": null,
    "metadata": {},
    "name": "settingBringForwardQuery",
    "operationKind": "query",
    "text": "query settingBringForwardQuery {\n  viewer {\n    allChartOfAccount(getHistoricalValue: true) {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          nameEn\n          chartOfAccountCode\n          totalHistoricalDebit\n          totalHistoricalCredit\n        }\n      }\n    }\n    id\n  }\n  allAccountProjectManager {\n    edges {\n      node {\n        id\n        tableAccount\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c25ceb3a84c708b8d39085af8499a553';

module.exports = node;
