/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SilvermanAgentResidentialType = "COMMERCIAL" | "CONDO" | "HOTEL" | "HOUSE" | "LAND" | "OFFICE" | "OFFICE_HOME" | "OTHER" | "STORE" | "TOWNHOUSE" | "VILLA" | "WHAREHOUSE" | "%future added value";
export type SilvermanAgentUserType = "AGENT" | "BUYER" | "OWNER" | "TENANT" | "%future added value";
export type silvermanAgentViewQueryVariables = {|
  silvermanAgentId?: ?string
|};
export type silvermanAgentViewQueryResponse = {|
  +allSilvermanAgent: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +userType: SilvermanAgentUserType,
        +residentialType: SilvermanAgentResidentialType,
        +residentialOther: ?string,
        +announceSell: boolean,
        +announceForRent: boolean,
        +announceBuy: boolean,
        +announceDown: boolean,
        +announceLease: boolean,
        +announceLookRent: boolean,
        +province: ?string,
        +district: ?string,
        +projectName: ?string,
        +area: ?string,
        +topic: ?string,
        +description: ?string,
        +sizeArea: number,
        +bedroom: number,
        +bathroom: number,
        +floor: ?string,
        +floorSize: ?string,
        +rai: number,
        +ngan: number,
        +squareWa: number,
        +remarkPet: boolean,
        +remarkForeign: boolean,
        +remarkDuplex: boolean,
        +remarkPenthouse: boolean,
        +remarkHighFloor: boolean,
        +remark: ?string,
        +contactName: ?string,
        +callName: ?string,
        +phone: ?string,
        +line: ?string,
        +email: ?string,
        +silvermanPhone: boolean,
        +silvermanLine: boolean,
        +silvermanEmail: boolean,
        +price: number,
        +rentDate: ?any,
        +rentPrice: number,
        +downPrice: number,
        +realPrice: number,
        +leasePrice: number,
        +buyPrice: number,
        +lookRentPrice: number,
        +thirdTerm: boolean,
        +sixTerm: boolean,
        +twelveTerm: boolean,
        +silvermanAgentImage: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: ?string,
              +fileUpload: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type silvermanAgentViewQuery = {|
  variables: silvermanAgentViewQueryVariables,
  response: silvermanAgentViewQueryResponse,
|};
*/


/*
query silvermanAgentViewQuery(
  $silvermanAgentId: String
) {
  allSilvermanAgent(silvermanAgentId: $silvermanAgentId) {
    edges {
      node {
        id
        userType
        residentialType
        residentialOther
        announceSell
        announceForRent
        announceBuy
        announceDown
        announceLease
        announceLookRent
        province
        district
        projectName
        area
        topic
        description
        sizeArea
        bedroom
        bathroom
        floor
        floorSize
        rai
        ngan
        squareWa
        remarkPet
        remarkForeign
        remarkDuplex
        remarkPenthouse
        remarkHighFloor
        remark
        contactName
        callName
        phone
        line
        email
        silvermanPhone
        silvermanLine
        silvermanEmail
        price
        rentDate
        rentPrice
        downPrice
        realPrice
        leasePrice
        buyPrice
        lookRentPrice
        thirdTerm
        sixTerm
        twelveTerm
        silvermanAgentImage {
          edges {
            node {
              id
              fileName
              fileUpload
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "silvermanAgentId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "silvermanAgentId",
        "variableName": "silvermanAgentId"
      }
    ],
    "concreteType": "SilvermanAgentNodeConnection",
    "kind": "LinkedField",
    "name": "allSilvermanAgent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SilvermanAgentNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SilvermanAgentNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "residentialType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "residentialOther",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "announceSell",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "announceForRent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "announceBuy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "announceDown",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "announceLease",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "announceLookRent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "province",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "district",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "area",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sizeArea",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bedroom",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bathroom",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "floor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "floorSize",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rai",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ngan",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "squareWa",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remarkPet",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remarkForeign",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remarkDuplex",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remarkPenthouse",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remarkHighFloor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remark",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contactName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "callName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "line",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "silvermanPhone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "silvermanLine",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "silvermanEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rentDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rentPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "downPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "realPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "leasePrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "buyPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lookRentPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thirdTerm",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sixTerm",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "twelveTerm",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SilvermanAgentImageNodeConnection",
                "kind": "LinkedField",
                "name": "silvermanAgentImage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SilvermanAgentImageNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SilvermanAgentImageNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileUpload",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "silvermanAgentViewQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "silvermanAgentViewQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "25d47090d6985af2ac10615ef50cf38d",
    "id": null,
    "metadata": {},
    "name": "silvermanAgentViewQuery",
    "operationKind": "query",
    "text": "query silvermanAgentViewQuery(\n  $silvermanAgentId: String\n) {\n  allSilvermanAgent(silvermanAgentId: $silvermanAgentId) {\n    edges {\n      node {\n        id\n        userType\n        residentialType\n        residentialOther\n        announceSell\n        announceForRent\n        announceBuy\n        announceDown\n        announceLease\n        announceLookRent\n        province\n        district\n        projectName\n        area\n        topic\n        description\n        sizeArea\n        bedroom\n        bathroom\n        floor\n        floorSize\n        rai\n        ngan\n        squareWa\n        remarkPet\n        remarkForeign\n        remarkDuplex\n        remarkPenthouse\n        remarkHighFloor\n        remark\n        contactName\n        callName\n        phone\n        line\n        email\n        silvermanPhone\n        silvermanLine\n        silvermanEmail\n        price\n        rentDate\n        rentPrice\n        downPrice\n        realPrice\n        leasePrice\n        buyPrice\n        lookRentPrice\n        thirdTerm\n        sixTerm\n        twelveTerm\n        silvermanAgentImage {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '57fe7bcf3cd6d4476acc7cf8e52dcd97';

module.exports = node;
