import "../styles/createBudget.scss";
import React, { forwardRef } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import _ from "lodash";
import { Translation } from "react-i18next";
import DatePicker from "react-datepicker";
import { addMonths, format, setDate, lastDayOfMonth } from "date-fns";
import { Redirect } from "react-router";
import numberWithCommas from "../../../libs/numberWithComma";
import { Button } from "../../../components/Button/Button";
import code from "../json/chart_of_account.json";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import MonthlyBudgetUsageTableSummary from "./monthlyBudgetUsageTableSummary";
import $ from "jquery"
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import MonthlyBudgetUsageReportSummaryExcelExport from "./monthlyBudgetUsageReportSummaryExcelExport";
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import i18next from "i18next";
import GetdateOnEngAndTh from "../../../components/Action/getdateOnEngAndTh";

const query = graphql`
  query monthlyBudgetUsageReportSummaryQuery($yearId: String, $lastDate: Date) {
    oneYearBudget(yearId: $yearId) {
      edges {
        node {
          id
          years
          startDate
          endDate
          project {
            name
          }
          chartOfAccountBudget {
            edges {
              node {
                totalBudget
                chartOfAccount {
                  id
                  name
                  nameEn
                  chartOfAccountCode
                }
                monthBudget {
                  edges {
                    node {
                      months
                      budget
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allChartOfAccountBudget(yearId: $yearId, lastDate: $lastDate) {
      edges {
        node {
          totalBudget
          chartOfAccount {
            id
            name
            nameEn
            chartOfAccountCode
          }
          summaryYear
        }
      }
    }
    allMonthBudget(yearId: $yearId, lastDate: $lastDate) {
      edges {
        node {
          months
          budget
          totalMonthHappend
          chartOfAccountBudget {
            id
            chartOfAccount {
              id
              name
              nameEn
              chartOfAccountCode
            }
          }
        }
      }
    }
  }
`;

class CreateBudget extends React.Component {
  constructor(pros) {
    super(pros);
    this.state = {
      lists: _.cloneDeep(code),
      loading: true,
      year: new Date(),
      start_date: new Date(2021, 0, 1),
      end_date: new Date(2021, 11, 1),
      list_month: [],
      value: [],
      save: false,
      redirectToList: false,
      disabled_table: true,
      id: "",
      year_id: this.props.match.params.id,
      lastDate: this.props.match.params.endDate,
      project_name: "",
      date_search: setDate(this.props.match.params.endDate, lastDayOfMonth(this.props.match.params.endDate).getDate()),
      sum_income: {},
      sum_expenses: {},
      total_sum_expenses: {},
      widthTable1: 0
    };
  }

  async componentDidMount() {
    let width = $('table');
    this.setState({ widthTable1: width.width() })
    if (format(new Date(), "YYYY-MM-DD") > this.state.lastDate) {
      this.getData(this.state.lastDate)
    } else {
      this.getData(this.state.lastDate)
      // if (getMonth(this.state.lastDate) < getMonth(this.state.date_search)) {
      // } else {
      // this.getData(format(subMonths(this.state.date_search, 1), "YYYY-MM-DD"))
      // }
    }

    // this.getData()
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (this.state.date_search !== prevState.date_search) {
  //     this.getData(this.state.date_search)
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.widthTable1 !== this.state.widthTable1 || prevState.loading !== this.state.loading) {
      let width = $('table');
      this.setState({ widthTable1: width.width() })
    }
  }

  handleSearch = () => {
    this.setState({ lists: _.cloneDeep(code), disabled_table: true }, () => {
      this.getData(this.state.date_search)
    })
  }


  getData = async (lastDate) => {
    let budget;
    let key = {
      sum_total_budget: 0,
      sum_total_month_happend: [],
      sum_total_happend: 0,
      sum_percent_diff_past_happend: 0,
      sum_budget_remaining: 0,
      sum_percent_budget_remaining: 0,
    }
    let sum_income = _.cloneDeep(key)
    let sum_expenses = {}
    await fetchQuery(environment, query, { yearId: this.state.year_id, lastDate: format(lastDate, "YYYY-MM-DD") }).then((data) => { budget = data }).catch((error) => { });
    let start = new Date(budget.oneYearBudget.edges[0].node.startDate);
    let end = addMonths(new Date(this.state.date_search), 1);
    let list = _.cloneDeep(this.state.lists);
    let list_month = [];
    do {
      list_month.push(start);
      start = addMonths(start, 1);
    } while (format(start, "MM-YYYY") !== format(end, "MM-YYYY"));

    let allChartOfAccountBudget = await budget.allChartOfAccountBudget.edges.map((item) => {
      let data = _.cloneDeep(item);
      data.node.summaryYear = JSON.parse(item.node.summaryYear);
      return data;
    });

    for (const [index, item] of budget.oneYearBudget.edges[0].node.chartOfAccountBudget.edges.entries()) {
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 1) === "4") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[4].push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "51") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[5][0].child.push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "52") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[5][1].child.push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "53" && item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) !== "531") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[5][2].child.push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "531") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[5][3].child.push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "54") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[5][4].child.push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "55") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[5][5].child.push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "56") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[5][6].child.push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "57") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[5][7].child.push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "581") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[5][8].child.push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "582") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[5][8].child.push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "583") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        check_null &&
          list[5][8].child.push({
            ...item.node.chartOfAccount,
            total_budget: item.node.totalBudget || "",
            month_budget_happend: [],
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
    }

    await budget.allMonthBudget.edges.map((item) => {
      if (item.node.chartOfAccountBudget.chartOfAccount.chartOfAccountCode.slice(0, 1) === "4") {
        let index = _.findIndex(list[4], function (o) {
          return o.chartOfAccountCode == item.node.chartOfAccountBudget.chartOfAccount.chartOfAccountCode;
        });
        if (index !== -1) {
          list[4][index].month_budget_happend.push({ totalMonthHappend: item.node.totalMonthHappend });
        }
      } else if (item.node.chartOfAccountBudget.chartOfAccount.chartOfAccountCode.slice(0, 1) === "5") {
        let index_1 = _.findIndex(list[5], function (o) {
          if (item.node.chartOfAccountBudget.chartOfAccount.chartOfAccountCode.slice(0, 3) !== "531") {
            return o.key.slice(0, 2) == item.node.chartOfAccountBudget.chartOfAccount.chartOfAccountCode.slice(0, 2);
          } else {
            return o.key.slice(0, 3) == item.node.chartOfAccountBudget.chartOfAccount.chartOfAccountCode.slice(0, 3);
          }
        });
        let index_2 = _.findIndex(list[5][index_1].child, function (o) {
          return o.chartOfAccountCode == item.node.chartOfAccountBudget.chartOfAccount.chartOfAccountCode;
        });

        if (index_2 !== -1) {
          list[5][index_1].child[index_2].month_budget_happend.push({ totalMonthHappend: item.node.totalMonthHappend });
        }
      }
    });

    list[4].map((item) => {
      sum_income.sum_total_budget = parseFloat(sum_income.sum_total_budget || 0) + parseFloat(item.total_budget || 0)
      item.month_budget_happend.map((happend, index) => {
        sum_income.sum_total_month_happend[index] = parseFloat(sum_income.sum_total_month_happend[index] || 0) + parseFloat(happend.totalMonthHappend || 0)
      })
      sum_income.sum_total_happend = parseFloat(sum_income.sum_total_happend || 0) + parseFloat(item.allChartOfAccountBudget.node.summaryYear.total_happend || 0)
      sum_income.sum_percent_diff_past_happend = parseFloat(sum_income.sum_percent_diff_past_happend || 0) + parseFloat(item.allChartOfAccountBudget.node.summaryYear.percent_diff_past_happend || 0)
      sum_income.sum_budget_remaining = parseFloat(sum_income.sum_budget_remaining || 0) + parseFloat(item.allChartOfAccountBudget.node.summaryYear.budget_remaining || 0)
      sum_income.sum_percent_budget_remaining = parseFloat(sum_income.sum_percent_budget_remaining || 0) + parseFloat(item.allChartOfAccountBudget.node.summaryYear.percent_budget_remaining || 0)
    })
    list[5].map((item) => {
      let code = item.key
      if (!sum_expenses[code]) {
        sum_expenses[code] = _.cloneDeep(key)
      }
      item.child.map((item_child) => {
        sum_expenses[code].sum_total_budget = parseFloat(sum_expenses[code].sum_total_budget || 0) + parseFloat(item_child.total_budget || 0)
        item_child.month_budget_happend.map((happend, index) => {
          sum_expenses[code].sum_total_month_happend[index] = parseFloat(sum_expenses[code].sum_total_month_happend[index] || 0) + parseFloat(happend.totalMonthHappend || 0)
        })
        sum_expenses[code].sum_total_happend = parseFloat(sum_expenses[code].sum_total_happend || 0) + parseFloat(item_child.allChartOfAccountBudget.node.summaryYear.total_happend || 0)
        sum_expenses[code].sum_percent_diff_past_happend = parseFloat(sum_expenses[code].sum_percent_diff_past_happend || 0) + parseFloat(item_child.allChartOfAccountBudget.node.summaryYear.percent_diff_past_happend || 0)
        sum_expenses[code].sum_budget_remaining = parseFloat(sum_expenses[code].sum_budget_remaining || 0) + parseFloat(item_child.allChartOfAccountBudget.node.summaryYear.budget_remaining || 0)
        sum_expenses[code].sum_percent_budget_remaining = parseFloat(sum_expenses[code].sum_percent_budget_remaining || 0) + parseFloat(item_child.allChartOfAccountBudget.node.summaryYear.percent_budget_remaining || 0)
      })
    })

    let total_sum_expenses = _.cloneDeep(key)
    _.map(sum_expenses, (total_item, index) => {
      total_sum_expenses.sum_total_budget = parseFloat(total_sum_expenses.sum_total_budget || 0) + parseFloat(total_item.sum_total_budget || 0)
      total_item.sum_total_month_happend.map((happend, index) => {
        total_sum_expenses.sum_total_month_happend[index] = parseFloat(total_sum_expenses.sum_total_month_happend[index] || 0) + parseFloat(happend || 0)
      })
      total_sum_expenses.sum_total_happend = total_sum_expenses.sum_total_happend + parseFloat(total_item.sum_total_happend) || 0
      total_sum_expenses.sum_percent_diff_past_happend = total_sum_expenses.sum_percent_diff_past_happend + parseFloat(total_item.sum_percent_diff_past_happend) || 0
      total_sum_expenses.sum_budget_remaining = total_sum_expenses.sum_budget_remaining + parseFloat(total_item.sum_budget_remaining) || 0
      total_sum_expenses.sum_percent_budget_remaining = total_sum_expenses.sum_percent_budget_remaining + parseFloat(total_item.sum_percent_budget_remaining) || 0
    });

    this.setState({
      lists: list,
      loading: false,
      list_month,
      year: new Date(budget.oneYearBudget.edges[0].node.years),
      start_date: new Date(budget.oneYearBudget.edges[0].node.startDate),
      end_date: new Date(budget.oneYearBudget.edges[0].node.endDate),
      id: budget.oneYearBudget.edges[0].node.id,
      project_name: budget.oneYearBudget.edges[0].node.project.name,
      disabled_table: false,
      sum_income,
      sum_expenses,
      total_sum_expenses
    });
  }

  toFixedNumber = (value) => {
    let sub = parseFloat(value);
    sub = sub.toString();
    if (sub.indexOf(".") > 0) {
      return numberWithCommas(sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3));
    } else if (value == 0) {
      return "-";
    } else if (value == "") {
      return "-";
    } else {
      return numberWithCommas(sub);
    }
  };

  toggleHandleScroll = (scroll) => {
    let scroller = document.querySelector('.table-responsive2');
    let table = document.querySelector('.table-responsive');

    if (scroll === "table-responsive2") {
      table.scrollTo(scroller.scrollLeft, 0);
    } if (scroll === "table-responsive") {
      scroller.scrollTo(table.scrollLeft, 0);
    }
  }

  render() {
    let last_index = 0;
    if (this.state.redirectToList) {
      return <Redirect to="/accounting/budget/list/all" />;
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box budget p-4" id="budget">
                <BackButtonIcon
                  LinkBack={"/accounting/budget/report/report-month-budget-usage-summary"}
                  LinkText={i18next.t("menuListBudget:Monthly Budget Usage Report Summary Remaining Balance")}
                  boxHtmlText={
                    <div className="row mt-2 d-flex justify-content-end" id='interest-report' style={{ position: 'absolute', paddingRight: 32, right: 0, top: '1%' }} >
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_print' }) &&
                        <Dropdown >
                          <DropdownToggle id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>{i18next.t("Allaction:Print")}</DropdownToggle>
                          <DropdownMenu>
                            {/* <Dropdown.Item>
                        <Link to={{
                            pathname: `/accounting/budget/report/wrapper-report-seting/${this.props.match.params.id}/${this.props.match.params.year}`,
                            query: { lastDate: new Date() }
                        }}
                            className="text-black">
                            <p className="text-black">PDF</p>
                        </Link>
                    </Dropdown.Item> */}
                            <MonthlyBudgetUsageReportSummaryExcelExport
                              year={this.props.match.params.id}
                              state={this.state}
                            />
                          </DropdownMenu>
                        </Dropdown>
                      }
                    </div >
                  }
                />
                <div className="">
                  <div className="">
                    <h3 className="text-center  ">{this.state.project_name}</h3>
                    <h3 className="text-center  ">{i18next.t("menuListBudget:Monthly Budget Usage Report Summary Remaining Balance")}</h3>
                    <h3 className="text-center  ">
                      {`${i18next.t("AllFilter:Date")} `}
                      <GetdateOnEngAndTh
                        date={this.state.start_date}
                        withFullMonth={true}
                        withFullYear={true}
                      />
                      {` ${i18next.t("AllFilter:to")} `}
                      <GetdateOnEngAndTh
                        date={this.state.date_search}
                        withFullMonth={true}
                        withFullYear={true}
                      />
                    </h3>
                  </div>

                  <div className="d-flex mt-3 justify-content-end align-items-center">
                    <div className="bold">{i18next.t("AllFilter:to")}: </div>
                    <div className="">
                      <DatePicker
                        className="form-control float-left colorborder"
                        selected={new Date(this.state.date_search)}
                        onChange={(date) => this.setState({ date_search: format(date, "YYYY-MM-DD") })}
                        dateFormat="dd/MM/yyyy"
                        customInput={<ButtonDate date />}
                        minDate={new Date(this.state.start_date)}
                        maxDate={new Date(this.state.end_date)}
                      />
                    </div>
                    <Button primary onClick={this.handleSearch}>{i18next.t("AllFilter:Search")}</Button>
                  </div>
                </div>
                <MonthlyBudgetUsageTableSummary state={this.state} last_index={last_index} toggleHandleScroll={this.toggleHandleScroll} />
              </div>
            }
          </Translation>
        </WrapperContent>
      </Wrapper >
    );
  }
}

const ButtonDate = forwardRef(({ value, onClick, disabled, date }, ref) => (
  <button
    type="button"
    className="date form-control booking-form summary-report-date "
    style={{ width: "100%" }}
    onClick={onClick}
    ref={ref}
    disabled={disabled}>
    {value}
    {date && (
      <div
        className="calendars"
        style={{
          position: "absolute",
          right: 0,
          marginRight: 8,
        }}>
        <img className="icon" src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"} alt="calendars" />
      </div>
    )}
  </button>
));

export default CreateBudget;
