import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import i18n from 'i18next';
import {graphql} from "babel-plugin-relay/macro";
import CashDepositCreateSelectTransactionList from "./cashDepositCreateSelectTransactionList"
import CashDepositCreateForm from "./cashDepositCreateForm"
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Swal from "sweetalert2";

const allCashReceive = graphql`
    query cashDepositCreateQuery{
        cashTransactionViewer{
            allCashTransaction(status:"not_pass", cashType: "cash_in",createCashDeposit:true){
                edges{
                    node{
                        id
                        receive{
                            docNumber
                            issuedDate
                        }

                        invoice {
                            docNumber
                            issuedDate
                        }

                        receiptDeposit{
                            docNumber
                            issuedDate
                        }

                        guaranteeMoneyReceive {
                            docNumber
                            issuedDate
                        }
                        clearAdvance {
                            docNumber
                            issuedDate
                        }
                        otherReceive{
                            docNumber
                            issuedDate
                        }
                        description
                        price
                    }
                }
            }
        }
    }
`;

class CashDepositCreate extends Component {

    constructor() {
        super();
        this.state = {
            select_cash_transaction: []
        };
        this.updateSelectCashTransaction = this.updateSelectCashTransaction.bind(this);
        this.onClickPrintAll = this.onClickPrintAll.bind(this);

    }

    updateSelectCashTransaction(select_cash_transaction) {
        this.setState({select_cash_transaction: select_cash_transaction})
    }

    onClickPrintAll() {
        if (this.state.select_cash_transaction.length <= 0) {
            Swal.fire(i18n.t("cash:Please select"), '', 'warning');
        }

    }


    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="invoice-list">

                        <div className="row justify-content-between">
                            <div className="col">
                                <div className="row">
                                    <h3>
                                        <Link to="/accounting/finance/cash/list">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                 alt="back" className="back"/>
                                        </Link>
                                        <Translation>
                                            {
                                                t => <span>{t('cash:create_cash_deposit')}</span>
                                            }
                                        </Translation>
                                    </h3>

                                </div>
                            </div>
                        </div>

                        <div className="content-inner">
                            {this.props.match.params.status === 'select-receive' &&
                            <CashDepositCreateSelectTransactionList query={allCashReceive}
                                                                    updateSelectCashTransaction={this.updateSelectCashTransaction}/>
                            }
                            {this.props.match.params.status === 'form' &&
                            <CashDepositCreateForm select_cash_transaction={this.state.select_cash_transaction}/>
                            }

                        </div>

                    </div>
                </WrapperContent>

            </Wrapper>

        )

    }
}

export default CashDepositCreate;