/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createUpdateSettingJuristicQueryVariables = {|
  id: string
|};
export type createUpdateSettingJuristicQueryResponse = {|
  +juristic: ?{|
    +id: string,
    +user: ?{|
      +id: string,
      +username: string,
    |},
    +firstName: string,
    +lastName: string,
    +role: string,
    +image: ?string,
    +signature: string,
    +phone: string,
    +email: string,
  |}
|};
export type createUpdateSettingJuristicQuery = {|
  variables: createUpdateSettingJuristicQueryVariables,
  response: createUpdateSettingJuristicQueryResponse,
|};
*/


/*
query createUpdateSettingJuristicQuery(
  $id: ID!
) {
  juristic(id: $id) {
    id
    user {
      id
      username
    }
    firstName
    lastName
    role
    image
    signature
    phone
    email
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "JuristicNode",
    "kind": "LinkedField",
    "name": "juristic",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "image",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signature",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createUpdateSettingJuristicQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createUpdateSettingJuristicQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8e6bee537d445e944fde1d26a2b27e0c",
    "id": null,
    "metadata": {},
    "name": "createUpdateSettingJuristicQuery",
    "operationKind": "query",
    "text": "query createUpdateSettingJuristicQuery(\n  $id: ID!\n) {\n  juristic(id: $id) {\n    id\n    user {\n      id\n      username\n    }\n    firstName\n    lastName\n    role\n    image\n    signature\n    phone\n    email\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'efb17b834901445c4d1e97e124cdc56c';

module.exports = node;
