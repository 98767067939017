
import React, { Component } from 'react';
import Select from 'react-select';
import './regisCollectionLawFrim.scss'
import CollectionLawFrimStep from "./collectionLawFrimStep"
import { Translation } from 'react-i18next';
import i18n from "i18next";

class DownloadDocsCollectionLawFrim extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email_list: [],
        }
    }

    render() {
        return (
            <>
            <Translation>
                {
                t => (
                <div className=''>
                    <CollectionLawFrimStep 
                        regisCLFStepFinish1={this.props.regisCLFStepFinish1}
                        regisCLFStepFinish2={this.props.regisCLFStepFinish2}
                    />
                    <div className='row'>
                        <div className='col-4'>
                            <p>{t('collectionLawFirm:Specify the personnel you want to receive notifications')} <span className='text-red'>*</span></p>
                        </div>
                        <div className='col-5'>
                            {
                               this.props.email_list && this.props.email_list.map((data, index) =>  {
                                    return(
                                    <>
                                        <div className='pt-2 pb-2'>
                                            <div style={{ position: 'relative', textAlign: 'left', width: '100%' }}>
                                              <Select
                                                  options={this.props.AllUser}
                                                  onChange={this.props.handleChange}
                                                  placeholder={t('collectionLawFirm:Select Admin')}
                                                  name="userName"
                                               />   
                                                <span className='pl-4 pt-1' onClick={() => this.props.deleteRow(index)} style={{
                                                    position:'absolute',
                                                    top:'2px',
                                                    right:'-10%'
                                                }}>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/delete-lawyer.png'} />
                                                </span>                      
                                             </div>
                                           
                                        </div>
                                    </>
                                    )      
                                })
                            }
                        </div> 
                    </div>
                    <div style={{marginRight:'20%'}}>
                            <span className='text-red'> 
                                {this.props.showEmailAlert ? `${i18n.t("collectionLawFirm:Email Required")}`:''} 
                            </span>
                    </div>
                    
                    <div className='pt-2' style={{paddingRight:'30px'}}>
                        <button className='btn btn-addEmail' type='button'
                         onClick={() => this.props.appendNewRow()}
                        >
                            + {t('collectionLawFirm:Add more staff to receive notifications')} 
                        </button>
                    </div>
                    <div className='wrapper-box' style={{padding:'80px 10px',height:'400px'}}>
                        <div className="cursor btn-download-docs-law-frim">
                            <span className='pt-1'>
                                <a href='https://silverman-storage-backup.s3.ap-southeast-1.amazonaws.com/silverman-storage/CLF/FM-CF-01+%E0%B8%AB%E0%B8%99%E0%B8%B1%E0%B8%87%E0%B8%AA%E0%B8%B7%E0%B8%AD%E0%B8%A1%E0%B8%AD%E0%B8%9A%E0%B8%AD%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%88.pdf'
                                target='_blank' style={{textDecoration:'none'}} >
                                 {t('collectionLawFirm:Download the attorney appointment form')}<img src="/images/icons/download-blue.png" alt="bin"/></a> 
                                 </span>
                        </div>
                        <div className='p-3'>
                            {t('collectionLawFirm:Document example')}
                        </div>
                        <div className='print-page-a4-docs'>
                        <div style={{paddingBottom:'10%'}}>
                            <a href='https://silverman-storage.s3.ap-southeast-1.amazonaws.com/silverman-storage/CLF/FM-CF-01+%E0%B8%AB%E0%B8%99%E0%B8%B1%E0%B8%87%E0%B8%AA%E0%B8%B7%E0%B8%AD%E0%B8%A1%E0%B8%AD%E0%B8%9A%E0%B8%AD%E0%B8%B3%E0%B8%99%E0%B8%B2%E0%B8%88.pdf'
                                target='_blank' >
                            <img src={process.env.PUBLIC_URL + '/images/logo/house-condo-law-docs-logo.png'} /> 
                            </a>
                        </div>
                        <span className='text-center' style={{paddingTop:'10%'}}> วันที่..............................</span>
                        <div style={{paddingLeft:'7%',textAlign:'justify'}}>
                        <span style={{paddingLeft:'9%'}}>โดยหนังสือฉบับนี้ ข้าพเจ้า.......................(ชื่อนิติบุคคล)..............................โดย นาย/</span><br/>
                        นางสาว.......................................... อยู่บ้านเลขที่.................................... <br/>
                        โทรศัพท์....................................................ซึ่งต่อไปจะขอเรียกว่า “ผู้มอบอำนาจ” ทำหนังสือฉบับนี้<br/>
                        เพื่อให้ปรากฏและเป็นที่ยอมรับต่อสาธารณชนทั่วไปว่า ผู้มอบอำนาจขอมอบอำนาจให้............................<br/>
                        อยู่บ้านเลขที่............................ซึ่งต่อไปนี้จะขอเรียกว่า “ผู้รับมอบอำนาจ” มีอำนาจกระทำการแทน<br/>
                        ข้าพเจ้าได้ดังนี้
                        </div>

                        <div style={{paddingLeft:'10%'}}>
                            <ul className='text-left number-law-list'>
                                <li>
                                    ให้มีอำนาจดำเนินคดีกับ.............................................ต่อศาลที่มีเขตอำนาจ ทั้งคดี <br/>
                                    แพ่งและคดีอาญาจนกว่าคดีจำถึงที่สุด
                                </li>
                                <li>ดำเนินการะบวนพิจารณาไปในทางจำหน่ายสิทธิของข้าพเจ้าได้ด้วย เช่น 
                                    การยอมรับตามที่คู่ความอีกฝ่ายหนึ่งเรียกร้อง การถอนฟ้อง การถอนคำร้องทุกข์ 
                                    การประนีประนอมยอมความการสละสิทธิในการอุทธรณ์ หรือฎีกา 
                                    หรือในการขอพิจารณาคดีใหม่ตลอดจนการแต่งตั้งอนุญาโตตุลาการชี้ขาดคดี
                                </li>
                                <li>
                                    ดำเนินกระบวนพิจารณาในชั้นบังคับคดี ตลอดจนการนำเจ้าพนักงานบังคับคดีไป
                                    ทำการยึด และ/หรืออายัดทรัพย์ของลูกหนี้ตามคำพิพากษา รับรู้การขายทอดตลาดทรัพย์สินที่ยึด 
                                    ยื่นคำร้องขอรับชำระหนี้จำนอง หรือคำร้องขอเฉลี่ยทรัพย์ของลูกหนี้ที่เจ้าพนักงานบังคับคดียึดไว้ในคดีอื่น 
                                    ตลอดจนยื่นคำร้องขอรับชำระหนี้ในคดีล้มละลายด้วย
                                </li>
                                <li>
                                    ยื่นคำร้อง คำขอ คำแถลง ขอคัดสำเนาสรรพเอกสารต่าง ๆ ในสำนวนความ พร้อม
                                    ทั้งให้มีอำนาจรับเงิน รับเอกสาร หรือสิ่งของอื่นใดจากศาล เจ้าพนักงานศาล หรือคู่
                                    ความอีกฝ่ายหนึ่ง หรือบุคคลอื่นใดแทนข้าพเจ้าได้
                                </li>
                                <li>
                                    แต่งตั้งตัวแทนช่วง หรือทนายความตามวัตถุประสงค์ เพื่อให้กระทำการดังกล่าวข้างต้นด้วย
                                </li>
                            </ul>
                        </div>
                        <div style={{paddingLeft:'15%',textAlign:'justify'}}>
                               <span className='pl-5'>การกระทำของผู้รับมอบอำนาจซึ่งได้กระทำไปภายในขอบเขตแห่งหนังสือมอบ</span> 
                                อำนาจนี้ให้ถือว่าเป็นการกระทำของผู้มอบอำนาจทุกประการ ผู้มอบอำนาจและผู้รับมอบ
                                อำนาจเข้าใจข้อความหนังสือมอบอำนาจนี้โดยตลอดแล้ว จึงลงลายมือชื่อไว้เป็นหลักฐานต่อหน้าพยาน	
                        </div>
                        <div className="row" style={{paddingTop:'5%'}}>
                            <div className="col-6">
                                ลงชื่อ ..................................ผู้มอบอำนาจ <br/>
                                 <span>(......................................)</span> 
                            </div>
                            <div className="col-6">
                                ลงชื่อ..................................ผู้มอบอำนาจ <br/>
                                <span>(......................................)</span> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                ลงชื่อ..................................ผู้มอบอำนาจ <br/>
                                <span>(......................................)</span> 
                            </div>
                            <div className="col-6">
                                ลงชื่อ..................................ผู้มอบอำนาจ <br/>
                                <span>(......................................)</span> 
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className='text-right'>
                        <button onClick={() => this.props.onSetStepFinish3(true)} className="btn btn-primary" 
                        type='button'
                        style={{width:'134px'}}>
                         {t('collectionLawFirm:Next')}
                        </button> 
                    </div>
                   
                </div> 
                )}
                </Translation>
            </>
        );
    }
}

export default DownloadDocsCollectionLawFrim;