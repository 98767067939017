import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <Translation>
                        {t=>
                        <ul>
                            <li>
                                <NavLink to="/accounting/income/receipt_deposit/list/all">
                                    {t("receipt_deposit:All")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounting/income/receipt_deposit/list/active">
                                    {t("receipt_deposit:Active")}
                                </NavLink>
                            </li>
                            {/*<li>
                                 <NavLink to="/accounting/income/receipt_deposit/list/draft">
                                    แบบร่าง
                                </NavLink>
                            </li>*/}
                            {/*<li>*/}
                            {/*    <NavLink to="/accounting/income/receipt_deposit/list/active">*/}
                            {/*        ตัดชำระแล้วทั้งจำนวน*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}
                            <li>
                                <NavLink to="/accounting/income/receipt_deposit/list/use_all">
                                    {t("receipt_deposit:Use all")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounting/income/receipt_deposit/list/use_partial">
                                    {t("receipt_deposit:Use partial")}
                                </NavLink>
                            </li>
                            {/*<li>*/}
                                 {/*<NavLink to="/accounting/income/receipt_deposit/list/use_partial">*/}
                                    {/*ใช้บางส่วน*/}
                                 {/*</NavLink>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                 {/*<NavLink to="/accounting/income/receipt_deposit/list/use_all">*/}
                                    {/*ใช้ทั้งหมด*/}
                                 {/*</NavLink>*/}
                            {/*</li>*/}
                            <li>
                                 <NavLink to="/accounting/income/receipt_deposit/list/void">
                                    {t("receipt_deposit:Void")}
                                 </NavLink>
                            </li>
                        </ul>}
                    </Translation>
                </div>
            </div>
        )
    }
}

export default Navigation;