import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
 
import NoMatch from "../../components/noMatch";
import ChatList from '../chatgroup/chatList';
import CreateMeetings from '../chatgroup/createMeeting';
import ChatGroup from './chat/ChatGroup';
import UpdateMeeting from './updateMeeting';

class Parcel extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/update/:id`} component={UpdateMeeting}/> 
                <PrivateRoute exact path={`${this.props.match.url}/create`} component={CreateMeetings}/> 
                <PrivateRoute exact path={`${this.props.match.url}/chatGroup/:id`} component={ChatGroup}/> 
                <PrivateRoute exact path={`${this.props.match.url}/:status`} component={ChatList}/>  
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Parcel;