import React, { Component } from 'react';
import '../styles/filingFormWithholdingTax.scss'
import _ from "lodash";
import { getMonth, getYear } from "date-fns";
import getNumberSplitDecimal from "../../libs/getNumberSplitDecimal";
import { getTotalpayRecord } from "../../libs/getTotalpayRecord";
class FilingFormWithholdingTax53Print extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);

        this.state = {
            project: {
                name: "\u00a0",

                roomNumber: "\u00a0",
                floor: "\u00a0",
                villageNo: "\u00a0",
                building: "\u00a0",
                homeNumberProject: "\u00a0",
                alleyProject: "\u00a0",
                roadProject: "\u00a0",
                subDistrictProject: "\u00a0",
                districtProject: "\u00a0",
                provinceProject: "\u00a0",
                postcodeProject: ["", "", "", "", ""],

                taxIdNumber: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
            },
            BranchNumber: ["", "", "", "", ""],
            count: "\u00a0",
            sumAllTax: "",
            sumAllTransaction: "",
            sumAllwtf: 0,
            month: "",
            year: "\u00a0",
        }

    }

    calculateFontSize = (content, widthDiv) => {
        const maxWidth = widthDiv; // Adjust for padding/margins
        const defaultFontSize = 9; // Default font size
        let fontSize = defaultFontSize;
        
        if (content?.length > 0 && widthDiv > 0) {
            fontSize = Math.min(defaultFontSize, maxWidth / content.length);
            fontSize = (fontSize + 3) < 7 ? 7 : defaultFontSize
        }
        return fontSize;
    };

    print() {
        window.print();
    }

    componentDidMount() {
        if (this.props.data) {
            if (this.props.data.selfProject) {
                let project = _.cloneDeep(this.props.data.selfProject);
                let taxIdNumber = []
                let taxIdNumberFilter 
                if (project.taxIdNumber) {
                     taxIdNumberFilter = project?.taxIdNumber?.split("")
                     taxIdNumberFilter.filter((idNumber) => {
                        if (idNumber !== "-") {
                            taxIdNumber.push(idNumber);
                        }
                        return idNumber
                     })
                }
                if (project.taxIdNumber) {
                    let tax_id_split = taxIdNumber;
                    if (tax_id_split.length === 13) {
                        project.taxIdNumber = tax_id_split
                    } else {
                        project.taxIdNumber = ["", "", "", "", "", "", "", "", "", "", "", "", ""]
                    }
                } else {
                    project.taxIdNumber = ["", "", "", "", "", "", "", "", "", "", "", "", ""]
                }
                if (project.postcodeProject) {
                    let post_code_split = project.postcodeProject.split('');
                    if (post_code_split.length === 5) {
                        project.postcodeProject = post_code_split
                    } else {
                        project.postcodeProject = ["", "", "", "", ""]
                    }
                } else {
                    project.postcodeProject = ["", "", "", "", ""]
                }
                this.setState({ project: project })
            }

            let summary = JSON.parse(this.props.data.withholdingTaxViewer.summary);

            if (this.props.date) {
                this.setState({ month: getMonth(this.props.date) + 1, year: getYear(this.props.date) + 543 });
            }
            this.setState({
                BranchNumber: ["0", "0", "0", "0", "0"],
                count: summary.count,
                sumAllTax: summary.sum_all_tax,
                sumAllTransaction: this.getTotal(this.props.data),
            })
        }
    }

    getTotal(date) {
        let payRecordGroupSum = 0
        let otherExpenseSum = 0
        let totalPrepaidDepositSum = 0
        //PS
        date.withholdingTaxViewer.allWithholdingTax.edges.forEach((withholdingTax) => {
            return (

                withholdingTax.node.payRecordGroup?.payRecord?.edges.map((payRecord) => {
                    payRecordGroupSum += getTotalpayRecord(payRecord)
                    return null;
                })
            );
        }
        )
        //OE PD
        date.withholdingTaxViewer.allWithholdingTax.edges.forEach((withholdingTax) => {
            otherExpenseSum += _.sumBy(withholdingTax.node.otherExpense?.otherExpenseTransaction?.edges, function (otherExpenses) {
                //ถ้าไม่มีหัก ณ ที่จ่าย ไม่ต้องนำยอดมารวม
                return otherExpenses.node.whtRate > 0 ? (parseFloat(otherExpenses.node.preTaxAmount) || 0) : 0;
            })
            totalPrepaidDepositSum += (parseFloat(withholdingTax.node.totalPrepaidDeposit) || 0);

        }
        )
        const totalAmount = payRecordGroupSum + otherExpenseSum + totalPrepaidDepositSum;
        return totalAmount;

    }

    getTotalpayRecord(payRecored) {
        let preTaxAmount_pr_acn_pd = 0;
        let acnsums = 0;
        let sumpd = 0;
        if (payRecored.node.whtRatePercent) {
            payRecored.node.purchaseRecord.acceptCreditNoteTransaction.edges.forEach((acnsum) => {
                acnsums = + acnsum.node.price
            })
            if (_.find(payRecored.node.purchaseRecord.prepaidDepositTransactionUsed.edges, { node: { prepaidDepositTransaction: { whtRatePercent: null } } }) === undefined) {
                payRecored.node.purchaseRecord.prepaidDepositTransactionUsed.edges.forEach((pdsum) => {
                    if (pdsum.node.prepaidDepositTransaction.whtRatePercent) {
                        sumpd = sumpd + Math.round((parseFloat(pdsum.node.amount) / (1 + parseFloat(pdsum.node.prepaidDepositTransaction.vat || 0) / 100) + Number.EPSILON) * 100) / 100;
                    }
                })
            }
            preTaxAmount_pr_acn_pd = Math.round((parseFloat(payRecored.node.purchaseRecord.preTaxAmount - sumpd - acnsums) + Number.EPSILON) * 100) / 100;
        }
        return preTaxAmount_pr_acn_pd;
    }

    render() {
        return (
            <div id="filing-form-withholding-tax-print">
                <div className="print-page-withholding-a4">
                    <div className="subpage">
                        <div className="filter-form-withholding-tax-body">
                            <div className="row">
                                <div className="col-9 pr-1 text-center align-self-center">
                                    <div className="form-inline gray-back-ground-radius">
                                        <div className="col-2 p-0">
                                            <img className="image-logo"
                                                src={process.env.PUBLIC_URL + '/images/logo/the-revenue-department-logo.png'}
                                                alt="the-revenue-department-logo" width={"80px"} />
                                        </div>
                                        <div className="col-10 p-0">
                                            <span
                                                className="bold">แบบยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย</span><br />
                                            <span>ตามมาตรา 3 เตรส และมาตรา 69 ทวิ</span><br />
                                            <span>และการเสียภาษีตามมาตรา 65 จัตวา แห่งประมวลรัษฎากร</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 p-0 text-center align-self-center gray-radius-corners">
                                    <span className="text-header-pnd bold">ภ.ง.ด.53</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8 border-address">
                                    <div className="form-inline mt-2">
                                        <div className="col-5">
                                            <span className="bold">เลขประจำตัวผู้เสียภาษีอากร</span><br />
                                            <span className="text-small">(ของผู้มีหน้าที่หักภาษี ณ ที่จ่าย)</span>
                                        </div>
                                        <div className="col-7">
                                            <table className="float-right">
                                                <tbody>
                                                    <tr>
                                                        <td className="border-line normal" rowSpan="2">{this.state.project.taxIdNumber[0]}</td>
                                                        <td className="bottom-line"></td>
                                                        <td className="border-line dotted-right" rowSpan="2">{this.state.project.taxIdNumber[1]}</td>
                                                        <td className="border-line dotted" rowSpan="2">{this.state.project.taxIdNumber[2]}</td>
                                                        <td className="border-line dotted" rowSpan="2">{this.state.project.taxIdNumber[3]}</td>
                                                        <td className="border-line dotted-left" rowSpan="2">{this.state.project.taxIdNumber[4]}</td>
                                                        <td className="bottom-line"></td>
                                                        <td className="border-line dotted-right" rowSpan="2">{this.state.project.taxIdNumber[5]}</td>
                                                        <td className="border-line dotted" rowSpan="2">{this.state.project.taxIdNumber[6]}</td>
                                                        <td className="border-line dotted" rowSpan="2">{this.state.project.taxIdNumber[7]}</td>
                                                        <td className="border-line dotted" rowSpan="2">{this.state.project.taxIdNumber[8]}</td>
                                                        <td className="border-line dotted-left" rowSpan="2">{this.state.project.taxIdNumber[9]}</td>
                                                        <td className="bottom-line"></td>
                                                        <td className="border-line dotted-right" rowSpan="2">{this.state.project.taxIdNumber[10]}</td>
                                                        <td className="border-line dotted-left" rowSpan="2">{this.state.project.taxIdNumber[11]}</td>
                                                        <td className="bottom-line"></td>
                                                        <td className="border-line normal" rowSpan="2">{this.state.project.taxIdNumber[12]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="form-inline justify-content-between">
                                        <div className="col-7 "><span className="bold">ชื่อผู้มีหน้าที่หักภาษี ณ ที่จ่าย</span> (หน่วยงาน) :</div>
                                        <div className="col-5">
                                            <div className="form-inline float-right">
                                                <span className="mr-2">สาขาที่</span>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-line dotted-right" rowSpan="2">{this.state.BranchNumber[0]}</td>
                                                            <td className="border-line dotted" rowSpan="2">{this.state.BranchNumber[1]}</td>
                                                            <td className="border-line dotted" rowSpan="2">{this.state.BranchNumber[2]}</td>
                                                            <td className="border-line dotted" rowSpan="2">{this.state.BranchNumber[3]}</td>
                                                            <td className="border-line dotted-left" rowSpan="2">{this.state.BranchNumber[4]}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-inline">
                                        <div className="col-12">
                                            <div className="form-inline">
                                                <div className="dotted" style={{ minWidth: "100%" }}>{this.state.project.name}</div>

                                                <span className="bold">ที่อยู่</span> : อาคาร
                                                <div className="dotted" style={{ minWidth: "70px", maxWidth: "70px" }}>
                                                    <span className="ml-1" id="buildingNameLangth" style={{ fontSize: this.calculateFontSize(this.state.project.building, 70) }}></span>
                                                </div>
                                                ห้องเลขที่
                                                <div className="dotted" style={{ minWidth: "40px", maxWidth: "40px" }}>
                                                    <span className="ml-1" style={{ fontSize: this.calculateFontSize(this.state.project.roomNumber, 40) }}></span>
                                                </div>
                                                ชั้นที่
                                                <div className="dotted" style={{ minWidth: "30px", maxWidth: "30px" }}>
                                                    <span className="ml-1" style={{ fontSize: this.calculateFontSize(this.state.project.building, 30) }}></span>
                                                </div>
                                                หมู่บ้าน
                                                <div className="dotted" style={{ minWidth: "160px", maxWidth: "160px" }}>
                                                    <span className="ml-1" style={{ fontSize: this.calculateFontSize(this.state.project.building, 160) }}></span>
                                                </div>

                                                เลขที่
                                                <div className="dotted" style={{ minWidth: "70px", maxWidth: "70px" }}>
                                                    <span className="ml-1" style={{ fontSize: this.calculateFontSize(this.state.project.homeNumberProject, 70) }}>{this.state.project.homeNumberProject}</span>
                                                </div>
                                                หมู่ที่
                                                <div className="dotted" style={{ minWidth: "30px", maxWidth: "30px" }}>&nbsp;</div>
                                                ตรอก/ซอย
                                                <div className="dotted" style={{ minWidth: "127px", maxWidth: "127px" }}>
                                                    <span className="ml-1" style={{ fontSize: this.calculateFontSize(this.state.project.alleyProject, 127) }}>{this.state.project.alleyProject}</span>
                                                </div>
                                                แยก
                                                <div className="dotted" style={{ minWidth: "115px", maxWidth: "115px" }}>&nbsp;</div>

                                                ถนน
                                                <div className="dotted" style={{ minWidth: "140px", maxWidth: "140px" }}>
                                                    <span className="ml-1" style={{ fontSize: this.calculateFontSize(this.state.project.roadProject, 140) }}>{this.state.project.roadProject}</span>
                                                </div>
                                                ตำบล/แขวง
                                                <div className="dotted" style={{ minWidth: "240px", maxWidth: "240px" }}>
                                                    <span className="ml-1" style={{ fontSize: this.calculateFontSize(this.state.project.subDistrictProject, 240) }}>{this.state.project.subDistrictProject}</span>
                                                </div>

                                                อำเภอ/เขต
                                                <div className="dotted" style={{ minWidth: "130px", maxWidth: "130px" }}>
                                                    <span className="ml-1" style={{ fontSize: this.calculateFontSize(this.state.project.districtProject, 130) }}>{this.state.project.districtProject}</span>
                                                </div>
                                                จังหวัด
                                                <div className="dotted" style={{ minWidth: "244px", maxWidth: "244px" }}>
                                                    <span className="ml-1" style={{ fontSize: this.calculateFontSize(this.state.project.provinceProject, 244) }}>{this.state.project.provinceProject}</span>
                                                </div>

                                                <span className="mr-2">รหัสไปรษณีย์</span>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-line dotted-right" rowSpan="2">{this.state.project.postcodeProject[0]}</td>
                                                            <td className="border-line dotted" rowSpan="2">{this.state.project.postcodeProject[1]}</td>
                                                            <td className="border-line dotted" rowSpan="2">{this.state.project.postcodeProject[2]}</td>
                                                            <td className="border-line dotted" rowSpan="2">{this.state.project.postcodeProject[3]}</td>
                                                            <td className="border-line dotted-left" rowSpan="2">{this.state.project.postcodeProject[4]}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="dotted" style={{ minWidth: "319px" }}>&nbsp;</div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3 line-top">
                                            <div className="form-inline">
                                                {/*<span className="ballot-box">&#9745;</span>*/}

                                                <span className="bold">เดือนที่จ่ายเงินได้พึงประเมิน</span> (ให้ทำ เครื่องหมาย “<span className="ballot-box">&#10003;</span>” ลงใน “<span className="ballot-box">&#9744;</span>” หน้าชื่อเดือน) พ.ศ.
                                                <div className="dotted" style={{ minWidth: "76px" }}>{this.state.year}</div>
                                            </div>

                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span className="ballot-box">
                                                                {this.state.month === 1 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (1) มกราคม
                                                        </td>
                                                        <td>
                                                            <span className="ballot-box ml-4">
                                                                {this.state.month === 4 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (4) เมษายน
                                                        </td>
                                                        <td>
                                                            <span className="ballot-box ml-4">
                                                                {this.state.month === 7 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (7) กรกฎาคม
                                                        </td>
                                                        <td>
                                                            <span className="ballot-box ml-4">
                                                                {this.state.month === 10 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (10) ตุลาคม
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span className="ballot-box">
                                                                {this.state.month === 2 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (2) กุมภาพันธ์
                                                        </td>
                                                        <td>
                                                            <span className="ballot-box ml-4">
                                                                {this.state.month === 5 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (5) พฤษภาคม
                                                        </td>
                                                        <td>
                                                            <span className="ballot-box ml-4">
                                                                {this.state.month === 8 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (8) สิงหาคม
                                                        </td>
                                                        <td>
                                                            <span className="ballot-box ml-4">
                                                                {this.state.month === 11 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (11) พฤศจิกายน
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span className="ballot-box">
                                                                {this.state.month === 3 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (3) มีนาคม
                                                        </td>
                                                        <td>
                                                            <span className="ballot-box ml-4">
                                                                {this.state.month === 6 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (6) มิถุนายน
                                                        </td>
                                                        <td>
                                                            <span className="ballot-box ml-4">
                                                                {this.state.month === 9 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (9) กันยายน
                                                        </td>
                                                        <td>
                                                            <span className="ballot-box ml-4">
                                                                {this.state.month === 12 ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                            </span> (12) ธันวาคม
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 border-remit-tax mt-2">
                                    <div className="remit-tax bold">นำส่งภาษีตาม</div>
                                    <div className="ml-4">
                                        <span className="ballot-box">
                                            {this.props.data ? <span>&#9745;</span> : <span>&#9744;</span>}
                                        </span> (1) มาตรา <span className="bold">3 เตรส</span> แห่งประมวลรัษฎากร
                                    </div>
                                    <div className="ml-4">
                                        <span className="ballot-box">&#9744;</span> (2) มาตรา <span className="bold">65 จัตวา</span> แห่งประมวลรัษฎากร
                                    </div>
                                    <div className="ml-4">
                                        <span className="ballot-box">&#9744;</span> (3) มาตรา <span className="bold">69 ทวิ</span> แห่งประมวลรัษฎากร
                                    </div>
                                    <div className="line-top line-bottom">
                                        <div className="form-inline mt-2 mb-2">
                                            <div className="ml-4">
                                                <span className="ballot-box">
                                                    {this.props.data ? <span>&#9745;</span> : <span>&#9744;</span>}
                                                </span> ยื่น<span className="bold">ปกติ</span>
                                            </div>
                                            <div className="ml-1 form-inline">
                                                <span className="ballot-box">&#9744;</span> ยื่น<span className="bold">เพิ่มเติม</span>ครั้งที่
                                                <div className="dotted" style={{ minWidth: "40px" }}>&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tcl">
                                        สำหรับบันทึกข้อมูลจากระบบ TCL
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-5 text-center align-self-center">
                                    มีรายละเอียดการหักเป็นรายผู้มีเงินได้ ปรากฏตาม <br />
                                    (ให้แสดงรายละเอียดใน<span className="bold">ใบแนบ ภ.ง.ด.53</span> หรือใน<span className="bold">สื่อ</span> <br />
                                    <span className="bold">บันทึกในระบบคอมพิวเตอร์</span>อย่างใดอย่างหนึ่งเท่านั้น)
                                </div>
                                <div className="col-7">
                                    <div className="form-inline">
                                        <div className="col-8">
                                            <span className="ballot-box">&#9745;</span> <span className="bold">ใบแนบ ภ.ง.ด.53</span> ที่แนบมาพร้อมนี้ :
                                        </div>
                                        <div className="col-4">
                                            <div className="form-inline float-right">
                                                จำนวน<div className="dotted" style={{ minWidth: "40px", textAlign: "center" }}>{this.state.count}</div>ราย
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-inline">
                                        <div className="col-4 offset-8">
                                            <div className="form-inline float-right">จำนวน<div className="dotted" style={{ minWidth: "37px", textAlign: "center" }}>{Math.ceil(this.state.count / 6)}</div>แผ่น
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-inline">
                                        <div className="col-12 bold">
                                            หรือ
                                        </div>
                                    </div>
                                    <div className="form-inline">
                                        <div className="col-8">
                                            <span className="ballot-box">&#9744;</span> <span className="bold">สื่อบันทึกในระบบคอมพิวเตอร์</span> ที่แนบมาพร้อมนี้ :
                                        </div>
                                        <div className="col-4">
                                            <div className="form-inline float-right">
                                                จำนวน<div className="dotted" style={{ minWidth: "40px" }}>&nbsp;</div>ราย
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-inline">
                                        <div className="col-4 offset-8">
                                            <div className="form-inline float-right">
                                                จำนวน<div className="dotted" style={{ minWidth: "37px" }}>&nbsp;</div>แผ่น
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-inline mt-2">
                                        <div className="col-12">
                                            <div className="form-inline">
                                                (ตามหนังสือแสดงความประสงค์ฯ ทะเบียนรับเลขที่
                                                <div className="dotted" style={{ minWidth: "144px" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-inline mt-1">
                                        <div className="col-12">
                                            <div className="form-inline">
                                                หรือตามหนังสือข้อตกลงการใช้งานฯ เลขอ้างอิงการลงทะเบียน
                                                <div className="dotted" style={{ minWidth: "91px" }}></div>)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center mt-3 line-bottom">
                                <div className="col-6 p-0 pr-1">
                                    <div className="total-remit-tax bold">สรุปรายการภาษีที่นำส่ง</div>
                                    <div className="horizontal-dotted-line"><span className="bold">1.รวม</span>ยอดเงินได้ทั้งสิ้น</div>
                                    <div className="horizontal-dotted-line"><span className="bold">2.รวม</span>ยอดภาษีที่นำส่งทั้งสิ้น</div>
                                    <div className="horizontal-dotted-line"><span className="bold">3.</span>เงินเพิ่ม (ถ้ามี)</div>
                                    <div className="horizontal-dotted-line"><span className="bold">4.รวม</span>ยอดภาษีที่นำส่งทั้งสิ้น และเงินเพิ่ม (<span className="bold">2. + 3.</span>)</div>
                                </div>
                                <div className="col-2 p-0 mb-2">
                                    <div className="total-amount bold">จำนวนเงิน</div>
                                    <table className="mt-2">
                                        <tbody>
                                            <tr>
                                                <td className="border-amount amount" rowSpan="2">{this.props.data ? getNumberSplitDecimal(this.state.sumAllTransaction, "number") : "\u00a0"}</td>
                                                <td className="border-amount decimal" rowSpan="2">{this.props.data ? getNumberSplitDecimal(this.state.sumAllTransaction, "decimal") : "\u00a0"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="mt-2">
                                        <tbody>
                                            <tr>
                                                <td className="border-amount amount" rowSpan="2">{this.props.data ? getNumberSplitDecimal(this.state.sumAllTax, "number") : "\u00a0"}</td>
                                                <td className="border-amount decimal" rowSpan="2">{this.props.data ? getNumberSplitDecimal(this.state.sumAllTax, "decimal") : "\u00a0"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="mt-2">
                                        <tbody>
                                            <tr>
                                                <td className="border-amount amount" rowSpan="2">{this.props.data && "0"}</td>
                                                <td className="border-amount decimal" rowSpan="2">{this.props.data && "00"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="mt-2">
                                        <tbody>
                                            <tr>
                                                <td className="border-amount amount" rowSpan="2">{this.props.data ? getNumberSplitDecimal(this.state.sumAllTax, "number") : "\u00a0"}</td>
                                                <td className="border-amount decimal" rowSpan="2">{this.props.data ? getNumberSplitDecimal(this.state.sumAllTax, "decimal") : "\u00a0"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="row justify-content-center line-bottom">
                                <div className="mt-3 mb-4">ข้าพเจ้าขอรับรองว่า รายการที่แจ้งไว้ข้างต้นนี้ เป็นรายการที่ถูกต้องและครบถ้วนทุกประการ</div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col text-center">
                                            <p className="mb-2">ลงชื่อ..................................................................................ผู้จ่ายเงิน</p>
                                            <p className="mb-2">(..........................................................................)</p>
                                            <p className="mb-2">ตำแหน่ง........................................................................................</p>
                                            <p className="mb-2">ยื่นวันที่.......................เดือน...................................พ.ศ....................</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 div-seal">
                                    <div className="border-seal">
                                        <span className="text-center">ประทับตรานิติบุคคล (ถ้ามี)</span>
                                    </div>
                                </div>
                            </div>
                            <div className="text-right mt-2">(ก่อนกรอกรายการ ดูคำชี้แจงด้านหลัง)</div>

                            <div className="row mt-5">
                                <div className="col">
                                    <i>
                                        สอบถามข้อมูลเพิ่มเติมได้ที่ศูนย์สารนิเทศสรรพากร RD Intelligence Center โทร. 1161
                                    </i>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FilingFormWithholdingTax53Print;
