import React from "react";
import Header from "../../components/header/";
import Sidebar from "../../components/sidebar/";
import Wrapper from "../../components/wrapper/";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18next from "i18next";
import "./styles/survey.scss";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { commitMutation, QueryRenderer } from "react-relay";
import ComponentPagination from "../../libs/componentPagination";
import Pagination from "../../libs/newPagination";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import Swal from "sweetalert2";
import DeleteSurvey from "./deleteSurvey";
import { fetchQuery } from "relay-runtime";
import ContactTopMenuNavigation from "../contactTopMenuNavigation";
import _ from "lodash";
import Loading from "../../libs/loading";

const query = graphql`
  query surveyListQuery($first: Int, $last: Int, $search: String) {
    allSurvey(first: $first, last: $last, search: $search) {
      totalCount
      edges {
        node {
          id
          title
          status
          creator
          juristicPost
          dueDate
          postDate
        }
      }
    }
    countSurveyByStatus(search: $search)
  }
`;

const mutation = graphql`
  mutation surveyListMutation($input: DeleteSurveyInput!) {
    deleteSurvey(input: $input) {
      ok
    }
  }
`;

class SurveyList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.search = "";
    this.state.search_input = "";
    this.state.loading = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onDeleteSurvey = this.onDeleteSurvey.bind(this);
    this.init = this.init.bind(this);
  }

  init() {
    fetchQuery(environment, query, {
      search: this.state.search,
      first: this.state.first,
      last: this.state.last,
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleChange(e);
    }
  }

  onDeleteSurvey(id_list) {
    if (id_list.length !== 0 && !this.state.loading) {
      Swal.fire({
        title: i18next.t("survey:want_to_remove"),
        text: i18next.t("survey:all_survey") + id_list.length + i18next.t("survey:item"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: i18next.t("survey:yes"),
        cancelButtonText: i18next.t("survey:cancel"),
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });

          let variables = {
            input: {
              idList: id_list,
            },
          };

          commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
              this.setState({
                loading: false,
                check_all: false,
                checkList: [],
              });
              if (response.deleteSurvey.ok) {
                Swal.fire(i18next.t("survey:remove_success"), "", "success").then(() => {
                  this.init();
                });
              }
            },
            onError: (err) => {
              this.setState({ loading: false });
              Swal.fire(i18next.t("survey:remove_fail"), "", "error");
            },
          });
        }
      });
    } else if (this.state.loading) {
      Swal.fire({
        title: "กรุณารอสักครู่",
        type: "warning",
      });
    } else {
      Swal.fire({
        title: i18next.t("survey:please_select_item"),
        type: "warning",
      });
    }
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <ContactTopMenuNavigation mini={true} />
        <Translation>{t=>
          <div className="container-fluid box" id="survey">
            <div className="row justify-content-between">
              <div className="col">
                <h3>
                  <Link to="/contact">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("topMenuNavigation:survey")}</span>}
                  </Translation>
                </h3>
              </div>

              <div className="col text-right">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "contact_survey_create",
                }) && (
                  <Link to="/contact/survey/form/create">
                    <button className="btn btn-primary add">
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                        alt="plus"
                      />
                        {t("survey:add_survey")}
                    </button>
                  </Link>
                )}
              </div>
            </div>

            <div className="content-inner">
              <div className="row mb-2 mt-4">
                <div className="col">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-light dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {t("survey:manage_multiple")}
                    </button>
                    {_.some(
                      JSON.parse(localStorage.getItem("permission_list")),
                      { codename: "contact_survey_delete" }
                    ) && (
                      <div className="dropdown-menu">
                        <span
                          className="dropdown-item approve-dropdown"
                          onClick={() =>
                            this.onDeleteSurvey(this.state.checkList)
                          }
                        >
                          {this.state.loading && (
                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                          )}
                            {t("survey:remove_list")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-3 input-group">
                  <input
                    type="text"
                    className="form-control search-input input-size"
                    placeholder={t("survey:search")}
                    name="search"
                    value={this.state.search_input}
                    onChange={(e) =>
                      this.setState({ search_input: e.target.value })
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary form-control search-button"
                    name="search"
                    value={this.state.search_input}
                    onClick={(e) => this.handleChange(e)}
                  >
                    {t("survey:search")}
                  </button>
                </div>
              </div>

              <QueryRenderer
                environment={environment}
                query={query}
                variables={{
                  search: this.state.search,
                  first: this.state.first,
                  last: this.state.last,
                }}
                cacheConfig={{ use_cache: false }}
                render={({ error, props }) => {
                  if (error) {
                    return (
                      <div className="alert alert-danger" role="alert">
                        {error.message}
                      </div>
                    );
                  } else if (props) {
                    return (
                      <React.Fragment>
                        <div className="card fade-up">
                          <div className="table-responsive">
                            <table className="table table-hover">
                              <thead className="thead-light">
                                <tr>
                                  <th width="50">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        id="checkAll"
                                        className="custom-control-input"
                                        onChange={() =>
                                          this.getAllList(props.allSurvey.edges)
                                        }
                                        checked={this.state.check_all}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="checkAll"
                                      />
                                    </div>
                                  </th>
                                  <th width="600">{t("survey:subject_desc")}</th>
                                  <th>{t("survey:post_by")}</th>
                                  <th>{t("survey:vote_close")}</th>
                                  <th>
                                    <div className="dropdown dropdown-status">
                                      <span>
                                        {t("survey:date_post")}
                                        <button
                                          className="btn btn-primary-outline dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        ></button>
                                      </span>
                                    </div>
                                  </th>
                                  <th className="text-center">{t("survey:voter")}</th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {props?.allSurvey?.edges.map((survey) => {
                                  let all_count = 0;
                                  let vote_count = 0;
                                  props.countSurveyByStatus.forEach(
                                    (survey_count) => {
                                      survey_count = JSON.parse(
                                        survey_count.replace(/'/g, '"')
                                      );
                                      if (
                                        atob(survey.node.id)
                                          .split(":")
                                          .pop() ===
                                        survey_count.survey.toString()
                                      ) {
                                        all_count = survey_count.count;
                                        vote_count =
                                          survey_count.count_not_vote;
                                      }
                                    }
                                  );

                                  return (
                                    <tr key={survey.node.id} className="show">
                                      <td>
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            id={survey.node.id}
                                            className="custom-control-input"
                                            onChange={() =>
                                              this.appendToCheckList(
                                                survey.node.id
                                              )
                                            }
                                            checked={this.checkList(
                                              survey.node.id
                                            )}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={survey.node.id}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        {/*{survey.node.status === 'DRAFT' ?*/}
                                        {vote_count === 0 ? (
                                          <Link
                                            to={
                                              "/contact/survey/form/edit/" +
                                              survey.node.id
                                            }
                                          >
                                            <h6 className="blue-color">
                                              {survey.node.title.length >= 80
                                                ? survey.node.title
                                                    .replace(/<[^>]+>/g, "")
                                                    .substring(0, 80) + "..."
                                                : survey.node.title.replace(
                                                    /<[^>]+>/g,
                                                    ""
                                                  )}
                                              {survey.node.status ===
                                                "DRAFT" && (
                                                <span className="small ml-2 color red-color">
                                                  {"("}{t("survey:draft")}{")"}
                                                </span>
                                              )}
                                            </h6>
                                          </Link>
                                        ) : (
                                          <Link
                                            to={
                                              "/contact/survey/detail/all/" +
                                              survey.node.id
                                            }
                                          >
                                            <h6 className="color">
                                              {survey.node.title.length >= 80
                                                ? survey.node.title
                                                    .replace(/<[^>]+>/g, "")
                                                    .substring(0, 80) + "..."
                                                : survey.node.title.replace(
                                                    /<[^>]+>/g,
                                                    ""
                                                  )}
                                            </h6>
                                          </Link>
                                        )}
                                      </td>
                                      {survey.node.juristicPost ? (
                                        <td>{survey.node.juristicPost}</td>
                                      ) : (
                                        <td>-</td>
                                      )}
                                      <td>
                                        {format(
                                          survey.node.dueDate,
                                          "DD/MM/YYYY",
                                          { locale: thLocale }
                                        )}
                                      </td>
                                      <td>
                                        {survey.node.postDate
                                          ? format(
                                              survey.node.postDate,
                                              "DD/MM/YYYY - HH:mm น.",
                                              { locale: thLocale }
                                            )
                                          : "-"}
                                      </td>
                                      {survey.node.status !== "DRAFT" ? (
                                        <td className="text-center">
                                          {vote_count}/{all_count}
                                        </td>
                                      ) : (
                                        <td className="text-center">-</td>
                                      )}
                                      <td width="100">
                                        {_.some(
                                          JSON.parse(
                                            localStorage.getItem(
                                              "permission_list"
                                            )
                                          ),
                                          { codename: "contact_survey_delete" }
                                        ) && (
                                          <DeleteSurvey
                                            survey={survey.node}
                                            reQuerySurvey={this.init}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <Pagination
                            changePage={this.changePage}
                            first={this.state.first}
                            last={this.state.last}
                            totalCount={props?.allSurvey?.totalCount}
                          />
                        </div>
                      </React.Fragment>
                    );
                  }
                  return <Loading/>
                }}
              />
            </div>
          </div>
          }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default SurveyList;
