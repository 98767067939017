

import React, { Component } from 'react';
import { QRCode } from 'react-qrcode-logo';
import './vms.scss'

class VMSSuccess extends Component {

    constructor(props) {
        super(props)
        this.state = {
          isDownload : false,
          lang: this.props.match.params?.lang  === 'true' ? this.props.match.params?.lang : false,
        }

        this.downloadQR = this.downloadQR.bind(this)
    }

    downloadQR = () => {
        const canvas = document.getElementById("react-qrcode-logo");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qrcode2.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
       // Swal.fire('', 'บันทึกรูปภาพเรียบร้อย', 'success')
    };

    render() {

        let id = this.props.match.params.id
       // let path = getApiRoot(); //http://svmsupport.silverman.cloud/
        let path = window.location.protocol + '//' +window.location.hostname+ "/";
    
        return (
            <>
                  <div id="wrapper-content">
                    <div id='vms-wrapper'>
                         
                        <div>
                            <img
                                src={process.env.PUBLIC_URL + '/images/setting-walkin/walkin-bg.png'}
                                alt="walkin"
                                className="walkin" style={{width:'100%'}}
                            />
                            
                            <div className="centered">
                            <div>
                                <span onClick={ () => this.setState({lang : !this.state.lang})} className='btn-lang' style={{right:'-9',top:'39px'}}>
                                    <img src={this.state.lang ? (process.env.PUBLIC_URL + '/images/flags/en.png') : (process.env.PUBLIC_URL + '/images/flags/th.png')} 
                                    alt="flag-icon" className='flag'/>
                                    <h6><small>{this.state.lang ? 'EN':'ไทย'}</small></h6>
                                </span>
                            </div>
                                <div className='vms-container'>
                                    <div className='pt-5'>
                                        <img src={process.env.PUBLIC_URL + '/images/logo/SVM-full-dark.png'} alt="svm-agent-logo"/>
                                    </div>
                                    <p className='pt-5' style={{fontSize:'1.4rem'}}>{this.state.lang ? 'Thanks for signing up with us' :'ขอบคุณที่ลงทะเบียนกับทางเรา'}</p>
                                    <div>
                                        <QRCode 
                                            value={path+"vms/vms-view/"+id}
                                            logoImage='/images/logo/logo.svg'
                                            size={266}
                                            logoWidth={50}
                                            logoHeight={50}
                                         />
                                    </div>
                                    <div className='pt-5'>
                                        <a onClick={this.downloadQR}>
                                            <button className="btn btn-add-new-slip" style={{width:'18rem'}}>
                                                 {this.state.lang ? 'Save QR Code' :'บันทึก QR Code'}
                                            </button>
                                        </a>
                                    </div>
                                    <p className='pt-8 text-center' style={{fontSize:'1rem'}}>{this.state.lang ? '*Please save this QR code as a record of your registered information.' :'*กรุณาบันทึก QR Code นี้เพื่อดูข้อมูลที่ลงทะเบียนไว้'}</p>
                             
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
            </>
                
        );
    }
}

export default VMSSuccess;
