import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import localStorage from "../../../../libs/localstorage";
import { format } from "date-fns";
import _ from "lodash"
import i18next from "i18next";

class PaySummaryTable extends React.Component {

    countPayment(payment, status_void) {
        let total = 0
        if (!status_void) total += _.sumBy(payment.edges, function (payment) {
            return parseFloat(payment.node.price) || 0;
        })
        return total;
    }

    countPaymentSpecial(payment, slug, status_void) {
        let total = 0
        if (!status_void) total += _.sumBy(payment.edges, function (payment) {
            if (payment.node.slug === slug) {
                return parseFloat(payment.node.price) || 0;
            } else {
                return 0;
            }
        })
        return total;
    }
    totalAccumulateRecord(Amount, Wht, retention, small_change, small_change_income, fee, last_row) {
        let total = (Amount + Wht) || 0;
        if (last_row) {
            if (retention || small_change_income) {
                total = total + (retention + small_change_income)
            }
            else if (small_change || fee) {
                total = total - (small_change + fee)
            }
        }
        return total;

    }


    render() {
        let order_id = this.props.state.first - localStorage.getPageLimit();
        let total = 0.0;

        //จำนวนเงินที่ชำระ
        let cash_amount = 0;
        let bank_amount = 0;
        let cheque_amount = 0;

        //จำนวนเงินที่ชำระที่พิเศษ
        let petty_cash = 0; //รับเงินสดย่อย
        let retention = 0; //รับมาน้อยกว่าให้บวก
        let small_change = 0; //รับมากกว่าให้ติดลบ
        let small_change_income = 0;  //รับมาน้อยกว่าให้บวก
        let fee = 0; //รับมากกว่าให้ติดลบ

        //ผลรวม
        let TotaltotalPayRecord = 0;
        let Totalcash_amount = 0;
        let Totalbank_amount = 0;
        let Totalcheque_amount = 0;
        let TotalPettyCash_amount = 0;
        let Totalretention = 0;
        let Totalsmall_change = 0;
        let Totalsmall_change_income = 0;
        let Totalfee = 0;
        let TotalWht = 0;

        // CSS Red Void PS
        let color
        let status_void = false
        return (
            <React.Fragment>
                {this.props.props.allPayRecordGroup.edges.map((pay_record_group, index) => {
                    let accumulate = 0;
                    // CSS red
                    status_void = pay_record_group.node.status === 'VOID' ? true : false
                    color = status_void ? { color: 'red' } : null

                    // Total Payment
                    cash_amount = this.countPayment(pay_record_group.node.cashTransaction, status_void)
                    bank_amount = this.countPayment(pay_record_group.node.bankAccountTransaction, status_void)
                    cheque_amount = this.countPayment(pay_record_group.node.chequeTransaction, status_void)

                    //Total Payment Special
                    petty_cash = this.countPaymentSpecial(pay_record_group.node.paymentChannel, "petty_cash", status_void)
                    retention = this.countPaymentSpecial(pay_record_group.node.paymentChannel, "retention", status_void)
                    small_change = this.countPaymentSpecial(pay_record_group.node.paymentChannel, "small-change", status_void)
                    small_change_income = this.countPaymentSpecial(pay_record_group.node.paymentChannel, "small-change-income", status_void)
                    fee = this.countPaymentSpecial(pay_record_group.node.paymentChannel, "fee", status_void)
                    TotalPettyCash_amount += petty_cash
                    Totalretention += retention;
                    Totalsmall_change += small_change;
                    Totalsmall_change_income += small_change_income;
                    Totalfee += fee;

                    return (
                        pay_record_group.node.payRecord.edges.map((transaction, t_index) => {
                            const number_of_transaction = pay_record_group.node.payRecord.edges.length;
                            const last_row = (number_of_transaction === t_index + 1) ? true : false;
                            let row_number = (t_index === 0 && order_id + index + 1);

                            let amount_select = status_void ? 0 : transaction.node.amount;
                            let cash_cal = 0;
                            let bank_cal = 0;
                            let cheque_cal = 0;
                            let petty_cal = 0
                            let totalPayRecord = 0;
                            let accumulateRecord = 0;
                            let retention_cal = 0
                            if (!status_void) {

                                //เลือกวิธีชำระเงิน เงินสด
                                if (amount_select != 0 && amount_select >= cash_amount) {
                                    cash_cal = cash_amount;
                                    cash_amount = 0;
                                    amount_select = amount_select - cash_cal
                                } else if (amount_select != 0 && amount_select < cash_amount) {
                                    if (last_row) {
                                        cash_cal = cash_amount;
                                        cash_amount = 0;
                                        amount_select = 0;
                                    } else {
                                        cash_cal = amount_select
                                        cash_amount = cash_amount - amount_select;
                                        amount_select = 0;
                                    }
                                }
                                // เงินโอน
                                if (amount_select != 0 && amount_select >= bank_amount) {
                                    bank_cal = bank_amount;
                                    bank_amount = 0;
                                    amount_select = amount_select - bank_cal
                                } else if (amount_select != 0 && amount_select < bank_amount) {
                                    if (last_row) {
                                        bank_cal = bank_amount;
                                        bank_amount = 0;
                                        amount_select = 0;
                                    } else {
                                        bank_cal = amount_select
                                        bank_amount = bank_amount - amount_select;
                                        amount_select = 0;
                                    }
                                }
                                //เช็ค
                                if (amount_select != 0 && amount_select >= cheque_amount) {
                                    cheque_cal = cheque_amount;
                                    cheque_amount = 0;
                                    amount_select = amount_select - cheque_cal
                                } else if (amount_select != 0 && amount_select < cheque_amount) {
                                    if (last_row) {
                                        cheque_cal = cheque_amount
                                        cheque_amount = 0;
                                        amount_select = 0;
                                    } else {
                                        cheque_cal = amount_select
                                        cheque_amount = cheque_amount - amount_select;
                                        amount_select = 0;
                                    }
                                }
                                //เงินสดย่อย
                                if (amount_select != 0 && amount_select >= petty_cash) {
                                    petty_cal = petty_cash;
                                    petty_cash = 0;
                                    amount_select = amount_select - petty_cal
                                } else if (amount_select != 0 && amount_select < petty_cash) {
                                    if (last_row) {
                                        petty_cal = petty_cash
                                        petty_cash = 0;
                                        amount_select = 0;
                                    } else {

                                        petty_cal = amount_select
                                        petty_cash = petty_cash - amount_select;
                                        amount_select = 0;
                                    }
                                }

                                //เงินประกันผลงาน
                                if (amount_select != 0 && amount_select >= retention) {
                                    retention_cal = retention;
                                    retention = 0;
                                    amount_select = amount_select - retention_cal
                                } else if (amount_select != 0 && amount_select < retention) {
                                    if (last_row) {
                                        retention_cal = retention
                                        retention = 0;
                                        amount_select = 0;
                                    } else {

                                        retention_cal = amount_select
                                        retention = retention - amount_select;
                                        amount_select = 0;
                                    }
                                }

                                totalPayRecord = transaction.node.amount + transaction.node.whtAmount;
                                accumulateRecord = this.totalAccumulateRecord((cash_cal + bank_cal + cheque_cal + petty_cal + retention_cal), transaction.node.whtAmount,
                                    retention, small_change, small_change_income, fee,
                                    last_row)
                                accumulate = accumulate + accumulateRecord;
                                TotaltotalPayRecord += totalPayRecord;
                                Totalcash_amount += cash_cal;
                                Totalbank_amount += bank_cal;
                                Totalcheque_amount += cheque_cal;
                                TotalWht += transaction.node.whtAmount;
                                total += accumulateRecord;
                            }

                            return (
                                <tr key={transaction.node.id}>
                                    <td style={color} className="text-center">{row_number && index + order_id + 1}</td>
                                    <td style={color} className="text-center">{row_number && pay_record_group.node.docNumber}
                                    </td>
                                    <td style={color} className="text-center">{format(pay_record_group.node.issuedDate, 'DD/MM/YYYY')}</td>
                                    <td style={color} >{pay_record_group.node.contact.refNumber}</td>
                                    <td style={color} className="text-left">{pay_record_group.node.contact.name}</td>
                                    <td style={color} className="text-center">{transaction.node.purchaseRecord.purchaseRecordGroup.docNumber}</td>
                                    <td style={color} className="text-center">{format(transaction.node.purchaseRecord.purchaseRecordGroup.issuedDate, 'DD/MM/YYYY')}</td>
                                    <td style={color} >{transaction.node.purchaseRecord.description}</td>

                                    <td style={color} className="text-center">{numberWithComma(totalPayRecord)}</td>
                                    <td style={color} className="text-center">{numberWithComma(cash_cal)}</td>
                                    <td style={color} className="text-center">{numberWithComma(bank_cal)}</td>
                                    <td style={color} className="text-center">{numberWithComma(cheque_cal)}</td>
                                    <td style={color} className="text-center">{numberWithComma(petty_cal)}</td>
                                    <td style={color} className="text-center">{status_void ? '-' : numberWithComma(transaction.node.whtAmount)}</td>
                                    <td style={color} className="text-center">{last_row ? numberWithComma(small_change_income) : "-"}</td>
                                    <td style={color} className="text-center">{numberWithComma(retention_cal)}</td>
                                    <td style={color} className="text-center">{last_row ? numberWithComma(-Math.abs(small_change)) : "-"}</td>
                                    <td style={color} className="text-center">{last_row ? numberWithComma(-Math.abs(fee)) : "-"}</td>
                                    <td style={color} className="text-right">{status_void ? '-' : numberWithComma(accumulateRecord, "-")}</td>
                                    <td style={color} className="text-right">{status_void ? '-' : t_index === number_of_transaction - 1 && numberWithComma(accumulate)}</td>
                                </tr>
                            )

                        })
                    )
                })}
                {(this.props.props.allPayRecordGroup.pageInfo.hasNextPage || this.props.props.allPayRecordGroup.pageInfo.hasPreviousPage) &&
                    <tr>
                        <td colSpan={19} className="text-right"><strong>รวม</strong></td>
                        <td className="text-right"><strong>{numberWithComma(total)}</strong></td>
                    </tr>
                }
                {/* {!this.props.props.allPayRecordGroup.pageInfo.hasNextPage && */}
                <tr>
                    <td colSpan={8}
                        className="text-center"><strong> {i18next.t("FilterPayable:Total Number of Items")} {this.props.props.allPayRecordGroup.totalCount}  {i18next.t("PaySummaryReport:Item")}</strong>
                    </td>
                    <td className="text-center"><strong>{numberWithComma(TotaltotalPayRecord)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(Totalcash_amount)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(Totalbank_amount)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(Totalcheque_amount)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(TotalPettyCash_amount)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(TotalWht)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(Totalsmall_change_income)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(Totalretention)}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(-Math.abs(Totalsmall_change))}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(-Math.abs(Totalfee))}</strong></td>
                    <td colSpan={3} className="text-right"><strong> {i18next.t("PaySummaryReport:Total")} {numberWithComma(this.props.props.summaryPayRecordGroup, "-")}</strong></td>
                </tr>
                {/* } */}
            </React.Fragment>
        )
    }
}

export default PaySummaryTable;
