import { format } from 'date-fns';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import BackButtonIcon from '../../components/BackBtn/indexBack';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from '../accountingTopMenuNavigation';
import jwtDecode from 'jwt-decode'
import StatusLawDebt from './status';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { fetchQuery } from "relay-runtime";

import environment from '../../env/environment';
import { encode } from 'base-64';


const mutation = graphql`
    mutation collectionLawFirmENoticeMutation ($input: CreateAndUpdateCollectionLawFirmInput!) {
        createAndUpdateCollectionLawFirm(input:$input){
            ok
            massage
            idList
        }
    }
`

const query = graphql`
    query collectionLawFirmENoticeQuery(
        $language:String,
        $siteId: ID
    ) {
        allCollectionLawFirmFromSetting(documentType: "FM_CF_02",language:$language, siteId: $siteId) {
            edges{
                node{
                    id
                    documentType
                    dueDateDays
                    dueDateDaysOnsite
                    feeRateTh
                    feeRateOther
                    lawyerSignature
                    lawyerName
                    rulesRegulationsjuristic
                    telOffice
                    telJuristic
                    language
                    creator
                    creatorEdit
                }
            } 
        }
    }
`

const CollectionLawFirmENotice = (props) => {
    const [checkList] = useState(props?.location?.state?.checkList ?? [])
    const [userCreated] = useState(jwtDecode(localStorage.getItem("token")) ?? null)
    const [nextPage, setPage] = useState("")
    const [dataRespon, setDataRespon] = useState([])
    const [setting, setSetting] = useState({})
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        fetchQuery(environment, query, { language: 'th',  siteId: encode("SiteNode:" + localStorage.getItem("site_id"))}).then((res) => {
            setSetting(res?.allCollectionLawFirmFromSetting?.edges[0]?.node ?? {})
            return
        })
    }, [])


    const conFirm = () => {
        const isEmpty = JSON.stringify(setting) === '{}';
        if(!isEmpty) {
            Swal.fire({
                customClass: {
                    title: 'swal2-title-custom',
                    header: 'swal2-header-custom',
                    container: 'swal2-container-custom colorUse',
                    content: 'swal2-content-custom',
                    confirmButton: 'swal2-confirmToRight btn bg1567B4-textfff h48 w200',
                    cancelButton: 'btn bg-BFBFBF h48 w200 fw-300',
    
                },
                imageUrl: '/images/iconAction/question.png',
                imageWidth: 122,
                html: `
                    <div class="text-center colorUse">
                        <label class="fs-18 fw-400">${i18next.t("collectionLawFirm:Confirming the completion of all debt collection letter")}</label><br/>
                        <label class="fs-18 fw-400">${i18next.t("newBookingSingha_Create_step1:Quantity")} ${checkList.length} ${i18next.t("PageDetailProjectInfo:Lists")}</label><br/>
                        <label class="fs-18 fw-400">${i18next.t("collectionLawFirm:transactions a total of")} ${_.sumBy(checkList, 'node.collectionLettersFee')} ${i18next.t("collectionLawFirm:baht (not including tax)")}</label><br/> 
                        <label class="fs-18 fw-400">${i18next.t("collectionLawFirm:The system will automatically configure expenses in the expense settings menu")}</label>
                    </div>
                `,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: i18next.t("Allaction:Confirm"),
                cancelButtonText: i18next.t("Allaction:cancel"),
            }).then((res) => {
                if (res.value) {
                    setSaving(true)
                    let contactList = []
                    checkList.map((ex) => {
                        contactList.push({ Id: ex.node.id })
                    })
    
                    if (contactList.length === checkList.length) {
                        let variables = {
                            "input": {
                                "jsonObject": {
                                    "issuedDate": new Date,
                                    "contactList": contactList,
                                    "DocumentList": []
                                }
                            }
                        }
    
    
    
                        Swal.fire({
                            timer: contactList.length * 2000,
                            customClass: {
                                title: 'swal2-title-custom',
                                header: 'swal2-header-custom',
                                container: 'swal2-container-custom colorUse',
                                content: 'swal2-content-custom',
                                confirmButton: 'swal2-confirmToRight btn bg1567B4-textfff h48 w200',
                                cancelButton: 'btn bg-BFBFBF h48 w200 fw-300',
    
                            },
                            imageUrl: '/images/iconAlerts/iconAlert-Blue4x.png',
                            imageWidth: 122,
                            html: `
                                <div class="text-center colorUse">
                                    <label class="fs-18 fw-400">โปรดรอสักครู่</label><br/>
                                    <label class="fs-18 fw-400">ระบบกำลังทำการออกหนังสือติดตาม</label><br/>
                                </div>
                            `,
                        })
                        commitMutation(
                            environment,
                            {
                                mutation,
                                variables,
                                onCompleted: (response) => {
    
                                    if (response.createAndUpdateCollectionLawFirm.ok) {
                                        Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success');
                                        setPage(true)
                                        localStorage.setItem("checkList", JSON.stringify(response.createAndUpdateCollectionLawFirm.idList))
                                        setDataRespon(response.createAndUpdateCollectionLawFirm.idList)
                                        setSaving(false)
                                    } else {
                                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createAndUpdateCollectionLawFirm.massage, 'warning');
                                    }
                                },
                                onError: (err) => {
                                    Swal.fire('Error!', i18next.t("Allaction:Please try again"), 'warning');
                                },
                            },
                        )
                    }
                } else {
                    return;
                }
            })
        }else{
            
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"),i18next.t("collectionLawFirm:The document has not been set up yet. Please contact the admin of Silverman."), 'error')
        }
       
    }

    if (!props?.location?.state?.checkList) {
        return <Redirect to={"/accounting/collection-law-firm/list/all"} />
    }

    if (nextPage && dataRespon.length > 0) {
        return <Redirect to={{ pathname: "/accounting/collection-law-firm/printDocument", state: { checkList: dataRespon } }} />
    }

    return (
        <Wrapper>
            <Header />
            <Sidebar />
            <WrapperContent>
                <AccountingTopMenuNavigation mini={true} />
                <Translation>
                    {t =>
                        <div className="container-fluid box" id="collection-law-firm">
                            <BackButtonIcon
                                LinkBack={props?.location?.state?.backLink ? props?.location?.state?.backLink : "/accounting/collection-law-firm/list/all"}
                                LinkText={t('collectionLawFirm:Summary Of Service Charge Invoice for E-Notice Debt Collection Letter')}
                            />
                            <div className='container-fluid box row colorUse'>
                                <div className='col-12'>
                                    <div className='card'>
                                        <div className='card-body row' style={{ zoom: '90%' }}>
                                            <div className='col-2'>
                                                <p className='fs-16 fw-400'>{t('invoiceDetail:Date')} </p>
                                                <p className='fs-16 fw-400'>{format(new Date(), "DD/MM/YYYY")}</p>
                                            </div>
                                            <div className='col-2'>
                                                <p className='fs-16 fw-400'>{t('payCreateAccrued:Due date')}</p>
                                                <p className='fs-16 fw-400'>{format(new Date().setDate(new Date().getDate() + setting?.dueDateDays), "DD/MM/YYYY")} ({setting?.dueDateDays} วัน)</p>
                                            </div>
                                            <div className='col-2'>
                                                <p className='fs-16 fw-400'>{t('collectionLawFirm:Creator')}</p>
                                                <p className='fs-16 fw-400'>{`${userCreated?.first_name} ${userCreated?.last_name}`}</p>
                                            </div>
                                            <div className='col-2'>
                                                <p className='fs-16 fw-400'>{t('collectionLawFirm:Status')}</p>
                                                <p className='fs-16 fw-400'>{t('collectionLawFirm:Create Debt Collection Letter')}</p>
                                            </div>

                                            <div className='col-12 mt-10'>
                                                <div className='table-responsive fade-up mt-3' style={{ width: '100%' }}>
                                                    <table className='table' style={{ width: 'max-content', minWidth: '-webkit-fill-available' }}>
                                                        <thead>
                                                            <tr className='bg-E2E2E2 fs-16 fw-400'>
                                                                <th>{t('collectionLawFirm:No.')}</th>
                                                                <th>{t('collectionLawFirm:Document Date')}</th>
                                                                <th>{t('collectionLawFirm:Unit No.')}</th>
                                                                <th>{t('collectionLawFirm:Name')}</th>
                                                                <th className='text-right'>{t('collectionLawFirm:Total Due (Baht)')}</th>
                                                                <th className='text-right'>{t('collectionLawFirm:Service charge (Baht)')}</th>
                                                                <th>{t('collectionLawFirm:Create Date')}</th>
                                                                <th>{t('collectionLawFirm:Status')}</th>
                                                                <th>{t('collectionLawFirm:Creator')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                checkList?.map((itemListCheck, index) => {
                                                                    const number = (index + 1);
                                                                    const desiredLength = 4;
                                                                    const formattedString = String(number).padStart(desiredLength, '0');

                                                                    let Ex_DocNumber = `FM-CF-${format(new Date(), "YYYYMM")}${formattedString}`
                                                                    return (
                                                                        <tr className='fs-16 fw-300' key={"collectionLawFirmENotice::" + index}>
                                                                            <td>CLF-202306XXXX</td>
                                                                            <td>{format(new Date(itemListCheck.node.collectionLettersIssuedDate), "DD/MM/YYYY")}</td>
                                                                            <td>{itemListCheck?.node?.name}</td>
                                                                            <td width={320}>{itemListCheck.node.firstName + " " + itemListCheck.node.lastName}</td>
                                                                            <td className='text-right'>{itemListCheck?.node?.collectionLettersSumTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ?? 0}</td>
                                                                            <td className='text-right'>{itemListCheck?.node?.collectionLettersFee?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ?? 0}</td>
                                                                            <td>{format(new Date(itemListCheck?.node?.collectionLettersDateCreate), "DD/MM/YYYY")}</td>
                                                                            <td><StatusLawDebt className="bg_FFBD2D pb-1 h32" status={t('collectionLawFirm:Legal Pending')} /></td>
                                                                            <td>{itemListCheck?.node?.collectionLettersCreator ?? "-"}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className='text-right mt-3'>
                                        <Link to={'/accounting/collection-law-firm/create'} style={{ textDecoration: 'none' }}>
                                            <button type='button' className='btn bg-BFBFBF h48 mr-2'> {t('collectionLawFirm:Cancel')}</button>
                                        </Link>
                                        <button type='button' disabled={saving} className='btn bg1567B4-textfff h48 ml-2' onClick={conFirm}> {t('collectionLawFirm:Confirm the use of Debt Collection Attorney Service')}</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </Translation>
            </WrapperContent>
        </Wrapper>

    )
}


export default CollectionLawFirmENotice