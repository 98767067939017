/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type oldBoardQueryVariables = {|
  search?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type oldBoardQueryResponse = {|
  +oldTenants: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +firstName: string,
        +lastName: ?string,
        +householder: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +residential: {|
                +name: string
              |}
            |}
          |}>
        |},
        +boadPersonal: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +image: ?string,
              +signature: ?string,
              +position: ?string,
              +authorization: boolean,
              +financial: ?number,
              +status: string,
              +note: ?string,
            |}
          |}>
        |},
        +boadWork: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +nameOfCompany: ?string,
              +position: ?string,
              +dateOfPosition: ?any,
              +dateOfEndPosition: ?any,
            |}
          |}>
        |},
        +boadEducation: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +degree: ?string,
              +state: string,
            |}
          |}>
        |},
        +boadFilepdf: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +nameFile: ?string,
              +fileUpload: ?string,
            |}
          |}>
        |},
        +boadTerm: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +startDate: ?any,
              +expDate: ?any,
            |}
          |}>
        |},
      |}
    |}>,
  |}
|};
export type oldBoardQuery = {|
  variables: oldBoardQueryVariables,
  response: oldBoardQueryResponse,
|};
*/


/*
query oldBoardQuery(
  $search: String
  $first: Int
  $last: Int
) {
  oldTenants(search: $search, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        firstName
        lastName
        householder {
          edges {
            node {
              residential {
                name
                id
              }
              id
            }
          }
        }
        boadPersonal {
          edges {
            node {
              id
              image
              signature
              position
              authorization
              financial
              status
              note
            }
          }
        }
        boadWork {
          edges {
            node {
              id
              nameOfCompany
              position
              dateOfPosition
              dateOfEndPosition
            }
          }
        }
        boadEducation {
          edges {
            node {
              id
              name
              degree
              state
            }
          }
        }
        boadFilepdf {
          edges {
            node {
              id
              nameFile
              fileUpload
            }
          }
        }
        boadTerm {
          edges {
            node {
              id
              startDate
              expDate
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardPersonalNodeConnection",
  "kind": "LinkedField",
  "name": "boadPersonal",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardPersonalNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BoardPersonalNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signature",
              "storageKey": null
            },
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "authorization",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "financial",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "note",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardWorkNodeConnection",
  "kind": "LinkedField",
  "name": "boadWork",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardWorkNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BoardWorkNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameOfCompany",
              "storageKey": null
            },
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dateOfPosition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dateOfEndPosition",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardEducationNodeConnection",
  "kind": "LinkedField",
  "name": "boadEducation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardEducationNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BoardEducationNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "degree",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardFilepdfNodeConnection",
  "kind": "LinkedField",
  "name": "boadFilepdf",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardFilepdfNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BoardFilepdfNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameFile",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileUpload",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardTermfNodeConnection",
  "kind": "LinkedField",
  "name": "boadTerm",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardTermfNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BoardTermfNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "oldBoardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "TenantNodeConnection",
        "kind": "LinkedField",
        "name": "oldTenants",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HouseHolderNodeConnection",
                    "kind": "LinkedField",
                    "name": "householder",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResidentialNode",
                                "kind": "LinkedField",
                                "name": "residential",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "oldBoardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "TenantNodeConnection",
        "kind": "LinkedField",
        "name": "oldTenants",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HouseHolderNodeConnection",
                    "kind": "LinkedField",
                    "name": "householder",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResidentialNode",
                                "kind": "LinkedField",
                                "name": "residential",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "afb5cfd43a50f9ee57ba671ea955ebfa",
    "id": null,
    "metadata": {},
    "name": "oldBoardQuery",
    "operationKind": "query",
    "text": "query oldBoardQuery(\n  $search: String\n  $first: Int\n  $last: Int\n) {\n  oldTenants(search: $search, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        householder {\n          edges {\n            node {\n              residential {\n                name\n                id\n              }\n              id\n            }\n          }\n        }\n        boadPersonal {\n          edges {\n            node {\n              id\n              image\n              signature\n              position\n              authorization\n              financial\n              status\n              note\n            }\n          }\n        }\n        boadWork {\n          edges {\n            node {\n              id\n              nameOfCompany\n              position\n              dateOfPosition\n              dateOfEndPosition\n            }\n          }\n        }\n        boadEducation {\n          edges {\n            node {\n              id\n              name\n              degree\n              state\n            }\n          }\n        }\n        boadFilepdf {\n          edges {\n            node {\n              id\n              nameFile\n              fileUpload\n            }\n          }\n        }\n        boadTerm {\n          edges {\n            node {\n              id\n              startDate\n              expDate\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '423bf3a6cbd8a6213e62f4d8639587f6';

module.exports = node;
