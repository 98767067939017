import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import localStorage from "../../../../libs/localstorage";
import i18next from "i18next";

class GuaranteeMoneyReceiveMovementTable extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.props.allGuaranteeMoneyReceived.edges.map((guarantee, index) => {
                    let order_id = this.props.state.first - localStorage.getPageLimit();
                    let return_amount = 0.0;
                    let forfeit_amount = 0.0;
                    let total = guarantee.node.amount;
                    if (guarantee.node.status !== "VOID") {
                        return (
                            <React.Fragment key={guarantee.node.id}>
                                <tr>
                                    <td className="text-center">{order_id + index + 1}</td>
                                    <td>{guarantee.node.docNumber}</td>
                                    <td className="text-center">{format(guarantee.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                    <td>{guarantee.node.chartOfAccount.chartOfAccountCode + " " + guarantee.node.chartOfAccount.name}</td>
                                    <td>{guarantee.node.description}</td>
                                    <td className="text-right">{numberWithComma(guarantee.node.amount)}</td>
                                    <td className="text-right">-</td>
                                    <td className="text-right">-</td>
                                    <td className="text-right">{numberWithComma(guarantee.node.amount)}</td>
                                </tr>


                                {guarantee.node.clearGuarantee.edges.map((clear_guarantee) => {
                                    if (clear_guarantee.node.status === "ACTIVE"){
                                        clear_guarantee.node.guaranteeTransaction.edges.forEach((transaction) => {
                                            if (transaction.node.typeOfTransaction === 'FORFEIT' && clear_guarantee.node.status === "ACTIVE") {
                                                forfeit_amount += transaction.node.amount
                                            } else {
                                                return_amount += transaction.node.amount
                                            }
                                        });
                                        total = total - (forfeit_amount + return_amount);
                                        return (
                                            <tr key={clear_guarantee.node.id}>
                                            <td />
                                            <td>{clear_guarantee.node.docNumber}</td>
                                            <td className="text-center">{format(clear_guarantee.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                            <td>{guarantee.node.chartOfAccount.chartOfAccountCode + " " + guarantee.node.chartOfAccount.name}</td>
                                            <td>{guarantee.node.description}</td>
                                            <td className="text-right">-</td>
                                            <td className="text-right">{numberWithComma(forfeit_amount)}</td>
                                            <td className="text-right">{numberWithComma(return_amount)}</td>
                                            <td className="text-right">0.0</td>
                                        </tr>
                                    )
                                }else{
                                    return <></>
                                }
                            }
                                )}
                                <tr>
                                    <td className="text-center font-weight-bold" colSpan={5}>{i18next.t("dailyPaymentReceiptReport:Sum")}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(guarantee.node.amount)}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(forfeit_amount)}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(return_amount)}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(total, '0.0')}</td>
                                </tr>

                            </React.Fragment>
                        )
                    }
                    else {
                        return (
                            <React.Fragment key={guarantee.node.id}>
                                <tr>
                                    <td className="text-center text-danger">{order_id + index + 1}</td>
                                    <td className="text-danger">
                                        {guarantee.node.docNumber}
                                    </td>
                                    <td className="text-center text-danger">{format(guarantee.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                    <td className="text-danger">{guarantee.node.chartOfAccount.chartOfAccountCode + " " + guarantee.node.chartOfAccount.name}</td>
                                    <td className="text-danger">{guarantee.node.description}</td>
                                    <td className="text-right text-danger">{numberWithComma(0)}</td>
                                    <td className="text-right text-danger">-</td>
                                    <td className="text-right text-danger">-</td>
                                    <td className="text-right text-danger">{numberWithComma(0)}</td>
                                </tr>


                                {guarantee.node.clearGuarantee.edges.map((clear_guarantee) => {
                                    return (
                                        <tr key={clear_guarantee.node.id}>
                                            <td />
                                            <td>{clear_guarantee.node.docNumber}</td>
                                            <td className="text-center">{format(clear_guarantee.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                            <td>{guarantee.node.chartOfAccount.chartOfAccountCode + " " + guarantee.node.chartOfAccount.name}</td>
                                            <td>{guarantee.node.description}</td>
                                            <td className="text-right">-</td>
                                            <td className="text-right">{numberWithComma(0)}</td>
                                            <td className="text-right">{numberWithComma(0)}</td>
                                            <td className="text-right">0.0</td>
                                        </tr>
                                    )
                                }
                                
                                )}
                                <tr>
                                    <td className="text-center font-weight-bold" colSpan={5}>{i18next.t("GuaranteeMoneyReceiveMovementReport:Total")}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(0)}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(0)}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(0)}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(0)}</td>
                                </tr>

                            </React.Fragment>
                        )
                    }
                })
                }
            </React.Fragment>
        )
    }
}

export default GuaranteeMoneyReceiveMovementTable;
