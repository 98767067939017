import React, { useState } from 'react';
import Header from './headerLogin';
import './styles/termOfuser.scss'
import { Link } from "react-router-dom";

const AcceptForMarketing = () => {
    const [langauge, setLangauge] = useState(localStorage.getItem("language")?? 'th')
    const [forMarketing, checkAcceptForMarketing] = useState(true)

    const nextPage = () => {
        localStorage.setItem('checkStatusAcceptForMarketing', forMarketing)
        // window.location.href = '/accept-for-policy'
    }

    const changeLangauge = (langBoon,lang) => {
        setLangauge(lang)
    }

    const acceptForMarketings = (e) => {
        if (e.target.value === "true") {
            checkAcceptForMarketing(true)
        } else if (e.target.value === "false") {
            checkAcceptForMarketing(false)
        }
    }

    return (
        <div className="height-100"> 
            <Header lang={changeLangauge} />
            <div className="col-md-10 container-fluid box " id='pdpa-menu'>
                {langauge === "th"
                    ?
                    <div className="height-80 justify-content-center" id="accept-for-marketing">
                        <div className="col-11 ml-8 selectManagementAndPolicy">
                            <h3>ความยินยอมเพื่อวัตถุประสงค์ทางการตลาด</h3>
                            <p>เราให้ความสำคัญกับความเป็นส่วนตัวของคุณอยู่เสมอ</p>
                            <p>เราทำการวิจัยและวิเคราะห์ข้อมูลส่วนบุคคลของคุณเพื่อประโยชน์สูงสุดในการพัฒนาผลิตภัณฑ์และบริการที่ตอบสนองความต้องการของคุณอย่างแท้จริง
                                รวมทั้งติดต่อคุณเพื่อเสนอผลิตภัณฑ์ บริการ และสิทธิประโยชน์ที่เหมาะสมแก่คุณโดยเฉพาะ</p>
                            <p>กรุณากด <b>"ยินยอม"</b> หากคุณตกลงให้เราเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ทางการตลาดข้างต้น</p>
                            <p>โปรดอ่านเพิ่มเติมเกี่ยวกับประกาศนโยบายความเป็นส่วนตัวของเราอย่างละเอียด เพื่อเข้าใจถึงวิธีการที่เราเก็บรวบรวม ใช้
                                <br />และเปิดเผยข้อมูลส่วนบุคคลของคุณและสิทธิของคุณที่เว็บไซต์ https://silverman.app/privacy</p>
                            <p>กรณีคุณไม่ให้ความยินยอมหรือขอถอนความยินยอมในภายหน้า คุณอาจจะพลาดโอกาสในการรับข้อเสนอผลิตภัณฑ์ บริการ
                                <br />และสิทธิประโยชน์ที่เหมาะสมกับความต้องการของคุณโดยเฉพาะจากเรา</p>

                            <p className='mt-10'>ยินยอมให้เก็บข้อมูลเพื่อวัตถุประสงค์การตลาดได้</p>

                            <div style={{ display: 'inline-flex' }}>
                                <label className="containerRadio">ยินยอม
                                    <input type={"radio"}
                                        defaultChecked={forMarketing == true}
                                        value={true}
                                        name='acceptPolicy' onChange={acceptForMarketings} />
                                    <span className="checkmark"></span></label>

                                <label className="containerRadio">ปฏิเสธ
                                    <input type={"radio"}
                                        defaultChecked={forMarketing == false}
                                        value={false}
                                        name='acceptPolicy' onChange={acceptForMarketings} />
                                    <span className="checkmark"></span></label>
                            </div>




                            <hr className='mt-10' />
                            <div className='apcentPolicy'>
                                <p>คุณอาจจะพลาด โอกาสในการรับข้อเสนอผลิตภัณฑ์บริการ และสิทธิประโยชน์ที่เหมาะสมกับความต้องการของคุณ
                                    <br />โดยเฉพาะจาก Silverman ในกรณีที่คุณไม่ให้ความยินยอมหรือขอถอนความยินยอม</p>
                            </div>
                        </div>

                    </div>
                    :
                    <div className="height-80 justify-content-center" id="accept-for-marketing">
                        <div className="col-11 ml-8 selectManagementAndPolicy">
                            <h3>Consent for marketing purposes</h3>
                            <p>Your privacy is always respected by us.</p>
                            <p>We always research and analyze your personal information for the best advantage of developing products and services that actually serve your needs.</p>
                            <p>Please press <b>"Accept"</b> if you agree to our team collecting your personal information for better marketing purposes.</p>
                            <p>Please read more about our privacy policy carefully. To understand how we collect, use, and disclose your personal information and rights at the website “ https://silverman.app/privacy”.</p>
                            <p>In case you don't give consent or later request to withdraw your consent, You may miss the opportunity to receive offers of products, services, and specific benefits for your needs from Silverman.</p>

                            <p className='mt-10'>Give your Consent for marketing purposes</p>

                            <div style={{ display: 'inline-flex' }}>
                                <label className="containerRadio">Accept
                                    <input type={"radio"}
                                        defaultChecked={forMarketing == true}
                                        value={true}
                                        name='acceptPolicy' onChange={acceptForMarketings} />
                                    <span className="checkmark"></span></label>

                                <label className="containerRadio">Reject
                                    <input type={"radio"}
                                        defaultChecked={forMarketing == false}
                                        value={false}
                                        name='acceptPolicy' onChange={acceptForMarketings} />
                                    <span className="checkmark"></span></label>
                            </div>




                            <hr className='mt-10' />
                            <div className='apcentPolicy'>
                                <p>You may miss the opportunity to receive offers of products, services, and specific benefits for your needs from Silverman in case you don't give consent or later request to withdraw your consent.</p>
                            </div>
                        </div>
                    </div>
                }
                <div id="term-of-user">
                    <div className="col-mt-10 btnLogin text-right">
                        {/* <button className='nextStep1'>เข้าสู่ระบบ / ลงทะเบียน</button> */}
                        <Link to={'/accept-for-policy'} >
                            <button className='nextStep2' onClick={nextPage}
                                disabled={forMarketing == null}

                            >{langauge === 'th' ? "ถัดไป" : "Next"}</button>
                        </Link>

                    </div>
                </div>

            </div>
        </div>
    )
}
export default AcceptForMarketing