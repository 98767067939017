import React from 'react';
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import ComponentPagination from "../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import _ from "lodash";
import Loading from "../../libs/loading"
import BackButtonIcon from '../../components/BackBtn/indexBack';

const query = graphql`
    query waterMeterListCreateQrCodeQuery($search: String) {
        allResidential (search: $search) {
            edges {
                node {
                    id
                    name
                    residentialHouseholder{
                        edges{
                            node{
                                id
                                tenant{
                                    id
                                    firstName
                                    lastName
                                }
                            }
                        }
                    }
                }
            }
        }

    }
`;


class WaterMeterListCreateQrCode extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            selectNOwNumber: 0,
            selectNow: [],
            allResidential: [],
            selectAllChk: false,
            percentLoading: null,
            loading: true,
            searchBox: "",

        }
        this.selectNow = this.selectNow.bind(this)
        this.removeSelectAll = this.removeSelectAll.bind(this)
        this.selectAllRoom = this.selectAllRoom.bind(this)
        this.handleInput = this.handleInput.bind(this)
        this.serach = this.serach.bind(this)
        this.setTimePercent = null
    }

    componentDidMount() {
        this.getMeterList();
    }

    getMeterList() {
        let variables = {
            // first: this.state.first,
            // last: this.state.last,
        };

        this.setTimePercent = setInterval(() => {
            this.setState((prevState) => ({ percentLoading: prevState.percentLoading + 8 }))
        }, 1000)

        fetchQuery(environment, query, variables).then(results => {
            let data = results?.allResidential?.edges?.map((n) => ({
                ...n.node,
                roomNumber: n?.node?.name,
                FnameOwn: n?.node?.residentialHouseholder?.edges[0]?.node?.tenant?.firstName,
                LnameOwn: n?.node?.residentialHouseholder?.edges[0]?.node?.tenant?.lastName,
                statusChecked: false,
                searchRoom: false
            }))

            this.setState({ allResidential: data, percentLoading: null, loading: false }, () => {
                clearInterval(this.setTimePercent)
            })
        });

    }

    selectNow(data) {
        let clone = _.cloneDeep(this.state.selectNow)
        let cloneAllResident = _.cloneDeep(this.state.allResidential)
        let indexFind = clone.findIndex(ex => ex.data.id === data.id)
        let indexFindInAllResident = cloneAllResident.findIndex(ex => ex.id === data.id)
        if (data.statusChecked) {
            clone.splice(indexFind, 1)
            document.getElementById(data.id).checked = false
            _.set(cloneAllResident, `[${indexFindInAllResident}].statusChecked`, false)
        }
        else {
            _.set(cloneAllResident, `[${indexFindInAllResident}].statusChecked`, true)
            let dataPull = { data: data }
            document.getElementById(data.id).checked = true
            clone.push(dataPull)
        }
        this.setState({ selectNow: clone, allResidential: cloneAllResident })
    }
    removeSelectAll() {
        this.state.selectNow.map((n) => document.getElementById(n.data.id).checked = false)
        let cloneAllResident = _.cloneDeep(this.state.allResidential)
        cloneAllResident.map((n) => {
            return (
                n.statusChecked = false
            )
        })

        this.setState({ selectNow: [], allResidential: cloneAllResident, selectAllChk: false })
    }

    selectAllRoom(type) {
        let clone = _.cloneDeep(this.state.selectNow)
        let cloneAllResident = _.cloneDeep(this.state.allResidential)
        cloneAllResident.map((n) => document.getElementById(n.id).checked = type ? true : false)
        
        if (type) {
            clone = []
            cloneAllResident.map((n, index) => {
                let dataPush = { data: n }
                // let dataPush = { }
                return (
                    n.statusChecked = true,
                    clone.push(dataPush)
                )
            })
        } else {
            cloneAllResident.map((n, index) => {
                return (
                    clone = [],
                    n.statusChecked = false
                )
            })
        }

        this.setState({ selectNow: clone, selectAllChk: type, allResidential: cloneAllResident })
    }


    handleInput(e) {
        let search = e.target.value
        if (e.key === "Enter") {
            let deata = _.cloneDeep(this.state.allResidential)
            deata.map((n) => { n.searchRoom = false })
            this.setState({ [e.target.name]: e.target.value, loading: true, allResidential: deata }) 
            this.serach(search);
        }
    }

    serach(e) {
        let deata = _.cloneDeep(this.state.allResidential)
        deata.map((n) => {
            if (e && n.name.includes(e)) {
                n.searchRoom = true
            } else {
                n.searchRoom = false
            }
        })

        this.setState({ allResidential: deata, loading: false })
    }



    render() {
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <BackButtonIcon
                            LinkBack={"/accounting/water-meter/record"}
                            // LinkBack={this.props.history.goBack()}
                            LinkText={t("materWater:Generate QR code for water meter")}
                            SpanWordRed={t("materWater:UsedFor")}
                        />
                        <div className="content-inner claim">
                            <div className='row mb-5 mt-5 meterWaterFunction '>
                                <div className='col-7 text-right' style={{ display: 'inline' }}>
                                    <div className='floatLeft'>
                                        <input type="text" name='searchBox'
                                            defaultValue={this.state.searchBox}
                                            className='form-control searchBoxs'
                                            placeholder={t("silvermanguard:search")}
                                            onKeyUp={(e) => this.setState({ [e.target.name]: e.target.value })}
                                            onKeyPress={this.handleInput}
                                        />
                                        <span className='spanINBox cursor' onClick={this.serach}>
                                            <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} />
                                        </span>
                                    </div>
                                    <div className='col-5'>

                                    </div>
                                </div>
                            </div>


                            <div className="row create">
                                <div className="col-7">
                                    <label className='headerLabel18 mb-2'>{t("materWater:View multiple items")}</label>
                                    <div className='card' style={{ padding: 24, height: 500, overflowY: 'scroll' }}>


                                        <table className='table mb-5'>
                                            <thead>
                                                <tr className='text-left bg-E6F2FE' >
                                                    <th width={50} 
                                                        onClick={() => this.selectAllRoom(this.state.selectAllChk ? false : true)}>
                                                        <input type={"checkbox"} 
                                                            checked={this.state.selectAllChk}
                                                            name='selectNowAll' 
                                                            onChange={()=> this.selectAllRoom(this.state.selectAllChk ? false : true)}
                                                        />
                                                    </th>
                                                    <th width={150}>{t("materWater:Room No")}</th>
                                                    <th>{t("materWater:Owner Name")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.percentLoading ?
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <Loading />
                                                                <div className="progress">
                                                                    <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: `${this.state.percentLoading}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        :
                                                        <tr><td colSpan={3}></td></tr>

                                                }
                                                {
                                                    !this.state.loading &&
                                                    this.state.allResidential?.map((n , indexallResidential) => {
                                                        if (this.state.searchBox !== "" && n.searchRoom) {
                                                            return (
                                                                <tr key={indexallResidential}
                                                                    onClick={() => this.selectNow(n)}
                                                                    className={n?.statusChecked == true ? 'bg-E6F2FE' : ''}>
                                                                    <td>
                                                                        <input type={"checkbox"}
                                                                            defaultChecked={n?.statusChecked}
                                                                            name='selectNow'
                                                                            onClick={() => this.selectNow(n)}
                                                                            id={n?.id} />
                                                                    </td>
                                                                    <td>{n?.roomNumber}</td>
                                                                    <td>{n?.FnameOwn + " " + n?.LnameOwn}</td>
                                                                </tr>
                                                            )
                                                        } else if (this.state.searchBox === "" && !n.searchRoom) {
                                                            return (
                                                                <tr key={"IDKey" + n?.id}
                                                                    onClick={() => this.selectNow(n)}
                                                                    className={n?.statusChecked == true ? 'bg-E6F2FE' : ''}>
                                                                    <td>
                                                                        <input type={"checkbox"}
                                                                            defaultChecked={n?.statusChecked}
                                                                            name='selectNow'
                                                                            onClick={() => this.selectNow(n)}
                                                                            id={n?.id} />
                                                                    </td>
                                                                    <td>{n?.roomNumber}</td>
                                                                    <td>{n?.FnameOwn + " " + n?.LnameOwn}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <div className='col-5'>
                                    <label className='headerLabel18 mb-2'>
                                        {`${t("materWater:Selected item")} `}

                                    {
                                        this.state.selectNow?.length > 1 ?
                                        `(${this.state.selectNow?.length}) ${t("materWater:items")}` :
                                        `(${this.state.selectNow?.length}) ${t("materWater:item")}`
                                    } 
                                    </label>
                                    <div className='card' style={{ padding: 24, height: 500, overflowY: 'scroll', position: 'relative' }}>
                                        <table className='table'>
                                            <thead>
                                                <tr style={{ backgroundColor: '#E6F2FE' }} className='text-left'>
                                                    <th width={150}>{t("materWater:Room No")}</th>
                                                    <th>{t("materWater:Owner Name")}</th>
                                                    <th width={50} className='text-right'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.selectNow?.map((n , index) => {
                                                        return (
                                                            <tr key={"IDKeySelectNow"+index + n?.data?.id}>

                                                                <td>{n?.data?.roomNumber}</td>
                                                                <td>{n?.data?.FnameOwn + " " + n?.data?.LnameOwn}</td>
                                                                <td onClick={() => this.selectNow(n)}>
                                                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"}
                                                                        style={{ width: 16, height: 16 }} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>



                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col text-right mt-10'>
                                    {/* <Link
                                        to={{ pathname: `/accounting/water-meter/printQrcodeMeterWater`, query: { data: this.state.selectNow } }}
                                        target={'_blank'}> */}
                                    <span className='headerLabel18 cursor fw-600'
                                        onClick={this.removeSelectAll}
                                        style={{ position: 'absolute', top: -75, right: 24, color: '#E14558', fontSize: 12 }}>{t("materWater:Remove all")}</span>
                                    <button
                                        className='btn-primary'
                                        type={"button"}
                                        onClick={() =>
                                            this.props.history.push("/accounting/water-meter/printQrcodeMeterWater", { data: this.state.selectNow })}
                                    >
                                        {t("Allaction:next")}
                                    </button>
                                    {/* </Link> */}

                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        );
    }
}

export default withRouter(WaterMeterListCreateQrCode);
