/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DownloadJobRecordStatus = "CANCEL" | "FAIL" | "PENDING" | "SUCCEED" | "%future added value";
export type DownloadJobStatus = "CANCEL" | "FAIL" | "INCOMPLETE" | "PENDING" | "SUCCEED" | "%future added value";
export type downloadFileTableQueryVariables = {|
  search?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  typeReportRef?: ?string,
  status?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type downloadFileTableQueryResponse = {|
  +allDownloadJob: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +status: DownloadJobStatus,
        +typeReportRef: ?string,
        +downloadJobRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +idFile: ?string,
              +fileName: ?string,
              +pathFile: ?string,
              +sizeFile: ?string,
              +typeFile: ?string,
              +status: DownloadJobRecordStatus,
              +downloadWho: ?string,
              +downloadDate: ?any,
              +forecastTime: ?any,
              +added: any,
              +updated: any,
            |}
          |}>
        |},
      |}
    |}>,
  |}
|};
export type downloadFileTableQuery = {|
  variables: downloadFileTableQueryVariables,
  response: downloadFileTableQueryResponse,
|};
*/


/*
query downloadFileTableQuery(
  $search: String
  $startDate: Date
  $endDate: Date
  $typeReportRef: String
  $status: String
  $first: Int
  $last: Int
) {
  allDownloadJob(search: $search, startDate: $startDate, endDate: $endDate, typeReportRef: $typeReportRef, status: $status, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        docNumber
        status
        typeReportRef
        downloadJobRecord {
          edges {
            node {
              id
              idFile
              fileName
              pathFile
              sizeFile
              typeFile
              status
              downloadWho
              downloadDate
              forecastTime
              added
              updated
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeReportRef"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "typeReportRef",
        "variableName": "typeReportRef"
      }
    ],
    "concreteType": "DownloadJobNodeConnection",
    "kind": "LinkedField",
    "name": "allDownloadJob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DownloadJobNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DownloadJobNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeReportRef",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DownloadJobRecordNodeConnection",
                "kind": "LinkedField",
                "name": "downloadJobRecord",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DownloadJobRecordNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DownloadJobRecordNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "idFile",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pathFile",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sizeFile",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "typeFile",
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "downloadWho",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "downloadDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "forecastTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "added",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updated",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "downloadFileTableQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "downloadFileTableQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "43ef0eb844e6a2c73fa1c5428cd4bffa",
    "id": null,
    "metadata": {},
    "name": "downloadFileTableQuery",
    "operationKind": "query",
    "text": "query downloadFileTableQuery(\n  $search: String\n  $startDate: Date\n  $endDate: Date\n  $typeReportRef: String\n  $status: String\n  $first: Int\n  $last: Int\n) {\n  allDownloadJob(search: $search, startDate: $startDate, endDate: $endDate, typeReportRef: $typeReportRef, status: $status, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        status\n        typeReportRef\n        downloadJobRecord {\n          edges {\n            node {\n              id\n              idFile\n              fileName\n              pathFile\n              sizeFile\n              typeFile\n              status\n              downloadWho\n              downloadDate\n              forecastTime\n              added\n              updated\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6683f8beeeb67c79e621829cf0ce5c5f';

module.exports = node;
