/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProcurementFormStatus = "ACTIVE" | "APPROVE" | "DRAFT" | "EDIT" | "VOID" | "%future added value";
export type ProcurementFormType = "ASSET" | "PRODUCT" | "SERVICE" | "SERVICE_CONTACT" | "%future added value";
export type PurchaseOrderesStatus = "APPROVE" | "VOID" | "WAIT" | "%future added value";
export type manageOrderTableQueryVariables = {|
  status?: ?string,
  first?: ?number,
  last?: ?number,
  search?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  firstStr?: ?string,
  lastStr?: ?string,
|};
export type manageOrderTableQueryResponse = {|
  +allApproveProcurementForm: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +status: ProcurementFormStatus,
        +voidRemark: string,
        +creditor: {|
          +id: string,
          +name: string,
        |},
        +chartOfAccount: ?{|
          +id: string,
          +name: string,
          +nameEn: ?string,
          +chartOfAccountCode: string,
        |},
        +buyDate: any,
        +type: ProcurementFormType,
        +total: ?number,
        +comparativeCompany: ?number,
        +lowestOffer: number,
        +highestOffer: number,
        +contractor: ?string,
        +approveDate: ?any,
      |}
    |}>,
    +totalCount: ?number,
  |},
  +allPurchaseOrderes: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +status: PurchaseOrderesStatus,
        +voidRemark: string,
        +voidDate: ?any,
        +voider: ?string,
        +approver: ?string,
        +approveDate: ?any,
        +procurementForm: {|
          +creditor: {|
            +id: string,
            +name: string,
          |},
          +issuedDate: any,
          +buyDate: any,
          +type: ProcurementFormType,
          +status: ProcurementFormStatus,
          +total: ?number,
          +chartOfAccount: ?{|
            +id: string,
            +name: string,
            +nameEn: ?string,
            +chartOfAccountCode: string,
          |},
          +comparativeCompany: ?number,
          +lowestOffer: number,
          +highestOffer: number,
          +contractor: ?string,
        |},
      |}
    |}>,
    +totalCount: ?number,
  |},
  +listAllPurchaseOrderes: ?{|
    +dataTable: ?$ReadOnlyArray<?any>,
    +numRow: ?number,
  |},
|};
export type manageOrderTableQuery = {|
  variables: manageOrderTableQueryVariables,
  response: manageOrderTableQueryResponse,
|};
*/


/*
query manageOrderTableQuery(
  $status: String
  $first: Int
  $last: Int
  $search: String
  $startDate: Date
  $endDate: Date
  $firstStr: String
  $lastStr: String
) {
  allApproveProcurementForm(search: $search, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {
    edges {
      node {
        id
        docNumber
        issuedDate
        status
        voidRemark
        creditor {
          id
          name
        }
        chartOfAccount {
          id
          name
          nameEn
          chartOfAccountCode
        }
        buyDate
        type
        total
        comparativeCompany
        lowestOffer
        highestOffer
        contractor
        approveDate
      }
    }
    totalCount
  }
  allPurchaseOrderes(status: $status, search: $search, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {
    edges {
      node {
        id
        docNumber
        issuedDate
        status
        voidRemark
        voidDate
        voider
        approver
        approveDate
        procurementForm {
          creditor {
            id
            name
          }
          issuedDate
          buyDate
          type
          status
          total
          chartOfAccount {
            id
            name
            nameEn
            chartOfAccountCode
          }
          comparativeCompany
          lowestOffer
          highestOffer
          contractor
          id
        }
      }
    }
    totalCount
  }
  listAllPurchaseOrderes(search: $search, startDate: $startDate, endDate: $endDate, first: $firstStr, last: $lastStr) {
    dataTable
    numRow
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstStr"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastStr"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v8 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "endDate"
},
v9 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v10 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v11 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v12 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "startDate"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidRemark",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "creditor",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v18/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccount",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v18/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chartOfAccountCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buyDate",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comparativeCompany",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lowestOffer",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "highestOffer",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractor",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approveDate",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": [
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "concreteType": "ProcurementFormNodeConnection",
  "kind": "LinkedField",
  "name": "allApproveProcurementForm",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProcurementFormNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProcurementFormNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/),
            (v19/*: any*/),
            (v20/*: any*/),
            (v21/*: any*/),
            (v22/*: any*/),
            (v23/*: any*/),
            (v24/*: any*/),
            (v25/*: any*/),
            (v26/*: any*/),
            (v27/*: any*/),
            (v28/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v29/*: any*/)
  ],
  "storageKey": null
},
v31 = [
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidDate",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voider",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approver",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": [
    (v8/*: any*/),
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "firstStr"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "lastStr"
    },
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "concreteType": "ListAllPurchaseOrderesObject",
  "kind": "LinkedField",
  "name": "listAllPurchaseOrderes",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataTable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numRow",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "manageOrderTableQuery",
    "selections": [
      (v30/*: any*/),
      {
        "alias": null,
        "args": (v31/*: any*/),
        "concreteType": "PurchaseOrderesNodeConnection",
        "kind": "LinkedField",
        "name": "allPurchaseOrderes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseOrderesNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseOrderesNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v32/*: any*/),
                  (v33/*: any*/),
                  (v34/*: any*/),
                  (v28/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProcurementFormNode",
                    "kind": "LinkedField",
                    "name": "procurementForm",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v15/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v16/*: any*/),
                      (v23/*: any*/),
                      (v20/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v29/*: any*/)
        ],
        "storageKey": null
      },
      (v35/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "manageOrderTableQuery",
    "selections": [
      (v30/*: any*/),
      {
        "alias": null,
        "args": (v31/*: any*/),
        "concreteType": "PurchaseOrderesNodeConnection",
        "kind": "LinkedField",
        "name": "allPurchaseOrderes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseOrderesNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseOrderesNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v32/*: any*/),
                  (v33/*: any*/),
                  (v34/*: any*/),
                  (v28/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProcurementFormNode",
                    "kind": "LinkedField",
                    "name": "procurementForm",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v15/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v16/*: any*/),
                      (v23/*: any*/),
                      (v20/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v29/*: any*/)
        ],
        "storageKey": null
      },
      (v35/*: any*/)
    ]
  },
  "params": {
    "cacheID": "cb6bf8f551e1a3f4b6a7c263b06f2345",
    "id": null,
    "metadata": {},
    "name": "manageOrderTableQuery",
    "operationKind": "query",
    "text": "query manageOrderTableQuery(\n  $status: String\n  $first: Int\n  $last: Int\n  $search: String\n  $startDate: Date\n  $endDate: Date\n  $firstStr: String\n  $lastStr: String\n) {\n  allApproveProcurementForm(search: $search, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        status\n        voidRemark\n        creditor {\n          id\n          name\n        }\n        chartOfAccount {\n          id\n          name\n          nameEn\n          chartOfAccountCode\n        }\n        buyDate\n        type\n        total\n        comparativeCompany\n        lowestOffer\n        highestOffer\n        contractor\n        approveDate\n      }\n    }\n    totalCount\n  }\n  allPurchaseOrderes(status: $status, search: $search, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        status\n        voidRemark\n        voidDate\n        voider\n        approver\n        approveDate\n        procurementForm {\n          creditor {\n            id\n            name\n          }\n          issuedDate\n          buyDate\n          type\n          status\n          total\n          chartOfAccount {\n            id\n            name\n            nameEn\n            chartOfAccountCode\n          }\n          comparativeCompany\n          lowestOffer\n          highestOffer\n          contractor\n          id\n        }\n      }\n    }\n    totalCount\n  }\n  listAllPurchaseOrderes(search: $search, startDate: $startDate, endDate: $endDate, first: $firstStr, last: $lastStr) {\n    dataTable\n    numRow\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '659a55a0999ccd2ad3fc12677d56c52c';

module.exports = node;
