/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type receivableClassifiedReportQueryVariables = {|
  startMonth?: ?number,
  startYear?: ?number,
  endMonth?: ?number,
  endYear?: ?number,
  product_and_service?: ?string,
  asDate?: ?any,
|};
export type receivableClassifiedReportQueryResponse = {|
  +productViewer: ?{|
    +productSummaryByStatus: ?{|
      +productServiceNode: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: string,
            +productCode: string,
          |}
        |}>
      |},
      +colAllsList: ?$ReadOnlyArray<?any>,
    |}
  |}
|};
export type receivableClassifiedReportQuery = {|
  variables: receivableClassifiedReportQueryVariables,
  response: receivableClassifiedReportQueryResponse,
|};
*/


/*
query receivableClassifiedReportQuery(
  $startMonth: Int
  $startYear: Int
  $endMonth: Int
  $endYear: Int
  $product_and_service: String
  $asDate: Date
) {
  productViewer {
    productSummaryByStatus(startMonth: $startMonth, startYear: $startYear, productAndService: $product_and_service, endMonth: $endMonth, endYear: $endYear, asDate: $asDate) {
      productServiceNode {
        edges {
          node {
            id
            name
            productCode
          }
        }
      }
      colAllsList
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "asDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endMonth"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endYear"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "product_and_service"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startMonth"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startYear"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "asDate",
      "variableName": "asDate"
    },
    {
      "kind": "Variable",
      "name": "endMonth",
      "variableName": "endMonth"
    },
    {
      "kind": "Variable",
      "name": "endYear",
      "variableName": "endYear"
    },
    {
      "kind": "Variable",
      "name": "productAndService",
      "variableName": "product_and_service"
    },
    {
      "kind": "Variable",
      "name": "startMonth",
      "variableName": "startMonth"
    },
    {
      "kind": "Variable",
      "name": "startYear",
      "variableName": "startYear"
    }
  ],
  "concreteType": "ProductSummaryByStatusObject",
  "kind": "LinkedField",
  "name": "productSummaryByStatus",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNodeConnection",
      "kind": "LinkedField",
      "name": "productServiceNode",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAndServiceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductAndServiceNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "productCode",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "colAllsList",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "receivableClassifiedReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "receivableClassifiedReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b43821f08e0742c61b1d61a31faf266e",
    "id": null,
    "metadata": {},
    "name": "receivableClassifiedReportQuery",
    "operationKind": "query",
    "text": "query receivableClassifiedReportQuery(\n  $startMonth: Int\n  $startYear: Int\n  $endMonth: Int\n  $endYear: Int\n  $product_and_service: String\n  $asDate: Date\n) {\n  productViewer {\n    productSummaryByStatus(startMonth: $startMonth, startYear: $startYear, productAndService: $product_and_service, endMonth: $endMonth, endYear: $endYear, asDate: $asDate) {\n      productServiceNode {\n        edges {\n          node {\n            id\n            name\n            productCode\n          }\n        }\n      }\n      colAllsList\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '238a9a2c879d4a11eaf94e6a4024219f';

module.exports = node;
