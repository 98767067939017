/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdvanceStatus = "CLEAR" | "VOID" | "WITHDRAW" | "%future added value";
export type advanceListTableQueryVariables = {|
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  first?: ?number,
  last?: ?number,
|};
export type advanceListTableQueryResponse = {|
  +allAdvance: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +issuedDate: any,
        +dueDate: ?any,
        +docNumber: string,
        +description: string,
        +withdrawer: string,
        +amount: number,
        +status: AdvanceStatus,
      |}
    |}>,
  |}
|};
export type advanceListTableQuery = {|
  variables: advanceListTableQueryVariables,
  response: advanceListTableQueryResponse,
|};
*/


/*
query advanceListTableQuery(
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $first: Int
  $last: Int
) {
  allAdvance(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        issuedDate
        dueDate
        docNumber
        description
        withdrawer
        amount
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      }
    ],
    "concreteType": "AdvanceNodeConnection",
    "kind": "LinkedField",
    "name": "allAdvance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdvanceNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvanceNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "issuedDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "withdrawer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "advanceListTableQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "advanceListTableQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "f7564cc73835ac3351b3462e8dd60481",
    "id": null,
    "metadata": {},
    "name": "advanceListTableQuery",
    "operationKind": "query",
    "text": "query advanceListTableQuery(\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $first: Int\n  $last: Int\n) {\n  allAdvance(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        issuedDate\n        dueDate\n        docNumber\n        description\n        withdrawer\n        amount\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '777e69593002a4d985c5bb7dc9af66cb';

module.exports = node;
