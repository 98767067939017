import React from 'react';
import { Component } from 'react';
import TransferForm from './transferForm';
import {Redirect} from 'react-router'

class TransferOfOwnershipForm extends Component{

    render(){
        if(this.props.contactList.length > 0){
            return(
                <TransferForm contactList={this.props.contactList}/>

            );
        }
        return <Redirect to="/developer/unit-status/create/select_customer"/>;
    }



}
export default TransferOfOwnershipForm;