import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import NoMatch from "../../components/noMatch";
import ComplaintList from "./complaintList";
import Chat from "../chat";

class Complaint extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/:status`} component={ComplaintList}/>
                <PrivateRoute exact path={`${this.props.match.url}/chat/:page/:id`} component={Chat}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Complaint;