/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BatchReceiveStatus = "ACTIVE" | "VOID" | "%future added value";
export type batchPrintPDFAllQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
  search?: ?string,
  status?: ?string,
|};
export type batchPrintPDFAllQueryResponse = {|
  +allBatchReceive: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +batchDate: any,
        +startRt: ?string,
        +endRt: ?string,
        +creator: ?string,
        +voider: ?string,
        +remark: ?string,
        +status: BatchReceiveStatus,
        +added: any,
        +receiveCount: ?number,
        +importReceive: ?{|
          +id: string,
          +added: any,
          +allRow: number,
        |},
      |}
    |}>,
    +totalCount: ?number,
  |}
|};
export type batchPrintPDFAllQuery = {|
  variables: batchPrintPDFAllQueryVariables,
  response: batchPrintPDFAllQueryResponse,
|};
*/


/*
query batchPrintPDFAllQuery(
  $startDate: Date
  $endDate: Date
  $search: String
  $status: String
) {
  allBatchReceive(startDate: $startDate, endDate: $endDate, search: $search, status: $status) {
    edges {
      node {
        id
        docNumber
        batchDate
        startRt
        endRt
        creator
        voider
        remark
        status
        added
        receiveCount
        importReceive {
          id
          added
          allRow
        }
      }
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "BatchReceiveNodeConnection",
    "kind": "LinkedField",
    "name": "allBatchReceive",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BatchReceiveNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BatchReceiveNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "batchDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startRt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endRt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voider",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remark",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "receiveCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImportReceiveNode",
                "kind": "LinkedField",
                "name": "importReceive",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allRow",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "batchPrintPDFAllQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "batchPrintPDFAllQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "2fa85c574b71c1186af2320997a19eaa",
    "id": null,
    "metadata": {},
    "name": "batchPrintPDFAllQuery",
    "operationKind": "query",
    "text": "query batchPrintPDFAllQuery(\n  $startDate: Date\n  $endDate: Date\n  $search: String\n  $status: String\n) {\n  allBatchReceive(startDate: $startDate, endDate: $endDate, search: $search, status: $status) {\n    edges {\n      node {\n        id\n        docNumber\n        batchDate\n        startRt\n        endRt\n        creator\n        voider\n        remark\n        status\n        added\n        receiveCount\n        importReceive {\n          id\n          added\n          allRow\n        }\n      }\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5840f466b4827d8a71223fed45283bc6';

module.exports = node;
