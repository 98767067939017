/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatePurchaseRecordGroupAndTransactionInput = {|
  contactList?: ?string,
  purchase: any,
  transactionList: any,
  prepaidDepositUse?: ?any,
  taxInvoicePay?: ?any,
  clientMutationId?: ?string,
|};
export type CreatePurchaseMutationVariables = {|
  input: UpdatePurchaseRecordGroupAndTransactionInput
|};
export type CreatePurchaseMutationResponse = {|
  +updatePurchaseRecordGroupAndTransaction: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type CreatePurchaseMutation = {|
  variables: CreatePurchaseMutationVariables,
  response: CreatePurchaseMutationResponse,
|};
*/


/*
mutation CreatePurchaseMutation(
  $input: UpdatePurchaseRecordGroupAndTransactionInput!
) {
  updatePurchaseRecordGroupAndTransaction(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePurchaseRecordGroupAndTransactionPayload",
    "kind": "LinkedField",
    "name": "updatePurchaseRecordGroupAndTransaction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePurchaseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePurchaseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3f071720e733ca36a1aa4634a0df080d",
    "id": null,
    "metadata": {},
    "name": "CreatePurchaseMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePurchaseMutation(\n  $input: UpdatePurchaseRecordGroupAndTransactionInput!\n) {\n  updatePurchaseRecordGroupAndTransaction(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '17a9ad82b36b0c4d29e07eaa7b9c2f03';

module.exports = node;
