/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GlobalBankAccountStatus = "ACTIVE" | "DEACTIVATE" | "%future added value";
export type createChequeDepositQueryVariables = {|
  makePayment?: ?boolean,
  receivePayment?: ?boolean,
  accountType?: ?string,
|};
export type createChequeDepositQueryResponse = {|
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +bankName: string,
          +branch: string,
        |}
      |}>
    |}
  |},
  +allGlobalBankAccount: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +logo: string,
        +status: GlobalBankAccountStatus,
      |}
    |}>
  |},
|};
export type createChequeDepositQuery = {|
  variables: createChequeDepositQueryVariables,
  response: createChequeDepositQueryResponse,
|};
*/


/*
query createChequeDepositQuery(
  $makePayment: Boolean
  $receivePayment: Boolean
  $accountType: String
) {
  bankAccountViewer {
    allBankAccount(status: true, hasCheque: true, accountType_In: $accountType, makePayment: $makePayment, receivePayment: $receivePayment) {
      edges {
        node {
          id
          docNumber
          accountNumber
          bankName
          branch
        }
      }
    }
    id
  }
  allGlobalBankAccount(status: "active") {
    edges {
      node {
        id
        name
        logo
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "makePayment"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "receivePayment"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "accountType_In",
      "variableName": "accountType"
    },
    {
      "kind": "Literal",
      "name": "hasCheque",
      "value": true
    },
    {
      "kind": "Variable",
      "name": "makePayment",
      "variableName": "makePayment"
    },
    {
      "kind": "Variable",
      "name": "receivePayment",
      "variableName": "receivePayment"
    },
    {
      "kind": "Literal",
      "name": "status",
      "value": true
    }
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bankName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "branch",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "status",
      "value": "active"
    }
  ],
  "concreteType": "GlobalBankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allGlobalBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GlobalBankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GlobalBankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allGlobalBankAccount(status:\"active\")"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createChequeDepositQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createChequeDepositQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "35c4cf638cb0665c1646593f7bb9f592",
    "id": null,
    "metadata": {},
    "name": "createChequeDepositQuery",
    "operationKind": "query",
    "text": "query createChequeDepositQuery(\n  $makePayment: Boolean\n  $receivePayment: Boolean\n  $accountType: String\n) {\n  bankAccountViewer {\n    allBankAccount(status: true, hasCheque: true, accountType_In: $accountType, makePayment: $makePayment, receivePayment: $receivePayment) {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          bankName\n          branch\n        }\n      }\n    }\n    id\n  }\n  allGlobalBankAccount(status: \"active\") {\n    edges {\n      node {\n        id\n        name\n        logo\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '801311489462fea2e17ebdf5bb6f02c8';

module.exports = node;
