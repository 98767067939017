/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateEditorDocumentLogInput = {|
  docNumber: string,
  firstName?: ?string,
  lastName?: ?string,
  taxIdNumber?: ?string,
  address?: ?string,
  city?: ?string,
  province?: ?string,
  country?: ?string,
  district?: ?string,
  postalCode?: ?string,
  email?: ?string,
  phone?: ?string,
  clientMutationId?: ?string,
|};
export type CreateEditorDocumentLogMutationVariables = {|
  input: CreateEditorDocumentLogInput
|};
export type CreateEditorDocumentLogMutationResponse = {|
  +createEditorDocumentLog: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type CreateEditorDocumentLogMutation = {|
  variables: CreateEditorDocumentLogMutationVariables,
  response: CreateEditorDocumentLogMutationResponse,
|};
*/


/*
mutation CreateEditorDocumentLogMutation(
  $input: CreateEditorDocumentLogInput!
) {
  createEditorDocumentLog(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateEditorDocumentLogPayload",
    "kind": "LinkedField",
    "name": "createEditorDocumentLog",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateEditorDocumentLogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateEditorDocumentLogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "81040485cc86da408702d73853980280",
    "id": null,
    "metadata": {},
    "name": "CreateEditorDocumentLogMutation",
    "operationKind": "mutation",
    "text": "mutation CreateEditorDocumentLogMutation(\n  $input: CreateEditorDocumentLogInput!\n) {\n  createEditorDocumentLog(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b922e2b8b302d8b254c27a4d63603d1';

module.exports = node;
