import React, { Component } from 'react';
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import Navigation from './navigation'
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import upload from '../../../libs/upload';
import environment from "../../../env/environment";
import _ from "lodash";
import UploadImageInput from '../../../libs/uploadImageInput';
import Swal from 'sweetalert2';
import Select from 'react-select';
import API from '../../../api/silverGraund2'
import '../settingSecurity.scss';
import i18next from 'i18next';

const customStyles = {
    control: provided => ({
      ...provided,
      fontFamily: 'kanit'
    })
  }

const query = graphql`
    query settingWebviewQRQuery{

        selfProject {
            name
            logo
        }
       
    }
`;
class settingWebviewQR extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            bankData: [],
            payment:'',
            condition:'',
            selectBank: {},
            bankAccountID: '',
            bankName:'',
            bankNumber:'',
            bankAccountName:'',
            oldPayment:'',
            newPayment:'',
            projectName:'',
            projectLogo:'',
            settingParkingFeeAccountID: null,
            account:[],
            checkBankAccountId:"",
            isSelectBank: false,
            isUploadQRCode:false

        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputChangeBank = this.handleInputChangeBank.bind(this)
        this.getTypeBank = this.getTypeBank.bind(this)
        this.onSubmit = this.onSubmit.bind(this);
    }

 
    componentDidMount(){
        this.getBank()
        this.getSettingParkingFeeAccount()
        this.getSelfProject()
    }

    getSelfProject(){
        fetchQuery(environment, query).then(data => {
            if(data.selfProject){
                this.setState({
                    projectName:data.selfProject.name,
                    projectLogo:data.selfProject.logo
                })
            }
        })
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event?.target?.value;
        this.setState(prevState => { return _.set(_.cloneDeep(prevState), event.target.name, value)});   
    }

    handleInputChangeBank(e) {
        let event = _.cloneDeep(e);
        if(event.name === 'bankAccount'){
            this.setState({
                selectBank: event,
                bankAccountID: event.bankAccountId,
                bankName:event.bankName,
                bankNumber:event.accountNumber,
                bankAccountName:event.accountName,
            })
        }
          
    }

    getTypeBank(type){
        let result = ""
        switch (type) {
            case "current_account":
            result = "กระแส"
            break;
            case "saving_account":
            result = "ออมทรัพย์"
            break;
            case "fixed_deposit":
            result = "ประจำ"
            break;
            case `fixed_deposit`:
            result = "ฝากเผื่อเรียก"
            break;
        }
        return result;
    }
    
    getBank(){
        this.setState({
            loading : true
        })
        API.getBank().then((res) => {
           if(res.data){
            let data_clone = _.cloneDeep(res.data);
            let allBankData = data_clone?.bankAccount?.map((n) => 
            ({ ...n, value: n.bankAccountId, label:  n.accountNumber +" "+ this.getTypeBank(n.accountType) + " " + n.bankName ,name:'bankAccount'}))
                this.setState({
                    bankData : allBankData,
                    loading : false
                })
           }
        }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    getSettingParkingFeeAccount(){
        API.getSettingParkingFeeAccount().then((res) => {
            if(res.data.account){
                this.setState({
                    account: res.data.account,
                    condition : res.data.account?.condition,
                    payment: res.data.account?.payment,
                    bankName:res.data.account?.bankName,
                    bankNumber:res.data.account?.accountNumber,
                    bankAccountName:res.data.account?.accountName,
                    settingParkingFeeAccountID:res.data.account?.settingParkingFeeAccountId,
                    bankAccountID:res.data.account?.bankAccountId,
                    selectBank:{
                        value: res.data.account.bankAccountId, 
                        label: res.data.account.accountNumber +" "+ this.getTypeBank(res.data.account.accountType) + " " + res.data.account.bankName 
                    }
                })
            }
       
        }, () => {
            Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Saved Unsuccessful"), 'error')
        })
    } 
    

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/vms/" + path, file);
          resolve(res);
        });
    };
      

  async onSubmit(e){
    e.preventDefault();

        if(this.state.bankAccountID === ''){
            this.setState({
                isSelectBank: true
            })
        }else if(this.state.payment === ''){
            this.setState({
                isUploadQRCode: true
            })
        }else{
            let checkPayment = _.isObject(this.state.payment) ? this.state.payment :'';
        
            if(checkPayment){
                let new_file = new File([this.state.payment], `${'payment_' + new Date().getTime()}.jpeg`);
                await this.callUpload("image", new_file).then((data) => {
                    let dese = "vms/image/" + new_file.name
                    this.setState({ 
                        newPayment: dese,
                    })
                });
            }else{
                checkPayment = false
            }

            let idCode = this.state.account.length !== 0 ? this.state.settingParkingFeeAccountID : "";

            let data = {
                "settingParkingFeeAccountId":idCode,
                "chartOfAccountId":"",
                "payment":checkPayment ? this.state.newPayment :"",
                "bankAccountId":this.state.bankAccountID,
                "condition":this.state.condition
            }
            API.postSettingParkingFeeAccount(data).then((res) => {
                if(res.data){
                    if(res.data.statusMessage === "SUCCESS"){
                        Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                            this.setState({
                                loading: false
                            },() => {
                                window.location.reload()
                            });
                        });
                    }else{
                        Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:You have already created the data."), 'error') 
                        this.setState({
                            loading:false
                        }) 
                    }
                }
        
            }, () => {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Saved Unsuccessful"), 'error')
            })

        }
        
    

    
   }


    render() {
        let chekImgPayment = _.isObject(this.state.payment) ? URL.createObjectURL(this.state.payment) : this.state.payment;
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid box" id="settingWebviewQR">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span style={{ fontWeight: 'bolder' }}>{t('setting:settingKeepPacking')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>

                        <Navigation />

                        {
                        //  this.state.loading == false &&
                            <Translation>
                                {t =>
                                <>      
                                 <div>
                                    <form onSubmit={this.onSubmit}>
                                        <div className='row'>
                                                <div className='col'>
                                                    <div className='setting-qr-container'>
                                                    <div>
                                                            <Translation>
                                                                {
                                                                    t => <label>{t('settingBankAccount:Please provide the QR code file for the bank account to receive the parking fee.')}</label>
                                                                }
                                                            </Translation><span className='text-red'>*</span>
                                                            <Select
                                                                className="select"
                                                                options={this.state.bankData}
                                                                value={this.state.selectBank}
                                                                isSearchable={true}
                                                                onChange={this.handleInputChangeBank}
                                                                placeholder ="ชื่อธนาคาร"
                                                                styles={customStyles}
                                                            />
                                                            {this.state.isSelectBank ?  
                                                            <Translation>
                                                                {
                                                                    t =>   <span className='text-red'>{t('settingBankAccount:Please select a bank account')}</span>
                                                                }
                                                            </Translation>
                                                         : ''} 
                                                    </div>
                                                    <div className='pt-4'>
                                                            <Translation>
                                                                {
                                                                    t => <label>{t('settingBankAccount:The QR code image must be a .jpg or .png format and have dimensions of 250 x 250 pixels or less.')}</label>
                                                                }
                                                            </Translation>
                                                        <span className='text-red'>*</span>
                                                        <div className="tooltipImg">
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                            alt="question" className="question ml-1" style={{width:'12px',marginBottom:'10px'}}/>
                                                                <span className="tooltiptext">
                                                                        รูป QR Code ต้องเป็นไฟล์ภาพ .jpg หรือ .png <br/>ขนาดไม่เกิน 250 x 250 พิกเซล
                                                                </span>
                                                            <span className="tooltipTriangle">
                                                                <img src={process.env.PUBLIC_URL + '/images/tooltip/tooltipTriangle.png'}/>
                                                            </span>
                                                        </div>
                                                        <div className="upload-file">
                                                        {
                                                        this.state.payment === '' ?
                                                            <>
                                                                <div style={{position:'relative'}}>
                                                                <label
                                                                    htmlFor="uploadPayment"
                                                                    className="create news-button-image btn btn-light mt-2"
                                                                >
                                                                    <img
                                                                        src={
                                                                            process.env.PUBLIC_URL +
                                                                            "/images/icons/plus-icon.png" }
                                                                        alt="plus-icon"
                                                                        className="img-fluid plus news-image"
                                                                    />
                                                                </label>
                                                                </div>
                                                                <div className="upload-input">
                                                                    <UploadImageInput
                                                                        id="uploadPayment"
                                                                        setField="payment"
                                                                        onChange={this.handleInputChange}
                                                                    />
                                                                </div> 
                                                            </>
                                                            :''
                                                        }
                                                
                                                        {
                                                        this.state.payment !== '' ?
                                                            <div className="uploadPayment-img">
                                                                <img
                                                                    src={chekImgPayment}
                                                                    style={{ width: '200px', height: '200px'}}
                                                                    alt="news_preview"
                                                                    className="img-fluid image card-img"/>
                                                                    <label htmlFor="uploadPayment">
                                                                        <div className="middle">
                                                                            <div>
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + '/images/icons/addnote.png'}
                                                                                alt="addnote"/>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                    <div className="upload-input">
                                                                        <UploadImageInput
                                                                            id="uploadPayment"
                                                                            setField="payment"
                                                                            onChange={this.handleInputChange}
                                                                        />
                                                                    </div>
                                                            </div> :''
                                                        }
                                                        {this.state.isUploadQRCode ? <span className='text-red'> กรุณาแนบไฟล์ QR Code</span> : ''} 
                                                    </div>
                                                    </div>
                                                    <div className='pt-4'>
                                                            <Translation>
                                                                {
                                                                    t => <label>{t('settingBankAccount:Conditions')}</label> 
                                                                }
                                                            </Translation>
                                                        <textarea type="text" 
                                                            rows="3"
                                                            className="form-control form-control-border"
                                                            name="condition" onChange={this.handleInputChange} value={this.state.condition}
                                                        />
                                                    </div>
                                                    <div className='text-right pt-6'>
                                                        <button className="btn btn-add-slip mr-5" style={{fontSize:'15px',height:'45px'}}>
                                                            <Translation>
                                                                {
                                                                    t => <span>{t('settingWalking:Cancel')}</span> 
                                                                }
                                                            </Translation>
                                                        </button>
                                                        <button className="btn btn-primary add" onClick={this.submit}>
                                                            <Translation>
                                                                {
                                                                    t => <span>{t('settingWalking:Save')}</span> 
                                                                }
                                                            </Translation>
                                                        </button>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <Translation>
                                                        {
                                                            t =><h4 className='text-center'>{t('settingBankAccount:Display example')}</h4> 
                                                        }
                                                    </Translation>
                                                <div className="phone-preview">
                                                    <div className="inner">
                                                        <div>
                                                            <div className='text-center p-2'>
                                                            <img
                                                                src={this.state.projectLogo && this.state.projectLogo}
                                                                alt="logo"
                                                                className="logo" style={{width:'80px',height:'41px'}}
                                                            />
                                                            </div>
                                                            <p>{this.state.projectName && this.state.projectName}</p>
                                                            <p><span className='span-red'>xxxx</span> 
                                                            <Translation>
                                                                  {
                                                                     t =>   <span className='span-blue'>{t('Allaction:Time Over')}</span>
                                                                  }
                                                            </Translation>
                                                            </p>
                                                            <Translation>
                                                                  {
                                                                     t =>   <label>{t('vmsDashboard:Vehicle Registration No.')}</label>
                                                                  }
                                                            </Translation>
                                                            <Translation>
                                                                  {
                                                                     t =>   <label>{t('silvermanguard:Province')}</label>
                                                                  }
                                                            </Translation>
                                                            <div className='setting-webview-qr'>
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label>{t('vmsDashboard:Please scan the QR code to pay the parking fee for the project.')}</label>
                                                                        }
                                                                    </Translation>
                                                                <Translation>
                                                                        {
                                                                            t =>   <label>{t('paymentgateway:bank')}: {this.state.bankName ? this.state.bankName :'xxxxxxxxxxxx' } </label>
                                                                        }
                                                                </Translation>
                                                                <Translation>
                                                                        {
                                                                            t =>   <label>{t('cheque_transaction:Account No.')}: {this.state.bankNumber ? this.state.bankNumber :'xxxxxxxxxxxx' }</label>
                                                                        }
                                                                </Translation>
                                                                <Translation>
                                                                        {
                                                                            t =>   <label>{t('contact:Account name')}: {this.state.bankAccountName ? this.state.bankAccountName : 'xxxxxxxxxxxx'}</label>
                                                                        }
                                                                </Translation>
                                                               
                                                            </div>
                                                            <div className='setting-qr-code-center'>
                                                                {
                                                                this.state.payment === '' ? 
                                                                    <div className='setting-qr-code'>
                                                                        <div style={{paddingTop:'30px'}}>
                                                                            <span>รูป QR code</span>
                                                                        </div>
                                                                    </div> :""
                                                                }
                                                                {
                                                                    this.state.payment !== '' ?
                                                                    <div className='setting-qr-code' style={{border:0}}>
                                                                        <img src={chekImgPayment}
                                                                        style={{width:'123px', height:'123px'}}/>
                                                                    </div>:' '
                                                                }
                                                                {/* {
                                                                    this.state.payment !== ''?
                                                                    <div className='setting-qr-code' style={{border:0}}>
                                                                        <img src={chekImgPayment}
                                                                        style={{width:'123px', height:'123px'}}/>
                                                                    </div> :''
                                                                } */}
                                                            </div>
                                                            <div className='text-right pt-2'>
                                                                <button className="btn btn-add-slip">
                                                                    <img src={process.env.PUBLIC_URL + '/images/setting-walkin/download.png'} /> 
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label>{t('vmsDashboard:Save QR Code ')}</label>
                                                                        }
                                                                    </Translation>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='setting-webview-detail-time pt-4'> 
                                                            <div className='row'>
                                                            <div className='col font-10-size text-gray'> 
                                                                <Translation>
                                                                        {
                                                                            t =>   <label>{t('vmsDashboard:Parking Time')}</label>
                                                                        }
                                                                </Translation>
                                                                <label className="font-10-size" style={{color:'#1567B4'}}>00:00 AM</label>
                                                                <span>xx/xx/xxxx</span>
                                                            </div>
                                                            <div className='col'>
                                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconArrowRight.png'}
                                                                    style={{ height: '30px'}} />
                                                            </div>
                                                            <div className='col text-right font-10-size text-gray'> 
                                                                <Translation>
                                                                        {
                                                                            t =>   <label>{t('vmsDashboard:Exit time')}</label>
                                                                        }
                                                                </Translation>
                                                                <label className="font-10-size" style={{color:'#CA0824'}}>00:00 PM</label> 
                                                                <span>xx/xx/xxxx</span>
                                                            </div>
                                                            </div>
                                                            <div className='row pt-4'>
                                                                <div className='col-6'>
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label>{t('vmsDashboard:Total parking time')}</label>
                                                                        }
                                                                    </Translation>
                                                                </div>
                                                                
                                                                <div className='col text-right'>
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label>30 {t('newBookingSingha_Create_step2:Minutes')}</label>
                                                                        }
                                                                    </Translation>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label>{t('vmsDashboard:Free')}</label>
                                                                        }
                                                                    </Translation>
                                                                </div>
                                                                <div className='col'>
                                                                </div>
                                                                <div className='col text-right'>
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label>30 {t('newBookingSingha_Create_step2:Minutes')}</label>
                                                                        }
                                                                    </Translation>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label>{t('vmsDashboard:Over-parking time')}</label>
                                                                        }
                                                                    </Translation>
                                                                </div>
                                                                <div className='col'>
                                                                </div>
                                                                <div className='col text-right'>
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label>30 {t('newBookingSingha_Create_step2:Minutes')}</label>
                                                                        }
                                                                    </Translation>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label>{t('vmsDashboard:Parking Fee')}</label>
                                                                        }
                                                                    </Translation>
                                                                </div>
                                                                <div className='col text-right'>
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label>50 {t('silvermanguard:Baht/Hour')}</label>
                                                                        }
                                                                    </Translation>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <hr style={{marginTop:'0'}}/>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label style={{color:'#212830'}}><strong>{t('vmsDashboard:Total Fee')}</strong></label>
                                                                        }
                                                                    </Translation>
                                                                </div>
                                                                <div className='col text-right'>
                                                                    <Translation>
                                                                        {
                                                                            t =>   <label style={{color:'#212830'}}>00.00 {t('claim:bath')}</label>
                                                                        }
                                                                    </Translation>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <hr style={{marginTop:'0'}}/>
                                                            </div>
                                                            <div> 
                                                            <Translation>
                                                                {
                                                                    t => <label style={{color:'#212830'}}><strong>{t('settingBankAccount:Conditions')}</strong></label>
                                                                }
                                                            </Translation>
                                                                <div>
                                                                    {
                                                                        this.state.condition ? this.state.condition : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='pt-4'>
                                                                <button className="btn btn-add-slip" style={{width:'100%'}}> 
                                                                    <Translation>
                                                                        {
                                                                            t => <label>{t('settingBankAccount:Attach transfer slip')}</label>
                                                                        }
                                                                    </Translation>
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </form>
                                 </div>
                                </>
                                }

                            </Translation>
                        }

                        {/* {
                           this.state.loading == true && <Loading/>
                        } */}

                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default settingWebviewQR;
