import React from 'react'
import { Component } from 'react';
import { Translation } from 'react-i18next';
import ReactQuillAdapter from '../../libs/reactQuillAdapter';
import UploadImageInput from '../../libs/uploadImageInput';
import "react-quill/dist/quill.snow.css";
import "../../contact/styles/index.scss"
import _ from 'lodash'
import { Link } from 'react-router-dom';
import onUploadFile from '../../components/Action/onUploadFile';
class InputPost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerType: this.props.headerType, //string type
            type: this.props.type, //array type
            subject: this.props.post.subject ? this.props.subject : '',
            subjectEn: this.props.post.subjectEn ? this.props.subjectEn : '',
            previewImage: this.props.post.previewImage ? this.props.post.previewImage : '',
            language: this.props.language, //th or en
            keyTranslation: this.props.keyTranslation,
            status: this.props.post.status,
            description: this.props.post.description ? this.props.post.description : '',
            descriptionEn: this.props.post.description ? this.props.post.descriptionEn : '',
            post: this.props.post,
            uploadPreviewImage: '',
            upload_image_list: [],
            selectedType: this.props.post.selectedType ? this.props.post.selectedType : this.props.type[0],
            fileMore: this.props.fileMore
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.resetPreviewImage = this.resetPreviewImage.bind(this);
        this.switchLanguage = this.switchLanguage.bind(this)
        this.setInput = this.setInput.bind(this)
        this.callBackUploadFile = this.callBackUploadFile.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.dateFil = this.dateFile.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.post !== this.props.post) {
            this.setState({
                subject: this.props.post.subject,
                subjectEn: this.props.post.subjectEn,
                description: this.props.post.description,
                descriptionEn: this.props.post.descriptionEn,
                status: this.props.post.status,
                selectedType: this.props.post.type,
                previewImage: this.props.post.previewImage,
                uploadPreviewImage: this.props.uploadPreviewImage,
                fileMore: this.props.fileMore
            })
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        if (e.target.name === 'previewImage.upload_image') {
            this.setState({
                uploadPreviewImage: value
            }, () => this.props.callBack(this.setInput()))
        }
        if (e.target.name === 'selectedType') {
            this.setState({
                [event.target.name]: event.target.id
            }, () => this.props.callBack(this.setInput()))
        }
        else {
            this.setState((prevState) => {
                return _.set(_.cloneDeep(prevState), event.target.name, value);
            }, () => this.props.callBack(this.setInput()));
        }
    }

    resetPreviewImage() {
        this.setState({
            previewImage: '',
            uploadPreviewImage: ''
        }, () => this.props.callBack(this.setInput()))
    }

    async switchLanguage(language) {
        this.setState({ language }, () => this.props.callBack(this.setInput()));
        await localStorage.setItem("language", language);
    }

    setInput() {
        return {
            type: this.state.selectedType,
            subject: this.state.subject,
            subjectEn: this.state.subjectEn,
            previewImage: this.state.previewImage,
            description: this.state.description,
            descriptionEn: this.state.descriptionEn,
            uploadPreviewImage: this.state.uploadPreviewImage,
            fileMore: this.state.fileMore
        }
    }

    //uploadFileMore
    callBackUploadFile(data) {
        this.setState({ fileMore: data }, () => this.props.callBack(this.setInput()))
    }
    uploadFile(e) {
        let pathname = "regulation/data/files"
        onUploadFile(e, this.state.fileMore, pathname, this.callBackUploadFile);
    }

    dateFile(index, file) {
        let cloneData = _.cloneDeep(this.state.fileMore)
        cloneData.splice(index, 1)
        this.setState({ fileMore: cloneData },()=> this.props.updateFile(cloneData))
    }

    render() {
      
        return (
            <Translation>
                {t =>
                    <div id="news">
                        {this.props.switchLanguage &&
                            <ul className="switch-languages">
                                <li onClick={() => this.switchLanguage("th")} className={this.state.language === "th" ? "active" : "in-active"}>
                                    TH
                                </li>
                                <li onClick={() => this.switchLanguage("en")} className={this.state.language === "en" ? "active" : "in-active"}>
                                    EN
                                </li>
                            </ul>
                        }

                        <div className="card p-4">
                            <div className="row">
                                <div className="col">
                                    <h6>{t(this.state.keyTranslation + ":" + this.state.headerType)}</h6>
                                </div>
                            </div>
                            <div className="row mt-3">
                                {this.state.type.map((type, index) => {
                                    return (
                                        <div className="col-md-6" key={index}>
                                            <div className="custom-control custom-radio custom-control-inline" >
                                                <input
                                                    type="radio"
                                                    name="selectedType"
                                                    className="custom-control-input"
                                                    id={type.toLowerCase()}
                                                    onChange={this.handleInputChange}
                                                    disabled={this.state.status === 'cancel'}
                                                    checked={this.state.selectedType === type.toLowerCase()}
                                                />
                                                <label
                                                    className="custom-control-label cursor"
                                                    htmlFor={type.toLowerCase()}
                                                >
                                                    {t(this.state.keyTranslation + ":" + type)}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            {
                                this.state.language === 'th' &&
                                <div className="row mt-4">
                                    <div className="col">
                                        <h6> {t("press_release:topic")}<span style={{color:'red'}}>*</span></h6>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="subject"
                                            value={this.state.subject}
                                            onChange={this.handleInputChange}
                                            required
                                            disabled={this.state.status === 'cancel'}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.language === 'en' &&
                                <div className="row mt-4">
                                <div className="col">
                                    <h6> {t("press_release:topic_eng")} <span style={{color:'red'}}>*</span></h6>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="subjectEn"
                                        value={this.state.subjectEn}
                                        onChange={this.handleInputChange}
                                        required
                                        disabled={this.state.status === 'cancel'}
                                    />
                                </div>
                            </div>

                            }
                            <div className="row mt-4">
                                <div className="col">
                                    <h6>
                                        {t("press_release:title_image")}{" "}
                                        <span className="ml-2 color thin red-color">
                                            {t("press_release:title_image_description")}
                                        </span>
                                    </h6>
                                    {this.state.previewImage ? (
                                        <React.Fragment>
                                            <img
                                                src={this.state.previewImage}
                                                alt="previewImage"
                                                className="img-fluid card-img"
                                            />

                                            <div
                                                className="change-preview-image"
                                                onClick={this.resetPreviewImage}
                                            >
                                                <span>{t("press_release:change_image")}</span>
                                            </div>

                                            {this.state.status === 'draft' && (
                                                <div className="change-preview-image" onClick={this.resetPreviewImage}>
                                                    <span>{t("press_release:change_image")}</span>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <div className="btn card-wrapper border-wrapper">
                                            <label htmlFor="uploadPreviewImage">
                                                <div className="card-body">
                                                    <div className="col text-center">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/images/icons/select-photo.png"
                                                            }
                                                            alt="select-icon"
                                                        />
                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <div className="upload-file">
                                                            <span>{t("press_release:click_hear")}</span>
                                                            <div className="upload-input">
                                                                <UploadImageInput
                                                                    id="uploadPreviewImage"
                                                                    setField="previewImage.upload_image:previewImage"
                                                                    onChange={this.handleInputChange}
                                                                    required={true}
                                                                    disabled={this.state.status === 'cancel'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {
                                this.state.language === 'th' &&
                                <div className="row mt-4">
                                <div className="col ">
                                    <h6>{t("press_release:content")}<span style={{color:'red'}}>*</span></h6>
                                    <ReactQuillAdapter
                                        name="description"
                                        value={this.state?.description}
                                        onChange={this.handleInputChange}
                                        readOnly={this.state.status === 'cancel'}
                                    />
                                </div>
                            </div>
                            }
                            {
                              this.state.language === 'en' &&
                                <div className="row mt-4">
                                    <div className="col ">
                                        <h6>{t("press_release:content_eng")}<span style={{color:'red'}}>*</span></h6>
                                        <ReactQuillAdapter
                                            name="descriptionEn"
                                            value={this.state?.descriptionEn}
                                            onChange={this.handleInputChange}
                                            readOnly={this.state.status === 'cancel'}
                                        />
                                    </div>
                                </div>
                            }


                            {/* แนนไฟล์เพิ่มเติม */}
                            <div className="row mt-4">
                                <div className="col ">
                                    <h6> <span>{t("press_release:Attachment")}</span></h6>
                                    <div className="staff-create">
                                        <div className="uploadBTN" style={{ display: 'inline-flex' }}>
                                            <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                                <button className={"btn"}>
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />{t("silvermanguard:Choose File")}
                                                </button>
                                                <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>
                                                    {t("PageCreate:Support File Just .PDF Only and File Not Over 10 MB")}
                                                </span>

                                                <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder='ระบุเบอร์โทร' accept='application/pdf' onChange={this.uploadFile} />
                                            </label>
                                        </div>
                                    </div>

                                    {/* FileMore */}
                                    <div className="mt-2">
                                        {
                                            this.state.fileMore?.map((file, inx) => {
                                                let typeFile = file?.fileName?.split(".")
                                                let typeICONFile = typeFile?.length ? typeFile[typeFile.length - 1] : ""
                                
                                                return (

                                                    <p key={inx}>
                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeICONFile + "Icon.png"} style={{ width: '30px' }} /> &nbsp;
                                                        <Link
                                                            to="route"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                window.open(
                                                                    typeof file?.viewPtath === "string" || file?.fileUrl
                                                                        ? file.viewPtath || file.fileUrl
                                                                        : URL.createObjectURL(file?.viewPtath ? file?.viewPtath : file.fileUrl)
                                                                )
                                                            }}
                                                            target="blank">{file?.fileName}</Link>
                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file)}>X</label>
                                                    </p>

                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row mt-4"> 
                        <div className="col">
                          <h6>
                           {t("press_release:attach_more_pictures")}
                            <span className="ml-2 color thin red-color">
                             <span> {t("press_release:title_image_description")}</span>
                            </span>
                          </h6>

                          <div className="card-columns">
                            {this.state.uploadPreviewImage.map(
                              (news_image, index) => (
                                <RegulationImageRender
                                  key={index}
                                  src={news_image.node.source}
                                  onClickDelete={() =>
                                    !this.state.status &&
                                    this.deleteNewsImage(
                                      news_image.node.id,
                                      index
                                    )
                                  }
                                />
                              )
                            )}
                            {this.state.upload_image_list.map(
                              (news_image, index) => (
                                <RegulationImageRender
                                  key={index}
                                  src={URL.createObjectURL(news_image)}
                                  onClickDelete={() =>
                                    !this.state.status &&
                                    this.deleteNewsImage(false, index)
                                  }
                                />
                              )
                            )}
                            {!this.state.status && (
                              <div className="upload-file">
                                <div>
                                  <label
                                    htmlFor="uploadNewsImages"
                                    className="create news-button-image btn btn-light mt-2"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/icons/plus-icon.png"
                                      }
                                      alt="plus-icon"
                                      className="img-fluid plus news-image"
                                    />
                                  </label>
                                </div>
                                <div className="upload-input">
                                  <UploadImageInput
                                    id="uploadNewsImages"
                                    setField={
                                      "upload_image_list[" +
                                      this.state.upload_image_list.length +
                                      "]"
                                    }
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                         </div> */}
                        </div>
                    </div>
                }
            </Translation>
        );
    }



} export default InputPost;