/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateChequeDepositInput = {|
  chequeDeposit: any,
  clientMutationId?: ?string,
|};
export type createChequeDepositMutationVariables = {|
  input: CreateChequeDepositInput
|};
export type createChequeDepositMutationResponse = {|
  +createChequeDeposit: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type createChequeDepositMutation = {|
  variables: createChequeDepositMutationVariables,
  response: createChequeDepositMutationResponse,
|};
*/


/*
mutation createChequeDepositMutation(
  $input: CreateChequeDepositInput!
) {
  createChequeDeposit(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateChequeDepositPayload",
    "kind": "LinkedField",
    "name": "createChequeDeposit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createChequeDepositMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createChequeDepositMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e4101149104dc7f290e1d3499c7bcc82",
    "id": null,
    "metadata": {},
    "name": "createChequeDepositMutation",
    "operationKind": "mutation",
    "text": "mutation createChequeDepositMutation(\n  $input: CreateChequeDepositInput!\n) {\n  createChequeDeposit(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7587dd96e3cb38966509eaddfc28f568';

module.exports = node;
