/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type CreditNotePayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type CreditNoteStatus = "PAID" | "VOID" | "WAIT" | "%future added value";
export type CreditNoteTransactionStatus = "PAID" | "VOID" | "WAIT" | "%future added value";
export type CreditNoteVersion = "V1" | "V2" | "%future added value";
export type creditNoteListQueryVariables = {|
  status: string,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  first?: ?number,
  last?: ?number,
|};
export type creditNoteListQueryResponse = {|
  +creditNoteViewer: ?{|
    +allCreditNote: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +firstName: string,
          +lastName: string,
          +payGroup: CreditNotePayGroup,
          +contact: {|
            +id: string,
            +refNumber: string,
            +name: string,
            +firstName: string,
            +lastName: string,
            +typeOfContact: ContactTypeOfContact,
          |},
          +issuedDate: any,
          +price: number,
          +status: CreditNoteStatus,
          +description: ?string,
          +added: any,
          +updated: any,
          +voidRemark: ?string,
          +creator: ?string,
          +version: CreditNoteVersion,
          +creditNoteTransactionRecord: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +price: number,
                +status: CreditNoteTransactionStatus,
                +description: ?string,
              |}
            |}>
          |},
        |}
      |}>,
    |}
  |}
|};
export type creditNoteListQuery = {|
  variables: creditNoteListQueryVariables,
  response: creditNoteListQueryResponse,
|};
*/


/*
query creditNoteListQuery(
  $status: String!
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $first: Int
  $last: Int
) {
  creditNoteViewer {
    allCreditNote(status: $status, search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {
      totalCount
      edges {
        node {
          id
          docNumber
          firstName
          lastName
          payGroup
          contact {
            id
            refNumber
            name
            firstName
            lastName
            typeOfContact
          }
          issuedDate
          price
          status
          description
          added
          updated
          voidRemark
          creator
          version
          creditNoteTransactionRecord {
            edges {
              node {
                id
                price
                status
                description
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "end_date"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    },
    {
      "kind": "Variable",
      "name": "startDate",
      "variableName": "start_date"
    },
    {
      "kind": "Variable",
      "name": "status",
      "variableName": "status"
    }
  ],
  "concreteType": "CreditNoteNodeConnection",
  "kind": "LinkedField",
  "name": "allCreditNote",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CreditNoteNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreditNoteNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payGroup",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ContactNode",
              "kind": "LinkedField",
              "name": "contact",
              "plural": false,
              "selections": [
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "refNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "typeOfContact",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "issuedDate",
              "storageKey": null
            },
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "added",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "updated",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voidRemark",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creator",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "version",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CreditNoteTransactionNodeConnection",
              "kind": "LinkedField",
              "name": "creditNoteTransactionRecord",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CreditNoteTransactionNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CreditNoteTransactionNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v6/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "creditNoteListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CreditNoteViewer",
        "kind": "LinkedField",
        "name": "creditNoteViewer",
        "plural": false,
        "selections": [
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "creditNoteListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CreditNoteViewer",
        "kind": "LinkedField",
        "name": "creditNoteViewer",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d27eac1b01af9308a69861943d11d74",
    "id": null,
    "metadata": {},
    "name": "creditNoteListQuery",
    "operationKind": "query",
    "text": "query creditNoteListQuery(\n  $status: String!\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $first: Int\n  $last: Int\n) {\n  creditNoteViewer {\n    allCreditNote(status: $status, search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {\n      totalCount\n      edges {\n        node {\n          id\n          docNumber\n          firstName\n          lastName\n          payGroup\n          contact {\n            id\n            refNumber\n            name\n            firstName\n            lastName\n            typeOfContact\n          }\n          issuedDate\n          price\n          status\n          description\n          added\n          updated\n          voidRemark\n          creator\n          version\n          creditNoteTransactionRecord {\n            edges {\n              node {\n                id\n                price\n                status\n                description\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1eb7932babc3d2298b8d5e6eb1a88a95';

module.exports = node;
