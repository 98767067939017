/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConcernProcurementFormInput = {|
  procurementFormId: string,
  editRemark: string,
  clientMutationId?: ?string,
|};
export type concernManageRequestMutationVariables = {|
  input: ConcernProcurementFormInput
|};
export type concernManageRequestMutationResponse = {|
  +concernProcurementForm: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type concernManageRequestMutation = {|
  variables: concernManageRequestMutationVariables,
  response: concernManageRequestMutationResponse,
|};
*/


/*
mutation concernManageRequestMutation(
  $input: ConcernProcurementFormInput!
) {
  concernProcurementForm(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ConcernProcurementFormPayload",
    "kind": "LinkedField",
    "name": "concernProcurementForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "concernManageRequestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "concernManageRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "836ee81283df2dd53c4fe68b41996c20",
    "id": null,
    "metadata": {},
    "name": "concernManageRequestMutation",
    "operationKind": "mutation",
    "text": "mutation concernManageRequestMutation(\n  $input: ConcernProcurementFormInput!\n) {\n  concernProcurementForm(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ed8396e55400e077fd313b9060fc8e6';

module.exports = node;
