/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type contractExpenseTopMenuNavigationQueryVariables = {||};
export type contractExpenseTopMenuNavigationQueryResponse = {|
  +getNotificationContractExpense: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>
|};
export type contractExpenseTopMenuNavigationQuery = {|
  variables: contractExpenseTopMenuNavigationQueryVariables,
  response: contractExpenseTopMenuNavigationQueryResponse,
|};
*/


/*
query contractExpenseTopMenuNavigationQuery {
  getNotificationContractExpense {
    type
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NotificationType",
    "kind": "LinkedField",
    "name": "getNotificationContractExpense",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "contractExpenseTopMenuNavigationQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "contractExpenseTopMenuNavigationQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e2e7528e99b956234e45cab0a88ef52a",
    "id": null,
    "metadata": {},
    "name": "contractExpenseTopMenuNavigationQuery",
    "operationKind": "query",
    "text": "query contractExpenseTopMenuNavigationQuery {\n  getNotificationContractExpense {\n    type\n    count\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c024719255091a968c867f105df4bd2f';

module.exports = node;
