import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import ChequeDepositListTable from "./chequeDepositListTable";
import Navigation from "./navigation";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import _ from "lodash";
import ExportChequeDeposit from "./server_export_cheque_deposit";
import Search from "../../SearchInput";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";

const allChequeDeposit = graphql`
  query chequeDepositListAllQuery(
    $status: String
    $first: Int
    $last: Int
    $search: String
    $start_date: DateTime
    $end_date: DateTime
  ) {
    chequeDepositViewer {
      allChequeDeposit(
        status: $status
        first: $first
        last: $last
        search: $search
        startDate: $start_date
        endDate: $end_date
      ) {
        totalCount
        edges {
          node {
            id
            chequeNumber
            refNumber
            contact {
              name
              firstName
              lastName
              typeOfContact

            }
            unknownContact
            date
            price
            status
            description
            docNumber
            depositDate
          }
        }
      }
    }
  }
`;

class ChequeDepositListAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      temp_start_date: null,
      temp_end_date: null,
      start_date: null,
      end_date: null,
    };


    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onChangeSearch(text) {
    this.setState({
      search: text,
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
    });
  }


  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="invoice-list">
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("cheque:cheque")+ " "+"(QR)"}</span>}
                  </Translation>
                </h3>
              </div>
              <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "accounting_cheque_deposit_create",
                }) && (
                  <Link to="/accounting/finance/cheque-deposit/deposit/create">
                    <button
                      type="button"
                      className="btn btn-primary add float-right"
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                        alt="plus"
                      />
                      <Translation>
                        {(t) => <span>{t("cheque:add_cheque_deposit")}</span>}
                      </Translation>
                    </button>
                  </Link>
                )}
                {/* PRINT edit permission*/}
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "accounting_cheque_deposit_print",
                }) && (
                  <ExportChequeDeposit
                    status={this.props.match.params.status}
                    search={this.state.search}
                    start_date={this.state.start_date}
                    end_date={this.state.end_date}
                  />
                )}
              </div>
            </div>
            <div className="content-inner">
              <Navigation />
              <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                  <InlineAdvanceFilter
                    start_date={this.state.temp_start_date}
                    end_date={this.state.temp_end_date}
                    handleChange={this.handleChange}
                  />
                </div>
                <div className="col-3">
                  <Search
                    callback_function={this.onChangeSearch}
                    search={this.state.search}
                  />
                </div>
              </div>
              <div className="mt-2 mb-2">
                <ChequeDepositListTable
                  query={allChequeDeposit}
                  status={this.props.match.params.status}
                  search={this.state.search}
                  start_date={this.state.start_date}
                  end_date={this.state.end_date}
                />
              </div>
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ChequeDepositListAll;
