import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { Translation } from "react-i18next";
import {commitMutation} from "react-relay";

const mutation = graphql`
    mutation passwordValidityMutation ($input: PasswordValidityInput!) {
    passwordValidity (input: $input) {
        result
        reason
        messageTh
    }
}
`;

class PasswordValidation extends Component {

    constructor(props) {
        super(props);
        this.state = {
         passwordResult: null,
         passwordReason: '',
         messageTh:'',
        };  

        this.checkPassword = this.checkPassword.bind(this);
        this.queryCheckPassword = this.queryCheckPassword.bind(this);
    }

    checkPassword(event) {
        let custom_event = {
            target: {
                name: event.target.name,
                value: event.target.value,
                dataset: {passwordResult: this.state.passwordResult}
            }
        };

        this.props.handleInputChange(custom_event)
        this.queryCheckPassword(event.target.name, event.target.value) 
    }



    
    queryCheckPassword(name, value){
        let variables = {
            input: {
                username: this.props.username,
                password: value,
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    if(response){
                        let custom_event = {
                            target: {
                                name: this.props.name,
                                value: this.props.value,
                                dataset: {
                                    passwordResult: response.passwordValidity.result, 
                                    passwordReason: response.passwordValidity.reason,
                                    messageTh: response.passwordValidity.messageTh
                                },
                            }
                        };
        
                        this.setState({
                            passwordResult: response.passwordValidity.result,
                            passwordReason: response.passwordValidity.reason,
                            messageTh: response.passwordValidity.messageTh 
                        })

                        this.props.handleInputChange(custom_event)
                    }
                }
            },
        )
    }
    render() {
        
        return (
            <React.Fragment>
                <input type="password" id={`${this.props.id}`}
                       className={"form-control h48 " + (this.props.value.length === 0 && this.state.passwordResult === null ? " " :
                       this.props.value.length > 0 && !this.state.passwordResult ? 'is-invalid' : "is-valid")}
                       name={this.props.name}
                       value={this.props.value}
                       onChange={this.checkPassword}
                       placeholder={this.props.placeholder? this.props.placeholder :''}
                       />
                <Translation>
                    { t =>
                    <div className="invalid-feedback">
                        {this.props.langauge ?  
                          <h6><small> {this.props.langauge === 'th' ? this.state.messageTh : this.state.passwordReason}</small></h6> :
                          <h6><small>{t(`premission:${this.state.passwordReason}`)}</small></h6>
                        }
                    </div>
                  }
                </Translation> 
            </React.Fragment>
        );
    }
    
}

export default PasswordValidation;