// import '../../report.scss';
import React from 'react';
import _ from "lodash";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Dropdown } from "react-bootstrap";
import numberWithCommaReport from '../../libs/numberWithCommaReport'
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const ExportPetExcel = ({ state }) => {
    const getData = () => downloadExcel()
    const downloadExcel = async (data) => {
        let workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet('total receipt');
        let columns = [{ width: 10 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]
        // setBorder
        let borders = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        // let fontSizes = { size: 11.5 }
        let textCenter = { vertical: 'middle', horizontal: 'center' };
        let textRight = { vertical: 'middle', horizontal: 'right' };
        let textLeft = { vertical: 'middle', horizontal: 'left' };
        let fontBold = { size: 11.5, bold: true };

        //textHeader 
        worksheet.getCell('A1').value = state.project_name
        worksheet.getCell('A1').font = { size: 11.5, bold: true };
        worksheet.getCell('A2').value = "สรุปรายการสัตว์เลี้ยง"
        worksheet.getCell('A2').font = { size: 11.5, bold: true };
        worksheet.getCell('A3').value = `${format(new Date(), " DD MMMM YYYY", { locale: thLocale, })}`
        worksheet.getCell('A3').font = { size: 11.5, bold: true };

        // TABLE
        let headerRow, cell, last_index = 0

        // HEADER 
        headerRow = worksheet.getRow(5);
        const headerName = ['ลำดับ', 'บ้านเลขที่/ห้องเลขที่', 'ประเภทสัตว์เลี้ยง', 'ลักษณะ', 'รูปภาพ', 'ใบตรวจสุขภาพประจำปี']
        _.map(headerName, (keyName, index) => {
            cell = headerRow.getCell(index + 1);
            cell.value = keyName;
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textCenter
        })

        let startItem = 1

        //BODY
        _.map(state.dataExport, (item, index_code) => {
            last_index++
            startItem += index_code
            _.map(item.node.pet.edges, (pet, indexPet) => {

                var dataRow = worksheet.addRow();
                cell = dataRow.getCell(1);
                cell.value = indexPet === 0 ? last_index : ""
                cell.alignment = textCenter

                cell = dataRow.getCell(2);
                cell.value = indexPet === 0 ? item.node.name : ""
                cell.alignment = textCenter

                cell = dataRow.getCell(3);
                cell.value = pet.node.petType
                cell.alignment = textCenter

                cell = dataRow.getCell(4);
                cell.value = pet.node.description
                cell.alignment = textCenter
                cell.wrapText = true;

                cell = dataRow.getCell(5);
                cell.value = pet.node.imagePet ? "มี" : "ไม่มี";
                cell.alignment = textCenter

                cell = dataRow.getCell(6);
                cell.value = pet.node.healthCheckName ? "มี" : "ไม่มี";
                cell.alignment = textCenter
            })
        })

        worksheet.columns = columns;

        workbook.xlsx.writeBuffer()
            .then((data) => {
                const blob = new Blob([data], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                let nameFile = 'Pets Report'
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = nameFile;
                a.click();
            });
    }
    return (
        <Dropdown.Item  ><div className="text-black" onClick={getData} style={{ display: 'flex', justifyItems: 'center' }} >Excel</div></Dropdown.Item>
    );
};

export default ExportPetExcel;