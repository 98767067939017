import React, {Component, useEffect, useState,useCallback} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PaymentIcon from '@mui/icons-material/Payment';
import { styled } from '@mui/material/styles';
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import Loading from '../../libs/loading';
import i18next from 'i18next';
import getqueryGetCollectionLawFirmLog from './query/queryGetCollectionLawFirmLog'

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

const query = graphql`
       query collectionLawFrimNumberDocsTableQuery($startDate:DateTime,$endDate:DateTime,$first:Int,$last:Int){
        allCollectionLawFirm(startDate:$startDate, endDate:$endDate, first:$first, last:$last,log:true) {
            totalCount
            edges{
              node{
                id
                docNumber
                issuedDate
                added
                status
                dueDate
                contact{
                    id
                    refNumber
                    name
                }
                log
                site{
                    id
                    domain
                    project{
                        edges{
                            node{
                                id
                                nameTh
                                nameEn
                                typeOfProject
                                logo
                                added
                            }
                        }
                    }
                }
            }
        } 
    }
       }
`;


function Row(props) {
  const {row,secoundLogs} = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{fontFamily:'medium'}}>
        <TableCell component="th" scope="row">
          {row.node.docNumber}
        </TableCell>
        <TableCell align="left">{row.node.site.project.edges[0]?.node.nameEn}</TableCell>
        <TableCell align="center">{row.node.log}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => { setOpen(!open); props.fetchData2(row.node.id);}}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
            <Table size="small" className='slip-dark-gray'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0' }}>action_time</TableCell>
                    <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0' }}>user</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                  secoundLogs?.map((itemLogs, indexLogs) => {
                      return (
                        <React.Fragment key={`index_item_log ${indexLogs}`}>
                          <TableRow>
                            <TableCell style={{ paddingBottom: "16px" }}>{`${itemLogs.action_time}`}</TableCell>
                            <TableCell style={{ paddingBottom: "16px" }}>{`${itemLogs.user.name} (${itemLogs.user.username}) `}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     invoiceId: PropTypes.string.isRequired,
//     qrcodeData: PropTypes.string.isRequired,
//     resultCompare: PropTypes.string.isRequired,
//     resultCode: PropTypes.string.isRequired,
//     resultText: PropTypes.string.isRequired,
//     slipVerifyLogsStatusCode: PropTypes.string.isRequired,
//     slipVerifyLogsStatusMessage: PropTypes.string.isRequired,
//     amount: PropTypes.shape({
//       currentPaid: PropTypes.number.isRequired,
//       transactionTotal: PropTypes.number.isRequired,
//       amount_status: PropTypes.bool.isRequired,
//     }),
//     date: PropTypes.shape({
//       slipDate: PropTypes.string.isRequired,
//       invoiceDate: PropTypes.string.isRequired,
//       appDate: PropTypes.string.isRequired,
//       date_status: PropTypes.bool.isRequired,
//     }),
//     bank: PropTypes.shape({
//       bankName: PropTypes.string.isRequired,
//       bankName_svm: PropTypes.string.isRequired,
//       bankName_status: PropTypes.bool.isRequired,
//       accountName: PropTypes.string.isRequired,
//       accountName_svm: PropTypes.string.isRequired,
//       accountName_status: PropTypes.bool.isRequired,
//       accountNumber: PropTypes.string.isRequired,
//       accountNumber_svm: PropTypes.string.isRequired,
//       accountNumber_status: PropTypes.bool.isRequired
//     }),
//     slipVerifyLogResVerify: PropTypes.shape({
//       amount: PropTypes.number,
//       countryCode: PropTypes.string,
//       language: PropTypes.string,
//       paidLocalAmount: PropTypes.number,
//       paidLocalCurrency: PropTypes.string,
//       receiver: PropTypes.shape({
//         account: PropTypes.shape({
//           type: PropTypes.string,
//           value: PropTypes.string,
//         }),
//         displayName: PropTypes.string,
//         name: PropTypes.string,
//         proxy: PropTypes.shape({
//           type: PropTypes.string,
//           value: PropTypes.string,
//         }),
//       }),
//       receivingBank: PropTypes.string,
//       ref1: PropTypes.string,
//       ref2: PropTypes.string,
//       ref3: PropTypes.string,
//       sender: PropTypes.shape({
//         account: PropTypes.shape({
//           type: PropTypes.string,
//           value: PropTypes.string,
//         }),
//         displayName: PropTypes.string,
//         name: PropTypes.string,
//         proxy: PropTypes.shape({
//           type: PropTypes.string,
//           value: PropTypes.string,
//         }),
//       }),
//       sendingBank: PropTypes.string,
//       toMerchantId: PropTypes.string,
//       transDate: PropTypes.string,
//       transFeeAmount: PropTypes.number,
//       transRef: PropTypes.string,
//       transTime: PropTypes.string,
//     }),
//   }).isRequired,
// };

export default function CollectionLawFrimNumberDocsTable() {
  const [logs, setLogs] = useState([])
  const [secoundLogs, setsecoundLogs] = useState([])
  const [countLog, setCountLog] = useState()
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    fetchQuery(environment,query,{
      startDate:null, endDate:null, first:null, last:null,log:true
    }).then(response => { 
        setCountLog(response.allCollectionLawFirm?.totalCount);
        setLogs(response.allCollectionLawFirm?.edges);
        setLoading(false);
     })
  }


  const fetchData2 = async (id) => {
     await fetchQuery(environment,getqueryGetCollectionLawFirmLog(),{
        id:id
      }).then(response => { 
          let data = JSON.parse(response.getCollectionLawFirmLog)
          setsecoundLogs(data.logs)
       })
  }


  useEffect(() => {
     fetchData()
    .catch(console.error);
  }, [])

  return (

    <TableContainer component={Paper}>
      { !loading ?
        <Table aria-label="collapsible table">
          <caption>{i18next.t("slipVerificationList:Number of Data Found")} {countLog}</caption>
          <TableHead>
            <TableRow>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef'}}> CLF </TableCell>
              <TableCell style={{width: '20%',fontFamily:'medium',backgroundColor:'#e9ecef'}} align="center">Site</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef'}} align="center">Number Log</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',width: '20%'}}/>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((row) => (
              <Row key={row.node.id} row={row} fetchData2={fetchData2} secoundLogs={secoundLogs} />
            ))}
          </TableBody>
        </Table>
        :<Loading/>
      }
      
    </TableContainer>

  );
}
