import React, { Component } from 'react';
import Swal from "sweetalert2";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Navigation from './navigationCommon'
import ReactPaginate from "react-paginate";
import API from '../../../api/sing'
import _ from 'lodash';
import { format } from "date-fns";
import Select from 'react-select';
import ExportfixRequestExcelReport from '../../../document/fix_request/fixRequestExceReport';
import i18next from 'i18next';

// let search = ''
// let startDate = ''
// let endDate = ''
class FixrequestReportCommon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workingTypeAll: [],
            data: [],
            allRow: 4,
            LimitStRow: 1,
            LimitEnRow: 4,
            index_first: 0,
            index_last: 10,
            start_date: "",
            end_date: "",
            search: "",
            working_type: "",


            flg: "",
            flgTime: "",
            flgService: "",
            status: "",
            textSearch: "",
            num_record: 0,
            loading: true,
            first: "",
            last: 10,
            pageSize: 10,
            page: 0,
            pagesCount: 0,
        }
        this.SearchChange = this.SearchChange.bind(this)
        this.onSreach = this.onSreach.bind(this)
        this.onSelectWorkingType = this.onSelectWorkingType.bind(this)
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
    }
    componentDidMount() {
        this.onSreach()
    }
    // เปลี่ยนแท็บและอัพเดทข้อมูลใหม่
    componentDidUpdate(prevProps) {
        let sta = this.props.match.params.tabName
        if (prevProps.match.params.tabName !== sta) {
            this.setState({ loading: true });
            this.getData(this.state.index_first, this.state.index_last);
        }
    }


    async getData(index_first, index_last) {
        let filter = localStorage.getItem("filterReportFixRequest") ? JSON.parse(localStorage.getItem("filterReportFixRequest")) : []
        let data = {
            index_first: index_first,
            index_last: index_last,
            pageSize: this.state.pageSize,
            page: this.state.page,

            working_type: this.state.working_type,
            search: filter?.searchBox || this.state.search,
            start_date: filter?.searchStartDate || this.state.start_date,
            end_date: filter?.searchEndDate || this.state.end_date !== "" ? this.state.end_date + "T23:59" : '',

            insure_status_choices:
                this.props.match.params.tabName === "all" ? "" :
                    this.props.match.params.tabName === "warranty" ? "have" :
                        this.props.match.params.tabName === "expired" ? "out" : "not",

            repair_type: "public",

        }
        await API.getReport(data).then((res) => {
            if (res.data.data) {
                API.get_task_type_service(data.repair_type).then((result) => {
                    if (result.data.all_task_type) {
                        let dataWorkingType = []
                        let langs = localStorage.getItem("language")

                        result.data.all_task_type.forEach((n, index) => {
                            _.set(dataWorkingType, '[' + index + '].value', n.task_type_id);
                            _.set(dataWorkingType, '[' + index + '].label', langs === "th" ? n.task_type_name_th : n.task_type_name);
                            _.set(dataWorkingType, '[' + index + '].th', n.task_type_name_th);
                        })
                        this.setState({
                            workingTypeAll: dataWorkingType,
                            data: res.data.data,
                            allRow: res.data.totalCount,
                            index_last: res.data.pagesSize,
                            pagesCount: res.data.pagesCount,
                            loading: false,

                        })
                        document.body.style.cursor = "auto";
                    }
                }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'), () => {
                    window.location.reload()
                    this.setState({ loading: false })
                })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'), () => {
            window.location.reload()
            this.setState({ loading: false })
        })
    }

    onError(imgData) {
        if (imgData !== "" && imgData !== null) {
            this.setState({
                imgLoad: imgData
            });
        } else {
            this.setState({
                imgLoad: "/images/icons/select-photo.png"
                // imgLoad: "/images/carUploadRgis/20210101121155.png"
            });
        }

    }

    handlePageClick = (event) => {
        let page = event.selected + 1;

        let index_f = event.selected * this.state.pageSize
        let index_l = page * this.state.pageSize
        document.body.style.cursor = "wait";
        this.setState({ page: page })
        this.getData(index_f, index_l);
    };
    handlePageSizeChange(e) {
        let pageSizes = e.target.value
        this.setState({ pageSize: parseInt(pageSizes), index_last: pageSizes }, () => {
            if (parseInt(pageSizes) === this.state.pageSize) {
                document.body.style.cursor = "wait";
                this.getData(0, pageSizes);
            }
        })

    }


    onError(imgData, action) {
        if (action) {
            this.setState({
                imgLoad: "/images/icons/select-photo.png"
            });
        } else {
            this.setState({ imgLoad: imgData })
        }
    }

    onSelectWorkingType(e) {
        this.setState({ working_type: e.th })
    }

    async onSreach() {
        let getSearchFilter = JSON.parse(localStorage.getItem("filterReportFixRequest"))
        let setFilter = _.cloneDeep(getSearchFilter)

        let searchForReportCommon = {
            searchTypePerson: "",
            searchBox: this.state.search,
            searchStartDate: this.state.start_date,
            searchEndDate: this.state.end_date,
            searchWorkingType: this.state.working_type
        }
        let setData =
        {
            "searchForTenent": setFilter?.searchForTenent || null,
            "searchForCommon": setFilter?.searchForCommon || null,
            "searchForReportTenent": setFilter?.searchForReportTenent || null,
            "searchForReportCommon": searchForReportCommon,
        }

        await localStorage.setItem('filterReportFixRequest', JSON.stringify(setData))
        await this.getData(this.state.index_first, this.state.index_last)

    }
    SearchChange(e) {

        if (e.target.name === "search") {
            this.setState({ [e.target.name]: e.target.value })
        } else {
            this.setState({ [e.target.name]: format(e.target.value, "YYYY-MM-DD") })
        }


        if (e.key === "Enter") {
            // this.getData(this.state.index_first, this.state.index_last)
            this.onSreach()
        }
    }


    render() {
        let forPage = this.props.match.params.for
        let sta = this.props.match.params.tabName
        const { data, num_record, loading, page, pages, last, startDate, endDate } =
            this.state;
        let items = []
        let lists = []
        let pageA = 0
        items = this.state.data

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}> 
                <Translation>{t =>
                    <div className="container-fluid box fixRequestFast" id="reportGraund">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/contact">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <span>{t("FixRequestReport:Fix Request Common Report")}</span>
                                </h3>
                            </div>
                        </div>
                        {
                             _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'repairs_room_print' }) && 
                        <div className="dropdown printDoc">
                            <button type="button" className="btn-primary-scale-outline floatRight dropdown-toggle" data-toggle="dropdown">
                                {t("FixRequestReport:Print")}
                            </button>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" target={"_blank"} href={"/contact/fix-requests/document/reportcommon/" + this.props.match.params.tabName}>PDF</a>
                                <ExportfixRequestExcelReport status={this.props.match.params.tabName} data={items} for={"common"}
                                    startDate={this.state.start_date} endDate={this.state.end_date} />
                            </div>
                        </div>
                        }
                        <Navigation forPage={forPage} />

                        {
                            this.state.loading == false &&
                            <React.Fragment>

                                {/* FILTER AND SEARCH */}
                                <div className='row filterSearch'>
                                    <div className='col-4'>
                                        <label className='floatLeft labelInline'>{t("FixRequestReport:Choose")} :</label>
                                        <div style={{ position: 'relative', textAlign: 'left' }}>
                                            <Select
                                                classNamePrefix='inputBoxList'
                                                options={this.state.workingTypeAll}
                                                onChange={this.onSelectWorkingType}
                                                required={true}
                                                isSearchable={false}
                                                placeholder={t("FixRequestReport:Choose")}
                                            />
                                            <input name="searchWorkingType" type="text" value={this.state.details}
                                                style={{ position: "absolute", top: 0, left: 110, width: 5, zIndex: -1 }}
                                                onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                required={true} />
                                        </div>

                                    </div>

                                    <div className='col-8 text-right' style={{ display: 'inline' }}>
                                        <div className='floatRight'>
                                            <input type="text" name='search' className='form-control searchBox' placeholder={t("FixRequestReport:Search")}
                                                onChange={this.SearchChange} onKeyUp={this.SearchChange} defaultValue={this.state.search} />
                                            <span className='spanINBox' onClick={this.onSreach}><img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} /></span>
                                        </div>
                                        <div className='floatRight'>
                                            <label className='floatLeft labelInline'>{t("FixRequestReport:Date")}:</label>
                                            <input type="date" name='start_date' className='floatLeft form-control searchDate' defaultValue={this.state.start_date} placeholder='01/07/2021' onChange={this.SearchChange} />

                                            <label className='floatLeft labelInline'>{t("FixRequestReport:to")}</label>
                                            <input type="date" name='end_date' className='floatLeft form-control searchDate' defaultValue={this.state.end_date} placeholder='01/07/2021' onChange={this.SearchChange} />

                                        </div>

                                    </div>
                                </div>

                                <div className="table-responsive fade-up card" style={{ marginTop: '24px' }}>
                                    <table className="table table-hover">
                                        <thead className="thead-light">
                                            <tr className='text-center'>
                                                <th>{t("FixRequestReport:Document No.")}</th>
                                                <th>{t("FixRequestReport:Issue date")}</th>
                                                <th>{t("FixRequestReport:Category")}</th>
                                                <th>{t("FixRequestReport:Work type")}</th>
                                                <th>{t("FixRequestReport:Room No.")}</th>
                                                <th>{t("FixRequestReport:From")}</th>
                                                <th>{t("FixRequestReport:Technician / Contractor name")}</th>
                                                <th>{t("FixRequestReport:Insurance expiration date")}</th>
                                                <th>{t("FixRequestReport:Status")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                items.map((n, index) => {
                                                    let isStaff = n.tenant_contact?.substring(0, 2)
                                                    isStaff = isStaff === "Y-" ? true : false
                                                    return (
                                                        <tr className='text-center' key={this.props.match.params.tabName + index}>
                                                            <td><Link to={"/contact/fix-requests/forcommon/reportdetail/" + n.repairsRoomId}>{n.doc_number}</Link></td>
                                                            <td>{format(n.added, "DD/MM/YYYY")}</td>
                                                            <td>{n.working_type}</td>
                                                            <td>{n.material}</td>
                                                            <td>{n.room_number}</td>
                                                            <td>{
                                                                n.room_number === "นิติบุคคล" && isStaff ?
                                                                    n.juristic_contact : n.tenant_contact
                                                            }</td>
                                                            <td>{
                                                                n.job_responsed_by_staff_name ? n.job_responsed_by_staff_name :
                                                                    n.engineer_contact ? n.engineer_contact : '-'}</td>
                                                            <td>{n.insure_status !== "not" ? format(n.due_date, "DD/MM/YYYY") : "-"}</td>
                                                            <td>
                                                                {
                                                                    n.status === "finish" ?
                                                                        <label className='badge badge-success'>{t("FixRequestReport:Finish")}</label> :
                                                                        <label className='badge badge-danger'>{t("Allaction:cancel")}</label>
                                                                }

                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                            }

                                            {
                                                items.length === 0 &&
                                                <tr className='text-center'>
                                                    <td colSpan="11">{t("FixRequestReport:Not Found")}</td>
                                                </tr>
                                            }



                                        </tbody>
                                    </table>
                                </div>

                                <div className='row'>
                                    <div className='col-6'>
                                        <select className='page' onChange={this.handlePageSizeChange}>
                                            <option value="10">{t("chatGroup:Show")} 10 {t("chatGroup:List / page")}</option>
                                            <option value="20">{t("chatGroup:Show")} 20 {t("chatGroup:List / page")}</option>
                                            <option value="50">{t("chatGroup:Show")} 50 {t("chatGroup:List / page")}</option>
                                            <option value="100">{t("chatGroup:Show")}{t("chatGroup:Show")} 100 {t("chatGroup:List / page")}</option>
                                        </select>
                                    </div>
                                    <div className='col-6 text-right'>
                                        <div className='pullright' style={{ marginTop: '22px' }}>{t("chatGroup:List All")} {this.state.allRow} &nbsp;

                                            {this.state.pagesCount > 1 && (
                                                <ReactPaginate style={{ marginTop: '-7px' }}
                                                    previousLabel={"previous"}
                                                    nextLabel={"next"}
                                                    breakLabel={
                                                        <span className="btn page-link">...</span>
                                                    }
                                                    breakClassName={"break-me"}
                                                    pageClassName="page-item"
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={4}
                                                    previousClassName="sr-only"
                                                    containerClassName={"pagination float-right"}
                                                    subContainerClassName={"pagination-page"}
                                                    nextLinkClassName="sr-only"
                                                    pageLinkClassName="page-link"
                                                    activeClassName={"active"}
                                                    pageCount={this.state.pagesCount}
                                                    onPageChange={this.handlePageClick}
                                                />
                                            )}

                                        </div>

                                    </div>
                                </div>
                            </React.Fragment>

                        }



                        {
                            this.state.loading == true &&
                            <div className='row'>
                                <div className='col-sm-8 '>
                                    LOADING...
                                </div>

                            </div>
                        }

                    </div>

                }



                </Translation></WrapperContent>
            </Wrapper>
        )
    }
}

export default FixrequestReportCommon;