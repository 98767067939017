import React from 'react';
import numberWithComma from "../../libs/numberWithComma";
import {format} from "date-fns";
import _ from 'lodash';
import "../report/asset/register/styles/report.scss";
import './styles/asset.scss'
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import i18next from 'i18next';

const asset_type = [
    {
        code: "land",
        header_name: "LAN - ที่ดิน",
        header_nameEn: "LAN - Land"
    },
    {
        code: "building",
        header_name: "B - อาคาร",
        header_nameEn: "B - Building"
    },
    {
        code: "building_renovation",
        header_name: "BR - ส่วนต่อเติมและปรับปรุงอาคาร",
        header_nameEn: "BR - Building additions and improvements"
    },
    {
        code: "office_decoration",
        header_name: "OD - เครื่องตกแต่งสำนักงาน",
        header_nameEn: "OD - Office decorations"
    },
    {
        code: "office_equipment",
        header_name: "EQ - อุปกรณ์และเครื่องใช้สำนักงาน",
        header_nameEn: "EQ - Office equipment and supplies"
    },
    {
        code: "tools",
        header_name: "T - เครื่องมือ/อุปกรณ์งานช่าง",
        header_nameEn: "T - Tools / Mechanical equipment"
    },
    {
        code: "training_equipment",
        header_name: "TE - เครื่องออกกำลังกาย/สันทนาการ",
        header_nameEn: "TE - Exercise / Recreational equipment"
    },
    {
        code: "vehicle",
        header_name: "V - ยานพาหนะ",
        header_nameEn: "V - Vehicle"
    },
    {
        code: "common_property",
        header_name: "CP - ทรัพย์สินส่วนกลาง",
        header_nameEn: "CP - Common property"
    },
    {
        code: "other_asset",
        header_name: "OA - สินทรัพย์อื่นๆ",
        header_nameEn: "OA - Other assets"
    },
    {
        code: "intangible_asset",
        header_name: "ITG - สินทรัพย์ไม่มีตัวตน",
        header_nameEn: "ITG - Intangible assets"
    },
    
];


class AssetListTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkList: [], 
            check_all: false, 
            checkRecord:[],
            checkRefTrans: [], 
            checkRefNumber:[],
            allForQr: "",
        };
    }

    onClickGetData(data, asset_type, docNumber, id ) {
        this.props.history.push(`/accounting/asset/${asset_type}/form/${docNumber}/${id}?`,{ data: data})
    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        if (this.state.status !== this.props.status) {
          this.queryCountInvoiceWaitFix();
          this.setState(
            {
              status: this.props.status,
              checkList: [],
              check_all: false,
            }
          );
        }
    
        if (this.props.updateInvoice === true) {
            this.setState(
              { updateInvoice: this.props.updateInvoice },
              () => {
                this.props.updateInvoiceFunction()
            }
            );
        }
    
        if (this.state.checkList !== nextState.checkList) {
            // Invoke the callback function and pass the updated checkList state
            this.props.onCheckListUpdate(this.state.checkList);
          }
      }

    getList(props) {
        let checklist = [...this.state.checkList];
        let current_checkListdoc = null
        if(this.state.checkListdoc)current_checkListdoc = [...this.state.checkListdoc];

        let check_all = false;

        if (!this.state.check_all) check_all = true;

        if ((check_all && (checklist.length === props.length)) || !check_all ) {
            checklist = [];
            if(this.state.checkListdoc)current_checkListdoc = [];
        } else {
            if (checklist.length !== 0) {
                checklist = [];
                current_checkListdoc = [];
            }
            props.forEach((item) => {
                checklist.push(item.node.docNumber);
                if(this.state.checkListdoc)current_checkListdoc.push((item.node.docNumber?item.node.docNumber:'null'));
            });
        }
        if(this.state.checkListdoc) {
            this.setState({checkList: checklist, check_all: check_all, checkListdoc:current_checkListdoc})
            this.props.onCheckListUpdate(this.state.checkList);
        }
        else {
            this.setState({checkList: checklist, check_all: check_all}) 
            this.props.onCheckListUpdate(this.state.checkList)
        }
             
    }

    appendToCheckList(id,doc=null) {
        //id
        let current_checklist = [...this.state.checkList];
        if (current_checklist.includes(id)) {
            //out
            current_checklist.splice(current_checklist.indexOf(id), 1)
        } else {
            //in
            current_checklist.push(id);
        }
        //doc rt
        if(doc !== null){
            let current_checkListdoc = [...this.state.checkListdoc];
            if (current_checkListdoc.includes(doc)) {
                current_checkListdoc.splice(current_checkListdoc.indexOf(doc), 1)
            } else {
                current_checkListdoc.push(doc);
            }
            this.setState({checkListdoc: current_checkListdoc})
        }
        this.setState({checkList: current_checklist})
    }

    checkList(id) {
        return this.state.checkList.includes(id);
    } 
    
    render() {
        // let print_header = false;
        // let old_type = ''
        let order_id = 0;
        let assetEachType = _.chain(this.props.props.allAsset.edges).groupBy('node.type').map((asset,index)=> {
            let total_sum_asset_depreciate = 0.0; 
            //คำนวณค่าเสื่อม
            asset.map((asset_depreciate_list) => {
                total_sum_asset_depreciate += asset_depreciate_list.node.historicalAmount; //ราคารวมค่าเสื่อมสะสม + ค่าเสื่อมยกมา
                total_sum_asset_depreciate += _.sumBy(asset_depreciate_list.node.assetDepreciate.edges,'node.depreciatePrice')

            })
            return ({  
                total_costPrice : _.sumBy(asset,'node.costPrice'), //สินทรัพย์
                total_assetDepreciate : total_sum_asset_depreciate, //ค่าเสื่อมสะสม
                total_assetAfterDepreciate : _.sumBy(asset,'node.costPrice') - total_sum_asset_depreciate, //สินทรัพย์หลังหักค่าเสื่อมสะสม
                asset : asset,
                type : _.sumBy(asset,'node.type'),
            })
            }).value();
                                        //หาราคารวมของแต่ละชนิด

                                        let asset = {
                                            //ราคารวมทั้งหมด
                                            sum_costPrice : _.sumBy(assetEachType,'total_costPrice') , //สินทรัพย์
                                            sum_assetDepreciate : _.sumBy(assetEachType,'total_assetDepreciate'), //ค่าเสื่อม
                                            sum_assetAfterDepreciate : _.sumBy(assetEachType,'total_assetAfterDepreciate'), //สินทรัพย์หลังหักค่าเสื่อมสะสม
                                            assetEachType : assetEachType,
                                            count_list : assetEachType.length
                                        }            
        return (
            <Translation>
                {
                    t => 
                    <React.Fragment>
                    <div className="row mt-3" id="asset-style">
                        <div className="col">
                                            <React.Fragment>
                                                <div className="table-responsive fade-up">
                                                    <table className="table table-hover">
                                                        <thead className="thead-light">
                                                        <tr>                         
                                                            <th className="text-center head">
                                                                <input type="checkbox"
                                                                    checked={this.state.check_all}
                                                                    onChange={() => this.getList(this.props.props.allAsset.edges)}
                                                                />
                                                            </th>
                                                            <th className="text-center head">{i18next.t("assetTable:No.")}</th>
                                                            <th className="text-center head">{i18next.t("assetTable:Asset code")}</th>
                                                            <th className="text-center head">{i18next.t("assetTable:Internal asset code")}</th>
                                                            <th className="text-center head">{i18next.t("assetTable:Asset name")}</th>
                                                            <th className="text-center head">{i18next.t("assetTable:Description")}</th>
                                                            {/* <th className="text-center head">แหล่งที่จัดเก็บ</th> */}
                                                            <th className="text-center head">{i18next.t("assetTable:Vendor")}</th>
                                                            {this.props.asset_type !== 'land' &&
                                                            <React.Fragment>
                                                                <th className="text-center head">{i18next.t("assetTable:Depreciation rate (%)")}</th>
                                                                <th className="text-center head">{i18next.t("assetTable:Purchase date")}</th>
                                                                <th className="text-center head">{i18next.t("assetTable:Asset value")}</th>
                                                                <th className="text-center head">{i18next.t("assetTable:Total depreciation")}</th>
                                                                <th className="text-right head" width={200} >{i18next.t("assetTable:Asset value after deducting total accumulated depreciation as of date")} {format(this.props.last_date,'DD/MM/YYYY')}</th>
                                                                {/*<th className="text-right">มูลค่าตามบัญชีรวม</th>*/}
                                                            </React.Fragment>
                                                            }
                                                            {/* <th className="text-center head">หมายเหตุ</th> */}
                                                            <th className="text-center head">{i18next.t("assetTable:Status")}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            {asset.assetEachType.map((asset_each_type,index_asset_type) => {
                                                                order_id = 0;
                                                                return (
                                                                    <React.Fragment key = {"asset" + index_asset_type} >
                                                                        
                                                                        {asset_each_type.asset.map((asset,index) => {
                                                                            order_id += 1;
                                                                            let sum_asset_depreciate = asset.node.historicalAmount; //ราคารวมค่าเสื่อมสะสม + ค่าเสื่อมยกมา
                                                                            sum_asset_depreciate += _.sumBy(asset.node.assetDepreciate.edges,'node.depreciatePrice')
                                                                            let header_name = i18next.t("materWater:am") === "En" ? `header_nameEn` : 'header_name';
                                                                            return (
                                                                                <React.Fragment key={asset.node.id + index}>
                                                                                    {index === 0 && 
                                                                                        <tr className="table-active" key="head"> 
                                                                                            <td colSpan={13} className='main'>{_.find(asset_type,{code: asset.node.type.toLowerCase()})[header_name]}</td>
                                                                                        </tr> 
                                                                                    }
                                                                                    <tr>
                                                                                        <td className="text-center"> 
                                                                                        <input type="checkbox"
                                                                                            onChange={() => this.appendToCheckList(asset.node.docNumber)}
                                                                                            checked={this.checkList(asset.node.docNumber)}
                                                                                        />
                                                                                        </td>
                                                                                        <td className="text-center">{order_id}</td>
                                                                                        <td className="text-center">
                                                                                            {/* <a to={"/accounting/asset/"+this.props.asset_type+"/form/"+asset.node.docNumber+"/" + asset.node.id} */}
                                                                                            <a className="assetLink" to={"/accounting/asset/"+this.props.asset_type+"/form/"+asset.node.docNumber+"/" + asset.node.id} onClick={() => this.onClickGetData(asset, this.props.asset_type,  asset.node.docNumber, asset.node.id)} >{asset.node.docNumber}</a> 
                                                                                        </td>
                                                                                        <td>{asset.node.internalAssetCode}</td>
                                                                                        <td>{asset.node.name}</td>
                                                                                        <td>{asset.node.description}</td>
                                                                                        {/* <td className="text-center">{asset.node.storage}</td> */}
                                                                                        <td className="text-left">{asset.node.seller ? asset.node.seller.name : asset.node.sellerOther ? asset.node.sellerOther : '-'}</td>
                                                                                        {this.props.asset_type !== 'land' &&
                                                                                            <React.Fragment>
                                                                                                <td className="text-center">{asset.node.calDepreciation ? numberWithComma(asset.node.depreciationRatePercent) : '-'}</td>
                                                                                                <td className="text-center">{format(asset.node.purchaseDate, 'DD/MM/YYYY')}</td>
                                                                                                <td className="text-right">{numberWithComma(asset.node.costPrice)}</td>
                                                                                                <td className="text-right">{numberWithComma(sum_asset_depreciate)}</td>
                                                                                                <td className="text-right">{numberWithComma(asset.node.costPrice - sum_asset_depreciate)}</td>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                        <td>
                                                                                            <div className={_.lowerCase(asset.node.saleStatus) === 'active' ? 'status-available text-center ml-2': 'status-non text-center ml-2'}>
                                                                                                <span className='text-center'>{t('asset:'+ _.lowerCase(asset.node.saleStatus))}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        
                                                                                        {/* <td>{asset.node.remark.length >= 80 ? asset.node.remark.substring(0, 80)+'...' : asset.node.remark}</td> */}
    
                                                                                    </tr>
                                                                                </React.Fragment>
                                                                            )
                                                                        })}
                                                                        {this.props.asset_type !== 'land' &&
                                                                            <tr key ="foot">
                                                                                <td colSpan={7}/>
                                                                                <td  className="text-right"><strong>รวม</strong></td>
                                                                                <td className="text-right"><strong>{numberWithComma(asset_each_type.total_costPrice)}</strong></td>
                                                                                <td className="text-right"><strong>{numberWithComma(asset_each_type.total_assetDepreciate)}</strong></td>
                                                                                <td className="text-right"><strong>{numberWithComma(asset_each_type.total_assetAfterDepreciate)}</strong></td>
                                                                                <td />
                                                                            </tr>
                                                                        }
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                            {this.props.props.allAsset.edges.length > 0 && asset.count_list > 1 && this.props.asset_type !== 'land'  &&
                                                            <tr>
                                                                <td colSpan={7}/>
                                                                <td className="text-right"><strong>รวมทั้งหมด</strong></td>
                                                                <td className="text-right"><strong>{numberWithComma(asset.sum_costPrice)}</strong></td>
                                                                <td className="text-right"><strong>{numberWithComma(asset.sum_assetDepreciate)}</strong></td>
                                                                <td className="text-right"><strong>{numberWithComma(asset.sum_assetAfterDepreciate)}</strong></td>
                                                                <td/>
                                                            </tr>
                                                            }
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </React.Fragment>
                        </div>
                    </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default withRouter(AssetListTable);
