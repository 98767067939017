import React, { Component } from 'react'
import PrivateRoute from '../../../libs/privateRoute';
import NoMatch from "../../../components/noMatch";
import {Switch, Route} from "react-router-dom";
import ContractExpenseList from './contractExpenseList';
import UpdateFormContractExpense from './updateFormContractExpense'

export default class indexContractExpense extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={ContractExpenseList}/>
                <PrivateRoute exact path={`${this.props.match.url}/updateFormContractExpense/:docNumber/:id/:status`} component={UpdateFormContractExpense}/>
                <Route component={NoMatch}/>
            </Switch>
        )
    }
}