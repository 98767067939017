import Swal from "sweetalert2";
import i18n from "../../../i18n";




export default function onSubmitSuccess(response){
    // this.setState({ loading: false });
    if (response) {
        Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
            // window.location.reload(); /contact/committee/pending
            // return <Redirect to='/contact/committee/pending' />
            // console.log(this.props);
            return false
        });
    } else {
        Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
    }
}

// onSubmitError = (err) => {
//     this.setState({ loading: false });
//     Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
// }