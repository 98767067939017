
import React, { Component } from 'react';
import './regisCollectionLawFrim.scss'
import CollectionLawFrimStep from "./collectionLawFrimStep"
import { Translation } from 'react-i18next';
const _ = require("lodash");

const docsName = [
    { name: 'docs1', label: `1. Attorney Appointment Document`},//หนังสือแต่งตั้งทนายความ
    { name: 'docs2', label: `2. Registered Corporate By laws`}, // ข้อบังคับนิติบุคคลฉบับจดทะเบียนสำนักที่ดิน
    { name: 'docs3', label: '3. List of the current corporate board members registered with the Land Office'},//3. รายชื่อคณะกรรมการนิติบุคคลชุดที่อยู่ในวาระอำนาจบริหารปัจจุบันฉบับจดทะเบียนกับสำนักที่ดิน
    { name: 'docs4', label: '4. Copy of the national identification card of the corporate board chairman, along with a sample of their signature'},
    { name: 'docs5', label: '5. Copy of the national identification card of the corporate manager, along with a sample of their signature'},
    { name: 'docs6', label: '6. Resolution to amend, change, or add to the corporate bylaws (if applicable)'},
];

class UploadDocsCollectionLawFrim extends Component {
    constructor(props) {
        super(props)

        this.getLastFileName = this.getLastFileName.bind(this)
    }
   
    getLastFileName(id,file){
        let typeFile = file[0]?.fileName?.split(".")
        let typeFileLength = typeFile && typeFile.length
        let typeICONFile = typeFile && typeFile[typeFileLength - 1]
        return (
            <div className='d-flex'>
                {file?.length > 0 ? <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeICONFile + "Icon.png"} style={{ width: '30px' }} /> : '' }
                <a href={file[0]?.fileUrl} target='_blank'>{file[0]?.fileName ? file[0]?.fileName : ''}</a>
                {file?.length > 0 ? <label onClick={() => this.props.deleFile(id, file)}>&nbsp;X </label> : ''}
            </div>  
        )
    }


    render() {

        return (

            <>
                <div className=''>
                        {
                            this.props.regisCLFStepFinish4 ? 
                                <CollectionLawFrimStep 
                                regisCLFStepFinish1={this.props.regisCLFStepFinish1} 
                                regisCLFStepFinish2={this.props.regisCLFStepFinish2} 
                                regisCLFStepFinish3={this.props.regisCLFStepFinish3} 
                                regisCLFStepFinish4={this.props.regisCLFStepFinish4} 
                            /> :
                                <CollectionLawFrimStep 
                                regisCLFStepFinish1={this.props.regisCLFStepFinish1} 
                                regisCLFStepFinish2={this.props.regisCLFStepFinish2} 
                                regisCLFStepFinish3={this.props.regisCLFStepFinish3} 
                                /> 
                        }
                    <div className='wrapper-box' style={{height:'400px'}}>
                            <Translation>
                                {
                                    t => (
                                    <p>{t('collectionLawFirm:Please attach the documents as specified to ensure completeness')}</p> 
                                    )
                                }
                            </Translation>
                            <Translation>
                                {
                                    t => (
                                    <p style={{ color: '#BFBFBF', fontSize: '12px'}}>{t('collectionLawFirm:Supports files with a size of up to 10 MB')}</p> 
                                    )
                                }
                            </Translation>
                            
                                <Translation>
                                {
                                t => (
                                _.map(docsName, (item, index) => {
                                    return (
                                    <>
                                        <div className='line-upload-docs'> 
                                          <div className='row'>
                                                <div className='col mt-2 text-left m-2'>
                                                    {t(`collectionLawFirm:${item.label}`)} {(index+1) !== 6 && <span className='text-danger'>*</span>} 
                                                    <div className="mt-2 text-left">
                                                        {
                                                            this.props[item.name] && this.getLastFileName(index+1,this.props[item.name])
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col pt-2'>
                                                    {
                                                        <div className="uploadBTN" style={{ display: 'inline-flex'}}>
                                                            <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                                                <button className={"btn"}  disabled={this.props[item.name].length < 1 ? false : true}>
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />
                                                                    {t(`collectionLawFirm:Only PDF ,JPG ,PNG`)}
                                                                </button>
                                                                <input type="file" name='file' id="uploadFile" className='form-control uploadFile'
                                                                       accept='.pdf,.png,.jpg' onChange={(event) =>this.props.uploadFile(event,index+1)} 
                                                                    />
                                                            </label>
                                                        </div>
                                                    }
                                                </div>
                                          </div>
                                        </div>
                                    </>
                                    )
                                })
                                )
                                 }
                                </Translation>
                            
                            
                    </div>
                    
                </div>
            </> 
           
        );
    }
}

export default UploadDocsCollectionLawFrim;