import '../../../document/styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {QueryRenderer} from "react-relay";
import '../debtFreeCertification.scss'
import DebtFreeCertification from './debtFreeCertification';
import ConfirmModal from './confirmModal';
import getAllSettingDebtFreeQuery from '../getAllSettingDebtFree';
import {Redirect} from 'react-router'; 
import Loading from "../../../libs/loading"
import EditForm from './editForm';
const query = graphql`
query wrapperPrintDebtFreeQuery($debsFreeCertificationId: [String]){
    selfProject{
        id
        name
        address
        logo
        juristicContactNumber
        keyProjectQr
        bankCompCode
        bankServiceCode
        taxIdNumber
        typeOfProject
        postcodeProject
        provinceProject
        districtProject
        subDistrictProject
    }
    allJuristicCompany {
        edges {
            node {
                id
                name
            }
        }
    }
    currentManager{
        edges{
            node{
                id
                image
                signature
                prefix
                firstName
                lastName
            }
        }
        totalCount
    } 
    allDebsFreeCertification(debsFreeCertificationId: $debsFreeCertificationId){
        totalCount
        edges{
            node{
                id
                docNumber
                status
                statusPrint
                dateStamp
                printDate
                dueDate
                creatorVoid
                voidDate
                voidNote
                debsFreeCertificationRequest{
                    id
                    docNumber
                    added
                    updated
                    contact{
                        id
                        name
                        firstName
                        lastName
                    }
                    requestingDate
                    creatorApprove
                    approvalDate
                    transactionDebtType
                    tenant{
                        firstName
                        lastName
                    }
                    receive{
                        id
                        docNumber
                    }
                    otherReceive{
                        id
                        docNumber
                    } 
                    status 
                }
            }
        }
    }
}`;



class wrapperPrintDebtFree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedContactID: this.props.location.state.selectedContactID,
            dateStamp : true,
            lifetime : 7,
            redirectToList : false,
            showFormChangeName: "",
            setName: "",
            name: "",
            showNameCompany: true
        }
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    checkDateStamp = () => {
        this.setState({
            dateStamp : !this.state.dateStamp
        })
    }

    updateLoading = () => {
        this.setState({redirectToList : true})

    }

    async componentDidMount(){
        let lifetime = 7
        let data = await getAllSettingDebtFreeQuery()
        if(data.allSettingDebsFreeCertification.edges && data.allSettingDebsFreeCertification.edges.length > 0){
            lifetime = data.allSettingDebsFreeCertification.edges[0].node.lifetime
        }
        this.setState({
            lifetime : lifetime,
            selectedContactID: this.props.location.state.selectedContactID,
        
        })
    }
    handleShowEditFromName = ()=> {
        if (this.state.showFormChangeName === "") {
            this.setState({showFormChangeName: "show"})}
        else {
            this.setState({showFormChangeName: ""})}
    }   
    handleAddNameChange = (value) => {
        this.setState({ setName: value });
      }

    handleButtonClick() {
        this.setState({ showFormChangeName: "" });
    }
    handleNameChange(name) {
        this.setState({ name: name });
      }
    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/debt_free_certification/list/active"/>;
        }
        return (
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Debt Free Certification</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu" id="debt-free-certification">
                    <div className="tab-print form-inline row">
                        <div className="d-flex justify-content-center form-inline col-3">
                            <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                        </div>
                        <div className="col-4 form-inline">
                            
                            <label>
                                <input type="checkBox" checked={this.state.showNameCompany} id='ShowNameCompany_debt_free_certification'
                                    onChange={() => this.setState({ showNameCompany: !this.state.showNameCompany })}/>
                                <label htmlFor='ShowNameCompany_debt_free_certification' className="ml-3 mr-3">แสดงชื่อบริษัท</label>
                            </label>
                           
                            <label>
                                <input type="checkBox" checked={this.state.dateStamp} id='ShowDateStamp_debt_free_certification'
                                    onChange={this.checkDateStamp}/>
                                <label htmlFor='ShowDateStamp_debt_free_certification' className="ml-3 mr-3">ลงวันที่ออกหนังสือรับรองการปลอดหนี้</label>
                            </label>  
                        </div> 
                        <div className='form-inline'> 
                        <div>                        
                            <button className='editBtn' onClick={this.handleShowEditFromName}>แก้ไขผู้รับ</button>
                        </div>
                            <ConfirmModal  lifetime = {this.state.lifetime} dateStamp={this.state.dateStamp} 
                                selectedContactID ={this.state.selectedContactID}
                                updateLoading ={this.updateLoading}
                            />
                        </div>
                    </div>
                </div>

                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{debsFreeCertificationId: this.state.selectedContactID}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return (
                                <React.Fragment>    
                                                {this.state.showFormChangeName === "show" && <EditForm onAddName={this.handleAddNameChange} onHide={this.handleButtonClick} name={this.state.name}/>}
                                    {props.allDebsFreeCertification.edges.map((debtCertification , index) => {

                                       let prefixName =  ""
                                        if ( props?.currentManager?.edges[0]?.node?.prefix === "Miss") {
                                            prefixName = props?.currentManager?.edges[0]?.node?.prefix?.replace("Miss","นางสาว")
                                        } else if ( props?.currentManager?.edges[0]?.node?.prefix === "Mrs.") {
                                            prefixName = props?.currentManager?.edges[0]?.node?.prefix?.replace("Mrs","นาง")
                                        } else if ( props?.currentManager?.edges[0]?.node?.prefix === "Mr.") {
                                            prefixName = props?.currentManager?.edges[0]?.node?.prefix?.replace("Mr","นาย") 
                                        } else{
                                            prefixName = props?.currentManager?.edges[0]?.node?.prefix
                                        }
                                        
                                        return (
                                            
                                            <DebtFreeCertification selfProject={props.selfProject} 
                                                key={index}
                                                allJuristicCompany={props.allJuristicCompany} currentManager={props.currentManager}
                                                dateStamp={this.state.dateStamp}
                                                prefixName={prefixName}
                                                date={debtCertification.node.dateStamp}
                                                debtCertification={debtCertification}
                                                lifetime={this.state.lifetime}
                                                setName={localStorage.getItem("nameDebtFreeCertification") ? localStorage.getItem("nameDebtFreeCertification") : this.state.setName}
                                                name={this.state.name}
                                                onNameChange={this.handleNameChange}
                                                debitFeeStatus={'edit'}
                                                showNameCompany={this.state.showNameCompany} 
                                            />
                                        )
                                    })}
                                </React.Fragment>
                            )
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />
            </React.Fragment>
        );
    }
}

export default wrapperPrintDebtFree;
