/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateBankTransferInput = {|
  transferOut: string,
  transferIn: string,
  amount: number,
  fee?: ?string,
  date: any,
  description: string,
  clientMutationId?: ?string,
|};
export type bankTransferCreateModalMutationVariables = {|
  input: CreateBankTransferInput
|};
export type bankTransferCreateModalMutationResponse = {|
  +createBankTransfer: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type bankTransferCreateModalMutation = {|
  variables: bankTransferCreateModalMutationVariables,
  response: bankTransferCreateModalMutationResponse,
|};
*/


/*
mutation bankTransferCreateModalMutation(
  $input: CreateBankTransferInput!
) {
  createBankTransfer(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateBankTransferPayload",
    "kind": "LinkedField",
    "name": "createBankTransfer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bankTransferCreateModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bankTransferCreateModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7e843f1aed6b749fed5e0d085bd78446",
    "id": null,
    "metadata": {},
    "name": "bankTransferCreateModalMutation",
    "operationKind": "mutation",
    "text": "mutation bankTransferCreateModalMutation(\n  $input: CreateBankTransferInput!\n) {\n  createBankTransfer(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7567a3d8994773aa072f967683710524';

module.exports = node;
