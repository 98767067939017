import '../styles/dailyJournal.scss';
import React, {Component} from 'react';

import $ from "jquery";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import TransactionChannel from "../../libs/transactionChannel";
import _ from "lodash";
import i18n from 'i18next'
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';

const ThaiBaht = require('thai-baht-text');

class DailyJournal extends Component {

    constructor(props){
        super(props);
        this.state = {
            page_record:{}, //แบ่งหน้า table กรณีเกิน 18 record
            checked:0,
            languages: "th"
        };
    }

    componentDidMount() {
        i18n.changeLanguage(this.props.languages);
        this.setState({
            languages: this.props.languages,
        })
        setTimeout(() => {
            let page = $('.print-daily-journal-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let transaction = page.find('.transaction').height();
            let page_height = 1125 - 200; //150

            let diff = page_height - (head + detail + invoice + signature + transaction);

            if (diff < 100) {
                $('tbody tr:last-child td').css({paddingBottom: diff});
            }else{
                $('tbody tr:last-child td').css({paddingBottom: diff});
            }
        }, 200);
        

        this.setPage();
    }

    setPage(){     
        let credit = this.props.query.allAccountRecordGroup.edges[0].node.accountRecord.edges.filter(value => value.node.credit !== 0)
        let debit = this.props.query.allAccountRecordGroup.edges[0].node.accountRecord.edges.filter(value => value.node.debit !== 0)
        let allAccountGroup = []
        allAccountGroup.push(...debit)
        allAccountGroup.push(...credit)
        //table:18 record
        if(this.props.query.allAccountRecordGroup.edges.length > 0){

            let allsort = _.map(allAccountGroup, (val) =>  {
               return val.node.name.length
            }); 
            let descriptionMaxlength = _.sortBy(allsort,sort => sort).reverse().shift();
        
            let checkRow = 0;

            let getLine = 45; //45 per one line on description

            let rowPerLine = descriptionMaxlength / getLine;

            rowPerLine = parseInt(rowPerLine);            
            if(descriptionMaxlength <= 43 && rowPerLine === 0){
                checkRow = 14;
            }
            else if(descriptionMaxlength > 45 && rowPerLine <= 2){
                checkRow = 12;
            }else if(descriptionMaxlength > 45 && rowPerLine <= 4){
                checkRow = 10;
            }else if(descriptionMaxlength > 45 && rowPerLine <= 8){
                checkRow = 4;
            }else if(descriptionMaxlength > 45 && rowPerLine > 8){
                checkRow = 14;
            }else {
                checkRow = 17;
            }
   
            if(allAccountGroup.length > checkRow){ //17
                let page = {};
                let index_page = 1;
                let count = 1;
                let check = 0
                allAccountGroup.map((value,index) => {

                    if(count <= checkRow){ //17
                        // เพิ่มจาก B520 ตัวคำอธิบายมี 1400 ตัวอักษรเลยเพิ่มเงื่อนไขถ้าเจอคำอธิบายมากกว่า 800 ให้อยู่ไปเลยหน้าเดียวรายการเดียว
                        if(value.node?.name?.length > 800){
                            count = 1;
                            index_page += 1;
                            if(!page[index_page]){
                                page[index_page] = [];
                                page[index_page].push(value);
                            }
                            return
                        }

                        if(!page[index_page]){
                            page[index_page] = [];
                        }
                        page[index_page].push(value);
                        count +=1;
                    }else{
                        // เพิ่มจาก B520 ตัวคำอธิบายมี 1400 ตัวอักษรเลยเพิ่มเงื่อนไขถ้าเจอคำอธิบายมากกว่า 800 ให้อยู่ไปเลยหน้าเดียวรายการเดียว
                        if(value.node?.name?.length > 800){
                            count = 1;
                            index_page += 1;
                            if(!page[index_page]){
                                page[index_page] = [];
                                page[index_page].push(value);
                            }
                            return
                        }

                        count = 1;
                        index_page += 1;
                        if(!page[index_page]){
                            page[index_page] = [];
                            page[index_page].push(value);
                        }
                    }
                    check = index_page                   
                    return value
                })
                this.setState({
                    page_record:page,
                    checked:check
                })
            }else{
                let page = {};
                page[1] = [];
                allAccountGroup.map(value => {
                    page[1].push(value);
                    return value
                })
            this.setState({page_record:page,checked:1})
        }}

    }

    render() {        
        return (
            <React.Fragment>
                {Object.entries(this.state.page_record).map(([key,record],index) =>
                    <div className="print-daily-journal-a4 pt-6" key={index} style={{position: 'relative'}}>
                        <div className="subpage" style={{overflow: 'hidden'}}>   
                            <div className="head">
                                <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"1fr 5fr"}}>
                                        <div style={{minHeight:100}}>
                                            <img src={this.props.query.selfProject.logo} alt="silverman"/>
                                        </div>
                                        <div>
                                            <span className="text-center" style={{position:"absolute"}}>
                                            <div><strong style={{fontSize:12}}>{this.state.languages === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name) : this.props.query.selfProject.name}</strong></div>
                                            <div>{this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}</div>
                                            <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                                            </span>
                                        </div>
                                </div>
                            </div>
                            
                            <div className="detail">
                                <div className="customer-detail">
                                    {
                                        this.props.query.allAccountRecordGroup.edges.length > 0 &&
                                            this.props.query.allAccountRecordGroup.edges[0].node.category === "PURCHASE" ?
                                                <strong style={{fontSize:12}}>สมุดรายวันซื้อ (เจ้าหนี้)</strong>
                                                : this.props.query.allAccountRecordGroup.edges[0].node.category === "SALES" ?
                                                <strong style={{fontSize:12}}>สมุดรายวันขาย (เจ้าของร่วม/สมาชิก, ลูกหนี้)</strong>
                                                : this.props.query.allAccountRecordGroup.edges[0].node.category === "PAYMENT" ?
                                                <strong style={{fontSize:12}}>สมุดรายวันจ่าย</strong>
                                                : this.props.query.allAccountRecordGroup.edges[0].node.category === "RECEIVE" ?
                                                <strong style={{fontSize:12}}>สมุดรายวันรับ</strong>
                                                : this.props.query.allAccountRecordGroup.edges[0].node.category === "GENERAL" &&
                                                <strong style={{fontSize:12}}>สมุดรายวันทั่วไป</strong>
                                    }
                                    <br/>
                                    <div>รายละเอียด {this.props.query.allAccountRecordGroup.edges.length > 0 && this.props.query.allAccountRecordGroup.edges[0].node.name}</div>
                                </div>
                                <div className="document-detail">
                                เลขที่เอกสาร&emsp;{this.props.query.allAccountRecordGroup.edges.length > 0 && this.props.query.allAccountRecordGroup.edges[0].node.refNumber}<br/>
                                    {this.props.query.allAccountRecordGroup.edges.length > 0 && this.props.query.allAccountRecordGroup.edges[0].node.refTransaction !== this.props.query.allAccountRecordGroup.edges[0].node.refNumber &&
                                    <React.Fragment>
                                        เลขที่อ้างอิง &emsp;{this.props.query.allAccountRecordGroup.edges.length > 0 && this.props.query.allAccountRecordGroup.edges[0].node.refTransaction}<br/>
                                    </React.Fragment>
                                    }
                                วันที่&emsp;&nbsp;{format(this.props.query.allAccountRecordGroup.edges.length > 0 && (this.props.query.allAccountRecordGroup.edges[0].node.refPayExpenseDate || this.props.query.allAccountRecordGroup.edges[0].node.issuedDate), 'DD/MM/YYYY', {locale: thLocale})}<br/>
                                </div>
                            </div>
        
                            <div className="invoice-body">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th width={60} className="text-center">
                                            <strong>รหัสบัญชี</strong>
                                        </th>
                                        <th className="text-center" width={180}>
                                            <strong>ชื่อบัญชี</strong>
                                        </th>
                                        <th className="text-center" width={220}>
                                            <strong>คำอธิบาย</strong>
                                        </th>
                                        <th width={70}  className="text-center">
                                            <strong>เดบิต</strong>
                                        </th>
                                        <th width={70}  className="text-center">
                                            <strong>เครดิต</strong>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.query.allAccountRecordGroup.edges.length > 0 &&
                                        <React.Fragment>
                                            {record.map((account_record) =>
                                                <tr key={account_record.node.id}>
                                                  
                                                    <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                    <td>{account_record.node.chartOfAccountCode.name}</td>
                                                    <td style={{maxWidth:200 ,overflowWrap:'break-word'}}>{this.props.query.allAccountRecordGroup.edges.length > 0 
                                                        && this.props.query.allAccountRecordGroup.edges[0].node.refTransaction 
                                                        ? account_record.node.name
                                                        :  account_record.node.name} </td>
                                                     {/* <td style={{maxWidth:200 ,overflowWrap:'break-word'}}>{this.props.query.allAccountRecordGroup.edges.length > 0 
                                                        && this.props.query.allAccountRecordGroup.edges[0].node.refTransaction 
                                                        && account_record.node.name}  </td> */}
                                                    <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                    <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    }
                                    </tbody>
                                    {index+1 === Object.keys(this.state.page_record).length ?
                                        <tfoot>
                                            <tr>
                                                <td className="text-center" style={{borderRight: "none"}}>
                                                    <strong>รวม</strong>
                                                </td>
                                                <td colSpan={2} className="text-left" style={{borderLeft:"none"}}>
                                                    <strong style={{fontSize:12}}>                                                        
                                                        {i18n.languages[0] === 'th' ? 
                                                        (ThaiBaht(this.props.query.allAccountRecordGroup.edges.length > 0 &&this.props.query.allAccountRecordGroup.edges[0].node.totalDebit)):
                                                        (ConvertNumberToTextEng(this.props.query.allAccountRecordGroup.edges.length > 0 &&this.props.query.allAccountRecordGroup.edges[0].node.totalDebit))}    
                                                    </strong>
                                                </td>
                                                <td className="text-right">
                                                    <strong style={{fontSize:12}}>{numberWithComma(this.props.query.allAccountRecordGroup.edges.length > 0 &&this.props.query.allAccountRecordGroup.edges[0].node.totalDebit)}</strong>
                                                </td>
                                                <td className="text-right">
                                                    <strong style={{fontSize:12}}>{numberWithComma(this.props.query.allAccountRecordGroup.edges.length > 0 &&this.props.query.allAccountRecordGroup.edges[0].node.totalCredit)}</strong>
                                                </td>
                                            </tr>
                                        </tfoot> 
                                        :
                                        <tfoot>
                                            <tr>
                                                <td style={{borderTop: "none"}}/>
                                                <td style={{borderTop: "none"}}/>
                                                <td style={{borderTop: "none"}}/>
                                                <td style={{borderTop: "none"}}/>
                                                <td style={{borderTop: "none"}}/>
                                                
                                            </tr>
                                        </tfoot>
                                     }
                                    
                                </table>
                                {/*<div className="remark">*/}
                                {/*    หมายเหตุ/Remarks: <br/>*/}
                                {/*</div>*/}
                            </div>
                            {index+1 === Object.keys(this.state.page_record).length &&
                                <div className = "transaction">
                                    {/*ช่องทางชำระเงิน*/}
                                    <TransactionChannel
                                        allAccountRecordGroup={this.props.query.allAccountRecordGroup}
                                        cashTransactionViewer={this.props.query.cashTransactionViewer}
                                        chequeDepositViewer={this.props.query.chequeDepositViewer}
                                        chequeTransactionViewer={this.props.query.chequeTransactionViewer}
                                        allBankAccountTransaction={this.props.query.allBankAccountTransaction}
                                        allCreditCardTransaction={this.props.query.allCreditCardTransaction}
                                        allPaymentChannel={this.props.query.paymentChannelViewer.allPaymentChannel}
                                        allPaymentGatewayMethod={this.props.query.allPaymentGatewayMethod}
                                        allOtherExpensePaymentChannel={this.props.query.otherExpensePaymentChannelViewer.allOtherExpensePaymentChannel}
                                        allAdvancePettyCashChannel={this.props.query.allAdvancePettyCashChannel}
                                        allClearAdvancePettyCashChannel={this.props.query.allClearAdvancePettyCashChannel}
                                        allClearGuaranteeMoneyReceivedPettyCashChannel={this.props.query.allClearGuaranteeMoneyReceivedPettyCashChannel}
                                    />
                                </div>
                            }
        
                            {index+1 === Object.keys(this.state.page_record).length &&
                            <div className="footer" style={{position:'absolute',bottom:0}}>
                                <div className="pt-5" >
                                    <span className="box-left">
                                        ..................................................<br/>
                                        ผู้จัดทำ
                                    </span>
                                    <span className="box-left">
                                        ..................................................<br/>
                                        ผู้ตรวจสอบ
                                    </span>
                                    <span className="box-left">
                                        ..................................................<br/>
                                        ผู้อนุมัติ
                                    </span>
                                </div>
                                <div>
                                    <span className="box-left">
                                    วันที่ ................................
                                    </span>
                                    <span className="box-left">
                                    วันที่ ................................
                                    </span>
                                    <span className="box-left">
                                    วันที่ ................................
                                    </span>
                                </div>
                            </div>}
        
                        </div>
                    </div>
                )}
            </React.Fragment>

        );
    }
}

export default DailyJournal;
