import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import LotteryAwardDashboard from './lotteryAward';
import ResultLotteryAward from './resultLotteryAward'
import LotteryAwardStep from './lotteryAwardStep'
import ViewLotteryAward from './viewLotteryAward'
import ViewLotteryAwardAll from './viewLotteryAwardAll'
class LotteryAward extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={`${this.props.match.url}`} component={LotteryAwardDashboard}/>
                <Route exact path={`${this.props.match.url}/result/:lang?`} component={ResultLotteryAward}/>
                <Route exact path={`${this.props.match.url}/step/:lang?`} component={LotteryAwardStep}/>
                <Route exact path={`${this.props.match.url}/view-more/:lang?`} component={ViewLotteryAward}/>
                <Route exact path={`${this.props.match.url}/view-all/:lang?`} component={ViewLotteryAwardAll}/>
            </Switch>
        );
    }
}

export default LotteryAward;