import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import Purchase from './purchase';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperPurchaseQuery($id: ID!) {
        selfProject{
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        purchaseRecordGroup(id: $id) {
            id
            docNumber
            issuedDate
            dueDate
            taxType
            remark
            status
            total
            contact {
                refNumber
                firstName
                lastName
                name
                taxIdNumber
                registeredName
                registeredAddress
                registeredCountry
                registeredProvince
                registeredCity
                registeredDistrict
                registeredPostalCode
                typeOfContact
                typeOfCompany
                nameBranch
                typeOfSupplier
            }

            firstName 
            lastName
            name
            phone
            email
            taxIdNumber
            address
            district
            city
            province
            postalCode
            country
            
            taxInvoiceNumber
            taxInvoiceDate
            taxInvoicePeriod
            purchaseRecord {
                edges {
                    node {
                        id
                        chartOfAccount {
                            chartOfAccountCode
                            name
                            totalDebit
                            totalCredit
                        }
                        productAndService {
                            productCode
                            name
                        }
                        description
                        unitItems
                        price
                        discount
                        vat
                        vatAmount
                        preTaxAmount
                        whtRate
                        whtRatePercent

                        prepaidDepositTransactionUsed{
                            edges{
                                node{
                                    id
                                    amount
                                    prepaidDepositTransaction{
                                        whtRatePercent
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
        purchaseAccountRecordGroup(purchaseRecord: $id){
            edges{
                node{
                    id
                    totalDebit
                    totalCredit
                    accountRecord {
                        edges {
                            node {
                                id
                                debit
                                credit
                                name
                                added
                                chartOfAccountCode {
                                    id
                                    chartOfAccountCode
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
        userConfig {
            id languages
        }
    }
`;

class WrapperPurchase extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.adjustHeight = this.adjustHeight.bind(this);
    }

    print() {
        window.print();
    }

    adjustHeight() {

    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Purchase</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{id: this.props.match.params.id}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return(
                                <Purchase 
                                    query={props}
                                    languages={props.userConfig.languages}
                                />
                            )
                        }
                         return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperPurchase;
