import '../../../document/styles/main.scss';
import React, {Component} from 'react';
import {format} from "date-fns";
import _ from "lodash";
import '../debtFreeCertification.scss'
import getNameResidential from '../../../libs/getNameResidential'
import { getTypeProject,getWordType,getTypeNiti,getTypeNiti2  } from "../../../libs/getTypeOfProject";
import { Translation } from 'react-i18next';


class DebtFreeCertification extends Component {

    constructor(props){
        super(props);
        this.state = {
            defaultName: `เจ้าหน้าที่พนักงานที่ดิน${this.getText(this.props.selfProject.postcodeProject)}`,
            name: props.setName !== "" ? props.setName : `เจ้าหน้าที่พนักงานที่ดิน${this.getText(this.props.selfProject.postcodeProject)}`
          };
        }
    componentDidUpdate(prevProps) {
            if (prevProps.defaultName !== this.props.defaultName || prevProps.setName !== this.props.setName) {
              const name = this.props.setName !== "" ? this.props.setName : this.props.cheque.node.payName;
              this.props.onNameChange(name); 
              this.setState({ name });
            }
    }
    componentDidMount() {
        const name = this.state.name;
        // if(this.props.debitFeeStatus === 'edit')
        this.props.onNameChange(name);
      }

    isBangkok = (code) => {
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310 ];
        if(_.indexOf(bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getText = (code) => {
        if(this.isBangkok(code)){
            // return  'กรุงเทพมหานคร '+'สาขา' + this.props.selfProject.subDistrictProject
            return  'กรุงเทพมหานคร สาขา' + this.props.selfProject.districtProject
        }else{
            return ' จังหวัด'+ this.props.selfProject.provinceProject + 'สาขา' + this.props.selfProject.districtProject
        }

    }

    getTypeProject = (type) =>{
        let result; 
        switch (type) {
            case "CONDOMINIUM":
              result = 'ห้องชุด'
              break;
            case "VILLAGE":
              result = 'บ้านเลขที่'
              break;
              default:
              result = 'ห้องชุด'
          }
          return result;
    }


    render() {

        let getType = getTypeProject(this.props.selfProject?.typeOfProject)

        return (
            <React.Fragment key={this.props.debtCertification.node.id}>
                <div className="print-page-a4 "  id="print-debt-certification">
                    <div className="subpage pl-4 pr-4 ">
                        {/* <div className="row col-12 d-flex justify-content-center"> 
                            <img src={this.props.selfProject.logo} alt="silverman" style={{maxHeight:120,maxWidth:120}} /> <br/>
                        </div>
                        <div className="row col-12 mt-2 mb-2 d-flex justify-content-end"> 
                            <span>วันที่ {this.props.dateStamp ? format(new Date(),'DD/MM/YYYY') : '...................................................'}</span>
                        </div> */}
                         <div className="head">                  
                            <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                            <div style={{minHeight:100,minWidth:100}}> 
                                <img src={this.props.selfProject.logo} alt="silverman"/>
                            </div>
                            <div>  
                                <div>
                                    <div className='address-header' style={{fontSize:'13px'}}><strong>{this.props.selfProject.name}</strong></div>
                                    <Translation>{t=><div className="address-header" style={{fontSize:'13px'}}>{this.props.selfProject.address} </div>}</Translation>
                                    <Translation>{t=><div className="address-header"style={{fontSize:'13px'}}>{t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                </div>
                                <Translation>{t=><div style={{fontSize:'13px'}}>{t("document:Taxpayer identification number")} {this.props.selfProject.taxIdNumber || '-'}</div>}</Translation>
                            </div> 
                            </div>

                            <div className="document-name">
                                <div className="title-invoice" style={{fontSize:'21px'}}>ใบปลอดหนี้</div>
                                <div className="title-invoice" style={{fontSize:'21px'}}>Debt Free Certification</div>
                            </div>
                        </div>
                        <br/>
                        <div className='row col-12' style={{marginLeft:'5px'}}>
                            <div className='col-8 mt-4'>
                            </div>
                            <div className='col-4 mt-4'>
                                <strong>เลขที่/No. : </strong>
                                <span>{this.props.debtCertification.node.docNumber ? this.props.debtCertification.node.docNumber : '-'}</span>
                            </div>
                        </div>
                        
                       {this.props.dateStamp &&
                            <div className='row col-12' style={{marginLeft:'5px'}}>
                                <div className='col-8'>
                                </div>
                                <div className='col-4'>
                                    <strong>วันที่/Date : </strong>
                                    {/* support -> เปลี่ยนจากวันที่กดพิมพ์เอกสารมาเป็นวันที่ ระบุในหนังสือรับรองการปลอดหนี้จากตอนสร้างคำร้อง */}
                                    <span>{format(new Date(this.props.debtCertification.node.debsFreeCertificationRequest.requestingDate),'DD/MM/YYYY')}</span>
                                </div>
                            </div>
                        }
                        {
                            !this.props.dateStamp &&
                                <div className='row col-12' style={{marginLeft:'5px'}}>
                                    <div className='col-8'>
                                    </div>
                                    <div className='col-4'>
                                        <strong>วันที่/Date : </strong>
                                        {/* support -> เปลี่ยนจากวันที่กดพิมพ์เอกสารมาเป็นวันที่ ระบุในหนังสือรับรองการปลอดหนี้จากตอนสร้างคำร้อง */}
                                    </div>
                                </div>

                        }

                        <div className='row'>
                            <div className='col-7 mt-4'>
                                <strong>เรื่อง : </strong>
                                <span>หนังสือรับรองการปลอดหนี้</span>
                            </div>
                            <div className='col-5 mt-4'>
                                
                            </div>
                        </div>
                       {
                    //    this.props.dateStamp &&
                    //         <div className='row col-12' style={{marginLeft:'5px'}}>
                    //             <div className='col-8'>
                    //             </div>
                    //             <div className='col-4'>
                    //                 <strong>วันที่/Date : </strong>
                    //                 {/* support -> เปลี่ยนจากวันที่กดพิมพ์เอกสารมาเป็นวันที่ ระบุในหนังสือรับรองการปลอดหนี้จากตอนสร้างคำร้อง */}
                    //                 <span>{this.props.dateStamp ? format(new Date(this.props.debtCertification.node.debsFreeCertificationRequest.requestingDate),'DD/MM/YYYY') : ''}</span>
                    //             </div>
                    //         </div>
                        }
                        <div className='row'>
                        <div className='col-7'>
                                <strong>เรียน :</strong>
                                {this.props.setName === "" ?  <span>{this.state.defaultName}</span> : <span>{this.props.setName}</span>}
                            </div>
                            <div className='col-5'>
                              
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="row col-12 mt-1 d-flex justify-content-start detail-indent">
                            <span >ด้วยพระราชบัญญัติ{getTypeNiti2(getType)}  พ.ศ.2522  แก้ไขเพิ่มเติมโดยพระราชบัญญัติ{getTypeNiti2(getType)} (ฉบับที่ 4) 
                                พ.ศ. 2551  มาตรา 29  วรรคสอง กำหนดไว้ว่าในกรณีที่มีผู้ขอจดทะเบียนสิทธิ และ นิติกรรมโอนกรรมสิทธิ์ใน
                                {getWordType(getType)}
                                พนักงานเจ้าหน้าที่จะรับจดทะเบียนสิทธิ และ นิติกรรมการได้ เมื่อ{getWordType(getType)}ดังกล่าวปลอดหนี้อันเกิด
                                จากค่าใช้จ่ายตามมาตรา 18 โดยต้องมีหนังสือรับรองการปลอดหนี้จากนิติบุคคล{getTypeNiti(getType)}มาแสดง
                            </span>
                        </div>
                        <div className="row col-12 mt-4 d-flex justify-content-start detail-indent"> 
                            <span>
                            <strong>
                                {this.props.selfProject.name}</strong> โดย 
                                
                            <strong>{this.props.currentManager?.edges.length === 0 ? '.................................' : (" "+this.props?.prefixName  +this.props.currentManager.edges[0].node.firstName+ " "+ this.props.currentManager.edges[0].node.lastName +" ")}
                            </strong>
                            {/* ผู้ดำเนินการแทน  */}
                            {/* {this.props.allJuristicCompany?.edges.length === 0 ? '.................................' :  " "+this.props.allJuristicCompany.edges[0].node.name} 
                            ซึ่งได้รับแต่งตั้งให้เป็นผู้จัดการนิติบุคคลอาคารชุดจึง */}
                            ซึ่งได้รับการแต่งตั้งให้เป็นผู้จัดการนิติบุคคล{getTypeNiti(getType)}{" "}
                            <strong>
                                จึงขอรับรองว่า {getNameResidential(this.props.debtCertification.node.debsFreeCertificationRequest.contact.firstName , this.props.debtCertification.node.debsFreeCertificationRequest.contact.lastName)}  
                                {" "}ผู้ถือกรรมสิทธิ์ใน{getWordType(getType)} {getType === 'highType' ? 'เลขที่' : ''}{this.props.debtCertification.node.debsFreeCertificationRequest.contact.name}  ไม่มีหนี้อันเกิดจากค่าใช้จ่ายตามมาตรา 18{" "} 
                            </strong>
                             <strong>ค้างชำระ ต่อ{this.props.selfProject.name+ " "} แต่อย่างใด</strong>
                            </span>
                        </div>
                        <div className="row col-12 mt-4 d-flex justify-content-start detail-indent"> 
                            <span> หนังสือรับรองฉบับนี้ มีผลบังคับใช้ภายใน {this.props.lifetime} วัน นับแต่วันที่ออกหนังสือฉบับนี้</span>
                        </div>
                        <div className="row col-12 mt-4 d-flex justify-content-start detail-indent"> 
                            <span>จึงเรียนมาเพื่อโปรดทราบ</span>
                        </div>
                        <div className="row footer">
                            <div className="col-4"/>
                            <div className="col-8">
                                <div className="signature-office">
                                    <div className="col-12"><strong>ขอแสดงความนับถือ</strong></div>
                                    <br />
                                    <br/> 


                                    <div className="col-12">
                                        <strong>..............................................................</strong>
                                    </div> 

                                    <div className="col-12">
                                        <strong>{this.props.currentManager?.edges.length === 0 ? 
                                            '...........................................' 
                                            : 
                                            this.props?.prefixName +this.props.currentManager.edges[0].node.firstName+ " "+ this.props.currentManager.edges[0].node.lastName}
                                        </strong>
                                    </div>
                                    <div className="col-12"><strong>ผู้จัดการนิติบุคคล{getTypeNiti(getType)}</strong></div>

                                    
                                    <div className="col-12">
                                        <strong>
                                            {
                                                this.props.showNameCompany && this.props.allJuristicCompany?.edges.length > 0 ? 
                                                this.props.allJuristicCompany.edges[0].node.name : ""
                                            }
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }                   
}

export default DebtFreeCertification;