import React, { Component } from 'react';
import { Translation } from "react-i18next";
import SwitchLanguages from "./switchLanguages";

class Menu extends Component {
    render() {
        return (
            <div className="menu dropdown">
                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={process.env.PUBLIC_URL + '/images/header/hamburger.png'} alt="menu"
                        className="menu-button"
                        srcSet={process.env.PUBLIC_URL + '/images/header/hamburger@2x.png 2x'} />
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                    {/* <Translation>
                        {
                            t => <a className="dropdown-item" href="/">{t('headerMenu:edit profile')}</a>
                        }
                    </Translation> */}
                    <SwitchLanguages />
                    <Translation>
                        {
                            t =>
                                <>
                                <a className="dropdown-item" href="https://drive.google.com/drive/folders/1UZc_8dAjffLitPpGR4zKjVSfL-4z8n7J" target="_blank">
                                <img src={process.env.PUBLIC_URL + '/images/header/manual.png'} alt="manual" />&nbsp;&nbsp;{t('headerMenu:manual')}</a> 
                                <a className="dropdown-item" href="https://drive.google.com/drive/folders/12_ly-o4CBN-MSeVo-3wtXtxyzeRXdGf5" target="_blank">
                                 <img src={process.env.PUBLIC_URL + '/images/header/file.png'} alt="file" />&nbsp;&nbsp;{t('headerMenu:file')}</a>
                                </>
                        }
                    </Translation>
                    <Translation>
                        {
                            t =>
                                <>
                                    <a className="dropdown-item" href="https://silverman.app/contact"> 
                                    <img src={process.env.PUBLIC_URL + '/images/header/phone.png'} alt="phone" />&nbsp;&nbsp;{t('headerMenu:contact silverman')}</a>
                                </>
                        }
                    </Translation>
                    <Translation>
                        {
                            t => <a className="dropdown-item" href="/feedback-post">
                                <img src={process.env.PUBLIC_URL + '/images/sidebar/tenant-side-icon2.png'} alt="icon" />&nbsp;&nbsp;{t('set_permission:Feedback')}</a>
                        }
                    </Translation>
                    <Translation>
                        {
                            t => <a className="dropdown-item" href="/login">
                                <img src={process.env.PUBLIC_URL + '/images/header/logout.png'} alt="logout" />&nbsp;&nbsp;{t('headerMenu:logout')}</a>
                        }
                    </Translation>

                    <Translation>
                        {
                            t => 
                            <a className="dropdown-item" href="/policyAndManagement" style={{color:'#808080',fontSize:'10px'}}>{t('headerMenu:pdpa and manage')} 
                            <ul><li>Silverman 3.0.2</li></ul></a>
                        }
                    </Translation>

                </div>
            </div>
        );
    }
}

export default Menu;
