/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type checkResidentailQueryVariables = {|
  name?: ?string
|};
export type checkResidentailQueryResponse = {|
  +checkResidential: ?{|
    +result: ?boolean,
    +reason: ?string,
  |}
|};
export type checkResidentailQuery = {|
  variables: checkResidentailQueryVariables,
  response: checkResidentailQueryResponse,
|};
*/


/*
query checkResidentailQuery(
  $name: String
) {
  checkResidential(name: $name) {
    result
    reason
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "NameResidentialObject",
    "kind": "LinkedField",
    "name": "checkResidential",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reason",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkResidentailQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkResidentailQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "712b0a1f83c2706ebd1d496cb97495ea",
    "id": null,
    "metadata": {},
    "name": "checkResidentailQuery",
    "operationKind": "query",
    "text": "query checkResidentailQuery(\n  $name: String\n) {\n  checkResidential(name: $name) {\n    result\n    reason\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b2a03ef0e22f29c1741363f7d29e2a6';

module.exports = node;
