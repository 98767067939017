import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import CreditCardDepositCreateSelectTransactionList from "./creditCardDepositCreateSelectTransactionList"
import CreditCardDepositCreateForm from "./creditCardDepositCreateForm"
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";

const query = graphql`
    query creditCardDepositCreateQuery{
        allCreditCardTransaction(status: "not_pass"){
            edges{
                node{
                    id
                    receive{
                        docNumber
                        issuedDate
                    }
                    invoice{
                        docNumber
                        issuedDate
                    }
                    otherReceive{
                        docNumber
                        issuedDate
                    }
                    receiptDeposit{
                        docNumber
                        issuedDate
                    }
                    description
                    amount
                }
            }
        }
    }
`;

class CreditCardDepositCreate extends Component {

      constructor() {
        super();
        this.state = {
         select_transaction:[]
        };
        this.updateSelectTransaction = this.updateSelectTransaction.bind(this);

    }

    updateSelectTransaction(select_transaction){
          this.setState({select_transaction: select_transaction})
    }

    render() {
            return (
                <Wrapper>
                    <Header/>
                    <Sidebar/>
                    <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                        <div className="container-fluid box" id="invoice-list">

                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/accounting/finance/credit-card/list">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                 alt="back" className="back"/>
                                        </Link>
                                        <Translation>
                                            {
                                                t => <span>{t('credit_card:add_credit_card')}</span>
                                            }
                                        </Translation>
                                    </h3>
                                </div>
                            </div>
                            <div className="content-inner">
                                {this.props.match.params.status === 'select-receive' &&
                                <CreditCardDepositCreateSelectTransactionList query={query} updateSelectTransaction={this.updateSelectTransaction}/>
                                }
                                {this.props.match.params.status === 'form' &&
                                <CreditCardDepositCreateForm select_transaction={this.state.select_transaction}/>
                                }

                            </div>

                        </div>
                    </WrapperContent>

                </Wrapper>

            )

    }
}

export default CreditCardDepositCreate;