import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import Dashboard from "./dashboard";
import PaySummaryReport from "./pay_summary/paySummaryReport";
import PayableOutstandingReport from "./payable_outstanding/payableOutstandingReport"
import PurchaseReport from "./purchase/purchaseReport";
import PayableOutstandingSummaryReport from "./payable_outstanding_summary/payableOutstandingSummaryReport"
import PettyCashReport from "./petty_cash/pettyCashReport"
import PrepaidDepositReport from "./prepaid_deposit/prepaidDepositReport"
import FlowOfPrepaidDepositReport from "./flow_of_prepaid_deposit/selectRoom"
import AdvanceReport from "./advance/advanceReport"
import AcceptCreditNoteReport from "./accept_credit_note/acceptCreditNoteReport"
import OtherExpenseReport from "./other_expense/otherExpenseReport";
import payableClassifiedReport from "./payable_classified/payableClassifiedReport"
import PayableMovement from "./payable_movement/selectRoom"


class Report extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={Dashboard}/>
                <PrivateRoute path={`${this.props.match.url}/pay_summary`} component={PaySummaryReport}/>
                <PrivateRoute path={`${this.props.match.url}/payable_outstanding`} component={PayableOutstandingReport}/>
                <PrivateRoute path={`${this.props.match.url}/payable_outstanding_summary`} component={PayableOutstandingSummaryReport}/>
                <PrivateRoute path={`${this.props.match.url}/purchase`} component={PurchaseReport}/>
                <PrivateRoute path={`${this.props.match.url}/petty_cash`} component={PettyCashReport}/>
                <PrivateRoute path={`${this.props.match.url}/prepaid_deposit`} component={PrepaidDepositReport}/>
                <PrivateRoute path={`${this.props.match.url}/flow_of_prepaid_deposit/:status`} component={FlowOfPrepaidDepositReport}/>
                <PrivateRoute path={`${this.props.match.url}/advance`} component={AdvanceReport}/>
                <PrivateRoute path={`${this.props.match.url}/accept_credit_note`} component={AcceptCreditNoteReport}/>
                <PrivateRoute path={`${this.props.match.url}/other_expense`} component={OtherExpenseReport}/>
                <PrivateRoute path={`${this.props.match.url}/payable_classified`} component={payableClassifiedReport}/>
                <PrivateRoute path={`${this.props.match.url}/payable_movement/:status`} component={PayableMovement}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Report;
