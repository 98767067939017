
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import Select from 'react-select';
import { Redirect } from 'react-router';
import SwitchLanguages from '../../components/header/switchLanguages';
import { Translation } from 'react-i18next';
import '../../accounting/collectionLawFirm/regisCollectionLawFrim.scss';
import getqueryAllRegisterCollectionLawFirmSite from "./query/queryAllRegisterCollectionLawFirmSite"
const _ = require("lodash");

const query = graphql`
       query collectionLawFrimDocsStatusQuery($siteId:Int){
        summaryRegisterCollectionLawFirm(siteId:$siteId)
       }
`;

const docsName = [
    { name: 'docs1', label: `1. Attorney Appointment Document`},//หนังสือแต่งตั้งทนายความ
    { name: 'docs2', label: `2. Registered Corporate By laws`}, // ข้อบังคับนิติบุคคลฉบับจดทะเบียนสำนักที่ดิน
    { name: 'docs3', label: '3. List of the current corporate board members registered with the Land Office'},//3. รายชื่อคณะกรรมการนิติบุคคลชุดที่อยู่ในวาระอำนาจบริหารปัจจุบันฉบับจดทะเบียนกับสำนักที่ดิน
    { name: 'docs4', label: '4. Copy of the national identification card of the corporate board chairman, along with a sample of their signature'},
    { name: 'docs5', label: '5. Copy of the national identification card of the corporate manager, along with a sample of their signature'},
    { name: 'docs6', label: '6. Resolution to amend, change, or add to the corporate bylaws (if applicable)'},
];


class CollectionLawFrimDocsStatus extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            getAllSite: [],
            emailData:[],
            documentData:[],
            siteID:null
        }

        this.getAllSite = this.getAllSite.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.onChangeSite = this.onChangeSite.bind(this);
    }

    componentDidMount() {
        this.getAllSite()
    }

   componentWillMount() {

   }

    handleInputChange(event){
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    onChangeSite = (e) => {
        this.setState({
            siteID: e.value,
        }, () => this.fetchData())
    }

    async fetchData() {
        let mySiteId = this.state.siteID
        await fetchQuery(environment, query, { siteId: mySiteId }).then((response) => {
            if(response.summaryRegisterCollectionLawFirm){
               let data =  JSON.parse(response.summaryRegisterCollectionLawFirm)
               this.setState({
                    emailData : data.email,
                    documentData : data.document
               })
            }
        })
    }

    getAllSite() {
        fetchQuery(environment, getqueryAllRegisterCollectionLawFirmSite()).then(data => {
            let data_clone = _.cloneDeep(data);
            let getAllSiteData = data_clone?.allRegisterCollectionLawFirmSite?.edges?.map((n) => (
                { ...n.node, value: n.node.site.id, label: n.node.site.project?.edges[0]?.node?.nameTh }))

            let optionSearch = [...getAllSiteData]

            this.setState({
                getAllSite: optionSearch,
            })
        }
        );
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to='/accounting/collection-law-firm/list/law' />
        }
        const {documentData } = this.state;

        return (
            <>
               <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #f7f7f7;
                            overflow-x: hidden;
                            text-align: center;
                            font-size: 14px;
                            font-family: 'Kanit';
                            font-weight: 400;
                        }
                    `
                    }]}>
                </Helmet>
                 <div id='regisCollectionLawFrim'>
                    <form>
                        <div className="row background-top" >
                            <div className="container"> 
                                <div className='law-lang-container'>
                                    <SwitchLanguages isLawlang={false} />
                                </div>
                                <div className="col mt-3 justify-content-center">
                                    <div className="row">
                                        <div className="col mb-4 mt-4">
                                            <div className='d-flex justify-content-center'>
                                                <img src={process.env.PUBLIC_URL + '/images/logo/SVM-Logo.png'} style={{width:'220px'}} className='pr-6'/>
                                                <img src={process.env.PUBLIC_URL + '/images/logo/house-condo-lawyer-logo.png'} />
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Translation>
                                                {
                                                    t => <h3>{t('collectionLawFirm:Register for the debt collection service by an attorney (E-Notice)')}</h3>
                                                }
                                            </Translation>  
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Translation>
                                                {
                                                    t => <p style={{color:'#FFFFFF'}}>{t('collectionLawFirm:Terms and Conditions for Using Debt Collection Attorney Service')}</p>
                                                }
                                            </Translation>  
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-10 justify-content-center background-box">
                                <div>
                                <div className='row'>
                                <div className='col-6'/>
                                <div className='col-6'>
                                    <div className='pt-2 pb-2'>
                                        <div style={{ position: 'relative', textAlign: 'left', width: '100%' }}>
                                            <Select
                                                options={this.state.getAllSite}
                                                onChange={(e) => this.onChangeSite(e)}
                                                placeholder="ค้นหาไซด์"
                                                name="siteID"
                                            /> 
                                        </div>
                                    </div>
                                </div> 
                                </div>
                                <div className='row'>
                                <div className='col-4'>
                                <Translation>
                                    {
                                        t => <p>{t('collectionLawFirm:Specify the personnel you want to receive notifications')}:</p>
                                    }
                                </Translation>  

                                </div>
                                <div className='col-5'>      
                                  {
                                    _.map(this.state.emailData, (item, index) => {
                                        return (
                                        <>
                                        <div className='pt-2 pb-2'>
                                            <div style={{ position: 'relative', textAlign: 'left', width: '100%' }}>
                                            <Select defaultInputValue={`(${item.juristic?.user?.username}) ${item?.name}`} isDisabled={true}></Select>
                                            </div>
                                         </div>
                                        </>
                                        )
                                  }  ) 
                                }    
                                </div> 
                                </div>
                                 <Translation>
                                    {
                                    t => (
                                    _.map(docsName, (item, index) => {
                                        let typeFile = documentData[index]?.file_name?.split(".")
                                        let typeFileLength = typeFile && typeFile.length
                                        let typeICONFile = typeFile && typeFile[typeFileLength - 1]

                                        return (
                                        <>
                                            <div className='line-upload-docs'> 
                                            <div className='row'>
                                                    <div className='col mt-2 text-left m-2'>
                                                        {t(`collectionLawFirm:${item.label}`)} {(index+1) !== 6 && <span className='text-danger'>*</span>} 
                                                        <div className="mt-2 text-left">
                                                     
                                                             <div className='d-flex'>
                                                             { documentData[index]?.file_name?.length > 0 ? <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeICONFile + "Icon.png"} style={{ width: '25px' }} /> : '' }
                                                                <a href={documentData[index]?.file_url} target='_blank'>{documentData[index]?.file_name ? documentData[index]?.file_name : ''}</a>
                                                                
                                                            </div>  
                                                        </div>
                                                    </div>
                                                    <div className='col pt-2'>
                                                        {
                                                            <div className="uploadBTN" style={{ display: 'inline-flex'}}>
                                                                <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                                                    {/* <button className={"btn"}  disabled={this.props[item.name].length < 1 ? false : true}>
                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />
                                                                        {t(`collectionLawFirm:Only PDF ,JPG ,PNG`)}
                                                                    </button>
                                                                    <input type="file" name='file' id="uploadFile" className='form-control uploadFile'
                                                                        accept='.pdf,.png,.jpg' onChange={(event) =>this.props.uploadFile(event,index+1)} 
                                                                        /> */}
                                                                </label>
                                                            </div>
                                                        }
                                                    </div>
                                            </div>
                                            </div>
                                        </>
                                        )
                                    })
                                    )
                                    }
                                 </Translation>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}

export default CollectionLawFrimDocsStatus;