import React, { forwardRef } from "react";
import "../../styles/commonArea.scss";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "../../../../libs/styles/customDatePickerWidth.scss";
import "../../../../libs/styles/styleColor.scss"
import ICON from './iconBooking'
import { Translation } from "react-i18next";

const PublicServiceOpening = ({
  handleChange,
  addHoliday,
  state: {
    openOnEveryday,
    openOnEverydaySt,
    openOnEverydayEn,
    openonEveryday24,
    holiday,
    holiday_status,
    setDateToOpen,
  },
  onChangeTime
}) => {
  const ExampleCustomInput = forwardRef(({ value, onClick, color }, ref) => (
    <Translation>
      {t =>
        <div
          className="button-add cursor"
          onClick={onClick}
          ref={ref}
          style={{ color: color }}
        >
          + {t("newBookingSingha_Create_step1:Add a special holiday")}
        </div>
      }
    </Translation>

  ));

  return (
    <Translation>
      {
        t =>
          <div className="booking-form mb-5  mt-10">
            <div className="switch-languages-div">
              <h2>{t("newBookingSingha_Create_step1:Date and opening hours")}</h2>
            </div>

            {/* เปิดบริการทุก{i18n.t("PageList:Daily")} และเวลาเดิม */}
            <div className="claim">
              <div className="create btnSwitch">
                <label className="switch mt-5 mr-3" style={{ width: 43, height: 20 }}>
                  <input type="checkbox"
                    name="openOnEveryday"
                    defaultChecked={openOnEveryday}
                    onChange={handleChange}
                  />
                  <span className={openOnEveryday ? "sliderNew round selected" : "sliderNew round"}></span>
                </label>
                <label className="headerLabel18 fw-400">{t("newBookingSingha_Create_step1:The same daily opening time")}</label>
              </div>
            </div>
            <div className="text-grey">
              {
                openOnEveryday ?
                  t("newBookingSingha_Create_step1:If this button is turned on, the facility is open every day at the same time.") :
                  t("newBookingSingha_Create_step1:Turn off the daily open button to customize the date and time")
              }
            </div>

            {
              openOnEveryday ?
                <div className="row mt-5">
                  <div className="col-12 fixRequestFast">
                    <React.Fragment>
                      {/* // Time Start */}
                      <div style={{ width: 192 }} className="float-left">
                        <DatePicker
                          value={new Date(`2023-01-01T${openOnEverydaySt}`).getTime()}
                          selected={new Date(`2023-01-01T${openOnEverydaySt}`).getTime()}
                          onChange={(time) => onChangeTime(`startTime`, `openOnEverydaySt`, time)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control ml-0 searchDate icon_ArrowDown"
                          disabled={openonEveryday24}
                        // minTime={setHours(setMinutes(new_open[`${name}_end`], 0), 0)}
                        // maxTime={setHours(
                        //   setMinutes(
                        //     new_open[`${name}_end`],
                        //     new Date(new_open[`${name}_end`]).getMinutes()
                        //   ),
                        //   new Date(new_open[`${name}_end`]).getHours()
                        // )}
                        />
                      </div>
                      {/* //  Time End */}
                      <div style={{ width: 210 }} className="float-left">
                        <DatePicker
                          value={new Date(`2023-01-01T${openOnEverydayEn}`).getTime()}
                          selected={new Date(`2023-01-01T${openOnEverydayEn}`).getTime()}
                          onChange={(time) => onChangeTime(`endTime`, `openOnEverydayEn`, time)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control searchDate icon_ArrowDown"
                          disabled={openonEveryday24}
                        // minTime={openOnEverydaySt}
                        // maxTime={setHours(
                        //   setMinutes(
                        //     new_open[`${name}_end`],
                        //     new Date(new_open[`${name}_end`]).getMinutes()
                        //   ),
                        //   new Date(new_open[`${name}_end`]).getHours()
                        // )}
                        />
                      </div>

                      {/* Open 24 Hours */}
                      <div style={{ width: 180 }} className="float-left">
                        <div className="usedAll">
                          <label className="containerRadio" style={{ marginLeft: '35px' }}>{t("newBookingSingha_Create_step1:Open 24 hours")}
                            <input type="checkbox" name="openonEveryday24" defaultChecked={openonEveryday24}
                              onChange={(val) => onChangeTime(`openonEveryday24`, `openonEveryday24`, val.target.checked)} />
                            <span className="checkmarkCheckBox"></span>
                          </label>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                </div>
                :
                <React.Fragment>
                  {/* จันทร์ - อาทิตย์ */}
                  <div className="mt-5">
                    {
                      setDateToOpen?.map((day, index_setDateToOpen) => {
                        let startTime = new Date(`2023-01-01T${day.startTime}`).getTime()
                        let endTime = new Date(`2023-01-01T${day.endTime}`).getTime()
                        return (
                          <div className="row mb-3" key={index_setDateToOpen}>
                            <div className="col-2 mt-2">
                              {t("newBookingSingha_Create_step1:" + day.label)}
                            </div>
                            <div className="col-10 fixRequestFast">

                              {
                                !day.startTime && !day.endTime ?
                                  <React.Fragment>
                                    <div style={{ width: 420 }} className="float-left pl-4 usedAll">
                                      <label className="labelInSvg">
                                        <span style={{ textAlign: "center", verticalAlign: "middle" ,color: "gray" }}>
                                          {t("newBookingSingha_Booking:Closed")}
                                        </span>
                                      </label>
                                      <ICON.iconClose />
                                    </div>

                                    <div className="float-left" style={{ marginTop: 2, marginLeft: 14 }}
                                      onClick={() => onChangeTime(`add`, `${day.label}`, "")}>
                                      <ICON.iconAdd />
                                    </div>
                                  </React.Fragment>

                                  :
                                  <React.Fragment>
                                    {/* // Time Start */}
                                    <div style={{ width: 192 }} className="float-left colorUse">
                                      <DatePicker
                                        value={startTime}
                                        selected={startTime}
                                        onChange={(time) => onChangeTime(`startTime`, `${day.label}`, time)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        className="form-control ml-0 searchDate icon_ArrowDown fs-16 pl-3"
                                        disabled={day.setOpen24}
                                      // minTime={setHours(setMinutes(new_open[`${name}_end`], 0), 0)}
                                      // maxTime={setHours(
                                      //   setMinutes(
                                      //     new_open[`${name}_end`],
                                      //     new Date(new_open[`${name}_end`]).getMinutes()
                                      //   ),
                                      //   new Date(new_open[`${name}_end`]).getHours()
                                      // )}
                                      />
                                    </div>
                                    {/* //  Time End */}
                                    <div style={{ width: 210 }} className="float-left colorUse">
                                      <DatePicker
                                        value={endTime}
                                        selected={endTime}
                                        onChange={(time) => onChangeTime(`endTime`, `${day.label}`, time)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        className="form-control searchDate icon_ArrowDown fs-16 pl-3"
                                        disabled={day.setOpen24}
                                      // minTime={setHours(setMinutes(new_open[`${name}_end`], 0), 0)}
                                      // maxTime={setHours(
                                      //   setMinutes(
                                      //     new_open[`${name}_end`],
                                      //     new Date(new_open[`${name}_end`]).getMinutes()
                                      //   ),
                                      //   new Date(new_open[`${name}_end`]).getHours()
                                      // )}
                                      />
                                    </div>

                                    {/* Open 24 Hours */}
                                    <div style={{ width: 180 }} className="float-left">
                                      <div className="usedAll">
                                        <label className="containerRadio" style={{ marginLeft: '35px' }}>{t("newBookingSingha_Create_step1:Open 24 hours")}
                                          <input type="checkbox" name="open24Hours" defaultChecked={day.setOpen24}
                                            onChange={(val) => onChangeTime(`setOpen24`, `${day.label}`, val.target.checked)} />
                                          <span className="checkmarkCheckBox"></span>
                                        </label>
                                      </div>
                                    </div>

                                    {/* Delete */}
                                    <div className="float-left mt-1">
                                      <div className="cursor" onClick={() => onChangeTime(`deleted`, `${day.label}`, "")}>
                                        <ICON.iconDelte />
                                      </div>
                                    </div>
                                  </React.Fragment>
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </React.Fragment>
            }



            {/* เพิ่ม{i18n.t("PageList:Daily")}หยุดพิเศษ */}
            <div className="form-check mt-5 usedAll">
              <input
                className="form-check-input"
                type="checkbox"
                name="holiday_status"
                id="specialHoliday"
                value="option2"
                onChange={handleChange}
                defaultChecked={holiday_status == true}
              />
              <label className="form-check-label text headerLabel18 mt-0" htmlFor="specialHoliday">
                {t("newBookingSingha_Create_step1:special holiday")}
              </label>

              <div className="text-grey mt-3 ml-0">
                {t("newBookingSingha_Create_step1:Select a day to set the day that the central service is closed. (Ex. 1 January is a New Year Day)")}
              </div>



              <div className="mt-2">
                <div className="d-flex flex-row align-items-center text "></div>
                <div>
                  <ul className="list-group list-group-flush col-8 ">
                    {holiday.map((item, index) => {
                      let dayHoliday = format(new Date(item.holidays), "DD")
                      let yearHoliday = format(new Date(item.holidays), "YYYY")
                      let monthHoliday = format(new Date(item.holidays), "MMMM")

                      return (
                        <li
                          className="list-group-item  d-flex justify-content-between col-6 "
                          key={index}
                          style={{
                            borderBottomColor: "red",
                            borderBottomWidth: 1,
                            color: "#B3B3B3",
                          }}
                        >
                          {`${dayHoliday} ${t("materWater:" + monthHoliday)} ${yearHoliday}`}
                          <div
                            className="cursor"
                            disabled={!holiday_status}
                            onClick={() => addHoliday("remove", index)}
                            style={{ color: "#B3B3B3" }}
                          >
                            X
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="mt-3">
                  <DatePicker
                    className=""
                    disabled={holiday_status == false}
                    selected={new Date()}
                    onChange={(date) => addHoliday(date, "add")}
                    customInput={
                      <ExampleCustomInput
                        color={holiday_status ? "" : "#B3B3B3"}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
      }
    </Translation >
  );
};

export default PublicServiceOpening;
