import React, { Component } from 'react'
import _ from "lodash"
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import {graphql} from "babel-plugin-relay/macro";
import { fetchQuery } from 'react-relay';
import environment from '../../env/environment';
import Swal from 'sweetalert2';
import PieChart from "./../pieChart";
import {Translation} from 'react-i18next';
import Dropdown  from 'react-bootstrap/Dropdown';
import ReportDashboardSummaryExcel from "./reportDashboardSummaryExcel";

const query = graphql`
    query reportDashboardSummaryQuery ($startYear: Int ,$startMonth: Int) {
        productViewer {
            productSummaryByStatusYearAndMonth(year: $startYear , month: $startMonth, productType: "service", productType2: "servicefine")
        }
    }
`;

const month_list_arr = ["-","January","February","March","April","May","June","July","August","September","October","November","December"];


export default class reportDashboardSummary extends Component {

        constructor(props) {
          super(props);
          this.state = {
              summary : {},
              isLoading : true
          }
        }

      print() {
        window.print();
      }

      componentDidMount(){
        fetchQuery(environment, query,{
            startYear: this.props.location.state.year,
            startMonth: this.props.location.state.month
        }).then(async (data) => {
            this.setState({summary:data.productViewer},
              ()=>{this.setState({isLoading: false});});
        }).catch((error) => {
            Swal.fire("Can't login at this time. Please try agian", "", 'error').then((es) => {
                return
            })
        })
      }

 
      render() {

        return (
          <React.Fragment>
            <Helmet
              style={[{
                "cssText": `
                  body {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    background-color: #FAFAFA;
                  }
                  
                  * {
                    box-sizing: border-box;
                    -moz-box-sizing: border-box;
                  }
                
                  .page {
                    width: 297mm;
                    height: 210mm;
                    padding: 5mm;
                    margin: 10mm auto;
                    border: 1px #D3D3D3 solid;
                    border-radius: 2px;
                    background: white;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                  }
                  
                  
                  @page {
                    size: landscape;
                    margin: 0;
                  }
                  @media print {
                    html, body {
                      width: 297mm;
                      height: 210mm;
                    }

                    .page {
                      margin: 0;
                      border: initial;
                      border-radius: initial;
                      width: initial;
                      min-height: initial;
                      box-shadow: initial;
                      background: initial;
                      page-break-after: always;
                    }

                    .reportDashboardBody{


                    }
                  }
                `
              }]}>
              <meta charSet="utf-8" />
              <title>Dashboard Summary Report</title>
              <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
            </Helmet>
    
            <div className="print-top-menu">
              <div className="logo">
                <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
              </div>
              <div className="d-flex justify-content-center mt-6">
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-dashboard-button" bsPrefix="">
                      <Translation>
                          {
                            t => <>{t('dashboard:SaveAs')}</>
                          }
                      </Translation>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={this.print}>                                      
                            PDF
                        </Dropdown.Item>
                    <ReportDashboardSummaryExcel
                      year={this.props.location.state.year}
                      month={this.props.location.state.month}
                      data={this.state.summary}
                      />
                                  
                    </Dropdown.Menu>
                </Dropdown>
                <div className="ml-2">
                  <Link 
                    to="/home">
                      <button type="button" class="btn btn-secondary">
                          ย้อนกลับ
                      </button>
                  </Link>
                </div>
              </div>

            </div>
              
            <div className="page">
              <div className="print-interest-report mb-5">
                <div className="subpage">
                  <div className="head">
                    <div className="font-weight-bold">
                      <div><h3>สรุปยอดรวมการเรียกเก็บเงิน</h3></div>
                      <div><h5>ประจำปี  {this.props.location.state.year}</h5></div>
                      <div>
                        <h5>รอบเดือน&nbsp;&nbsp;
                          <Translation>
                          {
                              t => t(`dashboard:${month_list_arr[this.props.location.state.month]}`)
                          }
                          </Translation>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>

              <div className="reportDashboardBody ml-6">
                <div className="row mt-6">
                {_.map(this.state.summary.productSummaryByStatusYearAndMonth,(item,index) =>{
                  let product = JSON.parse(item);
                  if (product.col_all_total >0) {
                  return (
                              <div className='ml-6' style={{width:'47mm'}} key={index}>
                                <div className="chart text-center w-auto">
                                    <PieChart data={product}/>
                                </div>
                              </div>
                        )
                  }
                  else{
                    return <></>  
                  }
                })          
                }
                </div>
              </div>                


            </div>

            

          </React.Fragment>
        )
      }

}
