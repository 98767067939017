import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../env/environment";
import {QueryRenderer} from "react-relay";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import {Translation} from 'react-i18next';
import Loading from "../libs/loading";

const query = graphql`
    query ownerChangeQuery{
        residentialOwnership(dashboard:true, first:4){
            totalCount
            edges {
                node {
                    id
                    name
                    ownership {
                        edges {
                            node {
                                id
                                name
                                status
                                ownershipDate
                            }
                        }
                    }
                }
            }
        }
    }
`;

class OwnerChange extends React.Component {

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                cacheConfig={{use_cache: false}}
                render={({error, props}) => {

                    if (error) {
                        return <div className="alert alert-danger"
                                    role="alert">{error.message}</div>;
                    } else if (props) {
                        return <div className="table-responsive">
                        <table className="table table-hover">
                            <thead className="thead-light">
                            <tr>
                                <Translation>
                                    {
                                        t => <th className="text-center">{t('dashboard:Date')}</th >
                                    }
                                </Translation>
                                <Translation>
                                    {
                                        t => <th>{t('dashboard:Room')}</th >
                                    }
                                </Translation>
                                <Translation>
                                    {
                                        t => <th>{t('dashboard:Name - Surname')}</th >
                                    }
                                </Translation>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {props.residentialOwnership?.edges.map((ownership_dashboard) =>
                                 <tr key={ownership_dashboard.node.id}>
                                     <td width="130">
                                         {ownership_dashboard.node.ownership.edges.map((ownership_date) =>
                                             <div key={ownership_date.node.id}>
                                                 {format(ownership_date.node.ownershipDate, 'DD/MM/YYYY', {locale: thLocale})} &nbsp;
                                             </div>
                                         )}
                                     </td>
                                     <td><span>{ownership_dashboard.node.name}</span></td>
                                     <td>
                                         {ownership_dashboard.node.ownership.edges.map((ownership_name) =>
                                             <div key={ownership_name.node.id}>
                                                 {ownership_name.node.name} &nbsp;
                                             </div>
                                         )}
                                     </td>
                                     <td>
                                         {ownership_dashboard.node.ownership.edges.map((ownership_status) =>
                                             <div key={ownership_status.node.id}>
                                                 {
                                                     ownership_status.node.status === "MOVE_IN" ?
                                                         <span>
                                                             <img src={process.env.PUBLIC_URL + '/images/icons/ownership-active.png'}
                                                                  alt="ownership-active"/>
                                                         </span>
                                                         :
                                                         ownership_status.node.status === "MOVE_OUT" &&
                                                         <span>
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/ownership-inactive.png'}
                                                                 alt="ownership-inactive"/>
                                                         </span>
                                                 }
                                            </div>
                                         )}
                                     </td>
                                 </tr>
                             )}
                            </tbody>
                        </table>
                        </div>
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default OwnerChange;
