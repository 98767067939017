import '../../../document/styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {QueryRenderer} from "react-relay";
import '../debtFreeRequest.scss'
import DebtFreeRequest from './debtFreeRequest';
import Loading from "../../../libs/loading"
const query = graphql`
query wrapperDebtFreeRequestQuery($debsFreeCertificationRequestId: [String]){
    selfProject{
        id
        name
        address
        logo
        juristicContactNumber
        keyProjectQr
        bankCompCode
        bankServiceCode
        taxIdNumber
        typeOfProject
        postcodeProject
        provinceProject
        districtProject
        subDistrictProject
    }
    allDebsFreeCertificationRequest(debsFreeCertificationRequestId:$debsFreeCertificationRequestId){
        edges{
            node{
                id
                docNumber 
                fee
                charge
                phone
                added
                addressContact
                address
                copyIdCard
                copyHouse
                copyApartment
                copySurnameChange
                otherDocument
                otherDocumentDescription
                attorney
                attorneyBook
                waterAndElectric
                requestingDate
                startSuspend
                endSuspend
                waiting
                lifetime
                nameTransfer
                phoneTransfer
                tenant {
                    id 
                    firstName
                    lastName
                    nameTitle
                }
                contact{
                    id
                    name
                    firstName
                    lastName
                    residential {
                        size
                    }
                }
            }
        }
    }
}`;



class WrapperDebtFreeRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: props.location.state.selected,
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Debt Free Certification Request</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu" id="debtRequest">
                    <div className="tab-print form-inline row">
                        <div className="d-flex justify-content-center form-inline col-3">
                            <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                        </div>
                        <div className="col-3">
                            <button type="button"  id ="debt-free-certification" className="modal-confirm" onClick={() => this.print()}>
                                <img src={process.env.PUBLIC_URL + '/images/icons/printer-white.png'} alt="plus" className="mr-1" style={{width:'13px' ,height:'13px'}}/> <span>พิมพ์</span>
                            </button>
                        </div>
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{debsFreeCertificationRequestId: this.state.selected}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return (
                                <React.Fragment>
                                    {props.allDebsFreeCertificationRequest.edges.map((debtFree , index) => {
                                        return (
                                            <DebtFreeRequest props={debtFree} selfProject={props.selfProject} key={index}/>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        }
                        return <div className="text-center"><Loading/></div>;

                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperDebtFreeRequest;
