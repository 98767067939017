import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import NoMatch from "../../../components/noMatch";
import {Route, Switch} from "react-router-dom";
import OtherReceiverList from "./otherReceiverList";
import OtherExpenseCreate from "./otherReceiverCreate";
import OtherReceiverView from "./otherReceiverView";

class OtherReceiver extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={OtherReceiverList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status`} component={OtherExpenseCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/view/:id/:ref_transaction`} component={OtherReceiverView}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default OtherReceiver;