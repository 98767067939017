import React from 'react'
import { Translation } from 'react-i18next'
import { Link } from 'react-router-dom'
import StockManageTopmenuNavigation from './stockMenagementTopmenuNavigation'
import DatePickerNoti from '../../accounting/finance/depositInterest/datePickerNoti'
import _ from "lodash"
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import { format } from 'date-fns'
import ComponentPagination from "../../libs/componentPagination";
import Pagination from "../../libs/newPagination";
import Loading from "../../libs/loading"
import Swal from 'sweetalert2'
import createAndUpdateRequisitionNote from './mutation/createAndUpdateRequisitionNote';
import ModalVoidStockManagement from './modalVoid/modalVoidStockManagement'
import getNameResidential from "../../libs/getNameResidential"
import "./style/stockMenagement.scss"
import i18next from 'i18next'

const query = graphql`
  query stockMenagementTableQuery($startDate: DateTime, $endDate: DateTime, $search: String, $status: String, $first: Int, $last: Int){
    allRequisitionNote(startDate: $startDate, endDate: $endDate, search: $search, status: $status, first: $first, last: $last){
      edges{
        node{
          id
          status
          docNumber
          typeRequisition
          typeNote
          issuedDate
          recipient
          firstNameRecipient
          lastNameRecipient
          username
          objective
          remark
          noteVoid
          creatorVoid
          contact{
            id
            name
            firstName
            lastName
          }
          juristict{
            id
            firstName
            lastName
          }
          requisitionList{
            edges{
              node{
                id
                numberOfPieces
                numberOfBalance
                noNumber
                productAndService{
                  id
                  noNumber
                  name
                }
              }
            }
          }
          requisitionNoteDocuments{
            edges{
              node{
                id
                fileName
                fileUpload
              }
            }
          }
        }
      }
      totalCount
    }
  }
`
export default class stockManagementTable extends ComponentPagination {

  constructor(props){
    super(props)

    this.state = {
      selectedId: [],
      checkAll : false,
      requisitionNoteLists: [],
      loading: false,
      startDate: null,
      endDate: null,
      textSearch: "",
      openModalVoid: false
    }
  }

  componentWillMount(){
    this.goFirst()
  }

  componentDidMount(){
    this.getData()
  }

  componentDidUpdate(prevProps, prevState){
    if( prevState.first !== this.state.first || prevState.last !== this.state.last || prevProps.status !== this.props.status){
      this.getData()
    }
    if(this.state.selectedId !== prevState.selectedId && this.state.selectedId.length === 0){
      this.setState({
        checkAll : false
      })
    }
  }

  getData = () => {
    this.setState({loading: true})

    fetchQuery(
      environment, 
      query,
      {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        first: this.state.first,
        last: this.state.last,
        search: this.state.textSearch,
        status: this.props.status === "all" ? "" : this.props.status
      }
    ).then((res) => {
      this.setState({requisitionNoteLists: res.allRequisitionNote, loading: false})
    })
  }

  onCheckAll = (props) => {
    let selected = []
    this.setState({checkAll : !this.state.checkAll} , () => {
        if(this.state.checkAll){
            _.forEach(props, (item) =>{
                if(item.node.status !== "VOID"){
                  selected.push(item.node.id)
                }
            })
            this.setState({
                selectedId : selected
            })
        }else{
            this.setState({
                selectedId : []
            })
        }
    })
  }

  onCheck = (id) => {
    let index = this.state.selectedId.indexOf(id);
    let selected = _.cloneDeep(this.state.selectedId)
    if(index === -1){
        selected.push(id)
    }else{
        selected.splice(index, 1)
    }
    this.setState({
        selectedId : selected
    })
  }

  getStatus = (statusInput) => {
    let textResult = ""

    switch (statusInput) {
      case "ACTIVE":
        textResult = "ปกติ"
        break;
      case "VOID":
        textResult = "รายการที่ยกเลิก"
        break;
    }

    return (textResult)
  }

  getTypeNote = (typeNoteInput) => {
    let textResult = ""

    switch (typeNoteInput) {
      case "juristic":
        textResult = "เบิกใช้งานส่วนกลาง"
        break;
      case "tenant":
        textResult = "เบิกใช้งานลูกบ้าน"
        break;
      default:
        textResult = "เบิกตามสิทธิ์"
        break;
    }

    return textResult
  }

  handleChange = (e) => {
    let { name, value } = e.target;

    this.setState({ [name] : value })
  }

  handleSearch = () => {
    this.getData()
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.getData()
    }
  }

  onDeleteRequisitionNote = async () => {
    let listRequisitionNote = []

    this.setState({openModalVoid: false})

    if(this.state.selectedId.length > 0){
      let {value: void_remark } = await Swal.fire({
        title: i18next.t("stockCreateAndUpdate:Note"),
        input: "text",
        showCancelButton: true,
        confirmButtonColor: "#1567B4",
        cancelButtonColor: "#d33",
        cancelButtonText:  i18next.t("Allaction:cancel"),
        confirmButtonText:  i18next.t("Allaction:Confirm"),
        inputValidator: (value) => {
          if (!value) {
            return i18next.t("Allaction:Please fill input before cancel")
          }
        },
      });
      if (void_remark) {
        _.forEach(this.state.requisitionNoteLists.edges, (item, index) => {
          if(this.state.selectedId.includes(item.node.id)){
  
            listRequisitionNote.push({
              variables: {
                input: {
                  requisitionNote: {
                    requisitionNoteId: item.node.id,
                    typeRequisition: _.lowerCase(item.node.typeRequisition),
                    typeNote:  _.lowerCase(item.node.typeNote),
                    contactId: item.node.contact?.id || "" ,
                    issuedDate: format(item.node.issuedDate, "YYYY-MM-DD"),
                    recipient:  _.lowerCase(item.node.recipient),
                    firstNameRecipient: item.node.firstNameRecipient || "",
                    lastNameRecipient:  item.node.lastNameRecipient || "",
                    username: item.node.username || "",
                    objective: item.node.objective || "",
                    remark: item.node.remark || "",
                    noteVoid: void_remark,
                    status: "void",
                    requisitionList: [],
                    requisitionNoteDocuments: []
                  }
                }
              }
            })
  
          }
        })

        _.forEach(listRequisitionNote, (itemDelete, index) => {

          if(listRequisitionNote.length - 1 === index){
            createAndUpdateRequisitionNote(itemDelete.variables, 
              (res) => {
                this.setState({ selectedId: [], checkAll: false }, 
                  () => {
                    this.getData()
                  }
                )
              }, 
              () => {this.setState({ selectedId: [], checkAll: false }, () => this.getData())} 
            )
          }else{
            createAndUpdateRequisitionNote(itemDelete.variables, (res) => console.log(res), () => true )
          }
        })

      }
    }

  }

  handleOpenModal = () => {
    this.setState({
      openModalVoid: true
    })
  }

  handleCloseModal = () => {
    this.setState({
      openModalVoid: false
    })
  }

  render() {
    return (
      <Translation> 
        { t=>
      <div id="stockManagementTable">
        <div className="row">
          <div className="col-6"/>
          <div className="col-6">
            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: "accounting_productstock_create"}) &&
              <Link to="/stockandsupplies/stockManagement/form/input">
                <button type="button" className="btn btn-create float-right mr-2">
                  <img className="mr-2" src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus" />
                 
                      <span>{t("PageList:Create requisition form")}</span> 
                 
                </button>
              </Link>
            }
          </div>
        </div>

        <StockManageTopmenuNavigation All={t("PageList:All")} Cancled={t("PageList:Cancle")}/>

        <div className="row">
          <div className="col-12 col-lg-4 d-flex align-items-center">
            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: "accounting_productstock_print"}) &&
              <Link to={{
                  pathname: '/document/stockMenagement/all',
                  state: {requisitionNoteId: this.state.selectedId}
                }}>
                <button
                  type="button"
                  className="ml-2 btnPrint"
                >
                  <img style={{width: "50%"}} src={process.env.PUBLIC_URL + "/images/icons/iconPrintBlack.png"} alt="print" />
                </button>
              </Link>
            }
            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: "accounting_productstock_delete"}) &&
              <button
                type="button"
                className="ml-2 btnBin"
                onClick={this.handleOpenModal}
              >
                <img style={{width: "50%"}} src={process.env.PUBLIC_URL + "/images/icons/bin-outline.png"} alt="delete" />
              </button>
            }
          </div>
          <div className="col-12 col-lg-8 d-flex justify-content-end">
            <div className="d-flex align-items-center">
              <h5 className="m-0">{t('PageList:Date')}:</h5>
              <div
                className="calendars-wrapper mx-4"
                style={{ width: 160}}
              >
                <DatePickerNoti
                  selected={this.state.startDate}
                  name='startDate'
                  onChange={this.handleChange}
                  />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <h5 className="m-0">{t('PageList:To')}:</h5>
              <div
                className="calendars-wrapper mx-4"
                style={{ width: 160}}
              >
                <DatePickerNoti 
                  selected={this.state.endDate}
                  name='endDate'
                  onChange={this.handleChange}
                  />
              </div>
            </div>
            <div className="calendars-wrapper">
              <form>
                <input
                  type="text"
                  placeholder={t('PageList:Search')}
                  value={this.state.textSearch}
                  onChange={(event) => this.setState({textSearch: event.target.value})}
                  onKeyDown={this.handleKeyDown}
                  className="form-control inputSearch"
                />
              </form>
              <div
                className="calendars float-right"
                style={{ paddingRight: 25 }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/icons/search-outline.png"
                  }
                  alt="calendars"
                  style={{ width: "17.81px", height: "17.81px" }}
                  onClick={this.handleSearch}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.loading ? <Loading/>
          : <React.Fragment>
              <div className="row mt-5">
                <div className="col">
                  <div className="fade-up">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th className="text-center">
                              <input
                                type="checkbox"
                                checked={this.state.checkAll}
                                onChange={() => this.onCheckAll(this.state.requisitionNoteLists.edges)}
                                />
                            </th>
                            <th>{t('PageList:Doc No.')}</th>
                            <th>{t('PageList:Date')}</th>
                            <th>{t('PageList:Detail')}</th>
                            <th className="text-center">{t('PageList:Object')}</th>
                            <th>{t('PageList:Room No')}</th>
                            <th>{t('PageList:Name')}</th>
                            <th>{t('PageList:No.')}</th>
                            <th className="text-right">{t('PageList:Balance')}</th>
                            {this.props.status === "all" &&
                              <React.Fragment>
                                <th>{t('PageList:Status')}</th>
                                <th>{t('PageList:withdrawn by')}</th>
                              </React.Fragment>
                            }
                            {this.props.status === "void" &&
                              <React.Fragment>
                                <th>{t('PageList:Cancled By')}</th>
                                <th>{t('PageList:Reason Cancled')}</th>
                              </React.Fragment>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {_.map(this.state.requisitionNoteLists.edges, (item, index) => {
                            return (
                              <React.Fragment key={index}>
                                {_.map(item.node.requisitionList.edges, (itemRequisitionList, indexRequisitionList) => {
                                  return (
                                    <tr key={indexRequisitionList}>
                                      {indexRequisitionList === 0 
                                        ? <React.Fragment>
                                          {item.node.status === "VOID" ? 
                                            <td/>
                                            :
                                            <td className="text-center">
                                                <input 
                                                  type="checkbox" 
                                                  onChange={() => this.onCheck(item.node.id)} 
                                                  checked={this.state.selectedId.includes(item.node.id)}
                                                  />
                                            </td>
                                          }
                                            <td>
                                              {item.node.status === "ACTIVE" 
                                                ?  <Link to={{
                                                      pathname: `/stockandsupplies/stockManagement/createUpdateForm/edit/${_.lowerCase(item.node.typeNote || item.node.typeRequisition)}`,
                                                      state: { typeRequisition: _.lowerCase(item.node.typeRequisition), requisitionNoteID: item.node.id }
                                                      }}>
                                                      <span style={{color: "#40A3FF"}}>{item.node.docNumber}</span>
                                                    </Link>
                                                  
                                                : <span>{item.node.docNumber}</span>
                                              }
                                            </td>
                                          </React.Fragment>
                                        : <React.Fragment>
                                            <td/>
                                            <td/>
                                          </React.Fragment>
                                      }
                                      <td>{format(item.node.issuedDate, "DD/MM/YYYY")}</td>
                                      <td>{itemRequisitionList.node.productAndService.name}</td>
                                      <td className="text-center">{this.getTypeNote(_.lowerCase(item.node.typeNote))}</td>
                                      <td>{item.node.contact?.name || "-"}</td>
                                      <td>
                                        {item.node.typeRequisition === "UNIT" && (item.node.firstNameRecipient  || item.node.lastNameRecipient)
                                          ? getNameResidential(item.node.firstNameRecipient, item.node.lastNameRecipient)
                                          : item.node.typeRequisition === "PROJECT" && _.lowerCase(item.node.typeNote) === "tenant"
                                            ? getNameResidential(item.node.contact.firstName, item.node.contact.lastName)
                                            : item.node.typeRequisition === "PROJECT" && _.lowerCase(item.node.typeNote) === "juristic"
                                              ? getNameResidential(item.node.juristict.firstName, item.node.juristict.lastName)
                                              : "-"
                                        }
                                      </td>
                                      <td className={itemRequisitionList.node.noNumber ? "text-left" : "text-center"}>{itemRequisitionList.node.noNumber || "-"}</td>
                                      <td className="text-right">{itemRequisitionList.node.numberOfBalance}</td>
                                      {this.props.status === "all" &&
                                        <React.Fragment>
                                          <td>{this.getStatus(item.node.status)}</td>
                                          <td>{item.node.juristict.firstName} {item.node.juristict.lastName}</td>
                                        </React.Fragment>
                                      }
                                      {this.props.status === "void" &&
                                        <React.Fragment>
                                          <td>{item.node.creatorVoid}</td>
                                          <td>{item.node.noteVoid}</td>
                                        </React.Fragment>
                                      }
                                    </tr>
                                  )
                                })}
                              </React.Fragment>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <Pagination
                  changePage={this.changePage}
                  first={this.state.first}
                  last={this.state.last}
                  totalCount={this.state.requisitionNoteLists.totalCount}
                  />
              </div>

              {this.state.openModalVoid && 
                <ModalVoidStockManagement 
                  open={this.state.openModalVoid}
                  handleVoid={this.onDeleteRequisitionNote}
                  handleCloseModal={this.handleCloseModal}/>
              }

            </React.Fragment>
        }
      </div>

        }
      </Translation>
    )
  }
}
