/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type cancleRegulationQueryVariables = {|
  search?: ?string,
  order?: ?string,
  startdate?: ?any,
  enddate?: ?any,
  first?: ?number,
  last?: ?number,
  regulationid?: ?string,
|};
export type cancleRegulationQueryResponse = {|
  +cancelRegulation: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +subject: ?string,
        +description: ?string,
        +creator: string,
        +datePosted: ?any,
        +dateSchedule: ?any,
        +status: string,
        +type: ?string,
        +previewImage: ?string,
      |}
    |}>,
    +totalCount: ?number,
  |}
|};
export type cancleRegulationQuery = {|
  variables: cancleRegulationQueryVariables,
  response: cancleRegulationQueryResponse,
|};
*/


/*
query cancleRegulationQuery(
  $search: String
  $order: String
  $startdate: DateTime
  $enddate: DateTime
  $first: Int
  $last: Int
  $regulationid: String
) {
  cancelRegulation(search: $search, order: $order, startdate: $startdate, enddate: $enddate, first: $first, last: $last, regulationid: $regulationid) {
    edges {
      node {
        id
        subject
        description
        creator
        datePosted
        dateSchedule
        status
        type
        previewImage
      }
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "enddate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regulationid"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startdate"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "enddate",
        "variableName": "enddate"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "regulationid",
        "variableName": "regulationid"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startdate",
        "variableName": "startdate"
      }
    ],
    "concreteType": "RegulationNodeConnection",
    "kind": "LinkedField",
    "name": "cancelRegulation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RegulationNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegulationNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subject",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "datePosted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dateSchedule",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previewImage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "cancleRegulationQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "cancleRegulationQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "d215699fb09b84612a63eef03ff0668c",
    "id": null,
    "metadata": {},
    "name": "cancleRegulationQuery",
    "operationKind": "query",
    "text": "query cancleRegulationQuery(\n  $search: String\n  $order: String\n  $startdate: DateTime\n  $enddate: DateTime\n  $first: Int\n  $last: Int\n  $regulationid: String\n) {\n  cancelRegulation(search: $search, order: $order, startdate: $startdate, enddate: $enddate, first: $first, last: $last, regulationid: $regulationid) {\n    edges {\n      node {\n        id\n        subject\n        description\n        creator\n        datePosted\n        dateSchedule\n        status\n        type\n        previewImage\n      }\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b1a0881314482722fa835638ca2cb448';

module.exports = node;
