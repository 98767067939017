import { graphql } from "babel-plugin-relay/macro";

const queryAllInvoices = graphql`
    query allInvoicesQuery($search: String){
        invoiceViewer{
            allInvoice(search: $search){
                edges{
                    node{
                        id
                        contact{
                            name
                            firstName
                            lastName
                        }
                    }
                }
            }
        }
    }
`

export default queryAllInvoices;