import React, {Component} from "react";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import ClearAdvanceCreateAndViewForm from "./clearAdvanceCreateAndViewForm"
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {QueryRenderer} from "react-relay";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Loading from '../../../libs/loading';

const query = graphql`
    query clearAdvanceQuery($id: ID!, $status: String){
        allAdvance(status_Icontains: $status){
            totalCount
            edges{
                node{
                    id
                    issuedDate
                    docNumber
                    description
                    withdrawer
                    amount
                 
                }
            }
        }
        clearAdvance(id: $id) {
            id
            docNumber
            issuedDate
            description
            returnPrice
            overPrice
            status
            voidRemark
            clearAdvanceTransaction {
                edges {
                    node {
                        id
                        chartOfAccount {
                            id
                            chartOfAccountCode
                            name
                        }
                        description
                        price
                        refClearAdvanceTransactionDoc
                    }
                }
            }
            advance {
                id
                amount
            }
            bankAccountTransaction {
                edges {
                    node {
                        id
                        price
                        date
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            cashTransaction {
                edges {
                    node {
                        id
                        price
                    }
                }
            }
            chequeTransaction {
                edges {
                    node {
                        id
                        date
                        chequeNumber
                        bankAccount {
                            id
                            branch
                            accountNumber
                            accountName
                            bankName
                        }
                        price
                    }
                }
            }
            advancePettyCashChannel{
                edges{
                    node{
                        id
                        price
                        date
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }

                    }
                }
            }
        }
        
        viewer {
            allChartOfAccount(codeList: ["5"]) {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
    }
`;


class ClearAdvance extends Component {
    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box">
                        <QueryRenderer
                            environment={environment}
                            query={query}
                            cacheConfig={{use_cache: false}}
                            variables={{
                                id: this.props.match.params.id,
                                status: this.props.match.params.id ? "" : "WITHDRAW",
                            }}
                            render={({error, props}) => {
                                if (props) {
                                    return <ClearAdvanceCreateAndViewForm
                                        data={props}
                                        clearAdvanceType={'edit'}
                                        isViewPage={true}
                                    />
                                } else {
                                    return <Loading/>
                                }
                            }}
                        />
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ClearAdvance;
