import '../styles/main.scss';
import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import WrapperInvoiceGetAllProcess from './wrapperInvoiceGetAllProcess';
import jwtDecode from 'jwt-decode'
import {fetchQuery} from "relay-runtime";
import _ from "lodash";
const query = graphql`
    query wrapperInvoiceGetAllQuery($status: [String], $search: String, $start_date : DateTime, $end_date : DateTime, $is_contact_online: Boolean) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            bankCompCode
            bankServiceCode
        }
        invoiceViewer{
            getAllInvoicePrint(status:$status, search:$search, startDate:$start_date, endDate:$end_date, isContactOnline: $is_contact_online)
        }
        userConfig {
            id languages
        }

    }
`;


class WrapperInvoiceGetAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: props.location.state.search,
            start_date: props.location.state.start_date,
            end_date: props.location.state.end_date,
            status: props.location.state.status === "all" ? ['active', 'overdue', 'paid', 'partial_payment'] : [props.location.state.status],
            app_status:props.location.state.app_status,
            check_templete: 'default',
            check_type: 'all',
            data_list:[],
            parts:[],
            data:null
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.adddatalist = this.adddatalist.bind(this);

    }

    componentDidMount(){
        let token = jwtDecode(localStorage.getItem("token"))
        let siteChk = token?.site?.includes("sklasmanagement")
        this.setState({ check_templete : siteChk ? 'templateSingha' : 'default'})

        fetchQuery(environment, query, {
            search: this.state.search,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            status: this.state.status,
            is_contact_online:
                this.state.app_status === "all"
                ? null
                : this.state.app_status === "active",
        }).then((data) => {
            let data_set = _.cloneDeep(data);
            let parts = [];
            const maxLength = 100; // maximum length of each part
            if( data_set.invoiceViewer.getAllInvoicePrint.length > 100){
                for (let i = 0; i < data_set.invoiceViewer.getAllInvoicePrint.length; i += maxLength) {
                    const part = data_set.invoiceViewer.getAllInvoicePrint.slice(i, i + maxLength);
                    parts.push(part);
                }

            }else{
                parts.push(data_set.invoiceViewer.getAllInvoicePrint);
            }

            this.setState({ parts: parts, data:data_set })

        });
    }

    adddatalist(data_list){
        this.setState({data_list:[...this.state.data_list,...data_list]})
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>Invoice Print</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeAll"
                                    onChange={this.handleInputChange}
                                    value="all"
                                    checked={this.state.check_type === 'all'} />
                                <label className="form-check-label" htmlFor="checkTypeAll">
                                    ทั้งหมด
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeManuscript"
                                    value="manuscript"
                                    onChange={this.handleInputChange}
                                    checked={this.state.check_type === 'manuscript'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    ต้นฉบับ
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeCopy"
                                    value="copy"
                                    onChange={this.handleInputChange}
                                    checked={this.state.check_type === 'copy'} />
                                <label className="form-check-label" htmlFor="checkTypeCopy">
                                    สำเนา
                                </label>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate1"
                                    onChange={this.handleInputChange}
                                    value="default"
                                    checked={this.state.check_templete === 'default'} />
                                <label className="form-check-label" htmlFor="checkTypeAll">
                                    รูปแบบที่ 1
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate2"
                                    value="templateSingha"
                                    onChange={this.handleInputChange}
                                    checked={this.state.check_templete === 'templateSingha'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    รูปแบบที่ 2
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                {
                    this.state.data && this.state.parts.length > 0 && this.state.parts.map((id_query_group, index) => {
                        return <WrapperInvoiceGetAllProcess key={index}
                        data_id_list={id_query_group} 
                        selfProject={this.state.data.selfProject}
                        userConfig={this.state.data.userConfig}
                        check_templete={this.state.check_templete}
                        adddatalist={this.adddatalist}
                        data_list={this.state.data_list}
                        getAllInvoicePrintLength={this.state.data.invoiceViewer.getAllInvoicePrint.length}
                        check_type={this.state.check_type}
                        keyindex={index}
                        />
                    })
                }

            </React.Fragment>
        );
    }
}

export default WrapperInvoiceGetAll;
