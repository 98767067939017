import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import ContactReportList from "../contactPayableReportList";
import PaySummaryTable from "./paySummaryTable";
import ExportPayableOutstandingSummaryReport from "./server_export";
import PaySummaryProductAndServiceList from "./paySummaryProductAndServiceList";
import numberWithComma from "../../../../libs/numberWithComma";
import _ from "lodash";
import Loading from "../../../../libs/loading";
import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from "../../../../components/BackBtn/indexBack";
import i18next from "i18next";

const query = graphql`
  query paySummaryReportQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $search: String
    $paymentChannel: String
    $productAndService: String
    $contact: String
    $status: String
  ) {
    allPayRecordGroup(
      startDate: $startDate
      endDate: $endDate
      first: $first
      last: $last
      search: $search
      paymentChannel: $paymentChannel
      productAndService: $productAndService
      contact: $contact
      status: $status
      order: "doc_number"
      report: true
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          docNumber
          issuedDate
          status
          total
          contact {
            refNumber
            name
          }

          payRecord {
            edges {
              node {
                id
                amount
                #หัก ณ ที่จ่าย
                whtAmount
                purchaseRecord {
                  id
                  description
                  total
                  purchaseRecordGroup {
                    docNumber
                    issuedDate
                  }
                }

              }
            }
          }

          #ค่าธรรมเนียม ส่วนต่างขาดเกิน
          paymentChannel{
            totalCount
            edges {
              node {
                slug
                price
                negativeNumber
              }
            }
          }

          #เงินสด
          cashTransaction {
            totalCount
            edges {
              node {
                price
              }
            }
          }

          #ธนาคาร
          bankAccountTransaction {
            totalCount
            edges {
              node {
                price
              }
            }
          }
          
          #เช็ค
          chequeTransaction {
            totalCount
            edges {
              node {
                price
              }
            }
          }

        }
      }
    }

    summaryPayRecordGroup(startDate: $startDate,endDate: $endDate,search: $search,paymentChannel: $paymentChannel,contact: $contact,productAndService: $productAndService,status: "paid",report: true)
    payRecordSummaryIncomeExpense(startDate: $startDate,endDate: $endDate,search: $search,paymentChannel: $paymentChannel,contact: $contact,productAndService: $productAndService,status: "paid",report: true){
      cashTotal
      bankTotal
      chequeTotal
      pettyCashTotal
      paymoreFee
      paylessRetention
      total
    }
  }
`;

class PaySummaryReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);
    // month_before.setDate(7);

    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.search = "";
    this.state.search_input = "";
    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.payment_channel = "";
    this.state.temp_payment_channel = "";
    this.state.temp_product_and_service = "";
    this.state.product_and_service = "";

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "paySummaryReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value, });
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }

  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      contact: this.state.temp_contact,
      product_and_service: this.state.temp_product_and_service,
      payment_channel: this.state.temp_payment_channel,
    });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-payable"}
                  LinkText={i18next.t("DashboardPayable:Summary Payment Report")}
                  boxHtmlText={
                    <div className="col">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "report_payable_print", }) && (
                        <ExportPayableOutstandingSummaryReport state={this.state} />
                      )}
                    </div>
                  }
                />

                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) => this.setState({ search_input: e.target.value })}
                          onKeyDown={this.handleKeyDown}
                        />
                        <button type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <ContactReportList
                            handleChange={this.handleChange}
                            contact={this.state.temp_contact}
                          />
                          <PaySummaryProductAndServiceList
                            handleChange={this.handleChange}
                            product_and_service={this.state.temp_product_and_service}
                          />


                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("FilterPayable:Payment Method")}</label>
                              <select name="temp_payment_channel"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("FilterPayable:All")}</option>
                                <option value="cash">{i18next.t("PaymentChannel:Cash")}</option>
                                <option value="bank">{i18next.t("PaymentChannel:Transfer")}</option>
                                <option value="cheque">{i18next.t("PaymentChannel:Cheque")}</option>
                                <option value="petty_cash">{i18next.t("FilterPayable:Petty Cash")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <QueryRenderer
                    environment={environment}
                    query={query}
                    variables={{
                      first: this.state.first,
                      last: this.state.last,
                      startDate: this.state.start_date,
                      endDate: this.state.end_date,
                      contact: this.state.contact,
                      search: this.state.search,
                      productAndService: this.state.product_and_service,
                      paymentChannel: this.state.payment_channel,
                    }}
                    render={({ error, props }) => {
                      if (props) {
                        return (
                          <React.Fragment>

                            <div className="row mt-3">
                              <div className="col-7"></div>
                              <div className="col-5">
                                <div className="card fade-up">
                                  <table className="table table-hover">
                                    <thead className="thead-light">
                                      <tr>
                                        <th>{i18next.t("FilterPayable:Payment Paid Type")}</th>
                                        <th className="text-right">จำนวนเงิน</th>
                                      </tr>
                                      <tr>
                                        <td>{i18next.t("PaymentChannel:Cash")}</td>
                                        <td className="text-right">{numberWithComma(props.payRecordSummaryIncomeExpense.cashTotal)}</td>
                                      </tr>
                                      <tr>
                                        <td>{i18next.t("PaymentChannel:Transfer")}</td>
                                        <td className="text-right">{numberWithComma(props.payRecordSummaryIncomeExpense.bankTotal)}</td>
                                      </tr>
                                      <tr>
                                        <td>{i18next.t("PaymentChannel:Cheque")}</td>
                                        <td className="text-right">{numberWithComma(props.payRecordSummaryIncomeExpense.chequeTotal)}</td>
                                      </tr>
                                      <tr>
                                        <td>{i18next.t("FilterPayable:Petty Cash")}</td>
                                        <td className="text-right">{numberWithComma(props.payRecordSummaryIncomeExpense.pettyCashTotal)}</td>
                                      </tr>
                                      {/* <tr>
                                     <td>รายรับจากส่วนต่างเศษสตางค์ / เงินประกันผลงาน</td>
                                     <td className="text-right">{numberWithComma(props.payRecordSummaryIncomeExpense.paylessRetention)}</td>
                                   </tr>
                                   <tr>
                                     <td>รายจ่ายจากส่วนต่างเศษสตางค์ / ค่าธรรมเนียม</td>
                                     <td className="text-right">{numberWithComma(props.payRecordSummaryIncomeExpense.paymoreFee)}</td>
                                   </tr> */}
                                      <tr>
                                        <th className="text-center">{i18next.t("PurchaseReport:Sum")}</th>
                                        <th className="text-right">{numberWithComma(props.payRecordSummaryIncomeExpense.total)}</th>
                                      </tr>
                                    </thead>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table className="table table-hover" style={{ fontSize: 12 }}>
                                      <thead className="thead-light">

                                        <tr>
                                          <th rowSpan={2} className="align-middle text-center">
                                            {i18next.t("PaySummaryReport:Order")}
                                          </th>
                                          <th rowSpan={2} className="align-middle text-center">
                                            {i18next.t("PaySummaryReport:Payment Number")}
                                          </th>
                                          <th rowSpan={2} className="align-middle text-center">
                                            {i18next.t("PaySummaryReport:Payment Date")}
                                          </th>
                                          <th rowSpan={2} className="align-middle">
                                            {i18next.t("PaySummaryReport:Creditor Code")}
                                          </th>
                                          <th rowSpan={2} className="align-middle">
                                            {i18next.t("PaySummaryReport:Name")}
                                          </th>
                                          <th rowSpan={2} className="text-center">
                                            {i18next.t("PaySummaryReport:Expense Setting Record Number")}
                                          </th>
                                          <th rowSpan={2} className="text-center">
                                            {i18next.t("PaySummaryReport:Expense Setting Record Date")}
                                          </th>
                                          <th rowSpan={2} className="text-center">
                                            {i18next.t("PaySummaryReport:Item")}
                                          </th>
                                          <th rowSpan={2} className="text-center">
                                            {i18next.t("PaySummaryReport:Expense Amount")}
                                          </th>
                                          <th colSpan={9} className="text-center">
                                            {i18next.t("PaySummaryReport:Payment Method")}
                                          </th>
                                          <th rowSpan={2} className="text-right">
                                            {i18next.t("PaySummaryReport:Amount")}
                                          </th>
                                          <th rowSpan={2} className="text-right">
                                            {i18next.t("PaySummaryReport:Total")}
                                          </th>
                                        </tr>

                                        <tr className="border-top">
                                          <th className="text-center"> {i18next.t("PaySummaryReport:Cash")}</th>
                                          <th className="text-center"> {i18next.t("PaySummaryReport:Transfer")}</th>
                                          <th className="text-center"> {i18next.t("PaySummaryReport:Cheque")}</th>
                                          <th className="text-center"> {i18next.t("PaySummaryReport:Petty Cash")}</th>
                                          <th className="text-center"> {i18next.t("PaySummaryReport:Deduct at Source")}</th>
                                          <th className="text-center"> {i18next.t("guarantee_money_received:Income from penny difference")}</th>
                                          <th className="text-center"> {i18next.t("payChannelInPay:Performance insurance")}</th>
                                          <th className="text-center"> {i18next.t("PaySummaryReport:Expenditure from Fractions of Cents")}</th>
                                          <th className="text-center"> {i18next.t("PaySummaryReport:Fees")}</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        <PaySummaryTable
                                          props={props}
                                          state={this.state}
                                        />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={props.allPayRecordGroup.totalCount}
                              />
                            </div>
                          </React.Fragment>
                        );
                      } else {
                        return <Loading />
                      }
                    }}
                  />
                </div>
              </div>
            }
          </Translation>

        </WrapperContent>
      </Wrapper>
    );
  }
}

export default PaySummaryReport;
