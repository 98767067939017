import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import InputAddress from "../../../libs/autoCompleteAddress";

class RenterAddressDetail extends Component {

    render() {
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className='row m-0 p-0'>
                            <div className='col-8 pl-0'>
                                <p className='fs-16 mt-5'>{t("add_receivable:Address")} <span className="star-button">*</span></p>
                                <textarea className="form-control h48" id="renterAddress" maxLength="256"
                                    rows={1} style={{ resize: 'none' }}
                                    name="renterAddress" value={this.props.state.renterAddress}
                                    onChange={this.props.handleInputChange}
                                    disabled={this.props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                    required
                                />
                            </div>

                            <div className='col' />

                            <div className='col-4 pl-0'>
                                <p className='fs-16 mt-5' htmlFor="renterDistrict">{t("add_receivable:Sub-district")}</p>
                                <InputAddress
                                    address="renterDistrict"
                                    value={this.props.state.renterDistrict}
                                    onChange={this.props.handleInputChange}
                                    onSelect={this.props.onSelect}
                                    mailing={false}
                                    className="form-control h48"
                                    disabled={this.props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                />

                            </div>

                            <div className='col-4 pl-0'>
                                <p className='fs-16 mt-5' htmlFor="renterCity">{t("add_receivable:District")}</p>
                                <InputAddress
                                    address="renterCity"
                                    value={this.props.state.renterCity}
                                    onChange={this.props.handleInputChange}
                                    onSelect={this.props.onSelect}
                                    mailing={false}
                                    className="form-control h48"
                                    disabled={this.props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                />
                            </div>
                            <div className='col' />

                            <div className='col-4 pl-0'>
                                <p className='fs-16 mt-5' htmlFor="renterProvince">{t("add_receivable:Province")}</p>
                                <InputAddress
                                    address="renterProvince"
                                    value={this.props.state.renterProvince}
                                    onChange={this.props.handleInputChange}
                                    onSelect={this.props.onSelect}
                                    mailing={false}
                                    className="form-control h48"
                                    disabled={this.props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                />

                            </div>

                            <div className='col-4 pl-0'>
                                <p className='fs-16 mt-5' htmlFor="renterPostalCode">{t("add_receivable:Zip code")}</p>
                                <InputAddress
                                    address="renterPostalCode"
                                    value={this.props.state.renterPostalCode}
                                    onChange={this.props.handleInputChange}
                                    onSelect={this.props.onSelect}
                                    mailing={false}
                                    className="form-control h48"
                                    disabled={this.props.state.typeOfContact === 'RESIDENTIAL' ? "disabled" : false}
                                />
                            </div>

                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default RenterAddressDetail;