import React from 'react';
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import Wrapper from "../../../components/wrapper";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { Translation } from "react-i18next";
import ComponentPagination from "../../../libs/componentPagination";
import _ from "lodash";
import BackButtonIcon from '../../../components/BackBtn/indexBack';
import ContactTopMenuNavigation from '../../contactTopMenuNavigation';
import '../../../libs/styles/styleColor.scss'
import api from '../../../api';
import Loading from '../../../libs/loading';
import Swal from 'sweetalert2';
import i18n from '../../../i18n';

class ScoreManageByRoomSetDataScore extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            dataFromApi: [],
            loading: true,
        }
        // this.selectNow = this.selectNow.bind(this)
        // this.removeSelectAll = this.removeSelectAll.bind(this)
        // this.onSreach = this.onSreach.bind(this)
    }

    componentDidMount() {
        let data = this.props.location?.state?.data || []
        let residential_list = []
        data.map((item) => {
            residential_list.push(item.id)
        })

        api.bookingSingha.getscore({ residential_list: residential_list }).then((res) => {
            if (res?.data?.all_residential_score) {
                let datase = res?.data?.all_residential_score.map((items) => ({
                    ...items,
                    residential_id: items.id,
                    increase_resident_score: null,
                    decrease_resident_score: null,
                    increase_rent_score: null,
                    decrease_rent_score: null,
                    increase_agent_score: null,
                    decrease_agent_score: null,
                    totalRes: items.score_resident_remaining,
                    totalRen: items.score_rent_remaining,
                    totalAgent: items.score_agent_remaining,
                }))
                this.setState({ dataFromApi: datase, loading: false })
            } else {
                this.setState({ loading: false })
            }
        }).catch((err) => { })
    }

    onChnageData = (e, nameIndex, total, fors) => {
        let { name, value } = e.target
        let clone = _.cloneDeep(this.state.dataFromApi)
        _.set(clone, `[${nameIndex}].${name}`, parseInt(value))
        // let nameIncluded = name.includes("resident")
        let nameIncludedRes = name.includes("resident")
        let nameIncludedRen = name.includes("rent")
        if (nameIncludedRes) {
            _.set(clone, `[${nameIndex}].totalRes`, fors === "plus" ? parseInt(value? value : 0) + parseInt(total) : parseInt(total) - parseInt(value? value : 0))
        } else if(nameIncludedRen){
            _.set(clone, `[${nameIndex}].totalRen`, fors === "plus" ? parseInt(value? value : 0) + parseInt(total) : parseInt(total) - parseInt(value? value : 0))  
        } else {
            _.set(clone, `[${nameIndex}].totalAgent`, fors === "plus" ? parseInt(value? value : 0) + parseInt(total) : parseInt(total) - parseInt(value? value : 0))              
        }
        this.setState({ dataFromApi: clone })
    }

    save = () => {
        let data = {
            residential_list: this.state.dataFromApi.map((items) => ({
                ...items,
                increase_resident_score: items.increase_resident_score ? items.increase_resident_score : 0,
                decrease_resident_score: items.decrease_resident_score ? items.decrease_resident_score : 0,
                increase_rent_score: items.increase_rent_score ? items.increase_rent_score : 0,
                decrease_rent_score: items.decrease_rent_score ? items.decrease_rent_score : 0,
                increase_agent_score: items.increase_agent_score ? items.increase_agent_score : 0,
                decrease_agent_score: items.decrease_agent_score ? items.decrease_agent_score : 0,
            }))
        }
        api.bookingSingha.editscore(data).then((res) => {
            if (res.data.all_residential_score) {
                Swal.fire(i18n.t("Allaction:Saved Successfully"), "", "success").then(() => {
                    window.location.reload()
                })
            } else {
                Swal.fire(i18n.t("Allaction:Saved Unsuccessful"), "", "warning").then(() => {
                    return;
                })
            }
        }).catch((err) => { })
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <ContactTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>

                            <React.Fragment>
                                <div className="container-fluid box">
                                    <BackButtonIcon
                                        LinkBack={"/contact"}
                                        LinkText={t("newBookingSingha_ManageScore:Adding points")}
                                    />

                                    <div className='container-fluid box ml-2 mt-10 mr-2 usedAll colorUse'>
                                        <div className='row'>
                                            <div className='col-16'>
                                                <p className='headerLabel24 fw-400'>{t("newBookingSingha_ManageScore:Money to Points list")}</p>

                                                <table className='table table-bordered '>
                                                    <thead className='bg-gray text-center fs-16 fw-400 text_2D2D30'>
                                                        <tr>
                                                            <td rowSpan={2}>{t("newBookingSingha_ManageScore:No.")}</td>
                                                            <td rowSpan={2}>{t("newBookingSingha_ManageScore:Room no.")}</td>
                                                            <td colSpan={4}>{t("newBookingSingha_ManageScore:Owner")}</td>
                                                            <td colSpan={4}>{t("newBookingSingha_ManageScore:Tenant")}</td>
                                                            <td colSpan={4}>{t("newBookingSingha_ManageScore:Agent")}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("newBookingSingha_ManageScore:Reduce points")}</td>
                                                            <td>{t("newBookingSingha_ManageScore:Current points")}</td>
                                                            <td>{t("newBookingSingha_ManageScore:Add points")}</td>
                                                            <td>{t("newBookingSingha_ManageScore:Remaining")}</td>
                                                            <td>{t("newBookingSingha_ManageScore:Reduce points")}</td>
                                                            <td>{t("newBookingSingha_ManageScore:Current points")}</td>
                                                            <td>{t("newBookingSingha_ManageScore:Add points")}</td>
                                                            <td>{t("newBookingSingha_ManageScore:Remaining")}</td>
                                                            <td>{t("newBookingSingha_ManageScore:Reduce points")}</td>
                                                            <td>{t("newBookingSingha_ManageScore:Current points")}</td>
                                                            <td>{t("newBookingSingha_ManageScore:Add points")}</td>
                                                            <td>{t("newBookingSingha_ManageScore:Remaining")}</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-center'>
                                                        {
                                                            this.state.loading == false ?
                                                                this.state.dataFromApi?.map((itemsData, indexitemsData) => {
                                                                    return (
                                                                        <tr className='text-center fw-300 fs-16' key={indexitemsData}>
                                                                            <td style={{ width: '8%' }}>{indexitemsData + 1}</td>
                                                                            <td style={{ width: '8%' }}>{itemsData.name}</td>

                                                                            {/* Resident */}
                                                                            <td style={{ width: '7%' }}>
                                                                                <input type={"number"}
                                                                                    name='decrease_resident_score'
                                                                                    className='form-control text-center h42'
                                                                                    disabled={itemsData.increase_resident_score > 0}
                                                                                    onChange={(e) => this.onChnageData(e, indexitemsData, itemsData.score_resident_remaining, "minus")} />
                                                                            </td>
                                                                            <td style={{ width: '7%' }} className='usedAll'>
                                                                                <input type={"number"}
                                                                                    className='form-control text-center h42 disabledInput'
                                                                                    defaultValue={itemsData.score_resident_remaining}
                                                                                    disabled={true}
                                                                                />
                                                                            </td>
                                                                            <td style={{ width: '7%' }}>
                                                                                <input type={"number"}
                                                                                    name='increase_resident_score'
                                                                                    className='form-control text-center h42'
                                                                                    disabled={itemsData.decrease_resident_score > 0}
                                                                                    onChange={(e) => this.onChnageData(e, indexitemsData, itemsData.score_resident_remaining, "plus")} />
                                                                            </td>
                                                                            <td style={{ width: '7%' }} className='usedAll'>
                                                                                <input type={"number"}
                                                                                    value={itemsData.totalRes}
                                                                                    className='form-control text-center h42 disabledInput'
                                                                                    disabled={true} />
                                                                            </td>

                                                                            {/* Rent */}
                                                                            <td style={{ width: '7%' }}>
                                                                                <input type={"number"}
                                                                                    name='decrease_rent_score'
                                                                                    className='form-control text-center h42'
                                                                                    disabled={itemsData.increase_rent_score > 0}
                                                                                    onChange={(e) => this.onChnageData(e, indexitemsData, itemsData.score_rent_remaining, "minus")} />
                                                                            </td>
                                                                            <td style={{ width: '7%' }}>
                                                                                <input type={"number"}
                                                                                    className='form-control text-center h42 disabledInput'
                                                                                    defaultValue={itemsData.score_rent_remaining}
                                                                                    disabled={true}
                                                                                />
                                                                            </td>
                                                                            <td style={{ width: '7%' }}>
                                                                                <input type={"number"}
                                                                                    name='increase_rent_score'
                                                                                    className='form-control text-center h42'
                                                                                    disabled={itemsData.decrease_rent_score > 0}
                                                                                    onChange={(e) => this.onChnageData(e, indexitemsData, itemsData.score_rent_remaining, "plus")} />
                                                                            </td>
                                                                            <td style={{ width: '7%' }}>
                                                                                <input type={"number"}
                                                                                    value={itemsData.totalRen}
                                                                                    className='form-control text-center h42 disabledInput'
                                                                                    disabled={true} />
                                                                            </td>

                                                                            {/* Agent */}
                                                                            <td style={{ width: '7%' }}>
                                                                                <input type={"number"}
                                                                                    name='decrease_agent_score'
                                                                                    className='form-control text-center h42'
                                                                                    disabled={itemsData.increase_agent_score > 0}
                                                                                    onChange={(e) => this.onChnageData(e, indexitemsData, itemsData.score_agent_remaining, "minus")} />
                                                                            </td>
                                                                            <td style={{ width: '7%' }}>
                                                                                <input type={"number"}
                                                                                    className='form-control text-center h42 disabledInput'
                                                                                    defaultValue={itemsData.score_agent_remaining}
                                                                                    disabled={true}
                                                                                />
                                                                            </td>
                                                                            <td style={{ width: '7%' }}>
                                                                                <input type={"number"}
                                                                                    name='increase_agent_score'
                                                                                    className='form-control text-center h42'
                                                                                    disabled={itemsData.decrease_agent_score > 0}
                                                                                    onChange={(e) => this.onChnageData(e, indexitemsData, itemsData.score_agent_remaining, "plus")} />
                                                                            </td>
                                                                            <td style={{ width: '7%' }}>
                                                                                <input type={"number"}
                                                                                    value={itemsData.totalAgent}
                                                                                    className='form-control text-center h42 disabledInput'
                                                                                    disabled={true} />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan={10}>
                                                                        <Loading />
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>

                                                </table>

                                                <div className='text-right cliam mt-5'>
                                                    <button type='button' className='btn-primary'
                                                        onClick={this.save}>{t("Allaction:save")}</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </React.Fragment>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>




        );
    }
}

export default ScoreManageByRoomSetDataScore;
