import React from "react";
import localStorage from "../../../../libs/localstorage";
import numberWithComma from "../../../../libs/numberWithComma";
import {format, differenceInCalendarDays} from "date-fns";
import Link from "react-router-dom/es/Link";
import i18next from "i18next";
import getNameResidential from "../../../../libs/getNameResidential";

class ReceivableOutstandingReportTable extends React.Component {
    removeTrailingZeros(description){
        if(description.includes("ค่าน้ำประปา")){
            description = description.replace(/ *\[[^)]*\] */g, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }

    getAmountOwed(transaction) {

        var _this = this

        const sumRecive = transaction.node.receiveTransaction.edges.reduce((total, obj) => {
            if(differenceInCalendarDays(_this.props.state.end_date, obj.node.receive.issuedDate) >= 0) {
                return total + obj.node.amount
            } else {
                return total
            }
        }, 0);

        const sumReceiptDeposit = transaction.node.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
            if (obj.node.receiveTransaction) {
                return total
            } else {
                return total + obj.node.amount
            }
        }, 0);

        const sumCreditNote = transaction.node.creditNoteTransaction.edges.reduce((total, obj) => {
            if(obj){
                if (obj.node.receive) {
                    return total
                }else if(differenceInCalendarDays(_this.props.state.end_date, obj.node.issuedDate) >= 0){
                    return total + obj.node.price
                }else{
                    return total
                }
            }else{
                return total
            }
        }, 0)

        const sumCreditNotev2 = transaction.node.creditNoteTransactionRecord.edges.reduce((total, obj) => {
            if(obj){
                 if(differenceInCalendarDays(_this.props.state.end_date, obj.node.creditNote.issuedDate) >= 0){
                    return total + obj.node.price
                }else{
                    return total
                }
            }else{
                return total
            }
        }, 0)
        return parseFloat(transaction.node.total - transaction.node.whtRate - sumRecive - sumReceiptDeposit - sumCreditNote - sumCreditNotev2)
    }

    getNumberDay(invoice) {
        if (differenceInCalendarDays(this.props.state.end_date, invoice.node.dueDate) < 0) {
            return 0
        } else {
            return differenceInCalendarDays(this.props.state.end_date, invoice.node.dueDate)
        }
    }

    between(value, min, max) {
        return value >= min && value <= max;
    }

    over(value,max){
        return value > max;
    }

    render() {
        let sum_totals = [0, 0, 0, 0, 0, 0, 0, 0];
        let sum_total_contact = 0;
        // let nameAndDataOutstanding = this.props.state.reportOutstandingReceivableSummary.edges;
        let outstandingBalanceOfCustomer = 0;

        // ใช้ในกรณีที่ ลูกหนี้เป็นรายการสุดท้ายที่อยู่ในหน้าที่ render มา
        let outstandingBalanceForThelastLine = 0;

        return (
            <React.Fragment>
                {
                    this.props.props.invoiceViewer.allInvoice.edges.map((invoice, index) => {
                        let order_id = this.props.state.first - localStorage.getPageLimit();
                        let first = true
                        let invoice_select = null
                        let invoiceSelectNextItem = [];
                        // let checkedAmountOwedZero = false;
                        let outstandingBalanceOfCustomerShow = 0
                        // หา outstading balance customer โดยการเช็คว่า customer รายการล่าสุดกับรายการถัดไปเป็นตัวเดียวกันมั้ย
                        // if(invoice.node.contact.id !== this.props.props.invoiceViewer.allInvoice.edges[index +1]?.node.contact.id){
                        //     nameAndDataOutstanding.map((data)=>{
                        //         if(data.node.refNumber === invoice.node.contact.refNumber){
                        //             outstandingBalanceOfCustomer = data.node.outstandingBalance;
                        //             return outstandingBalanceOfCustomer
                        //         }
                        //         }
                        //     )
                        // } else {
                        //     outstandingBalanceOfCustomer = 0;
                        // }

                        //ตัด product_and_service ที่แถมมาเวลา ฟิล ไม่เกี่ยวข้อง
                        if (this.props.state.product_and_service === "") {
                            invoice_select = invoice.node.transaction.edges
                        }
                        else {
                            invoice_select = invoice.node.transaction.edges.filter(element => {
                                if(element.node.productAndService){
                                    if(element.node.productAndService.id === this.props.state.product_and_service){
                                        return element
                                    }
                                }
                            });

                            // invoice_select ตัวถัดไป
                            invoiceSelectNextItem = this.props.props.invoiceViewer.allInvoice.edges[index +1]?.node.transaction.edges.filter(element => {
                                if(element.node.productAndService){
                                    if(element.node.productAndService.id === this.props.state.product_and_service){
                                        return element
                                    }
                                }
                            });

                            // คำนวน amountOwed ตัวถัดไป
                            invoiceSelectNextItem && invoiceSelectNextItem.map((transaction) => {
                                // const amountOwed = -0.01 <this.getAmountOwed(transaction) && this.getAmountOwed(transaction) < 0.01 ? 0 : this.getAmountOwed(transaction) 
                                // if(amountOwed === 0){
                                //     // checkedAmountOwedZero = true;
                                // }
                                return transaction
                            })
                        }

                        return (
                            invoice_select.map((transaction, t_index) => {
                                const amountOwed = -0.01 <this.getAmountOwed(transaction) && this.getAmountOwed(transaction) < 0.01 ? 0 : this.getAmountOwed(transaction) 
                                const amountOwedWithComma = numberWithComma(amountOwed, "")
                                if(this.props.state.product_and_service){
                                    if (amountOwed !== 0) {

                                        // เนื่องจากตอน filter จะมี transaction amountOwed === 0 ติดมาด้วย
                                        // ถ้า transaction ปัจจุบัน amountOwed !== 0 จะเช็ค amountOwed ตัวถัดไปว่าเป็น 0 มั้ยถ้าตัวถัดไปเป็น 0 จะถือว่าบรรทัดนั้นเป็นบรรทัดสุดท้ายแล้วแสดงยอดค้างชำระสุทธิของห้องนั้น
                                        // if(checkedAmountOwedZero) {
                                        //     nameAndDataOutstanding.map((data)=>{
                                        //         if(data.node.refNumber === invoice.node.contact.refNumber){
                                        //             outstandingBalanceOfCustomer = data.node.outstandingBalance;
                                        //             return outstandingBalanceOfCustomer
                                        //         }
                                        //         }
                                        //     )
                                        // }

                                        outstandingBalanceOfCustomer += amountOwed
                                        if(invoice.node.contact.id !== this.props.props.invoiceViewer.allInvoice.edges[index + 1]?.node.contact.id 
                                            && this.props.props.invoiceViewer.allInvoice.edges[index + 1]
                                        ){
                                            outstandingBalanceOfCustomerShow = outstandingBalanceOfCustomer
                                            outstandingBalanceOfCustomer = 0
                                        }else{
                                            outstandingBalanceOfCustomerShow = 0
                                        }

                                        let row_number = (first && order_id + index + 1);
                                        first = false

                                        let total_contact = 0
                                        sum_total_contact += amountOwed
                                        // หา outstading balance customer โดยการเช็คว่า customer รายการล่าสุดกับรายการถัดไปเป็นตัวเดียวกันมั้ย
                                        if(invoice.node.contact.id !== this.props.props.invoiceViewer.allInvoice.edges[index +1]?.node.contact.id 
                                            && this.props.props.invoiceViewer.allInvoice.edges[index+1]
                                            ){
                                                outstandingBalanceForThelastLine = 0;
                                        } else {
                                            outstandingBalanceForThelastLine += amountOwed
                                        }
                                        

                                        if (!invoice_select[t_index + 1]) {
                                            total_contact = sum_total_contact
                                            sum_total_contact = 0
                                        }

                                        if (this.getNumberDay(invoice) < 1) {
                                            sum_totals[0] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 1, 30)) {
                                            sum_totals[1] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 31, 60)) {
                                            sum_totals[2] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 61, 90)) {
                                            sum_totals[3] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 91, 120)) {
                                            sum_totals[4] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 121, 150)) {
                                            sum_totals[5] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 151, 180)) {
                                            sum_totals[6] += amountOwed
                                        } else {
                                            sum_totals[7] += amountOwed
                                        }
                                        
                                        return (
                                            (
                                                <tr key={transaction.node.id}>
                                                    <td className="text-center">{row_number}</td>
                                                    <td>
                                                        {invoice.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                        invoice.node.contact.residential.name}

                                                        {invoice.node.contact.typeOfContact === "SUPPLIER" &&
                                                        invoice.node.contact.refNumber
                                                        }
                                                    </td>
                                                    <td>
                                                        {invoice.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                             invoice.node?.firstName + " " + invoice.node?.lastName
                                                        }
                                                        {invoice.node.contact.typeOfContact === "SUPPLIER" &&
                                                            invoice.node?.name
                                                        }
                                                        {" "}                                            
                                                        {
                                                            (invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER") &&
                                                            <label className="text-danger">
                                                                ({invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${invoice.node.payGroup}`) : ""})
                                                            </label>
                                                        }  
                                                    </td>
                                                        <td className="text-center">
                                                            <Link
                                                                to={"/accounting/income/invoice/invoice-detail/" + invoice.node.id}
                                                                target={"_blank"}>
                                                                {invoice.node.docNumber}
                                                            </Link>
                                                        </td>
                                                        <td>{transaction.node.productAndService.productCode}</td>
                                                        <td>{
                                                            this.removeTrailingZeros(transaction.node.description)
                                                            }</td>
                                                        <td className="text-center">{format(invoice.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                        <td className="text-center">{format(invoice.node.dueDate, 'DD/MM/YYYY')}</td>
                                                        <td className="text-right">{this.getNumberDay(invoice)}</td>
                                                        {(this.props.state.overdue_date === ""|| this.props.state.overdue_date === "not_overdue") &&
                                                            <td className="text-right">
                                                                {
                                                                    this.getNumberDay(invoice) < 1 && 
                                                                    amountOwedWithComma
                                                                }
                                                            </td>
                                                        }
                                                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "1-30") &&
                                                            <td className="text-right">
                                                                {
                                                                    this.between(this.getNumberDay(invoice), 1, 30) &&
                                                                    amountOwedWithComma
                                                                }
                                                            </td>
                                                        }
                                                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "31-60") &&
                                                            <td className="text-right">
                                                                {
                                                                    this.between(this.getNumberDay(invoice), 31, 60) &&
                                                                    amountOwedWithComma
                                                                }
                                                            </td>
                                                        }
                                                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "61-90") &&
                                                            <td className="text-right">
                                                                {
                                                                    this.between(this.getNumberDay(invoice), 61, 90) &&
                                                                    amountOwedWithComma
                                                                }
                                                            </td>
                                                        }
                                                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "91-120") &&
                                                            <td className="text-right">
                                                                {
                                                                    this.between(this.getNumberDay(invoice), 91, 120) &&
                                                                    amountOwedWithComma
                                                                }
                                                            </td>
                                                        }
                                                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "121-150") &&
                                                            <td className="text-right">
                                                                {
                                                                    this.between(this.getNumberDay(invoice), 121, 150) &&
                                                                    amountOwedWithComma
                                                                }
                                                            </td>
                                                        }
                                                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "151-180") &&
                                                            <td className="text-right">
                                                                {
                                                                    this.between(this.getNumberDay(invoice), 151, 180) &&
                                                                    amountOwedWithComma
                                                                }
                                                            </td>
                                                        }
                                                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "over180") &&
                                                            <td className="text-right">
                                                                {
                                                                    this.getNumberDay(invoice) > 180 && 
                                                                    amountOwedWithComma
                                                                }
                                                            </td>
                                                        }
                                                        <td className="text-right">{numberWithComma(total_contact, "")}</td>
                                                        {!invoice_select[t_index + 1]?                                                  
                                                            this.props.props.invoiceViewer.allInvoice.edges[index+1] ? 
                                                            <td className="text-right">{numberWithComma(outstandingBalanceOfCustomerShow, "")}</td>
                                                            :
                                                            <td className="text-right">{numberWithComma(outstandingBalanceForThelastLine, "")}</td>
                                                        :
                                                        <td>{" "}</td>  
                                                        }  
                                                    </tr>
                                                )
                                            )
                                        
                                    }
                                } else {
                                    if(amountOwed !== 0){
                                        let row_number = (first && order_id + index + 1);
                                        first = false

                                        let total_contact = 0
                                        sum_total_contact += amountOwed
                                        // หา outstading balance customer โดยการเช็คว่า customer รายการล่าสุดกับรายการถัดไปเป็นตัวเดียวกันมั้ย
                                        if(invoice.node.contact.id !== this.props.props.invoiceViewer.allInvoice.edges[index +1]?.node.contact.id 
                                            && this.props.props.invoiceViewer.allInvoice.edges[index+1]
                                            ){
                                                outstandingBalanceForThelastLine = 0;
                                        } else {
                                            outstandingBalanceForThelastLine += amountOwed
                                        }
                                        if (!invoice.node.transaction.edges[t_index + 1]) {
                                            total_contact = sum_total_contact
                                            sum_total_contact = 0
                                        }

                                        if (this.getNumberDay(invoice) < 1) {
                                            sum_totals[0] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 1, 30)) {
                                            sum_totals[1] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 31, 60)) {
                                            sum_totals[2] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 61, 90)) {
                                            sum_totals[3] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 91, 120)) {
                                            sum_totals[4] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 121, 150)) {
                                            sum_totals[5] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 151, 180)) {
                                            sum_totals[6] += amountOwed
                                        } else {
                                            sum_totals[7] += amountOwed
                                        }

                                        return (
                                            (
                                                <tr key={transaction.node.id}>
                                                    <td className="text-center">{row_number}</td>
                                                    <td>
                                                        {invoice.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                        invoice.node.contact.residential.name}

                                                        {invoice.node.contact.typeOfContact === "SUPPLIER" &&
                                                        invoice.node.contact.refNumber
                                                        }
                                                    </td>
                                                    <td>
                                                        {invoice.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                        //invoice.node.contact.firstName + " " + invoice.node.contact.lastName
                                                        getNameResidential(invoice.node.firstName, invoice.node.lastName)
                                                        }

                                                        {invoice.node.contact.typeOfContact === "SUPPLIER" &&
                                                        invoice.node.contact.name
                                                        }
                                                        {" "}                                            
                                                        {
                                                            (invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER") &&
                                                            <label className="text-danger">
                                                                ({invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${invoice.node.payGroup}`) : ""})
                                                            </label>
                                                        }  
                                                    </td>
                                                    <td className="text-center">
                                                        <Link
                                                            to={"/accounting/income/invoice/invoice-detail/" + invoice.node.id}
                                                            target={"_blank"}>
                                                            {invoice.node.docNumber}
                                                        </Link>
                                                    </td>
                                                    <td>{transaction.node.productAndService ? transaction.node.productAndService?.productCode : (transaction.node.chartOfAccount && transaction.node.chartOfAccount?.chartOfAccountCode === '1131-12' || transaction.node.chartOfAccount?.chartOfAccountCode === '1131-10' ? 'S000' : '')}</td>
                                                    <td>{
                                                        this.removeTrailingZeros(transaction.node.description)
                                                        }</td>
                                                    <td className="text-center">{format(invoice.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                    <td className="text-center">{format(invoice.node.dueDate, 'DD/MM/YYYY')}</td>
                                                    <td className="text-right">{this.getNumberDay(invoice)}</td>
                                                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "not_overdue") &&
                                                        <td className="text-right">
                                                            {
                                                                this.getNumberDay(invoice) < 1 &&
                                                                amountOwedWithComma
                                                                }
                                                        </td>
                                                    }
                                                    
                                                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "1-30") &&
                                                        <td className="text-right">
                                                            {
                                                                this.between(this.getNumberDay(invoice), 1, 30) &&
                                                                amountOwedWithComma
                                                            }
                                                        </td>
                                                    }
                                                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "31-60") &&
                                                        <td className="text-right">
                                                            {
                                                                this.between(this.getNumberDay(invoice), 31, 60) &&
                                                                amountOwedWithComma
                                                            }
                                                        </td>
                                                    }
                                                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "61-90") &&
                                                        <td className="text-right">
                                                            {
                                                                this.between(this.getNumberDay(invoice), 61, 90) &&
                                                                amountOwedWithComma
                                                            }
                                                        </td>
                                                    }
                                                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "91-120")  &&
                                                        <td className="text-right">
                                                            {
                                                                this.between(this.getNumberDay(invoice), 91, 120) &&
                                                                amountOwedWithComma
                                                            }
                                                        </td>
                                                    }
                                                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "121-150")  &&
                                                        <td className="text-right">
                                                            {
                                                                this.between(this.getNumberDay(invoice), 121, 150) &&
                                                                amountOwedWithComma
                                                            }
                                                        </td>
                                                    }
                                                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "151-180")  &&
                                                        <td className="text-right">
                                                            {
                                                                this.between(this.getNumberDay(invoice), 151, 180) &&
                                                                amountOwedWithComma
                                                            }
                                                        </td>
                                                    }
                                                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "over180")  &&
                                                        <td className="text-right">
                                                            {
                                                                this.getNumberDay(invoice) > 180 && 
                                                                amountOwedWithComma 
                                                            }
                                                        </td>
                                                    }
                                                    <td className="text-right">{numberWithComma(total_contact, "")}</td>
                                                    {!invoice_select[t_index + 1]?                                                           
                                                            this.props.props.invoiceViewer.allInvoice.edges[index+1] ? 
                                                            <td className="text-right">{numberWithComma(outstandingBalanceOfCustomer, "")}</td>
                                                            :
                                                            <td className="text-right">{numberWithComma(outstandingBalanceForThelastLine, "")}</td>
                                                    :<td>{" "}</td>  
                                                    }                                                
                                                </tr>
                                            )
                                        )
                                    }
                                }
                                //return null;
                            })
                        )
                    })
                }
                {
                    (this.props.props.invoiceViewer.allInvoice.pageInfo.hasNextPage || this.props.props.invoiceViewer.allInvoice.pageInfo.hasPreviousPage) &&
                    
                    <tr>
                        <td colSpan={9} className="text-right"><strong>{i18next.t("receivableOutstandingReport:Sum")}</strong></td>
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "not_overdue") && <td className="text-right"><strong>{numberWithComma(sum_totals[0], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "1-30") &&<td className="text-right"><strong>{numberWithComma(sum_totals[1], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "31-60") &&<td className="text-right"><strong>{numberWithComma(sum_totals[2], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "61-90") &&<td className="text-right"><strong>{numberWithComma(sum_totals[3], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "91-120") &&<td className="text-right"><strong>{numberWithComma(sum_totals[4], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "121-150") &&<td className="text-right"><strong>{numberWithComma(sum_totals[5], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "151-180") &&<td className="text-right"><strong>{numberWithComma(sum_totals[6], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "over180") &&<td className="text-right"><strong>{numberWithComma(sum_totals[7], "")}</strong></td>}
                        <td className="text-right"><strong>{numberWithComma(sum_totals.reduce((a, b) => a + b, 0), "")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(sum_totals.reduce((a, b) => a + b, 0), "")}</strong></td>
                    </tr>
                }
                {/* {!this.props.props.invoiceViewer.allInvoice.pageInfo.hasNextPage && */}
                <tr>
                    <td colSpan={9} className="text-right">
                        {
                            this.props.state.summaryReceivableOutstandingLoading && <span className="spinner-border spinner-border-sm align-middle mr-3"></span> 
                        }
                        <strong>{i18next.t("receivableOutstandingReport:Total")}</strong>
                    </td>
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "not_overdue") &&<td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding[0], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "1-30") &&<td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding[1], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "31-60") &&<td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding[2], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "61-90") &&<td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding[3], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "91-120") &&<td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding[4], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "121-150") &&<td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding[5], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "151-180") &&<td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding[6], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "over180") &&<td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding[7], "")}</strong></td>}
                    <td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding.reduce((a, b) => a + b, 0), "")}</strong></td>
                    <td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding.reduce((a, b) => a + b, 0), "")}</strong></td>
                </tr>
                {/* } */}
            </React.Fragment>
        )
    }
}

export default ReceivableOutstandingReportTable;
