import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import '../styles/withholdingTaxPrint.scss';
import i18n  from 'i18next';
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';
const ThaiBaht = require('thai-baht-text');

class AdvanceRecord extends Component {

    constructor(props) {
        super(props);
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];
        this.state = {
            bangkokCode: bangkokCode,
            languages: "th",
        };

        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
    }

    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {

        if (type === "district" && this.isBangkok(code)) {
            return "แขวง"
        } else if (type === "city" && this.isBangkok(code)) {
            return "เขต"
        } else if (type === "province" && this.isBangkok(code)) {
            return ""
        } else if (type === "province") {
            return "จ."
        } else if (type === "district") {
            return "ต."
        } else if (type === "city") {
            return "อ."
        }
    }
    
    componentWillMount() {
        i18n.changeLanguage(this.props.languages);
    }

    componentDidMount() {
        this.setState({
            languages: this.props.languages,
        })
        setTimeout(() => {
            let page = $('.' + this.props.pageNumber);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.chart-of-account').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + signature);
            $('.' + this.props.pageNumber + ' table').css({ borderBottom: "1px solid" });
            if (this.props.current_page !== this.props.end_page) {
                $('.' + this.props.pageNumber + ' tbody tr:last-child td').css({ paddingBottom: diff });
            } else {
                $('.' + this.props.pageNumber + ' tbody tr:last-child td').css({ paddingBottom: diff });
            }
        }, 200);

    }


    render() {
        return (

            <React.Fragment>
                <div className={"print-page-a4 " + this.props.pageNumber} id="receive">
                    <div className="subpage">
                        <div className="head">
                            <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                                <div style={{ minHeight: 100, minWidth: 100 }}>
                                    <img src={this.props.selfProject.logo} alt="silverman" />
                                </div>
                                <div>
                                    <div className="title">
                                        <strong>{this.state.languages === 'en' ? this.props.selfProject.nameEn : this.props.selfProject.name}</strong>
                                    </div>
                                    <div
                                        className="title">{this.state.languages === "en" ?  (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address} โทร {this.props.selfProject.juristicContactNumber || '-'}
                                    </div>
                                    <div>เลขประจำตัวผู้เสียภาษี {this.props.selfProject.taxIdNumber || '-'}</div>
                                </div>

                            </div>

                            <div className="document-name">
                                <div className="title">ใบสำคัญจ่าย</div>
                                <div className="title">Payment Voucher</div>
                            </div>
                        </div>

                        <div className="detail">
                            <div className="customer-detail">
                                <strong>ผู้เบิก/Withdrawer:</strong>{this.props.advance.withdrawer} <br />
                                <br />
                            </div>

                            <div className="document-detail" >
                                <strong
                                    className="outsider">เลขที่/No:</strong> {this.props.allAccountRecordGroup.edges[0].node.refTransaction}
                                <br />
                                <strong
                                    className="outsider">วันที่/Date:</strong> {format(this.props.allAccountRecordGroup.edges[0].node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                            </div>
                        </div>

                        <div className="chart-of-account">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th width={100} className="text-center">
                                            รหัสบัญชี
                                        </th>
                                        <th className="text-center">
                                            ชื่อบัญชี
                                        </th>
                                        <th width={100} className="text-center">
                                            เดบิต
                                        </th>
                                        <th width={100} className="text-center">
                                            เครดิต
                                        </th>
                                    </tr>
                                </thead>

                                <React.Fragment>
                                    <tbody>
                                        {_.orderBy(this.props.allAccountRecordGroup.edges[0].node.accountRecord.edges, 'node.id', 'asc').map((account_record) =>
                                            account_record.node.debit > 0 &&
                                            <tr key={account_record.node.id}>
                                                <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                <td>{account_record.node.name}</td>
                                                <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                            </tr>
                                        )}
                                        {this.props.allAccountRecordGroup.edges[0].node.accountRecord.edges.map((account_record) =>
                                            account_record.node.credit > 0 &&
                                            <tr key={account_record.node.id}>
                                                <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                <td>{account_record.node.name}</td>
                                                <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                            </tr>
                                        )}
                                    </tbody>

                                    <tfoot className="font-weight-bold">
                                        {(this.props.current_page === this.props.end_page) &&
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row">
                                                        <div className="col-1">
                                                            รวม/Total
                                                        </div>
                                                        <div className="col-10 text-center">
                                                        {i18n.languages[0] === 'th' ?
                                                        (ThaiBaht(this.props.allAccountRecordGroup.edges[0].node.totalDebit))
                                                        :
                                                        (ConvertNumberToTextEng(this.props.allAccountRecordGroup.edges[0].node.totalDebit))    
                                                        }   
                                                        </div>
                                                        <div className="col-1" />
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    {numberWithComma(this.props.allAccountRecordGroup.edges[0].node.totalDebit.toFixed(2))}
                                                </td>
                                                <td className="text-right">
                                                    {numberWithComma(this.props.allAccountRecordGroup.edges[0].node.totalCredit.toFixed(2))}
                                                </td>
                                            </tr>
                                        }
                                    </tfoot>
                                </React.Fragment>
                            </table>
                        </div>

                        <div className="footer">
                            {(this.props.current_page === this.props.end_page) &&
                                <div className="signature-large">
                                    <div className="column-foursign" >
                                        .........................................................<br />
                                        <span className="font-weight-bold">ผู้จัดทำ / Organizer</span>
                                    </div >
                                    <div className="column-foursign">
                                        .........................................................<br />
                                        <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span>
                                    </div>
                                    <div className="column-foursign">
                                        .........................................................<br />
                                        <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span>
                                    </div>
                                    <div className="column-foursign">
                                        .........................................................<br />
                                        <span className="font-weight-bold">ผู้รับเงิน / Receiver</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="number-page">
                            <br />
                            {this.props.current_page}/{Math.ceil(this.props.count_page)}
                        </div>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default AdvanceRecord;
