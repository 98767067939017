import React, {Component} from 'react';
import Swal from "sweetalert2";
import i18n from "i18next"
import { format } from 'date-fns';

class UploadImageInput extends Component {
    constructor(props) {
        super(props);
        this.handleInputImage = this.handleInputImage.bind(this);
    }

    async handleInputImage(e) {

        let file = e.currentTarget.files[0];
        let new_file = new File([file], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${file.name}`);
        // validate image
        let fileValidated = await this.readUploadedFile(new_file);
        if (!fileValidated) {
            return
        }

        let fields = this.props.setField.split(':');

        // eslint-disable-next-line
        for (let index in fields) {
            if (index % 2 !== 0) {
                //field preview
                new_file = URL.createObjectURL(new_file)
            }
            this.props.onChange({
                target: {
                    type: 'file',
                    name: fields[index],
                    value: new_file,
                }
            });
        }
    }

    readUploadedFile = inputFile => {
        let file_reader = new FileReader();

        return new Promise((resolve, reject) => {
            file_reader.onerror = () => {
                file_reader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            file_reader.onload = () => {
                let img = new Image();
                img.onload = function () {
                    if (img.width >= 10000 && img.height >= 10000 || img.sizes >= 10485760) {
                        Swal.fire(i18n.t("complaint:Image size is too big"), i18n.t("complaint:Please select a new image"), 'warning');
                        resolve(false)
                    } else {
                        resolve(true)
                    }

                };
                img.src = file_reader.result;
            };
            if (inputFile) {
                file_reader.readAsDataURL(inputFile);
            }
        });
    };


    render() {
        return (
            <input type="file" className="custom-file-input" disabled={this.props.disabled}
                   id={this.props.id} name={this.props.name}
                   accept="image/*" required={this.props.required} value=""
                   onChange={this.handleInputImage} hidden={this.props.hidden}/>
        );
    }

}

export default UploadImageInput;
