/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type invoiceListTableQueryVariables = {|
  status?: ?string,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  first?: ?number,
  last?: ?number,
  is_contact_online?: ?boolean,
|};
export type invoiceListTableQueryResponse = {|
  +invoiceViewer: ?{|
    +allInvoice: ?{|
      +totalCount: ?number
    |}
  |},
  +selfProject: ?{|
    +name: string,
    +address: ?string,
    +logo: ?string,
  |},
|};
export type invoiceListTableQuery = {|
  variables: invoiceListTableQueryVariables,
  response: invoiceListTableQueryResponse,
|};
*/


/*
query invoiceListTableQuery(
  $status: String
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $first: Int
  $last: Int
  $is_contact_online: Boolean
) {
  invoiceViewer {
    allInvoice(status: $status, search: $search, status_In: "active, overdue, paid, partial_payment", startDate: $start_date, endDate: $end_date, first: $first, last: $last, isContactOnline: $is_contact_online) {
      totalCount
    }
    id
  }
  selfProject {
    name
    address
    logo
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "is_contact_online"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "end_date"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "isContactOnline",
      "variableName": "is_contact_online"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    },
    {
      "kind": "Variable",
      "name": "startDate",
      "variableName": "start_date"
    },
    {
      "kind": "Variable",
      "name": "status",
      "variableName": "status"
    },
    {
      "kind": "Literal",
      "name": "status_In",
      "value": "active, overdue, paid, partial_payment"
    }
  ],
  "concreteType": "InvoiceNodeConnection",
  "kind": "LinkedField",
  "name": "allInvoice",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "invoiceListTableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "invoiceListTableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0315e95e96008f8fad6f01f96d33657c",
    "id": null,
    "metadata": {},
    "name": "invoiceListTableQuery",
    "operationKind": "query",
    "text": "query invoiceListTableQuery(\n  $status: String\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $first: Int\n  $last: Int\n  $is_contact_online: Boolean\n) {\n  invoiceViewer {\n    allInvoice(status: $status, search: $search, status_In: \"active, overdue, paid, partial_payment\", startDate: $start_date, endDate: $end_date, first: $first, last: $last, isContactOnline: $is_contact_online) {\n      totalCount\n    }\n    id\n  }\n  selfProject {\n    name\n    address\n    logo\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '43cd34c10843b8df6e1650ad2e6ebf30';

module.exports = node;
