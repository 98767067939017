/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateDocumentAddressQueryVariables = {|
  id: string
|};
export type updateDocumentAddressQueryResponse = {|
  +mailing: ?{|
    +id: string,
    +address: ?string,
    +mailingVillageBuilding: ?string,
    +mailingVillageNumber: ?string,
    +mailingLane: ?string,
    +mailingPostalCode: ?string,
    +mailingProvince: ?string,
    +mailingDistrict: ?string,
    +mailingSubDistrict: ?string,
  |},
  +register: ?{|
    +id: string,
    +name: string,
    +homeNumber: ?string,
    +villageBuilding: ?string,
    +villageNumber: ?string,
    +lane: ?string,
    +postalCode: ?string,
    +province: ?string,
    +district: ?string,
    +subDistrict: ?string,
  |},
|};
export type updateDocumentAddressQuery = {|
  variables: updateDocumentAddressQueryVariables,
  response: updateDocumentAddressQueryResponse,
|};
*/


/*
query updateDocumentAddressQuery(
  $id: ID!
) {
  mailing: residential(id: $id) {
    id
    address
    mailingVillageBuilding
    mailingVillageNumber
    mailingLane
    mailingPostalCode
    mailingProvince
    mailingDistrict
    mailingSubDistrict
  }
  register: residential(id: $id) {
    id
    name
    homeNumber
    villageBuilding
    villageNumber
    lane
    postalCode
    province
    district
    subDistrict
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": "mailing",
    "args": (v1/*: any*/),
    "concreteType": "ResidentialNode",
    "kind": "LinkedField",
    "name": "residential",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingVillageBuilding",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingVillageNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingLane",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingPostalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingProvince",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingDistrict",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingSubDistrict",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "register",
    "args": (v1/*: any*/),
    "concreteType": "ResidentialNode",
    "kind": "LinkedField",
    "name": "residential",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "homeNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "villageBuilding",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "villageNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lane",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "province",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "district",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subDistrict",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateDocumentAddressQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateDocumentAddressQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "845b4ce7a209b03b92dee143cc2ddbb3",
    "id": null,
    "metadata": {},
    "name": "updateDocumentAddressQuery",
    "operationKind": "query",
    "text": "query updateDocumentAddressQuery(\n  $id: ID!\n) {\n  mailing: residential(id: $id) {\n    id\n    address\n    mailingVillageBuilding\n    mailingVillageNumber\n    mailingLane\n    mailingPostalCode\n    mailingProvince\n    mailingDistrict\n    mailingSubDistrict\n  }\n  register: residential(id: $id) {\n    id\n    name\n    homeNumber\n    villageBuilding\n    villageNumber\n    lane\n    postalCode\n    province\n    district\n    subDistrict\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '67f89d540387a4f721e0bc2908822e26';

module.exports = node;
