import React, { Component } from 'react';
import { format } from "date-fns";
import QRCode from "qrcode.react";
import API from '../../api/sing'
import Swal from 'sweetalert2';
import { Redirect } from 'react-router';
import './styles/printQrAsset.scss';
import i18next from 'i18next';
class assetPrintQrCode extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.state = {
            data: {
                "qr": "",
                "id": "",
                "docNumber": "",
                "name": "",
                "type": "",
                "storage": "",
                "purchase_date": "",
                "remark": ""
            },
            urlPath: "",
            redidect: false,
            loading: true,
        }
        this.colorPickerRef = React.createRef();

    }

    componentWillMount() {
        this.getDataId()
    }

    getDataId() {
        let Code = this.props.match.params.assetPrintQrCode
        API.getassetAll().then((res) => {
            if (res.data.asset) {
                let dataFilter = []
                let url = window.location.origin
                dataFilter = res.data.asset.filter((n) => n.docNumber === Code)
                if (dataFilter.length > 0) {
                    this.setState({ data: dataFilter[0], loading: false, urlPath: url })
                } else {
                    Swal.fire(i18next.t("Allaction:Not found"), i18next.t("Allaction:Please try again."), 'error').then(() => {
                        this.setState({ redidect: true, loading: false })
                    })
                }
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error').then(() => this.setState({ loading: false })))
    }



    print() {       

        window.print()
        // html2canvas($("#SubprintQRAsset"), {
        //     onrendered: function(canvas) {
        //         theCanvas = canvas;
        //         document.body.appendChild(canvas);

        //         // Convert and download as image 
        //         Canvas2Image.saveAsPNG(canvas); 
        //         $("#img-out").append(canvas);
        //         // Clean up 
        //         //document.body.removeChild(canvas);
        //     }
        // });
        
        // const saveFile = async (blob) => {
        //     var a = document.createElement('a');
        //     a.download = 'QrrCodeAsset.png';
        //     a.href = URL.createObjectURL(blob);
        //     a.addEventListener('click', (e) => {
        //       setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        //     });
        //     a.click();
        //   };
          
        //   const blob = new Blob([], {type : 'image/png'});
          
        //   saveFile(blob);
    }

    render() {
        if (this.state.redidect) {
            return <Redirect to={"/accounting/asset/all"} />
        }


        return (
            <div>
                {
                    // localStorage.getItem('site_id') && localStorage.getItem('site_id') === '331' &&
                    this.state.loading == false && 
                    <div id='printQRAsset'>


                        <div className="barLogo" >
                            <div className="logo" style={{ float: 'left' }}>
                                <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                            </div>
                            <div className="btn btn-primary" onClick={this.print} style={{ float: 'right' }}>
                                <img src={process.env.PUBLIC_URL + '/images/icons/iconPrint.png'} /> พิมพ์
                            </div>
                        </div>

                        <section className='content-bodys'>
                            <div className='row text-center'>

                                <div id='SubprintQRAsset' className='divToPrint mx-auto'>
                                    <div className='card text-left' >
                                        <label>Code: {this.state.data.docNumber}</label>
                                        {
                                            this.state.data.name.length > 26 &&
                                            <label style={{ fontSize: "9px" }}>Name: {this.state.data.name}</label>

                                            ||

                                            <label>Name: {this.state.data.name}</label>
                                        }


                                        <div className='boxQRCode '>
                                            <QRCode value={this.state.urlPath + "/scanToRepair/" + this.state.data.qr} size={60} />
                                        </div>

                                        <label className='mtop-1 lcabel-20'>
                                            Type: {
                                                this.state.data.type === "land" ? "ที่ดิน" :
                                                    this.state.data.type === "building" ? "อาคาร" :
                                                        this.state.data.type === "building_renovation" ? "ส่วนต่อเติมและปรับปรุงอาคาร" :
                                                            this.state.data.type === "office_decoration" ? "เครื่องตกแต่งสำนักงาน" :
                                                                this.state.data.type === "office_equipment" ? "อุปกรณ์และเครื่องใช้สำนักงาน" :
                                                                    this.state.data.type === "tools" ? "เครื่องมือ / อุปกรณ์งานช่าง" :
                                                                        this.state.data.type === "training_equipment" ? "เครื่องออกกำลังกาย / สันทนาการ" :
                                                                            this.state.data.type === "vehicle" ? "ยานพาหนะ" :
                                                                                this.state.data.type === "common_property" ? "ทรัพย์สินส่วนกลาง" :
                                                                                    this.state.data.type === "intangible_asset" ? "สินทรัพย์ไม่มีตัวตน" :
                                                                                        this.state.data.type === "other_asset" ? "สินทรัพย์อื่นๆ" : "-"
                                            }
                                        </label>
                                        <label className='lcabel-20'>Location: {this.state.data.storage}</label>
                                        <label className='lcabel-20'>Received Date: {format(this.state.data.purchase_date + "T00:00", "DD/MM/YYYY")}</label>
                                        <label className='lcabel-20'>Remark: {this.state.data.remark}</label>

                                    </div>
                                </div>
                            </div>


                            {/* <div className='row'>
                                <div className='cardQr mx-auto'>
                                    <div className="btn btn-primary printBTN" onClick={this.print}>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/iconPrint.png'} /> พิมพ์
                                    </div>
                                </div>

                            </div> */}



                        </section>




                    </div>
                }
            </div>


        )
    }
}

export default assetPrintQrCode;
