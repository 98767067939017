export default function CountThaiConsonants(text) {
    const thaiConsonants = 'กขฃฆคฅชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬฮ';
    let count = 0;

    for (const char of text) {
        if (thaiConsonants.includes(char)) {
            count++;
        }
    }

    return count;
}