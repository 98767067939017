import { format } from "date-fns";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";

const GetdateOnEngAndTh = (props) => {

    const [date, SetDate] = useState(props?.date)

    useEffect(() => {
        SetDate(props?.date)
    }, [props?.date])

    return (
        <Translation>
            {t =>
                <React.Fragment>
                    {props?.withDate && format(new Date(date), "D ")}

                    {props?.withMonthShort && i18next.t("monthNameShort:" + format(new Date(date), "MMM"))}
                    {props?.withFullMonth && i18next.t("monthNameShort:" + format(new Date(date), "MMMM "))}

                    {props?.withFullYear && format(new Date(date), " YYYY")}
                    {props?.withYearShort && format(new Date(date), " YY")}
                </React.Fragment>

            }
        </Translation>

    )
}

export default GetdateOnEngAndTh;