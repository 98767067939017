import React, { useState, useEffect } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "react-relay";
import environment from "../../env/environment";
import '../styles/termOfuser.scss'
import { Translation } from 'react-i18next';
import Loading from '../../libs/loading';
import { Redirect } from 'react-router';

const query = graphql`
    query checkSiteQuery ($username: String, $domain: String) {
    authAdmin (username: $username, domain: $domain) {
        token
        permission
        result
        haveVat
        url
        projectName
        tenant
        notification
        acceptPolicy
        acceptTermOfUse
        acceptMarketing
        acceptPartner
        message
        messageTh
    }
    allExtraMenu
}
`;


const CheckSite = (props) => {
    // const [langauge, setLangauge] = useState(localStorage.getItem("language") ?? 'th')
    const [Redirecting, setRedirect] = useState(false)


    useEffect(() => {
        getProjectList()
    }, [])

    const getProjectList = () => {

        let user = props.match.params.user
        let site = props.match.params.site

        window.localStorage.setItem("token", props.match.params.token)
        fetchQuery(environment, query, {
            "domain": site,
            "username": user,
            "role": 'admin'
        }).then(data => {
            // console.log(data.authAdmin);
            if (data?.authAdmin?.token) {
                localStorage.setItem("selPr", "true")
                localStorage.setItem("token", data.authAdmin.token)
                localStorage.setItem("permission_list", data.authAdmin.permission);
                localStorage.setItem("allExtraMenu", data.allExtraMenu)
                localStorage.setItem('checkStatusPolicyAndTerms', data.authAdmin.acceptPolicy)
                localStorage.setItem('project_have_vat', data.authAdmin.haveVat);
                setRedirect(true)
                // window.location.href = '/home'
            }
        })
    }

    if (Redirecting) {
        return <Redirect to={"/home"} />
    }

    // const changeLangauge = (lang) => {
    //     setLangauge(lang == false ? 'en' : 'th')
    // }

    return (
        <Translation>
            {t =>
                <div className="container-fluid box colorUse" key="SelectProject">
                    <Loading />
                </div>
            }
        </Translation >
    )
}
export default CheckSite