import React, { Component } from "react";
import { Link } from "react-router-dom";
import { addDays, differenceInCalendarDays, format } from "date-fns";

import PurchaseSummary from "./PurchaseSummary";
import PurchaseDelete from "./PurchaseDelete";
import PurchaseCreateDetailContact from "./PurchaseCreateDetailContact";
// import PurchaseRunNoModal from "./PurchaseRunNoModal";
// import PurchaseWaterMeterSelectDate from "./PurchasewaterMeterSelectDate";
// import PurchasePeriodAdjustSetting from "./PurchaseperiodAdjustSetting";
// import PurchaseIndividualAreaPriceSettings from "./PurchaseindividualAreaPriceSetting";
// import PurchaseCarParkSelect from "./PurchasecarParkSelect.js";

import SearchSelect from "../../../../libs/searchSelect";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import numberWithComma from "../../../../libs/numberWithComma";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { fetchQuery } from "relay-runtime";
import { Redirect } from "react-router";

import "./css/PurchaseCreateUpdateForm.scss";
import Swal from "sweetalert2";
import getDocumentNumber from "../../../../libs/getDocumentNumber";
import CreatePurchaseMutation from "../mutations/CreatePurchaseMutation";
import {
  prepaidDepositMutation,
  setFormatPrepaidDepositMutation,
} from "../../prepaid_deposit/prepaidDepositMutation";
import getNameResidential from "../../../../libs/getNameResidential";
import UploadFileInput from "../../../../libs/uploadFileInput";
// import { formatDateISO, formatDateLocale } from "../../../../utils";
import _ from "lodash";
import { Translation } from "react-i18next";
import i18next from "i18next";

const query = graphql`
  query PurchaseCreateUpdateFormQuery(
    $contact: [String]!
    $taxType: String
    $vat: String
  ) {
    prepaidDepositViewer {
      allPrepaidDeposit(
        contactList: $contact
        taxType: $taxType
        vat: $vat
        status_In: "active, use_partial"
      ) {
        edges {
          node {
            id
            docNumber
            prepaidDepositTransaction {
              edges {
                node {
                  id
                  total
                  price
                  balance
                  vat
                  vatAmount
                  whtAmount
                  whtRatePercent
                  chartOfAccount {
                    id
                    name
                    chartOfAccountCode
                  }

                  prepaidDepositTransactionUsed {
                    edges {
                      node {
                        id
                        amount
                        purchaseRecord {
                          purchaseRecordGroup {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allProjects {
            edges {
            node {
            periodCreate
            }
      }
    }
  }
`;

let transaction_object = {
  description: "",
  discount: 0,
  id: "",
  preTaxAmount: 0,
  price: "",
  productAndService: {},
  unitItems: 1,
  vat: 7,
  vatAmount: 0,
  whtRate: 0,
  whtRatePercent: 0,
  totalLeftInStock: "",

  purchase_orderes: false,
  PurchaseOrderesId: null,
  unit: "",
  unitNameproduct: {},
  unitRunType: "",
  modalManual: false,
  modalAuto: false,
  runtxt: "",
  runnumber: 0,
  assetType: null,
  facilityUse: "",
};
class PurchaseCreateUpdateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      issuedDate: new Date(),
      dueDateCount: 7,
      dueDate: addDays(new Date(), 7),
      taxType:
        !props.project_have_vat &&
          (props.purchase_form || props.other_expense) &&
          props.contact &&
          props.contact.vat === 7
          ? "INCLUDE_TAX"
          : "NO_TAX",
      remark: "",
      total: 0,
      transaction_list: [],
      redirectToList: false,
      category: props.category,
      chartOfAccountId: "",
      product_and_service: props.product_and_service,

      tax_invoice_number: "",
      ref_number: "",

      prepaidDeposit: 0,
      tax_invoice_date: new Date(),
      tax_invoice_period: new Date(),
      loading: false,
      payChannel: [],
      upload: {},
      contactList: props.contactList,
      prepaidDepositTransaction: [],
      totalPay: 0,
      total_discount: 0,
      total_wht_rate: 0,
      document_upload: "",
      status: "draft",
      vat: null,
      case_return_vat:
        !props.project_have_vat &&
          (props.purchase_form || props.other_expense) &&
          props.contact &&
          props.contact.vat === 7
          ? "true"
          : false,
      PurchaseOrderesId: null,
      assetDate: false,
      PurchaseOrderesType: "",
      PurchaseOrderesTypeReal: "",
      PurchaseOrderesTypeTable: "",
      PurchaseOrderesissuedDate: "",
      // Modal Use
      runNoType: "",
      item_select: [],
      index_select: 0,
      signreq: Math.random().toString(36),
      temp_month_before: '',
      temp_month_after: '',
      period_create: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitFrom = this.submitFrom.bind(this);
    this.appendNewRow = this.appendNewRow.bind(this);
    this.getPreTaxAmount = this.getPreTaxAmount.bind(this);
    this.setTransactionList = this.setTransactionList.bind(this);
    this.submitPurchase = this.submitPurchase.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.onSuccessPrepaid = this.onSuccessPrepaid.bind(this);
    this.handleInputDocument = this.handleInputDocument.bind(this);
    // this.setServicePeriod = this.setServicePeriod.bind(this);
    // this.setWaterMeterTransaction = this.setWaterMeterTransaction.bind(this);
    // this.setElectricMeterTransaction = this.setElectricMeterTransaction.bind(this);
    // this.setIndividualTransaction = this.setIndividualTransaction.bind(this);
    // this.setCarParkTransaction = this.setCarParkTransaction.bind(this);
    this.getDeposit = this.getDeposit.bind(this);
    this.getRemark = this.getRemark.bind(this);
    this.updateModalState = this.updateModalState.bind(this);
  }

  removeTrailingZeros(description) {
    if (description.includes("ค่าน้ำประปา")) {
      description = description.replace(/ *\[[^)]*\] */g, " ");
    } else {
      description = description.replace(/ *\[[^)]*\] */g, " ");
    }
    return description;
  }

  componentWillMount() {
    // create new
    this.setState(
      {
        transaction_list: [
          ...this.state.transaction_list,
          { ...transaction_object },
        ],
      },
      () => {
        if (this.state.case_return_vat) {
          let transaction_list = [...this.state.transaction_list];
          transaction_list.forEach((transaction) => {
            transaction["vat"] = 7;
            transaction["preTaxAmount"] = this.getPreTaxAmount(transaction);
            transaction["whtRate"] = this.getWhtRate(transaction);
          });
          this.setState({ transaction_list: [...transaction_list], vat: 7 });
        }
      }
    );

    if (this.props.chart_of_account) {
      let product_and_services = [];
      product_and_services = [...this.props.product_and_service];
      let chart_of_accounts = [...this.props.chart_of_account];

      chart_of_accounts.forEach((chart_of_account) => {
        let list_object = {
          id: chart_of_account.node.id,
          name: chart_of_account.node.name,
          productCode: chart_of_account.node.chartOfAccountCode,
          totalLeftInStock: null,
          price: "",
          chartOfAccount: {
            id: chart_of_account.node.id,
            chartOfAccountCode: chart_of_account.node.chartOfAccountCode,
            name: chart_of_account.node.name,
          },
          type: "chart_of_account",
        };
        product_and_services = [...product_and_services, { node: list_object }];
      });

      this.setState(
        {
          product_and_service: product_and_services,
          taxType:
            !this.props.project_have_vat &&
              (this.props.purchase_form || this.props.other_expense) &&
              this.props.contact &&
              this.props.contact.vat === 7
              ? "INCLUDE_TAX"
              : "NO_TAX",
        },
        () => {
          if (this.state.category === "purchase") {
            this.getDeposit();
          }
        }
      );
    }
  }

  componentDidMount() {
    let variable = {
      contact: this.props.contactList,
      taxType: this.state.taxType,
    };

    fetchQuery(environment, query, variable)
      .then(response_data => {
        if (response_data) {
          let data = _.cloneDeep(response_data);
          this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
        }
      })
  }

  calPeriodMouth(val) {
    let month_before = new Date();
    let month_after = new Date();

    month_before.setMonth(month_before.getMonth() - parseInt(val));
    month_after.setMonth(month_after.getMonth() + parseInt(val));

    this.setState({
      temp_month_before: month_before,
      temp_month_after: month_after,
    })
  }

  setPrepaidDepositUse(prepaid_deposit = null) {
    let prepaidDepositTransaction = [];
    prepaid_deposit.edges.forEach((pd_group) => {
      pd_group.node.prepaidDepositTransaction.edges.forEach((pd_trans) => {
        let checked = false;
        if (!(pd_trans.node.balance === 0 && !checked)) {
          prepaidDepositTransaction.push({
            prepaid_transaction: pd_trans.node,
            docNumber: pd_group.node.docNumber,
            checked: checked,
          });
        }
      });
    });

    this.setState({ prepaidDepositTransaction: prepaidDepositTransaction });
  }

  appendNewRow() {
    transaction_object.vat = this.state.transaction_list[0].vat;
    this.setState({
      transaction_list: [
        ...this.state.transaction_list,
        { ...transaction_object },
      ],
    });
  }

  getPreTaxAmount(transaction) {
    let pre_tax_amount =
      transaction.unitItems * transaction.price - transaction.discount;
    return parseFloat(pre_tax_amount.toFixed(2));
  }

  getWhtRate(transaction) {
    return (
      this.getPreTaxAmount(transaction) *
      ((transaction.whtRatePercent || 0) / 100)
    );
  }

  getVat(transaction) {
    const vatAmount =
      this.getPreTaxAmount(transaction) * ((transaction.vat || 0) / 100);
    return parseFloat(vatAmount.toFixed(2));
  }

  submitFrom(e) {
    e.preventDefault();
    this.setState({ loading: true });
    if (this.state.category === "purchase") {
      this.submitPurchase();
    } else if (this.state.category === "prepaid_deposit") {
      let variables = setFormatPrepaidDepositMutation(this.state);
      if (variables) {
        prepaidDepositMutation(variables, this.onSuccessPrepaid, this.onError);
      }
    }
  }

  onSuccessPrepaid(response) {
    this.setState({ loading: false });
    if (response.createPrepaidDepositAndTransaction.ok) {
      Swal.fire(i18next.t("Alaction:successcompleate"), "", "success").then(() => {
        this.setState({ redirectToList: true });
      });
    } else {
      Swal.fire(
        i18next.t("Alaction:Saved Unsuccessful"),
        response.createPrepaidDepositAndTransaction.warningText,
        "warning"
      );
    }
  }

  submitPurchase() {
    //ตรวจสอบ run no. ว่ามีไหม
    if (this.state.PurchaseOrderesTypeTable.toLocaleLowerCase() === "product") {
      let check_unitNameproduct = false;
      this.state.transaction_list.forEach((e) => {
        if (e.productAndService.noNumber && _.isEmpty(e.unitNameproduct)) {
          check_unitNameproduct = true;
        }
      });
      if (check_unitNameproduct) {
        this.setState({ loading: false });
        Swal.fire( i18next.t("Alaction:Saved Unsuccessful"), i18next.t("PurchaseCreate:You don't enter product number"), "warning");
        return;
      }
    }

    //ตรวจสอบว่า PRODUCT มีการตั้งค่าบัญชีซื้อขายสินค้าหรือยัง
    let creditorChartOfAccountProductCheckNull = false;
    let creditorChartOfAccountProductCheckNullName = "";
    this.state.transaction_list.forEach((e) => {
      if (
        e.productAndService.type === "PRODUCT" &&
        e.productAndService.creditorChartOfAccount === null
      ) {
        creditorChartOfAccountProductCheckNull = true;
        creditorChartOfAccountProductCheckNullName =
          e.productAndService.productCode + " " + e.productAndService.name;
      }
    });

    if (creditorChartOfAccountProductCheckNull) {
      this.setState({ loading: false });
      Swal.fire(
        i18next.t("Allaction:Saved Unsuccessful"),
        i18next.t("PurchaseCreate:You don't setup account buy and sell products") +
        creditorChartOfAccountProductCheckNullName,
        "warning"
      );
      return;
    }

    let purchase = {
      id: this.state.group_detail ? this.state.group_detail.id : "",
      issuedDate: format(this.state.issuedDate, "YYYY-MM-DD"),
      dueDate: format(this.state.dueDate, "YYYY-MM-DD"),
      taxType: this.state.taxType.toLowerCase(),
      remark: this.state.remark,
      status: "active",
      creator: "",
      approver: "",
      updater: "",
      total: this.state.total < 0 ? 0 : this.state.total,
      total_no_pd: this.state.total_no_pd < 0 ? 0 : this.state.total_no_pd,
      tax_invoice_number: this.state.tax_invoice_number,
      tax_invoice_date: format(this.state.tax_invoice_date, "YYYY-MM-DD"),
      tax_invoice_period: format(this.state.tax_invoice_period, "YYYY-MM-DD"),
      ref_number: this.state.ref_number,

      chartOfAccountId: this.state.chartOfAccountId,

      purchase_orderes_id: this.state.PurchaseOrderesId,
      purchase_orderes_type: this.state.PurchaseOrderesTypeReal,
      purchase_orderes_type_table: this.state.PurchaseOrderesTypeTable,
      purchase_orderes_issued_date: this.state.PurchaseOrderesissuedDate,
    };
    let uploadables = { document_upload: this.state.document_upload };
    let variables = {
      input: {
        contactList: this.props.contactList,
        purchase: JSON.stringify(purchase),
        transactionList: JSON.stringify(this.state.transaction_list),
        prepaidDepositUse: JSON.stringify(this.state.prepaidDepositTransaction),
        clientMutationId: "PR" + this.state.signreq,
      },
    };

    // CreatePurchaseMutation(variables, uploadables, this.onSuccess, this.onError);

    if (this.state.assetDate) {
      Swal.fire({
        title: i18next.t("PurchaseCreate:Inspection notification system"),
        text: i18next.t("PurchaseCreate:Please check the date used to calculate asset depreciation."),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: i18next.t("Allaction:cancel"),
        confirmButtonText: i18next.t("Allaction:Comfirm"),
      }).then(async (result) => {
        if (result.value) {
          CreatePurchaseMutation(
            variables,
            uploadables,
            this.onSuccess,
            this.onError
          );
        } else {
          this.setState({ loading: false });
        }
      });
    } else {
      CreatePurchaseMutation(
        variables,
        uploadables,
        this.onSuccess,
        this.onError
      );
    }
  }

  onSuccess(response) {
    this.setState({ loading: false });
    if (response.updatePurchaseRecordGroupAndTransaction.ok) {
      Swal.fire(i18next.t("Allaction:Saved Successfully"), "", "success").then(() => {
        if (!this.state.group_detail) {
          this.setState({ redirectToList: true });
        }
      });
    } else {
      Swal.fire(
        i18next.t("Allaction:Saved Unsuccessful"),
        response.updatePurchaseRecordGroupAndTransaction.warningText,
        "warning"
      );
    }
  }

  onError(err) {
    this.setState({ loading: false });
    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
  }

  setTransactionList(new_transaction) {
    this.setState({ transaction_list: new_transaction });
  }

  handleInputDocument(e) {
    this.setState({
      document_upload: e.currentTarget.files[0],
    });
  }

  getDeposit() {
    let variable = {
      contact: this.props.contactList,
      taxType: this.state.taxType,
      // vat: this.state.transaction_list[0].vat === null ? null : this.state.transaction_list[0].vat,
    };
    fetchQuery(environment, query, variable).then((response_data) => {
      if (this.state.category === "purchase") {
        this.setPrepaidDepositUse(
          response_data.prepaidDepositViewer.allPrepaidDeposit
        );
      }
    });
  }

  handleInputChange(e) {
    if (e.target.name === "prepaidDepositTransaction") {
      let trans_arr = [...this.state.prepaidDepositTransaction];
      trans_arr[e.target.dataset.id]["checked"] = e.target.checked;

      let prepaid_deposit = this.state.prepaidDeposit;
      let sum_amount =
        trans_arr[e.target.dataset.id].prepaid_transaction.balance;
      if (e.target.checked) {
        prepaid_deposit += sum_amount;
      } else {
        prepaid_deposit -= sum_amount;
      }
      this.setState({
        prepaidDepositTransaction: trans_arr,
        prepaidDeposit: prepaid_deposit,
      });

      return;
    }

    if (e.target.name === "dueDateCount") {
      this.setState({
        dueDateCount: e.currentTarget.value,
        dueDate: addDays(this.state.issuedDate, e.currentTarget.value),
      });
    } else if (e.target.name === "PurchaseOrderesId") {
      let all_purchase_orderes = _.find(this.props.allPurchaseOrderes, {
        node: { id: e.target.value },
      });
      //ดึง transaction_list มาใช้
      let transaction_list = [];
      let all_setting_asset_check = false;

      //Loop//
      all_purchase_orderes.node.procurementForm.procurementList.edges.forEach(
        (e) => {
          //ตรวจสอบสินทรัพย์ว่ามีการตั้งค่าหรือยัง
          let all_setting_asset = null;
          if (
            all_purchase_orderes.node.procurementForm.type === "ASSET" &&
            (_.startsWith(e.node.chartOfAccount.chartOfAccountCode, "1210") ||
              _.startsWith(e.node.chartOfAccount.chartOfAccountCode, "1230"))
          ) {
            all_setting_asset = _.find(this.props.allSettingAsset, {
              node: {
                chartOfAccountType: {
                  chartOfAccountCode: e.node.chartOfAccount.chartOfAccountCode,
                },
              },
            });
            if (!all_setting_asset) {
              all_setting_asset_check = true;
            }
          }

          // chartOfAccount to productAndService
          let chart_of_account_list_object = {};
          if (e.node.chartOfAccount) {
            chart_of_account_list_object = {
              id: e.node.chartOfAccount.id,
              name: e.node.chartOfAccount.name,
              productCode: e.node.chartOfAccount.chartOfAccountCode,
              totalLeftInStock: null,
              price: "",
              chartOfAccount: {
                id: e.node.chartOfAccount.id,
                chartOfAccountCode: e.node.chartOfAccount.chartOfAccountCode,
                name: e.node.chartOfAccount.name,
              },
              type: "chart_of_account",
            };
          }

          //code
          // e.node.productAndService.productCode
          let pre_tax_amount =
            e.node.unitItems * e.node.price - e.node.discount;
          const vatAmount =
            Math.round(
              (pre_tax_amount * ((e.node.vat || 0) / 100) + Number.EPSILON) *
              100
            ) / 100;
          const whtRate_value =
            Math.round(
              (pre_tax_amount * ((e.node.whtRate || 0) / 100) +
                Number.EPSILON) *
              100
            ) / 100;

          let unitNameproduct = [];
          if (e.node.productAndService?.autoOrder === false) {
            for (var i = 1; i < e.node.unitItems + 1; i++) {
              let unitNameproduct_objects = {
                number: i,
                docNumber: "",
              };
              unitNameproduct.push(unitNameproduct_objects);
            }
          }

          let transaction_object = {
            description: e.node.description,
            discount: e.node.discount || 0,
            id: "",
            preTaxAmount: e.node.preTaxAmount,
            price: e.node.price,
            productAndService:
              all_purchase_orderes.node.procurementForm.type !== "ASSET"
                ? e.node.productAndService
                : chart_of_account_list_object,
            unit: e.node.unit,
            unitItems: e.node.unitItems,
            vat: e.node.vat,
            vatAmount: vatAmount,
            whtRate: whtRate_value, // e.node.whtRatePercent
            whtRatePercent: e.node.whtRate || 0,
            totalLeftInStock:
              e.node.productAndService?.totalLeftInStock || null,

            purchase_orderes: true,
            procurementListId: e.node.id,
            unitNameproduct: unitNameproduct.length > 0 ? unitNameproduct : {},
            unitRunType: "",
            // modalManual: false,
            // modalAuto: false,
            modalManual:
              e.node.productAndService?.autoOrder === false ? true : false,
            modalAuto: e.node.productAndService?.autoOrder ? true : false,
            runtxt: "",
            runnumber: 0,
            assetType: all_setting_asset?.node.typeAsset || null,
            facilityUse:
              all_purchase_orderes.node.procurementForm.facilityUse || "",
          };
          transaction_list.push(transaction_object);
        }
      );
      //Loop//
      // ตรวจสอบการตั้งค่าสินทรัพย์
      if (all_setting_asset_check) {
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful") + i18next.t("PurchaseCreate:Asset don't setup"), "", "warning");
      } else {
        this.setState({
          transaction_list: transaction_list,
          PurchaseOrderesId: e.target.value,
          assetDate:
            all_purchase_orderes.node.procurementForm.type === "ASSET"
              ? true
              : false,
          PurchaseOrderesType: this.checkPurchaseRequisitionType(
            all_purchase_orderes.node.procurementForm.type
          ),
          PurchaseOrderesTypeTable:
            all_purchase_orderes.node.procurementForm.type,
          PurchaseOrderesissuedDate:
            all_purchase_orderes.node.procurementForm.issuedDate,
          PurchaseOrderesTypeReal:
            all_purchase_orderes.node.procurementForm.type,
        });
      }
    } else if (e.target.dataset.key === "transaction_list") {
      let transaction_list = _.cloneDeep(this.state.transaction_list);
      let all_setting_asset_check = false;
      if (e.target.name === "productAndService") {
        let product_service_list = _.cloneDeep(this.state.product_and_service);
        product_service_list.forEach((ps) => {
          if (ps.node.id === e.target.value) {
            //ตรวจสอบสินทรัพย์ว่ามีการตั้งค่าหรือยัง
            let all_setting_asset = null;
            if (
              _.startsWith(ps.node.chartOfAccount.chartOfAccountCode, "1210") ||
              _.startsWith(ps.node.chartOfAccount.chartOfAccountCode, "1230")
            ) {
              all_setting_asset = _.find(this.props.allSettingAsset, {
                node: {
                  chartOfAccountType: {
                    chartOfAccountCode:
                      ps.node.chartOfAccount.chartOfAccountCode,
                  },
                },
              });
              if (!all_setting_asset) {
                all_setting_asset_check = true;
              }
            }

            transaction_list[e.target.dataset.id]["typeAsset"] =
              all_setting_asset?.node.typeAsset || null;

            transaction_list[e.target.dataset.id]["productAndService"] =
              ps.node;
            if (ps.node.price == null) {
              transaction_list[e.target.dataset.id]["price"] = "";
            } else {
              transaction_list[e.target.dataset.id]["price"] = ps.node.price;
              transaction_list[e.target.dataset.id]["vatAmount"] = this.props
                .openVat
                ? this.getVat(transaction_list[e.target.dataset.id])
                : 0;
            }
            transaction_list[e.target.dataset.id]["description"] = ps.node.name;
            transaction_list[e.target.dataset.id]["totalLeftInStock"] =
              ps.node.totalLeftInStock;
            transaction_list[e.target.dataset.id]["type"] = ps.node.type;
            transaction_list[e.target.dataset.id]["unitItems"] = 1;
            transaction_list[e.target.dataset.id]["whtRatePercent"] = 0;
            transaction_list[e.target.dataset.id]["vat"] = this.state.vat;
            transaction_list[e.target.dataset.id]["price_type"] = "";
            if (ps.node.type === "PRODUCT") {
              transaction_list[e.target.dataset.id]["whtRatePercent"] = 0;
              transaction_list[e.target.dataset.id]["whtRate"] = 0;
              transaction_list[e.target.dataset.id]["modalManual"] =
                ps.node.autoOrder === false ? true : false;
              transaction_list[e.target.dataset.id]["modalAuto"] = ps.node
                .autoOrder
                ? true
                : false;
            }
            if (ps.node.type === "EXPENSE") {
              if (!this.props.contact && this.props.openWht) {
                transaction_list[e.target.dataset.id]["whtRatePercent"] = 0;
              } else {
                transaction_list[e.target.dataset.id]["whtRatePercent"] = this
                  .props.openWht
                  ? ps.node.taxRate | 0
                  : 0;
              }
            }
          }
        });
      } else {
        transaction_list[e.target.dataset.id][e.target.name] = e.target.value;
      }

      transaction_list[e.target.dataset.id]["preTaxAmount"] =
        this.getPreTaxAmount(transaction_list[e.target.dataset.id]);
      transaction_list[e.target.dataset.id]["whtRate"] = 0;
      if (e.target.name === "price" || e.target.name === "unitItems") {
        transaction_list[e.target.dataset.id]["vatAmount"] = this.getVat(
          transaction_list[e.target.dataset.id]
        );
      }

      // ตรวจสอบการตั้งค่าสินทรัพย์
      if (all_setting_asset_check) {
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "สินทรัพย์ยังไม่ได้ตั้งค่า", "warning");
      } else {
        this.setState({ transaction_list: [...transaction_list] });
      }
    } else {
      let name = e.target.name;
      this.setState({ [e.target.name]: e.target.value }, () => {
        if (name === "dueDate" || name === "issuedDate") {
          this.setState({
            dueDateCount: differenceInCalendarDays(
              this.state.dueDate,
              this.state.issuedDate
            ),
          });
        }
      });
    }
  }

  checkPurchaseRequisitionType(type) {
    let valueResult = "";
    switch (type.toLocaleLowerCase()) {
      case "product":
        valueResult = i18next.t("purchaseRequisition:Product");
        break;
      case "service":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(General work)");
        break;
      case "service_contact":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(Contract)");
        break;
      case "asset":
        valueResult = i18next.t("purchaseRequisition:Asset")
        break;

      default:
        break;
    }
    return valueResult;
  }

  checkAndGetPrefixAddress(type, value) {
    if (
      this.props.contact?.registeredProvince.includes("กรุงเทพ") ||
      this.props.contact?.registeredProvince.includes("กทม")
    ) {
      if (value) {
        switch (type) {
          case "province":
            return "";
          case "district":
            return "แขวง";
          default:
            return "เขต";
        }
      }
      return "";
    } else {
      if (value) {
        switch (type) {
          case "province":
            return "จังหวัด";
          case "district":
            return "ตำบล";
          default:
            return "อำเภอ";
        }
      }
      return "";
    }
  }

  showTextPrepaidAmount(pd_transaction) {
    return (
      pd_transaction.docNumber +
      " " +
      pd_transaction.prepaid_transaction.chartOfAccount.chartOfAccountCode +
      " " +
      pd_transaction.prepaid_transaction.chartOfAccount.name +
      " จำนวน " +
      numberWithComma(pd_transaction.prepaid_transaction.total) +
      " บาท ยอดที่สามารถใช้ได้ " +
      numberWithComma(pd_transaction.prepaid_transaction.balance)
    );
  }

  getRemark(remark) {
    this.setState({ remark: remark });
  }

  updateModalState(name, value, index) {
    let transaction_list = _.cloneDeep(this.state.transaction_list);
    if (name === "modalAuto") {
      transaction_list[index]["modalAuto"] = true;
      transaction_list[index]["modalManual"] = false;
      this.setState({
        item_select: {
          ...this.state.item_select,
          modalAuto: true,
          modalManual: false,
        },
      });
    } else if (name === "modalManual") {
      transaction_list[index]["modalAuto"] = false;
      transaction_list[index]["modalManual"] = true;

      let unitNameproduct = [];
      let unitItems = Number(transaction_list[this.state.index_select]["unitItems"]);
      let sumUnitItems = unitItems + 1;

        for (
          var i = 1;
         // i < transaction_list[this.state.index_select]["unitItems"] + 1;
          i < sumUnitItems;
          i++
        ) {
          let unitNameproduct_objects = {
            number: i,
            docNumber: "",
          };
          unitNameproduct.push(unitNameproduct_objects);
        }

      transaction_list[index]["unitNameproduct"] = unitNameproduct;

      this.setState({
        transaction_list: transaction_list,
        item_select: {
          ...this.state.item_select,
          modalAuto: false,
          modalManual: true,
          unitNameproduct: unitNameproduct,
        },
      });
    }

    // else {
    //   this.setState({ item_select: { ...this.state.item_select, modalAuto: false, modalManual: true } })
    //   transaction_list[index][name] = value
    // }
    this.setState({ transaction_list: [...transaction_list] });
  }

  handleInput = (e) => {
    let { value, name } = e.target,
      transaction_list = _.cloneDeep(this.state.transaction_list),
      { index_select, item_select } = this.state;
    transaction_list[index_select][name] = value;
    this.setState({
      transaction_list,
      item_select: { ...item_select, [name]: value },
    });
  };

  handleInputManual = (e) => {
    let { value, name } = e.target,
      transaction_list = _.cloneDeep(this.state.transaction_list),
      { index_select, item_select } = this.state;
    const number = name.replace(/\D/g, "");

    let unitNameproduct = [];
    transaction_list[index_select]["unitNameproduct"].forEach(
      (e, index_unitNameproduct) => {
        if (index_unitNameproduct === parseInt(number)) {
          let unitNameproduct_objects = {
            number: e.number,
            docNumber: value,
          };
          unitNameproduct.push(unitNameproduct_objects);
        } else {
          let unitNameproduct_objects = {
            number: e.number,
            docNumber: e.docNumber,
          };
          unitNameproduct.push(unitNameproduct_objects);
        }
      }
    );
    transaction_list[index_select]["unitNameproduct"] = unitNameproduct;
    this.setState({
      transaction_list: transaction_list,
      item_select: { ...item_select, unitNameproduct: unitNameproduct },
    });
  };

  onConfirmAuto = () => {
    let transaction_list = _.cloneDeep(this.state.transaction_list),
      { index_select, item_select } = this.state;

    let count = 1;
    let unitNameproduct = [];

    let unitNameproduct_objects = {
      number: 1,
      docNumber: item_select.runtxt + item_select.runnumber,
    };
    unitNameproduct.push(unitNameproduct_objects);

    for (var i = 0; i < transaction_list[index_select]["unitItems"] - 1; i++) {
      let number = parseInt(item_select.runnumber) + count;
      let runnumber = item_select.runnumber;
      runnumber = runnumber.substring(
        0,
        runnumber.length - number.toString().length
      );
      runnumber = runnumber + number;
      const docNumber = item_select.runtxt + runnumber;
      count++;

      let unitNameproduct_objects = {
        number: count,
        docNumber: docNumber,
      };
      unitNameproduct.push(unitNameproduct_objects);
    }

    transaction_list[index_select]["unitNameproduct"] = unitNameproduct;
    this.setState({ transaction_list: transaction_list });
  };

  onConfirmManual = () => {
    let transaction_list = _.cloneDeep(this.state.transaction_list);
    this.setState({ transaction_list: transaction_list });
  };

  render() {
    if (this.state.redirectToList) {
      if (this.state.status === "active") {
        return <Redirect to={this.props.back_page} />;
      } else {
        return <Redirect to={this.props.next_page} />;
      }
    }

    return (<Translation>{t =>
      <form onSubmit={this.submitFrom} id="purchase-create-update">
        <div className="row">
          {this.props.contactList.length > 0 && (
            <div className="col-3">
              <p>
                <strong>
                  {this.props.other_expense ||
                    this.props.purchase_form ||
                    this.props.prepaid_deposit
                    ? t("PurchaseCreate:Accounts Payable")
                    : t("PurchaseCreate:Customer")}
                </strong>
              </p>

              <div className="input-group">
                {this.props.contactList.length === 1 ? (
                  this.props.contact ? (
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.props.contact.typeOfContact === "RESIDENTIAL"
                          ? this.props.contact.refNumber +
                          " " +
                          getNameResidential(
                            this.props.contact.firstName,
                            this.props.contact.lastName
                          )
                          : this.props.contact.refNumber +
                          " " +
                          this.props.contact.name
                      }
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      value={this.props.contactList[0] || "-"}
                      disabled
                    />
                  )
                ) : (
                  <PurchaseCreateDetailContact
                    Contact={this.props.contactList}
                  />
                )}
              </div>
            </div>
          )}

          <div className="col-2">
            <p>
              <strong>{t("PurchaseCreate:Document number")}</strong>
            </p>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={
                  this.state.group_detail &&
                    this.state.group_detail.status !== "DRAFT" &&
                    this.state.group_detail.status !== "WAITING_FIX"
                    ? this.state.group_detail.docNumber
                    : getDocumentNumber.get(
                      this.state.category,
                      "XXXX",
                      this.state.issuedDate
                    )
                }
                disabled
              />
            </div>
          </div>

          <div className="col-3">
            <p>
              {this.state.assetDate ? (
                <strong>
                  {t("PurchaseCreate:Document number")}{" "}
                  <span className="text-red">
                    {" "}
                    *(เพื่อใช้คำนวณค่าเสื่อมราคาสินทรัพย์){" "}
                  </span>{" "}
                </strong>
              ) : (
                <strong> {t("PurchaseCreate:Date")}</strong>
              )}
            </p>
            <div className="input-group">
              {
                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ?
                  <DatePickerAdapter
                    name="issuedDate"
                    className="form-control"
                    selected={this.state.issuedDate}
                    minDate={this.state.temp_month_before}
                    // maxDate={this.state.temp_month_after}
                    onChange={this.handleInputChange}
                    required={true}
                  /> :
                  <DatePickerAdapter
                    name="issuedDate"
                    className="form-control"
                    selected={this.state.issuedDate}
                    // maxDate={this.state.dueDate}
                    onChange={this.handleInputChange}
                    required={true}
                  />
              }
            </div>
          </div>

          <div className="col-2">
            <p>
              <strong> {t("PurchaseCreate:Due date")}</strong>
            </p>
            <div className="input-group">
              <DatePickerAdapter
                name="dueDate"
                className="form-control"
                selected={this.state.dueDate}
                onChange={this.handleInputChange}
                required={true}
              />
            </div>
          </div>

          <div className="col-2">
            <p>&nbsp;</p>
            <div className="input-group">
              <input
                type="number"
                min={0}
                name="dueDateCount"
                className="form-control borderRight text-right"
                value={this.state.dueDateCount}
                onChange={this.handleInputChange}
                required
              />
              <div className="input-group-append">
                <span className="input-group-text input-date"> {t("PurchaseCreate:Day (s)")}</span>
              </div>
            </div>
          </div>

          <div className="col-2">
            {this.props.project_have_vat && (
              <React.Fragment>
                <p>
                  <strong> {t("PurchaseCreate:Type of tax")}</strong>
                </p>
                <div className="input-group">
                  <select
                    className="form-control"
                    name="taxType"
                    value={this.state.taxType}
                    onChange={this.handleInputChange}
                  >
                    <option value="NO_TAX"> {t("PurchaseCreate:No tax")}</option>
                    <option value="EXCLUDE_TAX"> {t("PurchaseCreate:Exclude tax")}</option>
                    <option value="INCLUDE_TAX"> {t("PurchaseCreate:Include tax")}</option>
                  </select>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>

        <hr />
        <React.Fragment>
          <div className="row form-group mt-3">
            <div className="col-3">
              <label htmlFor="tax_invoice_number" className="mr-5">
                <strong> {t("PurchaseCreate:Invoice / Tax invoice number")}</strong>
              </label>
              <input
                type="text"
                className="form-control"
                id="tax_invoice_number"
                value={this.state.tax_invoice_number}
                onChange={this.handleInputChange}
                name="tax_invoice_number"
                required={false}
                maxLength="30"
              />
              <strong className="text-danger font-small">{t("PurchaseCreate:*Enter text no more than 30 characters")} {this.state.tax_invoice_number.length} / 30</strong>
            </div>
            {this.state.tax_invoice_number !== "" && (
              <div className="col-3">
                <label htmlFor="taxDate" className="mr-5">
                  <strong>{t("PurchaseCreate:date")}</strong>
                </label>
                <div className="input-group">
                  <DatePickerAdapter
                    name="tax_invoice_date"
                    className="form-control"
                    selected={
                      this.state.taxType.tax_invoice_number !== ""
                        ? this.state.tax_invoice_date
                          ? this.state.tax_invoice_date
                          : new Date()
                        : null
                    }
                    onChange={this.handleInputChange}
                    required={
                      this.state.taxType.tax_invoice_number !== ""
                        ? "required"
                        : false
                    }
                  />
                </div>
              </div>
            )}
            <div className="col-3">
              <label htmlFor="ref_number" className="mr-5">
                <strong>{t("PurchaseCreate:Reference number")}</strong>
              </label>
              <input
                type="text"
                className="form-control"
                id="ref_number"
                value={this.state.ref_number}
                onChange={this.handleInputChange}
                name="ref_number"
                maxLength="30"
              />
            </div>

            <div className="col-3">
              <UploadFileInput
                handleInputDocument={this.handleInputDocument}
                documentUpload={this.state.document_upload}
              />
            </div>
          </div>
        </React.Fragment>

        {/* PO Menu */}

        {this.props.purchaseNew && (
          <React.Fragment>
            <hr />
            <div className="row form-group mt-3">
              <div className="col-3">
                <label htmlFor="PurchaseOrderesId" className="mr-5">
                  <strong>{t("PurchaseCreate:No.")} PO</strong>
                </label>
                <SearchSelect
                  onChange={this.handleInputChange}
                  value={this.state.PurchaseOrderesId}
                  name="PurchaseOrderesId"
                  placeholder="เลขที่ PO"
                  queryObject={this.props.allPurchaseOrderes}
                  keyOfValue="id"
                  require={true}
                  keyOfLabel="docNumber"
                />
              </div>
              <div className="col-3">
                <label htmlFor="PurchaseOrderesType" className="mr-5">
                  <strong>{t("PurchaseCreate:Type")} PO</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="PurchaseOrderesType"
                  value={this.state.PurchaseOrderesType}
                  // onChange={this.handleInputChange}
                  name="PurchaseOrderesType"
                  disabled={true}
                />
              </div>

              <div className="col-3">
                <label htmlFor="PurchaseOrderesissuedDate" className="mr-5">
                  <strong>{t("PurchaseCreate:Date")}</strong>
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="PurchaseOrderesissuedDate"
                  value={
                    this.state.PurchaseOrderesissuedDate
                      ? format(
                        this.state.PurchaseOrderesissuedDate,
                        "DD/MM/YYYY"
                      )
                      : ""
                  }
                  name="PurchaseOrderesissuedDate"
                  disabled={true}
                />
              </div>
            </div>
            <hr />
          </React.Fragment>
        )}

        <div className="fade-up">
          <div className="card mt-2">
            <table className="table table-hover">
              <thead className="thead-light">
                <tr>
                  <th width="200">{t("PurchaseCreate:List")}</th>
                  <th width="90">{t("PurchaseCreate:Account")}</th>
                  <th width="200">{t("PurchaseCreate:Description")}</th>
                  <th width="60" className="text-right">{t("PurchaseCreate:Amount Unit")}</th>
                  <th width="80" className="text-right">{t("PurchaseCreate:Price/Unit")}</th>
                  {
                  this.props.purchaseNew &&
                    this.state.PurchaseOrderesTypeTable.toLocaleLowerCase() ===
                    "asset" && (
                      <th width="60" className="text-right">
                        {t("PurchaseCreate:Unit")}
                      </th>
                    )
                    }

                  {(!(this.state.taxType === "NO_TAX") ||
                    this.state.case_return_vat) && (
                      <th width="60" className="text-right">
                        {t("PurchaseCreate:Vat")}
                      </th>
                    )}

                  {(!(this.state.taxType === "NO_TAX") ||
                    this.state.case_return_vat) && (
                      <th width="60" className="text-right">
                        {t("PurchaseCreate:Amount Vat")}
                      </th>
                    )}

                  {this.props.contact && this.props.openWht && (
                    <th width="100" className="text-right">
                      {t("PurchaseCreate:Withholding")}
                    </th>
                  )}
                  {/* หน่วย */}
                  {
                  // this.props.purchaseNew &&
                  //   this.state.PurchaseOrderesTypeTable.toLocaleLowerCase() ===
                  //   "product" && (
                      <th width="100" className="text-right">
                        Run No.
                      </th>
                 //  )
                    }

                  {this.state.transaction_list.length !== 1 && <th width={1} />}
                </tr>
              </thead>
              <tbody>
                {this.state.transaction_list.map((transaction, index) => {
                  let productAndServiceOrChartOfAccount = "";
                  let disableTransaction = false;
                  let invoice_cal_fine = false;
                  let showlimit = transaction.whtRatePercent != 0;
                  if (transaction.chartOfAccount) {
                    disableTransaction = true;
                    productAndServiceOrChartOfAccount =
                      transaction.chartOfAccount;
                  } else {
                    productAndServiceOrChartOfAccount =
                      transaction.productAndService;
                  }

                  return (
                    <tr key={"transaction-" + index}>
                      <td className={showlimit ? "pt-6" : ""}>
                        {transaction.chartOfAccount ? (
                          <input
                            type="text"
                            className="form-control"
                            value={
                              transaction.chartOfAccount.chartOfAccountCode +
                              " " +
                              transaction.chartOfAccount.name
                            }
                            disabled
                          />
                        ) : (
                          <SearchSelect
                            onChange={this.handleInputChange}
                            value={productAndServiceOrChartOfAccount.id}
                            name="productAndService"
                            placeholder={i18next.t("PurchaseCreate:List")}
                            data-id={index}
                            data-key="transaction_list"
                            queryObject={this.state.product_and_service}
                            keyOfValue="id"
                            require={true}
                            keyOfLabel="productCode:name"
                            disabled={
                              disableTransaction || this.props.purchaseNew
                            }
                          />
                        )}
                      </td>
                      <td className="pt-6">
                        {transaction.chartOfAccount ? (
                          <span>
                            {transaction.chartOfAccount.chartOfAccountCode}
                          </span>
                        ) : (
                          this.state.product_and_service.map(
                            (product) =>
                              product.node.id ===
                              productAndServiceOrChartOfAccount.id && (
                                <span key={product.node.id}>
                                  {product.node.type === "PRODUCT"
                                    ? product.node.creditorChartOfAccount
                                      ?.chartOfAccountCode
                                      ? product.node.creditorChartOfAccount
                                        .chartOfAccountCod
                                      : t("PurchaseCreate:Please setting purchase")
                                    : product.node.chartOfAccount
                                      .chartOfAccountCode}
                                </span>
                              )
                          )
                        )}
                      </td>

                      <td>
                        <div className="purchase-description-wrapper">
                          {/*for product and service no option*/}
                          {showlimit && (
                            <p
                              className="text-danger"
                              style={{ fontSize: "10px", marginBottom: "-1px" }}
                            >
                              <Translation>
                                {(t) =>
                                  t(
                                    "purchaseCreate:*Enter text not over 50 character characters"
                                  )
                                }
                              </Translation>
                              {` ${transaction.description?.length} `} / 50
                            </p>
                          )}
                          <input
                            type="text"
                            min={1}
                            className="form-control float-left"
                            data-id={index}
                            data-key="transaction_list"
                            name="description"
                            value={transaction.description || ""}
                            onChange={this.handleInputChange}
                            required
                            disabled={
                              disableTransaction ||
                              invoice_cal_fine ||
                              this.props.purchaseNew
                            }
                            maxLength={showlimit ? 50 : null}
                          />
                        </div>
                      </td>

                      <td className={showlimit ? "text-right pt-6" : ""}>
                        <input
                          type="number"
                          min={this.props.contactList.length > 1 ? 0 : 1}
                          className="form-control text-right"
                          max={
                            this.state.category === "purchase"
                              ? undefined
                              : productAndServiceOrChartOfAccount.totalLeftInStock
                          }
                          data-id={index}
                          data-key="transaction_list"
                          name="unitItems"
                          value={transaction.unitItems}
                          onChange={this.handleInputChange}
                          disabled={
                            disableTransaction ||
                            invoice_cal_fine ||
                            this.props.purchaseNew
                            // || transaction?.totalLeftInStock === 0
                          }
                          required
                        />
                      </td>

                      <td className={showlimit ? "text-right pt-6" : ""}>
                        <input
                          type="number"
                          min={0.01}
                          step="0.01"
                          className="form-control text-right"
                          data-id={index}
                          data-key="transaction_list"
                          name="price"
                          value={transaction.price}
                          onChange={this.handleInputChange}
                          disabled={
                            disableTransaction ||
                            invoice_cal_fine ||
                            this.props.purchaseNew
                          }
                          title={"กรุณากรอกราคาก่อนภาษี"}
                          required
                        />
                      </td>

                      {/* หน่วย */}
                      {
                      this.props.purchaseNew &&
                        this.state.PurchaseOrderesTypeTable.toLocaleLowerCase() ===
                        "asset" && (
                          <td className={showlimit ? "text-right pt-6" : ""}>
                            <input
                              type="unit"
                              min={0.01}
                              step="0.01"
                              className="form-control text-right"
                              data-id={index}
                              data-key="transaction_list"
                              name="unit"
                              value={transaction.unit}
                              onChange={this.handleInputChange}
                              disabled={
                                disableTransaction ||
                                invoice_cal_fine ||
                                this.props.purchaseNew
                              }
                              title={t("PurchaseCreate:Kind of thing")}
                              required
                            />
                          </td>
                        )
                        }
                      {/* Vat */}
                      {(!(this.state.taxType === "NO_TAX") ||
                        this.state.case_return_vat) &&
                        this.props.openVat && (
                          <td className={showlimit ? "pt-6" : ""}>
                            <select
                              className="form-control"
                              value={transaction.vat}
                              data-id={index}
                              data-key="transaction_list"
                              name="vat"
                              disabled={
                                this.state.case_return_vat ? "disabled" : false
                              }
                              onChange={this.handleInputChange}
                            >
                              <option value="">{t("PurchaseCreate:None")}</option>
                              <option value="0">VAT 0%</option>
                              <option value="7">VAT 7%</option>
                            </select>
                          </td>
                        )}
                      {(!(this.state.taxType === "NO_TAX") ||
                        this.state.case_return_vat) &&
                        this.props.openVat && (
                          <td className={showlimit ? "pt-6" : ""}>
                            <input
                              type="number"
                              min={0}
                              step="0.01"
                              className="form-control text-right"
                              data-id={index}
                              data-key="transaction_list"
                              name="vatAmount"
                              value={transaction.vatAmount}
                              onChange={this.handleInputChange}
                              title={"จำนวนภาษี"}
                              required
                              // disabled={this.props.purchaseNew}
                            />
                          </td>
                        )}

                      {this.props.contact && this.props.openWht && (
                        <td className={showlimit ? "pt-6" : ""}>
                          <select
                            className="form-control"
                            value={transaction.whtRatePercent || 0}
                            data-id={index}
                            data-key="transaction_list"
                            name="whtRatePercent"
                            disabled={
                              //   _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "AREA" ||
                              //   _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "WATER_METER" ||
                              //   _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "ELECTRIC_METER" ||
                              disableTransaction ||
                              invoice_cal_fine ||
                              this.props.purchaseNew
                            }
                            onChange={this.handleInputChange}
                          >
                            <option value={0}>{t("PurchaseCreate:None")}</option>
                            <option value={1}>1%</option>
                            <option value={1.5}>1.5%</option>
                            <option value={2}>2%</option>
                            <option value={3}>3%</option>
                            <option value={5}>5%</option>
                            <option value={10}>10%</option>
                            <option value={15}>15%</option>
                          </select>
                        </td>
                      )}

                      {/* Run No. */}
                      {
                      // this.props.purchaseNew &&
                      //   this.state.PurchaseOrderesTypeTable.toLocaleLowerCase() ===
                      //   "product" &&
                        transaction.productAndService.noNumber === true && (
                          <td className="text-right">
                            <span
                              data-toggle="modal"
                              data-target="#run-no-label"
                              className="link float-right"
                              onClick={() => {
                                this.setState({
                                  item_select: transaction,
                                  index_select: index,
                                });
                              }}
                            >
                              <React.Fragment>
                                <span>
                                  {transaction.modalManual === false &&
                                    transaction.modalAuto === false &&
                                    t("PurchaseCreate:Set format")}
                                  {transaction.modalManual === false &&
                                    transaction.modalAuto === true &&
                                    t("PurchaseCreate:Automatic")}
                                  {transaction.modalManual === true &&
                                    transaction.modalAuto === false &&
                                    t("PurchaseCreate:Custom")}
                                </span>
                                {/* <img src={process.env.PUBLIC_URL + '/images/icons/view-table.png'} alt="view" className="mr-1"/> */}
                              </React.Fragment>
                            </span>
                          </td>
                        )}

                      {
                      // this.props.purchaseNew &&
                      //   this.state.PurchaseOrderesTypeTable.toLocaleLowerCase() ===
                      //   "product" &&
                        transaction.productAndService.noNumber === false && (
                          <td />
                        )}

                      {this.state.transaction_list.length !== 1 && (
                        <td width={1} className={showlimit ? "pt-6" : ""}>
                          {
                            <PurchaseDelete
                              transaction={transaction}
                              transaction_list={this.state.transaction_list}
                              setTransactionList={this.setTransactionList}
                            />
                          }
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div
            ref={(modal) => (this.modal = modal)}
            data-backdrop="static"
            data-keyboard="false"
            className="modal fade"
            id="run-no-label"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                {/* modal-header */}
                <div className="modal-header">
                  <div className="modal-title" id="run-no-label">
                    <h3>{t("PurchaseCreate:Set")} Run No.</h3>
                  </div>
                </div>

                {/* modal-body */}
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12  mt-3 form-inline">
                      <h5>{t("PurchaseCreate:Sort")}</h5>
                    </div>

                    <div className="col-12  mt-3 form-inline">
                      <input
                        type="radio"
                        id="auto"
                        className="radio-request mr-3"
                        onChange={() =>
                          this.updateModalState(
                            "modalAuto",
                            true,
                            this.state.index_select
                          )
                        }
                        checked={this.state.item_select.modalAuto === true}
                      />
                      
                      <span htmlFor="auto">{t("PurchaseCreate:Automatic")}</span>
                    </div>

                    <div className="col-12  mt-3 form-inline">
                      <input
                        type="radio"
                        id="manual"
                        className="radio-request mr-3"
                        onChange={() =>
                          this.updateModalState(
                            "modalManual",
                            true,
                            this.state.index_select
                          )
                        }
                        checked={this.state.item_select.modalManual === true}
                      />
                      <span htmlFor="manual">{t("PurchaseCreate:Custom")}</span>
                    </div>
                  </div>

                  {/* อัตโนมัติ */}
                  {this.state.item_select.modalAuto === true && (
                    <React.Fragment>
                      <div className="row form-group mt-3">
                        <div className="col-12 mt-3 form-inline">
                          <h6>{t("PurchaseCreate:Set the starting number")}</h6>
                        </div>
                      </div>
                      <div className="row form-group mt-3">
                        <div className="col-3 mt-3 form-inline">
                          <label htmlFor="runtxt" className="mr-5">
                            <strong>{t("PurchaseCreate:Character")}</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="runtxt"
                            value={this.state.item_select.runtxt}
                            onChange={this.handleInput}
                          />
                        </div>

                        <div className="col-3 mt-3 form-inline">
                          <label htmlFor="runnumber" className="mr-5">
                            <strong>{t("PurchaseCreate:Number")}</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            min={0}
                            name="runnumber"
                            value={this.state.item_select.runnumber}
                            onChange={this.handleInput}
                          />
                        </div>

                        <div className="col-3 mt-3 form-inline">
                          <label htmlFor="unit" className="mr-5">
                            <strong>{t("PurchaseCreate:Amount")}</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="unitItems"
                            value={this.state.item_select.unitItems}
                            disabled
                          />
                        </div>
                      </div>

                      <hr />
                      {!_.isEmpty(this.state.item_select.unitNameproduct) && (
                        <React.Fragment>
                          <div className="mt-3 table-responsive card">
                            <table className="table">
                              <thead>
                                <tr className="bg-light">
                                  <th className="text-center">{t("PurchaseCreate:No.")}</th>
                                  <th className="text-left">Run No.</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.item_select.unitNameproduct.map(
                                  (e, index_item_select) => {
                                    let num_row = index_item_select + 1;
                                    return (
                                      <tr key={"resident." + index_item_select}>
                                        <td className="text-center">
                                          {num_row}
                                        </td>
                                        <td className="text-left">
                                          <input
                                            type="text"
                                            className="form-control text-left"
                                            name={
                                              "unitNameproduct[" +
                                              index_item_select +
                                              "]"
                                            }
                                            value={e.docNumber}
                                            data-id={this.state.index_select}
                                            data-key="transaction_list"
                                            disabled
                                          />
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                          <hr />
                        </React.Fragment>
                      )}

                      <div className="mt-3 float-right">
                        <button
                          type="button"
                          className="btn btn-secondary mr-1"
                          data-dismiss="modal"
                        >
                          {t("PurchaseCreate:Close")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                          onClick={this.onConfirmAuto}
                        >
                          {t("PurchaseCreate:Save")}
                        </button>
                      </div>
                    </React.Fragment>
                  )}

                  {/* กำหนดเอง */}
                  {this.state.item_select.modalManual === true && (
                    <React.Fragment>
                      <div className="mt-3 table-responsive card">
                        <table className="table">
                          <thead>
                            <tr className="bg-light">
                              <th className="text-center">{t("PurchaseCreate:Custom")}</th>
                              <th className="text-left">Run No.</th>
                            </tr>
                          </thead>

                          <tbody>
                            {this.state.item_select.unitNameproduct.map(
                              (e, index_item_select) => {
                                let num_row = index_item_select + 1;
                                return (
                                  <tr key={"resident." + index_item_select}>
                                    <td className="text-center">{num_row}</td>
                                    <td className="text-left">
                                      <input
                                        type="text"
                                        className="form-control text-left"
                                        name={
                                          "unitNameproduct[" +
                                          index_item_select +
                                          "]"
                                        }
                                        data-id={this.state.index_select}
                                        data-key="transaction_list"
                                        onChange={this.handleInputManual}
                                        required
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="mt-3 float-right">
                        <button
                          type="button"
                          className="btn btn-secondary mr-1"
                          data-dismiss="modal"
                        >
                          {t("PurchaseCreate:Close")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-dismiss="modal"
                          onClick={this.onConfirmManual}
                        >
                          {t("PurchaseCreate:Save")}
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>

          {this.props.purchaseNew ||
            (!this.props.disableNewRow && (
              <button
                type="button"
                className="btn add-button add mt-3"
                onClick={this.appendNewRow}
              >
                +{t("settingAll:Add list")}
              </button>
            ))}

          {this.state.prepaidDepositTransaction &&
            this.state.prepaidDepositTransaction.map(
              (pd_transaction, index) => (
                <React.Fragment key={pd_transaction.prepaid_transaction.id}>
                  <div className="form-group row mt-3">
                    <div className="col-sm-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="prepaidDepositTransaction"
                          onChange={this.handleInputChange}
                          data-id={index}
                          id={
                            "prepaidDepositTransaction" +
                            pd_transaction.prepaid_transaction.id
                          }
                          checked={pd_transaction.checked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={
                            "prepaidDepositTransaction" +
                            pd_transaction.prepaid_transaction.id
                          }
                        >
                          {this.showTextPrepaidAmount(pd_transaction)}
                        </label>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            )}
        </div>
        <PurchaseSummary
          remark={this.state.remark}
          handleInputChange={this.handleInputChange}
          transaction_list={this.state.transaction_list}
          remark_edit={false}
          prepaidDeposit={this.state.prepaidDeposit}
          taxType={this.state.taxType}
          category={this.state.category}
          required={false}
          mode={this.props.mode}
          getRemarkCallback={this.getRemark}
          total_discount={this.state.total_discount}
        />

        <div className="row">
          <div className="col text-right mt-2 button-grp">
            <Link to={this.props.back_page}>
              <button type="button" className="btn btn-secondary add mr-2">
                {t("PurchaseCreate:Cancel")}
              </button>
            </Link>

            <button
              type="submit"
              onClick={() => this.setState({ status: "draft" })}
              className="btn btn-primary add"
              disabled={this.state.loading}
            >
              {this.state.loading && (
                <span className="spinner-border spinner-border-sm align-middle mr-2" />
              )}
              {this.state.category === "purchase" ? t("PurchaseCreate:Save") : t("PurchaseCreate:Save a draft")}
            </button>
          </div>
        </div>
      </form>


    }
    </Translation>

    );
  }
}

export default PurchaseCreateUpdateForm;
