import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';

class Navigation extends Component {
    render() {
        return (
            <Translation>
                {t=>
                <div className="row" id="navigation-tab">
                    <div className="col">
                        <ul>
                            <li>
                                <NavLink exact={true} to="/project/staff/current">
                                   {t("PageDetailProjectInfo:Current")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/project/staff/old">
                                {t("PageDetailProjectInfo:Expair")}
                                </NavLink>
                            </li> 
                        </ul>
                    </div>
                </div>}
            </Translation>
        )
    }
}

export default Navigation;