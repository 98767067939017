/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateUpdateContactInput = {|
  name?: ?string,
  taxIdNumber?: ?string,
  prefixName?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  nickName?: ?string,
  position?: ?string,
  phone?: ?string,
  email?: ?string,
  prefixNameContact?: ?string,
  firstNameContact?: ?string,
  lastNameContact?: ?string,
  nickNameContact?: ?string,
  phoneContact?: ?string,
  emailContact?: ?string,
  mainPhone?: ?string,
  mainEmail?: ?string,
  fax?: ?string,
  webSite?: ?string,
  bankName?: ?string,
  bankBranchNumber?: ?string,
  accountNumber?: ?string,
  accountName?: ?string,
  accountNameEn?: ?string,
  bookBankFileName?: ?string,
  bookBankFileUpload?: ?string,
  registeredName?: ?string,
  registeredAddress?: ?string,
  registeredCountry?: ?string,
  registeredProvince?: ?string,
  registeredDistrict?: ?string,
  registeredCity?: ?string,
  registeredPostalCode?: ?string,
  mailingName?: ?string,
  mailingAddress?: ?string,
  mailingCountry?: ?string,
  mailingProvince?: ?string,
  mailingDistrict?: ?string,
  mailingCity?: ?string,
  mailingPostalCode?: ?string,
  typeOfPayment?: ?string,
  typeOfContact?: ?string,
  typeOfSupplier?: ?string,
  note?: ?string,
  typeOfCompany?: ?string,
  nameBranch?: ?string,
  vat?: ?string,
  typeOfCountry?: ?string,
  typeOfPeople?: ?string,
  typeOfSetting?: ?string,
  settingDate?: ?any,
  settingNumberDate?: ?number,
  contactFile?: ?$ReadOnlyArray<?any>,
  statusOfCompany?: ?string,
  registeredCapital?: ?string,
  haveVat?: ?boolean,
  vatChoice?: ?string,
  vatAmount?: ?number,
  clientMutationId?: ?string,
|};
export type contactCreateUpdateFormNewMutationVariables = {|
  input: CreateUpdateContactInput
|};
export type contactCreateUpdateFormNewMutationResponse = {|
  +createUpdateContact: ?{|
    +newContact: ?{|
      +id: string
    |}
  |}
|};
export type contactCreateUpdateFormNewMutation = {|
  variables: contactCreateUpdateFormNewMutationVariables,
  response: contactCreateUpdateFormNewMutationResponse,
|};
*/


/*
mutation contactCreateUpdateFormNewMutation(
  $input: CreateUpdateContactInput!
) {
  createUpdateContact(input: $input) {
    newContact {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUpdateContactPayload",
    "kind": "LinkedField",
    "name": "createUpdateContact",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactNode",
        "kind": "LinkedField",
        "name": "newContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contactCreateUpdateFormNewMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contactCreateUpdateFormNewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7b587f394830499709dde70bf3cd1dca",
    "id": null,
    "metadata": {},
    "name": "contactCreateUpdateFormNewMutation",
    "operationKind": "mutation",
    "text": "mutation contactCreateUpdateFormNewMutation(\n  $input: CreateUpdateContactInput!\n) {\n  createUpdateContact(input: $input) {\n    newContact {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b50069ac2e3dc228efbfcb00fd71e457';

module.exports = node;
