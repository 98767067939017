/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeveloperCrmLink = "NO" | "UNIDENTIFIED" | "YES" | "%future added value";
export type DeveloperTransferOwnership = "ALL" | "NO" | "UNIDENTIFIED" | "%future added value";
export type settingDeveloperQueryVariables = {||};
export type settingDeveloperQueryResponse = {|
  +allDeveloperProject: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +transferOwnership: DeveloperTransferOwnership,
        +transferOwnershipDate: any,
        +crmLink: DeveloperCrmLink,
        +crmLinkConnect: string,
        +crmLinkConnectDate: any,
        +creator: ?string,
        +editor: ?string,
      |}
    |}>
  |}
|};
export type settingDeveloperQuery = {|
  variables: settingDeveloperQueryVariables,
  response: settingDeveloperQueryResponse,
|};
*/


/*
query settingDeveloperQuery {
  allDeveloperProject {
    edges {
      node {
        id
        transferOwnership
        transferOwnershipDate
        crmLink
        crmLinkConnect
        crmLinkConnectDate
        creator
        editor
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DeveloperProjectNodeConnection",
    "kind": "LinkedField",
    "name": "allDeveloperProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeveloperProjectNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeveloperProjectNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transferOwnership",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transferOwnershipDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "crmLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "crmLinkConnect",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "crmLinkConnectDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingDeveloperQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingDeveloperQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "444414fa94b494c295ee41619a702f52",
    "id": null,
    "metadata": {},
    "name": "settingDeveloperQuery",
    "operationKind": "query",
    "text": "query settingDeveloperQuery {\n  allDeveloperProject {\n    edges {\n      node {\n        id\n        transferOwnership\n        transferOwnershipDate\n        crmLink\n        crmLinkConnect\n        crmLinkConnectDate\n        creator\n        editor\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3157ca5e887e22306ad4e9aacd0e3d7e';

module.exports = node;
