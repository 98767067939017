import React, {Component} from 'react';
import './style/bankCreateModal.scss'
import {fetchQuery} from "relay-runtime";
import environment from "../../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import CreateBankAccountMutation from './mutations/CreateBankAccountMutation'
import SearchSelect from "../../../libs/searchSelect";
import Swal from "sweetalert2";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import { Translation } from 'react-i18next';
import upload from "../../../libs/upload";
import Resizer from "react-image-file-resizer";
import i18next from 'i18next';
import API from '../../../api/optService'

const bankCodeNext = graphql`
    query bankCreateModalQuery{
         bankAccountViewer{
            backCodeNext
        }
        lastBankAccount {
            edges{
                node{
                    notiStatus
                    numberOfUnit
                    unit
                }
            }          
        }
        allGlobalBankAccount(status: "active"){
            edges{
                node{
                    id
                    name
                    logo
                    status
                }
            }
        }
        myUser {
            id username
            juristic {
                id firstName lastName image email phone
            }
        }
    }
`;

const $ = window.jQuery;
const _ = require('lodash');

const other_bank = [
    {
        node: {
            id: 'other',
            slug: "other",
            name: 'อื่นๆ',
            logo: '',
        }
    },
];

class BankCreateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cheque: true,
            account_number: '',
            account_name: '',
            account_name_en: '',
            branch:'',
            description: '',
            carrying_balance: '',
            receive_payment: false,
            make_payment: false,
            crossBilling: false,
            status: false,
            account_type: 'current_account',
            bank_name: '',
            new_code: 'Loading',
            image_upload: '',
            image: '',
            close_remark: '',
            loading : false,
            error: false,
            success: false,
            allGlobalBankAccount: [],
            bank_id: '',
            slug: '',
            number_of_month: '',
            issued_date:null,
            due_date:null,
            tax_rate:null,
            mobile_payment:false,
            show_in_app:false,
            notiStatus : false,
            numberOfUnitMonth : 1,
            numberOfUnitDay : '',
            unit : 'day',
            signreq:Math.random().toString(36),
            optionPayment:'',
            prompayQr:'',
            billerId:'',
            other:'',
            channelName:'',
            email:'',
            sms:'',
            radioType:''
        };

        this.onClickOpenModal = this.onClickOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.toggle_checkbox = this.toggle_checkbox.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputImage = this.handleInputImage.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.clearState = this.clearState.bind(this);
        this.handleInputChangeRadio = this.handleInputChangeRadio.bind(this);
        this.resizeImg = this.resizeImg.bind(this)
        this.deleteImage = this.deleteImage.bind(this)
    }

    componentDidMount() {
        $(this.modal).on('hidden.bs.modal',()=>{
            this.clearState();
            if (this.state.success || this.state.error) {
                this.props.changePage();
                this.props.refreshQuery();
            }
             this.setState({loading: false, success: false, error: false, bank_id: '', slug: ''});

        });
    }

    onClickOpenModal() {
        $(this.modal).modal('show');
        this.refreshBankCodeNext()
    }

    onCloseModal() {
        document.querySelector('#bank-create-model').removeAttribute('inert');
        $(this.modal).modal('hide');
        
    }

    refreshBankCodeNext() {
        fetchQuery(environment, bankCodeNext, {}).then(data => {
            // other_bank
            let data_clone = _.cloneDeep(data);
            let bank_list = [...data_clone.allGlobalBankAccount.edges, ...other_bank];
            if (data_clone.bankAccountViewer.backCodeNext) {
                this.setState({
                    new_code: data_clone.bankAccountViewer.backCodeNext,
                    allGlobalBankAccount: bank_list
                })
            }
            if(data_clone.lastBankAccount.edges.length > 0){
                if(_.lowerCase(data_clone.lastBankAccount.edges[0].node.unit) === 'day'){
                    this.setState({
                        unit : _.lowerCase(data_clone.lastBankAccount.edges[0].node.unit) ,
                        numberOfUnitDay : data_clone.lastBankAccount.edges[0].node.numberOfUnit
                    })
                }else{
                    this.setState({
                        unit : _.lowerCase(data_clone.lastBankAccount.edges[0].node.unit),
                        numberOfUnitMonth : data_clone.lastBankAccount.edges[0].node.numberOfUnit
                    })
                }
            }
            if(data_clone.myUser.juristic){
                this.setState({
                    email: data_clone.myUser.juristic.email,
                    sms: data_clone.myUser.juristic.phone
                })
            }
        });
    }

    toggle_noti_box = () => {
        this.setState({
            notiStatus : !this.state.notiStatus
        })
    }

    toggle_unit = (e) => {
        if(e.target.value !== this.state.unit){
            this.setState({
                unit : e.target.value
            })
        }
    }

    toggle_checkbox(e) {
        let name = e.currentTarget.name;
        let value = e.currentTarget.checked;
        this.setState({[name]: value});
        if(name === 'status'){
            this.setState({close_remark: ''});
        }
        if(e.currentTarget.checked){
            // this.setState({account_type: 'current_account'});
        }

        let state = {}
        if (e.currentTarget.name === 'mobile_payment') {
            if (e.currentTarget.checked) {
                state['receive_payment'] = true
                state[e.currentTarget.name] = e.currentTarget.checked
            }else{
                state['receive_payment'] = false
                state[e.currentTarget.name] = e.currentTarget.checked
            }
        }else{
            state[e.currentTarget.name] = e.currentTarget.checked
        }
        this.setState({...state})
    }

    handleInputChange(e) {
        let replace_account_number = /[^0-9-]+/;
        if(e.target.name === 'account_number'){
           e.target.value = e.target.value.replace(replace_account_number,'');
        }
        if (e.target.name === 'account_type' && e.target.value === 'current_account') {
            this.setState({cheque: true});
        }else if(e.target.name === 'account_type' && e.target.value !== 'current_account'){
            this.setState({cheque: false});
        }

        if (e.target.name === 'bank_id') {
            this.state.allGlobalBankAccount.forEach((bank) => {
                if (bank.node.id === e.target.value) {
                    let name = bank.node.name;
                    if (bank.node.slug === "other"){
                        name = '';
                    }
                    this.setState({
                        slug: bank.node.slug,
                        image: bank.node.logo,
                        bank_name: name,
                    });
                }
            })
        }
        this.setState({[e.target.name]: e.target.value});
    }

    handleInputImage(e) {
        if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === 'image') {
            this.setState({
                image_upload: e.currentTarget.files[0],
                image: URL.createObjectURL(e.target.files[0])
            });
        }
    }


    onSubmit(e) {
        e.preventDefault();
        document.querySelector('#bank-create-model').setAttribute('inert', 'true');
        
        Swal.fire({
            title: 'ช่องทางการรับ OTP',
            input: 'radio',
            inputOptions: {
              email: 'Email',
              sms: 'SMS',
            },
          //  inputValue: 'email',
            showCancelButton: true,
            html: '<input type="text" class="form-control" id="selectedOption" style="position:absolute; bottom:115px;width:200px;left:150px" disabled/><div id="optionAlert" style="position:absolute; bottom:82px;width:258px;left:138px;font-size:12px;font-family:Kanit;"></div>', 
            confirmButtonText: 'ตกลง',
            cancelButtonText:'ยกเลิก',
            showLoaderOnConfirm: true,
            preConfirm: (value) => {
              return new Promise((resolve) => {
                setTimeout(() => {
                  if (!value) {
                    Swal.showValidationMessage('Please enter your name');
                  }
                  resolve();
                }, 4000);
              });
            }
          }).then((result) => {
            if (result.value) {
                const channel = result.value;
                this.setState({
                channelName: channel
                })
                var bodyFormData = new FormData();
                bodyFormData.append('channel', channel);
                API.postOTP(bodyFormData).then((res) => {
                  let reference = res.data.message;
                    if (res.data.statusMessage === "SUCCESS") {
                      Swal.fire({
                      title: 'กรุณาใส่รหัส OTP',
                      text: 'OTP มีอายุ 5 นาที',
                      input: 'text',
                      inputAttributes: {
                        maxlength: 6 // Set the maximum length of text input to 6 characters
                      },
                      inputPlaceholder: 'รหัส OTP',
                      showCancelButton: true,
                      confirmButtonText: 'ตกลง',
                      cancelButtonText: 'ขอรหัสใหม่',
                    }).then((result) => {
                      if (result.value) {
                        const otpValue = result.value;
                        API.checkOTP(this.state.channelName,otpValue,reference).then((res) => {
                          if(res.data.ok === true){
                              if(this.state.optionPayment  ===  'QRCode' && this.state.prompayQr.length <= 0){
                                  Swal.fire('Error!',i18next.t("Allaction:Please specifies") + " QRCODE",'warning')
                                  return
                              }else{
                                  this.setState({loading: true, success: false, error: false});
                                  let input = {
                                      accountName: this.state.account_name,
                                      accountNameEn: this.state.account_name_en,
                                      accountNumber: this.state.account_number,
                                      bankName: this.state.bank_name,
                                      branch: this.state.branch,
                                      accountType: this.state.account_type,
                                      receivePayment: this.state.receive_payment,
                                      makePayment: this.state.make_payment,
                                      crossBilling: this.state.optionPayment  ===  'crossBilling' ? true : false,
                                      status: this.state.status,
                                      carryingBalance: this.state.carrying_balance === ''? 0.0 : this.state.carrying_balance,
                                      hasCheque: this.state.cheque,
                                      description: this.state.description,
                                      closeRemark: this.state.close_remark,
                                      numberOfMonth: this.state.number_of_month,
                                      bankId: this.state.bank_id,
                          
                                      mobilePayment: this.state.mobile_payment,
                                      issuedDate: this.state.issued_date,
                                      dueDate: this.state.due_date,
                                      taxRate: this.state.tax_rate,
                                      
                                      notiStatus: this.state.notiStatus,
                                      numberOfUnit: this.state.unit === 'day' ? (this.state.numberOfUnitDay === '' ? null : this.state.numberOfUnitDay) : this.state.numberOfUnitMonth,
                                      unit: this.state.unit,
                                      clientMutationId:"Bank"+this.state.signreq,
                                      prompay:this.state.optionPayment  ===  'QRCode' ? true : false,
                                      prompayQr: this.state.prompayQr[0]?.fileUpload || null,
                                      billerId: this.state.billerId || null
                          
                                  };
                      
                                  const uploadables = { bank_logo: this.state.image_upload };
                          
                                  CreateBankAccountMutation(input,uploadables,this.onSuccess,this.onError)
                                  this.onCloseModal()
                              }
                          }else if(res.data.ok === false){
                            Swal.fire(i18next.t('settingAccount:Failed to save'),i18next.t(`settingAccount:${res.data.message}`), 'warning').then(() => {
                                return
                            })
                          }
                        }, () => {
                          Swal.fire(i18next.t('settingAccount:Failed to save'), '', 'warning').then(() => {
                              return
                          })
                        })
                      }else if (result.dismiss === Swal.DismissReason.cancel) {
                        // Handle Resend action
                        // Call API to resend OTP
                        API.postOTP(bodyFormData).then((res) => {
                            let reference = res.data.message;
                            if (res.data.statusMessage === "SUCCESS") {
                                Swal.fire({
                                    title: 'กรุณาใส่รหัส OTP',
                                    input: 'text',
                                    inputAttributes: {
                                        maxlength: 6 // Set the maximum length of text input to 6 characters
                                    },
                                    inputPlaceholder: 'รหัส OTP',
                                    showCancelButton: true,
                                    confirmButtonText: 'ตกลง',
                                    cancelButtonText: 'ขอรหัสใหม่',
                                    }).then((result) => {
                                    if (result.value) {
                                        const otpValue = result.value;
                                        API.checkOTP(this.state.channelName,otpValue,reference).then((res) => {
                                        if(res.data.ok === true){
                                            if(this.state.optionPayment  ===  'QRCode' && this.state.prompayQr.length <= 0){
                                                Swal.fire('Error!',i18next.t("Allaction:Please specifies") + " QRCODE",'warning')
                                                return
                                            }else{
                                                this.setState({loading: true, success: false, error: false});
                                                let input = {
                                                    accountName: this.state.account_name,
                                                    accountNameEn: this.state.account_name_en,
                                                    accountNumber: this.state.account_number,
                                                    bankName: this.state.bank_name,
                                                    branch: this.state.branch,
                                                    accountType: this.state.account_type,
                                                    receivePayment: this.state.receive_payment,
                                                    makePayment: this.state.make_payment,
                                                    crossBilling: this.state.optionPayment  ===  'crossBilling' ? true : false,
                                                    status: this.state.status,
                                                    carryingBalance: this.state.carrying_balance === ''? 0.0 : this.state.carrying_balance,
                                                    hasCheque: this.state.cheque,
                                                    description: this.state.description,
                                                    closeRemark: this.state.close_remark,
                                                    numberOfMonth: this.state.number_of_month,
                                                    bankId: this.state.bank_id,
                                        
                                                    mobilePayment: this.state.mobile_payment,
                                                    issuedDate: this.state.issued_date,
                                                    dueDate: this.state.due_date,
                                                    taxRate: this.state.tax_rate,
                                                    
                                                    notiStatus: this.state.notiStatus,
                                                    numberOfUnit: this.state.unit === 'day' ? (this.state.numberOfUnitDay === '' ? null : this.state.numberOfUnitDay) : this.state.numberOfUnitMonth,
                                                    unit: this.state.unit,
                                                    clientMutationId:"Bank"+this.state.signreq,
                                                    prompay:this.state.optionPayment  ===  'QRCode' ? true : false,
                                                    prompayQr: this.state.prompayQr[0]?.fileUpload || null,
                                                    billerId: this.state.billerId || null
                                        
                                                };
                                    
                                                const uploadables = { bank_logo: this.state.image_upload };
                                        
                                                CreateBankAccountMutation(input,uploadables,this.onSuccess,this.onError)
                                               
                                                this.onCloseModal()
                                            }  
                                        }else if(res.data.ok === false){
                                            Swal.fire(i18next.t('settingAccount:Failed to save'),res.data.message, 'warning').then(() => {
                                                return
                                            })
                                        }
                                        }, () => {
                                            Swal.fire(i18next.t('settingAccount:Failed to save'), '', 'warning').then(() => {
                                                return
                                            })
                                        })
                                    }
                                    })
                            }else if(res.data.statusMessage === "FAIL"){
                                Swal.fire(i18next.t('settingAccount:Failed to save'),i18next.t(`settingAccount:${res.data.message}`), 'warning').then(() => {
                                    return
                                })
                              }
                        })
                      }
                    });
                  }else if(res.data.statusMessage === "FAIL"){
                    Swal.fire(i18next.t('settingAccount:Failed to save'),i18next.t(`settingAccount:${res.data.message}`), 'warning').then(() => {
                        return
                    })
                  }
                    
                }, () => {
                  Swal.fire(i18next.t('settingAccount:Failed to save'), '', 'warning').then(() => {
                      return
                  })
               })
            }
        });
        
        
        document.addEventListener('input', function (event) {
            var target = event.target;
            if (target && target.matches('.swal2-radio input[type="radio"]')) {
                document.getElementById('selectedOption').value = this.state[target.value]
                if(target.value === 'email'){
                    if(this.state.email === ''){
                      document.getElementById('optionAlert').innerHTML = `
                        <span class='text-danger'>กรุณาตั้งค่าอีเมล <b>เมนูตั้งค่า > ตั้งค่าสิทธิ์ผู้ใช้งาน </b></span>`;
                    }else{
                        document.getElementById('optionAlert').textContent = "" 
                    }
                }

                if(target.value === 'sms'){
                    if(this.state.sms === ''){
                        document.getElementById('optionAlert').innerHTML = `
                        <span class='text-danger'>กรุณาตั้งค่าเบอร์โทร <b>เมนูตั้งค่า > ตั้งค่าสิทธิ์ผู้ใช้งาน </b></span>`;
                    }else{
                        document.getElementById('optionAlert').textContent = "" 
                    }
                }
          
                 
            }
        }.bind(this)); // Bind 'this' to ensure the correct context

       // document.getElementById('selectedOption').value = this.state.email;
      
       
        // old code
        //   if(this.state.optionPayment  ===  'QRCode' && this.state.prompayQr.length <= 0){
        //     Swal.fire('Error!',i18next.t("Allaction:Please specifies") + " QRCODE",'warning')
        //     return
        // }else{
        //     this.setState({loading: true, success: false, error: false});
        //     let input = {
        //         accountName: this.state.account_name,
        //         accountNameEn: this.state.account_name_en,
        //         accountNumber: this.state.account_number,
        //         bankName: this.state.bank_name,
        //         branch: this.state.branch,
        //         accountType: this.state.account_type,
        //         receivePayment: this.state.receive_payment,
        //         makePayment: this.state.make_payment,
        //         crossBilling: this.state.optionPayment  ===  'crossBilling' ? true : false,
        //         status: this.state.status,
        //         carryingBalance: this.state.carrying_balance === ''? 0.0 : this.state.carrying_balance,
        //         hasCheque: this.state.cheque,
        //         description: this.state.description,
        //         closeRemark: this.state.close_remark,
        //         numberOfMonth: this.state.number_of_month,
        //         bankId: this.state.bank_id,
    
        //         mobilePayment: this.state.mobile_payment,
        //         issuedDate: this.state.issued_date,
        //         dueDate: this.state.due_date,
        //         taxRate: this.state.tax_rate,
                
        //         notiStatus: this.state.notiStatus,
        //         numberOfUnit: this.state.unit === 'day' ? (this.state.numberOfUnitDay === '' ? null : this.state.numberOfUnitDay) : this.state.numberOfUnitMonth,
        //         unit: this.state.unit,
        //         clientMutationId:"Bank"+this.state.signreq,
        //         prompay:this.state.optionPayment  ===  'QRCode' ? true : false,
        //         prompayQr: this.state.prompayQr[0]?.fileUpload || null,
        //         billerId: this.state.billerId || null
    
        //     };

        //     const uploadables = { bank_logo: this.state.image_upload };
    
        //     CreateBankAccountMutation(input,uploadables,this.onSuccess,this.onError)
        //     this.onCloseModal()
        // }

       

     
    }
  

    onSuccess(response) {
        if (response.createBankAccount.ok) {
            Swal.fire(i18next.t("add_bank:Save successfully"), '', 'success')
            this.setState({loading: false, success: true});
            this.clearState();
            this.refreshBankCodeNext();

        } else {
            this.setState({loading: false, error: true});
            alert(response.createBankAccount.warningText)
        }
       

    }

    onError() {
        this.setState({loading: false, error: true});
        alert('server error')
    }

    clearState() {
        this.setState({
            cheque: true,
            account_number: '',
            account_name: '',
            account_name_en: '',
            branch:'',
            description: '',
            carrying_balance: '',
            receive_payment: false,
            make_payment: false,
            crossBilling: false,
            status: false,
            account_type: 'current_account',
            bank_name: '',
            new_code: 'Loading',
            image_upload: '',
            image: '',
            close_remark: '',
            number_of_month:'',
            issued_date:null,
            due_date:null,
            tax_rate:null,
        });
    }

    handleChange = (event,name) => {
        if (event.target.name === 'tax_rate') {
            var t = event.target.value
            this.setState({ [event.target.name]: (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 4)) : t });            
        }else{
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    handleInputChangeRadio(e) {
        this.setState({
             optionPayment: e.target.value,
            [e.target.name]: e.target.value
          });
    }

    resizeImg(file) {
        let resizeImg;
         const resizeFile = (file) =>
            new Promise((resolve) => {
                Resizer.imageFileResizer(
                file,
                160,
                160,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
                );
            });

           resizeImg =  resizeFile(file);
         return resizeImg
    }


    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/bankQRcode" + path, file);
          resolve(res);
        });
    };

    async handleInputFile (e) {
        let file = e.currentTarget.files[0];
        let uploadFile = [];
        if (file) {
            let totalBytes = file.size;
             if (totalBytes >  10485760) {
                Swal.fire(i18next.t("Allactio:Failed to upload file"), i18next.t("Allactio:Please upload a different file because it exceeds the size limit."), 'warning');
                return
            } else {

            let imgResize ;
            await this.resizeImg(file).then((data) => {
                imgResize = data
            })

            this.callUpload('', imgResize).then((data) => {
                uploadFile.push({
                        fileUpload : data.key.replace("silverman-storage/private/", ""), 
                        fileName :  file.name,
                        fileShow: data.location
                })
                this.setState({
                    prompayQr : uploadFile
                })
            });
         
            }
        }
    }

    deleteImage(index) {
        this.setState({ prompayQr: '' })
    }

    render() {
        return (
            <React.Fragment>
                <Translation>
                    {t=>
                    <>
                    <button type="button" className="btn btn-primary add" style={{float: "right"}} id="onClickOpenModalBank"
                            onClick={this.onClickOpenModal}>
                        <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                        {t("add_bank:Add bank")}
                    </button>
                    <div className="modal fade bd-example-modal-lg" id="bank-create-model" role="dialog"
                         aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modal => this.modal = modal}>
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="col">
                                        <div className="row">
                                            <h5 className="modal-title">
                                                {t("add_bank:Add bank")}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col form-inline bank-code">
                                        <div className="row">
                                            <input type="text" className="form-control text-center"
                                                   value={this.state.new_code} id="bank_code"
                                                   disabled/> 
                                        </div>
                                    </div>
                                    <button type="button" className="close" onClick={this.onCloseModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form id="form-add" onSubmit={this.onSubmit}>
                                         {this.state.error && <div className="alert alert-danger text-left" role="alert">
                                            {t("add_bank:Can't add new bank")}</div>
                                        }
                                        {this.state.loading && <div className="alert alert-primary text-left" role="alert">
                                            {t("add_bank:Saving")}</div>
                                        }
                                        {this.state.success && <div className="alert alert-primary text-left" role="alert">
                                            {t("add_bank:Save success")}</div>
                                        }
    
                                        <div className="row ">
                                            <div className="col-2">
                                                <label htmlFor="InputBankName"
                                                       className="col-form-label">
                                                    {t("add_bank:Bank")} <span className='text-danger'>*</span>
                                                </label>
                                            </div>
                                            <div className="col text-left">
                                                <SearchSelect onChange={this.handleInputChange}
                                                              value={this.state.bank_id}
                                                              name="bank_id"
                                                              placeholder={t("add_bank:Bank")}
                                                              queryObject={_.sortBy(this.state.allGlobalBankAccount,'node.name' )}
                                                              keyOfValue="id" require={true}
                                                              keyOfLabel="name"
                                                              logo={true} />
                                                {this.state.slug === "other" &&
                                                    <input type="text" className="form-control width-bank-name mt-3"
                                                           id="InputBankName"
                                                           placeholder={t("add_bank:Please enter bank name")} value={this.state.bank_name}
                                                           onChange={this.handleInputChange} name="bank_name" required/>
                                                }
                                            </div>
                                            <div className="col-3 check_middle">
    
                                                {this.state.account_type === 'current_account' &&
                                                <div className="text-left custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input"
                                                           id="customCheckCheque" checked={this.state.cheque}
                                                           onChange={this.toggle_checkbox} name="cheque"
                                                           disabled/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customCheckCheque">{t("add_bank:Cheque")}</label>
                                                </div>
                                                }
    
                                            </div>
                                            <div className="col-1"/>
                                        </div>
    
                                        <div className="row mt-3">
                                            <label htmlFor="branch"
                                                   className="col-2 col-form-label">
                                                {t("add_bank:Branch")} <span className='text-danger'>*</span>
                                            </label>
                                            <div className="col">
                                                <input type="text" className="form-control" id="branch"
                                                       placeholder={t("add_bank:Branch")} value={this.state.branch}
                                                       onChange={this.handleInputChange} name="branch" required/>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
    
                                        {this.state.slug === "other" &&
                                        <div className="row mt-3">
                                            <label htmlFor="bankLogo"
                                                   className="col-2 col-form-label">
                                                {t("add_bank:Bank logo")} <span className='text-danger'>*</span>
                                            </label>
                                            <div className="col-6">
                                                <div className="custom-file input-file-index">
                                                    <input type="file" className="custom-file-input" id="customFile"
                                                           name="image_bank" value={this.state.image_bank}
                                                           onChange={this.handleInputImage}/>
                                                    <label className="custom-file-label text-left"
                                                           htmlFor="customFile">{this.state.image === '' ? 'Choose file...' : this.state.image_upload.name.length > 34 ? this.state.image_upload.name.substr(0, 35) + '...' : this.state.image_upload.name}</label>
                                                </div>
                                               
                                            </div>
                                            <div className="col-3 text-center pt-2"> <span className='text-red'>{i18next.t("Allaction:Each file should not exceed 10 MB")}</span></div>
                                        </div>
                                        }
    
                                        <div className="row mt-3">
                                            <label htmlFor="inputEmail3"
                                                   className="col-2 col-form-label">
                                                {t("add_bank:Account type")} 
                                            </label>
                                            <div className="col">
                                                <select className="form-control" name="account_type"
                                                        value={this.state.account_type}
                                                        onChange={this.handleInputChange}>
                                                    <option value="current_account">{t("add_bank:Current Account")}</option>
                                                    <option value="saving_account">{t("add_bank:Saving Account")}</option>
                                                    <option value="fixed_deposit">{t("add_bank:Fixed Deposit Account")}</option>
                                                    <option value="saving_deposit">{t("add_bank:Saving Deposit Account")}</option>
                                                </select>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
    
                                        {this.state.account_type === 'fixed_deposit' &&
                                        <div className="row mt-3">
                                            <label htmlFor="InputBankMonthNum"
                                                   className="col-2 col-form-label">
                                                {t("add_bank:Number of months")}
                                            </label>
                                            <div className="col">
                                                <input type="number" className="form-control" id="InputBankMonthNum"
                                                       min={1} name="number_of_month"
                                                       onChange={this.handleInputChange}
                                                       value={this.state.number_of_month}/>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        }
                                        {this.state.account_type === 'fixed_deposit'&&<div className="row mt-3">
                                            <label htmlFor="issued_date"  className="col-2 col-form-label">
                                                {t("add_bank:Start date")}
                                            </label>
                                            <div className="col-2 ">
                                                <DatePickerAdapter
                                                    id="issued_date"
                                                    className="form-control"
                                                    name="issued_date"
                                                    selected={this.state.issued_date}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col-1"/>
                                            <label htmlFor="due_date" className="col-2 col-form-label" >
                                                {t("add_bank:Due date")}
                                            </label>
                                            <div className="col">
                                                <DatePickerAdapter
                                                    id="due_date"
                                                    name="due_date"
                                                    className="form-control"
                                                    selected={this.state.due_date}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>}
                                        {this.state.account_type === 'fixed_deposit'&&<div className="row mt-3">
                                            <label htmlFor="tax_rate"
                                                   className="col-2 col-form-label">
                                                {t("add_bank:Interest")}
                                            </label>
                                            <div className="col" style={{display:'flex'}} >
                                                <input  type="text" className="form-control" id="tax_rate" placeholder={t("add_bank:Interest")} value={this.state.tax_rate}
                                                    name="tax_rate" onInput={this.handleChange} 
                                                    style={{borderRightWidth:0,borderTopRightRadius:0,borderBottomRightRadius:0,width:200}}
                                                    >
                                                    </input>
                                                        <span  className="form-control" style={{borderLeftWidth:0,width:50,borderTopLeftRadius:0,borderBottomLeftRadius:0}} >%</span>
                                            </div>
                                            <div className="col-1"/>
                                        </div>}
                                        <div className="row mt-3">
                                            <label htmlFor="InputBankAccountName"
                                                   className="col-2 col-form-label">
                                                {t("add_bank:Account name")} <span className='text-danger'>*</span>
                                            </label>
                                            <div className="col">
                                                <input type="text" className="form-control" id="InputBankAccountName"
                                                       placeholder={t("add_bank:Account name")} value={this.state.account_name}
                                                       onChange={this.handleInputChange} name="account_name" required/>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        {/* ชื่อบัญชีภาษาอังกฤษ */}
                                        <div className="row mt-3">
                                            <label htmlFor="InputBankAccountName"
                                                className="col-2 col-form-label">
                                                {t("add_bank:Account name (English)")}
                                            </label>
                                            <div className="col">
                                                <input type="text" className="form-control" id="InputBankAccountNameEn"
                                                    placeholder={t("add_bank:Account name (English)")} value={this.state.account_name_en}
                                                    onChange={this.handleInputChange} name="account_name_en"/>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        {/* เลขบัญชี */}
                                        <div className="row mt-3">
                                            <label htmlFor="InputBankAccountNum"
                                                   className="col-2 col-form-label">
                                                {t("add_bank:Account No.")}<span className='text-danger'>*</span>
                                            </label>
                                            <div className="col">
                                                <input type="text" className="form-control" id="InputBankAccountNum"
                                                       placeholder={t("add_bank:Account No.")}value={this.state.account_number}
                                                       onChange={this.handleInputChange} name="account_number" required/> 
                                                       <span className='text-danger'>*</span>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
    
                                        
    
                                        <div className="row mt-3">
                                            <label htmlFor="InputBankAccountNum"
                                                   className="col-2 col-form-label">
                                                {t("add_bank:Note")}
                                            </label>
                                            <div className="col">
                                               <textarea className="form-control"
                                                         rows="3" name="description" value={this.state.description}
                                                         onChange={this.handleInputChange}/>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
    
                                        {/* <div className="row mt-3">
                                            <label htmlFor="InputBankAccountNum"
                                                   className="col-2 col-form-label">
                                                {t("add_bank:Bring forward")}
                                            </label>
                                            <div className="col">
                                                <input type="number" className="form-control" id="InputBankAccountNum"
                                                       step="0.01" name="carrying_balance"
                                                       onChange={this.handleInputChange}
                                                       value={this.state.carrying_balance}/>
                                            </div>
                                            <div className="col-1"/>
                                        </div> */}
    
                                            <div className="row mt-4">
                                                <div className="col-2"/>
                                                <div className="col-3 text-left">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input"
                                                                id="customCheckOpen" checked={this.state.status}
                                                                onChange={this.toggle_checkbox} name="status"/>
                                                        <label className="custom-control-label"
                                                                htmlFor="customCheckOpen">{t("add_bank:Enable")}</label>

                                                    </div>
                                                </div>
                                                <div className="col-3 text-left">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input"
                                                                id="customShowInApp"
                                                                checked={this.state.mobile_payment}
                                                                onChange={this.toggle_checkbox} name="mobile_payment"/>
                                                        <label className="custom-control-label"
                                                                htmlFor="customShowInApp">{t("add_bank:Show in Silverman application")}</label>
                                                    </div>
                                                </div>
                                                <div className="col-1"/>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-2"/>
                                                <div className="col-3 text-left">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input"
                                                                id="customCheckReceive"
                                                                checked={this.state.receive_payment}
                                                                onChange={this.toggle_checkbox} name="receive_payment"/>
                                                        <label className="custom-control-label"
                                                                htmlFor="customCheckReceive">{t("add_bank:For receive money")}</label>
                                                    </div>
                                                </div>
                                                <div className="col-3 text-left">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input"
                                                                id="customCheckPayBank"
                                                                checked={this.state.make_payment}
                                                                onChange={this.toggle_checkbox} name="make_payment"/>
                                                        <label className="custom-control-label"
                                                                htmlFor="customCheckPayBank">{t("add_bank:For spend")}</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-2"/>
                                                {!this.state.status &&
                                                    <div className="col-6 text-left">
                                                        <label htmlFor="closeRemark">{t("add_bank:Specify a note to close the account")}
                                                        <span className='text-danger'>*</span>
                                                        </label>
                                                        <textarea className="form-control" id="closeRemark"
                                                                    rows="2" name="close_remark"
                                                                    value={this.state.close_remark}
                                                                    onChange={this.handleInputChange} maxLength={256}
                                                                    required={true}/>
                                                </div> }
                                            </div>
                                        <div className="row mt-4">
                                            <div className="col-2"/>
                                            <div className="col-3 text-left">
                                                    {/* <input type="checkbox" className="custom-control-input"
                                                           id="customCheckCrossBilling" checked={this.state.crossBilling}
                                                           onChange={this.toggle_checkbox} name="crossBilling"/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customCheckCrossBilling">{t("add_bank:Use Cross Billing")}</label> */}
                                                    <input type="radio" id="radioOption1" name="radioGroup" value="crossBilling" placeholder={t("add_bank:Please enter Biller ID")}
                                                    onChange={this.handleInputChangeRadio} checked={this.state.optionPayment  ===  'crossBilling'  ? 'checked' :''} />
                                                    <label htmlFor="radioOption1" className='pl-2'>{t("add_bank:Use Cross Billing")}</label>
                                            </div>
                                            <div className='col-3'>
                                                {
                                                    this.state.optionPayment  ===  'crossBilling' ? 
                                                    <input type='text' name="billerId" onChange={this.handleChange} className='form-control form-input' 
                                                     placeholder={t("add_bank:Please enter Biller ID")}
                                                     disabled={this.state.optionPayment  ===  'QRCode'} maxlength="15"
                                                     required />
                                                    :''
                                                }
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-2"/>
                                            <div className="col-3 text-left">
                                                <div className="">
                                                    <input type="radio" id="radioOption2" name="radioGroup" value="QRCode"
                                                        onChange={this.handleInputChangeRadio} checked={this.state.optionPayment  ===  'QRCode'  ? 'checked' :''}  />
                                                    <label htmlFor="radioOption2" className='pl-2'>QRCode</label>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <label className="mr-2 uploadBtn" htmlFor="prompayQr"> 
                                                {
                                                    this.state.prompayQr.length >= 1 ? t("finance:QR Code New upload") : t("finance:QR Code upload")
                                                }
                                                </label>
                                                <input type="file" id="prompayQr" 
                                                        accept="image/*" value=""
                                                        hidden={true}
                                                        onChange={(e) => this.handleInputFile(e)}
                                                        disabled={this.state.optionPayment  ===  'crossBilling' || this.state.optionPayment  ===  'other' }
                                                        />   
                                             
                                                    {
                                                    this.state.prompayQr && _.map(this.state.prompayQr, (budget, index) =>{
                                                        return(
                                                            <div style={{position:'relative'}}>
                                                             <button className='btnDeleteImage' type={"button"} onClick={() => this.deleteImage(index)}>
                                                                <img src={process.env.PUBLIC_URL + "/images/feedback/closeImg.png"}  className="img-pre"/>
                                                            </button>
                                                             <img src={budget.fileShow} style={{width:'150px'}}/>
                                                           </div>
                                                        )
                                                    })
                                                    }
                                               
                                            </div>
                                            <div className='col-2'>
                                            <div className="tooltipImg">
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                            alt="question" className="question ml-1 mr-1" style={{width:'12px'}}/>
                                                    <span className="tooltiptext">
                                                            <Translation>{t=><label htmlFor="radioOption3" className='pl-2'>{t("finance:Example")}</label>}</Translation> <br/>
                                                            <Translation>{t=><label htmlFor="radioOption3" className='pl-2'>{t("finance:Please upload only image")}</label>}</Translation>
                                                            <img className='pl-2' src={process.env.PUBLIC_URL + '/images/tooltip/exp-qrcode.png'} style={{width:'80px'}}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-2"/>
                                            <div className="col-3 text-left">
                                                <div className="">
                                                <input type="radio" id="radioOption3" name="radioGroup" value="other" 
                                                onChange={this.handleInputChangeRadio} checked={this.state.optionPayment  ===  'other'  ? 'checked' :''} />
                                                <label htmlFor="radioOption3" className='pl-2'>{t("add_bank:Other")}</label>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        {this.state.account_type === 'fixed_deposit'&&
                                            <React.Fragment>
                                                <div className="row mt-4">
                                                    <div className="col-2"/>
                                                    <div className="col-4 text-left">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input"
                                                                id="notiStatus" checked={this.state.notiStatus}
                                                                onChange={this.toggle_noti_box} name="notiStatus"/>
                                                            <label className="custom-control-label"
                                                                htmlFor="notiStatus">แจ้งเตือนก่อนถึงวันที่ครบกำหนด</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.notiStatus && 
                                                    <React.Fragment>
                                                        <div className="row mt-4 text-left">
                                                            <div className="col-2"/>
                                                            <div className="col-10 form-inline">
                                                                <input type="radio" className="custom-radio" id="unit" onChange = {(e) => this.toggle_unit(e)}
                                                                    checked={this.state.unit === 'day'} name="unit" value="day" />
                                                                <span className="ml-2 mr-2"> 
                                                                    ล่วงหน้า
                                                                </span>
                                                                <input type= "text"className="form-control col-3" name="numberOfUnitDay" onChange={this.handleInputChange} value={this.state.numberOfUnitDay} />
                                                                <span className="ml-2 mr-2">วัน</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4 text-left">
                                                            <div className="col-2"/>
                                                            <div className="col-10 form-inline">
                                                                <input type="radio" className="custom-radio" id="unit" onChange = {(e) => this.toggle_unit(e)}
                                                                    checked={this.state.unit === 'month'} name="unit" value="month"/>
                                                                <span className="ml-2 mr-2"> 
                                                                    ล่วงหน้า
                                                                </span>
                                                                    <select className="form-control" name="numberOfUnitMonth"
                                                                        value={this.state.numberOfUnitMonth} 
                                                                        onChange={this.handleInputChange}
                                                                        >
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                </select>
                                                                <span className="ml-2 mr-2">เดือน</span>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }                                        
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary add" onClick={this.onCloseModal}>{t("add_bank:Cancel")}
                                    </button>
                                    <button type="submit" className="btn btn-primary add" form="form-add" disabled={this.state.loading}>
                                        {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                        {t("add_bank:Create")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                    }
                </Translation>
            </React.Fragment>

        )
    }
}

export default BankCreateModal;
