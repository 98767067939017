/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type InvoicePayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type ReceivePayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type ReceiveStatus = "PAID" | "VOID" | "WAIT" | "%future added value";
export type wrapperReceiveSirirajDataQueryVariables = {|
  id: string
|};
export type wrapperReceiveSirirajDataQueryResponse = {|
  +batchReceive: ?{|
    +receiveBatch: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +receive: {|
            +id: string,
            +docNumber: string,
            +firstName: string,
            +lastName: string,
            +payGroup: ReceivePayGroup,
            +contact: {|
              +id: string,
              +name: string,
              +refNumber: string,
              +firstName: string,
              +lastName: string,
              +typeOfContact: ContactTypeOfContact,
            |},
            +issuedDate: any,
            +added: any,
            +total: ?number,
            +status: ReceiveStatus,
            +voidRemark: ?string,
            +creator: ?string,
            +updated: any,
            +receiveTransaction: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +amount: ?number,
                  +receiver: ?string,
                  +transaction: {|
                    +id: string,
                    +productAndService: ?{|
                      +id: string
                    |},
                    +description: string,
                    +total: ?number,
                    +paid: ?number,
                    +invoice: {|
                      +firstName: string,
                      +lastName: string,
                      +payGroup: InvoicePayGroup,
                    |},
                  |},
                |}
              |}>
            |},
          |}
        |}
      |}>
    |}
  |}
|};
export type wrapperReceiveSirirajDataQuery = {|
  variables: wrapperReceiveSirirajDataQueryVariables,
  response: wrapperReceiveSirirajDataQueryResponse,
|};
*/


/*
query wrapperReceiveSirirajDataQuery(
  $id: ID!
) {
  batchReceive(id: $id) {
    receiveBatch {
      edges {
        node {
          receive {
            id
            docNumber
            firstName
            lastName
            payGroup
            contact {
              id
              name
              refNumber
              firstName
              lastName
              typeOfContact
            }
            issuedDate
            added
            total
            status
            voidRemark
            creator
            updated
            receiveTransaction {
              edges {
                node {
                  id
                  amount
                  receiver
                  transaction {
                    id
                    productAndService {
                      id
                    }
                    description
                    total
                    paid
                    invoice {
                      firstName
                      lastName
                      payGroup
                      id
                    }
                  }
                }
              }
            }
          }
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payGroup",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfContact",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidRemark",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receiver",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductAndServiceNode",
  "kind": "LinkedField",
  "name": "productAndService",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperReceiveSirirajDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BatchReceiveNode",
        "kind": "LinkedField",
        "name": "batchReceive",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReceiveBatchNodeConnection",
            "kind": "LinkedField",
            "name": "receiveBatch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReceiveBatchNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiveBatchNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveNode",
                        "kind": "LinkedField",
                        "name": "receive",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReceiveTransactionNodeConnection",
                            "kind": "LinkedField",
                            "name": "receiveTransaction",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReceiveTransactionNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReceiveTransactionNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v15/*: any*/),
                                      (v16/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TransactionNode",
                                        "kind": "LinkedField",
                                        "name": "transaction",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          (v17/*: any*/),
                                          (v18/*: any*/),
                                          (v10/*: any*/),
                                          (v19/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "InvoiceNode",
                                            "kind": "LinkedField",
                                            "name": "invoice",
                                            "plural": false,
                                            "selections": [
                                              (v4/*: any*/),
                                              (v5/*: any*/),
                                              (v6/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wrapperReceiveSirirajDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BatchReceiveNode",
        "kind": "LinkedField",
        "name": "batchReceive",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReceiveBatchNodeConnection",
            "kind": "LinkedField",
            "name": "receiveBatch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReceiveBatchNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiveBatchNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveNode",
                        "kind": "LinkedField",
                        "name": "receive",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReceiveTransactionNodeConnection",
                            "kind": "LinkedField",
                            "name": "receiveTransaction",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReceiveTransactionNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReceiveTransactionNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v15/*: any*/),
                                      (v16/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TransactionNode",
                                        "kind": "LinkedField",
                                        "name": "transaction",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          (v17/*: any*/),
                                          (v18/*: any*/),
                                          (v10/*: any*/),
                                          (v19/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "InvoiceNode",
                                            "kind": "LinkedField",
                                            "name": "invoice",
                                            "plural": false,
                                            "selections": [
                                              (v4/*: any*/),
                                              (v5/*: any*/),
                                              (v6/*: any*/),
                                              (v2/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad60970227a1736a39bf6ea7e26dbbc1",
    "id": null,
    "metadata": {},
    "name": "wrapperReceiveSirirajDataQuery",
    "operationKind": "query",
    "text": "query wrapperReceiveSirirajDataQuery(\n  $id: ID!\n) {\n  batchReceive(id: $id) {\n    receiveBatch {\n      edges {\n        node {\n          receive {\n            id\n            docNumber\n            firstName\n            lastName\n            payGroup\n            contact {\n              id\n              name\n              refNumber\n              firstName\n              lastName\n              typeOfContact\n            }\n            issuedDate\n            added\n            total\n            status\n            voidRemark\n            creator\n            updated\n            receiveTransaction {\n              edges {\n                node {\n                  id\n                  amount\n                  receiver\n                  transaction {\n                    id\n                    productAndService {\n                      id\n                    }\n                    description\n                    total\n                    paid\n                    invoice {\n                      firstName\n                      lastName\n                      payGroup\n                      id\n                    }\n                  }\n                }\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f0b7a1ee4986f2035552ae79170461a9';

module.exports = node;
