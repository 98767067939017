import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import { format } from "date-fns";
import getNameResidential from "../../../../libs/getNameResidential";
import thLocale from "date-fns/locale/th";
import localStorage from "../../../../libs/localstorage";
import i18next from "i18next";



class CreditNoteReportTable extends React.Component {
    removeTrailingZeros(description) {
        if (description.includes("ค่าน้ำประปา")) {
            description = description.replace(/ *\[[^)]*\] */g, " ")
            // description = description.replace(/ *\[[^)]*\] */g, " ").replace(/.50 /gi, ".5 ")
            // description = description.replace(/.00 /gi, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }

    render() {
        let total = 0
        let tran_total = 0
        this.props.props.creditNoteViewer.allCreditNote.edges.forEach(credit_note => {
            if (credit_note.node.version === 'V1') {
                total += credit_note.node.price
                tran_total += credit_note.node.transaction.total

            } else {
                credit_note.node.creditNoteTransactionRecord.edges.forEach((credit_note_transaction) => {
                    total += credit_note_transaction.node.price
                    tran_total += credit_note_transaction.node.transaction.total
                })
            }
        });

        return (
            <React.Fragment>
                {this.props.props.creditNoteViewer.allCreditNote.edges.map((credit_note, index) => {
                    let price_total = 0
                    let order_id = this.props.state.first - localStorage.getPageLimit();
                    if (credit_note.node.version == 'V1') {
                        if (credit_note.node.status !== "VOID") {
                            price_total += credit_note.node.price
                            return (
                                <tr key={credit_note.node.id}>
                                    <td className="text-center">{order_id + index + 1}</td>
                                    <td className="text-center">
                                        {credit_note.node.docNumber}
                                    </td>
                                    <td className="text-center">{format(credit_note.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                    <td>
                                        {credit_note.node.contact.residential ? credit_note.node.contact.residential.name : "-"}
                                    </td>
                                    <td>
                                        {credit_note.node.contact.typeOfContact === "RESIDENTIAL" &&
                                            getNameResidential(credit_note.node.firstName, credit_note.node.lastName)
                                        }

                                        {credit_note.node.contact.typeOfContact === "SUPPLIER" &&
                                            credit_note.node.name
                                        }
                                            {" "}                                            
                                            {
                                            (credit_note.node.payGroup === "RENT" || credit_note.node.payGroup === "AGENT" || credit_note.node.payGroup === "OWNER" || credit_note.node.payGroup === "DEVELOPER") &&
                                            <label className="text-danger">
                                            ({credit_note.node.payGroup === "RENT" || credit_note.node.payGroup === "AGENT" || credit_note.node.payGroup === "OWNER" || credit_note.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${credit_note.node.payGroup}`) : ""})
                                            </label>
                                            }                                        
                                    </td>
                                    <td className="text-center">{credit_note.node.transaction.invoice.docNumber}</td>
                                    <td className="text-center">{format(credit_note.node.transaction.invoice.issuedDate, 'DD/MM/YYYY')}</td>
                                    <td>{
                                        this.removeTrailingZeros(credit_note.node.transaction.description)
                                    }</td>
                                    <td className="text-right">{numberWithComma(credit_note.node.transaction.total)}</td>
                                    <td className="text-right">{numberWithComma(credit_note.node.price)}</td>
                                    <td className="text-right">{numberWithComma(price_total,'')}</td>
                                    <td className="text-center">{credit_note.node.receive ? credit_note.node.receive.docNumber : "-"}</td>
                                    
                                </tr>


                            )
                        } else {
                            return (
                                <tr key={credit_note.node.id}>
                                    <td className="text-center text-danger">{order_id + index + 1}</td>
                                    <td className="text-center text-danger">
                                        {credit_note.node.docNumber}
                                    </td>
                                    <td className="text-center text-danger">{format(credit_note.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                    <td className="text-danger">
                                        {credit_note.node.contact.residential ? credit_note.node.contact.residential.name : "-"}
                                    </td>
                                    <td className="text-danger">
                                        {credit_note.node.contact.typeOfContact === "RESIDENTIAL" &&
                                            getNameResidential(credit_note.node.contact.firstName, credit_note.node.contact.lastName)
                                        }

                                        {credit_note.node.contact.typeOfContact === "SUPPLIER" &&
                                            credit_note.node.contact.name
                                        }
                                            {" "}                                            
                                            {
                                            (credit_note.node.payGroup === "RENT" || credit_note.node.payGroup === "AGENT" || credit_note.node.payGroup === "OWNER" || credit_note.node.payGroup === "DEVELOPER") &&
                                            <label className="text-danger">
                                            ({credit_note.node.payGroup === "RENT" || credit_note.node.payGroup === "AGENT" || credit_note.node.payGroup === "OWNER" || credit_note.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${credit_note.node.payGroup}`) : ""})
                                            </label>
                                            }    
                                    </td>
                                    <td className="text-center text-danger">{credit_note.node.transaction.invoice.docNumber}</td>
                                    <td className="text-center text-danger">{format(credit_note.node.transaction.invoice.issuedDate, 'DD/MM/YYYY')}</td>
                                    <td className="text-danger">{
                                        this.removeTrailingZeros(credit_note.node.transaction.description)
                                    }</td>
                                    <td className="text-right text-danger">{numberWithComma(0)}</td>
                                    <td className="text-right text-danger">{numberWithComma(0)}</td>
                                    <td className="text-right">{numberWithComma(price_total,'')}</td>
                                    <td className="text-center text-danger">{credit_note.node.receive ? credit_note.node.receive.docNumber : "-"}</td>
                                    
                                </tr>
                            )
                        }


                    } else {
                        if (credit_note.node.status !== "VOID") {

                            return credit_note.node.creditNoteTransactionRecord.edges.map((credit_note_transaction, jindex) => {
                                price_total += credit_note_transaction.node.price
                                return (
                                    <tr key={credit_note_transaction.node.id}>
                                        <td className="text-center">{jindex === 0 ? order_id + index + 1 : ''}</td>
                                        <td className="text-center">
                                            {credit_note.node.docNumber}
                                        </td>
                                        <td className="text-center">{format(credit_note.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                        <td>
                                            {credit_note.node.contact.residential ? credit_note.node.contact.residential.name : "-"}
                                        </td>
                                        <td>
                                            {credit_note.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                getNameResidential(credit_note.node.firstName, credit_note.node.lastName)
                                            }

                                            {credit_note.node.contact.typeOfContact === "SUPPLIER" &&
                                                credit_note.node.name
                                            }
                                                {" "}                                            
                                            {
                                            (credit_note.node.payGroup === "RENT" || credit_note.node.payGroup === "AGENT" || credit_note.node.payGroup === "OWNER" || credit_note.node.payGroup === "DEVELOPER") &&
                                            <label className="text-danger">
                                            ({credit_note.node.payGroup === "RENT" || credit_note.node.payGroup === "AGENT" || credit_note.node.payGroup === "OWNER" || credit_note.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${credit_note.node.payGroup}`) : ""})
                                            </label>
                                            }    
                                        </td>
                                        <td className="text-center">{credit_note_transaction.node.transaction.invoice.docNumber}</td>
                                        <td className="text-center">{format(credit_note_transaction.node.transaction.invoice.issuedDate, 'DD/MM/YYYY')}</td>
                                        <td>{
                                            this.removeTrailingZeros(credit_note_transaction.node.transaction.description)
                                        }</td>
                                        <td className="text-right">{numberWithComma(credit_note_transaction.node.transaction.total)}</td>
                                        <td className="text-right">{numberWithComma(credit_note_transaction.node.price)}</td>
                                        <td className="text-right">{jindex + 1 === credit_note.node.creditNoteTransactionRecord.edges.length ? numberWithComma(price_total) : ''}</td>
                                        <td className="text-center">{"-"}</td>
                                    </tr>)
                            })

                        } else {

                            return credit_note.node.creditNoteTransactionRecord.edges.map((credit_note_transaction, jindex) => {
                                return (
                                    <tr key={credit_note_transaction.node.id}>
                                        <td className="text-center text-danger">{jindex === 0 ? order_id + index + 1 : ''}</td>
                                        <td className="text-center text-danger">
                                            {credit_note.node.docNumber}
                                        </td>
                                        <td className="text-center text-danger">{format(credit_note.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                        <td className="text-danger">
                                            {credit_note.node.contact.residential ? credit_note.node.contact.residential.name : "-"}
                                        </td>
                                        <td className="text-danger">
                                            {credit_note.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                getNameResidential(credit_note.node.firstName, credit_note.node.lastName)
                                            }

                                            {credit_note.node.contact.typeOfContact === "SUPPLIER" &&
                                                credit_note.node.name
                                            }
                                            {" "}                                            
                                            {
                                            (credit_note.node.payGroup === "RENT" || credit_note.node.payGroup === "AGENT" || credit_note.node.payGroup === "OWNER" || credit_note.node.payGroup === "DEVELOPER") &&
                                            <label className="text-danger">
                                            ({credit_note.node.payGroup === "RENT" || credit_note.node.payGroup === "AGENT" || credit_note.node.payGroup === "OWNER" || credit_note.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${credit_note.node.payGroup}`) : ""})
                                            </label>
                                            }   
                                        </td>
                                        <td className="text-center text-danger">{credit_note_transaction.node.transaction.invoice.docNumber}</td>
                                        <td className="text-center text-danger">{format(credit_note_transaction.node.transaction.invoice.issuedDate, 'DD/MM/YYYY')}</td>
                                        <td className="text-danger">{
                                            this.removeTrailingZeros(credit_note_transaction.node.transaction.description)
                                        }</td>
                                        <td className="text-right text-danger">{numberWithComma(0)}</td>
                                        <td className="text-right text-danger">{numberWithComma(0)}</td>
                                        <td className="text-right">{jindex + 1 === credit_note.node.creditNoteTransactionRecord.edges.length ? numberWithComma(price_total) : ''}</td>
                                        <td className="text-center text-danger">{"-"}</td>
                                       
                                    </tr>
                                )

                            })

                        }
                    }

                })
                }
                {(this.props.props.creditNoteViewer.allCreditNote.pageInfo.hasNextPage || this.props.props.creditNoteViewer.allCreditNote.pageInfo.hasPreviousPage) &&
                    <tr>
                        <td colSpan={8} className="text-right"><strong>{i18next.t("creditNoteReport:Sum")}</strong></td>
                        <td className="text-right">
                            <strong>{numberWithComma(tran_total, 0)}</strong></td>
                        <td className="text-right">
                            <strong>{numberWithComma(total, 0)}</strong></td>
                        <td className="text-right">
                            <strong>{numberWithComma(total, 0)}</strong></td>
                        <td />
                    </tr>
                }
                {/* {!this.props.props.creditNoteViewer.allCreditNote.pageInfo.hasNextPage && */}
                <tr>
                    <td colSpan={8} className="text-right"><strong>{i18next.t("creditNoteReport:Total")}</strong></td>
                    <td className="text-right">
                        <strong>{numberWithComma(this.props.props.creditNoteViewer.summaryCreditNote[0])}</strong></td>
                    <td className="text-right">
                        <strong>{numberWithComma(this.props.props.creditNoteViewer.summaryCreditNote[1])}</strong></td>
                    <td className="text-right">
                        <strong>{numberWithComma(this.props.props.creditNoteViewer.summaryCreditNote[1])}</strong></td>
                    <td />
                </tr>
                {/* } */}
            </React.Fragment>
        )
    }
}

export default CreditNoteReportTable;
