import React, { Component } from 'react'
import PrivateRoute from '../../libs/privateRoute'
import NoMatch from '../../components/noMatch'
import { Route, Switch } from "react-router-dom";
import ManageOrderList from "./manageOrderList"
import UpdateFormManageOrder from './updateFormManageOrder';

export default class manageRequest extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={ManageOrderList} />
        <PrivateRoute exact path={`${this.props.match.url}/updateFormManageOrder/:type/:status/:id`} component={UpdateFormManageOrder} />
        <Route component={NoMatch} />
      </Switch>
    )
  }
}
