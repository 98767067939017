import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import WrapperContent from '../../components/wrapper/wrapperContent'
import Header from "../../components/header/index"
import Sidebar from '../../components/sidebar'
import { Link } from 'react-router-dom'
import _ from "lodash"
import i18next from 'i18next'

const detailFree = [
    {list: "ชำระบริการค่าที่จอดรถ", account: "xxx-xxx-xxxx ธนาคารกสิกรไทย Kierra Press", description: "Lorem ipsum dolor sit amet, consectetur", quantity: "2", servicerate: "250 บาท/ชม.", discount: "30", total: "470", status: "old"},
    {list: "ชำระบริการค่าที่จอดรถ", account: "xxx-xxx-xxxx ธนาคารกสิกรไทย Kierra Press", description: "Lorem ipsum dolor sit amet, consectetur", quantity: "2", servicerate: "250 บาท/ชม.", discount: "30", total: "470", status: "old"},
    {list: "ชำระบริการค่าที่จอดรถ", account: "xxx-xxx-xxxx ธนาคารกสิกรไทย Kierra Press", description: "Lorem ipsum dolor sit amet, consectetur", quantity: "2", servicerate: "250 บาท/ชม.", discount: "30", total: "470", status: "old"},
    {list: "ชำระบริการค่าที่จอดรถ", account: "xxx-xxx-xxxx ธนาคารกสิกรไทย Kierra Press", description: "Lorem ipsum dolor sit amet, consectetur", quantity: "2", servicerate: "250 บาท/ชม.", discount: "30", total: "470", status: "old"},
]

export default class accountingAddIncome extends Component {

    constructor(props){
        super(props)

        this.state = {
            addDetailFree: false,
            addPaymentMethod: false,
            accountingIncomeList: [],
            accountingIncomeNewObject: {
                list: "", 
                account: "", 
                description: "", 
                quantity: "", 
                servicerate: "", 
                discount: "", 
                total: "",
                status: "new"
            }
        }
    }

    componentDidMount(){
        this.getData()
    }

    getData(){
        this.setState({accountingIncomeList: detailFree})
    }

    appendDetailFreeRow = () =>{
        this.setState({accountingIncomeList: [...this.state.accountingIncomeList, {...this.state.accountingIncomeNewObject}]})
    }

    deleteDetailFreeRow = (index) => {
        let item = [...this.state.accountingIncomeList];
        if (index !== -1) {
            item.splice(index, 1)
        }
        this.setState({accountingIncomeList: item});
    }

    editDetailFreeRow = (index) => {
        let item = _.merge(this.state.accountingIncomeList[index], {status: "edit"});
        this.state.accountingIncomeList[index] = item;
        this.setState({accountingIncomeList: this.state.accountingIncomeList});
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <div className="container-fluid gray" style={{ width: "100%", height: "48px" }}/>
                    <div className="container-fluid box">
                        <div className="row">
                            <div className="col">
                                <h3>
                                    <Link to="/contact/global-area-singha/accounting/all">
                                        <img
                                            src={process.env.PUBLIC_URL +
                                                "/images/icons/chevron-back.png"}
                                            alt="back"
                                            className="back"
                                            />
                                    </Link>
                                    เพิ่มบันทึกรายรับค่าใช้บริการ
                                </h3>
                            </div>
                        </div>

                        <div className="content-inner mt-5">
                            <div className="row">
                                <div className="col-12">
                                    <h6>ผู้ชำระค่าบริการ</h6>
                                    <div className="my-2" style={{width: "100%", height: "1px", backgroundColor: "#E2E2E2"}}/> 

                                    <div className="mt-3">
                                        <p>เลขที่ยูนิต: 1099/222 ผู้ทำการชำระ: Erin Saris</p>
                                        <p>ที่อยู่: 1086, 9-10 Charoen Krung Rd, Si Phraya, Bang Rak, Bangkok 10500</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                        <h6 className="mr-3 mb-0">
                                            รายละเอียดค่าบริการ
                                        </h6>
                                        <span 
                                            style={{fontSize: "10px"}} 
                                            className="btn text-primary p-0 font-weight-bold"
                                            onClick={this.appendDetailFreeRow}
                                            >
                                            + {i18next.t("settingAll:Add list")}
                                        </span>
                                    </div>
                                    <div className="mb-3 mt-3" style={{width: "100%", height: "1px", backgroundColor: "#E2E2E2"}}/>

                                    <div className="table-responsive fade-up">
                                        <table className="table table-hover">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>รายการ</th>
                                                    <th>บัญชี</th>
                                                    <th>คำอธิบาย</th>
                                                    <th>จำนวน</th>
                                                    <th>อัตราค่าบริการ</th>
                                                    <th>ส่วนลด (บาท)</th>
                                                    <th>ยอดรวม (บาท)</th>
                                                    <th/>
                                                </tr>               
                                            </thead>
                                            <tbody>
                                                {this.state.accountingIncomeList.map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            {item.status === "new" || item.status === "edit"
                                                                ? 
                                                                    (<tr key={index}>
                                                                        <td>
                                                                            <select className="form-control">
                                                                                <option value="">001 คีย์การ์ด</option>
                                                                                <option value="today">002 สินค้าเทสภาษีซื้อ 7%</option>
                                                                                <option value="today">003 ทิชชู่</option>
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <select className="form-control">
                                                                                <option value="">001 คีย์การ์ด</option>
                                                                                <option value="today">002 สินค้าเทสภาษีซื้อ 7%</option>
                                                                                <option value="today">003 ทิชชู่</option>
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" className="form-control"/>
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" className="form-control"/>
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" className="form-control"/>
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" className="form-control"/>
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" className="form-control"/>
                                                                        </td>
                                                                        <td>
                                                                            <img 
                                                                                src="/images/icons/bin.png" 
                                                                                alt="bin"
                                                                                onClick={() => this.deleteDetailFreeRow(index)}
                                                                                />
                                                                        </td>
                                                                    </tr>)
                                                                :
                                                                    (<tr key={index}>
                                                                        <td>{item.list}</td>
                                                                        <td>{item.account}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>{item.quantity}</td>
                                                                        <td>{item.servicerate}</td>
                                                                        <td>{item.discount}</td>
                                                                        <td>{item.total}</td>
                                                                        <td className="pr-0">
                                                                            <div className="d-flex justify-content-end">
                                                                                <div className="btn-group dropdown">
                                                                                    <span className="btn pr-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                                                        <img src={"/images/icons/choiceNoBG.png"} alt="choice"/>
                                                                                    </span>
                                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                        <React.Fragment>
                                                                                            <span className="dropdown-item" onClick={() => this.editDetailFreeRow(index)}>
                                                                                                <img src="/images/icons/edit.png" alt="edit"/>
                                                                                                <span className="ml-3" >แก้ไข</span>
                                                                                            </span>
                                                                                            <div className="dropdown-divider" />
                                                                                            <span className="dropdown-item"  onClick={() => this.deleteDetailFreeRow(index)}>
                                                                                                    <img src="/images/icons/bin.png" alt="bin"/>
                                                                                                    <span className="ml-3">ลบ</span>
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>)
                                                            }
                                                        </React.Fragment>
                                                    )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <div style={{width: "100%", height: "1.5px", backgroundColor: "#E2E2E2"}}/>
                                    </div>

                                    <div className="mt-3" style={{backgroundColor: "#E6F2FE", width: "100%", height: "auto", color: "#0243AE"}}>
                                        <div className="p-3">
                                            <div className="row">
                                                <div className="col-10">
                                                    <h6>ยอดรวมการชำระ (บาท)</h6>
                                                </div>
                                                <div className="col-2">
                                                    <h6 className="pl-xl-4 ml-xl-2">1,410</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="d-flex align-items-center">
                                        <h6 className="mr-3 mb-0">ช่องทางการรับชำระ</h6>
                                        <span
                                            style={{fontSize: "10px"}} 
                                            className="btn text-primary p-0 font-weight-bold"
                                            onClick={() => this.setState({addPaymentMethod: true})}
                                            >
                                            + {i18next.t("settingAll:Add list")}
                                        </span>
                                    </div>
                                    <div className="mb-3 mt-3" style={{width: "100%", height: "1px", backgroundColor: "#E2E2E2"}}/>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="table-responsive fade-up">
                                                <table className="table">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>รับโดย</th>
                                                            <th>จำนวนเงิน (บาท)</th>
                                                            <th/>
                                                        </tr>               
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Cristofer Dokidis</td>
                                                            <td>470</td>
                                                            <td/>
                                                        </tr>
                                                        {this.state.addPaymentMethod &&
                                                            <tr>
                                                                <td>
                                                                    <select className="form-control">
                                                                        <option value="">เงินสด</option>
                                                                        <option value="today">ธนาคารกสิกรไทย ออมทรัพย์</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input type="text" className="form-control"/>
                                                                </td>
                                                                <td>
                                                                    <img 
                                                                        src="/images/icons/bin.png" 
                                                                        alt="bin"
                                                                        onClick={() => this.setState({addPaymentMethod: false})}
                                                                        />
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                                <div style={{width: "100%", height: "1.5px", backgroundColor: "#E2E2E2"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col d-flex justify-content-end">
                                    <button type="button" className="btn btn-primary">เพิ่มบันทึก</button>
                                    <Link to="/contact/global-area-singha/accounting/all">
                                        <button 
                                            type="button" 
                                            className="btn btn-outline-primary ml-3">
                                            ยกเลิก
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}
