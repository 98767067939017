/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type postRegulationQueryVariables = {|
  search?: ?string,
  order?: ?string,
  startdate?: ?any,
  enddate?: ?any,
  first?: ?number,
  last?: ?number,
  regulationid?: ?string,
|};
export type postRegulationQueryResponse = {|
  +postRegulation: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +subject: ?string,
        +subjectEn: ?string,
        +description: ?string,
        +descriptionEn: ?string,
        +creator: string,
        +datePosted: ?any,
        +dateSchedule: ?any,
        +status: string,
        +type: ?string,
        +previewImage: ?string,
        +regulationFile: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: ?string,
              +fileUpload: string,
              +fileUrl: ?string,
            |}
          |}>
        |},
      |}
    |}>,
    +totalCount: ?number,
  |}
|};
export type postRegulationQuery = {|
  variables: postRegulationQueryVariables,
  response: postRegulationQueryResponse,
|};
*/


/*
query postRegulationQuery(
  $search: String
  $order: String
  $startdate: DateTime
  $enddate: DateTime
  $first: Int
  $last: Int
  $regulationid: String
) {
  postRegulation(search: $search, order: $order, startdate: $startdate, enddate: $enddate, first: $first, last: $last, regulationid: $regulationid) {
    edges {
      node {
        id
        subject
        subjectEn
        description
        descriptionEn
        creator
        datePosted
        dateSchedule
        status
        type
        previewImage
        regulationFile {
          edges {
            node {
              id
              fileName
              fileUpload
              fileUrl
            }
          }
        }
      }
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "enddate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regulationid"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startdate"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "enddate",
        "variableName": "enddate"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "regulationid",
        "variableName": "regulationid"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startdate",
        "variableName": "startdate"
      }
    ],
    "concreteType": "RegulationNodeConnection",
    "kind": "LinkedField",
    "name": "postRegulation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RegulationNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegulationNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subject",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subjectEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "descriptionEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "datePosted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dateSchedule",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previewImage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RegulationFileNodeConnection",
                "kind": "LinkedField",
                "name": "regulationFile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RegulationFileNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RegulationFileNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileUpload",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "postRegulationQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "postRegulationQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "f034b12c67a2eebfc76dd9c1c32b0e28",
    "id": null,
    "metadata": {},
    "name": "postRegulationQuery",
    "operationKind": "query",
    "text": "query postRegulationQuery(\n  $search: String\n  $order: String\n  $startdate: DateTime\n  $enddate: DateTime\n  $first: Int\n  $last: Int\n  $regulationid: String\n) {\n  postRegulation(search: $search, order: $order, startdate: $startdate, enddate: $enddate, first: $first, last: $last, regulationid: $regulationid) {\n    edges {\n      node {\n        id\n        subject\n        subjectEn\n        description\n        descriptionEn\n        creator\n        datePosted\n        dateSchedule\n        status\n        type\n        previewImage\n        regulationFile {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n              fileUrl\n            }\n          }\n        }\n      }\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a3ca06eec9649ea42b79445261d0a2a9';

module.exports = node;
