import React from "react";
import Header from "../components/header/";
import Sidebar from "../components/sidebar/";
import Wrapper from "../components/wrapper/";
import WrapperContent from "../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../env/environment";
import { commitMutation, QueryRenderer } from "react-relay";
import { format, subMonths } from "date-fns";
import thLocale from "date-fns/locale/th";
import Pagination from "../libs/newPagination";
import ComponentPagination from "../libs/componentPagination";
import Swal from "sweetalert2";
import _ from "lodash";
import DatePickerAdapter from "../libs/datePickerAdapter";
import Navigation from "./navigation";
import i18n from "i18next";
import Loading from "../libs/loading";

const query = graphql`
  query announceDashboardListQuery(
    $first: Int
    $last: Int
    $search: String
    $order: String
    $start_date: DateTime
    $end_date: DateTime
    $status: String
    $page: Boolean
  ) {
    allJuristicNews(
      first: $first
      last: $last
      search: $search
      order: $order
      startDate: $start_date
      endDate: $end_date
      newsStatus: $status
      page: $page
    ) {
      edges {
        node {
          id
          title
          titleEn
          detail
          detailEn
          status
          creator
          added
          updated
          newsType
          scheduleTime
          highlight
          group{
            id
            name
            nameEn
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation announceDashboardListMutation($input: DeleteJuristicNewsInput!) {
    deleteJuristicNews(input: $input) {
      ok
    }
  }
`;

class AnnounceDashboardList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.order = "-updated";
    this.state.search = "";
    this.state.search_input = "";
    this.state.loading = false;
    this.state.reQuery = false;
    this.state.start_date = subMonths(new Date(), 1);
    this.state.end_date = new Date();
    this.state.temp_start_date = subMonths(new Date(), 1);
    this.state.temp_end_date = new Date();
    this.state.news_type = props.match.params.news_type;

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onDeleteNews = this.onDeleteNews.bind(this);
  }

  componentDidUpdate(nextProps, nextState, nextContext) {
    if (this.state.status !== this.props.status) {
      this.setState(
        {
          checkList: [],
          check_all: false,
        },
        () => {
          this.goFirst();
        }
      );
    }
    if (this.state.news_type !== this.props.match.params.news_type) {
      this.setState({ news_type: this.props.match.params.news_type });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  onDeleteNews(id_list) {
    if (id_list.length !== 0 && !this.state.loading) {
      Swal.fire({
        title: i18n.t("press_release:are_you_sure"),
        text: i18n.t("press_release:all_press_release") + id_list.length + i18n.t("press_release:item"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: i18n.t("press_release:yes"),
        cancelButtonText: i18n.t("press_release:cancel"),
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });

          let variables = {
            input: {
              idList: id_list,
            },
          };

          commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
              this.setState({ loading: false });
              if (response.deleteJuristicNews.ok) {
                this.setState({ reQuery: !this.state.reQuery });
                Swal.fire(i18n.t("press_release:remove_success"), "", "success");
              }
            },
            onError: (err) => {
              this.setState({ loading: false });
              Swal.fire(i18n.t("press_release:remove_unsuccess"), "", "error");
            },
          });
        }
      });
    } else if (this.state.loading) {
      Swal.fire({
        title: i18n.t("press_release:waiting"),
        type: "warning",
      });
    } else {
      Swal.fire({
        title: i18n.t("press_release:select_item"),
        type: "warning",
      });
    }
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          {/* <ContactTopMenuNavigation mini={true} /> */}
          <div className="container-fluid box" id="news">
            <div className="row justify-content-between">
              <div className="col">
                <h3>
                  <Link to="/contact">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("topMenuNavigation:ประกาศ")}</span>}
                  </Translation>
                </h3>
              </div>

              <div className="col text-right">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "contact_news_create",
                }) && (
                  <Link to="/announce-dashboard/create/">
                    <button className="btn btn-primary add">
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                        alt="plus"
                      />
                      <Translation>
                        {(t) => <span>{t("press_release:เพิ่มประกาศ")}</span>}
                      </Translation>
                    </button>
                  </Link>
                )}
                <Link to="/announce-dashboard/group">
                    <button className="btn btn-primary add ml-3">
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                        alt="plus"
                      />
                      <Translation>
                        {(t) => <span>{t("press_release:จัดการหมวดหมู่")}</span>}
                      </Translation>
                    </button>
                  </Link>
              </div>
            </div>
            <Navigation />
            <Translation>
              {t=>
              <div className="content-inner">
              <div className="row mb-2 mt-4">
                <div className="col-2">
                  {this.state.news_type !== "deleted" && (
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-light dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {t("press_release:manage_multiple")}
                      </button>
                      {_.some(
                        JSON.parse(localStorage.getItem("permission_list")),
                        { codename: "contact_news_delete" }
                      ) && (
                        <div className="dropdown-menu">
                          <span
                            className="dropdown-item approve-dropdown"
                            onClick={() =>
                              this.onDeleteNews(this.state.checkList)
                            }
                          >
                            {this.state.loading && (
                              <span className="spinner-border spinner-border-sm align-middle mr-2" />
                            )}
                               {t("press_release:remove_list")}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="col-7">
                  <div className="form-inline float-right">
                    <div className="form-group mb-2">
                      <label htmlFor="end_date" className="ml-3 mr-3">
                            {t("press_release:sort_by_date")}
                      </label>
                      <DatePickerAdapter
                        id="temp_start_date"
                        className="form-control"
                        name="temp_start_date"
                        selected={this.state.temp_start_date}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label htmlFor="end_date" className="ml-3 mr-3">
                            {t("press_release:to")}
                      </label>
                      <DatePickerAdapter
                        id="temp_end_date"
                        name="temp_end_date"
                        className="form-control"
                        selected={this.state.temp_end_date}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-3 input-group">
                  <input
                    type="text"
                    className="form-control search-input input-size col-8"
                    placeholder={t("press_release:search")}
                    name="search"
                    value={this.state.search_input}
                    onChange={(e) =>
                      this.setState({ search_input: e.target.value })
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary form-control search-button"
                    name="search"
                    value={this.state.search_input}
                    onClick={(e) => this.handleSearch(e)}
                  >
                    {t("press_release:search")}
                  </button>
                </div>
              </div>

              <QueryRenderer
                environment={environment}
                query={query}
                variables={{
                  search: this.state.search,
                  first: this.state.first,
                  last: this.state.last,
                  order: this.state.order,
                  reQuery: this.state.reQuery,
                  start_date: this.state.start_date,
                  end_date: this.state.end_date,
                  status:this.state.news_type,
                  page: true
                }}
                cacheConfig={{ use_cache: false }}
                render={({ error, props }) => {
                  if (error) {
                    return (
                      <div className="alert alert-danger" role="alert">
                        {error.message}
                      </div>
                    );
                  } else if (props) {
                    return (
                      <React.Fragment>
                        <div className="card fade-up">
                          <div className="table-responsive">
                            <table className="table table-hover">
                              <thead className="thead-light">
                                <tr>
                                  {this.state.news_type !== "VOID" && (
                                    <th width="50">
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          id="checkAll"
                                          className="custom-control-input"
                                          onChange={() =>
                                            this.getAllList(props.allNews.edges)
                                          }
                                          checked={this.state.check_all}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="checkAll"
                                        />
                                      </div>
                                    </th>
                                  )}
                                  <th width="600">{t("press_release:subject")}</th>
                                  <th>{t("press_release:post_by")}</th>
                                  <th>
                                    <span
                                      onClick={() =>
                                        this.setState({
                                          order:
                                            this.state.order === "updated"
                                              ? "-updated"
                                              : "updated",
                                        })
                                      }
                                      className="cursor"
                                    >
                                      {t("press_release:date_posted")}
                                      {"-updated" === this.state.order ? (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/sort-down.png"
                                          }
                                          alt="sort-down-icon"
                                          className="ml-1"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/sort-up.png"
                                          }
                                          alt="sort-up-icon"
                                          className="ml-1"
                                        />
                                      )}
                                    </span>
                                  </th>
                                  <th>
                                    <span
                                      className="cursor">
                                      {t("press_release:schedule")}
                                    </span>
                                  </th>
                                  {this.state.news_type !== "VOID" && <th />}
                                </tr>
                              </thead>
                              <tbody>
                                {props?.allJuristicNews?.edges.length > 0 &&
                                  props?.allJuristicNews?.edges?.map((news) => {
                                    let detail_new = null;
                                    if (news?.node?.detail?.length >= 80) {
                                      detail_new =
                                        news?.node?.detail
                                          .replaceAll(/<[^>]+>/g, "")
                                          .substring(0, 80) + "...";
                                      detail_new = detail_new.replaceAll(
                                        "&nbsp;",
                                        " "
                                      );
                                    } else {
                                      detail_new = news?.node?.detail.replaceAll(
                                        /<[^>]+>/g,
                                        ""
                                      );
                                      detail_new = detail_new.replaceAll(
                                        "&nbsp;",
                                        " "
                                      );
                                    }

                                    return (
                                      <tr key={news.node?.id}>
                                        {this.state.news_type !== "VOID" && (
                                          <td>
                                            <div className="custom-control custom-checkbox">
                                              <input
                                                type="checkbox"
                                                id={news.node.id}
                                                className="custom-control-input"
                                                onChange={() =>
                                                  this.appendToCheckList(
                                                    news.node.id
                                                  )
                                                }
                                                checked={this.checkList(
                                                  news.node.id
                                                )}
                                                disabled={news.node.status === 'VOID'}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={news.node.id}
                                              />
                                            </div>
                                          </td>
                                        )}
                                        <td>
                                          {news.node.status === 'DRAFT' && new Date(news.node.scheduleTime) > new Date(Date.now()) ? (
                                            <Link
                                              to={
                                                "/announce-dashboard/create/" +
                                                news.node.id +
                                                "/" +
                                                this.props.match.params
                                                  .news_type
                                              }
                                            >
                                              <h6 className="blue-color">
                                                {news.node.title}
                                                <span className="small ml-2 color red-color">{t("press_release:draft")}</span>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/clock.png'} alt="clock-icon" width="25" height="25" style={{marginLeft:'10px'}}/>
                                              </h6>
                                            </Link>
                                          ) : 
                                          news.node.status !== 'DRAFT' && new Date(news.node.scheduleTime) > new Date(Date.now()) ?
                                          <Link
                                            to={"/announce-dashboard/create/" + news.node.id + '/' + this.props.match.params.news_type}>
                                            <h6 className="blue-color">
                                              {news.node.title}
                                              <img src={process.env.PUBLIC_URL + '/images/icons/clock.png'} alt="clock-icon" width="25" height="25" style={{marginLeft:'10px'}}/>
                                            </h6>
                                          </Link> :
                                          news.node.status === 'DRAFT' ?
                                          <Link
                                              to={"/announce-dashboard/create/" + news.node.id + '/' + this.props.match.params.news_type}>
                                              <h6 className="blue-color">
                                                {news.node.title}
                                                <span className="small ml-2 color red-color">{t("press_release:draft")}</span>
                                              </h6>
                                          </Link> : 
                                           news.node.status === 'VOID' ? ' ' :
                                          
                                          (
                                            <Link
                                              to={
                                                "/announce-dashboard/create/" +
                                                news.node.id +
                                                "/" +
                                                this.props.match.params
                                                  .news_type
                                              }
                                            >
                                              <h6>{news.node.title}</h6>
                                            </Link>
                                          )}

                                          <p className="small color gray-color">
                                            {detail_new}
                                          </p>
                                        </td>
                                        <td>{news.node.creator}</td>
                                        <td>
                                          {format(
                                            news.node.updated,
                                            "DD/MM/YYYY - HH:mm น.",
                                            { locale: thLocale }
                                          )}
                                        </td>
                                        {news.node.scheduleTime ?
                                          <td>{format(news.node.scheduleTime, 'DD/MM/YYYY - HH:mm น.', { locale: thLocale })}</td> 
                                          :
                                          <td></td>
                                        }
                                        {this.state.news_type !== "VOID" && (
                                          <td>
                                            {_.some(
                                              JSON.parse(
                                                localStorage.getItem(
                                                  "permission_list"
                                                )
                                              ),
                                              { codename: "contact_news_edit" }
                                            ) && news.node.status !== 'VOID' &&  (
                                              <Link
                                                to={
                                                  "/announce-dashboard/create/" +
                                                  news.node.id
                                                }
                                              >
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/edit.png"
                                                  }
                                                  alt="edit-icon"
                                                  className="cursor"
                                                />
                                              </Link>
                                            )}
                                            {_.some(
                                              JSON.parse(
                                                localStorage.getItem(
                                                  "permission_list"
                                                )
                                              ),
                                              {
                                                codename: "contact_news_delete",
                                              }
                                            ) &&  news.node.status !== 'VOID' &&  (
                                              <img
                                                onClick={() =>
                                                  this.onDeleteNews([
                                                    news.node.id,
                                                  ])
                                                }
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/images/icons/delete.png"
                                                }
                                                alt="delete-icon"
                                                className="ml-4 cursor"
                                              />
                                            )}
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <Pagination
                            changePage={this.changePage}
                            first={this.state.first}
                            last={this.state.last}
                            totalCount={props?.allNews?.totalCount}
                          />
                        </div>
                      </React.Fragment>
                    );
                  }
                  return <Loading/>
                }}
              />
            </div>
              }
            </Translation>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default AnnounceDashboardList;
