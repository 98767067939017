import '../styles/main.scss';
import React, {Component} from 'react';
import CollectionLetter from './collectionLetter'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import _ from 'lodash';
import Loading from "../../libs/loading";
import WrapperCollectionLetterOldTime3 from './wrapperCollectionLetterOldTime3';

const query = graphql`
    query wrapperCollectionLetterOldTime2Query($search: String, $formDebit: Int) {
        selfProject{
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            bankCompCode
            bankServiceCode
            taxIdNumber
            typeOfProject
            typeOfCompany
            establishmentStatus
        }
        allSettingAutomaticDebit{
            edges{
                node{
                    receivableOutstanding
                }
            }
        }
        allSettingFormDebit(formDebit: $formDebit) {
            edges{
                node{
                    id
                    description
                    paymentCheck
                    paymentTimeStatus
                    paymentTime1
                    paymentTime2
                    bankAccountStatus
                    numberOfDays
                    bankAccount{
                        id
                        accountNumber
                        bankName
                        accountName
                        branch
                    }
                    channelDescription
                    contactName
                    contactPhone
                    signerName
                    signerPosition
                    formDebit
                    refDocument
                    refReport
                    refCollection1
                    refCollection2
                    refCollection3
                }
            }
        }
        allCollectionLetters(search: $search){
            edges{
                node{
                    id
                    numberOfTimes
                    refDocNumber
                    docNumber
                    added
                    issuedDate
                    creator
                    receivableOutstanding
                    debtStatus
                    status
                    updated
                    sumTotal
                    contact {
                        id
                        name
                        refNumber
                        firstName
                        lastName
                        registeredName
                        registeredAddress
                        registeredCountry
                        registeredProvince
                        registeredCity
                        registeredDistrict
                        registeredPostalCode
                        typeOfContact
                        residential {
                            type {
                                name
                                slug
                            }
                        }
                    }
                    documentCollectionLetters{
                                edges{
                                    node{
                                        id
                                        fileName
                                        numPage
                                    }
                                }
                    }
                    recordCollectionLetters{
                        edges {
                            node {
                                id
                                sumTotalRecord
                                transaction {
                                            id
                                            description
                                            total
                                            invoice{
                                                id
                                                docNumber
                                                dueDate
                                                total
                                                issuedDate
                                            }
                                            productAndService {
                                                id
                                                productCode
                                                name
                                            }
                                            chartOfAccount {
                                                chartOfAccountCode
                                            }
                                            receiveTransaction(receive_Status_In: "paid") {
                                                edges {
                                                node {
                                                    amount
                                                    added
                                                    receive {
                                                    issuedDate

                                                    }
                                                }
                                                }
                                            }
                                            creditNoteTransaction(status: "paid") {
                                                edges {
                                                node {
                                                    price
                                                    issuedDate
                                                    receive {
                                                    id
                                                    }
                                                }
                                                }
                                            }
                                            creditNoteTransactionRecord(status: "paid"){
                                                edges{
                                                    node{
                                                        id
                                                        price
                                                        status
                                                    }
                                                }
                                            }
                                            receiptDepositTransactionUsed {
                                                edges {
                                                node {
                                                    amount
                                                    receiveTransaction {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }  
    }`;

class WrapperCollectionLetterOldTime2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type : 'all',
            indexOutStanding : 1,
        }
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    updateIndexOutStanding = (value) => {
        this.setState({
            indexOutStanding : value
        })
    }

    render() {        
        return (
            <React.Fragment>

                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{search: this.props.searchOld, formDebit: this.props.formDebit}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            let sumAll = props.allCollectionLetters.edges[0].node.sumTotal
                            let group_transaction = _(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges).groupBy('node.transaction.productAndService.name').map((value,name)=> {
                                let sumTotal = _.sumBy(value,'node.sumTotalRecord')
                                _.forEach(value, transaction => {
                                    if(transaction.node.transaction.creditNoteTransaction.edges.length > 0){
                                        let sumCredit = _.sumBy(transaction.node.transaction.creditNoteTransaction.edges, 'node.price') 
                                        sumTotal -= sumCredit
                                    }
                                    if(transaction.node.transaction.creditNoteTransactionRecord.edges.length > 0){
                                        let sumCredit = _.sumBy(transaction.node.transaction.creditNoteTransactionRecord.edges, 'node.price') 
                                        sumTotal -= sumCredit
                                    }
                                    if(transaction.node.transaction.receiptDepositTransactionUsed.edges.length > 0){
                                        // let sumReceiveUse = _.sumBy(transaction.node.transaction.receiptDepositTransactionUsed.edges, 'node.amount') 
                                        // sumTotal -= sumReceiveUse
                                        const sumReceiveUse = transaction.node.transaction.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
                        
                                            if (obj.node.receiveTransaction) {
                                                return total
                                            } else {
                                                return total + obj.node.amount
                                            }
                                        }, 0);

                                        sumTotal -= sumReceiveUse
                                    }
                                    if(transaction.node.transaction.receiveTransaction.edges.length > 0){
                                        let sumReceive = _.sumBy(transaction.node.transaction.receiveTransaction.edges, 'node.amount') 
                                        sumTotal -= sumReceive
                                    }
                                })
                                return({
                                    name: name,
                                    sum_transaction: sumTotal,
                                })
                            }).value()

                            group_transaction = _.filter(group_transaction,transaction => {
                                return transaction.sum_transaction > 0
                            })

                            let recordCollectionLetters = []
                            let countList = 20
                            
                            //พิเศษ
                            if(localStorage.getItem('site_id') === '340' || localStorage.getItem('site_id') === '468' || localStorage.getItem('site_id') === '402' || localStorage.getItem('site_id') === '490' || localStorage.getItem('site_id') === '456'){
                                countList = 10
                            }

                            // theteak39 - CLL-2022070001
                            if(localStorage.getItem('site_id') === '340' && this.props.match.params.id === 'Q29sbGVjdGlvbkxldHRlcnNOb2RlOjQ1ODI1MQ=='){
                                countList = 9
                            }

                            let sumLine = 0;
                            let dataMapLine = []

                            if(localStorage.getItem('site_id') !== '340' && localStorage.getItem('site_id') !== '468' && 
                                localStorage.getItem('site_id') !== '402' && localStorage.getItem('site_id') !== '490' && 
                                localStorage.getItem('site_id') !== '456' && localStorage.getItem('site_id') !== '340'){
                                    let mapData = props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges.map((n,ins)=> ({...n, no : ins+1}))
                                    
                                    _.forEach(mapData , (record , index) => {
                                 
                                        dataMapLine.push(record)
                                        if(record.node.transaction.description.length >= 145){
                                            sumLine = sumLine + 5;  //cal row description per page
                                        }
                                        else if(record.node.transaction.description.length >= 115){
                                            sumLine = sumLine + 4; 
                                        }
                                        else if(record.node.transaction.description.length >= 95){
                                            sumLine = sumLine + 3;
                                        }
                                        else if(record.node.transaction.description.length >= 75){
                                            sumLine = sumLine + 2.7;
                                        }
                                        else if(record.node.transaction.description.length >= 40){
                                            sumLine = sumLine + 2.1; 
                                        }
                                        else if(record.node.transaction.description.length > 0) {
                                            sumLine = sumLine + 1.1; 
                                        }
                                        
                                        if(sumLine >= 37 || props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges.length === (index+1)){
                                            recordCollectionLetters.push(dataMapLine)
                                            dataMapLine = []
                                            sumLine = 0
                                        }
                                    })

                            }else{
                                if(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges.length > countList){
                                    recordCollectionLetters = _.chunk(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges,countList)
                                }else{
                                    recordCollectionLetters = [props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges]
                                }

                            } 

                            // let totalRecordOutStanding = _.sumBy(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges,'node.sumTotalRecord')
                            _.forEach(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges , record => {
                                if(record.node.transaction.creditNoteTransaction.edges.length > 0){
                                    // let sumCredit = _.sumBy(record.node.transaction.creditNoteTransaction.edges, 'node.price') 
                                    // totalRecordOutStanding -= sumCredit
                                }
                                if(record.node.transaction.creditNoteTransactionRecord.edges.length > 0){
                                    // let sumCredit = _.sumBy(record.node.transaction.creditNoteTransactionRecord.edges, 'node.price') 
                                    // totalRecordOutStanding -= sumCredit
                                }
                                if(record.node.transaction.receiptDepositTransactionUsed.edges.length > 0){
                                    // let sumReceiveUse = _.sumBy(record.node.transaction.receiptDepositTransactionUsed.edges, 'node.amount') 
                                    // totalRecordOutStanding -= sumReceiveUse
                                    // const sumReceiveUse = record.node.transaction.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
                        
                                    //     if (obj.node.receiveTransaction) {
                                    //         return total
                                    //     } else {
                                    //         return total + obj.node.amount
                                    //     }
                                    // }, 0);

                                    // totalRecordOutStanding -= sumReceiveUse
                                }
                                if(record.node.transaction.receiveTransaction.edges.length > 0){
                                    // let sumReceive = _.sumBy(record.node.transaction.receiveTransaction.edges, 'node.amount') 
                                    // totalRecordOutStanding -= sumReceive
                                }
                            } ) 
                            return(
                                <React.Fragment>         
                                    { Number(this.props.numberOftime) > 3 &&                              
                                    <WrapperCollectionLetterOldTime3
                                        receivablePage = {recordCollectionLetters?.length}  
                                        formDebit = {this.props.formDebit - 1}  
                                        searchOld = {props.allCollectionLetters?.edges[0].node.refDocNumber}  
                                        numberOftime = {this.props.numberOftime} 
                                        ref1 = {this.props.ref1}                                                                                                 
                                    /> }

                                    {this.props.numberOftime === '4' && this.props.ref2 &&
                                    <CollectionLetter 
                                        selfProject={props.selfProject}
                                        allSettingFormDebit = {props.allSettingFormDebit?.edges[0]}
                                        allCollectionLetters = {props.allCollectionLetters?.edges[0]}
                                        group_transaction = {group_transaction}
                                        timeOld = {this.props.formDebit}
                                        receivablePage = {this.props.receivablePage}
                                        sumAll= {sumAll}
                                    />}    
                                    {this.props.numberOftime === '3' && this.props.ref1 &&
                                    <CollectionLetter 
                                        selfProject={props.selfProject}
                                        allSettingFormDebit = {props.allSettingFormDebit?.edges[0]}
                                        allCollectionLetters = {props.allCollectionLetters?.edges[0]}
                                        group_transaction = {group_transaction}
                                        timeOld = {this.props.formDebit}
                                        receivablePage = {this.props.receivablePage}
                                        sumAll= {sumAll}
                                    />}    
                                                                        

                                </React.Fragment>   
                            )
                        }
                         return <div className="text-center"><Loading/></div>;
                    }}
                /> 
            </React.Fragment>
        );
    }
}

export default WrapperCollectionLetterOldTime2;
