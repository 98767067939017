/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductTransactionList = "BUY" | "CONVERSION_BALANCE" | "PROJECT" | "SELL" | "UNIT" | "%future added value";
export type productTransactionListQueryVariables = {|
  id: string,
  start_date?: ?any,
  end_date?: ?any,
|};
export type productTransactionListQueryResponse = {|
  +allProductTransaction: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +list: ProductTransactionList,
        +ref: string,
        +amount: number,
        +price: number,
        +totalValue: number,
        +added: any,
        +date: ?any,
        +balance: ?number,
        +valueReal: ?number,
        +totalValueReal: ?number,
        +product: {|
          +cost: ?number
        |},
      |}
    |}>
  |}
|};
export type productTransactionListQuery = {|
  variables: productTransactionListQueryVariables,
  response: productTransactionListQueryResponse,
|};
*/


/*
query productTransactionListQuery(
  $id: ID!
  $start_date: DateTime
  $end_date: DateTime
) {
  allProductTransaction(product_Id: $id, startDate: $start_date, endDate: $end_date) {
    edges {
      node {
        id
        list
        ref
        amount
        price
        totalValue
        added
        date
        balance
        valueReal
        totalValueReal
        product {
          cost
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v3 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "product_Id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "list",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ref",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalValue",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valueReal",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalValueReal",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "productTransactionListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ProductTransactionNodeConnection",
        "kind": "LinkedField",
        "name": "allProductTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductTransactionNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductTransactionNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductAndServiceNode",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "productTransactionListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ProductTransactionNodeConnection",
        "kind": "LinkedField",
        "name": "allProductTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductTransactionNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductTransactionNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductAndServiceNode",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f50ed9c7718cfd7ae7dca49d39c00097",
    "id": null,
    "metadata": {},
    "name": "productTransactionListQuery",
    "operationKind": "query",
    "text": "query productTransactionListQuery(\n  $id: ID!\n  $start_date: DateTime\n  $end_date: DateTime\n) {\n  allProductTransaction(product_Id: $id, startDate: $start_date, endDate: $end_date) {\n    edges {\n      node {\n        id\n        list\n        ref\n        amount\n        price\n        totalValue\n        added\n        date\n        balance\n        valueReal\n        totalValueReal\n        product {\n          cost\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '04257a0614f4a681dd2d431a4a973fae';

module.exports = node;
