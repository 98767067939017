/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type profileQueryVariables = {||};
export type profileQueryResponse = {|
  +myUser: ?{|
    +id: string,
    +username: string,
    +juristic: ?{|
      +id: string,
      +firstName: string,
      +lastName: string,
      +image: ?string,
    |},
    +tenant: ?{|
      +id: string,
      +firstName: string,
      +lastName: ?string,
      +image: string,
    |},
  |}
|};
export type profileQuery = {|
  variables: profileQueryVariables,
  response: profileQueryResponse,
|};
*/


/*
query profileQuery {
  myUser {
    id
    username
    juristic {
      id
      firstName
      lastName
      image
    }
    tenant {
      id
      firstName
      lastName
      image
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "image",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserNode",
    "kind": "LinkedField",
    "name": "myUser",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicNode",
        "kind": "LinkedField",
        "name": "juristic",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TenantNode",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "profileQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "profileQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "55205a887633dae3ecbe571590f59276",
    "id": null,
    "metadata": {},
    "name": "profileQuery",
    "operationKind": "query",
    "text": "query profileQuery {\n  myUser {\n    id\n    username\n    juristic {\n      id\n      firstName\n      lastName\n      image\n    }\n    tenant {\n      id\n      firstName\n      lastName\n      image\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76f137dffaa9b471ff7d312a62cd1a4b';

module.exports = node;
