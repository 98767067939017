/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateParcelStatusInput = {|
  idList?: ?$ReadOnlyArray<?string>,
  status?: ?string,
  returnRemark?: ?string,
  deleteRemark?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateParcelMutationVariables = {|
  input: UpdateParcelStatusInput
|};
export type UpdateParcelMutationResponse = {|
  +updateParcelStatus: ?{|
    +ok: ?boolean
  |}
|};
export type UpdateParcelMutation = {|
  variables: UpdateParcelMutationVariables,
  response: UpdateParcelMutationResponse,
|};
*/


/*
mutation UpdateParcelMutation(
  $input: UpdateParcelStatusInput!
) {
  updateParcelStatus(input: $input) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateParcelStatusPayload",
    "kind": "LinkedField",
    "name": "updateParcelStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateParcelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateParcelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "89a470f9f8094238d08ab19aa5573b15",
    "id": null,
    "metadata": {},
    "name": "UpdateParcelMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateParcelMutation(\n  $input: UpdateParcelStatusInput!\n) {\n  updateParcelStatus(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '99bf66e3a150b34e89247dd8b5466257';

module.exports = node;
