import React, { Component } from 'react';
import { Link } from "react-router-dom";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { fetchQuery } from "relay-runtime";
// import {commitMutation} from 'react-relay'
import Swal from "sweetalert2";
import { Redirect } from 'react-router';
import getDocumentNumber from "../../../../libs/getDocumentNumber";
import { Translation } from "react-i18next";
import { format } from "date-fns";
import numberWithComma from "../../../../libs/numberWithComma";
// import SearchSelect from "../../../../libs/searchSelect";
import AcceptCreditNoteCreateMutation from "../mutations/acceptCreditNoteCreateMutation"
import getNameResidential from "../../../../libs/getNameResidential";
import UploadFileInput from "../../../../libs/uploadFileInput";
import i18n from "i18next";
import _ from "lodash"
import i18next from 'i18next';

const query = graphql`
    query acceptCreditNoteCreateFormCoreQuery{
      allProjects {
            edges {
            node {
            periodCreate
            }
      }
    }
    }
`;
class AcceptCreditNoteCreateFormCore extends Component {

    constructor(props) {
        super(props);
        //Cal vat pretax
        const price = Math.round(((props.transaction_object.total - props.transaction_object.paid) / (1 + props.transaction_object.vat / 100) + Number.EPSILON) * 100) / 100;
        const vatAmount = Math.round((price * (props.transaction_object.vat / 100) + Number.EPSILON) * 100) / 100;
        this.state = {
            issuedDate: new Date(),
            remark: '',
            price: price,
            vat: props.contact.vat || 0,
            vatAmount: vatAmount,
            total: (props.transaction_object.total - props.transaction_object.paid),
            max: (props.transaction_object.total - props.transaction_object.paid),
            transaction_object: props.transaction_object,
            chartOfAccount: props.transaction_object.productAndService?.chartOfAccount?.id,
            redirectToList: false,
            category: props.category,
            loading: false,
            tax_invoice_number: "",
            ref_number: '',
            tax_invoice_date: new Date(),
            // tax_invoice_period: new Date(),
            tax_invoice_period: null,
            document_upload: '',
            case_return_vat: props.contact && props.contact.vat === 7 ? true : false,
            temp_month_before : '',
            temp_month_after : '',
            period_create:''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputDocument = this.handleInputDocument.bind(this);
        this.submitFrom = this.submitFrom.bind(this);
        this.acceptCreditNoteSubmit = this.acceptCreditNoteSubmit.bind(this);
        this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
        this.onSubmitError = this.onSubmitError.bind(this);
    }

    handleInputChange(e) {
        if (e.target.name === 'description') {
            let transaction_object = { ...this.state.transaction_object };
            transaction_object.description = e.target.value;
            this.setState({ transaction_object: transaction_object });
        } else {
            if (e.target.name === 'price') {
                const vatAmount = this.state.case_return_vat ? Math.round((parseFloat(e.target.value) * (this.state.transaction_object.vat / 100) + Number.EPSILON) * 100) / 100 : 0;
                const total = parseFloat(e.target.value) + parseFloat(vatAmount);
                this.setState({
                    price: e.target.value,
                    vatAmount: vatAmount,
                    total: total,
                });
            }else if(e.target.name === 'vatAmount'){
                const total =  Math.round( ((parseFloat(this.state.price) + parseFloat(e.target.value)) + Number.EPSILON) * 100) / 100 ;
                this.setState({
                    vatAmount: parseFloat(e.target.value),
                    total: total,
                });
            }
             else {
                this.setState({ [e.target.name]: e.target.value });
            }

        }

    }

    handleInputDocument(e) {
        this.setState({
            document_upload: e.currentTarget.files[0],
        });
    }

    submitFrom(e) {
        e.preventDefault();
        if (this.state.price <= 0) {
            Swal.fire('ยอดชำระไม่ถูกต้อง', 'กรุณาใส่จำนวนยอดลดหนี้', 'warning')
                .then(() => this.setState({ loading: false }))
        }
        else {
            this.setState({ loading: true });
            this.acceptCreditNoteSubmit();
        }
    }

    acceptCreditNoteSubmit() {
        let variables = {
            input: {
                "issuedDate": format(this.state.issuedDate, 'YYYY-MM-DD'),
                "contact": this.props.customerList[0],
                "reason": this.props.reason_create,
                "transaction": this.state.transaction_object.id,
                "description": this.state.transaction_object.description,
                "remark": this.state.remark,
                "price": this.state.price,
                "vat": this.state.vat,
                "vatAmount": this.state.vatAmount,
                "total": this.state.total,
                "taxInvoiceNumber": this.state.tax_invoice_number,
                "refNumber": this.state.ref_number,
                "taxInvoiceDate": format(this.state.tax_invoice_date, 'YYYY-MM-DD'),
                "taxInvoicePeriod": format(this.state.tax_invoice_period, 'YYYY-MM-DD'),
            }
        };
        let uploadables = { document_upload: this.state.document_upload };
        if( parseFloat(this.state.price) + parseFloat(this.state.vatAmount) > this.state.max){
            Swal.fire('จำนวนยอดรวมไม่ถูกต้อง!', '', 'warning');
            this.setState({ loading: false });
        }
        else{
            AcceptCreditNoteCreateMutation(variables, uploadables, this.onSubmitSuccess, this.onSubmitError)
        }
    }

    onSubmitSuccess(response) {
        this.setState({ loading: false });
        if (response) {
            // Swal.fire('i18next.t("Allaction:successcompleate")', '', 'success').then(() => {
            //     this.setState({ redirectToList: true })
            // });
            if (response.createAcceptCreditNote.ok) {
                Swal.fire(i18n.t('creditNoteCreate:Save successfully'), '', 'success').then(() => {
                    this.setState({redirectToList: true})
                });
            } else {
                Swal.fire(i18n.t('creditNoteCreate:Failed to save'), response.createAcceptCreditNote.warningText, 'warning');
            }

        } else {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'warning');
        }
    }

    onSubmitError(err) {
        this.setState({ loading: false });
        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
    }


    componentDidMount(){
        fetchQuery(environment, query)
        .then(response_data => {
            if(response_data){
              let data = _.cloneDeep(response_data);
              this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
            }
        })
    }
  
  
    calPeriodMouth(val){
      let month_before = new Date();
      let month_after= new Date();
  
      month_before.setMonth(month_before.getMonth() -  parseInt(val));
      month_after.setMonth(month_after.getMonth() + parseInt(val));
  
      this.setState({
          temp_month_before : month_before,
          temp_month_after : month_after,
      })
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to={this.props.nextLink} />
        }

        return (
            <form onSubmit={this.submitFrom} id="credit-note-create-form">
                <div className="container-fluid box">
                    <div className="row">
                        <div className="col">
                            <h3 className="mb-4">
                                <Link to={this.props.backLink}>
                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                        alt="back" className="back" />
                                </Link>
                                <Translation>
                                    {
                                        t => <span>{t(this.state.category + ':create_' + this.state.category)}</span>
                                    }
                                </Translation>
                            </h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <p><strong>เจ้าหนี้</strong></p>
                            <div className="input-group">
                                <input type="text" className="form-control"
                                    value={
                                        this.props.contact.typeOfContact === "RESIDENTIAL" ? this.props.contact.refNumber + " " + getNameResidential(this.props.contact.firstName, this.props.contact.lastName) : this.props.contact.refNumber + " " + this.props.contact.name
                                    }
                                    disabled />
                            </div>
                        </div>
                        <div className="col">
                            <p><strong>เลขที่</strong></p>
                            <div className="input-group">

                                <input type="text" className="form-control"
                                    value={getDocumentNumber.get(this.state.category, 'XXXX', this.state.issued_date)}
                                    disabled />
                            </div>
                        </div>
                        <div className="col">
                            <p><strong>อ้างอิง</strong></p>
                            <div className="input-group">
                                <input type="text" className="form-control"
                                    value={this.state.transaction_object.purchaseRecordGroup.docNumber}
                                    disabled />
                            </div>
                        </div>
                        <div className="col">
                            <p><strong>วันที่ออก</strong></p>
                            <div className="input-group">
                            {
                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ? 
                                <DatePickerAdapter
                                    name="issuedDate" className="form-control"
                                    selected={this.state.issuedDate} 
                                    minDate={this.state.temp_month_before}
                                    // maxDate={this.state.temp_month_after}
                                    onChange={this.handleInputChange}
                                    required={true}
                                />:
                                <DatePickerAdapter
                                    name="issuedDate" className="form-control"
                                    selected={this.state.issuedDate} maxDate={this.state.dueDate}
                                    onChange={this.handleInputChange}
                                    required={true}
                            />
                            }
                            </div>
                        </div>
                    </div>

                    {this.props.category === 'accept_credit_note' &&
                        <React.Fragment>
                            <div className="row form-group mt-3">
                                <div className="col">
                                    <label htmlFor="tax_invoice_number"
                                        className="mr-5">
                                        <strong>เลขที่ใบกำกับภาษี</strong>
                                    </label>
                                    <input type="text" className="form-control" id="tax_invoice_number"
                                        value={this.state.tax_invoice_number}
                                        onChange={this.handleInputChange} name="tax_invoice_number"
                                        maxLength="30"
                                    // required={((this.props.contact && this.props.contact.typeOfSupplier === 'PERSON') || !this.props.contact) ? false : 'required'}
                                    />
                                     <strong className="text-danger font-small"> <Translation>{t => t("PurchaseCreate:*Enter text no more than 30 characters")}</Translation> {this.state.tax_invoice_number.length} / 30</strong>
                                </div>
                                {this.state.tax_invoice_number &&
                                    <div className="col ">
                                        <label htmlFor="taxDate"
                                            className="mr-5">
                                            <strong>ลงวันที่</strong>
                                        </label>
                                        <div className="input-group">
                                            <DatePickerAdapter
                                                name="tax_invoice_date" className="form-control"
                                                selected={this.state.tax_invoice_date}
                                                onChange={this.handleInputChange}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="col">
                                    <label htmlFor="ref_number" className="mr-5">
                                        <strong>เลขเอกสารอ้างอิง</strong>
                                    </label>

                                    <input type="text" className="form-control" id="ref_number"
                                        value={this.state.ref_number}
                                        onChange={this.handleInputChange} name="ref_number" />
                                </div>
                                <div className="col">
                                    <UploadFileInput
                                        handleInputDocument={this.handleInputDocument}
                                        documentUpload={this.state.document_upload}
                                    />
                                </div>
                            </div>

                            {/* <div className="row form-group mt-3">
                            <div className="col form-inline">
                                <label htmlFor="tax_invoice_period"
                                       className="mr-5">
                                    <strong>งวด</strong>
                                </label>
                                <div className="input-group">
                                    <DatePickerAdapter
                                        name="tax_invoice_period" className="form-control"
                                        id="tax_invoice_period"
                                        selected={this.state.tax_invoice_period}
                                        onChange={this.handleInputChange}
                                        showMonthYearPicker={true}
                                        dateFormat="MM/yyyy"
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div> */}

                        </React.Fragment>
                    }

                    <hr />
                    <div className="row">
                        <div className="col">
                            <p><strong>ที่อยู่</strong></p>
                            {this.props.contact.registeredProvince.includes("กรุงเทพ") || this.props.contact.registeredProvince.includes("กทม") ? (
                                <>
                                    {this.props.contact.registeredAddress}
                                    {this.props.contact.registeredDistrict && ` แขวง${this.props.contact.registeredDistrict}`}
                                    {this.props.contact.registeredCity && ` เขต${this.props.contact.registeredCity}`}
                                    {this.props.contact.registeredProvince && ` ${this.props.contact.registeredProvince} `}
                                    {this.props.contact.registeredPostalCode}
                                </>
                            ) : (
                                <>
                                    {this.props.contact.registeredAddress}
                                    {this.props.contact.registeredDistrict && ` ตำบล${this.props.contact.registeredDistrict}`}
                                    {this.props.contact.registeredCity && ` อำเภอ${this.props.contact.registeredCity}`}
                                    {this.props.contact.registeredProvince && ` จังหวัด${this.props.contact.registeredProvince} `}
                                    {this.props.contact.registeredPostalCode}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="fade-up">
                        <table className="table table-hover mt-2">
                            <thead className="thead-light">
                                <tr>
                                    <th>สินค้า/บริการ</th>
                                    <th>บัญชี</th>
                                    <th>คำอธิบาย</th>
                                    <th className="text-right">{this.state.case_return_vat ? "ยอดก่อนภาษี": "ยอด"}</th>
                                    {(this.state.case_return_vat) && (
                                        <th width="30" className="text-right">
                                            ภาษี
                                        </th>
                                    )}

                                    {(this.state.case_return_vat) && (
                                        <th width="40" className="text-right">
                                            จำนวนภาษี
                                        </th>
                                    )}
                                </tr>

                            </thead>
                            <tbody>
                                <tr key={"transaction-" + this.state.transaction_object.id}>
                                    <td width="60">
                                        <input type="text" className="form-control"
                                            value={this.state.transaction_object.productAndService?.productCode || 
                                                this.state.transaction_object.chartOfAccount?.name ||
                                                "-"}
                                            disabled />
                                    </td>
                                    <td width="90">
                                        <input type="text" className="form-control"
                                            value={this.state.transaction_object.productAndService?.chartOfAccount?.chartOfAccountCode
                                                || this.state.transaction_object.chartOfAccount?.chartOfAccountCode
                                                || "-"}
                                            disabled />
                                    </td>
                                    <td width="200">
                                        <input type="text" className="form-control"
                                            data-key="transaction_list" name="description"
                                            value={this.state.transaction_object.description.replace(/ *\[[^)]*\] */g, " ") || ""}
                                            onChange={this.handleInputChange} required />
                                    </td>

                                    <td width="50">
                                        <input type="number" min={0} className="form-control text-right"
                                            step="0.01" name="price" value={this.state.price}
                                            onChange={this.handleInputChange}
                                            required />
                                    </td>

                                    {/* Vat */}
                                    {(this.state.case_return_vat) && (
                                        <td width="10">
                                            <select
                                                className="form-control"
                                                value={this.state.vat}
                                                name="vat"
                                                disabled={this.state.case_return_vat ? "disabled" : false}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="">ไม่มี</option>
                                                <option value="0">VAT 0%</option>
                                                <option value="7">VAT 7%</option>
                                            </select>
                                        </td>
                                    )}

                                    {(this.state.case_return_vat) && (
                                        <td width="50">
                                            <input type="number" min={0} className="form-control text-right"
                                                step="0.01" name="vatAmount" value={this.state.vatAmount}
                                                onChange={this.handleInputChange}
                                                required />
                                        </td>
                                    )}


                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            <label htmlFor="note"><strong>หมายเหตุ</strong></label>
                            <textarea className="form-control" rows="5" name="remark" value={this.state.remark}
                                // required
                                onChange={this.handleInputChange} />
                        </div>
                        <div className="col offset-6 bg-light">
                            <div className="row p-3 mb-2 text-dark">
                                <div className="col text-left">{this.state.case_return_vat ? "ยอดก่อนภาษี": "ยอด"}</div>
                                <div
                                    className="col text-right"> {numberWithComma(this.state.price || 0)} บาท
                                </div>
                            </div>
                            { this.state.case_return_vat &&
                            <div className="row p-3 mb-2 text-dark">
                                <div className="col text-left">ภาษีมูลค่าเพิ่ม</div>
                                <div
                                    className="col text-right"> {numberWithComma(this.state.vatAmount || 0)} บาท
                                </div>
                            </div>
                            }
                            <div className="row p-3 mb-2 text-dark">
                                <div className="col text-left">ยอดลดหนี้</div>
                                <div
                                    className="col text-right"> {numberWithComma(this.state.total || 0)} บาท
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row mt-3 fade-up">
                        <div className="col text-right">
                            <div className="btn-group mr-2">
                                <Link to={this.props.backLink}>
                                    <button type="button"
                                        className="btn btn-secondary add">
                                        ยกเลิก
                                    </button>
                                </Link>
                            </div>
                            <div className="btn-group mr-2">
                                <button type="submit" className="btn btn-primary add"
                                    disabled={this.state.loading}>
                                    {this.state.loading &&
                                        <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                    บันทึก
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default AcceptCreditNoteCreateFormCore;
