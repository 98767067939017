import React from 'react'
import FileInput from '../../../project/projectInfo/componentProjectList/fileInput';
import _ from "lodash"
import { Translation } from 'react-i18next';
import SelectCreditor from '../../selectCreditor/selectCreditor';
import SelectCatagoryAsset from '../../selectCatagoryAsset/selectCatagoryAsset';
import SelectCatagoryServiceContact from '../../selectCatagoryServiceContact/selectCatagoryServiceContact';
import SelectPaymentTerms from '../../selectPaymentTerms/selectPaymentTerms';
import { Link } from 'react-router-dom';
import DatePickerNoti from '../../../accounting/finance/depositInterest/datePickerNoti';
import SelectBuyReplaceAssetNo from '../../selectBuyReplaceAssetNo/selectBuyReplaceAssetNo';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import "../style/manageRequest.scss"
import i18next from 'i18next';

const query = graphql`
    query formHeadCheckTypeQuery{
      allProjects {
            edges {
            node {
            periodCreate
            }
      }
    }
    }
`;
class formHeadCheckType extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        temp_month_before : '',
        temp_month_after : '',
        period_create:''
    };

    }


  componentWillMount(){
      fetchQuery(environment, query)
      .then(response_data => {
          if(response_data){
            let data = _.cloneDeep(response_data);
            this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
          }
      })
  }


  calPeriodMouth(val){
    let month_before = new Date();
    let month_after= new Date();

    month_before.setMonth(month_before.getMonth() -  parseInt(val));
    month_after.setMonth(month_after.getMonth() + parseInt(val));

    this.setState({
        temp_month_before : month_before,
        temp_month_after : month_after,
    })
  }

selectpaymentTerms = (value, name) => { 
    this.props.handleChangeInput({
      target : {
        name: name,
        value: value
      }
    })
  }

  selectAddPurchasing = (e) => {
    let {name, value } = e.target;

    this.props.handleChangeInput({
      target : {
        name: name,
        value: {title: value, detail: value === "buyMore" ? "ซื้อเพิ่ม" : ""}
      }
    })
  }

  render() { 
    return (
      <>
      <div id="formHeadCheckType">
        <div className="row">
          <div className="col-2 customDatePickerWidth">
            <h6>{i18next.t("purchaseRequisition:Date")}</h6>
            <DatePickerNoti
              disabled={true}
              selected={this.props.purchaseRequisition.creationDate}
              name='purchaseRequisition.creationDate'
              />
          </div>
          {this.props.docNumber &&
            <div className="col-2 customDatePickerWidth">
              <h6>{i18next.t("purchaseRequisition:Document No")}</h6>
              <input type="text"
                  disabled={true}
                  className="form-control"
                  value={this.props.docNumber}
                  />
            </div>
          }
        </div>

        {this.props.purchaseType === "asset" &&
          <div className="row mt-5">
            <div className="col">
              <h5>{i18next.t("purchaseRequisition:Purchased")}</h5>
              
              <div className="row">
                <div className="col-12 mt-5">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input 
                      disabled={this.props.disibleInput}
                      type="radio" className="custom-control-input"
                      id="buyMore" name="purchaseRequisition.addPurchasing"
                      value="buyMore"
                      checked={this.props.purchaseRequisition.addPurchasing.title === 'buyMore'}
                      onChange={this.selectAddPurchasing}
                    />
                    <Translation>
                      {t => <label className="custom-control-label textDetail"
                        htmlFor="buyMore">{i18next.t("purchaseRequisition:Buy more")}</label>}
                    </Translation>
                  </div>
                </div>

                <div className="col-12 mt-5 d-flex align-items-center">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input 
                      disabled={this.props.disibleInput}
                      type="radio" className="custom-control-input"
                      id="buyReplaceAssetsNo" name="purchaseRequisition.addPurchasing"
                      value="buyReplaceAssetsNo"
                      checked={this.props.purchaseRequisition.addPurchasing.title === 'buyReplaceAssetsNo'}
                      onChange={this.selectAddPurchasing}
                    />
                    <Translation>
                      {t => <label className="custom-control-label textDetail"
                        htmlFor="buyReplaceAssetsNo">{i18next.t("purchaseRequisition:Purchase to replace asset no.")}</label>}
                    </Translation>
                  </div>
                  <div className="col-2">
                    <SelectBuyReplaceAssetNo
                      handleChangeInput={this.props.handleChangeInput}
                      chartOfAccountId={
                        this.props.purchaseRequisition.addPurchasing.title === 'buyReplaceAssetsNo' 
                        ? this.props.purchaseRequisition.addPurchasing.detail : ""
                      }
                      name="purchaseRequisition.addPurchasing.detail"
                      disibleInput={this.props.purchaseRequisition.addPurchasing.title !== 'buyReplaceAssetsNo' || this.props.disibleInput}
                      required={this.props.purchaseRequisition.addPurchasing.title === 'buyReplaceAssetsNo'}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        <div className="row mt-5">
          <div className="col-4">
            <h6>{i18next.t("purchaseRequisition:Payable")} <span className="text-danger">*</span></h6>
            <SelectCreditor
              disibleInput={this.props.disibleInput}
              creditorId={this.props.purchaseRequisition.creditorId}
              handleChangeInput={this.props.handleChangeInput}
              />
          </div>

          <div className="col-2 customDatePickerWidth">
            <h6>{i18next.t("purchaseRequisition:Purchase requisition date")} <span className="text-danger">*</span></h6>
            {
                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ? 
                <DatePickerNoti
                disabled={this.props.disibleInput}
                selected={this.props.purchaseRequisition.buyDate}
                minDate={this.state.temp_month_before}
                // maxDate={this.state.temp_month_after}
                name='purchaseRequisition.buyDate'
                onChange={this.props.handleChangeInput}
                />:
                <DatePickerNoti
                disabled={this.props.disibleInput}
                selected={this.props.purchaseRequisition.buyDate}
                name='purchaseRequisition.buyDate'
                onChange={this.props.handleChangeInput}
                />
            }
           
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-4">
            <h6>{i18next.t("purchaseRequisition:Contract number / Reference document")}</h6>
            <div className="input-group">
              <input 
                disabled={this.props.disibleInput}
                type="text"
                className="form-control"
                name="purchaseRequisition.contractNumber" 
                value={this.props.purchaseRequisition.contractNumber || ""}
                onChange={this.props.handleChangeInput}
              />
            </div>
          </div>

          {this.props.purchaseRequisition.contractNumber && 
            <div className="col-2 customDatePickerWidth">
              <h6>{i18next.t("purchaseRequisition:Date")} <span className="text-danger">*</span></h6>
              <DatePickerNoti
                disabled={this.props.disibleInput}
                selected={this.props.purchaseRequisition.contractDate}
                name='purchaseRequisition.contractDate'
                onChange={this.props.handleChangeInput}
                />
            </div>
          }

          <div className="col-4">
            <h6>{i18next.t("purchaseRequisition:payment conditions")}</h6>
            <SelectPaymentTerms
              disibleInput={this.props.disibleInput}
              paymentTerms={this.props.purchaseRequisition.paymentTerms}
              otherPaymentTerms={this.props.purchaseRequisition.otherPaymentTerms}
              handleChangeInput={this.props.handleChangeInput}
              />
          </div>

          {this.props.purchaseRequisition.paymentTerms === "other" && this.props.purchaseRequisition.otherPaymentTerms !== null &&
            <div className="col-2">
              <div style={{marginTop: "30px"}}>
                <input type="text"
                      disabled={this.props.disibleInput}
                      className="form-control"
                      name="purchaseRequisition.otherPaymentTerms"
                      value={this.props.purchaseRequisition.otherPaymentTerms}
                      onChange={this.props.handleChangeInput}
                      required
                      />
              </div>
            </div>
          }
        </div>

        {this.props.purchaseType === "service_contact" &&
          <div className="row mt-5">
            <div className="col-4">
              <h6>{i18next.t("purchaseRequisition:Category")} <span className='text-danger'>*</span></h6>
              <SelectCatagoryServiceContact
                disibleInput={this.props.disibleInput}
                chartOfAccountId={this.props.purchaseRequisition.chartOfAccountId}
                handleChangeInput={this.props.handleChangeInput}
                />
            </div>
          </div>
        }

        {this.props.purchaseType === "asset" &&
          <div className="row mt-5">
            <div className="col-4">
              <h6>{i18next.t("purchaseRequisition:Category")}</h6>
              <SelectCatagoryAsset
                disibleInput={this.props.disibleInput}
                chartOfAccountId={this.props.purchaseRequisition.chartOfAccountId}
                handleChangeInput={this.props.handleChangeInput}
                />
            </div>
            
            <div className="col-4">
              <h6>{i18next.t("purchaseRequisition:Place where the property is used")}</h6>
              <input type="text"
                  disabled={this.props.disibleInput}
                  className="form-control"
                  name="purchaseRequisition.facilityUse"
                  value={this.props.purchaseRequisition.facilityUse}
                  onChange={this.props.handleChangeInput}
                  />
            </div>
          </div>
        }

        <div className="row mt-5">
          <div className="col d-flex align-items-center">
            <div className="upload-file">
              <label 
                className={(this.props.disibleInput && `${"bgOpacity"}`) + " btn btn-sm btnAttach"} 
                htmlFor="CustomFile">
                  <img className="iconAttach" src={process.env.PUBLIC_URL + "/images/icons/attachIcon.png"} alt="attachIcon" />
                  {i18next.t("purchaseRequisition:Attach file")}
              </label>
              <div className="upload-input">
                {!this.props.disibleInput &&
                  <FileInput
                    id="CustomFile"
                    appendFileInputRow={(fileInput) => this.props.appendFileInputRow("purchaseRequisition.procurementFormContract", fileInput)}
                    />
                }
                
              </div>
            </div>
            <span 
              className={ (this.props.disibleInput && `${"bgOpacity"}`) + " ml-4 text-danger"}
              >{i18next.t("purchaseRequisition:Attach document .jpg or .pdf size not over 10 MB")}</span>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-4">
            <div
              className="slide"
            >
              {_.map(this.props.purchaseRequisition.procurementFormContract, (item, index) => {
                let fileName = item.node.fileName
                let typeFileName = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length)

                return (
                  <div className="lineBottom" key={index}>
                    <div className="item">
                      <div className="detail">
                        {typeFileName === "pdf"
                          ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} alt="pdf" />
                          : typeFileName === "png"
                            ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pngIcon.png"} alt="png" />
                            : typeFileName === "jpeg" && <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/jpgIcon.png"} alt="jpeg" />
                        }
                        
                        <Link 
                          to="route"
                          onClick={(event) => { event.preventDefault(); 
                          window.open(
                            typeof item.node.fileUpload === "string"
                              ? item.node.fileUpload
                              : URL.createObjectURL(item.node.fileUpload)
                          ) }} 
                          target="blank">
                           {fileName.length >= 20 ? fileName.substr(0, 20) : fileName}
                        </Link>
                      </div>
                      {!this.props.disibleInput &&
                        <img
                          src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'}
                          alt="cancel-icon"
                          style={{ width: "10px", height: "10px" }}
                          onClick={() => 
                            this.props.handleRemoveImgSelect(
                              "purchaseRequisition.procurementFormContract", 
                              index
                            )} 
                        />
                      }
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default formHeadCheckType;
