import '../../../../../document/styles/main.scss';
import '../../../report.scss';
import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../../env/environment";
import format from 'date-fns/format';
import thLocale from "date-fns/locale/th";
import _ from 'lodash'
import { H5 } from '../../../../../components/Text/Text';
import { formatDateISO} from "../../../../../utils";
import PrintA4horizontal from './printA4horizontal';
import BankImpactReportTableTransectionPDF from './bankImpactReportTableTransectionPDF';


const query = graphql`
  query wrapperBankDepositImpactReportDetailQuery($bankAccountId:String, $startDate: Date,$endDate: Date, $search: String) {
    selfProject {
      name
      logo
    }
    allBankAccountTransactionReport(bankAccountId:$bankAccountId,startDate: $startDate,endDate:$endDate, search: $search)
  }
`;

class WrapperBankDepositImpactReportDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      all_bank: '',
      summary_balance: '',
      summary_report_residential: '',
      summary_report_supplier: '',
      summary_bank_report: '',
      project_name: '',
      project_logo: '',
      loading: false,
      startDate: "",
      endDate: "",
    };
  }
  getData = async () => {
    this.setState({ loading: true })
    await fetchQuery(environment, query, {
      bankAccountId: this.props?.match?.params?.bankAccountId,
      startDate: formatDateISO(this.props?.match?.params?.startDate),
      endDate: formatDateISO(this.props?.match?.params?.endDate)
    }).then((data) => {
      // let sum = _.sumBy(data.allBankAccountTransactionReport.edges, function (o) { return parseFloat(JSON.parse(o.node).balance) });
      let data_list = [];
       let dse = data?.allBankAccountTransactionReport?.forEach(element => data_list.push(JSON.parse(element)));
      this.setState({
        project_name: data.selfProject.name,
        project_logo: data.selfProject.logo,
        // summary_balance: sum,
        // summary_bank_report: data.summaryBankReportPurchaseRecordGroup,
        all_bank: data_list,
        startDate: format(new Date(this.props?.match?.params?.startDate), "D MMMM YYYY", { locale: thLocale, }),
        endDate: format(new Date(this.props?.match?.params?.endDate), "D MMMM YYYY", { locale: thLocale, }),
        loading: true,
      })
    });
  }

  handleSummary = (sumBalance) => (sumBalance / this.state.summary_balance) * 100
  async componentDidMount() { await this.getData() }

  print = () => {
    window.print();
  }

  render() {
    const { all_bank, project_name, project_logo,loading } = this.state
    return (
      <React.Fragment>
        <PrintA4horizontal />

        <div className="print-top-menu">
          <div className="logo"><img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" /></div>
          <div className="print" onClick={this.print}>PRINT</div>
        </div>
        <div>

          {_.chunk(all_bank, 10).map((transaction, index, array) => {
            return (
              <div className="page colorUseDoc" >
                <div className="container-fluid box p-3" id='bank-deposit-report-main' >
                  <div className="">
                    <div className="logo ml-1 " style={{ position: 'absolute' }}>{project_logo !== '' && <img src={project_logo} alt="silverman" style={{ width: '90px' }} />}</div>
                    <div className="mt-5 mb-4 ">
                      <H5 className="text-center">{project_name}</H5>
                      <H5 className="text-center">รายงานกระทบยอดเงินฝากธนาคาร</H5>
                      <H5 className="text-center">ประจำวันที่ {this.state.startDate} - {this.state.endDate}</H5>
                      <H5 className="text-center">{this.props?.match?.params?.type} {this.props?.match?.params?.namebank}</H5>
                    </div>

                    <BankImpactReportTableTransectionPDF
                      all_bank={transaction}
                      lastIndex={all_bank[0][-1]}
                      page={index + 1}
                      allPage={array.length}
                      reportType={"pdf"}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>

      </React.Fragment >
    );
  }
}

export default WrapperBankDepositImpactReportDetail;
