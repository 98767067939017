/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type waterMeterListHistoryByRoomViewQueryVariables = {|
  search?: ?string,
  year?: ?number,
  month?: ?number,
  issuedDate_Gte?: ?any,
  issuedDate_Lte?: ?any,
|};
export type waterMeterListHistoryByRoomViewQueryResponse = {|
  +allResidential: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +logWaterMeterUnit: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +meterValue: ?number,
              +creator: ?string,
              +remark: string,
              +changeWaterMeter: boolean,
              +updated: any,
              +initialChangeMeterValue: ?number,
              +editDate: ?any,
              +creatorApp: string,
              +editCreatorDate: ?any,
              +editCreatorDateApp: ?any,
              +unitUse: ?number,
              +logWaterMeterImage: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +fileName: ?string,
                    +fileUpload: ?string,
                  |}
                |}>
              |},
              +logWaterMeterTag: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +name: ?string
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type waterMeterListHistoryByRoomViewQuery = {|
  variables: waterMeterListHistoryByRoomViewQueryVariables,
  response: waterMeterListHistoryByRoomViewQueryResponse,
|};
*/


/*
query waterMeterListHistoryByRoomViewQuery(
  $search: String
  $year: Float
  $month: Float
  $issuedDate_Gte: Date
  $issuedDate_Lte: Date
) {
  allResidential(search: $search) {
    edges {
      node {
        id
        name
        logWaterMeterUnit(year: $year, month: $month, issuedDate_Gte: $issuedDate_Gte, issuedDate_Lte: $issuedDate_Lte) {
          edges {
            node {
              id
              meterValue
              creator
              remark
              changeWaterMeter
              updated
              initialChangeMeterValue
              editDate
              creatorApp
              editCreatorDate
              editCreatorDateApp
              unitUse
              logWaterMeterImage {
                edges {
                  node {
                    id
                    fileName
                    fileUpload
                  }
                }
              }
              logWaterMeterTag {
                edges {
                  node {
                    name
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issuedDate_Gte"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issuedDate_Lte"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "month"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v5 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "issuedDate_Gte",
    "variableName": "issuedDate_Gte"
  },
  {
    "kind": "Variable",
    "name": "issuedDate_Lte",
    "variableName": "issuedDate_Lte"
  },
  {
    "kind": "Variable",
    "name": "month",
    "variableName": "month"
  },
  {
    "kind": "Variable",
    "name": "year",
    "variableName": "year"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "meterValue",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "changeWaterMeter",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initialChangeMeterValue",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editDate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorApp",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editCreatorDate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editCreatorDateApp",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitUse",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "LogWaterMeterImageNodeConnection",
  "kind": "LinkedField",
  "name": "logWaterMeterImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LogWaterMeterImageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LogWaterMeterImageNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileUpload",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "waterMeterListHistoryByRoomViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ResidentialNodeConnection",
        "kind": "LinkedField",
        "name": "allResidential",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "LogWaterMeterUnitNodeConnection",
                    "kind": "LinkedField",
                    "name": "logWaterMeterUnit",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LogWaterMeterUnitNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LogWaterMeterUnitNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v20/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LogWaterMeterTagNodeConnection",
                                "kind": "LinkedField",
                                "name": "logWaterMeterTag",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LogWaterMeterTagNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "LogWaterMeterTagNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v7/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "waterMeterListHistoryByRoomViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ResidentialNodeConnection",
        "kind": "LinkedField",
        "name": "allResidential",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "LogWaterMeterUnitNodeConnection",
                    "kind": "LinkedField",
                    "name": "logWaterMeterUnit",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LogWaterMeterUnitNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LogWaterMeterUnitNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v20/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LogWaterMeterTagNodeConnection",
                                "kind": "LinkedField",
                                "name": "logWaterMeterTag",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "LogWaterMeterTagNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "LogWaterMeterTagNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v7/*: any*/),
                                          (v6/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "97223d7b60169c312842b4c057abe47e",
    "id": null,
    "metadata": {},
    "name": "waterMeterListHistoryByRoomViewQuery",
    "operationKind": "query",
    "text": "query waterMeterListHistoryByRoomViewQuery(\n  $search: String\n  $year: Float\n  $month: Float\n  $issuedDate_Gte: Date\n  $issuedDate_Lte: Date\n) {\n  allResidential(search: $search) {\n    edges {\n      node {\n        id\n        name\n        logWaterMeterUnit(year: $year, month: $month, issuedDate_Gte: $issuedDate_Gte, issuedDate_Lte: $issuedDate_Lte) {\n          edges {\n            node {\n              id\n              meterValue\n              creator\n              remark\n              changeWaterMeter\n              updated\n              initialChangeMeterValue\n              editDate\n              creatorApp\n              editCreatorDate\n              editCreatorDateApp\n              unitUse\n              logWaterMeterImage {\n                edges {\n                  node {\n                    id\n                    fileName\n                    fileUpload\n                  }\n                }\n              }\n              logWaterMeterTag {\n                edges {\n                  node {\n                    name\n                    id\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '09d5e69c1d23bf8b6578d94bcb408ac4';

module.exports = node;
