// import React, { Component } from 'react';
import React, { useState, useEffect } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from 'react-relay';
import environment from "../../env/environment";
import i18n from 'i18next'
import _ from 'lodash';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
const query = graphql`
    query changeProjectQuery($typeOfProject: String, $search: String, $order: String) {
        userConfig {
            id languages
        }
        selfProject {
            id name logo slug nameEn
        }
        allSelectJuristic(typeOfProject: $typeOfProject, search: $search, order:$order){
            edges{
                node{
                    id
                    userOrder{
                        id
                        username
                        lastLogin
                    }
                    site{
                        id
                        domain
                        siteselectProjectManager{
                          edges{
                            node{
                              onlineSite
                              onlineApp
                            }
                          }
                        }
                        project{
                            edges{
                                node{
                                    id
                                    nameTh
                                    nameEn
                                    typeOfProject
                                    logo
                                    added
                                }
                            }
                        }

                    }
                    
                }
            }
                    
        }
    }
`;
const ChangeProject = () => {
  const [langauge, setLangauge] = useState(localStorage.getItem("language") ?? 'th')
  const [selectedProject, setSelectedProject] = useState(null);
  const [data, setData] = useState([])
  const [error] = useState(false)
  const [projectsView, setProjectView] = useState([])
  const [search, setSearch] = useState("")

  useEffect(() => {
    getData()
  }, []);

  const getData = () => {
    fetchQuery(environment, query, {
      "typeOfProject": "",
      "search": "",
      "order": "online"
    }).then((res) => {
      if (res?.allSelectJuristic) {
        setData(res.allSelectJuristic.edges)
        setProjectView(res.allSelectJuristic.edges)
        setSelectedProject(res.selfProject)
        setLangauge(res.userConfig.languages)
      }
    }).catch((err) => {
      setData([])
      setProjectView([])
      setSelectedProject(null)
      setLangauge(langauge)
    })
  }


  const goToSite = async (siteName, userOrder) => {
    let siteDev = window.location.host.includes("port80")
    let hotname = "http://" + window.location.host
    let domain = siteName.domain.split(".")
    let checkSite = siteDev ? 'http://' + window.location.host + '/changeSite' : `https://${domain[0]}-admin.${domain[1]}.${domain[2]}/changeSite`
    let checkLocal = siteName.id === "331" && siteDev ? hotname + "/changeSite" : siteName.id === "163" && siteDev ? hotname + "/changeSite" : checkSite

    window.location.href = checkLocal + `/${userOrder}/${siteName.domain}/${localStorage.getItem("token")}`

  }

  const searchDataProjectName = (event) => {
    let eveClo = _.cloneDeep(event)
    let { value } = eveClo.target
    let valLower = value.toLowerCase()
    setSearch(valLower)
    let name = langauge === "th" ? `nameTh` : `nameEn`
    let findSearchData = data.filter(s => s.node.site?.project.edges[0].node[name].toLowerCase().includes(valLower))
    setProjectView(findSearchData)
  }


  if (error) {
    // Handle error state here
    return <div>Error fetching data</div>;
  }
  return (
    <Translation>
      {(t) =>
        selectedProject && (
          <div className="select-project dropdown">
            <button
              className="btn dropdown-toggle text-capitalize"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={selectedProject.logo} alt="project" className="logo" />
              {i18n.languages[0] === 'th' ? selectedProject.name : selectedProject.nameEn}
              <img
                src={`${process.env.PUBLIC_URL}/images/header/dropdown.png`}
                alt="dropdown"
                className="arrow"
                srcSet={`${process.env.PUBLIC_URL}/images/header/dropdown@2x.png 2x`}
              />
            </button>
            <div className={`dropdown-menu w-auto colorUse border p-0`} aria-labelledby="dropdownMenuButton" style={{ minWidth: 500 }} >
              {
                projectsView && localStorage.getItem("selPr") === "true" &&
                <div className='col-12 p-2 pr-4 m-0'>
                  <input placeholder={t("selectProject:Search by project name")}
                    type={"text"} name="search" className='h48 mr-1 ml-1 form-control h48'
                    defaultValue={search} onKeyUp={(e) => searchDataProjectName(e)} />
                  <div style={{ position: 'absolute', top: '6px', right: '18px' }}>
                    <label className='btn' style={{ margin: '15px 10px 0 20px' }}>
                      <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'}
                        className="search-outline" alt="search" />
                    </label>
                  </div>
                </div>
              }
              <div style={{ maxHeight: 223, overflow: 'scroll', overflowX: 'hidden' }}>
                {projectsView && localStorage.getItem("selPr") === "true" ?
                  projectsView?.map((itemProjectList, inx) => {
                    let checkOnline = itemProjectList.node.site?.siteselectProjectManager.edges[0]?.node.onlineSite
                    return (
                      <div
                        className={`cursor dropdown-item text-capitalize m-0 pt-3 pb-3 p-3 border-bottom border 
                        ${itemProjectList.node.site.project.edges[0].node.nameTh === selectedProject.name ? 'bg-e9ecef text_808080' : ''}`} key={"itemProjectList" + inx}
                        onClick={() => checkOnline ? goToSite(itemProjectList.node.site, itemProjectList.node.userOrder.username) : null}>
                        <img src={itemProjectList.node.site.project.edges[0].node.logo}
                          onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = "/images/icons/defualt.png"; }}
                          alt="project" className="logo w30 h30" />
                        {i18n.languages[0] === 'th'
                          ? itemProjectList.node.site.project.edges[0].node.nameTh
                          : itemProjectList.node.site.project.edges[0].node.nameEn}
                        {
                          !checkOnline && <label className='text-red'>&nbsp;- {t("selectProject:Project suspended")}</label>
                        }
                      </div>
                    )
                  })


                  :
                  <div className="cursor dropdown-item text-capitalize m-0 pt-4 pb-4 p-4 border-bottom border">

                    <img src={selectedProject.logo} alt="project" className="logo h40" />
                    {i18n.languages[0] === 'th'
                      ? selectedProject.name
                      : selectedProject.nameEn}

                    {selectedProject.name || selectedProject.nameEn ? (
                      <img
                        src={`${process.env.PUBLIC_URL}/images/header/selected.png`}
                        alt="selected"
                        className="selected ml-2"
                        srcSet={`${process.env.PUBLIC_URL}/images/header/selected@2x.png 2x`}
                      />
                    ) : null}
                  </div>
                }
              </div>

              {
                projectsView && localStorage.getItem("selPr") === "true" &&
                <Link to={"/select-project"}>
                  <div className="cursor dropdown-item text-capitalize m-0 pt-4 pb-4 p-2 border-bottom bg-E6F2FE text_1567B4 border">
                    {t("selectProject:Back to select project page.")} ({t("selectProject:Amount")} {data.length} {t("selectProject:Projects")})
                  </div>
                </Link>

              }


            </div>
          </div>
        )


      }
    </Translation>
  );
};

export default ChangeProject;