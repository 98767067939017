import React from 'react'
import { Link } from 'react-router-dom'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import _ from "lodash"
import { format } from 'date-fns';
import ComponentPagination from "../../libs/componentPagination";
import Pagination from "../../libs/newPagination";
import Loading from "../../libs/loading"
import reportProductTransaction from "../query/reportProductTransaction"
import { Translation } from "react-i18next";
import "./style/reportStock.scss"
import i18n from "i18next";

const query = graphql`
  query reportStockTableQuery($first: Int, $last: Int, $search: String){
    reportProduct(type_In: "product", noNumber: true,  first: $first, last: $last, search: $search){
      edges{
        node{
          id
          productCode
          name
          updated
          reorderPoint
          stock {
            edges {
              node {
                id
                cost
                leftInStock
                added
                transaction{
                    date
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`

export default class reportStockTable extends ComponentPagination {

  constructor(props) {
    super(props)

    this.state = {
      productList: [],
      loading: false,
      textSearch: "",
      totalCount: 0
    }
  }

  componentWillMount() {
    this.goFirst()
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.setState({ loading: true })
    fetchQuery(
      environment,
      query,
      {
        first: this.state.first,
        last: this.state.last,
        search: this.state.textSearch
      }
    ).then(async (res) => {
      let newAllProduct = []

      for (const item of res.reportProduct.edges) {
        let updated = await this.getDateUpdate(item.node.id)

        newAllProduct.push({
          node: {
            id: item.node.id,
            name: item.node.name,
            productCode: item.node.productCode,
            reorderPoint: item.node.reorderPoint,
            updated: updated || item.node.updated,
            stock: item.node.stock
          }
        })
      }

      this.setState({
        productList: newAllProduct,
        loading: false,
        totalCount: res.reportProduct.totalCount,
      })
    })
  }

  getDateUpdate = async (productAndServiceIds) => {
    let updated = ""
    await fetchQuery(environment, reportProductTransaction, { productAndServiceId: productAndServiceIds })
      .then((res) => {
        if (res.reportProductTransaction.edges.length > 0) {
          let dateLast = _.findLast(res.reportProductTransaction.edges, (o) => o.node.date);
          updated = dateLast?.node?.date
        }
      })

    return updated
  }

  getStatusStock = (totalStock, reorderPoint) => {
    let sumResultInput = 0
    let resultText = ""
    let bgColor = ""
    sumResultInput = totalStock - reorderPoint

    if (sumResultInput > 0) {
      resultText = i18n.t("PageList:Ready")
      bgColor = "statusGreen"
    } else if (sumResultInput < 0) {
      resultText = i18n.t("PageList:Limit Order")
      bgColor = "statusRed"
    } else if (sumResultInput === 0) {
      resultText = i18n.t("PageList:Reached")
      bgColor = "statusYellow"
    }

    return (<div className={`${bgColor}`} style={{ width: 180 }}>
      <span>{resultText}</span>
    </div>)
  }

  getStatusStockLink = (totalStock, reorderPoint) => {
    let sumResultInput = 0
    let resultText = ""
    sumResultInput = totalStock - reorderPoint

    if (sumResultInput > 0) {
      resultText = "ready"
    } else if (sumResultInput < 0) {
      resultText = "almost"
    } else if (sumResultInput === 0) {
      resultText = "reachedPoint"
    }

    return resultText
  }

  handleSearch = () => {
    this.getData()
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.getData()
    }
  }

  render() {
    return (
      <Translation>
        {
          t =>
            <div id="reportStockTable">
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <div className="calendars-wrapper">
                    <form>
                      <input
                        type="text"
                        placeholder={t("PageList:Search")}
                        value={this.state.textSearch}
                        onChange={(event) => this.setState({ textSearch: event.target.value })}
                        onKeyDown={this.handleKeyDown}
                        className="form-control inputSearch"
                      />
                    </form>
                    <div
                      className="calendars float-right"
                      style={{ paddingRight: 25 }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/search-outline.png"
                        }
                        alt="calendars"
                        style={{ width: "17.81px", height: "17.81px" }}
                        onClick={this.handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {this.state.loading ? <Loading />
                : <React.Fragment>
                  <div className="row mt-5">
                    <div className="col">
                      <div className="fade-up">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead className="thead-light">
                              <tr>
                                <th className="text-center">{t("PageList:Code")}</th>
                                <th>{t("PageList:Detail")}</th>
                                <th className="text-center">{t("PageList:Last Movement Date")}</th>
                                <th className="text-right">{t("PageList:Balance")}</th>
                                <th className="text-center">{t("PageList:Status")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {_.map(this.state.productList, (item, index) => {
                                let total_stock = 0;

                                if (item.node.stock.edges.length > 0) {
                                  item.node.stock.edges.forEach((stock, t_index) => {
                                    total_stock = total_stock + stock.node.leftInStock;
                                  }
                                  )
                                }

                                return (
                                  <tr key={index}>
                                    <td className="text-center">
                                      <Link to={`/stockandsupplies/reportStock/reportStockDetail/${item.node.id}/${this.getStatusStockLink(total_stock, item.node.reorderPoint)}/all`}>
                                        {item.node.productCode}
                                      </Link>
                                    </td>
                                    <td>{item.node.name}</td>
                                    <td className="text-center">{format(item.node.updated, "DD/MM/YYYY")}</td>
                                    <td className="text-right">{total_stock}</td>
                                    <td className="d-flex justify-content-center">{this.getStatusStock(total_stock, item.node.reorderPoint)}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <Pagination
                      changePage={this.changePage}
                      first={this.state.first}
                      last={this.state.last}
                      totalCount={this.state.totalCount}
                    />
                  </div>
                </React.Fragment>
              }

              <div className="row mt-5">
                <div className="col-12">
                  <h4 className="textWarnRunNumber">**{t("PageList:Auto run number")}<br />  {t("PageList:That Product Code in this page")}</h4>
                </div>
              </div>
            </div>
        }
      </Translation>
    )
  }
}
