import axios from "axios";
import getApiRoot from "../../libs/getAPIRoot";
// let host = window.location.hostname;

const facility = (token, status, time_period, index_first, index_last, service_charge, search, start_date, end_date) => {
  let header = {};
  if (token) {
    header = {
      authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    };
  }

  return axios.get(`${getApiRoot()}booking_facility/list_booking`, {
    headers: header,
    params: {
      status: status,
      time_period: time_period,
      index_first: index_first,
      index_last: index_last,
      service_charge: service_charge,
      search: search,
      start_date: start_date,
      end_date: end_date
    },
  });
};

const all_facility = (token, status, search, time_period, index_first, index_last) => {
  let header = {};
  if (token) {
    header = {
      authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    };
  }

  return axios.get(`${getApiRoot()}booking_facility/all_facility`, {
    headers: header,
    params: {
      status: status,
      search: search,
      time_period: time_period,
      index_first: index_first,
      index_last: index_last
    },
  });
}

const one_niti_booking = (token, booking_id) => {
  let header = {};
  if (token) {
    header = {
      authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    };
  }

  return axios.get(`${getApiRoot()}booking_facility/one_niti_booking`, {
    headers: header,
    params: {
      booking_id: booking_id,
    },
  });
}

const niti_cancel_booking = (token, booking_id, void_remark) => {
  let header = {};
  if (token) {
    header = {
      authorization: `JWT ${token}`,
      "content-Type": "application/json",
    };
  }

  const data = new FormData()
  data.append('void_remark', void_remark)

  return axios.post(`${getApiRoot()}booking_facility/niti_cancel_booking?booking_id=${booking_id}`,data ,{
    headers: header
  });
}

const month_booking = (token, month, year, search, service_charge, facility_id) => {
  let header = {};
  if (token) {
    header = {
      authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    };
  }

  return axios.get(`${getApiRoot()}booking_facility/month_booking`, {
    headers: header,
    params: {
      month: month,
      year: year,
      search: search,
      service_charge: service_charge,
      facility_id: facility_id
    },
  });
}

const booking_facility = (token, facility_id) => {
  let header = {};
  if (token) {
    header = {
      authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    };
  }

  return axios.get(`${getApiRoot()}booking_facility/booking_facility`, {
    headers: header,
    params: {
      facility_id: facility_id
    },
  });
}

const create_booking_facility = (token, start_time, end_time, residential_id, type_user, use_right, booking_people, total_price, booking_name, booking_tel, booking_remark, facility_id) => {
  let header = {};
  if (token) {
    header = {
      authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    };
  }

  const data = new FormData()
  data.append('start_time', start_time)
  data.append('end_time', end_time)
  data.append('residential_id', residential_id)
  data.append('type_user', type_user)
  data.append('use_right', use_right)
  data.append('booking_people', booking_people)
  data.append('total_price', total_price)
  data.append('booking_name', booking_name)
  data.append('booking_tel', booking_tel)
  data.append('booking_remark', booking_remark)


  return axios.post(`${getApiRoot()}booking_facility/booking_facility?facility_id=${facility_id}`,data, {
    headers: header
  });
}

const day_slot = (token, day_slot, service_charge, facility_id, search) => {
  let header = {};
  if (token) {
    header = {
      authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    };
  }

  return axios.get(`${getApiRoot()}booking_facility/day_booking`, {
    headers: header,
    params: {
      day_slot: day_slot,
      service_charge: service_charge,
      facility_id: facility_id,
      search: search
    },
  });
}

export const bookingService = {
  facility,
  all_facility,
  one_niti_booking,
  niti_cancel_booking,
  month_booking,
  booking_facility,
  create_booking_facility,
  day_slot
};
