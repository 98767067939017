/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateChangeWaterMeterInput = {|
  residential?: ?string,
  year?: ?string,
  month?: ?string,
  unit?: ?number,
  initialUnit?: ?number,
  remark?: ?string,
  editDate?: ?any,
  clientMutationId?: ?string,
|};
export type waterMeterCreateHistoryMutationVariables = {|
  input: CreateChangeWaterMeterInput
|};
export type waterMeterCreateHistoryMutationResponse = {|
  +createChangeWaterMeter: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type waterMeterCreateHistoryMutation = {|
  variables: waterMeterCreateHistoryMutationVariables,
  response: waterMeterCreateHistoryMutationResponse,
|};
*/


/*
mutation waterMeterCreateHistoryMutation(
  $input: CreateChangeWaterMeterInput!
) {
  createChangeWaterMeter(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateChangeWaterMeterPayload",
    "kind": "LinkedField",
    "name": "createChangeWaterMeter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "waterMeterCreateHistoryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "waterMeterCreateHistoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f8be8eac90992fd976e6de787559a5b5",
    "id": null,
    "metadata": {},
    "name": "waterMeterCreateHistoryMutation",
    "operationKind": "mutation",
    "text": "mutation waterMeterCreateHistoryMutation(\n  $input: CreateChangeWaterMeterInput!\n) {\n  createChangeWaterMeter(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cedf04a813db8c171e632e6b45b61dd5';

module.exports = node;
