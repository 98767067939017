import React, { Component } from 'react';
import "./style.scss";
import Header from '../components/header/';
import Sidebar from '../components/sidebar/';
import Wrapper from '../components/wrapper/';
import WrapperContent from '../components/wrapper/wrapperContent';
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import IncomeGraph from "./incomeGraph";
import ParcelReport from "./parcelReport";
import PieChartMultiColor from "./pieChartMultiColor";
import OwnerChange from "./ownerChange";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../env/environment";
import { fetchQuery } from "relay-runtime";
import Loading from "../libs/loading";
import AlertWithholdPage from '../libs/alertWithholdPage';
import PopupBanner from './popupBanner'
// import CollapsibleTable from './collapsibleTable';
// SVM
// import ProjectTypePieChart from './analyticChart/projectTypesPieChart';
// import ProjetUnitRange from './analyticChart/projectUnitRangeBarChart';
// import ProjectByBrandBarChart from './analyticChart/projectByBrandBarChart';
// import UnitStatusPieChart from './analyticChart/unitStatusPieChart';
// import UnitTypeForRentPieChart from './analyticChart/unitTypeForRentPieChart';
// import ResidentSexPieChart from './analyticChart/ResidentSexPieChart';
// import ResidentAgePieChart from './analyticChart/ResidentAgePieChart';
import './analyticChart/graph.scss';


const query = graphql`
    query dashboardQuery {
        countByCountries(countries:"TH")
        countAllCountries:countByCountries(allCountries:true)
        countResidentialByType(residentialType:"live")
        countForRent:countResidentialByType(residentialType:"for_rent")
        countSeizure:countResidentialByType(residentialType:"seizure")
        countAvailability:countResidentialByType(residentialType:"availability")
        countOther:countResidentialByType(residentialType:"other")
        checkOnline
    }
`;


let data = "";

let data_residential_type = "";

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            data_residential_type: [],
            loading: true,
            checkOnline: false
        }
    }

    componentWillMount() {
        this.getData()
    }

    async getData(){
        data = [
            ["ประเภท", "ค่า"],
        ];

        data_residential_type = [
            ["ประเภท", "ค่า"],
        ]; 
        await fetchQuery(environment, query).then(response_data => {
            if (response_data.countByCountries) {
                data.push(["ชาวไทย", response_data.countByCountries])
            }

            if (response_data.countAllCountries) {
                data.push(["ชาวต่างชาติ", response_data.countAllCountries])
            }

            if (response_data.countResidentialByType) {
                data_residential_type.push(["อยู่อาศัยเอง", response_data.countResidentialByType])
            }

            if (response_data.countForRent) {
                data_residential_type.push(["ปล่อยเช่า", response_data.countForRent])
            }
            if (response_data.countAvailability) {
                data_residential_type.push(["ห้องว่าง", response_data.countAvailability])
            }
            if (response_data.countSeizure) {
                data_residential_type.push(["ถูกดำเนินการตามกฎหมาย", response_data.countSeizure])
            }
            if (response_data.countOther) {
                data_residential_type.push(["อื่นๆ", response_data.countOther])
            }


            this.setState({ data: data, data_residential_type: data_residential_type, loading: false , checkOnline : response_data.checkOnline })

        });
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    {
                        this.state.loading == false && this.state.checkOnline == false ?
                            <AlertWithholdPage/> :
                        this.state.loading == false && this.state.checkOnline == true?
                            <div className="container-fluid box bg-gray" id="dashboard">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/accounting">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>
                                            <Translation>
                                                {
                                                    t => <span>{t('page:dashboard')}</span>
                                                }
                                            </Translation>
                                        </h3>
                                    </div>
                                </div>

                                <div className="content-inner fade-up">
                                    <div className="row same-height">                                        
                                        {/* Analytic for SVM*/}
                                        {/*
                                        <div className="col-xl-12 mb-4">
                                            <div className="card p-4">
                                                Analytic SVM
                                            </div>
                                            <div className="card p-4">
                                                <ProjectTypePieChart />
                                            </div>
                                            <div className="card p-4">
                                                <ProjetUnitRange />
                                            </div>
                                            <div className="card p-4">
                                                <ProjectByBrandBarChart />
                                            </div>
                                            <div className="card p-4">
                                               <UnitStatusPieChart />
                                            </div>
                                            <div className="card p-4">
                                                <UnitTypeForRentPieChart />
                                            </div>
                                            <div className="card p-4">
                                                Resident's Nationality
                                            </div>
                                            <div className="card p-4">
                                                <ResidentSexPieChart />
                                            </div>
                                            {/* <div className="card p-4">
                                                <ResidentAgePieChart />
                                            </div> 
                                        </div> */}
                                        
                                        <div className="col-xl-8 mb-4">
                                            <div className="card p-4">
                                                <IncomeGraph />
                                            </div> 
                                        </div>
                                        <div className='popbanner'>
                                            <PopupBanner />
                                        </div>
                                        <div className="col-xl-4 mb-4">
                                            <div className="card p-4">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Translation>
                                                            {
                                                                t => <h6>{t('dashboard:Parcel')}</h6>
                                                            }
                                                        </Translation>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link to="/contact/parcel/not_receive">
                                                            <Translation>
                                                                {
                                                                    t => <span className="float-right">{t('dashboard:View all')}</span >
                                                                }
                                                            </Translation>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <ParcelReport />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row same-height">
                                        <div className="col-xl-4 mb-4">
                                            <div className="card p-4">
                                                <Translation>
                                                    {
                                                        t => <h6>{t('dashboard:Units and Residents')}</h6>
                                                    }
                                                </Translation>
                                                <PieChartMultiColor data={this.state.data_residential_type} />
                                            </div>
                                        </div>
                                        <div className="col-xl-4 mb-4">
                                            <div className="card p-4">
                                                <Translation>
                                                    {
                                                        t => <h6>{t('dashboard:Ownership Ratio')}</h6>
                                                    }
                                                </Translation>
                                                <PieChartMultiColor data={this.state.data} />
                                            </div>
                                        </div>
                                        <div className="col-xl-4 mb-4">
                                            <div className="card p-4">
                                                <div className="row pb-3">
                                                    <div className="col-8">
                                                        <Translation>
                                                            {
                                                                t => <h6>{t('dashboard:Change of Ownership')}</h6>
                                                            }
                                                        </Translation>
                                                    </div>
                                                    <div className="col-4">
                                                        <Link to="/register/ownership/list">
                                                            <Translation>
                                                                {
                                                                    t => <span className="float-right">{t('dashboard:View all')}</span >
                                                                }
                                                            </Translation>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <OwnerChange />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            :
                            <Loading />
                    }
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default Dashboard;
