import React, { Component } from 'react'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import SearchSelect from '../../libs/searchSelect';
import _ from "lodash"

const query = graphql`
  query selectCatagoryAssetListQuery{
    viewer {
      allChartOfAccount(codeList: ["1210"]) {
          edges {
              node {
                  id chartOfAccountCode name
              }
          }
      }
    }
  }
` 

const customStyles = {
  control: (style, { isDisabled }) => ({
    ...style,
    height: 48,
    minHeight: 48,
    backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF'
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none'
  }),
};

export default class selectCatagoryAsset extends Component {

  constructor(props){
    super(props)

    this.state = {
      selectData: [],
    }
  }

  componentWillMount(){
    this.getData()
  }

  getData = () => {

    fetchQuery(environment, query).then((response) => {
      let textInput = []

      _.map(response.viewer.allChartOfAccount.edges, (item) => {
        textInput.push(item)
      })

      this.setState({selectData: textInput})
    })

  }

  render() {
    return (
      <SearchSelect 
        onChange={this.props.handleChangeInput} 
        disabled={this.props.disibleInput}
        value={this.props.chartOfAccountId}
        name={"purchaseRequisition.chartOfAccountId"} 
        placeholder="รายการ"
        queryObject={this.state.selectData}
        keyOfValue="id"
        keyOfLabel="name"
        style={customStyles}
        />
    )
  }
}
