
import React, { Component } from 'react';
import './style/chequeHolder.scss';


export default class chequeHolderName extends Component {

    constructor(props) {
        super(props);
        const fullName = this.props.payerName.split(' ');
        this.state = {
              inputFirstName: fullName[0],
              inputLastName: fullName[1]
            };
 
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
      }

    handleFirstNameChange(e){
        this.setState({ inputFirstName: e.target.value });
    }
    handleLastNameChange(e){
        this.setState({ inputLastName: e.target.value });
    }


    handleAddClick = () => {
        this.props.onAddFirstName(this.state.inputFirstName);
        this.props.onAddLastName(this.state.inputLastName);
        this.handleCloseForm();
      };

    handleCloseForm = () => {
        this.props.onHide()
    }

    render(){
        return (
            <div className='from-holder-container'>
                <h4>แก้ไขชื่อ</h4>
                    <form>
                        <label>ชื่อ</label>
                        <input className='form-control' type="text" value={this.state.inputFirstName} onChange={this.handleFirstNameChange}/>
                        <label>สกุล</label>
                        <input className='form-control' type="text" value={this.state.inputLastName} onChange={this.handleLastNameChange}/>
                    </form>
                <div className='btn'>
                    <button className="btn btn-secondary add cancel-btn" onClick={this.handleCloseForm} >ยกเลิก</button>
                    <button className="btn btn-primary add" onClick={this.handleAddClick} >พิมพ์เช็ค</button>
                </div>
            </div>
        )
    }
}