import React from "react";
import localStorage from "../../../../libs/localstorage";
import numberWithComma from "../../../../libs/numberWithComma";
import { format, differenceInCalendarDays } from "date-fns";
import Link from "react-router-dom/es/Link";


class PayableOutstandingReportTable extends React.Component {

    getAmountOwed(purchase) {
        var _this = this
        const sumPayRecord = purchase.node.payRecord.edges.reduce((total, obj) => {
            if (differenceInCalendarDays(_this.props.state.end_date, obj.node.payRecordGroup.issuedDate) >= 0) {
                return total + (obj.node.amount + obj.node.whtAmount)
            } else {
                return total
            }
        }, 0);

        const sumPrepaidDeposit = purchase.node.prepaidDepositTransactionUsed.edges.reduce((total, obj) => {
            return total + obj.node.amount
        }, 0);

        const sumAcceptCreditNote = purchase.node.acceptCreditNoteTransaction.edges.reduce((total, obj) => {
            if (obj) {
                if (differenceInCalendarDays(_this.props.state.end_date, obj.node.issuedDate) >= 0) {
                    return total + obj.node.total
                } else {
                    return total
                }
            }
        }, 0)
        return parseFloat(purchase.node.total - sumPayRecord - sumPrepaidDeposit - sumAcceptCreditNote)
    }

    getNumberDay(purchase) {
        if (differenceInCalendarDays(this.props.state.end_date, purchase.node.dueDate) < 0) {
            return 0
        } else {
            return differenceInCalendarDays(this.props.state.end_date, purchase.node.dueDate)
        }
    }

    between(value, min, max) {
        return value >= min && value <= max;
    }

    render() {
        let sum_totals = [0, 0, 0, 0, 0, 0, 0, 0];
        let sum_total_contact = 0;
        let nameAndDataOutstanding = this.props.props.reportOutstandingPayableSummary.edges;
        let outstandingBalanceOfSupplier = 0;

        // ใช้ในกรณีที่ ลูกหนี้เป็นรายการสุดท้ายที่อยู่ในหน้าที่ render มา
        let outstandingBalanceForThelastLine = 0;
        return (
            <React.Fragment>
                {this.props.props.allPurchaseRecordGroup.edges.map((purchase, index) => {
                    
                    let order_id = this.props.state.first - localStorage.getPageLimit();
                    let first = true
                        // หา outstading balance supplier โดยการเช็คว่า supplier รายการล่าสุดกับรายการถัดไปเป็นตัวเดียวกันมั้ย
                        if(purchase.node.contact.id !== this.props.props.allPurchaseRecordGroup.edges[index +1]?.node.contact.id){
                            nameAndDataOutstanding.map((data)=>{
                                if(data.node.refNumber === purchase.node.contact.refNumber){
                                    outstandingBalanceOfSupplier = data.node.outstandingBalance;
                                    return outstandingBalanceOfSupplier
                                }
                                }
                            )
                        } else {
                            outstandingBalanceOfSupplier = 0;
                        }     


                    return (
                        purchase.node.purchaseRecord.edges.map((transaction, t_index) => {
                            const amountOwed = this.getAmountOwed(transaction)
                            const amountOwedWithComma = numberWithComma(amountOwed, "")
                            if (this.props.state.product_and_service) {
                                if (amountOwed !== 0) {
                                    let row_number = (first && order_id + index + 1);
                                    first = false

                                    let total_contact = 0
                                    sum_total_contact += amountOwed
                                    // หา outstading balance supplier โดยการเช็คว่า supplier รายการล่าสุดกับรายการถัดไปเป็นตัวเดียวกันมั้ย
                                    if(purchase.node.contact.id !== this.props.props.allPurchaseRecordGroup.edges[index +1]?.node.contact.id 
                                            && this.props.props.allPurchaseRecordGroup.edges[index+1]
                                        ){
                                            outstandingBalanceForThelastLine = 0;
                                    } else {
                                            outstandingBalanceForThelastLine += amountOwed
                                    }
                                    
                                    if (!purchase.node.purchaseRecord.edges[t_index + 1]) {
                                        total_contact = sum_total_contact
                                        sum_total_contact = 0
                                    }

                                    if (this.getNumberDay(purchase) < 1) {
                                        sum_totals[0] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 1, 30)) {
                                        sum_totals[1] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 31, 60)) {
                                        sum_totals[2] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 61, 90)) {
                                        sum_totals[3] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 91, 120)) {
                                        sum_totals[4] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 121, 150)) {
                                        sum_totals[5] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 151, 180)) {
                                        sum_totals[6] += amountOwed
                                    } else {
                                        sum_totals[7] += amountOwed
                                    }

                                    return (
                                        (
                                            <tr key={transaction.node.id}>
                                                <td className="text-center">{row_number}</td>
                                                <td>{purchase.node.contact.refNumber}</td>
                                                <td>{purchase.node.contact.name}</td>
                                                <td className="text-center">
                                                <Link to={"/accounting/expense/purchase/purchase-detail/" + purchase.node.id}
                                                    target={"_blank"}>
                                                    {purchase.node.docNumber}
                                                </Link>
                                                </td>
                                                <td>{transaction.node.description}</td>
                                                <td className="text-center">{format(purchase.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                <td className="text-center">{format(purchase.node.dueDate, 'DD/MM/YYYY')}</td>
                                                <td className="text-right">{this.getNumberDay(purchase)}</td>
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "not_overdue") &&
                                                    <td className="text-right">
                                                        {
                                                            this.getNumberDay(purchase) < 1 &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "1-30") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 1, 30) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "31-60") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 31, 60) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "61-90") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 61, 90) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "91-120") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 91, 120) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "121-150") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 121, 150) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "151-180") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 151, 180) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "over180") &&
                                                    <td className="text-right">
                                                        {
                                                            this.getNumberDay(purchase) > 180 &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                <td className="text-right">{numberWithComma(total_contact, "")}</td>
                                                {!purchase.node.purchaseRecord.edges[t_index + 1]?
                                                        this.props.props.allPurchaseRecordGroup.edges[index+1] ? 
                                                        <td className="text-right">{numberWithComma(outstandingBalanceOfSupplier, "")}</td>
                                                        :
                                                        <td className="text-right">{numberWithComma(outstandingBalanceForThelastLine, "")}</td>
                                                : <td>{" "}</td>
                                                }       
                                            </tr>
                                        )
                                    )

                                }
                            } else {
                                if (amountOwed !== 0) {
                                    let row_number = (first && order_id + index + 1);
                                    first = false

                                    let total_contact = 0
                                    sum_total_contact += amountOwed

                                    // หา outstading balance supplier โดยการเช็คว่า supplier รายการล่าสุดกับรายการถัดไปเป็นตัวเดียวกันมั้ย
                                    if(purchase.node.contact.id !== this.props.props.allPurchaseRecordGroup.edges[index +1]?.node.contact.id 
                                        && this.props.props.allPurchaseRecordGroup.edges[index+1]
                                    ){
                                            outstandingBalanceForThelastLine = 0;
                                    } else {
                                            outstandingBalanceForThelastLine += amountOwed
                                    }
                                    
                                    if (!purchase.node.purchaseRecord.edges[t_index + 1]) {
                                        total_contact = sum_total_contact
                                        sum_total_contact = 0
                                    }

                                    if (this.getNumberDay(purchase) < 1) {
                                        sum_totals[0] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 1, 30)) {
                                        sum_totals[1] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 31, 60)) {
                                        sum_totals[2] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 61, 90)) {
                                        sum_totals[3] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 91, 120)) {
                                        sum_totals[4] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 121, 150)) {
                                        sum_totals[5] += amountOwed
                                    } else if (this.between(this.getNumberDay(purchase), 151, 180)) {
                                        sum_totals[6] += amountOwed
                                    } else {
                                        sum_totals[7] += amountOwed
                                    }

                                    return (
                                        (
                                            <tr key={transaction.node.id}>
                                                <td className="text-center">{row_number}</td>
                                                <td>{purchase.node.contact.refNumber}</td>
                                                <td>{purchase.node.contact.name}</td>
                                                <td className="text-center">
                                                <Link to={"/accounting/expense/purchase/purchase-detail/" + purchase.node.id}
                                                    target={"_blank"}>
                                                    {purchase.node.docNumber}
                                                </Link>
                                                </td>
                                                <td>{transaction.node.description}</td>
                                                <td className="text-center">{format(purchase.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                <td className="text-center">{format(purchase.node.dueDate, 'DD/MM/YYYY')}</td>
                                                <td className="text-right">{this.getNumberDay(purchase)}</td>
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "not_overdue") &&
                                                    <td className="text-right">
                                                        {
                                                            this.getNumberDay(purchase) < 1 &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }

                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "1-30") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 1, 30) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "31-60") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 31, 60) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "61-90") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 61, 90) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "91-120") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 91, 120) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "121-150") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 121, 150) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "151-180") &&
                                                    <td className="text-right">
                                                        {
                                                            this.between(this.getNumberDay(purchase), 151, 180) &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "over180") &&
                                                    <td className="text-right">
                                                        {
                                                            this.getNumberDay(purchase) > 180 &&
                                                            amountOwedWithComma
                                                        }
                                                    </td>
                                                }
                                                <td className="text-right">{numberWithComma(total_contact, "")}</td>
                                                {!purchase.node.purchaseRecord.edges[t_index + 1]?  
                                                        this.props.props.allPurchaseRecordGroup.edges[index+1] ? 
                                                        <td className="text-right">{numberWithComma(outstandingBalanceOfSupplier, "")}</td>
                                                        :
                                                        <td className="text-right">{numberWithComma(outstandingBalanceForThelastLine, "")}</td>
                                                    : <td>{" "}</td>
                                                }                                      
                                            </tr>
                                        )
                                    )
                                }
                            }
                        }))
                })}
                {
                    (this.props.props.allPurchaseRecordGroup.pageInfo.hasNextPage || this.props.props.allPurchaseRecordGroup.pageInfo.hasPreviousPage) &&
                    <tr>
                        <td colSpan={8} className="text-right"><strong>รวม</strong></td>
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "not_overdue") && <td className="text-right"><strong>{numberWithComma(sum_totals[0], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "1-30") &&<td className="text-right"><strong>{numberWithComma(sum_totals[1], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "31-60") &&<td className="text-right"><strong>{numberWithComma(sum_totals[2], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "61-90") &&<td className="text-right"><strong>{numberWithComma(sum_totals[3], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "91-120") &&<td className="text-right"><strong>{numberWithComma(sum_totals[4], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "121-150") &&<td className="text-right"><strong>{numberWithComma(sum_totals[5], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "151-180") &&<td className="text-right"><strong>{numberWithComma(sum_totals[6], "")}</strong></td>}
                        {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "over180") &&<td className="text-right"><strong>{numberWithComma(sum_totals[7], "")}</strong></td>}
                        <td className="text-right"><strong>{numberWithComma(sum_totals.reduce((a, b) => a + b, 0), "")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(sum_totals.reduce((a, b) => a + b, 0), "")}</strong></td>
                    </tr>
                }
                {/* {!this.props.props.allPurchaseRecordGroup.pageInfo.hasNextPage && */}
                <tr>
                    <td colSpan={8} className="text-right"><strong>รวมทั้งหมด</strong></td>
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "not_overdue") &&<td className="text-right"><strong>{numberWithComma(this.props.props.summaryPurchaseRecordGroup[0], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "1-30") &&<td className="text-right"><strong>{numberWithComma(this.props.props.summaryPurchaseRecordGroup[1], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "31-60") &&<td className="text-right"><strong>{numberWithComma(this.props.props.summaryPurchaseRecordGroup[2], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "61-90") &&<td className="text-right"><strong>{numberWithComma(this.props.props.summaryPurchaseRecordGroup[3], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "91-120") &&<td className="text-right"><strong>{numberWithComma(this.props.props.summaryPurchaseRecordGroup[4], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "121-150") &&<td className="text-right"><strong>{numberWithComma(this.props.props.summaryPurchaseRecordGroup[5], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "151-180") &&<td className="text-right"><strong>{numberWithComma(this.props.props.summaryPurchaseRecordGroup[6], "")}</strong></td>}
                    {(this.props.state.overdue_date === "" || this.props.state.overdue_date === "over180") &&<td className="text-right"><strong>{numberWithComma(this.props.props.summaryPurchaseRecordGroup[7], "")}</strong></td>}
                    <td className="text-right"><strong>{numberWithComma(this.props.props.summaryPurchaseRecordGroup.reduce((a, b) => a + b, 0), "")}</strong></td>
                    <td className="text-right"><strong>{numberWithComma(this.props.props.summaryPurchaseRecordGroup.reduce((a, b) => a + b, 0), "")}</strong></td>
                </tr>
                {/* } */}
            </React.Fragment>
        )
    }
}

export default PayableOutstandingReportTable;
