import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import FilingFormWithholdingTax3Print from "./filingFormWithholdingTax3Print"
import Loading from '../../libs/loading';

const query = graphql`
    query wrapperFilingFormWithholdingTax3Query ($start_date: DateTime, $end_date: DateTime){
        selfProject {
            taxIdNumber
            name
            homeNumberProject
            roadProject
            alleyProject
            provinceProject
            districtProject
            subDistrictProject
            postcodeProject
        }
        withholdingTaxViewer{
            allWithholdingTax(pnd: "pnd-3",status: "active" ,startDate: $start_date, endDate: $end_date){
                edges{
                    node{
                        id
                        totalPrepaidDeposit
                        payRecordGroup {
                            id
                            docNumber
                            payRecord {
                                edges {
                                    node {
                                      id
                                      whtAmount
                                      whtRatePercent
                                      purchaseRecord {
                                        id
                                        preTaxAmount
                                        prepaidDepositTransactionUsed {
                                          edges {
                                              node {
                                                  amount
                                                  prepaidDepositTransaction{
                                                      whtRatePercent
                                                      vat
                                                  }
                                              }
                                          }
                                      }
                                      acceptCreditNoteTransaction(status: "paid"){
                                          edges {
                                              node {
                                                  price
                                              }
                                          }
                                      }
                                    }
                                  }
                                }
                              }
                          }
                        otherExpense{
                            otherExpenseTransaction{
                                edges{
                                    node{
                                        preTaxAmount
                                        whtRate
                                    }
                                }
                            }
                        }
                    }
                }
            }
            summary(typeTax: "pnd-3",status: "active", startDate: $start_date, endDate: $end_date)
        }
    }
`;

class WrapperFilingFormWithholdingTax3 extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>AttachmentWithholdingTax</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                {this.props.match.params.type === 'not-data' ?
                    <FilingFormWithholdingTax3Print/>
                    :
                    <QueryRenderer
                        environment={environment}
                        query={query}
                        variables={{
                            start_date: this.props.location.state.start_date,
                            end_date: this.props.location.state.end_date,
                        }}
                        render={({error, props}) => {
                            if (error) {
                                return <div>{error.message}</div>;
                            } else if (props) {
                                return (
                                    <FilingFormWithholdingTax3Print
                                        data={props}
                                        date={this.props.location.state.start_date}
                                    />
                                )
                            }

                            return <div className="text-center"><Loading/></div>;
                        }}
                    />
                }


            </React.Fragment>
        );
    }
}

export default WrapperFilingFormWithholdingTax3;
