import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import SelectResident from "./selectResident"
import PreInvoiceForm from './preInvoiceForm';
import PreInvoiceEditForm from './preInvoiceEditForm';
import AccountingTopMenuNavigation from "../../accounting/accountingTopMenuNavigation";
import { Dropdown } from "reactjs-dropdown-component";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import './style/settingInvoiceTermsList.scss'
import Loading from '../../libs/loading';
import { Translation } from "react-i18next";
import i18n from "i18next";

const query = graphql`
    query settingInvoiceCreateQuery($contact_type: String, $conveyance: String) {
        contactViewer {
            id
            allContactPreInvoice(typeOfContact: $contact_type, conveyance: $conveyance, typeOfPayment:"receivable"){
                edges{
                    node{
                        id
                        refNumber
                        name
                        firstName
                        lastName
                        residential {
                            id
                            conveyance
                            size
                            ownershipRatio
                        }
                    }
                }
            }
        }
    }
`;

const SettingInvoiceTermsCreate = (props) => {

    const [updateContactList, setUpdateContactList]= useState([])
    const [updateContact, setUpdateContact]= useState([])
    const [search, setSearch]= useState("")
    const [searchValue, setSearchValue]= useState("")
    const [conveyance]= useState("all")
    const [contact_type, setContact_type] = useState("residential")

    const type = [
        {
          label: i18n.t("settingInvoiceTerms:CoownerMember"),
          value: "residential",
        },
        // {
        //   label: "ลูกหนี้",
        //   value: "supplier"
        // }
    ];

    const updateList =(contactListID,contactList)=>{
        setUpdateContactList(contactListID)
        setUpdateContact(contactList)
    }

    const onChangeService = (text) => {
        let value = text.value
        setContact_type(value)
    }

    const onSearch = (e) => {
        setSearch(e.target.value)
        setSearchValue(e.target.value)
    }

    const onSubmit = () => {
        setSearch(searchValue)
    }

    return (
        <Wrapper>
            <Header />
            <Sidebar />
            {/* <div className="div-setting-invoice"/> */}
            <Translation>
                {t => (
                    <WrapperContent>
                      <AccountingTopMenuNavigation mini={true}/>
                        {props.match.params.status ==="select" &&
                            <div className="container-fluid box" id="select-contact">
                                <div className="row">
                                    <div className="col">
                                        <h3>
                                            <Link to={"/setting/invoice-setting-terms"}>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/back.png'}
                                                    alt="back" className="back cursor" />
                                            </Link>
                                            <span className="text-global-header">{t("settingInvoiceTerms:settingInvoicesTerms")}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div className="content-inner mx-2">
                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <p className="text-headline-4 mb-2">{t("settingInvoiceTerms:debtorInformation")}</p>
                                            <Dropdown
                                                name="type"
                                                required
                                                title={t("settingInvoiceTerms:CoownerMember")}
                                                list={type}
                                                onChange={(text)=>onChangeService(text)}
                                                styles={{
                                                headerTitle: { fontSize: "15px" },
                                                header: { border: "1px solid #b3b3b3" },
                                                wrapper: {
                                                    width: "467px"
                                                },
                                                listItem: { fontSize: "16px" }
                                                }}
                                            />
                                        </div>
                                        <div className="col-8 mt-6 ml-0">
                                            <span className="text-headline-4">{t("settingInvoiceTerms:search")}</span>
                                            <div className="calendars-wrapper mt">
                                                {/* <form> */}
                                                    <input
                                                        type="text"
                                                        required
                                                        placeholder={t("settingInvoiceTerms:searchUnitNumbersWithinTheProject")}
                                                        className="calendars-wrapper colorborder form-control float-left"
                                                        onChange={(e)=>onSearch(e)}
                                                    />
                                                    <a className="dataItem" target="_blank"></a>
                                                {/* </form> */}
                                                <div className="calendars float-right">
                                                <button
                                                    type="submit"
                                                    className="btn"
                                                    name="search"
                                                    onClick={onSubmit} style={{display:'contents'}}>
                                                    <img className="icon" src={process.env.PUBLIC_URL + "/images/icons/search-outline.png"}
                                                        alt="calendars"
                                                    />
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <QueryRenderer
                                        environment={environment}
                                        query={query}
                                        cacheConfig={{use_cache: false}}
                                        variables={{
                                            // search: search,
                                            contact_type: contact_type,
                                            conveyance: conveyance
                                        }}
                                        render={({error, props}) => {
                                            if (error) {
                                                return <div>{error.message}</div>;
                                            } else if (props) {
                                                return <SelectResident query={query} contact_type={contact_type}
                                                    conveyance='all' updateContactList={updateList}
                                                    listResident={updateContactList}
                                                    search={search}
                                                    queryObject={props.contactViewer.allContactPreInvoice.edges}
                                                    nextLink="/setting/invoice-setting-terms/form/create"
                                                />
                                            }
                                            return <Loading/>
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        {props.match.params.status === "create" &&
                            <PreInvoiceForm listResidentId={updateContactList} listResident={updateContact}/>
                        }
                        {props.match.params.status === "edit"&&
                            <PreInvoiceEditForm invoice_id={this.props.match.params.id}/>
                        }
                    </WrapperContent>
                )}
            </Translation>
        </Wrapper>
    )
}
export default SettingInvoiceTermsCreate