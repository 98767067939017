/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeveloperTransferOwnership = "ALL" | "NO" | "UNIDENTIFIED" | "%future added value";
export type ProjectEstablishmentStatus = "ALLOCATED" | "OTHER" | "UNALLOCATED" | "%future added value";
export type ProjectTypeOfCompany = "COMMERCIAL" | "DEVELOPER" | "MANAGE_YOURSELF" | "PROPERTY_MANAGEMENT" | "%future added value";
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type projectListQueryVariables = {||};
export type projectListQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +nameTh: ?string,
    +nameEn: ?string,
    +address: ?string,
    +addressEn: ?string,
    +logo: ?string,
    +space: ?string,
    +nameDeveloper: ?string,
    +lat: ?string,
    +long: ?string,
    +numberOfBuilding: ?number,
    +numberOfFloor: ?number,
    +numberOfRoom: ?number,
    +description: ?string,
    +juristicContactNumber: ?string,
    +securityContactNumber: ?string,
    +technicianContactNumber: ?string,
    +registrationDate: ?any,
    +bringForwardStartDate: ?any,
    +typeOfProject: ?ProjectTypeOfProject,
    +keyProjectQr: ?string,
    +registrationNumber: ?string,
    +haveVat: boolean,
    +taxIdNumber: ?string,
    +typeOfCompany: ?ProjectTypeOfCompany,
    +establishmentStatus: ?ProjectEstablishmentStatus,
    +usableSpace: ?string,
    +parkingSpace: ?string,
    +parkingSlot: ?string,
    +openTime: ?string,
    +endTime: ?string,
    +image: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +source: ?string,
        |}
      |}>
    |},
    +floorPlan: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +source: ?string,
          +title: ?string,
        |}
      |}>
    |},
    +fileBuilding: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +nameFile: ?string,
          +fileUpload: ?string,
        |}
      |}>
    |},
    +projectBuilding: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +projectBuildingUnit: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +building: ?string,
                +numberOfFloor: ?number,
                +numberOfRoom: ?number,
              |}
            |}>
          |},
        |}
      |}>
    |},
    +insideBuilding: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fileUpload: ?string,
        |}
      |}>
    |},
    +unitBuilding: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fileUpload: ?string,
        |}
      |}>
    |},
  |},
  +allDeveloperProject: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +transferOwnership: DeveloperTransferOwnership,
      |}
    |}>
  |},
|};
export type projectListQuery = {|
  variables: projectListQueryVariables,
  response: projectListQueryResponse,
|};
*/


/*
query projectListQuery {
  selfProject {
    id
    name
    nameTh
    nameEn
    address
    addressEn
    logo
    space
    nameDeveloper
    lat
    long
    numberOfBuilding
    numberOfFloor
    numberOfRoom
    description
    juristicContactNumber
    securityContactNumber
    technicianContactNumber
    registrationDate
    bringForwardStartDate
    typeOfProject
    keyProjectQr
    registrationNumber
    haveVat
    taxIdNumber
    typeOfCompany
    establishmentStatus
    usableSpace
    parkingSpace
    parkingSlot
    openTime
    endTime
    image {
      edges {
        node {
          id
          source
        }
      }
    }
    floorPlan {
      edges {
        node {
          id
          source
          title
        }
      }
    }
    fileBuilding {
      edges {
        node {
          id
          nameFile
          fileUpload
        }
      }
    }
    projectBuilding {
      edges {
        node {
          id
          name
          projectBuildingUnit {
            edges {
              node {
                id
                building
                numberOfFloor
                numberOfRoom
              }
            }
          }
        }
      }
    }
    insideBuilding {
      edges {
        node {
          id
          fileUpload
        }
      }
    }
    unitBuilding {
      edges {
        node {
          id
          fileUpload
        }
      }
    }
  }
  allDeveloperProject {
    edges {
      node {
        id
        transferOwnership
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfFloor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfRoom",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileUpload",
  "storageKey": null
},
v6 = [
  (v0/*: any*/),
  (v5/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameTh",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameEn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addressEn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "space",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameDeveloper",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "long",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberOfBuilding",
        "storageKey": null
      },
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "juristicContactNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "securityContactNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "technicianContactNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registrationDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bringForwardStartDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfProject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "keyProjectQr",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registrationNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "haveVat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taxIdNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfCompany",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "establishmentStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usableSpace",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingSpace",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parkingSlot",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "openTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectImageNodeConnection",
        "kind": "LinkedField",
        "name": "image",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectImageNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectImageNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FloorPlanNodeConnection",
        "kind": "LinkedField",
        "name": "floorPlan",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FloorPlanNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FloorPlanNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FileBuildingNodeConnection",
        "kind": "LinkedField",
        "name": "fileBuilding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FileBuildingNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FileBuildingNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameFile",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectBuildingNodeConnection",
        "kind": "LinkedField",
        "name": "projectBuilding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectBuildingNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectBuildingNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectBuildingUnitNodeConnection",
                    "kind": "LinkedField",
                    "name": "projectBuildingUnit",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectBuildingUnitNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectBuildingUnitNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "building",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InsideBuildingNodeConnection",
        "kind": "LinkedField",
        "name": "insideBuilding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InsideBuildingNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InsideBuildingNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UnitBuildingNodeConnection",
        "kind": "LinkedField",
        "name": "unitBuilding",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitBuildingNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnitBuildingNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DeveloperProjectNodeConnection",
    "kind": "LinkedField",
    "name": "allDeveloperProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeveloperProjectNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeveloperProjectNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transferOwnership",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "projectListQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "projectListQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "c31d591e34a7bd8c55c5f929f3881069",
    "id": null,
    "metadata": {},
    "name": "projectListQuery",
    "operationKind": "query",
    "text": "query projectListQuery {\n  selfProject {\n    id\n    name\n    nameTh\n    nameEn\n    address\n    addressEn\n    logo\n    space\n    nameDeveloper\n    lat\n    long\n    numberOfBuilding\n    numberOfFloor\n    numberOfRoom\n    description\n    juristicContactNumber\n    securityContactNumber\n    technicianContactNumber\n    registrationDate\n    bringForwardStartDate\n    typeOfProject\n    keyProjectQr\n    registrationNumber\n    haveVat\n    taxIdNumber\n    typeOfCompany\n    establishmentStatus\n    usableSpace\n    parkingSpace\n    parkingSlot\n    openTime\n    endTime\n    image {\n      edges {\n        node {\n          id\n          source\n        }\n      }\n    }\n    floorPlan {\n      edges {\n        node {\n          id\n          source\n          title\n        }\n      }\n    }\n    fileBuilding {\n      edges {\n        node {\n          id\n          nameFile\n          fileUpload\n        }\n      }\n    }\n    projectBuilding {\n      edges {\n        node {\n          id\n          name\n          projectBuildingUnit {\n            edges {\n              node {\n                id\n                building\n                numberOfFloor\n                numberOfRoom\n              }\n            }\n          }\n        }\n      }\n    }\n    insideBuilding {\n      edges {\n        node {\n          id\n          fileUpload\n        }\n      }\n    }\n    unitBuilding {\n      edges {\n        node {\n          id\n          fileUpload\n        }\n      }\n    }\n  }\n  allDeveloperProject {\n    edges {\n      node {\n        id\n        transferOwnership\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '75f3a128aa834c8805473b985de54bbf';

module.exports = node;
