import React, { useState, useEffect } from 'react'
import { Translation } from "react-i18next"
import { Modal } from 'react-bootstrap';
import "../debtFreeRequest.scss";
import _ from 'lodash'
import i18next from 'i18next';


function ApproveModal(props) {
    const [propsInput, setProps] = useState(props);
    const [noteInput, setNote] = useState('');
    const [state, setState] = useState({
        noteInput: '',
        choice: 'not-success',
        modalApprove: false,
        modalError: false,
        modalCancel: false,
        modalVoid: false,
        modalChoice: false,
        modalSuccess: false,
        modalErrorInputPayment: false,
    })

    useEffect(() => {
        setProps(props)
        return () => {

        }
    }, [props])

    const handleChangeVoid = e => {
        const { name, value } = e.target
        let noteInput = value
        // setNote(state.noteInput+value)
        setState({
            noteInput: noteInput,
            modalVoid: true,
            choice: 'other'
        })
    }

    const handleChangeCancel = e => {
        const { name, value } = e.target
        let noteInput = value
        // setNote(state.noteInput+value)
        setState({
            noteInput: noteInput,
            modalCancel: true,
            choice: 'other'
        })
    }

    const onApprove = () => {
        if (propsInput.props.price > 0) {
            setState({ modalApprove: false })
            setState({ modalError: true })
        } else {
            setState({ modalApprove: false })
            propsInput.updateNote('approve', '')
        }
    }

    const onCancel = () => {
        setState({
            modalChoice: false,
            modalCancel: true
        })
    }

    const onVoid = () => {
        setState({
            modalChoice: false,
            modalVoid: true
        })
    }

    const switchBoxCancel = (value) => {
        setState({
            choice: value,
            modalCancel: true
        })
    }

    const switchBoxVoid = (value) => {
        setState({
            choice: value,
            modalVoid: true
        })
    }

    const submitCancelOrVoid = (type) => {
        let note = ''
        if (state.choice === 'not-success') {
            note = 'หลักฐานไม่ครบถ้วน / ไม่สมบูรณ์'
        } else if (state.choice === 'have-invoice') {
            note = 'ยังมีหนี้ค้างชำระ / ตรวจสอบแล้วยังไม่ปลอดหนี้'
        } else if (state.choice === 'void') {
            note = 'ระงับ / แจ้งยกเลิกการขอใบปลอดหนี้'
        } else if (state.choice === 'other') {
            note = state.noteInput
        }
        propsInput.updateNote(type, note)
        if (type === 'noteVoid') {
            setState({
                modalVoid: false,
            })
        } else {
            setState({
                modalCancel: false
            })
        }

    }

    const submitButton = () => {
        if (propsInput.props.price > 0) {
            setState({
                modalError: true
            })
        } else if (propsInput.props.alertInputPaymentReceive && propsInput.props.allSettingDebsFreeCertification !== null && propsInput.props.allSettingDebsFreeCertification?.node.fee) {
            setState({
                modalErrorInputPayment: true
            })
        }
        else {
            props.onSubmit()
        }
    }

    return (
        <Translation>
            {t =>
                <div>
                    {propsInput.submit ?
                        <button type="button" className="btn btn-confirm" onClick={() => submitButton()}
                        // disabled={propsInput.props.alertInputPaymentReceive} 
                        >
                            {propsInput.props.loading && <span className="spinner-border spinner-border-sm spinner-border-white align-middle mr-2" />}
                            {i18next.t("Allaction:save")}
                        </button>


                        :

                        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertificationrequest_approve' }) &&
                        <React.Fragment>
                            <button type="button" className="btn-notApprove mr-2" onClick={() => setState({ modalChoice: true })}>
                                {propsInput.props.loading && <span className="spinner-border spinner-border-sm spinner-border-white align-middle mr-2" />}
                                {i18next.t("Allaction:Not Approved")}
                            </button>
                            <button type="button" className="btn-add"
                                onClick={() => setState({ modalApprove: true })}
                            >
                                {propsInput.props.loading && <span className="spinner-border spinner-border-sm spinner-border-white align-middle mr-2" />}
                                {i18next.t("Allaction:Approve")}
                            </button>
                        </React.Fragment>
                    }


                    <Modal show={state.modalApprove} dialogClassName="modal-approve" id="modal" >
                        <Modal.Body style={{ fontFamily: 'Kanit', borderBottom: 'none' }}>
                            <div className="row mt-1 mb-1">
                                <div className="col-12 d-flex justify-content-center">
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/icons/alert-blue.png'}
                                        alt="alert" style={{ width: '60px', height: '60px' }} />
                                </div>
                                <div className="col-12  mt-3 d-flex justify-content-center">
                                    <h5> {i18next.t("debt_free_request:Requesting approval for the request of a debt-free certification")} <br /> {i18next.t("debt_free_request:Number")} {propsInput.props.docNumberDebtRequest} ? </h5>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ fontFamily: 'Kanit', borderTop: 'none' }}>
                            <div className="col-12  mt-3 d-flex justify-content-center">
                                <button type="button" className="btn-confirm mr-2" onClick={() => onApprove()}>
                                    <span>{i18next.t("Allaction:Approve")}</span>
                                </button>
                                <button type="button" className="btn-close mr-2" onClick={() => setState({ modalApprove: false })}>
                                    <span>{i18next.t("Allaction:Close")}</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={state.modalSuccess} dialogClassName="modal-approve" id="modal">
                        <Modal.Body style={{ fontFamily: 'Kanit', borderBottom: 'none' }}>
                            <div className="row mt-1 mb-1">
                                <div className="col-12 d-flex justify-content-center">
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/icons/success-icon.png'}
                                        alt="alert" style={{ width: '60px', height: '60px' }} />
                                </div>
                                <div className="col-12  mt-3 d-flex justify-content-center">
                                    <h5> {i18next.t("debt_free_request:Successful")} </h5>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ fontFamily: 'Kanit', borderTop: 'none' }}>
                            <div className="col-12  mt-3 d-flex justify-content-center">
                                <button type="button" className="btn-close mr-2" onClick={() => setState({ modalSuccess: false })}>
                                    <span>{i18next.t("Allaction:Close")}</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={state.modalChoice} dialogClassName="modal-approve" id="modal">
                        <Modal.Body style={{ fontFamily: 'Kanit', borderBottom: 'none' }}>
                            <div className="row mt-1 mb-1">
                                <div className="col-12 d-flex justify-content-center">
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/icons/alert-blue.png'}
                                        alt="alert" style={{ width: '60px', height: '60px' }} />
                                </div>
                                <div className="col-12  mt-3 d-flex justify-content-center">
                                    <h5> {i18next.t("debt_free_request:Not approving the request will result in the cancellation of the certification request with that specific reference number.")}{i18next.t("debt_free_request:However, if you want to allow the ability to go back and edit the information again, please select the 'Reject' menu")} </h5>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ fontFamily: 'Kanit', borderTop: 'none' }}>
                            <div className="col-12  mt-3 d-flex justify-content-center">
                                <button type="button" className="btn-confirm mr-2" onClick={() => onCancel()}>
                                    <span>{i18next.t("Allaction:Reject")}</span>
                                </button>
                                <button type="button" className="btn-cancel mr-2" onClick={() => onVoid()} >
                                    <span>{i18next.t("Allaction:Not Approved")}</span>
                                </button>
                                <button type="button" className="btn-close mr-2" onClick={() => setState({ modalChoice: false, noteInput: '', choice: 'not-success' })}>
                                    <span>{i18next.t("Allaction:Close")}</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={state.modalCancel} dialogClassName="modal-approve" id="modal">
                        <Modal.Body style={{ fontFamily: 'Kanit', borderBottom: 'none' }}>
                            <div className="row mt-1 mb-1">
                                <div className="col-12 d-flex justify-content-center">
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/icons/alert-blue.png'}
                                        alt="alert" style={{ width: '60px', height: '60px' }} />
                                </div>
                                <div className="col-12  mt-3 d-flex justify-content-center">
                                    <h5> {i18next.t("debt_free_request:Please specify the reason for rejecting the request for a debt-free certification.")}</h5>
                                </div>
                                <div className="col-12  mt-3 form-inline">
                                    <input type="radio"
                                        id="not-success"
                                        className="radio-request mr-3"
                                        onChange={() => switchBoxCancel('not-success')}
                                        checked={state.choice === 'not-success'}
                                    />
                                    <span htmlFor="not-success">
                                        {i18next.t("debt_free_request:Incomplete or insufficient evidence.")}
                                    </span>
                                </div>
                                <div className="col-12  mt-3 form-inline">
                                    <input type="radio"
                                        id="have-invoice"
                                        className="radio-request mr-3"
                                        onChange={() => switchBoxCancel('have-invoice')}
                                        checked={state.choice === 'have-invoice'}
                                    />
                                    <span htmlFor="have-invoice">
                                        {i18next.t("debt_free_request:There are still outstanding debts / Not cleared after verification.")}
                                    </span>
                                </div>
                                <div className="col-12  mt-3 form-inline">
                                    <input type="radio"
                                        id="void"
                                        className="radio-request mr-3"
                                        onChange={() => switchBoxCancel('void')}
                                        checked={state.choice === 'void'}
                                    />
                                    <span htmlFor="void">
                                        {i18next.t("debt_free_request:Suspended / Notified cancellation of the request for a debt-free certificate.")}
                                    </span>
                                </div>
                                <div className="col-12  mt-3 form-inline">
                                    <input type="radio"
                                        id="other"
                                        className="radio-request mr-3"
                                        onChange={() => switchBoxCancel('other')}
                                        checked={state.choice === 'other'}
                                    />
                                    <span htmlFor="other" className="mr-2">
                                        {i18next.t("debt_free_request:Other, please specify.")}
                                    </span>
                                    <input type="text" className="form-control" onChange={handleChangeCancel} value={state.noteInput} />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ fontFamily: 'Kanit', borderTop: 'none' }}>
                            <div className="col-12  mt-3 d-flex justify-content-center">
                                <button type="button" className="btn-confirm mr-2" onClick={() => submitCancelOrVoid('noteEdit')} >
                                    <span>{i18next.t("Allaction:Confirm")}</span>
                                </button>
                                <button type="button" className="btn-close mr-2" onClick={() => setState({ modalCancel: false, noteInput: '', choice: 'not-success' })}>
                                    <span>{i18next.t("Allaction:Close")}</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={state.modalVoid} dialogClassName="modal-approve" id="modal">
                        <Modal.Body style={{ fontFamily: 'Kanit', borderBottom: 'none' }}>
                            <div className="row mt-1 mb-1">
                                <div className="col-12 d-flex justify-content-center">
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/icons/alert-blue.png'}
                                        alt="alert" style={{ width: '60px', height: '60px' }} />
                                </div>
                                <div className="col-12  mt-3 d-flex justify-content-center">
                                    <h5>{i18next.t("debt_free_request:Please specify the reason for not approving the request for a debt-free certification")}</h5>
                                </div>
                                <div className="col-12  mt-3 form-inline">
                                    <input type="radio"
                                        id="not-success"
                                        className="radio-request mr-3"
                                        onChange={() => switchBoxVoid('not-success')}
                                        checked={state.choice === 'not-success'}
                                    />
                                    <span htmlFor="not-success">
                                        {i18next.t("debt_free_request:Incomplete or insufficient evidence.")}
                                    </span>
                                </div>
                                <div className="col-12  mt-3 form-inline">
                                    <input type="radio"
                                        id="have-invoice"
                                        className="radio-request mr-3"
                                        onChange={() => switchBoxVoid('have-invoice')}
                                        checked={state.choice === 'have-invoice'}
                                    />
                                    <span htmlFor="have-invoice">
                                        {i18next.t("debt_free_request:There are still outstanding debts / Not cleared after verification.")}
                                    </span>
                                </div>
                                <div className="col-12  mt-3 form-inline">
                                    <input type="radio"
                                        id="void"
                                        className="radio-request mr-3"
                                        onChange={() => switchBoxVoid('void')}
                                        checked={state.choice === 'void'}
                                    />
                                    <span htmlFor="void">
                                        {i18next.t("debt_free_request:Suspended / Notified cancellation of the request for a debt-free certificate.")}
                                    </span>
                                </div>
                                <div className="col-12  mt-3 form-inline">
                                    <input type="radio"
                                        id="other"
                                        className="radio-request mr-3"
                                        onChange={() => switchBoxVoid('other')}
                                        checked={state.choice === 'other'}
                                    />
                                    <span htmlFor="other" className="mr-2">
                                        {i18next.t("debt_free_request:Other, please specify.")}
                                    </span>
                                    <input type="text" className="form-control" onChange={handleChangeVoid} value={state.noteInput} />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ fontFamily: 'Kanit', borderTop: 'none' }}>
                            <div className="col-12  mt-3 d-flex justify-content-center">
                                <button type="button" className="btn-confirm mr-2" onClick={() => submitCancelOrVoid('noteVoid')}>
                                    <span>{i18next.t("debt_free_request:Confirm")}</span>
                                </button>
                                <button type="button" className="btn-close mr-2" onClick={() => setState({ modalVoid: false, noteInput: '', choice: 'not-success' })}>
                                    <span>{i18next.t("debt_free_request:Close")}</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={state.modalError} dialogClassName="modal-approve" id="modal">
                        <Modal.Body style={{ fontFamily: 'Kanit', borderBottom: 'none' }}>
                            <div className="row mt-1 mb-1">
                                <div className="col-12 d-flex justify-content-center">
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/icons/fail-icon.png'}
                                        alt="alert" style={{ width: '60px', height: '60px' }} />
                                </div>
                                <div className="col-12  mt-3 d-flex justify-content-center">
                                    <h5>{i18next.t("debt_free_request:Unable to process the request for a debt-free certificate/approve the request while there is still an outstanding balance in the system. Please settle the balance in full first")}.</h5>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ fontFamily: 'Kanit', borderTop: 'none' }}>
                            <div className="col-12  mt-3 d-flex justify-content-center">
                                <button type="button" className="btn-cancel" onClick={() => setState({ modalError: false })}>
                                    <span>{i18next.t("Allaction:yes")}</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={state.modalErrorInputPayment} dialogClassName="modal-approve" id="modal">
                        <Modal.Body style={{ fontFamily: 'Kanit', borderBottom: 'none' }}>
                            <div className="row mt-1 mb-1">
                                <div className="col-12 d-flex justify-content-center">
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/icons/fail-icon.png'}
                                        alt="alert" style={{ width: '60px', height: '60px' }} />
                                </div>
                                <div className="col-12  mt-3 d-flex justify-content-center">
                                    <h5>{i18next.t("debt_free_request:Unable to request a debt-free certificate. Please enter the receipt code correctly.")}</h5>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ fontFamily: 'Kanit', borderTop: 'none' }}>
                            <div className="col-12  mt-3 d-flex justify-content-center">
                                <button type="button" className="btn-cancel" onClick={() => setState({ modalErrorInputPayment: false })}>
                                    <span>{i18next.t("Allaction:yes")}</span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </Translation>
    );
}

export default ApproveModal;