import React, { Component } from "react";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import environment from "../../env/environment";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import getNameResidential from "../../libs/getNameResidential";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import PayChannel from "../expense/pay/payChannel";
import Swal from "sweetalert2";
import GuaranteeMoneyReceivedUpdateMutation from "./mutations/guaranteeMoneyReceivedUpdateMutation.js";
import Redirect from "react-router-dom/es/Redirect";
import _ from "lodash";
import { Translation } from "react-i18next";
import i18n from "i18next";
import Loading from '../../libs/loading';
import InputAddress from "../../libs/autoCompleteAddress";
import { Modal} from 'react-bootstrap';
import AddressDataShow from "../../components/FucntionLib/addressDataShow";
import i18next from "i18next";
import { fetchQuery } from "relay-runtime";
const clearGuaranteeKey = [ "firstName", "lastName", "taxIdNumber","address","city","province","district","postalCode","email","phone" ];

const guarantee = graphql`
  query guaranteeMoneyReceivedViewQuery($id: ID!) {
    guaranteeMoneyReceived(id: $id) {
      id
      docNumber
      issuedDate
      status
      amount
      description
      voidRemark

      firstName
      lastName
      payGroup
      address district city province postalCode
      name
      taxIdNumber
      email
      phone
      note

      contact {
        refNumber
        firstName
        lastName
        registeredName
        registeredAddress
        registeredCountry
        registeredProvince
        registeredCity
        registeredDistrict
        registeredPostalCode
        typeOfContact
        name
      }
      chartOfAccount {
        id
        chartOfAccountCode
        name
      }
      note
      bankAccountTransaction {
        edges {
          node {
            id
            price
            date
            imageSlip
            bankAccount {
              bankName
              accountNumber
            }
          }
        }
      }
      cashDepositReceiveTransaction {
        edges {
          node {
            price
          }
        }
      }
      chequeDeposit {
        edges {
          node {
            id
            date
            bankName
            chequeNumber
            branchNumber
            price
          }
        }
      }
      unknownReceive{
        edges{
            node{
              id description docNumber amount refNumber
                chartOfAccount{
                  chartOfAccountCode name
                }
                  imageSlip
            }
        }
      }
    }
  }
`;

class ReceiptDepositView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sum_total: 0,
      loading: false,
      redirect: false,
      showModal:false,
      clearGuaranteeNameAddress:[],
    };
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.getDataForEdit = this.getDataForEdit.bind(this);
    this.submit = this.submit.bind(this)
  }

  onChangeStatus() {
    Swal.fire({
      title: i18n.t("guarantee_money_received:Are you sure to cancel this guarantee receipt?"),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: i18n.t("guarantee_money_received:Cancel"),
      confirmButtonText: i18n.t("guarantee_money_received:Yes"),
    }).then(async (result) => {
      if (result.value) {
        let { value: void_remark } = await Swal.fire({
          title: i18n.t("guarantee_money_received:Note"),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: i18n.t("guarantee_money_received:Cancel"),
          confirmButtonText: i18n.t("guarantee_money_received:Confirm"),
          inputValidator: (value) => {
            if (!value) {
              return i18n.t("guarantee_money_received:Please enter the cancellation note!");
            }
          },
        });

        if (void_remark) {
          this.setState({ loading: true });
          let input = {
            clientMutationId: this.props.match.params.id,
            status: "void",
            voidRemark: void_remark,
          };
          GuaranteeMoneyReceivedUpdateMutation(
            input,
            (response) => {

              if (response.updateGuaranteeMoneyReceived.ok) {
                Swal.fire(
                  i18n.t("guarantee_money_received:Success!"),
                  i18n.t("guarantee_money_received:The guarantee receipt has been cancelled"),
                  "success"
                ).then(() => this.setState({ redirect: true, loading: false }));
              } else {
                Swal.fire(
                  i18n.t("guarantee_money_received:Unsuccess"),
                  response.updateGuaranteeMoneyReceived.warningText,
                  "warning"
                ).then(() => this.setState({ loading: false }));
              }
            },
            () => {
              this.setState({ loading: false });
              Swal.fire(
                i18n.t("guarantee_money_received:Unsuccess"),
                i18n.t("guarantee_money_received:Failed to cancel the guarantee receipt"),
                "error"
              );
            }
          );
        }
      } else {
        this.setState({ loading: false });
      }
    });
  }

  onChangeEdit() {
    Swal.fire({
      title: i18n.t('invoiceDetail:Are you sure to edit this invoice?'),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: i18n.t('invoiceDetail:No'),
      confirmButtonText: i18n.t('invoiceDetail:Yes'),
    }).then(async (result) => {
      if (result.value) {
        this.setState({ showModal: true });
      }
    });
  }

  componentWillMount() {
    this.getDataForEdit()
  }
  

  getDataForEdit(){
  ///edit clearGuarantee address
  let state = { ...this.state}; 

  if (this.props.location.state) {
      let key = Object.keys(this.props.location.state);
      key.forEach((key) => {
        state[key] = this.props.location.state[key];
        state['address'] = this.props.location.state['registeredAddress'];
        state['registeredCity'] = this.props.location.state['registeredCity'];
        state['registeredDistrict'] = this.props.location.state['registeredDistrict'];
        state['registeredProvince'] = this.props.location.state['registeredProvince'];
        state['registeredPostalCode'] = this.props.location.state['registeredPostalCode'];
      });
  } else {
    clearGuaranteeKey.forEach((key) => {
      state[key] = '';
    });
  }

  let dataState = state

  fetchQuery(environment, guarantee, { id: this.props.match.params.id }).then((res)=> {
    _.set(dataState, `firstName` , res.guaranteeMoneyReceived?.firstName)
    _.set(dataState, `lastName`, res.guaranteeMoneyReceived?.lastName)
    this.setState({
      clearGuaranteeNameAddress:dataState
    })
  })
   
  

  }

  handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    
    let state = _.set(_.cloneDeep(this.state), name, value);
    this.setState(state);
  }

  onSelect(fullAddress) {

    const { registeredDistrict, registeredCity, registeredProvince, registeredPostalCode } = fullAddress

    let value = _.cloneDeep(this.state.clearGuaranteeNameAddress);
    _.set(value,'registeredDistrict',registeredDistrict)
    _.set(value,'registeredCity',registeredCity)
    _.set(value,'registeredProvince',registeredProvince)
    _.set(value,'registeredPostalCode',registeredPostalCode)

    this.setState({
      clearGuaranteeNameAddress:value
    })

  }

  submit(e) {

    e.preventDefault();

    this.props.history.push({
      pathname:`/document/guarantee-money-received/` +
      this.props.match.params.id,
      state: {
        firstName : this.state.clearGuaranteeNameAddress.firstName,
        lastName : this.state.clearGuaranteeNameAddress.lastName,
        taxIdNumber : this.state.clearGuaranteeNameAddress.taxIdNumber,
        address : this.state.clearGuaranteeNameAddress.address,
        city : this.state.clearGuaranteeNameAddress.registeredCity,
        province : this.state.clearGuaranteeNameAddress.registeredProvince,
        district : this.state.clearGuaranteeNameAddress.registeredDistrict,
        postalCode : this.state.clearGuaranteeNameAddress.registeredPostalCode,
      },
    })
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect to="/accounting/guarantee-money-received/list/received" />
      );
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <QueryRenderer
            environment={environment}
            query={guarantee}
            variables={{ id: this.props.match.params.id }}
            render={({ error, props }) => {
              if (error) {
                return <div>Error :(</div>;
              } else if (props) {
                return (
                  <div
                    className="container-fluid box"
                    id="receipt_deposit-view"
                    key={props.guaranteeMoneyReceived.id}
                  >
                    <div className="row justify-content-between">
                      <div className="col">
                        <Translation>
                          {t=>
                          <h3 className="mb-4">
                            <Link to="/accounting/guarantee-money-received/list/received">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/wrapperContent/back.png"
                                }
                                alt="back"
                                className="back"  
                              />
                            </Link>
                            {t("guarantee_money_received:Guarantee receipt")+ " "+"(GR)"}
                          </h3>}
                        </Translation>
                      </div>
                      <div className="col text-right">
                        {props.guaranteeMoneyReceived.status === "IN_PROCESS" &&
                          _.some(
                            JSON.parse(localStorage.getItem("permission_list")),
                            { codename: "accounting_guarantee_receive_delete" }
                          ) && (
                            <Translation>
                              {t=>
                              <button
                                className="btn btn-danger add mr-3"
                                disabled={this.state.loading}
                                onClick={this.onChangeStatus}
                              >
                                {this.state.loading && (
                                  <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                )}
                                {t("guarantee_money_received:Cancel the guarantee receipt")}
                              </button>}
                            </Translation>
                          )}
                            <Translation>
                                  {t=>
                                    <button
                                      className="btn btn-primary add mr-3"
                                      onClick={this.onChangeEdit}
                                    >
                                        {t("other_received:Edit Name/Address")}
                                    </button>
                            }
                            </Translation>
                        {_.some(
                          JSON.parse(localStorage.getItem("permission_list")),
                          { codename: "accounting_guarantee_receive_print" }
                        ) && (
                          <Link
                            to={
                              "/document/guarantee-money-received/" +
                              props.guaranteeMoneyReceived.id
                            }
                            target={"_blank"}
                          >
                            <Translation>
                              {t=>
                              <button className="btn btn-primary add">
                                {t("guarantee_money_received:Print guarantee receipt")}
                              </button>}
                            </Translation>
                          </Link>
                        )}
                      </div>
                    </div>

                    <Modal show={this.state.showModal} dialogClassName="modal-approve" id="modal" >
                        <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                          <div > 
                            <h3 className="pt-2 pb-2">{i18next.t("editAddress:Edit customer name-surname / address")}</h3>

                            <form onSubmit={this.submit}>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label htmlFor="name">{i18next.t("editAddress:Name")}</label>
                                <input type="text" className="form-control" id="firstName" name="clearGuaranteeNameAddress.firstName" 
                                  value={this.state.clearGuaranteeNameAddress.firstName}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="surname">{i18next.t("editAddress:Surname")}</label>
                                <input type="text" className="form-control" id="lastName" name="clearGuaranteeNameAddress.lastName" 
                                  value={this.state.clearGuaranteeNameAddress.lastName}
                                onChange={this.handleInputChange}/>
                              </div>
                            </div>
                              <div className="form-group">
                                <label htmlFor="taxIdNumber">{i18next.t("editAddress:Tax payer identification/Identification number")}</label>
                                <input type="text" className="form-control" id="taxIdNumber" name="clearGuaranteeNameAddress.taxIdNumber" value={this.state?.clearGuaranteeNameAddress?.taxIdNumber?.replace('--', '')}
                                onChange={this.handleInputChange}/>
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputAddress2">{i18next.t("editAddress:Address")}</label>
                                <textarea cols="" rows="3" className="form-control" id="address"
                                name="clearGuaranteeNameAddress.address" maxLength={256} value={this.state.clearGuaranteeNameAddress.address} onChange={this.handleInputChange}/>
                              </div>
                              <div className="form-row">
                              <div className="form-group col-md-6">
                                  <label htmlFor="city">{i18next.t("editAddress:Sub-district")}</label>
                                  <InputAddress
                                      address="clearGuaranteeNameAddress.registeredDistrict"
                                      value={this.state.clearGuaranteeNameAddress.registeredDistrict}
                                      onChange={this.handleInputChange}
                                      onSelect={this.onSelect}
                                      mailing={false}
                                      className="form-control"    
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="city">{i18next.t("editAddress:District")}</label>
                                  <InputAddress
                                      address="clearGuaranteeNameAddress.registeredCity"
                                      value={this.state.clearGuaranteeNameAddress.registeredCity}
                                      onChange={this.handleInputChange}
                                      onSelect={this.onSelect}
                                      mailing={false}
                                      className="form-control"    
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="inputState">{i18next.t("editAddress:Province")}</label>
                                  <InputAddress
                                      address="clearGuaranteeNameAddress.registeredProvince"
                                      value={this.state.clearGuaranteeNameAddress.registeredProvince}
                                      onChange={this.handleInputChange}
                                      onSelect={this.onSelect}
                                      mailing={false}
                                      className="form-control"    
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="inputZip">{i18next.t("editAddress:Zip code")}</label>
                                  <InputAddress
                                      address="clearGuaranteeNameAddress.registeredPostalCode"
                                      value={this.state.clearGuaranteeNameAddress.registeredPostalCode}
                                      onChange={this.handleInputChange}
                                      onSelect={this.onSelect}
                                      mailing={false}
                                      className="form-control"    
                                  />
                                </div>
                              </div>
      
                              <div className="col-12  mt-3 text-right">
                            
                              <button type="button" className="btn btn-danger mr-2" onClick={() => this.setState({showModal : false})}>
                                  <span>{i18next.t("editAddress:Cancel")}</span>
                              </button>
                              <button
                                    className="btn btn-primary"
                                    disabled={this.state.loading} type="submit"
                                  >
                                    {i18next.t("editAddress:Print")}{localStorage.getItem("language") === "th" && "ใบค้ำประกัน"}
                              </button>
                            </div>
                         </form>
                          </div>
                        </Modal.Body>
                    </Modal>

                    <div className="card mt-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <p>
                              <Translation>{t=><strong>{t("guarantee_money_received:Customer")}</strong>}</Translation>
                            </p>
                            <p>
                              {props.guaranteeMoneyReceived.contact.refNumber}{" "}
                              {props.guaranteeMoneyReceived.contact.typeOfContact !== 'SUPPLIER' ?

                              props.guaranteeMoneyReceived.firstName !== "" ||
                              props.guaranteeMoneyReceived.lastName !== "" ? getNameResidential(
                                    props.guaranteeMoneyReceived.firstName,
                                    props.guaranteeMoneyReceived.lastName
                                  )
                                : (props.guaranteeMoneyReceived.contact.firstName !== '' || props.guaranteeMoneyReceived.contact.lastName !== '' ) ? 
                                getNameResidential(props.guaranteeMoneyReceived.contact.firstName, props.guaranteeMoneyReceived.contact.lastName) : props.guaranteeMoneyReceived.contact.name : props.guaranteeMoneyReceived.name }

                              {<span style={{color: '#F43853'}}>
                                    {" "}{props.guaranteeMoneyReceived.payGroup === "RENT" ? `(${i18next.t("AgentRole:Renter")})` : 
                                        props.guaranteeMoneyReceived.payGroup === "AGENT" ? `(${i18next.t("AgentRole:Agent")})`:
                                          ""
                                    }
                              </span>}  
                            </p>

                          </div>
                          <div className="col">
                            <p>
                              <Translation>{t=><strong>{t("guarantee_money_received:No.")}</strong>}</Translation>
                            </p>
                            <p>{props.guaranteeMoneyReceived.docNumber} </p>
                          </div>
                          <div className="col">
                            <p>
                              <Translation>{t=><strong>{t("guarantee_money_received:Date of issue")}</strong>}</Translation>
                            </p>
                            <p>
                              {format(
                                props.guaranteeMoneyReceived.issuedDate,
                                "DD/MM/YYYY",
                                { locale: thLocale }
                              )}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col">
                            <p>
                              <Translation>{t=><strong>{t("guarantee_money_received:Address")}</strong>}</Translation>
                            </p>
                            <p>
                              {
                                (props.guaranteeMoneyReceived?.address || props.guaranteeMoneyReceived.contact.registeredAddress) &&
                                  <AddressDataShow
                                      from={true}
                                      address={
                                        props.guaranteeMoneyReceived?.address ?
                                              {
                                                  address: props.guaranteeMoneyReceived.address,
                                                  district: props.guaranteeMoneyReceived.district,
                                                  city: props.guaranteeMoneyReceived.city,
                                                  province: props.guaranteeMoneyReceived.province,
                                                  postalCode: props.guaranteeMoneyReceived.postalCode,
                                              } :
                                              {
                                                  address: props.guaranteeMoneyReceived.contact.registeredAddress,
                                                  district: props.guaranteeMoneyReceived.contact.registeredDistrict,
                                                  city: props.guaranteeMoneyReceived.contact.registeredCity,
                                                  province: props.guaranteeMoneyReceived.contact.registeredProvince,
                                                  postalCode: props.guaranteeMoneyReceived.contact.registeredPostalCode,
                                                  registeredCountry: props.guaranteeMoneyReceived.contact.registeredCountry,
                                              }
                                      }
                                  
                                  />
                              }
                            </p>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col">
                            <div className="mt-3">
                              <div className="table-responsive fade-up">
                                <table className="table table-hover mt-2">
                                  <thead className="thead-light">
                                    <Translation>
                                      {t=>
                                      <tr>
                                        <th width="80">{t("guarantee_money_received:List")}</th>
                                        <th width="200">{t("guarantee_money_received:Description")}</th>
                                        <th width="80" className="text-right">
                                          {t("guarantee_money_received:Amounts")}
                                        </th>
                                      </tr>}
                                    </Translation>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {props.guaranteeMoneyReceived
                                          .chartOfAccount.chartOfAccountCode +
                                          " " +
                                          props.guaranteeMoneyReceived
                                            .chartOfAccount.name}
                                      </td>
                                      <td>
                                        {
                                          props.guaranteeMoneyReceived
                                            .description
                                        }
                                      </td>
                                      <td className="text-right">
                                        {numberWithComma(
                                          props.guaranteeMoneyReceived.amount
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {props.guaranteeMoneyReceived.status !== "VOID" ? (
                          <PayChannel
                            total={props.guaranteeMoneyReceived.amount}
                            bankTransaction={
                              props.guaranteeMoneyReceived
                                .bankAccountTransaction.edges
                            }
                            cashTransaction={
                              props.guaranteeMoneyReceived
                                .cashDepositReceiveTransaction.edges
                            }
                            chequeTransaction={
                              props.guaranteeMoneyReceived.chequeDeposit.edges
                            }
                            unknownReceive={
                              props.guaranteeMoneyReceived.unknownReceive
                            }
                            account_type="receive"
                          />
                        ) : (
                          <div className="card mt-3">
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <Translation>{t=><strong>{t("guarantee_money_received:Reason for cancellation")}</strong>}</Translation>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col">
                                  {props.guaranteeMoneyReceived.voidRemark}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <label htmlFor="note">
                          <Translation>{t=><strong>{t("guarantee_money_received:Note")}</strong>}</Translation>
                        </label>
                        <textarea
                          className="form-control col-4"
                          rows="4"
                          name="remark"
                          value={props.guaranteeMoneyReceived.note}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
              return <Loading/>
            }}
          />
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ReceiptDepositView;
