import getApiRoot from "../../libs/getAPIRoot";
import { format } from "date-fns";
import axios from "axios";

async function getUnitReport(token,start_date,end_date,search,indexFirst = '',indexLast = ''){
    let startDate = start_date === '' ? '' : format(start_date,'YYYY-MM-DD')
    let endDate = end_date === '' ? '' : format(end_date,'YYYY-MM-DD')
    return axios.get(`${getApiRoot()}ownership/unitreport`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            search: search,
            startDate : startDate,
            endDate : endDate,
            indexFirst : indexFirst,
            indexLast : indexLast
        },
    });
}

export default {getUnitReport}