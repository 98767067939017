import React, { Component } from 'react';
import { Translation } from 'react-i18next';

class AgentAddressDetail extends Component {

    render() {
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="row">
                            <div className="col">
                                <h6>{t("residential:no.")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.agentHomeNumber}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="agentHomeNumber" />
                            </div>
                            <div className="col">
                                <h6>{t("residential:Village/Building")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.agentVillageBuilding}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="agentVillageBuilding" />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Moo")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.agentVillageNumber}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="agentVillageNumber" />
                            </div>

                            <div className="col">
                                <h6>{t("residential:Soi")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.agentSoi}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="agentSoi" />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-6">
                                <h6>{t("residential:Road")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.agentLane}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="agentLane" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Sub-district")}</h6>
                                {/*<select className="custom-select">*/}
                                {/*    <option>-- ระบุแขวง --</option>*/}
                                {/*</select>*/}
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.agentSubDistrict}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="agentSubDistrict" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:District")}</h6>
                                {/*<select className="custom-select">*/}
                                {/*    <option>-- ระบุเขต --</option>*/}
                                {/*</select>*/}
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.agentDistrict}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="agentDistrict" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Province")}</h6>
                                {/*<select className="custom-select">*/}
                                {/*    <option>-- ระบุจังหวัด --</option>*/}
                                {/*</select>*/}
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.agentProvince}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="agentProvince" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Zip code")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.agentPostalCode}
                                    onChange={this.props.handleChangeInputAddress}
                                    maxLength="5"
                                    name="agentPostalCode" />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default AgentAddressDetail; 