/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type dataFilterQueryVariables = {|
  service: string,
  contact: string,
  car_type?: ?string,
  contacts?: ?$ReadOnlyArray<?string>,
|};
export type dataFilterQueryResponse = {|
  +periodServicePricing: ?{|
    +unit: ?number,
    +unitPrice: ?number,
    +totalPrice: ?number,
    +description: ?string,
  |},
  +allCars: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +licencePlate: string,
      |}
    |}>
  |},
|};
export type dataFilterQuery = {|
  variables: dataFilterQueryVariables,
  response: dataFilterQueryResponse,
|};
*/


/*
query dataFilterQuery(
  $service: String!
  $contact: String!
  $car_type: String
  $contacts: [String]
) {
  periodServicePricing(service: $service, contact: $contact) {
    unit
    unitPrice
    totalPrice
    description
  }
  allCars(contactList: $contacts, chargedFee: "true", carType: $car_type) {
    edges {
      node {
        id
        licencePlate
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "car_type"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contacts"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "service"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contact",
        "variableName": "contact"
      },
      {
        "kind": "Variable",
        "name": "service",
        "variableName": "service"
      }
    ],
    "concreteType": "PeriodServicePricing",
    "kind": "LinkedField",
    "name": "periodServicePricing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unitPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "carType",
        "variableName": "car_type"
      },
      {
        "kind": "Literal",
        "name": "chargedFee",
        "value": "true"
      },
      {
        "kind": "Variable",
        "name": "contactList",
        "variableName": "contacts"
      }
    ],
    "concreteType": "CarNodeConnection",
    "kind": "LinkedField",
    "name": "allCars",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CarNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CarNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "licencePlate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "dataFilterQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "dataFilterQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "e2260aa3f2005a5721079898adf45194",
    "id": null,
    "metadata": {},
    "name": "dataFilterQuery",
    "operationKind": "query",
    "text": "query dataFilterQuery(\n  $service: String!\n  $contact: String!\n  $car_type: String\n  $contacts: [String]\n) {\n  periodServicePricing(service: $service, contact: $contact) {\n    unit\n    unitPrice\n    totalPrice\n    description\n  }\n  allCars(contactList: $contacts, chargedFee: \"true\", carType: $car_type) {\n    edges {\n      node {\n        id\n        licencePlate\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '543c543fdcefa3e39c10d56aa5398d94';

module.exports = node;
