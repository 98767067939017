import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import numberWithCommas from '../../libs/numberWithComma';
import { Translation } from "react-i18next";
import './collectionLawFirm.scss'


const CollectionLawFrimInvoiceTable = (props) => {
    const [detaTable, setDataTable] = useState(props?.data ?? [])

    useEffect(() => {
        setDataTable(props?.data)
    }, [props?.data])

    let total = 0
    let paid = 0
    let after_paid = 0
    return (
        <Translation>
        {(t) =>
        <div className="pl-4 mt-4" id="collection-letter">
            <div className="row col-12 mb-3" style={{ marginTop: '48px' }}>
                <h4>{t('collectionLawFirm:Total outstanding debt')}</h4>
                <hr />
            </div>
            <div className="row mt-3 col-12">
                <div className="table-responsive">
                    <table className="table table-collection">
                        <thead className="thead-light headTable">
                            <tr style={{ fontSize: '16px' }}>
                                <th className="text-center" width={100}> {t('collectionLawFirm:No.')}</th>
                                <th className="text-center"> {t('collectionLawFirm:Invoice Number')}</th>
                                <th>{t('PageDetailProjectInfo:Lists')}</th>
                                <th>{t('newBookingSingha_FacilityList:Description')}</th>
                                <th className="text-center">{t('invoice:due_date')}</th>
                                <th className="text-center">{t('invoice:overdue_day')}</th>
                                { props.status !== 'partial_payment' && <th className="text-center" width={140}>{t('collectionLawFirm:Overdue (Baht)')}</th>}

                                { props.status === 'partial_payment' && <th className="text-center" width={140}>{t('collectionLawFirm:Overdue (Baht)')}</th>}
                                { props.status === 'partial_payment' && <th className="text-center" width={140}>{t('collectionLawFirm:Paid (Baht)')}</th>}
                                { props.status === 'partial_payment' && <th className="text-center" width={140}>{t('collectionLawFirm:Balance (Baht)')}</th>}
                            </tr>
                        </thead>
                        <tbody className="tableList">
                            {
                                detaTable?.map((item, index) => {
                                    let dateDiff = (new Date(props?.date).getTime() - new Date(item?.node?.transaction?.invoice?.dueDate).getTime()) / (1000 * 60 * 60 * 24)
                                    if(props.status === 'partial_payment'){
                                        total = total + item.node.sumPaidBeforeLegalclaim
                                        paid = paid + (item.node.sumPaidBeforeLegalclaim - item.node.sumPaidAfterLegalclaim)
                                        after_paid = after_paid + (item.node.sumPaidBeforeLegalclaim - (item.node.sumPaidBeforeLegalclaim - item.node.sumPaidAfterLegalclaim))
                                    }
                                    
                                    return (
                                        <tr key={"dataTableCollecttionLawFrimInvoice" + index} className='fs-16 fw-300'>
                                            <td className="text-center">{index + 1}</td>
                                            <td className="text-center text_1567B4">
                                                <Link to={"/accounting/income/invoice/invoice-detail/" + item.node.transaction.invoice.id}> {item.node.transaction.invoice.docNumber}</Link>

                                            </td>
                                            <td>{item.node.transaction.productAndService.name}</td>
                                            <td>{item.node.transaction.description}</td>
                                            <td className="text-center">{format(item.node.transaction.invoice.dueDate, 'DD/MM/YYYY')}</td>
                                            <td className="text-center text_CA0824 ">{Math.round(dateDiff)}</td>
                                            
                                            {props.status !== 'partial_payment' && <td className="text-right">{numberWithCommas(item.node.sumPaidBeforeLegalclaim, 0)}</td>}

                                            {props.status === 'partial_payment' && <td className="text-right">{numberWithCommas(item.node.sumPaidBeforeLegalclaim, 0)}</td>}
                                            {props.status === 'partial_payment' && <td className="text-right">{numberWithCommas(item.node.sumPaidBeforeLegalclaim - item.node.sumPaidAfterLegalclaim, 0)}</td>}
                                            {props.status === 'partial_payment' && <td className="text-right">{numberWithCommas(item.node.sumPaidBeforeLegalclaim - (item.node.sumPaidBeforeLegalclaim - item.node.sumPaidAfterLegalclaim) , 0)}</td>}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        {props.status === 'partial_payment' ?
                            <tfoot className="tableList">
                                <tr>
                                    <td className='fs-24 fw-400 text_1567B4' colSpan={6}>{t('collectionLawFirm:Total outstanding debt')}</td>
                                    <td className="fs-24 fw-400 text-right text_1567B4">{numberWithCommas(total ?? 0)}</td>
                                    <td className="fs-24 fw-400 text-right text_1567B4">{numberWithCommas(paid ?? 0)}</td>
                                    <td className="fs-24 fw-400 text-right text_1567B4">{numberWithCommas(after_paid ?? 0)}</td>
                                </tr>
                            </tfoot> :
                            <tfoot className="tableList">
                                <tr>
                                    <td className='fs-24 fw-400 text_1567B4' colSpan={6}>{t('collectionLawFirm:Total outstanding debt')}</td>
                                    <td className="fs-24 fw-400 text-right text_1567B4">{numberWithCommas(props.sumTotal ?? 0)}</td>
                                </tr>
                            </tfoot>
                        }
                    </table>
                </div>
            </div>
        </div>
        }
        </Translation>


    );
}
export default CollectionLawFrimInvoiceTable;