/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type foreignOwnershipDocumentQueryVariables = {||};
export type foreignOwnershipDocumentQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +provinceProject: ?string,
    +typeOfProject: ?ProjectTypeOfProject,
  |},
  +allJuristic: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +firstName: string,
        +lastName: string,
        +role: string,
        +image: ?string,
        +user: ?{|
          +id: string,
          +username: string,
          +lastLogin: ?any,
        |},
      |}
    |}>,
  |},
  +allDocumentationForeignOwnership: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +subject: string,
        +dearYou: string,
        +descriptionSubpage1: ?string,
        +descriptionSubpage2: ?string,
        +remark: ?string,
        +juristicNoUse: boolean,
        +juristicSignatureUse: boolean,
        +signerName: ?string,
        +signerRole: ?string,
        +juristic: ?{|
          +id: string,
          +firstName: string,
          +lastName: string,
          +role: string,
        |},
      |}
    |}>,
  |},
|};
export type foreignOwnershipDocumentQuery = {|
  variables: foreignOwnershipDocumentQueryVariables,
  response: foreignOwnershipDocumentQueryResponse,
|};
*/


/*
query foreignOwnershipDocumentQuery {
  selfProject {
    id
    name
    provinceProject
    typeOfProject
  }
  allJuristic {
    totalCount
    edges {
      node {
        id
        firstName
        lastName
        role
        image
        user {
          id
          username
          lastLogin
        }
      }
    }
  }
  allDocumentationForeignOwnership {
    totalCount
    edges {
      node {
        id
        subject
        dearYou
        descriptionSubpage1
        descriptionSubpage2
        remark
        juristicNoUse
        juristicSignatureUse
        signerName
        signerRole
        juristic {
          id
          firstName
          lastName
          role
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provinceProject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfProject",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "JuristicNodeConnection",
    "kind": "LinkedField",
    "name": "allJuristic",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastLogin",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DocumentationForeignOwnershipNodeConnection",
    "kind": "LinkedField",
    "name": "allDocumentationForeignOwnership",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentationForeignOwnershipNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentationForeignOwnershipNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subject",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dearYou",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "descriptionSubpage1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "descriptionSubpage2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remark",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "juristicNoUse",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "juristicSignatureUse",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signerRole",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JuristicNode",
                "kind": "LinkedField",
                "name": "juristic",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "foreignOwnershipDocumentQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "foreignOwnershipDocumentQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "f0335e3be7a15b5866d601a997940ec2",
    "id": null,
    "metadata": {},
    "name": "foreignOwnershipDocumentQuery",
    "operationKind": "query",
    "text": "query foreignOwnershipDocumentQuery {\n  selfProject {\n    id\n    name\n    provinceProject\n    typeOfProject\n  }\n  allJuristic {\n    totalCount\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        role\n        image\n        user {\n          id\n          username\n          lastLogin\n        }\n      }\n    }\n  }\n  allDocumentationForeignOwnership {\n    totalCount\n    edges {\n      node {\n        id\n        subject\n        dearYou\n        descriptionSubpage1\n        descriptionSubpage2\n        remark\n        juristicNoUse\n        juristicSignatureUse\n        signerName\n        signerRole\n        juristic {\n          id\n          firstName\n          lastName\n          role\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '68a26e1f5425bd02575ebce0d6fe9d0d';

module.exports = node;
