import React, { Component } from 'react';
import '../styles/main.scss';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import $ from "jquery";
import { Helmet } from "react-helmet";


const surveyQuery = graphql`
    query surveyAllQuery($id: ID!, $residential_vote_id_in: [String]!) {
        allResidentialVoteWeb(surveyId: $id, id_In: $residential_vote_id_in){
            edges {
                node {
                    residential{
                        residentialHouseholder{
                            edges{
                                node{
                                    type
                                    tenant{
                                        firstName
                                        lastName
                                    }
                                }
                            }
                        }
                        size
                        name
                    }
                    id
                    survey {
                        id
                        title
                        detail
                        status
                        surveyQuestion {
                            edges {
                                node {
                                    id
                                    title
                                    type
                                    questionChoice {
                                        edges {
                                            node {
                                                id
                                                title
                                                voteSet {
                                                    edges {
                                                        node {
                                                            id
                                                            residentialVote {
                                                                id
                                                            }
                                                            choice {
                                                                id
                                                                title
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        residentialSizeAll{
            sizeAll
        }
    }
`;

class SurveyAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disable_edit_vote: true,
            loading: false,
            size_all: false,
            residential_vote_list: [],
        };
    }

    componentDidMount() {

        this.init();
        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 50;
            let diff = page_height - (head + detail + signature);
            $('tbody tr:last-child td').css({ paddingBottom: diff });
        }, 200);
    }

    init() {
        if (this.props.match.params.id) {
            fetchQuery(environment, surveyQuery, { id: this.props.match.params.id, residential_vote_id_in: this.props.location.state.checkList }).then((data) => {
                if (data.allResidentialVoteWeb.edges.length > 0) {
                    this.setState({
                        size_all: data.residentialSizeAll.sizeAll,
                        residential_vote_list: data.allResidentialVoteWeb.edges,
                    });
                }
            });
        }
    }

    check(choice, residential_vote_id) {
        let boolean = false;
        choice.node.voteSet.edges.forEach((vote) => {
            if (vote.node.residentialVote.id === residential_vote_id) {
                boolean = true;
            }
        });
        return boolean
    }

    typeInput(question) {
        return question.node.type === "RADIO" ? "radio" : "checkbox"
    }

    print() {
        window.print();
    }

    render() {
        let count_recorde = 0;
        return (
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>Survey all</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>

                {this.state.residential_vote_list.map((residential_vote, index) => {
                    count_recorde = 0;
                    let keep_next = {};
                    let name_rundom = Math.random().toString(36).substring(7);
                    let pang_next = 0
                    let pang_keep = 0
                    let under_pang = [];
                    return (
                        <React.Fragment key={residential_vote.node.id}>
                            <div className="print-page-a4" key={residential_vote.node.id}>
                                <div className="subpage">

                                    <div className="head">
                                        <h4 className="overflow-wrap" style={{ border: '1px solid', padding: "10px" }}>แบบสอบถาม
                                    : {residential_vote.node.survey.title}</h4>
                                        <div><span className="title-into">ชื่อเจ้าของกรรมสิทธิ์/Name House-Owner :</span>
                                    {residential_vote.node.residential.residentialHouseholder.edges.map((house_holder) =>
                                            house_holder.node.type === "OWNER" &&
                                            ' ' + house_holder.node.tenant.firstName + ' ' + house_holder.node.tenant.lastName
                                        )}
                                        </div>
                                        <div><span className="title-into">บ้านเลขที่/Unit(House) No.</span>
                                    {'   ' + residential_vote.node.residential.name} <span className="title-into">อัตราส่วนค่าใช้จ่าย/คะแนนเสียง : </span> {(parseFloat(residential_vote.node.residential.size) / this.state.size_all).toFixed(2)}
                                        </div>
                                        <div><span className="title-into">จํานวนอัตราส่วนกรรมสิทธิ์/Right to common assets :</span> {parseFloat(residential_vote.node.residential.size)}
                                        </div>

                                        <div><span className="title-into">รายละเอียด/description : </span><div> </div>
                                            <span className="title-description">{residential_vote.node.survey.detail.replaceAll(/<[^>]+>/g, '').replaceAll('&nbsp;', ' ')}</span>
                                        </div>
                                    </div>

                                    <div className="detail">
                                        <div className="customer-detail-vote">
                                            {residential_vote.node.survey.surveyQuestion.edges.map((question, index_question) => {
                                                count_recorde = count_recorde + 1 + question.node.questionChoice.edges.length
                                                if (count_recorde < 40) {
                                                    return (
                                                        <React.Fragment key={question.node.id}>
                                                            <div style={{ marginTop: "2px",fontWeight: 'bold' }}>
                                                                    {index_question + 1}{". " + question.node.title}
                                                            </div>
                                                            {question.node.questionChoice.edges.map((choice) =>
                                                                <div style={{ marginTop: "1px" }} key={choice.node.id}>
                                                                    <input
                                                                        type={this.typeInput(question)}
                                                                        checked={this.check(choice, residential_vote.node.id)}
                                                                        disabled={this.state.disable_edit_vote}
                                                                    />
                                                                    <span style={{ marginLeft: "2%" }}>{choice.node.title}</span>
                                                                </div>
                                                            )}
                                                        </React.Fragment>
                                                    )
                                                }
                                                else {
                                                    if (Object.keys(keep_next).length === 0){
                                                        keep_next['key' + name_rundom] = [];
                                                        keep_next['key' + name_rundom].push(question)
                                                    }
                                                    else {
                                                        if (keep_next['key' + name_rundom].length < 10)
                                                            keep_next['key' + name_rundom].push(question)
                                                        else {
                                                            name_rundom = Math.random().toString(36).substring(7);
                                                            keep_next['key' + name_rundom] = [];
                                                            keep_next['key' + name_rundom].push(question)
                                                        }
                                                    }
                                                    if (pang_next === 0) pang_next = index_question;
                                                    return null
                                                }
                                            })
                                            }

                                        </div>
                                    </div>

                                    {Object.keys(keep_next).length === 0 &&
                                        <div className="footer">
                                            <div className="d-flex justify-content-end">
                                                <div className="right mt-5 text-center">
                                                    .................................................................<br />
                                        ลงชื่อ/Signed
                                        </div>
                                            </div>
                                        </div>}


                                </div>
                            </div>
                            {/* pang > dynamic  */}
                            {Object.keys(keep_next).length !== 0 && Object.entries(keep_next).map(([index, result], index_stack) => {
                                let show_array = []
                                if(pang_keep !== 0)pang_next = pang_keep;

                                if(under_pang.length !== 0){
                                    under_pang.forEach((node, index) => {
                                        show_array.push(node)
                                    })
                                    result.forEach((node,index) =>{
                                        show_array.push(node)
                                    })
                                }else{
                                    result.forEach((node,index) =>{
                                        show_array.push(node)
                                    })
                                }
                                count_recorde = 0
                                pang_keep = 0
                                under_pang = [];
                                return (
                                    <div className="print-page-a4" key={residential_vote.node.id + index_stack}>
                                        <div className="subpage">
                                            <div className="detail">
                                                <div className="customer-detail-vote">
                                                    {show_array.map((question, index_question) => {
                                                        count_recorde = count_recorde + 1 + question.node.questionChoice.edges.length
                                                        if(count_recorde < 45){
                                                            pang_keep = index_question + 1 + pang_next;
                                                        return (
                                                            <React.Fragment key={question.node.id + index_stack}>
                                                                <div style={{ marginTop: "2px",fontWeight: 'bold' }}>
                                                                    {index_question + 1 + pang_next}{". " + question.node.title}
                                                                </div>
                                                                {question.node.questionChoice.edges.map((choice) =>
                                                                    <div style={{ marginTop: "2px" }} key={choice.node.id + index_stack}>
                                                                        <input
                                                                            type={this.typeInput(question)}
                                                                            checked={this.check(choice, residential_vote.node.id)}
                                                                            disabled={this.state.disable_edit_vote}
                                                                        />
                                                                        <span style={{ marginLeft: "2%" }}>{choice.node.title}</span>
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                        )}else{
                                                            under_pang.push(question)
                                                            return null;
                                                        }
                                                    })
                                                    }

                                                </div>
                                            </div>
                                            
                                            { (Object.keys(keep_next).length-1) === index_stack &&
                                            <div className="footer">
                                                <div className="d-flex justify-content-end">
                                                    <div className="right mt-5 text-center">
                                                        .................................................................<br />
                                                        ลงชื่อ/Signed
                                                    </div>
                                                </div>
                                            </div>}


                                        </div>
                                    </div>)
                            })
                            }
                        ) //

                        </React.Fragment>
                    ) //this.state.residential_vote_list
                })}
            </React.Fragment>
        );
    }
}

export default SurveyAll;