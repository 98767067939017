const _ = require('lodash');
let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));

export default function getAccountingMenuData() {
    return {
        revenue: [
            {   
                name: 'invoice', 
                codename: 'accounting_invoice', 
                path: '/accounting/income/invoice/list/all',
                shortName: 'IV'
            },
            {
                name: 'receive',
                codename: 'accounting_receive',
                path: '/accounting/income/receive/list/all',
                shortName: 'RT'
            },
            {   name: 'credit_note', 
                codename: 'accounting_credit_note',
                path: '/accounting/income/credit-note/list/all',
                shortName: 'CN'
            },
            {
                name: 'receipt_deposit',
                codename: 'accounting_receipt_deposit',
                path: '/accounting/income/receipt_deposit/list/all',
                shortName: 'RD'
            },
            {
                name: 'guarantee_money_receive',
                codename: 'accounting_guarantee_receive',
                path: '/accounting/guarantee-money-received/list/received',
                shortName: 'GD/CGR'
            },
            {
                name: 'other_received',
                codename: 'accounting_other_receiver',
                path: '/accounting/income/other_receiver/list/all',
                shortName: 'OR'
            },
            {
                name: 'contract service',
                codename: 'accounting_contract_revenue',
                path: '/accounting/income/contract_service/list/all',
                shortName: 'CS'
            },
            {
                name: 'unknown receive',
                codename: 'accounting_unknown_receive',
                path: '/accounting/income/unknown-receive/list/all',
                shortName: 'UR'
            },
            {name: 'water meter record', codename: 'accounting_water_meter', path: '/accounting/water-meter/record'},
            {
                name: 'electric meter record',
                codename: 'accounting_electric_meter',
                path: '/accounting/electric-meter/record'
            },
            {name: 'fine', codename: 'accounting_fine', path: '/accounting/income/fine/list'},
            {
                name: 'cross_billing',
                codename: 'accounting_cross_billing_create',
                path: '/accounting/income/cross-billing-payment'
            },
        ],
        expense: [
            {
                name: 'purchase',
                codename: 'accounting_purchase',
                path: '/accounting/expense/purchase/list/all',
                shortName: 'PR'
            },
            {   name: 'pay', codename: 'accounting_pay', path: '/accounting/expense/pay/list/all',shortName: 'PP/PS'},
            {
                name: 'accept_credit_note',
                codename: 'accounting_accept_credit_note',
                path: '/accounting/expense/accept-credit-note/list/all',
                shortName: 'ACN'
            },
            {
                name: 'prepaid_deposit',
                codename: 'accounting_prepaid_deposit',
                path: '/accounting/expense/prepaid-deposit/list/all',
                shortName: 'PD'
            },
            {name: 'petty_cash', codename: 'accounting_petty_cash', path: '/accounting/expense/petty_cash/list/set',shortName: 'SPC/PC/PCP'},
            {name: 'advance', codename: 'accounting_advance', path: '/accounting/expense/advance/list/withdraw',shortName: 'AD/CAD'},
            {
                name: 'other_expense',
                codename: 'accounting_other_expense',
                path: '/accounting/expense/other_expense/list/all',
                shortName: 'OE'
            },
            {   name: 'contract_expense',
                codename: 'accounting_contract_expense', 
                path: '/accounting/expense/contract_expese/list/all',
                shortName: 'CX'
            },
        ],
        contact: [
            {name: 'all', codename: 'contact_residential', path: '/accounting/contact/all'},
            {
                name: _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ? 
                textData?.data?.accountingMenu.settingResidential:'residential_setup',
                codename: 'contact_residential',
                path: '/accounting/contact/residential/all'
            },
            {name: 'receivable_setup', codename: 'contact_receivable', path: '/accounting/contact/supplier/receivable'},
            {name: 'payable_setup', codename: 'contact_payable', path: '/accounting/contact/supplier/payable'},
        ],
        inventory: [
            {name: 'all', codename: 'accounting_product', path: '/accounting/inventory/product/list/all'},
            {name: 'products_setup', codename: 'accounting_product', path: '/accounting/inventory/product/list/product'},
            {
                name: 'services_setup',
                codename: 'accounting_service',
                path: '/accounting/inventory/product/list/service'
            },
            {
                name: 'expense_setup',
                codename: 'accounting_expense',
                path: '/accounting/inventory/product/list/expense'
            },
        ],
        finance: [
            {name: 'cash_deposit', codename: 'accounting_cash', path: '/accounting/finance/cash/list', shortName: 'CD'},
            {name: 'credit_card', codename: 'accounting_credit_card', path: '/accounting/finance/credit-card',shortName: 'CL'},
            {
                name: 'payment_gateway',
                // codename: 'accounting_payment_gateway',
                codename: 'accounting_credit_card',
                path: '/accounting/finance/payment-gateway',
                shortName: 'PG'
            },

            {name: 'bank_setup', codename: 'accounting_bank', path: '/accounting/finance/bank',shortName: 'BSV'},
            {name: 'bank_transfer', codename: 'accounting_bank_transfer', path: '/accounting/finance/bank-transfer',shortName: 'BT'},
            {
                name: 'deposit_interest',
                codename: 'accounting_bankunit',
                path: '/accounting/finance/deposit-interest/list/during'
            },
            {
                name: 'cheque',
                codename: 'accounting_cheque_deposit',
                path: '/accounting/finance/cheque-deposit/list/all',
                shortName: 'QR'
            },
            {name: 'cheque_transaction', codename: 'accounting_cheque', path: '/accounting/finance/cheque/list/all',shortName: 'QP'},
            {name: 'cheque_control', codename: 'accounting_chequecontrol', path: '/accounting/finance/cheque-control/list/all'},
            {name: 'tax', codename: 'accounting_tax', path: '/accounting/finance/tax/output-tax'},
            {
                name: 'withholding_tax',
                codename: 'accounting_withholdingTax',
                path: '/accounting/finance/withholdingTax/pnd-3',
                shortName: 'WTI'
            },
            // {
            //     name: 'withheld_tax',
            //     codename: 'accounting_withheldTax',
            //     path: '/accounting/finance/withheldTax/'
            // },
        ],
        journal: [
            {
                name: 'purchase',
                codename: 'daily_journal_purchase',
                path: '/accounting/daily-journal/purchase/list/all',
                shortName: 'RR'
            },
            {
                name: _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ? 
                textData?.data?.accountingMenu.sales :'sales',
                codename: 'daily_journal_sales',
                path: '/accounting/daily-journal/sales/list/all',
                shortName: 'SV'
            },
            {name: 'payment', codename: 'daily_journal_payment', path: '/accounting/daily-journal/payment/list/all',shortName: 'PV'},
            {name: 'receive', codename: 'daily_journal_receive', path: '/accounting/daily-journal/receive/list/all',shortName: 'RV'},
            {name: 'general', codename: 'daily_journal_general', path: '/accounting/daily-journal/general/list/all',shortName: 'JV'},
        ],
        report: [
            {name: 'debtor', codename: 'report_receivable', path: '/accounting/report/account-receivable'},
            {name: 'creditor', codename: 'report_payable', path: '/accounting/report/account-payable'},
            {name: 'asset', codename: 'report_asset', path: '/accounting/report/asset'},
            {name: 'finance', codename: 'report_financial', path: '/accounting/report/finance'},
            {name: 'slip verification', codename: 'slip_verify_log', path: '/accounting/report/slip/slip-verification'},
            {name: 'Icon Transfer Log', codename: 'Icon_Transfer_Log', path: '/accounting/report/log/log-transfer'},
            {name: 'Icon History Log', codename: 'Icon_History_Log', path: '/accounting/report/log/log-history'},
            {name: 'VMS Log', codename: 'vms_log', path: '/accounting/report/log/log-vms'},
            {name: 'Reparis Room Log', codename: 'log_repairs_room', path: '/accounting/report/log/log-repairs-room'}
        ],
        account: [
            {name: 'chart of accounting', codename: 'accounting_chart_of_account', path: '/accounting/chart-of-account/all'},
            {name: 'general ledger', codename: 'accounting_general_ledger', path: '/accounting/general-ledger'},
            {name: 'trial balance', codename: 'accounting_trial_balance', path: '/accounting/trial-balance'},
            {name: 'work sheet', codename: 'accounting_work_sheet', path: '/accounting/work-sheet'},
            //Beta : งบแสดงฐานะการเงิน งบรายรับ-รายจ่ายแบบสรุป งบรายรับ-รายจ่ายเกณฑ์คงค้าง งบรายรับ-รายจ่ายเกณฑ์เงินสด
            {name: 'balance sheet', codename: 'accounting_balance_sheet', path: '/accounting/balance-sheet'},
            {name: 'balance sheet account statements', codename: 'accounting_balance_sheet', path: '/accounting/balance-sheet-statement'},
            {
                name: 'summary of income expense',
                codename: 'accounting_summary_income_expense',
                path: '/accounting/summary-income-expense'
            },
            {
                name: 'accrued revenue expenditure',
                codename: 'accounting_accrued_revenue_expenditure',
                path: '/accounting/accrued-revenue-expenditure'
            },
            {
                name: 'cash flow statement',
                codename: 'accounting_cash_flow_statement',
                path: '/accounting/cash-flow-statement'
            },
            {name: 'budget', codename: 'accounting_budget', path: '/accounting/budget'},
            {name: 'asset', codename: 'accounting_asset', path: '/accounting/asset/all'},
        ],
        letter: [
            {
                name: 'Setting Automatic Debit',
                codename: 'accounting_settingautomaticdebit',
                // path: '/setting/collection/settings'
                path: '/accounting/collection/first'
            },
            {
                name: 'Collection Letters',
                codename: 'accounting_collectionletters',
                path: '/accounting/collection_letters/list/all',
                shortName: 'CLL'
            },
            {
                name: 'Setting Debs Free Certification',
                codename: 'accounting_settingdebsfreecertification',
                path: '/accounting/fee/settings'
            },
            {
                name: 'Request for Debt-Free Certification',
                codename: 'accounting_debfreecertificationrequest',
                path: '/accounting/debt_free_request/list/all',
                shortName: 'RDFC'
            },
            {
                name: 'Deb Free Certification',
                codename: 'accounting_debfreecertification',
                path: '/accounting/debt_free_certification/list/all',
                shortName: 'DFC'
            },
        ],
        collectionLetters: [
            {
                name: 'ติดตามทวงถามโดยทนายความ',
                codename: '/accounting/collection-law-firm/regis',
                path: '',
                shortName: 'CLF'
            },
            {
                name: 'ตั้งค่าติดตามทวงถามโดยทนายความ',
                codename: '',
                path: ''
            },
        ]
    }
};

