import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { addHours, format, subHours } from 'date-fns';
import thLocale from "date-fns/locale/th";
import _ from 'lodash';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Dropdown } from 'react-bootstrap';
import CountThaiConsonants from '../../../libs/countThaiLengthCar';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNewBold.ttf',
        italics: 'THSarabunNewItalic.ttf',
        bolditalics: 'THSarabunNewBoldItalic.ttf'
    },
    Sarabun: {
        normal: 'Sarabun-Light.ttf',
        bold: 'Sarabun-Bold.ttf',
        italics: 'Sarabun-Italic.ttf',
        bolditalics: 'Sarabun-BoldItalic.ttf'
    }
}

const ExportCommonAreaPDF = (state) => {
    const generatePdf = () => {
        let startItem = 0
        let dataMapExport = []
        _.map(state.dataExport, (item, indexItem, collection) => {
            startItem++
            let bookingNameCountChar = CountThaiConsonants(item.booking_detail.booking_name) > 30 ? item.booking_detail.booking_name.slice(0, 30) + "..." : item.booking_detail.booking_name

            return dataMapExport.push([
                { text: item.all_booking_bill[0].doc_number, style: 'tabletd_center' },
                { text: format(new Date(subHours(item.booking_detail.start_time, 7)), "DD/MM/YYYY"), style: 'tabletd_center' },
                { text: format(new Date(subHours(item.booking_detail.start_time, 7)), "HH:mm") + "-" + format(new Date(subHours(item.booking_detail.end_time, 7)), "HH:mm"), style: 'tabletd_center' },
                { text: bookingNameCountChar, style: 'tabletd_center' },
                { text: item.booking_detail.tel, style: 'tabletd_center' },
                { text: item.facility_detail.name, style: 'tabletd_center' },
                { text: item.booking_detail.use_right, style: 'tabletd_center' },
                { text: item.booking_detail.num_people, style: 'tabletd_center' },
                { text: item.booking_detail.booker_detail.name, style: 'tabletd_center' },
                { text: format(new Date(subHours(item.booking_detail.added, 7)), "DD/MM/YYYY HH:mm"), style: 'tabletd_center' },
                { text: item.booking_detail.status === "used" ? "ใช้ไปแล้ว" : item.booking_detail.status === "confirmed" ? "ยืนยันแล้ว" : "ยกเลิก", style: 'tabletd_center', },
            ]);
        })


        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape', //portrait ตั้ง  || landscape นอน
            pageMargins: 20,
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {
                            text: `${currentPage}/${pageCount}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [0, 0],
                        },
                        {
                            text: `ผู้พิมพ์ ${state.namePrint} วันที่พิมพ์ ${format(new Date(), "DD/MM/YYYY")}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [20, 0],
                        },
                    ],
                };
            },
            content: [
                { text: state.project_name, style: 'header' },
                { text: 'รายงานการจองพื้นที่ส่วนกลาง', style: 'header' },
                { text: format(new Date(), "ณ DD MMMM YYYY", { locale: thLocale }), style: 'header' },
                { text: "", margin: [0, 0, 0, 10] },
                {

                    table: {
                        headerRows: 1,
                        widths: [65, 60, 65, 80, 80, 80, 55, 55, 70, 60, 40],
                        heights: 20,
                        margin: [0, 0, 0, 30],
                        alignment: 'center',
                        dontBreakRows: true, // ใช้สำหรับ ให้แถวสุดท้ายที่ตกบรรทัดให้ ขึ้นหน้าใหม่แทน
                        body: [
                            [
                                { text: 'เลขที่การจอง', style: 'tableTh' },
                                { text: 'วันที่ใช้สิทธิ์', style: 'tableTh' },
                                { text: 'เวลาที่จองสิทธิ์', style: 'tableTh' },
                                { text: 'ชื่อผู้จอง', style: 'tableTh' },
                                { text: 'เบอร์ติดต่อ', style: 'tableTh' },
                                { text: 'พื้นที่ส่วนกลาง', style: 'tableTh' },
                                { text: 'สิทธิ์การจอง', style: 'tableTh' },
                                { text: 'จำนวนผู้เข้าใช้ บริการ (คน)', style: 'tableTh' },
                                { text: 'ผู้ทำรายการ', style: 'tableTh' },
                                { text: 'วัน/เวลา ทำรายการ', style: 'tableTh' },
                                { text: 'สถานะ', style: 'tableTh' },
                            ],
                            ...dataMapExport
                        ],
                    },
                    layout: 'auto',
                },
            ],
            defaultStyle: {
                font: 'Sarabun'
            },
            styles: {
                header: {
                    fontSize: 14,
                    bold: true
                },
                tableTh: {
                    alignment: 'center', bold: true, fontSize: 8
                },
                tabletd_center: {
                    alignment: 'center', fontSize: 8
                }
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBlob((blob) => {
            let preview = URL.createObjectURL(blob)
            window.open(preview)
        });
    };

    return (
        <Link
            to="route"
            target="blank"
            onClick={(event) => {
                event.preventDefault();
                generatePdf();
            }}>
            <Dropdown.Item>
                PDF
            </Dropdown.Item>
        </Link>
    );


}

export default ExportCommonAreaPDF;