import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import './menuSidebar.scss'
import SettingRole from './settingRole';
import PrivateRoute from '../../libs/privateRoute';
import noMatch from '../../components/noMatch';
import SettingRoleCreate from './settingRoleCreate';
class Role extends Component {
    render() {
        return (
            <Switch> 
                <PrivateRoute exact path={`${this.props.match.url}`} component={SettingRole}/>
                {/* BY ID */}
                {/* <PrivateRoute exact path={`${this.props.match.url}/:id/create`} component={SettingRoleCreateMain}/> */}

                {/* create */}
                <PrivateRoute exact path={`${this.props.match.url}/create`} component={SettingRoleCreate}/>

                <Route component={noMatch}/>
            </Switch>
        );
    }
}

export default Role;
