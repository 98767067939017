import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import "./style/stockMenagement.scss"

export default class stockManageTopmenuNavigation extends Component {

  render() {
    return (
      <div className="row" id="stockManagementTopmenuNavigation">
        <div className="col">
          <ul>
            <li>
              <NavLink to="/stockandsupplies/stockManagement/list/all" >
                {this.props.All}
              </NavLink>
            </li>
            <li>
              <NavLink to="/stockandsupplies/stockManagement/list/void" >
              {this.props.Cancled}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
