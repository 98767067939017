
import React, { Component } from 'react';
import API from '../../api/sing'
import Swal from 'sweetalert2';
import format from 'date-fns/format';
import _ from 'lodash'
import jwtDecode from 'jwt-decode'
import environment from "../../env/environment";
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import i18next from 'i18next';

const query = graphql`
query fixRequsetPDFQuery{
    selfProject{
        id
        name
        address
        logo
    }
}`
class fixRequestPDF extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            optionAction: false,
            data: [],
            dataReport: [],
            wroksheetView: [],
            allResidential: [],
            page: 1,
            pageAll: 0,
            pageSize: 100,
            totalCount: 0,
            index_first: 0,
            index_last: 100,
            startDate: "",
            endDate: "",
            printBy: "",
            jwtDecodes: "",
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            this.setState({
                projectName: res.selfProject.name,
            })    
        })
        this.getData(this.state.index_first, this.state.index_last);
        this.setState({
            jwtDecodes: jwtDecode(window.localStorage.getItem('token')),
                }, () => {
                  this.setState({
                      printBy: `${this.state.jwtDecodes.first_name} ${this.state.jwtDecodes.last_name}`,
                  });
                });
    }
    getData(index_f, index_l) {
        let filter = JSON.parse(localStorage.getItem("filterReportFixRequest"))
        let forParam = this.props.match.params.for
        let filterBy = forParam === "tenant" ? filter.searchForTenent :
            forParam === "common" ? filter.searchForCommon :
                forParam === "reporttenant" ? filter.searchForReportTenent : filter.searchForReportCommon
        let datas = {
            index_first: index_f,
            index_last: index_l,
            page: this.state.page,
            status: forParam === "tenant" || forParam === "common" ? this.props.match.params.tabName : '',
            working_type: filterBy?.searchWorkingType || "",
            id: "",
            person_working: filterBy?.searchTypePerson,
            search: filterBy?.searchBox,
            start_date: filterBy?.searchStartDate !== "" ? filterBy?.searchStartDate + "T00:00" : '',
            end_date: filterBy?.searchEndDate !== "" ? filterBy?.searchEndDate + "T23:59" : '',
            repair_type: forParam === "tenant" || forParam === "reporttenant" ? "private" : 'public',
            insure_status_choices:
                this.props.match.params.tabName === "all" ? "" :
                    this.props.match.params.tabName === "warranty" ? "have" :
                        this.props.match.params.tabName === "expired" ? "out" : "not",
            pageSize: this.state.pageSize
        }
        if (forParam === "tenant" || forParam === 'common') {
            API.getrepairs_room_view(datas).then((res) => {
                let data = []
                if (res.data.data) {
                    data = res.data.data
                    this.setState({
                        loading: false, dataReport: data,
                        totalCount: res.data.totalCount,
                        pageAll: res.data.pagesCount,
                        startDate: datas.start_date,
                        endDate: datas.end_date
                    })
                }
            }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error')).then(() => {
                this.setState({ loading: false })
            })
        } else {
            API.getReport(datas).then((res) => {
                let data = []
                if (res.data.data) {
                    data = res.data.data
                    this.setState({
                        loading: false, dataReport: data,
                        totalCount: res.data.totalCount,
                        pageAll: res.data.pagesCount,
                        startDate: datas.start_date,
                        endDate: datas.end_date
                    })
                }
            }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error')).then(() => {
                this.setState({ loading: false })
            })
        }

    }
    print() {
        window.print();
      }    

    render() {
        let forParam = this.props.match.params.for
        let forPages = forParam === "tenant" ? "ลูกบ้าน" : forParam === "common" ? "ส่วนกลาง" : forParam === "reporttenant" ? "ลูกบ้าน" : "ส่วนกลาง"
        const rowPerPage = 24;
        const chunks = _.chunk(this.state.dataReport, rowPerPage); 
        const allPage = chunks.length 

        return (
            <React.Fragment >
                        <div className="print-top-menu">
                            <div className="logo">
                                <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                            </div>
                            <div className="print" onClick={this.print}>
                                PRINT
                            </div>
                        </div>
                {
                    <div id="fixRequestPDF">
                        {chunks.map((data, index)=>(
                        <div className={"print-page-a4 "} key={"index-fixrequest"+index}>
                            <div className="subpage" >
                                {/* วนตรงนี้ */}
                                
                                <section className="body" key={index}>
                                    {index === 0 && 
                                        <div className="head">
                                        <h4>{this.state.projectName}</h4>    
                                        <h4>รายงาน{forParam === "common" || forParam == "tenant" ? "คำร้องงาน" : ""}แจ้งซ่อมของ{forPages}</h4>
                                        {
                                            forParam === "tenant" || forParam === "common" ?
                                                <h5>เรียงตามสถานะ -
                                                    {
                                                        this.props.match.params.tabName === "pending" ? " รอดำเนินการ" :
                                                            this.props.match.params.tabName === "approve" ? " อนุมัติค่าใช้จ่าย" :
                                                                this.props.match.params.tabName === "finish" ? " เสร็จสิ้น" : " ยกเลิก"
                                                    }
                                                </h5>
                                                :
                                                <h5>เรียงตามสถานะประกัน -
                                                    {
                                                        this.props.match.params.tabName === "all" ? " ทั้งหมด" :
                                                            this.props.match.params.tabName === "warranty" ? " มีประกัน" :
                                                                this.props.match.params.tabName === "expired" ? " หมดประกัน" : " ไม่มีประกัน"
                                                    }
                                                </h5>
                                        }
                                        <h5>
                                            เรียงตามวันที่  {this.state.startDate ? format(this.state.startDate, " DD/MM/YYYY ") : " - "}
                                            ถึง  {this.state.endDate ? format(this.state.endDate, " DD/MM/YYYY ") : " - "}
                                        </h5>
                                        </div>                                    
                                    }
                                    <table className="table ">
                                        {
                                            forParam === "tenant" || forParam === "common" ?
                                                <thead>
                                                    {
                                                        this.props.match.params.tabName !== "void" ?
                                                            <tr className='text-center bg-gray'>
                                                                <th>ลำดับ</th>
                                                                <th>ห้อง</th>
                                                                <th>หมวดงาน</th>
                                                                <th>ชนิดงาน /รายละเอียดงาน</th>
                                                                <th>พื้นที่งาน</th>
                                                                <th>ผู้รับผิดชอบ</th>
                                                                <th>ประเภท</th>
                                                                <th>เจ้าหน้าที่</th>
                                                                <th>ชื่อช่าง / ผู้รับเหมา</th>
                                                                <th>เบอร์โทรติดต่อ</th>
                                                                <th>วันที่ดำเนินการ</th>
                                                                <th>เวลา</th>
                                                            </tr>
                                                            :
                                                            <tr className='text-center bg-gray'>
                                                                <th>ลำดับ</th>
                                                                <th>ห้อง</th>
                                                                <th>หมวดงาน</th>
                                                                <th>เหตุผลที่ปฏิเสธงาน</th>
                                                                <th>พื้นที่งาน</th>
                                                                <th>ผู้รับผิดชอบ</th>
                                                            </tr>
                                                    }
                                                </thead>
                                                :
                                                <thead>
                                                    <tr className='text-center bg-gray'>
                                                        <th>ลำดับ</th>
                                                        <th style={{ width: '150px' }}>เลขทีเอกสาร</th>
                                                        <th>วันที่แจ้ง</th>
                                                        <th>หมวดหมู่</th>
                                                        <th style={{ width: '300px' }}>ชนิดงาน /รายละเอียดงาน</th>
                                                        <th>ผู้แจ้ง</th>
                                                        <th>ชื่อผู้แจ้ง</th>
                                                        <th>ชื่อช่าง/ผู้รับเหมา</th>
                                                        <th>วันหมดประกัน</th>
                                                        <th>สถานะการซ่อม</th>
                                                    </tr>
                                                </thead>
                                        }
                                        {
                                            forParam === "tenant" || forParam === "common" ?
                                                <tbody className="current">
                                                    {
                                                        data.length > 0 ?
                                                            data.map((n, index) => {
                                                                let person_working = n.person_working === "S-Homecare" ? "S-Homecare" : "S KLAS"
                                                                let workSheet = n.worksheet[0]
                                                                return (
                                                                    this.props.match.params.tabName !== "void" ?
                                                                        <tr key={index} className='text-center'>
                                                                            <td>{index + 1}</td>
                                                                            <td>{n.residential?.name || 'นิติบุคคล'}</td>
                                                                            <td>{n.working_type}</td>
                                                                            <td className='text-left'>{n.material + ":" + n.details}</td>
                                                                            <td>{n.repair_type === "private" ? "ส่วนตัว" : "ส่วนกลาง"}</td>
                                                                            <td>{person_working}</td>
                                                                            <td>{workSheet?.working_type === "repair" ? "ซ่อมแซม" : workSheet?.working_type === "improvement" ? "ต่อเติม" : "อื่นๆ"}</td>
                                                                            <td>{workSheet?.technician === "inside" ? 'ช่างในโครงการ' : "ช่างนอกโครงการ"}</td>
                                                                            <td>{
                                                                                workSheet?.job_responsed_by_staff_name ? workSheet.job_responsed_by_staff_name :
                                                                                    workSheet?.project_staff ?
                                                                                        workSheet?.project_staff.name_title + workSheet?.project_staff.name_staff + " " + workSheet?.project_staff.last_name_staff :
                                                                                        workSheet?.project_staff_other
                                                                            }</td>
                                                                            <td>{workSheet?.phone}</td>
                                                                            <td>{format(workSheet?.appointments, "DD/MM/YYYY")}</td>
                                                                            <td>{format(workSheet?.appointments, "HH:mm น.")}</td>
                                                                        </tr>
                                                                        :
                                                                        <tr key={index} className='text-center'>
                                                                            <td>{index + 1}</td>
                                                                            <td>{n.residential?.name}</td>
                                                                            <td>{n.working_type}</td>
                                                                            <td>{"-"}</td>
                                                                            <td>{n.repair_type === "private" ? "ส่วนตัว" : "ส่วนกลาง"}</td>
                                                                            <td>{person_working}</td>
                                                                        </tr>
                                                                        )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={12} className='text-center'>---ไม่มีข้อมูล---</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                                :
                                                <tbody className="current">
                                                    {
                                                        data.length > 0 ?
                                                            data.map((n, index) => {
                                                                let isStaff = n.tenant_contact?.substring(0, 2)
                                                                isStaff = isStaff === "Y-" ? true : false
                                                                return (
                                                                    <tr key={index} className='text-center'>
                                                                        <td>{index + 1}</td>
                                                                        <td>{n.doc_number}</td>
                                                                        <td>{format(n.added, "DD/MM/YYYY")}</td>
                                                                        <td>{n.working_type}</td>
                                                                        <td className='text-left'>{n.material + ":" + n.details}</td>
                                                                        <td className='text-left'>{n.room_number}</td>
                                                                        <td>{
                                                                            n.room_number === "นิติบุคคล" && isStaff ?
                                                                                n.juristic_contact : n.tenant_contact
                                                                        }</td>
                                                                        <td>{
                                                                            n.job_responsed_by_staff_name ? n.job_responsed_by_staff_name :
                                                                                n.engineer_contact ? n.engineer_contact : '-'
                                                                        }</td>
                                                                        <td>{n.insure_status !== "not" ? format(n.due_date, "DD/MM/YYYY") : "-"}</td>
                                                                        <td>
                                                                            {
                                                                                n.status === "finish" ?
                                                                                    "เสร็จสิ้น" : i18next.t("Allaction:cancel")
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={12} className='text-center'>---ไม่มีข้อมูล---</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                        }
                                        <tfoot>
                                        </tfoot>
                                    </table>
                                    <div className='footerTable'>
                                      <div className='row'>
                                          <div className='col-5' />
                                      <div className='col-2 text-center'>
                                          <p className='f14 pb-0 mb-0'>{index+1}/{allPage}</p>
                                      </div>
                                      <div className='col-5 text-right'>
                                        <p className='f14 pb-0 mb-0'>ผู้พิมพ์ {this.state.printBy} วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}</p>
                                      </div>
                                    </div>
                                    </div>
                                </section>                                    
                            </div>
                        </div>
                        
                        ))}
                    </div>
                }
            </React.Fragment>
        )
    }

}

export default fixRequestPDF






