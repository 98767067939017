import React, {Component} from "react";
import Swal from "sweetalert2";
// import './styles/uploadFileInput.scss'
import {Translation} from "react-i18next";
import i18n from 'i18next';
import i18next from "i18next";

class UploadFile extends Component {
    constructor(props) {
        super(props);
        this.handleInputFile = this.handleInputFile.bind(this)

    }

    handleInputFile(e) {
        let file = e.currentTarget.files[0];
        if (file) {
            // check gt 20 mb
            if (file?.size > 20971520) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), `${i18next.t("Allaction:Please upload a new file as it exceeds the specified size of")} 20 MB`, 'error')
                return
            }else if (file?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .xlsx ${i18next.t("Allaction:Only")}`, 'error')
            }
             else {
                this.props.handleInputDocument(e)
            }
        }
    }

    render() {
        let name = "document_upload";
        let id = "uploadFile";
        if (this.props.name) {
            name = this.props.name;
            id = this.props.name;
        }
        if (this.props.dataId || this.props.dataId === 0) {
            name = name + this.props.dataId;
            id = id + this.props.dataId;
        }


        return (
            <React.Fragment>
                {!this.props.disableHeaderText &&
                <div className=" text-right">

                    {/* <div className="tooltipsSpan"><img src={process.env.PUBLIC_URL + "/images/iconAlerts/iconInfo-Gray.png"} alt='iconInfoGray'/>
                           <span className='tooltiptext' >{i18n.t("createUpdateForm:Import watermeter first, before Import watermeterchange.")}</span>
                     </div> */}

                    <label htmlFor={id} className="mr-3">
                        <Translation>{t => <strong className="text-danger font-small">{t('createUpdateForm:Attach files XLSM size not over 20 MB')}</strong>}</Translation>
                    </label>

                    <a href="https://docs.google.com/spreadsheets/d/1pzgZTyA8VK_ZjTbaLMu58toXFHOsSLNSbq-JnqV7q8o/edit?usp=sharing" target="_blank" rel="noreferrer noopener">
                            <Translation>{t => <strong className=" font-small">{t('createUpdateForm:Form example')}</strong>}</Translation>
                    </a>

                </div>
                }

                <div className="custom-file">
                    <input type="file" className="custom-file-input"
                           id={id} name={name} data-key={this.props.dataKey} data-id={this.props.dataId}
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           onChange={this.handleInputFile}/>

                    <label className="custom-file-label text-document text-secondary " htmlFor={id}>
                        {
                            this.props.documentUpload &&
                            this.props.documentUpload.name.length >= 100 ?
                                this.props.documentUpload.name.substr(0, 100)
                                :
                                this.props.documentUpload.name
                        }
                    </label>
                </div>
            </React.Fragment>
        )
    }
}

export default UploadFile