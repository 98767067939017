import React, { Component } from "react";
import { Translation } from "react-i18next";
import { NavLink } from "react-router-dom";

class Navigation extends Component {
  render() {
    return (
      <div className="row" id="navigation-tab">
        <div className="col">
          <Translation>
            {(t) => (
              <ul>
                <li>
                  <NavLink exact={true} to="/contact/global-area-singha/booking/common-area/all">
                    {/* {t("parcel_list:All")} */}
                    {t("newBookingSingha_FacilityList:All items")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact/global-area-singha/booking/common-area/open">
                    {/* {t("parcel_list:Nor received")} */}
                    {t("newBookingSingha_FacilityList:Open")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact/global-area-singha/booking/common-area/no_booking">
                    {/* {t("parcel_list:Received success")} */}
                    {t("newBookingSingha_Create_step1:Without reservation")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact/global-area-singha/booking/common-area/temporarily_open">
                    {/* {t("parcel_list:Received success")} */}
                    {t("newBookingSingha_FacilityList:Temporarily reservation")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact/global-area-singha/booking/common-area/renovate">
                    {t("newBookingSingha_FacilityList:Under maintenance")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact/global-area-singha/booking/common-area/closed">
                    {t("newBookingSingha_FacilityList:Permanently closed")}
                  </NavLink>
                </li>
              </ul>
            )}
          </Translation>
        </div>
      </div>
    );
  }
}

export default Navigation;
