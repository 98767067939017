import React, {Component} from 'react';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import Wrapper from '../components/wrapper';
import WrapperContent from '../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import './fixRequest/styles/fixRequest.scss';
import 'react-responsive-ui/style.css';
import getWSRoot from '../libs/getWSRoot';
import Swal from "sweetalert2";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../env/environment";
import {commitMutation, QueryRenderer} from "react-relay";
import ChatDetail from "./chatDetail";
import ChatDetailHistory from "./chatDetailHistory";
import ChatBoxRender from "./chatBoxRender";
import getCurrentUser from "../libs/getCurrentUser";
import UploadImageInput from "../libs/uploadImageInput";
import _ from "lodash"
import { TextInput } from 'react-responsive-ui'
import { Translation } from "react-i18next";
import i18n from "i18next"
import Loading from "../libs/loading";

const mutation = graphql`
    mutation chatMutation($input: CreateMessageInput!){
        createMessage(input: $input){
            newMessage{
                id image
            }
        }
    }
`;

const query = graphql`
    query chatQuery($id: ID!) {
        room(id: $id) {
            id
            residential {
                name
            }
            name
            detail
            status
            image
            added
            note
            fixRequestRoom {
                id
                technician
            }

            handle {
                tenant {
                    firstName
                    lastName
                    phone
                    image
                }
                juristic {
                    firstName
                    lastName
                    phone
                    image
                }
            }
            messages {
                edges {
                    node {
                        id
                        updated
                        message
                        image
                        handle {
                            tenant {
                                image
                            }
                            juristic {
                                image
                            }
                        }
                    }
                }
            }
        }
    }
`;


let chatSocket = {};

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message_list: [],
            message: "",
            image: "",
            room_id:this.props.match.params.id,
            sending: false
        };

        this.sendMessage = this.sendMessage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleInputChangeText = this.handleInputChangeText.bind(this);
    }

    scrollToBottom() {
        let objDiv = document.getElementById("chat-box");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    componentWillMount() {

        // connect ws
        chatSocket = new WebSocket(getWSRoot() + '/chat/' + atob(this.props.match.params.id).split(':')[1] + '/', window.localStorage.getItem('token'));

        let _this = this;
        chatSocket.onmessage = function (e) {
            let data = JSON.parse(e.data);
            let message_list = [..._this.state.message_list];

            let update = false;
            message_list.forEach((message) => {
                if (data['message_id'] === message['message_id']) {
                    message['updated'] = data['updated'];
                    update = true;
                }
            });

            if (update) {
                // update updated time
                _this.setState({message_list: message_list}, () => {
                    _this.scrollToBottom();
                })
            } else {
                // receive from web socket
                _this.setState({message_list: [..._this.state.message_list, data]}, () => {
                    _this.scrollToBottom();
                })
            }
        };

        chatSocket.onclose = function (event) {
            if (event.code !== 1000 && event.code !== 1006) {
                Swal.fire({
                    title: i18n.t("fix_request:Chat system crashes"),
                    text: i18n.t("fix_request:connection_fail"),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: i18n.t("fix_request:Try again"),
                    cancelButtonText: i18n.t("fix_request:close")
                }).then((result) => {
                    if (result.value) {
                        window.location.reload();
                    }
                })
            }
        };
    }

    componentWillUnmount() {
        chatSocket.close();
    }

    async sendMessage() {
        this.setState({ sending: true })
        this.scrollToBottom();

        if (this.state.message.length > 0 || this.state.image) {
            let current_user = await getCurrentUser();

            let data = {
                message_id: Math.random().toString(20).substring(2),
                message: this.state.message,
                // image: this.state.image ? URL.createObjectURL(this.state.image) : '',
                image: '',
                role: 'juristic',
                status: 'send',
                profile: current_user.image,
                token: window.localStorage.getItem('token') || null
            };


            this.setState({message_list: [...this.state.message_list, data]}, () => {
                this.scrollToBottom();
            });

            let variables = {
                input: {
                    room: this.props.match.params.id,
                    message: this.state.message
                }
            };

            let uploadables = {
                image: this.state.image
            };

            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    uploadables,
                    onCompleted: (response) => {
                        _.set(data, "image", response.createMessage.newMessage.image);
                        chatSocket.send(JSON.stringify(data));
                        this.setState({message: '',image:'' ,sending: false});
                    },
                    onError: (err) => {
                         Swal.fire(i18n.t("fix_request:Failed to send"), i18n.t("fix_request:Try_again"), 'error');
                    },
                },
            );

        }else{
            Swal.fire({
                title: i18n.t("fix_request:แจ้งเตือน"),
                text: i18n.t("fix_request:กรุณาใส่ข้อความ"),
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: i18n.t("fix_request:ตกลง"),
            }).then((result) => {
                if (result.value) {
                    window.location.reload();
                }
            })
        }
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let type = event.target.type;
        let value = event.target.value;

        if (type === 'text') {
            value = value.replace(/\\/g, '');
        }

        this.setState({[event.target.name]: value}, async ()=>{
            if (type === 'file'){
                await this.sendMessage()
            }
        });
    }

    handleInputChangeText(value) {
        this.setState({message: value});
    }

    async handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            if(!this.state.sending){
                await this.sendMessage()
            }
        }
    }

    getLink(key) {
        let status = {
            'OPEN': 'open',
            'FINISHED': 'finished',
            'APPROVE': 'approve',
            'NOT_APPROVE': 'not-approve',
        };
        return "/contact/" + this.props.match.params.page + "/" + status[key]
    }
    
    loadHistory = (room_id) =>{
        this.setState({room_id})
    }

    render() {

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <Translation>
                        {t=>
                    <div className="container-fluid box" id="chat">
                        <div className="row">

                            <QueryRenderer
                                environment={environment}
                                query={query}
                                variables={{id: this.state.room_id}}
                                render={({error, props}) => {
                                    if (error) {
                                        return <div>{error.message}</div>;
                                    } else if (props) {
                                        return (
                                            <div className="col-md-7 col-xl-8">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-inline">
                                                            <Link to={this.getLink(props.room.status!=='NOT_APPROVE'?props.room.status:'OPEN')}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                    alt="back" className="back"/>
                                                            </Link>
                                                            <span className="ml-4 form-inline">
                                                                <div className="row">
                                                                    <div className="col-md-1">
                                                                        <img
                                                                            src={props.room.handle.tenant ? props.room.handle.tenant.image : props.room.handle.juristic.image}
                                                                            alt="sender-profile"
                                                                            className="rounded-circle avatar-img profile-img juristic"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    {props.room.handle.tenant !== null ?
                                                                        <React.Fragment>
                                                                            <h6>
                                                                                {props.room.handle.tenant.firstName} {props.room.handle.tenant.lastName}
                                                                            </h6>
                                                                            <span>{props.room.residential.name}</span>
                                                                        </React.Fragment>
                                                                         :
                                                                        <h6>
                                                                            {props.room.handle.juristic.firstName} {props.room.handle.juristic.lastName}
                                                                        </h6>
                                                                    }
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr/>

                                                <ChatBoxRender messagesQuery={props.room.messages.edges}
                                                               messagesWebSocket={this.state.message_list}
                                                               room={props.room}
                                                />


                                                {/*ช่องพิมพ์แชท*/}
                                                <hr className="chat-input"/>
                                                {
                                                    (props.room.status === 'OPEN' || props.room.status === 'APPROVE') && ((this.props.match.params.page === 'fix-request' ? _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_fix_request_edit'}) : _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_chat_edit'}))) &&
                                                    <div className="row">
                                                        <div className="col-md-11 col-11">
                                                            <div className="row" >
                                                                <div className="upload-file mt-3 ml-3" >
                                                                    <label htmlFor="uploadImage"  style={{marginTop:6}} >
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/select-photo-grey.png'}
                                                                            alt="select-grey"/>
                                                                    </label>
                                                                    <div className="upload-input">
                                                                        <UploadImageInput id="uploadImage"
                                                                                          setField="image"
                                                                                          onChange={this.handleInputChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                    <Translation>
                                                                        {t=>
                                                                        <TextInput
                                                                            inputStyle={{borderBottomWidth:0}}
                                                                            multiline
                                                                            label={t("fix_request:type your message")}
                                                                            value={this.state.message} 
                                                                            className="input-field"
                                                                            onChange={this.handleInputChangeText} 
                                                                            onKeyPress={this.handleKeyPress}
                                                                        />}
                                                                    </Translation>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 col-1">
                                                            <button
                                                                    disabled={this.state.sending}
                                                                    className="send"
                                                                    onClick={this.sendMessage}>
                                                                <Translation>{t=><h5 className="mt-2">{t("fix_request:send")}</h5>}</Translation>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }

                                                {/*ช่องพิมพ์แชท*/}

                                            </div>
                                        );
                                    }
                                    return <Loading/>
                                }}
                            />



                            <div className="col-md-5 col-xl-4">
                                {/*   <div className="row">
                                    <div className="col">
                                       <Link to="/contact/fix-request/schedule">
                                    <button type="button" className="btn btn-outline-primary add float-right">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/calendar-blue.png'}
                                             alt="calendar-blue"/>
                                        <Translation>
                                            {
                                                t => <span>{t('fix_request:mechanic_calendar')}</span>
                                            }
                                        </Translation>
                                    </button>
                                       </Link>
                                    </div>
                                </div>*/}

                                <div className="row mt-3">
                                    <div className="col">

                                        <ChatDetail id={this.props.match.params.id}
                                                    loadHistory = {this.loadHistory}
                                                    page={this.props.match.params.page}/>

                                        <ChatDetailHistory id={this.props.match.params.id}
                                                           page={this.props.match.params.page}
                                                           loadHistory = {this.loadHistory}
                                                           />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        }
                    </Translation>
                    {/*<div className="modal fade bd-new-tag-modal" tabIndex="-1" role="dialog"*/}
                    {/*     aria-labelledby="myLargeModalLabel" aria-hidden="true"*/}
                    {/*     ref={modal => this.modalReceive = modal}>*/}
                    {/*    <div className="modal-dialog" role="document">*/}
                    {/*        <div className="modal-content">*/}
                    {/*            <div className="modal-body p-4">*/}
                    {/*                <h4>สร้าง Tag ใหม่</h4>*/}

                    {/*                <div className="row mt-4">*/}
                    {/*                    <div className="col">*/}
                    {/*                        <input type="text" className="form-control" placeholder="ระบุชื่อ Tag"/>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*                <div className="row mt-4">*/}
                    {/*                    <div className="col">*/}
                    {/*                        <button type="submit" className="btn btn-primary bt-style form-update">*/}
                    {/*                            สร้าง Tag*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}

                    {/*                    <div className="col">*/}
                    {/*                        <button className="btn btn-secondary bt-style form-update"*/}
                    {/*                                data-dismiss="modal">*/}
                    {/*                            ยกเลิก*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default Chat;
