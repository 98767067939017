/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type genCodeA5QueryVariables = {|
  id?: ?string,
  status?: ?string,
|};
export type genCodeA5QueryResponse = {|
  +genInviteCode: ?$ReadOnlyArray<?string>,
  +selfProject: ?{|
    +juristicContactNumber: ?string
  |},
|};
export type genCodeA5Query = {|
  variables: genCodeA5QueryVariables,
  response: genCodeA5QueryResponse,
|};
*/


/*
query genCodeA5Query(
  $id: String
  $status: String
) {
  genInviteCode(residential: $id, status: $status)
  selfProject {
    juristicContactNumber
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "residential",
      "variableName": "id"
    },
    {
      "kind": "Variable",
      "name": "status",
      "variableName": "status"
    }
  ],
  "kind": "ScalarField",
  "name": "genInviteCode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "juristicContactNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "genCodeA5Query",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "genCodeA5Query",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "762eb7991a46d7fc2d1351f9539bc5d6",
    "id": null,
    "metadata": {},
    "name": "genCodeA5Query",
    "operationKind": "query",
    "text": "query genCodeA5Query(\n  $id: String\n  $status: String\n) {\n  genInviteCode(residential: $id, status: $status)\n  selfProject {\n    juristicContactNumber\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '908c8fb2d45014e6848d6dfe637be132';

module.exports = node;
