import "../styles/createBudget.scss";
import React, { forwardRef } from "react";
import Swal from "sweetalert2";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import _ from "lodash";
import { Translation } from "react-i18next";
import DatePicker from "react-datepicker";
import { addMonths, format, setMonth} from "date-fns";
import { Redirect } from "react-router";
import Loading from "../../../libs/loading";
import createBudgetMutations from "../budgetDraft/mutations/createBudgetMutations";
import code from "../json/chart_of_account.json";
import { checkRangeYearBudget } from "./query/checkRangeYearBudget";
import numberWithCommas from "../../../libs/numberWithComma";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import $ from "jquery"
import i18next from "i18next";
import BackButtonIcon from "../../../components/BackBtn/indexBack";

const query = graphql`
  query createBudgetQuery {
    viewer {
      allChartOfAccount(getHistoricalValue: true) {
        totalCount
        edges {
          node {
            id
            name
            nameEn
            chartOfAccountCode
            totalHistoricalDebit
            totalHistoricalCredit
          }
        }
      }
    }
  }
`;

class CreateBudget extends React.Component {
  constructor(pros) {
    super(pros);
    this.state = {
      lists: _.cloneDeep(code),
      loading: false,
      year: new Date(),
      start_date: new Date(2021, 0, 1),
      end_date: new Date(2021, 11, 1),
      list_month: [],
      value: [],
      save: false,
      redirectToList: false,
      disabled_table: true,
      disabled_button: false,
      widthTable1: 0
    };
  }
  componentDidMount() {
    let width = $('table');
    this.setState({ widthTable1: width.width() })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.widthTable1 !== this.state.widthTable1 || prevState.loading !== this.state.loading) {
      let width = $('table');
      this.setState({ widthTable1: width.width() })
    }
  }

  handleSearch = () => {
    if (this.state.disabled_table) {
      this.setState({ disabled_button: true });
      checkRangeYearBudget({ startDate: format(this.state.start_date, "YYYY-MM-DD"), endDate: format(this.state.end_date, "YYYY-MM-DD") }, (data) => {
        if (data) {
          if (data.checkRangeYearBudget) {
            this.setState({ loading: true });
            this.getData();
          } else {
            this.setState({ disabled_button: false });
            Swal.fire("แจ้งเตือน", "ช่วงเวลาการตั้งงบประมาณที่คุณเลือก มีอยู่ระบบแล้ว กรุณาเลือกใหม่อีกครั้ง", "warning");
          }
        } else {
          console.log("error ");
        }
      });
    } else {
      this.setState({ disabled_table: !this.state.disabled_table, lists: code, disabled_button: false });
    }
  };

  getData = () => {
    let start = _.cloneDeep(this.state.start_date);
    let end = addMonths(_.cloneDeep(this.state.end_date), 1);
    let list_month = [];
    let list = _.cloneDeep(this.state.lists);
    let list_month_new = [];

    do {
      list_month.push(start);
      list_month_new.push({
        month_date: format(start, "YYYY-MM-DD"),
        budget: "",
      });
      start = addMonths(start, 1);
    } while (format(start, "MM-YYYY") !== format(end, "MM-YYYY"));

    fetchQuery(environment, query, {}).then((data) => {
      for (const item of data.viewer.allChartOfAccount.edges) {
        if (item.node.chartOfAccountCode.slice(0, 1) === "4") {
          list[4].push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
        if (item.node.chartOfAccountCode.slice(0, 2) === "51") {
          list[5][0].child.push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
        if (item.node.chartOfAccountCode.slice(0, 2) === "52") {
          list[5][1].child.push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
        if (item.node.chartOfAccountCode.slice(0, 2) === "53" && item.node.chartOfAccountCode.slice(0, 3) !== "531") {
          list[5][2].child.push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
        if (item.node.chartOfAccountCode.slice(0, 3) === "531") {
          list[5][3].child.push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
        if (item.node.chartOfAccountCode.slice(0, 2) === "54") {
          list[5][4].child.push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
        if (item.node.chartOfAccountCode.slice(0, 2) === "55") {
          list[5][5].child.push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
        if (item.node.chartOfAccountCode.slice(0, 2) === "56") {
          list[5][6].child.push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
        if (item.node.chartOfAccountCode.slice(0, 2) === "57") {
          list[5][7].child.push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
        if (item.node.chartOfAccountCode.slice(0, 3) === "581") {
          list[5][8].child.push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
        if (item.node.chartOfAccountCode.slice(0, 3) === "582") {
          list[5][8].child.push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
        if (item.node.chartOfAccountCode.slice(0, 3) === "583") {
          list[5][8].child.push({
            ...item.node,
            month_budget: list_month_new,
            total_budget: "",
          });
        }
      }
      this.setState({
        lists: list,
        loading: false,
        list_month,
        disabled_table: !this.state.disabled_table,
        disabled_button: true,
      });
    });
  };

  handleChange = (e, chartOfAccountCode, main_code, sub_code) => {
    const { name, value } = e.target;
    let lists = _.cloneDeep(this.state.lists);
    let update;
    if (main_code === 4) {
      update = lists[main_code].map((item) => {
        if (chartOfAccountCode === item.chartOfAccountCode) {
          let total_budget = 0;
          let month_budget = _.cloneDeep(item.month_budget);
          month_budget.map((month) => {
            if (month.month_date === name) {
              // month.budget = parseFloat(value);
              month.budget = this.toFixedNumber(value);
            }
            if (month.budget !== "") {
              total_budget = parseFloat(total_budget) + parseFloat(month.budget || 0);
            }
          });
          item.month_budget = month_budget;
          item.total_budget = total_budget;
        }
        return item;
      });
    } else {
      update = lists[main_code][sub_code].child.map((item) => {
        if (chartOfAccountCode === item.chartOfAccountCode) {
          let total_budget = 0;
          let month_budget = _.cloneDeep(item.month_budget);
          month_budget.map((month) => {
            if (month.month_date === name) {
              // month.budget = parseFloat(value);
              month.budget = this.toFixedNumber(value);
            }
            if (month.budget !== "") {
              total_budget = parseFloat(total_budget) + parseFloat(month.budget || 0);
            }
          });
          item.month_budget = month_budget;
          item.total_budget = total_budget;
        }
        return item;
      });
    }

    if (main_code === 4) {
      this.setState({
        lists: { ...this.state.lists, [main_code]: update },
      });
    } else {
      this.setState({
        lists: {
          ...this.state.lists,
          [main_code]: {
            ...this.state.lists[main_code],
            [sub_code]: {
              ...this.state.lists[main_code][sub_code],
              child: update,
            },
          },
        },
      });
    }
  };

  handleChangeSum = (e, chartOfAccountCode, main_code, sub_code) => {
    const { name, value } = e.target;
    let lists = _.cloneDeep(this.state.lists);
    let update;
    if (main_code === 4) {
      update = lists[main_code].map((item) => {
        if (chartOfAccountCode === item.chartOfAccountCode) {
          let total_budget = value;
          let month_budget = _.cloneDeep(item.month_budget);
          let sum = value / item.month_budget.length;
          month_budget.map((month, index) => {
            if (value === "") {
              month.budget = "";
            } else {
              let sub = parseFloat(sum);
              sub = sub.toString();
              if (sub.indexOf(".") >= 0) {
                if (index === item.month_budget.length - 1) {
                  let list_index = (value - ((item.month_budget.length - 1) * parseFloat(sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3)))).toString()
                  sub = list_index.substr(0, list_index.indexOf(".")) + list_index.substr(list_index.indexOf("."), 3);
                } else {
                  sub = sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3);
                }
              } else {
                sub = sub;
              }
              month.budget = parseFloat(sub);
            }
          });
          item.month_budget = month_budget;
          item.total_budget = total_budget;
        }
        return item;
      });
    } else {
      update = lists[main_code][sub_code].child.map((item) => {
        if (chartOfAccountCode === item.chartOfAccountCode) {
          let total_budget = value;
          let month_budget = _.cloneDeep(item.month_budget);
          let sum = value / item.month_budget.length;
          month_budget.map((month, index) => {
            if (value === "") {
              month.budget = "";
            } else {
              let sub = parseFloat(sum);
              sub = sub.toString();
              if (sub.indexOf(".") >= 0) {
                if (index === item.month_budget.length - 1) {
                  let list_index = (value - ((item.month_budget.length - 1) * parseFloat(sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3)))).toString()
                  sub = list_index.substr(0, list_index.indexOf(".")) + list_index.substr(list_index.indexOf("."), 3);
                } else {
                  sub = sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3);
                }
              } else {
                sub = sub;
              }
              month.budget = parseFloat(sub);
            }
          });
          item.month_budget = month_budget;
          item.total_budget = total_budget;
        }
        return item;
      });
    }

    if (main_code === 4) {
      this.setState({
        lists: { ...this.state.lists, [main_code]: update },
      });
    } else {
      this.setState({
        lists: {
          ...this.state.lists,
          [main_code]: {
            ...this.state.lists[main_code],
            [sub_code]: {
              ...this.state.lists[main_code][sub_code],
              child: update,
            },
          },
        },
      });
    }
  };

  handleSubmit = () => {
    this.setState({ save: true });
    let data = [];
    _.map(this.state.lists, (item, index) => {
      if (index == 4) {
        _.map(item, (item_2, index) => {
          data.push(item_2);
        });
      } else if (index == 5) {
        _.map(item, (item_3, index) => {
          _.map(item_3.child, (item_4, index) => {
            data.push(item_4);
          });
        });
      }
    });
    let variables = {
      input: {
        budgetPayload: {
          years: format(this.state.year, "YYYY"),
          start_date: format(this.state.start_date, "YYYY-MM-DD"),
          end_date: format(this.state.end_date, "YYYY-MM-DD"),
          chart_of_account_budget: data,
          status: "approve",
        },
      },
    };

    createBudgetMutations(
      variables,
      () => {
        this.setState({ save: false });
        Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => {
          this.setState({ redirectToList: true });
        });
      },
      () => {
        this.setState({ save: false });
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error")
      }
    );
  };

  toFixedNumber = (value) => {
    let sub = value.toString();
    if (sub.indexOf(".") >= 0) {
      return sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3);
    } else {
      return sub;
    }

  };
  toFixedNumberSum = (value) => {
    return numberWithCommas(value);
  };

  toggleHandleScroll = (scroll) => {
    let scroller = document.querySelector('.table-responsive2');
    let table = document.querySelector('.table-responsive');

    if (scroll === "table-responsive2") {
      table.scrollTo(scroller.scrollLeft, 0);
    } if (scroll === "table-responsive") {
      scroller.scrollTo(table.scrollLeft, 0);
    }
  }

  render() {
    let last_index = 0;
    if (this.state.redirectToList) {
      return <Redirect to="/accounting/budget/list/all" />;
    }
    let total_month_income = {};
    let total_income = null;
    let expenses_type = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
    let expenses_type_sum = [null, null, null, null, null, null, null, null, null];
    let total_month_expenses = {};
    let total_expenses = null;
    let monthEndDate = setMonth(new Date(this.state.start_date), this.state.start_date.getMonth() + 23)
    let maxEndDate = new Date(monthEndDate.getFullYear(), monthEndDate.getMonth(), 0)
    let monthStartDate = setMonth(new Date(this.state.end_date), this.state.end_date.getMonth() - 22)
    let minStartDate = new Date(monthStartDate.getFullYear(), monthStartDate.getMonth(), 1)
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box budget p-4">
                <BackButtonIcon
                  LinkBack={"/accounting/budget/list/all"}
                  LinkText={i18next.t("menuListBudget:Record Budget Setting")}
                />

                <div className="d-flex mt-3 ">
                  <div className="form-row pl-3 col-12 ">
                    <div className="col-2 customDatePickerWidth ">
                      <div>{i18next.t("budgetAll:Select the budgeting year")}</div>
                      <DatePicker
                        className="form-control float-left colorborder"
                        selected={this.state.year}
                        onChange={(date) =>
                          this.setState({
                            year: date,
                            start_date: setMonth(date, 0),
                            end_date: setMonth(date, 11),
                          })
                        }
                        showYearPicker
                        locale="th-TH"
                        dateFormat="yyyy"
                        customInput={<ButtonDate date />}
                        disabled={this.state.disabled_button}
                      />
                    </div>
                    <div className="col-2 customDatePickerWidth ml-3 ">
                      <div>{i18next.t("budgetAll:Budgeting year start date")}</div>
                      <DatePicker
                        className="form-control float-left colorborder"
                        selected={this.state.start_date}
                        onChange={(date) => this.setState({ start_date: date })}
                        dateFormat="dd/MM/yyyy"
                        customInput={<ButtonDate date />}
                        minDate={minStartDate}
                        disabled={this.state.disabled_button}
                      />
                    </div>
                    <div className="col-2 customDatePickerWidth ">
                      <div>{i18next.t("budgetAll:Budgeting year end date")}</div>
                      <DatePicker
                        className="form-control float-left colorborder"
                        selected={this.state.end_date}
                        onChange={(date) => this.setState({ end_date: date })}
                        dateFormat="dd/MM/yyyy"
                        customInput={<ButtonDate date />}
                        minDate={this.state.start_date}
                        maxDate={maxEndDate}
                        disabled={this.state.disabled_button}
                      />
                    </div>
                    <div className="row align-items-end ml-3">
                      <button
                        type="button"
                        className={!this.state.disabled_button ? "btn btn-primary add " : "btn btn-secondary add "}
                        style={{ height: 48 }}
                        onClick={this.handleSearch}
                        disabled={this.state.loading}>
                        {!this.state.disabled_button ? i18next.t("budgetAll:Create Table") : i18next.t("budgetAll:Change Date")}
                      </button>
                    </div>
                  </div>
                </div>
                {this.state.loading && <Loading />}
                {!this.state.disabled_table && (
                  <React.Fragment>
                    <div className="card fade-up mt-4 " id="parcel">
                      <div className="table-responsive2" style={{ overflowX: "auto", width: "100%", display: "block" }} onScroll={() => this.toggleHandleScroll("table-responsive2")}>
                        <div className="table" style={{ width: `${this.state.widthTable1}px`, height: "1px" }}></div>
                      </div>
                      <div className="table-responsive" onScroll={() => this.toggleHandleScroll("table-responsive")}>
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th rowSpan={2} className="text-center">
                                {i18next.t("budgetAll:Sequence")}
                              </th>
                              <th rowSpan={2} className="text-center">
                              {i18next.t("budgetAllList:Item")}
                              </th>
                              <th colSpan={this.state.list_month.length} className="text-center">
                              {i18next.t("budgetAllList:Monthly Budget")}
                              </th>
                              <th rowSpan={2} className="text-center">
                              {i18next.t("budgetAllList:Total Budget")}
                              </th>
                            </tr>
                            <tr className="border-top">
                              {this.state.list_month.map((item, index) => {
                                return (
                                  <th key={index} className="text-center">
                                    {i18next.t("monthNameShort:"+format(item, "MMM"))} {format(item, "YY")} 
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(this.state.lists[4], (item, index) => {
                              ++last_index;
                              if (item.total_budget !== "") {
                                total_income += parseFloat(item.total_budget);
                              }
                              return (
                                // Detail ใน ตารางจะไม่แปล
                                <React.Fragment key={index}>
                                  <tr>
                                    <td className="text-center">{last_index}</td>
                                    <td
                                      style={{
                                        paddingLeft: 50,
                                        minWidth: 300,
                                      }}>
                                      {index == 0 && <div style={{ marginLeft: -32 }}>รายรับ</div>}
                                      {item.name}
                                    </td>
                                    {item.month_budget.map((month, index_month) => {
                                      let sum = total_month_income[index_month] || 0;
                                      total_month_income[index_month] = parseFloat(sum) + parseFloat(month.budget || 0);
                                      return (
                                        <td key={index_month} width={120} height={24}>
                                          <input
                                            disabled={this.state.disabled_table}
                                            type="number"
                                            className="form-control text-center "
                                            style={{ maxHeight: 24 }}
                                            // value={this.toFixedNumber(month.budget) || ""}
                                            value={month.budget}
                                            onChange={(e) => {
                                              this.handleChange(e, item.chartOfAccountCode, 4);
                                            }}
                                            name={month.month_date}
                                          />
                                        </td>
                                      );
                                    })}

                                    <td width={200}>
                                      <input
                                        disabled={this.state.disabled_table}
                                        type="number"
                                        className="form-control text-center"
                                        style={{ maxHeight: 24 }}
                                        value={this.toFixedNumber(item.total_budget) || ""}
                                        name={item.chartOfAccountCode}
                                        onChange={(e) => this.handleChangeSum(e, item.chartOfAccountCode, 4)}
                                      />
                                    </td>
                                  </tr>
                                  {index === this.state.lists[4].length - 1 && (
                                    <tr className="td-sum ">
                                      <td></td>
                                      <td
                                        style={{
                                          paddingLeft: 50,
                                          minWidth: 300,
                                        }}>
                                        รวมรายรับ
                                      </td>
                                      {this.state.list_month.map((item, index) => {
                                        return (
                                          <td key={index} width={120} height={24} className="text-center">
                                            {this.toFixedNumberSum(total_month_income[index])}
                                          </td>
                                        );
                                      })}
                                      <td width={200} className="text-center">
                                        {this.toFixedNumberSum(total_income)}
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              );
                            })}
                            {_.map(this.state.lists[5], (item, index_code) => {
                              return (
                                <React.Fragment key={index_code}>
                                  <tr>
                                    <td></td>
                                    <td>
                                      <div className="ml-2">
                                        {index_code == 51 && <div>รายจ่าย</div>}
                                        <div>{item.name}</div>
                                      </div>
                                    </td>
                                  </tr>
                                  {_.map(item.child, (item_child, index) => {
                                    ++last_index;
                                    if (item_child.total_budget !== "") {
                                      expenses_type_sum[index_code] += parseFloat(item_child.total_budget);
                                      total_expenses += parseFloat(item_child.total_budget);
                                    }
                                    return (
                                      <React.Fragment key={index}>
                                        <tr>
                                          <td className="text-center">{last_index}</td>
                                          <td
                                            style={{
                                              paddingLeft: 50,
                                              minWidth: 300,
                                            }}>
                                            {item_child.name}
                                          </td>
                                          {item_child.month_budget.map((month, index_month) => {
                                            if (month.budget !== "") {
                                              let sum = expenses_type[index_code][index_month] || 0;
                                              expenses_type[index_code][index_month] = sum + parseFloat(month.budget);
                                            }
                                            let sum_all = total_month_expenses[index_month] || 0;
                                            total_month_expenses[index_month] = parseFloat(sum_all) + parseFloat(month.budget || 0);
                                            return (
                                              <td key={index_month} width={120} height={24}>
                                                <input
                                                  disabled={this.state.disabled_table}
                                                  type="number"
                                                  className="form-control text-center "
                                                  style={{ maxHeight: 24 }}
                                                  value={this.toFixedNumber(month.budget) || ""}
                                                  onChange={(e) => this.handleChange(e, item_child.chartOfAccountCode, 5, index_code)}
                                                  name={month.month_date}
                                                />
                                              </td>
                                            );
                                          })}

                                          <td width={200}>
                                            <input
                                              disabled={this.state.disabled_table}
                                              type="number"
                                              className="form-control text-center"
                                              style={{ maxHeight: 24 }}
                                              onChange={(e) => this.handleChangeSum(e, item_child.chartOfAccountCode, 5, index_code)}
                                              value={this.toFixedNumber(item_child.total_budget) || ""}
                                            />
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })}

                                  <tr className="td-sum ">
                                    <td></td>
                                    <td
                                      style={{
                                        paddingLeft: 50,
                                        minWidth: 300,
                                      }}>
                                      รวม{item.name}
                                    </td>
                                    {this.state.list_month.map((item, index) => {
                                      return (
                                        <td key={index} width={120} height={24} className="text-center">
                                          {this.toFixedNumberSum(expenses_type[index_code][index])}
                                        </td>
                                      );
                                    })}
                                    <td width={200} className="text-center">
                                      {this.toFixedNumberSum(expenses_type_sum[index_code])}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                            <tr className="td-sum2 ">
                              <td></td>
                              <td
                                style={{
                                  paddingLeft: 50,
                                  minWidth: 300,
                                }}>
                                รวมรายจ่าย
                              </td>
                              {this.state.list_month.map((item, index) => {
                                return (
                                  <td key={index} width={120} height={24} className="text-center">
                                    {this.toFixedNumberSum(total_month_expenses[index])}
                                  </td>
                                );
                              })}
                              <td width={200} className="text-center">
                                {this.toFixedNumberSum(total_expenses)}
                              </td>
                            </tr>
                            <tr className="td-sum3 ">
                              <td></td>
                              <td
                                style={{
                                  paddingLeft: 50,
                                  minWidth: 300,
                                }}>
                                รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด
                              </td>
                              {this.state.list_month.map((item, index) => {
                                return (
                                  <td key={index} width={120} height={24} className="text-center">
                                    {this.toFixedNumberSum(total_month_income[index] - total_month_expenses[index])}
                                    {/* {(total_month_income[index] - total_month_expenses[index] < 0
                                  ? `(${(total_month_income[index] - total_month_expenses[index]) * -1})`
                                  : total_month_income[index] - total_month_expenses[index]) || 0} */}
                                  </td>
                                );
                              })}
                              <td width={200} className="text-center">
                                {/* {(total_income - total_expenses < 0 ? `(${(total_income - total_expenses) * -1})` : total_income - total_expenses) || 0} */}
                                {this.toFixedNumberSum(total_income - total_expenses)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mb-2  mt-3 ">
                      <button type="button" className="btn btn-primary add float-right " onClick={this.handleSubmit} disabled={this.state.save}>
                        {i18next.t("Allaction:save")}
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            }
          </Translation>

        </div>
      </Wrapper>
    );
  }
}

const ButtonDate = forwardRef(({ value, onClick, disabled, date }, ref) => (
  <button type="button" className="date form-control mt-2 booking-form" style={{ width: "100%" }} onClick={onClick} ref={ref} disabled={disabled}>
    {value}
    {date && (
      <div
        className="calendars"
        style={{
          position: "absolute",
          right: 0,
          marginRight: 8,
        }}>
        <img className="icon" src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"} alt="calendars" />
      </div>
    )}
  </button>
));

export default CreateBudget;
