import React, { Component } from 'react';
import PrivateRoute from '../../libs/privateRoute';
import { Route, Switch } from "react-router-dom";

import NoMatch from "../../components/noMatch";
import FixRequestList from "./fixRequestList";
import Chat from "../chat";
import Schedule from "./schedule/scheduleList";

class FixRequest extends Component { 
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/:status`} component={FixRequestList}/>
                <PrivateRoute exact path={`${this.props.match.url}/chat/:page/:id`} component={Chat}/>
                <PrivateRoute path={`${this.props.match.url}/schedule`} component={Schedule}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default FixRequest;