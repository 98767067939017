import React, { Component } from 'react'
import _ from "lodash"
import {format} from "date-fns";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { fetchQuery } from "react-relay";
import Swal from "sweetalert2";
import axios from 'axios';
import getApiRoot from "../../../../libs/getAPIRoot";
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');
const query = graphql`

query waterAndElectricUtilityReceiveReportExportDataQuery
    ($atDate: Date
     $month: Int
     $year: Int
    $search: String
    $contactId: ID
    $typeOfContact: String
    $productAndService: String
    )
  {
    allContactUnitTransaction(
        atDate: $atDate,
        month: $month,
        year: $year
        search:$search,
        contactId:$contactId,
        typeOfContact:$typeOfContact,
        productAndService: $productAndService,
    )
    selfProject{
      name
    }
    productViewer{
        allProduct (type_In: "product, service, fine",servicePricing_PricingType_In:"water_meter, electric_meter"){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                    chartOfAccount{
                        id
                        chartOfAccountCode
                        name
                    }
                    description
                    totalLeftInStock
                }
            }
        }
    }
    contactViewer{
            allContact(typeOfPayment: "receivable", typeOfContact: $typeOfContact,order: "ref_number"){
                edges{
                    node{
                        id
                        refNumber
                        name
                        firstName
                        lastName
                    }
                }
    }}
}`

export default class WaterAndElectricUtilityReceiveReportExports extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: false,
        selfProjectName: "",
        productAndServiceList: [],
        contactList: [],
    }
    this.queryData = this.queryData.bind(this);
  }

  queryData = () => {
    this.setState({ 
      loading: true,
    },()=>this.props.handleLoadingBtn(this.state.loading))
    let variable = {
        atDate: this.props.selectedAtDate,
        month: this.props.selectedMonth,
        year: this.props.selectedYear,
        search: this.props.search,
        contactId: this.props.contact,
        productAndService: this.props.productAndServiceFilter ? this.props.productAndServiceFilter : this.props.productAndServiceInit,         
    }
    fetchQuery(environment, query, variable).then(data => {
      this.setState({ 
        loading: false,     
        selfProjectName: data.selfProject.name,
        productAndServiceList: data.productViewer.allProduct.edges,
        contactList: data.contactViewer.allContact.edges,   
       },()=>this.downloadExcel(data))
    })
    .catch(error => {
      Swal.fire("Error!", "", "error"); 
    })
  }

  getContactList(contact) {
    let value = ""
    let listProductAndService = []

    listProductAndService = _.filter(this.state.contactList, (o) => o.node.id === contact)

    if (listProductAndService.length > 0) {
      value = `${listProductAndService[0].node?.refNumber} ${listProductAndService[0].node?.name}`
    } else {
      value = "ทั้งหมด"
    }

    return value
  }

  getProductList(productAndService){
    let value = ""
    let listProductAndService = []

    listProductAndService = _.filter(this.state.productAndServiceList, (o) => o.node.id === productAndService)

    if (listProductAndService.length > 0) {
      value = `${listProductAndService[0].node?.productCode} ${listProductAndService[0].node?.name}`
    } else {
      value = "ทั้งหมด"
    }

    return value
  }
  

  downloadExcel = (dataAllForExcel) => {
    let token_id = localStorage.getItem("token");
    let dataExcel = dataAllForExcel.allContactUnitTransaction;
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('total receipt');

    let columns = [
      { width: 20 },
      { width: 30 },
      { width: 70 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];
    
    // Set column widths
    worksheet.columns = columns.map((col, index) => {
      return { width: col.width, key: String.fromCharCode(65 + index) };
    });

    // setBorder
    let borders = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }

    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let textLeft = { vertical: 'middle', horizontal: 'left' };
    let textRight = { vertical: 'middle', horizontal: 'right' };
    let fontBold = { size: 12, bold: true };
    let projectName = this.state.selfProjectName;
    let reportName = 'รายงานลูกหนี้ค้างชำระค่าน้ำประปาและค่าไฟฟ้า'
    let contactHeader = `ผู้ติดต่อ - ${this.getContactList(this.props.contact)}`
    let productAndServiceHeader = `ประเภทสินค้าและบริการ -  ${this.getProductList(this.props.productAndServiceFilter ? this.props.productAndServiceFilter : this.props.productAndServiceInit)}`
    let monthDate = "ประจำเดือน " + this.props.selectedMonth +"/"+ this.props.selectedYear + " ณ วันที่ " + format(this.props.selectedAtDate, "DD-MM-YYYY")


    worksheet.getCell('A1').value = projectName
    worksheet.getCell('A1').font = { size: 16, bold: true };
    
    worksheet.getCell('A2').value = reportName
    worksheet.getCell('A2').font = { size: 16, bold: true };

    worksheet.getCell('A3').value = contactHeader
    worksheet.getCell('A3').font = fontBold;

    worksheet.getCell('A4').value = productAndServiceHeader
    worksheet.getCell('A4').font = fontBold;


    worksheet.getCell('A5').value = monthDate
    worksheet.getCell('A5').font = fontBold;

    worksheet.addRow();
    let headerRow = worksheet.addRow();
    let headers = ['ลำดับ', 'บ้านเลขที่/เลขที่ห้อง','ชื่อ', 'จำนวน (หน่วย)', 'อัตรา', 'แจ้งหนี้', 'ชำระ','ค้างชำระ','% ค้างชำระ']

    let count = 1
    headers.forEach((item) => {
      let cell = headerRow.getCell(count);
      cell.value = item;
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter
      count += 1
    })
    
    for (let i = 0; i < dataExcel.length; i++) {
      let dataRow = worksheet.addRow();  
            let value = []
            const data = JSON.parse(dataExcel[i]);   
            value.push(data.no)
            value.push(data.room)
            value.push(data.name === "รวม" ? "รวมทั้งหมด" : data.name)
            value.push(data.count)
            value.push(data.rate)
            value.push(data.total)
            value.push(data.paid)
            value.push(data.balance)
            value.push(data.percent_balance)
            
            value.forEach((data,index)=>{
                let cell = dataRow.getCell(index + 1); 
                cell.value = data;
                cell.border = borders
                cell.alignment = 
                  (index === 1 || index === 2) ? 
                    textLeft : 
                    (index >=3) ?
                      textRight
                        :
                      textCenter      
            })
            value = [];        
    }
    
    workbook.xlsx.writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let nameFile = 'รายงานลูกหนี้ค้างชำระค่าน้ำประปาและค่าไฟฟ้า' + monthDate;    

        let formData = new FormData();
        formData.append('file', blob , "report.xlsx");
        formData.append('type', "excel");
        formData.append('type_report_ref', "water_and_Electric_utility_receive_report");
        axios.post(`${getApiRoot()}taskdownload/add_download`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `JWT ${token_id}`,
          }
        })
        .then(response => {
          console.log('File uploaded successfully:', response.data);
        })
        .catch(error => {
          console.error('There was an error sending the file to the server:', error);
        })
        .finally(() => {
          // Download the file after sending it to the API
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = nameFile;
          a.click();
        });
      });
      
    this.props.handleLoadingBtn(false)
  }

  render() {       
    return (
      <a className="dropdown-item" target={"_blank"} onClick={this.queryData}>Excel</a>
    )
  }
}
