import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import {fetchQuery} from "react-relay";
import { format } from "date-fns";

const query = graphql`
  query receivableOutstandingReportOnLoadQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $endDateOnDate: Date
    $search: String
    $customerType: String
    $productAndService: String
    $contact: String
    $overdueDate: String
    $rangeOverdueDate: [Int]
  ) {
    invoiceViewer {
      allInvoice(
        approveOnly: true
        receivableOutstanding: true
        first: $first
        last: $last
        startDate: $startDate
        endDate: $endDate
        search: $search
        customerType: $customerType
        productAndService: $productAndService
        contact: $contact
        order: "contact__residential__name"
        status_In: "active, overdue, partial_payment, paid"
        overdueDate: $overdueDate
        rangeOverdueDate: $rangeOverdueDate
      ) {
        edges {
          node {
            id
            docNumber
            issuedDate
            dueDate
            payGroup
            firstName
            lastName
            contact {
              id
              name
              firstName
              lastName
              refNumber
              typeOfContact
              residential {
                id
                name
              }
            }
            transaction {
              edges {
                node {
                  id
                  description
                  whtRate
                  total
                  status
                  productAndService {
                    id
                    productCode
                    name
                  }
                  chartOfAccount {
                    chartOfAccountCode
                  }
                  receiveTransaction(receive_Status_In: "paid" , receive_IssuedDate_Lte:$endDateOnDate) {
                    edges {
                      node {
                        amount
                        added
                        receive {
                          issuedDate

                        }
                      }
                    }
                  }
                  creditNoteTransaction(status: "paid",issuedDate_Lte:$endDateOnDate, receive_Isnull:true ) {
                    edges {
                      node {
                        price
                        issuedDate
                        receive {
                          id
                        }
                      }
                    }
                  }
                  creditNoteTransactionRecord(status: "paid",creditNote_IssuedDate_Lte:$endDateOnDate){
                      edges{
                          node{
                              id
                              price
                              creditNote{
                                  id
                                  docNumber
                                  issuedDate
                                  status
                              }
                              price
                              status
                          }
                      }
                  }
                  receiptDepositTransactionUsed(receiveTransaction_Isnull:true) {
                    edges {
                      node {
                        amount
                        receiveTransaction {
                          id

                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
 
    }

  }
`;


async function receivableOutstandingReportOnLoadGraph(first,last,state) {
    return fetchQuery(environment, query, {
        first: first,
        last: last,
        startDate: state.end_date,
        endDate: state.end_date,
        endDateOnDate: format(state.end_date, "YYYY-MM-DD"),
        search: state.search,
        customerType: state.customer_type,
        productAndService: state.product_and_service,
        contact: state.contact,
        overdueDate: state.overdue_date,
        rangeOverdueDate: state.range_overdue_date,
     });
 }

export default {
  receivableOutstandingReportOnLoadGraph,
}