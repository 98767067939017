/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAndUpdateManagerInput = {|
  id?: ?string,
  image?: ?string,
  signature?: ?string,
  namePrefix: string,
  firstName: string,
  lastName: string,
  address: string,
  department: string,
  position: string,
  affiliation: string,
  email?: ?string,
  phone?: ?string,
  education?: ?any,
  workExperience?: ?any,
  filepdf?: ?any,
  startDate?: ?any,
  clientMutationId?: ?string,
|};
export type createAndUpdateManagerMutationVariables = {|
  input: CreateAndUpdateManagerInput
|};
export type createAndUpdateManagerMutationResponse = {|
  +createAndUpdateManager: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type createAndUpdateManagerMutation = {|
  variables: createAndUpdateManagerMutationVariables,
  response: createAndUpdateManagerMutationResponse,
|};
*/


/*
mutation createAndUpdateManagerMutation(
  $input: CreateAndUpdateManagerInput!
) {
  createAndUpdateManager(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAndUpdateManagerPayload",
    "kind": "LinkedField",
    "name": "createAndUpdateManager",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createAndUpdateManagerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createAndUpdateManagerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bd34747023a8ccac4c80ce1bc32da63d",
    "id": null,
    "metadata": {},
    "name": "createAndUpdateManagerMutation",
    "operationKind": "mutation",
    "text": "mutation createAndUpdateManagerMutation(\n  $input: CreateAndUpdateManagerInput!\n) {\n  createAndUpdateManager(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f500206c936a566eb1ad0734a1ffe220';

module.exports = node;
