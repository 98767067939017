import React from 'react';
import { Component } from 'react';
import Wrapper from '../../components/wrapper';
import WrapperContent from '../../components/wrapper/wrapperContent';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import { Translation } from 'react-i18next';
import InputPost from './inputPost';
import _ from 'lodash'
import PreviewMobilePost from './previewMobilePost';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import i18n from "i18next";
import environment from '../../env/environment';
import getQueryPost from './graphQL/postRegulation';
import getQueryCancel from './graphQL/cancleRegulation';
import { fetchQuery } from "relay-runtime";
import updateMutation from './graphQL/updateRegulation';
import createMutation from './graphQL/createRegulation';
import { Redirect } from 'react-router';
import upload from '../../libs/upload';
import BackButtonIcon from '../../components/BackBtn/indexBack';



class CreateUpdateRegulation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: this.props.match.params.status,
            language: 'th',
            post: {},
            scheduleStatus: false,
            dateSchedule: new Date(new Date().setHours(new Date().getHours() + 1)),
            loading: false,
            statusPost: 'post',
            defaultData: {},
            redirectToList: false,
            uploadPreviewImage: '',
            fileMore: []
        }
        this.handleInputPost = this.handleInputPost.bind(this)
        this.handleScheduleDate = this.handleScheduleDate.bind(this)
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
        this.onSubmitError = this.onSubmitError.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
    }



    componentDidUpdate() {
        if (this.state.status !== this.props.match.params.status) {
            this.setState({
                status: this.props.match.params.status
            })
        }
    }

    componentDidMount() {
        if (this.props.match.params.status === 'edit') {
            let query = this.props.match.params.type === 'effective' ? getQueryPost() : getQueryCancel()
            fetchQuery(environment, query, {
                regulationid: this.props.match.params.id,
            }).then(data => {
                if (data.postRegulation) {
                    this.setState({
                        post: data.postRegulation.edges[0].node,
                        defaultData: data.postRegulation.edges[0].node,
                        fileMore: data.postRegulation.edges[0].node.regulationFile?.edges?.map((n) => ({ ...n.node }))
                    }, () => {
                        if (this.state.post.dateSchedule) {
                            this.setState({
                                scheduleStatus: true
                            })
                        }
                    })
                } else if (data.cancelRegulation) {
                    this.setState({
                        post: data.cancelRegulation.edges[0].node,
                        defaultData: data.cancelRegulation.edges[0].node,
                        fileMore: data.postRegulation.edges[0].node.regulationFile?.edges?.map((n) => ({ ...n.node }))
                    }, () => {
                        if (this.state.post.dateSchedule) {
                            this.setState({
                                scheduleStatus: true
                            })
                        }
                    })
                }
            })
        }
    }

    onChangeStatus(statusPost) {
        this.setState({ statusPost: statusPost }, () => this.onSubmit())
    }

    handleInputPost(input) {
        if (input.uploadPreviewImage !== this.state.uploadPreviewImage) {
            this.setState({
                uploadPreviewImage: input.uploadPreviewImage
            })
        }
        let newPost = _.mergeWith(_.cloneDeep(this.state.post),
            {
                type: input.type,
                previewImage: input.previewImage,
                description: input.description,
                subject: input.subject,
                descriptionEn: input.descriptionEn,
                subjectEn: input.subjectEn
            })
        let mapdata = input.fileMore.map((n) => ({ ...n }))
        this.setState({ post: newPost, fileMore: mapdata })
    }

    handleScheduleDate(date) {
        if (date < new Date()) {
            Swal.fire(i18n.t('regulation:Error!'), i18n.t('regulation:Please select a date and time after the current date and time'), 'warning')
        } else {
            this.setState({
                'dateSchedule': date
            })
        }
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/regulation/data" + path, file);
            resolve(res);
        });
    };


    async onSubmit() {
        if (this.state.status === 'create') {
            this.setState({ loading: true })
            let mapdata = this.state.fileMore.map((n) => JSON.stringify(n))
            let variables = {
                input: {
                    subject: this.state.post.subject,
                    description: this.state.post.description,
                    subjectEn: this.state.post.subjectEn,
                    descriptionEn: this.state.post.descriptionEn,
                    status: this.state.statusPost,
                    type: this.state.post.type,
                    file: mapdata
                }
            }
            if (this.state.uploadPreviewImage !== '') {
                await this.callUpload('', this.state.uploadPreviewImage).then((data) => {
                    variables.input["previewImage"] = data.key.replace("silverman-storage/private/", "");
                });
            }
            if (this.state.statusPost === 'draft' || (this.state.statusPost === 'post' && !this.state.scheduleStatus)) {
                variables.input["datePosted"] = new Date()
            } else {
                variables.input["scheduleTime"] = this.state.scheduleStatus ? this.state.dateSchedule : new Date()
            }
            createMutation(variables, this.onSubmitSuccess, this.onSubmitError)
        } else {
            let mapdata = this.state.fileMore.map((n) => JSON.stringify(n))
            this.setState({ loading: true })
            let variables = {
                input: {
                    id: this.props.match.params.id,
                    status: this.state.statusPost,
                    file: mapdata,
                    subject: this.state.post.subject,
                    description: this.state.post.description,
                    subjectEn: this.state.post.subjectEn,
                    descriptionEn: this.state.post.descriptionEn,
                    type: this.state.post.type,
                }
            }
            //status Post  คือสถานะที่กดล่าสุด
            for (const [key, value] of Object.entries(this.state.defaultData)) {
                if (this.state.post[key] !== value) {
                    if (key === 'previewImage') {
                        if (this.state.uploadPreviewImage !== '') {
                            await this.callUpload('', this.state.uploadPreviewImage).then((data) => {
                                variables.input["previewImage"] = data.key.replace("silverman-storage/private/", "");
                            });
                        }
                    } 
                    // else {
                    //     variables.input[key] = this.state.post[key]
                    // }
                }
            }
            if (this.state.statusPost === 'draft' || (this.state.statusPost === 'post' && !this.state.scheduleStatus)) {
                variables.input["datePosted"] = new Date()
            } else {
                variables.input["scheduleTime"] = this.state.scheduleStatus ? this.state.dateSchedule : new Date()
            }
            updateMutation(variables, this.onSubmitSuccess, this.onSubmitError)
        }

    }

    onSubmitSuccess(response) {
        this.setState({ loading: false });
        if (response) {
            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                this.setState({ redirectToList: true })
            });
        } else {
            Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
        }
    }

    onSubmitError(err) {
        this.setState({ loading: false });
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }


    render() {
        let header = this.state.status === 'create' ? 'Add Regulation / New Rule of resident' : 'Edit Regulation / Rule of resident'
        if (this.state.redirectToList) {
            return <Redirect to="/project/regulation/list/effective" />
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <ProjectTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="projectDashboard">
                                <BackButtonIcon 
                                    LinkBack={"/project/regulation/list/effective"}
                                    LinkText={t('regulation:' + header)}
                                /> 
                                <div className="content-inner fade-up">
                                    <div className="row">
                                        <div className="col-md-7 col-xl-6 col-sm-12">
                                            <InputPost
                                                keyTranslation="regulation"
                                                type={["Regulation", "Rule"]}
                                                headerType="Type"
                                                language="th"
                                                post={this.state.post}
                                                callBack={this.handleInputPost}
                                                switchLanguage={true}
                                                uploadPreviewImage={this.state.uploadPreviewImage}
                                                fileMore={this.state.fileMore}
                                                updateFile={(file)=> this.setState({ fileMore: file })}
                                            />
                                        </div>
                                        <div className="col-md-4 col-xl-5 col-sm-12">
                                            <div className="card p-4 mt-2">
                                                <h6>{t("press_release:posting_option")}</h6>
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="scheduleCheckbox"
                                                        checked={this.state.scheduleStatus}
                                                        disabled={this.state.post.status === 'cancel' || this.state.post.status === 'post'}
                                                        onChange={() => this.setState({ scheduleStatus: !this.state.scheduleStatus })}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="scheduleCheckbox"
                                                    >
                                                        {t("regulation:Set time")}
                                                    </label>
                                                </div>
                                                {this.state.scheduleStatus &&
                                                    <div className="mt-3 time-selected">
                                                        <div className="row">
                                                            <div className="col">
                                                                <p>{t('regulation:Date and Time to post')} </p>
                                                            </div>
                                                            <div className="col-7 noti-date group-inline" style={{ zIndex: 99 }}>
                                                                <DatePicker
                                                                    className="form-control text-center"
                                                                    timeInputLabel="Time:"
                                                                    dateFormat="dd/MM/yyyy       hh:mm aa"
                                                                    showTimeInput
                                                                    selected={this.state.dateSchedule}
                                                                    minDate={new Date()}
                                                                    name="dateSchedule"
                                                                    disabled={this.state.post.status === 'cancel' || this.state.post.status === 'post'}
                                                                    onChange={(date) => this.handleScheduleDate(date)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-block btn-outline-primary"
                                                            onClick={() => this.onChangeStatus("draft")}
                                                            disabled={this.state.scheduleStatus || this.state.post.status === 'cancel' || this.state.post.status === 'post'}
                                                        >
                                                            <span>{t("contact_menu:save_draft")}</span>
                                                        </button>
                                                    </div>
                                                    <div className="col">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-block btn-primary"
                                                            onClick={() => this.onChangeStatus("post")}
                                                        // disabled = {this.state.post.status === 'cancel' || this.state.post.status === 'post' || !this.state.post.subject || this.state.post.subject === ''}
                                                        >
                                                            <span>{t("press_release:confirm")}</span>
                                                        </button>
                                                    </div>
                                                </div>


                                            </div>
                                            <PreviewMobilePost subject={this.state.post.subject}
                                                subjectEn={this.state.post.subjectEn}
                                                detail={this.state.post.description}
                                                detailEn={this.state.post.descriptionEn}
                                                previewImage={this.state.post.previewImage} 
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </Translation>

                </WrapperContent>
            </Wrapper>

        );
    }



} export default CreateUpdateRegulation;