import axios from "axios";

function getToken() {
    let data = new FormData();
    data.append('username', 'pingnuttakrid');
    data.append('password', '4309243092');

    return axios.post('https://svm-log-qsobffrqcq-as.a.run.app/login', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

function getHistoryLog(data) {
    return axios.get(`https://svm-log-qsobffrqcq-as.a.run.app/get_all_log`, {
        headers: {
            authorization: `bearer ${data.token}`
        },
        params: {
            domain: data.domain,
            page_size: data.page_size,
            page_num: data.page_num
        }
    })
}

function getLogById(id, token, domain) {
    return axios.get(`https://svm-log-qsobffrqcq-as.a.run.app/get_log/`, {
        headers: {
            'authorization': `bearer ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            log_id: id,
            domain: domain
        }
    })
}

function getLogBySearch(data) {
    return axios.get(`https://svm-log-qsobffrqcq-as.a.run.app/seach_log/`, {
        headers: {
            'authorization': `bearer ${data.token}`,
            'Content-Type': 'application/json'
        },
        params: {
            search: data.search,
            user_id: data.user_id,
            domain: data.domain,
            page_size: data.page_size,
            page_num: data.page_num,
        }
    })
}

function getLogRepairRoom(token,id,domain) {
    return axios.get(`https://svm-log-qsobffrqcq-as.a.run.app/search_repairs/`, {
        headers: {
            'authorization': `bearer ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            search_id: id,
            domain: domain
        }
    })
}



export default {
    getToken, getHistoryLog, getLogById, getLogBySearch,getLogRepairRoom
}