import React, {Component} from 'react';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import Wrapper from '../components/wrapper';
import WrapperContent from '../components/wrapper/wrapperContent';
import {fetchQuery} from "relay-runtime";
import environment from "../env/environment";
import queryCheckSiteOnline from "../libs/checkSiteOnline"
import Loading from "../libs/loading"
import AlertWithholdPage from '../libs/alertWithholdPage';
import DataAnalyticMenuNavigaton from './dataAnalyticMenuNavigaton';

class DataAnalyticMenu extends Component {

    constructor(props){
        super(props)

        this.state = {
            loading: false,
            checkOnline: true
        }
    }

    componentWillMount(){
        this.setState({loading: true})
        fetchQuery(environment, queryCheckSiteOnline).then(data => {
            this.setState({checkOnline: data.checkOnline, loading: false})
        });
    }
    
    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    {this.state.loading ? <Loading/>
                        : !this.state.checkOnline ? 
                        <AlertWithholdPage/>
                            : 
                        <DataAnalyticMenuNavigaton center={true}/>
                    }
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default DataAnalyticMenu;
