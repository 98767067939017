import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import CollectionLettersCreate from "./collectionLetterCreate/collectionLettersCreate";
import CollectionLettersList from "./collectionLettersList";
import CollectionLetterDetail from "./collectionLetterDetail";
import CollectionLetterAttach from "./collectionLetterAttach"

class CollectionLetter extends Component {
    
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={CollectionLettersList}/>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={CollectionLettersList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status/:id?`} component={CollectionLettersCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:status/:id`} component={CollectionLetterDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:status/:id/attach`} component={CollectionLetterAttach}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default CollectionLetter;