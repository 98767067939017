import React, { Component } from 'react';
import format from 'date-fns/format';
import _ from 'lodash'
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from '../../../../env/environment';
import jwtDecode from 'jwt-decode';
import getNameResidential from "../../../../libs/getNameResidential";
import './receiveReportPDF.scss'
import ReceiveReportPDFrenderTable from './receiveReportPDFTableRender';
import i18next from 'i18next';

const query = graphql`
  query receiveReportPDFTableQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

let checkValueLast = false;

class ReceiveReportPDFTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            state: [],
            status: "",
            printBy: "",
            startDate: "",
            endDate: "",
            props: "",
            projectName: "",
            paymentChannel: "",
            contactName: "",
            productAndService: "",
        }
    }
    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            if (res.selfProject) {
                let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
                this.setState({
                    status: this.props.status,
                    projectName: res.selfProject.name,
                    printBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
                    startDate: this.props.startDate,
                    endDate: this.props.endDate,
                    props: this.props.props,
                    paymentChannel: this.props.paymentChannel,
                    contactName: this.props.contactName,
                    productAndService: this.props.productAndService,
                })
            }
        })
    }

    //ปรับคำค่าน้ำปะปา
    removeTrailingZeros(description) {
        if (description.includes("ค่าน้ำประปา")) {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }

    //การตัดเงิน
    cutCash(remainCurrent, cashTotal, displayCash) {
        // remainCurrent = จำนวนเงินปัจจุบัน
        // cashTotal = จำนวนเงินรวม
        // displayCash = จำนวนเงินที่แสดงผล

        //จำนวนเงินรวมมากกว่าจำนวนเงินปัจจุบัน
        if (cashTotal > remainCurrent) {
            displayCash = remainCurrent
            cashTotal = cashTotal - remainCurrent
            remainCurrent = 0
        } //จำนวนเงินรวมมากเท่าจำนวนเงินปัจจุบัน
        else if (cashTotal === remainCurrent) {
            displayCash = remainCurrent
            cashTotal = 0
            remainCurrent = 0
        } //จำนวนเงินรวมน้อยกว่าจำนวนเงินปัจจุบัน (รับเกิน)
        else if (cashTotal < remainCurrent) {
            displayCash = cashTotal
            remainCurrent = remainCurrent - cashTotal
            cashTotal = 0
        }
        return { remainCurrent, cashTotal, displayCash }
    }

    //ผลรวมจำนวนเงิน
    sumAmountObject(obj, type, status) {
        let total = 0
        if (status !== 'VOID') {
            if (type === "price") {
                if (Object.keys(obj).length > 0) total = obj.map(item => item.node.price).reduce((prev, next) => prev + next);
            }
            else if (type === "amount") {
                if (Object.keys(obj).length > 0) total = obj.map(item => item.node.amount).reduce((prev, next) => prev + next);
            }
            else if (type === "total") {
                if (Object.keys(obj).length > 0) total = obj.map(item => item.node.total).reduce((prev, next) => prev + next);
            }
        }
        return total
    }

    //ผลรวมเงินจาก รับโอน หรือ CROSS_BANK
    sumAmountObjectBank(obj, type, status) {
        let total = 0
        if (status !== 'VOID') {
            if (type === "price") {
                if (Object.keys(obj).length > 0) {
                    obj = obj.filter(item => item.node.transferMethod !== "CROSS_BANK");
                }
                if (Object.keys(obj).length > 0) total = obj.map(item => item.node.price).reduce((prev, next) => prev + next);
            }
            else if (type === "amount") {
                if (Object.keys(obj).length > 0) total = obj.map(item => item.node.amount).reduce((prev, next) => prev + next);
            }
            else if (type === "feeAmount") {
                if (Object.keys(obj).length > 0) total = obj.map(item => item.node.feeAmount).reduce((prev, next) => prev + next);
            }
            else if (type === "total") {
                if (Object.keys(obj).length > 0) total = obj.map(item => item.node.total).reduce((prev, next) => prev + next);
            }
        }
        return total
    }

    //
    checkValueLast(docNumberInvoice, otherTotal) {
        let last = this.props.props.receiveViewer?.allReceive?.edges[0]?.node?.receiveTransaction?.edges[this.props.props.receiveViewer?.allReceive?.edges[0]?.node?.receiveTransaction?.edges.length - 1]
        if (last.node.transaction.productAndService.productCode !== docNumberInvoice) {
            checkValueLast = true;
            return ""
        } else if (otherTotal < 0) {
            checkValueLast = false;
            return `-${this.numberWithComma(otherTotal * (-1), "")}`
        } else {
            checkValueLast = false;
            return this.numberWithComma(otherTotal, "")
        }
    }

    //เปลี่ยน format number
    numberWithComma(num) {
        let isNegative = false;
        let formattedNum;

        if (num === 0) {
            return formattedNum = "-";
        }
        // Check if the number is negative
        if (num < 0) {
            isNegative = true;
            num = Math.abs(num); // Convert negative number to positive
        }
        // Add .00 to the number
        num = num.toFixed(2);
        // Format the number with commas
        formattedNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // Add a minus sign for negative numbers
        if (isNegative) {
            formattedNum = '(' + formattedNum + ')';
        }
        return formattedNum;
    }

    render() {
        let total = 0;
        let sum_receive_transaction_amount = 0
        let sum_cash = 0
        let sum_bankAccount = 0
        let sum_crossBank = 0
        let sum_paymentGatewayTransaction = 0
        let sum_creditCardTransaction = 0
        let sum_chequeDeposit = 0
        let sum_discount = 0
        let sum_receiptDepositTransactionUsed = 0
        let sum_feeAccounting = 0
        let sum_unknownReceive = 0
        let sum_other = 0
        let sum_receiveTotal = 0

        // non display
        let total_nd = 0;
        let sum_receive_transaction_amount_nd = 0
        let sum_cash_nd = 0
        let sum_bankAccount_nd = 0
        let sum_crossBank_nd = 0
        let sum_paymentGateway_nd = 0
        let sum_creditCardTransaction_nd = 0
        let sum_chequeDeposit_nd = 0
        let sum_discount_nd = 0
        let sum_receiptDepositTransactionUsed_nd = 0
        let sum_feeAccounting_nd = 0
        let sum_unknownReceive_nd = 0
        let sum_other_nd = 0
        let sum_receiveTotal_nd = 0
        let rd_other_nd = 0;

        let col_span = 7;
        if (this.props.state.payment_channel !== "") {
            col_span = 7;
        }

        if (_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on) {
            col_span += 1
        }
        if (_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on) {
            col_span += 1
        }

        let summaryReceive_invoice_total = 0
        let summaryReceive_cash_total = 0
        let summaryReceive_bank_account_total = 0
        let summaryReceive_cross_bank_total = 0
        let summaryReceive_paymentGateway_total = 0
        let summaryReceive_credit_card_total = 0
        let summaryReceive_cheque_deposit_total = 0
        let summaryReceive_receipt_deposit_used_total = 0
        let summaryReceive_discount_total = 0
        let summaryReceive_fee_total = 0
        let summaryReceive_unknown_receive_total = 0
        let summaryReceive_other_total = 0
        let summaryReceive_sum_total = 0

        let summaryReceive_total = 0

        // นับจำนวนรายการ
        let cashDepositReceiveTransactionCount = 0
        let bankAccountTransactionReceiveCount = 0
        let crossBankCount = 0
        let paymentGatewayTransactionCount = 0
        let creditCardTransactionCount = 0
        let chequeDepositCount = 0
        let unknownReceiveCount = 0
        let receiptDepositTransactionUsedCount = 0
        let otherCount = 0

        //ประมวลผลข้อมูล
        this.props.props.receiveViewer.allReceive.edges.map((receive, index) => {
            // let orderReceive = this.props.state.first - localStorage.getPageLimit()
            let accumulate = 0;
            let receive_select = null;

            let cashDepositReceiveTransactionTotal = 0
            let bankAccountTransactionReceiveTotal = 0
            let crossBankTotal = 0
            let paymentGatewayTransactionTotal = 0
            let creditCardTransactionTotal = 0
            let chequeDepositTotal = 0
            let receiptDepositTotal = 0 // รับล่วงหน้า
            let creditNoteReceiveTotal = 0 // หักลดหนี้
            let feeAccountingTotal = 0 // ค่าธรรมเนียม
            let unknownReceiveTotal = 0 // เงินรับรอการตรวจสอบ
            let smallChangeTotal = 0 // ส่วนต่าง
            let withheldTaxReceiveTotal = 0 // ภาษีหัก ณ ที่จ่าย
            let receiptDepositTransactionUsedTotal = 0

            let discountTotalOld = 0
            // รูปแบบเพย์เมนท์เกตเวย์
            let text_type_paymentType = receive.node.paymentGatewayMethod.edges[0]?.node.paymentType || ""
            cashDepositReceiveTransactionTotal = this.sumAmountObject(receive.node.cashDepositReceiveTransaction.edges, "price", receive.node.status)
            bankAccountTransactionReceiveTotal = this.sumAmountObjectBank(receive.node.bankAccountTransactionReceive.edges, "price", receive.node.status)
            crossBankTotal = this.sumAmountObject(receive.node.crossBank.edges, "price", receive.node.status)

            // สำหรับ Crossbill ต้องเอาไปรวมไว้กับตัว Crossbill
            if (text_type_paymentType === 'crossbill') {
                crossBankTotal += this.sumAmountObject(receive.node.paymentGatewayMethod.edges, "total", receive.node.status)
            } else {
                paymentGatewayTransactionTotal = this.sumAmountObject(receive.node.paymentGatewayMethod.edges, "total", receive.node.status)
            }

            cashDepositReceiveTransactionCount += receive.node.cashDepositReceiveTransaction.edges.length || 0
            bankAccountTransactionReceiveCount += receive.node.bankAccountTransactionReceive.edges.length || 0

            crossBankCount += receive.node.crossBank.edges.length || 0
            if (text_type_paymentType === 'crossbill') {
                crossBankCount += receive.node.paymentGatewayMethod.edges.length || 0
            } else {
                paymentGatewayTransactionCount += receive.node.paymentGatewayMethod.edges.length || 0
            }

            creditCardTransactionCount += receive.node.creditCardTransaction.edges.length || 0
            chequeDepositCount += receive.node.chequeDeposit.edges.length || 0
            unknownReceiveCount += receive.node.unknownReceive.edges.length || 0


            creditCardTransactionTotal = this.sumAmountObject(receive.node.creditCardTransaction.edges, "amount", receive.node.status)
            chequeDepositTotal = this.sumAmountObject(receive.node.chequeDeposit.edges, "price", receive.node.status)
            receiptDepositTotal = this.sumAmountObject(receive.node.receiptDeposit.edges, "total", receive.node.status)
            creditNoteReceiveTotal = this.sumAmountObject(receive.node.creditNoteReceive.edges, "price", receive.node.status)
            feeAccountingTotal = this.sumAmountObject(receive.node.feeAccounting.edges, "amount", receive.node.status)
            unknownReceiveTotal = this.sumAmountObject(receive.node.unknownReceive.edges, "amount", receive.node.status)
            smallChangeTotal = this.sumAmountObject(receive.node.smallChange.edges, "amount", receive.node.status)
            withheldTaxReceiveTotal = this.sumAmountObject(receive.node.withheldTaxReceive.edges, "total", receive.node.status)
            receive.node.receiveTransaction.edges.forEach((receive_transaction) => {
                receiptDepositTransactionUsedTotal = receiptDepositTransactionUsedTotal + this.sumAmountObject(receive_transaction.node.receiptDepositTransactionUsed.edges, "amount", receive.node.status)

                if (receive.node.status !== 'VOID') receiptDepositTransactionUsedCount += receive_transaction.node.receiptDepositTransactionUsed.edges.length || 0
            })

            // รวมยอดชำระเงินทั้งหมด
            let paidTotal = cashDepositReceiveTransactionTotal + bankAccountTransactionReceiveTotal + crossBankTotal + creditCardTransactionTotal + chequeDepositTotal + paymentGatewayTransactionTotal
            paidTotal = paidTotal + receiptDepositTransactionUsedTotal + creditNoteReceiveTotal + feeAccountingTotal + unknownReceiveTotal
            paidTotal = paidTotal + smallChangeTotal + withheldTaxReceiveTotal

            //เวลาที่ฟิลเตอร์รหัสรายรายรับจะฟิลเตอร์รหัสรายรับที่ไม่ถูกต้องออกไป
            receive_select = receive.node.receiveTransaction.edges
            if (this.props.state.product_and_service === "") {
                receive_select = receive.node.receiveTransaction.edges
            }
            else {
                receive_select = receive.node.receiveTransaction.edges.filter(element => {
                    if (element.node.transaction.productAndService) {
                        if (element.node.transaction.productAndService.id === this.props.state.product_and_service) {
                            return element
                        }
                    }
                });
            }

            //เวลาที่ฟิลเตอร์การชำระที่น้อยกว่าศูนย์
            let minus_receive_select = receive_select.filter(receive_transaction => receive_transaction.node.amount < 0)
            let minusAll = 0
            minus_receive_select.forEach(element => {
                minusAll += element.node.amount
            });

            receive_select = receive_select.filter(receive_transaction => receive_transaction.node.amount > 0)

            let number_of_transaction = receive_select.length;
            return (
                receive_select.map((receive_transaction, t_index) => {
                    let minusUse = false;
                    let minusType = ''

                    let receive_transaction_amount = receive_transaction.node.amount
                    let remainCurrentTransaction = receive_transaction_amount
                    let cashDepositReceiveTransaction = 0
                    let bankAccountTransactionReceive = 0
                    let crossBank = 0
                    let creditCardTransaction = 0
                    let paymentGatewayTransaction = 0
                    let chequeDeposit = 0
                    let receiveTransactionDiscount = 0
                    let creditNoteReceive = 0
                    let feeAccounting = 0
                    let unknownReceive = 0
                    let smallChange = 0
                    let withheldTaxReceive = 0
                    let receiptDepositTransactionUsed = 0

                    // ลดหนี้
                    if (remainCurrentTransaction > 0) {
                        // // เพิ่มค่าเงินที่ติดลบจากเคส การทำส่วนลดที่ผิดรูปแบบ (นำรหัสรายรับมาทำส่วนลด)
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && creditNoteReceiveTotal !== 0){
                            creditNoteReceiveTotal = creditNoteReceiveTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'creditNote'
                        }
                        let cutCreditNoteReceive = this.cutCash(remainCurrentTransaction, creditNoteReceiveTotal, creditNoteReceive)
                        remainCurrentTransaction = cutCreditNoteReceive.remainCurrent
                        creditNoteReceiveTotal = cutCreditNoteReceive.cashTotal
                        creditNoteReceive = cutCreditNoteReceive.displayCash
                    } else {
                        creditNoteReceive = 0
                    }

                    // รับล่วงหน้า
                    if (remainCurrentTransaction > 0) {
                        // // เพิ่มค่าเงินที่ติดลบจากเคส การทำส่วนลดที่ผิดรูปแบบ (นำรหัสรายรับมาทำส่วนลด)
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && receiptDepositTransactionUsedTotal !== 0){
                            receiptDepositTransactionUsedTotal = receiptDepositTransactionUsedTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'receiptDeposit'
                        }
                        let cutreceiptDepositTransactionUsed = this.cutCash(remainCurrentTransaction, receiptDepositTransactionUsedTotal, receiptDepositTransactionUsed)
                        remainCurrentTransaction = cutreceiptDepositTransactionUsed.remainCurrent
                        receiptDepositTransactionUsedTotal = cutreceiptDepositTransactionUsed.cashTotal
                        receiptDepositTransactionUsed = cutreceiptDepositTransactionUsed.displayCash
                    } else {
                        receiptDepositTransactionUsed = 0
                    }

                    // ส่วนลด
                    let receiveTransactionDiscountTotal = receive_transaction.node.discount || 0
                    paidTotal = paidTotal + receiveTransactionDiscountTotal
                    discountTotalOld = discountTotalOld + receiveTransactionDiscountTotal
                    if (remainCurrentTransaction > 0) {
                        // // เพิ่มค่าเงินที่ติดลบจากเคส การทำส่วนลดที่ผิดรูปแบบ (นำรหัสรายรับมาทำส่วนลด)
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && receiveTransactionDiscountTotal !== 0){
                            receiveTransactionDiscountTotal = receiveTransactionDiscountTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'receiveTransactionDiscount'
                        }
                        let cutReceiveTransactionDiscount = this.cutCash(remainCurrentTransaction, receiveTransactionDiscountTotal, receiveTransactionDiscount)
                        remainCurrentTransaction = cutReceiveTransactionDiscount.remainCurrent
                        receiveTransactionDiscountTotal = cutReceiveTransactionDiscount.cashTotal
                        receiveTransactionDiscount = cutReceiveTransactionDiscount.displayCash
                    } else {
                        receiveTransactionDiscount = 0
                    }

                    // เงินรับรอการตรวจสอบ
                    if (remainCurrentTransaction > 0) {
                        // // เพิ่มค่าเงินที่ติดลบจากเคส การทำส่วนลดที่ผิดรูปแบบ (นำรหัสรายรับมาทำส่วนลด)
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && unknownReceiveTotal !== 0){
                            unknownReceiveTotal = unknownReceiveTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'unknownReceive'
                        }
                        let cutUnknownReceive = this.cutCash(remainCurrentTransaction, unknownReceiveTotal, unknownReceive)
                        remainCurrentTransaction = cutUnknownReceive.remainCurrent
                        unknownReceiveTotal = cutUnknownReceive.cashTotal
                        unknownReceive = cutUnknownReceive.displayCash
                    } else {
                        unknownReceive = 0
                    }

                    // เงินสด
                    if (remainCurrentTransaction > 0) {
                        // // เพิ่มค่าเงินที่ติดลบจากเคส การทำส่วนลดที่ผิดรูปแบบ (นำรหัสรายรับมาทำส่วนลด)
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && cashDepositReceiveTransactionTotal !== 0){
                            cashDepositReceiveTransactionTotal = cashDepositReceiveTransactionTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'cash'
                        }
                        let cutCashDepositReceive = this.cutCash(remainCurrentTransaction, cashDepositReceiveTransactionTotal, cashDepositReceiveTransaction)
                        remainCurrentTransaction = cutCashDepositReceive.remainCurrent
                        cashDepositReceiveTransactionTotal = cutCashDepositReceive.cashTotal
                        cashDepositReceiveTransaction = cutCashDepositReceive.displayCash
                    } else {
                        cashDepositReceiveTransaction = 0
                    }

                    // เงินโอน
                    if (remainCurrentTransaction > 0) {
                        // // เพิ่มค่าเงินที่ติดลบจากเคส การทำส่วนลดที่ผิดรูปแบบ (นำรหัสรายรับมาทำส่วนลด)
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && bankAccountTransactionReceiveTotal !== 0){
                            bankAccountTransactionReceiveTotal = bankAccountTransactionReceiveTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'bank'
                        }
                        let cutBankAccountTransactionReceive = this.cutCash(remainCurrentTransaction, bankAccountTransactionReceiveTotal, bankAccountTransactionReceive)
                        remainCurrentTransaction = cutBankAccountTransactionReceive.remainCurrent
                        bankAccountTransactionReceiveTotal = cutBankAccountTransactionReceive.cashTotal
                        bankAccountTransactionReceive = cutBankAccountTransactionReceive.displayCash
                    } else {
                        bankAccountTransactionReceive = 0
                    }

                    // crossBank
                    if (remainCurrentTransaction > 0) {
                        // // เพิ่มค่าเงินที่ติดลบจากเคส การทำส่วนลดที่ผิดรูปแบบ (นำรหัสรายรับมาทำส่วนลด)
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && crossBankTotal !== 0){
                            crossBankTotal = crossBankTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'crossBank'
                        }
                        let cutCrossBank = this.cutCash(remainCurrentTransaction, crossBankTotal, crossBank)
                        remainCurrentTransaction = cutCrossBank.remainCurrent
                        crossBankTotal = cutCrossBank.cashTotal
                        crossBank = cutCrossBank.displayCash
                    } else {
                        crossBank = 0
                    }

                    // บัตรเครดิต
                    if (remainCurrentTransaction > 0) {
                        // // เพิ่มค่าเงินที่ติดลบจากเคส การทำส่วนลดที่ผิดรูปแบบ (นำรหัสรายรับมาทำส่วนลด)
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && creditCardTransactionTotal !== 0){
                            creditCardTransactionTotal = creditCardTransactionTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'creditCard'
                        }
                        let cutCreditCardTransaction = this.cutCash(remainCurrentTransaction, creditCardTransactionTotal, creditCardTransaction)
                        remainCurrentTransaction = cutCreditCardTransaction.remainCurrent
                        creditCardTransactionTotal = cutCreditCardTransaction.cashTotal
                        creditCardTransaction = cutCreditCardTransaction.displayCash
                    } else {
                        creditCardTransaction = 0
                    }

                    // เพย์เมนท์เกตเวย์
                    // ถ้ามีค่าธรรมเนียมจะเหลือเศษใน paymentGatewayTransactionTotal
                    if (remainCurrentTransaction > 0) {
                        // // เพิ่มค่าเงินที่ติดลบจากเคส การทำส่วนลดที่ผิดรูปแบบ (นำรหัสรายรับมาทำส่วนลด)
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && paymentGatewayTransactionTotal !== 0){
                            paymentGatewayTransactionTotal = paymentGatewayTransactionTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'paymentGateway'
                        }
                        let cutPaymentGatewayTransaction = this.cutCash(remainCurrentTransaction, paymentGatewayTransactionTotal, paymentGatewayTransaction)
                        remainCurrentTransaction = cutPaymentGatewayTransaction.remainCurrent
                        paymentGatewayTransactionTotal = cutPaymentGatewayTransaction.cashTotal
                        paymentGatewayTransaction = cutPaymentGatewayTransaction.displayCash
                    } else {
                        paymentGatewayTransaction = 0
                    }

                    // เช็ค
                    if (remainCurrentTransaction > 0) {
                        // เพิ่มค่าเงินที่ติดลบจากเคส การทำส่วนลดที่ผิดรูปแบบ (นำรหัสรายรับมาทำส่วนลด)
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && chequeDepositTotal !== 0){
                            chequeDepositTotal = chequeDepositTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'cheque'
                        }
                        let cutChequeDeposit = this.cutCash(remainCurrentTransaction, chequeDepositTotal, chequeDeposit)
                        remainCurrentTransaction = cutChequeDeposit.remainCurrent
                        chequeDepositTotal = cutChequeDeposit.cashTotal
                        chequeDeposit = cutChequeDeposit.displayCash
                    } else {
                        chequeDeposit = 0
                    }

                    // ค่าธรรมเนียม
                    if (remainCurrentTransaction > 0) {
                        let cutFeeAccounting = this.cutCash(remainCurrentTransaction, feeAccountingTotal, feeAccounting)
                        remainCurrentTransaction = cutFeeAccounting.remainCurrent
                        feeAccountingTotal = cutFeeAccounting.cashTotal
                        feeAccounting = cutFeeAccounting.displayCash
                    } else {
                        feeAccounting = 0
                    }

                    // ส่วนต่างเล็กน้อย
                    if (remainCurrentTransaction > 0) {
                        let cutSmallChange = this.cutCash(remainCurrentTransaction, smallChangeTotal, smallChange)
                        remainCurrentTransaction = cutSmallChange.remainCurrent
                        smallChangeTotal = cutSmallChange.cashTotal
                        smallChange = cutSmallChange.displayCash
                    } else {
                        smallChange = 0
                    }

                    // ภาษี ณ ที่จ่าย
                    if (remainCurrentTransaction > 0) {
                        let cutWithheldTaxReceive = this.cutCash(remainCurrentTransaction, withheldTaxReceiveTotal, withheldTaxReceive)
                        remainCurrentTransaction = cutWithheldTaxReceive.remainCurrent
                        withheldTaxReceiveTotal = cutWithheldTaxReceive.cashTotal
                        withheldTaxReceive = cutWithheldTaxReceive.displayCash
                    } else {
                        withheldTaxReceive = 0
                    }

                    let remainSmallChangeTotal = 0
                    let remainOtherTotal = 0
                    let remainRdTotal = 0
                    let remainRdTotalSmall = 0
                    let remainRdTotalRecordRt = 0

                    //ค่าใช้จ่ายอื่นๆ รหัส 5830-01 เงินคืน - อื่นๆ
                    let currentRecordRt = this.props.props.currentRecord.edges.filter(item => {
                        return item.node.group.refTransaction === receive.node.docNumber && item.node.chartOfAccountCode.chartOfAccountCode === "5830-01"
                    })

                    if (receive.node.status !== "VOID") {
                        // ส่วนขาดเกินประเภทต่างๆ currentRecordRt มาจาก รหัส 5830-01 รายรับอื่นๆ (ช่องทางชำระ ค่าใช้จ่ายอื่นๆ)
                        if (smallChangeTotal > 0 && currentRecordRt.length > 0 && t_index === receive_select.length - 1) {
                            remainSmallChangeTotal = smallChangeTotal * (-1)
                            remainOtherTotal = currentRecordRt[0].node.credit * (-1)

                            let receive_select_productAndService_total = 0
                            let receive_select_productAndService_discount = 0
                            if (this.props.state.product_and_service !== "") {
                                let receive_select_productAndService = receive.node.receiveTransaction.edges.filter(element => {
                                    if (element.node.transaction.productAndService) {
                                        if (element.node.transaction.productAndService.id !== this.props.state.product_and_service) {
                                            return element
                                        }
                                    }
                                });

                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_total = receive_select_productAndService.map(item => item.node.amount).reduce((prev, next) => prev + next);
                                }
                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_discount = receive_select_productAndService.map(item => item.node.discount).reduce((prev, next) => prev + next);
                                }
                            }

                            receive_select_productAndService_total = receive_select_productAndService_total - receive_select_productAndService_discount

                            if (receive.node.receiptDeposit.edges) {
                                receive.node.receiptDeposit.edges.map(item => {
                                    let cashrd = 0
                                    let bankAccountrd = 0
                                    let crossBankrd = 0
                                    let creditCardrd = 0
                                    let chequeDepositrd = 0
                                    let otherrd = 0
                                    if (item.node.cashTransaction.totalCount) {
                                        let tempCash = item.node.cashTransaction.edges.node[0].price
                                        cashrd = tempCash
                                    }
                                    if (item.node.bankAccountTransaction.totalCount) {
                                        let tempBank = item.node.bankAccountTransaction.edges.node[0].price
                                        bankAccountrd = tempBank
                                    }
                                    if (item.node.crossBank.totalCount) {
                                        let tempCross = item.node.crossBank.edges.node[0].price
                                        crossBankrd = tempCross
                                    }
                                    if (item.node.creditCardTransaction.totalCount) {
                                        let tempCreditCard = item.node.creditCardTransaction.edges.node[0].amount
                                        creditCardrd = tempCreditCard
                                    }
                                    if (item.node.chequeDeposit.totalCount) {
                                        let tempCheque = item.node.chequeDeposit.edges.node[0].price
                                        chequeDepositrd = tempCheque
                                    }
                                    if (item.node.receiptDepositTransaction.totalCount) {
                                        let tempOther = item.node.receiptDepositTransaction.edges[0].node.price
                                        otherrd = tempOther
                                    }
                                    let currentRD = cashrd + bankAccountrd + crossBankrd + creditCardrd + chequeDepositrd + otherrd
                                    remainRdTotal = currentRD
                                })
                            }

                            let othSum = smallChangeTotal + Math.abs(remainOtherTotal) + remainRdTotal
                            let othSumNotRd = smallChangeTotal + Math.abs(remainOtherTotal)

                            if (Math.abs(cashDepositReceiveTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                cashDepositReceiveTransaction += othSumNotRd
                            }

                            else if (Math.abs(bankAccountTransactionReceiveTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                bankAccountTransactionReceive += othSumNotRd
                            }

                            else if (Math.abs(crossBankTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                crossBank += othSumNotRd
                            }

                            else if (Math.abs(creditCardTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                creditCardTransaction += othSumNotRd
                            }
                            else if (Math.abs(chequeDepositTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                chequeDeposit += othSumNotRd
                            }
                        }

                        else if (smallChangeTotal > 0 && t_index === receive_select.length - 1) {
                            remainSmallChangeTotal = smallChangeTotal * (-1)
                            let receive_select_productAndService_total = 0
                            let receive_select_productAndService_discount = 0
                            if (this.props.state.product_and_service !== "") {
                                let receive_select_productAndService = receive.node.receiveTransaction.edges.filter(element => {
                                    if (element.node.transaction.productAndService) {
                                        if (element.node.transaction.productAndService.id !== this.props.state.product_and_service) {
                                            return element
                                        }
                                    }
                                });

                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_total = receive_select_productAndService.map(item => item.node.amount).reduce((prev, next) => prev + next);
                                }
                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_discount = receive_select_productAndService.map(item => item.node.discount).reduce((prev, next) => prev + next);
                                }
                            }

                            receive_select_productAndService_total = receive_select_productAndService_total - receive_select_productAndService_discount

                            if (receive.node.receiptDeposit.edges) {
                                receive.node.receiptDeposit.edges.map(item => {
                                    let cashrd = 0
                                    let bankAccountrd = 0
                                    let crossBankrd = 0
                                    let creditCardrd = 0
                                    let chequeDepositrd = 0
                                    let otherrd = 0
                                    if (item.node.cashTransaction.totalCount) {
                                        let tempCash = item.node.cashTransaction.edges.node[0].price
                                        cashrd = tempCash
                                    }
                                    if (item.node.bankAccountTransaction.totalCount) {
                                        let tempBank = item.node.bankAccountTransaction.edges.node[0].price
                                        bankAccountrd = tempBank
                                    }
                                    if (item.node.crossBank.totalCount) {
                                        let tempCross = item.node.crossBank.edges.node[0].price
                                        crossBankrd = tempCross
                                    }
                                    if (item.node.creditCardTransaction.totalCount) {
                                        let tempCreditCard = item.node.creditCardTransaction.edges.node[0].amount
                                        creditCardrd = tempCreditCard
                                    }
                                    if (item.node.chequeDeposit.totalCount) {
                                        let tempCheque = item.node.chequeDeposit.edges.node[0].price
                                        chequeDepositrd = tempCheque
                                    }
                                    if (item.node.receiptDepositTransaction.totalCount) {
                                        let tempOther = item.node.receiptDepositTransaction.edges[0].node.price
                                        otherrd = tempOther
                                    }
                                    let currentRD = cashrd + bankAccountrd + crossBankrd + creditCardrd + chequeDepositrd + otherrd
                                    remainRdTotalSmall = currentRD
                                })
                            }

                            let othSum = smallChangeTotal + remainRdTotalSmall

                            if (Math.abs(cashDepositReceiveTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                cashDepositReceiveTransaction += smallChangeTotal
                            }

                            else if (Math.abs(bankAccountTransactionReceiveTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                bankAccountTransactionReceive += smallChangeTotal
                            }

                            else if (Math.abs(crossBankTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                crossBank += smallChangeTotal
                            }

                            else if (Math.abs(creditCardTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                creditCardTransaction += smallChangeTotal
                            }
                            else if (Math.abs(chequeDepositTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                chequeDeposit += smallChangeTotal
                            }

                        }

                        else if (currentRecordRt.length > 0 && t_index === receive_select.length - 1) {
                            remainOtherTotal = currentRecordRt[0].node.credit * (-1)
                            let receive_select_productAndService_total = 0
                            let receive_select_productAndService_discount = 0
                            if (this.props.state.product_and_service !== "") {
                                let receive_select_productAndService = receive.node.receiveTransaction.edges.filter(element => {
                                    if (element.node.transaction.productAndService) {
                                        if (element.node.transaction.productAndService.id !== this.props.state.product_and_service) {
                                            return element
                                        }
                                    }
                                });

                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_total = receive_select_productAndService.map(item => item.node.amount).reduce((prev, next) => prev + next);
                                }
                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_discount = receive_select_productAndService.map(item => item.node.discount).reduce((prev, next) => prev + next);
                                }
                            }

                            receive_select_productAndService_total = receive_select_productAndService_total - receive_select_productAndService_discount

                            if (receive.node.receiptDeposit.edges) {
                                receive.node.receiptDeposit.edges.map(item => {
                                    let cashrd = 0
                                    let bankAccountrd = 0
                                    let crossBankrd = 0
                                    let creditCardrd = 0
                                    let chequeDepositrd = 0
                                    let otherrd = 0
                                    if (item.node.cashTransaction.totalCount) {
                                        let tempCash = item.node.cashTransaction.edges.node[0].price
                                        cashrd = tempCash
                                    }
                                    if (item.node.bankAccountTransaction.totalCount) {
                                        let tempBank = item.node.bankAccountTransaction.edges.node[0].price
                                        bankAccountrd = tempBank
                                    }
                                    if (item.node.crossBank.totalCount) {
                                        let tempCross = item.node.crossBank.edges.node[0].price
                                        crossBankrd = tempCross
                                    }
                                    if (item.node.creditCardTransaction.totalCount) {
                                        let tempCreditCard = item.node.creditCardTransaction.edges.node[0].amount
                                        creditCardrd = tempCreditCard
                                    }
                                    if (item.node.chequeDeposit.totalCount) {
                                        let tempCheque = item.node.chequeDeposit.edges.node[0].price
                                        chequeDepositrd = tempCheque
                                    }
                                    if (item.node.receiptDepositTransaction.totalCount) {
                                        let tempOther = item.node.receiptDepositTransaction.edges[0].node.price
                                        otherrd = tempOther
                                    }
                                    let currentRD = cashrd + bankAccountrd + crossBankrd + creditCardrd + chequeDepositrd + otherrd
                                    remainRdTotalRecordRt = currentRD
                                })
                            }

                            let othSum = Math.abs(remainOtherTotal) + remainRdTotalRecordRt

                            if (Math.abs(cashDepositReceiveTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                cashDepositReceiveTransaction += Math.abs(remainOtherTotal)
                            }

                            else if (Math.abs(bankAccountTransactionReceiveTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                bankAccountTransactionReceive += Math.abs(remainOtherTotal)
                            }

                            else if (Math.abs(crossBankTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                crossBank += Math.abs(remainOtherTotal)
                            }

                            else if (Math.abs(creditCardTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                creditCardTransaction += Math.abs(remainOtherTotal)
                            }

                            else if (Math.abs(chequeDepositTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                chequeDeposit += Math.abs(remainOtherTotal)
                            }
                        }
                    }

                    if (withheldTaxReceive) otherCount += 1;
                    if (smallChange) otherCount += 1;
                    if (creditNoteReceive) otherCount += 1;
                    if (remainSmallChangeTotal) otherCount += 1;
                    if (remainOtherTotal) otherCount += 1;
                    if (feeAccounting) otherCount += 1;
                    if (receiveTransactionDiscount) otherCount += 1;

                    // อื่นๆ
                    let otherTotal = withheldTaxReceive + smallChange + creditNoteReceive + remainSmallChangeTotal + remainOtherTotal + feeAccounting + receiveTransactionDiscount

                    // รับทั้งหมด
                    let receiveTotal = cashDepositReceiveTransaction + bankAccountTransactionReceive + crossBank + creditCardTransaction + chequeDeposit + paymentGatewayTransaction// ฝั่งซ้าย
                    receiveTotal = receiveTotal + receiptDepositTransactionUsed + unknownReceive // ฝั่งขวา
                    receiveTotal = receiveTotal + otherTotal // อื่นๆ

                    if (receive.node.status !== "VOID") {
                        sum_receive_transaction_amount_nd = sum_receive_transaction_amount_nd + receive_transaction_amount
                        sum_cash_nd = sum_cash_nd + cashDepositReceiveTransaction
                        sum_bankAccount_nd = sum_bankAccount_nd + bankAccountTransactionReceive
                        sum_crossBank_nd = sum_crossBank_nd + crossBank
                        sum_creditCardTransaction_nd = sum_creditCardTransaction_nd + creditCardTransaction
                        sum_paymentGateway_nd = sum_paymentGateway_nd + paymentGatewayTransaction
                        sum_chequeDeposit_nd = sum_chequeDeposit_nd + chequeDeposit
                        sum_discount_nd = sum_discount_nd + receiveTransactionDiscount
                        sum_receiptDepositTransactionUsed_nd = sum_receiptDepositTransactionUsed_nd + receiptDepositTransactionUsed
                        sum_feeAccounting_nd = sum_feeAccounting_nd + feeAccounting
                        sum_unknownReceive_nd = sum_unknownReceive_nd + unknownReceive
                        sum_other_nd = sum_other_nd + otherTotal
                        sum_receiveTotal_nd = sum_receiveTotal_nd + receiveTotal

                        ////////////////////// ผลรวมต่างๆ ////////////////////////
                        summaryReceive_invoice_total += receive_transaction_amount
                        summaryReceive_cash_total += cashDepositReceiveTransaction
                        summaryReceive_bank_account_total += bankAccountTransactionReceive
                        summaryReceive_cross_bank_total += crossBank
                        summaryReceive_credit_card_total += creditCardTransaction
                        summaryReceive_paymentGateway_total += paymentGatewayTransaction
                        summaryReceive_cheque_deposit_total += chequeDeposit
                        summaryReceive_receipt_deposit_used_total += receiptDepositTransactionUsed
                        summaryReceive_discount_total += receiveTransactionDiscount
                        summaryReceive_fee_total += feeAccounting
                        summaryReceive_unknown_receive_total += unknownReceive
                        summaryReceive_other_total += otherTotal
                        summaryReceive_sum_total += receiveTotal
                        //////////////////////////////////////////////////////////
                    }

                    if (receive.node.status !== "VOID") {
                        // accumulate = accumulate + receive_transaction_amount + receiptDepositTotal
                        // total_nd = total_nd + receive_transaction_amount + receiptDepositTotal

                        accumulate = accumulate + receive_transaction_amount //ยอดสะสมของ RT แต่ละใบโดยใช้ Transaction แทน
                        total_nd = total_nd + receive_transaction_amount //ผลรวมทั้งหมด ไม่นำมาแสดงผล
                    }

                    //รวมชำระเงินทั้งหมด
                    let paidTotalCurrent = cashDepositReceiveTransactionTotal + bankAccountTransactionReceiveTotal + crossBankTotal + creditCardTransactionTotal + chequeDepositTotal + paymentGatewayTransactionTotal
                    paidTotalCurrent = paidTotalCurrent + receiptDepositTransactionUsedTotal + receiveTransactionDiscountTotal + creditNoteReceiveTotal + feeAccountingTotal + unknownReceiveTotal
                    paidTotalCurrent = paidTotalCurrent + smallChangeTotal + withheldTaxReceiveTotal

                    //คำนวนเงินชำระที่คงเหลือ เอาไปตัด รับล่วงหน้า
                    let lastPaidTotalCurrent = 0
                    if (t_index === (number_of_transaction - 1)) {
                        if (paidTotalCurrent.toFixed(2) > 0 && receive.node.status !== "VOID") {
                            lastPaidTotalCurrent = paidTotalCurrent

                            // add more
                            if (receive.node.status !== "VOID") {
                                accumulate = accumulate + receiptDepositTotal
                                total_nd = total_nd + receiptDepositTotal
                            }
                        }
                    }

                    //คำนวน RT ที่มี RD ที่เกิดจากใบ RT (ไม่แน่ใจว่าเข้าใจถูกไหม) 
                    receive.node.receiptDeposit.edges.map(item => {
                        if (receive.node.status !== "VOID" && lastPaidTotalCurrent > 0) {
                            let cashrd = 0
                            let bankAccountrd = 0
                            let crossBankrd = 0
                            let creditCardrd = 0
                            let chequeDepositrd = 0
                            let otherrd = 0
                            if (item.node.cashTransaction.totalCount) {
                                let tempCash = item.node.cashTransaction.edges.node[0].price
                                cashrd = tempCash
                            }
                            if (item.node.bankAccountTransaction.totalCount) {
                                let tempBank = item.node.bankAccountTransaction.edges.node[0].price
                                bankAccountrd = tempBank
                            }
                            if (item.node.crossBank.totalCount) {
                                let tempCross = item.node.crossBank.edges.node[0].price
                                crossBankrd = tempCross
                            }
                            if (item.node.creditCardTransaction.totalCount) {
                                let tempCreditCard = item.node.creditCardTransaction.edges.node[0].amount
                                creditCardrd = tempCreditCard
                            }
                            if (item.node.chequeDeposit.totalCount) {
                                let tempCheque = item.node.chequeDeposit.edges.node[0].price
                                chequeDepositrd = tempCheque
                            }
                            if (item.node.receiptDepositTransaction.totalCount) {
                                let tempOther = item.node.receiptDepositTransaction.edges[0].node.price
                                otherrd = tempOther
                                rd_other_nd = rd_other_nd + tempOther
                            }

                            let currentRD_nd = cashrd + bankAccountrd + crossBankrd + creditCardrd + chequeDepositrd + otherrd

                            if (receive.node.status !== "VOID") {
                                let temp_otherTotal = Math.abs(otherTotal) + currentRD_nd
                                if (cashDepositReceiveTransactionTotal.toFixed(2) === temp_otherTotal.toFixed(2)) {
                                    summaryReceive_cash_total += currentRD_nd
                                }
                                if (bankAccountTransactionReceiveTotal.toFixed(2) === temp_otherTotal.toFixed(2)) {
                                    summaryReceive_bank_account_total += currentRD_nd
                                }
                                if (crossBankTotal.toFixed(2) === temp_otherTotal.toFixed(2)) {
                                    summaryReceive_cross_bank_total += currentRD_nd
                                }
                                if (creditCardTransactionTotal.toFixed(2) === temp_otherTotal.toFixed(2)) {
                                    summaryReceive_credit_card_total += currentRD_nd
                                }
                                if (chequeDepositTotal.toFixed(2) === temp_otherTotal.toFixed(2)) {
                                    summaryReceive_cheque_deposit_total += currentRD_nd
                                }
                                if (unknownReceiveTotal.toFixed(2) === temp_otherTotal.toFixed(2)) {
                                    summaryReceive_unknown_receive_total += currentRD_nd
                                }
                                summaryReceive_sum_total += currentRD_nd
                            }
                        }
                    })

                    //คำนวนค่าธรรมเนียมจากระบบเพย์เมนต์
                    if (t_index === (number_of_transaction - 1)) {
                        if (paidTotalCurrent.toFixed(2) > 0 && receive.node.status !== "VOID") {
                            // add more
                            if (receive.node.status !== "VOID") {
                                accumulate = accumulate + paymentGatewayTransactionTotal + crossBankTotal
                                total_nd = total_nd + paymentGatewayTransactionTotal + crossBankTotal
                                if (text_type_paymentType === 'crossbill') {
                                    summaryReceive_cross_bank_total += crossBankTotal
                                } else {
                                    summaryReceive_paymentGateway_total += paymentGatewayTransactionTotal
                                }

                            }
                        }
                    }

                    receive.node.status !== "VOID" && t_index === number_of_transaction - 1 && minus_receive_select &&
                    minus_receive_select.map(item => {

                        let otherStatus = minusType !== 'cash' && minusType !== 'bank' && minusType !== 'crossBank' && minusType !== 'paymentGateway'
                            && minusType !== 'creditCard' && minusType !== 'creditNote' && minusType !== 'receiveTransactionDiscount' 
                            && minusType !== 'cheque' && minusType !== 'receiptDeposit' && minusType !== 'unknownReceive'
                            ? true : false


                        if (receive.node.status !== "VOID") {
                            ////////////////////// ผลรวมต่างๆทั้งหมดในบรรทัดสุดท้าย ////////////////////////
                            summaryReceive_invoice_total += item.node.amount
                            summaryReceive_cash_total += minusType === 'cash' ? item.node.amount : 0
                            summaryReceive_bank_account_total += minusType === 'bank' ? item.node.amount : 0
                            summaryReceive_cross_bank_total += minusType === 'crossBank' ? item.node.amount : 0
                            summaryReceive_credit_card_total += minusType === 'creditCard' ? item.node.amount : 0
                            summaryReceive_paymentGateway_total += minusType === 'paymentGateway' ? item.node.amount : 0
                            summaryReceive_cheque_deposit_total += minusType === 'cheque' ? item.node.amount : 0
                            summaryReceive_receipt_deposit_used_total += minusType === 'receiptDeposit' ? item.node.amount : 0
                            summaryReceive_discount_total +=  minusType === 'receiveTransactionDiscount' ? item.node.amount : 0
                            summaryReceive_unknown_receive_total += minusType === 'unknownReceive' ? item.node.amount : 0
                            summaryReceive_other_total += otherStatus ? item.node.amount : 0
                            summaryReceive_sum_total += item.node.amount
                            //////////////////////////////////////////////////////////
                        }
                        
                    })

                })
            )
        })

        summaryReceive_total = summaryReceive_cash_total + summaryReceive_bank_account_total +
            summaryReceive_cross_bank_total + summaryReceive_credit_card_total +
            summaryReceive_cheque_deposit_total + summaryReceive_paymentGateway_total +
            summaryReceive_other_total + summaryReceive_unknown_receive_total +
            summaryReceive_receipt_deposit_used_total

        let total_count_recive = cashDepositReceiveTransactionCount + bankAccountTransactionReceiveCount +
            crossBankCount + paymentGatewayTransactionCount + creditCardTransactionCount +
            chequeDepositCount + otherCount + unknownReceiveCount + receiptDepositTransactionUsedCount

        // use for slice transaction in render
        let transaction = "";
        let sumtransaction = "";
        let dataBeforeSlice = [];
        let dataAll = [];
        let dataSumBeforeSlice = [];
        let footerSummary = [];

        // ประเภทการรับชำระเงินผลรวมทั้งหมด ช่องด้านบน
        let topTable = (
            <div className="col-5" id="topTable" >
                <table className='table tebleBorder'>
                    <thead>
                        <tr>
                            <th>ประเภทการรับชำระเงิน</th>
                            <th className="text-center">รายการ</th>
                            <th className="text-center">จำนวนเงิน</th>
                        </tr>
                        <tr>
                            <td>เงินสด</td>
                            <td className="text-center">{cashDepositReceiveTransactionCount > 0 ? cashDepositReceiveTransactionCount : '-'}</td>
                            <td className="text-right">{this.numberWithComma(summaryReceive_cash_total)}</td>
                        </tr>
                        <tr>
                            <td>เงินโอน</td>
                            <td className="text-center">{bankAccountTransactionReceiveCount > 0 ? bankAccountTransactionReceiveCount : '-'}</td>
                            <td className="text-right">{this.numberWithComma(summaryReceive_bank_account_total)}</td>
                        </tr>
                        <tr>
                            <td>Cross bill</td>
                            <td className="text-center">{crossBankCount > 0 ? crossBankCount : '-'}</td>
                            <td className="text-right">{this.numberWithComma(summaryReceive_cross_bank_total)}</td>
                        </tr>

                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on &&
                            <tr>
                                <td>บัตรเครดิต</td>
                                <td className="text-center">{creditCardTransactionCount > 0 ? creditCardTransactionCount : '-'}</td>
                                <td className="text-right">{this.numberWithComma(summaryReceive_credit_card_total)}</td>
                            </tr>
                        }
                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                            <tr>
                                <td>เพย์เมนท์เกตเวย์</td>
                                <td className="text-center">{paymentGatewayTransactionCount > 0 ? paymentGatewayTransactionCount : '-'}</td>
                                <td className="text-right">{this.numberWithComma(summaryReceive_paymentGateway_total)}</td>
                            </tr>
                        }

                        <tr>
                            <td>เช็ค</td>
                            <td className="text-center">{chequeDepositCount > 0 ? chequeDepositCount : '-'}</td>
                            <td className="text-right">{this.numberWithComma(summaryReceive_cheque_deposit_total)}</td>
                        </tr>

                        <tr>
                            <td>รับล่วงหน้า</td>
                            <td className="text-center">{receiptDepositTransactionUsedCount > 0 ? receiptDepositTransactionUsedCount : '-'}</td>
                            <td className="text-right">{this.numberWithComma(summaryReceive_receipt_deposit_used_total)}</td>
                        </tr>

                        <tr>
                            <td>เงินรับรอการตรวจสอบ</td>
                            <td className="text-center">{unknownReceiveCount > 0 ? unknownReceiveCount : '-'}</td>
                            <td className="text-right">{this.numberWithComma(summaryReceive_unknown_receive_total)}</td>
                        </tr>

                        <tr>
                            <td>อื่นๆ</td>
                            <td className="text-center">{otherCount > 0 ? otherCount : '-'}</td>
                            <td className="text-right">{this.numberWithComma(summaryReceive_other_total)}</td>
                        </tr>

                        <tr>
                            <th className="text-center">รวม</th>
                            <th className="text-center">{total_count_recive > 0 ? total_count_recive : '-'}</th>
                            <th className="text-right">{this.numberWithComma(summaryReceive_total)}</th>
                        </tr>
                    </thead>
                </table>
            </div>
        )
        // หัวตาราง
        let headerTable = (
            <thead className="tableHead">
                <tr>
                    <th rowSpan="2" className="text-center width1">ลำดับ</th>
                    <th rowSpan="2" className="text-center width8">เลขที่ใบเสร็จ</th>
                    <th rowSpan="2" className="text-center width5">วันที่ออกใบเสร็จ</th>
                    <th rowSpan="2" className="text-center width3">เลขที่ห้อง/บ้านเลขที่</th>
                    <th rowSpan="2" className="text-center width13">ชื่อ</th>
                    <th rowSpan="2" className="text-center width8">เลขที่ใบแจ้งหนี้</th>
                    <th rowSpan="2" className="text-center width5">วันที่ออกใบแจ้งหนี้</th>
                    <th rowSpan="2" className="text-center width2">รหัส</th>
                    <th rowSpan="2" className="text-center width13">รายละเอียด</th>
                    <th rowSpan="2" className="text-center width5">จำนวนเงินแจ้งหนี้</th>
                    <th colSpan={col_span} className="text-center width20">วิธีการชำระเงิน</th>
                    {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                        <th rowSpan="2" className="text-center width2">รูปแบบเพย์เมนท์เกตเวย์</th>
                    }
                    <th rowSpan="2" className="text-center width5">จำนวนเงิน</th>
                    <th rowSpan="2" className="text-center width5">จำนวนเงินรวม</th>
                </tr>
                <tr>
                    <React.Fragment>
                        <th className="text-center width2_5">เงินสด</th>
                        <th className="text-center width2_5">เงินโอน</th>
                        <th className="text-center width2_5">Cross bill</th>
                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on &&
                            <th className="text-center width2_5">บัตรเครดิต</th>
                        }
                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                            <th className="text-center width2_5">เพย์เมนท์เกตเวย์</th>
                        }
                        <th className="text-center width2_5">เช็ค</th>
                        <th className="text-center width2_5">รับล่วงหน้า</th>
                        <th className="text-center width2_5">เงินรับรอการตรวจสอบ</th>
                        <th className="text-center width2_5">อื่นๆ</th>
                    </React.Fragment>
                </tr>
            </thead>
        )
        // ตารางข้อมูลหลัก
        let countRow = 0
        {
            this.props.props.receiveViewer.allReceive.edges.map((receive, index) => {
                // let order_id = this.props.state.first - localStorage.getPageLimit();
                let accumulate = 0;
                let color = ""
                let receive_select = null;
                let minusUse = false;
                let minusType = ''

                //ตัวแปรจะเหมือนข้างบนอีก 1 ลูป
                let cashDepositReceiveTransactionTotal = 0
                let bankAccountTransactionReceiveTotal = 0
                let crossBankTotal = 0
                let creditCardTransactionTotal = 0
                let paymentGatewayTransactionTotal = 0
                let chequeDepositTotal = 0
                let receiptDepositTotal = 0 // รับล่วงหน้า
                let creditNoteReceiveTotal = 0 // หักลดหนี้
                let feeAccountingTotal = 0 // ค่าธรรมเนียม
                let unknownReceiveTotal = 0 // เงินรับรอการตรวจสอบ
                let smallChangeTotal = 0 // ส่วนต่าง
                let withheldTaxReceiveTotal = 0 // ภาษีหัก ณ ที่จ่าย
                let receiptDepositTransactionUsedTotal = 0
                let discountTotalOld = 0
                let text_type_paymentType = receive.node.paymentGatewayMethod.edges[0]?.node.paymentType || ""

                cashDepositReceiveTransactionTotal = this.sumAmountObject(receive.node.cashDepositReceiveTransaction.edges, "price", receive.node.status)
                bankAccountTransactionReceiveTotal = this.sumAmountObjectBank(receive.node.bankAccountTransactionReceive.edges, "price", receive.node.status)
                crossBankTotal = this.sumAmountObject(receive.node.crossBank.edges, "price", receive.node.status)
                creditCardTransactionTotal = this.sumAmountObject(receive.node.creditCardTransaction.edges, "amount", receive.node.status)

                // สำหรับ Crossbill ต้องเอาไปรวมไว้กับตัว Crossbill
                if (text_type_paymentType === 'crossbill') {
                    crossBankTotal += this.sumAmountObject(receive.node.paymentGatewayMethod.edges, "total", receive.node.status)
                } else {
                    paymentGatewayTransactionTotal = this.sumAmountObject(receive.node.paymentGatewayMethod.edges, "total", receive.node.status)
                }

                chequeDepositTotal = this.sumAmountObject(receive.node.chequeDeposit.edges, "price", receive.node.status)
                receiptDepositTotal = this.sumAmountObject(receive.node.receiptDeposit.edges, "total", receive.node.status)
                creditNoteReceiveTotal = this.sumAmountObject(receive.node.creditNoteReceive.edges, "price", receive.node.status)
                feeAccountingTotal = this.sumAmountObject(receive.node.feeAccounting.edges, "amount", receive.node.status)
                unknownReceiveTotal = this.sumAmountObject(receive.node.unknownReceive.edges, "amount", receive.node.status)
                smallChangeTotal = this.sumAmountObject(receive.node.smallChange.edges, "amount", receive.node.status)
                withheldTaxReceiveTotal = this.sumAmountObject(receive.node.withheldTaxReceive.edges, "total", receive.node.status)
                receive.node.receiveTransaction.edges.map((receive_transaction) => {
                    receiptDepositTransactionUsedTotal = receiptDepositTransactionUsedTotal + this.sumAmountObject(receive_transaction.node.receiptDepositTransactionUsed.edges, "amount", receive.node.status)
                })

                let paidTotal = cashDepositReceiveTransactionTotal + bankAccountTransactionReceiveTotal + crossBankTotal + creditCardTransactionTotal + chequeDepositTotal + paymentGatewayTransactionTotal
                paidTotal = paidTotal + receiptDepositTransactionUsedTotal + creditNoteReceiveTotal + feeAccountingTotal + unknownReceiveTotal
                paidTotal = paidTotal + smallChangeTotal + withheldTaxReceiveTotal

                receive_select = receive.node.receiveTransaction.edges
                if (this.props.state.product_and_service === "") {
                    receive_select = receive.node.receiveTransaction.edges
                }
                else {
                    receive_select = receive.node.receiveTransaction.edges.filter(element => {
                        if (element.node.transaction.productAndService) {
                            if (element.node.transaction.productAndService.id === this.props.state.product_and_service) {
                                return element
                            }
                        }
                    });
                }

                let minus_receive_select = receive_select.filter(receive_transaction => receive_transaction.node.amount < 0)
                let minusAll = 0
                minus_receive_select.forEach(element => {
                    minusAll += element.node.amount
                });
                receive_select = receive_select.filter(receive_transaction => receive_transaction.node.amount > 0)
                let number_of_transaction = receive_select.length;
                // render This 1 to many transaction
                receive_select.map((receive_transaction, t_index) => {

                    // let receive_transaction_amount = receive_transaction.node.amount - Math.abs(minusAll)
                    let receive_transaction_amount = receive_transaction.node.amount

                    let remainCurrentTransaction = receive_transaction_amount
                    let cashDepositReceiveTransaction = 0
                    let bankAccountTransactionReceive = 0
                    let crossBank = 0
                    let creditCardTransaction = 0
                    let paymentGatewayTransaction = 0
                    let chequeDeposit = 0
                    let receiveTransactionDiscount = 0
                    let creditNoteReceive = 0
                    let feeAccounting = 0
                    let unknownReceive = 0
                    let smallChange = 0
                    let withheldTaxReceive = 0
                    let receiptDepositTransactionUsed = 0

                    // รับล่วงหน้าที่เกิน
                    // if(remainCurrentTransaction > 0) {
                    //     let cutReceiptDeposit = this.cutCash(remainCurrentTransaction, receiptDepositTotal, receiptDeposit)
                    //     remainCurrentTransaction = cutReceiptDeposit.remainCurrent
                    //     receiptDepositTotal = cutReceiptDeposit.cashTotal
                    //     receiptDeposit = cutReceiptDeposit.displayCash
                    // } else {
                    //     receiptDeposit = 0
                    // }

                    // ลดหนี้
                    if (remainCurrentTransaction > 0) {
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && creditNoteReceiveTotal !== 0){
                            creditNoteReceiveTotal = creditNoteReceiveTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'creditNote'
                        }
                        let cutCreditNoteReceive = this.cutCash(remainCurrentTransaction, creditNoteReceiveTotal, creditNoteReceive)
                        remainCurrentTransaction = cutCreditNoteReceive.remainCurrent
                        creditNoteReceiveTotal = cutCreditNoteReceive.cashTotal
                        creditNoteReceive = cutCreditNoteReceive.displayCash
                    } else {
                        creditNoteReceive = 0
                    }

                    // รับล่วงหน้า
                    if (remainCurrentTransaction > 0) {
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && receiptDepositTransactionUsedTotal !== 0){
                            receiptDepositTransactionUsedTotal = receiptDepositTransactionUsedTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'receiptDeposit'
                        }
                        let cutreceiptDepositTransactionUsed = this.cutCash(remainCurrentTransaction, receiptDepositTransactionUsedTotal, receiptDepositTransactionUsed)
                        remainCurrentTransaction = cutreceiptDepositTransactionUsed.remainCurrent
                        receiptDepositTransactionUsedTotal = cutreceiptDepositTransactionUsed.cashTotal
                        receiptDepositTransactionUsed = cutreceiptDepositTransactionUsed.displayCash
                    } else {
                        receiptDepositTransactionUsed = 0
                    }

                    // ส่วนลด
                    let receiveTransactionDiscountTotal = receive_transaction.node.discount
                    paidTotal = paidTotal + receiveTransactionDiscountTotal
                    discountTotalOld = discountTotalOld + receiveTransactionDiscountTotal
                    if (remainCurrentTransaction > 0) {
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && receiveTransactionDiscountTotal !== 0){
                            receiveTransactionDiscountTotal = receiveTransactionDiscountTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'receiveTransactionDiscount'
                        }

                        let cutReceiveTransactionDiscount = this.cutCash(remainCurrentTransaction, receiveTransactionDiscountTotal, receiveTransactionDiscount)
                        remainCurrentTransaction = cutReceiveTransactionDiscount.remainCurrent
                        receiveTransactionDiscountTotal = cutReceiveTransactionDiscount.cashTotal
                        receiveTransactionDiscount = cutReceiveTransactionDiscount.displayCash
                    } else {
                        receiveTransactionDiscount = 0
                    }


                    // เงินรับรอการตรวจสอบ
                    if (remainCurrentTransaction > 0) {
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && unknownReceiveTotal !== 0){
                            unknownReceiveTotal = unknownReceiveTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'unknownReceive'
                        }
                        let cutUnknownReceive = this.cutCash(remainCurrentTransaction, unknownReceiveTotal, unknownReceive)
                        remainCurrentTransaction = cutUnknownReceive.remainCurrent
                        unknownReceiveTotal = cutUnknownReceive.cashTotal
                        unknownReceive = cutUnknownReceive.displayCash
                    } else {
                        unknownReceive = 0
                    }


                    // เงินสด
                    if (remainCurrentTransaction > 0) {
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && cashDepositReceiveTransactionTotal !== 0){
                            cashDepositReceiveTransactionTotal = cashDepositReceiveTransactionTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'cash'
                        }
                        let cutCashDepositReceive = this.cutCash(remainCurrentTransaction, cashDepositReceiveTransactionTotal, cashDepositReceiveTransaction)
                        remainCurrentTransaction = cutCashDepositReceive.remainCurrent
                        cashDepositReceiveTransactionTotal = cutCashDepositReceive.cashTotal
                        cashDepositReceiveTransaction = cutCashDepositReceive.displayCash
                    } else {
                        cashDepositReceiveTransaction = 0
                    }

                    // เงินโอน
                    if (remainCurrentTransaction > 0) {
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && bankAccountTransactionReceiveTotal !== 0){
                            bankAccountTransactionReceiveTotal = bankAccountTransactionReceiveTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'bank'
                        }
                        let cutBankAccountTransactionReceive = this.cutCash(remainCurrentTransaction, bankAccountTransactionReceiveTotal, bankAccountTransactionReceive)
                        remainCurrentTransaction = cutBankAccountTransactionReceive.remainCurrent
                        bankAccountTransactionReceiveTotal = cutBankAccountTransactionReceive.cashTotal
                        bankAccountTransactionReceive = cutBankAccountTransactionReceive.displayCash
                    } else {
                        bankAccountTransactionReceive = 0
                    }

                    // crossBank
                    if (remainCurrentTransaction > 0) {
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && crossBankTotal !== 0){
                            crossBankTotal = crossBankTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'crossBank'
                        }
                        let cutCrossBank = this.cutCash(remainCurrentTransaction, crossBankTotal, crossBank)
                        remainCurrentTransaction = cutCrossBank.remainCurrent
                        crossBankTotal = cutCrossBank.cashTotal
                        crossBank = cutCrossBank.displayCash
                    } else {
                        crossBank = 0
                    }

                    // บัตรเครดิต
                    if (remainCurrentTransaction > 0) {
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && creditCardTransactionTotal !== 0){
                            creditCardTransactionTotal = creditCardTransactionTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'creditCard'
                        }
                        let cutCreditCardTransaction = this.cutCash(remainCurrentTransaction, creditCardTransactionTotal, creditCardTransaction)
                        remainCurrentTransaction = cutCreditCardTransaction.remainCurrent
                        creditCardTransactionTotal = cutCreditCardTransaction.cashTotal
                        creditCardTransaction = cutCreditCardTransaction.displayCash
                    } else {
                        creditCardTransaction = 0
                    }

                    // เพย์เมนท์เกตเวย์
                    if (remainCurrentTransaction > 0) {
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && creditCardTransactionTotal !== 0){
                            paymentGatewayTransactionTotal = paymentGatewayTransactionTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'paymentGateway'
                        }
                        let cutPaymentGatewayTransaction = this.cutCash(remainCurrentTransaction, paymentGatewayTransactionTotal, paymentGatewayTransaction)
                        remainCurrentTransaction = cutPaymentGatewayTransaction.remainCurrent
                        paymentGatewayTransactionTotal = cutPaymentGatewayTransaction.cashTotal
                        paymentGatewayTransaction = cutPaymentGatewayTransaction.displayCash
                    } else {
                        paymentGatewayTransaction = 0
                    }

                    // เช็ค
                    if (remainCurrentTransaction > 0) {
                        if(t_index === number_of_transaction - 1 && minusAll !== 0 && chequeDepositTotal !== 0){
                            chequeDepositTotal = chequeDepositTotal + Math.abs(minusAll)
                            minusAll = 0
                            minusUse = true
                            minusType = 'cheque'
                        }
                        let cutChequeDeposit = this.cutCash(remainCurrentTransaction, chequeDepositTotal, chequeDeposit)
                        remainCurrentTransaction = cutChequeDeposit.remainCurrent
                        chequeDepositTotal = cutChequeDeposit.cashTotal
                        chequeDeposit = cutChequeDeposit.displayCash
                    } else {
                        chequeDeposit = 0
                    }

                    // ค่าธรรมเนียม
                    if (remainCurrentTransaction > 0) {
                        let cutFeeAccounting = this.cutCash(remainCurrentTransaction, feeAccountingTotal, feeAccounting)
                        remainCurrentTransaction = cutFeeAccounting.remainCurrent
                        feeAccountingTotal = cutFeeAccounting.cashTotal
                        feeAccounting = cutFeeAccounting.displayCash
                    } else {
                        feeAccounting = 0
                    }

                    // ส่วนต่างเล็กน้อย
                    if (remainCurrentTransaction > 0) {
                        let cutSmallChange = this.cutCash(remainCurrentTransaction, smallChangeTotal, smallChange)
                        remainCurrentTransaction = cutSmallChange.remainCurrent
                        smallChangeTotal = cutSmallChange.cashTotal
                        smallChange = cutSmallChange.displayCash
                    } else {
                        smallChange = 0
                    }

                    // ภาษี ณ ที่จ่าย
                    if (remainCurrentTransaction > 0) {
                        let cutWithheldTaxReceive = this.cutCash(remainCurrentTransaction, withheldTaxReceiveTotal, withheldTaxReceive)
                        remainCurrentTransaction = cutWithheldTaxReceive.remainCurrent
                        withheldTaxReceiveTotal = cutWithheldTaxReceive.cashTotal
                        withheldTaxReceive = cutWithheldTaxReceive.displayCash
                    } else {
                        withheldTaxReceive = 0
                    }

                    // product and service ยัวไม่เอามาใช้
                    // let transactionReceiveTotal = receive_transaction.node.transaction.productAndService.price
                    // if(remainCurrentTransaction > 0) {
                    //     let cutTransactionReceive = this.cutCash(remainCurrentTransaction, transactionReceiveTotal, transactionReceive)
                    //     remainCurrentTransaction = cutTransactionReceive.remainCurrent
                    //     transactionReceiveTotal = cutTransactionReceive.cashTotal
                    //     transactionReceive = cutTransactionReceive.displayCash
                    // } else {
                    //     transactionReceive = 0
                    // }

                    let remainSmallChangeTotal = 0
                    let remainOtherTotal = 0
                    let remainRdTotal = 0
                    let remainRdTotalSmall = 0
                    let remainRdTotalRecordRt = 0

                    let currentRecordRt = this.props.props.currentRecord.edges.filter(item => {
                        return item.node.group.refTransaction === receive.node.docNumber && item.node.chartOfAccountCode.chartOfAccountCode === "5830-01"
                    })

                    if (receive.node.status !== "VOID") {
                        if (smallChangeTotal > 0 && currentRecordRt.length > 0 && t_index === receive_select.length - 1) {
                            remainSmallChangeTotal = smallChangeTotal * (-1)
                            remainOtherTotal = currentRecordRt[0].node.credit * (-1)

                            let receive_select_productAndService_total = 0
                            let receive_select_productAndService_discount = 0
                            if (this.props.state.product_and_service !== "") {
                                let receive_select_productAndService = receive.node.receiveTransaction.edges.filter(element => {
                                    if (element.node.transaction.productAndService) {
                                        if (element.node.transaction.productAndService.id !== this.props.state.product_and_service) {
                                            return element
                                        }
                                    }
                                });

                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_total = receive_select_productAndService.map(item => item.node.amount).reduce((prev, next) => prev + next);
                                }
                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_discount = receive_select_productAndService.map(item => item.node.discount).reduce((prev, next) => prev + next);
                                }
                            }

                            receive_select_productAndService_total = receive_select_productAndService_total - receive_select_productAndService_discount

                            if (receive.node.receiptDeposit.edges) {
                                receive.node.receiptDeposit.edges.map(item => {
                                    let cashrd = 0
                                    let bankAccountrd = 0
                                    let crossBankrd = 0
                                    let creditCardrd = 0
                                    let chequeDepositrd = 0
                                    let otherrd = 0
                                    if (item.node.cashTransaction.totalCount) {
                                        let tempCash = item.node.cashTransaction.edges.node[0].price
                                        cashrd = tempCash
                                    }
                                    if (item.node.bankAccountTransaction.totalCount) {
                                        let tempBank = item.node.bankAccountTransaction.edges.node[0].price
                                        bankAccountrd = tempBank
                                    }
                                    if (item.node.crossBank.totalCount) {
                                        let tempCross = item.node.crossBank.edges.node[0].price
                                        crossBankrd = tempCross
                                    }
                                    if (item.node.creditCardTransaction.totalCount) {
                                        let tempCreditCard = item.node.creditCardTransaction.edges.node[0].amount
                                        creditCardrd = tempCreditCard
                                    }
                                    if (item.node.chequeDeposit.totalCount) {
                                        let tempCheque = item.node.chequeDeposit.edges.node[0].price
                                        chequeDepositrd = tempCheque
                                    }
                                    if (item.node.receiptDepositTransaction.totalCount) {
                                        let tempOther = item.node.receiptDepositTransaction.edges[0].node.price
                                        otherrd = tempOther
                                    }
                                    let currentRD = cashrd + bankAccountrd + crossBankrd + creditCardrd + chequeDepositrd + otherrd
                                    remainRdTotal = currentRD
                                })
                            }

                            let othSum = smallChangeTotal + Math.abs(remainOtherTotal) + remainRdTotal
                            let othSumNotRd = smallChangeTotal + Math.abs(remainOtherTotal)

                            if (Math.abs(cashDepositReceiveTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                cashDepositReceiveTransaction += othSumNotRd
                            }

                            else if (Math.abs(bankAccountTransactionReceiveTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                bankAccountTransactionReceive += othSumNotRd
                            }

                            else if (Math.abs(crossBankTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                crossBank += othSumNotRd
                            }

                            else if (Math.abs(creditCardTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                creditCardTransaction += othSumNotRd
                            }
                            else if (Math.abs(chequeDepositTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                chequeDeposit += othSumNotRd
                            }
                        }

                        else if (smallChangeTotal > 0 && t_index === receive_select.length - 1) {
                            remainSmallChangeTotal = smallChangeTotal * (-1)

                            let receive_select_productAndService_total = 0
                            let receive_select_productAndService_discount = 0
                            if (this.props.state.product_and_service !== "") {
                                let receive_select_productAndService = receive.node.receiveTransaction.edges.filter(element => {
                                    if (element.node.transaction.productAndService) {
                                        if (element.node.transaction.productAndService.id !== this.props.state.product_and_service) {
                                            return element
                                        }
                                    }
                                });

                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_total = receive_select_productAndService.map(item => item.node.amount).reduce((prev, next) => prev + next);
                                }
                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_discount = receive_select_productAndService.map(item => item.node.discount).reduce((prev, next) => prev + next);
                                }
                            }

                            receive_select_productAndService_total = receive_select_productAndService_total - receive_select_productAndService_discount

                            if (receive.node.receiptDeposit.edges) {
                                receive.node.receiptDeposit.edges.map(item => {
                                    let cashrd = 0
                                    let bankAccountrd = 0
                                    let crossBankrd = 0
                                    let creditCardrd = 0
                                    let chequeDepositrd = 0
                                    let otherrd = 0
                                    if (item.node.cashTransaction.totalCount) {
                                        let tempCash = item.node.cashTransaction.edges.node[0].price
                                        cashrd = tempCash
                                    }
                                    if (item.node.bankAccountTransaction.totalCount) {
                                        let tempBank = item.node.bankAccountTransaction.edges.node[0].price
                                        bankAccountrd = tempBank
                                    }
                                    if (item.node.crossBank.totalCount) {
                                        let tempCross = item.node.crossBank.edges.node[0].price
                                        crossBankrd = tempCross
                                    }
                                    if (item.node.creditCardTransaction.totalCount) {
                                        let tempCreditCard = item.node.creditCardTransaction.edges.node[0].amount
                                        creditCardrd = tempCreditCard
                                    }
                                    if (item.node.chequeDeposit.totalCount) {
                                        let tempCheque = item.node.chequeDeposit.edges.node[0].price
                                        chequeDepositrd = tempCheque
                                    }
                                    if (item.node.receiptDepositTransaction.totalCount) {
                                        let tempOther = item.node.receiptDepositTransaction.edges[0].node.price
                                        otherrd = tempOther
                                    }
                                    let currentRD = cashrd + bankAccountrd + crossBankrd + creditCardrd + chequeDepositrd + otherrd
                                    remainRdTotalSmall = currentRD
                                })
                            }

                            let othSum = smallChangeTotal + remainRdTotalSmall

                            if (Math.abs(cashDepositReceiveTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                cashDepositReceiveTransaction += smallChangeTotal
                            }

                            else if (Math.abs(bankAccountTransactionReceiveTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                bankAccountTransactionReceive += smallChangeTotal
                            }

                            else if (Math.abs(crossBankTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                crossBank += smallChangeTotal
                            }

                            else if (Math.abs(creditCardTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                creditCardTransaction += smallChangeTotal
                            }
                            else if (Math.abs(chequeDepositTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                chequeDeposit += smallChangeTotal
                            }
                        }

                        else if (currentRecordRt.length > 0 && t_index === receive_select.length - 1) {
                            remainOtherTotal = currentRecordRt[0].node.credit * (-1)

                            let receive_select_productAndService_total = 0
                            let receive_select_productAndService_discount = 0
                            if (this.props.state.product_and_service !== "") {
                                let receive_select_productAndService = receive.node.receiveTransaction.edges.filter(element => {
                                    if (element.node.transaction.productAndService) {
                                        if (element.node.transaction.productAndService.id !== this.props.state.product_and_service) {
                                            return element
                                        }
                                    }
                                });

                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_total = receive_select_productAndService.map(item => item.node.amount).reduce((prev, next) => prev + next);
                                }
                                if (Object.keys(receive_select_productAndService).length > 0) {
                                    receive_select_productAndService_discount = receive_select_productAndService.map(item => item.node.discount).reduce((prev, next) => prev + next);
                                }
                            }

                            receive_select_productAndService_total = receive_select_productAndService_total - receive_select_productAndService_discount

                            if (receive.node.receiptDeposit.edges) {
                                receive.node.receiptDeposit.edges.map(item => {
                                    let cashrd = 0
                                    let bankAccountrd = 0
                                    let crossBankrd = 0
                                    let creditCardrd = 0
                                    let chequeDepositrd = 0
                                    let otherrd = 0
                                    if (item.node.cashTransaction.totalCount) {
                                        let tempCash = item.node.cashTransaction.edges.node[0].price
                                        cashrd = tempCash
                                    }
                                    if (item.node.bankAccountTransaction.totalCount) {
                                        let tempBank = item.node.bankAccountTransaction.edges.node[0].price
                                        bankAccountrd = tempBank
                                    }
                                    if (item.node.crossBank.totalCount) {
                                        let tempCross = item.node.crossBank.edges.node[0].price
                                        crossBankrd = tempCross
                                    }
                                    if (item.node.creditCardTransaction.totalCount) {
                                        let tempCreditCard = item.node.creditCardTransaction.edges.node[0].amount
                                        creditCardrd = tempCreditCard
                                    }
                                    if (item.node.chequeDeposit.totalCount) {
                                        let tempCheque = item.node.chequeDeposit.edges.node[0].price
                                        chequeDepositrd = tempCheque
                                    }
                                    if (item.node.receiptDepositTransaction.totalCount) {
                                        let tempOther = item.node.receiptDepositTransaction.edges[0].node.price
                                        otherrd = tempOther
                                    }
                                    let currentRD = cashrd + bankAccountrd + crossBankrd + creditCardrd + chequeDepositrd + otherrd
                                    remainRdTotalRecordRt = currentRD
                                })
                            }

                            let othSum = Math.abs(remainOtherTotal) + remainRdTotalRecordRt

                            if (Math.abs(cashDepositReceiveTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                cashDepositReceiveTransaction += Math.abs(remainOtherTotal)
                            }

                            else if (Math.abs(bankAccountTransactionReceiveTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                bankAccountTransactionReceive += Math.abs(remainOtherTotal)
                            }

                            else if (Math.abs(crossBankTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                crossBank += Math.abs(remainOtherTotal)
                            }

                            else if (Math.abs(creditCardTransactionTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                creditCardTransaction += Math.abs(remainOtherTotal)
                            }

                            else if (Math.abs(chequeDepositTotal.toFixed(2) - receive_select_productAndService_total.toFixed(2)) === Math.abs(othSum.toFixed(2))) {
                                chequeDeposit += Math.abs(remainOtherTotal)
                            }
                        }
                    }

                    // อื่นๆ
                    let otherTotal = withheldTaxReceive + smallChange +
                        creditNoteReceive + remainSmallChangeTotal +
                        remainOtherTotal + feeAccounting + receiveTransactionDiscount

                    // รับทั้งหมด
                    let receiveTotal = cashDepositReceiveTransaction + bankAccountTransactionReceive + crossBank + creditCardTransaction + chequeDeposit + paymentGatewayTransaction // ฝั่งซ้าย
                    receiveTotal = receiveTotal + receiptDepositTransactionUsed + unknownReceive // ฝั่งขวา
                    receiveTotal = receiveTotal + otherTotal // อื่นๆ

                    if (receive.node.status !== "VOID") {
                        sum_receive_transaction_amount = sum_receive_transaction_amount + receive_transaction_amount
                        sum_cash = sum_cash + cashDepositReceiveTransaction
                        sum_bankAccount = sum_bankAccount + bankAccountTransactionReceive
                        sum_crossBank = sum_crossBank + crossBank
                        sum_creditCardTransaction = sum_creditCardTransaction + creditCardTransaction
                        sum_paymentGatewayTransaction = sum_paymentGatewayTransaction + paymentGatewayTransaction
                        sum_chequeDeposit = sum_chequeDeposit + chequeDeposit
                        sum_discount = sum_discount + receiveTransactionDiscount
                        // sum_receiptDepositTransactionUsed = sum_receiptDepositTransactionUsed + receiptDepositTransactionUsed
                        // sum_feeAccounting = sum_feeAccounting + feeAccounting
                        sum_unknownReceive = sum_unknownReceive + unknownReceive
                        sum_other = sum_other + otherTotal
                        sum_receiveTotal = sum_receiveTotal + receiveTotal
                    }

                    if (receive.node.status !== "VOID") {
                        accumulate = accumulate + receive_transaction_amount
                        // accumulate = accumulate + receive_transaction_amount + receiptDepositTotal
                    }

                    let row_number = (t_index === 0 && index + 1);
                    // let row_number = index + 1;
                    if (receive.node.status !== "VOID") { total = total + receive_transaction_amount + receiptDepositTotal; color = "text-dark"; }
                    else { color = "text-danger"; }

                    if (receiveTotal.toFixed(2) !== receive_transaction_amount.toFixed(2) && receive.node.status !== "VOID") {
                        color = "text-danger"
                    } else if (receiveTotal.toFixed(2) !== receive_transaction_amount.toFixed(2) || receive.node.status === "VOID") {
                        color = "text-danger"
                    }


                    let paidTotalCurrent = cashDepositReceiveTransactionTotal + bankAccountTransactionReceiveTotal + crossBankTotal + creditCardTransactionTotal + chequeDepositTotal + paymentGatewayTransactionTotal
                    paidTotalCurrent = paidTotalCurrent + receiptDepositTransactionUsedTotal + receiveTransactionDiscountTotal + creditNoteReceiveTotal + feeAccountingTotal + unknownReceiveTotal
                    paidTotalCurrent = paidTotalCurrent + smallChangeTotal + withheldTaxReceiveTotal

                    let lastPaidTotalCurrent = 0
                    let rdFont = "text-center text-dark"
                    if (t_index === (number_of_transaction - 1)) {
                        if (paidTotalCurrent.toFixed(2) > 0 && receive.node.status !== "VOID") {
                            lastPaidTotalCurrent = paidTotalCurrent

                            if (receive.node.status !== "VOID") {
                                accumulate = accumulate + receiptDepositTotal
                            }
                        }
                    }

                    transaction = (
                        <React.Fragment key={receive_transaction.node.id}>
                            <tr >
                                <td className={"text-center f8"}>{row_number}</td>
                                <td className={"text-center f8"}>
                                    {receive.node.docNumber}
                                </td>
                                <td className={"text-center f8"}>{format(receive.node.issuedDate, 'DD/MM/YYYY')}</td>
                                <td className={"text-center f8"}>
                                    {receive.node.contact.residential ? receive.node.contact.residential.name : "-"}
                                </td>
                                <td className={"text-left f8"}>
                                    {receive.node.contact.typeOfContact === "RESIDENTIAL" &&
                                        getNameResidential(receive.node.firstName, receive.node.lastName)
                                    }

                                    {receive.node.contact.typeOfContact === "SUPPLIER" &&
                                        receive.node.name
                                    }
                                    {" "}
                                    {
                                        (receive.node.payGroup === "RENT" || receive.node.payGroup === "AGENT" || receive.node.payGroup === "OWNER" || receive.node.payGroup === "DEVELOPER") &&
                                        <label>
                                            ({receive.node.payGroup === "RENT" || receive.node.payGroup === "AGENT" || receive.node.payGroup === "OWNER" || receive.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${receive.node.payGroup}`) : ""})
                                        </label>
                                    }
                                    {" "}
                                    {receive.node.status === "VOID" &&
                                        <span> (ยกเลิก)</span>
                                    }
                                </td>
                                <td className={"text-center f8"}>{receive_transaction.node.transaction.invoice.docNumber}</td>
                                <td className={"text-center f8"}>{format(receive_transaction.node.transaction.invoice.issuedDate, 'DD/MM/YYYY')}</td>
                                <td className={"text-center f8"}>{(receive_transaction.node.transaction.productAndService.productCode)}</td>
                                <td className={"text-left f8"}>{this.removeTrailingZeros(receive_transaction.node.transaction.description)}</td>

                                <td className={"text-right f8"}>{receive.node.status === "VOID" ? '-' : this.numberWithComma(receive_transaction_amount)}</td>
                                <React.Fragment>
                                    <td className={"text-right f8"}>{receive.node.status === "VOID" ? '' : this.numberWithComma(cashDepositReceiveTransaction, "-")}</td>
                                    <td className={"text-right f8"}>{receive.node.status === "VOID" ? '' : this.numberWithComma(bankAccountTransactionReceive, "-")}</td>
                                    <td className={"text-right f8"}>{receive.node.status === "VOID" ? '' : this.numberWithComma(crossBank, "-")}</td>
                                    {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on &&
                                        <td className={"text-right f8"}>{receive.node.status === "VOID" ? '' : this.numberWithComma(creditCardTransaction, "-")}</td>
                                    }
                                    {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                                        <td className={"text-right f8"}>{receive.node.status === "VOID" ? '' : this.numberWithComma(paymentGatewayTransaction, "-")}</td>
                                    }
                                    <td className={"text-right f8"}>{receive.node.status === "VOID" ? '' : this.numberWithComma(chequeDeposit, "-")}</td>
                                    <td className={"text-right f8"}>{receive.node.status === "VOID" ? '' : this.numberWithComma(receiptDepositTransactionUsed, "-")}</td>

                                    {/* <td className={"text-right f8"}>{receive.node.status === "VOID" ? '' : this.numberWithComma(receiveTransactionDiscount, "")}</td> */}
                                    {/* <td className={"text-right f8"}>{receive.node.status === "VOID" ? '' : this.numberWithComma(feeAccounting, "")}</td> */}

                                    <td className={"text-right f8"}>{receive.node.status === "VOID" ? '' : this.numberWithComma(unknownReceive, "-")}</td>
                                    <td className={"text-right f8"}>{
                                        receive.node.status === "VOID" ? '' :
                                            this.props.state.temp_product_and_service || checkValueLast ?
                                                this.checkValueLast(receive_transaction.node.transaction.productAndService.productCode, otherTotal) :
                                                otherTotal < 0 ? `-${this.numberWithComma(otherTotal * (-1), "-")}` :
                                                    this.numberWithComma(otherTotal, "-")
                                    }</td>
                                </React.Fragment>
                                {/* รูปแบบเพย์เมนท์เกตเวย์ */}
                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                                    <td className={"text-center f8"}>{text_type_paymentType !== 'crossbill' ? text_type_paymentType : ''}</td>
                                }

                                <td className={"text-right f8"}>{receive.node.status === "VOID" ? '' : (checkValueLast ? this.numberWithComma((-otherTotal) + receiveTotal) : this.numberWithComma(receiveTotal, "-"))}</td>

                                <td className={"text-right f8"}>{
                                    receive.node.status === "VOID" ? '-' :
                                        receive.node.status !== "VOID" && t_index === number_of_transaction - 1 && (lastPaidTotalCurrent === 0 || receive.node.receiptDeposit.edges.length === 0) && minusUse === false ?
                                            // receive.node.status !== "VOID" && t_index === number_of_transaction - 1 && receive.node.receiptDeposit.edges.length === 0 ?
                                            this.numberWithComma(accumulate)
                                            : "-"
                                }</td>
                            </tr>

                            {/* RD เกิดจาก RT รับเกิน */}
                            {
                                receive.node.status !== "VOID" && lastPaidTotalCurrent > 0 && receive.node.receiptDeposit.edges ?
                                    receive.node.receiptDeposit.edges.map(item => {
                                        let cashrd = 0
                                        let bankAccountrd = 0
                                        let crossBankrd = 0
                                        let creditCardrd = 0
                                        let chequeDepositrd = 0
                                        let otherrd = 0

                                        if (receive.node.status !== "VOID") {
                                            if (item.node.cashTransaction.totalCount) {
                                                let tempCash = item.node.cashTransaction.edges.node[0].price
                                                cashrd = tempCash
                                            }
                                            if (item.node.bankAccountTransaction.totalCount) {
                                                let tempBank = item.node.bankAccountTransaction.edges.node[0].price
                                                bankAccountrd = tempBank
                                            }
                                            if (item.node.crossBank.totalCount) {
                                                let tempCross = item.node.crossBank.edges.node[0].price
                                                crossBankrd = tempCross
                                            }
                                            if (item.node.creditCardTransaction.totalCount) {
                                                let tempCreditCard = item.node.creditCardTransaction.edges.node[0].amount
                                                creditCardrd = tempCreditCard
                                            }
                                            if (item.node.chequeDeposit.totalCount) {
                                                let tempCheque = item.node.chequeDeposit.edges.node[0].price
                                                chequeDepositrd = tempCheque
                                            }
                                            if (item.node.receiptDepositTransaction.totalCount) {
                                                let tempOther = item.node.receiptDepositTransaction.edges[0].node.price
                                                otherrd = tempOther
                                            }

                                        }
                                        let currentRD = cashrd + bankAccountrd + crossBankrd + creditCardrd + chequeDepositrd + otherrd

                                        let cashStatus = false
                                        let bankAccountStatus = false
                                        let crossBankStatus = false
                                        let creditCardStatus = false
                                        let chequeStatus = false
                                        let receiptDepositTransactionUseStatus = false
                                        let discountStatus = false
                                        let feeStatus = false
                                        let unknownReceiveStatus = false
                                        let otherStatus = false

                                        if (receive.node.status !== "VOID") {
                                            let temp_otherTotal = Math.abs(otherTotal) + currentRD
                                            if (cashDepositReceiveTransactionTotal.toFixed(2) === temp_otherTotal.toFixed(2)) {
                                                cashStatus = true;
                                                sum_cash += currentRD
                                            }
                                            if (bankAccountTransactionReceiveTotal.toFixed(2) === temp_otherTotal.toFixed(2)) {
                                                bankAccountStatus = true;
                                                sum_bankAccount += currentRD
                                            }
                                            if (crossBankTotal.toFixed(2) === temp_otherTotal.toFixed(2)) {
                                                crossBankStatus = true;
                                                sum_crossBank += currentRD
                                            }
                                            if (creditCardTransactionTotal.toFixed(2) === temp_otherTotal.toFixed(2)) {
                                                creditCardStatus = true;
                                                sum_creditCardTransaction += currentRD
                                            }
                                            if (chequeDepositTotal.toFixed(2) === temp_otherTotal.toFixed(2)) {
                                                chequeStatus = true;
                                                sum_chequeDeposit += currentRD
                                            }
                                            if (receiptDepositTransactionUsedTotal === currentRD) {
                                                receiptDepositTransactionUseStatus = true;
                                            }
                                            if (receiveTransactionDiscountTotal === currentRD) {
                                                discountStatus = true;
                                            }
                                            if (feeAccountingTotal === currentRD) {
                                                feeStatus = true;
                                            }
                                            if (unknownReceiveTotal === currentRD) {
                                                unknownReceiveStatus = true;
                                                sum_unknownReceive += currentRD
                                            }
                                            if (otherTotal === currentRD) {
                                                otherStatus = true;
                                            }
                                            sum_receiveTotal += currentRD
                                        }

                                        let receipt_deposit_description = ''
                                        if (item.node.receiptDepositTransaction.edges.length > 0) {
                                            receipt_deposit_description = item.node.receiptDepositTransaction.edges[0].node.chartOfAccount.chartOfAccountCode +
                                                " " + item.node.receiptDepositTransaction.edges[0].node.chartOfAccount.name
                                        }

                                        return (
                                            <tr key={receive.node.id}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="text-center f8">{item.node.docNumber}</td>
                                                <td></td>
                                                <td></td>
                                                <td className="text-left f8">{item.node.receiptDepositTransaction.edges.length > 0 ? receipt_deposit_description : '-'}</td>
                                                <td className="text-right f8">-</td>
                                                <td className="text-right f8">{cashStatus ? this.numberWithComma(currentRD) : "-"}</td>
                                                <td className="text-right f8">{bankAccountStatus ? this.numberWithComma(currentRD) : "-"}</td>
                                                <td className="text-right f8">{crossBankStatus ? this.numberWithComma(currentRD) : "-"}</td>

                                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on &&
                                                    <td className="text-right f8">{creditCardStatus ? this.numberWithComma(currentRD) : "-"}</td>
                                                }
                                                {/* เพย์เมนต์  */}
                                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                                                    <td />
                                                }

                                                {/* เพย์เมนต์  */}
                                                <td className="text-right f8">{chequeStatus ? this.numberWithComma(currentRD) : "-"}</td>
                                                <td className="text-right f8">{receiptDepositTransactionUseStatus ? this.numberWithComma(currentRD) : "-"}</td>
                                                {/* <td className="text-right f8">{discountStatus ? this.numberWithComma(currentRD) : ""}</td>
                                                                                                        <td className="text-right f8">{feeStatus ? this.numberWithComma(currentRD) : ""}</td> */}
                                                <td className="text-right f8">{unknownReceiveStatus ? this.numberWithComma(currentRD) : "-"}</td>
                                                <td className="text-right f8">{otherStatus ? this.numberWithComma(currentRD) : "-"}</td>
                                                {/* <td></td> */}
                                                {/* <td className="text-right f8">{item.node.receiptDepositTransaction.totalCount > 0 ? this.numberWithComma(item.node.receiptDepositTransaction.edges[0].node.price) : ""}</td> */}
                                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                                                    <td></td>
                                                }
                                                <td className="text-right f8">{this.numberWithComma(item.node.total)}</td>
                                                <td className="text-right f8">{minusUse === false ? this.numberWithComma(accumulate) : "" }</td>
                                            </tr>)
                                    })
                                    : <tr></tr>
                            }

                            {/* ค่าธรรมเนียมจากเพย์เมนต์ */}
                            {
                                receive.node.status !== "VOID" && lastPaidTotalCurrent > 0 && receive.node.paymentGatewayMethod.edges ?
                                    receive.node.paymentGatewayMethod.edges.map(item => {
                                        accumulate += item.node.feeAmount
                                        //Payment แบบ Crossbill จะเข้า Crpssbill
                                        let paymentTypeText = ''
                                        if (item.node.paymentType === 'crossbill') {
                                            sum_crossBank += item.node.feeAmount
                                        } else {
                                            sum_paymentGatewayTransaction += item.node.feeAmount
                                            paymentTypeText = item.node.paymentType
                                        }
                                        summaryReceive_sum_total += item.node.feeAmount
                                        sum_receiveTotal += item.node.feeAmount
                                        return (
                                            <tr key={receive.node.id}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="text-left f8">{item.node.paymentType === 'crossbill' ? "บัญชีพักค่าธรรมเนียม" : "ค่าธรรมเนียมจากเพย์เมนท์เกตเวย์"}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>

                                                {/* Payment แบบ Crossbill */}
                                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on && item.node.paymentType === 'crossbill' &&
                                                    <td className="text-right f8">{this.numberWithComma(item.node.feeAmount)}</td>
                                                }
                                                <td></td>

                                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on &&
                                                    <td></td>
                                                }
                                                {/* payment_gateway ปกติ */}
                                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on && item.node.paymentType !== 'crossbill' &&
                                                    <td className="text-right f8">{this.numberWithComma(item.node.feeAmount)}</td>
                                                }
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                {/* <td></td>
                                                                                                        <td></td> */}
                                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                                                    <td className="text-center f8">{paymentTypeText}</td>
                                                }
                                                <td className="text-right f8">{this.numberWithComma(item.node.feeAmount)}</td>
                                                <td className="text-right f8">{minusUse === false ? this.numberWithComma(accumulate) : "" }</td>
                                            </tr>)
                                    })
                                    : <tr></tr>
                            }

                            {/* ค่าเงินที่ติดลบจากเคส การทำส่วนลดที่ผิดรูปแบบ (นำรหัสรายรับมาทำส่วนลด) */}
                            {
                                receive.node.status !== "VOID" && t_index === number_of_transaction - 1 && minus_receive_select ?
                                minus_receive_select.map(item => {
                                    accumulate += item.node.amount
                                    let cashStatus = minusType === 'cash' ? true : false
                                    let bankAccountStatus = minusType === 'bank' ? true : false
                                    let crossBankStatus = minusType === 'crossBank' ? true : false
                                    let creditCardStatus = minusType === 'creditCard' ? true : false
                                    let chequeStatus = minusType === 'cheque' ? true : false
                                    let receiptDepositTransactionUseStatus = minusType === 'receiptDeposit' ? true : false
                                    let unknownReceiveStatus = minusType === 'unknownReceive' ? true : false
                                    // let receiveTransactionDiscountStatus = minusType === 'receiveTransactionDiscount'? true : false
                                    let paymentGatewayStatus = minusType === 'paymentGateway'? true : false
                                    // let creditNoteStatus = minusType === 'creditNote'? true : false

                                    let otherStatus = minusType !== 'cash' && minusType !== 'bank' && minusType !== 'crossBank' && minusType !== 'paymentGateway'
                                        && minusType !== 'creditCard' && minusType !== 'creditNote' && minusType !== 'receiveTransactionDiscount' 
                                        && minusType !== 'cheque' && minusType !== 'receiptDeposit' && minusType !== 'unknownReceive'
                                        ? true : false


                                    if (receive.node.status !== "VOID") {
                                        ////////////////////// ผลรวมต่างๆ รองบรรทัดสุดท้าย ////////////////////////
                                        sum_receive_transaction_amount += item.node.amount
                                        sum_cash += minusType === 'cash' ? item.node.amount : 0
                                        sum_bankAccount += minusType === 'bank' ? item.node.amount : 0
                                        sum_crossBank += minusType === 'crossBank' ? item.node.amount : 0
                                        sum_creditCardTransaction += minusType === 'creditCard' ? item.node.amount : 0
                                        sum_paymentGatewayTransaction += minusType === 'paymentGateway' ? item.node.amount : 0
                                        sum_chequeDeposit += minusType === 'cheque' ? item.node.amount : 0
                                        sum_discount +=  minusType === 'receiveTransactionDiscount' ? item.node.amount : 0
                                        sum_receiptDepositTransactionUsed +=  minusType === 'receiptDeposit' ? item.node.amount : 0
                                        sum_unknownReceive += minusType === 'unknownReceive' ? item.node.amount : 0
                                        sum_other += otherStatus ? item.node.amount : 0
                                        sum_receiveTotal += item.node.amount
                                        //////////////////////////////////////////////////////////
                                    }
                                    return (
                                        <tr key={receive.node.id}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                            
                                            <td className={"text-center text-danger"}>{item.node.transaction.invoice.docNumber}</td>
                                            <td className={"text-center text-danger"}>{format(item.node.transaction.invoice.issuedDate, 'DD/MM/YYYY')}</td>
                                            <td className={"text-center text-danger"}>{(item.node.transaction.productAndService.productCode)}</td>
                                            <td className={"text-center text-danger"}>{this.removeTrailingZeros(item.node.transaction.description)}</td>
                                            <td className={"text-center text-danger"}>{this.numberWithComma(item.node.amount)}</td>
                                            <td className={"text-center text-danger"}>{cashStatus ? this.numberWithComma(item.node.amount) : ""}</td>
                                            <td className={"text-center text-danger"}>{bankAccountStatus ? this.numberWithComma(item.node.amount) : ""}</td>
                                            <td className={"text-center text-danger"}>{crossBankStatus ? this.numberWithComma(item.node.amount) : ""}</td>

                                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on &&
                                                <td className={"text-center text-danger"}>{creditCardStatus ? this.numberWithComma(item.node.amount) : ""}</td>
                                            }
                                            {/* เพย์เมนต์  */}
                                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                                                <td className={"text-center text-danger"}>{paymentGatewayStatus ? this.numberWithComma(item.node.amount) : ""}</td>
                                            }
                                            <td className={"text-center text-danger"}>{chequeStatus ? this.numberWithComma(item.node.amount) : ""}</td>
                                            <td className={"text-center text-danger"}>{receiptDepositTransactionUseStatus ? this.numberWithComma(item.node.amount) : ""}</td>
                                            <td className={"text-center text-danger"}>{unknownReceiveStatus ? this.numberWithComma(item.node.amount) : ""}</td>
                                            <td className={"text-center text-danger"}>{otherStatus ? this.numberWithComma(item.node.amount) : ""}</td>
                                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                                                <td></td>
                                            }
                                            <td className={"text-center text-danger"}>{this.numberWithComma(item.node.amount)}</td>
                                            <td className={"text-center"}>{this.numberWithComma(accumulate)}</td>
                                        </tr>)
                                })
                                : <tr></tr>
                            }
                        </React.Fragment>
                    )
                    dataBeforeSlice.push(transaction)
                    countRow += 1
                    sumtransaction = (
                        <tr key={receive.node.id}>
                            <td className="text-center text-dark f8" colSpan={9}>รวม</td>
                            <td className="text-right text-dark f8"><strong>{sum_receive_transaction_amount > 0 ? this.numberWithComma(sum_receive_transaction_amount) : "-"}</strong></td>
                            <td className="text-right text-dark f8"><strong>{sum_cash > 0 ? this.numberWithComma(sum_cash) : "-"}</strong></td>
                            <td className="text-right text-dark f8"><strong>{sum_bankAccount > 0 ? this.numberWithComma(sum_bankAccount) : "-"}</strong></td>
                            <td className="text-right text-dark f8"><strong>{sum_crossBank > 0 ? this.numberWithComma(sum_crossBank) : "-"}</strong></td>
                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on &&
                                <td className="text-right text-dark f8"><strong>{sum_creditCardTransaction > 0 ? this.numberWithComma(sum_creditCardTransaction) : "-"}</strong></td>
                            }
                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                                <td className="text-right text-dark f8"><strong>{sum_paymentGatewayTransaction > 0 ? this.numberWithComma(sum_paymentGatewayTransaction) : "-"}</strong></td>
                            }
                            <td className="text-right text-dark f8"><strong>{sum_chequeDeposit > 0 ? this.numberWithComma(sum_chequeDeposit) : "-"}</strong></td>
                            <td className="text-right text-dark f8"><strong>{sum_receiptDepositTransactionUsed > 0 ? this.numberWithComma(sum_receiptDepositTransactionUsed) : "-"}</strong></td>
                            {/* <td className="text-right text-dark f8"><strong>{sum_discount > 0 ? this.numberWithComma(sum_discount) : "-"}</strong></td>
                                                                                    <td className="text-right text-dark f8"><strong>{sum_feeAccounting > 0 ? this.numberWithComma(sum_feeAccounting) : "-"}</strong></td> */}
                            <td className="text-right text-dark f8"><strong>{sum_unknownReceive > 0 ? this.numberWithComma(sum_unknownReceive) : "-"}</strong></td>
                            {/* <td className="text-right text-dark f8"><strong>{sum_other+rd_other > 0 ? this.numberWithComma(sum_other+rd_other) : "-"}</strong></td> */}
                            <td className={"text-right text-dark f8"}><strong>{
                                checkValueLast ? "-" :
                                    (sum_other) < 0 ?
                                        `${this.numberWithComma(-(sum_other) * (-1), "-")}` :
                                        this.numberWithComma((sum_other), "-")}</strong></td>
                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                                <td></td>
                            }
                            <td className="text-right text-dark f8"><strong>{sum_receiveTotal > 0 ? (checkValueLast ? this.numberWithComma((-summaryReceive_other_total) + sum_receiveTotal) : this.numberWithComma(sum_receiveTotal)) : "-"}</strong></td>
                            <td className="text-right f8"><strong>{checkValueLast ? this.numberWithComma((-summaryReceive_other_total) + sum_receiveTotal) : this.numberWithComma(sum_receiveTotal)}</strong></td>
                        </tr>
                    )
                    dataSumBeforeSlice.push(sumtransaction)
                    countRow += 1
                })


            })
        }
        // row รวมผลลัพธ์
        let totalSummary = (
            <tr className='tableFooter'>
                <td className="text-center text-dark f8" colSpan={9}>รวมทั้งหมด</td>
                <td className="text-right text-dark f8"><strong>{summaryReceive_invoice_total > 0 ? this.numberWithComma(summaryReceive_invoice_total) : "-"}</strong></td>
                <td className="text-right text-dark f8"><strong>{summaryReceive_cash_total > 0 ? this.numberWithComma(summaryReceive_cash_total) : "-"}</strong></td>
                <td className="text-right text-dark f8"><strong>{summaryReceive_bank_account_total > 0 ? this.numberWithComma(summaryReceive_bank_account_total) : "-"}</strong></td>
                <td className="text-right text-dark f8"><strong>{summaryReceive_cross_bank_total > 0 ? this.numberWithComma(summaryReceive_cross_bank_total) : "-"}</strong></td>
                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on &&
                    <td className="text-right text-dark f8"><strong>{summaryReceive_credit_card_total > 0 ? this.numberWithComma(summaryReceive_credit_card_total) : "-"}</strong></td>
                }
                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                    <td className="text-right text-dark f8"><strong>{summaryReceive_paymentGateway_total > 0 ? this.numberWithComma(summaryReceive_paymentGateway_total) : "-"}</strong></td>
                }
                <td className="text-right text-dark f8"><strong>{summaryReceive_cheque_deposit_total > 0 ? this.numberWithComma(summaryReceive_cheque_deposit_total) : "-"}</strong></td>
                <td className="text-right text-dark f8"><strong>{summaryReceive_receipt_deposit_used_total > 0 ? this.numberWithComma(summaryReceive_receipt_deposit_used_total) : "-"}</strong></td>
                <td className="text-right text-dark f8"><strong>{summaryReceive_unknown_receive_total > 0 ? this.numberWithComma(summaryReceive_unknown_receive_total) : "-"}</strong></td>
                <td className="text-right text-dark f8"><strong>{
                    checkValueLast ? "-" :
                        summaryReceive_other_total === 0 ? "-" :
                            summaryReceive_other_total < 0 ? `${this.numberWithComma(-(summaryReceive_other_total) * (-1), "-")}` :
                                this.numberWithComma(summaryReceive_other_total)}</strong></td>
                <td colSpan={4} className="text-center f8"><strong>รวมทั้งหมด {checkValueLast ? this.numberWithComma((-summaryReceive_other_total) + summaryReceive_sum_total) : this.numberWithComma(summaryReceive_sum_total)}</strong></td>
            </tr>
        );

        let rowPerPage = 9;
        for (let i = 0; i < dataBeforeSlice.length; i += rowPerPage) {
            // check last page 
            let chunk = dataBeforeSlice.slice(i, i + rowPerPage);
            chunk.push(dataSumBeforeSlice[i + rowPerPage - 1]);
            if (i + rowPerPage > dataBeforeSlice.length) {
                chunk.push(dataSumBeforeSlice[dataSumBeforeSlice.length - 1]);
            }


            dataAll.push(chunk);
        }
        return (
            <ReceiveReportPDFrenderTable
                dataAll={dataAll}
                topTable={topTable}
                headerTable={headerTable}
                printBy={this.state.printBy}
                totalSummary={totalSummary}
                projectName={this.state.projectName}
                contactName={this.state.contactName}
                productAndService={this.state.productAndService}
                paymentChannel={this.state.paymentChannel}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
            />
        );
    }
}
export default ReceiveReportPDFTable

