import React from 'react';
import './styles/inputTax.scss'

import {format} from "date-fns";
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";

import numberWithCommas from "../../../../libs/numberWithComma";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import getNameResidential from "../../../../libs/getNameResidential";

import Loading from "../../../../libs/loading"

const query = graphql`
    query inputTaxListQuery($search: String, $start_date : DateTime, $end_date : DateTime){
        allValueAddedTax(search:$search, startDate:$start_date, endDate:$end_date, typeVat: "input"){
            totalCount
            edges {
                node {
                    invoice {
                        id
                        docNumber
                        issuedDate
                        total
                        contact{
                            refNumber
                            name
                            firstName
                            taxIdNumber
                            lastName
                            typeOfCompany
                            nameBranch
                        }
                        transaction{
                            edges{
                                node{
                                    total
                                    preTaxAmount
                                    productAndService{
                                        type
                                    }
                                }
                            }
                        }
                    }
                    receive{
                        id
                        docNumber
                        issuedDate
                        total
                        contact{
                            refNumber
                            name
                            firstName
                            taxIdNumber
                            lastName
                            typeOfCompany
                            nameBranch
                        }
                        receiveTransaction{
                            edges{
                                node{
                                    transaction{
                                        total
                                        preTaxAmount
                                        productAndService{
                                            type
                                        }
                                    }
                                }
                            }
                        }
                    }
                    otherReceive {
                        id
                        docNumber
                        issuedDate
                        total
                        unknownContact
                        contact{
                            refNumber
                            name
                            firstName
                            taxIdNumber
                            lastName
                            typeOfCompany
                            nameBranch
                        }
                        otherReceiveTransaction {
                            edges{
                                node{
                                    total
                                    preTaxAmount
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`;


class InputTaxList extends ComponentPagination {

    render() {
        return (
            <div className="row">
                <div className="col">
                    <QueryRenderer
                        environment={environment}
                        query={query}
                        variables={{
                            search: this.props.search,
                            start_date: this.props.start_date,
                            end_date: this.props.end_date,
                        }}
                        render={({error, props}) => {
                            if (error) {
                                return <div className="alert alert-danger" role="alert">
                                    {error.message}</div>;
                            } else if (props) {
                                let totalPreTaxAmount = 0;
                                let totalVatAmount = 0;

                                return <React.Fragment>
                                    <div className="table-responsive fade-up">
                                        <table className="table table-hover mt-2 table-bordered">
                                            <thead className="thead-light">
                                            <tr>
                                                <th className="text-center align-middle" rowSpan={2}>ลำดับ</th>
                                                <th className="text-center align-middle" rowSpan={2}>วันที่</th>
                                                <th className="align-middle" rowSpan={2}>เลขที่</th>
                                                <th className="align-middle"
                                                    rowSpan={2}>ชื่อผู้ซื้อสินค้า/ผู้รับบริการ
                                                </th>
                                                <th className="text-center align-middle"
                                                    rowSpan={2}>เลขประจำตัวผู้เสียภาษี
                                                </th>
                                                <th className="text-center" colSpan={2}>สถานประกอบการ</th>
                                                <th className="text-right align-middle"
                                                    rowSpan={2}>มูลค่าสินค้าหรือบริการ
                                                </th>
                                                <th className="text-right align-middle"
                                                    rowSpan={2}>จำนวนเงินภาษีมูลค่าเพิ่ม
                                                </th>
                                            </tr>
                                            <tr>
                                                <th className="text-center">สนญ.</th>
                                                <th>สาขาที่</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {props.allValueAddedTax.edges.map((vat, index) => {
                                                let inputTax = '';
                                                let preTaxAmount = 0;
                                                let vatAmount = 0;

                                                if (vat.node.invoice) {
                                                    inputTax = vat.node.invoice;
                                                    vat.node.invoice.transaction.edges.forEach((transaction) => {
                                                        if (transaction.node.productAndService.type === 'PRODUCT') {
                                                            preTaxAmount = preTaxAmount + transaction.node.preTaxAmount;
                                                            vatAmount = vatAmount + transaction.node.total - transaction.node.preTaxAmount
                                                        }
                                                    });
                                                }
                                                else if (vat.node.receive) {
                                                    inputTax = vat.node.receive;
                                                    vat.node.receive.receiveTransaction.edges.forEach((receive_transaction) => {
                                                        if (receive_transaction.node.transaction.productAndService.type === 'SERVICE') {
                                                            preTaxAmount = preTaxAmount + receive_transaction.node.transaction.preTaxAmount;
                                                            vatAmount = vatAmount + receive_transaction.node.transaction.total - receive_transaction.node.transaction.preTaxAmount
                                                        }
                                                    });
                                                }
                                                else {
                                                    inputTax = vat.node.otherReceive;
                                                    preTaxAmount = vat.node.otherReceive.otherReceiveTransaction.edges[0].node.preTaxAmount;
                                                    vatAmount = vat.node.otherReceive.otherReceiveTransaction.edges[0].node.total - vat.node.otherReceive.otherReceiveTransaction.edges[0].node.preTaxAmount
                                                }

                                                totalPreTaxAmount += preTaxAmount;
                                                totalVatAmount += vatAmount;

                                                return (
                                                    <tr key={inputTax.id}>
                                                        <td className="text-center">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-center">
                                                            {format(inputTax.issuedDate, 'DD/MM/YYYY')}
                                                        </td>
                                                        <td>{inputTax.docNumber}</td>
                                                        {inputTax.contact ?
                                                            <td>{inputTax.contact.refNumber} {inputTax.contact.name} {getNameResidential(inputTax.contact.firstName, inputTax.contact.lastName)}</td>
                                                            :
                                                            <td>{inputTax.unknownContact}</td>
                                                        }
                                                        <td className="text-center">{inputTax.contact ? inputTax.contact.taxIdNumber : '-'}</td>
                                                        {inputTax.contact &&
                                                            inputTax.contact.typeOfCompany === 'HEADQUARTERS' ?
                                                                <td className="text-center">✓</td> : <td/>
                                                        }
                                                        <td>{inputTax.contact ? inputTax.contact.nameBranch : ''}</td>
                                                        <td className="text-right">
                                                            {numberWithCommas(preTaxAmount)}
                                                        </td>
                                                        <td className="text-right">
                                                            {numberWithCommas(vatAmount)}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }

                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td colSpan={7} className="text-right">รวม</td>
                                                <td className="text-right">{numberWithCommas(totalPreTaxAmount)}</td>
                                                <td className="text-right">{numberWithCommas(totalVatAmount)}</td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <Pagination changePage={this.changePage}
                                                    first={this.state.first}
                                                    last={this.state.last}
                                                    totalCount={props.allValueAddedTax.totalCount}/>
                                    </div>
                                </React.Fragment>
                            }
                            return <Loading/>;
                        }}
                    />
                </div>
            </div>

        )
    }
}

export default InputTaxList;
