/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SettingAssetTypeAsset = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type pettyCashRecordFormQueryVariables = {||};
export type pettyCashRecordFormQueryResponse = {|
  +allSetPettyCash: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +description: ?string,
        +withdrawer: ?string,
      |}
    |}>,
  |},
  +viewer: ?{|
    +group5: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
        |}
      |}>,
    |},
    +group2: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
        |}
      |}>,
    |},
    +group1: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
        |}
      |}>,
    |},
  |},
  +allSettingAsset: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +typeAsset: SettingAssetTypeAsset,
        +depreciationRatePercent: ?number,
        +depreciationRate: ?number,
        +lifeTimeYear: ?number,
      |}
    |}>
  |},
|};
export type pettyCashRecordFormQuery = {|
  variables: pettyCashRecordFormQueryVariables,
  response: pettyCashRecordFormQueryResponse,
|};
*/


/*
query pettyCashRecordFormQuery {
  allSetPettyCash(status: "active") {
    totalCount
    edges {
      node {
        id
        docNumber
        description
        withdrawer
      }
    }
  }
  viewer {
    group5: allChartOfAccount(chartOfAccountCode_Istartswith: "5") {
      totalCount
      edges {
        node {
          id
          chartOfAccountCode
          name
        }
      }
    }
    group2: allChartOfAccount(chartOfAccountCode_Istartswith: "2") {
      totalCount
      edges {
        node {
          id
          chartOfAccountCode
          name
        }
      }
    }
    group1: allChartOfAccount(chartOfAccountCode_Istartswith: "1") {
      totalCount
      edges {
        node {
          id
          chartOfAccountCode
          name
        }
      }
    }
    id
  }
  allSettingAsset(settingAssetId: "", typeAsset: "") {
    edges {
      node {
        id
        typeAsset
        depreciationRatePercent
        depreciationRate
        lifeTimeYear
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "status",
      "value": "active"
    }
  ],
  "concreteType": "SetPettyCashNodeConnection",
  "kind": "LinkedField",
  "name": "allSetPettyCash",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SetPettyCashNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SetPettyCashNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "withdrawer",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allSetPettyCash(status:\"active\")"
},
v3 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ChartOfAccountNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "chartOfAccountCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": "group5",
  "args": [
    {
      "kind": "Literal",
      "name": "chartOfAccountCode_Istartswith",
      "value": "5"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "allChartOfAccount(chartOfAccountCode_Istartswith:\"5\")"
},
v5 = {
  "alias": "group2",
  "args": [
    {
      "kind": "Literal",
      "name": "chartOfAccountCode_Istartswith",
      "value": "2"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "allChartOfAccount(chartOfAccountCode_Istartswith:\"2\")"
},
v6 = {
  "alias": "group1",
  "args": [
    {
      "kind": "Literal",
      "name": "chartOfAccountCode_Istartswith",
      "value": "1"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "allChartOfAccount(chartOfAccountCode_Istartswith:\"1\")"
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "settingAssetId",
      "value": ""
    },
    {
      "kind": "Literal",
      "name": "typeAsset",
      "value": ""
    }
  ],
  "concreteType": "SettingAssetNodeConnection",
  "kind": "LinkedField",
  "name": "allSettingAsset",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SettingAssetNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SettingAssetNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "typeAsset",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "depreciationRatePercent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "depreciationRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lifeTimeYear",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allSettingAsset(settingAssetId:\"\",typeAsset:\"\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "pettyCashRecordFormQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "pettyCashRecordFormQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "d61a5ac340174e325ffce4430d8d7414",
    "id": null,
    "metadata": {},
    "name": "pettyCashRecordFormQuery",
    "operationKind": "query",
    "text": "query pettyCashRecordFormQuery {\n  allSetPettyCash(status: \"active\") {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        description\n        withdrawer\n      }\n    }\n  }\n  viewer {\n    group5: allChartOfAccount(chartOfAccountCode_Istartswith: \"5\") {\n      totalCount\n      edges {\n        node {\n          id\n          chartOfAccountCode\n          name\n        }\n      }\n    }\n    group2: allChartOfAccount(chartOfAccountCode_Istartswith: \"2\") {\n      totalCount\n      edges {\n        node {\n          id\n          chartOfAccountCode\n          name\n        }\n      }\n    }\n    group1: allChartOfAccount(chartOfAccountCode_Istartswith: \"1\") {\n      totalCount\n      edges {\n        node {\n          id\n          chartOfAccountCode\n          name\n        }\n      }\n    }\n    id\n  }\n  allSettingAsset(settingAssetId: \"\", typeAsset: \"\") {\n    edges {\n      node {\n        id\n        typeAsset\n        depreciationRatePercent\n        depreciationRate\n        lifeTimeYear\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e564d1d4fbf8a35ce2fd0cf6640b4e41';

module.exports = node;
