import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import ContactTopMenuNavigation from "../../contactTopMenuNavigation";
import "../styles/commonArea.scss";
import ComponentPagination from "../../../libs/componentPagination";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Navigation from "./navigation";
import Search from "../../SearchInput";
import api from "../../../api/index";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import Loading from "../../../libs/loading";
import ReactPaginate from "../../../libs/ReactPaginate";

class CommonAreaList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: true,
      timePeriod: "",
      status: this.props.match.params.status,
      search: "",
      first: 0,
      last: 10,
      page_count: 0,
      num_record: 0,
      all_item: 10,
      page_select: 0,
      index_pages: {},
    };
  }

  async componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { status, search, page_select, timePeriod, all_item, num_record } =
      this.state;
    if (status !== this.props.match.params.status) {
      this.setState({ status: this.props.match.params.status },()=>{
        this.getData();
      });
      
    }

    if (prevState.page_select !== page_select) {
      this.getData(true);
    }
    if (prevState.all_item !== all_item) {
      let first_index = 0;
      let last_index = _.cloneDeep(all_item);
      let index_pages = {};
      let page_count = Math.ceil(num_record / all_item);
      if (page_count > 1) {
        for (let index = 0; index < page_count; index++) {
          index_pages[index] = {
            first: first_index,
            last: last_index,
          };
          if (index == page_count - 2) {
            first_index += all_item;
            last_index +=
              num_record % all_item != 0 ? num_record % all_item : all_item;
          } else {
            first_index += all_item;
            last_index += all_item;
          }
        }
      } else {
        index_pages[0] = {
          first: 0,
          last: num_record,
        };
      }
      this.setState(
        {
          page_select: 0,
          index_pages: index_pages,
        },
        () => this.getData(true)
      );
    }
  }

  getData = (update) => {
    const {
      first,
      last,
      all_item,
      status,
      search,
      timePeriod,
      index_pages,
      page_select,
    } = this.state;
    this.setState({ loading: true });
    api.booking
      .getAllFacility(
        status === "all" ? "" : status,
        search,
        timePeriod,
        update ? index_pages[page_select].first : first,
        update ? index_pages[page_select].last : last
      )
      .then((data) => {
        let first_index = _.cloneDeep(first);
        let last_index = _.cloneDeep(last);
        let index_pages = {};
        let page_count = Math.ceil(data.num_record / this.state.all_item);
        if (page_count > 1) {
          for (let index = 0; index < page_count; index++) {
            index_pages[index] = {
              first: first_index,
              last: last_index,
            };
            if (index == page_count - 2) {
              first_index += all_item;
              last_index +=
                data.num_record % all_item != 0
                  ? data.num_record % all_item
                  : all_item;
            } else {
              first_index += all_item;
              last_index += all_item;
            }
          }
        } else {
          index_pages[0] = {
            first: 0,
            last: data.num_record,
          };
        }
        if (!update) {
          this.setState({
            list: data,
            loading: false,
            page_count: page_count,
            num_record: data.num_record,
            index_pages: index_pages,
          });
        }
        setTimeout(() => {
          this.setState({
            list: data,
            loading: false,
            page_count: page_count,
            num_record: data.num_record,
            index_pages: !update ? index_pages : this.state.index_pages,
          });
        }, 500);
      });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSearch = (e) => {
    const { name, value } = e.target;
    this.setState({ search: value },()=>{
      this.getData();
    });
  };

  handleStatus = (status) => {
    switch (status) {
      case "hours":
        return { text: "ชม." };
      case "round":
        return { text: "รอบ" };
      case "time":
        return { text: "สิทธิ์การจอง" };

      default:
        break;
    }
  };

  handlePageClick = (event) => {
    let page = event.selected;
    this.setState({ page_select: page });
  };

  changePageLimit = (value) => {
    this.setState({
      all_item: value,
    });
  };

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}> 
          <ContactTopMenuNavigation mini={true} close_new />
          <Translation>
            {(t) => (
              <div className="container-fluid box booking-form p-3" id="parcel">
                <div className="row justify-content-start">
                  <div className="col">
                    <h3>
                      <Link to="/contact">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/wrapperContent/back.png"
                          }
                          alt="back"
                          className="back"
                        />
                      </Link>
                      <Translation>
                        {(t) => (
                          <span className="header">{t("newBookingSingha_FacilityList:Facility List")}</span>
                        )}
                      </Translation>
                    </h3>
                  </div>
                </div>
                <div className="row justify-content-end">
                  <div className="col">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_facility_create' }) && 
                      <Link to="/contact/global-area/booking/common-area/create/facility">
                      <button
                        type="button"
                        className="btn btn-primary add float-right"
                      >
                        + {t("newBookingSingha_FacilityList:Add new facility")}
                      </button>
                    </Link>}
                  </div>
                </div>
                <Navigation />
                <div className="content-inner">
                  <div className="d-flex flex-row-reverse mt-2">
                    <div className="ml-3">
                      <Search
                        callback_function={this.onChangeSearch}
                        search={this.state.search}
                        handleSearch={this.handleSearch}
                      />
                    </div>
                    <div className="form-inline float-right">
                      <div className="form-group mb-2 ">
                        <Translation>
                          {(t) => (
                            <label htmlFor="end_date" className="ml-3 mr-3">
                              {t("newBookingSingha_FacilityList:Show period as")}:
                            </label>
                          )}
                        </Translation>
                        <select
                          id="timePeriod"
                          name="timePeriod"
                          className="form-control"
                          onChange={this.handleChange}
                          defaultValue={this.state.timePeriod}
                        >
                          <option value="1">{t("newBookingSingha_FacilityList:Today")}</option>
                          <option value="7">{t("newBookingSingha_FacilityList:This week")}</option>
                          <option value="30">{t("newBookingSingha_FacilityList:This month")}</option>
                          <option value="90">{t("newBookingSingha_FacilityList:3 months")}</option>
                          <option value="">{t("newBookingSingha_FacilityList:All periods")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {!this.state.loading ? (
                  <React.Fragment>
                    <div className="card fade-up mt-4 " id="parcel">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <React.Fragment>
                            <thead>
                              <Translation>
                              {(t) => (
                                <tr>
                                  <th style={{ padding: 16 }}>{t("newBookingSingha_FacilityList:Cover photo")}</th>
                                  <th>{t("newBookingSingha_FacilityList:Facility name")}</th>
                                  <th>{t("newBookingSingha_FacilityList:Description")}</th>
                                  <th>{t("newBookingSingha_FacilityList:Location")}</th>
                                  <th>{t("newBookingSingha_FacilityList:Public open")}</th>
                                  <th>{t("newBookingSingha_FacilityList:Service charge/unit")}</th>
                                  <th>{t("newBookingSingha_FacilityList:Created date")}</th>
                                  <th>{t("newBookingSingha_FacilityList:Latest update")}</th>
                                  <th>{t("newBookingSingha_FacilityList:Created by")}</th>
                                  <th className="text-center">{t("newBookingSingha_FacilityList:Status")}</th>
                                </tr>
                              )}
                            </Translation>
                            </thead>

                            <tbody>
                              {_.map(
                                this.state.list.all_facility,
                                (common_area, index) => (
                                  <React.Fragment key={index}>
                                    <tr className="show">
                                      <td style={{ padding: 16 }}>
                                        {common_area.facility.image_cover && (
                                          <React.Fragment>
                                            <img
                                              src={common_area.facility.image_cover}
                                              alt="parcel"
                                              className="cursor image-booking"
                                            />
                                            &emsp;
                                          </React.Fragment>
                                        )}
                                      </td>
                                      <td style={{ color: "#0382FA" }}>
                                        {_.get(common_area, "facility.status") !==
                                        "closed" ? (
                                          <Link
                                            to={
                                              "/contact/global-area/booking/common-area/edit/facility/" +
                                              common_area.facility.id
                                            }
                                          >
                                            {common_area.facility.name || "-"}
                                          </Link>
                                        ) : (
                                          <span>
                                            {common_area.facility.name || "-"}
                                          </span>
                                        )}
                                      </td>
                                      <td>{common_area.facility.remark || "-"}</td>
                                      <td>
                                        {common_area.facility.location || "-"}
                                      </td>
                                      <td>
                                        {common_area.facility.public_private ===
                                        "public"
                                          ? t("Allaction:Yes")
                                          : t("Allaction:No")}
                                      </td>
                                      <td>
                                        {common_area.facility.price +
                                          " บาท/" +
                                          this.handleStatus(
                                            common_area.facility
                                              .right_per_round_unit
                                          ).text}
                                      </td>
                                      <td>
                                        {format(
                                          new Date(common_area.facility.added),
                                          "DD/MM/YYYY",
                                          {
                                            locale: thLocale,
                                          }
                                        )}
                                      </td>
                                      <td>
                                        {format(
                                          new Date(common_area.facility.updated),
                                          "DD/MM/YYYY",
                                          {
                                            locale: thLocale,
                                          }
                                        )}
                                      </td>
                                      <td>{common_area.facility.creator}</td>
                                      <td width={300} className="text-center">
                                        {_.get(common_area, "facility.status") ===
                                        "open" ? (
                                          <span
                                            className="tag-confirmed"
                                            style={{ color: "#FFFFFF" }}
                                          >
                                            {t("newBookingSingha_FacilityList:Open")}
                                          </span>
                                        ) : _.get(
                                            common_area,
                                            "facility.status"
                                          ) === "temporarily_open" ? (
                                          <span
                                            className="tag-temporarily-open"
                                            style={{ color: "#FFFFFF" }}
                                          >
                                            {t("newBookingSingha_FacilityList:Temporarily reservation")}
                                          </span>
                                        ) : _.get(
                                            common_area,
                                            "facility.status"
                                          ) === "renovate" ? (
                                          <span
                                            className="tag-used"
                                            style={{ color: "#FFFFFF" }}
                                          >
                                          {t("newBookingSingha_FacilityList:Under maintenance")}
                                          </span>
                                        ) : (
                                          _.get(common_area, "facility.status") ===
                                            "closed" && (
                                            <span
                                              className="tag-overdue"
                                              style={{ color: "#FFFFFF" }}
                                            >
                                              {t("newBookingSingha_FacilityList:Permanently closed")}
                                            </span>
                                          )
                                        )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            </tbody>
                          </React.Fragment>
                        </table>
                      </div>
                    </div>
                    <ReactPaginate
                      state={this.state}
                      changePageLimit={this.changePageLimit}
                      handlePageClick={this.handlePageClick}
                    />
                  </React.Fragment>
                ) : (
                  <Loading />
                )}
              </div> 
            )}
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default CommonAreaList;
