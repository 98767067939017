import React, { Component } from "react";
import _ from 'lodash'
import {Link} from "react-router-dom";
import format from 'date-fns/format';
import {Translation} from "react-i18next";
import invoiceViewerQuery from "../debtFreeRequest/createRequest/invoiceViewerQuery";


class DebtFreeCertificationTable extends Component {

    constructor(props){
        super(props)
        this.state = {
            debtFreeCertification : this.props.debtFreeCertification,
            checkNewInvoice : true
        }
    }

    componentDidUpdate(prevProps){
        if(this.state.debtFreeCertification !== this.props.debtFreeCertification){
            this.setState({
                debtFreeCertification : this.props.debtFreeCertification
            }, () => {
                this.getInvoice()
            })
            
        }
    }

    componentDidMount(){
        this.getInvoice()
        
    }

    getInvoice = async() => {
        let listInvoice = []
        listInvoice = await invoiceViewerQuery([this.state.debtFreeCertification.node.debsFreeCertificationRequest.contact.id])
        if(listInvoice?.invoiceViewer.allInvoiceByStatus.totalCount > 0){
           this.setState({checkNewInvoice : false})
        }else{
            this.setState({checkNewInvoice : true})
        }
    }

    render(){
        return(
            <Translation>
                {t =>
                <tr key={this.props.index}>
                    {this.props.status === 'draft'  && 
                        <td className="text-center">
                            <input type="checkbox" onChange={() => this.props.onCheck(this.state.debtFreeCertification.node.id)} checked={this.props.selectedId.includes(this.state.debtFreeCertification.node.id) || false}></input>
                        </td>
                    }
                    {
                        this.props.status === 'active' &&  !this.state.debtFreeCertification.node.statusPrint &&
                        <td className="text-center"></td>
                    }
                    {
                        this.props.status === 'active' &&  this.state.debtFreeCertification.node.statusPrint ?
                        <td className="text-center">
                            {this.state.checkNewInvoice  && <input type="checkbox" onChange={() => this.props.onCheck(this.state.debtFreeCertification.node.id)} checked={this.props.selectedId.includes(this.state.debtFreeCertification.node.id)}></input>}
                        </td>
                        :
                        ""
                    }
                    <td className="text-center">
                        {this.state.debtFreeCertification.node.statusPrint && this.state.debtFreeCertification.node.status === 'active' ? 
                            <Link to={"/accounting/debt_free_certification/form/"+_.lowerCase(this.state.debtFreeCertification.node.status)+'/'+this.state.debtFreeCertification.node.id}>{this.state.debtFreeCertification.node.docNumber}</Link>
                            :
                            this.state.debtFreeCertification.node.docNumber
                        }   
                    </td>
                    <td className="text-center">{this.state.debtFreeCertification.node.debsFreeCertificationRequest.requestingDate ? format(this.state.debtFreeCertification.node.debsFreeCertificationRequest.requestingDate, "DD/MM/YYYY") : '-'}</td>
                    <td className="text-center">{this.state.debtFreeCertification.node.debsFreeCertificationRequest.contact.name}</td>
                    <td className="text-left">{this.state.debtFreeCertification.node.debsFreeCertificationRequest.tenant.firstName} {this.state.debtFreeCertification.node.debsFreeCertificationRequest.tenant.lastName}</td>
                    <td className="text-left">{this.state.debtFreeCertification.node.debsFreeCertificationRequest.creatorApprove}</td>
                    <td className="text-center">{this.state.debtFreeCertification.node.debsFreeCertificationRequest.approvalDate ? format(this.state.debtFreeCertification.node.debsFreeCertificationRequest.approvalDate, "DD/MM/YYYY") : '-'}</td>
                    <td className="text-center">{this.state.debtFreeCertification.node.debsFreeCertificationRequest.docNumber}</td>
                    {this.props.status === 'all' && 
                        <td className="text-center d-flex justify-content-center">
                            <div className={"status text-center " + _.lowerCase(this.state.debtFreeCertification.node.status)}><span>{t('debtFreeCertification:'+ _.lowerCase(this.state.debtFreeCertification.node.status))}</span></div>
                        </td>
                    }
                    {this.props.status === 'active' && <td className="text-center">{this.state.debtFreeCertification.node.printDate ? format(this.state.debtFreeCertification.node.printDate, "DD/MM/YYYY") : '-'}</td>}
                    {this.props.status === 'active' && <td className="text-center">{this.state.debtFreeCertification.node.dueDate ? format(this.state.debtFreeCertification.node.dueDate, "DD/MM/YYYY") : '-'}</td>}
                    {this.props.status === 'active' && <td className="text-center">{!this.state.checkNewInvoice && 'มียอดใบแจ้งหนี้'}</td>}
                    {this.props.status === 'void' && <td className="text-left">{this.state.debtFreeCertification.node.creatorVoid}</td>}
                    {this.props.status === 'void' && <td className="text-center">{this.state.debtFreeCertification.node.voidDate ? format(this.state.debtFreeCertification.node.voidDate, "DD/MM/YYYY") : '-'}</td>}
                    {this.props.status === 'void' && <td className="text-left">{this.state.debtFreeCertification.node.voidNote}</td>}
                </tr>
                }
            </Translation>
        );
    }
}
export default DebtFreeCertificationTable