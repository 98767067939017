import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  addDays,
  parse,
  differenceInCalendarDays,
  format,
  addMonths, /*, addYears*/
  setHours,
  getHours,
  getMinutes,
  setMinutes,
} from "date-fns";
import InvoiceSummary from "./invoiceSummary";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import DatePicker from "react-datepicker";
import numberWithComma from "../../../libs/numberWithComma";
import SearchSelect from "../../../libs/searchSelect";
import "./css/invoiceCreateUpdateForm.scss";
import InvoiceDelete from "./invoiceDelete";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import { commitMutation, QueryRenderer } from "react-relay";
import Swal from "sweetalert2";
import { fetchQuery } from "relay-runtime";
import InvoiceCreateDetailContact from "./invoiceCreateDetailContact";
import SelectRoleAccounting from '../../../components/FucntionLib/selectRoleAccounting';

import { Redirect } from "react-router";
import getDocumentNumber from "../../../libs/getDocumentNumber";
import CreatePurchaseMutation from "../../expense/purchase/mutations/CreatePurchaseMutation";
import OtherReceiver from "../../income/other_receiver/otherReceiverMutation";
import getNameResidential from "../../../libs/getNameResidential";
import getNameResidentialRent from "../../../libs/getNameResidentialRent";
import ReceiveForm from "../../receive/receiveForm";
import CreatePreinvoiceMutation from "../../../setting/settingInvoice/Mutation/CreatePreinvoiceMutation"
import _ from "lodash";

import PeriodAdjustSetting from "./periodAdjustSetting";
import WaterMeterSelectDate from "./waterMeterSelectDate";
import IndividualAreaPriceSettings from "./individualAreaPriceSetting";
import CarParkSelect from "./carParkSelect";

import { Translation } from "react-i18next";
import i18n from "i18next";
import upload from "../../../libs/upload";
import Loading from '../../../libs/loading';
import { getContact } from "../../../api/graphql/getContact";

import API from '../../../api/silverGraund2';
import i18next from "i18next";

import queryCheckHouseHolderPaygroup from "../query/queryCheckHouseHolderPaygroup"

var timeout;
const mutation = graphql`
  mutation invoiceCreateUpdateFormMutation(
    $input: UpdateInvoiceAndTransactionInput!
  ) {
    updateInvoiceAndTransaction(input: $input) {
      ok
      warningText
    }
  }
`;

const query = graphql`
  query invoiceCreateUpdateFormQuery(
    $contact: [String]
    $taxType: String
    $vat: String
    $whtRatePercent: String
    $invoiceDate: DateTime
  ) {
    receiptDepositTransactionActive(
      contact: $contact
      taxType: $taxType
      invoiceDate: $invoiceDate
      vat: $vat
    ) {
      chartOfAccount_Id
      chartOfAccount_Name
      chartOfAccount_Code
      totalBalance
    }
    receiptDepositViewer {
      allReceiptDeposit(
        contactList: $contact
        taxType: $taxType
        invoiceDate: $invoiceDate
        vat: $vat
        status_In: "active, use_partial, use_all"
      ) {
        edges {
          node {
            id
            receiptDepositTransaction {
              edges {
                node {
                  id
                  price
                  balance
                  chartOfAccount {
                    id
                    name
                    chartOfAccountCode
                  }
                  receiptDepositTransactionUsed {
                    edges {
                      node {
                        id
                        amount
                        transaction {
                          invoice {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prepaidDepositViewer {
      allPrepaidDeposit(
        contactList: $contact
        taxType: $taxType
        vat: $vat
        whtRatePercent: $whtRatePercent
        status_In: "active, use_partial, use_all"
      ) {
        edges {
          node {
            id
            prepaidDepositTransaction {
              edges {
                node {
                  id
                  price
                  balance
                  chartOfAccount {
                    id
                    name
                    chartOfAccountCode
                  }
                  prepaidDepositTransactionUsed {
                    edges {
                      node {
                        id
                        amount
                        purchaseRecord {
                          purchaseRecordGroup {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allProjects {
            edges {
            node {
            periodCreate
            }
          }
    }
  }
`;

let transaction_object = {
  description: "",
  discount: 0,
  id: "",
  preTaxAmount: 0,
  price: "",
  productAndService: {},
  unitItems: 1,
  vat: null,
  whtRate: 0,
  whtRatePercent: 0,
  totalLeftInStock: "",
  numberKeyId: "",

  contract_revenue: false,
  contractId: "",
  contractRevenueListTotal: 0,
  contractRevenPaid: 0,
};

class InvoiceCreateUpdateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issuedDate: new Date(),
      temp_month_before: '',
      temp_month_after: '',
      periodCreate: '',
      dueDateCount: 7,
      dueDate: addDays(new Date(), 7),
      taxType:
        !props.project_have_vat &&
          props.contact &&
          props.contact.vat === 7
          ? "INCLUDE_TAX"
          : "NO_TAX",
      remark: "",
      total: 0,
      transaction_list: [],
      redirectToList: false,
      category: props.category,
      receiveDeposit: "0",
      chartOfAccountId: "",
      receiptDepositUse: [],
      product_and_service: props.product_and_service,
      group_detail: props.group_detail,
      tax_invoice_number: "",
      ref_number: "",

      tax_invoice_date: new Date(),
      tax_invoice_period: new Date(),
      loading: false,
      payChannel: [],
      upload: {},
      contactList: props.contactList,
      prepaidDepositTransaction: [],
      list_history: [],
      list_history_checker: true,
      totalPay: 0,
      total_discount: 0,
      total_wht_rate: 0,
      document_upload: "",
      status: "draft",
      vat: null,
      signreq: Math.random().toString(36),
      all_contract_revenue_id: null,
      all_contract_revenue_category: null,
      all_contract_revenue_contract_number: null,
      preinvoice: props.pre_Invoice || false,
      case_return_vat:
        !props.project_have_vat &&
          props.contact &&
          props.contact.vat === 7
          ? "true"
          : false,
      schedule_status: this.props.mode === 'edit' && this.props.group_detail?.scheduleTime ? true : false,
      temp_schedule_date: new Date(),
      date_now: new Date(),
      pay_group: `${this.props.role? this.props.role : "owner"}`,
      name: "",
      address: "",
      onSelectProduct: false,
    };
    this.formRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitFrom = this.submitFrom.bind(this);
    this.appendNewRow = this.appendNewRow.bind(this);
    this.getPreTaxAmount = this.getPreTaxAmount.bind(this);
    this.setTransactionList = this.setTransactionList.bind(this);
    this.submitInvoiceOrOtherReceive = this.submitInvoiceOrOtherReceive.bind(this);
    this.submitPreinvoice = this.submitPreinvoice.bind(this)

    this.onSuccess = this.onSuccess.bind(this);
    this.onSuccessPreinvoice = this.onSuccessPreinvoice.bind(this)
    this.onError = this.onError.bind(this);
    this.updatePayChannel = this.updatePayChannel.bind(this);

    this.handleInputDocument = this.handleInputDocument.bind(this);
    this.setServicePeriod = this.setServicePeriod.bind(this);
    this.setWaterMeterTransaction = this.setWaterMeterTransaction.bind(this);
    this.setElectricMeterTransaction = this.setElectricMeterTransaction.bind(this);
    this.setIndividualTransaction = this.setIndividualTransaction.bind(this);
    this.setCarParkTransaction = this.setCarParkTransaction.bind(this);
    this.getDeposit = this.getDeposit.bind(this);
    this.getRemark = this.getRemark.bind(this);
    this.isLimitDescription = this.isLimitDescription.bind(this);
    this.handleChangeDatetime = this.handleChangeDatetime.bind(this);
    this.setDatetime = this.setDatetime.bind(this);
  }

  removeTrailingZeros(description) {
    if (description.includes("ค่าน้ำประปา")) {
      description = description.replace(/ *\[[^)]*\] */g, " ");
    } else {
      description = description.replace(/ *\[[^)]*\] */g, " ");
    }
    return description;
  }

  componentWillMount() {
    let addrrss = this.props.contactResidential?.length > 0 ? this.addressData(this.props.contactResidential[0]) : null
    let name = this.props.contact ? this.props.contact.typeOfContact === "RESIDENTIAL"
      ? this.props.invoiceQuery.invoice?.payGroup === "RENT"
        ? getNameResidentialRent(this.props.invoiceQuery.invoice.contact.residential.residentialHouseholder?.edges)
        : this.props.mode === 'edit' ? getNameResidential(
          this.props.invoiceQuery.invoice.firstName,
          this.props.invoiceQuery.invoice.lastName
        ) :
          getNameResidential(
            this.props.contact.firstName,
            this.props.contact.lastName
          )
      : this.props.contact.name : null
    this.setState({ address: addrrss, name: name });


    fetchQuery(environment, query, {
      contact: this.props.contactList,
      taxType: this.state.taxType,
      invoiceDate: this.state.issuedDate,
      vat: "",
    }).then((data) => {      
      let data_clone = _.cloneDeep(data);
      this.calPeriodMouth(data_clone.allProjects.edges[0].node.periodCreate)

    });

    this.setState({ tax_invoice_date: "" });
    if (this.state.group_detail) {
      // update
      let issued_date = parse(this.state.group_detail.issuedDate);
      let schedule_time = parse(this.state.group_detail.scheduleTime ? this.state.group_detail.scheduleTime : new Date());
      let due_date = parse(this.state.group_detail.dueDate);
      let due_date_count = differenceInCalendarDays(due_date, issued_date);

      let list = [...this.state.transaction_list];
      let transactions = [...this.props.transactions];

      transactions.forEach((transaction) => {
        transaction = transaction.node;
        if (!_.get(transaction, "productAndService")) {
          _.set(transaction, "productAndService", _.get(transaction, "chartOfAccount"));
        }

        if (transaction.keycardPerInvoice?.edges.length > 0) {
          _.set(transaction, "numberKeyId", transaction.keycardPerInvoice.edges[0].node.numberKey.id)
        }
        list = [...list, transaction];
      });
      this.setState(
        {
          transaction_list: list,
          issuedDate: issued_date,
          dueDate: due_date,
          dueDateCount: due_date_count,
          taxType: this.state.group_detail.taxType,
          remark: this.state.group_detail.remark,
          temp_schedule_date: schedule_time,
          pay_group : this.props.payGroup?.toLowerCase()
        },
        () => this.getDeposit()
      );
    } else {
      // create new
      this.setState(
        {
          transaction_list: [...this.state.transaction_list, { ...transaction_object },],
        },
        () => {
          if (this.state.case_return_vat) {
            let transaction_list = [...this.state.transaction_list];
            transaction_list.forEach((transaction) => {
              transaction["vat"] = 7;
              transaction["preTaxAmount"] = this.getPreTaxAmount(transaction);
              transaction["whtRate"] = this.getWhtRate(transaction);
            });
            this.setState({ transaction_list: [...transaction_list], vat: 7 });
          }
        }
      );
      if (this.state.preinvoice === false || this.props.contactList?.length > 0) {
        fetchQuery(environment, query, {
          contact: this.props.contactList,
          taxType: this.state.taxType,
          invoiceDate: this.state.issuedDate,
          vat: "",
        }).then((data) => {
          let data_clone = _.cloneDeep(data);
          if (data_clone.receiptDepositTransactionActive?.length > 0) {
            this.setState({
              chartOfAccountId: data_clone.receiptDepositTransactionActive[0].chartOfAccount_Id,
              receiveDeposit: data_clone.receiptDepositTransactionActive[0].totalBalance,
            });
          }

          // this.calPeriodMouth(data_clone.allProjects.edges[0].node.periodCreate)

        });
      }
    }

    if (this.props.chart_of_account) {
      let product_and_services = [];
      product_and_services = [...this.props.product_and_service];
      let chart_of_accounts = [...this.props.chart_of_account];

      chart_of_accounts.forEach((chart_of_account) => {
        let list_object = {
          id: chart_of_account.node.id,
          name: chart_of_account.node.name,
          productCode: chart_of_account.node.chartOfAccountCode,
          totalLeftInStock: null,
          price: "",
          chartOfAccount: {
            id: chart_of_account.node.id,
            chartOfAccountCode: chart_of_account.node.chartOfAccountCode,
            name: chart_of_account.node.name,
          },
          type: "chart_of_account",
        };
        product_and_services = [...product_and_services, { node: list_object }];
      });

      this.setState(
        {
          product_and_service: product_and_services,
          taxType:
            !this.props.project_have_vat &&
              this.props.contact &&
              this.props.contact.vat === 7
              ? "INCLUDE_TAX"
              : "NO_TAX",
        }
      );
    }

    if (this.state.category === "invoice") {
      // append service fine to service list
      if (this.state.preinvoice === false && !this.state.product_and_service?.some((item) => item.node.productCode === "S000")) {
        let fine_object_node = {
          chartOfAccount: this.props.fine_chart_of_account[0]?.node,
          description: "",
          id: "fine",
          name: "คำนวณค่าปรับอัตโนมัติ",
          price: 0,
          productCode: "S000",
          servicePricing: { edges: [] },
          numberKey: { edges: [] },
          totalLeftInStock: null,
          type: "FINE",
        };

        let update_product_and_service = [{ node: fine_object_node }, ...this.state.product_and_service ];        
        this.setState({ product_and_service: update_product_and_service });
      }
      if (this.props.history) {
        this.setState({ list_history: JSON.parse(this.props.history) });
      }
      if (this.props.mode === "edit") {
        this.setState({
          list_history_checker: this.props.group_detail.historyShow,
        });
      }
    }


  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevState.issuedDate !== this.state.issuedDate && this.props.mode === "create") {
    //   fetchQuery(environment, query, {
    //     contact: this.props.contactList,
    //     taxType: this.state.taxType,
    //     invoiceDate: this.state.issuedDate,
    //     vat: "",
    //   }).then((data) => {
    //     if (data.receiptDepositTransactionActive?.length > 0) {
    //       this.setState({
    //         chartOfAccountId: data.receiptDepositTransactionActive[0].chartOfAccount_Id,
    //         receiveDeposit: data.receiptDepositTransactionActive[0].totalBalance,
    //       });
    //     } else {
    //       this.setState({
    //         chartOfAccountId: "",
    //         receiveDeposit: 0,
    //       });          
    //     }
    //   });
    // }
    if(prevState.issuedDate !== this.state.issuedDate && this.props.mode === "edit"){
      this.getDeposit()   
    } 
  }
  

  setReceiptDepositUse(receipt_deposit = this.props.contact.receiptDeposit) {
    let receiptDepositTransaction = [];
    let amountUse = 0;
    receipt_deposit.edges.forEach((rd_group) => {
      rd_group.node.receiptDepositTransaction.edges.forEach((rd_trans) => {
        let checked = false;
        rd_trans.node.receiptDepositTransactionUsed.edges.forEach((rd_use) => {
          if (
            rd_use.node.transaction.invoice.id ===
            this.props.invoiceQuery.invoice.id
          ) {
            amountUse += rd_use.node.amount;
            checked = true;
          }
        });

        if (!(rd_trans.node.balance === 0 && !checked)) {
          receiptDepositTransaction.push({
            receipt_transaction: rd_trans.node,
            checked: checked,
          });
        }
      });
    });

    this.setState({
      receiptDepositTransaction: receiptDepositTransaction,
      receiveDeposit: amountUse,
    });
  }

  appendNewRow() {
    transaction_object.vat = this.state.transaction_list[0].vat;
    this.setState({
      transaction_list: [
        ...this.state.transaction_list,
        { ...transaction_object },
      ],
    });
  }

  getPreTaxAmount(transaction) {
    let pre_tax_amount = 0;
    if (this.state.taxType === "INCLUDE_TAX") {
      pre_tax_amount = (transaction.unitItems * transaction.price - transaction.discount) / (1 + transaction.vat / 100);
    } else if (this.state.taxType === "EXCLUDE_TAX" || this.state.taxType === "NO_TAX") {
      pre_tax_amount = transaction.unitItems * transaction.price - transaction.discount;
    }
    // return parseFloat(pre_tax_amount.toFixed(2));
    return parseFloat(pre_tax_amount);
  }

  getWhtRate(transaction) {
    return (
      this.getPreTaxAmount(transaction) *
      ((transaction.whtRatePercent || 0) / 100)
    );
  }

  submitFrom(e) {
    e.preventDefault();
    this.setState({ loading: true });
    // create draft invoice
    if (this.state.status === "active" && this.state.preinvoice === false) {
      Swal.fire({
        title: i18n.t('createUpdateForm:Are you sure to approve the invoice?'),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: i18n.t('createUpdateForm:yes'),
        cancelButtonText: i18n.t('createUpdateForm:cancel'),
      }).then((result) => {
        if (result.value) {
          this.submitInvoiceOrOtherReceive();
        } else {
          this.setState({ loading: false });
        }
      });
    } else if (this.state.preinvoice) {
      this.submitPreinvoice()
    }
    else {
      this.submitInvoiceOrOtherReceive();
    }

  }

  submitPreinvoice() {
    let uploadables = { document_upload: this.state.document_upload };
    let variables = {
      input: {
        status: true,
        issuedDate: this.state.issuedDate,
        dueDate: this.state.dueDate,
        historyShow: this.state.list_history_checker,
        remark: this.state.remark,
        scheduleTime: this.state.schedule_status && this.state.temp_schedule_date ? this.state.temp_schedule_date : null
      },
    };
    CreatePreinvoiceMutation(
      variables,
      uploadables,
      this.onSuccessPreinvoice,
      this.onError
    );
  }
  // create draft invoice
  async submitInvoiceOrOtherReceive() {
    let totalPay = Math.round(((this.state.totalPay) + Number.EPSILON) * 100) / 100;
    let total = Math.round(((this.state.total - this.state.total_discount) + Number.EPSILON) * 100) / 100;
    //if ((parseFloat(this.state.total_wht_rate) === parseFloat(this.state.totalPay)) || !(this.state.category === 'other_receiver'))
    let error_outOfStock = false;

    let dateNow = new Date()

    this.state.transaction_list.forEach((value) => {
      if (value.totalLeftInStock === 0) {
        error_outOfStock = true;
      }
    })
    if (parseFloat(total) === parseFloat(totalPay) || !(this.state.category === "other_receiver")) {
      let status = this.state.category === "other_receiver" ? "paid" : this.state.status;


      let invoice = {
        id: this.state.group_detail ? this.state.group_detail.id : "",
        issuedDate: format(this.state.issuedDate, "YYYY-MM-DD"),
        dueDate: format(this.state.dueDate, "YYYY-MM-DD"),
        taxType: this.state.taxType.toLowerCase(),
        remark: this.state.remark,
        chartOfAccountId: this.state.chartOfAccountId,
        invoiceType:
          this.state.category === "other_receiver" ? "other" : "invoice",
        status: status,
        approver: "",
        updater: "",
        total: parseFloat(this.state.total) + parseFloat(this.state.receiveDeposit),
        historyShow: this.state.list_history_checker,
        contractRevenueId: this.state.all_contract_revenue_id,
      };

     
      if (this.state.category === "other_receiver" && this.state.payChannel.length > 0) {
        for (const index in this.state.payChannel) {
          if (this.state.payChannel[`${index}`].cheque && this.state.payChannel[`${index}`].cheque.chequeImage) {

            var new_file = new File([this.state.payChannel[`${index}`].cheque.chequeImage], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${this.state.payChannel[`${index}`].cheque.chequeImage.name}`);
            let data = await upload(`/other_receive`, new_file);

            await this.setState((prevState) => {
              return _.set(_.cloneDeep(prevState), `payChannel[${index}].cheque.chequeImage`, data.key.replace("silverman-storage/private/", ""));
            });
          }
        }
      }

      let variables = {}
      if (this.state.category === "other_receiver") {
        variables = {
          input: {
            contactList: this.props.contactList,
            invoice: JSON.stringify(invoice),
            transactionList: JSON.stringify(this.state.transaction_list),
            receiptDepositUse: JSON.stringify(this.state.receiptDepositTransaction),
            payChannel: JSON.stringify(this.state.payChannel),
            clientMutationId: "OR" + this.state.signreq,
            payGroup: this.state.pay_group
          },
        };
      } else {
        variables = {
          input: {
            contactList: this.props.contactList,
            invoice: JSON.stringify(invoice),
            transactionList: JSON.stringify(this.state.transaction_list),
            receiptDepositUse: JSON.stringify(this.state.receiptDepositTransaction),
            payChannel: JSON.stringify(this.state.payChannel),
            clientMutationId: "IV" + this.state.signreq,
            scheduleTime: this.state.schedule_status && this.state.temp_schedule_date ? this.state.temp_schedule_date : null,
            payGroup: this.state.pay_group
          },
        };
      }

      //create other receive
      if (this.state.category === "other_receiver") {
        const uploadables = this.state.upload;
        var valueArr = this.state.payChannel
          .filter((item) => {
            return item.slug === "unknown-receive";
          })
          .map((item) => {
            return item.unknown_ref;
          });
        var isDuplicate = valueArr.some(function (item, idx) {
          return valueArr.indexOf(item) !== idx;
        });

        if (!isDuplicate) {
          fetchQuery(environment, queryCheckHouseHolderPaygroup, {
            contactList:this.props.contactList,
            payGroup:this.state.pay_group
         }).then((data) => {
           let checkHouseHolderPaygroup = JSON.parse(data.checkHouseHolderPaygroup)
           if(checkHouseHolderPaygroup.ok){
             // create OtherReceiver
             OtherReceiver(
              variables,
              (response) => {
                if (response.createOtherReceiveAndOtherReceiveTransaction.ok) {
                  if(this.props.carTransactionIdList.length >= 1){
                    let data = {
                      "carTransactionIdList":this.props.carTransactionIdList,
                      "otherReceiveId":response.createOtherReceiveAndOtherReceiveTransaction.newOr.id
                    }     
                    API.postOtherReceive(data).then((res) => {
                      if(res.data){
                          if(res.data.statusMessage === "SUCCESS"){
                            Swal.fire(i18n.t('createUpdateForm:Successful record') + "!", "", "success").then(() =>
                            this.setState({ 
                              redirectToList: true
                             })
                          );
                          }
                      }
                      }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Saved Unsuccessful"), 'error'))
                      
                  }else{
                    Swal.fire(i18n.t('createUpdateForm:Successful record') + "!", "", "success").then(() =>
                    this.setState({ 
                      redirectToList: true
                     })
                  );
                  }
                } else {
                  if (error_outOfStock) {
                    Swal.fire(i18next.t("slockCreate:Sorry, we're unable to process your request."), i18next.t("slockCreate:Because the product is out of stock."), "warning").then(() =>
                      this.setState({ loading: false })
                    );
                  } else {
  
                    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning").then(() =>
                      this.setState({ loading: false })
                    );
                    if (response.createOtherReceiveAndOtherReceiveTransaction.warningText) {
                      this.setState({ loading: false });
                      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createOtherReceiveAndOtherReceiveTransaction.warningText, "warning");
                    }
                    else {
                      this.setState({ loading: false });
                      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
                    }
                  }
                }
              },
              (response) =>
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() =>
                  this.setState({ loading: false })
                ),
              uploadables
            );
           }else if(checkHouseHolderPaygroup.ok === false && this.state.pay_group === 'owner' ){
             Swal.fire({
              //  title: "ออกได้แต่ชื่อจะเป็นค่าช่องว่าง",
               type: 'warning',
               showCancelButton: true,
               text:checkHouseHolderPaygroup.message,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#d33',
               confirmButtonText: i18next.t("Allaction:Yes"),
               cancelButtonText: i18next.t("Allaction:cancel")
             }).then((result) => {
                 if (result.value) {
                   // create invoice
                  OtherReceiver(
                    variables,
                    (response) => {
                      if (response.createOtherReceiveAndOtherReceiveTransaction.ok) {
                        if(this.props.carTransactionIdList.length >= 1){
                          let data = {
                            "carTransactionIdList":this.props.carTransactionIdList,
                            "otherReceiveId":response.createOtherReceiveAndOtherReceiveTransaction.newOr.id
                          }     
                          API.postOtherReceive(data).then((res) => {
                            if(res.data){
                                if(res.data.statusMessage === "SUCCESS"){
                                  Swal.fire(i18n.t('createUpdateForm:Successful record') + "!", "", "success").then(() =>
                                  this.setState({ 
                                    redirectToList: true
                                  })
                                );
                                }
                            }
                            }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Saved Unsuccessful"), 'error'))
                            
                        }else{
                          Swal.fire(i18n.t('createUpdateForm:Successful record') + "!", "", "success").then(() =>
                          this.setState({ 
                            redirectToList: true
                          })
                        );
                        }
                      } else {
                        if (error_outOfStock) {
                          Swal.fire(i18next.t("slockCreate:Sorry, we're unable to process your request."), i18next.t("slockCreate:Because the product is out of stock."), "warning").then(() =>
                            this.setState({ loading: false })
                          );
                        } else {

                          Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning").then(() =>
                            this.setState({ loading: false })
                          );
                          if (response.createOtherReceiveAndOtherReceiveTransaction.warningText) {
                            this.setState({ loading: false });
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createOtherReceiveAndOtherReceiveTransaction.warningText, "warning");
                          }
                          else {
                            this.setState({ loading: false });
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
                          }
                        }
                      }
                    },
                    (response) =>
                      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() =>
                        this.setState({ loading: false })
                      ),
                    uploadables
                  );
                 }else{
                   this.setState({ loading: false });
                 }
             })
           }else{
             Swal.fire({
               title: "บันทึกไม่สำเร็จ",
               type: 'warning',
               text:checkHouseHolderPaygroup.message,
               showCancelButton: true,
               showConfirmButton:false,
               cancelButtonColor: '#d33',
               cancelButtonText: 'ปิด',
           })
             this.setState({ loading: false });
           }
         })

        } else {
          Swal.fire(
            "รายละเอียดการรับชำระไม่ถูกต้อง",
            "กรุณาเลือกเงินรับรอการตรวจสอบที่ไม่ซ้ำกัน",
            "warning"
          ).then(() => this.setState({ loading: false }));
        }
      } else {
        //check total not navigation
        let check_total = 0;
        let check_contract_revenue = false
        let check_contract_revenue_text = ""
        this.state.transaction_list.forEach((transaction) => {

          check_total = check_total + transaction.preTaxAmount;
          //เช็คไม่ให้ใช้เงินเกินสัญญารายรับ
          if (transaction.contract_revenue) {
            const preTaxAmount_check = transaction.preTaxAmount + transaction.contractRevenPaid
            if (preTaxAmount_check > transaction.contractRevenueListTotal) {
              check_contract_revenue = true
              check_contract_revenue_text = "รายการ " + transaction.description
            }
          }

        });
        if (check_total < 0) {
          this.setState({ loading: false });
          Swal.fire("บันทึกไม่สำเร็จ! จำนวนเงินไม่ถูกต้อง", "", "warning");
        } else if (check_contract_revenue) {
          this.setState({ loading: false });
          Swal.fire("บันทึกไม่สำเร็จ! เงินเกินจำนวนเงินของสัญญารายรับ", check_contract_revenue_text, "warning");
        }
        else if (error_outOfStock) {
          Swal.fire(i18next.t("slockCreate:Sorry, we're unable to process your request."), i18next.t("slockCreate:Because the product is out of stock."), "warning").then(() =>
            this.setState({ loading: false })
          );
        } else if (this.state.schedule_status && dateNow > this.state.temp_schedule_date) {
          Swal.fire("ไม่สามารถอนุมัติแบบร่างได้!", "เนื่องจาก เกินวัน/เวลา ที่ตั้งเวลาไว้ กรุณาลบรายการดังกล่าวและออกใบแจ้งหนี้ใหม่อีกครั้ง", "warning").then(() =>
            this.setState({ loading: false }))
        } else {
          // create invoice
          if(this.state.status === 'draft'){
           //check create invoice for agent rule (queryCheckHouseHolderPaygroup)
          fetchQuery(environment, queryCheckHouseHolderPaygroup, {
             contactList: this.props.contactList,
             payGroup:this.state.pay_group
            }).then((data) => {
            let checkHouseHolderPaygroup = JSON.parse(data.checkHouseHolderPaygroup)
            if(checkHouseHolderPaygroup.ok){
              // create invoice
            commitMutation(environment, {
                mutation,
                variables,
                onCompleted: (response) => {
                  this.setState({ loading: false });
                  if (response.updateInvoiceAndTransaction.ok) {
                    Swal.fire(i18n.t('createUpdateForm:Successful record') + "!", "", "success").then(() => {
                      this.setState({ redirectToList: true });
                    });
                  } else {
                    if (response.updateInvoiceAndTransaction.warningText) {
                      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.updateInvoiceAndTransaction.warningText, "warning");
                    }
                    else {
                      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
                    }
                  }

                  if (this.state.group_detail) {
                    fetchQuery(environment, this.props.query, {
                      id: this.state.group_detail.id,
                    }).then((data) => {
                      //update transaction_list
                      let transactions = [...data.allInvoiceTransaction.edges];
                      let list = [];
                      transactions.forEach((transaction) => {
                        list = [...list, { ...transaction.node }];
                      });
                      this.setState({ transaction_list: list });
                      // this.setReceiptDepositUse();
                    });
                  }
                },
                onError: (err) => {
                  this.setState({ loading: false });
                  Swal.fire("Error!", i18next.t("Allaction:Please try again."), "warning");
                },
              });
            }else if(checkHouseHolderPaygroup.ok === false && this.state.pay_group === 'owner' ){
              Swal.fire({
                // title: "ออกได้แต่ชื่อจะเป็นค่าช่องว่าง",
                type: 'warning',
                showCancelButton: true,
                text:checkHouseHolderPaygroup.message,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: i18next.t("Allaction:Yes"),
                cancelButtonText: i18next.t("Allaction:cancel")
              }).then((result) => {
                  if (result.value) {
                       // create invoice
                      commitMutation(environment, {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                          this.setState({ loading: false });
                          if (response.updateInvoiceAndTransaction.ok) {
                            Swal.fire(i18n.t('createUpdateForm:Successful record') + "!", "", "success").then(() => {
                              this.setState({ redirectToList: true });
                            });
                          } else {
                            if (response.updateInvoiceAndTransaction.warningText) {
                              Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.updateInvoiceAndTransaction.warningText, "warning");
                            }
                            else {
                              Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
                            }
                          }

                          if (this.state.group_detail) {
                            fetchQuery(environment, this.props.query, {
                              id: this.state.group_detail.id,
                            }).then((data) => {
                              //update transaction_list
                              let transactions = [...data.allInvoiceTransaction.edges];
                              let list = [];
                              transactions.forEach((transaction) => {
                                list = [...list, { ...transaction.node }];
                              });
                              this.setState({ transaction_list: list });
                              // this.setReceiptDepositUse();
                            });
                          }
                        },
                        onError: (err) => {
                          this.setState({ loading: false });
                          Swal.fire("Error!", i18next.t("Allaction:Please try again."), "warning");
                        },
                    });
                  }else{
                    this.setState({ loading: false });
                  }
              })
            }else{
              Swal.fire({
                title: "บันทึกไม่สำเร็จ",
                type: 'warning',
                text:checkHouseHolderPaygroup.message,
                showCancelButton: true,
                showConfirmButton:false,
                cancelButtonColor: '#d33',
                cancelButtonText: 'ปิด',
            })
              this.setState({ loading: false });
            }
          })

          }else{
             // create invoice
               commitMutation(environment, {
                mutation,
                variables,
                onCompleted: (response) => {
                  this.setState({ loading: false });
                  if (response.updateInvoiceAndTransaction.ok) {
                    Swal.fire(i18n.t('createUpdateForm:Successful record') + "!", "", "success").then(() => {
                      this.setState({ redirectToList: true });
                    });
                  } else {
                    if (response.updateInvoiceAndTransaction.warningText) {
                      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.updateInvoiceAndTransaction.warningText, "warning");
                    }
                    else {
                      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
                    }
                  }

                  if (this.state.group_detail) {
                    fetchQuery(environment, this.props.query, {
                      id: this.state.group_detail.id,
                    }).then((data) => {
                      //update transaction_list
                      let transactions = [...data.allInvoiceTransaction.edges];
                      let list = [];
                      transactions.forEach((transaction) => {
                        list = [...list, { ...transaction.node }];
                      });
                      this.setState({ transaction_list: list });
                      // this.setReceiptDepositUse();
                    });
                  }
                },
                onError: (err) => {
                  this.setState({ loading: false });
                  Swal.fire("Error!", i18next.t("Allaction:Please try again."), "warning");
                },
              });
          }
        }
      }
    } else {
      Swal.fire(
        "ยอดชำระไม่ถูกต้อง",
        "กรุณากรอกจำนวนเงินที่ได้รับ ให้เท่ากับ " +
        numberWithComma(this.state.total) +
        " บาท",
        "warning"
      ).then(() => this.setState({ loading: false }));
    }
  }

  submitPurchase() {
    let purchase = {
      id: this.state.group_detail ? this.state.group_detail.id : "",
      issuedDate: format(this.state.issuedDate, "YYYY-MM-DD"),
      dueDate: format(this.state.dueDate, "YYYY-MM-DD"),
      taxType: this.state.taxType.toLowerCase(),
      remark: this.state.remark,
      status: "active",
      creator: "",
      approver: "",
      updater: "",
      total: this.state.total < 0 ? 0 : this.state.total,
      tax_invoice_number: this.state.tax_invoice_number,
      tax_invoice_date: format(this.state.tax_invoice_date, "YYYY-MM-DD"),
      tax_invoice_period: format(this.state.tax_invoice_period, "YYYY-MM-DD"),
      ref_number: this.state.ref_number,

      chartOfAccountId: this.state.chartOfAccountId,
    };
    let uploadables = { document_upload: this.state.document_upload };
    let variables = {
      input: {
        contactList: this.props.contactList,
        purchase: JSON.stringify(purchase),
        transactionList: JSON.stringify(this.state.transaction_list),
        prepaidDepositUse: JSON.stringify(this.state.prepaidDepositTransaction),
      },
    };

    CreatePurchaseMutation(
      variables,
      uploadables,
      this.onSuccess,
      this.onError
    );
  }

  onSuccess(response) {
    this.setState({ loading: false });
    if (response.updatePurchaseRecordGroupAndTransaction.ok) {
      Swal.fire(i18n.t('createUpdateForm:Successful record') + "!", "", "success").then(() => {
        if (!this.state.group_detail) {
          this.setState({ redirectToList: true });
        }
      });
    }
    else {
      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
    }
  }

  onSuccessPreinvoice(response) {
    this.setState({ loading: false });
    if (response.createInvoice.ok) {
      Swal.fire(i18n.t('createUpdateForm:Successful record') + "!", "", "success").then(() => {
        if (!this.state.group_detail) {
          this.setState({ redirectToList: true });
        }
      });
    } else {
      if (response.createInvoice.warningText) {
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createInvoice.warningText, "warning");
      }
      else {
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
      }
    }
  }

  onError(err) {
    this.setState({ loading: false });
    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
  }

  setTransactionList(new_transaction) {
    this.setState({ transaction_list: new_transaction });
  }

  updatePayChannel(channel_list) {
    let totalPay = 0;
    let upload = {};
    channel_list.forEach((channel, index) => {
      if (
        channel.slug === "small-change" ||
        channel.slug === "receipt-deposit" ||
        channel.slug === "other-expense"
      ) {
        if (
          this.state.total - this.state.total_discount - this.state.totalPay >
          0
        )
          totalPay += parseFloat(channel.pay);
        else totalPay -= parseFloat(channel.pay);
      } else {
        totalPay += parseFloat(channel.pay);
      }
      if (channel.image) {
        upload[index] = channel.image;
      }
    });
    this.setState({
      payChannel: channel_list,
      totalPay: totalPay,
      upload: upload,
    });
  }

  handleInputDocument(e) {
    this.setState({
      document_upload: e.currentTarget.files[0],
    });
  }

  handleChangeDatetime(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    );
  }

  setDatetime(date) {
    let _temp_schedule_date = _.cloneDeep(this.state.temp_schedule_date)
    _temp_schedule_date = setHours(new Date(_temp_schedule_date), getHours(date))
    _temp_schedule_date = setMinutes(new Date(_temp_schedule_date), getMinutes(date))
    this.setState({ temp_schedule_date: _temp_schedule_date })
  }

  getDeposit() {
    let variable = {
      contact: this.props.contactList,
      taxType: this.state.taxType,
      invoiceDate: this.state.issuedDate,
      vat:
        this.state.transaction_list[0].vat === null
          ? ""
          : this.state.transaction_list[0].vat,
      whtRatePercent: this.state.transaction_list[0].whtRatePercent,
    };

    fetchQuery(environment, query, variable).then((response_data) => {
      // เช็คว่าถ้า receiptDepositTransactionActive เป็น null แสดงว่าเป็นบุคคลภายนอกไม่ต้องขึ้นรับหรือจ่ายล่วงหน้า
      if (response_data.receiptDepositTransactionActive) {
        if (this.state.group_detail) {
          this.setReceiptDepositUse(
            response_data.receiptDepositViewer.allReceiptDeposit
          );
        }
        if (this.state.category === "purchase") {
          this.setPrepaidDepositUse(
            response_data.prepaidDepositViewer.allPrepaidDeposit
          );
        }
      }
    });
  }

  handleInputChange(e) {
    let total_discount = 0;
    if (e.target.name === "list_history_checker") {
      this.setState({ [e.target.name]: e.target.checked });
      return;
    }
    if (e.target.name === "vat") {
      let transaction_list = [...this.state.transaction_list];
      transaction_list.forEach((transaction) => {
        transaction.vat = e.target.value;
        transaction["preTaxAmount"] = this.getPreTaxAmount(transaction);
        transaction["whtRate"] = this.getWhtRate(transaction);
      });

      this.getDeposit();

      this.setState({ transaction_list, vat: e.target.value });
      return;
    }

    if (e.target.name === "receiptDepositTransaction") {
      let trans_arr = [...this.state.receiptDepositTransaction];
      trans_arr[e.target.dataset.id]["checked"] = e.target.checked;

      let receive_deposit = this.state.receiveDeposit;
      let sum_amount = trans_arr[e.target.dataset.id].receipt_transaction.balance;
      let preTaxAmount = 0
      let whtRate = 0

      let transaction_list = [...this.state.transaction_list];
      transaction_list.forEach((transaction) => {
        preTaxAmount += this.getPreTaxAmount(transaction);
        whtRate += this.getWhtRate(transaction);
      });


      if (e.target.checked) {
        let sum_totla = parseFloat(preTaxAmount) - parseFloat(whtRate)
        if (parseFloat(sum_amount) + parseFloat(receive_deposit) > parseFloat(sum_totla)) {
          sum_amount = sum_totla - receive_deposit
        }
        receive_deposit += sum_amount;
      } else {
        if (receive_deposit > sum_amount) {
          receive_deposit -= sum_amount;
        } else {
          receive_deposit = 0;
        }

      }

      this.setState({
        receiptDepositTransaction: trans_arr,
        receiveDeposit: receive_deposit,
      });

      return;
    }

    // สัญญารายรับใช้ได้กับลูกหนี้ 1 ห้องต่อครั้ง
    if (e.target.name === "all_contract_revenue_id") {
      let all_contract_revenue = _.find(this.props.all_contract_revenue, { node: { id: e.target.value } })
      //ดึง transaction_list มาใช้
      let transaction_list = []
      all_contract_revenue.node.contractRevenueList.edges.forEach((e) => {
        const total_con_list = e.node.total - e.node.paid
        if (total_con_list !== 0) {
          const price_con_list = parseFloat(total_con_list) / parseFloat(e.node.pricePerPeriod)
          let transaction_object = {
            description: e.node.description,
            discount: 0,
            id: "",
            preTaxAmount: total_con_list,
            price: e.node.pricePerPeriod,
            productAndService: e.node.productAndService,
            unitItems: price_con_list,
            vat: null,
            whtRate: 0,
            whtRatePercent: 0,
            totalLeftInStock: "",

            contract_revenue: true,
            contractId: e.node.id,
            contractRevenueListTotal: e.node.total,
            contractRevenPaid: e.node.paid,
          };
          transaction_list.push(transaction_object);
        }
      })
      let contract_form_test = ''
      if (all_contract_revenue.node.contractForm === 'A_3_MONTH') { contract_form_test = 'ราย 3 เดือน' }
      else if (all_contract_revenue.node.contractForm === 'A_6_MONTH') { contract_form_test = 'ราย 6 เดือน' }
      else if (all_contract_revenue.node.contractForm === 'A_1_YEAR') { contract_form_test = 'รายปี' }
      else { contract_form_test = all_contract_revenue.node.otherContractForm }

      this.setState({
        transaction_list: transaction_list,
        all_contract_revenue_id: e.target.value,
        all_contract_revenue_category: all_contract_revenue.node.chartOfAccount.name,
        all_contract_revenue_contract_number: contract_form_test,
      });
      return;
    }

    if (e.target.name === "dueDateCount") {
      this.setState({
        dueDateCount: e.currentTarget.value,
        dueDate: addDays(this.state.issuedDate, e.currentTarget.value),
      });
    } else if (e.target.dataset.key === "transaction_list") {
      let transaction_list = _.cloneDeep(this.state.transaction_list);
      if (e.target.name === "productAndService") {        
        let product_service_list = _.cloneDeep(this.state.product_and_service);        
        
        // สำหรับไว้เช็คกรณีที่เป็นพวกค่าที่จอดรถ จะ auto fill license(ถ้ามี) เฉพาะตอนเลือกจาก dropdown 
        this.setState({
          onSelectProduct: true
        })

        let ps_nummber = _.find(product_service_list, { node: { id: e.target.value } });        
        // ถ้าเลือกหลายห้องจะใช้งานไม่ได้ ในการเลือกคีย์การ์ด
        if (this.props.category === "invoice" && ps_nummber.node.numberKey.edges.length > 0) {
          if (this.props.contactList?.length > 1) {
            Swal.fire("คีย์การ์ดมีสามารถทำได้ต่อ 1 ลูกหนี้ ไม่สามารถเลือกแบบหลายห้องได้!", "", "warning");
            return;
          }
        }

        product_service_list.forEach((ps) => {
          if (ps.node.id === e.target.value) {
            transaction_list[e.target.dataset.id]["productAndService"] = ps.node;
            if (ps.node.price == null) {
              transaction_list[e.target.dataset.id]["price"] = "";
            } else {
              transaction_list[e.target.dataset.id]["price"] = ps.node.price;
            }
            transaction_list[e.target.dataset.id]["description"] = ps.node.name;
            transaction_list[e.target.dataset.id]["totalLeftInStock"] = ps.node.totalLeftInStock;
            transaction_list[e.target.dataset.id]["type"] = ps.node.type;
            transaction_list[e.target.dataset.id]["unitItems"] = 1;
            transaction_list[e.target.dataset.id]["whtRatePercent"] = 0;
            transaction_list[e.target.dataset.id]["vat"] = this.state.vat;
            transaction_list[e.target.dataset.id]["price_type"] = "";
            if (ps.node.type === "PRODUCT") {
              transaction_list[e.target.dataset.id]["whtRatePercent"] = 0;
              transaction_list[e.target.dataset.id]["whtRate"] = 0;
            }
            if (ps.node.type === "EXPENSE") {
              if (!this.props.contact) {
                transaction_list[e.target.dataset.id]["whtRatePercent"] = 0;
              } else {
                transaction_list[e.target.dataset.id]["whtRatePercent"] = ps.node.taxRate || 0;
              }
            }
          }
        });
      } else {
        transaction_list[e.target.dataset.id][e.target.name] = e.target.value;

        //คีย์การ์ดมีถูกเลือกไปแล้ว
        if (e.target.name === "numberKeyId") {
          let numberKey = _.find(transaction_list[e.target.dataset.id].productAndService.numberKey.edges, { node: { id: e.target.value } })
          if (numberKey.node.keycardPerInvoice.totalCount > 0 || numberKey.node.keycardPerOtherReceiveTransaction.totalCount > 0) {
            Swal.fire("เลขนี้ถูกใช้แล้ว ที่เมนูแบบร่าง !", "", "warning");
            return;
          }
        }

        if (e.target.name === "discount") {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            transaction_list.forEach((value) => {
              if (value.discount !== "" && value.discount !== null) {
                total_discount += parseFloat(value.discount);
              } else {
                total_discount += 0;
              }
            });
            this.setState({ total_discount: total_discount });
          }, 100);
        }
      }
      transaction_list[e.target.dataset.id]["preTaxAmount"] = this.getPreTaxAmount(transaction_list[e.target.dataset.id]);
      transaction_list[e.target.dataset.id]["whtRate"] = this.getWhtRate(transaction_list[e.target.dataset.id]);

      this.setState({ transaction_list: [...transaction_list] });
    } else if (e.target.name === "taxType") {
      let value = e.target.value;
      this.setState({ [e.target.name]: e.target.value }, () => {
        this.getDeposit();

        if (value !== "NO_TAX") {
          let transaction_list = [...this.state.transaction_list];
          transaction_list.forEach((transaction) => {
            transaction["preTaxAmount"] = this.getPreTaxAmount(transaction);
            transaction["whtRate"] = this.getWhtRate(transaction);
          });
          this.setState({ transaction_list: [...transaction_list] });
        } else {
          let transaction_list = [...this.state.transaction_list];
          transaction_list.forEach((transaction) => {
            transaction["vat"] = null;
            transaction["preTaxAmount"] = this.getPreTaxAmount(transaction);
          });
          this.setState({ transaction_list: [...transaction_list] });
        }
      });
    } else {
      let name = e.target.name;
      if (name === "receiveDeposit") {
        this.setState({ chartOfAccountId: e.target.dataset.id });
      }

      this.setState({ [e.target.name]: e.target.value }, () => {
        if (name === "dueDate" || name === "issuedDate") {
          this.setState({
            dueDateCount: differenceInCalendarDays(
              this.state.dueDate,
              this.state.issuedDate
            ),
          });
        }
      });
    }
  }

  setServicePeriod(transaction_index, period_state) {
    let service_name = this.state.transaction_list[transaction_index].productAndService.name;
    let next_month = addMonths(new Date(period_state.startYear, period_state.startMonth, 1), period_state.periodMonth - 1).getMonth();
    let next_year = addMonths(new Date(period_state.startYear, period_state.startMonth, 1), period_state.periodMonth - 1).getFullYear();
    let description_text =
      " (" +
      format(new Date(period_state.startYear, period_state.startMonth, 1), "MM") + "/" +
      period_state.startYear + " - " +
      format(new Date(next_year, next_month, 1), "MM") + "/" + next_year + ")";

    let transaction = this.state.transaction_list[transaction_index];
    _.set(transaction, "description", service_name + description_text);
    _.set(transaction, "servicePeriod", period_state);

    if (transaction.contract_revenue) {
      _.set(transaction, "preTaxAmount", transaction.price * period_state.periodMonth);
      _.set(transaction, "unitItems", period_state.periodMonth);
    } else {
      _.set(transaction, "price", (period_state.totalPrice / period_state.periodMonth).toFixed(2));
      _.set(transaction, "preTaxAmount", period_state.totalPrice);
      _.set(transaction, "unitItems", period_state.periodMonth);
    }

    _.set(transaction, "WaterMeterTransaction", []);
    _.set(transaction, "ElectricMeterTransaction", []);

    this.setState((prevState) => {
      return _.set(
        _.cloneDeep(prevState),
        "transaction_list." + transaction_index,
        transaction
      );
    });
  }

  setWaterMeterTransaction(transaction_index, water_state) {
    let service_name = this.state.transaction_list[transaction_index]
      .productAndService.name;
    let description_text =
      service_name +
      " (" +
      (parseInt(water_state.MeterMonth) + 1) +
      "/" +
      water_state.MeterYear +
      ") หน่วยที่ " +
      water_state.description +
      " (" +
      water_state.unit +
      ")";

    let transaction = this.state.transaction_list[transaction_index];
    _.set(transaction, "description", description_text);
    _.set(transaction, "servicePeriod", []);
    _.set(transaction, "WaterMeterTransaction", water_state);
    _.set(transaction, "price", water_state.unitPrice);
    // _.set(transaction, 'preTaxAmount', water_state.totalPrice);
    _.set(transaction, "preTaxAmount", water_state.unit * water_state.unitPrice);
    _.set(transaction, "unitItems", water_state.unit);

    this.setState((prevState) => {
      return _.set(
        _.cloneDeep(prevState),
        "transaction_list." + transaction_index,
        transaction
      );
    });
  }

  setElectricMeterTransaction(transaction_index, electric_state) {
    let service_name = this.state.transaction_list[transaction_index]
      .productAndService.name;
    let description_text =
      service_name +
      " (" +
      (parseInt(electric_state.MeterMonth) + 1) +
      "/" +
      electric_state.MeterYear +
      ") หน่วยที่ " +
      electric_state.description +
      " (" +
      electric_state.unit +
      ")";

    let transaction = this.state.transaction_list[transaction_index];
    _.set(transaction, "description", description_text);
    _.set(transaction, "servicePeriod", []);
    _.set(transaction, "ElectricMeterTransaction", electric_state);
    _.set(transaction, "price", electric_state.unitPrice);
    _.set(transaction, "preTaxAmount", electric_state.unit * electric_state.unitPrice);
    _.set(transaction, "unitItems", electric_state.unit);

    this.setState((prevState) => {
      return _.set(
        _.cloneDeep(prevState),
        "transaction_list." + transaction_index,
        transaction
      );
    });
  }

  setIndividualTransaction(transaction_index, individual_state) {
    let transaction = this.state.transaction_list[transaction_index];
    _.set(transaction, "price", individual_state.price);
    _.set(transaction, "price_type", "individual");
    _.set(transaction, "unitItems", individual_state.unit);
    _.set(
      transaction,
      "preTaxAmount",
      parseFloat((individual_state.unit * individual_state.price))
      // parseFloat((individual_state.unit * individual_state.price).toFixed(2))
    );

    this.setState((prevState) => {
      return _.set(
        _.cloneDeep(prevState),
        "transaction_list." + transaction_index,
        transaction
      );
    });
  }

  setCarParkTransaction(transaction_index, data) {
    let service = this.state.transaction_list[transaction_index];    
    let licence_plate = [];
    let count = 0;
  
    data.allCars.edges.forEach((detail_park) => {
      licence_plate.push(detail_park.node.licencePlate);
      count += 1;
    });
    
    let service_name = service.description;    
    // onSelectProduct สำหรับไว้เช็คกรณีที่เป็นพวกค่าที่จอดรถ จะ auto fill license(ถ้ามี) เฉพาะตอนเลือกจาก dropdown  ถ้าเป็นตอน query ในแบบร่างจะไม่ auto fill license
    if (licence_plate.length > 0 && this.state.onSelectProduct) {
      service_name = service_name + " ทะเบียนรถ " + licence_plate.join();
    }
  
    let transaction = { ...this.state.transaction_list[transaction_index] };    
  
    transaction.description = service_name;
    transaction.unitItems = count;
    transaction.price = service.price;
    transaction.preTaxAmount = service.price * count;
  
    this.setState((prevState) => {
      const updatedTransactionList = [...prevState.transaction_list];
      updatedTransactionList[transaction_index] = transaction;                  
  
      return {
        ...prevState,
        transaction_list: updatedTransactionList
      };
    });
  }
  

  checkAndGetPrefixAddress(type, value) {
    if (this.props.contact?.registeredProvince === "กรุงเทพมหานคร") {
      if (value) {
        switch (type) {
          case "province":
            return "";
          case "district":
            return "แขวง";
          default:
            return "เขต";
        }
      }
      return "";
    } else {
      if (value) {
        switch (type) {
          case "province":
            return "จังหวัด";
          case "district":
            return "ตำบล";
          default:
            return "อำเภอ";
        }
      }
      return "";
    }
  }

  showTextPrepaidAmount(pd_transaction) {
    let vat = this.props.contact.vat ? parseFloat(this.props.contact.vat) : parseFloat(this.state.vat);
    if (vat) {
      let price = (pd_transaction.prepaid_transaction.price * (100 + vat)) / 100;
      let balance = (pd_transaction.prepaid_transaction.balance * (100 + vat)) / 100;
      return (
        pd_transaction.prepaid_transaction.chartOfAccount.chartOfAccountCode +
        " " +
        pd_transaction.prepaid_transaction.chartOfAccount.name +
        " จำนวน " +
        numberWithComma(price) +
        " บาท ยอดที่สามารถใช้ได้ " +
        numberWithComma(balance)
      );
    } else {
      return (
        pd_transaction.prepaid_transaction.chartOfAccount.chartOfAccountCode +
        " " +
        pd_transaction.prepaid_transaction.chartOfAccount.name +
        " จำนวน " +
        numberWithComma(pd_transaction.prepaid_transaction.price) +
        " บาท ยอดที่สามารถใช้ได้ " +
        numberWithComma(pd_transaction.prepaid_transaction.balance)
      );
    }
  }

  getRemark(remark) {
    this.setState({ remark: remark });
  }

  isLimitDescription(category) {
    if (category === "invoice" || category === "other_receiver") {
      return true;
    } else {
      return false;
    }
  }


  addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }



  calPeriodMouth(val) {

    let issued_date = ''
    if (this.state.group_detail) {
      // update
      issued_date = parse(this.state.group_detail.issuedDate);
    }

    let month_before = this.props.mode === 'edit' ? new Date(issued_date) : new Date();
    let month_after = this.props.mode === 'edit' ? new Date(issued_date) : new Date();

    const date = new Date(issued_date);
    // let set_month_before = this.props.mode === 'edit' ? issued_date.setMonth(issued_date.getMonth() -  parseInt(val)) : month_before.setMonth(month_before.getMonth() -  parseInt(val));
    let set_month_before = this.props.mode === 'edit' ? issued_date : month_before.setMonth(month_before.getMonth() - parseInt(val));
    let set_month_after = this.props.mode === 'edit' ? addMonths(date, val) : month_after.setMonth(month_after.getMonth() + parseInt(val));

    this.setState({
      temp_month_before: set_month_before,
      temp_month_after: set_month_after,
    })
  }

  getData = (pay_group) => {
    getContact({
      "id": this.props?.contact?.id
    }, (res) => {

      let name = "";
      let address = "";

      if (pay_group === "agent") {
        name = res.contactViewer.allContact.edges[0].node.agentName
        address = res.contactViewer.allContact.edges[0].node.agentAddress +
          ` ${res.contactViewer.allContact.edges[0].node.agentDistrict}` +
          ` ${res.contactViewer.allContact.edges[0].node.agentCity}` +
          ` ${res.contactViewer.allContact.edges[0].node.agentProvince}` +
          ` ${res.contactViewer.allContact.edges[0].node.agentPostalCode}`
      } else if (pay_group === "rent") {
        name = res.contactViewer.allContact.edges[0].node.renterName
        address = res.contactViewer.allContact.edges[0].node.renterAddress +
          ` ${res.contactViewer.allContact.edges[0].node.renterDistrict}` +
          ` ${res.contactViewer.allContact.edges[0].node.renterCity}` +
          ` ${res.contactViewer.allContact.edges[0].node.renterProvince}` +
          ` ${res.contactViewer.allContact.edges[0].node.renterPostalCode}`
      } else {
        name = res.contactViewer.allContact.edges[0].node.ownerName
        address = res.contactViewer.allContact.edges[0].node.mailingAddress +
          ` ${res.contactViewer.allContact.edges[0].node.mailingDistrict}` +
          ` ${res.contactViewer.allContact.edges[0].node.mailingCity}` +
          ` ${res.contactViewer.allContact.edges[0].node.mailingProvince}` +
          ` ${res.contactViewer.allContact.edges[0].node.mailingPostalCode}`
      } 
      this.setState({ pay_group: pay_group, name: name, address: address })
    })
  }

  addressData = (address) => {
    return (
      this.props.contact.registeredProvince.includes('กรุงเทพ') || this.props.contact.registeredProvince.includes('กทม') ? (
        <>
          {this.props.contact.registeredAddress}
          {this.props.contact.registeredDistrict && ` แขวง${this.props.contact.registeredDistrict}`}
          {this.props.contact.registeredCity && ` เขต${this.props.contact.registeredCity}`}
          {this.props.contact.registeredProvince && ` ${this.props.contact.registeredProvince} `}
          {this.props.contact.registeredPostalCode}
        </>
      ) : (
        <>
          {this.props.contact.registeredAddress}
          {this.props.contact.registeredDistrict && ` ตำบล${this.props.contact.registeredDistrict}`}
          {this.props.contact.registeredCity && ` อำเภอ${this.props.contact.registeredCity}`}
          {this.props.contact.registeredProvince && ` จังหวัด${this.props.contact.registeredProvince} `}
          {this.props.contact.registeredPostalCode}
        </>
      )
    )
  }


  checkFormBeforeSubmit = (status) => {     
    let unitItems = Number(this.state.transaction_list[0]?.unitItems)     
    if (this.formRef.current.checkValidity()) {
      if (this.formRef.current) {
        this.setState({ status: status },()=>{
          this.formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
        });
      }
    } else if(!Number.isInteger(unitItems) && unitItems !== 0){
      Swal.fire({
        title: "ไม่สามารถใส่จุดทศนิยมในช่องจำนวนได้",
        type: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",              
      }).then((result) => {        
        if (result.value) {                    
          setTimeout(() => {
            this.formRef.current.reportValidity();
          }, 500);
        }
      });      
    }else {
      this.formRef.current.reportValidity();
    }
  };

  render() {    
    if (this.state.redirectToList) {
      if (this.state.status === "active") {
        return <Redirect to={this.props.back_page} />;
      } else {
        return <Redirect to={this.props.next_page} />;
      }
    }
    return (
      <form onSubmit={this.submitFrom} id="invoice-create-update" ref={this.formRef}>
        <div className="row">
          {this.props.contactList?.length > 0 && (
            //contact length == 0 กรณีหน้า บันทึกจ่ายอื่นๆ
            <React.Fragment>
              {
                this.props.contactType !== "unknown" &&
                <SelectRoleAccounting
                  handleChangeSelect={(value) => this.getData(value)}
                  value={this.state.pay_group}
                  editor={this.props.role ? true : false}
                />
              }
              <div className="col-3">
                <p>
                  <Translation>
                    {t => <strong>
                      {t('selectContact:customer')}
                    </strong>
                    }
                  </Translation>
                </p>
                {this.state.preinvoice === false &&
                  <div className="input-group">
                    {this.props.contactList.length === 1 ? (
                      this.props.contact ? (
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.name}
                          // value={
                          //   this.props.contact.typeOfContact === "RESIDENTIAL" ? this.props.contact.refNumber + " " +
                          //     getNameResidential(
                          //       this.props.contact.firstName,
                          //       this.props.contact.lastName
                          //     )
                          //     : this.props.contact.refNumber +
                          //     " " +
                          //     this.props.contact.name
                          // }
                          disabled
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          value={this.props.contactList[0] || "-"}
                          disabled
                        />
                      )
                    ) : (
                      <InvoiceCreateDetailContact
                        contactNameRole={this.state.pay_group}
                        contactResidential={this.props.contactResidential}
                      />
                    )}
                  </div>
                }

              </div>
            </React.Fragment>
          )}

          {this.state.preinvoice === false &&
            <div className="col">
              <p>
                <Translation>{t => <strong>{t('createUpdateForm:no')}</strong>}</Translation>
              </p>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={
                    this.state.group_detail &&
                      this.state.group_detail.status !== "DRAFT" &&
                      this.state.group_detail.status !== "WAITING_FIX"
                      ? this.state.group_detail.docNumber
                      : getDocumentNumber.get(
                        this.state.category,
                        "XXXX",
                        this.state.issuedDate
                      )
                  }
                  disabled
                />
              </div>
            </div>}
          <div className="col">
            <p>
              <Translation>{t => <strong>{this.state.category === "other_receiver" ? t('createUpdateForm:date') : t('createUpdateForm:date_of_invoice')}</strong>}</Translation>
            </p>
            <div className="input-group">
              {
                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ?
                  <DatePickerAdapter
                    name="issuedDate"
                    className="form-control"
                    selected={this.state.issuedDate}
                    minDate={this.state.temp_month_before}
                    // maxDate={this.state.temp_month_after}
                    disable={this.props.mode === 'edit' && this.state.group_detail?.scheduleTime}
                    onChange={this.handleInputChange}
                    required={true}
                  /> :
                  <DatePickerAdapter
                    name="issuedDate"
                    className="form-control"
                    selected={this.state.issuedDate}
                    disable={this.props.mode === 'edit' && this.state.group_detail?.scheduleTime}
                    // maxDate={this.state.dueDate}
                    onChange={this.handleInputChange}
                    required={true}
                  />
              }
            </div>
          </div>

          {this.state.category !== "other_expense" && this.state.category !== "other_receiver" && (
            <React.Fragment>
              <div className="col">
                <p>
                  <Translation>{t => <strong>{t('createUpdateForm:due_date')}</strong>}</Translation>
                </p>
                <div className="input-group">
                  <DatePickerAdapter
                    name="dueDate"
                    className="form-control"
                    selected={this.state.dueDate}
                    onChange={this.handleInputChange}
                    required={true}
                  />
                </div>
              </div>

              <div className="col">
                <p>&nbsp;</p>
                <div className="input-group">
                  <input
                    type="number"
                    min={0}
                    name="dueDateCount"
                    className="form-control borderRight text-right"
                    value={this.state.dueDateCount}
                    onChange={this.handleInputChange}
                    required
                  />
                  <div className="input-group-append">
                    <Translation>{t => <span className="input-group-text input-date">{t('createUpdateForm:day')}</span>}</Translation>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          <div className="col">
            {this.props.project_have_vat && (
              <React.Fragment>
                <p>
                  <Translation>{t => <strong>{t('createUpdateForm:type_of_tax')}</strong>}</Translation>
                </p>
                <div className="input-group">
                  <select
                    className="form-control"
                    name="taxType"
                    value={this.state.taxType}
                    onChange={this.handleInputChange}
                  >
                    <Translation>{t => <option value="NO_TAX">{t('createUpdateForm:no_tax')}</option>}</Translation>
                    <Translation>{t => <option value="EXCLUDE_TAX">{t('createUpdateForm:exclude_tax')}</option>}</Translation>
                    <Translation>{t => <option value="INCLUDE_TAX">{t('createUpdateForm:include_tax')}</option>}</Translation>
                  </select>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>


        {/* สัญญารายรับใช้ได้กับลูกหนี้ 1 ห้องต่อครั้ง */}
        {
          // localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
          <div className="row">
            {this.props.contactList?.length === 1 && (this.props.contact?.typeOfContact === "SUPPLIER" && this.props.contact?.typeOfPayment === "RECEIVABLE") && (
              <React.Fragment>
                <div className="col-3">

                  <p><Translation>{t => <strong>{t('contract_service:Contract Service Document')}</strong>}</Translation></p>
                  <SearchSelect onChange={this.handleInputChange}
                    value={this.state.all_contract_revenue_id}
                    name="all_contract_revenue_id" placeholder="รายการ"
                    queryObject={this.props.all_contract_revenue}
                    keyOfValue="id"
                    // require={true}
                    keyOfLabel="docNumber" />
                </div>
                <div className="col-3">
                  <p><Translation>{t => <strong>{t('contract_service:Category')}</strong>}</Translation></p>
                  {
                    this.state.all_contract_revenue_category
                  }
                </div>

                <div className="col-3">
                  <p><Translation>{t => <strong>{t('contract_service:Contract')}</strong>}</Translation></p>

                  {
                    this.state.all_contract_revenue_contract_number
                  }

                </div>
              </React.Fragment>
            )}
          </div>
        }
        {/* สัญญารายรับใช้ได้กับลูกหนี้ 1 ห้องต่อครั้ง */}


        <hr />

        {this.state.preinvoice === false &&
          this.state.category !== "purchase" &&
          this.state.category !== "other_expense" && (
            <div className="row justify-content-between">
              <div className="col-md-6">
                <p>
                  <Translation>{t => <strong>{t('createUpdateForm:address')}</strong>}</Translation>
                </p>
                {this.props.contactList?.length === 1 ? (
                  this.props.contact ? (
                    <p>{this.state.address}</p>
                  ) : (
                    <p>-</p>
                  )
                ) : (
                  <p>-</p>
                )}
              </div>
            </div>
          )}
        {this.state.preinvoice === false &&
          <div className="fade-up">
            <div className="card mt-2">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th width="200"><Translation>{t => t('createUpdateForm:list')}</Translation></th>
                    <th width="90"><Translation>{t => t('createUpdateForm:account')}</Translation></th>
                    <th width="200"><Translation>{t => t('createUpdateForm:description')}</Translation></th>
                    <th width="60" className="text-right">
                      <Translation>{t => t('createUpdateForm:amount')}</Translation>
                    </th>
                    <th width="80" className="text-right">
                      <Translation>{t => t('createUpdateForm:price/unit')}</Translation>
                    </th>
                    {this.props.category !== "purchase" && (
                      <th width="60" className="text-right">
                        <Translation>{t => t('createUpdateForm:discount')}</Translation>
                      </th>
                    )}
                    {(!(this.state.taxType === "NO_TAX") ||
                      this.state.case_return_vat) && (
                        <th width="60" className="text-right">
                          <Translation>{t => t('createUpdateForm:tax')}</Translation>
                        </th>
                      )}
                    <Translation>
                      {t =>
                        <th width="100" className="text-right">
                          {!(this.state.taxType === "NO_TAX")
                            ? "ยอดก่อนภาษี"
                            : t('createUpdateForm:total')}
                        </th>
                      }
                    </Translation>
                    {this.props.contact && (
                      <th width="100" className="text-right">
                        <Translation>{t => t('createUpdateForm:withholding')}</Translation>
                      </th>
                    )}

                    <th width="100" className="text-right"><Translation>{t => t('createUpdateForm:No.Number')}</Translation></th>


                    {/*<th width="100" className="text-right">ยอดหัก ณ ที่จ่าย</th>*/}
                    {this.state.transaction_list.length !== 1 && <th width={1} />}
                  </tr>
                </thead>
                <tbody>                  
                  {this.state.preinvoice === false && this.state.transaction_list?.map((transaction, index) => {                  
                    let productAndServiceOrChartOfAccount = "";
                    let disableTransaction = false;
                    let invoice_cal_fine = false;
                    if (transaction.chartOfAccount) {
                      disableTransaction = true;
                      productAndServiceOrChartOfAccount = transaction.chartOfAccount;
                    } else {
                      productAndServiceOrChartOfAccount = transaction.productAndService;
                    }
                    if (this.state.category === "invoice" && transaction.type === "FINE" && this.props.mode === "create") {
                      invoice_cal_fine = true;
                    }
                    return (
                      <tr key={"transaction-" + index}>
                        <td>
                          {transaction.chartOfAccount ? (
                            <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                              <input
                                type="text"
                                className="form-control mt-2"
                                value={transaction.chartOfAccount.chartOfAccountCode + " " + transaction.chartOfAccount.name}
                                disabled
                              />
                            </div>

                          ) : (
                            <Translation>
                              {t =>
                                <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                                  <SearchSelect
                                    onChange={this.handleInputChange}
                                    value={transaction.productAndService.productCode === 'S000' && this.props.mode === 'edit' ? 'fine' : productAndServiceOrChartOfAccount.id}
                                    name="productAndService"
                                    placeholder={t('createUpdateForm:list')}
                                    data-id={index}
                                    data-key="transaction_list"
                                    queryObject={this.state.product_and_service}
                                    keyOfValue="id"
                                    require={true}
                                    keyOfLabel="productCode:name"
                                    disabled={transaction.productAndService.productCode === 'S000' && this.props.mode === 'edit' ? true : disableTransaction}
                                  />
                                </div>
                              }
                            </Translation>
                          )}
                        </td>

                        <td>
                          {transaction?.chartOfAccount ? (
                            <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                              <span>
                                {transaction.chartOfAccount.chartOfAccountCode}
                              </span>
                            </div >
                          ) : (
                            this.state.product_and_service?.map((product) => {
                              if (product.node.id === productAndServiceOrChartOfAccount.id) {
                                return (
                                  <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""} key={product.node.id}>
                                    <span>
                                      {product.node.chartOfAccount
                                        .chartOfAccountCode}
                                    </span>
                                  </div>
                                )
                              } else if (product.node.id === 'fine' && productAndServiceOrChartOfAccount.productCode === 'S000') {
                                return (
                                  <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""} key={product.node.id}>
                                    <span>
                                      {product.node.chartOfAccount.chartOfAccountCode}
                                    </span>
                                  </div>
                                )
                              }
                            }
                            )
                          )}
                        </td>

                        <td>
                          <div className="invoice-description-wrapper">
                            {/*for product and service no option*/}
                            {!_.get(
                              this.state.transaction_list,
                              index +
                              ".productAndService.servicePricing.edges.0.node.periodAdjustment"
                            ) &&
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") !== "WATER_METER" &&
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") !== "ELECTRIC_METER" ? (

                              <div className={(disableTransaction && invoice_cal_fine && this.isLimitDescription(this.props?.category)) ? "padding-top" : ""}>
                                <Translation>{t => (!disableTransaction && !invoice_cal_fine && this.isLimitDescription(this.props?.category)) && <p className="text-danger" style={{ fontSize: '10px', marginBottom: '-1px' }}> * {t('createUpdateForm:Enter message not more than')} 80 {t('createUpdateForm:characters')} {transaction.description.length}/80 </p>}</Translation>
                                <input
                                  type="text"
                                  min={1}
                                  className="form-control float-left"
                                  data-id={index}
                                  data-key="transaction_list"
                                  name="description"
                                  value={transaction.description || ""}
                                  onChange={this.handleInputChange}
                                  required
                                  maxLength={this.isLimitDescription(this.props?.category) && 80}
                                />
                              </div>
                            ) : (
                              <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                                <span>
                                  {this.removeTrailingZeros(transaction.description)}
                                </span>
                              </div>
                            )}

                            {/*for water meter*/}
                            {(_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "WATER_METER" ||
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "ELECTRIC_METER") &&
                              _.get(this.state.transaction_list, index + ".productAndService.type") === "SERVICE" &&
                              _.get(this.state.transaction_list, index + ".contract_revenue") !== true && (
                                <WaterMeterSelectDate
                                  type_service={_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType")}
                                  setWaterMeterTransaction={this.setWaterMeterTransaction}
                                  setElectricMeterTransaction={this.setElectricMeterTransaction}
                                  transaction={transaction}
                                  contact={this.state.contactList}
                                  transaction_index={index}
                                  mode={this.props.mode}
                                />
                              )}

                            {/*for period service*/}
                            {_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.periodAdjustment"
                            ) && _.get(this.state.transaction_list, index + ".productAndService.type") === "SERVICE" && (
                                // _.get(this.state.transaction_list, index + ".contract_revenue") !== true && (
                                <PeriodAdjustSetting
                                  setServicePeriod={this.setServicePeriod}
                                  transaction={transaction}
                                  contact={this.state.contactList}
                                  transaction_index={index}
                                  mode={this.props.mode}
                                />
                              )}

                            {/* for area and individual no service */}
                            {!_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.periodAdjustment") &&
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") !== "WATER_METER" &&
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") !== "ELECTRIC_METER" &&
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") !== "STATIC" &&
                              _.get(this.state.transaction_list, index + ".productAndService.type") === "SERVICE" &&
                              _.get(this.state.transaction_list, index + ".contract_revenue") !== true &&

                              !(_.get(this.state.transaction_list, index + ".preInvoice") ||
                                _.get(this.state.transaction_list, index + ".obligationDeveloper") ||
                                _.get(this.state.transaction_list, index + ".obligationTenant")
                              ) &&
                              (
                                <IndividualAreaPriceSettings
                                  setIndividualTransaction={
                                    this.setIndividualTransaction
                                  }
                                  transaction={transaction}
                                  contact={this.state.contactList}
                                  transaction_index={index}
                                  mode={this.props.mode}
                                />
                              )}

                            {(_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "CAR_PARK" ||
                              _.get(this.state.transaction_list, +".productAndService.servicePricing.edges.0.node.pricingType") === "MOTORCYCLE_PARK") &&
                              _.get(this.state.transaction_list, index + ".productAndService.type") === "SERVICE" &&
                              _.get(this.state.transaction_list, index + ".contract_revenue") !== true && (
                                <CarParkSelect
                                  setCarParkTransaction={this.setCarParkTransaction}
                                  contact={this.state.contactList}
                                  transaction={transaction}
                                  transaction_index={index}                                  
                                  type_fee={_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType")}
                                />
                              )}
                          </div>
                        </td>
                        <td className="text-right">
                          <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                            <input
                              type="number"
                              min={(_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "CAR_PARK" ||
                                _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "MOTORCYCLE_PARK") &&
                                this.props.contactList.length > 1 ? 0 : 1
                              }
                              className="form-control text-right"
                              max={productAndServiceOrChartOfAccount.totalLeftInStock}
                              data-id={index}
                              data-key="transaction_list"
                              name="unitItems"
                              value={this.props.category !== 'purchase' ? transaction?.totalLeftInStock !== 0 ?
                                transaction.unitItems : 0 :
                                transaction.unitItems}
                              onChange={this.handleInputChange}
                              disabled={
                                (_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "AREA" ||
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "WATER_METER" ||
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "ELECTRIC_METER" ||
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "RATIO" ||
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "INDIVIDUAL" ||
                                  (_.get(this.state.transaction_list, index + ".productAndService.type") === "PRODUCT" && _.get(this.state.transaction_list, index + ".productAndService.numberKey.edges.length") > 0) ||
                                  disableTransaction || invoice_cal_fine) || 
                                  (this.props.category !== 'purchase' && transaction?.totalLeftInStock === 0)
                              }
                              required
                            />
                          </div>
                        </td>
                        <td className="text-right">
                          <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                            <input
                              type="number"
                              min={0}
                              step="0.01"
                              className="form-control text-right"
                              data-id={index}
                              data-key="transaction_list"
                              name="price"
                              value={transaction.price}
                              onChange={this.handleInputChange}
                              disabled={
                                _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "AREA" ||
                                _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "WATER_METER" ||
                                _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "ELECTRIC_METER" ||
                                _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "INDIVIDUAL" ||
                                _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "RATIO" ||
                                disableTransaction ||
                                invoice_cal_fine
                              }
                              title={this.state.case_return_vat ? "กรุณากรอกราคาที่รวมยอดภาษี" : ""}
                            // required
                            />
                          </div>
                        </td>
                        {this.props.category !== "purchase" && (
                          <td style={{ width: 60 }} className="text-right">
                            <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                              <input
                                type="number"
                                min={0}
                                className="form-control text-right"
                                data-id={index}
                                data-key="transaction_list"
                                name="discount"
                                value={transaction.productAndService.productCode === 'S000' ? 0 : transaction.discount}
                                onChange={this.handleInputChange}
                                disabled={
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "AREA" ||
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "WATER_METER" ||
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "ELECTRIC_METER" ||
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "RATIO" ||
                                  disableTransaction ||
                                  invoice_cal_fine
                                }
                                step="0.01"
                                required
                              />
                            </div>
                          </td>
                        )}
                        {(!(this.state.taxType === "NO_TAX") ||
                          this.state.case_return_vat) && (
                            <td>
                              <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                                <select
                                  className="form-control"
                                  value={transaction.vat}
                                  data-id={index}
                                  data-key="transaction_list"
                                  name="vat"
                                  disabled={this.state.case_return_vat ? "disabled" : false}
                                  onChange={this.handleInputChange}
                                >
                                  <Translation>{t => <option value="">{t('createUpdateForm:none')}</option>}</Translation>
                                  <option value="0">VAT 0%</option>
                                  <option value="7">VAT 7%</option>
                                </select>
                              </div>
                            </td>
                          )}
                        <td className="text-right">
                          {_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.periodAdjustment") ? (
                            <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                              <span>
                                {numberWithComma(transaction.preTaxAmount)}
                              </span>
                            </div>
                          ) : (
                            <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                              <span>
                                {numberWithComma(this.getPreTaxAmount(transaction))}
                              </span>
                            </div>
                          )}
                        </td>
                        {this.props.contact && (
                          <td>
                            <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                              <select
                                className="form-control"
                                value={transaction.whtRatePercent || 0}
                                data-id={index}
                                data-key="transaction_list"
                                name="whtRatePercent"
                                disabled={
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "AREA" ||
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "WATER_METER" ||
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "ELECTRIC_METER" ||
                                  _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "RATIO" ||
                                  disableTransaction ||
                                  invoice_cal_fine
                                }
                                onChange={this.handleInputChange}
                              >
                                <Translation>{t => <option value={0}>{t('createUpdateForm:none')}</option>}</Translation>
                                <option value={1}>1%</option>
                                <option value={1.5}>1.5%</option>
                                <option value={2}>2%</option>
                                <option value={3}>3%</option>
                                <option value={5}>5%</option>
                                <option value={10}>10%</option>
                                <option value={15}>15%</option>
                              </select>
                            </div>
                          </td>
                        )}
                        {(this.props.category === "invoice" || this.props.category === "other_receiver") && _.get(this.state.transaction_list, index + ".productAndService.type") === "PRODUCT" && _.get(this.state.transaction_list, index + ".productAndService.noNumber") !== false && _.get(this.state.transaction_list, index + ".productAndService.numberKey.edges.length") > 0 ?
                          <td>
                            <div className={this.isLimitDescription(this.props?.category) ? "padding-top" : ""}>
                              <SearchSelect
                                onChange={this.handleInputChange}
                                value={transaction.numberKeyId}
                                name="numberKeyId"
                                placeholder={"รายการ"}
                                data-id={index}
                                data-key="transaction_list"
                                queryObject={this.state.transaction_list[index].productAndService.numberKey.edges}
                                keyOfValue="id"
                                require={true}
                                keyOfLabel="noNumber"
                              />
                            </div>
                          </td>
                          :
                          <td />
                        }

                        {this.state.transaction_list.length !== 1 && (
                          <td width={1}>
                            {
                              <InvoiceDelete
                                transaction={transaction}
                                transaction_list={this.state.transaction_list}
                                setTransactionList={this.setTransactionList}
                              />
                            }
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {!this.props.disableNewRow && (
              <button
                type="button"
                className="btn add-button add mt-3"
                onClick={this.appendNewRow}
              >
                + <Translation>{t => t('createUpdateForm:add_list')}</Translation>
              </button>
            )}

            {!this.state.group_detail &&
              this.state.transaction_list.length > 0 &&
              this.state.category !== "prepaid_deposit" &&
              this.state.category !== "other_expense" &&
              this.state.category !== "other_receiver" &&
              !this.state.preinvoice &&
              (
                <QueryRenderer
                  environment={environment}
                  query={query}
                  fetchPolicy="no-cache"
                  variables={{
                    contact: this.props.contactList,
                    taxType: this.state.taxType,
                    invoiceDate: this.state.issuedDate,
                    vat: this.state.transaction_list[0].vat,
                  }}
                  render={({ error, props }) => {
                    if (props) {
                      {/* การใช้ RD ในการสร้างใบแจ้งหนี้ไม่ใช่แบบร่าง*/ }
                      return (
                        props.receiptDepositTransactionActive.length > 0 && (
                          <React.Fragment>
                            <div className="row mt-3">
                              <div className="col-sm-12">
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="not-use-prepaid"
                                    name="receiveDeposit"
                                    data-id=""
                                    value="0"
                                    onChange={this.handleInputChange}
                                    checked={!this.state.chartOfAccountId}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="not-use-prepaid"
                                  >

                                    <Translation>{t => t('createUpdateForm:Not use receipt deposit transaction')}</Translation>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {props.receiptDepositTransactionActive.map(
                              (trans) => (
                                <div
                                  className="row"
                                  key={trans.chartOfAccount_Id}
                                >
                                  <div className="col-sm-12">
                                    <div className="custom-control custom-radio custom-control-inline">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id={trans.chartOfAccount_Id}
                                        name="receiveDeposit"
                                        data-id={trans.chartOfAccount_Id}
                                        value={trans.totalBalance}
                                        onChange={this.handleInputChange}
                                        // checked={this.state.receiveDeposit === trans.totalBalance}
                                        checked={
                                          this.state.chartOfAccountId ===
                                          trans.chartOfAccount_Id
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={trans.chartOfAccount_Id}
                                      >
                                        {trans.chartOfAccount_Name + " " + trans.chartOfAccount_Code +
                                          " (" + numberWithComma(trans.totalBalance) + " บาท)"}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </React.Fragment>
                        )
                      );
                    } else {
                      return <Loading />
                    }
                  }}
                />
              )}

            <React.Fragment>
              {/* การแสดงยอดค้างชำระ */}
              {this.props.category === "invoice" && this.props.invoiceQuery.invoice?.payGroup !== "RENT" && (
                <div className="row mt-3">
                  <div className="col-sm-12">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="list_history_checker"
                        name="list_history_checker"
                        onChange={this.handleInputChange}
                        value={!this.state.list_history_checker}
                        checked={this.state.list_history_checker}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="list_history_checker"
                      >
                        <Translation>{t => <strong>{t('createUpdateForm:Show outstanding items.')}</strong>}</Translation>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>

            {/* การตัดใช้ RD  */}
            {this.state.receiptDepositTransaction && this.props.invoiceQuery.invoice?.payGroup === "OWNER" &&
              this.state.receiptDepositTransaction.map(
                (rd_transaction, index) => (
                  <React.Fragment key={rd_transaction.receipt_transaction.id}>
                    <div className="form-group row mt-3">
                      <div className="col-sm-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="receiptDepositTransaction"
                            onChange={this.handleInputChange}
                            data-id={index}
                            id={"receiptDepositTransaction" + rd_transaction.receipt_transaction.id
                            }
                            checked={rd_transaction.checked}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"receiptDepositTransaction" + rd_transaction.receipt_transaction.id
                            }
                          >
                            {rd_transaction.receipt_transaction.chartOfAccount
                              .chartOfAccountCode +
                              " " +
                              rd_transaction.receipt_transaction.chartOfAccount
                                .name +
                              " จำนวน " +
                              numberWithComma(
                                rd_transaction.receipt_transaction.price
                              ) +
                              " บาท ยอดที่สามารถใช้ได้ " +
                              numberWithComma(
                                rd_transaction.receipt_transaction.balance
                              )}
                          </label>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              )}

            {this.state.category === "other_receiver" && (
              <ReceiveForm
                updateReceiveChannel={this.updatePayChannel}
                page={"other_receiver"}
                payment_type={"receive"}
                credit_card_channel={this.props.credit_card_channel}
                not_other_channel={this.props.not_other_channel}
                issued_date={this.state.issuedDate}
                receive_form={true}
                pay={this.state.totalPay}
                total={this.state.total - this.state.total_discount}
                total_discount={this.state.total_discount}
                notReceiptDeposit={this.props.contactType === 'unknown' && true}
                carReportDocList={this.props.carReportDocList}
              />
            )}
          </div>
        }

        {/* การแสดงยอดค้างชำระ ของแจ้งหนี้ออโต้*/}
        {this.state.preinvoice && (
          <div className="row mt-3">
            <div className="col-sm-12">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="list_history_checker"
                  name="list_history_checker"
                  onChange={this.handleInputChange}
                  value={!this.state.list_history_checker}
                  checked={this.state.list_history_checker}
                />
                <label
                  className="custom-control-label"
                  htmlFor="list_history_checker"
                >
                  <Translation>{t => <strong>{t('createUpdateForm:Show outstanding items.')}</strong>}</Translation>
                </label>
              </div>
            </div>
          </div>
        )}
        {/* ผลรวมและการเลือกหมายเหตุ */}
        <InvoiceSummary
          remark={this.state.remark}
          handleInputChange={this.handleInputChange}
          transaction_list={this.state.transaction_list}
          remark_edit={false}
          receiveDeposit={this.state.receiveDeposit}
          taxType={this.state.taxType}
          category={this.state.category}
          required={this.state.category === "other_expense" ? true : false}
          mode={this.props.mode}
          getRemarkCallback={this.getRemark}
          total_discount={this.state.total_discount}
          preinvoice={this.state.preinvoice}
        />

        {/* setting schedule_date invoice */}

        {this.state.category === "invoice" && <div className="row pt-12">
          <div className="col-8">
            <div className={`card p-4 mt-2 ${this.state.contactList ? 'setting-post-invoice' : ''} ${this.state?.remark ? 'setting-post-invoice2' : ''}`}>
              <div className="d-flex">
                <h6>
                  <Translation>
                    {(t) => <span>{t("createUpdateForm:Setting a schedule date post invoice")}</span>}
                  </Translation>
                </h6>
                <Translation>
                  {(t) => <span className="text-red pl-2">{t("createUpdateForm:Please select when you would like to setting a schedule date invoice")}</span>}
                </Translation>
              </div>

              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="tdCheckbox"
                  onClick={() => this.setState({ schedule_status: !this.state.schedule_status })}
                  disabled={this.props.mode === 'edit' && this.state.group_detail?.scheduleTime}
                />
                <label
                  className="form-check-label"
                  htmlFor="tdCheckbox"
                >
                  <Translation>
                    {(t) => <span>{t("createUpdateForm:set time schedule invoice")}</span>}
                  </Translation>
                  <span></span>
                </label>
              </div>
              <div></div>


              {this.state.schedule_status && (
                <div className="mt-3 time-selected">
                  <div className="row">
                    <div className="col-8 noti-date">
                      <p>
                        <Translation>
                          {(t) => <span>{t("dashboard:Date")}</span>}
                        </Translation>
                      </p>
                      <DatePickerAdapter
                        id="temp_schedule_date"
                        className="form-control"
                        name="temp_schedule_date"
                        selected={this.state.temp_schedule_date}
                        onChange={this.handleChangeDatetime}
                        maxDate={this.state.issuedDate}
                        minDate={this.state.date_now}
                        value={this.state.temp_schedule_date}
                        disable={this.props.mode === 'edit' && this.state.group_detail?.scheduleTime}
                      />
                    </div>
                    <div className="col-4">
                      <div className="calendar-wrapper time-selected-input">
                        <p>
                          <Translation>
                            {(t) => <span>{t("press_release:time")}</span>}
                          </Translation>
                        </p>
                        <DatePicker
                          className="form-control"
                          selected={this.state.temp_schedule_date}
                          onChange={(date) => {
                            this.setDatetime(date)
                          }}
                          timeInputLabel="Time:"
                          dateFormat="h:mm aa"
                          showTimeInput
                          disabled={this.props.mode === 'edit' && this.state.group_detail?.scheduleTime}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>

        </div>}

        <div className="row">
          <div className="col text-right mt-2 button-grp">
            <Link to={this.props.back_page}>
              <button type="button" className="btn btn-secondary add">
                <Translation>{t => t('createUpdateForm:cancel')}</Translation>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => this.checkFormBeforeSubmit("draft")}
              className="btn btn-primary add"
              disabled={this.state.loading}
            >
              {this.state.loading && (<span className="spinner-border spinner-border-sm align-middle mr-2" />)}

              <Translation>{t =>
                <span>{this.props.category === "other_receiver"
                  ? t('createUpdateForm:Save')
                  : t('createUpdateForm:save_as_draft')}
                </span>
              }
              </Translation>
            </button>
            {this.state.category === "invoice" &&
              this.state.group_detail && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_invoice_approve", }) && (
                <Translation>
                  {t =>
                    <button
                      type="button"
                      onClick={() => this.checkFormBeforeSubmit("active")}                      
                      className="btn btn-info add"
                      disabled={this.state.loading}
                    >
                      {this.state.loading && (
                        <span className="spinner-border spinner-border-sm align-middle mr-2" />
                      )}
                      {t('createUpdateForm:approve')}
                    </button>
                  }
                </Translation>
              )}
          </div>
        </div>
      </form>
    );
  }
}

export default InvoiceCreateUpdateForm;