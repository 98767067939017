import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../env/environment";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import { Translation } from "react-i18next";

const mutation = graphql`
    mutation chatBoxRenderMutation($input: CreateUpdateReadInput!){
        createUpdateRead(input: $input){
            newRead{
                id
            }
        }
    }
`;

class ChatBoxRender extends Component {

    componentDidMount() {
        // default scroll to bottom
        let objDiv = document.getElementById("chat-box");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    componentWillMount() {
        this.mutationCreateUpdateRead()
    }

    componentWillUnmount() {
        this.mutationCreateUpdateRead()
    }

    mutationCreateUpdateRead() {
        let variables = {
            input: {
                clientMutationId: this.props.room.id,
            }
        };
        commitMutation(
            environment,
            {
                mutation,
                variables,
            },
        );
    }

    getStatus(status) {
        let text_request = ''
        switch (status.room.status) {
            case 'OPEN':
                text_request= "new_request";
                break;
            case 'FINISHED':
                text_request= "finished";
                break;
            case 'APPROVE':
                text_request= "approve";
                break;
            default:
                text_request= 'cancel';
                break;
        }
            return <Translation>{t=>t('fix_request:'+text_request)}</Translation>

    }

    render() {

        return (
            <React.Fragment>
                <Translation>
                    {t=>
                <div className="row">
                    <div id="chat-box" className="col chat-area">
                        <div className="row">
                            <div className="col-md-3"/>
                            <div className="col-md-6">
                                <div className="card card-border">
                                    <div className="card-body text-center">
                                        <h6>{this.getStatus(this.props)}</h6>
                                        {
                                            this.props.room.fixRequestRoom &&
                                                <span className="small">
                                                        {this.props.room.fixRequestRoom.technician === 'IN_HOUSE' ? t("chat:in_house_handyman") : t("chat:commercial_handyman")} | {t("chat:inside_the_room")}
                                                </span>
                                        }

                                        <p>{this.props.room.name}</p>
                                    </div>
                                </div>
                                {/*<p className="grey-color text-right mt-2 small">18:20 น.</p>*/}
                            </div>
                            <div className="col-md-3"/>
                        </div>

                        <ul>
                            {this.props.messagesQuery.map((message, index) =>
                                message.node.handle.juristic ?
                                    <li key={message.node.id}>
                                        {/*แชทฝั่งขวา*/}
                                        <div className="juristic">
                                            {message.node.message}
                                            <a href={message.node.image} target="_blank" rel="noopener noreferrer">
                                            <img src={message.node.image} style={{maxWidth: 200, maxHeight: 200}} alt={"image-query" + index} className={message.node.image ? 'img-fluid d-block': 'd-none'}/>
                                            </a>
                                            <span
                                                className="time">{format(message.node.updated, 'DD/MM/YYYY - HH:mm น.', {locale: thLocale})}</span>
                                        </div>
                                    </li> :

                                    <li key={message.node.id}>
                                        {/*แชทฝั่งซ้าย*/}
                                        <div className="tenant">
                                            {message.node.message}
                                            <a href={message.node.image} target="_blank" rel="noopener noreferrer">
                                            <img src={message.node.image} style={{maxWidth: 200, maxHeight: 200}}  alt={"image-query" + index} className={message.node.image ? 'img-fluid d-block': 'd-none'}/>
                                            </a>
                                            <img
                                                src={message.node.handle.tenant ? message.node.handle.tenant.image : process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                alt="profile"
                                                className="profile rounded-circle"/>
                                            <span
                                                className="time">{format(message.node.updated, 'DD/MM/YYYY - HH:mm น.', {locale: thLocale})}</span>
                                        </div>
                                    </li>
                            )}

                            {
                                // websocket
                                this.props.messagesWebSocket.map((message, index) =>

                                    message.role === 'juristic' ?
                                        <li key={message.message_id}>
                                            {/*แชทฝั่งขวา*/}
                                            <div className="juristic">
                                                {message.message}
                                                <img src={message.image} alt={"image-ws" + index} className={message.image ? 'img-fluid d-block': 'd-none'}/>
                                                <Translation>
                                                    {t=>
                                                    <span className="time">
                                                    {message.updated ?
                                                        format(message.updated, 'DD/MM/YYYY - HH:mm น.', {locale: thLocale})
                                                        : t("fix_request:sending")}
                                                </span>}
                                                </Translation>

                                            </div>
                                        </li> :

                                        <li key={message.message_id}>
                                            {/*แชทฝั่งซ้าย*/}
                                            <div className="tenant">
                                                {message.message}
                                                <img src={message.image} alt={"image-ws" + index} className={message.image ? 'img-fluid d-block': 'd-none'}/>
                                                <img
                                                    src={message.profile ? message.profile : process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                    alt="profile"
                                                    className="profile rounded-circle"/>
                                                <Translation>
                                                    {t=><span className="time">
                                                    {message.updated ?
                                                        format(message.updated, 'DD/MM/YYYY - HH:mm น.', {locale: thLocale})
                                                        : t("fix_request:sending")}
                                                </span>}
                                                </Translation>
                                            </div>
                                        </li>
                                )}

                            <li style={{clear: 'both', width: '100%'}}/>
                        </ul>
                    </div>
                </div>
                    }
                </Translation>
            </React.Fragment>
        )
    }
}

export default ChatBoxRender;
