import { graphql } from "babel-plugin-relay/macro";

const queryAllOtherReceives = graphql`
    query allOtherReceivesQuery($search: String){
        allOtherReceive(search: $search){
            edges{
                node{
                    id
                    unknownContact
                    contact{
                        name
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`

export default queryAllOtherReceives;