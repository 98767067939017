/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type ChequeTransactionStatus = "BIDE" | "CANCEL" | "CLEARING" | "DELETE" | "VOID" | "VOID_HISTORICAL" | "WAIT" | "%future added value";
export type viewChequeTransactionQueryVariables = {|
  status?: ?string,
  first?: ?number,
  last?: ?number,
  chequeTransactionId?: ?string,
  bankAccountId?: ?string,
|};
export type viewChequeTransactionQueryResponse = {|
  +allChequeTransactions: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +date: any,
        +refNumber: ?string,
        +depositDate: ?any,
        +chequeDetails: ?{|
          +id: string,
          +noNumber: string,
        |},
        +chequeNo: ?{|
          +id: string,
          +noNumber: string,
        |},
        +chequeDate: ?any,
        +bankAccount: ?{|
          +id: string,
          +bankName: string,
        |},
        +chequeNumber: string,
        +chequeNoCustom: ?string,
        +chequeDetailsCustom: ?string,
        +payName: ?string,
        +docNumber: ?string,
        +description: ?string,
        +price: number,
        +status: ChequeTransactionStatus,
        +chequeDocuments: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: ?string,
              +fileUpload: string,
            |}
          |}>
        |},
        +historical: boolean,
      |}
    |}>,
  |},
  +chequeControl: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +bankName: string,
        +branch: string,
        +bankLogo: string,
        +accountType: BankAccountAccountType,
        +accountName: string,
        +accountNameEn: ?string,
        +accountNumber: string,
        +updated: any,
        +chequeDetails: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +status: boolean,
              +noNumber: string,
              +startCheque: ?string,
              +endCheque: ?string,
              +lastCheque: ?string,
              +chequeNo: ?{|
                +totalCount: ?number,
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +status: boolean,
                    +noNumber: string,
                  |}
                |}>,
              |},
            |}
          |}>
        |},
      |}
    |}>,
  |},
|};
export type viewChequeTransactionQuery = {|
  variables: viewChequeTransactionQueryVariables,
  response: viewChequeTransactionQueryResponse,
|};
*/


/*
query viewChequeTransactionQuery(
  $status: String
  $first: Int
  $last: Int
  $chequeTransactionId: String
  $bankAccountId: String
) {
  allChequeTransactions(chequeTransactionId: $chequeTransactionId, status: $status, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        date
        refNumber
        depositDate
        chequeDetails {
          id
          noNumber
        }
        chequeNo {
          id
          noNumber
        }
        chequeDate
        bankAccount {
          id
          bankName
        }
        chequeNumber
        chequeNoCustom
        chequeDetailsCustom
        payName
        docNumber
        description
        price
        status
        chequeDocuments {
          edges {
            node {
              id
              fileName
              fileUpload
            }
          }
        }
        historical
      }
    }
  }
  chequeControl(bankAccountId: $bankAccountId) {
    totalCount
    edges {
      node {
        id
        docNumber
        bankName
        branch
        bankLogo
        accountType
        accountName
        accountNameEn
        accountNumber
        updated
        chequeDetails(status: true) {
          edges {
            node {
              id
              status
              noNumber
              startCheque
              endCheque
              lastCheque
              chequeNo(status: true) {
                totalCount
                edges {
                  node {
                    id
                    status
                    noNumber
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bankAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chequeTransactionId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "noNumber",
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "status",
    "value": true
  }
],
v13 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "chequeTransactionId",
        "variableName": "chequeTransactionId"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "ChequeTransactionNodeConnection",
    "kind": "LinkedField",
    "name": "allChequeTransactions",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ChequeTransactionNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChequeTransactionNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "depositDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChequeDetailsNode",
                "kind": "LinkedField",
                "name": "chequeDetails",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChequeNoNode",
                "kind": "LinkedField",
                "name": "chequeNo",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "chequeDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountNode",
                "kind": "LinkedField",
                "name": "bankAccount",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "chequeNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "chequeNoCustom",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "chequeDetailsCustom",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "payName",
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ChequeDocumentsNodeConnection",
                "kind": "LinkedField",
                "name": "chequeDocuments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChequeDocumentsNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChequeDocumentsNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileUpload",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "historical",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bankAccountId",
        "variableName": "bankAccountId"
      }
    ],
    "concreteType": "BankAccountNodeConnection",
    "kind": "LinkedField",
    "name": "chequeControl",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v10/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "branch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bankLogo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNameEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "ChequeDetailsNodeConnection",
                "kind": "LinkedField",
                "name": "chequeDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChequeDetailsNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChequeDetailsNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v11/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startCheque",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCheque",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastCheque",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v12/*: any*/),
                            "concreteType": "ChequeNoNodeConnection",
                            "kind": "LinkedField",
                            "name": "chequeNo",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ChequeNoNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ChequeNoNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v11/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "chequeNo(status:true)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "chequeDetails(status:true)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "viewChequeTransactionQuery",
    "selections": (v13/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "viewChequeTransactionQuery",
    "selections": (v13/*: any*/)
  },
  "params": {
    "cacheID": "70e73b276d84ebc405ad368e3ad37aed",
    "id": null,
    "metadata": {},
    "name": "viewChequeTransactionQuery",
    "operationKind": "query",
    "text": "query viewChequeTransactionQuery(\n  $status: String\n  $first: Int\n  $last: Int\n  $chequeTransactionId: String\n  $bankAccountId: String\n) {\n  allChequeTransactions(chequeTransactionId: $chequeTransactionId, status: $status, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        date\n        refNumber\n        depositDate\n        chequeDetails {\n          id\n          noNumber\n        }\n        chequeNo {\n          id\n          noNumber\n        }\n        chequeDate\n        bankAccount {\n          id\n          bankName\n        }\n        chequeNumber\n        chequeNoCustom\n        chequeDetailsCustom\n        payName\n        docNumber\n        description\n        price\n        status\n        chequeDocuments {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n            }\n          }\n        }\n        historical\n      }\n    }\n  }\n  chequeControl(bankAccountId: $bankAccountId) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        bankName\n        branch\n        bankLogo\n        accountType\n        accountName\n        accountNameEn\n        accountNumber\n        updated\n        chequeDetails(status: true) {\n          edges {\n            node {\n              id\n              status\n              noNumber\n              startCheque\n              endCheque\n              lastCheque\n              chequeNo(status: true) {\n                totalCount\n                edges {\n                  node {\n                    id\n                    status\n                    noNumber\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '095d71cee39e2bc470aa809fff1767e3';

module.exports = node;
