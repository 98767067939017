import React, { Component } from 'react';
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Wrapper from "../../../components/wrapper";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18next from 'i18next';
import BackButtonIcon from "../../../components/BackBtn/indexBack";

class Dashboard extends Component {

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack={"/accounting"}
                                    LinkText={i18next.t("receivable_dashboard:Accounts Receivable Report")}
                                />

                                <div className="content-inner">
                                    <div className="row fade-up">

                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">1. {i18next.t("receivable_dashboard:Invoice Issuance Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/invoice" className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">2. {i18next.t("receivable_dashboard:Payment Receipt Summary Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/receive"
                                                        className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">3. {i18next.t("receivable_dashboard:Detailed Outstanding Receivables Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/receivable_outstanding" className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row fade-up mt-3">
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">4. {i18next.t("receivable_dashboard:Summary Outstanding Receivables Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/receivable_outstanding_summary"
                                                        className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">5. {i18next.t("receivable_dashboard:Total Accounts Receivable Summary Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/receivable_classified"
                                                        className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">6. {i18next.t("receivable_dashboard:Accounts Receivable Movement Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/history/select-customer" className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row fade-up mt-3">
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">7. {i18next.t("receivable_dashboard:Credit Note Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/credit_note"
                                                        className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">8. {i18next.t("receivable_dashboard:Security Deposit Receipt Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/guarantee-money-received"
                                                        className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">9. {i18next.t("receivable_dashboard:Security Deposit Movement Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/guarantee-money-received-movement/select-customer"
                                                        className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row fade-up mt-3">
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">10. {i18next.t("receivable_dashboard:Advance Payment Receipt Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/receipt_deposit"
                                                        className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">11. {i18next.t("receivable_dashboard:Advance Payment Movement Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/receipt_deposit_movement/select-customer"
                                                        className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">12. {i18next.t("receivable_dashboard:Other Payment Receipt Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/other_receipt"
                                                        className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row fade-up mt-3">
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">13. {i18next.t("receivable_dashboard:Daily Summary Payment Receipts Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/dailyPaymentReceiptReport"
                                                        className="btn btn-primary btn-sm">{i18next.t("receivable_dashboard:View")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                        <Translation>
                                        {
                                            t =>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">14. {t('individualReceipt:individualReceipt')}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/individualReceiptReport"
                                                        className="btn btn-primary btn-sm">{t('individualReceipt:viewReport')}</Link>
                                                </div>
                                            </div>
                                        }
                                        </Translation>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                        <Translation>
                                        {
                                            t =>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">15. {t('utilityReceivable:electricityAndWaterReceivableReport')}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-receivable/waterAndElectricUtilityReceiveReport"
                                                        className="btn btn-primary btn-sm">{t('individualReceipt:viewReport')}</Link>
                                                </div>
                                            </div>
                                            
                                        }
                                        </Translation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default Dashboard;
