/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChangeManagerInput = {|
  note: string,
  clientMutationId?: ?string,
|};
export type juristicManagerListMutationVariables = {|
  input: ChangeManagerInput
|};
export type juristicManagerListMutationResponse = {|
  +changeManager: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type juristicManagerListMutation = {|
  variables: juristicManagerListMutationVariables,
  response: juristicManagerListMutationResponse,
|};
*/


/*
mutation juristicManagerListMutation(
  $input: ChangeManagerInput!
) {
  changeManager(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeManagerPayload",
    "kind": "LinkedField",
    "name": "changeManager",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "juristicManagerListMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "juristicManagerListMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e1e4c5b8cb4ec0bd6f7a004cba285635",
    "id": null,
    "metadata": {},
    "name": "juristicManagerListMutation",
    "operationKind": "mutation",
    "text": "mutation juristicManagerListMutation(\n  $input: ChangeManagerInput!\n) {\n  changeManager(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7d8a06b242aa87e1f183a5d3017b8c98';

module.exports = node;
