import React, {Component} from 'react';
import {Translation} from "react-i18next";
import {Link} from "react-router-dom";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";

const query = graphql`
    query profileQuery {
        myUser {
            id username
            juristic {
                id firstName lastName image
            }
            tenant {
                id firstName lastName image
            }
        }
    }
`;

class Profile extends Component {
    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                cacheConfig={{use_cache: false}}
                variables={{}}
                render={({error, props}) => {
                    if (props) {
                        return (
                            <div className="profile d-none d-md-block">
                                {props?.myUser?.juristic?.image &&
                                <div className="profile-image">
                                    <img src={props?.myUser?.juristic?.image} alt="profile"/>
                                </div>
                                }
                                <div className="name text-capitalize">
                                    {props?.myUser?.juristic?.firstName} {props?.myUser?.juristic?.lastName}
                                </div>
                                <small className="pl-2"> ({props?.myUser?.username})</small>
                                <div className="logout">
                                    <Link to={"/login"}>
                                        <Translation>
                                            {
                                                t => <span>{t('headerMenu:logout')}</span>
                                            }
                                        </Translation>
                                    </Link>
                                </div>
                            </div>)
                    }
                }}
            />
        );
    }
}

export default Profile;
