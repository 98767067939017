import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { fetchQuery } from "react-relay";
import { format } from "date-fns";
import IndividualReceiptReportTable from "./individualReceiptReportTable"
import GrahpAPI from "./individualReceiptReportSummary"
import ProductServiceList from "../productServiceList";
import ContactReportList from "../contactReportList";
import Loading from '../../../../libs/loading';
import postApiUseing from '../../../../libs/postApiUseing';
import jwtDecode from 'jwt-decode'
import Swal from "sweetalert2";
import IndividualReceiptReportExports from "./individualReceiptReportExportsData";

const query = graphql`
  query individualReceiptReportQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $endDateOnDate: Date
    $search: String
    $customerType: String
    $productAndService: String
    $productAndServiceID: ID
    $contact: String
    $overdueDate: String
    $rangeOverdueDate: [Int]
  ) {
    invoiceViewer {
      allInvoice(
        approveOnly: true
        receivableOutstanding: true
        first: $first
        last: $last
        startDate: $startDate
        endDate: $endDate
        search: $search
        customerType: $customerType
        productAndService: $productAndService
        # transaction_ProductAndService_Id: $productAndServiceID
        contact: $contact
        order: "contact__residential__name"
        suborder: "individual_report"
        status_In: "active, overdue, partial_payment, paid"
        overdueDate: $overdueDate
        rangeOverdueDate: $rangeOverdueDate
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            docNumber
            issuedDate
            dueDate
            contact {
              id
              name
              firstName
              lastName
              refNumber
              typeOfContact
              residential {
                id
                name
              }
            }
            transaction(productAndService_Id: $productAndServiceID) {
              edges {
                node {
                  id
                  description
                  whtRate
                  total
                  productAndService {
                    id
                    productCode
                    name
                  }
                  chartOfAccount {
                    chartOfAccountCode
                  }
                  receiveTransaction(receive_Status_In: "paid" , receive_IssuedDate_Lte:$endDateOnDate) {
                    edges {
                      node {
                        amount
                        added
                        receive {
                          issuedDate

                        }
                      }
                    }
                  }
                  creditNoteTransaction(status: "paid",issuedDate_Lte:$endDateOnDate, receive_Isnull:true ) {
                    edges {
                      node {
                        price
                        issuedDate
                        receive {
                          id
                        }
                      }
                    }
                  }
                  creditNoteTransactionRecord(status: "paid",creditNote_IssuedDate_Lte:$endDateOnDate){
                      edges{
                          node{
                              id
                              price
                              creditNote{
                                  id
                                  docNumber
                                  issuedDate
                                  status
                              }
                              price
                              status
                          }
                      }
                  }
                  receiptDepositTransactionUsed(receiveTransaction_Isnull:true) {
                    edges {
                      node {
                        amount
                        receiveTransaction {
                          id

                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;

class ReceivableOutstandingReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);

    let user = jwtDecode(localStorage.getItem("token"))

    this.state.current_date = new Date();
    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.min_date = min_date;
    this.state.product_and_service = "";
    this.state.temp_product_and_service = "";
    this.state.customer_type = "";
    this.state.search = "";
    this.state.search_input = "";
    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.overdue_date = "";
    this.state.temp_overdue_date = "";
    this.state.range_overdue_date = [null, null];
    this.state.temp_range_overdue_date = [null, null]
    this.state.data = null
    this.state.loading = false
    this.state.summaryReceivableOutstanding = null
    this.state.summaryReceivableOutstandingLoading = false
    this.state.reportOutstandingReceivableSummary = null
    this.state.user = user
    this.state.summaryIndividualReceivableReport = "";
    this.state.firstInit = true
    this.state.checkFetchState = false;
    this.state.loadingBtn = false;

    this.state.productList = [];
    this.state.contactList = [];


    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.queryData = this.queryData.bind(this);
    this.queryDataSummary = this.queryDataSummary.bind(this);
    this.handleLoadingBtn = this.handleLoadingBtn.bind(this);
    this.onClickGetData = this.onClickGetData.bind(this);
  }

  componentDidMount() {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "receivableOutstandingReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (
        (prevState.first === this.state.first ||  prevState.last === this.state.last) &
        (
          prevState.end_date !== this.state.end_date ||
          prevState.search !== this.state.search ||
          prevState.customer_type !== this.state.customer_type ||
          prevState.product_and_service !== this.state.product_and_service ||
          prevState.contact !== this.state.contact ||
          prevState.overdue_date !== this.state.overdue_date ||
          prevState.range_overdue_date !== this.state.range_overdue_date ||
          prevState.dueDate !== this.state.dueDate
        )
      )
    ) {      
      this.queryData();
      this.queryDataSummary();
    }
    else if( (prevState.first !== this.state.first ||  prevState.last !== this.state.last ) &
        ( prevState.end_date === this.state.end_date &
          prevState.search === this.state.search &
          prevState.customer_type === this.state.customer_type &
          prevState.product_and_service === this.state.product_and_service &
          prevState.contact === this.state.contact &
          prevState.overdue_date === this.state.overdue_date &
          prevState.range_overdue_date === this.state.range_overdue_date &
          prevState.dueDate === this.state.dueDate
        )  ){
        this.queryData()
    } 
  }

  queryDataSummary = async () => {
    this.setState({summaryReceivableOutstandingLoading:true})
    const grahpAPIres = await GrahpAPI.receivableOutstandingReportSummary(this.state)
    this.setState({ 
      summaryReceivableOutstanding: grahpAPIres.invoiceViewer.summaryReceivableOutstanding,
      summaryIndividualReceivableReport : grahpAPIres.invoiceViewer.summaryIndividualReceivableReport,
      reportOutstandingReceivableSummary: grahpAPIres.reportOutstandingReceivableSummary,
      summaryReceivableOutstandingLoading:false,
     })
  }

  queryData = () => {
    this.setState({ 
      loading: true,
      firstInit: false,
      checkFetchState: true,
    })
    let variable = {
      first: this.state.first,
      last: this.state.last,
      startDate: this.state.end_date,
      endDate: this.state.end_date,
      endDateOnDate: format(this.state.end_date, "YYYY-MM-DD"),
      search: this.state.search,
      customerType: this.state.customer_type,
      productAndService: this.state.product_and_service,
      productAndServiceID: this.state.product_and_service,
      contact: this.state.contact,
      overdueDate: "",
      dueDate: this.state.end_date.toISOString().slice(0, 10),
    }
    fetchQuery(environment, query, variable).then(data => {      
      this.setState({ 
        data: data,
        loading: false,        
        checkFetchState : false,
       })
    })
    .catch(error => {
      Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
    })

  }

  handleChange(e) {
    if (e.target.name === "temp_overdue_date") {
      let min_date = null;
      let max_date = null;
      if (e.target.value === "1-30") {
        min_date = 1;
        max_date = 30;
      } else if (e.target.value === "31-60") {
        min_date = 31;
        max_date = 60;
      } else if (e.target.value === "61-90") {
        min_date = 61;
        max_date = 90;
      } else if (e.target.value === "91-120") {
        min_date = 91;
        max_date = 120;
      } else if (e.target.value === "121-150") {
        min_date = 121;
        max_date = 150;
      } else if (e.target.value === "151-180") {
        min_date = 151;
        max_date = 180;
      } else if (e.target.value === "over180") {
        min_date = 181;
        max_date = null;
      } else if (e.target.value === "not_overdue") {
        min_date = null;
        max_date = 0;
      }
      this.setState({
        temp_range_overdue_date: [min_date, max_date],
      })
    }
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      product_and_service: this.state.temp_product_and_service,
      contact: this.state.temp_contact,
      range_overdue_date: this.state.temp_range_overdue_date,
      overdue_date: this.state.temp_overdue_date,
      firstInit: false,
      checkFetchState : false,
    });
    super.goFirst()
  }

  handleLoadingBtn(loading){
    this.setState({
      loadingBtn : loading
    })
  }

  onClickGetData() {
    this.props.history.push(`/accounting/report/account-receivable/individualReceiptReport/PDF`,{ 
      state: {
        startDate: this.state.end_date,
        endDate: this.state.end_date,
        endDateOnDate: format(this.state.end_date, "YYYY-MM-DD"),
        search: this.state.search,
        customerType: this.state.customer_type,
        productAndService: this.state.product_and_service,
        productAndServiceID: this.state.product_and_service,
        contact: this.state.contact,
        overdueDate: "",
        dueDate: this.state.end_date.toISOString().slice(0, 10),
      }

    })
  }

  render() {    
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box">
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting/report/account-receivable">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {
                    (t) => <span>{t('individualReceipt:individualReceipt')}</span>
                    }
                  </Translation>
                </h3>
              </div> 
              {!this.state.firstInit && this.state.data && this.state.reportOutstandingReceivableSummary &&
              <div className="col fixRequestFast">
                <div className={`${this.state.checkFetchState ? "dropdown" : ''} printDoc`}>
                  {
                    this.state.loading || this.state.loadingBtn ?
                    (
                        <React.Fragment>
                        <button type="button" className="btn-primary-scale-outline floatRight">
                          <span className="spinner-border spinner-border-sm align-middle mr-2" />
                          <Translation>{t => t("meter:Preparing information")}</Translation>
                        </button>
                      </React.Fragment>
                    )
                      :
                      <React.Fragment>
                        <button type="button" className="btn-primary-scale-outline floatRight dropdown-toggle" data-toggle="dropdown">
                          <Translation>{t => t("PageList:Print")}</Translation>
                        </button>
                      </React.Fragment>
                  }
                  <div className={`dropdown-menu`}>
                    <a className="dropdown-item" target={"_blank"} onClick={this.onClickGetData}>PDF</a>      
                    <IndividualReceiptReportExports 
                        props={this.state.data}
                        asAtDate={this.state.end_date}
                        startDate={this.state.end_date}
                        endDate={this.state.end_date}
                        endDateOnDate={format(this.state.end_date, "YYYY-MM-DD")}
                        search={this.state.search}
                        customerType={this.state.customer_type}
                        productAndService={this.state.product_and_service}
                        productAndServiceID={this.state.product_and_service}
                        contact={this.state.contact}
                        overdueDate={this.state.overdue_date}
                        rangeOverdueDate={this.state.range_overdue_date}
                        dueDate={this.state.end_date.toISOString().slice(0, 10)}
                        handleLoadingBtn={this.handleLoadingBtn}
                    />              
                  </div>
                </div>
              </div>}            
            </div>

            <Translation>
                {
                (t) =>
                <div className="content-inner">
                <div className="row justify-content-end mb-2">
                    <div className="col">
                    <div className="input-group float-right w-auto ml-2">
                        <input
                        type="text"
                        className="form-control input-size"
                        placeholder={t('individualReceipt:search')}
                        value={this.state.search_input}
                        name="search"
                        onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                        }
                        onKeyDown={this.handleKeyDown}
                        />
                        <button
                        type="submit"
                        className="btn btn-primary form-control search-button"
                        name="search"
                        value={this.state.search_input}
                        onClick={(e) => this.handleSearch(e)}
                        >
                        ค้นหา
                        </button>
                    </div>

                    <div className="float-right ml-2">
                        <DatePickerAdapter
                            name="temp_end_date"
                            className="form-control"
                            selected={this.state.temp_end_date}
                            maxDate={this.state.current_date}
                            onChange={this.handleChange}
                            required={true}
                        />
                    </div>
                    </div>
                </div>

                <div className="row fade-up">
                    <div className="col">
                    <div className="card advance-search">
                        <div className="row">

                        <ContactReportList
                            handleChange={this.handleChange}
                            contact={this.state.temp_contact}
                        />

                        <ProductServiceList
                            handleChange={this.handleChange}
                            product_and_service={
                            this.state.temp_product_and_service
                            }
                        />

                        </div>
                    </div>
                    </div>
                </div>
                { !this.state.loading  ?
                (this.state.data && this.state.reportOutstandingReceivableSummary?
                    <React.Fragment>
                    <div className="row mt-3">
                        <div className="col-12">
                        <div className="card fade-up">
                            <div className="table-responsive">
                            <table
                                className="table table-hover"
                                style={{ fontSize: 12 }}
                            >
                                <thead className="thead-light">
                                <tr>
                                    <th rowSpan={2} className="align-middle">
                                    {t('individualReceipt:house')}
                                    </th>
                                    <th rowSpan={2} className="align-middle">
                                    {t('individualReceipt:name')}
                                    </th>
                                    <th
                                    rowSpan={2}
                                    className="align-middle text-center"
                                    >
                                    {t('individualReceipt:issueDate')}
                                    </th>
                                    <th
                                    rowSpan={2}
                                    className="align-middle text-center"
                                    >
                                    วันที่ครบกำหนด
                                    </th>
                                    <th
                                    rowSpan={2}
                                    width={130}
                                    className="align-middle text-center"
                                    >
                                    {t('individualReceipt:invoiceNumber')}                                    
                                    </th>
                                    <th rowSpan={2} className="align-middle">
                                    {t('individualReceipt:code')}
                                    </th>
                                    <th rowSpan={2} className="align-middle">
                                    {t('individualReceipt:description')}
                                    </th>
                                    <th
                                    rowSpan={2}
                                    className="align-middle text-center"
                                    >
                                    วันที่เกินกำหนด (วัน)
                                    </th>
                                    <th
                                    rowSpan={2}
                                    className="align-middle text-right"
                                    >                                    
                                    {t('individualReceipt:amount')}
                                    </th>
                                    <th
                                    rowSpan={2}
                                    className="align-middle text-right"
                                    >
                                    {t('individualReceipt:paid')}
                                    </th>

                                    <th
                                    rowSpan={2}
                                    className="align-middle text-right"
                                    >
                                    {t('individualReceipt:balance')}
                                    </th>
                                    {!this.state.contact &&<th
                                    rowSpan={2}
                                    className="align-middle text-right"
                                    >
                                    {t('individualReceipt:totalbalance')}                                    
                                    </th>}
                                </tr>
                                </thead>
                                <tbody>
                                <IndividualReceiptReportTable
                                    props={this.state.data}
                                    state={this.state}
                                    haveNoFilterContact={this.state.contact ? false : true}
                                />
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={this.state.data.invoiceViewer.allInvoice.totalCount}
                        />
                    </div>
                    </React.Fragment>
                      :
                    !this.state.firstInit && <Loading />
                )                
                  :
                (
                  !this.state.firstInit && <Loading />
                )}
              


                </div>
                }
            </Translation>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ReceivableOutstandingReport;
