/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type PrepaidDepositStatus = "ACTIVE" | "USE_ALL" | "USE_PARTIAL" | "VOID" | "%future added value";
export type prepaidDepositReportQueryVariables = {|
  first?: ?number,
  last?: ?number,
  start_date?: ?any,
  end_date?: ?any,
  status?: ?string,
  search?: ?string,
  contact?: ?string,
  payment_channel?: ?string,
|};
export type prepaidDepositReportQueryResponse = {|
  +prepaidDepositViewer: ?{|
    +allPrepaidDeposit: ?{|
      +totalCount: ?number,
      +pageInfo: {|
        +hasNextPage: boolean,
        +hasPreviousPage: boolean,
      |},
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +issuedDate: any,
          +status: PrepaidDepositStatus,
          +contact: {|
            +name: string,
            +firstName: string,
            +lastName: string,
            +refNumber: string,
            +typeOfContact: ContactTypeOfContact,
          |},
          +prepaidDepositTransaction: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +description: string,
                +total: number,
                +balance: number,
              |}
            |}>
          |},
          +prepaidDepositTransactionUsed: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +amount: ?number,
              |}
            |}>
          |},
          +cashTransaction: ?{|
            +totalCount: ?number
          |},
          +bankAccountTransaction: ?{|
            +totalCount: ?number
          |},
          +chequeTransaction: ?{|
            +totalCount: ?number
          |},
        |}
      |}>,
    |},
    +summaryPrepaidDeposit: ?number,
  |}
|};
export type prepaidDepositReportQuery = {|
  variables: prepaidDepositReportQueryVariables,
  response: prepaidDepositReportQueryResponse,
|};
*/


/*
query prepaidDepositReportQuery(
  $first: Int
  $last: Int
  $start_date: DateTime
  $end_date: DateTime
  $status: String
  $search: String
  $contact: String
  $payment_channel: String
) {
  prepaidDepositViewer {
    allPrepaidDeposit(first: $first, last: $last, startDate: $start_date, endDate: $end_date, status: $status, search: $search, report: true, contact: $contact, paymentChannel: $payment_channel, order: "doc_number") {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          docNumber
          issuedDate
          status
          contact {
            name
            firstName
            lastName
            refNumber
            typeOfContact
            id
          }
          prepaidDepositTransaction {
            edges {
              node {
                id
                description
                total
                balance
              }
            }
          }
          prepaidDepositTransactionUsed {
            edges {
              node {
                id
                amount
              }
            }
          }
          cashTransaction {
            totalCount
          }
          bankAccountTransaction {
            totalCount
          }
          chequeTransaction {
            totalCount
          }
        }
      }
    }
    summaryPrepaidDeposit(startDate: $start_date, endDate: $end_date, status: $status, search: $search, report: true, contact: $contact, paymentChannel: $payment_channel)
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payment_channel"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v8 = {
  "kind": "Variable",
  "name": "contact",
  "variableName": "contact"
},
v9 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "end_date"
},
v10 = {
  "kind": "Variable",
  "name": "paymentChannel",
  "variableName": "payment_channel"
},
v11 = {
  "kind": "Literal",
  "name": "report",
  "value": true
},
v12 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v13 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "start_date"
},
v14 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v15 = [
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": "doc_number"
  },
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "PrepaidDepositTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "prepaidDepositTransaction",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PrepaidDepositTransactionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PrepaidDepositTransactionNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v18/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "balance",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
  "kind": "LinkedField",
  "name": "prepaidDepositTransactionUsed",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PrepaidDepositTransactionUsedNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v18/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v29 = [
  (v16/*: any*/)
],
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "CashTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "cashTransaction",
  "plural": false,
  "selections": (v29/*: any*/),
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccountTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "bankAccountTransaction",
  "plural": false,
  "selections": (v29/*: any*/),
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "ChequeTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "chequeTransaction",
  "plural": false,
  "selections": (v29/*: any*/),
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": [
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "summaryPrepaidDeposit",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "prepaidDepositReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PrepaidDepositViewer",
        "kind": "LinkedField",
        "name": "prepaidDepositViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v15/*: any*/),
            "concreteType": "PrepaidDepositNodeConnection",
            "kind": "LinkedField",
            "name": "allPrepaidDeposit",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PrepaidDepositNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrepaidDepositNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v30/*: any*/),
                      (v31/*: any*/),
                      (v32/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v33/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "prepaidDepositReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PrepaidDepositViewer",
        "kind": "LinkedField",
        "name": "prepaidDepositViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v15/*: any*/),
            "concreteType": "PrepaidDepositNodeConnection",
            "kind": "LinkedField",
            "name": "allPrepaidDeposit",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PrepaidDepositNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrepaidDepositNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/),
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v30/*: any*/),
                      (v31/*: any*/),
                      (v32/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v33/*: any*/),
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f5869687eed426d6730ea260c9ad17f",
    "id": null,
    "metadata": {},
    "name": "prepaidDepositReportQuery",
    "operationKind": "query",
    "text": "query prepaidDepositReportQuery(\n  $first: Int\n  $last: Int\n  $start_date: DateTime\n  $end_date: DateTime\n  $status: String\n  $search: String\n  $contact: String\n  $payment_channel: String\n) {\n  prepaidDepositViewer {\n    allPrepaidDeposit(first: $first, last: $last, startDate: $start_date, endDate: $end_date, status: $status, search: $search, report: true, contact: $contact, paymentChannel: $payment_channel, order: \"doc_number\") {\n      totalCount\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n      }\n      edges {\n        node {\n          id\n          docNumber\n          issuedDate\n          status\n          contact {\n            name\n            firstName\n            lastName\n            refNumber\n            typeOfContact\n            id\n          }\n          prepaidDepositTransaction {\n            edges {\n              node {\n                id\n                description\n                total\n                balance\n              }\n            }\n          }\n          prepaidDepositTransactionUsed {\n            edges {\n              node {\n                id\n                amount\n              }\n            }\n          }\n          cashTransaction {\n            totalCount\n          }\n          bankAccountTransaction {\n            totalCount\n          }\n          chequeTransaction {\n            totalCount\n          }\n        }\n      }\n    }\n    summaryPrepaidDeposit(startDate: $start_date, endDate: $end_date, status: $status, search: $search, report: true, contact: $contact, paymentChannel: $payment_channel)\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '035525cbedcdaaebe1d09ed6e07744e2';

module.exports = node;
