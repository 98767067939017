import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import Wrapper from '../../components/wrapper';
import { Link } from 'react-router-dom';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import DatePickerAdapter from '../../libs/datePickerAdapter';
import "../../project/projectDashboard.scss"
import {commitMutation} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {fetchQuery} from "relay-runtime";
import Swal from 'sweetalert2';
import i18n from "i18next";
import { getYear, setYear } from 'date-fns';
import getDateTime from '../../libs/getDateTime';
import _ from 'lodash'
import i18next from 'i18next';


const mutation = graphql`
    mutation settingProjectManagerMutation ($input: CreateBoardInput!) {
    createBoard (input: $input) {
        ok
        message
    }
}

`

const query = graphql`
    query settingProjectManagerQuery ($status: String) {
        chooseTerm (status: $status) {
            edges{
                node{
                    id
                    note
                    startDate
                    expDate
                    numberOfPersons
                }
                    
            }
        }
}

`

class SettingProjectManager extends Component{

    constructor(props){
        super(props)
        this.state = {
            numberCommittees: "3",
            numberCommittees_select: "3",
            numberCommittees_custom: "",
            startDate: new Date(),
            expDate: setYear(new Date() ,getYear(new Date()) + 1),
            note: "",
            resetTerm : false,
            isFirstTerm : true,
            id : '',
            permissionEdit : _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_boardpersonal_edit'})  ?
            _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_boardpersonal_edit'}) : false
        }
    }

    handleChange = (e) => {
        if(e.target.name === 'startDate' || e.target.name === 'expDate' ){
            if(!this.state.isFirstTerm){
                Swal.fire({
                title: 'ยืนยันที่จะเปลี่ยนแปลงวันที่?',
                type: 'warning',
                text : 'การเปลี่ยนแปลงวันที่จะทำให้คณะกรรมการปัจจุบันทั้งหมดสิ้นสุดวาระ',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: i18next.t("Allaction:yes"),
                cancelButtonText: i18next.t("Allaction:cancel")
                }).then((result) => {
                    if(result.value){
                        this.setState({
                            [e.target.name]: e.target.value,
                            resetTerm : true
                        })
                    }
                })
            }else{
                this.setState({[e.target.name]: e.target.value})
            }
        }else{
            this.setState({[e.target.name]: e.target.value})
        }
    }

    componentWillMount(){
        fetchQuery(environment, query,{status:'current'}).then(data => {
            if(data.chooseTerm.edges.length > 0){
                this.setState({
                    numberCommittees : data.chooseTerm.edges[0].node.numberOfPersons,
                    numberCommittees_select : data.chooseTerm.edges[0].node.numberOfPersons > 9 ? "custom" : data.chooseTerm.edges[0].node.numberOfPersons,
                    numberCommittees_custom : data.chooseTerm.edges[0].node.numberOfPersons > 9 ? data.chooseTerm.edges[0].node.numberOfPersons : "",
                    startDate : data.chooseTerm.edges[0].node.startDate,
                    expDate : data.chooseTerm.edges[0].node.expDate,
                    note : data.chooseTerm.edges[0].node.note,
                    isFirstTerm : false,
                    id :  data.chooseTerm.edges[0].node.id,
                })
            }
            
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        let variables = {}

        let numberCommittees = 0
        if(this.state.numberCommittees_select === 'custom'){
            numberCommittees = this.state.numberCommittees_custom
        }else{
            numberCommittees = this.state.numberCommittees_select
        }

        if(this.state.resetTerm){
            variables = {
                input : {
                    id : '',
                    startDate : this.state.startDate,
                    expDate : this.state.expDate,
                    note : this.state.note,
                    numberOfPersons : parseInt(numberCommittees)
                }
            }

        }else{
            variables = {
                input : {
                    id : this.state.id,
                    startDate : this.state.startDate,
                    expDate : this.state.expDate,
                    note : this.state.note,
                    numberOfPersons : parseInt(numberCommittees)
                }
            }
        }
        

        commitMutation(
            environment,
            {
              mutation,
              variables,
              onCompleted: (response) => {
                this.setState({loading: false});
                if (response.createBoard.ok) {
                    Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                        this.setState({redirectToList: true})
                    });
                } else {
                    Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
                }
              },
              onError: (err) => {
                Swal.fire(i18n.t('project:Please try again'), '', 'warning');
              },
            },
        )
    }

    render(){
        return(
            <Wrapper>
                <Header />
                <Sidebar/>
                <div id="wrapper-content">
                    <Translation>
                        {t => 
                            <div className="container-fluid box" id="projectDashboard">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/setting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <span>{t('setting:Setting Juristic Committee')}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div className="content-inner" id="projectDashboard">
                                    <form onSubmit={this.onSubmit}  id="createSettingManager">
                                        <div className="row">
                                            <div className="col-4">
                                                <h4>{t('settingJuristicCommittee:Tenure of committree')}</h4>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-control-inline d-flex align-items-center">
                                                            <DatePickerAdapter 
                                                                selected={getDateTime(this.state.startDate)}
                                                                className="form-control"
                                                                name="startDate"
                                                                onChange={this.handleChange}
                                                                disable = {!this.state.permissionEdit}
                                                            />
                                                            <span className="mx-2 font-weight-bold"> {t('settingJuristicCommittee:To')}</span>
                                                            <DatePickerAdapter 
                                                                selected={getDateTime(this.state.expDate)}
                                                                className="form-control"
                                                                name="expDate"
                                                                onChange={this.handleChange}
                                                                disable = {!this.state.permissionEdit}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-8 d-flex">
                                                <span>{t('settingJuristicCommittee:No. of committree')}</span>
                                                <div className="mx-3">
                                                    <select 
                                                        style={{height: "20px", width: "65px", border: "1px solid #cecece", borderRadius: "2px"}} 
                                                        name="numberCommittees_select"
                                                        value={this.state.numberCommittees_select}
                                                        onChange={this.handleChange}
                                                        disabled={!this.state.permissionEdit}
                                                        >
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="custom">{t('settingJuristicCommittee:Specify')}</option>
                                                    </select>
                                                </div>
                                                {this.state.numberCommittees_select === 'custom' &&
                                                    <div className="mx-3">
                                                        <input type='number' style={{height: "20px", width: "45px", border: "1px solid #cecece", borderRadius: "2px"}} 
                                                        name="numberCommittees_custom" onChange={this.handleChange}
                                                        value={this.state.numberCommittees_custom}
                                                        />
                                                    </div>
                                                }
                                                <span>{t('settingJuristicCommittee:Person')}</span>
                                            </div>

                                        
                                            <div className="col-12 mt-4">
                                                <h5>{t('settingJuristicCommittee:Note')}</h5>
                                                <textarea 
                                                    className="form-control col-6" 
                                                    style={{height: "100px", maxHeight: "100px"}} 
                                                    name="note"
                                                    value={this.state.note}
                                                    onChange={this.handleChange}
                                                    required={true}
                                                    disabled={!this.state.permissionEdit}
                                                    />
                                            </div>

                                            <div className="col-12 mt-5">
                                                <div className="row">
                                                    {this.state.permissionEdit &&
                                                        <div className="col-3 d-flex justify-content-between">
                                                            <button type="submit" form="createSettingManager" className="btn btn-primary mr-3">{t('settingJuristicCommittee:Save data')}</button>
                                                            <Link to="/project">
                                                                <button type="button" className="btn btn-secondary" style={{width: "106px"}}>{t('settingJuristicCommittee:Cancel')}</button>
                                                            </Link>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    </Translation>
                </div>
            </Wrapper>
            
        );
    }


}
export default SettingProjectManager;