import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { decode } from 'base-64';
class SummarizeClaimVoid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingDetails: true,
            selectedBtn: "",
            status: "Y29uc2lkZXJfZmFpbA==" || decode(this.props.match.params.status),
            // new = bmV3
            // consider_fail = Y29uc2lkZXJfZmFpbA==
            // pending_approve = cGVuZGluZ19hcHByb3Zl
            // not_approve = bm90X2FwcHJvdmU=
            // approve = YXBwcm92ZQ==
            // finish = ZmluaXNo

            note:  "ยอดเคลมไม่ถึงขั้นต่ำมูลค่ารับผิดชอบส่วนแรก"
            
        } 
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
    }
    componentWillMount() {

    } 


    render() {
        let imageRadioTrue = "/images/iconAction/radioTrue.png"
        let imageRadioFalse = "/images/iconAction/radioFalse.png"

        return (
            <form onSubmit={this.onSubmit}>
                <div className='ResultClaim mt-10'>
                    <label className='headerLabel18 mt-2'>สาเหตุที่ปฏิเสธงานเคลม  : </label> <br />
                    <label className='label16 mt-2'>{this.state.note}</label> 
                </div> 

                <div className='text-right mt-5'>
                    <Link to={"/purchasing/claim/void"}>
                        <button className='btn btn-outline bg-1567B4 w222'>กลับสู่หน้าเคลม</button>
                    </Link>
                </div>


            </form>
        )
    }
}

export default SummarizeClaimVoid;
