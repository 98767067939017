import React from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import WrapperContent from '../../components/wrapper/wrapperContent'
import { Link } from 'react-router-dom'
import { Translation } from 'react-i18next'
import StockTopMenuNavigation from '../stockTopMenuNavigation'
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DatePickerNoti from '../../accounting/finance/depositInterest/datePickerNoti'
import SearchSelect from '../../libs/searchSelect';
import StockMovementReportTopMenu from './stockMovementReportTopMenu'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import ComponentPagination from "../../libs/componentPagination";
import Pagination from "../../libs/newPagination";
import _ from "lodash"
import { format } from 'date-fns'
import Loading from "../../libs/loading"
import numberWithComma from "../../libs/numberWithComma"
import StockMovementReportExcel from './documentStockMovementReport/stockMovementReportExcel'
import allInvoice from '../query/allInvoices'
import allOtherReceive from "../query/allOtherReceives"
import getNameResidential from "../../libs/getNameResidential"
import "./style/stockMovementReport.scss"
import i18next from 'i18next'
import BackButtonIcon from "../../components/BackBtn/indexBack";

// const selectUrgent = [
//   { node: { id :'', label : i18next.t("stockAndReport:All")}},
//   { node: { id :'sell', label : i18next.t("stockAndReport:Sell")}},
//   { node: { id :'buy', label : i18next.t("stockAndReport:Buy")}},
//   { node: { id :'conversion_balance', label : i18next.t("keyofProduct:Total brought forward")}},
//   { node: { id :'unit', label : i18next.t("stockAndReport:Rights")}},
//   { node: { id :'project', label : i18next.t("stockAndReport:Requisition")}},
// ]

const customStyles = {
  control: (style, { isDisabled }) => ({
    ...style,
    width: 184,
    height: 48,
    minHeight: 48,
    backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF'
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none'
  }),
};

const query = graphql`
  query stockMovementReportDetailQuery($productAndServiceId: String, $first: Int, $last: Int, $startDate: DateTime,$endDate: DateTime, $search: String, $listProduct: String){
    reportProduct(productAndServiceId: $productAndServiceId,type_In: "product", noNumber: false,  first: $first, last: $last){
      edges{
        node{
          id
          productCode
          name
          description
          chartOfAccount {
            id 
            name 
            nameEn 
            chartOfAccountCode
          }
        }
      }
    }
    reportProductTransactionExport: reportProductTransaction(productAndServiceId: $productAndServiceId, startDate: $startDate, endDate: $endDate, search: $search, listProduct: $listProduct){
      edges{
        node{
          id
          date
          list
          ref
          amount
          balance
          numberKey{
            edges{
              node{
                id
                noNumber
              }
            }
          }
          requisitionList{
            edges{
              node{
                id
                numberOfPieces
                numberOfBalance
                noNumber
                requisitionNote{
                  id
                  typeRequisition
                  typeNote
                  objective
                  docNumber
                  firstNameRecipient
                  lastNameRecipient
                  username
                  juristict{
                    id
                    firstName
                    lastName
                  }
                  contact{
                    id
                    name
                    firstName
                    lastName
                  }
                  requisitionNoteDocuments{
                    edges{
                      node{
                        id
                        fileName
                        fileUpload
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    reportProductTransaction(productAndServiceId: $productAndServiceId, startDate: $startDate, endDate: $endDate, search: $search, listProduct: $listProduct, first: $first, last: $last){
      edges{
        node{
          id
          date
          list
          ref
          amount
          balance
          numberKey{
            edges{
              node{
                id
                noNumber
              }
            }
          }
          requisitionList{
            edges{
              node{
                id
                numberOfPieces
                numberOfBalance
                noNumber
                requisitionNote{
                  id
                  typeRequisition
                  typeNote
                  objective
                  docNumber
                  firstNameRecipient
                  lastNameRecipient
                  username
                  juristict{
                    id
                    firstName
                    lastName
                  }
                  contact{
                    id
                    name
                    firstName
                    lastName
                  }
                  requisitionNoteDocuments{
                    edges{
                      node{
                        id
                        fileName
                        fileUpload
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
    selfProject{
      name
    }
    myUser {
      id 
      juristic {
        id firstName lastName
      }
    }
  }
`

export default class stockMovementReportDetail extends ComponentPagination {

  constructor(props) {
    super(props)

    this.state = {
      reportProductList: [],
      textSearch: "",
      startDate: new Date(),
      endDate: new Date(),
      reportProductTransactionList: [],
      reportProductTransactionExport: [],
      loading: false,
      urgentStatus: "",
      selfProject: {},
      myUser: "",
      totalCount: 0
    }
  }

  componentWillMount() {
    this.goFirst()
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.statusTable !== prevProps.match.params.statusTable || prevState.first !== this.state.first || prevState.last !== this.state.last) {
      this.getData()
    }
    if (this.state.urgentStatus !== prevState.urgentStatus) {
      this.getData(true)
    }
  }

  getData = (update) => {
    this.setState({ loading: true })
    fetchQuery(
      environment,
      query,
      {
        productAndServiceId: this.props.match.params.id,
        listProduct: update
          ? this.state.urgentStatus
          : this.props.match.params.statusTable === "all" ? "" : this.props.match.params.statusTable,
        first: this.state.first,
        last: this.state.last,
        search: this.state.textSearch,
        startDate: this.state.startDate,
        endDate: this.state.endDate
      }
    ).then(async (res) => {
      // table
      let newReportProductTransaction = []
      await this.setReportProductTransaction(newReportProductTransaction, res)


      // export
      let newReportProductTransactionExport = []
      await this.setReportProductTransactionExport(newReportProductTransactionExport, res)

      this.setState({
        reportProductList: res.reportProduct.edges[0],
        reportProductTransactionList: newReportProductTransaction,
        reportProductTransactionExport: newReportProductTransactionExport,
        loading: false,
        selfProject: res.selfProject,
        totalCount: res.reportProductTransaction.totalCount,
        myUser: res.myUser.juristic.firstName + " " + res.myUser.juristic.lastName
      })
    })
  }

  setReportProductTransaction = async (newReportProductTransaction, res) => {
    for (const item of res.reportProductTransaction.edges) {
      let contact = ""
      let unknow_contact = ""
      if (item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR") {
        contact = await this.getNewContact(contact, item)
        unknow_contact = await this.getUnknowContact(contact, item)

        newReportProductTransaction.push({
          node: {
            id: item.node.id,
            date: item.node.date,
            amount: item.node.amount,
            list: item.node.list,
            ref: item.node.ref,
            balance: item.node.balance,
            requisitionList: item.node.requisitionList,
            numberKey: item.node.numberKey,
            contact: contact,
            unknow_contact: unknow_contact
          }
        })
      } else {
        newReportProductTransaction.push({
          node: {
            id: item.node.id,
            date: item.node.date,
            amount: item.node.amount,
            list: item.node.list,
            ref: item.node.ref,
            balance: item.node.balance,
            requisitionList: item.node.requisitionList,
            numberKey: item.node.numberKey,
            contact: contact,
            unknow_contact: unknow_contact
          }
        })
      }
    }

    return newReportProductTransaction
  }

  setReportProductTransactionExport = async (newReportProductTransactionExport, res) => {
    for (const item of res.reportProductTransactionExport.edges) {
      let contact = ""
      let unknow_contact = ""
      if (item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR") {
        contact = await this.getNewContact(contact, item)
        unknow_contact = await this.getUnknowContact(contact, item)
        newReportProductTransactionExport.push({
          node: {
            id: item.node.id,
            date: item.node.date,
            amount: item.node.amount,
            list: item.node.list,
            ref: item.node.ref,
            balance: item.node.balance,
            requisitionList: item.node.requisitionList,
            numberKey: item.node.numberKey,
            contact: contact,
            unknow_contact: unknow_contact
          }
        })
      } else {
        newReportProductTransactionExport.push({
          node: {
            id: item.node.id,
            date: item.node.date,
            amount: item.node.amount,
            list: item.node.list,
            ref: item.node.ref,
            balance: item.node.balance,
            requisitionList: item.node.requisitionList,
            numberKey: item.node.numberKey,
            contact: contact,
            unknow_contact: unknow_contact
          }
        })
      }
    }

    return newReportProductTransactionExport
  }
  getUnknowContact = async (contact, item) => {
    // if(item.node.ref.substring(0, 2) === "IV"){
    //   await fetchQuery(environment, allInvoice, { search: item.node.ref }).then((res) => {
    //     contact = res.invoiceViewer.allInvoice.edges[0].node.contact
    //   })
    // }
    if (item.node.ref.substring(0, 2) === "OR") {
      await fetchQuery(environment, allOtherReceive, { search: item.node.ref }).then((res) => {
        contact = res.allOtherReceive.edges[0].node.unknownContact
      })
    }

    return contact;
  }

  getNewContact = async (contact, item) => {
    if (item.node.ref.substring(0, 2) === "IV") {
      await fetchQuery(environment, allInvoice, { search: item.node.ref }).then((res) => {
        contact = res.invoiceViewer.allInvoice.edges[0].node.contact
      })
    }
    if (item.node.ref.substring(0, 2) === "OR") {
      await fetchQuery(environment, allOtherReceive, { search: item.node.ref }).then((res) => {
        contact = res.allOtherReceive.edges[0].node.contact
      })
    }

    return contact;
  }

  getStatusStock = (textInput) => {
    let resultText = ""
    let bgColor = ""

    if (textInput === "ready") {
      resultText = i18next.t("stockCreateAndUpdate:Ready to use")
      bgColor = "statusGreen"
    } else if (textInput === "almost") {
      resultText = i18next.t("stockCreateAndUpdate:Nearly sold out")
      bgColor = "statusRed"
    } else if (textInput === "reachedPoint") {
      resultText = i18next.t("stockCreateAndUpdate:Ordering point")
      bgColor = "statusYellow"
    }

    return (<div className={`${bgColor} ml-4`} style={{ width: 180 }}>
      <span>{resultText}</span>
    </div>)
  }

  getStatusList = (inputStatusList) => {
    let result = ""

    switch (inputStatusList) {
      case "SELL":
        result = i18next.t("stockAndReport:Sell")
        break;
      case "BUY":
        result = i18next.t("stockAndReport:Buy")
        break;
      case "CONVERSION_BALANCE":
        result = i18next.t("keyofProduct:Total brought forward")
        break;
      case "UNIT":
        result = i18next.t("stockAndReport:Rights")
        break;
      case "PROJECT":
        result = i18next.t("stockAndReport:Requisition")
        break;

      default:
        break;
    }

    return <td>{result}</td>
  }

  handleChange = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.getData()
    }
  }

  handleSearch = () => {
    this.getData()
  }

  render() {


    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <StockTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box" id="stockMovementReportDetail" style={{ minHeight: '80vh' }}>
                <BackButtonIcon
                  LinkBack={this.props?.location?.state?.LinkBack ? this.props?.location?.state?.LinkBack : "/stockandsupplies/stockMovementReport/list"}
                  LinkText={i18next.t("stockAndReport:Stock and consumables report")}
                />
                <div className="content-inner">
                  <div className="row mt-5">
                    <div className="col-12 d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <h4>{i18next.t("stockAndReport:Product")} {this.state.reportProductList.node?.productCode} - {this.state.reportProductList.node?.name}</h4>
                        {this.getStatusStock(this.props.match.params.status)}
                      </div>
                      <div className="d-flex justify-content-end">
                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_producttransaction_print" }) &&
                          <Dropdown>
                            <DropdownToggle id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>
                              {i18next.t("stockAndReport:Print")}
                            </DropdownToggle>
                            <DropdownMenu>
                              <Dropdown.Item>
                                <Link
                                  to={{
                                    pathname: `/stockandsupplies/stockMovementReport/document`,
                                    state: {
                                      reportProductTransactionExport: this.state.reportProductTransactionExport,
                                      selfProject: this.state.selfProject,
                                      statusTable: this.props.match.params.statusTable,
                                      startDate: this.state.startDate,
                                      endDate: this.state.endDate,
                                      myUser: this.state.myUser
                                    }
                                  }}
                                  className="text-black"
                                >
                                  <p className="text-black">PDF</p>
                                </Link>
                              </Dropdown.Item>
                              <StockMovementReportExcel
                                reportProductTransactionExport={this.state.reportProductTransactionExport}
                                statusTable={this.props.match.params.statusTable}
                                selfProject={this.state.selfProject}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                              />
                            </DropdownMenu>
                          </Dropdown>
                        }
                      </div>
                    </div>

                    <div className="col-12 mt-4">
                      <span>{i18next.t("stockAndReport:Account type")} : {this.state.reportProductList.node?.chartOfAccount.chartOfAccountCode} - {this.state.reportProductList.node?.chartOfAccount.name}</span>
                    </div>
                    <div className="col-12">
                      <span>{i18next.t("stockAndReport:Description")} : {this.state.reportProductList.node?.description}</span>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <StockMovementReportTopMenu id={this.props.match.params.id} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-5 d-flex align-items-center">
                      <h5 className="mr-3">{i18next.t("stockAndReport:Show priority status")}:</h5>
                      <SearchSelect
                        onChange={this.handleChange}
                        value={this.state.urgentStatus}
                        name={"urgentStatus"}
                        placeholder={i18next.t("stockAndReport:List")}
                        queryObject={[
                          { node: { id: '', label: i18next.t("stockAndReport:All") } },
                          { node: { id: 'sell', label: i18next.t("stockAndReport:Sell") } },
                          { node: { id: 'buy', label: i18next.t("stockAndReport:Buy") } },
                          { node: { id: 'conversion_balance', label: i18next.t("keyofProduct:Total brought forward") } },
                          { node: { id: 'unit', label: i18next.t("stockAndReport:Rights") } },
                          { node: { id: 'project', label: i18next.t("stockAndReport:Requisition") } },
                        ]}
                        keyOfValue="id" require={true}
                        keyOfLabel="label"
                        style={customStyles}
                      />
                    </div>
                    <div className="col-12 col-lg-7 d-flex justify-content-end">
                      <div className="d-flex align-items-center">
                        <h5 className="m-0">{i18next.t("stockAndReport:Date")}:</h5>
                        <div
                          className="calendars-wrapper mx-4"
                          style={{ width: 160 }}
                        >
                          <DatePickerNoti
                            selected={this.state.startDate}
                            name='startDate'
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h5 className="m-0">{i18next.t("stockAndReport:to")}:</h5>
                        <div
                          className="calendars-wrapper mx-4"
                          style={{ width: 160 }}
                        >
                          <DatePickerNoti
                            selected={this.state.endDate}
                            name='endDate'
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="calendars-wrapper">
                        <form>
                          <input
                            type="text"
                            placeholder={i18next.t("stockAndReport:Search")}
                            name="textSearch"
                            value={this.state.textSearch}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            className="form-control inputSearch"
                          />
                        </form>
                        <div
                          className="calendars float-right"
                          style={{ paddingRight: 25 }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/search-outline.png"
                            }
                            alt="calendars"
                            style={{ width: "17.81px", height: "17.81px" }}
                            onClick={this.handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.loading ? <Loading />
                    : <React.Fragment>
                      <div className="row mt-4">
                        <div className="col">
                          <div className="fade-up">
                            <div className="table-responsive">
                              <table className="table table-hover">
                                <thead className="thead-light">
                                  <tr>
                                    <th>{i18next.t("stockAndReport:Date")}</th>
                                    {this.props.match.params.statusTable === "all" && <th>{i18next.t("stockAndReport:Type ")}</th>}
                                    <th>{i18next.t("stockAndReport:Document")}</th>
                                    <th>{i18next.t("stockAndReport:Requisition purpose")}</th>
                                    <th>{i18next.t("stockAndReport:Room No.")}</th>
                                    <th>{i18next.t("stockAndReport:Consignee name")}</th>
                                    <th>{i18next.t("stockAndReport:No.")}</th>
                                    <th>{i18next.t("stockAndReport:Requisition by")}</th>
                                    <th className="text-right">{i18next.t("stockAndReport:Quantity")}</th>
                                    <th className="text-right">{i18next.t("stockAndReport:Remaining amount")}</th>
                                    <th>{i18next.t("stockAndReport:Reference")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {_.map(this.state.reportProductTransactionList, (item, index) => {
                                    const amount = item.node.amount;

                                    // if (item.node.list === "SELL") {
                                    //   totalAmount -= amount;
                                    // }else if(item.node.list === "UNIT" || item.node.list === "PROJECT"){
                                    //   totalAmount -= amount;
                                    // } else {
                                    //   totalAmount += amount;
                                    // }

                                    return (
                                      <tr key={index}>
                                        <td>{format(item.node.date, "DD/MM/YYYY")}</td>
                                        {this.props.match.params.statusTable === "all" && this.getStatusList(item.node.list)}
                                        <td style={{ minWidth: 135 }}>{item.node.ref}</td>
                                        <td>
                                          {item.node.requisitionList?.edges[0]?.node.requisitionNote?.typeRequisition === "UNIT"
                                            ? "เบิกตามสิทธิ์"
                                            : item.node.requisitionList?.edges[0]?.node.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node.requisitionNote?.typeNote) === "tenant"
                                              ? "เบิกใช้งานลูกบ้าน"
                                              : item.node.requisitionList?.edges[0]?.node.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node.requisitionNote?.typeNote) === "juristic"
                                                ? "เบิกใช้งานส่วนกลาง"
                                                : '-'
                                          }
                                        </td>
                                        <td>
                                          {(item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR")
                                            ? item.node.contact?.name
                                            : item.node.requisitionList?.edges[0]?.node.requisitionNote?.contact?.name || "-"
                                          }
                                        </td>
                                        <td>
                                          {item.node.requisitionList.edges[0]?.node?.requisitionNote?.typeRequisition === "UNIT" && (item.node.requisitionList?.edges[0]?.node?.requisitionNote?.firstNameRecipient || item.node.requisitionList?.edges[0]?.node?.requisitionNote?.lastNameRecipient)
                                            ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.firstNameRecipient || "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.lastNameRecipient || "")
                                            : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "tenant"
                                              ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.firstName || "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.lastName || "")
                                              : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "juristic"
                                                ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName || "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName || "")
                                                : (item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR")
                                                  ? item.node?.contact ? getNameResidential(item.node?.contact?.firstName || "", item.node?.contact?.lastName || "") : item.node?.unknow_contact
                                                  : "-"
                                          }
                                        </td>
                                        {/* <td>{item.node.requisitionList?.edges[0]?.node.noNumber || "-"}</td> */}

                                        <td>{item.node.list === 'CONVERSION_BALANCE' ? '-' : item.node.numberKey?.edges[0]?.node.noNumber || "-"}</td>


                                        <td>
                                          {(item.node.requisitionList?.edges[0]?.node.requisitionNote?.juristict?.firstName || item.node.requisitionList?.edges[0]?.node.requisitionNote?.juristict?.lastName)
                                            ? `${item.node.requisitionList?.edges[0]?.node.requisitionNote?.juristict?.firstName} ${item.node.requisitionList?.edges[0]?.node.requisitionNote?.juristict?.lastName}`
                                            : "-"
                                          }
                                        </td>
                                        <td className="text-right">
                                          {((item.node.list === "SELL" || item.node.list === "UNIT" || item.node.list === "PROJECT") ? "-" : "") +
                                            numberWithComma(amount)}
                                        </td>
                                        {/* <td className="text-right">{numberWithComma(totalAmount)}</td> */}
                                        <td className="text-right">{numberWithComma(item.node.balance)}</td>
                                        <td>
                                          {item.node.requisitionList?.edges[0]?.node.requisitionNote?.requisitionNoteDocuments?.edges.length > 0
                                            ?
                                            _.map(item.node.requisitionList?.edges[0]?.node.requisitionNote?.requisitionNoteDocuments.edges, (itemImg, indexItemImg) => {
                                              let fileName = itemImg.node.fileName
                                              let typeFileName = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length)

                                              return (
                                                <span key={indexItemImg} className="d-flex">
                                                  {typeFileName === "pdf"
                                                    ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} alt="pdf" />
                                                    : typeFileName === "png"
                                                      ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pngIcon.png"} alt="png" />
                                                      : (typeFileName === "jpeg" || typeFileName === "jpg") && <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/jpgIcon.png"} alt="jpeg" />
                                                  }
                                                  <Link
                                                    to="route"
                                                    onClick={(event) => {
                                                      event.preventDefault();
                                                      window.open(
                                                        typeof itemImg.node.fileUpload === "string"
                                                          ? itemImg.node.fileUpload
                                                          : URL.createObjectURL(itemImg.node.fileUpload)
                                                      )
                                                    }}
                                                    target="blank">
                                                    {fileName.length >= 15 ? fileName.substr(0, 15) : fileName}
                                                  </Link>
                                                  <br />
                                                </span>
                                              )
                                            })
                                            : "-"
                                          }
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={this.state.totalCount}
                        />
                      </div>
                    </React.Fragment>
                  }
                </div>
              </div>
            }
          </Translation>


        </WrapperContent>
      </Wrapper>
    )
  }
}
