/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatePettyCashPaymentStatusInput = {|
  status: string,
  voidRemark?: ?string,
  clientMutationId?: ?string,
|};
export type pettyCashPaymentListTableMutationVariables = {|
  input: UpdatePettyCashPaymentStatusInput
|};
export type pettyCashPaymentListTableMutationResponse = {|
  +updatePettyCashPaymentStatus: ?{|
    +warningText: ?string,
    +pettyCashPayment: ?{|
      +id: string
    |},
  |}
|};
export type pettyCashPaymentListTableMutation = {|
  variables: pettyCashPaymentListTableMutationVariables,
  response: pettyCashPaymentListTableMutationResponse,
|};
*/


/*
mutation pettyCashPaymentListTableMutation(
  $input: UpdatePettyCashPaymentStatusInput!
) {
  updatePettyCashPaymentStatus(input: $input) {
    warningText
    pettyCashPayment {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePettyCashPaymentStatusPayload",
    "kind": "LinkedField",
    "name": "updatePettyCashPaymentStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PettyCashPaymentNode",
        "kind": "LinkedField",
        "name": "pettyCashPayment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pettyCashPaymentListTableMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pettyCashPaymentListTableMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a5683ecfc7959c980e0763359d3ca981",
    "id": null,
    "metadata": {},
    "name": "pettyCashPaymentListTableMutation",
    "operationKind": "mutation",
    "text": "mutation pettyCashPaymentListTableMutation(\n  $input: UpdatePettyCashPaymentStatusInput!\n) {\n  updatePettyCashPaymentStatus(input: $input) {\n    warningText\n    pettyCashPayment {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4bdbea3b19a1c6a567a2d297a33dc5a2';

module.exports = node;
