/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAdvanceInput = {|
  issuedDate: any,
  dueDate?: ?any,
  description: string,
  withdrawer: string,
  payChannel?: ?any,
  clientMutationId?: ?string,
|};
export type advanceCreateAndViewFormMutationVariables = {|
  input: CreateAdvanceInput
|};
export type advanceCreateAndViewFormMutationResponse = {|
  +createAdvance: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type advanceCreateAndViewFormMutation = {|
  variables: advanceCreateAndViewFormMutationVariables,
  response: advanceCreateAndViewFormMutationResponse,
|};
*/


/*
mutation advanceCreateAndViewFormMutation(
  $input: CreateAdvanceInput!
) {
  createAdvance(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAdvancePayload",
    "kind": "LinkedField",
    "name": "createAdvance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "advanceCreateAndViewFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "advanceCreateAndViewFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7bad7eadd0df20ec1d609face4485972",
    "id": null,
    "metadata": {},
    "name": "advanceCreateAndViewFormMutation",
    "operationKind": "mutation",
    "text": "mutation advanceCreateAndViewFormMutation(\n  $input: CreateAdvanceInput!\n) {\n  createAdvance(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '802b16ea43a4a2266cc6213966a74cfe';

module.exports = node;
