import React, {Component} from 'react';
import numberWithComma from "../../../libs/numberWithComma";

import "./css/invoiceSummary.scss";
import SearchSelect from "../../../libs/searchSelect";
import { graphql } from "babel-plugin-relay/macro";

import _ from "lodash";
import { fetchQuery } from "react-relay";
import environment from "../../../env/environment";
import {Translation} from "react-i18next";

const query = graphql`
    query invoiceSummaryQuery($type:String) {

        selfProject{
            keyProjectQr
        }
        quickMassageViewer(typeNote:$type){
            edges{
              node{
                id
                massageNote
                typeNote
                creator
              }
            }
        }
    }`;

const $ = window.jQuery;
class InvoiceSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wht_percent: 0,
            vat: 0,
            other: "",
            loading: false,
            reQuery: false,
            note_list: [],
            note_other:'',
            isOther: false,
            note_id:'',
            keyProjectQr: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this)
        this.notInvoice = this.notInvoice.bind(this)
        this.isLimitDescription = this.isLimitDescription.bind(this)
    }

    queryChartOfAccount() {
        fetchQuery(environment, query, {
            type: 'invoice'
        }).then(data => {
            let data_clone = _.cloneDeep(data);
            let note_list = []
            if (data_clone.quickMassageViewer.edges.length > 0) {
                data_clone.quickMassageViewer.edges.forEach((data) => {
                    note_list.push({node : {creator : data.node.creator , id : data.node.id , name : data.node.massageNote , typeNote: data.node.typeNote}})
                })
            }
            note_list.push({node : {creator : " ", id : "other" , name : "อื่นๆ" , typeNote: "INVOICE"}})
            this.setState({note_list : note_list})
            if(data_clone.selfProject.keyProjectQr !== null){
                this.setState({
                    keyProjectQr : true
                })
            }
            let max_rows = 6;
            if(this.state.keyProjectQr && this.props.category === 'invoice'){
                max_rows = 2;
            }
            $(document).ready(function () {
                $('textarea[data-limit-rows=true]')
                    .on('keypress', function (event) {
                        var textarea = $(this),
                            text = textarea.val(),
                            numberOfLines = (text.match(/\n/g) || []).length + 1,
                            maxRows =  max_rows
                
                        if (event.which === 13 && numberOfLines === maxRows ) {
                        return false;
                        }
                    });
            });
        })
    }

    roundToTwo(num) {    
        return +(Math.round(num + "e+2")  + "e-2");
    }
    componentWillMount() {
        this.calSummary();
        this.queryChartOfAccount();
        this.props.remark && this.state.note_list.forEach((value) =>{
            if(value.node.name === this.props.remark){
                this.setState({note_id : value.node.id})
            }else{
                this.setState({note_id: "other"})
            }
        })
        

        //check หมายเหตุในส่วนที่ไม่ใช่ invoice
        this.notInvoice();
    }

    componentDidMount(){
        let max_rows = 6;
        if(this.state.keyProjectQr && this.props.category === 'invoice'){
            max_rows = 2;
        }
        $(document).ready(function () {
            $('textarea[data-limit-rows=true]')
                .on('keypress', function (event) {
                    var textarea = $(this),
                        text = textarea.val(),
                        numberOfLines = (text.match(/\n/g) || []).length + 1,
                        maxRows =  max_rows
            
                    if (event.which === 13 && numberOfLines === maxRows ) {
                    return false;
                    }
                });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.transaction_list !== prevProps.transaction_list || this.props.receiveDeposit !== prevProps.receiveDeposit) {
            this.calSummary()
        }
        let max_rows = 6;
        if(this.state.keyProjectQr && this.props.category === 'invoice'){
            max_rows = 2;
        }
        $(document).ready(function () {
            $('textarea[data-limit-rows=true]')
                .on('keypress', function (event) {
                    var textarea = $(this),
                        text = textarea.val(),
                        numberOfLines = (text.match(/\n/g) || []).length + 1,
                        maxRows =  max_rows
            
                    if (event.which === 13 && numberOfLines === maxRows ) {
                    return false;
                    }
                });
        });
    }

    calSummary() {
        // set vat
        if (this.props.transaction_list.length > 0) {
            this.setState({vat: this.props.transaction_list[0].vat || 0})
        }
        let sumDiscount = 0;
        let sumPrice = 0;

        let total = 0;
        let sumVat = 0;
        let sumPreTax = 0;
        let sumWhtRate = 0;
        let totalWhtRate = 0;

        // ยอด *100 /107

        this.props.transaction_list.forEach((transactions) => {
            sumDiscount = parseFloat(sumDiscount) + parseFloat(transactions.discount || 0);
            sumPrice = parseFloat(sumPrice) + parseFloat(transactions.preTaxAmount || 0);

            let preTaxAmount = transactions.preTaxAmount;

            sumVat = parseFloat(sumVat) + parseFloat(preTaxAmount || 0) * (transactions.vat / 100 || 0);
            sumPreTax = parseFloat(sumPreTax) + parseFloat(preTaxAmount || 0);
            sumWhtRate = parseFloat(sumWhtRate) + parseFloat(transactions.whtRate || 0);
        });

        sumPrice = parseFloat(sumPrice) + parseFloat(sumDiscount)

        if (this.props.other_expense) {
            total = parseFloat(sumVat) + parseFloat(sumPreTax);
        }
        else {
            total = parseFloat(sumVat) + parseFloat(sumPreTax) - (parseFloat(this.props.receiveDeposit) * (1 + ((this.state.vat) / 100)));
        }

        totalWhtRate = parseFloat(this.roundToTwo(total))- parseFloat(this.roundToTwo(sumWhtRate));
        // totalWhtRate = parseFloat(total).toFixed(2) - parseFloat(sumWhtRate).toFixed(2);

        let transaction_use_wht = this.props.transaction_list.find((transaction) => {
            if (transaction.whtRatePercent && transaction.whtRatePercent !== "0") {
                return transaction
            } else {
                return false
            }
        });

        if (transaction_use_wht) {
            this.setState({wht_percent: transaction_use_wht.whtRatePercent});
        } else {
            this.setState({wht_percent: 0})
        }

        this.setState({
            sum_discount: sumDiscount,
            sum_price: sumPrice,
            sum_pre_tax: sumPreTax
        }, () => {

            let event = {
                target: {
                    name: 'total',
                    value: this.calTotalAmount(),
                    dataset: {id: '', key: ''},
                }
            };
            if (this.props.handleInputChange) {
                this.props.handleInputChange(event);
            }
        });

        let total_wht_rate = {
            target: {
                name: 'total_wht_rate',
                value: this.roundToTwo(totalWhtRate) < 0 ? 0 : this.roundToTwo(totalWhtRate),
                dataset: {id: '', key: ''},
            }
        };

        if (this.props.handleInputChange) {
            this.props.handleInputChange(total_wht_rate);
        }
    }

    getSumAmount() {
        // let amount = (parseFloat(this.state.sum_pre_tax) - parseFloat(this.state.sum_discount)) - parseFloat(this.props.receiveDeposit || 0);
        let amount = parseFloat(this.state.sum_pre_tax) - parseFloat(this.props.receiveDeposit || 0);
        // console.log(parseFloat(this.state.sum_pre_tax) ,"-", parseFloat(this.props.receiveDeposit || 0))
        return amount > 0 ? amount : 0
    }

    getSumVat() {
        // return (this.getSumAmount() * parseFloat(this.state.vat) / 100)
        if(this.props.refNumber_oe === "OE-2020110014") {
            return parseFloat(this.state.sum_price) - this.getSumAmount()
        }
        else{
            if(this.props.refNumber_oe === "OE-2020120009")return (this.getSumAmount() * parseFloat(this.state.vat) / 100) + 0.01
            else return (this.getSumAmount() * parseFloat(this.state.vat) / 100)
        }
    }

    getWht() {
        let whtRate = 0;
        if(this.props.transaction_list.length > 1){
            this.props.transaction_list.forEach((result,index) => {
                let whtPercent = result.whtRatePercent ? result.whtRatePercent : 0;
                whtRate = whtRate + result.preTaxAmount * ((whtPercent || 0) / 100);
            })
        }else{
            let whtPercent = this.props.transaction_list.length > 0 ? this.props.transaction_list[0].whtRatePercent : 0;
            whtRate = this.getSumAmount() * ((whtPercent || 0) / 100);
        }
        return parseFloat(whtRate)
    }

    getPrice() {
        let amount = parseFloat(this.state.sum_price);
        return amount > 0 ? amount : 0
    }

    calTotalAmount() {
        let amount = 0;
        if (this.props.category === 'other_receiver'){
            amount = parseFloat(this.roundToTwo(this.getPrice()))
        } else {
            amount = parseFloat(this.roundToTwo(this.getSumAmount())) + parseFloat(this.roundToTwo(this.getSumVat())) - parseFloat(this.roundToTwo(this.getWht()));
        }
        return amount > 0 ? amount : 0
    }

    getVat(price, vat) {
        let amount = 0;
        if (this.props.taxType === 'INCLUDE_TAX') {
            amount = parseFloat(price) * ((vat || 0) / 100)
        }
        return parseFloat(this.roundToTwo(amount))
    }

    handleInputChange(e) {
        if (e.target.name === 'noteListInvoice') {
            if(e.target.value !== "other"){
                this.state.note_list.forEach((value) => {
                    if(value.node.id === e.target.value){
                        this.setState({
                            note_other : value.node.name,
                            isOther : false
                        })
                        
                        this.props.getRemarkCallback(value.node.name)
                    }
                })

            }else{
                this.setState({
                    isOther : true,
                })
                this.props.getRemarkCallback("")
            }
        }
    }

    notInvoice(){
        if(this.props.category !== 'invoice'){
            this.setState({isOther:true})
        }
    }

    isLimitDescription(category){
        if(category === "invoice" || category ==="other_receiver"){
          return true;
        }else{
          return false;
        }
      }

    render() {
        return (
            <div className="row mt-5" id="invoice-summary">
                <div className="col-8">

                    {
                     this.props.carTransaction?.edges?.length >= 1 ? 
                          <div className="pt-5">
                               <div className="row">
                                    <div className="col-1">
                                       <div style={{width:'300px'}}>
                                        <h5>เอกสารอ้างอิง: </h5> 
                                       </div>
                                    </div>
                                  <div className="col-5" style={{padding:'0',marginLeft:'48px'}}>
                                   {
                                    _.map( this.props.carTransaction?.edges, (item, index) => {
                                        return(
                                            <span className='pl-2'>{item.node.codeNumber}</span>
                                        )
                                    })}</div>
                             </div>
                              <p className="">จำนวนเอกสารที่อ้างอิงทั้งหมด: {this.props.carTransaction?.edges.length} รายการ</p>
                              <hr/>
                          </div>
                         : ''
                    }
                    <label htmlFor="note"><Translation>{t => <strong>{t('createUpdateForm:note')}</strong>}</Translation></label>
                    {(this.props.mode === 'edit' || this.props.mode === 'create') &&
                        <SearchSelect
                            onChange={this.handleInputChange}
                            value = {this.state.note_id}
                            name="noteListInvoice" 
                            placeholder = 'เลือกหมายเหตุ'
                            queryObject={this.state.note_list}
                            keyOfValue="id"
                            keyOfLabel="name"
                            required={false}
                            pre_wrap={true} 
                            />
                    }

                       

                    {(this.state.isOther || (this.props.mode === 'edit' && this.props.remark) || (this.props.mode !== 'edit' && this.props.mode !== 'create')) &&
                        <div className = {this.props.category === 'other_receiver' ? "" : "mt-3"}>
                            {this.isLimitDescription(this.props.category) &&<Translation>{t => <p className="text-danger" style={{fontSize:'11px' ,marginBottom:'8px'}}> *{t('createUpdateForm:Enter message not more than')} {(this.props.category === 'invoice' && this.state.keyProjectQr) ? "200" : "800"} {t('createUpdateCreate:characters')} {this.props.remark.length}/{(this.props.category === 'invoice' && this.state.keyProjectQr) ? "200" : "800"} </p>}</Translation>}
                            <textarea   className="form-control" rows="5" name="remark"
                                        value={this.props.remark} 
                                        disabled={this.props.remark_edit || (this.state.isOther === false)}
                                        onChange={(event) => this.props.handleInputChange(event)}
                                        required={!(this.props.category === 'other_receiver') && !(this.props.category === 'purchase') && !(this.props.category === 'other_expense')}
                                        data-limit-rows={this.isLimitDescription(this.props.category) && "true"}
                                        maxLength={this.isLimitDescription(this.props.category) ? (this.props.category === 'invoice' && this.state.keyProjectQr) ? 200 : 800 : ""}
                            />
                        </div>
                    }
                </div>
                {/* {(this.props.preinvoice === false) && */}
                {(this.props.preinvoice !== true) &&
                    <div className="col-4">
                        <div className="box-summary">
                            <div className="row">
                                <div className="col">
                                    <Translation>{t => <p>{t('createUpdateForm:amount_price')}</p>}</Translation>
                                </div>
                                <div className="col text-right">
                                    {/*<p>{numberWithComma(this.state.sum_pre_tax, '0.00')} บาท</p>*/}
                                    {/* <p>{numberWithComma(this.state.sum_price, '0.00')} บาท</p> */}
                                    {/* { this.props.category ?  */}
                                        <Translation>{t => <p>{numberWithComma(this.state.sum_price, '0.00')} {t('createUpdateForm:baht')}</p> }</Translation>
                                        {/* :  */}
                                        {/* <p>{numberWithComma(this.state.sum_pre_tax, '0.00')} บาท</p> */}
                                    {/* } */}
                                </div>
                            </div>

                            {
                                parseFloat(this.state.sum_discount) !== 0 &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col">
                                            <p>หักส่วนลด</p>
                                        </div>
                                        <div className="col text-right">
                                            <p>{numberWithComma(this.state.sum_discount, '0.00')} บาท</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>จำนวนเงินหลังหักส่วนลด</p>
                                        </div>
                                        <div className="col text-right">
                                            {/*<p>{numberWithComma((parseFloat(this.state.sum_pre_tax) - parseFloat(this.state.sum_discount)), '0.00')} บาท</p>*/}
                                            <Translation>{t=><p>{numberWithComma((parseFloat(this.state.sum_price) - parseFloat(this.state.sum_discount)), '0.00')} {t('createUpdateForm:baht')}</p>}</Translation>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }

                            <div className="row">
                                <div className="col">
                                    {
                                        this.props.category === "purchase" ?
                                            <p>หักจ่ายชำระล่วงหน้า</p>
                                            :
                                            this.props.category === "prepaid_deposit" || this.props.category === 'other_receiver' ?
                                                <React.Fragment/>
                                                :
                                                <Translation>{t => <p>{t('createUpdateForm:Deduct receipt deposit transaction')}</p> }</Translation>
                                    }
                                </div>

                                <div className="col text-right">
                                    {
                                        this.props.category !== "prepaid_deposit" && this.props.category !== 'other_receiver' &&
                                        <p>{(numberWithComma(this.props.receiveDeposit + this.getVat(this.props.receiveDeposit, this.state.vat) || 0, '0.00'))} <Translation>{t=>t('createUpdateForm:baht')}</Translation></p>
                                    }
                                    {
                                        this.props.category === 'other_receiver' &&
                                        <p></p>
                                    }
                                </div>
                            </div>

                            {
                                (this.props.taxType !== 'NO_TAX' || parseFloat(this.state.wht_percent) !== 0) &&
                                <div className="row">
                                    <div className="col">
                                        {
                                            this.props.category === "purchase" ?
                                                <p>จำนวนเงินหลังหักจ่ายชำระล่วงหน้า</p>
                                            : <p>จำนวนเงินหลังหักรับชำระล่วงหน้า</p>
                                        }

                                    </div>
                                    <div className="col text-right">
                                        <p>{numberWithComma(this.getSumAmount(), '0.00')} บาท</p>
                                    </div>
                                </div>

                            }

                            {/*============== have vat ===============*/}
                            {(this.props.taxType !== 'NO_TAX') &&
                            <React.Fragment>
                                <div className="row">
                                    <div className="col">
                                        <p>ภาษีมูลค่าเพิ่ม</p>
                                    </div>
                                    <div className="col text-right">
                                        <p>{numberWithComma(this.getSumVat(), '0.00')} บาท</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p>จำนวนเงินรวม</p>
                                    </div>
                                    <div className="col text-right">
                                        <p>{numberWithComma(parseFloat(this.getSumAmount()) + parseFloat(this.getSumVat()), '0.00')} บาท</p>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                            {/*============== have vat ===============*/}

                            {
                                this.state.wht_percent !== 0 &&
                                <div className="row">
                                    <div className="col">
                                        <Translation>{t => <p>{t('createUpdateForm:withholding_tax')}</p>}</Translation>
                                    </div>
                                    <div className="col text-right">
                                        <Translation>{t => <p>{numberWithComma(this.getWht(), '0.00')} {t('createUpdateForm:baht')}</p>}</Translation>
                                    </div>
                                </div>
                            }

                            <div className="row">
                                <div className="col-12">
                                    <hr/>
                                </div>
                                <div className="col">
                                    <Translation>{t => <strong>{t('createUpdateForm:total_amount')}</strong>}</Translation>
                                </div>
                                <div className="col text-right">
                                    <Translation>
                                        {t => 
                                            <strong className="red">
                                                {this.props.category === 'other_receiver'?
                                                numberWithComma(this.calTotalAmount()-this.state.sum_discount, '0.00'):
                                                numberWithComma(this.calTotalAmount(), '0.00')}
                                                {t('createUpdateForm:baht')}
                                            </strong>
                                        }
                                    </Translation>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default InvoiceSummary;