import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import _ from "lodash"
import CollectionLettersListTable from "./collectionLettersListTable";
import "./collection.scss";
import Swal from 'sweetalert2';
import getForm from '../../setting/settingCollectionLetter/formOneQuery'
import i18next from 'i18next';



class CollectionLetterList extends Component {
    constructor(props){
        super(props);
        this.state = {
            status : this.props.match.params.status,
            statusCollection : '',
            numberOfTimes : null,
            isNullSettingForm: false , //เช็คว่ามีบันทึก form แล้วหรือยัง -> true = no , false = yes
        }
    }

    componentDidUpdate(){
        if(this.props.match.params.status !== this.state.status){
            this.setState({status : this.props.match.params.status} , () => {
                this.switchStatus()
            })   
        }
    }

    async componentDidMount(){
        this.switchStatus()
        for (const num of  [1,2,3,4]){
            let form = await getForm(num)
            if(form.allSettingFormDebit?.edges.length === 0){
             this.setState({
                 isNullSettingForm : true
             })
            }
        }
    }

    switchStatus = () => {
        if(this.state.status === 'all'){
            this.setState({
                statusCollection : ['active','paid','void'],
                numberOfTimes : null
            })
        }else if(this.state.status === 'draft' || this.state.status === 'paid' || this.state.status === 'void'){
            this.setState({
                statusCollection : [this.state.status],
                numberOfTimes : null
            })
        }else{
            this.setState({
                statusCollection : ['active'],
                numberOfTimes : parseInt(this.state.status)
            })
        }   
    }

    onAlert = () => {
        Swal.fire(i18next.t("collectionLettersList:Incomplete Form Configuration"), i18next.t("collectionLettersList:Setup Document Format Before Proceeding"), 'warning')
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="collection-letter">

                        <div className="row justify-content-between">
                            <div className="col">
                                    <Translation>
                                        {
                                            t => 
                                            <h5 className="mb-4">
                                                <Link to="/accounting">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back"/>
                                                </Link>
                                                    {t('letter:Collection Letters')+" "+"(CLL)"}
                                                </h5>
                                        }
                                    </Translation>
                                
                            </div>
                            <div className="col">
                                
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_settingautomaticdebit' })&&
                                    <Link to="/setting/collection/settings">
                                    {/* <Link to={'/accounting/collection/first'}> */}
                                        <button type="button" className="btn btn-setting float-right">
                                            <img src={process.env.PUBLIC_URL + '/images/icons/setting.png'} alt="edit"/>
                                        </button>
                                    </Link>
                                }
                                
                                
                                {this.state.isNullSettingForm ? 
                                    _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_create' }) &&
                                    <button type="button" className="btn btn-create add float-right mr-2" onClick={() => this.onAlert()}>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                        <Translation>
                                            {
                                                t => <span>{t('letter:Add Collection Letters')}</span>
                                            }
                                        </Translation>
                                    </button>
                                    :
                                    _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_create' }) &&
                                    <Link to={{pathname: "/accounting/collection_letters/form/input", state: {  LinkBack: window.location.pathname  }}}>
                                        <button type="button" className="btn btn-create add float-right mr-2">
                                            <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                            <Translation>
                                                {
                                                    t => <span>{t('letter:Add Collection Letters')}</span>
                                                }
                                            </Translation>
                                        </button>
                                    </Link>
                                }
                                
                            </div>
                            
                        </div>
                        <div className="content-inner">
                            <CollectionLettersListTable
                                status={ this.state.status} numberOfTimes={this.state.numberOfTimes} statusCollection={this.state.statusCollection}/>
                        </div>
                    </div>
                </WrapperContent>

            </Wrapper>

        )
    }
}

export default CollectionLetterList;
