import React from "react";
import ComponentPagination from "../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import SearchSelect from "../../libs/searchSelect";
import getDocumentNumber from "../../libs/getDocumentNumber";
import { Redirect } from "react-router";
import ReceiveForm from "../receive/receiveForm";
import PayPaymentChannelForm from "../pay/payPaymentChannelForm";
import numberWithCommas from "../../libs/numberWithComma";
import "./style/guaranteeMoneyReceivedCreateUpdate.scss";
import { commitMutation } from "react-relay";
import Swal from "sweetalert2";
import CreateClearGuaranteeMoneyReceivedAndTransactionMutation from "./mutations/createClearGuaranteeMoneyReceivedAndTransactionMutation";
import _ from "lodash";
import { format } from "date-fns";
import i18n from 'i18next'
import { Modal} from 'react-bootstrap';
import InputAddress from "../../libs/autoCompleteAddress";
import SelectRoleAccounting from "../../components/FucntionLib/selectRoleAccounting";
import Loading from "../../libs/loading";
import i18next from "i18next";
import queryCheckHouseHolderPaygroup from "../income/query/queryCheckHouseHolderPaygroup"

const pay_channel = {
  slug: "",
  pay: "",
  image: false,
  petty_cash: "",
  cheque: {
    chequeNumber: ''
  },
  date: new Date(),
  type_other_pay: false,
};

const contact_field = {
  id: "",
  refNumber: "",
  name: "",
  lastName: "",
  registeredAddress: "",
  registeredDistrict: "",
  registeredCity: "",
  registeredProvince: "",
  registeredPostalCode: "",
};

const rg_field = {
  id: "",
  docNumber: "",
  amount: "",
  issuedDate: new Date(),
  creator: "",
  note: "",
  contact: contact_field,
  chartOfAccount: "",
  description: "",
  forfeit_remark: "",
};
const options = [
  {
    node: {
      id: "1",
      value: "return_all",
      label: "คืนทั้งหมด",
    },
  },
  {
    node: {
      id: "2",
      value: "forfeit_all",
      label: "ยึดทั้งหมด",
    },
  },
  {
    node: {
      id: "3",
      value: "return_or_forfeit_partial",
      label: "คืนบางส่วน/ยึดบางส่วน",
    },
  },
];


const query = graphql`
  query guaranteeMoneyReceivedCreateUpdateQuery(
    $contact_id: ID!
    $gr_id: ID!
    $startswith: String
  ) {
    contact(id: $contact_id) {
      id
      refNumber
      name
      lastName
      registeredAddress
      registeredDistrict
      registeredCity
      registeredProvince
      registeredPostalCode

      ownerName
      mailingAddress
      mailingDistrict
      mailingCity
      mailingProvince
      mailingPostalCode
      
      renterName
      renterAddress
      renterDistrict
      renterCity
      renterProvince
      renterPostalCode

      agentName
      agentAddress
      agentDistrict
      agentCity
      agentProvince
      agentPostalCode
    }
    guaranteeMoneyReceived(id: $gr_id) {
      id
      docNumber
      firstName lastName
      address district city province postalCode
      payGroup
      amount
      issuedDate
      creator
      note
      description
      chartOfAccount {
        id
        chartOfAccountCode
        name
      }
      contact {
        id
        refNumber
        name
        lastName

        ownerName
        registeredAddress
        registeredDistrict
        registeredCity
        registeredProvince
        registeredPostalCode

        renterName
        renterAddress
        renterDistrict
        renterCity
        renterProvince
        renterPostalCode

        agentName
        agentAddress
        agentDistrict
        agentCity
        agentProvince
        agentPostalCode
      }
    }
    viewer {
      allChartOfAccount(chartOfAccountCode_Istartswith: $startswith) {
        edges {
          node {
            id
            chartOfAccountCode
            name
          }
        }
      }
    }
    allProjects {
            edges {
            node {
            periodCreate
            }
      }
    }
    
  }
`;

const mutation = graphql`
  mutation guaranteeMoneyReceivedCreateUpdateMutation(
    $input: CreateGuaranteeMoneyReceivedInput!
  ) {
    createGuaranteeMoneyReceived(input: $input) {
      ok
      warningText
    }
  }
`;

class GuaranteeMoneyReceived extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      contact: contact_field,
      gr: rg_field,
      charts: false,
      charts_return: [],
      category: this.props.category,
      receive_channel: [],
      total_receive: 0.0,
      select_type_of_transaction: "return_all",
      return_chart_of_account: "",
      return_amount: "",
      forfeit_amount: "",
      disabled: props.match.params.method === "clear" ? true : false,
      method: props.match.params.method,
      upload: {},
      voiding: false,
      total_amount: 0.0,
      signreq: Math.random().toString(36),
      temp_month_before: '',
      temp_month_after: '',
      period_create: '',
      pay_group: "owner",
      name: "",
      address: "",
      loading: true,
      editor: false,
      showModal:false,
      invoiceNameAddress:[],
      taxIdNumber:"",
      firstName:"",
      lastName:"",
      addressOther:""

    };
    this.handleChange = this.handleChange.bind(this);
    this.updateReceiveChannel = this.updateReceiveChannel.bind(this);
    this.submitFrom = this.submitFrom.bind(this);
    this.onSubmitCreate = this.onSubmitCreate.bind(this);
    this.onSubmitClear = this.onSubmitClear.bind(this);
    this.onUpdateError = this.onUpdateError.bind(this);
    this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
    this.onVoidGuaranteeMoney = this.onVoidGuaranteeMoney.bind(this);
    this.submitModal = this.submitModal.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentWillMount() {
    let method = this.state.method;

    if (method === "create") {
      if (this.props.contact) {
        let variables = {
          contact_id: this.props.contact,
          gr_id: "",
          startswith: "2125",
        };
        fetchQuery(environment, query, variables).then((data) => {
          let addrrss = this.addressData(data.contact)
          let name = data.contact.refNumber + " " + data.contact.name
          // this.setState({ address: addrrss, name: name });
          this.setState({
            address: addrrss, name: name,
            contact: data.contact,
            charts: data.viewer.allChartOfAccount.edges,
            gr: rg_field,
            loading: false,
            editor: false,

          });
          this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
        });
      }
    }

    if (method === "clear") {
      let variables = {
        contact_id: "",
        gr_id: this.props.match.params.id,
        startswith: "",
      };
      fetchQuery(environment, query, variables).then((data) => {
        let charts = [];
        let charts_return = [];
        data.viewer.allChartOfAccount.edges.forEach((chart_of_account) => {
          if (chart_of_account.node.chartOfAccountCode.startsWith("2126")) {
            charts.push(chart_of_account);
          } else if (
            chart_of_account.node.chartOfAccountCode.startsWith("4200")
          ) {
            charts_return.push(chart_of_account);
          } else if (
            // chart_of_account.node.chartOfAccountCode.startsWith("4300-06")
            chart_of_account.node.chartOfAccountCode.startsWith("4300")
          ) {
            charts_return.push(chart_of_account);
          }
          // else if (chart_of_account.node.chartOfAccountCode.startsWith("42")) {
          //     charts_return.push(chart_of_account);
          // }
        });
        let gr = { ...rg_field };
        gr.id = data.guaranteeMoneyReceived.id;
        gr.docNumber = data.guaranteeMoneyReceived.docNumber;
        gr.amount = data.guaranteeMoneyReceived.amount;
        // gr.issuedDate = new Date(data.guaranteeMoneyReceived.issuedDate);
        gr.issuedDate = new Date();
        gr.creator = data.guaranteeMoneyReceived.creator;
        gr.note = data.guaranteeMoneyReceived.note;
        gr.contact = data.guaranteeMoneyReceived.contact;
        gr.chartOfAccount = data.guaranteeMoneyReceived.chartOfAccount;
        gr.description = data.guaranteeMoneyReceived.description;

        let addrrss = this.addressData(data.guaranteeMoneyReceived.contact)
        let name = data.guaranteeMoneyReceived.contact.refNumber + " " + data.guaranteeMoneyReceived.firstName + " " + data.guaranteeMoneyReceived.lastName

        let payGroup = data?.guaranteeMoneyReceived?.payGroup?.toLowerCase()
        this.setState({
          gr: gr,
          contact: data.guaranteeMoneyReceived.contact,
          charts: charts,
          charts_return: charts_return,
          address: addrrss, name: name,
          pay_group: payGroup,
          loading: false,
          editor: false,
        });
      });
    }
  }


  calPeriodMouth(val) {
    let month_before = new Date();
    let month_after = new Date();

    month_before.setMonth(month_before.getMonth() - parseInt(val));
    month_after.setMonth(month_after.getMonth() + parseInt(val));

    this.setState({
      temp_month_before: month_before,
      temp_month_after: month_after,
    })
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    let state_group = event.target.name.split(".")[0];
    let state_key = event.target.name.split(".")[1];
    let new_state = "";
    if (state_key) {
      new_state = { ...this.state[state_group] };
      new_state[state_key] = event.target.value;
      if (state_key === "chartOfAccount") {
        this.state.charts.forEach((ps) => {
          if (ps.node.id === event.target.value) {
            new_state["description"] = ps.node.name;
          }
        });
      }
    } else {
      new_state = event.target.value;
    }
    this.setState({ [state_group]: new_state }, () => {
      if (name === "forfeit_amount") {
        this.setState({ total_receive: this.state.gr.amount - value });
      }
      if (name === "select_type_of_transaction") {
        let new_value = "";
        if (value !== "return_all") {
          new_value = this.state.charts_return[0].node.id;
        }
        if (value === "forfeit_all") {
          this.setState({
            receive_channel: [pay_channel]
          })
        }
        this.setState({ return_chart_of_account: new_value });
      }
    });


  }

  updateReceiveChannel(channel_list) {


    let total_receive = 0;
    let upload = {};

    channel_list.forEach((channel) => {

      if (channel.slug === "small-change") {
        total_receive -= parseFloat(channel.pay);
      } else {
        total_receive += parseFloat(channel.pay);
      }

      if (channel.image) {
        Object.assign(upload, { [channel.slug]: channel.image });
      }
    });


    this.setState({
      receive_channel: channel_list,
      total_receive: total_receive,
      upload: upload,
    });
  }

  submitFrom(e) {
    e.preventDefault();

    if (this.state.method === "create") {
      this.onSubmitCreate();
    } else {
      this.onSubmitClear();
    }
  }

  onSubmitCreate() {
    if (
      parseFloat(this.state.gr.amount) !== parseFloat(this.state.total_receive)
    ) {
      Swal.fire(i18n.t("guarantee_money_received:Please enter the correct amount!"), "", "warning");
    } else {
      this.setState({ loading: true });
      let variables = {
        input: {
          contactId: this.state.contact.id,
          payChannel: JSON.stringify(this.state.receive_channel),
          transactions: JSON.stringify(this.state.gr),
          clientMutationId: "GR" + this.state.signreq,
          payGroup: this.state.pay_group
        },
      };
      let uploadables = this.state.upload;

      fetchQuery(environment, queryCheckHouseHolderPaygroup, {
        contactId: this.state.contact.id,
        payGroup:this.state.pay_group
       }).then((data) => {
       let checkHouseHolderPaygroup = JSON.parse(data.checkHouseHolderPaygroup)
       if(checkHouseHolderPaygroup.ok){
         // create guaranteeMoneyReceived
          commitMutation(environment, {
            mutation,
            variables,
            uploadables,
            onCompleted: (response) => {
              if (response.createGuaranteeMoneyReceived.ok) {
                this.onUpdateSuccess(response);
              } else {
                this.setState({ loading: false });
                Swal.fire(i18n.t("guarantee_money_received:Save unsuccessfully!"), response.createGuaranteeMoneyReceived.warningText, "warning");
              }
            },
            onError: (err) => {
              this.onUpdateError(err);
            },
          });

       }else if(checkHouseHolderPaygroup.ok === false && this.state.pay_group === 'owner' ){
         Swal.fire({
          //  title: "ออกได้แต่ชื่อจะเป็นค่าช่องว่าง",
           type: 'warning',
           showCancelButton: true,
           text:checkHouseHolderPaygroup.message,
           confirmButtonColor: '#3085d6',
           cancelButtonColor: '#d33',
           confirmButtonText: i18n.t("Allaction:Yes"),
           cancelButtonText: i18n.t("Allaction:cancel")
         }).then((result) => {
             if (result.value) {
               // create guaranteeMoneyReceived
                commitMutation(environment, {
                  mutation,
                  variables,
                  uploadables,
                  onCompleted: (response) => {
                    if (response.createGuaranteeMoneyReceived.ok) {
                      this.onUpdateSuccess(response);
                    } else {
                      this.setState({ loading: false });
                      Swal.fire(i18n.t("guarantee_money_received:Save unsuccessfully!"), response.createGuaranteeMoneyReceived.warningText, "warning");
                    }
                  },
                  onError: (err) => {
                    this.onUpdateError(err);
                  },
                });
    
             }else{
               this.setState({ loading: false });
             }
         })
       }else{
         Swal.fire({
           title: "บันทึกไม่สำเร็จ",
           type: 'warning',
           text:checkHouseHolderPaygroup.message,
           showCancelButton: true,
           showConfirmButton:false,
           cancelButtonColor: '#d33',
           cancelButtonText: 'ปิด',
       })

         this.setState({ loading: false });
       }
     })

    }
  }

  onSubmitClear() {
    if (
      this.state.select_type_of_transaction === "return_all" &&
      parseFloat(this.state.gr.amount) !== parseFloat(this.state.total_receive)
    ) {
      Swal.fire(i18n.t("guarantee_money_received:Please enter the correct amount!"), "", "warning");
    } else if (
      this.state.select_type_of_transaction === "return_or_forfeit_partial" &&
      parseFloat(this.state.total_receive) +
      parseFloat(this.state.forfeit_amount) !==
      parseFloat(this.state.gr.amount)
    ) {
      Swal.fire(i18n.t("guarantee_money_received:Please enter the correct amount!"), "", "warning");
    } else {
      this.setState({ loading: true });

      let detail_transaction = {
        type_of_transaction: this.state.select_type_of_transaction,
        return_chart_of_account: this.state.return_chart_of_account,
        total_receive: this.state.total_receive,
        forfeit_amount: this.state.forfeit_amount,
        issued_date: format(this.state.gr.issuedDate, "YYYY-MM-DD"),
      };

      let otherAddressDetail = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        tax_id_number: this.state.taxIdNumber,
        address: this.state.addressOther,
        province: this.state.invoiceNameAddress.registeredProvince,
        district: this.state.invoiceNameAddress.registeredDistrict,
        city: this.state.invoiceNameAddress.registeredCity,
        postal_code: this.state.invoiceNameAddress.registeredPostalCode
      };

      // console.log("otherAddressDetail",otherAddressDetail);

      this.state.receive_channel[0]['pay'] = this.state.total_receive;
      let variables = {
        input: {
          guaranteeMoneyReceived: this.state.gr.id,
          detailTransaction: JSON.stringify(detail_transaction),
          payChannel: JSON.stringify(this.state.receive_channel),
          note: this.state.gr.note,
          forfeitRemark: this.state.gr.forfeit_remark,
          clientMutationId: "CGR" + this.state.signreq,
          otherNameAddress: JSON.stringify(otherAddressDetail),
          payGroup: this.state.pay_group
        },
      };



      if(this.state.pay_group !== 'other'){
        fetchQuery(environment, queryCheckHouseHolderPaygroup, {
          contactId: this.state.contact.id,
          payGroup:this.state.pay_group
         }).then((data) => {
         let checkHouseHolderPaygroup = JSON.parse(data.checkHouseHolderPaygroup)
          // console.log("checkHouseHolderPaygroup",checkHouseHolderPaygroup);
          if(checkHouseHolderPaygroup.ok){
            // create guaranteeMoneyReceived clear 
              CreateClearGuaranteeMoneyReceivedAndTransactionMutation(
              variables,
              this.onUpdateSuccess,
              this.onUpdateError
            );
            
          }else if(checkHouseHolderPaygroup.ok === false && this.state.pay_group === 'owner' ){
            Swal.fire({
             //  title: "ออกได้แต่ชื่อจะเป็นค่าช่องว่าง",
              type: 'warning',
              showCancelButton: true,
              text:checkHouseHolderPaygroup.message,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: i18n.t("Allaction:Yes"),
              cancelButtonText: i18n.t("Allaction:cancel")
            }).then((result) => {
                if (result.value) {
                  // create guaranteeMoneyReceived clear
                CreateClearGuaranteeMoneyReceivedAndTransactionMutation(
                  variables,
                  this.onUpdateSuccess,
                  this.onUpdateError
                );
       
                }else{
                  this.setState({ loading: false });
                }
            })
          }else{
            Swal.fire({
              title: "บันทึกไม่สำเร็จ",
              type: 'warning',
              text:checkHouseHolderPaygroup.message,
              showCancelButton: true,
              showConfirmButton:false,
              cancelButtonColor: '#d33',
              cancelButtonText: 'ปิด',
          })
            this.setState({ loading: false });
          }
          
         })
      }else{

        CreateClearGuaranteeMoneyReceivedAndTransactionMutation(
        variables,
        this.onUpdateSuccess,
        this.onUpdateError
      );
      }
      

      // CreateClearGuaranteeMoneyReceivedAndTransactionMutation(
      //   variables,
      //   this.onUpdateSuccess,
      //   this.onUpdateError
      // );
    }
  }

  onUpdateSuccess(response) {
    if (response.createClearGuaranteeMoneyReceivedAndTransaction) {

      if (response.createClearGuaranteeMoneyReceivedAndTransaction.ok) {
        this.setState({ loading: false });
        Swal.fire(i18n.t("guarantee_money_received:Save successfully!"), "", "success").then(() => {
          this.setState({ redirectToList: true });
        });
      } else {
        this.setState({ loading: false });
        Swal.fire(i18n.t("guarantee_money_received:Save unsuccessfully!"), response.createClearGuaranteeMoneyReceivedAndTransaction.warningText, "warning");
        // Swal.fire("Error!", i18n.t("guarantee_money_received:Please try again"), "warning");
      }
    } else {
      if (response.createGuaranteeMoneyReceived.ok) {
        this.setState({ loading: false });
        Swal.fire(i18n.t("guarantee_money_received:Save successfully!"), "", "success").then(() => {
          this.setState({ redirectToList: true });
        });
      } else {
        this.setState({ loading: false });
        Swal.fire(i18n.t("guarantee_money_received:Save unsuccessfully!"), response.createGuaranteeMoneyReceived.warningText, "warning");

      }
    }
  }


  onUpdateError(error) {
    this.setState({ loading: false });
    Swal.fire("Error!", i18n.t("guarantee_money_received:Please try again"), "warning");
  }

  onVoidGuaranteeMoney() {
    // this.setState({voiding: true});
    // let variables = {
    //     input: {
    //         clientMutationId: this.props.match.params.id,
    //         status: 'void',
    //     }
    // };
    // GuaranteeMoneyReceivedUpdateMutation(variables,()=>this.onUpdateSuccess('ยกเลิก'), this.onUpdateError);
  }

  getData = (pay_group) => {
    let name = "";
    let addresss = "";
    if (pay_group === "agent") {
      name = this.state.contact.refNumber + " " + this.state.contact.agentName
      addresss = this.state.contact.agentAddress +
        ` ${this.state.contact.agentDistrict}` +
        ` ${this.state.contact.agentCity}` +
        ` ${this.state.contact.agentProvince}` +
        ` ${this.state.contact.agentPostalCode}`
    } else if (pay_group === "rent") {
      name = this.state.contact.refNumber + " " + this.state.contact.renterName
      addresss = this.state.contact.renterAddress +
        ` ${this.state.contact.renterDistrict}` +
        ` ${this.state.contact.renterCity}` +
        ` ${this.state.contact.renterProvince}` +
        ` ${this.state.contact.renterPostalCode}`
    }else if (pay_group === "other") {
        this.setState({ 
          showModal: true,
          // firstName:'',
          // lastName:'',
        });
    }
    else {
      name = this.state.contact.refNumber + " " + this.state.contact.ownerName
      addresss = this.state.contact.registeredAddress +
        ` ${this.state.contact.registeredDistrict}` +
        ` ${this.state.contact.registeredCity}` +
        ` ${this.state.contact.registeredProvince}` +
        ` ${this.state.contact.registeredPostalCode}`
    }

    this.setState({ pay_group: pay_group, name: name, address: addresss })
  }

  addressData = (address) => {
    return (
      address.registeredProvince.includes('กรุงเทพ') || address.registeredProvince.includes('กทม') ? (
        <>
          {address.registeredAddress}
          {address.registeredDistrict && ` แขวง${address.registeredDistrict}`}
          {address.registeredCity && ` เขต${address.registeredCity}`}
          {address.registeredProvince && ` ${address.registeredProvince} `}
          {address.registeredPostalCode}
        </>
      ) : (
        <>
          {address.registeredAddress}
          {address.registeredDistrict && ` ตำบล${address.registeredDistrict}`}
          {address.registeredCity && ` อำเภอ${address.registeredCity}`}
          {address.registeredProvince && ` จังหวัด${address.registeredProvince} `}
          {address.registeredPostalCode}
        </>
      )
    )
  }

  handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    let state = _.set(_.cloneDeep(this.state), name, value);
    this.setState(state);
 }

  onSelect(fullAddress) {
    const { registeredDistrict, registeredCity, registeredProvince, registeredPostalCode } = fullAddress
    let value = _.cloneDeep(this.state.invoiceNameAddress);
    _.set(value,'registeredDistrict',registeredDistrict)
    _.set(value,'registeredCity',registeredCity)
    _.set(value,'registeredProvince',registeredProvince)
    _.set(value,'registeredPostalCode',registeredPostalCode)
  
    this.setState({
      invoiceNameAddress:value
    })
  }

  submitModal(e) {
    e.preventDefault()
    let addresssOther = `${this.state.addressOther || ""}` +
        ` ${this.state.invoiceNameAddress.registeredDistrict || ""}` +
        ` ${this.state.invoiceNameAddress.registeredCity || ""}` +
        ` ${this.state.invoiceNameAddress.registeredProvince || ""}` +
        ` ${this.state.invoiceNameAddress.registeredPostalCode || ""}`
    this.setState({
      showModal : false,
      name: this.state.firstName +" "+ this.state.lastName,
      address: addresssOther
    })
  }

  render() {
    if (this.state.method === "create" && !this.props.contact) {
      return (
        <Redirect to="/accounting/guarantee-money-received/form/select-contact" />
      );
    }
    if (this.state.redirectToList && this.state.method === "create") {
      return (
        <Redirect to="/accounting/guarantee-money-received/list/received" />
      );
    }
    if (this.state.redirectToList && this.state.method === "clear") {
      return <Redirect to="/accounting/guarantee-money-received/list/clear" />;
    }
    return (
      this.state.loading == false ?
        <div className="container-fluid box" id="guarantee-money-receive">
          <div className="row">
            <div className="col">
              <h3 className="mb-4 content-inner">
                <Link to="/accounting/guarantee-money-received/list/received">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/wrapperContent/back.png"
                    }
                    alt="back"
                    className="back"
                  />
                </Link>
                <Translation>
                  {(t) => (
                    <span>{t("accounting:record guarantee money received")}</span>
                  )}
                </Translation>
              </h3>
            </div>
            {/*{
                        this.state.disabled &&
                        <div className="col text-right">
                            <button className="btn btn-danger add mr-3" disabled={this.state.voiding}
                                    onClick={this.onVoidGuaranteeMoney}>
                                {this.state.voiding &&
                                <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                ยกเลิกใบบันทึกรับเงินค้ำประกัน
                            </button>
                        </div>
                    }*/}
          </div>

          <div className="content-inner fade-up">
            <form id="form-add" onSubmit={this.submitFrom}>
              <div className="row">
                <SelectRoleAccounting
                  editor={this.state.editor}
                  handleChangeSelect={(value) => this.getData(value)}
                  value={this.state.pay_group}
                  isHasOther={this.state.method === "create" ? false : true}
                />


                <div className="col-md-3">
                  <p>
                    <Translation>{t => <strong>{t("guarantee_money_received:Customer")}</strong>}</Translation>
                  </p>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name="contact.name"
                      value={this.state.name}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <p>
                    <Translation>{t => <strong>{t("guarantee_money_received:No.")}</strong>}</Translation>
                  </p>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name="gr.docNumber"
                      value={
                        this.state.gr.docNumber
                          ? getDocumentNumber.get(
                            this.state.category,
                            "XXX",
                            this.state.gr.issued_date
                          )
                          : getDocumentNumber.get(
                            this.state.category,
                            "XXX",
                            this.state.gr.issued_date
                          )
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                {this.state.method === "clear" && (
                  <div className="col-md-3">
                    <p>
                      <Translation>{t => <strong>{t("guarantee_money_received:Refer")}</strong>}</Translation>
                    </p>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="gr.docNumber"
                        value={this.state.gr.docNumber}
                        disabled={true}
                      />
                    </div>
                  </div>
                )}
                <div className="col-md-3">
                  <p>
                    <Translation>{t => <strong>{t("guarantee_money_received:Date of issue")}</strong>}</Translation>
                  </p>
                  <div className="input-group">
                    {
                      _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ?
                        <DatePickerAdapter
                          name="gr.issuedDate"
                          className="form-control"
                          minDate={this.props.match.params.method === "clear" ? '' : this.state.temp_month_before}
                          // maxDate={this.state.temp_month_after}
                          selected={this.state.gr.issuedDate}
                          onChange={this.handleChange}
                        /> :
                        <DatePickerAdapter
                          name="gr.issuedDate"
                          className="form-control"
                          selected={this.state.gr.issuedDate}
                          onChange={this.handleChange}
                        />
                    }
                  </div>
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-md-6">
                  <p>
                    <Translation>{t => <strong>{t("guarantee_money_received:Address")}</strong>}</Translation>
                  </p>
                  {
                    <p>{this.state.address}</p>
                  }
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-bordered ">
                    <thead className="thead-light">
                      <Translation>
                        {t =>
                          <tr>
                            <th width={300}>{t("guarantee_money_received:List")}</th>
                            <th>{t("guarantee_money_received:Description")}</th>
                            <th>{t("guarantee_money_received:Amount")}</th>
                          </tr>}
                      </Translation>
                    </thead>
                    <tbody>
                      {this.state.disabled ? (
                        <tr>
                          <td>
                            {this.state.gr.chartOfAccount.chartOfAccountCode}{" "}
                            {this.state.gr.chartOfAccount.name}
                          </td>
                          <td>{this.state.gr.description}</td>
                          <td className="text-right">
                            {numberWithCommas(this.state.gr.amount)}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            {this.state.charts && (
                              <SearchSelect
                                onChange={this.handleChange}
                                value={this.state.gr.chartOfAccount}
                                name="gr.chartOfAccount"
                                placeholder="กรุณาเลือกรายการ"
                                queryObject={this.state.charts}
                                keyOfValue="id"
                                keyOfLabel="chartOfAccountCode:name"
                                require={true}
                                disabled={this.state.disabled}
                              />
                            )}
                          </td>
                          <td>
                            <textarea
                              id=""
                              cols=""
                              rows="1"
                              className="form-control"
                              name="gr.description"
                              value={this.state.gr.description}
                              onChange={this.handleChange}
                              disabled={this.state.disabled}
                              required
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control text-right"
                              name="gr.amount"
                              value={this.state.gr.amount}
                              onChange={this.handleChange}
                              disabled={this.state.disabled}
                              required
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="row mt-4">
                    <div className="col">
                      <label htmlFor="note">
                        <Translation>{t => <strong>{t("guarantee_money_received:Note")}</strong>}</Translation>
                      </label>
                      <textarea
                        className="form-control"
                        rows="5"
                        name="gr.note"
                        value={this.state.gr.note}
                        onChange={this.handleChange}
                        disabled={this.state.disabled}
                      />
                    </div>
                    <div className="col offset-6 bg-light">
                      <div className="row p-3 mb-2 text-dark">
                        <Translation>{t => <div className="col text-left">{t("guarantee_money_received:Total")}</div>}</Translation>
                        <Translation>
                          {t =>
                            <div className="col text-right">
                              {numberWithCommas(this.state.gr.amount || 0)} {t("guarantee_money_received:Baht")}
                            </div>}
                        </Translation>
                      </div>
                    </div>
                  </div>

                  {this.state.method === "clear" && (
                    <div className="row mt-5">
                      <div className="col-4">
                        <p>
                          <Translation>{t => <strong>{t("guarantee_money_received:Guaranteed payment options")}</strong>}</Translation>
                        </p>
                        <SearchSelect
                          onChange={this.handleChange}
                          value={this.state.select_type_of_transaction}
                          name="select_type_of_transaction"
                          placeholder="ยึดเข้าหมวด"
                          queryObject={options}
                          keyOfValue="value"
                          keyOfLabel="label"
                          require={true}
                        />
                      </div>
                      {(this.state.select_type_of_transaction === "forfeit_all" ||
                        this.state.select_type_of_transaction ===
                        "return_or_forfeit_partial") && (
                          <div className="col-4">
                            <p>
                              <Translation>{t => <strong>{t("guarantee_money_received:Seize to")}</strong>}</Translation>
                            </p>
                            <SearchSelect
                              onChange={this.handleChange}
                              value={this.state.return_chart_of_account}
                              name="return_chart_of_account"
                              placeholder="ยึดเข้าหมวด"
                              queryObject={this.state.charts_return}
                              keyOfValue="id"
                              keyOfLabel="chartOfAccountCode:name"
                              require={true}
                            />
                          </div>
                        )}
                      {this.state.select_type_of_transaction ===
                        "return_or_forfeit_partial" && (
                          <div className="col-4">
                            <p>
                              <Translation>{t => <strong>{t("guarantee_money_received:The amount to be seized")}</strong>}</Translation>
                            </p>
                            <input
                              type="number"
                              className="form-control text-right"
                              name="forfeit_amount"
                              step="0.01"
                              min={0.01}
                              value={this.state.forfeit_amount}
                              max={_.get(this.state, "gr.amount")}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        )}
                    </div>
                  )}

                  {this.state.method === "create" && (
                    <ReceiveForm
                      updateReceiveChannel={this.updateReceiveChannel}
                      payment_type={"receive"}
                      not_other_channel={true}
                      issued_date={this.state.gr.issuedDate}
                      total={this.state.gr.amount}
                      receive_form={true}
                    />
                  )}

                  {/*{this.state.select_type_of_transaction === 'return_or_forfeit_partial' &&*/}
                  {/*    <div className="row mt-3">*/}
                  {/*<div className="col-4">*/}
                  {/*    <p>จำนวนเงินที่ต้องการคืน</p>*/}
                  {/*    <input type="number" className="form-control text-right"*/}
                  {/*           name="return_amount" step="0.01" min={0.01}*/}
                  {/*           value={this.state.return_amount}*/}
                  {/*           onChange={this.handleChange} required/>*/}
                  {/*</div>*/}

                  {/*<div className="col-4">*/}
                  {/*    <p>จำนวนเงินที่ต้องการยึด</p>*/}
                  {/*    <input type="number" className="form-control text-right"*/}
                  {/*           name="forfeit_amount" step="0.01" min={0.01}*/}
                  {/*           value={this.state.forfeit_amount}*/}
                  {/*           onChange={this.handleChange} required/>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*}*/}
                  {this.state.select_type_of_transaction !== "forfeit_all" &&
                    this.state.method === "clear" &&
                    this.state.gr.amount !== "" && (
                      <PayPaymentChannelForm
                        updatePayChannel={this.updateReceiveChannel}
                        payment_type={"pay"}
                        not_other_channel={true}
                        use_petty_cash={true}
                        auto_suggest={
                          _.get(this.state, "gr.amount", 0) -
                          _.get(this.state, "forfeit_amount", 0)
                        }
                        issued_date={this.state.gr.issuedDate}
                      />
                    )}
                  {this.state.select_type_of_transaction !== "return_all" && (
                    <div className="row mt-4">
                      <div className="col-4">
                        <label htmlFor="forfeit_remark">
                          <Translation>{t => <strong>{t("guarantee_money_received:Reason for seizure")}</strong>}</Translation>
                        </label>
                        <textarea
                          className="form-control"
                          rows="4"
                          name="gr.forfeit_remark"
                          value={this.state.gr.forfeit_remark}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row mt-4">
                    <div className="col text-right">
                      <div className="btn-group mr-2">
                        <Link to="/accounting/guarantee-money-received/list/received">
                          <Translation>
                            {t =>
                              <button type="button" className="btn btn-secondary add">
                                {t("guarantee_money_received:Cancel")}
                              </button>}
                          </Translation>
                        </Link>
                      </div>
                      <div className="btn-group mr-2">
                        <Translation>
                          {t =>
                            <button
                              type="submit"
                              className="btn btn-primary add"
                              disabled={this.state.loading}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm align-middle mr-2" />
                              )}
                              {t("guarantee_money_received:Save")}
                            </button>}
                        </Translation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <Modal show={this.state.showModal} dialogClassName="modal-approve" id="modal" >
              <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                  <div onSubmit={this.submitModal}> 
                  <h3 className="pt-2 pb-2">{i18next.t("editAddress:Edit customer name-surname / address")}</h3>
                  <form >
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="name">{i18next.t("editAddress:Name")}<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="firstName" name="firstName" value={this.state.firstName} required
                         onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="surname">{i18next.t("editAddress:Surname")} <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="lastName" name="lastName" value={this.state.lastName} required
                        onChange={this.handleInputChange}/>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="taxIdNumber">{i18next.t("editAddress:Tax payer identification/Identification number")}</label>
                      <input type="text" className="form-control" id="taxIdNumber" name="taxIdNumber" value={this.state.taxIdNumber?.replace('--', '')}
                      onChange={this.handleInputChange}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputAddress2">{i18next.t("editAddress:Address")}</label>
                      <textarea cols="" rows="3" className="form-control" id="addressOther"
                       name="addressOther" maxLength={256} value={this.state.addressOther} onChange={this.handleInputChange}/>
                    </div>
                    <div className="form-row">
                     <div className="form-group col-md-6">
                        <label htmlFor="city">{i18next.t("editAddress:Sub-district")}</label>
                        <InputAddress
                             address="invoiceNameAddress.registeredDistrict"
                             value={this.state.invoiceNameAddress.registeredDistrict}
                             onChange={this.handleInputChange}
                             onSelect={this.onSelect}
                             mailing={false}
                             className="form-control"    
                         />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="city">{i18next.t("editAddress:District")}</label>
                        <InputAddress
                             address="invoiceNameAddress.registeredCity"
                             value={this.state.invoiceNameAddress.registeredCity}
                             onChange={this.handleInputChange}
                             onSelect={this.onSelect}
                             mailing={false}
                             className="form-control"    
                         />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputState">{i18next.t("editAddress:Province")}</label>
                        <InputAddress
                             address="invoiceNameAddress.registeredProvince"
                             value={this.state.invoiceNameAddress.registeredProvince}
                             onChange={this.handleInputChange}
                             onSelect={this.onSelect}
                             mailing={false}
                             className="form-control"    
                         />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputZip">{i18next.t("editAddress:Zip code")}</label>
                        <InputAddress
                             address="invoiceNameAddress.registeredPostalCode"
                             value={this.state.invoiceNameAddress.registeredPostalCode}
                             onChange={this.handleInputChange}
                             onSelect={this.onSelect}
                             mailing={false}
                             className="form-control"    
                         />
                      </div>
                    </div>
                    <div className="col-12  mt-3 text-right">
                  
                    <button type="button" className="btn btn-danger mr-2" onClick={() => this.setState({showModal : false})}>
                        <span>{i18next.t("editAddress:Cancel")}</span>
                    </button>
                    <button
                          className="btn btn-primary"
                          disabled={this.state.loading} type="submit"
                        >
                          {i18next.t("Allaction:yes")}
                    </button>
                     
                </div>
                  </form>
                  </div>
              </Modal.Body>

             </Modal>
          </div>
        </div>
        :
        <Loading />
    );
  }
}

export default GuaranteeMoneyReceived;
