/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type waterAndElectricUtilityReceiveReportTableQueryVariables = {|
  atDate?: ?any,
  month?: ?number,
  year?: ?number,
  search?: ?string,
  contactId?: ?string,
  typeOfContact?: ?string,
  productAndService?: ?string,
|};
export type waterAndElectricUtilityReceiveReportTableQueryResponse = {|
  +allContactUnitTransaction: ?$ReadOnlyArray<?any>
|};
export type waterAndElectricUtilityReceiveReportTableQuery = {|
  variables: waterAndElectricUtilityReceiveReportTableQueryVariables,
  response: waterAndElectricUtilityReceiveReportTableQueryResponse,
|};
*/


/*
query waterAndElectricUtilityReceiveReportTableQuery(
  $atDate: Date
  $month: Int
  $year: Int
  $search: String
  $contactId: ID
  $typeOfContact: String
  $productAndService: String
) {
  allContactUnitTransaction(atDate: $atDate, month: $month, year: $year, search: $search, contactId: $contactId, typeOfContact: $typeOfContact, productAndService: $productAndService)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "atDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "month"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productAndService"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeOfContact"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "atDate",
        "variableName": "atDate"
      },
      {
        "kind": "Variable",
        "name": "contactId",
        "variableName": "contactId"
      },
      {
        "kind": "Variable",
        "name": "month",
        "variableName": "month"
      },
      {
        "kind": "Variable",
        "name": "productAndService",
        "variableName": "productAndService"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "typeOfContact",
        "variableName": "typeOfContact"
      },
      {
        "kind": "Variable",
        "name": "year",
        "variableName": "year"
      }
    ],
    "kind": "ScalarField",
    "name": "allContactUnitTransaction",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "waterAndElectricUtilityReceiveReportTableQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "waterAndElectricUtilityReceiveReportTableQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "f1c45ba2e3e80c9883dce4e3ba1a95e3",
    "id": null,
    "metadata": {},
    "name": "waterAndElectricUtilityReceiveReportTableQuery",
    "operationKind": "query",
    "text": "query waterAndElectricUtilityReceiveReportTableQuery(\n  $atDate: Date\n  $month: Int\n  $year: Int\n  $search: String\n  $contactId: ID\n  $typeOfContact: String\n  $productAndService: String\n) {\n  allContactUnitTransaction(atDate: $atDate, month: $month, year: $year, search: $search, contactId: $contactId, typeOfContact: $typeOfContact, productAndService: $productAndService)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '136668ed74a6330d14e6d7fbbef9dfdf';

module.exports = node;
