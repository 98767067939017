import Swal from "sweetalert2";
import i18n from "../../../i18n";




export default function onSubmitError(err){ 
    Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    return false
}

