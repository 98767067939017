/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FixRequestDetailFixType = "DEMOLISH" | "RENOVATE" | "REPAIR" | "%future added value";
export type FixRequestDetailTechnician = "IN_HOUSE" | "OUT_SOURCE" | "%future added value";
export type RoomStatus = "APPROVE" | "FINISHED" | "NOT_APPROVE" | "OPEN" | "%future added value";
export type fixRequestListQueryVariables = {|
  first?: ?number,
  last?: ?number,
  status?: ?string,
  fix_type?: ?string,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
|};
export type fixRequestListQueryResponse = {|
  +allRooms: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +numberNotRead: ?number,
        +residential: {|
          +name: string
        |},
        +name: string,
        +detail: ?string,
        +status: RoomStatus,
        +phone: string,
        +fixRequestRoom: ?{|
          +id: string,
          +fixType: FixRequestDetailFixType,
          +technician: FixRequestDetailTechnician,
          +startDate: ?string,
          +startTime: ?string,
          +endTime: ?string,
          +contactPerson: ?string,
          +contactNumber: ?string,
          +technicianName: ?string,
        |},
        +added: any,
        +note: ?string,
        +handle: {|
          +tenant: ?{|
            +firstName: string,
            +lastName: ?string,
            +phone: ?string,
          |}
        |},
      |}
    |}>,
  |}
|};
export type fixRequestListQuery = {|
  variables: fixRequestListQueryVariables,
  response: fixRequestListQueryResponse,
|};
*/


/*
query fixRequestListQuery(
  $first: Int
  $last: Int
  $status: String
  $fix_type: String
  $search: String
  $start_date: DateTime
  $end_date: DateTime
) {
  allRooms(roomType: "fix_request", first: $first, last: $last, status: $status, fixRequestRoom_FixType: $fix_type, search: $search, startDate: $start_date, endDate: $end_date) {
    totalCount
    edges {
      node {
        id
        numberNotRead
        residential {
          name
          id
        }
        name
        detail
        status
        phone
        fixRequestRoom {
          id
          fixType
          technician
          startDate
          startTime
          endTime
          contactPerson
          contactNumber
          technicianName
        }
        added
        note
        handle {
          tenant {
            firstName
            lastName
            phone
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fix_type"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "fixRequestRoom_FixType",
    "variableName": "fix_type"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Literal",
    "name": "roomType",
    "value": "fix_request"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberNotRead",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detail",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "FixRequestDetailNode",
  "kind": "LinkedField",
  "name": "fixRequestRoom",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fixType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "technician",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactPerson",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "technicianName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "fixRequestListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "RoomNodeConnection",
        "kind": "LinkedField",
        "name": "allRooms",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RoomNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RoomNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "kind": "LinkedField",
                    "name": "handle",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "fixRequestListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "RoomNodeConnection",
        "kind": "LinkedField",
        "name": "allRooms",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RoomNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RoomNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "kind": "LinkedField",
                    "name": "handle",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v14/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "717c10c4007e2a75a9477bd9e0cb37b1",
    "id": null,
    "metadata": {},
    "name": "fixRequestListQuery",
    "operationKind": "query",
    "text": "query fixRequestListQuery(\n  $first: Int\n  $last: Int\n  $status: String\n  $fix_type: String\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n) {\n  allRooms(roomType: \"fix_request\", first: $first, last: $last, status: $status, fixRequestRoom_FixType: $fix_type, search: $search, startDate: $start_date, endDate: $end_date) {\n    totalCount\n    edges {\n      node {\n        id\n        numberNotRead\n        residential {\n          name\n          id\n        }\n        name\n        detail\n        status\n        phone\n        fixRequestRoom {\n          id\n          fixType\n          technician\n          startDate\n          startTime\n          endTime\n          contactPerson\n          contactNumber\n          technicianName\n        }\n        added\n        note\n        handle {\n          tenant {\n            firstName\n            lastName\n            phone\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c4630d83f0e5a6e39d5ceeb16a9fee4';

module.exports = node;
