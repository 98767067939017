import React from 'react';
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import ComponentPagination from "../../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import _ from "lodash";
import Loading from "../../../libs/loading"
import BackButtonIcon from '../../../components/BackBtn/indexBack';
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
const query = graphql`
    query scoreManageByRoomSelectQuery($search: String) {
        allResidential (search: $search) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;


class ScoreManageByRoomSelect extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            selectNOwNumber: 0,
            selectNow: [],
            allResidential: [],
            percentLoading: 0,
            unitPriceMeter: null,
            searchBox: "",
            searchMonth: "",
            searchYears: "",
            loading: true

        }
        this.selectNow = this.selectNow.bind(this)
        this.removeSelectAll = this.removeSelectAll.bind(this)
        this.serach = this.serach.bind(this)
        this.handleInput = this.handleInput.bind(this)
        this.setTimePercent = null
    }

    componentDidMount() {
        this.getMeterList();
    }

    getMeterList() {
        let variables = {
            // first: this.state.first,
            // last: this.state.last,
        };

        this.setTimePercent = setInterval(() => {
            this.setState((prevState) => ({ percentLoading: prevState.percentLoading + 8 }))
        }, 1000)



        fetchQuery(environment, query, variables).then(results => {
            let data = results?.allResidential?.edges?.map((n) => ({
                ...n.node,
                roomNumber: n?.node?.name,
                FnameOwn: n?.node?.residentialHouseholder?.edges[0]?.node?.tenant?.firstName,
                LnameOwn: n?.node?.residentialHouseholder?.edges[0]?.node?.tenant?.lastName,
                statusChecked: false,
                searchRoom: false

            }))
            this.setState({
                allResidential: data,
                percentLoading: null,
                unitPriceMeter: results?.productViewer?.allProduct?.edges[0]?.node?.price,
                loading: false

            }, () => {
                clearInterval(this.setTimePercent)
            })
        });

    }

    selectNow(data) {
        let clone = _.cloneDeep(this.state.selectNow)
        let cloneAllResident = _.cloneDeep(this.state.allResidential)
        let indexFind = clone.findIndex(ex => ex.id === data.id)
        let indexFindInAllResident = cloneAllResident.findIndex(ex => ex.id === data.id)

        if (indexFind >= 0) {
            clone.splice(indexFind, 1)
            document.getElementById(data.id).checked = false
            _.set(cloneAllResident, `[${indexFindInAllResident}].statusChecked`, false)

        }
        else {
            if (this.state.selectNow.length < 10) {
                document.getElementById(data.id).checked = true
                _.set(cloneAllResident, `[${indexFindInAllResident}].statusChecked`, true)
                let dataPull = { data: data, id: data.id }
                clone.push(dataPull)
            }

        }
        this.setState({ selectNow: clone, allResidential: cloneAllResident })
    }
    removeSelectAll() {
        let dea = this.state.selectNow?.map((n) => document.getElementById(n.id).checked = false)
        let cloneAllResident = _.cloneDeep(this.state.allResidential)
        cloneAllResident.map((n) => {
            return (
                n.statusChecked = false
            )
        })

        this.setState({ selectNow: [], allResidential: cloneAllResident })
    }

    goViewDetailHistory() {
        // selectNow 
        this.props.history.push("/accounting/water-meter/viewHistoryDetail", {
            data: this.state.selectNow,
            unitPriceMeter: this.state.unitPriceMeter
        })
    }

    handleInput(e) {
        let search = e.target.value
        if (e.key === "Enter") {
            let deata = _.cloneDeep(this.state.allResidential)
            deata.map((n) => { n.searchRoom = false })
            this.setState({ [e.target.name]: e.target.value, loading: true, allResidential: deata })
            this.serach(search);
        }
    }

    serach(e) {
        let deata = _.cloneDeep(this.state.allResidential)
        deata.map((n) => {
            if (e && n.name.includes(e)) {
                n.searchRoom = true
            } else {
                n.searchRoom = false
            }
        })

        this.setState({ allResidential: deata, loading: false })
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack={"/contact"}
                                    LinkText={t("newBookingSingha_ManageScore:Adding points")}
                                />
                                <div className="content-inner claim">
                                    <div className='row mb-5 mt-5 meterWaterFunction '>
                                        <div className='col-7 text-right' style={{ display: 'inline' }}>
                                            <div className='floatLeft'>
                                                <input type="text" name='searchBox'
                                                    defaultValue={this.state.searchBox}
                                                    className='form-control searchBoxs'
                                                    placeholder={t("silvermanguard:search")}
                                                    // onKeyUp={ (e) => setSearchBoxId(e.target.value)} 
                                                    onKeyPress={this.handleInput}
                                                // onChange={(e) => setSearchBoxId(e.target.value)} 
                                                />
                                                <span className='spanINBox' onClick={()=> this.serach(this.state.searchBox)}>
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} />
                                                </span>
                                            </div>
                                            <div className='col-5'>

                                            </div>
                                        </div>
                                    </div>


                                    <div className="row create">
                                        <div className="col-7">
                                            {/* เติมแต้ม */}
                                            <label className='headerLabel18 mb-2'>{`${t("newBookingSingha_ManageScore:Choose multiple items")} `}</label>
                                            <div className='card' style={{ padding: 24, height: 500, overflowY: 'scroll' }}>


                                                <table className='table claim'>
                                                    <thead>
                                                        <tr style={{ backgroundColor: '#E6F2FE' }} className='text-left'>
                                                            <th width={10}></th>
                                                            <th width={150}>{t("newBookingSingha_ManageScore:Room No.")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.percentLoading ?
                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        <Loading />
                                                                        <div className="progress">
                                                                            <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: `${this.state.percentLoading}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                <tr><td colSpan={2}></td></tr>

                                                        }

                                                        {
                                                            !this.state.loading &&
                                                            this.state.allResidential?.map((n) => {
                                                                if (this.state.searchBox !== "" && n.searchRoom) {
                                                                    return (
                                                                        <tr key={"IDKey" + n?.id}
                                                                            onClick={() => this.selectNow(n)}
                                                                            className={n?.statusChecked == true ? 'bg-E6F2FE' : ''}>
                                                                            <td>
                                                                                <input type={"checkbox"}
                                                                                    defaultChecked={n?.statusChecked}
                                                                                    name='selectNow'
                                                                                    onClick={() => this.selectNow(n)}
                                                                                    id={n?.id} />
                                                                            </td>
                                                                            <td>{n?.roomNumber}</td>
                                                                        </tr>
                                                                    )
                                                                } else if (this.state.searchBox === "" && !n.searchRoom) {
                                                                    return (
                                                                        <tr key={"IDKey" + n?.id}
                                                                            onClick={() => this.selectNow(n)}
                                                                            className={n?.statusChecked == true ? 'bg-E6F2FE' : ''}>
                                                                            <td>
                                                                                <input type={"checkbox"}
                                                                                    defaultChecked={n?.statusChecked}
                                                                                    name='selectNow'
                                                                                    onClick={() => this.selectNow(n)}
                                                                                    disabled={this.state.selectNow.length > 9 && n?.statusChecked == false}
                                                                                    id={n?.id} />
                                                                            </td>
                                                                            <td>{n?.roomNumber}</td>
                                                                        </tr>
                                                                    )
                                                                }


                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <label className='headerLabel18 mb-2'>
                                                {`${t("newBookingSingha_ManageScore:Selected item")} `}
                                                ({this.state.selectNow?.length})
                                                {/* {this.state.selectNow?.length > 1 ? t("newBookingSingha_ManageScore:items") : t("newBookingSingha_ManageScore:item")} */}
                                            </label>
                                            <div className='card' style={{ padding: 24, height: 500, overflowY: 'scroll', position: 'relative' }}>
                                                <table className='table mb-3'>
                                                    <thead>
                                                        <tr style={{ backgroundColor: '#E6F2FE' }} className='text-left'>
                                                            <th width={150}>{t("newBookingSingha_ManageScore:Room No.")}</th>
                                                            <th width={50} className='text-right'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.selectNow?.map((n) => {
                                                                return (
                                                                    <tr key={"IDKey" + n?.id}>

                                                                        <td>{n?.data?.roomNumber}</td>
                                                                        <td onClick={() => this.selectNow(n)} className='text-right'>
                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"}
                                                                                style={{ width: 16, height: 16 }} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col text-right mt-10'>
                                            <span className='headerLabel18 cursor fw-600'
                                                onClick={this.removeSelectAll}
                                                style={{ position: 'absolute', top: -75, right: 24, color: '#E14558', fontSize: 12 }}>{t("newBookingSingha_ManageScore:Clear items")}</span>
                                            <button
                                                className='btn-primary'
                                                type={"button"}
                                                onClick={() =>
                                                    this.props.history.push("/contact/global-area-singha/managescore/setscore", { data: this.state.selectNow })}
                                            >
                                                {t("Allaction:next")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>

        );
    }
}

export default withRouter(ScoreManageByRoomSelect);
