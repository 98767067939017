import i18next from 'i18next';
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/expense/other_expense/list/all">
                                {i18next.t("otherExpenseList:All")}
                            </NavLink>
                        </li>
                        {/*<li>*/}
                        {/*    <NavLink to="/accounting/expense/other_expense/list/paid">*/}
                        {/*        จ่ายชำระแล้ว*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                        <li>
                            <NavLink to="/accounting/expense/other_expense/list/void">
                                {i18next.t("otherExpenseList:Canceled Transactions")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;