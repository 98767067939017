import React from "react";
import Chart from "react-google-charts";
import Loading from "../libs/loading";
import numberWithCommas from "../libs/numberWithComma";
import _ from "lodash";

const options = {
    pieHole: 0.65,
    is3D: false,
    chartArea: {left: 10, top: 10, bottom: 10, right: 10},
    fontSize: 10,
    pieSliceText: 'none',
    legend: 'none',
    colors: ["#1290DD", "#D7D7D7"]
};

class pieChart extends React.PureComponent {
    constructor(props) {
        super(props);

        let total = _.get(this.props.data, "col_all_total", 0) || 0;
        let paid = _.get(this.props.data, "col_all_paid", 0) || 0;
        let not_pay = _.get(this.props.data, "col_all_not_pay", 0) || 0;
        
        this.state = {
            data:
                [
                    ["ประเภท", "ยอดเงิน"],
                    ["ชำระแล้ว",Math.abs(paid)],
                    ["ยังไม่ชำระ",Math.abs(not_pay)],
                ],
            total: Math.abs(total)
        }
    }

    render() {
        if (this.state.total > 0) {
            return (
                <React.Fragment>
                    <Chart
                        chartType="PieChart"
                        data={this.state.data}
                        options={options}
                        loader={<Loading/>}
                    />
                    <p className="description">
                        {this.props.data.product_code || "" } {this.props.data.name} <br/>
                        <strong>{numberWithCommas(this.state.total)} บาท</strong>
                    </p>
                </React.Fragment>
            )
        }
        else {
            return <span/>
        }
    }
}

export default pieChart;
