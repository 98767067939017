/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type contactCreateUpdateFormNewQueryVariables = {|
  idCardNumber?: ?string,
  contactType?: ?string,
  branchName?: ?string,
|};
export type contactCreateUpdateFormNewQueryResponse = {|
  +checkIdCardContact: ?{|
    +result: ?boolean,
    +reason: ?string,
  |},
  +checkIdCardContactBranch: ?{|
    +result: ?boolean,
    +reason: ?string,
  |},
|};
export type contactCreateUpdateFormNewQuery = {|
  variables: contactCreateUpdateFormNewQueryVariables,
  response: contactCreateUpdateFormNewQueryResponse,
|};
*/


/*
query contactCreateUpdateFormNewQuery(
  $idCardNumber: String
  $contactType: String
  $branchName: String
) {
  checkIdCardContact(idCardNumber: $idCardNumber, contactType: $contactType) {
    result
    reason
  }
  checkIdCardContactBranch(idCardNumber: $idCardNumber, contactType: $contactType, branchName: $branchName) {
    result
    reason
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branchName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "idCardNumber"
},
v3 = {
  "kind": "Variable",
  "name": "contactType",
  "variableName": "contactType"
},
v4 = {
  "kind": "Variable",
  "name": "idCardNumber",
  "variableName": "idCardNumber"
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "result",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "reason",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": [
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "concreteType": "CheckIdCardContactObject",
    "kind": "LinkedField",
    "name": "checkIdCardContact",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "branchName",
        "variableName": "branchName"
      },
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "concreteType": "CheckIdCardContactObject",
    "kind": "LinkedField",
    "name": "checkIdCardContactBranch",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "contactCreateUpdateFormNewQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "contactCreateUpdateFormNewQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "0350676f8f9a77c0765dc5725b567283",
    "id": null,
    "metadata": {},
    "name": "contactCreateUpdateFormNewQuery",
    "operationKind": "query",
    "text": "query contactCreateUpdateFormNewQuery(\n  $idCardNumber: String\n  $contactType: String\n  $branchName: String\n) {\n  checkIdCardContact(idCardNumber: $idCardNumber, contactType: $contactType) {\n    result\n    reason\n  }\n  checkIdCardContactBranch(idCardNumber: $idCardNumber, contactType: $contactType, branchName: $branchName) {\n    result\n    reason\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ccfce7ad8992fdc9ba635172fc644c82';

module.exports = node;
