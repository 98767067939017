import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../components/noMatch";
import AnnounceDashboard from './announceDashboard'
import createUpdateAnnounceDashboard from './createUpdateAnnounceDashboard'
import announceDashboardList from './announceDashboardList'
import AnnounceDashboardGroupList from './announceDashboardGroupList'
import AnnounceDetail from './announceDetail'

class Announce extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/news`} component={AnnounceDashboard}/>
                <PrivateRoute exact path={`${this.props.match.url}/news/detail/:id?`} component={AnnounceDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/news-list/:news_type?`} component={announceDashboardList}/>
                <PrivateRoute exact path={`${this.props.match.url}/create/:id?/:status?`} component={createUpdateAnnounceDashboard}/>
                <PrivateRoute exact path={`${this.props.match.url}/group`} component={AnnounceDashboardGroupList}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Announce;