import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import thLocale from "date-fns/locale/th";
import _ from 'lodash';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Dropdown } from 'react-bootstrap';
import { Translation } from 'react-i18next';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNewBold.ttf',
        italics: 'THSarabunNewItalic.ttf',
        bolditalics: 'THSarabunNewBoldItalic.ttf'
    },
    Sarabun: {
        normal: 'Sarabun-Light.ttf',
        bold: 'Sarabun-Bold.ttf',
        italics: 'Sarabun-Italic.ttf',
        bolditalics: 'Sarabun-BoldItalic.ttf'
    }
}

const ExportFixReportPDFEn = (state) => {
    const [imageUrlData, setImageUrlData] = useState('');

    const getTag = (tag_repairs_room) => {
        let tagData = ""
        tag_repairs_room.map((n) => {
            tagData = tagData + ", " + n.tag_global.tag_name
        })
        return tagData;
    }
    const getDot = (length) => {
        let text = ""
        for (let i = 0; i <= length; i++) {
            text = text + "."
        }
        return text
    }
    const getSpace = (length) => {
        let text = ""
        for (let i = 0; i <= length; i++) {
            text = text + " "
        }
        return text
    }

    const removeExtraSpaces = (text) => {
    
        return text.replace(/\s+/g, ' ');
    }

    const generatePdf = async () => {
        let nameRequest = state.dataExport?.tenant_contact ?
            state.dataExport?.tenant_contact?.first_name + " " + state.dataExport?.tenant_contact?.last_name :
            state.dataExport?.juristic.first_name + " " + state.dataExport?.juristic.last_name

        const headerData =
            state.selfProject.logo ? [
                {
                    image: state.selfProject.logo ? `data:image/jpeg;base64,` + state.selfProject.logo : '',
                    width: 60,
                    height: 60,
                    absolutePosition: { x: 20, y: 20 }
                },
                { text: state.selfProject.nameTh, margin: [70, 0, 0, 0], style: 'headerBold' },
                { text: `${removeExtraSpaces(state.selfProject.addressTh)}`, margin: [70, 0, 0, 0], style: 'headerLight' },
                { text: 'โทร.' + state.selfProject.juristicContactNumber, margin: [70, 0, 0, 0], style: 'headerLight' },
            ] :
                [
                    { text: state.selfProject.nameTh, style: 'headerBold' },
                    { text: `${removeExtraSpaces(state.selfProject.addressTh)}`, style: 'headerLight' },
                    { text: 'โทร.' + state.selfProject.juristicContactNumber, style: 'headerLight' },
                ]

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait', //portrait ตั้ง  || landscape นอน
            pageMargins: [20, 30, 20, 20],
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {
                            text: `${currentPage}/${pageCount}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [0, 0],
                        },
                        {
                            text: `Print Name: ${state.namePrint.first_name + " " + state.namePrint.last_name} Print Date: ${format(new Date(), "DD/MM/YYYY")}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [20, 0],
                        },
                    ],
                };
            },
            content: [
                ...headerData,
                { text: "", margin: [0, 0, 0, 44] },
                { text: 'Fix / Maintenance request worksheet', style: 'headerCenter', margin: [0, 0, 0, 10] },
                { text: 'Document number.............................................', alignment: 'right', fontSize: 8 },
                { text: state.dataExport?.worksheet[0]?.doc_number, margin: [0, -15, 5, 0], alignment: 'right', fontSize: 8 },
                { text: 'PART 1 NOTIFICATION', style: 'contentHeader' },


                {
                    text:
                        '1. Informer name:....................................................................................................' +
                        'House No:............................................................................................................',
                    style: 'contentLight'
                },
                //ผู้แจ้งซ่อมชื่อ
                {
                    text: state.editDataExport && state.dataExport?.step1Name ? state.dataExport?.step1Name : nameRequest,
                    style: 'contentLight',                   
                   absolutePosition: { x: 95, y: 155 }
                },
                //บ้านเลขที่
                {
                    text: state.dataExport?.residential?.name ? state.dataExport?.residential?.name : "-", style: 'contentLight',
                    absolutePosition: { x: 350, y: 155 },                      
                },

                { text: '2.Phone number:...........................................................................', style: 'contentLight' },
                {
                    text: state.dataExport?.tenant_contact?.phone ?
                    state.dataExport?.tenant_contact.phone : state.dataExport?.juristic?.phone,
                    absolutePosition: { x: 93, y: 178 },                        
                    style: 'contentLight'
                },

                {
                    text:
                        '3.Receiver staff:..............................................................' +
                        'Time:..........................................................................' +
                        'Date:..........................................................................'
                    ,
                    style: 'contentLight'
                },
                //ผู้รับแจ้งชื่อ
                {
                    text: state.dataExport?.juristic ? state.dataExport?.juristic.first_name + " " + state.dataExport?.juristic.last_name : nameRequest, 
                    absolutePosition: { x: 85, y: 200 }, style: 'contentLight'
                },
                //เวลา
                { text: format(new Date(state.dataExport?.added), "HH:mm"), 
                absolutePosition: { x: 250, y: 200 },  style: 'contentLight' },
                //วันที่
                { text: format(new Date(state.dataExport?.added), "DD/MM/YYYY"),  
                absolutePosition: { x: 430, y: 200 },  style: 'contentLight' },

                {
                    text: '4. Damage description:' + getDot(215),
                    style: 'contentLight'
                },


                {
                    //รายการปัญหาความเสียหาย
                    // text: state.editDataExport ? state.dataExport.details :
                    //     state.dataExport.working_type + ", " +
                    //     state.dataExport.details + ", " +
                    //     state.dataExport.material +
                    //     getTag(state.dataExport.tag_repairs_room)
                    // ,
                    text: state.editDataExport ? state.dataExport.dataDetails :
                    state.dataExport.working_type + ", " +
                    state.dataExport.details + ", " +
                    state.dataExport.material +
                    getTag(state.dataExport.tag_repairs_room),
                    absolutePosition: { x: 148, y: 222 },  style: 'contentLight'
                },
                {
                    text: 'Note:.......................................................................................................................................................................................................................................................',
                    style: 'contentLight'
                },

                {
                    //ลักษณะของปัญหา
                    text:
                        state.dataExport.step4_2 ? state.dataExport.step4_2 : "",
                        absolutePosition: { x: 70, y: 245 }, style: 'contentLightleft'
                },

                {
                    text:
                        '5.Manager assigns responsible staff:.............................................................................' +
                        'Position....................................................................................................',
                    style: 'contentLight'
                },

                {
                    //ผู้จัดการ มอบหมายผู้รับผิดชอบ
                    text: state.dataExport.dataStaff || "",
                    absolutePosition: { x: 165, y: 267 }, style: 'contentLight'
                },
                {
                    //ตำแหน่ง
                    text:
                        state.dataExport.worksheet[0]?.project_staff ? state.dataExport.worksheet[0]?.project_staff?.role : '',
                    // margin: [350, -30, 0, 10],
                    absolutePosition: { x: 380, y: 267 },
                    style: 'contentLight'
                },

                {
                    text: 'Additional message:' + getDot(140),
                    style: 'contentLight'
                },
                { text: getDot(255), style: 'contentLight' },
                { text: getDot(255), style: 'contentLight' },

                {
                    text:
                        ////ข้อความเพิ่มเติม
                        state.dataExport.worksheet[0]?.description ? "." + getSpace(30) + state.dataExport.worksheet[0].description.replace(/\n/g, '') : "",
                    absolutePosition: { x: 30, y: 289 },
                    style: 'contentLightleft'
                },


                { text: 'Part 2 DAMAGE ASSESSMENT', style: 'contentHeader' },
                {
                    //สาเหตุ
                    text: state.dataExport?.reason6 ? "." + getSpace(10) + state.dataExport?.reason6 : "",
                    style: 'contentLightleft',
                    // margin: [5, 0, 10, 0],
                    absolutePosition: { x: 65, y: 373 }
                },
                {
                    text:
                        '6.Cause ' + getDot(240),
                    style: 'contentLight'
                },
                { text: getDot(255), style: 'contentLight' },
                { text: getDot(255), style: 'contentLight' },

                {
                    //วิธีการแก้ไข / การใช้ทรัพยากร / งบประมาณรายจ่าย
                    text: state.dataExport?.step7 ? "." + getSpace(90) + state.dataExport?.step7 : "Solution / Resource usage / Expenditure budgeting ...",
                    style: 'contentLightleft',
                    // margin: [5, 0, 10, 0],
                    absolutePosition: { x: 230, y: 440 }
                },

                {
                    text:
                        '7. Solution / Resource usage / Expenditure budgeting ' + getDot(155),
                    style: 'contentLight'
                },
                { text: getDot(255), style: 'contentLight' },
                { text: getDot(255), style: 'contentLight' },

                {
                    text:
                        '8. Fixed by' + "           Technician within the project" + getSpace(30) + "In warranty",
                    style: 'contentLight'
                },
                {
                    image: `data:image/png;base64,${state.dataExport.worksheet[0]?.technician === "inside" ? state.checkboxTrue : state.checkBox}`,
                    margin: [42, -25, 0, 10],
                    width: 20,
                    height: 20,
                    style: 'contentLight'
                },

                {
                    image: `data:image/png;base64,${state.dataExport.insure_status === "have" ? state.checkboxTrue : state.checkBox}`,
                    alignment: 'right',
                    margin: [0, -30, 160, 10],
                    width: 20,
                    height: 20,
                    style: 'contentLight'
                },
                {
                    text: "Outsourced repair service provider according to employment documents",
                    margin: [0, 0, 35, 0],
                    alignment: 'right',
                    style: 'contentLight'
                },
                {
                    image: `data:image/png;base64,` + state.checkBox,
                    alignment: 'right',
                    margin: [0, -25, 160, 10],
                    width: 20,
                    height: 20,
                    style: 'contentLight'
                },
                {
                    //เลขที่
                    text: state.dataExport.numberTechnicianChoices ? state.dataExport.numberTechnicianChoices : "",
                    margin: [0, 0, 35, 0],
                    alignment: 'right',
                    style: 'contentLight'
                },

                {
                    text: "No" + getDot(63),
                    margin: [0, 0, 35, 20],
                    alignment: 'right',
                    style: 'contentLight'
                },

                {
                    //กำหนดแล้วเสร็จ
                    text: state.dataExport?.worksheet_status ? "." + getSpace(30) + state.dataExport?.worksheet_status : "",
                    style: 'contentLightleft',
                    absolutePosition: { x: 25, y: 595 }
                },
                {
                    text: "9.Scheduled completion date " + getDot(200),
                    style: 'contentLight'
                },

                {
                    //ผู้ประเมิน
                    text: `${state.dataExport?.job_assigned_by_staff_name ? "." + state.dataExport?.job_assigned_by_staff_name : ""}`,
                    style: 'contentLightleft',
                    absolutePosition: { x: 70, y: 624 }
                },

                {
                    //ผู้อนุมัติ
                    text: `${state.dataExport?.job_resolved_by_staff_name ? state.dataExport?.job_resolved_by_staff_name : ""}`,
                    style: 'contentLightleft',
                    absolutePosition: { x: 390, y: 624 }

                },
                {
                    text:
                        "Evaluator " + getDot(88) + "                                " +
                        "Approver " + getDot(80),
                    style: 'contentLight'
                },

                {
                    //วันที่ประเมิน
                    text: `${state.dataExport?.job_inspection_date ? format(new Date(state.dataExport.job_inspection_date), "DD/MM/YYYY") : ""}`,
                    style: 'contentLightleft',
                    absolutePosition: { x: 70, y: 647 }

                },

                {
                    //วันที่อนุมัติ
                    text: `${state.dataExport?.job_resolved_date ? format(new Date(state.dataExport.job_resolved_date), "DD/MM/YYYY") : ""}`,
                    style: 'contentLightleft',
                    absolutePosition: { x: 390, y: 647 },

                },
                {
                    text:
                        "Date " + getDot(95) + "                                 " +
                        "Date " + getDot(95),
                    style: 'contentLight'
                },

                { text: 'Part 3 FOLLOW-UP / INSPECTION OF WORK', style: 'contentHeader' },

                {
                    //บันทึกผลการติดตามผล
                    text: state.dataExport.remark ? "." + getSpace(50) + state.dataExport.remark.replace(/\n/g, '') : "",
                    style: 'contentLightleft',
                    absolutePosition: { x: 135, y: 686 }
                },
                {
                    text:
                        '10. Following up the result' + getDot(205),
                    style: 'contentLight'
                },
                { text: getDot(255), style: 'contentLight' },
                { text: getDot(255), style: 'contentLight' },

                { text: '11. Inspection results' + getSpace(20) + 'Pass' + getSpace(100) + 'Fail', style: 'contentLight' },


                {
                    image: `data:image/png;base64,${state.dataExport.status === "finish" ? state.checkboxTrue : state.checkBox}`,
                    absolutePosition: { x: 120, y: 753 },
                    // margin: [100, -28, 20, 20],
                    width: 20,
                    height: 20,
                    style: 'contentLight'
                },
                {
                    image: `data:image/png;base64,${state.dataExport.status === "not_pass" ? state.checkboxTrue : state.checkBox}`,
                    absolutePosition: { x: 355, y: 753 },
                    // margin: [320, -38, 0, 0],
                    width: 20,
                    height: 20,
                    style: 'contentLight'
                },
                {
                    //ผู้ตรวจรับงาน
                    text: `${state.dataExport?.job_closed_by_staff_name ? state.dataExport?.job_closed_by_staff_name : ""}`,
                    style: 'contentLight',
                    // margin: [5, 0, 10, 0],
                    absolutePosition: { x: 80, y: 775 }
                },

                {
                    //วันที่ผ่าน
                    text: `${state.dataExport?.job_closed_date ? format(new Date(state.dataExport.job_closed_date), "DD/MM/YYYY") : ""}`,
                    style: 'contentLight',
                    // margin: [5, 0, 10, 0],
                    absolutePosition: { x: 390, y: 775 }
                },
                {
                    text:
                        "Inspector " + getDot(90) + "                          " +
                        "Date " + getDot(95),
                    style: 'contentLight'
                },
            ],
            defaultStyle: {
                font: 'Sarabun'
            },
            styles: {
                contentHeader: {
                    bold: true, fontSize: 9,
                    lineHeight: 1.5
                },
                contentLight: {
                    fontSize: 9,
                    lineHeight: 1.9,
                    alignment: 'justify'
                },
                headerBold: {
                    fontSize: 10,
                    bold: true
                },
                headerLight: {
                    fontSize: 10
                },
                headerCenter: {
                    fontSize: 10, bold: true, alignment: 'center',
                },
                tablecell: {
                    alignment: 'center', bold: true, fontSize: 10, verticalAlignment: 'middle',
                },
                tablecellMiddle: {
                    alignment: 'center', bold: true, fontSize: 10, margin: [0, 5, 0, 0],
                },
                tabletd: {
                    alignment: 'center', heihgt: 40, maxHeight: 40, fontSize: 10, verticalAlignment: 'middle',
                },
                tabletd_left: {
                    alignment: 'left', heihgt: 40, maxHeight: 40, fontSize: 10, verticalAlignment: 'middle',
                },
                contentLightleft: {
                    fontSize: 9,
                    lineHeight: 1.8,
                    alignment: 'left'
                },
            }

        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBlob((blob) => {
            let preview = URL.createObjectURL(blob)
            window.open(preview)
        });
    };

    return (
        <Translation>
            {t =>
                <Link
                    to="route"
                    target="blank"
                    onClick={(event) => {
                        event.preventDefault();
                        generatePdf();
                    }}>
                    {
                        state?.printIcon ?
                            <img src={process.env.PUBLIC_URL + '/images/icons/printIcon.png'} style={{ height: " 20px" }} /> :
                            <button type="button" className="btn btn-primary"
                                style={{ width: "112px", height: "48px" }}
                                data-dismiss="modal" aria-label="Close">{t("Allaction:Print")}</button>
                    }
                </Link>
            }
        </Translation>
    );
}

export default ExportFixReportPDFEn;