import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import BudgetLastYearCompareWithYearPaper from "./budgetLastYearCompareWithYearPaper"
import Loading from "../../libs/loading";

const query = graphql`
  query wrapperBudgetLastYearCompareWithYearQuery {
    selfProject{
      id
      name
      address
      logo
      juristicContactNumber
      keyProjectQr
      bankCompCode
      bankServiceCode
      taxIdNumber
      typeOfProject
    }
  }
`;

export default class wrapperBudgetLastYearCompareWithYear extends Component {

  print = () => {
    window.print();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet
          style={[{
            "cssText": `
              body {
                  background-color: #ececec;
              }
          `
          }]}>
          <meta charSet="utf-8" />
          <title>BudgetLastYearCompareWithYear</title>
          <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
        </Helmet>

        <div className="print-top-menu">
          <div className="logo">
            <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
          </div>
          <div className="print" onClick={this.print}>
            PRINT
          </div>
        </div>

        <QueryRenderer
          environment={environment}
          query={query}
          cacheConfig={{ use_cache: false }}
          variables={{ }}
          render={({ error, props }) => {
            if (error) {
              return <div className="alert alert-danger"
                role="alert">{error.message}</div>;
            } else if (props) {
              return (
                <BudgetLastYearCompareWithYearPaper 
                  selfProject={props.selfProject}
                  state={this.props.location.query}
                  />
              )
            }
            return <div className="text-center"><Loading/></div>;
          }}
        />

      </React.Fragment>
    )
  }
}
