import React, { Component } from "react";
import "./styles/commonArea.scss";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import ContactTopMenuNavigation from "../contactTopMenuNavigation";

class BookingCommomArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      data: [],
      filteredData: [],
      text: "",
      path: "niti",
      selectedOption: "จองใช้งานภายใน",
      textArea: ""
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  onValueChange(event) {
    this.setState({
      selectedOption: event.target.value
    });

    if (event.target.value === "จองให้ลูกบ้าน") {
      this.setState({
        path: "customer"
      });
    } else if (event.target.value === "จองสำหรับบุคคลทั่วไป") {
      this.setState({
        path: "guest"
      });
    } else if (event.target.value === "จองใช้งานภายใน") {
      this.setState({
        path: "niti"
      });
    }
  }

  formSubmit(event) {
    event.preventDefault();
  }

  onChangeTextArea(event) {
    this.setState({
      textArea: event.target.value
    });
  }

  render() {
    const { textArea } = this.state;

    return (
      <Wrapper>
        <Header />
        <Sidebar />

        <Translation>
          {t => <WrapperContent>
            <ContactTopMenuNavigation mini={true} />
            <div className="container-fluid box">
              <div className="row justify-content-between">
                <div className="col">
                  <h3>
                    <Link to="/contact">
                      <img src={process.env.PUBLIC_URL + "/images/wrapperContent/back.png"}
                        alt="back"
                      />
                    </Link>
                    <Translation>
                      {(t) => <span className="text-header-booking ml-3">{t("newBookingSingha_Booking:Booking Facility")}</span>}
                    </Translation>
                  </h3>
                </div>
              </div>
            </div>
            <div className="container-type">
              <h4 className="text-subheader-booking" style={{ fontFamily: 'Kanit' }}>{t("newBookingSingha_Booking:Choose Type to Book")}</h4>
              <form className="mb-3" onSubmit={() => this.formSubmit()}>
                <div className="radio">
                  <label className="text-radio-booking">
                    <input
                      type="radio"
                      value="จองใช้งานภายใน"
                      checked={this.state.selectedOption === "จองใช้งานภายใน"}
                      onChange={this.onValueChange}
                    />
                    &nbsp;&nbsp;{t("newBookingSingha_Booking:Book for Insign")}
                  </label>
                </div>
                <div className="radio">
                  <label className="text-radio-booking">
                    <input
                      type="radio"
                      value="จองให้ลูกบ้าน"
                      checked={this.state.selectedOption === "จองให้ลูกบ้าน"}
                      onChange={this.onValueChange}
                    />
                    &nbsp;&nbsp;{t("newBookingSingha_Booking:Book for Tenant")}
                  </label>
                </div>
                <div className="radio">
                  <label className="text-radio-booking">
                    <input
                      type="radio"
                      value="จองสำหรับบุคคลทั่วไป"
                      checked={
                        this.state.selectedOption === "จองสำหรับบุคคลทั่วไป"
                      }
                      onChange={this.onValueChange}
                    />
                    &nbsp;&nbsp;{t("newBookingSingha_Booking:Book for Guest")}
                  </label>
                </div>
              </form>
              <h4 className="text-subheader-booking" style={{ fontFamily: 'Kanit' }}>{t("newBookingSingha_Booking:Detail")}</h4>
              <div style={{ marginRight: "264px", marginBottom: "64px" }}>
                <textarea
                  cols=""
                  rows="3"
                  className="form-control"
                  id="description"
                  name="asset.description"
                  maxLength={256}
                  onChange={(event) => this.onChangeTextArea(event)}
                />
              </div>
              <Link to={{
                pathname: `/contact/global-area-singha/booking/create/${this.state.path}`,
                textArea: textArea,
                path: this.state.path
              }}
              >
                <button type="submit" className="btn booking float-right mr-3 mb-10">
                  {t("Allaction:Next")}
                </button>
              </Link>
            </div>
          </WrapperContent>}
        </Translation>


      </Wrapper>
    );
  }
}

export default BookingCommomArea;
