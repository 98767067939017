import React, { useState } from 'react';
import { Translation } from "react-i18next";
import { withRouter } from 'react-router-dom';

const SortMeterWater = (props) => {
    const [searchBoxId, setSearchBoxId] = useState(props.search);
    const [startDateIs] = useState(props.start_date);
    const [endDateIs] = useState(props.end_date);
    const [status] = useState(props.status);
    const [month, setMonth] = useState(props.selectMonth);
    const [year, setYear] = useState(props.selectYear);


    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSreach()
        }
    }
    const onSreach = () => {

        let event = []

        let data_search = {
            name: 'search',
            value: searchBoxId,
        };
        event.push(data_search)

        let data_startDateIs = {
            name: 'start_date',
            value: startDateIs,
        };
        event.push(data_startDateIs)

        let data_endDateIs = {
            name: 'end_date',
            value: endDateIs,
        };
        event.push(data_endDateIs)

        let data_status = {
            name: 'status',
            value: status,
        };
        event.push(data_status)

        let data_month = {
            name: 'month',
            value: month,
        };
        event.push(data_month)

        let data_year = {
            name: 'year',
            value: year,
        };
        event.push(data_year)

        if (props.handlePropsChange) {
            props.handlePropsChange(event);
        }
    }


    const seleteMonthDetail = [
        { value: '01', label: 'January' },
        { value: '02', label: 'February' },
        { value: '03', label: 'March' },
        { value: '04', label: 'April' },
        { value: '05', label: 'May' },
        { value: '06', label: 'June' },
        { value: '07', label: 'July' },
        { value: '08', label: 'August' },
        { value: '09', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' },
    ]
    
    const seleteYearDetail = [
        { value: new Date().getFullYear() - 3, label: new Date().getFullYear() - 3 },
        { value: new Date().getFullYear() - 2, label: new Date().getFullYear() - 2 },
        { value: new Date().getFullYear() - 1, label: new Date().getFullYear() - 1 },
        { value: new Date().getFullYear(), label: new Date().getFullYear() },
        // { value: new Date().getFullYear() + 1, label: new Date().getFullYear() + 1 },
    
    ]

    const selectYear = year || props.selectYear || new Date().getFullYear()
    const selectMonth = month || props.selectMonth || parseInt(new Date().getMonth() + 1)

    const textSelect = props?.selectText

    return (
        <Translation >
            {
                t =>
                    <div className='row searchFunction mb-5 mt-5 claim'>
                        <div className='col-12 text-right' style={{ display: 'inline' }}>
                            {
                                props.selectIs &&
                                <div className='floatLeft'>
                                    <label className='floatLeft labelInline'>{props?.selectText ? textSelect : t("materWater:Water charges of")}: &nbsp;&nbsp;</label>

                                    <select
                                        name='searchTypePerson'
                                        className='form-control searchDate '
                                        onChange={(e) => setYear(e.target.value)}
                                        defaultValue={selectYear}>
                                        {
                                            seleteYearDetail.map((n, ind) => {
                                                return <option value={n.value} key={ind}>{t("materWater:Year") +n.label}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            }
                            {
                                props.selectMonthIs &&
                                <div className='floatLeft'>
                                    <select
                                        name='searchTypePerson'
                                        className='form-control searchDate'
                                        onChange={(e) => setMonth(e.target.value)}
                                        defaultValue={selectMonth}>
                                        {
                                            seleteMonthDetail.map((n, ind) => {
                                                return <option value={n.value} key={ind}>{t("materWater:"+n.label)}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            }

                            {
                                props.searchBoxId &&
                                <div className='floatRight'>
                                    <input type="text" name='search' defaultValue={searchBoxId} className='form-control searchBox' placeholder={t("silvermanguard:search")}
                                        // onKeyUp={ (e) => setSearchBoxId(e.target.value)} 
                                        onKeyPress={handleKeyPress}
                                        onChange={(e) => setSearchBoxId(e.target.value)} />
                                    <span className='spanINBox cursor' onClick={onSreach}>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} />
                                    </span>
                                </div>
                            }

                            {
                                props.changMeter &&
                                <div className='floatRight'>
                                    <button className='btn-gray-outline' style={{height: 47}}
                                        onClick={()=> props.history.push("/accounting/water-meter/create_history" , {data : props.data})}>
                                        {/* แจ้งเปลี่ยนมิเตอร์ */}
                                        {t("materWater:Change Water Meter")}
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
            }
        </Translation>

    );
}

export default withRouter(SortMeterWater);