/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AcceptCreditNoteStatus = "PAID" | "VOID" | "WAIT" | "WAITING_PAY" | "%future added value";
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type acceptCreditNoteViewQueryVariables = {|
  id: string
|};
export type acceptCreditNoteViewQueryResponse = {|
  +acceptCreditNote: ?{|
    +id: string,
    +docNumber: string,
    +issuedDate: any,
    +status: AcceptCreditNoteStatus,
    +contact: {|
      +refNumber: string,
      +firstName: string,
      +lastName: string,
      +name: string,
      +registeredName: string,
      +registeredAddress: string,
      +registeredCountry: string,
      +registeredProvince: string,
      +registeredCity: string,
      +registeredDistrict: string,
      +registeredPostalCode: string,
      +typeOfContact: ContactTypeOfContact,
    |},
    +purchaseRecord: {|
      +id: string,
      +productAndService: ?{|
        +productCode: string,
        +name: string,
        +chartOfAccount: ?{|
          +chartOfAccountCode: string,
          +name: string,
        |},
      |},
      +chartOfAccount: {|
        +name: string,
        +chartOfAccountCode: string,
      |},
      +purchaseRecordGroup: {|
        +docNumber: string
      |},
    |},
    +reason: string,
    +description: ?string,
    +price: number,
    +vat: number,
    +vatAmount: number,
    +total: number,
    +remark: string,
    +acceptCreditNoteNumber: ?string,
    +taxInvoiceDate: ?any,
    +taxInvoiceNumber: ?string,
    +taxInvoicePeriod: ?any,
    +refAcceptCreditNoteDoc: ?string,
    +refAcceptCreditNoteNumber: ?string,
  |}
|};
export type acceptCreditNoteViewQuery = {|
  variables: acceptCreditNoteViewQueryVariables,
  response: acceptCreditNoteViewQueryResponse,
|};
*/


/*
query acceptCreditNoteViewQuery(
  $id: ID!
) {
  acceptCreditNote(id: $id) {
    id
    docNumber
    issuedDate
    status
    contact {
      refNumber
      firstName
      lastName
      name
      registeredName
      registeredAddress
      registeredCountry
      registeredProvince
      registeredCity
      registeredDistrict
      registeredPostalCode
      typeOfContact
      id
    }
    purchaseRecord {
      id
      productAndService {
        productCode
        name
        chartOfAccount {
          chartOfAccountCode
          name
          id
        }
        id
      }
      chartOfAccount {
        name
        chartOfAccountCode
        id
      }
      purchaseRecordGroup {
        docNumber
        id
      }
    }
    reason
    description
    price
    vat
    vatAmount
    total
    remark
    acceptCreditNoteNumber
    taxInvoiceDate
    taxInvoiceNumber
    taxInvoicePeriod
    refAcceptCreditNoteDoc
    refAcceptCreditNoteNumber
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredAddress",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCountry",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredProvince",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCity",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredDistrict",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredPostalCode",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reason",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vat",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vatAmount",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptCreditNoteNumber",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxInvoiceDate",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxInvoiceNumber",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxInvoicePeriod",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refAcceptCreditNoteDoc",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refAcceptCreditNoteNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "acceptCreditNoteViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptCreditNoteNode",
        "kind": "LinkedField",
        "name": "acceptCreditNote",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNode",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordNode",
            "kind": "LinkedField",
            "name": "purchaseRecord",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductAndServiceNode",
                "kind": "LinkedField",
                "name": "productAndService",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccount",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "chartOfAccount",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseRecordGroupNode",
                "kind": "LinkedField",
                "name": "purchaseRecordGroup",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "acceptCreditNoteViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptCreditNoteNode",
        "kind": "LinkedField",
        "name": "acceptCreditNote",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNode",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordNode",
            "kind": "LinkedField",
            "name": "purchaseRecord",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductAndServiceNode",
                "kind": "LinkedField",
                "name": "productAndService",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccount",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v9/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "chartOfAccount",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v19/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseRecordGroupNode",
                "kind": "LinkedField",
                "name": "purchaseRecordGroup",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f6dc60825324b86f4511775982efd7a0",
    "id": null,
    "metadata": {},
    "name": "acceptCreditNoteViewQuery",
    "operationKind": "query",
    "text": "query acceptCreditNoteViewQuery(\n  $id: ID!\n) {\n  acceptCreditNote(id: $id) {\n    id\n    docNumber\n    issuedDate\n    status\n    contact {\n      refNumber\n      firstName\n      lastName\n      name\n      registeredName\n      registeredAddress\n      registeredCountry\n      registeredProvince\n      registeredCity\n      registeredDistrict\n      registeredPostalCode\n      typeOfContact\n      id\n    }\n    purchaseRecord {\n      id\n      productAndService {\n        productCode\n        name\n        chartOfAccount {\n          chartOfAccountCode\n          name\n          id\n        }\n        id\n      }\n      chartOfAccount {\n        name\n        chartOfAccountCode\n        id\n      }\n      purchaseRecordGroup {\n        docNumber\n        id\n      }\n    }\n    reason\n    description\n    price\n    vat\n    vatAmount\n    total\n    remark\n    acceptCreditNoteNumber\n    taxInvoiceDate\n    taxInvoiceNumber\n    taxInvoicePeriod\n    refAcceptCreditNoteDoc\n    refAcceptCreditNoteNumber\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab85650d7719451120c1c25989e23d14';

module.exports = node;
