/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PettyCashRecordStatus = "VOID" | "WAIT" | "WITHDRAW" | "%future added value";
export type wrapperPettyCashQueryVariables = {|
  id: string,
  docNumber: string,
|};
export type wrapperPettyCashQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +nameEn: ?string,
    +address: ?string,
    +addressEn: ?string,
    +taxIdNumber: ?string,
    +juristicContactNumber: ?string,
    +logo: ?string,
  |},
  +pettyCashRecord: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +issuedDate: any,
        +docNumber: string,
        +chartOfAccount: {|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
        |},
        +description: ?string,
        +price: number,
        +status: PettyCashRecordStatus,
        +setPettyCash: {|
          +docNumber: string,
          +withdrawer: ?string,
          +description: ?string,
        |},
      |}
    |}>
  |},
  +pettyCashRecordAccountRecordGroup: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +totalDebit: number,
        +totalCredit: number,
        +accountRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +debit: number,
              +credit: number,
              +name: string,
              +chartOfAccountCode: ?{|
                +id: string,
                +chartOfAccountCode: string,
                +name: string,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +userConfig: ?{|
    +id: string,
    +languages: string,
  |},
|};
export type wrapperPettyCashQuery = {|
  variables: wrapperPettyCashQueryVariables,
  response: wrapperPettyCashQueryResponse,
|};
*/


/*
query wrapperPettyCashQuery(
  $id: ID!
  $docNumber: String!
) {
  selfProject {
    id
    name
    nameEn
    address
    addressEn
    taxIdNumber
    juristicContactNumber
    logo
  }
  pettyCashRecord(docNumber: $docNumber) {
    edges {
      node {
        id
        issuedDate
        docNumber
        chartOfAccount {
          id
          chartOfAccountCode
          name
        }
        description
        price
        status
        setPettyCash {
          docNumber
          withdrawer
          description
          id
        }
      }
    }
  }
  pettyCashRecordAccountRecordGroup(pettyCashRecord: $id) {
    edges {
      node {
        id
        totalDebit
        totalCredit
        accountRecord {
          edges {
            node {
              id
              debit
              credit
              name
              chartOfAccountCode {
                id
                chartOfAccountCode
                name
              }
            }
          }
        }
      }
    }
  }
  userConfig {
    id
    languages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "docNumber"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectNode",
  "kind": "LinkedField",
  "name": "selfProject",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxIdNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "juristicContactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "docNumber",
    "variableName": "docNumber"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "chartOfAccountCode",
    "storageKey": null
  },
  (v3/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccount",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "withdrawer",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "pettyCashRecord",
      "variableName": "id"
    }
  ],
  "concreteType": "AccountRecordGroupNodeConnection",
  "kind": "LinkedField",
  "name": "pettyCashRecordAccountRecordGroup",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountRecordGroupNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountRecordGroupNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountRecordNodeConnection",
              "kind": "LinkedField",
              "name": "accountRecord",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountRecordNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AccountRecordNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "debit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "credit",
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ChartOfAccountNode",
                          "kind": "LinkedField",
                          "name": "chartOfAccountCode",
                          "plural": false,
                          "selections": (v8/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "UserConfigNode",
  "kind": "LinkedField",
  "name": "userConfig",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "languages",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperPettyCashQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "PettyCashRecordNodeConnection",
        "kind": "LinkedField",
        "name": "pettyCashRecord",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PettyCashRecordNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PettyCashRecordNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SetPettyCashNode",
                    "kind": "LinkedField",
                    "name": "setPettyCash",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v13/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v14/*: any*/),
      (v15/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "wrapperPettyCashQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "PettyCashRecordNodeConnection",
        "kind": "LinkedField",
        "name": "pettyCashRecord",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PettyCashRecordNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PettyCashRecordNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SetPettyCashNode",
                    "kind": "LinkedField",
                    "name": "setPettyCash",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v13/*: any*/),
                      (v10/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v14/*: any*/),
      (v15/*: any*/)
    ]
  },
  "params": {
    "cacheID": "e7ac2893b1000c31b7429f4eec46b59e",
    "id": null,
    "metadata": {},
    "name": "wrapperPettyCashQuery",
    "operationKind": "query",
    "text": "query wrapperPettyCashQuery(\n  $id: ID!\n  $docNumber: String!\n) {\n  selfProject {\n    id\n    name\n    nameEn\n    address\n    addressEn\n    taxIdNumber\n    juristicContactNumber\n    logo\n  }\n  pettyCashRecord(docNumber: $docNumber) {\n    edges {\n      node {\n        id\n        issuedDate\n        docNumber\n        chartOfAccount {\n          id\n          chartOfAccountCode\n          name\n        }\n        description\n        price\n        status\n        setPettyCash {\n          docNumber\n          withdrawer\n          description\n          id\n        }\n      }\n    }\n  }\n  pettyCashRecordAccountRecordGroup(pettyCashRecord: $id) {\n    edges {\n      node {\n        id\n        totalDebit\n        totalCredit\n        accountRecord {\n          edges {\n            node {\n              id\n              debit\n              credit\n              name\n              chartOfAccountCode {\n                id\n                chartOfAccountCode\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  userConfig {\n    id\n    languages\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a8f697057d39ae156cb09aeaaded614f';

module.exports = node;
