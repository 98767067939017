/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OtherExpensePaymentChannelStatus = "ACTIVE" | "PAID" | "%future added value";
export type OtherExpensePaymentChannelStatusSetPettyCash = "VOID" | "WAIT" | "WITHDRAW" | "%future added value";
export type pettyCashRecordListTableOtherExpenseQueryVariables = {|
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  first?: ?number,
  last?: ?number,
  setPettyCash_Id?: ?string,
|};
export type pettyCashRecordListTableOtherExpenseQueryResponse = {|
  +otherExpensePaymentChannelViewer: ?{|
    +allOtherExpensePaymentChannel: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +otherExpense: ?{|
            +id: string,
            +docNumber: string,
            +issuedDate: any,
            +contact: ?{|
              +id: string,
              +refNumber: string,
              +name: string,
            |},
            +unknownContact: ?string,
            +otherExpenseTransaction: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +description: string,
                  +productAndService: ?{|
                    +id: string,
                    +name: string,
                    +productCode: string,
                  |},
                  +chartOfAccount: ?{|
                    +id: string,
                    +name: string,
                    +chartOfAccountCode: string,
                  |},
                |}
              |}>
            |},
          |},
          +description: string,
          +price: number,
          +status: OtherExpensePaymentChannelStatus,
          +statusSetPettyCash: ?OtherExpensePaymentChannelStatusSetPettyCash,
          +setPettyCash: ?{|
            +docNumber: string,
            +description: ?string,
            +withdrawer: ?string,
          |},
        |}
      |}>,
    |}
  |}
|};
export type pettyCashRecordListTableOtherExpenseQuery = {|
  variables: pettyCashRecordListTableOtherExpenseQueryVariables,
  response: pettyCashRecordListTableOtherExpenseQueryResponse,
|};
*/


/*
query pettyCashRecordListTableOtherExpenseQuery(
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $first: Int
  $last: Int
  $setPettyCash_Id: ID
) {
  otherExpensePaymentChannelViewer {
    allOtherExpensePaymentChannel(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last, setPettyCash_Id: $setPettyCash_Id) {
      totalCount
      edges {
        node {
          id
          otherExpense {
            id
            docNumber
            issuedDate
            contact {
              id
              refNumber
              name
            }
            unknownContact
            otherExpenseTransaction {
              edges {
                node {
                  id
                  description
                  productAndService {
                    id
                    name
                    productCode
                  }
                  chartOfAccount {
                    id
                    name
                    chartOfAccountCode
                  }
                }
              }
            }
          }
          description
          price
          status
          statusSetPettyCash
          setPettyCash {
            docNumber
            description
            withdrawer
            id
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "setPettyCash_Id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v6 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "setPettyCash_Id",
    "variableName": "setPettyCash_Id"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "OtherExpenseNode",
  "kind": "LinkedField",
  "name": "otherExpense",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactNode",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "refNumber",
          "storageKey": null
        },
        (v10/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unknownContact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OtherExpenseTransactionNodeConnection",
      "kind": "LinkedField",
      "name": "otherExpenseTransaction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OtherExpenseTransactionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OtherExpenseTransactionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v8/*: any*/),
                (v11/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductAndServiceNode",
                  "kind": "LinkedField",
                  "name": "productAndService",
                  "plural": false,
                  "selections": [
                    (v8/*: any*/),
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "productCode",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChartOfAccountNode",
                  "kind": "LinkedField",
                  "name": "chartOfAccount",
                  "plural": false,
                  "selections": [
                    (v8/*: any*/),
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "chartOfAccountCode",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusSetPettyCash",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "withdrawer",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "pettyCashRecordListTableOtherExpenseQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OtherExpensePaymentChannelViewer",
        "kind": "LinkedField",
        "name": "otherExpensePaymentChannelViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "OtherExpensePaymentChannelNodeConnection",
            "kind": "LinkedField",
            "name": "allOtherExpensePaymentChannel",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OtherExpensePaymentChannelNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OtherExpensePaymentChannelNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v12/*: any*/),
                      (v11/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SetPettyCashNode",
                        "kind": "LinkedField",
                        "name": "setPettyCash",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v11/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "pettyCashRecordListTableOtherExpenseQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OtherExpensePaymentChannelViewer",
        "kind": "LinkedField",
        "name": "otherExpensePaymentChannelViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "OtherExpensePaymentChannelNodeConnection",
            "kind": "LinkedField",
            "name": "allOtherExpensePaymentChannel",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OtherExpensePaymentChannelNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OtherExpensePaymentChannelNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v12/*: any*/),
                      (v11/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SetPettyCashNode",
                        "kind": "LinkedField",
                        "name": "setPettyCash",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v11/*: any*/),
                          (v16/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "26c1692df1842399b328602e697d1d2b",
    "id": null,
    "metadata": {},
    "name": "pettyCashRecordListTableOtherExpenseQuery",
    "operationKind": "query",
    "text": "query pettyCashRecordListTableOtherExpenseQuery(\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $first: Int\n  $last: Int\n  $setPettyCash_Id: ID\n) {\n  otherExpensePaymentChannelViewer {\n    allOtherExpensePaymentChannel(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last, setPettyCash_Id: $setPettyCash_Id) {\n      totalCount\n      edges {\n        node {\n          id\n          otherExpense {\n            id\n            docNumber\n            issuedDate\n            contact {\n              id\n              refNumber\n              name\n            }\n            unknownContact\n            otherExpenseTransaction {\n              edges {\n                node {\n                  id\n                  description\n                  productAndService {\n                    id\n                    name\n                    productCode\n                  }\n                  chartOfAccount {\n                    id\n                    name\n                    chartOfAccountCode\n                  }\n                }\n              }\n            }\n          }\n          description\n          price\n          status\n          statusSetPettyCash\n          setPettyCash {\n            docNumber\n            description\n            withdrawer\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f104e27fe5201fad0579e805fcbac537';

module.exports = node;
