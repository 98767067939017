import {
  commitMutation,

} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";


const mutation = graphql`
    mutation addWithdrawAmountMutation($input: UpdateSetPettyCashInput!){
        updateSetPettyCash(input:$input){
            ok
        }
    }
`;

export default function addWithdrawAmountMutation(variables, uploadables, callback, error_callback) {

  commitMutation(
    environment,
    {
      mutation,
      variables,
      uploadables,
      onCompleted: (response) => {
        callback(response.updateSetPettyCash.ok)
      },
      onError: (err) => {
          error_callback(err);
      },
    },
  )
}
