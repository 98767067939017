import { format } from 'date-fns';
import _ from 'lodash';
import React, { Component } from 'react'
import numberWithCommas from '../../libs/numberWithComma';
import {Translation} from "react-i18next";
import './collection.scss'

class CollectionInvoiceTable extends Component {
    
    render(){

        let invoiceDoc =  ''
        let orderNumber = 0;

        let filter_zero = _.filter(this.props.props.node.recordCollectionLetters.edges, e => e.node.sumTotalRecord > 0)
        return(
            <Translation>
            {  t =>  
            <div className="pl-4 mt-4" id="collection-letter">
                <div className="row col-12 mb-3" style={{marginTop:'48px'}}>
                    <h4>{t("collectionLetter:Overdue details")}</h4>
                    <hr />
                </div>
                <div className="row mt-3 col-12">
                    <div className="table-responsive ">
                        <table className="table table-collection">
                            <thead className="thead-light headTable">
                                <tr style={{fontSize:'16px'}}>
                                    <th className="text-center" width={100}>{t("collectionLetter:No.")}</th>
                                    <th className="text-center">{t("collectionLetter:Invoice No.")}</th>
                                    <th >{t("collectionLetter:List")}</th>
                                    <th >{t("collectionLetter:Description")}</th>
                                    <th className="text-center">{t("collectionLetter:Due Date")}</th>
                                    <th className="text-center">{t("collectionLetter:Overdue (days)")}</th>
                                    <th className="text-center" width={140}>{t("collectionLetter:Outstanding Balance")}</th>
                                    {(this.props.props.node.status === "DRAFT" ) &&
                                        <th/>
                                    }
                                </tr>
                            </thead>
                            <tbody className="tableList">
                                {filter_zero.length > 0  && filter_zero.map((transaction,index) => {
                                    let dateDiff =  (new Date(this.props.props.node.updated).getTime() - new Date(transaction.node.transaction.invoice.dueDate).getTime()) / (1000*60*60*24)
                                    let total = transaction.node.sumTotalRecord;
                                    
                                    let duplicate = true
                                    if(invoiceDoc !== transaction.node.transaction.invoice.docNumber){
                                        invoiceDoc = transaction.node.transaction.invoice.docNumber
                                        duplicate = false
                                    }
                                    if(total > 0) orderNumber += 1
                                    return(
                                        total > 0 &&
                                        <tr key={index}>
                                            <td className="text-center"><span>{orderNumber}</span></td>
                                            <td className="text-center"><span>{!duplicate && invoiceDoc}</span></td>
                                            <td><span>{transaction.node.transaction.productAndService.name}</span></td>
                                            <td><span>{transaction.node.transaction.description}</span></td>
                                            <td className="text-center"><span>{format(transaction.node.transaction.invoice.dueDate,'DD/MM/YYYY')}</span></td>
                                            <td className="text-center"><span style={{color:'#E14558',fontSize:'16px'}}>{Math.round(dateDiff)}</span></td>
                                            <td className="text-right"><span>{numberWithCommas(total,0)}</span></td>
                                            {(this.props.props.node.status === 'DRAFT' && filter_zero.length > 1) &&
                                                <td className="text-center">
                                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_edit' }) &&
                                                        <img src={process.env.PUBLIC_URL + '/images/icons/icon_delete.png'}
                                                            alt="delete" className="delete cursor"
                                                            onClick={() => this.props.onDelete(transaction.node.id)}
                                                        />
                                                    }
                                                </td>
                                            }
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot className="tableList">
                                <tr>
                                    <td colSpan={6}><span>{t("collectionLetter:Total outstanding balance (Baht)")}</span></td>
                                    <td className="text-right"><span>{numberWithCommas(this.props.sumTotal ?? 0)}</span></td>
                                    {this.props.props.node.status === 'DRAFT' && <td/>}
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div> 
            }
            </Translation>
        );
    }
}
export default CollectionInvoiceTable;