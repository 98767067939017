import React from "react";
import Wrapper from "../../components/wrapper";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import MobilePreview from "./mobilePreview";
import _ from 'lodash'
import { commitMutation, fetchQuery } from "react-relay";
import environment from "../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import i18n from "i18next";
import Loading from '../../libs/loading';
import jwtDecode from 'jwt-decode'
import i18next from "i18next";

const mutation = graphql`
    mutation settingFavoriteMutation($input: SetFavoriteAppInput!){
        setFavoriteApp(input:$input){
            ok
        }
    }`;
const query = graphql`
    query settingFavoriteQuery{
        getFavoriteApp
    }`;

const menu = [
    {
        name: 'parcel'
    },
    {
        name: 'bill'
    },
    {
        name: 'survey'
    },
    {
        name: 'booking'
    },
    // {
    //     name: 'meeting'
    // },
    {
        name: 'complaint'
    },
    {
        name: 'emergency'
    },
    {
        name: 'project'
    },
    {
        name: 'visitor'
    },
    {
        name: 'fixrequest'
    },
]

const default_fav = [ 'parcel' ,'bill', 'booking','complaint']



class SettingFavorite extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            favList : default_fav,
            allListMenu : [],
            loadingData: false,
            loading: false,
            token: jwtDecode(window.localStorage.getItem('token'))
        }
    }

    componentDidMount(){
        this.queryData()
    }

    queryData = () => {
        this.setState({
            loadingData : true
        })
        fetchQuery(environment, query, {}).then(data => {
            if(data.getFavoriteApp.length > 0){
                let favList = JSON.parse(data.getFavoriteApp[0])
                this.setState({
                    favList:favList,
                    allListMenu:menu,
                    loadingData:false
                })
            }else{
                this.setState({
                    favList:default_fav,
                    allListMenu:menu,
                    loadingData:false
                })
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({
                favList:default_fav,
                allListMenu:menu,
                loadingData:false
            })
        })
    }

    changeList = (name) => {
        let index = _.indexOf(this.state.favList ,name)
        let favList = _.clone(this.state.favList)

        if(index === -1){
            if(favList.length <= 7){
                favList.push(name)
            }
        }else{
            favList.splice(index,1)
        }
        this.setState({
            favList:favList
        })
    }

    checkChecked = (name) => {
        if(this.state.favList.includes(name)){
            return true
        }else{
            return false
        }
    }

    clear = () => {
        this.setState({
            favList:[]
        })
    }
 
    onSubmit = () => {
        this.setState({
            loading : true
        })
        let selectFourItem = true
        // if(this.state.favList.length < 8){
        //     selectFourItem = false
        // }
        if(this.state.favList.length === 8){
            let variables = {
                input: {
                    favoriteList: JSON.stringify(this.state.favList),
                }
            };
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    // uploadables,
                    onCompleted: (response) => {
                        this.setState({
                            loading : false
                        })
                        if (response.setFavoriteApp.ok) {
                            Swal.fire( i18n.t('settingNote:Save successfully'), '', 'success').then(() => {
                               
                                this.queryData();
                            })
                        } else {
                            Swal.fire( i18n.t('settingNote:Can\'t save'), '', 'warning');

                        }
                    },
                    onError: (err) => {
                        Swal.fire('Error!',  i18n.t('settingNote:Please try again'), 'warning')
                        this.setState({
                            loading : false
                        })
                    },
                },
            )
        }else{
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"),  'กรุณาเลือกรายการให้ครบ 8 รายการ' , 'warning')
            this.setState({
                loading : false
            })
        }
        
    }

    render(){

        
        return(
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid box">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t("MenuName:Menu Moblie Setting")}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>
                        <div className="content-inner" id="favorite">
                            {this.state.loadingData ? 
                            <Loading/>
                                :
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <div className="row d-flex justify-content-center mb-4 mt-4 ">
                                            <div className="col-12  justify-content-center d-flex">
                                                <h6  style={{marginBottom:'24px'}}>
                                                     <Translation>{t => <span>{t("regulation:Juristic Features")}</span>}</Translation>
                                                </h6>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center row" style={{marginBottom:'24px'}}>
                                            {this.state.allListMenu.length > 0 && this.state.allListMenu.map((item,index) => {
                                                return(

                                                    <div className="row col-12 mt-2 mb-2">
                                                        <div className="col-2" />
                                                        <div className="col">
                                                            {
                                                                this.state.token?.site?.includes('sklasmanagement') && 
                                                                 item.name === 'visitor' ? '' 
                                                                 :  <Translation>{t => <span>{t("favoriteIcon:"+item.name)}</span>}</Translation>
                                                            }
                                                        </div>
                                                        <div className="col-3">
                                                            {
                                                                this.state.token?.site?.includes('sklasmanagement') && 
                                                                 item.name === 'visitor' ? '' :
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={() => this.changeList(item.name)}
                                                                    checked={this.checkChecked(item.name)}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                );
                                                })
                                            }
                                        </div>

                                        <div className="mt-4 row">
                                            <div className="col-6 d-flex  justify-content-end">
                                                <button type="button" className="btn-confirm"
                                                    onClick={() => this.onSubmit()} 
                                                    disabled={this.state.loading}> 
                                                    {this.state.loading && <span className="spinner-border spinner-border-sm spinner-border-white align-middle mr-2"/>}
                                                    <Translation>
                                                        {
                                                            t => <span>{t("Allaction:save")}</span>
                                                        }
                                                    </Translation>
                                                </button>
                                            </div>
                                        <div className="col-6 flex-start">
                                                <button type="button" className="btn-clear ml-4"
                                                    onClick={() => this.clear()}
                                                    disabled={this.state.loading}
                                                    > 
                                                    {this.state.loading && <span className="spinner-border spinner-border-sm spinner-border-white align-middle mr-2"/>}
                                                    <Translation>
                                                        {
                                                            t => <span>{t("Allaction:clear all")}</span>
                                                        }
                                                    </Translation>
                                                </button>
                                        </div>
                                        </div>

                                    </div>
                                    <div className="col-6">
                                        <MobilePreview 
                                            favorite = {this.state.favList}
                                        />

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>



        );
    }
}
export default SettingFavorite;