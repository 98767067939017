import React, { Component } from "react";
import SearchSelect from "./searchSelect";
import { Translation } from "react-i18next";

class SelectPaymentMethod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethod: ""
        };
        this.paymentMethod = this.paymentMethod.bind(this)

    }

    paymentMethod(e) {
        let value = e.target.value;
        this.setState({ [e.target.name]: value });

        let event = {
            target: {
                name: this.props.name,
                value: value
            }
        };
        this.props.handleInputChange(event);
    }

    PaymentMethodObjects(payment_method) {

        let payment_method_list = [
            { "node": { label: "-", value: "no" } },
        ]
        if (payment_method) {
            if (payment_method === "2C2P") {
                payment_method_list = [
                    { "node": { label: "Credit Card", value: "credit-card" } },
                    { "node": { label: "Debit Card", value: "debit-card" } },
                    { "node": { label: "True Wallet", value: "true-wallet" } },
                    { "node": { label: "Alipay", value: "alipay" } },
                ]
            }
    
        }
        return payment_method_list
    }

    render() {
        const payment_gateway_method = this.PaymentMethodObjects(this.props.paymentType);
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="col">
                            <p>{t('paymentGateway:type')}</p>
                            <SearchSelect onChange={this.paymentMethod}
                                            value={this.state.paymentMethod}
                                            name="paymentMethod"
                                            placeholder="ประเภท"
                                            queryObject={payment_gateway_method}
                                            keyOfValue="value" require={true}
                                            keyOfLabel="label" />
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default SelectPaymentMethod