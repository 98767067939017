import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import SettingDashboard from './settingDashboard'

import NoMatch from "../components/noMatch"; 
import VisitMange from './manageVisitor/manageVisitor';
import VisitMangeAdd from './manageVisitor/manageVisitorAdd';
import manageVisitorPrint from './manageVisitor/manageVisitorPrint';
import VisitMangeDetail from './manageVisitor/manageVisitorDetail';
import VisitReport from './report/visitReport';
import VisitReportDetail from './report/reportDetail';
import SilgruandreportPrint from './report/reportPrint';

import ParkingFeeReport from './reportParkingFee/report'
import ParkingFeeReportDetail from './reportParkingFee/detail'
import ReportParkingFeePrint from "./reportParkingFee/ReportParkingFeePrint"
import ReportPDF from './reportParkingFee/reportPDF';
class Project extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={SettingDashboard}/>
                
                <PrivateRoute exact path={`${this.props.match.url}/visitormanage/add`} component={VisitMangeAdd}/>
                <PrivateRoute exact path={`${this.props.match.url}/visitormanage/detail/:id`} component={VisitMangeDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/visitormanage/print/:id`} component={manageVisitorPrint}/>
                <PrivateRoute exact path={`${this.props.match.url}/visitormanage/:tabName`} component={VisitMange}/>

                <PrivateRoute exact path={`${this.props.match.url}/report/print/:id`} component={SilgruandreportPrint}/>
                <PrivateRoute exact path={`${this.props.match.url}/report/detail/:id`} component={VisitReportDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/report/:tabName`} component={VisitReport}/>

                {/* parking fee report */}
                <PrivateRoute exact path={`${this.props.match.url}/report-parking-fee`} component={ParkingFeeReport}/> 
                <PrivateRoute exact path={`${this.props.match.url}/report-parking-fee/detail/:id?`} component={ParkingFeeReportDetail}/> 
                <PrivateRoute exact path={`${this.props.match.url}/report-parking-fee/receipt/:id?`} component={ReportParkingFeePrint}/> 
                
                {/* parking fee report pdf */}
                <PrivateRoute exact path={`${this.props.match.url}/report-parking-fee/reportPDF`} component={ReportPDF}/> 
                
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Project;