import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../env/environment";

const query = graphql`
    query getCurrentUserQuery{
        current {
            id
            image
        }
    }
`;

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        fetchQuery(environment, query).then(data => {
            resolve(data.current)
        });
    });

};

export default getCurrentUser;
