import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper";
import _ from "lodash";
import SettingInvoiceTermsListTable from "./settingInvoiceListTable";
import AccountingTopMenuNavigation from "../../accounting/accountingTopMenuNavigation";
import "./style/settingInvoiceTermsList.scss";
import i18n from "i18next";
import { graphql } from "babel-plugin-relay/macro";

const query = graphql`
    query settingInvoiceListQuery(
        $search: String
        $first: Int
        $last: Int
        ) {
        allPreInvoiceGroup(
            search:$search
            first: $first
            last: $last
            ){
            totalCount
            edges{
                node{
                    id
                    contactCode
                    status
                    creator
                    updated
                    contact{
                        id
                        name
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

const SettingInvoiceTermsList = () => {

    const [search, setSearch] = useState("")
    const [sendsearch, setSendsetSearch] = useState("")
    
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            setSendsetSearch(search);
        }
       
    }

    const handleSubmit = () => {
        setSendsetSearch(search)
    }

    return (
        <Wrapper>
            <Header />
            <Sidebar />
            <Translation>
                {(t) => (
                    <WrapperContent>
                     <AccountingTopMenuNavigation mini={true}/>
                        {/* <div className="div-setting-invoice" /> */}
                        <div className="container-fluid box">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/setting">
                                            <img
                                                src={process.env.PUBLIC_URL + "/images/icons/back.png"}
                                                alt="back"
                                                className="back"
                                            />
                                        </Link>
                                        <span className="text-global-header">{t("setting:settingInvoiceTerms")}</span>
                                    </h3>
                                </div>
                            </div>
                            <div className="content-inner mx-2">
                                <div className="grid">
                                    <div>
                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'preinvoicegroup_create'}) &&
                                            <Link to="/setting/invoice-setting-terms/form/select">
                                                <button type="button" className="btn btn-new-search add float-right ml-2">
                                                    <img src={process.env.PUBLIC_URL + "/images/icons/plus.png"} alt="plus" />
                                                    <Translation>
                                                        {(t) => (
                                                            <span className="text-primary-button">
                                                                {t("settingInvoiceTerms:createInvoiceTerms")}
                                                            </span>
                                                        )}
                                                    </Translation>
                                                </button>
                                            </Link>
                                        }
       
                                        {/* <button type="button" className="btn btn-border add float-right">
                                            <img src={process.env.PUBLIC_URL + "/images/icons/printer.png"} alt="printer" />
                                            <Translation>
                                                {(t) => <span className="text-second-button">{t("settingInvoiceTerms:print")}</span>}
                                            </Translation>
                                        </button> */}
                                    </div>
                                </div>
                                <div className="grid mb-6 mt-6">
                                    <div className="calendars-wrapper">
                                        <input type="text" name="text" placeholder={i18n.t("settingInvoiceTerms:Enter code, room number or name")} className="calendars-wrapper colorborder form-controls float-right text-search"
                                            onChange={(e) => setSearch(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                          
                                        <div className="calendars float-right">
                                        <button
                                            type="submit"
                                            className="btn"
                                            name="search"
                                            onClick={handleSubmit} style={{display:'contents'}}
                                            > 
                                             <img src={process.env.PUBLIC_URL + "/images/icons/search-outline.png"} className="search-outline" alt="search" />
                                        </button>
                                        </div>
                                    </div>
                                </div>
                                
                                <>
                                    <SettingInvoiceTermsListTable
                                        query={query}
                                        search={sendsearch}
                                        //เงื่อนไข SENA
                                        developer={_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'developer'])?.menu_on  ? true : false}
                                        />
                                </>
                            </div>
                        </div>
                    </WrapperContent>
                )}
            </Translation>
        </Wrapper>
    );
};

export default SettingInvoiceTermsList;
