import React, {Component} from 'react';
import ResultLotteryAwardTrue from './resultLotteryAwardTrue'
import ResultLotteryAwardFalse from './resultLotteryAwardFalse'
import { Link } from "react-router-dom";
import Loading from "../libs/loading";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import './lottery.scss'

class ResultLotteryAward extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status_data:[],
            result:[],
            loading: true
        };
    }

    componentDidMount() {
        this.getcheckLotteryResult()
     }

    getcheckLotteryResult(){
        var raw = JSON.stringify({
            number: this.props.location.state.transaction_list,
            period_date: this.props.location?.state?.period_date
        });

            fetch("https://svmsupport.silverman.cloud/open/checking/getcheckLotteryResult", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', 
                },
                body: raw
            })
                .then((res) => res.json())
                .then((data) => {
                    if(data){
                        this.setState({
                            result:data?.response?.result,
                            loading:false
                        })
                    }
                
            });
    }
    render() {
        let status_type = null
        let periodDateinThai = format(new Date(this.props.location.state.period_date).setFullYear(parseInt(new Date().getFullYear()) + 543), "DD MMM YYYY", { locale: thLocale})

        let myLang = this.props.match?.params?.lang;
        return (
            <div id="wrapper-content">
                <div id='svm-lottery'>
                    <form>
                    <Link to="/lottery-award/">
                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'} alt="back" className="back" style={{padding:'20px'}} />
                    </Link>
                        <div className='row'>
                            <div className='col text-center'>
                                <h4> {myLang === 'en' ? 'Results' : 'ผลการตรวจสลากหมายเลข'}
                                    <p>{myLang === 'en' ? 'Date' : 'งวดประจำวันที่ '} {periodDateinThai}</p>
                                </h4>
                            </div>
                        </div>
                    </form>
                    <div className='lottery-award-content ml-4 mr-4'>
                        <div>
                            {
                                this.state.loading ?
                                <Loading/> :
                                this.state?.result?.map((lottery)=>{
                                    status_type = lottery.statusType
                                    return(
                                        <div className='p-2 mt-6' style={{backgroundColor:'#FFF'}}>
                                        {
                                            status_type === 1 ? <ResultLotteryAwardTrue number={lottery.number} status_data={lottery.status_data}/> 
                                            : <ResultLotteryAwardFalse number={lottery.number}/>
                                        }
                                        </div>
                                    )
                             })
                            }
                        </div>
                        <div className='row justify-content-center pt-8'>
                           <div className='col text-center mb-8'>
                           <Link to="/lottery-award/step">
                            <button className="btn btn-primary" style={{width:'320px'}}>
                                {myLang === 'en' ? 'How to get the award' : 'ขั้นตอนการรับรางวัล'}
                            </button>
                            </Link>
                           </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default ResultLotteryAward;