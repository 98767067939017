import {fetchQuery} from "relay-runtime";
import environment from "../../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import _ from 'lodash';

const query = graphql`
    query bankAccountQuery($first: Int, $last: Int , $depositStatus: String, $juristic: ID) {

        bankAccountViewer {
            allBankAccount(status:true, accountType_In : "fixed_deposit", first:$first , last:$last){
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountNameEn
                        accountType
                        receivePayment
                        makePayment
                        mobilePayment
                        crossBilling
                        bankName
                        bankLogo
                        branch
                        status
                        bankUnit (depositStatus: $depositStatus){
                            edges{
                                node{
                                    id
                                    automaticCal
                                    issuedDate
                                    dueDate
                                    interestPerYear
                                    price
                                    calculationDate
                                    depositStatus
                                    bankNotification(juristic: $juristic) {
                                        edges {
                                            node {
                                                id
                                                status
                                            }
                                        }
                                    }
                                } 
                            } 
                        }
                    }
                }
                totalCount
            }
        }
    }`;

export default async function bankAccountQuery(id,first,last,status) {
   return  await fetchQuery(environment, query, 
    {
        first : first ,
        last : last ,
        depositStatus : status ,
        juristic : id
    }).then((data) => {
        if(data.bankAccountViewer.allBankAccount.edges){
            let depositBank = []
            let totalCount = 0
            if(status === 'finished'){
                depositBank =  _.filter(data.bankAccountViewer.allBankAccount.edges, bank => {
                    return bank.node.bankUnit.edges.length > 0
                })
                totalCount = depositBank.length
            }else{
                depositBank = data.bankAccountViewer.allBankAccount.edges
                totalCount = data.bankAccountViewer.allBankAccount.totalCount
            }
            let data_list = {
                depositBank : depositBank,
                totalCount : totalCount
            }
            return data_list
        }
        

    })
}