/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type navigationQueryVariables = {||};
export type navigationQueryResponse = {|
  +getNotification: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
  +getNotificationAccounting: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
  +getNotificationAdvance: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
  +getNotificationContractExpense: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
  +getNotificationProcurementForm: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
  +getNotificationWaitPurchaseOrderes: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
  +myUser: ?{|
    +juristic: ?{|
      +id: string
    |}
  |},
  +ownershipSummaryPercent: ?any,
  +allNoticeForeignOwnership: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +percentNoti: number,
      |}
    |}>,
  |},
  +countNotiCollection: ?{|
    +countNoti: ?number
  |},
  +sumJuristicNews: ?number,
|};
export type navigationQuery = {|
  variables: navigationQueryVariables,
  response: navigationQueryResponse,
|};
*/


/*
query navigationQuery {
  getNotification {
    type
    count
  }
  getNotificationAccounting {
    type
    count
  }
  getNotificationAdvance {
    type
    count
  }
  getNotificationContractExpense {
    type
    count
  }
  getNotificationProcurementForm {
    type
    count
  }
  getNotificationWaitPurchaseOrderes {
    type
    count
  }
  myUser {
    juristic {
      id
    }
    id
  }
  ownershipSummaryPercent
  allNoticeForeignOwnership {
    totalCount
    edges {
      node {
        id
        percentNoti
      }
    }
  }
  countNotiCollection {
    countNoti
  }
  sumJuristicNews
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationType",
  "kind": "LinkedField",
  "name": "getNotification",
  "plural": true,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationType",
  "kind": "LinkedField",
  "name": "getNotificationAccounting",
  "plural": true,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationType",
  "kind": "LinkedField",
  "name": "getNotificationAdvance",
  "plural": true,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationType",
  "kind": "LinkedField",
  "name": "getNotificationContractExpense",
  "plural": true,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationType",
  "kind": "LinkedField",
  "name": "getNotificationProcurementForm",
  "plural": true,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationType",
  "kind": "LinkedField",
  "name": "getNotificationWaitPurchaseOrderes",
  "plural": true,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "JuristicNode",
  "kind": "LinkedField",
  "name": "juristic",
  "plural": false,
  "selections": [
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ownershipSummaryPercent",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "NoticeForeignOwnershipNodeConnection",
  "kind": "LinkedField",
  "name": "allNoticeForeignOwnership",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NoticeForeignOwnershipNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NoticeForeignOwnershipNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentNoti",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "CountNotiCollection",
  "kind": "LinkedField",
  "name": "countNotiCollection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countNoti",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sumJuristicNews",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "navigationQuery",
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "myUser",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "navigationQuery",
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "myUser",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ]
  },
  "params": {
    "cacheID": "36adeef080686373a48d4c0d668dd690",
    "id": null,
    "metadata": {},
    "name": "navigationQuery",
    "operationKind": "query",
    "text": "query navigationQuery {\n  getNotification {\n    type\n    count\n  }\n  getNotificationAccounting {\n    type\n    count\n  }\n  getNotificationAdvance {\n    type\n    count\n  }\n  getNotificationContractExpense {\n    type\n    count\n  }\n  getNotificationProcurementForm {\n    type\n    count\n  }\n  getNotificationWaitPurchaseOrderes {\n    type\n    count\n  }\n  myUser {\n    juristic {\n      id\n    }\n    id\n  }\n  ownershipSummaryPercent\n  allNoticeForeignOwnership {\n    totalCount\n    edges {\n      node {\n        id\n        percentNoti\n      }\n    }\n  }\n  countNotiCollection {\n    countNoti\n  }\n  sumJuristicNews\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ba32a855cd41ea8b228f2a8308ee3298';

module.exports = node;
