import axios from "axios";

async function LotteryLog(data){
    let token_id = localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };

    return axios.post(`https://svmsupport.silverman.cloud/open/lottery`, data, {
        headers: header,
    });
}

export default {LotteryLog}