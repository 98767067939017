/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type notificationQueryVariables = {|
  id?: ?string,
  types?: ?string,
  payGroup?: ?string,
  first?: ?string,
  last?: ?string,
  readStatus?: ?string,
|};
export type notificationQueryResponse = {|
  +notifications: ?any
|};
export type notificationQuery = {|
  variables: notificationQueryVariables,
  response: notificationQueryResponse,
|};
*/


/*
query notificationQuery(
  $id: ID
  $types: String
  $payGroup: String
  $first: String
  $last: String
  $readStatus: String
) {
  notifications(first: $first, last: $last, residentId: $id, types: $types, payGroup: $payGroup, readStatus: $readStatus)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payGroup"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "readStatus"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "types"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "payGroup",
        "variableName": "payGroup"
      },
      {
        "kind": "Variable",
        "name": "readStatus",
        "variableName": "readStatus"
      },
      {
        "kind": "Variable",
        "name": "residentId",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "types",
        "variableName": "types"
      }
    ],
    "kind": "ScalarField",
    "name": "notifications",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "notificationQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "notificationQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "afdeb599b721d3c02e2e47dc506a1e4a",
    "id": null,
    "metadata": {},
    "name": "notificationQuery",
    "operationKind": "query",
    "text": "query notificationQuery(\n  $id: ID\n  $types: String\n  $payGroup: String\n  $first: String\n  $last: String\n  $readStatus: String\n) {\n  notifications(first: $first, last: $last, residentId: $id, types: $types, payGroup: $payGroup, readStatus: $readStatus)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8128b08efdc89105b4229acddcb0f66d';

module.exports = node;
