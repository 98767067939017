import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper";
import _ from "lodash";
import Navigation from './navigation';
import SettingInvoiceTermsDetailTable from './settingInvoiceDetailTable';
import AccountingTopMenuNavigation from "../../accounting/accountingTopMenuNavigation";
import "./style/SettingInvoiceCreateForm.scss"
import DatePicker from "react-datepicker";

import { graphql } from "babel-plugin-relay/macro";
import environment from '../../env/environment';
import { fetchQuery } from 'relay-runtime';
import revenueContractAPI from '../../developer/revenueContract/revenueContractAPI';

const query = graphql`
    query settingInvoiceDatailQuery(
        $id: ID!
        ) {
        allPreInvoiceGroup(id:$id){
            totalCount
            edges{
                node{
                    id
                    contactCode
                    status
                    effectDate
                    creator
                    updated
                    contact{
                        id
                        name
                        refNumber
                        firstName
                        lastName
                        residential {
                            id
                            conveyance
                            size
                        }
                    }
                    preInvoice{
                        edges{
                            node{
                                id
                                amount
                                unitItems
                                incomeAvg
                                invoiceAdvance
                                billingPeriod
                                billingPeriodList
                                rentPay
                                agentPay
                                status
                                edite
                                productAndService{
                                    id
                                    productCode
                                    name
                                    description
                                    price
                                    type
                                    chartOfAccount{
                                        id
                                        chartOfAccountCode
                                        name
                                    }
                                    customerChartOfAccount{
                                        id
                                        chartOfAccountCode
                                        name
                                    }
                                    creditorChartOfAccount{
                                        id
                                        chartOfAccountCode
                                        name           
                                    }
                                    servicePricing {
                                        edges {
                                            node {
                                                id
                                                periodAdjustment
                                                pricingType
                                                deferredIncome{
                                                    chartOfAccountCode
                                                    name
                                                }
                                                receiveDeposit{
                                                    chartOfAccountCode
                                                    name
                                                }
                                            }
                                        }
                                    }
                                    servicePricingEachUnit{
                                        edges {
                                            node {
                                                id
                                                pricing
                                                unit{
                                                    id
                                                }
                                            }
                                        }                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
`;

const SettingInvoiceDetail = (props) => {
    const [PreInvoiceGroup, setPreInvoiceGroup] = useState(null);
    const [status, setStatus] = useState(false)
    const [RoomName, setRoomName] = useState(null)
    const [freebies, setFreebies] = useState(null)
    const [condition, setCondition] = useState(null)
    const [residentialID , setResidentialID] = useState(null) 
    const [id, setID] = useState(props.match.params.id)
    useEffect(() => {
        queryPreInvoiceGroup()
    }, [])

    const queryPreInvoiceGroup = () => {
        fetchQuery(environment, query, {
            id:props.match.params.id
        }).then(data => {
            setPreInvoiceGroup(data.allPreInvoiceGroup.edges)
            setStatus(data.allPreInvoiceGroup.edges[0].node.status)
            // setRoomName(data.allPreInvoiceGroup.edges[0].node.contact.name + " " + data.allPreInvoiceGroup.edges[0].node.contact.refNumber)
            setRoomName(data.allPreInvoiceGroup.edges[0].node.contact.name)
            setResidentialID(data.allPreInvoiceGroup.edges[0].node.contact.residential.id)
            revenueContractAPI.getFreebies(localStorage.getItem("token"),atob(data.allPreInvoiceGroup.edges[0].node.contact.residential.id).split(":")[1]).then((response) => {
                if(response.data){
                    setFreebies(response.data.obligationDeveloper)
                }
            })
            revenueContractAPI.getCondition(localStorage.getItem("token"),atob(data.allPreInvoiceGroup.edges[0].node.contact.residential.id).split(":")[1]).then((response) => {
                if(response.data){
                    setCondition(response.data.obligationTenant)
                }
            })
        })
        
    }
    const path = props.match.params.status
    const type = props.match.params.status === 'developer' ? 'freebies' : 'condition'

    return (
        <Wrapper>
        <Header />
        <Sidebar />
        <Translation>
            {(t) => (
            <WrapperContent>
              <AccountingTopMenuNavigation mini={true}/>
                {/* <div className="div-setting-invoice"/> */}
                <div className="container-fluid box">
                    <div className="row justify-content-between">
                        <div className="col">
                            <h3 className="mb-4">
                                <Link to="/setting/invoice-setting-terms">
                                <img
                                    src={process.env.PUBLIC_URL + "/images/icons/back.png"}
                                    alt="back"
                                    className="back"
                                />
                                </Link>
                                <span>{RoomName}</span>
                            </h3>
                        </div>
                    </div>
                    <div className="content-inner mx-2">
                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'developer'])?.menu_on &&
                            <div className="grid">
                                <div>
                                    <Navigation id={id} status={status}/>
                                </div>
                            </div>
                        }
                        <div className="mt-6">
                            <div className="row d-flex align-items-center mb-4">
                            {props.match.params.status === "developer" ?
                                <h4 className="mb-0 mx-4">{t("settingAll:List gifts")}</h4>:
                                <h4 className="mb-0 mx-4">{t("settingAll:Condition lists")}</h4>}
                                {/* ของพี่โอ้ก */}
                                {props.match.params.status === 'not' ?
                                _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'preinvoicegroup_edit'}) &&
                                    <Link to={"/setting/invoice-setting-terms/edit/" + path + "/" + props.match.params.id}>
                                        <button className="d-flex align-items-center btn-event">
                                            <img
                                                src={process.env.PUBLIC_URL + "/images/icons/edit-outline.png"}
                                                alt="edit"
                                                className="edit mr-2"
                                            />
                                            <span className="text-blue">{t("Allaction:edit")}</span>
                                        </button>
                                    </Link>
                                :
                                    type === 'freebies' ?
                                        (freebies && freebies.length > 0) && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'preinvoicegroup_edit'}) &&
                                        <Link to={"/setting/invoice-setting-terms/revenue/" + residentialID + "/" + type + "/" + id}>
                                            <button className="d-flex align-items-center btn-event">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/images/icons/edit-outline.png"}
                                                    alt="edit"
                                                    className="edit mr-2"
                                                />
                                                <span className="text-blue">{t("Allaction:edit")}</span>
                                            </button>
                                        </Link>
                                    :
                                    (condition && condition.length > 0) && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'preinvoicegroup_edit'}) &&
                                    <Link to={"/setting/invoice-setting-terms/revenue/" + residentialID + "/" + type + "/" + id}>
                                        <button className="d-flex align-items-center btn-event">
                                            <img
                                                src={process.env.PUBLIC_URL + "/images/icons/edit-outline.png"}
                                                alt="edit"
                                                className="edit mr-2"
                                            />
                                            <span className="text-blue">{t("Allaction:edit")}</span>
                                        </button>
                                    </Link>
                                }
                            </div>
                            {props.match.params.status === "not" && PreInvoiceGroup &&
                            <div className="row d-flex align-items-center mb-5">
                                <h5 className="mb-0 mx-4">{t("settingAll:Billing start date")}:</h5>
                                <button className="btn-event">
                                    <div className="calendars-wrapper">
                                        <DatePicker
                                            id="calDepreciationDate"
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control float-left colorborder"
                                            selected={ PreInvoiceGroup[0]?.node.effectDate ? new Date(PreInvoiceGroup[0].node.effectDate) : null }
                                            disabled
                                        />
                                        <div className="calendars float-right">
                                            <img className="icon"
                                                src={
                                                process.env.PUBLIC_URL +
                                                "/images/icons/calendar-outline.png"
                                                }
                                                alt="calendars"
                                            />
                                        </div>
                                  </div>
                                </button>
                            </div>}
                            {props.match.params.status === "developer" &&
                            <div className="row d-flex align-items-center mb-5">
                                <h5 className="mb-0 mx-4" style={{color: '#F43853'}}>{t("settingAll:The developer is responsible for these expenses on behalf of the customer.")}</h5>
                            </div>}
                            {props.match.params.status === "owner" &&
                            <div className="row d-flex align-items-center mb-5">
                                <h5 className="mb-0 mx-4" style={{color: '#F43853'}}>{t("settingAll:Resident is responsible for these expenses.")}</h5>
                            </div>}
                        </div>
                        <>
                            {PreInvoiceGroup &&
                                <SettingInvoiceTermsDetailTable preInvoiceGroup={PreInvoiceGroup} freebies={freebies}
                                condition={condition} status={props.match.params.status} residentialID={residentialID} queryPreInvoiceGroup={queryPreInvoiceGroup}/>
                            }
                        </>
                    </div>
                </div>
            </WrapperContent>
            )}
        </Translation>
    </Wrapper>
    )
}

export default SettingInvoiceDetail