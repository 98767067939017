import React, {Component} from 'react';
import {Switch} from "react-router-dom";
import PrivateRoute from '../../libs/privateRoute';
import SettingWalkin from './settingWalkin'
import WrapperSettingWalkinQRcode from './wrapperSettingWalkinQRcode'

class SettingWalkinIndex extends Component {

    render() {
    return (
        <Switch>
            <PrivateRoute exact  path={`${this.props.match.url}`} component={SettingWalkin}/>
            <PrivateRoute exact  path={`${this.props.match.url}/qrcode`} component={WrapperSettingWalkinQRcode}/>
        </Switch>
    )
    }
}

export default SettingWalkinIndex;