import React, { Component } from 'react';
import Swal from "sweetalert2";
import i18n from "i18next";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { encode } from 'base-64';
import { Redirect } from 'react-router'
import Select from 'react-select';
import format from 'date-fns/format';
import apiSing from '../../../api/sing';

import _ from 'lodash';
import i18next from 'i18next';

class fixRequestListCommonDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loading: true,
            typeFix: "1",
            Requsetfrom: "Tenant",
            RequestBy: "เลือกชื่อผู้แจ้ง",
            RequestKey: "",
            isRequestKey: false,
            pageX: "0px",
            pageY: "0px",
            display: "none",
            file: [],
            data: {}
        }
        // this.printMousePosX = this.printMousePosX.bind(this)
        // this.printMousePosY = this.printMousePosY.bind(this)
    }
    componentWillUnmount() { }
    componentWillMount() {
        this.getDataId()
        this.getWorkType()

    }
    getWorkType() {
        apiSing.get_task_type_service("public").then((res) => {
            let data = []
            if (res.data.all_task_type) {
                data = res.data.all_task_type
                res.data.all_task_type.map((n, index) => {
                    let image = "/images/typeIcon/sing/singCommon/" + n.task_type_name + '.png'
                    _.set(data, '[' + index + ']images', image)
                })
                this.setState({ workingTypeAll: data })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }
    getDataId() {
        let idd = this.props.match.params.id
        let data = {
            index_first: "",
            index_last: "",
            page: "",
            status: "",
            id: idd,
            person_working: "",
            search: "",
            start_date: "",
            end_date: ""
        }
        apiSing.getrepairs_room_view(data).then((res) => {
            if (res.data.data) {
                let data = res.data.data[0]
                this.setState({ data: data, loading: false })
            }
        })
    }

    printMousePos(e) {
        var my_div = document.getElementById('my_div_id');
        var box = my_div.getBoundingClientRect();
        var cursorX = e.clientX - box.left - 10;
        var cursorY = e.clientY - box.top - 10;

        this.setState({
            pageX: cursorX + "px",
            pageY: cursorY + "px",
            mark_x: (e.clientX).toString(),
            mark_y: (e.clientY).toString(),
            mark: e.clientX + "," + e.clientY
        })
    }

    render() {
        let zone = [{ value: '', label: this.state.data?.zone }]
        let detail = [{ value: '', label: this.state.data?.details }]
        let material = [{ value: '', label: this.state.data?.material }]
        let floor = [{ value: '', label: this.state.data?.floor }]
        let tenant_contact = this.state.data?.tenant_contact ?
            this.state.data?.tenant_contact?.name_title + this.state.data?.tenant_contact?.first_name + " " + this.state.data?.tenant_contact?.last_name :
            this.state.data?.other_contact
        let juristic = [{ value: '', label: this.state.data?.juristic }]
        let description = this.state.data.description ? this.state.data.description : '-'

        // let isStaff = this.state.data.other_contact?.substring(0, 2)
        // isStaff = isStaff === "Y-" ? true : false
        let isStaff = this.state.data?.tenant_contact? false : true
        let isWorksheet = this.state.data?.worksheet?.length > 0 ? true : false

        if (this.state.redirect) {
            return <Redirect to="/contact/fix-requests/fortenant/review" />;
        }

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>

                            <div className="container-fluid box fixRequestFast" >
                                {
                                    this.state.loading == false ?
                                        <div className='createFixrequest'>
                                            <div className="row justify-content-between">
                                                <div className="col">
                                                    <h3 className="mb-4">
                                                        <Link to=
                                                            {this.props.match.params.detail === "detail" ?
                                                                "/contact/fix-requests/forcommon/review" :
                                                                "/contact/fix-requests/report/forcommon/all"
                                                            }>
                                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                alt="back" className="back" />
                                                        </Link>
                                                        <span style={{ fontWeight: '400' }}>
                                                            {isWorksheet ? this.state.data.worksheet[0]?.doc_number : t("PageList:Click Read More")+t("PageList:Common")}</span>

                                                    </h3>
                                                </div>
                                            </div>
                                            {
                                                <React.Fragment>
                                                    <div className='row'>
                                                        <div className="col-10 mx-auto">
                                                            <form onSubmit={this.onSubmit}>
                                                                <div>
                                                                    <br /><br />
                                                                    <React.Fragment>
                                                                        <section className='HeaderForCommon'>
                                                                            <React.Fragment>
                                                                                <p style={{ fontSize: '18px' }}>{t("PageList:Create Subject")}</p>
                                                                                <div style={{ display: 'inline' }}>
                                                                                    <label className='labelInput'>
                                                                                        <input type={"radio"} name='Requsetfrom' disabled
                                                                                            checked={!isStaff} />
                                                                                        {t("PageList:Fix Request From Tenant")}
                                                                                    </label>

                                                                                    <label style={{ marginLeft: "190px" }}></label>

                                                                                    <label className='labelInput'>
                                                                                        <input type={"radio"} name='Requsetfrom' disabled
                                                                                            checked={isStaff} />
                                                                                         {t("PageList:Fix Request From Juristic")}
                                                                                    </label>
                                                                                </div>
                                                                                <hr />
                                                                            </React.Fragment>
                                                                        </section>

                                                                        <section className='GeneralInformation'>
                                                                            <h3 style={{ fontWeight: '400' }}>{t("silvermanguard:GeneralInformation")}
                                                                                <label style={{ float: 'right' }} >{t("claimReport:status")}:   &nbsp;
                                                                                    {
                                                                                        this.state.data.insure_status === "have" ||  this.state.data.create_by === "fast" ?
                                                                                            <span className={'btnStatusDetailsChatFast'}>
                                                                                                <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/" + this.state.data.status + ".png"} />
                                                                                                &nbsp;
                                                                                                {
                                                                                                    this.state.data.status === 'new' ? t("claimRequst:new") :
                                                                                                        this.state.data.status === 'appointment' ? t("claimRequst:Appointment") :
                                                                                                            this.state.data.status === 'repaired' ?  t("claimRequst:Repaired"):
                                                                                                                    this.state.data.status === 'edited' ? t("claimRequst:Fixed") :
                                                                                                                        this.state.data.status === "finish" ? t("claimRequst:Finished") : t("PageList:Cancled")
                                                                                                }
                                                                                            </span>
                                                                                            :
                                                                                            <span className={'btnStatusDetail ' + this.state.data.status}>
                                                                                                <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/" + this.state.data.status + ".png"} />
                                                                                                &nbsp;
                                                                                                {
                                                                                                    this.state.data.status === "review" ? t("PageList:Review") :
                                                                                                        this.state.data.status === "pending" ? t("PageList:Pending") :
                                                                                                            this.state.data.status === "approve" ? t("PageList:Approve") :
                                                                                                                this.state.data.status === "finish" ? t("claimRequst:Finished")  : t("PageList:Cancled")
                                                                                                }


                                                                                            </span>


                                                                                    }

                                                                                </label>
                                                                            </h3>
                                                                            <hr />

                                                                            {
                                                                                !isStaff ?
                                                                                    <div>
                                                                                        <label style={{ marginTop: '24px' }}>{t("PageCreate:Find Room")} </label>
                                                                                        <input type="text" className='form-control inputBox'
                                                                                            defaultValue={this.state.data?.residential?.name || "-"} readOnly={true}/>

                                                                                        {
                                                                                            this.state.data?.tenant_contact ?
                                                                                                <div>
                                                                                                    <label>{t("PageCreate:First Name - Last Name")}</label>
                                                                                                    <input type="text" name='other_contact' className='form-control inputBox' placeholder='ระบุชื่อผู้แจ้ง'
                                                                                                        defaultValue={tenant_contact} readOnly={true} />
                                                                                                    <label>{t("PageCreate:Phone")}</label>
                                                                                                    <input type="text" name='phone_contact' className='form-control inputBox' placeholder='XXX-XXX-XXXX'
                                                                                                        defaultValue={this.state.data?.phone_contact} readOnly={true} />
                                                                                                </div>

                                                                                                :
                                                                                                <div>
                                                                                                    <label>{t("PageCreate:Choose Name Request")}</label>
                                                                                                    <select name='RequestBy' className="form-control inputBox" disabled={true} value={"other"}>
                                                                                                        <option value={"other"}>{t("resident:Specify")}</option>
                                                                                                    </select>

                                                                                                    <label>{t("PageCreate:First Name - Last Name")}</label>
                                                                                                    <input type="text" name='other_contact' className='form-control inputBox' placeholder={t("PageCreate:First Name - Last Name")}
                                                                                                        defaultValue={this.state.data?.other_contact} readOnly={true} />
                                                                                                    <label>{t("PageCreate:Phone")}</label>
                                                                                                    <input type="text" name='phone_contact' className='form-control inputBox' placeholder='XXX-XXX-XXXX'
                                                                                                        defaultValue={this.state.data?.phone_contact} readOnly={true} />

                                                                                                    <label>{"E-mail"}</label>
                                                                                                    <input type="text" name='phone_contact' className='form-control inputBox' placeholder='XXX-XXX-XXXX'
                                                                                                        defaultValue={this.state.data?.email_contact} readOnly={true} />
                                                                                                </div>
                                                                                        }

                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        <label>{t("PageCreate:Choose Name Request")}</label>
                                                                                        <input type="text" name='other_contact' className='form-control inputBox'
                                                                                            defaultValue={this.state.data?.juristic.first_name + " " + this.state.data?.juristic.last_name}
                                                                                            readOnly={true} />

                                                                                        <label>{t("PageCreate:Phone")}</label>
                                                                                        <input type="text" name='phone_contact' className='form-control inputBox' placeholder='XXX-XXX-XXXX'
                                                                                            defaultValue={this.state.data?.phone_contact} readOnly={true} />


                                                                                    </div>


                                                                            }
                                                                        </section>

                                                                        <section className='WorkingType'>
                                                                            <label>{t("PageCreate:Category")}  <span className='text-red'>*</span></label><br />
                                                                            <div>
                                                                                {
                                                                                    this.state.workingTypeAll?.map((n, index) => {
                                                                                        return (
                                                                                            <React.Fragment key={index}>
                                                                                                <div
                                                                                                    className={this.state.data?.working_type === n.task_type_name_th ? 'divTypeCar actived' : 'divTypeCar '}
                                                                                                // onClick={() => this.setState({ working_type: n.task_type_name_th , task_id : n.task_type_id})

                                                                                                >
                                                                                                    <div className='imgTypeSelect'>
                                                                                                        <img src={process.env.PUBLIC_URL + n.images} className='imgType' /><br />
                                                                                                        {
                                                                                                            this.state.data?.working_type === n.task_type_name_th &&
                                                                                                            <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Gray.png'} className='selected' />
                                                                                                        }
                                                                                                    </div>
                                                                                                    <p>{n.task_type_name_th}</p>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>

                                                                            <div style={{ clear: 'both' }}></div>
                                                                            <br />
                                                                        </section>

                                                                        <section className='TypeOject'>
                                                                            <label style={{ marginTop: '10px' }}>{t("PageCreate:Work Type / Material")} <span className='text-red'>*</span></label>
                                                                            <Select
                                                                                classNamePrefix='inputBox'
                                                                                isDisabled={true}
                                                                                isSearchable={false}
                                                                                value={material[0]}
                                                                                option={material}
                                                                            />
                                                                        </section>

                                                                        <section className='DetailWorkingType'>
                                                                            <label>{t("PageCreate:Detail Fix Request")} <span className='text-red'>*</span></label>
                                                                            <Select
                                                                                classNamePrefix='inputBox'
                                                                                isDisabled={true}
                                                                                isSearchable={false}
                                                                                value={detail[0]}
                                                                                option={detail}
                                                                            />

                                                                        </section>

                                                                        <section className='floor'>
                                                                            <label>{t("PageCreate:Floor")}</label>
                                                                            <Select
                                                                                classNamePrefix='inputBox'
                                                                                isDisabled={true}
                                                                                isSearchable={false}
                                                                                value={floor[0]}
                                                                                option={floor}
                                                                            />

                                                                            <section className='zone'>
                                                                                <label>{t("PageCreate:Zone")}</label>
                                                                                <Select
                                                                                    classNamePrefix='inputBox'
                                                                                    isDisabled={true}
                                                                                    isSearchable={false}
                                                                                    value={zone[0]}
                                                                                    option={zone}
                                                                                />

                                                                            </section>

                                                                            {
                                                                                this.state.data.image ?
                                                                                    <div className='imgProductBox' id='my_div_id'
                                                                                        style={{
                                                                                            backgroundPositionX: parseInt(this.state.data.mark_x / 2 - 6) + "px",
                                                                                            backgroundPositionY: parseInt(this.state.data.mark_y / 2 - 0.97 - 8) + "px"
                                                                                        }}
                                                                                    >
                                                                                        <img src={this.state.data.image} style={{ height: "300px", width: '400px' }} />
                                                                                        {/* <img src={process.env.PUBLIC_URL + '/images/typeIcon/sing/imgProject.png'} style={{ height: "248px" }} /> */}
                                                                                    </div>
                                                                                    :
                                                                                    ""

                                                                            }
                                                                        </section>

                                                                        <section className='description'>
                                                                            <label>{t("PageCreate:Detail of the problem")} </label>
                                                                            <textarea readOnly={true}
                                                                                name='description' value={description} rows={3}
                                                                                className='form-control'
                                                                                style={{ resize: "none", width: "475px" }} />
                                                                        </section>

                                                                        <section className='UploadFile'>
                                                                            <div style={{ display: 'inline-flex' }}>
                                                                                <h3 style={{ marginTop: '40px' }}>{i18n.t("FixRequestModal:Attach slip")} </h3>
                                                                                <label htmlFor="uploadFile" className="upload-btn-wrapper"
                                                                                    style={{ pointerEvents: "none" }}>
                                                                                    <button className="btn disabled"><img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />{t("silvermanguard:Choose File")} </button>
                                                                                    <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>{"PageCreate:Support File Just .png, .jpg, jpeg Only and File Not Over 10 MB (Max 3 Image Only)"} </span>

                                                                                    <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder='ระบุเบอร์โทร' accept='.pdf,.png,.jpg' onChange={this.uploadFile}
                                                                                    />

                                                                                </label>
                                                                            </div>

                                                                            {
                                                                                this.state.data?.image_per_repairs_room?.length > 0 &&
                                                                                this.state.data?.image_per_repairs_room?.map((file, inx) => {
                                                                                    let filesed = file.fast_url?.split("https://fastinspect-fileupload-uat.s3.ap-southeast-1.amazonaws.com") || ""
                                                                                    let typeFile = file.file_upload ? file.file_upload?.split(".") : filesed[1]?.split(".")
                                                                                    let nameFile = file.file_upload?.split("sing/file/")
                                                                                    return (
                                                                                        <p key={inx}>
                                                                                            {typeof(typeFile) === 'object' && <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} />}
                                                                                             &nbsp;
                                                                                            {
                                                                                                file.file_upload ?
                                                                                                    <Link
                                                                                                        to="route"
                                                                                                        onClick={(event) => {
                                                                                                            event.preventDefault();
                                                                                                            window.open(
                                                                                                                typeof file.file_name === "string"
                                                                                                                    ? file.file_name
                                                                                                                    : URL.createObjectURL(file.file_name)
                                                                                                            )
                                                                                                        }}
                                                                                                        target="blank">{nameFile[1]}</Link>
                                                                                                    :
                                                                                                    <Link to={{ pathname: file.fast_url }} target="blank">{file.file_name.substring(0, 100)}</Link>
                                                                                            }

                                                                                        </p>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </section>

                                                                        <section className='ChkWarranty'>
                                                                            <p style={{
                                                                                fontWeight: "600", fontSize: "13px", color: this.state.data?.insure_status === "have" ? "#38AE3E" : "#CA0824"
                                                                            }}>
                                                                                <React.Fragment>
                                                                                    <React.Fragment>
                                                                                        {
                                                                                            this.state.data.insure_status === "have" ?
                                                                                                <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/InsurGreen.png"} style={{ width: '18px' }} /> :
                                                                                                this.state.data.insure_status === "out" ?
                                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/InsurRed.png"} style={{ width: '18px' }} /> :
                                                                                                    ""
                                                                                        }
                                                                                        {
                                                                                            this.state.data.insure_status === "have" || this.state.data.insure_status === "out" ?
                                                                                                <React.Fragment>
                                                                                                    &nbsp; {t("PageCreate:Warranty Expiry")} &nbsp; {this.state.data.due_date ? format(this.state.data.due_date, "DD/MM/YYYY") : "-"}

                                                                                                </React.Fragment>
                                                                                                :
                                                                                                ""
                                                                                        }
                                                                                    </React.Fragment>


                                                                                </React.Fragment>

                                                                            </p>

                                                                        </section>

                                                                        <section className='worksheetDetail'>
                                                                            {
                                                                                this.state.data.status === "finish" &&
                                                                                <Link to={"/contact/fix-requests/forcommon/" + this.state.data.status + "?worksheetId=" + encode(this.state.data.id)}>
                                                                                    {t("PageCreate:Click View Detail Wroksheet")}
                                                                                </Link>
                                                                            }
                                                                        </section>
                                                                    </React.Fragment>
                                                                </div>
                                                            </form>

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                        :
                                        <div className="text-center" >
                                            <img src={process.env.PUBLIC_URL + '/images/typeIcon/sing/loading.gif'} />
                                        </div>
                                }



                            </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default fixRequestListCommonDetail;
