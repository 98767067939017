import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import "../styles/commonArea.scss";
import ComponentPagination from "../../../libs/componentPagination";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
// import api from "../../../api/index";
import Swal from "sweetalert2";
import Loading from "../../../libs/loading";
import SettingBookingStep1 from "./settingBookingStep1";
import SettingBookingStep2 from "./settingBookingStep2";
import SettingBookingStep3 from "./settingBookingStep3";
import apis from "../../../api/index";
import i18n from "../../../i18n";
const data = require("./data");

class SettingBooking extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      advanceDay: 30,
      advanceRight: 5,
      maxReservationPerRoom: 30,
      loading: true,
      permissionEdit: _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_setting_create' }) ?
        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_setting_create' }) : false,


      step1: true,
      step2: false,
      step3: false,

      //Step2
      resident_score: 500,
      rent_score: 200,
      agent_score: 0,

      expire_per_year: true,
      expire_number: 1,
      expire_numberYear: 0,
      expire_numberDay: 0,


      //Step3
      MoneytoPoint: true,
      score_exchange: 2,
      max_topup: null,
      min_topup: null,

      savingData: false
    };
    this.onClickNext = this.onClickNext.bind(this)
    this.onClickPrev = this.onClickPrev.bind(this)
  }

  async componentDidMount() {

    await apis.bookingSingha.score_setting().then((res) => {
      if (res.data) {
        this.setState({
          advanceDay: res.data.advance_day,
          expire_number: res.data.expire_day,
          expire_numberYear: res.data.expire_type === "year" ? res.data.expire_day : 1,
          expire_numberDay: res.data.expire_type === "day" ? res.data.expire_day : 100,
          expire_per_year: res.data.expire_type === "year" ? true : false,
          resident_score: res.data.resident_score,
          rent_score: res.data.rent_score,
          agent_score: res.data.agent_score,
          score_exchange: res.data.score_exchange,
          max_topup: res.data.max_topup,
          min_topup: res.data.min_topup,
          loading: false,
        })
      }
    })
  }

  save = async (e) => {

    e.preventDefault();
    let data = {
      advance_day: this.state.advanceDay,
      expire_day: this.state.expire_per_year ? this.state.expire_numberYear : this.state.expire_numberDay,
      expire_type: this.state.expire_per_year ? "year" : "day",
      resident_score: this.state.resident_score,
      rent_score: this.state.rent_score,
      agent_score: this.state.agent_score,
      score_exchange: this.state.MoneytoPoint ? this.state.score_exchange : null,

      max_topup: this.state.max_topup,
      min_topup: this.state.min_topup,
      button: "True",
    };

    let onSave = false

    if (!this.state.advanceDay) {
      this.setState({ step1: true, step3: false }, () => { Swal.fire(i18n.t("Allaction:Saved Unsuccessful"), i18n.t("Allaction:Please specifies data"), "warning").then(() => { return }) })
    }
    else { onSave = true }

    if (onSave) {
      this.setState({ savingData: true })
      await apis.bookingSingha.score_setting(data).then((res) => {
        if (res.data.message === "successful") {
          Swal.fire(i18n.t("createUpdateForm:Successful record"), "", "success").then(() => {
            window.location.reload()
          });
        } else {
          Swal.fire(i18n.t("Allaction:Saved Unsuccessful"), "", "warning");
        }
      }).catch((err) => { Swal.fire(i18n.t("Allaction:Saved Unsuccessful"), i18n.t("Allaction:Please specifies data"), "warning"); })
    }
  };

  selectChange = (val) => {

    let { value, name, checked } = val.target
    if (name === "MoneytoPoint") {
      this.setState({ [name]: checked });
    } else if (name === "expire_per_year") {
      this.setState({ [name]: value === "true" ? true : false });
    } else {
      this.setState({ [name]: parseInt(value) });
    }
  };

  onClickNext() {
    if (this.state.step1) {
      this.setState({ step1: false, step2: true })
    } else if (this.state.step2) {
      this.setState({ step2: false, step3: true })
    }
  }

  onClickPrev() {
    if (this.state.step2) {
      this.setState({ step2: false, step1: true })
    } else if (this.state.step3) {
      this.setState({ step3: false, step2: true })
    }
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          {!this.state.loading ? (
            <div className="container-fluid box booking-form" id="parcel">
              <div className="row justify-content-between">
                <div className="col">
                  <h3>
                    <Link to="/contact">
                      <img src={process.env.PUBLIC_URL + "/images/wrapperContent/back.png"} alt="back" className="back" />
                    </Link>
                    <Translation>{(t) => <span className="header">{t("newBookingSingha_Setting:Booking Facility setup")}</span>}</Translation>
                  </h3>
                </div>
              </div>
              <form onSubmit={this.save}>
                {
                  this.state.step1 &&
                  <SettingBookingStep1 state={this.state} data={data} selectChange={this.selectChange}
                    onClickNext={this.onClickNext} onClickPrev={this.onClickPrev} />
                }

                {
                  this.state.step2 &&
                  <SettingBookingStep2 state={this.state} data={data} selectChange={this.selectChange}
                    onClickNext={this.onClickNext} onClickPrev={this.onClickPrev} />
                }

                {
                  this.state.step3 &&
                  <SettingBookingStep3 state={this.state} data={data} selectChange={this.selectChange}
                    onClickNext={this.onClickNext} onClickPrev={this.onClickPrev} />
                }
              </form>
            </div>
          ) : (
            <Loading />
          )}
        </WrapperContent>
      </Wrapper>
    );
  }
}



export default SettingBooking;
