/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QuestionType = "CHOICE" | "RADIO" | "TEXT" | "%future added value";
export type SurveyStatus = "DELETE" | "DRAFT" | "INACTIVE" | "PUBLISH" | "%future added value";
export type modalSumVoteAndResultQueryVariables = {|
  id: string
|};
export type modalSumVoteAndResultQueryResponse = {|
  +survey: ?{|
    +id: string,
    +title: string,
    +detail: string,
    +status: SurveyStatus,
    +surveyQuestion: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: string,
          +type: QuestionType,
          +questionChoice: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +title: string,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
  +getResidentialVoteBySurvey: ?{|
    +surveyId: ?string,
    +countVoted: ?string,
    +countVoteApprove: ?string,
    +countAll: ?string,
  |},
|};
export type modalSumVoteAndResultQuery = {|
  variables: modalSumVoteAndResultQueryVariables,
  response: modalSumVoteAndResultQueryResponse,
|};
*/


/*
query modalSumVoteAndResultQuery(
  $id: ID!
) {
  survey(id: $id) {
    id
    title
    detail
    status
    surveyQuestion {
      edges {
        node {
          id
          title
          type
          questionChoice {
            edges {
              node {
                id
                title
              }
            }
          }
        }
      }
    }
  }
  getResidentialVoteBySurvey(surveyId: $id) {
    surveyId
    countVoted
    countVoteApprove
    countAll
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "SurveyNode",
    "kind": "LinkedField",
    "name": "survey",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "detail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "QuestionNodeConnection",
        "kind": "LinkedField",
        "name": "surveyQuestion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "QuestionNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QuestionNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChoiceNodeConnection",
                    "kind": "LinkedField",
                    "name": "questionChoice",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChoiceNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ChoiceNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "surveyId",
        "variableName": "id"
      }
    ],
    "concreteType": "SurveyResidentialVote",
    "kind": "LinkedField",
    "name": "getResidentialVoteBySurvey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "surveyId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countVoted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countVoteApprove",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countAll",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "modalSumVoteAndResultQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "modalSumVoteAndResultQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "eb232ff9fc00246851b5d796726e3a0e",
    "id": null,
    "metadata": {},
    "name": "modalSumVoteAndResultQuery",
    "operationKind": "query",
    "text": "query modalSumVoteAndResultQuery(\n  $id: ID!\n) {\n  survey(id: $id) {\n    id\n    title\n    detail\n    status\n    surveyQuestion {\n      edges {\n        node {\n          id\n          title\n          type\n          questionChoice {\n            edges {\n              node {\n                id\n                title\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  getResidentialVoteBySurvey(surveyId: $id) {\n    surveyId\n    countVoted\n    countVoteApprove\n    countAll\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'abaab33c7c6d19201d64b3b747b2d14f';

module.exports = node;
