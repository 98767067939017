import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import OtherReceiver from './otherReceiver';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import { Translation } from 'react-i18next';
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperOtherReceiverQuery($id: ID!, $ref_transaction: String) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        otherReceive(id: $id) {
            id
            issuedDate
            docNumber
            remark
            status
            total
            taxType
            unknownContact
            
            firstName
            lastName
            address district city province postalCode

            name
            taxIdNumber
            email
            phone
            
            contact {
                name
                refNumber
                firstName
                lastName
                registeredName
                registeredAddress
                registeredCountry
                registeredProvince
                registeredCity
                registeredDistrict
                registeredPostalCode
                typeOfContact
                typeOfSupplier
                typeOfCompany
                nameBranch
                taxIdNumber
                email
                phone
                residential{
                    size
                    ownershipRatio
                }
            }
            otherReceiveTransaction {
                edges {
                    node {
                        id
                        chartOfAccount {
                            chartOfAccountCode
                            name
                        }
                        productAndService {
                            productCode
                            name
                        }
                        keycardPerOtherReceiveTransaction{
                            edges {
                                node {
                                id
                                numberKey{
                                    id
                                    noNumber
                                    }
                                }
                            }
                        }
                        description
                        unitItems
                        price
                        total
                        discount
                        vat
                        preTaxAmount
                        whtRate
                        whtRatePercent
                        accountRecord{
                            id
                        }
                    }
                }
            }
            creditCardTransaction{
                edges{
                    node{
                        id
                        amount
                    }
                }
            }
        }
        cashTransactionViewer{
            allCashTransaction(refNumber: $ref_transaction){
                edges{
                    node{
                        id
                        description
                        price
                    }
                }
            }
        }
        chequeDepositViewer{
            allChequeDeposit(refNumber: $ref_transaction){
                edges{
                    node {
                        id
                        price
                        date
                        chequeNumber
                        bankName
                        branchNumber
                    }
                }
            }
        }

        allBankAccountTransaction(description_Icontains: $ref_transaction) {
            edges{
                node {
                    id
                    price
                    date
                    imageSlip
                    bankAccount {
                        bankName
                        accountNumber
                    }
                }
            }
        }

        allAccountRecordGroup(refTransaction: $ref_transaction) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }
        userConfig {
            id 
            languages
        }
    }
`;

class WrapperOtherReceiver extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type: 'all'
        };
        this.print = this.print.bind(this);
        this.adjustHeight = this.adjustHeight.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }

    adjustHeight() {

    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Other receive</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>

                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeAll"
                                       onChange={this.handleInputChange}
                                       value="all"
                                       checked={this.state.check_type === 'all'}
                                       />
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeAll">
                                        {t("document:All")}
                                    </label>}
                                </Translation>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeManuscript"
                                       value="manuscript"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'manuscript'}
                                       />
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeManuscript">
                                        {t("document:Original_")}
                                    </label>}
                                </Translation>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeCopy"
                                       value="copy"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'copy'}
                                       />
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeCopy">
                                        {t("document:Copy_")}
                                    </label>}
                                </Translation>
                            </div>
                        </div>
                    </div>

                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{
                        id: this.props.match.params.id,
                        ref_transaction: this.props.match.params.ref_transaction
                    }}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return <OtherReceiver query={props}
                                            bank_transaction={props.allBankAccountTransaction.edges}
                                            cash_transaction={props.cashTransactionViewer.allCashTransaction.edges}
                                            cheque_transaction={props.chequeDepositViewer.allChequeDeposit.edges}
                                            credit_card_transaction={props.otherReceive.creditCardTransaction.edges}
                                            selfProject={props.otherReceive.selfProject}
                                            remark={props.otherReceive.remark}
                                            otherTransaction = {props.allAccountRecordGroup.edges}
                                            check_type={this.state.check_type}
                                            editOtherReceive={this.props.location.state}
                                            />
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperOtherReceiver;
