import React, { Component } from 'react';
import Api from '../../../api/sing'
import Swal from 'sweetalert2';
import format from 'date-fns/format';
import _ from 'lodash';
import Select from 'react-select';
import i18n from 'i18next';
import i18next from 'i18next';
class Modalworksheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingDate: false,
            staff_technician: [],


            repairs_room_id: "",
            working_type: "repair", //repair,improvement,other
            technician: "inside", //inside , outside
            project_staff_id: "",
            project_staff_other: "",
            phone: "",
            description: "",
            appointments: new Date(),
            appiontmentTimeSt: "",
            appiontmentTimeEn: ""
        }
        this.onChangeData = this.onChangeData.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onChangeData(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentWillMount() {
        this.getDataTechnic();
        // console.log(this.state , this.props.indexID);
    }

    getDataTechnic() {
        try {
            Api.getstaff_technician().then((res) => {
                let data = []
                if (res?.data?.data) {
                    data = res.data.data
                    let dataNewSet = []
                    data.forEach((n, index) => {
                        _.set(dataNewSet, '[' + index + '].value', n.id);
                        _.set(dataNewSet, '[' + index + '].label', n.name_title + n.name_staff + " " + n.last_name_staff);
                    })
                    this.setState({ loading: false, staff_technician: dataNewSet })
                }
            })
        } catch (err) {
            Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error').then(() => {
                this.setState({ loading: false })
            })
        }

    }

    onSubmit(e) {
        e.preventDefault()


        let data = {
            repairs_room_id: this.props.onClick,
            working_type: this.state.working_type, //repair,improvement,other
            technician: this.state.technician, //inside , outside
            project_staff_id: this.state.technician === "inside" ? this.state.project_staff_id : "",
            project_staff_other: this.state.technician !== "inside" ? this.state.project_staff_other : "",
            phone: this.state.phone,
            description: this.state.description,
            appointments: this.state.appointments + "T" + this.state.appiontmentTimeSt,
            repair_type: this.props.data.repair_type
            // appiontmentTimeSt: "",
            // appiontmentTimeEn: ""
        }

        Api.postrepairs_room_view(data).then((res) => {
            if (res.statusMessage === "success") {
                Swal.fire(i18n.t("Allaction:Saved Successfully"), "", "success").then(() => {
                    window.location.reload()
                })
            } else {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), "", "error")
            }
        })




    }

    render() {
        let doc = this.props.data?.repair_type === "private" ? "PNT-" : "CNT-";
        let docNumber = doc + format(this.state.appointments, "YYYYMM") + "XXXX";
        return (
            // <form onSubmit={()=>this.onSubmit(this ,this.props.onClick )}>
            <div className={"modal fade bd-example-modal-lg worksheetFix worksheetFixRequset" + this.props.onClick} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog  modal-lg" role="document" style={{ width: "750px" }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ padding: "19px 56px" }}>
                            <input type={"text"} className='inputWorkSheet text-center'
                                value={docNumber} readOnly={true}
                            />


                            <h2 className="modal-title text-center" style={{ fontSize: "24px" }}>{i18n.t('PageList:Wroksheet')}</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={this.onSubmit}>
                            <div className="modal-body" style={{ padding: "19px 56px", textAlign: 'left' }}>

                                <div className='row'>
                                    <div className='col-12 claim create'>

                                        <label style={{ color: "#808080", fontWeight: "normal", fontSize: "18px", textAlign: 'left' }}>
                                            {this.props.data?.working_type} : &nbsp;
                                            {this.props.data?.material} ;  &nbsp;
                                            {this.props.data?.details}  &nbsp; &nbsp;
                                        </label>
                                        {this.props.data?.tag_repairs_room?.map((tag, index) => {
                                            return (
                                                <React.Fragment key={index} >
                                                    <button className={'tag tagBtnBlue'}>
                                                        {tag.tag_global.tag_name}
                                                    </button>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "10px 0" }}>{i18n.t("FixRequestModal:Type")}</label>
                                    <div className='col-9'>
                                        <input type={"text"} onChange={this.onChangeData}
                                            name='workArea' defaultValue={this.props.data.repair_type === "private" ? i18n.t("FixRequestModal:Private") : i18n.t("FixRequestModal:Public")} readOnly={true}
                                            className='form-control inputBoxModal' />
                                    </div>
                                </div>
                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "10px 0" }}>{i18n.t("FixRequestModal:Responsible staff")}</label>
                                    <div className='col-9'>
                                        <input type={"text"} onChange={this.onChangeData}
                                            name='responsiblePerson' readOnly={true}
                                            defaultValue={"Juristic Person"}
                                            className='form-control inputBoxModal' />
                                    </div>
                                </div>
                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "-1px 0" }}>{i18n.t("FixRequestModal:Work Type")} <span className='text-red'>*</span></label>
                                    <div className='col-9'>
                                        <input type={"checkbox"} name='repair' value={"repair"}
                                            checked={this.state.working_type === "repair"}
                                            onChange={() => this.setState({ working_type: "repair" })} />
                                        <span className='span'> {i18n.t("FixRequestModal:Repair")}</span>
                                        <input type={"checkbox"} name='improvement' value={"improvement"}
                                            checked={this.state.working_type === "improvement"}
                                            onChange={() => this.setState({ working_type: "improvement" })} />
                                        <span className='span'> {i18n.t("FixRequestModal:Improvement")}</span>
                                        <input type={"checkbox"} name='other' value={"improvement"}
                                            checked={this.state.working_type === "other"}
                                            onChange={() => this.setState({ working_type: "other" })} />
                                        <span className='span'> {i18n.t("FixRequestModal:etc.")}</span>

                                        <input type={"text"} name='working_type' defaultValue={this.state.working_type} required style={{ opacity: 0 }} />

                                    </div>
                                </div>

                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "-1px 0" }}>{i18n.t("FixRequestModal:Technician type")} <span className='text-red'>*</span></label>
                                    <div className='col-9'>
                                        <input type={"radio"} name='technician' value={"inside"}
                                            checked={this.state.technician === "inside"}
                                            onChange={() => this.setState({ technician: "inside" })} required
                                        />
                                        <span className='span'> {i18n.t("FixRequestModal:Technician in the project")}</span>
                                        <input type={"radio"} name='technician' value={"outside"}
                                            checked={this.state.technician === "outside"}
                                            onChange={() => this.setState({ technician: "outside" })} required
                                        />
                                        <span className='span'> {i18n.t("FixRequestModal:Technician outside the project")}</span>

                                        <p></p>

                                        {
                                            this.state.technician === "inside" ?
                                                <div style={{ position: 'relative', textAlign: 'left' }}>
                                                    <Select
                                                        style={{ width: '444px', height: '48px' }}
                                                        options={this.state.staff_technician}
                                                        onChange={(e) => this.setState({ project_staff_id: e.value })}
                                                        required={true}
                                                        isSearchable={false}
                                                        placeholder={`${i18n.t("FixRequestModal:Choose the technician of your project")}`}
                                                    />
                                                    <input name="project_staff_id" type="text" value={this.state.project_staff_id}
                                                        style={{ position: "absolute", top: 0, left: 110, width: 5, zIndex: -1 }}
                                                        onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                        required={true} />
                                                </div>

                                                :
                                                <input type={"text"} onChange={this.onChangeData} required
                                                    name='project_staff_other' defaultValue={this.state.project_staff_other} className='form-control inputBoxModal' />
                                        }

                                    </div>
                                </div>

                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "10px 0" }}>{i18n.t("newBookingSingha_Booking:Contact number")} <span className='text-red'>*</span></label>
                                    <div className='col-9'>
                                        <input type={"text"} onChange={this.onChangeData}
                                            name='phone' defaultValue={""}
                                            placeholder={`${i18n.t("newBookingSingha_Booking:Specify phone number")}`} minLength={9}
                                            maxLength={10} pattern='[0-9]{9,10}'
                                            className='form-control inputBoxModal' />
                                    </div>
                                </div>

                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "10px 0" }}>{i18n.t("FixRequestModal:Appointment Date")}</label>
                                    <div className='col-9'>
                                        <input type={"date"} onChange={this.onChangeData}
                                            name='appointments' defaultValue={this.state.appointments} className='form-control inputBoxModal' />
                                    </div>
                                </div>


                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "10px 0" }}>{i18n.t("FixRequestModal:Appointment Time")} </label>
                                    <div className='col-9 text-center'>
                                        <input type={"time"} onChange={this.onChangeData}
                                            defaultValue={this.state.appiontmentTimeSt} name='appiontmentTimeSt' className='form-control' style={{ width: "190px", height: "48px" }} />
                                    </div>
                                </div>

                                <div className='row' style={{ marginTop: "19px" }}>
                                    <label className='col-3' style={{ margin: "-1px 0" }}>{i18n.t("FixRequestModal:Additional message")}</label>
                                    <div className='col-9 text-center'>
                                        <textarea onChange={this.onChangeData} name='description'
                                            className='form-control' defaultValue={this.state.description}
                                            style={{ resize: 'none', height: '128px' }}
                                        />
                                    </div>
                                </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className='btn ' style={{ color: "#1567B4" }} data-dismiss="modal" aria-label="Close">{i18n.t("Allaction:cancel")}</button>
                                <button type="submit" className="btn btn-primary">{i18n.t("FixRequestModal:Save")}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            // </form>

        )
    }
}

export default Modalworksheet

