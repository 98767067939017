/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type GuaranteeMoneyReceivedPayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type GuaranteeMoneyReceivedStatus = "COMPLETED" | "FORFEIT_ALL" | "IN_PROCESS" | "RETURN_FORFEIT" | "VOID" | "%future added value";
export type guaranteeMoneyReceivedViewQueryVariables = {|
  id: string
|};
export type guaranteeMoneyReceivedViewQueryResponse = {|
  +guaranteeMoneyReceived: ?{|
    +id: string,
    +docNumber: string,
    +issuedDate: any,
    +status: GuaranteeMoneyReceivedStatus,
    +amount: ?number,
    +description: string,
    +voidRemark: ?string,
    +firstName: string,
    +lastName: string,
    +payGroup: GuaranteeMoneyReceivedPayGroup,
    +address: string,
    +district: string,
    +city: string,
    +province: string,
    +postalCode: string,
    +name: string,
    +taxIdNumber: ?string,
    +email: ?string,
    +phone: string,
    +note: ?string,
    +contact: {|
      +refNumber: string,
      +firstName: string,
      +lastName: string,
      +registeredName: string,
      +registeredAddress: string,
      +registeredCountry: string,
      +registeredProvince: string,
      +registeredCity: string,
      +registeredDistrict: string,
      +registeredPostalCode: string,
      +typeOfContact: ContactTypeOfContact,
      +name: string,
    |},
    +chartOfAccount: ?{|
      +id: string,
      +chartOfAccountCode: string,
      +name: string,
    |},
    +bankAccountTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +price: number,
          +date: ?any,
          +imageSlip: ?string,
          +bankAccount: {|
            +bankName: string,
            +accountNumber: string,
          |},
        |}
      |}>
    |},
    +cashDepositReceiveTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +price: number
        |}
      |}>
    |},
    +chequeDeposit: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +date: any,
          +bankName: string,
          +chequeNumber: string,
          +branchNumber: string,
          +price: number,
        |}
      |}>
    |},
    +unknownReceive: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +description: string,
          +docNumber: string,
          +amount: number,
          +refNumber: ?string,
          +chartOfAccount: ?{|
            +chartOfAccountCode: string,
            +name: string,
          |},
          +imageSlip: ?string,
        |}
      |}>
    |},
  |}
|};
export type guaranteeMoneyReceivedViewQuery = {|
  variables: guaranteeMoneyReceivedViewQueryVariables,
  response: guaranteeMoneyReceivedViewQueryResponse,
|};
*/


/*
query guaranteeMoneyReceivedViewQuery(
  $id: ID!
) {
  guaranteeMoneyReceived(id: $id) {
    id
    docNumber
    issuedDate
    status
    amount
    description
    voidRemark
    firstName
    lastName
    payGroup
    address
    district
    city
    province
    postalCode
    name
    taxIdNumber
    email
    phone
    note
    contact {
      refNumber
      firstName
      lastName
      registeredName
      registeredAddress
      registeredCountry
      registeredProvince
      registeredCity
      registeredDistrict
      registeredPostalCode
      typeOfContact
      name
      id
    }
    chartOfAccount {
      id
      chartOfAccountCode
      name
    }
    bankAccountTransaction {
      edges {
        node {
          id
          price
          date
          imageSlip
          bankAccount {
            bankName
            accountNumber
            id
          }
        }
      }
    }
    cashDepositReceiveTransaction {
      edges {
        node {
          price
          id
        }
      }
    }
    chequeDeposit {
      edges {
        node {
          id
          date
          bankName
          chequeNumber
          branchNumber
          price
        }
      }
    }
    unknownReceive {
      edges {
        node {
          id
          description
          docNumber
          amount
          refNumber
          chartOfAccount {
            chartOfAccountCode
            name
            id
          }
          imageSlip
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidRemark",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payGroup",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "district",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "province",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxIdNumber",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredName",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredAddress",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCountry",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredProvince",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCity",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredDistrict",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredPostalCode",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccount",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v31/*: any*/),
    (v17/*: any*/)
  ],
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageSlip",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "concreteType": "ChequeDepositNodeConnection",
  "kind": "LinkedField",
  "name": "chequeDeposit",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChequeDepositNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChequeDepositNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v34/*: any*/),
            (v36/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chequeNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "branchNumber",
              "storageKey": null
            },
            (v33/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "guaranteeMoneyReceivedViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GuaranteeMoneyReceivedNode",
        "kind": "LinkedField",
        "name": "guaranteeMoneyReceived",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNode",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v32/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "bankAccountTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v33/*: any*/),
                      (v34/*: any*/),
                      (v35/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v36/*: any*/),
                          (v37/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CashTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "cashDepositReceiveTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CashTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CashTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v33/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v38/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UnknownReceiveNodeConnection",
            "kind": "LinkedField",
            "name": "unknownReceive",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnknownReceiveNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnknownReceiveNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v22/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChartOfAccountNode",
                        "kind": "LinkedField",
                        "name": "chartOfAccount",
                        "plural": false,
                        "selections": [
                          (v31/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v35/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "guaranteeMoneyReceivedViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GuaranteeMoneyReceivedNode",
        "kind": "LinkedField",
        "name": "guaranteeMoneyReceived",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNode",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v17/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v32/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "bankAccountTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v33/*: any*/),
                      (v34/*: any*/),
                      (v35/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v36/*: any*/),
                          (v37/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CashTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "cashDepositReceiveTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CashTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CashTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v33/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v38/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UnknownReceiveNodeConnection",
            "kind": "LinkedField",
            "name": "unknownReceive",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnknownReceiveNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnknownReceiveNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v22/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChartOfAccountNode",
                        "kind": "LinkedField",
                        "name": "chartOfAccount",
                        "plural": false,
                        "selections": [
                          (v31/*: any*/),
                          (v17/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v35/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "569b85c14f50e2c004f758e8be045889",
    "id": null,
    "metadata": {},
    "name": "guaranteeMoneyReceivedViewQuery",
    "operationKind": "query",
    "text": "query guaranteeMoneyReceivedViewQuery(\n  $id: ID!\n) {\n  guaranteeMoneyReceived(id: $id) {\n    id\n    docNumber\n    issuedDate\n    status\n    amount\n    description\n    voidRemark\n    firstName\n    lastName\n    payGroup\n    address\n    district\n    city\n    province\n    postalCode\n    name\n    taxIdNumber\n    email\n    phone\n    note\n    contact {\n      refNumber\n      firstName\n      lastName\n      registeredName\n      registeredAddress\n      registeredCountry\n      registeredProvince\n      registeredCity\n      registeredDistrict\n      registeredPostalCode\n      typeOfContact\n      name\n      id\n    }\n    chartOfAccount {\n      id\n      chartOfAccountCode\n      name\n    }\n    bankAccountTransaction {\n      edges {\n        node {\n          id\n          price\n          date\n          imageSlip\n          bankAccount {\n            bankName\n            accountNumber\n            id\n          }\n        }\n      }\n    }\n    cashDepositReceiveTransaction {\n      edges {\n        node {\n          price\n          id\n        }\n      }\n    }\n    chequeDeposit {\n      edges {\n        node {\n          id\n          date\n          bankName\n          chequeNumber\n          branchNumber\n          price\n        }\n      }\n    }\n    unknownReceive {\n      edges {\n        node {\n          id\n          description\n          docNumber\n          amount\n          refNumber\n          chartOfAccount {\n            chartOfAccountCode\n            name\n            id\n          }\n          imageSlip\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '43595e21225891ac7982999b214ab648';

module.exports = node;
