import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import _ from 'lodash';
import Swal from 'sweetalert2';
import generalAPI from "./generalAPI"
import i18n from 'i18next';
import i18next from 'i18next';
class GeneralDetail extends Component{

    constructor(props){
        super(props);
        this.state = {
            generalEdit : false,
            generalData : this.props.generalData,
            token : localStorage.getItem("token"),
            prefixInput : this.props.prefixInput,
        }

        this.onEdit = this.onEdit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        
    }

    componentDidUpdate(prevProps){
        if(prevProps.generalData !== this.props.generalData){
            this.setState({
                generalData : this.props.generalData
            })
        }
        if(prevProps.prefixInput !== this.props.prefixInput){
            this.setState({
                prefixInput : this.props.prefixInput
            })
        }
    }

    handleChange(e){
        let generalData = _.cloneDeep(this.state.generalData)
        generalData[e.target.name] = e.target.value
        this.setState({
            generalData : generalData
        })
    }

    getPrefix = (e) =>{
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    onEdit(){
        this.setState({generalEdit : !this.state.generalEdit})
    }

    onSubmit(){
        this.onEdit() 
        let data = {
            description : this.state.generalData.description ? this.state.generalData.description : '',
            developer: this.state.generalData.developer ? this.state.generalData.developer : '',
            address : this.state.generalData.address ? this.state.generalData.address : '',
            taxId: this.state.generalData.taxId ? this.state.generalData.taxId : '',
            website: this.state.generalData.website ? this.state.generalData.website : '',
            tel: this.state.generalData.tel ? this.state.generalData.tel : '',
            email: this.state.generalData.email ? this.state.generalData.email :'',
            facebook: this.state.generalData.facebook ? this.state.generalData.facebook : '',
            line: this.state.generalData.line ? this.state.generalData.line : '',
            prefix : this.state.generalData.prefix === 'other' ? this.state.prefixInput : this.state.generalData.prefix 
        }

        if(!this.props.developerDisable){
            Swal.fire({
                title: i18next.t("AlertMore:Please enter the correct title and developer name. Once saved, it cannot be edited."), 
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: i18next.t("Allaction:cancel"),
                confirmButtonText: i18next.t("Allaction:yes")
            }).then((res) => {
                if(res.value){
                    this.setGeneralAPI(data)
                }
            })
        }else{
            this.setGeneralAPI(data)
        }
        
    }

    setGeneralAPI = (data) => {
        generalAPI.createProfile(data,this.state.token).then((response) => {
            if(response.status === 200){
                Swal.fire(i18n.t('developer:Save success!'), '', 'success').then(() => this.props.fetchData());
            }else{
                Swal.fire(i18n.t('developer:Fail to save!'), '', 'warning').then(() => this.props.fetchData());
            }
        })
    }

    render(){
        return (
            <Translation>
                {t =>
                    <div className="general-box p-4">
                        <div className="row col-12">
                            <div className="col-8 p-3">
                                <h4>{t("generalInfo:Developer information")}</h4>
                            </div>
                            <div className="col-4 p-1">
                            {this.state.generalEdit ?
                            
                                <button onClick={this.onSubmit} type="button" className="btn add-button add float-right">
                                    
                                    <span className="text-blue"> {t("Allaction:save")}</span>
                                </button>
                                : 
                                _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'ownership_developer_edit'}) &&
                                <button onClick={this.onEdit} className="btn add-button add float-right" type="button">
                                    <img src={process.env.PUBLIC_URL + '/images/icons/edit-blue-color.png'} alt="edit"/>
                                    <span className="text-blue show-icon"> {t("Allaction:edit")}</span>
                                </button>
                                
                            }
                            </div>
                        </div>
                        <div className="row mt-2">
                            {this.state.generalEdit && 
                                <div className="col-12 ml-4 align-self-center">
                                    <strong><span className="h5">{t("generalInfo:Prefix")}</span></strong> <br/>
                                    <div className="form-inline">
                                        <select className="form-control col-3"
                                                name="prefix"
                                                value={this.state.generalData.prefix}
                                                onChange={this.handleChange}
                                                disabled={this.props.developerDisable}
                                                >
                                            <option value="บริษัท">{t("generalInfo:Company")}</option>
                                            <option value="ห้างหุ้นส่วน">{t("generalInfo:Partnership")}</option>
                                            <option value="other">{t("generalInfo:Specify")}</option>
                                        </select>
                                        {this.state.generalData.prefix === 'other' &&
                                            <input 
                                            disabled={this.props.developerDisable}
                                            type="text" 
                                            className="form-control col-6 ml-2" 
                                            value={this.state.prefixInput} 
                                            name="prefixInput" 
                                            onChange={this.getPrefix}
                                            placeholder="ระบุคำนำหน้า"
                                            required={this.state.generalData.prefix === 'other'}/>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row mt-2">
                            {this.state.generalEdit ? 
                                <div className="col-12 ml-4 align-self-center">
                                    <strong><span className="h5">{t("generalInfo:Developer name")}</span></strong> <br/>
                                    <div className="input-group mb-2 mr-4">
                                        <input 
                                            disabled={this.props.developerDisable}
                                            type="text" 
                                            className="form-control col-11" 
                                            value={this.state.generalData.developer || ''} 
                                            name="developer" 
                                            onChange={this.handleChange}
                                            placeholder={t("generalInfo:Developer name")}
                                            required={true}/>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="col-11 is-span ml-1 mb-2 ml-4 mr-4">
                                        <h5 className="ml-1">{this.state.generalData.prefix === 'other' ? this.props.prefixInput : this.state.generalData.prefix} {this.state.generalData.developer}</h5>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 ml-4">
                                <strong><span className="h5 ml-2">{t("generalInfo:About the developer")}</span></strong>
                            </div>
                            <div className="col-12">
                                {this.state.generalEdit ?
                                    <textarea className="form-control col-11 description ml-4 mr-4" value={this.state.generalData.description || ''} 
                                    name="description" onChange={this.handleChange}/>

                                    :
                                    <div className=" col-11 description form-control is-span ml-4 mr-4 ">
                                        <span>{this.state.generalData.description}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 ml-4">
                                <strong><span className="h5 ml-2">{t("generalInfo:Location")}</span></strong>
                            </div>
                            <div className="col-12 mt-1 mb-3">
                                {this.state.generalEdit ?
                                    <textarea className="form-control col-11 address ml-4 mr-4" value={this.state.generalData.address || ''} 
                                    name="address" onChange={this.handleChange} placeholder="ที่อยู่"/>

                                    :
                                    <div className=" col-11 address form-control is-span ml-4 mr-4 ">
                                        <span>{this.state.generalData.address}</span>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-5">
                                <div className=" col-11 form-control is-span ml-4 mr-4 ">
                                    <strong><span className="h5">{t("generalInfo:Tax payer identification / Identification number")}</span></strong>
                                </div>
                            </div>
                            <div className="col-7">
                                {this.state.generalEdit ?
                                    <input type="text" className="form-control col-10  ml-4 mr-4" value={this.state.generalData.taxId || ''} 
                                    name="taxId" onChange={this.handleChange}/>
                                    :
                                    <div className=" col-10 form-control is-span ml-4 mr-4 ">
                                        <strong><span className="h5">{this.state.generalData.taxId}</span></strong>
                                    </div>
                                }
                            </div>
                            
                            <div className="col-5 mt-2">
                                <div className=" col-11 form-control is-span ml-4 mr-4 ">
                                    <strong><span className="h5">{t("generalInfo:Website")}</span></strong>
                                </div>
                            </div>
                            <div className="col-7 mt-2">
                                {this.state.generalEdit ?
                                    <input type="text" className="form-control col-10  ml-4 mr-4" value={this.state.generalData.website || ''} 
                                    name="website" onChange={this.handleChange}/>
                                    :
                                    <div className=" col-10 form-control is-span ml-4 mr-4 ">
                                        <strong><span className="h5">{this.state.generalData.website}</span></strong>
                                    </div>
                                }
                            </div>

                            <div className="col-5 mt-2">
                                <div className=" col-11 form-control is-span ml-4 mr-4 ">
                                    <strong><span className="h5">{t("generalInfo:Contact number")}</span></strong>
                                </div>
                            </div>
                            <div className="col-7 mt-2">
                                {this.state.generalEdit ?
                                    <input type="text" className="form-control col-10  ml-4 mr-4" value={this.state.generalData.tel || ''} 
                                    name="tel" onChange={this.handleChange}/>
                                    :
                                    <div className=" col-10 form-control is-span ml-4 mr-4 ">
                                        <strong><span className="h5">{this.state.generalData.tel}</span></strong>
                                    </div>
                                }
                            </div>

                            <div className="col-5 mt-2">
                                <div className=" col-11 form-control is-span ml-4 mr-4 ">
                                    <strong><span className="h5">{t("generalInfo:E-Mail")}</span></strong>
                                </div>
                            </div>
                            <div className="col-7 mt-2">
                                {this.state.generalEdit ?
                                    <input type="text" className="form-control col-10  ml-4 mr-4" value={this.state.generalData.email || ''} 
                                    name="email" onChange={this.handleChange}/>
                                    :
                                    <div className=" col-10 form-control is-span ml-4 mr-4 ">
                                        <strong><span className="h5">{this.state.generalData.email}</span></strong>
                                    </div>
                                }
                            </div>

                            <div className="col-5 mt-2">
                                <div className=" col-11 form-control is-span ml-4 mr-4 ">
                                    <strong><span className="h5">Facebook</span></strong>
                                </div>
                            </div>
                            <div className="col-7 mt-2">
                                {this.state.generalEdit ?
                                    <input type="text" className="form-control col-10  ml-4 mr-4" value={this.state.generalData.facebook|| ''} 
                                    name="facebook" onChange={this.handleChange}/>
                                    :
                                    <div className=" col-10 form-control is-span ml-4 mr-4 ">
                                        <strong><span className="h5">{this.state.generalData.facebook}</span></strong>
                                    </div>
                                }
                            </div>

                            <div className="col-5 mt-2 mb-3">
                                <div className=" col-11 form-control is-span ml-4 mr-4 ">
                                    <strong><span className="h5">Line</span></strong>
                                </div>
                            </div>
                            <div className="col-7 mt-2 mb-3">
                                {this.state.generalEdit ?
                                    <input type="text" className="form-control col-10  ml-4 mr-4" value={this.state.generalData.line || ''} 
                                    name="line" onChange={this.handleChange}/>
                                    :
                                    <div className=" col-10 form-control is-span ml-4 mr-4 ">
                                        <strong><span className="h5">{this.state.generalData.line}</span></strong>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
            
                }
            </Translation>

        );
    }


}export default GeneralDetail;