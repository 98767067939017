/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type otherExpensePayChannelQueryVariables = {|
  invoiceId?: ?string
|};
export type otherExpensePayChannelQueryResponse = {|
  +resultNotAutoReconcileLog: ?{|
    +resultCompare: ?string,
    +resultText: ?string,
  |}
|};
export type otherExpensePayChannelQuery = {|
  variables: otherExpensePayChannelQueryVariables,
  response: otherExpensePayChannelQueryResponse,
|};
*/


/*
query otherExpensePayChannelQuery(
  $invoiceId: String
) {
  resultNotAutoReconcileLog(invoiceId: $invoiceId) {
    resultCompare
    resultText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "invoiceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "invoiceId",
        "variableName": "invoiceId"
      }
    ],
    "concreteType": "ResultNotAutoReconcileLogNode",
    "kind": "LinkedField",
    "name": "resultNotAutoReconcileLog",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resultCompare",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resultText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "otherExpensePayChannelQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "otherExpensePayChannelQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a9cc499d4ba71cfd60636013283eabe9",
    "id": null,
    "metadata": {},
    "name": "otherExpensePayChannelQuery",
    "operationKind": "query",
    "text": "query otherExpensePayChannelQuery(\n  $invoiceId: String\n) {\n  resultNotAutoReconcileLog(invoiceId: $invoiceId) {\n    resultCompare\n    resultText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '521591bf8026fcf8cb2f237c7a67e061';

module.exports = node;
