/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteNewsInput = {|
  idList?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type newsListMutationVariables = {|
  input: DeleteNewsInput
|};
export type newsListMutationResponse = {|
  +deleteNews: ?{|
    +ok: ?boolean
  |}
|};
export type newsListMutation = {|
  variables: newsListMutationVariables,
  response: newsListMutationResponse,
|};
*/


/*
mutation newsListMutation(
  $input: DeleteNewsInput!
) {
  deleteNews(input: $input) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteNewsPayload",
    "kind": "LinkedField",
    "name": "deleteNews",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "newsListMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "newsListMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "42b03d155ffbc1ba678d2430574fe955",
    "id": null,
    "metadata": {},
    "name": "newsListMutation",
    "operationKind": "mutation",
    "text": "mutation newsListMutation(\n  $input: DeleteNewsInput!\n) {\n  deleteNews(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '973fa71b7e9dab4f059b637fa778bd3c';

module.exports = node;
