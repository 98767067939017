import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import InvoiceCreateUpdateForm from "./invoiceCreateUpdateForm"
import {Translation} from "react-i18next";
import { Link } from "react-router-dom";
import Loading from '../../../libs/loading';
import _ from "lodash";

const invoiceEdit = graphql`
    query invoiceEditQuery($id: ID!) {
        invoice(id: $id) {
            id
            docNumber
            taxType
            remark
            contact {
                id
                refNumber
                name
                firstName
                lastName
                registeredAddress
                registeredCountry
                registeredProvince
                registeredDistrict
                registeredCity
                registeredPostalCode
                typeOfContact
                residential {
                    id
                    name
                    size
                    residentialHouseholder(type:"renter"){
                        edges{
                            node{
                                id
                                tenant{
                                    nameTitle
                                    firstName
                                    lastName
                                    phone
                                    email
                                }
                            }
                        }
                    }
                }
#                receiptDeposit(status_In: "active, use_partial, use_all") {
#                    edges {
#                        node {
#                            id
#                            receiptDepositTransaction {
#                                edges {
#                                    node {
#                                        id
#                                        price
#                                        balance
#                                        chartOfAccount {
#                                            id
#                                            name
#                                            chartOfAccountCode
#                                        }
#                                        receiptDepositTransactionUsed {
#                                            edges {
#                                                node {
#                                                    id
#                                                    amount
#                                                    transaction{
#                                                        invoice{
#                                                            id
#                                                        }
#                                                    }
#                                                }
#                                            }
#                                        }
#                                    }
#                                }
#                            }
#                        }
#                    }
#                }
            }
            issuedDate
            scheduleTime
            dueDate
            status
            approver
            firstName
            lastName
            payGroup
            historyShow
        }
        allInvoiceTransaction(invoice_Id:$id){
            edges{
                node{
                    id
                    productAndService{
                        id
                        name
                        productCode
                        type
                        price
                        cost
                        noNumber
                        autoOrder
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        servicePricing {
                            edges {
                                node {
                                    id
                                    periodAdjustment
                                    pricingType
                                }
                            }
                        }
                        numberKey(status:true){
                            totalCount
                            edges {
                                node {
                                    id
                                    noNumber
                                    status
                                    keycardPerInvoice{
                                        totalCount
                                    }
                                    keycardPerOtherReceiveTransaction{
                                        totalCount
                                    }
                                }
                            }
                        }
                        description
                        totalLeftInStock
                        taxRate
                    }
                    keycardPerInvoice{
                        totalCount
                        edges{
                            node {
                                id
                                numberKey{
                                    id
                                    noNumber
                                    status
                                    keycardPerInvoice{
                                        totalCount
                                    }
                                    keycardPerOtherReceiveTransaction{
                                        totalCount
                                    }
                                }
                            }
                        }
                    }
                    chartOfAccount{
                        id
                        chartOfAccountCode
                        name
                    }
                    description
                    unitItems
                    price
                    discount
                    vat
                    preTaxAmount
                    whtRate
                    whtRatePercent
                    servicePeriod {
                        id
                        periodMonth
                        startMonth
                        startYear
                    }
                    preInvoice{
                        id
                    }
                    obligationDeveloper{
                        id
                    }
                    obligationTenant{
                        id
                    }

                }
            }
        }
        productViewer{
            allProduct (type_In: "product, service"){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                        cost
                        noNumber
                        autoOrder
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        servicePricing {
                            edges {
                                node {
                                    id
                                    periodAdjustment
                                    pricingType
                                }
                            }
                        }
                        numberKey(status:true){
                            edges {
                                node {
                                    id
                                    noNumber
                                    status
                                    keycardPerInvoice{
                                        totalCount
                                        # edges {
                                        #     node {
                                        #         id
                                        #         transaction{
                                        #             id
                                        #             status
                                        #         }
                                        #     }
                                        # }
                                    }
                                    keycardPerOtherReceiveTransaction{
                                        totalCount
                                    }
                                }
                            }
                        }
                        description
                        totalLeftInStock
                        taxRate
                    }
                }
            }
        }
          selfProject{
            haveVat
        }
        viewer {
            allChartOfAccount(chartOfAccountCode: "1131-12"){
                totalCount
                edges{
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
        }
        group113110: viewer {
            allChartOfAccount(chartOfAccountCode: "1131-10"){
                totalCount
                edges{
                    node {
                        id
                        chartOfAccountCode
                        name
                        
                    }
                }
            }
        }
        
    }`;

class InvoiceEdit extends Component {

    render() {
        return (
            <div className="container-fluid box">
                <div className="row">
                    <div className="col">
                        <h3 className="mb-4">
                            <Link to="/accounting/income/invoice/list/all">
                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                     alt="back" className="back"/>
                            </Link>
                            <Translation>
                                {
                                    t => <span>{t('accounting:edit_invoice')}</span>
                                }
                            </Translation>
                        </h3>
                    </div>
                </div>
                <div className="content-inner">
                <QueryRenderer
                    environment={environment}
                    query={invoiceEdit}
                    fetchPolicy="no-cache"
                    variables={{"id": this.props.invoice_id,id_in: this.props.invoice_id}}
                    render={({error, props}) => {
                        if (props) {
                            return <InvoiceCreateUpdateForm
                                contactList={[props.invoice.contact.id]}
                                contact={props.invoice.contact} invoiceQuery={props}
                                payGroup={props.invoice.payGroup}
                                query={invoiceEdit}
                                mode="edit"
                                category={'invoice'}
                                transactions={props.allInvoiceTransaction.edges}
                                group_detail={props.invoice}
                                product_and_service={props.productViewer.allProduct.edges}
                                next_page={'/accounting/income/invoice/list/draft'}
                                back_page={'/accounting/income/invoice/list/all'}
                                project_have_vat={props.selfProject.haveVat}
                                fine_chart_of_account={_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'chart_of_account_sena_v2'])?.menu_on ? 
                                                        props.group113110.allChartOfAccount.edges 
                                                        : 
                                                        props.viewer.allChartOfAccount.edges}
                            />;
                        } else {
                            return <Loading/>
                        }
                    }}
                />
                </div>
            </div>
        )
    }
}

export default InvoiceEdit;