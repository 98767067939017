import i18next from 'i18next';
import React, { Component} from 'react';


class FixRequestStatusFast extends Component {
    
    render() {
        return (
            <div style={{ display: 'inline-flex' ,marginBottom: "20px" }}>
                <div className={'linestatus ml-1 mr-1 mt-4'} style={{top: this.props.status === "void" || this.props.status === "finish" ? '100px': '110px'}}></div>
                <div className={this.props.status === "review" || this.props.status === "new" ? "groudStatus active" : "groudStatus "}>
                    <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/1.png"} />
                    <p>{i18next.t("FixRequestStatusFast:New")}</p>
                </div>

                <div className='mr-3 ml-3'></div>
                <div className={this.props.status === "appointment" || this.props.status === "pending" ? "groudStatus active" : "groudStatus "}>
                    <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/2.png"} />
                    <p>{i18next.t("FixRequestStatusFast:Appointment")}</p>
                </div>

                <div className='mr-3 ml-3'></div>
                <div className={this.props.status === "repaired" ? "groudStatus active" : "groudStatus "}>
                    <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/3.png"} />
                    <p>{i18next.t("FixRequestStatusFast:Fixing")}</p>
                </div>

                <div className='mr-3 ml-3'></div>
                <div className={this.props.status === "edited" ? "groudStatus active" : "groudStatus "}>
                    <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/4.png"} />
                    <p>{i18next.t("FixRequestStatusFast:Edited")}</p>
                </div>

                {

                    this.props.status === "void" ?
                    <React.Fragment>
                        <div className='mr-3 ml-3'></div>
                        <div className={this.props.status === "void" ? "groudStatus active" : "groudStatus "}>
                            <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/void.png"} />
                            <p>{i18next.t("FixRequestStatusFast:Canceled")}</p>
                        </div>
                    </React.Fragment>

                    :

                    <React.Fragment>
                        <div className='mr-3 ml-3'></div>
                        <div className={this.props.status === "finish" ? "groudStatus active" : "groudStatus "}>
                            <img src={process.env.PUBLIC_URL + "/images/typeIcon/sing/statusType/5.png"} />
                            <p>{i18next.t("FixRequestModal:Finish this work")}</p>
                        </div>
                    </React.Fragment>
                }




            </div >
        )
    }
}


export default FixRequestStatusFast
