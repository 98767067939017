import React, { Component } from "react";
import {Translation} from "react-i18next";
class searchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: this.props.search,
      search_input: "",
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleSearchChange(e) {
    this.setState({ search_input: e.currentTarget.value });
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.props.callback_function(e.currentTarget.value);
    }
  }
  handleChange(e) {
    this.props.callback_function(e.currentTarget.value);
  }

  render() {
    return (
      <React.Fragment>
        <Translation>
          {t=>
          <div className="input-group">
            <input
              type="text"
              className="form-control input-size"
              placeholder={t("contact_list:Search")}
              value={this.state.search_input}
              onChange={this.handleSearchChange}
              onKeyDown={this.handleKeyDown}
            />
            <button
              type="submit"
              className="btn btn-primary form-control search-button"
              name="search"
              value={this.state.search_input}
              onClick={(e) => this.handleChange(e)}
            >
              {t("contact_list:Search")}
            </button>
          </div>
          }
        </Translation>
      </React.Fragment>
    );
  }
}

export default searchInput;
