import React from 'react';
import Modal from 'react-modal';
import { H5, LButton } from '../../components/Text/Text';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '24px 24px 16px 24px'
    },
};
function PeriodLockModal({ modal, handleModalSubmit, closeModal, status }) {
    return (
        <Modal
            isOpen={modal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
            appElement={document.getElementById('root') || undefined}
        >
            <div className="d-flex flex-column align-items-center" >
                <img src={process.env.PUBLIC_URL + "/images/icons/periodLock/icon_lock_confirm.png"} style={{ width: 78, height: 78 }} alt="confirm" ></img>
                <H5 className='mt-9' >คุณยืนยันที่จะ{`${!status ? 'ปิด' : 'เปิด'}`}งวดหรือไม่?</H5>
                <div className='d-flex mt-10' >
                    <LButton minWidth='112px' background='#D9D9D9' justifyContent='center' color='#4D4D4D' onClick={closeModal} >ยกเลิก</LButton>
                    <LButton minWidth='112px' justifyContent='center' onClick={handleModalSubmit} >ยืนยัน</LButton>
                </div>
            </div>

        </Modal>
    );
}

export default PeriodLockModal