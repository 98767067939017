import React from "react";
import { QueryRenderer } from "react-relay";
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Loading from "../../../libs/loading";
import i18next from "i18next";

class ContractServiceListTable extends ComponentPagination {
  componentDidUpdate(nextProps, nextState, nextContext) {
    if (this.state.status !== this.props.status) {
      this.setState(
        {
          status: this.props.status,
          checkList: [],
          check_all: false,
        },
        () => {
          this.goFirst();
        }
      );
    }
  }

  getColorAndText(object) {
    let text = "";
    let color = "text-center";
    switch (object.node.status) {
      case "PAID":
        text = "รับชำระแล้ว";
        break;
      default:
        text = "รายการที่ยกเลิก";
        color = "text-center text-danger";
    }
    return <td className={color}>{text}</td>;
  }

  render() {
    return (
      <QueryRenderer
        environment={environment}
        query={this.props.query}
        variables={{
          search: this.props.search,
          startDate:
            this.props.start_date &&
            format(this.props.start_date, "YYYY-MM-DD"),
          endDate:
            this.props.end_date && format(this.props.end_date, "YYYY-MM-DD"),
          first: this.state.first,
          last: this.state.last,
          status: this.props.status === "all" ? null : this.props.status,
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return (
              <React.Fragment>
                <div className="table-responsive fade-up">
                  <table className="table table-hover mt-2">
                    <thead className="thead-light">
                      <Translation>
                        {(t) => (
                          <tr>
                            <th width={130}>
                              {t("contract_service:Contract Number")}
                            </th>
                            <th>{t("contract_service:Date")}</th>
                            <th>{t("contract_service:Debtor Code")}</th>
                            <th>{t("contract_service:Contract Company")}</th>
                            <th>{t("contract_service:Category")}</th>
                            <th>{t("contract_service:Contract Start Date")}</th>
                            <th>
                              {t("contract_service:Contract Expiration Date")}
                            </th>
                            <th>{t("contract_service:Service fee/month")}</th>
                            {this.props.status !== "void" && (
                              <>
                                <th>{t("contract_service:Save As")}</th>
                                <th>{t("contract_service:Date Save")}</th>
                                <th>{t("contract_service:Status")}</th>
                              </>
                            )}
                            {this.props.status === "void" && (
                              <>
                                <th>{t("PageListPRPO:Cancled date")}</th>
                                <th>{t("PageListPRPO:Cancled By")}</th>
                                <th>{t("PageList:Reason Cancled")}</th>
                              </>
                            )}
                          </tr>
                        )}
                      </Translation>
                    </thead>
                    <tbody>
                      {props.allContractRevenue?.edges?.map(
                        (contract_revenue, index) => {
                          // let order_id = this.state.first - localStorage.getPageLimit();
                          // let accumulate = 0;
                          let status_text = "";
                          if (contract_revenue.node.status === "WAIT") {
                            status_text = "รอดำเนินการ";
                          } else if (
                            contract_revenue.node.status === "IN_PROGRESS"
                          ) {
                            status_text = "กำลังดำเนินการ";
                          } else if (
                            contract_revenue.node.status === "NEAR_END"
                          ) {
                            status_text = "ใกล้ครบกำหนดสัญญา";
                          } else if (contract_revenue.node.status === "RENEW") {
                            status_text = "ต่อสัญญา";
                          } else if (
                            contract_revenue.node.status === "NOT_RENEW"
                          ) {
                            status_text = "ไม่ต่อสัญญา";
                          } else {
                            status_text = i18next.t("Allaction:cancel");
                          }
                          return (
                            <tr key={contract_revenue.node.id}>
                              <td>
                                {contract_revenue.node.status === "VOID" ? (
                                  contract_revenue.node.docNumber
                                ) : (
                                  <Link
                                    to={
                                      "/accounting/income/contract_service/view/" +
                                      contract_revenue.node.id
                                    }
                                  >
                                    {contract_revenue.node.docNumber}
                                  </Link>
                                )}
                              </td>

                              <td>
                                {format(
                                  contract_revenue.node.issuedDate,
                                  "DD/MM/YYYY",
                                  { locale: thLocale }
                                )}
                              </td>

                              <td>{contract_revenue.node.debtor.refNumber}</td>
                              <td>{contract_revenue.node.debtor.name}</td>
                              <td>
                                {contract_revenue.node.chartOfAccount.name}
                              </td>
                              <td>
                                {format(
                                  contract_revenue.node.startContractDate,
                                  "DD/MM/YYYY",
                                  { locale: thLocale }
                                )}
                              </td>
                              <td>
                                {format(
                                  contract_revenue.node.endContractDate,
                                  "DD/MM/YYYY",
                                  { locale: thLocale }
                                )}
                              </td>

                              <td>
                                {numberWithComma(contract_revenue.node.total)}
                              </td>

                              {this.props.status !== "void" && (
                                <>
                                  <td>{contract_revenue.node.creator}</td>
                                  <td>
                                    {format(
                                      contract_revenue.node.added,
                                      "DD/MM/YYYY",
                                      { locale: thLocale }
                                    )}
                                  </td>
                                  <td>{status_text}</td>
                                </>
                              )}

                              {this.props.status === "void" && (
                                <>
                                  <td>
                                    {format(
                                      contract_revenue.node.voidDate,
                                      "DD/MM/YYYY",
                                      { locale: thLocale }
                                    )}
                                  </td>
                                  <td>{contract_revenue.node.voider}</td>
                                  <td>{contract_revenue.node.voidRemark}</td>
                                </>
                              )}
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <Pagination
                    changePage={this.changePage}
                    first={this.state.first}
                    last={this.state.last}
                    totalCount={props.allContractRevenue?.totalCount}
                  />
                </div>
              </React.Fragment>
            );
          }
          return <Loading />;
        }}
      />
    );
  }
}

export default ContractServiceListTable;
