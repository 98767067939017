import React, {Component} from 'react';
import './fixRequest/styles/fixRequest.scss';
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import {graphql} from "babel-plugin-relay/macro";
import {commitMutation, QueryRenderer} from "react-relay";
import environment from "../env/environment";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import _ from "lodash";
import Lightbox from "react-images";
import { Translation } from "react-i18next";
import i18next from 'i18next';
import Loading from "../libs/loading";

const query = graphql`
    query chatDetailQuery($id: ID!) {
        room(id: $id) {
            id
            name
            detail
            status
            image
            added
            note
            phone
            fixRequestRoom {
                id
                fixType
                technician
                startDate
                startTime
                endTime
                contactPerson
                contactNumber
                technicianName
            }
            handle {
                tenant {
                    firstName
                    lastName
                    phone
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation chatDetailMutation($input: UpdateRoomInput!) {
        updateRoom(input: $input){
            newRoom {
                id
            }
        }
    }`;

class ChatDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            status: false,
            image_light_box: '',
            open_light_box: false,
        };
        this.toggleLightBox = this.toggleLightBox.bind(this);
    }

    changeStatus(status) {
        if (status === 'not-approve') {
            Swal.fire({
                title: i18next.t('fix_request:note'),
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: i18next.t('fix_request:save_record'),
                cancelButtonText: i18next.t("fix_request:cancel"),
                showLoaderOnConfirm: true,
                preConfirm: (note) => {
                    if (note !== '') {
                        let variables = {
                            input: {
                                clientMutationId: this.props.id,
                                status: status,
                                note: note
                            }
                        };
                        this.mutationStatus(variables, status)

                    } else {
                        return Swal.showValidationMessage(i18next.t('fix_request:input_reason'))
                    }


                },
                allowOutsideClick: () => !Swal.isLoading()
            });

        } else if (status === 'approve' || status === 'finished') {
            let variables = {
                input: {
                    clientMutationId: this.props.id,
                    status: status,
                }
            };
            this.mutationStatus(variables, status)
        }
    }

    mutationStatus(variables, status) {
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    if (response.updateRoom.newRoom.id) {
                        Swal.fire(i18next.t('fix_request:save_success'), '', 'success').then(() => {
                            this.setState({status: status})
                        })
                    } else {
                        Swal.fire(i18next.t('fix_request:save_unsuccess'), '', 'warning');
                    }
                },
                onError: (err) => {
                    Swal.fire(i18next.t('fix_request:save_unsuccess'), i18next.t('fix_request:try_again'), 'error');
                },
            },
        );
    }

    toggleLightBox(open_light_box, image = '') {
        this.setState({open_light_box: open_light_box, image_light_box: image})
    }

    render() {
        if (this.state.status) {
            return <Redirect to={"/contact/"+ this.props.page +"/" + this.state.status}/>;
        }

        return (
            <div className="card card-border" onClick={()=>this.props.loadHistory(this.props.id)} >
                <div className="card-body">
                    <Translation>
                        {t => 
                    <QueryRenderer
                        environment={environment}
                        query={query}
                        variables={{id: this.props.id}}
                        render={({error, props}) => {
                            if (error) {
                                return <div>{error.message}</div>;
                            } else if (props) {
                                return (

                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col">
                                                <span
                                                    className="grey-color">{format(props.room.added, 'DD/MM/YYYY - HH:mm น.', {locale: thLocale})}</span>
                                            </div>
                                            {
                                                (props.room.status === 'OPEN' || props.room.status === 'APPROVE') &&
                                                    <div className="col text-right">
                                                        <span className="btn" id="dropdownMenuButton" data-toggle="dropdown"
                                                              aria-haspopup="true" aria-expanded="false">
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/choice.png'}
                                                                 alt="choice"/>
                                                        </span>
                                                        {
                                                            this.props.page === 'fix-request' ?
                                                                <React.Fragment>
                                                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_fix_request_approve'}) &&
                                                                    <div className="dropdown-menu"
                                                                         aria-labelledby="dropdownMenuButton">
                                                                        {
                                                                            props.room.status === 'OPEN' &&
                                                                            <React.Fragment>
                                                                            <span className="dropdown-item"
                                                                                onClick={() => this.changeStatus('approve')}>{t('fix_request:accept_request')}</span>
                                                                                <div className="dropdown-divider"/>
                                                                                <span className="dropdown-item"
                                                                                onClick={() => this.changeStatus('not-approve')}>{t('fix_request:reject_request')}</span>
                                                                            </React.Fragment>
                                                                        }

                                                                        {
                                                                            props.room.status === 'APPROVE' &&
                                                                            <React.Fragment>
                                                                            <span className="dropdown-item"
                                                                                onClick={() => this.changeStatus('finished')}>{t('fix_request:finished')}</span>
                                                                                <div className="dropdown-divider"/>
                                                                                <span className="dropdown-item"
                                                                                onClick={() => this.changeStatus('not-approve')}>{t('fix_request:dismiss_request')}</span>
                                                                            </React.Fragment>
                                                                        }

                                                                    </div>
                                                                    }
                                                                </React.Fragment>
                                                                :
                                                                <React.Fragment>
                                                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_chat_approve'}) &&
                                                                    <div className="dropdown-menu"
                                                                         aria-labelledby="dropdownMenuButton">
                                                                   <span className="dropdown-item"
                                                                         onClick={() => this.changeStatus('finished')}>ดำเนินการเสร็จสิ้น</span>
                                                                    </div>
                                                                    }
                                                                </React.Fragment>

                                                        }

                                                    </div>
                                            }
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col">
                                                {
                                                    props.room.fixRequestRoom &&
                                                    <span className="grey-color">
                                                        {props.room.fixRequestRoom.technician === 'IN_HOUSE' ? t("chat:in_house_handyman") : t("chat:commercial_handyman")} | {t("chat:inside_the_room")}
                                                    </span>
                                                }

                                                <h6 className="mt-1">{props.room.name}</h6>
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col">
                                                <p>{props.room.detail}</p>
                                            </div>
                                        </div>

                                        {
                                            props.room.image &&
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <img src={props.room.image}
                                                         style={{width: 80, height: 80, cursor: "pointer"}}
                                                         onClick={() => this.toggleLightBox(true, props.room.image)}
                                                         alt="fix"/>
                                                </div>
                                            </div>
                                        }
                                        {
                                            props.room.fixRequestRoom &&
                                                <React.Fragment>
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            {props.room.fixRequestRoom.startDate &&
                                                            <React.Fragment>
                                                                <span className="grey-color">วันเวลาที่สะดวก</span>
                                                                <p className="mt-1">
                                                                    {props.room.fixRequestRoom.startDate ? format(new Date(props.room.fixRequestRoom.startDate), 'DD/MM/YYYY', {locale: thLocale}) : '-'} {props.room.fixRequestRoom.startTime + ' น.'} - {props.room.fixRequestRoom.endTime + ' น.'}
                                                                </p>
                                                            </React.Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                        <span className="grey-color">{t("chat:resident")}</span>
                                                            {props.room.fixRequestRoom.contactPerson ?
                                                                <p>{props.room.fixRequestRoom.contactPerson}</p>
                                                                :
                                                                <p>{props.room.handle.tenant.firstName} {props.room.handle.tenant.lastName}</p>
                                                            }
                                                            <div className="form-inline">
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/phone-icon.png'}
                                                                    alt="phone-icon" className="mr-2"/>
                                                                {props.room.phone!=='-'?
                                                                    <span>{props.room.phone}</span>
                                                                    :
                                                                    props.room.fixRequestRoom.contactNumber ?
                                                                    <span
                                                                        className="mt-1">{props.room.fixRequestRoom.contactNumber}</span>
                                                                    :
                                                                    <span>{props.room.handle.tenant.phone}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                        }





                                        {/*<div className="row mt-2">
                                                  <div className="col">
                                                      <button className="btn btn-light" id="dropdownMenuButton"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true" aria-expanded="false">
                                                          <span className="grey-color">+ เพิ่ม tag</span>
                                                      </button>

                                                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                          <span className="dropdown-item">
                                                              งานด่วน
                                                          </span>

                                                          <div className="dropdown-divider"/>

                                                          <span className="dropdown-item">
                                                              งานเจ้าของร่วม/สมาชิก
                                                          </span>

                                                          <div className="dropdown-divider"/>

                                                          <span className="dropdown-item">
                                                            ช่างนอก
                                                          </span>

                                                          <div className="dropdown-divider"/>

                                                          <span className="dropdown-item grey-color small"
                                                                onClick={() => this.openModalReceive(1)}>
                                                              + เพิ่ม tag ใหม่
                                                          </span>
                                                      </div>
                                                  </div>
                                              </div>*/}
                                    </React.Fragment>
                                );
                            }
                            return <Loading/>
                        }}
                    />
                        }
                    </Translation>
                </div>
                <Lightbox
                    images={[{src: this.state.image_light_box}]}
                    onClose={() => this.toggleLightBox(false)}
                    isOpen={this.state.open_light_box}
                    backdropClosesModal={true}
                    showImageCount={false}
                />
            </div>

        )
    }
}

export default ChatDetail;
