import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import EmergencyList from "./emergencyList";
import NoMatch from "../../components/noMatch";
import EmergencyGroup from './emergencyGroup';

class Emergency extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={EmergencyList}/>
                <PrivateRoute exact path={`${this.props.match.url}/group`} component={EmergencyGroup}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Emergency;