import {
    commitMutation,

} from 'react-relay'
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
 

const mutation = graphql`
    mutation deleteEmergencyCallGroupMutation($input:DeleteEmergencyCallGroupInput!){
        deleteEmergencyCallGroup(input:$input) {
            ok
        }
    }
`;


export default function deleteEmergencyCallGroup(variables, callback, error_callback) {

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                if(response){
                    callback(response)
                }else{
                    error_callback(false);
                }
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
