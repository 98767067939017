import React, { Component } from "react";
import { Translation } from "react-i18next";

class searchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: this.props.search,
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSearchChange(e) {
    this.setState({ search: e.currentTarget.value });
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.props.callback_function(e.currentTarget.value);
    }
  }

  handleChange(e) {
    this.props.callback_function(e.currentTarget.value);
  }

  render() {
    return (
      <React.Fragment>
        <Translation>
          {t=>
          <div className="input-group">
            <input
              type="text"
              className="form-control search-input input-size"
              placeholder={t("residential:Search")}
              name="search"
              value={this.state.search}
              onChange={this.handleSearchChange}
              onKeyDown={this.handleKeyDown}
            />
            <button
              type="submit"
              className="btn btn-primary form-control search-button w-25"
              name="search"
              value={this.state.search}
              onClick={(e) => this.props.callback_function(e.currentTarget.value)}
            >
              {t("residential:Search")}
            </button>
          </div>}
        </Translation>
      </React.Fragment>
    );
  }
}

export default searchInput;
