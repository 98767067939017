import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import Navigation from "./navigation";

import {Link} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import {Translation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";

import PrepaidDepositListTable from "./prepaidDepositListTable"
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import _ from "lodash";

const allPrepaidDeposit = graphql`
    query prepaidDepositListQuery($status: String!, $search: String, $start_date: DateTime, $end_date: DateTime, $first: Int, $last: Int) {

        prepaidDepositViewer{
            allPrepaidDeposit(status:$status, search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {
                totalCount
                edges{
                    node{
                        id
                        docNumber
                        contact {
                            id
                            name
                            refNumber
                            firstName
                            lastName
                            typeOfContact
                        }
                        issuedDate
                        added
                        total
                        status
                        creator
                        updated
                        voidRemark
                        prepaidDepositTransaction{
                            edges{
                                node{
                                    id
                                    total
                                    description
                                    price
                                    balance
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

class PrepaidDepositList extends Component {

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="prepaid-list">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('expense:prepaid_deposit')+" "+"(PD)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                            <div className="col">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_prepaid_deposit_create'}) &&
                                <Link to="/accounting/expense/prepaid-deposit/form/select_customer">
                                    <button type="button" className="btn btn-primary add float-right">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                        <Translation>
                                            {
                                                t => <span>{t('accounting:add_prepaid_deposit')}</span>
                                            }
                                        </Translation>
                                    </button>
                                </Link>
                                }
                            </div>
                        </div>
                        <Translation>{(t)=>
                            <div className="content-inner">
                                <Navigation/>
                                <PrepaidDepositListTable query={allPrepaidDeposit} status={this.props.match.params.status}/>
                            </div>
                        }
                        </Translation>
                        
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default PrepaidDepositList;