import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import Swal from "sweetalert2";
import settingAPI from '../../api/setting'
import * as htmlToImage from 'html-to-image';
import upload from '../../libs/upload';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import { format } from "date-fns";
import API from '../../api/silverGraund'
import API2 from '../../api/silverGraund2'
import { Translation } from "react-i18next";
import numberWithComma from "../../libs/numberWithComma";
import '../setting.scss'
import _ from "lodash";
import i18next from "i18next";


const query = graphql`
    query ReportParkingFeePrintQuery{
        selfProject {
            name
            logo
            address
            typeOfAccount
        }  
}`;
class ReportParkingFeePrint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            settingCalu: [],
            hours: null,
            minutes: null,
            projectData: [],
            loading: false
        }

        this.print = this.print.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.callUpload = this.callUpload.bind(this);
        this.getTime = this.getTime.bind(this)
    }

    print() {
        window.print();
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/vms/" + path, file);
            resolve(res);
        });
    };




    getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }



    sendEmail = async () => {

        let new_file;

        this.setState({
            loading: true
        })



        await htmlToImage.toBlob(document.getElementById('wrapperReportParkingFee'), { quality: 0.95 })
            .then(function (dataUrl) {  
            new_file = new File([dataUrl], `${'receipt_' + new Date().getTime()}.jpeg`);
        });

        let dese;
        await this.callUpload('image', new_file).then((data) => {
            if(data.result.ok){
                dese = "vms/image/" + new_file.name
            }
        });

        let data = {
            "carTransactionId": this.state.data.carTransactionId,
            "email": this.state.data.email,
            "image":dese
        }
 
         API2.postSentEmail(data).then((res) => {
            if(res.data){
                if(res.data.statusMessage === "SUCCESS"){
                Swal.fire(i18next.t("Allaction:Saved Successfully"), 'ส่งอีเมลเรียบร้อยแล้ว', 'success')
                    this.setState({
                        loading:false
                    }) 
                }else{
                    Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:You have already created the data."), 'error') 
                    this.setState({
                        loading:false
                    }) 
                }
            }
            }, () => {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Saved Unsuccessful"), 'error')
            })

    };

    componentDidMount() {
        this.getCarParkingFeeReceipt()
        this.getcaluletePackingSetting()
        this.getSelfProject()
    }

    getSelfProject() {
        fetchQuery(environment, query).then(data => {
            if (data.selfProject) {
                let selfProject = {}
                selfProject = data.selfProject
                this.getBase64FromUrl(data.selfProject.logo).then(async(res) => { return _.set(selfProject, `logo`, res) })
                this.setState({
                    projectData: selfProject
                })
            }
        })
    }

    getcaluletePackingSetting() {
        settingAPI.getcaluletePackingSetting().then((res) => {
            if (res.data.settingCalu) {
                this.setState({
                    settingCalu: res.data.settingCalu,
                    hours: res.data.settingCalu.etampTimerVistor ? res.data.settingCalu.etampTimerVistor : res.data.settingCalu.etampTimerVistorHH,
                    minutes: res.data.settingCalu.etampTimerVistor ? 0 : res.data.settingCalu.etampTimerVistorMM
                })
            }
        }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    getCarParkingFeeReceipt() {
        let idCode = this.props.match.params.id
        API.getCarParkingFeeReceipt(idCode).then((ress) => {
            if (ress.data.data) {
                let dataSet = {}
                dataSet = ress.data.data[0]
                this.getBase64FromUrl(ress.data.data[0].img).then(async(res) => { return _.set(dataSet, `logo`, res) })

                this.setState({ data: dataSet })
            }
        }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    getTime(type) {
        if (type === 'hours') {
            return parseInt(Math.abs(new Date(this.state.data.paymentDate) - new Date(this.state.data.startDate)) / (1000 * 60 * 60))
        } else {
            return parseInt(Math.abs(new Date(this.state.data.paymentDate).getTime() - new Date(this.state.data.startDate).getTime()) / (1000 * 60) % 60)
        }
    }

    render() {

        const { estampStatus, etampTimer, etampTimerHH, etampTimerMM, etampTimerVistor, etampTimerVistorHH, etampTimerVistorMM } = this.state.settingCalu
        let A = (this.getTime('hours') * 3600) + (this.getTime() * 60)
        let B = estampStatus ? etampTimer ? (etampTimer * 3600) : (etampTimerHH * 3600 + etampTimerMM * 60) : etampTimerVistor ? (etampTimerVistor * 3600) : (etampTimerVistorHH * 3600 + etampTimerVistorMM * 60)

        let hours;
        let minutes;
        let price;

        if (Math.sign(A - B) === 1) {
            hours = Math.floor((A - B) / 3600); // get hours
            minutes = Math.floor(((A - B) - (hours * 3600)) / 60); // get minutes
            price = (hours * parseFloat(this.state.settingCalu?.toPayCal) + (minutes >= this.state.settingCalu?.toCalOverTime ? parseFloat(this.state.settingCalu.toPayCal) : 0))
            // setOverTime({ hours, minutes, price: (hours * parseFloat(toPayCal) + (minutes >= toCalOverTime ? parseFloat(toPayCal) : 0)) })
        } else {
            //setOverTime(false)
        }

        return (
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>Receipt Print</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" crossorigin="anonymous" />
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <div className="row p-4" style={{ fontFamily: 'Kanit' }}>
                            <div className="col">
                                <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png"
                                    style={{ display: 'inline' }}
                                    alt="silverman" />
                            </div>
                            <div className="col">
                                <div style={{ float: 'right' }}>
                                    <button className="btn" onClick={this.print}
                                        style={{
                                            backgroundColor: '#ffff', color: '#1567B4', border: '1px solid #1567B4', display: 'inline-flex',
                                            cursor: 'pointer'
                                        }}>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/print-blue.png'} style={{ width: '16px' }} alt="print-icon" />
                                        <span className="pl-2"><Translation>{t => t("vmsDashboard:Print")}</Translation></span>
                                    </button>
                                    <button className="btn btn-primary ml-2" disabled={this.state.loading} onClick={this.sendEmail}>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/mail-while.png'}
                                            style={{ width: '16px', display: 'inline-flex', cursor: 'pointer' }} alt="print-icon" />
                                        <span className="pl-2"><Translation>{t => t("vmsDashboard:Send Email")}</Translation></span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div id="wrapperReportParkingFee" className="pt-6">
                    <Translation>
                        {t =>
                            <div className="pr-4 pl-4 pb-6  parking-fee" id="reportParkingFee">
                                <div className="text-right">
                                    <img
                                        src={this.state.projectData.logo}
                                        alt="svm"
                                        className="svm" style={{ width: '80px', height: 'auto' }}
                                    />
                                </div>
                                <div className="address-print">
                                    <h3><Translation>{t => t("vmsDashboard:Payment Receipt")}</Translation></h3>
                                    <div>
                                        <label style={{ marginBottom: '0' }}>{this.state.projectData.name}</label>
                                        <label>{this.state.projectData.address}</label>
                                    </div>
                                </div>
                                <div className="pt-6">
                                    <img src={this.state.data?.img} alt="svm"
                                        style={{maxHeight: '254.54px' }} />
                                    <p className="text-center pt-2"><Translation>{t => t("vmsDashboard:Vehicle Registration No.")}</Translation> {this.state.data?.carRegistration}, {this.state.data?.province}</p>
                                </div>
                                <div className="content-print">
                                    <div className='row'>
                                        <div className='col text-left'>
                                            <label><Translation>{t => t("silvermanguard:TimeUse")}</Translation> </label>
                                            <h4 style={{ color: '#1567B4' }}>{format(this.state.data.startDate, "HH:mm A")}</h4>
                                            <span>{format(this.state.data?.startDate, "DD/MM/YYYY")}</span>
                                        </div>
                                        <div className='col'>
                                            <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconArrowRight.png'}
                                                style={{ height: '30px', marginTop: "30px" }} alt="svm" />
                                        </div>
                                        <div className='col text-left'>
                                            <label><Translation>{t => t("vmsDashboard:End time for parking rights")}</Translation></label>
                                            <h4 style={{ color: '#CA0824' }}>{format(this.state.data.paymentDate, "HH:mm A")}</h4>
                                            <span>{format(this.state.data?.paymentDate, "DD/MM/YYYY")}</span>
                                        </div>
                                    </div>
                                    <div className='row pt-4'>
                                        <div className='col text-left'>
                                            <label><Translation>{t => t("vmsDashboard:Total parking time")}</Translation></label>
                                        </div>
                                        <div className='col'>
                                        </div>
                                        <div className='col text-left'>
                                            <label>{this.getTime('hours')} <Translation>{t => t("newBookingSingha_Create_step1:Hours")}</Translation> {this.getTime('minutes')} <Translation>{t => t("newBookingSingha_Create_step1:Minute")}</Translation></label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col text-left'>
                                            <label><Translation>{t => t("vmsDashboard:Free")}</Translation></label>
                                        </div>
                                        <div className='col'>
                                        </div>
                                        <div className='col text-left'>
                                            <label>{this.state.hours || 0} <Translation>{t => t("newBookingSingha_Create_step1:Hours")}</Translation> {this.state.minutes || 0} <Translation>{t => t("newBookingSingha_Create_step1:Minute")}</Translation></label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col text-left'>
                                            <label><Translation>{t => t("vmsDashboard:Over-parking time")}</Translation></label>
                                        </div>
                                        <div className='col'>
                                        </div>
                                        <div className='col text-left'>
                                            <label>{hours || 0}  <Translation>{t => t("newBookingSingha_Create_step1:Hours")}</Translation> {minutes || 0} <Translation>{t => t("newBookingSingha_Create_step1:Minute")}</Translation></label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col text-left'>
                                            <label><Translation>{t => t("vmsDashboard:Parking Fee")}</Translation></label>
                                        </div>
                                        <div className='col'>
                                        </div>
                                        <div className='col text-left'>
                                            <label>{this.state.settingCalu.toPayCal || 0} <Translation>{t => t("newBookingSingha_Create_step1:Hours")}</Translation>/<Translation>{t => t("newBookingSingha_Create_step1:Minute")}</Translation></label>
                                        </div>
                                    </div>
                                    <div className="pt-4">
                                        <p className="text-left" style={{ fontSize: '12px', color: '#4D4D4D' }}><Translation>{t => t("vmsDashboard:Remarks Parking more than")}</Translation> 30 <Translation>{t => t("vmsDashboard:minutes will be charged as 1 hour")}</Translation></p>
                                        <hr />
                                    </div>
                                    <div className='row'>
                                        <div className='col-8 text-left'>
                                            <h3><Translation>{t => t("vmsDashboard:Total Fee")}</Translation></h3>
                                            <lable><Translation>{t => t("vmsDashboard:Payment Method")}</Translation>: {this.state.data.methodPayment === 'cash' ? 'เงินสด' : 'QR CODE'}</lable>
                                        </div>
                                        <div className='col text-left'>
                                            <h3>{numberWithComma(price || 0, "0.00")} <Translation>{t => t("vmsDashboard:Baht")}</Translation></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>
                </div>


            </React.Fragment>
        );
    }
}

export default ReportParkingFeePrint;
