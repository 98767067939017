import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import "../../contact/styles/index.scss"
import './favorite.scss'

class MobilePreview extends Component{

    getIcon = (name) => {
        if(name === 'parcel'){
            return (
                <img
                src={process.env.PUBLIC_URL + '/images/icons/menu/parcel.png'}
                alt="parcel"  />
            );
        }
        else if(name === 'bill'){
            return (
                <img
                src={process.env.PUBLIC_URL + '/images/icons/menu/bill.png'}
                alt="bill"  />
            );
        }
        else if(name === 'survey'){
            return (
                <img
                src={process.env.PUBLIC_URL + '/images/icons/menu/survey.png'}
                alt="survey"  
                // style={{marginLeft:'20px'}}
                />
            );
        }
        else if(name === 'booking'){
            return (
                <img
                src={process.env.PUBLIC_URL + '/images/icons/menu/booking.png'}
                alt="booking"  />
            );
        }
        else if(name === 'meeting'){
            return (
                <img
                src={process.env.PUBLIC_URL + '/images/icons/menu/meeting.png'}
                alt="meeting"  />
            );
        }
        else if(name === 'complaint'){
            return (
                <img
                src={process.env.PUBLIC_URL + '/images/icons/menu/complaint.png'}
                alt="complaint"  />
            );
        }
        else if(name === 'emergency'){
            return (
                <img
                src={process.env.PUBLIC_URL + '/images/icons/menu/emergency.png'}
                alt="emergency"  />
            );
        }
        else if(name === 'project'){
            return (
                <img
                src={process.env.PUBLIC_URL + '/images/icons/menu/project.png'}
                alt="project"  />
            );
        }
        else if(name === 'visitor'){
            return (
                <img
                src={process.env.PUBLIC_URL + '/images/icons/menu/visitor.png'}
                alt="visitor"  />
            );
        }
        else if(name === 'fixrequest'){
            return (
                <img
                src={process.env.PUBLIC_URL + '/images/icons/menu/fixrequest.png'}
                alt="fixrequest"  />
            );
        }
        else{
            return (
                <div />
                );
        }
    }



    render(){

        const rows = [...Array( Math.ceil(this.props.favorite.length / 4) )];
        const productRows = rows.map( (row, idx) => this.props.favorite.slice(idx * 4, idx * 4 + 4) ); //chunk for each row

        return(
            <Translation>
                {t =>
                    <div id="favorite">
                        <h6 className="text-center mt-5">{t("press_release:display_ex")}</h6>
                        <div className="phone-preview">
                            <div className="inner" >
                            <div className='p-2 bg-grey'>
                                {
                                productRows.length > 0 ?
                                    productRows.map((row, idx) => (
                                        <div className="row d-flex justify-content-around" key={idx}>    
                                          { 
                                            row.map(item => {
                                                return (
                                                    <div className='box-icon  justify-content-center row' style={{width:'25%'}}>
                                                        {this.getIcon(item)}
                                                        <Translation>{t => <span className=' align-items-center col-12' style={{width:'100%'}}>{t("favoriteIcon:"+item)}</span>}</Translation>
                                                    </div>
                                                )
                                            }
                                             )
                                          }
                                        </div> 
                                    )
                                    ) : 
                                    <div className="row d-flex justify-content-around">
                                        <div className='box-icon d-flex justify-content-center row align-items-center' style={{width:'25%'}}>
                                                <div className='col-12' />
                                        </div>
                                    </div>
                                }
                            </div>
                                {/* <div className='p-2'>
                                    <div className='row d-flex justify-content-around bg-grey'>
                                        {this.props.favorite.length > 0 ?
                                            this.props.favorite.map((item,index) => {
                                                return(
                                                    <div className='box-icon d-flex justify-content-center row' style={{width:'25%'}}>
                                                        {this.getIcon(item)}
                                                        <Translation>{t => <span className='d-flex align-items-center col-12' style={{width:'100%'}}>{t("favoriteIcon:"+item)}</span>}</Translation>
                                                    </div>
                                                );
                                            })
                                         :
                                            <div className='box-icon d-flex justify-content-center row align-items-center' style={{width:'25%'}}>
                                                <div className='col-12' />
                                            </div>
                                        }
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                }
            </Translation>

        );
    }


}export default MobilePreview;