import React, { Component} from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import Wrapper from "../../../components/wrapper";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import _ from "lodash";
import Navigation from '../navigation';
import ownershipAPI from '../../ownership/ownershipAPI';
import SearchProject from '../../../project/searchProject';
import residentialAPI from "../../serviceAPI"
import ReactPaginate from '../../../libs/ReactPaginate';

class OwnershipListDetail extends Component {

    constructor(props){
        super(props)
        this.state = {
            ownershipList : [],
            start_date : '',
            end_date : '',
            search : '',
            order : false,
            token : localStorage.getItem("token"),
            lastDateConveyance : '',
            loading : false,
            totalCount : 0,
            first: 0,
            last: 10,
            page_count: 0,
            num_record: 0,
            all_item: 10,
            page_select: 0,
            index_pages: {},
            indexFirst : 0
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (prevState.page_select !== this.state.page_select) {
            this.fetchData(true);
        }
    }

    componentWillMount(){
        residentialAPI.getResidential(this.state.token,atob(this.props.match.params.id).split(":")[1]).then((response) => {
            if(response.data){
                this.setState({
                    lastDateConveyance : response.data.dateConveyance,
                })
            }
        })
        this.fetchData()
    }

    fetchData = (update) => {
        ownershipAPI.getOwnershipList(this.state.token,atob(this.props.match.params.id).split(":")[1] , this.state.order ? 'order' : '' , 
            this.state.start_date,this.state.end_date,this.state.search,
            update ? this.state.index_pages[this.state.page_select].first : this.state.first,
            update ? this.state.index_pages[this.state.page_select].last : this.state.last)
        .then((response) => {
            if(response.data.totalCount !== 0){
                let first_index = _.cloneDeep(this.state.first);
                let last_index = _.cloneDeep(this.state.last);
                let index_pages = {};
                let page_count = Math.ceil(response.data.totalCount / this.state.all_item);
                for (let index = 0; index < page_count; index++) {
                    index_pages[index] = {
                    first: first_index,
                    last: last_index,
                    };
                    if (index === page_count - 2) {
                    first_index += this.state.all_item;
                    last_index +=
                        response.data.totalCount % this.state.all_item !== 0
                        ? response.data.totalCount % this.state.all_item
                        : this.state.all_item;
                    } else {
                    first_index += this.state.all_item;
                    last_index += this.state.all_item;
                    }
                }
                this.setState({
                    page_count: page_count,
                    num_record: response.data.totalCount,
                    index_pages: index_pages,
                
                },() => this.setState({
                    indexFirst : this.state.index_pages[this.state.page_select].first
                }))
            }else{
                this.setState({
                    page_count: 0,
                    num_record: response.data.totalCount,
                    index_pages: {},
                },() => this.setState({
                    indexFirst : 0
                }))
            }
            this.setState({
                ownershipList : response.data.ownershipTenant,
                num_record: response.data.totalCount,
            })
        })
    }

    handleSearch = (text) => {
        this.setState({search: text} ,() => this.fetchData())
    }
    handleDate = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        }, () => this.fetchData())
    }

    handleOrder = () => {
        this.setState({
            order : !this.state.order
        },() => this.fetchData())
    }

    changePageLimit = (value) => {
        this.setState({
          first: 0,
          last: value,
          all_item: value,
        },() => this.fetchData());
    }

    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({ page_select: page });
    }

    render(){
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        return(
            <Wrapper>
                <Header />
                <Sidebar />
                <Translation>
                    {
                        t =>
                            <WrapperContent>
                                <div className="container-fluid box">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/register/residential/list/all">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span>{
                                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                textData?.data?.ownershipListDetail.ownershipTransferHistory    
                                                :
                                                t("ownership_history:Ownership transfer history")
                                                }</span>
                                            </h3>
                                        </div>
                                        <div className="col">
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'register_ownership_create'}) &&
                                                <Link to={"/register/residential/detail/change-ownership-detail/" + this.props.match.params.id}>
                                                    <button style={{backgroundColor: '#1567B4' , color: "#fff"}}
                                                        type="button"
                                                        className="btn add float-right"
                                                    >
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                                                            alt="plus"
                                                        />
                                                        <span>{
                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                        textData?.data?.ownershipListDetail.changeOwnership    
                                                        :                                                        
                                                        t("ownership_history:Change Ownership")
                                                        }</span>
                                                    </button>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                    <div className="content-inner">
                                        <Navigation id={this.props.match.params.id} />
                                        <div className="row mb-2 mt-1">
                                            <div className="col">
                                                <span className="d-flex ql-align-center">{
                                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                textData?.data?.ownershipListDetail.lastestTransferDate    
                                                        :                                                            
                                                t("ownership_history:Latest ownership transfer date")
                                                } {this.state.lastDateConveyance}</span>
                                            </div>
                                            <div className="col-4 w-auto mb-2">
                                                <div className="form-inline  float-right">
                                                    <div className="form-group mb-2">
                                                        <label className="mr-2">{t("ownership_history:Date")}</label>
                                                        <DatePickerAdapter
                                                            id="start_date"
                                                            className="form-control"
                                                            name="start_date"
                                                            selected={this.state.start_date}
                                                            onChange={this.handleDate}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2">
                                                        <Translation>
                                                            {t=>
                                                            <label htmlFor="end_date" className="ml-3 mr-3">
                                                            {t("keycard:To")}
                                                            </label>}
                                                        </Translation>
                                                        <DatePickerAdapter
                                                            id="end_date"
                                                            name="end_date"
                                                            className="form-control"
                                                            selected={this.state.end_date}
                                                            onChange={this.handleDate}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3 float-right w-auto mb-2">
                                                <SearchProject callback_function={this.handleSearch} search={this.state.search}/>
                                            </div>
                                        </div>
                                        <div className="table-responsive fade-up card">
                                            <table className="table">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th width="100" className="text-center">
                                                            <span onClick={() => this.handleOrder()} className="cursor">{
                                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                            textData?.data?.ownershipListDetail.transferDate    
                                                            :                                                                       
                                                            t("ownership_history:Transfer date")
                                                            }
                                                            {!this.state.order ? (
                                                                    <img
                                                                    src={
                                                                        process.env.PUBLIC_URL +
                                                                        "/images/icons/sort-down.png"
                                                                    }
                                                                    alt="sort-down-icon"
                                                                    className="ml-1"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                    src={
                                                                        process.env.PUBLIC_URL +
                                                                        "/images/icons/sort-up.png"
                                                                    }
                                                                    alt="sort-up-icon"
                                                                    className="ml-1"
                                                                    />
                                                            )}
                                                            </span>
                                                        </th>
                                                        <th width="80" className="text-center">{t("ownership_history:Room No.")}</th>
                                                        <th width="200" >{
                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                        textData?.data?.ownershipListDetail.assignor    
                                                        :                                                            
                                                        t("ownership_history:Assignor")
                                                        }</th>
                                                        <th width="200" >{
                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                        textData?.data?.ownershipListDetail.assignee   
                                                        :                                                                
                                                        t("ownership_history:Assignee")
                                                        }</th>
                                                        <th width="200" className="text-center">{t("ownership_history:Saved by")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.ownershipList.length > 0 ? this.state.ownershipList.map((owner,index) => {
                                                    let lastOwnership = this.state.order ?   0 : (this.state.ownershipList.length -1)
                                                    return(
                                                        <tr  className="text-center" key={index} style={{background: index === lastOwnership ? '#F2F9FF' : ''}}>
                                                            <td >
                                                                {lastOwnership === index ?
                                                                    <strong>{owner.dateConveyance}</strong>
                                                                    :
                                                                    <span>{owner.dateConveyance}</span>
                                                                }
                                                            </td>
                                                            <td  className="text-center">
                                                                {owner.residential}
                                                            </td>
                                                            <td className ="text-left">
                                                                {owner.transferOfOwnership.map((ownershipName,indexOwner) =>{
                                                                    return <div key={indexOwner}><span>{ownershipName}</span><br /></div>
                                                                })}
                                                            </td>
                                                            <td className ="text-left">
                                                                {owner.owner.map((ownershipName,indexOwner) =>{
                                                                    return <div key={indexOwner}><span>{ownershipName}</span><br /></div>
                                                                })}
                                                            </td>
                                                            <td  className="text-center">
                                                                {owner.creator}
                                                            </td>
                                                        </tr>
                                                        
                                                    );

                                                    })
                                                    :
                                                    <tr>
                                                        <td className="text-center" colSpan={5} >
                                                            <h5 style={{color: '#E14558'}}>{t("changeOwnership:Not transfer ownership history")}</h5>
                                                        </td>
                                                    </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <ReactPaginate
                                        state={this.state}
                                        changePageLimit={this.changePageLimit}
                                        handlePageClick={this.handlePageClick}
                                    />
                                </div>
                            </WrapperContent>
                    }
                </Translation>
            </Wrapper>
        )
    }
}

export default OwnershipListDetail