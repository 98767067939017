import "../styles/createBudget.scss";
import React from "react";
import _ from "lodash";
import { addMonths, format } from "date-fns";
import thLocale from "date-fns/locale/th";
import Loading from "../../../libs/loading";
import numberWithCommas from "../../../libs/numberWithComma";
import { H6, P2, Td, Th } from '../../../components/Text/Text'
import i18next from "i18next";
import GetdateOnEngAndTh from "../../../components/Action/getdateOnEngAndTh";

const MonthlyBudgetUsageTableSummary = ({ state, last_index, report = false, toggleHandleScroll }) => {
    return (
        !state.disabled_table ? <React.Fragment >
            <div className="fade-up">
                <div className="table-responsive2" style={{ overflowX: "auto", width: "100%", display: "block" }} onScroll={() => toggleHandleScroll("table-responsive2")}>
                    <div className="table" style={{ width: `${state.widthTable1}px`, height: "20px", margin: 0 }}></div>
                </div>
                <div className="table-responsive" onScroll={() => toggleHandleScroll("table-responsive")}>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <Th rowSpan={2} className="text-center">{i18next.t("monthlyBudgetUsageTable:Sequence")}</Th>
                                <Th rowSpan={2} className="text-center">{i18next.t("monthlyBudgetUsageTable:Items")}</Th>
                                <Th rowSpan={2} className="text-center" style={{ minWidth: 170 }} >
                                    {i18next.t("budgetUsageSummaryReport:Annual Budget")}
                                    <div>
                                        <GetdateOnEngAndTh
                                            date={state.start_date}
                                            withMonthShort={true}
                                            withYearShort={true}
                                        />
                                        -
                                        <GetdateOnEngAndTh
                                            date={state.date_search}
                                            withMonthShort={true}
                                            withYearShort={true}
                                        />
                                    </div>
                                </Th>
                                <Th colSpan={state.list_month.length} className="text-center">{i18next.t("monthlyBudgetUsageTable:Actual")}</Th>
                                <Th rowSpan={2} className="text-center">
                                    {i18next.t("monthlyBudgetUsageReportSummary:Accumulated Actual")}
                                    <div>
                                        <GetdateOnEngAndTh
                                            date={state.start_date}
                                            withMonthShort={true}
                                            withYearShort={true}
                                        />
                                        -
                                        <GetdateOnEngAndTh
                                            date={state.date_search}
                                            withMonthShort={true}
                                            withYearShort={true}
                                        />
                                    </div>
                                </Th>
                                <Th rowSpan={2} className="text-center">%</Th>
                                <Th rowSpan={2} className="text-center">
                                    <H6>{i18next.t("budgetUsageSummaryReport:Remaining Budget")}</H6>
                                    {format(state.end_date, "MMMYY", { locale: thLocale }) < format(state.date_search, "MMMYY", { locale: thLocale }) &&
                                        <H6>
                                            {format(addMonths(new Date(state.date_search), 1), " MMMYY-", { locale: thLocale })}
                                            {format(state.end_date, "MMMYY", { locale: thLocale })}
                                        </H6>}
                                </Th>
                                <Th rowSpan={2} className="text-center">%</Th>
                            </tr>
                            <tr className="border-top">{state.list_month.map((item, index) => <Th key={index} className="text-center">{format(item, "MMMYY", { locale: thLocale })}</Th>)}</tr>
                        </thead>
                        <tbody>
                            {_.map(state.lists[4], (item, index) => {
                                ++last_index;
                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <Td className="text-center">{last_index}</Td>
                                            <Td style={{ paddingLeft: 50, minWidth: 300 }}>{index == 0 && <H6 style={{ marginLeft: -32 }}>รายรับ</H6>}<P2>{item.name}</P2></Td>
                                            <Td className="text-center" width={270}>{numberWithCommas(item.total_budget)}</Td>
                                            {item.month_budget_happend.map((month, index_month) => <Td key={index_month} style={{ minWidth: 85 }} height={24} className="text-center td-red">{numberWithCommas(month.totalMonthHappend)}</Td>)}
                                            <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.total_happend)}</Td>
                                            <Td style={{ minWidth: 130 }} className="text-center td-red">{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.percent_use_budget)}</Td>
                                            <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.budget_remaining)}</Td>
                                            <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.percent_budget_remaining)}</Td>
                                        </tr>
                                        {index === state.lists[4].length - 1 && (
                                            <tr className="td-sum ">
                                                <Td />
                                                <Td style={{ paddingLeft: 50, minWidth: 300 }}>รวมรายรับ</Td>
                                                <Td className="text-center" width={270}>{numberWithCommas(state.sum_income.sum_total_budget)}</Td>
                                                {state.sum_income.sum_total_month_happend.map((item, index) => <Td width={180} key={index} height={24} className="text-center">{numberWithCommas(item)}</Td>)}
                                                <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(state.sum_income.sum_total_happend)}</Td>
                                                <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(state.sum_income.sum_percent_diff_past_happend)}</Td>
                                                <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(state.sum_income.sum_budget_remaining)}</Td>
                                                <Td style={{ minWidth: 130 }} className="text-center">{numberWithCommas(state.sum_income.sum_percent_budget_remaining)}</Td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            {_.map(state.lists[5], (item, index_code) => {
                                return (
                                    <React.Fragment key={index_code}>
                                        {item.child.length > 0 && (
                                            <React.Fragment>
                                                <tr>
                                                    <Td />
                                                    <Td><div className="ml-2">{index_code == 0 && <H6>รายจ่าย</H6>}<P2>{item.name}</P2></div></Td>
                                                </tr>
                                                {_.map(item.child, (item_child, index) => {
                                                    ++last_index;
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <Td className="text-center">{last_index}</Td>
                                                                <Td style={{ paddingLeft: 50, minWidth: 300, }}>{item_child.name}</Td>
                                                                <Td className="text-center" width={270}>{numberWithCommas(item_child.total_budget)}</Td>
                                                                {item_child.month_budget_happend.map((month, index_month) => <Td key={index_month} height={24} className="text-center td-red">{numberWithCommas(month.totalMonthHappend)}</Td>)}
                                                                <Td className="text-center">{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.total_happend)}</Td>
                                                                <Td className="text-center td-red">{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.percent_use_budget)}</Td>
                                                                <Td className="text-center">{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.budget_remaining)}</Td>
                                                                <Td className="text-center">{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.percent_budget_remaining)}</Td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}

                                                <tr className="td-sum ">
                                                    <Td />
                                                    <Td style={{ paddingLeft: 50, minWidth: 300, }}>รวม{item.name}</Td>
                                                    <Td className="text-center" width={270}>{numberWithCommas(state.sum_expenses[item.key].sum_total_budget)}</Td>
                                                    {state.sum_expenses[item.key].sum_total_month_happend.map((item, index) => <Td key={index} height={24} className="text-center">{numberWithCommas(item)}</Td>)}
                                                    <Td className="text-center">{numberWithCommas(state.sum_expenses[item.key].sum_total_happend)}</Td>
                                                    <Td className="text-center">{numberWithCommas(state.sum_expenses[item.key].sum_percent_diff_past_happend)}</Td>
                                                    <Td className="text-center">{numberWithCommas(state.sum_expenses[item.key].sum_budget_remaining)}</Td>
                                                    <Td className="text-center">{numberWithCommas(state.sum_expenses[item.key].sum_percent_budget_remaining)}</Td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            <tr className="td-sum2 ">
                                <Td />
                                <Td style={{ paddingLeft: 50, minWidth: 300 }}>รวมรายจ่าย</Td>
                                <Td className="text-center" width={270}>{numberWithCommas(state.total_sum_expenses.sum_total_budget)}</Td>
                                {state.total_sum_expenses.sum_total_month_happend.map((item, index) => <Td key={index} height={24} className="text-center">{numberWithCommas(item)}</Td>)}
                                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.sum_total_happend)}</Td>
                                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.sum_percent_diff_past_happend)}</Td>
                                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.sum_budget_remaining)}</Td>
                                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.sum_percent_budget_remaining)}</Td>
                            </tr>
                            <tr className="td-sum3 ">
                                <Td />
                                <Td style={{ paddingLeft: 50, minWidth: 300 }}> รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด </Td>
                                <Td className="text-center" width={270}>{numberWithCommas(state.sum_income.sum_total_budget - state.total_sum_expenses.sum_total_budget)}</Td>
                                {state.total_sum_expenses.sum_total_month_happend.map((item, index) => <Td key={index} height={24} className="text-center">{numberWithCommas(state.sum_income.sum_total_month_happend[index] - item)}</Td>)}
                                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.sum_total_happend - state.sum_income.sum_total_happend)}</Td>
                                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.sum_percent_diff_past_happend - state.sum_income.sum_percent_diff_past_happend)}</Td>
                                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.sum_budget_remaining - state.sum_income.sum_budget_remaining)}</Td>
                                <Td className="text-center">{numberWithCommas(state.total_sum_expenses.sum_percent_budget_remaining - state.sum_income.sum_percent_budget_remaining)}</Td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment> : <Loading />
    );
};


export default MonthlyBudgetUsageTableSummary;