/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreatePettyCashRecordInput = {|
  issuedDate?: ?any,
  setPettyCash: string,
  transactionList: any,
  asset?: ?any,
  clientMutationId?: ?string,
|};
export type pettyCashRecordCreateMutationVariables = {|
  input: CreatePettyCashRecordInput
|};
export type pettyCashRecordCreateMutationResponse = {|
  +createPettyCashRecord: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type pettyCashRecordCreateMutation = {|
  variables: pettyCashRecordCreateMutationVariables,
  response: pettyCashRecordCreateMutationResponse,
|};
*/


/*
mutation pettyCashRecordCreateMutation(
  $input: CreatePettyCashRecordInput!
) {
  createPettyCashRecord(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePettyCashRecordPayload",
    "kind": "LinkedField",
    "name": "createPettyCashRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pettyCashRecordCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pettyCashRecordCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "30457f4a8316edc5f7af9d5f38f3ed04",
    "id": null,
    "metadata": {},
    "name": "pettyCashRecordCreateMutation",
    "operationKind": "mutation",
    "text": "mutation pettyCashRecordCreateMutation(\n  $input: CreatePettyCashRecordInput!\n) {\n  createPettyCashRecord(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf6bdc7c255dd5d240ff66023f1a2201';

module.exports = node;
