import React, {Component} from "react";
import {graphql} from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../../../env/environment";
import Swal from "sweetalert2";
import { Translation } from "react-i18next";
import i18n from 'i18next'

const mutation = graphql`
    mutation unknownReceiveVoidMutation($input: UpdateUnknownReceiveInput!){
        updateUnknownReceive(input: $input){
            newUnknownReceive {
                id
            }
            ok
            warningText
        }
    }`;

class UnknownReceiveVoid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
        this.deleteGroup = this.deleteGroup.bind(this)
    }

   async deleteGroup(e) {
        e.preventDefault();
        this.setState({loading: true});

       let {value: void_remark} = await Swal.fire({
           title: i18n.t("unknown_receive:Note"),
           input: 'text',
           showCancelButton: true,
           confirmButtonColor: '#3085d6',
           cancelButtonColor: '#d33',
           cancelButtonText: i18n.t("unknown_receive:Cancel"),
           confirmButtonText: i18n.t("unknown_receive:Confirm"),
           inputValidator: (value) => {
               if (!value) {
                   return i18n.t("unknown_receive:Please enter the cancellation note!")
               }
           }
       });

        if (void_remark) {
            let variables = {
                input: {
                    clientMutationId: this.props.id,
                    status: "void",
                    voidRemark: void_remark
                }
            };

            commitMutation(environment, {
                mutation,
                variables,
                onCompleted: (response, errors) => {
                    this.setState({loading: false});
                    if (response.updateUnknownReceive.ok) {
                        Swal.fire(i18n.t("unknown_receive:Canceled successfully"), '', 'success').then(() => {
                            this.props.redirectToList()
                        });
                    } else {
                        if(response.updateUnknownReceive.warningText){
                            Swal.fire(i18n.t("unknown_receive:Canceled unsuccessfully"), response.updateUnknownReceive.warningText, 'warning');
                        }else{
                            Swal.fire(i18n.t("unknown_receive:Canceled unsuccessfully"), "", 'warning');
                        }
                        
                    }
                },
                onError: error => {
                    alert('server error')
                }
            });
        }
    }

    render() {
        return (
            <Translation>{t=>
            <button className="btn btn-danger add mr-3"
                    disabled={this.state.loading}
                    onClick={this.deleteGroup}>
                {this.state.loading && <span
                    className="spinner-border spinner-border-sm align-middle mr-2"/>}
                {t("unknown_receive:Canceled successfully")}
            </button>}
            </Translation>
        )
    }
}

export default UnknownReceiveVoid
