import React, { Component } from "react";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import { format } from "date-fns";
import "./css/contractServiceView.scss";
import i18next from "i18next";

const Excel = require("exceljs/dist/es5/exceljs.browser");

export default class exportContractService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: this.props.start_date,
      endDate: this.props.end_date,
      status: this.props.status,
      query: this.props.query,
      search: this.props.search,
      queryListExport: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.start_date !== prevProps.start_date) {
      this.setState({ startDate: this.props.start_date });
    }
    if (this.props.end_date !== prevProps.end_date) {
      this.setState({ endDate: this.props.end_date });
    }
    if (this.props.status !== prevProps.status) {
      this.setState({ status: this.props.status });
    }
  }

  fetchDataQuery = () => {
    fetchQuery(environment, this.state.query, {
      // receive parameter date search status
      startDate:
        this.state.startDate && format(this.props.start_date, "YYYY-MM-DD"),
      endDate: this.state.endDate && format(this.props.end_date, "YYYY-MM-DD"),
      search: this.state.search,
      status: this.state.status === "all" ? "" : this.state.status,
    }).then((data) => {
      this.setState({
        queryListExport: data.allContractRevenue,
      });
      // call downloadExcel
      this.state.queryListExport &&
        this.downloadExcel(this.state.queryListExport);
    });
  };

  downloadExcel = (query) => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("contract service");

    let status_text = "";
    if (this.state.status === "all") {
      status_text = "ทั้งหมด";
    } else if (this.state.status === "wait") {
      status_text = "รอดำเนินการ";
    } else if (this.state.status === "in_progress") {
      status_text = "กำลังดำเนินการ";
    } else if (this.state.status === "near_end") {
      status_text = "ใกล้ครบกำหนดสัญญา";
    } else if (this.state.status === "renew") {
      status_text = "ต่อสัญญา";
    } else if (this.state.status === "not_renew") {
      status_text = "ไม่ต่อสัญญา";
    } else {
      status_text = i18next.t("Allaction:cancel");
    }

    // set border
    let borders = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    // set widths
    let cellWidths = [];

    // description table
    const startDateHeader =
      this.state.startDate === null
        ? "-"
        : format(this.state.startDate, "DD-MM-YYYY");

    const endDateHeader =
      this.state.endDate === null
        ? "-"
        : format(this.state.startDate, "DD-MM-YYYY");

    worksheet.getCell("A1").value = "บันทึกสัญญาบริการพื้นที่ส่วนกลาง";
    worksheet.getCell("A1").font = { size: 16, bold: true };
    worksheet.getCell("A2").value = "สถานะ: " + status_text;
    worksheet.getCell("A2").font = { size: 16, bold: true };
    worksheet.getCell("A3").value =
      "เรียงตามวันที่ " + startDateHeader + " ถึง " + endDateHeader;
    worksheet.getCell("A3").font = { size: 12, bold: true };

    // head table
    let head_table_contract = [];
    if (this.state.status === "void") {
      head_table_contract = [
        "เลขที่สัญญา",
        "วันที่",
        "รหัสลูกหนี้",
        "บริษัทคู่สัญญา",
        "หมวดงาน",
        "วันที่เริ่มสัญญา",
        "วันที่หมดสัญญา",
        "ค่าบริการ",
        "วันที่ยกเลิก",
        "ผู้ยกเลิก",
        "เหตุผลที่ยกเลิก",
      ];
      cellWidths = [13, 11, 10, 25, 31, 12, 13, 8, 15, 16, 20];
    } else {
      head_table_contract = [
        "เลขที่สัญญา",
        "วันที่",
        "รหัสลูกหนี้",
        "บริษัทคู่สัญญา",
        "หมวดงาน",
        "วันที่เริ่มสัญญา",
        "วันที่หมดสัญญา",
        "ค่าบริการ",
        "บันทึกโดย",
        "วันที่บันทึก",
        "สถานะ",
      ];
      cellWidths = [13, 11, 10, 25, 31, 12, 13, 8, 15, 10, 18];
    }

    // add width and border to cell
    const headTable = worksheet.addRow(head_table_contract);
    headTable.eachCell((cell, colNumber) => {
      cell.border = borders;
      const column = worksheet.getColumn(colNumber);
      column.width = cellWidths[colNumber - 1];
    });

    // body table
    let data_table_row1 = [];
    for (let i = 0; i < query.edges.length; i++) {
      const data = query.edges[i];
      let status = "";
      if (data.node.status === "WAIT") {
        status = "รอดำเนินการ";
      } else if (data.node.status === "IN_PROGRESS") {
        status = "กำลังดำเนินการ";
      } else if (data.node.status === "NEAR_END") {
        status = "ใกล้ครบกำหนดสัญญา";
      } else if (data.node.status === "RENEW") {
        status = "ต่อสัญญา";
      } else if (data.node.status === "NOT_RENEW") {
        status = "ไม่ต่อสัญญา";
      } else {
        status = i18next.t("Allaction:cancel");
      }

      // data table
      data_table_row1.push(data.node.docNumber);
      data_table_row1.push(format(data.node.issuedDate, "DD/MM/YYYY"));
      data_table_row1.push(data.node.debtor.refNumber);
      data_table_row1.push(data.node.debtor.name);
      data_table_row1.push(data.node.chartOfAccount.name);
      data_table_row1.push(format(data.node.startContractDate, "DD/MM/YYYY"));
      data_table_row1.push(format(data.node.endContractDate, "DD/MM/YYYY"));
      data_table_row1.push(
        data.node.total ? data.node.total.toLocaleString() : ""
      );
      if (this.state.status === "void") {
        data_table_row1.push(format(data.node.voidDate, "DD/MM/YYYY"));
        data_table_row1.push(data.node.voider);
        data_table_row1.push(data.node.voidRemark);
      } else {
        data_table_row1.push(data.node.creator);
        data_table_row1.push(format(data.node.added, "DD/MM/YYYY"));
        data_table_row1.push(status);
      }

      const dataTable = worksheet.addRow(data_table_row1);
      dataTable.eachCell((cell) => {
        cell.border = borders;
      });
      data_table_row1 = [];
    }

    // Generate Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      let fileName =
        format(this.state.startDate, "DD/MM/YYYY") ===
        format(this.state.endDate, "DD/MM/YYYY")
          ? "contract-service" +
            format(this.state.startDate, "DD/MM/YYYY") +
            ".xlsx"
          : "contract-service" +
            format(this.state.startDate, "DD/MM/YYYY") +
            "-" +
            format(this.state.endDate, "DD/MM/YYYY") +
            ".xlsx";
      if (this.state.startDate === null) {
        fileName = "contract-service";
      }
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  render() {
    return (
      <button className="btn-export-contractService">
        <a target={"_blank"} onClick={this.fetchDataQuery}>
          {i18next.t("Allaction:Print")}
        </a>
      </button>
    );
  }
}
