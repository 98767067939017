/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createUpdatePetQueryVariables = {|
  id: string,
  residential_id: string,
|};
export type createUpdatePetQueryResponse = {|
  +residential: ?{|
    +name: string
  |},
  +pet: ?{|
    +id: string,
    +petType: string,
    +description: string,
    +imagePet: string,
    +healthCheckPath: ?string,
    +healthCheckName: ?string,
  |},
|};
export type createUpdatePetQuery = {|
  variables: createUpdatePetQueryVariables,
  response: createUpdatePetQueryResponse,
|};
*/


/*
query createUpdatePetQuery(
  $id: ID!
  $residential_id: ID!
) {
  residential(id: $residential_id) {
    name
    id
  }
  pet(id: $id) {
    id
    petType
    description
    imagePet
    healthCheckPath
    healthCheckName
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "residential_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "residential_id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "PetNode",
  "kind": "LinkedField",
  "name": "pet",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "petType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imagePet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "healthCheckPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "healthCheckName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createUpdatePetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialNode",
        "kind": "LinkedField",
        "name": "residential",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createUpdatePetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialNode",
        "kind": "LinkedField",
        "name": "residential",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "9adedf1c2190380c798149426daa9c50",
    "id": null,
    "metadata": {},
    "name": "createUpdatePetQuery",
    "operationKind": "query",
    "text": "query createUpdatePetQuery(\n  $id: ID!\n  $residential_id: ID!\n) {\n  residential(id: $residential_id) {\n    name\n    id\n  }\n  pet(id: $id) {\n    id\n    petType\n    description\n    imagePet\n    healthCheckPath\n    healthCheckName\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4333e0ffe30ddddb839d64f278b36a37';

module.exports = node;
