/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type sendDocTopMenuNavigationQueryVariables = {||};
export type sendDocTopMenuNavigationQueryResponse = {|
  +selfProject: ?{|
    +useAccount: boolean
  |}
|};
export type sendDocTopMenuNavigationQuery = {|
  variables: sendDocTopMenuNavigationQueryVariables,
  response: sendDocTopMenuNavigationQueryResponse,
|};
*/


/*
query sendDocTopMenuNavigationQuery {
  selfProject {
    useAccount
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useAccount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "sendDocTopMenuNavigationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "sendDocTopMenuNavigationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b1e5a36a95060afcf3b80f0a3e8955f",
    "id": null,
    "metadata": {},
    "name": "sendDocTopMenuNavigationQuery",
    "operationKind": "query",
    "text": "query sendDocTopMenuNavigationQuery {\n  selfProject {\n    useAccount\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ef22b2b9d71f9925851c2ebfc625f690';

module.exports = node;
