import React from "react";
import { Helmet } from "react-helmet";

const PrintA4horizontal = () => {
  return <Helmet
    style={[{
      "cssText": `
                   body {
                       width: 100%;
                       height: 100%;
                       margin: 0;
                       padding: 0;
                       background-color: #FAFAFA;
                     }
                   
                     * {
                       box-sizing: border-box;
                       -moz-box-sizing: border-box;
                     }
                   
                     .page {
                       width: 297mm;
                       min-height: 210mm;
                       max-height: 210mm;
                       position: relative;
                       padding: 5mm;
                       margin: 10mm auto;
                       border: 1px #D3D3D3 solid;
                       border-radius: 2px;
                       background: white;
                       box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                     }
                   
                     @page {
                       size: landscape;
                       margin: 0;
                     }
                     @media print {
                       html, body {
                         width: 297mm;
                         height: 210mm;
                       }
                       .page {
                         margin: 0;
                         border: initial;
                         border-radius: initial;
                         width: initial;
                         min-height: initial;
                         box-shadow: initial;
                         background: initial;
                         page-break-after: always;
                       }
                     }
               `
    }]}>
    <meta charSet="utf-8" />
    <title>Interest Print</title>
    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
  </Helmet>
}

export default PrintA4horizontal;
