import React, { Component } from "react";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import ContactTopMenuNavigation from "../contactTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18next from "i18next";
import _ from "lodash";
import FixRequestDashbroadChart from "./fixRequestDashBroadChart";
import getApiRoot from "../../libs/getAPIRoot";
import Loading from "../../libs/loading"
import { Dropdown  } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
class FixRequestDashbroad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      data: "",
      loading: false,
      dateSelected: `${i18next.t("fix_request:Previous 1 day")}`,
      mountFilterList: [],
      monthDateSelected: [],
      dataRange: [],
    };
  }

  componentWillMount() {
    // 1 is default filter
    this.getData(1);
    this.getFilterList();
  }

  handleChange = (e) => {
    let event = _.cloneDeep(e)
    this.setState(prevState => {
        return _.set(
            _.cloneDeep(prevState),
            event.target.name,
            event.target.value
        );
    })
  }

  getData(period, startDate, endDate, dateSelected){
    this.setState({loading: true})
    let repairStatus = "";
    let repairWorkingType = "";
    let repairFinishJob = "";
    let repairStaffJob = "";
    let repairStaffDay = "";
    let repairStaffTime = "";

    if(period !== ""){
      repairStatus = `${getApiRoot()}singha/repair_room_data?data_type=status&period=${period}`;
      repairWorkingType = `${getApiRoot()}singha/repair_room_data?data_type=working_type&period=${period}`;
      repairFinishJob = `${getApiRoot()}singha/repair_room_data?data_type=finish_job&period=${period}`;
      repairStaffJob = `${getApiRoot()}singha/repair_room_data?data_type=project_staff_job&period=${period}`;
      repairStaffDay = `${getApiRoot()}singha/repair_room_data?data_type=project_staff_day&period=${period}`;
      repairStaffTime = `${getApiRoot()}singha/repair_room_data?data_type=staff_time&period=${period}`;
    } else {
      repairStatus = `${getApiRoot()}singha/repair_room_data?data_type=status&start_date=${startDate}&end_date=${endDate}`;
      repairWorkingType = `${getApiRoot()}singha/repair_room_data?data_type=working_type&start_date=${startDate}&end_date=${endDate}`;
      repairFinishJob = `${getApiRoot()}singha/repair_room_data?data_type=finish_job&start_date=${startDate}&end_date=${endDate}`;
      repairStaffJob = `${getApiRoot()}singha/repair_room_data?data_type=project_staff_job&start_date=${startDate}&end_date=${endDate}`;
      repairStaffDay = `${getApiRoot()}singha/repair_room_data?data_type=project_staff_day&start_date=${startDate}&end_date=${endDate}`;
      repairStaffTime = `${getApiRoot()}singha/repair_room_data?data_type=staff_time&start_date=${startDate}&end_date=${endDate}`;
    }

    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };
  
    const promises = [
      fetch(repairStatus, requestOptions).then((response) => response.json()),
      fetch(repairWorkingType, requestOptions).then((response) => response.json()),
      fetch(repairFinishJob, requestOptions).then((response) => response.json()),
      fetch(repairStaffJob, requestOptions).then((response) => response.json()),
      fetch(repairStaffDay, requestOptions).then((response) => response.json()),
      fetch(repairStaffTime, requestOptions).then((response) => response.json()),
    ];
  
    Promise.all(promises)
    .then((results) => {
      this.setState({
        data: {
          dataFixStatus: results[0].data,
          dataFixWorkingType: results[1].data,
          dataFixFinishJob: results[2].data,
          dataFixProjectStaff: results[3].data,
          dataFixProjectStaffDay: results[4].data,
          dataFixProjectStaffTime: results[5].data,
        },
        loading: false,
        dateSelected: dateSelected,
      })
    })
    .catch((error) => console.log('Error:', error));
  }

  getFilterList() {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };
    let repairFilter = `${getApiRoot()}singha/get_data_range`;

    fetch(repairFilter, requestOptions)
    .then(response => response.json())
    .then(result => 
      this.setState({
        dataRange: result.range,
      })  
    )
    .catch(error => console.log('error', error));
  }

  getMonthName(number) {
    let localeUse = `${i18next.t("fix_request_dashbroad:Locale")}`
    

    const monthsTH = [
      "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
      "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม",
      "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
    ];
    const monthsEN = [
      "January", "February", "March", "April",
      "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];
    
    if (number >= 1 && number <= 12) {
      return localeUse === "thLocale" ? monthsTH[number - 1] : monthsEN[number - 1];
    }
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          <ContactTopMenuNavigation mini={true} />
          <Translation>
          { t =>
            <div className="container-fluid box" id="fixRequestDashbroad">
                <div className="row justify-content-between">
                    <div className="col">
                        <h3 className="mb-4">
                            <Link to="/contact">
                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                alt="back" className="back" />
                            </Link>
                            <span style={{ fontWeight: '400' }}>{t("MenuName:Fix Request Dashboard")}</span>
                        </h3>
                    </div>
                </div>  
                
                <div className="d-flex ml-8 mb-4">
                            <Dropdown>
                                <DropdownToggle id="dropdown-item-button" className="mr-2" bsPrefix = 'dropdown-toggle'>
                                       {t("fix_request:Choose period")}
                                </DropdownToggle>
                                <DropdownMenu style={{height:'200px', overflowY: 'scroll'}}>
                                {this.state.dataRange?.map((range, index) => {
                                      if (range.period !== 100 && range.period !== 0) {
                                        return (
                                          <Dropdown.Item key={index} onClick={() => this.getData(range.period,'','', range.period === 0? `${i18next.t("fix_request:Present date")}`:`${i18next.t("fix_request:Previous")} ${range.period} ${i18next.t("fix_request:Day")}`)}>
                                            {range.period === 0? 
                                              `${i18next.t("fix_request:Present date")}`
                                            :                                            
                                              `${i18next.t("fix_request:Previous")} ${range.period} ${i18next.t("fix_request:Day")}`
                                            }
                                          </Dropdown.Item>
                                        );
                                      } else {
                                        return (
                                          <Dropdown.Item key={index} onClick={() => this.getData('',range.start_date,range.end_date,`${this.getMonthName(range.month)} ${range.year}`)}>
                                            {this.getMonthName(range.month)} {range.year}
                                          </Dropdown.Item>
                                        );
                                      }
                                })}
                                </DropdownMenu>
                            </Dropdown>
                            <input 
                                value={this.state.dateSelected}
                                disabled={true}
                            />
                </div>
                {
                  this.state.loading ?
                  <Loading />
                    :
                  <FixRequestDashbroadChart 
                      allFixRequestData = {this.state.data}
                  />
                }
            </div>        
            }
          </Translation>
        </div>
      </Wrapper>
    );
  }
}

export default FixRequestDashbroad;
