import React, {Component} from 'react';
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import Wrapper from "../../../../components/wrapper";
import SelectContact from "../../../income/selectContact";
import History from "./history";
import ExportHistoryAll from "./exportHistoryAll.js"
class SelectRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_list: [],
            accountType: "report_history",
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({customer_list: contact_list})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    {
                        this.props.match.params.status === "select-customer" &&
                             <SelectContact updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                            initialContactType="residential"
                                            nextLink="/accounting/report/account-receivable/history/list"
                                            backLink="/accounting/report/account-receivable"/>
                    }
                    {/* หน้า select customer ที่สามารถเลือก all ได้*/}
                    {this.props.match.params.status === 'select-customer' &&  <ExportHistoryAll />}
                    {/* หน้า list ที่เลือก customer มาแล้ว*/}
                    {this.props.match.params.status === 'list' &&
                        <History customerList={this.state.customer_list}/>
                    }

                </WrapperContent>
            </Wrapper>
        );
    }
}

export default SelectRoom;