import React from "react";
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import ComponentPagination from "../../../libs/componentPagination";
import DatePickerNoti from "../../../accounting/finance/depositInterest/datePickerNoti";
import SearchProject from "../../../project/searchProject";
import format from "date-fns/format";
import i18next from 'i18next';
import { QueryRenderer } from "react-relay";
import Loading from '../../../libs/loading';
import localstorage from '../../../libs/localstorage';
import environment from "../../../env/environment";
import _ from 'lodash';
import Pagination from '../../../libs/newPagination';
import numberWithComma from "../../../libs/numberWithComma";
import { Translation } from "react-i18next";


const query = graphql`
 query reportCollectionLawFrimTableQuery(
    $id:ID,
    $status:String,
    $first: Int, 
    $last: Int,
    $search: String,
    $startDate: DateTime,
    $endDate: DateTime
) {
  allReceiveCollectionLawFirm
  (id:$id,status:$status,first: $first, last: $last, 
  search:$search,startDate:$startDate,endDate:$endDate) {
          edges{
              node{
                id
                docNumber
                issuedDate
                status
                type
                total
                paid
                typePaid
                lawyerApproval

                fileName
                fileUpload

                billingName
                billingUpload

                receiptName
                receiptUpload

                language

                creator
                creatorDelete
                creatorApproval

                receiveCollectionLawFirmRecord{
                    edges{
                        node{
                            id
                            status
                            type
                            total
                            paid
                            creator
                            collectionLawFirm{
                                id
                                docNumber
                                totalDebt
                                totalFine
                                contact{
                                    id
                                    refNumber
                                    name
                                    firstName
                                    lastName
                                }
                                purchaseRecordGroupLawFirm{
                                    totalCount
                                    edges{
                                        node{
                                            purchaseRecordGroup{
                                                id
                                                docNumber
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } 
    }
    summaryReceiveCollectionLawFirm
  }
`;
class ReportCollectionLawFrimTable extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      checkAll: false,
      dataCollection: [],
      checkItem: "",
      status : this.props.status ? this.props.status : "",
      checkList: [],
      carReportDocList:[],
      check_all: false, 
      page: 0,
      last: 10,
      tempStartDate: null,
      tempEndDate: null,
      search : '',
      startDate : null,
      endDate : null,
    }

    this.getStatusLabel = this.getStatusLabel.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }


  componentDidUpdate(){
    if(this.props.status !== this.state.status){
        this.setState({status : this.props.status})
    }
  }
   
  componentWillMount() {
    this.goFirst()
    if(!this.state.check_all){
      this.setState({
          check_all:false
      })
  }
  }

  handleChange(event){
    this.setState({ [event.target.name]: event.target.value });
  }

  handleKeyDown(event) {
      if (event.key === "Enter") {
        this.handleSearch(event);
        this.setState({
            startDate: new Date(new Date(this.state.tempStartDate).setHours(0,0,0,0)),
            endDate:new Date(new Date(this.state.tempEndDate).setHours(24,0,0,0)),
        })
      }
  }
    
  handleSearch = (text) => {
      this.setState({
          search: text,
          startDate:  this.state.tempStartDate ? new Date(new Date(this.state.tempStartDate).setHours(0,0,0,0)) : null,
          endDate: this.state.tempEndDate ?  new Date(new Date(this.state.tempEndDate).setHours(24,0,0,0)) : null,
      })
  }

 

  getStatusLabel(status){
    let text = ""
    let getColor = ""
    
    switch (status) {
      case "WAIT":
        text = `${i18next.t("graudreport:complete")}`;
        getColor = 'clf-label-green'
        break;
      case "PAID":
        text = `${i18next.t("collectionLawFirm:Waiting to Paid")}`;
        getColor = 'clf-label-red';
        break;
    }
    return(
        <span className={`${getColor}`}>{text}</span>
    )
  }

  render() {
    return (
      <Translation>
      { (t) => (
      <React.Fragment>
        <div className="d-flex justify-content-between col-12 p-0">
          <div className="d-flex p-0 m-0 align-items-center">
          </div>
          <div className="d-flex p-0 m-0 align-items-center">
            <div>
              <span className="mr-2">{t('PageList:Date')}</span>
            </div>
              <div className="ml-2">
                <DatePickerNoti 
                  selected={this.state.tempStartDate}
                  name='tempStartDate'
                  height="20px"
                  onChange={this.handleChange}
                />
              </div>
              <div className="ml-2">
                  <span className="mr-1 ml-1 mr-2">{t('PageList:To')}</span>
              </div>
              <div className="ml-2">
                <DatePickerNoti 
                  selected={this.state.tempEndDate}
                  name='tempEndDate'
                  className="mr-2"
                  height="20px"
                  onChange={this.handleChange}
                />
              </div>
              <div className="ml-2">
                <SearchProject callback_function={this.handleSearch} search={this.state.search} height={'43px'}/>
              </div>                   
        </div>
        </div>

        <QueryRenderer
              environment={environment}
              query={query}
              fetchPolicy="no-cache"
              variables={{ 
                status: this.state.status,  
                first: this.state.first,
                last: this.state.last,
                search: this.state.search,
                startDate : this.state.startDate,
                endDate : this.state.endDate,
              }}
              render={({ error, props }) => {
                let order_id = this.state.first - localstorage.getPageLimit();
                if (props) {
                 
                  return (
                    <React.Fragment>
                    <div className="table-responsive fade-up card mt-3 table-container">
                      <table id="table-collection" className="table table-hover">
                        <thead className="thead-light headTable">
                          <tr>
                            <th className="text-left" style={{width:'190px'}}>{t("collectionLawFirm:Document No.")}</th>
                            <th className="text-left" style={{width:'140px'}}>{t("collectionLawFirm:Payment Docs No.")}</th>
                            <th className="text-left" style={{width:'140px'}}>{t('collectionLawFirm:No.')}</th>
                            <th className="text-center" style={{width:'140px'}}>{t('collectionLawFirm:Unit No.')}</th>
                            <th className="text-left" style={{width:'140px'}}>{t("collectionLawFirm:Document Date")}</th>
                            <th className="text-left" style={{width:'190px'}}>{t('collectionLawFirm:Name')}</th>
                            <th className="text-left" style={{width:'190px'}}>{t('collectionLawFirm:Total Due (Baht)')}</th>
                            <th className="text-left" style={{width:'140px'}}>{t('collectionLawFirm:Create Date')}</th>
                            <th className="text-left" style={{width:'140px'}}>{t('collectionLawFirm:Service charge (Baht)')}</th>
                            <th className="text-left" style={{width:'140px'}}>{t('collectionLawFirm:Total Service charge (Baht)')}</th>
                            <th className="text-center" style={{width:'210px'}}>{t('collectionLawFirm:Status')}</th>
                            <th className="text-left" style={{width:'140px'}}>{t('collectionLawFirm:Creator')}</th>
                            <th className="text-left" style={{width:'150px'}}>{t('collectionLawFirm:Invoice Document')}</th>
                            <th className="text-left" style={{width:'150px'}}>{t('collectionLawFirm:Receipt Document')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            props.allReceiveCollectionLawFirm.edges.length >=1 ?
                            props.allReceiveCollectionLawFirm.edges && _.map(props.allReceiveCollectionLawFirm.edges,(item) => {
                                  return (
                                    <>
                                         <tr>
                                            <td className="text-left">
                                                {item.node.docNumber || '-'}
                                            </td>
                                            <td className="">
                                               <Link to={`/accounting/expense/purchase/purchase-detail/${item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                  item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.purchaseRecordGroupLawFirm.edges[0].node.purchaseRecordGroup.id}`}>
                                                {
                                                  item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                  item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.purchaseRecordGroupLawFirm.edges[0].node.purchaseRecordGroup.docNumber || '-'
                                                }
                                                </Link>
                                            </td>
                                            <td className="">
                                               <Link to={`/accounting/collection-law-firm/detail/${
                                                      item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                      item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.id
                                               }`}>
                                                {
                                                  item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                  item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.docNumber || '-'
                                                }
                                                </Link>
                                            </td>
                                            <td className="text-center">
                                                {
                                                  item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                  item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.contact.name || '-'
                                                }
                                            </td>
                                            <td className="text-left">{format(item.node.issuedDate, 'YYYY-MM-DD') || '-'}</td>
                                            <td className="text-left">
                                                {  
                                                  item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                  item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.contact.firstName + " " + item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.contact.lastName || '-'
                                                }  
                                            </td>
                                            <td className=""> 
                                                {
                                                  item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                  numberWithComma(item?.node?.receiveCollectionLawFirmRecord.edges[0].node.total) || '-'
                                                }
                                            </td>
                                            <td className="">{format(item.node.issuedDate, 'YYYY-MM-DD') || '-'}</td>
                                            <td className="">
                                                {
                                                  item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                  numberWithComma(item?.node?.receiveCollectionLawFirmRecord.edges[0].node.paid) || '-'
                                                }
                                            </td>
                                            <td className="text-left">-</td>
                                            <td className="text-center">{ this.getStatusLabel(item.node.status) || '-'}</td>
                                            <td className="">{item.node.creator || '-'}</td>
                                            <td className="">
                                              {
                                                item.node.billingUpload && 
                                                <a href={item.node.billingUpload || ''} target="_blank">{item.node.billingName || '-'}</a>
                                              }
                                               
                                            </td>
                                            <td className="">
                                              {
                                                item.node.receiptUpload && 
                                                <a href={item.node.receiptUpload || ''} target="_blank">{item.node.receiptName || '-'}</a>
                                              }
                                            </td>
                                        </tr>  
                                          {
                                            //
                                            item?.node?.receiveCollectionLawFirmRecord.edges &&
                                            _.map(item?.node?.receiveCollectionLawFirmRecord.edges, (itemReceiveCollection,index) => {
                                              if(index > 0){
                                                  let id = itemReceiveCollection.node.collectionLawFirm.id
                                                  let docNumber = itemReceiveCollection.node.collectionLawFirm.docNumber
                                                  let totalDebt = itemReceiveCollection.node.collectionLawFirm.totalDebt
                                                  let totalFine = itemReceiveCollection.node.collectionLawFirm.totalFine
                                                  let totalSum =  totalFine + totalDebt
                                                  let fullName =  itemReceiveCollection.node.collectionLawFirm.contact.firstName + " " + itemReceiveCollection.node.collectionLawFirm.contact.lastName
                                                  let numberOfRoom =  itemReceiveCollection.node.collectionLawFirm.contact.name
                                                  let to = `/accounting/collection-law-firm/detail/${id}`
                                                return(
                                                  <tr>
                                                      <td className="text-left"> </td>
                                                      <td className="text-left"> </td>
                                                      <td className="">
                                                       {
                                                         <Link to={to} key={docNumber}> {docNumber || '-'}</Link>                 
                                                        }
                                                      </td>
                                                      <td className="text-center">{numberOfRoom || '-'}</td>
                                                      <td className="">{format(item.node.issuedDate, 'YYYY-MM-DD') || '-'}</td>
                                                      <td className="">{fullName}</td>
                                                      <td className="">{numberWithComma(totalDebt) || '-'}</td>
                                                      <td className="">{format(item.node.issuedDate, 'YYYY-MM-DD') || '-'}</td>
                                                      <td className="">{numberWithComma(totalFine) || '-'}</td>
                                                      <td className="">{numberWithComma(totalSum) || '-'}</td>
                                                      <td className="text-center">{this.getStatusLabel(item.node.status) || '-'}</td>
                                                      <td className=""></td>
                                                      <td className="">
                                                        {
                                                          item.node.billingUpload && 
                                                          <a href={item.node.billingUpload || ''} target="_blank">{item.node.billingName || '-'}</a>
                                                        }
                                                      </td>
                                                      <td className="">
                                                        {
                                                          item.node.receiptUpload && 
                                                          <a href={item.node.receiptUpload || ''} target="_blank">{item.node.receiptName || '-'}</a>
                                                        }
                                                      </td>
                                                  </tr>  
                                               )
                                              }
                                            }) 
                                          }
                                    </>
                                  )
                            }) 
                            :<tr><td colSpan={15} className="text-center">{t('collectionLawFirm:No Data')}</td></tr>
                          }

                        </tbody>
                      </table>
                    </div>
                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={props.allReceiveCollectionLawFirm.edges.length}
                        />
                      </div>
                      {/* <div className="d-flex justify-content-end">
                        <span className="rclf-sum">ยอดค่าบริการค้างชำระทั้งหมด : {props.summaryReceiveCollectionLawFirm} บาท</span>
                      </div> */}
                    </React.Fragment>
                  )
                } else {
                  return <Loading/>
                }
              }}
            />
       
       
      </React.Fragment>
          )}
        </Translation>
    );
  }
}

export default ReportCollectionLawFrimTable;
