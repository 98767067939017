/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type queryAllChartOfAccountBudgetQueryVariables = {|
  issuedDate?: ?any,
  lastDate?: ?any,
  productAndServiceId?: ?string,
|};
export type queryAllChartOfAccountBudgetQueryResponse = {|
  +allChartOfAccountBudget: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +totalBudget: ?number,
        +chartOfAccount: ?{|
          +id: string,
          +name: string,
          +nameEn: ?string,
          +chartOfAccountCode: string,
        |},
        +summaryYear: ?any,
      |}
    |}>
  |}
|};
export type queryAllChartOfAccountBudgetQuery = {|
  variables: queryAllChartOfAccountBudgetQueryVariables,
  response: queryAllChartOfAccountBudgetQueryResponse,
|};
*/


/*
query queryAllChartOfAccountBudgetQuery(
  $issuedDate: Date
  $lastDate: Date
  $productAndServiceId: String
) {
  allChartOfAccountBudget(issuedDate: $issuedDate, lastDate: $lastDate, productAndServiceId: $productAndServiceId) {
    edges {
      node {
        totalBudget
        chartOfAccount {
          id
          name
          nameEn
          chartOfAccountCode
        }
        summaryYear
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issuedDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lastDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productAndServiceId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "issuedDate",
    "variableName": "issuedDate"
  },
  {
    "kind": "Variable",
    "name": "lastDate",
    "variableName": "lastDate"
  },
  {
    "kind": "Variable",
    "name": "productAndServiceId",
    "variableName": "productAndServiceId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalBudget",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccount",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chartOfAccountCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summaryYear",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queryAllChartOfAccountBudgetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChartOfAccountBudgetNodeConnection",
        "kind": "LinkedField",
        "name": "allChartOfAccountBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountBudgetNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountBudgetNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queryAllChartOfAccountBudgetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChartOfAccountBudgetNodeConnection",
        "kind": "LinkedField",
        "name": "allChartOfAccountBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountBudgetNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountBudgetNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "864f79e86708d759e362c8e49562739e",
    "id": null,
    "metadata": {},
    "name": "queryAllChartOfAccountBudgetQuery",
    "operationKind": "query",
    "text": "query queryAllChartOfAccountBudgetQuery(\n  $issuedDate: Date\n  $lastDate: Date\n  $productAndServiceId: String\n) {\n  allChartOfAccountBudget(issuedDate: $issuedDate, lastDate: $lastDate, productAndServiceId: $productAndServiceId) {\n    edges {\n      node {\n        totalBudget\n        chartOfAccount {\n          id\n          name\n          nameEn\n          chartOfAccountCode\n        }\n        summaryYear\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ad5b2772a03f4a862b150f6cfb9f416';

module.exports = node;
