import React, { Component } from "react";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Wrapper from "../../../components/wrapper";
import { Link } from "react-router-dom";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import SearchSelect from "../../../libs/searchSelect";
import Swal from "sweetalert2";
import { commitMutation } from "react-relay";
import { format, setDate, setMonth, lastDayOfMonth } from "date-fns";
import Redirect from "react-router-dom/es/Redirect";
import { Translation } from "react-i18next";
import i18n from "i18next";
import getApiCrossBilling from "./getApiCrossBilling";
import Loading from "../../../libs/loading";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
import CrossBillingPaymentGet from "./crossBillingPaymentGet";
import i18next from "i18next";
const _ = require('lodash');
const query = graphql`
    query crossBillingPaymentCreateQuery($search: String, $start_date : DateTime, $end_date : DateTime,$ref_list:[String]){
        invoiceViewer {
            id
            allInvoiceByStatus(search:$search, startDate:$start_date, endDate:$end_date,statusList:["active", "overdue", "partial_payment"],ref_In: $ref_list) {
                totalCount
                edges {
                    node {
                        id
                        docNumber
                    }
                }
            }
        }

        paymentGatewaySettingList(paymentType:"crossbill"){
            edges{
              node{
                id
                paymentType
                fee
                bankAccount{
                    id
                    docNumber
                    accountNumber
                    accountName
                    accountType
                    receivePayment
                    makePayment
                    bankName
                    status
                }
              }
            }
        }

        bankAccountViewer {
            allBankAccount(status: true, crossBilling: true) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        status
                    }
                }
                totalCount
            }
        }
    }
`;

const mutation = graphql`
    mutation crossBillingPaymentCreateMutation($input: CreateCrossBillingPaymentInput!){
        createCrossBillingPayment(input:$input){
            ok
            warningText
        }
    }
`;

class CrossBillingPaymentCreate extends Component {
    constructor(props) {
        super(props);

        let token = window.localStorage.getItem('token');
        // let token_decode = jwtDecode(token)

        let firstdays = new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1);
        let lastDay = lastDayOfMonth(setDate(setMonth(new Date(), new Date().getMonth()), 1))
        this.state = {
            search: "",
            search_input: "",

            start_date: firstdays,
            end_date: lastDay,
            temp_start_date: firstdays,
            temp_end_date: lastDay,

            loading: false,
            redirect: false,
            upload: false,
            checkLoading: true,

            cross_billing_list: [],
            cross_billing_object: {
                iv_number: '',
                ref_number: '',
                ref_number_2: '',
                balance: '',
                balance_expect: '',
                bank: '',
                note: '',
                fee_amount: 0,
            },

            invoice_list: [],
            bank_list: [],
            date: new Date(),
            paymentGatewaySettingList: null,
            inputFiles: "",
            signreq: Math.random().toString(36),
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.appendNewRow = this.appendNewRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.setBalance = this.setBalance.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentWillMount() {


        this.appendNewRow();
        //1

        fetchQuery(environment, query, {
            search: this.state.search,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            first: this.state.first,
        }
        ).then(response => {
            // let invoice_lists = _.filter(response.invoiceViewer.allInvoiceByStatus.edges, (e) => e.node.status !== 'PAID' && e.node.status !== 'VOID')          
            let invoice_lists = response.invoiceViewer.allInvoiceByStatus.edges
            this.setState({
                loading: true,
                checkLoading: false,
                invoice_list: invoice_lists,
                bank_list: response.bankAccountViewer.allBankAccount.edges,
                checkSpecialSite: response.checkSpecialSite,
                paymentGatewaySettingList: response?.paymentGatewaySettingList?.edges[0]
            })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.search !== this.state.search || prevState.start_date !== this.state.start_date || prevState.end_date !== this.state.end_date) {
            fetchQuery(environment, query, {
                search: this.state.search,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
            }
            ).then(response => {
                // let invoice_lists = _.filter(response.invoiceViewer.allInvoiceByStatus.edges, (e) => e.node.status !== 'PAID' && e.node.status !== 'VOID')
                let invoice_lists = response.invoiceViewer.allInvoiceByStatus.edges
                this.setState({
                    loading: true,
                    checkLoading: false,
                    invoice_list: invoice_lists,
                    bank_list: response.bankAccountViewer.allBankAccount.edges,
                    checkSpecialSite: response.checkSpecialSite,
                    paymentGatewaySettingList: response?.paymentGatewaySettingList?.edges[0]
                })
            })
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleKeyDown(event) {
        if (event.key === "Enter") {
            this.handleSearch(event);
        }
    }
    handleSearch(event) {
        this.setState({
            start_date: this.state.temp_start_date,
            end_date: this.state.temp_end_date,
            search: event.target.value,
            checkLoading: true,
            loading: false,
            inputFiles: "",
            cross_billing_list: [],
        });
    }

    setBalance(invoice, index) {
        let cross_billing_list = [...this.state.cross_billing_list];
        let data_from_getTotalBalance = this.getTotalBalance(invoice);
        let total_balance = data_from_getTotalBalance[0].toFixed(2);
        let fee_amount = data_from_getTotalBalance[1];
        cross_billing_list[index].balance = total_balance;
        cross_billing_list[index].balance_expect = total_balance;
        cross_billing_list[index].fee_amount = fee_amount;

        if (_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on && this.state.paymentGatewaySettingList?.node?.fee > 0) {
            cross_billing_list[index].bank = this.state.paymentGatewaySettingList?.node?.bankAccount?.id
        }
        this.setState({ cross_billing_list })
    }

    getTotalBalance(invoice) {
        let sum_total = 0;
        let fee_amount = 0
        invoice.node.transaction.edges.forEach((transaction) => {

            let amount = parseFloat(_.get(transaction, 'node.total', 0));
            let total = amount - parseFloat(_.get(transaction, 'node.paid', 0));
            let before_vat = total;

            if (invoice.node.taxType !== "NO_TAX") {
                before_vat = total * 100 / (100 + (parseFloat(_.get(transaction, 'node.vat') || 0)))
            }
            let wht = before_vat * (parseFloat(_.get(transaction, 'node.whtRatePercent') || 0) / 100);
            let pay = total - wht;
            sum_total += pay

        });
        if (_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on) {
            if (this.state.paymentGatewaySettingList?.node?.fee > 0) {
                if (this.state.paymentGatewaySettingList?.node.typeCal === 'PERCENT') {
                    fee_amount = Math.round(((sum_total * this.state.paymentGatewaySettingList?.node?.fee / 100) + Number.EPSILON) * 100) / 100;
                } else {
                    fee_amount = this.state.paymentGatewaySettingList?.node?.fee
                }

                sum_total = sum_total + fee_amount
            }
        }

        return [sum_total, fee_amount]
    }

    handleInputChange = async (e) => {
        let event = _.cloneDeep(e);
        let value = event.target.value;

        if ((e.target.dataset.key === 'ref_number')) {
            let invoice_list = _.cloneDeep(this.state.invoice_list)
            let index_invoice = _.findIndex(invoice_list, { node: { id: value } });
            await fetchQuery(environment, CrossBillingPaymentGet, {
                id: value,
            }
            ).then(response => {
                _.set(invoice_list, index_invoice, response.invoiceViewer.allInvoiceByStatus.edges[0]);
                this.setState({
                    invoice_list: invoice_list,
                },
                    () => this.setBalance(response.invoiceViewer.allInvoiceByStatus.edges[0], e.target.dataset.id)
                )
            })
        }

        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });
    }

    appendNewRow() {
        let cross_billing_object = { ...this.state.cross_billing_object };

        // set default bank
        if (this.state.cross_billing_list.length > 0) {
            let previous_bank_id = this.state.cross_billing_list[this.state.cross_billing_list.length - 1].bank;
            if (previous_bank_id) {
                cross_billing_object.bank = previous_bank_id
            }
        }

        this.setState({
            cross_billing_list: [...this.state.cross_billing_list, { ...cross_billing_object }]
        })
    }

    deleteRow(index) {
        let cross_billing_list = [...this.state.cross_billing_list];
        cross_billing_list.splice(index, 1);
        this.setState({ cross_billing_list })
    }

    handleChangeFiles = async (e) => {
        let files = e.currentTarget.files[0];
        let name = e.currentTarget.files[0].name;

        let bodyFormData = new FormData();
        bodyFormData.append('file', files);
        this.setState({ inputFiles: name })

        // เมนูปิดไว้ ใช้สำหรับ import txt ไฟล์แล้วเรียก iv ตาม txt ไฟล์ มาเลย
        // let invoice_list = []
        // await getApiCrossBilling.postFilesCrossBilling(bodyFormData).then(res => {
        //     let detailCrossBilling = res.data.data.detail
        //     detailCrossBilling.forEach((element, index) => {
        //         let ref_number = element.svm.ref_number
        //         invoice_list.push(ref_number)
        //     });

        //     fetchQuery(environment, query,{
        //         ref_list: invoice_list,
        //     }
        //     ).then(response => {
        //         let invoice_lists = _.filter(response.invoiceViewer.allInvoiceByStatus.edges, (e) => e.node.status !== 'PAID' && e.node.status !== 'VOID')
        //         this.setState({invoice_list: invoice_lists,})
        //     })

        // }).catch(error => {
        //     Swal.fire(
        //         i18n.t('regulation:Error!'),
        //         error.toString(),
        //         'error'
        //     )
        // })
        let invoice_list_objects = _.cloneDeep(this.state.invoice_list)

        await getApiCrossBilling.postFilesCrossBilling(bodyFormData).then(res => {
            let cross_billing_list = []
            let invoice_list = []
            let detailCrossBilling = res.data.data.detail
            
            detailCrossBilling.forEach(async (element, index) => {
                let ref_number = element.svm.ref_number
                let index_invoice = _.findIndex(invoice_list_objects, { node: { id: ref_number } });
                invoice_list.push(ref_number)
                await fetchQuery(environment, CrossBillingPaymentGet, {
                    id: ref_number,
                }
                ).then(response => {
                    _.set(invoice_list_objects, index_invoice, response.invoiceViewer.allInvoiceByStatus.edges[0]);
                    let target_invoice = _.find(invoice_list_objects, { node: { id: ref_number } });
                    if (target_invoice) {
                        let data_from_getTotalBalance = this.getTotalBalance(target_invoice);
                        let total_balance = data_from_getTotalBalance[0].toFixed(2);
                        let fee_amount = data_from_getTotalBalance[1];

                        element.svm.balance_expect = total_balance
                        element.svm.fee_amount = fee_amount
                    }
                    cross_billing_list.push(element.svm)
                    this.setState({ 
                        cross_billing_list: cross_billing_list,
                        invoice_list: invoice_list_objects, 
                    })
                })
            });


        }).catch(error => {
            Swal.fire(
                i18n.t('regulation:Error!'),
                error.toString(),
                'error'
            )
        })
    }

    addNotes(iv_number, ref_number_2, balance, balance_expect) {
        if (balance > 0 & balance !== '' & balance_expect === '') {
            if (ref_number_2 === '') {
                return `ใบแจ้งหนี้หมายเลข ${iv_number} ไม่พบในระบบ`
            } else if (ref_number_2 === 'draft') {
                return `ใบแจ้งหนี้หมายเลข ${iv_number} มีสถานะเป็นแบบร่าง`
            } else if (ref_number_2 === 'paid') {
                return `ใบแจ้งหนี้หมายเลข ${iv_number} ถูกรับชำระเงินไปแล้ว`
            } else if (ref_number_2 === 'void') {
                return `ใบแจ้งหนี้หมายเลข ${iv_number} ถูกยกเลิกไปแล้ว`
            } else if (ref_number_2 === 'partial_payment') {
                return `ใบแจ้งหนี้หมายเลข ${iv_number} ถูกรับชำระเงินไปแล้วบางส่วน`
            } else {
                return ''
            }
        } else if (balance !== balance_expect && balance_expect !== '' && balance_expect > 0) {
            return `ยอดเงินที่ได้รับชำระ(${balance} บาท) ไม่ตรงกับยอดเงินในใบแจ้งหนี้หมายเลข ${iv_number}(${balance_expect} บาท)`
        } else {
            return ''
        }
    }

    onSubmit(e) {
        e.preventDefault();

        let cross_billing_list_uniq = _.uniqBy(this.state.cross_billing_list, (cross_billing) => {
            return cross_billing.ref_number
        });

        let checkBalance = true
        this.state.cross_billing_list.forEach(element => {

            if (element.balance !== element.balance_expect) {
                checkBalance = false
            }
        });
        if (cross_billing_list_uniq.length === this.state.cross_billing_list.length && checkBalance) {
            this.setState({ loading: true , checkLoading: true });
            let variables = {
                input: {
                    date: format(this.state.date, 'YYYY-MM-DD'),
                    crossBillingList: JSON.stringify(this.state.cross_billing_list),
                    clientMutationId: "CB" + this.state.signreq,
                }
            };

            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        if (response.createCrossBillingPayment.ok) {
                            Swal.fire(i18n.t('crossBillingPayment:Save successfully'), '', 'success').then((result) => {
                                if (result.value) {
                                    this.setState({
                                        redirect: true,
                                        loading: false,
                                        checkLoading: false,
                                    })
                                }
                            });
                        } else {
                            Swal.fire(i18n.t('crossBillingPayment:UnSave successfully'), response.createCrossBillingPayment.warningText, 'warning').then((result) => {
                                if (result.value) {
                                    this.setState({
                                        loading: false,
                                        checkLoading: false,
                                    })
                                }
                            });
                        }

                    },
                    onError: () => {
                        Swal.fire('Error', '', 'error').then((result) => {
                            if (result.value) {
                                this.setState({
                                    loading: false,
                                    checkLoading: false,
                                })
                            }
                        });
                    },
                },
            )
        } else {
            Swal.fire(i18n.t('crossBillingPayment:Duplicate Ref No. 2'), '', 'warning');
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/accounting/income/receive/list/all" />
        }

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <div id="wrapper-content">
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/accounting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>{t('crossBillingPayment:Record of Cross Billing Payment (QR Code)')}
                                        </h3>
                                    </div>
                                </div>
                                <div className="content-inner">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-inline">
                                                <div className="mr-3">
                                                    <label>{t('crossBillingPayment:Date')}</label>
                                                </div>

                                                {this.state.loading && <DatePickerAdapter
                                                    className="form-control"
                                                    name="date"
                                                    selected={this.state.date}
                                                    onChange={this.handleInputChange}
                                                    required={true}
                                                />
                                            }
                                                {this.state.loading &&
                                                    <div className="custom-file col-6 ml-3 mb-1">
                                                        <input type="file" className="custom-file-input" id="customFile"
                                                            accept="text/plain" name="inputFiles" onChange={this.handleChangeFiles} disabled={this.state.checkLoading} />

                                                        <label className="custom-file-label text-right" htmlFor="customFile" style={{ justifyContent: 'left' }}>
                                                            {this.state.inputFiles.length >= 20 ?
                                                                this.state.inputFiles.substr(0, 20) + "..." : this.state.inputFiles}
                                                        </label>
                                                    </div>
                                                }
                                                {
                                                    <h5 style={{ paddingLeft: "10px", paddingTop: "15px", color: "red" }}>{i18next.t("crossBillingPayment1:Support .txt file only and file size not over 10 MB")} </h5>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-8 text-right">
                                            <div className="input-group float-right w-auto ml-2 mb-2">
                                                <Translation>
                                                    {t =>
                                                        <input
                                                            type="text"
                                                            className="form-control input-size col-8"
                                                            placeholder={t('filterFunction:search')}
                                                            value={this.state.search_input}
                                                            name="search"
                                                            onChange={(e) =>
                                                                this.setState({ search_input: e.target.value })
                                                            }
                                                            onKeyDown={this.handleKeyDown}
                                                        />
                                                    }
                                                </Translation>

                                                <button
                                                    type="submit"
                                                    className="btn btn-primary form-control search-button text-center"
                                                    name="search"
                                                    value={this.state.search_input}
                                                    onClick={(e) => this.handleSearch(e)}
                                                >
                                                    <Translation>{t => t('filterFunction:search')}</Translation>
                                                </button>
                                            </div>

                                            <InlineAdvanceFilter
                                                start_date={this.state.temp_start_date}
                                                end_date={this.state.temp_end_date}
                                                handleChange={this.handleChange}
                                            />

                                            <div className="pt-9">
                                                <h5 style={{ paddingLeft: "10px", paddingTop: "15px", color: "red" }}>{i18next.t("crossBillingPayment1:Please select a date range that corresponds to the invoice date range.(Date of the earliest invoice that is payable)")}</h5>
                                            </div>
                                        </div>

                                    </div>
                                    <form action="" onSubmit={this.onSubmit}>
                                        <div className="row">
                                            <div className="col">
                                                <div className="fade-up card">
                                                    <table className="table table-hover">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th width="100" className="text-center">{t('crossBillingPayment:No.')}</th>
                                                                <th width="200" className="text-left">Ref No. 2</th>
                                                                <th width="200" className="text-left">{t('crossBillingPayment:Bank')}</th>
                                                                <th width="200" className="text-left">{t('crossBillingPayment:Amount')}</th>
                                                                {
                                                                    // localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                                                                    <th width="200" className="text-left">{t('crossBillingPayment1:Note')}</th>
                                                                }
                                                                <th width="50" />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.loading ?
                                                                    this.state.cross_billing_list.map((cross_billing, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className="text-center">{index + 1}.</td>
                                                                                <td className="text-left">
                                                                                    <SearchSelect data-key="ref_number" data-id={index}
                                                                                        onChange={this.handleInputChange}
                                                                                        value={cross_billing.ref_number}
                                                                                        name={"cross_billing_list[" + index + "].ref_number"}
                                                                                        queryObject={this.state.invoice_list}
                                                                                        keyOfValue="id"
                                                                                        require={true}
                                                                                        keyOfLabel="docNumber" />


                                                                                </td>
                                                                                <td className="text-left">
                                                                                    <SearchSelect onChange={this.handleInputChange}
                                                                                        value={cross_billing.bank}
                                                                                        name={"cross_billing_list[" + index + "].bank"}
                                                                                        queryObject={this.state.bank_list}
                                                                                        keyOfValue="id" require={true}
                                                                                        keyOfLabel="accountName:accountNumber:bankName"
                                                                                    />
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    <input max={cross_billing.balance_expect}
                                                                                        min={cross_billing.balance_expect} type="number"
                                                                                        className="form-control text-right"
                                                                                        name={"cross_billing_list[" + index + "].balance"}
                                                                                        value={cross_billing.balance}
                                                                                        placeholder={t('crossBillingPayment:Baht')}
                                                                                        onChange={this.handleInputChange}
                                                                                        required />
                                                                                </td>
                                                                                {
                                                                                    // localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                                                                                    <td className="text-left">
                                                                                        <p style={{ paddingTop: "15px", color: "red" }}>{this.addNotes(cross_billing.iv_number, cross_billing.ref_number_2, cross_billing.balance, cross_billing.balance_expect)}</p>
                                                                                    </td>
                                                                                }
                                                                                <td>
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                        alt="delete" className="btn-delete cursor"
                                                                                        onClick={() => this.deleteRow(index)} />
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }

                                                                    )
                                                                    : <div><Loading /></div>
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="row justify-content-between">
                                                    <div className="col">
                                                        <button type="button" className="btn add mt-3"
                                                            style={{ backgroundColor: '#f2f2f2' }}
                                                            onClick={this.appendNewRow}>
                                                            +{t('crossBillingPayment:Add list')}
                                                        </button>
                                                    </div>
                                                    <div className="col text-right">
                                                        <Link to="/accounting">
                                                            <button type="button" className="btn btn-secondary add mt-3">
                                                                {t('crossBillingPayment:Cancel')}
                                                            </button>
                                                        </Link>
                                                        <button type="submit" className="btn btn-primary add mt-3 ml-2"
                                                            disabled={this.state.checkLoading}
                                                        >
                                                            {this.state.checkLoading &&
                                                            <span
                                                                className="spinner-border spinner-border-sm align-middle mr-2"/>
                                                            }
                                                            {t('crossBillingPayment:Save')}
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    </Translation>
                </div>
            </Wrapper>

        )
    }
}

export default CrossBillingPaymentCreate
