import React, { Component } from 'react'
import _ from "lodash"
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import numberWithCommas from '../../../libs/numberWithComma'
import Pagination from '../../../libs/newPagination'
import i18next from 'i18next'

export default class tablePO extends Component {

  checkType = (type) => {

    let valueResult = ""

    switch (type) {
      case "PRODUCT":
        valueResult = "สินค้า"
        break;
      case "SERVICE":
        valueResult = "จ้างบริการ / ค่าใช้จ่าย (งานทั่วไป)"
        break;
      case "SERVICE_CONTACT":
        valueResult = "จ้างบริการ / ค่าใช้จ่าย (สัญญาจ้าง)"
        break;
      case "ASSET":
        valueResult = "สินทรัพย์"
        break;
    }
    return valueResult
  }

  render() {
    return (
      <React.Fragment>

        <div className="row">
          <div className="col">
            <div className="fede-up">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th>{i18next.t("manageOrderList:PO Number")}</th>
                      {(this.props.status == "wait" || this.props.status == "approve") && 
                        <th>{i18next.t("manageOrderList:Date")}</th> 
                      }
                      <th>{i18next.t("manageOrderList:Creditor/Trading Partner")}</th>
                      <th>{i18next.t("manageOrderList:Category")}</th>
                      <th>{i18next.t("manageOrderList:Type")}</th>
                      <th className="text-center">{i18next.t("manageOrderList:Amount (THB)")}</th>
                      {this.props.status == "approve" &&
                        <React.Fragment>
                          <th>{i18next.t("manageOrderList:Approval Date")}</th>
                          <th>{i18next.t("manageOrderList:Approved By")}</th>
                        </React.Fragment>
                      }
                      {this.props.status == "void" &&
                        <React.Fragment>
                          <th>{i18next.t("manageOrderList:Cancellation Date")}</th>
                          <th>{i18next.t("manageOrderList:Cancelled By")}</th>
                          <th>{i18next.t("manageOrderList:Cancellation Reason")}</th>
                        </React.Fragment>

                      }
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(this.props.dataManageOrderListPO, (item, indexItem) => {
                      return (
                        <tr key={indexItem}>
                          <td>
                            <Link 
                              to={`/purchasing/manageOrder/updateFormManageOrder/${item.node.procurementForm.type}/${_.lowerCase(item.node.status)}/${item.node.id}`}
                              >{item.node.docNumber}
                            </Link>
                          </td>
                          {(this.props.status == "wait" || this.props.status == "approve") && 
                            <td>{format(item.node.issuedDate, "DD/MM/YYYY")}</td>
                          }
                          <td>{item.node.procurementForm.creditor?.name || "-"}</td>
                          <td>{item.node.procurementForm.chartOfAccount?.name || "-"}</td>
                          <td>{this.checkType(item.node.procurementForm.type)}</td>
                          <td className="text-center">{numberWithCommas(item.node.procurementForm.total)}</td>
                          {this.props.status == "approve" &&
                            <React.Fragment>
                              <td>{format(item.node.approveDate, "DD/MM/YYYY")}</td>
                              <td>{item.node.approver}</td>
                            </React.Fragment>
                          }
                          {this.props.status == "void" &&
                            <React.Fragment>
                              <td>{format(item.node.voidDate, "DD/MM/YYYY")}</td>
                              <td>{item.node.voider}</td>
                              <td>{item.node.voidRemark}</td>
                            </React.Fragment>
                          }
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            changePage={this.props.changePage}
            first={this.props.first}
            last={this.props.last}
            totalCount={this.props.totalCount}
            />
        </div>
      </React.Fragment>
    )
  }
}
