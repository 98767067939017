/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type logsHistoryListQueryVariables = {||};
export type logsHistoryListQueryResponse = {|
  +allJuristic: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +firstName: string,
        +lastName: string,
        +role: string,
        +image: ?string,
        +user: ?{|
          +id: string,
          +username: string,
          +lastLogin: ?any,
        |},
      |}
    |}>,
  |}
|};
export type logsHistoryListQuery = {|
  variables: logsHistoryListQueryVariables,
  response: logsHistoryListQueryResponse,
|};
*/


/*
query logsHistoryListQuery {
  allJuristic {
    totalCount
    edges {
      node {
        id
        firstName
        lastName
        role
        image
        user {
          id
          username
          lastLogin
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JuristicNodeConnection",
    "kind": "LinkedField",
    "name": "allJuristic",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastLogin",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "logsHistoryListQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "logsHistoryListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b0c06ae04398aeb262d8fd9c41ade611",
    "id": null,
    "metadata": {},
    "name": "logsHistoryListQuery",
    "operationKind": "query",
    "text": "query logsHistoryListQuery {\n  allJuristic {\n    totalCount\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        role\n        image\n        user {\n          id\n          username\n          lastLogin\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6a1fe5639f126e0b9233747053ebac5f';

module.exports = node;
