import './styles/index.scss';
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import getAccountingMenuData from "../../libs/accountingMenuData";
import BankAccountQuery from '../../accounting/finance/depositInterest/bankAccount';

const _ = require('lodash');

const query = graphql`
    query navigationQuery{
        getNotification {
            type
            count
        }
        getNotificationAccounting {
            type
            count
        }
        getNotificationAdvance {
            type
            count
        }
        getNotificationContractExpense {
            type
            count
        }
        getNotificationProcurementForm {
            type
            count
        }
        getNotificationWaitPurchaseOrderes {
            type
            count
        }
        myUser {
            juristic {
                id
            }
        }
        ownershipSummaryPercent
        allNoticeForeignOwnership {
        totalCount
            edges {
                node {
                id
                percentNoti
                }
            }
        }
        countNotiCollection{
            countNoti
        }
        sumJuristicNews
    }
`;

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count_notification: window.localStorage.getItem('count_notification_navigation')||0,
            count_notification_accounting : window.localStorage.getItem('count_notification_accounting')||0,
            countNotificationProcurement: window.localStorage.getItem('countNotificationProcurement')||0,
            checkSpecialSite : window.localStorage.getItem("checkSpecialSite") === 'true' ? true : false,
            // count_notification: window.localStorage.getItem('count_notification_navigation') || 0,
            // count_notification_accounting: window.localStorage.getItem('count_notification_accounting') || 0,
            // countNotificationProcurement: window.localStorage.getItem('countNotificationProcurement') || "0",
             
            permission_list: localStorage.getItem("permission_list"),
            codename_accounting: [],
            codename_contact: [
                "contact_news",
                "contact_survey",
                "contact_parcel",
                "contact_chat",
                "contact_fix_request",
                "booking_calendar",
                "booking_list",
                "booking_facility",
                "booking_setting",
                "edm",
                "repairs_room",
                "committee_chat"
            ],
            codename_vms:[
                "vms_manage_visitor",
                "vms_manage_visitor_create",
                "vms_manage_visitor_view",
                "vms_visitor_report",
                "vms_visitor_report_create",
                "vms_visitor_report_view",
                "vms_packing_income",
                "vms_packing_income_create",
                "vms_packing_income_view",
            ],
            codename_register: [
                // "register_residential",
                // "register_vehicle",
                // "register_key_card",
                // "register_pet",
                "tenant_tenant",
                "project_venhicles",
                "project_keycards",
                "project_pets",
                "register_ownership",
                "coowner"
            ],
            codename_project: [
                "project_info",
                "project_emergency",
                "project_manager",
                // "project_staff",
                "project_juristicmanager",
                "project_regulation",
                "project_juristiccompany",
                "project_projectstaff"
            ],
            codename_setting: [
               "project_juristic",
               "setting_account_start_date",
               "period_lock",
               "accounting_accountrecord",
               "preinvoicegroup",
               "accounting_chartofaccount",
               "accounting_bank",
               "asset_settingasset",
               "project_developerproject",
               "accounting_settingnoteinvoice",
               "accounting_settingnotereceive",
               "project_settingproject",
               "project_boardpersonal",
               "claim"
            ],
            codename_developer: [
                "ownership_ownership",
                "ownership_unitreport",
                "ownership_obligation",
                "ownership_developer"
            ],
            codename_purchasing:[
                "accounting_procurement_form",
                "accounting_purchase_orderes",
                "project_juristic",
                "claim",
                "accounting_productstock"
            ],
            notificationInterest: 0,

            allNoti: "",
            percentNation: "",
            countNotificationRegister: 0,
            countSumJuristicNews:0
        };
    }

    componentWillMount() {
        fetchQuery(environment, query).then(response_data => {
            let count = 0;
            let count_accounting = 0;
            let countProcurement = 0;
            if (response_data.getNotification) {
                response_data.getNotification.forEach((notification) => {
                    count += notification.count
                });
            }
            if (response_data.getNotificationAccounting) {
                response_data.getNotificationAccounting.forEach((notification) => {
                    count_accounting += notification.count
                });
            }
            if (response_data.getNotificationAdvance) {
                response_data.getNotificationAdvance.forEach((notification) => {
                    count_accounting += notification.count
                });
            }
            if (response_data.getNotificationContractExpense) {
                response_data.getNotificationContractExpense.forEach((notification) => {
                    count_accounting += notification.count
                });
            }
            if (response_data.getNotificationProcurementForm) {
                response_data.getNotificationProcurementForm.forEach((notification) => {
                    countProcurement += notification.count
                });
            }
            if (response_data.getNotificationWaitPurchaseOrderes) {
                response_data.getNotificationWaitPurchaseOrderes.forEach((notification) => {
                    countProcurement += notification.count
                });
            }
            if (response_data.allNoticeForeignOwnership) {
                this.setState({
                    allNoti: response_data.allNoticeForeignOwnership,
                })
            }
            if (response_data.ownershipSummaryPercent) {
                this.setState({
                    percentNation: response_data.ownershipSummaryPercent,
                })               
            }
            if (response_data?.countNotiCollection?.countNoti) {
                count_accounting += response_data?.countNotiCollection?.countNoti
            }

            if(response_data?.sumJuristicNews){
                this.setState({
                    countSumJuristicNews:response_data?.sumJuristicNews
                })
            }
            window.localStorage.setItem('count_notification_accounting', count_accounting);
            window.localStorage.setItem('count_notification_navigation', count);
            window.localStorage.setItem('countNotificationProcurement', countProcurement);
            

            this.setState({
                count_notification: count,
                count_notification_accounting: count_accounting,
                countNotificationProcurement: countProcurement,
                // checkSpecialSite:response_data.checkSpecialSite,
            })
            this.fetchQueryBank()
            this.calNumberOfNoti()
        });

        let codename = [];
        Object.keys(getAccountingMenuData()).forEach((key) => {
            getAccountingMenuData()[key].forEach((data) => {
                codename.push(data.codename)
            })
        });
        this.setState({ codename_accounting: codename })
    }

    fetchQueryBank = async () => {
        fetchQuery(environment, query, {}).then(async (data) => {
            if (data.myUser.juristic.id) {
                BankAccountQuery(data.myUser.juristic.id, null, null, 'during').then((data_list) => {
                    let count_noti_interest = 0
                    if (data_list.depositBank.length > 0) {
                        _.forEach(data_list.depositBank, bank => {
                            if (bank.node.bankUnit.edges.length > 0) {
                                count_noti_interest += this.getNotificationNum(bank.node.bankUnit.edges)
                            }
                        })
                    }
                    this.setState({
                        notificationInterest: count_noti_interest
                    })
                })
            }
        });
    }

    getNotificationNum = (notificationInterest) => {
        let count = 0
        if (notificationInterest && notificationInterest.length > 0) {
            _.forEach(notificationInterest, bank => {
                if (bank.node.bankNotification?.edges[0]?.node.status === true) {
                    count++
                }
            })
            return count
        } else {
            return count
        }
    }

    calNumberOfNoti() {
        let countNotificationRegister = 0;
        this.state.allNoti.edges && this.state.allNoti.edges.map((noti)=>{
            if(JSON.parse(this.state.percentNation).nationality_not_th_percent > noti.node.percentNoti){
                countNotificationRegister++
            }
        })
        this.setState({
            countNotificationRegister: countNotificationRegister
        })
    }

    render() {
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"))
        return (
            <div className="navigation" style={{ overflowY : 'scroll' , height : "85vh" }}>
                <ul>
                    <li>
                        <NavLink to="/home" activeClassName="active">
                            <img
                                src={process.env.PUBLIC_URL + '/images/sidebar/dashboard-side-icon.png'}
                                alt="dashboard-side-icon" />
                            <Translation>
                                {
                                    t => <span>{t('navigation:dashboard')}</span>
                                }
                            </Translation>
                        </NavLink>
                    </li>
                    {/* New Features */}
                    <li>
                        <NavLink to="/announce-dashboard/news" activeClassName="active">
                            <img
                                src={process.env.PUBLIC_URL + '/images/sidebar/Icon-New-Feature.png'}
                                alt="dashboard-side-icon" />
                            <Translation>
                                {
                                    t => <span>{t('announceDashboard:announce')}
                                        {
                                                this.state.countSumJuristicNews > 0 && 
                                                <span className="badge">{this.state.countSumJuristicNews > 0 ? 
                                                   this.state.countSumJuristicNews : 0 } </span>
                                        }
                                    </span>
                                }
                            </Translation>
                        </NavLink>
                    </li>
                    {
                        _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_accounting, element.codename)) &&
                        <li>
                            <NavLink to="/accounting">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/sidebar/accounting-side-icon.png'}
                                    alt="accounting-side-icon" />
                                <Translation>
                                    {
                                        t => <span>
                                            {t('navigation:accounting')}
                                            {
                                                this.state.count_notification_accounting > 0 && 
                                                <span className="badge">{this.state.count_notification_accounting > 0 ? 
                                                   parseInt(this.state.count_notification_accounting + this.state.notificationInterest) : 0 } </span>
                                                // <span className="badge">{parseInt(this.state.count_notification_accounting) + localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=')  ? parseInt(this.state.count_notification_accounting + this.state.notificationInterest) : 0}</span>
                                            }
                                        </span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }

                    { 
                    // localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                     _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_purchasing, element.codename)) &&
                        <li>
                            <NavLink to="/stockandsupplies">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/sidebar/stock-side-icon.png'}
                                    alt="residential-side-icon"/>
                                <Translation>
                                    {
                                        t => <span>
                                            {t("set_permission:Stock")}
                                        </span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }

                    { 
                    // localStorage.getItem('site_id') && 
                    // localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                        _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_purchasing, element.codename)) &&
                        <li>
                            <NavLink to="/purchasing">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/sidebar/purchasing_side_icon.png'}
                                    alt="residential-side-icon" />
                                <Translation>
                                    {
                                        t => <span>
                                            {t('set_permission:Common Property Management')}
                                            {
                                                this.state.countNotificationProcurement > 0 &&
                                                <span className="badge">{this.state.countNotificationProcurement} </span> 
                                            }
                                        </span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }
                    {   !(_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on) &&
                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'feedback'])?.menu_on && // extra menu default false
                        <li>
                            <NavLink to="/feedback/feedback-dashboard">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/sidebar/feedback.png'}
                                    alt="feedback-icon" style={{width:'24px'}}/>
                                <Translation>
                                    {
                                        t => <span>
                                            {t('set_permission:Event/Feedback')}
                                        </span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }
                    {/* ปิดไว้ของ Sena */}
                    {
                    // localStorage.getItem('site_id') && (localStorage.getItem('site_id') === '331' || localStorage.getItem('site_id') === '524') &&
                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'silverman_guard'])?.menu_on &&
                        _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_vms, element.codename)) &&
                        <li>
                            <NavLink to="/silvermanguard">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/sidebar/grand-side-icon.png'}
                                    alt="tenant-side-icon" />
                                <Translation>
                                    {
                                        t => <span>{t('navigation:guard')}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }
                    {
                        _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_contact, element.codename)) &&
                        <li>
                            <NavLink to="/contact">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/sidebar/tenant-side-icon.png'}
                                    alt="tenant-side-icon"/>
                                <Translation>
                                    {
                                        t => 
                                        (_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on) ?
                                        <span>
                                            {textData?.data?.navigation.contact}
                                            {
                                                this.state.count_notification > 0 &&
                                                <span className="badge">{this.state.count_notification}</span>
                                            }
                                        </span>
                                        :
                                        <span>
                                            {t('navigation:contact')}
                                            {
                                                this.state.count_notification > 0 &&
                                                <span className="badge">{this.state.count_notification}</span>
                                            }
                                        </span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }

                    

                    
                    {
                        _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_register, element.codename)) &&
                        <li>
                            <NavLink to="/register">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/sidebar/residential-side-icon.png'}
                                    alt="residential-side-icon" />
                                <Translation>
                                    {
                                        t => 
                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                        <span>{textData?.data?.navigation.register}</span>
                                        :
                                        <span style={{fontSize: this.state.countNotificationRegister > 0 ? '12px':'14px'}}>
                                            {t('navigation:register')} 
                                            {/* codename_register */}
                                            {
                                                this.state.countNotificationRegister > 0 &&
                                                <span className="badge">{this.state.countNotificationRegister}</span>
                                            }
                                        </span>                                        
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }
                    {
                        _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_project, element.codename)) &&
                        <li>
                            <NavLink to="/project">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/sidebar/project-info-side-icon.png'}
                                    alt="project-info-side-icon" />
                                <Translation>
                                    {
                                        t => <span>{t('navigation:project detail')}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }
                    {
                    // localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') 
                    // localStorage.getItem('execute'
                    //extraMenu
                    !(_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on) &&
                    (_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'developer'])?.menu_on &&                
                    _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_developer, element.codename)) ?
                        <li>
                            <NavLink to="/developer">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/icons/developer-icon.png'}
                                    alt="project-info-side-icon" />
                                <Translation>
                                    {
                                        t => <span>{t('navigation:developer')}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li> : <li>
                            <NavLink to="/developer">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/icons/developer-icon.png'}
                                    alt="project-info-side-icon" />
                                <Translation>
                                    {
                                        t => <span>{t('navigation:developer')}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>)
                    }

                    {/* ปิดไว้ของ Sena */}
                    {
                        //extraMenu
                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'foqus'])?.menu_on &&
                        _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_project, element.codename)) &&
                        <li>
                            <NavLink to="/meetingAndScore">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/icons/meeting.svg'}
                                    alt="project-info-side-icon" />
                                <Translation>
                                    {
                                        t => <span>{t('navigation:meetingAndScore')}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }

                    {
                        //extraMenu
                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'filing_documents_to_government_agencies'])?.menu_on &&
                        <li>
                            <NavLink to="/senddoc">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/icons/printer-white.png'}
                                    alt="project-info-side-icon"/>
                                <Translation>
                                    {
                                        t => <span>{t("set_permission:Filing Documents To Government Agencies")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }

                    {
                        //extraMenu
                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'add-on_service'])?.menu_on &&
                        <li>
                            <NavLink to="/addon">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/partner-icon/partner/add-on.png'}
                                    alt="project-info-side-icon" />
                                <Translation>
                                    {
                                        t => <span>{t("set_permission:Add-On")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }

                    {
                        //DownloadFile RQ212
                        _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: "download_file", }) &&
                        <li>
                            <NavLink to="/download_file/">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/topMenuNavigation/downloadFile/logo.png'}
                                    alt="project-info-side-icon" />
                                <Translation>
                                    {
                                        t => <span>{t("downloadFile:Document to download")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }
                    {
                        //เอกสารที่อัพโหลด
                        localStorage.getItem('site_id') && (localStorage.getItem('site_id') === '331' || localStorage.getItem('site_id') === '163') &&
                        <li>
                            <NavLink to="/uploadfile/">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/topMenuNavigation/downloadFile/logo.png'}
                                    alt="project-info-side-icon" />
                                <Translation>
                                    {
                                        t => <span>{t("uploadFileList:File Upload")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }

                    {
                        _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_setting, element.codename)) &&
                        <li>
                            <NavLink to="/setting">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-side-icon.png'}
                                    alt="project-info-side-icon" />
                                <Translation>
                                    {
                                        t => <span>{t('navigation:setting')}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }
                                        {
                        //DataAnalytic menu
                        localStorage.getItem("site_id") && localStorage.getItem("site_id") === "331"  &&
                        <li>
                            <NavLink to="/dataAnalytic">
                                <img
                                    src={process.env.PUBLIC_URL + '/images/topMenuNavigation/downloadFile/logo.png'}
                                    alt="project-info-side-icon" />
                                <Translation>
                                    {
                                        t => <span>{t("navigation:dataAnalytic")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    }

                </ul>
            </div>

        );
    }
}

export default Navigation;