import React, { Component } from 'react'
import { Translation } from 'react-i18next'
import { format } from "date-fns";
import _ from "lodash"

export default class stockMenagement extends Component {

  constructor(props){
    super(props)

    this.state = {
      allRequisitionNote: []
    }
  }

  componentDidMount(){
    this.setPage()
  }

  setPage = () => {

    let page = []
    let limitItem = 25
    let listItemArr = []

    _.forEach(this.props.allRequisitionNote, (itemAllRequisitionNote, indexItemAllRequisitionNote) => {
      listItemArr = _.chunk(itemAllRequisitionNote.node.requisitionList.edges, limitItem)
      _.forEach(listItemArr, (itemListItemArr, indexItemListItemArr) => {
        if(listItemArr.length -1 === indexItemListItemArr){
          page.push({
            itemAllRequisitionNote: itemAllRequisitionNote,
            itemListItemArr: itemListItemArr,
            indexLast: "pageLast",
            numPage: indexItemListItemArr
          })

        }else{
          page.push({
            itemAllRequisitionNote: itemAllRequisitionNote,
            itemListItemArr: itemListItemArr,
            indexLast: "",
            numPage: indexItemListItemArr
          })
        }
      })
    })

    this.setState({allRequisitionNote: page})
  }

  getTypeRequisition = (inputTypeRequisition) => {
    let result = ""

    switch (inputTypeRequisition) {
      case "UNIT":
        result = "สิทธิ์"
        break;
      case "PROJECT":
        result = "เบิกใช้งาน"
        break;

      default:
        break;
    }
    return result
  }

  getTypeNote = (typeNoteInput) => {
    let textResult = ""

    switch (typeNoteInput) {
      case "juristic":
        textResult = "เบิกใช้ ส่วนกลาง"
        break;
      case "tenant":
        textResult = "เบิกใช้ ลูกบ้าน"
        break;
    
      default:
        textResult = "สิทธิ์"
        break;
    }

    return textResult
  }

  render() {

    return (
      <React.Fragment>
        {_.map(this.state.allRequisitionNote, (itemRequisitionNote, indexItemRequisitionNote) => {
          let index = 0
          return (
            <React.Fragment key={indexItemRequisitionNote}>
              <div className="print-page-a4" id="stockMenagement">
                <div className="subpage">
    
                  <div className="head">
                    <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                      <div style={{ minHeight: 100, minWidth: 100 }}>
                        <img src={this.props.selfProject.logo} alt="silverman" />
                      </div>
                      <div>
                        <strong className="selfProjectNameText">{this.props.selfProject.name}</strong>
                        {
                          this.props.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ?
                            <div>
                              <Translation>{t => <div className="address-header">{this.nextLine(this.props.selfProject.address)} </div>}</Translation>
                              <Translation>{t => <div className="address-header">{t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                            </div>
                            :
                            <Translation>{t => <div className="address-header">{this.props.selfProject.address} {t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                        }
                        <Translation>{t => <div>{t("document:Taxpayer identification number")} {this.props.selfProject.taxIdNumber || '-'}</div>}</Translation>
                      </div>
                    </div>
    
                    <div className="document-name">
                      <Translation>
                        {t =>
                          <React.Fragment>
                            <div className="title-document-name">ใบบันทึกเบิกสินค้า</div>
                            <div className="title-document-name">Stock Taking Form</div>
                          </React.Fragment>
                        }
                      </Translation>

                      <div className="document-detail float-left text-left">
                        <div className="d-flex">
                          <div className="sub-document-detail">เลขที่/No.:</div> {itemRequisitionNote.itemAllRequisitionNote.node.docNumber}<br/>
                        </div>
                        <div className="d-flex">
                          <div className="sub-document-detail">วันที่/Date:</div> {format(itemRequisitionNote.itemAllRequisitionNote.node.issuedDate, "DD/MM/YYYY")}<br/>
                        </div>
                        <div className="d-flex">
                          <div className="sub-document-detail">ประเภทเบิก:</div> {this.getTypeRequisition(itemRequisitionNote.itemAllRequisitionNote.node.typeRequisition)}
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <div className="detail">
                    <div className="customer-detail">
                      {(itemRequisitionNote.itemAllRequisitionNote.node.typeRequisition === "UNIT"  || (itemRequisitionNote.itemAllRequisitionNote.node.typeRequisition === "PROJECT" && _.lowerCase(itemRequisitionNote.itemAllRequisitionNote.node.typeNote) === "tenant")) &&
                        <div className="d-flex">
                          <div className="sub-customer-detail">เจ้าของร่วม/สมาชิก(สิทธิ์)</div> 
                          {`${itemRequisitionNote.itemAllRequisitionNote.node.contact.name} ${itemRequisitionNote.itemAllRequisitionNote.node.contact.firstName} ${itemRequisitionNote.itemAllRequisitionNote.node.contact.lastName}` } <br/>
                        </div>
                      }
                      {itemRequisitionNote.itemAllRequisitionNote.node.typeRequisition === "UNIT" &&
                        <div className="d-flex">
                          <div className="sub-customer-detail">ผู้รับสินค้า &nbsp;
                            {itemRequisitionNote.itemAllRequisitionNote.node.recipient === "OWNER"
                              ? "(เจ้าของห้อง/ผู้อาศัย)"
                              : itemRequisitionNote.itemAllRequisitionNote.node.recipient === "OTHER" && "(ระบุเอง)"
                            }
                          </div> 
                          {`${itemRequisitionNote.itemAllRequisitionNote.node.firstNameRecipient} ${itemRequisitionNote.itemAllRequisitionNote.node.lastNameRecipient}`}
                          <br/>
                        </div>
                      }
                      <div className="d-flex">
                        <div className="sub-customer-detail">เบิกโดย</div> {itemRequisitionNote.itemAllRequisitionNote.node.juristict.firstName} {itemRequisitionNote.itemAllRequisitionNote.node.juristict.lastName}<br/>
                      </div>
                      {itemRequisitionNote.itemAllRequisitionNote.node.typeRequisition === "PROJECT" && _.lowerCase(itemRequisitionNote.itemAllRequisitionNote.node.typeNote) === "juristic" &&
                        <div className="d-flex">
                          <div className="sub-customer-detail">ผู้ใช้สินค้า</div> 
                          {itemRequisitionNote.itemAllRequisitionNote.node.username}<br/>
                        </div>
                      }
                      <div className="d-flex">
                        <div className="sub-customer-detail">วัตถุประสงค์ในการเบิก </div> {this.getTypeNote(_.lowerCase(itemRequisitionNote.itemAllRequisitionNote.node.typeNote))} : {itemRequisitionNote.itemAllRequisitionNote.node.objective}<br/>
                      </div>
                    </div>
                  </div>
    
                  <div className="stockMenagement-body">
                    <table className="table">
                      <thead>
                        <tr className="tr-light">
                          <th width={80} className="text-center">ลำดับ</th>
                          <th width={80} className="text-center">รหัส</th>
                          <th width={270} className="text-center">รายการ</th>
                          {itemRequisitionNote.itemAllRequisitionNote.node.typeRequisition === "UNIT" && 
                            <th width={120} className="text-center">เลข No.</th>
                          }
                          <th width={120} className="text-center">จำนวน</th>
                          <th width={120} className="text-center">จำนวนคงเหลือ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(itemRequisitionNote.itemListItemArr, (itemListItem, indexItemListItem) => {
                          index +=1
                          return (
                            <tr key={indexItemListItem}>
                              <td className="text-center tdBodyNone">{index + (itemRequisitionNote.numPage * 25)}</td>
                              <td className="text-center tdBodyNone">{itemListItem.node.productAndService.productCode}</td>
                              <td className="tdBodyNone">{itemListItem.node.productAndService.name}</td>
                              {itemRequisitionNote.itemAllRequisitionNote.node.typeRequisition === "UNIT" && 
                                <td className="text-center tdBodyNone">{itemListItem.node.noNumber}</td>
                              }
                              <td className="text-right tdBodyNone">{itemListItem.node.numberOfPieces}</td>
                              <td className="text-right tdBodyNone">{itemListItem.node.numberOfBalance}</td>
                            </tr>
                          )
                        })}
                        <tr className="lastRow">
                          <td className="lasttd tdBodyTopNone"/>
                          <td className="lasttd tdBodyTopNone"/>
                          <td className="lasttd tdBodyTopNone"/>
                          {itemRequisitionNote.itemAllRequisitionNote.node.typeRequisition === "UNIT" && 
                            <td className="lasttd tdBodyTopNone"/>
                          }
                          <td className="lasttd tdBodyTopNone"/>
                          <td className="lasttd tdBodyTopNone"/>
                        </tr>
                      </tbody>
                    </table>
                  </div>
    
                  <div className="stockMenagement-body-bottom">
                    <div className="mt-4">
                      <span className="text-remark-title">
                        หมายเหตุ 
                      </span>&nbsp;
                      {itemRequisitionNote.itemAllRequisitionNote.node.remark}
                    </div> 
                  </div>
    
                  {itemRequisitionNote.indexLast === "pageLast" &&
                    <div className="footer mt-5" style={{bottom: 0, position: 'absolute'}}>
                      <div className="row">
                        <div className="col-3">
                          <div className="footerSignatureDot">
                            .................................
                          </div>
                          <div className="footerTitle text-center">ผู้บันทึกรายการ</div>
                          <div className="footerTitle d-flex justify-content-center">
                            วันที่ <span className="ml-2 d-flex align-items-center"><div className="lineDate mt-2"/>/<div className="lineDate mt-2"/>/<div className="lineDate mt-2"/></span>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="footerSignatureDot">
                            .................................
                          </div>
                          <div className="footerTitle text-center">ผู้รับสินค้า</div>
                          <div className="footerTitle d-flex justify-content-center">
                            วันที่ <span className="ml-2 d-flex align-items-center"><div className="lineDate mt-2"/>/<div className="lineDate mt-2"/>/<div className="lineDate mt-2"/></span>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="footerSignatureDot">
                            .................................
                          </div>
                          <div className="footerTitle text-center">ผู้ตรวจสอบ</div>
                          <div className="footerTitle d-flex justify-content-center">
                            วันที่ <span className="ml-2 d-flex align-items-center"><div className="lineDate mt-2"/>/<div className="lineDate mt-2"/>/<div className="lineDate mt-2"/></span>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="footerSignatureDot">
                            .................................
                          </div>
                          <div className="footerTitle text-center">ผู้อนุมัติ</div>
                          <div className="footerTitle d-flex justify-content-center">
                            วันที่ <span className="ml-2 d-flex align-items-center"><div className="lineDate mt-2"/>/<div className="lineDate mt-2"/>/<div className="lineDate mt-2"/></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  
                </div>
              </div>
            </React.Fragment>
          )
        })}

      </React.Fragment>
      
    )
    
  }
}
