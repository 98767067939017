import React, { Component } from "react";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import { format } from "date-fns";
import i18next from "i18next";

const Excel = require("exceljs/dist/es5/exceljs.browser");

export default class exportContractExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      query: this.props.query,
      status: this.props.status,
      queryListExport: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.startDate !== prevProps.startDate) {
      this.setState({ startDate: this.props.startDate });
    }
    if (this.props.endDate !== prevProps.endDate) {
      this.setState({ endDate: this.props.endDate });
    }
    if (this.props.status !== prevProps.status) {
      this.setState({ status: this.props.status });
    }
  }
  fetchDataQuery = () => {
    fetchQuery(environment, this.state.query, {
      // receive parameter date search status
      status: this.state.status === "all" ? "" : this.state.status,
      search: this.state.textSearch,
      startDate:
        this.state.startDate && format(this.state.startDate, "YYYY-MM-DD"),
      endDate: this.state.endDate && format(this.state.endDate, "YYYY-MM-DD"),
    }).then((data) => {
      this.setState({
        queryListExport: data.allContractExpense,
      });
      this.state.queryListExport &&
        this.downloadExcel(this.state.queryListExport);
    });
  };

  downloadExcel = (query) => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("contract service");

    let status_text = "";
    if (this.state.status === "all") {
      status_text = "ทั้งหมด";
    } else if (this.state.status === "wait") {
      status_text = "รอดำเนินการ";
    } else if (this.state.status === "in_progress") {
      status_text = "กำลังดำเนินการ";
    } else if (this.state.status === "near_end") {
      status_text = "ใกล้ครบกำหนดสัญญา";
    } else if (this.state.status === "renew") {
      status_text = "ต่อสัญญา";
    } else if (this.state.status === "not_renew") {
      status_text = "ไม่ต่อสัญญา";
    } else {
      status_text = i18next.t("Allaction:cancel");
    }

    // set border
    let borders = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    // set widths
    const cellWidths = [14, 12, 12, 27, 33, 14, 15, 10, 16, 15, 20];

    // description table
    const startDateHeader =
      this.state.startDate === null
        ? "-"
        : format(this.state.startDate, "DD-MM-YYYY");

    const endDateHeader =
      this.state.endDate === null
        ? "-"
        : format(this.state.endDate, "DD-MM-YYYY");

    worksheet.getCell("A1").value = "สัญญารายจ่าย";
    worksheet.getCell("A1").font = { size: 16, bold: true };
    worksheet.getCell("A2").value = "สถานะ: " + status_text;
    worksheet.getCell("A2").font = { size: 16, bold: true };
    worksheet.getCell("A3").value =
      "เรียงตามวันที่ " + startDateHeader + " ถึง " + endDateHeader;
    worksheet.getCell("A3").font = { size: 12, bold: true };

    // head table
    let head_table_contract = [];
    if (this.state.status === "all") {
      head_table_contract = [
        "เลขที่สัญญา",
        "วันที่",
        "รหัสเจ้าหนี้",
        "บริษัทคู่สัญญา",
        "หมวดหมู่",
        "วันที่เริ่มสัญญา",
        "วันที่หมดสัญญา",
        "ค่าบริการ",
        "บันทึกโดย",
        "อ้างถึง PO",
        "สถานะ",
      ];
    } else if (this.state.status === "not_renew") {
      head_table_contract = [
        "เลขที่สัญญา",
        "วันที่",
        "รหัสเจ้าหนี้",
        "บริษัทคู่สัญญา",
        "หมวดหมู่",
        "วันที่เริ่มสัญญา",
        "วันที่หมดสัญญา",
        "ผู้บันทึก",
        "เหตุผลที่ไม่ต่อสัญญา",
      ];
    } else if (this.state.status === "void") {
      head_table_contract = [
        "เลขที่สัญญา",
        "วันที่",
        "รหัสเจ้าหนี้",
        "บริษัทคู่สัญญา",
        "หมวดหมู่",
        "วันที่เริ่มสัญญา",
        "วันที่หมดสัญญา",
        "วันที่ยกเลิก",
        "ผู้ยกเลิก",
        "เหตุผลที่ยกเลิก",
      ];
    } else {
      head_table_contract = [
        "เลขที่สัญญา",
        "วันที่",
        "รหัสเจ้าหนี้",
        "บริษัทคู่สัญญา",
        "หมวดหมู่",
        "วันที่เริ่มสัญญา",
        "วันที่หมดสัญญา",
        "ค่าบริการ",
        "บันทึกโดย",
        "วันที่บันทึก",
      ];
    }

    // add width and border to cell
    const headTable = worksheet.addRow(head_table_contract);
    headTable.eachCell((cell, colNumber) => {
      cell.border = borders;
      const column = worksheet.getColumn(colNumber);
      column.width = cellWidths[colNumber - 1];
    });

    // body table
    let data_table_row1 = [];
    for (let i = 0; i < query.edges.length; i++) {
      const data = query.edges[i];
      let status = "";
      if (data.node.status === "WAIT") {
        status = i18next.t("contract_expese_list:Pending");
      } else if (data.node.status === "IN_PROGRESS") {
        status = i18next.t("contract_expese_list:In Process")
      } else if (data.node.status === "NEAR_END") {
        status = i18next.t("contract_expese_list:Near Expiry");
      } else if (data.node.status === "RENEW") {
        status = i18next.t("contract_expese_list:Renew");
      } else if (data.node.status === "NOT_RENEW") {
        status = i18next.t("contract_expese_list:Don't Renew");
      } else {
        status = i18next.t("contract_expese_list:Void");
      }

      // default
      data_table_row1.push(data.node.docNumber);
      data_table_row1.push(format(data.node.issuedDate, "DD/MM/YYYY"));
      data_table_row1.push(
        data.node.purchaseOrderes.procurementForm.creditor.refNumber
      );
      data_table_row1.push(
        data.node.purchaseOrderes.procurementForm.creditor.name
      );
      data_table_row1.push(
        data.node.purchaseOrderes.procurementForm.chartOfAccount.name
      );

      data_table_row1.push(
        format(
          data.node.purchaseOrderes.procurementForm.startContractDate,
          "DD/MM/YYYY"
        )
      );
      data_table_row1.push(
        format(
          data.node.purchaseOrderes.procurementForm.endContractDate,
          "DD/MM/YYYY"
        )
      );

      // adddition table
      // case all
      if (this.state.status === "all") {
        data_table_row1.push(
          data.node.purchaseOrderes.procurementForm.total &&
          data.node.purchaseOrderes.procurementForm.total.toLocaleString()
        );
        data_table_row1.push(data.node.purchaseOrderes.approver);
        data_table_row1.push(data.node.purchaseOrderes.docNumber);
        data_table_row1.push(status);
        // case void
      } else if (this.state.status === "void") {
        data_table_row1.push(data.node.voidDate);
        data_table_row1.push(data.node.voider);
        data_table_row1.push(data.node.voidRemark);
        // case not_renew
      } else if (this.state.status === "not_renew") {
        data_table_row1.push(data.node.renewer);
        data_table_row1.push(data.node.voidRemark);
        // others case
      } else {
        data_table_row1.push(
          data.node.purchaseOrderes.procurementForm.total &&
          data.node.purchaseOrderes.procurementForm.total.toLocaleString()
        );
        data_table_row1.push(data.node.purchaseOrderes.approver);
        data_table_row1.push(format(data.node.added, "DD/MM/YYYY"));
      }

      const dataTable = worksheet.addRow(data_table_row1);
      dataTable.eachCell((cell) => {
        cell.border = borders;
      });
      data_table_row1 = [];
    }

    // // Generate Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      let fileName =
        format(this.state.startDate, "DD/MM/YYYY") ===
          format(this.state.endDate, "DD/MM/YYYY")
          ? "contract-expense" +
          format(this.state.startDate, "DD/MM/YYYY") +
          ".xlsx"
          : "contract-expense" +
          format(this.state.startDate, "DD/MM/YYYY") +
          "-" +
          format(this.state.endDate, "DD/MM/YYYY") +
          ".xlsx";
      if (this.state.startDate === null) {
        fileName = "contract-expense";
      }
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  render() {
    return (
      <div>
        <button className="btn-export-contractService">
          <a target={"_blank"} onClick={this.fetchDataQuery}>
            {i18next.t("Allaction:Print")}
          </a>
        </button>
      </div>
    );
  }
}
