import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import CashDeposit from './cashDeposit';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperCashDepositQuery($id_list:[String]) {
        selfProject {
            id
            name
            address
            taxIdNumber
            juristicContactNumber
            logo
        }
        cashTransactionViewer{
            allCashTransaction(status:"not_pass", cashType: "cash_in", id_In: $id_list){
                totalCount
                edges{
                    node{
                        id
                        receive{
                            docNumber
                            added
                            issuedDate
                        }

                        invoice {
                            docNumber
                            added
                            issuedDate
                        }

                        receiptDeposit{
                            docNumber
                            added
                            issuedDate
                        }

                        guaranteeMoneyReceive {
                            docNumber
                            added
                            issuedDate
                        }
                        clearAdvance {
                            docNumber
                            added
                            issuedDate
                        }
                        otherReceive{
                            docNumber
                            added
                            issuedDate
                        }
                        description
                        price
                    }
                }
            }
        }
    }
`;

class WrapperCashDeposit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            select_cash_transaction: props.location.state.select_cash_transaction,
            id_list: [],
            total: 0.0,
        };
        this.print = this.print.bind(this);
        this.adjustHeight = this.adjustHeight.bind(this);
    }

    componentWillMount() {
        let id_list = [];
        let total = 0.0;
        this.state.select_cash_transaction.forEach((select) => {
            id_list.push(select.id);
            total = total + select.price
        });
        this.setState({id_list: id_list, total: total})
    }

    print() {
        window.print();
    }

    adjustHeight() {

    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>CashDeposit</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png"
                             alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{id_list: this.state.id_list}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            let count_page = Math.ceil((props.cashTransactionViewer.allCashTransaction.totalCount) / 26);
                            let arrX = Array.from(new Array(count_page));
                            let transaction_per_page = 26;
                            return (
                                <React.Fragment>
                                    {arrX.map((value, index) => {
                                        return (
                                            <CashDeposit query={props} total={this.state.total}
                                                         key={index}
                                                         cash_page={index + 1}
                                                         end_page={count_page - 1}
                                                         current_page={index}
                                                         all_cash_transactions={props.cashTransactionViewer.allCashTransaction.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1))}/>
                                        )
                                    })}

                                </React.Fragment>
                            )
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperCashDeposit;
