/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type checkPermisionJobQueryVariables = {|
  positionId?: ?string
|};
export type checkPermisionJobQueryResponse = {|
  +allPositionPermission: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +positionName: ?string,
        +permissionPerPosition: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +permission: {|
                +id: string,
                +codename: string,
                +name: string,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type checkPermisionJobQuery = {|
  variables: checkPermisionJobQueryVariables,
  response: checkPermisionJobQueryResponse,
|};
*/


/*
query checkPermisionJobQuery(
  $positionId: String
) {
  allPositionPermission(positionId: $positionId) {
    edges {
      node {
        id
        positionName
        permissionPerPosition {
          edges {
            node {
              id
              permission {
                id
                codename
                name
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "positionId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "positionId",
        "variableName": "positionId"
      }
    ],
    "concreteType": "PositionNodeConnection",
    "kind": "LinkedField",
    "name": "allPositionPermission",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PositionNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PositionNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "positionName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PermissionPerPositionNodeConnection",
                "kind": "LinkedField",
                "name": "permissionPerPosition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PermissionPerPositionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PermissionPerPositionNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PermissionNode",
                            "kind": "LinkedField",
                            "name": "permission",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "codename",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkPermisionJobQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkPermisionJobQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "46845be04c349e72e6e3dd496d5f9f48",
    "id": null,
    "metadata": {},
    "name": "checkPermisionJobQuery",
    "operationKind": "query",
    "text": "query checkPermisionJobQuery(\n  $positionId: String\n) {\n  allPositionPermission(positionId: $positionId) {\n    edges {\n      node {\n        id\n        positionName\n        permissionPerPosition {\n          edges {\n            node {\n              id\n              permission {\n                id\n                codename\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9fdc13fbb58d70e805f421f5d717fda7';

module.exports = node;
