import { graphql } from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";

const query = graphql`
    query dataFilterQuery($service: String!, $contact: String!, $car_type: String, $contacts: [String])  {
        periodServicePricing(service: $service, contact: $contact) {
            unit
            unitPrice
            totalPrice
            description
        }
        allCars(contactList: $contacts, chargedFee: "true",carType: $car_type){
            edges{
                node{
                    id
                    licencePlate
                }
            }
        }
    }
`;
export function billingperiodlistObjects() {
      return [
            { "node": { label: "ครั้งเดียว", value: "oy1" } },
            { "node": { label: "รายเดือน", value: "m" } },
            { "node": { label: "ราย 2 เดือน", value: "m2" } },
            { "node": { label: "ราย 3 เดือน", value: "m3" } },
            { "node": { label: "ราย 4 เดือน", value: "m4" } },
            { "node": { label: "ราย 6 เดือน", value: "m6" } },
            { "node": { label: "รายปี", value: "year" } }
      ]
}

export function invoiceAdvanceObjects() {
      return [
            { "node": { label: "-", value: "no" } },
            { "node": { label: "ล่วงหน้า 1 เดือน", value: "m1" } },
            { "node": { label: "ล่วงหน้า 2 เดือน", value: "m2" } },
            { "node": { label: "ล่วงหน้า 3 เดือน", value: "m3" } },
            { "node": { label: "ล่วงหน้า 4 เดือน", value: "m4" } },
            { "node": { label: "ล่วงหน้า 5 เดือน", value: "m5" } },
            { "node": { label: "ล่วงหน้า 6 เดือน", value: "m6" } }
      ]
}

export function billingPeriodMonthObjects(billing_period) {

      let billing_period_month = [
            { "node": { label: "-", value: "no" } },
      ]
      //Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec
      if (billing_period) {
            if (billing_period === "m2") {
                  billing_period_month = [
                        { "node": { label: "Jan Mar May Jul Sep Nov ", value: "1,3,5,7,9,11" } },
                        { "node": { label: "Feb Apr Jun Aug Oct Dec", value: "2,4,6,8,10,12" } },
                  ]
            }
            else if (billing_period === "m3") {
                  billing_period_month = [
                        { "node": { label: "Jan Apr Jul Oct", value: "1,4,7,10" } },
                        { "node": { label: "Feb May Aug Nov", value: "2,5,8,11" } },
                        { "node": { label: "Mar Jun Sep Dec", value: "3,6,9,12" } },
                  ]
            }
            else if (billing_period === "m4") {
                  billing_period_month = [
                        { "node": { label: "Jan May Sep", value: "1,5,9" } },
                        { "node": { label: "Feb Jun Oct", value: "2,6,10" } },
                        { "node": { label: "Mar Jul Nov", value: "3,7,11" } },
                        { "node": { label: "Apr Aug Dec", value: "4,8,12" } },
                  ]
            }
            else if (billing_period === "m6") {
                  billing_period_month = [
                        { "node": { label: "Jan Jul", value: "1,7" } },
                        { "node": { label: "Feb Aug", value: "2,8" } },
                        { "node": { label: "Mar Sep", value: "3,9" } },
                        { "node": { label: "Apr Oct", value: "4,10" } },
                        { "node": { label: "May Nov", value: "5,11" } },
                        { "node": { label: "Jun Dec", value: "6,12" } },
                  ]
            }
            else if (billing_period === "year") {
                  billing_period_month = [
                        { "node": { label: "Jan", value: "1" } },
                        { "node": { label: "Feb", value: "2" } },
                        { "node": { label: "Mar", value: "3" } },
                        { "node": { label: "Apr", value: "4" } },
                        { "node": { label: "May", value: "5" } },
                        { "node": { label: "Jun", value: "6" } },
                        { "node": { label: "Jul", value: "7" } },
                        { "node": { label: "Aug", value: "8" } },
                        { "node": { label: "Sep", value: "9" } },
                        { "node": { label: "Oct", value: "10" } },
                        { "node": { label: "Nov", value: "11" } },
                        { "node": { label: "Dec", value: "12" } },
                  ]
            }

      }
      return billing_period_month
}

export function pricingTypesObjects(pricingTypes = null) {

      let pricingType = ""
      if (pricingTypes === "STATIC") {
            pricingType = "ค่าคงที่"
      } else if (pricingTypes === "AREA") {
            pricingType = "คิดตามขนาดพื้นที่"
      } else if (pricingTypes === "INDIVIDUAL") {
            pricingType = "คิดแยกตามยูนิต"
      } else if (pricingTypes === "WATER_METER") {
            pricingType = "คิดจากมิเตอร์น้ำ"
      } else if (pricingTypes === "ELECTRIC_METER") {
            pricingType = "คิดจากมิเตอร์ไฟฟ้า"
      } else if (pricingTypes === "CAR_PARK") {
            pricingType = "คิดค่าที่จอดรถยนต์"
      } else if (pricingTypes === "MOTORCYCLE_PARK") {
            pricingType = "คิดค่าที่จอดรถจักรยานยนต์"
      }
      else if (pricingTypes === "RATIO") {
            pricingType = "อัตราส่วนกรรมสิทธิ์"
      }
      return pricingType
}

export async function PeriodAdjust(pricingTypes = null, price, rounding, billing_period, size, productAndServiceId,contactId) {

      let amount = 0
      //ค่าคงที่
      if (pricingTypes === "STATIC") {
            amount = (price * 1) * billingPeriodMonthCal(billing_period)
      }
      
      let variables = {
            "contact": contactId,
            "service": productAndServiceId,
            "contacts": contactId,
            "car_type" : pricingTypes === 'CAR_PARK' ? 'car': 'motorcycle'
      };

      //ต้องใช้คำนวน
      if (pricingTypes && billing_period) {
            if (pricingTypes === "AREA") {
                  let cal_price = (size * price) * billingPeriodMonthCal(billing_period)
                  if (rounding == 'UP') amount = Math.ceil(cal_price)
                  else if (rounding == 'DOWN') amount = Math.floor(cal_price)
                  else amount = cal_price

            } else if (pricingTypes === "INDIVIDUAL") {
                  await fetchQuery(environment, query, variables).then(data => {
                        amount = (data.periodServicePricing.unitPrice * 1) * billingPeriodMonthCal(billing_period)
                  });
                  
            } else if (pricingTypes === "WATER_METER") {
                  amount = 0
            } else if (pricingTypes === "ELECTRIC_METER") {
                  amount = 0
            } else if (pricingTypes === "CAR_PARK" || pricingTypes === "MOTORCYCLE_PARK") {
                  await fetchQuery(environment, query, variables).then(data => {
                          const count = data.allCars.edges.length | 0
                          amount = (price * count) * billingPeriodMonthCal(billing_period)
                  });
            } 
            else if (pricingTypes === "RATIO") {
                  let cal_price = (size * price) * billingPeriodMonthCal(billing_period)
                  if (rounding == 'UP') amount = Math.ceil(cal_price)
                  else if (rounding == 'DOWN') amount = Math.floor(cal_price)
                  else  amount = cal_price

            }

      }
      return Promise.resolve(amount)
      // return amount
}

export function billingPeriodMonthCal(billing_period) {

      let billing_period_cal = 1
      //Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec
      if (billing_period === "m2") {
            billing_period_cal = 2
      }
      else if (billing_period === "m3") {
            billing_period_cal = 3
      }
      else if (billing_period === "m4") {
            billing_period_cal = 4
      }
      else if (billing_period === "m6") {
            billing_period_cal = 6
      }
      else if (billing_period === "year"){
            billing_period_cal = 12
      }

      return billing_period_cal
}