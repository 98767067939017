import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import NavigationMenuSetup from "../libs/navigationMenuSetup";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../env/environment";
import './setting.scss'
 
const query = graphql`
    query pdpaAndManagementIndexQuery {
        myUser { 
            juristic {
                acceptPolicy acceptTermOfUse acceptMarketing acceptPartner
            } 
        }
    }
`;

class pdpaAndManagementIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            acceptPolicy : "" ,
            acceptTermOfUse : "",
            acceptMarketing : "",
            acceptPartner: "",
        } 
    }

    componentWillMount() { 
        this.getQuery()
    }

    getQuery(){
        fetchQuery(environment, query).then(response_data => { 
            if(response_data.myUser.juristic) { 
                this.setState({
                    acceptPolicy: response_data.myUser.juristic.acceptPolicy , 
                    acceptTermOfUse : response_data.myUser.juristic.acceptTermOfUse , 
                    acceptMarketing : response_data.myUser.juristic.acceptMarketing ,
                    acceptPartner : response_data.myUser.juristic.acceptPartner ,
                })
            }
        });
    }

    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
    }



    render() { 
        return (
            <Translation>
                {t =>
                    <div id="pdpa-menu" style={{ overflowX: "hidden" }}>
                        <div className={"row "
                            + (this.props.mini ? "mini " : "")
                            + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

                            <div className='col-12 selectManagementAndPolicy'>
                                {this.props.center &&
                                    <div className="col-12 mt-10 mb-10 headerSelect">
                                        <h3 className="text-center">
                                            <img src={process.env.PUBLIC_URL + '/images/topMenuNavigation/PDPA/privacy.svg' } alt="privacy"/>
                                            {t('pdpaandmanagement:manageProfile')}</h3></div>
                                }

                                <div className='col-7 mx-auto bodySelect'>
                                    <Link to='/policyAndManagement/Consentformarketingpurposes'>
                                        <div className='row'>
                                            <div className='col-9' >
                                                <p>{t('pdpaandmanagement:Consent for marketing purposes')}</p>
                                            </div>
                                            <div className='col-3 text-right'>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/chevron-forward.png'} alt="chevronforward" />
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to='/policyAndManagement/ConsenttoDisclosureforMarketingPurposes'>
                                        <div className='row'>
                                            <div className='col-9' >
                                                <p>{t('pdpaandmanagement:Consent to Disclosure for Marketing Purposes')}</p>
                                            </div>
                                            <div className='col-3 text-right'>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/chevron-forward.png'} alt="chevronforward." />
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to='/policyAndManagement/SilvermanAffiliateConsent'>
                                        <div className='row'>
                                            <div className='col-9' >
                                                <p>{t('pdpaandmanagement:Silverman Affiliate Consent')}</p>
                                            </div>
                                            <div className='col-3 text-right'>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/chevron-forward.png'} alt="chevronforward"/>
                                            </div>
                                        </div>
                                    </Link>




                                </div>

                                <div className='footerSelct'>
                                    <p className='text-center'>
                                        {t('pdpaandmanagement:Please1')} < br />
                                        {t('pdpaandmanagement:Please2')} <Link to={"/policyAndManagement/PrivacyPolicy"}>{t('pdpaandmanagement:Click Here')}</Link>
                                    </p>
                                </div>

                                <div className='row bg-gray DetailSelect'>
                                    <div className='col-11 mx-auto'>
                                        <h3 style={{marginTop: '57px' , marginRight: '10px'}} className='text-center'> {t('pdpaTermofUses:Terms and Conditions')}</h3>
                                        <p>{t('pdpaTermofUses:The terms and conditions herein')}</p>
                                        <p>{t('pdpaTermofUses:Please read')}</p>
                                        <p>{t('pdpaTermofUses:This Agreement')}</p>
                                        <ol>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses1')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses2')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses3')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses4')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses5')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses6')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses7')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses8')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses9')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses10')} </li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses11')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses12')}<br/>
                                                {t('pdpaTermofUses:pdpaTermofUses12-A')}<br/>
                                                {t('pdpaTermofUses:pdpaTermofUses12-B')}<br/>
                                                {t('pdpaTermofUses:pdpaTermofUses12-C')}<br/>
                                                {t('pdpaTermofUses:pdpaTermofUses12-1')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses13')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses14')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses15')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses16')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses17')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses18')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses19')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses20')} <u>{t('pdpaTermofUses:pdpaTermofUses20-A')}</u></li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses21')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses22')}</li>
                                            <li>{t('pdpaTermofUses:pdpaTermofUses23')}</li>
                                        </ol> 

                                        <div className='ml-5 mb-10 mt-10'>
                                            <input type={"checkbox"} checked={this.state.acceptTermOfUse === true} disabled /> 
                                            &nbsp; &nbsp; 
                                            <span style={{fontWeight: "400",fontSize: "16px"}}>{t('pdpaTermofUses:AcceptToContion')}</span>
                                        </div> 
                                    </div> 
                                </div>
                            </div> 
                        </div>
                    </div>
                }
            </Translation>

        );
    }
}

export default pdpaAndManagementIndex;
