import React, { Component } from 'react'
import Select from 'react-select';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';

const query = graphql`
  query selectProductQuery{
    productViewer{
      allProduct(type_In: "product", isActive: true){
        edges{
          node{
            id
            productCode
            name
          }
        }
      }
    }
  }
`

export default class selectProduct extends Component {

  constructor(props){
    super(props)

    this.state = {
      allProductList: [],
    }
  }

  componentWillMount = () => {
    fetchQuery(environment, query).then(res => {
      if(res.productViewer.allProduct){
        let itemOption = []
        res.productViewer.allProduct.edges.forEach(item => {
          itemOption.push({label: `${item.node.productCode} ${item.node.name}`,value: item.node.id})
        });

        this.setState({allProductList: itemOption})
      }
    })
  }

  handleChange = (e) => {
    this.props.appendRequisitionList({
      target : {
        value: e.value
      }
    })
    
  }

  render() {
    return (
      <Select 
        name="productSelect"
        value=""
        placeholder="ระบุรหัส หรือรายการ"
        onChange={this.handleChange}
        options={this.state.allProductList}
        isDisabled={this.props.disabled}
        isClearable={this.props.isClearable}
        styles={this.props.style}/>
    )
  }
}
