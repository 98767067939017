import React, { Component } from 'react';
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Redirect } from 'react-router';
import SearchSelect from "../../../libs/searchSelect";
import environment from "../../../env/environment";
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import Swal from "sweetalert2";
import numberWithComma from "../../../libs/numberWithComma";
import { fetchQuery } from "relay-runtime";
import { commitMutation } from "react-relay";
import { Translation } from 'react-i18next';
import i18n from 'i18next';
import paymentGatewayText from "../../../libs/paymentGatewayText";

const query = graphql`
    query paymentGatewayCreateFormQuery{
        bankAccountViewer{
            allBankAccount{
                edges{
                    node{
                        id
                        accountNumber
                        accountName
                        bankName

                    }
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation paymentGatewayCreateFormMutation($input : CreatePaymentGatewayGroupInput!){
        createPaymentGatewayGroup(input: $input){
            clientMutationId
            ok
            warningText
        }
    }
`;

const _ = require('lodash');


class PaymentGatewayCreateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            total_all: 0,
            image_upload: '',
            depositDate: new Date(),
            depositTotal: 0,
            feeTotal: 0,
            bank_list: [],
            bank_select: null,
            fee: 0.0,
            description: '',

            loading: false,
            redirectToList: false,
            deeplink_mod:false,
            signreq:Math.random().toString(36)

        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentWillMount() {
        await fetchQuery(environment, query, {}).then((data) => {
            if (_.isObject(data.bankAccountViewer)) {
                this.setState({ bank_list: data.bankAccountViewer.allBankAccount.edges })
            }
        });

        if(this.props.select_transaction){
            let total_all = _.sumBy(this.props.select_transaction, 'total')
            total_all = Math.round(((total_all) + Number.EPSILON) * 100) / 100;
            let depositTotal = 0
            let feeTotal = 0
            let deeplink_mod = false
            let bankAccount_id = this.props.select_transaction[0].bankAccount?.id || null
            if(paymentGatewayText(this.props.select_transaction[0]?.paymentType) === 'DEEPLINK'){
                deeplink_mod = true
                depositTotal = _.sumBy(this.props.select_transaction, 'amount')
                feeTotal = _.sumBy(this.props.select_transaction, 'feeAmount')
            }

            this.setState({ 
                total_all:total_all,
                bank_select:bankAccount_id,
                deeplink_mod:deeplink_mod,
                depositTotal:depositTotal,
                feeTotal:feeTotal
                
            })
        }
        
    }

    handleInputChange(e) {
        if (e.target.name === 'image_upload') {
            if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === 'image') {
                this.setState({ image_upload: e.currentTarget.files[0] });
            }
        }
        else if(e.target.name === 'depositTotal'){
            let depositTotal = 0
            if(parseFloat(e.target.value) > 0.00){
                depositTotal = e.target.value
            }

            //แบบที่น้อยกว่า เอาจำนวนเงินที่กรอกมาลบกับยอดรวมเข้าค่าธรรมเนียม
            let feeTotal = Math.round((( e.target.value - this.state.total_all) + Number.EPSILON) * 100) / 100;
            this.setState({ 
                depositTotal: depositTotal,
                feeTotal:Math.abs(feeTotal),
             })
        } 
        else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    onSubmit(e) {
       
        e.preventDefault();
        if (parseFloat((parseFloat(this.state.depositTotal) + parseFloat(this.state.feeTotal)).toFixed(2)) === parseFloat(this.state.total_all)) {
            Swal.fire({
                title: i18n.t("paymentgateway:Are you sure to add a deposit item?"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: i18n.t("paymentgateway:Yes"),
                cancelButtonText: i18n.t("paymentgateway:Cancel")
            }).then((result) => {
                if (result.value) {
                    let variables = {
                        input: {
                            paymentGatewayGroup: {
                                "description": this.state.description,
                                "depositDateTime": new Date(`${this.state.depositDate.toDateString()} 00:00:00 +07:00`),
                                // "bankAccountId": this.props.select_transaction[0].bankAccount.id,
                                "bankAccountId": this.state.bank_select,
                                "depositTotal": this.state.depositTotal,
                                "feeTotal": this.state.feeTotal,
                                "totalAll": this.state.total_all,
                                "deepLinkMod": this.state.deeplink_mod,
                                "transactions": JSON.stringify(this.props.select_transaction),
                                "clientMutationId":"PG"+this.state.signreq,
                            }
                        }
                    };

                    const uploadables = { image_deposit: this.state.image_upload };
                    commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            uploadables,
                            onCompleted: (response) => {
                                this.setState({ loading: false });
                                if (response.createPaymentGatewayGroup.ok) {
                                    Swal.fire(i18n.t("paymentgateway:Save successfully!"), '', 'success').then(() => {
                                        this.setState({ redirectToList: true });
                                    });
                                } else {
                                    Swal.fire(i18n.t("paymentgateway:Save unsuccessfully"), response.createPaymentGatewayGroup.warningText, 'warning');
                                }

                            },
                            onError: (err) => {
                                this.setState({ loading: false });
                                Swal.fire('Error!', i18n.t("paymentgateway:Please try again"), 'warning')
                            },
                        },
                    );

                }
            });

        } else {
            Swal.fire(i18n.t("paymentgateway:Wrong amount"), i18n.t("paymentgateway:Please check the amount again"), 'warning');
        }

    }

    render() {
        if (this.state.redirectToList) {
            return (<Redirect to="/accounting/finance/payment-gateway/list"/>);
        }
        if (this.props.select_transaction.length > 0) {
            return (
                <React.Fragment>
                    <div className="table-responsive fade-up">
                        <table className="table table-hover mt-2">
                            <thead className="thead-light">
                                <Translation>
                                    {t =>
                                        <tr>
                                            <th>{t("paymentgateway:No.")}</th>
                                            <th>{t("paymentgateway:Date")}</th>
                                            <th>{t("paymentgateway:Description")}</th>
                                            <th>{t("paymentgateway:bank")}</th>
                                            <th>{t("paymentgateway:payment Method")}</th>
                                            <th>{t("paymentgateway:Amount")}</th>
                                        </tr>}
                                </Translation>
                            </thead>
                            <tbody>
                                {this.props.select_transaction.map((payment_gateway) => {
                                    return (
                                        <tr key={payment_gateway.id}>
                                            <td>
                                                {payment_gateway.receive.docNumber}
                                            </td>
                                            <td> {format(payment_gateway.receive.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                            <td>{payment_gateway.description}</td>
                                            <td>{payment_gateway?.bankAccount ? payment_gateway.bankAccount.bankName + " " + payment_gateway.bankAccount.accountNumber : '-'}</td>
                                            <td>{paymentGatewayText(payment_gateway.paymentType)}</td>
                                            <Translation>{t => <td>{numberWithComma(payment_gateway.total)} {t("paymentgateway:Baht")}</td>}</Translation>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="row mt-3 fade-up">
                        <div className="col-9" />
                        <div className="col-3">
                            <div className="col">
                                <div className="row p-3 mb-2 bg-light text-dark">
                                    <Translation>{t => <div className="col text-left"><strong>{t("paymentgateway:All amount")}</strong></div>}</Translation>
                                    <div
                                        className="col text-right"><Translation>{t => <strong>{numberWithComma(this.state.total_all)} {t("paymentgateway:Baht")}</strong>}</Translation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-3 fade-up">
                        <form id="payment-gateway-deposit" onSubmit={this.onSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <Translation>{t => <label className="col-2 col-form-label">{t("paymentgateway:Description")}</label>}</Translation>
                                    <div className="col-5">
                                        <textarea className="form-control" rows="4"
                                            name="description" maxLength={256}
                                            onChange={this.handleInputChange}
                                            value={this.state.description}
                                            required={true}
                                        />

                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <Translation>{t => <label className="col-2 col-form-label">{t("paymentgateway:Deposit date")}</label>}</Translation>
                                    <div className="col">
                                        <DatePickerAdapter
                                            name="depositDate" className="form-control"
                                            selected={this.state.depositDate} /*maxDate={this.state.dueDate}*/
                                            onChange={this.handleInputChange}
                                            required={true}
                                        />

                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <Translation>{t => <label className="col-2 col-form-label">{t("paymentgateway:Deposit channel")}</label>}</Translation>
                                    <div className="col-3">
                                        <SearchSelect onChange={this.handleInputChange}
                                            // value={this.props.select_transaction[0].bankAccount.id}
                                            value={this.state.bank_select}
                                            name="bank_select" require={true}
                                            placeholder="กรุณาทำเลือกธนาคาร"
                                            queryObject={this.state.bank_list}
                                            keyOfValue="id"
                                            keyOfLabel="bankName:accountName:accountNumber"
                                            // disabled={true}
                                            disabled={this.props.select_transaction[0].bankAccount?.id ? true : false}
                                            placeholder_default={true}
                                        />

                                    </div>
                                    <Translation>{t => <label className="col-2 col-form-label ml-3 text-center">{t("paymentgateway:Amount")}</label>}</Translation>
                                    <div className="col-2">
                                        <input type="number" className="form-control text-right"
                                            step="0.01"
                                            name="depositTotal"
                                            onChange={this.handleInputChange}
                                            value={this.state.depositTotal}
                                            min="0"
                                            disabled={this.state.deeplink_mod}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <Translation>{t => <label className="col-2 col-form-label">{t("paymentgateway:Difference")}</label>}</Translation>
                                    <div className="col-3">
                                        <input type="text" className="form-control text-center"
                                            name="difference"
                                            value={this.state.deeplink_mod? "บัญชีพักค่าธรรมเนียม" : "รายรับค่าธรรมเนียมเพย์เมนท์เกตเวย์"}
                                            disabled={true}
                                        />
                                    </div>
                                    <Translation>{t => <label className="col-2 col-form-label ml-3 text-center">{t("paymentgateway:Amount")}</label>}</Translation>
                                    <div className="col-2">
                                        <input type="number" className="form-control text-right"
                                            step="0.01"
                                            name="feeTotal"
                                            onChange={this.handleInputChange}
                                            value={this.state.feeTotal}
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col bottom">
                                        <Translation>
                                            {t =>
                                                <label
                                                    className={this.state.image_upload.name ? "btn btn-success mb-0 cursor font-medium mr-3" : "btn btn-secondary mb-0 cursor font-medium mr-3"}>
                                                    {this.state.image_upload.name ? t("paymentgateway:Done") : t("paymentgateway:Upload receipt")}
                                                    <input type="file" name="image_upload"
                                                        onChange={this.handleInputChange}
                                                        hidden />
                                                </label>}
                                        </Translation>
                                        {this.state.image_upload.name}
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="row mt-5">
                        <div className="col text-right">
                            <div className="btn-group mr-2">
                                <Link to="/accounting/finance/payment-gateway/list">
                                    <Translation>
                                        {t =>
                                            <button type="button" className="btn btn-secondary add">
                                                {t("paymentgateway:cancel")}
                                            </button>}
                                    </Translation>
                                </Link>
                            </div>
                            <div className="btn-group mr-2">
                                <Translation>
                                    {t =>
                                        <button type="submit" className="btn btn-primary add" form="payment-gateway-deposit" disabled={this.state.loading}>
                                            {this.state.loading &&
                                                <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                            {t("paymentgateway:payment_gateway_deposit")}
                                        </button>}
                                </Translation>
                            </div>
                        </div>
                    </div>

                </React.Fragment>

            )
        }
        return <Redirect to="/accounting/finance/payment-gateway/list" />;
    }
}

export default PaymentGatewayCreateForm;
