/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QuickMassageTypeNote = "INVOICE" | "PAYMENT_QUOTATION" | "QUOTATION" | "RECEIVED" | "%future added value";
export type settingNoteReceiveQueryVariables = {|
  id?: ?string,
  type?: ?string,
|};
export type settingNoteReceiveQueryResponse = {|
  +quickMassageViewer: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +massageNote: ?string,
        +typeNote: QuickMassageTypeNote,
        +creator: ?string,
      |}
    |}>
  |}
|};
export type settingNoteReceiveQuery = {|
  variables: settingNoteReceiveQueryVariables,
  response: settingNoteReceiveQueryResponse,
|};
*/


/*
query settingNoteReceiveQuery(
  $id: ID
  $type: String
) {
  quickMassageViewer(id: $id, typeNote: $type) {
    edges {
      node {
        id
        massageNote
        typeNote
        creator
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "typeNote",
        "variableName": "type"
      }
    ],
    "concreteType": "QuickMassageNodeConnection",
    "kind": "LinkedField",
    "name": "quickMassageViewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QuickMassageNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "QuickMassageNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "massageNote",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeNote",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingNoteReceiveQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingNoteReceiveQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bb9ed6ef5ad38222db0059719f9b5537",
    "id": null,
    "metadata": {},
    "name": "settingNoteReceiveQuery",
    "operationKind": "query",
    "text": "query settingNoteReceiveQuery(\n  $id: ID\n  $type: String\n) {\n  quickMassageViewer(id: $id, typeNote: $type) {\n    edges {\n      node {\n        id\n        massageNote\n        typeNote\n        creator\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '36f2b484a1caa649cdf138d1e843c122';

module.exports = node;
