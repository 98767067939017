import React, { Component } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";
import i18n from "i18next";
import APIClaimReport from "../../../api/claimReport";
import ClaimReportPDF from "./claimReportPDF"

const head_table = [
  { value: "no", label: i18n.t("claimReport:no.") },
  { value: "date", label: i18n.t("claimReport:date") },
  { value: "title", label: i18n.t("claimReport:title") },
  { value: "claimarea", label: i18n.t("claimReport:claimArea") },
  { value: "tel", label: i18n.t("claimReport:tel") },
  { value: "price", label: i18n.t("claimReport:price") },
  { value: "numofapprove", label: i18n.t("claimReport:numofapprove") },
  { value: "status", label: i18n.t("claimReport:status") },
];

class WrapperClaimReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      claimReportList: [],
      loading: false,
      head_table: head_table,
      allApprovedAmount: null,
      allClaimAmount: null,
      startDate : this.props.match.params.startDate ? new Date(parseInt(this.props.match.params.startDate)) : '',
      endDate : this.props.match.params.endDate ? new Date(parseInt(this.props.match.params.endDate)) : '',
      search : this.props.match.params.search ?  this.props.match.params.search : '',
      lastIndex: 20,
    };
  }

  print() {
    window.print();
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.head_table !== this.state.head_table) {
      this.setState({
        head_table: this.state.head_table,
      });
    }
  }

  fetchData = () => {
    this.setState({ loading: true });

    let dataParams = {
        // first: this.state.first,
        // last: this.state.last,
        status: this.state.status,
        search: this.state.search,
        report: true,
        startDate:this.state.startDate,
        endDate:this.state.endDate,
    }
    APIClaimReport.getClaimReportALL(dataParams).then((res) => {
      this.setState(
        {
          claimReportList: _.chunk(res.data.claim_list, this.state.lastIndex),
          allApprovedAmount: res.data.all_approved_amount,
          allClaimAmount: res.data.all_claim_amount,
        },
        () => this.setState({ loading: false })
      );
    });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet
          style={[
            {
              cssText: `
                        body {
                            background-color: #ececec;
                        }
                    `,
            },
          ]}
        >
          <meta charSet="utf-8" />
          <title>Unit Report</title>
          <link
            href="https://fonts.googleapis.com/css?family=Sarabun&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <div className="print-top-menu">
          <div className="logo">
            <img
              src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png"
              alt="silverman"
            />
          </div>
          <div className="print" onClick={this.print}>
            PRINT
          </div>
        </div>
        {this.state.loading ?
                    <div className="text-center">Loading</div>
                    :
                    
                    this.state.claimReportList.map((unit,index) => {
                        let order = index === 0 ? 0 : this.state.lastIndex * index
                        return (
                            <ClaimReportPDF claimReportList ={unit} 
                                head_table ={this.state.head_table} 
                                state = {this.state} 
                                startDate = {this.state.startDate}
                                lastPage = {index+1 === this.state.claimReportList.length ? true : false}
                                endDate= {this.state.endDate} 
                                // order = {order}
                                key ={index + '-report'}
                            />
                        );
                    }) 
                }
      </React.Fragment>
    );
  }
}
export default WrapperClaimReport;
