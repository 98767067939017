import React, { useEffect, useState }   from  'react'
import Header from '../../components/header'
import Wrapper from '../../components/wrapper';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {commitMutation} from "react-relay";
import {fetchQuery } from "relay-runtime";
import { Link } from 'react-router-dom';
import getNameResidential from "../../libs/getNameResidential";
import './style.scss'
import { encode } from 'base-64';
import getAdvertise from '../../components/header/getAdvertise';
import clickAdvertise from '../../components/header/clickAdvertise';
import Loading from '../../libs/loading';
import moment from 'moment';
import { format } from 'date-fns';
import ViewTracker from '../../components/header/view-tracker'
import ReadClickAdvertise from '../../components/header/readClickAdvertise';
import {Translation} from "react-i18next";
import ReadAll from "../../components/header/readAll"

const query = graphql` 
        query viewAllNotificationQuery($id: ID,$types: String,$payGroup:String, $first: String, $last: String, $readStatus: String){
            notifications(first: $first, last: $last,residentId:$id,types: $types,payGroup: $payGroup, readStatus: $readStatus)
        }
`;

const mutation = graphql`
  mutation viewAllNotificationMutation($input: ReadNotiInput!)  {
    readNoti (input: $input) {
        ok   
    }
  }
`;

function GetTime(timeUpdated) {
    let today = new Date()
    let updatedDay = new Date(timeUpdated)
    let diffYear = moment(today).diff(updatedDay,'year');
    let diffMonth = moment(today).diff(updatedDay,'month');
    let diffDay = moment(today).diff(updatedDay,'day');
    let diffHour = moment(today).diff(updatedDay,'hours');
    let diffMinute = moment(today).diff(updatedDay,'minute');
    if(diffYear > 0){
        return diffYear + ' years ago'
    }else if(diffMonth > 0){
        return diffMonth + ' months ago'
    }else if(diffDay > 0){
        return diffDay + ' days ago'
    }else if(diffHour > 0){
        return diffHour + ' hours ago'
    }else if(diffMinute > 0){
        return diffMinute + ' minutes ago'
    }else{
        return 'just a moment'
    }
}


const NotificationDetail = (data) => {
    let name = ''
    let time = ''
    let detail = ''
    let url = ''
    let img = ''

    let repair_type_wording = "" 
    if(data.repair_type === 'public'){
        repair_type_wording = 'ส่วนกลาง'
    }else{
        repair_type_wording = 'ส่วนตัว'
    }

    time = GetTime(data.updated)

    if(data.type === 'vote'){
        name =  data.juristic_post
        detail = ' ได้รับการโหวตใหม่ใน \"แบบสอบถาม\"'
        url = '/contact/survey'
        img= `/images/header/noti/vote.png`
    }else if(data.type === 'invoice'){
        name = data.first_name !== '' ? getNameResidential(data.first_name ,data.last_name) : 'Customer' 
        detail = data.doc_number + ' มียอดค้างชำระ ' + data.total + ' บาท'
        url='/accounting/income/invoice/list/all'
    }else if(data.type === 'room'){
        name = data.sender_name
        detail = 'ได้ส่งข้อความเรื่อง ' + data.name
        url = '/contact/complaint/open'
        img= `/images/header/noti/chat.png`
    }else if(data.type === "room_group"){
        name = `[งานกรรมการ : ${ data.name}] `
        detail = " ได้ส่งข้อความ :" + data.detail
        img= `/images/header/noti/chat.png`
        url = `/contact/committee/chatGroup/${encode("RoomGroupNode:"+data.id)}`
    }else if(data.type === 'parcel'){
        name = data.receiver_name !== null ? data.receiver_name : 'มีพัสดุใหม่จากขนส่ง '
        detail = data.receiver_name !== null ? ' ได้รับพัสดุเรียบร้อยแล้ว' : data.service_name
        url = '/contact/parcel/all'
    }else if(data.type === "repairs_room"){
        name = `[งานใหม่] ${data.juristic_name ? data.juristic_name : data.tenant_name} แจ้งซ่อม${repair_type_wording}` 
        detail = ": "+ data.material
        url = '/contact/fix-requests/fortenant/review'
        img= `/images/header/noti/fix_request.png`
    }else if(data.type === "booking"){
        let startDate = data?.start_time.split("+")
        let endDate = data?.end_time.split("+")
        let _startDate = format(new Date(startDate[0]), ' DD/MM/YYYY')
        let _startTime = format(new Date(startDate[0]), 'HH:mm')
        let _endTime = format(new Date(endDate[0]), ' HH:mm')
        name = `[การจอง] ${data?.facility_name}  : `
        detail = `วันที่ ${_startDate} เริ่ม ${_startTime} จนถึง ${_endTime} จองโดย ${data?.booking_name} ${data?.residential_name ? 'ห้อง ' + data?.residential_name : ''}`
        url = '/contact/global-area/all'
        img= `/images/header/noti/booking.png`
    }else if(data.type === "collection_law_firm_paid"){
        name = `[ชำระแล้ว] ${data?.doc_number} ได้มีการชำระค่าบริการครบทั้งจำนวนเรียบร้อยแล้ว`
        detail = "กรุณาตรวจสอบค่าชำระค่าบริการออกเอกสาร"
        url = ''
    }else if(data.type === "collection_law_firm_overdue"){
        name = `[กรุณาชำระ] ${data?.doc_number} ครบกำหนดชำระค่าบริการแล้ว`
        detail = "กรุณาตรวจสอบกำหนดการชำระค่าบริการออกเอกสาร"
        url = ''
    }else if(data.type === "collection_law_firm_partial_payment"){
        name = `[ชำระบางส่วน] ${data?.doc_number} ชำระค่าบริการบางส่วน`
        detail = "กรุณาตรวจสอบค่าชำระค่าบริการออกเอกสาร"
        url = ''
    }
    return(
        <Link to={url} onClick = {() => {updateRead(data.id,data.type)}}>
            <div className={data.status === 'read' ? 'item cursor' : 'item bg-blue cursor'}  style={{color:data.status === 'read' ? '' : '#B9B9B9'}} key ={data.id}>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row col-12'>
                            <span><img className='mr-2' src={process.env.PUBLIC_URL + img} style={{width:'44px',height:'44px'}}/><strong>{name + ' '}</strong>{detail}</span>
                        </div>
                        <div className='row col-12 '><span className='mini'>{time}</span></div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

const sendTrackingEvent = (entry, id, title) => {
    try {
        // Send tracking event
        let variables = {
            input: {
                advertiseId: id,
                types: 0
            },
        };
        ReadClickAdvertise(
            variables,
            () => console.log(''),
            () => console.log('ads view fail!')
        );
    } catch (error) {
        console.log('error', error)
    } 
}

const Advertise = (data) => {
    let time = GetTime(data.node.updated)
    return(
        <ViewTracker key={data.node.id} onView={(entry)=> {sendTrackingEvent(entry, data.node.id, data.node.title)}}>
        <a href={data.node.link} target="_blank" 
            onClick={() => clickAdvertise(data.node.id)}
        >
            <div className='col-12 mt-1 mb-1' key ={data.node.id}>
                <div className='row advertise' >
                    <div className='col-1 text-center'>
                         <div className='mr-2'>
                            <img src={process.env.PUBLIC_URL + '/images/header/noti/lusso.png'} style={{width:'44px',height:'44px'}} />
                        </div>
                    </div>
                    <div className='bg-blue col-7'>
                        
                        <div className='col-12'>
                            <h6 style={{marginLeft: '-5px'}}>{data.node.title}</h6>
                        </div>
                        <div className='col-12'>
                            <span >{data.node.detail.length > 500 ? data.node.detail.substring(0,500) + '...' : data.node.detail}</span>
                        </div>
                        <div className='col-12 '><span className='mini'>{time}</span></div>
                    </div>
                    <div className='col-3 text-center'>
                        <img
                            src={data.node.image}
                            alt="advertise-icon"
                            style={{width:'auto', height:150}}
                        />
                    </div>
                </div>  
            </div>
        </a>
        </ViewTracker>
    )
}

const updateRead = (id,type) => {

    let variables = {
        input : {
            id : id,
            types : type
        }
    }
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                
            },
            onError: (err) => {
                console.log(err);
            },
        },
    )
}


const updateReadAll = (id,type) => {
    let variables = {
        input : {
            id : id,
            types : type
        }
    }
    ReadAll(variables)
}

const ViewAllNotification = (props) =>{
    const [new_list,setNewList] = useState([])
    const [old_list,setOldList] = useState([])
    const [advertise,setAdvertise] = useState([])
    const [loading,setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('');
    const [readType, setReadType] = useState('');


    const handleChange = (event) => {
        setReadType(event.target.value);
    };

    useEffect(() => {
        const fetchData = async() => {
            setLoading(true)
            fetchQuery(environment,query,{first:0,last:20,types:activeTab,readStatus:readType}).then(async data => {
                let noti_list = JSON.parse(data.notifications)
                if(noti_list === null){
                    setNewList([])
                    setOldList([])
                    setLoading(false)
                }else{
                    let new_list = []
                    let old_list = []
                    // let countBadge = 0
                    let index = Math.floor(noti_list.noti_list.length / 5)
                    await getAdvertise(index).then((data) => {
                        if(data !== null){
                            setAdvertise(data)
                        }else{
                            setAdvertise([])
                        }
                    })
                    for (const item of noti_list.noti_list){
                        const diffTime = Math.abs(new Date() - new Date(item.updated));
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                        // if(item.status === 'not_read'){
                        //     countBadge+=1
                        // }
                        if(diffDays > 7){
                            old_list.push(item)
                        }else{
                            new_list.push(item)
                        }
                    }
                    setNewList(new_list)
                    setOldList(old_list)
                    setLoading(false)
                }
        }).catch(err => {
            console.log(err);
            setNewList([])
            setOldList([])
            setLoading(false)
        })
    
        }
        fetchData()
        return() => {}
    },[activeTab,readType])

    let index_advertise = -1
    
    return(
        <Wrapper>
            <Header/>
            <Sidebar/>
            <WrapperContent>
                <div className='container-fluid box' id='notification-all'>
                    <div className='content-inner'>
                        <div className='row'>
                        <div className='col-6 mb-2'><h3>การแจ้งเตือน</h3></div>
                        <div className='col-6 d-flex justify-content-end'>
                            <div onClick = {() => {updateReadAll()}} ><h6 className='mt-2 mr-2 cursor' style={{color:'#1491DE',fontSize:'14px'}}>อ่านแล้วทั้งหมด</h6></div>
                            <select name="readtype" id="readtype" style={{width:'50%'}}
                                 onChange={(e) => handleChange(e)}
                                className="form-control dropdown-custom-arrow cursor"
                              >
                                <option value="">ฟิวเตอร์ข้อมูล</option>
                                <option value="">แสดงแล้วทั้งหมด</option>
                                <option value="read">อ่านแล้วทั้งหมด</option>
                                <option value="not_read">ยังไม่อ่านทั้งหมด</option>
                            </select>
                        </div>
                        </div>
                        <div className="row" id="navigation-tab">
                            <div className="col">
                                <ul>
                                    <li style={{ borderBottom: activeTab === '' ? '2px solid #1567B4' : '', color: activeTab === '' ? '#1567B4' : '' }}>
                                        <div onClick={() => setActiveTab('')}>
                                            <Translation>{t => t('receive:ทั้งหมด')}</Translation>
                                        </div>  
                                    </li>
                                    <li style={{ borderBottom: activeTab === 'room' ? '2px solid #1567B4' : '',color: activeTab === 'room' ? '#1567B4' : ''}}>
                                        <div onClick={() => setActiveTab('room')}>
                                            <Translation>{t => t('receive:ติดต่อนิติ')}</Translation> 
                                        </div>
                                    </li>
                                    <li style={{ borderBottom: activeTab === 'vote' ? '2px solid #1567B4' : '',color: activeTab === 'vote' ? '#1567B4' : '' }}>
                                        <div onClick={() => setActiveTab('vote')}>
                                            <Translation>{t => t('receive:โหวต')}</Translation> 
                                        </div>
                                    </li>
                                    <li style={{ borderBottom: activeTab === 'repairs_room' ? '2px solid #1567B4' : '',color: activeTab === 'repairs_room' ? '#1567B4' : ''  }}>
                                        <div onClick={() => setActiveTab('repairs_room')}>
                                            <Translation>{t => t('receive:แจ้งซ่อม')}</Translation> 
                                        </div>
                                    </li>
                                    <li style={{ borderBottom: activeTab === 'booking' ? '2px solid #1567B4' : '',color: activeTab === 'booking' ? '#1567B4' : ''  }}>
                                        <div onClick={() => setActiveTab('booking')}>
                                            <Translation>{t => t('receive:จองพื้นที่ส่วนกลาง')}</Translation> 
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {loading ? 
                          <Loading/>
                            :
                            (new_list.length === 0 && old_list.length === 0) ?
                                <div className='col-12 mb-4'>
                                    <strong className='ml-2' style={{color:'#B9B9B9'}}>ไม่ได้รับการแจ้งเตือน</strong>
                                </div>  
                                :
                                <React.Fragment>
                                    {new_list.length > 0 &&
                                    <React.Fragment>
                                        <div className='col-12 pl-0'>
                                            <strong>ใหม่</strong>
                                        </div>
                                        <div className='mb-2' >
                                            {new_list.map((list,index) => {
                                                if((index === list.length-1 && list.length <= 5 ) || ((index +1 )%5 === 0)){
                                                    index_advertise += 1
                                                    return (
                                                        <React.Fragment key={'new'+index}>
                                                            {NotificationDetail(list)}
                                                            {advertise.length >= index_advertise+1 && Advertise(advertise[index_advertise])}
                                                        </React.Fragment>
                                                    )
                                                }else{
                                                    return NotificationDetail(list)
                                                }
                                            })}
                                        </div>
                                    </React.Fragment>
                                    }
                                    {old_list.length > 0 &&
                                    <React.Fragment>
                                        <div className='col-12 pl-0'>
                                            <strong>ก่อนหน้านี้</strong>
                                        </div>
                                        <div className=' mb-2' >
                                            {old_list.map((list,index) => {
                                                if((index === list.length-1 && list.length <= 5 ) || ((index +1 )%5 === 0)){
                                                    index_advertise += 1
                                                    return (
                                                        <React.Fragment key={'old' + index}>
                                                            {NotificationDetail(list)}
                                                            {advertise.length >= index_advertise+1 && Advertise(advertise[index_advertise])}
                                                        </React.Fragment>
                                                    )
                                                }else{
                                                    return NotificationDetail(list)
                                                }
                                            })}
                                        </div>
                                    </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                    </div>
                </div>
            </WrapperContent>
        </Wrapper>
    );
}

export default ViewAllNotification