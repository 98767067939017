/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateRoomGroupMessageReadInput = {|
  messageRead: any,
  clientMutationId?: ?string,
|};
export type updateReadMessageMutationVariables = {|
  input: CreateRoomGroupMessageReadInput
|};
export type updateReadMessageMutationResponse = {|
  +createRoomGroupMessageRead: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type updateReadMessageMutation = {|
  variables: updateReadMessageMutationVariables,
  response: updateReadMessageMutationResponse,
|};
*/


/*
mutation updateReadMessageMutation(
  $input: CreateRoomGroupMessageReadInput!
) {
  createRoomGroupMessageRead(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRoomGroupMessageReadPayload",
    "kind": "LinkedField",
    "name": "createRoomGroupMessageRead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateReadMessageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateReadMessageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8e4ede377c9294942997671a8f2be440",
    "id": null,
    "metadata": {},
    "name": "updateReadMessageMutation",
    "operationKind": "mutation",
    "text": "mutation updateReadMessageMutation(\n  $input: CreateRoomGroupMessageReadInput!\n) {\n  createRoomGroupMessageRead(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f879096100bc7ba894fca6166f330303';

module.exports = node;
