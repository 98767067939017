import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";

const query = graphql`
    query getContactQuery($id: ID){
      contactViewer {
        id
        allContact(id: $id){
          edges{
            node{
              id
              firstName
              lastName
              name
              ownerName
              mailingAddress
              mailingDistrict
              mailingCity
              mailingProvince
              mailingPostalCode
              
              renterName
              renterAddress
              renterDistrict
              renterCity
              renterProvince
              renterPostalCode

              agentName
              agentAddress
              agentDistrict
              agentCity
              agentProvince
              agentPostalCode
            }
          }
        }
      }
    } 
`;

export const getContact = (variables, response) => {
  fetchQuery(environment, query, variables)
    .then((data) => {
      if (data) {
        response(data);
      }
    })
    .catch((error) => {
      response(null);
    });
};
