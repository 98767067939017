/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type fixRequestListForCommonQueryVariables = {||};
export type fixRequestListForCommonQueryResponse = {|
  +selfProject: ?{|
    +typeOfProject: ?ProjectTypeOfProject,
    +nameTh: ?string,
    +nameEn: ?string,
    +logo: ?string,
    +logoBase64: ?string,
    +address: ?string,
    +addressTh: ?string,
    +addressEn: ?string,
    +juristicContactNumber: ?string,
  |}
|};
export type fixRequestListForCommonQuery = {|
  variables: fixRequestListForCommonQueryVariables,
  response: fixRequestListForCommonQueryResponse,
|};
*/


/*
query fixRequestListForCommonQuery {
  selfProject {
    typeOfProject
    nameTh
    nameEn
    logo
    logoBase64
    address
    addressTh
    addressEn
    juristicContactNumber
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfProject",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameTh",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameEn",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoBase64",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressTh",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressEn",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "juristicContactNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "fixRequestListForCommonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "fixRequestListForCommonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d3c04763612b492f16fd9252ebbfe4e",
    "id": null,
    "metadata": {},
    "name": "fixRequestListForCommonQuery",
    "operationKind": "query",
    "text": "query fixRequestListForCommonQuery {\n  selfProject {\n    typeOfProject\n    nameTh\n    nameEn\n    logo\n    logoBase64\n    address\n    addressTh\n    addressEn\n    juristicContactNumber\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd98805a698a813667eab7830bd4040cc';

module.exports = node;
