import React, {Component, useEffect, useState,useCallback} from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TextField from '@mui/material/TextField'
import Lightbox from "react-images";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import Loading from '../../../../libs/loading';
import API from '../../../../api/silverGraund'
import APILogin from '../../../../api/logHistory'
import i18next from 'i18next';
import { Translation } from "react-i18next";
import jwtDecode from 'jwt-decode'
import { format } from "date-fns";

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));


function Row(props) {
  const {row, secoundLogs, loading2, expandedRow, setExpandedRow,setLoading2 } = props;
  const isExpanded = expandedRow === row.codeVisit;
  const [open, setOpen] = React.useState(false);
  const [image_light_box, setImageLightBox] = React.useState("");
  const [open_light_box, setOpenLightBox] = React.useState(false);

  const toggleRow = () => {
    setExpandedRow(isExpanded ? null : row.codeVisit);
    if (!isExpanded) {
      setLoading2(true); // Set loading when expanding the row
    }
  };

  const closeRow = () => {
    setExpandedRow(null);
  };

  useEffect(() => {
    if (isExpanded && loading2) {
      // Simulate loading effect with a delay of 1 second
      const timer = setTimeout(() => {
        setLoading2(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isExpanded, loading2]);

  const getPersonType = (val) => {
    let result;
    switch (val) {
        case "tenant":
          result = "ผู้เช่า/ลูกบ้าน"
          break;
        case "contact":
          result = "ติดต่อสำนักงานขาย"
          break;
        case "appointment":
          result = "ผู้ติดต่อนัดหมายล่วงหน้า"
        case "person":
          result = "บุคคลทั่วไป"
          break;
      }
      return result;
   }

  const toggleLightBox = (open_light_box, image = "") =>{
    setImageLightBox(image)
    setOpenLightBox(open_light_box)
  }

   const getStatus = (val) => {
    let result;
    switch (val) {
        case "coming":
          result = "กำลังจะเข้า"
          break;
        case "over":
          result = "อยู่ในที่จอด (จอดเกินเวลา)"
          break;
        case "park":
          result = "อยู่ในที่จอด"
        case "success":
          result = "สำเร็จ"
          break;
        default:
            result = 'ยกเลิก'
      }
      return result;
   }

   const getMethodPayment = (value) => {
    let result
    if(value === 'cash'){
        result = 'เงินสด'
    }else{
        result = 'QR Code'
    }
    return result
    }
  
  return (
    <React.Fragment>

  <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{fontFamily:'medium'}}>
        <TableCell component="th" scope="row">{row.codeVisit}</TableCell>
        <TableCell align="left">{row.dateUsingSt} - {row.timeUsingSt}</TableCell>
        <TableCell align="center">{row.dateCheckOut} - {row.timeCheckOut}</TableCell>
        <TableCell align="center">{row.fullnameVisit || "-"}</TableCell>
        <TableCell align="center">{row.idCard}</TableCell>
        <TableCell align="center">{getPersonType(row.typePerson)}</TableCell>
        <TableCell align="center">
        <img
            src={row.img ? row.img : process.env.PUBLIC_URL + "/images/icons/select-photo.png"}
            alt="parcel"
            className="img-fluid cursor parcel"
            onClick={() =>
                toggleLightBox(
                    true,
                    row.img ? row.img : process.env.PUBLIC_URL + "/images/icons/select-photo.png"
                )
            }
                                                                                />
        </TableCell>
        <TableCell align="center">{row.typeRole  == "temp" ? "ครั้งเดียว" : "หลายครั้ง"}</TableCell>
        <TableCell align="center">{getMethodPayment(row.tool)}</TableCell>
        <TableCell align="center">{row.ceateBy !== null ? row.ceateBy : "Admin"}</TableCell>
        <TableCell align="center">{getStatus(row.status) || "-"}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              isExpanded ? closeRow() : toggleRow();
              props.fetchData2(row.carTransactionId);
            }}
           // onClick={() => { setOpen(!open); props.fetchData2(row.carTransactionId);}}
          >
             {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <Translation>
       {(t) =>
      <TableRow>
        <TableCell style={{ padding: 0}} colSpan={6}>
          {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
            <Table size="small" className='slip-dark-gray'>
                <TableHead>
                  <TableRow>
                      <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0',width:'20%' }}>{t("FixRequestReport:User")}</TableCell>
                      <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0',width:'5%' }}>{t("FixRequestReport:Type Work")}</TableCell>
                      <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0',width:'5%' }}>{t("FixRequestReport:Detail Log")}</TableCell>
                      <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0',width:'10%' }}>Log</TableCell>
                      <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0',width:'15%'}}>{t("FixRequestReport:Time")}</TableCell>
                  </TableRow>
                </TableHead>
                { !loading2 ?
                <TableBody>
                  {
                secoundLogs?.length > 0 ? 
                  secoundLogs?.map((itemLogs, indexLogs) => {
                      return (
                        <React.Fragment key={`index_item_log ${indexLogs}`}>
                          <TableRow>
                            <TableCell style={{ paddingBottom: "16px"}}>{`${itemLogs.user.name} (${itemLogs.user.username}) `}</TableCell>
                            <TableCell style={{ paddingBottom: "16px"}}>{`${itemLogs.type}`}</TableCell>
                            <TableCell style={{ paddingBottom: "16px"}}>{`${itemLogs.note}`}</TableCell>
                            <TableCell style={{ paddingBottom: "16px" }}>{`${JSON.stringify(itemLogs.log)}`}</TableCell>
                            <TableCell style={{ paddingBottom: "16px" }}>{`${format(itemLogs.action_time,"YYYY-MM-DD")}`}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    }) : 
                    <TableRow>
                        <TableCell style={{ paddingBottom: "16px" }}>{t("FixRequestReport:Not Found")}</TableCell>
                    </TableRow>
                  } 
                </TableBody>
                  :  <Loading/>} 
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> 
        }
        </Translation>
      <Lightbox
        images={[{ src: image_light_box }]}
        onClose={() => toggleLightBox(false)}
        isOpen={open_light_box}
        backdropClosesModal={true}
        showImageCount={false}
      />
  </>
     
    </React.Fragment>
  );
}


const VMSLogsTable = ({data,loading}) => {
  const [secoundLogs, setSecoundLogs] = useState([])
  const [loading2, setLoading2] = useState(true)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRow, setExpandedRow] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const fetchData2 = async (id) => {
    setLoading2(true)
   await  APILogin.getToken().then((res) => {
    let token = localStorage.getItem("token")
    let tokenDecode = jwtDecode(token)
        if (res?.data) {
            API.getSearchCarTransaction(res?.data?.access_token,id,tokenDecode?.site).then((res) => {
              if(res.data.logs){
                setSecoundLogs(res.data.logs)
                setLoading2(false)
              }
          }) 
        }
    })
  }
     

  return (
  
    <>
    <Translation>
      {(t) =>
      <TableContainer component={Paper}>
      { !loading ?
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',textAlign:'center'}}>{t("silvermanguard:codeVisitor")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',width:'10%',textAlign:'center'}}>{t("silvermanguard:dateSt")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',width:'13%',textAlign:'center'}}>{t("silvermanguard:dateEn")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',width:'10%',textAlign:'center'}}>{t("silvermanguard:nameVisitor")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',width:'15%',textAlign:'center'}}>{t("silvermanguard:idCard")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',width:'10%',textAlign:'center'}}>{t("silvermanguard:typePerson")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef'}}>{t("silvermanguard:carImageIn")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef'}}>{t("silvermanguard:carImageOut")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',width:'10%',textAlign:'center'}}>{t("silvermanguard:typeRole")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',width:'10%',textAlign:'center'}}>{t("silvermanguard:tool")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',width:'10%',textAlign:'center'}}>{t("silvermanguard:createBy")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',width:'30%',textAlign:'center'}}>{t("silvermanguard:status")}</TableCell>
              <TableCell align="center"/>
            </TableRow>
          </TableHead>
          <TableBody>
              {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <Row
                    key={index}
                    row={row}
                    fetchData2={fetchData2}
                    loading2={loading2}
                    secoundLogs={secoundLogs}
                    expandedRow={expandedRow}
                    setExpandedRow={setExpandedRow}
                    setLoading2={setLoading2}
                  />
                );
              })}
          </TableBody>
        </Table>
        :<Loading/>
      }   
      <TablePagination
        rowsPerPageOptions={[10, 15, 25]}
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
      }
    </Translation>
      </>
  );
}

export default VMSLogsTable;
