import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import ChequeTransactionListTable from "./chequeTransactionListTable";
import Navigation from "./navigation";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import _ from "lodash";
import ExportChequeTransaction from "../cheque/server_export_cheque_transaction";
import Search from "../../SearchInput";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
import Swal from "sweetalert2";
import i18next from "i18next";

const query = graphql`
    query chequeTransactionListAllQuery($status: String, $first: Int, $last: Int, $chequeTransactionId:String, $search: String, $start_date: DateTime, $end_date: DateTime, $historical: String,){
        allChequeTransactions(chequeTransactionId: $chequeTransactionId, status: $status,first :$first, last:$last ,search: $search, startDate : $start_date, endDate: $end_date, historical: $historical) {
            totalCount
            edges{
                node{
                    id
                    date
                    note
                    refNumber
                    depositDate
                    chequeDetails{
                        id
                        noNumber
                    }
                    chequeNo{
                        id
                        noNumber
                    }
                    chequeDate
                    bankAccount{
                        id
                        bankName
                    }
                    chequeNumber
                    chequeNoCustom
                    chequeDetailsCustom
                    payName
                    docNumber
                    description
                    price
                    status
                    juristic{
                            firstName
                            lastName
                    }
                    chequeDocuments{
                        edges{
                            node{
                                id
                                fileName
                                fileUpload
                            }
                        }
                    }
                    historical
                }  
            }          
        }
  }
`;

class ChequeTransactionListAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            temp_end_date: null,
            temp_start_date: null,
            start_date: null,
            end_date: null,
            checkList: null,
            listChequeTransaction: [],
        }

        this.onChangeSearch = this.onChangeSearch.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getListPrint = this.getListPrint.bind(this)
        this.onClickPrintAll = this.onClickPrintAll.bind(this)

    }

    getListPrint(list_cheque) {
        let listChequeTransaction = _.clone(list_cheque)
        this.setState({
            listChequeTransaction: listChequeTransaction
        })
    }

    onClickPrintAll() {
        if (this.state.checkList.length <= 0) {
            Swal.fire(i18next.t("Allaction:Please selected items before continue"), '', 'warning');
        }

    }

    onChangeSearch(text) {
        this.setState({
            search: text,
            start_date: this.state.temp_start_date,
            end_date: this.state.temp_end_date,
        });
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <div id="wrapper-content">
                    <AccountingTopMenuNavigation mini={true} />
                    <div className="container-fluid box" id="invoice-list">

                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('cheque:cheque_transaction')+ " "+"(QP)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                            <div className="col">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_cheque_create' }) &&
                                    <Link to="/accounting/finance/cheque/clearing/create">
                                        <button type="button" className="btn btn-primary add float-right">
                                            <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus" />
                                            <Translation>
                                                {
                                                    t => <span>{t('cheque:add_cheque_transaction')}</span>
                                                }
                                            </Translation>
                                        </button>
                                    </Link>
                                }
                                {/* PRINT edit permission*/}
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_cheque_print' }) &&
                                    <ExportChequeTransaction status={this.props.match.params.status} search={this.state.search} start_date={this.state.start_date} end_date={this.state.end_date} />
                                }
                            </div>
                        </div>
                        <div className="content-inner">
                            <Navigation />
                            <div className="row">
                                <div className="col-3"></div>
                                <div className="col-6">
                                    <InlineAdvanceFilter
                                        start_date={this.state.temp_start_date}
                                        end_date={this.state.temp_end_date}
                                        handleChange={this.handleChange}
                                    />
                                </div>
                                <div className="col-3">
                                    <Search
                                        callback_function={this.onChangeSearch}
                                        search={this.state.search}
                                    />
                                </div>
                            </div>
                            {/* สำหรับPrint หลายใบ */}
                            {/* <div className="col"> */}
                            {/* Print cheque permission */}
                            {/* {(_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_cheque_print' }) && this.props.match.params.status === 'wait')?
                                            this.state.listChequeTransaction.length > 0 ?
                                                <Link
                                                    to={{
                                                        pathname: "print-cheque/all/",
                                                        state: { checkList: this.state.listChequeTransaction }
                                                    }}>
                                                    <button type="button" className="btn btn-outline-secondary">
                                                        <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'}
                                                            alt="print-icon" />
                                                    </button>
                                                </Link> 
                                                :
                                                <button type="button" className="btn btn-outline-secondary"
                                                    onClick={this.onClickPrintAll}>
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'}
                                                        alt="print-icon" />
                                                </button>
                                            : null
                                    }
                                </div> */}
                            <div className="mt-2 mb-2">
                                <ChequeTransactionListTable
                                    query={query} status={this.props.match.params.status}
                                    search={this.state.search}
                                    start_date={this.state.start_date}
                                    end_date={this.state.end_date}
                                    getListPrint={this.getListPrint}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        )
    }
}

export default ChequeTransactionListAll;
