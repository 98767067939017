import React from 'react';
import { QueryRenderer } from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import _ from "lodash";
import Loading from '../../../libs/loading';
import i18next from 'i18next';

const otherExpensePaymentChannel = graphql`
    query pettyCashRecordListTableOtherExpenseQuery($search: String, $start_date: DateTime, $end_date: DateTime, $first: Int, $last: Int, $setPettyCash_Id: ID) {
        otherExpensePaymentChannelViewer{
            allOtherExpensePaymentChannel(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last, setPettyCash_Id: $setPettyCash_Id){
                totalCount
                edges{
                    node{
                        id
                        otherExpense{
                            id
                            docNumber
                            issuedDate
                            contact {
                                id
                                refNumber
                                name
                            }
                            unknownContact
                            otherExpenseTransaction{
                                edges{
                                    node{
                                        id
                                        description
                                        productAndService{
                                            id
                                            name
                                            productCode
                                        }
                                        chartOfAccount{
                                            id
                                            name
                                            chartOfAccountCode
                                        }

                                    }
                                }
                            }
                        }
                        description
                        price
                        status
                        statusSetPettyCash
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }
        }

    }
`;

class PettyCashRecordListTableOtherExpense extends ComponentPagination {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            redirect: false,
            reQuery: false,
            first: this.state.first,
            last: this.state.last
        };
    }

    handleChange() {
        this.setState({ reQuery: true });
    }

    append_list(valuse) {
        let list_return = {};
        let docNumber = null;
        valuse.edges.map((result, index) => {
            if (docNumber === null) {
                docNumber = result.node.otherExpense.docNumber
                list_return[result.node.otherExpense.docNumber] = [];
                list_return[result.node.otherExpense.docNumber].push(result)
            } else {
                if (docNumber !== result.node.otherExpense.docNumber) {
                    docNumber = result.node.otherExpense.docNumber
                    list_return[result.node.otherExpense.docNumber] = [];
                    list_return[result.node.otherExpense.docNumber].push(result)
                } else {
                    list_return[result.node.otherExpense.docNumber].push(result)
                }
            }
            return result
        })
        return list_return
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={otherExpensePaymentChannel}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status,
                    setPettyCash_Id: this.props.set_petty_cash_id,
                    reQuery: this.state.reQuery
                }}
                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        let otherExpense_allPettyCashRecord = this.append_list(props.otherExpensePaymentChannelViewer.allOtherExpensePaymentChannel)
                        return (<React.Fragment>
                            <div className="table-responsive fade-up card">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center">{i18next.t("pettyCashView:Document No.")}</th>
                                            <th className="text-center">{i18next.t("pettyCashView:Date ")}</th>
                                            <th>{i18next.t("pettyCashView:List of petty cash")}</th>
                                            <th>{i18next.t("pettyCashView:Code")}</th>
                                            <th>{i18next.t("pettyCashView:Account")}</th>
                                            <th>{i18next.t("pettyCashView:List")}</th>
                                            <th className="text-right" style={{ whiteSpace: 'nowrap' }}>{i18next.t("pettyCashList:Amount withdrawal")}</th>
                                            <th className="text-center">{i18next.t("pettyCashView:Status")}</th>
                                            <th />
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {Object.entries(otherExpense_allPettyCashRecord).map(([index, other_expense_petty_cash_record], index_stack) => {

                                            return (
                                                other_expense_petty_cash_record.map((other_petty, index_question) => {
                                                    return (
                                                        other_petty.node.otherExpense.otherExpenseTransaction.edges.map((other_expense_transaction, index) => {
                                                            let code = "";
                                                            let name_code = "";
                                                            let last_index = index + 1 === other_petty.node.otherExpense.otherExpenseTransaction.edges.length ? true : false
                                                            if (other_expense_transaction.node.productAndService) {
                                                                code = other_expense_transaction.node.productAndService.productCode;
                                                                name_code = other_expense_transaction.node.productAndService.name;
                                                            } else {
                                                                code = other_expense_transaction.node.chartOfAccount.chartOfAccountCode;
                                                                name_code = other_expense_transaction.node.chartOfAccount.name;
                                                            }
                                                            return (
                                                                <tr key={other_petty.node.id + index}>
                                                                    <td style={{ whiteSpace: 'nowrap' }} className={other_petty.node.statusSetPettyCash === "VOID" ? "text-center text-danger" : "text-center"}>
                                                                        {index === 0 ? other_petty.node.otherExpense.docNumber : "-"}
                                                                    </td>
                                                                    <td className="text-center">{format(other_petty.node.otherExpense.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                    <td>{other_petty.node.setPettyCash.docNumber} {other_petty.node.setPettyCash.withdrawer} {other_petty.node.setPettyCash.description}</td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>{code}</td>
                                                                    <td>{name_code}</td>
                                                                    <td>{other_petty.node.otherExpense.contact ? other_petty.node.otherExpense.contact.name : other_petty.node.otherExpense.unknownContact || '-'} - {other_petty.node.otherExpense.otherExpenseTransaction.edges[0].node.description}</td>
                                                                    <td className="text-right">{ last_index ? numberWithComma(other_petty.node.price) : "-"}</td>
                                                                    <td className={other_petty.node.statusSetPettyCash === "VOID" ? "text-center text-danger" : "text-center"}>{other_petty.node.statusSetPettyCash === 'WAIT' ? 'รอเบิก' : other_petty.node.statusSetPettyCash === "VOID" ? 'ยกเลิก' : 'เบิกแล้ว'}</td>
                                                                    <td className="text-center">
                                                                        {other_petty.node.refPettyCashRecordDoc ?
                                                                            <a download="name_of_downloaded_file"
                                                                                href={other_petty.node.refPettyCashRecordDoc}
                                                                                target={"_blank"}>
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + '/images/icons/download-file-icon.png'}
                                                                                    alt="back"
                                                                                    className="download-file-icon" />
                                                                            </a>
                                                                            :
                                                                            "-"
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {index_question === 0 && other_petty.node.status !== "VOID" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_print' }) &&
                                                                            <Link
                                                                                to={"/accounting/expense/other_expense/view/" + other_petty.node.otherExpense.id + "/" + other_petty.node.otherExpense.docNumber}
                                                                                target={"_blank"}>
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + '/images/icons/print.png'}
                                                                                    alt="print" className="print" />
                                                                            </Link>

                                                                        }
                                                                    </td>

                                                                </tr>
                                                            )//return otherExpenseTransaction
                                                        })

                                                    )//return other_expense_petty_cash_record
                                                })

                                            )//return Object
                                        }
                                        )}

                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <div className="col" />
                                <div className="col">
                                    <div className="form-inline float-right p-3 mb-2 mt-2 bg-light text-dark">
                                        <div className="text-left">{i18next.t("pettyCashView:Balance")}</div>
                                        <div className="text-right ml-5">{
                                            this.props.all_set_petty_cash_list.map((petty) => petty.node.id === this.props.set_petty_cash_id &&
                                            numberWithComma(Math.round(((petty.node.withdrawAmount - petty.node.totalPettyCashRecord) + Number.EPSILON) * 100) / 100)
                                            )
                                        } {i18next.t("pettyCashView:Baht")}</div>
                                    </div>
                                    <div className="form-inline float-right p-3 mb-2 mt-2 bg-light text-dark mr-4">
                                        <div className="text-left">{i18next.t("pettyCashView:Total amount withdrawal")}</div>
                                        <div className="text-right ml-5">{
                                            this.props.all_set_petty_cash_list.map((petty) => petty.node.id === this.props.set_petty_cash_id &&
                                            numberWithComma( Math.round(((petty.node.totalPettyCashRecord) + Number.EPSILON) * 100) / 100 )
                                            )
                                        } {i18next.t("pettyCashView:Baht")}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props.otherExpensePaymentChannelViewer.allOtherExpensePaymentChannel.totalCount} />
                            </div>
                        </React.Fragment>
                        )
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default PettyCashRecordListTableOtherExpense;