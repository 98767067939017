import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import Dashboard from './dashboard/';
import Contact from './contact';
import Product from './inventory/product/';
import Invoice from './income/invoice';
import Receive from './income/receive';
import ReceiptDeposit from './income/receipt_deposit';
import ProductTransaction from './inventory/product/productTransaction';
import ChartOfAccount from './chartOfAccount';
import DailyJournal from "./dailyJournal";
import Bank from "./finance/bank";
import WithheldTax from "./finance/withheldTax";
import withholdingTax from "./finance/withholdingTax";
import Tax from "./finance/tax/index";
import GeneralLedger from "./generalLedger/";
import NoMatch from "../components/noMatch";
import Cash from "./finance/cash";
import Cheque from "./finance/cheque";
import ChequeControl from "./finance/chequeControl";
import ChequeDeposit from "./finance/chequeDeposit";
import CreditNote from "./income/credit_note";
import GuaranteeMoneyReceived from "./guaranteeMoneyReceived";
import Purchase from "./expense/purchase"
import Pay from "./expense/pay"
import OtherExpense from "./expense/otherExpense"
import otherReceiver from "./income/other_receiver"
import contractService from "./income/contract_service"
import PettyCash from "./expense/petty_cash"
import Advance from "./expense/advance"
import WaterMeter from "./waterMeter"
import UnknownReceive from "./income/unknown_receive"
import AcceptCreditNote from "./expense/accept_credit_note"
import PrepaidDeposit from "./expense/prepaid_deposit"
import Report from "./report/"
import Asset from "./asset"
import Fine from "./income/fine"
import CreditCard from "./finance/credit_card"
import SummaryOfIncomeExpense from "./summaryOfIncomeExpense"
import BalanceSheetAccount from "./balanceSheetAccount/balanceSheetAccount"
import balanceSheetAccountStatements from "./balanceSheetAccountStatements/balanceSheetAccountStatements"
import CrossBillingPayment from './income/cross_billing_payment';
import TrialBalance from "./trialBalance";
import WorkSheet from "./workSheet";
import ElectricMeter from "./electricMeter";
import AccrualBasis from "./accrualBasis/index";
import CashBasis from "./cashBasis/index";
import BankTransfer from "./finance/bankTransfer";
import Budget from "./budget/index";
import DepositInterest from './finance/depositInterest';
import CollectionLetter from "./collectionLetters/index";
import CollectionLawFirm from "./collectionLawFirm/index"
import DebtFreeRequest from './debtFreeRequest'
import DebtFreeCertification from './debtFreeCertification'
import ContractExpense from './expense/contractExpense';
import SettingCollectionLetter from '../setting/settingCollectionLetter';
import SettingFee from '../setting/settingFee'
import PaymentGateway from './finance/payment_gateway'

// import BalanceSheet from "./balanceSheet"
// import AccruedRevenueExpenditure from "./accruedRevenueExpenditure" เก็บไว้เผื่ออันใหม่มีปัญหาจะได้สลับไปอันเก่าได้เลย

class Accounting extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={Dashboard}/>
                <PrivateRoute path={`${this.props.match.url}/income/invoice`} component={Invoice}/>
                <PrivateRoute path={`${this.props.match.url}/income/receive`} component={Receive}/>
                <PrivateRoute path={`${this.props.match.url}/income/receipt_deposit`} component={ReceiptDeposit}/>
                <PrivateRoute path={`${this.props.match.url}/contact`} component={Contact}/>
                <PrivateRoute path={`${this.props.match.url}/daily-journal`} component={DailyJournal}/>
                <PrivateRoute path={`${this.props.match.url}/inventory/product`} component={Product}/>
                <PrivateRoute exact path={`${this.props.match.url}/inventory/product-detail/:product_type/:id`} component={ProductTransaction}/>
                <PrivateRoute path={`${this.props.match.url}/finance/bank`} component={Bank}/>
                <PrivateRoute path={`${this.props.match.url}/finance/bank-transfer`} component={BankTransfer}/>
                <PrivateRoute path={`${this.props.match.url}/finance/withheldTax`} component={WithheldTax}/>
                <PrivateRoute path={`${this.props.match.url}/finance/withholdingTax`} component={withholdingTax}/>
                <PrivateRoute path={`${this.props.match.url}/finance/tax`} component={Tax}/>
                <PrivateRoute path={`${this.props.match.url}/finance/cash`} component={Cash}/>
                <PrivateRoute path={`${this.props.match.url}/finance/cheque-control`} component={ChequeControl}/>
                <PrivateRoute path={`${this.props.match.url}/finance/cheque-deposit`} component={ChequeDeposit}/>
                <PrivateRoute path={`${this.props.match.url}/finance/cheque`} component={Cheque}/>
                <PrivateRoute path={`${this.props.match.url}/finance/deposit-interest`} component={DepositInterest}/>
                <PrivateRoute path={`${this.props.match.url}/chart-of-account`} component={ChartOfAccount}/>
                <PrivateRoute path={`${this.props.match.url}/general-ledger`} component={GeneralLedger}/>
                <PrivateRoute exact path={`${this.props.match.url}/trial-balance`} component={TrialBalance}/>
                <PrivateRoute path={`${this.props.match.url}/work-sheet`} component={WorkSheet}/>
                <PrivateRoute path={`${this.props.match.url}/income/credit-note`} component={CreditNote}/>
                <PrivateRoute path={`${this.props.match.url}/guarantee-money-received`} component={GuaranteeMoneyReceived}/>
                <PrivateRoute path={`${this.props.match.url}/expense/purchase`} component={Purchase}/>
                <PrivateRoute path={`${this.props.match.url}/expense/pay`} component={Pay}/>
                <PrivateRoute path={`${this.props.match.url}/expense/other_expense`} component={OtherExpense}/>
                <PrivateRoute path={`${this.props.match.url}/income/other_receiver`} component={otherReceiver}/>
                <PrivateRoute path={`${this.props.match.url}/expense/petty_cash`} component={PettyCash}/>
                <PrivateRoute path={`${this.props.match.url}/expense/advance`} component={Advance}/>
                <PrivateRoute path={`${this.props.match.url}/water-meter`} component={WaterMeter}/>
                <PrivateRoute path={`${this.props.match.url}/electric-meter`} component={ElectricMeter}/>
                <PrivateRoute path={`${this.props.match.url}/income/unknown-receive`} component={UnknownReceive}/>
                <PrivateRoute path={`${this.props.match.url}/expense/accept-credit-note`} component={AcceptCreditNote}/>
                <PrivateRoute path={`${this.props.match.url}/expense/prepaid-deposit`} component={PrepaidDeposit}/>
                <PrivateRoute path={`${this.props.match.url}/report`} component={Report}/>
                <PrivateRoute path={`${this.props.match.url}/asset`} component={Asset}/>
                <PrivateRoute path={`${this.props.match.url}/income/fine`} component={Fine}/>
                <PrivateRoute path={`${this.props.match.url}/finance/credit-card`} component={CreditCard}/>
                <PrivateRoute path={`${this.props.match.url}/finance/payment-gateway`} component={PaymentGateway}/>
                <PrivateRoute path={`${this.props.match.url}/accrued-revenue-expenditure`} component={AccrualBasis}/>
                <PrivateRoute path={`${this.props.match.url}/balance-sheet`} component={BalanceSheetAccount}/>
                <PrivateRoute path={`${this.props.match.url}/balance-sheet-statement`} component={balanceSheetAccountStatements}/>
                <PrivateRoute path={`${this.props.match.url}/balance-sheet-statements/:note/:period`} component={balanceSheetAccountStatements}/>
                <PrivateRoute path={`${this.props.match.url}/cash-flow-statement`} component={CashBasis}/>
                <PrivateRoute path={`${this.props.match.url}/summary-income-expense`} component={SummaryOfIncomeExpense}/>
                <PrivateRoute path={`${this.props.match.url}/income/cross-billing-payment`} component={CrossBillingPayment}/>
                <PrivateRoute path={`${this.props.match.url}/budget`} component={Budget}/>
                <PrivateRoute path={`${this.props.match.url}/collection_letters`} component={CollectionLetter}/>
                <PrivateRoute path={`${this.props.match.url}/income/contract_service`} component={contractService}/>
                <PrivateRoute path={`${this.props.match.url}/debt_free_request`} component={DebtFreeRequest}/>
                <PrivateRoute path={`${this.props.match.url}/debt_free_certification`} component={DebtFreeCertification}/>
                <PrivateRoute path={`${this.props.match.url}/expense/contract_expese`} component={ContractExpense}/>
                <PrivateRoute path={`${this.props.match.url}/collection`} component={SettingCollectionLetter} />
                <PrivateRoute path={`${this.props.match.url}/fee`} component={SettingFee} />
                <PrivateRoute path={`${this.props.match.url}/collection-law-firm`} component={CollectionLawFirm} />

                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Accounting;
