import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import { format } from "date-fns";
import getNameResidential from "../../../../libs/getNameResidential";
import thLocale from "date-fns/locale/th";
import localStorage from "../../../../libs/localstorage";
import i18next from "i18next";

class ReceiptDepositReportTable extends React.Component {

    getColorAndText(status) {
        let text = '';
        let color = 'text-center';
        switch (status) {
            case 'DRAFT':
                text = 'แบบร่าง';
                break;
            case 'ACTIVE':
                text = 'ยังไม่ตัดชำระ';
                break;
            case 'USE_PARTIAL':
                text = 'ตัดชำระแล้วบางส่วน';
                break;
            case 'USE_ALL':
                text = 'ตัดชำระแล้วทั้งจำนวน';
                break;
            default:
                text = 'รายการที่ยกเลิก';
                color = 'text-center text-danger';
        }
        return <td className={color}>{text}</td>
    }

    getPaymentChannel(receipt_deposit){
        if(receipt_deposit.node.bankAccountTransaction.totalCount > 0){
            return "เงินโอน";
        }else if(receipt_deposit.node.cashTransaction.totalCount > 0){
            return "เงินสด";
        }else if(receipt_deposit.node.chequeDeposit.totalCount > 0){
            return "เช็ค"
        }else{
            return "อื่นๆ";
        }
    }


    render() {
        let total = 0;
        let cut_pay = 0;
        let total_balance_all = 0;
        return (
            <React.Fragment>
                {this.props.props.receiptDepositViewer.allReceiptDeposit.edges.map((receipt_deposit, index) => {
                    let order_id = this.props.state.first - localStorage.getPageLimit();
                    let total_use = 0
                    let total_balance = 0
                    if (receipt_deposit.node.status !== "VOID") {
                        total += receipt_deposit.node.total;
                        receipt_deposit.node.receiptDepositTransactionUsed.edges.map((val,inden)=>{ 
                            cut_pay += val.node.amount
                            total_use = total_use + val.node.amount
                            return val
                        })
                        total_balance += receipt_deposit.node.total - total_use
                        total_balance_all += total_balance

                        // receipt_deposit.node.receiptDepositTransaction.edges.map((val,inden)=>{ 
                        //     total_balance_all += val.node.balance
                        //     total_balance = total_balance + val.node.balance
                        //     return val
                        // })
                        return (
                            <tr key={receipt_deposit.node.id}>
                                <td className="text-center">{order_id + index + 1}</td>
                                <td className="text-center">
                                    {receipt_deposit.node.docNumber}
                                </td>
                                <td className="text-center">{format(receipt_deposit.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                <td>
                                    {receipt_deposit.node.contact.residential ? receipt_deposit.node.contact.residential.name : "-"}
                                </td>
                                <td>
                                    {receipt_deposit.node.contact.typeOfContact === "RESIDENTIAL" &&
                                        getNameResidential(receipt_deposit.node.firstName, receipt_deposit.node.lastName)
                                    }

                                    {receipt_deposit.node.contact.typeOfContact === "SUPPLIER" &&
                                        receipt_deposit.node.name
                                    }
                                    {" "}                                            
                                    {
                                    (receipt_deposit.node.payGroup === "RENT" || receipt_deposit.node.payGroup === "AGENT" || receipt_deposit.node.payGroup === "OWNER" || receipt_deposit.node.payGroup === "DEVELOPER") &&
                                    <label className="text-danger">
                                    ({receipt_deposit.node.payGroup === "RENT" || receipt_deposit.node.payGroup === "AGENT" || receipt_deposit.node.payGroup === "OWNER" || receipt_deposit.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${receipt_deposit.node.payGroup}`) : ""})
                                    </label>
                                    }                                  
                                </td>
                                <td>{receipt_deposit.node.receiptDepositTransaction.edges[0].node.description}</td>
                                <td className="text-right">{numberWithComma(receipt_deposit.node.total)}</td>
                                <td className="text-right">{numberWithComma(total_use)}</td>
                                <td className="text-right">{numberWithComma(total_balance)}</td>
                                <td className="text-center">
                                    {this.getPaymentChannel(receipt_deposit)}
                                </td>
                                {this.getColorAndText(receipt_deposit.node.status)}
                            </tr>
                        )
                    } else {
                        return (
                            <tr key={receipt_deposit.node.id}>
                                <td className="text-center text-danger">{order_id + index + 1}</td>
                                <td className="text-center text-danger">
                                    {receipt_deposit.node.docNumber}
                                </td>
                                <td className="text-center text-danger">{format(receipt_deposit.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                <td className="text-danger">
                                    {receipt_deposit.node.contact.residential ? receipt_deposit.node.contact.residential.name : "-"}
                                </td>
                                <td className="text-danger">                                    
                                    {receipt_deposit.node.contact.typeOfContact === "RESIDENTIAL" &&
                                        getNameResidential(receipt_deposit.node.firstName, receipt_deposit.node.lastName)
                                    }

                                    {receipt_deposit.node.contact.typeOfContact === "SUPPLIER" &&
                                        receipt_deposit.node.name
                                    }
                                    {" "}                                            
                                    {
                                    (receipt_deposit.node.payGroup === "RENT" || receipt_deposit.node.payGroup === "AGENT" || receipt_deposit.node.payGroup === "OWNER" || receipt_deposit.node.payGroup === "DEVELOPER") &&
                                    <label className="text-danger">
                                    ({receipt_deposit.node.payGroup === "RENT" || receipt_deposit.node.payGroup === "AGENT" || receipt_deposit.node.payGroup === "OWNER" || receipt_deposit.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${receipt_deposit.node.payGroup}`) : ""})
                                    </label>
                                    }                                    
                                </td>
                                <td className="text-danger">{receipt_deposit.node.receiptDepositTransaction.edges[0].node.description}</td>
                                <td className="text-right text-danger">{numberWithComma(0)}</td>
                                <td className="text-right text-danger">{numberWithComma(0)}</td>
                                <td className="text-right text-danger">{numberWithComma(0)}</td>
                                <td className="text-center text-danger">
                                    {receipt_deposit.node.bankAccountTransaction.totalCount > 0 && "เงินโอน"}
                                    {receipt_deposit.node.cashTransaction.totalCount > 0 && "เงินสด"}
                                    {receipt_deposit.node.chequeDeposit.totalCount > 0 && "เช็ค"}
                                </td>
                                {this.getColorAndText(receipt_deposit.node.status)}
                            </tr>
                        )
                    }
                })
                }
                {
                    (this.props.props.receiptDepositViewer.allReceiptDeposit.pageInfo.hasNextPage || this.props.props.receiptDepositViewer.allReceiptDeposit.pageInfo.hasPreviousPage) &&
                    <tr>
                        <td colSpan={6} className="text-right"><strong>{i18next.t("ReceiptDepositReport:Sum")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(total)}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(cut_pay)}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(total_balance_all)}</strong></td>
                        <td />
                        <td />
                        <td />
                    </tr>
                }
                {/* {!this.props.props.receiptDepositViewer.allReceiptDeposit.pageInfo.hasNextPage && */}
                    <tr>
                        <td colSpan={6} className="text-right"><strong>{i18next.t("ReceiptDepositReport:Total")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(this.props.props.receiptDepositViewer.summaryReceiptDeposit)}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(this.props.summaryUsePartialReceiptDeposit.cut_pay)}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(this.props.summaryUsePartialReceiptDeposit.total_balance)}</strong></td>
                        <td />
                        <td />
                    </tr>
                {/* } */}
            </React.Fragment>
        )
    }
}

export default ReceiptDepositReportTable;
