import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import Loading from "../../../../libs/loading";
import getNameResidential from "../../../../libs/getNameResidential";
import {format, differenceInCalendarDays} from "date-fns";
import IndividualReceiptReportPDFRenderTable from "./individualReceiptReportPDFRenderTable";
import Swal from "sweetalert2";
import _ from "lodash";
import './individualReceiptReportPDF.scss';

const query = graphql`
  query individualReceiptReportPDFQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $endDateOnDate: Date
    $search: String
    $customerType: String
    $productAndService: String
    $productAndServiceID: ID
    $contact: String
    $overdueDate: String
    $rangeOverdueDate: [Int]
  ) {
    invoiceViewer {
      allInvoice(
        approveOnly: true
        receivableOutstanding: true
        first: $first
        last: $last
        startDate: $startDate
        endDate: $endDate
        search: $search
        customerType: $customerType
        productAndService: $productAndService
        # transaction_ProductAndService_Id: $productAndServiceID
        contact: $contact
        order: "contact__residential__name"
        suborder: "individual_report"
        status_In: "active, overdue, partial_payment, paid"
        overdueDate: $overdueDate
        rangeOverdueDate: $rangeOverdueDate
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            docNumber
            issuedDate
            dueDate
            contact {
              id
              name
              firstName
              lastName
              refNumber
              typeOfContact
              residential {
                id
                name
              }
            }
            transaction(productAndService_Id: $productAndServiceID) {
              edges {
                node {
                  id
                  description
                  whtRate
                  total
                  productAndService {
                    id
                    productCode
                    name
                  }
                  chartOfAccount {
                    chartOfAccountCode
                  }
                  receiveTransaction(receive_Status_In: "paid" , receive_IssuedDate_Lte:$endDateOnDate) {
                    edges {
                      node {
                        amount
                        added
                        receive {
                          issuedDate

                        }
                      }
                    }
                  }
                  creditNoteTransaction(status: "paid",issuedDate_Lte:$endDateOnDate, receive_Isnull:true ) {
                    edges {
                      node {
                        price
                        issuedDate
                        receive {
                          id
                        }
                      }
                    }
                  }
                  creditNoteTransactionRecord(status: "paid",creditNote_IssuedDate_Lte:$endDateOnDate){
                      edges{
                          node{
                              id
                              price
                              creditNote{
                                  id
                                  docNumber
                                  issuedDate
                                  status
                              }
                              price
                              status
                          }
                      }
                  }
                  receiptDepositTransactionUsed(receiveTransaction_Isnull:true) {
                    edges {
                      node {
                        amount
                        receiveTransaction {
                          id

                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        totalCount
      }
    }
    selfProject{
      name
    }   
    contactViewer{
            allContact(typeOfPayment: "receivable",order: "ref_number"){
                edges{
                    node{
                        id
                        refNumber
                        name
                        firstName
                        lastName
                    }
                }
            }
    }
    productViewer{
            allProduct (type_In: "product, service, fine"){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        description
                        totalLeftInStock
                    }
                }
            }
      }
  }
`;
class IndividualReceiptReportPDF extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            contactId: null,
            id: null,
            typePrint: "",
            
            loading: false,
            dataAll: [],
            projectName: "",
            contactList: [],
            productAndServiceList: [],
        }
        this.componentWillMount = this.componentWillMount.bind(this);
        this.queryData = this.queryData.bind(this);
    }

    componentWillMount() {
        this.queryData()
    }

    calOverdueDate(issueDate) {
        let overdueDate = Math.ceil((new Date() - new Date(issueDate)) / (1000 * 60 * 60 * 24))
        if(overdueDate < 0) {
            return ""
        }
        return overdueDate
    }

    queryData() {
        let props = this.props.location.state.state;
        this.setState({ 
            loading: true,
          })
          let variable = {
            startDate: props.startDate,
            endDate: props.endDate,
            endDateOnDate: props.endDateOnDate,
            search: props.search,
            customerType: props.customerType,
            productAndService: props.productAndService,
            productAndServiceID: props.productAndServiceID,
            contact: props.contact,
            overdueDate: props.overdueDate,
            rangeOverdueDate: props.range_overdue_date,
            dueDate: props.dueDate,
          }
          
          fetchQuery(environment, query, variable).then(data => {
            let dataAll = this.getData(data)     
            this.setState({ 
              loading: false,   
              dataAll: dataAll,
              projectName: data.selfProject.name, 
              contactList: data.contactViewer.allContact.edges,
              productAndServiceList: data.productViewer.allProduct.edges,
             })
          })
          .catch(error => {
            Swal.fire("Error!", "", "error"); 
          })
    }

      getData = (data) => {
        let dataAllForPDF = [];
        let dataRow = [];
        let allInvoiceData = data.invoiceViewer.allInvoice.edges
        let sum_totals = [0, 0, 0, 0, 0, 0, 0, 0];
        let sum_total_contact = 0;
        let outstandingBalanceOfCustomer = 0;
        let summaryAmoutBeforePaid = 0;
        let summaryAmountPaid = 0;
            
    
        allInvoiceData.map((invoice, index) => {                    
          let first = true
          let invoice_select = null
        //   let invoiceSelectNextItem = [];
          let firstLineOfCustomer = false;
          let invoiceName = "";
          let firstName = "";
          let lastName = "";
          let sameNextItem = true;                                
    
    
          if(index > 0 && invoice.node.contact?.id !== allInvoiceData[index-1]?.node?.contact?.id){
            outstandingBalanceOfCustomer = 0;
              if(invoice.node.contact.typeOfContact === "RESIDENTIAL") {
                  invoiceName = invoice.node.contact.residential.name
                  firstName = invoice.node.contact.firstName
                  lastName = invoice.node.contact.lastName
                  firstLineOfCustomer = true
              } else if(invoice.node.contact.typeOfContact === "SUPPLIER") {
                  invoiceName = invoice.node.contact.refNumber
                  firstName = invoice.node.contact.name
                  lastName = ""
                  firstLineOfCustomer = true
              }
    
          } else if(index === 0){
              if(invoice.node.contact.typeOfContact === "RESIDENTIAL") {
                  invoiceName = invoice.node.contact.residential.name
                  firstName = invoice.node.contact.firstName
                  lastName = invoice.node.contact.lastName
                  firstLineOfCustomer = true
              } else if(invoice.node.contact.typeOfContact === "SUPPLIER") {
                  invoiceName = invoice.node.contact.refNumber
                  firstName = invoice.node.contact.name
                  lastName = ""
                  firstLineOfCustomer = true
              }
          }
    
          if(allInvoiceData[index+1] && invoice.node.contact?.id !== allInvoiceData[index+1]?.node?.contact?.id){                            
              sameNextItem = false                            
          } else if(!allInvoiceData[index+1]) {
              sameNextItem = false
          } else {
              sameNextItem = true
          }
    
          if (this.props.location.state.state.productAndServiceID === "") {
              invoice_select = invoice.node.transaction.edges
          }else {
              invoice_select = invoice.node.transaction.edges.filter(element => {
                  if(element.node.productAndService){
                      if(element.node.productAndService.id === this.props.location.state.state.productAndServiceID){
                          return element
                      }
                  }
              });
    
            //   // invoice_select ตัวถัดไป
            //   invoiceSelectNextItem = allInvoiceData[index +1]?.node.transaction.edges.filter(element => {
            //       if(element.node.productAndService){
            //           if(element.node.productAndService.id === this.props.location.state.state.productAndServiceID){
            //               return element
            //           }
            //       }
            //   });

          }           
            invoice_select.map((transaction, t_index) => {
                    const amountOwed = -0.01 <this.getAmountOwed(transaction) && this.getAmountOwed(transaction) < 0.01 ? 0 : this.getAmountOwed(transaction) 
                    const amountOwedWithComma = this.numberWithComma(amountOwed, "")
                    const amountPaid = this.getAmountPaid(transaction)
                    const amountPaidWithComma  = this.numberWithComma(amountPaid, "")                                                        
                        if (amountOwed !== 0) {                      
                            first = false
        
                            outstandingBalanceOfCustomer += amountOwed
                            
        
                            let total_contact = 0
                            sum_total_contact += amountOwed
        
                            if (!invoice.node.transaction.edges[t_index + 1]) {
                                total_contact = sum_total_contact
                                sum_total_contact = 0
                            }     
                            if (this.getNumberDay(invoice) < 1) {
                                sum_totals[0] += amountOwed
                            } else if (this.between(this.getNumberDay(invoice), 1, 30)) {
                                sum_totals[1] += amountOwed
                            } else if (this.between(this.getNumberDay(invoice), 31, 60)) {
                                sum_totals[2] += amountOwed
                            } else if (this.between(this.getNumberDay(invoice), 61, 90)) {
                                sum_totals[3] += amountOwed
                            } else if (this.between(this.getNumberDay(invoice), 91, 120)) {
                                sum_totals[4] += amountOwed
                            } else if (this.between(this.getNumberDay(invoice), 121, 150)) {
                                sum_totals[5] += amountOwed
                            } else if (this.between(this.getNumberDay(invoice), 151, 180)) {
                                sum_totals[6] += amountOwed
                            } else {
                                sum_totals[7] += amountOwed
                            }                        
                            summaryAmoutBeforePaid += transaction.node.total
                            summaryAmountPaid += amountPaid
        
                            if(firstLineOfCustomer && t_index === 0){                                
                                dataRow.push(invoiceName)
                                dataRow.push(getNameResidential(firstName,lastName))
                                dataRow.push("")
                                dataRow.push("")
                                dataRow.push("")
                                dataRow.push("")
                                dataRow.push("")
                                dataRow.push("")
                                dataRow.push("")
                                dataRow.push("")
                                dataAllForPDF.push(dataRow)
                                dataRow = [];
                            }
                                    
                            dataRow.push("")
                            dataRow.push("")
                            dataRow.push(format(invoice.node.issuedDate, 'DD/MM/YYYY'))
                            dataRow.push(format(invoice.node.dueDate, 'DD/MM/YYYY'))
                            dataRow.push(invoice.node.docNumber)
                            dataRow.push(transaction.node.productAndService ? transaction.node.productAndService?.productCode : (transaction.node.chartOfAccount && transaction.node.chartOfAccount?.chartOfAccountCode === '1131-12' || transaction.node.chartOfAccount?.chartOfAccountCode === '1131-10' ? 'S000' : ''))
                            dataRow.push(this.removeTrailingZeros(transaction.node.description))
                            dataRow.push(this.calOverdueDate(invoice.node.dueDate))  
                            dataRow.push(this.numberWithComma(transaction.node.total, ""))
                            dataRow.push(amountPaidWithComma)
                            dataRow.push(amountOwedWithComma)
                            {!sameNextItem ? 
                                dataRow.push(this.numberWithComma(outstandingBalanceOfCustomer, ""))
                                :
                                dataRow.push("")
                            }
                            dataAllForPDF.push(dataRow)
                            dataRow = [];        
                        } else if(t_index === 0 && firstLineOfCustomer){                            
                            // กรณีที่ data ที่ query มามี transaction transsaction แรกมี AmountOwed เป็น 0 แต่ transaction อื่นมีปกติจะให้แถวแรกแสดงชื่อ
                            for (let index = 0; index < invoice_select.length; index++) {
                                const currentTransaction = invoice_select[index];                                            
                                let thisCustomerHaveAmountOwned = currentTransaction && this.getAmountOwed(currentTransaction)
                                if (thisCustomerHaveAmountOwned){
                                    dataRow.push(invoiceName)
                                    dataRow.push(getNameResidential(firstName,lastName))
                                    dataRow.push("")
                                    dataRow.push("")
                                    dataRow.push("")
                                    dataRow.push("")
                                    dataRow.push("")
                                    dataRow.push("")
                                    dataRow.push("")
                                    dataRow.push("")
                                    dataRow.push("")
                                    dataRow.push("")
                                    dataAllForPDF.push(dataRow)
                                    dataRow = [];
                                    return
                                }
                            }
                        }
            })
        })
        dataRow.push("")
        dataRow.push("")
        dataRow.push("")
        dataRow.push("")
        dataRow.push("")
        dataRow.push("")
        dataRow.push("")
        dataRow.push("รวมทั้งหมด")
        dataRow.push(this.numberWithComma(summaryAmoutBeforePaid, ""))
        dataRow.push(this.numberWithComma(summaryAmountPaid, ""))
        dataRow.push(this.numberWithComma(sum_totals.reduce((a, b) => a + b, 0), ""))
        dataRow.push(this.numberWithComma(sum_totals.reduce((a, b) => a + b, 0), ""))
        dataAllForPDF.push(dataRow)
        return dataAllForPDF
      }
    
      removeTrailingZeros(description){
        if(description.includes("ค่าน้ำประปา")){
            description = description.replace(/ *\[[^)]*\] */g, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
      }
    
      getAmountOwed(transaction) {
          const sumRecive = transaction.node.receiveTransaction.edges.reduce((total, obj) => {
              if(differenceInCalendarDays(this.props.location?.state.state.endDate, obj.node.receive.issuedDate) >= 0) {
                  return total + obj.node.amount
              } else {
                  return total
              }
          }, 0);
    
          const sumReceiptDeposit = transaction.node.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
              if (obj.node.receiveTransaction) {
                  return total
              } else {
                  return total + obj.node.amount
              }
          }, 0);
    
          const sumCreditNote = transaction.node.creditNoteTransaction.edges.reduce((total, obj) => {
              if(obj){
                  if (obj.node.receive) {
                      return total
                  }else if(differenceInCalendarDays(this.props.location?.state.state.endDate, obj.node.issuedDate) >= 0){
                      return total + obj.node.price
                  }else{
                      return total
                  }
              }
          }, 0)
    
          const sumCreditNotev2 = transaction.node.creditNoteTransactionRecord.edges.reduce((total, obj) => {
              if(obj){
                  if(differenceInCalendarDays(this.props.location?.state.state.endDate, obj.node.creditNote.issuedDate) >= 0){
                      return total + obj.node.price
                  }else{
                      return total
                  }
              }
          }, 0)
          return parseFloat(transaction.node.total - transaction.node.whtRate - sumRecive - sumReceiptDeposit - sumCreditNote - sumCreditNotev2)
      }
    
      getAmountPaid(transaction) {
          var _this = this
    
          const sumRecive = transaction.node.receiveTransaction.edges.reduce((total, obj) => {
              if(differenceInCalendarDays(this.props.location?.state.state.endDate, obj.node.receive.issuedDate) >= 0) {
                  return total + obj.node.amount
              } else {
                  return total
              }
          }, 0);
    
          const sumReceiptDeposit = transaction.node.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
              if (obj.node.receiveTransaction) {
                  return total
              } else {
                  return total + obj.node.amount
              }
          }, 0);
    
          const sumCreditNote = transaction.node.creditNoteTransaction.edges.reduce((total, obj) => {
              if(obj){
                  if (obj.node.receive) {
                      return total
                  }else if(differenceInCalendarDays(this.props.location?.state.state.endDate, obj.node.issuedDate) >= 0){
                      return total + obj.node.price
                  }else{
                      return total
                  }
              }
          }, 0)
    
          const sumCreditNotev2 = transaction.node.creditNoteTransactionRecord.edges.reduce((total, obj) => {
              if(obj){
                  if(differenceInCalendarDays(this.props.location?.state.state.endDate, obj.node.creditNote.issuedDate) >= 0){
                      return total + obj.node.price
                  }else{
                      return total
                  }
              }
          }, 0)
          return parseFloat(-transaction.node.whtRate + sumRecive + sumReceiptDeposit + sumCreditNote + sumCreditNotev2)
      }
    
      getNumberDay(invoice) {
          if (differenceInCalendarDays(this.props.location?.state.state.endDate, invoice.node.dueDate) < 0) {
              return 0
          } else {
              return differenceInCalendarDays(this.props.location?.state.state.endDate, invoice.node.dueDate)
          }
      }
    
      between(value, min, max) {
          return value >= min && value <= max;
      }
    
      numberWithComma(amount, blank_sign = '-', digit = false, number_only = false) {
    
        let formatter = new Intl.NumberFormat('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    
        if (digit || digit === 0) {
            formatter = new Intl.NumberFormat('en', {
                minimumFractionDigits: digit,
                maximumFractionDigits: 2
            });
        }
        
        if (amount !== undefined && !isNaN(amount) && amount !== 0 && amount !== null) {
            if(amount < 0) {
                if(number_only) {
                    return formatter.format(amount)
                }
            } else {
                return formatter.format(amount);
            }
        } else {
            return blank_sign;
        }
      };

      getContact(contact) {
        let value = ""
        let listContact = []
    
        listContact = _.filter(this.state.contactList, (o) => o.node.id === contact)
    
        if (listContact.length > 0) {            
          value = `${listContact[0].node?.refNumber} ${listContact[0].node?.name} ${listContact[0].node?.firstName} ${listContact[0].node?.lastName}`
        } else {
          value = "ทั้งหมด"
        }        
        return value
      }
    
      getProduct(productAndService){
        let value = ""
        let listProductAndService = []
    
        listProductAndService = _.filter(this.state.productAndServiceList, (o) => o.node.id === productAndService)
        
        if (listProductAndService.length > 0) {            
          value = `${listProductAndService[0].node?.productCode} ${listProductAndService[0].node?.name}`
        } else {
          value = "ทั้งหมด"
        }
        return value
      }
    
      render() {
        const style = [{
            "cssText": `
            body {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                background-color: #FAFAFA;
                }
            
                * {
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                }
            
                .page {
                width: 297mm;
                max-hight:210mm;
                min-height: 210mm;
                padding: 10mm 5mm 5mm 5mm ;
                border: 1px #000 solid;
                border-radius: 2px;
                background: white;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                position: relative;
                }
                .pageNumber{
                position: fixed;
                bottom: 0;
                }
            
                @page {
                size: a4 landscape;
                margin: 0;
                }
                @media print {
                html, body {
                    width: 297mm;
                    height: 210mm;
                }

                @page: last{
                    .pageNumber {
                        display: block;
                    }
                }
                .bg-gray{
                    background-color: #D3D3D3;
                }
                .page {
                    margin: 0;
                    border: initial;
                    border-radius: initial;
                    width: initial;
                    min-height: initial;
                    box-shadow: initial;
                    background: initial;
                    page-break-after: always;
                    position: relative;
                }
                .pageNumber{
                    position: fixed;
                    bottom: 0;
                }
                }
        `
        }]
        let props = this.props.location.state.state;
        let productAndService = this.getProduct(props.productAndServiceID)
        let contact = this.getContact(props.contact)
        let haveNoFilterContact = props.contact ? false : true

        return (
            <React.Fragment>
                        <Helmet
                        style={style}>
                        <meta charSet="utf-8" />
                        <title>Individual Receivable</title>
                        <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        </Helmet>
                        <div id="individualReceiptReportPDF">
                            <div className="print-top-menu">
                                    <div className="logo">
                                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                                    </div>
                                    <div className="print" onClick={() => window.print()}>
                                        PRINT
                                    </div>
                            </div>     
                            {
                                this.state.loading ?
                                <Loading />
                                    :
                                <IndividualReceiptReportPDFRenderTable 
                                    dataAll={this.state.dataAll}
                                    atDate={this.props.location.state.state.endDateOnDate}
                                    projectName={this.state.projectName}
                                    productAndService={productAndService}
                                    contact={contact}
                                    haveNoFilterContact={haveNoFilterContact}
                                />
                            }
                        </div>    
            </React.Fragment>
        )
    }
}
export default IndividualReceiptReportPDF;



