import '../styles/main.scss';
import React, {Component} from 'react';
import QRCode from "qrcode.react";
import Barcode from "react-barcode";

import $ from "jquery";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import getNameResidential from "../../libs/getNameResidential";
import {getTypeProject} from "../../libs/getTypeOfProject";
import i18n from "i18next"
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import _ from "lodash";
import './invoice.scss';


const ThaiBaht = require('thai-baht-text');
const numberToText = require('number-to-text')
require('number-to-text/converters/en-us');


const dicts = [];

class InvoiceTemplete5 extends Component {

    constructor(props){
        super(props);
        this.state = {
            sum_vat: 0,
            sum_amount: 0,
            sum_grand_total:0,
            sum_discount:0,
            discount:0,
            sum_total:0,
            sum_wht_rate: 0,
            sum_receive_deposit: 0,
            total_wht_rate: 0,
            receiveDeposit: 0,
            total_pay_qr: 0,
            project : atob(this.props.selfProject.id).split(":")[1],
            doc_num_for_qr : this.props.invoice.docNumber.indexOf("-") > -1 ? this.props.invoice.docNumber.split("-")[1] : this.props.invoice.docNumber,
            contact_room_name : this.props.invoice.contact.residential && this.props.invoice.contact.residential.name!== null ? this.props.invoice.contact.residential.name.replace(/[\/()\s]/g, "")  : this.props.invoice.contact.refNumber.replace("C", "") ,
            
            have_vat:"",
            no_vat:"",
            have_wht_rate_percent:"",
            no_wht_rate_percent:"",
            discount_status:"",
            list_history:[],
            list_map: [],
            diff: 0,
            check_type: 'all',
            over_page:false,
            page_invoice : [],
            remark : "",
            show_tax:false,
            languages:"th"

        };

         this.calSummary = this.calSummary.bind(this);
         this.allStatus = this.allStatus.bind(this);
         this.getPrefix = this.getPrefix.bind(this);
         this.isBangkok = this.isBangkok.bind(this);
         this.setData = this.setData.bind(this);
         this.getHistoryById = this.getHistoryById.bind(this);
         this.getDiff = this.getDiff.bind(this);
         this.setTaxStatus = this.setTaxStatus.bind(this);
    }

    componentDidMount() {

        this.calSummary();
        this.allStatus();
        this.setData();
        this.setPage();
        this.setState({list_map: [1, 2]});
        this.queryLanguages();
        this.setTaxStatus();
    }

    async setTaxStatus(){
        let typeOfContact = await this.props.invoice.contact.typeOfSupplier.toLocaleLowerCase()
        let key = ['company','public_company','partnership','ordinary_partnership','foundation','association','joint_venture','supplier']
        this.setState(prevState => _.set(_.cloneDeep(prevState), "show_tax", _.findIndex(key, function (k) { return k === typeOfContact }) === -1 ? false : true));
    }

    queryLanguages(){
        this.setState({languages:this.props.userConfig.languages})
        i18n.changeLanguage(this.props.userConfig.languages)
    }

    getHistoryById(id){
        let index = this.state.list_history.findIndex(x => x.key === id);
        let group_transaction = _(this.state.list_history[index].historyList).groupBy('product_code').map((value,name)=> { 
            return({
                product_and_service_name : value[0].product_code === "S000" ? i18next.t("settingAll:Automatic Penalties fee") : value[0].product_and_service_name,
                count_transaction: value.length,
                sum_transaction: _.sumBy(value,'total')
            })
        }).value()
        return _.filter(group_transaction,function(transaction) { return transaction.sum_transaction !== 0; })
    }

    sortListFineS000(all_transaction){
        
        let transaction_not_S000_first = _.dropRight(all_transaction, all_transaction.length - _.findIndex(all_transaction,['node.productAndService.productCode','S000'])) // get first transaction
        let transaction_not_S000_last  =_.drop(all_transaction, (_.findLastIndex(all_transaction,['node.productAndService.productCode','S000'])+1)) // get last transaction
        let sort_S000_list = _.sortBy(_.filter(all_transaction,['node.productAndService.productCode','S000']),'node.find.edges[0].node.group.fineDate')

        let after_sort_list = [];
        after_sort_list.push(...transaction_not_S000_first)
        after_sort_list.push(...sort_S000_list)
        after_sort_list.push(...transaction_not_S000_last)

        
        return sort_S000_list.length > 0 ? after_sort_list : all_transaction;


    }

    // เตรียมข้อมูล
    async setData(){
        let max_remark = {length:800,line:8}; //จำนวนตัวอักษรมากสุดของหมายเหตุ
        let max_transaction = 10; //จำนวนรายการหนี้มากสุดต่อ 1 หน้ากระดาษ (รวมทั้งหนี้ใหม่และหนี้เก่าค้างชำระ)
        let itemPerPage = 0; //คำนวนจาก char description ว่าจะได้กี่ row ต่อหน้าแรก 
        let lengthPerPage = this.props.selfProject.keyProjectQr ? 14 : 16

        //แก้โครงการริชพาร์ค เตาปูน
        if(this.props.selfProject.id === "UHJvamVjdE5vZGU6MzU4"){
            max_transaction = 13
            itemPerPage =13
        }
        // for suchareekosum site 
        if(this.props.selfProject.id === "UHJvamVjdE5vZGU6NTkx"){
            max_transaction = 15
            itemPerPage =15

        }

        let allsort = _.map(this.props.invoice.transaction.edges, (val) =>  {
            return val.node.description.length
         }); 

         let descriptionMaxlength = _.sortBy(allsort,sort => sort).reverse().shift();

   
        let getLine = 75; //75 per one line on description

        let rowPerLine = descriptionMaxlength / getLine;

        rowPerLine = parseInt(rowPerLine);
        // console.log("rowPerLine",rowPerLine);
        // console.log("descriptionMaxlength",descriptionMaxlength);
        
        let isTwoLine = false;
        let isOneLine = false
        if(descriptionMaxlength <= 80 && rowPerLine === 0){ //น้อยกว่า 12 บรรทัด desc บรรทัดละ 1 ตัดเป็น 1 หน้า
        //   console.log("1111");
           itemPerPage = 16;
           isTwoLine = false
           isOneLine = true
        }else if(descriptionMaxlength > 82 && rowPerLine === 1){
        //    console.log("2222");
           itemPerPage = 10;
           isTwoLine = true
           isOneLine = false
        }else{
        //    console.log("3333");
           itemPerPage = 8; 
        }

        let new_remark = ""; //หมายเหตุใหม่กรณีเกินที่กำหนด สำหรับใบแจ้งหนี้เก่าก่อนที่จะมีการกำหนด
        let count_line_remark = 0; //จำนวนบรรทัดของหมายเหตุ (แบบเฉลี่ยสำหรับกรณีไม่ได้เว้นบรรทัดจะหารจากตัวอักษรแทน)
        let history_transaction = [];
        let page = [];

        let length_new_transaction = 0; //จำนวนรายการหนี้ใหม่
        length_new_transaction = this.props.invoice.transaction.edges.length;
        let length_history_transaction = 0; //จำนวนรายการหนี้ค้างชำระ

        let summary_line = 0; //จำนวนรายการรวมล่างสุดของตาราง
        //เช็คว่ามีรายการด้านล่างหรือไม่
        if (this.props.invoice.transaction.edges.length > 0) {
            //หัก ณ ที่จ่าย
            if (this.props.invoice.transaction.edges[0].node.whtRatePercent !== 0) {
                summary_line += 1;
            }
            //vat
            if (this.props.invoice.transaction.edges[0].node.vat !== null) {
                summary_line += 1;
            }
        }
        max_transaction -= summary_line; //กรณีมีส่วนลดหรือหัก ณ ที่จ่ายเพิ่มเข้ามา
        
        if (this.props.history) {
            await this.setState({list_history: JSON.parse(this.props.history)}) //แปลงข้อมูลค้างชำระเก็บใน state
            //historyShow === เลือกแสดงรายการค้างชำระ
            if(this.props.invoice.historyShow){
                length_history_transaction = this.getHistoryById(this.props.invoice.id).length
            }
        }

        //เช็คโปรเจคว่ามี QR Code หรือไม่
        if(this.props.selfProject.keyProjectQr){
            max_remark = {length:266,line:2};
            itemPerPage = 10; //สำหรับมี crossbill
        }

        //ตัดหมายเหตุไม่ให้เกินที่กำหนดทั้งตัวอักษรและบรรทัด
        if(this.props.invoice.remark.length > 0){
            //บรรทัด remark เกินที่กำหนด (กรณีพิมพ์แบบกดเว้นบรรทัด)
            if(this.props.invoice.remark.split(/\r\n|\r|\n/).length > max_remark.line){
                this.props.invoice.remark.split(/\r\n|\r|\n/).forEach((remark,index)=> {
                    if(index+1 <= max_remark.line){
                        new_remark += remark + "\n";
                    }
                })
                count_line_remark = max_remark.line
            }else if(this.props.invoice.remark.length > max_remark.length){
                //จำนวนตัวอักษร remark เกินที่กำหนด
                new_remark = this.props.invoice.remark.slice(0,max_remark.length);
                count_line_remark = max_remark.line;
                if(this.props.invoice.remark.split(/\r\n|\r|\n/).length > 0){
                    //count_line_remark ใช้สำหรับวัดบรรทัดที่เหลือสำหรับใบแจ้งหนี้ปกติ เช่นถ้าไม่มีหมายเหตุ รายการที่มากสุดจะเพิ่มมาได้
                    count_line_remark = this.props.invoice.remark.split(/\r\n|\r|\n/).length;
                    this.props.invoice.remark.split(/\r\n|\r|\n/).forEach((remark,index)=> {
                        if(remark.length > 133){
                            count_line_remark += Math.round(remark.length/133);
                        }
                    })
                }else{
                    count_line_remark = Math.round(this.props.invoice.remark.length/133);
                }


            }else{
                new_remark = this.props.invoice.remark;
                if(!this.props.selfProject.keyProjectQr){
                    if(this.props.invoice.remark.split(/\r\n|\r|\n/).length > 0){
                        //count_line_remark ใช้สำหรับวัดบรรทัดที่เหลือสำหรับใบแจ้งหนี้ปกติ เช่นถ้าไม่มีหมายเหตุ รายการที่มากสุดจะเพิ่มมาได้
                        count_line_remark = this.props.invoice.remark.split(/\r\n|\r|\n/).length;
                        this.props.invoice.remark.split(/\r\n|\r|\n/).forEach((remark,index)=> {
                            if(remark.length > 133){
                                count_line_remark += Math.round(remark.length/133);
                            }
                        })
                    }else{
                        count_line_remark = Math.round(this.props.invoice.remark.length/133);
                    }
                }else{
                    count_line_remark = max_remark.line
                }
            }
        }else{
            new_remark = this.props.invoice.remark;
            count_line_remark = 0;
        }

        if(!this.props.selfProject.keyProjectQr || !this.props.selfProject.prompayQrShow){
            // if((max_remark.line - (count_line_remark*2)) > 0){
            //     max_transaction =+ (max_remark.line - (count_line_remark*2))

            //    // console.log("max_transaction",max_transaction);
            // }
            max_transaction = 10
        }

        this.setState({
            remark : new_remark
        })


        let transaction_invoice = this.sortListFineS000(this.props.invoice.transaction.edges)
        //ตัดรายการหนี้เก่าและหนี้ใหม่แบ่งตามหน้า
        //เช็คหนี้ใหม่เกินที่กำหนดรึเปล่า มีQR 7 / ไม่มี QR 10 (+-3)

        max_transaction = itemPerPage;

        // console.log("max_transaction",max_transaction);
        // console.log("length_new_transaction",length_new_transaction);

        if(length_new_transaction >= max_transaction){
           // console.log("มีสองหน้า");
            
            //เกินกำหนด ต้องตัดรายการหนี้ใหม่ให้พอดีหน้า
            let invoice_transaction = _.chunk(transaction_invoice,lengthPerPage) //ตัดรายการหนี้ใหม่ 
            let first_page_history = 0 //จำนวนรายการหนี้เก่าที่สามารถเพิ่มเข้าไปต่อหน้าเดิมได้
            let index_page = 1;
     
            if(_.last(invoice_transaction).length <= lengthPerPage){
                //รายการหนี้ใหม่หน้าสุดท้ายไม่เต็ม 16 และมีรายการหนี้เก่า
                if(this.props.invoice.historyShow){
                    first_page_history = 32 - (_.last(invoice_transaction).length * 2); //จำนวนรายการที่สามารถเอาหนี้เก่าไปต่อได้
                    history_transaction.push(_.take(this.getHistoryById(this.props.invoice.id),first_page_history)); //รายการที่นำไปต่อหน้าแรก
                    _.chunk((_.drop(this.getHistoryById(this.props.invoice.id),first_page_history)),32).forEach((history)=> {
                        history_transaction.push(history); //รายการถัดไป
                    })
                }
            }else {
                //  รายการหนี้ใหม่หน้าสุดท้ายเต็ม 16 และมีรายการหนี้เก่า หนี้เก่าจะต้องขึ้นหน้าใหม่
                if(this.props.invoice.historyShow){
                    _.chunk(this.getHistoryById(this.props.invoice.id),32).forEach((history)=> {
                        history_transaction.push(history); //รายการถัดไป
                    })
                }
            }

            //จัดข้อมูลแบ่งตามหน้ากระดาษ
            invoice_transaction.forEach((invoice,index_invoice) => {
                //หน้าสุดท้ายของหนี้ใหม่ จะทำการแบ่งงหนี้เก่าต่อเลย
                if(index_invoice +1 === invoice_transaction.length){
                    //หน้าสุดท้าย >= 16 ? -> Yes -> หนี้เก่าค้างชำระขึ้นหน้าใหม่ // -> No -> หนี้เก่าเขียนต่อ
                    if(invoice.length <= 16){
                        //กรณีมีหนี้เก่า
                        if(this.props.invoice.historyShow){
                            history_transaction.forEach((history,index_history)=> {
                                if(index_history === 0){
                                    //กรณีเป็นหน้าต่อหนี้ใหม่หน้าแรกและหน้าสุดท้าย
                                    if(index_history+1 === history_transaction.length){
                                        //เช็คว่าหน้าสุดท้ายรายการพอดีกับหน้า? ที่ใช้ max_transaction*2 เพราะถ้าเป็นหนี้เก่ามีบรรทัดเดียวจำนวนรายการจึงได้มากเป็นสองเท่า
                                        // check for isOneLine
                                        // console.log("isOneLine",isOneLine);
                                        if((invoice.length + history.length) <= (max_transaction*2) && isOneLine){
                                            page.push({invoice_transaction : invoice, history_transaction : history, index_page, isNextHistory : false}) 
                                            index_page += 1;

                                        }else{
                                            page.push({invoice_transaction : invoice, history_transaction : history, index_page, isNextHistory : false}) // isNextHistory true = เป็นหน้าต่อของหนี้ค้างชำระ
                                            index_page += 1;
                                            //หน้าใหม่สำหรับ footer
                                            page.push({invoice_transaction : [], history_transaction : [], index_page, isNextHistory : false}) // showSummary : true = หน้านี้จะแสดงในส่วนของราคารวม หัก ลด
                                            index_page += 1; 
                                        }
                                    }else{
                                        page.push({invoice_transaction : invoice, history_transaction : history, index_page, isNextHistory : false})
                                        index_page += 1;
                                    }
                                }else if(index_history+1 === history_transaction.length){
                                    //เช็คหน้าสุดท้าย กรณีมีแต่ history
                                    if(history.length <= (max_transaction*2)){
                                        page.push({invoice_transaction : [], history_transaction : history, index_page, isNextHistory : true}) 
                                        index_page += 1;
                                    }else{
                                        page.push({invoice_transaction : [], history_transaction : history, index_page, isNextHistory : true})
                                        index_page += 1;
                                        page.push({invoice_transaction : [], history_transaction : [], index_page, isNextHistory : false})
                                        index_page += 1; 
                                    }
                                }else{
                                    page.push({invoice_transaction : [], history_transaction : history , index_page , isNextHistory : true})
                                    index_page += 1;
                                }
                            })
                        }else{
                            //แบบไม่มีหนี้เก่า
                            // if((invoice.length *2) <= (max_transaction*2)){
                            if((invoice.length) <= (max_transaction*2) && isOneLine){
                                page.push({invoice_transaction : invoice , history_transaction : [], index_page , isNextHistory : false});
                                    index_page += 1;
                            }else{
                                page.push({invoice_transaction : invoice , history_transaction : [], index_page , isNextHistory : false});
                                index_page += 1;
                                page.push({invoice_transaction : [] , history_transaction : [], index_page , isNextHistory : false});
                                index_page += 1;
                            }
                            
                        }
                    }else{
                        //พอดี 16 แต่มีมีหนี้เก่าต่อ
                        if(this.props.invoice.historyShow){
                            //ขึ้นหน้าใหม่เพราะหนี้ใหม่ === 16
                            page.push({invoice_transaction : invoice, history_transaction : [], index_page, isNextHistory : false})
                            index_page += 1;
                            history_transaction.forEach((history,index_history)=> {
                                if(index_history === 0){
                                    page.push({invoice_transaction : [], history_transaction : history, index_page, isNextHistory : false})
                                    index_page += 1;
                                }else if(index_history + 1 === history_transaction.length){
                                    if(history.length <= (max_transaction*2)){
                                        page.push({invoice_transaction : [] , history_transaction : history, index_page , isNextHistory : true});
                                        index_page += 1;
                                    }else{
                                        page.push({invoice_transaction : [] , history_transaction : history, index_page , isNextHistory : true});
                                        index_page += 1;
                                        page.push({invoice_transaction : [] , history_transaction : [], index_page , isNextHistory : false});
                                        index_page += 1;
                                    }
                                }
                                else{
                                    page.push({invoice_transaction : [], history_transaction : history , index_page , isNextHistory : true})
                                    index_page += 1;
                                }
                            })
                        }else{
                            //ไม่มีหนี้เก่า
                            if((invoice.length * 2) <= (max_transaction*2)){
                                page.push({invoice_transaction : invoice, history_transaction : [], index_page, isNextHistory : false})
                                index_page += 1;
                            }else{
                                page.push({invoice_transaction : invoice, history_transaction : [], index_page, isNextHistory : false})
                                    index_page += 1;
                                    page.push({invoice_transaction : [], history_transaction : [], index_page, isNextHistory : false})
                                    index_page += 1;
                            }
                        }

                    }
                }else{
                    page.push({invoice_transaction : invoice, history_transaction : [], index_page , isNextHistory : false})
                    index_page += 1;
                }
            })
            
        }else{
            // console.log("not over");
            //หนี้ใหม่ไม่เกินกำหนด มาเช๋็คต่อว่าเมื่อรวมกับหนี้เก่าแล้วเกินกำหนดหรือไม่
            //เช็คแล้วสองอันรวมกันเกินกำหนด -> เกิน1แผ่น -> ตัดรายการหนี้เก่าให้พอดีกับหน้า

            let checkNextwithKeyProjectQr = false
            let sumOverall = length_new_transaction + length_history_transaction

            if(this.props.selfProject.keyProjectQr && sumOverall >= 20 ){
                checkNextwithKeyProjectQr = true
            }

            if((length_new_transaction + length_history_transaction) > max_transaction){
                let first_page_history = 0
                //ตัดรายการหนี้เก่าให้พอดีกับหน้า
                if(this.props.invoice.historyShow){
                    first_page_history = 32 - (length_new_transaction*2);

                    history_transaction.push(_.take(this.getHistoryById(this.props.invoice.id),checkNextwithKeyProjectQr ? 10 : first_page_history));
                   
                    _.chunk((_.drop(this.getHistoryById(this.props.invoice.id),checkNextwithKeyProjectQr ? 10 : first_page_history)),32).forEach((history)=> {
                        history_transaction.push(history);
                    })
                }

                if(this.props.invoice.historyShow){
                    let index_page = 1;

                    history_transaction.forEach((history,index_history)=> {
                        if(index_history === 0 ){
                            //หน้าสุดท้าย หนี้ใหม่ + หนี้เก่าไม่เกิน 32
                            if(index_history + 1 === history_transaction.length){

                                if((this.props.invoice.transaction.edges.length *2) + history.length <= (max_transaction*2)){
                                    page.push({invoice_transaction : transaction_invoice , history_transaction : history, index_page , isNextHistory : false});
                                    index_page += 1;
                                }
                                else{
                                    page.push({invoice_transaction : transaction_invoice , history_transaction : history, index_page , isNextHistory : false});
                                    index_page += 1;
                                    page.push({invoice_transaction : [] , history_transaction : [], index_page , isNextHistory : false});
                                    index_page += 1;
                                }
                            }else{
                                page.push({invoice_transaction : transaction_invoice , history_transaction : history, index_page , isNextHistory : false});
                                index_page += 1;
                            }
                        }else{
                            //เช็คจำนวนรายการหน้าสุดท้ายว่ามีเนื้อที่พอสำหรับยอดรวมหรือไม่และดูว่าสามารถใส่ส่วน footer พอหรือไม่
                            if(index_history + 1 === history_transaction.length){
                                if(history.length <= (max_transaction*2)){
                                    //รายการพอที่จะแสดง footer
                                    page.push({invoice_transaction : [] , history_transaction : history, index_page , isNextHistory : true});
                                    index_page += 1;
                                }else{
                                    page.push({invoice_transaction : [] , history_transaction : history, index_page , isNextHistory : true});
                                    index_page += 1;
                                    page.push({invoice_transaction : [] , history_transaction : [], index_page , isNextHistory : false });
                                    index_page += 1;
                                }
                            }else{
                                page.push({invoice_transaction : [] , history_transaction : history, index_page , isNextHistory : true});
                                index_page += 1;
                            }
                            
                        }
                    })
                }
                

            }else{
                //หนี้ใหม่ไม่เกินกำหนดและเมื่อรวมหนี้เก่าก็ไม่เกินกำหนด -> แสดง 1 แผ่นได้
                if(this.props.invoice.historyShow){
                    history_transaction = this.getHistoryById(this.props.invoice.id);
                }
                page.push({invoice_transaction : transaction_invoice , history_transaction : history_transaction, index_page : 1 , isNextHistory : false})
            }
        }

        this.setState({page_invoice:page})
    }

    removeTrailingZeros(description){
        if(description.includes("ค่าน้ำประปา")){
            description = description.replace(/ *\[[^)]*\] */g, " ")
            // description = description.replace(/ *\[[^)]*\] */g, " ").replace(/.50 /gi, ".5 ")
            // description = description.replace(/.00 /gi, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.check_type !== this.props.check_type) {
            if (this.props.check_type === 'all') {
                this.setState({list_map: [1, 2]})
            } else if (this.props.check_type === 'manuscript') {
                this.setState({list_map: [1]})
            } else {
                this.setState({list_map: [2]})
            }
            this.setState({check_type: this.props.check_type});
        }
    }

    setPage() {
        setTimeout(() => {
            let page = $('.' + this.props.invoiceNumber);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + signature) - 50;
            if(this.state.over_page){
                $('.' + this.props.invoiceNumber + ' .lastRow td').css({paddingBottom: this.props.selfProject.keyProjectQr || this.props.selfProject.prompayQrShow ? 10 :(diff/2) });
            }else{
                $('.' + this.props.invoiceNumber + ' .lastRow td').css({paddingBottom: diff});
            }
            this.setState({diff: diff});
        }, 200);
    }

    allStatus() {
        if (this.props.invoice.transaction.edges.length > 0) {
            //หัก ณ ที่จ่าย
            if (this.props.invoice.transaction.edges[0].node.whtRatePercent !== 0) {
                this.setState({
                    have_wht_rate_percent: true,
                    no_wht_rate_percent: false
                })
            } else if (this.props.invoice.transaction.edges[0].node.whtRatePercent === 0) {
                this.setState({
                    no_wht_rate_percent: true,
                    have_wht_rate_percent: false
                })
            }
            //หัก ณ ที่จ่าย

            //vat
            if (this.props.invoice.transaction.edges[0].node.vat !== null) {
                this.setState({
                    have_vat: true,
                    no_vat: false
                })
            } else if (this.props.invoice.transaction.edges[0].node.vat === null) {
                this.setState({
                    no_vat: true,
                    have_vat: false
                })
            }
            //vat
            this.props.invoice.transaction.edges.forEach((value)=>{
                if (value.node.discount === null) {
                    if (this.state.discount_status !== true) {
                        this.setState({discount_status: false})
                    }
                }
                else if (value.node.discount !== 0) {
                    this.setState({discount_status: true})
                } else {
                    if (this.state.discount_status !== true) {
                        this.setState({discount_status: false})
                    }
                }
            })
            //discount
        }
    }

    calSummary(){
        let amount = 0; //จน.ของ * ราคา
        let sumAmount = 0; // amountทั้งหมด
        let discount = 0; //ส่วนลด
        let sumDiscount = 0; //ส่วนลดทั้งหมด
        let sumWhtRate = 0; //หัก ณ ที่จ่ายทั้งหมด
        let receiveDeposit = 0; //รับล่วงหน้า
        let sumReceiveDeposit = 0; //รวมหลังหักรับล่วงหน้า
        let sumVat = 0;//VAT ทั้งหมด
        let sumTotal = 0;// รวมหลังหักรับล่วงหน้า + VAT ทั้งหมด
        let sumGrandTotal = 0;//ยอดรวมสุทธิ

        this.props.invoice.transaction.edges.forEach((transactions) => {
            //amount = parseFloat(transactions.node.unitItems) * parseFloat(transactions.node.price);
            // amount = parseFloat(transactions.node.price * transactions.node.unitItems)
            amount = parseFloat(transactions.node.total)
            sumAmount += parseFloat(amount);
            discount += parseFloat(transactions.node.discount || 0);
            // sumDiscount += amount - parseFloat(transactions.node.discount || 0);
            sumDiscount += amount

            transactions.node.receiptDepositTransactionUsed.edges.forEach((rd_transaction) => {
                if(rd_transaction.node.receiptDepositTransaction.couple !== "NO" && rd_transaction.node.coupleUse !== "NO")
                    receiveDeposit += rd_transaction.node.amount;
            });
            //คำนวนรับล่วงหน้า
            if (this.props.invoice.taxType === "INCLUDE_TAX") {
                sumReceiveDeposit = (((parseFloat(sumDiscount) - receiveDeposit) * 100) / (100 + transactions.node.vat));
            } else {
                sumReceiveDeposit = parseFloat(sumDiscount) - receiveDeposit;
            }
            //คำนวนvat
            sumVat = parseFloat(sumReceiveDeposit) * (transactions.node.vat / 100);

            //คำนวนหัก ณ ที่จ่าย
            if(transactions.node.whtRatePercent !== 0){
                sumWhtRate += (amount - parseFloat(transactions.node.discount || 0)) * (transactions.node.whtRatePercent / 100);
            }

            //คำนวนจำนวนเงินรวม
            sumTotal = sumReceiveDeposit + sumVat;
            //คำนวนยอดรวมทั้งสิ้น
            sumGrandTotal = sumReceiveDeposit + sumVat - sumWhtRate;

            
        });
        
        sumAmount = parseFloat(sumAmount) + parseFloat(discount)

        this.setState({
            sum_wht_rate: sumWhtRate.toFixed(2),
            sum_vat: sumVat.toFixed(2),
            sum_grand_total: sumGrandTotal.toFixed(2),
            sum_amount: sumAmount.toFixed(2),
            sum_total: sumTotal.toFixed(2),
            sum_receive_deposit: sumReceiveDeposit.toFixed(2),
            receiveDeposit: receiveDeposit.toFixed(2),
            sum_discount: sumDiscount.toFixed(2),
            discount: discount.toFixed(2),
            total_pay_qr: sumGrandTotal*100,
        });


    }

    isBangkok(code) {
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310 ];
        if(_.indexOf(bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }
    getPrefix(type, code) {
        // let languages = this.state.languages
        let languages = i18n.languages[0];

        if(type === "district" && this.isBangkok(code)){
            return languages == "th" ? "แขวง" : "District "
        }
        else if(type === "city" && this.isBangkok(code)){
            return languages == "th" ? "เขต" : "County "
        }
        else if(type === "province" && this.isBangkok(code)){
            return languages == "th" ? "" : ""
        }
        else if(type === "province") {
            return languages == "th" ? "จ." : "Province "
        }
        else if(type === "district") {
            return languages == "th" ? "ต." : "District "
        }
        else if(type === "city"){
            return languages == "th" ? "อ." : "County "
        }
    }

    checkCountHistory(id, type) {
        let index = this.state.list_history.findIndex(x => x.key === id);
        if (type === 'count') {
            let count = _.filter(_(this.state.list_history[index].historyList).groupBy('docNumber').map((value,name)=> {
                return({
                    sum_transaction: _.sumBy(value,'total')
                })
            }).value(),function(transaction) { return transaction.sum_transaction !== 0;})
            // return this.state.list_history[index].count
            return count.length;
        } else {
            return this.state.list_history[index].summary
        }
    }

    sumInvoice(outstanding,amount){
        return parseFloat(outstanding) + parseFloat(amount) - parseFloat(this.state.receiveDeposit)
    }

    async addRowword(index,count_invoice,dict){
        if(dicts.length === 0){
            dicts.push(dict)
        }else{
            let result = await _.filter(dicts, (res) => res._id === ('.' + this.props.invoiceNumber +'-'+ index+'-'+count_invoice))
            
            await result.length === 0 && dicts.push(dict)
            await result.length !== 0 && $('.' + this.props.invoiceNumber+'-'+index +'-'+ count_invoice + ' .lastRow td.lasttd').css({paddingBottom: result[0].diff})

        }
    }

    //ค่าว่างที่เหลืออยู่ในหน้ากระดาษ ->  เพิ่มให้เต็ม
    getDiff(index,count_invoice){
        setTimeout(() => {
            let page = $('.' + this.props.invoiceNumber +'-'+ index+'-'+count_invoice);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            var dict = {}
            if(head !== undefined && detail !== undefined && invoice !== undefined && signature !== undefined){
                let diff = page_height - (head + detail + invoice + signature) - 50;
                dict["_id"] = ('.' + this.props.invoiceNumber +'-'+ index+'-'+count_invoice);
                dict["diff"] = diff;
                this.addRowword(index,count_invoice,dict)

                _.filter(dict, (res) => 
                    res._id === ('.' + this.props.invoiceNumber +'-'+ index+'-'+count_invoice) && $('.' + this.props.invoiceNumber+'-'+index +'-'+ count_invoice + ' .lastRow td.lasttd')
                    .css({paddingBottom: res.diff})
                )
            }
        }, 200);
    }

    nextLine(address) {
        return address = address.replace('แขวง', '\nแขวง')
    }

    getPlusTopupCrossBill(sum_grand_total,topupCrossbill){
      return parseFloat(sum_grand_total) + parseFloat(topupCrossbill)
    }

    convertTextToNumberEng(number) {
        let parts = ""
        if (typeof number === "number") {
            parts = number.toFixed(2).toString().split('.');
        } else if (typeof number === "string"){
            parts = number.split('.');
        }    
        const integerPart = parseInt(parts[0]);
        const decimalPart = parts[1] || '0';
    
        const integerText = numberToText.convertToText(integerPart);
        let resultText = integerText.charAt(0).toUpperCase() + integerText.slice(1);
    
        if (decimalPart !== '0' && parseInt(decimalPart) !== 0) {
            let decimalText = ' point';
            for (let digit of decimalPart) {
                decimalText += ` ${numberToText.convertToText(parseInt(digit))}`;
            }
            resultText += decimalText;
        }
    
        return resultText;
    }

    render() {

        let getType = getTypeProject(this.props.selfProject.typeOfProject)

        return (
            <React.Fragment key={this.props.invoice.id}>
                {
                    this.state.list_map.map((count_invoice, index1) => {

                        let total_page = this.state.page_invoice.length; //จำนวนหน้าทั้งหมด
                        let order_transaction = 0; //ลำดับ No.

                        let split_address = []
                        split_address = this.props.invoice.address.split('หมู่ที่')
                        let village = split_address.length > 0 ? 'หมู่ที่ ' + split_address[split_address.length-1] : ''
                        

                        return (
                            this.state.page_invoice.map((page,index) => {
                                this.getDiff(index,count_invoice);
                                return (
                                    <React.Fragment key={this.props.invoice.id+'-'+index1+'-'+index} >
                                        <div className={"print-page-a4 "+ this.props.invoiceNumber+'-'+index+'-'+count_invoice} key={index+"-index-invoice"} id="invoiceTemplete4">
                                        <div className="subpage" style={{ overflow: 'hidden' }}>
                                                <div className="head">
                                                    <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                                                    <div style={{minHeight:100,minWidth:100}}> 
                                                        <img src={this.props.selfProject.logo} alt="silverman"/>
                                                    </div>
                                                    <div>  
                                                        <div style={{minWidth:'160%'}}>
                                                        <strong>{this.state.languages === "en" ? (this.props.selfProject.nameEn || this.props.selfProject.name) : this.props.selfProject.name}</strong>
                                                        </div>
                                                         
                                                        {
                                                            (this.props.selfProject.id === "UHJvamVjdE5vZGU6NDE2" || this.props.selfProject.id === 'UHJvamVjdE5vZGU6MzQz' || this.props.selfProject.id === 'UHJvamVjdE5vZGU6NjIy')? 
                                                            <div>
                                                                <Translation>{t=><div className="address-header">{this.nextLine(this.state.languages === "en" ?  (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address)} </div>}</Translation>
                                                                <Translation>{t=><div className="address-header">{t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                                            </div>
                                                            :
                                                            <Translation>{t=><div className="address-header">{this.state.languages === "en" ?  (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address} {t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                                        }
                                                        <Translation>{t=><div>{t("document:Taxpayer identification number")} {this.props.selfProject.taxIdNumber || '-'}</div>}</Translation>
                                                    </div> 
                                                    </div>
            
                                                    <div className="document-name">
                                                            <Translation>
                                                                {t =><div>
                                                                    {t("document:Invoice") === "Invoice" ? <div className="title-invoice">Invoice</div> 
                                                                    :   <>
                                                                        <div className="title-invoice">{this.props.langTitleTH ? this.props.langTitleTH : 'ใบแจ้งหนี้'}</div>
                                                                        <div className="title-invoice">{this.props.langTitleEN ? this.props.langTitleEN : 'Invoice'}</div>
                                                                    </>
                                                                    }
                                                                </div>
                                                                } 
                                                            </Translation>
                                                        {
                                                            count_invoice === 1 ?
                                                                <Translation>{t=><div className="title small">({t("document:Original")})</div>}</Translation>
                                                                :
                                                                <Translation>{t=><div className="title small">({t("document:Copy")})</div>}</Translation>
                                                        }
                                                    </div>
                                                </div>
            
                                                <div className="detail">
                                                    <div className="customer-detail">
                                                        <Translation>{t=><strong>{t("document:ID")}:</strong>}</Translation> {this.props.invoice.contact.refNumber} <br/>
                                                        {this.props.invoice.contact.typeOfContact === 'RESIDENTIAL' ?
                                                            // this.props.invoice.payGroup === 'RENT' ? 
                                                            // <React.Fragment>
                                                            //     <Translation>{t=><strong>{t("document:Customer")}:</strong>}</Translation>
                                                            //     {
                                                            //         getNameResidentialRent(this.props.invoice.contact.residential.residentialHouseholder?.edges) + "(ผู้เช่า)"
                                                            //     }
                                                            //     <br/>
                                                            // </React.Fragment>
                                                            // :
                                                            <React.Fragment>
                                                                <Translation>{t=><strong>{t("document:Customer")}:</strong>}</Translation>
                                                                {
                                                                    this.props.query?.firstName ? getNameResidential(this.props.query?.firstName, this.props.query?.lastName):
                                                                    getNameResidential(this.props.invoice.firstName, this.props.invoice.lastName)
                                                                }
                                                                <br/>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <Translation>{t=><strong>{t("document:Customer")}:</strong>}</Translation> {this.props.invoice.name} <br/>
                                                            </React.Fragment>
                                                        }

                                                        <Translation>{t=><strong>{t("document:Address")}:</strong>}</Translation>
                                                        <div className="customer-detail address-space">
                                                            {/* UHJvamVjdE5vZGU6Mjk3 censirihome ตัดหมู่ที่อีกบรรทัด B274 */}
                                                            {this.props.selfProject.id === "UHJvamVjdE5vZGU6Mjk3" ?
                                                                split_address.map((address,index) => {
                                                                    if(index+1 !== split_address.length){
                                                                        return <React.Fragment key={index}>{address + " "}</React.Fragment>
                                                                    }
                                                                })
                                                                :
                                                                this.props.query?.address ? 
                                                                this.state.languages === "en" ?
                                                                this.props.query?.address.replace('เลขที่', 'No.').replace('ซอย', 'Soi').replace('หมู่ที่', 'Moo').replace('ถนน', 'Road') + " ":
                                                                this.props.query?.address + " " :
                                                                this.state.languages === "en" ?
                                                                this.props.invoice?.address.replace('เลขที่', 'No.').replace('ซอย', 'Soi').replace('หมู่ที่', 'Moo').replace('ถนน', 'Road') + " ":
                                                                this.props.invoice.address + " "
                                                            }
                                                            {/* {this.props.invoice.address + " "} */}
                                                            {this.props.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ?
                                                            <React.Fragment>
                                                                {this.props.invoice.district && this.getPrefix("district", this.props.invoice.postalCode)+this.props.invoice.district + " "}<br/>
                                                            </React.Fragment>:
                                                                this.props.selfProject.id === 'UHJvamVjdE5vZGU6MzQz' ?
                                                                <React.Fragment>
                                                                    {this.props.invoice.district && <React.Fragment><br/>{this.getPrefix("district", this.props.invoice.postalCode)+this.props.invoice.district + " "}</React.Fragment>}
                                                                </React.Fragment>
                                                                :
                                                                <React.Fragment>
                                                                    {(this.props.selfProject.id === "UHJvamVjdE5vZGU6Mjk3") && <React.Fragment><br/>{village+" "}</React.Fragment>}

                                                                    {
                                                                     this.props.query?.district  ? this.props.query?.district && this.getPrefix("district", this.props.query?.postalCode)+this.props.query?.district + " " : 
                                                                     this.props.invoice.district && this.getPrefix("district", this.props.invoice.postalCode)+this.props.invoice.district + " "
                                                                    }
                                                                </React.Fragment>
                                                            }

                                                            {
                                                                this.props.query?.city ?  this.props.query?.city && this.getPrefix("city", this.props.query?.postalCode)+this.props.query?.city  + " " :
                                                                this.props.invoice.city && this.getPrefix("city", this.props.invoice.postalCode)+this.props.invoice.city + " "
                                                            }
                                                            {
                                                                this.props.query?.province ?  this.props.query?.province && this.getPrefix("province", this.props.query?.postalCode)+this.props.query?.province + " " :
                                                                this.props.invoice.province && this.getPrefix("province", this.props.invoice.postalCode)+this.props.invoice.province + " "
                                                            }
                                                            {
                                                                this.props.query?.postalCode ? this.props.query?.postalCode : this.props.invoice.postalCode + " "
                                                            }
                                                            {
                                                                (this.props.invoice.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.invoice.contact.typeOfSupplier === "COMPANY") &&
                                                                    <Translation>
                                                                        {t=>
                                                                        <React.Fragment>
                                                                            {
                                                                                this.props.invoice.contact.typeOfCompany === "HEADQUARTERS" ?
                                                                                    t("document:Head office")
                                                                                    : this.props.invoice.contact.typeOfCompany === "BRANCH" &&
                                                                                    `${t("document:Branch")} ` + this.props.invoice.contact.nameBranch
                                                                            }
                                                                        </React.Fragment>}
                                                                    </Translation>
                                                            }
                                                        </div>
                                                        <br/>
                                                        {((this.props.invoice.contact.typeOfContact !== 'RESIDENTIAL')) &&
                                                            <React.Fragment>
                                                                <Translation>{t=><strong>{t("document:Tax ID")}:</strong>}</Translation> 
                                                                {
                                                                     this.props.query?.taxIdNumber ? this.props.query?.taxIdNumber :this.props.invoice.taxIdNumber
                                                                } <br/>
                                                                <Translation>{t=><strong>{t("document:Attention")}:</strong>}</Translation> {this.props.invoice.firstName} {this.props.invoice.lastName}
                                                                &emsp;{this.props.invoice.phone && "T: "+this.props.invoice.phone}&emsp;{this.props.invoice.email && "E: "+this.props.invoice.email}
                                                            </React.Fragment>
                                                        }
            
                                                        {
                                                            this.props.query?.firstName ?  
                                                            this.props.query?.firstName && (this.props.query?.firstName.substring(0, 6) === "บริษัท" || this.state.show_tax) && this.props.invoice.contact.typeOfContact === 'RESIDENTIAL' &&
                                                                <React.Fragment>
                                                                    <Translation>{t=><strong>{t("document:Tax ID")}:</strong>}</Translation> {this.props.query?.taxIdNumber} <br/>
                                                                </React.Fragment> 
                                                                
                                                           :

                                                            this.props.invoice.firstName && (this.props.invoice.firstName.substring(0, 6) === "บริษัท" || this.state.show_tax) && this.props.invoice.contact.typeOfContact === 'RESIDENTIAL' &&
                                                                <React.Fragment>
                                                                    <Translation>{t=><strong>{t("document:Tax ID")}:</strong>}</Translation> {this.props.invoice.taxIdNumber} <br/>
                                                                </React.Fragment>
                                                        }

                                                    </div>
                                                    <div className="document-detail">
                                                        <Translation>{t=><strong className="sub-document-detail">{t("document:No.")}:</strong>}</Translation> {this.props.invoice.docNumber} <br/>
                                                        <Translation>{t=><strong className="sub-document-detail">{t("document:Date")}:</strong>}</Translation> {format(this.props.invoice.issuedDate, 'DD/MM/YYYY', {locale: thLocale})} <br/>
                                                        <Translation>{t=><strong className="sub-document-detail">{t("document:Due Date")}:</strong>}</Translation> {format(this.props.invoice.dueDate, 'DD/MM/YYYY', {locale: thLocale})} <br/>
                                                        {this.props.invoice.contact.typeOfContact === 'RESIDENTIAL' &&
                                                            <React.Fragment>
                                                                <Translation>{t=><strong className="sub-document-detail">{getType === "highType" ? `${t("document:Room No.")}:` : `${t("document:House No.")}:`}</strong>}</Translation> {this.props.invoice.contact.name} &nbsp;<br/>
                                                                <Translation>{t=><><strong className="sub-document-detail">{t("document:Ratio/Space")}:</strong> {numberWithComma(this.props.invoice.contact.residential.ownershipRatio)}/{numberWithComma(this.props.invoice.contact.residential.size)}</>}</Translation> <br/>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="invoice-body">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                        <Translation>
                                                        {t=>
                                                        <tr>
                                                            <th 
                                                                width={50} 
                                                                className="text-center" 
                                                                dangerouslySetInnerHTML={{__html: t("document:No._br")}}/>
                                                            <th 
                                                                width={50} 
                                                                className="text-center" 
                                                                dangerouslySetInnerHTML={{__html: t("document:Code_br")}}/>
                                                            <th 
                                                                className="text-center" 
                                                                colSpan={2} 
                                                                dangerouslySetInnerHTML={{__html: t("document:Description_br")}}/>
                                                            <th 
                                                                width={90} 
                                                                className="text-center" 
                                                                dangerouslySetInnerHTML={{__html: t("document:Unit_br")}}/>
                                                            <th 
                                                                width={90} 
                                                                className="text-center" 
                                                                dangerouslySetInnerHTML={{__html: t("document:Unit Price_br")}}/>
                                                            <th 
                                                                width={90} 
                                                                className="text-center" 
                                                                dangerouslySetInnerHTML={{__html: t("document:Amount_br")}}/>
                                                        </tr>}
                                                        </Translation>
                                                        </thead>
                                                        <tbody className="current">
                                                        {page.invoice_transaction.length > 0 &&
                                                            page.invoice_transaction.map((transaction, index_transaction) => {
                                                                order_transaction += 1;
                                                                let text = this.removeTrailingZeros(transaction.node.description).slice(0,130)

                                                                if(transaction.node.productAndService.productCode === "S000" &&  i18n.languages[0] === 'en') {                                                                    
                                                                    text = text.replace("ค่าปรับ", "Penalties");
                                                                    text = text.replace("เงินเพิ่ม", "Surcharges");
                                                                    text = text.replace("ประจำเดือน", "Monthly");                                                                
                                                                }

                                                                if(transaction.node.keycardPerInvoice.edges.length > 0){
                                                                    text += "("
                                                                    text += i18n.t("invoiceDetail:No.Number")
                                                                    text += " "
                                                                    text += transaction.node.keycardPerInvoice.edges[0].node.numberKey.noNumber
                                                                    text += ")"
                                                                }
                                                                return (
                                                                    <React.Fragment key={this.props.invoice.id+'-'+index1+'-'+index+'-'+index_transaction}>
                                                                        <tr>
                                                                            <td className="text-center">{order_transaction}</td>
                                                                            <td className="text-center">{transaction.node.productAndService ? transaction.node.productAndService.productCode :  transaction.node.chartOfAccount.chartOfAccountCode}</td>
                                                                            <td colSpan={2}>
                                                                                {text}
                                                                            </td>
                                                                            <td className="text-right">{numberWithComma(transaction.node.unitItems)}</td>
                                                                            <td className="text-right">{numberWithComma(transaction.node.price)}</td>
                                                                            <td className="text-right">
                                                                                {/* {numberWithComma(transaction.node.price * transaction.node.unitItems)} */}
                                                                                {numberWithComma(transaction.node.total + transaction.node.discount)}
                                                                                {/* {numberWithComma(transaction.node.price)} */}
                                                                            </td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ) 
                                                                }
                                                            )}
                                                            {/* className={this.state.page_invoice.length !== 1 ? "" : "lastRow"} */}
            
                                                            {(page.history_transaction.length >= 0 && page.invoice_transaction.length > 0) &&
                                                            <tr className="lastRow">
                                                                <td className="lasttd"/>
                                                                <td className="lasttd"/>
                                                                <td colSpan={2} className="lasttd"/>
                                                                <td className="lasttd"/>
                                                                <td className="lasttd"/>
                                                                <td className="lasttd"/>
                                                            </tr>
                                                            }

                                                             <React.Fragment>
                                                                    <tr>
                                                                        <td/>
                                                                        <td/>
                                                                        <td style={{borderRight:'none'}}>
                                                                            <ul>
                                                                                {/* {page.history_transaction.map((summary, index) => {
                                                                                    return ( */}
                                                                                        {/* <React.Fragment key={this.props.invoice.id+'-'+index1+'description'+index}> */}
                                                                                        <div className="previousBalanceDetail" style={{border:'0'}}>
                                                                                            {/* <li>
                                                                                                - 
                                                                                            </li> */}
                                                                                           
                                                                                        </div>
                                                                                        {/* </React.Fragment> */}
                                                                                    {/* )
                                                                                })} */}
                                                                            </ul>
                                                                        </td>
                                                                        <td style={{borderLeft:'none'}}/>           
                                                                        <td/>
                                                                        <td/>
                                                                        <td/>
                                                                    </tr>
                                                            </React.Fragment>

                                                            {page.history_transaction.length > 0 && this.props.invoice.historyShow && (this.props.invoice.payGroup === "OWNER" || this.props.invoice.payGroup === "DEVELOPER") &&
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td/>
                                                                        <td/>
                                                                        <td colSpan={2} style={{borderRight:'none'}}> 
                                                                        <Translation>
                                                                            {t=><strong>
                                                                                {t("document:Previous Balance")} {page.isNextHistory && <Translation>{t=><strong> ({t("document:per")}) </strong>}</Translation>} {
                                                                                    !page.isNextHistory &&numberWithComma(this.checkCountHistory(this.props.invoice.id, 'summary'),0)}{!page.isNextHistory && t("document:Baht")}
                                                                                    {!page.isNextHistory && '('}{!page.isNextHistory &&this.checkCountHistory(this.props.invoice.id, 'count')} {!page.isNextHistory && `${t("document:Documents")})`}
                                                                            </strong>}
                                                                        </Translation>
                                                                        </td>
                                                                        <td/>
                                                                        <td/>
                                                                        <td/>
                                                                    </tr>
                                                                    <tr>
                                                                        <td/>
                                                                        <td/>
                                                                        <td style={{borderRight:'none'}}>
                                                                            <ul>
                                                                                {page.history_transaction.map((summary, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={this.props.invoice.id+'-'+index1+'description'+index}>
                                                                                            <div className="previousBalanceDetail">
                                                                                            <li>
                                                                                                - {summary.product_and_service_name.substring(0,45)}
                                                                                            </li>
                                                                                            <Translation key={index} >
                                                                                                {t=>
                                                                                                <li key={this.props.invoice.id+'-'+index1+'price'+index}
                                                                                                    className='previousBalanceDetailAmount'
                                                                                                >
                                                                                                    {(summary.sum_transaction >= 0 ? numberWithComma(summary.sum_transaction) : "-"+numberWithComma(Math.abs(summary.sum_transaction))) + ` ${t("document:Baht")}`} 
                                                                                                </li>}
                                                                                            </Translation>
                                                                                        </div>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </td>
                                                                        
                                                                        {/* <td style={{borderLeft:'none'}}className="text-right" >
                                                                            <ul>
                                                                                {page.history_transaction.map((summary, index) => {
                                                                                    return (
                                                                                        <Translation key={index} >
                                                                                            {t=>
                                                                                            <li key={this.props.invoice.id+'-'+index1+'price'+index}>
                                                                                                {(summary.sum_transaction >= 0 ? numberWithComma(summary.sum_transaction) : "-"+numberWithComma(Math.abs(summary.sum_transaction))) + ` ${t("document:Baht")}`} 
                                                                                            </li>}
                                                                                        </Translation>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </td> */}
                                                                        <td style={{borderLeft:'none'}}/> 
                                                                        <td/>
                                                                        <td/>
                                                                        <td/>
                                                                    </tr>
                                                                    {page.history_transaction.length > 0 && page.invoice_transaction.length <= 0 &&
                                                                        <tr className= 'lastRow'>
                                                                            <td className="lasttd"/>
                                                                            <td className="lasttd"/>
                                                                            <td colSpan={2} className="lasttd"/>
                                                                            <td className="lasttd"/>
                                                                            <td className="lasttd"/>
                                                                            <td className="lasttd"/>
                                                                        </tr>
                                                                    }


                                                                </React.Fragment>
                                                            }
                                                        
            
                                                        </tbody>
                                                        <tfoot>
                                                            {(page.index_page === total_page ) ? 
                                                            <React.Fragment>
                                                                {page.history_transaction.length === 0 && page.invoice_transaction.length === 0 &&
                                                                    <tr className="lastRow">
                                                                        <td className="lasttd" style={{borderBottom:'none'}}/>
                                                                        <td className="lasttd" style={{borderBottom:'none'}}/>
                                                                        <td colSpan={2} className="lasttd" style={{borderBottom: !this.props.invoice.historyShow &&'none'}}/>
                                                                        <td className="lasttd"/>
                                                                        <td className="lasttd"/>
                                                                        <td className="lasttd"/>
                                                                    </tr>
                                                                }

                                                                <tr >
                                                                    <td rowSpan={2} style={{borderTop:'none' , borderBottom:'none'}} />
                                                                    <td rowSpan={2} style={{borderTop:'none' ,  borderBottom:'none'}}/>
                                                                    {
                                                                        this.props.invoice.contact.typeOfContact === "RESIDENTIAL" ?
                                                                                <td colSpan={2} rowSpan={
                                                                                    this.props.invoice.contact.typeOfContact === "RESIDENTIAL" &&
                                                                                    // this.state.discount_status ? 4 : 2
                                                                                    this.state.discount_status ? 5 : 3
                                                                                } 
                                                                                style={{borderTop:'none'}}
                                                                               //  style={this.props.invoice.historyShow ? null : {borderTop:'none' ,  borderBottom:'none'}}
                                                                                className="text-center">
                                                                                     {/* แสดงผลรวมบรรทัดแรกก */}
                                                                                    {/* {this.props.invoice.historyShow && <Translation>{t=><strong style={{display: 'block'}}> {(page.history_transaction.length === 0) ? t("document:Total payment") : t("document:Total outstanding balance") }       {numberWithComma(this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount),0)} {t("document:Baht")} </strong>}</Translation> } */}
                                                                                    {/* {this.props.invoice.historyShow && <strong style={{display: 'block'}} className="mt-2"> 
                                                                                    ({
                                                                                            i18n.languages[0] === 'th' ? ThaiBaht(
                                                                                                this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount)) 
                                                                                                : numberToText.convertToText(this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),parseInt(this.state.sum_amount))) + " Baht"
                                                                                    }) </strong> 
                                                                                    } */}
                                                                                {/* {this.props.invoice.historyShow && <strong style={{display: 'block'}} className="mt-2"> 
                                                                                { 
                                                                                    i18n.languages[0] === 'th' ? 
                                                                                        this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount) === 0 ? 
                                                                                            ' ' :
                                                                                            "(" + ThaiBaht((this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount)).toFixed(2)) + ")"
                                                                                    :
                                                                                    this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount) < 0 ? ' ' : "(" + numberToText.convertToText(this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),parseInt(this.state.sum_amount))) + " Baht)"
                                                                                } </strong> 
                                                                                } */}
                                                                                </td>
                                                                            :
                                                                            <td colSpan={2}
                                                                                rowSpan={
                                                                                    (this.state.no_vat && this.state.no_wht_rate_percent && !this.state.discount_status) ? 3 :
                                                                                        (
                                                                                            (this.state.no_vat && this.state.no_wht_rate_percent && this.state.discount_status) ||
                                                                                            (this.state.no_vat && this.state.have_wht_rate_percent && !this.state.discount_status) ||
                                                                                            (this.state.have_vat && this.state.no_wht_rate_percent && !this.state.discount_status)
                                                                                        ) ? 5 :
                    
                                                                                            (
                                                                                                (this.state.have_vat && this.state.no_wht_rate_percent && this.state.discount_status) ||
                                                                                                (this.state.no_vat && this.state.have_wht_rate_percent && this.state.discount_status) ||
                                                                                                (this.state.have_vat && this.state.have_wht_rate_percent && !this.state.discount_status)
                                                                                            ) ? 6 :
                                                                                                this.state.have_vat && this.state.have_wht_rate_percent && this.state.discount_status && 8
                                                                                } style={this.props.invoice.historyShow ? null : {borderTop:'none' ,  borderBottom:'none'}}
                                                                                className="text-center"  >
                                                                                {this.props.invoice.historyShow && <Translation>{t=><strong style={{display: 'block'}}> {(page.history_transaction.length === 0) ? t("document:Total payment") : t("document:Total outstanding balance") }       {numberWithComma(this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount),0)} {t("document:Baht")} </strong>}</Translation> }
                                                                                {this.props.invoice.historyShow && <strong style={{display: 'block'}} className="mt-2"> 
                                                                                { 
                                                                                    i18n.languages[0] === 'th' ? this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount) === 0 ? ' ' :
                                                                                    "(" + ThaiBaht((this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount)).toFixed(2)) + ")":
                                                                                    this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount) < 0 ? ' ' : "(" + this.convertTextToNumberEng(this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount)) + " Baht)"
                                                                                } </strong> 
                                                                                }
                                                                            </td>
                                                                    }
                    
                                                                    <Translation>
                                                                        {t=><td colSpan={2} >
                                                                            {t("document:Amount")}
                                                                        </td>}
                                                                    </Translation>
                                                                    <td className="text-right">
                                                                        {numberWithComma(this.state.sum_amount, 0)}
                                                                    </td>
                                                                </tr>
                                                            
                
                                                            {this.state.discount_status &&
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <Translation>
                                                                            {t=>
                                                                            <td colSpan={2}>
                                                                                <strong>{t("document:Deduct")}&nbsp;</strong>{t("document:Discount")}
                                                                            </td>}
                                                                        </Translation>
                                                                        <td className="text-right">
                                                                            {numberWithComma(this.state.discount, 0)}
                                                                        </td>
                                                                    </tr>
            
                                                                    <tr>
                                                                        <td rowSpan={1} style={{borderTop:'none' , borderBottom:'none'}} />
                                                                        <td rowSpan={1} style={{borderTop:'none' , borderBottom:'none'}}/>
                                                                        <Translation>
                                                                            {t=>
                                                                            <td colSpan={2}>
                                                                                {t("document:Total Discount")}
                                                                            </td>}
                                                                        </Translation>
                                                                        <td className="text-right">
                                                                            {numberWithComma(this.state.sum_discount, 0)}
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            }

                                                            <tr>
                                                                {this.state.discount_status &&  
                                                                <>
                                                                 <td rowSpan={1} style={{borderTop:'none' , borderBottom:'none'}} />
                                                                 <td rowSpan={1} style={{borderTop:'none' ,  borderBottom:'none'}}/>
                                                                </>
                                                                 }
                                                                <Translation>
                                                                    {t=>
                                                                    <td colSpan={2}>
                                                                        {t("receivableClassifiedReport:Outstanding Amount")}
                                                                    </td>}
                                                                </Translation>
                                                                <td className="text-right">
                                                                 {/* {numberWithComma(this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount),0)} */}
                                                                 { this.props.invoice.historyShow ? numberWithComma(this.checkCountHistory(this.props.invoice.id, 'summary'),0) : "0.00"}
                                                                </td>
                                                            </tr>
                                                            
                                                            <tr>
                                                                <>
                                                                <td rowSpan={1} style={{borderTop:'none' , borderBottom:'none'}}/>
                                                                <td rowSpan={1} style={{borderTop:'none' ,  borderBottom:'none'}}/>
                                                                </>
                                                                {/* } */}
                                                                <Translation>
                                                                    {t=>
                                                                    <>
                                                                    <td colSpan={2} style={{width:'300px'}}>
                                                                        <strong>{t("document:Deduct")}&nbsp;</strong>{t("document:Receipt Deposit")}
                                                                    </td>
                                                                    </>
                                                                   }
                                                                </Translation>
                                                                <td className="text-right">
                                                                    {numberWithComma(this.state.receiveDeposit,0)}
                                                                </td>
                                                            </tr>
            
                                                            {
                                                                (this.state.have_vat || this.state.have_wht_rate_percent) &&
                                                                this.props.invoice.contact.typeOfContact !== "RESIDENTIAL" && 
                                                                    <tr>
                                                                        <td rowSpan={1} style={{borderTop:'none' , borderBottom:'none'}} />
                                                                        <td rowSpan={1} style={{borderTop:'none' ,  borderBottom:'none'}}/>
                                                                        <Translation>
                                                                            {t=><td colSpan={2}>
                                                                                {t("document:Total Receipt Deposit")}
                                                                            </td>}
                                                                        </Translation>
                                                                        <td className="text-right">
                                                                            {numberWithComma(this.state.sum_receive_deposit, 0)}
                                                                        </td>
                                                                    </tr>
                                                            }
                
                                                            {
                                                                (
                                                                    (this.state.have_vat && this.state.no_wht_rate_percent) ||
                                                                    (this.state.have_vat && this.state.have_wht_rate_percent)) &&
                                                                    this.props.invoice.contact.typeOfContact !== "RESIDENTIAL"  &&
                                                                    <tr>
                                                                        <td rowSpan={1} style={{borderTop:'none' , borderBottom:'none'}} />
                                                                        <td rowSpan={1} style={{borderTop:'none' ,  borderBottom:'none'}}/>
                                                                        <Translation>
                                                                            {t=><td colSpan={2}>
                                                                                {t("document:Vat")}
                                                                            </td>}
                                                                        </Translation>
                                                                        <td className="text-right">
                                                                            {numberWithComma(this.state.sum_vat, 0)}
                                                                        </td>
                                                                    </tr>
                                                            }
                
                                                            {
                                                                this.props.invoice.contact.typeOfContact !== "RESIDENTIAL" &&
                                                                this.state.have_wht_rate_percent && this.state.have_vat &&
                                                                <tr>
                                                                    <td rowSpan={1} style={{borderTop:'none' , borderBottom:'none'}} />
                                                                    <td rowSpan={1} style={{borderTop:'none' ,  borderBottom:'none'}}/>
                                                                    <Translation>
                                                                        {t=><td colSpan={2}>
                                                                           {t("document:Total cash")}
                                                                        </td>}
                                                                    </Translation>
                                                                    <td className="text-right">
                                                                        {numberWithComma(this.state.sum_total, 0)}
                                                                    </td>
                                                                </tr>
                                                            }
                
                                                            {
                                                                this.state.have_wht_rate_percent &&
                                                                this.props.invoice.contact.typeOfContact !== "RESIDENTIAL" &&
                                                                <tr>
                                                                    <td rowSpan={1} style={{borderTop:'none' , borderBottom:'none'}} />
                                                                    <td rowSpan={1} style={{borderTop:'none' ,  borderBottom:'none'}}/>
                                                                    <Translation>
                                                                        {t=>
                                                                        <td colSpan={2}>
                                                                            <strong>{t("document:Deduct")}&nbsp;</strong>{t("document:With holding tax")}
                                                                        </td>}
                                                                    </Translation>
                                                                    <td className="text-right">
                                                                        {numberWithComma(this.state.sum_wht_rate, 0)}
                                                                    </td>
                                                                </tr>
                                                            }
            
                                                            <tr>
                                                                <td style={{borderTop:'none'}}/>
                                                                <td style={{borderTop:'none'}} />
                                                                <td colSpan={2} className="text-center">
                                                                <strong>
                                                                 {
                                                                    // this.props.invoice.historyShow ?                                                                     
                                                                    // i18n.languages[0] === 'th' ? this.state.sum_grand_total === '0.00' || this.state.sum_grand_total  < 0 ? " ": "(HHH" + ThaiBaht(this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount),0) + ")"
                                                                    // : this.state.sum_grand_total  === '0.00' || this.state.sum_grand_total < 0 ? " " : "(" + numberToText.convertToText( parseInt(this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount),0)) + " Baht)"
                                                                    //  :
                                                                    !this.props.invoice.historyShow ? 
                                                                    i18n.languages[0] === 'th' ? this.state.sum_grand_total === '0.00' || this.state.sum_grand_total  < 0 ? " ": "(" + ThaiBaht(this.state.sum_grand_total) + ")"
                                                                    : this.state.sum_grand_total  === '0.00' || this.state.sum_grand_total < 0 ? " " : "(" + this.convertTextToNumberEng(this.state.sum_grand_total) + " Baht)" :
                                                                   ''
                                                                     //<Translation>{t=><strong style={{display: 'block'}}> {(page.history_transaction.length === 0) ? t("document:Total payment") : t("document:Total outstanding balance") }{numberWithComma(this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount),0)} {t("document:Baht")} RR</strong>}</Translation>
                                                                 }
                                                                 </strong>
                                                                 {this.props.invoice.historyShow && <strong style={{display: 'block'}}> 
                                                                    { 
                                                                        i18n.languages[0] === 'th' ? 
                                                                            this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount) === 0 ? 
                                                                                ' ' :
                                                                                "(" + ThaiBaht((this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount)).toFixed(2)) + ")"
                                                                        :
                                                                        this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount) < 0 ? ' ' : "(" + this.convertTextToNumberEng(this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount)) + " Baht)"
                                                                    } </strong> 
                                                                    }
                                                                </td>
                                                                <Translation>
                                                                    {t=>
                                                                    <td colSpan={2}>
                                                                        {t("document:Grand Total")}
                                                                    </td>}
                                                                </Translation>
                                                                <td className="text-right">

                                                                {
                                                                    this.props.invoice.historyShow ? 
                                                                    numberWithComma(this.sumInvoice(this.checkCountHistory(this.props.invoice.id,'summary'),this.state.sum_amount),0):
                                                                    numberWithComma(this.state.sum_grand_total < 0 ? 0 :this.state.sum_grand_total , 0) 
                                                                }
                                                                </td>
                                                            </tr> 
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td style={{borderTop:'none'}} />
                                                                    <td style={{borderTop:'none'}} />
                                                                    <td colSpan={2} style={{borderTop:'none'}}/>
                                                                    <td style={{borderTop:'none'}} />
                                                                    <td style={{borderTop:'none'}} />
                                                                    <td style={{borderTop:'none'}} />
                                                                </tr>
                                                                
                                                            </React.Fragment>
                                                        
                                                        }
                                                        
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                        
                                                
            
                                                <div className="footer">
                                                    {page.index_page === total_page &&
                                                        <div className="remark" style={{whiteSpace: 'pre-line'}}>
                                                            <Translation>{t=><strong style={{fontWeight:'bold'}}>{t("document:Remarks")} : </strong>}</Translation> <br/>
                                                            {this.state.remark}
                                                            
                                                        </div>
                                                    }
                                                    {page.index_page === total_page && this.props.selfProject.keyProjectQr && 
                                                    // <div className="cross-payment" style={{marginTop:'0',padding:'5px'}}>
                                                    //     <Translation>
                                                    //         {t=>
                                                    //         <p className="w-100">
                                                    //             1. {t("document:Pay at Thanachart Bank counter or Kiatnakin")}
                                                    //             <small><b> ({t("document:Fee not more than")} 20 {t("document:Baht")}) </b></small>
                                                    //         </p>}
                                                    //     </Translation>
                                                    //     <Translation>
                                                    //         {t=>
                                                    //         <div className="w-100">
                                                    //             2. {t("document:Paying through electronic channels")} ATM, INTERNET, MOBILE BANKING
                                                    //             <small><b> ({t("document:Fee")} {this.props.invoice.topupCrossbill > 0 ? this.props.invoice.topupCrossbill : 0} {t("document:Baht")})</b> </small> <br/>
                                                    //             <div className="bank-space"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/bay.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/bbl.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/cimb.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/gsb.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/icbc.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/kbank.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/kk.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/ktb.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/mb.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/scb.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/ttb.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                    //             <img src={process.env.PUBLIC_URL + '/images/banks/uob.svg'}
                                                    //                 alt="bay" className="bank"/>
                                                                
                                                    //         </div>}
                                                    //     </Translation>
                                                    //     <div className="detail w-100">
                                                    //         <Translation>
                                                    //             {t =>
                                                    //             <small>
                                                    //                 {t("document:participating banks and service providers")}
                                                    //             </small>
                                                    //             }
                                                    //         </Translation>
                                                    //     </div>
            
                                                    //     <div className="clearfix"/>
                                                    //     {this.props.selfProject.bankCompCode &&
                                                    //         <div><u>COMP CODE:</u> {this.props.selfProject.bankCompCode} <u>SERVICE CODE:</u> {this.props.selfProject.bankServiceCode}</div>
                                                    //     }
                                                       
                                                    //     <div className="clearfix"/>
                                                    //     <div className='row'>
                                                    //     <div className="col barcode">
                                                    //         {
                                                    //              this.props.invoice.topupCrossbill > 0 ? 
                                                    //            <>
                                                    //             <Barcode
                                                    //                 width={1.1} height={40} fontSize={0}
                                                    //                 value={"|" + this.props.selfProject.keyProjectQr + "\r" + this.state.contact_room_name + "\r" + this.state.doc_num_for_qr + "\r" + this.getPlusTopupCrossBill(this.state.sum_grand_total,this.props.invoice.topupCrossbill) * 100}/>
                                                    //             <div>{"|" +this.props.selfProject.keyProjectQr + this.state.contact_room_name + this.state.doc_num_for_qr +  this.getPlusTopupCrossBill(this.state.sum_grand_total,this.props.invoice.topupCrossbill) * 100}</div>
                                                    //             </>
                                                    //             : 
                                                    //             <>
                                                    //              <Barcode
                                                    //             width={1.1} height={40} fontSize={0}
                                                    //             value={"|" + this.props.selfProject.keyProjectQr + "\r" + this.state.contact_room_name + "\r" + this.state.doc_num_for_qr + "\r" + this.state.sum_grand_total * 100}/>
                                                    //             <div>{"|" +this.props.selfProject.keyProjectQr + this.state.contact_room_name + this.state.doc_num_for_qr + this.state.sum_grand_total * 100}</div>     
                                                    //             </>

                                                    //         }

                                                    //     </div>
                                                    //     <div className="col qr">
                                                    //         {
                                                    //             this.props.invoice.topupCrossbill > 0 ? 
                                                    //             <QRCode
                                                    //             value={"|" + this.props.selfProject.keyProjectQr + "\r" + this.state.contact_room_name + "\r" + this.state.doc_num_for_qr + "\r" +
                                                    //             (this.getPlusTopupCrossBill(this.state.sum_grand_total,this.props.invoice.topupCrossbill) * 100).toFixed(3)}
                                                    //             size={65}/> 
                                                    //             :  
                                                    //             <QRCode
                                                    //             value={"|" + this.props.selfProject.keyProjectQr + "\r" + this.state.contact_room_name + "\r" + this.state.doc_num_for_qr + "\r" + this.state.sum_grand_total * 100}
                                                    //             size={65}/>
                                                    //         }
                                                    //     </div>
                                                    //     <div className='col'><u>BILLER ID:</u> {this.props.selfProject.keyProjectQr}, <u>REF1:</u> {this.state.contact_room_name}, <u>REF2:</u> {this.state.doc_num_for_qr}</div>
                                                    //     </div>
                                                   
                                                    // </div>
                                                    <div className="cross-payment" style={{marginTop:'0',padding:'3px'}}>
                                                    <div className='row'>
                                                       <div className='col-9'>
                                                       <Translation>
                                                          {t=>
                                                          <div className="w-100">
                                                              {"("+t("document:Fees should not exceed 5 THB per transaction via electronic channels and 20 THB per transaction at branch locations.")+")"}
                                                              <br/>
                                                              <div className="bank-space"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/bay.svg'}
                                                                  alt="bay" className="bank"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/bbl.svg'}
                                                                  alt="bay" className="bank"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/cimb.svg'}
                                                                  alt="bay" className="bank"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/gsb.svg'}
                                                                  alt="bay" className="bank"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/icbc.svg'}
                                                                  alt="bay" className="bank"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/kbank.svg'}
                                                                  alt="bay" className="bank"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/kk.svg'}
                                                                  alt="bay" className="bank"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/ktb.svg'}
                                                                  alt="bay" className="bank"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/mb.svg'}
                                                                  alt="bay" className="bank"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/scb.svg'}
                                                                  alt="bay" className="bank"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/ttb.svg'}
                                                                  alt="bay" className="bank"/>
                                                              <img src={process.env.PUBLIC_URL + '/images/banks/uob.svg'}
                                                                  alt="bay" className="bank"/>
                                                              
                                                          </div>}
                                                       </Translation>
                                                       <div className="clearfix"/>
                                                       <div className="barcode">
                                                          
                                                          {
                                                               this.props.invoice.topupCrossbill > 0 ? 
                                                             <>
                                                              <Barcode
                                                                  width={1.1} height={45} fontSize={0}
                                                                  value={"|" + this.props.selfProject.keyProjectQr + "\r" + this.state.contact_room_name + "\r" + this.state.doc_num_for_qr + "\r" + this.getPlusTopupCrossBill(this.state.sum_grand_total,this.props.invoice.topupCrossbill) * 100}/>
                                                              <div>{"|" +this.props.selfProject.keyProjectQr + this.state.contact_room_name + this.state.doc_num_for_qr +  this.getPlusTopupCrossBill(this.state.sum_grand_total,this.props.invoice.topupCrossbill) * 100}</div>
                                                              </>
                                                              : 
                                                              <>
                                                               <Barcode
                                                              width={1.1} height={45} fontSize={0}
                                                              value={"|" + this.props.selfProject.keyProjectQr + "\r" + this.state.contact_room_name + "\r" + this.state.doc_num_for_qr + "\r" + this.state.sum_grand_total * 100}/>
                                                              <div>{"|" +this.props.selfProject.keyProjectQr + this.state.contact_room_name + this.state.doc_num_for_qr + this.state.sum_grand_total * 100}</div>     
                                                              </>

                                                          }

                                                       </div>
                                                       <div className="clearfix"/>
                                                       <div>
                                                          <Translation>{t=><p style={{fontSize:'10px'}}> - {t("document:You can check the list of participating banks and service providers on the Bank of Thailand's website.")} </p>}</Translation> 
                                                          <Translation>{t=><p style={{fontSize:'10px'}}> - {t("document:Fees are subject to the terms and conditions of each bank/service provider.")} </p>}</Translation> 
                                                       </div>
                                                       </div>
                                                       <div className='col-3'>
                                                          {this.props.selfProject.bankCompCode &&
                                                              <div><u>COMP CODE:</u> {this.props.selfProject.bankCompCode} <u>SERVICE CODE:</u> {this.props.selfProject.bankServiceCode}</div>
                                                          }
                                                          <div style={{float:'right'}}><p>BILLER ID:{this.props.selfProject.keyProjectQr},</p>  <p>REF1:{this.state.contact_room_name},</p><p>REF2:{this.state.doc_num_for_qr}</p></div>
                                                          <div className="clearfix"/>
                                                          <div className="qr" style={{marginTop:'0'}}>
                                                              {
                                                                  this.props.invoice.topupCrossbill > 0 ? 
                                                                  <QRCode
                                                                  value={"|" + this.props.selfProject.keyProjectQr + "\r" + this.state.contact_room_name + "\r" + this.state.doc_num_for_qr + "\r" +
                                                                  (this.getPlusTopupCrossBill(this.state.sum_grand_total,this.props.invoice.topupCrossbill) * 100).toFixed(3)}
                                                                  size={80}/> 
                                                                  :  
                                                                  <QRCode
                                                                  value={"|" + this.props.selfProject.keyProjectQr + "\r" + this.state.contact_room_name + "\r" + this.state.doc_num_for_qr + "\r" + this.state.sum_grand_total * 100}
                                                                  size={80}/>
                                                              }
                                                          </div>
                                                       </div>
                                                    </div>

                                                  </div>
                                                    }

                                                    {page.index_page === total_page && this.props.selfProject.prompayQrShow &&
                                                    <div class="cross-payment d-flex flex-row" style={{justifyContent: 'space-between'}}>
                                                    <div><strong style={{fontWeight:'bold'}}>สำหรับการชำระเงินผ่าน พร้อมเพย์ (Promptpay) :</strong> <br/>
                                                    <span>กรุณาสแกน QR Code พร้อมตรวจสอบยอดที่ต้องชำระและกด "ยืนยัน" เพื่อชำระค่าบริการ</span>
                                                    </div>
                                                    <div><img src={this.props.selfProject.prompayQrShow} style={{width:'100px',height:'100px'}} /></div>
                                                    </div>}
            
                                                    {page.index_page === total_page &&
                                                        <div className="signature" style={{marginTop:'5px'}}>
                                                            ............................................................... <br/>
                                                            <Translation>{t=><strong style={{fontSize:'12px' , fontWeight:'bold'}}>{t("document:Authorized")}</strong>}</Translation>
                                                        </div>
                                                    }
                                                    {total_page !== 1 &&
                                                    <div className="number-page">
                                                        <br/>
                                                        {page.index_page}/{total_page}
                                                    </div>
                                                    }
                                                </div>
            
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                                )
                            )
                            
                    })
                }
                
            </React.Fragment>
        );
    }
}

export default InvoiceTemplete5;