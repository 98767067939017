import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Wrapper from "../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, commitMutation } from "relay-runtime";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import "./setupChartofaccount.scss"
import _ from "lodash";
import i18n from "i18next";
import SearchSelect from "../../libs/searchSelect";
import jwtDecode from "jwt-decode";
import Loading from '../../libs/loading';

const query = graphql`
    query setupChartofaccountQuery {

        allChartOfAccountSettingUsing {
            edges{
                node{
                    id
                    nameTitle
                    nameTitleTh
                    chartOfAccount{
                        id
                        name
                        chartOfAccountCode
                    }
                }
            } 
        }

        viewer {
            allChartOfAccount{
                totalCount
                edges {
                    node {
                        id name nameEn chartOfAccountCode totalHistoricalDebit totalHistoricalCredit
                    }
                }
            }
        }

    }`;

const mutation = graphql`
    mutation setupChartofaccountMutation($input: UpdateChartOfAccountSettingUsingInput!) {
        updateChartOfAccountSettingUsing(input: $input) {
            ok
            massage
        }
    }
`;

class setupChartofaccountList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            redirectToList: false,
            user_super_admin: false,
            allChartOfAccountSettingUsing: null,
            allChartOfAccount: null,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        let token = window.localStorage.getItem('token');
        let token_decode = jwtDecode(token)
        if (token_decode.user_super_admin) {
            this.query();
        }
        this.setState({ user_super_admin: token_decode.user_super_admin })
    }

    query() {
        fetchQuery(environment, query).then(data => {
            let data_clone = _.cloneDeep(data);
            this.setState({
                allChartOfAccountSettingUsing:data_clone.allChartOfAccountSettingUsing,
                allChartOfAccount:data_clone.viewer.allChartOfAccount,
                loading: false,
            });
        })
    }

    handleInputChange(e) {

        let allChartOfAccountSettingUsing = _.cloneDeep(this.state.allChartOfAccountSettingUsing);
        if (e.target.name === "allChartOfAccountList") {
            let all_chart_of_account = _.find(this.state.allChartOfAccount.edges, { node: { id: e.target.value } });
            _.set(allChartOfAccountSettingUsing, "edges[" + e.target.dataset.id + "].node.chartOfAccount", all_chart_of_account.node);
            _.set(allChartOfAccountSettingUsing, "edges[" + e.target.dataset.id + "].node.change", true);
            this.setState({ allChartOfAccountSettingUsing: allChartOfAccountSettingUsing });
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }

    }

    onSubmit(e) {
        e.preventDefault();

        let updateChartOfAccount = []
        let allChartOfAccountSettingUsing_change = _.filter(_.cloneDeep(this.state.allChartOfAccountSettingUsing.edges),{ node: { change: true } })
        _.forEach(allChartOfAccountSettingUsing_change , e => {
           updateChartOfAccount.push({
            nameTitle:e.node.nameTitle,
            chartOfAccountId:e.node.chartOfAccount.id
           })
        })

        let variables = {
            input: {
                jsonObjectsGroup: JSON.stringify(updateChartOfAccount)
            },
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    if (response.updateChartOfAccountSettingUsing.ok) {
                        Swal.fire(i18n.t('setupChartofaccount:Save successfully'), '', 'success').then(() => {
                            this.setState({ redirectToList: true, loading: false });
                            this.query();
                        })
                    } else {
                        Swal.fire(i18n.t('setupChartofaccount:Failed to save'), '', 'warning');
                        this.setState({ loading: false });

                    }
                },
                onError: (err) => {
                    Swal.fire('Error!', i18n.t('setupChartofaccount:Please try again'), 'warning')
                    this.setState({ loading: false });
                },
            },
        );

    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <div id="wrapper-content" >
                    <div className="container-fluid box" id='setupchartofaccountlist-setting' >
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('setupChartofaccount:setupChartOfAccount')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>


                        <div className="content-inner padding" >
                            {this.state.loading ?
                                <Loading />
                                :
                                <div className="card-table fade-up">
                                    <form id="setupchartofaccountlist-setting" onSubmit={this.onSubmit}>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr className="head" >
                                                    <Translation>
                                                        {
                                                            t => <th width={150} className="text-center" >{t('setupChartofaccount:Title')}</th>
                                                        }
                                                    </Translation>
                                                    <Translation>
                                                        {
                                                            t => <th width={150} className="text-center" >{t('setupChartofaccount:Chartofaccountscode')}</th>
                                                        }
                                                    </Translation>
                                                        </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.allChartOfAccount && this.state.allChartOfAccountSettingUsing.edges.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-center">
                                                                {
                                                                    data.node.nameTitleTh
                                                                }
                                                            </td>
                                                            <td className="text-left">
                                                                <SearchSelect onChange={this.handleInputChange}
                                                                    value={data.node.chartOfAccount.id ? data.node.chartOfAccount.id : null}
                                                                    name="allChartOfAccountList" placeholder="รายการ"
                                                                    queryObject={this.state.allChartOfAccount.edges}
                                                                    keyOfValue="id"
                                                                    require={true}
                                                                    data-id={index}
                                                                    data-key="allChartOfAccountSettingUsing"
                                                                    keyOfLabel="chartOfAccountCode:name"
                                                                />
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>

                                        <div className='row col-12 mt-48 mb-48 pb-3 d-flex justify-content-end'>
                                            <button type='submit' className='btn mr-1 submit-btn'>
                                                {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                <span>{i18n.t('setupChartofaccount:Save')}</span>
                                            </button>

                                            <button type='button' className='btn submit-outline-btn'>
                                                {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                <span>{i18n.t('setupChartofaccount:Un Save')}</span>
                                            </button>
                                        </div >

                                    </form>
                                </div>
                            }
                            <hr />
                        </div>

                    </div>

                </div>
            </Wrapper >
        )
    }
}

export default setupChartofaccountList;
