import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";

var token_id = localStorage.getItem("token");
// console.log(token_id);
// var header = {
//     authorization: `JWT ${token_id}`,
//     "Content-Type": "application/json",
// };

function getToken() {
    var token_ids = localStorage.getItem("token");
    var header = {
        authorization: `JWT ${token_ids}`,
        "Content-Type": "application/json",
    };
    token_id = token_ids
    return header
}


//Asset
function getQR(QR) {
    return axios.get(`${getApiRoot()}singha/asset`, { headers: getToken(), params: { qr: QR } },);
}

function getassetAll() {
    return axios.get(`${getApiRoot()}singha/asset`, { headers: getToken(), },);
}

function approveStatus(data){  
    return axios.post(`${getApiRoot()}singha/asset`, data ,{ headers: getToken() });
}


// SettingFastConnect
function getRepairs_setting() {
    return axios.get(`${getApiRoot()}singha/repairs_setting`, { headers: getToken(), },)
}

async function postRepairs_setting(data) {
    let formBody = [];
    formBody.push('repairs_fastinspect='+ data);
    const response = await fetch(
        getApiRoot() + "singha/repairs_setting",
        {
            method: "POST",
            headers: {
                authorization: `JWT ${token_id}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formBody
        }
    ).catch((error) => { console.error("Error:", error); });

    return response.json();
}

function getrepairs_room_view(data) {
    return axios.get(`${getApiRoot()}singha/repairs_room_view`, {
        headers: getToken(),
        params: {
            index_first: data.index_first,
            index_last: data.index_last,
            pageSize: data.pageSize,
            page: data.page,
            status: data.status,
            id: data.id,
            person_working: data.person_working,
            search: data.search,
            start_date: data.start_date,
            end_date: data.end_date,
            repair_type: data.repair_type,

        }
    })
}

function getrepairs_room_view1() {
    // console.log("data",data);
    return axios.get(`${getApiRoot()}singha/repairs_room_view`, {
        headers: getToken(),
        params: {
            // index_first: data.index_first,
            // index_last: data.index_last,
            // pageSize: data.pageSize,
            // page: data.page,
            // status: data.status,
            // id: data.id,
            // person_working: data.person_working,
            // search: data.search,
            // start_date: data.start_date,
            // end_date: data.end_date,
            // repair_type: data.repair_type,

        }
    })
}

function getrepairs_room_viewBYId(data) {
    return axios.get(`${getApiRoot()}singha/repairs_room_view`, {
        headers: getToken(),
        params: { 
            id: data.id, 
        }
    })
}
 

// ออกใบงาน
async function postrepairs_room_view(data) {
    let formBody = [];
    formBody.push('repairs_room_id='+ data.repairs_room_id);
    formBody.push('working_type='+ data.working_type);
    formBody.push('technician='+ data.technician);
    formBody.push('project_staff_id='+ data.project_staff_id);
    formBody.push('project_staff_other='+ data.project_staff_other);
    formBody.push('phone='+ data.phone);
    formBody.push('description='+ data.description);
    formBody.push('appointments='+ data.appointments);
    formBody = formBody.join("&");

    const response = await fetch(
        getApiRoot() + "singha/repairs_room_worksheet",
        {
            method: "POST",
            headers: {
                authorization: `JWT ${token_id}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formBody,
        }
    ).catch((error) => {
        console.error("Error:", error);
    });

    return response.json();
}

// Update WorkSheet
function repairs_room_work_update(data) {
    return axios.patch(`${getApiRoot()}singha/repairs_room_work_update`, data, { headers: getToken(), })
}



// GET  ออกใบงาน โดย ID
function getRepairs_room_worksheet(data) {
    return axios.get(`${getApiRoot()}singha/repairs_room_worksheet`, {
        headers: getToken(),
        params: {
            repairs_room_id: data.repairs_room_id,
            id : data.id
        }
    })
}





function patchRepairs_room_worksheet(data) {
    return axios.patch(`${getApiRoot()}singha/repairs_room_worksheet`, data, {
        headers: getToken()
    })
}

function getstaff_technician() {
    return axios.get(`${getApiRoot()}singha/staff_technician`, {
        headers: getToken()
    })
}

//หมวดงาน
function get_task_type_service(areaType) {
    return axios.get(`${getApiRoot()}singha/get_task_type_service`, {
        headers: getToken(),
        params: {
            areaType: areaType
        }
    })
}

function get_task_detail(taskTypeId) {
    return axios.get(`${getApiRoot()}singha/get_task_detail`, {
        headers: getToken(),
        params: {
            taskTypeId: taskTypeId
        }
    })
}
function get_task_detail_note(taskTypeId, taskDetailId , residentialId) {
    return axios.get(`${getApiRoot()}singha/get_task_detail_note`, {
        headers: getToken(),
        params: {
            taskTypeId: taskTypeId,
            taskDetailId: taskDetailId, 
            residentialId: residentialId
        }
    })
}

function get_unit_detail(residentialId) {
    return axios.get(`${getApiRoot()}singha/get_unit_detail`, {
        headers: getToken(),
        params: {
            residentialId: residentialId
        }
    })
}

function POSTrepairs_room_view(data) {
    return axios.post(`${getApiRoot()}singha/repairs_room_view`, data, { headers: getToken() })
}

function patchrepairs_room_view(data) {
    return axios.patch(`${getApiRoot()}singha/repairs_room_view`, data, { headers: getToken() })
}

function patchrepairs_room_update(data) {
    return axios.patch(`${getApiRoot()}singha/repairs_room_update`, data, { headers: getToken() })
}


// โซน ส่วนกลางดีืแ

function getZoneCommon(floorNo , noWarranty) { return axios.get(`${getApiRoot()}singha/get_facilty`, { headers: getToken(), params: { floorNo: floorNo , noWarranty : noWarranty } }) }
function get_facilty_detail(unitCode) { return axios.get(`${getApiRoot()}singha/get_facilty_detail`, { headers: getToken(), params: { unitCode: unitCode } }) }

//จำนวนชั้น 
function get_number_of_floor() { return axios.get(`${getApiRoot()}singha/get_number_of_floor`, { headers: getToken(), }) }


function getReport(data) {
    return axios.get(`${getApiRoot()}singha/repairs_room_report`, {
        headers: getToken(),
        params: {
            index_first: data.index_first,
            index_last: data.index_last,
            pageSize: data.pageSize,
            page: data.page,
            insure_status_choices: data.insure_status_choices,
            repair_type: data.repair_type,
            start_date: data.start_date,
            end_date: data.end_date,
            search: data.search,
            working_type: data.working_type
        }
    })
}


//TAG 
function postNewTag(data) {
    return axios.post(`${getApiRoot()}singha/tag_global`, data, { headers: getToken() })
}

function getListTag() {
    return axios.get(`${getApiRoot()}singha/tag_global`, { headers: getToken() })
}

function postTagToRepairRoom(data) {
    return axios.post(`${getApiRoot()}singha/tag_repairs_room`, data, { headers: getToken() })
}

function removeTagFromRepairRoom(data) {
    return axios.patch(`${getApiRoot()}singha/tag_repairs_room`, data, { headers: getToken() })
}

function removeTagFromGlobal(data) {
    return axios.patch(`${getApiRoot()}singha/tag_global`, data, { headers: getToken() })
}
//ENDTAG


//CHAT
function readChat(data) {
    return axios.post(`${getApiRoot()}singha/read_message`,data ,{
        headers: getToken()
    })
}

function getChat(data) {
    return axios.get(`${getApiRoot()}singha/send_message`, {
        headers: getToken(),
        params: {
            repairsRoomId64: data.repairsRoomId64
        }
    })
}

function postChatMessage(data) {
    return axios.post(`${getApiRoot()}singha/send_message`, data, { headers: getToken() })
}
//END CHAT



export default {
    getQR, getassetAll,
    getRepairs_setting, postRepairs_setting,
    getrepairs_room_view,
    getRepairs_room_worksheet, patchRepairs_room_worksheet,
    getstaff_technician, postrepairs_room_view,
    get_task_type_service, get_task_detail, get_task_detail_note,
    get_unit_detail, POSTrepairs_room_view,
    patchrepairs_room_view, patchrepairs_room_update,
    getZoneCommon, get_facilty_detail,
    get_number_of_floor, getReport,
    postNewTag, getChat, postChatMessage,readChat,
    getListTag, postTagToRepairRoom, removeTagFromRepairRoom, removeTagFromGlobal,
    repairs_room_work_update , approveStatus , getrepairs_room_viewBYId,
    getrepairs_room_view1
}