import {graphql} from "babel-plugin-relay/macro";

const queryAllChartOfAccountBudget = graphql`
    query queryAllChartOfAccountBudgetQuery($issuedDate: Date, $lastDate: Date, $productAndServiceId: String){
        allChartOfAccountBudget(issuedDate: $issuedDate,lastDate: $lastDate, productAndServiceId: $productAndServiceId){
            edges{
                node{
                    totalBudget
                    chartOfAccount{
                        id 
                        name 
                        nameEn 
                        chartOfAccountCode 
                    }
                    summaryYear
                }
            }
        } 
    }
`

export default queryAllChartOfAccountBudget;