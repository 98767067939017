import React, { Component } from "react";
import FixRequestStatusAndTypechart from "./FixRequestStatusAndTypechart";
import FixRequestTechnician from "./fixRequestTechnician";
import FixRequestLinechart from "./fixRequestLinechart";
import FixRequestTechnicianJobTimechart from "./fixRequestBarchart";


import i18next from 'i18next';
class FixRequestDashbroadChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div>
          {this.props.allFixRequestData.dataFixStatus?.length > 0 ? 
          (
            <div>
                <FixRequestStatusAndTypechart 
                  dataStatus = {this.props.allFixRequestData.dataFixStatus}
                  dataFixWorkingType = {this.props.allFixRequestData.dataFixWorkingType}      
                />
                <FixRequestTechnician 
                  dataFixProjectStaff = {this.props.allFixRequestData.dataFixProjectStaff}
                  dataFixProjectStaffTime = {this.props.allFixRequestData.dataFixProjectStaffTime}
                />
                <FixRequestTechnicianJobTimechart 
                  dataFixProjectStaff = {this.props.allFixRequestData.dataFixProjectStaff}
                  dataFixProjectStaffDay = {this.props.allFixRequestData.dataFixProjectStaffDay}
                />
                <FixRequestLinechart 
                  dataFixFinishJob = {this.props.allFixRequestData.dataFixFinishJob}
                />
            </div>
          )
          :
          (
            <div className="ml-8">
              {i18next.t("fix_request:There is no data at the selected time period")}
            </div>
          )
        }
        </div>
    );
  }
}

export default FixRequestDashbroadChart;
