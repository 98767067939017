import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import CreditCardListTable from "./creditCardListTable";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import ModalSetting from "./modalSetting"
import _ from "lodash";


const query = graphql`
    query creditCardListAllQuery{
        creditCardDepositGroups{
            edges{
                node{
                    id
                    docNumber
                    depositDate
                    description
                    bank{
                        bankName
                        accountNumber
                    }
                    depositTotal
                    feeTotal
                }
            }
            totalCount
        }
        selfProject {
            timeZone
        }
    }
`;

class CreditCardListAll extends Component {
    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="invoice-list">

                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('credit_card:credit_card')+ " "+"(CL)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                            <div className="col">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_credit_card_create'}) &&
                                <Link to="/accounting/finance/credit-card/create/select-receive">
                                    <button type="button" className="btn btn-primary add ml-1" style={{float: "right"}}>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                        <Translation>
                                            {
                                                t => <span>{t('credit_card:add_credit_card')}</span>
                                            }
                                        </Translation>
                                    </button>
                                </Link>
                                }
                                {(_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_credit_card_create'}))  &&
                                <ModalSetting/>
                                }
                            </div>
                        </div>
                        <div className="content-inner">
                            <CreditCardListTable query={query} />
                        </div>

                    </div>
                </WrapperContent>

            </Wrapper>

        )
    }
}

export default CreditCardListAll;