/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateParcelInput = {|
  id?: ?string,
  idIn?: ?$ReadOnlyArray<?string>,
  status: string,
  receiverName?: ?string,
  pickupDate?: ?any,
  deleteRemark?: ?string,
  clientMutationId?: ?string,
|};
export type parcelListMutationVariables = {|
  input: UpdateParcelInput
|};
export type parcelListMutationResponse = {|
  +updateParcel: ?{|
    +ok: ?boolean
  |}
|};
export type parcelListMutation = {|
  variables: parcelListMutationVariables,
  response: parcelListMutationResponse,
|};
*/


/*
mutation parcelListMutation(
  $input: UpdateParcelInput!
) {
  updateParcel(input: $input) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateParcelPayload",
    "kind": "LinkedField",
    "name": "updateParcel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "parcelListMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "parcelListMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "543ad89f7ad2bf251208a958cd90d8e2",
    "id": null,
    "metadata": {},
    "name": "parcelListMutation",
    "operationKind": "mutation",
    "text": "mutation parcelListMutation(\n  $input: UpdateParcelInput!\n) {\n  updateParcel(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0341eb7c3932dfced1b8f5cc49ec8018';

module.exports = node;
