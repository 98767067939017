/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type OtherReceivePayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type OtherReceiveStatus = "PAID" | "VOID" | "%future added value";
export type otherReceiverListQueryVariables = {|
  status?: ?string,
  search?: ?string,
  first?: ?number,
  last?: ?number,
  issuedDate_Gte?: ?any,
  issuedDate_Lte?: ?any,
|};
export type otherReceiverListQueryResponse = {|
  +allOtherReceive: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +total: number,
        +issuedDate: any,
        +creator: ?string,
        +status: OtherReceiveStatus,
        +remark: string,
        +voidRemark: ?string,
        +refNumber: ?string,
        +updated: any,
        +payGroup: OtherReceivePayGroup,
        +firstName: string,
        +lastName: string,
        +contact: ?{|
          +id: string,
          +refNumber: string,
          +name: string,
          +firstName: string,
          +lastName: string,
          +typeOfContact: ContactTypeOfContact,
          +registeredName: string,
          +registeredAddress: string,
          +registeredCountry: string,
          +registeredProvince: string,
          +registeredCity: string,
          +registeredDistrict: string,
          +registeredPostalCode: string,
        |},
        +unknownContact: ?string,
        +otherReceiveTransaction: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +productAndService: ?{|
                +id: string
              |},
              +chartOfAccount: ?{|
                +id: string
              |},
              +description: string,
              +total: ?number,
            |}
          |}>
        |},
      |}
    |}>,
  |}
|};
export type otherReceiverListQuery = {|
  variables: otherReceiverListQueryVariables,
  response: otherReceiverListQueryResponse,
|};
*/


/*
query otherReceiverListQuery(
  $status: String
  $search: String
  $first: Int
  $last: Int
  $issuedDate_Gte: Date
  $issuedDate_Lte: Date
) {
  allOtherReceive(status: $status, search: $search, issuedDate_Gte: $issuedDate_Gte, issuedDate_Lte: $issuedDate_Lte, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        docNumber
        total
        issuedDate
        creator
        status
        remark
        voidRemark
        refNumber
        updated
        payGroup
        firstName
        lastName
        contact {
          id
          refNumber
          name
          firstName
          lastName
          typeOfContact
          registeredName
          registeredAddress
          registeredCountry
          registeredProvince
          registeredCity
          registeredDistrict
          registeredPostalCode
        }
        unknownContact
        otherReceiveTransaction {
          edges {
            node {
              id
              productAndService {
                id
              }
              chartOfAccount {
                id
              }
              description
              total
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issuedDate_Gte"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issuedDate_Lte"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v11 = [
  (v6/*: any*/)
],
v12 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "issuedDate_Gte",
        "variableName": "issuedDate_Gte"
      },
      {
        "kind": "Variable",
        "name": "issuedDate_Lte",
        "variableName": "issuedDate_Lte"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "OtherReceiveNodeConnection",
    "kind": "LinkedField",
    "name": "allOtherReceive",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OtherReceiveNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OtherReceiveNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "issuedDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remark",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voidRemark",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "payGroup",
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "typeOfContact",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredCountry",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredPostalCode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unknownContact",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OtherReceiveTransactionNodeConnection",
                "kind": "LinkedField",
                "name": "otherReceiveTransaction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OtherReceiveTransactionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OtherReceiveTransactionNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductAndServiceNode",
                            "kind": "LinkedField",
                            "name": "productAndService",
                            "plural": false,
                            "selections": (v11/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ChartOfAccountNode",
                            "kind": "LinkedField",
                            "name": "chartOfAccount",
                            "plural": false,
                            "selections": (v11/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "otherReceiverListQuery",
    "selections": (v12/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "otherReceiverListQuery",
    "selections": (v12/*: any*/)
  },
  "params": {
    "cacheID": "c65e3c58a696f0a2cc080418a8aed728",
    "id": null,
    "metadata": {},
    "name": "otherReceiverListQuery",
    "operationKind": "query",
    "text": "query otherReceiverListQuery(\n  $status: String\n  $search: String\n  $first: Int\n  $last: Int\n  $issuedDate_Gte: Date\n  $issuedDate_Lte: Date\n) {\n  allOtherReceive(status: $status, search: $search, issuedDate_Gte: $issuedDate_Gte, issuedDate_Lte: $issuedDate_Lte, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        total\n        issuedDate\n        creator\n        status\n        remark\n        voidRemark\n        refNumber\n        updated\n        payGroup\n        firstName\n        lastName\n        contact {\n          id\n          refNumber\n          name\n          firstName\n          lastName\n          typeOfContact\n          registeredName\n          registeredAddress\n          registeredCountry\n          registeredProvince\n          registeredCity\n          registeredDistrict\n          registeredPostalCode\n        }\n        unknownContact\n        otherReceiveTransaction {\n          edges {\n            node {\n              id\n              productAndService {\n                id\n              }\n              chartOfAccount {\n                id\n              }\n              description\n              total\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b583b27c861884e62882f5f58e6e105b';

module.exports = node;
