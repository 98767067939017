import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Translation } from "react-i18next";
import './settingWalkin.scss'

class WrapperSettingWalkinQRcode extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
    }

    print() {
        window.print();
    }

    render() {

        let qrCode = this.props.location.state

        // let path = getApiRoot();
        
        let path = window.location.protocol + '//' +window.location.hostname+ "/";



        return (
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Walkin QRcode</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
               
               <div className="text-center" id="wrapperSettingWalkinQRcode">
               <Translation>
                        { t =>
                        <div>
                        <h4 className="mt-8 mb-5">{t("settingWalking:Please print this QR Code and provide it to the visitor for scanning before entering the project")}</h4>
                            <div style={{backgroundColor:'#ffffff',width:'35%',margin:'auto'}}>
                                <h2 className="pt-8">{t("settingWalking:Scan to enter the project for visitors")}</h2>
                                <p>{t("settingWalking:QR Code for entering the project")}</p>
                                <QRCode 
                                    value=
                                    {
                                        path + "vms/vms-accept-qrcode/" + qrCode
                                    }
                                    size={250}
                                    logoWidth={50}
                                    logoHeight={50}
                                    logoImage='/images/logo/logo.svg'
                                />
                                <div  className="text-center" style={{width:'80%',margin:'auto'}}>
                                    <h5 className="p-4">
                                        {t("settingWalking:Please ensure that you complete the information before leaving the project")}
                                    <br/>
                                    <span className="text-red">* {t("settingWalking:The project reserves the right to prevent visitors from leaving in case of incomplete registration")}
                                    </span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                     }
                     </Translation>
               </div>
             

            </React.Fragment>
        );
    }
}

export default WrapperSettingWalkinQRcode;
