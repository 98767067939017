import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';

class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <Translation>
                        {t=>
                        <ul>
                            <li>
                                <NavLink to="/accounting/income/other_receiver/list/all">
                                    {t("other_received:All")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounting/income/other_receiver/list/paid">
                                    {t("other_received:Paid")}
                                </NavLink>
                            </li>
                            <li>
                                 <NavLink to="/accounting/income/other_receiver/list/void">
                                    {t("other_received:List of cancellation")}
                                 </NavLink>
                            </li>
                        </ul>}
                    </Translation>
                </div>
            </div>
        )
    }
}

export default Navigation;