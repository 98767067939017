import React from 'react';
import {QueryRenderer} from 'react-relay';
import {Translation} from "react-i18next";
import environment from "../../../env/environment";
import {Link} from "react-router-dom";
import ComponentPagination from "../../../libs/componentPagination";
import Pagination from "../../../libs/newPagination";
import BankCreateModal from "./bankCreateModal"
import BankEditModal from "./bankEditModal"
import _ from "lodash"
import Loading from "../../../libs/loading"

class ProductListTable extends ComponentPagination {
    constructor(props){
        super(props);
        this.state.re_query = false;
        this.refreshQuery = this.refreshQuery.bind(this);
    }
    checkAccountType(account_type) {
        return account_type.toLowerCase()
    }
    refreshQuery(){
        this.setState({re_query: !this.state.re_query});
    }

    render() {
        return (
            <React.Fragment>
                <div className="row justify-content-between">
                    <div className="col">
                        <h3 className="mb-4">
                            <Link to="/accounting">
                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                     alt="back" className="back"/>
                            </Link>
                            <Translation>
                                {
                                    t => <span>{t('finance:bank')+ " "+"(BSV)"}</span>
                                }
                            </Translation>
                        </h3>
                    </div>
                    <div className="col text-right">
                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_bank_create'}) &&
                        <BankCreateModal refreshQuery={this.refreshQuery} changePage={this.goFirst}/>
                        }
                    </div>
                </div>
                <div className="content-inner">
                    <QueryRenderer
                        environment={environment}
                        query={this.props.query}
                        cacheConfig={{use_cache: false}}
                        variables={{first: this.state.first, last: this.state.last, re_query: this.state.re_query}}
                        render={({error, props}) => {
                            if (error) {
                                return <div className="alert alert-danger" role="alert">{error.message}</div>;
                            } else if (props) {

                                return <React.Fragment>

                                    <div className="table-responsive fade-up card" id="product-list-table">

                                        <table className="table table-hover">
                                            <thead className="thead-light">
                                            <tr>
                                                <th>
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:code')}</span>
                                                        }
                                                    </Translation>
                                                </th>
                                                <th className="text-left">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:account_no')}</span>
                                                        }
                                                    </Translation>
                                                </th>
                                                <th className="text-left">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:bank_name')}</span>
                                                        }
                                                    </Translation>
                                                </th>
                                                <th className="text-left">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:bank_branch')}</span>
                                                        }
                                                    </Translation>
                                                </th>
                                                <th className="text-left">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:account_name')}</span>
                                                        }
                                                    </Translation>
                                                </th>
                                                <th className="text-left">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:account name (English)')}</span>
                                                        }
                                                    </Translation>
                                                </th>
                                                <th className="text-left">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:account_type')}</span>
                                                        }
                                                    </Translation>
                                                </th>
                                                <th className="text-center">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:receive_payment')}</span>
                                                        }
                                                    </Translation>
                                                </th>
                                                <th className="text-center">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:make_payment')}</span>
                                                        }
                                                    </Translation>
                                                </th>
                                                <th className="text-center">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:Show in Silverman application')}</span>
                                                        }
                                                    </Translation>
                                                </th>
                                                <th className="text-center">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:cross_billing')}</span>
                                                        }
                                                    </Translation>
                                                </th>
                                                <th className="text-center">
                                                    {/* <Translation>
                                                        {
                                                            t => <span>{t('finance:cross_billing')}</span>
                                                        }
                                                    </Translation> */}
                                                    PromptPay
                                                </th>
                                                <th className="text-center">
                                                    <Translation>
                                                        {
                                                            t => <span>{t('finance:status')}</span>
                                                        }
                                                    </Translation>
                                                    
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {props.bankAccountViewer.allBankAccount.edges.map((bank) => (
                                                <tr key={bank.node.id}>
                                                    <td>
                                                        <BankEditModal id={bank.node.id} code={bank.node.docNumber}
                                                                       refreshQuery={this.refreshQuery}/>
                                                    </td>
                                                    <td>{bank.node.accountNumber}</td>
                                                    <td>{bank.node.bankName}</td>
                                                    <td>{bank.node.branch}</td>
                                                    <td>{bank.node.accountName}</td>
                                                    <td>{bank.node.accountNameEn}</td>
                                                    <td>
                                                        <Translation>
                                                            {
                                                                t => <span>{t('finance:'+this.checkAccountType(bank.node.accountType))}</span>
                                                            }
                                                        </Translation>
                                                    </td>
                                                    <td className="text-center">{bank.node.receivePayment ?
                                                        <p style={{color: 'green'}}>&#10003;</p> :
                                                        <p style={{color: 'red'}}>&#10005;</p>}</td>
                                                    <td className="text-center">{bank.node.makePayment ?
                                                        <p style={{color: 'green'}}>&#10003;</p> :
                                                        <p style={{color: 'red'}}>&#10005;</p>}</td>
                                                    <td className="text-center" width={140} >{bank.node.mobilePayment ?
                                                        <p style={{color: 'green'}}>&#10003;</p> :
                                                        <p style={{color: 'red'}}>&#10005;</p>}</td>
                                                    <td className="text-center">{bank.node.crossBilling ?
                                                        <p style={{color: 'green'}}>&#10003;</p> :
                                                        <p style={{color: 'red'}}>&#10005;</p>}</td>
                                                    <td className="text-center">{bank.node.prompay ?
                                                        <p style={{color: 'green'}}>&#10003;</p> :
                                                        <p style={{color: 'red'}}>&#10005;</p>}</td>
                                                    <td className="text-center">
                                                        {bank.node.status ?
                                                            <button type="button"
                                                                    className="btn btn-success active">
                                                                <Translation>
                                                                    {
                                                                        t => <span>{t('finance:open')}</span>
                                                                    }
                                                                </Translation>
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-danger active">
                                                                <Translation>
                                                                    {
                                                                        t => <span>{t('finance:close')}</span>
                                                                    }
                                                                </Translation>
                                                            </button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row">
                                        <Pagination changePage={this.changePage} first={this.state.first}
                                                    last={this.state.last}
                                                    totalCount={props.bankAccountViewer.allBankAccount.totalCount}/>
                                    </div>

                                </React.Fragment>
                            }
                            return <Loading/>
                        }}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default ProductListTable;
