import React, { Component } from 'react';
import Swal from "sweetalert2";
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import Lightbox from "react-images";
import { Translation } from "react-i18next";
import Navigation from './navigation'
import API from '../../api/silverGraund'
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
import Loading from "../../libs/loading";
import _ from 'lodash';
import SettingTopMenuNavigation from '../settingTopMenuNavigation';
import i18next from 'i18next';
import BackButtonIcon from '../../components/BackBtn/indexBack';
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import ExportSvmGuardManageReportPDF from './export/svmGuardManageReportPDF';
import ExportsvmGuardManageReportExcel from './export/svmGuardManageReportExcel';
import jwtDecode from 'jwt-decode';
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";

let search = ''
let startDate = ''
let endDate = ''
let typePerson = ""
let objectCar = ""

const manageVisitor = graphql`
query manageVisitorQuery{
    selfProject{
        name
        address
        logo
    }
}`;

class VisitMange extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            allRow: 4,
            LimitStRow: 1,
            LimitEnRow: 4,
            overTime: false,
            typePerson: '',

            flg: "",
            flgTime: "",
            flgService: "",
            status: "",
            textSearch: "",
            num_record: 0,
            first: "",
            last: 10,
            perPage: 10,
            page: 0,
            pages: 0,
            startDate: "",
            endDate: "",

            imgLoad: "",
            isImgFlase: false,
            loading: true,
            checkList: [],
            check_all: false,
            checkRecord: [],
            checkRefTrans: [],
            checkRefNumber: [],
            reload: false,

            image_light_box: "",
            open_light_box: false,
            loadingData: true,
        }
        this.SearchChange = this.SearchChange.bind(this)
        this.onSreach = this.onSreach.bind(this)
        // this.setStates = this.setStates.bind(this)
        this.toggleLightBox = this.toggleLightBox.bind(this);
    }
    componentWillMount() {
        try {
            this.getDataVisitor();
        } catch (err) {
            Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error')
        }

    }

    getDataVisitor() {
        API.getcarVms().then((res) => {
            if (res.data.data) {
                let dataList = []
                dataList = res.data.data
                // dataList.map((n) => ({
                //     ...n,
                //     overTimess: n.status === "park" && timeNow > new Date(n.endDate).getTime() ? true : false
                // }))

                const { last } = this.state;
                let nameToken = jwtDecode(localStorage.getItem("token"))
                fetchQuery(environment, manageVisitor).then((response) => {
                    if (response?.selfProject) {
                        this.setState({
                            data: dataList, allRow: res.data.allRow,
                            num_record: res.data.data.allRow,
                            loading: false,
                            pages: Math.ceil(res.data.data.length / last),
                            loadingData: false,
                            namePrint: nameToken.first_name + " " + nameToken.last_name,
                            project_name: response?.selfProject?.name,
                        });
                    }
                })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error')).finally(() => {
            this.setState({ loading: false })
        })
    }

    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({ page });
    };

    toggleLightBox(open_light_box, image = "") {
        this.setState({ open_light_box: open_light_box, image_light_box: image });
    }

    onError(imgData) {
        if (imgData !== "" && imgData !== null) {
            this.setState({
                imgLoad: imgData
            });
        } else {
            this.setState({
                imgLoad: "/images/icons/select-photo.png"
                // imgLoad: "/images/carUploadRgis/20210101121155.png"
            });
        }

    }

    funCSubstring(value) {
        let idCard = value !== "" && value !== null ? value.substring(0, 1) + "-XXXX-XXXXX-" + value.substring(10, 12) + "-" + value.substring(12) : "-"
        return idCard
    }

    onSreach() {

        API.getSearchVisitmange(search, startDate, endDate, typePerson, objectCar).then((res) => {
            if (res.data.data) {
                let datas = []
                datas = res.data.data
                this.setState({ data: datas })
            }
        }, () => Swal.fire(i18next.t("manageVisitor:Unable to search"), i18next.t("manageVisitor:Unable to search at this time"), 'error'))
    }

    SearchChange(e) {
        if (e.target.name === "search") {
            search = e.target.value
        } else if (e.target.name.includes("startDate")) {
            startDate = format(e.target.value, "YYYY-MM-DD")
        } else if (e.target.name === "endDate") {
            endDate = format(e.target.value, "YYYY-MM-DD")
        } else if (e.target.name === "searchTypePerson") {
            typePerson = e.target.value
            this.setState({ typePerson: e.target.value })
        } else if (e.target.name === "searchObjectCar") {
            objectCar = e.target.value
        }


        if (e.key === "Enter") {
            API.getSearchVisitmange(search, startDate, endDate, typePerson, objectCar).then((res) => {
                if (res.data.data) {
                    let datas = []
                    datas = res.data.data
                    this.setState({ data: datas })
                }
            }, () => Swal.fire(i18next.t("manageVisitor:Unable to search"), i18next.t("manageVisitor:Unable to search at this time"), 'error'))
        }
    }

    getList(props) {
        let checklist = [...this.state.checkList];
        let current_checkListdoc = null
        if (this.state.checkListdoc) current_checkListdoc = [...this.state.checkListdoc];

        let check_all = false;

        if (!this.state.check_all) check_all = true;

        if ((check_all && (checklist.length === props.length)) || !check_all) {
            checklist = [];
            if (this.state.checkListdoc) current_checkListdoc = [];
        } else {
            if (checklist.length !== 0) {
                checklist = [];
                current_checkListdoc = [];
            }
            props.forEach((item) => {
                if (item.status !== 'cancel') {
                    checklist.push(item.carTransactionId);
                    if (this.state.checkListdoc) current_checkListdoc.push((item.node.docNumber ? item.node.docNumber : 'null'));
                }
            });
        }
        if (this.state.checkListdoc) {
            this.setState({ checkList: checklist, check_all: check_all, checkListdoc: current_checkListdoc })
        }
        else {
            this.setState({ checkList: checklist, check_all: check_all })
        }

    }

    appendToCheckList(id) {
        //id
        let current_checklist = [...this.state.checkList];
        if (current_checklist.includes(id)) {
            //out
            current_checklist.splice(current_checklist.indexOf(id), 1)
        } else {
            //in
            current_checklist.push(id);
        }
        this.setState({ checkList: current_checklist })
    }

    checkList(id) {
        return this.state.checkList.includes(id);
    }

    cancelSelected() {
        if (this.state.checkList.length > 0) {
            Swal.fire({
                title: `${i18next.t("manageVisitor:Do you want to confirm the cancellation of this list")}?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: i18next.t("Allaction:Yes"),
                cancelButtonText: i18next.t("Allaction:cancel"),
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: `${i18next.t("manageVisitor:Comments")}`,
                        html: `<input type="text" class="swal2-input" id="cancelRemark">`,
                        showCancelButton: true,
                        confirmButtonText: i18next.t("Allaction:Yes"),
                        cancelButtonText: i18next.t("Allaction:cancel"),
                        reverseButtons: true,
                        preConfirm: () => {
                            let cancelRemark = Swal.getPopup().querySelector('#cancelRemark').value
                            return { cancelRemark: cancelRemark }
                        }
                    }).then((result) => {
                        if (result.value) {
                            let data = JSON.stringify({
                                carTransactionId: this.state.checkList,
                                remark: `${result.value.cancelRemark}`
                            });
                            try {
                                API.cancelVisitorVMS(data).then((res) => {
                                    if (res.data.statusMessage === "SUCCESS") {
                                        Swal.fire(i18next.t("Allaction:Saved Successfully"), i18next.t("Allaction:Canceled Successfully"), 'success').then(() => {
                                            this.setState({ reload: true })
                                        })
                                    }
                                }, () => {
                                    Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), '', 'error')
                                })
                            } catch (error) {
                                Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), '', 'error')
                            }
                        } else {
                            this.setState({ loading: false });
                        }
                    })
                }
            })
        }
    }

    render() {
        if (this.state.reload) {
            window.location.reload();
        }
        let sta = this.props.match.params.tabName
        const { data, page, pages, last } =
            this.state;
        let items = []
        let lists = []
        let pageA = 0
        if (sta === 'coming') {
            lists = data.filter((n) => n.status === 'coming')
            items = lists.slice(page * last, (page + 1) * last);
            pageA = Math.ceil(lists.length / last)

        } else if (sta === 'park') {

            lists = data.filter((n) => n.status === 'park')
            items = lists.slice(page * last, (page + 1) * last);
            pageA = Math.ceil(lists.length / last)
        } else if (sta === 'success') {

            lists = data.filter((n) => n.status === 'success')
            items = lists.slice(page * last, (page + 1) * last);
            pageA = Math.ceil(lists.length / last)
        } else if (sta === 'over') {

            lists = data.filter((n) => n.status === 'over')
            items = lists.slice(page * last, (page + 1) * last);
            pageA = Math.ceil(lists.length / last)
        }
        else if (sta === 'cancel') {

            lists = data.filter((n) => n.status === 'cancel' || n.status !== "coming" && n.status !== "park" && n.status !== "success" && n.status !== "over")
            items = lists.slice(page * last, (page + 1) * last);
            pageA = Math.ceil(lists.length / last)
        } else if (sta === 'all') {
            lists = data
            items = data.slice(page * last, (page + 1) * last);
            pageA = Math.ceil(data.length / last)

        }

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <SettingTopMenuNavigation mini={true} />
                    <Translation>
                        {
                            t =>
                                <div className="container-fluid box colorUse" id="silvermanguard">
                                    <BackButtonIcon
                                        LinkBack={this.props?.location?.state?.backLink ? this.props?.location?.state?.backLink : "/silvermanguard"}
                                        LinkText={t("graud:visitormanage")+" "+"(VP)"}
                                        printdocLinkChk={true}
                                        // addLinkChk={_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'vms_manage_visitor_create' }) ? true : false}
                                        addLinkButton={_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'vms_manage_visitor_create' }) ? "/silvermanguard/visitormanage/add" : ""}
                                        addLinkText={`+ ` + t("silvermanguard:createViisitor")}
                                        btnCustomExportIs={true}
                                        exportFileProps={
                                            !this.state.loadingData ? <React.Fragment>
                                                <button type="button" className="h46 btn-primary-scale-outline dropdown-toggle btnPrint" data-toggle="dropdown">
                                                    <Translation>{t => t("PageList:Print")}</Translation>
                                                </button>
                                                <div className={`dropdown-menu`} style={{ left: '68px' }}>

                                                    {/* ปิดไว้ เผื่อเค้าอยากได้ PDF อีกครั้ง ที่ตัดออกเพราะเค้าไม่อยากตัดบางฟิลด์ออก
                                                    <ExportSvmGuardManageReportPDF
                                                        project_name={this.state.project_name}
                                                        dataExport={lists}
                                                        namePrint={this.state.namePrint}
                                                    /> */}
                                                    <ExportsvmGuardManageReportExcel
                                                        state={this.state}
                                                        namePrint={this.state.namePrint}
                                                        dataExport={lists}
                                                    />
                                                </div>
                                            </React.Fragment> :
                                                <button className="btn btn-outline bg-1567B4 h46">
                                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                                    <Translation>{t => t('meter:Preparing information')}</Translation>
                                                </button>
                                        }
                                    />
                                    <Navigation />

                                    {
                                        this.state.loading == false &&
                                        <React.Fragment>
                                            <div className='row'>
                                                <div className='col-12 text-right' style={{ display: 'inline' }}>
                                                    <div className='floatRight'>
                                                        <input type="text" name='search' className='form-control searchBox' placeholder={t("silvermanguard:search")}
                                                            onKeyUp={this.SearchChange} onChange={this.SearchChange} />
                                                        <span className='spanINBox' onClick={this.onSreach}>
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} />
                                                        </span>
                                                    </div>
                                                    <div className='floatRight'>
                                                        {
                                                            this.state.typePerson === 'contact' &&
                                                            <select
                                                                name='searchObjectCar'
                                                                className='form-control searchDate'
                                                                onChange={this.SearchChange}>
                                                                <option value={""}>{i18next.t("visitorManage:All")}</option>
                                                                <option value={"เยี่ยมชมโครงการล่วงหน้า"}>{i18next.t("visitorManage:Visit the project in advance")}</option>
                                                                <option value={"เยี่ยมชมโครงการไม่ได้นัดหมาย"}>{i18next.t("visitorManage:Visit the project without an appointment")}</option>
                                                                <option value={"ผู้รับเหมา"}>{i18next.t("visitorManage:Contractor")}</option>
                                                                <option value={"ติดต่อเจ้าหน้าที่"}>{i18next.t("visitorManage:Contact staff")}</option>
                                                                <option value={"other"}>{i18next.t("visitorManage:Other")}</option>
                                                            </select>
                                                        }
                                                    </div>
                                                    <div className='floatRight'>
                                                        {/* <label className='floatLeft labelInline'>ประเภทบุคคล: &nbsp;&nbsp;</label> */}
                                                        <select
                                                            name='searchTypePerson'
                                                            className='form-control searchDate'
                                                            onChange={this.SearchChange}>
                                                            <option value={""}>{i18next.t("visitorManage:All types")}</option>
                                                            <option value={"tenant"}>{i18next.t("visitorManage:Resident / Tenant")}</option>
                                                            <option value={"contact"}>{i18next.t("visitorManage:Contact with sale office")}</option>
                                                            <option value={"appointment"}>{i18next.t("visitorManage:Make an appointment in advance")}</option>
                                                            <option value={"person"}>{i18next.t("visitorManage:Guest")}</option>
                                                        </select>
                                                    </div>

                                                    <div className='floatRight'>
                                                        <label className='floatLeft labelInline'>{t("silvermanguard:date")}</label>
                                                        <input type="date" name='startDate' className='floatLeft form-control searchDate'
                                                            placeholder='01/07/2021' onChange={this.SearchChange} />

                                                        <label className='floatLeft labelInline'>{t("silvermanguard:dateTo")}</label>
                                                        <input type="date" name='endDate' className='floatLeft form-control searchDate'
                                                            placeholder='01/07/2021' onChange={this.SearchChange} />

                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            {sta !== 'cancel' &&
                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'vms_manage_visitor_delete' }) &&
                                                <button
                                                    type="button"
                                                    style={{ float: 'left' }}
                                                    className="btn btn-outline-secondary mb-2"
                                                    onClick={() => this.cancelSelected()}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + "/images/icons/delete.png"
                                                        }
                                                        alt="delete"
                                                    />
                                                </button>
                                            }
                                            <div className="table-responsive fade-up card" style={{ display: 'contents' }}>
                                                <table className="table table-hover">
                                                    <thead className="thead-light">
                                                        <tr className='text-center'>
                                                            {(sta !== "cancel") &&
                                                                <th className="text-center head">
                                                                    <input type="checkbox"
                                                                        checked={this.state.check_all}
                                                                        onChange={() => this.getList(this.state.data)}
                                                                    />
                                                                </th>}
                                                            <th>{t("silvermanguard:codeVisitor")}</th>
                                                            <th>{t("silvermanguard:dateSt")}</th>
                                                            <th>{t("silvermanguard:dateEn")}</th>
                                                            <th>{t("silvermanguard:nameVisitor")}</th>
                                                            <th>{t("silvermanguard:idCard")}</th>
                                                            <th>{t("silvermanguard:typePerson")}</th>
                                                            {
                                                                sta !== "coming" &&
                                                                <th>{t("silvermanguard:carImageIn")}</th>
                                                            }
                                                            {
                                                                sta !== "coming" &&
                                                                <th>{t("silvermanguard:carImageOut")}</th>
                                                            }
                                                            <th>{t("silvermanguard:typeRole")}</th>
                                                            <th>{t("silvermanguard:tool")}</th>
                                                            <th>{t("silvermanguard:createBy")}</th>
                                                            {
                                                                sta === 'all' &&
                                                                <th>{t("silvermanguard:status")}</th>
                                                            }
                                                            {sta === "cancel" && <th>{t("silvermanguard:remark")}</th>}

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            items.map((n) => {
                                                                let timeNow = new Date().getTime()
                                                                let overTimes = timeNow > new Date(`${n.endDate}`).getTime() ? true : false
                                                                return (
                                                                    <tr className='text-center' key={n.carTransactionId}>
                                                                        {
                                                                            (sta !== "cancel") &&
                                                                            (n.status === 'cancel' ?
                                                                                <td className="text-center" />
                                                                                :
                                                                                <td className="text-center">
                                                                                    <input type="checkbox"
                                                                                        onChange={() => this.appendToCheckList(n.carTransactionId)}
                                                                                        checked={this.checkList(n.carTransactionId)}
                                                                                    />
                                                                                </td>)
                                                                        }
                                                                        {
                                                                            // n.status === 'cancel' ?
                                                                            //     <td>{n.codeVisit}</td>
                                                                            //     :
                                                                            <td><Link to={"/silvermanguard/visitormanage/detail/" + n.codeVisit}>{n.codeVisit}</Link></td>
                                                                        }
                                                                        <td>{n.dateUsingSt}-{n.timeUsingSt}</td>
                                                                        <td>{n.dateCheckOut}-{n.timeCheckOut}</td>
                                                                        <td>{n.fullnameVisit || "-"}</td>
                                                                        <td>{this.funCSubstring(n.idCard)}</td>
                                                                        <td>{
                                                                            n.typePerson == "person" ? t("silvermanguard:person") :
                                                                                n.typePerson == "contact" ? t("silvermanguard:contact") :
                                                                                    n.typePerson == "appointment" ? t("silvermanguard:appointment") : t("silvermanguard:tenant")}
                                                                        </td>
                                                                        {
                                                                            sta !== "coming" &&
                                                                            <td>
                                                                                {/* {
                                                                                    n.img !== "" &&
                                                                                    <div className="tooltipsSpan">
                                                                                       <img src={process.env.PUBLIC_URL + this.state.imgLoad} style={{ width: '50px' }} />
                                                                                        <img src={n.img} style={{ width: '50px' }}  />
                                                                                        <img src={n.img}
                                                                                            className="back tooltiptext"
                                                                                            style={{ width: '50px' }}
                                                                                            onError={() => this.onError(n.img)} />                           
                                                                                    </div>

                                                                                    ||

                                                                                    n.img === "" &&
                                                                                    <img src={process.env.PUBLIC_URL + "/images/icons/select-photo.png"} style={{ width: '50px' }} />

                                                                                } */}

                                                                                <img
                                                                                    src={n.carImageIn ? n.carImageIn : process.env.PUBLIC_URL + "/images/icons/select-photo.png"}
                                                                                    alt="parcel"
                                                                                    className="img-fluid cursor parcel"
                                                                                    onClick={() =>
                                                                                        this.toggleLightBox(
                                                                                            true,
                                                                                            n.carImageIn ? n.carImageIn : process.env.PUBLIC_URL + "/images/icons/select-photo.png"
                                                                                        )
                                                                                    }
                                                                                />
                                                                                

                                                                            </td>

                                                                        }
                                                                         {
                                                                            sta !== "coming" &&
                                                                            <td>
                                                                                {/* {
                                                                                    n.img !== "" &&
                                                                                    <div className="tooltipsSpan">
                                                                                       <img src={process.env.PUBLIC_URL + this.state.imgLoad} style={{ width: '50px' }} />
                                                                                        <img src={n.img} style={{ width: '50px' }}  />
                                                                                        <img src={n.img}
                                                                                            className="back tooltiptext"
                                                                                            style={{ width: '50px' }}
                                                                                            onError={() => this.onError(n.img)} />                           
                                                                                    </div>

                                                                                    ||

                                                                                    n.img === "" &&
                                                                                    <img src={process.env.PUBLIC_URL + "/images/icons/select-photo.png"} style={{ width: '50px' }} />

                                                                                } */}

                                                                                <img
                                                                                    src={n.carImageOut ? n.carImageOut : process.env.PUBLIC_URL + "/images/icons/select-photo.png"}
                                                                                    alt="parcel"
                                                                                    className="img-fluid cursor parcel"
                                                                                    onClick={() =>
                                                                                        this.toggleLightBox(
                                                                                            true,
                                                                                            n.carImageOut ? n.carImageOut : process.env.PUBLIC_URL + "/images/icons/select-photo.png"
                                                                                        )
                                                                                    }
                                                                                />
                                                                                

                                                                            </td>

                                                                        }

                                                                        <td>{n.typeRole == "temp" ? t("silvermanguard:temp") : t("silvermanguard:perm")}</td>
                                                                        <td>{n.tool == "perm" ? 'LPR' : 'QR CODE'}</td>
                                                                        <td>{n.ceateBy !== null ? n.ceateBy : "Admin"}</td>
                                                                        {
                                                                            sta === 'all' &&
                                                                            <td>
                                                                                {
                                                                                    n.status === "coming" && !overTimes ?
                                                                                        <label className='badge badge-yellow'>{t("silvermanguard:coming")}</label> :
                                                                                        n.status === "over" ?
                                                                                            <label className='badge badge-danger'>{t("silvermanguard:overtime")}</label> :
                                                                                            n.status === "park" ?
                                                                                                <label className='badge badge-success'>{t("silvermanguard:park")}</label> :
                                                                                                n.status === "success" ?
                                                                                                    <label className='badge badge-secondary'>{t("silvermanguard:success")}</label> :
                                                                                                    <label className='badge badge-danger'>{t("silvermanguard:cancel")}</label>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        {sta === "cancel" && <td>{n.remark}</td>}

                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            items.length === 0 &&
                                                            <tr className='text-center'>
                                                                <td colSpan="11">{t("silvermanguard:notFound")}</td>
                                                            </tr>
                                                        }



                                                    </tbody>
                                                </table>
                                            </div>
                                            <hr />

                                            <div className='row'>
                                                <div className='col-6'>
                                                    <select className='page' onChange={(e) => this.setState({ last: parseInt(e.target.value) })}>
                                                        <option value="10">{t("silvermanguard:show")} 10 {t("silvermanguard:items")}</option>
                                                        <option value="20">{t("silvermanguard:show")} 20 {t("silvermanguard:items")}</option>
                                                        <option value="50">{t("silvermanguard:show")} 50 {t("silvermanguard:items")}</option>
                                                        <option value="100">{t("silvermanguard:show")} 100 {t("silvermanguard:items")}</option>
                                                    </select>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='pullright' style={{ marginTop: '22px' }}>{t("silvermanguard:listAll")} {lists.length} &nbsp;

                                                        {pages > 0 && (
                                                            <ReactPaginate style={{ marginTop: '-7px' }}
                                                                previousLabel={"previous"}
                                                                nextLabel={"next"}
                                                                breakLabel={
                                                                    <span className="btn page-link">...</span>
                                                                }
                                                                breakClassName={"break-me"}
                                                                pageClassName="page-item"
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={4}
                                                                previousClassName="sr-only"
                                                                containerClassName={"pagination float-right"}
                                                                subContainerClassName={"pagination-page"}
                                                                nextLinkClassName="sr-only"
                                                                pageLinkClassName="page-link"
                                                                activeClassName={"active"}
                                                                pageCount={pageA}
                                                                onPageChange={this.handlePageClick}
                                                            />
                                                        )}

                                                    </div>


                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }

                                    {
                                        this.state.loading == true && <Loading />
                                    }

                                    <Lightbox
                                        images={[{ src: this.state.image_light_box }]}
                                        onClose={() => this.toggleLightBox(false)}
                                        isOpen={this.state.open_light_box}
                                        backdropClosesModal={true}
                                        showImageCount={false}
                                    />

                                </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default VisitMange;
