import React, { Component } from 'react'
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import Chart from "react-google-charts";
import { Translation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SeacrhDetails from '../../../components/search/indexSearch';
import APIClaimReport from '../../../api/claimReport'
import '../style/claimReport.scss'
import { format } from "date-fns";


const _ = require('lodash');

const options = {
    pieHole: 0.65,
    is3D: false,
    chartArea: {left: 10, top: 10, bottom: 10, right: 10},
    fontSize: 10,
    pieSliceText: 'none',
    legend: 'none'
};
export default class ClaimSummaryReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isActivePublic:false,
            isActivePrivate:true,
            search: "",
            startDate:'',
            endDate:'',
            claimType:"private",
            claimSummary: {},
            claimTag: {},
            claimTagShow: {} 
        }

        this.handlePropsChange = this.handlePropsChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    getData() {

        let dataParams = {
            start_date: this.state.startDate,
            end_date:  this.state.endDate,
            claim_type: this.state.claimType
        }

        APIClaimReport.getClaimSummaryReport(dataParams).then((res) => {
            if (res.data) {
                let data_clone = _.cloneDeep(res.data);

                this.setState({
                    claimSummary: res.data.summary,
                    claimTag: res.data.tag_count,
                    claimTagShow: data_clone.tag_count
                })
            }
        })
    }

    handleChange(e) {
        if(e.target.name === 'public' && e.target.name !== '' ){
            this.setState({
                claimType: e.target.name,
                isActivePublic:true,
                isActivePrivate:false
            }) //,() => this.getData()
        }else if (e.target.name === 'private' && e.target.name !== ''){
            this.setState({
                claimType: e.target.name, 
                isActivePrivate:true,
                isActivePublic:false
            }) //,() => this.getData()
        }
       
    }

    handlePropsChange(channel_list) {

        let search = ""
        let start_date = null
        let end_date = null

        channel_list.forEach((e, index) => {
            if (e.name === "search") {
                search = e.value
            }
            else if (e.name === "start_date" && e.value !== '') {
                start_date = e.value
            }
            else if (e.name === "end_date" && e.value !== '') {
                end_date = e.value
            }
        });
        
        this.setState({
            startDate: format(start_date, "DD/MM/YYYY") != "Invalid Date" ? format(start_date, "DD/MM/YYYY") : start_date,
            endDate: format(end_date, "DD/MM/YYYY") != "Invalid Date" ? format(end_date, "DD/MM/YYYY") : end_date,
        })

    }

    showTag () {

        let filter =   _.filter(this.state.claimTagShow,(o,val) => val !== 0)

        return _.map(filter, (key,value)=>{
            return (
                <div>
                    <ul style={{marginBottom:'0'}}>
                        <li> {key[0]}</li>
                    </ul>
                </div>
            )
        })
    }

    componentDidMount() {
        this.getData()
        this.showTag()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate || prevState.claimType !== this.state.claimType ) {
            this.getData()
          }
    }
   
    render() {

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation >
                        {
                            t =>
                                <div className="container-fluid box claim-report-all bg-light-blue">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/purchasing">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span>{t('claimReport:SinMai Reports Lists')}</span>
                                            </h3>
                                        </div>
                                    </div>

                                    <>
                                    </>
                                    <div className="content-inner pt-6">
                                        <div className="row justify-content-between">
                                            <div className='col' style={{ padding: '0' }}>
                                                <div className='mt-5'>
                                                <button className={`btn btn-view-claim ${this.state.isActivePrivate ? 'btn-active' :''}`} 
                                                        type="submit" name="private" id="private" value="private" onClick={(e) => this.handleChange(e)}
                                                        >
                                                            เคลมส่วนตัว
                                                        </button>
                                                        <button 
                                                        className={`btn btn-view-claim ${this.state.isActivePublic ? 'btn-active' :''}`} 
                                                        name="public" id="public" value="public" type="submit" 
                                                        onClick={(e) => this.handleChange(e)}>
                                                            เคลมส่วนกลาง
                                                        </button>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='mr-12' style={{display:'flex',justifyContent:'space-around'}}>
                                                <SeacrhDetails 
                                                    autoChange={true}
                                                    text={[t("claim:Choose Show")]} // dont forget to add mindate maxdate
                                                    selectIs={false}
                                                    startDateIs={true}
                                                    endDateIs={true}
                                                    searchBoxId={false}
                                                    search={this.state.search}
                                                    start_date={this.state.start_date}
                                                    end_date={this.state.end_date}
                                                    handlePropsChange={this.handlePropsChange}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row pt-10'>
                                            <div className='col-5 bg-white mr-10 claim-shadew'>
                                                <div className='row claim-box-main'>
                                                    <div className='col'>
                                                        <div className='pt-9'>
                                                            <Chart
                                                            chartType="PieChart"
                                                            data={this.state.claimTag}
                                                            options={options}
                                                            />
                                                        </div>
                                                       
                                                    </div>
                                                    <div className='col'>
                                                        <div className='claim-list'>
                                                            <h4>รายการแจ้งเคลมสูงสุด</h4>
                                                            {this.showTag()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-3'>
                                                <div className='row bg-white mr-6 mb-4 claim-shadew'>
                                                    <div className='claim-box'>
                                                        <div className='col'>
                                                            <img src={process.env.PUBLIC_URL + '/images/propertyManagement/claim-icon/succeed-claim.png'} />
                                                        </div>
                                                        <div className='col'>
                                                            <div className='claim-box-text'>
                                                                <h2>{this.state.claimSummary.success_claim}</h2>
                                                                ปิดเคลมสำเสร็จ
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row bg-white mr-6 mb-4 claim-shadew'>
                                                    <div className='claim-box'>
                                                        <div className='col'>
                                                            <img src={process.env.PUBLIC_URL + '/images/propertyManagement/claim-icon/cancel-claim.png'} />
                                                        </div>
                                                        <div className='col'>
                                                            <div className='claim-box-text'>
                                                            <h2>{this.state.claimSummary.void_claim}</h2>
                                                                ยกเลิก / ปฎิเสธ
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='row bg-white mb-4 claim-shadew'>
                                                    <div className='claim-box'>
                                                        <div className='col'>
                                                            <img src={process.env.PUBLIC_URL + '/images/propertyManagement/claim-icon/money-claim.png'} />
                                                        </div>
                                                        <div className='col'>
                                                            <div className='claim-box-text'>
                                                            <h2>{this.state.claimSummary.sum_approved_amount}</h2>
                                                                จำนวนเงินที่ขออนุมัติ
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row bg-white mb-4 claim-shadew'>
                                                    <div className='claim-box'>
                                                        <div className='col'>
                                                            <img src={process.env.PUBLIC_URL + '/images/propertyManagement/claim-icon/money-approve.png'} />
                                                        </div>
                                                        <div className='col'>
                                                            <div className='claim-box-text'>
                                                            <h2>{this.state.claimSummary.sum_claim_amount}</h2>
                                                                จำนวนเงินที่ขอเคลม
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                        }

                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}
