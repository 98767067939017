import React from 'react';
import { Component } from 'react';
import ContractForm from './contractForm';
import {Redirect} from 'react-router'
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import "../../developer.scss";


class RevenueContractForm extends Component{


    render(){
        if(this.props.contactList.length > 0){
            return(
                <Translation>
                    {t =>
                        <div className="container-fluid box" id="developer-form">
                            <div className="row">
                                <div className="col">
                                    <h4 className="mb-4">
                                        <Link to="/developer/revenue-contract/create/select_customer">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                        </Link>
                                        {t('developer:Bind revenue contract')}
                                    </h4>
                                </div>
                            </div>
                                <div className="content-inner">
                                    <ContractForm  contactList={this.props.contactList} conditionContract={false}/>
                                </div>
                        </div>
                    }
                </Translation>
            )
        }
        return <Redirect to="/developer/revenue-contract/create/select_customer"/>;
    }



}
export default RevenueContractForm;