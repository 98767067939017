import React from 'react';
import { QueryRenderer } from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import _ from "lodash";
import Loading from '../../../libs/loading';

const otherExpensePaymentChannel = graphql`
    query pettyCashRecordListTablePayQuery($search: String, $start_date: DateTime, $end_date: DateTime, $first: Int, $last: Int, $setPettyCash_Id: ID) {
        paymentChannelViewer{
            allPaymentChannel(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last, setPettyCash_Id: $setPettyCash_Id){
                totalCount
                edges{
                    node{
                        id
                        payRecordGroup{
                            id
                            docNumber
                            issuedDate
                            contact {
                                id
                                refNumber
                                name
                                firstName
                                lastName
                            }
                            payRecord{
                                edges{
                                    node{
                                        id
                                        purchaseRecord{
                                            id
                                            description
                                            productAndService{
                                                id
                                                name
                                                productCode
                                            }
                                            chartOfAccount{
                                                id
                                                name
                                                chartOfAccountCode
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        description
                        price
                        status
                        statusSetPettyCash
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }
        }

    }
`;

class PettyCashRecordListTablePay extends ComponentPagination {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            redirect: false,
            reQuery: false,
            first: this.state.first,
            last: this.state.last
        };
    }

    handleChange() {
        this.setState({ reQuery: true });
    }

    append_list(valuse) {
        let list_return = {};
        let docNumber = null;
        valuse.edges.map((result, index) => {
            if (docNumber === null) {
                docNumber = result.node.payRecordGroup.docNumber
                list_return[result.node.payRecordGroup.docNumber] = [];
                list_return[result.node.payRecordGroup.docNumber].push(result)
            } else {
                if (docNumber !== result.node.payRecordGroup.docNumber) {
                    docNumber = result.node.payRecordGroup.docNumber
                    list_return[result.node.payRecordGroup.docNumber] = [];
                    list_return[result.node.payRecordGroup.docNumber].push(result)
                } else {
                    list_return[result.node.payRecordGroup.docNumber].push(result)
                }
            }
            return result
        })
        return list_return
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={otherExpensePaymentChannel}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status,
                    setPettyCash_Id: this.props.set_petty_cash_id,
                    reQuery: this.state.reQuery
                }}
                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        let allPaymentChannel = this.append_list(props.paymentChannelViewer.allPaymentChannel)
                        return (<React.Fragment>
                            <div className="table-responsive fade-up card">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center">เลขที่</th>
                                            <th className="text-center">วันที่</th>
                                            <th>รายการตั้งเบิก</th>
                                            <th>รหัส</th>
                                            <th>บัญชี</th>
                                            <th>รายการ</th>
                                            <th className="text-right" style={{ whiteSpace: 'nowrap' }}>จำนวนที่เบิก</th>
                                            <th className="text-center">สถานะ</th>
                                            <th />
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {Object.entries(allPaymentChannel).map(([index, pay_petty_cash_record], index_stack) => {

                                            return (
                                                pay_petty_cash_record.map((pay_petty, index_question) => {
                                                    return (
                                                        pay_petty.node.payRecordGroup.payRecord.edges.map((pay_Record, index) => {
                                                            let last_index = index + 1 === pay_petty.node.payRecordGroup.payRecord.edges.length ? true : false
                                                            let code = "";
                                                            let name_code = "";
                                                            if (pay_Record.node.purchaseRecord.productAndService) {
                                                                code = pay_Record.node.purchaseRecord.productAndService.productCode;
                                                                name_code = pay_Record.node.purchaseRecord.productAndService.name;
                                                            } else {
                                                                code = pay_Record.node.purchaseRecord.chartOfAccount.chartOfAccountCode;
                                                                name_code = pay_Record.node.purchaseRecord.chartOfAccount.name;
                                                            }
                                                            return (
                                                                <tr key={pay_petty.node.id + index}>
                                                                    <td style={{ whiteSpace: 'nowrap' }} className={pay_petty.node.statusSetPettyCash === "VOID" ? "text-center text-danger" : "text-center"}>
                                                                        {index === 0 ? pay_petty.node.payRecordGroup.docNumber : "-"}
                                                                    </td>
                                                                    <td className="text-center">{format(pay_petty.node.payRecordGroup.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                    <td>{pay_petty.node.setPettyCash.docNumber} {pay_petty.node.setPettyCash.withdrawer} {pay_petty.node.setPettyCash.description}</td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>{code}</td>
                                                                    <td>{name_code}</td>
                                                                    <td>{pay_petty.node.payRecordGroup.contact.name} - {pay_Record.node.purchaseRecord.description}</td>

                                                                    <td className="text-right">{ last_index ? numberWithComma(pay_petty.node.price) : "-" }</td>
                                                                    <td className={pay_petty.node.statusSetPettyCash === "VOID" ? "text-center text-danger" : "text-center"}>{pay_petty.node.statusSetPettyCash === 'WAIT' ? 'รอเบิก' : pay_petty.node.statusSetPettyCash === "VOID" ? 'ยกเลิก' : 'เบิกแล้ว'}</td>
                                                                    <td className="text-center">
                                                                        {pay_petty.node.refPettyCashRecordDoc ?
                                                                            <a download="name_of_downloaded_file"
                                                                                href={pay_petty.node.refPettyCashRecordDoc}
                                                                                target={"_blank"}>
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + '/images/icons/download-file-icon.png'}
                                                                                    alt="back"
                                                                                    className="download-file-icon" />
                                                                            </a>
                                                                            :
                                                                            "-"
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {index_question === 0 && pay_petty.node.status !== "VOID" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_print' }) &&
                                                                            <Link
                                                                                to={"/accounting/expense/pay/detail/" + pay_petty.node.payRecordGroup.id + "/" + pay_petty.node.payRecordGroup.docNumber}
                                                                                target={"_blank"}>
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + '/images/icons/print.png'}
                                                                                    alt="print" className="print" />
                                                                            </Link>

                                                                        }
                                                                    </td>

                                                                </tr>
                                                            )//return PayRecord
                                                        })

                                                    )//return pay_petty
                                                })

                                            )//return Object
                                        }
                                        )}

                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <div className="col" />
                                <div className="col">
                                    <div className="form-inline float-right p-3 mb-2 mt-2 bg-light text-dark">
                                        <div className="text-left">ยอดคงเหลือ</div>
                                        <div className="text-right ml-5">{
                                            this.props.all_set_petty_cash_list.map((petty) => petty.node.id === this.props.set_petty_cash_id &&
                                            numberWithComma(Math.round(((petty.node.withdrawAmount - petty.node.totalPettyCashRecord) + Number.EPSILON) * 100) / 100)
                                            )
                                        } บาท</div>
                                    </div>
                                    <div className="form-inline float-right p-3 mb-2 mt-2 bg-light text-dark mr-4">
                                        <div className="text-left">ยอดรวมจำนวนที่เบิก</div>
                                        <div className="text-right ml-5">{
                                            this.props.all_set_petty_cash_list.map((petty) => petty.node.id === this.props.set_petty_cash_id &&
                                            numberWithComma( Math.round(((petty.node.totalPettyCashRecord) + Number.EPSILON) * 100) / 100 )
                                            )
                                        } บาท</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props.paymentChannelViewer.allPaymentChannel.totalCount} />
                            </div>
                        </React.Fragment>
                        )
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default PettyCashRecordListTablePay;