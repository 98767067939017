import React, { Component } from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import SelectContactPayable from '../../income/selectContactPayable';
import AcceptCreditNoteCreateFormReason from "./acceptCreditNoteCreateFormReason";
import AcceptCreditNoteContactCreateForm from "./acceptCreditNoteContactCreateForm"
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";


class AcceptCreditNoteCreate extends Component {

    constructor() {
        super();
        this.state = {
            customer_list: [],
            accountType: "accept_credit_note",
            reason_create: '',
            transaction: '',
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
        this.updateReasonCreate = this.updateReasonCreate.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({ customer_list: contact_list })
    }

    updateReasonCreate(type, detail) {
        this.setState({ [type]: detail })
    }


    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    {/* เลือกห้อง */}
                    {this.props.match.params.status === 'select_customer' &&
                        <SelectContactPayable updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                            nextLink="/accounting/expense/accept-credit-note/form/select_purchase"
                            backLink="/accounting/expense/accept-credit-note/list/all"
                            disable_select_type={true}
                        />
                    }

                    {/* เลือกเลขที่ตั้งค่าใช้จ่าย */}
                    {this.props.match.params.status === 'select_purchase' &&
                        <AcceptCreditNoteCreateFormReason customerList={this.state.customer_list}
                            updateReasonCreate={this.updateReasonCreate} />
                    }

                    {/* สร้างใบ */}
                    {this.props.match.params.status === 'create' &&
                        <AcceptCreditNoteContactCreateForm customerList={this.state.customer_list}
                            reason_create={this.state.reason_create}
                            transaction_id={this.state.transaction}

                        />
                    }
                </WrapperContent>
            </Wrapper>


        )
    }
}

export default AcceptCreditNoteCreate;