import {graphql} from "babel-plugin-relay/macro";

const queryCnInvoice = graphql`
    query fetchCnInvoiceQuery($id: ID!){
        invoiceViewer{
            allInvoiceByStatus(statusList:["active","overdue","partial_payment"],contact_Id: $id, order: "doc_number"){
                totalCount
                edges{
                    node{
                        id
                        docNumber
                        total
                        issuedDate
                        dueDate
                        status
                        taxType
                        transaction(status_In: "active, overdue, partial_payment"){
                            totalCount
                            edges{
                                node{
                                    id
                                    description
                                    unitItems
                                    discount
                                    price
                                    preTaxAmount
                                    vat
                                    paid
                                    whtRate
                                    whtRatePercent
                                    total
                                    productAndService{
                                        productCode
                                    }
                                    
                                    receiptDepositTransactionUsed {
                                        edges{
                                            node{
                                                id
                                                amount
                                            }
                                        }
                                    }
                                    creditNoteTransaction{
                                        edges{
                                            node{
                                                id
                                                price
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default queryCnInvoice;
