/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClearGuaranteeMoneyReceivedPayGroup = "AGENT" | "DEVELOPER" | "OTHER" | "OWNER" | "RENT" | "%future added value";
export type ClearGuaranteeMoneyReceivedStatus = "ACTIVE" | "VOID" | "%future added value";
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type GuaranteeMoneyReceivedStatus = "COMPLETED" | "FORFEIT_ALL" | "IN_PROCESS" | "RETURN_FORFEIT" | "VOID" | "%future added value";
export type clearGuaranteeMoneyReceivedListTableQueryVariables = {|
  first?: ?number,
  last?: ?number,
  start_date?: ?any,
  end_date?: ?any,
  search?: ?string,
|};
export type clearGuaranteeMoneyReceivedListTableQueryResponse = {|
  +allClearGuaranteeMoneyReceived: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +updated: any,
        +cancelRemark: ?string,
        +status: ClearGuaranteeMoneyReceivedStatus,
        +firstName: string,
        +lastName: string,
        +name: string,
        +taxIdNumber: ?string,
        +phone: string,
        +email: ?string,
        +address: string,
        +country: string,
        +province: string,
        +district: string,
        +city: string,
        +postalCode: string,
        +payGroup: ClearGuaranteeMoneyReceivedPayGroup,
        +contact: ?{|
          +typeOfContact: ContactTypeOfContact,
          +id: string,
          +name: string,
          +firstName: string,
          +lastName: string,
          +refNumber: string,
          +registeredName: string,
          +registeredAddress: string,
          +registeredCountry: string,
          +registeredProvince: string,
          +registeredCity: string,
          +registeredDistrict: string,
          +registeredPostalCode: string,
        |},
        +guarantee: {|
          +id: string,
          +docNumber: string,
          +amount: ?number,
          +firstName: string,
          +lastName: string,
          +contact: {|
            +id: string,
            +name: string,
            +firstName: string,
            +lastName: string,
            +typeOfContact: ContactTypeOfContact,
            +refNumber: string,
            +registeredName: string,
            +registeredAddress: string,
            +registeredCountry: string,
            +registeredProvince: string,
            +registeredCity: string,
            +registeredDistrict: string,
            +registeredPostalCode: string,
          |},
          +issuedDate: any,
          +status: GuaranteeMoneyReceivedStatus,
          +creator: string,
          +description: string,
        |},
      |}
    |}>,
  |}
|};
export type clearGuaranteeMoneyReceivedListTableQuery = {|
  variables: clearGuaranteeMoneyReceivedListTableQueryVariables,
  response: clearGuaranteeMoneyReceivedListTableQueryResponse,
|};
*/


/*
query clearGuaranteeMoneyReceivedListTableQuery(
  $first: Int
  $last: Int
  $start_date: DateTime
  $end_date: DateTime
  $search: String
) {
  allClearGuaranteeMoneyReceived(first: $first, last: $last, search: $search, startDate: $start_date, endDate: $end_date) {
    totalCount
    edges {
      node {
        id
        docNumber
        issuedDate
        updated
        cancelRemark
        status
        firstName
        lastName
        name
        taxIdNumber
        phone
        email
        address
        country
        province
        district
        city
        postalCode
        payGroup
        contact {
          typeOfContact
          id
          name
          firstName
          lastName
          refNumber
          registeredName
          registeredAddress
          registeredCountry
          registeredProvince
          registeredCity
          registeredDistrict
          registeredPostalCode
        }
        guarantee {
          id
          docNumber
          amount
          firstName
          lastName
          contact {
            id
            name
            firstName
            lastName
            typeOfContact
            refNumber
            registeredName
            registeredAddress
            registeredCountry
            registeredProvince
            registeredCity
            registeredDistrict
            registeredPostalCode
          }
          issuedDate
          status
          creator
          description
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredAddress",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCountry",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredProvince",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCity",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredDistrict",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredPostalCode",
  "storageKey": null
},
v21 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      }
    ],
    "concreteType": "ClearGuaranteeMoneyReceivedNodeConnection",
    "kind": "LinkedField",
    "name": "allClearGuaranteeMoneyReceived",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClearGuaranteeMoneyReceivedNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClearGuaranteeMoneyReceivedNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cancelRemark",
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxIdNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "province",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "district",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "payGroup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v5/*: any*/),
                  (v11/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GuaranteeMoneyReceivedNode",
                "kind": "LinkedField",
                "name": "guarantee",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v11/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "creator",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "clearGuaranteeMoneyReceivedListTableQuery",
    "selections": (v21/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "clearGuaranteeMoneyReceivedListTableQuery",
    "selections": (v21/*: any*/)
  },
  "params": {
    "cacheID": "1bd71193b89fc7e66a6c2c70f7162005",
    "id": null,
    "metadata": {},
    "name": "clearGuaranteeMoneyReceivedListTableQuery",
    "operationKind": "query",
    "text": "query clearGuaranteeMoneyReceivedListTableQuery(\n  $first: Int\n  $last: Int\n  $start_date: DateTime\n  $end_date: DateTime\n  $search: String\n) {\n  allClearGuaranteeMoneyReceived(first: $first, last: $last, search: $search, startDate: $start_date, endDate: $end_date) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        updated\n        cancelRemark\n        status\n        firstName\n        lastName\n        name\n        taxIdNumber\n        phone\n        email\n        address\n        country\n        province\n        district\n        city\n        postalCode\n        payGroup\n        contact {\n          typeOfContact\n          id\n          name\n          firstName\n          lastName\n          refNumber\n          registeredName\n          registeredAddress\n          registeredCountry\n          registeredProvince\n          registeredCity\n          registeredDistrict\n          registeredPostalCode\n        }\n        guarantee {\n          id\n          docNumber\n          amount\n          firstName\n          lastName\n          contact {\n            id\n            name\n            firstName\n            lastName\n            typeOfContact\n            refNumber\n            registeredName\n            registeredAddress\n            registeredCountry\n            registeredProvince\n            registeredCity\n            registeredDistrict\n            registeredPostalCode\n          }\n          issuedDate\n          status\n          creator\n          description\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '216d66ff824df83c8fd38d522f495a2b';

module.exports = node;
