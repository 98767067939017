import '../styles/main.scss';
import '../styles/modify_print.scss'
import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import getNameResidential from "../../libs/getNameResidential";

const ThaiBaht = require('thai-baht-text');

export default class PurchaseRecord extends Component {

    componentDidMount(){
        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            // let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = (page_height - (head + detail + invoice));
            $('.invoice-body tbody tr:last-child td').css({ paddingBottom: diff });
        }, 200);
    }

    render() {
        return (
            <React.Fragment>
                {["original"].map((paper_type, index) => {
                    return (
                        <div id="style-modify" className={"print-page-a4"} key={index}>
                            <div className="subpage">
                                <div className="head">
                                    <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                                        <div style={{ minHeight: 100, minWidth: 100 }}>
                                            <img
                                                src={this.props.query.selfProject.logo}
                                                alt="silverman" />
                                        </div>
                                        <div>
                                            <div className="title">
                                                <strong>{this.props.query.selfProject.name}</strong>
                                            </div>
                                            <div
                                                className="title">{this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}
                                            </div>
                                            <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                                        </div>
    
                                    </div>
    
                                    <div className="document-name">
                                        <div className="title">ใบตั้งค่าใช้จ่าย</div>
                                        <div className="title">Expense</div>
                                        {
                                            paper_type === "original" ?
                                                <div className="title small">(ต้นฉบับ/Original)</div>
                                                :
                                                <div className="title small">(สำเนา/Copy)</div>
                                        }
                                    </div>
                                </div>
    
                                <div className="detail">
                                    <div className="customer-detail">
                                        <strong>รหัสเจ้าหนี้/ID:</strong> {this.props.query.purchaseRecordGroup.contact.refNumber}
                                        <br />
                                        {this.props.query.purchaseRecordGroup.contact.typeOfContact === 'RESIDENTIAL' ?
                                            <React.Fragment>
                                                <strong>ชื่อลูกค้า/Customer:</strong>
                                                {getNameResidential(this.props.query.purchaseRecordGroup.contact.firstName, this.props.query.purchaseRecordGroup.contact.lastName)}
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <strong>ชื่อลูกค้า/Customer:</strong> {this.props.query.purchaseRecordGroup.contact.name}
                                            </React.Fragment>
                                        }
                                        <br />
                                        <strong>ที่อยู่/Address:</strong>
    
                                        <div className="customer-detail address-space">
                                            {this.props.query.purchaseRecordGroup.contact.registeredAddress + " "}
                                            {this.props.query.purchaseRecordGroup.contact.registeredDistrict && this.props.getPrefix("district", this.props.query.purchaseRecordGroup.contact.registeredPostalCode) + this.props.query.purchaseRecordGroup.contact.registeredDistrict + " "}
                                            {this.props.query.purchaseRecordGroup.contact.registeredCity && this.props.getPrefix("city", this.props.query.purchaseRecordGroup.contact.registeredPostalCode) + this.props.query.purchaseRecordGroup.contact.registeredCity + " "}
                                            {this.props.query.purchaseRecordGroup.contact.registeredProvince && this.props.getPrefix("province", this.props.query.purchaseRecordGroup.contact.registeredPostalCode) + this.props.query.purchaseRecordGroup.contact.registeredProvince + " "}
                                            {this.props.query.purchaseRecordGroup.contact.registeredPostalCode + " "}
    
                                            {
                                                (this.props.query.purchaseRecordGroup.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.query.purchaseRecordGroup.contact.typeOfSupplier === "COMPANY") &&
                                                <React.Fragment>
                                                    {
                                                        this.props.query.purchaseRecordGroup.contact.typeOfCompany === "HEADQUARTERS" ?
                                                            "สำนักงานใหญ่"
                                                            : this.props.query.purchaseRecordGroup.contact.typeOfCompany === "BRANCH" &&
                                                            "สาขา " + this.props.query.purchaseRecordGroup.contact.nameBranch
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>
                                        <br />
                                        <strong>เลขผู้เสียภาษี/Tax
                                            ID:</strong> {this.props.query.purchaseRecordGroup.contact.taxIdNumber}
                                        <br />
                                        <strong>เลขที่ใบกำกับ/Attention:</strong> {this.props.query.purchaseRecordGroup.taxInvoiceNumber}
                                        <span
                                            className="tax-invoice-date">{this.props.query.purchaseRecordGroup.taxInvoiceNumber ? 'ลวท.' + format(this.props.query.purchaseRecordGroup.taxInvoiceDate, 'DD/MM/YYYY', { locale: thLocale })
                                                :
                                                this.props.query.purchaseRecordGroup.taxInvoiceNumber ? 'ลวท.' + format(this.props.query.purchaseRecordGroup.issuedDate, 'DD/MM/YYYY', { locale: thLocale })
                                                    : null}</span>
                                        <br />
                                    </div>
                                    <div className="document-detail">
                                        <strong>เลขที่/No:</strong> {this.props.query.purchaseRecordGroup.docNumber}
                                        <br />
                                        <strong>วันที่/Date:</strong> {format(this.props.query.purchaseRecordGroup.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}
                                        <br />
                                        <strong>ครบกำหนด/Due
                                            Date:</strong> {format(this.props.query.purchaseRecordGroup.dueDate, 'DD/MM/YYYY', { locale: thLocale })}
                                        <br />
                                    </div>
                                </div>
    
                                <div className="invoice-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th width={50} className="text-center">
                                                    ลำดับ <br />
                                                No.
                                            </th>
                                                <th width={60} className="text-center">
                                                    รหัส <br />
                                                Code
                                            </th>
                                                <th className="text-center">
                                                    รายละเอียด <br />
                                                Description
                                            </th>
                                                <th width={100} className="text-center">
                                                    จำนวนหน่วย <br />
                                                Unit
                                            </th>
                                                <th width={100} className="text-center">
                                                    ราคาต่อหน่วย <br />
                                                Unit Price
                                            </th>
                                                <th width={100} className="text-center">
                                                    จำนวนเงิน <br />
                                                Amount
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.query.purchaseRecordGroup.purchaseRecord.edges.map((purchase, index) =>
                                                <tr key={purchase.node.id}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td className="text-center">{purchase.node.productAndService ? purchase.node.productAndService.productCode : purchase.node.chartOfAccount.chartOfAccountCode}</td>
                                                    <td>{purchase.node.description}</td>
                                                    <td className="text-right">{numberWithComma(purchase.node.unitItems)}</td>
                                                    <td className="text-right">{numberWithComma(purchase.node.price)}</td>
                                                    <td className="text-right">{numberWithComma(purchase.node.price * purchase.node.unitItems)}</td>
                                                </tr>
                                            )}
    
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={3} rowSpan={
                                                    (this.props.state.no_vat && this.props.state.no_wht_rate_percent && !this.props.state.discount_status) ? 2 :
                                                        (
                                                            (this.props.state.no_vat && this.props.state.no_wht_rate_percent && this.props.state.discount_status) ||
                                                            (this.props.state.no_vat && this.props.state.have_wht_rate_percent && !this.props.state.discount_status) ||
                                                            (this.props.state.have_vat && this.props.state.no_wht_rate_percent && !this.props.state.discount_status)
                                                        ) ? 4 :
                                                            (
                                                                (this.props.state.have_vat && this.props.state.no_wht_rate_percent && this.props.state.discount_status) ||
                                                                (this.props.state.no_vat && this.props.state.have_wht_rate_percent && this.props.state.discount_status) ||
                                                                (this.props.state.have_vat && this.props.state.have_wht_rate_percent && !this.props.state.discount_status)
                                                            ) ? 5 :
                                                                this.props.state.have_vat && this.props.state.have_wht_rate_percent && this.props.state.discount_status && 8
                                                } />
                                                <td colSpan={2} className="font-weight-bold">
                                                    จำนวนเงิน /Amount
                                            </td>
                                                <td className="text-right font-weight-bold">
                                                    {numberWithComma(this.props.state.sum_amount, 0)}
                                                </td>
                                            </tr>
    
                                            {
                                                this.props.state.discount_status &&
                                                <React.Fragment>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <strong>หัก&nbsp;</strong>ส่วนลด/Discount
                                                    </td>
                                                        <td className="text-right">
                                                            {numberWithComma(this.props.state.discount, 0)}
                                                        </td>
                                                    </tr>
    
                                                    <tr>
                                                        <td colSpan={2} className="font-weight-bold">
                                                            จำนวนเงินหลังหักส่วนลด/Total Discount
                                                    </td>
                                                        <td className="text-right font-weight-bold">
                                                            {numberWithComma(this.props.state.sum_discount, 0)}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            }
    
                                            {
                                                ((this.props.state.have_vat && this.props.state.no_wht_rate_percent) ||
                                                    (this.props.state.have_vat && this.props.state.have_wht_rate_percent)) &&
                                                <tr>
                                                    <td colSpan={2} className="font-weight-bold">
                                                        ภาษีมูลค่าเพิ่ม (บาท) /VAT
                                                </td>
                                                    <td className="text-right font-weight-bold">
                                                        {numberWithComma(this.props.state.sum_vat, 0)}
                                                    </td>
                                                </tr>
                                            }
    
                                            {
                                                this.props.state.have_wht_rate_percent && this.props.state.have_vat  &&
                                                <tr>
                                                    <td colSpan={2} className="font-weight-bold">
                                                        จำนวนเงินรวม/Total cash
                                                </td>
                                                    <td className="text-right font-weight-bold">
                                                        {numberWithComma(this.props.state.sum_total, 0)}
                                                    </td>
                                                </tr>
                                            }
    
                                            <tr>
                                                <td colSpan={2} className="font-weight-bold">
                                                    <strong>หัก&nbsp;จ่ายชำระล่วงหน้า/Prepaid Deposit</strong>
                                            </td>
                                                <td className="text-right font-weight-bold">
                                                    {numberWithComma(this.props.state.prepaidDeposit, 0)}
                                                </td>
                                            </tr>
    
                                            <tr />
                                            <tr />
    
                                            <tr>
                                                <td colSpan={3} className="text-center font-weight-bold">
                                                    ({ThaiBaht(this.props.state.sum_grand_total)})
                                            </td>
                                                <td colSpan={2} className="font-weight-bold">
                                                    จำนวนเงินรวมทั้งสิ้น/Grand Total
                                            </td>
                                                <td className="text-right font-weight-bold">
                                                    {numberWithComma(this.props.state.sum_grand_total, 0)}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
    
                                    <div className="remark">
                                        <strong>หมายเหตุ/Remarks:</strong><br />
                                        {this.props.query.purchaseRecordGroup.remark}
                                    </div>
                                </div>
    
                                {/* <div className="footer">
                                    <div className="d-flex justify-content-end">
                                        <div className="left mt-5 text-center">
                                            .........................................................<br />
                                            <span className="font-weight-bold">ผู้จัดทำ / Organizer</span>
                                        </div>
                                        <div className="center mt-5 text-center">
                                            .........................................................<br />
                                            <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span>
                                        </div>
                                        <div className="right mt-5 text-center">
                                            .........................................................<br />
                                            <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="number-page">
                                    <br/>
                                    1/2
                                </div>
                            </div>
                        </div>
                    )
                })
                }
            </React.Fragment>
        )
    }
}