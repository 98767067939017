import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import CreditNoteCreateFormReason from './creditNoteCreateFormReason';
import CreditNoteContactCreateForm from "./creditNoteContactCreateForm";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import SelectContactReceivable from '../selectContactReceivable';

class CreditNoteCreate extends Component {

    constructor() {
        super();
        this.state = {
            customer_list: [],
            accountType: "credit-note",
            reason_create: '',
            transaction:[],
            invoice:[],
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
        this.updateReasonCreate = this.updateReasonCreate.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({customer_list: contact_list})
    }

    updateReasonCreate(type ,detail) {
        this.setState({[type]: detail})
    }


    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    {this.props.match.params.status === 'select_customer' &&
                    <SelectContactReceivable updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                   initialContactType="residential"
                                   nextLink="/accounting/income/credit-note/form/select_invoice"
                                   backLink="/accounting/income/credit-note/list/all"/>
                    }
                    {this.props.match.params.status === 'select_invoice' &&
                    <CreditNoteCreateFormReason customerList={this.state.customer_list}
                                                updateReasonCreate={this.updateReasonCreate}/>
                    }
                    {this.props.match.params.status === 'create' &&
                    <CreditNoteContactCreateForm customerList={this.state.customer_list}
                                                 reason_create={this.state.reason_create}
                                                 transaction={this.state.transaction}
                                                 invoice={this.state.invoice}
                    />
                    }
                </WrapperContent>
            </Wrapper>


        )
    }
}

export default CreditNoteCreate;