import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link, withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import { format } from "date-fns";
import i18n from "i18next";
import Select from 'react-select';
import '../style/claim.scss'
import DatePicker from "react-datepicker";
import APIInsur from '../../../api/insurrance'
import _ from 'lodash';
import upload from '../../../libs/upload';
import jwtDecode from 'jwt-decode'
import CurrencyInput from 'react-currency-input-field';
import i18next from 'i18next';


class CreateInsur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            image_upload: '',
            image: '',
            name_title: '',
            name_staff: '',
            last_name_staff: '',
            role: '',
            department: '',
            date_work: new Date(),
            document: '',
            document_upload: '',
            reQuery: false,
            loading: false,

            dataStep1: true,
            dataStep1Finish: false,
            policy_number: '',
            beneficiary_first_name: "",
            beneficiary_last_name: "",
            building_insurance: "",
            condominium_property: "",
            unit_number: "",
            insurance_per_unit: "",
            sum_insurance_unit: "",
            insurance_start_date: "",
            insurance_end_date: "",
            insurance_premium: "",
            coverage_limit: "",
            file_insurance: [],

            dataStep2: false,
            dataStep2Finish: false,
            dataInsuranceCompany: [],
            main_insurance_company_id: "",
            main_insurance_company_name: "",
            insurance_company_address: "",
            main_insurance_company_tax_number: "",
            main_insurance_company_website: "",
            main_insurance_company_tel: "",
            main_insurance_company_email: "",

            dataStep3: false,
            dataStep3Finish: false,
            co_insurance: [],

            dataStep4: false,
            dataStep4Finish: false,
            broker_first_name: "",
            broker_last_name: "",
            broker_company: "",
            broker_company_address: "",
            broker_tax_number: "",
            broker_tel: "",
            broker_email: "",


            CompanyList: [],

            step1: false,
            step2: false,
            step3: false,
            step4: false,
        };

        // this.handleInputImage = this.handleInputImage.bind(this);
        // this.onSubmit = this.onSubmit.bind(this);
        // this.handleInputChange = this.handleInputChange.bind(this);

        this.onFinishFt = this.onFinishFt.bind(this)
        this.onFinishSe = this.onFinishSe.bind(this)
        this.onFinishTh = this.onFinishTh.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.SeleteCompanyName = this.SeleteCompanyName.bind(this)
        this.co_insuranceUpdate = this.co_insuranceUpdate.bind(this)
        this.addListInsur = this.addListInsur.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.deleteListInsur = this.deleteListInsur.bind(this)
    }

    componentWillMount() {
        this.getCompanyAll()
        if (this.props.match.params.id) {
            // fetchQuery(environment, query, {id: this.props.match.params.id}).then(data => {
            //     if (data.projectStaff) {
            //         this.setState({
            //             id: data.projectStaff.id,
            //             name_title: data.projectStaff.nameTitle,
            //             name_staff: data.projectStaff.nameStaff,
            //             last_name_staff: data.projectStaff.lastNameStaff,
            //             image: data.projectStaff.image,
            //             role: data.projectStaff.role,
            //             date_work: parse(data.projectStaff.dateWork),
            //             department: data.projectStaff.department,
            //             document: data.projectStaff.document,
            //         })
            //     }
            // });
        }
    }

    getCompanyAll() {
        APIInsur.getCompanyAll().then((res) => {
            if (res.data) {
                let data = res.data.company_list;
                this.setState({ dataInsuranceCompany: [...data.map((n) => ({ ...n, value: n.id, label: n.name, insurance_ratio: 0 })), { id: 'other', value: 'other', label: 'อื่น ๆ', insurance_ratio: 0 }] })
            }
        })
    }

    // handleInputImage(e) {
    //     if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === 'image') {
    //         this.setState({
    //             [e.currentTarget.name + '_upload']: e.currentTarget.files[0],
    //             [e.currentTarget.name]: URL.createObjectURL(e.target.files[0])
    //         });
    //     }

    // }

    onFinishFt(e) {
        e.preventDefault();
        this.setState({ dataStep1: false, dataStep1Finish: true, dataStep2: true, step1: true })
    }
    onFinishSe(e) {
        e.preventDefault();
        this.setState({ dataStep2: false, dataStep2Finish: true, dataStep3: true, step2: true })
    }
    onFinishTh(e) {
        e.preventDefault();
        this.setState({ dataStep3: false, dataStep3Finish: true, dataStep4: true, step3: true })
    }

    onSubmit(e) {
        e.preventDefault();

        let state = this.state;
        var bodyFormData = new FormData();
        bodyFormData.append('policy_number', state.policy_number);
        bodyFormData.append('beneficiary_first_name', state.beneficiary_first_name);
        bodyFormData.append('beneficiary_last_name', state.beneficiary_last_name);
        bodyFormData.append('building_insurance', state.building_insurance || 0);
        bodyFormData.append('condominium_property', parseFloat(state.condominium_property || 0));
        bodyFormData.append('unit_number', state.unit_number || 0);
        bodyFormData.append('insurance_per_unit', state.insurance_per_unit || 0);
        bodyFormData.append('sum_insurance_unit', state.sum_insurance_unit || 0);
        bodyFormData.append('insurance_start_date', format(state.insurance_start_date, "DD/MM/YYYY"));
        bodyFormData.append('insurance_end_date', format(state.insurance_end_date, "DD/MM/YYYY"));
        bodyFormData.append('insurance_premium', state.insurance_premium || 0);
        bodyFormData.append('coverage_limit', state.coverage_limit || 0);
        bodyFormData.append('main_insurance_company_id', state.main_insurance_company_id.id);
        bodyFormData.append('main_insurance_company_name', state.main_insurance_company_name);
        bodyFormData.append('insurance_company_address', state.insurance_company_address);
        bodyFormData.append('main_insurance_company_tax_number', state.main_insurance_company_tax_number);
        bodyFormData.append('main_insurance_company_website', state.main_insurance_company_website);
        bodyFormData.append('main_insurance_company_tel', state.main_insurance_company_tel);
        bodyFormData.append('main_insurance_company_email', state.main_insurance_company_email);
        bodyFormData.append('broker_first_name', state.broker_first_name);
        bodyFormData.append('broker_last_name', state.broker_last_name);
        bodyFormData.append('broker_company', state.broker_company);
        bodyFormData.append('broker_company_address', state.broker_company_address);
        bodyFormData.append('broker_tax_number', state.broker_tax_number);
        bodyFormData.append('broker_tel', state.broker_tel);
        bodyFormData.append('broker_email', state.broker_email);
        bodyFormData.append('site', localStorage.getItem("site_id"));



        let co_insurance = state.co_insurance
        let file_insurance = state.file_insurance


        let chknull = co_insurance.filter((n) => n.name !== "" && n.co_insurance_company_id !== "")
        if (chknull.length > 0) {
            co_insurance.forEach((element) => {
                if (element.co_insurance_company_id) {
                    bodyFormData.append('co_insurance', JSON.stringify(element));
                }
            });
        }

        file_insurance.forEach((element) => {
            bodyFormData.append('file_insurance', JSON.stringify(element))
        });


        Swal.fire({
            title: i18n.t("Allaction:This will only take a moment, please wait"),
            type: 'info',
            showLoaderOnConfirm: true,
            showCloseButton: false,
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 3000,
            allowOutsideClick: () => !Swal.isLoading(),
        }).then(() => {

            APIInsur.post_create_insurance(bodyFormData).then((res) => {
                if (res.data.statusCode === "0000") {
                    Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                        this.props.history.push(`/purchasing/insurrance`)
                    })
                } else {
                    Swal.fire(i18n.t('settingAccount:Failed to save', "", "warning"))
                }
            })
        }, () => {
            Swal.fire(i18n.t('settingAccount:Failed to save', "", "error"))
        })
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }



    SeleteCompanyName(e, index, main) {
        let co_insurance = _.cloneDeep(this.state.co_insurance)
        if (main) {
            co_insurance.push({ co_insurance_company_id: e?.id, name: e?.name ? e?.name : this.state.main_insurance_company_name, insurance_ratio: 100, status: "main" })
            this.setState({ co_insurance: co_insurance, main_insurance_company_id: e })
        } else if (!main) {
            _.set(co_insurance, `[${index}].co_insurance_company_id`, parseInt(e.id))
            _.set(co_insurance, `[${index}].name`, e.name)
            this.setState({ co_insurance: co_insurance })
        }
    }


    co_insuranceUpdate(data, index, e) {

        let co_insurance = _.cloneDeep(this.state.co_insurance)
        _.set(co_insurance, `[${index}].insurance_ratio`, parseInt(e.target.value))
        this.setState({ co_insurance: co_insurance })

    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/claim/insur" + path, file);
            resolve(res);
        });
    };
    async uploadFile(e, file_type) {
        let event = _.cloneDeep(e);
        // let value = event.target.value
        let files = event.currentTarget.files[0];

        if (files) {
            if (files?.size > 10000000) { //2097152
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }
            else if (
                files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg' &&
                files?.type !== "application/pdf" &&
                files?.type !== "application/vnd.ms-excel" && files?.type !== "application/msword" &&
                files?.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                files?.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), 'กรุณาอัพโหลดไฟล์ในประเภท .pdf, .png, .jpg, และ .jpegเท่านั้น และไฟล์ขนาดไม่เกิน 10 MB', 'error')
            }
            else {
                let jwt = jwtDecode(localStorage.getItem("token"))
                let new_file = new File([files], `insur_web_${Math.floor(Math.random() * 1000000001)}${format(new Date(), jwt.user_id + "_YYYYMMDDHHmmss")}${files.name}`);

                let data_state = _.cloneDeep(this.state.file_insurance)
                // let file_claim = data_state.file_claim
                await this.callUpload('', new_file).then((data) => {
                    let dese = "claim/insur/" + new_file.name
                    data_state.push({ "id": "", "file_name": new_file.name, "file_upload": dese, "viewPtath": data.location })
                });
                this.setState({ file_insurance: data_state })
            }
        }
    }

    dateFile = (index, data) => {
        let DataFile = _.cloneDeep(this.state.file_insurance)
        DataFile.splice(index, 1)
        this.setState({ file_insurance: DataFile })
    }

    addListInsur() {
        let co_insurance = _.cloneDeep(this.state.co_insurance)
        let co_insurancechk = co_insurance.filter((n) => n.name !== "" && n.co_insurance_company_id !== "")

        if (co_insurancechk.length >= 0) {
            co_insurance.push({ co_insurance_company_id: "", name: "", insurance_ratio: 0, status: 'co' })
            this.setState({ co_insurance: co_insurance })
        }
    }

    deleteListInsur(index) {
        let co_insurance = _.cloneDeep(this.state.co_insurance)
        co_insurance.splice(index, 1)
        this.setState({ co_insurance })
    }




    render() {

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            <div className="container-fluid box claim" id="staff-create">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/purchasing/insurrance">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>
                                            <span>{this.props.match.params.id ? t('claim:edit_staff') : t('claim:Insurance')}</span>
                                        </h3>
                                    </div>
                                </div>


                                <div className="content-inner mt-5 create">
                                    <div className='mx-auto' style={{ width: 744 }}>

                                        <hr className='lineStep' />
                                        <button className={this.state.step1 && this.state.dataStep1Finish ? 'steper  steperFinish' : this.state.dataStep1 ? 'steper  steperActive' : ""}>1</button>
                                        <button className={this.state.step2 && this.state.dataStep2Finish ? 'steper  steperFinish' : this.state.dataStep2 ? 'steper  steperActive' : "steper"}>2</button>
                                        <button className={this.state.step3 && this.state.dataStep3Finish ? 'steper  steperFinish' : this.state.dataStep3 ? 'steper  steperActive' : "steper"}>3</button>
                                        <button className={this.state.step4 && this.state.dataStep4Finish ? 'steper  steperFinish' : this.state.dataStep4 ? 'steper  steperActive' : "steper"}>4</button>
                                    </div>
                                    <div className="row mb-12 mt-4 mr-5 fade-up">
                                        <div className={this.state.dataStep3 ? "" : "mx-auto"} style={{ width: this.state.dataStep3 ? 1061 : 744 }}>
                                            <div className={this.state.dataStep3 ? "" : "row"}>

                                                {
                                                    this.state.dataStep1 &&
                                                    <React.Fragment>
                                                        <form onSubmit={this.onFinishFt}>
                                                            <div className='onFinishFt'>

                                                                <div className='col-12'>
                                                                    <label className='headerLabel'>{t("claim:Policy Info")}</label>
                                                                </div>

                                                                <div className='col-12 mt-5 mb-5'>
                                                                    <label className='label16'>{t("claim:Policy number")} <span className='text-red'>*</span></label>
                                                                    <input type={"text"} name='dataStep1.policy_number'
                                                                        onChange={(e) => this.setState({ policy_number: e.target.value })}
                                                                        className='form-control inputBox'
                                                                        placeholder={t("claim:Policy number")}
                                                                        required
                                                                        defaultValue={this.state.policy_number} />
                                                                </div>

                                                                <div className='col-12 mt-5 mb-5'>
                                                                    {/* <label className='label16'>{t("claim:Name Policy")}</label> */}
                                                                    {/* <div style={{ display: 'inline-flex' }}>
                                                                        <div style={{ marginRight: 32 }}> */}
                                                                    <label className='label16'>{t("claim:Name Policy")} <span className='text-red'>*</span></label>
                                                                    <input type={"text"} name='beneficiary_first_name'
                                                                        onChange={(e) => this.setState({ beneficiary_first_name: e.target.value })}
                                                                        placeholder={t("claim:Name Policy")}
                                                                        className='form-control inputBox'
                                                                        // style={{ width: 332 }}
                                                                        required
                                                                        defaultValue={this.state.beneficiary_first_name} />
                                                                    {/* </div> */}

                                                                    {/* <div>
                                                                            <label className='label16'>{t("claim:LastName")} <span className='text-red'>*</span></label>
                                                                            <input type={"text"} name='beneficiary_last_name'
                                                                                onChange={(e) => this.setState({ beneficiary_last_name: e.target.value })}
                                                                                placeholder={t("claim:LastName")}
                                                                                className='form-control inputBox' style={{ width: 332 }}
                                                                                required
                                                                                defaultValue={this.state.beneficiary_last_name} />
                                                                        </div> */}
                                                                    {/* </div> */}
                                                                </div>

                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <label className='headerLabel18'>{t("claim:Building insurance")} <i className='text-red'>*</i></label>
                                                                        </div>
                                                                        <div>
                                                                            <span>{t("claim:bath")}</span>
                                                                            <CurrencyInput
                                                                                id="building_insurance"
                                                                                name="building_insurance"
                                                                                className='form-control inputBox'
                                                                                style={{ width: 508 }}
                                                                                placeholder={t("claim:Building insurance")}
                                                                                defaultValue={this.state.building_insurance}
                                                                                decimalsLimit={2}

                                                                                onValueChange={(value, name) => this.setState({ building_insurance: value })}
                                                                                maxLength={16}
                                                                            />
                                                                            <input type={"text"} required defaultValue={this.state.building_insurance}
                                                                                style={{ position: 'absolute', zIndex: -1, top: 10 }}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกช่องนี้ด้วย')}
                                                                                onInput={e => e.target.setCustomValidity('')} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <label className='headerLabel18'>{t("claim:expand apartment")}</label>
                                                                        </div>

                                                                        <div>
                                                                            <input type={"text"} name='condominium_property'
                                                                                onChange={(e) => this.setState({ condominium_property: e.target.value })}
                                                                                placeholder={t("claim:expand apartment")}
                                                                                className='form-control inputBox' style={{ width: 508 }}

                                                                                defaultValue={this.state.condominium_property} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <label className='headerLabel18'>{t("claim:all suites")}</label>
                                                                        </div>
                                                                        <div>
                                                                            <span>{t("claim:unit")}</span>
                                                                            <input type={"text"} name='unit_number'
                                                                                onChange={(e) => this.setState({ unit_number: e.target.value })}
                                                                                placeholder={t("claim:all suites")}
                                                                                className='form-control inputBox' style={{ width: 508 }}

                                                                                defaultValue={this.state.unit_number} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <label className='headerLabel18'>{t("claim:per suite")} <i className='text-red'>*</i></label>
                                                                        </div>
                                                                        <div>
                                                                            <span>{t("claim:bath")}</span>
                                                                            <CurrencyInput
                                                                                id="insurance_per_unit"
                                                                                name="insurance_per_unit"
                                                                                className='form-control inputBox'
                                                                                style={{ width: 508 }}
                                                                                placeholder={t("claim:per suite")}
                                                                                defaultValue={this.state.insurance_per_unit}
                                                                                decimalsLimit={2}

                                                                                onValueChange={(value, name) => this.setState({ insurance_per_unit: value })}
                                                                                maxLength={16}
                                                                            />
                                                                            <input type={"text"} required defaultValue={this.state.insurance_per_unit}
                                                                                style={{ position: 'absolute', zIndex: -1, top: 10 }}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกช่องนี้ด้วย')}
                                                                                onInput={e => e.target.setCustomValidity('')} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <label className='headerLabel18'>{t("claim:Total sum insured")}</label>
                                                                        </div>
                                                                        <div>
                                                                            <span>{t("claim:bath")}</span>
                                                                            <CurrencyInput
                                                                                id="sum_insurance_unit"
                                                                                name="sum_insurance_unit"
                                                                                className='form-control inputBox'
                                                                                style={{ width: 508 }}
                                                                                placeholder={t("claim:Total sum insured")}
                                                                                defaultValue={this.state.sum_insurance_unit}
                                                                                decimalsLimit={2}

                                                                                onValueChange={(value, name) => this.setState({ sum_insurance_unit: value })}
                                                                                maxLength={16}
                                                                            />
                                                                            <input type={"text"} defaultValue={this.state.sum_insurance_unit}
                                                                                style={{ position: 'absolute', zIndex: -1, top: 10 }} />
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                                <div className='col-12' style={{ marginTop: 96 }}>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <label className='headerLabel18'>{t("claim:Protection period Start")} <i className='text-red'>*</i></label>
                                                                        </div>
                                                                        <div className='datePicker'>
                                                                            <DatePicker
                                                                                name='insurance_start_date'
                                                                                className="form-control searchDateBox"
                                                                                selected={this.state.insurance_start_date}
                                                                                onChange={(e) => this.setState({ insurance_start_date: e })}
                                                                                dateFormat="dd/MM/yyyy" placeholderText='dd/mm/yyyy'
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 mt-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <label className='headerLabel18'>{t("claim:Protection period End")} <i className='text-red'>*</i></label>
                                                                        </div>
                                                                        <div className='datePicker'>
                                                                            <DatePicker
                                                                                name='insurance_end_date'
                                                                                className="form-control searchDateBox"
                                                                                selected={this.state.insurance_end_date}
                                                                                onChange={(e) => this.setState({ insurance_end_date: e })}
                                                                                dateFormat="dd/MM/yyyy" placeholderText='dd/mm/yyyy'
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 mt-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <label className='headerLabel18'>{t("claim:premium")} <i className='text-red'>*</i></label>
                                                                        </div>
                                                                        <div>
                                                                            <span>{t("claim:bath")}</span>
                                                                            <CurrencyInput
                                                                                id="insurance_premium"
                                                                                name="insurance_premium"
                                                                                className='form-control inputBox'
                                                                                style={{ width: 508 }}
                                                                                placeholder={t("claim:premium")}
                                                                                defaultValue={this.state.insurance_premium}
                                                                                decimalsLimit={2}

                                                                                onValueChange={(value, name) => this.setState({ insurance_premium: value })}
                                                                                maxLength={16}
                                                                            />
                                                                            <input type={"text"} required defaultValue={this.state.insurance_premium}
                                                                                style={{ position: 'absolute', zIndex: -1, top: 10 }}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกช่องนี้ด้วย')}
                                                                                onInput={e => e.target.setCustomValidity('')} />
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className='col-12' style={{ marginTop: 96 }}>
                                                                    <label className='headerLabel'>{t("claim:Policy conditions")}</label>
                                                                </div>

                                                                <div className='col-12 mt-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <label className='headerLabel18'>{t("claim:Protection limit")} <i className='text-red'>*</i></label>
                                                                        </div>
                                                                        <div>
                                                                            <span>{t("claim:bath")}</span>
                                                                            <CurrencyInput
                                                                                id="coverage_limit"
                                                                                name="coverage_limit"
                                                                                className='form-control inputBox'
                                                                                style={{ width: 508 }}
                                                                                placeholder={t("claim:Protection limit")}
                                                                                defaultValue={this.state.coverage_limit}
                                                                                decimalsLimit={2}

                                                                                onValueChange={(value, name) => this.setState({ coverage_limit: value })}
                                                                                maxLength={16}
                                                                            />
                                                                            <input type={"text"} required defaultValue={this.state.coverage_limit}
                                                                                style={{ position: 'absolute', zIndex: -1, top: 10 }}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกช่องนี้ด้วย')}
                                                                                onInput={e => e.target.setCustomValidity('')} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* แนบไฟล​ */}

                                                                <div className='col-12' style={{ marginTop: 86 }}>
                                                                    {/* <label className='headerLabel'>{t("claim:Documentation")} <span className='text-red'>*</span></label> */}
                                                                    <label className='headerLabel'>{t("claim:Documentation")} </label>
                                                                </div>

                                                                <div className='col-12 mt-2'>
                                                                    <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                                                        <button className="btn"><img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />{t("silvermanguard:Choose File")} </button>
                                                                        <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>
                                                                            {t("Allaction:TypeJust")} pdf, png, jpg, Excel(xlsx, xlx), Word(doc, docx) {t("Allaction:only")} {t("silvermanguard:Max10MB")}
                                                                        </span>

                                                                        <input type="file" name='file' id="uploadFile"
                                                                            className='form-control uploadFile' placeholder='uploadFile' accept='.pdf,.png,.jpg,.docx,.doc,.xlsx,.xls'
                                                                            onChange={this.uploadFile}
                                                                        />
                                                                    </label>
                                                                </div>


                                                                <div className='col-12 mt-2'>
                                                                    {
                                                                        this.state.file_insurance.length > 0 &&
                                                                        <table className='table boxFile'>
                                                                            <tbody>
                                                                                {
                                                                                    this.state.file_insurance.map((file, index) => {
                                                                                        let typeFile = file.file_name.split(".")
                                                                                        let typeFiles =
                                                                                            typeFile.includes("docx") ? "docx" :
                                                                                                typeFile.includes("doc") ? "doc" :
                                                                                                    typeFile.includes("xls") ? "xls" :
                                                                                                        typeFile.includes("xlsx") ? "xlsx" :
                                                                                                            typeFile.includes("pdf") ? "pdf" :
                                                                                                                typeFile.includes("jpeg") ? "jpeg" :
                                                                                                                    typeFile.includes("jpg") ? "jpg" : "png"
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td style={{ width: "10%", verticalAlign: 'top' }}>
                                                                                                    <img src={`${process.env.PUBLIC_URL}/images/typeFiles/${typeFiles}Icon.png`} style={{ width: '30px' }}
                                                                                                    />
                                                                                                </td>
                                                                                                <td style={{ width: "80%", verticalAlign: 'top' }}>
                                                                                                    <Link
                                                                                                        to="route"
                                                                                                        onClick={(event) => {
                                                                                                            event.preventDefault();
                                                                                                            window.open(
                                                                                                                typeof file.file_upload === "string" || file.viewPtath
                                                                                                                    ? file.viewPtath ? file.viewPtath : file.file_upload
                                                                                                                    : URL.createObjectURL(file.viewPtath ? file.viewPtath : file.file_upload)
                                                                                                            )
                                                                                                        }}
                                                                                                        target="blank">{file.file_name}</Link>
                                                                                                </td>
                                                                                                <td style={{ width: "10%", textAlign: 'right', verticalAlign: 'top' }} onClick={() => this.dateFile(index, file)}>
                                                                                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/chat/closeGray03.svg"} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className='col-12 text-right mt-5'>
                                                                <button type='button' className='btn-outline-blue' style={{ width: 128, height: 48 }}
                                                                    onClick={() => this.props.history.push("/purchasing/insurrance")}>{t("Allaction:cancel")}</button>

                                                                <button type='submit' className='btn-blue-primary' style={{ width: 128, height: 48 }} >{t("Allaction:next")}</button>
                                                            </div>
                                                        </form>
                                                    </React.Fragment>
                                                }

                                                {
                                                    this.state.dataStep2 && this.state.dataStep1Finish &&
                                                    <React.Fragment>
                                                        <form onSubmit={this.onFinishSe}>
                                                            <div className='onFinishSe'>

                                                                <div className='col-12'>
                                                                    <label className='headerLabel'>{t("claim:PrimaryInsurer")}</label>
                                                                    <br />
                                                                    <label className='label16 mt-5'>{t("claim:PictureLogoInsurrance")}</label>
                                                                    <br />

                                                                    {
                                                                        this.state.main_insurance_company_id !== "other" ?
                                                                            <img src={this.state.main_insurance_company_id.logo_link}
                                                                                style={{ width: 124, height: 124, borderRadius: "50%", marginTop: 12, objectFit: 'contain' }}
                                                                                onError={({ currentTarget }) => {
                                                                                    currentTarget.onerror = null; // prevents looping
                                                                                    currentTarget.src = process.env.PUBLIC_URL + '/images/iconAction/claim/logoCompany.png';
                                                                                }} />
                                                                            :
                                                                            <img src={process.env.PUBLIC_URL + '/images/iconAction/claim/logoCompany.png'}
                                                                                style={{ width: 124, height: 124 }} />
                                                                    }
                                                                </div>

                                                                {/* main_insurance_company_id */}
                                                                <div className='col-12 mt-5 mb-1'>
                                                                    <label className='label16'>{t("claim:CompanyName")}</label>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <div className='seletedInputBox'>
                                                                            <Select
                                                                                placeholder={t("claim:CompanyName")}
                                                                                options={this.state.dataInsuranceCompany}
                                                                                isSearchable={true}
                                                                                onChange={(e) => this.SeleteCompanyName(e, null, true)}
                                                                            />
                                                                            <input name="main_insurance_company_id" type="text" value={this.state.main_insurance_company_id || ""}
                                                                                style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                required={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                {
                                                                    this.state.main_insurance_company_id.value === "other" &&
                                                                    <div className='col-12 mt-5 mb-1'>
                                                                        <label className='label16'>{t("claim:Company Name Other")}</label>
                                                                        <div style={{ position: 'relative' }}>
                                                                            <div className='seletedInputBox'>
                                                                                <input type={'text'} name='main_insurance_company_name' className='form-control'
                                                                                    // onChange={(e) => this.updateCoinsurance(e)}
                                                                                    onChange={(e) => this.setState({ co_insurance: _.set(_.cloneDeep(this.state.co_insurance), '[0].name', e.target.value), main_insurance_company_name: e.target.value })}
                                                                                    defaultValue={this.state.main_insurance_company_name}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                {/* insurance_company_address */}

                                                                <div className='col-12 mt-2 mb-1'>
                                                                    <label className='label16 mt-5'>{t("claim:CompanyAddress")}</label>
                                                                    <textarea
                                                                        onChange={(e) => this.setState({ insurance_company_address: e.target.value })}
                                                                        className='form-control textareaBox'
                                                                        placeholder={t("claim:CompanyAddress")}
                                                                        defaultValue={this.state.insurance_company_address}
                                                                        style={{ resize: 'none' }}
                                                                    />
                                                                </div>


                                                                {/* main_insurance_company_tax_number */}
                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <label className='headerLabel18'>{t("claim:TaxId")}</label>
                                                                        </div>

                                                                        <div>
                                                                            <input type={"text"} name='main_insurance_company_tax_number'
                                                                                onChange={(e) => this.setState({ main_insurance_company_tax_number: e.target.value })}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกแค่ตัวเลขเท่านั้น 13 หลัก')}
                                                                                onInput={e => e.target.setCustomValidity('')}
                                                                                placeholder={t("claim:TaxId")}
                                                                                pattern='[0-9]{13}'
                                                                                maxLength={13} minLength={13}
                                                                                className='form-control inputBox' style={{ width: 508 }}
                                                                                defaultValue={this.state.main_insurance_company_tax_number} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* WebSite */}

                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <img src={process.env.PUBLIC_URL + '/images/iconAction/claim/language.png'}
                                                                                className='imgIcon' />
                                                                            <label className='headerLabel18'>{t("claim:WebSite")}</label>
                                                                        </div>
                                                                        <div>
                                                                            {/* <span>{t("claim:unit")}</span> */}
                                                                            <input type={"url"} name='main_insurance_company_website'
                                                                                onChange={(e) => this.setState({ main_insurance_company_website: e.target.value })}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกเป็นลิงค์ URL ให้ถูกต้อง')}
                                                                                onInput={e => e.target.setCustomValidity('')}
                                                                                placeholder={t("claim:WebSite")}
                                                                                className='form-control inputBox' style={{ width: 508 }}
                                                                                defaultValue={this.state.main_insurance_company_website} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* Phone */}
                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <img
                                                                                className='imgIcon'
                                                                                src={process.env.PUBLIC_URL + '/images/iconAction/claim/phone.png'} />
                                                                            <label className='headerLabel18'>{t("claim:Phone")}</label>
                                                                        </div>
                                                                        <div>
                                                                            {/* <span>{t("claim:bath")}</span> */}
                                                                            <input type={"tel"} name='main_insurance_company_tel'
                                                                                onChange={(e) => this.setState({ main_insurance_company_tel: e.target.value })}
                                                                                pattern='[0-9]+$'
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขเท่านั้น')}
                                                                                onInput={e => e.target.setCustomValidity('')}
                                                                                placeholder={t("claim:Phone")}
                                                                                className='form-control inputBox' style={{ width: 508 }}
                                                                                defaultValue={this.state.main_insurance_company_tel} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* Email */}
                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <img
                                                                                className='imgIcon'
                                                                                src={process.env.PUBLIC_URL + '/images/iconAction/claim/mail.png'} />
                                                                            <label className='headerLabel18'>{t("claim:Email")}</label>
                                                                        </div>
                                                                        <div>
                                                                            {/* <span>{t("claim:bath")}</span> */}
                                                                            <input type={"email"} name='main_insurance_company_email'
                                                                                onChange={(e) => this.setState({ main_insurance_company_email: e.target.value })}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกเป็นรูปแบบ Email ให้ถูกต้อง')}
                                                                                onInput={e => e.target.setCustomValidity('')}
                                                                                placeholder={t("claim:Email")}
                                                                                className='form-control inputBox' style={{ width: 508 }}
                                                                                defaultValue={this.state.main_insurance_company_email} />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='col-11 text-right mt-10 pr-1'>

                                                                <button type='button' className='btn-outline-blue' style={{ width: 128, height: 48 }}
                                                                    onClick={() => this.props.history.push("/purchasing/insurrance")}>{t("Allaction:cancel")}</button>
                                                                <button type='button' className='btn-outline-blue ml-5' style={{ width: 128, height: 48 }}
                                                                    onClick={() => this.setState({ dataStep2: false, dataStep1Finish: false, dataStep1: true })}>{t("Allaction:back")}</button>
                                                                <button type='submit' className='btn-blue-primary' style={{ width: 128, height: 48 }}>{t("Allaction:next")}</button>
                                                            </div>
                                                        </form>
                                                    </React.Fragment>
                                                }

                                                {
                                                    this.state.dataStep2Finish && this.state.dataStep3 &&
                                                    <React.Fragment>
                                                        <form onSubmit={this.onFinishTh}>
                                                            <div className='onFinishTh'>
                                                                <div className='col-12'>
                                                                    <label className='headerLabel18'>{t("claim:listMainCompany")}</label>
                                                                </div>

                                                                <div className='col-12 mt-5'>
                                                                    <table className='table table-hover'>
                                                                        <thead className='thead-light'>
                                                                            <tr>
                                                                                <th style={{ width: 120 }} />
                                                                                <th style={{ width: 50 }} className='text-center'>{t("claim:thNo")}</th>
                                                                                <th className='text-left'>{t("claim:thCompany")}</th>
                                                                                <th className='text-center'>{t("claim:thPercent")}</th>
                                                                                <th />
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                this.state.co_insurance.map((n, inx) => {
                                                                                    return (
                                                                                        <tr key={inx}>
                                                                                            <td className='tag text-left'>
                                                                                                {
                                                                                                    inx === 0 &&
                                                                                                    <span className='tagDanger'>{t("claim:thMain")}</span>
                                                                                                }
                                                                                            </td>
                                                                                            <td className='text-center'>
                                                                                                <span className='text-black label16'>
                                                                                                    {inx + 1}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td  >
                                                                                                {
                                                                                                    inx === 0 ?
                                                                                                        <input type={'text'} readOnly defaultValue={n.name}
                                                                                                            className='form-control text-center' />
                                                                                                        :
                                                                                                        <div style={{ position: 'relative' }}>
                                                                                                            <div className='seletedInputBox'>
                                                                                                                <Select
                                                                                                                    placeholder={t("claim:CompanyName")}
                                                                                                                    options={this.state.dataInsuranceCompany.filter((dataInsuranceCompany) =>
                                                                                                                        dataInsuranceCompany.id !== n.co_insurance_company_id && dataInsuranceCompany.id !== this.state.main_insurance_company_id.id
                                                                                                                    )}
                                                                                                                    isSearchable={false}
                                                                                                                    onChange={(e) => this.SeleteCompanyName(e, inx, false)}
                                                                                                                />
                                                                                                                <input name="main_insurance_company_id" type="text" value={this.state.main_insurance_company_id || ""}
                                                                                                                    style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                                                    onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                                                    required={true} />
                                                                                                            </div>
                                                                                                        </div>

                                                                                                }
                                                                                            </td>
                                                                                            <td className='text-center' style={{ alignSelf: 'center' }}>
                                                                                                <input type={'number'} max={100}
                                                                                                    onChange={(e) => this.co_insuranceUpdate(n, inx, e)}
                                                                                                    onKeyDown={(e) => this.co_insuranceUpdate(n, inx, e)}
                                                                                                    defaultValue={n.insurance_ratio}
                                                                                                    className='form-control text-center inputBoxPercent' placeholder='0.00' />
                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    n.status !== "main" &&
                                                                                                    <img src={process.env.PUBLIC_URL + "/images/icons/deleteRed.png"} sizes='16'
                                                                                                        onClick={() => this.deleteListInsur(inx)} />
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </tbody>
                                                                    </table>

                                                                    <label className='label16 colorBlue mt-10'
                                                                        onClick={this.addListInsur}
                                                                    >+ {t("claim:addList")}</label>
                                                                </div>

                                                            </div>
                                                            <div className='col-12 text-right mt-10'>
                                                                <button type='button' className='btn-outline-blue'
                                                                    onClick={() => this.props.history.push("/purchasing/insurrance")}
                                                                    style={{ width: 128, height: 48 }}>{t("Allaction:cancel")}</button>
                                                                <button type='button' className='btn-outline-blue ml-5' style={{ width: 128, height: 48 }}
                                                                    onClick={() => this.setState({ dataStep3: false, dataStep2Finish: false, dataStep2: true })}>{t("Allaction:back")}</button>
                                                                <button type='submit' className='btn-blue-primary'
                                                                    style={{ width: 128, height: 48 }}>{t("Allaction:next")}</button>
                                                            </div>
                                                        </form>
                                                    </React.Fragment>
                                                }

                                                {
                                                    this.state.dataStep3Finish && this.state.dataStep4 &&
                                                    <React.Fragment>
                                                        <form onSubmit={this.onSubmit}>
                                                            <div className='onSubmit'>

                                                                <div className='col-12'>
                                                                    <label className='headerLabel'>{t("claim:insurance broker")}</label>
                                                                </div>

                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div style={{ display: 'inline-flex' }}>
                                                                        <div style={{ marginRight: 32 }}>
                                                                            <label className='label16'>{t("claim:FirsthName")}</label>
                                                                            <input type={"text"} name='broker_first_name'
                                                                                onChange={(e) => this.setState({ broker_first_name: e.target.value })}
                                                                                placeholder={t("claim:FirsthName")}
                                                                                className='form-control inputBox' style={{ width: 332 }}
                                                                                defaultValue={this.state.broker_first_name} />
                                                                        </div>

                                                                        <div>
                                                                            <label className='label16'>{t("claim:LastName")}</label>
                                                                            <input type={"text"} name='broker_last_name'
                                                                                onChange={(e) => this.setState({ broker_last_name: e.target.value })}
                                                                                placeholder={t("claim:LastName")}
                                                                                className='form-control inputBox' style={{ width: 332 }}
                                                                                defaultValue={this.state.broker_last_name} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* insurance_company_address */}

                                                                <div className='col-12 mt-2 mb-1'>
                                                                    <label className='label16 mt-5'>{t("claim:CompanyAddress")}</label>
                                                                    <textarea
                                                                        onChange={(e) => this.setState({ broker_company_address: e.target.value })}
                                                                        name='broker_company_address'
                                                                        className='form-control textareaBox'
                                                                        placeholder={t("claim:CompanyAddress")}
                                                                        defaultValue={this.state.broker_company_address}
                                                                        style={{ resize: 'none' }}
                                                                    />
                                                                </div>


                                                                {/* main_insurance_company_tax_number */}
                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <label className='headerLabel18'>{t("claim:TaxId")}</label>
                                                                        </div>

                                                                        <div>
                                                                            <input type={"text"} name='broker_tax_number'
                                                                                onChange={(e) => this.setState({ broker_tax_number: e.target.value })}
                                                                                placeholder={t("claim:TaxId")}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกแค่ตัวเลข 13 หลักเท่านั้น')}
                                                                                onInput={e => e.target.setCustomValidity('')}
                                                                                pattern='[0-9]{13}'
                                                                                maxLength={13} minLength={13}
                                                                                className='form-control inputBox' style={{ width: 508 }}
                                                                                defaultValue={this.state.broker_tax_number} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* Phone */}
                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <img
                                                                                className='imgIcon'
                                                                                src={process.env.PUBLIC_URL + '/images/iconAction/claim/phone.png'} />
                                                                            <label className='headerLabel18'>{t("claim:Phone")}</label>
                                                                        </div>
                                                                        <div>
                                                                            {/* <span>{t("claim:bath")}</span> */}
                                                                            <input type={"text"} name='broker_tel'
                                                                                onChange={(e) => this.setState({ broker_tel: e.target.value })}
                                                                                placeholder={t("claim:Phone")}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกแค่ตัวเลขเท่านั้น')}
                                                                                onInput={e => e.target.setCustomValidity('')}
                                                                                pattern='[0-9]+$'
                                                                                className='form-control inputBox' style={{ width: 508 }}
                                                                                defaultValue={this.state.broker_tel} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* Email */}
                                                                <div className='col-12 mt-2 mb-2'>
                                                                    <div className='divinlineFlex'>
                                                                        <div className='divInlineWithInput'>
                                                                            <img
                                                                                className='imgIcon'
                                                                                src={process.env.PUBLIC_URL + '/images/iconAction/claim/mail.png'} />
                                                                            <label className='headerLabel18'>{t("claim:Email")}</label>
                                                                        </div>
                                                                        <div>
                                                                            {/* <span>{t("claim:bath")}</span> */}
                                                                            <input type={"email"} name='main_insurance_company_email'
                                                                                onChange={(e) => this.setState({ main_insurance_company_email: e.target.value })}
                                                                                placeholder={t("claim:Email")}
                                                                                onInvalid={e => e.target.setCustomValidity('โปรดกรอกเป็นรูปแบบ Email ให้ถูกต้อง')}
                                                                                onInput={e => e.target.setCustomValidity('')}
                                                                                className='form-control inputBox' style={{ width: 508 }}
                                                                                defaultValue={this.state.broker_email} />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='col-11 text-right mt-10 pr-1'>
                                                                <button type='button' className='btn-outline-blue' style={{ width: 128, height: 48 }}
                                                                    onClick={() => this.props.history.push("/purchasing/insurrance")}>{t("Allaction:cancel")}</button>
                                                                <button type='button' className='btn-outline-blue ml-5' style={{ width: 128, height: 48 }}
                                                                    onClick={() => this.setState({ dataStep4: false, dataStep3Finish: false, dataStep3: true })}>{t("Allaction:back")}</button>
                                                                <button type='submit' className='btn-blue-primary' style={{ width: 128, height: 48 }}>{t("Allaction:save")}</button>
                                                            </div>
                                                        </form>

                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default withRouter(CreateInsur);
