import React, { Component } from 'react';
import Swal from "sweetalert2";
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import { decode, encode } from 'base-64'; 
import APIClaim from '../../../../api/claim' 


import { format } from 'date-fns';
class SummarizeClaimApprove extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            id: null,
            loading: true
        }
        this.onFinishCliam =this.onFinishCliam.bind(this)
    }
    componentWillUnmount() { }
    componentWillMount() {
        APIClaim.GetCliamID(decode(this.props.id)).then((res)=> {
            if(res.data.claim){
                this.setState({data: res.data.claim ,  id: decode(this.props.id) , loading: false },()=> {
                    // console.log(this.state);
                })
            }
        })
    }

    onFinishCliam(){
        Swal.fire({
            title: i18n.t("Allaction:This will only take a moment, please wait"),
            type: 'info',
            showLoaderOnConfirm: true,
            showCloseButton: false,
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 3000,
            allowOutsideClick: () => !Swal.isLoading(),
        }).then(() => {
            let data ={status: 'finish' , finish_date: format(new Date(), "DD/MM/YYYY")}
            APIClaim.UpdateCliam(data, parseInt(this.state.id)).then((res) => {
                if (res.data.statusCode === "0000") {
                    Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                        this.props.history.push(`/purchasing/claim/summarize/${this.props.id}/${encode("finish")}`)
                    })
                } else {
                    Swal.fire(i18n.t('settingAccount:Failed to save', "", "warning"))
                }
            })
        })
    }


    render() {
        return (
            this.state.loading == false &&
            this.state.data?.claim_detail?.claim_type === "public"?
            <React.Fragment>
                <label className='headerLabel28'>สรุปค่าสินไหม</label>
                <hr /> <br />

                <div style={{ flexDirection: 'row' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <div className="w250">
                            <label className='headerLabel18'>จำนวนเงินที่ขอเคลม <span className='text-red'></span> (บาท)</label>
                            <input readOnly value={parseInt(this.state.data?.claim_detail?.claim_amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                className='form-control inputBox text-right w222' />
                        </div>

                        <div className="w250">
                            <label className='headerLabel18'>จำนวนเงินเคลมที่อนุมัติ <span className='text-red'></span> (บาท)</label>
                            <input readOnly value={parseInt(this.state.data?.claim_detail?.approved_amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                className='form-control inputBox text-right w222' />
                        </div>
                    </div>
                </div>

                <div style={{ flexDirection: 'row' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <div className="w250 mt-3">
                            <label className='headerLabel18'>ส่วนต่างค่าสินไหม <span className='text-red'></span> (บาท)</label>
                            <input readOnly value={parseInt( this.state.data?.claim_detail?.approved_amount - this.state.data?.claim_detail?.claim_amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                className='form-control inputBox text-right w222' />
                        </div>
                    </div>
                </div> 


                <div className='row mt-2'>
                    <div className='col-12 text-right'>
                        <button type='button' onClick={this.onFinishCliam} className='btn btn-outline bg1567B4-textfff m-2'>{"รับค่าสินไหม และปิดเคลม"}</button>
                    </div>
                </div>                
                    
            </React.Fragment>
            :

            <div className='ResultClaim text-center' style={{ marginTop: 100 }}>
                <img src={process.env.PUBLIC_URL + '/images/iconAction/logo.png'} />
                <p className='mt-9 mb-2'>
                    <label className='headerLabel18'>รอการยืนยันรับสินไหม</label>
                </p>
                <p>
                    <label className='label16 mt-2' style={{fontWeight: 300}}>กรุณาให้ลูกบ้านยืนยันรับค่าสินไหม ภายใน 7 วัน</label>
                    <br />
                    <label className='label16 mb-2' style={{fontWeight: 300}}>นับจากได้รับการแจ้งเตือนประกันอนุมัติค่าสินไหม</label>

                </p>
            </div>
        )
    }
}

export default withRouter(SummarizeClaimApprove);
