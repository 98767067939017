import './styles/footer.scss';
import React from 'react';
import { Link } from "react-router-dom";

const Footer = (props) => {

    let { langauge } = props;
    return (
        <div className="row" id="footer">
            <div className="col-md-3" style={{ display: 'flex', justifyContent:'flex-start'}}>
                <Link to="/privacy-and-policy">
                    <h6 className=" text-style">
                        <small>
                            {langauge === "th" ? 'นโยบายคุ้มครองข้อมูลส่วนบุคคล' : 'Privacy Policy'}
                        </small>
                    </h6>
                </Link>
            </div>
            <div className="col-md ">
                <div className="row social">
                    <div>
                        <a href="mailto:hello@silverman.app" target="_blank" className="row">
                            <img src={process.env.PUBLIC_URL + '/images/icons/mail-icon.png'}
                                alt="mail-icon" className="icons"/>
                            <h6 className="text-style text-social"><small>hello@silverman.app</small></h6>
                        </a>
                    </div>
                    <div>
                        <a href="https://lin.ee/l0VpSQF" target="_blank" className="row">
                            <img src={process.env.PUBLIC_URL + '/images/icons/line.png'}
                                alt="line-icon"
                                className="icons" />
                            <h6 className="text-style text-social"><small>@SilvermanApp</small></h6>
                        </a>
                    </div>
                    <div>
                        <a href="https://www.facebook.com/SilvermanApp/" target="_blank" className="row">
                            <img src={process.env.PUBLIC_URL + '/images/icons/facebook-grey.png'}
                                alt="facebook-icon"
                                className="icons"/>
                            <h6 className="text-style text-social"><small>Silverman App</small></h6>
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-md-3 ">
                <div className="row third-box text-style" >
                <a href="https://g.page/r/CX60PqUHZlp3EAg/review" target="_blank" >
                    <h6 className=" text-style">
                        <small>
                            {langauge === "th" ? 'ให้คะแนน Silverman' : 'Review Silverman'}
                        </small>
                    </h6>
                </a>
                </div>
            </div>
        </div>

    )
}
export default Footer