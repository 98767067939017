import React, { Component } from 'react';
import Swal from "sweetalert2";
import API from "../../api/setting"
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import './settingSecurity.scss';
import i18n from "i18next";
class SettingSecutiy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            settingLicensePlateRecognitionId: null,
            ircActive: null
        }
        this.saveActiveLPR = this.saveActiveLPR.bind(this)
    }

    alertActive() {
        Swal.fire({
            icon: 'error',
            title: 'ไม่สามารถบันทึกได้',
            text: 'โปรดระบุการเชื่อมต่อฐานข้อมูลจากกล้องกับระบบรักษาปลอดภัยและผู้มาติดต่อ ใช่ หรือ ไม่'
        })
    }

    componentWillMount() {
        this.getDataLPR();
    }

    getDataLPR() {
        API.getDataLPR().then((res) => {
            if (res.data.settingLicensePlateRecognition) {
                this.setState({
                    settingLicensePlateRecognitionId: res.data.settingLicensePlateRecognition.settingLicensePlateRecognitionId,
                    ircActive: res.data.settingLicensePlateRecognition.ircActive,
                })
            }
        })
    }

    saveActiveLPR(e) {
        e.preventDefault();
        let Data = {
            "settingLicensePlateRecognitionId": this.state.settingLicensePlateRecognitionId != null ? this.state.settingLicensePlateRecognitionId : '',
            "ircActive": this.state.ircActive
        }

        API.saveLPR(Data).then((res) => {
            if (res.data) {
                Swal.fire(i18n.t('settingToolandConnect:successfully'), i18n.t('settingToolandConnect:savedSuccessfully'), 'success')
            } else {
                Swal.fire(i18n.t('settingToolandConnect:unsuccessful'), i18n.t('settingToolandConnect:saveFailed'), 'error')
            }

        }, () => Swal.fire(i18n.t('settingToolandConnect:unsuccessful'), i18n.t('settingToolandConnect:Unable to connect to the API at this time.'), 'error'))
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid box" id="settingConnectLPR">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('setting:ToolandConnection')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>

                        <br />
                        <br />
                        <form method='post' onSubmit={this.saveActiveLPR}>
                            <Translation>
                                {t =>
                                    <React.Fragment>
                                        <div style={{ width: "804px", marginLeft: "15%" }}>
                                            <div className="content-inner" id="projectDashboard">

                                                <h4 className='text-left '>{t('settingSecurity:LPRYesOrNo')}</h4>


                                                <label className="containerRadio" style={{ marginTop: '26px' }}>
                                                    {t('settingSecurity:yes')}
                                                    <input type="radio" name="ircActive"
                                                        checked={this.state.ircActive == true}
                                                        onChange={() => this.setState({ ircActive: true })} />
                                                    <span className="checkmark"></span>
                                                </label>

                                                <label className="containerRadio" style={{ marginTop: '16px' }}>
                                                    {t('settingSecurity:no')}
                                                    <input type="radio" name="ircActive"
                                                        checked={this.state.ircActive == false}
                                                        onChange={() => this.setState({ ircActive: false })} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>



                                        <div className='pullright' style={{ marginRight: "10%" }}>
                                            <button type="submit" style={{ marginRight: "8%" }}
                                                className="btn btn-primary add mr-1"
                                                disabled={this.state.ircActive == null}>{t('Allaction:save')}</button>
                                            <button type="button"
                                                className="btn btn-primary-outline mr-1">{t('Allaction:cancel')}</button>
                                        </div>
                                    </React.Fragment>

                                }
                            </Translation>

                        </form>


                    </div>


                </WrapperContent>
            </Wrapper>
        )
    }
}

export default SettingSecutiy;
