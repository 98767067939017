/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type collectionLawFirmListTableQueryVariables = {||};
export type collectionLawFirmListTableQueryResponse = {|
  +allContactCollectionLawFirm: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +refNumber: string,
        +name: string,
        +collectionLettersLastId: ?string,
        +collectionLettersSumTotal: ?number,
        +collectionLettersIssuedDate: ?any,
        +collectionLettersDateCreate: ?any,
        +collectionLettersCreator: ?string,
        +collectionLettersFee: ?number,
        +firstName: string,
        +lastName: string,
      |}
    |}>,
  |}
|};
export type collectionLawFirmListTableQuery = {|
  variables: collectionLawFirmListTableQueryVariables,
  response: collectionLawFirmListTableQueryResponse,
|};
*/


/*
query collectionLawFirmListTableQuery {
  allContactCollectionLawFirm {
    totalCount
    edges {
      node {
        id
        refNumber
        name
        collectionLettersLastId
        collectionLettersSumTotal
        collectionLettersIssuedDate
        collectionLettersDateCreate
        collectionLettersCreator
        collectionLettersFee
        firstName
        lastName
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContactNodeConnection",
    "kind": "LinkedField",
    "name": "allContactCollectionLawFirm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collectionLettersLastId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collectionLettersSumTotal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collectionLettersIssuedDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collectionLettersDateCreate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collectionLettersCreator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collectionLettersFee",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionLawFirmListTableQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "collectionLawFirmListTableQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4a4dd1906ebe2365e9d055d8171ff41f",
    "id": null,
    "metadata": {},
    "name": "collectionLawFirmListTableQuery",
    "operationKind": "query",
    "text": "query collectionLawFirmListTableQuery {\n  allContactCollectionLawFirm {\n    totalCount\n    edges {\n      node {\n        id\n        refNumber\n        name\n        collectionLettersLastId\n        collectionLettersSumTotal\n        collectionLettersIssuedDate\n        collectionLettersDateCreate\n        collectionLettersCreator\n        collectionLettersFee\n        firstName\n        lastName\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6cd929ab907c9486859ce54a023e32b5';

module.exports = node;
