import { _ } from 'core-js';
import React, { Component } from 'react'
import SearchSelect from '../../../libs/searchSelect';
import DatePickerNoti from '../../../accounting/finance/depositInterest/datePickerNoti';
import { differenceInCalendarMonths } from 'date-fns';
import "../style/manageOrder.scss"
import i18next from 'i18next';
import { Translation } from 'react-i18next';

const customStyles = {
  control: (style, { isDisabled }) => ({
    ...style,
    height: 32,
    minHeight: 32,
    backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF'
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none'
  }),
};

export default class formOrderList extends Component {
  render() {

    let contractPeriod = this.props.procurementForm.contractPeriod ? this.props.procurementForm.contractPeriod : differenceInCalendarMonths(this.props.procurementForm.endContractDate, this.props.procurementForm.startContractDate)

    return (
      <Translation>
        {t =>
          <div id="formOrderList">
            <div className="row">
              <div className="col-12">
                <h4>{i18next.t("formOrderList:Purchase Order")}</h4>
              </div>
            </div>

            {(this.props.type === "service_contact" || this.props.type === "SERVICE_CONTACT") &&
              <div className="row" id="formControl-formOrderList-big">
                <div className="col-2 customDatePickerWidth">
                  <h6>{i18next.t("formOrderList:Start Date of Contract")}</h6>
                  <DatePickerNoti
                    disabled
                    className="form-control"
                    selected={this.props.procurementForm.startContractDate}
                  />
                </div>
                <div className="col-2 customDatePickerWidth">
                  <h6>{i18next.t("formOrderList:End Date of Contract")}</h6>
                  <DatePickerNoti
                    disabled
                    className="form-control"
                    selected={this.props.procurementForm.endContractDate}
                  />
                </div>
                <div className="col-2">
                  <h6>{i18next.t("formOrderList:Contract Duration (Months)")}</h6>
                  <input
                    type="number"
                    className="form-control"
                    value={contractPeriod}
                    disabled
                  />
                </div>
              </div>
            }

            <div className="row">
              <div className="col-12 mt-5">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th>{i18next.t("formOrderList:Item")}</th>
                        <th>{i18next.t("formOrderList:Description")}</th>
                        <th className="text-center">{i18next.t("formOrderList:Quantity")}</th>
                        <th className="text-center">{i18next.t("formOrderList:Unit Price (THB)")}</th>
                        {(this.props.type === "ASSET" || this.props.type === "asset") &&
                          <th className="text-center">{i18next.t("formOrderList:Unit")}</th>
                        }
                        <th className="text-center">{i18next.t("formOrderList:Budget (THB)")}</th>
                        <th className="text-center">{i18next.t("formOrderList:Value Added Tax (VAT)")}</th>
                        <th className="text-center">{i18next.t("formOrderList:Withholding Tax")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(this.props.procurementList, (item, index) => {
                        return (
                          <tr key={index}>
                            <td width="240">
                              <SearchSelect
                                disabled={true}
                                value={item.node.productAndService ? item.node.productAndService.id : item.node.chartOfAccount.id}
                                queryObject={this.props.productAndServiceList}
                                keyOfValue="id"
                                keyOfLabel="productCode:name"
                                style={customStyles}
                              />
                            </td>
                            <td width="240">
                              <input
                                disabled={true}
                                type="text"
                                className="form-control"
                                value={item.node.description}
                              />
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <button className="btn-subtract opacity" disabled>-</button>
                                <input
                                  disabled={true} type="number"
                                  className="form-control mx-2 text-right"
                                  style={{ width: 80 }}
                                  value={item.node.unitItems}
                                />
                                <button className="btn-plus opacity" disabled>+</button>
                              </div>
                            </td>
                            <td width="160">
                              <input
                                disabled={true} type="number"
                                className="form-control text-right"
                                value={item.node.price}
                              />
                            </td>
                            {(this.props.type === "ASSET" || this.props.type === "asset") &&
                              <td width="120">
                                <input
                                  disabled={true} type="text"
                                  className="form-control"
                                  value={item.node.unit}
                                />
                              </td>
                            }
                            <td width="160">
                              <input
                                disabled={true} type="number"
                                className="form-control text-right"
                                value={item.node.budget}
                              />
                            </td>
                            <td className="text-center">
                              <select
                                className="form-control"
                                value={typeof (item.node.vat) === "number" ? item.node.vat : ""}
                                disabled={true}
                              >
                                <option value="">{i18next.t("formOrderList:None")}</option>
                                <option value="0">VAT 0%</option>
                                <option value="7">VAT 7%</option>
                              </select>
                            </td>
                            <td className="text-center">
                              <select
                                disabled
                                className="form-control"
                                value={typeof (item.node.whtRate) === "number" ? item.node.whtRate : ""}
                              >
                                <option value="">{i18next.t("formOrderList:None")}</option>
                                <option value="1">1%</option>
                                <option value="1.5">1.5%</option>
                                <option value="2">2%</option>
                                <option value="3">3%</option>
                                <option value="5">5%</option>
                                <option value="10">10%</option>
                                <option value="15">15%</option>
                              </select>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        }
      </Translation>
    )
  }
}
