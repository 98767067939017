/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type payChannelQueryVariables = {|
  invoiceId?: ?string
|};
export type payChannelQueryResponse = {|
  +resultNotAutoReconcileLog: ?{|
    +resultCompare: ?string,
    +resultText: ?string,
  |}
|};
export type payChannelQuery = {|
  variables: payChannelQueryVariables,
  response: payChannelQueryResponse,
|};
*/


/*
query payChannelQuery(
  $invoiceId: String
) {
  resultNotAutoReconcileLog(invoiceId: $invoiceId) {
    resultCompare
    resultText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "invoiceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "invoiceId",
        "variableName": "invoiceId"
      }
    ],
    "concreteType": "ResultNotAutoReconcileLogNode",
    "kind": "LinkedField",
    "name": "resultNotAutoReconcileLog",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resultCompare",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resultText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "payChannelQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "payChannelQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "82a94f8fca00a382199caee8b3788fad",
    "id": null,
    "metadata": {},
    "name": "payChannelQuery",
    "operationKind": "query",
    "text": "query payChannelQuery(\n  $invoiceId: String\n) {\n  resultNotAutoReconcileLog(invoiceId: $invoiceId) {\n    resultCompare\n    resultText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76bd138f2c0f019cf08a3b26c29408e3';

module.exports = node;
