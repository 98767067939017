import React from "react";
import "./styles/commonArea.scss";
import "./styles/dropdawn.scss"
import ContactTopMenuNavigation from "../contactTopMenuNavigation";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import ComponentPagination from "../../libs/componentPagination";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { Dropdown } from "reactjs-dropdown-component";
import { bookingService } from "./services";
import "react-dropdown/style.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
const localizer = momentLocalizer(moment);
const color = ["#C3E1FE", "#FF91A3", "#FCEB9A", "#90F295", "#77D7CC"];
/* eslint-disable import/first */
import "react-big-calendar/lib/css/react-big-calendar.css";

// const locations = [
//   {
//     label: "ทั้งหมด",
//     value: "ทั้งหมด"
//   },
//   {
//     label: "เก็บค่าบริการ",
//     value: "เก็บค่าบริการ"
//   },
//   {
//     label: "ไม่เก็บค่าบริการ",
//     value: "ไม่เก็บค่าบริการ"
//   }
// ];

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "#EADDDD"
    }
  });

class Calendars extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      headerTitle: this.props.title,
      isListOpen: false,
      location: [
        {
          label: "เดือน",
          value: "เดือน"
        },
        {
          label: "วัน",
          value: "วัน"
        }
      ],
      view: "month",
      month: true,
      day: false,
      count_reservation: 0,
      sum_service_charge: 0,
      event: [],
      all_facility: [],
      textSearch: "",
      selectedDate: "",
      showDate:
        monthNames[new Date().getMonth()] +
        " " +
        moment(new Date()).format("YYYY"),
      flgService: "",
      facility_id: "",
      all_facility_slot: [],
      selectedDay: new Date()
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  selectItem = (item) => {
    // const { resetThenSet } = this.props;
    const { title, id, key } = item;

    this.setState(
      {
        headerTitle: title,
        isListOpen: false
      },
      () => this.resetThenSet(id, key)
    );
  };

  async handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      await this.onChangeSearch(e);
      this.onClickSearch()
    }
  }

  resetThenSet = (id, key) => {
    const temp = [...this.state[key]];

    temp.forEach((item) => (item.selected = false));
    temp[id].selected = true;

    this.setState({
      [key]: temp
    });
  };

  async toggleItem(id, key) {
    if (id.value === "วัน") {
      await this.setState({
        view: "day"
      });
      this.getDay();
    } else {
      await this.setState({
        view: "month"
      });
      this.getData();
    }
  }

  async goToMore(e) {
    await this.setState({
      view: "day",
      selectedDay: _.get(e[0], 'start')
    });
    this.getDay();
  }

  async componentDidMount() {
    this.getData();
  }

  getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  }

  async getData() {
    const { selectedDate, flgService, facility_id } = this.state;
    let date;
    let year;
    if (selectedDate) {
      date = moment(new Date(selectedDate)).format("MM");
      year = moment(new Date(selectedDate)).format("YYYY");
    } else {
      date = moment(new Date()).format("MM");
      year = moment(new Date()).format("YYYY");
    }

    let token = localStorage.getItem("token");

    await bookingService
      .month_booking(
        token,
        date,
        year,
        this.state.textSearch,
        flgService,
        facility_id
      )
      .then((res) => {
        const data = res.data;
        const day_slot = _.get(data, "day_slot");
        const all_facility = _.get(data, "all_facility");
        let dataArray = [];
        Object.values(day_slot).map((item, index) => {
          let time = _.get(item, "time_slot");
          time.map((value) => {
            dataArray.push({
              id: _.get(value, "id"),
              title: `${moment(_.get(value, "start_time")).utc().format("HH:mm - ")}${moment(_.get(value, "end_time")).utc().format("HH:mm")} ${_.get(value, "facility_name")}`,
              allDay: false,
              start: moment(_.get(value, "start_time")).utc().format("YYYY-MM-DD HH:mm"),
              end: moment(_.get(value, "end_time")).utc().format("YYYY-MM-DD HH:mm"),
              hexColor: color[this.getRandomInt(5)],
            });
          });
        });
        this.setState({
          count_reservation: _.get(data, "count_reservation"),
          sum_service_charge: _.get(data, "sum_service_charge"),
          event: dataArray,
          all_facility: all_facility
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async onChangeService(text) {
    let value = text.value;
    await this.setState({
      flgService:
        value === "ทั้งหมด" ? "" : value === "เก็บค่าบริการ" ? true : false
    });
    if (this.state.view === "month") {
      this.getData();
    } else {
      this.getDay();
    }
  }

  async eventSelected(event) {
    this.props.history.push({
      pathname: "/contact/global-area-singha/booking/detail/" + event.id,
      state: event.id,
      calendar: true
    });
  }

  async eventSelectedDay(event) {
    this.props.history.push({
      pathname: "/contact/global-area-singha/booking/detail/" + event.id,
      state: event.id,
      calendar: true
    });
  }

  async getDay() {
    let token = localStorage.getItem("token");
    const { selectedDay, flgService, facility_id, textSearch } = this.state;

    await bookingService
      .day_slot(
        token,
        selectedDay
          ? moment(new Date(selectedDay)).format("DD/MM/YYYY")
          : moment(new Date()).format("DD/MM/YYYY"),
        flgService,
        facility_id,
        textSearch
      )
      .then((res) => {
        const data = res.data;
        const all_facility_slot = _.get(data, "all_facility_slot");
        let dataArray = [];
        all_facility_slot.map((item) => {
          if (item.booking_list) {
            item.booking_list.map((value) => {
              let offset = '+7';
              let start_time = new Date(_.get(value, "start_time"))
              let end_time = new Date(_.get(value, "end_time"))
              dataArray.push({
                id: _.get(value, "id"),
                title: item.name,
                start: new Date(start_time.toGMTString() + offset),
                end: new Date(end_time.toGMTString() + offset),
                color: "#E3FFE5"
              });
            });
          }
        });
        this.setState({
          view: "day",
          all_facility_slot: dataArray
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onChangeSearch(value) {
    this.setState({
      textSearch: value.target.value
    });
  }

  async onSelectedDate(date) {
    await this.setState({
      selectedDate: date,
      showDate:
        monthNames[new Date(date).getMonth()] +
        " " +
        moment(new Date(date)).format("YYYY")
    });
    this.getData();
  }

  async onSelectedDay(date) {
    await this.setState({
      selectedDay: date,
      showDate:
        monthNames[new Date(date).getMonth()] +
        " " +
        moment(new Date(date)).format("YYYY")
    });
    this.getDay();
  }

  async onClickSearch() {
    if (this.state.view === "month") {
      this.getData();
    } else {
      this.getDay();
    }
  }

  render() {
    const {
      count_reservation,
      sum_service_charge,
      event,
      all_facility_slot
    } = this.state;
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>

          <ContactTopMenuNavigation mini={true} />
          <Translation>
            {
              t =>
                <div className="container-fluid box">
                  <div className="row justify-content-between">
                    <div className="col">
                      <h3>
                        <Link to="/contact">
                          <img src={process.env.PUBLIC_URL + "/images/wrapperContent/back.png"}
                            alt="back"
                            className="back-booking"
                          />
                        </Link>
                        <Translation>
                          {(t) => <span className="text-header-booking ml-3">{t("newBookingSingha_Booking:Summary of booking schedule")}</span>}
                        </Translation>
                      </h3>
                    </div>
                    <div className="col"></div>
                  </div>
                  <div className="content-inner">
                    <Translation>
                      {(t) => (
                        <div className="row mt-5">
                          <div className="col-4.5 ml-3">
                            <span className="text-header-booking">{t("newBookingSingha_Booking:Booking common area List")}: {count_reservation}</span>
                          </div>
                          <div className="col-5">
                            <span className="text-header-booking">{t("newBookingSingha_Booking:Total Service Charge")}: {sum_service_charge}</span>
                          </div>
                        </div>
                      )}
                    </Translation>
                    <div className="row text-center px-3 justify-content-end mr-0 ml-0 mt-2" width="100%">
                      <div className="d-flex align-items-center example custom-header">
                        <span className="title-perspective">{t("newBookingSingha_Booking:View")}:</span>
                        <div className="calendars-wrapper d-flex align-items-center">
                          <Dropdown
                            name="location"
                            title={t("newBookingSingha_Booking:Monthly")}
                            className="mt"
                            list={[
                              {
                                label: t("newBookingSingha_Booking:Monthly"),
                                value: "เดือน"
                              },
                              {
                                label: t("newBookingSingha_Booking:Daily"),
                                value: "วัน"
                              }
                            ]}
                            styles={{
                              headerTitle: { fontSize: "14px", color: "#B3B3B3", fontFamily: 'light' },
                              header: { border: "1px solid #B3B3B3" },
                              wrapper: {
                                width: "128px"
                              },
                              listItem: { fontSize: "14px" }
                            }}
                            onChange={(item, name) => this.toggleItem(item, name)}
                          />
                        </div>
                        <span className="title-calendar">{t("newBookingSingha_Booking:Sort")}:</span>
                        <div className="calendars-wrapper d-flex align-items-center">
                          <Dropdown
                            name="location"
                            title={t("newBookingSingha_Booking:Charge")}
                            className="mt"
                            list={[
                              {
                                label: t("newBookingSingha_Booking:All"),
                                value: "ทั้งหมด"
                              },
                              {
                                label: t("newBookingSingha_Booking:Charge"),
                                value: "เก็บค่าบริการ"
                              },
                              {
                                label: t("newBookingSingha_Booking:No charge"),
                                value: "ไม่เก็บค่าบริการ"
                              }
                            ]}
                            onChange={(text) => this.onChangeService(text)}
                            styles={{
                              headerTitle: { fontSize: "14px", color: "#B3B3B3", fontFamily: 'light' },
                              header: { border: "1px solid #B3B3B3" },
                              wrapper: {
                                width: "198px"
                              },
                              listItem: { fontSize: "14px" }
                            }}
                          />
                        </div>
                        <div className="calendars-wrapper form-input-search">
                          <form>
                            <input
                              type="text"
                              placeholder={t("newBookingSingha_Booking:Search")}
                              onKeyDown={this.handleKeyDown}
                              value={this.state.textSearch}
                              onChange={(event) => this.onChangeSearch(event)}
                              className="calendars-wrapper colorborder form-control float-right"
                            />
                          </form>
                          <div className="calendars float-right">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icons/search-outline.png"
                              }
                              alt="calendars"
                              className="img-search-outline"
                              onClick={() => this.onClickSearch()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ height: 700 }}>

                      {this.state.view === "month" && (
                        <Calendar
                          events={event}
                          views={{
                            month: true
                          }}
                          step={60}
                          showMultiDayTimes
                          defaultView={"month"}
                          defaultDate={new Date()}
                          onNavigate={(date) => this.onSelectedDate(date)}
                          onSelectEvent={(event) => this.eventSelected(event)}
                          onShowMore={(event) => this.goToMore(event)}
                          components={{
                            timeSlotWrapper: ColoredDateCellWrapper,
                            toolbar: CustomToolbar
                          }}
                          eventPropGetter={(event, start, end, isSelected) => {
                            return {
                              // style: { backgroundColor: event.hexColor,color:'#4D4D4D',textAlign:'center' },
                              style: {
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #2D2D30',
                                color: '#2D2D30',
                                textAlign: 'center',
                                width: '90%',
                                marginLeft: '5%',
                                fontFamily: 'Kanit',
                                fontSize: 12,
                                fontWeight: 400
                              },
                            };
                          }}
                          localizer={localizer}
                        />
                      )}
                      {this.state.view === "day" && (
                        <Calendar
                          events={all_facility_slot}
                          views={["day"]}
                          components={{
                            toolbar: CustomToolbar
                          }}
                          eventPropGetter={
                            (event, start, end, isSelected) => {
                              let newStyle = {
                                backgroundColor: event.title === event.title && event.color,
                                color: '#2D2D30',
                                borderRadius: "0px",
                                border: "none",
                                maxWidth: '25%'
                              };

                              if (event.isMine) {
                                newStyle.backgroundColor = "lightgreen"
                              }

                              return {
                                className: "",
                                style: newStyle
                              };
                            }
                          }
                          defaultView="day"
                          defaultDate={new Date(this.state.selectedDay)}
                          onNavigate={(date) => this.onSelectedDay(date)}
                          onSelectEvent={(event) => this.eventSelectedDay(event)}
                          localizer={localizer}
                        />
                      )}
                    </div>
                  </div>
                </div>
            }

          </Translation>

        </WrapperContent>
      </Wrapper>
    );
  }
}

class CustomToolbar extends ComponentPagination {
  render() {
    const { view, date } = this.props
    // let thaiYear = moment(date).add(543, 'year').format("YYYY")
    let year = moment(date).format("YYYY")
    let thaiMonth = monthNames[new Date(date).getMonth()]
    let no = new Date(date).getDate()
    // let viewMonth = thaiMonth + " " + year
    // let viewDay = no + " " + thaiMonth + " " + year

    return (
      <Translation>
        {t =>
          <div className="content-inner ml-0" width="100%">
            <div
              className="row text-center px-3 justify-content-between mr-0 ml-0"
              style={{ backgroundColor: "#E6F2FE", height: "80px", marginTop: "48px" }}
            >
              <div className="align-self-center p-0 ml-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/chevron-backv2.png"}
                  alt="back"
                  className="back img-chevron"
                  onClick={() => this.navigate("PREV")}
                />
                <b className="label-calendar">
                  {view === "day" ? `${no} ${t("materWater:" + thaiMonth)} ${year}` : `${t("materWater:" + thaiMonth)} ${year}`}
                </b>
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/chevron-forwardv2.png"}
                  className="back img-chevron"
                  onClick={() => this.navigate("NEXT")}
                />
              </div>
            </div>
          </div>

        }
      </Translation>

    );
  }

  navigate = (action) => {
    this.props.onNavigate(action);
  };
}


export default Calendars;
