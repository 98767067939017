import React, {Component} from 'react';
import $ from "jquery";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import "../styles/receive.scss"
import _ from "lodash";
import getNameResidential from "../../libs/getNameResidential";
import "../styles/new-styles.scss"
import i18n from 'i18next'
import { Translation } from 'react-i18next';
import AddressDataShow from '../../components/FucntionLib/addressDataShow';
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';
const ThaiBaht = require('thai-baht-text');

class GuaranteeMoneyReceived extends Component {

    constructor(props) {
        super(props);

        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
                10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
                10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
                10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
                10160, 10210, 10310 ];

        this.state = {
            bangkokCode: bangkokCode,
            list_map: [],
            languages:"th"
        };
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
    }

    componentDidMount() {        
        this.setState({
            languages: this.props.languages,
        })
        i18n.changeLanguage(this.props.languages);
        // this.setState({ list_map: [1, 2],languages : this.props.query.userConfig.languages});
        this.setState({ list_map: [1, 2]});

        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + signature);
            $('tbody tr:last-child td').css({paddingBottom: diff});
        }, 200);
    }

    isBangkok(code) {
        if(_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {
        let languages = this.state.languages
        if(type === "district" && this.isBangkok(code)){
            return languages == "th" ? "แขวง" : "District "
        }
        else if(type === "city" && this.isBangkok(code)){
            return languages == "th" ? "เขต" : "County "
        }
        else if(type === "province" && this.isBangkok(code)){
            return languages == "th" ? "" : ""
        }
        else if(type === "province") {
            return languages == "th" ? "จ." : "Province "
        }
        else if(type === "district") {
            return languages == "th" ? "ต." : "District "
        }
        else if(type === "city"){
            return languages == "th" ? "อ." : "County "
        }
    }

    nextLine(address) {
        return address = address.replace('แขวง', '\nแขวง')
    }

    render() {
        let invoice_object = [];
        let total_cn = 0
        let total_invoice = 0
        let total = 0
        if(this.props.query.creditNote.version === 'V1'){
          invoice_object.push(this.props.query.creditNote.transaction.invoice.docNumber)
          total_cn += this.props.query.creditNote.price
          total_invoice += this.props.query.creditNote.transaction.invoice.total
          total += parseFloat(this.props.query.creditNote.transaction.invoice.total) - parseFloat(this.props.query.creditNote.price)
          
        }else{
            this.props.query.creditNote.creditNoteTransactionRecord.edges.forEach((credit_note_transaction) => {
            total_cn += credit_note_transaction.node.price
            total_invoice += credit_note_transaction.node.transaction.total
            total += parseFloat(credit_note_transaction.node.transaction.total) - parseFloat(credit_note_transaction.node.price)
            if(!invoice_object.includes(credit_note_transaction.node.transaction.invoice.docNumber) ){
              invoice_object.push(credit_note_transaction.node.transaction.invoice.docNumber)
          }
          })
            
        }

        if(this.props.query.creditNote.version === 'V1'){
            return (
                <React.Fragment>
                    {
                        this.state.list_map.map((count_receive,index) => {
                            return (
                                <div className="print-page-a4" id="receive" key={index}>
                                    <div className="subpage" id="style-document">
                                        <div className="head">
                                            <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                                                <div style={{minHeight:100,minWidth:100}}>
                                                    <img src={this.props.query.selfProject.logo} alt="silverman"/>
                                                </div>
                                                <div> 
                                                    <div style={{minWidth:'150%'}}>                                                        
                                                        <strong>{this.state.languages === "en" ? this.props.selfProject.nameEn : this.props.selfProject.name}</strong>
                                                    </div>
                                                    {/* <Translation>{t=><div>{this.props.query.selfProject.address} {t("document:Tel")} {this.props.query.selfProject.juristicContactNumber || '-'}</div>}</Translation> */}
                                                    {
                                                        this.props.query.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ? 
                                                        <div>
                                                            <Translation>{t=><div className="address-header">{this.nextLine(this.props.query.selfProject.address)} </div>}</Translation>
                                                            <Translation>{t=><div className="address-header">{t("document:Tel")} {this.props.query.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                                        </div>
                                                        :
                                                        <Translation>{t=><div className="address-header">{this.props.query.selfProject.address} {t("document:Tel")} {this.props.query.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                                    }
                                                    <Translation>{t=><div>{t("document:Taxpayer identification number")} {this.props.query.selfProject.taxIdNumber || '-'}</div>}</Translation>
                                                </div>
                                               
                                            </div>
    
                                            <div className="document-name">
                                                <Translation>
                                                    {t=>
                                                    <React.Fragment>
                                                        {
                                                            t("document:Credit Note") === "Credit Note" ?
                                                                <div className="title-head">Credit Note</div>
                                                                :
                                                                <>
                                                                    <div className="title-head">ใบลดหนี้</div>
                                                                    <div className="title-head">Credit Note</div>
                                                                </>
                                                        }
                                                        
                                                    </React.Fragment>}
                                                </Translation>
    
                                                {
                                                    count_receive === 1 ?
                                                        <Translation>{t=><div className="title small">({t("document:Original")})</div>}</Translation>
                                                        :
                                                        <Translation>{t=><div className="title small">({t("document:Copy")})</div>}</Translation>
                                                }
                                                </div>
                                        </div>
    
                                        <div className="detail">
                                            <div className="customer-detail">
                                                <Translation>{t=><strong>{t("document:ID")}:</strong>}</Translation> {this.props.query.creditNote.contact.refNumber}<br/>
                                                    <React.Fragment>
                                                        <Translation>{t=><strong>{t("document:Customer")}:</strong>}</Translation>
                                                        {
                                                            this.props.query.creditNote.contact.typeOfContact === 'RESIDENTIAL' &&
                                                            this.props.query.creditNote.firstName && this.props.query.creditNote.firstName ?
                                                                getNameResidential(this.props.query.creditNote.firstName, this.props.query.creditNote.lastName):
                                                            this.props.query.creditNote.contact.typeOfContact === 'RESIDENTIAL' && 
                                                            this.props.query.creditNote.contact.firstName ?
                                                                getNameResidential(this.props.query.creditNote.contact.firstName, this.props.query.creditNote.contact.lastName):
                                                                this.props.query.creditNote.contact.name
                                                        }
                                                        {/* {this.props.query.creditNote.contact.typeOfContact === 'RESIDENTIAL' ? 
                                                         this.props.query.creditNote.contact.firstName &&  this.props.query.creditNote.contact.firstName !== '' ? getNameResidential(this.props.query.creditNote.contact.firstName, this.props.query.creditNote.contact.lastName) : this.props.query.creditNote.contact.name : 
                                                         this.props.query.creditNote.contact.name || this.props.query.creditNote.contact.firstName + this.props.query.creditNote.contact.lastName} */}
                                                        <br/>
                                                    </React.Fragment>
    
                                                <Translation>{t=><strong>{t("document:Address")}:</strong>}</Translation>
    
                                                <div className="customer-detail address-space">
                                                    {
                                                       ( this.props.query.creditNote?.address || this.props.query.creditNote.contact.registeredAddress) &&
                                                        <AddressDataShow
                                                            languages={this.state.languages === "en" ? true : false}
                                                            custom={this.props.query.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ? true : false}
                                                            from={true}
                                                            address={
                                                                this.props.query.creditNote?.address ?
                                                            {
                                                                address: this.props.query.creditNote.address,
                                                                district: this.props.query.creditNote.district,
                                                                city: this.props.query.creditNote.city,
                                                                province: this.props.query.creditNote.province,
                                                                postalCode: this.props.query.creditNote.postalCode,
                                                            }:
                                                            {
                                                                address: this.props.query.creditNote.contact.registeredAddress,
                                                                district: this.props.query.creditNote.contact.registeredDistrict,
                                                                city: this.props.query.creditNote.contact.registeredCity,
                                                                province: this.props.query.creditNote.contact.registeredProvince,
                                                                postalCode: this.props.query.creditNote.contact.registeredPostalCode,
                                                                registeredCountry: this.props.query.creditNote.contact.registeredCountry,
                                                            }
                                                            }
                                                        />
    
                                                    }
                                                    {/* {
                                                        this.state.languages === "en" ?
                                                        this.props.query.creditNote.contact.registeredAddress.replace('เลขที่', 'No.').replace('ซอย', 'Soi').replace('หมู่ที่', 'Moo').replace('ถนน', 'Road') + " " :
                                                        this.props.query.creditNote.contact.registeredAddress + " "
                                                    }
                                                    //{this.props.query.creditNote.contact.registeredDistrict && this.getPrefix("district", this.props.query.creditNote.contact.registeredPostalCode)+this.props.query.creditNote.contact.registeredDistrict + " "}
                                                    {this.props.query.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ?
                                                        <React.Fragment>
                                                            {this.props.query.creditNote.contact.registeredDistrict && this.getPrefix("district", this.props.query.creditNote.contact.registeredPostalCode) + this.props.query.creditNote.contact.registeredDistrict + " "}<br/>
                                                        </React.Fragment>:
                                                        <React.Fragment>
                                                            {this.props.query.creditNote.contact.registeredDistrict && this.getPrefix("district", this.props.query.creditNote.contact.registeredPostalCode) + this.props.query.creditNote.contact.registeredDistrict + " "}
                                                        </React.Fragment>
                                                    }
                                                    {this.props.query.creditNote.contact.registeredCity && this.getPrefix("city", this.props.query.creditNote.contact.registeredPostalCode)+this.props.query.creditNote.contact.registeredCity + " "}
                                                    {this.props.query.creditNote.contact.registeredProvince && this.getPrefix("province", this.props.query.creditNote.contact.registeredPostalCode)+this.props.query.creditNote.contact.registeredProvince + " "}
                                                    {this.props.query.creditNote.contact.registeredPostalCode + " "} */}
    
                                                    {
                                                        (this.props.query.creditNote.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.query.creditNote.contact.typeOfSupplier === "COMPANY") &&
                                                            <Translation>
                                                                {t=>
                                                                <React.Fragment>
                                                                    {
                                                                        this.props.query.creditNote.contact.typeOfCompany === "HEADQUARTERS" ?
                                                                            t("document:Head office")
                                                                            : this.props.query.creditNote.contact.typeOfCompany === "BRANCH" &&
                                                                            `${t("document:Branch")} ` + this.props.query.creditNote.contact.nameBranch
                                                                    }
                                                                </React.Fragment>}
                                                            </Translation>
                                                    }
                                                </div>
                                                <br/>
                                                <Translation>{t=><strong>{t("document:Tax ID")}:</strong>}</Translation>
                                                        {this.props.query.creditNote.contact.taxIdNumber ? this.props.query.creditNote.contact.taxIdNumber : ''}
                                                <br/>
                                            </div>
                                            <div className="document-detail">
                                                <Translation>{t=><strong className="sub-document-detail">{t("document:No.")}:</strong>}</Translation> {this.props.query.creditNote.docNumber} <br/>
                                                <Translation>{t=><strong className="sub-document-detail">{t("document:Date")}:</strong>}</Translation> {format(this.props.query.creditNote.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}<br/>
                                                {
                                                    invoice_object.length === 1 && 
                                                    <React.Fragment>
                                                        <Translation>{t=><strong className="sub-document-detail">{t("document:Refer to")}:</strong>}</Translation> {invoice_object[0]}<br/>
                                                    </React.Fragment>
                                                }
                                                {this.props.query.creditNote.contact.typeOfContact === "RESIDENTIAL" &&
                                                <React.Fragment>
                                                    <Translation>{t=><strong className="sub-document-detail">{this.props.query.selfProject.typeOfProject === "CONDOMINIUM" ? `${t("document:Room No.")}:` : `${t("document:House No.")}:`}</strong>}</Translation> {this.props.query.creditNote.contact.name} &nbsp;
                                                    <br/>
    
                                                    <Translation>{t=><><strong className="sub-document-detail">{t("document:Ratio/Space")}:</strong> {numberWithComma(this.props.query.creditNote.contact.residential.ownershipRatio)}/{numberWithComma(this.props.query.creditNote.contact.residential.size)} </>}</Translation>
                                                    <br/>
                                                </React.Fragment>
                                                }
                                            </div>
                                        </div>
    
                                        <div className="invoice-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                <Translation>
                                                    {t=>
                                                    <tr>
                                                        <th 
                                                            width={50} 
                                                            className="text-center" 
                                                            dangerouslySetInnerHTML={{__html: t("document:No._br")}}/>
                                                        <th 
                                                            width={50} 
                                                            className="text-center" 
                                                            dangerouslySetInnerHTML={{__html: t("document:Code_br")}}/>
        
                                                        <th 
                                                            className="text-center" 
                                                            dangerouslySetInnerHTML={{__html: t("document:Description_br")}}/>
                                                        <th 
                                                            width={110} 
                                                            className="text-center" 
                                                            dangerouslySetInnerHTML={{__html: t("document:Unit_br")}}/>
                                                        <th 
                                                            width={110} 
                                                            className="text-center" 
                                                            dangerouslySetInnerHTML={{__html: t("document:Unit Price_br")}}/>
        
                                                        <th 
                                                            width={100} 
                                                            className="text-center" 
                                                            dangerouslySetInnerHTML={{__html: t("document:Amount_br")}}/>
                                                    </tr>}
                                                </Translation>
                                                </thead>
                                                <tbody>
                                                        {
                                                            this.props.query.creditNote.version === 'V1' ?
                                                                <tr key={this.props.query.creditNote.id}>
                                                                    {
                                                                        count_receive === 1 ?
                                                                        <td className="text-center">{index + 1}</td>
                                                                        :
                                                                        <td className="text-center">{index}</td>
                                                                    }
                                                                    <td className="text-center">{this.props.query.creditNote.transaction.productAndService.productCode}</td>
                                                                    <td>{this.props.query.creditNote.description}</td>
                                                                    <td className="text-right">1.00</td>
                                                                    <td className="text-right">{numberWithComma(this.props.query.creditNote.price)}</td>
                                                                    <td className="text-right">{numberWithComma(this.props.query.creditNote.price)}</td>
                                                                </tr>
                                                            :
    
                                                            this.props.query.creditNote.creditNoteTransactionRecord.edges.map((credit_note_transaction, index) => {
    
                                                                let description = "";
                                                                if(invoice_object.length > 1){
                                                                    description = credit_note_transaction.node.description +" #"+ credit_note_transaction.node.transaction.invoice.docNumber
                                                                }else{
                                                                    description = credit_note_transaction.node.description
                                                                }
                                                                return(
                                                                    <tr key={credit_note_transaction.node.id}>
         
                                                                        <td className="text-center">{index + 1}</td>
                                                                        <td className="text-center">{credit_note_transaction.node.transaction.productAndService.productCode}</td>
                                                                        <td>{description}</td>
                                                                        <td className="text-right">1.00</td>
                                                                        <td className="text-right">{numberWithComma(credit_note_transaction.node.price)}</td>
                                                                        <td className="text-right">{numberWithComma(credit_note_transaction.node.price)}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
    
    
    
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <td colSpan={3} rowSpan={3}/>
                                                    <td colSpan={2}>
                                                        <Translation>{t=><strong>{t("document:Amount")}</strong>}</Translation>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>{numberWithComma(total_cn, 0)}</strong>
                                                    </td>
                                                </tr>
    
                                                <tr>
                                                    <td colSpan={2}>
                                                        <Translation>{t=><strong>{t("document:Original Document Value")}</strong>}</Translation>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>{numberWithComma(total_invoice, 0)}</strong>
                                                    </td>
                                                </tr>
    
                                                <tr>
                                                    <td colSpan={2}>
                                                        <Translation>{t=><strong>{t("document:Correct Value")}</strong>}</Translation>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>
                                                            {numberWithComma(total, 0)}
                                                        </strong>
                                                    </td>
                                                </tr>
    
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div className="row">
                                                            <div className="col-1">
                                                                <Translation>{t=><strong>{t("document:Total")}</strong>}</Translation>
                                                            </div>
                                                            <div className="col-10 text-center">
                                                                <strong>
                                                                {
                                                                    i18n.languages[0] === 'th' ? (ThaiBaht(total_cn))
                                                                    : (ConvertNumberToTextEng(total_cn) + " Baht")
                                                                }                                                                
                                                                </strong>
                                                            </div>
                                                            <div className="col-1"/>
                                                        </div>
                                                    </td>
    
                                                    <td colSpan={2}>
                                                        <Translation>{t=><strong>{t("document:Grand Total")}</strong>}</Translation>
                                                    </td>
                                                    <td className="text-right">
                                                        <strong>{numberWithComma(total_cn, 0)}</strong>
                                                    </td>
                                                </tr>
                                                </tfoot>
                                            </table>
    
                                            <div className="remark">
                                                <Translation>{t=><strong>{t("document:Remarks")}:</strong>}</Translation> <br/>
                                            </div>
                                            <div className="remark">
                                                {this.props.query.creditNote.remark}
                                                <br/>
                                            </div>
    
                                        </div>
                                        <div className="footer">
                                            <div className="signature">
                                                <div className="left">
                                                    ...................................................... <br/>
                                                    <Translation>{t=><strong>{t("document:Authorized")}</strong>}</Translation>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
    
                </React.Fragment>
            );
        }else{
        //V2
        let checkNumPerpage = this.props.num_perpage ? this.props.num_perpage : 16;
        let order = this.props.current_page * checkNumPerpage;
        return (   
            <React.Fragment>
                {
                    // this.state.list_map.map((count_receive,index) => {
                    //     return (
                            <div className="print-page-a4" id="receive">
                                <div className="subpage" id="style-document">
                                    <div className="head">
                                        <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                                            <div style={{minHeight:100,minWidth:100}}>
                                                <img src={this.props.query.selfProject.logo} alt="silverman"/>
                                            </div>
                                            <div> 
                                                <div style={{minWidth:'150%'}}>
                                                    <strong>{this.state.languages === "en" ? (this.props.selfProject.nameEn || this.props.selfProject.name) : this.props.selfProject.name}</strong>                                                    
                                                </div>                                                
                                                {
                                                    this.props.query.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ? 
                                                    <div>
                                                        <Translation>{t=><div className="address-header">{this.nextLine(this.state.languages === "en" ?  (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address)} </div>}</Translation>
                                                        <Translation>{t=><div className="address-header">{t("document:Tel")} {this.props.query.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                                    </div>
                                                    :
                                                    <Translation>{t=><div className="address-header">{this.state.languages === "en" ?  (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address} {t("document:Tel")} {this.props.query.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                                }
                                                <Translation>{t=><div>{t("document:Taxpayer identification number")} {this.props.query.selfProject.taxIdNumber || '-'}</div>}</Translation>
                                            </div>
                                           
                                        </div>

                                        <div className="document-name">
                                            <Translation>
                                                {t=>
                                                <React.Fragment>
                                                    {
                                                        t("document:Credit Note") === "Credit Note" ?
                                                            <div className="title-head">Credit Note</div>
                                                            :
                                                            <>
                                                                <div className="title-head">ใบลดหนี้</div>
                                                                <div className="title-head">Credit Note</div>
                                                            </>
                                                    }
                                                    
                                                </React.Fragment>}
                                            </Translation>

                                            {
                                                this.props.check_type === "manuscript" ?
                                                    <Translation>{t=><div className="title small">({t("document:Original")})</div>}</Translation>
                                                    :
                                                    <Translation>{t=><div className="title small">({t("document:Copy")})</div>}</Translation>
                                            }
                                            </div>
                                    </div>

                                    <div className="detail">
                                        <div className="customer-detail">
                                            <Translation>{t=><strong>{t("document:ID")}:</strong>}</Translation> {this.props.query.creditNote.contact.refNumber}<br/>
                                                <React.Fragment>
                                                    <Translation>{t=><strong>{t("document:Customer")}:</strong>}</Translation>
                                                    {
                                                        this.props.query.creditNote.contact.typeOfContact === 'RESIDENTIAL' &&
                                                        this.props.query.creditNote.firstName && this.props.query.creditNote.firstName ?
                                                            getNameResidential(this.props.query.creditNote.firstName, this.props.query.creditNote.lastName):
                                                        this.props.query.creditNote.contact.typeOfContact === 'RESIDENTIAL' && 
                                                        this.props.query.creditNote.contact.firstName ?
                                                            getNameResidential(this.props.query.creditNote.contact.firstName, this.props.query.creditNote.contact.lastName):
                                                            this.props.query.creditNote.contact.name
                                                    }
                                                    {/* {this.props.query.creditNote.contact.typeOfContact === 'RESIDENTIAL' ? 
                                                     this.props.query.creditNote.contact.firstName &&  this.props.query.creditNote.contact.firstName !== '' ? getNameResidential(this.props.query.creditNote.contact.firstName, this.props.query.creditNote.contact.lastName) : this.props.query.creditNote.contact.name : 
                                                     this.props.query.creditNote.contact.name || this.props.query.creditNote.contact.firstName + this.props.query.creditNote.contact.lastName} */}
                                                    <br/>
                                                </React.Fragment>

                                            <Translation>{t=><strong>{t("document:Address")}:</strong>}</Translation>

                                            <div className="customer-detail address-space">
                                                {
                                                   ( this.props.query.creditNote?.address || this.props.query.creditNote.contact.registeredAddress) &&
                                                    <AddressDataShow
                                                        languages={this.state.languages === "en" ? true : false}
                                                        custom={this.props.query.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ? true : false}
                                                        from={true}
                                                        address={
                                                            this.props.query.creditNote?.address ?
                                                        {
                                                            address: this.props.query.creditNote.address,
                                                            district: this.props.query.creditNote.district,
                                                            city: this.props.query.creditNote.city,
                                                            province: this.props.query.creditNote.province,
                                                            postalCode: this.props.query.creditNote.postalCode,
                                                        }:
                                                        {
                                                            address: this.props.query.creditNote.contact.registeredAddress,
                                                            district: this.props.query.creditNote.contact.registeredDistrict,
                                                            city: this.props.query.creditNote.contact.registeredCity,
                                                            province: this.props.query.creditNote.contact.registeredProvince,
                                                            postalCode: this.props.query.creditNote.contact.registeredPostalCode,
                                                            registeredCountry: this.props.query.creditNote.contact.registeredCountry,
                                                        }
                                                        }
                                                    />

                                                }
                                                {/* {
                                                    this.state.languages === "en" ?
                                                    this.props.query.creditNote.contact.registeredAddress.replace('เลขที่', 'No.').replace('ซอย', 'Soi').replace('หมู่ที่', 'Moo').replace('ถนน', 'Road') + " " :
                                                    this.props.query.creditNote.contact.registeredAddress + " "
                                                }
                                                //{this.props.query.creditNote.contact.registeredDistrict && this.getPrefix("district", this.props.query.creditNote.contact.registeredPostalCode)+this.props.query.creditNote.contact.registeredDistrict + " "}
                                                {this.props.query.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ?
                                                    <React.Fragment>
                                                        {this.props.query.creditNote.contact.registeredDistrict && this.getPrefix("district", this.props.query.creditNote.contact.registeredPostalCode) + this.props.query.creditNote.contact.registeredDistrict + " "}<br/>
                                                    </React.Fragment>:
                                                    <React.Fragment>
                                                        {this.props.query.creditNote.contact.registeredDistrict && this.getPrefix("district", this.props.query.creditNote.contact.registeredPostalCode) + this.props.query.creditNote.contact.registeredDistrict + " "}
                                                    </React.Fragment>
                                                }
                                                {this.props.query.creditNote.contact.registeredCity && this.getPrefix("city", this.props.query.creditNote.contact.registeredPostalCode)+this.props.query.creditNote.contact.registeredCity + " "}
                                                {this.props.query.creditNote.contact.registeredProvince && this.getPrefix("province", this.props.query.creditNote.contact.registeredPostalCode)+this.props.query.creditNote.contact.registeredProvince + " "}
                                                {this.props.query.creditNote.contact.registeredPostalCode + " "} */}

                                                {
                                                    (this.props.query.creditNote.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.query.creditNote.contact.typeOfSupplier === "COMPANY") &&
                                                        <Translation>
                                                            {t=>
                                                            <React.Fragment>
                                                                {
                                                                    this.props.query.creditNote.contact.typeOfCompany === "HEADQUARTERS" ?
                                                                        t("document:Head office")
                                                                        : this.props.query.creditNote.contact.typeOfCompany === "BRANCH" &&
                                                                        `${t("document:Branch")} ` + this.props.query.creditNote.contact.nameBranch
                                                                }
                                                            </React.Fragment>}
                                                        </Translation>
                                                }
                                            </div>
                                            <br/>
                                            <Translation>{t=><strong>{t("document:Tax ID")}:</strong>}</Translation>
                                                    {this.props.query.creditNote.contact.taxIdNumber ? this.props.query.creditNote.contact.taxIdNumber : ''}
                                            <br/>
                                        </div>
                                        <div className="document-detail">
                                            <Translation>{t=><strong className="sub-document-detail">{t("document:No.")}:</strong>}</Translation> {this.props.query.creditNote.docNumber} <br/>
                                            <Translation>{t=><strong className="sub-document-detail">{t("document:Date")}:</strong>}</Translation> {format(this.props.query.creditNote.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}<br/>
                                            {
                                                invoice_object.length === 1 && 
                                                <React.Fragment>
                                                    <Translation>{t=><strong className="sub-document-detail">{t("document:Refer to")}:</strong>}</Translation> {invoice_object[0]}<br/>
                                                </React.Fragment>
                                            }
                                            {this.props.query.creditNote.contact.typeOfContact === "RESIDENTIAL" &&
                                            <React.Fragment>
                                                <Translation>{t=><strong className="sub-document-detail">{this.props.query.selfProject.typeOfProject === "CONDOMINIUM" ? `${t("document:Room No.")}:` : `${t("document:House No.")}:`}</strong>}</Translation> {this.props.query.creditNote.contact.name} &nbsp;
                                                <br/>

                                                <Translation>{t=><><strong className="sub-document-detail">{t("document:Ratio/Space")}:</strong> {numberWithComma(this.props.query.creditNote.contact.residential.ownershipRatio)}/{numberWithComma(this.props.query.creditNote.contact.residential.size)} </>}</Translation>
                                                <br/>
                                            </React.Fragment>
                                            }
                                        </div>
                                    </div>

                                    <div className="invoice-body">
                                        <table className="table table-bordered">
                                            <thead>
                                            <Translation>
                                                {t=>
                                                <tr>
                                                    <th 
                                                        width={50} 
                                                        className="text-center" 
                                                        dangerouslySetInnerHTML={{__html: t("document:No._br")}}/>
                                                    <th 
                                                        width={50} 
                                                        className="text-center" 
                                                        dangerouslySetInnerHTML={{__html: t("document:Code_br")}}/>
    
                                                    <th 
                                                        className="text-center" 
                                                        dangerouslySetInnerHTML={{__html: t("document:Description_br")}}/>
                                                    <th 
                                                        width={80} 
                                                        className="text-center" 
                                                        dangerouslySetInnerHTML={{__html: t("document:Unit_br")}}/>
                                                    <th 
                                                        width={80} 
                                                        className="text-center" 
                                                        dangerouslySetInnerHTML={{__html: t("document:Unit Price_br")}}/>
    
                                                    <th 
                                                        width={70} 
                                                        className="text-center" 
                                                        dangerouslySetInnerHTML={{__html: t("document:Amount_br")}}/>
                                                </tr>}
                                            </Translation>
                                            </thead>
                                            <tbody style={{borderBottom:'1px solid'}}>
                                                    {
                                                       this.props.query.creditNote.version === 'V1' ?
                                                            <tr key={this.props.query.creditNote.id}>
                                                                {
                                                                    this.props.current_page === 1 ?
                                                                    <td className="text-center">{this.props.current_page  + 1}</td>
                                                                    :
                                                                    <td className="text-center">{this.props.current_page }</td>
                                                                }
                                                                <td className="text-center">{this.props.query.creditNote.transaction.productAndService.productCode}</td>
                                                                <td>{this.props.query.creditNote.description}</td>
                                                                <td className="text-right">1.00</td>
                                                                <td className="text-right">{numberWithComma(this.props.query.creditNote.price)}</td>
                                                                <td className="text-right">{numberWithComma(this.props.query.creditNote.price)}</td>
                                                            </tr>
                                                        :

                                                        this.props.creditNoteTransaction.map((credit_note_transaction, index) => {
                                                            order += 1;
                                                            let description = "";
                                                            if(invoice_object.length > 1){
                                                                description = credit_note_transaction.node.description +" #"+ credit_note_transaction.node.transaction.invoice.docNumber
                                                            }else{
                                                                description = credit_note_transaction.node.description
                                                            }
                                                            return(
                                                                <tr key={credit_note_transaction.node.id}>
     
                                                                    <td className="text-center">{order}</td>
                                                                    <td className="text-center">{credit_note_transaction.node.transaction.productAndService.productCode}</td>
                                                                    <td>{description}</td>
                                                                    <td className="text-right">1.00</td>
                                                                    <td className="text-right">{numberWithComma(credit_note_transaction.node.price)}</td>
                                                                    <td className="text-right">{numberWithComma(credit_note_transaction.node.price)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }



                                            </tbody>
                                             {this.props.current_page === this.props.end_page &&
                                            <tfoot>

                                            <tr>
                                                <td colSpan={3} rowSpan={3}/>
                                                <td colSpan={2}>
                                                    <Translation>{t=><strong>{t("document:Amount")}</strong>}</Translation>
                                                </td>
                                                <td className="text-right">
                                                    <strong>{numberWithComma(total_cn, 0)}</strong>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colSpan={2}>
                                                    <Translation>{t=><strong>{t("document:Original Document Value")}</strong>}</Translation>
                                                </td>
                                                <td className="text-right">
                                                    <strong>{numberWithComma(total_invoice, 0)}</strong>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colSpan={2}>
                                                    <Translation>{t=><strong>{t("document:Correct Value")}</strong>}</Translation>
                                                </td>
                                                <td className="text-right">
                                                    <strong>
                                                        {numberWithComma(total, 0)}
                                                    </strong>
                                                </td>
                                            </tr>

                                           
                                            <tr>
                                                <td colSpan={3}>
                                                    <div className="row">
                                                        <div className="col-1">
                                                            <Translation>{t=><strong>{t("document:Total")}</strong>}</Translation>
                                                        </div>
                                                        <div className="col-10 text-center">
                                                            {
                                                                i18n.languages[0] === 'th' ? (ThaiBaht(total_cn))
                                                                : (ConvertNumberToTextEng(total_cn) + " Baht")
                                                            }     
                                                        </div>
                                                        <div className="col-1"/>
                                                    </div>
                                                </td>

                                                <td colSpan={2}>
                                                    <Translation>{t=><strong>{t("document:Grand Total")}</strong>}</Translation>
                                                </td>
                                                <td className="text-right">
                                                    <strong>{numberWithComma(total_cn, 0)}</strong>
                                                </td>
                                            </tr>
                                            </tfoot> }
                                        </table>

                                        {this.props.current_page === this.props.end_page &&
                                        <>
                                            <div className="remark">
                                                <Translation>{t=><strong>{t("document:Remarks")}:</strong>}</Translation> <br/>
                                            </div>
                                            <div className="remark">
                                                {this.props.query.creditNote.remark}
                                                <br/>
                                            </div>
                                        </>
                                        }

                                    </div>
                                    {this.props.current_page === this.props.end_page &&
                                    <div className="footer">
                                        <div className="signature">
                                            <div className="left">
                                                ...................................................... <br/>
                                                <Translation>{t=><strong>{t("document:Authorized")}</strong>}</Translation>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                    //     )
                    // })
                }

            </React.Fragment>
        );
        }
       
    }
}

export default GuaranteeMoneyReceived;
