import "../styles/createBudget.scss";
import React, { forwardRef } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import _ from "lodash";
import { Translation } from "react-i18next";
import DatePicker from "react-datepicker";
import { addMonths, format, lastDayOfMonth, setDate } from "date-fns";
import thLocale from "date-fns/locale/th";
import Loading from "../../../libs/loading";
import { P2, H6 } from "../../../components/Text/Text";
import { Button } from "../../../components/Button/Button";
import code from "../json/chart_of_account.json";
import numberWithCommas from "../../../libs/numberWithComma";
import AccountingTopMenuNavigation from '../../accountingTopMenuNavigation'
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import BudgetUsageSummaryExcelExports from "./budgetUsageSummaryExcelExports";
import i18next from "i18next";
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import GetdateOnEngAndTh from "../../../components/Action/getdateOnEngAndTh";

let _list = {
  ..._.cloneDeep(code),
  allChartOfAccountBudget: [],
};

const query = graphql`
  query budgetUsageSummaryReportQuery($yearId: String, $lastDate: Date) {
    oneYearBudget(yearId: $yearId) {
      edges {
        node {
          id
          years
          startDate
          endDate
          project {
            name
          }
          chartOfAccountBudget {
            edges {
              node {
                totalBudget
                chartOfAccount {
                  id
                  name
                  nameEn
                  chartOfAccountCode
                }
                monthBudget {
                  edges {
                    node {
                      months
                      budget
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allChartOfAccountBudget(yearId: $yearId, lastDate: $lastDate) {
      edges {
        node {
          chartOfAccount {
            id
            name
            nameEn
            chartOfAccountCode
          }
          summaryYear
        }
      }
    }
  }
`;

// const mutation = graphql`
//   mutation budgetUsageSummaryReportMutation($input: UpdateBudgetInput!) {
//     updateBudget(input: $input) {
//       ok
//       message
//     }
//   }
// `;

class CreateBudget extends React.Component {
  constructor(pros) {
    super(pros);
    this.state = {
      lists: _.cloneDeep(_list),
      loading: true,
      year: new Date(),
      start_date: new Date(2021, 0, 1),
      end_date: new Date(2021, 11, 1),
      list_month: [],
      value: [],
      save: false,
      redirectToList: false,
      disabled_table: true,
      id: "",
      year_search: this.props.match.params.id,
      project_name: "",
      // lastDate: format(setYear(new Date(), this.props.match.params.year), "YYYY-MM-DD"),
      // lastDate: this.props.match.params.endDate,
      lastDate: setDate(this.props.match.params.endDate, lastDayOfMonth(this.props.match.params.endDate).getDate()),
      loading_search: false,
      sum_income: [],
      sum_expenses: {},
      total_sum_expenses: {}
    };
  }

  componentDidMount() {
    this.getDate();
  }

  getDate = async () => {
    this.setState({ loading_search: true, lists: _.cloneDeep(_list) });
    let budget;
    let sum_annual_budget_income = 0
    let sum_total_past_budget = 0
    let sum_total_happend = 0
    let sum_diff_past_happend = 0
    let sum_percent_diff_past_happend = 0
    let sum_budget_remaining = 0
    let sum_percent_budget_remaining = 0
    let sum_expenses = {}
    let key = {
      sum_annual_budget_income: 0,
      sum_total_past_budget: 0,
      sum_total_happend: 0,
      sum_diff_past_happend: 0,
      sum_percent_diff_past_happend: 0,
      sum_budget_remaining: 0,
      sum_percent_budget_remaining: 0
    }
    await fetchQuery(environment, query, { yearId: this.state.year_search, lastDate: format(this.state.lastDate, "YYYY-MM-DD"), }).then((data) => { budget = data; }).catch((error) => { });
    let start = new Date(budget.oneYearBudget.edges[0].node.startDate);
    let end = addMonths(new Date(budget.oneYearBudget.edges[0].node.endDate), 1);
    let allChartOfAccountBudget = await budget.allChartOfAccountBudget.edges.map((item) => {
      let data = _.cloneDeep(item);
      data.node.summaryYear = JSON.parse(item.node.summaryYear);
      return data;
    });
    let list = _.cloneDeep(this.state.lists);
    let list_month = [];
    do {
      list_month.push(start);
      start = addMonths(start, 1);
    } while (format(start, "MM-YYYY") !== format(end, "MM-YYYY"));

    for (const [index, item] of budget.oneYearBudget.edges[0].node.chartOfAccountBudget.edges.entries()) {
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 1) === "4") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
        }

        check_null &&
          list[4].push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "51") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          let code = item.node.chartOfAccount.chartOfAccountCode.slice(0, 4)
          if (!sum_expenses[code]) {
            sum_expenses[code] = _.cloneDeep(key)
          }
          sum_expenses[code].sum_annual_budget_income = sum_expenses[code].sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_expenses[code].sum_total_past_budget = sum_expenses[code].sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_expenses[code].sum_total_happend = sum_expenses[code].sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_expenses[code].sum_diff_past_happend = sum_expenses[code].sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_expenses[code].sum_percent_diff_past_happend = sum_expenses[code].sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_expenses[code].sum_budget_remaining = sum_expenses[code].sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_expenses[code].sum_percent_budget_remaining = sum_expenses[code].sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
          list[5][0].child.push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
        }
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "52") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          let code = item.node.chartOfAccount.chartOfAccountCode.slice(0, 4)
          if (!sum_expenses[code]) {
            sum_expenses[code] = _.cloneDeep(key)
          }
          sum_expenses[code].sum_annual_budget_income = sum_expenses[code].sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_expenses[code].sum_total_past_budget = sum_expenses[code].sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_expenses[code].sum_total_happend = sum_expenses[code].sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_expenses[code].sum_diff_past_happend = sum_expenses[code].sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_expenses[code].sum_percent_diff_past_happend = sum_expenses[code].sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_expenses[code].sum_budget_remaining = sum_expenses[code].sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_expenses[code].sum_percent_budget_remaining = sum_expenses[code].sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
          list[5][1].child.push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
        }
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "53" && item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) !== "531") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          let code = item.node.chartOfAccount.chartOfAccountCode.slice(0, 4)
          if (!sum_expenses[code]) {
            sum_expenses[code] = _.cloneDeep(key)
          }
          sum_expenses[code].sum_annual_budget_income = sum_expenses[code].sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_expenses[code].sum_total_past_budget = sum_expenses[code].sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_expenses[code].sum_total_happend = sum_expenses[code].sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_expenses[code].sum_diff_past_happend = sum_expenses[code].sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_expenses[code].sum_percent_diff_past_happend = sum_expenses[code].sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_expenses[code].sum_budget_remaining = sum_expenses[code].sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_expenses[code].sum_percent_budget_remaining = sum_expenses[code].sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
          list[5][2].child.push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
        }
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "531") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          let code = item.node.chartOfAccount.chartOfAccountCode.slice(0, 4)
          if (!sum_expenses[code]) {
            sum_expenses[code] = _.cloneDeep(key)
          }
          sum_expenses[code].sum_annual_budget_income = sum_expenses[code].sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_expenses[code].sum_total_past_budget = sum_expenses[code].sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_expenses[code].sum_total_happend = sum_expenses[code].sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_expenses[code].sum_diff_past_happend = sum_expenses[code].sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_expenses[code].sum_percent_diff_past_happend = sum_expenses[code].sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_expenses[code].sum_budget_remaining = sum_expenses[code].sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_expenses[code].sum_percent_budget_remaining = sum_expenses[code].sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
          list[5][3].child.push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
        }
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "54") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          let code = item.node.chartOfAccount.chartOfAccountCode.slice(0, 4)
          if (!sum_expenses[code]) {
            sum_expenses[code] = _.cloneDeep(key)
          }
          sum_expenses[code].sum_annual_budget_income = sum_expenses[code].sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_expenses[code].sum_total_past_budget = sum_expenses[code].sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_expenses[code].sum_total_happend = sum_expenses[code].sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_expenses[code].sum_diff_past_happend = sum_expenses[code].sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_expenses[code].sum_percent_diff_past_happend = sum_expenses[code].sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_expenses[code].sum_budget_remaining = sum_expenses[code].sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_expenses[code].sum_percent_budget_remaining = sum_expenses[code].sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
          list[5][4].child.push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
        }
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "55") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          let code = item.node.chartOfAccount.chartOfAccountCode.slice(0, 4)
          if (!sum_expenses[code]) {
            sum_expenses[code] = _.cloneDeep(key)
          }
          sum_expenses[code].sum_annual_budget_income = sum_expenses[code].sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_expenses[code].sum_total_past_budget = sum_expenses[code].sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_expenses[code].sum_total_happend = sum_expenses[code].sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_expenses[code].sum_diff_past_happend = sum_expenses[code].sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_expenses[code].sum_percent_diff_past_happend = sum_expenses[code].sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_expenses[code].sum_budget_remaining = sum_expenses[code].sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_expenses[code].sum_percent_budget_remaining = sum_expenses[code].sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
          list[5][5].child.push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
        }
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "56") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          let code = item.node.chartOfAccount.chartOfAccountCode.slice(0, 4)
          if (!sum_expenses[code]) {
            sum_expenses[code] = _.cloneDeep(key)
          }
          sum_expenses[code].sum_annual_budget_income = sum_expenses[code].sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_expenses[code].sum_total_past_budget = sum_expenses[code].sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_expenses[code].sum_total_happend = sum_expenses[code].sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_expenses[code].sum_diff_past_happend = sum_expenses[code].sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_expenses[code].sum_percent_diff_past_happend = sum_expenses[code].sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_expenses[code].sum_budget_remaining = sum_expenses[code].sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_expenses[code].sum_percent_budget_remaining = sum_expenses[code].sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
          list[5][6].child.push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
        }
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "57") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          let code = item.node.chartOfAccount.chartOfAccountCode.slice(0, 4)
          if (!sum_expenses[code]) {
            sum_expenses[code] = _.cloneDeep(key)
          }
          sum_expenses[code].sum_annual_budget_income = sum_expenses[code].sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_expenses[code].sum_total_past_budget = sum_expenses[code].sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_expenses[code].sum_total_happend = sum_expenses[code].sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_expenses[code].sum_diff_past_happend = sum_expenses[code].sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_expenses[code].sum_percent_diff_past_happend = sum_expenses[code].sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_expenses[code].sum_budget_remaining = sum_expenses[code].sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_expenses[code].sum_percent_budget_remaining = sum_expenses[code].sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
          list[5][7].child.push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
        }
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "581") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          let code = '5800'
          if (!sum_expenses[code]) {
            sum_expenses[code] = _.cloneDeep(key)
          }
          sum_expenses[code].sum_annual_budget_income = sum_expenses[code].sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_expenses[code].sum_total_past_budget = sum_expenses[code].sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_expenses[code].sum_total_happend = sum_expenses[code].sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_expenses[code].sum_diff_past_happend = sum_expenses[code].sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_expenses[code].sum_percent_diff_past_happend = sum_expenses[code].sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_expenses[code].sum_budget_remaining = sum_expenses[code].sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_expenses[code].sum_percent_budget_remaining = sum_expenses[code].sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
          list[5][8].child.push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
        }
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "582") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          let code = '5800'
          if (!sum_expenses[code]) {
            sum_expenses[code] = _.cloneDeep(key)
          }
          sum_expenses[code].sum_annual_budget_income = sum_expenses[code].sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_expenses[code].sum_total_past_budget = sum_expenses[code].sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_expenses[code].sum_total_happend = sum_expenses[code].sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_expenses[code].sum_diff_past_happend = sum_expenses[code].sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_expenses[code].sum_percent_diff_past_happend = sum_expenses[code].sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_expenses[code].sum_budget_remaining = sum_expenses[code].sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_expenses[code].sum_percent_budget_remaining = sum_expenses[code].sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
          list[5][8].child.push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
        }
      }
      if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "583") {
        let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
        if (check_null) {
          let code = '5800'
          if (!sum_expenses[code]) {
            sum_expenses[code] = _.cloneDeep(_.cloneDeep(key))
          }
          sum_expenses[code].sum_annual_budget_income = sum_expenses[code].sum_annual_budget_income += parseFloat(item.node.totalBudget) || 0
          sum_expenses[code].sum_total_past_budget = sum_expenses[code].sum_total_past_budget += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_past_budget) || 0
          sum_expenses[code].sum_total_happend = sum_expenses[code].sum_total_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.total_happend) || 0
          sum_expenses[code].sum_diff_past_happend = sum_expenses[code].sum_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.diff_past_happend) || 0
          sum_expenses[code].sum_percent_diff_past_happend = sum_expenses[code].sum_percent_diff_past_happend += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_diff_past_happend) || 0
          sum_expenses[code].sum_budget_remaining = sum_expenses[code].sum_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.budget_remaining) || 0
          sum_expenses[code].sum_percent_budget_remaining = sum_expenses[code].sum_percent_budget_remaining += parseFloat(allChartOfAccountBudget[index].node.summaryYear.percent_budget_remaining) || 0
          list[5][8].child.push({
            ...item.node.chartOfAccount,
            month_budget: item.node.monthBudget.edges,
            total_budget: item.node.totalBudget || "",
            allChartOfAccountBudget: allChartOfAccountBudget[index],
          });
        }
      }
    }

    let total_sum_expenses = _.cloneDeep(key)
    _.map(sum_expenses, (item, index) => {
      total_sum_expenses.sum_annual_budget_income = total_sum_expenses.sum_annual_budget_income += parseFloat(item.sum_annual_budget_income) || 0
      total_sum_expenses.sum_total_past_budget = total_sum_expenses.sum_total_past_budget += parseFloat(item.sum_total_past_budget) || 0
      total_sum_expenses.sum_total_happend = total_sum_expenses.sum_total_happend += parseFloat(item.sum_total_happend) || 0
      total_sum_expenses.sum_diff_past_happend = total_sum_expenses.sum_diff_past_happend += parseFloat(item.sum_diff_past_happend) || 0
      total_sum_expenses.sum_percent_diff_past_happend = total_sum_expenses.sum_percent_diff_past_happend += parseFloat(item.sum_percent_diff_past_happend) || 0
      total_sum_expenses.sum_budget_remaining = total_sum_expenses.sum_budget_remaining += parseFloat(item.sum_budget_remaining) || 0
      total_sum_expenses.sum_percent_budget_remaining = total_sum_expenses.sum_percent_budget_remaining += parseFloat(item.sum_percent_budget_remaining) || 0
    });

    this.setState({
      lists: list,
      loading: false,
      loading_search: false,
      list_month,
      year: new Date(budget.oneYearBudget.edges[0].node.years),
      start_date: new Date(budget.oneYearBudget.edges[0].node.startDate),
      end_date: new Date(budget.oneYearBudget.edges[0].node.endDate),
      id: budget.oneYearBudget.edges[0].node.id,
      project_name: budget.oneYearBudget.edges[0].node.project.name,
      allChartOfAccountBudget: allChartOfAccountBudget,
      sum_income: {
        sum_annual_budget_income,
        sum_total_past_budget,
        sum_total_happend,
        sum_diff_past_happend,
        sum_percent_diff_past_happend,
        sum_budget_remaining,
        sum_percent_budget_remaining
      },
      sum_expenses: sum_expenses,
      total_sum_expenses: total_sum_expenses,
    });
  };

  toFixedNumber = (value) => {
    let sub = parseFloat(value);
    sub = sub.toString();
    if (sub.indexOf(".") > 0) {
      return numberWithCommas(sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3));
    } else if (value == 0) {
      return "-";
    } else if (value == "") {
      return "-";
    } else {
      return numberWithCommas(sub);
    }
  };

  render() {
    let last_index = 0;
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box budget p-4" id="budget">
                <BackButtonIcon
                  LinkBack={"/accounting/budget/report/report-usage-summary"}
                  LinkText={i18next.t("menuListBudget:Summary Budget Usage Report")}
                  boxHtmlText={
                    <div className="row mt-2 d-flex justify-content-end" id='interest-report' style={{ position: 'absolute', marginRight: 24, right: 0, top: '1%' }} >
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_budget_print' }) &&
                        <Dropdown >
                          <DropdownToggle id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>{i18next.t("Allaction:Print")}</DropdownToggle>
                          <DropdownMenu>
                            {/* <Dropdown.Item>
                        <Link to={{
                            pathname: `/accounting/budget/report/wrapper-report-seting/${this.props.match.params.id}/${this.props.match.params.year}`,
                            query: { lastDate: new Date() }
                        }}
                            className="text-black">
                            <p className="text-black">PDF</p>
                        </Link>
                    </Dropdown.Item> */}
                            <BudgetUsageSummaryExcelExports
                              year={this.props.match.params.id}
                              state={this.state}
                            />
                          </DropdownMenu>
                        </Dropdown>
                      }
                    </div >
                  }
                />
                <div className="">
                  <div className="">
                    <h3 className="text-center">{this.state.project_name}</h3>
                    <h3 className="text-center">{i18next.t("menuListBudget:Summary Budget Usage Report")}</h3>
                    <h3 className="text-center">
                      {` ${i18next.t("AllFilter:Date")} `}
                      <GetdateOnEngAndTh
                        date={this.state.start_date}
                        withDate={true}
                        withFullMonth={true}
                        withFullYear={true}
                      />
                      {` ${i18next.t("AllFilter:to")} `}
                      <GetdateOnEngAndTh
                        date={this.state.lastDate}
                        withDate={true}
                        withFullMonth={true}
                        withFullYear={true}
                      />
                    </h3>
                  </div>

                  <div className="d-flex mt-3 justify-content-end align-items-center">
                    <div className="bold">{i18next.t("AllFilter:to")}: </div>
                    <div className="">
                      <DatePicker
                        className="form-control float-left colorborder"
                        selected={new Date(this.state.lastDate)}
                        onChange={(date) => this.setState({ lastDate: format(date, "YYYY-MM-DD") })}
                        dateFormat="dd/MM/yyyy"
                        customInput={<ButtonDate date />}
                        minDate={new Date(this.state.start_date)}
                        maxDate={new Date(this.state.end_date)}
                        disabled={!this.state.disabled_table}
                      />
                    </div>
                    <Button primary onClick={this.getDate}>{i18next.t("AllFilter:Search")}</Button>
                  </div>
                </div>
                {!this.state.loading_search ? (
                  <React.Fragment>
                    <div className="card fade-up mt-4 " id="parcel">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th rowSpan={2} className="text-center"><H6>{i18next.t("budgetUsageSummaryReport:Sequence")}</H6></th>
                              <th rowSpan={2} className="text-center"><H6>{i18next.t("budgetUsageSummaryReport:Item")}</H6></th>
                              <th rowSpan={2} className="text-center">
                                <H6>{i18next.t("budgetUsageSummaryReport:Annual Budget")}</H6>
                                <H6>
                                  <GetdateOnEngAndTh
                                    date={this.state.start_date}
                                    withMonthShort={true}
                                    withYearShort={true}
                                  /> -
                                  <GetdateOnEngAndTh
                                    date={this.state.lastDate}
                                    withMonthShort={true}
                                    withYearShort={true}
                                  />
                                </H6>
                              </th>
                              <th colSpan={4} className="text-center">
                                <H6>
                                  {`${i18next.t("budgetUsageSummaryReport:Round")} `}
                                  <GetdateOnEngAndTh
                                    date={this.state.start_date}
                                    withMonthShort={true}
                                    withYearShort={true}
                                  /> -
                                  <GetdateOnEngAndTh
                                    date={this.state.lastDate}
                                    withMonthShort={true}
                                    withYearShort={true}
                                  />
                                </H6>
                              </th>
                              <th rowSpan={2} className="text-center">
                                <H6>{i18next.t("budgetUsageSummaryReport:Remaining Budget")}</H6>
                                {/* {format(new Date, "MMMYY", { locale: thLocale })<=format(this.state.end_date, "MMMYY", { locale: thLocale })&& */}
                                {format(this.state.end_date, "MMMYY", { locale: thLocale }) > format(this.state.lastDate, "MMMYY", { locale: thLocale }) &&
                                  <H6>
                                    <GetdateOnEngAndTh
                                      date={this.state.lastDate}
                                      withFullMonth={true}
                                      withFullYear={true}
                                    /> -
                                    <GetdateOnEngAndTh
                                      date={this.state.end_date}
                                      withMonthShort={true}
                                      withYearShort={true}
                                    />
                                  </H6>}
                              </th>
                              <th rowSpan={2} className="text-center"><H6>%</H6></th>
                            </tr>
                            <tr className="border-top">
                              <th className="text-center"><H6>{i18next.t("budgetUsageSummaryReport:Budget")}</H6></th>
                              <th className="text-center"><H6>{i18next.t("budgetUsageSummaryReport:Actual")}</H6></th>
                              <th className="text-center"><H6>{i18next.t("budgetUsageSummaryReport:Difference (Increase/Decrease)")}</H6></th>
                              <th className="text-center"><H6>%</H6></th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(this.state.lists[4], (item, index) => {
                              ++last_index;
                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td className="text-center"><P2>{last_index}</P2></td>
                                    <td style={{ paddingLeft: 50, minWidth: 300 }}>
                                      {index == 0 && <H6 style={{ marginLeft: -32 }}>รายรับ</H6>}
                                      <P2>{item.name}</P2>
                                    </td>
                                    <td className="text-center" width={270}><P2>{numberWithCommas(item.total_budget)}</P2></td>
                                    <td className="text-center" width={150}> <P2>{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.total_past_budget)}</P2></td>
                                    <td className="text-center td-red" width={150}> <P2>{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.total_happend)}</P2> </td>
                                    <td className="text-center" width={150}> <P2>{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.diff_past_happend)}</P2></td>
                                    <td width={150} className="text-center"><P2>{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.percent_diff_past_happend)}</P2></td>
                                    <td width={200} className="text-center td-green"><P2>{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.budget_remaining)}</P2></td>
                                    <td width={200} className="text-center"><P2>{numberWithCommas(item.allChartOfAccountBudget.node.summaryYear.percent_budget_remaining)}</P2></td>
                                  </tr>
                                  {index === this.state.lists[4].length - 1 && (
                                    <tr className="td-sum">
                                      <td />
                                      <td style={{ paddingLeft: 50, minWidth: 300 }}><H6>รวมรายรับ</H6></td>
                                      {_.map(this.state.sum_income, (item, index) => <td key={index} width={120} height={24} className="text-center"><H6>{numberWithCommas(item)}</H6></td>)}
                                    </tr>
                                  )}
                                </React.Fragment>
                              );
                            })}
                            {_.map(this.state.lists[5], (item, index_code) => {
                              return (
                                <React.Fragment key={index_code}>
                                  {item.child.length > 0 && (
                                    <React.Fragment>
                                      <tr>
                                        <td />
                                        <td>
                                          <div className="ml-2">
                                            {index_code == 51 && <H6>รายจ่าย</H6>}
                                            <P2>{item.name}</P2>
                                          </div>
                                        </td>
                                      </tr>
                                      {_.map(item.child, (item_child, index) => {
                                        ++last_index;
                                        return (
                                          <React.Fragment key={index}>
                                            <tr>
                                              <td className="text-center"><P2>{last_index}</P2></td>
                                              <td style={{ paddingLeft: 50, minWidth: 300 }}><P2>{item_child.name}</P2></td>
                                              <td className="text-center" ><P2>{numberWithCommas(item_child.total_budget)}</P2></td>
                                              <td className="text-center" ><P2>{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.total_past_budget)}</P2></td>
                                              <td className="text-center td-red"><P2>{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.total_happend)}</P2></td>
                                              <td className="text-center" ><P2>{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.diff_past_happend)}</P2></td>
                                              <td className="text-center"><P2>{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.percent_diff_past_happend)}</P2></td>
                                              <td className="text-center td-green"> <P2>{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.budget_remaining)}</P2> </td>
                                              <td className="text-center"> <P2>{numberWithCommas(item_child.allChartOfAccountBudget.node.summaryYear.percent_budget_remaining)}</P2>
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        );
                                      })}

                                      <tr className="td-sum ">
                                        <td />
                                        <td style={{ paddingLeft: 50, minWidth: 300 }}><H6>รวม{item.name}</H6></td>
                                        {_.map(this.state.sum_expenses[item.key], (sum, index) => <td key={index} height={24} className="text-center"><H6>{numberWithCommas(sum)}</H6></td>)}
                                      </tr>
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              );
                            })}
                            <tr className="td-sum2 ">
                              <td />
                              <td style={{ paddingLeft: 50, minWidth: 300 }}><H6>รวมรายจ่าย</H6></td>
                              {_.map(this.state.total_sum_expenses, (sum, index) => <td key={index} height={24} className="text-center"><H6>{numberWithCommas(sum)}</H6></td>)}
                            </tr>
                            <tr className="td-sum3 ">
                              <td />
                              <td style={{ paddingLeft: 50, minWidth: 300 }}><H6>รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด</H6></td>
                              {_.map(this.state.total_sum_expenses, (sum, index) => <td key={index} height={24} className="text-center"><H6>{numberWithCommas(this.state.sum_income[index] - sum)}</H6></td>)}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </React.Fragment>) : (<Loading />)}
              </div>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

const ButtonDate = forwardRef(({ value, onClick, disabled, date }, ref) => (
  <button
    type="button"
    className="date form-control booking-form summary-report-date "
    style={{ width: "100%" }}
    onClick={onClick}
    ref={ref}
    disabled={disabled}>
    {value}
    {date && (
      <div
        className="calendars"
        style={{
          position: "absolute",
          right: 0,
          marginRight: 8,
        }}>
        <img className="icon" src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"} alt="calendars" />
      </div>
    )}
  </button>
));

export default CreateBudget;
