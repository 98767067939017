import React from 'react'
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { Link } from 'react-router-dom';
import { Button } from "../../../components/Button/Button";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import ComponentPagination from '../../../libs/componentPagination';
import Pagination from '../../../libs/newPagination';
import _ from "lodash"
import localstorage from '../../../libs/localstorage';
import AccountingTopMenuNavigation from '../../accountingTopMenuNavigation';
import { format } from 'date-fns';
import Loading from '../../../libs/loading';
import { Translation } from 'react-i18next';
import BackButtonIcon from '../../../components/BackBtn/indexBack';
import i18next from 'i18next';

const query = graphql`
  query reportRegisterListQuery($first: Int, $last: Int) {
    allYearBudget(first: $first, last: $last, status: "approve"){
      edges {
          node {
              id
              years
              startDate
              endDate
              creator
              status    
              project{
                  name
              }
          }
      }
      totalCount
    }
  }
`

class budgetRegisterList extends ComponentPagination {

  componentWillMount() {
    this.goFirst()
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box booking-form p-4 mt-3 " id="parcel" >
                <BackButtonIcon 
                  LinkBack={"/accounting/budget/"}
                  LinkText={i18next.t("menuListBudget:Budget Usage Control Register")}
                />
                <QueryRenderer
                  environment={environment}
                  query={query}
                  fetchPolicy="no-cache"
                  variables={{ first: this.state.first, last: this.state.last }}
                  render={({ error, props }) => {
                    let order_id = this.state.first - localstorage.getPageLimit();
                    if (props) {
                      return (
                        <React.Fragment>
                          <div className="card fade-up mt-4 " id="parcel">
                            <div className="table-responsive">
                              <table className="table table-hover budgetß ">
                                <React.Fragment>
                                  <thead>
                                    <tr>
                                      <th className="text-center">{i18next.t("budgetAll:Sequence")}</th>
                                      <th className="text-center">{i18next.t("budgetAll:Corporate name")}</th>
                                      <th className="text-center">{i18next.t("budgetAll:Budgeting year")}</th>
                                      <th className="text-center">{i18next.t("budgetAll:Budgeting year start date")}</th>
                                      <th className="text-center">{i18next.t("budgetAll:Budgeting year end date")}</th>
                                      <th className="text-center" />
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {_.map(props.allYearBudget.edges, (budget, index) => (
                                      <React.Fragment key={index}>
                                        <tr className="show">
                                          <td className="text-center">
                                            {order_id + index + 1}
                                          </td>
                                          <td className="text-center">
                                            {budget.node.project?.name}
                                          </td>
                                          <td className="text-center">
                                            {budget.node.years}
                                          </td>
                                          <td className="text-center">
                                            {format(budget.node.startDate, "DD/MM/YYYY")}
                                          </td>
                                          <td className="text-center">
                                            {format(budget.node.endDate, "DD/MM/YYYY")}
                                          </td>
                                          <td className="text-center">
                                            <Link
                                              to={`/accounting/budget/reportRegister/detail/${budget.node.id}`}
                                            >
                                              <Button>{i18next.t("receivable_dashboard:View")}</Button>
                                            </Link>
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ))}
                                  </tbody>
                                </React.Fragment>
                              </table>
                            </div>
                          </div>
                          <div className="row">
                            <Pagination
                              changePage={this.changePage}
                              first={this.state.first}
                              last={this.state.last}
                              totalCount={props.allYearBudget.totalCount}
                            />
                          </div>
                        </React.Fragment>
                      )
                    } else {
                      return <Loading />
                    }
                  }}
                />
              </div>

            }
          </Translation>

        </WrapperContent>
      </Wrapper>
    )
  }
}
export default budgetRegisterList;
