/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductAndServiceType = "EXPENSE" | "FINE" | "PRODUCT" | "SERVICE" | "%future added value";
export type waterAndElectricUtilityReceiveReportQueryVariables = {||};
export type waterAndElectricUtilityReceiveReportQueryResponse = {|
  +productViewer: ?{|
    +allProduct: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +productCode: string,
          +type: ProductAndServiceType,
          +price: ?number,
          +chartOfAccount: ?{|
            +id: string,
            +chartOfAccountCode: string,
            +name: string,
          |},
          +description: ?string,
          +totalLeftInStock: ?number,
        |}
      |}>
    |}
  |}
|};
export type waterAndElectricUtilityReceiveReportQuery = {|
  variables: waterAndElectricUtilityReceiveReportQueryVariables,
  response: waterAndElectricUtilityReceiveReportQueryResponse,
|};
*/


/*
query waterAndElectricUtilityReceiveReportQuery {
  productViewer {
    allProduct(type_In: "product, service, fine", servicePricing_PricingType_In: "water_meter, electric_meter") {
      edges {
        node {
          id
          name
          productCode
          type
          price
          chartOfAccount {
            id
            chartOfAccountCode
            name
          }
          description
          totalLeftInStock
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "servicePricing_PricingType_In",
      "value": "water_meter, electric_meter"
    },
    {
      "kind": "Literal",
      "name": "type_In",
      "value": "product, service, fine"
    }
  ],
  "concreteType": "ProductAndServiceNodeConnection",
  "kind": "LinkedField",
  "name": "allProduct",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAndServiceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChartOfAccountNode",
              "kind": "LinkedField",
              "name": "chartOfAccount",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "chartOfAccountCode",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalLeftInStock",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allProduct(servicePricing_PricingType_In:\"water_meter, electric_meter\",type_In:\"product, service, fine\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "waterAndElectricUtilityReceiveReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "waterAndElectricUtilityReceiveReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c07af09833e4fae92db948657dd632c",
    "id": null,
    "metadata": {},
    "name": "waterAndElectricUtilityReceiveReportQuery",
    "operationKind": "query",
    "text": "query waterAndElectricUtilityReceiveReportQuery {\n  productViewer {\n    allProduct(type_In: \"product, service, fine\", servicePricing_PricingType_In: \"water_meter, electric_meter\") {\n      edges {\n        node {\n          id\n          name\n          productCode\n          type\n          price\n          chartOfAccount {\n            id\n            chartOfAccountCode\n            name\n          }\n          description\n          totalLeftInStock\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '07b3ed303769e212b0c3e5ffd66181d4';

module.exports = node;
