import React, { Component
 } from 'react';
import Swal from "sweetalert2";
import i18n from "i18next";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import Navigation from './navigation'
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import '../settingSecurity.scss';
import _ from 'lodash';
import API from '../../../api/setting'
import { Redirect } from 'react-router'
import Loading from "../../../libs/loading"
import i18next from 'i18next';

class settingPackingCompnany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            settingSecurity: {
                settingSecuritySystemId: "",
                name: "",
                phoneMain: "",
                phoneSpare: "",
                parkingRent: 0,
            },
            selectedId: 0,
            account: [],
            mapAccount: [],
            redirect: false,
            loading: true
        }
        this.onChangeData = this.onChangeData.bind(this);
        this.dropDownChanged = this.dropDownChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onKeyUpChk = this.onKeyUpChk.bind(this)

    }

    componentWillMount() {
        this.getDataCompaynSecurity()
    }

    onKeyUpChk(event) {
        let e = _.cloneDeep(event)

        let numm = parseInt(e.key) == NaN ? null : parseInt(e.key)
        if (!numm && parseInt(e.key) != 0 && e.keyCode != 8 && e.key != "Unidentified") {
            Swal.fire('ผิดพลาด', 'ระบุตัวเลขเท่านั้น', 'error')
            e.target.value = ""
        }
    }

    onChangeData(e) {
        let dataState = _.cloneDeep(this.state.settingSecurity)
        dataState.name = e.target.name === 'name' ? e.target.value : dataState.name;
        dataState.phoneMain = e.target.name === 'phoneMain' ? e.target.value : dataState.phoneMain;
        dataState.phoneSpare = e.target.name === 'phoneSpare' ? e.target.value : dataState.phoneSpare;
        dataState.parkingRent = e.target.name === 'parkingRent' ? e.target.value : dataState.parkingRent;
        this.setState({ settingSecurity: dataState })
    }

    // GET บริษัทที่รับผิดชอบ
    getDataCompaynSecurity() {
        API.getCompanySetting().then((res) => {
            if (res.data.settingSecuritySystem) {
                let data = res.data.settingSecuritySystem
                this.setState({ settingSecurity: data, selectedId: data.settingSecuritySystemId })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error')).finally(() => {
            this.setState({ loading: false })
        })
    }

    dropDownChanged(e) {
        this.setState({ selectedId: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault();

        let idCode = this.state.account.settingParkingFeeAccountId != "" ?
            this.state.account.settingParkingFeeAccountId : ""

        let data = {
            "settingSecuritySystemId": this.state.selectedId != 0 ? this.state.settingSecurity.settingSecuritySystemId : "",
            "name": this.state.settingSecurity.name,
            "phoneMain": this.state.settingSecurity.phoneMain,
            "phoneSpare": this.state.settingSecurity.phoneSpare,
            "parkingRent": this.state.settingSecurity.parkingRent
        }

        if (data.name != "") {
            API.saveCompanySetting(data).then((res) => {
                if (res.data.statusMessage === "SUCCESS") {
                    Swal.fire(i18n.t('settingSecurityCompany:Successful'), i18n.t('settingSecurityCompany:Saved successful'), 'success').then(() => {
                        this.setState({ redirect: true })
                    })
                } else {
                    Swal.fire(i18n.t('settingSecurityCompany:Unuccessful'), i18n.t('settingSecurityCompany:Save failed'), 'error')
                }
            }, () => {
                Swal.fire(i18n.t('settingSecurityCompany:Unuccessful'), i18n.t('settingSecurityCompany:Unable to connect to the API at this time.'), 'error')
            })

        } else {
            Swal.fire(i18n.t('settingSecurityCompany:Unuccessful'), i18n.t('settingSecurityCompany:Please specify the company name.'), 'error')

        }

    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/setting/setting-keep-packing/all" />
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid box" id="settingPacking">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span style={{ fontWeight: 'bolder' }}>{t('setting:settingKeepPacking')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>

                        <Navigation />

                        {
                            this.state.loading == false &&
                            <Translation>
                                {t =>
                                    <div className='row'>
                                        <div className='col-sm-8 mx-auto'>
                                            <form onSubmit={this.onSubmit}>
                                                <div className="row" style={{ marginTop: '40px' }}>
                                                    <div className="col-8">
                                                        <h3>{t('settingSecurityCompany:head')} </h3>
                                                        <hr />
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginTop: '25px' }}>
                                                    <div className="col-8">
                                                        <label>{t('settingSecurityCompany:name')} <label className='text-danger'> * <span className='text-danger fw-300' style={{fontSize:'10px'}}>**{t("createUpdateForm:Enter message not more than")} 800 {t("createUpdateForm:characters")} {this.state.settingSecurity.name.length} / 200 </span></label></label>
                                                        <input type={'text'} name='name' placeholder={t('settingSecurityCompany:placeHolname')}
                                                            className={this.state.settingSecurity.name.length > 0 ? 'form-control' : 'form-control invlidInput'}
                                                            maxLength={200}
                                                            required={true} onChange={this.onChangeData}
                                                            defaultValue={this.state.settingSecurity.name}
                                                        />

                                                        {this.state.settingSecurity.name.length < 1 &&
                                                            <p className='invlidText'>{t('settingSecurityCompany:Please enter the name of the company responsible for security.')} </p>
                                                        }

                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginTop: '16px' }}>
                                                    <div className="col-8">
                                                        <label>{t('settingSecurityCompany:phoneMain')}</label>
                                                        <input type='text' name='phoneMain' className='form-control'
                                                            placeholder={t('settingSecurityCompany:placeHolphoneMain')}
                                                            defaultValue={this.state.settingSecurity.phoneMain}
                                                            pattern='[0-9]{9,10}' maxLength="10"
                                                            onChange={this.onChangeData} onKeyUp={this.onKeyUpChk} />
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginTop: '16px' }}>
                                                    <div className="col-8">
                                                        <label>{t('settingSecurityCompany:phoneSub')}</label>
                                                        <input type='text' name='phoneSpare' className='form-control'
                                                            placeholder={t('settingSecurityCompany:placeHolphoneSub')}
                                                            defaultValue={this.state.settingSecurity.phoneSpare}
                                                            pattern='[0-9]{9,10}' maxLength="10"
                                                            onChange={this.onChangeData} onKeyUp={this.onKeyUpChk} />
                                                    </div>
                                                </div>


                                                <div className="row" style={{ marginTop: '40px' }}>
                                                    <div className="col-8">
                                                        <h3>{t('settingSecurityCompany:The total number of parking for rental space')}</h3>
                                                        <hr />
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginTop: '25px' }}>
                                                    <div className="col-8">
                                                        <label>{t('settingSecurityCompany:The total number of parking')} <label className='text-danger'>*</label></label>
                                                        <input type={'number'} name='parkingRent' placeholder={"จำนวนช่อง"}
                                                            className={parseInt(this.state.settingSecurity.parkingRent)  >= 0 ? 'form-control' : 'form-control invlidInput'}

                                                            required={true} onChange={this.onChangeData}
                                                            defaultValue={this.state.settingSecurity.parkingRent}
                                                        />

                                                        {parseInt(this.state.settingSecurity.parkingRent) < 0 &&
                                                            <p className='invlidText'>{t('settingSecurityCompany:Please specify the number of parking.')} </p>
                                                        }

                                                    </div>
                                                </div>



                                                <div className='row mt-10'>
                                                    <div className='col-12 text-right'>

                                                        <button type='submit' className='btn btn-primary'>
                                                            {t('Allaction:save')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                }

                            </Translation>
                        }

                        {
                            this.state.loading == true && <Loading/>
                        }







                    </div>


                </WrapperContent>
            </Wrapper>
        )
    }
}

export default settingPackingCompnany;
