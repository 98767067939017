import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import WaterMeterList from "./waterMeterList";
import WaterMeterCreateHistory from "./waterMeterCreateHistory"
import NoMatch from "../../components/noMatch";
import WaterMeterListHistoryByRoomView from './waterMeterListHistoryByRoomView';
import WaterMeterListPrintQrcodeMeterWater from './waterMeterListPrintQrcodeMeterWater'

class WaterMeter extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/printQrcodeMeterWater`} component={WaterMeterListPrintQrcodeMeterWater}/>
                <PrivateRoute exact path={`${this.props.match.url}/viewHistoryDetail/:id?`} component={WaterMeterListHistoryByRoomView}/>
                <PrivateRoute exact path={`${this.props.match.url}/create_history/:id?`} component={WaterMeterCreateHistory}/>
                <PrivateRoute exact path={`${this.props.match.url}/:status`} component={WaterMeterList}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default WaterMeter;