import React, { Component } from 'react';
import { Translation } from "react-i18next";
import _ from "lodash"

const $ = window.jQuery;

class IdCardForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check_perm: false,
            check_perm_print: false,
            isNotDuplicateIDCard: true,
            paramLink: "info",

            uploadFile: [],
            imageBookBank: [],
            idCardNumber: []
        };

    }

    onkeyIdCard = (e) => {
        let { name } = e.target;
        let data = _.cloneDeep(e)
        let idCardNumber = _.cloneDeep(this.state.idCardNumber)
        let taxIdNumber = ""
        let val = ""

        const BACKSPACE_KEY = 8;
        const DELETE_KEY = 46;
        let tabindex = $(e.target).attr("tabindex") || 0;
        tabindex = Number(tabindex);
        if(BACKSPACE_KEY || DELETE_KEY || data.key === "1" || data.key === "2" || data.key === "3" || data.key === "4" || data.key === "5" ||
        data.key === "6" || data.key === "7" || data.key === "8" || data.key === "9" || data.key === "10" ||
        data.key === "11" || data.key === "12" || data.key === "0"){

            if (e.keyCode === BACKSPACE_KEY) {
                tabindex -= 1;
                const elem = $("[tabindex=" + tabindex + "]");
                if (elem[0]) {
                    elem.focus();
                }
            } else if (e.keyCode !== DELETE_KEY) {
                tabindex += 1;
                e.target.value = data.key
            }
            if (data.key === "1" || data.key === "2" || data.key === "3" || data.key === "4" || data.key === "5" ||
                data.key === "6" || data.key === "7" || data.key === "8" || data.key === "9" || data.key === "10" ||
                data.key === "11" || data.key === "12" || data.key === "0") {
                val = data.key;
                const elem = $("[tabindex=" + tabindex + "]");
                if (elem[0]) {
                    elem.focus();
                }
            }
    
            switch (name) {
                case "taxIdNumber_1": _.set(idCardNumber, `[0]`, val);
                    break;
                case "taxIdNumber_2": _.set(idCardNumber, `[1]`, val);
                    break;
                case "taxIdNumber_3": _.set(idCardNumber, `[2]`, val);
                    break;
                case "taxIdNumber_4": _.set(idCardNumber, `[3]`, val);
                    break;
                case "taxIdNumber_5": _.set(idCardNumber, `[4]`, val);
                    break;
                case "taxIdNumber_6": _.set(idCardNumber, `[5]`, val);
                    break;
                case "taxIdNumber_7": _.set(idCardNumber, `[6]`, val);
                    break;
                case "taxIdNumber_8": _.set(idCardNumber, `[7]`, val);
                    break;
                case "taxIdNumber_9": _.set(idCardNumber, `[8]`, val);
                    break;
                case "taxIdNumber_10": _.set(idCardNumber, `[9]`, val);
                    break;
                case "taxIdNumber_11": _.set(idCardNumber, `[10]`, val);
                    break;
                case "taxIdNumber_12": _.set(idCardNumber, `[11]`, val);
                    break;
                case "taxIdNumber_13": _.set(idCardNumber, `[12]`, val);
                    break;
                default: _.set(idCardNumber, `[0]`, val);
                    break;
            }
    
            for (let index = 0; index < idCardNumber.length; index++) {
                taxIdNumber += idCardNumber[index]
            }
    
    
            this.setState({
                idCardNumber: idCardNumber, taxIdNumber: taxIdNumber
            })
            this.props.onkeyIdCard(idCardNumber, taxIdNumber)
        }
    }

    onPasteData = (data) => {
        let taxIdNumber = data.clipboardData.getData('Text')
        let idCardNumber = taxIdNumber.split('');

        this.props.onkeyIdCard(idCardNumber, taxIdNumber)
    }


    render() {
        return (

            <Translation>
                {t =>
                    <React.Fragment>
                        <label id='box1'>
                            <label htmlFor={`taxIdNumber_1}`}>
                                <input tabIndex={0} key={0} disabled={this.props.taxIdNumber === "0105561063013"}
                                    value={this.props.state.idCardNumber[0]}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    onInput={e => e.target.setCustomValidity('')}
                                    onPaste={this.onPasteData}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_1`} id={`taxIdNumber_1`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>
                            <label className='p-2'> - </label>
                        </label>
                        <label id='box2'>
                            <label htmlFor={`taxIdNumber_2`}>
                                <input tabIndex={1} key={1} disabled={this.props.taxIdNumber === "0105561063013"}
                                    defaultValue={this.props.state.idCardNumber[1] || ""}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    onInput={e => e.target.setCustomValidity('')}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_2`} id={`taxIdNumber_2`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>

                            <label htmlFor={`taxIdNumber_3`}>
                                <input tabIndex={2} key={2} disabled={this.props.taxIdNumber === "0105561063013"}
                                    defaultValue={this.props.state.idCardNumber[2] || ""}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    onInput={e => e.target.setCustomValidity('')}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_3`} id={`taxIdNumber_3`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>
                            <label htmlFor={`taxIdNumber_4`}>
                                <input tabIndex={3} key={3} disabled={this.props.taxIdNumber === "0105561063013"}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    defaultValue={this.props.state.idCardNumber[3] || ""}
                                    onInput={e => e.target.setCustomValidity('')}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_4`} id={`taxIdNumber_4`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>
                            <label htmlFor={`taxIdNumber_5`}>
                                <input tabIndex={4} key={4} disabled={this.props.taxIdNumber === "0105561063013"}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    defaultValue={this.props.state.idCardNumber[4 || ""]}
                                    onInput={e => e.target.setCustomValidity('')}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_5`} id={`taxIdNumber_5`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>
                            <label className='p-2'> - </label>

                        </label>
                        <label id='box3'>
                            <label htmlFor={`taxIdNumber_6`}>
                                <input tabIndex={5} key={5} disabled={this.props.taxIdNumber === "0105561063013"}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    onInput={e => e.target.setCustomValidity('')}
                                    defaultValue={this.props.state.idCardNumber[5] || ""}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_6`} id={`taxIdNumber_6`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>
                            <label htmlFor={`taxIdNumber_7`}>
                                <input tabIndex={6} key={6} disabled={this.props.taxIdNumber === "0105561063013"}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    defaultValue={this.props.state.idCardNumber[6] || ""}
                                    onInput={e => e.target.setCustomValidity('')}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_7`} id={`taxIdNumber_7`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>
                            <label htmlFor={`taxIdNumber_8`}>
                                <input tabIndex={7} key={7} disabled={this.props.taxIdNumber === "0105561063013"}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    defaultValue={this.props.state.idCardNumber[7] || ""}
                                    onInput={e => e.target.setCustomValidity('')}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_8`} id={`taxIdNumber_8`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>
                            <label htmlFor={`taxIdNumber_9`}>
                                <input tabIndex={8} key={8} disabled={this.props.taxIdNumber === "0105561063013"}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    defaultValue={this.props.state.idCardNumber[8] || ""}
                                    onInput={e => e.target.setCustomValidity('')}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_9`} id={`taxIdNumber_9`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>
                            <label htmlFor={`taxIdNumber_10`}>
                                <input tabIndex={9} key={9} disabled={this.props.taxIdNumber === "0105561063013"}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    defaultValue={this.props.state.idCardNumber[9] || ""}
                                    onInput={e => e.target.setCustomValidity('')}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_10`} id={`taxIdNumber_10`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>
                            <label className='p-2'> - </label>

                        </label>
                        <label id='box4'>
                            <label htmlFor={`taxIdNumber_11`}>
                                <input tabIndex={10} key={10} disabled={this.props.taxIdNumber === "0105561063013"}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    onInput={e => e.target.setCustomValidity('')}
                                    defaultValue={this.props.state.idCardNumber[10] || ""}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_11`} id={`taxIdNumber_11`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>
                            <label htmlFor={`taxIdNumber_12`}>
                                <input tabIndex={11} key={11} disabled={this.props.taxIdNumber === "0105561063013"}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    defaultValue={this.props.state.idCardNumber[11] || ""}
                                    onInput={e => e.target.setCustomValidity('')}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_12`} id={`taxIdNumber_12`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>

                            <label className='p-2'> - </label>

                        </label>
                        <label id='box4'>
                            <label htmlFor={`taxIdNumber_13`}>
                                <input tabIndex={12} key={12} disabled={this.props.taxIdNumber === "0105561063013"}
                                        required={this.props.require} onInvalid={e => {
                                        if(this.props.require){
                                            e.target.setCustomValidity('โปรดกรอกเป็นตัวเลขให้ถูกต้อง')
                                        } else {
                                            e.target.setCustomValidity('')
                                        }                                      
                                    }}
                                    defaultValue={this.props.state.idCardNumber[12] || ""}
                                    onInput={e => e.target.setCustomValidity('')}
                                    maxLength={1} onKeyUp={this.onkeyIdCard} type={"number"} name={`taxIdNumber_13`} id={`taxIdNumber_13`}
                                    pattern='[0-9]+$' className='form-control w48 h48 text-center' />
                            </label>

                        </label>

                    </React.Fragment>

                }
            </Translation>
        )
    }
}

export default IdCardForm;
