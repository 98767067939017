import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {Redirect} from 'react-router'
import CreditNoteCreateSelectTransaction from "../../income/credit_note/creditNoteCreateSelectTransaction";
import Loading from '../../../libs/loading';

const query = graphql`
    query acceptCreditNoteCreateFormReasonQuery($id: ID!) {
       allPurchaseRecord(contact_Id:$id, status_In:"active, partial_payment"){
        totalCount
        edges{
          node{
            id
            productAndService{
              productCode
            }
            description
            total
            paid
            purchaseRecordGroup{
              docNumber
            }
          }
         }
        }
    }
`;

class AcceptCreditNoteCreateFormReason extends Component {

    render() {
        if (this.props.customerList.length > 0) {
            return (
                    <QueryRenderer
                        environment={environment}
                        query={query}
                        variables={{id: this.props.customerList[0]}}
                        cacheConfig={{use_cache: false}}
                        render={({error, props}) => {
                            if (props) {
                                return <CreditNoteCreateSelectTransaction customerList={this.props.customerList}
                                                                          updateReasonCreate={this.props.updateReasonCreate}
                                                                          transaction_list={props.allPurchaseRecord.edges}
                                                                          totalCount={props.allPurchaseRecord.totalCount}
                                                                          category={'accept_credit_note'}
                                                                          next_page={'/accounting/expense/accept-credit-note/form/create'}
                                                                          back_page={'/accounting/expense/accept-credit-note/form/select_customer'}
                                />
                            } else {
                                return <Loading/>
                            }
                        }}
                    />
            )
        }
        return <Redirect to="/accounting/expense/accept-credit-note/form/select_customer"/>;
    }
}

export default AcceptCreditNoteCreateFormReason;
