/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type bankDepositImpactReportDetailQueryVariables = {|
  bankAccountId?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  search?: ?string,
|};
export type bankDepositImpactReportDetailQueryResponse = {|
  +selfProject: ?{|
    +name: string
  |},
  +allBankAccountTransactionReport: ?$ReadOnlyArray<?any>,
|};
export type bankDepositImpactReportDetailQuery = {|
  variables: bankDepositImpactReportDetailQueryVariables,
  response: bankDepositImpactReportDetailQueryResponse,
|};
*/


/*
query bankDepositImpactReportDetailQuery(
  $bankAccountId: String
  $startDate: Date
  $endDate: Date
  $search: String
) {
  selfProject {
    name
    id
  }
  allBankAccountTransactionReport(bankAccountId: $bankAccountId, startDate: $startDate, endDate: $endDate, search: $search)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bankAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "bankAccountId",
      "variableName": "bankAccountId"
    },
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "endDate"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    },
    {
      "kind": "Variable",
      "name": "startDate",
      "variableName": "startDate"
    }
  ],
  "kind": "ScalarField",
  "name": "allBankAccountTransactionReport",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "bankDepositImpactReportDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "bankDepositImpactReportDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "a115d96adfff7e727008d503fa97b9bb",
    "id": null,
    "metadata": {},
    "name": "bankDepositImpactReportDetailQuery",
    "operationKind": "query",
    "text": "query bankDepositImpactReportDetailQuery(\n  $bankAccountId: String\n  $startDate: Date\n  $endDate: Date\n  $search: String\n) {\n  selfProject {\n    name\n    id\n  }\n  allBankAccountTransactionReport(bankAccountId: $bankAccountId, startDate: $startDate, endDate: $endDate, search: $search)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a74938b8e735d51b46bf42696a0e6b22';

module.exports = node;
