import '../../report.scss';
import React, { useState, useEffect } from 'react';
import Header from "../../../../components/header/index";
import Sidebar from "../../../../components/sidebar/index";
import Wrapper from "../../../../components/wrapper/index";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { format } from "date-fns";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import DatePickerAdapter from '../../../../libs/datePickerAdapter'
import { summaryReportOutstandingReceivable } from './query/summaryReportOutstandingReceivable';
import { H5, H6 } from '../../../../components/Text/Text'
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import BankDepositReportTable from './bankDepositReportTable';
import SummaryAccountsReceivableAndPayableTable from './summaryAccountsReceivableAndPayableTable';
import AccountingTopMenuNavigation from '../../../accountingTopMenuNavigation';
import BankDepositExcelExports from './bankDepositExcelExports';
import postApiUseing from '../../../../libs/postApiUseing';
import i18next from 'i18next';
import GetdateOnEngAndTh from "../../../../components/Action/getdateOnEngAndTh";

const query = graphql`
  query bankDepositReportQuery($lastDate: Date) {
    selfProject {
      name
      nameEn
  }
    allBankAccountReport (lastDate : $lastDate ) {
      edges{
        node{
            bankName
            branch
            accountType
            accountNumber
            summaryBank
            bankUnit{
                edges{
                    node{
                         issuedDate
                         dueDate
                         price
                    }
                }
          }
        }
      }
    }
    summaryBankReportPurchaseRecordGroup(lastDate:$lastDate){
      numRow
      sumBalance
    }
  }
`;

const BankDepositReport = (props) => {
  const [all_bank, setAllBank] = useState([])
  const [summary_report_residential, setSummaryReportResidential] = useState({})
  const [summary_report_supplier, setSummaryReportSupplier] = useState({})
  const [summary_bank_report, setSummaryBankReport] = useState({})
  const [summary_balance, setSummaryBalance] = useState({})
  const [project_name, setProjectName] = useState('')
  const [project_nameEn, setProjectNameEn] = useState('')
  const [loading, setLoading] = useState(true)
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "bankDepositReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })
    return () => {
    }
  }, [])

  const getData = async () => {
    let lastDate = format(date, "YYYY-MM-DD")
    setLoading(true)
    await summaryReportOutstandingReceivable({ dueDate: lastDate, typeOfContact: 'residential' }, (data) => setSummaryReportResidential(data.summaryReportOutstandingReceivable))
    await summaryReportOutstandingReceivable({ dueDate: lastDate, typeOfContact: 'supplier' }, (data) => setSummaryReportSupplier(data.summaryReportOutstandingReceivable))

    await fetchQuery(environment, query, { lastDate }).then((data) => {
      let sum = _.sumBy(data.allBankAccountReport.edges, function (o) { return parseFloat(JSON.parse(o.node.summaryBank).balance) });
      setProjectName(data.selfProject.name)
      setProjectNameEn(data.selfProject.nameEn)
      setSummaryBalance(sum)
      setAllBank(data.allBankAccountReport.edges)
      setSummaryBankReport(data.summaryBankReportPurchaseRecordGroup)
      setLoading(false)
    });
  }

  const handleSummary = (sumBalance) => (sumBalance / summary_balance) * 100
  useEffect(() => { getData() }, [date])
  return (
    <Wrapper>
      <Header />
      <Sidebar />
      <WrapperContent disabledOverflowX={true}>
        <AccountingTopMenuNavigation mini={true} />
        <Translation>
          {t =>
            <div className="container-fluid box" id='bank-deposit-report-main' >
              <div className="">
                <div className="col">
                  <div className="row">
                    <h3 className="mb-4" >
                      <Link to="/accounting/report/finance/"><img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'} alt="back" className="back" /></Link>
                      <span>{i18next.t("report_finance:Bank Deposit Reports")}</span>
                    </h3>
                    <div className="row ml-2">
                      <H6 className="mr-2">{i18next.t("report_finance:Monthly")}</H6>
                      <DatePickerAdapter selected={date} name="month" onChange={(e) => setDate(e.target.value)} className="form-control" dateFormat="MM/yyyy" showMonthYearPicker />
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <H5 className="text-center">{i18next.t("materWater:am") === "En" && project_nameEn !== "" ? project_nameEn : project_name}</H5>
                  <H5 className="text-center">{i18next.t("report_finance:Bank Deposit Reports")}</H5>
                  <H5 className="text-center">
                    {`${i18next.t("report_finance:Monthly")} `}
                    <GetdateOnEngAndTh
                      date={date}
                      withFullMonth={true}
                      withFullYear={true}
                    />
                  </H5>
                </div>
                <div className="row mt-2 d-flex justify-content-end" id='interest-report' style={{ position: 'absolute', right: '5%', top: '1%' }} >
                  {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'report_financial_print' }) &&
                    <Dropdown >
                      <DropdownToggle disabled={loading} id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>{i18next.t("Allaction:Print")}</DropdownToggle>
                      <DropdownMenu>
                        <Dropdown.Item>
                          <Link to={{
                            pathname: `/accounting/report/finance/wrapper-bank-deposit-report/${date}`,
                            query: { lastDate: date }
                          }}
                            className="text-black">
                            <p className="text-black">PDF</p>
                          </Link>
                        </Dropdown.Item>
                        <BankDepositExcelExports
                          all_bank={all_bank}
                          date={date}
                        />
                        {/* <BankDepositExcel
                    projectName={project_name}
                    firstDate={date}
                    allBankReport={all_bank}
                    summaryBalance={summary_balance}
                    summaryReportResidential={summary_report_residential}
                    summaryReportSupplier={summary_report_supplier}
                    summaryBankReport={summary_bank_report}
                    handleSummary={handleSummary}
                  /> */}
                      </DropdownMenu>
                    </Dropdown>}
                </div>
              </div>
              <BankDepositReportTable
                all_bank={all_bank}
                summary_report_residential={summary_report_residential}
                summary_report_supplier={summary_report_supplier}
                summary_bank_report={summary_bank_report}
                summary_balance={summary_balance}
                loading={loading}
                date={date}
                handleSummary={handleSummary}
                lastPage={true}
                lastPageNumber={_.chunk(all_bank, 12).length}
              />
              <SummaryAccountsReceivableAndPayableTable
                all_bank={all_bank}
                summary_report_residential={summary_report_residential}
                summary_report_supplier={summary_report_supplier}
                summary_bank_report={summary_bank_report}
                summary_balance={summary_balance}
                loading={loading}
                date={date}
                handleSummary={handleSummary}
              />
            </div>

          }
        </Translation>
      </WrapperContent>
    </Wrapper >
  );
};

export default BankDepositReport;