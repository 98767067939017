import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import { Link } from "react-router-dom";
import environment from "../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import _ from 'lodash'
import { fetchQuery } from "relay-runtime";
import i18next from 'i18next';
import { Translation } from "react-i18next";
import "./collectionLawFirm.scss";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import StatusLawDebt from './status';


const query = graphql`
    query collectionLawFrimCreateQuery {
        allContactCollectionLawFirm {
            totalCount
            edges{
                node{
                    id
                    refNumber
                    name
                    collectionLettersLastId
                    collectionLettersSumTotal
                    collectionLettersIssuedDate
                    collectionLettersDateCreate
                    collectionLettersCreator
                    collectionLettersFee
                    firstName lastName
                    collectionLettersDueDate
                }
            } 
        }
    }
`;

// const mutation = graphql`
// mutation collectionLawFrimCreateMutation ($input: CreateDebitManualInput!) {
//     createDebitManual (input: $input) {
//         ok
//         message
//     }
// }`;
class CollectionLawFrimCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            typOfContact: 'residential',
            startDate: new Date(),
            selectedContact: [],
            selectedContactID: [],
            loading: true,
            redirect: false,
            checkAll: false,
            dataTable: [],
            dataTableView: [],
            selectOverDue: "",
            selectOverAmount: "",
        };
    }

    componentDidMount = () => {
        fetchQuery(environment, query).then((res) => {
            if (res) {
                this.setState({ dataTable: res?.allContactCollectionLawFirm?.edges ?? [], dataTableView: res?.allContactCollectionLawFirm?.edges ?? [], loading: false })
            }
            else {
                this.setState({ loading: false })
            }
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    checkAll = () => {
        this.setState({
            checkAll: !this.state.checkAll
        }, () => {
            if (!this.state.checkAll) {
                this.setState({
                    selectedContact: [],
                    selectedContactID: [],
                })
            } else {
                fetchQuery(environment, query, {
                    typOfContact: this.state.typOfContact,
                    search: this.state.search
                }).then(data => {
                    let selectedContact = []
                    let selectedContactID = []
                    if (data.allContactDebit && data.allContactDebit.edges.length > 0) {
                        _.forEach(data.allContactDebit.edges, list => {
                            selectedContact.push(list)
                            selectedContactID.push(list.node.id)
                        })
                    }
                    this.setState({
                        selectedContact: selectedContact,
                        selectedContactID: selectedContactID,
                    })
                })
            }
        })
    }

    selectContact(param, detail) {
        let selectedContact = _.cloneDeep(this.state.selectedContact)
        let selectedContactID = _.cloneDeep(this.state.selectedContactID)
        if (!selectedContactID.includes(param)) {
            selectedContactID.push(param)
            selectedContact.push(detail)
        }
        this.setState({
            selectedContact: selectedContact,
            selectedContactID: selectedContactID
        });
    }

    onDelete = (param) => {
        let selectedContactID = _.cloneDeep(this.state.selectedContactID);
        let selectedContact = _.cloneDeep(this.state.selectedContact)
        let index_id = selectedContactID.indexOf(param);
        if (index_id !== -1) {
            selectedContactID.splice(index_id, 1)
        }
        let index_contact = _.findIndex(selectedContact, { 'node': { 'id': param } })
        if (index_contact !== -1) {
            selectedContact.splice(index_contact, 1)
        }
        let checkAll = selectedContact.length > 0 ? true : false
        this.setState({
            selectedContact: selectedContact,
            selectedContactID: selectedContactID,
            checkAll: checkAll
        });
    }

    clearList = () => {
        this.setState({
            selectedContact: [],
            selectedContactID: [],
            checkAll: false,
        })
    }

    handleSearch = (text) => {
        this.setState({
            search: text,
        })
    }

    checkAll = (dataa) => {
        this.setState({
            checkAll: !this.state.checkAll
        }, () => {
            if (!this.state.checkAll) {
                this.setState({
                    selectedContact: [],
                    selectedContactID: [],
                })
            } else {
                let selectedContact = []
                let selectedContactID = []
                if (dataa.length > 0) {
                    _.forEach(dataa, list => {
                        selectedContact.push(list)
                        selectedContactID.push(list.node.id)
                    })
                }
                this.setState({
                    selectedContact: selectedContact,
                    selectedContactID: selectedContactID,
                })
            }
        })
    }

    changeType = (type) => {
        if (type !== this.state.typOfContact) {
            this.setState({
                typOfContact: type,
                checkAll: false,
                selectedContact: [],
                selectedContactID: [],
            })
        }

    }

    findData = (sumTotal, dueDate, search) => {
        const currentDate = new Date(); // Current date  
        let datalist = _.cloneDeep(this.state.dataTable)
        let data = []

        data = datalist?.filter(ex => {
            let checkYearss = currentDate.getFullYear() - new Date(ex.node.collectionLettersDueDate).getFullYear()
            let checkMonths = currentDate.getMonth() - new Date(ex.node.collectionLettersDueDate).getMonth()
            let checkDate = (checkYearss * 12) + checkMonths
            let seacrhs = search ? search : ""


            if (
                parseInt(ex.node.collectionLettersSumTotal) >= sumTotal &&
                checkDate >= dueDate &&
                ex.node.name.toLowerCase().includes(seacrhs)
            ) {
                return true
            } else {
                return false
            }
        })
        return [...data];
    }

    onChange = (e) => {
        this.setState({ loading: true })
        let dataCloneE = _.cloneDeep(e)
        let { value, name } = dataCloneE.target

        let data = []
        let val = value ? parseFloat(value) : 0
        let sumTotal = this.state.selectOverAmount ? parseFloat(this.state.selectOverAmount) : 0
        let dueOver = this.state.selectOverDue ? parseFloat(this.state.selectOverDue) : 0
        let search = this.state.search ? this.state.search : ""

        if (name === "selectOverDue") {
            data = this.findData(sumTotal, val, search)
        }
        if (name === "selectOverAmount") {
            data = this.findData(val, dueOver, search)
        }
        if (name === "search") {
            data = this.findData(sumTotal, dueOver, value)
        }

        this.setState({ [name]: value, dataTableView: data, loading: false })
    }



    render() {
        // if (this.state.redirect) {
        //     return <Redirect to="/accounting/collection_letters/list/draft"/>;
        // }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                <Translation>
                {
                    t=> (       
                   <>
                    <AccountingTopMenuNavigation mini={true} />
                    <div className="container-fluid box" id="collection-law-firm">
                    <BackButtonIcon
                        LinkBack={this.props?.location?.state?.backLink ? this.props?.location?.state?.backLink : "/accounting/collection-law-firm/list/all"}
                        LinkText={i18next.t('collectionLawFirm:Create Debt Collection Letter')}
                    />
                   <div className="content-inner card" style={{ marginTop: '48px' }}>
                       <div className='card-body colorUse'>
                           <div className="row mt-3 arrowInSelect" style={{ marginTop: '24px', marginBottom: '48px' }}>
                               <div className='col-12 mb-5' style={{ display: 'flex' }}>
                                   <div className='w475 mr-2 '>
                                       <p>{t('collectionLawFirm:Search By Date')}</p>
                                       <select className='floatLeft form-control h48 selectArrow'
                                           name='selectOverDue'
                                           onChange={this.onChange}
                                           defaultValue={this.state.selectOverDue} defaultChecked={this.state.selectOverDue}>
                                           <option value={""}>{t('collectionLawFirm:All')}</option>
                                           <option value={"1"}>ค้างชำระเกิน 1 เดือน</option>
                                           <option value={"3"}>ค้างชำระเกิน 3 เดือน</option>
                                           <option value={"6"}>ค้างชำระเกิน 6 เดือน</option>
                                           <option value={"12"}>ค้างชำระเกิน 1 ปี</option>
                                           <option value={"24"}>ค้างชำระเกิน 2 ปี</option>
                                           <option value={"36"}>ค้างชำระเกิน 3 ปี</option>
                                           <option value={"48"}>ค้างชำระเกิน 4 ปี</option>
                                           <option value={"60"}>ค้างชำระเกิน 5 ปี</option>
                                       </select>
                                   </div>


                                   <div className='w475 mr-2 '>
                                       <p>{i18next.t('collectionLawFirm:Search By Amount')}</p>
                                       <select className='form-control h48 selectArrow'
                                           name='selectOverAmount'
                                           onChange={this.onChange}
                                           defaultValue={this.state.selectOverAmount} defaultChecked={this.state.selectOverAmount}>
                                           <option value={""}>{i18next.t('collectionLawFirm:All')}</option>
                                           <option value={"100000"}>ยอดค้างชำระเกิน 100,000 บาท</option>
                                           <option value={"300000"}>ยอดค้างชำระเกิน 300,000 บาท</option>
                                           <option value={"600000"}>ยอดค้างชำระเกิน 600,000 บาท</option>
                                       </select>
                                   </div>
                               </div>

                               <div className='col-12'>
                                   <div className='card'>
                                       <div className='card-body'>
                                           <div className='row'>
                                               <div className="wp-50 m-0 p-0">
                                                   <div className="col colSelect">
                                                       <div className="col-12">
                                                           <div className="row mt-3 mb-4" style={{ marginBottom: '48px' }}>
                                                               <div className="col-7 mb-3">
                                                                   <label style={{ position: 'relative', width: '100%' }}>
                                                                       <input placeholder={i18next.t("Allaction:Search")}
                                                                           type={"text"} name="search" className='h48 mr-1 ml-1 form-control' defaultValue={this.state.search}
                                                                           onKeyUp={(e) => this.onChange(e)} id='searchselectProject' />

                                                                       <div style={{ position: 'absolute', top: '6px', right: '0' }}>
                                                                           <button type="submit" className='btn'>
                                                                               <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} className="search-outline" alt="search" />
                                                                           </button>
                                                                       </div>
                                                                   </label>
                                                               </div>
                                                               <div className='col'>
                                                                   <label className='fs-16 fw-300' style={{ lineHeight: '48px' }}><input type={"checkbox"} className='ml-3 mt-3' name='selectAll' checked={this.state.checkAll} onChange={() => this.checkAll(this.state.dataTableView)} /> {i18next.t('collectionLawFirm:Select All')}</label>
                                                               </div>
                                                           </div>
                                                           <div className="row scrollbarDiv" style={{ zoom: '85%' }}>
                                                               <div className="col" >
                                                                   <div id="list-contact" >
                                                                       <table className="table table-hover mt-4 fw-300 fs-16">
                                                                           <thead className="blue-head">
                                                                               <tr>
                                                                                   <th style={{ width: '10%' }}>{i18next.t('collectionLawFirm:Code')}</th>
                                                                                   <th style={{ width: '10%' }}>{i18next.t('collectionLawFirm:Unit No.')}</th>
                                                                                   <th style={{ width: '40%' }}>{i18next.t('collectionLawFirm:Name')}</th>
                                                                                   <th style={{ width: '35%' }} className='text-right'>{i18next.t('collectionLawFirm:Total Due (Baht)')}</th>
                                                                                   <th style={{ width: '20%' }}>{i18next.t('collectionLawFirm:Status')}</th>
                                                                               </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                               {
                                                                                   this.state.dataTableView.length > 0 ?
                                                                                       this.state.dataTableView?.map((item, index) => {
                                                                                           return (
                                                                                               item &&
                                                                                               <tr key={"collectionLawFrimCreate:: " + index} className={`${this.state.selectedContact?.find(obj => obj.node.id === item.node.id) ? "bg-E6F2FE" : ""}`}
                                                                                                   id={item.node.id}
                                                                                                   onClick={() => this.selectContact(item.node.id, item)}
                                                                                               >
                                                                                                   <td>{item.node.refNumber}</td>
                                                                                                   <td>{item.node.name}</td>
                                                                                                   <td>{`${item.node.firstName} ${item.node.lastName}`}</td>
                                                                                                   <td className='text-right'>{item.node.collectionLettersSumTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                                                   <td><StatusLawDebt className="bg_FFBD2D pb-1 h32" status={t('collectionLawFirm:Legal Pending')} /></td>
                                                                                               </tr>
                                                                                           )
                                                                                       })
                                                                                       :
                                                                                       this.state.loading ?
                                                                                           <tr>
                                                                                               <td colSpan={5}><span><span className="spinner-border spinner-border-sm align-middle mr-2" /> {i18next.t("Allaction:Preparing information")}</span></td>
                                                                                           </tr>

                                                                                           :
                                                                                           <tr>
                                                                                               <td colSpan={5}>{i18next.t('collectionLawFirm:No Data')}</td>
                                                                                           </tr>
                                                                               }


                                                                           </tbody>
                                                                       </table>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>
                                               <div className="wp-50 m-0 p-0">
                                                   <div className="col colSelect">
                                                       <div className="col-12">
                                                           <div className='row pt-2'>
                                                               <div className="col-6"><label className='fs-16 fw-300' style={{ lineHeight: '72px' }}>{i18next.t('collectionLawFirm:Selected item')} {this.state.selectedContact.length > 0 && `(${this.state.selectedContact.length} รายการ)`}</label></div>
                                                           </div>
                                                           <div className="row scrollbarDiv" style={{ zoom: '85%' }}>
                                                               <div className="col">
                                                                   <div id="list-contact">
                                                                       <table className="table table-hover mt-4 fw-300" style={{ fontSize: '16px' }}>
                                                                           <thead className="blue-head">
                                                                               <tr>
                                                                                   <th style={{ width: '10%' }}>{i18next.t('collectionLawFirm:Code')}</th>
                                                                                   <th style={{ width: '10%' }}>{i18next.t('collectionLawFirm:Unit No.')}</th>
                                                                                   <th style={{ width: '35%' }}>{i18next.t('collectionLawFirm:Name')}</th>
                                                                                   <th style={{ width: '35%' }} className='text-right'>{i18next.t('collectionLawFirm:Total Due (Baht)')}</th>
                                                                                   <th style={{ width: '20%' }}>{i18next.t('collectionLawFirm:Status')}</th>
                                                                                   <th style={{ width: '5%' }} />
                                                                               </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                               {this.state.selectedContact.length > 0 &&
                                                                                   this.state.selectedContact.map((item, index) => {
                                                                                       return (
                                                                                           <tr key={"selectedContact::" + item.node.id + 'selected'}>
                                                                                               <td>{item.node.refNumber}</td>
                                                                                               <td>{item.node.name}</td>
                                                                                               <td>{`${item.node.firstName} ${item.node.lastName}`}</td>
                                                                                               <td className='text-right'>{item.node.collectionLettersSumTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                                               <td><StatusLawDebt className="bg_FFBD2D pb-1 h32" status={t('collectionLawFirm:Legal Pending')} /></td>
                                                                                               <td>
                                                                                                   <img className="cursor" src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="plus"
                                                                                                       onClick={() => this.onDelete(item.node.id)} />
                                                                                               </td>
                                                                                           </tr>
                                                                                       )
                                                                                   })}
                                                                           </tbody>
                                                                       </table>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="row card-body">
                           <div className='col-6'>
                               <span onClick={this.clearList} className="clear-list"><u>{t('collectionLawFirm:Clear')}</u></span>
                           </div>
                           <div className="col-6">
                               <div className='float-right colorUse'>
                                   <Link to={{ pathname: this.props?.location?.state?.backLink ? this.props?.location?.state?.backLink : "/accounting/collection-law-firm/list/all" }}>
                                       <button type="button" className="btn btn-outline-primary h48 w100">
                                           <span> {i18next.t('selectContact:cancel')}</span>
                                       </button>
                                   </Link>

                                   <Link
                                       to={{ pathname: "/accounting/collection-law-firm/createENotice", state: { backLink: window.location.pathname, checkList: this.state.selectedContact } }}>
                                       <button type="button"
                                           disabled={this.state.selectedContact.length < 1}
                                           className="btn btn-primary ml-2 h48 w100">
                                           {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                           {i18next.t('selectContact:next')}
                                       </button>
                                   </Link>

                               </div>

                           </div>
                           <div className="col-1" />
                       </div>

                   </div>
               </div>
                   </>
                   ) }
                </Translation>
                </WrapperContent>
            </Wrapper>


        )
    }
}

export default CollectionLawFrimCreate;
