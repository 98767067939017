/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type fineDetailQueryVariables = {|
  first?: ?number,
  last?: ?number,
  fineGroup?: ?string,
  search?: ?string,
|};
export type fineDetailQueryResponse = {|
  +allFine: ?{|
    +totalCount: ?number,
    +pageInfo: {|
      +hasNextPage: boolean
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +invoice: ?{|
          +id: string,
          +docNumber: string,
          +issuedDate: any,
          +dueDate: any,
          +contact: {|
            +id: string,
            +name: string,
            +firstName: string,
            +lastName: string,
            +refNumber: string,
            +typeOfContact: ContactTypeOfContact,
            +residential: ?{|
              +id: string,
              +name: string,
            |},
          |},
          +total: number,
          +transaction: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +total: ?number,
                +paid: ?number,
                +productAndService: ?{|
                  +productCode: string
                |},
              |}
            |}>
          |},
        |},
        +group: {|
          +id: string,
          +fineDate: ?any,
          +service: {|
            +id: string,
            +productCode: string,
            +name: string,
          |},
        |},
        +rate: number,
        +fine: number,
      |}
    |}>,
  |},
  +summaryFine: ?number,
  +summaryFineInvoiceTransaction: ?number,
|};
export type fineDetailQuery = {|
  variables: fineDetailQueryVariables,
  response: fineDetailQueryResponse,
|};
*/


/*
query fineDetailQuery(
  $first: Int
  $last: Int
  $fineGroup: ID
  $search: String
) {
  allFine(first: $first, last: $last, group_Id: $fineGroup, search: $search, fineList: true) {
    totalCount
    pageInfo {
      hasNextPage
    }
    edges {
      node {
        id
        invoice {
          id
          docNumber
          issuedDate
          dueDate
          contact {
            id
            name
            firstName
            lastName
            refNumber
            typeOfContact
            residential {
              id
              name
            }
          }
          total
          transaction {
            edges {
              node {
                total
                paid
                productAndService {
                  productCode
                  id
                }
                id
              }
            }
          }
        }
        group {
          id
          fineDate
          service {
            id
            productCode
            name
          }
        }
        rate
        fine
      }
    }
  }
  summaryFine(groupId: $fineGroup, search: $search)
  summaryFineInvoiceTransaction(groupId: $fineGroup, search: $search)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fineGroup"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v5 = [
  {
    "kind": "Literal",
    "name": "fineList",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "group_Id",
    "variableName": "fineGroup"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfContact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResidentialNode",
      "kind": "LinkedField",
      "name": "residential",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        (v12/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paid",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "FineGroupNode",
  "kind": "LinkedField",
  "name": "group",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fineDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNode",
      "kind": "LinkedField",
      "name": "service",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        (v16/*: any*/),
        (v12/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fine",
  "storageKey": null
},
v20 = [
  {
    "kind": "Variable",
    "name": "groupId",
    "variableName": "fineGroup"
  },
  (v4/*: any*/)
],
v21 = {
  "alias": null,
  "args": (v20/*: any*/),
  "kind": "ScalarField",
  "name": "summaryFine",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": (v20/*: any*/),
  "kind": "ScalarField",
  "name": "summaryFineInvoiceTransaction",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "fineDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "FineNodeConnection",
        "kind": "LinkedField",
        "name": "allFine",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FineNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FineNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceNode",
                    "kind": "LinkedField",
                    "name": "invoice",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionNodeConnection",
                        "kind": "LinkedField",
                        "name": "transaction",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TransactionNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TransactionNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductAndServiceNode",
                                    "kind": "LinkedField",
                                    "name": "productAndService",
                                    "plural": false,
                                    "selections": [
                                      (v16/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v21/*: any*/),
      (v22/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "fineDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "FineNodeConnection",
        "kind": "LinkedField",
        "name": "allFine",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FineNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FineNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceNode",
                    "kind": "LinkedField",
                    "name": "invoice",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionNodeConnection",
                        "kind": "LinkedField",
                        "name": "transaction",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TransactionNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TransactionNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductAndServiceNode",
                                    "kind": "LinkedField",
                                    "name": "productAndService",
                                    "plural": false,
                                    "selections": [
                                      (v16/*: any*/),
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v21/*: any*/),
      (v22/*: any*/)
    ]
  },
  "params": {
    "cacheID": "38e6ddccb8717e80f23737dd5b52010e",
    "id": null,
    "metadata": {},
    "name": "fineDetailQuery",
    "operationKind": "query",
    "text": "query fineDetailQuery(\n  $first: Int\n  $last: Int\n  $fineGroup: ID\n  $search: String\n) {\n  allFine(first: $first, last: $last, group_Id: $fineGroup, search: $search, fineList: true) {\n    totalCount\n    pageInfo {\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        invoice {\n          id\n          docNumber\n          issuedDate\n          dueDate\n          contact {\n            id\n            name\n            firstName\n            lastName\n            refNumber\n            typeOfContact\n            residential {\n              id\n              name\n            }\n          }\n          total\n          transaction {\n            edges {\n              node {\n                total\n                paid\n                productAndService {\n                  productCode\n                  id\n                }\n                id\n              }\n            }\n          }\n        }\n        group {\n          id\n          fineDate\n          service {\n            id\n            productCode\n            name\n          }\n        }\n        rate\n        fine\n      }\n    }\n  }\n  summaryFine(groupId: $fineGroup, search: $search)\n  summaryFineInvoiceTransaction(groupId: $fineGroup, search: $search)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2a5c10ccf9ef8614deb093ec7159e338';

module.exports = node;
