import React, { Component } from 'react';
import { QueryRenderer } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { Translation } from "react-i18next";
import EmergencyListTable from "./emergencyListTable"
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import Wrapper from "../../components/wrapper";
import Loading from '../../libs/loading';
import BackButtonIcon from '../../components/BackBtn/indexBack';

const query = graphql`
query emergencyListQuery {
        emergencyCallViewer{
            allEmergencyCall{
                edges{
                    node{
                        id
                        name
                        callOutContactNumber
                        contactNumber
                        group{
                            id
                            name
                        }
                    }
                }
                 
            }
        }
        emergencyCallGroupViewer{
            allEmergencyCallGroup{
                edges{
                    node{
                        id
                        name
                    }
                }
                totalCount
            }

        }
    }
`; 

class EmergencyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reQuery: false,
            modal: false,
        };
        this.refreshQuery = this.refreshQuery.bind(this);
    }

    refreshQuery() {
        this.setState({ reQuery: !this.state.reQuery })
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <ProjectTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box" >
                                <BackButtonIcon
                                    addLinkChk={true}
                                    addLinkButton={"/project/emergency/group"}
                                    addLinkText={t("claimRequst:Add phone directory")}
                                    LinkBack={"/project"}
                                    LinkText={t('emergency:emergency')}
                                /> 
                                
                                <QueryRenderer
                                    environment={environment}
                                    query={query}
                                    variables={{ reQuery: this.state.reQuery }}
                                    render={({ error, props }) => {

                                        //props.emergencyCallViewer.allEmergencyCall.edges
                                        if (props) {
                                            return <EmergencyListTable 
                                                emergency_list={props.emergencyCallViewer.allEmergencyCall.edges} 
                                                groupPhone={props.emergencyCallGroupViewer.allEmergencyCallGroup.edges} 
                                                refreshQuery={this.refreshQuery} />;
                                        } else {
                                            return <Loading />
                                        }
                                    }}
                                />
                            </div>
                        }
                    </Translation>

                </WrapperContent>
            </Wrapper>
        )
    }
}

export default EmergencyList;
