import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import "../styles/receive.scss"
import "../styles/modify_print.scss"
import _ from "lodash";
import getNameResidential from "../../libs/getNameResidential";
import i18n from 'i18next'
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';

const ThaiBaht = require('thai-baht-text');

class AcceptCreditNote extends Component {

    constructor(props) {
        super(props);

        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];

        this.state = {
            bangkokCode: bangkokCode,
            languages: "th"
        };
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
    }

    componentDidMount() {
        i18n.changeLanguage(this.props.languages);
        this.setState({ languages: this.props.query.userConfig.languages || 'th' })
        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + signature);
            $('tbody tr:last-child td').css({ paddingBottom: diff });
        }, 200);
    }

    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {
        let languages = this.state.languages
        if (type === "district" && this.isBangkok(code)) {
            return languages == "th" ? "แขวง" : "District "
        }
        else if (type === "city" && this.isBangkok(code)) {
            return languages == "th" ? "เขต" : "County "
        }
        else if (type === "province" && this.isBangkok(code)) {
            return languages == "th" ? "" : ""
        }
        else if (type === "province") {
            return languages == "th" ? "จ." : "Province "
        }
        else if (type === "district") {
            return languages == "th" ? "ต." : "District "
        }
        else if (type === "city") {
            return languages == "th" ? "อ." : "County "
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    [1, 2].map((count_receive, index) => {
                        return (
                            <div className="print-page-a4" id="receive" key={index}>
                                <div className="subpage">
                                    <div className="head">
                                        <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                                            <div style={{ minHeight: 100, minWidth: 100 }}>
                                                <img src={this.props.query.selfProject.logo} alt="silverman" />
                                            </div>
                                            <div>
                                                <div style={{ minWidth: '115%' }}>
                                                    <strong>{this.state.languages === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name) : this.props.query.selfProject.name}</strong>
                                                </div>
                                                <div>{this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}</div>
                                                <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                                            </div>

                                        </div>

                                        <div className="document-name">
                                            <React.Fragment>
                                                <div className="title">ใบรับลดหนี้</div>
                                                <div className="title">Accept Credit Note</div>
                                            </React.Fragment>

                                            {
                                                count_receive === 1 ?
                                                    <div className="title small">(ต้นฉบับ/Original)</div>
                                                    :
                                                    <div className="title small">(สำเนา/Copy)</div>
                                            }
                                        </div>
                                    </div>

                                    <div className="detail">
                                        <div className="customer-detail">
                                            <strong>รหัสลูกค้า/ID:</strong> {this.props.query.acceptCreditNote.contact.refNumber}<br />
                                            <React.Fragment>
                                                <strong>ชื่อลูกค้า/Customer:</strong>
                                                {this.props.query.acceptCreditNote.contact.typeOfContact === "RESIDENTIAL" ?
                                                    getNameResidential(this.props.query.acceptCreditNote.contact.firstName, this.props.query.acceptCreditNote.contact.lastName)
                                                    : this.props.query.acceptCreditNote.contact.name}{" "}
                                                <br />
                                            </React.Fragment>

                                            <strong>ที่อยู่/Address:</strong>

                                            <div className="customer-detail address-space">
                                                {this.state.languages === "en" ?
                                                    this.props.query.acceptCreditNote.contact.registeredAddress.replace('เลขที่', 'No.').replace('ซอย', 'Soi').replace('หมู่ที่', 'Moo').replace('ถนน', 'Road') + " " :
                                                    this.props.query.acceptCreditNote.contact.registeredAddress + " "
                                                }
                                                {this.props.query.acceptCreditNote.contact.registeredDistrict && this.getPrefix("district", this.props.query.acceptCreditNote.contact.registeredPostalCode) + this.props.query.acceptCreditNote.contact.registeredDistrict + " "}
                                                {this.props.query.acceptCreditNote.contact.registeredCity && this.getPrefix("city", this.props.query.acceptCreditNote.contact.registeredPostalCode) + this.props.query.acceptCreditNote.contact.registeredCity + " "}
                                                {this.props.query.acceptCreditNote.contact.registeredProvince && this.getPrefix("province", this.props.query.acceptCreditNote.contact.registeredPostalCode) + this.props.query.acceptCreditNote.contact.registeredProvince + " "}
                                                {this.props.query.acceptCreditNote.contact.registeredPostalCode + " "}

                                                {
                                                    (this.props.query.acceptCreditNote.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.query.acceptCreditNote.contact.typeOfSupplier === "COMPANY") &&
                                                    <React.Fragment>
                                                        {
                                                            this.props.query.acceptCreditNote.contact.typeOfCompany === "HEADQUARTERS" ?
                                                                "สำนักงานใหญ่"
                                                                : this.props.query.acceptCreditNote.contact.typeOfCompany === "BRANCH" &&
                                                                "สาขา " + this.props.query.acceptCreditNote.contact.nameBranch
                                                        }
                                                    </React.Fragment>
                                                }
                                            </div>
                                            <br />
                                            <strong>เลขผู้เสียภาษี/Tax ID:</strong>
                                            {this.props.query.acceptCreditNote.contact.taxIdNumber ? this.props.query.acceptCreditNote.contact.taxIdNumber : ''}
                                            <br />
                                        </div>
                                        <div className="document-detail">
                                            <strong>เลขที่/No:</strong> {this.props.query.acceptCreditNote.docNumber} <br />
                                            <strong>วันที่/Date:</strong> {format(this.props.query.acceptCreditNote.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                                            <strong>อ้างถึง:</strong> {this.props.query.acceptCreditNote.purchaseRecord.purchaseRecordGroup.docNumber}<br />
                                            {this.props.query.acceptCreditNote.contact.typeOfContact === "RESIDENTIAL" &&
                                                <React.Fragment>
                                                    <strong>{this.props.query.selfProject.typeOfProject === "CONDOMINIUM" ? "เลขที่ห้องชุด/Room No.:" : "บ้านเลขที่/House No."}</strong> {this.props.query.acceptCreditNote.contact.name} &nbsp;
                                                    <br />

                                                    <strong>พื้นที่/Space:</strong> {numberWithComma(this.props.query.acceptCreditNote.contact.residential.size)} {this.props.query.selfProject.typeOfProject === "CONDOMINIUM" ? "ตร.ม." : "ตร.ว."}
                                                    <br />
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>

                                    <div className="invoice-body">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width={50} className="text-center">
                                                        ลำดับ <br />
                                                        No.
                                                    </th>
                                                    <th width={60} className="text-center">
                                                        รหัส <br />
                                                        Code
                                                    </th>

                                                    <th className="text-center">
                                                        รายละเอียด <br />
                                                        Description
                                                    </th>
                                                    <th width={110} className="text-center">
                                                        จำนวนหน่วย <br />
                                                        Unit
                                                    </th>
                                                    <th width={110} className="text-center">
                                                        ราคาต่อหน่วย <br />
                                                        Unit Price
                                                    </th>

                                                    <th width={100} className="text-center">
                                                        จำนวนเงิน <br />
                                                        Amount
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr key={this.props.query.acceptCreditNote.id}>
                                                    {
                                                        count_receive === 1 ?
                                                            <td className="text-center">{index + 1}</td>
                                                            :
                                                            <td className="text-center">{index}</td>
                                                    }

                                                    <td className="text-center">{this.props.query.acceptCreditNote.purchaseRecord.productAndService?.productCode ? this.props.query.acceptCreditNote.purchaseRecord.productAndService.productCode : this.props.query.acceptCreditNote.purchaseRecord.chartOfAccount.chartOfAccountCode}</td>
                                                    <td>{this.props.query.acceptCreditNote.description}</td>
                                                    <td className="text-right">1.00</td>
                                                    <td className="text-right">{numberWithComma(this.props.query.acceptCreditNote.price)}</td>
                                                    <td className="text-right">{numberWithComma(this.props.query.acceptCreditNote.price)}</td>
                                                </tr>

                                            </tbody>
                                            <tfoot className="font-weight-bold">
                                                <tr>
                                                    <td colSpan={3} rowSpan={4} />
                                                    <td colSpan={2}>
                                                        จำนวนเงิน/Amount
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(this.props.query.acceptCreditNote.price, 0)}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={2}>
                                                        ภาษีมูลค่าเพิ่ม/VAT
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(parseFloat(this.props.query.acceptCreditNote.vatAmount), 0)}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={2}>
                                                        มูลค่าเอกสารเดิม
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(this.props.query.acceptCreditNote.purchaseRecord.total, 0)}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={2}>
                                                        มูลค่าที่ถูกต้อง
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(parseFloat(this.props.query.acceptCreditNote.purchaseRecord.total) - parseFloat(this.props.query.acceptCreditNote.total), 0)}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={3}>
                                                        <div className="row">
                                                            <div className="col-1">
                                                                รวม/Total
                                                            </div>
                                                            <div className="col-10 text-center">
                                                                {i18n.languages[0] === 'th' ? 
                                                                (ThaiBaht(this.props.query.acceptCreditNote.total)):
                                                                (ConvertNumberToTextEng(this.props.query.acceptCreditNote.total))}
                                                            </div>
                                                            <div className="col-1" />
                                                        </div>
                                                    </td>

                                                    <td colSpan={2}>
                                                        จำนวนเงินรวมทั้งสิ้น/Grand Total
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(this.props.query.acceptCreditNote.total, 0)}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <div className="remark">
                                            <span className="font-weight-bold">หมายเหตุ/Remarks:</span> <br />
                                        </div>
                                        <div className="remark">
                                            {this.props.query.acceptCreditNote.remark}<br />
                                        </div>

                                    </div>
                                    <div className="footer">
                                        <div className="d-flex justify-content-end">
                                            <div className="left mt-5 text-center">
                                                .........................................................<br />
                                                <span className="font-weight-bold">ผู้จัดทำ / Organizer</span>
                                            </div>
                                            <div className="center mt-5 text-center">
                                                .........................................................<br />
                                                <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span>
                                            </div>
                                            <div className="right mt-5 text-center">
                                                .........................................................<br />
                                                <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </React.Fragment>
        );
    }
}

export default AcceptCreditNote;
