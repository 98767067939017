import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import Wrapper from '../../../components/wrapper';
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import WrapperContent from '../../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from '../../accountingTopMenuNavigation';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/asset.scss';
import { Dropdown  } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { fetchQuery , commitMutation} from "relay-runtime";
import environment from '../../../env/environment';
import _ from 'lodash'
import DatePickerNoti from '../../finance/depositInterest/datePickerNoti';
import SelectContactDropDown from '../../income/selectContactDropDown';
import Swal from 'sweetalert2';
import DeleteAssetImage from '../deleteAssetImage';
import i18n from 'i18next';
import {format, setDate, setMonth, lastDayOfMonth} from "date-fns";
import DeleteAsset from '../deleteAsset';
import {Redirect} from 'react-router';
import numberWithCommas from '../../../libs/numberWithComma';
import Loading from "../../../libs/loading";
import apiSing from '../../../api/sing'
import { decode, encode } from 'base-64';
import jwtDecode from 'jwt-decode'
import ApproveAsset from '../approveAsset';
import UnapproveAsset from '../unapproveAsset';
import i18next from 'i18next';

const mutation = graphql`
    mutation assetDetailMutation($input: CreateAssetInput!) {
        createAsset(input: $input) {
            ok
        }
    }
`;


const query = graphql`
    query assetDetailQuery($id: ID!,$date:Date) {
        asset(id: $id) {
            id
            type
            docNumber
            nounClassifier
            importStatus
            chartOfAccountType {
                chartOfAccountCode
                name
                id
            }
            chartOfAccountDepreciation {
                id
                chartOfAccountCode
                name
            }
            chartOfAccountDepreciationCumulative {
                id
                chartOfAccountCode
                name
            }
            name
            description
            storage
            remark
            internalAssetCode
            seller{
                id name
            }
            sellerOther
            calDepreciation
            depreciationRatePercent
            depreciationRate
            lifeTimeYear
            purchaseDate
            saleDate
            calDepreciationDate
            costPrice
            carcassPrice
            calSchedule
            historicalValue
            historicalAmount
            saleStatus
            refNumber
            assetDepreciate(date_Lte:$date) {
                edges {
                    node {
                        id
                        depreciatePrice
                    }
                }
            }
            deteriorateAsset {
                edges {
                    node {
                        id approver total 
                        chartOfAccountBook {
                            name
                        }
                    }
                }
            }
        }
        allAssetImage(asset_Id: $id){
            edges{
                node{
                    id
                    source
                }
            }
        }
        checkApproveAsset(assetId: $id)
    }
`;

class AssetDetail extends Component {
    constructor(props){
        super(props)
        this.state = {
            qrAsset: {},
            approveStatus: '',
            approveHistory: '',
            loadingData : false,
            loading : false,
            redirect : false,
            last_date : lastDayOfMonth(setDate(setMonth(new Date(), new Date().getMonth() - 1), 1)),
            sum_asset_depreciate : 0,
            asset: {
                id: '',
                type: 'LAND',
                nounClassifier: '',
                chartOfAccountDepreciation: {id: ''},
                chartOfAccountType: {id: ''},
                chartOfAccountDepreciationCumulative: {id: ''},

                saleStatus: '',

                docNumber: '',
                name: '',
                description: '',
                storage: '',
                seller: {id: '',},
                sellerOther: "",
                remark:"",
                refNumber : '',

                calDepreciation: false,
                formula: 'STRAIGHT_LINE',
                depreciationRatePercent: 5,
                depreciationRate: 0.05,
                lifeTimeYear: 20,
                purchaseDate: new Date(),
                calDepreciationDate: new Date(),
                costPrice: 0,
                historicalValue: 0,
                carcassPrice: 1,
                calSchedule: "MONTHLY",
                historicalAmount: 0,
                saleDate: null,
                internalAssetCode : '',
                nounClassifier: '',
            },
            upload_image_list: [],
            allAssetImage: {
                edges: []
            },
            permissionEdit : _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_asset_edit'}) ?
                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_asset_edit' }) : false,
            token: jwtDecode(window.localStorage.getItem('token'))
        }
        this.approveStatus = this.approveStatus.bind(this);
    }

    componentDidMount(){
        this.fetchData()
       

    }

    readUploadedFile = inputFile => {
        let file_reader = new FileReader();

        return new Promise((resolve, reject) => {
            file_reader.onerror = () => {
                file_reader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            file_reader.onload = () => {
                let img = new Image();
                img.onload = function () {
                    if (img.width >= 2000 && img.height >= 2000) {
                        Swal.fire(i18n.t("complaint:Image size is too big"), i18n.t("complaint:Please select a new image"), 'warning');
                        resolve(false)
                    } else {
                        resolve(true)
                    }

                };
                img.src = file_reader.result;
            };
            if (inputFile) {
                file_reader.readAsDataURL(inputFile);
            }
        });
    };

    fetchData = async() => {
        this.setState({loadingData : true})
        await fetchQuery(environment , query , {
            id : this.props.match.params.id,
            date: format(this.state.last_date, 'YYYY-MM-DD')
        }).then(response => {
            let data = _.cloneDeep(response);
            _.set(data.asset, 'purchaseDate', data.asset.purchaseDate ? new Date(data.asset.purchaseDate) : null);
            _.set(data.asset, 'saleStatus', _.lowerCase(data.asset.saleStatus));
            _.set(data.asset, 'calDepreciationDate', data.asset.calDepreciationDate ? new Date(data.asset.calDepreciationDate) : null);
            if (data.asset.saleDate){
                _.set(data.asset, 'saleDate', new Date(data.asset.saleDate));
            }

            _.set(data.asset, 'nounClassifier',data.asset.nounClassifier);
            // _.set(data.asset, 'sellerOther', _.get(data.asset, "sellerOther"));

            if (!_.get(data.asset, "seller") && !_.get(data.asset, "sellerOther")) {
                _.set(data.asset, 'seller', {id: "on_contact"});
            } else if (!_.get(data.asset, "seller") && _.get(data.asset, "sellerOther")) {
                _.set(data.asset, 'seller', {id: "other_select"});
            }
            let sum_asset_depreciate = data.asset.historicalAmount; //ราคารวมค่าเสื่อมสะสม + ค่าเสื่อมยกมา
            sum_asset_depreciate += _.sumBy(data.asset.assetDepreciate.edges,'node.depreciatePrice')

            let checkApproveAsset = JSON.parse(data.checkApproveAsset)
            let approveHistory = this.props.location?.state?.data.node.approveAsset;

            this.setState({
                asset: data.asset,
                allAssetImage: data.allAssetImage,
                sum_asset_depreciate : sum_asset_depreciate,
                checkApproveAsset: checkApproveAsset,
                approveHistory: approveHistory,
            },() => {
                // if(this.state.token?.site?.includes('sklasmanagement')){
                    this.approveStatus()
                // }
                this.setState({loadingData:false})
            });

        }).catch(err => {
            alert(err)
        })

    }

    handleChange = (e) => {
        let event = _.cloneDeep(e)
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                event.target.value
            );
        })
    }

    deleteAssetImage = (news_image_id, index) => {
        this.setState({
            loading : true
        })
        if (news_image_id) {
            // mutation delete
            let variables = {
                input: {
                    clientMutationId: news_image_id,
                }
            };
            DeleteAssetImage(variables, () => 
                Swal.fire(i18next.t("Allaction:Deleted Successfully"), '', 'success').then(() => {
                this.setState({
                    loading: false,
                },() => {
                    window.location.reload()
                });
            }), () => Swal.fire(i18next.t("Allaction:Deleted Unsuccessful"), '', 'error')).then(() => {
                this.setState({
                    loading : false
                })
            });

        } else {
            // delete upload_image_list
            let upload_image_list = [...this.state.upload_image_list];
            upload_image_list.splice(index, 1);
            this.setState({upload_image_list: upload_image_list , loading:false});
        }
    }

    handleInputImage = async(e) => {

        let file = e.currentTarget.files[0];
        // validate image
        let fileValidated = await this.readUploadedFile(file);
        if (!fileValidated) {
            return
        }

        let fields = ("upload_image_list[" + this.state.upload_image_list.length + "]").split(':');

        // eslint-disable-next-line
        for (let index in fields) {
            if (index % 2 !== 0) {
                //field preview
                file = URL.createObjectURL(file)
            }

            this.handleChange({
                target: {
                    type: 'file',
                    name: fields[index],
                    value: file,
                }
            });
        }
    }

    onSubmit = () => {
        this.setState({
            loading : true
        })
        let variables = {
            input: {
                asset: JSON.stringify(this.state.asset),
            }
        };
        let uploadables = Object.assign(this.state.upload_image_list);


        commitMutation(
            environment,
            {
                mutation,
                variables,
                uploadables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createAsset.ok) {
                        Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                            this.setState({
                                loading: false
                            },() => {
                                window.location.reload()
                            });
                        });
                    } else {
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                },
            },
        )
    }

    deleteAsset() {
        this.setState({loading: true});
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let variables = {
                    input: {
                        id: this.state.asset.id,
                    }
                };
                DeleteAsset(variables,
                    () => Swal.fire(i18next.t("Allaction:Canceled Successfully"), '', 'success').then(() => {
                        this.setState({
                            loading: false,
                            redirect:true
                        });
                    })
                    , () => Swal.fire(i18next.t("Allaction:Canceled Successfully"), '', 'error').then(() => {
                        this.setState({loading: false});
                    })
                );
            }else{
                this.setState({loading: false});
            }
        });
    }

    approveStatus() {
        let id = decode(this.state?.asset?.id).split(":")
        let idQR = (id[1]+this.state?.asset?.docNumber).toString() 
        let qr = encode(idQR) 
        apiSing.getQR(qr).then((res) => { 
            this.setState({ qrAsset: res.data.asset[0] })
        })
    }
    

    approveAsset () {
        this.setState({loading: true});
        let title = `${i18next.t("assetDetail:Are you sure you want to inspect the assets")}?`;
        let haveBeforeApprove = "";
        

        if(this.state.checkApproveAsset.approve_status) {
            let lastTimeApproveHistory = this.state.approveHistory.edges.length - 1
            if(this.state.approveHistory.edges[lastTimeApproveHistory].node.approveStatus !== 'NOT_APPROVE') {
                // check ว่าถ้า ครั้งล่าสุดเป็นการยกเลิก จะไม่โชว์ wording ใน haveBeforeApprove แต่ยังคงโชว์ คุณแน่ใจหรือไม่ที่จะตรวจสอบสินทรัพย์ซ้ำ 
                haveBeforeApprove = `<span style="color: red;">${i18next.t("assetDetail:Because this item has already been inspected for assets")} </br> 
                ${i18next.t("assetDetail:Date")} ${format(this.state.checkApproveAsset.approve_date, 'DD/MM/YYYY')} ${i18next.t("assetDetail:By")} ${this.state.checkApproveAsset.approver}
                </span>`;
            }
            title = i18next.t("assetDetail:Are you sure you want to inspect the assets again")
        }

        Swal.fire({
                title: title,
                html: haveBeforeApprove,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: i18next.t("Allaction:Yes"),
                cancelButtonText: i18next.t("Allaction:cancel"),
                reverseButtons: true
              }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: i18next.t("manageVisitor:Comments"),
                        html: `<input type="text" class="swal2-input" id="assetApproveRemark">`,
                        showCancelButton: true,
                        confirmButtonText: i18next.t("Allaction:Yes"),
                        cancelButtonText: i18next.t("Allaction:cancel"),
                        reverseButtons: true,
                        preConfirm: () => {
                            let assetApproveRemark = Swal.getPopup().querySelector('#assetApproveRemark').value
                            return { assetApproveRemark: assetApproveRemark}
                          }
                    }).then((result) => {
                        if (result.value) {
                            let variables = {
                                input: {
                                    assetId: this.state.asset.id,
                                    remark: `${result.value.assetApproveRemark}`
                                }
                            };
                                ApproveAsset(variables,
                                    () => Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                                        this.setState({
                                            loading: false,
                                            redirect:true
                                        })
                                        })
                                    , () => Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error').then(() => {
                                        this.setState({loading: false});
                                    })
                                );
                        }else{
                            this.setState({loading: false});
                        }
                      })
                } else{
                    this.setState({loading: false});
                }
        });            
    }

    unapproveAsset() {
        let haveBeforeApprove = 
        `<span style="color: red;">${i18next.t("assetDetail:Because this item has already been inspected for assets")} </br> 
        ${i18next.t("assetDetail:Date")} ${format(this.state.checkApproveAsset.approve_date,'DD/MM/YYYY')} ${i18next.t("assetDetail:By")} ${this.state.checkApproveAsset.approver}
        </span>`;

        this.setState({loading: true});
        Swal.fire({
            title: `${i18next.t("assetDetail:Are you sure you want to cancel the asset inspection")}?`,
            html: haveBeforeApprove,
            type: 'warning',
            showCancelButton: true,
            // confirmButtonColor: '#3085d6',
            // cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel"),
            reverseButtons: true
          }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: i18next.t("manageVisitor:Comments"),
                    html: `<input type="text" class="swal2-input" id="assetUnapproveRemark">`,
                    showCancelButton: true,
                    confirmButtonText: i18next.t("Allaction:Yes"),
                    cancelButtonText: i18next.t("Allaction:cancel"),
                    reverseButtons: true,
                    preConfirm: () => {
                        let assetUnapproveRemark = Swal.getPopup().querySelector('#assetUnapproveRemark').value
                        return { assetUnapproveRemark: assetUnapproveRemark}
                      }
                }).then((result) => {
                    if (result.value) {
                        let variables = {
                            input: {
                                assetId: this.state.asset.id,
                                remark: `${result.value.assetUnapproveRemark}`,
                            }
                        };
                        UnapproveAsset(variables,
                            () => Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                                this.setState({
                                    loading: false,
                                    redirect:true
                                })
                                })
                            , () => Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error').then(() => {
                                this.setState({loading: false});
                            })
                        );
                    }else{
                        this.setState({loading: false});
                    }
                })
            }else{
                this.setState({loading: false});
            }
        })              
        
    }

    statusName(status) {
        if(status === "APPROVE") {
            return i18next.t("assetDetail:Inspected");
        } else {
            return i18next.t("assetDetail:Canceled");
        }
    }

    render() {
        // ถ้าเข้าหน้านี้จาก รายงาน asset หรือ รายงานตรวจสอบ เวลา redirect จะย้อนกลับไปหน้านั้นๆ
        if(this.state.redirect && this.props.location.state.approveReport){
            return <Redirect to="/accounting/report/asset/checking"/>;
        } else if(this.state.redirect){
            return <Redirect to="/accounting/asset/all"/>;
        }

        let approve_status = this.state.qrAsset.approve_status ? i18next.t("assetDetail:Inspected") : i18next.t("assetDetail:Uninspected");
        let approveHistory = this.state.approveHistory?.edges;
        return (
           <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className='container-fluid box claim' id="asset-style">
                        <div className="row justify-content-between d-flex align-items-center">
                            <div className="col-12">
                                <Translation>
                                    {
                                        t => 
                                        <h4 className="mb-4 form-inline">
                                            {/* ถ้าเข้าหน้านี้จาก รายงาน asset หรือ รายงานตรวจสอบ เวลากดย้อนกลับ จะย้อนกลับไปหน้านั้นๆ */}
                                            <Link to={`${this.props.location.state.approveReport? "/accounting/report/asset/checking" : "/accounting/asset/all"}`}>
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back-new.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <div className="mt-1">{this.props.match.params.docNumber}</div>
                                        </h4>
                                    }
                                </Translation>
                            </div>
                        </div>
                        <Translation>
                            { t=> 
                                <div className='content-inner pr-4'>
                                    
                                    {this.state.loadingData ?
                                    <Loading/>
                                    :
                                    <React.Fragment>
                                        <div className='row'>
                                            <div className='col-6 form-inline d-flex align-items-center'>
                                                <h5 className='align-self-center'>{t("assetDetail:Status")}: </h5>
                                                <div className={_.lowerCase(this.state.asset.saleStatus) === 'active' ? 'status-available text-center ml-2': 'status-non text-center ml-2'}>
                                                    <span className='text-center'>{t('asset:'+ _.lowerCase(this.state.asset.saleStatus))}</span>
                                                </div>
                                            </div>
                                            <div className='col-6 d-flex justify-content-end'>

                                                {
                                                //    (this.state.token?.site?.includes('sklasmanagement') || this.state.token?.site?.includes('silverman')) && 
                                                   
                                                   <React.Fragment>   
                                                        {this.state.qrAsset.approve_status !== undefined && 
                                                            (this.state.qrAsset.approve_status ?
                                                                <Dropdown >
                                                                    <DropdownToggle id="statusApprove" className="mr-2">
                                                                        <Translation>{t => <span>{approve_status}</span>}</Translation>
                                                                    </DropdownToggle> 
                                                                    <DropdownMenu>
                                                                        <Dropdown.Item className="dropdownCancel">
                                                                            <div className="dropdown" onClick={()=>this.approveAsset()}>
                                                                                <p className="dropdown dropdownItemApproveAsset">{t("assetDetail:Reinspect")}</p>
                                                                            </div>                                                                        
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item className="dropdownCancel">
                                                                            <div className="dropdown" onClick={()=>this.unapproveAsset()} >
                                                                                <p className="dropdown dropdownItemApproveAsset">{t("assetDetail:Cancel asset inspection")}</p>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    </DropdownMenu>                                                            
                                                                </Dropdown>
                                                                :
                                                                <Dropdown>
                                                                    <DropdownToggle id="statusUnApprove" className="mr-2">
                                                                        <Translation>{t => <span>{approve_status}</span>}</Translation>
                                                                    </DropdownToggle>  
                                                                    <DropdownMenu>
                                                                        <Dropdown.Item>
                                                                            <div className="dropdown" onClick={()=>this.approveAsset()}>
                                                                                <p className="dropdown dropdownItemApproveAsset">{t("assetDetail:Asset inspection")}</p>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    </DropdownMenu>                                                      
                                                                </Dropdown>
                                                            )
                                                        }
                                                        <Link to={"/accounting/asset/assetPrintQrCode/" + this.state.asset.docNumber} target={"_blank"}>
                                                            <button className="btn btn-primary mr-2" disabled={this.state.loading}>
                                                                <Translation>
                                                                    {
                                                                        t => <span>{t('Allaction:printQr asset')}</span>
                                                                    }
                                                                </Translation>
                                                            </button>
                                                        </Link>
                                                   </React.Fragment>
                                                }

                                                {this.state.asset.importStatus && 
                                                <Dropdown >
                                                    <DropdownToggle id="dropdown-item-button" bsPrefix = 'dropdown-export dropdown-toggle'>
                                                    {t("assetDetail:Distribution")}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {_.lowerCase(this.state.asset.saleStatus) === 'active' ?
                                                         this.state.permissionEdit && 
                                                         <React.Fragment>
                                                            <Dropdown.Item>
                                                                <Link to={"/accounting/asset/create/sold/"+ this.props.match.params.id} 
                                                                className="dropdown">
                                                                    <p className="dropdown">{t("assetDetail:Selling")}</p>
                                                                </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item>
                                                                <Link to={"/accounting/asset/create/deteriorate/"+ this.props.match.params.id}
                                                                className="dropdown">
                                                                    <p className="dropdown">{t("assetDetail:Deteriorated / Unusable")}</p>
                                                                </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item>
                                                                <Link to={"/accounting/asset/create/disappear/"+ this.props.match.params.id} 
                                                                className="dropdown">
                                                                    <p className="dropdown">{t("assetDetail:Lost")}</p>
                                                                </Link>
                                                            </Dropdown.Item>
                                                        </React.Fragment>
                                                        :
                                                        
                                                        <Dropdown.Item>
                                                            <Link to={"/accounting/asset/view/"+_.lowerCase(this.state.asset.saleStatus)+"/"+ this.props.match.params.id} 
                                                            className="dropdown">
                                                                <p className="dropdown">{t('asset:'+ _.lowerCase(this.state.asset.saleStatus))}</p>
                                                            </Link>
                                                        </Dropdown.Item>
                                                        
                                                        }
                                                    </DropdownMenu>
                                                </Dropdown>
                                                }
                                            </div>
                                        </div>
                                        <form id="asset" onSubmit={this.onSubmit}>
                                            <div className='row mt-44'>
                                                <div className='col-4'>
                                                    <h6>{t("assetDetail:Category")}</h6>
                                                    <input type='text' value={t('assetSetting:'+_.lowerCase(this.state.asset.type).replace(' ','_'))} 
                                                        readOnly={true} className='form-control form-input'/>
                                                </div>
                                                <div className='col-2'>
                                                    <h6>{t("assetDetail:Unit")}</h6>
                                                    <input type='text' value={this.state.asset.nounClassifier} name='asset.nounClassifier'
                                                        onChange={this.handleChange}
                                                        className='form-control form-input'
                                                        placeholder="เช่น ตัว, อัน, คัน, เครื่อง"
                                                        disabled={_.lowerCase(this.state.asset.saleStatus) !== 'active' || !this.state.permissionEdit}
                                                        
                                                        />
                                                </div>
                                                <div className='col-6' />
                                            </div>
                                            <div className='row mt-24'>
                                                <div className='col-4'>
                                                    <h6>{t("assetDetail:Asset account number")}</h6>
                                                    <input type='text' readOnly={true} 
                                                        value={this.state.asset.chartOfAccountType.chartOfAccountCode +' ' + this.state.asset.chartOfAccountType.name || ''}
                                                        className='form-control form-input'/>
                                                </div>
                                                <div className='col-2'>
                                                    {this.state.asset.type !== 'LAND' && 
                                                        <React.Fragment>
                                                            <h6>{t("assetDetail:Accumulated depreciation (Baht)")}</h6>
                                                            <input type='text' readOnly={true} value={numberWithCommas(this.state.sum_asset_depreciate)} className='form-control form-input'/>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                                <div className='col-6' />
                                            </div>
        
                                            <h4 className='mt-48'>{t("assetDetail:Asset information")}</h4>
                                            <hr />
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <h6>{t("assetDetail:Asset code")}</h6>
                                                    <input type='text' readOnly={true} value={this.state.asset.docNumber} className='form-control form-input'/>
                                                </div>
                                                <div className='col-2'>
                                                    <h6>{t("assetDetail:Purchase date")}</h6>
                                                    <DatePickerNoti selected={this.state.asset.purchaseDate} disabled={true} className='form-input'/>
                                                </div>
                                                <div className='col-2'>
                                                    <h6>{t("assetDetail:Internal asset code")}</h6>
                                                    <input type='text'  name='asset.internalAssetCode' value={this.state.asset.internalAssetCode? this.state.asset.internalAssetCode : ""} onChange={this.handleChange}
                                                     className='form-control form-input' disabled={_.lowerCase(this.state.asset.saleStatus) !== 'active' || !this.state.permissionEdit} />
                                                </div>
                                                <div className='col-2'>
                                                    <h6>{t("assetDetail:Reference document number")}</h6>
                                                    <input type='text' readOnly={true} value={!this.state.asset.importStatus ? 'Import' : this.state.asset.refNumber} 
                                                     className='form-control form-input'/>
                                                </div>
                                                <div className='col-2' />
                                            </div>
                                            <div className='row mt-24'>
                                                <div className='col-6'>
                                                    <h6>{t("assetDetail:Asset name")} </h6>
                                                    <input type='text' value={this.state.asset.name} onChange={this.handleChange}
                                                        name='asset.name' className='form-control form-input'disabled={_.lowerCase(this.state.asset.saleStatus) !== 'active'} />
                                                </div>
                                                <div className='col-2'>
                                                    <h6>{t("assetDetail:Asset value (baht)")}</h6>
                                                    <input type='text' value={numberWithCommas(this.state.asset.costPrice)} readOnly={true} className='form-control form-input'/>
                                                </div>
                                                <div className='col-2'>
                                                    <h6>{t("assetDetail:Salvage price (baht)")}</h6>
                                                    <input type='text' value='1' readOnly={true} className='form-control form-input'/>
                                                </div>
                                                <div className='col-2' />
                                            </div>
                                            <div className='row mt-24'>
                                                <div className='col-6'>
                                                    <h6>{t("assetDetail:StatDescriptionus")}</h6>
                                                    <input type='text' name="asset.description" readOnly={true}
                                                        value={this.state.asset.description} className='form-control form-input'/>
                                                </div>
                                                <div className='col-2'>
                                                    <h6>{t("assetDetail:Value of assets brought forward (Baht)")}</h6>
                                                    <input type='text' readOnly={true} value={numberWithCommas(this.state.asset.historicalValue)} className='form-control form-input'/>
                                                </div>
                                                <div className='col-2'>
                                                    <h6>{t("assetDetail:Depreciation brought forward (Baht)")}</h6>
                                                    <input type='text' readOnly={true} value={numberWithCommas(this.state.asset.historicalAmount)} className='form-control form-input'/>
                                                </div>
                                                <div className='col-2' />
                                            </div>
                                            <div className='row mt-24'>
                                                <div className='col-6'>
                                                    <h6>{t("assetDetail:Location of property use")}</h6>
                                                    <input type='text' value={this.state.asset.storage} name='asset.storage' onChange={this.handleChange}
                                                     className='form-control form-input'disabled={_.lowerCase(this.state.asset.saleStatus) !== 'active' || !this.state.permissionEdit} />
                                                </div>
                                                <div className='col-2'>
                                                    <h6>{t("assetDetail:Start date of depreciation calculation")}</h6>
                                                    <DatePickerNoti selected={this.state.asset.calDepreciationDate} 
                                                        name='asset.calDepreciationDate' onChange={this.handleChange} className='form-input'
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className='col-4' />
                                            </div>
                                            <div className='row mt-24'>
                                                <div className='col-6'>
                                                    <h6>{t("assetDetail:Note")}</h6>
                                                    <input type='text' name="asset.remark" onChange={this.handleChange}
                                                        value={this.state.asset.remark} className='form-control form-input'/>
                                                </div>
                                                <div className='col-6' />
                                            </div>
                                            <div className='row mt-24'>
                                                <div className='col-6'>
                                                    <h6>{t("assetDetail:Vendor")}</h6>
                                                        <SelectContactDropDown
                                                            contact_type=''
                                                            handleInputChange={this.handleChange}
                                                            name="asset.seller.id"
                                                            payment_type="payable"
                                                            contact={this.state.asset.seller.id}
                                                            asset={true}
                                                            require={true}
                                                            contact_key={false}
                                                            disabled={_.lowerCase(this.state.asset.saleStatus) !== 'active' || !this.state.permissionEdit}
                                                        />
                                                </div>
                                                <div className='col-6' />
                                            </div>
                                            {this.state.asset.seller.id === "other_select" &&
                                                <div className='row mt-24'>
                                                    <div className='col-6'>
                                                        <input type="text" className="form-control form-input"
                                                            name="asset.sellerOther"
                                                            value={this.state.asset.sellerOther}
                                                            onChange={this.handleChange}
                                                            required={true}
                                                            disabled={_.lowerCase(this.state.asset.saleStatus) !== 'active' || !this.state.permissionEdit}
                                                        />
                                                    </div>
                                                    <div className='col-6' />
                                                </div>
                                            }
        
                                            <h4 className='mt-48'>{t("assetDetail:Asset picture")}</h4>
                                            <hr />
                                            <div className='row'>
                                                {
                                                    this.state.allAssetImage.edges.map((asset_image, index) =>
                                                    <div className="col-1 card m-1" key={index} >
                                                        <img
                                                            src={asset_image.node.source}
                                                            alt="news_preview"
                                                            className="img-fluid image card-img"
                                                            style={{width:'128px',height:'80px'}}
                                                            />
                                                        <div className="middle">
                                                            <div onClick={ () => this.deleteAssetImage(asset_image.node.id, index)}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                    alt="news_preview_delete"
                                                                    className='cursor'
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )
                                                }

                                                {
                                                    this.state.upload_image_list.map((image, index) =>
                                                        <div className="col-1 card m-1" key={index}>
                                                            <img
                                                                src={URL.createObjectURL(image)}
                                                                alt="news_preview"
                                                                className="img-fluid image card-img"
                                                                style={{width:'128px',height:'80px'}}
                                                                />
                                                            <div className="middle">
                                                                <div onClick={ () => this.deleteAssetImage(false, index)}>
                                                                    <img
                                                                        src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                        alt="news_preview_delete"
                                                                        className='cursor'
                                                                        
                                                                        />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                </div>
                                            </div>
                                            {_.lowerCase(this.state.asset.saleStatus) === 'active' &&  this.state.permissionEdit &&
                                            <>
                                                <label htmlFor='add' className='cursor add-pic mt-44 mb-48'>+ {t("assetDetail:Add picture")} </label>
                                                <small className='text-red pl-2' >{t("assetDetail:Image files must be in JPG or PNG format, with dimensions not exceeding 2000 x 2000 pixels and a file size limit of 10 MB.")}</small>
                                            </>  
                                            }
                                            <hr />
                                            <input type="file" className="custom-file-input" 
                                                id="uploadNewsImages" name={this.props.name}
                                                accept="image/*" value=""
                                                onChange={this.handleInputImage}
                                            />
                                            {approveHistory?.length > 0 && 
                                                    (<React.Fragment>
                                                        <h4 className='mt-48'>{t("assetDetail:Asset inspection")}</h4>
                                                        <hr /> 
                                                        {approveHistory.map((data, index)=>
                                                        <React.Fragment  key={index}>
                                                            <div className='row mt-2'>
                                                                <div className='col-1'>                                                                 
                                                                    <div className='height50'></div>
                                                                    <div className='height50'>{t("assetDetail:time(s)")} {index+1}</div>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <label> <h6>{t("assetDetail:Inspector")}</h6></label>
                                                                    <input type='text' readOnly={true} value={data.node.approver} className='form-control form-input'/>
                                                                </div>
                                                                <div className='col-2'>
                                                                    <label> <h6>{t("assetDetail:Inspection date")}</h6></label>
                                                                    <input type='text' readOnly={true} value={format(data.node.approveDate, 'DD/MM/YYYY')} className='form-control form-input'/>
                                                                </div>
                                                                <div className='col-2'>
                                                                    <label> <h6>{t("assetDetail:Inspection status")}</h6></label>
                                                                    <input type='text' readOnly={true} value={this.statusName(data.node.approveStatus)} className='form-control form-input'/>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-2'>
                                                                <div className='col-1'></div>
                                                                <div className='col-4'>
                                                                    <label> <h6>{t("assetDetail:Comment")}</h6></label>
                                                                    <input type='text' readOnly={true} value={data.node.remark ? data.node.remark : ""} className='form-control form-input'/>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        )}
                                                    </React.Fragment>)

                                            }

                                            {_.lowerCase(this.state.asset.saleStatus) === 'active' &&
                                            <div className='row col-12 mt-48 mb-48 d-flex justify-content-end'>
                                                {this.state.permissionEdit &&
                                                <button type='button' onClick={() => this.onSubmit()} className='btn-confirm cursor'>
                                                    {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                    <span>{t("assetDetail:Save")}</span>
                                                </button>}
                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_asset_delete'}) &&
                                                    
                                                    <button type='button' className='btn-cancel cursor' onClick={() => this.deleteAsset()}>
                                                        {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                        <span>{t("assetDetail:Cancel asset")}</span>
                                                    </button>
                                                }
                                            </div >
                                            }
                                        </form>
                                       
                                    </React.Fragment>
                                    }

                                    

                                </div>
                            }
                        </Translation>
                    </div>
                </WrapperContent>

           </Wrapper>
        )
    }
}

export default AssetDetail;