import React from 'react';
import Header from '../../components/header/';
import Sidebar from '../../components/sidebar/';
import Wrapper from '../../components/wrapper/';
import WrapperContent from '../../components/wrapper/wrapperContent';
import SelectContact from '../income/selectContact';
import CreateUpdate from './guaranteeMoneyReceivedCreateUpdate';
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";


class GuaranteeMoneyReceivedForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accountType: "guarantee_money_receive",
            customer_list: [],
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({customer_list: contact_list})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    {this.props.match.params.method === 'select-contact' &&
                    <SelectContact updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                   initialContactType="residential"
                                   nextLink="/accounting/guarantee-money-received/form/create"
                                   backLink="/accounting/guarantee-money-received/list/received"/>
                    }
                    {(this.props.match.params.method === 'create' ||  this.props.match.params.method === 'clear') &&
                    <CreateUpdate match={this.props.match} contact={this.state.customer_list[0]} category={this.props.match.params.method === 'clear'? 'clear_guarantee_money_receive' : this.state.accountType}/>
                    }
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default GuaranteeMoneyReceivedForm;
