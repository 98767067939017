import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import Jsb from './jsb/jsbView'
import NoMatch from "../components/noMatch";
import AddOnDashBoard from './addonDashBoard'
import lawyerView from './lawyer/lawyerView'

class AddOn extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={AddOnDashBoard}/>
                <PrivateRoute exact path={`${this.props.match.url}/jsb`} component={Jsb}/>
                <PrivateRoute exact path={`${this.props.match.url}/lawyer`} component={lawyerView}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default AddOn;