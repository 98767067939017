import React, { Component } from 'react'
import { Translation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";


export default class InsuranceNavigation extends Component {

    render() {

        let statusUrl =
            this.props.status === "Info" ? "insur-edit-info" :
                this.props.status === "Policy" ? "insur-edit-policy" : this.props.status === "Insurance" ? "insur-edit-insurance" : "insur-edit-broker"
        return (
            <Translation>
                {t =>
                    <div className="row" id="navigation-tab">

                        <div className="col-9">
                            <ul>
                                <li>
                                    <NavLink exact={true} to={`/purchasing/insurrance/insur-detail/${this.props.paramsId}/Info`}>
                                        {t("claim:Insurance")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/purchasing/insurrance/insur-detail/${this.props.paramsId}/Policy`}>
                                        {t("claim:Policy Info")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/purchasing/insurrance/insur-detail/${this.props.paramsId}/Insurance`}>
                                        {t("claim:joint insurance")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/purchasing/insurrance/insur-detail/${this.props.paramsId}/Broker`}>
                                        {t("claim:insurance broker")}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className='col-3' style={{ marginTop: -100 }}>
                            {
                                this.props.paramsId ?
                                    <Link to={`/purchasing/insurrance/${statusUrl}/${this.props.paramsId}`}>
                                        <button className='btn-outline bg-1567B4' >
                                        {t("Allaction:edit")}
                                        </button>
                                    </Link>
                                    :
                                    <button className='btn btn-outline bg-1567B4'>
                                         {t("Allaction:Add")}
                                    </button>
                            }
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}
