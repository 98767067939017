import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import Survey from "./survey";

const surveyQuery = graphql`
    query surveyQueryQuery($id: ID!, $residential_vote_id: ID) {
        allVote(residentialVote_Survey_Id: $id, residentialVote_Id: $residential_vote_id){
            edges{
                node{
                    id
                    choice{
                        id
                    }
                }
            }
        }
    }
`;

class SurveyQuery extends Component {

    render() {
        return (
            <Survey survey_id={this.props.match.params.id} query={surveyQuery} type={this.props.match.params.type} residential_vote_id={this.props.match.params.residential} size={this.props.match.params.size}/>
        );
    }
}

export default SurveyQuery;
