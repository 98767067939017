import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from "react-i18next";


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/announce-dashboard/news-list/ALL">
                                <Translation>
                                    {(t) => <span>{t("press_release:ทั้งหมด")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/announce-dashboard/news-list/VOID">
                                <Translation>
                                    {(t) => <span>{t("press_release:list_of_cancellation")}</span>}
                                </Translation>
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation; 