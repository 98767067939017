/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccountRecordGroupCategory = "GENERAL" | "HISTORICAL" | "PAYMENT" | "PURCHASE" | "RECEIVE" | "SALES" | "VOIDED" | "%future added value";
export type generalLedgerTableQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
  betweenStartId?: ?string,
  betweenEndId?: ?string,
  chart?: ?string,
|};
export type generalLedgerTableQueryResponse = {|
  +historicalRecord: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +debit: number,
        +credit: number,
        +chartOfAccountCode: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |},
      |}
    |}>
  |},
  +currentRecord: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +debit: number,
        +credit: number,
        +added: any,
        +group: ?{|
          +id: string,
          +name: string,
          +issuedDate: ?any,
          +refNumber: ?string,
          +totalDebit: number,
          +totalCredit: number,
          +refTransaction: ?string,
          +added: any,
          +category: AccountRecordGroupCategory,
        |},
        +chartOfAccountCode: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |},
      |}
    |}>
  |},
  +summaryPreviousAccountRecord: ?{|
    +sumJson: ?any
  |},
  +betweenChartOfAccount: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +chartOfAccountCode: string,
        +name: string,
      |}
    |}>
  |},
  +allAccountProjectManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +tableAccount: ?any,
      |}
    |}>
  |},
|};
export type generalLedgerTableQuery = {|
  variables: generalLedgerTableQueryVariables,
  response: generalLedgerTableQueryResponse,
|};
*/


/*
query generalLedgerTableQuery(
  $startDate: DateTime
  $endDate: DateTime
  $betweenStartId: String
  $betweenEndId: String
  $chart: String
) {
  historicalRecord: allAccountRecord(typeOfRecord: "historical", startDate: $startDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, orderLedger: true, chart: $chart) {
    edges {
      node {
        id
        name
        debit
        credit
        chartOfAccountCode {
          id
          name
          chartOfAccountCode
        }
      }
    }
  }
  currentRecord: allAccountRecord(startDate: $startDate, endDate: $endDate, typeOfRecord: "current", betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, orderLedger: true, chart: $chart) {
    edges {
      node {
        id
        name
        debit
        credit
        added
        group {
          id
          name
          issuedDate
          refNumber
          totalDebit
          totalCredit
          refTransaction
          added
          category
        }
        chartOfAccountCode {
          id
          name
          chartOfAccountCode
        }
      }
    }
  }
  summaryPreviousAccountRecord(startDate: $startDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, chart: $chart) {
    sumJson
  }
  betweenChartOfAccount(betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, chart: $chart) {
    edges {
      node {
        id
        chartOfAccountCode
        name
      }
    }
  }
  allAccountProjectManager {
    edges {
      node {
        id
        tableAccount
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "betweenEndId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "betweenStartId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chart"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "kind": "Variable",
  "name": "betweenEndId",
  "variableName": "betweenEndId"
},
v6 = {
  "kind": "Variable",
  "name": "betweenStartId",
  "variableName": "betweenStartId"
},
v7 = {
  "kind": "Variable",
  "name": "chart",
  "variableName": "chart"
},
v8 = {
  "kind": "Literal",
  "name": "orderLedger",
  "value": true
},
v9 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "startDate"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "debit",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "credit",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccountCode",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v11/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v17 = [
  {
    "alias": "historicalRecord",
    "args": [
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      {
        "kind": "Literal",
        "name": "typeOfRecord",
        "value": "historical"
      }
    ],
    "concreteType": "AccountRecordNodeConnection",
    "kind": "LinkedField",
    "name": "allAccountRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountRecordNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountRecordNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "currentRecord",
    "args": [
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      (v8/*: any*/),
      (v9/*: any*/),
      {
        "kind": "Literal",
        "name": "typeOfRecord",
        "value": "current"
      }
    ],
    "concreteType": "AccountRecordNodeConnection",
    "kind": "LinkedField",
    "name": "allAccountRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountRecordNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountRecordNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountRecordGroupNode",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "issuedDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalDebit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCredit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refTransaction",
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v9/*: any*/)
    ],
    "concreteType": "SummaryPreviousAccountRecord",
    "kind": "LinkedField",
    "name": "summaryPreviousAccountRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sumJson",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "concreteType": "ChartOfAccountNodeConnection",
    "kind": "LinkedField",
    "name": "betweenChartOfAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v14/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountProjectManagerNodeConnection",
    "kind": "LinkedField",
    "name": "allAccountProjectManager",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountProjectManagerNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountProjectManagerNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tableAccount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "generalLedgerTableQuery",
    "selections": (v17/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "generalLedgerTableQuery",
    "selections": (v17/*: any*/)
  },
  "params": {
    "cacheID": "915bff8b6b656d34f81783e7616addf7",
    "id": null,
    "metadata": {},
    "name": "generalLedgerTableQuery",
    "operationKind": "query",
    "text": "query generalLedgerTableQuery(\n  $startDate: DateTime\n  $endDate: DateTime\n  $betweenStartId: String\n  $betweenEndId: String\n  $chart: String\n) {\n  historicalRecord: allAccountRecord(typeOfRecord: \"historical\", startDate: $startDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, orderLedger: true, chart: $chart) {\n    edges {\n      node {\n        id\n        name\n        debit\n        credit\n        chartOfAccountCode {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n  }\n  currentRecord: allAccountRecord(startDate: $startDate, endDate: $endDate, typeOfRecord: \"current\", betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, orderLedger: true, chart: $chart) {\n    edges {\n      node {\n        id\n        name\n        debit\n        credit\n        added\n        group {\n          id\n          name\n          issuedDate\n          refNumber\n          totalDebit\n          totalCredit\n          refTransaction\n          added\n          category\n        }\n        chartOfAccountCode {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n  }\n  summaryPreviousAccountRecord(startDate: $startDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, chart: $chart) {\n    sumJson\n  }\n  betweenChartOfAccount(betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, chart: $chart) {\n    edges {\n      node {\n        id\n        chartOfAccountCode\n        name\n      }\n    }\n  }\n  allAccountProjectManager {\n    edges {\n      node {\n        id\n        tableAccount\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0b0238a272c80a84a673c42bf5dc8019';

module.exports = node;
