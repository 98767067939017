import React, { Component } from 'react'
import { format } from "date-fns";
import _ from "lodash"
import numberWithComma from "../../../../libs/numberWithCommaReport";
import { Dropdown } from "react-bootstrap";

require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

export default class budgetLastYearCompareWithMonthExport extends Component {

  summaryRevenueYear = (codeValue, indexMonth) => {
    let result = 0;
    let sumRevenue_summaryYear_total_budget = 0;
    let sumRevenue_total_happend = 0;
    let sumRevenue_total_budget = 0;
    let sumRevenue_total_month = 0;

    sumRevenue_summaryYear_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
    sumRevenue_total_happend = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
    sumRevenue_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.total_budget || 0))
    sumRevenue_total_month = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))

    switch (codeValue) {
      case "4000-00 summaryYear_total_budget":
        result = sumRevenue_summaryYear_total_budget
        break;
      case "4000-00 total_happend":
        result = sumRevenue_total_happend
        break;
      case "4000-00 total_budget":
        result = sumRevenue_total_budget
        break;
      case `4000-00 total_month[${indexMonth}]`:
        result = sumRevenue_total_month
        break;

      default:
        break;
    }
    return result;
  }

  summaryExpenseYear = (codeValue, indexMonth) => {
    let result = 0;
    let sumExpense_otherExpenses_summaryYear_total_budget = 0;
    let sumExpense_otherExpenses_total_happend = 0;
    let sumExpense_otherExpenses_total_budget = 0;
    let sumExpense_otherExpenses_total_month = 0;

    let sumExpense_items_summaryYear_total_budget = 0;
    let sumExpense_items_total_happend = 0;
    let sumExpense_items_total_budget = 0;
    let sumExpense_items_total_month = 0;

    if (codeValue.substring(0, 7) === "5800-00") {
      _.forIn(this.props.state.lists[1].children[8].children, function (item) {
        sumExpense_otherExpenses_summaryYear_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_budget || 0))
        sumExpense_otherExpenses_total_happend += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_happend || 0))
        sumExpense_otherExpenses_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.total_budget || 0))
        sumExpense_otherExpenses_total_month += _.sumBy(item.children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      })
    } else {
      if (codeValue.substring(0, 7) === "5100-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5200-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5300-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5310-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5400-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5500-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5600-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5700-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }

    }

    switch (codeValue) {
      case "5800-00 summaryYear_total_budget":
        result = sumExpense_otherExpenses_summaryYear_total_budget
        break;
      case "5800-00 total_happend":
        result = sumExpense_otherExpenses_total_happend
        break;
      case "5800-00 total_budget":
        result = sumExpense_otherExpenses_total_budget
        break;
      case "5800-00 total_month":
        result = sumExpense_otherExpenses_total_month
        break;
      case `${codeValue.substring(0, 7)} summaryYear_total_budget`:
        result = sumExpense_items_summaryYear_total_budget
        break;
      case `${codeValue.substring(0, 7)} total_happend`:
        result = sumExpense_items_total_happend
        break;
      case `${codeValue.substring(0, 7)} total_budget`:
        result = sumExpense_items_total_budget
        break;
      case `${codeValue.substring(0, 7)} total_month`:
        result = sumExpense_items_total_month
        break;

      default:
        break;
    }
    return result;
  }

  checkMonth(monthInput) {
    let resultMonth = ""
    let monthFormat = format(new Date(monthInput), "MMM")
    let yearFormat = format(new Date(monthInput), "YY")

    switch (monthFormat) {
      case "Jan":
        resultMonth = "ม.ค."
        break;
      case "Feb":
        resultMonth = "ก.พ."
        break;
      case "Mar":
        resultMonth = "มี.ค."
        break;
      case "Apr":
        resultMonth = "เม.ย."
        break;
      case "May":
        resultMonth = "พ.ค."
        break;
      case "Jun":
        resultMonth = "มิ.ย."
        break;
      case "Jul":
        resultMonth = "ก.ค."
        break;
      case "Aug":
        resultMonth = "ส.ค."
        break;
      case "Sep":
        resultMonth = "ก.ย."
        break;
      case "Oct":
        resultMonth = "ต.ค."
        break;
      case "Nov":
        resultMonth = "พ.ย."
        break;
      case "Dec":
        resultMonth = "ธ.ค."
        break;

      default:
        break;
    }
    return resultMonth + yearFormat
  }

  getRowColCode = (indexRow, indexCol) => {
    let value = ""
    switch (indexCol+1) {
      case 1:
        value = "A"
        break;
      case 2:
        value = "B"
        break;
      case 3:
        value = "C"
        break;
      case 4:
        value = "D"
        break;
      case 5:
        value = "E"
        break;
      case 6:
        value = "F"
        break;
      case 7:
        value = "G"
        break;
      case 8:
        value = "H"
        break;
      case 9:
        value = "I"
        break;
      case 10:
        value = "J"
        break;
      case 11:
        value = "K"
        break;
      case 12:
        value = "L"
        break;
      case 13:
        value = "M"
        break;
      case 14:
        value = "N"
        break;
      case 15:
        value = "O"
        break;
      case 16:
        value = "P"
        break;
      case 17:
        value = "Q"
        break;
      case 18:
        value = "R"
        break;
      case 19:
        value = "S"
        break;
      case 20:
        value = "T"
        break;
      case 21:
        value = "U"
        break;
      case 22:
        value = "V"
        break;
      case 23:
        value = "W"
        break;
      case 24:
        value = "X"
        break;

      default:
        break;
    }

    return `${value}${indexRow}`
  }

  downloadExcel = () => {
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('LastYearCampareWithMonth');

    // setWidthcolumns
    let columns = [
      { width: 15 },
      { width: 50 },
      { width: 15 },
      { width: 15 },
    ]

    // setBorder
    let borders = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }

    let fontSizes = { size: 12 }
    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let fontBold = { size: 12, bold: true };

    worksheet.getCell('A1').value = this.props.checkTextSelect
    worksheet.getCell('A1').font = {
      size: 20,
      bold: true
    };

    worksheet.getCell('A2').value = `ปีตั้งงบประมาณ ${format(this.props.state.temp_year, "YYYY")}`
    worksheet.getCell('A2').font = fontBold;

    worksheet.getCell('A3').value = `ระหว่างวันที่ ${format(this.props.state.temp_start_date, "DD/MM/YYYY")} ถึง ${format(this.props.state.temp_end_date, "DD/MM/YYYY")}`
    worksheet.getCell('A3').font = fontBold;

    let headerTop = ["ลำดับ", "รายการ", `งบประมาณปี ${this.props.state.temp_year.getFullYear() - 1}`, ""]
    let headerBottom = ["", "", "งบประมาณ", "เกิดจริง"]
    let countMount = -1

    {_.map(this.props.state.lists[0].children[0].data.month_budget, (item, index) => {
      if(index === 0){
        columns.push({ width: 15 })
        headerTop.push(`งบประมาณปี ${format(this.props.state.temp_start_date, "YYYY")}`)
        headerBottom.push(this.checkMonth(item.month_date))
      }else {
        columns.push({ width: 15 })
        headerTop.push("")
        headerBottom.push(this.checkMonth(item.month_date))
      }
      countMount++
    })}

    columns.push({ width: 15 })
    headerTop.push("งบประมาณรวม")
    headerBottom.push("")

    let mergeHeader = [headerTop, headerBottom]

    _.map(mergeHeader, (value, index) => {
      worksheet.getRow(5 + index).values = value
    })

    _.map(mergeHeader, (value, index) => {
      _.map(value, (value2, indexValue2) => {
        let cell = worksheet.getRow(5 + index).getCell(1 + indexValue2)

        cell.font = fontBold;
        cell.border = borders;
        cell.alignment = textCenter;

        switch (value2) {
          case "ลำดับ":
            worksheet.mergeCells("A5", "A6");
            break;
          case "รายการ":
            worksheet.mergeCells("B5", "B6");
            break;
          case `งบประมาณปี ${this.props.state.temp_year.getFullYear() - 1}`:
            worksheet.mergeCells("C5", "D5");
            break;
          case `งบประมาณปี ${format(this.props.state.temp_start_date, "YYYY")}`:
            worksheet.mergeCells(this.getRowColCode(5 + index, indexValue2), this.getRowColCode(5 + index, indexValue2 + countMount));
            break;
          case "งบประมาณรวม":
            worksheet.mergeCells(this.getRowColCode(5 + index, indexValue2), this.getRowColCode(6 + index, indexValue2));
            break;

          default:
            break;
        }
      })
    })

    let last_index = 0;
    let last_index_child = 0;
    let summaryExpenseTotalMonth = [];
    let summaryExpenseTotalMonthDiffSumRevenue = [];
    let expenseDiffRevenueSummaryYearTotalBudget = 0;
    let expenseDiffRevenueTotalHappend = 0;
    let expenseDiffRevenueTotalBudget = 0;

    let data1 = []
    _.map(this.props.state.lists[0]?.children, (item, index) => {
      ++last_index;

      let valueNullZero = []
      let valueMonthZero = []

      _.map(item.data.month_budget, (month, index_month) => {
        valueNullZero.push("")
        valueMonthZero.push(month.budget ? numberWithComma(month.budget) : "")
      })

      if(index == 0){

        data1.push(['', 'รายรับ', '', '', ...valueNullZero, ""])
        data1.push([
          `${last_index}`, 
          `${item.data.name}`, 
          `${item.data.summaryYear.total_budget ? numberWithComma(item.data.summaryYear.total_budget) : ""}`, 
          `${item.data?.summaryYear?.total_happend ? numberWithComma(item.data?.summaryYear?.total_happend) : ""}`, 
          ...valueMonthZero,
          `${item.data?.total_budget ? numberWithComma(item.data?.total_budget) : ""}`,
        ])

      }else if(index === (this.props.state.lists[0].children.length - 1)){

        data1.push([
          `${last_index}`, 
          `${item.data.name}`, 
          `${item.data.summaryYear.total_budget ? numberWithComma(item.data.summaryYear.total_budget) : ""}`, 
          `${item.data?.summaryYear?.total_happend ? numberWithComma(item.data?.summaryYear?.total_happend) : ""}`, 
          ...valueMonthZero,
          `${item.data?.total_budget ? numberWithComma(item.data?.total_budget) : ""}`,
        ])

        let summaryRevenueArr = []

        {this.props.state.lists[0].children[0].data.month_budget.map((item, index) => {

          summaryExpenseTotalMonthDiffSumRevenue[index] = this.summaryRevenueYear(`4000-00 total_month[${index}]`, index) || 0

          summaryRevenueArr.push(this.summaryRevenueYear(`4000-00 total_month[${index}]`, index) !== 0 
                                    ? numberWithComma(this.summaryRevenueYear(`4000-00 total_month[${index}]`, index).toFixed(2)) : "-")
        })}
        data1.push([
          "", 
          "รวมรายรับ", 
          `${this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0) !== 0 ? numberWithComma(this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0).toFixed(2)) : "-"}`, 
          `${this.summaryRevenueYear("4000-00 total_happend", 0) !== 0 ? numberWithComma(this.summaryRevenueYear("4000-00 total_happend", 0).toFixed(2)) : "-"}`, 
          ...summaryRevenueArr,
          `${this.summaryRevenueYear("4000-00 total_budget", 0) !== 0 ? numberWithComma(this.summaryRevenueYear("4000-00 total_budget", 0).toFixed(2)) : "-"}`
        ])
      }else {

        data1.push([
          `${last_index}`, 
          `${item.data.name}`, 
          `${item.data.summaryYear.total_budget ? numberWithComma(item.data.summaryYear.total_budget) : ""}`, 
          `${item.data?.summaryYear?.total_happend ? numberWithComma(item.data?.summaryYear?.total_happend) : ""}`, 
          ...valueMonthZero,
          `${item.data?.total_budget ? numberWithComma(item.data?.total_budget) : ""}`,
        ])

      }
    })


    let valueNull = []

    {_.map(this.props.state.lists[0].children[0].data.month_budget, (item, index) => {
      valueNull.push("")
    })}

    {_.map(this.props.state.lists[1]?.children, (item, index_code) => {
      expenseDiffRevenueSummaryYearTotalBudget += this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`, 0)
      expenseDiffRevenueTotalHappend += this.summaryExpenseYear(`${item.data.code} total_happend`, 0)
      expenseDiffRevenueTotalBudget += this.summaryExpenseYear(`${item.data.code} total_budget`, 0)

      if(index_code === 0){
        data1.push(['', 'รายจ่าย', '', '', ...valueNull, ""])
      }

      data1.push(['', `${item.data.name}`, '', '', ...valueNull, ""])

      _.map(item.children, (item_child, index) => {
        if (index_code !== 8) { ++last_index }

        if(item_child.children?.length > 0){
          data1.push(['', `${item_child.data.name}`, '', '', ...valueNull, ""])

          _.map(item_child?.children, (item_child_child, index_child_child) => {
            ++last_index_child

            let valueMonth_child_child = []

            {_.map(item_child_child.data.month_budget, (month, index_month) => {
              valueMonth_child_child.push(month.budget ? numberWithComma(month.budget) : "")
            })}

            data1.push([
              `${last_index + last_index_child}`, 
              `${item_child_child.data.name}`, 
              `${item_child_child.data?.summaryYear?.total_budget ? numberWithComma(item_child_child.data?.summaryYear?.total_budget) : ""}`, 
              `${item_child_child.data?.summaryYear?.total_happend ? numberWithComma(item_child_child.data?.summaryYear?.total_happend) : ""}`, 
              ...valueMonth_child_child,
              `${item_child_child.data?.total_budget ? numberWithComma(item_child_child.data?.total_budget) : ""}`, 
            ])

          })
        }else{

          let valueMonth_child = []

          {_.map(item_child.data.month_budget, (month, index_month) => {
            valueMonth_child.push(month.budget ? numberWithComma(month.budget) : "")
          })}

          data1.push([
            `${last_index + last_index_child}`, 
            `${item_child.data.name}`, 
            `${item_child.data?.summaryYear?.total_budget ? numberWithComma(item_child.data?.summaryYear?.total_budget) : ""}`, 
            `${item_child.data?.summaryYear?.total_happend ? numberWithComma(item_child.data?.summaryYear?.total_happend) : ""}`, 
            ...valueMonth_child,
            `${item_child.data?.total_budget ? numberWithComma(item_child.data?.total_budget) : ""}`, 
          ])
        }
      })


      let summaryExpenseYearArr = []
      
      {_.map(this.props.state.lists[0].children[0].data.month_budget, (item_month, index_month) => {

        typeof(summaryExpenseTotalMonth[index_month]) !== "number"
          ? summaryExpenseTotalMonth[index_month] = this.summaryExpenseYear(`${item.data.code} total_month`, index_month)
            : summaryExpenseTotalMonth[index_month] += this.summaryExpenseYear(`${item.data.code} total_month`, index_month);

        summaryExpenseYearArr.push(this.summaryExpenseYear(`${item.data.code} total_month`, index_month) !== 0 ?
          numberWithComma(this.summaryExpenseYear(`${item.data.code} total_month`, index_month)) : "-")
      })}

      data1.push([
        "", 
        `รวม${item.data.name}`, 
        `${this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`, 0) !== 0 ? numberWithComma(this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`, 0).toFixed(2)) : "-"}`, 
        `${this.summaryExpenseYear(`${item.data.code} total_happend`, 0) !== 0 ? numberWithComma(this.summaryExpenseYear(`${item.data.code} total_happend`, 0).toFixed(2)) : "-"}`,
        ...summaryExpenseYearArr,
        `${this.summaryExpenseYear(`${item.data.code} total_budget`, 0) !== 0 ? numberWithComma(this.summaryExpenseYear(`${item.data.code} total_budget`, 0).toFixed(2)) : "-"}`, 
      ])
    })}

    let expenseDiffRevenueMonth = []

    {_.map(this.props.state.lists[0].children[0].data.month_budget, (item, index) => {

      summaryExpenseTotalMonthDiffSumRevenue[index] += (-summaryExpenseTotalMonth[index])

      expenseDiffRevenueMonth.push(
        summaryExpenseTotalMonth.length > 0 
          ? summaryExpenseTotalMonth[index] !== 0 
            ? numberWithComma(summaryExpenseTotalMonth[index].toFixed(2)) 
            : "-" 
          : "-"
      )
      
    })}

    data1.push([
      "", 
      `รวมรายจ่าย`, 
      `${expenseDiffRevenueSummaryYearTotalBudget !== 0 ? numberWithComma(expenseDiffRevenueSummaryYearTotalBudget.toFixed(2)) : "-"}`, 
      `${expenseDiffRevenueTotalHappend !== 0 ? numberWithComma(expenseDiffRevenueTotalHappend.toFixed(2)) : "-"}`, 
      ...expenseDiffRevenueMonth,
      `${expenseDiffRevenueTotalBudget !== 0 ? expenseDiffRevenueTotalBudget.toFixed(2) : "-"}`, 
    ])

    let summaryExpenseTotalMonthDiffSumRevenueMonth = []

    {_.map(this.props.state.lists[0].children[0].data.month_budget, (item, index) => {

      summaryExpenseTotalMonthDiffSumRevenueMonth.push(
        summaryExpenseTotalMonthDiffSumRevenue.length > 0 
          ? summaryExpenseTotalMonthDiffSumRevenue[index] !== 0 
            ? numberWithComma(summaryExpenseTotalMonthDiffSumRevenue[index].toFixed(2))
            : "-" 
          : "-"
      )
    })}

    data1.push([
      "", 
      `รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด`, 
      `${(this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0) - expenseDiffRevenueSummaryYearTotalBudget) !== 0
          ? numberWithComma((this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0) - expenseDiffRevenueSummaryYearTotalBudget).toFixed(2))
          : "-"}`, 
      `${(this.summaryRevenueYear("4000-00 total_happend", 0) - expenseDiffRevenueTotalHappend) !== 0 
          ? numberWithComma((this.summaryRevenueYear("4000-00 total_happend", 0) - expenseDiffRevenueTotalHappend).toFixed(2))
          : "-"}`, 
      ...summaryExpenseTotalMonthDiffSumRevenueMonth,
      `${(this.summaryRevenueYear("4000-00 total_budget", 0) - expenseDiffRevenueTotalBudget) !== 0 
          ? numberWithComma((this.summaryRevenueYear("4000-00 total_budget", 0) - expenseDiffRevenueTotalBudget).toFixed(2)) 
          : "-"}`
    ])

    _.map(data1, (value, index) => {

      worksheet.getRow(7 + index).values = value

      _.map(value, (value2, indexValue2) => {

        let cell = worksheet.getRow(7 + index).getCell(1 + indexValue2)
        let textRight = { vertical: 'middle', horizontal: 'right' }

        cell.font = fontSizes;
        cell.border = borders;

        if(indexValue2 === 0){
          cell.alignment = textCenter;
        }else if(indexValue2 !== 0 && indexValue2 !== 1){
          cell.alignment = textRight;
        }
      })
    })

    worksheet.columns = columns;

    workbook.xlsx.writeBuffer()
    .then((data) => {
      const blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      let nameFile = `ExcelLastYearCompareWithMonth_${format(this.props.state.temp_start_date, "DD/MM/YYYY")}-${format(this.props.state.temp_end_date, "DD/MM/YYYY")}`
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = nameFile;
      a.click();
    });
  }

  render() {
    return (
      <Dropdown.Item onClick={this.downloadExcel}>
        Excel
      </Dropdown.Item>
    )
  }
}
