import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import environment from "../../env/environment";
import CustomerFilterReceivablePayable from "./invoice/customerFilterReceivablePayable";
import Loading from '../../libs/loading';

class ContactSupplierListReceivable extends Component {

    render() {
        return (
            <div className="card p-3 mt-5">
                <div className="row card-body">
                    <div className="col" id="list-contact">
                        {/* {console.log(this.props.contact_type, this.props.payment_type)} */}
                        <QueryRenderer
                            environment={environment}
                            query={this.props.query}
                            cacheConfig={{use_cache: false}}
                            variables={{contact_type: this.props.contact_type, payment_type: this.props.payment_type, receivable_filter: true}}
                            render={({error, props}) => {
                                if (error) {
                                    return <div>{error.message}</div>;
                                } else if (props) {
                                    return <React.Fragment>
                                        <CustomerFilterReceivablePayable queryObject={props.contactViewer.allContact.edges}
                                                        contactSelected={this.props.contactSelected}
                                                        selectContact={this.props.selectContact}
                                                        activeRowSelectedContact={this.activeRowSelectedContact}
                                                        queryField="name:refNumber"
                                                        list_type={"supplier"}
                                                        type_use={"receivable"}
                                        />
                                    </React.Fragment>
                                }
                                return <Loading/>
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactSupplierListReceivable;
