
import React, { Component } from 'react';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import environment from "../env/environment";
import {commitMutation} from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import Swal from 'sweetalert2';
import i18n from 'i18next';
import { format } from 'date-fns';
import upload from '../libs/upload'
import _ from "lodash";
import './feedback.scss'
import i18next from 'i18next';

const mutation = graphql`
mutation feedbackCreateMutation ($input: FeedbackCreateInput!) {
    feedbackCreate (input: $input) {
        ok
        message   
    }
}
`;

class FeedbackCreate extends Component {

    constructor(props) {
        super(props)

        this.state = {
            award:null,
            note:"",
            feedbackImage: [],
            noteLength:0,
            alertAward:false,
            isDisabled:true,
            langauge:this.props.match.params.lang ? this.props.match.params.lang : "th",
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.deleteImage = this.deleteImage.bind(this)
    }


    handleInputChange(e) {
        let numLength = 0;
        if(e.target.value){
            if(e.target.name === 'note'){
                numLength = e.target.value.length
            }

            this.setState({
                [e.target.name]: e.target.value,
                noteLength:numLength,
                isDisabled: false
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        
        if(this.state.award){
            this.setState({
                alertAward:false
            });

            let feedbackImageUser = this.state.feedbackImage.map(({fileName, fileUpload}) => ({fileName, fileUpload}));
            let variables = {
                input: {
                    feedback: {
                        award: this.state.award,
                        note: this.state.note,
                        image: feedbackImageUser
                    }
                }
            };

            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        if (response.feedbackCreate.ok) {
                            Swal.fire(i18n.t("ขอบคุณสำหรับการแนะนำติชม"), 'เราจะนำข้อมูลไปพัฒนาบริการของเราให้ดียิ่งขึ้น', 'success').then(() => {
                                window.location.reload()
                            });

                        } else {
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), 'กรุณาส่งใหม่อีกครั้ง', 'warning')
                        }
                    },
                    onError: (err) => {
                        Swal.fire('Error!', i18n.t("bank_transfer:Please try again"), 'warning')
                        
                    },
                },
            )

        }else{
            this.setState({
                alertAward:true
            });
        }
            
        
      
    };

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/user/" + path, file);
            resolve(res);
        });
    };

    uploadFile(e) {
        let event = _.cloneDeep(e);
        let files = event.currentTarget.files[0];

        if (files) {
            if (files?.size > 10485760) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }
            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), 'กรุณาอัพโหลดไฟล์ในประเภท .png, .jpg, และ .jpegเท่านั้น และไฟล์ขนาดไม่เกิน 2 MB (สูงสุด 10 รูป)', 'error')
            }
            else {
                let new_file = new File([files], `${format(new Date(), "picture" + "_YYYYMMDDHHmmss")}${Math.floor(Math.random() * 1000000001)}${files.name}`);

                this.callUpload("image", new_file).then((data) => {
                        let fileOldList = _.cloneDeep(this.state.feedbackImage);
                        let dese = "user/image/" + new_file.name
                        fileOldList.push({ 
                            "fileName": new_file.name, 
                            "fileUpload": dese,
                            "viewPtath": data.location
                        })
                        this.setState({ 
                            feedbackImage: fileOldList,
                        })
                });
            }
        }
    }

    deleteImage(data, index) {
        let imageData = _.cloneDeep(this.state.feedbackImage)
        imageData.splice(index, 1)
        this.setState({ feedbackImage: imageData })
    }

    render() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams?.get('token')
        const lang = urlParams?.get('lang')

        let myLang = '';

        if(token && lang){
            window.localStorage.setItem("token", token);
            myLang = lang?.split("?")[0];
        }

        return (
            <>
            <div id="wrapper-content" style={{backgroundColor:'#FAFAFA',overflow:'hidden'}}>
                <div id='feedback-customer'>   
                <div className='row pt-6'>
                    <div className='col text-center mb-4'> 
                        <h4> {myLang === 'en' ? 'Feedback' :'แนะนำติชม'} </h4>
                    </div>
                </div>
                <div className='row'>
                    <div className='col text-center mb-4'> 
                        <img src={process.env.PUBLIC_URL + '/images/feedback/feedback.png'} alt="feedback" className="feedback"/>
                    </div>  
                </div>
 
                <div className='feedback-content'>
                   <div className='p-4'>
                   <form className='p-2' onSubmit={this.handleSubmit}>
                        <div className='row'>
                            <div className='col text-center'> 
                                <span> {myLang === 'en' ? 'Rating a service' :'คะแนนการให้บริการ'}</span>
                            </div>
                        </div>
                        <div className='row p-2'>
                            <div className='col text-center'>
                                <div className="star-rating">
                                    <input id="star-5" type="radio" name="award" value="5" 
                                     onChange = {this.handleInputChange}
                                    />
                                    <label htmlFor="star-5" title="5 stars">
                                        <FontAwesomeIcon icon={faStar} />
                                    </label>
                                    <input id="star-4" className='myAward' type="radio" name="award" value="4" onChange={this.handleInputChange}/>
                                    <label htmlFor="star-4" title="4 stars">
                                        <FontAwesomeIcon icon={faStar} />
                                    </label>
                                    <input id="star-3" className='myAward' type="radio" name="award" value="3" onChange={this.handleInputChange}/>
                                    <label htmlFor="star-3" title="3 stars">
                                        <FontAwesomeIcon icon={faStar} />
                                    </label>
                                    <input id="star-2" className='myAward' type="radio" name="award" value="2" onChange={this.handleInputChange}/>
                                    <label htmlFor="star-2" title="2 stars">
                                        <FontAwesomeIcon icon={faStar} />
                                    </label>
                                    <input id="star-1" className='myAward' type="radio" name="award" value="1" onChange={this.handleInputChange} />
                                    <label htmlFor="star-1" title="1 star">
                                        <FontAwesomeIcon icon={faStar} />
                                    </label>
                              </div>
                            </div>
                        </div>
                      
                        <div className='row p-2'>
                            {myLang === 'en' ? 'Suggenst' :'กรุณาแสดงความคิดเห็นของคุณ'}
                        </div>
                        <div className='row p-2'>
                            <textarea maxlength="300" rows="7" name='note' onChange={this.handleInputChange} required
                            className="form-control text-area-feedback" id="note" placeholder={myLang === 'en' ? 'Some suggenst' :'คุณมีอะไรที่อยากแนะนำเราบ้าง'}/>
                            <div className='noteLength'> {this.state.noteLength} / 300</div>
                        </div>
                        <div className='row p-2'>
                                <div className='svm-agent-img'>
                                    {
                                       this.state.feedbackImage?.length <= 10 &&
                                        this.state.feedbackImage?.map((n, inx) => {
                                            
                                                return (
                                                <div style={{position:'relative'}}>
                                                    <img src={n.viewPtath} style={{ width: 100, height: 100,borderRadius:'8px',margin:'2px'}} />
                                                    <button className='btnDeleteImage' type={"button"} onClick={() => this.deleteImage(n, inx)}>
                                                        <img src={process.env.PUBLIC_URL + "/images/feedback/closeImg.png"}  className="img-pre"/>
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {this.state.feedbackImage?.length <= 9 ?
                                <div className='btn-svmUploadImg'>
                                    <div> 
                                        <img src={process.env.PUBLIC_URL + '/images/icons/camera.png'} style={{position:'relative',top:'16px',left:'32px',width:'25px'}} />
                                    </div>
                                        <div className='text-center'>
                                            <input type="file" name='feedbackImage' id="feedbackImage" className='svmUploadImg'                          
                                                    accept='application/.pdf,.png,.jpg'
                                                    onChange={(e) => this.uploadFile(e)}
                                                />
                                                <span style={{position:'relative',top:'-10px',left:'2px',fontSize:'12px',color:'#0F0F0F'}}>
                                                {myLang === 'en' ? 'add images' :'เพิ่มรูปภาพ'}</span>
                                        </div>
                                    </div>
                                         :<span className='text-danger'> {myLang === 'en' ? 'only 10 images' :'แนบรูปสูงสุดได้ 10 รูปเท่านั้น'} </span>
                                }    
                        </div>
                        <div className='row p-2'>
                            <button className="btn btn-primary btn-svm-feedback" disabled={this.state.isDisabled}>
                                 {myLang === 'en' ? 'Sent' :'ส่ง'}
                            </button>
                             {this.state.alertAward && <span className='text-red'> {myLang === 'en' ? 'Please rating us' :'กรุณาให้คะแนน'}</span>}
                        </div>
                     </form>
                   </div>
                </div>

                </div>
            </div>
            </>
        )
    }
}

export default FeedbackCreate;